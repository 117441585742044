import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { Roles } from 'src/app/shared/global.variables';
import { TP2Permission } from '../../services/user-permissions-service';
import { ActivationUsersComponent } from './activation-users/activation-users.component';
import { CreateUsersComponent } from './create-users/create-users.component';
import { ListUsersComponent } from './list-users/list-users.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'listUser',
    pathMatch: 'full',
  },
  {
    path: 'listUser',
    component: ListUsersComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        Roles.Admin,
        Roles.Investigator,
        TP2Permission.UserCreate,
        TP2Permission.UserUpdate,
        TP2Permission.UserDelete,
        TP2Permission.UserEnableDisable,
        TP2Permission.UserList,
        TP2Permission.UserResetPassword,
      ],
    },
  },
  {
    path: 'listUser/:idProject',
    component: ListUsersComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        Roles.Admin,
        Roles.Investigator,
        TP2Permission.UserCreate,
        TP2Permission.UserUpdate,
        TP2Permission.UserDelete,
        TP2Permission.UserEnableDisable,
        TP2Permission.UserList,
        TP2Permission.UserResetPassword,
      ],
    },
  },
  {
    path: 'createUser',
    component: CreateUsersComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, TP2Permission.UserCreate],
    },
  },
  {
    path: 'activation/:username',
    component: ActivationUsersComponent,
  },
  {
    path: 'editUser',
    component: CreateUsersComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, TP2Permission.UserCreate],
    },
  },
  {
    path: 'newPassword',
    component: NewPasswordComponent,
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'resetPassword/:username',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
