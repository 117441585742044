<div class="header p-grid p-mb-3">
  <div class="p-col-12 p-px-0">
    <div class="p-col-12 p-md-6">
      <p class="activeText">
        {{
          'ediary.components.generalConfiguration.conciliation.configuration'
            | translate
        }}
      </p>
    </div>
  </div>
  <div class="grid p-col-12">
    <div>
      <div class="p-d-flex p-justify-between p-ai-center">
        <label
          for="cmb_activeConciliation"
          id="lbl_activeConciliation"
          class="p-mr-3"
        >
          {{
            'ediary.components.generalConfiguration.conciliation.activeConciationQuestion'
              | translate
          }}
        </label>
        <p-selectButton
          id="cmb_isConciliationEnabled"
          name="isConciliationEnabled"
          [options]="booleanOptions"
          [(ngModel)]="confEdiary.isConciliationRequired"
          (onChange)="isConciliationEnabledChange()"
          optionLabel="label"
          optionValue="value"
          required
        ></p-selectButton>
      </div>
      <div *ngIf="!confEdiary.isConciliationRequired">
        <small
          >{{
            'ediary.components.generalConfiguration.conciliation.checkingInformation'
              | translate
          }}
          <span style="font-weight: 700">{{ 'general.yes' | translate }}</span
          >,
          {{
            'ediary.components.generalConfiguration.conciliation.completeInformation'
              | translate
          }}</small
        >
      </div>
    </div>
    <div *ngIf="confEdiary.isConciliationRequired">
      <div class="p-d-flex p-justify-between p-ai-center">
        <label for="cmb_activeWindow" id="lbl_activeWindow" class="p-mr-3">
          {{
            'ediary.components.generalConfiguration.conciliation.activeWindow'
              | translate
          }}
        </label>
        <p-selectButton
          id="cmb_isWindowsConciliationEnabled"
          name="isWindowsConciliationEnabled"
          [options]="booleanOptions"
          [(ngModel)]="confEdiary.isWindowsConciliationRequired"
          (onChange)="isWindowsConciliationEnabledChange()"
          optionLabel="label"
          optionValue="value"
          required
        ></p-selectButton>
      </div>
      <div *ngIf="!confEdiary.isWindowsConciliationRequired">
        <small
          >{{
            'ediary.components.generalConfiguration.conciliation.checkingInformation'
              | translate
          }}
          <span style="font-weight: 700">{{ 'general.yes' | translate }}</span
          >,
          {{
            'ediary.components.generalConfiguration.conciliation.completeInformation'
              | translate
          }}</small
        >
      </div>
    </div>
  </div>
  <!--Modo de revisión-->
  <div
    class="grid p-col-12 assessment-container"
    *ngIf="confEdiary.isConciliationRequired"
  >
    <div class="p-col-12 p-field p-ai-center">
      <label class="p-d-block p-mb-2" for="drop_assessmentMode">
        {{
          'ediary.components.generalConfiguration.conciliation.assessmentMode.title'
            | translate
        }}
      </label>

      <small class="p-d-block p-mb-4">
        {{
          'ediary.components.generalConfiguration.conciliation.assessmentMode.subtitle'
            | translate
        }}</small
      >
      <ul class="assessment-mode">
        <ol>
          <strong
            >{{
              'ediary.components.generalConfiguration.conciliation.assessmentMode.enum.DETAILED_REVIEW'
                | translate
            }}:</strong
          >
          {{
            'ediary.components.generalConfiguration.conciliation.assessmentMode.detailedReviewInfo'
              | translate
          }}
        </ol>
        <ol>
          <strong
            >{{
              'ediary.components.generalConfiguration.conciliation.assessmentMode.enum.AUTOMATIC_REVIEW'
                | translate
            }}:</strong
          >
          {{
            'ediary.components.generalConfiguration.conciliation.assessmentMode.automaticReviewInfo'
              | translate
          }}
        </ol>
      </ul>
    </div>
    <div class="p-col-12 p-field p-ai-center">
      <p-dropdown
        id="drop_assessmentMode"
        name="drop_assessmentMode"
        optionLabel="label"
        optionValue="value"
        class="input p-col-12 p-p-0 p-mr-1"
        appendTo="body"
        [options]="assessmentModes"
        [(ngModel)]="confEdiary.assessmentMode"
        (onChange)="assessmentModelChange()"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
      >
      </p-dropdown>
    </div>
  </div>
</div>

<div class="p-grid p-mb-3">
  <div class="p-col-12">
    <div class="border"></div>
  </div>
</div>

<div
  class="p-grid"
  *ngIf="
    confEdiary.isConciliationRequired &&
    !confEdiary.isWindowsConciliationRequired
  "
>
  <div class="p-col-12 warning">
    <p-message
      class="warning"
      severity="warn"
      [text]="
        'ediary.components.generalConfiguration.conciliation.conciliationWarning'
          | translate
      "
    ></p-message>
  </div>
</div>
