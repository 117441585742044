import {
  ConfState,
  ModelBooleanInput,
  ModelFloatInput,
  ModelIDInput,
  ModelStringInput,
  ModelYNAInput,
  ModelYNInput,
  Site,
  TP2File,
  TP2FileInput,
  YN,
  YNA,
} from 'src/app/services/trialpal.types';
import {
  ConfDay,
  ConfEDiary,
  ConfForm,
  ConfSymptom,
  InjectionSide,
  InjectionSite,
  Intensity,
  IntensityType,
  Symptom,
  SymptomType,
} from '../ediary/ediary.types';
import { Project } from '../project/project.types';
import { ConfReport } from '../report/report.types';
import {
  EDiaryPhaseInstance,
  IntensityQuestionAnswer,
  Subject,
  SubjectState,
} from '../subjects/subjects.types';
import { UserEntityType } from '../user/user.types';

export type ConciliationLockInput = {
  user: string;
  date: string;
};

export type CreateCommentConciliationInstanceInput = {
  eDiaryPhaseInstanceId: string;
  id?: string | null;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateCommentConciliationInstanceInput = {
  eDiaryPhaseInstanceId?: string | null;
  id: string;
  comment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateEDiaryPhaseInstanceInput = {
  subjectId?: string | null;
  eDiaryInstanceId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  phase?: string | null;
  pdfFiles?: Array<TP2FileInput> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLockInput | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ConciliationLock = {
  __typename: 'ConciliationLock';
  user: string;
  date: string;
};

export type EDiaryInstance = {
  __typename: 'EDiaryInstance';
  subjectId: string;
  subject?: Subject | null;
  confEDiaryId: string;
  confEDiary?: ConfEDiary | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfEDiaryWindow = {
  __typename: 'ConfEDiaryWindow';
  name: string;
  phases: Array<string>;
  daysAfterVisit: number;
  totalWindowDays: number;
};

export enum OnDemandAlertType {
  DAYS = 'DAYS',
  WEEKLY = 'WEEKLY',
}

export type CreateVerifiedMedicationInstanceInput = {
  subjectId: string;
  verifiedDayInstanceId?: string | null;
  medicationInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelVerifiedMedicationInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  medicationInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  isOnGoing?: ModelBooleanInput | null;
  isOnGoingComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  name?: ModelStringInput | null;
  nameComment?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  reasonComment?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  symptomsInstancesComment?: ModelStringInput | null;
  givenTo?: ModelMedicationGivenToListInput | null;
  givenToComment?: ModelStringInput | null;
  dose?: ModelStringInput | null;
  doseComment?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  frequencyComment?: ModelStringInput | null;
  administrationRoute?: ModelStringInput | null;
  administrationRouteComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedMedicationInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedMedicationInstanceConditionInput | null> | null;
  not?: ModelVerifiedMedicationInstanceConditionInput | null;
};

export type CreateVerifiedDayInstanceInput = {
  subjectId: string;
  eDiaryPhaseInstanceId: string;
  confDayId: string;
  siteId: string;
  id?: string | null;
  dayInstanceId?: string | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateVerifiedDayInstanceInput = {
  subjectId?: string | null;
  eDiaryPhaseInstanceId?: string | null;
  confDayId?: string | null;
  siteId?: string | null;
  id: string;
  dayInstanceId?: string | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type CreateSignatureInstanceInput = {
  subjectId?: string | null;
  id?: string | null;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type SymptomOccurrencyInput = {
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};

export type ModelMedicationGivenToListInput = {
  eq?: Array<MedicationGivenTo | null> | null;
  ne?: Array<MedicationGivenTo | null> | null;
  contains?: MedicationGivenTo | null;
  notContains?: MedicationGivenTo | null;
};

export type VerifiedMedicationInstance = {
  __typename: 'VerifiedMedicationInstance';
  subjectId: string;
  subject?: Subject | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  medicationInstanceId?: string | null;
  medicationInstance?: MedicationInstance | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type MedicationInstance = {
  __typename: 'MedicationInstance';
  subjectId: string;
  subject?: Subject | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedMedicationInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  medicationInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedMedicationInstanceInput = {
  id: string;
  _version?: number | null;
};

export type ProjectTermsAndConditions = {
  __typename: 'ProjectTermsAndConditions';
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};

export type ConfTemperatureByConfEdiaryQuery = {
  __typename: 'ModelConfTemperatureConnection';
  items: Array<{
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfTemperatureByConfEdiaryCustomQuery = {
  __typename: 'ModelConfTemperatureConnection';
  items: Array<{
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    units?: Array<TemperatureUnit | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    showHelpScreen?: boolean | null;
    helpScreenText?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    buttonHelpScreenLabel?: string | null;
    allowExitToTakeTemperature?: boolean | null;
    alertExitToTakeTemperatureText?: string | null;
    celciusRanges?: {
      __typename: 'TemperatureRanges';
      minValue?: number | null;
      maxValue?: number | null;
      decimalPlaces?: number | null;
      expectedMinValue?: number | null;
      expectedMaxValue?: number | null;
      seriousMinValue?: number | null;
      seriousMaxValue?: number | null;
    } | null;
    fahrenheitRanges?: {
      __typename: 'TemperatureRanges';
      minValue?: number | null;
      maxValue?: number | null;
      decimalPlaces?: number | null;
      expectedMinValue?: number | null;
      expectedMaxValue?: number | null;
      seriousMinValue?: number | null;
      seriousMaxValue?: number | null;
    } | null;
    isExpectedRangeActive?: boolean | null;
    alertTextForUnexpectedValues?: string | null;
    isSeriousRangeActive?: boolean | null;
    alertTextForSeriousValues?: string | null;
    dataInputComponent?: DataInputComponent | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMedication = {
  __typename: 'ConfMedication';
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfMedicalAttention = {
  __typename: 'ConfMedicalAttention';
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelVerifiedMedicalAttentionInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  medicalAttentionInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelMedicalAttentionTypeInput | null;
  typeComment?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  reasonComment?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  symptomsInstancesComment?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  wasHospitalized?: ModelBooleanInput | null;
  wasHospitalizedComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedMedicalAttentionInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedMedicalAttentionInstanceConditionInput | null> | null;
  not?: ModelVerifiedMedicalAttentionInstanceConditionInput | null;
};

export type ModelInstanceStateInput = {
  eq?: InstanceState | null;
  ne?: InstanceState | null;
};

export type ModelMedicalAttentionTypeInput = {
  eq?: MedicalAttentionType | null;
  ne?: MedicalAttentionType | null;
};

export type UpdateVerifiedMedicalAttentionInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  medicalAttentionInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateVerifiedMedicalAttentionInstanceMutation = {
  __typename: 'VerifiedMedicalAttentionInstance';
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: 'VerifiedDayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: 'MedicalAttentionInstance';
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type VerifiedMedicalAttentionInstance = {
  __typename: 'VerifiedMedicalAttentionInstance';
  subjectId: string;
  subject?: Subject | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: MedicalAttentionInstance | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfMedicalAttentionInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ConfMedicalAttentionByConfEdiaryQuery = {
  __typename: 'ModelConfMedicalAttentionConnection';
  items: Array<{
    __typename: 'ConfMedicalAttention';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    isBindToSymptomOcurrencies?: boolean | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showHospitalization?: boolean | null;
    mode?: MedicalAttentionConfigurationMode | null;
    showPrecondition?: boolean | null;
    showType?: boolean | null;
    medicalAttentionTypes?: Array<MedicalAttentionType> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfTemperature = {
  __typename: 'ConfTemperature';
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  units?: Array<TemperatureUnit | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  showHelpScreen?: boolean | null;
  helpScreenText?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  allowExitToTakeTemperature?: boolean | null;
  alertExitToTakeTemperatureText?: string | null;
  celciusRanges?: TemperatureRanges | null;
  fahrenheitRanges?: TemperatureRanges | null;
  isExpectedRangeActive?: boolean | null;
  alertTextForUnexpectedValues?: string | null;
  isSeriousRangeActive?: boolean | null;
  alertTextForSeriousValues?: string | null;
  dataInputComponent?: DataInputComponent | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type TemperatureRanges = {
  __typename: 'TemperatureRanges';
  minValue?: number | null;
  maxValue?: number | null;
  decimalPlaces?: number | null;
  expectedMinValue?: number | null;
  expectedMaxValue?: number | null;
  seriousMinValue?: number | null;
  seriousMaxValue?: number | null;
};

export type CreateVerifiedMedicalAttentionInstanceInput = {
  subjectId: string;
  verifiedDayInstanceId?: string | null;
  medicalAttentionInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type CreateVerifiedMedicalAttentionInstanceMutation = {
  __typename: 'VerifiedMedicalAttentionInstance';
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: 'VerifiedDayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: 'MedicalAttentionInstance';
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfMedicalAttentionInput = {
  id: string;
  _version?: number | null;
};

export type ConfTemperatureByConfFormQuery = {
  __typename: 'ModelConfTemperatureConnection';
  items: Array<{
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CreateVerifiedTemperatureRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  subjectId: string;
  temperatureRecordLogID?: string | null;
  id?: string | null;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type TemperatureRecordLog = {
  __typename: 'TemperatureRecordLog';
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  reportInstanceId?: string | null;
  reportInstance?: ReportInstance | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ReportInstance = {
  __typename: 'ReportInstance';
  confReportId: string;
  confReport?: ConfReport | null;
  subjectId: string;
  subject?: Subject | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityType | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type SymptomOccurrency = {
  __typename: 'SymptomOccurrency';
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};

export type CreateVerifiedSymptomInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  confSymptomId?: string | null;
  symptomInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  occurrencyComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type IntensityQuestionAnswerInput = {
  question?: IntensityQuestionInput | null;
  answer?: boolean | null;
};

export type IntensityQuestionInput = {
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};

export type ModelVerifiedSymptomInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  symptomInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  whichOtherSymptom?: ModelStringInput | null;
  whichOtherSymptomComment?: ModelStringInput | null;
  intensity?: ModelIntensityInput | null;
  intensityComment?: ModelStringInput | null;
  size?: ModelFloatInput | null;
  sizeComment?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  isOtherSymptom?: ModelBooleanInput | null;
  medications?: ModelStringInput | null;
  medicationsComment?: ModelStringInput | null;
  medicalAttentions?: ModelStringInput | null;
  medicalAttentionsComment?: ModelStringInput | null;
  professionalHealthCare?: ModelYNAInput | null;
  professionalHealthCareComment?: ModelStringInput | null;
  hospitalAdmission?: ModelYNInput | null;
  hospitalAdmissionComment?: ModelStringInput | null;
  intensityQuestionAnswersComment?: ModelStringInput | null;
  rememberNoValuesTaken?: ModelBooleanInput | null;
  rememberNoValuesTakenComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedSymptomInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedSymptomInstanceConditionInput | null> | null;
  not?: ModelVerifiedSymptomInstanceConditionInput | null;
};

export type DeleteVerifiedSymptomInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedSymptomRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  verifiedSymptomInstanceId?: string | null;
  subjectId?: string | null;
  symptomRecordLogId?: string | null;
  id?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedSymptomRecordLogInput = {
  id: string;
  _version?: number | null;
};

export type SymptomInstance = {
  __typename: 'SymptomInstance';
  subjectId: string;
  subject?: Subject | null;
  reportInstanceId?: string | null;
  reportInstance?: ReportInstance | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  confSymptomId?: string | null;
  confSymptom?: ConfSymptom | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswer> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type IntensityScale = {
  __typename: 'IntensityScale';
  active: boolean;
  startValue: number;
  endValue: number;
  helpText: string;
  label?: string | null;
};

export type UpdateVerifiedSymptomRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  verifiedSymptomInstanceId?: string | null;
  subjectId?: string | null;
  symptomRecordLogId?: string | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type VerifiedSymptomInstance = {
  __typename: 'VerifiedSymptomInstance';
  subjectId?: string | null;
  subject?: Subject | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  confSymptomId?: string | null;
  confSymptom?: ConfSymptom | null;
  symptomInstanceId?: string | null;
  symptomInstance?: SymptomInstance | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswer | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type VerifiedSymptomRecordLog = {
  __typename: 'VerifiedSymptomRecordLog';
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: VerifiedSymptomInstance | null;
  subjectId?: string | null;
  subject?: Subject | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: SymptomRecordLog | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type SymptomRecordLog = {
  __typename: 'SymptomRecordLog';
  dayInstanceId: string;
  dayInstance?: DayInstance | null;
  symptomInstanceId: string;
  symptomInstance?: SymptomInstance | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type MedicationInstanceBySubjectIdQuery = {
  __typename: 'ModelMedicationInstanceConnection';
  items: Array<{
    __typename: 'MedicationInstance';
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UpdateVerifiedSymptomInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  confSymptomId?: string | null;
  symptomInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  occurrencyComment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelSymptomTypeInput = {
  eq?: SymptomType | null;
  ne?: SymptomType | null;
};

export type ModelSymptomInput = {
  eq?: Symptom | null;
  ne?: Symptom | null;
};

export type ModelInjectionSiteInput = {
  eq?: InjectionSite | null;
  ne?: InjectionSite | null;
};

export type ModelInjectionSideInput = {
  eq?: InjectionSide | null;
  ne?: InjectionSide | null;
};

export type ModelIntensityTypeInput = {
  eq?: IntensityType | null;
  ne?: IntensityType | null;
};

export type ModelIntensityInput = {
  eq?: Intensity | null;
  ne?: Intensity | null;
};

export type DayInstance = {
  __typename: 'DayInstance';
  subjectId: string;
  subject?: Subject | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: EDiaryPhaseInstance | null;
  confDayId: string;
  confDay?: ConfDay | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedTemperatureRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  subjectId?: string | null;
  temperatureRecordLogID?: string | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateVerifiedTemperatureRecordLogMutation = {
  __typename: 'VerifiedTemperatureRecordLog';
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: 'VerifiedDayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: 'TemperatureRecordLog';
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVerifiedTemperatureRecordLogMutation = {
  __typename: 'VerifiedTemperatureRecordLog';
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: 'VerifiedDayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: 'TemperatureRecordLog';
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type VerifiedTemperatureRecordLog = {
  __typename: 'VerifiedTemperatureRecordLog';
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  subjectId: string;
  subject?: Subject | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: TemperatureRecordLog | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type MedicalAttentionInstance = {
  __typename: 'MedicalAttentionInstance';
  subjectId: string;
  subject?: Subject | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type VerifiedDayInstance = {
  __typename: 'VerifiedDayInstance';
  subjectId: string;
  subject?: Subject | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: EDiaryPhaseInstance | null;
  confDayId: string;
  confDay?: ConfDay | null;
  siteId: string;
  site?: Site | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<SymptomOccurrency | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelSignatureInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  date?: ModelStringInput | null;
  type?: ModelSignatureTypeInput | null;
  reason?: ModelSignatureReasonInput | null;
  entityType?: ModelSignatureEntityTypeInput | null;
  entityId?: ModelStringInput | null;
  signedInfo?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSignatureInstanceFilterInput | null> | null;
  or?: Array<ModelSignatureInstanceFilterInput | null> | null;
  not?: ModelSignatureInstanceFilterInput | null;
};

export type ModelSignatureTypeInput = {
  eq?: SignatureType | null;
  ne?: SignatureType | null;
};

export type ModelSignatureReasonInput = {
  eq?: SignatureReason | null;
  ne?: SignatureReason | null;
};

export type ModelSignatureEntityTypeInput = {
  eq?: SignatureEntityType | null;
  ne?: SignatureEntityType | null;
};

export enum SignatureType {
  BIOMETRICS = 'BIOMETRICS',
  USER_PASS = 'USER_PASS',
}

export enum SignatureReason {
  AUTHORSHIP = 'AUTHORSHIP',
  APPROVAL = 'APPROVAL',
  REVIEW = 'REVIEW',
}

export enum SignatureEntityType {
  EDIARY = 'EDIARY',
  REPORT = 'REPORT',
  ECONSENT = 'ECONSENT',
}

export enum InstanceState {
  PENDING = 'PENDING',
  MUST_UPDATE = 'MUST_UPDATE',
  ON_GOING = 'ON_GOING',
  NOT_SENDED = 'NOT_SENDED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  UNSIGNED = 'UNSIGNED',
  DELETED = 'DELETED',
  CONCILIATION_DELETED = 'CONCILIATION_DELETED',
  SUSPENDED = 'SUSPENDED',
  LOCALLY_SAVED = 'LOCALLY_SAVED',
  CONCILIATION_COMPLETED = 'CONCILIATION_COMPLETED',
}

export enum DayRule {
  MIN = 'MIN',
  MAX = 'MAX',
  AVERAGE = 'AVERAGE',
}

export enum TemperatureRoute {
  ORAL = 'ORAL',
  RECTAL = 'RECTAL',
  AXILLARY = 'AXILLARY',
  EAR = 'EAR',
  OTHER = 'OTHER',
}

export enum HelpScreenDisplayOption {
  ON_DEMAND = 'ON_DEMAND',
  PREVIOUS_TO_FORM = 'PREVIOUS_TO_FORM',
  WITH_SYMPTOM_OCCURRENCY = 'WITH_SYMPTOM_OCCURRENCY',
  SKIP_EDITION = 'SKIP_EDITION',
}

export enum DataInputComponent {
  TEXT_INPUT = 'TEXT_INPUT',
  SPINNER = 'SPINNER',
  DROPDOWN = 'DROPDOWN',
}

export enum TemperatureUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT',
}

export enum MedicationGivenTo {
  TREAT = 'TREAT',
  PREVENT = 'PREVENT',
}

export enum ReportProgramationType {
  ON_DEMAND = 'ON_DEMAND',
  PLANNED_DAYS = 'PLANNED_DAYS',
}

export enum ReportAvailableUserType {
  SUBJECT = 'SUBJECT',
  INVESTIGATOR = 'INVESTIGATOR',
}

export enum MedicalAttentionConfigurationMode {
  OPEN_REASON = 'OPEN_REASON',
  SYMPTOM_BINDING = 'SYMPTOM_BINDING',
  NO_REASON = 'NO_REASON',
}

export enum MedicalAttentionType {
  ER = 'ER',
  MEDICAL_APPONTMENT = 'MEDICAL_APPONTMENT',
  TELEMEDICINE = 'TELEMEDICINE',
  HOSPITALIZATION = 'HOSPITALIZATION',
}

export enum MedicationConfigurationMode {
  GENERAL_GIVEN_TO_OPEN_REASON = 'GENERAL_GIVEN_TO_OPEN_REASON',
  INDIVIDUAL_GIVEN_TO_OPEN_REASON = 'INDIVIDUAL_GIVEN_TO_OPEN_REASON',
  GENERAL_GIVEN_TO_SYMPTOM_BINDING = 'GENERAL_GIVEN_TO_SYMPTOM_BINDING',
  INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING = 'INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING',
  NO_GIVEN_TO_OPEN_REASON = 'NO_GIVEN_TO_OPEN_REASON',
  NO_GIVEN_TO_SYMPTOM_BINDING = 'NO_GIVEN_TO_SYMPTOM_BINDING',
  NO_GIVEN_NO_REASON = 'NO_GIVEN_NO_REASON',
}

export type ReconciliationConfig = {
  __typename: 'ReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptions | null;
  allowAdditionalQuestionsReconciliation?: boolean | null;
};

export type IntensityReconciliationOptions = {
  __typename: 'IntensityReconciliationOptions';
  allow?: boolean | null;
  allowedAction?: IntensityReconciliationOption | null;
};

export enum IntensityReconciliationOption {
  ANY = 'ANY',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}
export type ReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptionsInput | null;
  allowAdditionalQuestionsReconciliation?: boolean | null;
};

export type IntensityReconciliationOptionsInput = {
  allow?: boolean | null;
  allowedAction?: IntensityReconciliationOption | null;
};
