import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ReportService } from '../report.service';
import { FormPreviewComponent } from './form-preview/form-preview.component';
import { Field, FormlyDisplay } from './interfaces/formly-designer.interface';
import { JsonViewComponent } from './json-view/json-view.component';
import { ConfReport, UpdateConfReportInput } from '../report.types';

@Component({
  selector: 'app-formly-designer',
  templateUrl: './formly-designer.component.html',
  styleUrls: ['./formly-designer.component.scss'],
})
export class FormlyDesignerComponent implements OnInit {
  @Input() projectId: string = '';
  @Input() confReport!: ConfReport;
  @Output() confReportChange: EventEmitter<ConfReport> = new EventEmitter();
  @ViewChild('jsonEditor') jsonEditor!: JsonViewComponent;
  formFieldDesginer: any = {}; //Donde se almacenará el JSON para el diseñador
  formFieldJson: string = ''; //El JSON que se mostrará en el editor JSON
  formFieldToPreview: any = {}; //
  formFieldInit: Field = {
    key: 'designer',
    fieldGroup: [
      {
        wrappers: [FormlyDisplay.CARD],
        key: '',
        templateOptions: {
          label: '',
          isMain: true,
        },
        fieldGroup: [],
        validation: {
          messages: {
            required: '',
          },
        },
      },
    ],
  };

  isSaving: boolean = false;
  isJsonError: boolean = false;
  isFormFieldInvalid: boolean = false;
  constructor(
    private dialogService: DialogService,
    private confReportService: ReportService,
    private trialpalservice: TrialpalService
  ) {}

  ngOnInit() {
    try {
      const jsonForm = JSON.parse(this.confReport.jsonForm ?? '[]');
      this.formFieldToPreview = jsonForm[0] ?? this.formFieldInit;
    } catch (error) {
      this.formFieldToPreview = this.formFieldInit;
    }

    this.formFieldDesginer = { ...this.formFieldToPreview };
    this.formFieldJson = JSON.stringify(this.formFieldToPreview);
    this.isFormFieldInvalid = this.getIsFormFieldInvalid();
  }

  openPreviewComponent() {
    this.dialogService.open(FormPreviewComponent, {
      width: '70%',
      dismissableMask: true,
      closeOnEscape: true,
      data: {
        formField: this.formFieldToPreview,
      },
      styleClass: 'modal-rounded',
      baseZIndex: 3000,
    });
  }

  async formFieldDesignerChange(formField: any) {
    this.jsonEditor.locked = true;
    this.formFieldJson = JSON.stringify(formField);
    this.formFieldToPreview = JSON.parse(JSON.stringify(formField));
    this.jsonEditor.activateAction('editor.action.formatDocument');
    this.isFormFieldInvalid = this.getIsFormFieldInvalid();
    await this.wait700ms();
    this.jsonEditor.locked = false;
  }

  async formFieldJSONChange(formField: string) {
    try {
      this.isSaving = true;
      await this.wait700ms();
      this.formFieldDesginer = JSON.parse(formField);
      this.formFieldToPreview = JSON.parse(formField);
      this.isFormFieldInvalid = this.getIsFormFieldInvalid();
      this.isSaving = false;
      this.isJsonError = false;
    } catch (error) {
      this.isSaving = false;
      this.isJsonError = true;
    }
  }

  async wait700ms() {
    return new Promise((resolve) => setTimeout(() => resolve(true), 700));
  }

  async saveForm(): Promise<void> {
    this.trialpalservice.showSpinner(
      this.trialpalservice.translateService.instant('general.savingInfo')
    );
    this.confReport.jsonForm = JSON.stringify([this.formFieldToPreview]);
    const input: UpdateConfReportInput = {
      id: this.confReport.id ?? '',
      jsonForm: JSON.stringify([this.formFieldToPreview]),
      _version: this.confReport._version,
    };
    this.confReport = await this.confReportService.updateConfReportInstance(
      input
    );
    this.confReportChange.emit(this.confReport);
    this.trialpalservice.hideSpinner();
  }

  /**
   * Verifica si el formulario contiene algún campo no válido.
   * @returns Verdadero si el formulario contiene campos no válidos, de lo contrario, falso.
   */
  getIsFormFieldInvalid() {
    const fieldGroup = this.formFieldDesginer?.fieldGroup[0];
    if (!fieldGroup) return true;
    if (!fieldGroup?.fieldGroup?.length) return true;
    const countEmptyFieldGroups = this.getCountEmptyFieldGroups(
      this.formFieldDesginer,
      0
    );
    if (countEmptyFieldGroups > 0) return true;
    return false;
  }

  /**
   * Recorre recursivamente una configuración JSON y cuenta el número de grupos vacíos.
   * @param configuration La configuración JSON a ser examinada.
   * @param emptyGroupsCount El contador de grupos vacíos (debe comenzar en 0).
   * @returns El número total de grupos vacíos encontrados en la configuración.
   */
  getCountEmptyFieldGroups(configuration: any, emptyGroupsCount: number) {
    for (let key in configuration) {
      if (key === 'fieldGroup' || key === 'fieldArray') {
        const fieldGroup =
          key === 'fieldGroup'
            ? configuration[key]
            : configuration[key]?.fieldGroup;
        if (!fieldGroup?.length) {
          emptyGroupsCount++;
        } else {
          for (const group of fieldGroup) {
            emptyGroupsCount = this.getCountEmptyFieldGroups(
              group,
              emptyGroupsCount
            );
          }
        }
      }
    }

    return emptyGroupsCount;
  }
}
