<div class="p-grid">
  <app-button-refresh-data
    [showSpinner]="showSpinner"
    (buttonAction)="ngOnInit()"
  ></app-button-refresh-data>
  <app-button-table-card-mode
    dataSource="subjectMedicationsList"
    class="p-col-11"
    *ngIf="medications && medications.length > 0 && !showSpinner"
    (cardOrTableModeEmit)="tableViewMode = $event"
  ></app-button-table-card-mode>
</div>

<app-tour-guide-button
  [pageTourDataType]="DETAIL_SUBJECT_MEDICATION_TOUR"
  *ngIf="medications?.length && !showSpinner"
></app-tour-guide-button>

<section *ngIf="!showSpinner">
  <div
    *ngIf="medications && medications.length > 0"
    id="subjectMedication_container"
  >
    <section *ngIf="!tableViewMode">
      <div class="p-grid p-fluid">
        <app-tpcard
          class="p-col-12 p-md-4 p-lg-3"
          *ngFor="let p of dataCard; let i = index"
          [tpCardData]="p"
          [id]="'medicationCard' + i"
        ></app-tpcard>
      </div>
    </section>
    <section *ngIf="tableViewMode" class="p-mb-5">
      <app-tptable
        (customAction1)="
          cardOrTableService.openAuditModal({
            data: $event.data,
            header: $event.name,
            entity: $event.entity
          })
        "
        [timeZoneOffset]="timeZoneOffset"
        [dataTable]="dataTable"
        id="medicationCard"
      ></app-tptable>
    </section>
  </div>

  <div class="p-grid p-fluid" *ngIf="medications.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</section>
<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
