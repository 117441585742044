import { Injectable } from '@angular/core';
import { Logger } from '@aws-amplify/core';
import { TranslateService } from '@ngx-translate/core';
import { API, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { InstanceState, TP2IdEntity } from 'src/app/services/trialpal.types';
import { INTEGRATIONS_API } from 'src/app/shared/global.variables';
import { RequestParamsBuilder } from 'src/app/shared/utils/requestParmasBuilder';
import { tp2ManagePDFAndAlertLogInput } from '../ediary/ediary.types';
import {
  ReportAvailableUserType,
  ReportInstance,
} from '../report/report.types';
import { TP2UserInput } from '../user/user.types';
import { SUBJECT_QUERIES } from './subjects.queries';
import {
  CreateSubjectInput,
  CustomEDiaryPhaseInstanceBySubjectIdQuery,
  DayInstanceByEDiaryPhaseInstanceCustomQuery,
  GetConfAlertQuery,
  GetDayInstanceQuery,
  GetSubjectQuery,
  MediaRecordLogBySubjectIdQuery,
  MedicalAttentionInstanceBySubjectIdQuery,
  MedicationInstanceBySubjectIdQuery,
  ModelMediaRecordLogFilterInput,
  ModelMedicalAttentionInstanceFilterInput,
  ModelMedicationInstanceFilterInput,
  ModelReportInstanceFilterInput,
  ModelSymptomInstanceFilterInput,
  ModelTemperatureRecordLogFilterInput,
  ScheduledPhaseState,
  SubjectState,
  SymptomInstanceBySubjectIdQuery,
  SymptomRecordLogBysymptomInstanceIdQuery,
  TP2SubjectInput,
  UpdateSubjectInput,
  UpdateSubjectMutation,
} from './subjects.types';
const logger = new Logger('tp2-logger-SubjectService');
@Injectable({
  providedIn: 'root',
})
export class SubjectsService {
  subjects: any[] = [];
  pdfGeneratedOnSite: { reportInstanceId: string | undefined } | undefined =
    undefined;
  private paramsBuilder: RequestParamsBuilder = new RequestParamsBuilder('');
  constructor(
    private translateService: TranslateService,
    private auth: AuthService
  ) {}

  async getSubjectsByProjectId(projectId: string): Promise<any[]> {
    this.subjects = await this.listProjectSubjects(projectId);
    return this.subjects;
  }
  async getSubjectsBySiteId(siteId: string): Promise<any[]> {
    let subjects: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SUBJECT_QUERIES.SubjectsBySiteId,
        { siteId }
      );
      subjects = subjects.concat(listQuery.data.subjectsBySiteId.items);
      nextToken = listQuery.data.subjectsBySiteId.nextToken ?? undefined;
    } while (nextToken);
    return subjects;
  }

  async getSearchSubjectsByProjectId(projectId: string): Promise<any[]> {
    return this.listSubjectNumbers(projectId);
  }

  async listSubjectNumbers(projectId: string): Promise<any[]> {
    const rolSites = this.auth.getUserSites();
    const subjects = await this.getSubjectSearchByProjectIdCustom(projectId);
    if (this.auth.isInvestigator() || this.auth.isReader()) {
      return subjects.filter((subject) => rolSites.includes(subject.siteId));
    }
    return subjects;
  }

  async getSubjectByProjectIdCustom(projectId: string): Promise<any[]> {
    let subjects: any[] = [];
    let nextToken: string | undefined = undefined;
    const filter = {
      state: {
        ne: SubjectState.DELETED,
      },
    };
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SUBJECT_QUERIES.SubjectByProjectIdCustom,
        { projectId, filter, limit: 1000, nextToken }
      );
      subjects = subjects.concat(listQuery.data.subjectByProjectId.items);
      nextToken = listQuery.data.subjectByProjectId.nextToken;
      logger.debug(listQuery.data.subjectByProjectId.items);
    } while (nextToken);
    return subjects.filter(Boolean).filter((s: any) => !s._deleted);
  }

  async listProjectSubjects(projectId: string): Promise<any[]> {
    const rolSites = this.auth.getUserSites();
    const subjects = await this.getSubjectByProjectIdCustom(projectId);
    if (this.auth.isInvestigator() || this.auth.isReader()) {
      return subjects.filter((subject) => rolSites.includes(subject.siteId));
    }
    return subjects;
  }

  async getSubjectSearchByProjectIdCustom(projectId: string): Promise<any[]> {
    let subjects: any[] = [];
    let nextToken: string | undefined = undefined;
    const filter = {
      state: {
        ne: SubjectState.DELETED,
      },
    };
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SUBJECT_QUERIES.SubjectSearchByProjectIdCustom,
        { projectId, filter, limit: 1000, nextToken }
      );
      subjects = subjects.concat(listQuery.data.subjectByProjectId.items);
      nextToken = listQuery.data.subjectByProjectId.nextToken;
      logger.debug(listQuery.data.subjectByProjectId.items);
    } while (nextToken);
    return subjects.filter(Boolean).filter((s: any) => !s._deleted);
  }

  async getSubject(id: string): Promise<GetSubjectQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetSubject,
      {
        id,
      }
    );
    return response.data.getSubject;
  }
  async medicationInstanceBySubjectId(
    id: string
  ): Promise<MedicationInstanceBySubjectIdQuery> {
    const filter: ModelMedicationInstanceFilterInput = {
      or: [
        { state: { eq: InstanceState.COMPLETED } },
        { state: { eq: InstanceState.ON_GOING } },
      ],
    };
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.MedicationInstanceBySubjectIdCustom,
      { subjectId: id, filter, limin: 10000 }
    );
    return response.data.medicationInstanceBySubjectId;
  }
  async medicalAttentionInstanceBySubjectId(
    id: string
  ): Promise<MedicalAttentionInstanceBySubjectIdQuery> {
    const filter: ModelMedicalAttentionInstanceFilterInput = {
      or: [
        { state: { eq: InstanceState.COMPLETED } },
        { state: { eq: InstanceState.ON_GOING } },
      ],
    };
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.MedicalAttentionInstanceBySubjectIdCustom,
      { subjectId: id, filter, limit: 1000 }
    );
    return response.data.medicalAttentionInstanceBySubjectId;
  }
  async ConfMedicalAttentionByConfForm(id: string): Promise<any> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.ConfMedicalAttentionByConfForm,
      { confFormId: id }
    );
    return response.data.confMedicalAttentionByConfForm;
  }
  async confMedicationByConfForm(id: string): Promise<any> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.ConfMedicationByConfForm,
      { confFormId: id }
    );
    return response.data.confMedicationByConfForm;
  }
  async confTemperatureByConfForm(id: string): Promise<any> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.ConfTemperatureByConfForm,
      { confFormId: id }
    );
    return response.data.confTemperatureByConfForm;
  }
  async mediaRecordLogBySubjectId(
    id: string
  ): Promise<MediaRecordLogBySubjectIdQuery> {
    const filter: ModelMediaRecordLogFilterInput = {
      state: { eq: InstanceState.COMPLETED },
    };
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.MediaRecordLogBySubjectId,
      { subjectId: id, filter, limit: 1000 }
    );
    return response.data.mediaRecordLogBySubjectId;
  }
  async symptomInstanceBySubjectId(
    id: string
  ): Promise<SymptomInstanceBySubjectIdQuery> {
    const filter: ModelSymptomInstanceFilterInput = {
      or: [
        { state: { eq: InstanceState.COMPLETED } },
        { state: { eq: InstanceState.ON_GOING } },
      ],
    };
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.SymptomInstanceBySubjectId,
      { subjectId: id, filter, limit: 10000 }
    );
    return response.data.SymptomInstanceBySubjectId;
  }
  async symptomRecordLogBysymptomInstanceId(
    id: string
  ): Promise<SymptomRecordLogBysymptomInstanceIdQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.SymptomRecordLogBysymptomInstanceId,
      { symptomInstanceId: id }
    );
    return response.data.symptomRecordLogBysymptomInstanceId;
  }

  async symptomInstanceById(id: string) {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetSymptomInstance,
      { id }
    );
    return response.data.getSymptomInstance;
  }
  async reportInstanceBySubjectId(id: string): Promise<any> {
    const filter: ModelReportInstanceFilterInput = {
      state: { ne: InstanceState.DRAFT },
    };
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SUBJECT_QUERIES.CustomReportInstanceBySubjectId,
        { subjectId: id, filter, limit: 1000, nextToken }
      );
      instances = instances.concat(
        listQuery.data.reportInstanceBySubjectId.items
      );
      nextToken =
        listQuery.data.reportInstanceBySubjectId.nextToken ?? undefined;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  //Escuchará los reportes creados desde el site
  OnUpdateReportInstanceListener(subjectId: string) {
    return (
      this.performGraphQLQuery(
        SUBJECT_QUERIES.OnUpdateReportInstance,
        {}
      ) as any
    )
      .filter((response: any) => {
        const value = response.value.data.onUpdateReportInstance;
        const reportInstance = value?.value?.data
          ?.onUpdateReportInstance as ReportInstance;

        //Valida si se esta en verdadero (Es la ultima actualización que se hace)
        const isAlertChecked = reportInstance?.isAlertChecked ?? false;
        //Filtra los reportes que pertenezcan al sujeto y hayan sido completados desde la app
        return (
          reportInstance.subjectId === subjectId &&
          reportInstance.reportedBy?.userType ===
            ReportAvailableUserType.SUBJECT &&
          isAlertChecked
        );
      })
      .map((value: any) => value?.value?.data?.onUpdateReportInstance);
  }

  async eDiaryPhaseInstanceBySubjectId(
    subjectId: any
  ): Promise<CustomEDiaryPhaseInstanceBySubjectIdQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.CustomEDiaryPhaseInstanceBySubjectId,
      { subjectId }
    );
    return response.data.eDiaryPhaseInstanceBySubjectId;
  }
  async dayInstanceByEDiaryPhaseInstance(id: any): Promise<any> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.DayInstanceByEDiaryPhaseInstanceCustom,
      { eDiaryPhaseInstanceId: id }
    );
    return response.data.dayInstanceByEDiaryPhaseInstance;
  }
  async subjectEnroll(
    subject: TP2SubjectInput,
    users: TP2UserInput[],
    project: TP2IdEntity,
    site: TP2IdEntity
  ): Promise<string | null> {
    const params = await this.paramsBuilder
      .withBody({ subject, users, project, site })
      .build();
    return API.post(INTEGRATIONS_API, '/subjects/enroll', params);
  }
  async tp2SubjectDelete(
    subjectNumber: string,
    projectCode: string,
    _changeReason: string
  ): Promise<any> {
    const params = await this.paramsBuilder
      .withBody({ subjectNumber, projectCode, _changeReason })
      .build();
    return API.post(INTEGRATIONS_API, '/subjects/delete', params);
  }
  async updateSubject(
    data: CreateSubjectInput,
    id: string,
    expectedVersion: any
  ): Promise<UpdateSubjectMutation> {
    let updateInput: UpdateSubjectInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.UpdateSubject,
      { input: updateInput }
    );
    return response.data.updateSubject;
  }
  getVisitStates(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('visit.enums.visitStates');
    const listTooltip = this.translateService.instant('subject.statesTooltip');
    Object.keys(ScheduledPhaseState).forEach((e) => {
      data.push({ label: list[e], value: e, tooltip: listTooltip[e] });
    });
    return data;
  }
  getSubjectStates(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('subject.enums.subjectStates');
    const listTooltip = this.translateService.instant(
      'subject.tooltipSubjectState'
    );
    Object.keys(SubjectState).forEach((state) => {
      if (state !== SubjectState.DELETED) {
        data.push({
          label: list[state],
          value: state,
          tooltip: listTooltip[state],
        });
      }
    });
    return data;
  }
  getStateReport(): any[] {
    const State: any = {
      planned: '',
      completed: '',
    };
    const data: any[] = [];
    const list = this.translateService.instant('transforStateVisitPipe');
    Object.keys(State).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }
  getPeriodCompleteReport(): any[] {
    const PeriodComplete: any = {
      '1W': '',
      '2W': '',
      '1M': '',
      '2M': '',
      '6M': '',
      '1Y': '',
      '5Y': '',
    };
    const data: any[] = [];
    const list = this.translateService.instant('subject.PeriodComplete');
    Object.keys(PeriodComplete).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
  }
  getPeriodPlannedReport(): any[] {
    const PeriodPlanned: any = {
      '1W': '',
      '2W': '',
      '1M': '',
      '2M': '',
      '6M': '',
      '1Y': '',
      '5Y': '',
    };
    const data: any[] = [];
    const list = this.translateService.instant('subject.PeriodPlanned');
    Object.keys(PeriodPlanned).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
  }
  async getUserSubjectsByNumber(
    subjectNumbers: string[],
    selectedSites: string[]
  ): Promise<any> {
    let items: any[] = [];
    for (const site of selectedSites) {
      for (const sn of subjectNumbers) {
        const response = await this.performGraphQLQuery(
          SUBJECT_QUERIES.SubjectBySubjectNumberAndSite,
          {
            subjectNumber: sn,
            siteId: {
              eq: site,
            },
          }
        );
        const arr = response.data.subjectBySubjectNumberAndSite;
        if (arr.items) {
          items = items.concat(
            arr.items.filter((x: any) => (x ? !x._deleted : false))
          );
        }
      }
    }
    return Promise.resolve(items);
  }
  async completeVisit(
    subjectNumber: string,
    projectCode: string,
    date: string,
    phase: string
  ): Promise<any> {
    const params = await this.paramsBuilder
      .withBody({ subjectNumber, projectCode, date, phase })
      .build();
    return API.post(INTEGRATIONS_API, '/subjects/completeVisit', params);
  }
  async listTemperatureRecordLogs(id: string): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const filter: ModelTemperatureRecordLogFilterInput = {
      or: [{ state: { eq: InstanceState.COMPLETED } }],
    };
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SUBJECT_QUERIES.TemperatureRecordLogBySubjectIdCustom,
        { subjectId: id, filter, limit: 1000, nextToken }
      );
      instances = instances.concat(
        listQuery.data.temperatureRecordLogBySubjectId.items
      );
      nextToken = listQuery.data.temperatureRecordLogBySubjectId.nextToken;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  async getConfSymptom(id: string): Promise<any> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetConfSymptom,
      { id }
    );
    return response.data.getConfSymptom;
  }

  async getDayInstance(id: string): Promise<GetDayInstanceQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetDayInstance,
      { id }
    );
    return response.data.getDayInstance;
  }

  async getReportInstance(id: string): Promise<GetDayInstanceQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetReportInstance,
      { id }
    );
    return response.data.getReportInstance;
  }

  async getAlertInstanceBySubjectId(id: string): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SUBJECT_QUERIES.AlertInstanceBySubjectIdCustom,
        { subjectId: id, limit: 1000, nextToken }
      );
      instances = instances.concat(
        listQuery.data.alertInstanceBySubjectId.items
      );
      nextToken = listQuery.data.alertInstanceBySubjectId.nextToken;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  async changeGroup(
    subjectNumber: string,
    projectCode: string,
    group: string,
    _changeReason: string
  ): Promise<any> {
    const params = await this.paramsBuilder
      .withBody({ subjectNumber, projectCode, group, _changeReason })
      .build();
    return API.post(INTEGRATIONS_API, '/subjects/changeGroup', params);
  }
  /**
   *
   * @param data projectId, siteId,id , tag y _changeReason
   * @param sujectId identificador del sujeto
   * @param version version
   * @returns actualizacion de la etiqueta del sujeto
   */
  async changeTag(
    data: UpdateSubjectInput,
    sujectId: string,
    version: any
  ): Promise<UpdateSubjectMutation> {
    let update: UpdateSubjectInput = {
      id: sujectId,
      _version: version,
    };
    update = Object.assign(update, data);
    update._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.UpdateSubject,
      { input: update }
    );
    return response.data.updateSubject;
  }
  async changeState(
    subjectNumber: string,
    projectCode: string,
    state: string,
    _changeReason: string
  ): Promise<any> {
    const params = await this.paramsBuilder
      .withBody({ subjectNumber, projectCode, state, _changeReason })
      .build();
    return API.post(INTEGRATIONS_API, '/subjects/changeState', params);
  }

  async managePDFandAlert(eDiaryPhaseInstance: any, reportInstanceId?: string) {
    const tp2ManagePDFAndAlertLogInput: tp2ManagePDFAndAlertLogInput = {
      source: 'SITE',
      eDiaryPhaseInstanceId: eDiaryPhaseInstance?.id ?? '',
      subjectId: eDiaryPhaseInstance?.subjectId ?? '',
      reportInstanceId: reportInstanceId ?? '',
    };

    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.Tp2ManagePDFAndAlertLog,
      { input: tp2ManagePDFAndAlertLogInput }
    );
    console.error('response', response);
    return response.data.tp2ManagePDFAndAlertLog;
  }

  async createEDiaryCardPDFConsolidate(subjectId: any) {
    const subject = {
      id: subjectId,
      phase: '',
    };
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.Tp2CreateEDiaryCardPDF,
      { subject, version: 'Web version' }
    );
    return response.data.tp2CreateEDiaryCardPDF;
  }
  async tp2EDiarySuspension(
    eDiaryPhaseInstanceId: string,
    suspensionDate: string,
    suspensionReason: string
  ) {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.Tp2EDiarySuspension,
      { eDiaryPhaseInstanceId, suspensionDate, suspensionReason }
    );
    return response.data.tp2EDiarySuspension;
  }
  async createEDiaryConciliationPDF(
    subjectId: string,
    ediaryPhaseInstanceId: string
  ) {
    const subject = {
      id: subjectId,
      phase: ediaryPhaseInstanceId,
    };
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.Tp2CreateEDiaryConciliationPDF,
      { subject, version: 'Web version' }
    );
    return response.data.tp2CreateEDiaryConciliationPDF;
  }

  async getDayInstanceByEDiaryPhase(
    eDiaryPhase: any
  ): Promise<DayInstanceByEDiaryPhaseInstanceCustomQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.DayInstanceByEDiaryPhaseInstanceCustom,
      { eDiaryPhaseInstanceId: eDiaryPhase }
    );
    return response.data.dayInstanceByEDiaryPhaseInstance;
  }
  //Le coloca timezone a una fecha que no se cree con este
  setDateWithTimezone(currentDate: any) {
    const newDate = new Date();
    currentDate = new Date(currentDate);
    const hour = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();
    currentDate.setHours(currentDate.getHours() + hour);
    currentDate.setMinutes(currentDate.getMinutes() + minutes);
    currentDate.setSeconds(currentDate.getSeconds() + seconds);
    return new Date(currentDate).toISOString();
  }
  async createReportCardPDFConsolidate(subjectId: any, confReportId: any) {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.Tp2CreateReportCardPDF,
      { subjectId, confReportId }
    );
    return response.data.tp2CreateReportCardPDF;
  }
  orderInstancesDescending(instances: any, sortBy: string = 'createdAt') {
    return instances?.length > 0
      ? [...instances].sort((a, b) => {
          if (sortBy === 'createdAt') {
            return Date.parse(b.createdAt) - Date.parse(a.createdAt);
          } else if (sortBy === 'updatedAt') {
            return Date.parse(b.updatedAt) - Date.parse(a.updatedAt);
          } else {
            throw new Error("sortBy debe ser 'createdAt' o 'updatedAt'");
          }
        })
      : [];
  }

  checkConciliationLock(ediaryPhaseInstance: any, username: string) {
    let isConciliationLocked = false;
    if (
      ediaryPhaseInstance?.locked?.user &&
      ediaryPhaseInstance?.locked?.user !== username
    ) {
      isConciliationLocked = true;
    }

    return isConciliationLocked;
  }
  async getConfAlert(id: string): Promise<GetConfAlertQuery> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetConfAlert,
      { id }
    );
    return response.data.getConfAlert;
  }

  /**
   * Obtiene el día actual (0 - domingo, 1 - lunes, 2 - martes, etc).
   * @returns El día actual de la semana.
   */
  getCurrentDay() {
    return moment().day();
  }

  //Retorna si la fecha actual esta entre la fechas del diario '2024-03-23' <= '2024-03-24' <= '2024-03-24'
  isInTheDateRange(startDate: any, finishDate: any): boolean {
    if (!startDate || !finishDate) return false;
    const currentDate = moment().format('YYYY-MM-DD');
    return moment(currentDate).isBetween(startDate, finishDate, null, '[]');
  }

  private performGraphQLQuery(
    query: any,
    args: any
  ): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
