<form name="form" autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="scroll-conciliation p-pr-2">
    <div class="p-ai-center" *ngIf="isShowPrecondition">
      <div class="p-mb-3 full-width">
        <div class="p-mb-3">
          <label
            id="lbl_verfiedPrecondiction"
            name="lbl_verfiedPrecondiction"
            for="verfiedPrecondiction"
          >
            <span *ngIf="configuration?.label">
              {{ configuration.label | dictionary }}
              <span class="inputRequired"> * </span></span
            >
            <span *ngIf="configuration?.preconditionLabel">
              {{ configuration.preconditionLabel | dictionary }}
              <span class="inputRequired"> * </span></span
            >
            <span
              *ngIf="!configuration?.preconditionLabel && !configuration?.label"
            >
              {{ 'general.configurations.enums.precondition' | translate }}
              <span class="inputRequired"> * </span></span
            >
          </label>
          <p-selectButton
            id="verfiedPrecondiction"
            formControlName="hasInstance"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            [ngClass]="{
              disabled: shouldDisablePrecondition(),
            }"
          ></p-selectButton>
          <small
            class="form-error p-col-12 p-pl-0"
            *ngIf="formHasInstance()?.invalid && formHasInstance()?.touched"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
        <div
          class="p-grid p-mb-3 p-ai-center full-width"
          *ngIf="changeReasonHasInstance()"
        >
          <label
            id="lbl_hasInstanceComment"
            name="lbl_hasInstanceComment"
            class="p-col-12"
            for="hasInstanceComment"
          >
            {{ 'conciliation.titleObservation' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <textarea
            id="hasInstanceComment"
            formControlName="hasInstanceComment"
            class="p-col"
            pInputTextarea
            type="text"
            [autoResize]="true"
            placeholder="{{
              'conciliation.placeholderObservation' | translate
            }}"
          ></textarea>
          <small
            class="form-error p-col-12"
            *ngIf="
              formHasInstanceComment()?.invalid &&
              formHasInstanceComment()?.touched
            "
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
      </div>
    </div>
    <div class="p-ai-center" *ngIf="isGivenToEnabled">
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedGivenTo"
          name="labelVerifiedGivenTo"
          for="inputVerifiedGivenTo"
        >
          {{ 'medicationinstance.givenTo' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-multiSelect
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          class="full-width"
          id="inputVerifiedGivenTo"
          formControlName="medicationGivenTo"
          [options]="givenTo"
          optionLabel="label"
          optionValue="value"
          [ngClass]="{
            disabled: enabledGivenTo(),
          }"
        >
        </p-multiSelect>
        <small
          class="form-error p-col-12"
          *ngIf="
            formMedicationGivenTo?.invalid && formMedicationGivenTo?.touched
          "
        >
          {{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="changeReasonMedicationGivenTo"
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
        >
          {{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedGivenToComment"
          formControlName="medicationGivenToComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            formMedicationGivenToComment?.invalid &&
            formMedicationGivenToComment?.touched
          "
        >
          {{ 'general.required' | translate }}</small
        >
      </div>
    </div>
  </div>
  <app-form-buttons
    (nextData)="isNext = $event"
    (previousData)="previousDay()"
    [isNextDisabled]="form.invalid"
  ></app-form-buttons>
</form>
