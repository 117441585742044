<div class="container">
  <div class="header p-pl-0" (click)="toogleMenu()">
    <i
      [ngClass]="expanded ? 'pi pi-angle-right' : 'pi pi-angle-left'"
      style="color: #000000"
      aria-hidden="true"
    ></i>
    <label *ngIf="expanded">{{ 'general.actions' | translate }}</label>
  </div>
  <div class="body" [class.active]="expanded">
    <p-button
      *ngFor="let menu of menuItems"
      (click)="!menu?.disabled && menu.command && menu?.command(menu)"
      [disabled]="menu?.disabled ?? false"
      [class]="menu.className"
    >
      <ng-template let-button pTemplate="content">
        <img [src]="menu.icon" alt="Menu action" />
        <span>{{ expanded ? menu.label : '' }}</span>
      </ng-template>
    </p-button>
  </div>
</div>

<app-delete-confirmation
  [header]="
    'informedConsent.followUp.table.rowDetail.modalCancelConsentTitle'
      | translate
  "
  [description]="
    'informedConsent.followUp.table.rowDetail.modalCancelConsentDescription'
      | translate
  "
  [btnDeleteText]="
    'informedConsent.followUp.table.rowDetail.modalCancelConsentButton'
      | translate
  "
  [(display)]="displayDialog"
  (confirm)="cancelConsent()"
  position="fixed"
>
</app-delete-confirmation>

<div class="card flex justify-content-center" *ngIf="isExecutionSummaryVisible">
  <p-sidebar
    [(visible)]="isExecutionSummaryVisible"
    position="right"
    [style]="{ width: '35%' }"
    [transitionOptions]="'500ms'"
  >
    <header class="title p-mb-6">
      <div class="p-d-flex">
        <button
          pButton
          pRipple
          type="button"
          class="p-button-text p-p-0 rm-border-button"
        >
          <img
            alt="logo"
            src="assets/icons/informed-consent/x.svg"
            style="width: 3.5rem"
            (click)="closeExecutionSummary()"
          />
        </button>
        <h1>
          {{
            'informedConsent.followUp.executionSummary.executionSummaryTitle'
              | translate
          }}
        </h1>
      </div>
      <p>
        {{
          'informedConsent.followUp.executionSummary.executionSummaryInfo'
            | translate
        }}
      </p>
    </header>

    <div class="p-d-flex p-jc-between" style="gap: 1rem">
      <div class="full-width">
        <h4 class="p-mb-2 blackColor">
          {{
            'informedConsent.followUp.executionSummary.readingTime' | translate
          }}
        </h4>
        <section *ngFor="let consentedUsers of econsentData?.consentedUsers">
          <div
            class="p-mb-3 container2"
            [ngClass]="getExecutionSummaryContainerStyle(consentedUsers)"
          >
            <div class="header2 m-b-0 p-b-0">
              <div class="back_button p-d-flex p-ai-center p-justify-between">
                <div class="p-mr-3">
                  <div class="p-d-flex p-ai-center">
                    <h3 class="p-my-2 blackColor">
                      {{ consentedUsers?.user?.login }}
                    </h3>
                    <img
                      src="
                      assets/icons/informed-consent/elipse.svg
                    "
                      alt="signed"
                      class="p-ml-2"
                      *ngIf="consentedUsers.state === 'SIGNED_BY_PARTICIPANT'"
                    />
                  </div>
                </div>
                <div class="p-text-right">
                  <h4 class="blackColor p-mb-0 p-mt-2">
                    {{ getExecutionSummaryStateByUser(consentedUsers) }}
                  </h4>
                  <p class="p-mt-0 p-mb-0">
                    {{ getExecutionSummaryStarDate(consentedUsers) }}
                  </p>
                  <p class="p-mt-0 p-mb-2">
                    {{ getExecutionSummaryFinishDate(consentedUsers) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </p-sidebar>
</div>
