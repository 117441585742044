import { CALCULATE_TYPES } from '../interfaces/formly-designer.interface';

export enum FieldType {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  MULTICHECKBOX = 'MULTICHECKBOX',
  MULTIRADIO = 'MULTIRADIO',
  MULTIRADIO_IMAGE = 'MULTIRADIO_IMAGE',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  SUBTITLE = 'SUBTITLE',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  TITLE = 'TITLE',
  GROUP = 'GROUP',
}

export enum DesignerField {
  checkbox = 'checkbox',
  number = 'number',
  date = 'date',
  text = 'text',
  textArea = 'textarea',
  email = 'email',
  multicheckbox = 'multicheckbox',
  radio = 'radio',
  radio_grid = 'radio-grid',
  radio_image = 'radio-image',
  select = 'select',
  subtitle = 'subtitle-text',
  title = 'title',
  group = 'group',
}

const ASSETS_URL = '/assets/icons/designer';

export const DesignerFieldTypes: DesignerFieldTypes[] = [
  {
    name: 'designer.fieldTypes.basic',
    options: [
      {
        type: FieldType.CHECKBOX,
        icon: `${ASSETS_URL}/checkbox.svg`,
        label: '',
        value: {
          key: '',
          type: DesignerField.checkbox,
          wrappers: ['validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            required: undefined,
            disabled: undefined,
            calculateType: CALCULATE_TYPES.NO_CALCULATE_VALUE,
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.DATE,
        icon: `${ASSETS_URL}/calendar.svg`,
        value: {
          key: '',
          type: 'input',
          wrappers: ['label', 'validation'],
          templateOptions: {
            type: DesignerField.date,
            label: '',
            isPdfVisible: undefined,
            required: undefined,
            disabled: undefined,
            calculateType: CALCULATE_TYPES.NO_CALCULATE_VALUE,
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.GROUP,
        icon: `${ASSETS_URL}/square.svg`,
        value: {
          templateOptions: {
            label: '',
            score: undefined,
            type: DesignerField.group,
            isPdfVisible: undefined,
          },
          fieldGroup: [
            {
              key: '',
              fieldGroup: [],
              validation: {
                messages: {
                  required: '',
                },
              },
            },
          ],
          customExpression: {},
          hideExpression: '',
        },
      },
      {
        type: FieldType.NUMBER,
        icon: `${ASSETS_URL}/hash-outline.svg`,
        value: {
          key: '',
          type: 'input',
          wrappers: ['label', 'validation'],
          templateOptions: {
            type: DesignerField.number,
            label: '',
            isPdfVisible: undefined,
            placeholder: '',
            required: undefined,
            disabled: undefined,
            calculateType: '',
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.RADIO,
        icon: `${ASSETS_URL}/radio-button-on.svg`,
        value: {
          key: '',
          type: DesignerField.radio,
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            pdfViewType: undefined,
            placeholder: '',
            required: undefined,
            disabled: undefined,
            options: [{ label: '', value: '' }],
            calculateType: '',
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.SELECT,
        icon: `${ASSETS_URL}/chevron-down.svg`,
        value: {
          key: '',
          type: DesignerField.select,
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            pdfViewType: undefined,
            placeholder: '',
            required: undefined,
            disabled: undefined,
            options: [{ label: '', value: '' }],
            calculateType: '',
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.SUBTITLE,
        icon: `${ASSETS_URL}/text.svg`,
        value: {
          type: DesignerField.subtitle,
          templateOptions: {
            label: '',
            required: undefined,
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.TEXTAREA,
        icon: `${ASSETS_URL}/square.svg`,
        value: {
          key: '',
          type: DesignerField.textArea,
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            placeholder: '',
            required: undefined,
            disabled: undefined,
            calculateType: CALCULATE_TYPES.NO_CALCULATE_VALUE,
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.TEXT,
        icon: `${ASSETS_URL}/document-text.svg`,
        value: {
          key: '',
          type: 'input',
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            type: DesignerField.text,
            placeholder: '',
            required: undefined,
            disabled: undefined,
            calculateType: CALCULATE_TYPES.NO_CALCULATE_VALUE,
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.TITLE,
        icon: `${ASSETS_URL}/text.svg`,
        value: {
          type: DesignerField.title,
          templateOptions: {
            label: '',
            required: false,
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
    ],
  },
  {
    name: 'designer.fieldTypes.avanced',
    options: [
      {
        type: FieldType.MULTIRADIO_IMAGE,
        icon: `${ASSETS_URL}/image.svg`,
        value: {
          key: '',
          type: DesignerField.radio_image,
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            pdfViewType: undefined,
            required: undefined,
            disabled: undefined,
            options: [{ label: '', value: '' }],
            //No existe en el tipado, es un atributo custom
            images: [null],
            calculateType: '',
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.MULTICHECKBOX,
        icon: `${ASSETS_URL}/checkbox.svg`,
        value: {
          key: '',
          type: DesignerField.multicheckbox,
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            pdfViewType: undefined,
            tooltip: '',
            required: undefined,
            disabled: undefined,
            options: [{ label: '', value: '' }],
            calculateType: '',
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
      {
        type: FieldType.MULTIRADIO,
        icon: `${ASSETS_URL}/radio-button-on.svg`,
        value: {
          key: '',
          type: DesignerField.radio_grid,
          wrappers: ['label', 'validation'],
          templateOptions: {
            label: '',
            isPdfVisible: undefined,
            pdfViewType: undefined,
            required: undefined,
            disabled: undefined,
            options: [
              {
                label: '',
                value: '',
              },
            ],
            questions: [
              {
                label: '',
                tooltip: '',
              },
            ],
            calculateType: '',
          },
          validation: {
            messages: {
              required: '',
            },
          },
          customExpression: { key: '', operator: '', value: '' },
          hideExpression: '',
        },
      },
    ],
  },
];

export interface DesignerFieldTypes {
  name: string;
  options: any[];
}
