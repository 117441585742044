<form
  name="form"
  class="consent-form"
  (ngSubmit)="onSubmit(f)"
  #f="ngForm"
  autocomplete="off"
>
  <header class="title">
    <h2>
      {{ 'informedConsent.followUp.assignParticipantToAnotherIC' | translate }}
    </h2>
    <p>
      {{
        'informedConsent.followUp.assignParticipantToAnotherICInfo' | translate
      }}
    </p>
  </header>

  <p class="p-mt-0 p-mb-4 subtitle">
    <span class="p-pr-2">{{ 'informedConsent.step1' | translate }}</span>
    <span>{{
      'informedConsent.followUp.assignInformedConsent.step1Description'
        | translate
    }}</span>
  </p>
  <div class="grid-form mb-5">
    <div>
      <label for="informedConsentType"
        >{{ 'informedConsent.followUp.assignInformedConsent.newIC' | translate
        }}<span class="inputRequired"> * </span></label
      >
      <p-dropdown
        appendTo="body"
        placeholder="{{
          'informedConsent.followUp.assignInformedConsent.selectICForAssign'
            | translate
        }}"
        [options]="confInformedConsents"
        optionLabel="label"
        optionValue="value"
        id="informedConsentType"
        name="informedConsentType"
        #informedConsentType="ngModel"
        [(ngModel)]="confInformedConsentSelectId"
        [required]="true"
        (onChange)="onChangeConfInformedConsentSelectId()"
        [showClear]="true"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="
          informedConsentType.invalid &&
          (f.submitted || informedConsentType.touched)
        "
      >
        {{ 'general.required' | translate }}
      </small>
    </div>
  </div>

  <p class="p-mt-0 p-mb-4 subtitle">
    <span class="p-pr-2">{{ 'informedConsent.step2' | translate }}</span>
    <span>{{
      'informedConsent.followUp.assignInformedConsent.step2Description'
        | translate
    }}</span>
  </p>

  <div class="grid-form mb-5">
    <div class="p-input-icon-right">
      <span class="pi pi-search search-icon"></span>
      <input
        type="text"
        #search="ngModel"
        [(ngModel)]="consentedIdentificatorSearch"
        (input)="applySearchFilter()"
        id="search_text"
        name="search_text"
        pInputText
        [disabled]="!confInformedConsentSelectId"
        [placeholder]="
          'informedConsent.followUp.assignInformedConsent.searchUsersForAssign'
            | translate
        "
      />
    </div>
  </div>

  <p-table
    #dt
    *ngIf="confInformedConsentSelectId"
    [value]="existingUsersTableFilter"
    [columns]="tableColumns"
    [rows]="10"
    [paginator]="true"
    responsiveLayout="scroll"
    [scrollable]="true"
    [(selection)]="selectedUsers"
    currentPageReportTemplate="{{ 'general.paginatorValues' | translate }}"
    [showCurrentPageReport]="true"
    dataKey="row"
    [rowHover]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    selectionMode="single"
    [filterDelay]="0"
  >
    <!--HEADER TABLE-->
    <ng-template pTemplate="header" let-columns>
      <tr class="header">
        <th scope="col" style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th
          id="{{ col.field }}"
          *ngFor="let col of columns"
          pSortableColumn="{{ col.field }}"
        >
          <span>{{ col.header }}</span>
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user>
      <tr>
        <td>
          <p-tableCheckbox [value]="user"></p-tableCheckbox>
        </td>

        <td [pTooltip]="user.login.data">
          <span>{{ user.login.data }}</span>
        </td>
        <td [pTooltip]="user.name.data">
          <span>{{ user.name.data }}</span>
        </td>
        <td [pTooltip]="user.email.data">
          <span>{{ user.email.data }}</span>
        </td>
        <td [pTooltip]="user.phoneNumber.data">
          <span>{{ user.phoneNumber.data }}</span>
        </td>
        <td [pTooltip]="user.relationship.data">
          <span *ngIf="!user.relationship.isError">{{
            user.relationship.data
          }}</span>
        </td>
        <td [pTooltip]="user.identifier.data">
          <span>{{ user.identifier.data }}</span>
        </td>
        <td [pTooltip]="user.informedConsentsName.data">
          <span>{{ user.informedConsentsName.data }}</span>
        </td>
        <td [pTooltip]="user.notificationPreference.data">
          <span *ngIf="!user.notificationPreference.isError">{{
            user.notificationPreference.data
          }}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="p-d-flex p-jc-end">
    <div class="p-mr-2">
      <button
        pButton
        type="submit"
        class="btn btn-info"
        icon="pi pi-check"
        label="{{ 'informedConsent.followUp.btnSaveUsers' | translate }}"
        [disabled]="selectedUsers.length === 0 || f.invalid"
      ></button>
    </div>
  </div>
</form>
