<div class="container p-text-center">
  <div class="content">
    <img
      src="../../../../assets/imgs/maintenance.svg"
      alt="Ventana de mantenimiento"
    />
    <label for="maintenance-page" class="p-text-center full-width">
      {{ 'general.labelManteinance' | translate }}
    </label>
    <p class="p-text-center">
      {{ 'general.labelMaintenanceMessage.prefix' | translate }}
      <strong>{{ 'general.labelMaintenanceMessage.app' | translate }}</strong>
      {{
        'general.labelMaintenanceMessage.sufix' | translate: { hour: endTime }
      }}
    </p>
  </div>
</div>
