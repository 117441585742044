import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-card',
  template: `
    <div class="p-flex p-flex-column" style="gap: 0.5rem">
      <p-accordion [activeIndex]="0">
        <p-accordionTab
          [header]="step.templateOptions?.label ?? '' | dictionary"
          *ngFor="
            let step of field.fieldGroup;
            let index = index;
            let last = last
          "
        >
          <formly-field [field]="step"></formly-field>
        </p-accordionTab>
      </p-accordion>
    </div>
  `,
})
export class FormlyFieldCardComponent extends FieldType implements OnInit {
  public dataStoreToggle: { [key: number]: boolean } = {};

  ngOnInit(): void {
    if (this.field?.fieldGroup) {
      for (let i = 0; i < this.field.fieldGroup.length; i++) {
        this.dataStoreToggle[i] = false;
      }
      if (this.field.fieldGroup.length > 0) this.toggleSection(0);
    }
  }

  toggleSection(index: number) {
    this.dataStoreToggle[index] = !this.dataStoreToggle[index];
  }
}
