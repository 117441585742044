<form class="form" #reportForm="ngForm">
  <header>
    <h3 class="p-mt-0 p-mb-3 title">
      {{ 'report.labelJsonForm' | translate }}
    </h3>
  </header>
  <section class="section p-mb-3">
    <formly-form
      [fields]="fields | dictionaryField"
      [form]="form"
      [model]="model"
      [options]="options"
    ></formly-form>
    <!--Obtiene la auditoria para el cambio de un campo del formulario-->
    <div
      class="field"
      *ngIf="reportExecutionService.isReportInstanceEdition && isFormChange"
    >
      <change-reason
        name="report_reason"
        [label]="'audit.reason' | translate"
        [(ngModel)]="changeReason"
        [required]="true"
        #changeReasonTemplate="ngModel"
      ></change-reason>
      <small
        class="form-error p-col-12"
        *ngIf="changeReasonTemplate?.invalid && changeReasonTemplate.touched"
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </section>

  <app-pagination-button
    (next)="goToNextPage()"
    (previous)="goToPreviousPage()"
    [nextDisabled]="form.invalid || (reportForm.invalid ?? false)"
    [previousDisabled]="
      reportExecutionService.currentPage === reportExecutionService.pages[0]
    "
  ></app-pagination-button>
</form>
