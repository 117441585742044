import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Dictionary } from '../table-languages/dictionary';
import { DictionaryService } from '../table-languages/dictionary.service';
import { TableLanguagesComponent } from '../table-languages/table-languages.component';
const logger = new Logger('tp2-logger-dictionaryComponent');

@Component({
  selector: 'app-simple-dictionary-input',
  templateUrl: './simple-dictionary-input.component.html',
  styleUrls: ['./simple-dictionary-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SimpleDictionaryInputComponent implements OnChanges {
  dictionaryItemSelected: any = {
    key: '',
    spanish: '',
    english: '',
  };
  dictionary: any[] = [];
  results: string[] = [];

  @Input() projectId: string = '';
  @Input() isReadOnly: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() placeholder: string = '';
  @Input() submitted: boolean = false;
  @Input() id: string = 'dictionaryInput_' + new Date().getTime();
  @Input() name: string = 'dictionaryInput_' + new Date().getTime();
  @Input() value: any = '';
  @Input() tooltip: string = '';
  @Input() shortText: number = 110;
  @Output() outputEvent = new EventEmitter();

  constructor(
    private readonly dictionaryService: DictionaryService,
    private readonly trialpalService: TrialpalService
  ) {}

  async ngOnChanges() {
    await this.loadDictionary();
    this.selectKeyLanguage(this.value);
  }

  search(event: any) {
    this.dictionaryItemSelected = {
      key: '',
      spanish: '',
      english: '',
    };
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  selectKeyLanguage(event: any) {
    this.dictionary.forEach((data) => {
      if (data.key === event) {
        this.dictionaryItemSelected = {
          key: data.key,
          spanish: data.spanish,
          english: data.english,
        };
      }
    });
  }

  onDictionaty(): void {
    const ref = this.trialpalService.dialogService.open(
      TableLanguagesComponent,
      {
        data: {
          projectId: this.projectId,
          isForm: true,
        },
        width: '95%',
        height: '95%',
      }
    );

    ref.onClose.subscribe(async (data) => {
      await this.loadDictionary();
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        try {
          this.selectKeyLanguage(data.key);
          this.value = data.key;
          this.emitChanges();
          this.trialpalService.hideSpinner();
          this.trialpalService.messageService.add({
            severity: 'success',
            summary: this.trialpalService.translateService.instant(
              'dictionary.selectTranslate'
            ),
            detail: this.trialpalService.translateService.instant(
              'dictionary.succesSelectTranslate'
            ),
          });
        } catch (err) {
          logger.error('No se pudo cargar el diccionario', err);
        }
      } else {
        this.selectKeyLanguage(this.dictionaryItemSelected.key);
      }
    });
  }

  async loadDictionary() {
    this.dictionary = [];
    const dictionaryData = JSON.parse(
      localStorage.getItem('Dictionary') ?? '{items: []}'
    );

    dictionaryData.items?.forEach((item: any) => {
      const obj: Dictionary = {
        id: item?.id ?? '',
        key: item?.key!,
        isHtml: item?.isHtml ?? false,
        spanish: item?.spanish ?? '',
        english: item?.english ?? '',
      };
      this.dictionary.push(obj);
    });
  }

  emitChanges(): void {
    this.outputEvent.emit(this.value);
  }
}
