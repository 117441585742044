import { Auth } from 'aws-amplify';
export class RequestParamsBuilder {
  private headers: Record<string, string> = {};
  private body: Record<string, any> = {};
  private token: string;

  constructor(token: string) {
    this.token = token;
  }
  updateToken(token: string) {
    this.token = token;
  }

  withHeader(key: string, value: string): this {
    this.headers[key] = value;
    return this;
  }

  withBody(data: Record<string, any>): this {
    this.body = data;
    return this;
  }

  async build(): Promise<{
    headers: Record<string, string>;
    body: Record<string, any>;
  }> {
    const response = await Auth.currentSession();
    const data = response.getAccessToken().getJwtToken();
    if (data !== this.token) {
      this.token = data;
    }
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.token,
        ...this.headers,
      },
      body: this.body,
    };
  }
}
