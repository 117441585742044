export const ROLES_QUERIES = {
  ListRoles: `query ListRoles($filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
        listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            isEnabled
            state
            permissions
            isForProjects
            projects
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  CreateRole: `mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
        createRole(input: $input, condition: $condition) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  UpdateRole: `mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
        updateRole(input: $input, condition: $condition) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  GetRole: `query GetRole($id: ID!) {
        getRole(id: $id) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,

  ListUsers: `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        notificationPreference
        subjects
        sites
        projects
        appVersion
        lastTimezone
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
};
