export const AUTH_QUERIES = {
  UserByLogin: `query UserByLogin($login: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByLogin(login: $login, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  GetUser: `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  Tp2TermsAndPolicies: `query Tp2TermsAndPolicies($version: String) {
        tp2TermsAndPolicies(version: $version)
      }`,
  OnUpdateUser: `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
};

export enum UserState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
