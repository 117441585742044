export const CONCILIATION_QUERIES = {
  DayInstanceByEDiaryPhaseInstanceCustom: `query DayInstanceByEDiaryPhaseInstanceCustom($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
    dayInstanceByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        subject {
          __typename
          subjectNumber
          id
          state
          projectId
          siteId
          _deleted
        }
        eDiaryPhaseInstanceId
        confDayId
        confDay {
          __typename
          dayName
          order
          confFormId
          startDay
          endDay
          hidePDFDayColumn
          _deleted
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            temperatureReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowIntensityReconciliation {
                allow
                allowedAction
              }
              allowRouteReconciliation
              allowOcurencyDateReconciliation
            }
            isMedicationRequired
            medicationReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowDosisReconciliation
              allowOcurencyDateReconciliation
              allowRouteReconciliation
              allowTreatmentReconciliation
              allowReasonReconciliation
            }
            isMedicalAttentionRequired
            medicalAttentionReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowTypeReconciliation
              allowSymptomOccurencyReconciliation
              allowOcurencyDateReconciliation
              allowHospitalizationReconciliation
              allowReasonReconciliation
            }
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomsReconciliationConfig {
              __typename
              allowAddRecords
              allowRemoveRecords
              allowOcurencyDateReconciliation
              allowIntensityReconciliation {
                __typename
                allow
                allowedAction
              }
            }
            localSymptomsReconciliationConfig {
              __typename
              allowAddRecords
              allowRemoveRecords
              allowOcurencyDateReconciliation
              allowIntensityReconciliation {
                __typename
                allow
                allowedAction
              }
            }
            otherSymptomsReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowOcurencyDateReconciliation
              allowHospitalizationReconciliation
              allowIntensityReconciliation {
                allow
                allowedAction
              }
            }
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
        siteId
        site {
          __typename
          projectId
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        state
        startDate
        finishDate
        completedDate
        userIP
        gps
        appVersion
        confDayVersion
        hasMedication
        medicationGivenTo
        hasMedicalAttention
        hasOtherSymptoms
        symptomOcurrencies {
          __typename
          confSymptomId
          symptomInstanceId
          symptomRecordLogId
          occurrency
          order
        }
        isAlertChecked
        isPDFGenerated
        comments
        _lastUser
        _deviceModel
        _deviceSOVersion
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,
  VerifiedDayInstanceByEdiaryPhaseIdCustom: `query VerifiedDayInstanceByEdiaryPhaseIdCustom($eDiaryPhaseInstanceId: ID!) {
    verifiedDayInstancesByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId) {
      __typename
      items {
        __typename
        symptomOcurrencies {
          __typename
          confSymptomId
          occurrency
          order
          symptomInstanceId
          symptomRecordLogId
        }
        symptomOcurrenciesComment
        subjectId
        state
        startDate
        siteId
        medicationGivenToComment
        medicationGivenTo
        id
        hasOtherSymptomsComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        hasMedicalAttentionComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        finishDate
        eDiaryPhaseInstanceId
        dayInstanceId
        createdAt
        confDayId
        _version
        _lastUser
        _deleted
        _changeReason
        completedDate
      }
    }
  }`,
  SignaturesBySubjectId: `query SignaturesBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSignatureInstanceFilterInput, $limit: Int, $nextToken: String) {
    signaturesBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        id
        date
        type
        reason
        entityType
        entityId
        signedInfo
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetEDiaryPhaseInstance: `query GetEDiaryPhaseInstance($id: ID!) {
    getEDiaryPhaseInstance(id: $id) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      eDiaryInstanceId
      eDiaryInstance {
        __typename
        subjectId
        confEDiaryId
        siteId
        id
        state
        confEdiaryVersion
        comment
        alerts
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      phase
      pdfFiles {
        __typename
        uploadDate
        fileUrl
      }
      pdfState
      completedPhaseDate
      suspensionReason
      suspensionDate
      suspensionUser
      locked {
        __typename
        user
        date
      }
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  SymptomInstanceByDayInstanceId: `query SymptomInstanceByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
    SymptomInstanceByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        reportInstanceId
        dayInstanceId
        confSymptomId
        id
        order
        state
        type
        symptom
        whichOtherSymptom
        intensity
        size
        rememberStartDate
        startDate
        rememberFinishDate
        finishDate
        _deviceModel
        _deviceSOVersion
        isOtherSymptom
        medications
        medicalAttentions
        professionalHealthCare
        hospitalAdmission
        createdAt
        rememberNoValuesTaken
        _lastUser
        _changeReason
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetSymptomInstance: `query GetSymptomInstance($id: ID!) {
    getSymptomInstance(id: $id) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      reportInstanceId
      reportInstance {
        __typename
        confReportId
        subjectId
        siteId
        id
        state
        plannedDate
        completeDate
        json
        score
        gps
        appVersion
        userIP
        reportConfVersion
        isAlertChecked
        isPDFGenerated
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dayInstanceId
      dayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        state
        startDate
        finishDate
        completedDate
        userIP
        gps
        appVersion
        confDayVersion
        hasMedication
        medicationGivenTo
        hasMedicalAttention
        hasOtherSymptoms
        isAlertChecked
        isPDFGenerated
        comments
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      order
      state
      type
      symptom
      whichOtherSymptom
      intensity
      size
      rememberStartDate
      startDate
      rememberFinishDate
      finishDate
      _deviceModel
      _deviceSOVersion
      isOtherSymptom
      medications
      medicalAttentions
      professionalHealthCare
      hospitalAdmission
      createdAt
      intensityQuestionAnswers {
        __typename
        answer
      }
      rememberNoValuesTaken
      _lastUser
      _changeReason
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  VerifiedSymptomInstanceBySymptomInstanceIdCustom: `query VerifiedSymptomInstanceBySymptomInstanceIdCustom($symptomInstanceId: ID!) {
    VerifiedSymptomInstanceBySymptomInstanceId(symptomInstanceId: $symptomInstanceId) {
      __typename
      items {
        __typename
        confSymptomId
        _version
        _lastUser
        _lastChangedAt
        _deleted
        _changeReason
        createdAt
        finishDate
        finishDateComment
        hospitalAdmission
        hospitalAdmissionComment
        id
        state
        intensity
        intensityQuestionAnswers {
          __typename
          answer
          question {
            __typename
            id
            intensity
            order
            question
          }
        }
        intensityQuestionAnswersComment
        isOtherSymptom
        medicalAttentions
        medicalAttentionsComment
        medications
        medicationsComment
        professionalHealthCare
        professionalHealthCareComment
        rememberFinishDate
        rememberFinishDateComment
        rememberNoValuesTaken
        rememberNoValuesTakenComment
        rememberStartDate
        rememberStartDateComment
        size
        sizeComment
        startDate
        startDateComment
        subjectId
        symptom
        symptomInstanceId
        type
        verifiedDayInstanceId
        whichOtherSymptom
        whichOtherSymptomComment
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,
  VerifiedSymptomRecordLogsByVerifiedSymptomInstanceId: `query VerifiedSymptomRecordLogsByVerifiedSymptomInstanceId($verifiedSymptomInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
    verifiedSymptomRecordLogsByVerifiedSymptomInstanceId(verifiedSymptomInstanceId: $verifiedSymptomInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        verifiedDayInstanceId
        verifiedSymptomInstanceId
        subjectId
        symptomRecordLogId
        id
        intensity
        intensityComment
        size
        sizeComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfSymptomByConfFormId: `query ConfSymptomByConfFormId($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String) {
    confSymptomByConfFormId(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confFormId
        confSymptomId
        projectId
        id
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetConfSymptom: `query GetConfSymptom($id: ID!) {
    getConfSymptom(id: $id) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      type
      symptom
      order
      injectionSite
      injectionSide
      showHelp
      symptomHelp
      symptomLabel
      symptomIcon
      decimalPlaces
      minSize
      maxSize
      intensityType
      intensitySizeLabel
      noneIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lowIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      mediumIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      highIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lifeThreateningScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      intensityQuestions {
        __typename
        id
        order
        question
        intensity
      }
      isIntensityQuestionsRequired
      isIntensityRequired
      isStartDateRequired
      isFinishDateRequired
      showNoValuesTaken
      noValuesTakenLabel
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  SymptomRecordLogBysymptomInstanceId: `query SymptomRecordLogBysymptomInstanceId($symptomInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
    symptomRecordLogBysymptomInstanceId(symptomInstanceId: $symptomInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        dayInstanceId
        symptomInstanceId
        state
        subjectId
        id
        intensity
        size
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetSymptomRecordLogsBySubjectIdCustom: `query GetSymptomRecordLogsBySubjectIdCustom($subjectId: ID!) {
    symptomRecordLogBySubjectId(subjectId: $subjectId) {
      __typename
      items {
        __typename
        dayInstanceId
        symptomInstanceId
        state
        subjectId
        id
        intensity
        size
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }`,
  SymptomInstanceBySubjectIdCustom: `query SymptomInstanceBySubjectIdCustom($subjectId: ID!) {
    SymptomInstanceBySubjectId(subjectId: $subjectId) {
      __typename
      items {
        __typename
        _version
        _lastUser
        _lastChangedAt
        _deviceSOVersion
        _deviceModel
        _deleted
        _changeReason
        confSymptom {
          __typename
          _changeReason
          _deleted
          _lastChangedAt
          _lastUser
          _version
          confEdiaryId
          confReportId
          createdAt
          decimalPlaces
          highIntensityScaleOptions {
            __typename
            active
            endValue
            helpText
            label
            startValue
          }
          id
          injectionSide
          injectionSite
          intensityType
          intensitySizeLabel
          isFinishDateRequired
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          intensityQuestions {
            __typename
            id
            intensity
            order
            question
          }
          lifeThreateningScaleOptions {
            __typename
            active
            endValue
            helpText
            label
            startValue
          }
          lowIntensityScaleOptions {
            __typename
            active
            endValue
            helpText
            label
            startValue
          }
          mediumIntensityScaleOptions {
            __typename
            active
            endValue
            helpText
            label
            startValue
          }
          noValuesTakenLabel
          noneIntensityScaleOptions {
            __typename
            active
            endValue
            helpText
            startValue
            label
          }
          order
          projectId
          showHelp
          showNoValuesTaken
          symptom
          symptomHelp
          updatedAt
          type
          symptomLabel
          symptomIcon
        }
        confSymptomId
        createdAt
        dayInstanceId
        finishDate
        id
        hospitalAdmission
        intensity
        isOtherSymptom
        medicalAttentions
        medications
        order
        professionalHealthCare
        rememberFinishDate
        intensityQuestionAnswers {
          __typename
          answer
          question {
            __typename
            id
            intensity
            order
            question
          }
        }
        rememberNoValuesTaken
        rememberStartDate
        reportInstanceId
        size
        startDate
        state
        subjectId
        symptom
        type
        updatedAt
        whichOtherSymptom
      }
    }
  }`,
  VerifiedSymptomInstanceBySubjectIdCustom: `query VerifiedSymptomInstanceBySubjectIdCustom($subjectId: ID!) {
    verifiedSymptomInstanceBySubjectId(subjectId: $subjectId) {
      __typename
      items {
        __typename
        confSymptomId
        _version
        _lastUser
        _lastChangedAt
        _deleted
        _changeReason
        createdAt
        finishDate
        finishDateComment
        hospitalAdmission
        hospitalAdmissionComment
        id
        state
        intensity
        intensityComment
        intensityQuestionAnswers {
          __typename
          answer
          question {
            __typename
            id
            intensity
            order
            question
          }
        }
        intensityQuestionAnswersComment
        isOtherSymptom
        medicalAttentions
        medicalAttentionsComment
        medications
        medicationsComment
        professionalHealthCare
        professionalHealthCareComment
        rememberFinishDate
        rememberFinishDateComment
        rememberNoValuesTaken
        rememberNoValuesTakenComment
        rememberStartDate
        rememberStartDateComment
        size
        sizeComment
        startDate
        startDateComment
        subjectId
        symptom
        symptomInstanceId
        type
        verifiedDayInstanceId
        whichOtherSymptom
        whichOtherSymptomComment
        occurrencyComment
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfSymptomByConfFormIdCustom: `query ConfSymptomByConfFormIdCustom($confFormId: ID!) {
    confSymptomByConfFormId(confFormId: $confFormId) {
      __typename
      items {
        __typename
        confFormId
        _version
        _lastUser
        _lastChangedAt
        _deleted
        _changeReason
        projectId
        id
        createdAt
        confSymptomId
        confSymptom {
          __typename
          confReportId
          confEdiaryId
          projectId
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }`,
  ConfOtherSymptomsByConfEdiary: `query ConfOtherSymptomsByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String) {
    confOtherSymptomsByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        showRememberStartDate
        showRememberFinishDate
        showProfessionalHealthCare
        showType
        showIntensity
        showOtherSymptom
        showPrecondition
        preconditionLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  CreateVerifiedSymptomInstance: `mutation CreateVerifiedSymptomInstance($input: CreateVerifiedSymptomInstanceInput!, $condition: ModelVerifiedSymptomInstanceConditionInput) {
    createVerifiedSymptomInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      symptomInstanceId
      symptomInstance {
        __typename
        subjectId
        reportInstanceId
        dayInstanceId
        confSymptomId
        id
        order
        state
        type
        symptom
        whichOtherSymptom
        intensity
        size
        rememberStartDate
        startDate
        rememberFinishDate
        finishDate
        _deviceModel
        _deviceSOVersion
        isOtherSymptom
        medications
        medicalAttentions
        professionalHealthCare
        hospitalAdmission
        createdAt
        rememberNoValuesTaken
        _lastUser
        _changeReason
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      type
      symptom
      whichOtherSymptom
      whichOtherSymptomComment
      intensity
      intensityComment
      size
      sizeComment
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      isOtherSymptom
      medications
      medicationsComment
      medicalAttentions
      medicalAttentionsComment
      professionalHealthCare
      professionalHealthCareComment
      hospitalAdmission
      hospitalAdmissionComment
      intensityQuestionAnswers {
        __typename
        answer
        question {
          __typename
          id
          intensity
          order
          question
        }
      }
      intensityQuestionAnswersComment
      rememberNoValuesTaken
      rememberNoValuesTakenComment
      occurrencyComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateVerifiedSymptomInstance: `mutation UpdateVerifiedSymptomInstance($input: UpdateVerifiedSymptomInstanceInput!, $condition: ModelVerifiedSymptomInstanceConditionInput) {
    updateVerifiedSymptomInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      symptomInstanceId
      symptomInstance {
        __typename
        subjectId
        reportInstanceId
        dayInstanceId
        confSymptomId
        id
        order
        state
        type
        symptom
        whichOtherSymptom
        intensity
        size
        rememberStartDate
        startDate
        rememberFinishDate
        finishDate
        _deviceModel
        _deviceSOVersion
        isOtherSymptom
        medications
        medicalAttentions
        professionalHealthCare
        hospitalAdmission
        createdAt
        rememberNoValuesTaken
        _lastUser
        _changeReason
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      type
      symptom
      whichOtherSymptom
      whichOtherSymptomComment
      intensity
      intensityComment
      size
      sizeComment
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      isOtherSymptom
      medications
      medicationsComment
      medicalAttentions
      medicalAttentionsComment
      professionalHealthCare
      professionalHealthCareComment
      hospitalAdmission
      hospitalAdmissionComment
      intensityQuestionAnswers {
        __typename
        answer
        question {
          __typename
          id
          intensity
          order
          question
        }
      }
      intensityQuestionAnswersComment
      rememberNoValuesTaken
      rememberNoValuesTakenComment
      occurrencyComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateVerifiedSymptomRecordLog: `mutation CreateVerifiedSymptomRecordLog($input: CreateVerifiedSymptomRecordLogInput!, $condition: ModelVerifiedSymptomRecordLogConditionInput) {
    createVerifiedSymptomRecordLog(input: $input, condition: $condition) {
      __typename
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedSymptomInstanceId
      verifiedSymptomInstance {
        __typename
        subjectId
        verifiedDayInstanceId
        confSymptomId
        symptomInstanceId
        id
        state
        type
        symptom
        whichOtherSymptom
        whichOtherSymptomComment
        intensity
        intensityComment
        size
        sizeComment
        rememberStartDate
        rememberStartDateComment
        startDate
        startDateComment
        rememberFinishDate
        rememberFinishDateComment
        finishDate
        finishDateComment
        isOtherSymptom
        medications
        medicationsComment
        medicalAttentions
        medicalAttentionsComment
        professionalHealthCare
        professionalHealthCareComment
        hospitalAdmission
        hospitalAdmissionComment
        intensityQuestionAnswersComment
        rememberNoValuesTaken
        rememberNoValuesTakenComment
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      symptomRecordLogId
      symptomRecordLog {
        __typename
        dayInstanceId
        symptomInstanceId
        state
        subjectId
        id
        intensity
        size
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      intensity
      intensityComment
      size
      sizeComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfTemperatureByConfEdiary: `query ConfTemperatureByConfEdiaryCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String) {
    confTemperatureByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        dayRule
        routeOptions
        units
        showFahrenheitUnit
        showTemperatureRoute
        ocurrencyValue
        ocurrencyUnit
        showTemperatureTaken
        showTemperatureTakenDate
        state
        showHelpScreen
        helpScreenText
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        allowExitToTakeTemperature
        alertExitToTakeTemperatureText
        celciusRanges {
          __typename
          minValue
          maxValue
          decimalPlaces
          expectedMinValue
          expectedMaxValue
          seriousMinValue
          seriousMaxValue
        }
        fahrenheitRanges {
          __typename
          minValue
          maxValue
          decimalPlaces
          expectedMinValue
          expectedMaxValue
          seriousMinValue
          seriousMaxValue
        }
        isExpectedRangeActive
        alertTextForUnexpectedValues
        isSeriousRangeActive
        alertTextForSeriousValues
        dataInputComponent
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfMedicationByConfForm: `query ConfMedicationByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
    confMedicationByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        showPrecondition
        mode
        showRememberStartDate
        showRememberFinishDate
        showDose
        showFrequency
        showAdministrationRoute
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  VerifiedTemperatureBySubjectIdCustom: `query VerifiedTemperatureBySubjectIdCustom($subjectId: ID!) {
    verifiedTemperatureRecordLogBySubjectId(subjectId: $subjectId) {
      __typename
      items {
        __typename
        verifiedDayInstanceId
        subjectId
        temperatureRecordLogID
        id
        temperature
        temperatureComment
        unit
        unitComment
        route
        routeComment
        whichOtherRoute
        whichOtherRouteComment
        taken
        takenComment
        takenDate
        takenDateComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        verifiedDayInstance {
          __typename
          confDay {
            __typename
            order
            dayName
            confFormId
            confEdiaryId
          }
          dayInstance {
            __typename
            id
          }
        }
      }
      nextToken
      startedAt
    }
  }`,
  VerifiedMedicationInstanceByMedicationId: `query VerifiedMedicationInstanceByMedicationId($medicationInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
    verifiedMedicationInstanceByMedicationId(medicationInstanceId: $medicationInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        verifiedDayInstanceId
        medicationInstanceId
        id
        state
        rememberStartDate
        rememberStartDateComment
        startDate
        startDateComment
        isOnGoing
        isOnGoingComment
        rememberFinishDate
        rememberFinishDateComment
        finishDate
        finishDateComment
        name
        nameComment
        reason
        reasonComment
        symptomsInstances
        symptomsInstancesComment
        givenTo
        givenToComment
        dose
        doseComment
        frequency
        frequencyComment
        administrationRoute
        administrationRouteComment
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  UpdateVerifiedSymptomRecordLog: `mutation UpdateVerifiedSymptomRecordLog($input: UpdateVerifiedSymptomRecordLogInput!, $condition: ModelVerifiedSymptomRecordLogConditionInput) {
    updateVerifiedSymptomRecordLog(input: $input, condition: $condition) {
      __typename
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedSymptomInstanceId
      verifiedSymptomInstance {
        __typename
        subjectId
        verifiedDayInstanceId
        confSymptomId
        symptomInstanceId
        id
        state
        type
        symptom
        whichOtherSymptom
        whichOtherSymptomComment
        intensity
        intensityComment
        size
        sizeComment
        rememberStartDate
        rememberStartDateComment
        startDate
        startDateComment
        rememberFinishDate
        rememberFinishDateComment
        finishDate
        finishDateComment
        isOtherSymptom
        medications
        medicationsComment
        medicalAttentions
        medicalAttentionsComment
        professionalHealthCare
        professionalHealthCareComment
        hospitalAdmission
        hospitalAdmissionComment
        intensityQuestionAnswersComment
        rememberNoValuesTaken
        rememberNoValuesTakenComment
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      symptomRecordLogId
      symptomRecordLog {
        __typename
        dayInstanceId
        symptomInstanceId
        state
        subjectId
        id
        intensity
        size
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      intensity
      intensityComment
      size
      sizeComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  VerifiedDayInstanceByDayInstanceIdIdCustom: `query VerifiedDayInstanceByDayInstanceIdIdCustom($dayInstanceId: ID!) {
    verifiedDayInstancesByDayInstanceId(dayInstanceId: $dayInstanceId) {
      __typename
      items {
        __typename
        symptomOcurrencies {
          __typename
          confSymptomId
          occurrency
          order
          symptomInstanceId
          symptomRecordLogId
        }
        symptomOcurrenciesComment
        subjectId
        state
        startDate
        siteId
        medicationGivenToComment
        medicationGivenTo
        id
        hasOtherSymptomsComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        hasMedicalAttentionComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        finishDate
        eDiaryPhaseInstanceId
        dayInstanceId
        createdAt
        confDayId
        _version
        _lastUser
        _deleted
        _changeReason
        completedDate
      }
    }
  }`,
  ConfMedicalAttentionByConfEdiary: `query ConfMedicalAttentionByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
    confMedicalAttentionByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        isBindToSymptomOcurrencies
        showRememberStartDate
        showRememberFinishDate
        showHospitalization
        mode
        showPrecondition
        showType
        medicalAttentionTypes
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfMedicationByConfEdiaryIdCustom: `query ConfMedicationByConfEdiaryIdCustom($confEdiaryId: ID!) {
    confMedicationByConfEdiary(confEdiaryId: $confEdiaryId) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        showPrecondition
        mode
        showRememberStartDate
        showRememberFinishDate
        showDose
        showFrequency
        showAdministrationRoute
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }`,
  CommentConciliationInstanceByEDiaryPhaseInstance: `query CommentConciliationInstanceByEDiaryPhaseInstance($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCommentConciliationInstanceFilterInput, $limit: Int, $nextToken: String) {
    commentConciliationInstanceByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        eDiaryPhaseInstanceId
        id
        comment
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ListVerifiedMedicalAttentionInstances: `query ListVerifiedMedicalAttentionInstances($filter: ModelVerifiedMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
    listVerifiedMedicalAttentionInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        verifiedDayInstanceId
        medicalAttentionInstanceId
        id
        state
        type
        typeComment
        reason
        reasonComment
        symptomsInstances
        symptomsInstancesComment
        rememberStartDate
        rememberStartDateComment
        startDate
        startDateComment
        rememberFinishDate
        rememberFinishDateComment
        finishDate
        finishDateComment
        wasHospitalized
        wasHospitalizedComment
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  CreateCommentConciliationInstance: `mutation CreateCommentConciliationInstance($input: CreateCommentConciliationInstanceInput!, $condition: ModelCommentConciliationInstanceConditionInput) {
    createCommentConciliationInstance(input: $input, condition: $condition) {
      __typename
      eDiaryPhaseInstanceId
      eDiaryPhaseInstance {
        __typename
        subjectId
        eDiaryInstanceId
        siteId
        id
        state
        phase
        pdfState
        completedPhaseDate
        suspensionReason
        suspensionDate
        suspensionUser
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      comment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateVerifiedTemperatureRecordLog: `mutation CreateVerifiedTemperatureRecordLog($input: CreateVerifiedTemperatureRecordLogInput!, $condition: ModelVerifiedTemperatureRecordLogConditionInput) {
    createVerifiedTemperatureRecordLog(input: $input, condition: $condition) {
      __typename
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      temperatureRecordLogID
      temperatureRecordLog {
        __typename
        dayInstanceId
        reportInstanceId
        subjectId
        id
        state
        temperature
        temperatureUnit
        temperatureRoute
        temperatureWhichOtherRoute
        date
        temperatureTaken
        temperatureTakenDate
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      temperature
      temperatureComment
      unit
      unitComment
      route
      routeComment
      whichOtherRoute
      whichOtherRouteComment
      taken
      takenComment
      takenDate
      takenDateComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateVerifiedMedicalAttentionInstance: `mutation CreateVerifiedMedicalAttentionInstance($input: CreateVerifiedMedicalAttentionInstanceInput!, $condition: ModelVerifiedMedicalAttentionInstanceConditionInput) {
    createVerifiedMedicalAttentionInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicalAttentionInstanceId
      medicalAttentionInstance {
        __typename
        subjectId
        dayInstanceId
        id
        state
        type
        reason
        symptomsInstances
        rememberStartDate
        startDate
        rememberFinishDate
        finishDate
        wasHospitalized
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      type
      typeComment
      reason
      reasonComment
      symptomsInstances
      symptomsInstancesComment
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      wasHospitalized
      wasHospitalizedComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateVerifiedDayInstance: `mutation CreateVerifiedDayInstance($input: CreateVerifiedDayInstanceInput!, $condition: ModelVerifiedDayInstanceConditionInput) {
    createVerifiedDayInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      eDiaryPhaseInstanceId
      eDiaryPhaseInstance {
        __typename
        subjectId
        eDiaryInstanceId
        siteId
        id
        state
        phase
        pdfState
        completedPhaseDate
        suspensionReason
        suspensionDate
        suspensionUser
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confDayId
      confDay {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        dayName
        order
        startDay
        endDay
        groups
        phases
        trackSymptomOcurrencies
        hidePDFDayColumn
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      dayInstanceId
      dayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        state
        startDate
        finishDate
        completedDate
        userIP
        gps
        appVersion
        confDayVersion
        hasMedication
        medicationGivenTo
        hasMedicalAttention
        hasOtherSymptoms
        isAlertChecked
        isPDFGenerated
        comments
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      finishDate
      completedDate
      hasMedication
      hasMedicationCompleted
      hasMedicationComment
      medicationGivenTo
      medicationGivenToComment
      hasMedicalAttention
      hasMedicalAttentionCompleted
      hasMedicalAttentionComment
      hasOtherSymptoms
      hasOtherSymptomsCompleted
      hasOtherSymptomsComment
      symptomOcurrencies {
        __typename
        confSymptomId
        symptomInstanceId
        symptomRecordLogId
        occurrency
        order
      }
      symptomOcurrenciesComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateVerifiedMedicationInstance: `mutation CreateVerifiedMedicationInstance($input: CreateVerifiedMedicationInstanceInput!, $condition: ModelVerifiedMedicationInstanceConditionInput) {
    createVerifiedMedicationInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicationInstanceId
      medicationInstance {
        __typename
        subjectId
        dayInstanceId
        id
        state
        rememberStartDate
        startDate
        isOnGoing
        rememberFinishDate
        finishDate
        name
        reason
        symptomsInstances
        givenTo
        dose
        frequency
        administrationRoute
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      isOnGoing
      isOnGoingComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      name
      nameComment
      reason
      reasonComment
      symptomsInstances
      symptomsInstancesComment
      givenTo
      givenToComment
      dose
      doseComment
      frequency
      frequencyComment
      administrationRoute
      administrationRouteComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateSignatureInstance: `mutation CreateSignatureInstance($input: CreateSignatureInstanceInput!, $condition: ModelSignatureInstanceConditionInput) {
    createSignatureInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      date
      type
      reason
      entityType
      entityId
      signedInfo
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateCommentConciliationInstance: `mutation UpdateCommentConciliationInstance($input: UpdateCommentConciliationInstanceInput!, $condition: ModelCommentConciliationInstanceConditionInput) {
    updateCommentConciliationInstance(input: $input, condition: $condition) {
      __typename
      eDiaryPhaseInstanceId
      eDiaryPhaseInstance {
        __typename
        subjectId
        eDiaryInstanceId
        siteId
        id
        state
        phase
        pdfState
        completedPhaseDate
        suspensionReason
        suspensionDate
        suspensionUser
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      comment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateVerifiedTemperatureRecordLog: `mutation UpdateVerifiedTemperatureRecordLog($input: UpdateVerifiedTemperatureRecordLogInput!, $condition: ModelVerifiedTemperatureRecordLogConditionInput) {
    updateVerifiedTemperatureRecordLog(input: $input, condition: $condition) {
      __typename
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      temperatureRecordLogID
      temperatureRecordLog {
        __typename
        dayInstanceId
        reportInstanceId
        subjectId
        id
        state
        temperature
        temperatureUnit
        temperatureRoute
        temperatureWhichOtherRoute
        date
        temperatureTaken
        temperatureTakenDate
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      temperature
      temperatureComment
      unit
      unitComment
      route
      routeComment
      whichOtherRoute
      whichOtherRouteComment
      taken
      takenComment
      takenDate
      takenDateComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateEDiaryPhaseInstance: `mutation UpdateEDiaryPhaseInstance($input: UpdateEDiaryPhaseInstanceInput!, $condition: ModelEDiaryPhaseInstanceConditionInput) {
    updateEDiaryPhaseInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      eDiaryInstanceId
      eDiaryInstance {
        __typename
        subjectId
        confEDiaryId
        siteId
        id
        state
        confEdiaryVersion
        comment
        alerts
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      phase
      pdfFiles {
        __typename
        uploadDate
        fileUrl
      }
      pdfState
      completedPhaseDate
      suspensionReason
      suspensionDate
      suspensionUser
      locked {
        __typename
        user
        date
      }
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateVerifiedMedicalAttentionInstance: `mutation UpdateVerifiedMedicalAttentionInstance($input: UpdateVerifiedMedicalAttentionInstanceInput!, $condition: ModelVerifiedMedicalAttentionInstanceConditionInput) {
    updateVerifiedMedicalAttentionInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicalAttentionInstanceId
      medicalAttentionInstance {
        __typename
        subjectId
        dayInstanceId
        id
        state
        type
        reason
        symptomsInstances
        rememberStartDate
        startDate
        rememberFinishDate
        finishDate
        wasHospitalized
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      type
      typeComment
      reason
      reasonComment
      symptomsInstances
      symptomsInstancesComment
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      wasHospitalized
      wasHospitalizedComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateVerifiedMedicationInstance: `mutation UpdateVerifiedMedicationInstance($input: UpdateVerifiedMedicationInstanceInput!, $condition: ModelVerifiedMedicationInstanceConditionInput) {
    updateVerifiedMedicationInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicationInstanceId
      medicationInstance {
        __typename
        subjectId
        dayInstanceId
        id
        state
        rememberStartDate
        startDate
        isOnGoing
        rememberFinishDate
        finishDate
        name
        reason
        symptomsInstances
        givenTo
        dose
        frequency
        administrationRoute
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      isOnGoing
      isOnGoingComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      name
      nameComment
      reason
      reasonComment
      symptomsInstances
      symptomsInstancesComment
      givenTo
      givenToComment
      dose
      doseComment
      frequency
      frequencyComment
      administrationRoute
      administrationRouteComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateVerifiedDayInstance: `mutation UpdateVerifiedDayInstance($input: UpdateVerifiedDayInstanceInput!, $condition: ModelVerifiedDayInstanceConditionInput) {
    updateVerifiedDayInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      eDiaryPhaseInstanceId
      eDiaryPhaseInstance {
        __typename
        subjectId
        eDiaryInstanceId
        siteId
        id
        state
        phase
        pdfState
        completedPhaseDate
        suspensionReason
        suspensionDate
        suspensionUser
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confDayId
      confDay {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        dayName
        order
        startDay
        endDay
        groups
        phases
        trackSymptomOcurrencies
        hidePDFDayColumn
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      dayInstanceId
      dayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        state
        startDate
        finishDate
        completedDate
        userIP
        gps
        appVersion
        confDayVersion
        hasMedication
        medicationGivenTo
        hasMedicalAttention
        hasOtherSymptoms
        isAlertChecked
        isPDFGenerated
        comments
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      finishDate
      completedDate
      hasMedication
      hasMedicationCompleted
      hasMedicationComment
      medicationGivenTo
      medicationGivenToComment
      hasMedicalAttention
      hasMedicalAttentionCompleted
      hasMedicalAttentionComment
      hasOtherSymptoms
      hasOtherSymptomsCompleted
      hasOtherSymptomsComment
      symptomOcurrencies {
        __typename
        confSymptomId
        symptomInstanceId
        symptomRecordLogId
        occurrency
        order
      }
      symptomOcurrenciesComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  DeleteVerifiedMedicationInstance: `mutation DeleteVerifiedMedicationInstance($input: DeleteVerifiedMedicationInstanceInput!, $condition: ModelVerifiedMedicationInstanceConditionInput) {
    deleteVerifiedMedicationInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicationInstanceId
      medicationInstance {
        __typename
        subjectId
        dayInstanceId
        id
        state
        rememberStartDate
        startDate
        isOnGoing
        rememberFinishDate
        finishDate
        name
        reason
        symptomsInstances
        givenTo
        dose
        frequency
        administrationRoute
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      rememberStartDate
      rememberStartDateComment
      startDate
      startDateComment
      isOnGoing
      isOnGoingComment
      rememberFinishDate
      rememberFinishDateComment
      finishDate
      finishDateComment
      name
      nameComment
      reason
      reasonComment
      symptomsInstances
      symptomsInstancesComment
      givenTo
      givenToComment
      dose
      doseComment
      frequency
      frequencyComment
      administrationRoute
      administrationRouteComment
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  DeleteVerifiedSymptomRecordLog: `mutation DeleteVerifiedSymptomRecordLog($input: DeleteVerifiedSymptomRecordLogInput!, $condition: ModelVerifiedSymptomRecordLogConditionInput) {
    deleteVerifiedSymptomRecordLog(input: $input, condition: $condition) {
      __typename
      verifiedDayInstanceId
      verifiedDayInstance {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        dayInstanceId
        startDate
        finishDate
        completedDate
        hasMedication
        hasMedicationCompleted
        hasMedicationComment
        medicationGivenTo
        medicationGivenToComment
        hasMedicalAttention
        hasMedicalAttentionCompleted
        hasMedicalAttentionComment
        hasOtherSymptoms
        hasOtherSymptomsCompleted
        hasOtherSymptomsComment
        symptomOcurrenciesComment
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      verifiedSymptomInstanceId
      verifiedSymptomInstance {
        __typename
        subjectId
        verifiedDayInstanceId
        confSymptomId
        symptomInstanceId
        id
        state
        type
        symptom
        whichOtherSymptom
        whichOtherSymptomComment
        intensity
        intensityComment
        size
        sizeComment
        rememberStartDate
        rememberStartDateComment
        startDate
        startDateComment
        rememberFinishDate
        rememberFinishDateComment
        finishDate
        finishDateComment
        isOtherSymptom
        medications
        medicationsComment
        medicalAttentions
        medicalAttentionsComment
        professionalHealthCare
        professionalHealthCareComment
        hospitalAdmission
        hospitalAdmissionComment
        intensityQuestionAnswersComment
        rememberNoValuesTaken
        rememberNoValuesTakenComment
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      symptomRecordLogId
      symptomRecordLog {
        __typename
        dayInstanceId
        symptomInstanceId
        state
        subjectId
        id
        intensity
        size
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      intensity
      intensityComment
      size
      sizeComment
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
};
