<div
  class="navbar p-grid p-ai-center vertical-container"
  id="navbar"
  *ngIf="this.isLoggedIn"
>
  <div class="p-col-12 p-md-1 p-sm-2 logo-header">
    <img
      (click)="goHome()"
      src="assets/logo/logo_blanco.png"
      class="p-mr-1"
      style="margin-left: 30px; cursor: pointer"
      alt="Logo trialpal"
    />
  </div>
  <div class="p-col-12 p-md-4 p-sm-5 p-text-left" *ngIf="currentProject">
    <a routerLink="/project/{{ currentProject.id }}/detail">
      <h3>{{ currentProject.code }}</h3>
    </a>
  </div>
  <div *ngIf="currentProject?.id && hasSubjectListPermission">
    <div class="search">
      <div>
        <p-autoComplete
          id="subjectSearch"
          name="subjectSearch"
          field="subjectNumber"
          [suggestions]="subjectsFound"
          [showEmptyMessage]="true"
          emptyMessage="{{ 'project.emptyMessageFindSubject' | translate }}"
          (completeMethod)="searchSubject($event)"
          (onSelect)="goDetailSubject($event)"
          placeholder="{{
            (subjectLoading
              ? 'subject.spinnerLoadListSubject'
              : 'project.labelSubjectSubjectNumber'
            ) | translate
          }}"
          [disabled]="subjectLoading"
        >
        </p-autoComplete>
        <small class="form-error" *ngIf="projectError">{{
          'project.noProjectSelectedError' | translate
        }}</small>
      </div>
      <div class="search-icon">
        <em class="pi pi-search"></em>
      </div>
    </div>
  </div>
  <div
    class="p-text-right"
    [ngClass]="{
      'p-col-12 p-md-4 p-sm-9': currentProject,
      'p-col-12 p-md-10 p-sm-7': !currentProject
    }"
  >
    <p class="text-white">
      <i
        id="icon-alerts"
        class="pi pi-bell"
        pTooltip="{{ 'general.alerts.tooltipIcon' | translate }}"
        (click)="goAlertsEmail()"
      >
        <span *ngIf="alerts > 0" class="alerts">{{ alerts }}</span>
      </i>
      {{ userName ? userName : '' }}<span *ngIf="userRole.length"> - </span>
      <span *ngFor="let item of userRole">
        <strong>{{ ('user.enums.groups.' + item | translate) + ' ' }}</strong>
      </span>
    </p>
  </div>

  <div class="p-col-12 p-md-1 p-sm-3 p-grid p-ai-center">
    <div class="p-col-8" style="text-align: right">
      <a
        href="https://integrait.atlassian.net/servicedesk/customer/portal/5"
        rel="noopener"
        target="_blank"
        data-amplify-analytics-on="click"
        data-amplify-analytics-name="site_btn_support"
      >
        <img
          src="/../assets/imgs/support-button.png"
          alt="support"
          width="32px"
          height="30px"
          style="cursor: pointer"
        />
      </a>
    </div>

    <div class="p-col-4 exit">
      <em
        class="pi pi-sign-out button_action"
        (click)="logout()"
        style="cursor: pointer"
      ></em>
    </div>
  </div>
</div>
<app-maintenance-banner
  *featureFlag="'maintenance-banner-site'"
></app-maintenance-banner>
