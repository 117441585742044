<app-symptoms
  *ngIf="reportExecutionService.currentPage === pagination.SYMPTOM"
></app-symptoms>

<app-form
  *ngIf="reportExecutionService.currentPage === pagination.FORM"
></app-form>

<app-report-success
  *ngIf="reportExecutionService.currentPage === pagination.REPORT_SUCCESS"
></app-report-success>
