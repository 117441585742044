import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-not-found-items',
  templateUrl: './not-found-items.component.html',
  styleUrls: ['./not-found-items.component.scss'],
})
export class NotFoundItemsComponent {
  @Input() description: string = '';
  @Input() buttonDescription: string = '';
  @Input() buttonText: string = '';
  @Input() buttonIcon: string = '';
  @Input() displayButton: boolean = true;
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();
}
