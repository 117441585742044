import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsGuard implements CanMatch {
  unAuthRoutes = [
    '/login',
    '/terms-and-conditions',
    '/privacy-and-terms',
    '/resetPassword',
    '/newPassword',
    '/activation',
  ];
  constructor(private router: Router, private authService: AuthService) {}
  canMatch(
    _route: Route,
    _segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const item: string | null = localStorage.getItem('maintenance');
    let maintenance = false;
    if (!item) {
      maintenance = true;
    }
    !maintenance && this.goToLastRoute();
    return maintenance;
  }

  goToLastRoute() {
    const currentUrl = this.router.url;
    if (
      this.authService.isAuthenticated() &&
      this.unAuthRoutes.some((route) => !currentUrl.startsWith(route))
    ) {
      this.router.navigateByUrl('/home');
    }
  }
}
