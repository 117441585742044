import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Observer, Subscription } from 'rxjs';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { OnBoardingSection } from 'src/app/shared/components/on-boarding/on-boarding.component';
import { SymptomType } from '../../ediary/ediary.types';
import { SitesService } from '../../sites/sites.service';
import { SubjectsService } from '../../subjects/subjects.service';
import { Subject } from '../../subjects/subjects.types';
import { ConciliationService } from '../conciliation.service';
import { ConciliationComponents } from '../conciliationData';
import { PaginationService } from '../pagination.service';
import { DetailDaysMedicalAttentionComponent } from './components/medical-attention/detail-days-medical-attention/detail-days-medical-attention.component';
import { MedicalAttentionService } from './components/medical-attention/medical-attention.service';
import { DetailDaysMedicationComponent } from './components/medication/detail-days-medication/detail-days-medication.component';
import { MedicationService } from './components/medication/medication.service';
import { DetailOtherSymptomsComponent } from './components/other-symptom/detail-other-symptoms/detail-other-symptoms.component';
import { DayInstanceService } from './components/shared/day-instance/day-instance.service';
import { SignatureConciliationComponent } from './components/signature-conciliation/signature-conciliation.component';
import { DetailSymptomComponent } from './components/symptom/detail-symptom/detail-symptom.component';
import { SymptomService } from './components/symptom/symptom.service';
import { DetailDaysTemperatureComponent } from './components/temperature/detail-days-temperature/detail-days-temperature.component';
import { TemperatureService } from './components/temperature/temperature.service';
const logger = new Logger('tp2-conciliationDetail');
@Component({
  selector: 'app-detail-conciliation',
  templateUrl: './detail-conciliation.component.html',
  styleUrls: ['./detail-conciliation.component.scss'],
})
export class DetailConciliationComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHandler(event: BeforeUnloadEvent) {
    if (
      this.conciliationService.isConciliationEdition &&
      this.conciliationService.isPdfConciliationGenerated
    ) {
      return;
    }
    event.preventDefault();
  }

  @ViewChild(DetailDaysTemperatureComponent)
  detailDaysTemperatureComponent!: DetailDaysTemperatureComponent;

  @ViewChild('local')
  detailSymptomComponentLocal!: DetailSymptomComponent;

  @ViewChild('general')
  detailSymptomComponentGeneral!: DetailSymptomComponent;

  @ViewChild(DetailOtherSymptomsComponent)
  detailOtherSymptomsComponent!: DetailOtherSymptomsComponent;

  @ViewChild(DetailDaysMedicationComponent)
  detailDaysMedicationComponent!: DetailDaysMedicationComponent;

  @ViewChild(DetailDaysMedicalAttentionComponent)
  detailDaysMedicalAttentionComponent!: DetailDaysMedicalAttentionComponent;

  @ViewChild(SignatureConciliationComponent)
  signatureConciliationComponent!: SignatureConciliationComponent;
  configurationByForm: any;
  symptoms: any[] = [];
  data: any = {};
  routeState: any;
  subjectId: string = '';
  subject!: Subject;
  //Inputs temperatura
  record: any = {}; // Guarda el registro que se va editando
  isSignatureButtonEnabled: boolean = false;
  formId: string = '';
  timeZoneOffset: number = 0;
  colsDays: any[] = [];
  startDates: any[] = [];
  showSkeleton: boolean = false;
  spinnerSubject!: Subscription;
  currentAuditRecord: any;
  //enums
  sectionTemperature = ConciliationComponents.TEMPERATURE;
  sectionSymptomLocal = ConciliationComponents.SYMPTOM_LOCAL;
  sectionSymptomGeneral = ConciliationComponents.SYMPTOM_GENERAL;
  sectionOtherSymptom = ConciliationComponents.OTHER_SYMPTOM;
  sectionMedication = ConciliationComponents.MEDICATION;
  sectionMedicalAttention = ConciliationComponents.MEDICAl_ATTETION;
  sectionComment = ConciliationComponents.COMMENT;
  isAutomaticReview: boolean = false;

  constructor(
    private router: Router,
    private sitesService: SitesService,
    private subjectsService: SubjectsService,
    private trialpalService: TrialpalService,
    private dialogService: DialogService,
    public paginationService: PaginationService,
    public conciliationService: ConciliationService,
    public dayInstanceService: DayInstanceService,
    public medicalAttentionService: MedicalAttentionService,
    public medicationService: MedicationService,
    public symptomService: SymptomService,
    public temperatureService: TemperatureService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    this.spinnerSubject = this.trialpalService.spinnerSubject.subscribe(
      (input: any) => {
        Promise.resolve().then(() => (this.showSkeleton = input.show));
      }
    );
  }

  closeSide() {
    this.paginationService.closeSideBar();
  }

  ngOnDestroy(): void {
    this.spinnerSubject.unsubscribe();
  }

  async ngOnInit() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant('general.loadingInfo')
    );
    this.resetData();
    this.getGeneralInfo();

    //Obtiene la instancia de ediaryPhaseInstance
    const ediaryPhaseInstance =
      await this.conciliationService.getEdiaryPhaseById();

    //Valida si la conciliacion esta bloqueada, si no setea los datos iniciales
    const isConciliationUnlock =
      await this.conciliationService.isEdiaryPhaseUnlock(ediaryPhaseInstance);

    //Si la conciliación esta bloqueada saca al usuario del modulo
    if (!isConciliationUnlock) return this.forward();
    await this.conciliationService.getConfEdiaryById();
    await this.conciliationService.getAssessmentMode();
    if (!this.subjectId) return;
    await this.getInformationSubjectId(this.subjectId);

    //Obtiene los dias y configuración según la visita seleccionada
    await this.conciliationService.getDayInstanceByEdiaryPhase();
    this.getInformationTableDays();
    if (!this.isEdiaryPhaseDateValid()) {
      this.conciliationService.conciliationLockedExit = true;
      return this.forward();
    }

    await this.openTabs();
    this.setSymptomComponents();
    this.validateActiveSections();
    this.setIsSignatureActive();
    if (!this.conciliationService.getIsAutomaticReview()) {
      this.trialpalService.hideSpinner();
      return this.initFirstSection();
    }
    await this.initializeReviewOfAutomaticSections();
  }

  /**
   * Initializes the review of automatic sections. It first performs the review, then handles
   * several UI and state changes such as opening the onboarding, closing the sidebar, resetting
   * selected instances, and hiding the spinner.
   *
   * @returns A promise that resolves when the initialization is complete.
   */
  async initializeReviewOfAutomaticSections(): Promise<void> {
    await this.reviewAutomaticSections();
    this.openOnBoarding();
    this.paginationService.closeSideBar();
    this.resetCurrentSelectedInstances();
    this.trialpalService.hideSpinner();
  }

  setIsSignatureActive() {
    this.isSignatureButtonEnabled =
      this.conciliationService.isEdiaryPhaseInstanceEnded();
  }

  setSymptomComponents() {
    this.symptomService.detailSymptomComponentGeneral =
      this.detailSymptomComponentGeneral;
    this.symptomService.detailSymptomComponentLocal =
      this.detailSymptomComponentLocal;
  }

  openOnBoarding() {
    this.trialpalService.openOnBoarding(
      OnBoardingSection.ASSESSMENT,
      this.translate('conciliation.onBoarding.title'),
      this.translate('conciliation.onBoarding.btnContinue'),
      [
        this.translate('conciliation.onBoarding.steps.step1'),
        this.translate('conciliation.onBoarding.steps.step2'),
        this.translate('conciliation.onBoarding.steps.step3'),
        this.translate('conciliation.onBoarding.steps.step4'),
        this.translate('conciliation.onBoarding.steps.step5'),
      ]
    );
  }
  translate(key: string) {
    return this.trialpalService.translateService.instant(key);
  }

  onExit() {
    if (this.allowExitFromConciliationPage()) {
      this.conciliationService.conciliationUnLock().then();
      this.resetData();
      return true;
    }
    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        header: this.trialpalService.translateService.instant(
          'conciliation.conciliationExitHeader'
        ),
        message: this.trialpalService.translateService.instant(
          'conciliation.conciliationExitMessage'
        ),
        acceptLabel: this.trialpalService.translateService.instant(
          'conciliation.conciliationExitAcceptLabel'
        ),
        rejectLabel: this.trialpalService.translateService.instant(
          'conciliation.conciliationRejectLabel'
        ),
        accept: async () => {
          observer.next(true);
          observer.complete();
          this.conciliationService.recordExitEvent();
          await this.conciliationService.conciliationUnLock();
          this.resetData();
          return true;
        },
        reject: () => {
          observer.next(false);
          observer.complete();
          return false;
        },
      });
    });
  }

  allowExitFromConciliationPage(): boolean {
    return (
      !this.conciliationService.getauthSession() ||
      this.conciliationService.conciliationLockedExit ||
      (this.conciliationService.isConciliationEdition &&
        this.conciliationService.isPdfConciliationGenerated)
    );
  }

  //Si se entra a la pagina el mismo día que se completa la visita, sacará al usuario de la conciliacion
  isEdiaryPhaseDateValid() {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    //Filtra los días que ya hayan pasado
    dayInstances = dayInstances.filter((DI: any) => {
      return this.conciliationService.isBeforeToCurrentDate(
        DI.dayInstance.finishDate
      );
    });

    return dayInstances.length !== 0;
  }

  async openTabs() {
    this.trialpalService.showSpinner('conciliation.loadingInformation');
    await this.symptomService.getDaySymptomsInstances();

    //Carga la información a conciliar de temperatura
    this.paginationService.currentSection = ConciliationComponents.TEMPERATURE;
    await this.waitTwoMilisec();

    //Carga la información de sintomas locales
    this.paginationService.currentSection =
      ConciliationComponents.SYMPTOM_LOCAL;
    await this.waitTwoMilisec();
    this.detailSymptomComponentLocal.symptomType = SymptomType.LOCAL;

    //Carga la información de sintomas generales
    this.paginationService.currentSection =
      ConciliationComponents.SYMPTOM_GENERAL;
    await this.waitTwoMilisec();
    this.detailSymptomComponentGeneral.symptomType = SymptomType.GENERAL;

    //Carga la información a conciliar de otros sintomas
    this.paginationService.currentSection =
      ConciliationComponents.OTHER_SYMPTOM;
    await this.waitTwoMilisec();
    await this.detailOtherSymptomsComponent.ngOnInit();

    //Carga la información a conciliar de medicamentos
    this.paginationService.currentSection = ConciliationComponents.MEDICATION;
    await this.waitTwoMilisec();
    await this.detailDaysMedicationComponent?.ngOnInit();

    //Carga la información a conciliar de Atenciones medicas
    this.paginationService.currentSection =
      ConciliationComponents.MEDICAl_ATTETION;
    await this.waitTwoMilisec();
    await this.detailDaysMedicalAttentionComponent?.ngOnInit();
  }

  async waitTwoMilisec() {
    await new Promise((resolve: any) => setTimeout(() => resolve(), 200));
  }

  //Se encarga de actualiza el arreglo con las secciones activa según la configuracion de los diarios
  validateActiveSections() {
    this.paginationService.sections = this.paginationService.sections.map(
      (section: any) => {
        section.isActive =
          section.configurationKey === 'areCommentsRequired'
            ? true
            : this.paginationService.isSectionActive(section.configurationKey);
        return section;
      }
    );
  }

  //Función que se encarga de escoger la primera sección activa
  initFirstSection() {
    this.resetCurrentSelectedInstances();
    const section = this.paginationService.sections.find(
      (section: any) => section.isActive
    );
    this.paginationService.onTabOpen({ index: section?.section });
  }

  /**
   * Resets the current state of various services to undefined.
   */
  resetCurrentSelectedInstances(): void {
    this.temperatureService.currentTemperatureDay = undefined;
    this.symptomService.currentSymptom = undefined;
    this.symptomService.currentOtherSymptom = undefined;
    this.medicationService.currentMedication = undefined;
    this.medicalAttentionService.selectedMedicalAttention = undefined;
    this.dayInstanceService.currentDayInstance = undefined;
  }

  getInformationTableDays() {
    const days = [...this.conciliationService.dayInstancesAndConfigurations];
    // Ordenar los datos por la fecha de inicio
    days.sort(
      (a: any, b: any) =>
        a.dayInstance?.confDay?.order - b.dayInstance?.confDay?.order
    );
    const rangeDates = [];
    const stateDays = [];
    for (const day of days) {
      rangeDates.push(
        `${this.getTransformDate(day.dayInstance.startDate)} -
        ${this.getTransformDate(day.dayInstance.finishDate)}`
      );
      stateDays.push(
        `${this.trialpalService.translateService.instant(
          'general.instanceState.' + day?.dayInstance?.state
        )}`
      );
      this.colsDays.push({
        field: day.dayInstance.id,
        header: day.dayInstance.confDay?.dayName,
      });
    }
    logger.debug('daysTable', days);
    this.startDates[0] = rangeDates;
    this.startDates[1] = stateDays;
  }

  getTransformDate(date: string) {
    return this.conciliationService.transformDatePipe.transform(date);
  }
  resetData() {
    this.isAutomaticReview = false;
    this.paginationService.currentSection = '';
    this.paginationService.dayName = '';
    this.paginationService.sectionIndex = -1;
    this.paginationService.sectionName = '';
    this.paginationService.display = false;
    this.paginationService.isTemperatureOpen = false;
    this.paginationService.isSymptomLocalOpen = false;
    this.paginationService.isSymptomGeneralOpen = false;
    this.paginationService.isOtherSymptomOpen = false;
    this.paginationService.isMedicationOpen = false;
    this.paginationService.isMedicalAttentionOpen = false;
    this.paginationService.isCommentsOpen = false;
    this.conciliationService.ediaryPhaseId = '';
    this.conciliationService.dayInstancesAndConfigurations = [];
    this.conciliationService.currentComment = {};
    this.conciliationService.isPdfConciliationGenerated = true;
    this.conciliationService.conciliationLockedExit = false;
    this.symptomService.confSymptoms = [];
    this.symptomService.symptoms = [];
    this.symptomService.symptomInstances = [];
    this.symptomService.tableOtherSymptoms = [];
    this.conciliationService.confEdiaryId = '';
    this.symptomService.addConfOtherSymptoms = [];
    //=====DATOS SELECCIONADO DE DIA===============
    this.dayInstanceService.verifiedDayInstances = [];
    //=====DATOS SELECCIONADO DE TEMPERATURA===============
    this.temperatureService.colTemperatureDays = [];
    this.temperatureService.currentTemperatureDay = {};
    this.temperatureService.temperatures = [];
    this.temperatureService.verifiedTemperatures = [];
    this.temperatureService.temperature = [];
    this.temperatureService.temperatureRoute = [];
    this.temperatureService.temperatureTaken = [];
    this.temperatureService.temperatureTakenDate = [];
    this.temperatureService.unit = [];
    this.temperatureService.observations = [];
    this.temperatureService.pendingInstances = [];
    this.temperatureService.configTemperatureByEdiary = undefined;
    this.temperatureService.verifiedTempeturesBySubject = [];
    //Objetos que representan los medicamentos
    this.medicationService.currentMedication = {};
    this.medicationService.confMedications = [];
    this.medicationService.tableMedications = [];
    this.medicationService.medicationDays = [];
    //Objetos que representan las atenciones medicas
    this.medicalAttentionService.medication = {};
    this.medicalAttentionService.observations = [];
    this.medicalAttentionService.medicalAttentionTable = [];
    this.medicalAttentionService.medicalAttention = undefined;
    this.medicalAttentionService.confMedicalAttention = {};
    this.medicalAttentionService.verifiedMedicalAttention = undefined;
    this.medicalAttentionService.verifiedMedicalAttentionsBySubject = [];
    this.medicalAttentionService.isEdition = false;
  }

  getGeneralInfo() {
    this.subjectId = this.route.snapshot.params.subjectId;
    this.conciliationService.ediaryPhaseId =
      this.route.snapshot.params.eDiaryPhaseInstanceId;
    this.conciliationService.subjectId = this.subjectId;
  }

  /**
   * @desc Cargar la informacion del sujeto
   * @param subjectId id del sujeto
   */
  async getInformationSubjectId(subjectId: string) {
    await this.subjectsService
      .getSubject(subjectId)
      .then((subject) => {
        logger.debug('subject', subject);
        if (subject) {
          this.subject = subject;
          //obtener la zona horaria
          this.timeZoneOffset = this.sitesService.getSiteGMT(
            this.subject.site?.timezone
          );
          localStorage.setItem(
            'timeZoneOffset',
            JSON.stringify(this.timeZoneOffset)
          );
        }
      })
      .catch((error) => {
        logger.error('Error al cargar la informacion del sujeto', error);
        this.trialpalService.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'conciliation.messageError.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'conciliation.messageError.detail'
          ),
        });
        this.conciliationService.conciliationLockedExit = true;
        this.forward();
      });
  }

  /**
   * @desc modal de auditoria
   */
  openAuditModal() {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity: this.currentAuditRecord.entity,
        description: this.currentAuditRecord.description,
        idRecord: this.currentAuditRecord.id,
      },
      width: '95%',
      height: '95%',
    });
  }
  /**
   * @desc volver al detalle del sujeto y eliminar la informacion del diario del sujeto en el localStore
   */
  forward(): void {
    this.trialpalService.hideSpinner();
    this.router.navigate(['/subjects/', this.subjectId, 'detail']);
  }

  assignCurrentAuditRecord(data: any) {
    this.resetAudit();
    Promise.resolve().then(() => (this.currentAuditRecord = data));
  }

  resetAudit() {
    this.currentAuditRecord = null;
  }

  /**
   * @desc modal de firma de conciliacion
   */
  saveAndSignature() {
    this.paginationService.showSignature();
  }

  isButtonSignatureDisabled() {
    const isAutomaticReview = this.conciliationService.getIsAutomaticReview();
    const isEdition = this.conciliationService.isConciliationEdition;
    const isPdfGenerated = this.conciliationService.isPdfConciliationGenerated;
    if (!this.isSignatureButtonEnabled) return true;
    if (isEdition && isPdfGenerated) return true;
    if (!isAutomaticReview && !this.isConciliationCompleted()) return true;
    return false;
  }
  /**
   * Checks if the conciliation process is completed.
   *
   * This function iterates through the sections and checks if all the active sections
   * have reached 100% progress.
   *
   * @returns {boolean} - Returns true if all active sections have 100% progress, otherwise false.
   */
  isConciliationCompleted(): boolean {
    const reviewSections = this.paginationService.sections;
    const sectionsProgress = this.getGeneralProgress();
    const progress: number[] = [];
    sectionsProgress.forEach((sectionProgress: number, index: number) => {
      if (reviewSections[index].isActive) {
        progress.push(sectionProgress);
      }
    });
    return progress.every((progress) => progress === 100);
  }

  getGeneralProgress() {
    const temperaturesProgress = this.temperatureService.getProgressTemperature;
    const localSymptomsProgress =
      this.symptomService.getProgressByDetailSymptomComponent(
        this.detailSymptomComponentLocal
      );
    const generalSymptomsProgress =
      this.symptomService.getProgressByDetailSymptomComponent(
        this.detailSymptomComponentGeneral
      );
    const otherSymptomsProgress =
      this.symptomService.getOtherSymptomProgressBar;
    const medicationsProgress = this.medicationService.getProgressMedication();
    const medicalAttentionsProgress =
      this.medicalAttentionService.getProgressMedicalAttentions();
    return [
      temperaturesProgress,
      localSymptomsProgress,
      generalSymptomsProgress,
      otherSymptomsProgress,
      medicationsProgress,
      medicalAttentionsProgress,
    ];
  }

  async reviewAutomaticSections() {
    this.trialpalService.hideSpinner();
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'conciliation.automaticAssessmentMessage'
      )
    );
    const reviewSections = this.paginationService.sections;
    if (reviewSections[0].isActive) {
      await this.temperatureService.reviewTemperatureRecordLogsData();
    }

    if (reviewSections[1].isActive) {
      await this.symptomService.reviewSymptomsDetailData(
        this.detailSymptomComponentLocal
      );
    }

    if (reviewSections[2].isActive) {
      await this.symptomService.reviewSymptomsDetailData(
        this.detailSymptomComponentGeneral
      );
    }

    if (reviewSections[3].isActive) {
      await this.symptomService.reviewOtherSymptomsData();
    }

    if (reviewSections[4].isActive) {
      await this.medicationService.reviewMedicationInstancesData();
    }

    if (reviewSections[5].isActive) {
      await this.medicalAttentionService.reviewMedicalAttentionInstancesData();
    }
    this.isAutomaticReview = true;
    this.trialpalService.hideSpinner();
  }

  isSectionSelected(section: ConciliationComponents): boolean {
    if (this.isAutomaticReview) return true;
    return this.paginationService.currentSection === section;
  }
}
