<label
  class="p-d-block"
  style="margin-bottom: 0.5rem"
  for="{{ field?.key + '_' + date ?? '' }}"
>
  {{ to.label }} <span *ngIf="to.required ?? false">*</span></label
>
<input
  id="{{ field?.key + '_' + date ?? '' }}"
  type="date"
  [formControl]="formControl"
  [formlyAttributes]="field"
  class="full-width"
  [required]="to.required ?? false"
  pInputText
/>
