<p-table
  [value]="symptomOcurrencesTable"
  class="p-d-block p-mb-4 symptom-conciliation-header"
  styleClass="p-datatable-gridlines"
  responsiveLayout="scroll"
  [tableStyle]="{ width: '100%' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th id="symptom_name" class="position-sticky">
        <strong>{{ 'symptominstance.symptomIllness' | translate }}</strong>
      </th>
      <th [id]="dayInstance.id" *ngFor="let dayInstance of dayInstances">
        <strong>{{ dayInstance?.confDay?.dayName ?? '' | dictionary }}</strong>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-symptom let-index="rowIndex">
    <tr>
      <td class="position-sticky">
        <strong>{{ symptom.name }}</strong>
      </td>
      <td
        *ngFor="let occurrency of symptom.occurrences"
        class="btn-occurrency"
        [ngClass]="{
          'ocurrency-selected':
            occurrency.key === symptomService.currentSymptom?.key,
          disabled: !occurrency.isAssociated
        }"
        (click)="selectedSymptom(index, occurrency, undefined)"
        role="button"
      >
        <span
          [innerHTML]="
            occurrency.occurrency
              | textNa: occurrency.verifiedOccurrency:'boolean'
          "
          *ngIf="occurrency.isAssociated"
        ></span>
        <span *ngIf="!occurrency.isAssociated"
          ><strong>{{
            'symptominstance.noAssociated' | translate
          }}</strong></span
        >
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="symptom-conciliation">
  <p-accordion
    [multiple]="false"
    [activeIndex]="symptomService.activeAccordionTabIndex"
    (activeIndexChange)="symptomService.activeAccordionTabIndex = $event"
  >
    <p-accordionTab
      [header]="symptom.name"
      *ngFor="
        let symptom of symptomOcurrencesTable;
        let activeAccordionTabIndex = index
      "
    >
      <div *ngIf="symptom.tableSymptoms && symptom.tableSymptoms.length > 0">
        <p-table
          [value]="symptom.tableSymptoms"
          dataKey="key"
          styleClass="p-datatable-gridlines"
          responsiveLayout="scroll"
          class="p-pb-3"
        >
          <ng-template pTemplate="header">
            <tr>
              <th id="day">{{ 'conciliation.day' | translate }}</th>
              <th id="occurrency">
                {{ 'symptominstance.occurrency' | translate }}
              </th>
              <th id="intensityName">
                {{ 'symptominstance.intensity' | translate }}
              </th>
              <th id="questionName">
                {{ 'symptom.questionsTitle' | translate }}
              </th>
              <th id="startDate">
                {{ 'symptominstance.startDate' | translate }}
              </th>
              <th id="finishDate">
                {{ 'symptominstance.endDate' | translate }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-symptom let-expanded="expanded">
            <tr
              class="header"
              [ngClass]="{
                'header-select':
                  symptom.key === symptomService.currentSymptom?.key
              }"
              (click)="
                selectedSymptom(activeAccordionTabIndex, undefined, symptom)
              "
            >
              <td>
                <div class="grid-c col-c-2">
                  <app-check
                    [isConciliated]="symptom?.verifiedSymptomInstance?.id"
                    [verifiedInstance]="symptom?.verifiedSymptomInstance"
                  ></app-check>
                  <span
                    [pTooltip]="symptom?.dayName | dictionary"
                    class="ellipsis"
                  >
                    {{ symptom?.dayName | dictionary }}
                  </span>
                </div>
              </td>
              <td>
                <span
                  [innerHTML]="
                    symptom.symptomOccurrency?.occurrency
                      | textNa
                        : symptom.verifiedSymptomOccurrency?.occurrency
                        : 'boolean'
                  "
                ></span>
                <sup
                  class="p-ml-1"
                  *ngIf="symptom.comments?.occurrencyComment"
                  [pTooltip]="symptom.comments?.occurrencyComment"
                >
                  {{ symptom.comments?.occurrencyCommentIndex }}
                </sup>
              </td>
              <td>
                <div *ngIf="symptom?.rememberNoValuesTaken">
                  <span>
                    {{ symptom.confSymptom.noValuesTakenLabel | dictionary }}
                  </span>
                  <sup
                    class="p-ml-1"
                    *ngIf="symptom.comments?.rememberNoValuesTakenComment"
                    [pTooltip]="symptom.comments?.rememberNoValuesTakenComment"
                  >
                    {{ symptom.comments?.rememberNoValuesTakenCommentIndex }}
                  </sup>
                </div>
                <div *ngIf="!symptom.rememberNoValuesTaken">
                  <div
                    *ngIf="
                      symptom?.confSymptom?.intensityType === 'NUMERIC_VALUE'
                    "
                  >
                    <span class="p-d-block p-mb-3"
                      >{{
                        symptom.confSymptom.intensitySizeLabel | dictionary
                      }}:
                    </span>
                    <span
                      [innerHTML]="
                        symptom.symptomInstance.size
                          | textNa: symptom.verifiedSymptomInstance?.size
                      "
                    ></span>
                    <sup
                      class="p-ml-1"
                      *ngIf="symptom.comments?.sizeComment"
                      [pTooltip]="symptom.comments?.sizeComment"
                    >
                      {{ symptom.comments?.sizeCommentIndex }}
                    </sup>
                  </div>

                  <div *ngIf="symptom?.confSymptom?.intensityType === 'RANGE'">
                    <span
                      class="p-d-block p-mb-3"
                      [innerHTML]="
                        symptom.symptomInstance.intensity
                          | textNa
                            : symptom.verifiedSymptomInstance?.intensity
                            : 'intensity'
                            : false
                            : symptom.comments?.occurrencyComment
                      "
                    ></span>
                    <span
                      [innerHTML]="
                        symptom.symptomInstance.size
                          | textNa
                            : symptom.verifiedSymptomInstance?.size
                            : ''
                            : false
                            : symptom.comments?.occurrencyComment
                      "
                    ></span>
                    <sup
                      class="p-ml-1"
                      *ngIf="symptom.comments?.sizeComment"
                      [pTooltip]="symptom.comments?.sizeComment"
                    >
                      {{ symptom.comments?.sizeCommentIndex }}
                    </sup>
                  </div>

                  <div
                    *ngIf="
                      symptom?.confSymptom?.intensityType === 'MULTIPLE_CHOICE'
                    "
                  >
                    <span
                      [innerHTML]="
                        symptom.symptomInstance.intensity
                          | textNa
                            : symptom.verifiedSymptomInstance?.intensity
                            : 'intensity'
                            : false
                            : symptom.comments?.occurrencyComment
                      "
                    ></span>
                    <sup
                      class="p-ml-1"
                      *ngIf="symptom.comments?.sizeComment"
                      [pTooltip]="symptom.comments?.sizeComment"
                    >
                      {{ symptom.comments?.sizeCommentIndex }}
                    </sup>
                  </div>
                </div>
              </td>
              <td>
                <span
                  [innerHTML]="
                    symptom.intensityQuestions
                      | textNa
                        : symptom.verifiedIntensityQuestions
                        : ''
                        : false
                        : symptom.comments?.occurrencyComment
                  "
                ></span>
                <sup
                  class="p-ml-1"
                  *ngIf="symptom.comments?.intensityQuestionAnswersComment"
                  [pTooltip]="symptom.comments?.intensityQuestionAnswersComment"
                >
                  {{ symptom.comments?.intensityQuestionAnswersCommentIndex }}
                </sup>
              </td>
              <td>
                <span
                  [innerHTML]="
                    symptom.symptomInstance.startDate
                      | textNa
                        : symptom.verifiedSymptomInstance?.startDate
                        : 'date'
                        : false
                        : symptom.verifiedSymptomInstance?.startDateComment ||
                            symptom.comments?.occurrencyComment
                  "
                ></span>
                <sup
                  class="p-ml-1"
                  *ngIf="symptom.comments?.startDateComment"
                  [pTooltip]="symptom.comments?.startDateComment"
                >
                  {{ symptom.comments?.startDateCommentIndex }}
                </sup>
              </td>

              <td>
                <span
                  [innerHTML]="
                    symptom.symptomInstance.finishDate
                      | textNa
                        : symptom.verifiedSymptomInstance?.finishDate
                        : 'date'
                        : false
                        : symptom.verifiedSymptomInstance?.finishDateComment ||
                            symptom.comments?.occurrencyComment
                  "
                ></span>
                <sup
                  class="p-ml-1"
                  *ngIf="symptom.comments?.finishDateComment"
                  [pTooltip]="symptom.comments?.finishDateComment"
                >
                  {{ symptom.comments?.finishDateCommentIndex }}
                </sup>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-grid p-col-12 p-pt-3" *ngIf="symptom.comments.length">
          <span class="p-col-12 color-primary">{{
            'conciliation.titleObservation' | translate
          }}</span>
          <div>
            <ul class="p-d-flex p-flex-row p-flex-wrap">
              <div class="p-pl-3" *ngFor="let comment of symptom.comments">
                <li *ngIf="comment?.comment">
                  <span class="color-primary">{{ comment?.index }}. </span>
                  <span style="word-break: break-word">{{
                    comment?.comment
                  }}</span>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
