import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConciliationService } from '../../../../conciliation.service';
import { SymptomService } from '../../symptom/symptom.service';

@Component({
  selector: 'app-summary-other-symptoms',
  templateUrl: './summary-other-symptoms.component.html',
})
export class SummaryOtherSymptomComponent {
  original: any = {};
  verified: any = {};
  configuration: any = {};
  showWithOtherSymptom: boolean = false;
  showIntensity: boolean = false;
  showProfessionalHealthCare: boolean = false;
  showHospitalAdmission: boolean = false;
  showRememberStartDate: boolean = false;
  showRememberFinishDate: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public symptomService: SymptomService,
    public conciliationService: ConciliationService
  ) {
    this.original = this.config.data.original;
    this.verified = this.config.data.verified;
    this.configuration = this.config.data.configuration;
    this.conciliationService.transformSummaryDate(this.original);
    this.conciliationService.transformSummaryDate(this.verified);

    this.showChanges();
  }

  showChanges() {
    //Muestra o no el elmento
    this.showWithOtherSymptom =
      this.original?.whichOtherSymptom || this.verified?.whichOtherSymptom;

    this.showIntensity =
      this.configuration?.showIntensity &&
      (this.original?.intensity || this.verified?.intensity);

    this.showProfessionalHealthCare =
      this.configuration?.showProfessionalHealthCare &&
      (this.original?.professionalHealthCare ||
        this.verified?.professionalHealthCare);

    this.showHospitalAdmission =
      this.configuration?.showProfessionalHealthCare &&
      (this.original?.hospitalAdmission || this.verified?.hospitalAdmission);

    this.showRememberStartDate =
      this.configuration?.showRememberStartDate &&
      (![undefined, null].includes(this.original?.rememberStartDate) ||
        ![undefined, null].includes(this.verified?.rememberStartDate));

    this.showRememberFinishDate =
      this.configuration?.showRememberFinishDate &&
      (![undefined, null].includes(this.original?.rememberFinishDate) ||
        ![undefined, null].includes(this.verified?.rememberFinishDate));
  }

  onClose(result: boolean) {
    this.ref.close(result);
  }
}
