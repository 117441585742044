import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Logger } from 'aws-amplify';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Dictionary } from './dictionary';
import { DictionaryService } from './dictionary.service';
import { EditAddDictionaryComponent } from './edit-add-dictionary/edit-add-dictionary.component';

const logger = new Logger('tp2-logger-LanguagesPage');

@Component({
  selector: 'app-table-languages',
  templateUrl: './table-languages.component.html',
  styleUrls: ['./table-languages.component.scss'],
})
export class TableLanguagesComponent implements OnInit {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('dictionaryTable') dictionaryTable!: Table;
  dictionaries: Dictionary[] = [];
  newItem: boolean = false;
  projectId = '';
  isForm: boolean = false;
  isEditorForm: boolean = false;
  isGlobal: boolean = false; //Validar si se deben mostrar el boton de añadir contenido y añadir contenido plano
  constructor(
    private dictionaryService: DictionaryService,
    private trialpalService: TrialpalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.projectId = this.config.data.projectId;
    this.isForm = this.config.data.isForm;
    this.isEditorForm = this.config.data?.isEditorForm ?? false;
    this.isGlobal = this.config.data?.isGlobal ?? false;
    if (this.projectId) {
      this.getListDictionary(this.projectId);
    }
  }

  addEditDictionary(
    dictionary?: Dictionary,
    isEdit?: boolean,
    isHtml?: boolean
  ): void {
    const ref = this.dialogService.open(EditAddDictionaryComponent, {
      data: {
        dictionary: dictionary,
        projectId: this.projectId,
        isEdit: isEdit,
        dictionarys: this.dictionaries,
        isHtml: dictionary?.isHtml ?? isHtml,
      },
      width: '45%',
      height: 'auto',
    });

    ref.onClose.subscribe(async (dataInput) => {
      if (dataInput) {
        await this.getListDictionary(this.projectId);
      }
    });
  }

  private async getListDictionary(projectId: string) {
    this.dictionaries = [];
    this.trialpalService.showSpinner('dictionary.translationPlural', 'LIST');
    await this.dictionaryService
      .getConfDictionaryByProjectId(projectId)
      .then((data) => {
        data.items?.forEach((d: any) => {
          const dictionary: Dictionary = {
            id: d.id,
            projectId: d.projectId,
            isHtml: d.isHtml,
            key: d.key,
            spanish: d.spanish,
            english: d.english,
            _version: d._version,
          };
          this.dictionaries.push(dictionary);
        });
        localStorage.removeItem('Dictionaty');
        localStorage.setItem('Dictionary', JSON.stringify(data));
        this.filterDictionaryByType();
        this.orderDictionaryByKey();
      })
      .catch((error: Error) => {
        logger.error('listTranslation error', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  onSelectKeySetForm(dictionary: Dictionary) {
    this.ref.close(dictionary);
  }

  filterDictionaryByType() {
    this.dictionaries = this.dictionaries.filter((dictionary: Dictionary) => {
      if (this.isGlobal) return true;
      if (this.isEditorForm && dictionary?.isHtml) return true;
      if (!this.isEditorForm && !dictionary?.isHtml) return true;
      return false;
    });
  }
  orderDictionaryByKey() {
    this.dictionaries.sort(function (a, b) {
      if (a.key.toLowerCase() > b.key.toLowerCase()) {
        return 1;
      }
      if (a.key.toLowerCase() < b.key.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  clearTable() {
    this.searchInput.nativeElement.value = '';
    this.dictionaryTable.clear();
    this.dictionaryTable.filterGlobal('', 'contains');
  }
}
