import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-refresh-data',
  templateUrl: './button-refresh-data.component.html',
})
export class ButtonRefreshDataComponent {
  @Input() showSpinner: any;
  @Output() buttonAction = new EventEmitter<any>();

  onButtonAction() {
    this.buttonAction.emit();
  }
}
