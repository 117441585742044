import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConfState, InstanceState } from 'src/app/services/trialpal.types';
import { SubjectsService } from '../subjects/subjects.service';
import { REPORT_QUERIES } from './report.queries';
import {
  ConfSymptomByConfReportIdCustomQuery,
  CreateConfReportBySubjectInput,
  CreateConfReportBySubjectMutation,
  CreateConfReportInput,
  CreateConfReportMutation,
  DeleteConfReportInput,
  DeleteConfReportMutation,
  GetConfReportQuery,
  ListConfAlertsQueryCustom,
  ModelReportInstanceFilterInput,
  OnDemandAlertType,
  ReportAvailableUserType,
  ReportProgramationType,
  UpdateConfReportBySubjectInput,
  UpdateConfReportBySubjectMutation,
  UpdateConfReportInput,
  UpdateConfReportMutation,
} from './report.types';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  alertsToReview: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(
    private translateService: TranslateService,
    private auth: AuthService,
    private subjectsService: SubjectsService
  ) {}
  async getConfSymptomByConfReportId(
    confReportId: string
  ): Promise<ConfSymptomByConfReportIdCustomQuery> {
    const gqlAPIServiceArguments: any = {
      confReportId,
    };
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.ConfSymptomByConfReportIdCustom,
      gqlAPIServiceArguments
    );
    return <ConfSymptomByConfReportIdCustomQuery>(
      response.data.confSymptomByConfReportId
    );
  }

  async getConfAlertByConfReportId(confReportId: string): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      confReportId,
      limit: 1000,
    };
    do {
      gqlArguments.nextToken = nextToken;
      const listQuery: any = await this.performGraphQLQuery(
        REPORT_QUERIES.ConfAlertByConfReportIdCustom,
        gqlArguments
      );

      instances = instances.concat(
        listQuery.data.confAlertByConfReportId.items
      );
      nextToken = listQuery?.nextToken || undefined;
    } while (nextToken);

    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== ConfState.DELETED);

    return instances;
  }

  async getConfReportByProjectId(projectId: string): Promise<any> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      projectId,
      limit: 1000,
    };
    do {
      gqlArguments.nextToken = nextToken;
      let listQuery = await this.performGraphQLQuery(
        REPORT_QUERIES.ConfReportByProject,
        gqlArguments
      );
      instances = listQuery.data.confReportByProject.items;
      nextToken = listQuery.data.confReportByProject.nextToken ?? undefined;
    } while (nextToken);

    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== ConfState.DELETED);
    return instances;
  }

  async getConfReportBySubjectId(subjectId: string): Promise<any> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      subjectId,
      limit: 1000,
    };
    do {
      gqlArguments.nextToken = nextToken;
      let listQuery = await this.performGraphQLQuery(
        REPORT_QUERIES.ConfReportBySubjectId,
        gqlArguments
      );
      instances = listQuery.data.confReportBySubjectId.items;
      nextToken = listQuery.data.confReportBySubjectId.nextToken ?? undefined;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  async createConfReport(
    data: CreateConfReportInput
  ): Promise<CreateConfReportMutation> {
    data._lastUser = this.auth.getUsername();
    const isDateRangeLimitRequired = data?.isDateRangeLimitRequired ?? false;
    data.startDate = this.formatDateToYYYYMMDD(
      data.startDate,
      isDateRangeLimitRequired
    );
    data.endDate = this.formatDateToYYYYMMDD(
      data.endDate,
      isDateRangeLimitRequired
    );
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.CreateConfReport,
      { input: data }
    );
    return response.data.createConfReport;
  }

  async getConfReport(id: string): Promise<GetConfReportQuery> {
    const gqlArguments: any = {
      id,
    };
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.GetConfReport,
      gqlArguments
    );
    return response.data.getConfReport;
  }

  async reportInstanceByConfReportId(id: string): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      confReportId: id,
      limit: 1000,
    };
    do {
      gqlArguments.nextToken = nextToken;
      let listQuery = await this.performGraphQLQuery(
        REPORT_QUERIES.ReportInstanceByConfReportIdCustom,
        gqlArguments
      );
      instances = instances.concat(
        listQuery.data.reportInstanceByConfReportId.items
      );
      nextToken =
        listQuery.data.reportInstanceByConfReportId.nextToken ?? undefined;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return this.getInstanceBySite(instances);
  }

  async findAtLeastOneReportInstanceByConfReport(id: string): Promise<any> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      confReportId: id,
      limit: 100,
      nextToken,
    };
    let listQuery = await this.performGraphQLQuery(
      REPORT_QUERIES.ReportInstanceByConfReportIdCustom,
      gqlArguments
    );
    instances = listQuery.data.reportInstanceByConfReportId.items
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    nextToken =
      listQuery.data.reportInstanceByConfReportId.nextToken ?? undefined;

    if (instances.length > 0) return true;
    while (nextToken) {
      listQuery = await this.performGraphQLQuery(
        REPORT_QUERIES.ReportInstanceByConfReportIdCustom,
        gqlArguments
      );
      instances = instances.concat(
        listQuery.data.reportInstanceByConfReportId.items
      );
      nextToken = listQuery.data.reportInstanceByConfReportId.nextToken;
      instances = instances
        .filter(Boolean)
        .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
      if (instances.length > 0) return true;
    }

    return instances.length > 0;
  }

  async reportInstanceBySubjectId(
    id: string,
    confReportId: string
  ): Promise<any[]> {
    const filter: ModelReportInstanceFilterInput = {
      confReportId: { eq: confReportId },
    };
    const gqlArguments: any = {
      subjectId: id,
      filter,
      limit: 1000,
    };
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      gqlArguments.nextToken = nextToken;
      let listQuery = await this.performGraphQLQuery(
        REPORT_QUERIES.CustomReportInstanceBySubjectId,
        gqlArguments
      );
      instances = listQuery.data.reportInstanceBySubjectId.items;
      nextToken =
        listQuery.data.reportInstanceBySubjectId.nextToken ?? undefined;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return this.getInstanceBySite(instances);
  }

  getInstanceBySite(instances: any) {
    const rolSites = this.auth.getUserSites();
    const siteInstances: any[] = [];
    if (this.auth.isInvestigator() || this.auth.isReader()) {
      rolSites.forEach((siteId: any) => {
        instances.forEach((element: any) => {
          if (element.siteId === siteId) {
            siteInstances.push(element);
          }
        });
      });
      instances = siteInstances;
    }
    return instances;
  }

  async deleteConfReport(
    id: string,
    expectedVersion: number
  ): Promise<DeleteConfReportMutation> {
    const input: DeleteConfReportInput = {
      id,
      _version: expectedVersion,
    };
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.DeleteConfReport,
      { input }
    );
    return response.data.deleteConfReport;
  }

  async deleteConfReportSoft(
    confReport: any
  ): Promise<UpdateConfReportMutation> {
    let updateInput: UpdateConfReportInput = {
      id: confReport.id,
      _version: confReport._version,
      state: ConfState.DELETED,
    };
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.UpdateConfReport,
      { input: updateInput }
    );
    return response.data.updateConfReport;
  }

  async updateConfReport(
    data: CreateConfReportInput,
    id: string,
    expectedVersion: any
  ): Promise<UpdateConfReportMutation> {
    const isDateRangeLimitRequired = data?.isDateRangeLimitRequired ?? false;
    data.startDate = this.formatDateToYYYYMMDD(
      data.startDate,
      isDateRangeLimitRequired
    );
    data.endDate = this.formatDateToYYYYMMDD(
      data.endDate,
      isDateRangeLimitRequired
    );

    let updateInput: UpdateConfReportInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.UpdateConfReport,
      {
        input: updateInput,
      }
    );
    return response.data.updateConfReport;
  }

  /**
   * Formats a given date to 'YYYY-MM-DD' format if the date and range limit are required.
   *
   * @param {any} date - The date to be formatted.
   * @param {boolean} isRangeLimitRequired - Indicates if the date range limit is required.
   * @returns {string | undefined} - The formatted date as a string or undefined if no date is provided.
   */
  formatDateToYYYYMMDD(
    date: any,
    isRangeLimitRequired: boolean
  ): string | undefined {
    return isRangeLimitRequired && date
      ? moment(date).format('YYYY-MM-DD')
      : undefined;
  }

  async updateConfReportInstance(input: UpdateConfReportInput) {
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.UpdateConfReport,
      { input }
    );
    return response.data.updateConfReport;
  }

  getProgramationTypes(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService
        .get('report.enums.programationTypes')
        .subscribe((translate) => {
          const data: any[] = [];
          Object.keys(ReportProgramationType).forEach((e) => {
            data.push({ name: translate[e], value: e });
          });
          resolve(data);
        });
    });
  }

  getOnDemandAlertTypes(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService
        .get('report.enums.onDemandAlertTypes')
        .subscribe((translate) => {
          const data: any[] = [];
          Object.keys(OnDemandAlertType).forEach((e) => {
            data.push({ name: translate[e], value: e });
          });
          resolve(data);
        });
    });
  }

  getWeekDays(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService.get('primeng.dayNames').subscribe((translate) => {
        const data: any[] = [];
        Object.keys(translate).forEach((e) => {
          data.push({ name: translate[e], value: Number.parseInt(e) });
        });
        resolve(data);
      });
    });
  }

  getfrequency(): any[] {
    const frequency = {
      Daily: '',
      Weekly: '',
      Monthly: '',
    };
    const data: any[] = [];
    const list = this.translateService.instant('general.frequency');
    Object.keys(frequency).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }

  async createConfReportBySubject(
    data: CreateConfReportBySubjectInput
  ): Promise<CreateConfReportBySubjectMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.CreateConfReportBySubject,
      { input: data }
    );
    return response.data.createConfReportBySubject;
  }

  async updateConfReportBySubject(
    data: UpdateConfReportBySubjectInput
  ): Promise<UpdateConfReportBySubjectMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.UpdateConfReportBySubject,
      { input: data }
    );
    return response.data.updateConfReportBySubject;
  }

  getGraphTypes(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('general.graphTypes');
    Object.keys(graphTypes).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }
  getAvailableUsers(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('report.enums.availableUsers');
    Object.keys(ReportAvailableUserType).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }

  async getReportInstanceById(reportInstanceId: string) {
    const response = await this.performGraphQLQuery(
      REPORT_QUERIES.GetReportInstance,
      {
        id: reportInstanceId,
      }
    );
    return response.data.getReportInstance;
  }

  async getConfAlerts(filter: any = undefined): Promise<any[]> {
    let confAlerts: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        REPORT_QUERIES.listConfAlertsCustom,
        { filter, limit: 1000, nextToken }
      );
      confAlerts = confAlerts.concat(listQuery.data.listConfAlerts.items);
      nextToken = listQuery.data.listConfAlertsnextToken ?? undefined;
    } while (nextToken);
    confAlerts = confAlerts.filter(Boolean).filter((s: any) => !s._deleted);
    return confAlerts;
  }

  async getConfAlertsByEmail(email: string): Promise<any[]> {
    let confAlerts: ListConfAlertsQueryCustom[] = [];
    confAlerts = await this.getConfAlerts();
    confAlerts = confAlerts.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
    confAlerts = confAlerts.filter((confAlert: any) => {
      let siteRecipients = [
        ...new Set<string>(
          confAlert.siteRecipients.filter(
            (sr: { siteId: string; recipients: string[] }) => {
              if (sr.recipients.includes(email)) {
                return true;
              } else {
                return false;
              }
            }
          )
        ),
      ];
      if (confAlert.recipients.includes(email) || siteRecipients.length > 0) {
        confAlert.currentSiteRecipients = siteRecipients;
        return true;
      } else {
        return false;
      }
    });
    if (!this.auth.isAdmin()) {
      return this.getConfAlertsByProjectAndSites(confAlerts);
    }
    return confAlerts;
  }
  getConfAlertsByProjectAndSites(confAlerts: any[]): Array<any> {
    const authSites = this.auth.getUserSites();
    const authProjects = this.auth.getUserProjects();
    return (
      confAlerts.filter((confAlert: any) => {
        let sitesId = confAlert.currentSiteRecipients.map(
          (sr: { siteId: string; recipients: string[] }) => sr.siteId
        );
        if (sitesId.length === 0) {
          return true;
        }
        for (const siteId of sitesId) {
          if (
            authSites.includes(siteId) &&
            authProjects.includes(confAlert.projectId)
          ) {
            return true;
          }
        }
        return false;
      }) ?? []
    );
  }
  async listAlertInstances(confAlerts: any[]): Promise<any[]> {
    let alertInstances: any[] = [];
    const promises = confAlerts.map((confAlert: any) =>
      this.fetchAlertInstances(confAlert)
    );
    alertInstances = await Promise.all(promises);
    alertInstances = [].concat(...alertInstances);
    alertInstances = alertInstances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== 'DELETED');

    alertInstances = await Promise.all(
      alertInstances.map(async (alertInstance) => {
        alertInstance.subject = await this.subjectsService.getSubject(
          alertInstance.subjectId
        );
        return alertInstance;
      })
    );
    if (!this.auth.isAdmin()) {
      alertInstances =
        this.getSubjectAlertInstancesWithAuthSites(alertInstances);
    }
    this.pendingWeekAlerts(alertInstances);
    return alertInstances;
  }

  async fetchAlertInstances(confAlert: any): Promise<any[]> {
    let alertInstances: any[] = [];
    let nextToken: string | undefined = undefined;

    do {
      const listQuery: any = await this.performGraphQLQuery(
        REPORT_QUERIES.AlertInstanceByConfAlertId,
        { confAlertId: confAlert.id, limit: 1000, nextToken }
      );
      listQuery.data.alertInstanceByConfAlertId.items.forEach(
        (alertItem: any) => (alertItem.confAlert = confAlert)
      );
      alertInstances = alertInstances.concat(
        listQuery.data.alertInstanceByConfAlertId.items
      );
      nextToken =
        listQuery.data.alertInstanceByConfAlertId.nextToken ?? undefined;
    } while (nextToken);

    return alertInstances;
  }

  pendingWeekAlerts(alertInstances: Array<any>) {
    const now = new Date();
    const year = now.getFullYear();
    const week = moment(now).isoWeek(); // use Moment.js to get the ISO week number

    const weekAlerts = alertInstances.filter((alert) => {
      const dateObj = new Date(alert.createdAt);
      return (
        moment(dateObj).isoWeek() === week &&
        dateObj.getFullYear() === year &&
        (alert?.revisionState === 'PENDING' || !alert?.revisionState)
      );
    });
    this.alertsToReview.next(weekAlerts.length);
    return weekAlerts;
  }

  getSubjectAlertInstancesWithAuthSites(alertInstances: any[]) {
    const authSites = this.auth.getUserSites();
    return (
      alertInstances.filter((alertInstance: any) => {
        if (authSites.includes(alertInstance.subject.siteId)) {
          return true;
        }
        return false;
      }) ?? []
    );
  }
  private async performGraphQLQuery(query: any, args: any): Promise<any> {
    return (await API.graphql(graphqlOperation(query, args))) as any;
  }

  transformStringToDate(date: any): Date {
    if (!date) return date;
    return moment(date).toDate();
  }
}

export enum graphTypes {
  ADHERENCE = 'ADHERENCE',
  TOTALS = 'TOTALS',
}
