import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { AddEditSitesComponent } from './add-edit-sites/add-edit-sites.component';
import { ListSitesComponent } from './list-sites/list-sites.component';
import { SitesRoutingModule } from './sites-routing.module';

@NgModule({
  declarations: [ListSitesComponent, AddEditSitesComponent],
  imports: [
    CommonModule,
    SitesRoutingModule,
    SharedComponentsModule,
    PrimengModule,
  ],
})
export class SitesModule {}
