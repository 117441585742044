<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" class="form-style">
  <div class="p-grid p-fluid">
    <div class="p-offset-1 p-col-11" style="margin-top: 1rem">
      <a routerLink="/login">
        <em class="pi pi-chevron-left"> </em>
        {{ 'user.buttonLogin' | translate }}
      </a>
    </div>
    <div class="p-offset-1 p-col-11">
      <h2>{{ 'user.h2UserActivation' | translate }}</h2>
      <h4>{{ 'user.h4EnterCodeRecivedEmail' | translate }}</h4>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4">
      <h4>{{ 'user.labelId' | translate }}</h4>
      <input
        name="username"
        type="text"
        pInputText
        [(ngModel)]="userId"
        required
        #username="ngModel"
      />
      <small
        class="form-error"
        *ngIf="!username.valid && (f.submitted || username.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4">
      <h4>
        {{ 'user.labelActivationCode' | translate }}
      </h4>
      <input
        name="code"
        type="text"
        pInputText
        [(ngModel)]="codigo"
        required
        #code="ngModel"
      />
      <small
        class="form-error"
        *ngIf="!code.valid && (f.submitted || code.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4" style="text-align: center">
      <a class="forgot" (click)="sendAgain()">
        {{ 'user.labelResendCode' | translate }}
      </a>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-2 p-lg-offset-8">
      <button
        pButton
        label="{{ 'user.labelActivateUser' | translate }}"
        type="submit"
        class="p-button-secondary"
      ></button>
    </div>
  </div>
</form>
