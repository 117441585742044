<form name="form" autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="scroll-conciliation p-pr-2">
    <div class="p-ai-center">
      <div class="p-grid p-mb-3 p-ai-center full-width">
        <label
          id="lbl_labelDayComment"
          name="labelDayComment"
          for="conciliationComment"
        >
          {{ 'conciliation.labelComment' | translate }}
        </label>
        <textarea
          id="conciliationComment"
          formControlName="comment"
          class="p-col minh"
          pInputTextarea
          type="text"
          placeholder="{{ 'conciliation.placeholderComment' | translate }}"
        ></textarea>
      </div>
    </div>
  </div>
  <app-change-reason
    #formReason
    *ngIf="
      this.currentComment?.id &&
      formChange.trim() !== form.controls['comment'].value.trim()
    "
  ></app-change-reason>
  <app-form-buttons
    [isNextDisabled]="
      this.formChange?.trim() === this.form.value?.comment?.trim() &&
      !isSignatureActive
    "
    (previousData)="previousMedication()"
  ></app-form-buttons>
</form>
