import { GraphQLResult } from '@aws-amplify/api-graphql';
import { TP2Permission } from 'src/app/services/user-permissions-service';
export const CHAT_QUERIES = {
  AuthorizedSitesBySiteIdCustom: `query AuthorizedSitesBySiteIdCustom($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String) {
        AuthorizedSitesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            user {
              __typename
              id
              login
              name
              email
              role
              state
              phoneNumber
              subjects
              sites
              projects
            }
            siteId
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  SubjectsBySiteId: `query SubjectsBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectsBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ChatMessagesBySiteId: `query ChatMessagesBySiteId($siteId: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatMessageFilterInput, $limit: Int, $nextToken: String) {
        ChatMessagesBySiteId(siteId: $siteId, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            siteId
            userId
            message
            sender
            senderAlias
            createdAt
            _lastUser
            _changeReason
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  CreateChatMessage: `mutation CreateChatMessage($input: CreateChatMessageInput!, $condition: ModelChatMessageConditionInput) {
        createChatMessage(input: $input, condition: $condition) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  OnCreateChatMessageByUserId: `subscription OnCreateChatMessageByUserId($userId: String!) {
        onCreateChatMessageByUserId(userId: $userId) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  OnCreateChatMessageBySiteId: `subscription OnCreateChatMessageBySiteId($siteId: String!) {
        onCreateChatMessageBySiteId(siteId: $siteId) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfChatUser: `mutation CreateConfChatUser($input: CreateConfChatUserInput!, $condition: ModelConfChatUserConditionInput) {
        createConfChatUser(input: $input, condition: $condition) {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  UpdateConfChatUser: `mutation UpdateConfChatUser($input: UpdateConfChatUserInput!, $condition: ModelConfChatUserConditionInput) {
        updateConfChatUser(input: $input, condition: $condition) {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfCharUserByProjectId: `query ConfCharUserByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfChatUserFilterInput, $limit: Int, $nextToken: String) {
        ConfCharUserByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            userId
            siteId
            projectId
            id
            isChatActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfCharUserByUserId: `query ConfCharUserByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfChatUserFilterInput, $limit: Int, $nextToken: String) {
        ConfCharUserByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            userId
            siteId
            projectId
            id
            isChatActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
};

export type AuthorizedSitesBySiteIdCustomQuery = {
  __typename: 'ModelAuthorizedSiteConnection';
  items: Array<{
    __typename: 'AuthorizedSite';
    id: string;
    user?: {
      __typename: 'User';
      id: string;
      login: string;
      name?: string | null;
      email: string;
      role: string;
      state: UserState;
      phoneNumber?: string | null;
      subjects?: string | null;
      sites?: string | null;
      projects?: string | null;
    } | null;
    siteId: string;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CreateChatMessageInput = {
  siteId: string;
  userId: string;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  id?: string | null;
  _version?: number | null;
};

export type CreateChatMessageMutation = {
  __typename: 'ChatMessage';
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateChatMessageMutation = {
  __typename: 'ChatMessage';
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfChatUserInput = {
  userId: string;
  siteId: string;
  projectId: string;
  id?: string | null;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type SubjectsBySiteIdQuery = {
  __typename: 'ModelSubjectConnection';
  items: Array<{
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UpdateConfChatUserInput = {
  userId?: string | null;
  siteId?: string | null;
  projectId?: string | null;
  id: string;
  isChatActivated?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export enum UserState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AlertType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum ChatMessageSender {
  SITE = 'SITE',
  SUBJECT = 'SUBJECT',
}

export enum SubjectState {
  ENROLLED = 'ENROLLED',
  COMPLETED = 'COMPLETED',
  DROPOUT = 'DROPOUT',
  DELETED = 'DELETED',
}
