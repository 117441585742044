<div class="container p-grid p-fluid">
  <h4 _ngcontent-hvb-c134="" class="p-mt-2">
    {{ 'report.labelDeviceDataInstruction' | translate }}
  </h4>
  <div
    class="p-col-12 p-md-6 p-grid p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
  >
    <div class="p-col-8 p-md-6">
      <label for="gps">{{ 'report.labelGPS' | translate }}</label>
    </div>
    <div class="p-col">
      <p-selectButton
        [options]="booleanOptions"
        optionLabel="label"
        optionValue="value"
        [disabled]="isReadOnly || readMode"
        id="gps"
        name="gps"
        #gps="ngModel"
        [(ngModel)]="confReport.isGPSRequired"
        pTooltip="{{ 'report.labelGPSTooltip' | translate }}"
        tooltipPosition="top"
      ></p-selectButton>
    </div>
  </div>
  <div
    class="p-col-12 p-md-6 p-grid p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
  >
    <div class="p-col-8 p-md-6">
      <label for="labelDeviceData">{{
        'report.labelDeviceData' | translate
      }}</label>
    </div>
    <div class="p-col">
      <p-selectButton
        [options]="booleanOptions"
        optionLabel="label"
        optionValue="value"
        [disabled]="isReadOnly || readMode"
        id="deviceData"
        #ip="ngModel"
        [(ngModel)]="confReport.isDeviceDataRequired"
        pTooltip="{{ 'report.labelDeviceDataTooltip' | translate }}"
        tooltipPosition="top"
      ></p-selectButton>
    </div>
  </div>
  <div
    class="p-col-12 p-md-6 p-grid p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
  >
    <div class="p-col-8 p-md-6">
      <label for="ip">{{ 'report.labelIP' | translate }}</label>
    </div>
    <div class="p-col">
      <p-selectButton
        [options]="booleanOptions"
        optionLabel="label"
        optionValue="value"
        [disabled]="isReadOnly || readMode"
        name="ip"
        id="ip"
        #ip="ngModel"
        [(ngModel)]="confReport.isUserIPRequired"
        pTooltip="{{ 'report.labelIPTooltip' | translate }}"
        tooltipPosition="top"
      ></p-selectButton>
    </div>
  </div>
</div>
