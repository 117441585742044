import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConciliationService } from '../../../conciliation.service';
import { SymptomService } from '../symptom/symptom.service';
import { AuthService } from './../../../../../services/auth.service';
import { GeneralModel } from './../../../conciliationData';
import { CONCILIATION_QUERIES } from '../../../conciliation.queries';
import {
  ConfMedicalAttention,
  ConfMedicalAttentionByConfEdiaryQuery,
  CreateVerifiedMedicalAttentionInstanceInput,
  CreateVerifiedMedicalAttentionInstanceMutation,
  InstanceState,
  MedicalAttentionConfigurationMode,
  MedicalAttentionInstance,
  MedicalAttentionType,
  ModelVerifiedMedicalAttentionInstanceConditionInput,
  UpdateVerifiedMedicalAttentionInstanceInput,
  UpdateVerifiedMedicalAttentionInstanceMutation,
  VerifiedMedicalAttentionInstance,
} from '../../../conciliation.types';
const logger = new Logger('tp2-medicalAttentionService');
@Injectable({
  providedIn: 'root',
})
export class MedicalAttentionService {
  medication: any;
  observations: any[] = [];
  medicalAttentionTable: VerifiedMedicalAttention[] = [];
  preconditionTable: any[] = [];
  medicalAttention?: any;
  confMedicalAttention?: any;
  verifiedMedicalAttention?: VerifiedMedicalAttentionInstance;
  verifiedMedicalAttentionsBySubject: VerifiedMedicalAttentionInstance[] = [];
  configMedicalAttentionsByEdiary?: ConfMedicalAttentionByConfEdiaryQuery;
  selectedMedicalAttention: any;
  isEdition: boolean = false;
  addMedicalAttention: boolean = false;
  isNewMedicalAttention: boolean = false;
  constructor(
    private transformDatePipe: TransformDatePipe,
    private trialpalService: TrialpalService,
    private conciliationService: ConciliationService,
    private authService: AuthService,
    private symptomService: SymptomService
  ) {}

  /**
   * @param confEdiaryId id de configuracion del edairy
   * @returns array de configuraciones correspondientes
   */
  async getConfMedicalAttentions(
    confEdiaryId: string
  ): Promise<ConfMedicalAttentionByConfEdiaryQuery> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfMedicalAttentionByConfEdiary,
      { confEdiaryId }
    );
    return response.data.confMedicalAttentionByConfEdiary;
  }

  /**
   * @param verified objeto conciliado de atencion medica
   * @returns Objeto de la creacion de la instancia de verificacion de la atencion medica
   */
  async createVerifiedMedicalAttentionInstance(
    verified: CreateVerifiedMedicalAttentionInstanceInput
  ): Promise<CreateVerifiedMedicalAttentionInstanceMutation> {
    verified._lastUser = this.authService.getUsername();
    verified.state =
      verified?.state === InstanceState.CONCILIATION_DELETED ||
      verified?.state === InstanceState.DELETED
        ? verified.state
        : InstanceState.UNSIGNED;
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateVerifiedMedicalAttentionInstance,
      {
        input: verified,
      }
    );
    return response.data.createVerifiedMedicalAttentionInstance;
  }

  /**
   * @desc metodo para actualizar la instancia de la temperatura
   * @param data informacion que se va a actuallizar
   * @param id id de la instancia
   * @param expectedVersion version
   */
  async updateVerifiedMedicalAttentionInstance(
    data: UpdateVerifiedMedicalAttentionInstanceInput,
    id: string,
    expectedVersion: any
  ): Promise<UpdateVerifiedMedicalAttentionInstanceMutation> {
    let updateInput: UpdateVerifiedMedicalAttentionInstanceInput = {
      id,
      state: InstanceState.UNSIGNED,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateVerifiedMedicalAttentionInstance,
      {
        input: updateInput,
      }
    );
    return response.data.updateVerifiedMedicalAttentionInstance;
  }

  objMedicalAttention(
    index: number,
    dayInstance: any,
    medicalAttention: MedicalAttentionInstance,
    reason: any,
    confMedicalAttention: any,
    verifiedMedicalAttention?: VerifiedMedicalAttentionInstance
  ) {
    this.isEdition = !!verifiedMedicalAttention;
    this.confMedicalAttention = confMedicalAttention;
    this.medicalAttention = medicalAttention;
    this.verifiedMedicalAttention = verifiedMedicalAttention;
    return this.newObjMedicalAttention(
      index,
      dayInstance,
      medicalAttention?.subjectId,
      medicalAttention,
      confMedicalAttention,
      verifiedMedicalAttention,
      reason
    );
  }

  /**
   * @param index
   * @param dayInstance
   * @param subjectId
   * @param medicalAttention
   * @param configuration
   * @param verifiedMedicalAttention
   * @param reason
   * @returns
   */
  newObjMedicalAttention(
    index: number,
    dayInstance: any,
    subjectId: string,
    medicalAttention: any,
    configuration: any,
    verifiedMedicalAttention: any,
    reason?: any
  ) {
    const isEdition = !!verifiedMedicalAttention?.id;
    return {
      index,
      dayInstance,
      confForm: dayInstance.confDay?.confForm,
      dayName: dayInstance?.confDay?.dayName,
      subjectId: subjectId,
      verifiedDayInstanceId: verifiedMedicalAttention?.id,
      medicalAttentionInstanceId: medicalAttention?.id,
      configuration,
      medicalAttention: medicalAttention,
      verifiedMedicalAttention,
      type: this.getType(
        configuration,
        medicalAttention,
        verifiedMedicalAttention,
        isEdition
      ),
      reason: this.getReason(
        reason,
        medicalAttention,
        configuration,
        verifiedMedicalAttention,
        isEdition
      ),
      symptomsInstances: this.getSymptoms(
        medicalAttention,
        verifiedMedicalAttention
      ),
      wasHospitalized: this.getWasHospitalized(
        configuration,
        medicalAttention,
        verifiedMedicalAttention,
        isEdition
      ),
      rememberStartDate: this.getRememberStartDate(
        medicalAttention,
        verifiedMedicalAttention
      ),
      startDate: this.getStartDate(
        configuration,
        medicalAttention,
        verifiedMedicalAttention,
        isEdition
      ),
      rememberFinishDate: this.getRememberFinishDate(
        medicalAttention,
        verifiedMedicalAttention
      ),
      finishDate: this.getFinishDate(
        configuration,
        medicalAttention,
        verifiedMedicalAttention,
        isEdition
      ),
      isEdition,
    };
  }
  /**
   *
   * @param confMedicalAttention
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @param isEdition
   * @returns objeto con el comentario y traduccion del tipo de atencion medica
   */
  getType(
    confMedicalAttention?: any,
    medicalAttention?: any,
    verifiedMedicalAttention?: any,
    isEdition?: boolean
  ) {
    const type: RecordVerified = {};
    type.record =
      confMedicalAttention?.showType && medicalAttention?.type
        ? this.getTransFormEnumType(medicalAttention?.type)
        : GeneralModel.NA;
    if (isEdition) {
      type.verified = verifiedMedicalAttention?.type
        ? this.getTransFormEnumType(verifiedMedicalAttention.type)
        : '';
      type.comment = verifiedMedicalAttention?.typeComment;
    }
    return type;
  }

  /** Consulta el modo de configuracion y hace el translate del reason dependiendo de la configuracion
   * @param inputReason
   * @param confMedicalAttention
   * @param verifiedMedicalAttention
   * @param isEdition
   * @returns Objeto reason ya sea por modo abierto o con sintomas reportados
   */
  getReason(
    inputReason: any,
    medicalAttention: any,
    confMedicalAttention?: any,
    verifiedMedicalAttention?: any,
    isEdition?: boolean
  ) {
    const reason: RecordVerified = {};
    reason.record = inputReason;
    if (
      confMedicalAttention?.mode ===
      MedicalAttentionConfigurationMode.SYMPTOM_BINDING
    ) {
      if (isEdition) {
        if (verifiedMedicalAttention?.symptomsInstances) {
          reason.verified = this.symptomService.getSymptoms(
            verifiedMedicalAttention?.symptomsInstances,
            medicalAttention
          );
        }
        reason.comment = verifiedMedicalAttention?.symptomsInstancesComment;
      }
    }
    if (
      confMedicalAttention?.mode ===
      MedicalAttentionConfigurationMode.OPEN_REASON
    ) {
      if (isEdition) {
        reason.verified = verifiedMedicalAttention?.reason;
        reason.comment = verifiedMedicalAttention?.reasonComment;
      }
    }
    return reason;
  }

  /**
   *
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @returns
   */
  getSymptoms(medicalAttention?: any, verifiedMedicalAttention?: any) {
    return {
      record: medicalAttention?.symptomsInstances,
      verified: verifiedMedicalAttention?.symptomsInstances,
      comment: verifiedMedicalAttention?.symptomsInstancesComment,
    };
  }
  /**
   *
   * @param confMedicalAttention
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @param isEdition
   * @returns
   */
  getWasHospitalized(
    confMedicalAttention?: any,
    medicalAttention?: any,
    verifiedMedicalAttention?: any,
    isEdition?: boolean
  ) {
    const wasHospitalized: RecordVerified = {};
    wasHospitalized.record = confMedicalAttention?.showHospitalization
      ? this.getYN(medicalAttention?.wasHospitalized)
      : GeneralModel.NA;
    if (isEdition) {
      wasHospitalized.verified = confMedicalAttention?.showHospitalization
        ? this.getYN(verifiedMedicalAttention?.wasHospitalized)
        : GeneralModel.NA;
      wasHospitalized.comment =
        verifiedMedicalAttention?.wasHospitalizedComment;
    }
    return wasHospitalized;
  }

  /**
   *
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @returns
   */
  getRememberStartDate(medicalAttention?: any, verifiedMedicalAttention?: any) {
    return {
      record: medicalAttention?.rememberStartDate,
      verified: verifiedMedicalAttention?.rememberStartDate,
      comment: verifiedMedicalAttention?.rememberStartDateComment,
    };
  }
  /**
   *
   * @param confMedicalAttention
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @param isEdition
   * @returns
   */
  getStartDate(
    confMedicalAttention?: any,
    medicalAttention?: any,
    verifiedMedicalAttention?: any,
    isEdition?: boolean
  ) {
    const startDate: RecordVerified = {};
    startDate.record =
      confMedicalAttention?.showRememberStartDate &&
      medicalAttention?.rememberStartDate
        ? this.getTransFormDate(medicalAttention?.startDate)
        : GeneralModel.NA;
    if (isEdition) {
      startDate.verified =
        confMedicalAttention?.showRememberStartDate &&
        verifiedMedicalAttention?.rememberStartDate
          ? this.getTransFormDate(verifiedMedicalAttention?.startDate)
          : GeneralModel.NA;
      startDate.comment = verifiedMedicalAttention?.startDateComment;
    }

    return startDate;
  }

  /**
   *
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @returns
   */
  getRememberFinishDate(
    medicalAttention?: any,
    verifiedMedicalAttention?: any
  ) {
    return {
      record: medicalAttention?.rememberFinishDate || GeneralModel.NA,
      verified: verifiedMedicalAttention?.rememberFinishDate,
      comment: verifiedMedicalAttention?.rememberFinishDateComment,
    };
  }

  /**
   *
   * @param confMedicalAttention
   * @param medicalAttention
   * @param verifiedMedicalAttention
   * @param isEdition
   * @returns Fecha de finalizacion
   */
  getFinishDate(
    confMedicalAttention?: any,
    medicalAttention?: any,
    verifiedMedicalAttention?: any,
    isEdition?: boolean
  ) {
    const finishDate: RecordVerified = {};
    finishDate.record =
      confMedicalAttention?.showRememberFinishDate &&
      medicalAttention?.rememberFinishDate
        ? this.getTransFormDate(medicalAttention?.finishDate)
        : GeneralModel.NA;
    if (isEdition) {
      finishDate.verified =
        confMedicalAttention?.showRememberFinishDate &&
        verifiedMedicalAttention?.rememberFinishDate
          ? this.getTransFormDate(verifiedMedicalAttention?.finishDate)
          : GeneralModel.NA;
      finishDate.comment = verifiedMedicalAttention?.finishDateComment;
    }
    return finishDate;
  }
  /**
   *
   * @param configuration
   * @param medicalAttention
   * @returns
   */
  addReason(configuration: any, medicalAttention: any): string {
    let reason: any = GeneralModel.NA;
    if (configuration?.mode) {
      // agregar la razon depediendo del modo de configuracion
      if (
        configuration.mode === MedicalAttentionConfigurationMode.SYMPTOM_BINDING
      ) {
        if (medicalAttention?.symptomsInstances) {
          reason = this.symptomService.getSymptoms(
            medicalAttention?.symptomsInstances,
            medicalAttention
          );
        }
      }
      if (
        configuration.mode === MedicalAttentionConfigurationMode.OPEN_REASON
      ) {
        reason = medicalAttention?.reason;
      }
    }
    return reason;
  }

  /**
   * @desc agregar los indices de comentarios en la tabla
   */
  addCommentsTable() {
    let indexComment = 1;
    this.observations = [];
    logger.debug('addCommentsTable', this.preconditionTable);
    for (const precondicion of this.preconditionTable) {
      if (precondicion?.verifiedDayInstance?.hasMedicalAttentionComment) {
        this.observations.push({
          indexComment: indexComment,
          comment: precondicion.verifiedDayInstance?.hasMedicalAttentionComment,
        });
        indexComment = indexComment + 1;
      }
    }
    this.medicalAttentionTable = this.medicalAttentionTable.map(
      (medical: any) => {
        if (medical?.type?.comment) {
          medical.type.indexComment = indexComment;
          this.addObservation(medical?.type?.comment, indexComment);
          indexComment++;
        }
        if (medical?.reason?.comment) {
          medical.reason.indexComment = indexComment;
          this.addObservation(medical?.reason?.comment, indexComment);
          indexComment++;
        }
        if (medical?.wasHospitalized?.comment) {
          medical.wasHospitalized.indexComment = indexComment;
          this.addObservation(medical?.wasHospitalized?.comment, indexComment);
          indexComment++;
        }
        if (medical?.startDate?.comment) {
          medical.startDate.indexComment = indexComment;
          this.addObservation(medical?.startDate?.comment, indexComment);
          indexComment++;
        }
        if (medical?.finishDate?.comment) {
          medical.finishDate.indexComment = indexComment;
          this.addObservation(medical?.finishDate?.comment, indexComment);
          indexComment++;
        }

        return medical;
      }
    );
  }

  addObservation(comment: string, indexComment: number) {
    if (comment.length > 0) {
      this.observations.push({
        indexComment: indexComment,
        comment: comment,
      });
    }
  }
  getTransFormDate(date: any) {
    return this.transformDatePipe.transform(date);
  }

  /**
   *
   * @param type Tipo de enumeracion de atencion medica
   * @returns translate de la enumeracion
   */
  getTransFormEnumType(type?: MedicalAttentionType | null) {
    return this.trialpalService.translateService.instant(
      'medicalattentioninstance.enums.' + type
    );
  }

  getYN(yn?: boolean | null) {
    return this.conciliationService.getYN(yn);
  }

  /**
   *@desc Cargar la lista e instancias verificadas por el id del sujeto
   */
  async getVerifiedMedicalAttentions(subjectId: string) {
    await this.listVerifiedMedicalAttentions(subjectId)
      .then((verifiedMedicalAttention: VerifiedMedicalAttentionInstance[]) => {
        if (verifiedMedicalAttention?.length) {
          logger.debug('verifiedMedicalAttention', verifiedMedicalAttention);
          this.verifiedMedicalAttentionsBySubject = verifiedMedicalAttention;
        }
      })
      .catch((error: any) => {
        logger.error('listVerifiedMedicalAttentions error', error);
      });
  }

  /**
   * @desc filtro las intancias verificadas por el sujeto de las attenciones medicas
   * @param subjectId id del sujeto
   * @returns array de atenciones medicas del sujeto
   */
  async listVerifiedMedicalAttentions(subjectId: string): Promise<any[]> {
    let instances: any[] = [];
    const filter: ModelVerifiedMedicalAttentionInstanceConditionInput = {
      or: [{ subjectId: { eq: subjectId } }],
    };
    let response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ListVerifiedMedicalAttentionInstances,
      {
        filter,
        limit: 1000,
      }
    );
    let listQuery = response.data.listVerifiedMedicalAttentionInstances;
    instances = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.conciliationService.performGraphQLQuery(
        CONCILIATION_QUERIES.ListVerifiedMedicalAttentionInstances,
        {
          filter,
          limit: 1000,
          nextToken,
        }
      );
      listQuery = response.data.listVerifiedMedicalAttentionInstances;
      instances = instances.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    instances = instances
      .filter(Boolean)
      .filter(
        (medicalAttention: any) =>
          !medicalAttention._deleted &&
          medicalAttention.state !== InstanceState.CONCILIATION_DELETED
      );
    return instances;
  }

  /**
   *@desc cargar las configuraciones de los formularios
   * @param id del confEdiary
   */
  async getConfMedicalAttentionsByEdiary(id: string) {
    await this.getConfMedicalAttentions(id)
      .then((data: ConfMedicalAttentionByConfEdiaryQuery) => {
        if (data && data.items.length > 0) {
          logger.debug('ConfMedicalAttention', data);
          this.configMedicalAttentionsByEdiary = data;
        }
      })
      .catch((err) => {
        logger.error('errorGetConfMedicalAttentionsByEdiary', err);
      });
  }

  /**
   * @desc Buscar la configuracion de la medicacion segun el formulario correspondiente
   * @param formId del formulario
   */
  getConfMedicalAttentionByForm(formId: string) {
    return this.configMedicalAttentionsByEdiary?.items.find(
      (item: any) => item?.confFormId === formId
    );
  }

  getProgressMedicalAttentionValues() {
    const tamMedicalAttentions = this.medicalAttentionTable?.length || 0;
    const tamMedicalAttentionsColDays = this.preconditionTable?.length - 1 || 0;

    const tamVerifiedMedicalAttentions =
      this.medicalAttentionTable.filter((medicalAttention: any) => {
        return medicalAttention.verifiedMedicalAttention?.id;
      })?.length || 0;

    const tamVerifiedMedicalAttentionColDays =
      this.preconditionTable.filter(
        (day: any) => day.verifiedDayInstance?.hasMedicalAttentionCompleted
      )?.length || 0;

    return [
      tamMedicalAttentions,
      tamMedicalAttentionsColDays,
      tamVerifiedMedicalAttentions,
      tamVerifiedMedicalAttentionColDays,
    ];
  }

  getProgressMedicalAttentions() {
    let [
      tamMedicalAttentions,
      tamMedicalAttentionsColDays,
      tamVerifiedMedicalAttentions,
      tamVerifiedMedicalAttentionColDays,
    ] = this.getProgressMedicalAttentionValues();
    const progress =
      ((tamVerifiedMedicalAttentions + tamVerifiedMedicalAttentionColDays) /
        (tamMedicalAttentions + tamMedicalAttentionsColDays)) *
      100;
    return tamMedicalAttentions + tamMedicalAttentionsColDays === 0
      ? 100
      : progress;
  }

  getNewMedicalAttention(
    dayInstance: any,
    confFormId: string,
    subjectId: string,
    verifiedMedicalAttention: any
  ) {
    let configuration = this.getConfMedicalAttentionByForm(confFormId);
    if (!configuration) {
      configuration = this.getEmptyConfMedicalAttention();
    }
    const medicalAttention = {
      id: '',
      state: InstanceState.UNSIGNED,
      subjectId: subjectId,
      dayInstanceId: dayInstance.id,
      dayInstance: dayInstance,
      confForm: dayInstance.confDay?.confForm,
      finishDate: '',
      startDate: '',
      rememberFinishDate: null,
      rememberStartDate: null,
      reason: null,
      symptomsInstances: [],
      wasHospitalized: null,
      type: null,
    };
    const reason = this.addReason(configuration, medicalAttention);
    return this.newObjMedicalAttention(
      this.medicalAttentionTable.length,
      dayInstance,
      subjectId,
      medicalAttention,
      configuration,
      verifiedMedicalAttention,
      reason
    );
  }

  getDeleteMedicalAttention(comment: string) {
    return {
      state: InstanceState.DELETED,
      typeComment: comment,
      reasonComment: null,
      symptomsInstancesComment: null,
      rememberStartDateComment: null,
      startDateComment: null,
      rememberFinishDateComment: null,
      finishDateComment: null,
      wasHospitalizedComment: null,
      _changeReason: comment,
    };
  }

  getEmptyConfMedicalAttention(): ConfMedicalAttention {
    return {
      mode: MedicalAttentionConfigurationMode.OPEN_REASON,
      showHospitalization: false,
      showPrecondition: false,
      showRememberFinishDate: false,
      showRememberStartDate: false,
      showType: false,
      id: '12345678',
      isBindToSymptomOcurrencies: false,
      medicalAttentionTypes: [],
      helpText: '',
      _lastUser: '',
      _version: 1,
      __typename: 'ConfMedicalAttention',
      label: '',
      confEdiaryId: '',
      confFormId: '',
      projectId: '',
      _lastChangedAt: 0,
      _changeReason: '',
      createdAt: '',
      updatedAt: '',
    };
  }

  async updateOrDeleteMedicalAttentionTable(
    verifiedMedicalAttention: any,
    medicalAttention: any,
    deleteReason: string
  ) {
    let isDelete = false;
    const medicalAttentionInput: any =
      this.getDeleteMedicalAttention(deleteReason);

    //Si la instancia de atencion medica existe, actualiza el estado, si no borra la atencion medica
    if (!medicalAttention?.id) {
      //Quita la atencion medica de la tabla
      this.medicalAttentionTable = this.medicalAttentionTable.filter(
        (medicalAttention: any) => {
          return (
            verifiedMedicalAttention?.id !==
            medicalAttention?.verifiedMedicalAttention?.id
          );
        }
      );
      medicalAttentionInput.state = InstanceState.CONCILIATION_DELETED;
      isDelete = true;
    }

    verifiedMedicalAttention =
      await this.updateVerifiedMedicalAttentionInstance(
        medicalAttentionInput,
        verifiedMedicalAttention.id,
        verifiedMedicalAttention._version
      );

    return { verified: verifiedMedicalAttention, isDelete };
  }

  /**
   * Revisa y procesa las instancias de atención médica pendientes.
   * Esta función obtiene las atenciones médicas pendientes, crea instancias verificadas para cada una
   * y actualiza la tabla de atenciones médicas verificadas.
   *
   * @returns Una promesa que se resuelve cuando todas las instancias de atención médica hayan sido procesadas.
   */
  async reviewMedicalAttentionInstancesData(): Promise<void> {
    setTimeout(async () => {
      const pendingMedicalAttentionTable = this.getPendingMedicalAttentions();
      for (let medicalAttentionData of pendingMedicalAttentionTable) {
        //Obtiene la instancia verificada
        const createVerifiedMedicalAttentionInstance: any =
          this.getObjectVerifiedMedicalAttention(medicalAttentionData);
        //Guarda la instancia verificada en base de datos
        const verifiedMedicalAttentionInstance =
          await this.createVerifiedMedicalAttentionInstance(
            createVerifiedMedicalAttentionInstance
          );

        //Actualiza la tabla de atentiones medicas
        this.updateVerifiedMedicalAttentionTable(
          medicalAttentionData,
          verifiedMedicalAttentionInstance
        );
      }
    }, 3000);
  }

  getPendingMedicalAttentions(): VerifiedMedicalAttention[] {
    return this.medicalAttentionTable.filter(
      (medicalAttention: VerifiedMedicalAttention) =>
        !medicalAttention.verifiedMedicalAttention?.id
    );
  }

  /**
   * Convierte los datos de la atención médica verificada en un objeto de entrada para crear una instancia de atención médica verificada.
   *
   * @param medicalAttentionData - Los datos de la atención médica verificada.
   * @returns Un objeto que puede ser utilizado para crear una instancia de atención médica verificada.
   */
  getObjectVerifiedMedicalAttention(
    medicalAttentionData: VerifiedMedicalAttention
  ): CreateVerifiedMedicalAttentionInstanceInput {
    const medicalAttentionInstance =
      medicalAttentionData.medicalAttention as MedicalAttentionInstance;
    const confMedicalAttentionInstance =
      medicalAttentionData.configuration as ConfMedicalAttention;
    return {
      subjectId: this.conciliationService.subjectId,
      verifiedDayInstanceId: medicalAttentionData.verifiedDayInstanceId,
      medicalAttentionInstanceId:
        medicalAttentionData.medicalAttentionInstanceId,
      type: confMedicalAttentionInstance?.showType
        ? medicalAttentionInstance?.type
        : undefined,
      symptomsInstances: medicalAttentionInstance?.symptomsInstances,
      rememberStartDate: confMedicalAttentionInstance?.showRememberStartDate
        ? medicalAttentionInstance?.rememberStartDate
        : undefined,
      startDate:
        confMedicalAttentionInstance.showRememberStartDate &&
        medicalAttentionInstance?.rememberStartDate
          ? medicalAttentionInstance.startDate
          : undefined,
      rememberFinishDate: confMedicalAttentionInstance?.showRememberFinishDate
        ? medicalAttentionInstance.rememberFinishDate
        : undefined,
      finishDate:
        confMedicalAttentionInstance.showRememberFinishDate &&
        medicalAttentionInstance?.rememberFinishDate
          ? medicalAttentionInstance.finishDate
          : undefined,
      wasHospitalized: confMedicalAttentionInstance?.showHospitalization
        ? medicalAttentionInstance?.wasHospitalized
        : undefined,
      _lastUser: this.authService.getUsername(),
      _version: 1,
      _changeReason: '',
      state: InstanceState.UNSIGNED,
    };
  }

  updateVerifiedMedicalAttentionTable(
    medicalAttentionData: VerifiedMedicalAttention,
    verifiedMedicalAttentionInstance: VerifiedMedicalAttentionInstance
  ) {
    medicalAttentionData.verifiedMedicalAttention =
      verifiedMedicalAttentionInstance;
    const dayIndex = medicalAttentionData.index ?? 0;
    this.verifiedMedicalAttentionsBySubject.push(
      verifiedMedicalAttentionInstance
    );
    const medicalAttentionInstance =
      medicalAttentionData.medicalAttention as MedicalAttentionInstance;
    const reason = this.addReason(
      medicalAttentionData.configuration,
      medicalAttentionInstance
    );

    const updateTable: any = this.objMedicalAttention(
      dayIndex,
      medicalAttentionInstance?.dayInstance,
      medicalAttentionInstance,
      reason,
      medicalAttentionData.configuration,
      verifiedMedicalAttentionInstance
    );

    this.medicalAttentionTable = this.medicalAttentionTable?.map((day: any) =>
      day.index === dayIndex ? updateTable : day
    );
  }
}
interface VerifiedMedicalAttention {
  index?: number;
  dayName?: string;
  subjectId?: string;
  verifiedDayInstanceId?: string | null;
  medicalAttentionInstanceId?: string;
  configuration?: any;
  medicalAttention?: MedicalAttentionInstance;
  verifiedMedicalAttention?: VerifiedMedicalAttentionInstance;
  type?: RecordVerified;
  reason?: RecordVerified;
  symptomsInstances?: RecordVerified;
  wasHospitalized?: RecordVerified;
  rememberStartDate?: RecordVerified;
  startDate?: RecordVerified;
  rememberFinishDate?: RecordVerified;
  finishDate?: RecordVerified;
  state?: InstanceState | null;
  isEdition?: boolean;
}

interface RecordVerified {
  record?: any;
  verified?: any;
  comment?: string | null;
  indexComment?: number;
}
