import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { AlertAddEditComponent } from './alert/alert-add-edit/alert-add-edit.component';
import { AlertListComponent } from './alert/alert-list/alert-list.component';
import { ConciliationConfigurationComponent } from './conciliation-configuration/conciliation-configuration.component';
import { ConciliationHeaderComponent } from './conciliation-configuration/conciliation-header/conciliation-header.component';
import { WindowTimeFormComponent } from './conciliation-configuration/window-time-form/window-time-form.component';
import { DayAddEditComponent } from './day/day-add-edit/day-add-edit.component';
import { DayListComponent } from './day/day-list/day-list.component';
import { EdiaryReportStatusComponent } from './ediary-report-status/ediary-report-status.component';
import { EdiaryRoutingModule } from './ediary-routing.module';
import { FormAddEditComponent } from './form/form-add-edit/form-add-edit.component';
import { FormListComponent } from './form/form-list/form-list.component';
import { GeneralConfigurationComponent } from './general-configuration/general-configuration.component';
import { SymptomAddEditComponent } from './symptom/symptom-add-edit/symptom-add-edit.component';
import { SymptomListComponent } from './symptom/symptom-list/symptom-list.component';

@NgModule({
  declarations: [
    GeneralConfigurationComponent,
    SymptomListComponent,
    SymptomAddEditComponent,
    DayAddEditComponent,
    DayListComponent,
    FormListComponent,
    FormAddEditComponent,
    AlertAddEditComponent,
    AlertListComponent,
    EdiaryReportStatusComponent,
    ConciliationConfigurationComponent,
    WindowTimeFormComponent,
    ConciliationHeaderComponent,
  ],
  imports: [
    PrimengModule,
    SharedComponentsModule,
    EdiaryRoutingModule,
    ReactiveFormsModule,
  ],
  providers: [TranslatePipe],
  exports: [
    SymptomListComponent,
    SymptomAddEditComponent,
    AlertListComponent,
    AlertAddEditComponent,
  ],
})
export class EdiaryModule {}
