import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldGroup',
  pure: true,
})
export class FieldGroupPipe implements PipeTransform {
  /*Elimina las expresiones para que el campo se visible en los formularios de creación en el modulo del diseñador*/
  /*Si no se hace la transformación y el campo esta atado a otro, no se mostraria al momento de editarlo si la condicion no se cumple */
  transform(field: any, type: string = ''): any {
    field = JSON.parse(JSON.stringify(field));
    if (type === 'boolean') {
      if (field?.fieldGroup) return true;
      if (field?.fieldArray?.fieldGroup) return true;
      return false;
    }

    if (type === 'length') {
      if (field?.fieldArray) {
        return field?.fieldArray?.fieldGroup[0]?.fieldGroup?.length === 0;
      }

      if (field?.fieldGroup) {
        return field?.fieldGroup[0]?.fieldGroup?.length === 0;
      }
      return true;
    }

    if (field?.fieldArray) {
      return field.fieldArray.fieldGroup;
    }

    if (field?.fieldGroup) {
      return field.fieldGroup;
    }
    return field;
  }
}
