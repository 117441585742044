import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ReportExecutionService } from '../../../services/report-executation.service';
import {
  CreateSymptomInstanceInput,
  IntensityQuestionAnswerInput,
  UpdateSymptomInstanceInput,
} from 'src/app/modules/subjects/subjects.types';
import {
  ConfSymptom,
  Intensity,
  IntensityType,
} from 'src/app/modules/ediary/ediary.types';
import { InstanceState } from 'src/app/services/trialpal.types';

const logger = new Logger('ReportExecuttionSymptomAddEdit');
@Component({
  selector: 'app-add-edit-symptom',
  templateUrl: './add-edit-symptom.component.html',
  styleUrls: ['./add-edit-symptom.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AddEditSymptomComponent implements OnChanges, OnDestroy {
  @Input() confSymptom: ConfSymptom = {} as ConfSymptom;
  @Input() isEdition: boolean = false;
  @Input() symptomInstanceInput = {} as
    | CreateSymptomInstanceInput
    | UpdateSymptomInstanceInput; //Instancia donde cambiara los datos y posteriormente servira para actualizar la instancia original
  @Input() symptomInstance = {} as
    | CreateSymptomInstanceInput
    | UpdateSymptomInstanceInput; //Instancia original (No se tocará durante la transaccion)

  oldSymptomInstance: any = {};
  intensityScaleOptions: any[] = [];
  integerScaleOptions: number[] = [];
  decimalScaleOptions: number[] = [];
  booleanOptions: any[] = [];

  NUMERIC_VALUE: IntensityType = IntensityType.NUMERIC_VALUE;
  MULTIPLE_CHOICE: IntensityType = IntensityType.MULTIPLE_CHOICE;
  RANGE: IntensityType = IntensityType.RANGE;
  minSize: number = 0;
  maxSize: number = 1000;

  //Booleans
  showDecimalOption: boolean = false;
  currentDate: Date = new Date();
  currentMinDate: Date = new Date('01-01-2000');
  currentMaxDate: Date = new Date();
  //Numeric
  symptomInteger: string = '';
  symptomDecimal: string = '';

  changeReason: string = '';

  attributes = [
    'size',
    'intensity',
    'finishDate',
    'startDate',
    'rememberFinishDate',
    'rememberStartDate',
    'rememberNoValuesTaken',
    'intensityQuestionAnswers',
  ];

  constructor(private reportExecutionService: ReportExecutionService) {}

  ngOnChanges() {
    try {
      this.buildBooleanOptions();
      this.buildIntensityScaleOptions();
      this.buildQuestionsAnswerInput();
      this.buildIntegerAndDecimalOptions();
      this.buildMaxDate();
      this.buildSymptomInstanceDate();
      this.buildCurrentMaxDate();
      this.buildMinDate();
      this.buildMinMaxValue();
      this.oldSymptomInstance = structuredClone(this.symptomInstanceInput);

      logger.debug('Symptom info -->', this.symptomInstanceInput);
      logger.debug('ConfSymptom info -->', this.confSymptom);
    } catch (error) {
      logger.error('AddEdit symptom error -->', error);
    }
  }

  ngOnDestroy(): void {
    if (this.isSymptomInstanceChange && this.isEdition) {
      this.symptomInstanceInput._changeReason = this.changeReason;
    }

    logger.debug('submit symptomInstance -->', this.symptomInstanceInput);
  }

  buildMinMaxValue() {
    this.minSize = this.confSymptom?.minSize ?? 0;
    this.maxSize = this.confSymptom?.maxSize ?? 1000;
  }

  buildIntensityScaleOptions() {
    this.intensityScaleOptions = this.reportExecutionService.getValueScale(
      this.confSymptom
    );
  }

  //Transforma las fechas a un formato que pueda entender el calendario (primeng)
  buildSymptomInstanceDate() {
    const { isStartDateRequired, isFinishDateRequired } = this.confSymptom;
    const { startDate, finishDate } = this.symptomInstanceInput;
    if (isStartDateRequired && startDate) {
      this.symptomInstanceInput.startDate =
        this.reportExecutionService.getFormatDate(startDate);
    }

    if (isFinishDateRequired && finishDate) {
      this.symptomInstanceInput.finishDate =
        this.reportExecutionService.getFormatDate(finishDate);
    }
  }

  //Calcula cual es la fecha minima que puede tomar la fecha final
  buildMinDate() {
    this.currentMinDate = this.symptomInstanceInput.startDate
      ? new Date(this.symptomInstanceInput.startDate)
      : new Date('01-01-2000');
  }

  buildCurrentMaxDate() {
    this.currentMaxDate = this.symptomInstanceInput.finishDate
      ? new Date(this.symptomInstanceInput.finishDate)
      : new Date();
  }

  buildQuestionsAnswerInput() {
    if (
      this.confSymptom?.isIntensityQuestionsRequired &&
      this.confSymptom.intensityQuestions
    ) {
      const intensityQuestionAnswers: IntensityQuestionAnswerInput[] = [];

      for (const question of this.confSymptom.intensityQuestions) {
        if (!question) continue; //Valida si la pregunta existe
        //Valida si la pregunta no se respondio anteriormente
        const answer = this.getIntensityQuestionById(question.id);

        //Crea la pregunta con su respuesta
        const intensityQuestionAnswerInput: IntensityQuestionAnswerInput = {
          question: {
            id: question.id,
            order: question.order,
            question: question.question,
            intensity: question.intensity,
          },
          answer: answer?.answer ?? null,
        };

        intensityQuestionAnswers.push(intensityQuestionAnswerInput);
      }

      //Asigna nuevamente el arreglo de preguntas a la instancia de sintoma
      this.symptomInstanceInput.intensityQuestionAnswers =
        intensityQuestionAnswers;
    }
  }

  getIntensityQuestionById(questionId: string) {
    return this.symptomInstanceInput.intensityQuestionAnswers?.find(
      (answer: any) => answer.question?.id === questionId
    );
  }

  buildIntegerAndDecimalOptions() {
    if (this.confSymptom?.intensityType === this.RANGE) {
      this.integerScaleOptions = this.reportExecutionService.getIntRange(
        this.intensityScaleOptions
      );
      const [isDecimalRangeActive, decimalRange] =
        this.reportExecutionService.getDecimalRange(this.confSymptom);
      this.decimalScaleOptions = decimalRange;
      this.showDecimalOption = isDecimalRangeActive;
      //Calcula el size y los asigna a las variables
      const size = `${this.symptomInstanceInput?.size}`;
      const range = this.symptomInstanceInput?.size ? size.split('.') : [];

      this.symptomInteger = range[0] ?? '';
      this.symptomDecimal = range[1] ?? '';

      if (Number.isInteger(Number(size))) {
        this.symptomDecimal = '0';
      }
    }
  }

  buildBooleanOptions() {
    this.booleanOptions = this.reportExecutionService.booleanOptions;
  }

  //Rango de fechas que puede el usuario seleccionar
  buildMaxDate() {
    this.currentDate = this.reportExecutionService.getCurrentDate();
  }

  //Funcion que se encarga de guardar en la instancia del sintoma el size y la intensidad
  buildSymptomInstanceSizeAndIntensity() {
    this.buildSymptomInstanceSize();
    this.buildSymptomInstanceIntensity();
  }

  //Actualiza la instancia con el valor del sintoma en una pregunta de tipo rango
  buildSymptomInstanceSize() {
    const size = Number([this.symptomInteger, this.symptomDecimal].join('.'));
    if (size || size === 0) {
      this.symptomInstanceInput.size = Number(size);
    }
  }

  //Función que transforma los enteros y decimales en un valor, y calcula la intensidad
  buildSymptomInstanceIntensity() {
    if (this.confSymptom?.intensityType === this.RANGE) {
      const size = this.symptomInteger ?? 0;
      this.symptomInstanceInput.intensity = this.getIntensityFromRangeScale(
        Number(size)
      );
    } else if (this.confSymptom.intensityType === this.NUMERIC_VALUE) {
      this.symptomInstanceInput.intensity = Intensity.NONE;
    }

    //Obtiene la intensidad mayor entre las preguntas adiccionales y las previamente asignadas
    this.symptomInstanceInput.intensity = this.getMaxIntensity();
  }

  //Calcula la intensidad según el size enviado
  getIntensityFromRangeScale(size: number) {
    let intensity = null;
    for (let scaleOption of this.intensityScaleOptions) {
      if (scaleOption.startValue <= size && size <= scaleOption.endValue) {
        intensity = scaleOption.intensity;
      }
    }
    return intensity;
  }

  //Calcula cual es la intensidad mayor. si la actual o la de las preguntas adiccionales
  getMaxIntensity() {
    let intensity = this.symptomInstanceInput.intensity ?? Intensity.NONE;
    if (this.confSymptom.isIntensityQuestionsRequired) {
      let intensityQuestion =
        this.reportExecutionService.getIntensityFromQuestion(
          this.symptomInstanceInput.intensityQuestionAnswers
        );
      intensity = this.reportExecutionService.getIntensityOrder([
        {
          intensity: intensityQuestion,
        },
        {
          intensity: intensity,
        },
      ]);
    }
    return intensity;
  }

  //Resetea los cambios hechos cuando se indica que no se tomaron valores
  noValuesTakenChange() {
    if (this.symptomInstanceInput?.rememberNoValuesTaken) {
      this.symptomInstanceInput.size = null;
      this.symptomInstanceInput.intensity = null;
      this.symptomDecimal = '';
      this.symptomInteger = '';
    }
  }

  updateRememberDate() {
    if (!this.symptomInstanceInput.rememberStartDate) {
      this.symptomInstanceInput.startDate = '';
      this.buildMinDate();
    }

    if (!this.symptomInstanceInput.rememberFinishDate) {
      this.symptomInstanceInput.finishDate = '';
      this.buildCurrentMaxDate();
      this.updateSymptomInstanceState();
    }
  }

  updateSymptomInstanceState() {
    this.symptomInstanceInput.state = this.symptomInstanceInput.finishDate
      ? InstanceState.COMPLETED
      : InstanceState.ON_GOING;
  }

  get isSymptomInstanceChange() {
    return this.reportExecutionService.haveAttributesMutated(
      this.oldSymptomInstance,
      this.symptomInstanceInput,
      this.attributes
    );
  }
}
