import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { ReconciliationSectionsComponent } from './components/reconciliation-sections/reconciliation-sections.component';
import { TemperatureConfigureComponent } from './components/temperature/temperatureConfigure.component';
import { ReconciliationSectionDetailComponent } from './components/reconciliation-section-detail/reconciliation-section-detail.component';

@NgModule({
  declarations: [
    TemperatureConfigureComponent,
    ReconciliationSectionsComponent,
    ReconciliationSectionDetailComponent,
  ],
  imports: [
    CommonModule,
    PrimengModule,
    TranslateModule,
    FormsModule,
    SharedComponentsModule,
  ],
})
export class ConfigurationsModule {}
