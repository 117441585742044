import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ProjectService } from 'src/app/modules/project/project.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { DataInputComponent } from 'src/app/services/trialpal.types';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { DictionaryService } from 'src/app/shared/components/table-languages/dictionary.service';
import {
  TP2Entites,
  projectStateGlobal,
} from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import {
  ConfSymptom,
  CreateConfSymptomInput,
  HelpScreenDisplayOption,
  InjectionSite,
  Intensity,
  IntensityType,
  Symptom,
  SymptomType,
} from '../../ediary.types';

const logger = new Logger('tp2-logger-symptomAddEditPage');
@Component({
  selector: 'app-symptom-add-edit',
  templateUrl: './symptom-add-edit.component.html',
  styleUrls: ['./symptom-add-edit.component.scss'],
})
export class SymptomAddEditComponent implements OnInit {
  @ViewChild('f') form!: NgForm;
  confSymptom: CreateConfSymptomInput = {
    projectId: this.projectService.currentProject?.id || '',
    type: SymptomType.GENERAL,
    symptom: Symptom.FEVER,
    order: 1,
    isIntensityRequired: false,
    isStartDateRequired: false,
    isFinishDateRequired: false,
    isIntensityQuestionsRequired: false,
    showHelp: false,
    intensitySizeLabel: '',
    showNoValuesTaken: false,
    noValuesTakenLabel: '',
    intensityQuestions: [],
    minSize: null,
    maxSize: null,
    noneIntensityScaleOptions: {
      active: true,
      endValue: 0,
      helpText: '',
      startValue: 0,
    },
    lowIntensityScaleOptions: {
      active: true,
      endValue: 0,
      helpText: '',
      startValue: 0,
    },
    mediumIntensityScaleOptions: {
      active: true,
      endValue: 0,
      helpText: '',
      startValue: 0,
    },
    highIntensityScaleOptions: {
      active: true,
      endValue: 0,
      helpText: '',
      startValue: 0,
    },
    lifeThreateningScaleOptions: {
      active: true,
      endValue: 0,
      helpText: '',
      startValue: 0,
    },
    _lastUser: '',
  };

  isIntensityScaleOptionsError: boolean = false;

  decimalPlaces: number = 0;

  relatedEntityId: any = null;
  relatedEntity: string = '';
  isEdition = false;
  symptoms: any[] = [];
  icons: any[] = [];
  symptomTypes: any[] = [];
  injectionSites: any[] = [];
  injectionSides: any[] = [];
  intensityOptions: any[] = [];
  dataInputComponentOptions: any[] = [];
  intensityTypes: any[] = [];
  results: string[] = [];
  projectState: string = '';
  projectId: string = '';
  isQuestionInValid: boolean = false;
  requiresButtonHelpScreenLabel: boolean = false;
  private id: any = null;
  private expectedVersion: any = null;
  private originalConfSymptom: CreateConfSymptomInput = { ...this.confSymptom };
  readMode: boolean = false;
  maxSafeNumber = Number.MAX_SAFE_INTEGER;
  HelpScreenDisplayOptions: any[] = [];
  hasOnDemandHelpScreenSelected: boolean | undefined;
  constructor(
    private trialpalService: TrialpalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private readonly dictionaryService: DictionaryService,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.symptoms = this.ediaryService.getSymptoms();
    this.symptoms.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    this.icons = this.ediaryService.getSymptomIcons();
    this.symptomTypes = this.ediaryService.getSymptomTypes();
    this.injectionSites = this.ediaryService.getInjectionSites();
    this.injectionSides = this.ediaryService.getInjectionSides();
    this.intensityTypes = this.ediaryService.getIntensityTypes();
    this.HelpScreenDisplayOptions =
      this.ediaryService.getHelpScreenDisplayOption();
    this.intensityOptions = this.ediaryService.getIntensity();
    this.dataInputComponentOptions = this.trialpalService
      .getTranslatedEnum(
        'general.configurations.enums.dataInputComponent',
        DataInputComponent
      )
      .filter((component: any) => component.value !== 'DROPDOWN');
    this.projectState = this.config.data.projectState;
    this.relatedEntityId = this.config.data.relatedEntityId;
    this.relatedEntity = this.config.data.relatedEntity;
    this.projectId = this.config.data.projectId;
    this.isEdition = this.config.data.isEdition;
    this.readMode = this.config.data.readMode;

    if (this.config.data.confSymptom) {
      this.requiresButtonHelpScreenLabel =
        !!this.config.data.confSymptom.buttonHelpScreenLabel;
      this.id = this.config.data.confSymptom.id;
      this.expectedVersion = this.config.data.confSymptom._version;

      this.trialpalService.cleanQueryResponse(this.config.data.confSymptom);
      this.trialpalService.cleanQueryResponse(
        this.config.data.confSymptom.noneIntensityScaleOptions
      );
      this.trialpalService.cleanQueryResponse(
        this.config.data.confSymptom.lowIntensityScaleOptions
      );
      this.trialpalService.cleanQueryResponse(
        this.config.data.confSymptom.mediumIntensityScaleOptions
      );
      this.trialpalService.cleanQueryResponse(
        this.config.data.confSymptom.highIntensityScaleOptions
      );
      this.trialpalService.cleanQueryResponse(
        this.config.data.confSymptom.lifeThreateningScaleOptions
      );
      delete this.config.data.confSymptom.confReport;
      delete this.config.data.confSymptom.confEdiary;
      if (!this.config.data.confSymptom.intensityQuestions) {
        delete this.config.data.confSymptom.intensityQuestions;
      }
      Object.assign(this.confSymptom, this.config.data.confSymptom);
      if (this.confSymptom.intensityQuestions) {
        this.confSymptom.intensityQuestions =
          this.confSymptom.intensityQuestions.map((y: any) => ({
            id: y.id,
            order: y.order,
            question: y.question,
            intensity: y.intensity,
          }));
      }
      this.originalConfSymptom = JSON.parse(JSON.stringify(this.confSymptom));
    }

    this.decimalPlaces = this.confSymptom.decimalPlaces
      ? this.confSymptom?.decimalPlaces
      : 0;
    this.onHelpScreenDisplayOptionChange();
  }
  onItensityQuestionsChange(): void {
    if (this.confSymptom.isIntensityQuestionsRequired) {
      this.confSymptom.intensityQuestions = [
        {
          id: new Date().getTime().toString(),
          order: 1,
          question: '',
          intensity: Intensity.NONE,
        },
      ];
    } else {
      this.confSymptom.intensityQuestions = [];
    }
  }

  onSubmit(form: NgForm): void {
    this.confSymptom.decimalPlaces = this.decimalPlaces;
    logger.debug('Form submitted', this.confSymptom);
    this.removeSpaces();
    this.confirmErrors(form);

    if (
      form.valid &&
      this.confSymptom.order &&
      !this.isIntensityScaleOptionsError
    ) {
      this.handleValidForm();
    } else {
      this.handleInvalidForm();
    }
  }

  handleValidForm(): void {
    if (!this.confSymptom.isIntensityRequired) {
      this.confIntensityScaleOptionNotIntensityRequired();
    }

    if (this.isEdition) {
      this.handleEdition();
    } else {
      this.trialpalService.showSpinner('symptom.entity', 'CREATE');
      this.setRelatedEntity();
      this.createSymptom();
    }
  }

  handleEdition(): void {
    switch (this.projectState) {
      case projectStateGlobal.IN_DESIGN:
        this.editSymptom();
        break;
      case projectStateGlobal.IN_PROGRESS:
        this.confirmEditSymptom();
        break;
      default:
        this.handleInvalidEditionState();
        break;
    }
  }

  confirmEditSymptom(): void {
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'symptom.symptomEditConfirmationText'
      ),
      accept: () => {
        this.editSymptom();
      },
    });
  }

  handleInvalidEditionState(): void {
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: this.trialpalService.translateService.instant(
        'symptom.errorMessageEditingSymptom.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'symptom.errorMessageEditingSymptom.detail'
      ),
    });
  }

  handleInvalidForm(): void {
    this.trialpalService.showInvalidFormError();
  }

  setRelatedEntity(): void {
    if (this.relatedEntity === 'EDIARY') {
      this.confSymptom.confEdiaryId = this.relatedEntityId;
    } else {
      this.confSymptom.confReportId = this.relatedEntityId;
    }
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.confSymptom.symptomLabel = this.confSymptom.symptomLabel?.trim();
    this.confSymptom.intensitySizeLabel =
      this.confSymptom.intensitySizeLabel?.trim();

    if (this.confSymptom.noneIntensityScaleOptions?.active) {
      this.confSymptom.noneIntensityScaleOptions.helpText =
        this.confSymptom.noneIntensityScaleOptions?.helpText.trim();
    }

    if (this.confSymptom.lowIntensityScaleOptions?.active) {
      this.confSymptom.lowIntensityScaleOptions.helpText =
        this.confSymptom.lowIntensityScaleOptions?.helpText.trim();
    }

    if (this.confSymptom.mediumIntensityScaleOptions?.active) {
      this.confSymptom.mediumIntensityScaleOptions.helpText =
        this.confSymptom.mediumIntensityScaleOptions?.helpText.trim();
    }

    if (this.confSymptom.highIntensityScaleOptions?.active) {
      this.confSymptom.highIntensityScaleOptions.helpText =
        this.confSymptom.highIntensityScaleOptions?.helpText.trim();
    }
    if (this.confSymptom.intensityQuestions) {
      this.confSymptom.intensityQuestions =
        this.confSymptom.intensityQuestions.map((x: any) => ({
          ...x,
          question: x.question?.trim(),
        }));
    }
    if (
      this.confSymptom.showHelp &&
      this.hasOnDemandHelpScreenSelected &&
      this.requiresButtonHelpScreenLabel
    ) {
      this.confSymptom.buttonHelpScreenLabel =
        this.confSymptom.buttonHelpScreenLabel?.trim();
    }
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.validateRanges(form);
    if (
      this.confSymptom.intensityType === IntensityType.RANGE ||
      this.confSymptom.intensityType === IntensityType.NUMERIC_VALUE ||
      this.confSymptom.intensityType === IntensityType.MULTIPLE_CHOICE
    ) {
      this.trialpalService.validateSpaces(
        this.confSymptom.intensitySizeLabel,
        'intensitySizeLabel',
        form
      );
    }

    if (this.confSymptom.noneIntensityScaleOptions?.active) {
      this.trialpalService.validateSpaces(
        this.confSymptom.noneIntensityScaleOptions?.helpText,
        'noneHT',
        form
      );
    }

    if (this.confSymptom.lowIntensityScaleOptions?.active) {
      this.trialpalService.validateSpaces(
        this.confSymptom.lowIntensityScaleOptions?.helpText,
        'lowHT',
        form
      );
    }

    if (this.confSymptom.mediumIntensityScaleOptions?.active) {
      this.trialpalService.validateSpaces(
        this.confSymptom.mediumIntensityScaleOptions?.helpText,
        'medHT',
        form
      );
    }

    if (this.confSymptom.highIntensityScaleOptions?.active) {
      this.trialpalService.validateSpaces(
        this.confSymptom.highIntensityScaleOptions?.helpText,
        'highHT',
        form
      );
    }

    if (this.confSymptom.showHelp) {
      //envia el html a un objeto HMTL y luego obtiene el texto sin espacios
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = this.confSymptom.symptomHelp ?? '';
      this.trialpalService.validateSpaces(
        htmlObject.innerText.trim(),
        'symptomHelp',
        form
      );

      if (this.hasOnDemandHelpScreenSelected) {
        this.trialpalService.validateSpaces(
          this.confSymptom.buttonHelpScreenLabel,
          'buttonHelpScreenLabel',
          form
        );
      }
    }
  }
  confIntensityScaleOptionNotIntensityRequired(): void {
    this.confSymptom.intensityType = null;
    if (this.confSymptom.noneIntensityScaleOptions) {
      this.confSymptom.noneIntensityScaleOptions.active = false;
    }
    if (this.confSymptom.lowIntensityScaleOptions) {
      this.confSymptom.lowIntensityScaleOptions.active = false;
    }
    if (this.confSymptom.mediumIntensityScaleOptions) {
      this.confSymptom.mediumIntensityScaleOptions.active = false;
    }
    if (this.confSymptom.highIntensityScaleOptions) {
      this.confSymptom.highIntensityScaleOptions.active = false;
    }
    if (this.confSymptom.lifeThreateningScaleOptions) {
      this.confSymptom.lifeThreateningScaleOptions.active = false;
    }
  }
  /**
   * Esta funcion sirve para agregar una nueva pregunta
   */
  addQuestion(addQuestion: any, i: number): void {
    if (this.confSymptom.intensityQuestions) {
      if (
        !this.confSymptom.intensityQuestions.every(
          (x) => x && x.question.trim() !== ''
        ) ||
        addQuestion.order == null
      ) {
        this.isQuestionInValid = true;
        this.trialpalService.showInvalidFormError();
      } else {
        this.isQuestionInValid = false;
        this.confSymptom.intensityQuestions.push({
          id: i + 1 + new Date().getTime().toString(),
          order: 1,
          question: '',
          intensity: Intensity.NONE,
        });
      }
    }
  }
  /**
   * Esta funcion sirve para eliminar una nueva pregunta
   */
  deleteQuestion(id: string): void {
    if (this.confSymptom.intensityQuestions) {
      this.confSymptom.intensityQuestions =
        this.confSymptom.intensityQuestions.filter(
          (val) => val && val.id !== id
        );
    }
  }

  onCancel(): void {
    this.ref.close(null);
  }

  getSymptomIconName(value: string): string {
    return this.icons.find((e) => e.value === value).name;
  }

  onChangeInjectionSite(): void {
    if (this.confSymptom.injectionSite === InjectionSite.NA) {
      this.confSymptom.injectionSide = null;
    }
  }

  onChangeSymptomType(): void {
    this.confSymptom.injectionSite = null;
    this.confSymptom.injectionSide = null;
  }

  changeDecimal() {
    if (this.confSymptom.intensityType === IntensityType.MULTIPLE_CHOICE) {
      this.decimalPlaces = 0;
      this.confSymptom.isIntensityQuestionsRequired = false;
      this.validateRangeIntensityOptions();
    } else {
      this.decimalPlaces = this.confSymptom.decimalPlaces ?? 0;
    }
    if (this.confSymptom.intensityType === IntensityType.RANGE) {
      this.validateRangeIntensityOptions();
    }
  }

  editSymptom(): void {
    this.trialpalService.showSpinner('symptom.entity', 'UPDATE');
    logger.debug('updateConfSymptom', this.confSymptom);
    this.ediaryService
      .updateConfSymptom(this.confSymptom, this.id, this.expectedVersion)
      .then((res) => {
        logger.debug('updateConfSymptom response', res);
        delete res.project;
        this.trialpalService.showMutationSuccess('symptom.entity', 'UPDATE');
        this.ref.close(res);
      })
      .catch((err) => {
        this.trialpalService.showServiceError(
          'symptom.actions.updateConfSymptom',
          err
        );
        logger.error('updateConfSymptom error', err);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  createSymptom(): void {
    logger.debug(this.confSymptom, 'confSymptom');
    this.ediaryService
      .createConfSymptom(this.confSymptom)
      .then((res) => {
        logger.debug('createConfSymptom response', res);
        this.trialpalService.showMutationSuccess('symptom.entity', 'CREATE');
        this.ref.close(res);
      })
      .catch((err) => {
        logger.error('createConfSymptom', err);
        this.trialpalService.showServiceError(
          'symptom.actions.createConfSymptom',
          err
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  onChangeIntensityConfiguration() {
    if (
      this.confSymptom.highIntensityScaleOptions &&
      !this.confSymptom.highIntensityScaleOptions?.active
    ) {
      this.confSymptom.highIntensityScaleOptions.startValue = 0;
      this.confSymptom.highIntensityScaleOptions.endValue = 0;
      this.confSymptom.highIntensityScaleOptions.helpText = '';
    }
    if (
      this.confSymptom.mediumIntensityScaleOptions &&
      !this.confSymptom.mediumIntensityScaleOptions?.active
    ) {
      this.confSymptom.mediumIntensityScaleOptions.startValue = 0;
      this.confSymptom.mediumIntensityScaleOptions.endValue = 0;
      this.confSymptom.mediumIntensityScaleOptions.helpText = '';
    }
    if (
      this.confSymptom.lowIntensityScaleOptions &&
      !this.confSymptom.lowIntensityScaleOptions?.active
    ) {
      this.confSymptom.lowIntensityScaleOptions.startValue = 0;
      this.confSymptom.lowIntensityScaleOptions.endValue = 0;
      this.confSymptom.lowIntensityScaleOptions.helpText = '';
    }
    if (
      this.confSymptom.lifeThreateningScaleOptions &&
      !this.confSymptom.lifeThreateningScaleOptions?.active
    ) {
      this.confSymptom.lifeThreateningScaleOptions.startValue = 0;
      this.confSymptom.lifeThreateningScaleOptions.endValue = 0;
      this.confSymptom.lifeThreateningScaleOptions.helpText = '';
    }
    if (
      this.confSymptom.noneIntensityScaleOptions &&
      !this.confSymptom.noneIntensityScaleOptions?.active
    ) {
      this.confSymptom.noneIntensityScaleOptions.startValue = 0;
      this.confSymptom.noneIntensityScaleOptions.endValue = 0;
      this.confSymptom.noneIntensityScaleOptions.helpText = '';
    }
  }

  openAuditModal() {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity: TP2Entites.CONFSYMPTOM,
        description: this.trialpalService.translateService.instant(
          'ediary.components.generalConfiguration.requiredSymptoms'
        ),
        idRecord: this.confSymptom.id,
      },
      width: '95%',
      height: '95%',
    });
  }
  resetIntensityType() {
    this.confSymptom.intensityType = null;
    this.confSymptom.showNoValuesTaken = false;
    this.confSymptom.isIntensityQuestionsRequired = false;
  }
  onRequiresButtonHelpScreenLabelChange() {
    if (!this.requiresButtonHelpScreenLabel) {
      this.confSymptom.buttonHelpScreenLabel = null;
    }
  }
  onHelpScreenDisplayOptionChange() {
    logger.debug(
      'onHelpScreenDisplayOptionChange',
      this.confSymptom.helpScreenDisplayOptions
    );
    this.hasOnDemandHelpScreenSelected =
      this.confSymptom.helpScreenDisplayOptions?.includes(
        HelpScreenDisplayOption.ON_DEMAND
      );
  }
  validateRanges(form: NgForm) {
    this.confSymptom.isRangeAlertActive &&
      this.checkForError(
        this.confSymptom.minValueForAlert,
        this.confSymptom.maxValueForAlert,
        'maxValueForAlert',
        form
      );
    this.confSymptom.intensityType === IntensityType.NUMERIC_VALUE &&
      this.checkForError(
        this.confSymptom.minSize,
        this.confSymptom.maxSize,
        'maxSize',
        form
      );
    if (
      this.confSymptom.intensityType === IntensityType.RANGE ||
      this.confSymptom.intensityType === IntensityType.MULTIPLE_CHOICE
    ) {
      this.validateRangeIntensityOptions();
    }
  }

  validateRangeIntensityOptions() {
    this.isIntensityScaleOptionsError = false;
    const intensityScaleOptions = this.getIntensityScaleOptions();
    for (let intensityOption of intensityScaleOptions) {
      if (intensityOption.active) {
        const hasStartValueError = this.validateRange(
          intensityOption.intensityOptionName,
          true,
          this.form.controls[intensityOption.controlMinName].value
        );
        let hasEndValueError = false;
        if (this.confSymptom.intensityType === IntensityType.RANGE) {
          hasEndValueError = this.validateRange(
            intensityOption.intensityOptionName,
            false,
            this.form.controls[intensityOption.controlMaxName].value
          );
        }
        if (
          (hasStartValueError || hasEndValueError) &&
          !this.isIntensityScaleOptionsError
        ) {
          this.isIntensityScaleOptionsError = true;
        }
      }
    }
  }
  checkForError(
    minValue: number | null | undefined,
    maxValue: number | null | undefined,
    control: string,
    form: NgForm
  ) {
    if (!form.controls?.[control]) return;
    if (maxValue && minValue && maxValue < minValue) {
      form.controls?.[control]?.setErrors({ wrongRange: true });
    } else {
      form.controls?.[control].value &&
        form.controls?.[control]?.setErrors(null);
    }
  }

  validateRange(
    intensityOption: IntensityOption,
    isStartValue: boolean,
    intensityValue: number | undefined
  ) {
    const { intensityPreviousScaleOptions, intensityNextScaleOptions } =
      this.getPreviousAndNextIntensityScaleOptions(
        intensityOption,
        isStartValue
      );
    if (!intensityValue) return false;
    const allBelowValue = intensityPreviousScaleOptions.every(
      (intensityScaleOption: number) => intensityScaleOption < intensityValue
    );

    const allAboveValue = intensityNextScaleOptions.every(
      (intensityScaleOption: number) => intensityScaleOption > intensityValue
    );
    let isSequence = this.validateSequence(
      intensityValue,
      intensityPreviousScaleOptions,
      isStartValue
    );

    return !allBelowValue || !allAboveValue || !isSequence;
  }

  validateSequence(
    intensityValue: number,
    intensityPreviousScaleOptions: number[],
    isStartValue: boolean
  ): boolean {
    const previousIntensityValue =
      intensityPreviousScaleOptions[intensityPreviousScaleOptions.length - 1];
    if (!isStartValue || !previousIntensityValue) return true;
    const decimals = [1, 0.1, 0.01];
    return (
      previousIntensityValue + decimals[this.decimalPlaces] === intensityValue
    );
  }

  getPreviousAndNextIntensityScaleOptions(
    intensityOption: IntensityOption,
    isStartValue: boolean
  ): {
    intensityPreviousScaleOptions: number[];
    intensityNextScaleOptions: number[];
  } {
    const intensityScaleOptions = this.getIntensityScaleOptions();
    const intensityScaleOptionIndex = intensityScaleOptions.findIndex(
      (intensityScaleOption) => {
        return intensityScaleOption.intensityOptionName === intensityOption;
      }
    );

    if (intensityScaleOptionIndex === -1) {
      return {
        intensityPreviousScaleOptions: [],
        intensityNextScaleOptions: [],
      };
    }

    const intensityPreviousScaleOptions = this.getIntensityPreviousScaleOptions(
      intensityScaleOptions,
      intensityScaleOptionIndex,
      isStartValue
    );
    const intensityNextScaleOptions = this.getIntensityNextScaleOptions(
      intensityScaleOptions,
      intensityScaleOptionIndex,
      isStartValue
    );
    return { intensityPreviousScaleOptions, intensityNextScaleOptions };
  }

  getIntensityScaleOptions(): IntensityScaleOption[] {
    const confSymptom = this.confSymptom as ConfSymptom;
    return [
      {
        startValue: confSymptom.noneIntensityScaleOptions?.startValue,
        endValue: confSymptom.noneIntensityScaleOptions?.endValue,
        active: confSymptom.noneIntensityScaleOptions?.active,
        intensityOptionName: 'none',
        controlMinName: 'noneR1',
        controlMaxName: 'noneR2',
      },
      {
        startValue: confSymptom.lowIntensityScaleOptions?.startValue,
        endValue: confSymptom.lowIntensityScaleOptions?.endValue,
        active: confSymptom.lowIntensityScaleOptions?.active,
        intensityOptionName: 'low',
        controlMinName: 'lowR1',
        controlMaxName: 'lowR2',
      },
      {
        startValue: confSymptom.mediumIntensityScaleOptions?.startValue,
        endValue: confSymptom.mediumIntensityScaleOptions?.endValue,
        active: confSymptom.mediumIntensityScaleOptions?.active,
        intensityOptionName: 'medium',
        controlMinName: 'medR1',
        controlMaxName: 'medR2',
      },
      {
        startValue: confSymptom.highIntensityScaleOptions?.startValue,
        endValue: confSymptom.highIntensityScaleOptions?.endValue,
        active: confSymptom.highIntensityScaleOptions?.active,
        intensityOptionName: 'high',
        controlMinName: 'highR1',
        controlMaxName: 'highR2',
      },
      {
        startValue: confSymptom.lifeThreateningScaleOptions?.startValue,
        endValue: confSymptom.lifeThreateningScaleOptions?.endValue,
        active: confSymptom.lifeThreateningScaleOptions?.active,
        intensityOptionName: 'lifeThreatening',
        controlMinName: 'lifeR1',
        controlMaxName: 'lifeR2',
      },
    ];
  }

  getIntensityPreviousScaleOptions(
    intensityScaleOptions: IntensityScaleOption[],
    intensityScaleOptionIndex: number,
    isStartValue: boolean
  ): number[] {
    return intensityScaleOptions
      .slice(0, intensityScaleOptionIndex + 1)
      .filter((intensityScaleOption) => intensityScaleOption.active)
      .flatMap((intensityScaleOption, index, intensityScaleOptions) => {
        if (isStartValue && index === intensityScaleOptions.length - 1) {
          return [];
        }
        if (
          index === intensityScaleOptions.length - 1 ||
          this.confSymptom.intensityType === IntensityType.MULTIPLE_CHOICE
        ) {
          return [intensityScaleOption.startValue];
        }
        return [intensityScaleOption.startValue, intensityScaleOption.endValue];
      })
      .filter(
        (intensityValue) => Boolean(intensityValue) || intensityValue === 0
      ) as number[];
  }

  getIntensityNextScaleOptions(
    intensityScaleOptions: IntensityScaleOption[],
    intensityScaleOptionIndex: number,
    isStartValue: boolean
  ): number[] {
    return intensityScaleOptions
      .slice(intensityScaleOptionIndex, intensityScaleOptions.length)
      .filter((intensityScaleOption) => intensityScaleOption.active)
      .flatMap((intensityScaleOption, index) => {
        if (!isStartValue && index === 0) return [];
        if (this.confSymptom.intensityType === IntensityType.MULTIPLE_CHOICE) {
          if (index === 0) return [];
          return [intensityScaleOption.startValue];
        }
        if (index === 0) {
          return [intensityScaleOption.endValue];
        }
        return [intensityScaleOption.startValue, intensityScaleOption.endValue];
      })
      .filter(
        (intensityValue) => Boolean(intensityValue) || intensityValue === 0
      ) as number[];
  }
}

export type IntensityOption =
  | 'none'
  | 'low'
  | 'medium'
  | 'high'
  | 'lifeThreatening';

interface IntensityScaleOption {
  startValue: number | undefined;
  endValue: number | undefined;
  active: boolean | undefined;
  intensityOptionName: IntensityOption;
  controlMinName: string;
  controlMaxName: string;
}
