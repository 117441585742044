/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateChatMessageBySiteId: OnCreateChatMessageBySiteIdSubscription;
  onCreateChatMessageByUserId: OnCreateChatMessageByUserIdSubscription;
  onCreateBackupInstance: OnCreateBackupInstanceSubscription;
  onUpdateBackupInstance: OnUpdateBackupInstanceSubscription;
  onDeleteBackupInstance: OnDeleteBackupInstanceSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateRole: OnCreateRoleSubscription;
  onUpdateRole: OnUpdateRoleSubscription;
  onDeleteRole: OnDeleteRoleSubscription;
  onCreateConfChatUser: OnCreateConfChatUserSubscription;
  onUpdateConfChatUser: OnUpdateConfChatUserSubscription;
  onDeleteConfChatUser: OnDeleteConfChatUserSubscription;
  onCreateChatMessage: OnCreateChatMessageSubscription;
  onUpdateChatMessage: OnUpdateChatMessageSubscription;
  onDeleteChatMessage: OnDeleteChatMessageSubscription;
  onCreateVerifiedDayInstance: OnCreateVerifiedDayInstanceSubscription;
  onUpdateVerifiedDayInstance: OnUpdateVerifiedDayInstanceSubscription;
  onDeleteVerifiedDayInstance: OnDeleteVerifiedDayInstanceSubscription;
  onCreateCommentConciliationInstance: OnCreateCommentConciliationInstanceSubscription;
  onUpdateCommentConciliationInstance: OnUpdateCommentConciliationInstanceSubscription;
  onDeleteCommentConciliationInstance: OnDeleteCommentConciliationInstanceSubscription;
  onCreateVerifiedSymptomInstance: OnCreateVerifiedSymptomInstanceSubscription;
  onUpdateVerifiedSymptomInstance: OnUpdateVerifiedSymptomInstanceSubscription;
  onDeleteVerifiedSymptomInstance: OnDeleteVerifiedSymptomInstanceSubscription;
  onCreateVerifiedSymptomRecordLog: OnCreateVerifiedSymptomRecordLogSubscription;
  onUpdateVerifiedSymptomRecordLog: OnUpdateVerifiedSymptomRecordLogSubscription;
  onDeleteVerifiedSymptomRecordLog: OnDeleteVerifiedSymptomRecordLogSubscription;
  onCreateVerifiedMedicationInstance: OnCreateVerifiedMedicationInstanceSubscription;
  onUpdateVerifiedMedicationInstance: OnUpdateVerifiedMedicationInstanceSubscription;
  onDeleteVerifiedMedicationInstance: OnDeleteVerifiedMedicationInstanceSubscription;
  onCreateVerifiedMedicalAttentionInstance: OnCreateVerifiedMedicalAttentionInstanceSubscription;
  onUpdateVerifiedMedicalAttentionInstance: OnUpdateVerifiedMedicalAttentionInstanceSubscription;
  onDeleteVerifiedMedicalAttentionInstance: OnDeleteVerifiedMedicalAttentionInstanceSubscription;
  onCreateVerifiedTemperatureRecordLog: OnCreateVerifiedTemperatureRecordLogSubscription;
  onUpdateVerifiedTemperatureRecordLog: OnUpdateVerifiedTemperatureRecordLogSubscription;
  onDeleteVerifiedTemperatureRecordLog: OnDeleteVerifiedTemperatureRecordLogSubscription;
  onCreateProject: OnCreateProjectSubscription;
  onUpdateProject: OnUpdateProjectSubscription;
  onDeleteProject: OnDeleteProjectSubscription;
  onCreateConfVisitGroup: OnCreateConfVisitGroupSubscription;
  onUpdateConfVisitGroup: OnUpdateConfVisitGroupSubscription;
  onDeleteConfVisitGroup: OnDeleteConfVisitGroupSubscription;
  onCreateSite: OnCreateSiteSubscription;
  onUpdateSite: OnUpdateSiteSubscription;
  onDeleteSite: OnDeleteSiteSubscription;
  onCreateAuthorizedSite: OnCreateAuthorizedSiteSubscription;
  onUpdateAuthorizedSite: OnUpdateAuthorizedSiteSubscription;
  onDeleteAuthorizedSite: OnDeleteAuthorizedSiteSubscription;
  onCreateConfReport: OnCreateConfReportSubscription;
  onUpdateConfReport: OnUpdateConfReportSubscription;
  onDeleteConfReport: OnDeleteConfReportSubscription;
  onCreateConfReportBySubject: OnCreateConfReportBySubjectSubscription;
  onUpdateConfReportBySubject: OnUpdateConfReportBySubjectSubscription;
  onDeleteConfReportBySubject: OnDeleteConfReportBySubjectSubscription;
  onCreateConfEDiary: OnCreateConfEDiarySubscription;
  onUpdateConfEDiary: OnUpdateConfEDiarySubscription;
  onDeleteConfEDiary: OnDeleteConfEDiarySubscription;
  onCreateConfDay: OnCreateConfDaySubscription;
  onUpdateConfDay: OnUpdateConfDaySubscription;
  onDeleteConfDay: OnDeleteConfDaySubscription;
  onCreateConfForm: OnCreateConfFormSubscription;
  onUpdateConfForm: OnUpdateConfFormSubscription;
  onDeleteConfForm: OnDeleteConfFormSubscription;
  onCreateConfSymptom: OnCreateConfSymptomSubscription;
  onUpdateConfSymptom: OnUpdateConfSymptomSubscription;
  onDeleteConfSymptom: OnDeleteConfSymptomSubscription;
  onCreateConfFormConfSymptom: OnCreateConfFormConfSymptomSubscription;
  onUpdateConfFormConfSymptom: OnUpdateConfFormConfSymptomSubscription;
  onDeleteConfFormConfSymptom: OnDeleteConfFormConfSymptomSubscription;
  onCreateConfMedication: OnCreateConfMedicationSubscription;
  onUpdateConfMedication: OnUpdateConfMedicationSubscription;
  onDeleteConfMedication: OnDeleteConfMedicationSubscription;
  onCreateConfMedicalAttention: OnCreateConfMedicalAttentionSubscription;
  onUpdateConfMedicalAttention: OnUpdateConfMedicalAttentionSubscription;
  onDeleteConfMedicalAttention: OnDeleteConfMedicalAttentionSubscription;
  onCreateConfTemperature: OnCreateConfTemperatureSubscription;
  onUpdateConfTemperature: OnUpdateConfTemperatureSubscription;
  onDeleteConfTemperature: OnDeleteConfTemperatureSubscription;
  onCreateConfMultimedia: OnCreateConfMultimediaSubscription;
  onUpdateConfMultimedia: OnUpdateConfMultimediaSubscription;
  onDeleteConfMultimedia: OnDeleteConfMultimediaSubscription;
  onCreateConfOtherSymptoms: OnCreateConfOtherSymptomsSubscription;
  onUpdateConfOtherSymptoms: OnUpdateConfOtherSymptomsSubscription;
  onDeleteConfOtherSymptoms: OnDeleteConfOtherSymptomsSubscription;
  onCreateConfAlert: OnCreateConfAlertSubscription;
  onUpdateConfAlert: OnUpdateConfAlertSubscription;
  onDeleteConfAlert: OnDeleteConfAlertSubscription;
  onCreateConfDictionary: OnCreateConfDictionarySubscription;
  onUpdateConfDictionary: OnUpdateConfDictionarySubscription;
  onDeleteConfDictionary: OnDeleteConfDictionarySubscription;
  onCreateConfInformedConsent: OnCreateConfInformedConsentSubscription;
  onUpdateConfInformedConsent: OnUpdateConfInformedConsentSubscription;
  onDeleteConfInformedConsent: OnDeleteConfInformedConsentSubscription;
  onCreateConfICSection: OnCreateConfICSectionSubscription;
  onUpdateConfICSection: OnUpdateConfICSectionSubscription;
  onDeleteConfICSection: OnDeleteConfICSectionSubscription;
  onCreateConfICQuestion: OnCreateConfICQuestionSubscription;
  onUpdateConfICQuestion: OnUpdateConfICQuestionSubscription;
  onDeleteConfICQuestion: OnDeleteConfICQuestionSubscription;
  onCreateConfICAnswer: OnCreateConfICAnswerSubscription;
  onUpdateConfICAnswer: OnUpdateConfICAnswerSubscription;
  onDeleteConfICAnswer: OnDeleteConfICAnswerSubscription;
  onCreateInformedConsentInstance: OnCreateInformedConsentInstanceSubscription;
  onUpdateInformedConsentInstance: OnUpdateInformedConsentInstanceSubscription;
  onDeleteInformedConsentInstance: OnDeleteInformedConsentInstanceSubscription;
  onCreateConsentedUser: OnCreateConsentedUserSubscription;
  onUpdateConsentedUser: OnUpdateConsentedUserSubscription;
  onDeleteConsentedUser: OnDeleteConsentedUserSubscription;
  onCreateICSectionInstance: OnCreateICSectionInstanceSubscription;
  onUpdateICSectionInstance: OnUpdateICSectionInstanceSubscription;
  onDeleteICSectionInstance: OnDeleteICSectionInstanceSubscription;
  onCreateICQuestionInstance: OnCreateICQuestionInstanceSubscription;
  onUpdateICQuestionInstance: OnUpdateICQuestionInstanceSubscription;
  onDeleteICQuestionInstance: OnDeleteICQuestionInstanceSubscription;
  onCreateSubject: OnCreateSubjectSubscription;
  onUpdateSubject: OnUpdateSubjectSubscription;
  onDeleteSubject: OnDeleteSubjectSubscription;
  onCreateReportInstance: OnCreateReportInstanceSubscription;
  onUpdateReportInstance: OnUpdateReportInstanceSubscription;
  onDeleteReportInstance: OnDeleteReportInstanceSubscription;
  onCreateSignatureInstance: OnCreateSignatureInstanceSubscription;
  onUpdateSignatureInstance: OnUpdateSignatureInstanceSubscription;
  onDeleteSignatureInstance: OnDeleteSignatureInstanceSubscription;
  onCreateEDiaryInstance: OnCreateEDiaryInstanceSubscription;
  onUpdateEDiaryInstance: OnUpdateEDiaryInstanceSubscription;
  onDeleteEDiaryInstance: OnDeleteEDiaryInstanceSubscription;
  onCreateEDiaryPhaseInstance: OnCreateEDiaryPhaseInstanceSubscription;
  onUpdateEDiaryPhaseInstance: OnUpdateEDiaryPhaseInstanceSubscription;
  onDeleteEDiaryPhaseInstance: OnDeleteEDiaryPhaseInstanceSubscription;
  onCreateDayInstance: OnCreateDayInstanceSubscription;
  onUpdateDayInstance: OnUpdateDayInstanceSubscription;
  onDeleteDayInstance: OnDeleteDayInstanceSubscription;
  onCreateSymptomRecordLog: OnCreateSymptomRecordLogSubscription;
  onUpdateSymptomRecordLog: OnUpdateSymptomRecordLogSubscription;
  onDeleteSymptomRecordLog: OnDeleteSymptomRecordLogSubscription;
  onCreateSymptomInstance: OnCreateSymptomInstanceSubscription;
  onUpdateSymptomInstance: OnUpdateSymptomInstanceSubscription;
  onDeleteSymptomInstance: OnDeleteSymptomInstanceSubscription;
  onCreateMedicationInstance: OnCreateMedicationInstanceSubscription;
  onUpdateMedicationInstance: OnUpdateMedicationInstanceSubscription;
  onDeleteMedicationInstance: OnDeleteMedicationInstanceSubscription;
  onCreateMedicalAttentionInstance: OnCreateMedicalAttentionInstanceSubscription;
  onUpdateMedicalAttentionInstance: OnUpdateMedicalAttentionInstanceSubscription;
  onDeleteMedicalAttentionInstance: OnDeleteMedicalAttentionInstanceSubscription;
  onCreateTemperatureRecordLog: OnCreateTemperatureRecordLogSubscription;
  onUpdateTemperatureRecordLog: OnUpdateTemperatureRecordLogSubscription;
  onDeleteTemperatureRecordLog: OnDeleteTemperatureRecordLogSubscription;
  onCreateMediaRecordLog: OnCreateMediaRecordLogSubscription;
  onUpdateMediaRecordLog: OnUpdateMediaRecordLogSubscription;
  onDeleteMediaRecordLog: OnDeleteMediaRecordLogSubscription;
  onCreateAlertInstance: OnCreateAlertInstanceSubscription;
  onUpdateAlertInstance: OnUpdateAlertInstanceSubscription;
  onDeleteAlertInstance: OnDeleteAlertInstanceSubscription;
  onCreatePDFAndAlertsTransactionLog: OnCreatePDFAndAlertsTransactionLogSubscription;
  onUpdatePDFAndAlertsTransactionLog: OnUpdatePDFAndAlertsTransactionLogSubscription;
  onDeletePDFAndAlertsTransactionLog: OnDeletePDFAndAlertsTransactionLogSubscription;
};

export type UpdateICSectionInstanceInput = {
  projectId?: string | null;
  informedConsentInstanceId?: string | null;
  confICSectionId?: string | null;
  id: string;
  consentedUserId?: string | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<
    ComplementaryQuestionAnswerInput
  > | null;
  progress?: number | null;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted?: boolean | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ComplementaryQuestionAnswerInput = {
  type?: ComplementaryQuestionType | null;
  question?: ComplementaryQuestionInput | null;
  answer?: string | null;
};

export enum ComplementaryQuestionType {
  YES_NO = "YES_NO",
  CHECK = "CHECK",
  TEXT = "TEXT",
  DATE = "DATE"
}

export type ComplementaryQuestionInput = {
  id?: string | null;
  question?: string | null;
  type?: ComplementaryQuestionType | null;
  options?: Array<string | null> | null;
};

export enum InstanceState {
  PENDING = "PENDING",
  MUST_UPDATE = "MUST_UPDATE",
  ON_GOING = "ON_GOING",
  NOT_SENDED = "NOT_SENDED",
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  UNSIGNED = "UNSIGNED",
  DELETED = "DELETED",
  CONCILIATION_DELETED = "CONCILIATION_DELETED",
  SUSPENDED = "SUSPENDED",
  LOCALLY_SAVED = "LOCALLY_SAVED",
  CONCILIATION_COMPLETED = "CONCILIATION_COMPLETED"
}

export type ModelICSectionInstanceConditionInput = {
  projectId?: ModelIDInput | null;
  informedConsentInstanceId?: ModelIDInput | null;
  confICSectionId?: ModelIDInput | null;
  consentedUserId?: ModelIDInput | null;
  consentedSignatureDate?: ModelStringInput | null;
  progress?: ModelIntInput | null;
  startDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  isCompleted?: ModelBooleanInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelICSectionInstanceConditionInput | null> | null;
  or?: Array<ModelICSectionInstanceConditionInput | null> | null;
  not?: ModelICSectionInstanceConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelInstanceStateInput = {
  eq?: InstanceState | null;
  ne?: InstanceState | null;
};

export type ICSectionInstance = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: Project | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: InformedConsentInstance | null;
  confICSectionId: string;
  confICSection?: ConfICSection | null;
  id: string;
  consentedUserId: string;
  consentedUser?: ConsentedUser | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<ComplementaryQuestionAnswer> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type Project = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<AccessibilityGroup | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<ProjectTermsAndConditions | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum ProjectState {
  IN_DESIGN = "IN_DESIGN",
  IN_PROGRESS = "IN_PROGRESS",
  SUSPENDED = "SUSPENDED",
  ENDED = "ENDED",
  ARCHIVED = "ARCHIVED"
}

export type AccessibilityGroup = {
  __typename: "AccessibilityGroup";
  group?: string | null;
  isActive?: boolean | null;
};

export type ProjectTermsAndConditions = {
  __typename: "ProjectTermsAndConditions";
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};

export type InformedConsentInstance = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  confInformedConsentId: string;
  ConfInformedConsent?: ConfInformedConsent | null;
  consenterUserId?: string | null;
  consenterUser?: User | null;
  subjectId?: string | null;
  subject?: Subject | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChange | null> | null;
  pdfFiles?: Array<TP2File | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type Site = {
  __typename: "Site";
  projectId: string;
  project?: Project | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfInformedConsent = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: Project | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<InformedConsentSite | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type InformedConsentSite = {
  __typename: "InformedConsentSite";
  site: string;
  recipients?: Array<string> | null;
  totalSubjects: number;
};

export enum InformedConsentType {
  CONSENT = "CONSENT",
  ASSENT = "ASSENT",
  AMENDMENT = "AMENDMENT"
}

export enum QuizLayout {
  QUIZ_BY_SECTION = "QUIZ_BY_SECTION",
  QUIZ_BY_CONSENT = "QUIZ_BY_CONSENT"
}

export enum ConfState {
  DELETED = "DELETED"
}

export type User = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<TermsAndConditions | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum TP2Permission {
  UserCreate = "UserCreate",
  UserEnableDisable = "UserEnableDisable",
  UserDelete = "UserDelete",
  UserList = "UserList",
  UserResetPassword = "UserResetPassword",
  ProjectList = "ProjectList",
  ProjectCreate = "ProjectCreate",
  ProjectRead = "ProjectRead",
  ProjectStatus = "ProjectStatus",
  ProjectCreateGroupVisit = "ProjectCreateGroupVisit",
  ProjectReadGroupVisit = "ProjectReadGroupVisit",
  ProjectConfigVisitVisibility = "ProjectConfigVisitVisibility",
  ProjectDeleteGroupVisit = "ProjectDeleteGroupVisit",
  ReportCreate = "ReportCreate",
  ReportList = "ReportList",
  ReportDetail = "ReportDetail",
  ReportDelete = "ReportDelete",
  ReportUpdate = "ReportUpdate",
  ReportConfSymptomCreateUpdate = "ReportConfSymptomCreateUpdate",
  ReportConfDevicesCreateUpdate = "ReportConfDevicesCreateUpdate",
  ReportRemindersCreateUpdate = "ReportRemindersCreateUpdate",
  ReportConfMultimediaCreateUpdate = "ReportConfMultimediaCreateUpdate",
  ReportJSONFormCreateUpdate = "ReportJSONFormCreateUpdate",
  ReportSymptomDelete = "ReportSymptomDelete",
  ReportAlertCreateUpdate = "ReportAlertCreateUpdate",
  ReportAlertDelete = "ReportAlertDelete",
  DiaryCreate = "DiaryCreate",
  DiaryRead = "DiaryRead",
  DiarySymptomCreate = "DiarySymptomCreate",
  DiarySymptomDelete = "DiarySymptomDelete",
  DiaryFormCreate = "DiaryFormCreate",
  DiaryFormDelete = "DiaryFormDelete",
  DiaryDayCreate = "DiaryDayCreate",
  DiaryDayDelete = "DiaryDayDelete",
  DiaryAlertCreate = "DiaryAlertCreate",
  DiaryAlertDelete = "DiaryAlertDelete",
  FollowupReports = "FollowupReports",
  FollowupDiary = "FollowupDiary",
  SubjectCreate = "SubjectCreate",
  SubjectList = "SubjectList",
  SubjectDelete = "SubjectDelete",
  SubjectRead = "SubjectRead",
  SubjectCompleteVisit = "SubjectCompleteVisit",
  SubjectSuspendVisit = "SubjectSuspendVisit",
  SubjectEnableDisableReport = "SubjectEnableDisableReport",
  SiteCreate = "SiteCreate",
  SiteDelete = "SiteDelete",
  SiteList = "SiteList",
  VersionRead = "VersionRead",
  DownloadTableData = "DownloadTableData"
}

export enum UserState {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED"
}

export type TermsAndConditions = {
  __typename: "TermsAndConditions";
  termsAndConditionsVersion: string;
  policiesVersion: string;
  acceptanceDate: string;
};

export enum AlertType {
  EMAIL = "EMAIL",
  SMS = "SMS"
}

export type Subject = {
  __typename: "Subject";
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<ScheduledPhase> | null;
  currentScheduledPhase?: ScheduledPhase | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ScheduledPhase = {
  __typename: "ScheduledPhase";
  phase: string;
  date?: string | null;
  state: ScheduledPhaseState;
};

export enum ScheduledPhaseState {
  PLANNED = "PLANNED",
  COMPLETED = "COMPLETED",
  NOT_DONE = "NOT_DONE"
}

export enum SubjectState {
  ENROLLED = "ENROLLED",
  COMPLETED = "COMPLETED",
  DROPOUT = "DROPOUT",
  DELETED = "DELETED"
}

export enum InformedConsentState {
  ASSIGNED = "ASSIGNED",
  IN_REVIEW = "IN_REVIEW",
  REVIEWED = "REVIEWED",
  PENDING_FOR_SIGNATURE = "PENDING_FOR_SIGNATURE",
  PARTIALLY_SIGNED = "PARTIALLY_SIGNED",
  SIGNED_BY_PARTICIPANT = "SIGNED_BY_PARTICIPANT",
  SIGNED_BY_INVESTIGATOR = "SIGNED_BY_INVESTIGATOR",
  DELETED = "DELETED"
}

export type StateChange = {
  __typename: "StateChange";
  state?: InformedConsentState | null;
  date?: string | null;
};

export type TP2File = {
  __typename: "TP2File";
  uploadDate: string;
  fileUrl: string;
};

export type ConfICSection = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: ConfInformedConsent | null;
  projectId: string;
  project?: Project | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<ComplementaryQuestion | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum InformedConsentSectionType {
  CONSENT = "CONSENT",
  RISK = "RISK",
  PURPOSE = "PURPOSE",
  BENEFITS = "BENEFITS",
  ALTERNATIVES = "ALTERNATIVES",
  COMPENSATION = "COMPENSATION",
  WITHDRAW = "WITHDRAW",
  FINDINGS = "FINDINGS",
  CONTACTS = "CONTACTS",
  CONFIDENTIALITY = "CONFIDENTIALITY",
  OTHER = "OTHER",
  OPTIONAL_CONSENT = "OPTIONAL_CONSENT",
  CONSENTER_SIGNATURE = "CONSENTER_SIGNATURE"
}

export enum MediaType {
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO"
}

export type ComplementaryQuestion = {
  __typename: "ComplementaryQuestion";
  id?: string | null;
  question?: string | null;
  type?: ComplementaryQuestionType | null;
  options?: Array<string | null> | null;
};

export type ConsentedUser = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  userId: string;
  user?: User | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: InformedConsentInstance | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChange | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ComplementaryQuestionAnswer = {
  __typename: "ComplementaryQuestionAnswer";
  type?: ComplementaryQuestionType | null;
  question?: ComplementaryQuestion | null;
  answer?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelEDiaryPhaseInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  eDiaryInstanceId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  phase?: ModelStringInput | null;
  pdfState?: ModelInstanceStateInput | null;
  completedPhaseDate?: ModelStringInput | null;
  suspensionReason?: ModelStringInput | null;
  suspensionDate?: ModelStringInput | null;
  suspensionUser?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelEDiaryPhaseInstanceFilterInput | null> | null;
  or?: Array<ModelEDiaryPhaseInstanceFilterInput | null> | null;
  not?: ModelEDiaryPhaseInstanceFilterInput | null;
};

export type ModelEDiaryPhaseInstanceConnection = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<EDiaryPhaseInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryPhaseInstance = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: Subject | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: EDiaryInstance | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<TP2File> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLock | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type EDiaryInstance = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: Subject | null;
  confEDiaryId: string;
  confEDiary?: ConfEDiary | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfEDiary = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: Project | null;
  windows?: Array<ConfEDiaryWindow | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfEDiaryWindow = {
  __typename: "ConfEDiaryWindow";
  name: string;
  phases: Array<string>;
  daysAfterVisit: number;
  totalWindowDays: number;
};

export enum OnDemandAlertType {
  DAYS = "DAYS",
  WEEKLY = "WEEKLY"
}

export type ConciliationLock = {
  __typename: "ConciliationLock";
  user: string;
  date: string;
};

export type ModelEDiaryInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  confEDiaryId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  confEdiaryVersion?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  alerts?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelEDiaryInstanceFilterInput | null> | null;
  or?: Array<ModelEDiaryInstanceFilterInput | null> | null;
  not?: ModelEDiaryInstanceFilterInput | null;
};

export type ModelEDiaryInstanceConnection = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<EDiaryInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelReportInstanceFilterInput = {
  confReportId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  plannedDate?: ModelStringInput | null;
  completeDate?: ModelStringInput | null;
  json?: ModelStringInput | null;
  score?: ModelIntInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  reportConfVersion?: ModelIntInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  timezonesHistory?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelReportInstanceFilterInput | null> | null;
  or?: Array<ModelReportInstanceFilterInput | null> | null;
  not?: ModelReportInstanceFilterInput | null;
};

export type ModelReportInstanceConnection = {
  __typename: "ModelReportInstanceConnection";
  items: Array<ReportInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ReportInstance = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: ConfReport | null;
  subjectId: string;
  subject?: Subject | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityType | null;
  pdfFiles?: Array<TP2File> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfReport = {
  __typename: "ConfReport";
  projectId: string;
  project?: Project | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum PageLayout {
  OCURRENCE_FIRST = "OCURRENCE_FIRST",
  ONE_BY_ONE = "ONE_BY_ONE"
}

export enum ReportProgramationType {
  ON_DEMAND = "ON_DEMAND",
  PLANNED_DAYS = "PLANNED_DAYS"
}

export enum ReportAvailableUserType {
  SUBJECT = "SUBJECT",
  INVESTIGATOR = "INVESTIGATOR"
}

export type SymptomOccurrency = {
  __typename: "SymptomOccurrency";
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};

export type UserEntityType = {
  __typename: "UserEntityType";
  username: string;
  userType: ReportAvailableUserType;
};

export type SymptomInstance = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: Subject | null;
  reportInstanceId?: string | null;
  reportInstance?: ReportInstance | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  confSymptomId?: string | null;
  confSymptom?: ConfSymptom | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswer> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type DayInstance = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: Subject | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: EDiaryPhaseInstance | null;
  confDayId: string;
  confDay?: ConfDay | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfDay = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId: string;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfForm = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  projectId: string;
  project?: Project | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: ReconciliationConfig | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: ReconciliationConfig | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: ReconciliationConfig | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: ReconciliationConfig | null;
  otherSymptomsReconciliationConfig?: ReconciliationConfig | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ReconciliationConfig = {
  __typename: "ReconciliationConfig";
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptions | null;
  allowAdditionalQuestionsReconciliation?: boolean | null;
};

export type IntensityReconciliationOptions = {
  __typename: "IntensityReconciliationOptions";
  allow?: boolean | null;
  allowedAction?: IntensityReconciliationOption | null;
};

export enum IntensityReconciliationOption {
  ANY = "ANY",
  INCREASE = "INCREASE",
  DECREASE = "DECREASE"
}

export enum MedicationGivenTo {
  TREAT = "TREAT",
  PREVENT = "PREVENT"
}

export type ConfSymptom = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: ConfReport | null;
  confEdiaryId?: string | null;
  confEdiary?: ConfEDiary | null;
  projectId: string;
  project?: Project | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: IntensityScale | null;
  lowIntensityScaleOptions?: IntensityScale | null;
  mediumIntensityScaleOptions?: IntensityScale | null;
  highIntensityScaleOptions?: IntensityScale | null;
  lifeThreateningScaleOptions?: IntensityScale | null;
  intensityQuestions?: Array<IntensityQuestion | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum SymptomType {
  LOCAL = "LOCAL",
  GENERAL = "GENERAL"
}

export enum Symptom {
  PAIN = "PAIN",
  TENDERNESS = "TENDERNESS",
  NAUSEA = "NAUSEA",
  FEVER = "FEVER",
  IRRITABILITY = "IRRITABILITY",
  VOMIT = "VOMIT",
  ANORMAL_CRYING = "ANORMAL_CRYING",
  DROWSINESS = "DROWSINESS",
  APPETITE_LOSS = "APPETITE_LOSS",
  HEADACHE = "HEADACHE",
  COUGH = "COUGH",
  SORE_THROAT = "SORE_THROAT",
  RUNNY_NOSE = "RUNNY_NOSE",
  WHEEZING = "WHEEZING",
  MUSCLE_SORENESS = "MUSCLE_SORENESS",
  EARACHE = "EARACHE",
  DIARRHEA = "DIARRHEA",
  FATIGUE = "FATIGUE",
  CHILL = "CHILL",
  ARTHRALGIA = "ARTHRALGIA",
  SWELLING = "SWELLING",
  REDNESS = "REDNESS",
  HEMATOMA = "HEMATOMA",
  SORENESS = "SORENESS",
  HARDNESS = "HARDNESS",
  ITCHING = "ITCHING",
  OTHER = "OTHER"
}

export enum InjectionSite {
  NA = "NA",
  ARM = "ARM",
  THIGH = "THIGH",
  BUTTOCK = "BUTTOCK"
}

export enum InjectionSide {
  NA = "NA",
  LEFT = "LEFT",
  RIGHT = "RIGHT"
}

export enum IntensityType {
  RANGE = "RANGE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  NUMERIC_VALUE = "NUMERIC_VALUE",
  NUMERIC_VALUE_QUESTIONS = "NUMERIC_VALUE_QUESTIONS"
}

export type IntensityScale = {
  __typename: "IntensityScale";
  active: boolean;
  startValue: number;
  endValue: number;
  helpText: string;
  label?: string | null;
};

export type IntensityQuestion = {
  __typename: "IntensityQuestion";
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};

export enum Intensity {
  NONE = "NONE",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  LIFE_THREATENING = "LIFE_THREATENING"
}

export enum YNA {
  YES = "YES",
  NO = "NO",
  NOT_APPLY = "NOT_APPLY"
}

export enum YN {
  YES = "YES",
  NO = "NO"
}

export type IntensityQuestionAnswer = {
  __typename: "IntensityQuestionAnswer";
  question?: IntensityQuestion | null;
  answer?: boolean | null;
};

export type ModelConfDayFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  dayName?: ModelStringInput | null;
  order?: ModelIntInput | null;
  startDay?: ModelIntInput | null;
  endDay?: ModelIntInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  trackSymptomOcurrencies?: ModelBooleanInput | null;
  hidePDFDayColumn?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfDayFilterInput | null> | null;
  or?: Array<ModelConfDayFilterInput | null> | null;
  not?: ModelConfDayFilterInput | null;
};

export type ModelConfStateInput = {
  eq?: ConfState | null;
  ne?: ConfState | null;
};

export type ModelConfDayConnection = {
  __typename: "ModelConfDayConnection";
  items: Array<ConfDay | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfSymptomFilterInput = {
  confReportId?: ModelIDInput | null;
  confEdiaryId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  order?: ModelIntInput | null;
  injectionSite?: ModelInjectionSiteInput | null;
  injectionSide?: ModelInjectionSideInput | null;
  showHelp?: ModelBooleanInput | null;
  symptomHelp?: ModelStringInput | null;
  symptomLabel?: ModelStringInput | null;
  symptomIcon?: ModelStringInput | null;
  decimalPlaces?: ModelIntInput | null;
  minSize?: ModelIntInput | null;
  maxSize?: ModelIntInput | null;
  intensityType?: ModelIntensityTypeInput | null;
  intensitySizeLabel?: ModelStringInput | null;
  isIntensityQuestionsRequired?: ModelBooleanInput | null;
  isIntensityRequired?: ModelBooleanInput | null;
  isStartDateRequired?: ModelBooleanInput | null;
  isFinishDateRequired?: ModelBooleanInput | null;
  showNoValuesTaken?: ModelBooleanInput | null;
  noValuesTakenLabel?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfSymptomFilterInput | null> | null;
  or?: Array<ModelConfSymptomFilterInput | null> | null;
  not?: ModelConfSymptomFilterInput | null;
};

export type ModelSymptomTypeInput = {
  eq?: SymptomType | null;
  ne?: SymptomType | null;
};

export type ModelSymptomInput = {
  eq?: Symptom | null;
  ne?: Symptom | null;
};

export type ModelInjectionSiteInput = {
  eq?: InjectionSite | null;
  ne?: InjectionSite | null;
};

export type ModelInjectionSideInput = {
  eq?: InjectionSide | null;
  ne?: InjectionSide | null;
};

export type ModelIntensityTypeInput = {
  eq?: IntensityType | null;
  ne?: IntensityType | null;
};

export type ModelConfSymptomConnection = {
  __typename: "ModelConfSymptomConnection";
  items: Array<ConfSymptom | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelDayInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  confDayId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  confDayVersion?: ModelStringInput | null;
  hasMedication?: ModelBooleanInput | null;
  medicationGivenTo?: ModelMedicationGivenToListInput | null;
  hasMedicalAttention?: ModelBooleanInput | null;
  hasOtherSymptoms?: ModelBooleanInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  comments?: ModelStringInput | null;
  timezonesHistory?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelDayInstanceFilterInput | null> | null;
  or?: Array<ModelDayInstanceFilterInput | null> | null;
  not?: ModelDayInstanceFilterInput | null;
};

export type ModelMedicationGivenToListInput = {
  eq?: Array<MedicationGivenTo | null> | null;
  ne?: Array<MedicationGivenTo | null> | null;
  contains?: MedicationGivenTo | null;
  notContains?: MedicationGivenTo | null;
};

export type ModelDayInstanceConnection = {
  __typename: "ModelDayInstanceConnection";
  items: Array<DayInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelSymptomRecordLogConnection = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<SymptomRecordLog | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomRecordLog = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: DayInstance | null;
  symptomInstanceId: string;
  symptomInstance?: SymptomInstance | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelVerifiedDayInstanceConnection = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<VerifiedDayInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedDayInstance = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: Subject | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: EDiaryPhaseInstance | null;
  confDayId: string;
  confDay?: ConfDay | null;
  siteId: string;
  site?: Site | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<SymptomOccurrency | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelConfMedicationConnection = {
  __typename: "ModelConfMedicationConnection";
  items: Array<ConfMedication | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMedication = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum MedicationConfigurationMode {
  GENERAL_GIVEN_TO_OPEN_REASON = "GENERAL_GIVEN_TO_OPEN_REASON",
  INDIVIDUAL_GIVEN_TO_OPEN_REASON = "INDIVIDUAL_GIVEN_TO_OPEN_REASON",
  GENERAL_GIVEN_TO_SYMPTOM_BINDING = "GENERAL_GIVEN_TO_SYMPTOM_BINDING",
  INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING = "INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING",
  NO_GIVEN_TO_OPEN_REASON = "NO_GIVEN_TO_OPEN_REASON",
  NO_GIVEN_TO_SYMPTOM_BINDING = "NO_GIVEN_TO_SYMPTOM_BINDING",
  NO_GIVEN_NO_REASON = "NO_GIVEN_NO_REASON"
}

export type ModelVerifiedSymptomInstanceConnection = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<VerifiedSymptomInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomInstance = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: Subject | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  confSymptomId?: string | null;
  confSymptom?: ConfSymptom | null;
  symptomInstanceId?: string | null;
  symptomInstance?: SymptomInstance | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswer | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type ModelConfOtherSymptomsConnection = {
  __typename: "ModelConfOtherSymptomsConnection";
  items: Array<ConfOtherSymptoms | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfOtherSymptoms = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelSymptomInstanceConnection = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<SymptomInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfFormConfSymptomConnection = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<ConfFormConfSymptom | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfFormConfSymptom = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: ConfForm | null;
  confSymptomId: string;
  confSymptom?: ConfSymptom | null;
  projectId: string;
  project?: Project | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelAlertInstanceFilterInput = {
  confAlertId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  confAlert_data?: ModelStringInput | null;
  entityId?: ModelStringInput | null;
  entityName?: ModelStringInput | null;
  state?: ModelStringInput | null;
  revisionState?: ModelRevisionStateAlertInstanceInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelAlertInstanceFilterInput | null> | null;
  or?: Array<ModelAlertInstanceFilterInput | null> | null;
  not?: ModelAlertInstanceFilterInput | null;
};

export type ModelRevisionStateAlertInstanceInput = {
  eq?: RevisionStateAlertInstance | null;
  ne?: RevisionStateAlertInstance | null;
};

export enum RevisionStateAlertInstance {
  PENDING = "PENDING",
  EFFECTIVE_CONTACT = "EFFECTIVE_CONTACT",
  NOT_EFFECTIVE_CONTACT = "NOT_EFFECTIVE_CONTACT",
  REVIEWED = "REVIEWED"
}

export type ModelAlertInstanceConnection = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<AlertInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AlertInstance = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: ConfAlert | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfAlert = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: ConfReport | null;
  confEdiaryId?: string | null;
  confEdiary?: ConfEDiary | null;
  confSymptomId?: string | null;
  confSymptom?: ConfSymptom | null;
  confTemperatureId?: string | null;
  confTemperature?: ConfTemperature | null;
  projectId: string;
  project?: Project | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<SiteRecipients> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<AlertRule>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<TriggeredVisit> | null;
  suspendVisitList?: Array<TriggeredVisit> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConfTemperature = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum DayRule {
  MIN = "MIN",
  MAX = "MAX",
  AVERAGE = "AVERAGE"
}

export enum TemperatureRoute {
  ORAL = "ORAL",
  RECTAL = "RECTAL",
  AXILLARY = "AXILLARY",
  EAR = "EAR",
  OTHER = "OTHER"
}

export enum TemperatureUnit {
  CELSIUS = "CELSIUS",
  FAHRENHEIT = "FAHRENHEIT"
}

export type SiteRecipients = {
  __typename: "SiteRecipients";
  siteId: string;
  recipients: Array<string>;
};

export type AlertRule = {
  __typename: "AlertRule";
  ruleId?: string | null;
  alertRuleType: AlertRuleType;
  expectedValue?: string | null;
  expectedUnit?: string | null;
};

export enum AlertRuleType {
  SYMPTOM_INTENSITY_IN = "SYMPTOM_INTENSITY_IN",
  TEMPERATURE_EQUALS_OR_MORE_THAN = "TEMPERATURE_EQUALS_OR_MORE_THAN",
  MEDICAL_ATTENTION_WITH_HOSPITALIZATION = "MEDICAL_ATTENTION_WITH_HOSPITALIZATION",
  JSONFORM_ANSWER = "JSONFORM_ANSWER",
  SYMPTOM_VALUE_EQUALS_OR_MORE_THAN = "SYMPTOM_VALUE_EQUALS_OR_MORE_THAN",
  SYMPTOM_VALUE_EQUALS_OR_LESS_THAN = "SYMPTOM_VALUE_EQUALS_OR_LESS_THAN",
  SYMPTOM_VALUE_EQUALS = "SYMPTOM_VALUE_EQUALS",
  SYMPTOM_OCCURRENCY = "SYMPTOM_OCCURRENCY",
  WITHOUT_SYMPTOM_OCCURRENCY = "WITHOUT_SYMPTOM_OCCURRENCY",
  SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN = "SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN",
  SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN = "SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN",
  SYMPTOMS_OCURRENCY_EQUALS = "SYMPTOMS_OCURRENCY_EQUALS",
  TOTAL_FORM_VALUE_EQUALS = "TOTAL_FORM_VALUE_EQUALS",
  TOTAL_FORM_VALUE_LESS_THAN = "TOTAL_FORM_VALUE_LESS_THAN",
  TOTAL_FORM_VALUE_MORE_THAN = "TOTAL_FORM_VALUE_MORE_THAN"
}

export type TriggeredVisit = {
  __typename: "TriggeredVisit";
  order: number;
  visitName: string;
};

export type ModelConfAlertFilterInput = {
  confReportId?: ModelIDInput | null;
  confEdiaryId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  confTemperatureId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  alertName?: ModelStringInput | null;
  recipients?: ModelStringInput | null;
  type?: ModelAlertTypeInput | null;
  mailSubject?: ModelStringInput | null;
  mailBody?: ModelStringInput | null;
  SMSBody?: ModelStringInput | null;
  triggerVisitCompletion?: ModelBooleanInput | null;
  triggerEDiarySuspension?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfAlertFilterInput | null> | null;
  or?: Array<ModelConfAlertFilterInput | null> | null;
  not?: ModelConfAlertFilterInput | null;
};

export type ModelAlertTypeInput = {
  eq?: AlertType | null;
  ne?: AlertType | null;
};

export type ModelConfAlertConnection = {
  __typename: "ModelConfAlertConnection";
  items: Array<ConfAlert | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelAuthorizedSiteFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelAuthorizedSiteFilterInput | null> | null;
  or?: Array<ModelAuthorizedSiteFilterInput | null> | null;
  not?: ModelAuthorizedSiteFilterInput | null;
};

export type ModelAuthorizedSiteConnection = {
  __typename: "ModelAuthorizedSiteConnection";
  items: Array<AuthorizedSite | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AuthorizedSite = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: User | null;
  siteId: string;
  site?: Site | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelConfReportBySubjectConnection = {
  __typename: "ModelConfReportBySubjectConnection";
  items: Array<ConfReportBySubject | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfReportBySubject = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: Subject | null;
  confReportId: string;
  confReport?: ConfReport | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelSubjectFilterInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  subjectNumber?: ModelStringInput | null;
  group?: ModelStringInput | null;
  state?: ModelSubjectStateInput | null;
  tag?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSubjectFilterInput | null> | null;
  or?: Array<ModelSubjectFilterInput | null> | null;
  not?: ModelSubjectFilterInput | null;
};

export type ModelSubjectStateInput = {
  eq?: SubjectState | null;
  ne?: SubjectState | null;
};

export type ModelSubjectConnection = {
  __typename: "ModelSubjectConnection";
  items: Array<Subject | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelTemperatureRecordLogFilterInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  temperature?: ModelFloatInput | null;
  temperatureUnit?: ModelTemperatureUnitInput | null;
  temperatureRoute?: ModelTemperatureRouteInput | null;
  temperatureWhichOtherRoute?: ModelStringInput | null;
  date?: ModelStringInput | null;
  temperatureTaken?: ModelBooleanInput | null;
  temperatureTakenDate?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelTemperatureRecordLogFilterInput | null> | null;
  or?: Array<ModelTemperatureRecordLogFilterInput | null> | null;
  not?: ModelTemperatureRecordLogFilterInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelTemperatureUnitInput = {
  eq?: TemperatureUnit | null;
  ne?: TemperatureUnit | null;
};

export type ModelTemperatureRouteInput = {
  eq?: TemperatureRoute | null;
  ne?: TemperatureRoute | null;
};

export type ModelTemperatureRecordLogConnection = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<TemperatureRecordLog | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TemperatureRecordLog = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  reportInstanceId?: string | null;
  reportInstance?: ReportInstance | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelMedicalAttentionInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelMedicalAttentionTypeInput | null;
  reason?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  wasHospitalized?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelMedicalAttentionInstanceFilterInput | null> | null;
  or?: Array<ModelMedicalAttentionInstanceFilterInput | null> | null;
  not?: ModelMedicalAttentionInstanceFilterInput | null;
};

export type ModelMedicalAttentionTypeInput = {
  eq?: MedicalAttentionType | null;
  ne?: MedicalAttentionType | null;
};

export enum MedicalAttentionType {
  ER = "ER",
  MEDICAL_APPONTMENT = "MEDICAL_APPONTMENT",
  TELEMEDICINE = "TELEMEDICINE",
  HOSPITALIZATION = "HOSPITALIZATION"
}

export type ModelMedicalAttentionInstanceConnection = {
  __typename: "ModelMedicalAttentionInstanceConnection";
  items: Array<MedicalAttentionInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicalAttentionInstance = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: Subject | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelMedicationInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  isOnGoing?: ModelBooleanInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  name?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  givenTo?: ModelMedicationGivenToListInput | null;
  dose?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  administrationRoute?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelMedicationInstanceFilterInput | null> | null;
  or?: Array<ModelMedicationInstanceFilterInput | null> | null;
  not?: ModelMedicationInstanceFilterInput | null;
};

export type ModelMedicationInstanceConnection = {
  __typename: "ModelMedicationInstanceConnection";
  items: Array<MedicationInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicationInstance = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: Subject | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelInformedConsentInstanceFilterInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  consenterUserId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  consentedIdentificator?: ModelStringInput | null;
  consentedName?: ModelStringInput | null;
  consenterName?: ModelStringInput | null;
  consenterIdentification?: ModelStringInput | null;
  consenterSignature?: ModelStringInput | null;
  consenterRole?: ModelStringInput | null;
  state?: ModelInformedConsentStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelInformedConsentInstanceFilterInput | null> | null;
  or?: Array<ModelInformedConsentInstanceFilterInput | null> | null;
  not?: ModelInformedConsentInstanceFilterInput | null;
};

export type ModelInformedConsentStateInput = {
  eq?: InformedConsentState | null;
  ne?: InformedConsentState | null;
};

export type ModelInformedConsentInstanceConnection = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<InformedConsentInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVerifiedTemperatureRecordLogConnection = {
  __typename: "ModelVerifiedTemperatureRecordLogConnection";
  items: Array<VerifiedTemperatureRecordLog | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedTemperatureRecordLog = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  subjectId: string;
  subject?: Subject | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: TemperatureRecordLog | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelConsentedUserConnection = {
  __typename: "ModelConsentedUserConnection";
  items: Array<ConsentedUser | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfEDiaryConnection = {
  __typename: "ModelConfEDiaryConnection";
  items: Array<ConfEDiary | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfICSectionConnection = {
  __typename: "ModelConfICSectionConnection";
  items: Array<ConfICSection | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfICQuestionConnection = {
  __typename: "ModelConfICQuestionConnection";
  items: Array<ConfICQuestion | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICQuestion = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: ConfICSection | null;
  confInformedConsentId: string;
  confInformedConsent?: ConfInformedConsent | null;
  projectId: string;
  project?: Project | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelConfICAnswerConnection = {
  __typename: "ModelConfICAnswerConnection";
  items: Array<ConfICAnswer | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICAnswer = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: ConfICQuestion | null;
  confInformedConsentId: string;
  confInformedConsent?: ConfInformedConsent | null;
  projectId: string;
  project?: Project | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelICQuestionInstanceConnection = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<ICQuestionInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICQuestionInstance = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: Project | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: InformedConsentInstance | null;
  confICQuestionId: string;
  confICQuestion?: ConfICQuestion | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: ConsentedUser | null;
  confICSection?: ConfICSection | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelICSectionInstanceConnection = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<ICSectionInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfInformedConsentConnection = {
  __typename: "ModelConfInformedConsentConnection";
  items: Array<ConfInformedConsent | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConsentedUserFilterInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  informedConsentInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  kinship?: ModelStringInput | null;
  identification?: ModelStringInput | null;
  state?: ModelInformedConsentStateInput | null;
  requiresSendingEmail?: ModelBooleanInput | null;
  signature?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConsentedUserFilterInput | null> | null;
  or?: Array<ModelConsentedUserFilterInput | null> | null;
  not?: ModelConsentedUserFilterInput | null;
};

export type ModelICSectionInstanceFilterInput = {
  projectId?: ModelIDInput | null;
  informedConsentInstanceId?: ModelIDInput | null;
  confICSectionId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  consentedUserId?: ModelIDInput | null;
  consentedSignatureDate?: ModelStringInput | null;
  progress?: ModelIntInput | null;
  startDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  isCompleted?: ModelBooleanInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelICSectionInstanceFilterInput | null> | null;
  or?: Array<ModelICSectionInstanceFilterInput | null> | null;
  not?: ModelICSectionInstanceFilterInput | null;
};

export type ModelICQuestionInstanceFilterInput = {
  projectId?: ModelIDInput | null;
  informedConsentInstanceId?: ModelIDInput | null;
  confICQuestionId?: ModelIDInput | null;
  confICSectionId?: ModelIDInput | null;
  consentedUserId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  failedAttempts?: ModelIntInput | null;
  isCompleted?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelICQuestionInstanceFilterInput | null> | null;
  or?: Array<ModelICQuestionInstanceFilterInput | null> | null;
  not?: ModelICQuestionInstanceFilterInput | null;
};

export type CreateBackupInstanceInput = {
  projectId: string;
  siteId: string;
  subjectId: string;
  id?: string | null;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  _version?: number | null;
};

export enum EntityName {
  ReportInstance = "ReportInstance",
  SignatureInstance = "SignatureInstance",
  EDiaryInstance = "EDiaryInstance",
  EDiaryPhaseInstance = "EDiaryPhaseInstance",
  DayInstance = "DayInstance",
  SymptomInstance = "SymptomInstance",
  SymptomRecordLog = "SymptomRecordLog",
  MedicationInstance = "MedicationInstance",
  MedicalAttentionInstance = "MedicalAttentionInstance",
  TemperatureRecordLog = "TemperatureRecordLog",
  AlertInstance = "AlertInstance",
  MediaRecordLog = "MediaRecordLog",
  Subject = "Subject"
}

export type ModelBackupInstanceConditionInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  data?: ModelStringInput | null;
  instanceName?: ModelEntityNameInput | null;
  STORAGETYPE?: ModelStringInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  deviceModel?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  and?: Array<ModelBackupInstanceConditionInput | null> | null;
  or?: Array<ModelBackupInstanceConditionInput | null> | null;
  not?: ModelBackupInstanceConditionInput | null;
};

export type ModelEntityNameInput = {
  eq?: EntityName | null;
  ne?: EntityName | null;
};

export type BackupInstance = {
  __typename: "BackupInstance";
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateBackupInstanceInput = {
  projectId?: string | null;
  siteId?: string | null;
  subjectId?: string | null;
  id: string;
  data?: string | null;
  instanceName?: EntityName | null;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  deviceModel?: string | null;
  _lastUser?: string | null;
  _version?: number | null;
};

export type DeleteBackupInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateUserInput = {
  id?: string | null;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<TermsAndConditionsInput | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type TermsAndConditionsInput = {
  termsAndConditionsVersion: string;
  policiesVersion: string;
  acceptanceDate: string;
};

export type ModelUserConditionInput = {
  login?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelStringInput | null;
  roles?: ModelStringInput | null;
  permissions?: ModelTP2PermissionListInput | null;
  state?: ModelUserStateInput | null;
  phoneNumber?: ModelStringInput | null;
  firebaseToken?: ModelStringInput | null;
  isMFAActivated?: ModelBooleanInput | null;
  notificationPreference?: ModelAlertTypeInput | null;
  subjects?: ModelStringInput | null;
  sites?: ModelStringInput | null;
  projects?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  lastTimezone?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelTP2PermissionListInput = {
  eq?: Array<TP2Permission | null> | null;
  ne?: Array<TP2Permission | null> | null;
  contains?: TP2Permission | null;
  notContains?: TP2Permission | null;
};

export type ModelUserStateInput = {
  eq?: UserState | null;
  ne?: UserState | null;
};

export type UpdateUserInput = {
  id: string;
  login?: string | null;
  name?: string | null;
  email?: string | null;
  role?: string | null;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state?: UserState | null;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<TermsAndConditionsInput | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteUserInput = {
  id: string;
  _version?: number | null;
};

export type CreateRoleInput = {
  id?: string | null;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelRoleConditionInput = {
  name?: ModelStringInput | null;
  isEnabled?: ModelBooleanInput | null;
  state?: ModelStringInput | null;
  permissions?: ModelTP2PermissionListInput | null;
  isForProjects?: ModelBooleanInput | null;
  projects?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelRoleConditionInput | null> | null;
  or?: Array<ModelRoleConditionInput | null> | null;
  not?: ModelRoleConditionInput | null;
};

export type role = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateRoleInput = {
  id: string;
  name?: string | null;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteRoleInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfChatUserInput = {
  userId: string;
  siteId: string;
  projectId: string;
  id?: string | null;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfChatUserConditionInput = {
  userId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  isChatActivated?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfChatUserConditionInput | null> | null;
  or?: Array<ModelConfChatUserConditionInput | null> | null;
  not?: ModelConfChatUserConditionInput | null;
};

export type ConfChatUser = {
  __typename: "ConfChatUser";
  userId: string;
  user?: User | null;
  siteId: string;
  site?: User | null;
  projectId: string;
  project?: Project | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfChatUserInput = {
  userId?: string | null;
  siteId?: string | null;
  projectId?: string | null;
  id: string;
  isChatActivated?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfChatUserInput = {
  id: string;
  _version?: number | null;
};

export type CreateChatMessageInput = {
  siteId: string;
  userId: string;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  id?: string | null;
  _version?: number | null;
};

export enum ChatMessageSender {
  SITE = "SITE",
  SUBJECT = "SUBJECT"
}

export type ModelChatMessageConditionInput = {
  siteId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  message?: ModelStringInput | null;
  sender?: ModelChatMessageSenderInput | null;
  senderAlias?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelChatMessageConditionInput | null> | null;
  or?: Array<ModelChatMessageConditionInput | null> | null;
  not?: ModelChatMessageConditionInput | null;
};

export type ModelChatMessageSenderInput = {
  eq?: ChatMessageSender | null;
  ne?: ChatMessageSender | null;
};

export type ChatMessage = {
  __typename: "ChatMessage";
  siteId: string;
  site?: Site | null;
  userId: string;
  user?: User | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateChatMessageInput = {
  siteId?: string | null;
  userId?: string | null;
  message?: string | null;
  sender?: ChatMessageSender | null;
  senderAlias?: string | null;
  createdAt?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  id: string;
  _version?: number | null;
};

export type DeleteChatMessageInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedDayInstanceInput = {
  subjectId: string;
  eDiaryPhaseInstanceId: string;
  confDayId: string;
  siteId: string;
  id?: string | null;
  dayInstanceId?: string | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type SymptomOccurrencyInput = {
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};

export type ModelVerifiedDayInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  confDayId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  hasMedication?: ModelBooleanInput | null;
  hasMedicationCompleted?: ModelBooleanInput | null;
  hasMedicationComment?: ModelStringInput | null;
  medicationGivenTo?: ModelMedicationGivenToListInput | null;
  medicationGivenToComment?: ModelStringInput | null;
  hasMedicalAttention?: ModelBooleanInput | null;
  hasMedicalAttentionCompleted?: ModelBooleanInput | null;
  hasMedicalAttentionComment?: ModelStringInput | null;
  hasOtherSymptoms?: ModelBooleanInput | null;
  hasOtherSymptomsCompleted?: ModelBooleanInput | null;
  hasOtherSymptomsComment?: ModelStringInput | null;
  symptomOcurrenciesComment?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedDayInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedDayInstanceConditionInput | null> | null;
  not?: ModelVerifiedDayInstanceConditionInput | null;
};

export type UpdateVerifiedDayInstanceInput = {
  subjectId?: string | null;
  eDiaryPhaseInstanceId?: string | null;
  confDayId?: string | null;
  siteId?: string | null;
  id: string;
  dayInstanceId?: string | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedDayInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateCommentConciliationInstanceInput = {
  eDiaryPhaseInstanceId: string;
  id?: string | null;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelCommentConciliationInstanceConditionInput = {
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  comment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelCommentConciliationInstanceConditionInput | null> | null;
  or?: Array<ModelCommentConciliationInstanceConditionInput | null> | null;
  not?: ModelCommentConciliationInstanceConditionInput | null;
};

export type CommentConciliationInstance = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: EDiaryPhaseInstance | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCommentConciliationInstanceInput = {
  eDiaryPhaseInstanceId?: string | null;
  id: string;
  comment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteCommentConciliationInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedSymptomInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  confSymptomId?: string | null;
  symptomInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type IntensityQuestionAnswerInput = {
  question?: IntensityQuestionInput | null;
  answer?: boolean | null;
};

export type IntensityQuestionInput = {
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};

export type ModelVerifiedSymptomInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  symptomInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  whichOtherSymptom?: ModelStringInput | null;
  whichOtherSymptomComment?: ModelStringInput | null;
  intensity?: ModelIntensityInput | null;
  intensityComment?: ModelStringInput | null;
  size?: ModelFloatInput | null;
  sizeComment?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  isOtherSymptom?: ModelBooleanInput | null;
  medications?: ModelStringInput | null;
  medicationsComment?: ModelStringInput | null;
  medicalAttentions?: ModelStringInput | null;
  medicalAttentionsComment?: ModelStringInput | null;
  professionalHealthCare?: ModelYNAInput | null;
  professionalHealthCareComment?: ModelStringInput | null;
  hospitalAdmission?: ModelYNInput | null;
  hospitalAdmissionComment?: ModelStringInput | null;
  intensityQuestionAnswersComment?: ModelStringInput | null;
  rememberNoValuesTaken?: ModelBooleanInput | null;
  rememberNoValuesTakenComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedSymptomInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedSymptomInstanceConditionInput | null> | null;
  not?: ModelVerifiedSymptomInstanceConditionInput | null;
  verifiedSymptomInstanceConfSymptomId?: ModelIDInput | null;
};

export type ModelIntensityInput = {
  eq?: Intensity | null;
  ne?: Intensity | null;
};

export type ModelYNAInput = {
  eq?: YNA | null;
  ne?: YNA | null;
};

export type ModelYNInput = {
  eq?: YN | null;
  ne?: YN | null;
};

export type UpdateVerifiedSymptomInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  confSymptomId?: string | null;
  symptomInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type DeleteVerifiedSymptomInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedSymptomRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  verifiedSymptomInstanceId?: string | null;
  subjectId?: string | null;
  symptomRecordLogId?: string | null;
  id?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelVerifiedSymptomRecordLogConditionInput = {
  verifiedDayInstanceId?: ModelIDInput | null;
  verifiedSymptomInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  symptomRecordLogId?: ModelIDInput | null;
  intensity?: ModelIntensityInput | null;
  intensityComment?: ModelStringInput | null;
  size?: ModelFloatInput | null;
  sizeComment?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedSymptomRecordLogConditionInput | null> | null;
  or?: Array<ModelVerifiedSymptomRecordLogConditionInput | null> | null;
  not?: ModelVerifiedSymptomRecordLogConditionInput | null;
};

export type VerifiedSymptomRecordLog = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: VerifiedSymptomInstance | null;
  subjectId?: string | null;
  subject?: Subject | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: SymptomRecordLog | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedSymptomRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  verifiedSymptomInstanceId?: string | null;
  subjectId?: string | null;
  symptomRecordLogId?: string | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedSymptomRecordLogInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedMedicationInstanceInput = {
  subjectId: string;
  verifiedDayInstanceId?: string | null;
  medicationInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelVerifiedMedicationInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  medicationInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  isOnGoing?: ModelBooleanInput | null;
  isOnGoingComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  name?: ModelStringInput | null;
  nameComment?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  reasonComment?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  symptomsInstancesComment?: ModelStringInput | null;
  givenTo?: ModelMedicationGivenToListInput | null;
  givenToComment?: ModelStringInput | null;
  dose?: ModelStringInput | null;
  doseComment?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  frequencyComment?: ModelStringInput | null;
  administrationRoute?: ModelStringInput | null;
  administrationRouteComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedMedicationInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedMedicationInstanceConditionInput | null> | null;
  not?: ModelVerifiedMedicationInstanceConditionInput | null;
};

export type VerifiedMedicationInstance = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: Subject | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  medicationInstanceId?: string | null;
  medicationInstance?: MedicationInstance | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedMedicationInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  medicationInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedMedicationInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedMedicalAttentionInstanceInput = {
  subjectId: string;
  verifiedDayInstanceId?: string | null;
  medicalAttentionInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelVerifiedMedicalAttentionInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  medicalAttentionInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelMedicalAttentionTypeInput | null;
  typeComment?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  reasonComment?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  symptomsInstancesComment?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  wasHospitalized?: ModelBooleanInput | null;
  wasHospitalizedComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedMedicalAttentionInstanceConditionInput | null> | null;
  or?: Array<ModelVerifiedMedicalAttentionInstanceConditionInput | null> | null;
  not?: ModelVerifiedMedicalAttentionInstanceConditionInput | null;
};

export type VerifiedMedicalAttentionInstance = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: Subject | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: VerifiedDayInstance | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: MedicalAttentionInstance | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedMedicalAttentionInstanceInput = {
  subjectId?: string | null;
  verifiedDayInstanceId?: string | null;
  medicalAttentionInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedMedicalAttentionInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateVerifiedTemperatureRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  subjectId: string;
  temperatureRecordLogID?: string | null;
  id?: string | null;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelVerifiedTemperatureRecordLogConditionInput = {
  verifiedDayInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  temperatureRecordLogID?: ModelIDInput | null;
  temperature?: ModelFloatInput | null;
  temperatureComment?: ModelStringInput | null;
  unit?: ModelTemperatureUnitInput | null;
  unitComment?: ModelStringInput | null;
  route?: ModelTemperatureRouteInput | null;
  routeComment?: ModelStringInput | null;
  whichOtherRoute?: ModelStringInput | null;
  whichOtherRouteComment?: ModelStringInput | null;
  taken?: ModelBooleanInput | null;
  takenComment?: ModelStringInput | null;
  takenDate?: ModelStringInput | null;
  takenDateComment?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedTemperatureRecordLogConditionInput | null> | null;
  or?: Array<ModelVerifiedTemperatureRecordLogConditionInput | null> | null;
  not?: ModelVerifiedTemperatureRecordLogConditionInput | null;
};

export type UpdateVerifiedTemperatureRecordLogInput = {
  verifiedDayInstanceId?: string | null;
  subjectId?: string | null;
  temperatureRecordLogID?: string | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteVerifiedTemperatureRecordLogInput = {
  id: string;
  _version?: number | null;
};

export type CreateProjectInput = {
  id?: string | null;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<AccessibilityGroupInput | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<ProjectTermsAndConditionsInput | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type AccessibilityGroupInput = {
  group?: string | null;
  isActive?: boolean | null;
};

export type ProjectTermsAndConditionsInput = {
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};

export type ModelProjectConditionInput = {
  state?: ModelProjectStateInput | null;
  code?: ModelStringInput | null;
  name?: ModelStringInput | null;
  sponsor?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  faultRecipients?: ModelStringInput | null;
  isEdiaryActivated?: ModelBooleanInput | null;
  isReportsActivated?: ModelBooleanInput | null;
  isICActivated?: ModelBooleanInput | null;
  isChatActivated?: ModelBooleanInput | null;
  isMFAActivated?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelProjectConditionInput | null> | null;
  or?: Array<ModelProjectConditionInput | null> | null;
  not?: ModelProjectConditionInput | null;
};

export type ModelProjectStateInput = {
  eq?: ProjectState | null;
  ne?: ProjectState | null;
};

export type UpdateProjectInput = {
  id: string;
  state?: ProjectState | null;
  code?: string | null;
  name?: string | null;
  sponsor?: string | null;
  groups?: Array<string> | null;
  phases?: Array<string> | null;
  accessibilityGroups?: Array<AccessibilityGroupInput | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated?: boolean | null;
  isReportsActivated?: boolean | null;
  termsAndConditions?: Array<ProjectTermsAndConditionsInput | null> | null;
  isICActivated?: boolean | null;
  isChatActivated?: boolean | null;
  isMFAActivated?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteProjectInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfVisitGroupInput = {
  projectId: string;
  id?: string | null;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfVisitGroupConditionInput = {
  projectId?: ModelIDInput | null;
  visit?: ModelStringInput | null;
  group?: ModelStringInput | null;
  isVisible?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfVisitGroupConditionInput | null> | null;
  or?: Array<ModelConfVisitGroupConditionInput | null> | null;
  not?: ModelConfVisitGroupConditionInput | null;
};

export type ConfVisitGroup = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: Project | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfVisitGroupInput = {
  projectId?: string | null;
  id: string;
  visit?: string | null;
  group?: string | null;
  isVisible?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfVisitGroupInput = {
  id: string;
  _version?: number | null;
};

export type CreateSiteInput = {
  projectId: string;
  id?: string | null;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelSiteConditionInput = {
  projectId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  contactInfo?: ModelStringInput | null;
  showContactInfo?: ModelBooleanInput | null;
  showContactInfoLogin?: ModelBooleanInput | null;
  timezone?: ModelStringInput | null;
  defaultLanguage?: ModelStringInput | null;
  timezoneRecipients?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSiteConditionInput | null> | null;
  or?: Array<ModelSiteConditionInput | null> | null;
  not?: ModelSiteConditionInput | null;
};

export type UpdateSiteInput = {
  projectId?: string | null;
  id: string;
  name?: string | null;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteSiteInput = {
  id: string;
  _version?: number | null;
};

export type CreateAuthorizedSiteInput = {
  id?: string | null;
  userId: string;
  siteId: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelAuthorizedSiteConditionInput = {
  userId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelAuthorizedSiteConditionInput | null> | null;
  or?: Array<ModelAuthorizedSiteConditionInput | null> | null;
  not?: ModelAuthorizedSiteConditionInput | null;
};

export type UpdateAuthorizedSiteInput = {
  id: string;
  userId?: string | null;
  siteId?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteAuthorizedSiteInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfReportInput = {
  projectId: string;
  id?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfReportConditionInput = {
  projectId?: ModelIDInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  instancePerDay?: ModelBooleanInput | null;
  reportTitle?: ModelStringInput | null;
  showHelp?: ModelBooleanInput | null;
  reportHelp?: ModelStringInput | null;
  reportIcon?: ModelStringInput | null;
  symptomPageLayout?: ModelPageLayoutInput | null;
  programationType?: ModelReportProgramationTypeInput | null;
  plannedDayList?: ModelIntInput | null;
  isDisplayDependentOnTheEDiary?: ModelBooleanInput | null;
  forms?: ModelStringInput | null;
  isControlledBySite?: ModelBooleanInput | null;
  allowReportBackDating?: ModelBooleanInput | null;
  enableLocalNotifications?: ModelBooleanInput | null;
  futureNotifications?: ModelIntInput | null;
  alertType?: ModelOnDemandAlertTypeInput | null;
  alertWeeklyDays?: ModelIntInput | null;
  alertInBetweenDaysFrequency?: ModelIntInput | null;
  alertDuringSameDayFrequency?: ModelIntInput | null;
  alertStartTime?: ModelStringInput | null;
  alertEndTime?: ModelStringInput | null;
  jsonForm?: ModelStringInput | null;
  isSignatureRequired?: ModelBooleanInput | null;
  isGPSRequired?: ModelBooleanInput | null;
  isUserIPRequired?: ModelBooleanInput | null;
  isMediaRequired?: ModelBooleanInput | null;
  isDeviceDataRequired?: ModelBooleanInput | null;
  mediaTypes?: ModelMediaTypeListInput | null;
  isAssociatedToVisits?: ModelBooleanInput | null;
  areSymptomsRequired?: ModelBooleanInput | null;
  areAlertsRequired?: ModelBooleanInput | null;
  isDeviceDataManagementRequired?: ModelBooleanInput | null;
  availableUsers?: ModelReportAvailableUserTypeListInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfReportConditionInput | null> | null;
  or?: Array<ModelConfReportConditionInput | null> | null;
  not?: ModelConfReportConditionInput | null;
};

export type ModelPageLayoutInput = {
  eq?: PageLayout | null;
  ne?: PageLayout | null;
};

export type ModelReportProgramationTypeInput = {
  eq?: ReportProgramationType | null;
  ne?: ReportProgramationType | null;
};

export type ModelOnDemandAlertTypeInput = {
  eq?: OnDemandAlertType | null;
  ne?: OnDemandAlertType | null;
};

export type ModelMediaTypeListInput = {
  eq?: Array<MediaType | null> | null;
  ne?: Array<MediaType | null> | null;
  contains?: MediaType | null;
  notContains?: MediaType | null;
};

export type ModelReportAvailableUserTypeListInput = {
  eq?: Array<ReportAvailableUserType | null> | null;
  ne?: Array<ReportAvailableUserType | null> | null;
  contains?: ReportAvailableUserType | null;
  notContains?: ReportAvailableUserType | null;
};

export type UpdateConfReportInput = {
  projectId?: string | null;
  id: string;
  groups?: Array<string> | null;
  phases?: Array<string> | null;
  instancePerDay?: boolean | null;
  reportTitle?: string | null;
  showHelp?: boolean | null;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout?: PageLayout | null;
  programationType?: ReportProgramationType | null;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating?: boolean | null;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired?: boolean | null;
  isGPSRequired?: boolean | null;
  isUserIPRequired?: boolean | null;
  isMediaRequired?: boolean | null;
  isDeviceDataRequired?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfReportInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfReportBySubjectInput = {
  id?: string | null;
  subjectId: string;
  confReportId: string;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfReportBySubjectConditionInput = {
  subjectId?: ModelIDInput | null;
  confReportId?: ModelIDInput | null;
  isEnabled?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfReportBySubjectConditionInput | null> | null;
  or?: Array<ModelConfReportBySubjectConditionInput | null> | null;
  not?: ModelConfReportBySubjectConditionInput | null;
};

export type UpdateConfReportBySubjectInput = {
  id: string;
  subjectId?: string | null;
  confReportId?: string | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfReportBySubjectInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfEDiaryInput = {
  projectId: string;
  windows?: Array<ConfEDiaryWindowInput | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id?: string | null;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ConfEDiaryWindowInput = {
  name: string;
  phases: Array<string>;
  daysAfterVisit: number;
  totalWindowDays: number;
};

export type ModelConfEDiaryConditionInput = {
  projectId?: ModelIDInput | null;
  isConciliationRequired?: ModelBooleanInput | null;
  isWindowsConciliationRequired?: ModelBooleanInput | null;
  isSignatureRequired?: ModelBooleanInput | null;
  isPastEntryEnabled?: ModelBooleanInput | null;
  isGPSRequired?: ModelBooleanInput | null;
  isUserIPRequired?: ModelBooleanInput | null;
  isDeviceDataRequired?: ModelBooleanInput | null;
  enableLocalNotifications?: ModelBooleanInput | null;
  alertType?: ModelOnDemandAlertTypeInput | null;
  alertWeeklyDays?: ModelIntInput | null;
  alertInBetweenDaysFrequency?: ModelIntInput | null;
  alertDuringSameDayFrequency?: ModelIntInput | null;
  alertStartTime?: ModelStringInput | null;
  alertEndTime?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfEDiaryConditionInput | null> | null;
  or?: Array<ModelConfEDiaryConditionInput | null> | null;
  not?: ModelConfEDiaryConditionInput | null;
};

export type UpdateConfEDiaryInput = {
  projectId?: string | null;
  windows?: Array<ConfEDiaryWindowInput | null> | null;
  isConciliationRequired?: boolean | null;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired?: boolean | null;
  isPastEntryEnabled?: boolean | null;
  isGPSRequired?: boolean | null;
  isUserIPRequired?: boolean | null;
  isDeviceDataRequired?: boolean | null;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfEDiaryInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfDayInput = {
  confEdiaryId: string;
  confFormId: string;
  projectId: string;
  id?: string | null;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfDayConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  dayName?: ModelStringInput | null;
  order?: ModelIntInput | null;
  startDay?: ModelIntInput | null;
  endDay?: ModelIntInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  trackSymptomOcurrencies?: ModelBooleanInput | null;
  hidePDFDayColumn?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfDayConditionInput | null> | null;
  or?: Array<ModelConfDayConditionInput | null> | null;
  not?: ModelConfDayConditionInput | null;
};

export type UpdateConfDayInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  dayName?: string | null;
  order?: number | null;
  startDay?: number | null;
  endDay?: number | null;
  groups?: Array<string> | null;
  phases?: Array<string> | null;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfDayInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfFormInput = {
  confEdiaryId: string;
  projectId: string;
  id?: string | null;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: ReconciliationConfigInput | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: ReconciliationConfigInput | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: ReconciliationConfigInput | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: ReconciliationConfigInput | null;
  otherSymptomsReconciliationConfig?: ReconciliationConfigInput | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptionsInput | null;
  allowAdditionalQuestionsReconciliation?: boolean | null;
};

export type IntensityReconciliationOptionsInput = {
  allow?: boolean | null;
  allowedAction?: IntensityReconciliationOption | null;
};

export type ModelConfFormConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  isTemperatureRequired?: ModelBooleanInput | null;
  isMedicationRequired?: ModelBooleanInput | null;
  isMedicalAttentionRequired?: ModelBooleanInput | null;
  isMediaRequired?: ModelBooleanInput | null;
  isReconciliationRequired?: ModelBooleanInput | null;
  sectionsToReconcile?: ModelStringInput | null;
  areCommentsRequired?: ModelBooleanInput | null;
  isOtherForm?: ModelBooleanInput | null;
  mediaTypes?: ModelMediaTypeListInput | null;
  areOtherSymptomsRequired?: ModelBooleanInput | null;
  symptomPageLayout?: ModelPageLayoutInput | null;
  showHelp?: ModelBooleanInput | null;
  formHelp?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfFormConditionInput | null> | null;
  or?: Array<ModelConfFormConditionInput | null> | null;
  not?: ModelConfFormConditionInput | null;
};

export type UpdateConfFormInput = {
  confEdiaryId?: string | null;
  projectId?: string | null;
  id: string;
  name?: string | null;
  isTemperatureRequired?: boolean | null;
  temperatureReconciliationConfig?: ReconciliationConfigInput | null;
  isMedicationRequired?: boolean | null;
  medicationReconciliationConfig?: ReconciliationConfigInput | null;
  isMedicalAttentionRequired?: boolean | null;
  medicalAttentionReconciliationConfig?: ReconciliationConfigInput | null;
  isMediaRequired?: boolean | null;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired?: boolean | null;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired?: boolean | null;
  symptomsReconciliationConfig?: ReconciliationConfigInput | null;
  otherSymptomsReconciliationConfig?: ReconciliationConfigInput | null;
  symptomPageLayout?: PageLayout | null;
  showHelp?: boolean | null;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfFormInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfSymptomInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  projectId: string;
  id?: string | null;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: IntensityScaleInput | null;
  lowIntensityScaleOptions?: IntensityScaleInput | null;
  mediumIntensityScaleOptions?: IntensityScaleInput | null;
  highIntensityScaleOptions?: IntensityScaleInput | null;
  lifeThreateningScaleOptions?: IntensityScaleInput | null;
  intensityQuestions?: Array<IntensityQuestionInput | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type IntensityScaleInput = {
  active: boolean;
  startValue: number;
  endValue: number;
  helpText: string;
  label?: string | null;
};

export type ModelConfSymptomConditionInput = {
  confReportId?: ModelIDInput | null;
  confEdiaryId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  order?: ModelIntInput | null;
  injectionSite?: ModelInjectionSiteInput | null;
  injectionSide?: ModelInjectionSideInput | null;
  showHelp?: ModelBooleanInput | null;
  symptomHelp?: ModelStringInput | null;
  symptomLabel?: ModelStringInput | null;
  symptomIcon?: ModelStringInput | null;
  decimalPlaces?: ModelIntInput | null;
  minSize?: ModelIntInput | null;
  maxSize?: ModelIntInput | null;
  intensityType?: ModelIntensityTypeInput | null;
  intensitySizeLabel?: ModelStringInput | null;
  isIntensityQuestionsRequired?: ModelBooleanInput | null;
  isIntensityRequired?: ModelBooleanInput | null;
  isStartDateRequired?: ModelBooleanInput | null;
  isFinishDateRequired?: ModelBooleanInput | null;
  showNoValuesTaken?: ModelBooleanInput | null;
  noValuesTakenLabel?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfSymptomConditionInput | null> | null;
  or?: Array<ModelConfSymptomConditionInput | null> | null;
  not?: ModelConfSymptomConditionInput | null;
};

export type UpdateConfSymptomInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  projectId?: string | null;
  id: string;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  order?: number | null;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp?: boolean | null;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: IntensityScaleInput | null;
  lowIntensityScaleOptions?: IntensityScaleInput | null;
  mediumIntensityScaleOptions?: IntensityScaleInput | null;
  highIntensityScaleOptions?: IntensityScaleInput | null;
  lifeThreateningScaleOptions?: IntensityScaleInput | null;
  intensityQuestions?: Array<IntensityQuestionInput | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfSymptomInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfFormConfSymptomInput = {
  confFormId: string;
  confSymptomId: string;
  projectId: string;
  id?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfFormConfSymptomConditionInput = {
  confFormId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfFormConfSymptomConditionInput | null> | null;
  or?: Array<ModelConfFormConfSymptomConditionInput | null> | null;
  not?: ModelConfFormConfSymptomConditionInput | null;
};

export type UpdateConfFormConfSymptomInput = {
  confFormId?: string | null;
  confSymptomId?: string | null;
  projectId?: string | null;
  id: string;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfFormConfSymptomInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfMedicationInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfMedicationConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  showPrecondition?: ModelBooleanInput | null;
  mode?: ModelMedicationConfigurationModeInput | null;
  showRememberStartDate?: ModelBooleanInput | null;
  showRememberFinishDate?: ModelBooleanInput | null;
  showDose?: ModelBooleanInput | null;
  showFrequency?: ModelBooleanInput | null;
  showAdministrationRoute?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfMedicationConditionInput | null> | null;
  or?: Array<ModelConfMedicationConditionInput | null> | null;
  not?: ModelConfMedicationConditionInput | null;
};

export type ModelMedicationConfigurationModeInput = {
  eq?: MedicationConfigurationMode | null;
  ne?: MedicationConfigurationMode | null;
};

export type UpdateConfMedicationInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfMedicationInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfMedicalAttentionInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export enum MedicalAttentionConfigurationMode {
  OPEN_REASON = "OPEN_REASON",
  SYMPTOM_BINDING = "SYMPTOM_BINDING",
  NO_REASON = "NO_REASON"
}

export type ModelConfMedicalAttentionConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  isBindToSymptomOcurrencies?: ModelBooleanInput | null;
  showRememberStartDate?: ModelBooleanInput | null;
  showRememberFinishDate?: ModelBooleanInput | null;
  showHospitalization?: ModelBooleanInput | null;
  mode?: ModelMedicalAttentionConfigurationModeInput | null;
  showPrecondition?: ModelBooleanInput | null;
  showType?: ModelBooleanInput | null;
  medicalAttentionTypes?: ModelMedicalAttentionTypeListInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfMedicalAttentionConditionInput | null> | null;
  or?: Array<ModelConfMedicalAttentionConditionInput | null> | null;
  not?: ModelConfMedicalAttentionConditionInput | null;
};

export type ModelMedicalAttentionConfigurationModeInput = {
  eq?: MedicalAttentionConfigurationMode | null;
  ne?: MedicalAttentionConfigurationMode | null;
};

export type ModelMedicalAttentionTypeListInput = {
  eq?: Array<MedicalAttentionType | null> | null;
  ne?: Array<MedicalAttentionType | null> | null;
  contains?: MedicalAttentionType | null;
  notContains?: MedicalAttentionType | null;
};

export type ConfMedicalAttention = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfMedicalAttentionInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfMedicalAttentionInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfTemperatureInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfTemperatureConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  dayRule?: ModelDayRuleInput | null;
  routeOptions?: ModelTemperatureRouteListInput | null;
  showFahrenheitUnit?: ModelBooleanInput | null;
  showTemperatureRoute?: ModelBooleanInput | null;
  ocurrencyValue?: ModelFloatInput | null;
  ocurrencyUnit?: ModelTemperatureUnitInput | null;
  showTemperatureTaken?: ModelBooleanInput | null;
  showTemperatureTakenDate?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfTemperatureConditionInput | null> | null;
  or?: Array<ModelConfTemperatureConditionInput | null> | null;
  not?: ModelConfTemperatureConditionInput | null;
};

export type ModelDayRuleInput = {
  eq?: DayRule | null;
  ne?: DayRule | null;
};

export type ModelTemperatureRouteListInput = {
  eq?: Array<TemperatureRoute | null> | null;
  ne?: Array<TemperatureRoute | null> | null;
  contains?: TemperatureRoute | null;
  notContains?: TemperatureRoute | null;
};

export type UpdateConfTemperatureInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfTemperatureInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfMultimediaInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfMultimediaConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  showDetail?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfMultimediaConditionInput | null> | null;
  or?: Array<ModelConfMultimediaConditionInput | null> | null;
  not?: ModelConfMultimediaConditionInput | null;
};

export type ConfMultimedia = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId?: string | null;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfMultimediaInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfMultimediaInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfOtherSymptomsInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfOtherSymptomsConditionInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  showRememberStartDate?: ModelBooleanInput | null;
  showRememberFinishDate?: ModelBooleanInput | null;
  showProfessionalHealthCare?: ModelBooleanInput | null;
  showType?: ModelBooleanInput | null;
  showIntensity?: ModelBooleanInput | null;
  showOtherSymptom?: ModelBooleanInput | null;
  showPrecondition?: ModelBooleanInput | null;
  preconditionLabel?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfOtherSymptomsConditionInput | null> | null;
  or?: Array<ModelConfOtherSymptomsConditionInput | null> | null;
  not?: ModelConfOtherSymptomsConditionInput | null;
};

export type UpdateConfOtherSymptomsInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfOtherSymptomsInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfAlertInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  confSymptomId?: string | null;
  confTemperatureId?: string | null;
  projectId: string;
  id?: string | null;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<SiteRecipientsInput> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<AlertRuleInput>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<TriggeredVisitInput> | null;
  suspendVisitList?: Array<TriggeredVisitInput> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type SiteRecipientsInput = {
  siteId: string;
  recipients: Array<string>;
};

export type AlertRuleInput = {
  ruleId?: string | null;
  alertRuleType: AlertRuleType;
  expectedValue?: string | null;
  expectedUnit?: string | null;
};

export type TriggeredVisitInput = {
  order: number;
  visitName: string;
};

export type ModelConfAlertConditionInput = {
  confReportId?: ModelIDInput | null;
  confEdiaryId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  confTemperatureId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  alertName?: ModelStringInput | null;
  recipients?: ModelStringInput | null;
  type?: ModelAlertTypeInput | null;
  mailSubject?: ModelStringInput | null;
  mailBody?: ModelStringInput | null;
  SMSBody?: ModelStringInput | null;
  triggerVisitCompletion?: ModelBooleanInput | null;
  triggerEDiarySuspension?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfAlertConditionInput | null> | null;
  or?: Array<ModelConfAlertConditionInput | null> | null;
  not?: ModelConfAlertConditionInput | null;
};

export type UpdateConfAlertInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  confSymptomId?: string | null;
  confTemperatureId?: string | null;
  projectId?: string | null;
  id: string;
  alertName?: string | null;
  recipients?: Array<string> | null;
  siteRecipients?: Array<SiteRecipientsInput> | null;
  type?: AlertType | null;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules?: Array<AlertRuleInput> | null;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<TriggeredVisitInput> | null;
  suspendVisitList?: Array<TriggeredVisitInput> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfAlertInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfDictionaryInput = {
  projectId: string;
  id?: string | null;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfDictionaryConditionInput = {
  projectId?: ModelIDInput | null;
  key?: ModelStringInput | null;
  spanish?: ModelStringInput | null;
  english?: ModelStringInput | null;
  isHtml?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfDictionaryConditionInput | null> | null;
  or?: Array<ModelConfDictionaryConditionInput | null> | null;
  not?: ModelConfDictionaryConditionInput | null;
};

export type ConfDictionary = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: Project | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfDictionaryInput = {
  projectId?: string | null;
  id: string;
  key?: string | null;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfDictionaryInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfInformedConsentInput = {
  projectId: string;
  id?: string | null;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<InformedConsentSiteInput | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type InformedConsentSiteInput = {
  site: string;
  recipients?: Array<string> | null;
  totalSubjects: number;
};

export type ModelConfInformedConsentConditionInput = {
  projectId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  icVersion?: ModelFloatInput | null;
  recipients?: ModelStringInput | null;
  type?: ModelInformedConsentTypeInput | null;
  quizPageLayout?: ModelQuizLayoutInput | null;
  approvalSealFileUrl?: ModelStringInput | null;
  isMultipleSignatureRequired?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  minRequiredSignatures?: ModelIntInput | null;
  maxRequiredSignatures?: ModelIntInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfInformedConsentConditionInput | null> | null;
  or?: Array<ModelConfInformedConsentConditionInput | null> | null;
  not?: ModelConfInformedConsentConditionInput | null;
};

export type ModelInformedConsentTypeInput = {
  eq?: InformedConsentType | null;
  ne?: InformedConsentType | null;
};

export type ModelQuizLayoutInput = {
  eq?: QuizLayout | null;
  ne?: QuizLayout | null;
};

export type UpdateConfInformedConsentInput = {
  projectId?: string | null;
  id: string;
  name?: string | null;
  icVersion?: number | null;
  recipients?: Array<string | null> | null;
  sites?: Array<InformedConsentSiteInput | null> | null;
  type?: InformedConsentType | null;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl?: string | null;
  isMultipleSignatureRequired?: boolean | null;
  state?: ConfState | null;
  minRequiredSignatures?: number | null;
  maxRequiredSignatures?: number | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfInformedConsentInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfICSectionInput = {
  confInformedConsentId: string;
  projectId: string;
  id?: string | null;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<ComplementaryQuestionInput | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfICSectionConditionInput = {
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  type?: ModelInformedConsentSectionTypeInput | null;
  order?: ModelIntInput | null;
  content?: ModelStringInput | null;
  mediaUrl?: ModelStringInput | null;
  mediaType?: ModelMediaTypeInput | null;
  icon?: ModelStringInput | null;
  isElectronicSignatureRequired?: ModelBooleanInput | null;
  isStudyRolRequired?: ModelBooleanInput | null;
  enableApprovalQuestions?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICSectionConditionInput | null> | null;
  or?: Array<ModelConfICSectionConditionInput | null> | null;
  not?: ModelConfICSectionConditionInput | null;
};

export type ModelInformedConsentSectionTypeInput = {
  eq?: InformedConsentSectionType | null;
  ne?: InformedConsentSectionType | null;
};

export type ModelMediaTypeInput = {
  eq?: MediaType | null;
  ne?: MediaType | null;
};

export type UpdateConfICSectionInput = {
  confInformedConsentId?: string | null;
  projectId?: string | null;
  id: string;
  title?: string | null;
  type?: InformedConsentSectionType | null;
  order?: number | null;
  content?: string | null;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon?: string | null;
  complementaryQuestions?: Array<ComplementaryQuestionInput | null> | null;
  isElectronicSignatureRequired?: boolean | null;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfICSectionInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfICQuestionInput = {
  confICSectionId: string;
  confInformedConsentId: string;
  projectId: string;
  id?: string | null;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfICQuestionConditionInput = {
  confICSectionId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  order?: ModelIntInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICQuestionConditionInput | null> | null;
  or?: Array<ModelConfICQuestionConditionInput | null> | null;
  not?: ModelConfICQuestionConditionInput | null;
};

export type UpdateConfICQuestionInput = {
  confICSectionId?: string | null;
  confInformedConsentId?: string | null;
  projectId?: string | null;
  id: string;
  description?: string | null;
  order?: number | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfICQuestionInput = {
  id: string;
  _version?: number | null;
};

export type CreateConfICAnswerInput = {
  confICQuestionId: string;
  confInformedConsentId: string;
  projectId: string;
  id?: string | null;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConfICAnswerConditionInput = {
  confICQuestionId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  order?: ModelIntInput | null;
  onErrorMessage?: ModelStringInput | null;
  isCorrect?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICAnswerConditionInput | null> | null;
  or?: Array<ModelConfICAnswerConditionInput | null> | null;
  not?: ModelConfICAnswerConditionInput | null;
};

export type UpdateConfICAnswerInput = {
  confICQuestionId?: string | null;
  confInformedConsentId?: string | null;
  projectId?: string | null;
  id: string;
  description?: string | null;
  order?: number | null;
  onErrorMessage?: string | null;
  isCorrect?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConfICAnswerInput = {
  id: string;
  _version?: number | null;
};

export type CreateInformedConsentInstanceInput = {
  projectId: string;
  siteId: string;
  confInformedConsentId: string;
  consenterUserId?: string | null;
  subjectId?: string | null;
  id?: string | null;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChangeInput | null> | null;
  pdfFiles?: Array<TP2FileInput | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type StateChangeInput = {
  state?: InformedConsentState | null;
  date?: string | null;
};

export type TP2FileInput = {
  uploadDate: string;
  fileUrl: string;
};

export type ModelInformedConsentInstanceConditionInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  consenterUserId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  consentedIdentificator?: ModelStringInput | null;
  consentedName?: ModelStringInput | null;
  consenterName?: ModelStringInput | null;
  consenterIdentification?: ModelStringInput | null;
  consenterSignature?: ModelStringInput | null;
  consenterRole?: ModelStringInput | null;
  state?: ModelInformedConsentStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelInformedConsentInstanceConditionInput | null> | null;
  or?: Array<ModelInformedConsentInstanceConditionInput | null> | null;
  not?: ModelInformedConsentInstanceConditionInput | null;
};

export type UpdateInformedConsentInstanceInput = {
  projectId?: string | null;
  siteId?: string | null;
  confInformedConsentId?: string | null;
  consenterUserId?: string | null;
  subjectId?: string | null;
  id: string;
  consentedIdentificator?: string | null;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state?: InformedConsentState | null;
  stateChanges?: Array<StateChangeInput | null> | null;
  pdfFiles?: Array<TP2FileInput | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteInformedConsentInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateConsentedUserInput = {
  projectId: string;
  siteId: string;
  userId: string;
  informedConsentInstanceId: string;
  id?: string | null;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChangeInput | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelConsentedUserConditionInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  informedConsentInstanceId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  kinship?: ModelStringInput | null;
  identification?: ModelStringInput | null;
  state?: ModelInformedConsentStateInput | null;
  requiresSendingEmail?: ModelBooleanInput | null;
  signature?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConsentedUserConditionInput | null> | null;
  or?: Array<ModelConsentedUserConditionInput | null> | null;
  not?: ModelConsentedUserConditionInput | null;
};

export type UpdateConsentedUserInput = {
  projectId?: string | null;
  siteId?: string | null;
  userId?: string | null;
  informedConsentInstanceId?: string | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state?: InformedConsentState | null;
  stateChanges?: Array<StateChangeInput | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteConsentedUserInput = {
  id: string;
  _version?: number | null;
};

export type CreateICSectionInstanceInput = {
  projectId: string;
  informedConsentInstanceId: string;
  confICSectionId: string;
  id?: string | null;
  consentedUserId: string;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<
    ComplementaryQuestionAnswerInput
  > | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteICSectionInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateICQuestionInstanceInput = {
  projectId: string;
  informedConsentInstanceId: string;
  confICQuestionId: string;
  confICSectionId: string;
  consentedUserId: string;
  id?: string | null;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelICQuestionInstanceConditionInput = {
  projectId?: ModelIDInput | null;
  informedConsentInstanceId?: ModelIDInput | null;
  confICQuestionId?: ModelIDInput | null;
  confICSectionId?: ModelIDInput | null;
  consentedUserId?: ModelIDInput | null;
  failedAttempts?: ModelIntInput | null;
  isCompleted?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelICQuestionInstanceConditionInput | null> | null;
  or?: Array<ModelICQuestionInstanceConditionInput | null> | null;
  not?: ModelICQuestionInstanceConditionInput | null;
};

export type UpdateICQuestionInstanceInput = {
  projectId?: string | null;
  informedConsentInstanceId?: string | null;
  confICQuestionId?: string | null;
  confICSectionId?: string | null;
  consentedUserId?: string | null;
  id: string;
  failedAttempts?: number | null;
  isCompleted?: boolean | null;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteICQuestionInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateSubjectInput = {
  projectId: string;
  siteId: string;
  id?: string | null;
  subjectNumber: string;
  scheduledPhases?: Array<ScheduledPhaseInput> | null;
  currentScheduledPhase?: ScheduledPhaseInput | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ScheduledPhaseInput = {
  phase: string;
  date?: string | null;
  state: ScheduledPhaseState;
};

export type ModelSubjectConditionInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  subjectNumber?: ModelStringInput | null;
  group?: ModelStringInput | null;
  state?: ModelSubjectStateInput | null;
  tag?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSubjectConditionInput | null> | null;
  or?: Array<ModelSubjectConditionInput | null> | null;
  not?: ModelSubjectConditionInput | null;
};

export type UpdateSubjectInput = {
  projectId?: string | null;
  siteId?: string | null;
  id: string;
  subjectNumber?: string | null;
  scheduledPhases?: Array<ScheduledPhaseInput> | null;
  currentScheduledPhase?: ScheduledPhaseInput | null;
  group?: string | null;
  state?: SubjectState | null;
  tag?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteSubjectInput = {
  id: string;
  _version?: number | null;
};

export type CreateReportInstanceInput = {
  confReportId: string;
  subjectId: string;
  siteId: string;
  id?: string | null;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<SymptomOccurrencyInput> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityTypeInput | null;
  pdfFiles?: Array<TP2FileInput> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type UserEntityTypeInput = {
  username: string;
  userType: ReportAvailableUserType;
};

export type ModelReportInstanceConditionInput = {
  confReportId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  plannedDate?: ModelStringInput | null;
  completeDate?: ModelStringInput | null;
  json?: ModelStringInput | null;
  score?: ModelIntInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  reportConfVersion?: ModelIntInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  timezonesHistory?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelReportInstanceConditionInput | null> | null;
  or?: Array<ModelReportInstanceConditionInput | null> | null;
  not?: ModelReportInstanceConditionInput | null;
};

export type UpdateReportInstanceInput = {
  confReportId?: string | null;
  subjectId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion?: number | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityTypeInput | null;
  pdfFiles?: Array<TP2FileInput> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type DeleteReportInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateSignatureInstanceInput = {
  subjectId?: string | null;
  id?: string | null;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export enum SignatureType {
  BIOMETRICS = "BIOMETRICS",
  USER_PASS = "USER_PASS"
}

export enum SignatureReason {
  AUTHORSHIP = "AUTHORSHIP",
  APPROVAL = "APPROVAL",
  REVIEW = "REVIEW"
}

export enum SignatureEntityType {
  EDIARY = "EDIARY",
  REPORT = "REPORT",
  ECONSENT = "ECONSENT"
}

export type ModelSignatureInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  date?: ModelStringInput | null;
  type?: ModelSignatureTypeInput | null;
  reason?: ModelSignatureReasonInput | null;
  entityType?: ModelSignatureEntityTypeInput | null;
  entityId?: ModelStringInput | null;
  signedInfo?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSignatureInstanceConditionInput | null> | null;
  or?: Array<ModelSignatureInstanceConditionInput | null> | null;
  not?: ModelSignatureInstanceConditionInput | null;
};

export type ModelSignatureTypeInput = {
  eq?: SignatureType | null;
  ne?: SignatureType | null;
};

export type ModelSignatureReasonInput = {
  eq?: SignatureReason | null;
  ne?: SignatureReason | null;
};

export type ModelSignatureEntityTypeInput = {
  eq?: SignatureEntityType | null;
  ne?: SignatureEntityType | null;
};

export type SignatureInstance = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: Subject | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSignatureInstanceInput = {
  subjectId?: string | null;
  id: string;
  date?: string | null;
  type?: SignatureType | null;
  reason?: SignatureReason | null;
  entityType?: SignatureEntityType | null;
  entityId?: string | null;
  signedInfo?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteSignatureInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateEDiaryInstanceInput = {
  subjectId: string;
  confEDiaryId: string;
  siteId: string;
  id?: string | null;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<TP2FileInput> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelEDiaryInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  confEDiaryId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  confEdiaryVersion?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  alerts?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelEDiaryInstanceConditionInput | null> | null;
  or?: Array<ModelEDiaryInstanceConditionInput | null> | null;
  not?: ModelEDiaryInstanceConditionInput | null;
};

export type UpdateEDiaryInstanceInput = {
  subjectId?: string | null;
  confEDiaryId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  confEdiaryVersion?: string | null;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<TP2FileInput> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteEDiaryInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateEDiaryPhaseInstanceInput = {
  subjectId: string;
  eDiaryInstanceId: string;
  siteId: string;
  id?: string | null;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<TP2FileInput> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLockInput | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ConciliationLockInput = {
  user: string;
  date: string;
};

export type ModelEDiaryPhaseInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  eDiaryInstanceId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  phase?: ModelStringInput | null;
  pdfState?: ModelInstanceStateInput | null;
  completedPhaseDate?: ModelStringInput | null;
  suspensionReason?: ModelStringInput | null;
  suspensionDate?: ModelStringInput | null;
  suspensionUser?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelEDiaryPhaseInstanceConditionInput | null> | null;
  or?: Array<ModelEDiaryPhaseInstanceConditionInput | null> | null;
  not?: ModelEDiaryPhaseInstanceConditionInput | null;
};

export type UpdateEDiaryPhaseInstanceInput = {
  subjectId?: string | null;
  eDiaryInstanceId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  phase?: string | null;
  pdfFiles?: Array<TP2FileInput> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLockInput | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteEDiaryPhaseInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateDayInstanceInput = {
  subjectId: string;
  eDiaryPhaseInstanceId: string;
  confDayId: string;
  siteId: string;
  id?: string | null;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type ModelDayInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  confDayId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  confDayVersion?: ModelStringInput | null;
  hasMedication?: ModelBooleanInput | null;
  medicationGivenTo?: ModelMedicationGivenToListInput | null;
  hasMedicalAttention?: ModelBooleanInput | null;
  hasOtherSymptoms?: ModelBooleanInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  comments?: ModelStringInput | null;
  timezonesHistory?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelDayInstanceConditionInput | null> | null;
  or?: Array<ModelDayInstanceConditionInput | null> | null;
  not?: ModelDayInstanceConditionInput | null;
};

export type UpdateDayInstanceInput = {
  subjectId?: string | null;
  eDiaryPhaseInstanceId?: string | null;
  confDayId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion?: string | null;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type DeleteDayInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateSymptomRecordLogInput = {
  dayInstanceId: string;
  symptomInstanceId: string;
  state?: InstanceState | null;
  subjectId: string;
  id?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelSymptomRecordLogConditionInput = {
  dayInstanceId?: ModelIDInput | null;
  symptomInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  subjectId?: ModelIDInput | null;
  intensity?: ModelIntensityInput | null;
  size?: ModelFloatInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSymptomRecordLogConditionInput | null> | null;
  or?: Array<ModelSymptomRecordLogConditionInput | null> | null;
  not?: ModelSymptomRecordLogConditionInput | null;
};

export type UpdateSymptomRecordLogInput = {
  dayInstanceId?: string | null;
  symptomInstanceId?: string | null;
  state?: InstanceState | null;
  subjectId?: string | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteSymptomRecordLogInput = {
  id: string;
  _version?: number | null;
};

export type CreateSymptomInstanceInput = {
  subjectId: string;
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  confSymptomId?: string | null;
  id?: string | null;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
  symptomInstanceConfSymptomId?: string | null;
};

export type ModelSymptomInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  whichOtherSymptom?: ModelStringInput | null;
  intensity?: ModelIntensityInput | null;
  size?: ModelFloatInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  isOtherSymptom?: ModelBooleanInput | null;
  medications?: ModelStringInput | null;
  medicalAttentions?: ModelStringInput | null;
  professionalHealthCare?: ModelYNAInput | null;
  hospitalAdmission?: ModelYNInput | null;
  createdAt?: ModelStringInput | null;
  rememberNoValuesTaken?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSymptomInstanceConditionInput | null> | null;
  or?: Array<ModelSymptomInstanceConditionInput | null> | null;
  not?: ModelSymptomInstanceConditionInput | null;
  symptomInstanceConfSymptomId?: ModelIDInput | null;
};

export type UpdateSymptomInstanceInput = {
  subjectId?: string | null;
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  confSymptomId?: string | null;
  id: string;
  order?: number | null;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
  symptomInstanceConfSymptomId?: string | null;
};

export type DeleteSymptomInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateMedicationInstanceInput = {
  subjectId: string;
  dayInstanceId?: string | null;
  id?: string | null;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type ModelMedicationInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  isOnGoing?: ModelBooleanInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  name?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  givenTo?: ModelMedicationGivenToListInput | null;
  dose?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  administrationRoute?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelMedicationInstanceConditionInput | null> | null;
  or?: Array<ModelMedicationInstanceConditionInput | null> | null;
  not?: ModelMedicationInstanceConditionInput | null;
};

export type UpdateMedicationInstanceInput = {
  subjectId?: string | null;
  dayInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type DeleteMedicationInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateMedicalAttentionInstanceInput = {
  subjectId: string;
  dayInstanceId?: string | null;
  id?: string | null;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type ModelMedicalAttentionInstanceConditionInput = {
  subjectId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelMedicalAttentionTypeInput | null;
  reason?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  wasHospitalized?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelMedicalAttentionInstanceConditionInput | null> | null;
  or?: Array<ModelMedicalAttentionInstanceConditionInput | null> | null;
  not?: ModelMedicalAttentionInstanceConditionInput | null;
};

export type UpdateMedicalAttentionInstanceInput = {
  subjectId?: string | null;
  dayInstanceId?: string | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};

export type DeleteMedicalAttentionInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreateTemperatureRecordLogInput = {
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  id?: string | null;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelTemperatureRecordLogConditionInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  temperature?: ModelFloatInput | null;
  temperatureUnit?: ModelTemperatureUnitInput | null;
  temperatureRoute?: ModelTemperatureRouteInput | null;
  temperatureWhichOtherRoute?: ModelStringInput | null;
  date?: ModelStringInput | null;
  temperatureTaken?: ModelBooleanInput | null;
  temperatureTakenDate?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelTemperatureRecordLogConditionInput | null> | null;
  or?: Array<ModelTemperatureRecordLogConditionInput | null> | null;
  not?: ModelTemperatureRecordLogConditionInput | null;
};

export type UpdateTemperatureRecordLogInput = {
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId?: string | null;
  id: string;
  state?: InstanceState | null;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteTemperatureRecordLogInput = {
  id: string;
  _version?: number | null;
};

export type CreateMediaRecordLogInput = {
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  id?: string | null;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelMediaRecordLogConditionInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  mediaType?: ModelMediaTypeInput | null;
  url?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  dateTime?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelMediaRecordLogConditionInput | null> | null;
  or?: Array<ModelMediaRecordLogConditionInput | null> | null;
  not?: ModelMediaRecordLogConditionInput | null;
};

export type MediaRecordLog = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: Subject | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMediaRecordLogInput = {
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId?: string | null;
  id: string;
  state?: InstanceState | null;
  mediaType?: MediaType | null;
  url?: string | null;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteMediaRecordLogInput = {
  id: string;
  _version?: number | null;
};

export type CreateAlertInstanceInput = {
  confAlertId: string;
  subjectId: string;
  id?: string | null;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ModelAlertInstanceConditionInput = {
  confAlertId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  confAlert_data?: ModelStringInput | null;
  entityId?: ModelStringInput | null;
  entityName?: ModelStringInput | null;
  state?: ModelStringInput | null;
  revisionState?: ModelRevisionStateAlertInstanceInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelAlertInstanceConditionInput | null> | null;
  or?: Array<ModelAlertInstanceConditionInput | null> | null;
  not?: ModelAlertInstanceConditionInput | null;
};

export type UpdateAlertInstanceInput = {
  confAlertId?: string | null;
  subjectId?: string | null;
  id: string;
  confAlert_data?: string | null;
  entityId?: string | null;
  entityName?: string | null;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type DeleteAlertInstanceInput = {
  id: string;
  _version?: number | null;
};

export type CreatePDFAndAlertsTransactionLogInput = {
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  id?: string | null;
  _version?: number | null;
};

export enum TransactionState {
  IN_PROGRESS = "IN_PROGRESS",
  ENDED = "ENDED",
  ERROR = "ERROR"
}

export type ModelPDFAndAlertsTransactionLogConditionInput = {
  subjectId?: ModelIDInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  state?: ModelTransactionStateInput | null;
  source?: ModelStringInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  confReportId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelPDFAndAlertsTransactionLogConditionInput | null> | null;
  or?: Array<ModelPDFAndAlertsTransactionLogConditionInput | null> | null;
  not?: ModelPDFAndAlertsTransactionLogConditionInput | null;
};

export type ModelTransactionStateInput = {
  eq?: TransactionState | null;
  ne?: TransactionState | null;
};

export type PDFAndAlertsTransactionLog = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: ReportInstance | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdatePDFAndAlertsTransactionLogInput = {
  subjectId?: string | null;
  startDate?: string | null;
  finishDate?: string | null;
  state?: TransactionState | null;
  source?: string | null;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  id: string;
  _version?: number | null;
};

export type DeletePDFAndAlertsTransactionLogInput = {
  id: string;
  _version?: number | null;
};

export type TP2UserInput = {
  login: string;
  name?: string | null;
  email?: string | null;
  role?: string | null;
  phoneNumber?: string | null;
  state?: UserState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  notificationPreference?: string | null;
};

export type TP2IdEntity = {
  id: string;
};

export type TP2MFAConfiguration = {
  projectId?: string | null;
  mfaActive?: boolean | null;
  batchSize?: number | null;
  retryCount?: number | null;
};

export type TP2SubjectInput = {
  subjectNumber: string;
  group: string;
  site_id: string;
  tag?: string | null;
  currentScheduledPhase: TP2ScheduledPhases;
  scheduledPhases?: Array<TP2ScheduledPhases | null> | null;
  _lastUser?: string | null;
};

export type TP2ScheduledPhases = {
  date: string;
  phase: string;
  state: string;
};

export type TP2SubjectDiaryCard = {
  id: string;
  phase: string;
};

export type tp2SendProjectAdminNotificationInput = {
  user?: string | null;
  subject?: string | null;
  action?: string | null;
  data?: string | null;
  error?: tp2SendProjectAdminNotificationError | null;
  source?: string | null;
};

export type tp2SendProjectAdminNotificationError = {
  code?: string | null;
  message?: string | null;
};

export type tp2ValidateAndCompleteTransactionInput = {
  dayInstance?: string | null;
  reportInstance?: string | null;
  temperature?: string | null;
  symptomInstances?: Array<string | null> | null;
  mediaRecordLogs?: Array<string | null> | null;
  otherSymptomInstances?: Array<string | null> | null;
  symptomRecordLogs?: Array<string | null> | null;
  medicationInstances?: Array<string | null> | null;
  medicalAttentionInstances?: Array<string | null> | null;
  signatureInstance?: string | null;
  user?: string | null;
  deviceId?: string | null;
};

export type tp2ManagePDFAndAlertLogInput = {
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  eDiaryPhaseInstanceId?: string | null;
  source: string;
  subjectId?: string | null;
  deviceId?: string | null;
};

export type AuditTrace = {
  __typename: "AuditTrace";
  id: string;
  relatedEntityId: string;
  entity: string;
  projectId?: string | null;
  eventDateTime: string;
  eventType: string;
  _lastUser: string;
  _changeReason?: string | null;
  data: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelAuditTraceFilterInput = {
  id?: ModelIDInput | null;
  relatedEntityId?: ModelStringInput | null;
  entity?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  eventDateTime?: ModelStringInput | null;
  eventType?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  data?: ModelStringInput | null;
  and?: Array<ModelAuditTraceFilterInput | null> | null;
  or?: Array<ModelAuditTraceFilterInput | null> | null;
  not?: ModelAuditTraceFilterInput | null;
};

export type ModelAuditTraceConnection = {
  __typename: "ModelAuditTraceConnection";
  items: Array<AuditTrace | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type UserLoginAttempts = {
  __typename: "UserLoginAttempts";
  login: string;
  attempts: number;
  lastAttempt: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelUserLoginAttemptsFilterInput = {
  login?: ModelIDInput | null;
  attempts?: ModelIntInput | null;
  lastAttempt?: ModelStringInput | null;
  and?: Array<ModelUserLoginAttemptsFilterInput | null> | null;
  or?: Array<ModelUserLoginAttemptsFilterInput | null> | null;
  not?: ModelUserLoginAttemptsFilterInput | null;
};

export type ModelUserLoginAttemptsConnection = {
  __typename: "ModelUserLoginAttemptsConnection";
  items: Array<UserLoginAttempts | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelBackupInstanceFilterInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  data?: ModelStringInput | null;
  instanceName?: ModelEntityNameInput | null;
  STORAGETYPE?: ModelStringInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  deviceModel?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  and?: Array<ModelBackupInstanceFilterInput | null> | null;
  or?: Array<ModelBackupInstanceFilterInput | null> | null;
  not?: ModelBackupInstanceFilterInput | null;
};

export type ModelBackupInstanceConnection = {
  __typename: "ModelBackupInstanceConnection";
  items: Array<BackupInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  login?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelStringInput | null;
  roles?: ModelStringInput | null;
  permissions?: ModelTP2PermissionListInput | null;
  state?: ModelUserStateInput | null;
  phoneNumber?: ModelStringInput | null;
  firebaseToken?: ModelStringInput | null;
  isMFAActivated?: ModelBooleanInput | null;
  notificationPreference?: ModelAlertTypeInput | null;
  subjects?: ModelStringInput | null;
  sites?: ModelStringInput | null;
  projects?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  lastTimezone?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelRoleFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  isEnabled?: ModelBooleanInput | null;
  state?: ModelStringInput | null;
  permissions?: ModelTP2PermissionListInput | null;
  isForProjects?: ModelBooleanInput | null;
  projects?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelRoleFilterInput | null> | null;
  or?: Array<ModelRoleFilterInput | null> | null;
  not?: ModelRoleFilterInput | null;
};

export type ModelRoleConnection = {
  __typename: "ModelRoleConnection";
  items: Array<role | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfChatUserFilterInput = {
  userId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  isChatActivated?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfChatUserFilterInput | null> | null;
  or?: Array<ModelConfChatUserFilterInput | null> | null;
  not?: ModelConfChatUserFilterInput | null;
};

export type ModelConfChatUserConnection = {
  __typename: "ModelConfChatUserConnection";
  items: Array<ConfChatUser | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelChatMessageFilterInput = {
  siteId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  message?: ModelStringInput | null;
  sender?: ModelChatMessageSenderInput | null;
  senderAlias?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelChatMessageFilterInput | null> | null;
  or?: Array<ModelChatMessageFilterInput | null> | null;
  not?: ModelChatMessageFilterInput | null;
};

export type ModelChatMessageConnection = {
  __typename: "ModelChatMessageConnection";
  items: Array<ChatMessage | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVerifiedDayInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  confDayId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  hasMedication?: ModelBooleanInput | null;
  hasMedicationCompleted?: ModelBooleanInput | null;
  hasMedicationComment?: ModelStringInput | null;
  medicationGivenTo?: ModelMedicationGivenToListInput | null;
  medicationGivenToComment?: ModelStringInput | null;
  hasMedicalAttention?: ModelBooleanInput | null;
  hasMedicalAttentionCompleted?: ModelBooleanInput | null;
  hasMedicalAttentionComment?: ModelStringInput | null;
  hasOtherSymptoms?: ModelBooleanInput | null;
  hasOtherSymptomsCompleted?: ModelBooleanInput | null;
  hasOtherSymptomsComment?: ModelStringInput | null;
  symptomOcurrenciesComment?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedDayInstanceFilterInput | null> | null;
  or?: Array<ModelVerifiedDayInstanceFilterInput | null> | null;
  not?: ModelVerifiedDayInstanceFilterInput | null;
};

export type ModelCommentConciliationInstanceFilterInput = {
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  comment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelCommentConciliationInstanceFilterInput | null> | null;
  or?: Array<ModelCommentConciliationInstanceFilterInput | null> | null;
  not?: ModelCommentConciliationInstanceFilterInput | null;
};

export type ModelCommentConciliationInstanceConnection = {
  __typename: "ModelCommentConciliationInstanceConnection";
  items: Array<CommentConciliationInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVerifiedSymptomInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  symptomInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  whichOtherSymptom?: ModelStringInput | null;
  whichOtherSymptomComment?: ModelStringInput | null;
  intensity?: ModelIntensityInput | null;
  intensityComment?: ModelStringInput | null;
  size?: ModelFloatInput | null;
  sizeComment?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  isOtherSymptom?: ModelBooleanInput | null;
  medications?: ModelStringInput | null;
  medicationsComment?: ModelStringInput | null;
  medicalAttentions?: ModelStringInput | null;
  medicalAttentionsComment?: ModelStringInput | null;
  professionalHealthCare?: ModelYNAInput | null;
  professionalHealthCareComment?: ModelStringInput | null;
  hospitalAdmission?: ModelYNInput | null;
  hospitalAdmissionComment?: ModelStringInput | null;
  intensityQuestionAnswersComment?: ModelStringInput | null;
  rememberNoValuesTaken?: ModelBooleanInput | null;
  rememberNoValuesTakenComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedSymptomInstanceFilterInput | null> | null;
  or?: Array<ModelVerifiedSymptomInstanceFilterInput | null> | null;
  not?: ModelVerifiedSymptomInstanceFilterInput | null;
  verifiedSymptomInstanceConfSymptomId?: ModelIDInput | null;
};

export type ModelVerifiedSymptomRecordLogFilterInput = {
  verifiedDayInstanceId?: ModelIDInput | null;
  verifiedSymptomInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  symptomRecordLogId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  intensity?: ModelIntensityInput | null;
  intensityComment?: ModelStringInput | null;
  size?: ModelFloatInput | null;
  sizeComment?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedSymptomRecordLogFilterInput | null> | null;
  or?: Array<ModelVerifiedSymptomRecordLogFilterInput | null> | null;
  not?: ModelVerifiedSymptomRecordLogFilterInput | null;
};

export type ModelVerifiedSymptomRecordLogConnection = {
  __typename: "ModelVerifiedSymptomRecordLogConnection";
  items: Array<VerifiedSymptomRecordLog | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVerifiedMedicationInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  medicationInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  isOnGoing?: ModelBooleanInput | null;
  isOnGoingComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  name?: ModelStringInput | null;
  nameComment?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  reasonComment?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  symptomsInstancesComment?: ModelStringInput | null;
  givenTo?: ModelMedicationGivenToListInput | null;
  givenToComment?: ModelStringInput | null;
  dose?: ModelStringInput | null;
  doseComment?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  frequencyComment?: ModelStringInput | null;
  administrationRoute?: ModelStringInput | null;
  administrationRouteComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedMedicationInstanceFilterInput | null> | null;
  or?: Array<ModelVerifiedMedicationInstanceFilterInput | null> | null;
  not?: ModelVerifiedMedicationInstanceFilterInput | null;
};

export type ModelVerifiedMedicationInstanceConnection = {
  __typename: "ModelVerifiedMedicationInstanceConnection";
  items: Array<VerifiedMedicationInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVerifiedMedicalAttentionInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  verifiedDayInstanceId?: ModelIDInput | null;
  medicalAttentionInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelMedicalAttentionTypeInput | null;
  typeComment?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  reasonComment?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  symptomsInstancesComment?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  rememberStartDateComment?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  startDateComment?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  rememberFinishDateComment?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  finishDateComment?: ModelStringInput | null;
  wasHospitalized?: ModelBooleanInput | null;
  wasHospitalizedComment?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedMedicalAttentionInstanceFilterInput | null> | null;
  or?: Array<ModelVerifiedMedicalAttentionInstanceFilterInput | null> | null;
  not?: ModelVerifiedMedicalAttentionInstanceFilterInput | null;
};

export type ModelVerifiedMedicalAttentionInstanceConnection = {
  __typename: "ModelVerifiedMedicalAttentionInstanceConnection";
  items: Array<VerifiedMedicalAttentionInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVerifiedTemperatureRecordLogFilterInput = {
  verifiedDayInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  temperatureRecordLogID?: ModelIDInput | null;
  id?: ModelIDInput | null;
  temperature?: ModelFloatInput | null;
  temperatureComment?: ModelStringInput | null;
  unit?: ModelTemperatureUnitInput | null;
  unitComment?: ModelStringInput | null;
  route?: ModelTemperatureRouteInput | null;
  routeComment?: ModelStringInput | null;
  whichOtherRoute?: ModelStringInput | null;
  whichOtherRouteComment?: ModelStringInput | null;
  taken?: ModelBooleanInput | null;
  takenComment?: ModelStringInput | null;
  takenDate?: ModelStringInput | null;
  takenDateComment?: ModelStringInput | null;
  state?: ModelInstanceStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelVerifiedTemperatureRecordLogFilterInput | null> | null;
  or?: Array<ModelVerifiedTemperatureRecordLogFilterInput | null> | null;
  not?: ModelVerifiedTemperatureRecordLogFilterInput | null;
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null;
  state?: ModelProjectStateInput | null;
  code?: ModelStringInput | null;
  name?: ModelStringInput | null;
  sponsor?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  faultRecipients?: ModelStringInput | null;
  isEdiaryActivated?: ModelBooleanInput | null;
  isReportsActivated?: ModelBooleanInput | null;
  isICActivated?: ModelBooleanInput | null;
  isChatActivated?: ModelBooleanInput | null;
  isMFAActivated?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelProjectFilterInput | null> | null;
  or?: Array<ModelProjectFilterInput | null> | null;
  not?: ModelProjectFilterInput | null;
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection";
  items: Array<Project | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfVisitGroupFilterInput = {
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  visit?: ModelStringInput | null;
  group?: ModelStringInput | null;
  isVisible?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfVisitGroupFilterInput | null> | null;
  or?: Array<ModelConfVisitGroupFilterInput | null> | null;
  not?: ModelConfVisitGroupFilterInput | null;
};

export type ModelConfVisitGroupConnection = {
  __typename: "ModelConfVisitGroupConnection";
  items: Array<ConfVisitGroup | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelSiteFilterInput = {
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  contactInfo?: ModelStringInput | null;
  showContactInfo?: ModelBooleanInput | null;
  showContactInfoLogin?: ModelBooleanInput | null;
  timezone?: ModelStringInput | null;
  defaultLanguage?: ModelStringInput | null;
  timezoneRecipients?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSiteFilterInput | null> | null;
  or?: Array<ModelSiteFilterInput | null> | null;
  not?: ModelSiteFilterInput | null;
};

export type ModelSiteConnection = {
  __typename: "ModelSiteConnection";
  items: Array<Site | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfReportFilterInput = {
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  instancePerDay?: ModelBooleanInput | null;
  reportTitle?: ModelStringInput | null;
  showHelp?: ModelBooleanInput | null;
  reportHelp?: ModelStringInput | null;
  reportIcon?: ModelStringInput | null;
  symptomPageLayout?: ModelPageLayoutInput | null;
  programationType?: ModelReportProgramationTypeInput | null;
  plannedDayList?: ModelIntInput | null;
  isDisplayDependentOnTheEDiary?: ModelBooleanInput | null;
  forms?: ModelStringInput | null;
  isControlledBySite?: ModelBooleanInput | null;
  allowReportBackDating?: ModelBooleanInput | null;
  enableLocalNotifications?: ModelBooleanInput | null;
  futureNotifications?: ModelIntInput | null;
  alertType?: ModelOnDemandAlertTypeInput | null;
  alertWeeklyDays?: ModelIntInput | null;
  alertInBetweenDaysFrequency?: ModelIntInput | null;
  alertDuringSameDayFrequency?: ModelIntInput | null;
  alertStartTime?: ModelStringInput | null;
  alertEndTime?: ModelStringInput | null;
  jsonForm?: ModelStringInput | null;
  isSignatureRequired?: ModelBooleanInput | null;
  isGPSRequired?: ModelBooleanInput | null;
  isUserIPRequired?: ModelBooleanInput | null;
  isMediaRequired?: ModelBooleanInput | null;
  isDeviceDataRequired?: ModelBooleanInput | null;
  mediaTypes?: ModelMediaTypeListInput | null;
  isAssociatedToVisits?: ModelBooleanInput | null;
  areSymptomsRequired?: ModelBooleanInput | null;
  areAlertsRequired?: ModelBooleanInput | null;
  isDeviceDataManagementRequired?: ModelBooleanInput | null;
  availableUsers?: ModelReportAvailableUserTypeListInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfReportFilterInput | null> | null;
  or?: Array<ModelConfReportFilterInput | null> | null;
  not?: ModelConfReportFilterInput | null;
};

export type ModelConfReportConnection = {
  __typename: "ModelConfReportConnection";
  items: Array<ConfReport | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfReportBySubjectFilterInput = {
  id?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  confReportId?: ModelIDInput | null;
  isEnabled?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfReportBySubjectFilterInput | null> | null;
  or?: Array<ModelConfReportBySubjectFilterInput | null> | null;
  not?: ModelConfReportBySubjectFilterInput | null;
};

export type ModelConfEDiaryFilterInput = {
  projectId?: ModelIDInput | null;
  isConciliationRequired?: ModelBooleanInput | null;
  isWindowsConciliationRequired?: ModelBooleanInput | null;
  id?: ModelIDInput | null;
  isSignatureRequired?: ModelBooleanInput | null;
  isPastEntryEnabled?: ModelBooleanInput | null;
  isGPSRequired?: ModelBooleanInput | null;
  isUserIPRequired?: ModelBooleanInput | null;
  isDeviceDataRequired?: ModelBooleanInput | null;
  enableLocalNotifications?: ModelBooleanInput | null;
  alertType?: ModelOnDemandAlertTypeInput | null;
  alertWeeklyDays?: ModelIntInput | null;
  alertInBetweenDaysFrequency?: ModelIntInput | null;
  alertDuringSameDayFrequency?: ModelIntInput | null;
  alertStartTime?: ModelStringInput | null;
  alertEndTime?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfEDiaryFilterInput | null> | null;
  or?: Array<ModelConfEDiaryFilterInput | null> | null;
  not?: ModelConfEDiaryFilterInput | null;
};

export type ModelConfFormFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  isTemperatureRequired?: ModelBooleanInput | null;
  isMedicationRequired?: ModelBooleanInput | null;
  isMedicalAttentionRequired?: ModelBooleanInput | null;
  isMediaRequired?: ModelBooleanInput | null;
  isReconciliationRequired?: ModelBooleanInput | null;
  sectionsToReconcile?: ModelStringInput | null;
  areCommentsRequired?: ModelBooleanInput | null;
  isOtherForm?: ModelBooleanInput | null;
  mediaTypes?: ModelMediaTypeListInput | null;
  areOtherSymptomsRequired?: ModelBooleanInput | null;
  symptomPageLayout?: ModelPageLayoutInput | null;
  showHelp?: ModelBooleanInput | null;
  formHelp?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfFormFilterInput | null> | null;
  or?: Array<ModelConfFormFilterInput | null> | null;
  not?: ModelConfFormFilterInput | null;
};

export type ModelConfFormConnection = {
  __typename: "ModelConfFormConnection";
  items: Array<ConfForm | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfFormConfSymptomFilterInput = {
  confFormId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfFormConfSymptomFilterInput | null> | null;
  or?: Array<ModelConfFormConfSymptomFilterInput | null> | null;
  not?: ModelConfFormConfSymptomFilterInput | null;
};

export type ModelConfMedicationFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  showPrecondition?: ModelBooleanInput | null;
  mode?: ModelMedicationConfigurationModeInput | null;
  showRememberStartDate?: ModelBooleanInput | null;
  showRememberFinishDate?: ModelBooleanInput | null;
  showDose?: ModelBooleanInput | null;
  showFrequency?: ModelBooleanInput | null;
  showAdministrationRoute?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfMedicationFilterInput | null> | null;
  or?: Array<ModelConfMedicationFilterInput | null> | null;
  not?: ModelConfMedicationFilterInput | null;
};

export type ModelConfMedicalAttentionFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  isBindToSymptomOcurrencies?: ModelBooleanInput | null;
  showRememberStartDate?: ModelBooleanInput | null;
  showRememberFinishDate?: ModelBooleanInput | null;
  showHospitalization?: ModelBooleanInput | null;
  mode?: ModelMedicalAttentionConfigurationModeInput | null;
  showPrecondition?: ModelBooleanInput | null;
  showType?: ModelBooleanInput | null;
  medicalAttentionTypes?: ModelMedicalAttentionTypeListInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfMedicalAttentionFilterInput | null> | null;
  or?: Array<ModelConfMedicalAttentionFilterInput | null> | null;
  not?: ModelConfMedicalAttentionFilterInput | null;
};

export type ModelConfMedicalAttentionConnection = {
  __typename: "ModelConfMedicalAttentionConnection";
  items: Array<ConfMedicalAttention | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfTemperatureFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  dayRule?: ModelDayRuleInput | null;
  routeOptions?: ModelTemperatureRouteListInput | null;
  showFahrenheitUnit?: ModelBooleanInput | null;
  showTemperatureRoute?: ModelBooleanInput | null;
  ocurrencyValue?: ModelFloatInput | null;
  ocurrencyUnit?: ModelTemperatureUnitInput | null;
  showTemperatureTaken?: ModelBooleanInput | null;
  showTemperatureTakenDate?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfTemperatureFilterInput | null> | null;
  or?: Array<ModelConfTemperatureFilterInput | null> | null;
  not?: ModelConfTemperatureFilterInput | null;
};

export type ModelConfTemperatureConnection = {
  __typename: "ModelConfTemperatureConnection";
  items: Array<ConfTemperature | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfMultimediaFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  showDetail?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfMultimediaFilterInput | null> | null;
  or?: Array<ModelConfMultimediaFilterInput | null> | null;
  not?: ModelConfMultimediaFilterInput | null;
};

export type ModelConfMultimediaConnection = {
  __typename: "ModelConfMultimediaConnection";
  items: Array<ConfMultimedia | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfOtherSymptomsFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  helpText?: ModelStringInput | null;
  label?: ModelStringInput | null;
  showRememberStartDate?: ModelBooleanInput | null;
  showRememberFinishDate?: ModelBooleanInput | null;
  showProfessionalHealthCare?: ModelBooleanInput | null;
  showType?: ModelBooleanInput | null;
  showIntensity?: ModelBooleanInput | null;
  showOtherSymptom?: ModelBooleanInput | null;
  showPrecondition?: ModelBooleanInput | null;
  preconditionLabel?: ModelStringInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfOtherSymptomsFilterInput | null> | null;
  or?: Array<ModelConfOtherSymptomsFilterInput | null> | null;
  not?: ModelConfOtherSymptomsFilterInput | null;
};

export type ModelConfDictionaryFilterInput = {
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  key?: ModelStringInput | null;
  spanish?: ModelStringInput | null;
  english?: ModelStringInput | null;
  isHtml?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfDictionaryFilterInput | null> | null;
  or?: Array<ModelConfDictionaryFilterInput | null> | null;
  not?: ModelConfDictionaryFilterInput | null;
};

export type ModelConfDictionaryConnection = {
  __typename: "ModelConfDictionaryConnection";
  items: Array<ConfDictionary | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelConfInformedConsentFilterInput = {
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  icVersion?: ModelFloatInput | null;
  recipients?: ModelStringInput | null;
  type?: ModelInformedConsentTypeInput | null;
  quizPageLayout?: ModelQuizLayoutInput | null;
  approvalSealFileUrl?: ModelStringInput | null;
  isMultipleSignatureRequired?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  minRequiredSignatures?: ModelIntInput | null;
  maxRequiredSignatures?: ModelIntInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfInformedConsentFilterInput | null> | null;
  or?: Array<ModelConfInformedConsentFilterInput | null> | null;
  not?: ModelConfInformedConsentFilterInput | null;
};

export type ModelConfICSectionFilterInput = {
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  type?: ModelInformedConsentSectionTypeInput | null;
  order?: ModelIntInput | null;
  content?: ModelStringInput | null;
  mediaUrl?: ModelStringInput | null;
  mediaType?: ModelMediaTypeInput | null;
  icon?: ModelStringInput | null;
  isElectronicSignatureRequired?: ModelBooleanInput | null;
  isStudyRolRequired?: ModelBooleanInput | null;
  enableApprovalQuestions?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICSectionFilterInput | null> | null;
  or?: Array<ModelConfICSectionFilterInput | null> | null;
  not?: ModelConfICSectionFilterInput | null;
};

export type ModelConfICQuestionFilterInput = {
  confICSectionId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  description?: ModelStringInput | null;
  order?: ModelIntInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICQuestionFilterInput | null> | null;
  or?: Array<ModelConfICQuestionFilterInput | null> | null;
  not?: ModelConfICQuestionFilterInput | null;
};

export type ModelConfICAnswerFilterInput = {
  confICQuestionId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  description?: ModelStringInput | null;
  order?: ModelIntInput | null;
  onErrorMessage?: ModelStringInput | null;
  isCorrect?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICAnswerFilterInput | null> | null;
  or?: Array<ModelConfICAnswerFilterInput | null> | null;
  not?: ModelConfICAnswerFilterInput | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelSignatureInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  date?: ModelStringInput | null;
  type?: ModelSignatureTypeInput | null;
  reason?: ModelSignatureReasonInput | null;
  entityType?: ModelSignatureEntityTypeInput | null;
  entityId?: ModelStringInput | null;
  signedInfo?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSignatureInstanceFilterInput | null> | null;
  or?: Array<ModelSignatureInstanceFilterInput | null> | null;
  not?: ModelSignatureInstanceFilterInput | null;
};

export type ModelSignatureInstanceConnection = {
  __typename: "ModelSignatureInstanceConnection";
  items: Array<SignatureInstance | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelSymptomRecordLogFilterInput = {
  dayInstanceId?: ModelIDInput | null;
  symptomInstanceId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  intensity?: ModelIntensityInput | null;
  size?: ModelFloatInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSymptomRecordLogFilterInput | null> | null;
  or?: Array<ModelSymptomRecordLogFilterInput | null> | null;
  not?: ModelSymptomRecordLogFilterInput | null;
};

export type ModelSymptomInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  order?: ModelIntInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  whichOtherSymptom?: ModelStringInput | null;
  intensity?: ModelIntensityInput | null;
  size?: ModelFloatInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  isOtherSymptom?: ModelBooleanInput | null;
  medications?: ModelStringInput | null;
  medicalAttentions?: ModelStringInput | null;
  professionalHealthCare?: ModelYNAInput | null;
  hospitalAdmission?: ModelYNInput | null;
  createdAt?: ModelStringInput | null;
  rememberNoValuesTaken?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSymptomInstanceFilterInput | null> | null;
  or?: Array<ModelSymptomInstanceFilterInput | null> | null;
  not?: ModelSymptomInstanceFilterInput | null;
  symptomInstanceConfSymptomId?: ModelIDInput | null;
};

export type ModelMediaRecordLogFilterInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  mediaType?: ModelMediaTypeInput | null;
  url?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  dateTime?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelMediaRecordLogFilterInput | null> | null;
  or?: Array<ModelMediaRecordLogFilterInput | null> | null;
  not?: ModelMediaRecordLogFilterInput | null;
};

export type ModelMediaRecordLogConnection = {
  __typename: "ModelMediaRecordLogConnection";
  items: Array<MediaRecordLog | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelPDFAndAlertsTransactionLogFilterInput = {
  subjectId?: ModelIDInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  state?: ModelTransactionStateInput | null;
  source?: ModelStringInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  confReportId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelPDFAndAlertsTransactionLogFilterInput | null> | null;
  or?: Array<ModelPDFAndAlertsTransactionLogFilterInput | null> | null;
  not?: ModelPDFAndAlertsTransactionLogFilterInput | null;
};

export type ModelPDFAndAlertsTransactionLogConnection = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<PDFAndAlertsTransactionLog | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UpdateICSectionInstanceCustomMutation = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    signature?: string | null;
    createdAt: string;
    updatedAt: string;
    _lastUser?: string | null;
    _changeReason?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    question?: {
      __typename: "ComplementaryQuestion";
      id?: string | null;
      type?: ComplementaryQuestionType | null;
      question?: string | null;
      options?: Array<string | null> | null;
    } | null;
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CustomEDiaryPhaseInstanceBySubjectIdQuery = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<{
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    subject?: {
      __typename: "Subject";
      projectId: string;
      siteId: string;
      id: string;
      subjectNumber: string;
      group: string;
      state: SubjectState;
      tag?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    eDiaryInstanceId: string;
    eDiaryInstance?: {
      __typename: "EDiaryInstance";
      subjectId: string;
      confEDiaryId: string;
      siteId: string;
      id: string;
      state: InstanceState;
      confEdiaryVersion: string;
      comment?: string | null;
      alerts?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    siteId: string;
    site?: {
      __typename: "Site";
      projectId: string;
      id: string;
      name: string;
      contactInfo?: string | null;
      showContactInfo?: boolean | null;
      showContactInfoLogin?: boolean | null;
      timezone?: string | null;
      defaultLanguage?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    id: string;
    state: InstanceState;
    phase: string;
    pdfFiles?: Array<{
      __typename: "TP2File";
      uploadDate: string;
      fileUrl: string;
    }> | null;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    locked?: {
      __typename: "ConciliationLock";
      user: string;
      date: string;
    } | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CustomEDiaryInstanceBySubjectIdQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    subject?: {
      __typename: "Subject";
      projectId: string;
    } | null;
    pdfFiles?: Array<{
      __typename: "TP2File";
      fileUrl: string;
      uploadDate: string;
    }> | null;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CustomReportInstanceBySubjectIdQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    confReport?: {
      __typename: "ConfReport";
      projectId: string;
      id: string;
      groups: Array<string>;
      phases: Array<string>;
      instancePerDay: boolean;
      reportTitle: string;
      showHelp: boolean;
      reportHelp?: string | null;
      reportIcon?: string | null;
      symptomPageLayout: PageLayout;
      programationType: ReportProgramationType;
      plannedDayList?: Array<number> | null;
      isDisplayDependentOnTheEDiary?: boolean | null;
      forms?: Array<string> | null;
      isControlledBySite?: boolean | null;
      allowReportBackDating: boolean;
      enableLocalNotifications?: boolean | null;
      futureNotifications?: number | null;
      alertType?: OnDemandAlertType | null;
      alertWeeklyDays?: Array<number | null> | null;
      alertInBetweenDaysFrequency?: number | null;
      alertDuringSameDayFrequency?: number | null;
      alertStartTime?: string | null;
      alertEndTime?: string | null;
      jsonForm?: string | null;
      isSignatureRequired: boolean;
      isGPSRequired: boolean;
      isUserIPRequired: boolean;
      isMediaRequired: boolean;
      isDeviceDataRequired: boolean;
      mediaTypes?: Array<MediaType> | null;
      isAssociatedToVisits?: boolean | null;
      areSymptomsRequired?: boolean | null;
      areAlertsRequired?: boolean | null;
      isDeviceDataManagementRequired?: boolean | null;
      availableUsers?: Array<ReportAvailableUserType | null> | null;
      state?: ConfState | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    subjectId: string;
    subject?: {
      __typename: "Subject";
      projectId: string;
      siteId: string;
      id: string;
      subjectNumber: string;
      group: string;
      state: SubjectState;
      tag?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    siteId: string;
    site?: {
      __typename: "Site";
      projectId: string;
      id: string;
      name: string;
      contactInfo?: string | null;
      showContactInfo?: boolean | null;
      showContactInfoLogin?: boolean | null;
      timezone?: string | null;
      defaultLanguage?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    reportedBy?: {
      __typename: "UserEntityType";
      username: string;
      userType: ReportAvailableUserType;
    } | null;
    timezonesHistory?: Array<string | null> | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    symptomOcurrencies?: Array<{
      __typename: "SymptomOccurrency";
      confSymptomId: string;
      symptomInstanceId?: string | null;
      symptomRecordLogId?: string | null;
      occurrency?: boolean | null;
      order?: number | null;
    }> | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    pdfFiles?: Array<{
      __typename: "TP2File";
      uploadDate: string;
      fileUrl: string;
    }> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CustomGetSymptomInstanceQuery = {
  __typename: "SymptomInstance";
  subjectId: string;
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  confSymptomId?: string | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
    question?: {
      __typename: "IntensityQuestion";
      id: string;
      intensity?: Intensity | null;
      order: number;
      question: string;
    } | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type CustomEDiaryInstanceByConfEDiaryIdQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    pdfFiles?: Array<{
      __typename: "TP2File";
      fileUrl: string;
      uploadDate: string;
    }> | null;
    state: InstanceState;
    siteId: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfDayByConfEdiaryCustomQuery = {
  __typename: "ModelConfDayConnection";
  items: Array<{
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    confForm?: {
      __typename: "ConfForm";
      id: string;
      name: string;
      isReconciliationRequired?: boolean | null;
    } | null;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    groups: Array<string>;
    phases: Array<string>;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfSymptomByConfEdiaryIdCustomQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    isIntensityRequired?: boolean | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isFinishDateRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    intensityQuestions?: Array<{
      __typename: "IntensityQuestion";
      id: string;
      order: number;
      question: string;
      intensity?: Intensity | null;
    } | null> | null;
    noneIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lowIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    mediumIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    highIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lifeThreateningScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type listUsersSubjectsCustomQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    state: UserState;
    phoneNumber?: string | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ListDayInstancesCustomQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    subject?: {
      __typename: "Subject";
      subjectNumber: string;
      id: string;
      state: SubjectState;
      projectId: string;
      siteId: string;
      currentScheduledPhase?: {
        __typename: "ScheduledPhase";
        date?: string | null;
        phase: string;
        state: ScheduledPhaseState;
      } | null;
      _deleted?: boolean | null;
    } | null;
    eDiaryPhaseInstanceId: string;
    eDiaryPhaseInstance?: {
      __typename: "EDiaryPhaseInstance";
      phase: string;
      state: InstanceState;
    } | null;
    confDayId: string;
    confDay?: {
      __typename: "ConfDay";
      dayName: string;
    } | null;
    completedDate?: string | null;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    _lastUser: string;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type DayInstanceByEDiaryPhaseInstanceCustomQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    subject?: {
      __typename: "Subject";
      subjectNumber: string;
      id: string;
      state: SubjectState;
      projectId: string;
      siteId: string;
      _deleted?: boolean | null;
    } | null;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    confDay?: {
      __typename: "ConfDay";
      dayName: string;
      order: number;
      confFormId: string;
      startDay: number;
      endDay: number;
      hidePDFDayColumn?: boolean | null;
      _deleted?: boolean | null;
      confForm?: {
        __typename: "ConfForm";
        confEdiaryId: string;
        projectId: string;
        id: string;
        name: string;
        isTemperatureRequired: boolean;
        isMedicationRequired: boolean;
        isMedicalAttentionRequired: boolean;
        isReconciliationRequired?: boolean | null;
        sectionsToReconcile?: Array<string | null> | null;
        isMediaRequired: boolean;
        areCommentsRequired: boolean;
        isOtherForm?: boolean | null;
        mediaTypes?: Array<MediaType> | null;
        areOtherSymptomsRequired: boolean;
        symptomPageLayout: PageLayout;
        showHelp: boolean;
        formHelp?: string | null;
        _lastUser: string;
        _changeReason?: string | null;
        createdAt: string;
        updatedAt: string;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
      } | null;
    } | null;
    siteId: string;
    site?: {
      __typename: "Site";
      projectId: string;
      id: string;
      name: string;
      contactInfo?: string | null;
      showContactInfo?: boolean | null;
      showContactInfoLogin?: boolean | null;
      timezone?: string | null;
      defaultLanguage?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    symptomOcurrencies?: Array<{
      __typename: "SymptomOccurrency";
      confSymptomId: string;
      symptomInstanceId?: string | null;
      symptomRecordLogId?: string | null;
      occurrency?: boolean | null;
      order?: number | null;
    }> | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ReportInstanceByConfReportIdCustomQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    subject?: {
      __typename: "Subject";
      subjectNumber: string;
      id: string;
      siteId: string;
      state: SubjectState;
      projectId: string;
      currentScheduledPhase?: {
        __typename: "ScheduledPhase";
        date?: string | null;
        phase: string;
        state: ScheduledPhaseState;
      } | null;
    } | null;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    _lastUser: string;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfSymptomByConfReportIdCustomQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensitySizeLabel?: string | null;
    isFinishDateRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    intensityType?: IntensityType | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    intensityQuestions?: Array<{
      __typename: "IntensityQuestion";
      id: string;
      order: number;
      question: string;
      intensity?: Intensity | null;
    } | null> | null;
    highIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    mediumIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lowIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    noneIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lifeThreateningScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSymptomRecordLogsBySubjectIdCustomQuery = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<{
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
};

export type VerifiedDayInstanceByDayInstanceIdIdCustomQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    symptomOcurrencies?: Array<{
      __typename: "SymptomOccurrency";
      confSymptomId: string;
      occurrency?: boolean | null;
      order?: number | null;
      symptomInstanceId?: string | null;
      symptomRecordLogId?: string | null;
    } | null> | null;
    symptomOcurrenciesComment?: string | null;
    subjectId: string;
    state?: InstanceState | null;
    startDate?: string | null;
    siteId: string;
    medicationGivenToComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    id: string;
    hasOtherSymptomsComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    hasMedicalAttentionComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    finishDate?: string | null;
    eDiaryPhaseInstanceId: string;
    dayInstanceId?: string | null;
    createdAt: string;
    confDayId: string;
    _version: number;
    _lastUser: string;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    completedDate?: string | null;
  } | null>;
};

export type VerifiedDayInstanceByEdiaryPhaseIdCustomQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    symptomOcurrencies?: Array<{
      __typename: "SymptomOccurrency";
      confSymptomId: string;
      occurrency?: boolean | null;
      order?: number | null;
      symptomInstanceId?: string | null;
      symptomRecordLogId?: string | null;
    } | null> | null;
    symptomOcurrenciesComment?: string | null;
    subjectId: string;
    state?: InstanceState | null;
    startDate?: string | null;
    siteId: string;
    medicationGivenToComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    id: string;
    hasOtherSymptomsComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    hasMedicalAttentionComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    finishDate?: string | null;
    eDiaryPhaseInstanceId: string;
    dayInstanceId?: string | null;
    createdAt: string;
    confDayId: string;
    _version: number;
    _lastUser: string;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    completedDate?: string | null;
  } | null>;
};

export type ConfMedicationByConfEdiaryIdCustomQuery = {
  __typename: "ModelConfMedicationConnection";
  items: Array<{
    __typename: "ConfMedication";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showPrecondition?: boolean | null;
    mode?: MedicationConfigurationMode | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showDose?: boolean | null;
    showFrequency?: boolean | null;
    showAdministrationRoute?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
};

export type VerifiedSymptomInstanceBySymptomInstanceIdCustomQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    confSymptomId?: string | null;
    _version: number;
    _lastUser: string;
    _lastChangedAt: number;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    createdAt: string;
    finishDate?: string | null;
    finishDateComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    id: string;
    state?: InstanceState | null;
    intensity?: Intensity | null;
    intensityQuestionAnswers?: Array<{
      __typename: "IntensityQuestionAnswer";
      answer?: boolean | null;
      question?: {
        __typename: "IntensityQuestion";
        id: string;
        intensity?: Intensity | null;
        order: number;
        question: string;
      } | null;
    } | null> | null;
    intensityQuestionAnswersComment?: string | null;
    isOtherSymptom?: boolean | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    subjectId?: string | null;
    symptom?: Symptom | null;
    symptomInstanceId?: string | null;
    type?: SymptomType | null;
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceConfSymptomId?: string | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfOtherSymptomsByConfEdiaryCustomQuery = {
  __typename: "ModelConfOtherSymptomsConnection";
  items: Array<{
    __typename: "ConfOtherSymptoms";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showProfessionalHealthCare?: boolean | null;
    showType?: boolean | null;
    showIntensity?: boolean | null;
    showOtherSymptom?: boolean | null;
    showPrecondition?: boolean | null;
    preconditionLabel?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
};

export type VerifiedSymptomInstanceBySubjectIdCustomQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    confSymptomId?: string | null;
    _version: number;
    _lastUser: string;
    _lastChangedAt: number;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    createdAt: string;
    finishDate?: string | null;
    finishDateComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    id: string;
    state?: InstanceState | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    intensityQuestionAnswers?: Array<{
      __typename: "IntensityQuestionAnswer";
      answer?: boolean | null;
      question?: {
        __typename: "IntensityQuestion";
        id: string;
        intensity?: Intensity | null;
        order: number;
        question: string;
      } | null;
    } | null> | null;
    intensityQuestionAnswersComment?: string | null;
    isOtherSymptom?: boolean | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    subjectId?: string | null;
    symptom?: Symptom | null;
    symptomInstanceId?: string | null;
    type?: SymptomType | null;
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceConfSymptomId?: string | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomInstanceByDayInstanceIdCustomQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    _version: number;
    _lastUser: string;
    _lastChangedAt: number;
    _deviceSOVersion?: string | null;
    _deviceModel?: string | null;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    confSymptom?: {
      __typename: "ConfSymptom";
      _changeReason?: string | null;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      _lastUser: string;
      _version: number;
      confEdiaryId?: string | null;
      confReportId?: string | null;
      createdAt: string;
      decimalPlaces?: number | null;
      highIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      id: string;
      injectionSide?: InjectionSide | null;
      injectionSite?: InjectionSite | null;
      intensityType?: IntensityType | null;
      intensitySizeLabel?: string | null;
      isFinishDateRequired?: boolean | null;
      isIntensityQuestionsRequired?: boolean | null;
      isIntensityRequired?: boolean | null;
      isStartDateRequired?: boolean | null;
      intensityQuestions?: Array<{
        __typename: "IntensityQuestion";
        id: string;
        intensity?: Intensity | null;
        order: number;
        question: string;
      } | null> | null;
      lifeThreateningScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      lowIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      mediumIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      noValuesTakenLabel?: string | null;
      noneIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        startValue: number;
        label?: string | null;
      } | null;
      order: number;
      projectId: string;
      showHelp: boolean;
      showNoValuesTaken?: boolean | null;
      symptom: Symptom;
      symptomHelp?: string | null;
      updatedAt: string;
      type: SymptomType;
      symptomLabel?: string | null;
      symptomIcon?: string | null;
    } | null;
    confSymptomId?: string | null;
    createdAt?: string | null;
    dayInstanceId?: string | null;
    finishDate?: string | null;
    id: string;
    hospitalAdmission?: YN | null;
    intensity?: Intensity | null;
    isOtherSymptom?: boolean | null;
    medicalAttentions?: Array<string> | null;
    medications?: Array<string> | null;
    order: number;
    professionalHealthCare?: YNA | null;
    rememberFinishDate?: boolean | null;
    intensityQuestionAnswers?: Array<{
      __typename: "IntensityQuestionAnswer";
      answer?: boolean | null;
      question?: {
        __typename: "IntensityQuestion";
        id: string;
        intensity?: Intensity | null;
        order: number;
        question: string;
      } | null;
    }> | null;
    rememberNoValuesTaken?: boolean | null;
    rememberStartDate?: boolean | null;
    reportInstanceId?: string | null;
    size?: number | null;
    startDate?: string | null;
    state: InstanceState;
    subjectId: string;
    symptom: Symptom;
    symptomInstanceConfSymptomId?: string | null;
    type?: SymptomType | null;
    updatedAt: string;
    whichOtherSymptom?: string | null;
  } | null>;
};

export type SymptomInstanceBySubjectIdCustomQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    _version: number;
    _lastUser: string;
    _lastChangedAt: number;
    _deviceSOVersion?: string | null;
    _deviceModel?: string | null;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    confSymptom?: {
      __typename: "ConfSymptom";
      _changeReason?: string | null;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      _lastUser: string;
      _version: number;
      confEdiaryId?: string | null;
      confReportId?: string | null;
      createdAt: string;
      decimalPlaces?: number | null;
      highIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      id: string;
      injectionSide?: InjectionSide | null;
      injectionSite?: InjectionSite | null;
      intensityType?: IntensityType | null;
      intensitySizeLabel?: string | null;
      isFinishDateRequired?: boolean | null;
      isIntensityQuestionsRequired?: boolean | null;
      isIntensityRequired?: boolean | null;
      isStartDateRequired?: boolean | null;
      intensityQuestions?: Array<{
        __typename: "IntensityQuestion";
        id: string;
        intensity?: Intensity | null;
        order: number;
        question: string;
      } | null> | null;
      lifeThreateningScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      lowIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      mediumIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        label?: string | null;
        startValue: number;
      } | null;
      noValuesTakenLabel?: string | null;
      noneIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        endValue: number;
        helpText: string;
        startValue: number;
        label?: string | null;
      } | null;
      order: number;
      projectId: string;
      showHelp: boolean;
      showNoValuesTaken?: boolean | null;
      symptom: Symptom;
      symptomHelp?: string | null;
      updatedAt: string;
      type: SymptomType;
      symptomLabel?: string | null;
      symptomIcon?: string | null;
    } | null;
    confSymptomId?: string | null;
    createdAt?: string | null;
    dayInstanceId?: string | null;
    finishDate?: string | null;
    id: string;
    hospitalAdmission?: YN | null;
    intensity?: Intensity | null;
    isOtherSymptom?: boolean | null;
    medicalAttentions?: Array<string> | null;
    medications?: Array<string> | null;
    order: number;
    professionalHealthCare?: YNA | null;
    rememberFinishDate?: boolean | null;
    intensityQuestionAnswers?: Array<{
      __typename: "IntensityQuestionAnswer";
      answer?: boolean | null;
      question?: {
        __typename: "IntensityQuestion";
        id: string;
        intensity?: Intensity | null;
        order: number;
        question: string;
      } | null;
    }> | null;
    rememberNoValuesTaken?: boolean | null;
    rememberStartDate?: boolean | null;
    reportInstanceId?: string | null;
    size?: number | null;
    startDate?: string | null;
    state: InstanceState;
    subjectId: string;
    symptom: Symptom;
    symptomInstanceConfSymptomId?: string | null;
    type?: SymptomType | null;
    updatedAt: string;
    whichOtherSymptom?: string | null;
  } | null>;
};

export type GetConfSymptomByConfEdiaryIdCustomQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    noneIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lowIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    mediumIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    highIntensityScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lifeThreateningScaleOptions?: {
      __typename: "IntensityScale";
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    intensityQuestions?: Array<{
      __typename: "IntensityQuestion";
      id: string;
      order: number;
      question: string;
      intensity?: Intensity | null;
    } | null> | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
};

export type ConfFormConfSymptomsByConfSymptomIdCustomQuery = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<{
    __typename: "ConfFormConfSymptom";
    id: string;
    confFormId: string;
    confSymptomId: string;
    _version: number;
  } | null>;
};

export type ListAlertInstancesCustomQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    confAlert?: {
      __typename: "ConfAlert";
      confReportId?: string | null;
      confEdiaryId?: string | null;
      confSymptomId?: string | null;
      confTemperatureId?: string | null;
      id: string;
      alertName: string;
      recipients?: Array<string> | null;
      type: AlertType;
      mailSubject?: string | null;
      mailBody?: string | null;
      SMSBody?: string | null;
      alertRules: Array<{
        __typename: "AlertRule";
        ruleId?: string | null;
        alertRuleType: AlertRuleType;
        expectedValue?: string | null;
      }>;
      _lastUser: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    subjectId: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfAlertByConfEdiaryIdCustomQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    state?: ConfState | null;
    alertName: string;
    alertRules: Array<{
      __typename: "AlertRule";
      ruleId?: string | null;
      alertRuleType: AlertRuleType;
      expectedValue?: string | null;
    }>;
    visitList?: Array<{
      __typename: "TriggeredVisit";
      order: number;
      visitName: string;
    }> | null;
    suspendVisitList?: Array<{
      __typename: "TriggeredVisit";
      order: number;
      visitName: string;
    }> | null;
    siteRecipients?: Array<{
      __typename: "SiteRecipients";
      recipients: Array<string>;
      siteId: string;
    }> | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfAlertByConfReportIdCustomQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    state?: ConfState | null;
    alertName: string;
    alertRules: Array<{
      __typename: "AlertRule";
      ruleId?: string | null;
      alertRuleType: AlertRuleType;
      expectedValue?: string | null;
    }>;
    visitList?: Array<{
      __typename: "TriggeredVisit";
      order: number;
      visitName: string;
    }> | null;
    siteRecipients?: Array<{
      __typename: "SiteRecipients";
      recipients: Array<string>;
      siteId: string;
    }> | null;
    triggerVisitCompletion?: boolean | null;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AuthorizedSitesBySiteIdCustomQuery = {
  __typename: "ModelAuthorizedSiteConnection";
  items: Array<{
    __typename: "AuthorizedSite";
    id: string;
    user?: {
      __typename: "User";
      id: string;
      login: string;
      name?: string | null;
      email: string;
      role: string;
      state: UserState;
      phoneNumber?: string | null;
      subjects?: string | null;
      sites?: string | null;
      projects?: string | null;
    } | null;
    siteId: string;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type getConfReportBySubjectCustomQuery = {
  __typename: "ModelConfReportBySubjectConnection";
  items: Array<{
    __typename: "ConfReportBySubject";
    id: string;
    subjectId: string;
    confReportId: string;
    isEnabled?: boolean | null;
    _deleted?: boolean | null;
    _version: number;
  } | null>;
};

export type SubjectByProjectIdCustomQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    _lastUser: string;
    _changeReason?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    site?: {
      __typename: "Site";
      name: string;
    } | null;
    currentScheduledPhase?: {
      __typename: "ScheduledPhase";
      date?: string | null;
      phase: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SubjectSearchByProjectIdCustomQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    id: string;
    siteId: string;
    subjectNumber: string;
    state: SubjectState;
    _deleted?: boolean | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TemperatureRecordLogBySubjectIdCustomQuery = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<{
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    dayInstance?: {
      __typename: "DayInstance";
      state: InstanceState;
      startDate: string;
      finishDate: string;
      completedDate?: string | null;
      confDay?: {
        __typename: "ConfDay";
        confFormId: string;
        dayName: string;
        order: number;
      } | null;
      eDiaryPhaseInstance?: {
        __typename: "EDiaryPhaseInstance";
        phase: string;
      } | null;
    } | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    state: InstanceState;
    _lastUser: string;
    _changeReason?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicalAttentionInstanceBySubjectIdCustomQuery = {
  __typename: "ModelMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    dayInstance?: {
      __typename: "DayInstance";
      confDay?: {
        __typename: "ConfDay";
        confFormId: string;
        dayName: string;
      } | null;
    } | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UserByLoginCustomQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    termsAndConditions?: Array<{
      __typename: "TermsAndConditions";
      termsAndConditionsVersion: string;
      policiesVersion: string;
      acceptanceDate: string;
    } | null> | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    permissions?: Array<TP2Permission | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicationInstanceBySubjectIdCustomQuery = {
  __typename: "ModelMedicationInstanceConnection";
  items: Array<{
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    dayInstance?: {
      __typename: "DayInstance";
      confDay?: {
        __typename: "ConfDay";
        confFormId: string;
        dayName: string;
      } | null;
    } | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type InformedConsentByConsenterUserCustomQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    ConfInformedConsent?: {
      __typename: "ConfInformedConsent";
      id: string;
      name: string;
      state?: ConfState | null;
      sites?: Array<{
        __typename: "InformedConsentSite";
        site: string;
        recipients?: Array<string> | null;
        totalSubjects: number;
      } | null> | null;
      _deleted?: boolean | null;
    } | null;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    pdfFiles?: Array<{
      __typename: "TP2File";
      fileUrl: string;
      uploadDate: string;
    } | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedTemperatureBySubjectIdCustomQuery = {
  __typename: "ModelVerifiedTemperatureRecordLogConnection";
  items: Array<{
    __typename: "VerifiedTemperatureRecordLog";
    verifiedDayInstanceId?: string | null;
    subjectId: string;
    temperatureRecordLogID?: string | null;
    id: string;
    temperature?: number | null;
    temperatureComment?: string | null;
    unit?: TemperatureUnit | null;
    unitComment?: string | null;
    route?: TemperatureRoute | null;
    routeComment?: string | null;
    whichOtherRoute?: string | null;
    whichOtherRouteComment?: string | null;
    taken?: boolean | null;
    takenComment?: string | null;
    takenDate?: string | null;
    takenDateComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedDayInstance?: {
      __typename: "VerifiedDayInstance";
      confDay?: {
        __typename: "ConfDay";
        order: number;
        dayName: string;
        confFormId: string;
        confEdiaryId: string;
      } | null;
      dayInstance?: {
        __typename: "DayInstance";
        id: string;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConsentedUserByUserIdCustomQuery = {
  __typename: "ModelConsentedUserConnection";
  items: Array<{
    __typename: "ConsentedUser";
    informedConsentInstance?: {
      __typename: "InformedConsentInstance";
      projectId: string;
      siteId: string;
      confInformedConsentId: string;
      ConfInformedConsent?: {
        __typename: "ConfInformedConsent";
        projectId: string;
        id: string;
        name: string;
        icVersion: number;
        state?: ConfState | null;
        sites?: Array<{
          __typename: "InformedConsentSite";
          site: string;
          recipients?: Array<string> | null;
          totalSubjects: number;
        } | null> | null;
        type: InformedConsentType;
        quizPageLayout?: QuizLayout | null;
        approvalSealFileUrl: string;
        isMultipleSignatureRequired: boolean;
        minRequiredSignatures: number;
        maxRequiredSignatures: number;
        _lastUser: string;
        _changeReason?: string | null;
        createdAt: string;
        updatedAt: string;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
      } | null;
      consenterUserId?: string | null;
      subjectId?: string | null;
      id: string;
      consentedIdentificator: string;
      consentedName?: string | null;
      consenterName?: string | null;
      consenterIdentification?: string | null;
      consenterSignature?: string | null;
      consenterRole?: string | null;
      state: InformedConsentState;
      stateChanges?: Array<{
        __typename: "StateChange";
        state?: InformedConsentState | null;
        date?: string | null;
      } | null> | null;
      pdfFiles?: Array<{
        __typename: "TP2File";
        uploadDate: string;
        fileUrl: string;
      } | null> | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    identification?: string | null;
    id: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    informedConsentInstanceId: string;
    kinship?: string | null;
    name?: string | null;
    projectId: string;
    signature?: string | null;
    state: InformedConsentState;
    stateChanges?: Array<{
      __typename: "StateChange";
      date?: string | null;
      state?: InformedConsentState | null;
    } | null> | null;
    requiresSendingEmail?: boolean | null;
    siteId: string;
    updatedAt: string;
    userId: string;
    _lastUser?: string | null;
    _changeReason?: string | null;
  } | null>;
};

export type ConfEdiaryByProjectIdCustomQuery = {
  __typename: "ModelConfEDiaryConnection";
  items: Array<{
    __typename: "ConfEDiary";
    projectId: string;
    windows?: Array<{
      __typename: "ConfEDiaryWindow";
      name: string;
      phases: Array<string>;
      daysAfterVisit: number;
      totalWindowDays: number;
    } | null> | null;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfSymptomByConfFormIdCustomQuery = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<{
    __typename: "ConfFormConfSymptom";
    confFormId: string;
    _version: number;
    _lastUser: string;
    _lastChangedAt: number;
    _deleted?: boolean | null;
    _changeReason?: string | null;
    projectId: string;
    id: string;
    createdAt: string;
    confSymptomId: string;
    confSymptom?: {
      __typename: "ConfSymptom";
      confReportId?: string | null;
      confEdiaryId?: string | null;
      projectId: string;
      id: string;
      type: SymptomType;
      symptom: Symptom;
      order: number;
      injectionSite?: InjectionSite | null;
      injectionSide?: InjectionSide | null;
      showHelp: boolean;
      symptomHelp?: string | null;
      symptomLabel?: string | null;
      symptomIcon?: string | null;
      decimalPlaces?: number | null;
      minSize?: number | null;
      maxSize?: number | null;
      intensityType?: IntensityType | null;
      intensitySizeLabel?: string | null;
      noneIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        startValue: number;
        endValue: number;
        helpText: string;
        label?: string | null;
      } | null;
      lowIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        startValue: number;
        endValue: number;
        helpText: string;
        label?: string | null;
      } | null;
      mediumIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        startValue: number;
        endValue: number;
        helpText: string;
        label?: string | null;
      } | null;
      highIntensityScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        startValue: number;
        endValue: number;
        helpText: string;
        label?: string | null;
      } | null;
      lifeThreateningScaleOptions?: {
        __typename: "IntensityScale";
        active: boolean;
        startValue: number;
        endValue: number;
        helpText: string;
        label?: string | null;
      } | null;
      intensityQuestions?: Array<{
        __typename: "IntensityQuestion";
        id: string;
        order: number;
        question: string;
        intensity?: Intensity | null;
      } | null> | null;
      isIntensityQuestionsRequired?: boolean | null;
      isIntensityRequired?: boolean | null;
      isStartDateRequired?: boolean | null;
      isFinishDateRequired?: boolean | null;
      showNoValuesTaken?: boolean | null;
      noValuesTakenLabel?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
  } | null>;
};

export type AlertInstanceBySubjectIdCustomQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    confAlert?: {
      __typename: "ConfAlert";
      confReportId?: string | null;
      confEdiaryId?: string | null;
      confSymptomId?: string | null;
      confTemperatureId?: string | null;
      id: string;
      alertName: string;
      recipients?: Array<string> | null;
      type: AlertType;
      mailSubject?: string | null;
      mailBody?: string | null;
      SMSBody?: string | null;
      alertRules: Array<{
        __typename: "AlertRule";
        ruleId?: string | null;
        alertRuleType: AlertRuleType;
        expectedValue?: string | null;
      }>;
      _lastUser: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    subjectId: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICSectionByConfInformedConsentIdCustomQuery = {
  __typename: "ModelConfICSectionConnection";
  items: Array<{
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    complementaryQuestions?: Array<{
      __typename: "ComplementaryQuestion";
      id?: string | null;
      type?: ComplementaryQuestionType | null;
      question?: string | null;
      options?: Array<string | null> | null;
    } | null> | null;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICQuestionByConfInformedConsentIdCustomQuery = {
  __typename: "ModelConfICQuestionConnection";
  items: Array<{
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confICSection?: {
      __typename: "ConfICSection";
      state?: ConfState | null;
      _deleted?: boolean | null;
    } | null;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICAnswerByConfInformedConsentIdCustomQuery = {
  __typename: "ModelConfICAnswerConnection";
  items: Array<{
    __typename: "ConfICAnswer";
    confICQuestionId: string;
    confICQuestion?: {
      __typename: "ConfICQuestion";
      state?: ConfState | null;
      _deleted?: boolean | null;
    } | null;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    onErrorMessage?: string | null;
    isCorrect: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICQuestionInstanceByConsentedUserIdCustomQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICQuestion?: {
      __typename: "ConfICQuestion";
      state?: ConfState | null;
      _deleted?: boolean | null;
    } | null;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICSectionInstanceByConsentedUserIdCustomQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    confICSection?: {
      __typename: "ConfICSection";
      state?: ConfState | null;
      _deleted?: boolean | null;
    } | null;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    complementaryQuestionsAnswers?: Array<{
      __typename: "ComplementaryQuestionAnswer";
      type?: ComplementaryQuestionType | null;
      question?: {
        __typename: "ComplementaryQuestion";
        id?: string | null;
        type?: ComplementaryQuestionType | null;
        question?: string | null;
        options?: Array<string | null> | null;
      } | null;
      answer?: string | null;
    }> | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfInformedConsentByProjectIDCustomQuery = {
  __typename: "ModelConfInformedConsentConnection";
  items: Array<{
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    sites?: Array<{
      __typename: "InformedConsentSite";
      site: string;
      recipients?: Array<string> | null;
      totalSubjects: number;
    } | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    state?: ConfState | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConsentedUserByInformedConsentInstanceIdCustomQuery = {
  __typename: "ModelConsentedUserConnection";
  items: Array<{
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      login: string;
      name?: string | null;
      email: string;
      role: string;
      roles?: Array<string | null> | null;
      permissions?: Array<TP2Permission | null> | null;
      state: UserState;
      phoneNumber?: string | null;
      firebaseToken?: string | null;
      isMFAActivated?: boolean | null;
      subjects?: string | null;
      sites?: string | null;
      projects?: string | null;
    } | null;
    informedConsentInstanceId: string;
    informedConsentInstance?: {
      __typename: "InformedConsentInstance";
      consentedIdentificator: string;
    } | null;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    stateChanges?: Array<{
      __typename: "StateChange";
      state?: InformedConsentState | null;
      date?: string | null;
    } | null> | null;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type InformedConsentInstanceByICConfigurationCustomQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    stateChanges?: Array<{
      __typename: "StateChange";
      state?: InformedConsentState | null;
      date?: string | null;
    } | null> | null;
    pdfFiles?: Array<{
      __typename: "TP2File";
      uploadDate: string;
      fileUrl: string;
    } | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICSectionInstanceByInformedConsentInstanceIdCustomQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    state?: InstanceState | null;
    confICSection?: {
      __typename: "ConfICSection";
      title: string;
      id: string;
    } | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICQuestionInstanceByInformedConsentInstanceIdCustomQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    confICQuestion?: {
      __typename: "ConfICQuestion";
      description: string;
      id: string;
    } | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CreateBackupInstanceMutation = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateBackupInstanceMutation = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteBackupInstanceMutation = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateRoleMutation = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateRoleMutation = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteRoleMutation = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfChatUserMutation = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfChatUserMutation = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfChatUserMutation = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateChatMessageMutation = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateChatMessageMutation = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteChatMessageMutation = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateVerifiedDayInstanceMutation = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedDayInstanceMutation = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVerifiedDayInstanceMutation = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateCommentConciliationInstanceMutation = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCommentConciliationInstanceMutation = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCommentConciliationInstanceMutation = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateVerifiedSymptomInstanceMutation = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type UpdateVerifiedSymptomInstanceMutation = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type DeleteVerifiedSymptomInstanceMutation = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type CreateVerifiedSymptomRecordLogMutation = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedSymptomRecordLogMutation = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVerifiedSymptomRecordLogMutation = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateVerifiedMedicationInstanceMutation = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedMedicationInstanceMutation = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVerifiedMedicationInstanceMutation = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateVerifiedMedicalAttentionInstanceMutation = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedMedicalAttentionInstanceMutation = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVerifiedMedicalAttentionInstanceMutation = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateVerifiedTemperatureRecordLogMutation = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVerifiedTemperatureRecordLogMutation = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVerifiedTemperatureRecordLogMutation = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateProjectMutation = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateProjectMutation = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteProjectMutation = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfVisitGroupMutation = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfVisitGroupMutation = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfVisitGroupMutation = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateSiteMutation = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSiteMutation = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSiteMutation = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateAuthorizedSiteMutation = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateAuthorizedSiteMutation = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteAuthorizedSiteMutation = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfReportMutation = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfReportMutation = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfReportMutation = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfReportBySubjectMutation = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfReportBySubjectMutation = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfReportBySubjectMutation = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfEDiaryMutation = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfEDiaryMutation = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfEDiaryMutation = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfDayMutation = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfDayMutation = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfDayMutation = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfFormMutation = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfFormMutation = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfFormMutation = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfSymptomMutation = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfSymptomMutation = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfSymptomMutation = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfFormConfSymptomMutation = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfFormConfSymptomMutation = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfFormConfSymptomMutation = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfMedicationMutation = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfMedicationMutation = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfMedicationMutation = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfMedicalAttentionMutation = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfMedicalAttentionMutation = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfMedicalAttentionMutation = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfTemperatureMutation = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfTemperatureMutation = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfTemperatureMutation = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfMultimediaMutation = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfMultimediaMutation = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfMultimediaMutation = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfOtherSymptomsMutation = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfOtherSymptomsMutation = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfOtherSymptomsMutation = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfAlertMutation = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfAlertMutation = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfAlertMutation = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfDictionaryMutation = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfDictionaryMutation = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfDictionaryMutation = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfInformedConsentMutation = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfInformedConsentMutation = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfInformedConsentMutation = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfICSectionMutation = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfICSectionMutation = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfICSectionMutation = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfICQuestionMutation = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfICQuestionMutation = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfICQuestionMutation = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfICAnswerMutation = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfICAnswerMutation = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConfICAnswerMutation = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateInformedConsentInstanceMutation = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateInformedConsentInstanceMutation = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteInformedConsentInstanceMutation = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConsentedUserMutation = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConsentedUserMutation = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteConsentedUserMutation = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateICSectionInstanceMutation = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateICSectionInstanceMutation = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteICSectionInstanceMutation = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateICQuestionInstanceMutation = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateICQuestionInstanceMutation = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteICQuestionInstanceMutation = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateSubjectMutation = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSubjectMutation = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSubjectMutation = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateReportInstanceMutation = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateReportInstanceMutation = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteReportInstanceMutation = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateSignatureInstanceMutation = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSignatureInstanceMutation = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSignatureInstanceMutation = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateEDiaryInstanceMutation = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateEDiaryInstanceMutation = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteEDiaryInstanceMutation = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateEDiaryPhaseInstanceMutation = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateEDiaryPhaseInstanceMutation = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteEDiaryPhaseInstanceMutation = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateDayInstanceMutation = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateDayInstanceMutation = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteDayInstanceMutation = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateSymptomRecordLogMutation = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSymptomRecordLogMutation = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSymptomRecordLogMutation = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateSymptomInstanceMutation = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type UpdateSymptomInstanceMutation = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type DeleteSymptomInstanceMutation = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type CreateMedicationInstanceMutation = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMedicationInstanceMutation = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteMedicationInstanceMutation = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateMedicalAttentionInstanceMutation = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMedicalAttentionInstanceMutation = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteMedicalAttentionInstanceMutation = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateTemperatureRecordLogMutation = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateTemperatureRecordLogMutation = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteTemperatureRecordLogMutation = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateMediaRecordLogMutation = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMediaRecordLogMutation = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteMediaRecordLogMutation = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateAlertInstanceMutation = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateAlertInstanceMutation = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteAlertInstanceMutation = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreatePDFAndAlertsTransactionLogMutation = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdatePDFAndAlertsTransactionLogMutation = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeletePDFAndAlertsTransactionLogMutation = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type GetAuditTraceQuery = {
  __typename: "AuditTrace";
  id: string;
  relatedEntityId: string;
  entity: string;
  projectId?: string | null;
  eventDateTime: string;
  eventType: string;
  _lastUser: string;
  _changeReason?: string | null;
  data: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListAuditTracesQuery = {
  __typename: "ModelAuditTraceConnection";
  items: Array<{
    __typename: "AuditTrace";
    id: string;
    relatedEntityId: string;
    entity: string;
    projectId?: string | null;
    eventDateTime: string;
    eventType: string;
    _lastUser: string;
    _changeReason?: string | null;
    data: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncAuditTracesQuery = {
  __typename: "ModelAuditTraceConnection";
  items: Array<{
    __typename: "AuditTrace";
    id: string;
    relatedEntityId: string;
    entity: string;
    projectId?: string | null;
    eventDateTime: string;
    eventType: string;
    _lastUser: string;
    _changeReason?: string | null;
    data: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetAuditInfoQuery = {
  __typename: "ModelAuditTraceConnection";
  items: Array<{
    __typename: "AuditTrace";
    id: string;
    relatedEntityId: string;
    entity: string;
    projectId?: string | null;
    eventDateTime: string;
    eventType: string;
    _lastUser: string;
    _changeReason?: string | null;
    data: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetUserLoginAttemptsQuery = {
  __typename: "UserLoginAttempts";
  login: string;
  attempts: number;
  lastAttempt: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListUserLoginAttemptsQuery = {
  __typename: "ModelUserLoginAttemptsConnection";
  items: Array<{
    __typename: "UserLoginAttempts";
    login: string;
    attempts: number;
    lastAttempt: string;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncUserLoginAttemptsQuery = {
  __typename: "ModelUserLoginAttemptsConnection";
  items: Array<{
    __typename: "UserLoginAttempts";
    login: string;
    attempts: number;
    lastAttempt: string;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetBackupInstanceQuery = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListBackupInstancesQuery = {
  __typename: "ModelBackupInstanceConnection";
  items: Array<{
    __typename: "BackupInstance";
    projectId: string;
    siteId: string;
    subjectId: string;
    id: string;
    data: string;
    instanceName: EntityName;
    STORAGETYPE?: string | null;
    gps?: string | null;
    appVersion: string;
    userIP?: string | null;
    deviceModel: string;
    _lastUser: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncBackupInstancesQuery = {
  __typename: "ModelBackupInstanceConnection";
  items: Array<{
    __typename: "BackupInstance";
    projectId: string;
    siteId: string;
    subjectId: string;
    id: string;
    data: string;
    instanceName: EntityName;
    STORAGETYPE?: string | null;
    gps?: string | null;
    appVersion: string;
    userIP?: string | null;
    deviceModel: string;
    _lastUser: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type BackupInstanceBySubjectIdQuery = {
  __typename: "ModelBackupInstanceConnection";
  items: Array<{
    __typename: "BackupInstance";
    projectId: string;
    siteId: string;
    subjectId: string;
    id: string;
    data: string;
    instanceName: EntityName;
    STORAGETYPE?: string | null;
    gps?: string | null;
    appVersion: string;
    userIP?: string | null;
    deviceModel: string;
    _lastUser: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UserByLoginQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UserByEmailQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type UserByProjectQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetRoleQuery = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListRolesQuery = {
  __typename: "ModelRoleConnection";
  items: Array<{
    __typename: "role";
    id: string;
    name: string;
    isEnabled?: boolean | null;
    state?: string | null;
    permissions?: Array<TP2Permission | null> | null;
    isForProjects?: boolean | null;
    projects?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncRolesQuery = {
  __typename: "ModelRoleConnection";
  items: Array<{
    __typename: "role";
    id: string;
    name: string;
    isEnabled?: boolean | null;
    state?: string | null;
    permissions?: Array<TP2Permission | null> | null;
    isForProjects?: boolean | null;
    projects?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfChatUserQuery = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfChatUsersQuery = {
  __typename: "ModelConfChatUserConnection";
  items: Array<{
    __typename: "ConfChatUser";
    userId: string;
    siteId: string;
    projectId: string;
    id: string;
    isChatActivated: boolean;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfChatUsersQuery = {
  __typename: "ModelConfChatUserConnection";
  items: Array<{
    __typename: "ConfChatUser";
    userId: string;
    siteId: string;
    projectId: string;
    id: string;
    isChatActivated: boolean;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfCharUserByUserIdQuery = {
  __typename: "ModelConfChatUserConnection";
  items: Array<{
    __typename: "ConfChatUser";
    userId: string;
    siteId: string;
    projectId: string;
    id: string;
    isChatActivated: boolean;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfCharUserByProjectIdQuery = {
  __typename: "ModelConfChatUserConnection";
  items: Array<{
    __typename: "ConfChatUser";
    userId: string;
    siteId: string;
    projectId: string;
    id: string;
    isChatActivated: boolean;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetChatMessageQuery = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListChatMessagesQuery = {
  __typename: "ModelChatMessageConnection";
  items: Array<{
    __typename: "ChatMessage";
    siteId: string;
    userId: string;
    message: string;
    sender: ChatMessageSender;
    senderAlias: string;
    createdAt: string;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncChatMessagesQuery = {
  __typename: "ModelChatMessageConnection";
  items: Array<{
    __typename: "ChatMessage";
    siteId: string;
    userId: string;
    message: string;
    sender: ChatMessageSender;
    senderAlias: string;
    createdAt: string;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ChatMessagesBySiteIdQuery = {
  __typename: "ModelChatMessageConnection";
  items: Array<{
    __typename: "ChatMessage";
    siteId: string;
    userId: string;
    message: string;
    sender: ChatMessageSender;
    senderAlias: string;
    createdAt: string;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ChatMessagesByUserIdQuery = {
  __typename: "ModelChatMessageConnection";
  items: Array<{
    __typename: "ChatMessage";
    siteId: string;
    userId: string;
    message: string;
    sender: ChatMessageSender;
    senderAlias: string;
    createdAt: string;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVerifiedDayInstanceQuery = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListVerifiedDayInstancesQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVerifiedDayInstancesQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedDayInstancesBySubjectIdQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedDayInstancesByEDiaryPhaseInstanceQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedDayInstancesByConfDayIdQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedDayInstancesBySiteIdQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedDayInstancesByDayInstanceIdQuery = {
  __typename: "ModelVerifiedDayInstanceConnection";
  items: Array<{
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCommentConciliationInstanceQuery = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListCommentConciliationInstancesQuery = {
  __typename: "ModelCommentConciliationInstanceConnection";
  items: Array<{
    __typename: "CommentConciliationInstance";
    eDiaryPhaseInstanceId: string;
    id: string;
    comment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCommentConciliationInstancesQuery = {
  __typename: "ModelCommentConciliationInstanceConnection";
  items: Array<{
    __typename: "CommentConciliationInstance";
    eDiaryPhaseInstanceId: string;
    id: string;
    comment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CommentConciliationInstanceByEDiaryPhaseInstanceQuery = {
  __typename: "ModelCommentConciliationInstanceConnection";
  items: Array<{
    __typename: "CommentConciliationInstance";
    eDiaryPhaseInstanceId: string;
    id: string;
    comment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVerifiedSymptomInstanceQuery = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type ListVerifiedSymptomInstancesQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVerifiedSymptomInstancesQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomInstanceBySubjectIdQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomInstanceByDayInstanceIdQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomInstanceBySymptomInstanceIdQuery = {
  __typename: "ModelVerifiedSymptomInstanceConnection";
  items: Array<{
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVerifiedSymptomRecordLogQuery = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListVerifiedSymptomRecordLogsQuery = {
  __typename: "ModelVerifiedSymptomRecordLogConnection";
  items: Array<{
    __typename: "VerifiedSymptomRecordLog";
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceId?: string | null;
    subjectId?: string | null;
    symptomRecordLogId?: string | null;
    id: string;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVerifiedSymptomRecordLogsQuery = {
  __typename: "ModelVerifiedSymptomRecordLogConnection";
  items: Array<{
    __typename: "VerifiedSymptomRecordLog";
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceId?: string | null;
    subjectId?: string | null;
    symptomRecordLogId?: string | null;
    id: string;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomRecordLogsByDayInstanceIdQuery = {
  __typename: "ModelVerifiedSymptomRecordLogConnection";
  items: Array<{
    __typename: "VerifiedSymptomRecordLog";
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceId?: string | null;
    subjectId?: string | null;
    symptomRecordLogId?: string | null;
    id: string;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomRecordLogsByVerifiedSymptomInstanceIdQuery = {
  __typename: "ModelVerifiedSymptomRecordLogConnection";
  items: Array<{
    __typename: "VerifiedSymptomRecordLog";
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceId?: string | null;
    subjectId?: string | null;
    symptomRecordLogId?: string | null;
    id: string;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedSymptomRecordLogsBySymptomRecordLogIdQuery = {
  __typename: "ModelVerifiedSymptomRecordLogConnection";
  items: Array<{
    __typename: "VerifiedSymptomRecordLog";
    verifiedDayInstanceId?: string | null;
    verifiedSymptomInstanceId?: string | null;
    subjectId?: string | null;
    symptomRecordLogId?: string | null;
    id: string;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVerifiedMedicationInstanceQuery = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListVerifiedMedicationInstancesQuery = {
  __typename: "ModelVerifiedMedicationInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicationInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicationInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    isOnGoing?: boolean | null;
    isOnGoingComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    name?: string | null;
    nameComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string | null> | null;
    symptomsInstancesComment?: string | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    givenToComment?: string | null;
    dose?: string | null;
    doseComment?: string | null;
    frequency?: string | null;
    frequencyComment?: string | null;
    administrationRoute?: string | null;
    administrationRouteComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVerifiedMedicationInstancesQuery = {
  __typename: "ModelVerifiedMedicationInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicationInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicationInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    isOnGoing?: boolean | null;
    isOnGoingComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    name?: string | null;
    nameComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string | null> | null;
    symptomsInstancesComment?: string | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    givenToComment?: string | null;
    dose?: string | null;
    doseComment?: string | null;
    frequency?: string | null;
    frequencyComment?: string | null;
    administrationRoute?: string | null;
    administrationRouteComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedMedicationInstanceBySubjectIdQuery = {
  __typename: "ModelVerifiedMedicationInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicationInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicationInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    isOnGoing?: boolean | null;
    isOnGoingComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    name?: string | null;
    nameComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string | null> | null;
    symptomsInstancesComment?: string | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    givenToComment?: string | null;
    dose?: string | null;
    doseComment?: string | null;
    frequency?: string | null;
    frequencyComment?: string | null;
    administrationRoute?: string | null;
    administrationRouteComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedMedicationByDayInstanceIdQuery = {
  __typename: "ModelVerifiedMedicationInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicationInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicationInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    isOnGoing?: boolean | null;
    isOnGoingComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    name?: string | null;
    nameComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string | null> | null;
    symptomsInstancesComment?: string | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    givenToComment?: string | null;
    dose?: string | null;
    doseComment?: string | null;
    frequency?: string | null;
    frequencyComment?: string | null;
    administrationRoute?: string | null;
    administrationRouteComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedMedicationInstanceByMedicationIdQuery = {
  __typename: "ModelVerifiedMedicationInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicationInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicationInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    isOnGoing?: boolean | null;
    isOnGoingComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    name?: string | null;
    nameComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string | null> | null;
    symptomsInstancesComment?: string | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    givenToComment?: string | null;
    dose?: string | null;
    doseComment?: string | null;
    frequency?: string | null;
    frequencyComment?: string | null;
    administrationRoute?: string | null;
    administrationRouteComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVerifiedMedicalAttentionInstanceQuery = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListVerifiedMedicalAttentionInstancesQuery = {
  __typename: "ModelVerifiedMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicalAttentionInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicalAttentionInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: MedicalAttentionType | null;
    typeComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string> | null;
    symptomsInstancesComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    wasHospitalized?: boolean | null;
    wasHospitalizedComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVerifiedMedicalAttentionInstancesQuery = {
  __typename: "ModelVerifiedMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicalAttentionInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicalAttentionInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: MedicalAttentionType | null;
    typeComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string> | null;
    symptomsInstancesComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    wasHospitalized?: boolean | null;
    wasHospitalizedComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedMedicalAttentionInstanceBySubjectIdQuery = {
  __typename: "ModelVerifiedMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicalAttentionInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicalAttentionInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: MedicalAttentionType | null;
    typeComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string> | null;
    symptomsInstancesComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    wasHospitalized?: boolean | null;
    wasHospitalizedComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedMedicalAttentionByDayInstanceIdQuery = {
  __typename: "ModelVerifiedMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "VerifiedMedicalAttentionInstance";
    subjectId: string;
    verifiedDayInstanceId?: string | null;
    medicalAttentionInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: MedicalAttentionType | null;
    typeComment?: string | null;
    reason?: string | null;
    reasonComment?: string | null;
    symptomsInstances?: Array<string> | null;
    symptomsInstancesComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    wasHospitalized?: boolean | null;
    wasHospitalizedComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVerifiedTemperatureRecordLogQuery = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListVerifiedTemperatureRecordLogsQuery = {
  __typename: "ModelVerifiedTemperatureRecordLogConnection";
  items: Array<{
    __typename: "VerifiedTemperatureRecordLog";
    verifiedDayInstanceId?: string | null;
    subjectId: string;
    temperatureRecordLogID?: string | null;
    id: string;
    temperature?: number | null;
    temperatureComment?: string | null;
    unit?: TemperatureUnit | null;
    unitComment?: string | null;
    route?: TemperatureRoute | null;
    routeComment?: string | null;
    whichOtherRoute?: string | null;
    whichOtherRouteComment?: string | null;
    taken?: boolean | null;
    takenComment?: string | null;
    takenDate?: string | null;
    takenDateComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVerifiedTemperatureRecordLogsQuery = {
  __typename: "ModelVerifiedTemperatureRecordLogConnection";
  items: Array<{
    __typename: "VerifiedTemperatureRecordLog";
    verifiedDayInstanceId?: string | null;
    subjectId: string;
    temperatureRecordLogID?: string | null;
    id: string;
    temperature?: number | null;
    temperatureComment?: string | null;
    unit?: TemperatureUnit | null;
    unitComment?: string | null;
    route?: TemperatureRoute | null;
    routeComment?: string | null;
    whichOtherRoute?: string | null;
    whichOtherRouteComment?: string | null;
    taken?: boolean | null;
    takenComment?: string | null;
    takenDate?: string | null;
    takenDateComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedTemperatureRecordLogByVerifiedDayInstanceIdQuery = {
  __typename: "ModelVerifiedTemperatureRecordLogConnection";
  items: Array<{
    __typename: "VerifiedTemperatureRecordLog";
    verifiedDayInstanceId?: string | null;
    subjectId: string;
    temperatureRecordLogID?: string | null;
    id: string;
    temperature?: number | null;
    temperatureComment?: string | null;
    unit?: TemperatureUnit | null;
    unitComment?: string | null;
    route?: TemperatureRoute | null;
    routeComment?: string | null;
    whichOtherRoute?: string | null;
    whichOtherRouteComment?: string | null;
    taken?: boolean | null;
    takenComment?: string | null;
    takenDate?: string | null;
    takenDateComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type VerifiedTemperatureRecordLogBySubjectIdQuery = {
  __typename: "ModelVerifiedTemperatureRecordLogConnection";
  items: Array<{
    __typename: "VerifiedTemperatureRecordLog";
    verifiedDayInstanceId?: string | null;
    subjectId: string;
    temperatureRecordLogID?: string | null;
    id: string;
    temperature?: number | null;
    temperatureComment?: string | null;
    unit?: TemperatureUnit | null;
    unitComment?: string | null;
    route?: TemperatureRoute | null;
    routeComment?: string | null;
    whichOtherRoute?: string | null;
    whichOtherRouteComment?: string | null;
    taken?: boolean | null;
    takenComment?: string | null;
    takenDate?: string | null;
    takenDateComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetProjectQuery = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListProjectsQuery = {
  __typename: "ModelProjectConnection";
  items: Array<{
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncProjectsQuery = {
  __typename: "ModelProjectConnection";
  items: Array<{
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfVisitGroupQuery = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfVisitGroupsQuery = {
  __typename: "ModelConfVisitGroupConnection";
  items: Array<{
    __typename: "ConfVisitGroup";
    projectId: string;
    id: string;
    visit: string;
    group: string;
    isVisible: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfVisitGroupsQuery = {
  __typename: "ModelConfVisitGroupConnection";
  items: Array<{
    __typename: "ConfVisitGroup";
    projectId: string;
    id: string;
    visit: string;
    group: string;
    isVisible: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfVisitGroupByProjectIdQuery = {
  __typename: "ModelConfVisitGroupConnection";
  items: Array<{
    __typename: "ConfVisitGroup";
    projectId: string;
    id: string;
    visit: string;
    group: string;
    isVisible: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSiteQuery = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListSitesQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSitesQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SitesByProjectIdQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetAuthorizedSiteQuery = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListAuthorizedSitesQuery = {
  __typename: "ModelAuthorizedSiteConnection";
  items: Array<{
    __typename: "AuthorizedSite";
    id: string;
    userId: string;
    siteId: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncAuthorizedSitesQuery = {
  __typename: "ModelAuthorizedSiteConnection";
  items: Array<{
    __typename: "AuthorizedSite";
    id: string;
    userId: string;
    siteId: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AuthorizedSitesByUserIdQuery = {
  __typename: "ModelAuthorizedSiteConnection";
  items: Array<{
    __typename: "AuthorizedSite";
    id: string;
    userId: string;
    siteId: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AuthorizedSitesBySiteIdQuery = {
  __typename: "ModelAuthorizedSiteConnection";
  items: Array<{
    __typename: "AuthorizedSite";
    id: string;
    userId: string;
    siteId: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfReportQuery = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfReportsQuery = {
  __typename: "ModelConfReportConnection";
  items: Array<{
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfReportsQuery = {
  __typename: "ModelConfReportConnection";
  items: Array<{
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfReportByProjectQuery = {
  __typename: "ModelConfReportConnection";
  items: Array<{
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfReportBySubjectQuery = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfReportBySubjectsQuery = {
  __typename: "ModelConfReportBySubjectConnection";
  items: Array<{
    __typename: "ConfReportBySubject";
    id: string;
    subjectId: string;
    confReportId: string;
    isEnabled?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfReportBySubjectsQuery = {
  __typename: "ModelConfReportBySubjectConnection";
  items: Array<{
    __typename: "ConfReportBySubject";
    id: string;
    subjectId: string;
    confReportId: string;
    isEnabled?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfReportBySubjectIdQuery = {
  __typename: "ModelConfReportBySubjectConnection";
  items: Array<{
    __typename: "ConfReportBySubject";
    id: string;
    subjectId: string;
    confReportId: string;
    isEnabled?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfEDiaryQuery = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfEDiariesQuery = {
  __typename: "ModelConfEDiaryConnection";
  items: Array<{
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfEDiariesQuery = {
  __typename: "ModelConfEDiaryConnection";
  items: Array<{
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfEdiaryByProjectQuery = {
  __typename: "ModelConfEDiaryConnection";
  items: Array<{
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfDayQuery = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfDaysQuery = {
  __typename: "ModelConfDayConnection";
  items: Array<{
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfDaysQuery = {
  __typename: "ModelConfDayConnection";
  items: Array<{
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfDayByConfEdiaryQuery = {
  __typename: "ModelConfDayConnection";
  items: Array<{
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfFormQuery = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfFormsQuery = {
  __typename: "ModelConfFormConnection";
  items: Array<{
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfFormsQuery = {
  __typename: "ModelConfFormConnection";
  items: Array<{
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfFormByConfEdiaryIdQuery = {
  __typename: "ModelConfFormConnection";
  items: Array<{
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfSymptomQuery = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfSymptomsQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfSymptomsQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfSymptomByConfReportIdQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfSymptomByConfEdiaryIdQuery = {
  __typename: "ModelConfSymptomConnection";
  items: Array<{
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfFormConfSymptomQuery = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfFormConfSymptomsQuery = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<{
    __typename: "ConfFormConfSymptom";
    confFormId: string;
    confSymptomId: string;
    projectId: string;
    id: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfFormConfSymptomsQuery = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<{
    __typename: "ConfFormConfSymptom";
    confFormId: string;
    confSymptomId: string;
    projectId: string;
    id: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfSymptomByConfFormIdQuery = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<{
    __typename: "ConfFormConfSymptom";
    confFormId: string;
    confSymptomId: string;
    projectId: string;
    id: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfFormByConfSymptomQuery = {
  __typename: "ModelConfFormConfSymptomConnection";
  items: Array<{
    __typename: "ConfFormConfSymptom";
    confFormId: string;
    confSymptomId: string;
    projectId: string;
    id: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfMedicationQuery = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfMedicationsQuery = {
  __typename: "ModelConfMedicationConnection";
  items: Array<{
    __typename: "ConfMedication";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showPrecondition?: boolean | null;
    mode?: MedicationConfigurationMode | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showDose?: boolean | null;
    showFrequency?: boolean | null;
    showAdministrationRoute?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfMedicationsQuery = {
  __typename: "ModelConfMedicationConnection";
  items: Array<{
    __typename: "ConfMedication";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showPrecondition?: boolean | null;
    mode?: MedicationConfigurationMode | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showDose?: boolean | null;
    showFrequency?: boolean | null;
    showAdministrationRoute?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMedicationByConfEdiaryQuery = {
  __typename: "ModelConfMedicationConnection";
  items: Array<{
    __typename: "ConfMedication";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showPrecondition?: boolean | null;
    mode?: MedicationConfigurationMode | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showDose?: boolean | null;
    showFrequency?: boolean | null;
    showAdministrationRoute?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMedicationByConfFormQuery = {
  __typename: "ModelConfMedicationConnection";
  items: Array<{
    __typename: "ConfMedication";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showPrecondition?: boolean | null;
    mode?: MedicationConfigurationMode | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showDose?: boolean | null;
    showFrequency?: boolean | null;
    showAdministrationRoute?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfMedicalAttentionQuery = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfMedicalAttentionsQuery = {
  __typename: "ModelConfMedicalAttentionConnection";
  items: Array<{
    __typename: "ConfMedicalAttention";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    isBindToSymptomOcurrencies?: boolean | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showHospitalization?: boolean | null;
    mode?: MedicalAttentionConfigurationMode | null;
    showPrecondition?: boolean | null;
    showType?: boolean | null;
    medicalAttentionTypes?: Array<MedicalAttentionType> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfMedicalAttentionsQuery = {
  __typename: "ModelConfMedicalAttentionConnection";
  items: Array<{
    __typename: "ConfMedicalAttention";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    isBindToSymptomOcurrencies?: boolean | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showHospitalization?: boolean | null;
    mode?: MedicalAttentionConfigurationMode | null;
    showPrecondition?: boolean | null;
    showType?: boolean | null;
    medicalAttentionTypes?: Array<MedicalAttentionType> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMedicalAttentionByConfEdiaryQuery = {
  __typename: "ModelConfMedicalAttentionConnection";
  items: Array<{
    __typename: "ConfMedicalAttention";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    isBindToSymptomOcurrencies?: boolean | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showHospitalization?: boolean | null;
    mode?: MedicalAttentionConfigurationMode | null;
    showPrecondition?: boolean | null;
    showType?: boolean | null;
    medicalAttentionTypes?: Array<MedicalAttentionType> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMedicalAttentionByConfFormQuery = {
  __typename: "ModelConfMedicalAttentionConnection";
  items: Array<{
    __typename: "ConfMedicalAttention";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    isBindToSymptomOcurrencies?: boolean | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showHospitalization?: boolean | null;
    mode?: MedicalAttentionConfigurationMode | null;
    showPrecondition?: boolean | null;
    showType?: boolean | null;
    medicalAttentionTypes?: Array<MedicalAttentionType> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfTemperatureQuery = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfTemperaturesQuery = {
  __typename: "ModelConfTemperatureConnection";
  items: Array<{
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfTemperaturesQuery = {
  __typename: "ModelConfTemperatureConnection";
  items: Array<{
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfTemperatureByConfEdiaryQuery = {
  __typename: "ModelConfTemperatureConnection";
  items: Array<{
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfTemperatureByConfFormQuery = {
  __typename: "ModelConfTemperatureConnection";
  items: Array<{
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfMultimediaQuery = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfMultimediasQuery = {
  __typename: "ModelConfMultimediaConnection";
  items: Array<{
    __typename: "ConfMultimedia";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showDetail?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfMultimediasQuery = {
  __typename: "ModelConfMultimediaConnection";
  items: Array<{
    __typename: "ConfMultimedia";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showDetail?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMultimediaByConfEdiaryQuery = {
  __typename: "ModelConfMultimediaConnection";
  items: Array<{
    __typename: "ConfMultimedia";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showDetail?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfMultimediaByConfFormQuery = {
  __typename: "ModelConfMultimediaConnection";
  items: Array<{
    __typename: "ConfMultimedia";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showDetail?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfOtherSymptomsQuery = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfOtherSymptomsQuery = {
  __typename: "ModelConfOtherSymptomsConnection";
  items: Array<{
    __typename: "ConfOtherSymptoms";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showProfessionalHealthCare?: boolean | null;
    showType?: boolean | null;
    showIntensity?: boolean | null;
    showOtherSymptom?: boolean | null;
    showPrecondition?: boolean | null;
    preconditionLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfOtherSymptomsQuery = {
  __typename: "ModelConfOtherSymptomsConnection";
  items: Array<{
    __typename: "ConfOtherSymptoms";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showProfessionalHealthCare?: boolean | null;
    showType?: boolean | null;
    showIntensity?: boolean | null;
    showOtherSymptom?: boolean | null;
    showPrecondition?: boolean | null;
    preconditionLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfOtherSymptomsByConfEdiaryQuery = {
  __typename: "ModelConfOtherSymptomsConnection";
  items: Array<{
    __typename: "ConfOtherSymptoms";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showProfessionalHealthCare?: boolean | null;
    showType?: boolean | null;
    showIntensity?: boolean | null;
    showOtherSymptom?: boolean | null;
    showPrecondition?: boolean | null;
    preconditionLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfOtherSymptomsByConfFormQuery = {
  __typename: "ModelConfOtherSymptomsConnection";
  items: Array<{
    __typename: "ConfOtherSymptoms";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showProfessionalHealthCare?: boolean | null;
    showType?: boolean | null;
    showIntensity?: boolean | null;
    showOtherSymptom?: boolean | null;
    showPrecondition?: boolean | null;
    preconditionLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfAlertQuery = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfAlertsQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfAlertsQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfAlertByConfReportIdQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfAlertByConfEdiaryIdQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfAlertByConfSymptomIdQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfAlertByConfTemperatureIdQuery = {
  __typename: "ModelConfAlertConnection";
  items: Array<{
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfDictionaryQuery = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfDictionariesQuery = {
  __typename: "ModelConfDictionaryConnection";
  items: Array<{
    __typename: "ConfDictionary";
    projectId: string;
    id: string;
    key: string;
    spanish?: string | null;
    english?: string | null;
    isHtml?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfDictionariesQuery = {
  __typename: "ModelConfDictionaryConnection";
  items: Array<{
    __typename: "ConfDictionary";
    projectId: string;
    id: string;
    key: string;
    spanish?: string | null;
    english?: string | null;
    isHtml?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfDictionaryByProjectIdQuery = {
  __typename: "ModelConfDictionaryConnection";
  items: Array<{
    __typename: "ConfDictionary";
    projectId: string;
    id: string;
    key: string;
    spanish?: string | null;
    english?: string | null;
    isHtml?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfInformedConsentQuery = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfInformedConsentsQuery = {
  __typename: "ModelConfInformedConsentConnection";
  items: Array<{
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfInformedConsentsQuery = {
  __typename: "ModelConfInformedConsentConnection";
  items: Array<{
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfInformedConsentByProjectIDQuery = {
  __typename: "ModelConfInformedConsentConnection";
  items: Array<{
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfICSectionQuery = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfICSectionsQuery = {
  __typename: "ModelConfICSectionConnection";
  items: Array<{
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfICSectionsQuery = {
  __typename: "ModelConfICSectionConnection";
  items: Array<{
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICSectionByConfInformedConsentIdQuery = {
  __typename: "ModelConfICSectionConnection";
  items: Array<{
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfICQuestionQuery = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfICQuestionsQuery = {
  __typename: "ModelConfICQuestionConnection";
  items: Array<{
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfICQuestionsQuery = {
  __typename: "ModelConfICQuestionConnection";
  items: Array<{
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICQuestionByConfICSectionIdQuery = {
  __typename: "ModelConfICQuestionConnection";
  items: Array<{
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICQuestionByConfInformedConsentIdQuery = {
  __typename: "ModelConfICQuestionConnection";
  items: Array<{
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConfICAnswerQuery = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConfICAnswersQuery = {
  __typename: "ModelConfICAnswerConnection";
  items: Array<{
    __typename: "ConfICAnswer";
    confICQuestionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    onErrorMessage?: string | null;
    isCorrect: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConfICAnswersQuery = {
  __typename: "ModelConfICAnswerConnection";
  items: Array<{
    __typename: "ConfICAnswer";
    confICQuestionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    onErrorMessage?: string | null;
    isCorrect: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICAnswerByConfICQuestionIdQuery = {
  __typename: "ModelConfICAnswerConnection";
  items: Array<{
    __typename: "ConfICAnswer";
    confICQuestionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    onErrorMessage?: string | null;
    isCorrect: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfICAnswerByConfInformedConsentIdQuery = {
  __typename: "ModelConfICAnswerConnection";
  items: Array<{
    __typename: "ConfICAnswer";
    confICQuestionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    onErrorMessage?: string | null;
    isCorrect: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetInformedConsentInstanceQuery = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListInformedConsentInstancesQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncInformedConsentInstancesQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfInformedConsentByProjectQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type InformedConsentInstanceByICConfigurationQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type InformedConsentByConsenterUserQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type InformedConsentBySubjectQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type InformedConsentByConsentedIdentificatorQuery = {
  __typename: "ModelInformedConsentInstanceConnection";
  items: Array<{
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetConsentedUserQuery = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListConsentedUsersQuery = {
  __typename: "ModelConsentedUserConnection";
  items: Array<{
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncConsentedUsersQuery = {
  __typename: "ModelConsentedUserConnection";
  items: Array<{
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConsentedUserByUserIdQuery = {
  __typename: "ModelConsentedUserConnection";
  items: Array<{
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConsentedUserByInformedConsentInstanceIdQuery = {
  __typename: "ModelConsentedUserConnection";
  items: Array<{
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetICSectionInstanceQuery = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListICSectionInstancesQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncICSectionInstancesQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICSectionInstanceByInformedConsentInstanceIdQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICSectionInstanceByConfICSectionIdQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICSectionInstanceByConsentedUserIdQuery = {
  __typename: "ModelICSectionInstanceConnection";
  items: Array<{
    __typename: "ICSectionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICSectionId: string;
    id: string;
    consentedUserId: string;
    consentedSignatureDate?: string | null;
    progress: number;
    startDate?: string | null;
    completedDate?: string | null;
    isCompleted: boolean;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetICQuestionInstanceQuery = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListICQuestionInstancesQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncICQuestionInstancesQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICQuestionInstanceByInformedConsentInstanceIdQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICQuestionInstanceByConfICSectionIdQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ICQuestionInstanceByConsentedUserIdQuery = {
  __typename: "ModelICQuestionInstanceConnection";
  items: Array<{
    __typename: "ICQuestionInstance";
    projectId: string;
    informedConsentInstanceId: string;
    confICQuestionId: string;
    confICSectionId: string;
    consentedUserId: string;
    id: string;
    failedAttempts: number;
    isCompleted: boolean;
    startDate?: string | null;
    completedDate?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSubjectQuery = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListSubjectsQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSubjectsQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SubjectByProjectIdQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SubjectsBySiteIdQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SubjectBySubjectNumberAndSiteQuery = {
  __typename: "ModelSubjectConnection";
  items: Array<{
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetReportInstanceQuery = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListReportInstancesQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncReportInstancesQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ReportInstanceByConfReportIdQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ReportInstanceBySubjectIdQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ReportInstancesBySiteIdQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetReportInstanceByStateAndCompletedDateQuery = {
  __typename: "ModelReportInstanceConnection";
  items: Array<{
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSignatureInstanceQuery = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListSignatureInstancesQuery = {
  __typename: "ModelSignatureInstanceConnection";
  items: Array<{
    __typename: "SignatureInstance";
    subjectId?: string | null;
    id: string;
    date: string;
    type: SignatureType;
    reason: SignatureReason;
    entityType: SignatureEntityType;
    entityId: string;
    signedInfo: string;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSignatureInstancesQuery = {
  __typename: "ModelSignatureInstanceConnection";
  items: Array<{
    __typename: "SignatureInstance";
    subjectId?: string | null;
    id: string;
    date: string;
    type: SignatureType;
    reason: SignatureReason;
    entityType: SignatureEntityType;
    entityId: string;
    signedInfo: string;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SignaturesBySubjectIdQuery = {
  __typename: "ModelSignatureInstanceConnection";
  items: Array<{
    __typename: "SignatureInstance";
    subjectId?: string | null;
    id: string;
    date: string;
    type: SignatureType;
    reason: SignatureReason;
    entityType: SignatureEntityType;
    entityId: string;
    signedInfo: string;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetEDiaryInstanceQuery = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListEDiaryInstancesQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncEDiaryInstancesQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryInstanceBySubjectIdQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryInstanceByConfEDiaryIdQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryInstancesBySiteIdQuery = {
  __typename: "ModelEDiaryInstanceConnection";
  items: Array<{
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetEDiaryPhaseInstanceQuery = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListEDiaryPhaseInstancesQuery = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<{
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncEDiaryPhaseInstancesQuery = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<{
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryPhaseInstanceBySubjectIdQuery = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<{
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type DayInstanceByEDiaryInstanceQuery = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<{
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryPhaseInstancesBySiteIdQuery = {
  __typename: "ModelEDiaryPhaseInstanceConnection";
  items: Array<{
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetDayInstanceQuery = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListDayInstancesQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncDayInstancesQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type DayInstanceBySubjectIdQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type DayInstanceByEDiaryPhaseInstanceQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type DayInstanceByConfDayIdQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type DayInstancesBySiteIdQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetDayInstanceByStateAndCompletedDateQuery = {
  __typename: "ModelDayInstanceConnection";
  items: Array<{
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSymptomRecordLogQuery = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListSymptomRecordLogsQuery = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<{
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSymptomRecordLogsQuery = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<{
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomRecordLogByDayInstanceIdQuery = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<{
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomRecordLogBysymptomInstanceIdQuery = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<{
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomRecordLogBySubjectIdQuery = {
  __typename: "ModelSymptomRecordLogConnection";
  items: Array<{
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSymptomInstanceQuery = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type ListSymptomInstancesQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSymptomInstancesQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomInstanceBySubjectIdQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomInstanceByReportInstanceIdQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomInstanceByDayInstanceIdQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomInstanceByConfSymptomIdQuery = {
  __typename: "ModelSymptomInstanceConnection";
  items: Array<{
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetMedicationInstanceQuery = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListMedicationInstancesQuery = {
  __typename: "ModelMedicationInstanceConnection";
  items: Array<{
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncMedicationInstancesQuery = {
  __typename: "ModelMedicationInstanceConnection";
  items: Array<{
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicationInstanceBySubjectIdQuery = {
  __typename: "ModelMedicationInstanceConnection";
  items: Array<{
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicationByDayInstanceIdQuery = {
  __typename: "ModelMedicationInstanceConnection";
  items: Array<{
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetMedicalAttentionInstanceQuery = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListMedicalAttentionInstancesQuery = {
  __typename: "ModelMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncMedicalAttentionInstancesQuery = {
  __typename: "ModelMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicalAttentionInstanceBySubjectIdQuery = {
  __typename: "ModelMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MedicalAttentionByDayInstanceIdQuery = {
  __typename: "ModelMedicalAttentionInstanceConnection";
  items: Array<{
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetTemperatureRecordLogQuery = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListTemperatureRecordLogsQuery = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<{
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncTemperatureRecordLogsQuery = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<{
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TemperatureRecordLogByDayInstanceIdQuery = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<{
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TemperatureRecordLogByReportInstanceIdQuery = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<{
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TemperatureRecordLogBySubjectIdQuery = {
  __typename: "ModelTemperatureRecordLogConnection";
  items: Array<{
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetMediaRecordLogQuery = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListMediaRecordLogsQuery = {
  __typename: "ModelMediaRecordLogConnection";
  items: Array<{
    __typename: "MediaRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    mediaType: MediaType;
    url: string;
    comment?: string | null;
    dateTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncMediaRecordLogsQuery = {
  __typename: "ModelMediaRecordLogConnection";
  items: Array<{
    __typename: "MediaRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    mediaType: MediaType;
    url: string;
    comment?: string | null;
    dateTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MediaRecordLogByDayInstanceIdQuery = {
  __typename: "ModelMediaRecordLogConnection";
  items: Array<{
    __typename: "MediaRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    mediaType: MediaType;
    url: string;
    comment?: string | null;
    dateTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MediaRecordLogByReportInstanceIdQuery = {
  __typename: "ModelMediaRecordLogConnection";
  items: Array<{
    __typename: "MediaRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    mediaType: MediaType;
    url: string;
    comment?: string | null;
    dateTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type MediaRecordLogBySubjectIdQuery = {
  __typename: "ModelMediaRecordLogConnection";
  items: Array<{
    __typename: "MediaRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    mediaType: MediaType;
    url: string;
    comment?: string | null;
    dateTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetAlertInstanceQuery = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListAlertInstancesQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    subjectId: string;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncAlertInstancesQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    subjectId: string;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AlertInstanceByConfAlertIdQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    subjectId: string;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AlertInstanceBySubjectIdQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    subjectId: string;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AlertInstanceByEntityIdQuery = {
  __typename: "ModelAlertInstanceConnection";
  items: Array<{
    __typename: "AlertInstance";
    confAlertId: string;
    subjectId: string;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetPDFAndAlertsTransactionLogQuery = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListPDFAndAlertsTransactionLogsQuery = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<{
    __typename: "PDFAndAlertsTransactionLog";
    subjectId?: string | null;
    startDate: string;
    finishDate?: string | null;
    state: TransactionState;
    source: string;
    eDiaryPhaseInstanceId?: string | null;
    reportInstanceId?: string | null;
    confReportId?: string | null;
    dayInstanceId?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncPDFAndAlertsTransactionLogsQuery = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<{
    __typename: "PDFAndAlertsTransactionLog";
    subjectId?: string | null;
    startDate: string;
    finishDate?: string | null;
    state: TransactionState;
    source: string;
    eDiaryPhaseInstanceId?: string | null;
    reportInstanceId?: string | null;
    confReportId?: string | null;
    dayInstanceId?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type PDFAndAlertsTransactionLogByEDiaryPhaseInstanceIdQuery = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<{
    __typename: "PDFAndAlertsTransactionLog";
    subjectId?: string | null;
    startDate: string;
    finishDate?: string | null;
    state: TransactionState;
    source: string;
    eDiaryPhaseInstanceId?: string | null;
    reportInstanceId?: string | null;
    confReportId?: string | null;
    dayInstanceId?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type PDFAndAlertsTransactionLogByReportInstanceIdQuery = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<{
    __typename: "PDFAndAlertsTransactionLog";
    subjectId?: string | null;
    startDate: string;
    finishDate?: string | null;
    state: TransactionState;
    source: string;
    eDiaryPhaseInstanceId?: string | null;
    reportInstanceId?: string | null;
    confReportId?: string | null;
    dayInstanceId?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type PDFAndAlertsTransactionLogByConfReportIdQuery = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<{
    __typename: "PDFAndAlertsTransactionLog";
    subjectId?: string | null;
    startDate: string;
    finishDate?: string | null;
    state: TransactionState;
    source: string;
    eDiaryPhaseInstanceId?: string | null;
    reportInstanceId?: string | null;
    confReportId?: string | null;
    dayInstanceId?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type PDFAndAlertsTransactionLogByDayInstanceIdQuery = {
  __typename: "ModelPDFAndAlertsTransactionLogConnection";
  items: Array<{
    __typename: "PDFAndAlertsTransactionLog";
    subjectId?: string | null;
    startDate: string;
    finishDate?: string | null;
    state: TransactionState;
    source: string;
    eDiaryPhaseInstanceId?: string | null;
    reportInstanceId?: string | null;
    confReportId?: string | null;
    dayInstanceId?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type OnCreateChatMessageBySiteIdSubscription = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateChatMessageByUserIdSubscription = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateBackupInstanceSubscription = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateBackupInstanceSubscription = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteBackupInstanceSubscription = {
  __typename: "BackupInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  data: string;
  instanceName: EntityName;
  STORAGETYPE?: string | null;
  gps?: string | null;
  appVersion: string;
  userIP?: string | null;
  deviceModel: string;
  _lastUser: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: "TermsAndConditions";
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateRoleSubscription = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateRoleSubscription = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteRoleSubscription = {
  __typename: "role";
  id: string;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfChatUserSubscription = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfChatUserSubscription = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfChatUserSubscription = {
  __typename: "ConfChatUser";
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  isChatActivated: boolean;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateChatMessageSubscription = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateChatMessageSubscription = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteChatMessageSubscription = {
  __typename: "ChatMessage";
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  message: string;
  sender: ChatMessageSender;
  senderAlias: string;
  createdAt: string;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateVerifiedDayInstanceSubscription = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateVerifiedDayInstanceSubscription = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteVerifiedDayInstanceSubscription = {
  __typename: "VerifiedDayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  startDate?: string | null;
  finishDate?: string | null;
  completedDate?: string | null;
  hasMedication?: boolean | null;
  hasMedicationCompleted?: boolean | null;
  hasMedicationComment?: string | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  medicationGivenToComment?: string | null;
  hasMedicalAttention?: boolean | null;
  hasMedicalAttentionCompleted?: boolean | null;
  hasMedicalAttentionComment?: string | null;
  hasOtherSymptoms?: boolean | null;
  hasOtherSymptomsCompleted?: boolean | null;
  hasOtherSymptomsComment?: string | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  } | null> | null;
  symptomOcurrenciesComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCommentConciliationInstanceSubscription = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCommentConciliationInstanceSubscription = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCommentConciliationInstanceSubscription = {
  __typename: "CommentConciliationInstance";
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  comment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateVerifiedSymptomInstanceSubscription = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type OnUpdateVerifiedSymptomInstanceSubscription = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type OnDeleteVerifiedSymptomInstanceSubscription = {
  __typename: "VerifiedSymptomInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId?: string | null;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  whichOtherSymptomComment?: string | null;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string | null> | null;
  medicationsComment?: string | null;
  medicalAttentions?: Array<string | null> | null;
  medicalAttentionsComment?: string | null;
  professionalHealthCare?: YNA | null;
  professionalHealthCareComment?: string | null;
  hospitalAdmission?: YN | null;
  hospitalAdmissionComment?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  } | null> | null;
  intensityQuestionAnswersComment?: string | null;
  rememberNoValuesTaken?: boolean | null;
  rememberNoValuesTakenComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  verifiedSymptomInstanceConfSymptomId?: string | null;
};

export type OnCreateVerifiedSymptomRecordLogSubscription = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateVerifiedSymptomRecordLogSubscription = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteVerifiedSymptomRecordLogSubscription = {
  __typename: "VerifiedSymptomRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedSymptomInstanceId?: string | null;
  verifiedSymptomInstance?: {
    __typename: "VerifiedSymptomInstance";
    subjectId?: string | null;
    verifiedDayInstanceId?: string | null;
    confSymptomId?: string | null;
    symptomInstanceId?: string | null;
    id: string;
    state?: InstanceState | null;
    type?: SymptomType | null;
    symptom?: Symptom | null;
    whichOtherSymptom?: string | null;
    whichOtherSymptomComment?: string | null;
    intensity?: Intensity | null;
    intensityComment?: string | null;
    size?: number | null;
    sizeComment?: string | null;
    rememberStartDate?: boolean | null;
    rememberStartDateComment?: string | null;
    startDate?: string | null;
    startDateComment?: string | null;
    rememberFinishDate?: boolean | null;
    rememberFinishDateComment?: string | null;
    finishDate?: string | null;
    finishDateComment?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string | null> | null;
    medicationsComment?: string | null;
    medicalAttentions?: Array<string | null> | null;
    medicalAttentionsComment?: string | null;
    professionalHealthCare?: YNA | null;
    professionalHealthCareComment?: string | null;
    hospitalAdmission?: YN | null;
    hospitalAdmissionComment?: string | null;
    intensityQuestionAnswersComment?: string | null;
    rememberNoValuesTaken?: boolean | null;
    rememberNoValuesTakenComment?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    verifiedSymptomInstanceConfSymptomId?: string | null;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomRecordLogId?: string | null;
  symptomRecordLog?: {
    __typename: "SymptomRecordLog";
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  intensityComment?: string | null;
  size?: number | null;
  sizeComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateVerifiedMedicationInstanceSubscription = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateVerifiedMedicationInstanceSubscription = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteVerifiedMedicationInstanceSubscription = {
  __typename: "VerifiedMedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicationInstanceId?: string | null;
  medicationInstance?: {
    __typename: "MedicationInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  isOnGoing?: boolean | null;
  isOnGoingComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  name?: string | null;
  nameComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string | null> | null;
  symptomsInstancesComment?: string | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  givenToComment?: string | null;
  dose?: string | null;
  doseComment?: string | null;
  frequency?: string | null;
  frequencyComment?: string | null;
  administrationRoute?: string | null;
  administrationRouteComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateVerifiedMedicalAttentionInstanceSubscription = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateVerifiedMedicalAttentionInstanceSubscription = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteVerifiedMedicalAttentionInstanceSubscription = {
  __typename: "VerifiedMedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  medicalAttentionInstanceId?: string | null;
  medicalAttentionInstance?: {
    __typename: "MedicalAttentionInstance";
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateVerifiedTemperatureRecordLogSubscription = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateVerifiedTemperatureRecordLogSubscription = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteVerifiedTemperatureRecordLogSubscription = {
  __typename: "VerifiedTemperatureRecordLog";
  verifiedDayInstanceId?: string | null;
  verifiedDayInstance?: {
    __typename: "VerifiedDayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    dayInstanceId?: string | null;
    startDate?: string | null;
    finishDate?: string | null;
    completedDate?: string | null;
    hasMedication?: boolean | null;
    hasMedicationCompleted?: boolean | null;
    hasMedicationComment?: string | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    medicationGivenToComment?: string | null;
    hasMedicalAttention?: boolean | null;
    hasMedicalAttentionCompleted?: boolean | null;
    hasMedicalAttentionComment?: string | null;
    hasOtherSymptoms?: boolean | null;
    hasOtherSymptomsCompleted?: boolean | null;
    hasOtherSymptomsComment?: string | null;
    symptomOcurrenciesComment?: string | null;
    state?: InstanceState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  temperatureRecordLogID?: string | null;
  temperatureRecordLog?: {
    __typename: "TemperatureRecordLog";
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  temperature?: number | null;
  temperatureComment?: string | null;
  unit?: TemperatureUnit | null;
  unitComment?: string | null;
  route?: TemperatureRoute | null;
  routeComment?: string | null;
  whichOtherRoute?: string | null;
  whichOtherRouteComment?: string | null;
  taken?: boolean | null;
  takenComment?: string | null;
  takenDate?: string | null;
  takenDateComment?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateProjectSubscription = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateProjectSubscription = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteProjectSubscription = {
  __typename: "Project";
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: "AccessibilityGroup";
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: "ProjectTermsAndConditions";
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfVisitGroupSubscription = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfVisitGroupSubscription = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfVisitGroupSubscription = {
  __typename: "ConfVisitGroup";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSiteSubscription = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSiteSubscription = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSiteSubscription = {
  __typename: "Site";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  timezoneRecipients?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateAuthorizedSiteSubscription = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateAuthorizedSiteSubscription = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteAuthorizedSiteSubscription = {
  __typename: "AuthorizedSite";
  id: string;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfReportSubscription = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfReportSubscription = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfReportSubscription = {
  __typename: "ConfReport";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfReportBySubjectSubscription = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfReportBySubjectSubscription = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfReportBySubjectSubscription = {
  __typename: "ConfReportBySubject";
  id: string;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfEDiarySubscription = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfEDiarySubscription = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfEDiarySubscription = {
  __typename: "ConfEDiary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: "ConfEDiaryWindow";
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfDaySubscription = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfDaySubscription = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfDaySubscription = {
  __typename: "ConfDay";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfFormSubscription = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfFormSubscription = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfFormSubscription = {
  __typename: "ConfForm";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: "ReconciliationConfig";
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowAdditionalQuestionsReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfSymptomSubscription = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfSymptomSubscription = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfSymptomSubscription = {
  __typename: "ConfSymptom";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: "IntensityScale";
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: "IntensityQuestion";
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfFormConfSymptomSubscription = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfFormConfSymptomSubscription = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfFormConfSymptomSubscription = {
  __typename: "ConfFormConfSymptom";
  confFormId: string;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfMedicationSubscription = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfMedicationSubscription = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfMedicationSubscription = {
  __typename: "ConfMedication";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfMedicalAttentionSubscription = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfMedicalAttentionSubscription = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfMedicalAttentionSubscription = {
  __typename: "ConfMedicalAttention";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfTemperatureSubscription = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfTemperatureSubscription = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfTemperatureSubscription = {
  __typename: "ConfTemperature";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfMultimediaSubscription = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfMultimediaSubscription = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfMultimediaSubscription = {
  __typename: "ConfMultimedia";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfOtherSymptomsSubscription = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfOtherSymptomsSubscription = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfOtherSymptomsSubscription = {
  __typename: "ConfOtherSymptoms";
  confEdiaryId: string;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: "ConfForm";
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfAlertSubscription = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfAlertSubscription = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfAlertSubscription = {
  __typename: "ConfAlert";
  confReportId?: string | null;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: "ConfTemperature";
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: "SiteRecipients";
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: "AlertRule";
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: "TriggeredVisit";
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfDictionarySubscription = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfDictionarySubscription = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfDictionarySubscription = {
  __typename: "ConfDictionary";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfInformedConsentSubscription = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfInformedConsentSubscription = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfInformedConsentSubscription = {
  __typename: "ConfInformedConsent";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: "InformedConsentSite";
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfICSectionSubscription = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfICSectionSubscription = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfICSectionSubscription = {
  __typename: "ConfICSection";
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: "ComplementaryQuestion";
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfICQuestionSubscription = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfICQuestionSubscription = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfICQuestionSubscription = {
  __typename: "ConfICQuestion";
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConfICAnswerSubscription = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConfICAnswerSubscription = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConfICAnswerSubscription = {
  __typename: "ConfICAnswer";
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateInformedConsentInstanceSubscription = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateInformedConsentInstanceSubscription = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteInformedConsentInstanceSubscription = {
  __typename: "InformedConsentInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: "ConfInformedConsent";
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateConsentedUserSubscription = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateConsentedUserSubscription = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteConsentedUserSubscription = {
  __typename: "ConsentedUser";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    lastTimezone?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: "StateChange";
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  requiresSendingEmail?: boolean | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateICSectionInstanceSubscription = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateICSectionInstanceSubscription = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteICSectionInstanceSubscription = {
  __typename: "ICSectionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: "ComplementaryQuestionAnswer";
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateICQuestionInstanceSubscription = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateICQuestionInstanceSubscription = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteICQuestionInstanceSubscription = {
  __typename: "ICQuestionInstance";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: "InformedConsentInstance";
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: "ConfICQuestion";
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: "ConsentedUser";
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    requiresSendingEmail?: boolean | null;
    signature?: string | null;
    _lastUser?: string | null;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: "ConfICSection";
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    isStudyRolRequired?: boolean | null;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSubjectSubscription = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSubjectSubscription = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSubjectSubscription = {
  __typename: "Subject";
  projectId: string;
  project?: {
    __typename: "Project";
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: "ScheduledPhase";
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateReportInstanceSubscription = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateReportInstanceSubscription = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteReportInstanceSubscription = {
  __typename: "ReportInstance";
  confReportId: string;
  confReport?: {
    __typename: "ConfReport";
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: {
    __typename: "UserEntityType";
    username: string;
    userType: ReportAvailableUserType;
  } | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSignatureInstanceSubscription = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSignatureInstanceSubscription = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSignatureInstanceSubscription = {
  __typename: "SignatureInstance";
  subjectId?: string | null;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateEDiaryInstanceSubscription = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateEDiaryInstanceSubscription = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteEDiaryInstanceSubscription = {
  __typename: "EDiaryInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEDiaryId: string;
  confEDiary?: {
    __typename: "ConfEDiary";
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateEDiaryPhaseInstanceSubscription = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateEDiaryPhaseInstanceSubscription = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteEDiaryPhaseInstanceSubscription = {
  __typename: "EDiaryPhaseInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: {
    __typename: "EDiaryInstance";
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<{
    __typename: "TP2File";
    uploadDate: string;
    fileUrl: string;
  }> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: {
    __typename: "ConciliationLock";
    user: string;
    date: string;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateDayInstanceSubscription = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateDayInstanceSubscription = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteDayInstanceSubscription = {
  __typename: "DayInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: "EDiaryPhaseInstance";
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: "ConfDay";
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: "Site";
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: "SymptomOccurrency";
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  timezonesHistory?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSymptomRecordLogSubscription = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSymptomRecordLogSubscription = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSymptomRecordLogSubscription = {
  __typename: "SymptomRecordLog";
  dayInstanceId: string;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  symptomInstanceId: string;
  symptomInstance?: {
    __typename: "SymptomInstance";
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    symptomInstanceConfSymptomId?: string | null;
  } | null;
  state?: InstanceState | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  intensity?: Intensity | null;
  size?: number | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSymptomInstanceSubscription = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type OnUpdateSymptomInstanceSubscription = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type OnDeleteSymptomInstanceSubscription = {
  __typename: "SymptomInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: "ConfSymptom";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: "IntensityQuestionAnswer";
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  symptomInstanceConfSymptomId?: string | null;
};

export type OnCreateMedicationInstanceSubscription = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateMedicationInstanceSubscription = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteMedicationInstanceSubscription = {
  __typename: "MedicationInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateMedicalAttentionInstanceSubscription = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateMedicalAttentionInstanceSubscription = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteMedicalAttentionInstanceSubscription = {
  __typename: "MedicalAttentionInstance";
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  type?: MedicalAttentionType | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  wasHospitalized?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateTemperatureRecordLogSubscription = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateTemperatureRecordLogSubscription = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteTemperatureRecordLogSubscription = {
  __typename: "TemperatureRecordLog";
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  temperature?: number | null;
  temperatureUnit?: TemperatureUnit | null;
  temperatureRoute?: TemperatureRoute | null;
  temperatureWhichOtherRoute?: string | null;
  date?: string | null;
  temperatureTaken?: boolean | null;
  temperatureTakenDate?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateMediaRecordLogSubscription = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateMediaRecordLogSubscription = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteMediaRecordLogSubscription = {
  __typename: "MediaRecordLog";
  dayInstanceId?: string | null;
  reportInstanceId?: string | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  mediaType: MediaType;
  url: string;
  comment?: string | null;
  dateTime?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateAlertInstanceSubscription = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateAlertInstanceSubscription = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteAlertInstanceSubscription = {
  __typename: "AlertInstance";
  confAlertId: string;
  confAlert?: {
    __typename: "ConfAlert";
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId: string;
  subject?: {
    __typename: "Subject";
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  confAlert_data: string;
  entityId: string;
  entityName: string;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreatePDFAndAlertsTransactionLogSubscription = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdatePDFAndAlertsTransactionLogSubscription = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeletePDFAndAlertsTransactionLogSubscription = {
  __typename: "PDFAndAlertsTransactionLog";
  subjectId?: string | null;
  startDate: string;
  finishDate?: string | null;
  state: TransactionState;
  source: string;
  eDiaryPhaseInstanceId?: string | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: "ReportInstance";
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId?: string | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: "DayInstance";
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  _lastUser: string;
  _changeReason?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async UpdateICSectionInstanceCustom(
    input: UpdateICSectionInstanceInput,
    condition?: ModelICSectionInstanceConditionInput
  ): Promise<UpdateICSectionInstanceCustomMutation> {
    const statement = `mutation UpdateICSectionInstanceCustom($input: UpdateICSectionInstanceInput!, $condition: ModelICSectionInstanceConditionInput) {
        updateICSectionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            signature
            createdAt
            updatedAt
            _lastUser
            _changeReason
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            question {
              __typename
              id
              type
              question
              options
            }
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateICSectionInstanceCustomMutation>(
      response.data.updateICSectionInstance
    );
  }
  async CustomEDiaryPhaseInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryPhaseInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomEDiaryPhaseInstanceBySubjectIdQuery> {
    const statement = `query CustomEDiaryPhaseInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryPhaseInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            subject {
              __typename
              projectId
              siteId
              id
              subjectNumber
              group
              state
              tag
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            eDiaryInstanceId
            eDiaryInstance {
              __typename
              subjectId
              confEDiaryId
              siteId
              id
              state
              confEdiaryVersion
              comment
              alerts
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            phase
            pdfFiles {
              __typename
              uploadDate
              fileUrl
            }
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            locked {
              __typename
              user
              date
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomEDiaryPhaseInstanceBySubjectIdQuery>(
      response.data.eDiaryPhaseInstanceBySubjectId
    );
  }
  async CustomEDiaryInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomEDiaryInstanceBySubjectIdQuery> {
    const statement = `query CustomEDiaryInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            subject {
              __typename
              projectId
            }
            pdfFiles {
              __typename
              fileUrl
              uploadDate
            }
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomEDiaryInstanceBySubjectIdQuery>(
      response.data.eDiaryInstanceBySubjectId
    );
  }
  async CustomReportInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomReportInstanceBySubjectIdQuery> {
    const statement = `query CustomReportInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confReport {
              __typename
              projectId
              id
              groups
              phases
              instancePerDay
              reportTitle
              showHelp
              reportHelp
              reportIcon
              symptomPageLayout
              programationType
              plannedDayList
              isDisplayDependentOnTheEDiary
              forms
              isControlledBySite
              allowReportBackDating
              enableLocalNotifications
              futureNotifications
              alertType
              alertWeeklyDays
              alertInBetweenDaysFrequency
              alertDuringSameDayFrequency
              alertStartTime
              alertEndTime
              jsonForm
              isSignatureRequired
              isGPSRequired
              isUserIPRequired
              isMediaRequired
              isDeviceDataRequired
              mediaTypes
              isAssociatedToVisits
              areSymptomsRequired
              areAlertsRequired
              isDeviceDataManagementRequired
              availableUsers
              state
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subjectId
            subject {
              __typename
              projectId
              siteId
              id
              subjectNumber
              group
              state
              tag
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            plannedDate
            completeDate
            json
            score
            reportedBy {
              __typename
              username
              userType
            }
            timezonesHistory
            gps
            appVersion
            userIP
            reportConfVersion
            symptomOcurrencies {
              __typename
              confSymptomId
              symptomInstanceId
              symptomRecordLogId
              occurrency
              order
            }
            isAlertChecked
            isPDFGenerated
            pdfFiles {
              __typename
              uploadDate
              fileUrl
            }
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomReportInstanceBySubjectIdQuery>(
      response.data.reportInstanceBySubjectId
    );
  }
  async CustomGetSymptomInstance(
    id: string
  ): Promise<CustomGetSymptomInstanceQuery> {
    const statement = `query CustomGetSymptomInstance($id: ID!) {
        getSymptomInstance(id: $id) {
          __typename
          subjectId
          reportInstanceId
          dayInstanceId
          confSymptomId
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
            question {
              __typename
              id
              intensity
              order
              question
            }
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomGetSymptomInstanceQuery>response.data.getSymptomInstance;
  }
  async CustomEDiaryInstanceByConfEDiaryId(
    confEDiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomEDiaryInstanceByConfEDiaryIdQuery> {
    const statement = `query CustomEDiaryInstanceByConfEDiaryId($confEDiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryInstanceByConfEDiaryId(confEDiaryId: $confEDiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            pdfFiles {
              __typename
              fileUrl
              uploadDate
            }
            state
            siteId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEDiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomEDiaryInstanceByConfEDiaryIdQuery>(
      response.data.eDiaryInstanceByConfEDiaryId
    );
  }
  async ConfDayByConfEdiaryCustom(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfDayFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfDayByConfEdiaryCustomQuery> {
    const statement = `query ConfDayByConfEdiaryCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfDayFilterInput, $limit: Int, $nextToken: String) {
        confDayByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            confForm {
              __typename
              id
              name
              isReconciliationRequired
            }
            id
            dayName
            order
            startDay
            endDay
            trackSymptomOcurrencies
            hidePDFDayColumn
            groups
            phases
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfDayByConfEdiaryCustomQuery>response.data.confDayByConfEdiary;
  }
  async ConfSymptomByConfEdiaryIdCustom(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfSymptomByConfEdiaryIdCustomQuery> {
    const statement = `query ConfSymptomByConfEdiaryIdCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        confSymptomByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            isIntensityRequired
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isFinishDateRequired
            isStartDateRequired
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            showNoValuesTaken
            noValuesTakenLabel
            isIntensityQuestionsRequired
            intensityQuestions {
              __typename
              id
              order
              question
              intensity
            }
            noneIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lowIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            mediumIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            highIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lifeThreateningScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfSymptomByConfEdiaryIdCustomQuery>(
      response.data.confSymptomByConfEdiaryId
    );
  }
  async listUsersSubjectsCustom(
    idSubject?: string,
    limit?: number,
    nextToken?: string
  ): Promise<listUsersSubjectsCustomQuery> {
    const statement = `query listUsersSubjectsCustom($idSubject: String, $limit: Int, $nextToken: String) {
        listUsers(filter: {subjects: {contains: $idSubject}}, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            state
            phoneNumber
            subjects
            sites
            projects
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (idSubject) {
      gqlAPIServiceArguments.idSubject = idSubject;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <listUsersSubjectsCustomQuery>response.data.listUsers;
  }
  async ListDayInstancesCustom(
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDayInstancesCustomQuery> {
    const statement = `query ListDayInstancesCustom($filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        listDayInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            subject {
              __typename
              subjectNumber
              id
              state
              projectId
              siteId
              currentScheduledPhase {
                __typename
                date
                phase
                state
              }
              _deleted
            }
            eDiaryPhaseInstanceId
            eDiaryPhaseInstance {
              __typename
              phase
              state
            }
            confDayId
            confDay {
              __typename
              dayName
            }
            completedDate
            siteId
            id
            state
            startDate
            finishDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            hasMedicalAttention
            hasOtherSymptoms
            _lastUser
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDayInstancesCustomQuery>response.data.listDayInstances;
  }
  async DayInstanceByEDiaryPhaseInstanceCustom(
    eDiaryPhaseInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DayInstanceByEDiaryPhaseInstanceCustomQuery> {
    const statement = `query DayInstanceByEDiaryPhaseInstanceCustom($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        dayInstanceByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            subject {
              __typename
              subjectNumber
              id
              state
              projectId
              siteId
              _deleted
            }
            eDiaryPhaseInstanceId
            confDayId
            confDay {
              __typename
              dayName
              order
              confFormId
              startDay
              endDay
              hidePDFDayColumn
              _deleted
              confForm {
                __typename
                confEdiaryId
                projectId
                id
                name
                isTemperatureRequired
                isMedicationRequired
                isMedicalAttentionRequired
                isReconciliationRequired
                sectionsToReconcile
                isMediaRequired
                areCommentsRequired
                isOtherForm
                mediaTypes
                areOtherSymptomsRequired
                symptomPageLayout
                showHelp
                formHelp
                _lastUser
                _changeReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            symptomOcurrencies {
              __typename
              confSymptomId
              symptomInstanceId
              symptomRecordLogId
              occurrency
              order
            }
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DayInstanceByEDiaryPhaseInstanceCustomQuery>(
      response.data.dayInstanceByEDiaryPhaseInstance
    );
  }
  async ReportInstanceByConfReportIdCustom(
    confReportId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReportInstanceByConfReportIdCustomQuery> {
    const statement = `query ReportInstanceByConfReportIdCustom($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            subject {
              __typename
              subjectNumber
              id
              siteId
              state
              projectId
              currentScheduledPhase {
                __typename
                date
                phase
                state
              }
            }
            siteId
            id
            state
            plannedDate
            completeDate
            json
            gps
            appVersion
            userIP
            reportConfVersion
            _lastUser
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportInstanceByConfReportIdCustomQuery>(
      response.data.reportInstanceByConfReportId
    );
  }
  async ConfSymptomByConfReportIdCustom(
    confReportId: string
  ): Promise<ConfSymptomByConfReportIdCustomQuery> {
    const statement = `query ConfSymptomByConfReportIdCustom($confReportId: ID!) {
        confSymptomByConfReportId(confReportId: $confReportId) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensitySizeLabel
            isFinishDateRequired
            isStartDateRequired
            isIntensityRequired
            intensityType
            showNoValuesTaken
            noValuesTakenLabel
            isIntensityQuestionsRequired
            intensityQuestions {
              __typename
              id
              order
              question
              intensity
            }
            highIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            mediumIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lowIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            noneIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lifeThreateningScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfSymptomByConfReportIdCustomQuery>(
      response.data.confSymptomByConfReportId
    );
  }
  async GetSymptomRecordLogsBySubjectIdCustom(
    subjectId: string
  ): Promise<GetSymptomRecordLogsBySubjectIdCustomQuery> {
    const statement = `query GetSymptomRecordLogsBySubjectIdCustom($subjectId: ID!) {
        symptomRecordLogBySubjectId(subjectId: $subjectId) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSymptomRecordLogsBySubjectIdCustomQuery>(
      response.data.symptomRecordLogBySubjectId
    );
  }
  async VerifiedDayInstanceByDayInstanceIdIdCustom(
    dayInstanceId: string
  ): Promise<VerifiedDayInstanceByDayInstanceIdIdCustomQuery> {
    const statement = `query VerifiedDayInstanceByDayInstanceIdIdCustom($dayInstanceId: ID!) {
        verifiedDayInstancesByDayInstanceId(dayInstanceId: $dayInstanceId) {
          __typename
          items {
            __typename
            symptomOcurrencies {
              __typename
              confSymptomId
              occurrency
              order
              symptomInstanceId
              symptomRecordLogId
            }
            symptomOcurrenciesComment
            subjectId
            state
            startDate
            siteId
            medicationGivenToComment
            medicationGivenTo
            id
            hasOtherSymptomsComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            hasMedicalAttentionComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            finishDate
            eDiaryPhaseInstanceId
            dayInstanceId
            createdAt
            confDayId
            _version
            _lastUser
            _deleted
            _changeReason
            completedDate
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstanceByDayInstanceIdIdCustomQuery>(
      response.data.verifiedDayInstancesByDayInstanceId
    );
  }
  async VerifiedDayInstanceByEdiaryPhaseIdCustom(
    eDiaryPhaseInstanceId: string
  ): Promise<VerifiedDayInstanceByEdiaryPhaseIdCustomQuery> {
    const statement = `query VerifiedDayInstanceByEdiaryPhaseIdCustom($eDiaryPhaseInstanceId: ID!) {
        verifiedDayInstancesByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId) {
          __typename
          items {
            __typename
            symptomOcurrencies {
              __typename
              confSymptomId
              occurrency
              order
              symptomInstanceId
              symptomRecordLogId
            }
            symptomOcurrenciesComment
            subjectId
            state
            startDate
            siteId
            medicationGivenToComment
            medicationGivenTo
            id
            hasOtherSymptomsComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            hasMedicalAttentionComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            finishDate
            eDiaryPhaseInstanceId
            dayInstanceId
            createdAt
            confDayId
            _version
            _lastUser
            _deleted
            _changeReason
            completedDate
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstanceByEdiaryPhaseIdCustomQuery>(
      response.data.verifiedDayInstancesByEDiaryPhaseInstance
    );
  }
  async ConfMedicationByConfEdiaryIdCustom(
    confEdiaryId: string
  ): Promise<ConfMedicationByConfEdiaryIdCustomQuery> {
    const statement = `query ConfMedicationByConfEdiaryIdCustom($confEdiaryId: ID!) {
        confMedicationByConfEdiary(confEdiaryId: $confEdiaryId) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMedicationByConfEdiaryIdCustomQuery>(
      response.data.confMedicationByConfEdiary
    );
  }
  async VerifiedSymptomInstanceBySymptomInstanceIdCustom(
    symptomInstanceId: string
  ): Promise<VerifiedSymptomInstanceBySymptomInstanceIdCustomQuery> {
    const statement = `query VerifiedSymptomInstanceBySymptomInstanceIdCustom($symptomInstanceId: ID!) {
        VerifiedSymptomInstanceBySymptomInstanceId(symptomInstanceId: $symptomInstanceId) {
          __typename
          items {
            __typename
            confSymptomId
            _version
            _lastUser
            _lastChangedAt
            _deleted
            _changeReason
            createdAt
            finishDate
            finishDateComment
            hospitalAdmission
            hospitalAdmissionComment
            id
            state
            intensity
            intensityQuestionAnswers {
              __typename
              answer
              question {
                __typename
                id
                intensity
                order
                question
              }
            }
            intensityQuestionAnswersComment
            isOtherSymptom
            medicalAttentions
            medicalAttentionsComment
            medications
            medicationsComment
            professionalHealthCare
            professionalHealthCareComment
            rememberFinishDate
            rememberFinishDateComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            rememberStartDate
            rememberStartDateComment
            size
            sizeComment
            startDate
            startDateComment
            subjectId
            symptom
            symptomInstanceId
            type
            verifiedDayInstanceId
            verifiedSymptomInstanceConfSymptomId
            whichOtherSymptom
            whichOtherSymptomComment
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      symptomInstanceId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomInstanceBySymptomInstanceIdCustomQuery>(
      response.data.VerifiedSymptomInstanceBySymptomInstanceId
    );
  }
  async ConfOtherSymptomsByConfEdiaryCustom(
    confEdiaryId: string
  ): Promise<ConfOtherSymptomsByConfEdiaryCustomQuery> {
    const statement = `query ConfOtherSymptomsByConfEdiaryCustom($confEdiaryId: ID!) {
        confOtherSymptomsByConfEdiary(confEdiaryId: $confEdiaryId) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfOtherSymptomsByConfEdiaryCustomQuery>(
      response.data.confOtherSymptomsByConfEdiary
    );
  }
  async VerifiedSymptomInstanceBySubjectIdCustom(
    subjectId: string
  ): Promise<VerifiedSymptomInstanceBySubjectIdCustomQuery> {
    const statement = `query VerifiedSymptomInstanceBySubjectIdCustom($subjectId: ID!) {
        verifiedSymptomInstanceBySubjectId(subjectId: $subjectId) {
          __typename
          items {
            __typename
            confSymptomId
            _version
            _lastUser
            _lastChangedAt
            _deleted
            _changeReason
            createdAt
            finishDate
            finishDateComment
            hospitalAdmission
            hospitalAdmissionComment
            id
            state
            intensity
            intensityComment
            intensityQuestionAnswers {
              __typename
              answer
              question {
                __typename
                id
                intensity
                order
                question
              }
            }
            intensityQuestionAnswersComment
            isOtherSymptom
            medicalAttentions
            medicalAttentionsComment
            medications
            medicationsComment
            professionalHealthCare
            professionalHealthCareComment
            rememberFinishDate
            rememberFinishDateComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            rememberStartDate
            rememberStartDateComment
            size
            sizeComment
            startDate
            startDateComment
            subjectId
            symptom
            symptomInstanceId
            type
            verifiedDayInstanceId
            verifiedSymptomInstanceConfSymptomId
            whichOtherSymptom
            whichOtherSymptomComment
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomInstanceBySubjectIdCustomQuery>(
      response.data.verifiedSymptomInstanceBySubjectId
    );
  }
  async SymptomInstanceByDayInstanceIdCustom(
    dayInstanceId: string
  ): Promise<SymptomInstanceByDayInstanceIdCustomQuery> {
    const statement = `query SymptomInstanceByDayInstanceIdCustom($dayInstanceId: ID!) {
        SymptomInstanceByDayInstanceId(dayInstanceId: $dayInstanceId) {
          __typename
          items {
            __typename
            _version
            _lastUser
            _lastChangedAt
            _deviceSOVersion
            _deviceModel
            _deleted
            _changeReason
            confSymptom {
              __typename
              _changeReason
              _deleted
              _lastChangedAt
              _lastUser
              _version
              confEdiaryId
              confReportId
              createdAt
              decimalPlaces
              highIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              id
              injectionSide
              injectionSite
              intensityType
              intensitySizeLabel
              isFinishDateRequired
              isIntensityQuestionsRequired
              isIntensityRequired
              isStartDateRequired
              intensityQuestions {
                __typename
                id
                intensity
                order
                question
              }
              lifeThreateningScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              lowIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              mediumIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              noValuesTakenLabel
              noneIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                startValue
                label
              }
              order
              projectId
              showHelp
              showNoValuesTaken
              symptom
              symptomHelp
              updatedAt
              type
              symptomLabel
              symptomIcon
            }
            confSymptomId
            createdAt
            dayInstanceId
            finishDate
            id
            hospitalAdmission
            intensity
            isOtherSymptom
            medicalAttentions
            medications
            order
            professionalHealthCare
            rememberFinishDate
            intensityQuestionAnswers {
              __typename
              answer
              question {
                __typename
                id
                intensity
                order
                question
              }
            }
            rememberNoValuesTaken
            rememberStartDate
            reportInstanceId
            size
            startDate
            state
            subjectId
            symptom
            symptomInstanceConfSymptomId
            type
            updatedAt
            whichOtherSymptom
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomInstanceByDayInstanceIdCustomQuery>(
      response.data.SymptomInstanceByDayInstanceId
    );
  }
  async SymptomInstanceBySubjectIdCustom(
    subjectId: string
  ): Promise<SymptomInstanceBySubjectIdCustomQuery> {
    const statement = `query SymptomInstanceBySubjectIdCustom($subjectId: ID!) {
        SymptomInstanceBySubjectId(subjectId: $subjectId) {
          __typename
          items {
            __typename
            _version
            _lastUser
            _lastChangedAt
            _deviceSOVersion
            _deviceModel
            _deleted
            _changeReason
            confSymptom {
              __typename
              _changeReason
              _deleted
              _lastChangedAt
              _lastUser
              _version
              confEdiaryId
              confReportId
              createdAt
              decimalPlaces
              highIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              id
              injectionSide
              injectionSite
              intensityType
              intensitySizeLabel
              isFinishDateRequired
              isIntensityQuestionsRequired
              isIntensityRequired
              isStartDateRequired
              intensityQuestions {
                __typename
                id
                intensity
                order
                question
              }
              lifeThreateningScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              lowIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              mediumIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                label
                startValue
              }
              noValuesTakenLabel
              noneIntensityScaleOptions {
                __typename
                active
                endValue
                helpText
                startValue
                label
              }
              order
              projectId
              showHelp
              showNoValuesTaken
              symptom
              symptomHelp
              updatedAt
              type
              symptomLabel
              symptomIcon
            }
            confSymptomId
            createdAt
            dayInstanceId
            finishDate
            id
            hospitalAdmission
            intensity
            isOtherSymptom
            medicalAttentions
            medications
            order
            professionalHealthCare
            rememberFinishDate
            intensityQuestionAnswers {
              __typename
              answer
              question {
                __typename
                id
                intensity
                order
                question
              }
            }
            rememberNoValuesTaken
            rememberStartDate
            reportInstanceId
            size
            startDate
            state
            subjectId
            symptom
            symptomInstanceConfSymptomId
            type
            updatedAt
            whichOtherSymptom
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomInstanceBySubjectIdCustomQuery>(
      response.data.SymptomInstanceBySubjectId
    );
  }
  async GetConfSymptomByConfEdiaryIdCustom(
    confEdiaryId: string
  ): Promise<GetConfSymptomByConfEdiaryIdCustomQuery> {
    const statement = `query GetConfSymptomByConfEdiaryIdCustom($confEdiaryId: ID!) {
        confSymptomByConfEdiaryId(confEdiaryId: $confEdiaryId) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            intensityType
            intensitySizeLabel
            noneIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lowIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            mediumIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            highIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lifeThreateningScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            intensityQuestions {
              __typename
              id
              order
              question
              intensity
            }
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfSymptomByConfEdiaryIdCustomQuery>(
      response.data.confSymptomByConfEdiaryId
    );
  }
  async ConfFormConfSymptomsByConfSymptomIdCustom(
    confSymptomId: string
  ): Promise<ConfFormConfSymptomsByConfSymptomIdCustomQuery> {
    const statement = `query ConfFormConfSymptomsByConfSymptomIdCustom($confSymptomId: ID!) {
        listConfFormConfSymptoms(filter: {confSymptomId: {eq: $confSymptomId}}) {
          __typename
          items {
            __typename
            id
            confFormId
            confSymptomId
            _version
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confSymptomId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfFormConfSymptomsByConfSymptomIdCustomQuery>(
      response.data.listConfFormConfSymptoms
    );
  }
  async ListAlertInstancesCustom(
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAlertInstancesCustomQuery> {
    const statement = `query ListAlertInstancesCustom($filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        listAlertInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            confAlert {
              __typename
              confReportId
              confEdiaryId
              confSymptomId
              confTemperatureId
              id
              alertName
              recipients
              type
              mailSubject
              mailBody
              SMSBody
              alertRules {
                __typename
                ruleId
                alertRuleType
                expectedValue
              }
              _lastUser
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            id
            confAlert_data
            entityId
            entityName
            subjectId
            state
            revisionState
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAlertInstancesCustomQuery>response.data.listAlertInstances;
  }
  async ConfAlertByConfEdiaryIdCustom(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfAlertByConfEdiaryIdCustomQuery> {
    const statement = `query ConfAlertByConfEdiaryIdCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            state
            alertName
            alertRules {
              __typename
              ruleId
              alertRuleType
              expectedValue
            }
            visitList {
              __typename
              order
              visitName
            }
            suspendVisitList {
              __typename
              order
              visitName
            }
            siteRecipients {
              __typename
              recipients
              siteId
            }
            triggerVisitCompletion
            triggerEDiarySuspension
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfAlertByConfEdiaryIdCustomQuery>(
      response.data.confAlertByConfEdiaryId
    );
  }
  async ConfAlertByConfReportIdCustom(
    confReportId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfAlertByConfReportIdCustomQuery> {
    const statement = `query ConfAlertByConfReportIdCustom($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            state
            alertName
            alertRules {
              __typename
              ruleId
              alertRuleType
              expectedValue
            }
            visitList {
              __typename
              order
              visitName
            }
            siteRecipients {
              __typename
              recipients
              siteId
            }
            triggerVisitCompletion
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfAlertByConfReportIdCustomQuery>(
      response.data.confAlertByConfReportId
    );
  }
  async AuthorizedSitesBySiteIdCustom(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuthorizedSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AuthorizedSitesBySiteIdCustomQuery> {
    const statement = `query AuthorizedSitesBySiteIdCustom($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String) {
        AuthorizedSitesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            user {
              __typename
              id
              login
              name
              email
              role
              state
              phoneNumber
              subjects
              sites
              projects
            }
            siteId
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuthorizedSitesBySiteIdCustomQuery>(
      response.data.AuthorizedSitesBySiteId
    );
  }
  async getConfReportBySubjectCustom(
    subjectId: string,
    confReportId: string
  ): Promise<getConfReportBySubjectCustomQuery> {
    const statement = `query getConfReportBySubjectCustom($subjectId: ID!, $confReportId: ID!) {
        listConfReportBySubjects(filter: {subjectId: {eq: $subjectId}, confReportId: {eq: $confReportId}}) {
          __typename
          items {
            __typename
            id
            subjectId
            confReportId
            isEnabled
            _deleted
            _version
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId,
      confReportId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <getConfReportBySubjectCustomQuery>(
      response.data.listConfReportBySubjects
    );
  }
  async SubjectByProjectIdCustom(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubjectByProjectIdCustomQuery> {
    const statement = `query SubjectByProjectIdCustom($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            _lastUser
            _changeReason
            _version
            _deleted
            _lastChangedAt
            site {
              __typename
              name
            }
            currentScheduledPhase {
              __typename
              date
              phase
            }
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubjectByProjectIdCustomQuery>response.data.subjectByProjectId;
  }
  async SubjectSearchByProjectIdCustom(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubjectSearchByProjectIdCustomQuery> {
    const statement = `query SubjectSearchByProjectIdCustom($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            subjectNumber
            state
            _deleted
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubjectSearchByProjectIdCustomQuery>(
      response.data.subjectByProjectId
    );
  }
  async TemperatureRecordLogBySubjectIdCustom(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TemperatureRecordLogBySubjectIdCustomQuery> {
    const statement = `query TemperatureRecordLogBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        temperatureRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            dayInstance {
              __typename
              state
              startDate
              finishDate
              completedDate
              confDay {
                __typename
                confFormId
                dayName
                order
              }
              eDiaryPhaseInstance {
                __typename
                phase
              }
            }
            reportInstanceId
            subjectId
            id
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            state
            _lastUser
            _changeReason
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TemperatureRecordLogBySubjectIdCustomQuery>(
      response.data.temperatureRecordLogBySubjectId
    );
  }
  async MedicalAttentionInstanceBySubjectIdCustom(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MedicalAttentionInstanceBySubjectIdCustomQuery> {
    const statement = `query MedicalAttentionInstanceBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicalAttentionInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            dayInstance {
              __typename
              confDay {
                __typename
                confFormId
                dayName
              }
            }
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MedicalAttentionInstanceBySubjectIdCustomQuery>(
      response.data.medicalAttentionInstanceBySubjectId
    );
  }
  async UserByLoginCustom(login: string): Promise<UserByLoginCustomQuery> {
    const statement = `query UserByLoginCustom($login: String!) {
        userByLogin(login: $login) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            termsAndConditions {
              __typename
              termsAndConditionsVersion
              policiesVersion
              acceptanceDate
            }
            subjects
            sites
            projects
            appVersion
            permissions
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      login
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByLoginCustomQuery>response.data.userByLogin;
  }
  async MedicationInstanceBySubjectIdCustom(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MedicationInstanceBySubjectIdCustomQuery> {
    const statement = `query MedicationInstanceBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicationInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            dayInstance {
              __typename
              confDay {
                __typename
                confFormId
                dayName
              }
            }
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MedicationInstanceBySubjectIdCustomQuery>(
      response.data.medicationInstanceBySubjectId
    );
  }
  async InformedConsentByConsenterUserCustom(
    consenterUserId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InformedConsentByConsenterUserCustomQuery> {
    const statement = `query InformedConsentByConsenterUserCustom($consenterUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        informedConsentByConsenterUser(consenterUserId: $consenterUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            ConfInformedConsent {
              __typename
              id
              name
              state
              sites {
                __typename
                site
                recipients
                totalSubjects
              }
              _deleted
            }
            consenterUserId
            subjectId
            id
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            pdfFiles {
              __typename
              fileUrl
              uploadDate
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consenterUserId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InformedConsentByConsenterUserCustomQuery>(
      response.data.informedConsentByConsenterUser
    );
  }
  async VerifiedTemperatureBySubjectIdCustom(
    subjectId: string
  ): Promise<VerifiedTemperatureBySubjectIdCustomQuery> {
    const statement = `query VerifiedTemperatureBySubjectIdCustom($subjectId: ID!) {
        verifiedTemperatureRecordLogBySubjectId(subjectId: $subjectId) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            subjectId
            temperatureRecordLogID
            id
            temperature
            temperatureComment
            unit
            unitComment
            route
            routeComment
            whichOtherRoute
            whichOtherRouteComment
            taken
            takenComment
            takenDate
            takenDateComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedDayInstance {
              __typename
              confDay {
                __typename
                order
                dayName
                confFormId
                confEdiaryId
              }
              dayInstance {
                __typename
                id
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedTemperatureBySubjectIdCustomQuery>(
      response.data.verifiedTemperatureRecordLogBySubjectId
    );
  }
  async ConsentedUserByUserIdCustom(
    userId: string
  ): Promise<ConsentedUserByUserIdCustomQuery> {
    const statement = `query ConsentedUserByUserIdCustom($userId: ID!) {
        ConsentedUserByUserId(userId: $userId) {
          __typename
          items {
            __typename
            informedConsentInstance {
              __typename
              projectId
              siteId
              confInformedConsentId
              ConfInformedConsent {
                __typename
                projectId
                id
                name
                icVersion
                state
                sites {
                  __typename
                  site
                  recipients
                  totalSubjects
                }
                type
                quizPageLayout
                approvalSealFileUrl
                isMultipleSignatureRequired
                minRequiredSignatures
                maxRequiredSignatures
                _lastUser
                _changeReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              consenterUserId
              subjectId
              id
              consentedIdentificator
              consentedName
              consenterName
              consenterIdentification
              consenterSignature
              consenterRole
              state
              stateChanges {
                __typename
                state
                date
              }
              pdfFiles {
                __typename
                uploadDate
                fileUrl
              }
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            identification
            id
            _version
            _deleted
            _lastChangedAt
            createdAt
            informedConsentInstanceId
            kinship
            name
            projectId
            signature
            state
            stateChanges {
              __typename
              date
              state
            }
            requiresSendingEmail
            siteId
            updatedAt
            userId
            _lastUser
            _changeReason
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConsentedUserByUserIdCustomQuery>(
      response.data.ConsentedUserByUserId
    );
  }
  async ConfEdiaryByProjectIdCustom(
    projectId: string
  ): Promise<ConfEdiaryByProjectIdCustomQuery> {
    const statement = `query ConfEdiaryByProjectIdCustom($projectId: ID!) {
        confEdiaryByProject(projectId: $projectId) {
          __typename
          items {
            __typename
            projectId
            windows {
              __typename
              name
              phases
              daysAfterVisit
              totalWindowDays
            }
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfEdiaryByProjectIdCustomQuery>response.data.confEdiaryByProject;
  }
  async ConfSymptomByConfFormIdCustom(
    confFormId: string
  ): Promise<ConfSymptomByConfFormIdCustomQuery> {
    const statement = `query ConfSymptomByConfFormIdCustom($confFormId: ID!) {
        confSymptomByConfFormId(confFormId: $confFormId) {
          __typename
          items {
            __typename
            confFormId
            _version
            _lastUser
            _lastChangedAt
            _deleted
            _changeReason
            projectId
            id
            createdAt
            confSymptomId
            confSymptom {
              __typename
              confReportId
              confEdiaryId
              projectId
              id
              type
              symptom
              order
              injectionSite
              injectionSide
              showHelp
              symptomHelp
              symptomLabel
              symptomIcon
              decimalPlaces
              minSize
              maxSize
              intensityType
              intensitySizeLabel
              noneIntensityScaleOptions {
                __typename
                active
                startValue
                endValue
                helpText
                label
              }
              lowIntensityScaleOptions {
                __typename
                active
                startValue
                endValue
                helpText
                label
              }
              mediumIntensityScaleOptions {
                __typename
                active
                startValue
                endValue
                helpText
                label
              }
              highIntensityScaleOptions {
                __typename
                active
                startValue
                endValue
                helpText
                label
              }
              lifeThreateningScaleOptions {
                __typename
                active
                startValue
                endValue
                helpText
                label
              }
              intensityQuestions {
                __typename
                id
                order
                question
                intensity
              }
              isIntensityQuestionsRequired
              isIntensityRequired
              isStartDateRequired
              isFinishDateRequired
              showNoValuesTaken
              noValuesTakenLabel
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfSymptomByConfFormIdCustomQuery>(
      response.data.confSymptomByConfFormId
    );
  }
  async AlertInstanceBySubjectIdCustom(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AlertInstanceBySubjectIdCustomQuery> {
    const statement = `query AlertInstanceBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        alertInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            confAlert {
              __typename
              confReportId
              confEdiaryId
              confSymptomId
              confTemperatureId
              id
              alertName
              recipients
              type
              mailSubject
              mailBody
              SMSBody
              alertRules {
                __typename
                ruleId
                alertRuleType
                expectedValue
              }
              _lastUser
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            id
            confAlert_data
            entityId
            entityName
            subjectId
            state
            revisionState
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AlertInstanceBySubjectIdCustomQuery>(
      response.data.alertInstanceBySubjectId
    );
  }
  async ConfICSectionByConfInformedConsentIdCustom(
    confInformedConsentId: string
  ): Promise<ConfICSectionByConfInformedConsentIdCustomQuery> {
    const statement = `query ConfICSectionByConfInformedConsentIdCustom($confInformedConsentId: ID!) {
        ConfICSectionByConfInformedConsentId(confInformedConsentId: $confInformedConsentId) {
          __typename
          items {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            complementaryQuestions {
              __typename
              id
              type
              question
              options
            }
            isElectronicSignatureRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICSectionByConfInformedConsentIdCustomQuery>(
      response.data.ConfICSectionByConfInformedConsentId
    );
  }
  async ConfICQuestionByConfInformedConsentIdCustom(
    confInformedConsentId: string
  ): Promise<ConfICQuestionByConfInformedConsentIdCustomQuery> {
    const statement = `query ConfICQuestionByConfInformedConsentIdCustom($confInformedConsentId: ID!) {
        ConfICQuestionByConfInformedConsentId(confInformedConsentId: $confInformedConsentId) {
          __typename
          items {
            __typename
            confICSectionId
            confICSection {
              __typename
              state
              _deleted
            }
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICQuestionByConfInformedConsentIdCustomQuery>(
      response.data.ConfICQuestionByConfInformedConsentId
    );
  }
  async ConfICAnswerByConfInformedConsentIdCustom(
    confInformedConsentId: string
  ): Promise<ConfICAnswerByConfInformedConsentIdCustomQuery> {
    const statement = `query ConfICAnswerByConfInformedConsentIdCustom($confInformedConsentId: ID!) {
        ConfICAnswerByConfInformedConsentId(confInformedConsentId: $confInformedConsentId) {
          __typename
          items {
            __typename
            confICQuestionId
            confICQuestion {
              __typename
              state
              _deleted
            }
            confInformedConsentId
            projectId
            id
            description
            order
            onErrorMessage
            isCorrect
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICAnswerByConfInformedConsentIdCustomQuery>(
      response.data.ConfICAnswerByConfInformedConsentId
    );
  }
  async ICQuestionInstanceByConsentedUserIdCustom(
    consentedUserId: string
  ): Promise<ICQuestionInstanceByConsentedUserIdCustomQuery> {
    const statement = `query ICQuestionInstanceByConsentedUserIdCustom($consentedUserId: ID!) {
        ICQuestionInstanceByConsentedUserId(consentedUserId: $consentedUserId) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICQuestion {
              __typename
              state
              _deleted
            }
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consentedUserId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICQuestionInstanceByConsentedUserIdCustomQuery>(
      response.data.ICQuestionInstanceByConsentedUserId
    );
  }
  async ICSectionInstanceByConsentedUserIdCustom(
    consentedUserId: string
  ): Promise<ICSectionInstanceByConsentedUserIdCustomQuery> {
    const statement = `query ICSectionInstanceByConsentedUserIdCustom($consentedUserId: ID!) {
        ICSectionInstanceByConsentedUserId(consentedUserId: $consentedUserId) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            confICSection {
              __typename
              state
              _deleted
            }
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            complementaryQuestionsAnswers {
              __typename
              type
              question {
                __typename
                id
                type
                question
                options
              }
              answer
            }
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consentedUserId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICSectionInstanceByConsentedUserIdCustomQuery>(
      response.data.ICSectionInstanceByConsentedUserId
    );
  }
  async ConfInformedConsentByProjectIDCustom(
    projectId: string
  ): Promise<ConfInformedConsentByProjectIDCustomQuery> {
    const statement = `query ConfInformedConsentByProjectIDCustom($projectId: ID!) {
        confInformedConsentByProjectID(projectId: $projectId) {
          __typename
          items {
            __typename
            projectId
            id
            name
            icVersion
            sites {
              __typename
              site
              recipients
              totalSubjects
            }
            type
            quizPageLayout
            state
            approvalSealFileUrl
            isMultipleSignatureRequired
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfInformedConsentByProjectIDCustomQuery>(
      response.data.confInformedConsentByProjectID
    );
  }
  async ConsentedUserByInformedConsentInstanceIdCustom(
    informedConsentInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConsentedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConsentedUserByInformedConsentInstanceIdCustomQuery> {
    const statement = `query ConsentedUserByInformedConsentInstanceIdCustom($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String) {
        ConsentedUserByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            userId
            user {
              __typename
              id
              login
              name
              email
              role
              roles
              permissions
              state
              phoneNumber
              firebaseToken
              isMFAActivated
              subjects
              sites
              projects
            }
            informedConsentInstanceId
            informedConsentInstance {
              __typename
              consentedIdentificator
            }
            id
            name
            kinship
            identification
            state
            stateChanges {
              __typename
              state
              date
            }
            requiresSendingEmail
            signature
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConsentedUserByInformedConsentInstanceIdCustomQuery>(
      response.data.ConsentedUserByInformedConsentInstanceId
    );
  }
  async InformedConsentInstanceByICConfigurationCustom(
    confInformedConsentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InformedConsentInstanceByICConfigurationCustomQuery> {
    const statement = `query InformedConsentInstanceByICConfigurationCustom($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        InformedConsentInstanceByICConfiguration(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            stateChanges {
              __typename
              state
              date
            }
            pdfFiles {
              __typename
              uploadDate
              fileUrl
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InformedConsentInstanceByICConfigurationCustomQuery>(
      response.data.InformedConsentInstanceByICConfiguration
    );
  }
  async ICSectionInstanceByInformedConsentInstanceIdCustom(
    informedConsentInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICSectionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICSectionInstanceByInformedConsentInstanceIdCustomQuery> {
    const statement = `query ICSectionInstanceByInformedConsentInstanceIdCustom($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICSectionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            state
            confICSection {
              __typename
              title
              id
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICSectionInstanceByInformedConsentInstanceIdCustomQuery>(
      response.data.ICSectionInstanceByInformedConsentInstanceId
    );
  }
  async ICQuestionInstanceByInformedConsentInstanceIdCustom(
    informedConsentInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICQuestionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICQuestionInstanceByInformedConsentInstanceIdCustomQuery> {
    const statement = `query ICQuestionInstanceByInformedConsentInstanceIdCustom($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICQuestionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            confICQuestion {
              __typename
              description
              id
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICQuestionInstanceByInformedConsentInstanceIdCustomQuery>(
      response.data.ICQuestionInstanceByInformedConsentInstanceId
    );
  }
  async CreateBackupInstance(
    input: CreateBackupInstanceInput,
    condition?: ModelBackupInstanceConditionInput
  ): Promise<CreateBackupInstanceMutation> {
    const statement = `mutation CreateBackupInstance($input: CreateBackupInstanceInput!, $condition: ModelBackupInstanceConditionInput) {
        createBackupInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBackupInstanceMutation>response.data.createBackupInstance;
  }
  async UpdateBackupInstance(
    input: UpdateBackupInstanceInput,
    condition?: ModelBackupInstanceConditionInput
  ): Promise<UpdateBackupInstanceMutation> {
    const statement = `mutation UpdateBackupInstance($input: UpdateBackupInstanceInput!, $condition: ModelBackupInstanceConditionInput) {
        updateBackupInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBackupInstanceMutation>response.data.updateBackupInstance;
  }
  async DeleteBackupInstance(
    input: DeleteBackupInstanceInput,
    condition?: ModelBackupInstanceConditionInput
  ): Promise<DeleteBackupInstanceMutation> {
    const statement = `mutation DeleteBackupInstance($input: DeleteBackupInstanceInput!, $condition: ModelBackupInstanceConditionInput) {
        deleteBackupInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBackupInstanceMutation>response.data.deleteBackupInstance;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateRole(
    input: CreateRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<CreateRoleMutation> {
    const statement = `mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
        createRole(input: $input, condition: $condition) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRoleMutation>response.data.createRole;
  }
  async UpdateRole(
    input: UpdateRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<UpdateRoleMutation> {
    const statement = `mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
        updateRole(input: $input, condition: $condition) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRoleMutation>response.data.updateRole;
  }
  async DeleteRole(
    input: DeleteRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<DeleteRoleMutation> {
    const statement = `mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
        deleteRole(input: $input, condition: $condition) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRoleMutation>response.data.deleteRole;
  }
  async CreateConfChatUser(
    input: CreateConfChatUserInput,
    condition?: ModelConfChatUserConditionInput
  ): Promise<CreateConfChatUserMutation> {
    const statement = `mutation CreateConfChatUser($input: CreateConfChatUserInput!, $condition: ModelConfChatUserConditionInput) {
        createConfChatUser(input: $input, condition: $condition) {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfChatUserMutation>response.data.createConfChatUser;
  }
  async UpdateConfChatUser(
    input: UpdateConfChatUserInput,
    condition?: ModelConfChatUserConditionInput
  ): Promise<UpdateConfChatUserMutation> {
    const statement = `mutation UpdateConfChatUser($input: UpdateConfChatUserInput!, $condition: ModelConfChatUserConditionInput) {
        updateConfChatUser(input: $input, condition: $condition) {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfChatUserMutation>response.data.updateConfChatUser;
  }
  async DeleteConfChatUser(
    input: DeleteConfChatUserInput,
    condition?: ModelConfChatUserConditionInput
  ): Promise<DeleteConfChatUserMutation> {
    const statement = `mutation DeleteConfChatUser($input: DeleteConfChatUserInput!, $condition: ModelConfChatUserConditionInput) {
        deleteConfChatUser(input: $input, condition: $condition) {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfChatUserMutation>response.data.deleteConfChatUser;
  }
  async CreateChatMessage(
    input: CreateChatMessageInput,
    condition?: ModelChatMessageConditionInput
  ): Promise<CreateChatMessageMutation> {
    const statement = `mutation CreateChatMessage($input: CreateChatMessageInput!, $condition: ModelChatMessageConditionInput) {
        createChatMessage(input: $input, condition: $condition) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChatMessageMutation>response.data.createChatMessage;
  }
  async UpdateChatMessage(
    input: UpdateChatMessageInput,
    condition?: ModelChatMessageConditionInput
  ): Promise<UpdateChatMessageMutation> {
    const statement = `mutation UpdateChatMessage($input: UpdateChatMessageInput!, $condition: ModelChatMessageConditionInput) {
        updateChatMessage(input: $input, condition: $condition) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChatMessageMutation>response.data.updateChatMessage;
  }
  async DeleteChatMessage(
    input: DeleteChatMessageInput,
    condition?: ModelChatMessageConditionInput
  ): Promise<DeleteChatMessageMutation> {
    const statement = `mutation DeleteChatMessage($input: DeleteChatMessageInput!, $condition: ModelChatMessageConditionInput) {
        deleteChatMessage(input: $input, condition: $condition) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChatMessageMutation>response.data.deleteChatMessage;
  }
  async CreateVerifiedDayInstance(
    input: CreateVerifiedDayInstanceInput,
    condition?: ModelVerifiedDayInstanceConditionInput
  ): Promise<CreateVerifiedDayInstanceMutation> {
    const statement = `mutation CreateVerifiedDayInstance($input: CreateVerifiedDayInstanceInput!, $condition: ModelVerifiedDayInstanceConditionInput) {
        createVerifiedDayInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVerifiedDayInstanceMutation>(
      response.data.createVerifiedDayInstance
    );
  }
  async UpdateVerifiedDayInstance(
    input: UpdateVerifiedDayInstanceInput,
    condition?: ModelVerifiedDayInstanceConditionInput
  ): Promise<UpdateVerifiedDayInstanceMutation> {
    const statement = `mutation UpdateVerifiedDayInstance($input: UpdateVerifiedDayInstanceInput!, $condition: ModelVerifiedDayInstanceConditionInput) {
        updateVerifiedDayInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVerifiedDayInstanceMutation>(
      response.data.updateVerifiedDayInstance
    );
  }
  async DeleteVerifiedDayInstance(
    input: DeleteVerifiedDayInstanceInput,
    condition?: ModelVerifiedDayInstanceConditionInput
  ): Promise<DeleteVerifiedDayInstanceMutation> {
    const statement = `mutation DeleteVerifiedDayInstance($input: DeleteVerifiedDayInstanceInput!, $condition: ModelVerifiedDayInstanceConditionInput) {
        deleteVerifiedDayInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVerifiedDayInstanceMutation>(
      response.data.deleteVerifiedDayInstance
    );
  }
  async CreateCommentConciliationInstance(
    input: CreateCommentConciliationInstanceInput,
    condition?: ModelCommentConciliationInstanceConditionInput
  ): Promise<CreateCommentConciliationInstanceMutation> {
    const statement = `mutation CreateCommentConciliationInstance($input: CreateCommentConciliationInstanceInput!, $condition: ModelCommentConciliationInstanceConditionInput) {
        createCommentConciliationInstance(input: $input, condition: $condition) {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCommentConciliationInstanceMutation>(
      response.data.createCommentConciliationInstance
    );
  }
  async UpdateCommentConciliationInstance(
    input: UpdateCommentConciliationInstanceInput,
    condition?: ModelCommentConciliationInstanceConditionInput
  ): Promise<UpdateCommentConciliationInstanceMutation> {
    const statement = `mutation UpdateCommentConciliationInstance($input: UpdateCommentConciliationInstanceInput!, $condition: ModelCommentConciliationInstanceConditionInput) {
        updateCommentConciliationInstance(input: $input, condition: $condition) {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCommentConciliationInstanceMutation>(
      response.data.updateCommentConciliationInstance
    );
  }
  async DeleteCommentConciliationInstance(
    input: DeleteCommentConciliationInstanceInput,
    condition?: ModelCommentConciliationInstanceConditionInput
  ): Promise<DeleteCommentConciliationInstanceMutation> {
    const statement = `mutation DeleteCommentConciliationInstance($input: DeleteCommentConciliationInstanceInput!, $condition: ModelCommentConciliationInstanceConditionInput) {
        deleteCommentConciliationInstance(input: $input, condition: $condition) {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCommentConciliationInstanceMutation>(
      response.data.deleteCommentConciliationInstance
    );
  }
  async CreateVerifiedSymptomInstance(
    input: CreateVerifiedSymptomInstanceInput,
    condition?: ModelVerifiedSymptomInstanceConditionInput
  ): Promise<CreateVerifiedSymptomInstanceMutation> {
    const statement = `mutation CreateVerifiedSymptomInstance($input: CreateVerifiedSymptomInstanceInput!, $condition: ModelVerifiedSymptomInstanceConditionInput) {
        createVerifiedSymptomInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVerifiedSymptomInstanceMutation>(
      response.data.createVerifiedSymptomInstance
    );
  }
  async UpdateVerifiedSymptomInstance(
    input: UpdateVerifiedSymptomInstanceInput,
    condition?: ModelVerifiedSymptomInstanceConditionInput
  ): Promise<UpdateVerifiedSymptomInstanceMutation> {
    const statement = `mutation UpdateVerifiedSymptomInstance($input: UpdateVerifiedSymptomInstanceInput!, $condition: ModelVerifiedSymptomInstanceConditionInput) {
        updateVerifiedSymptomInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVerifiedSymptomInstanceMutation>(
      response.data.updateVerifiedSymptomInstance
    );
  }
  async DeleteVerifiedSymptomInstance(
    input: DeleteVerifiedSymptomInstanceInput,
    condition?: ModelVerifiedSymptomInstanceConditionInput
  ): Promise<DeleteVerifiedSymptomInstanceMutation> {
    const statement = `mutation DeleteVerifiedSymptomInstance($input: DeleteVerifiedSymptomInstanceInput!, $condition: ModelVerifiedSymptomInstanceConditionInput) {
        deleteVerifiedSymptomInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVerifiedSymptomInstanceMutation>(
      response.data.deleteVerifiedSymptomInstance
    );
  }
  async CreateVerifiedSymptomRecordLog(
    input: CreateVerifiedSymptomRecordLogInput,
    condition?: ModelVerifiedSymptomRecordLogConditionInput
  ): Promise<CreateVerifiedSymptomRecordLogMutation> {
    const statement = `mutation CreateVerifiedSymptomRecordLog($input: CreateVerifiedSymptomRecordLogInput!, $condition: ModelVerifiedSymptomRecordLogConditionInput) {
        createVerifiedSymptomRecordLog(input: $input, condition: $condition) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVerifiedSymptomRecordLogMutation>(
      response.data.createVerifiedSymptomRecordLog
    );
  }
  async UpdateVerifiedSymptomRecordLog(
    input: UpdateVerifiedSymptomRecordLogInput,
    condition?: ModelVerifiedSymptomRecordLogConditionInput
  ): Promise<UpdateVerifiedSymptomRecordLogMutation> {
    const statement = `mutation UpdateVerifiedSymptomRecordLog($input: UpdateVerifiedSymptomRecordLogInput!, $condition: ModelVerifiedSymptomRecordLogConditionInput) {
        updateVerifiedSymptomRecordLog(input: $input, condition: $condition) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVerifiedSymptomRecordLogMutation>(
      response.data.updateVerifiedSymptomRecordLog
    );
  }
  async DeleteVerifiedSymptomRecordLog(
    input: DeleteVerifiedSymptomRecordLogInput,
    condition?: ModelVerifiedSymptomRecordLogConditionInput
  ): Promise<DeleteVerifiedSymptomRecordLogMutation> {
    const statement = `mutation DeleteVerifiedSymptomRecordLog($input: DeleteVerifiedSymptomRecordLogInput!, $condition: ModelVerifiedSymptomRecordLogConditionInput) {
        deleteVerifiedSymptomRecordLog(input: $input, condition: $condition) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVerifiedSymptomRecordLogMutation>(
      response.data.deleteVerifiedSymptomRecordLog
    );
  }
  async CreateVerifiedMedicationInstance(
    input: CreateVerifiedMedicationInstanceInput,
    condition?: ModelVerifiedMedicationInstanceConditionInput
  ): Promise<CreateVerifiedMedicationInstanceMutation> {
    const statement = `mutation CreateVerifiedMedicationInstance($input: CreateVerifiedMedicationInstanceInput!, $condition: ModelVerifiedMedicationInstanceConditionInput) {
        createVerifiedMedicationInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVerifiedMedicationInstanceMutation>(
      response.data.createVerifiedMedicationInstance
    );
  }
  async UpdateVerifiedMedicationInstance(
    input: UpdateVerifiedMedicationInstanceInput,
    condition?: ModelVerifiedMedicationInstanceConditionInput
  ): Promise<UpdateVerifiedMedicationInstanceMutation> {
    const statement = `mutation UpdateVerifiedMedicationInstance($input: UpdateVerifiedMedicationInstanceInput!, $condition: ModelVerifiedMedicationInstanceConditionInput) {
        updateVerifiedMedicationInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVerifiedMedicationInstanceMutation>(
      response.data.updateVerifiedMedicationInstance
    );
  }
  async DeleteVerifiedMedicationInstance(
    input: DeleteVerifiedMedicationInstanceInput,
    condition?: ModelVerifiedMedicationInstanceConditionInput
  ): Promise<DeleteVerifiedMedicationInstanceMutation> {
    const statement = `mutation DeleteVerifiedMedicationInstance($input: DeleteVerifiedMedicationInstanceInput!, $condition: ModelVerifiedMedicationInstanceConditionInput) {
        deleteVerifiedMedicationInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVerifiedMedicationInstanceMutation>(
      response.data.deleteVerifiedMedicationInstance
    );
  }
  async CreateVerifiedMedicalAttentionInstance(
    input: CreateVerifiedMedicalAttentionInstanceInput,
    condition?: ModelVerifiedMedicalAttentionInstanceConditionInput
  ): Promise<CreateVerifiedMedicalAttentionInstanceMutation> {
    const statement = `mutation CreateVerifiedMedicalAttentionInstance($input: CreateVerifiedMedicalAttentionInstanceInput!, $condition: ModelVerifiedMedicalAttentionInstanceConditionInput) {
        createVerifiedMedicalAttentionInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVerifiedMedicalAttentionInstanceMutation>(
      response.data.createVerifiedMedicalAttentionInstance
    );
  }
  async UpdateVerifiedMedicalAttentionInstance(
    input: UpdateVerifiedMedicalAttentionInstanceInput,
    condition?: ModelVerifiedMedicalAttentionInstanceConditionInput
  ): Promise<UpdateVerifiedMedicalAttentionInstanceMutation> {
    const statement = `mutation UpdateVerifiedMedicalAttentionInstance($input: UpdateVerifiedMedicalAttentionInstanceInput!, $condition: ModelVerifiedMedicalAttentionInstanceConditionInput) {
        updateVerifiedMedicalAttentionInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVerifiedMedicalAttentionInstanceMutation>(
      response.data.updateVerifiedMedicalAttentionInstance
    );
  }
  async DeleteVerifiedMedicalAttentionInstance(
    input: DeleteVerifiedMedicalAttentionInstanceInput,
    condition?: ModelVerifiedMedicalAttentionInstanceConditionInput
  ): Promise<DeleteVerifiedMedicalAttentionInstanceMutation> {
    const statement = `mutation DeleteVerifiedMedicalAttentionInstance($input: DeleteVerifiedMedicalAttentionInstanceInput!, $condition: ModelVerifiedMedicalAttentionInstanceConditionInput) {
        deleteVerifiedMedicalAttentionInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVerifiedMedicalAttentionInstanceMutation>(
      response.data.deleteVerifiedMedicalAttentionInstance
    );
  }
  async CreateVerifiedTemperatureRecordLog(
    input: CreateVerifiedTemperatureRecordLogInput,
    condition?: ModelVerifiedTemperatureRecordLogConditionInput
  ): Promise<CreateVerifiedTemperatureRecordLogMutation> {
    const statement = `mutation CreateVerifiedTemperatureRecordLog($input: CreateVerifiedTemperatureRecordLogInput!, $condition: ModelVerifiedTemperatureRecordLogConditionInput) {
        createVerifiedTemperatureRecordLog(input: $input, condition: $condition) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVerifiedTemperatureRecordLogMutation>(
      response.data.createVerifiedTemperatureRecordLog
    );
  }
  async UpdateVerifiedTemperatureRecordLog(
    input: UpdateVerifiedTemperatureRecordLogInput,
    condition?: ModelVerifiedTemperatureRecordLogConditionInput
  ): Promise<UpdateVerifiedTemperatureRecordLogMutation> {
    const statement = `mutation UpdateVerifiedTemperatureRecordLog($input: UpdateVerifiedTemperatureRecordLogInput!, $condition: ModelVerifiedTemperatureRecordLogConditionInput) {
        updateVerifiedTemperatureRecordLog(input: $input, condition: $condition) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVerifiedTemperatureRecordLogMutation>(
      response.data.updateVerifiedTemperatureRecordLog
    );
  }
  async DeleteVerifiedTemperatureRecordLog(
    input: DeleteVerifiedTemperatureRecordLogInput,
    condition?: ModelVerifiedTemperatureRecordLogConditionInput
  ): Promise<DeleteVerifiedTemperatureRecordLogMutation> {
    const statement = `mutation DeleteVerifiedTemperatureRecordLog($input: DeleteVerifiedTemperatureRecordLogInput!, $condition: ModelVerifiedTemperatureRecordLogConditionInput) {
        deleteVerifiedTemperatureRecordLog(input: $input, condition: $condition) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVerifiedTemperatureRecordLogMutation>(
      response.data.deleteVerifiedTemperatureRecordLog
    );
  }
  async CreateProject(
    input: CreateProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<CreateProjectMutation> {
    const statement = `mutation CreateProject($input: CreateProjectInput!, $condition: ModelProjectConditionInput) {
        createProject(input: $input, condition: $condition) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectMutation>response.data.createProject;
  }
  async UpdateProject(
    input: UpdateProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<UpdateProjectMutation> {
    const statement = `mutation UpdateProject($input: UpdateProjectInput!, $condition: ModelProjectConditionInput) {
        updateProject(input: $input, condition: $condition) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectMutation>response.data.updateProject;
  }
  async DeleteProject(
    input: DeleteProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<DeleteProjectMutation> {
    const statement = `mutation DeleteProject($input: DeleteProjectInput!, $condition: ModelProjectConditionInput) {
        deleteProject(input: $input, condition: $condition) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectMutation>response.data.deleteProject;
  }
  async CreateConfVisitGroup(
    input: CreateConfVisitGroupInput,
    condition?: ModelConfVisitGroupConditionInput
  ): Promise<CreateConfVisitGroupMutation> {
    const statement = `mutation CreateConfVisitGroup($input: CreateConfVisitGroupInput!, $condition: ModelConfVisitGroupConditionInput) {
        createConfVisitGroup(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfVisitGroupMutation>response.data.createConfVisitGroup;
  }
  async UpdateConfVisitGroup(
    input: UpdateConfVisitGroupInput,
    condition?: ModelConfVisitGroupConditionInput
  ): Promise<UpdateConfVisitGroupMutation> {
    const statement = `mutation UpdateConfVisitGroup($input: UpdateConfVisitGroupInput!, $condition: ModelConfVisitGroupConditionInput) {
        updateConfVisitGroup(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfVisitGroupMutation>response.data.updateConfVisitGroup;
  }
  async DeleteConfVisitGroup(
    input: DeleteConfVisitGroupInput,
    condition?: ModelConfVisitGroupConditionInput
  ): Promise<DeleteConfVisitGroupMutation> {
    const statement = `mutation DeleteConfVisitGroup($input: DeleteConfVisitGroupInput!, $condition: ModelConfVisitGroupConditionInput) {
        deleteConfVisitGroup(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfVisitGroupMutation>response.data.deleteConfVisitGroup;
  }
  async CreateSite(
    input: CreateSiteInput,
    condition?: ModelSiteConditionInput
  ): Promise<CreateSiteMutation> {
    const statement = `mutation CreateSite($input: CreateSiteInput!, $condition: ModelSiteConditionInput) {
        createSite(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteMutation>response.data.createSite;
  }
  async UpdateSite(
    input: UpdateSiteInput,
    condition?: ModelSiteConditionInput
  ): Promise<UpdateSiteMutation> {
    const statement = `mutation UpdateSite($input: UpdateSiteInput!, $condition: ModelSiteConditionInput) {
        updateSite(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteMutation>response.data.updateSite;
  }
  async DeleteSite(
    input: DeleteSiteInput,
    condition?: ModelSiteConditionInput
  ): Promise<DeleteSiteMutation> {
    const statement = `mutation DeleteSite($input: DeleteSiteInput!, $condition: ModelSiteConditionInput) {
        deleteSite(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteMutation>response.data.deleteSite;
  }
  async CreateAuthorizedSite(
    input: CreateAuthorizedSiteInput,
    condition?: ModelAuthorizedSiteConditionInput
  ): Promise<CreateAuthorizedSiteMutation> {
    const statement = `mutation CreateAuthorizedSite($input: CreateAuthorizedSiteInput!, $condition: ModelAuthorizedSiteConditionInput) {
        createAuthorizedSite(input: $input, condition: $condition) {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAuthorizedSiteMutation>response.data.createAuthorizedSite;
  }
  async UpdateAuthorizedSite(
    input: UpdateAuthorizedSiteInput,
    condition?: ModelAuthorizedSiteConditionInput
  ): Promise<UpdateAuthorizedSiteMutation> {
    const statement = `mutation UpdateAuthorizedSite($input: UpdateAuthorizedSiteInput!, $condition: ModelAuthorizedSiteConditionInput) {
        updateAuthorizedSite(input: $input, condition: $condition) {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAuthorizedSiteMutation>response.data.updateAuthorizedSite;
  }
  async DeleteAuthorizedSite(
    input: DeleteAuthorizedSiteInput,
    condition?: ModelAuthorizedSiteConditionInput
  ): Promise<DeleteAuthorizedSiteMutation> {
    const statement = `mutation DeleteAuthorizedSite($input: DeleteAuthorizedSiteInput!, $condition: ModelAuthorizedSiteConditionInput) {
        deleteAuthorizedSite(input: $input, condition: $condition) {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAuthorizedSiteMutation>response.data.deleteAuthorizedSite;
  }
  async CreateConfReport(
    input: CreateConfReportInput,
    condition?: ModelConfReportConditionInput
  ): Promise<CreateConfReportMutation> {
    const statement = `mutation CreateConfReport($input: CreateConfReportInput!, $condition: ModelConfReportConditionInput) {
        createConfReport(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfReportMutation>response.data.createConfReport;
  }
  async UpdateConfReport(
    input: UpdateConfReportInput,
    condition?: ModelConfReportConditionInput
  ): Promise<UpdateConfReportMutation> {
    const statement = `mutation UpdateConfReport($input: UpdateConfReportInput!, $condition: ModelConfReportConditionInput) {
        updateConfReport(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfReportMutation>response.data.updateConfReport;
  }
  async DeleteConfReport(
    input: DeleteConfReportInput,
    condition?: ModelConfReportConditionInput
  ): Promise<DeleteConfReportMutation> {
    const statement = `mutation DeleteConfReport($input: DeleteConfReportInput!, $condition: ModelConfReportConditionInput) {
        deleteConfReport(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfReportMutation>response.data.deleteConfReport;
  }
  async CreateConfReportBySubject(
    input: CreateConfReportBySubjectInput,
    condition?: ModelConfReportBySubjectConditionInput
  ): Promise<CreateConfReportBySubjectMutation> {
    const statement = `mutation CreateConfReportBySubject($input: CreateConfReportBySubjectInput!, $condition: ModelConfReportBySubjectConditionInput) {
        createConfReportBySubject(input: $input, condition: $condition) {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfReportBySubjectMutation>(
      response.data.createConfReportBySubject
    );
  }
  async UpdateConfReportBySubject(
    input: UpdateConfReportBySubjectInput,
    condition?: ModelConfReportBySubjectConditionInput
  ): Promise<UpdateConfReportBySubjectMutation> {
    const statement = `mutation UpdateConfReportBySubject($input: UpdateConfReportBySubjectInput!, $condition: ModelConfReportBySubjectConditionInput) {
        updateConfReportBySubject(input: $input, condition: $condition) {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfReportBySubjectMutation>(
      response.data.updateConfReportBySubject
    );
  }
  async DeleteConfReportBySubject(
    input: DeleteConfReportBySubjectInput,
    condition?: ModelConfReportBySubjectConditionInput
  ): Promise<DeleteConfReportBySubjectMutation> {
    const statement = `mutation DeleteConfReportBySubject($input: DeleteConfReportBySubjectInput!, $condition: ModelConfReportBySubjectConditionInput) {
        deleteConfReportBySubject(input: $input, condition: $condition) {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfReportBySubjectMutation>(
      response.data.deleteConfReportBySubject
    );
  }
  async CreateConfEDiary(
    input: CreateConfEDiaryInput,
    condition?: ModelConfEDiaryConditionInput
  ): Promise<CreateConfEDiaryMutation> {
    const statement = `mutation CreateConfEDiary($input: CreateConfEDiaryInput!, $condition: ModelConfEDiaryConditionInput) {
        createConfEDiary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfEDiaryMutation>response.data.createConfEDiary;
  }
  async UpdateConfEDiary(
    input: UpdateConfEDiaryInput,
    condition?: ModelConfEDiaryConditionInput
  ): Promise<UpdateConfEDiaryMutation> {
    const statement = `mutation UpdateConfEDiary($input: UpdateConfEDiaryInput!, $condition: ModelConfEDiaryConditionInput) {
        updateConfEDiary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfEDiaryMutation>response.data.updateConfEDiary;
  }
  async DeleteConfEDiary(
    input: DeleteConfEDiaryInput,
    condition?: ModelConfEDiaryConditionInput
  ): Promise<DeleteConfEDiaryMutation> {
    const statement = `mutation DeleteConfEDiary($input: DeleteConfEDiaryInput!, $condition: ModelConfEDiaryConditionInput) {
        deleteConfEDiary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfEDiaryMutation>response.data.deleteConfEDiary;
  }
  async CreateConfDay(
    input: CreateConfDayInput,
    condition?: ModelConfDayConditionInput
  ): Promise<CreateConfDayMutation> {
    const statement = `mutation CreateConfDay($input: CreateConfDayInput!, $condition: ModelConfDayConditionInput) {
        createConfDay(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfDayMutation>response.data.createConfDay;
  }
  async UpdateConfDay(
    input: UpdateConfDayInput,
    condition?: ModelConfDayConditionInput
  ): Promise<UpdateConfDayMutation> {
    const statement = `mutation UpdateConfDay($input: UpdateConfDayInput!, $condition: ModelConfDayConditionInput) {
        updateConfDay(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfDayMutation>response.data.updateConfDay;
  }
  async DeleteConfDay(
    input: DeleteConfDayInput,
    condition?: ModelConfDayConditionInput
  ): Promise<DeleteConfDayMutation> {
    const statement = `mutation DeleteConfDay($input: DeleteConfDayInput!, $condition: ModelConfDayConditionInput) {
        deleteConfDay(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfDayMutation>response.data.deleteConfDay;
  }
  async CreateConfForm(
    input: CreateConfFormInput,
    condition?: ModelConfFormConditionInput
  ): Promise<CreateConfFormMutation> {
    const statement = `mutation CreateConfForm($input: CreateConfFormInput!, $condition: ModelConfFormConditionInput) {
        createConfForm(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfFormMutation>response.data.createConfForm;
  }
  async UpdateConfForm(
    input: UpdateConfFormInput,
    condition?: ModelConfFormConditionInput
  ): Promise<UpdateConfFormMutation> {
    const statement = `mutation UpdateConfForm($input: UpdateConfFormInput!, $condition: ModelConfFormConditionInput) {
        updateConfForm(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfFormMutation>response.data.updateConfForm;
  }
  async DeleteConfForm(
    input: DeleteConfFormInput,
    condition?: ModelConfFormConditionInput
  ): Promise<DeleteConfFormMutation> {
    const statement = `mutation DeleteConfForm($input: DeleteConfFormInput!, $condition: ModelConfFormConditionInput) {
        deleteConfForm(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfFormMutation>response.data.deleteConfForm;
  }
  async CreateConfSymptom(
    input: CreateConfSymptomInput,
    condition?: ModelConfSymptomConditionInput
  ): Promise<CreateConfSymptomMutation> {
    const statement = `mutation CreateConfSymptom($input: CreateConfSymptomInput!, $condition: ModelConfSymptomConditionInput) {
        createConfSymptom(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfSymptomMutation>response.data.createConfSymptom;
  }
  async UpdateConfSymptom(
    input: UpdateConfSymptomInput,
    condition?: ModelConfSymptomConditionInput
  ): Promise<UpdateConfSymptomMutation> {
    const statement = `mutation UpdateConfSymptom($input: UpdateConfSymptomInput!, $condition: ModelConfSymptomConditionInput) {
        updateConfSymptom(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfSymptomMutation>response.data.updateConfSymptom;
  }
  async DeleteConfSymptom(
    input: DeleteConfSymptomInput,
    condition?: ModelConfSymptomConditionInput
  ): Promise<DeleteConfSymptomMutation> {
    const statement = `mutation DeleteConfSymptom($input: DeleteConfSymptomInput!, $condition: ModelConfSymptomConditionInput) {
        deleteConfSymptom(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfSymptomMutation>response.data.deleteConfSymptom;
  }
  async CreateConfFormConfSymptom(
    input: CreateConfFormConfSymptomInput,
    condition?: ModelConfFormConfSymptomConditionInput
  ): Promise<CreateConfFormConfSymptomMutation> {
    const statement = `mutation CreateConfFormConfSymptom($input: CreateConfFormConfSymptomInput!, $condition: ModelConfFormConfSymptomConditionInput) {
        createConfFormConfSymptom(input: $input, condition: $condition) {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfFormConfSymptomMutation>(
      response.data.createConfFormConfSymptom
    );
  }
  async UpdateConfFormConfSymptom(
    input: UpdateConfFormConfSymptomInput,
    condition?: ModelConfFormConfSymptomConditionInput
  ): Promise<UpdateConfFormConfSymptomMutation> {
    const statement = `mutation UpdateConfFormConfSymptom($input: UpdateConfFormConfSymptomInput!, $condition: ModelConfFormConfSymptomConditionInput) {
        updateConfFormConfSymptom(input: $input, condition: $condition) {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfFormConfSymptomMutation>(
      response.data.updateConfFormConfSymptom
    );
  }
  async DeleteConfFormConfSymptom(
    input: DeleteConfFormConfSymptomInput,
    condition?: ModelConfFormConfSymptomConditionInput
  ): Promise<DeleteConfFormConfSymptomMutation> {
    const statement = `mutation DeleteConfFormConfSymptom($input: DeleteConfFormConfSymptomInput!, $condition: ModelConfFormConfSymptomConditionInput) {
        deleteConfFormConfSymptom(input: $input, condition: $condition) {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfFormConfSymptomMutation>(
      response.data.deleteConfFormConfSymptom
    );
  }
  async CreateConfMedication(
    input: CreateConfMedicationInput,
    condition?: ModelConfMedicationConditionInput
  ): Promise<CreateConfMedicationMutation> {
    const statement = `mutation CreateConfMedication($input: CreateConfMedicationInput!, $condition: ModelConfMedicationConditionInput) {
        createConfMedication(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfMedicationMutation>response.data.createConfMedication;
  }
  async UpdateConfMedication(
    input: UpdateConfMedicationInput,
    condition?: ModelConfMedicationConditionInput
  ): Promise<UpdateConfMedicationMutation> {
    const statement = `mutation UpdateConfMedication($input: UpdateConfMedicationInput!, $condition: ModelConfMedicationConditionInput) {
        updateConfMedication(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfMedicationMutation>response.data.updateConfMedication;
  }
  async DeleteConfMedication(
    input: DeleteConfMedicationInput,
    condition?: ModelConfMedicationConditionInput
  ): Promise<DeleteConfMedicationMutation> {
    const statement = `mutation DeleteConfMedication($input: DeleteConfMedicationInput!, $condition: ModelConfMedicationConditionInput) {
        deleteConfMedication(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfMedicationMutation>response.data.deleteConfMedication;
  }
  async CreateConfMedicalAttention(
    input: CreateConfMedicalAttentionInput,
    condition?: ModelConfMedicalAttentionConditionInput
  ): Promise<CreateConfMedicalAttentionMutation> {
    const statement = `mutation CreateConfMedicalAttention($input: CreateConfMedicalAttentionInput!, $condition: ModelConfMedicalAttentionConditionInput) {
        createConfMedicalAttention(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfMedicalAttentionMutation>(
      response.data.createConfMedicalAttention
    );
  }
  async UpdateConfMedicalAttention(
    input: UpdateConfMedicalAttentionInput,
    condition?: ModelConfMedicalAttentionConditionInput
  ): Promise<UpdateConfMedicalAttentionMutation> {
    const statement = `mutation UpdateConfMedicalAttention($input: UpdateConfMedicalAttentionInput!, $condition: ModelConfMedicalAttentionConditionInput) {
        updateConfMedicalAttention(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfMedicalAttentionMutation>(
      response.data.updateConfMedicalAttention
    );
  }
  async DeleteConfMedicalAttention(
    input: DeleteConfMedicalAttentionInput,
    condition?: ModelConfMedicalAttentionConditionInput
  ): Promise<DeleteConfMedicalAttentionMutation> {
    const statement = `mutation DeleteConfMedicalAttention($input: DeleteConfMedicalAttentionInput!, $condition: ModelConfMedicalAttentionConditionInput) {
        deleteConfMedicalAttention(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfMedicalAttentionMutation>(
      response.data.deleteConfMedicalAttention
    );
  }
  async CreateConfTemperature(
    input: CreateConfTemperatureInput,
    condition?: ModelConfTemperatureConditionInput
  ): Promise<CreateConfTemperatureMutation> {
    const statement = `mutation CreateConfTemperature($input: CreateConfTemperatureInput!, $condition: ModelConfTemperatureConditionInput) {
        createConfTemperature(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfTemperatureMutation>response.data.createConfTemperature;
  }
  async UpdateConfTemperature(
    input: UpdateConfTemperatureInput,
    condition?: ModelConfTemperatureConditionInput
  ): Promise<UpdateConfTemperatureMutation> {
    const statement = `mutation UpdateConfTemperature($input: UpdateConfTemperatureInput!, $condition: ModelConfTemperatureConditionInput) {
        updateConfTemperature(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfTemperatureMutation>response.data.updateConfTemperature;
  }
  async DeleteConfTemperature(
    input: DeleteConfTemperatureInput,
    condition?: ModelConfTemperatureConditionInput
  ): Promise<DeleteConfTemperatureMutation> {
    const statement = `mutation DeleteConfTemperature($input: DeleteConfTemperatureInput!, $condition: ModelConfTemperatureConditionInput) {
        deleteConfTemperature(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfTemperatureMutation>response.data.deleteConfTemperature;
  }
  async CreateConfMultimedia(
    input: CreateConfMultimediaInput,
    condition?: ModelConfMultimediaConditionInput
  ): Promise<CreateConfMultimediaMutation> {
    const statement = `mutation CreateConfMultimedia($input: CreateConfMultimediaInput!, $condition: ModelConfMultimediaConditionInput) {
        createConfMultimedia(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfMultimediaMutation>response.data.createConfMultimedia;
  }
  async UpdateConfMultimedia(
    input: UpdateConfMultimediaInput,
    condition?: ModelConfMultimediaConditionInput
  ): Promise<UpdateConfMultimediaMutation> {
    const statement = `mutation UpdateConfMultimedia($input: UpdateConfMultimediaInput!, $condition: ModelConfMultimediaConditionInput) {
        updateConfMultimedia(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfMultimediaMutation>response.data.updateConfMultimedia;
  }
  async DeleteConfMultimedia(
    input: DeleteConfMultimediaInput,
    condition?: ModelConfMultimediaConditionInput
  ): Promise<DeleteConfMultimediaMutation> {
    const statement = `mutation DeleteConfMultimedia($input: DeleteConfMultimediaInput!, $condition: ModelConfMultimediaConditionInput) {
        deleteConfMultimedia(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfMultimediaMutation>response.data.deleteConfMultimedia;
  }
  async CreateConfOtherSymptoms(
    input: CreateConfOtherSymptomsInput,
    condition?: ModelConfOtherSymptomsConditionInput
  ): Promise<CreateConfOtherSymptomsMutation> {
    const statement = `mutation CreateConfOtherSymptoms($input: CreateConfOtherSymptomsInput!, $condition: ModelConfOtherSymptomsConditionInput) {
        createConfOtherSymptoms(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfOtherSymptomsMutation>(
      response.data.createConfOtherSymptoms
    );
  }
  async UpdateConfOtherSymptoms(
    input: UpdateConfOtherSymptomsInput,
    condition?: ModelConfOtherSymptomsConditionInput
  ): Promise<UpdateConfOtherSymptomsMutation> {
    const statement = `mutation UpdateConfOtherSymptoms($input: UpdateConfOtherSymptomsInput!, $condition: ModelConfOtherSymptomsConditionInput) {
        updateConfOtherSymptoms(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfOtherSymptomsMutation>(
      response.data.updateConfOtherSymptoms
    );
  }
  async DeleteConfOtherSymptoms(
    input: DeleteConfOtherSymptomsInput,
    condition?: ModelConfOtherSymptomsConditionInput
  ): Promise<DeleteConfOtherSymptomsMutation> {
    const statement = `mutation DeleteConfOtherSymptoms($input: DeleteConfOtherSymptomsInput!, $condition: ModelConfOtherSymptomsConditionInput) {
        deleteConfOtherSymptoms(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfOtherSymptomsMutation>(
      response.data.deleteConfOtherSymptoms
    );
  }
  async CreateConfAlert(
    input: CreateConfAlertInput,
    condition?: ModelConfAlertConditionInput
  ): Promise<CreateConfAlertMutation> {
    const statement = `mutation CreateConfAlert($input: CreateConfAlertInput!, $condition: ModelConfAlertConditionInput) {
        createConfAlert(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfAlertMutation>response.data.createConfAlert;
  }
  async UpdateConfAlert(
    input: UpdateConfAlertInput,
    condition?: ModelConfAlertConditionInput
  ): Promise<UpdateConfAlertMutation> {
    const statement = `mutation UpdateConfAlert($input: UpdateConfAlertInput!, $condition: ModelConfAlertConditionInput) {
        updateConfAlert(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfAlertMutation>response.data.updateConfAlert;
  }
  async DeleteConfAlert(
    input: DeleteConfAlertInput,
    condition?: ModelConfAlertConditionInput
  ): Promise<DeleteConfAlertMutation> {
    const statement = `mutation DeleteConfAlert($input: DeleteConfAlertInput!, $condition: ModelConfAlertConditionInput) {
        deleteConfAlert(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfAlertMutation>response.data.deleteConfAlert;
  }
  async CreateConfDictionary(
    input: CreateConfDictionaryInput,
    condition?: ModelConfDictionaryConditionInput
  ): Promise<CreateConfDictionaryMutation> {
    const statement = `mutation CreateConfDictionary($input: CreateConfDictionaryInput!, $condition: ModelConfDictionaryConditionInput) {
        createConfDictionary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfDictionaryMutation>response.data.createConfDictionary;
  }
  async UpdateConfDictionary(
    input: UpdateConfDictionaryInput,
    condition?: ModelConfDictionaryConditionInput
  ): Promise<UpdateConfDictionaryMutation> {
    const statement = `mutation UpdateConfDictionary($input: UpdateConfDictionaryInput!, $condition: ModelConfDictionaryConditionInput) {
        updateConfDictionary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfDictionaryMutation>response.data.updateConfDictionary;
  }
  async DeleteConfDictionary(
    input: DeleteConfDictionaryInput,
    condition?: ModelConfDictionaryConditionInput
  ): Promise<DeleteConfDictionaryMutation> {
    const statement = `mutation DeleteConfDictionary($input: DeleteConfDictionaryInput!, $condition: ModelConfDictionaryConditionInput) {
        deleteConfDictionary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfDictionaryMutation>response.data.deleteConfDictionary;
  }
  async CreateConfInformedConsent(
    input: CreateConfInformedConsentInput,
    condition?: ModelConfInformedConsentConditionInput
  ): Promise<CreateConfInformedConsentMutation> {
    const statement = `mutation CreateConfInformedConsent($input: CreateConfInformedConsentInput!, $condition: ModelConfInformedConsentConditionInput) {
        createConfInformedConsent(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfInformedConsentMutation>(
      response.data.createConfInformedConsent
    );
  }
  async UpdateConfInformedConsent(
    input: UpdateConfInformedConsentInput,
    condition?: ModelConfInformedConsentConditionInput
  ): Promise<UpdateConfInformedConsentMutation> {
    const statement = `mutation UpdateConfInformedConsent($input: UpdateConfInformedConsentInput!, $condition: ModelConfInformedConsentConditionInput) {
        updateConfInformedConsent(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfInformedConsentMutation>(
      response.data.updateConfInformedConsent
    );
  }
  async DeleteConfInformedConsent(
    input: DeleteConfInformedConsentInput,
    condition?: ModelConfInformedConsentConditionInput
  ): Promise<DeleteConfInformedConsentMutation> {
    const statement = `mutation DeleteConfInformedConsent($input: DeleteConfInformedConsentInput!, $condition: ModelConfInformedConsentConditionInput) {
        deleteConfInformedConsent(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfInformedConsentMutation>(
      response.data.deleteConfInformedConsent
    );
  }
  async CreateConfICSection(
    input: CreateConfICSectionInput,
    condition?: ModelConfICSectionConditionInput
  ): Promise<CreateConfICSectionMutation> {
    const statement = `mutation CreateConfICSection($input: CreateConfICSectionInput!, $condition: ModelConfICSectionConditionInput) {
        createConfICSection(input: $input, condition: $condition) {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfICSectionMutation>response.data.createConfICSection;
  }
  async UpdateConfICSection(
    input: UpdateConfICSectionInput,
    condition?: ModelConfICSectionConditionInput
  ): Promise<UpdateConfICSectionMutation> {
    const statement = `mutation UpdateConfICSection($input: UpdateConfICSectionInput!, $condition: ModelConfICSectionConditionInput) {
        updateConfICSection(input: $input, condition: $condition) {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfICSectionMutation>response.data.updateConfICSection;
  }
  async DeleteConfICSection(
    input: DeleteConfICSectionInput,
    condition?: ModelConfICSectionConditionInput
  ): Promise<DeleteConfICSectionMutation> {
    const statement = `mutation DeleteConfICSection($input: DeleteConfICSectionInput!, $condition: ModelConfICSectionConditionInput) {
        deleteConfICSection(input: $input, condition: $condition) {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfICSectionMutation>response.data.deleteConfICSection;
  }
  async CreateConfICQuestion(
    input: CreateConfICQuestionInput,
    condition?: ModelConfICQuestionConditionInput
  ): Promise<CreateConfICQuestionMutation> {
    const statement = `mutation CreateConfICQuestion($input: CreateConfICQuestionInput!, $condition: ModelConfICQuestionConditionInput) {
        createConfICQuestion(input: $input, condition: $condition) {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfICQuestionMutation>response.data.createConfICQuestion;
  }
  async UpdateConfICQuestion(
    input: UpdateConfICQuestionInput,
    condition?: ModelConfICQuestionConditionInput
  ): Promise<UpdateConfICQuestionMutation> {
    const statement = `mutation UpdateConfICQuestion($input: UpdateConfICQuestionInput!, $condition: ModelConfICQuestionConditionInput) {
        updateConfICQuestion(input: $input, condition: $condition) {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfICQuestionMutation>response.data.updateConfICQuestion;
  }
  async DeleteConfICQuestion(
    input: DeleteConfICQuestionInput,
    condition?: ModelConfICQuestionConditionInput
  ): Promise<DeleteConfICQuestionMutation> {
    const statement = `mutation DeleteConfICQuestion($input: DeleteConfICQuestionInput!, $condition: ModelConfICQuestionConditionInput) {
        deleteConfICQuestion(input: $input, condition: $condition) {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfICQuestionMutation>response.data.deleteConfICQuestion;
  }
  async CreateConfICAnswer(
    input: CreateConfICAnswerInput,
    condition?: ModelConfICAnswerConditionInput
  ): Promise<CreateConfICAnswerMutation> {
    const statement = `mutation CreateConfICAnswer($input: CreateConfICAnswerInput!, $condition: ModelConfICAnswerConditionInput) {
        createConfICAnswer(input: $input, condition: $condition) {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfICAnswerMutation>response.data.createConfICAnswer;
  }
  async UpdateConfICAnswer(
    input: UpdateConfICAnswerInput,
    condition?: ModelConfICAnswerConditionInput
  ): Promise<UpdateConfICAnswerMutation> {
    const statement = `mutation UpdateConfICAnswer($input: UpdateConfICAnswerInput!, $condition: ModelConfICAnswerConditionInput) {
        updateConfICAnswer(input: $input, condition: $condition) {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfICAnswerMutation>response.data.updateConfICAnswer;
  }
  async DeleteConfICAnswer(
    input: DeleteConfICAnswerInput,
    condition?: ModelConfICAnswerConditionInput
  ): Promise<DeleteConfICAnswerMutation> {
    const statement = `mutation DeleteConfICAnswer($input: DeleteConfICAnswerInput!, $condition: ModelConfICAnswerConditionInput) {
        deleteConfICAnswer(input: $input, condition: $condition) {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfICAnswerMutation>response.data.deleteConfICAnswer;
  }
  async CreateInformedConsentInstance(
    input: CreateInformedConsentInstanceInput,
    condition?: ModelInformedConsentInstanceConditionInput
  ): Promise<CreateInformedConsentInstanceMutation> {
    const statement = `mutation CreateInformedConsentInstance($input: CreateInformedConsentInstanceInput!, $condition: ModelInformedConsentInstanceConditionInput) {
        createInformedConsentInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInformedConsentInstanceMutation>(
      response.data.createInformedConsentInstance
    );
  }
  async UpdateInformedConsentInstance(
    input: UpdateInformedConsentInstanceInput,
    condition?: ModelInformedConsentInstanceConditionInput
  ): Promise<UpdateInformedConsentInstanceMutation> {
    const statement = `mutation UpdateInformedConsentInstance($input: UpdateInformedConsentInstanceInput!, $condition: ModelInformedConsentInstanceConditionInput) {
        updateInformedConsentInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInformedConsentInstanceMutation>(
      response.data.updateInformedConsentInstance
    );
  }
  async DeleteInformedConsentInstance(
    input: DeleteInformedConsentInstanceInput,
    condition?: ModelInformedConsentInstanceConditionInput
  ): Promise<DeleteInformedConsentInstanceMutation> {
    const statement = `mutation DeleteInformedConsentInstance($input: DeleteInformedConsentInstanceInput!, $condition: ModelInformedConsentInstanceConditionInput) {
        deleteInformedConsentInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInformedConsentInstanceMutation>(
      response.data.deleteInformedConsentInstance
    );
  }
  async CreateConsentedUser(
    input: CreateConsentedUserInput,
    condition?: ModelConsentedUserConditionInput
  ): Promise<CreateConsentedUserMutation> {
    const statement = `mutation CreateConsentedUser($input: CreateConsentedUserInput!, $condition: ModelConsentedUserConditionInput) {
        createConsentedUser(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConsentedUserMutation>response.data.createConsentedUser;
  }
  async UpdateConsentedUser(
    input: UpdateConsentedUserInput,
    condition?: ModelConsentedUserConditionInput
  ): Promise<UpdateConsentedUserMutation> {
    const statement = `mutation UpdateConsentedUser($input: UpdateConsentedUserInput!, $condition: ModelConsentedUserConditionInput) {
        updateConsentedUser(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConsentedUserMutation>response.data.updateConsentedUser;
  }
  async DeleteConsentedUser(
    input: DeleteConsentedUserInput,
    condition?: ModelConsentedUserConditionInput
  ): Promise<DeleteConsentedUserMutation> {
    const statement = `mutation DeleteConsentedUser($input: DeleteConsentedUserInput!, $condition: ModelConsentedUserConditionInput) {
        deleteConsentedUser(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConsentedUserMutation>response.data.deleteConsentedUser;
  }
  async CreateICSectionInstance(
    input: CreateICSectionInstanceInput,
    condition?: ModelICSectionInstanceConditionInput
  ): Promise<CreateICSectionInstanceMutation> {
    const statement = `mutation CreateICSectionInstance($input: CreateICSectionInstanceInput!, $condition: ModelICSectionInstanceConditionInput) {
        createICSectionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateICSectionInstanceMutation>(
      response.data.createICSectionInstance
    );
  }
  async UpdateICSectionInstance(
    input: UpdateICSectionInstanceInput,
    condition?: ModelICSectionInstanceConditionInput
  ): Promise<UpdateICSectionInstanceMutation> {
    const statement = `mutation UpdateICSectionInstance($input: UpdateICSectionInstanceInput!, $condition: ModelICSectionInstanceConditionInput) {
        updateICSectionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateICSectionInstanceMutation>(
      response.data.updateICSectionInstance
    );
  }
  async DeleteICSectionInstance(
    input: DeleteICSectionInstanceInput,
    condition?: ModelICSectionInstanceConditionInput
  ): Promise<DeleteICSectionInstanceMutation> {
    const statement = `mutation DeleteICSectionInstance($input: DeleteICSectionInstanceInput!, $condition: ModelICSectionInstanceConditionInput) {
        deleteICSectionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteICSectionInstanceMutation>(
      response.data.deleteICSectionInstance
    );
  }
  async CreateICQuestionInstance(
    input: CreateICQuestionInstanceInput,
    condition?: ModelICQuestionInstanceConditionInput
  ): Promise<CreateICQuestionInstanceMutation> {
    const statement = `mutation CreateICQuestionInstance($input: CreateICQuestionInstanceInput!, $condition: ModelICQuestionInstanceConditionInput) {
        createICQuestionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateICQuestionInstanceMutation>(
      response.data.createICQuestionInstance
    );
  }
  async UpdateICQuestionInstance(
    input: UpdateICQuestionInstanceInput,
    condition?: ModelICQuestionInstanceConditionInput
  ): Promise<UpdateICQuestionInstanceMutation> {
    const statement = `mutation UpdateICQuestionInstance($input: UpdateICQuestionInstanceInput!, $condition: ModelICQuestionInstanceConditionInput) {
        updateICQuestionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateICQuestionInstanceMutation>(
      response.data.updateICQuestionInstance
    );
  }
  async DeleteICQuestionInstance(
    input: DeleteICQuestionInstanceInput,
    condition?: ModelICQuestionInstanceConditionInput
  ): Promise<DeleteICQuestionInstanceMutation> {
    const statement = `mutation DeleteICQuestionInstance($input: DeleteICQuestionInstanceInput!, $condition: ModelICQuestionInstanceConditionInput) {
        deleteICQuestionInstance(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteICQuestionInstanceMutation>(
      response.data.deleteICQuestionInstance
    );
  }
  async CreateSubject(
    input: CreateSubjectInput,
    condition?: ModelSubjectConditionInput
  ): Promise<CreateSubjectMutation> {
    const statement = `mutation CreateSubject($input: CreateSubjectInput!, $condition: ModelSubjectConditionInput) {
        createSubject(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubjectMutation>response.data.createSubject;
  }
  async UpdateSubject(
    input: UpdateSubjectInput,
    condition?: ModelSubjectConditionInput
  ): Promise<UpdateSubjectMutation> {
    const statement = `mutation UpdateSubject($input: UpdateSubjectInput!, $condition: ModelSubjectConditionInput) {
        updateSubject(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubjectMutation>response.data.updateSubject;
  }
  async DeleteSubject(
    input: DeleteSubjectInput,
    condition?: ModelSubjectConditionInput
  ): Promise<DeleteSubjectMutation> {
    const statement = `mutation DeleteSubject($input: DeleteSubjectInput!, $condition: ModelSubjectConditionInput) {
        deleteSubject(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubjectMutation>response.data.deleteSubject;
  }
  async CreateReportInstance(
    input: CreateReportInstanceInput,
    condition?: ModelReportInstanceConditionInput
  ): Promise<CreateReportInstanceMutation> {
    const statement = `mutation CreateReportInstance($input: CreateReportInstanceInput!, $condition: ModelReportInstanceConditionInput) {
        createReportInstance(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateReportInstanceMutation>response.data.createReportInstance;
  }
  async UpdateReportInstance(
    input: UpdateReportInstanceInput,
    condition?: ModelReportInstanceConditionInput
  ): Promise<UpdateReportInstanceMutation> {
    const statement = `mutation UpdateReportInstance($input: UpdateReportInstanceInput!, $condition: ModelReportInstanceConditionInput) {
        updateReportInstance(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateReportInstanceMutation>response.data.updateReportInstance;
  }
  async DeleteReportInstance(
    input: DeleteReportInstanceInput,
    condition?: ModelReportInstanceConditionInput
  ): Promise<DeleteReportInstanceMutation> {
    const statement = `mutation DeleteReportInstance($input: DeleteReportInstanceInput!, $condition: ModelReportInstanceConditionInput) {
        deleteReportInstance(input: $input, condition: $condition) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteReportInstanceMutation>response.data.deleteReportInstance;
  }
  async CreateSignatureInstance(
    input: CreateSignatureInstanceInput,
    condition?: ModelSignatureInstanceConditionInput
  ): Promise<CreateSignatureInstanceMutation> {
    const statement = `mutation CreateSignatureInstance($input: CreateSignatureInstanceInput!, $condition: ModelSignatureInstanceConditionInput) {
        createSignatureInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSignatureInstanceMutation>(
      response.data.createSignatureInstance
    );
  }
  async UpdateSignatureInstance(
    input: UpdateSignatureInstanceInput,
    condition?: ModelSignatureInstanceConditionInput
  ): Promise<UpdateSignatureInstanceMutation> {
    const statement = `mutation UpdateSignatureInstance($input: UpdateSignatureInstanceInput!, $condition: ModelSignatureInstanceConditionInput) {
        updateSignatureInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSignatureInstanceMutation>(
      response.data.updateSignatureInstance
    );
  }
  async DeleteSignatureInstance(
    input: DeleteSignatureInstanceInput,
    condition?: ModelSignatureInstanceConditionInput
  ): Promise<DeleteSignatureInstanceMutation> {
    const statement = `mutation DeleteSignatureInstance($input: DeleteSignatureInstanceInput!, $condition: ModelSignatureInstanceConditionInput) {
        deleteSignatureInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSignatureInstanceMutation>(
      response.data.deleteSignatureInstance
    );
  }
  async CreateEDiaryInstance(
    input: CreateEDiaryInstanceInput,
    condition?: ModelEDiaryInstanceConditionInput
  ): Promise<CreateEDiaryInstanceMutation> {
    const statement = `mutation CreateEDiaryInstance($input: CreateEDiaryInstanceInput!, $condition: ModelEDiaryInstanceConditionInput) {
        createEDiaryInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEDiaryInstanceMutation>response.data.createEDiaryInstance;
  }
  async UpdateEDiaryInstance(
    input: UpdateEDiaryInstanceInput,
    condition?: ModelEDiaryInstanceConditionInput
  ): Promise<UpdateEDiaryInstanceMutation> {
    const statement = `mutation UpdateEDiaryInstance($input: UpdateEDiaryInstanceInput!, $condition: ModelEDiaryInstanceConditionInput) {
        updateEDiaryInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEDiaryInstanceMutation>response.data.updateEDiaryInstance;
  }
  async DeleteEDiaryInstance(
    input: DeleteEDiaryInstanceInput,
    condition?: ModelEDiaryInstanceConditionInput
  ): Promise<DeleteEDiaryInstanceMutation> {
    const statement = `mutation DeleteEDiaryInstance($input: DeleteEDiaryInstanceInput!, $condition: ModelEDiaryInstanceConditionInput) {
        deleteEDiaryInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEDiaryInstanceMutation>response.data.deleteEDiaryInstance;
  }
  async CreateEDiaryPhaseInstance(
    input: CreateEDiaryPhaseInstanceInput,
    condition?: ModelEDiaryPhaseInstanceConditionInput
  ): Promise<CreateEDiaryPhaseInstanceMutation> {
    const statement = `mutation CreateEDiaryPhaseInstance($input: CreateEDiaryPhaseInstanceInput!, $condition: ModelEDiaryPhaseInstanceConditionInput) {
        createEDiaryPhaseInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEDiaryPhaseInstanceMutation>(
      response.data.createEDiaryPhaseInstance
    );
  }
  async UpdateEDiaryPhaseInstance(
    input: UpdateEDiaryPhaseInstanceInput,
    condition?: ModelEDiaryPhaseInstanceConditionInput
  ): Promise<UpdateEDiaryPhaseInstanceMutation> {
    const statement = `mutation UpdateEDiaryPhaseInstance($input: UpdateEDiaryPhaseInstanceInput!, $condition: ModelEDiaryPhaseInstanceConditionInput) {
        updateEDiaryPhaseInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEDiaryPhaseInstanceMutation>(
      response.data.updateEDiaryPhaseInstance
    );
  }
  async DeleteEDiaryPhaseInstance(
    input: DeleteEDiaryPhaseInstanceInput,
    condition?: ModelEDiaryPhaseInstanceConditionInput
  ): Promise<DeleteEDiaryPhaseInstanceMutation> {
    const statement = `mutation DeleteEDiaryPhaseInstance($input: DeleteEDiaryPhaseInstanceInput!, $condition: ModelEDiaryPhaseInstanceConditionInput) {
        deleteEDiaryPhaseInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEDiaryPhaseInstanceMutation>(
      response.data.deleteEDiaryPhaseInstance
    );
  }
  async CreateDayInstance(
    input: CreateDayInstanceInput,
    condition?: ModelDayInstanceConditionInput
  ): Promise<CreateDayInstanceMutation> {
    const statement = `mutation CreateDayInstance($input: CreateDayInstanceInput!, $condition: ModelDayInstanceConditionInput) {
        createDayInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDayInstanceMutation>response.data.createDayInstance;
  }
  async UpdateDayInstance(
    input: UpdateDayInstanceInput,
    condition?: ModelDayInstanceConditionInput
  ): Promise<UpdateDayInstanceMutation> {
    const statement = `mutation UpdateDayInstance($input: UpdateDayInstanceInput!, $condition: ModelDayInstanceConditionInput) {
        updateDayInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDayInstanceMutation>response.data.updateDayInstance;
  }
  async DeleteDayInstance(
    input: DeleteDayInstanceInput,
    condition?: ModelDayInstanceConditionInput
  ): Promise<DeleteDayInstanceMutation> {
    const statement = `mutation DeleteDayInstance($input: DeleteDayInstanceInput!, $condition: ModelDayInstanceConditionInput) {
        deleteDayInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDayInstanceMutation>response.data.deleteDayInstance;
  }
  async CreateSymptomRecordLog(
    input: CreateSymptomRecordLogInput,
    condition?: ModelSymptomRecordLogConditionInput
  ): Promise<CreateSymptomRecordLogMutation> {
    const statement = `mutation CreateSymptomRecordLog($input: CreateSymptomRecordLogInput!, $condition: ModelSymptomRecordLogConditionInput) {
        createSymptomRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSymptomRecordLogMutation>response.data.createSymptomRecordLog;
  }
  async UpdateSymptomRecordLog(
    input: UpdateSymptomRecordLogInput,
    condition?: ModelSymptomRecordLogConditionInput
  ): Promise<UpdateSymptomRecordLogMutation> {
    const statement = `mutation UpdateSymptomRecordLog($input: UpdateSymptomRecordLogInput!, $condition: ModelSymptomRecordLogConditionInput) {
        updateSymptomRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSymptomRecordLogMutation>response.data.updateSymptomRecordLog;
  }
  async DeleteSymptomRecordLog(
    input: DeleteSymptomRecordLogInput,
    condition?: ModelSymptomRecordLogConditionInput
  ): Promise<DeleteSymptomRecordLogMutation> {
    const statement = `mutation DeleteSymptomRecordLog($input: DeleteSymptomRecordLogInput!, $condition: ModelSymptomRecordLogConditionInput) {
        deleteSymptomRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSymptomRecordLogMutation>response.data.deleteSymptomRecordLog;
  }
  async CreateSymptomInstance(
    input: CreateSymptomInstanceInput,
    condition?: ModelSymptomInstanceConditionInput
  ): Promise<CreateSymptomInstanceMutation> {
    const statement = `mutation CreateSymptomInstance($input: CreateSymptomInstanceInput!, $condition: ModelSymptomInstanceConditionInput) {
        createSymptomInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSymptomInstanceMutation>response.data.createSymptomInstance;
  }
  async UpdateSymptomInstance(
    input: UpdateSymptomInstanceInput,
    condition?: ModelSymptomInstanceConditionInput
  ): Promise<UpdateSymptomInstanceMutation> {
    const statement = `mutation UpdateSymptomInstance($input: UpdateSymptomInstanceInput!, $condition: ModelSymptomInstanceConditionInput) {
        updateSymptomInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSymptomInstanceMutation>response.data.updateSymptomInstance;
  }
  async DeleteSymptomInstance(
    input: DeleteSymptomInstanceInput,
    condition?: ModelSymptomInstanceConditionInput
  ): Promise<DeleteSymptomInstanceMutation> {
    const statement = `mutation DeleteSymptomInstance($input: DeleteSymptomInstanceInput!, $condition: ModelSymptomInstanceConditionInput) {
        deleteSymptomInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSymptomInstanceMutation>response.data.deleteSymptomInstance;
  }
  async CreateMedicationInstance(
    input: CreateMedicationInstanceInput,
    condition?: ModelMedicationInstanceConditionInput
  ): Promise<CreateMedicationInstanceMutation> {
    const statement = `mutation CreateMedicationInstance($input: CreateMedicationInstanceInput!, $condition: ModelMedicationInstanceConditionInput) {
        createMedicationInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMedicationInstanceMutation>(
      response.data.createMedicationInstance
    );
  }
  async UpdateMedicationInstance(
    input: UpdateMedicationInstanceInput,
    condition?: ModelMedicationInstanceConditionInput
  ): Promise<UpdateMedicationInstanceMutation> {
    const statement = `mutation UpdateMedicationInstance($input: UpdateMedicationInstanceInput!, $condition: ModelMedicationInstanceConditionInput) {
        updateMedicationInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMedicationInstanceMutation>(
      response.data.updateMedicationInstance
    );
  }
  async DeleteMedicationInstance(
    input: DeleteMedicationInstanceInput,
    condition?: ModelMedicationInstanceConditionInput
  ): Promise<DeleteMedicationInstanceMutation> {
    const statement = `mutation DeleteMedicationInstance($input: DeleteMedicationInstanceInput!, $condition: ModelMedicationInstanceConditionInput) {
        deleteMedicationInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMedicationInstanceMutation>(
      response.data.deleteMedicationInstance
    );
  }
  async CreateMedicalAttentionInstance(
    input: CreateMedicalAttentionInstanceInput,
    condition?: ModelMedicalAttentionInstanceConditionInput
  ): Promise<CreateMedicalAttentionInstanceMutation> {
    const statement = `mutation CreateMedicalAttentionInstance($input: CreateMedicalAttentionInstanceInput!, $condition: ModelMedicalAttentionInstanceConditionInput) {
        createMedicalAttentionInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMedicalAttentionInstanceMutation>(
      response.data.createMedicalAttentionInstance
    );
  }
  async UpdateMedicalAttentionInstance(
    input: UpdateMedicalAttentionInstanceInput,
    condition?: ModelMedicalAttentionInstanceConditionInput
  ): Promise<UpdateMedicalAttentionInstanceMutation> {
    const statement = `mutation UpdateMedicalAttentionInstance($input: UpdateMedicalAttentionInstanceInput!, $condition: ModelMedicalAttentionInstanceConditionInput) {
        updateMedicalAttentionInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMedicalAttentionInstanceMutation>(
      response.data.updateMedicalAttentionInstance
    );
  }
  async DeleteMedicalAttentionInstance(
    input: DeleteMedicalAttentionInstanceInput,
    condition?: ModelMedicalAttentionInstanceConditionInput
  ): Promise<DeleteMedicalAttentionInstanceMutation> {
    const statement = `mutation DeleteMedicalAttentionInstance($input: DeleteMedicalAttentionInstanceInput!, $condition: ModelMedicalAttentionInstanceConditionInput) {
        deleteMedicalAttentionInstance(input: $input, condition: $condition) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMedicalAttentionInstanceMutation>(
      response.data.deleteMedicalAttentionInstance
    );
  }
  async CreateTemperatureRecordLog(
    input: CreateTemperatureRecordLogInput,
    condition?: ModelTemperatureRecordLogConditionInput
  ): Promise<CreateTemperatureRecordLogMutation> {
    const statement = `mutation CreateTemperatureRecordLog($input: CreateTemperatureRecordLogInput!, $condition: ModelTemperatureRecordLogConditionInput) {
        createTemperatureRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTemperatureRecordLogMutation>(
      response.data.createTemperatureRecordLog
    );
  }
  async UpdateTemperatureRecordLog(
    input: UpdateTemperatureRecordLogInput,
    condition?: ModelTemperatureRecordLogConditionInput
  ): Promise<UpdateTemperatureRecordLogMutation> {
    const statement = `mutation UpdateTemperatureRecordLog($input: UpdateTemperatureRecordLogInput!, $condition: ModelTemperatureRecordLogConditionInput) {
        updateTemperatureRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTemperatureRecordLogMutation>(
      response.data.updateTemperatureRecordLog
    );
  }
  async DeleteTemperatureRecordLog(
    input: DeleteTemperatureRecordLogInput,
    condition?: ModelTemperatureRecordLogConditionInput
  ): Promise<DeleteTemperatureRecordLogMutation> {
    const statement = `mutation DeleteTemperatureRecordLog($input: DeleteTemperatureRecordLogInput!, $condition: ModelTemperatureRecordLogConditionInput) {
        deleteTemperatureRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTemperatureRecordLogMutation>(
      response.data.deleteTemperatureRecordLog
    );
  }
  async CreateMediaRecordLog(
    input: CreateMediaRecordLogInput,
    condition?: ModelMediaRecordLogConditionInput
  ): Promise<CreateMediaRecordLogMutation> {
    const statement = `mutation CreateMediaRecordLog($input: CreateMediaRecordLogInput!, $condition: ModelMediaRecordLogConditionInput) {
        createMediaRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMediaRecordLogMutation>response.data.createMediaRecordLog;
  }
  async UpdateMediaRecordLog(
    input: UpdateMediaRecordLogInput,
    condition?: ModelMediaRecordLogConditionInput
  ): Promise<UpdateMediaRecordLogMutation> {
    const statement = `mutation UpdateMediaRecordLog($input: UpdateMediaRecordLogInput!, $condition: ModelMediaRecordLogConditionInput) {
        updateMediaRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMediaRecordLogMutation>response.data.updateMediaRecordLog;
  }
  async DeleteMediaRecordLog(
    input: DeleteMediaRecordLogInput,
    condition?: ModelMediaRecordLogConditionInput
  ): Promise<DeleteMediaRecordLogMutation> {
    const statement = `mutation DeleteMediaRecordLog($input: DeleteMediaRecordLogInput!, $condition: ModelMediaRecordLogConditionInput) {
        deleteMediaRecordLog(input: $input, condition: $condition) {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMediaRecordLogMutation>response.data.deleteMediaRecordLog;
  }
  async CreateAlertInstance(
    input: CreateAlertInstanceInput,
    condition?: ModelAlertInstanceConditionInput
  ): Promise<CreateAlertInstanceMutation> {
    const statement = `mutation CreateAlertInstance($input: CreateAlertInstanceInput!, $condition: ModelAlertInstanceConditionInput) {
        createAlertInstance(input: $input, condition: $condition) {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAlertInstanceMutation>response.data.createAlertInstance;
  }
  async UpdateAlertInstance(
    input: UpdateAlertInstanceInput,
    condition?: ModelAlertInstanceConditionInput
  ): Promise<UpdateAlertInstanceMutation> {
    const statement = `mutation UpdateAlertInstance($input: UpdateAlertInstanceInput!, $condition: ModelAlertInstanceConditionInput) {
        updateAlertInstance(input: $input, condition: $condition) {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAlertInstanceMutation>response.data.updateAlertInstance;
  }
  async DeleteAlertInstance(
    input: DeleteAlertInstanceInput,
    condition?: ModelAlertInstanceConditionInput
  ): Promise<DeleteAlertInstanceMutation> {
    const statement = `mutation DeleteAlertInstance($input: DeleteAlertInstanceInput!, $condition: ModelAlertInstanceConditionInput) {
        deleteAlertInstance(input: $input, condition: $condition) {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAlertInstanceMutation>response.data.deleteAlertInstance;
  }
  async CreatePDFAndAlertsTransactionLog(
    input: CreatePDFAndAlertsTransactionLogInput,
    condition?: ModelPDFAndAlertsTransactionLogConditionInput
  ): Promise<CreatePDFAndAlertsTransactionLogMutation> {
    const statement = `mutation CreatePDFAndAlertsTransactionLog($input: CreatePDFAndAlertsTransactionLogInput!, $condition: ModelPDFAndAlertsTransactionLogConditionInput) {
        createPDFAndAlertsTransactionLog(input: $input, condition: $condition) {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePDFAndAlertsTransactionLogMutation>(
      response.data.createPDFAndAlertsTransactionLog
    );
  }
  async UpdatePDFAndAlertsTransactionLog(
    input: UpdatePDFAndAlertsTransactionLogInput,
    condition?: ModelPDFAndAlertsTransactionLogConditionInput
  ): Promise<UpdatePDFAndAlertsTransactionLogMutation> {
    const statement = `mutation UpdatePDFAndAlertsTransactionLog($input: UpdatePDFAndAlertsTransactionLogInput!, $condition: ModelPDFAndAlertsTransactionLogConditionInput) {
        updatePDFAndAlertsTransactionLog(input: $input, condition: $condition) {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePDFAndAlertsTransactionLogMutation>(
      response.data.updatePDFAndAlertsTransactionLog
    );
  }
  async DeletePDFAndAlertsTransactionLog(
    input: DeletePDFAndAlertsTransactionLogInput,
    condition?: ModelPDFAndAlertsTransactionLogConditionInput
  ): Promise<DeletePDFAndAlertsTransactionLogMutation> {
    const statement = `mutation DeletePDFAndAlertsTransactionLog($input: DeletePDFAndAlertsTransactionLogInput!, $condition: ModelPDFAndAlertsTransactionLogConditionInput) {
        deletePDFAndAlertsTransactionLog(input: $input, condition: $condition) {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePDFAndAlertsTransactionLogMutation>(
      response.data.deletePDFAndAlertsTransactionLog
    );
  }
  async Tp2UserCreate(
    user: TP2UserInput,
    projects?: Array<TP2IdEntity>,
    sites?: Array<TP2IdEntity>,
    subjects?: Array<TP2IdEntity>,
    roles?: Array<TP2IdEntity | null>
  ): Promise<string | null> {
    const statement = `query Tp2UserCreate($user: TP2UserInput!, $projects: [TP2IdEntity!], $sites: [TP2IdEntity!], $subjects: [TP2IdEntity!], $roles: [TP2IdEntity]) {
        tp2UserCreate(user: $user, projects: $projects, sites: $sites, subjects: $subjects, roles: $roles)
      }`;
    const gqlAPIServiceArguments: any = {
      user
    };
    if (projects) {
      gqlAPIServiceArguments.projects = projects;
    }
    if (sites) {
      gqlAPIServiceArguments.sites = sites;
    }
    if (subjects) {
      gqlAPIServiceArguments.subjects = subjects;
    }
    if (roles) {
      gqlAPIServiceArguments.roles = roles;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2UserCreate;
  }
  async Tp2TermsAndPolicies(version?: string): Promise<string | null> {
    const statement = `query Tp2TermsAndPolicies($version: String) {
        tp2TermsAndPolicies(version: $version)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (version) {
      gqlAPIServiceArguments.version = version;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2TermsAndPolicies;
  }
  async Tp2UserDelete(user: TP2UserInput): Promise<string | null> {
    const statement = `query Tp2UserDelete($user: TP2UserInput!) {
        tp2UserDelete(user: $user)
      }`;
    const gqlAPIServiceArguments: any = {
      user
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2UserDelete;
  }
  async Tp2UserEnabled(user: TP2UserInput): Promise<string | null> {
    const statement = `query Tp2UserEnabled($user: TP2UserInput!) {
        tp2UserEnabled(user: $user)
      }`;
    const gqlAPIServiceArguments: any = {
      user
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2UserEnabled;
  }
  async Tp2UserDisabled(user: TP2UserInput): Promise<string | null> {
    const statement = `query Tp2UserDisabled($user: TP2UserInput!) {
        tp2UserDisabled(user: $user)
      }`;
    const gqlAPIServiceArguments: any = {
      user
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2UserDisabled;
  }
  async Tp2UserEdit(
    user: TP2UserInput,
    projects?: Array<TP2IdEntity>,
    sites?: Array<TP2IdEntity>,
    subjects?: Array<TP2IdEntity>,
    roles?: Array<TP2IdEntity | null>,
    mfaConfiguration?: TP2MFAConfiguration
  ): Promise<string | null> {
    const statement = `query Tp2UserEdit($user: TP2UserInput!, $projects: [TP2IdEntity!], $sites: [TP2IdEntity!], $subjects: [TP2IdEntity!], $roles: [TP2IdEntity], $mfaConfiguration: TP2MFAConfiguration) {
        tp2UserEdit(user: $user, projects: $projects, sites: $sites, subjects: $subjects, roles: $roles, mfaConfiguration: $mfaConfiguration)
      }`;
    const gqlAPIServiceArguments: any = {
      user
    };
    if (projects) {
      gqlAPIServiceArguments.projects = projects;
    }
    if (sites) {
      gqlAPIServiceArguments.sites = sites;
    }
    if (subjects) {
      gqlAPIServiceArguments.subjects = subjects;
    }
    if (roles) {
      gqlAPIServiceArguments.roles = roles;
    }
    if (mfaConfiguration) {
      gqlAPIServiceArguments.mfaConfiguration = mfaConfiguration;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2UserEdit;
  }
  async Tp2SubjectCompleteVisit(
    subjectNumber: string,
    projectCode: string,
    date: string,
    phase: string
  ): Promise<string | null> {
    const statement = `query Tp2SubjectCompleteVisit($subjectNumber: String!, $projectCode: String!, $date: String!, $phase: String!) {
        tp2SubjectCompleteVisit(subjectNumber: $subjectNumber, projectCode: $projectCode, date: $date, phase: $phase)
      }`;
    const gqlAPIServiceArguments: any = {
      subjectNumber,
      projectCode,
      date,
      phase
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2SubjectCompleteVisit;
  }
  async Tp2SubjectEnroll(
    subject: TP2SubjectInput,
    users: Array<TP2UserInput | null>,
    project: TP2IdEntity,
    site: TP2IdEntity
  ): Promise<string | null> {
    const statement = `query Tp2SubjectEnroll($subject: TP2SubjectInput!, $users: [TP2UserInput]!, $project: TP2IdEntity!, $site: TP2IdEntity!) {
        tp2SubjectEnroll(subject: $subject, users: $users, project: $project, site: $site)
      }`;
    const gqlAPIServiceArguments: any = {
      subject,
      users,
      project,
      site
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2SubjectEnroll;
  }
  async Tp2AlertTrigger(
    reportInstanceId?: string,
    dayInstanceId?: string
  ): Promise<string | null> {
    const statement = `query Tp2AlertTrigger($reportInstanceId: String, $dayInstanceId: String) {
        tp2AlertTrigger(reportInstanceId: $reportInstanceId, dayInstanceId: $dayInstanceId)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (reportInstanceId) {
      gqlAPIServiceArguments.reportInstanceId = reportInstanceId;
    }
    if (dayInstanceId) {
      gqlAPIServiceArguments.dayInstanceId = dayInstanceId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2AlertTrigger;
  }
  async Tp2SubjectChangeState(
    subjectNumber: string,
    projectCode: string,
    state: string,
    _changeReason?: string
  ): Promise<string | null> {
    const statement = `query Tp2SubjectChangeState($subjectNumber: String!, $projectCode: String!, $state: String!, $_changeReason: String) {
        tp2SubjectChangeState(subjectNumber: $subjectNumber, projectCode: $projectCode, state: $state, _changeReason: $_changeReason)
      }`;
    const gqlAPIServiceArguments: any = {
      subjectNumber,
      projectCode,
      state
    };
    if (_changeReason) {
      gqlAPIServiceArguments._changeReason = _changeReason;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2SubjectChangeState;
  }
  async Tp2ForceChangePassword(user: TP2UserInput): Promise<string | null> {
    const statement = `query Tp2ForceChangePassword($user: TP2UserInput!) {
        tp2ForceChangePassword(user: $user)
      }`;
    const gqlAPIServiceArguments: any = {
      user
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2ForceChangePassword;
  }
  async Tp2SubjectChangeGroup(
    subjectNumber: string,
    projectCode: string,
    group: string,
    _changeReason?: string
  ): Promise<string | null> {
    const statement = `query Tp2SubjectChangeGroup($subjectNumber: String!, $projectCode: String!, $group: String!, $_changeReason: String) {
        tp2SubjectChangeGroup(subjectNumber: $subjectNumber, projectCode: $projectCode, group: $group, _changeReason: $_changeReason)
      }`;
    const gqlAPIServiceArguments: any = {
      subjectNumber,
      projectCode,
      group
    };
    if (_changeReason) {
      gqlAPIServiceArguments._changeReason = _changeReason;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2SubjectChangeGroup;
  }
  async Tp2CreateEDiaryCardPDF(
    subject: TP2SubjectDiaryCard,
    version?: string
  ): Promise<string | null> {
    const statement = `query Tp2CreateEDiaryCardPDF($subject: TP2SubjectDiaryCard!, $version: String) {
        tp2CreateEDiaryCardPDF(subject: $subject, version: $version)
      }`;
    const gqlAPIServiceArguments: any = {
      subject
    };
    if (version) {
      gqlAPIServiceArguments.version = version;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2CreateEDiaryCardPDF;
  }
  async Tp2CreateReportCardPDF(
    subjectId?: string,
    confReportId?: string,
    reportInstanceId?: string,
    version?: string
  ): Promise<string | null> {
    const statement = `query Tp2CreateReportCardPDF($subjectId: String, $confReportId: String, $reportInstanceId: String, $version: String) {
        tp2CreateReportCardPDF(subjectId: $subjectId, confReportId: $confReportId, reportInstanceId: $reportInstanceId, version: $version)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (subjectId) {
      gqlAPIServiceArguments.subjectId = subjectId;
    }
    if (confReportId) {
      gqlAPIServiceArguments.confReportId = confReportId;
    }
    if (reportInstanceId) {
      gqlAPIServiceArguments.reportInstanceId = reportInstanceId;
    }
    if (version) {
      gqlAPIServiceArguments.version = version;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2CreateReportCardPDF;
  }
  async Tp2CreateEDiaryConciliationPDF(
    subject: TP2SubjectDiaryCard,
    version?: string
  ): Promise<string | null> {
    const statement = `query Tp2CreateEDiaryConciliationPDF($subject: TP2SubjectDiaryCard!, $version: String) {
        tp2CreateEDiaryConciliationPDF(subject: $subject, version: $version)
      }`;
    const gqlAPIServiceArguments: any = {
      subject
    };
    if (version) {
      gqlAPIServiceArguments.version = version;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2CreateEDiaryConciliationPDF;
  }
  async Tp2SubjectDelete(
    subjectNumber: string,
    projectCode: string,
    _lastUser?: string,
    _changeReason?: string
  ): Promise<string | null> {
    const statement = `query Tp2SubjectDelete($subjectNumber: String!, $projectCode: String!, $_lastUser: String, $_changeReason: String) {
        tp2SubjectDelete(subjectNumber: $subjectNumber, projectCode: $projectCode, _lastUser: $_lastUser, _changeReason: $_changeReason)
      }`;
    const gqlAPIServiceArguments: any = {
      subjectNumber,
      projectCode
    };
    if (_lastUser) {
      gqlAPIServiceArguments._lastUser = _lastUser;
    }
    if (_changeReason) {
      gqlAPIServiceArguments._changeReason = _changeReason;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2SubjectDelete;
  }
  async Tp2SendProjectAdminNotification(
    input: tp2SendProjectAdminNotificationInput
  ): Promise<string | null> {
    const statement = `query Tp2SendProjectAdminNotification($input: tp2SendProjectAdminNotificationInput!) {
        tp2SendProjectAdminNotification(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2SendProjectAdminNotification;
  }
  async Tp2ValidateAndCompleteTransaction(
    input: tp2ValidateAndCompleteTransactionInput
  ): Promise<string | null> {
    const statement = `query Tp2ValidateAndCompleteTransaction($input: tp2ValidateAndCompleteTransactionInput!) {
        tp2ValidateAndCompleteTransaction(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2ValidateAndCompleteTransaction;
  }
  async Tp2ManagePDFAndAlertLog(
    input: tp2ManagePDFAndAlertLogInput
  ): Promise<string | null> {
    const statement = `query Tp2ManagePDFAndAlertLog($input: tp2ManagePDFAndAlertLogInput!) {
        tp2ManagePDFAndAlertLog(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2ManagePDFAndAlertLog;
  }
  async Tp2EDiarySuspension(
    eDiaryPhaseInstanceId: string,
    suspensionDate: string,
    suspensionReason?: string
  ): Promise<string | null> {
    const statement = `query Tp2EDiarySuspension($eDiaryPhaseInstanceId: String!, $suspensionReason: String, $suspensionDate: String!) {
        tp2EDiarySuspension(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, suspensionReason: $suspensionReason, suspensionDate: $suspensionDate)
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId,
      suspensionDate
    };
    if (suspensionReason) {
      gqlAPIServiceArguments.suspensionReason = suspensionReason;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2EDiarySuspension;
  }
  async Tp2CreateEConsentPDF(
    informedConsentInstanceId: string,
    consentedUsersEmails?: string
  ): Promise<string | null> {
    const statement = `query Tp2CreateEConsentPDF($informedConsentInstanceId: String!, $consentedUsersEmails: String) {
        tp2CreateEConsentPDF(informedConsentInstanceId: $informedConsentInstanceId, consentedUsersEmails: $consentedUsersEmails)
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (consentedUsersEmails) {
      gqlAPIServiceArguments.consentedUsersEmails = consentedUsersEmails;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tp2CreateEConsentPDF;
  }
  async GetAuditTrace(id: string): Promise<GetAuditTraceQuery> {
    const statement = `query GetAuditTrace($id: ID!) {
        getAuditTrace(id: $id) {
          __typename
          id
          relatedEntityId
          entity
          projectId
          eventDateTime
          eventType
          _lastUser
          _changeReason
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAuditTraceQuery>response.data.getAuditTrace;
  }
  async ListAuditTraces(
    filter?: ModelAuditTraceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAuditTracesQuery> {
    const statement = `query ListAuditTraces($filter: ModelAuditTraceFilterInput, $limit: Int, $nextToken: String) {
        listAuditTraces(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            relatedEntityId
            entity
            projectId
            eventDateTime
            eventType
            _lastUser
            _changeReason
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAuditTracesQuery>response.data.listAuditTraces;
  }
  async SyncAuditTraces(
    filter?: ModelAuditTraceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncAuditTracesQuery> {
    const statement = `query SyncAuditTraces($filter: ModelAuditTraceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncAuditTraces(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            relatedEntityId
            entity
            projectId
            eventDateTime
            eventType
            _lastUser
            _changeReason
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncAuditTracesQuery>response.data.syncAuditTraces;
  }
  async GetAuditInfo(
    entity: string,
    relatedEntityId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuditTraceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GetAuditInfoQuery> {
    const statement = `query GetAuditInfo($entity: String!, $relatedEntityId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAuditTraceFilterInput, $limit: Int, $nextToken: String) {
        getAuditInfo(entity: $entity, relatedEntityId: $relatedEntityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            relatedEntityId
            entity
            projectId
            eventDateTime
            eventType
            _lastUser
            _changeReason
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entity
    };
    if (relatedEntityId) {
      gqlAPIServiceArguments.relatedEntityId = relatedEntityId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAuditInfoQuery>response.data.getAuditInfo;
  }
  async GetUserLoginAttempts(id: string): Promise<GetUserLoginAttemptsQuery> {
    const statement = `query GetUserLoginAttempts($id: ID!) {
        getUserLoginAttempts(id: $id) {
          __typename
          login
          attempts
          lastAttempt
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserLoginAttemptsQuery>response.data.getUserLoginAttempts;
  }
  async ListUserLoginAttempts(
    filter?: ModelUserLoginAttemptsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserLoginAttemptsQuery> {
    const statement = `query ListUserLoginAttempts($filter: ModelUserLoginAttemptsFilterInput, $limit: Int, $nextToken: String) {
        listUserLoginAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            login
            attempts
            lastAttempt
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserLoginAttemptsQuery>response.data.listUserLoginAttempts;
  }
  async SyncUserLoginAttempts(
    filter?: ModelUserLoginAttemptsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncUserLoginAttemptsQuery> {
    const statement = `query SyncUserLoginAttempts($filter: ModelUserLoginAttemptsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncUserLoginAttempts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            login
            attempts
            lastAttempt
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncUserLoginAttemptsQuery>response.data.syncUserLoginAttempts;
  }
  async GetBackupInstance(id: string): Promise<GetBackupInstanceQuery> {
    const statement = `query GetBackupInstance($id: ID!) {
        getBackupInstance(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBackupInstanceQuery>response.data.getBackupInstance;
  }
  async ListBackupInstances(
    filter?: ModelBackupInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBackupInstancesQuery> {
    const statement = `query ListBackupInstances($filter: ModelBackupInstanceFilterInput, $limit: Int, $nextToken: String) {
        listBackupInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            subjectId
            id
            data
            instanceName
            STORAGETYPE
            gps
            appVersion
            userIP
            deviceModel
            _lastUser
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBackupInstancesQuery>response.data.listBackupInstances;
  }
  async SyncBackupInstances(
    filter?: ModelBackupInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncBackupInstancesQuery> {
    const statement = `query SyncBackupInstances($filter: ModelBackupInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncBackupInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            siteId
            subjectId
            id
            data
            instanceName
            STORAGETYPE
            gps
            appVersion
            userIP
            deviceModel
            _lastUser
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncBackupInstancesQuery>response.data.syncBackupInstances;
  }
  async BackupInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelBackupInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BackupInstanceBySubjectIdQuery> {
    const statement = `query BackupInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelBackupInstanceFilterInput, $limit: Int, $nextToken: String) {
        BackupInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            subjectId
            id
            data
            instanceName
            STORAGETYPE
            gps
            appVersion
            userIP
            deviceModel
            _lastUser
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BackupInstanceBySubjectIdQuery>(
      response.data.BackupInstanceBySubjectId
    );
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async SyncUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncUsersQuery> {
    const statement = `query SyncUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncUsersQuery>response.data.syncUsers;
  }
  async UserByLogin(
    login: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByLoginQuery> {
    const statement = `query UserByLogin($login: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByLogin(login: $login, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      login
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByLoginQuery>response.data.userByLogin;
  }
  async UserByEmail(
    email: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByEmailQuery> {
    const statement = `query UserByEmail($email: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByEmail(email: $email, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByEmailQuery>response.data.userByEmail;
  }
  async UserByProject(
    projects: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByProjectQuery> {
    const statement = `query UserByProject($projects: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByProject(projects: $projects, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projects
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByProjectQuery>response.data.userByProject;
  }
  async GetRole(id: string): Promise<GetRoleQuery> {
    const statement = `query GetRole($id: ID!) {
        getRole(id: $id) {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRoleQuery>response.data.getRole;
  }
  async ListRoles(
    filter?: ModelRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRolesQuery> {
    const statement = `query ListRoles($filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
        listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            isEnabled
            state
            permissions
            isForProjects
            projects
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRolesQuery>response.data.listRoles;
  }
  async SyncRoles(
    filter?: ModelRoleFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncRolesQuery> {
    const statement = `query SyncRoles($filter: ModelRoleFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncRoles(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            name
            isEnabled
            state
            permissions
            isForProjects
            projects
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncRolesQuery>response.data.syncRoles;
  }
  async GetConfChatUser(id: string): Promise<GetConfChatUserQuery> {
    const statement = `query GetConfChatUser($id: ID!) {
        getConfChatUser(id: $id) {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfChatUserQuery>response.data.getConfChatUser;
  }
  async ListConfChatUsers(
    filter?: ModelConfChatUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfChatUsersQuery> {
    const statement = `query ListConfChatUsers($filter: ModelConfChatUserFilterInput, $limit: Int, $nextToken: String) {
        listConfChatUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            userId
            siteId
            projectId
            id
            isChatActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfChatUsersQuery>response.data.listConfChatUsers;
  }
  async SyncConfChatUsers(
    filter?: ModelConfChatUserFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfChatUsersQuery> {
    const statement = `query SyncConfChatUsers($filter: ModelConfChatUserFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfChatUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            userId
            siteId
            projectId
            id
            isChatActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfChatUsersQuery>response.data.syncConfChatUsers;
  }
  async ConfCharUserByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfChatUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfCharUserByUserIdQuery> {
    const statement = `query ConfCharUserByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfChatUserFilterInput, $limit: Int, $nextToken: String) {
        ConfCharUserByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            userId
            siteId
            projectId
            id
            isChatActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfCharUserByUserIdQuery>response.data.ConfCharUserByUserId;
  }
  async ConfCharUserByProjectId(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfChatUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfCharUserByProjectIdQuery> {
    const statement = `query ConfCharUserByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfChatUserFilterInput, $limit: Int, $nextToken: String) {
        ConfCharUserByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            userId
            siteId
            projectId
            id
            isChatActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfCharUserByProjectIdQuery>response.data.ConfCharUserByProjectId;
  }
  async GetChatMessage(id: string): Promise<GetChatMessageQuery> {
    const statement = `query GetChatMessage($id: ID!) {
        getChatMessage(id: $id) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetChatMessageQuery>response.data.getChatMessage;
  }
  async ListChatMessages(
    filter?: ModelChatMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListChatMessagesQuery> {
    const statement = `query ListChatMessages($filter: ModelChatMessageFilterInput, $limit: Int, $nextToken: String) {
        listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            siteId
            userId
            message
            sender
            senderAlias
            createdAt
            _lastUser
            _changeReason
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChatMessagesQuery>response.data.listChatMessages;
  }
  async SyncChatMessages(
    filter?: ModelChatMessageFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncChatMessagesQuery> {
    const statement = `query SyncChatMessages($filter: ModelChatMessageFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            siteId
            userId
            message
            sender
            senderAlias
            createdAt
            _lastUser
            _changeReason
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncChatMessagesQuery>response.data.syncChatMessages;
  }
  async ChatMessagesBySiteId(
    siteId: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelChatMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ChatMessagesBySiteIdQuery> {
    const statement = `query ChatMessagesBySiteId($siteId: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatMessageFilterInput, $limit: Int, $nextToken: String) {
        ChatMessagesBySiteId(siteId: $siteId, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            siteId
            userId
            message
            sender
            senderAlias
            createdAt
            _lastUser
            _changeReason
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChatMessagesBySiteIdQuery>response.data.ChatMessagesBySiteId;
  }
  async ChatMessagesByUserId(
    userId: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelChatMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ChatMessagesByUserIdQuery> {
    const statement = `query ChatMessagesByUserId($userId: ID!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelChatMessageFilterInput, $limit: Int, $nextToken: String) {
        ChatMessagesByUserId(userId: $userId, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            siteId
            userId
            message
            sender
            senderAlias
            createdAt
            _lastUser
            _changeReason
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChatMessagesByUserIdQuery>response.data.ChatMessagesByUserId;
  }
  async GetVerifiedDayInstance(
    id: string
  ): Promise<GetVerifiedDayInstanceQuery> {
    const statement = `query GetVerifiedDayInstance($id: ID!) {
        getVerifiedDayInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVerifiedDayInstanceQuery>response.data.getVerifiedDayInstance;
  }
  async ListVerifiedDayInstances(
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVerifiedDayInstancesQuery> {
    const statement = `query ListVerifiedDayInstances($filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        listVerifiedDayInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVerifiedDayInstancesQuery>(
      response.data.listVerifiedDayInstances
    );
  }
  async SyncVerifiedDayInstances(
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVerifiedDayInstancesQuery> {
    const statement = `query SyncVerifiedDayInstances($filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVerifiedDayInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVerifiedDayInstancesQuery>(
      response.data.syncVerifiedDayInstances
    );
  }
  async VerifiedDayInstancesBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedDayInstancesBySubjectIdQuery> {
    const statement = `query VerifiedDayInstancesBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedDayInstancesBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstancesBySubjectIdQuery>(
      response.data.verifiedDayInstancesBySubjectId
    );
  }
  async VerifiedDayInstancesByEDiaryPhaseInstance(
    eDiaryPhaseInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedDayInstancesByEDiaryPhaseInstanceQuery> {
    const statement = `query VerifiedDayInstancesByEDiaryPhaseInstance($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedDayInstancesByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstancesByEDiaryPhaseInstanceQuery>(
      response.data.verifiedDayInstancesByEDiaryPhaseInstance
    );
  }
  async VerifiedDayInstancesByConfDayId(
    confDayId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedDayInstancesByConfDayIdQuery> {
    const statement = `query VerifiedDayInstancesByConfDayId($confDayId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedDayInstancesByConfDayId(confDayId: $confDayId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confDayId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstancesByConfDayIdQuery>(
      response.data.verifiedDayInstancesByConfDayId
    );
  }
  async VerifiedDayInstancesBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedDayInstancesBySiteIdQuery> {
    const statement = `query VerifiedDayInstancesBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedDayInstancesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstancesBySiteIdQuery>(
      response.data.verifiedDayInstancesBySiteId
    );
  }
  async VerifiedDayInstancesByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedDayInstancesByDayInstanceIdQuery> {
    const statement = `query VerifiedDayInstancesByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedDayInstancesByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedDayInstancesByDayInstanceIdQuery>(
      response.data.verifiedDayInstancesByDayInstanceId
    );
  }
  async GetCommentConciliationInstance(
    id: string
  ): Promise<GetCommentConciliationInstanceQuery> {
    const statement = `query GetCommentConciliationInstance($id: ID!) {
        getCommentConciliationInstance(id: $id) {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCommentConciliationInstanceQuery>(
      response.data.getCommentConciliationInstance
    );
  }
  async ListCommentConciliationInstances(
    filter?: ModelCommentConciliationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCommentConciliationInstancesQuery> {
    const statement = `query ListCommentConciliationInstances($filter: ModelCommentConciliationInstanceFilterInput, $limit: Int, $nextToken: String) {
        listCommentConciliationInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            eDiaryPhaseInstanceId
            id
            comment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCommentConciliationInstancesQuery>(
      response.data.listCommentConciliationInstances
    );
  }
  async SyncCommentConciliationInstances(
    filter?: ModelCommentConciliationInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCommentConciliationInstancesQuery> {
    const statement = `query SyncCommentConciliationInstances($filter: ModelCommentConciliationInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCommentConciliationInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            eDiaryPhaseInstanceId
            id
            comment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCommentConciliationInstancesQuery>(
      response.data.syncCommentConciliationInstances
    );
  }
  async CommentConciliationInstanceByEDiaryPhaseInstance(
    eDiaryPhaseInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentConciliationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentConciliationInstanceByEDiaryPhaseInstanceQuery> {
    const statement = `query CommentConciliationInstanceByEDiaryPhaseInstance($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCommentConciliationInstanceFilterInput, $limit: Int, $nextToken: String) {
        commentConciliationInstanceByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            eDiaryPhaseInstanceId
            id
            comment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentConciliationInstanceByEDiaryPhaseInstanceQuery>(
      response.data.commentConciliationInstanceByEDiaryPhaseInstance
    );
  }
  async GetVerifiedSymptomInstance(
    id: string
  ): Promise<GetVerifiedSymptomInstanceQuery> {
    const statement = `query GetVerifiedSymptomInstance($id: ID!) {
        getVerifiedSymptomInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVerifiedSymptomInstanceQuery>(
      response.data.getVerifiedSymptomInstance
    );
  }
  async ListVerifiedSymptomInstances(
    filter?: ModelVerifiedSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVerifiedSymptomInstancesQuery> {
    const statement = `query ListVerifiedSymptomInstances($filter: ModelVerifiedSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        listVerifiedSymptomInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVerifiedSymptomInstancesQuery>(
      response.data.listVerifiedSymptomInstances
    );
  }
  async SyncVerifiedSymptomInstances(
    filter?: ModelVerifiedSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVerifiedSymptomInstancesQuery> {
    const statement = `query SyncVerifiedSymptomInstances($filter: ModelVerifiedSymptomInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVerifiedSymptomInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVerifiedSymptomInstancesQuery>(
      response.data.syncVerifiedSymptomInstances
    );
  }
  async VerifiedSymptomInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedSymptomInstanceBySubjectIdQuery> {
    const statement = `query VerifiedSymptomInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedSymptomInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomInstanceBySubjectIdQuery>(
      response.data.verifiedSymptomInstanceBySubjectId
    );
  }
  async VerifiedSymptomInstanceByDayInstanceId(
    verifiedDayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedSymptomInstanceByDayInstanceIdQuery> {
    const statement = `query VerifiedSymptomInstanceByDayInstanceId($verifiedDayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedSymptomInstanceByDayInstanceId(verifiedDayInstanceId: $verifiedDayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      verifiedDayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomInstanceByDayInstanceIdQuery>(
      response.data.verifiedSymptomInstanceByDayInstanceId
    );
  }
  async VerifiedSymptomInstanceBySymptomInstanceId(
    symptomInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedSymptomInstanceBySymptomInstanceIdQuery> {
    const statement = `query VerifiedSymptomInstanceBySymptomInstanceId($symptomInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        VerifiedSymptomInstanceBySymptomInstanceId(symptomInstanceId: $symptomInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      symptomInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomInstanceBySymptomInstanceIdQuery>(
      response.data.VerifiedSymptomInstanceBySymptomInstanceId
    );
  }
  async GetVerifiedSymptomRecordLog(
    id: string
  ): Promise<GetVerifiedSymptomRecordLogQuery> {
    const statement = `query GetVerifiedSymptomRecordLog($id: ID!) {
        getVerifiedSymptomRecordLog(id: $id) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVerifiedSymptomRecordLogQuery>(
      response.data.getVerifiedSymptomRecordLog
    );
  }
  async ListVerifiedSymptomRecordLogs(
    filter?: ModelVerifiedSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVerifiedSymptomRecordLogsQuery> {
    const statement = `query ListVerifiedSymptomRecordLogs($filter: ModelVerifiedSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        listVerifiedSymptomRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            verifiedSymptomInstanceId
            subjectId
            symptomRecordLogId
            id
            intensity
            intensityComment
            size
            sizeComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVerifiedSymptomRecordLogsQuery>(
      response.data.listVerifiedSymptomRecordLogs
    );
  }
  async SyncVerifiedSymptomRecordLogs(
    filter?: ModelVerifiedSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVerifiedSymptomRecordLogsQuery> {
    const statement = `query SyncVerifiedSymptomRecordLogs($filter: ModelVerifiedSymptomRecordLogFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVerifiedSymptomRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            verifiedSymptomInstanceId
            subjectId
            symptomRecordLogId
            id
            intensity
            intensityComment
            size
            sizeComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVerifiedSymptomRecordLogsQuery>(
      response.data.syncVerifiedSymptomRecordLogs
    );
  }
  async VerifiedSymptomRecordLogsByDayInstanceId(
    verifiedDayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedSymptomRecordLogsByDayInstanceIdQuery> {
    const statement = `query VerifiedSymptomRecordLogsByDayInstanceId($verifiedDayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        verifiedSymptomRecordLogsByDayInstanceId(verifiedDayInstanceId: $verifiedDayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            verifiedSymptomInstanceId
            subjectId
            symptomRecordLogId
            id
            intensity
            intensityComment
            size
            sizeComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      verifiedDayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomRecordLogsByDayInstanceIdQuery>(
      response.data.verifiedSymptomRecordLogsByDayInstanceId
    );
  }
  async VerifiedSymptomRecordLogsByVerifiedSymptomInstanceId(
    verifiedSymptomInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedSymptomRecordLogsByVerifiedSymptomInstanceIdQuery> {
    const statement = `query VerifiedSymptomRecordLogsByVerifiedSymptomInstanceId($verifiedSymptomInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        verifiedSymptomRecordLogsByVerifiedSymptomInstanceId(verifiedSymptomInstanceId: $verifiedSymptomInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            verifiedSymptomInstanceId
            subjectId
            symptomRecordLogId
            id
            intensity
            intensityComment
            size
            sizeComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      verifiedSymptomInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomRecordLogsByVerifiedSymptomInstanceIdQuery>(
      response.data.verifiedSymptomRecordLogsByVerifiedSymptomInstanceId
    );
  }
  async VerifiedSymptomRecordLogsBySymptomRecordLogId(
    symptomRecordLogId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedSymptomRecordLogsBySymptomRecordLogIdQuery> {
    const statement = `query VerifiedSymptomRecordLogsBySymptomRecordLogId($symptomRecordLogId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        verifiedSymptomRecordLogsBySymptomRecordLogId(symptomRecordLogId: $symptomRecordLogId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            verifiedSymptomInstanceId
            subjectId
            symptomRecordLogId
            id
            intensity
            intensityComment
            size
            sizeComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      symptomRecordLogId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedSymptomRecordLogsBySymptomRecordLogIdQuery>(
      response.data.verifiedSymptomRecordLogsBySymptomRecordLogId
    );
  }
  async GetVerifiedMedicationInstance(
    id: string
  ): Promise<GetVerifiedMedicationInstanceQuery> {
    const statement = `query GetVerifiedMedicationInstance($id: ID!) {
        getVerifiedMedicationInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVerifiedMedicationInstanceQuery>(
      response.data.getVerifiedMedicationInstance
    );
  }
  async ListVerifiedMedicationInstances(
    filter?: ModelVerifiedMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVerifiedMedicationInstancesQuery> {
    const statement = `query ListVerifiedMedicationInstances($filter: ModelVerifiedMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        listVerifiedMedicationInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicationInstanceId
            id
            state
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            isOnGoing
            isOnGoingComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            name
            nameComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            givenTo
            givenToComment
            dose
            doseComment
            frequency
            frequencyComment
            administrationRoute
            administrationRouteComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVerifiedMedicationInstancesQuery>(
      response.data.listVerifiedMedicationInstances
    );
  }
  async SyncVerifiedMedicationInstances(
    filter?: ModelVerifiedMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVerifiedMedicationInstancesQuery> {
    const statement = `query SyncVerifiedMedicationInstances($filter: ModelVerifiedMedicationInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVerifiedMedicationInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicationInstanceId
            id
            state
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            isOnGoing
            isOnGoingComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            name
            nameComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            givenTo
            givenToComment
            dose
            doseComment
            frequency
            frequencyComment
            administrationRoute
            administrationRouteComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVerifiedMedicationInstancesQuery>(
      response.data.syncVerifiedMedicationInstances
    );
  }
  async VerifiedMedicationInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedMedicationInstanceBySubjectIdQuery> {
    const statement = `query VerifiedMedicationInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedMedicationInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicationInstanceId
            id
            state
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            isOnGoing
            isOnGoingComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            name
            nameComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            givenTo
            givenToComment
            dose
            doseComment
            frequency
            frequencyComment
            administrationRoute
            administrationRouteComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedMedicationInstanceBySubjectIdQuery>(
      response.data.verifiedMedicationInstanceBySubjectId
    );
  }
  async VerifiedMedicationByDayInstanceId(
    verifiedDayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedMedicationByDayInstanceIdQuery> {
    const statement = `query VerifiedMedicationByDayInstanceId($verifiedDayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedMedicationByDayInstanceId(verifiedDayInstanceId: $verifiedDayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicationInstanceId
            id
            state
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            isOnGoing
            isOnGoingComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            name
            nameComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            givenTo
            givenToComment
            dose
            doseComment
            frequency
            frequencyComment
            administrationRoute
            administrationRouteComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      verifiedDayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedMedicationByDayInstanceIdQuery>(
      response.data.verifiedMedicationByDayInstanceId
    );
  }
  async VerifiedMedicationInstanceByMedicationId(
    medicationInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedMedicationInstanceByMedicationIdQuery> {
    const statement = `query VerifiedMedicationInstanceByMedicationId($medicationInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedMedicationInstanceByMedicationId(medicationInstanceId: $medicationInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicationInstanceId
            id
            state
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            isOnGoing
            isOnGoingComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            name
            nameComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            givenTo
            givenToComment
            dose
            doseComment
            frequency
            frequencyComment
            administrationRoute
            administrationRouteComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      medicationInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedMedicationInstanceByMedicationIdQuery>(
      response.data.verifiedMedicationInstanceByMedicationId
    );
  }
  async GetVerifiedMedicalAttentionInstance(
    id: string
  ): Promise<GetVerifiedMedicalAttentionInstanceQuery> {
    const statement = `query GetVerifiedMedicalAttentionInstance($id: ID!) {
        getVerifiedMedicalAttentionInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVerifiedMedicalAttentionInstanceQuery>(
      response.data.getVerifiedMedicalAttentionInstance
    );
  }
  async ListVerifiedMedicalAttentionInstances(
    filter?: ModelVerifiedMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVerifiedMedicalAttentionInstancesQuery> {
    const statement = `query ListVerifiedMedicalAttentionInstances($filter: ModelVerifiedMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        listVerifiedMedicalAttentionInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicalAttentionInstanceId
            id
            state
            type
            typeComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            wasHospitalized
            wasHospitalizedComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVerifiedMedicalAttentionInstancesQuery>(
      response.data.listVerifiedMedicalAttentionInstances
    );
  }
  async SyncVerifiedMedicalAttentionInstances(
    filter?: ModelVerifiedMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVerifiedMedicalAttentionInstancesQuery> {
    const statement = `query SyncVerifiedMedicalAttentionInstances($filter: ModelVerifiedMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVerifiedMedicalAttentionInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicalAttentionInstanceId
            id
            state
            type
            typeComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            wasHospitalized
            wasHospitalizedComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVerifiedMedicalAttentionInstancesQuery>(
      response.data.syncVerifiedMedicalAttentionInstances
    );
  }
  async VerifiedMedicalAttentionInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedMedicalAttentionInstanceBySubjectIdQuery> {
    const statement = `query VerifiedMedicalAttentionInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedMedicalAttentionInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicalAttentionInstanceId
            id
            state
            type
            typeComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            wasHospitalized
            wasHospitalizedComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedMedicalAttentionInstanceBySubjectIdQuery>(
      response.data.verifiedMedicalAttentionInstanceBySubjectId
    );
  }
  async VerifiedMedicalAttentionByDayInstanceId(
    verifiedDayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedMedicalAttentionByDayInstanceIdQuery> {
    const statement = `query VerifiedMedicalAttentionByDayInstanceId($verifiedDayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        verifiedMedicalAttentionByDayInstanceId(verifiedDayInstanceId: $verifiedDayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            verifiedDayInstanceId
            medicalAttentionInstanceId
            id
            state
            type
            typeComment
            reason
            reasonComment
            symptomsInstances
            symptomsInstancesComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            wasHospitalized
            wasHospitalizedComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      verifiedDayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedMedicalAttentionByDayInstanceIdQuery>(
      response.data.verifiedMedicalAttentionByDayInstanceId
    );
  }
  async GetVerifiedTemperatureRecordLog(
    id: string
  ): Promise<GetVerifiedTemperatureRecordLogQuery> {
    const statement = `query GetVerifiedTemperatureRecordLog($id: ID!) {
        getVerifiedTemperatureRecordLog(id: $id) {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVerifiedTemperatureRecordLogQuery>(
      response.data.getVerifiedTemperatureRecordLog
    );
  }
  async ListVerifiedTemperatureRecordLogs(
    filter?: ModelVerifiedTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVerifiedTemperatureRecordLogsQuery> {
    const statement = `query ListVerifiedTemperatureRecordLogs($filter: ModelVerifiedTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        listVerifiedTemperatureRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            subjectId
            temperatureRecordLogID
            id
            temperature
            temperatureComment
            unit
            unitComment
            route
            routeComment
            whichOtherRoute
            whichOtherRouteComment
            taken
            takenComment
            takenDate
            takenDateComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVerifiedTemperatureRecordLogsQuery>(
      response.data.listVerifiedTemperatureRecordLogs
    );
  }
  async SyncVerifiedTemperatureRecordLogs(
    filter?: ModelVerifiedTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVerifiedTemperatureRecordLogsQuery> {
    const statement = `query SyncVerifiedTemperatureRecordLogs($filter: ModelVerifiedTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVerifiedTemperatureRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            subjectId
            temperatureRecordLogID
            id
            temperature
            temperatureComment
            unit
            unitComment
            route
            routeComment
            whichOtherRoute
            whichOtherRouteComment
            taken
            takenComment
            takenDate
            takenDateComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVerifiedTemperatureRecordLogsQuery>(
      response.data.syncVerifiedTemperatureRecordLogs
    );
  }
  async VerifiedTemperatureRecordLogByVerifiedDayInstanceId(
    verifiedDayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedTemperatureRecordLogByVerifiedDayInstanceIdQuery> {
    const statement = `query VerifiedTemperatureRecordLogByVerifiedDayInstanceId($verifiedDayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        verifiedTemperatureRecordLogByVerifiedDayInstanceId(verifiedDayInstanceId: $verifiedDayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            subjectId
            temperatureRecordLogID
            id
            temperature
            temperatureComment
            unit
            unitComment
            route
            routeComment
            whichOtherRoute
            whichOtherRouteComment
            taken
            takenComment
            takenDate
            takenDateComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      verifiedDayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedTemperatureRecordLogByVerifiedDayInstanceIdQuery>(
      response.data.verifiedTemperatureRecordLogByVerifiedDayInstanceId
    );
  }
  async VerifiedTemperatureRecordLogBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVerifiedTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VerifiedTemperatureRecordLogBySubjectIdQuery> {
    const statement = `query VerifiedTemperatureRecordLogBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelVerifiedTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        verifiedTemperatureRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            verifiedDayInstanceId
            subjectId
            temperatureRecordLogID
            id
            temperature
            temperatureComment
            unit
            unitComment
            route
            routeComment
            whichOtherRoute
            whichOtherRouteComment
            taken
            takenComment
            takenDate
            takenDateComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VerifiedTemperatureRecordLogBySubjectIdQuery>(
      response.data.verifiedTemperatureRecordLogBySubjectId
    );
  }
  async GetProject(id: string): Promise<GetProjectQuery> {
    const statement = `query GetProject($id: ID!) {
        getProject(id: $id) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectQuery>response.data.getProject;
  }
  async ListProjects(
    filter?: ModelProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectsQuery> {
    const statement = `query ListProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectsQuery>response.data.listProjects;
  }
  async SyncProjects(
    filter?: ModelProjectFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncProjectsQuery> {
    const statement = `query SyncProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncProjects(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncProjectsQuery>response.data.syncProjects;
  }
  async GetConfVisitGroup(id: string): Promise<GetConfVisitGroupQuery> {
    const statement = `query GetConfVisitGroup($id: ID!) {
        getConfVisitGroup(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfVisitGroupQuery>response.data.getConfVisitGroup;
  }
  async ListConfVisitGroups(
    filter?: ModelConfVisitGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfVisitGroupsQuery> {
    const statement = `query ListConfVisitGroups($filter: ModelConfVisitGroupFilterInput, $limit: Int, $nextToken: String) {
        listConfVisitGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            visit
            group
            isVisible
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfVisitGroupsQuery>response.data.listConfVisitGroups;
  }
  async SyncConfVisitGroups(
    filter?: ModelConfVisitGroupFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfVisitGroupsQuery> {
    const statement = `query SyncConfVisitGroups($filter: ModelConfVisitGroupFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfVisitGroups(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            id
            visit
            group
            isVisible
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfVisitGroupsQuery>response.data.syncConfVisitGroups;
  }
  async ConfVisitGroupByProjectId(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfVisitGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfVisitGroupByProjectIdQuery> {
    const statement = `query ConfVisitGroupByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfVisitGroupFilterInput, $limit: Int, $nextToken: String) {
        ConfVisitGroupByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            visit
            group
            isVisible
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfVisitGroupByProjectIdQuery>(
      response.data.ConfVisitGroupByProjectId
    );
  }
  async GetSite(id: string): Promise<GetSiteQuery> {
    const statement = `query GetSite($id: ID!) {
        getSite(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteQuery>response.data.getSite;
  }
  async ListSites(
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSitesQuery> {
    const statement = `query ListSites($filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSitesQuery>response.data.listSites;
  }
  async SyncSites(
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSitesQuery> {
    const statement = `query SyncSites($filter: ModelSiteFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSites(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSitesQuery>response.data.syncSites;
  }
  async SitesByProjectId(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SitesByProjectIdQuery> {
    const statement = `query SitesByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        SitesByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SitesByProjectIdQuery>response.data.SitesByProjectId;
  }
  async GetAuthorizedSite(id: string): Promise<GetAuthorizedSiteQuery> {
    const statement = `query GetAuthorizedSite($id: ID!) {
        getAuthorizedSite(id: $id) {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAuthorizedSiteQuery>response.data.getAuthorizedSite;
  }
  async ListAuthorizedSites(
    filter?: ModelAuthorizedSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAuthorizedSitesQuery> {
    const statement = `query ListAuthorizedSites($filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String) {
        listAuthorizedSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            siteId
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAuthorizedSitesQuery>response.data.listAuthorizedSites;
  }
  async SyncAuthorizedSites(
    filter?: ModelAuthorizedSiteFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncAuthorizedSitesQuery> {
    const statement = `query SyncAuthorizedSites($filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncAuthorizedSites(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            userId
            siteId
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncAuthorizedSitesQuery>response.data.syncAuthorizedSites;
  }
  async AuthorizedSitesByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuthorizedSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AuthorizedSitesByUserIdQuery> {
    const statement = `query AuthorizedSitesByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String) {
        AuthorizedSitesByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            siteId
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuthorizedSitesByUserIdQuery>response.data.AuthorizedSitesByUserId;
  }
  async AuthorizedSitesBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuthorizedSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AuthorizedSitesBySiteIdQuery> {
    const statement = `query AuthorizedSitesBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String) {
        AuthorizedSitesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            siteId
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuthorizedSitesBySiteIdQuery>response.data.AuthorizedSitesBySiteId;
  }
  async GetConfReport(id: string): Promise<GetConfReportQuery> {
    const statement = `query GetConfReport($id: ID!) {
        getConfReport(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfReportQuery>response.data.getConfReport;
  }
  async ListConfReports(
    filter?: ModelConfReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfReportsQuery> {
    const statement = `query ListConfReports($filter: ModelConfReportFilterInput, $limit: Int, $nextToken: String) {
        listConfReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfReportsQuery>response.data.listConfReports;
  }
  async SyncConfReports(
    filter?: ModelConfReportFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfReportsQuery> {
    const statement = `query SyncConfReports($filter: ModelConfReportFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfReports(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfReportsQuery>response.data.syncConfReports;
  }
  async ConfReportByProject(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfReportByProjectQuery> {
    const statement = `query ConfReportByProject($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfReportFilterInput, $limit: Int, $nextToken: String) {
        confReportByProject(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfReportByProjectQuery>response.data.confReportByProject;
  }
  async GetConfReportBySubject(
    id: string
  ): Promise<GetConfReportBySubjectQuery> {
    const statement = `query GetConfReportBySubject($id: ID!) {
        getConfReportBySubject(id: $id) {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfReportBySubjectQuery>response.data.getConfReportBySubject;
  }
  async ListConfReportBySubjects(
    filter?: ModelConfReportBySubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfReportBySubjectsQuery> {
    const statement = `query ListConfReportBySubjects($filter: ModelConfReportBySubjectFilterInput, $limit: Int, $nextToken: String) {
        listConfReportBySubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subjectId
            confReportId
            isEnabled
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfReportBySubjectsQuery>(
      response.data.listConfReportBySubjects
    );
  }
  async SyncConfReportBySubjects(
    filter?: ModelConfReportBySubjectFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfReportBySubjectsQuery> {
    const statement = `query SyncConfReportBySubjects($filter: ModelConfReportBySubjectFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfReportBySubjects(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            subjectId
            confReportId
            isEnabled
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfReportBySubjectsQuery>(
      response.data.syncConfReportBySubjects
    );
  }
  async ConfReportBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfReportBySubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfReportBySubjectIdQuery> {
    const statement = `query ConfReportBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfReportBySubjectFilterInput, $limit: Int, $nextToken: String) {
        confReportBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subjectId
            confReportId
            isEnabled
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfReportBySubjectIdQuery>response.data.confReportBySubjectId;
  }
  async GetConfEDiary(id: string): Promise<GetConfEDiaryQuery> {
    const statement = `query GetConfEDiary($id: ID!) {
        getConfEDiary(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfEDiaryQuery>response.data.getConfEDiary;
  }
  async ListConfEDiaries(
    filter?: ModelConfEDiaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfEDiariesQuery> {
    const statement = `query ListConfEDiaries($filter: ModelConfEDiaryFilterInput, $limit: Int, $nextToken: String) {
        listConfEDiaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfEDiariesQuery>response.data.listConfEDiaries;
  }
  async SyncConfEDiaries(
    filter?: ModelConfEDiaryFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfEDiariesQuery> {
    const statement = `query SyncConfEDiaries($filter: ModelConfEDiaryFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfEDiaries(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfEDiariesQuery>response.data.syncConfEDiaries;
  }
  async ConfEdiaryByProject(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfEDiaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfEdiaryByProjectQuery> {
    const statement = `query ConfEdiaryByProject($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfEDiaryFilterInput, $limit: Int, $nextToken: String) {
        confEdiaryByProject(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfEdiaryByProjectQuery>response.data.confEdiaryByProject;
  }
  async GetConfDay(id: string): Promise<GetConfDayQuery> {
    const statement = `query GetConfDay($id: ID!) {
        getConfDay(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfDayQuery>response.data.getConfDay;
  }
  async ListConfDays(
    filter?: ModelConfDayFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfDaysQuery> {
    const statement = `query ListConfDays($filter: ModelConfDayFilterInput, $limit: Int, $nextToken: String) {
        listConfDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfDaysQuery>response.data.listConfDays;
  }
  async SyncConfDays(
    filter?: ModelConfDayFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfDaysQuery> {
    const statement = `query SyncConfDays($filter: ModelConfDayFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfDays(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfDaysQuery>response.data.syncConfDays;
  }
  async ConfDayByConfEdiary(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfDayFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfDayByConfEdiaryQuery> {
    const statement = `query ConfDayByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfDayFilterInput, $limit: Int, $nextToken: String) {
        confDayByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfDayByConfEdiaryQuery>response.data.confDayByConfEdiary;
  }
  async GetConfForm(id: string): Promise<GetConfFormQuery> {
    const statement = `query GetConfForm($id: ID!) {
        getConfForm(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfFormQuery>response.data.getConfForm;
  }
  async ListConfForms(
    filter?: ModelConfFormFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfFormsQuery> {
    const statement = `query ListConfForms($filter: ModelConfFormFilterInput, $limit: Int, $nextToken: String) {
        listConfForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfFormsQuery>response.data.listConfForms;
  }
  async SyncConfForms(
    filter?: ModelConfFormFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfFormsQuery> {
    const statement = `query SyncConfForms($filter: ModelConfFormFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfForms(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfFormsQuery>response.data.syncConfForms;
  }
  async ConfFormByConfEdiaryId(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfFormFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfFormByConfEdiaryIdQuery> {
    const statement = `query ConfFormByConfEdiaryId($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormFilterInput, $limit: Int, $nextToken: String) {
        confFormByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfFormByConfEdiaryIdQuery>response.data.confFormByConfEdiaryId;
  }
  async GetConfSymptom(id: string): Promise<GetConfSymptomQuery> {
    const statement = `query GetConfSymptom($id: ID!) {
        getConfSymptom(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfSymptomQuery>response.data.getConfSymptom;
  }
  async ListConfSymptoms(
    filter?: ModelConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfSymptomsQuery> {
    const statement = `query ListConfSymptoms($filter: ModelConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        listConfSymptoms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfSymptomsQuery>response.data.listConfSymptoms;
  }
  async SyncConfSymptoms(
    filter?: ModelConfSymptomFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfSymptomsQuery> {
    const statement = `query SyncConfSymptoms($filter: ModelConfSymptomFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfSymptoms(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfSymptomsQuery>response.data.syncConfSymptoms;
  }
  async ConfSymptomByConfReportId(
    confReportId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfSymptomByConfReportIdQuery> {
    const statement = `query ConfSymptomByConfReportId($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        confSymptomByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfSymptomByConfReportIdQuery>(
      response.data.confSymptomByConfReportId
    );
  }
  async ConfSymptomByConfEdiaryId(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfSymptomByConfEdiaryIdQuery> {
    const statement = `query ConfSymptomByConfEdiaryId($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        confSymptomByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfSymptomByConfEdiaryIdQuery>(
      response.data.confSymptomByConfEdiaryId
    );
  }
  async GetConfFormConfSymptom(
    id: string
  ): Promise<GetConfFormConfSymptomQuery> {
    const statement = `query GetConfFormConfSymptom($id: ID!) {
        getConfFormConfSymptom(id: $id) {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfFormConfSymptomQuery>response.data.getConfFormConfSymptom;
  }
  async ListConfFormConfSymptoms(
    filter?: ModelConfFormConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfFormConfSymptomsQuery> {
    const statement = `query ListConfFormConfSymptoms($filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        listConfFormConfSymptoms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confFormId
            confSymptomId
            projectId
            id
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfFormConfSymptomsQuery>(
      response.data.listConfFormConfSymptoms
    );
  }
  async SyncConfFormConfSymptoms(
    filter?: ModelConfFormConfSymptomFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfFormConfSymptomsQuery> {
    const statement = `query SyncConfFormConfSymptoms($filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfFormConfSymptoms(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confFormId
            confSymptomId
            projectId
            id
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfFormConfSymptomsQuery>(
      response.data.syncConfFormConfSymptoms
    );
  }
  async ConfSymptomByConfFormId(
    confFormId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfFormConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfSymptomByConfFormIdQuery> {
    const statement = `query ConfSymptomByConfFormId($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        confSymptomByConfFormId(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confFormId
            confSymptomId
            projectId
            id
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfSymptomByConfFormIdQuery>response.data.confSymptomByConfFormId;
  }
  async ConfFormByConfSymptom(
    confSymptomId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfFormConfSymptomFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfFormByConfSymptomQuery> {
    const statement = `query ConfFormByConfSymptom($confSymptomId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        confFormByConfSymptom(confSymptomId: $confSymptomId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confFormId
            confSymptomId
            projectId
            id
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confSymptomId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfFormByConfSymptomQuery>response.data.confFormByConfSymptom;
  }
  async GetConfMedication(id: string): Promise<GetConfMedicationQuery> {
    const statement = `query GetConfMedication($id: ID!) {
        getConfMedication(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfMedicationQuery>response.data.getConfMedication;
  }
  async ListConfMedications(
    filter?: ModelConfMedicationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfMedicationsQuery> {
    const statement = `query ListConfMedications($filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
        listConfMedications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfMedicationsQuery>response.data.listConfMedications;
  }
  async SyncConfMedications(
    filter?: ModelConfMedicationFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfMedicationsQuery> {
    const statement = `query SyncConfMedications($filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfMedications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfMedicationsQuery>response.data.syncConfMedications;
  }
  async ConfMedicationByConfEdiary(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfMedicationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfMedicationByConfEdiaryQuery> {
    const statement = `query ConfMedicationByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
        confMedicationByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMedicationByConfEdiaryQuery>(
      response.data.confMedicationByConfEdiary
    );
  }
  async ConfMedicationByConfForm(
    confFormId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfMedicationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfMedicationByConfFormQuery> {
    const statement = `query ConfMedicationByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
        confMedicationByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMedicationByConfFormQuery>(
      response.data.confMedicationByConfForm
    );
  }
  async GetConfMedicalAttention(
    id: string
  ): Promise<GetConfMedicalAttentionQuery> {
    const statement = `query GetConfMedicalAttention($id: ID!) {
        getConfMedicalAttention(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfMedicalAttentionQuery>response.data.getConfMedicalAttention;
  }
  async ListConfMedicalAttentions(
    filter?: ModelConfMedicalAttentionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfMedicalAttentionsQuery> {
    const statement = `query ListConfMedicalAttentions($filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
        listConfMedicalAttentions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfMedicalAttentionsQuery>(
      response.data.listConfMedicalAttentions
    );
  }
  async SyncConfMedicalAttentions(
    filter?: ModelConfMedicalAttentionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfMedicalAttentionsQuery> {
    const statement = `query SyncConfMedicalAttentions($filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfMedicalAttentions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfMedicalAttentionsQuery>(
      response.data.syncConfMedicalAttentions
    );
  }
  async ConfMedicalAttentionByConfEdiary(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfMedicalAttentionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfMedicalAttentionByConfEdiaryQuery> {
    const statement = `query ConfMedicalAttentionByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
        confMedicalAttentionByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMedicalAttentionByConfEdiaryQuery>(
      response.data.confMedicalAttentionByConfEdiary
    );
  }
  async ConfMedicalAttentionByConfForm(
    confFormId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfMedicalAttentionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfMedicalAttentionByConfFormQuery> {
    const statement = `query ConfMedicalAttentionByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
        confMedicalAttentionByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMedicalAttentionByConfFormQuery>(
      response.data.confMedicalAttentionByConfForm
    );
  }
  async GetConfTemperature(id: string): Promise<GetConfTemperatureQuery> {
    const statement = `query GetConfTemperature($id: ID!) {
        getConfTemperature(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfTemperatureQuery>response.data.getConfTemperature;
  }
  async ListConfTemperatures(
    filter?: ModelConfTemperatureFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfTemperaturesQuery> {
    const statement = `query ListConfTemperatures($filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String) {
        listConfTemperatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfTemperaturesQuery>response.data.listConfTemperatures;
  }
  async SyncConfTemperatures(
    filter?: ModelConfTemperatureFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfTemperaturesQuery> {
    const statement = `query SyncConfTemperatures($filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfTemperatures(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfTemperaturesQuery>response.data.syncConfTemperatures;
  }
  async ConfTemperatureByConfEdiary(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfTemperatureFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfTemperatureByConfEdiaryQuery> {
    const statement = `query ConfTemperatureByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String) {
        confTemperatureByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfTemperatureByConfEdiaryQuery>(
      response.data.confTemperatureByConfEdiary
    );
  }
  async ConfTemperatureByConfForm(
    confFormId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfTemperatureFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfTemperatureByConfFormQuery> {
    const statement = `query ConfTemperatureByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String) {
        confTemperatureByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfTemperatureByConfFormQuery>(
      response.data.confTemperatureByConfForm
    );
  }
  async GetConfMultimedia(id: string): Promise<GetConfMultimediaQuery> {
    const statement = `query GetConfMultimedia($id: ID!) {
        getConfMultimedia(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfMultimediaQuery>response.data.getConfMultimedia;
  }
  async ListConfMultimedias(
    filter?: ModelConfMultimediaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfMultimediasQuery> {
    const statement = `query ListConfMultimedias($filter: ModelConfMultimediaFilterInput, $limit: Int, $nextToken: String) {
        listConfMultimedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showDetail
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfMultimediasQuery>response.data.listConfMultimedias;
  }
  async SyncConfMultimedias(
    filter?: ModelConfMultimediaFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfMultimediasQuery> {
    const statement = `query SyncConfMultimedias($filter: ModelConfMultimediaFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfMultimedias(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showDetail
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfMultimediasQuery>response.data.syncConfMultimedias;
  }
  async ConfMultimediaByConfEdiary(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfMultimediaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfMultimediaByConfEdiaryQuery> {
    const statement = `query ConfMultimediaByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMultimediaFilterInput, $limit: Int, $nextToken: String) {
        confMultimediaByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showDetail
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMultimediaByConfEdiaryQuery>(
      response.data.confMultimediaByConfEdiary
    );
  }
  async ConfMultimediaByConfForm(
    confFormId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfMultimediaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfMultimediaByConfFormQuery> {
    const statement = `query ConfMultimediaByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMultimediaFilterInput, $limit: Int, $nextToken: String) {
        confMultimediaByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showDetail
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfMultimediaByConfFormQuery>(
      response.data.confMultimediaByConfForm
    );
  }
  async GetConfOtherSymptoms(id: string): Promise<GetConfOtherSymptomsQuery> {
    const statement = `query GetConfOtherSymptoms($id: ID!) {
        getConfOtherSymptoms(id: $id) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfOtherSymptomsQuery>response.data.getConfOtherSymptoms;
  }
  async ListConfOtherSymptoms(
    filter?: ModelConfOtherSymptomsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfOtherSymptomsQuery> {
    const statement = `query ListConfOtherSymptoms($filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String) {
        listConfOtherSymptoms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfOtherSymptomsQuery>response.data.listConfOtherSymptoms;
  }
  async SyncConfOtherSymptoms(
    filter?: ModelConfOtherSymptomsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfOtherSymptomsQuery> {
    const statement = `query SyncConfOtherSymptoms($filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfOtherSymptoms(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfOtherSymptomsQuery>response.data.syncConfOtherSymptoms;
  }
  async ConfOtherSymptomsByConfEdiary(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfOtherSymptomsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfOtherSymptomsByConfEdiaryQuery> {
    const statement = `query ConfOtherSymptomsByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String) {
        confOtherSymptomsByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfOtherSymptomsByConfEdiaryQuery>(
      response.data.confOtherSymptomsByConfEdiary
    );
  }
  async ConfOtherSymptomsByConfForm(
    confFormId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfOtherSymptomsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfOtherSymptomsByConfFormQuery> {
    const statement = `query ConfOtherSymptomsByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String) {
        confOtherSymptomsByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confFormId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfOtherSymptomsByConfFormQuery>(
      response.data.confOtherSymptomsByConfForm
    );
  }
  async GetConfAlert(id: string): Promise<GetConfAlertQuery> {
    const statement = `query GetConfAlert($id: ID!) {
        getConfAlert(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfAlertQuery>response.data.getConfAlert;
  }
  async ListConfAlerts(
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfAlertsQuery> {
    const statement = `query ListConfAlerts($filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        listConfAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfAlertsQuery>response.data.listConfAlerts;
  }
  async SyncConfAlerts(
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfAlertsQuery> {
    const statement = `query SyncConfAlerts($filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfAlerts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfAlertsQuery>response.data.syncConfAlerts;
  }
  async ConfAlertByConfReportId(
    confReportId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfAlertByConfReportIdQuery> {
    const statement = `query ConfAlertByConfReportId($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfAlertByConfReportIdQuery>response.data.confAlertByConfReportId;
  }
  async ConfAlertByConfEdiaryId(
    confEdiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfAlertByConfEdiaryIdQuery> {
    const statement = `query ConfAlertByConfEdiaryId($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEdiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfAlertByConfEdiaryIdQuery>response.data.confAlertByConfEdiaryId;
  }
  async ConfAlertByConfSymptomId(
    confSymptomId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfAlertByConfSymptomIdQuery> {
    const statement = `query ConfAlertByConfSymptomId($confSymptomId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfSymptomId(confSymptomId: $confSymptomId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confSymptomId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfAlertByConfSymptomIdQuery>(
      response.data.confAlertByConfSymptomId
    );
  }
  async ConfAlertByConfTemperatureId(
    confTemperatureId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfAlertByConfTemperatureIdQuery> {
    const statement = `query ConfAlertByConfTemperatureId($confTemperatureId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfTemperatureId(confTemperatureId: $confTemperatureId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confTemperatureId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfAlertByConfTemperatureIdQuery>(
      response.data.confAlertByConfTemperatureId
    );
  }
  async GetConfDictionary(id: string): Promise<GetConfDictionaryQuery> {
    const statement = `query GetConfDictionary($id: ID!) {
        getConfDictionary(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfDictionaryQuery>response.data.getConfDictionary;
  }
  async ListConfDictionaries(
    filter?: ModelConfDictionaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfDictionariesQuery> {
    const statement = `query ListConfDictionaries($filter: ModelConfDictionaryFilterInput, $limit: Int, $nextToken: String) {
        listConfDictionaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            key
            spanish
            english
            isHtml
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfDictionariesQuery>response.data.listConfDictionaries;
  }
  async SyncConfDictionaries(
    filter?: ModelConfDictionaryFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfDictionariesQuery> {
    const statement = `query SyncConfDictionaries($filter: ModelConfDictionaryFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfDictionaries(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            id
            key
            spanish
            english
            isHtml
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfDictionariesQuery>response.data.syncConfDictionaries;
  }
  async ConfDictionaryByProjectId(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfDictionaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfDictionaryByProjectIdQuery> {
    const statement = `query ConfDictionaryByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfDictionaryFilterInput, $limit: Int, $nextToken: String) {
        confDictionaryByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            key
            spanish
            english
            isHtml
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfDictionaryByProjectIdQuery>(
      response.data.confDictionaryByProjectId
    );
  }
  async GetConfInformedConsent(
    id: string
  ): Promise<GetConfInformedConsentQuery> {
    const statement = `query GetConfInformedConsent($id: ID!) {
        getConfInformedConsent(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfInformedConsentQuery>response.data.getConfInformedConsent;
  }
  async ListConfInformedConsents(
    filter?: ModelConfInformedConsentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfInformedConsentsQuery> {
    const statement = `query ListConfInformedConsents($filter: ModelConfInformedConsentFilterInput, $limit: Int, $nextToken: String) {
        listConfInformedConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfInformedConsentsQuery>(
      response.data.listConfInformedConsents
    );
  }
  async SyncConfInformedConsents(
    filter?: ModelConfInformedConsentFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfInformedConsentsQuery> {
    const statement = `query SyncConfInformedConsents($filter: ModelConfInformedConsentFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfInformedConsents(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfInformedConsentsQuery>(
      response.data.syncConfInformedConsents
    );
  }
  async ConfInformedConsentByProjectID(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfInformedConsentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfInformedConsentByProjectIDQuery> {
    const statement = `query ConfInformedConsentByProjectID($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfInformedConsentFilterInput, $limit: Int, $nextToken: String) {
        confInformedConsentByProjectID(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfInformedConsentByProjectIDQuery>(
      response.data.confInformedConsentByProjectID
    );
  }
  async GetConfICSection(id: string): Promise<GetConfICSectionQuery> {
    const statement = `query GetConfICSection($id: ID!) {
        getConfICSection(id: $id) {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfICSectionQuery>response.data.getConfICSection;
  }
  async ListConfICSections(
    filter?: ModelConfICSectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfICSectionsQuery> {
    const statement = `query ListConfICSections($filter: ModelConfICSectionFilterInput, $limit: Int, $nextToken: String) {
        listConfICSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfICSectionsQuery>response.data.listConfICSections;
  }
  async SyncConfICSections(
    filter?: ModelConfICSectionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfICSectionsQuery> {
    const statement = `query SyncConfICSections($filter: ModelConfICSectionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfICSections(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfICSectionsQuery>response.data.syncConfICSections;
  }
  async ConfICSectionByConfInformedConsentId(
    confInformedConsentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfICSectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfICSectionByConfInformedConsentIdQuery> {
    const statement = `query ConfICSectionByConfInformedConsentId($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICSectionFilterInput, $limit: Int, $nextToken: String) {
        ConfICSectionByConfInformedConsentId(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICSectionByConfInformedConsentIdQuery>(
      response.data.ConfICSectionByConfInformedConsentId
    );
  }
  async GetConfICQuestion(id: string): Promise<GetConfICQuestionQuery> {
    const statement = `query GetConfICQuestion($id: ID!) {
        getConfICQuestion(id: $id) {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfICQuestionQuery>response.data.getConfICQuestion;
  }
  async ListConfICQuestions(
    filter?: ModelConfICQuestionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfICQuestionsQuery> {
    const statement = `query ListConfICQuestions($filter: ModelConfICQuestionFilterInput, $limit: Int, $nextToken: String) {
        listConfICQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfICQuestionsQuery>response.data.listConfICQuestions;
  }
  async SyncConfICQuestions(
    filter?: ModelConfICQuestionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfICQuestionsQuery> {
    const statement = `query SyncConfICQuestions($filter: ModelConfICQuestionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfICQuestions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfICQuestionsQuery>response.data.syncConfICQuestions;
  }
  async ConfICQuestionByConfICSectionId(
    confICSectionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfICQuestionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfICQuestionByConfICSectionIdQuery> {
    const statement = `query ConfICQuestionByConfICSectionId($confICSectionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICQuestionFilterInput, $limit: Int, $nextToken: String) {
        ConfICQuestionByConfICSectionId(confICSectionId: $confICSectionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confICSectionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICQuestionByConfICSectionIdQuery>(
      response.data.ConfICQuestionByConfICSectionId
    );
  }
  async ConfICQuestionByConfInformedConsentId(
    confInformedConsentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfICQuestionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfICQuestionByConfInformedConsentIdQuery> {
    const statement = `query ConfICQuestionByConfInformedConsentId($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICQuestionFilterInput, $limit: Int, $nextToken: String) {
        ConfICQuestionByConfInformedConsentId(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICQuestionByConfInformedConsentIdQuery>(
      response.data.ConfICQuestionByConfInformedConsentId
    );
  }
  async GetConfICAnswer(id: string): Promise<GetConfICAnswerQuery> {
    const statement = `query GetConfICAnswer($id: ID!) {
        getConfICAnswer(id: $id) {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfICAnswerQuery>response.data.getConfICAnswer;
  }
  async ListConfICAnswers(
    filter?: ModelConfICAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfICAnswersQuery> {
    const statement = `query ListConfICAnswers($filter: ModelConfICAnswerFilterInput, $limit: Int, $nextToken: String) {
        listConfICAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confICQuestionId
            confInformedConsentId
            projectId
            id
            description
            order
            onErrorMessage
            isCorrect
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfICAnswersQuery>response.data.listConfICAnswers;
  }
  async SyncConfICAnswers(
    filter?: ModelConfICAnswerFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConfICAnswersQuery> {
    const statement = `query SyncConfICAnswers($filter: ModelConfICAnswerFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConfICAnswers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confICQuestionId
            confInformedConsentId
            projectId
            id
            description
            order
            onErrorMessage
            isCorrect
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConfICAnswersQuery>response.data.syncConfICAnswers;
  }
  async ConfICAnswerByConfICQuestionId(
    confICQuestionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfICAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfICAnswerByConfICQuestionIdQuery> {
    const statement = `query ConfICAnswerByConfICQuestionId($confICQuestionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICAnswerFilterInput, $limit: Int, $nextToken: String) {
        ConfICAnswerByConfICQuestionId(confICQuestionId: $confICQuestionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confICQuestionId
            confInformedConsentId
            projectId
            id
            description
            order
            onErrorMessage
            isCorrect
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confICQuestionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICAnswerByConfICQuestionIdQuery>(
      response.data.ConfICAnswerByConfICQuestionId
    );
  }
  async ConfICAnswerByConfInformedConsentId(
    confInformedConsentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConfICAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfICAnswerByConfInformedConsentIdQuery> {
    const statement = `query ConfICAnswerByConfInformedConsentId($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICAnswerFilterInput, $limit: Int, $nextToken: String) {
        ConfICAnswerByConfInformedConsentId(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confICQuestionId
            confInformedConsentId
            projectId
            id
            description
            order
            onErrorMessage
            isCorrect
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfICAnswerByConfInformedConsentIdQuery>(
      response.data.ConfICAnswerByConfInformedConsentId
    );
  }
  async GetInformedConsentInstance(
    id: string
  ): Promise<GetInformedConsentInstanceQuery> {
    const statement = `query GetInformedConsentInstance($id: ID!) {
        getInformedConsentInstance(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInformedConsentInstanceQuery>(
      response.data.getInformedConsentInstance
    );
  }
  async ListInformedConsentInstances(
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInformedConsentInstancesQuery> {
    const statement = `query ListInformedConsentInstances($filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        listInformedConsentInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInformedConsentInstancesQuery>(
      response.data.listInformedConsentInstances
    );
  }
  async SyncInformedConsentInstances(
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncInformedConsentInstancesQuery> {
    const statement = `query SyncInformedConsentInstances($filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncInformedConsentInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncInformedConsentInstancesQuery>(
      response.data.syncInformedConsentInstances
    );
  }
  async ConfInformedConsentByProject(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfInformedConsentByProjectQuery> {
    const statement = `query ConfInformedConsentByProject($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        confInformedConsentByProject(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfInformedConsentByProjectQuery>(
      response.data.confInformedConsentByProject
    );
  }
  async InformedConsentInstanceByICConfiguration(
    confInformedConsentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InformedConsentInstanceByICConfigurationQuery> {
    const statement = `query InformedConsentInstanceByICConfiguration($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        InformedConsentInstanceByICConfiguration(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confInformedConsentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InformedConsentInstanceByICConfigurationQuery>(
      response.data.InformedConsentInstanceByICConfiguration
    );
  }
  async InformedConsentByConsenterUser(
    consenterUserId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InformedConsentByConsenterUserQuery> {
    const statement = `query InformedConsentByConsenterUser($consenterUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        informedConsentByConsenterUser(consenterUserId: $consenterUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consenterUserId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InformedConsentByConsenterUserQuery>(
      response.data.informedConsentByConsenterUser
    );
  }
  async InformedConsentBySubject(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InformedConsentBySubjectQuery> {
    const statement = `query InformedConsentBySubject($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        informedConsentBySubject(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InformedConsentBySubjectQuery>(
      response.data.informedConsentBySubject
    );
  }
  async InformedConsentByConsentedIdentificator(
    consentedIdentificator: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInformedConsentInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InformedConsentByConsentedIdentificatorQuery> {
    const statement = `query InformedConsentByConsentedIdentificator($consentedIdentificator: String!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
        informedConsentByConsentedIdentificator(consentedIdentificator: $consentedIdentificator, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consentedIdentificator
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InformedConsentByConsentedIdentificatorQuery>(
      response.data.informedConsentByConsentedIdentificator
    );
  }
  async GetConsentedUser(id: string): Promise<GetConsentedUserQuery> {
    const statement = `query GetConsentedUser($id: ID!) {
        getConsentedUser(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConsentedUserQuery>response.data.getConsentedUser;
  }
  async ListConsentedUsers(
    filter?: ModelConsentedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConsentedUsersQuery> {
    const statement = `query ListConsentedUsers($filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String) {
        listConsentedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConsentedUsersQuery>response.data.listConsentedUsers;
  }
  async SyncConsentedUsers(
    filter?: ModelConsentedUserFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncConsentedUsersQuery> {
    const statement = `query SyncConsentedUsers($filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncConsentedUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncConsentedUsersQuery>response.data.syncConsentedUsers;
  }
  async ConsentedUserByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConsentedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConsentedUserByUserIdQuery> {
    const statement = `query ConsentedUserByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String) {
        ConsentedUserByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConsentedUserByUserIdQuery>response.data.ConsentedUserByUserId;
  }
  async ConsentedUserByInformedConsentInstanceId(
    informedConsentInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConsentedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConsentedUserByInformedConsentInstanceIdQuery> {
    const statement = `query ConsentedUserByInformedConsentInstanceId($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String) {
        ConsentedUserByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConsentedUserByInformedConsentInstanceIdQuery>(
      response.data.ConsentedUserByInformedConsentInstanceId
    );
  }
  async GetICSectionInstance(id: string): Promise<GetICSectionInstanceQuery> {
    const statement = `query GetICSectionInstance($id: ID!) {
        getICSectionInstance(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetICSectionInstanceQuery>response.data.getICSectionInstance;
  }
  async ListICSectionInstances(
    filter?: ModelICSectionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListICSectionInstancesQuery> {
    const statement = `query ListICSectionInstances($filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
        listICSectionInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListICSectionInstancesQuery>response.data.listICSectionInstances;
  }
  async SyncICSectionInstances(
    filter?: ModelICSectionInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncICSectionInstancesQuery> {
    const statement = `query SyncICSectionInstances($filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncICSectionInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncICSectionInstancesQuery>response.data.syncICSectionInstances;
  }
  async ICSectionInstanceByInformedConsentInstanceId(
    informedConsentInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICSectionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICSectionInstanceByInformedConsentInstanceIdQuery> {
    const statement = `query ICSectionInstanceByInformedConsentInstanceId($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICSectionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICSectionInstanceByInformedConsentInstanceIdQuery>(
      response.data.ICSectionInstanceByInformedConsentInstanceId
    );
  }
  async ICSectionInstanceByConfICSectionId(
    confICSectionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICSectionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICSectionInstanceByConfICSectionIdQuery> {
    const statement = `query ICSectionInstanceByConfICSectionId($confICSectionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICSectionInstanceByConfICSectionId(confICSectionId: $confICSectionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confICSectionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICSectionInstanceByConfICSectionIdQuery>(
      response.data.ICSectionInstanceByConfICSectionId
    );
  }
  async ICSectionInstanceByConsentedUserId(
    consentedUserId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICSectionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICSectionInstanceByConsentedUserIdQuery> {
    const statement = `query ICSectionInstanceByConsentedUserId($consentedUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICSectionInstanceByConsentedUserId(consentedUserId: $consentedUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICSectionId
            id
            consentedUserId
            consentedSignatureDate
            progress
            startDate
            completedDate
            isCompleted
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consentedUserId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICSectionInstanceByConsentedUserIdQuery>(
      response.data.ICSectionInstanceByConsentedUserId
    );
  }
  async GetICQuestionInstance(id: string): Promise<GetICQuestionInstanceQuery> {
    const statement = `query GetICQuestionInstance($id: ID!) {
        getICQuestionInstance(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetICQuestionInstanceQuery>response.data.getICQuestionInstance;
  }
  async ListICQuestionInstances(
    filter?: ModelICQuestionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListICQuestionInstancesQuery> {
    const statement = `query ListICQuestionInstances($filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
        listICQuestionInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListICQuestionInstancesQuery>response.data.listICQuestionInstances;
  }
  async SyncICQuestionInstances(
    filter?: ModelICQuestionInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncICQuestionInstancesQuery> {
    const statement = `query SyncICQuestionInstances($filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncICQuestionInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncICQuestionInstancesQuery>response.data.syncICQuestionInstances;
  }
  async ICQuestionInstanceByInformedConsentInstanceId(
    informedConsentInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICQuestionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICQuestionInstanceByInformedConsentInstanceIdQuery> {
    const statement = `query ICQuestionInstanceByInformedConsentInstanceId($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICQuestionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      informedConsentInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICQuestionInstanceByInformedConsentInstanceIdQuery>(
      response.data.ICQuestionInstanceByInformedConsentInstanceId
    );
  }
  async ICQuestionInstanceByConfICSectionId(
    confICSectionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICQuestionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICQuestionInstanceByConfICSectionIdQuery> {
    const statement = `query ICQuestionInstanceByConfICSectionId($confICSectionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICQuestionInstanceByConfICSectionId(confICSectionId: $confICSectionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confICSectionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICQuestionInstanceByConfICSectionIdQuery>(
      response.data.ICQuestionInstanceByConfICSectionId
    );
  }
  async ICQuestionInstanceByConsentedUserId(
    consentedUserId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelICQuestionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ICQuestionInstanceByConsentedUserIdQuery> {
    const statement = `query ICQuestionInstanceByConsentedUserId($consentedUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
        ICQuestionInstanceByConsentedUserId(consentedUserId: $consentedUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            informedConsentInstanceId
            confICQuestionId
            confICSectionId
            consentedUserId
            id
            failedAttempts
            isCompleted
            startDate
            completedDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      consentedUserId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ICQuestionInstanceByConsentedUserIdQuery>(
      response.data.ICQuestionInstanceByConsentedUserId
    );
  }
  async GetSubject(id: string): Promise<GetSubjectQuery> {
    const statement = `query GetSubject($id: ID!) {
        getSubject(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubjectQuery>response.data.getSubject;
  }
  async ListSubjects(
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSubjectsQuery> {
    const statement = `query ListSubjects($filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        listSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubjectsQuery>response.data.listSubjects;
  }
  async SyncSubjects(
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSubjectsQuery> {
    const statement = `query SyncSubjects($filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSubjects(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSubjectsQuery>response.data.syncSubjects;
  }
  async SubjectByProjectId(
    projectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubjectByProjectIdQuery> {
    const statement = `query SubjectByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubjectByProjectIdQuery>response.data.subjectByProjectId;
  }
  async SubjectsBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubjectsBySiteIdQuery> {
    const statement = `query SubjectsBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectsBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubjectsBySiteIdQuery>response.data.subjectsBySiteId;
  }
  async SubjectBySubjectNumberAndSite(
    subjectNumber: string,
    siteId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubjectBySubjectNumberAndSiteQuery> {
    const statement = `query SubjectBySubjectNumberAndSite($subjectNumber: String!, $siteId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectBySubjectNumberAndSite(subjectNumber: $subjectNumber, siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectNumber
    };
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubjectBySubjectNumberAndSiteQuery>(
      response.data.subjectBySubjectNumberAndSite
    );
  }
  async GetReportInstance(id: string): Promise<GetReportInstanceQuery> {
    const statement = `query GetReportInstance($id: ID!) {
        getReportInstance(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReportInstanceQuery>response.data.getReportInstance;
  }
  async ListReportInstances(
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListReportInstancesQuery> {
    const statement = `query ListReportInstances($filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        listReportInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReportInstancesQuery>response.data.listReportInstances;
  }
  async SyncReportInstances(
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncReportInstancesQuery> {
    const statement = `query SyncReportInstances($filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncReportInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncReportInstancesQuery>response.data.syncReportInstances;
  }
  async ReportInstanceByConfReportId(
    confReportId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReportInstanceByConfReportIdQuery> {
    const statement = `query ReportInstanceByConfReportId($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportInstanceByConfReportIdQuery>(
      response.data.reportInstanceByConfReportId
    );
  }
  async ReportInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReportInstanceBySubjectIdQuery> {
    const statement = `query ReportInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportInstanceBySubjectIdQuery>(
      response.data.reportInstanceBySubjectId
    );
  }
  async ReportInstancesBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReportInstancesBySiteIdQuery> {
    const statement = `query ReportInstancesBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        ReportInstancesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportInstancesBySiteIdQuery>response.data.ReportInstancesBySiteId;
  }
  async GetReportInstanceByStateAndCompletedDate(
    state: InstanceState,
    completeDate?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GetReportInstanceByStateAndCompletedDateQuery> {
    const statement = `query GetReportInstanceByStateAndCompletedDate($state: InstanceState!, $completeDate: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        getReportInstanceByStateAndCompletedDate(state: $state, completeDate: $completeDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      state
    };
    if (completeDate) {
      gqlAPIServiceArguments.completeDate = completeDate;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReportInstanceByStateAndCompletedDateQuery>(
      response.data.getReportInstanceByStateAndCompletedDate
    );
  }
  async GetSignatureInstance(id: string): Promise<GetSignatureInstanceQuery> {
    const statement = `query GetSignatureInstance($id: ID!) {
        getSignatureInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSignatureInstanceQuery>response.data.getSignatureInstance;
  }
  async ListSignatureInstances(
    filter?: ModelSignatureInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSignatureInstancesQuery> {
    const statement = `query ListSignatureInstances($filter: ModelSignatureInstanceFilterInput, $limit: Int, $nextToken: String) {
        listSignatureInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            id
            date
            type
            reason
            entityType
            entityId
            signedInfo
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSignatureInstancesQuery>response.data.listSignatureInstances;
  }
  async SyncSignatureInstances(
    filter?: ModelSignatureInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSignatureInstancesQuery> {
    const statement = `query SyncSignatureInstances($filter: ModelSignatureInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSignatureInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            id
            date
            type
            reason
            entityType
            entityId
            signedInfo
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSignatureInstancesQuery>response.data.syncSignatureInstances;
  }
  async SignaturesBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSignatureInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SignaturesBySubjectIdQuery> {
    const statement = `query SignaturesBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSignatureInstanceFilterInput, $limit: Int, $nextToken: String) {
        signaturesBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            id
            date
            type
            reason
            entityType
            entityId
            signedInfo
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SignaturesBySubjectIdQuery>response.data.signaturesBySubjectId;
  }
  async GetEDiaryInstance(id: string): Promise<GetEDiaryInstanceQuery> {
    const statement = `query GetEDiaryInstance($id: ID!) {
        getEDiaryInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEDiaryInstanceQuery>response.data.getEDiaryInstance;
  }
  async ListEDiaryInstances(
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEDiaryInstancesQuery> {
    const statement = `query ListEDiaryInstances($filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        listEDiaryInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEDiaryInstancesQuery>response.data.listEDiaryInstances;
  }
  async SyncEDiaryInstances(
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncEDiaryInstancesQuery> {
    const statement = `query SyncEDiaryInstances($filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncEDiaryInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncEDiaryInstancesQuery>response.data.syncEDiaryInstances;
  }
  async EDiaryInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EDiaryInstanceBySubjectIdQuery> {
    const statement = `query EDiaryInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EDiaryInstanceBySubjectIdQuery>(
      response.data.eDiaryInstanceBySubjectId
    );
  }
  async EDiaryInstanceByConfEDiaryId(
    confEDiaryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EDiaryInstanceByConfEDiaryIdQuery> {
    const statement = `query EDiaryInstanceByConfEDiaryId($confEDiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryInstanceByConfEDiaryId(confEDiaryId: $confEDiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confEDiaryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EDiaryInstanceByConfEDiaryIdQuery>(
      response.data.eDiaryInstanceByConfEDiaryId
    );
  }
  async EDiaryInstancesBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EDiaryInstancesBySiteIdQuery> {
    const statement = `query EDiaryInstancesBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryInstancesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EDiaryInstancesBySiteIdQuery>response.data.eDiaryInstancesBySiteId;
  }
  async GetEDiaryPhaseInstance(
    id: string
  ): Promise<GetEDiaryPhaseInstanceQuery> {
    const statement = `query GetEDiaryPhaseInstance($id: ID!) {
        getEDiaryPhaseInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEDiaryPhaseInstanceQuery>response.data.getEDiaryPhaseInstance;
  }
  async ListEDiaryPhaseInstances(
    filter?: ModelEDiaryPhaseInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEDiaryPhaseInstancesQuery> {
    const statement = `query ListEDiaryPhaseInstances($filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String) {
        listEDiaryPhaseInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEDiaryPhaseInstancesQuery>(
      response.data.listEDiaryPhaseInstances
    );
  }
  async SyncEDiaryPhaseInstances(
    filter?: ModelEDiaryPhaseInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncEDiaryPhaseInstancesQuery> {
    const statement = `query SyncEDiaryPhaseInstances($filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncEDiaryPhaseInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncEDiaryPhaseInstancesQuery>(
      response.data.syncEDiaryPhaseInstances
    );
  }
  async EDiaryPhaseInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryPhaseInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EDiaryPhaseInstanceBySubjectIdQuery> {
    const statement = `query EDiaryPhaseInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryPhaseInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EDiaryPhaseInstanceBySubjectIdQuery>(
      response.data.eDiaryPhaseInstanceBySubjectId
    );
  }
  async DayInstanceByEDiaryInstance(
    eDiaryInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryPhaseInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DayInstanceByEDiaryInstanceQuery> {
    const statement = `query DayInstanceByEDiaryInstance($eDiaryInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String) {
        dayInstanceByEDiaryInstance(eDiaryInstanceId: $eDiaryInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DayInstanceByEDiaryInstanceQuery>(
      response.data.dayInstanceByEDiaryInstance
    );
  }
  async EDiaryPhaseInstancesBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEDiaryPhaseInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EDiaryPhaseInstancesBySiteIdQuery> {
    const statement = `query EDiaryPhaseInstancesBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryPhaseInstancesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EDiaryPhaseInstancesBySiteIdQuery>(
      response.data.eDiaryPhaseInstancesBySiteId
    );
  }
  async GetDayInstance(id: string): Promise<GetDayInstanceQuery> {
    const statement = `query GetDayInstance($id: ID!) {
        getDayInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDayInstanceQuery>response.data.getDayInstance;
  }
  async ListDayInstances(
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDayInstancesQuery> {
    const statement = `query ListDayInstances($filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        listDayInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDayInstancesQuery>response.data.listDayInstances;
  }
  async SyncDayInstances(
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncDayInstancesQuery> {
    const statement = `query SyncDayInstances($filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncDayInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncDayInstancesQuery>response.data.syncDayInstances;
  }
  async DayInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DayInstanceBySubjectIdQuery> {
    const statement = `query DayInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        dayInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DayInstanceBySubjectIdQuery>response.data.dayInstanceBySubjectId;
  }
  async DayInstanceByEDiaryPhaseInstance(
    eDiaryPhaseInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DayInstanceByEDiaryPhaseInstanceQuery> {
    const statement = `query DayInstanceByEDiaryPhaseInstance($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        dayInstanceByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DayInstanceByEDiaryPhaseInstanceQuery>(
      response.data.dayInstanceByEDiaryPhaseInstance
    );
  }
  async DayInstanceByConfDayId(
    confDayId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DayInstanceByConfDayIdQuery> {
    const statement = `query DayInstanceByConfDayId($confDayId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        dayInstanceByConfDayId(confDayId: $confDayId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confDayId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DayInstanceByConfDayIdQuery>response.data.dayInstanceByConfDayId;
  }
  async DayInstancesBySiteId(
    siteId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DayInstancesBySiteIdQuery> {
    const statement = `query DayInstancesBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        DayInstancesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DayInstancesBySiteIdQuery>response.data.DayInstancesBySiteId;
  }
  async GetDayInstanceByStateAndCompletedDate(
    state: InstanceState,
    completedDate?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDayInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GetDayInstanceByStateAndCompletedDateQuery> {
    const statement = `query GetDayInstanceByStateAndCompletedDate($state: InstanceState!, $completedDate: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        getDayInstanceByStateAndCompletedDate(state: $state, completedDate: $completedDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      state
    };
    if (completedDate) {
      gqlAPIServiceArguments.completedDate = completedDate;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDayInstanceByStateAndCompletedDateQuery>(
      response.data.getDayInstanceByStateAndCompletedDate
    );
  }
  async GetSymptomRecordLog(id: string): Promise<GetSymptomRecordLogQuery> {
    const statement = `query GetSymptomRecordLog($id: ID!) {
        getSymptomRecordLog(id: $id) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSymptomRecordLogQuery>response.data.getSymptomRecordLog;
  }
  async ListSymptomRecordLogs(
    filter?: ModelSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSymptomRecordLogsQuery> {
    const statement = `query ListSymptomRecordLogs($filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        listSymptomRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSymptomRecordLogsQuery>response.data.listSymptomRecordLogs;
  }
  async SyncSymptomRecordLogs(
    filter?: ModelSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSymptomRecordLogsQuery> {
    const statement = `query SyncSymptomRecordLogs($filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSymptomRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSymptomRecordLogsQuery>response.data.syncSymptomRecordLogs;
  }
  async SymptomRecordLogByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomRecordLogByDayInstanceIdQuery> {
    const statement = `query SymptomRecordLogByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        symptomRecordLogByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomRecordLogByDayInstanceIdQuery>(
      response.data.symptomRecordLogByDayInstanceId
    );
  }
  async SymptomRecordLogBysymptomInstanceId(
    symptomInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomRecordLogBysymptomInstanceIdQuery> {
    const statement = `query SymptomRecordLogBysymptomInstanceId($symptomInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        symptomRecordLogBysymptomInstanceId(symptomInstanceId: $symptomInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      symptomInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomRecordLogBysymptomInstanceIdQuery>(
      response.data.symptomRecordLogBysymptomInstanceId
    );
  }
  async SymptomRecordLogBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomRecordLogBySubjectIdQuery> {
    const statement = `query SymptomRecordLogBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        symptomRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomRecordLogBySubjectIdQuery>(
      response.data.symptomRecordLogBySubjectId
    );
  }
  async GetSymptomInstance(id: string): Promise<GetSymptomInstanceQuery> {
    const statement = `query GetSymptomInstance($id: ID!) {
        getSymptomInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSymptomInstanceQuery>response.data.getSymptomInstance;
  }
  async ListSymptomInstances(
    filter?: ModelSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSymptomInstancesQuery> {
    const statement = `query ListSymptomInstances($filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        listSymptomInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSymptomInstancesQuery>response.data.listSymptomInstances;
  }
  async SyncSymptomInstances(
    filter?: ModelSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSymptomInstancesQuery> {
    const statement = `query SyncSymptomInstances($filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSymptomInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSymptomInstancesQuery>response.data.syncSymptomInstances;
  }
  async SymptomInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomInstanceBySubjectIdQuery> {
    const statement = `query SymptomInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        SymptomInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomInstanceBySubjectIdQuery>(
      response.data.SymptomInstanceBySubjectId
    );
  }
  async SymptomInstanceByReportInstanceId(
    reportInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomInstanceByReportInstanceIdQuery> {
    const statement = `query SymptomInstanceByReportInstanceId($reportInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        SymptomInstanceByReportInstanceId(reportInstanceId: $reportInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      reportInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomInstanceByReportInstanceIdQuery>(
      response.data.SymptomInstanceByReportInstanceId
    );
  }
  async SymptomInstanceByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomInstanceByDayInstanceIdQuery> {
    const statement = `query SymptomInstanceByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        SymptomInstanceByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomInstanceByDayInstanceIdQuery>(
      response.data.SymptomInstanceByDayInstanceId
    );
  }
  async SymptomInstanceByConfSymptomId(
    confSymptomId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSymptomInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SymptomInstanceByConfSymptomIdQuery> {
    const statement = `query SymptomInstanceByConfSymptomId($confSymptomId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        SymptomInstanceByConfSymptomId(confSymptomId: $confSymptomId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confSymptomId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SymptomInstanceByConfSymptomIdQuery>(
      response.data.SymptomInstanceByConfSymptomId
    );
  }
  async GetMedicationInstance(id: string): Promise<GetMedicationInstanceQuery> {
    const statement = `query GetMedicationInstance($id: ID!) {
        getMedicationInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMedicationInstanceQuery>response.data.getMedicationInstance;
  }
  async ListMedicationInstances(
    filter?: ModelMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMedicationInstancesQuery> {
    const statement = `query ListMedicationInstances($filter: ModelMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        listMedicationInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMedicationInstancesQuery>response.data.listMedicationInstances;
  }
  async SyncMedicationInstances(
    filter?: ModelMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncMedicationInstancesQuery> {
    const statement = `query SyncMedicationInstances($filter: ModelMedicationInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncMedicationInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncMedicationInstancesQuery>response.data.syncMedicationInstances;
  }
  async MedicationInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MedicationInstanceBySubjectIdQuery> {
    const statement = `query MedicationInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicationInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MedicationInstanceBySubjectIdQuery>(
      response.data.medicationInstanceBySubjectId
    );
  }
  async MedicationByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMedicationInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MedicationByDayInstanceIdQuery> {
    const statement = `query MedicationByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicationByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MedicationByDayInstanceIdQuery>(
      response.data.medicationByDayInstanceId
    );
  }
  async GetMedicalAttentionInstance(
    id: string
  ): Promise<GetMedicalAttentionInstanceQuery> {
    const statement = `query GetMedicalAttentionInstance($id: ID!) {
        getMedicalAttentionInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMedicalAttentionInstanceQuery>(
      response.data.getMedicalAttentionInstance
    );
  }
  async ListMedicalAttentionInstances(
    filter?: ModelMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMedicalAttentionInstancesQuery> {
    const statement = `query ListMedicalAttentionInstances($filter: ModelMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        listMedicalAttentionInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMedicalAttentionInstancesQuery>(
      response.data.listMedicalAttentionInstances
    );
  }
  async SyncMedicalAttentionInstances(
    filter?: ModelMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncMedicalAttentionInstancesQuery> {
    const statement = `query SyncMedicalAttentionInstances($filter: ModelMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncMedicalAttentionInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncMedicalAttentionInstancesQuery>(
      response.data.syncMedicalAttentionInstances
    );
  }
  async MedicalAttentionInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MedicalAttentionInstanceBySubjectIdQuery> {
    const statement = `query MedicalAttentionInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicalAttentionInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MedicalAttentionInstanceBySubjectIdQuery>(
      response.data.medicalAttentionInstanceBySubjectId
    );
  }
  async MedicalAttentionByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMedicalAttentionInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MedicalAttentionByDayInstanceIdQuery> {
    const statement = `query MedicalAttentionByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicalAttentionByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MedicalAttentionByDayInstanceIdQuery>(
      response.data.medicalAttentionByDayInstanceId
    );
  }
  async GetTemperatureRecordLog(
    id: string
  ): Promise<GetTemperatureRecordLogQuery> {
    const statement = `query GetTemperatureRecordLog($id: ID!) {
        getTemperatureRecordLog(id: $id) {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTemperatureRecordLogQuery>response.data.getTemperatureRecordLog;
  }
  async ListTemperatureRecordLogs(
    filter?: ModelTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTemperatureRecordLogsQuery> {
    const statement = `query ListTemperatureRecordLogs($filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        listTemperatureRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTemperatureRecordLogsQuery>(
      response.data.listTemperatureRecordLogs
    );
  }
  async SyncTemperatureRecordLogs(
    filter?: ModelTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncTemperatureRecordLogsQuery> {
    const statement = `query SyncTemperatureRecordLogs($filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncTemperatureRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncTemperatureRecordLogsQuery>(
      response.data.syncTemperatureRecordLogs
    );
  }
  async TemperatureRecordLogByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TemperatureRecordLogByDayInstanceIdQuery> {
    const statement = `query TemperatureRecordLogByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        temperatureRecordLogByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TemperatureRecordLogByDayInstanceIdQuery>(
      response.data.temperatureRecordLogByDayInstanceId
    );
  }
  async TemperatureRecordLogByReportInstanceId(
    reportInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TemperatureRecordLogByReportInstanceIdQuery> {
    const statement = `query TemperatureRecordLogByReportInstanceId($reportInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        temperatureRecordLogByReportInstanceId(reportInstanceId: $reportInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      reportInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TemperatureRecordLogByReportInstanceIdQuery>(
      response.data.temperatureRecordLogByReportInstanceId
    );
  }
  async TemperatureRecordLogBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTemperatureRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TemperatureRecordLogBySubjectIdQuery> {
    const statement = `query TemperatureRecordLogBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        temperatureRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TemperatureRecordLogBySubjectIdQuery>(
      response.data.temperatureRecordLogBySubjectId
    );
  }
  async GetMediaRecordLog(id: string): Promise<GetMediaRecordLogQuery> {
    const statement = `query GetMediaRecordLog($id: ID!) {
        getMediaRecordLog(id: $id) {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMediaRecordLogQuery>response.data.getMediaRecordLog;
  }
  async ListMediaRecordLogs(
    filter?: ModelMediaRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMediaRecordLogsQuery> {
    const statement = `query ListMediaRecordLogs($filter: ModelMediaRecordLogFilterInput, $limit: Int, $nextToken: String) {
        listMediaRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            mediaType
            url
            comment
            dateTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMediaRecordLogsQuery>response.data.listMediaRecordLogs;
  }
  async SyncMediaRecordLogs(
    filter?: ModelMediaRecordLogFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncMediaRecordLogsQuery> {
    const statement = `query SyncMediaRecordLogs($filter: ModelMediaRecordLogFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncMediaRecordLogs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            mediaType
            url
            comment
            dateTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncMediaRecordLogsQuery>response.data.syncMediaRecordLogs;
  }
  async MediaRecordLogByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMediaRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MediaRecordLogByDayInstanceIdQuery> {
    const statement = `query MediaRecordLogByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMediaRecordLogFilterInput, $limit: Int, $nextToken: String) {
        mediaRecordLogByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            mediaType
            url
            comment
            dateTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MediaRecordLogByDayInstanceIdQuery>(
      response.data.mediaRecordLogByDayInstanceId
    );
  }
  async MediaRecordLogByReportInstanceId(
    reportInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMediaRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MediaRecordLogByReportInstanceIdQuery> {
    const statement = `query MediaRecordLogByReportInstanceId($reportInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMediaRecordLogFilterInput, $limit: Int, $nextToken: String) {
        mediaRecordLogByReportInstanceId(reportInstanceId: $reportInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            mediaType
            url
            comment
            dateTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      reportInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MediaRecordLogByReportInstanceIdQuery>(
      response.data.mediaRecordLogByReportInstanceId
    );
  }
  async MediaRecordLogBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMediaRecordLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MediaRecordLogBySubjectIdQuery> {
    const statement = `query MediaRecordLogBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMediaRecordLogFilterInput, $limit: Int, $nextToken: String) {
        mediaRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            mediaType
            url
            comment
            dateTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MediaRecordLogBySubjectIdQuery>(
      response.data.mediaRecordLogBySubjectId
    );
  }
  async GetAlertInstance(id: string): Promise<GetAlertInstanceQuery> {
    const statement = `query GetAlertInstance($id: ID!) {
        getAlertInstance(id: $id) {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAlertInstanceQuery>response.data.getAlertInstance;
  }
  async ListAlertInstances(
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAlertInstancesQuery> {
    const statement = `query ListAlertInstances($filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        listAlertInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            subjectId
            id
            confAlert_data
            entityId
            entityName
            state
            revisionState
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAlertInstancesQuery>response.data.listAlertInstances;
  }
  async SyncAlertInstances(
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncAlertInstancesQuery> {
    const statement = `query SyncAlertInstances($filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncAlertInstances(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            confAlertId
            subjectId
            id
            confAlert_data
            entityId
            entityName
            state
            revisionState
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncAlertInstancesQuery>response.data.syncAlertInstances;
  }
  async AlertInstanceByConfAlertId(
    confAlertId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AlertInstanceByConfAlertIdQuery> {
    const statement = `query AlertInstanceByConfAlertId($confAlertId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        alertInstanceByConfAlertId(confAlertId: $confAlertId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            subjectId
            id
            confAlert_data
            entityId
            entityName
            state
            revisionState
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confAlertId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AlertInstanceByConfAlertIdQuery>(
      response.data.alertInstanceByConfAlertId
    );
  }
  async AlertInstanceBySubjectId(
    subjectId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AlertInstanceBySubjectIdQuery> {
    const statement = `query AlertInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        alertInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            subjectId
            id
            confAlert_data
            entityId
            entityName
            state
            revisionState
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subjectId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AlertInstanceBySubjectIdQuery>(
      response.data.alertInstanceBySubjectId
    );
  }
  async AlertInstanceByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAlertInstanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AlertInstanceByEntityIdQuery> {
    const statement = `query AlertInstanceByEntityId($entityId: String!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        alertInstanceByEntityId(entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            subjectId
            id
            confAlert_data
            entityId
            entityName
            state
            revisionState
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AlertInstanceByEntityIdQuery>response.data.alertInstanceByEntityId;
  }
  async GetPDFAndAlertsTransactionLog(
    id: string
  ): Promise<GetPDFAndAlertsTransactionLogQuery> {
    const statement = `query GetPDFAndAlertsTransactionLog($id: ID!) {
        getPDFAndAlertsTransactionLog(id: $id) {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPDFAndAlertsTransactionLogQuery>(
      response.data.getPDFAndAlertsTransactionLog
    );
  }
  async ListPDFAndAlertsTransactionLogs(
    filter?: ModelPDFAndAlertsTransactionLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPDFAndAlertsTransactionLogsQuery> {
    const statement = `query ListPDFAndAlertsTransactionLogs($filter: ModelPDFAndAlertsTransactionLogFilterInput, $limit: Int, $nextToken: String) {
        listPDFAndAlertsTransactionLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            startDate
            finishDate
            state
            source
            eDiaryPhaseInstanceId
            reportInstanceId
            confReportId
            dayInstanceId
            _lastUser
            _changeReason
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPDFAndAlertsTransactionLogsQuery>(
      response.data.listPDFAndAlertsTransactionLogs
    );
  }
  async SyncPDFAndAlertsTransactionLogs(
    filter?: ModelPDFAndAlertsTransactionLogFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncPDFAndAlertsTransactionLogsQuery> {
    const statement = `query SyncPDFAndAlertsTransactionLogs($filter: ModelPDFAndAlertsTransactionLogFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncPDFAndAlertsTransactionLogs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            subjectId
            startDate
            finishDate
            state
            source
            eDiaryPhaseInstanceId
            reportInstanceId
            confReportId
            dayInstanceId
            _lastUser
            _changeReason
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncPDFAndAlertsTransactionLogsQuery>(
      response.data.syncPDFAndAlertsTransactionLogs
    );
  }
  async PDFAndAlertsTransactionLogByEDiaryPhaseInstanceId(
    eDiaryPhaseInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPDFAndAlertsTransactionLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PDFAndAlertsTransactionLogByEDiaryPhaseInstanceIdQuery> {
    const statement = `query PDFAndAlertsTransactionLogByEDiaryPhaseInstanceId($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelPDFAndAlertsTransactionLogFilterInput, $limit: Int, $nextToken: String) {
        PDFAndAlertsTransactionLogByEDiaryPhaseInstanceId(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            startDate
            finishDate
            state
            source
            eDiaryPhaseInstanceId
            reportInstanceId
            confReportId
            dayInstanceId
            _lastUser
            _changeReason
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      eDiaryPhaseInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PDFAndAlertsTransactionLogByEDiaryPhaseInstanceIdQuery>(
      response.data.PDFAndAlertsTransactionLogByEDiaryPhaseInstanceId
    );
  }
  async PDFAndAlertsTransactionLogByReportInstanceId(
    reportInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPDFAndAlertsTransactionLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PDFAndAlertsTransactionLogByReportInstanceIdQuery> {
    const statement = `query PDFAndAlertsTransactionLogByReportInstanceId($reportInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelPDFAndAlertsTransactionLogFilterInput, $limit: Int, $nextToken: String) {
        PDFAndAlertsTransactionLogByReportInstanceId(reportInstanceId: $reportInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            startDate
            finishDate
            state
            source
            eDiaryPhaseInstanceId
            reportInstanceId
            confReportId
            dayInstanceId
            _lastUser
            _changeReason
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      reportInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PDFAndAlertsTransactionLogByReportInstanceIdQuery>(
      response.data.PDFAndAlertsTransactionLogByReportInstanceId
    );
  }
  async PDFAndAlertsTransactionLogByConfReportId(
    confReportId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPDFAndAlertsTransactionLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PDFAndAlertsTransactionLogByConfReportIdQuery> {
    const statement = `query PDFAndAlertsTransactionLogByConfReportId($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelPDFAndAlertsTransactionLogFilterInput, $limit: Int, $nextToken: String) {
        PDFAndAlertsTransactionLogByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            startDate
            finishDate
            state
            source
            eDiaryPhaseInstanceId
            reportInstanceId
            confReportId
            dayInstanceId
            _lastUser
            _changeReason
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      confReportId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PDFAndAlertsTransactionLogByConfReportIdQuery>(
      response.data.PDFAndAlertsTransactionLogByConfReportId
    );
  }
  async PDFAndAlertsTransactionLogByDayInstanceId(
    dayInstanceId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPDFAndAlertsTransactionLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PDFAndAlertsTransactionLogByDayInstanceIdQuery> {
    const statement = `query PDFAndAlertsTransactionLogByDayInstanceId($dayInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelPDFAndAlertsTransactionLogFilterInput, $limit: Int, $nextToken: String) {
        PDFAndAlertsTransactionLogByDayInstanceId(dayInstanceId: $dayInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            startDate
            finishDate
            state
            source
            eDiaryPhaseInstanceId
            reportInstanceId
            confReportId
            dayInstanceId
            _lastUser
            _changeReason
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dayInstanceId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PDFAndAlertsTransactionLogByDayInstanceIdQuery>(
      response.data.PDFAndAlertsTransactionLogByDayInstanceId
    );
  }
  OnCreateChatMessageBySiteIdListener(
    siteId: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateChatMessageBySiteId">
    >
  > {
    const statement = `subscription OnCreateChatMessageBySiteId($siteId: String!) {
        onCreateChatMessageBySiteId(siteId: $siteId) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateChatMessageBySiteId">
      >
    >;
  }

  OnCreateChatMessageByUserIdListener(
    userId: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateChatMessageByUserId">
    >
  > {
    const statement = `subscription OnCreateChatMessageByUserId($userId: String!) {
        onCreateChatMessageByUserId(userId: $userId) {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateChatMessageByUserId">
      >
    >;
  }

  OnCreateBackupInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateBackupInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBackupInstance {
        onCreateBackupInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateBackupInstance">
    >
  >;

  OnUpdateBackupInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateBackupInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBackupInstance {
        onUpdateBackupInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateBackupInstance">
    >
  >;

  OnDeleteBackupInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteBackupInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBackupInstance {
        onDeleteBackupInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          data
          instanceName
          STORAGETYPE
          gps
          appVersion
          userIP
          deviceModel
          _lastUser
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteBackupInstance">
    >
  >;

  OnCreateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  >;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  >;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          termsAndConditions {
            __typename
            termsAndConditionsVersion
            policiesVersion
            acceptanceDate
          }
          notificationPreference
          subjects
          sites
          projects
          appVersion
          lastTimezone
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  >;

  OnCreateRoleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateRole {
        onCreateRole {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
  >;

  OnUpdateRoleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRole">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateRole {
        onUpdateRole {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRole">>
  >;

  OnDeleteRoleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteRole {
        onDeleteRole {
          __typename
          id
          name
          isEnabled
          state
          permissions
          isForProjects
          projects
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
  >;

  OnCreateConfChatUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfChatUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfChatUser {
        onCreateConfChatUser {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfChatUser">>
  >;

  OnUpdateConfChatUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfChatUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfChatUser {
        onUpdateConfChatUser {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfChatUser">>
  >;

  OnDeleteConfChatUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfChatUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfChatUser {
        onDeleteConfChatUser {
          __typename
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          isChatActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfChatUser">>
  >;

  OnCreateChatMessageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateChatMessage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateChatMessage {
        onCreateChatMessage {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateChatMessage">>
  >;

  OnUpdateChatMessageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateChatMessage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateChatMessage {
        onUpdateChatMessage {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateChatMessage">>
  >;

  OnDeleteChatMessageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteChatMessage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteChatMessage {
        onDeleteChatMessage {
          __typename
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          message
          sender
          senderAlias
          createdAt
          _lastUser
          _changeReason
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteChatMessage">>
  >;

  OnCreateVerifiedDayInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedDayInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVerifiedDayInstance {
        onCreateVerifiedDayInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedDayInstance">
    >
  >;

  OnUpdateVerifiedDayInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedDayInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVerifiedDayInstance {
        onUpdateVerifiedDayInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedDayInstance">
    >
  >;

  OnDeleteVerifiedDayInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedDayInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVerifiedDayInstance {
        onDeleteVerifiedDayInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startDate
          finishDate
          completedDate
          hasMedication
          hasMedicationCompleted
          hasMedicationComment
          medicationGivenTo
          medicationGivenToComment
          hasMedicalAttention
          hasMedicalAttentionCompleted
          hasMedicalAttentionComment
          hasOtherSymptoms
          hasOtherSymptomsCompleted
          hasOtherSymptomsComment
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          symptomOcurrenciesComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedDayInstance">
    >
  >;

  OnCreateCommentConciliationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCommentConciliationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCommentConciliationInstance {
        onCreateCommentConciliationInstance {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCommentConciliationInstance">
    >
  >;

  OnUpdateCommentConciliationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCommentConciliationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCommentConciliationInstance {
        onUpdateCommentConciliationInstance {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCommentConciliationInstance">
    >
  >;

  OnDeleteCommentConciliationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCommentConciliationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCommentConciliationInstance {
        onDeleteCommentConciliationInstance {
          __typename
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          comment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCommentConciliationInstance">
    >
  >;

  OnCreateVerifiedSymptomInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedSymptomInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVerifiedSymptomInstance {
        onCreateVerifiedSymptomInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedSymptomInstance">
    >
  >;

  OnUpdateVerifiedSymptomInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedSymptomInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVerifiedSymptomInstance {
        onUpdateVerifiedSymptomInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedSymptomInstance">
    >
  >;

  OnDeleteVerifiedSymptomInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedSymptomInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVerifiedSymptomInstance {
        onDeleteVerifiedSymptomInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          id
          state
          type
          symptom
          whichOtherSymptom
          whichOtherSymptomComment
          intensity
          intensityComment
          size
          sizeComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          isOtherSymptom
          medications
          medicationsComment
          medicalAttentions
          medicalAttentionsComment
          professionalHealthCare
          professionalHealthCareComment
          hospitalAdmission
          hospitalAdmissionComment
          intensityQuestionAnswers {
            __typename
            answer
          }
          intensityQuestionAnswersComment
          rememberNoValuesTaken
          rememberNoValuesTakenComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          verifiedSymptomInstanceConfSymptomId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedSymptomInstance">
    >
  >;

  OnCreateVerifiedSymptomRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedSymptomRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVerifiedSymptomRecordLog {
        onCreateVerifiedSymptomRecordLog {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedSymptomRecordLog">
    >
  >;

  OnUpdateVerifiedSymptomRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedSymptomRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVerifiedSymptomRecordLog {
        onUpdateVerifiedSymptomRecordLog {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedSymptomRecordLog">
    >
  >;

  OnDeleteVerifiedSymptomRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedSymptomRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVerifiedSymptomRecordLog {
        onDeleteVerifiedSymptomRecordLog {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedSymptomInstanceId
          verifiedSymptomInstance {
            __typename
            subjectId
            verifiedDayInstanceId
            confSymptomId
            symptomInstanceId
            id
            state
            type
            symptom
            whichOtherSymptom
            whichOtherSymptomComment
            intensity
            intensityComment
            size
            sizeComment
            rememberStartDate
            rememberStartDateComment
            startDate
            startDateComment
            rememberFinishDate
            rememberFinishDateComment
            finishDate
            finishDateComment
            isOtherSymptom
            medications
            medicationsComment
            medicalAttentions
            medicalAttentionsComment
            professionalHealthCare
            professionalHealthCareComment
            hospitalAdmission
            hospitalAdmissionComment
            intensityQuestionAnswersComment
            rememberNoValuesTaken
            rememberNoValuesTakenComment
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            verifiedSymptomInstanceConfSymptomId
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomRecordLogId
          symptomRecordLog {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          intensityComment
          size
          sizeComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedSymptomRecordLog">
    >
  >;

  OnCreateVerifiedMedicationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedMedicationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVerifiedMedicationInstance {
        onCreateVerifiedMedicationInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedMedicationInstance">
    >
  >;

  OnUpdateVerifiedMedicationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedMedicationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVerifiedMedicationInstance {
        onUpdateVerifiedMedicationInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedMedicationInstance">
    >
  >;

  OnDeleteVerifiedMedicationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedMedicationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVerifiedMedicationInstance {
        onDeleteVerifiedMedicationInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicationInstanceId
          medicationInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          isOnGoing
          isOnGoingComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          name
          nameComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          givenTo
          givenToComment
          dose
          doseComment
          frequency
          frequencyComment
          administrationRoute
          administrationRouteComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedMedicationInstance">
    >
  >;

  OnCreateVerifiedMedicalAttentionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedMedicalAttentionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVerifiedMedicalAttentionInstance {
        onCreateVerifiedMedicalAttentionInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedMedicalAttentionInstance">
    >
  >;

  OnUpdateVerifiedMedicalAttentionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedMedicalAttentionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVerifiedMedicalAttentionInstance {
        onUpdateVerifiedMedicalAttentionInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedMedicalAttentionInstance">
    >
  >;

  OnDeleteVerifiedMedicalAttentionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedMedicalAttentionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVerifiedMedicalAttentionInstance {
        onDeleteVerifiedMedicalAttentionInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          medicalAttentionInstanceId
          medicalAttentionInstance {
            __typename
            subjectId
            dayInstanceId
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          typeComment
          reason
          reasonComment
          symptomsInstances
          symptomsInstancesComment
          rememberStartDate
          rememberStartDateComment
          startDate
          startDateComment
          rememberFinishDate
          rememberFinishDateComment
          finishDate
          finishDateComment
          wasHospitalized
          wasHospitalizedComment
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedMedicalAttentionInstance">
    >
  >;

  OnCreateVerifiedTemperatureRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedTemperatureRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVerifiedTemperatureRecordLog {
        onCreateVerifiedTemperatureRecordLog {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateVerifiedTemperatureRecordLog">
    >
  >;

  OnUpdateVerifiedTemperatureRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedTemperatureRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVerifiedTemperatureRecordLog {
        onUpdateVerifiedTemperatureRecordLog {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateVerifiedTemperatureRecordLog">
    >
  >;

  OnDeleteVerifiedTemperatureRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedTemperatureRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVerifiedTemperatureRecordLog {
        onDeleteVerifiedTemperatureRecordLog {
          __typename
          verifiedDayInstanceId
          verifiedDayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            dayInstanceId
            startDate
            finishDate
            completedDate
            hasMedication
            hasMedicationCompleted
            hasMedicationComment
            medicationGivenTo
            medicationGivenToComment
            hasMedicalAttention
            hasMedicalAttentionCompleted
            hasMedicalAttentionComment
            hasOtherSymptoms
            hasOtherSymptomsCompleted
            hasOtherSymptomsComment
            symptomOcurrenciesComment
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          temperatureRecordLogID
          temperatureRecordLog {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          temperature
          temperatureComment
          unit
          unitComment
          route
          routeComment
          whichOtherRoute
          whichOtherRouteComment
          taken
          takenComment
          takenDate
          takenDateComment
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteVerifiedTemperatureRecordLog">
    >
  >;

  OnCreateProjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProject {
        onCreateProject {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProject">>
  >;

  OnUpdateProjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProject {
        onUpdateProject {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProject">>
  >;

  OnDeleteProjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProject {
        onDeleteProject {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProject">>
  >;

  OnCreateConfVisitGroupListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfVisitGroup">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfVisitGroup {
        onCreateConfVisitGroup {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfVisitGroup">
    >
  >;

  OnUpdateConfVisitGroupListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfVisitGroup">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfVisitGroup {
        onUpdateConfVisitGroup {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfVisitGroup">
    >
  >;

  OnDeleteConfVisitGroupListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfVisitGroup">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfVisitGroup {
        onDeleteConfVisitGroup {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfVisitGroup">
    >
  >;

  OnCreateSiteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSite">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSite {
        onCreateSite {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSite">>
  >;

  OnUpdateSiteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSite">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSite {
        onUpdateSite {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSite">>
  >;

  OnDeleteSiteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSite">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSite {
        onDeleteSite {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          timezoneRecipients
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSite">>
  >;

  OnCreateAuthorizedSiteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAuthorizedSite">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAuthorizedSite {
        onCreateAuthorizedSite {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAuthorizedSite">
    >
  >;

  OnUpdateAuthorizedSiteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAuthorizedSite">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAuthorizedSite {
        onUpdateAuthorizedSite {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAuthorizedSite">
    >
  >;

  OnDeleteAuthorizedSiteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAuthorizedSite">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAuthorizedSite {
        onDeleteAuthorizedSite {
          __typename
          id
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAuthorizedSite">
    >
  >;

  OnCreateConfReportListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfReport">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfReport {
        onCreateConfReport {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfReport">>
  >;

  OnUpdateConfReportListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfReport">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfReport {
        onUpdateConfReport {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfReport">>
  >;

  OnDeleteConfReportListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfReport">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfReport {
        onDeleteConfReport {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          groups
          phases
          instancePerDay
          reportTitle
          showHelp
          reportHelp
          reportIcon
          symptomPageLayout
          programationType
          plannedDayList
          isDisplayDependentOnTheEDiary
          forms
          isControlledBySite
          allowReportBackDating
          enableLocalNotifications
          futureNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          jsonForm
          isSignatureRequired
          isGPSRequired
          isUserIPRequired
          isMediaRequired
          isDeviceDataRequired
          mediaTypes
          isAssociatedToVisits
          areSymptomsRequired
          areAlertsRequired
          isDeviceDataManagementRequired
          availableUsers
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfReport">>
  >;

  OnCreateConfReportBySubjectListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfReportBySubject">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfReportBySubject {
        onCreateConfReportBySubject {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfReportBySubject">
    >
  >;

  OnUpdateConfReportBySubjectListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfReportBySubject">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfReportBySubject {
        onUpdateConfReportBySubject {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfReportBySubject">
    >
  >;

  OnDeleteConfReportBySubjectListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfReportBySubject">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfReportBySubject {
        onDeleteConfReportBySubject {
          __typename
          id
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          isEnabled
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfReportBySubject">
    >
  >;

  OnCreateConfEDiaryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfEDiary">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfEDiary {
        onCreateConfEDiary {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfEDiary">>
  >;

  OnUpdateConfEDiaryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfEDiary">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfEDiary {
        onUpdateConfEDiary {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfEDiary">>
  >;

  OnDeleteConfEDiaryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfEDiary">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfEDiary {
        onDeleteConfEDiary {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfEDiary">>
  >;

  OnCreateConfDayListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfDay">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfDay {
        onCreateConfDay {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfDay">>
  >;

  OnUpdateConfDayListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfDay">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfDay {
        onUpdateConfDay {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfDay">>
  >;

  OnDeleteConfDayListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfDay">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfDay {
        onDeleteConfDay {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          dayName
          order
          startDay
          endDay
          groups
          phases
          trackSymptomOcurrencies
          hidePDFDayColumn
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfDay">>
  >;

  OnCreateConfFormListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfForm">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfForm {
        onCreateConfForm {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfForm">>
  >;

  OnUpdateConfFormListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfForm">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfForm {
        onUpdateConfForm {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfForm">>
  >;

  OnDeleteConfFormListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfForm">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfForm {
        onDeleteConfForm {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowAdditionalQuestionsReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfForm">>
  >;

  OnCreateConfSymptomListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfSymptom">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfSymptom {
        onCreateConfSymptom {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfSymptom">>
  >;

  OnUpdateConfSymptomListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfSymptom">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfSymptom {
        onUpdateConfSymptom {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfSymptom">>
  >;

  OnDeleteConfSymptomListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfSymptom">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfSymptom {
        onDeleteConfSymptom {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfSymptom">>
  >;

  OnCreateConfFormConfSymptomListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfFormConfSymptom">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfFormConfSymptom {
        onCreateConfFormConfSymptom {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfFormConfSymptom">
    >
  >;

  OnUpdateConfFormConfSymptomListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfFormConfSymptom">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfFormConfSymptom {
        onUpdateConfFormConfSymptom {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfFormConfSymptom">
    >
  >;

  OnDeleteConfFormConfSymptomListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfFormConfSymptom">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfFormConfSymptom {
        onDeleteConfFormConfSymptom {
          __typename
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfFormConfSymptom">
    >
  >;

  OnCreateConfMedicationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfMedication">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfMedication {
        onCreateConfMedication {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfMedication">
    >
  >;

  OnUpdateConfMedicationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfMedication">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfMedication {
        onUpdateConfMedication {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfMedication">
    >
  >;

  OnDeleteConfMedicationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfMedication">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfMedication {
        onDeleteConfMedication {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfMedication">
    >
  >;

  OnCreateConfMedicalAttentionListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfMedicalAttention">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfMedicalAttention {
        onCreateConfMedicalAttention {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfMedicalAttention">
    >
  >;

  OnUpdateConfMedicalAttentionListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfMedicalAttention">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfMedicalAttention {
        onUpdateConfMedicalAttention {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfMedicalAttention">
    >
  >;

  OnDeleteConfMedicalAttentionListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfMedicalAttention">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfMedicalAttention {
        onDeleteConfMedicalAttention {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfMedicalAttention">
    >
  >;

  OnCreateConfTemperatureListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfTemperature">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfTemperature {
        onCreateConfTemperature {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfTemperature">
    >
  >;

  OnUpdateConfTemperatureListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfTemperature">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfTemperature {
        onUpdateConfTemperature {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfTemperature">
    >
  >;

  OnDeleteConfTemperatureListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfTemperature">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfTemperature {
        onDeleteConfTemperature {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showFahrenheitUnit
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfTemperature">
    >
  >;

  OnCreateConfMultimediaListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfMultimedia">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfMultimedia {
        onCreateConfMultimedia {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfMultimedia">
    >
  >;

  OnUpdateConfMultimediaListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfMultimedia">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfMultimedia {
        onUpdateConfMultimedia {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfMultimedia">
    >
  >;

  OnDeleteConfMultimediaListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfMultimedia">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfMultimedia {
        onDeleteConfMultimedia {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfMultimedia">
    >
  >;

  OnCreateConfOtherSymptomsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfOtherSymptoms">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfOtherSymptoms {
        onCreateConfOtherSymptoms {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfOtherSymptoms">
    >
  >;

  OnUpdateConfOtherSymptomsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfOtherSymptoms">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfOtherSymptoms {
        onUpdateConfOtherSymptoms {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfOtherSymptoms">
    >
  >;

  OnDeleteConfOtherSymptomsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfOtherSymptoms">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfOtherSymptoms {
        onDeleteConfOtherSymptoms {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfOtherSymptoms">
    >
  >;

  OnCreateConfAlertListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfAlert">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfAlert {
        onCreateConfAlert {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfAlert">>
  >;

  OnUpdateConfAlertListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfAlert">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfAlert {
        onUpdateConfAlert {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfAlert">>
  >;

  OnDeleteConfAlertListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfAlert">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfAlert {
        onDeleteConfAlert {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfAlert">>
  >;

  OnCreateConfDictionaryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfDictionary">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfDictionary {
        onCreateConfDictionary {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfDictionary">
    >
  >;

  OnUpdateConfDictionaryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfDictionary">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfDictionary {
        onUpdateConfDictionary {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfDictionary">
    >
  >;

  OnDeleteConfDictionaryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfDictionary">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfDictionary {
        onDeleteConfDictionary {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfDictionary">
    >
  >;

  OnCreateConfInformedConsentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfInformedConsent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfInformedConsent {
        onCreateConfInformedConsent {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfInformedConsent">
    >
  >;

  OnUpdateConfInformedConsentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfInformedConsent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfInformedConsent {
        onUpdateConfInformedConsent {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfInformedConsent">
    >
  >;

  OnDeleteConfInformedConsentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfInformedConsent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfInformedConsent {
        onDeleteConfInformedConsent {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          icVersion
          recipients
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          type
          quizPageLayout
          approvalSealFileUrl
          isMultipleSignatureRequired
          state
          minRequiredSignatures
          maxRequiredSignatures
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfInformedConsent">
    >
  >;

  OnCreateConfICSectionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfICSection">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfICSection {
        onCreateConfICSection {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfICSection">>
  >;

  OnUpdateConfICSectionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfICSection">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfICSection {
        onUpdateConfICSection {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfICSection">>
  >;

  OnDeleteConfICSectionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfICSection">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfICSection {
        onDeleteConfICSection {
          __typename
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          title
          type
          order
          content
          mediaUrl
          mediaType
          icon
          complementaryQuestions {
            __typename
            id
            question
            type
            options
          }
          isElectronicSignatureRequired
          isStudyRolRequired
          enableApprovalQuestions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfICSection">>
  >;

  OnCreateConfICQuestionListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfICQuestion">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfICQuestion {
        onCreateConfICQuestion {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConfICQuestion">
    >
  >;

  OnUpdateConfICQuestionListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfICQuestion">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfICQuestion {
        onUpdateConfICQuestion {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConfICQuestion">
    >
  >;

  OnDeleteConfICQuestionListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfICQuestion">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfICQuestion {
        onDeleteConfICQuestion {
          __typename
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConfICQuestion">
    >
  >;

  OnCreateConfICAnswerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfICAnswer">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConfICAnswer {
        onCreateConfICAnswer {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfICAnswer">>
  >;

  OnUpdateConfICAnswerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfICAnswer">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConfICAnswer {
        onUpdateConfICAnswer {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfICAnswer">>
  >;

  OnDeleteConfICAnswerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfICAnswer">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConfICAnswer {
        onDeleteConfICAnswer {
          __typename
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          confInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          description
          order
          onErrorMessage
          isCorrect
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfICAnswer">>
  >;

  OnCreateInformedConsentInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInformedConsentInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInformedConsentInstance {
        onCreateInformedConsentInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInformedConsentInstance">
    >
  >;

  OnUpdateInformedConsentInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInformedConsentInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInformedConsentInstance {
        onUpdateInformedConsentInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInformedConsentInstance">
    >
  >;

  OnDeleteInformedConsentInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInformedConsentInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInformedConsentInstance {
        onDeleteInformedConsentInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            recipients
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            state
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          consenterUser {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedIdentificator
          consentedName
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInformedConsentInstance">
    >
  >;

  OnCreateConsentedUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConsentedUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConsentedUser {
        onCreateConsentedUser {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConsentedUser">>
  >;

  OnUpdateConsentedUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConsentedUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConsentedUser {
        onUpdateConsentedUser {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConsentedUser">>
  >;

  OnDeleteConsentedUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConsentedUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConsentedUser {
        onDeleteConsentedUser {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          user {
            __typename
            id
            login
            name
            email
            role
            roles
            permissions
            state
            phoneNumber
            firebaseToken
            isMFAActivated
            notificationPreference
            subjects
            sites
            projects
            appVersion
            lastTimezone
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          kinship
          identification
          state
          stateChanges {
            __typename
            state
            date
          }
          requiresSendingEmail
          signature
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConsentedUser">>
  >;

  OnCreateICSectionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateICSectionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateICSectionInstance {
        onCreateICSectionInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateICSectionInstance">
    >
  >;

  OnUpdateICSectionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateICSectionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateICSectionInstance {
        onUpdateICSectionInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateICSectionInstance">
    >
  >;

  OnDeleteICSectionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteICSectionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteICSectionInstance {
        onDeleteICSectionInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consentedSignatureDate
          complementaryQuestionsAnswers {
            __typename
            type
            answer
          }
          progress
          startDate
          completedDate
          isCompleted
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteICSectionInstance">
    >
  >;

  OnCreateICQuestionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateICQuestionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateICQuestionInstance {
        onCreateICQuestionInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateICQuestionInstance">
    >
  >;

  OnUpdateICQuestionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateICQuestionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateICQuestionInstance {
        onUpdateICQuestionInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateICQuestionInstance">
    >
  >;

  OnDeleteICQuestionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteICQuestionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteICQuestionInstance {
        onDeleteICQuestionInstance {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          informedConsentInstanceId
          informedConsentInstance {
            __typename
            projectId
            siteId
            confInformedConsentId
            consenterUserId
            subjectId
            id
            consentedIdentificator
            consentedName
            consenterName
            consenterIdentification
            consenterSignature
            consenterRole
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICQuestionId
          confICQuestion {
            __typename
            confICSectionId
            confInformedConsentId
            projectId
            id
            description
            order
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSectionId
          consentedUserId
          consentedUser {
            __typename
            projectId
            siteId
            userId
            informedConsentInstanceId
            id
            name
            kinship
            identification
            state
            requiresSendingEmail
            signature
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confICSection {
            __typename
            confInformedConsentId
            projectId
            id
            title
            type
            order
            content
            mediaUrl
            mediaType
            icon
            isElectronicSignatureRequired
            isStudyRolRequired
            enableApprovalQuestions
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          failedAttempts
          isCompleted
          startDate
          completedDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteICQuestionInstance">
    >
  >;

  OnCreateSubjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSubject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSubject {
        onCreateSubject {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSubject">>
  >;

  OnUpdateSubjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSubject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSubject {
        onUpdateSubject {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSubject">>
  >;

  OnDeleteSubjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSubject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSubject {
        onDeleteSubject {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSubject">>
  >;

  OnCreateReportInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateReportInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateReportInstance {
        onCreateReportInstance {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateReportInstance">
    >
  >;

  OnUpdateReportInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateReportInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateReportInstance {
        onUpdateReportInstance {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateReportInstance">
    >
  >;

  OnDeleteReportInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteReportInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteReportInstance {
        onDeleteReportInstance {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteReportInstance">
    >
  >;

  OnCreateSignatureInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSignatureInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSignatureInstance {
        onCreateSignatureInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSignatureInstance">
    >
  >;

  OnUpdateSignatureInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSignatureInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSignatureInstance {
        onUpdateSignatureInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSignatureInstance">
    >
  >;

  OnDeleteSignatureInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSignatureInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSignatureInstance {
        onDeleteSignatureInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          date
          type
          reason
          entityType
          entityId
          signedInfo
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSignatureInstance">
    >
  >;

  OnCreateEDiaryInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateEDiaryInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEDiaryInstance {
        onCreateEDiaryInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateEDiaryInstance">
    >
  >;

  OnUpdateEDiaryInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEDiaryInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEDiaryInstance {
        onUpdateEDiaryInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEDiaryInstance">
    >
  >;

  OnDeleteEDiaryInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteEDiaryInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEDiaryInstance {
        onDeleteEDiaryInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEDiaryId
          confEDiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          confEdiaryVersion
          comment
          alerts
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteEDiaryInstance">
    >
  >;

  OnCreateEDiaryPhaseInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateEDiaryPhaseInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEDiaryPhaseInstance {
        onCreateEDiaryPhaseInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateEDiaryPhaseInstance">
    >
  >;

  OnUpdateEDiaryPhaseInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEDiaryPhaseInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEDiaryPhaseInstance {
        onUpdateEDiaryPhaseInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEDiaryPhaseInstance">
    >
  >;

  OnDeleteEDiaryPhaseInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteEDiaryPhaseInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEDiaryPhaseInstance {
        onDeleteEDiaryPhaseInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryInstanceId
          eDiaryInstance {
            __typename
            subjectId
            confEDiaryId
            siteId
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          phase
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          pdfState
          completedPhaseDate
          suspensionReason
          suspensionDate
          suspensionUser
          locked {
            __typename
            user
            date
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteEDiaryPhaseInstance">
    >
  >;

  OnCreateDayInstanceListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDayInstance">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDayInstance {
        onCreateDayInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDayInstance">>
  >;

  OnUpdateDayInstanceListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDayInstance">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDayInstance {
        onUpdateDayInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDayInstance">>
  >;

  OnDeleteDayInstanceListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDayInstance">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDayInstance {
        onDeleteDayInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            timezoneRecipients
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          timezonesHistory
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDayInstance">>
  >;

  OnCreateSymptomRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSymptomRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSymptomRecordLog {
        onCreateSymptomRecordLog {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSymptomRecordLog">
    >
  >;

  OnUpdateSymptomRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSymptomRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSymptomRecordLog {
        onUpdateSymptomRecordLog {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSymptomRecordLog">
    >
  >;

  OnDeleteSymptomRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSymptomRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSymptomRecordLog {
        onDeleteSymptomRecordLog {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          symptomInstanceId
          symptomInstance {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
            symptomInstanceConfSymptomId
          }
          state
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          intensity
          size
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSymptomRecordLog">
    >
  >;

  OnCreateSymptomInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSymptomInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSymptomInstance {
        onCreateSymptomInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSymptomInstance">
    >
  >;

  OnUpdateSymptomInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSymptomInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSymptomInstance {
        onUpdateSymptomInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSymptomInstance">
    >
  >;

  OnDeleteSymptomInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSymptomInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSymptomInstance {
        onDeleteSymptomInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
          symptomInstanceConfSymptomId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSymptomInstance">
    >
  >;

  OnCreateMedicationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMedicationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMedicationInstance {
        onCreateMedicationInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMedicationInstance">
    >
  >;

  OnUpdateMedicationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMedicationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMedicationInstance {
        onUpdateMedicationInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMedicationInstance">
    >
  >;

  OnDeleteMedicationInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMedicationInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMedicationInstance {
        onDeleteMedicationInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          rememberStartDate
          startDate
          isOnGoing
          rememberFinishDate
          finishDate
          name
          reason
          symptomsInstances
          givenTo
          dose
          frequency
          administrationRoute
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMedicationInstance">
    >
  >;

  OnCreateMedicalAttentionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMedicalAttentionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMedicalAttentionInstance {
        onCreateMedicalAttentionInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMedicalAttentionInstance">
    >
  >;

  OnUpdateMedicalAttentionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMedicalAttentionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMedicalAttentionInstance {
        onUpdateMedicalAttentionInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMedicalAttentionInstance">
    >
  >;

  OnDeleteMedicalAttentionInstanceListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMedicalAttentionInstance">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMedicalAttentionInstance {
        onDeleteMedicalAttentionInstance {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          type
          reason
          symptomsInstances
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          wasHospitalized
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMedicalAttentionInstance">
    >
  >;

  OnCreateTemperatureRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTemperatureRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTemperatureRecordLog {
        onCreateTemperatureRecordLog {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTemperatureRecordLog">
    >
  >;

  OnUpdateTemperatureRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTemperatureRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTemperatureRecordLog {
        onUpdateTemperatureRecordLog {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTemperatureRecordLog">
    >
  >;

  OnDeleteTemperatureRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTemperatureRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTemperatureRecordLog {
        onDeleteTemperatureRecordLog {
          __typename
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          temperature
          temperatureUnit
          temperatureRoute
          temperatureWhichOtherRoute
          date
          temperatureTaken
          temperatureTakenDate
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTemperatureRecordLog">
    >
  >;

  OnCreateMediaRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMediaRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMediaRecordLog {
        onCreateMediaRecordLog {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMediaRecordLog">
    >
  >;

  OnUpdateMediaRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMediaRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMediaRecordLog {
        onUpdateMediaRecordLog {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMediaRecordLog">
    >
  >;

  OnDeleteMediaRecordLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMediaRecordLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMediaRecordLog {
        onDeleteMediaRecordLog {
          __typename
          dayInstanceId
          reportInstanceId
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          mediaType
          url
          comment
          dateTime
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMediaRecordLog">
    >
  >;

  OnCreateAlertInstanceListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAlertInstance">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAlertInstance {
        onCreateAlertInstance {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAlertInstance">>
  >;

  OnUpdateAlertInstanceListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAlertInstance">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAlertInstance {
        onUpdateAlertInstance {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAlertInstance">>
  >;

  OnDeleteAlertInstanceListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAlertInstance">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAlertInstance {
        onDeleteAlertInstance {
          __typename
          confAlertId
          confAlert {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            alertName
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            triggerVisitCompletion
            triggerEDiarySuspension
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          confAlert_data
          entityId
          entityName
          state
          revisionState
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAlertInstance">>
  >;

  OnCreatePDFAndAlertsTransactionLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreatePDFAndAlertsTransactionLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePDFAndAlertsTransactionLog {
        onCreatePDFAndAlertsTransactionLog {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreatePDFAndAlertsTransactionLog">
    >
  >;

  OnUpdatePDFAndAlertsTransactionLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdatePDFAndAlertsTransactionLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePDFAndAlertsTransactionLog {
        onUpdatePDFAndAlertsTransactionLog {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdatePDFAndAlertsTransactionLog">
    >
  >;

  OnDeletePDFAndAlertsTransactionLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeletePDFAndAlertsTransactionLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePDFAndAlertsTransactionLog {
        onDeletePDFAndAlertsTransactionLog {
          __typename
          subjectId
          startDate
          finishDate
          state
          source
          eDiaryPhaseInstanceId
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confReportId
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            timezonesHistory
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          _lastUser
          _changeReason
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeletePDFAndAlertsTransactionLog">
    >
  >;
}
