<main style="position: relative">
  <nav [ngClass]="{ 'bg-dark': darkmodeActive }">
    <ul class="list-json-options">
      <li>
        <button
          type="button"
          [pTooltip]="'designer.json.formatDocument' | translate"
          tooltipPosition="top"
          tooltipStyleClass="tp2-tooltip tp2-tooltip-top"
          (click)="activateAction('editor.action.formatDocument')"
        >
          <span class="pi pi-align-justify"></span>
        </button>
      </li>
      <li>
        <button type="button">
          <span
            class="pi pi-search"
            [pTooltip]="'designer.json.openSearch' | translate"
            tooltipPosition="top"
            tooltipStyleClass="tp2-tooltip tp2-tooltip-top"
            (click)="activateAction('actions.find')"
          ></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          [pTooltip]="'designer.json.increaseFont' | translate"
          tooltipPosition="top"
          tooltipStyleClass="tp2-tooltip tp2-tooltip-top"
          (click)="activateAction('editor.action.fontZoomIn')"
        >
          <span
            class="pi pi-search-plus"
            (click)="activateAction('editor.action.fontZoomIn')"
          ></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          [pTooltip]="'designer.json.decreaseFont' | translate"
          tooltipPosition="top"
          tooltipStyleClass="tp2-tooltip tp2-tooltip-top"
          (click)="activateAction('editor.action.fontZoomOut')"
        >
          <span class="pi pi-search-minus"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          [pTooltip]="'designer.json.undo' | translate"
          tooltipPosition="top"
          tooltipStyleClass="tp2-tooltip tp2-tooltip-top"
          (click)="keyBoardAction('undo')"
        >
          <span class="pi pi-reply rotate-180"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          [pTooltip]="'designer.json.redo' | translate"
          tooltipPosition="top"
          tooltipStyleClass="tp2-tooltip tp2-tooltip-top"
          (click)="keyBoardAction('redo')"
        >
          <span class="pi pi-reply"></span>
        </button>
      </li>
    </ul>
    <div class="mode">
      <div class="toggle-container">
        <span class="pi pi-sun"></span>
        <label class="switch">
          <input
            type="checkbox"
            [value]="darkmodeActive"
            (change)="changeEditorTheme()"
          />
          <span class="slider round"></span>
        </label>
        <span class="pi pi-moon"></span>
      </div>
    </div>
  </nav>

  <div class="my-code-editor">
    <ngx-monaco-editor
      class="editor-container"
      [ngClass]="{ 'bg-dark': darkmodeActive }"
      [options]="editorOptions"
      [(ngModel)]="formField"
      (ngModelChange)="onEditorContentChange()"
      (onInit)="initEditor($event)"
    ></ngx-monaco-editor>
  </div>
</main>
