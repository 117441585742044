import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { ConciliationRoutingModule } from './conciliation-routing.module';
import { DetailDaysCommentComponent } from './detail-conciliation/components/comments/detail-days-comment/detail-days-comment.component';
import { EditCommentComponent } from './detail-conciliation/components/comments/edit-comment/edit-comment.component';
import { DetailDaysMedicalAttentionComponent } from './detail-conciliation/components/medical-attention/detail-days-medical-attention/detail-days-medical-attention.component';
import { EditMedicalAttentionComponent } from './detail-conciliation/components/medical-attention/edit-medical-attention/edit-medical-attention.component';
import { SummaryMedicalAttentionComponent } from './detail-conciliation/components/medical-attention/summary-medical-attention/summary-medical-attention.component';
import { DetailDaysMedicationComponent } from './detail-conciliation/components/medication/detail-days-medication/detail-days-medication.component';
import { EditMedicationComponent } from './detail-conciliation/components/medication/edit-medication/edit-medication.component';
import { SummaryModalComponent } from './detail-conciliation/components/medication/summary-modal/summary-modal.component';
import { DetailOtherSymptomsComponent } from './detail-conciliation/components/other-symptom/detail-other-symptoms/detail-other-symptoms.component';
import { EditOtherSymptomsComponent } from './detail-conciliation/components/other-symptom/edit-other-symptoms/edit-other-symptoms.component';
import { SummaryOtherSymptomComponent } from './detail-conciliation/components/other-symptom/summary/summary-other-symptoms.component';
import { ChangeReasonComponent } from './detail-conciliation/components/shared/change-reason/change-reason.component';
import { CheckComponent } from './detail-conciliation/components/shared/check/check.component';
import { EditDayInstanceComponent } from './detail-conciliation/components/shared/day-instance/edit-day-instance/edit-day-instance.component';
import { SummaryDayComponent } from './detail-conciliation/components/shared/day-instance/summary-day/summary-day.component';
import { TableDayInstanceComponent } from './detail-conciliation/components/shared/day-instance/table-day-instance/table-day-instance.component';
import { DeleteReasonComponent } from './detail-conciliation/components/shared/delete-reason/delete-reason.component';
import { FormButtonsComponent } from './detail-conciliation/components/shared/form-buttons/form-buttons.component';
import { SignatureConciliationComponent } from './detail-conciliation/components/signature-conciliation/signature-conciliation.component';
import { DetailSymptomComponent } from './detail-conciliation/components/symptom/detail-symptom/detail-symptom.component';
import { EditSymptomComponent } from './detail-conciliation/components/symptom/edit-symptom/edit-symptom.component';
import { SummarySymptomComponent } from './detail-conciliation/components/symptom/summary-symptom/summary-symptom.component';
import { DetailDaysTemperatureComponent } from './detail-conciliation/components/temperature/detail-days-temperature/detail-days-temperature.component';
import { TemperatureFormComponent } from './detail-conciliation/components/temperature/edit-temperature/temperatureForm.component';
import { SummaryTemperatureComponent } from './detail-conciliation/components/temperature/summary-temperature/summary-temperature.component';
import { DetailConciliationComponent } from './detail-conciliation/detail-conciliation.component';

@NgModule({
  declarations: [
    DetailConciliationComponent,
    TemperatureFormComponent,
    SignatureConciliationComponent,
    DetailDaysTemperatureComponent,
    DetailDaysMedicationComponent,
    EditMedicationComponent,
    FormButtonsComponent,
    SummaryModalComponent,
    SummaryTemperatureComponent,
    DetailDaysCommentComponent,
    EditCommentComponent,
    SummaryDayComponent,
    DetailDaysMedicalAttentionComponent,
    EditMedicalAttentionComponent,
    DetailSymptomComponent,
    EditSymptomComponent,
    CheckComponent,
    SummaryMedicalAttentionComponent,
    DetailOtherSymptomsComponent,
    EditOtherSymptomsComponent,
    EditDayInstanceComponent,
    TableDayInstanceComponent,
    SummaryOtherSymptomComponent,
    SummarySymptomComponent,
    DeleteReasonComponent,
    ChangeReasonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ConciliationRoutingModule,
    PrimengModule,
    TranslateModule,
    SharedComponentsModule,
  ],
  providers: [DictionaryPipe, TranslatePipe, NgForm],
})
export class ConciliationModule {}
