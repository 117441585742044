import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortText',
})
export class ShortTextPipe implements PipeTransform {
  /**
   * Permite generar una versión corta de un texto, dado el limite de caracteres y agregando '...' al final
   * @param value texto a recortar
   * @param limit limite de caracteres
   * @returns texto corto
   */
  transform(value?: string, limit?: number): string {
    if (value) {
      const maxLength = limit ?? 25;
      if (value.length > maxLength) {
        return value.substring(0, maxLength) + '...';
      } else {
        return value;
      }
    }
    return '';
  }
}
