<section
  class="signature-container"
  [ngClass]="{ 'col-1': !confICSection, 'col-2': confICSection }"
>
  <article class="border-right section-info" *ngIf="confICSection">
    <div>
      <h2
        class="p-mt-0 p-mb-3 title"
        [pTooltip]="confICSection.title | dictionary"
      >
        {{ confICSection.title | dictionary }}
      </h2>
      <div
        class="scroll-container p-px-1"
        [ngClass]="{ 'max-scroll': !isStudyRolRequired }"
        [innerHTML]="confICSection.content | dictionary"
      ></div>
    </div>
    <div *ngIf="isStudyRolRequired">
      <label id="lbl_user_rol" name="lbl_user" for="txt_user_rol"
        >{{ 'informedConsent.followUp.signature.rolStudy' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <input
        type="text"
        pInputText
        id="txt_user_rol"
        name="txt_user_rol"
        #userRol="ngModel"
        [(ngModel)]="consenterRol"
        required
        placeholder="{{
          'informedConsent.followUp.signature.rolStudyPlaceholder' | translate
        }}"
      />
      <small class="form-error" *ngIf="userRol.invalid && userRol.touched"
        >{{ 'general.required' | translate }}
      </small>
    </div>
  </article>
  <article>
    <app-signature
      [title]="'signature.title' | translate"
      [description]="'signature.descriptionEconsent' | translate"
      [loadingText]="'signature.loadingSignature' | translate"
      [disabled]="consenterRol.trim() === '' && isStudyRolRequired"
      (isUserAuthenticated)="isUserAuthenticated()"
    ></app-signature>
  </article>
</section>
