import { NgModule } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { ChatConfigurationComponent } from './chat-configuration/chat-configuration.component';
import { ChatRoutingModule } from './chat-routing.module';
import { SiteChatComponent } from './site-chat/site-chat.component';

@NgModule({
  declarations: [SiteChatComponent, ChatConfigurationComponent],
  imports: [PrimengModule, SharedComponentsModule, ChatRoutingModule],
  providers: [TranslatePipe],
})
export class ChatModule {}
