import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Analytics, Logger } from 'aws-amplify';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
const logger = new Logger('tp2-logger-view-pdf');
@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfComponent implements OnInit {
  isFileUploaded = false;
  storagePdfUrl!: SafeResourceUrl;
  disablePrint: boolean = false;
  showDownloadButton: boolean = false;
  showPrintButton: boolean = false;
  PDFUrl: string = '';
  pdfSrc = '';
  pdfProperties = {
    zoomLevel: 0.8,
    currentPage: 0,
    totalPages: 0,
    pdfName: '',
  };
  constructor(
    public sanitizer: DomSanitizer,
    private storage: StorageService,
    private trialpalService: TrialpalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private http: HttpClient,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.sanitizer = sanitizer;
    this.storagePdfUrl = this.transformToSecureUrl(this.PDFUrl);
    this.isFileUploaded = false;
  }

  @ViewChild('outsideElement') outsideElement!: ElementRef;
  @ViewChild('modalView') modalView$!: ElementRef;

  async ngOnInit(): Promise<void> {
    try {
      this.trialpalService.showSpinner('PDF', 'GET');
      this.extractConfigData();

      this.showNoPdfMessageIfRequired();
      await this.processPdfExistence();
    } finally {
      this.trialpalService.hideSpinner();
    }
  }

  /** La función "extractConfigData" extrae los valores PDFUrl y enablePrint de los datos de configuración.*/
  private extractConfigData(): void {
    const { data } = this.config || {};

    this.PDFUrl = data?.PDFUrl;
    this.disablePrint = data?.disablePrint;
    this.showDownloadButton = data?.showDownloadButton;
    this.showPrintButton = data?.showPrintButton;
  }

  /** La función comprueba si se proporciona una URL de PDF y muestra un mensaje si no es así.  */
  private showNoPdfMessageIfRequired(): void {
    if (!this.PDFUrl) {
      this.showNoPdfMessage();
    }
  }

  /* La función comprueba si existe un archivo PDF en un almacenamiento S3 y lo recupera si existe.*/
  private async processPdfExistence(): Promise<void> {
    await this.checkPdfExistenceInS3();
    await this.getPdfFromStorageByUrl(this.PDFUrl);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // Verificar si se presiona Ctrl + P
    if (event.ctrlKey && event.key.toLocaleLowerCase() === 'p') {
      // Prevenir el comportamiento por defecto
      event.preventDefault();
    }
  }

  @HostListener('contextmenu', ['$event'])
  blockRightClick(event: Event): void {
    event.preventDefault();
  }

  /**
   * La función "transformToSecureUrl" toma una URL como entrada y devuelve una versión segura de la
   * URL utilizando el objeto "sanitizer".
   * @param {any} url - El parámetro `url` es la URL que desea transformar en una URL segura.
   * @returns el resultado del método `bypassSecurityTrustResourceUrl` del objeto `sanitizer`.
   */
  transformToSecureUrl(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  async checkPdfExistenceInS3() {
    const listPDF = await this.getListOfPDFs();
    this.logListOfPDFs(listPDF);
    this.showNoPdfMessageIfEmpty(listPDF);
  }

  private async getListOfPDFs(): Promise<any[]> {
    return await this.storage.list(this.PDFUrl);
  }

  private logListOfPDFs(listPDF: any[]): void {
    logger.debug('checkPdfExistenceInS3', listPDF);
  }

  private showNoPdfMessageIfEmpty(listPDF: any[]): void {
    if (listPDF?.length === 0) {
      this.showNoPdfMessage();
    }
  }

  async getPdfFromStorageByUrl(url: string): Promise<void> {
    try {
      const storagePdf = await this.storage.get(url);
      this.setPdfProperties(url, storagePdf);
      this.setPdfSrc(storagePdf);
      this.logStoragePdf(storagePdf);
      this.setSecurePdfUrl(storagePdf);
      this.setFileUploaded(true);
      this.showModalView();
    } catch (error) {
      logger.error('Error al obtener el PDF desde el almacenamiento:', error);
      this.ref.close();
    }
  }

  private setPdfProperties(url: string, storagePdf: any): void {
    this.pdfProperties.pdfName = this.getPdfName(url);
  }

  private setPdfSrc(storagePdf: any): void {
    this.pdfSrc = storagePdf;
  }

  private logStoragePdf(storagePdf: any): void {
    logger.debug('getPdfFromStorageByUrl storagePdf', storagePdf);
  }

  private setSecurePdfUrl(storagePdf: any): void {
    this.storagePdfUrl = this.transformToSecureUrl(storagePdf);
  }

  private setFileUploaded(value: boolean): void {
    this.isFileUploaded = value;
  }

  showModalView() {
    const analyticsSource = this.config?.data?.sourceComponent;
    const subjectId = this.config?.data?.subjectId;
    logger.debug('subjectId', subjectId);
    Analytics.record({
      name: 'site_pdf_read_' + analyticsSource,
      attributes: {
        userSub: this.authService.user?.attributes?.sub,
        userName: this.authService.user?.username,
        subjectId: subjectId,
      },
    });
    if (!this.disablePrint)
      this.modalView$.nativeElement.classList.add('visible');
  }

  getPdfName(url: string) {
    const data = url.split('/');
    return data[data.length - 1];
  }

  showNoPdfMessage() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: 'PDF',
      detail: this.trialpalService.translateService.instant('subject.notPDF'),
    });
    this.ref.close();
  }

  zoomIn() {
    this.pdfProperties.zoomLevel += 0.1;
  }

  zoomOut() {
    this.pdfProperties.zoomLevel -= 0.1;
  }

  getZoomValue() {
    const value = Math.trunc(this.pdfProperties.zoomLevel * 100);
    return `${value}%`;
  }

  print() {
    const url = this.pdfSrc;
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(
      (data: ArrayBuffer) => {
        const pdfBlob = this.createPdfBlob(data);
        const pdfUrl = this.createObjectUrl(pdfBlob);
        this.printPDF(pdfUrl);
      },
      (error: any) => console.error('Error al descargar el PDF', error)
    );
  }

  download() {
    const url = this.pdfSrc;
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(
      (data: ArrayBuffer) => {
        const pdfBlob = this.createPdfBlob(data);
        const pdfUrl = this.createObjectUrl(pdfBlob);
        this.downloadPDF(pdfUrl);
      },
      (error: any) => console.error('Error al descargar el PDF', error)
    );
  }

  private downloadPDF(url: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = this.pdfProperties.pdfName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private createPdfBlob(data: ArrayBuffer): Blob {
    return new Blob([data], { type: 'application/pdf' });
  }

  private createObjectUrl(blob: Blob): string {
    return window.URL.createObjectURL(blob);
  }

  private printPDF(url: string) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe?.contentWindow?.print();
  }
}
