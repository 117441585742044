import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API, graphqlOperation } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import {
  GetConfSymptomQuery,
  GetSymptomInstanceQuery,
} from 'src/app/modules/ediary/ediary.types';
import {
  CreateSiteMutation,
  DeleteSiteInput,
  DeleteSiteMutation,
  SitesByProjectIdQuery,
} from 'src/app/modules/sites/sites.types';
import { AuthService } from 'src/app/services/auth.service';
import { ConfState } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { EDIARY_QUERIES } from '../ediary/ediary.queries';
import { SITES_QUERIES } from '../sites/sites.queries';
import { PROJECT_QUERIES } from './project.queries';
import {
  CreateConfVisitGroupInput,
  CreateProjectInput,
  CreateProjectMutation,
  DeleteConfVisitGroupInput,
  DeleteProjectInput,
  DeleteProjectMutation,
  GetProjectQuery,
  ProjectState,
  UpdateConfVisitGroupInput,
  UpdateProjectInput,
  UpdateProjectMutation,
} from './project.types';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  currentProjectId = '';
  currentProject: GetProjectQuery = {
    __typename: 'Project',
    id: '',
    state: ProjectState.IN_DESIGN,
    code: '---',
    name: '',
    sponsor: '',
    groups: [],
    phases: [],
    isEdiaryActivated: false,
    isReportsActivated: false,
    isICActivated: false,
    isChatActivated: false,
    createdAt: '',
    updatedAt: '',
    _version: 0,
    _lastChangedAt: 0,
    _lastUser: '',
  };

  currentProjectSubject = new BehaviorSubject<GetProjectQuery | null>({
    __typename: 'Project',
    id: '',
    state: ProjectState.IN_DESIGN,
    code: '000',
    name: '',
    sponsor: '',
    groups: [],
    phases: [],
    isEdiaryActivated: false,
    isReportsActivated: false,
    isICActivated: false,
    isChatActivated: false,
    createdAt: '',
    updatedAt: '',
    _version: 0,
    _lastChangedAt: 0,
    _lastUser: '',
  });
  projects: any[] = [];
  constructor(
    private auth: AuthService,
    private translateService: TranslateService,
    private userPermissionsService: UserPermissionsService
  ) {}
  pushProject(project: any) {
    this.projects.push(project);
  }
  cleanListProjects() {
    this.projects = [];
  }
  async getProjects(): Promise<any> {
    if (this.projects && this.projects.length > 0) {
      return this.projects;
    }
    this.projects = await this.listUserProjects();
    return this.projects || [];
  }

  async listUserProjects(): Promise<any> {
    if (this.auth.isAdmin()) {
      return this.listUserProjectsValidate();
    } else {
      if (
        !(await this.userPermissionsService.hasPermission(
          ['Investigator', 'Reader', TP2Permission.ProjectList],
          true
        ))
      )
        return [];
      const authProjects = this.auth.getUserProjects();
      const items: GetProjectQuery[] = [];
      const ListProjectsQuery = {
        items,
      };
      for (const projectID of authProjects) {
        const p: any = await this.performGraphQLQuery(
          PROJECT_QUERIES.GetProject,
          { id: projectID }
        );
        ListProjectsQuery.items.push(p.data.getProject);
      }
      return Promise.resolve(ListProjectsQuery.items);
    }
  }

  async listUserProjectsValidate(): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      filter: undefined,
      limit: 1000,
    };
    do {
      gqlArguments.nextToken = nextToken;
      const listQuery = await this.performGraphQLQuery(
        PROJECT_QUERIES.ListProjects,
        gqlArguments
      );
      instances = instances.concat(listQuery.data.listProjects.items);
      nextToken = listQuery.data.listProjects.nextToken ?? undefined;
    } while (nextToken);
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances;
  }

  async getSites(): Promise<any> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    const gqlArguments: any = {
      filter: undefined,
      limit: 1000,
    };
    do {
      gqlArguments.nextToken = nextToken;
      const listQuery = await this.performGraphQLQuery(
        PROJECT_QUERIES.ListSites,
        gqlArguments
      );
      instances = instances.concat(listQuery.data.listSites.items);
      nextToken = listQuery.data.listSites.nextToken ?? undefined;
    } while (nextToken);
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances;
  }

  async listUserSites(projectIDS: string[]): Promise<any> {
    let listSitesQuery: any[] = [];
    for (const projectID of projectIDS) {
      const sites: any = await this.performGraphQLQuery(
        PROJECT_QUERIES.SitesByProjectId,
        { projectId: projectID }
      );
      listSitesQuery = listSitesQuery.concat(sites.data.SitesByProjectId.items);
    }
    if (this.auth.isAdmin()) {
      return Promise.resolve(listSitesQuery);
    } else {
      const authSites = this.auth.getUserSites();
      listSitesQuery = listSitesQuery.filter(
        (x) => authSites.indexOf(x.id) > -1
      );
      return Promise.resolve(listSitesQuery);
    }
  }
  async getProject(id: string): Promise<GetProjectQuery> {
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.GetProject,
      { id }
    );
    return response.data.getProject;
  }
  async getSymtom(id: string): Promise<GetSymptomInstanceQuery> {
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.GetSymptomInstance,
      { id }
    );
    return response.data.getSymptomInstance;
  }

  async getConfSymptom(id: string): Promise<GetConfSymptomQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.GetConfSymptom,
      { id }
    );
    return response.data.getConfSymptom;
  }
  async createProject(
    data: CreateProjectInput
  ): Promise<CreateProjectMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.CreateProject,
      {
        input: data,
      }
    );
    return response.data.createProject;
  }
  async deleteProject(
    id: string,
    expectedVersion: number
  ): Promise<DeleteProjectMutation> {
    const input: DeleteProjectInput = {
      id,
      _version: expectedVersion,
    };
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.DeleteProject,
      { input }
    );
    return response.data.deleteProject;
  }
  async updateProject(
    data: CreateProjectInput | UpdateProjectInput,
    id: string,
    expectedVersion: any
  ): Promise<UpdateProjectMutation> {
    let updateInput: UpdateProjectInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    updateInput.termsAndConditions = data.termsAndConditions ?? [];
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.UpdateProject,
      { input: updateInput }
    );
    return response.data.updateProject;
  }
  setCurrentProject(project: GetProjectQuery): void {
    this.currentProject = project;
    this.currentProjectSubject.next(this.currentProject);
  }
  getCurrentProject(): any {
    if (!this.currentProject?.id) {
      const storedProject = localStorage.getItem('currentProject');
      this.currentProject = storedProject ? JSON.parse(storedProject) : '';
    }
    return this.currentProject;
  }
  removeCurrentProject(): void {
    this.currentProjectSubject.next(null);
  }
  getProjectStates(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('project.enums.projectStates');
    Object.keys(ProjectState).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }
  getProjectStatesMaster(actualState: any): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('project.enums.projectStates');
    let ProjectSt: any;
    if (actualState === 'IN_DESIGN') {
      ProjectSt = {
        IN_PROGRESS: 'IN_PROGRESS',
      };
    } else if (actualState === 'IN_PROGRESS') {
      ProjectSt = {
        SUSPENDED: 'SUSPENDED',
        ENDED: 'ENDED',
      };
    } else if (actualState === 'SUSPENDED') {
      ProjectSt = {
        IN_PROGRESS: 'IN_PROGRESS',
        ENDED: 'ENDED',
      };
    } else if (actualState === 'ENDED') {
      ProjectSt = {
        ARCHIVED: 'ARCHIVED',
      };
    }
    Object.keys(ProjectSt).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }
  getProjectStateLabel(dato: string): any {
    const list = this.translateService.instant('project.enums.projectStates');
    return list[dato].toLowerCase();
  }
  async getProjectSites(projectId: string): Promise<SitesByProjectIdQuery> {
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.SitesByProjectId,
      { projectId }
    );
    return response.data.SitesByProjectId;
  }
  /**
   * Crea las entidades de los sitios
   * @param projectId id del proyecto asociado
   * @param newSites string con los nombres de los sitios separados por ,
   */
  createProjectSites(projectId: string, newSites: string[]): Promise<any> {
    const promises: Promise<CreateSiteMutation>[] = [];
    newSites.forEach((s) => {
      const input: any = {
        name: s,
        projectId,
        _lastUser: this.auth.getUsername(),
      };
      promises.push(
        this.performGraphQLQuery(SITES_QUERIES.CreateSite, { input })
      );
    });

    return Promise.all(promises);
  }
  /**
   * Elimina las entidades de los sitios
   * @param deleteSiteIds string con los nombres de los sitios separados por ,
   */
  deleteProjectSites(deleteSite: any[]): Promise<any> {
    const promises: Promise<DeleteSiteMutation>[] = [];
    deleteSite.forEach((s) => {
      const input: DeleteSiteInput = {
        id: s.id,
        _version: s._version,
      };
      promises.push(
        this.performGraphQLQuery(SITES_QUERIES.DeleteSite, { input })
      );
    });
    return Promise.all(promises);
  }
  updateCurrentProject(currentProject: any) {
    const index = this.projects.findIndex(
      (x: any) => x.id === currentProject.id
    );
    if (index) {
      this.projects[index] = currentProject;
    }
  }

  async getConfVisitGroup(id: string) {
    const filter = {
      state: {
        ne: ConfState.DELETED,
      },
    };
    const gqlArguments = {
      projectId: id,
      filter,
    };
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.ConfVisitGroupByProjectId,
      gqlArguments
    );
    let { items } = response.data.ConfVisitGroupByProjectId;
    items = items.filter((visitGroup: any) => !visitGroup._deleted);
    return items;
  }

  //Crea la asoción de un grupo con una visita
  async addGroupVisit(
    group: string,
    visit: string,
    isVisible: boolean,
    projectId: string,
    _lastUser: string
  ) {
    const input: CreateConfVisitGroupInput = {
      group,
      isVisible,
      projectId,
      visit,
      _lastUser,
    };
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.CreateConfVisitGroup,
      {
        input,
      }
    );
    return response.data.createConfVisitGroup;
  }

  //Edita la visibilidad de una visita en un grupo
  async editVisitGroup(visit: any) {
    const input: UpdateConfVisitGroupInput = {
      id: visit.id,
      group: visit.group,
      isVisible: visit.isVisible,
      projectId: visit.projectId,
      visit: visit.visit,
      _changeReason: visit._changeReason,
      _lastUser: visit._lastUser,
      _version: visit._version,
    };

    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.UpdateConfVisitGroup,
      {
        input,
      }
    );
    return response.data.updateConfVisitGroup;
  }

  async deleteVisitGroup(id: string, _version: number) {
    const input: DeleteConfVisitGroupInput = {
      id,
      _version,
    };
    const response = await this.performGraphQLQuery(
      PROJECT_QUERIES.DeleteConfVisitGroup,
      {
        input,
      }
    );
    return response.data.deleteConfVisitGroup;
  }

  private async performGraphQLQuery(query: any, args: any): Promise<any> {
    return (await API.graphql(graphqlOperation(query, args))) as any;
  }
}
