import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Driver, driver, DriveStep } from 'driver.js';
import { User } from '../modules/user/user.types';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class PageTourService {
  TRANSLATE_REGEX = /{{(.*?)}}/;
  currentUser!: User;
  currentTour!: Driver;
  constructor(
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  /**
   * Starts the tour if the user has not seen it before. It checks if the user has seen the tour,
   * destroys any existing tour, filters the steps, and then initializes and starts the new tour.
   *
   * @param tour - The tour data type representing the tour to be started.
   * @param delay - The delay before starting the tour (in milliseconds).
   * @returns A promise that resolves when the tour has started or exits early if conditions are not met.
   */
  initiateTour(tour: PageTourDataType, delay: number = 0): void {
    const hasUserSeenTour = this.hasUserSeenTour(tour);
    if (hasUserSeenTour) return;
    const filteredSteps = this.filterSteps(PAGE_TOUR_DATA[tour]);
    if (!filteredSteps.length) return;
    this.destroyTour();
    this.createAndStartTour(tour, filteredSteps, delay);
  }

  /**
   * Starts an on-demand tour for the specified page.
   *
   * @param {PageTourDataType} tour - The tour data type for the page.
   * @param {number} [delay=0] - The delay in milliseconds before starting the tour.
   *
   * @returns {void}
   */
  startOnDemandTour(tour: PageTourDataType, delay: number = 0): void {
    const filteredSteps = this.filterSteps(PAGE_TOUR_DATA[tour]);
    if (!filteredSteps.length) return;
    this.destroyTour();
    this.createAndStartTour(tour, filteredSteps, delay);
  }

  /**
   * Creates and starts a new tour with the provided steps and delay.
   * Configures the tour with the provided parameters and starts it after the specified delay.
   *
   * @param tour - The tour data type representing the tour to be started.
   * @param steps - An array of steps to be included in the tour.
   * @param delay - The delay before starting the tour (in milliseconds).
   */
  createAndStartTour(
    tour: PageTourDataType,
    steps: DriveStep[],
    delay: number
  ) {
    this.currentTour = driver({
      showProgress: true,
      nextBtnText: this.translateService.instant('general.btnNext'),
      prevBtnText: this.translateService.instant('general.btnPrevious'),
      doneBtnText: this.translateService.instant('general.btnDone'),
      disableActiveInteraction: true,
      allowClose: true,
      allowKeyboardControl: true,
      showButtons: ['next', 'previous', 'close'],
      steps: this.translateDriveSteps(steps),
      onDestroyed: () => this.saveTourInLocalStorage(tour),
    });

    setTimeout(() => {
      this.currentTour.drive();
    }, delay);
  }

  destroyTour() {
    if (this.currentTour) {
      this.currentTour.destroy();
    }
  }

  /**
   * Filters out steps from the provided list where the `element` property does not match any existing DOM elements.
   * If the `element` property is not defined or if it matches a query selector in the DOM, the step is included in the result.
   *
   * @param steps - The array of `DriveStep` objects to be filtered.
   * @returns An array of `DriveStep` objects where each `element` property matches an existing DOM element.
   */
  filterSteps(steps: DriveStep[]): DriveStep[] {
    return steps.filter((step: DriveStep) => {
      if (!step?.element) return true;
      const query = step.element as string;
      return document.querySelector(query);
    });
  }

  /**
   * Translates the titles and descriptions of the popovers in the given drive steps.
   *
   * Esta función toma una lista de pasos de manejo (`DriveStep`) y traduce el título y la descripción
   * de los popovers asociados usando el servicio de traducción (`translateService`).
   *
   * @param steps - Lista de pasos de manejo a traducir.
   * @returns Una nueva lista de pasos de manejo con los títulos y descripciones traducidos.
   */
  translateDriveSteps(steps: DriveStep[]): DriveStep[] {
    return steps.map((step: DriveStep) => {
      const title = step.popover?.title ?? '';
      const description = step.popover?.description ?? '';
      return {
        ...step,
        popover: {
          ...step.popover,
          title: this.translateService.instant(title),
          description: this.TRANSLATE_REGEX.test(description)
            ? this.translateStringWithKey(description)
            : this.translateService.instant(description),
        },
      };
    });
  }

  /**
   * Translates a string containing a translation key enclosed in double curly braces (e.g., {{key}}).
   * If a translation key is found, it replaces the entire pattern with the translated value.
   *
   * @param value - The string potentially containing a translation key.
   * @returns The string with the translation key replaced by its corresponding translated value.
   */
  translateStringWithKey(value: string): string {
    const match = value.match(this.TRANSLATE_REGEX);
    const key = match ? match[1].trim() : '';
    return value.replace(
      this.TRANSLATE_REGEX,
      this.translateService.instant(key)
    );
  }
  /**
   * Checks if the user has already seen the specified tour.
   *
   * This function retrieves the tour history from local storage and searches for an entry
   * that matches the given tour name and the current user's username.
   *
   * @param {PageTourDataType} tour - The name or identifier of the tour to check.
   * @returns {boolean} - Returns true if the user has seen the tour, otherwise false.
   */
  hasUserSeenTour(tour: PageTourDataType): boolean {
    const tourHistory = this.getTourHistoryFromLocalStorage();
    const findTourIndex = tourHistory.findIndex(
      (currentTour: TourHistory) =>
        currentTour.name === tour &&
        currentTour.username === this.authService.getUsername()
    );
    return findTourIndex !== -1; // Indica que el usuario ha visto el tour
  }

  /**
   * Saves the specified tour to local storage for the current user.
   *
   * This function retrieves the current tour history from local storage, adds a new entry
   * for the specified tour, and then saves the updated tour history back to local storage.
   *
   * @param {PageTourDataType} tour - The name or identifier of the tour to save.
   */
  saveTourInLocalStorage(tour: PageTourDataType): void {
    if (this.hasUserSeenTour(tour)) return;
    const tourHistory = this.getTourHistoryFromLocalStorage();
    tourHistory.push({
      name: tour,
      username: this.authService.getUsername(),
      step: 0,
    });
    localStorage.setItem('tourHistory', JSON.stringify(tourHistory));
  }

  /**
   * Retrieves the tour history from local storage.
   *
   * This function fetches the tour history stored in local storage and parses it into
   * an array of TourHistory objects. If no tour history is found, it returns an empty array.
   *
   * @returns {TourHistory[]} - An array of TourHistory objects representing the user's tour history.
   */
  getTourHistoryFromLocalStorage(): TourHistory[] {
    const localTourHistory = localStorage.getItem('tourHistory');
    return localTourHistory ? JSON.parse(localTourHistory) : [];
  }
}

export const PAGE_TOUR_DATA: PageTourData = {
  CREATE_SUBJECT_TOUR: [
    {
      popover: {
        title: 'homeAdminComponent.welcome',
        description:
          '<img src="assets/icons/tour/tour-guide.png"/> <span>{{subject.crateSubjectTour.introduction}}</span>',
      },
    },
    {
      element: '#basic-information',
      popover: {
        title: 'subject.basicDataSuject',
        description: 'subject.crateSubjectTour.basicInformation',
      },
    },
    {
      element: '#site_container',
      popover: {
        title: 'project.labelSites',
        description: 'subject.crateSubjectTour.sites',
      },
    },
    {
      element: '#subjectNumber_container',
      popover: {
        title: 'subject.labelSubjectNumber',
        description: 'subject.crateSubjectTour.numberSubject',
      },
    },
    {
      element: '#group_container',
      popover: {
        title: 'subject.labelSubjectGroup',
        description: 'subject.crateSubjectTour.group',
      },
    },
    {
      element: '#tag_container',
      popover: {
        title: 'subject.tag',
        description: 'subject.crateSubjectTour.tag',
      },
    },
    {
      element: '#currentPhase_container',
      popover: {
        title: 'subject.firstVisitNotice',
        description: 'subject.crateSubjectTour.currentPhase',
      },
    },
    {
      element: '#phase_container',
      popover: {
        title: 'subject.labelVisit',
        description: 'subject.crateSubjectTour.phase',
      },
    },
    {
      element: '#status_container',
      popover: {
        title: 'subject.labelState',
        description: 'subject.crateSubjectTour.status',
      },
    },
    {
      element: '#date_container',
      popover: {
        title: 'subject.labelDateFirstVisit',
        description: 'subject.crateSubjectTour.date',
      },
    },
    {
      element: '#users_container',
      popover: {
        title: 'subject.associateUserToSubject',
        description: 'subject.crateSubjectTour.users',
      },
    },
  ],
  DETAIL_SUBJECT_TOUR: [
    {
      element: '#basicInformation_container',
      popover: {
        title: 'subject.tabheaderBasicSubjecDetailtTitle',
        description: 'subject.detailSubjectTour.basicInformation',
      },
    },
    {
      element: '#phase_container',
      popover: {
        title: 'subject.pCurrentVisit',
        description: 'subject.detailSubjectTour.phase',
      },
    },
    {
      element: '#status_container',
      popover: {
        title: 'subject.pState',
        description: 'subject.detailSubjectTour.status',
      },
    },
    {
      element: '#btnEdit_container',
      popover: {
        title: 'subject.tabheaderbtnEditSubjectTitle',
        description: 'subject.detailSubjectTour.btnEdit',
      },
    },
    {
      element: '#audit_container',
      popover: {
        title: 'subject.tabheaderbtnSubjectAuditTitle',
        description: 'subject.detailSubjectTour.btnAudit',
      },
    },
    {
      element: '.p-tabview-nav-container',
      popover: {
        title: 'subject.tabheaderVisitTabTitle',
        description: 'subject.detailSubjectTour.sections',
      },
    },
    {
      element: '#phases_container',
      popover: {
        title: 'subject.tabheaderVisitsTitle',
        description: 'subject.detailSubjectTour.PhaseInformation',
      },
    },
    {
      element: '#phases_container #phaseCard_container0',
      popover: {
        title: 'subject.tabheaderVisitCardTitle',
        description: 'subject.detailSubjectTour.phaseCard',
      },
    },
  ],
  DETAIL_SUBJECT_ALERT_TOUR: [
    {
      element: '#alerts_container',
      popover: {
        title: 'subject.tabheaderAlertCardTitle',
        description: 'subject.detailSubjectAlertTour.detail',
      },
    },
  ],
  DETAIL_SUBJECT_SYMPTOM_TOUR: [
    {
      element: '#symptoms_container',
      popover: {
        title: 'subject.tabheaderSymptom',
        description: 'subject.detailSubjectSymptomTour.detail',
      },
    },
    {
      element: '#symptom_container0',
      popover: {
        title: 'subject.tabheaderSymptomCardTitle',
        description: 'subject.detailSubjectSymptomTour.detailCard',
      },
    },
  ],
  DETAIL_SUBJECT_EDIARY_TOUR: [
    {
      element: '#eDiaryPhases_container',
      popover: {
        title: 'subject.tabheaderEdiaryDetailTitle',
        description: 'subject.detailSubjectEdiaryTour.detail',
      },
    },
    {
      element: '#eDiaryPhases_container0',
      popover: {
        title: 'subject.tabheaderEdiaryCardTitle',
        description: 'subject.detailSubjectEdiaryTour.card',
      },
    },
    {
      element: '#eDiaryPhases_container0 #content_container',
      popover: {
        title: 'subject.tabheaderEdiarySectionCardTitle',
        description: 'subject.detailSubjectEdiaryTour.sectionCard',
      },
    },
    {
      element: '#eDiaryPhases_container0 #menu_options',
      popover: {
        title: 'subject.tabheaderEdiaryOptionsCardTitle',
        description: 'subject.detailSubjectEdiaryTour.optionsCard',
      },
    },
    {
      element: '#consolidate_card',
      popover: {
        title: 'subject.tabheaderEdiaryConsolidateCardTitle',
        description: 'subject.detailSubjectEdiaryTour.consolidateCard',
      },
    },
    {
      element: '#consolidate_card #content_container',
      popover: {
        title: 'subject.tabheaderEdiaryConsolidateSectionCardTitle',
        description: 'subject.detailSubjectEdiaryTour.consolidateSection',
      },
    },
  ],
  DETAIL_SUBJECT_USERS_TOUR: [
    {
      element: '#users_container',
      popover: {
        title: 'subject.tabheaderUsersTitle',
        description: 'subject.detailSubjectUserTour.information',
      },
    },
  ],
  DETAIL_SUBJECT_REPORTS_TOUR: [
    {
      element: '#reportSubject_container',
      popover: {
        title: 'subject.tabheaderReportDetailTitle',
        description: 'subject.detailSubjectReportTour.detail',
      },
    },
    {
      element: '#reportSubjectFilter_container',
      popover: {
        title: 'subject.tabheaderReportFilterTitle',
        description: 'subject.detailSubjectReportTour.filters',
      },
    },
    {
      element: '#reportSubjectSection_container0',
      popover: {
        title: 'subject.tabheaderReportSectionTitle',
        description: 'subject.detailSubjectReportTour.reportSection',
      },
    },
  ],
  DETAIL_SUBJECT_REPORTS_DETAIL_TOUR: [] /**Se construye dinamicamente */,
  DETAIL_SUBJECT_TEMPERATURE_TOUR: [
    {
      element: '#subjectTemperature_container',
      popover: {
        title: 'subject.tabheaderTemperature',
        description: 'subject.detailSubjectTemperatureTour.detail',
      },
    },
    {
      element: '#temperatureCard0',
      popover: {
        title: 'subject.detailTemperature',
        description: 'subject.detailSubjectTemperatureTour.temperatureCard',
      },
    },
  ],
  DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR: [
    {
      element: '#subjectMedicalAttention_container',
      popover: {
        title: 'subject.tabheaderMedicalAssistancesTitle',
        description: 'subject.detailSubjectMedicalAttentionTour.detail',
      },
    },
    {
      element: '#medicalAttentionCard0',
      popover: {
        title: 'subject.tabheaderMedicalAssistanceDetailTitle',
        description:
          'subject.detailSubjectMedicalAttentionTour.medicalAttentionCard',
      },
    },
  ],
  DETAIL_SUBJECT_MEDICATION_TOUR: [
    {
      element: '#subjectMedication_container',
      popover: {
        title: 'subject.tabheaderMedicationsTitle',
        description: 'subject.detailSubjectMedicationTour.detail',
      },
    },
    {
      element: '#medicationCard0',
      popover: {
        title: 'subject.tabheaderMedicationCardTitle',
        description: 'subject.detailSubjectMedicationTour.medicationCard',
      },
    },
  ],
};

export enum PageTourDataType {
  CREATE_SUBJECT_TOUR = 'CREATE_SUBJECT_TOUR',
  DETAIL_SUBJECT_TOUR = 'DETAIL_SUBJECT_TOUR',
  DETAIL_SUBJECT_ALERT_TOUR = 'DETAIL_SUBJECT_ALERT_TOUR',
  DETAIL_SUBJECT_SYMPTOM_TOUR = 'DETAIL_SUBJECT_SYMPTOM_TOUR',
  DETAIL_SUBJECT_EDIARY_TOUR = 'DETAIL_SUBJECT_EDIARY_TOUR',
  DETAIL_SUBJECT_USERS_TOUR = 'DETAIL_SUBJECT_USERS_TOUR',
  DETAIL_SUBJECT_REPORTS_TOUR = 'DETAIL_SUBJECT_REPORTS_TOUR',
  DETAIL_SUBJECT_REPORTS_DETAIL_TOUR = 'DETAIL_SUBJECT_REPORTS_DETAIL_TOUR',
  DETAIL_SUBJECT_TEMPERATURE_TOUR = 'DETAIL_SUBJECT_TEMPERATURE_TOUR',
  DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR = 'DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR',
  DETAIL_SUBJECT_MEDICATION_TOUR = 'DETAIL_SUBJECT_MEDICATION_TOUR',
}

interface PageTourData {
  CREATE_SUBJECT_TOUR: DriveStep[];
  DETAIL_SUBJECT_TOUR: DriveStep[];
  DETAIL_SUBJECT_ALERT_TOUR: DriveStep[];
  DETAIL_SUBJECT_SYMPTOM_TOUR: DriveStep[];
  DETAIL_SUBJECT_EDIARY_TOUR: DriveStep[];
  DETAIL_SUBJECT_USERS_TOUR: DriveStep[];
  DETAIL_SUBJECT_REPORTS_TOUR: DriveStep[];
  DETAIL_SUBJECT_REPORTS_DETAIL_TOUR: DriveStep[];
  DETAIL_SUBJECT_TEMPERATURE_TOUR: DriveStep[];
  DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR: DriveStep[];
  DETAIL_SUBJECT_MEDICATION_TOUR: DriveStep[];
}

export interface TourHistory {
  name: PageTourDataType;
  username: string;
  step: number;
}
