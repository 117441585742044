<div class="container-tp2 containerAll" id="container">
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12" style="display: flex; align-items: center">
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        id="btn_forward"
        class="
          p-button-rounded
          p-button-secondary
          p-button-outlined
          p-button
          p-button-icon-only
        "
        (click)="forward()"
      ></button>
      <div class="p-col-10">
        <h1>{{ 'roles.h1ListRoles' | translate }}</h1>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-xl-4 container_button_add">
      <button
        id="button"
        pButton
        label="{{ 'general.add' | translate }}"
        type="button"
        (click)="createEdit()"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>

  <div *ngIf="roles && roles.length > 0">
    <section class="p-mb-5">
      <app-tptable
        [dataTable]="dataTable"
        (openDetail)="showRoleDetail($event)"
        (item)="getItemByTable($event)"
      ></app-tptable>
    </section>
  </div>
  <div class="p-grid p-fluid" *ngIf="roles.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
