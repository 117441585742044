import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { AddEditRolesComponent } from './add-edit-roles/add-edit-roles.component';
import { DetailRolesComponent } from './detail-roles/detail-roles.component';
import { ListRolesComponent } from './list-roles/list-roles.component';
import { RolesRoutingModule } from './roles-routing.module';
@NgModule({
  declarations: [
    ListRolesComponent,
    AddEditRolesComponent,
    DetailRolesComponent,
  ],
  imports: [
    CommonModule,
    RolesRoutingModule,
    SharedComponentsModule,
    PrimengModule,
  ],
})
export class RolesModule {}
