import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-privacy-and-terms',
  templateUrl: './privacy-and-terms.component.html',
  styleUrls: ['./privacy-and-terms.component.scss'],
})
export class PrivacyAndTermsComponent {
  isTerms: boolean = false;

  constructor(public config: DynamicDialogConfig) {
    this.isTerms = this.config.data.isTerms;
  }
}
