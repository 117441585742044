<app-button-refresh-data
  [showSpinner]="showSpinner"
  (buttonAction)="ngOnInit()"
></app-button-refresh-data>
<section *ngIf="!showSpinner">
  <div class="p-grid p-fluid" *ngIf="dataCard.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
  <div class="p-grid p-fluid" *ngIf="dataCard.length > 0">
    <app-tpcard
      id="media"
      class="p-col-12 p-md-4 p-lg-3"
      *ngFor="let currentMedia of dataCard"
      [tpCardData]="currentMedia"
      (detail)="openModal(currentMedia)"
    ></app-tpcard>
  </div>

  <p-galleria
    id="galleria"
    [value]="dataCard"
    [(visible)]="isVisible"
    [containerStyle]="{ 'max-width': '850px' }"
    [numVisible]="7"
    [circular]="true"
    [fullScreen]="true"
    [showThumbnails]="false"
    [baseZIndex]="100000"
  >
    <ng-template pTemplate="item" let-item id="template">
      <img [src]="url" style="width: 100%; display: block" />
    </ng-template>
  </p-galleria>
</section>
<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
