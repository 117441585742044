<div class="p-grid p-fluid">
  <div class="p-col-12 p-md-2">
    <img
      id="AIWizard"
      src="../../../../../../../assets/imgs/AIWizard.png"
      alt="AI Wizard"
      class="img-fluid"
    />
  </div>
  <div class="p-col-12 p-md-10" *ngIf="mediaType === 'AUDIO'">
    <strong
      ><p>
        {{ 'informedConsent.generateMediaInstructions' | translate }}
      </p></strong
    >
    <span
      [innerHTML]="'informedConsent.generateMediaInstructions1' | translate"
    ></span>

    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-4">
        <label for="voiceLanguage" class="ai-labels">
          {{ 'informedConsent.voiceLanguage' | translate }}
        </label>
        <p-dropdown
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="languageOptions"
          optionLabel="label"
          optionValue="value"
          id="mediaLanguage"
          name="mediaLanguage"
          #mediaLanguage="ngModel"
          [(ngModel)]="mediaInputLanguage"
          (onChange)="onLanguageSelected()"
        ></p-dropdown>
      </div>
      <div class="p-col-12 p-md-4">
        <label for="voiceName" class="ai-labels">
          {{ 'informedConsent.voice' | translate }}
        </label>
        <p-dropdown
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="voiceOptions"
          optionLabel="label"
          optionValue="value"
          id="mediaVoice"
          name="mediaVoice"
          #mediaVoice="ngModel"
          [(ngModel)]="mediaInputVoice"
          (onChange)="onVoiceSelected()"
        ></p-dropdown>
      </div>
      <div class="p-col-4 p-lg-2">
        <label for="testAudio" class="ai-labels">
          {{ 'informedConsent.testAudio' | translate }}</label
        >
        <p-button
          icon="{{
            isGeneratingTestAudio
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-caret-right'
          }}"
          type="button"
          (onClick)="playTestAudio()"
          label="{{ 'informedConsent.playMedia' | translate }}"
          styleClass="p-button-outlined p-play-button"
        ></p-button>
      </div>
    </div>
    <br />
    <span
      [innerHTML]="'informedConsent.generateMediaInstructions2' | translate"
    ></span>
    <div class="p-col-12">
      <label for="inputText" class="ai-labels">
        {{ 'informedConsent.sections.sectionMediaText' | translate }}
      </label>
      <textarea
        pInputTextarea
        name="mediaInputText"
        id="mediaInputText"
        [(ngModel)]="mediaInputText"
        style="width: 100%; height: 100%"
      ></textarea>
    </div>
    <br *ngIf="temporaryUploadedFile" />
    <span
      *ngIf="temporaryUploadedFile"
      [innerHTML]="'informedConsent.generateMediaInstructions3' | translate"
    ></span>
    <div class="p-col-12 p-grid p-fluid">
      <p-button
        style="padding-right: 0.5rem"
        icon="{{ isGeneratingMedia ? 'pi pi-spin pi-spinner' : '' }}"
        label="{{
          !isGeneratingMedia
            ? ('informedConsent.generateMediaAudio' | translate)
            : ''
        }}"
        (onClick)="generateMedia()"
        [disabled]="
          isGeneratingMedia ||
          !mediaInputText ||
          !mediaInputLanguage ||
          !mediaInputVoice
        "
      >
      </p-button>
      <p-button
        (onClick)="playOrPauseMedia()"
        type="button"
        label="{{
          isPlaying
            ? ('informedConsent.stopMedia' | translate)
            : ('informedConsent.playMedia' | translate)
        }}"
        styleClass="p-button-outlined p-play-button"
        [disabled]="!temporaryUploadedFile"
        icon="{{ isPlaying ? 'pi pi-pause' : 'pi pi-caret-right' }}"
      ></p-button>
    </div>
    <span
      *ngIf="temporaryUploadedFile"
      class="p-col-12 p-md-4 span-media-audio"
      style="padding-left: 0"
    >
      {{ 'informedConsent.sections.sectionMediaAudioGenerated' | translate }}
    </span>
  </div>
  <div class="p-col-12 p-md-10 p-grid" *ngIf="mediaType === 'PHOTO'">
    <strong
      ><p>
        {{ 'informedConsent.generateMediaPhotoInstructions' | translate }}
      </p>
    </strong>
    <div class="p-col-12 p-md-9">
      <span
        [innerHTML]="
          'informedConsent.generateMediaPhotoInstructions1' | translate
        "
      ></span>
      <div class="p-col-12">
        <label for="photoText" class="ai-labels">
          {{ 'informedConsent.sections.sectionMediaText' | translate }}
        </label>
        <textarea
          pInputTextarea
          name="photoText"
          id="photoText"
          [(ngModel)]="mediaInputText"
          style="width: 100%; height: 100%"
          placeholder="{{
            'informedConsent.sections.sectionMediaTextPlaceholder' | translate
          }}"
        ></textarea>
      </div>

      <span
        [innerHTML]="
          isGeneratingMedia || generatedImage
            ? ('informedConsent.generateMediaPhotoInstructions3' | translate)
            : ('informedConsent.generateMediaPhotoInstructions2' | translate)
        "
      ></span>
      <div class="p-col-4">
        <p-button
          icon="{{ isGeneratingMedia ? 'pi pi-spin pi-spinner' : '' }}"
          label="{{
            !isGeneratingMedia
              ? ('informedConsent.generateMediaPhoto' | translate)
              : ('informedConsent.generatingMediaPhoto' | translate)
          }}"
          [disabled]="!mediaInputText || isGeneratingMedia"
          (onClick)="generateMediaPhoto()"
        ></p-button>
      </div>
      <br />
      <span
        *ngIf="generatedImage"
        [innerHTML]="
          'informedConsent.generateMediaPhotoInstructions4' | translate
        "
      ></span>
    </div>
    <div class="p-col-12 p-md-3">
      <img
        [src]="
          generatedImage
            ? generatedImage
            : isGeneratingMedia
            ? '../../../../../../../assets/imgs/default-loading-ia.gif'
            : '../../../../../../../assets/imgs/default-image-ia.png'
        "
        alt="generatedFromAI"
        style="
          max-width: 80%;
          margin-left: 1rem;
          border-radius: 10%;
          border: 2px dashed #028eadac;
        "
      />
    </div>
  </div>
  <div
    class="p-col-12 p-md-4 p-grid"
    style="margin-left: auto; margin-right: 0; padding-bottom: 0"
  >
    <div class="p-col-12 p-md-6">
      <p-button
        label="{{ 'general.cancel' | translate }}"
        (onClick)="onCancel()"
        styleClass="p-button-outlined"
      ></p-button>
    </div>
    <div class="p-col-12 p-md-6">
      <p-button
        label="{{ 'general.assign' | translate }}"
        (onClick)="onSave()"
        [disabled]="!temporaryUploadedFile"
      ></p-button>
    </div>
  </div>
</div>
