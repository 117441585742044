import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Site } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { SubjectsService } from '../../subjects/subjects.service';
import { UserService } from '../../user/user.service';
import { AddEditSitesComponent } from '../add-edit-sites/add-edit-sites.component';
import { SitesService } from '../sites.service';

const logger = new Logger('tp2-logger-siteListPage');
@Component({
  selector: 'app-list-sites',
  templateUrl: './list-sites.component.html',
  styleUrls: ['./list-sites.component.scss'],
})
export class ListSitesComponent implements OnInit, AfterViewInit {
  sites: any[] = [];
  sitesPaginated: any[] = [];
  pagesize = 6;
  projectId = '';
  hasSiteCreatePermission = false;
  hasSiteDeletePermission = false;
  hasSiteListPermission = false;
  hasSiteEditPermission = false;
  constructor(
    private sitesService: SitesService,
    private subjectsService: SubjectsService,
    private trialpalService: TrialpalService,
    private dialogService: DialogService,
    private userService: UserService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getUserPermissions();
    this.projectId = this.route.snapshot.params.projectId;
    if (this.projectId && this.hasSiteListPermission) {
      this.getSitesByProject();
    }
  }
  async getUserPermissions() {
    this.hasSiteListPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.SiteList, 'Admin'],
        true
      );
    this.hasSiteCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SiteCreate,
        'Admin',
      ]);
    this.hasSiteDeletePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SiteDelete,
        'Admin',
      ]);
    this.hasSiteEditPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SiteCreate,
        'Admin',
      ]);
  }

  getSitesByProject() {
    this.trialpalService.showSpinner('site.entity', 'LIST');
    this.sitesService
      .getSitesByProject(this.projectId)
      .then((sites: any) => {
        if (sites) {
          this.sites = sites;
          this.sitesPaginated = this.sites.slice(0, this.pagesize);
          this.trialpalService.hideSpinner();
        }
      })
      .catch((error) => {
        logger.error('listSite error', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  public ngAfterViewInit() {
    this.detectScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectScreenSize();
  }
  private detectScreenSize() {
    const height = window.innerHeight;
    if (height > 1235) {
      this.pagesize = 12;
      this.sitesPaginated = this.sites.slice(0, this.pagesize);
    } else if (height > 1010) {
      this.pagesize = 9;
      this.sitesPaginated = this.sites.slice(0, this.pagesize);
    } else {
      this.pagesize = 6;
      this.sitesPaginated = this.sites.slice(0, this.pagesize);
    }
  }
  buildTPCardData(res: any): TPCardData {
    const language = res.defaultLanguage
      ? this.trialpalService.translateService.instant(
          'site.languages.' + res.defaultLanguage
        )
      : '';
    return {
      data: res,
      header: res.name,
      section2: res.timezone,
      icon2: 'pi pi-map-marker',
      section3: language,
      icon3: 'pi pi-globe',
      section4: res.siteCode,
      icon4: 'pi pi-tag',
      entity: 'SITE',
      styleColorState: '',
      showEditButton: this.hasSiteEditPermission,
      showAuditButton: this.hasSiteListPermission,
      showDeleteButton: this.hasSiteDeletePermission,
      objectAction: res.name,
      entityAction:
        this.trialpalService.translateService.instant('site.entity'),
    };
  }
  createEdit(p?: any): void {
    const isEdit = !!p;
    const ref = this.dialogService.open(AddEditSitesComponent, {
      header: p
        ? this.trialpalService.translateService.instant('site.h1EditSite')
        : this.trialpalService.translateService.instant(
            'site.buttonCreateSite'
          ),
      width: '80%',
      contentStyle: { minHeight: '20rem', overflowY: 'auto' },
      data: p
        ? { isEdit, id: p.id, isModal: true }
        : { isEdit, isModal: true, projectId: this.projectId },
    });
    ref.onClose.subscribe((response: any) => {
      if (response && !isEdit) {
        this.sites.push(response);
        this.sitesPaginated.push(response);
      } else if (response && isEdit) {
        this.sites = this.sites.map((site: Site) =>
          site.id === response.id ? response : site
        );
        this.sitesPaginated = this.sitesPaginated.map((site: Site) =>
          site.id === response.id ? response : site
        );
      }
    });
  }
  async delete(s: any): Promise<void> {
    this.trialpalService.showSpinner('site.entity', 'DELETE');
    //valida que el sitio no tenga usuarios asociados
    const filter = {
      sites: {
        contains: s.id,
      },
    };
    const siteUsers = await this.userService.listUsers(filter);

    //valida que el sitio no tenga sujetos asociados
    let siteSubjects = (
      await this.subjectsService.getSubjectsBySiteId(s.id)
    ).filter((Subject: any) => Subject.id && !Subject._deleted);

    logger.debug('siteUsers', siteUsers);
    logger.debug('siteSubjects', siteSubjects);

    if (siteUsers.length > 0 || siteSubjects.length > 0) {
      this.trialpalService.hideSpinner();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'site.errorDeleteSite'
        ),
        detail: this.trialpalService.translateService.instant(
          'site.errorUserSubjectsExistence'
        ),
      });
    } else {
      this.sitesService
        .deleteSite(s.id, s._version)
        .then((response) => {
          logger.debug('delete site response', response);
          const indexOriginal = this.sites.findIndex((x: any) => x.id === s.id);
          this.sites.splice(indexOriginal, 1);
          this.detectScreenSize();
          this.trialpalService.hideSpinner();
          this.trialpalService.showMutationSuccess('site.entity', 'DELETE');
        })
        .catch((error: any) => {
          this.trialpalService.hideSpinner();
          this.trialpalService.showServiceError('site.entity', error);
        })
        .finally(() => this.trialpalService.hideSpinner());
    }
  }
  paginate(event: any) {
    this.sitesPaginated = this.sites.slice(
      event.page * this.pagesize,
      event.page * this.pagesize + this.pagesize
    );
  }
  forward(): void {
    this.router.navigate(['project/' + this.projectId + '/detail']);
  }
}
