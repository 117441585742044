import { DesignerField } from '../designer/field-types';

export interface FormlyField {}

export interface Field {
  key: string;
  templateOptions?: FieldProps;
  scoreLabel?: string;
  pdfViewType?: string;
  pdfViewTypePriority?: boolean;
  wrappers?: FormlyDisplay[];
  fieldArray?: Field;
  fieldGroup?: Field[];
  type?: string | DesignerField;
  validation?: FieldValidation;
  hideExpression?: string;
  customExpression?: { key: string; operator: string; value: string };
}

export interface FieldValidation {
  messages: { required: string };
}

export interface FieldProps {
  label: string;
  type?: string | DesignerField;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  tooltip?: string;
  options?: any[];
  images?: any[];
  questions?: any[];
  isMain?: boolean;
  score?: boolean;
  removeText?: string;
  addButtonText?: string;
  calculateType?: string;
}

export interface FormConfiguration {
  showDataTab: boolean;
  showDisplayTab: boolean;
  showConditionalTab: boolean;
  showAvancedTab: boolean;
  showValidationTab: boolean;
  isRepeatField: boolean | undefined;
  isEvaluationSection: boolean;
  isGroupField: boolean;
  inputsVisibility: FormConfigurationInputVisibility;
  operatorTypes: FormOption[];
  booleanTypes: FormOption[];
  calculateTypes: FormOption[];
  fieldsInGroupTypes: FormOption[];
  pdfViewTypes: FormOption[];
  textTypes: FormOption[];
  imageTypes: FormOption[];
  conditional: Conditional;
}

interface FormOption {
  label: string;
  value: string | boolean | number;
  options?: any[];
}

interface Conditional {
  field: {
    key: string;
    type: string;
    options: any[];
    min: number;
    max: number;
  };
  operator: OPERATORS | null;
  value: any;
}

interface FormConfigurationInputVisibility {
  defaultValue: boolean;
  textTypes: boolean;
  required: boolean;
  tooltip: boolean;
  placeholder: boolean;
  score: boolean;
  repeat: boolean;
  min: boolean;
  max: boolean;
  minLength: boolean;
  maxLength: boolean;
  pdfViewType: boolean;
}

export enum PDF_VIEW_OPTIONS {
  ANSWER_SELECTED = 'ANSWER_SELECTED',
  OPTIONS_WITH_ASNWERS = 'OPTIONS_WITH_ASNWERS',
}

//Interfaces y enums para la creación/edición de campos
export enum OPERATORS {
  EQUAL = '===',
  NOT_EQUAL = '!==',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  GREATER_THAN_OR_EQUAL = '>=',
  LESS_THAN_OR_EQUAL = '<=',
}

export enum CALCULATE_TYPES {
  TAKE_MIN_VALUE = 'take_min_value',
  TAKE_MAX_VALUE = 'take_max_value',
  SUM_ALL_VALUE = 'sum_all_value',
  UNIQUE_VALUE = 'unique_value',
  NO_CALCULATE_VALUE = 'no_calculate_value',
}

export enum FormlyDisplay {
  ACCORDION = 'accordion',
  CARD = 'card',
  STEPPER = 'stepper',
  TABS = 'tabs',
}
