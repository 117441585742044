import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import {
  ButtonItem,
  Tp2Card,
  Tp2CardContent,
} from 'src/app/shared/global.variables';
import { InformedConsentService } from '../../informed-consent.service';
import {
  ConfICSection,
  UpdateConfInformedConsentInput,
} from '../../informed-consent.types';
import { ConfState } from 'src/app/services/trialpal.types';
const logger = new Logger('tp2-logger-listInformedConsentSectionsPage');

@Component({
  selector: 'app-informed-consent-section-list',
  templateUrl: './informed-consent-section-list.component.html',
  styleUrls: ['./informed-consent-section-list.component.scss'],
})
export class InformedConsentSectionListComponent implements OnChanges {
  @Input() confICSections: ConfICSection[] = [];
  @Output() detailEmitter: EventEmitter<any> = new EventEmitter();
  @Output() editEmitter: EventEmitter<any> = new EventEmitter();
  INFORMED_CONSENT_SECTION_ENTITY = 'informedConsent.sections.section';
  sectionCards: any[] = [];
  confICSectionSelected: any = [];
  showDisplayDeleteComponent: boolean = false;
  constructor(
    private readonly informedConsentService: InformedConsentService,
    private readonly trialpalService: TrialpalService,
    private readonly authService: AuthService
  ) {}

  ngOnChanges() {
    this.buildTPCardData();
  }

  buildTPCardData() {
    const cards: Tp2Card[] = [];

    this.confICSections.sort((a, b) => a.order - b.order);

    for (let confICSection of this.confICSections) {
      const buttonsItems: ButtonItem[] = [
        {
          color: '#205163',
          command: () => this.editEmitter.emit(confICSection),
          icon: 'pi pi-pencil',
          tooltip: '',
          disabled: false,
        },
        {
          color: '#E41E1E',
          command: () => {
            this.showDisplayDeleteComponent = true;
            this.confICSectionSelected = confICSection;
          },
          icon: 'pi pi-trash',
          tooltip: '',
          disabled: false,
        },
      ];

      const content: Tp2CardContent[] = [
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.sections.sectionOrder'
          )}:`,
          description: confICSection.order,
          icon: 'pi pi-sort',
        },
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.sections.sectionType'
          )}:`,
          description: this.trialpalService.translateService.instant(
            'informedConsent.sections.sectionTypes.' + confICSection.type
          ),
          icon: 'pi pi-file',
        },
      ];

      cards.push({
        id: confICSection.id,
        textHeader: this.trialpalService.dictionaryPipe.transform(
          confICSection.title
        ),
        data: confICSection,
        buttonItems: buttonsItems,
        content: content,
        isButtonsEnabled: true,
        isMenuEnabled: false,
        menu: [],
      });
    }

    this.sectionCards = cards;
  }

  async onDelete(): Promise<void> {
    this.trialpalService.showSpinner(
      this.INFORMED_CONSENT_SECTION_ENTITY,
      'DELETE'
    );

    const confICSection = this.confICSectionSelected;

    let input: UpdateConfInformedConsentInput = {
      id: confICSection.id,
      state: ConfState.DELETED,
      _lastUser: this.authService.getUsername(),
      _changeReason: '',
      _version: confICSection._version,
    };

    this.modalChangeReason(input)
      .then(async (input: any) => {
        try {
          await this.informedConsentService.updateConfICSection(
            input,
            confICSection._version
          );
          await this.informedConsentService.deleteInstancesAssociatedWithConfICSectionId(
            confICSection.id,
            input._changeReason
          );

          this.deleteConfICSectionFromArrays(confICSection.id);
          this.trialpalService.hideSpinner();
        } catch (error) {
          this.trialpalService.hideSpinner();
          logger.error('delete confICSection error', error);
          this.trialpalService.showServiceError(
            this.INFORMED_CONSENT_SECTION_ENTITY,
            error
          );
        }
      })
      .catch(() => this.trialpalService.hideSpinner());
  }

  async modalChangeReason(input: any) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'informedConsent.audit.updatingSection'
        ) +
        ': ' +
        this.trialpalService.dictionaryPipe.transform(input.name ?? ''),
      width: '70%',
    });
    return new Promise((resolve, reject) => {
      ref.onClose.subscribe({
        next: (data: string) => {
          if (data) {
            input._changeReason = data;
            resolve(input);
          } else {
            reject(new Error('No reason'));
            this.trialpalService.hideSpinner();
          }
        },
      });
    });
  }
  deleteConfICSectionFromArrays(confICSectionId: string) {
    this.sectionCards = this.sectionCards.filter(
      (confICSection: any) => confICSection?.data?.id !== confICSectionId
    );
    this.confICSections = this.confICSections.filter(
      (confICSection: any) => confICSection?.id !== confICSectionId
    );
    this.trialpalService.showMutationSuccess(
      this.INFORMED_CONSENT_SECTION_ENTITY,
      'DELETE'
    );
  }
}
