<div cdkDropListGroup class="tree-container">
  <div
    *ngFor="let field of fieldGroup; let fieldIndex = index"
    class="tree-node"
    [ngClass]="{
      'group-container': field?.fieldGroup,
      drag:
        (fieldSelected?.type === 'SUB_GROUP' &&
          field?.fieldGroup &&
          isMainFieldGroup) ||
        (fieldSelected && field?.fieldGroup && !isMainFieldGroup),
      'main-group': isMainFieldGroup,
      'drop-zone': isFieldGroupEmpty(field)
    }"
    cdkDrag
    pDroppable
    [cdkDragDisabled]="isMainFieldGroup"
    (onDrop)="
      addFieldInGroup($event, field, isMainFieldGroup, isEvaluationGroup(field))
    "
  >
    <div class="fieldGroup-container">
      <!--Opciones para cada campo o subgrupo-->
      <nav
        class="field-nav"
        [ngClass]="{ 'justify-content-end': !field?.fieldGroup }"
      >
        <p class="group-name" *ngIf="field?.fieldGroup && !isMainFieldGroup">
          {{ field?.templateOptions?.label ?? '' | dictionary }}
        </p>
        <!--opciones que tienen los campos-->
        <ul class="field-config" *ngIf="!isMainFieldGroup">
          <li>
            <span
              [pTooltip]="'designer.field.copy' | translate"
              tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
              class="pi pi-copy btn-icon"
              (click)="copyField(fieldIndex)"
            ></span>
          </li>
          <li>
            <span
              [pTooltip]="'designer.field.edit' | translate"
              tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
              class="pi pi-cog btn-icon"
              style="color: var(--primary-color)"
              (click)="editField(fieldIndex)"
            ></span>
          </li>
          <li>
            <span
              [pTooltip]="'designer.field.delete' | translate"
              tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
              class="pi pi-trash btn-icon"
              style="color: var(--text-danger-color)"
              (click)="openDeleteFieldComponent(fieldIndex)"
            ></span>
          </li>
        </ul>
      </nav>

      <!--Cuando un campo es un subgroup-->
      <div
        *ngIf="field?.fieldGroup; else showField"
        cdkDropList
        [cdkDropListData]="getFieldGroup(field)"
        (cdkDropListDropped)="onDragDrop$.next($event)"
      >
        <!--Mensaje para indicar que no hay elementos añadidos al subgrupo-->
        <p
          class="p-m-0 text-center"
          *ngIf="isFieldGroupEmpty(field) && !isMainFieldGroup"
        >
          {{ 'designer.field.labelDragGroup' | translate }}
        </p>
        <p
          class="p-m-0 text-center"
          *ngIf="isFieldGroupEmpty(field) && isMainFieldGroup"
        >
          {{ 'designer.field.labelDragOnlyGroup' | translate }}
        </p>
        <!--Construye de forma recursiva los campos asociados al grupo-->
        <app-list-field
          [fieldGroup]="getFieldGroup(field)"
          [fieldSelected]="fieldSelected"
          [isAPrincipalGroup]="isMainFieldGroup"
          [isAEvaluationGroup]="isEvaluationGroup(field)"
          [onDragDrop$]="onDragDrop$"
          [projectId]="projectId"
          (fieldGroupChange)="fieldGroupChange.emit($event)"
        ></app-list-field>
      </div>

      <!--construcción del campo en la libreria formly-->
      <ng-template #showField>
        <formly-form
          [fields]="[field] | clearField | dictionaryField"
        ></formly-form>
      </ng-template>
    </div>
  </div>
</div>

<!---mensaje de confirmacion para eliminar un campo-->
<app-delete-confirmation
  [header]="'designer.messageDeleteField.summary' | translate"
  [description]="'designer.messageDeleteField.detail' | translate"
  [btnDeleteText]="'designer.btnDelete' | translate"
  [(display)]="showDeleteFieldComponent"
  (confirm)="deleteField(fieldIndex)"
  position="fixed"
>
</app-delete-confirmation>
