import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent extends FieldWrapper {
  activeIndex: number = 0;
  isValid(index: number): boolean {
    if (this.field.fieldGroup) {
      const fieldGroup = this.field.fieldGroup.slice(0, index + 1);
      return fieldGroup.every(
        (field: FormlyFieldConfig) => field.formControl?.valid
      );
    }
    return false;
  }
}
