<div>
  <div
    *ngIf="
      temperatureService.temperatures &&
      temperatureService.temperatures.length > 0
    "
  >
    <p-table
      [columns]="temperatureService.colTemperatureDays"
      [value]="temperatureService.temperatures"
      [scrollable]="true"
      scrollDirection="horizontal"
      styleClass="p-datatable-gridlines"
      [resizableColumns]="true"
    >
      <ng-template pTemplate="header" let-temperatureDays>
        <tr>
          <th
            id="colHeader"
            (click)="emitEventForm(colTemperatureDay)"
            *ngFor="let colTemperatureDay of temperatureDays; let i = index"
            [ngClass]="{
              'header-select':
                i === temperatureService.currentTemperatureDay?.index
            }"
            class="header"
            appColSelect
          >
            {{ colTemperatureDay.header | dictionary }}
            <div class="p-ml-auto">
              <app-check
                [isConciliated]="colTemperatureDay.isEdition"
              ></app-check>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-value>
        <tr>
          <td *ngFor="let item of value">
            <!-- Agrega el indice y unidad con los indices de cada uno  -->
            <span>
              <span
                [innerHTML]="
                  item.value
                    | textNa
                      : item?.verified
                      : item.type
                      : false
                      : false
                      : false
                      : item?.isEdition
                "
              ></span>

              <sup
                *ngIf="item.commentIndex !== null"
                class="p-ml-1"
                pTooltip="{{ item.comment }}"
              >
                {{ item?.commentIndex }}
              </sup>

              <span *ngIf="item.type === 'temperature'" class="p-pl-1">
                <span
                  [innerHTML]="
                    item?.valueUnit
                      | textNa
                        : item?.verifiedUnit
                        : 'unit'
                        : false
                        : false
                        : false
                        : item?.isEdition
                  "
                ></span>
                <sup
                  *ngIf="item.commentUnit !== null"
                  class="p-ml-1"
                  pTooltip="{{ item.commentUnit }}"
                >
                  {{ item?.commentUnitIndex }}
                </sup>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div
      class="p-grid p-col-12 p-pt-3"
      *ngIf="
        temperatureService.observations &&
        temperatureService.observations.length > 0
      "
    >
      <label for="" class="p-col-12">
        {{ 'conciliation.titleObservation' | translate }}
      </label>
      <div class="p-d-flex p-d-flex-row p-flex-wrap">
        <div *ngFor="let observation of temperatureService.observations">
          <ul>
            <label id="lbl_record" class="p-ml-1">
              {{ observation.index }}.
            </label>
            <span class="line">{{ observation.comment }}</span>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div
    class="p-grid p-fluid"
    *ngIf="
      !temperatureService?.colTemperatureDays ||
      temperatureService?.colTemperatureDays?.length === 0
    "
  >
    <h4 class="notResult">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
