<div class="login p-grid p-ai-center vertical-container">
  <div class="p-col-12 p-md-6 p-grid p-jc-end">
    <div class="container_bienvenida">
      <p style="margin: 0">
        <span class="hola">{{ 'user.hello' | translate }}</span
        ><br />
        <span class="bienvenida">
          {{ 'user.welcome' | translate }}
        </span>
      </p>
    </div>
  </div>
  <div class="p-col-12 p-md-6">
    <form
      class="form container_form"
      name="form"
      (ngSubmit)="onSubmit(f)"
      #f="ngForm"
    >
      <div class="p-fluid">
        <div class="p-field">
          <label for="usuario">{{ 'user.labelUser' | translate }}</label>

          <input
            id="usuario"
            name="usuario"
            type="text"
            autocomplete="off"
            pInputText
            [(ngModel)]="username"
            required
            #usuario="ngModel"
          />
          <small
            class="form-error"
            *ngIf="!usuario.valid && (f.submitted || usuario.touched)"
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
        <div class="p-field">
          <label for="contra">
            {{ 'user.labelPassword' | translate }}
          </label>
          <div id="contra" class="p-inputgroup password-container">
            <input
              id="clave"
              name="clave"
              type="password"
              autocomplete="off"
              pPassword
              [(ngModel)]="password"
              [showPassword]="isPasswordShown"
              [feedback]="false"
              #clave="ngModel"
              required
              class="input_password"
            />
            <button
              pButton
              type="button"
              icon="pi pi-eye"
              (click)="showPasswords()"
              *ngIf="!isPasswordShown"
              class="rounded-left button_show_hide_password"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-eye-slash"
              (click)="showPasswords()"
              *ngIf="isPasswordShown"
              class="rounded-left button_show_hide_password"
            ></button>
          </div>
          <small
            class="form-error"
            *ngIf="!clave.valid && (f.submitted || clave.touched)"
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </div>
      <div class="container_button_submit">
        <button
          pButton
          id="btnSendLogin"
          type="submit"
          label="{{ 'user.buttonLogin' | translate }}"
          class="button_submit"
        ></button>
      </div>
      <div style="text-align: center; margin-top: 3%">
        <div>
          <a
            id="lnkRemeberPass"
            style="color: #007b97"
            routerLink="/resetPassword"
            routerLinkActive="active"
          >
            {{ 'user.labelForgotPassword' | translate }}
          </a>
        </div>
      </div>
      <br />
      <hr />
      <p class="separator-neo">
        <a> {{ 'user.language' | translate }}</a>
      </p>
      <div class="outside">
        <div class="within">
          <a class="espana li" href="" (click)="lang('es')"
            ><img src="../../../../assets/imgs/es.png" alt="" /> Español</a
          >
        </div>
        <div class="within">
          <a class="english li" href="" (click)="lang('en')"
            ><img src="../../../../assets/imgs/en.png" alt="" /> English</a
          >
        </div>
      </div>
      <p class="separator-neo">
        {{ versionProject }}
      </p>
    </form>
  </div>
</div>

<p-dialog
  [header]="'password.titleDialogCodeMFA' | translate"
  [modal]="true"
  [(visible)]="display"
  *ngIf="display"
>
  <input
    id="codeMFA"
    name="codeMFA"
    type="text"
    autocomplete="off"
    pInputText
    placeholder="{{ 'password.placeholderMfa' | translate }}"
    [(ngModel)]="codeMFA"
  />
  <button
    pButton
    id="btnCodeMFA"
    type="button"
    label="{{ 'user.buttonLogin' | translate }}"
    class="button_submit"
    style="margin-top: 10px"
    (click)="confirmLogin()"
  ></button>

  <br />
  <div *ngIf="showMFARetry">
    <p>{{ 'password.timeOutMessageCodeMFA' | translate }}</p>
    <a href="javascript:void(0)" (click)="onSubmit(f)">{{
      'password.resendCodeMFA' | translate
    }}</a>
  </div>
</p-dialog>
