import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import {
  ButtonItem,
  Tp2Card,
  Tp2CardContent,
} from 'src/app/shared/global.variables';
import { InformedConsentService } from '../informed-consent.service';
import {
  ConfInformedConsent,
  UpdateConfInformedConsentInput,
} from '../informed-consent.types';
import { ConfState } from 'src/app/services/trialpal.types';
const logger = new Logger('tp2-logger-listInformedConsentPage');

@Component({
  selector: 'app-informed-consent-list',
  templateUrl: './informed-consent-list.component.html',
  styleUrls: ['./informed-consent-list.component.scss'],
})
export class InformedConsentListComponent implements OnInit {
  projectId = '';
  eConsentsCard: Tp2Card[] = [];
  showDisplayDeleteComponent: boolean = false;
  confInformedConsentSelected: any = {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trialpalService: TrialpalService,
    private readonly informedConsentService: InformedConsentService
  ) {}

  async ngOnInit() {
    this.trialpalService.showSpinner(
      'informedConsent.informedConsents',
      'LIST'
    );
    this.projectId = this.route.snapshot.params.projectId;

    let confInformedConsents =
      await this.informedConsentService.getConfInformedConsentByProjectId(
        this.projectId
      );

    this.buildTPCardData(confInformedConsents);

    this.trialpalService.hideSpinner();
  }

  buildTPCardData(eConsents: ConfInformedConsent[]) {
    const cards: Tp2Card[] = [];

    for (let eConsent of eConsents) {
      const buttonsItems: ButtonItem[] = [
        {
          color: '#205163',
          command: () => this.onEdit(eConsent),
          icon: 'pi pi-pencil',
          tooltip: '',
          disabled: false,
        },
        {
          color: '#E41E1E',
          command: () => {
            this.showDisplayDeleteComponent = true;
            this.confInformedConsentSelected = eConsent;
          },
          icon: 'pi pi-trash',
          tooltip: '',
          disabled: false,
        },
      ];

      const content: Tp2CardContent[] = [
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.version'
          )}:`,
          description: eConsent.icVersion,
          icon: 'pi pi-clock',
        },
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.labelType'
          )}:`,
          description: this.trialpalService.translateService.instant(
            'informedConsent.informedConsentTypes.' + eConsent.type
          ),
          icon: 'pi pi-file',
        },
      ];

      cards.push({
        id: eConsent.id,
        textHeader: this.trialpalService.dictionaryPipe.transform(
          eConsent.name
        ),
        buttonItems: buttonsItems,
        content: content,
        isButtonsEnabled: true,
        isMenuEnabled: false,
        menu: [],
      });
    }

    this.eConsentsCard = cards;
  }

  create(): void {
    this.router.navigate(['add'], {
      relativeTo: this.route,
    });
  }

  onEdit(item: any): void {
    this.router.navigate(['edit', item.id], {
      relativeTo: this.route,
    });
  }

  onDetail(item: any): void {
    this.router.navigate(['detail', item.id], {
      relativeTo: this.route,
    });
  }

  async onDelete(): Promise<void> {
    this.trialpalService.showSpinner(
      'informedConsent.informedConsent',
      'DELETE'
    );
    const confInformedConsent = this.confInformedConsentSelected;
    let input: UpdateConfInformedConsentInput = {
      id: confInformedConsent.id,
      state: ConfState.DELETED,
      _lastUser: this.informedConsentService.auth.getUsername(),
      _version: confInformedConsent._version,
    };
    this.modalChangeReason(input)
      .then(async (input: any) => {
        try {
          await this.informedConsentService.updateConfInfomedConsentInstance(
            input
          );

          await this.informedConsentService.deleteInstancesAssociatedWithConfInformedConsentId(
            confInformedConsent.id,
            input._changeReason
          );

          this.trialpalService.hideSpinner();
          this.trialpalService.showMutationSuccess(
            'informedConsent.informedConsent',
            'DELETE'
          );
          this.eConsentsCard = this.eConsentsCard.filter(
            (_confInformedConset: any) =>
              _confInformedConset.id !== confInformedConsent.id
          );
        } catch (error) {
          this.showDeleteError(error);
        }
      })
      .catch(() => {
        this.trialpalService.hideSpinner();
      });
  }

  async modalChangeReason(input: any) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'informedConsent.audit.updatingEconsent'
        ) +
        ': ' +
        this.trialpalService.dictionaryPipe.transform(input.name ?? ''),
      width: '70%',
    });
    return new Promise((resolve, reject) => {
      ref.onClose.subscribe({
        next: (data: string) => {
          if (data) {
            input._changeReason = data;
            resolve(input);
          } else {
            reject(new Error('No reason'));
            this.trialpalService.hideSpinner();
          }
        },
      });
    });
  }

  showDeleteError(error: any) {
    this.trialpalService.hideSpinner();
    logger.error('deleteEconsent error', error);
    this.trialpalService.showServiceError(
      'informedConsent.informedConsent',
      error
    );
  }
}
