import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccessibilityGroupInput } from '../../project.types';

@Component({
  selector: 'app-add-edit-accessibility-group',
  templateUrl: './add-edit-accessibility-group.component.html',
  styleUrls: ['./add-edit-accessibility-group.component.scss'],
})
export class AddEditAccessibilityGroupComponent implements OnInit {
  projectId: string = '';
  groups: string[] = [];
  projectAccessibilityGroups: AccessibilityGroupInput[] = [];
  booleanOptions: any[] = [];
  accessibilityGroups: AccessibilityGroupInput[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private translateService: TranslateService
  ) {
    this.projectId = this.config.data.projectId;
    this.groups = this.config.data.groups;
    this.projectAccessibilityGroups =
      this.config.data.accessibilityGroups ?? [];
    this.buildAccessibilityGroups();
  }

  ngOnInit(): void {
    this.setBooleanOptions();
  }

  buildAccessibilityGroups() {
    this.accessibilityGroups = [];
    for (let group of this.groups) {
      const accessibilityGroup = this.projectAccessibilityGroups.find(
        (accessibilityGroup: AccessibilityGroupInput) =>
          accessibilityGroup.group === group
      );
      this.accessibilityGroups.push({
        group: group,
        isActive: accessibilityGroup?.isActive ?? false,
      });
    }
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }
  close() {
    this.ref.close(null);
  }

  saveInformation() {
    this.ref.close(this.accessibilityGroups);
  }
}
