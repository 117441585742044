import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from 'aws-amplify';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';
import {
  CreateSignatureInstanceInput,
  InformedConsentState,
  UpdateConsentedUserInput,
  UpdateICQuestionInstanceInput,
  UpdateICSectionInstanceInput,
  UpdateInformedConsentInstanceInput,
} from 'src/app/modules/informed-consent/informed-consent.types';
import { SitesService } from 'src/app/modules/sites/sites.service';
import { User } from 'src/app/modules/user/user.types';
import { tp2SendProjectAdminNotificationInput } from 'src/app/services/API.service';
import {
  SignatureEntityType,
  SignatureReason,
  SignatureType,
} from 'src/app/services/trialpal.types';
import { ViewPdfComponent } from 'src/app/shared/components/view-pdf/view-pdf.component';
import { TrialpalService } from '../../../../../../services/trialpal.service';
import { ProjectAndSiteInterface } from '../../../informed-consent-follow-up-user-form/informed-consent-follow-up-user-form.component';
import { ManagePdfVersionsComponent } from '../manage-pdf-versions/manage-pdf-versions.component';
import { SignatureEconsentComponent } from './components/signature-econsent/signature-econsent.component';

const logger = new Logger('tp2-econsent-detail');
@Component({
  selector: 'app-menu-actions',
  templateUrl: './menu-actions.component.html',
  styleUrls: ['./menu-actions.component.scss'],
})
export class MenuActionsComponent implements OnInit {
  menuItems: MenuActionItem[] = [];
  expanded: boolean = false;
  displayDialog: boolean = false;
  isExecutionSummaryVisible: boolean = false;
  timeZoneOffset: number = 0;
  @Input() econsentData: any;
  @Input() confInformedConsentId: string = '';
  @Input() projectAndSite: ProjectAndSiteInterface = {
    projectId: '',
    siteId: '',
  };
  @Input() confInformedConsent: any;
  @Output() refresh: EventEmitter<void> = new EventEmitter();

  constructor(
    private trialpalService: TrialpalService,
    private readonly informedConsentService: InformedConsentService,
    private readonly dialogService: DialogService,
    private sitesService: SitesService
  ) {}
  async ngOnInit(): Promise<void> {
    await this.buildMenuItems();
    this.timeZoneOffset = await this.getTimeZoneOffset();
  }

  async buildMenuItems() {
    this.menuItems = [
      {
        label: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.executionSummary'
        ),
        icon: this.getIconToToExecutionSummary(),
        className: this.getClassNameToExecutionSummary(),
        disabled: !this.isExecutionSummaryEnable(),
        command: (_event: any) => {
          this.isExecutionSummaryVisible = true;
          this.informedConsentService.recordeConsentEvent(
            'site_consent_summary',
            {
              project: this.projectAndSite.projectId,
              site: this.projectAndSite.siteId,
            }
          );
        },
      },
      {
        label: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.signInvestigator'
        ),
        icon: this.getIconToSignatureByInvestigator(),
        command: async () => {
          await this.showSignatureComponent();
          this.informedConsentService.recordeConsentEvent(
            'site_consent_signinves_entry',
            {
              project: this.projectAndSite.projectId,
              site: this.projectAndSite.siteId,
            }
          );
        },
        className: this.getClassNameToSignatureByInvestigator(),
        disabled: !this.isSignatureByInvestigatorEnabled(),
      },
      /** Se oculta opcion de menu ya que no ingresa en el MVP */
      // {
      //   label: this.trialpalService.translateService.instant(
      //     'informedConsent.followUp.table.rowDetail.createSubject'
      //   ),
      //   icon: 'assets/icons/informed-consent/contact_disabled.svg',
      //   command: (event: any) => {
      //     console.log(event);
      //   },
      // },
      {
        label: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.viewPDF'
        ),
        disabled: !this.isViewPDFEnable(),
        className: this.getClassNameToViewPDF(),
        icon: this.getIconToViewPDF(),
        command: () => {
          this.showPdfManager();
        },
      },
      {
        label: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.cancelConsent'
        ),
        disabled:
          this.econsentData?.state ===
            InformedConsentState.SIGNED_BY_INVESTIGATOR ||
          this.econsentData?.state === InformedConsentState.DELETED,
        get icon() {
          return this.disabled
            ? 'assets/icons/informed-consent/trash_disabled.svg'
            : 'assets/icons/informed-consent/trash.svg';
        },
        command: () => {
          this.displayDialog = true;
        },
      },
    ];
  }

  isViewPDFEnable(): boolean {
    if (!this.econsentData?.consentedUsers) return false;
    let isSignedByParticipant = false;
    this.econsentData.consentedUsers.forEach((consentedUser: any) => {
      const stateChange = consentedUser.stateChanges.find(
        (SC: any) => SC.state === InformedConsentState.SIGNED_BY_PARTICIPANT
      );
      if (stateChange) isSignedByParticipant = true;
    });

    if (isSignedByParticipant) return true;

    isSignedByParticipant = this.econsentData.consentedUsers.find(
      (user: any) => user.state === InformedConsentState.SIGNED_BY_PARTICIPANT
    );

    return isSignedByParticipant;
  }

  getClassNameToViewPDF(): string {
    if (this.isViewPDFEnable()) return 'btn-ViewPDFActive';
    return '';
  }

  getIconToViewPDF(): string {
    let path = 'assets/icons/informed-consent';
    if (this.isViewPDFEnable()) return `${path}/document_enabled.svg`;
    return `${path}/document_disabled.svg`;
  }
  isSignatureByInvestigatorEnabled(): boolean {
    const informedConsentState = this.econsentData?.state;
    return informedConsentState === InformedConsentState.SIGNED_BY_PARTICIPANT;
  }

  isSignatureByInvestigatorSuccess(): boolean {
    const informedConsentState = this.econsentData?.state;
    return informedConsentState === InformedConsentState.SIGNED_BY_INVESTIGATOR;
  }

  getClassNameToSignatureByInvestigator(): string {
    if (this.isSignatureByInvestigatorSuccess()) return 'btn-success';
    if (!this.isSignatureByInvestigatorEnabled()) return '';
    return 'btn-active';
  }

  getIconToSignatureByInvestigator(): string {
    let path = 'assets/icons/informed-consent';
    if (this.isSignatureByInvestigatorSuccess()) return `${path}/check.svg`;
    if (!this.isSignatureByInvestigatorEnabled()) {
      return `${path}/award_disabled.svg`;
    }
    return `${path}/award.svg`;
  }

  isExecutionSummaryEnable() {
    if (!this.econsentData?.consentedUsers) return false;

    const assignedConsented = this.econsentData.consentedUsers.filter(
      (user: any) => user.state !== InformedConsentState.ASSIGNED
    );

    return assignedConsented.length > 0;
  }

  getClassNameToExecutionSummary(): string {
    if (this.isExecutionSummaryEnable()) return 'btn-ExecutionSummaryActive';
    return '';
  }

  getIconToToExecutionSummary(): string {
    let path = 'assets/icons/informed-consent';
    if (this.isExecutionSummaryEnable()) return `${path}/chat_enabled.svg`;
    return `${path}/chat_disabled.svg`;
  }

  getExecutionSummaryContainerStyle(consentedUser: any): string {
    const isSignedByParticipant = this.getStateByConsentedUser(
      consentedUser,
      InformedConsentState.SIGNED_BY_PARTICIPANT
    );
    if (
      consentedUser.state === InformedConsentState.SIGNED_BY_PARTICIPANT ||
      consentedUser.state === InformedConsentState.SIGNED_BY_INVESTIGATOR ||
      isSignedByParticipant
    )
      return 'greenBorder';
    return 'blueBorder ';
  }

  toogleMenu() {
    this.expanded = !this.expanded;
  }

  getExecutionSummaryStateByUser(consentedUser: any): string {
    if (
      this.econsentData.state === InformedConsentState.SIGNED_BY_INVESTIGATOR
    ) {
      return this.trialpalService.translateService.instant(
        'informedConsent.followUp.executionSummary.completedSignatures'
      );
    }

    if (consentedUser.state === InformedConsentState.ASSIGNED) {
      return this.trialpalService.translateService.instant(
        'informedConsent.followUp.executionSummary.pendingToStartReading'
      );
    }

    if (consentedUser.state === InformedConsentState.IN_REVIEW) {
      return this.trialpalService.translateService.instant(
        'informedConsent.followUp.executionSummary.readingProcess'
      );
    }
    if (consentedUser.state === InformedConsentState.REVIEWED) {
      return this.getReviewedState(consentedUser);
    }
    if (
      consentedUser.state === InformedConsentState.PENDING_FOR_SIGNATURE ||
      consentedUser.state === InformedConsentState.SIGNED_BY_PARTICIPANT
    ) {
      return this.trialpalService.translateService.instant(
        'informedConsent.followUp.executionSummary.signatureProcess'
      );
    }

    return this.trialpalService.translateService.instant(
      'informedConsent.followUp.executionSummary.readingProcess'
    );
  }

  getReviewedState(consentedUser: any) {
    const inReviewDate = this.getStateByConsentedUser(
      consentedUser,
      InformedConsentState.IN_REVIEW
    )?.date;
    const signedByParticipantDate = this.getStateByConsentedUser(
      consentedUser,
      InformedConsentState.REVIEWED
    )?.date;

    let diffTime = '';
    if (inReviewDate && signedByParticipantDate) {
      diffTime = this.informedConsentService.transformDatePipe.diff(
        inReviewDate,
        signedByParticipantDate
      );
    }

    return (
      this.trialpalService.translateService.instant(
        'informedConsent.followUp.executionSummary.readingCompletedOn'
      ) + diffTime
    );
  }

  getExecutionSummaryFinishDate(consentedUser: any): string {
    const isReviewed = this.getStateByConsentedUser(
      consentedUser,
      InformedConsentState.REVIEWED
    );
    if (consentedUser.state === InformedConsentState.REVIEWED || isReviewed) {
      return (
        this.trialpalService.translateService.instant(
          'informedConsent.followUp.executionSummary.finishDate'
        ) +
        this.getStateDateByConsentState(
          consentedUser,
          InformedConsentState.REVIEWED
        )
      );
    }

    return '';
  }

  getExecutionSummaryStarDate(consentedUser: any): string {
    if (consentedUser.state === InformedConsentState.ASSIGNED) return '';

    return (
      this.trialpalService.translateService.instant(
        'informedConsent.followUp.executionSummary.startDate'
      ) +
      this.getStateDateByConsentState(
        consentedUser,
        InformedConsentState.IN_REVIEW
      )
    );
  }

  getStateDateByConsentState(consentedUser: any, informedConsentState: any) {
    const state = this.getStateByConsentedUser(
      consentedUser,
      informedConsentState
    );

    return state?.date
      ? this.informedConsentService.transformDatePipe.transformToSiteHour(
          state.date,
          this.timeZoneOffset
        )
      : '';
  }

  getStateByConsentedUser(consentedUser: any, informedConsentState: any) {
    const stateChange = consentedUser.stateChanges.find(
      (SC: any) => SC.state === informedConsentState
    );
    return stateChange;
  }

  closeExecutionSummary() {
    this.isExecutionSummaryVisible = false;
  }

  async cancelConsent() {
    try {
      this.displayDialog = false;
      this.trialpalService.showSpinner('informedConsent.entity', 'DELETE');
      const input: UpdateInformedConsentInstanceInput = {
        id: this.econsentData.id,
        _version: this.econsentData._version,
      };
      const data: Partial<UpdateInformedConsentInstanceInput> = {
        state: InformedConsentState.DELETED,
        _changeReason: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.consentWithdrawn'
        ),
      };
      const ICQuestionInstances =
        await this.informedConsentService.getICQuestionInstanceByInformedConsentInstanceId(
          this.econsentData.id
        );
      const ICSectionInstances =
        await this.informedConsentService.getICSectionInstanceByInformedConsentInstanceId(
          this.econsentData.id
        );
      const informedConsentUpdated =
        await this.informedConsentService.updateInfomedConsentInstance(
          input,
          data
        );
      this.econsentData = Object.assign(
        this.econsentData,
        informedConsentUpdated
      );
      const consentedUsers = this.econsentData?.consentedUsers ?? [];
      for (const consentedUser of consentedUsers) {
        const inputCU: UpdateConsentedUserInput = {
          id: consentedUser.id,
          _version: consentedUser._version,
        };

        const consentedUserUpdated =
          await this.informedConsentService.updateConsentedUser(inputCU, {
            state: InformedConsentState.DELETED,
          });

        consentedUser._version = consentedUserUpdated._version;
        consentedUser.state = consentedUserUpdated.state;
      }
      for (const ICQuestionInstance of ICQuestionInstances) {
        const inputICQ: UpdateICQuestionInstanceInput = {
          id: ICQuestionInstance.id,
          _version: ICQuestionInstance._version,
        };
        await this.informedConsentService.updateICQuestionInstance(
          inputICQ,
          data
        );
      }
      for (const ICSectionInstance of ICSectionInstances) {
        const inputICS: UpdateICSectionInstanceInput = {
          id: ICSectionInstance.id,
          _version: ICSectionInstance._version,
        };
        await this.informedConsentService.updateICSectionInstance(
          inputICS,
          data
        );
      }
      this.trialpalService.hideSpinner();
      this.showMessage(
        'success',
        this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.consentWithdrawn'
        ),
        this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.toastConsentCanceledDetail'
        )
      );
      this.refresh.emit();
    } catch (error) {
      this.trialpalService.hideSpinner();
      this.showMessage(
        'error',
        this.trialpalService.translateService.instant(
          'general.messageErrorOperation.summary'
        ),
        this.trialpalService.translateService.instant(
          'general.messageErrorOperation.detail'
        )
      );
      logger.debug('Error anulando consentimiento', error);
    }
  }

  showMessage(alertType: string, summary: string, detail: string): void {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: alertType,
      summary: summary,
      detail: detail,
    });
  }

  async showSignatureComponent() {
    const signatureComponentRef = this.dialogService.open(
      SignatureEconsentComponent,
      {
        closeOnEscape: true,
        dismissableMask: true,
        showHeader: false,
        styleClass: 'signature-modal',
        data: {
          confInformedConsentId: this.confInformedConsentId,
        },
      }
    );

    signatureComponentRef.onClose.subscribe((response: any) => {
      if (response?.isSignatureCorrect) {
        const { consenterRol } = response ?? '';
        this.signatureByInvestigator(consenterRol).then();
      }
    });
  }

  async signatureByInvestigator(consenterRol: string): Promise<void> {
    await this.updateInformedConsentInstanceBySignatureInvestigatorState(
      consenterRol
    );
    await this.createSignatureInstance();
    const createdPDF = await this.informedConsentService.createEConsentPDF(
      this.econsentData.id
    );
    if (createdPDF) {
      this.sendEConsentPDFNotification(JSON.parse(createdPDF).url);
    }
    logger.debug('EConsentData', this.econsentData);
    const data = await this.informedConsentService.getInformedConsentById(
      this.econsentData.id
    );
    this.econsentData = Object.assign(this.econsentData, data);
    this.trialpalService.hideSpinner();
    this.updateMenuItems();
    this.showSignatureSuccessMessage();
  }

  async sendEConsentPDFNotification(urlPDF: string) {
    const urlPDFRoot = urlPDF.split('.pdf')[0];
    const emails = this.econsentData?.consentedUsers
      .map((consentedUser: any) =>
        consentedUser.requiresSendingEmail ? consentedUser.user.email : null
      )
      .join(',');
    logger.debug('Emails to send notification', emails);
    const input: tp2SendProjectAdminNotificationInput = {
      data: JSON.stringify({
        siteId: this.econsentData.siteId,
        email: emails,
        urlPDF: `${urlPDFRoot}.pdf`,
      }),
      user: this.informedConsentService.auth.getUsername(),
      source: 'eConsentFollowUpSignature',
    };
    await this.informedConsentService.sendProjectAdminNotification(input);
  }

  updateMenuItems() {
    this.buildMenuItems();
    this.refresh.emit();
    this.informedConsentService.recordeConsentEvent(
      'site_consent_sign_invest',
      {
        project: this.projectAndSite.projectId,
        site: this.projectAndSite.siteId,
      }
    );
  }

  async createSignatureInstance(): Promise<void> {
    const input: CreateSignatureInstanceInput = {
      date: new Date().toISOString(),
      entityId: this.econsentData.id,
      entityType: SignatureEntityType.ECONSENT,
      reason: SignatureReason.APPROVAL,
      signedInfo: JSON.stringify(this.econsentData),
      type: SignatureType.USER_PASS,
      _changeReason: '',
      _lastUser: this.informedConsentService.auth.getUsername(),
    };
    await this.informedConsentService.createSignatureInstance(input);
  }

  async updateInformedConsentInstanceBySignatureInvestigatorState(
    consenterRol: string
  ): Promise<void> {
    const login = this.informedConsentService.auth.getUsername();
    const user: User = await this.informedConsentService.getUserByLogin(login);
    const newInformedConsentState = InformedConsentState.SIGNED_BY_INVESTIGATOR;
    const stateChanges = this.informedConsentService.getStateChanges(
      newInformedConsentState,
      this.econsentData?.stateChanges
    );

    const input: UpdateInformedConsentInstanceInput = {
      id: this.econsentData.id,
      consenterName: user.name,
      consenterRole: consenterRol,
      consenterUserId: user.id,
      state: newInformedConsentState,
      stateChanges: stateChanges,
      _changeReason: '',
      _lastUser: user.login,
      _version: this.econsentData._version,
    };

    const informedConsentUpdated =
      await this.informedConsentService.updateInfomedConsentInstance(
        this.econsentData,
        input
      );

    this.econsentData = Object.assign(
      this.econsentData,
      informedConsentUpdated
    );
  }

  showSignatureSuccessMessage() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.rowDetail.signatureInvestigatorSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.rowDetail.signatureInvestigatorDetail'
      ),
    });
  }
  showPdfManager() {
    const pdfFiles = this.econsentData.pdfFiles;
    if (pdfFiles?.length === 0 || !pdfFiles) return this.showNoPdfMessage();
    if (pdfFiles?.length === 1) return this.openPdfModal(pdfFiles[0]);

    return this.openManagePDFVersions();
  }

  openManagePDFVersions() {
    const ref = this.dialogService.open(ManagePdfVersionsComponent, {
      data: {
        informedConsent: this.econsentData,
        confInformedConsent: this.confInformedConsent,
      },
      width: '80%',
      height: '80%',
      showHeader: false,
    });

    ref.onClose.subscribe();
  }

  openPdfModal(pdfUrl: any): void {
    const ref = this.dialogService.open(ViewPdfComponent, {
      data: {
        PDFUrl: pdfUrl.fileUrl,
        sourceComponent: 'eConsentFollowUp',
        subjectId: this.econsentData?.subjectId,
      },
      closeOnEscape: true,
      dismissableMask: true,
      width: '80%',
      height: '90%',
    });

    ref.onClose.subscribe();
  }
  showNoPdfMessage() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: this.trialpalService.translateService.instant(
        'informedConsent.entity'
      ),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.followUp.viewPdf.noPdf'
      ),
    });
  }

  async getTimeZoneOffset() {
    const site = await this.sitesService.getSite(this.econsentData?.siteId);
    return this.sitesService.getSiteGMT(site.timezone);
  }
}

type ClassName = {
  className?: string;
};

type MenuActionItem = MenuItem & ClassName;
