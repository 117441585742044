import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@aws-amplify/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { ProjectService } from 'src/app/modules/project/project.service';
import { SitesService } from 'src/app/modules/sites/sites.service';
import { SubjectsService } from 'src/app/modules/subjects/subjects.service';
import { UserService } from 'src/app/modules/user/user.service';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  GetAuditInfoQuery,
  Symptom,
  YN,
  YNA,
} from 'src/app/services/trialpal.types';
import { Roles, TP2Entites, TP2Variables } from '../../global.variables';
import { DictionaryPipe } from '../../pipes/dictionary.pipe';

const logger = new Logger('tp2-logger-AuditInfoPage');

@Component({
  selector: 'app-audit-info',
  templateUrl: './audit-info.component.html',
  styleUrls: ['./audit-info.component.scss'],
})
export class AuditInfoComponent implements OnInit {
  @Input() auditTrace: any[] = [];
  @Input() multiEntity: boolean = false;
  data: Array<AuditInfoVO> = [];
  idRecord: string = '';
  entity: TP2Entites | undefined = undefined;
  entityName = '';
  description = '';
  parentEntities = [];
  copyData: any[] = [];
  subjects: DataArray[] = [];
  sites: DataArray[] = [];
  projects: DataArray[] = [];
  symptoms: any[] = [];
  professionalHealthCareTypes: any[] = [];
  admisionHospital: any[] = [];
  auditUsers: any[] = [];
  dataIgnore: string[] = [
    'updatedAt',
    'createdAt',
    '_lastChangedAt',
    '__typename',
    '_version',
    'logoURL',
    'id',
    '_lastUser',
    '_ttl',
    '_changeReason',
    'scheduledPhases',
    'reportInstanceId',
    'dayInstanceId',
    'temperatureWhichOtherRoute',
    'isElectronicSignatureRequired',
    '_deleted',
  ];

  constructor(
    private tp2Service: TrialpalService,
    private translateService: TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private transformDatePipe: TransformDatePipe,
    private subjectService: SubjectsService,
    private projectService: ProjectService,
    private dictionaryPipe: DictionaryPipe,
    private trialpalService: TrialpalService,
    private siteService: SitesService,
    private userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.multiEntity) {
      this.entity = this.config.data.entity;
      this.description =
        this.dictionaryPipe.transform(this.config.data.description) || '';
      this.idRecord = this.config.data.idRecord;
      // Se usa el estándar de llaves por módulos donde debemos tener el nombre de la entidad en la llave entity.
      const translateEntityKey = this.entity?.toLowerCase();
      this.entityName = this.translateService.instant(
        translateEntityKey + '.entity'
      );
    }
    this.symptoms = this.trialpalService.getTranslatedEnum(
      'enums.symptoms',
      Symptom
    );
    this.professionalHealthCareTypes = this.trialpalService.getTranslatedEnum(
      'requiredVisitProfessional',
      YNA
    );
    this.admisionHospital = this.trialpalService.getTranslatedEnum(
      'enums.admissionHospital',
      YN
    );
    if (this.auditTrace.length > 0) {
      this.auditTrace = this.auditTrace.map((trace) => ({
        ...trace,
        data: JSON.parse(trace.data),
      }));
      this.getAuditInfo(this.auditTrace)
        .then(async (res) => {
          await this.buildCHistoryList(res);
        })
        .catch((error) => {
          logger.error('getAuditInfo error', error);
          this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
        })
        .finally(() => this.tp2Service.hideSpinner());
    } else {
      this.tp2Service.showSpinner('audit.loadingAuditInfo');
      const originalData: any[] = [];
      this.tp2Service
        .getAuditInfo(this.entity, this.idRecord)
        .then((response: GetAuditInfoQuery) => {
          logger.debug('getAuditInfo response', response);
          if (response.items) {
            for (const i of response.items) {
              if (i) {
                i.data = JSON.parse(i.data);
                originalData.push(i);
              }
            }
            this.copyData = [...originalData];
          }
          this.getAuditInfo(this.copyData)
            .then(async (res) => {
              await this.buildCHistoryList(res);
            })
            .catch((error) => {
              logger.error('getAuditInfo error', error);
              this.tp2Service.showServiceError(
                'audit.actions.getAuditInfo',
                error
              );
            })
            .finally(() => this.tp2Service.hideSpinner());
        })
        .catch((error) => {
          logger.error('getAuditInfo error', error);
          this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
        });
    }
  }

  private async getAuditInfo(data: any) {
    for await (const i of data) {
      if (i.data.subjects) {
        const element = i.data.subjects;
        if (element.type === 'created') {
          if (element.data.newData.S) {
            let arrNew: string[] = [];
            let subject = this.subjects.find(
              (subject) => subject.id === element.data.newData.S
            );
            if (subject) {
              arrNew.push(subject.name);
            } else {
              const SUBJECT = await this.getSubject(element.data.newData.S);
              arrNew.push(SUBJECT.subjectNum);
              this.subjects.push({
                id: SUBJECT.idSubject,
                name: SUBJECT.subjectNum,
              });
            }
            element.data.newData.S = arrNew.join(', ');
          }
        }
        if (element.S) {
          const dataSubject = element.S.data;
          if (element.S.data.newData) {
            let splitNew = dataSubject.newData.split(/[,\s]+/);
            let arrNew: string[] = [];

            if (splitNew) {
              for await (const i of splitNew) {
                let subjects = this.subjects.find(
                  (subjects) => subjects.id === i
                );
                if (subjects) {
                  arrNew.push(subjects.name);
                } else {
                  const SUBJECT = await this.getSubject(i);
                  arrNew.push(SUBJECT.subjectNum);
                  this.subjects.push({
                    id: SUBJECT.idSubject,
                    name: SUBJECT.subjectNum,
                  });
                }
              }
              dataSubject.newData = arrNew.join(', ');
            }
          }
          if (element.S.data.oldData) {
            let splitOld = dataSubject.oldData.split(/[,\s]+/);
            let arrOld: string[] = [];

            if (splitOld) {
              for await (const i of splitOld) {
                let subjects = this.subjects.find(
                  (subjects) => subjects.id === i
                );
                if (subjects) {
                  arrOld.push(subjects.name);
                } else {
                  const SUBJECT = await this.getSubject(i);
                  arrOld.push(SUBJECT.subjectNum);
                  this.subjects.push({
                    id: SUBJECT.idSubject,
                    name: SUBJECT.subjectNum,
                  });
                }
              }
              dataSubject.oldData = arrOld.join(', ');
            }
          }
        }
      }
      if (i.data.sites || i.data.siteId) {
        let elementTemp = i.data;
        if (i.data.sites) {
          elementTemp = i.data.sites;
        } else if (i.data.siteId) {
          elementTemp = i.data.siteId;
        }
        const element = elementTemp;
        if (element.type === 'created') {
          if (element.data.newData.S) {
            let arrNew: any[] = [];
            let sites = this.sites.find(
              (site) => site.id === elementTemp.data.newData.S
            );
            if (sites) {
              arrNew.push(sites.name);
            } else {
              const SITE = await this.getSite(element.data.newData.S);
              arrNew.push(SITE.nameSite);
              this.sites.push({ id: SITE.idSite, name: SITE.nameSite });
            }
            element.data.newData.S = arrNew.join(', ');
          }
        }
        if (element.S) {
          if (element.S.data) {
            const dataSite = element.S.data;
            const splitNew = dataSite.newData.split(/[,\s]+/);
            if (splitNew) {
              let arrNew = await this.splitSites(splitNew);
              dataSite.newData = arrNew.join(', ');
            }
            const splitOld = dataSite.oldData.split(/[,\s]+/);
            if (splitOld) {
              let arrOld = await this.splitSites(splitOld);
              dataSite.oldData = arrOld.join(', ');
            }
          }
        }
        if (element.L) {
          for (const elementData of element.L) {
            if (
              elementData.site ||
              elementData.site.data ||
              elementData.site.S.data
            ) {
              const dataSite =
                elementData.site?.S?.data ??
                elementData.site?.data ??
                elementData.site;
              const splitNew = (
                dataSite?.newData?.S ?? dataSite?.newData
              )?.split(/[,\s]+/);
              if (splitNew) {
                let arrNew = await this.splitSites(splitNew);
                dataSite.newData = arrNew.join(', ');
              }
              const splitOld = (
                dataSite?.oldData?.S ?? dataSite?.oldData
              )?.split(/[,\s]+/);
              if (splitOld) {
                let arrOld = await this.splitSites(splitOld);
                dataSite.oldData = arrOld.join(', ');
              }
            }
            if (elementData?.recipients?.L) {
              const recipients: any = { S: { data: {} } };
              const dataRecipients: any = elementData.recipients.L;
              if (Array.isArray(dataRecipients)) {
                if (
                  dataRecipients.every(
                    (dataRecipient: any) => dataRecipient.type === 'unchanged'
                  )
                ) {
                  recipients.S.type = 'unchanged';
                  recipients.S.data.newData = (dataRecipients ?? [])
                    .map((recipient: any) => recipient?.data?.S)
                    .join(',');
                  recipients.S.data.oldData = (dataRecipients ?? [])
                    .map((recipient: any) => recipient?.data?.S)
                    .join(',');
                } else {
                  recipients.S.type = 'updated';
                  elementData.type = 'updated';
                  recipients.S.data.newData = (dataRecipients ?? [])
                    .filter(
                      (recipient: any) =>
                        recipient.type === 'unchanged' ||
                        recipient.type === 'created'
                    )
                    .map((recipient: any) => recipient?.data?.S)
                    .join(',');
                  recipients.S.data.oldData = (dataRecipients ?? [])
                    .filter(
                      (recipient: any) =>
                        recipient.type === 'unchanged' ||
                        recipient.type === 'deleted'
                    )
                    .map((recipient: any) => recipient?.data?.S)
                    .join(',');
                }
              } else if (
                typeof dataRecipients === 'object' &&
                (dataRecipients.type === 'created' ||
                  dataRecipients.type === 'deleted')
              ) {
                recipients.S.type = dataRecipients.type;
                recipients.S.data.newData = (
                  dataRecipients?.data?.newData?.L ?? []
                )
                  .map((recipient: any) => recipient?.S)
                  .join(',');
              }
              elementData.recipients = recipients;
            }
          }
        }
      }
      if (i.data.role) {
        const element = i.data.role;
        const splitNew = element.S.data.newData.split(',');
        let arrNew: any[] = [];
        if (splitNew) {
          for (const newRole of splitNew) {
            const roleInstance = this.translateService.instant(
              'user.enums.groups.' + newRole
            );
            arrNew.push(roleInstance);
          }
          element.S.data.newData = arrNew.join(', ');
        }
        const splitOld = element.S.data.oldData.split(',');
        let arrOld: any[] = [];
        if (splitOld) {
          for (const oldRol of splitOld) {
            const roleInstance = this.translateService.instant(
              'user.enums.groups.' + oldRol
            );
            arrOld.push(roleInstance);
          }
          element.S.data.oldData = arrOld.join(', ');
        }
      }
      if (i.data.projects) {
        const element = i.data.projects;
        if (element.type === 'created') {
          if (element.data.newData.S) {
            let arrNew: any[] = [];
            let projects = this.projects.find(
              (project) => project.id === element.data.newData.S
            );
            if (projects) {
              arrNew.push(projects.name);
            } else {
              const PROJECT = await this.getProject(element.data.newData.S);
              arrNew.push(PROJECT.nameProject);
              this.projects.push({
                id: PROJECT.idProject,
                name: PROJECT.nameProject,
              });
            }
            element.data.newData.S = arrNew.join(', ');
          }
        }

        if (element.S) {
          const splitNew = element.S.data.newData.split(/[,\s]+/);
          let arrNew: string[] = [];
          if (splitNew) {
            for await (const i of splitNew) {
              const projects = this.projects.find(
                (project) => project.id === i
              );
              if (projects) {
                arrNew.push(projects.name);
              } else {
                const PROJECT = await this.getProject(i);
                arrNew.push(PROJECT.nameProject);
                this.projects.push({
                  id: PROJECT.idProject,
                  name: PROJECT.nameProject,
                });
              }
            }
            element.S.data.newData = arrNew.join(', ');
          }

          const splitOld = element.S.data.oldData.split(/[,\s]+/);
          let arrOld: any[] = [];

          if (splitOld) {
            for await (const i of splitOld) {
              const projects = this.projects.find(
                (project) => project.id === i
              );
              if (projects) {
                arrOld.push(projects.name);
              } else {
                const PROJECT = await this.getProject(i);
                arrOld.push(PROJECT.nameProject);
                this.projects.push({
                  id: PROJECT.idProject,
                  name: PROJECT.nameProject,
                });
              }
            }
            element.S.data.oldData = arrOld.join(', ');
          }
        }
      }
      if (i.data.symptomsInstances) {
        const element = i.data.symptomsInstances;
        if (element.L) {
          for await (const i of element.L) {
            if (i.data.S) {
              let instance: any;
              let symptom = '';
              if (i.data.S === Symptom.FEVER) {
                symptom = Symptom.FEVER;
              }
              if (!symptom) {
                instance = await this.getSymptomById(i.data.S);
                symptom = instance?.nameSymp || '';
              }
              if (!symptom) {
                instance = await this.getConfFormConfSymptom(i.data.S);
              }
              if (!instance && !symptom) {
                instance = await this.getConfSymptom(i.data.S);
              }
              if (instance?.labelSymp || instance?.nameSymp || symptom) {
                if (instance?.labelSymp) {
                  i.data.S = this.trialpalService.dictionaryPipe.transform(
                    instance.labelSymp
                  );
                } else {
                  i.data.S = this.trialpalService.translateService.instant(
                    'symptom.enums.symptoms.' + (instance?.nameSymp || symptom)
                  );
                }
              }
              if (i?.data?.S?.includes('symptom.enums.symptoms')) {
                i.data.S = instance?.nameSymp || symptom;
              }
            }
          }
        }
      }

      if (i.data.symptom) {
        const element = i.data.symptom;
        if (element?.S?.data) {
          let SYMPTOM: any;
          if (element.S.data.newData) {
            const newData = element.S.data.newData;
            SYMPTOM = this.symptoms.find((sym: any) => newData === sym.value);
            if (SYMPTOM) {
              element.S.data.newData = this.translateService.instant(
                'symptom.enums.symptoms.' + SYMPTOM.value
              );
            }
          }
          if (element.S.data.oldData) {
            const oldData = element.S.data.oldData;
            SYMPTOM = this.symptoms.find((sym: any) => oldData === sym.value);
            if (SYMPTOM) {
              element.S.data.oldData = this.translateService.instant(
                'symptom.enums.symptoms.' + SYMPTOM.value
              );
            }
          }
        }
      }
      if (i.data.hospitalAdmission) {
        const element = i.data.hospitalAdmission;
        if (element?.S?.data) {
          let HOSPITAL: any;
          if (element.S.data.newData) {
            const newData = element.S.data.newData;
            HOSPITAL = this.admisionHospital.find(
              (sym: any) => newData === sym.value
            );
            if (HOSPITAL) {
              element.S.data.newData = this.translateService.instant(
                'admissionHospital.' + HOSPITAL.value
              );
            }
          }
          if (element.S.data.oldData) {
            const oldData = element.S.data.oldData;
            HOSPITAL = this.admisionHospital.find(
              (sym: any) => oldData === sym.value
            );
            if (HOSPITAL) {
              element.S.data.oldData = this.translateService.instant(
                'admissionHospital.' + HOSPITAL.value
              );
            }
          }
        }
      }
      if (i.data.professionalHealthCare) {
        const element = i.data.professionalHealthCare;
        if (element?.S?.data) {
          let PROFESSIONAL: any;
          if (element.S.data.newData) {
            const newData = element.S.data.newData;
            PROFESSIONAL = this.professionalHealthCareTypes.find(
              (sym: any) => newData === sym.value
            );
            if (PROFESSIONAL) {
              element.S.data.newData = this.translateService.instant(
                'visitProfessional.' + PROFESSIONAL.value
              );
            }
          }
          if (element.S.data.oldData) {
            const oldData = element.S.data.oldData;
            PROFESSIONAL = this.professionalHealthCareTypes.find(
              (sym: any) => oldData === sym.value
            );
            if (PROFESSIONAL) {
              element.S.data.oldData = this.translateService.instant(
                'visitProfessional.' + PROFESSIONAL.value
              );
            }
          }
        }
      }
    }
    return data;
  }

  async splitSites(siteData: any[]) {
    const sitesArray: any[] = [];
    for await (const i of siteData) {
      const sites = this.sites.find((site) => site.id === i);
      if (sites) {
        sitesArray.push(sites.name);
      } else {
        const SITE = await this.getSite(i);
        sitesArray.push(SITE.nameSite);
        this.sites.push({
          id: SITE.idSite,
          name: SITE.nameSite,
        });
      }
    }
    return sitesArray;
  }
  async getSymptomById(id: string) {
    let idSym: string = '';
    let nameSymp: string = '';
    await this.subjectService
      .symptomInstanceById(id)
      .then((symptom: any) => {
        if (symptom) {
          idSym = symptom.id;
          nameSymp =
            symptom.symptom === 'OTHER'
              ? symptom.whichOtherSymptom
              : symptom.symptom;
        }
      })
      .catch((error) => {
        logger.error('getSubject error', error);
        this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
      })
      .finally();
    return { idSym, nameSymp };
  }

  private async getSubject(subjectId: string) {
    let subjectNum: string = '';
    let idSubject: string = '';
    await this.subjectService
      .getSubject(subjectId)
      .then((subject: any) => {
        idSubject = subject?.id;
        subjectNum = subject?.subjectNumber;
      })
      .catch((error) => {
        logger.error('getSubject error', error);
        this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
      })
      .finally();
    return { idSubject, subjectNum };
  }

  private async getSite(id: string) {
    try {
      const site = await this.siteService.getSite(id);
      const idSite = site.id ?? '';
      const nameSite = site.name ?? '';
      return { idSite, nameSite };
    } catch (error) {
      logger.error('getAuditInfo error', error);
      this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
    }
    return { idSite: '', nameSite: '' };
  }

  private async getProject(project: string) {
    let idProject: string = '';
    let nameProject: string = '';
    await this.projectService
      .getProject(project)
      .then(({ id, name }) => {
        idProject = id;
        nameProject = name;
      })
      .catch((error) => {
        logger.error('getAuditInfo error', error);
        this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
      })
      .finally();
    return { idProject, nameProject };
  }
  private async getConfSymptom(symtompId: string) {
    let idSym: string = '';
    let nameSymp: string = '';
    await this.projectService
      .getSymtom(symtompId)
      .then((sym: any) => {
        if (sym) {
          idSym = sym.id;
          nameSymp = sym.symptom;
        }
      })
      .catch((error) => {
        logger.error('getAuditInfo error', error);
        this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
      })
      .finally();
    return { idSym, nameSymp };
  }

  private async getConfFormConfSymptom(id: string) {
    let idSym: string = '';
    let nameSymp: string = '';
    let labelSymp: string = '';
    await this.projectService
      .getConfSymptom(id)
      .then((sym: any) => {
        if (sym) {
          idSym = sym.id;
          nameSymp = sym.symptom;
          labelSymp = sym.symptomLabel;
        }
      })
      .catch((error) => {
        logger.error('getAuditInfo error', error);
        this.tp2Service.showServiceError('audit.actions.getAuditInfo', error);
      });
    return { idSym, nameSymp, labelSymp };
  }

  private async buildCHistoryList(
    items: any[],
    multiEntity: boolean = false
  ): Promise<void> {
    this.data = [];

    //Se ignorara los estados si no es de un proyecto o sujeto
    if (
      this.entity !== TP2Entites.PROJECT &&
      this.entity !== TP2Entites.SUBJECT &&
      Object.keys(TP2Entites).includes(this.entity ?? '')
    ) {
      this.dataIgnore.push('state');
    } else {
      const index = this.dataIgnore.findIndex((x: string) => x === 'state');
      this.dataIgnore.splice(index, 1);
    }

    for (const registro of items) {
      this.multiEntity && (this.entity = registro?.entity);
      for (const atr in registro.data) {
        if (!this.dataIgnore.includes(atr)) {
          const record = registro.data[atr];
          await this.processRecord(record, registro, atr);
        }
      }
    }

    this.data.sort((a, b) => {
      return b.time - a.time;
    });
  }
  private async processRecord(record: any, registro: any, atr: string) {
    if (record) {
      if (record.type && record.type === 'created') {
        await this.processCreateInput(registro, atr);
      } else if (record.type && record.type === 'deleted') {
        await this.processDeleteInput(registro, atr);
      } else if (!record.type && record.S) {
        await this.processString(registro, atr);
      } else if (!record.type && record.N) {
        await this.processNumber(registro, atr);
      } else if (
        !record.type &&
        record.BOOL !== null &&
        record.BOOL !== undefined
      ) {
        await this.processBoolean(registro, atr);
      } else if (record.L) {
        await this.processArray(registro, atr, record.L);
      } else if (record.M) {
        await this.processMap(registro, atr, record.M);
      }
    }
  }

  private async processDeleteInput(registro: any, atr: string) {
    const record = registro.data[atr];
    let valorAnterior = '';
    const val = record.data.oldData;
    if (val.S) {
      valorAnterior = val.S;
    } else if (val.N) {
      valorAnterior = val.N + '';
    } else if (val.BOOL !== null && val.BOOL !== undefined) {
      valorAnterior = this.getBooleanTranslatedValue(val.BOOL);
    } else if (val.L) {
      for (let currentVal of val.L) {
        if (currentVal.S) valorAnterior += currentVal.S;
      }
    }
    if (valorAnterior !== '') {
      const dataVO: AuditInfoVO = {
        entityType: registro?.entityType ?? '',
        entityId: registro.relatedEntityId ?? '',
        entityName: registro?.entityName ?? '',
        date: this.transformDatePipe.transformWithHour(registro.eventDateTime),
        lastUser: await this.getAuditLastUser(registro._lastUser),
        variable:
          registro.data?.useVariableTranslation ??
          this.getVariableTranslation(atr),
        oldValue: this.getValueTranslation(valorAnterior, atr),
        newValue: '---',
        time: new Date(registro.eventDateTime).getTime(),
        reason: this.getChangeReazonValue(registro._changeReason),
      };

      if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
        this.data.push(dataVO);
      }
    }
  }
  private async processCreateInput(registro: any, atr: string) {
    const record = registro.data[atr];
    let valorNuevo = '';
    const val = record.data.newData;
    if (val.S) {
      valorNuevo = val.S;
    } else if (val.N) {
      valorNuevo = val.N + '';
    } else if (val.BOOL !== null && val.BOOL !== undefined) {
      valorNuevo = this.getBooleanTranslatedValue(val);
    } else if (val.L) {
      for (let currentVal of val.L) {
        if (currentVal.S) valorNuevo += currentVal.S;
      }
    }
    const dataVO: AuditInfoVO = {
      entityType: registro?.entityType ?? '',
      entityId: registro.relatedEntityId ?? '',
      entityName: registro?.entityName ?? '',
      date: this.transformDatePipe.transformWithHour(registro.eventDateTime),
      lastUser: await this.getAuditLastUser(registro.v || registro._lastUser),
      variable:
        registro.data?.useVariableTranslation ??
        this.getVariableTranslation(atr),
      oldValue: '---',
      newValue: this.getValueTranslation(valorNuevo, atr),
      time: new Date(registro.eventDateTime).getTime(),
      reason: this.getChangeReazonValue(registro._changeReason),
    };

    if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
      this.data.push(dataVO);
    }
  }
  private getBooleanTranslatedValue(val: any) {
    const yes = this.translateService.instant('general.yes');
    const no = this.translateService.instant('general.no');
    return val ? yes : no;
  }

  private async processString(registro: any, atr: string): Promise<void> {
    const element = registro.data[atr].S;
    let valorNuevo = '';
    let valorAnterior = '';
    const action = element.type;
    if (
      action &&
      action === 'deleted' &&
      (element.data.S || element.data.newData)
    ) {
      valorAnterior += element.data.S ?? element.data.newData;
    } else if (
      action &&
      action === 'created' &&
      (element.data.S || element.data.newData)
    ) {
      if (element.data.S) {
        valorNuevo += element.data.S;
      } else if (element.data.newData) {
        valorNuevo += element.data.newData;
      }
    } else if (action && action === 'updated') {
      valorNuevo = element.data?.newData;
      valorAnterior = element.data?.oldData;
    }

    const dataVO: AuditInfoVO = {
      entityType: registro?.entityType ?? '',
      entityId: registro.relatedEntityId ?? '',
      entityName: registro?.entityName ?? '',
      date: this.transformDatePipe.transformWithHour(registro.eventDateTime),
      lastUser: await this.getAuditLastUser(registro._lastUser),
      variable:
        registro.data?.useVariableTranslation ??
        this.getVariableTranslation(atr),
      oldValue: this.getValueTranslation(valorAnterior, atr),
      newValue: this.getValueTranslation(valorNuevo, atr),
      time: new Date(registro.eventDateTime).getTime(),
      reason: this.getChangeReazonValue(registro._changeReason),
    };

    if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
      this.data.push(dataVO);
    }
  }

  private async processNumber(registro: any, atr: string): Promise<void> {
    const element = registro.data[atr].N;
    let valorNuevo = '';
    let valorAnterior = '';
    const action = element.type;
    if (
      action &&
      action === 'deleted' &&
      (element.data.N || element.data.newData?.N || element.data.newData)
    ) {
      valorAnterior +=
        element.data.N ?? element.data.newData.N ?? element.data.newData;
    } else if (
      action &&
      action === 'created' &&
      (element.data.N || element.data.newData.N || element.data.newData)
    ) {
      valorNuevo +=
        element.data.N ?? element.data.newData.N ?? element.data.newData;
    } else if (action && action === 'updated') {
      valorNuevo = element.data?.newData;
      valorAnterior = element.data?.oldData;
    }
    const dataVO: AuditInfoVO = {
      entityType: registro?.entityType ?? '',
      entityId: registro.relatedEntityId ?? '',
      entityName: registro?.entityName ?? '',
      date: this.transformDatePipe.transformWithHour(registro.eventDateTime),
      lastUser: await this.getAuditLastUser(registro._lastUser),
      variable:
        registro.data?.useVariableTranslation ??
        this.getVariableTranslation(atr),
      oldValue: this.getValueTranslation(valorAnterior, atr),
      newValue: this.getValueTranslation(valorNuevo, atr),
      time: new Date(registro.eventDateTime).getTime(),
      reason: this.getChangeReazonValue(registro._changeReason),
    };

    if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
      this.data.push(dataVO);
    }
  }

  private async processBoolean(registro: any, atr: string): Promise<void> {
    const element = registro.data[atr].BOOL;
    let valorNuevo = '';
    let valorAnterior = '';
    const action = element.type;
    if (action && action === 'deleted' && element.data.BOOL) {
      valorAnterior += element.data.BOOL;
    } else if (action && action === 'created' && element.data.BOOL) {
      valorNuevo += element.data.BOOL;
    } else if (action && action === 'updated') {
      valorNuevo = this.getBooleanTranslatedValue(element.data.newData);
      valorAnterior = this.getBooleanTranslatedValue(element.data.oldData);
    }
    const dataVO: AuditInfoVO = {
      entityType: registro?.entityType ?? '',
      entityId: registro.relatedEntityId ?? '',
      entityName: registro?.entityName ?? '',
      date: this.transformDatePipe.transformWithHour(registro.eventDateTime),
      lastUser: await this.getAuditLastUser(registro._lastUser),
      variable:
        registro.data?.useVariableTranslation ??
        this.getVariableTranslation(atr),
      oldValue: this.getValueTranslation(valorAnterior, atr),
      newValue: this.getValueTranslation(valorNuevo, atr),
      time: new Date(registro.eventDateTime).getTime(),
      reason: this.getChangeReazonValue(registro._changeReason),
    };

    if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
      this.data.push(dataVO);
    }
  }

  private async processArray(
    registro: any,
    atr: string,
    arr: any[]
  ): Promise<void> {
    let newValue = '';
    let previousValue = '';
    for (const element of arr) {
      const action = element?.type;
      if (action === 'deleted' && element.data.S) {
        previousValue += element.data.S + ', ';
      } else if (action === 'deleted' && element.data.N) {
        previousValue += element.data.N + ', ';
      } else if (action === 'created' && element.data.S) {
        newValue += element.data.S + ', ';
      } else if (action === 'created' && element.data.N) {
        newValue += element.data.N + ', ';
      } else if (action === 'unchanged' && element?.data?.S) {
        // validar si es tipo S
        if (element.data.S) {
          newValue += element.data.S + ', ';
          previousValue += element.data.S + ', ';
        } else {
          // recorrer el objeto para obtener el new y el old
          for (const [value] of Object.entries(element)) {
            const elementList: any = value;
            if (elementList.S?.type === 'updated') {
              newValue += elementList.S.data.newData + ', ';
              previousValue += elementList.S.data.oldData + ', ';
            }
          }
        }
      } else if (action === 'unchanged' && element?.data?.N) {
        // validar si es tipo S
        if (element.data.N) {
          newValue += element.data.N + ', ';
          previousValue += element.data.N + ', ';
        } else {
          // recorrer el objeto para obtener el new y el old
          for (const [value] of Object.entries(element)) {
            const elementList: any = value;
            if (elementList.N?.type === 'updated') {
              newValue += elementList.N.data.newData + ', ';
              previousValue += elementList.N.data.oldData + ', ';
            }
          }
        }
      } else if (action && action === 'updated') {
        for (let key in element) {
          if (typeof element[key] === 'object') {
            for (let obj in element[key]) {
              if (element[key][obj]) {
                const newRecord = { ...registro };
                newRecord.data = element;
                //Validacion para preguntas adicionales en sintomas
                if (
                  'question' in newRecord.data &&
                  'answer' in newRecord.data
                ) {
                  newRecord.data.useVariableTranslation =
                    //primero pasará por el pipe
                    this.trialpalService.dictionaryPipe.transform(
                      newRecord.data?.question.M?.question?.S?.data?.newData
                    ) ?? null;
                }
                this.processRecord(
                  { [obj]: element[key][obj] },
                  newRecord,
                  key
                );
              }
            }
          }
        }
      }
    }
    if (previousValue.indexOf(',') !== -1) {
      previousValue = previousValue.substring(0, previousValue.length - 1);
    }
    if (newValue.indexOf(',') !== -1) {
      newValue = newValue.substring(0, newValue.length - 1);
    }
    const dataVO: AuditInfoVO = {
      entityType: registro?.entityType ?? '',
      entityId: registro.relatedEntityId ?? '',
      entityName: registro?.entityName ?? '',
      date: this.transformDatePipe.transformWithHour(registro.eventDateTime),
      lastUser: await this.getAuditLastUser(registro._lastUser),
      variable:
        registro.data?.useVariableTranslation ??
        this.getVariableTranslation(atr),
      oldValue: this.getValueTranslation(previousValue, atr),
      newValue: this.getValueTranslation(newValue, atr),
      time: new Date(registro.eventDateTime).getTime(),
      reason: this.getChangeReazonValue(registro._changeReason),
    };

    if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
      this.data.push(dataVO);
    }
  }

  private async processMap(
    registro: any,
    atr: string,
    map: any
  ): Promise<void> {
    for (const [key, value] of Object.entries(map)) {
      let previousValue = '';
      let newValue = '';
      const element: any = value;
      if (element.S?.type === 'deleted' && element.data.S) {
        previousValue += element.data.S;
      } else if (element.S?.type === 'created' && element.data.S) {
        newValue += element.data.S;
      } else if (element.S?.type === 'updated') {
        newValue += element.S.data.newData;
        previousValue += element.S.data.oldData;
      } else if (element.N?.type === 'deleted' && element.data.N) {
        previousValue += element.data.N + '';
      } else if (element.N?.type === 'created' && element.data.N) {
        newValue += element.data.N + '';
      } else if (element.N?.type === 'updated') {
        newValue += element.N.data.newData + '';
        previousValue += element.N.data.oldData + '';
      } else if (element.BOOL?.type === 'deleted' && element.data.BOOL) {
        previousValue += this.getBooleanTranslatedValue(element.data.BOOL);
      } else if (element.BOOL?.type === 'created' && element.data.BOOL) {
        newValue += this.getBooleanTranslatedValue(element.data.BOOL);
      } else if (element.BOOL?.type === 'updated') {
        newValue += this.getBooleanTranslatedValue(element.BOOL.data.newData);
        previousValue += this.getBooleanTranslatedValue(
          element.BOOL.data.oldData
        );
      } else if (element.L) {
        // Aun no se implementa este nivel de auditoría.
      }
      if (
        element.S?.type !== 'unchanged' &&
        element.N?.type !== 'unchanged' &&
        element.BOOL?.type !== 'unchanged'
      ) {
        const dataVO: AuditInfoVO = {
          entityType: registro?.entityType ?? '',
          entityId: registro.relatedEntityId ?? '',
          entityName: registro?.entityName ?? '',
          date: this.transformDatePipe.transformWithHour(
            registro.eventDateTime
          ),
          lastUser: await this.getAuditLastUser(registro._lastUser),
          variable: this.getVariableTranslation(atr + '.' + key),
          oldValue: this.getValueTranslation(previousValue, atr + '.' + key),
          newValue: this.getValueTranslation(newValue, atr + '.' + key),
          time: new Date(registro.eventDateTime).getTime(),
          reason: this.getChangeReazonValue(registro._changeReason),
        };

        if (this.verifyOldValueNewValue(dataVO.oldValue, dataVO.newValue)) {
          this.data.push(dataVO);
        }
      }
    }
  }

  verifyOldValueNewValue(oldValue: any, newValue: any) {
    if (!oldValue || !newValue) {
      return false;
    }
    oldValue = oldValue.toString();
    newValue = newValue.toString();
    if (oldValue.includes('undefined') || newValue.includes('undefined')) {
      return false;
    }
    if (oldValue.includes('null') || newValue.includes('null')) {
      return false;
    }
    return !(oldValue === '---' && newValue === '---');
  }

  private getVariableTranslation(atr: string): string {
    let obj = this.getAuditVO(atr);
    let key = atr;
    if (obj) {
      key = this.translateService.instant(obj.translateKey);
    }
    return key;
  }
  private getValueTranslation(value: string, atr: string) {
    let obj = this.getAuditVO(atr);
    let key = value;
    if (obj?.isArray && !obj?.isDictionary) {
      return this.getTranslateArrayByTranslate(obj.enumKey, value.split(','));
    } else if (obj?.isArray && obj?.isDictionary) {
      return this.getTranslateArrayByDictionary(value.split(','));
    } else if (obj?.enumKey) {
      let itemValue = '';
      for (let item of value.split(/\s*,\s*/)) {
        if (item) {
          const completeKey = obj.enumKey + item;
          itemValue += this.translateService.instant(completeKey) + ', ';
        }
      }
      key = itemValue;
    } else if (obj?.ignore) {
      key = 'N/A';
    } else if (obj?.isDate && key.length >= 10) {
      key = this.transformDatePipe.transformWithHour(new Date(key));
    }
    if (!key) {
      key = '---';
    }

    if (key.trim().endsWith(',')) {
      key = key.trim().slice(0, -1);
    }
    //Cuando se usa una llave que no se encuentra en los assets de traducción pasará por el pipe
    return this.trialpalService.dictionaryPipe.transform(key);
  }

  //Se encarga de traducir los arreglos cuya traducir se creo desde base de datos
  getTranslateArrayByDictionary(values: string[] = []) {
    return values
      .filter((key) => Boolean(key))
      .map((key: string) =>
        this.trialpalService.dictionaryPipe.transform(key.trim())
      )
      .join(', ');
  }

  //Se encarga de traducir arreglos cuya traduccion se encuentre en i18n
  getTranslateArrayByTranslate(key: string = '', values: string[] = []) {
    const valuesFilter = values.filter((value) => Boolean(value));
    if (!valuesFilter.length) return '---';
    return valuesFilter
      .map((value: string) =>
        this.trialpalService.translateService.instant(key + value.trim())
      )
      .join(', ');
  }

  private getAuditVO(atr: string) {
    let obj = undefined;
    if (this.entity === TP2Entites.PROJECT) {
      obj = TP2Variables.PROJECT_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.SUBJECT) {
      obj = TP2Variables.SUBJECT_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.USER) {
      obj = TP2Variables.USER_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.SITES) {
      obj = TP2Variables.SITE_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.MEDICALATTENTIONINSTANCE) {
      obj = TP2Variables.MEDICALATTENTIONINSTANCE_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.SYMPTOMINSTANCE) {
      obj = TP2Variables.SYMPTOMINSTANCE_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.MEDICATIONINSTANCE) {
      obj = TP2Variables.MEDICATIONINSTANCE_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.TEMPERATURERECORDLOG) {
      obj = TP2Variables.TEMPERATURERECORDLOG_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFEDIARY) {
      obj = TP2Variables.CONF_EDIARY.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFSYMPTOM) {
      obj = TP2Variables.CONF_SYMPTOM.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFREPORT) {
      obj = TP2Variables.CONF_REPORT.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFFORM) {
      obj = TP2Variables.CONF_FORM.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.VERIFIEDTEMPERATURERECORDLOG) {
      obj = TP2Variables.VERIFIEDTEMPERATURERECORDLOG_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.VERIFIEDOTHERSYMPTOMINSTANCE) {
      obj = TP2Variables.VERIFIED_SYMPTOMINSTANCE_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.VERIFIEDMEDICATIONINSTANCE) {
      obj = TP2Variables.VERIFIED_MEDICATIONINSTANCE_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.VERIFIEDMEDICALATTENTIONINSTANCE) {
      obj = TP2Variables.VERIFIEDMEDICALATTENTIONINSTANCE_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.COMMENTCONCILIATIONINSTANCE) {
      obj = TP2Variables.COMMENTCONCILIATIONINSTANCE_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.CONFINFORMEDCONSENT) {
      obj = TP2Variables.CONF_INFORMED_CONSENT_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFICSECTION) {
      obj = TP2Variables.CONF_IC_SECTION_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFICQUESTION) {
      obj = TP2Variables.CONF_IC_QUESTION_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONFICANSWER) {
      obj = TP2Variables.CONF_IC_ANSWER_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.INFORMEDCONSENTINSTANCE) {
      obj = TP2Variables.INFORMED_CONSENT_INSTANCE_VARS.find(
        (x) => x.name === atr
      );
    } else if (this.entity === TP2Entites.ICSECTIONINSTANCE) {
      obj = TP2Variables.IC_SECTION_INSTANCE_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.ICQUESTIONINSTANCE) {
      obj = TP2Variables.IC_QUESTION_INSTANCE_VARS.find((x) => x.name === atr);
    } else if (this.entity === TP2Entites.CONSENTEDUSER) {
      obj = TP2Variables.CONSENTED_USER_VARS.find((x) => x.name === atr);
    }
    return obj;
  }
  private getChangeReazonValue(reason: any): string {
    if (
      reason === 'typingError' ||
      reason === 'configurationUpdate' ||
      reason === 'systemDefault'
    ) {
      return this.translateService.instant('general.changeReasons.' + reason);
    }
    return reason;
  }
  clear(table: Table, input: HTMLInputElement) {
    input.value = '';
    table.clear();
    table.filterGlobal('', 'contains');
  }

  async getAuditLastUser(login: string): Promise<string> {
    let user = this.auditUsers.find((user) => user.login === login);

    if (!user) {
      user = await this.userService.getUserByLogin(login);
      this.auditUsers.push(user);
    }

    return user.role === Roles.Subject ? user.id : login;
  }
}

export type AuditInfoVO = {
  date: string;
  lastUser: string;
  variable: string;
  newValue: string;
  oldValue: string;
  time: number;
  reason: string;
  entityType?: string;
  entityId?: string;
  entityName?: string;
};
interface DataArray {
  id: string;
  name: string;
}
