<div class="save-buttons" style="padding: 0">
  <div class="p-grid p-justify-between p-align-center p-mb-3">
    <div class="p-col-6 p-pl-0">
      <button
        id="btn_cancel"
        name="btn_cancel"
        iconPos="left"
        style="width: 100%"
        icon="pi pi-chevron-left"
        pButton
        type="button"
        label="{{ 'conciliation.buttonPrevious' | translate }}"
        [disabled]="isBackDisabled"
        (click)="previousForm()"
      ></button>
    </div>
    <div class="p-col-6 p-pr-0">
      <button
        id="btn_nextItem"
        name="btn_nextItem"
        style="width: 100%"
        iconPos="right"
        icon="pi pi-chevron-right"
        pButton
        type="submit"
        label="{{ 'conciliation.buttonNext' | translate }}"
        [disabled]="isNextDisabled"
        (click)="nextForm('')"
      ></button>
    </div>
  </div>
</div>
