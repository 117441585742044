<div class="form-dictionary">
  <form
    name="form"
    autocomplete="off"
    (ngSubmit)="saveItem(form)"
    #form="ngForm"
  >
    <!-- Para campos de tipo texto-->
    <div class="p-fluid p-grid" *ngIf="!isHtml">
      <div class="p-field p-col-12">
        <label id="lbl_key" name="lbl_key" for="txt_key">{{
          'dictionary.key' | translate
        }}</label>
        <input
          type="text"
          pInputText
          id="txt_key"
          name="txt_key"
          #txt_key="ngModel"
          [(ngModel)]="key"
          required
          placeholder="{{ 'dictionary.placeholderKey' | translate }}"
          (input)="onSearchChange($event)"
          (ngModelChange)="key = $event.toUpperCase()"
        />
        <small
          class="form-error"
          *ngIf="txt_key.invalid && (form.submitted || txt_key.touched)"
          >{{ 'general.required' | translate }}</small
        >
        <small class="form-error" *ngIf="isExistKey && !txt_key.invalid">{{
          'dictionary.errorCreatedKey' | translate
        }}</small>
      </div>
      <div class="p-field p-col-12">
        <label id="lbl_spanish" name="lbl_spanish" for="description">{{
          'dictionary.titleSpanish' | translate
        }}</label>
        <textarea
          id="txt_spanish"
          name="txt_spanish"
          #txt_spanish="ngModel"
          [(ngModel)]="spanish"
          pInputTextarea
          required
          placeholder="{{ 'dictionary.placeholderSpanish' | translate }}"
          [autoResize]="true"
        ></textarea>
        <small
          class="form-error"
          *ngIf="txt_spanish.invalid && (form.submitted || txt_spanish.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-field p-col-12">
        <label id="lbl_english" name="lbl_english" for="description2">{{
          'dictionary.titleEnglish' | translate
        }}</label>
        <textarea
          id="txt_english"
          name="txt_english"
          #txt_english="ngModel"
          [(ngModel)]="english"
          pInputTextarea
          required
          placeholder="{{ 'dictionary.placeholderEnglish' | translate }}"
          [autoResize]="true"
        ></textarea>
        <small
          class="form-error"
          *ngIf="txt_english.invalid && (form.submitted || txt_english.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <!---- Para campos de tipo HTML--->
    <div class="p-fluid p-grid" *ngIf="isHtml">
      <div class="p-field p-col-12">
        <label id="lbl_key" name="lbl_key" for="txt_key">{{
          'dictionary.key' | translate
        }}</label>
        <input
          type="text"
          pInputText
          id="txt_key"
          name="txt_key"
          #txt_key="ngModel"
          [(ngModel)]="key"
          required
          placeholder="{{ 'dictionary.placeholderKey' | translate }}"
          (input)="onSearchChange($event)"
          (ngModelChange)="key = $event.toUpperCase()"
        />
        <small
          class="form-error"
          *ngIf="txt_key.invalid && (form.submitted || txt_key.touched)"
          >{{ 'general.required' | translate }}</small
        >
        <small class="form-error" *ngIf="isExistKey && !txt_key.invalid">{{
          'dictionary.errorCreatedKey' | translate
        }}</small>
      </div>
      <div class="p-field p-col-12">
        <label id="lbl_spanish" name="lbl_spanish" for="description">{{
          'dictionary.titleSpanish' | translate
        }}</label>
        <p-editor
          id="txt_spanish"
          name="txt_spanish"
          #txt_spanish="ngModel"
          [required]="true"
          [(ngModel)]="spanish"
          [style]="{ height: '200px' }"
        ></p-editor>
        <small
          class="form-error"
          *ngIf="txt_spanish.invalid && (form.submitted || txt_spanish.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-field p-col-12">
        <label id="lbl_english" name="lbl_english" for="description2">{{
          'dictionary.titleEnglish' | translate
        }}</label>
        <p-editor
          [required]="true"
          id="txt_english"
          name="txt_english"
          #txt_english="ngModel"
          [(ngModel)]="english"
          [style]="{ height: '200px' }"
        ></p-editor>
        <small
          class="form-error"
          *ngIf="txt_english.invalid && (form.submitted || txt_english.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <!--Botones-->
    <div style="display: flex; justify-content: end">
      <button
        class="p-mx-2"
        id="btn_cancel"
        name="btn_cancel"
        pButton
        type="button"
        label="{{ 'general.cancel' | translate }}"
        (click)="cancel()"
      ></button>

      <button
        id="btn_save"
        name="btn_save"
        type="submit"
        pButton
        label="{{ 'general.save' | translate }}"
      ></button>
    </div>
  </form>
</div>
