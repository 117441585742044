import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  ReportExecutionService,
  ReportPagination,
} from './services/report-executation.service';
import { ReportInstance } from '../../../subjects.types';
import { InstanceState } from 'src/app/services/trialpal.types';
import { ConfReport } from 'src/app/modules/report/report.types';

@Component({
  selector: 'app-report-execution',
  templateUrl: './report-execution.component.html',
  styleUrls: ['./report-execution.component.scss'],
})
export class ReportExecutionComponent implements OnInit {
  @Input() confReport: ConfReport = { id: '' } as ConfReport;
  @Input() reportInstance: ReportInstance = { id: '' } as ReportInstance;
  @Input() subjectId: string = '';
  @Input() siteId: string = '';
  pagination = {
    SYMPTOM: ReportPagination.SYMPTOM,
    FORM: ReportPagination.FORM,
    REPORT_SUCCESS: ReportPagination.REPORT_SUCCESS,
  };

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public reportExecutionService: ReportExecutionService
  ) {
    this.confReport = this.dialogConfig.data?.confReport;
    this.subjectId = this.dialogConfig.data?.subjectId;
    this.siteId = this.dialogConfig.data?.siteId;
    this.reportInstance = this.dialogConfig.data?.reportInstance;
    this.reportExecutionService.clearVariables();
  }

  async ngOnInit() {
    this.reportInitialization();
  }

  reportInitialization(): void {
    this.reportExecutionService.confReport = this.confReport;
    this.reportExecutionService.subjectId = this.subjectId;

    if (this.reportExecutionService.confReport) {
      this.reportExecutionService.buildPagination();
      this.reportExecutionService.setBooleanOptions();

      //Construye un reporte desde cero, si no existe
      this.buildReportInstance();
    }
  }
  buildReportInstance() {
    let newReportInstance: any = {
      confReportId: this.confReport.id,
      state: InstanceState.PENDING,
      plannedDate: this.reportExecutionService.getCurrentDateWitHours(),
      subjectId: this.subjectId,
      id: `${new Date().getTime()}${this.confReport.id}`, //Agrega un id temporal
      siteId: this.siteId,
      _lastUser: this.reportExecutionService.getUsername(),
      isAlertChecked: false,
      isPDFGenerated: false,
      reportConfVersion: this.confReport._version,
      symptomOcurrencies: null,
      _changeReason: '',
    };
    if (this.reportInstance) {
      newReportInstance = this.reportInstance;
    }

    this.reportExecutionService.reportInstance = newReportInstance;
    this.reportExecutionService.isReportInstanceEdition =
      this.isReportInstanceEdition();
  }

  //Si el reporte tiene el id de la configuración es un reporte que aun no se ha creado en base de datos
  isReportInstanceEdition(): boolean {
    const { id, state } = this.reportExecutionService.reportInstance;
    if (
      state === InstanceState.COMPLETED &&
      !id?.includes(this.confReport.id)
    ) {
      return true;
    }

    return false;
  }
}
