<div class="container">
  <div class="header p-mb-1 p-pb-2">
    <h4 class="p-my-2">{{ userData.user.login }}</h4>
    <div id="user-state">
      <p class="p-my-2">
        {{ 'informedConsent.followUp.table.rowDetail.state' | translate }}
      </p>
      <span [ngClass]="userData.state">{{
        'informedConsent.enums.statesSingular.' + userData.state
          | translate
          | uppercase
      }}</span>
    </div>
  </div>
  <div class="body">
    <div>
      <label for="email">{{
        'informedConsent.followUp.table.rowDetail.email' | translate
      }}</label>
      <p id="email">{{ userData.user.email }}</p>
    </div>
    <div>
      <label for="phone">{{
        'informedConsent.followUp.table.rowDetail.phone' | translate
      }}</label>
      <p id="phone">{{ userData.user.phoneNumber }}</p>
    </div>
    <div>
      <label for="relationship">{{
        'informedConsent.followUp.table.rowDetail.relationship' | translate
      }}</label>
      <p id="relationship">
        {{ 'informedConsent.witnessesTypes.' + userData.kinship | translate }}
      </p>
    </div>
  </div>
  <div class="footer">
    <button
      pButton
      [disabled]="userData.state !== 'REVIEWED'"
      (click)="displayDialogEnableSign = true; trackEnableSignatureCTA()"
      type="button"
      class="p-button-outlined signatures-btn"
      [ngClass]="userData.state"
      label="{{
        'informedConsent.followUp.table.rowDetail.' +
          (userData.state === 'ASSIGNED' ||
          userData.state === 'IN_REVIEW' ||
          userData.state === 'REVIEWED'
            ? 'enableSignature'
            : 'signatureEnabled') | translate
      }}"
    ></button>
    <button
      [disabled]="
        userData.state === 'SIGNED_BY_PARTICIPANT' ||
        userData.state === 'SIGNED_BY_INVESTIGATOR'
      "
      pButton
      (click)="display = true; trackDeleteConsentedCTA()"
      type="button"
      class="p-button-text delete-btn"
      label="{{
        'informedConsent.followUp.table.rowDetail.deleteConsented' | translate
      }}"
    ></button>
  </div>
  <app-delete-confirmation
    [header]="
      'informedConsent.followUp.table.rowDetail.deleteConsentedQuestion'
        | translate
    "
    [description]="
      'informedConsent.followUp.table.rowDetail.deleteConsentedAction'
        | translate
    "
    [btnDeleteText]="'general.btnDelete' | translate"
    [(display)]="display"
    (confirm)="
      userActionEvent.emit({ action: deleteAction, consentedUser: userData });
      trackDeleteConsented()
    "
    position="fixed"
  >
  </app-delete-confirmation>
</div>

<p-dialog
  [header]="'informedConsent.followUp.enableSignaturesTitle' | translate"
  [(visible)]="displayDialogEnableSign"
  *ngIf="displayDialogEnableSign"
  [closable]="false"
  [style]="{ maxWidth: '374px' }"
  styleClass="enable-sign-modal"
  [resizable]="false"
>
  <div class="p-mb-5 p-text-center">
    <p
      class="p-py-0 p-mb-3 p-mt-0"
      style="font-size: 0.875rem; font-weight: 400"
    >
      {{ 'informedConsent.followUp.enableSignaturesDescription' | translate }}
    </p>
    <hr />
    <strong>{{ userData.user.login }}</strong>
    <hr />
    <div>
      <label for="email">{{
        'informedConsent.followUp.table.rowDetail.email' | translate
      }}</label>
      <p id="email">{{ userData.user.email }}</p>
    </div>
    <div>
      <label for="phone">{{
        'informedConsent.followUp.table.rowDetail.phone' | translate
      }}</label>
      <p id="phone">{{ userData.user.phoneNumber }}</p>
    </div>
    <div>
      <label for="relationship">{{
        'informedConsent.followUp.table.rowDetail.relationship' | translate
      }}</label>
      <p id="relationship">
        {{ 'informedConsent.witnessesTypes.' + userData.kinship | translate }}
      </p>
    </div>
  </div>

  <div class="buttons">
    <div class="p-d-flex p-justify-between p-p-0 p-gap-2">
      <div style="width: 100%">
        <p-button
          id="btn_enable-signature"
          [label]="'informedConsent.followUp.enableSignature' | translate"
          (click)="onEnableSignatures()"
          styleClass="p-button-primary full-width"
        ></p-button>
      </div>
      <div style="width: 100%">
        <p-button
          id="btn_cancel"
          class="cancelWindow"
          [label]="'informedConsent.followUp.closeModal' | translate"
          (click)="displayDialogEnableSign = false"
          styleClass="p-button-outlined full-width"
        ></p-button>
      </div>
    </div>
  </div>
</p-dialog>
