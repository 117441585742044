import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import { SymptomService } from '../../symptom/symptom.service';
import { MedicationService } from '../medication.service';

@Component({
  selector: 'app-summary-modal',
  templateUrl: './summary-modal.component.html',
})
export class SummaryModalComponent {
  original: any = {};
  verified: any = {};
  configuration: any = {};
  medication: any = {};
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private medicationService: MedicationService,
    public symptomService: SymptomService,
    public conciliationService: ConciliationService
  ) {
    this.original = this.config.data.original;
    this.verified = this.config.data.verified;
    this.configuration = this.config.data.configuration;
    this.medication = this.config.data.medication;
    this.conciliationService.transformSummaryDate(this.original);
    this.conciliationService.transformSummaryDate(this.verified);
    this.original.symptomsInstances = symptomService.getSymptoms(
      this.original?.symptomsInstances,
      this.medication
    );
    this.verified.symptomsInstances = symptomService.getSymptoms(
      this.verified?.symptomsInstances,
      this.medication
    );
  }

  get isReasonEnabled() {
    return (
      this.configuration?.mode ===
        this.medicationService.individualGivenToOpenReason ||
      this.configuration?.mode ===
        this.medicationService.generalGivenToOpenReason ||
      this.configuration?.mode === this.medicationService.noGivenToOpenReason
    );
  }

  get isGivenToEnabled() {
    return (
      this.configuration?.mode ===
        this.medicationService.individualGivenToSymptomBinding ||
      this.configuration?.mode ===
        this.medicationService.individualGivenToOpenReason
    );
  }

  get isSymptomsEnabled() {
    return (
      this.configuration?.mode ===
        this.medicationService.individualGivenToSymptomBinding ||
      this.configuration?.mode ===
        this.medicationService.generalGivenToSymptomBinding ||
      this.configuration?.mode ===
        this.medicationService.noGivenToSymptomBinding
    );
  }

  onClose(result: boolean) {
    this.ref.close(result);
  }
}
