import {
  ConfState,
  DataInputComponent,
  InstanceState,
  MediaType,
  ModelBooleanInput,
  ModelConfStateInput,
  ModelFloatInput,
  ModelIDInput,
  ModelIntInput,
  ModelStringInput,
  TP2FileInput,
  YN,
  YNA,
} from '../../services/trialpal.types';
import {
  ConciliationLockInput,
  ReconciliationConfigInput,
} from '../conciliation/conciliation.types';
import { Project, ProjectState } from '../project/project.types';
import {
  AlertRuleType,
  AlertType,
  ConfReport,
  PageLayout,
  ReportAvailableUserType,
  ReportProgramationType,
} from '../report/report.types';
import { DayInstance, Subject, SubjectState } from '../subjects/subjects.types';
export type GetConfSymptomQuery = {
  __typename: 'ConfSymptom';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: 'IntensityQuestion';
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  dataInputComponent?: DataInputComponent | null;
  state?: ConfState | null;
  isRangeAlertActive?: boolean | null;
  minValueForAlert?: number | null;
  maxValueForAlert?: number | null;
  alertText?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetSymptomInstanceQuery = {
  __typename: 'SymptomInstance';
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: 'ReportInstance';
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: 'DayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    buttonHelpScreenLabel?: string | null;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: 'IntensityQuestionAnswer';
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ModelMedicalAttentionTypeInput = {
  eq?: MedicalAttentionType | null;
  ne?: MedicalAttentionType | null;
};
export type ModelMedicationGivenToListInput = {
  eq?: Array<MedicationGivenTo | null> | null;
  ne?: Array<MedicationGivenTo | null> | null;
  contains?: MedicationGivenTo | null;
  notContains?: MedicationGivenTo | null;
};
export type ModelSymptomTypeInput = {
  eq?: SymptomType | null;
  ne?: SymptomType | null;
};
export type ModelSymptomInput = {
  eq?: Symptom | null;
  ne?: Symptom | null;
};
export type ModelIntensityInput = {
  eq?: Intensity | null;
  ne?: Intensity | null;
};
export type ModelTemperatureUnitInput = {
  eq?: TemperatureUnit | null;
  ne?: TemperatureUnit | null;
};
export type ModelTemperatureRouteInput = {
  eq?: TemperatureRoute | null;
  ne?: TemperatureRoute | null;
};
export type tp2ManagePDFAndAlertLogInput = {
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  eDiaryPhaseInstanceId?: string | null;
  source: string;
  subjectId?: string | null;
  deviceId?: string | null;
};
export type SymptomOccurrency = {
  __typename: 'SymptomOccurrency';
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};
export type ConfEdiaryByProjectQuery = {
  __typename: 'ModelConfEDiaryConnection';
  items: Array<{
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfFormByConfEdiaryIdQuery = {
  __typename: 'ModelConfFormConnection';
  items: Array<{
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfFormConfSymptomsByConfSymptomIdCustomQuery = {
  __typename: 'ModelConfFormConfSymptomConnection';
  items: Array<{
    __typename: 'ConfFormConfSymptom';
    id: string;
    confFormId: string;
    confSymptomId: string;
    _version: number;
  } | null>;
};
export type ConfMedicalAttentionByConfEdiaryQuery = {
  __typename: 'ModelConfMedicalAttentionConnection';
  items: Array<{
    __typename: 'ConfMedicalAttention';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    isBindToSymptomOcurrencies?: boolean | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showHospitalization?: boolean | null;
    mode?: MedicalAttentionConfigurationMode | null;
    showPrecondition?: boolean | null;
    showType?: boolean | null;
    medicalAttentionTypes?: Array<MedicalAttentionType> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfMedicationByConfEdiaryQuery = {
  __typename: 'ModelConfMedicationConnection';
  items: Array<{
    __typename: 'ConfMedication';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showPrecondition?: boolean | null;
    mode?: MedicationConfigurationMode | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showDose?: boolean | null;
    showFrequency?: boolean | null;
    showAdministrationRoute?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfMultimediaByConfEdiaryQuery = {
  __typename: 'ModelConfMultimediaConnection';
  items: Array<{
    __typename: 'ConfMultimedia';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showDetail?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfOtherSymptomsByConfEdiaryQuery = {
  __typename: 'ModelConfOtherSymptomsConnection';
  items: Array<{
    __typename: 'ConfOtherSymptoms';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    showRememberStartDate?: boolean | null;
    showRememberFinishDate?: boolean | null;
    showProfessionalHealthCare?: boolean | null;
    showType?: boolean | null;
    showIntensity?: boolean | null;
    showOtherSymptom?: boolean | null;
    showPrecondition?: boolean | null;
    preconditionLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfSymptomByConfEdiaryIdQuery = {
  __typename: 'ModelConfSymptomConnection';
  items: Array<{
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    dataInputComponent?: DataInputComponent | null;
    state?: ConfState | null;
    isRangeAlertActive?: boolean | null;
    minValueForAlert?: number | null;
    maxValueForAlert?: number | null;
    alertText?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfSymptomByConfFormIdQuery = {
  __typename: 'ModelConfFormConfSymptomConnection';
  items: Array<{
    __typename: 'ConfFormConfSymptom';
    confFormId: string;
    confSymptomId: string;
    projectId: string;
    id: string;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ConfTemperatureByConfEdiaryQuery = {
  __typename: 'ModelConfTemperatureConnection';
  items: Array<{
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type CreateConfAlertInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  confSymptomId?: string | null;
  confTemperatureId?: string | null;
  projectId: string;
  id?: string | null;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<SiteRecipientsInput> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<AlertRuleInput>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<TriggeredVisitInput> | null;
  suspendVisitList?: Array<TriggeredVisitInput> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type TriggeredVisitInput = {
  order: number;
  visitName: string;
};
export type AlertRuleInput = {
  ruleId?: string | null;
  alertRuleType: AlertRuleType;
  expectedValue?: string | null;
  expectedUnit?: string | null;
};
export type SiteRecipientsInput = {
  siteId: string;
  recipients: Array<string>;
};
export type CreateConfAlertMutation = {
  __typename: 'ConfAlert';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: 'SiteRecipients';
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: 'AlertRule';
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: 'TriggeredVisit';
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: 'TriggeredVisit';
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfDayInput = {
  confEdiaryId: string;
  confFormId: string;
  projectId: string;
  id?: string | null;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfDayMutation = {
  __typename: 'ConfDay';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export enum MedicationConfigurationMode {
  GENERAL_GIVEN_TO_OPEN_REASON = 'GENERAL_GIVEN_TO_OPEN_REASON',
  INDIVIDUAL_GIVEN_TO_OPEN_REASON = 'INDIVIDUAL_GIVEN_TO_OPEN_REASON',
  GENERAL_GIVEN_TO_SYMPTOM_BINDING = 'GENERAL_GIVEN_TO_SYMPTOM_BINDING',
  INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING = 'INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING',
  NO_GIVEN_TO_OPEN_REASON = 'NO_GIVEN_TO_OPEN_REASON',
  NO_GIVEN_TO_SYMPTOM_BINDING = 'NO_GIVEN_TO_SYMPTOM_BINDING',
  NO_GIVEN_NO_REASON = 'NO_GIVEN_NO_REASON',
}
export enum MedicalAttentionConfigurationMode {
  OPEN_REASON = 'OPEN_REASON',
  SYMPTOM_BINDING = 'SYMPTOM_BINDING',
  NO_REASON = 'NO_REASON',
}
export enum Intensity {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  LIFE_THREATENING = 'LIFE_THREATENING',
}
export enum IntensityType {
  RANGE = 'RANGE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  NUMERIC_VALUE = 'NUMERIC_VALUE',
  NUMERIC_VALUE_QUESTIONS = 'NUMERIC_VALUE_QUESTIONS',
}
export enum InjectionSide {
  NA = 'NA',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
export enum SymptomType {
  LOCAL = 'LOCAL',
  GENERAL = 'GENERAL',
}
export type CreateConfEDiaryInput = {
  projectId: string;
  windows?: Array<ConfEDiaryWindowInput | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  assessmentMode?: AssessmentMode | null;
  id?: string | null;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type ConfEDiaryWindowInput = {
  name: string;
  phases: Array<string>;
  daysAfterVisit: number;
  totalWindowDays: number;
};
export type CreateConfEDiaryMutation = {
  __typename: 'ConfEDiary';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  windows?: Array<{
    __typename: 'ConfEDiaryWindow';
    name: string;
    phases: Array<string>;
    daysAfterVisit: number;
    totalWindowDays: number;
  } | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  assessmentMode?: AssessmentMode | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfFormConfSymptomInput = {
  confFormId: string;
  confSymptomId: string;
  projectId: string;
  id?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfFormConfSymptomMutation = {
  __typename: 'ConfFormConfSymptom';
  confFormId: string;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfFormInput = {
  confEdiaryId: string;
  projectId: string;
  id?: string | null;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: TemperatureReconciliationConfigInput | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: MedicationReconciliationConfigInput | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: MedicalAttentionReconciliationConfigInput | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: SymptomsReconciliationConfigInput | null;
  localSymptomsReconciliationConfig?: SymptomsReconciliationConfigInput | null;
  otherSymptomsReconciliationConfig?: OtherSymptomsReconciliationConfigInput | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfFormMutation = {
  __typename: 'ConfForm';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    __typename: 'TemperatureReconciliationConfig';
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowRouteReconciliation?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    __typename: 'MedicationReconciliationConfig';
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowDosisReconciliation?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowRouteReconciliation?: boolean | null;
    allowTreatmentReconciliation?: boolean | null;
    allowReasonReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    __typename: 'MedicalAttentionReconciliationConfig';
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowTypeReconciliation?: boolean | null;
    allowSymptomOccurencyReconciliation?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowHospitalizationReconciliation?: boolean | null;
    allowReasonReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    __typename: 'SymptomsReconciliationConfig';
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
  } | null;
  localSymptomsReconciliationConfig?: {
    __typename: 'SymptomsReconciliationConfig';
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    __typename: 'OtherSymptomsReconciliationConfig';
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowHospitalizationReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfMedicalAttentionInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfMedicalAttentionMutation = {
  __typename: 'ConfMedicalAttention';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfMedicationInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfMedicationMutation = {
  __typename: 'ConfMedication';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfMultimediaInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfMultimediaMutation = {
  __typename: 'ConfMultimedia';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfOtherSymptomsInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfOtherSymptomsMutation = {
  __typename: 'ConfOtherSymptoms';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfSymptomInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  projectId: string;
  id?: string | null;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: IntensityScaleInput | null;
  lowIntensityScaleOptions?: IntensityScaleInput | null;
  mediumIntensityScaleOptions?: IntensityScaleInput | null;
  highIntensityScaleOptions?: IntensityScaleInput | null;
  lifeThreateningScaleOptions?: IntensityScaleInput | null;
  intensityQuestions?: Array<IntensityQuestionInput | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  dataInputComponent?: DataInputComponent | null;
  state?: ConfState | null;
  isRangeAlertActive?: boolean | null;
  minValueForAlert?: number | null;
  maxValueForAlert?: number | null;
  alertText?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type IntensityScaleInput = {
  active: boolean;
  startValue: number;
  endValue: number;
  helpText: string;
  label?: string | null;
};
export type IntensityQuestionInput = {
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};
export type CreateConfSymptomMutation = {
  __typename: 'ConfSymptom';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: 'IntensityQuestion';
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  dataInputComponent?: DataInputComponent | null;
  state?: ConfState | null;
  isRangeAlertActive?: boolean | null;
  minValueForAlert?: number | null;
  maxValueForAlert?: number | null;
  alertText?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export enum HelpScreenDisplayOption {
  ON_DEMAND = 'ON_DEMAND',
  PREVIOUS_TO_FORM = 'PREVIOUS_TO_FORM',
  WITH_SYMPTOM_OCCURRENCY = 'WITH_SYMPTOM_OCCURRENCY',
  SKIP_EDITION = 'SKIP_EDITION',
}

export type TemperatueRangesInput = {
  minValue?: number | null;
  maxValue?: number | null;
  expectedMinValue?: number | null;
  expectedMaxValue?: number | null;
  seriousMinValue?: number | null;
  seriousMaxValue?: number | null;
};

export type CreateConfTemperatureMutation = {
  __typename: 'ConfTemperature';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId?: string | null;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type DeleteConfDayInput = {
  id: string;
  _version?: number | null;
};
export type DeleteConfDayMutation = {
  __typename: 'ConfDay';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfSymptom = {
  __typename: 'ConfSymptom';
  confReportId?: string | null;
  confReport?: ConfReport | null;
  confEdiaryId?: string | null;
  confEdiary?: ConfEDiary | null;
  projectId: string;
  project?: Project | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: IntensityScale | null;
  lowIntensityScaleOptions?: IntensityScale | null;
  mediumIntensityScaleOptions?: IntensityScale | null;
  highIntensityScaleOptions?: IntensityScale | null;
  lifeThreateningScaleOptions?: IntensityScale | null;
  intensityQuestions?: Array<IntensityQuestion | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  dataInputComponent?: DataInputComponent | null;
  state?: ConfState | null;
  isRangeAlertActive?: boolean | null;
  minValueForAlert?: number | null;
  maxValueForAlert?: number | null;
  alertText?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type IntensityScale = {
  __typename: 'IntensityScale';
  active: boolean;
  startValue: number;
  endValue: number;
  helpText: string;
  label?: string | null;
};
export type IntensityQuestion = {
  __typename: 'IntensityQuestion';
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};
export type ProjectTermsAndConditions = {
  __typename: 'ProjectTermsAndConditions';
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};
export type ConfEDiary = {
  __typename: 'ConfEDiary';
  projectId: string;
  project?: Project | null;
  windows?: Array<ConfEDiaryWindow | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  assessmentMode?: AssessmentMode | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfEDiaryWindow = {
  __typename: 'ConfEDiaryWindow';
  name: string;
  phases: Array<string>;
  daysAfterVisit: number;
  totalWindowDays: number;
};
export type ConfSymptomByConfReportIdQuery = {
  __typename: 'ModelConfSymptomConnection';
  items: Array<{
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    dataInputComponent?: DataInputComponent | null;
    state?: ConfState | null;
    isRangeAlertActive?: boolean | null;
    minValueForAlert?: number | null;
    maxValueForAlert?: number | null;
    alertText?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type DeleteConfFormConfSymptomInput = {
  id: string;
  _version?: number | null;
};
export type DeleteConfFormConfSymptomMutation = {
  __typename: 'ConfFormConfSymptom';
  confFormId: string;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId: string;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type DeleteConfSymptomInput = {
  id: string;
  _version?: number | null;
};
export type DeleteConfSymptomMutation = {
  __typename: 'ConfSymptom';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: 'IntensityQuestion';
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ModelDayInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  eDiaryPhaseInstanceId?: ModelIDInput | null;
  confDayId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  startDate?: ModelStringInput | null;
  finishDate?: ModelStringInput | null;
  completedDate?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  confDayVersion?: ModelStringInput | null;
  hasMedication?: ModelBooleanInput | null;
  medicationGivenTo?: ModelMedicationGivenToListInput | null;
  hasMedicalAttention?: ModelBooleanInput | null;
  hasOtherSymptoms?: ModelBooleanInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  comments?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelDayInstanceFilterInput | null> | null;
  or?: Array<ModelDayInstanceFilterInput | null> | null;
  not?: ModelDayInstanceFilterInput | null;
};
export type ModelInstanceStateInput = {
  eq?: InstanceState | null;
  ne?: InstanceState | null;
};
export type UpdateConfAlertInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  confSymptomId?: string | null;
  confTemperatureId?: string | null;
  projectId?: string | null;
  id: string;
  alertName?: string | null;
  recipients?: Array<string> | null;
  siteRecipients?: Array<SiteRecipientsInput> | null;
  type?: AlertType | null;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules?: Array<AlertRuleInput> | null;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<TriggeredVisitInput> | null;
  suspendVisitList?: Array<TriggeredVisitInput> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfAlertMutation = {
  __typename: 'ConfAlert';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: 'SiteRecipients';
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: 'AlertRule';
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: 'TriggeredVisit';
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: 'TriggeredVisit';
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateConfDayInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  dayName?: string | null;
  order?: number | null;
  startDay?: number | null;
  endDay?: number | null;
  groups?: Array<string> | null;
  phases?: Array<string> | null;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfEDiaryInput = {
  projectId?: string | null;
  windows?: Array<ConfEDiaryWindowInput | null> | null;
  isConciliationRequired?: boolean | null;
  isWindowsConciliationRequired?: boolean | null;
  assessmentMode?: AssessmentMode | null;
  id: string;
  isSignatureRequired?: boolean | null;
  isPastEntryEnabled?: boolean | null;
  isGPSRequired?: boolean | null;
  isUserIPRequired?: boolean | null;
  isDeviceDataRequired?: boolean | null;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfFormInput = {
  confEdiaryId?: string | null;
  projectId?: string | null;
  id: string;
  name?: string | null;
  isTemperatureRequired?: boolean | null;
  temperatureReconciliationConfig?: TemperatureReconciliationConfigInput | null;
  isMedicationRequired?: boolean | null;
  medicationReconciliationConfig?: MedicationReconciliationConfigInput | null;
  isMedicalAttentionRequired?: boolean | null;
  medicalAttentionReconciliationConfig?: MedicalAttentionReconciliationConfigInput | null;
  isMediaRequired?: boolean | null;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired?: boolean | null;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired?: boolean | null;
  symptomsReconciliationConfig?: SymptomsReconciliationConfigInput | null;
  localSymptomsReconciliationConfig?: SymptomsReconciliationConfigInput | null;
  otherSymptomsReconciliationConfig?: OtherSymptomsReconciliationConfigInput | null;
  symptomPageLayout?: PageLayout | null;
  showHelp?: boolean | null;
  formHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateConfFormMutation = {
  __typename: 'ConfForm';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: {
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowRouteReconciliation?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
  } | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: {
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowDosisReconciliation?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowRouteReconciliation?: boolean | null;
    allowTreatmentReconciliation?: boolean | null;
    allowReasonReconciliation?: boolean | null;
  } | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: {
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowTypeReconciliation?: boolean | null;
    allowSymptomOccurencyReconciliation?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowHospitalizationReconciliation?: boolean | null;
    allowReasonReconciliation?: boolean | null;
  } | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: {
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
  } | null;
  localSymptomsReconciliationConfig?: {
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
  } | null;
  otherSymptomsReconciliationConfig?: {
    allowAddRecords?: boolean | null;
    allowRemoveRecords?: boolean | null;
    allowOcurencyDateReconciliation?: boolean | null;
    allowHospitalizationReconciliation?: boolean | null;
  } | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateConfMedicalAttentionInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  isBindToSymptomOcurrencies?: boolean | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showHospitalization?: boolean | null;
  mode?: MedicalAttentionConfigurationMode | null;
  showPrecondition?: boolean | null;
  showType?: boolean | null;
  medicalAttentionTypes?: Array<MedicalAttentionType> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfMedicationInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showPrecondition?: boolean | null;
  mode?: MedicationConfigurationMode | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showDose?: boolean | null;
  showFrequency?: boolean | null;
  showAdministrationRoute?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfMultimediaInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showDetail?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfOtherSymptomsInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  showRememberStartDate?: boolean | null;
  showRememberFinishDate?: boolean | null;
  showProfessionalHealthCare?: boolean | null;
  showType?: boolean | null;
  showIntensity?: boolean | null;
  showOtherSymptom?: boolean | null;
  showPrecondition?: boolean | null;
  preconditionLabel?: string | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfSymptomInput = {
  confReportId?: string | null;
  confEdiaryId?: string | null;
  projectId?: string | null;
  id: string;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  order?: number | null;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp?: boolean | null;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: IntensityScaleInput | null;
  lowIntensityScaleOptions?: IntensityScaleInput | null;
  mediumIntensityScaleOptions?: IntensityScaleInput | null;
  highIntensityScaleOptions?: IntensityScaleInput | null;
  lifeThreateningScaleOptions?: IntensityScaleInput | null;
  intensityQuestions?: Array<IntensityQuestionInput | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  dataInputComponent?: DataInputComponent | null;
  state?: ConfState | null;
  isRangeAlertActive?: boolean | null;
  minValueForAlert?: number | null;
  maxValueForAlert?: number | null;
  alertText?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfSymptomMutation = {
  __typename: 'ConfSymptom';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    symptomsReconciliationConfig?: ReconciliationConfigInput | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  type: SymptomType;
  symptom: Symptom;
  order: number;
  injectionSite?: InjectionSite | null;
  injectionSide?: InjectionSide | null;
  showHelp: boolean;
  symptomHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  symptomLabel?: string | null;
  symptomIcon?: string | null;
  decimalPlaces?: number | null;
  minSize?: number | null;
  maxSize?: number | null;
  intensityType?: IntensityType | null;
  intensitySizeLabel?: string | null;
  noneIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lowIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  mediumIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  highIntensityScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  lifeThreateningScaleOptions?: {
    __typename: 'IntensityScale';
    active: boolean;
    startValue: number;
    endValue: number;
    helpText: string;
    label?: string | null;
  } | null;
  intensityQuestions?: Array<{
    __typename: 'IntensityQuestion';
    id: string;
    order: number;
    question: string;
    intensity?: Intensity | null;
  } | null> | null;
  isIntensityQuestionsRequired?: boolean | null;
  isIntensityRequired?: boolean | null;
  isStartDateRequired?: boolean | null;
  isFinishDateRequired?: boolean | null;
  showNoValuesTaken?: boolean | null;
  noValuesTakenLabel?: string | null;
  dataInputComponent?: DataInputComponent | null;
  state?: ConfState | null;
  isRangeAlertActive?: boolean | null;
  minValueForAlert?: number | null;
  maxValueForAlert?: number | null;
  alertText?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateConfTemperatureInput = {
  confEdiaryId: string;
  confFormId?: string | null;
  projectId: string;
  id?: string | null;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  units?: Array<TemperatureUnit | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  showHelpScreen?: boolean | null;
  helpScreenText?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  allowExitToTakeTemperature?: boolean | null;
  alertExitToTakeTemperatureText?: string | null;
  celciusRanges?: TemperatureRangesInput | null;
  fahrenheitRanges?: TemperatureRangesInput | null;
  isExpectedRangeActive?: boolean | null;
  alertTextForUnexpectedValues?: string | null;
  isSeriousRangeActive?: boolean | null;
  alertTextForSeriousValues?: string | null;
  dataInputComponent?: DataInputComponent | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type UpdateConfTemperatureInput = {
  confEdiaryId?: string | null;
  confFormId?: string | null;
  projectId?: string | null;
  id: string;
  helpText?: string | null;
  label?: string | null;
  dayRule?: DayRule | null;
  routeOptions?: Array<TemperatureRoute | null> | null;
  units?: Array<TemperatureUnit | null> | null;
  showFahrenheitUnit?: boolean | null;
  showTemperatureRoute?: boolean | null;
  ocurrencyValue?: number | null;
  ocurrencyUnit?: TemperatureUnit | null;
  showTemperatureTaken?: boolean | null;
  showTemperatureTakenDate?: boolean | null;
  state?: ConfState | null;
  showHelpScreen?: boolean | null;
  helpScreenText?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  buttonHelpScreenLabel?: string | null;
  allowExitToTakeTemperature?: boolean | null;
  alertExitToTakeTemperatureText?: string | null;
  celciusRanges?: TemperatureRangesInput | null;
  fahrenheitRanges?: TemperatureRangesInput | null;
  isExpectedRangeActive?: boolean | null;
  alertTextForUnexpectedValues?: string | null;
  isSeriousRangeActive?: boolean | null;
  alertTextForSeriousValues?: string | null;
  dataInputComponent?: DataInputComponent | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type TemperatureRangesInput = {
  minValue?: number | null;
  maxValue?: number | null;
  decimalPlaces?: number | null;
  expectedMinValue?: number | null;
  expectedMaxValue?: number | null;
  seriousMinValue?: number | null;
  seriousMaxValue?: number | null;
};

export type UpdateEDiaryPhaseInstanceInput = {
  subjectId?: string | null;
  eDiaryInstanceId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  phase?: string | null;
  pdfFiles?: Array<TP2FileInput> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLockInput | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type ModelConfAlertFilterInput = {
  confReportId?: ModelIDInput | null;
  confEdiaryId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  confTemperatureId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  alertName?: ModelStringInput | null;
  recipients?: ModelStringInput | null;
  type?: ModelAlertTypeInput | null;
  mailSubject?: ModelStringInput | null;
  mailBody?: ModelStringInput | null;
  SMSBody?: ModelStringInput | null;
  triggerVisitCompletion?: ModelBooleanInput | null;
  triggerEDiarySuspension?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfAlertFilterInput | null> | null;
  or?: Array<ModelConfAlertFilterInput | null> | null;
  not?: ModelConfAlertFilterInput | null;
};
export type ModelAlertTypeInput = {
  eq?: AlertType | null;
  ne?: AlertType | null;
};
export type ModelConfDayFilterInput = {
  confEdiaryId?: ModelIDInput | null;
  confFormId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  dayName?: ModelStringInput | null;
  order?: ModelIntInput | null;
  startDay?: ModelIntInput | null;
  endDay?: ModelIntInput | null;
  groups?: ModelStringInput | null;
  phases?: ModelStringInput | null;
  trackSymptomOcurrencies?: ModelBooleanInput | null;
  hidePDFDayColumn?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfDayFilterInput | null> | null;
  or?: Array<ModelConfDayFilterInput | null> | null;
  not?: ModelConfDayFilterInput | null;
};
export type MedicationInstance = {
  __typename: 'MedicationInstance';
  subjectId: string;
  subject?: Subject | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  id: string;
  state: InstanceState;
  rememberStartDate: boolean;
  startDate?: string | null;
  isOnGoing?: boolean | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  name?: string | null;
  reason?: string | null;
  symptomsInstances?: Array<string> | null;
  givenTo?: Array<MedicationGivenTo | null> | null;
  dose?: string | null;
  frequency?: string | null;
  administrationRoute?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfFormConfSymptom = {
  __typename: 'ConfFormConfSymptom';
  confFormId: string;
  confForm?: ConfForm | null;
  confSymptomId: string;
  confSymptom?: ConfSymptom | null;
  projectId: string;
  project?: Project | null;
  id: string;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ListDayInstancesQuery = {
  __typename: 'ModelDayInstanceConnection';
  items: Array<{
    __typename: 'DayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    timezonesHistory?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ListTemperatureRecordLogsQuery = {
  __typename: 'ModelTemperatureRecordLogConnection';
  items: Array<{
    __typename: 'TemperatureRecordLog';
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    temperature?: number | null;
    temperatureUnit?: TemperatureUnit | null;
    temperatureRoute?: TemperatureRoute | null;
    temperatureWhichOtherRoute?: string | null;
    date?: string | null;
    temperatureTaken?: boolean | null;
    temperatureTakenDate?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ModelTemperatureRecordLogConditionInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  temperature?: ModelFloatInput | null;
  temperatureUnit?: ModelTemperatureUnitInput | null;
  temperatureRoute?: ModelTemperatureRouteInput | null;
  temperatureWhichOtherRoute?: ModelStringInput | null;
  date?: ModelStringInput | null;
  temperatureTaken?: ModelBooleanInput | null;
  temperatureTakenDate?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelTemperatureRecordLogConditionInput | null> | null;
  or?: Array<ModelTemperatureRecordLogConditionInput | null> | null;
  not?: ModelTemperatureRecordLogConditionInput | null;
};
export type GetConfDayQuery = {
  __typename: 'ConfDay';
  confEdiaryId: string;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    assessmentMode?: AssessmentMode | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confFormId: string;
  confForm?: {
    __typename: 'ConfForm';
    confEdiaryId: string;
    projectId: string;
    id: string;
    name: string;
    isTemperatureRequired: boolean;
    isMedicationRequired: boolean;
    isMedicalAttentionRequired: boolean;
    isMediaRequired: boolean;
    isReconciliationRequired?: boolean | null;
    sectionsToReconcile?: Array<string | null> | null;
    areCommentsRequired: boolean;
    isOtherForm?: boolean | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    mediaTypes?: Array<MediaType> | null;
    areOtherSymptomsRequired: boolean;
    symptomPageLayout: PageLayout;
    showHelp: boolean;
    formHelp?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfDay = {
  __typename: 'ConfDay';
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  confFormId: string;
  confForm?: ConfForm | null;
  projectId: string;
  project?: Project | null;
  id: string;
  dayName: string;
  order: number;
  startDay: number;
  endDay: number;
  groups: Array<string>;
  phases: Array<string>;
  trackSymptomOcurrencies?: boolean | null;
  hidePDFDayColumn?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfForm = {
  __typename: 'ConfForm';
  confEdiaryId: string;
  confEdiary?: ConfEDiary | null;
  projectId: string;
  project?: Project | null;
  id: string;
  name: string;
  isTemperatureRequired: boolean;
  temperatureReconciliationConfig?: TemperatureReconciliationConfig | null;
  isMedicationRequired: boolean;
  medicationReconciliationConfig?: MedicationReconciliationConfig | null;
  isMedicalAttentionRequired: boolean;
  medicalAttentionReconciliationConfig?: MedicalAttentionReconciliationConfig | null;
  isMediaRequired: boolean;
  isReconciliationRequired?: boolean | null;
  sectionsToReconcile?: Array<string | null> | null;
  areCommentsRequired: boolean;
  isOtherForm?: boolean | null;
  mediaTypes?: Array<MediaType> | null;
  areOtherSymptomsRequired: boolean;
  symptomsReconciliationConfig?: SymptomsReconciliationConfig | null;
  localSymptomsReconciliationConfig?: SymptomsReconciliationConfig | null;
  otherSymptomsReconciliationConfig?: OtherSymptomsReconciliationConfig | null;
  symptomPageLayout: PageLayout;
  showHelp: boolean;
  formHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ReconciliationConfig = {
  __typename: 'ReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptions | null;
  allowAdditionalQuestionsReconciliation?: boolean | null;
};

export type IntensityReconciliationOptions = {
  __typename: 'IntensityReconciliationOptions';
  allow?: boolean | null;
  allowedAction?: IntensityReconciliationOption | null;
};
export type TemperatureReconciliationConfig = {
  __typename: 'TemperatureReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptions | null;
  allowRouteReconciliation?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
};
export type MedicationReconciliationConfig = {
  __typename: 'MedicationReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowDosisReconciliation?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowRouteReconciliation?: boolean | null;
  allowTreatmentReconciliation?: boolean | null;
  allowReasonReconciliation?: boolean | null;
};

export type MedicalAttentionReconciliationConfig = {
  __typename: 'MedicalAttentionReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowTypeReconciliation?: boolean | null;
  allowSymptomOccurencyReconciliation?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowHospitalizationReconciliation?: boolean | null;
  allowReasonReconciliation?: boolean | null;
};

export type SymptomsReconciliationConfig = {
  __typename: 'SymptomsReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptions | null;
};

export type OtherSymptomsReconciliationConfig = {
  __typename: 'OtherSymptomsReconciliationConfig';
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptions | null;
  allowHospitalizationReconciliation?: boolean | null;
};
export type TemperatureReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptionsInput | null;
  allowRouteReconciliation?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
};

export type IntensityReconciliationOptionsInput = {
  allow?: boolean | null;
  allowedAction?: IntensityReconciliationOption | null;
};

export type MedicationReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowDosisReconciliation?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowRouteReconciliation?: boolean | null;
  allowTreatmentReconciliation?: boolean | null;
  allowReasonReconciliation?: boolean | null;
};

export type MedicalAttentionReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowTypeReconciliation?: boolean | null;
  allowSymptomOccurencyReconciliation?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowHospitalizationReconciliation?: boolean | null;
  allowReasonReconciliation?: boolean | null;
};

export type SymptomsReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptionsInput | null;
};

export type OtherSymptomsReconciliationConfigInput = {
  allowAddRecords?: boolean | null;
  allowRemoveRecords?: boolean | null;
  allowOcurencyDateReconciliation?: boolean | null;
  allowIntensityReconciliation?: IntensityReconciliationOptionsInput | null;
  allowHospitalizationReconciliation?: boolean | null;
};

export enum IntensityReconciliationOption {
  ANY = 'ANY',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export enum Symptom {
  PAIN = 'PAIN',
  TENDERNESS = 'TENDERNESS',
  NAUSEA = 'NAUSEA',
  FEVER = 'FEVER',
  IRRITABILITY = 'IRRITABILITY',
  VOMIT = 'VOMIT',
  ANORMAL_CRYING = 'ANORMAL_CRYING',
  DROWSINESS = 'DROWSINESS',
  APPETITE_LOSS = 'APPETITE_LOSS',
  HEADACHE = 'HEADACHE',
  COUGH = 'COUGH',
  SORE_THROAT = 'SORE_THROAT',
  RUNNY_NOSE = 'RUNNY_NOSE',
  WHEEZING = 'WHEEZING',
  MUSCLE_SORENESS = 'MUSCLE_SORENESS',
  EARACHE = 'EARACHE',
  DIARRHEA = 'DIARRHEA',
  FATIGUE = 'FATIGUE',
  CHILL = 'CHILL',
  ARTHRALGIA = 'ARTHRALGIA',
  SWELLING = 'SWELLING',
  REDNESS = 'REDNESS',
  HEMATOMA = 'HEMATOMA',
  SORENESS = 'SORENESS',
  HARDNESS = 'HARDNESS',
  ITCHING = 'ITCHING',
  OTHER = 'OTHER',
}
export enum InjectionSite {
  NA = 'NA',
  ARM = 'ARM',
  THIGH = 'THIGH',
  BUTTOCK = 'BUTTOCK',
}
export enum OnDemandAlertType {
  DAYS = 'DAYS',
  WEEKLY = 'WEEKLY',
}
export enum MedicationGivenTo {
  TREAT = 'TREAT',
  PREVENT = 'PREVENT',
}
export enum TemperatureUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT',
}
export enum TemperatureRoute {
  ORAL = 'ORAL',
  RECTAL = 'RECTAL',
  AXILLARY = 'AXILLARY',
  EAR = 'EAR',
  OTHER = 'OTHER',
}
export enum DayRule {
  MIN = 'MIN',
  MAX = 'MAX',
  AVERAGE = 'AVERAGE',
}
export enum MedicalAttentionType {
  ER = 'ER',
  MEDICAL_APPONTMENT = 'MEDICAL_APPONTMENT',
  TELEMEDICINE = 'TELEMEDICINE',
  HOSPITALIZATION = 'HOSPITALIZATION',
}

export enum AssessmentMode {
  DETAILED_REVIEW = 'DETAILED_REVIEW',
  AUTOMATIC_REVIEW = 'AUTOMATIC_REVIEW',
}
