<div class="p-col-12 p-pl-0">
  <label for="reasonSelect"
    >{{ label }}<span class="inputRequired" *ngIf="required"> * </span></label
  >
  <p-dropdown
    id="auditChangeReason"
    name="auditChangeReason"
    [options]="reasonsItems"
    [(ngModel)]="principalReason"
    placeholder="{{ 'general.selectPlaceholder' | translate }}"
    [required]="required"
    [disabled]="isDisabled"
    appendTo="body"
    (onChange)="changePrincipalReason()"
    (blur)="onTouched?.()"
    #auditChangeReason="ngModel"
  ></p-dropdown>
</div>
<div *ngIf="principalReason === OTHER_REASON" class="p-mt-3">
  <label for="txtOtherReason"
    >{{ 'general.changeReasons.other' | translate
    }}<span class="inputRequired" *ngIf="required"> * </span></label
  >
  <textarea
    class="p-col-12"
    pInputTextarea
    id="txtOtherReason"
    name="txtOtherReason"
    [(ngModel)]="otherReason"
    [disabled]="isDisabled"
    (input)="changeOtherReason()"
    (blur)="onTouched?.()"
    [autoResize]="true"
    [required]="required"
    #txtOtherReason="ngModel"
  ></textarea>
</div>
