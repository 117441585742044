<div class="container p-grid p-fluid">
  <h4 _ngcontent-hvb-c134="" class="p-mt-2">
    {{ 'report.labelMultimediaInstruction' | translate }}
  </h4>
  <div class="p-col-12 p-md-6 p-field" *ngIf="confReport.isMediaRequired">
    <label for="mediatypes">{{ 'report.labelMediaType' | translate }}</label
    ><span class="inputRequired">* </span>

    <p-selectButton
      [disabled]="isReadOnly"
      required
      id="mediaTypes"
      name="mediatypes"
      #mediatypes="ngModel"
      [options]="mediaTypes"
      [(ngModel)]="confReport.mediaTypes"
      [multiple]="true"
      optionLabel="name"
      optionValue="value"
      [disabled]="readMode"
    ></p-selectButton>
    <small
      class="form-error"
      *ngIf="!mediatypes.valid && (form.submitted || mediatypes.touched)"
      >{{ 'general.required' | translate }}</small
    >
  </div>
</div>
