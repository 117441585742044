import { Injectable } from '@angular/core';
import { Logger, Storage } from 'aws-amplify';
const logger = new Logger('tp2-storageService');
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  put(
    file: File,
    path: string,
    key: string,
    level: 'private' | 'public' | 'protected' = 'public'
  ) {
    const type = file.type;
    return Storage.put(path + key, file, {
      contentType: type,
      completeCallback: (event: any) => {
        logger.info(`Successfully uploaded ${event.key}`);
      },
      progressCallback: (progress: any) => {
        logger.info(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err: any) => {
        logger.info('Unexpected error while uploading', err);
      },
      level: level,
    });
  }

  get(key: string): Promise<any> {
    return Storage.get(key);
  }

  async getImage(key: string): Promise<any> {
    return await Storage.get(key);
  }

  async getObject(key: string): Promise<any> {
    return await Storage.get(key, { download: true });
  }

  remove(key: string) {
    return Storage.remove(key);
  }
  list(key: string) {
    return Storage.list(key);
  }
}
