import { Component, OnInit } from '@angular/core';
import { Predictions } from '@aws-amplify/predictions';
import {
  BedrockRuntimeClient,
  InvokeModelCommand,
} from '@aws-sdk/client-bedrock-runtime';
import { Auth } from 'aws-amplify';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import pollyVoices from 'src/assets/pollyVoices.json';

@Component({
  selector: 'app-generate-media-modal',
  templateUrl: './generate-media-modal.component.html',
  styleUrls: ['./generate-media-modal.component.scss'],
})
export class GenerateMediaModalComponent implements OnInit {
  isPlaying: boolean = false;
  temporaryAudioPlaying: any = undefined;
  generatedImage: string | ArrayBuffer | null = null;
  constructor(
    private trialpalService: TrialpalService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}
  temporaryUploadedFile: File | undefined;
  languageVoices: any[] = pollyVoices;
  languageOptions: any[] = [];
  mediaInputLanguage: string = 'en-US';
  mediaInputVoice: string = 'Joanna';
  voiceOptions: any[] = [];
  mediaInputText: string = '';
  isGeneratingMedia: boolean = false;
  isGeneratingTestAudio: boolean = false;
  mediaType: string = '';

  ngOnInit(): void {
    this.setLanguageOptions();
    this.setVoiceOptions();
    this.mediaType = this.config.data.mediaType;
  }
  setLanguageOptions() {
    this.languageOptions = this.languageVoices
      .map((e) => {
        return {
          value: e.language,
          label: this.trialpalService.translateService.instant(
            'general.pollyVoices.languages.' + e.language
          ),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  onLanguageSelected() {
    this.setVoiceOptions();
  }
  setVoiceOptions() {
    console.debug('mediaInputLanguage -->', this.mediaInputLanguage);
    const selectedLanguage = this.languageVoices.filter(
      (e) => e.language === this.mediaInputLanguage
    )[0];
    if (selectedLanguage) {
      this.voiceOptions = selectedLanguage.voices.map((e: any) => {
        return {
          value: e.name,
          label:
            e.name +
            ', ' +
            this.trialpalService.translateService.instant(
              'general.pollyVoices.genders.' + e.gender
            ),
        };
      });
    }
    console.debug('voiceOptions -->', this.voiceOptions);
  }
  onVoiceSelected() {
    this.temporaryUploadedFile = undefined;
  }
  generateMedia() {
    this.isGeneratingMedia = true;
    Predictions.convert({
      textToSpeech: {
        source: {
          text: this.mediaInputText,
        },
        voiceId: this.mediaInputVoice,
      },
    })
      .then((result: any) => {
        if (result.speech) {
          const uInt8Array = new Uint8Array(result.audioStream);
          const arrayBuffer = uInt8Array.buffer;
          const blob = new Blob([arrayBuffer]);
          const fileName =
            this.mediaInputText.slice(0, 15) + Date.now().toString() + '.mp3';
          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: 'audio/mpeg' });
          this.temporaryUploadedFile = file;
        }
      })
      .catch((err: any) => console.log('error -->', err))
      .finally(() => {
        this.isGeneratingMedia = false;
      });
  }
  playOrPauseMedia() {
    if (this.isPlaying) {
      this.stopMedia();
    } else {
      this.playMedia();
    }
  }
  playMedia() {
    if (this.temporaryUploadedFile) {
      const audio = new Audio(URL.createObjectURL(this.temporaryUploadedFile));
      this.temporaryAudioPlaying = audio;
      this.isPlaying = true;
      audio.play();
      audio.onended = () => {
        this.isPlaying = false;
      };
    }
  }
  stopMedia() {
    this.isPlaying = false;
    if (this.temporaryAudioPlaying) {
      this.temporaryAudioPlaying.pause();
      this.temporaryAudioPlaying = undefined;
    }
  }
  onCancel() {
    this.stopMedia();
    this.ref.close();
  }
  onSave() {
    this.stopMedia();
    console.debug('temporaryUploadedFile -->', this.temporaryUploadedFile);
    this.ref.close(this.temporaryUploadedFile);
  }
  playTestAudio() {
    this.isGeneratingTestAudio = true;
    Predictions.convert({
      textToSpeech: {
        source: {
          text: this.mediaInputLanguage.startsWith('es')
            ? 'Hola, esto es una prueba'
            : 'Hello, this is a test',
        },
        voiceId: this.mediaInputVoice,
      },
    })
      .then((result: any) => {
        if (result.speech) {
          const uInt8Array = new Uint8Array(result.audioStream);
          const arrayBuffer = uInt8Array.buffer;
          const blob = new Blob([arrayBuffer]);
          const fileName = 'test.mp3';
          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: 'audio/mpeg' });
          const audio = new Audio(URL.createObjectURL(file));
          audio.play();
        }
      })
      .catch((err: any) => console.log('error -->', err))
      .finally(() => {
        this.isGeneratingTestAudio = false;
      });
  }
  async generateMediaPhoto() {
    this.isGeneratingMedia = true;
    const credentials = await Auth.currentCredentials();
    const client = new BedrockRuntimeClient({
      serviceId: 'bedrock',
      credentials,
      region: 'us-east-1',
    });
    let rand_seed = this.generate_random_seed(0, 4294967295);
    const params = {
      contentType: 'application/json',
      accept: '*/*',
      modelId: 'stability.stable-diffusion-xl-v1',
      body: `{
          "width": 1024,
          "height": 1024,
          "text_prompts":[
              {
                  "text":"${this.mediaInputText}"
              }],
          "cfg_scale":10,
          "seed":${rand_seed},
          "steps":150}`,
    };
    try {
      const command = new InvokeModelCommand(params);
      let bedrock_response = await client.send(command);
      console.log('STABLE DIFFUSSION', bedrock_response);
      const textDecoder = new TextDecoder('utf-8');
      const jsonString = textDecoder.decode(bedrock_response.body.buffer);
      const parsedData = JSON.parse(jsonString);
      console.log('PARSED DATA STABLE DIFFUSSION', parsedData);
      //create a blob from the base64 string and display it
      const byteCharacters = atob(parsedData.artifacts[0].base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const url = reader.result;
        this.generatedImage = url;
        this.temporaryUploadedFile = blob as File;
      };
      this.isGeneratingMedia = false;
    } catch (e) {
      this.isGeneratingMedia = false;
      throw e;
    }
  }
  generate_random_seed(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }
}
