<div class="summary p-mb-5">
  <div class="p-grid p-col-12 p-p-0">
    <div class="p-d-flex p-col-12 p-jc-between" *ngIf="showWithOtherSymptom">
      <span
        id="lbl_verifiedWhichOtherSymptom"
        name="lbl_verifiedWhichOtherSymptom"
        for="verifiedWhichOtherSymptom"
      >
        {{ 'symptominstance.symptomIllness' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.whichOtherSymptom
            | textNa: verified?.whichOtherSymptom:'':true
        "
      ></div>
    </div>
    <div class="p-d-flex p-col-12 p-jc-between" *ngIf="showIntensity">
      <span
        id="lbl_Intensity"
        name="lbl_verifiedIntensity"
        for="verifiedIntensity"
      >
        {{ 'symptominstance.intensity' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.intensity | textNa: verified?.intensity:'intensity':true
        "
      ></div>
    </div>

    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="showProfessionalHealthCare"
    >
      <span
        id="lbl_verifiedProfessionalHealthCare"
        name="lbl_verifiedProfessionalHealthCare"
        for="verifiedProfessionalHealthCare"
      >
        {{ 'otherSymptom.professionalHealthCare' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.professionalHealthCare
            | textNa: verified?.professionalHealthCare:'translateRVP':true
        "
      ></div>
    </div>
    <div class="p-d-flex p-col-12 p-jc-between" *ngIf="showHospitalAdmission">
      <span
        id="lbl_verifiedHospitalAdmission"
        name="lbl_verifiedHospitalAdmission"
        for="verifiedHospitalAdmission"
      >
        {{ 'otherSymptom.admisionHours' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.hospitalAdmission
            | textNa: verified?.hospitalAdmission:'translateRVP':true
        "
      ></div>
    </div>
    <div class="p-d-flex p-col-12 p-jc-between" *ngIf="showRememberStartDate">
      <span
        id="lbl_verfiedRememberStartDate"
        name="lbl_verfiedRememberStartDate"
        for="verfiedRememberStartDate"
      >
        {{ 'symptominstance.startDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberStartDate
            | textNa: verified?.rememberStartDate:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        verified?.startDate ||
        original?.startDate ||
        verified?.rememberStartDate
      "
    >
      <span
        id="lbl_verifiedStartDate"
        name="lbl_verifiedStartDate"
        for="verifiedStartDate"
      >
        {{ 'symptominstance.startDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.startDate | textNa: verified?.startDate:'date':true
        "
      ></div>
    </div>
    <div class="p-d-flex p-col-12 p-jc-between" *ngIf="showRememberFinishDate">
      <span
        id="lbl_verifiedRememberFinishDate"
        name="lbl_veriifiedRememberFinishDate"
        for="verifiedRememberFinishDate"
      >
        {{ 'symptominstance.endDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberFinishDate
            | textNa: verified?.rememberFinishDate:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        original?.finishDate ||
        verified?.finishDate ||
        verified?.rememberFinishDate
      "
    >
      <span
        id="lbl_verifiedFinishDate"
        name="lbl_verifiedFinishDate"
        for="verifiedFinishDate"
      >
        {{ 'symptominstance.endDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.finishDate | textNa: verified?.finishDate:'date':true
        "
      ></div>
    </div>
  </div>
</div>

<div class="p-d-flex p-jc-end">
  <button
    pButton
    type="button"
    (click)="onClose(false)"
    label="{{ 'general.cancel' | translate }}"
    icon="pi pi-times"
    class="p-mx-2"
  ></button>
  <button
    pButton
    type="button"
    (click)="onClose(true)"
    label="{{ 'general.confirm' | translate }}"
    icon="pi pi-check"
    class="p-button-secondary"
  ></button>
</div>
