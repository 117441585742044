import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subscription, interval, map } from 'rxjs';
import { RolesService } from '../modules/roles/roles.service';
import { UserService } from '../modules/user/user.service';
import { Roles } from '../shared/global.variables';
import { AuthService } from './auth.service';
import { TrialpalService } from './trialpal.service';

@Injectable()
export class UserPermissionsService {
  private isInitialized = false;
  private initializationPromise: Promise<void>; // Para validar que se haya cargado los recursos necesarios

  userRoles: any[] = [];
  userCognitoPermissions: any[] = [];
  userPermissions: any[] = [];
  userAuthenticatedSubscriptions!: Subscription;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private trialpalService: TrialpalService,
    private confirmationService: ConfirmationService,
    public readonly userService: UserService,
    private rolesService: RolesService
  ) {
    this.initializationPromise = this.initialize();
  }

  async hasPermission(
    permissions: string[],
    showMessage: boolean = false
  ): Promise<boolean> {
    await this.ensureInitialized();

    if (this.userAuthenticatedSubscriptions?.closed) {
      this.initUserAuthenticatedSubscriptions();
    }

    return this.isAuthorized(permissions, showMessage);
  }

  private isAuthorized(permissions: string[], showMessage: boolean = false) {
    if (
      this.isAdmin(permissions) ||
      this.isInvestigator(permissions) ||
      this.IsReader(permissions)
    ) {
      return true;
    }

    const storedProject = localStorage.getItem('currentProject') ?? '{}';
    const projectId = JSON.parse(storedProject)?.id;
    const hasProjectPermission = this.hasPermissionForProject(
      permissions,
      projectId
    );

    if (!hasProjectPermission && showMessage) {
      this.showUnauthorizedMessage();
    }

    return hasProjectPermission;
  }

  private isAdmin(permissions: string[]): boolean {
    return permissions.includes(Roles.Admin) && this.authService.isAdmin();
  }

  private isInvestigator(permissions: string[]): boolean {
    return (
      permissions.includes(Roles.Investigator) &&
      this.authService.isInvestigator()
    );
  }

  private IsReader(permissions: string[]): boolean {
    return permissions.includes(Roles.Reader) && this.authService.isReader();
  }

  private hasPermissionForProject(roles: string[], projectId: string): boolean {
    // Buscar permisos con isForProjects en true y projectId en projects
    const hasProjectSpecificPermission = this.userPermissions.some(
      (permission: any) =>
        permission.isEnabled &&
        permission.isForProjects &&
        permission.projects.includes(projectId) &&
        roles.includes(permission.permission)
    );

    if (hasProjectSpecificPermission) {
      return true;
    }

    // Si no se encuentra, buscar permisos con isForProjects en false
    return this.userPermissions.some(
      (permission: any) =>
        permission.isEnabled &&
        !permission.isForProjects &&
        roles.includes(permission.permission)
    );
  }

  async initialize() {
    try {
      await this.getUserCognitoPermissions();
      this.initUserAuthenticatedSubscriptions();
      await this.getUserRoles();
      this.setPermissions();
      this.isInitialized = true;
    } catch (error) {
      console.error('error initializing permissions', error);
    }
  }

  public async ensureInitialized() {
    if (!this.isInitialized) {
      await this.initializationPromise;
    }
  }

  private async getUserCognitoPermissions() {
    try {
      const user = await this.getCurrentAuthenticatedUser();
      this.userCognitoPermissions =
        user.signInUserSession.accessToken.payload['cognito:groups'];
    } catch (error) {
      console.error('error initializing getUserCognitoPermissions', error);
    }
  }

  private async setPermissions() {
    if (!this.userRoles) return;

    this.userPermissions = this.userRoles.flatMap((role) =>
      role.permissions.map((permission: any) => ({
        isForProjects: role.isForProjects,
        permission: permission,
        projects: role.projects,
        isEnabled: role.isEnabled,
      }))
    );
  }

  private async getUserRoles() {
    let username =
      sessionStorage.getItem('username') ?? this.authService?.user?.username;
    while (!username) {
      await this.sleep(100); // Pausar 100ms antes de volver a verificar
      username =
        sessionStorage.getItem('username') ?? this.authService?.user?.username;
    }
    const user = await this.userService.getUserByLogin(username);
    if (user?.roles) {
      for (const roleId of user.roles) {
        const role = await this.rolesService.getRoleById(roleId);
        this.userRoles.push(role);
      }
    }
  }
  private sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private initUserAuthenticatedSubscriptions() {
    this.userAuthenticatedSubscriptions = this.getAuthenticatedUser().subscribe(
      (promise) => {
        promise.then((response: any) => {
          const updateRoles =
            response.signInUserSession.accessToken.payload['cognito:groups'];
          const areEqualLength =
            updateRoles.length === this.userCognitoPermissions.length;
          const areEqual =
            areEqualLength &&
            updateRoles.every((value: string) =>
              this.userCognitoPermissions.includes(value)
            );
          if (!areEqual && this.userCognitoPermissions.length > 0) {
            this.invokeConfirmationService();
          }
          if (this.userCognitoPermissions.length === 0) {
            this.getUserCognitoPermissions();
          }
        });
      }
    );
  }

  unsusbcribeUserAuth(): void {
    this.userCognitoPermissions = [];
    this.userRoles = [];
    this.userPermissions = [];
    this.isInitialized = false;
    this.userAuthenticatedSubscriptions?.unsubscribe();
  }

  private invokeConfirmationService() {
    this.trialpalService.closable = false;
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'roles.updatePageForPermissions'
      ),
      rejectVisible: false,
      closeOnEscape: false,
      accept: async () => {
        this.unsusbcribeUserAuth();
        this.trialpalService.closable = true;
        window.location.reload();
      },
    });
  }

  getAuthenticatedUser(): Observable<any> {
    return interval(5000).pipe(map(() => this.getCurrentAuthenticatedUser()));
  }

  getCurrentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  showUnauthorizedMessage() {
    this.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant('general.error'),
      detail: this.trialpalService.translateService.instant(
        'general.userNotAuthorized'
      ),
    });
    this.authService.navigateHome().then();
  }

  /**
   * Devuelve un array de permisos que están excluidos para el rol de Lector
   * Estos permisos no están disponibles para los usuarios con el rol de Lector.
   *
   * @returns {any[]} Una array de permisos que representan los permisos excluidos.
   */
  excludedReaderPermissions(): any[] {
    return [
      TP2Permission.UserCreate,
      TP2Permission.UserUpdate,
      TP2Permission.UserEnableDisable,
      TP2Permission.UserDelete,
      TP2Permission.UserList,
      TP2Permission.UserResetPassword,
    ];
  }
}

export enum TP2PermissionActive {
  UserCreate = 'UserCreate',
  UserUpdate = 'UserUpdate',
  UserEnableDisable = 'UserEnableDisable',
  UserDelete = 'UserDelete',
  UserList = 'UserList',
  UserResetPassword = 'UserResetPassword',
  DownloadTableData = 'DownloadTableData',
  CompleteVisit = 'CompleteVisit',
  RecompleteVisit = 'RecompleteVisit',
  SubjectAudit = 'SubjectAudit',
  SubjectCreate = 'SubjectCreate',
  SubjectDelete = 'SubjectDelete',
  SubjectDiaryGeneratePDF = 'SubjectDiaryGeneratePDF',
  SubjectDiaryPDF = 'SubjectDiaryPDF',
  SubjectEnableDisableReport = 'SubjectEnableDisableReport',
  SubjectList = 'SubjectList',
  SubjectRead = 'SubjectRead',
  SubjectSuspendVisit = 'SubjectSuspendVisit',
  SubjectDiaryAssesment = 'SubjectDiaryAssesment',
  SubjectGenerateReportPDF = 'SubjectGenerateReportPDF',
  SubjectExecuteUpdateReport = 'SubjectExecuteUpdateReport',
  SubjectReportPDF = 'SubjectReportPDF',
  SubjectDiaryAssessmentPDF = 'SubjectDiaryAssessmentPDF',
  SubjectUpdate = 'SubjectUpdate',
  DiaryCreate = 'DiaryCreate',
  DiarySymptomCreate = 'DiarySymptomCreate',
  DiarySymptomUpdate = 'DiarySymptomUpdate',
  DiarySymptomDelete = 'DiarySymptomDelete',
  DiaryFormCreate = 'DiaryFormCreate',
  DiaryFormUpdate = 'DiaryFormUpdate',
  DiaryFormDelete = 'DiaryFormDelete',
  DiaryDayCreate = 'DiaryDayCreate',
  DiaryDayDelete = 'DiaryDayDelete',
  ReportCreate = 'ReportCreate',
  ReportList = 'ReportList',
  ReportDelete = 'ReportDelete',
  ReportUpdate = 'ReportUpdate',
}

export enum TP2Permission {
  UserCreate = 'UserCreate',
  UserUpdate = 'UserUpdate',
  UserEnableDisable = 'UserEnableDisable',
  UserDelete = 'UserDelete',
  UserList = 'UserList',
  UserResetPassword = 'UserResetPassword',
  ProjectList = 'ProjectList',
  ProjectCreate = 'ProjectCreate',
  ProjectRead = 'ProjectRead',
  ProjectStatus = 'ProjectStatus',
  ProjectCreateGroupVisit = 'ProjectCreateGroupVisit',
  ProjectReadGroupVisit = 'ProjectReadGroupVisit',
  ProjectConfigVisitVisibility = 'ProjectConfigVisitVisibility',
  ProjectDeleteGroupVisit = 'ProjectDeleteGroupVisit',
  ReportCreate = 'ReportCreate',
  ReportList = 'ReportList',
  ReportDetail = 'ReportDetail',
  ReportDelete = 'ReportDelete',
  ReportUpdate = 'ReportUpdate',
  ReportConfSymptomCreateUpdate = 'ReportConfSymptomCreateUpdate',
  ReportConfDevicesCreateUpdate = 'ReportConfDevicesCreateUpdate',
  ReportRemindersCreateUpdate = 'ReportRemindersCreateUpdate',
  ReportConfMultimediaCreateUpdate = 'ReportConfMultimediaCreateUpdate',
  ReportJSONFormCreateUpdate = 'ReportJSONFormCreateUpdate',
  ReportSymptomDelete = 'ReportSymptomDelete',
  ReportAlertCreateUpdate = 'ReportAlertCreateUpdate',
  ReportAlertDelete = 'ReportAlertDelete',
  DiaryCreate = 'DiaryCreate',
  DiaryRead = 'DiaryRead',
  DiarySymptomCreate = 'DiarySymptomCreate',
  DiarySymptomUpdate = 'DiarySymptomUpdate',
  DiarySymptomDelete = 'DiarySymptomDelete',
  DiaryFormCreate = 'DiaryFormCreate',
  DiaryFormUpdate = 'DiaryFormUpdate',
  DiaryFormDelete = 'DiaryFormDelete',
  DiaryDayCreate = 'DiaryDayCreate',
  DiaryDayDelete = 'DiaryDayDelete',
  DiaryAlertCreate = 'DiaryAlertCreate',
  DiaryAlertDelete = 'DiaryAlertDelete',
  FollowupReports = 'FollowupReports',
  FollowupDiary = 'FollowupDiary',
  SubjectAudit = 'SubjectAudit',
  SubjectCreate = 'SubjectCreate',
  SubjectUpdate = 'SubjectUpdate',
  SubjectList = 'SubjectList',
  SubjectDelete = 'SubjectDelete',
  SubjectRead = 'SubjectRead',
  SubjectCompleteVisit = 'SubjectCompleteVisit',
  SubjectSuspendVisit = 'SubjectSuspendVisit',
  SubjectEnableDisableReport = 'SubjectEnableDisableReport',
  SubjectReportPDF = 'SubjectReportPDF',
  SubjectDiaryAssesment = 'SubjectDiaryAssesment',
  SubjectGenerateReportPDF = 'SubjectGenerateReportPDF',
  SubjectExecuteUpdateReport = 'SubjectExecuteUpdateReport',
  SiteCreate = 'SiteCreate',
  SiteDelete = 'SiteDelete',
  SiteList = 'SiteList',
  VersionRead = 'VersionRead',
  DownloadTableData = 'DownloadTableData',
  CompleteVisit = 'CompleteVisit',
  RecompleteVisit = 'RecompleteVisit',
  SubjectDiaryGeneratePDF = 'SubjectDiaryGeneratePDF',
  SubjectDiaryPDF = 'SubjectDiaryPDF',
  SubjectDiaryAssessmentPDF = 'SubjectDiaryAssessmentPDF',
}
