import { Component, Input, OnInit } from '@angular/core';
import { IntensityReconciliationOption } from 'src/app/modules/conciliation/conciliation.types';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { ConfForm } from 'src/app/modules/ediary/ediary.types';
import { SubjectsService } from 'src/app/modules/subjects/subjects.service';
import { TrialpalService } from 'src/app/services/trialpal.service';

@Component({
  selector: 'app-reconciliation-section-detail',
  templateUrl: './reconciliation-section-detail.component.html',
  styleUrls: ['./reconciliation-section-detail.component.scss'],
})
export class ReconciliationSectionDetailComponent implements OnInit {
  @Input() confForm!: ConfForm;
  @Input() readMode!: boolean;
  @Input()
  section!:
    | 'symptoms'
    | 'localSymptoms'
    | 'temperature'
    | 'medication'
    | 'medical-attention'
    | 'other-symptoms';
  reconciliationConfig!: any;
  intensityOptions: { label: any; value: boolean }[] = [];
  sectionReconciliationConfig: ReconciliationConfigSection = {
    enableAddingNewRecords: true,
    enableRemovingRecords: true,
    enableDatesReconciliation: false,
    enableRouteReconciliation: false,
    enableDosisReconciliation: false,
    enableHospitalizationReconciliation: false,
    enableSymptomOccurencyReconciliation: false,
    enableTreatmentReconciliation: false,
    enableReasonReconciliation: false,
  };
  constructor(
    private trialpalService: TrialpalService,
    private ediaryService: EdiaryService,
    private subjectsService: SubjectsService
  ) {}
  async ngOnInit(): Promise<void> {
    switch (this.section) {
      case 'symptoms':
        this.setIntensityOptions('symptoms');
        this.setSymptomsLabels();
        await this.setSymptomsConfig();
        this.reconciliationConfig = this.confForm.symptomsReconciliationConfig;
        break;
      case 'localSymptoms':
        this.setIntensityOptions('symptoms');
        this.setSymptomsLabels();
        await this.setSymptomsConfig();
        this.reconciliationConfig =
          this.confForm.localSymptomsReconciliationConfig;
        break;
      case 'temperature':
        this.reconciliationConfig =
          this.confForm.temperatureReconciliationConfig;
        this.setTemperatureLabels();
        this.setIntensityOptions('temperature');
        await this.setTemperatureConfig();
        break;
      case 'medication':
        this.reconciliationConfig =
          this.confForm.medicationReconciliationConfig;
        this.setMedicationLabels();
        await this.setMedicationConfig();
        break;
      case 'medical-attention':
        this.reconciliationConfig =
          this.confForm.medicalAttentionReconciliationConfig;
        this.setMedicalAttentionLabels();
        await this.setMedicalAttentionConfig();
        break;
      case 'other-symptoms':
        this.reconciliationConfig =
          this.confForm.otherSymptomsReconciliationConfig;
        this.setIntensityOptions('otherSymptoms');
        this.setOtherSymptomsLabels();
        this.setOtherSymptomsConfig();
        break;
    }
  }
  setIntensityOptions(section: SectionType) {
    this.intensityOptions = this.trialpalService
      .getTranslatedEnum(
        `general.configurations.reconciliation.${section}.intensityOptions`,
        IntensityReconciliationOption
      )
      .map((type) => ({
        value: type.value,
        label: type.name,
      }));
  }
  setCommondLabels(section: SectionType) {
    this.sectionReconciliationConfig.labelAddingNewRecords =
      this.trialpalService.translateService.instant(
        `general.configurations.reconciliation.${section}.labelAllowAddingNewRecords`
      );
    this.sectionReconciliationConfig.labelRemovingRecords =
      this.trialpalService.translateService.instant(
        `general.configurations.reconciliation.${section}.labelAllowRemovingNewRecords`
      );
    this.sectionReconciliationConfig.labelDatesReconciliation =
      this.trialpalService.translateService.instant(
        `general.configurations.reconciliation.${section}.labelAllowDatesReconciliation`
      );
  }
  setSymptomsLabels() {
    this.setCommondLabels('symptoms');
    this.sectionReconciliationConfig.labelIntensityReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.symptoms.labelAllowIntensityReconciliation'
      );
    this.sectionReconciliationConfig.labelIntensityReconciliationOptions =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.symptoms.labelIntensityReconciliationOptions'
      );
  }
  async setSymptomsConfig() {
    this.sectionReconciliationConfig.enableDatesReconciliation = true;
    this.sectionReconciliationConfig.enableIntensityReconciliation = true;
  }
  setTemperatureLabels() {
    this.setCommondLabels('temperature');
    this.sectionReconciliationConfig.labelIntensityReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.temperature.labelAllowIntensityReconciliation'
      );
    this.sectionReconciliationConfig.labelIntensityReconciliationOptions =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.temperature.labelIntensityReconciliationOptions'
      );
    this.sectionReconciliationConfig.labelRouteReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.temperature.labelAllowRouteReconciliation'
      );
  }
  async setTemperatureConfig() {
    if (this.confForm) {
      this.sectionReconciliationConfig.enableIntensityReconciliation = true;
      try {
        const confTemperature =
          await this.ediaryService.getConfTemperatureByByFormId(
            this.confForm.id
          );

        if (confTemperature.items && confTemperature?.items.length > 0) {
          this.sectionReconciliationConfig.enableDatesReconciliation =
            confTemperature.items[0]?.showTemperatureTakenDate ?? false;
          // if dates are not requested to subjects, set config in false
          if (!this.sectionReconciliationConfig.enableDatesReconciliation) {
            this.reconciliationConfig.allowOcurencyDateReconciliation = false;
          }
          this.sectionReconciliationConfig.enableRouteReconciliation =
            confTemperature.items[0]?.showTemperatureRoute ?? false;
          // if route is not requested to subjects, set config in false
          if (!this.sectionReconciliationConfig.enableRouteReconciliation) {
            this.reconciliationConfig.allowOcurencyDateReconciliation = false;
          }
        }
      } catch (err) {
        this.trialpalService.errorOperationMessage();
      }
    }
  }

  setMedicationLabels() {
    this.setCommondLabels('medication');
    this.sectionReconciliationConfig.labelDosisReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medication.labelAllowDosisReconciliation'
      );
    this.sectionReconciliationConfig.labelRouteReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medication.labelAllowRouteReconciliation'
      );
    this.sectionReconciliationConfig.labelTreatmentReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medication.labelAllowTreatmentReconciliation'
      );
    this.sectionReconciliationConfig.labelReasonReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medication.labelAllowReasonReconciliation'
      );
  }
  /**
   * The function `setMedicationConfig` sets configuration options for medication reconciliation based
   * on data retrieved from a service.
   */
  async setMedicationConfig() {
    this.sectionReconciliationConfig.enableReasonReconciliation = true;
    this.sectionReconciliationConfig.enableTreatmentReconciliation = true;
    if (this.confForm) {
      try {
        const confMedication =
          await this.subjectsService.confMedicationByConfForm(this.confForm.id);

        const medicaltionItem = confMedication.items?.[0];

        if (medicaltionItem) {
          this.sectionReconciliationConfig.enableDatesReconciliation =
            (medicaltionItem.showRememberStartDate ||
              medicaltionItem.showRememberFinishDate) ??
            false;

          this.sectionReconciliationConfig.enableDosisReconciliation =
            !!medicaltionItem.showDose;

          this.sectionReconciliationConfig.enableRouteReconciliation =
            !!medicaltionItem.showAdministrationRoute;

          // if dates are not requested to subjects, set config in false
          if (!this.sectionReconciliationConfig.enableDatesReconciliation) {
            this.reconciliationConfig.allowOcurencyDateReconciliation = false;
          }
          // if dose is not requested to subjects, set config in false
          if (!this.sectionReconciliationConfig.enableDosisReconciliation) {
            this.reconciliationConfig.allowDosisReconciliation = false;
          }
          // if route is not requested to subjects, set config in false
          if (!this.sectionReconciliationConfig.enableRouteReconciliation) {
            this.reconciliationConfig.allowRouteReconciliation = false;
          }
        }
      } catch (err) {
        this.trialpalService.errorOperationMessage();
      }
    }
  }

  setMedicalAttentionLabels() {
    this.setCommondLabels('medicalAttention');
    this.sectionReconciliationConfig.labelTypeReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medicalAttention.labelAllowTypeReconciliation'
      );
    this.sectionReconciliationConfig.labelSymptomOccurencyReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medicalAttention.labelAllowSymptomOccurencyReconciliation'
      );
    this.sectionReconciliationConfig.labelHospitalizationReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medicalAttention.labelAllowHospitalizationReconciliation'
      );
    this.sectionReconciliationConfig.labelReasonReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.medicalAttention.labelAllowReasonReconciliation'
      );
  }
  /**
   * Configures the reconciliation settings for the "medical attention" section based on the provided configuration form.
   * Fetches the relevant configuration data and updates the reconciliation settings accordingly.
   */
  async setMedicalAttentionConfig(): Promise<void> {
    if (!this.confForm) {
      return;
    }

    try {
      const confMedicalAttention =
        await this.subjectsService.ConfMedicalAttentionByConfForm(
          this.confForm.id
        );

      const medicalAttentionItem = confMedicalAttention.items?.[0];

      if (medicalAttentionItem) {
        this.sectionReconciliationConfig.enableDatesReconciliation = !!(
          medicalAttentionItem.showRememberStartDate ||
          medicalAttentionItem.showRememberFinishDate
        );
        this.sectionReconciliationConfig.enableTypeReconciliation =
          !!medicalAttentionItem.showType;
        this.sectionReconciliationConfig.enableHospitalizationReconciliation =
          !!medicalAttentionItem.showHospitalization;
        this.sectionReconciliationConfig.enableSymptomOccurencyReconciliation =
          medicalAttentionItem.mode === 'SYMPTOM_BINDING';
        this.sectionReconciliationConfig.enableReasonReconciliation =
          medicalAttentionItem.mode === 'OPEN_REASON';

        if (!this.sectionReconciliationConfig.enableDatesReconciliation) {
          this.reconciliationConfig.allowOcurencyDateReconciliation = false;
        }
        if (!this.sectionReconciliationConfig.enableTypeReconciliation) {
          this.reconciliationConfig.allowTypeReconciliation = false;
        }
        if (
          !this.sectionReconciliationConfig.enableHospitalizationReconciliation
        ) {
          this.reconciliationConfig.allowHospitalizationReconciliation = false;
        }
        if (!this.sectionReconciliationConfig.enableReasonReconciliation) {
          this.reconciliationConfig.allowReasonReconciliation = false;
        }
        if (
          !this.sectionReconciliationConfig.enableSymptomOccurencyReconciliation
        ) {
          this.reconciliationConfig.allowSymptomOccurencyReconciliation = false;
        }
      }
    } catch (err) {
      this.trialpalService.errorOperationMessage();
    }
  }
  setOtherSymptomsLabels() {
    this.setCommondLabels('otherSymptoms');
    this.sectionReconciliationConfig.labelHospitalizationReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.otherSymptoms.labelAllowHospitalizationReconciliation'
      );
    this.sectionReconciliationConfig.labelIntensityReconciliation =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.otherSymptoms.labelAllowIntensityReconciliation'
      );
    this.sectionReconciliationConfig.labelIntensityReconciliationOptions =
      this.trialpalService.translateService.instant(
        'general.configurations.reconciliation.otherSymptoms.labelIntensityReconciliationOptions'
      );
  }
  /**
   * Configures the reconciliation settings for the "other symptoms" section based on the provided configuration form.
   * Fetches the configuration data from the ediaryService and updates the reconciliation settings accordingly.
   */
  async setOtherSymptomsConfig() {
    if (!this.confForm) {
      return;
    }

    try {
      const confOtherSymptoms =
        await this.ediaryService.getConfOtherSymtompByByFormId(
          this.confForm.id
        );

      const firstItem = confOtherSymptoms.items?.[0];

      if (firstItem) {
        this.sectionReconciliationConfig.enableDatesReconciliation =
          (firstItem.showRememberStartDate ||
            firstItem.showRememberFinishDate) ??
          false;
        this.sectionReconciliationConfig.enableHospitalizationReconciliation =
          firstItem.showProfessionalHealthCare ?? false;
        this.sectionReconciliationConfig.enableIntensityReconciliation =
          firstItem.showIntensity ?? false;

        if (!this.sectionReconciliationConfig.enableDatesReconciliation) {
          this.reconciliationConfig.allowOcurencyDateReconciliation = false;
        }

        if (
          !this.sectionReconciliationConfig.enableHospitalizationReconciliation
        ) {
          this.reconciliationConfig.allowHospitalizationReconciliation = false;
        }

        if (!this.sectionReconciliationConfig.enableIntensityReconciliation) {
          this.reconciliationConfig.allowIntensityReconciliation = {
            allow: false,
          };
        }
      }
    } catch (err) {
      this.trialpalService.errorOperationMessage();
    }
  }
}
interface BaseReconciliationConfig {
  enableAddingNewRecords: boolean;
  labelAddingNewRecords?: string;
  enableRemovingRecords: boolean;
  labelRemovingRecords?: string;
  enableDatesReconciliation: boolean;
  labelDatesReconciliation?: string;
}
export interface ReconciliationConfigSection extends BaseReconciliationConfig {
  enableIntensityReconciliation?: boolean;
  labelIntensityReconciliation?: string;
  labelIntensityReconciliationOptions?: string;
  enableRouteReconciliation?: boolean;
  labelRouteReconciliation?: string;
  enableSymptomOccurencyReconciliation?: boolean;
  labelSymptomOccurencyReconciliation?: string;
  enableHospitalizationReconciliation?: boolean;
  labelHospitalizationReconciliation?: string;
  enableReasonReconciliation?: boolean;
  labelReasonReconciliation?: string;
  enableTreatmentReconciliation?: boolean;
  labelTreatmentReconciliation?: string;
  enableDosisReconciliation?: boolean;
  labelDosisReconciliation?: string;
  enableTypeReconciliation?: boolean;
  labelTypeReconciliation?: string;
}
export type SectionType =
  | 'symptoms'
  | 'temperature'
  | 'medicalAttention'
  | 'medication'
  | 'otherSymptoms';
