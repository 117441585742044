import { Injectable } from '@angular/core';
import { AuthService } from '../../../../../services/auth.service';
import { CONCILIATION_QUERIES } from '../../../conciliation.queries';
import { ConciliationService } from '../../../conciliation.service';
import {
  ConfMedication,
  CreateVerifiedMedicationInstanceInput,
  DayInstance,
  DeleteVerifiedMedicationInstanceInput,
  InstanceState,
  MedicationConfigurationMode,
  UpdateVerifiedMedicationInstanceInput,
  VerifiedMedicationInstance,
} from '../../../conciliation.types';
import { DayInstanceService } from '../shared/day-instance/day-instance.service';
import { ConfForm } from 'src/app/modules/ediary/ediary.types';
import { SymptomService } from '../symptom/symptom.service';
@Injectable({
  providedIn: 'root',
})
export class MedicationService {
  //Modos de configuración
  individualGivenToSymptomBinding: MedicationConfigurationMode =
    MedicationConfigurationMode.INDIVIDUAL_GIVEN_TO_SYMPTOM_BINDING;
  individualGivenToOpenReason: MedicationConfigurationMode =
    MedicationConfigurationMode.INDIVIDUAL_GIVEN_TO_OPEN_REASON;
  generalGivenToOpenReason: MedicationConfigurationMode =
    MedicationConfigurationMode.GENERAL_GIVEN_TO_OPEN_REASON;
  generalGivenToSymptomBinding: MedicationConfigurationMode =
    MedicationConfigurationMode.GENERAL_GIVEN_TO_SYMPTOM_BINDING;
  noGivenToOpenReason: MedicationConfigurationMode =
    MedicationConfigurationMode.NO_GIVEN_TO_OPEN_REASON;
  noGivenToSymptomBinding: MedicationConfigurationMode =
    MedicationConfigurationMode.NO_GIVEN_TO_SYMPTOM_BINDING;

  medicationComments: any[] = [];
  commentsDay: any[] = [];
  medicationColDays: any[] = [];
  order: any[] = [
    'nameComment',
    'doseComment',
    'givenToComment',
    'reasonComment',
    'symptomsInstancesComment',
    'administrationRouteComment',
    'frequencyComment',
    'startDateComment',
    'finishDateComment',
  ];
  orderDays: any[] = ['hasMedicationComment', 'medicationGivenToComment'];

  //Objetos para seleccionar medicamentos
  tableMedications: MedicationData[] = [];
  currentMedication: any = {};
  //Objetos que representan los medicamentos
  confMedications: ConfMedication[] = [];
  medicationDays: any[] = [];
  //Medicamentos creados
  /* Un booleano que indica si el medicamento es nuevo o no. */
  isNewMedicationInstance: boolean = false;

  constructor(
    private authService: AuthService,
    private conciliationService: ConciliationService,
    private dayInstanceService: DayInstanceService,
    private symptomService: SymptomService
  ) {}

  getProgressMedicationValues() {
    const tamMedications = this.tableMedications?.length || 0;
    const tamMedicationColDays = this.medicationColDays?.length - 1 || 0;

    const tamVerifiedMedications =
      this.tableMedications.filter((medication: any) => {
        return medication.verifiedMedication?.id;
      })?.length || 0;

    const tamVerifiedMedicationColDays =
      this.medicationColDays.filter(
        (day: any) => day.verifiedDayInstance?.hasMedicationCompleted
      )?.length || 0;

    return [
      tamMedications,
      tamMedicationColDays,
      tamVerifiedMedications,
      tamVerifiedMedicationColDays,
    ];
  }

  getProgressMedication() {
    let [
      tamMedications,
      tamMedicationColDays,
      tamVerifiedMedications,
      tamVerifiedMedicationColDays,
    ] = this.getProgressMedicationValues();
    const progress =
      ((tamVerifiedMedications + tamVerifiedMedicationColDays) /
        (tamMedications + tamMedicationColDays)) *
      100;
    return tamMedications + tamMedicationColDays === 0 ? 100 : progress;
  }

  //================== OBTENCIÓN DE DATOS EN LA CONCILIACIÓN DE MEDICAMENTOS =============================
  async getVerifiedMedicationInstancesByMedicationId(
    medicationInstanceId: string
  ): Promise<any[]> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.VerifiedMedicationInstanceByMedicationId,
      { medicationInstanceId }
    );
    const { items } = response.data.verifiedMedicationInstanceByMedicationId;
    const verifiedMedications = items?.filter((verifiedMedication: any) => {
      return (
        !verifiedMedication._deleted &&
        verifiedMedication.state !== InstanceState.CONCILIATION_DELETED
      );
    });
    return verifiedMedications || [];
  }

  async getConfMedicationByConfFormId(confFormId: string) {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfMedicationByConfForm,
      { confFormId }
    );
    const { items } = response.data.confMedicationByConfForm;
    return items[0];
  }

  async getConfMedicationByConfEdiaryId() {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfMedicationByConfEdiaryIdCustom,
      { confEdiaryId: this.conciliationService.confEdiaryId }
    );
    const { items } = response.data.confMedicationByConfEdiary;
    this.confMedications = items || [];
    return items;
  }

  //================== MUTACIÓN DE DATOS EN LA CONCILIACIÓN DE MEDICAMENTOS =============================
  async createVerifiedMedication(data: any, medication: any) {
    this.conciliationService.transformDate(data);

    //Obtiene el dia verificado
    const verifiedDayInstance =
      await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
        medication.dayInstanceId
      );

    let input: CreateVerifiedMedicationInstanceInput = {
      medicationInstanceId: medication.id,
      administrationRoute: medication?.administrationRoute,
      dose: medication?.dose,
      finishDate: medication?.finishDate,
      frequency: medication?.frequency,
      givenTo: medication?.givenTo,
      isOnGoing: medication?.isOnGoing,
      name: medication?.name,
      reason: medication?.reason,
      rememberFinishDate: medication?.rememberFinishDate,
      rememberStartDate: medication?.rememberStartDate,
      startDate: medication?.startDate,
      state: InstanceState.UNSIGNED,
      symptomsInstances: medication?.symptomsInstances,
      verifiedDayInstanceId: verifiedDayInstance?.id,
      subjectId: medication.subjectId,
      _lastUser: this.authService.getUsername(),
    };

    input = Object.assign(input, data);
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateVerifiedMedicationInstance,
      { input }
    );
    return response.data.createVerifiedMedicationInstance;
  }

  async updateVerifiedMedication(data: any, verifiedMedication: any) {
    //Le agrega la hora a las fechas seleccionadas
    this.conciliationService.transformDate(data, verifiedMedication);
    let input: UpdateVerifiedMedicationInstanceInput = {
      id: verifiedMedication.id,
      subjectId: this.conciliationService.subjectId,
      state: InstanceState.UNSIGNED,
      _lastUser: this.authService.getUsername(),
      _changeReason: verifiedMedication._changeReason,
      _version: verifiedMedication._version,
    };

    input = Object.assign(input, data);
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateVerifiedMedicationInstance,
      { input }
    );
    return response.data.updateVerifiedMedicationInstance;
  }

  async deleteVerifiedMedicationInstance(verifiedMedicationInstance: any) {
    const input: DeleteVerifiedMedicationInstanceInput = {
      id: verifiedMedicationInstance.id,
      _version: verifiedMedicationInstance._version,
    };
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.DeleteVerifiedMedicationInstance,
      { input }
    );
    return response.data.deleteVerifiedMedicationInstance;
  }

  //=================== Metodos para verificaciones ============================

  isReasonEnabled(configuration: any) {
    return (
      configuration?.mode === this.individualGivenToOpenReason ||
      configuration?.mode === this.generalGivenToOpenReason ||
      configuration?.mode === this.noGivenToOpenReason
    );
  }

  isSymptomsEnabled(configuration: any) {
    return (
      configuration?.mode === this.individualGivenToSymptomBinding ||
      configuration?.mode === this.generalGivenToSymptomBinding ||
      configuration?.mode === this.noGivenToSymptomBinding
    );
  }

  //===================== Comentarios ===================
  addMedicationComments() {
    this.medicationComments = [];
    let indexComment = this.addDaysComments();
    this.tableMedications = this.tableMedications.map((medication: any) => {
      const verifiedMedication = medication?.verifiedMedication ?? {};
      const {
        nameComment,
        doseComment,
        givenToComment,
        reasonComment,
        symptomsInstancesComment,
        administrationRouteComment,
        frequencyComment,
        startDateComment,
        finishDateComment,
      } = verifiedMedication;
      medication.comments = {};
      //Valida si tiene comentarios
      if (nameComment) {
        medication.comments.nameComment = nameComment;
        medication.comments.nameCommentIndex = indexComment;
        this.addMedicationObservation(nameComment, indexComment);
        indexComment++;
      }

      if (doseComment) {
        medication.comments.doseComment = doseComment;
        medication.comments.doseCommentIndex = indexComment;
        this.addMedicationObservation(doseComment, indexComment);
        indexComment++;
      }

      if (givenToComment) {
        medication.comments.givenToComment = givenToComment;
        medication.comments.givenToCommentIndex = indexComment;
        this.addMedicationObservation(givenToComment, indexComment);
        indexComment++;
      }

      if (reasonComment) {
        medication.comments.reasonComment = reasonComment;
        medication.comments.reasonCommentIndex = indexComment;
        this.addMedicationObservation(reasonComment, indexComment);
        indexComment++;
      }

      if (symptomsInstancesComment) {
        medication.comments.symptomsInstancesComment = symptomsInstancesComment;
        medication.comments.symptomsInstancesCommentIndex = indexComment;
        this.addMedicationObservation(symptomsInstancesComment, indexComment);
        indexComment++;
      }

      if (administrationRouteComment) {
        medication.comments.administrationRouteComment =
          administrationRouteComment;
        medication.comments.administrationRouteCommentIndex = indexComment;
        this.addMedicationObservation(administrationRouteComment, indexComment);
        indexComment++;
      }

      if (frequencyComment) {
        medication.comments.frequencyComment = frequencyComment;
        medication.comments.frequencyCommentIndex = indexComment;
        this.addMedicationObservation(frequencyComment, indexComment);
        indexComment++;
      }

      if (startDateComment) {
        medication.comments.startDateComment = startDateComment;
        medication.comments.startDateCommentIndex = indexComment;
        this.addMedicationObservation(startDateComment, indexComment);
        indexComment++;
      }

      if (finishDateComment) {
        medication.comments.finishDateComment = finishDateComment;
        medication.comments.finishDateCommentIndex = indexComment;
        this.addMedicationObservation(finishDateComment, indexComment);
        indexComment++;
      }

      return medication;
    });
  }

  addDaysComments() {
    let indexComment = 1;
    for (const precondicion of this.medicationColDays) {
      const verifiedDayInstance = precondicion.verifiedDayInstance;
      if (verifiedDayInstance?.hasMedicationComment) {
        this.addMedicationObservation(
          verifiedDayInstance.hasMedicationComment,
          indexComment
        );
        indexComment++;
      }

      if (verifiedDayInstance?.medicationGivenToComment) {
        this.addMedicationObservation(
          verifiedDayInstance.medicationGivenToComment,
          indexComment
        );
        indexComment++;
      }
    }
    return indexComment;
  }

  addMedicationObservation(comment: string, indexComment: number) {
    if (comment.length > 0) {
      this.medicationComments.push({
        index: indexComment,
        comment: comment,
      });
    }
  }

  getMedicationInstanceEmpty(dayInstanceId: string) {
    return {
      administrationRoute: '',
      dayInstanceId: dayInstanceId,
      dose: '',
      finishDate: '',
      frequency: '',
      givenTo: [],
      id: this.conciliationService.NEW_INSTANCE_ID,
      isOnGoing: false,
      name: '',
      reason: '',
      rememberFinishDate: null,
      rememberStartDate: null,
      startDate: '',
      state: InstanceState.UNSIGNED,
      subjectId: this.conciliationService.subjectId,
      symptomsInstances: [],
    };
  }

  getMedicationDeleteData(deleteReason: string) {
    return {
      state: InstanceState.DELETED,
      nameComment: deleteReason,
      administrationRouteComment: null,
      doseComment: null,
      frequencyComment: null,
      givenToComment: null,
      isOnGoingComment: null,
      reasonComment: null,
      finishDateComment: null,
      startDateComment: null,
      symptomsInstancesComment: null,
      _changeReason: deleteReason,
    };
  }

  //Funcion que se encarga de pasar un medicamento a estado borrado o borrarlo de la base de datos
  async deleteVerifiedMedicationInstanceTable(
    medication: any,
    verifiedMedication: any,
    data: any
  ) {
    let isDelete: boolean = false;
    //Elimina el medicamento si es una instancia creada desde el site
    if (medication.id === this.conciliationService.NEW_INSTANCE_ID) {
      data.state = InstanceState.CONCILIATION_DELETED;
      //Elimina el medicamento de la tabla
      this.tableMedications = this.tableMedications.filter(
        (medication: any) => {
          return medication?.verifiedMedication?.id !== verifiedMedication?.id;
        }
      );
      isDelete = true;
    }
    verifiedMedication = await this.updateVerifiedMedication(
      data,
      verifiedMedication
    );

    return { verified: verifiedMedication, isDelete };
  }

  /** ==================== Revisa los medicamentos de forma automatica=================== */

  async reviewMedicationInstancesData(): Promise<void> {
    const medicationsInstances: MedicationData[] = this.tableMedications;
    const pendingMedicationInstances = medicationsInstances.filter(
      (medicationInstance: MedicationData) =>
        !medicationInstance.verifiedMedication
    );

    for (let medicationInstance of pendingMedicationInstances) {
      const verifiedMedicationInstance = await this.createVerifiedMedication(
        {},
        medicationInstance.medication
      );
      medicationInstance.verifiedMedication = verifiedMedicationInstance;
      medicationInstance.isEdition = true;
      medicationInstance.symptomsVerified = this.symptomService.getSymptoms(
        verifiedMedicationInstance?.symptomsInstances,
        medicationInstance.medication
      );
      this.updateMedicationTable(medicationInstance);
    }
  }

  updateMedicationTable(updateMedicationTable: MedicationData) {
    this.tableMedications = this.tableMedications.map(
      (currentMedicationTable: MedicationData) => {
        return currentMedicationTable.medication.id ===
          updateMedicationTable.medication.id
          ? updateMedicationTable
          : currentMedicationTable;
      }
    );
  }
}

export interface MedicationData {
  configuration: ConfMedication;
  dayInstance: DayInstance;
  confForm?: ConfForm | null;
  index: number;
  isEdition: boolean;
  medication: any;
  verifiedMedication?: VerifiedMedicationInstance;
  symptomsInstances: string;
  symptomsVerified: string;
  comments: any;
}
