import { TP2Permission } from 'src/app/services/user-permissions-service';

export type CreateRoleInput = {
  id?: string | null;
  name: string;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export enum InstanceState {
  PENDING = 'PENDING',
  MUST_UPDATE = 'MUST_UPDATE',
  ON_GOING = 'ON_GOING',
  NOT_SENDED = 'NOT_SENDED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  UNSIGNED = 'UNSIGNED',
  DELETED = 'DELETED',
  CONCILIATION_DELETED = 'CONCILIATION_DELETED',
  SUSPENDED = 'SUSPENDED',
  LOCALLY_SAVED = 'LOCALLY_SAVED',
  CONCILIATION_COMPLETED = 'CONCILIATION_COMPLETED',
}

export type UpdateRoleInput = {
  id: string;
  name?: string | null;
  isEnabled?: boolean | null;
  state?: string | null;
  permissions?: Array<TP2Permission | null> | null;
  isForProjects?: boolean | null;
  projects?: Array<string | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
