import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { SubjectsService } from '../../subjects.service';

@Component({
  selector: 'app-media-subject',
  templateUrl: './media-subject.component.html',
  styleUrls: ['./media-subject.component.scss'],
})
export class MediaSubjectComponent implements OnInit {
  @Input() timeZoneOffset = 0;
  @Output() hasMultimedia: EventEmitter<boolean> = new EventEmitter();
  url = '';
  subjectId = '';
  isVisible = false;
  showSpinner: boolean = true;
  dataCard: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private storage: StorageService,
    private subjectsService: SubjectsService,
    public sanitizer: DomSanitizer,
    private transformDatePipe: TransformDatePipe,
    private trialpalService: TrialpalService
  ) {}
  async ngOnInit(): Promise<void> {
    const mediaRecordLogs = [];
    try {
      this.showSpinner = true;
      this.subjectId = this.route.snapshot.params.subjectId;
      const mediaRecords = await this.subjectsService.mediaRecordLogBySubjectId(
        this.subjectId
      );
      if (mediaRecords.items) {
        mediaRecords.items = mediaRecords.items.filter(
          (media) => !media?._deleted
        );
        mediaRecords.items = this.subjectsService.orderInstancesDescending(
          mediaRecords.items
        );
        for (let media of mediaRecords.items) {
          if (media!.url && !media?._deleted) {
            await this.getUrl(media);
            mediaRecordLogs.push(media);
          }
        }
        this.buildCards(mediaRecordLogs);
      }
      this.hasMultimedia.emit(mediaRecordLogs.length > 0);
      this.showSpinner = false;
    } catch (exception) {
      this.buildCards(mediaRecordLogs);
      this.showSpinner = false;
    }
  }
  buildCards(medias: any) {
    this.dataCard = [];
    for (let media of medias) {
      this.dataCard.push(this.buildTPCardData(media));
    }
  }
  async getUrl(media: any) {
    const url = await this.storage.get(media.url);
    this.url = this.transformToSecureUrl(url);
    media.media = url;
    return media;
  }
  transformToSecureUrl(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  openModal(p: any) {
    this.url = p.media;
    this.isVisible = true;
  }
  buildTPCardData(res: any): TPCardData {
    const type = this.trialpalService.translateService.instant(
      'ediary.enums.mediaTypes' + '.' + res.mediaType
    );
    return {
      ...res,
      hideState: true,
      header: type,
      section2: this.transformDatePipe.transformToSiteHour(
        res.createdAt,
        this.timeZoneOffset
      ),
      footer: res.comment,
      image2: res.media,
      styleColorState: 'green',
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      cardIsButton: true,
      objectAction: res.name,
    };
  }
}
