<main class="container-tp2 p-pt-0" style="min-height: 100%">
  <p-fieldset
    class="p-col-12"
    legend="{{ 'informedConsent.audit.labelQuery' | translate }}"
  >
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="symptomLabel"
          >{{ 'informedConsent.audit.labelEntity' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <p-dropdown
          id="entity"
          name="entity"
          optionLabel="label"
          optionValue="value"
          [(ngModel)]="entity"
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="entityOptions"
          (ngModelChange)="getEntities()"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        >
        </p-dropdown>
      </div>
      <div
        *ngIf="!entity || entity === 'ConfInformedConsent'"
        class="p-col-12 p-md-6 p-field"
      >
        <label class="full-width" for="symptomLabel">{{
          'informedConsent.audit.enums.entity.InformedConsentInstance'
            | translate
        }}</label>
        <div class="p-inputAutocompleteButton">
          <p-autoComplete
            class="p-flex-1"
            [ngStyle]="{ width: '100%' }"
            #consentModel="ngModel"
            [dropdown]="true"
            id="consent"
            name="consent"
            type="text"
            field="name"
            [required]="true"
            [completeOnFocus]="true"
            [(ngModel)]="consent"
            [suggestions]="consentResults"
            (completeMethod)="search($event)"
          >
          </p-autoComplete>
        </div>
      </div>
      <div
        *ngIf="entity && !(entity === 'ConfInformedConsent')"
        class="p-col-12 p-md-6 p-field"
      >
        <label class="full-width" for="symptomLabel">{{
          'informedConsent.audit.labelICInstanceByParticipant' | translate
        }}</label>
        <div class="p-inputAutocompleteButton">
          <p-autoComplete
            class="p-flex-1"
            [ngStyle]="{ width: '100%' }"
            #consentModel="ngModel"
            [dropdown]="true"
            id="consent"
            name="consent"
            type="text"
            field="label"
            [(ngModel)]="consent"
            [group]="true"
            [suggestions]="filteredGroups"
            (completeMethod)="search($event)"
          >
            <ng-template let-group pTemplate="group">
              <div class="p-d-flex p-ai-center">
                <label
                  for="consent"
                  style="font-size: 1rem; margin-left: 8px"
                  >{{ group.label }}</label
                >
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <div
        class="
          p-col-12 p-md-3 p-md-offset-9 p-lg-2 p-lg-offset-10 p-pt-4 p-pb-0
        "
      >
        <button
          pButton
          type="button"
          id="btn_forward"
          class="p-button-primary p-button"
          (click)="onSubmit()"
          [disabled]="!entity || !consent"
          label="{{ 'informedConsent.audit.btnQuery' | translate }}"
        ></button>
      </div>
    </div>
  </p-fieldset>

  <div
    class="p-datatable-wrapper"
    id="audit_trace_econsent"
    *ngIf="auditTrace.length > 0"
  >
    <app-audit-info
      [auditTrace]="auditTrace"
      [multiEntity]="true"
    ></app-audit-info>
  </div>
</main>
