<label
  style="font-weight: 700; font-size: 1rem; word-break: break-word"
  class="p-mb-2 p-d-block"
  [for]="field.id ?? field.key"
>
  {{ to?.label ?? '' | dictionary }}
  <span *ngIf="to.required" style="color: red">*</span>
  <span
    *ngIf="to['tooltip']?.trim()?.length > 0"
    [pTooltip]="to['tooltip'] | dictionary"
    class="pi pi-question-circle p-ml-2 cursor-pointer"
    style="color: var(--primary-color)"
  ></span>
</label>

<ng-container #fieldComponent></ng-container>
