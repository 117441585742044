import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { DictionaryPipe } from './dictionary.pipe';
import { TemperatureUnit } from 'src/app/modules/ediary/ediary.types';

@Pipe({
  name: 'textNa',
})
export class TextNaPipe implements PipeTransform {
  constructor(
    private transformDatePipe: TransformDatePipe,
    private translateService: TranslateService,
    private dictionaryPipe: DictionaryPipe
  ) {}

  transform(
    original: any,
    verified: any,
    dataType?: string,
    isMessageDialog?: boolean,
    verifiedExist?: boolean,
    isPendingInstance?: boolean,
    isEdition?: boolean
  ): string {
    let html = '';

    //verifica si el campo original esta vacio
    const isValueEmpty = this.isEmptyValue(original);

    //Verifica si el campo verificado esta vacio
    const isVerifiedEmpty = this.isEmptyValue(verified);

    //Cambia el formato del string
    original = this.transFormValue(original, isValueEmpty, dataType);
    verified = this.transFormValue(verified, isVerifiedEmpty, dataType);

    //Creación del html para mostrar
    if (isValueEmpty && isVerifiedEmpty) {
      html = `<span>N/A</span>`;
    }

    if (isValueEmpty && !isVerifiedEmpty) {
      html = `<span class="oldValue p-mx-1">N/A</span> <span class="newValue p-mx-1">${verified}</span>`;
    }

    if (!isValueEmpty && !isVerifiedEmpty) {
      if (JSON.stringify(original) === JSON.stringify(verified)) {
        html = `<span>${verified}</span>`;
      } else {
        html = `<span class="oldValue p-mx-1">${original}</span> <span class="newValue p-mx-1">${verified}</span>`;
      }
    }

    if (!isValueEmpty && isVerifiedEmpty) {
      html = `<span>${original}</span>`;
    }

    if (!isValueEmpty && isVerifiedEmpty && !isPendingInstance && isEdition) {
      html = `<span class="oldValue p-mx-1">${original}</span> <span class="newValue p-mx-1">N/A</span>`;
    }

    if (!isValueEmpty && isVerifiedEmpty && verifiedExist) {
      html = `<span class="oldValue p-mx-1">${original}</span> <span class="newValue p-mx-1">N/A</span>`;
    }

    if (isMessageDialog) {
      html = `<span class="oldValue">${
        original || 'N/A'
      }</span><span class="pi pi-arrow-right p-px-2">  </span><span class="newValue">${
        verified || 'N/A'
      }</span>`;

      if (isPendingInstance) {
        html = `<span class="newValue p-mx-1">${verified}</span>`;
      }
    }
    // Si es una nueva instancia que no fue reportada deja valor en verde
    if (isPendingInstance) {
      html = `<span>${verified}</span>`;
      if (isEdition) {
        html = `<span class="newValue p-mx-1">${verified}</span>`;
      }
    }

    return html;
  }

  isEmptyValue(value?: string) {
    return (
      value?.length === 0 ||
      value === null ||
      value === undefined ||
      value[0]?.length === 0
    );
  }

  transFormValue(value: any, isValueEmpty: boolean, dataType?: string) {
    const valueGeneral = this.transFormGeneral(value, isValueEmpty, dataType);
    if (valueGeneral !== value) return valueGeneral;

    const valueSymptom = this.transformTrasnlateSymptom(
      value,
      isValueEmpty,
      dataType
    );

    if (valueSymptom !== value) return valueSymptom;
    const valueUnit = this.transformUnit(value, isValueEmpty, dataType);

    if (valueUnit !== value) return valueUnit;

    return value;
  }

  transformUnit(value: any, isValueEmpty: boolean, dataType?: string) {
    if (!isValueEmpty && dataType === 'unit') {
      return value === TemperatureUnit.CELSIUS ? ' °C' : ' °F';
    }
    return value;
  }

  transFormGeneral(value: any, isValueEmpty: boolean, dataType?: string) {
    if (!isValueEmpty && dataType === 'date') {
      const timeZone = Number(localStorage.getItem('timeZoneOffset') ?? 0);
      return this.transformDatePipe.transformToSiteDate(value, timeZone);
    }

    if (!isValueEmpty && dataType === 'temperatureDate') {
      const timeZone = Number(localStorage.getItem('timeZoneOffset') ?? 0);
      return this.transformDatePipe.transformToSiteHour(value, timeZone);
    }

    if (!isValueEmpty && dataType === 'boolean') {
      return value
        ? this.translateService.instant('general.yes')
        : this.translateService.instant('general.no');
    }

    if (!isValueEmpty && dataType === 'givenTo') {
      //Traducciones de datos
      return value
        ?.map((g: any) =>
          this.translateService.instant(`medicationinstance.enums.${g}`)
        )
        .join(', ');
    }

    if (!isValueEmpty && dataType === 'dictionary') {
      return this.transformTypeDictionary(value);
    }

    return value;
  }

  transformTrasnlateSymptom(
    value: any,
    isValueEmpty: boolean,
    dataType?: string
  ) {
    if (!isValueEmpty && dataType === 'translateSymptom') {
      const translate = this.translateService.instant(
        `symptom.enums.symptoms.${value}`
      );
      return translate.includes('symptom.') ? value : translate;
    }

    if (!isValueEmpty && dataType === 'translateRVP') {
      return this.translateService.instant(
        `otherSymptom.requiredVisitProfessional.${value}`
      );
    }

    if (!isValueEmpty && dataType === 'intensity') {
      return this.translateService.instant(`symptominstance.enums.${value}`);
    }

    if (!isValueEmpty && dataType === 'route') {
      return this.translateService.instant(
        `ediary.enums.temperatureRoute.${value}`
      );
    }

    return value;
  }

  transformTypeDictionary(value: any) {
    return value
      ?.map((label: any) => {
        if (label === 'symptom.enums.symptoms.FEVER') {
          return this.translateService.instant(label);
        }
        return this.dictionaryPipe.transform(label);
      })
      .join(', ');
  }
}
