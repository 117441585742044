import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { Accordion } from 'primeng/accordion';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { SectionsToConcilied } from 'src/app/shared/global.variables';
const logger = new Logger('tp2-logger-ReconciliationSectionsComponent');
@Component({
  selector: 'app-reconciliation-sections',
  templateUrl: './reconciliation-sections.component.html',
  styleUrls: ['./reconciliation-sections.component.scss'],
})
export class ReconciliationSectionsComponent implements OnInit {
  @ViewChild('accordion', { static: false }) accordion!: Accordion;
  projectId: string = '';
  confEdiaryId: string = '';
  entityConfiguration: string = '';
  dictionary: any[] = [];
  formId: string = '';
  isForm: boolean = false;
  confForm: any = {};
  originalConfForm: any = {};
  readMode: any;
  sectionsToConcilied: { label: string; value: string; active: boolean }[] = [];
  booleanOptions: { label: any; value: boolean }[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private trialpalService: TrialpalService
  ) {}
  async ngOnInit() {
    this.trialpalService.showSpinner(
      'general.configurations.enums.configuration',
      'GET'
    );
    if (this.config.data) {
      this.projectId = this.config.data.projectId;
      this.confEdiaryId = this.config.data.confEdiaryId;
      this.entityConfiguration = this.config.data.entityConfiguration;
      this.dictionary = this.config.data.dictionary;
      this.formId = this.config.data.formId;
      this.isForm = this.config.data.isForm;
      this.confForm = this.config.data.confForm;
      this.originalConfForm = structuredClone(this.confForm);
      this.readMode = this.config.data.readMode;
      this.sectionsToConcilied = this.config.data.sectionsToConcilied;
    }
    logger.debug('sectionsToConcilied', this.confForm);

    this.setBooleanOptions();

    if (this.confForm) {
      await this.checkEmptyConfigs();
      await this.getCurrentValues();
    }
    logger.debug(this.confForm);
    this.trialpalService.hideSpinner();
    this.ref.onClose.subscribe((value) => {
      !value && this.ref.close(this.originalConfForm);
    });
  }

  async checkEmptyConfigs() {
    const defaultConfigs = {
      allowAddRecords: false,
      allowRemoveRecords: false,
      allowOcurencyDateReconciliation: false,
    };
    if (
      this.sectionsToConcilied.some((obj) =>
        Object.values(obj).includes(SectionsToConcilied.SOLICITED_SYMPTOMS)
      ) &&
      !this.confForm?.symptomsReconciliationConfig
    ) {
      this.confForm.symptomsReconciliationConfig = {
        allowIntensityReconciliation: { allow: false },
        ...defaultConfigs,
      };
    }
    if (
      this.sectionsToConcilied.some((obj) =>
        Object.values(obj).includes(SectionsToConcilied.LOCAL_SYMPTOMS)
      ) &&
      !this.confForm?.localSymptomsReconciliationConfig
    ) {
      this.confForm.localSymptomsReconciliationConfig = {
        allowIntensityReconciliation: { allow: false },
        ...defaultConfigs,
      };
    }
    if (
      this.sectionsToConcilied.some((obj) =>
        Object.values(obj).includes(SectionsToConcilied.TEMPERATURE)
      ) &&
      !this.confForm?.temperatureReconciliationConfig
    ) {
      this.confForm.temperatureReconciliationConfig = {
        allowIntensityReconciliation: { allow: false },
        allowRouteReconciliation: false,
        ...defaultConfigs,
      };
    }
    if (
      this.sectionsToConcilied.some((obj) =>
        Object.values(obj).includes(SectionsToConcilied.MEDICATION)
      ) &&
      !this.confForm?.medicationReconciliationConfig
    ) {
      this.confForm.medicationReconciliationConfig = {
        allowDosisReconciliation: false,
        allowRouteReconciliation: false,
        allowTreatmentReconciliation: false,
        allowReasonReconciliation: false,
        ...defaultConfigs,
      };
    }
    if (
      this.sectionsToConcilied.some((obj) =>
        Object.values(obj).includes(SectionsToConcilied.MEDICAL_ATTENTION)
      ) &&
      !this.confForm?.medicalAttentionReconciliationConfig
    ) {
      this.confForm.medicalAttentionReconciliationConfig = {
        allowTypeReconciliation: false,
        allowSymptomOccurencyReconciliation: false,
        allowHospitalizationReconciliation: false,
        allowReasonReconciliation: false,
        ...defaultConfigs,
      };
    }
    if (
      this.sectionsToConcilied.some((obj) =>
        Object.values(obj).includes(SectionsToConcilied.OTHER_SYMPTOMS)
      ) &&
      !this.confForm?.otherSymptomsReconciliationConfig
    ) {
      this.confForm.otherSymptomsReconciliationConfig = {
        allowIntensityReconciliation: { allow: false },
        allowHospitalizationReconciliation: false,
        ...defaultConfigs,
      };
    }
  }
  async getCurrentValues() {
    for (const section of this.sectionsToConcilied) {
      section.active = this.confForm.sectionsToReconcile?.includes(
        section.value
      );
    }
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  /**
   * Checks if the accordion tab at the specified index should be toggled open.
   * Toggles the tab open if the accordion exists and the section at the index is not active.
   *
   * @param index - The index of the accordion tab to check and potentially toggle.
   */
  checkAccordionTap(index: number) {
    if (this.accordion && !this.sectionsToConcilied[index].active) {
      this.accordion.tabs[index].selected &&
        this.accordion.tabs[index].toggle(true);
    }
  }
  async onSubmit(f: NgForm) {
    if (f.valid) {
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant('general.savingInfo')
      );
      const activeSections = this.sectionsToConcilied.filter(
        (STC: any) => STC.active
      );

      const input: any = {
        ...this.confForm,
        sectionsToReconcile: activeSections.map((STC: any) => STC.value),
      };
      try {
        delete input.project;
        delete input.confEdiary;
        delete input.__typename;
        delete input._deleted;
        delete input._lastChangedAt;
        delete input.updatedAt;
        delete input.createdAt;
        const updatedConfForm = await this.ediaryService.updateConfForm(
          input,
          this.formId,
          this.confForm._version
        );
        this.confForm = { ...updatedConfForm };
        this.ref.close(this.confForm);
        this.trialpalService.successUpdateMessage();
      } catch (error) {
        logger.error('UpdateConfFormError', error);
        this.trialpalService.errorOperationMessage();
      }
      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'conciliation.messageError.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'general.incompleteFormMessage'
        ),
      });
    }
  }
}
