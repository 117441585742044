<div style="height: 70vh">
  <div
    class="p-fluid"
    *ngIf="
      pdfFiles.length > 0 &&
      (pdfFilesConciliation.length === 0 || !shouldShowPDFAssessment)
    "
  >
    <app-tpcard
      class="p-col-12 p-md-4 p-lg-3"
      *ngFor="let p of dataCard"
      [tpCardData]="p"
      (detail)="shouldShowPDFDiary && viewPDF(p, 'managePDFVersions')"
      [ngStyle]="{ 'pointer-events': shouldShowPDFDiary ? 'auto' : 'none' }"
    ></app-tpcard>
  </div>
  <div
    class="p-fluid"
    *ngIf="pdfFilesConciliation.length > 0 && shouldShowPDFAssessment"
  >
    <p-tabView (onChange)="handleChange($event)">
      <p-tabPanel
        [selected]="index === 0"
        header="{{ 'subject.sourceDocument' | translate }}"
        *ngIf="shouldShowPDFDiary || hasSubjectDiaryGeneratePDFPermission"
      >
        <button
          *ngIf="isBtnGeneratePDFActive"
          pButton
          label=" {{ 'general.generatePDFEntity' | translate }}"
          icon="pi pi-file-pdf"
          iconPos="right"
          type="button"
          style="width: auto"
          (click)="createPDF()"
          class="p-button-secondary p-button-outlined"
        ></button>
        <app-tptable
          (customAction1)="
            shouldShowPDFDiary && viewPDF($event, 'managePDFVersionsTable')
          "
          (openDetail)="
            shouldShowPDFDiary && viewPDF($event, 'managePDFVersionsTable')
          "
          [dataTable]="dataTableEdiary"
          [timeZoneOffset]="timeZoneOffset"
          [rowsPerPage]="5"
        ></app-tptable>
      </p-tabPanel>
      <p-tabPanel
        [selected]="index === 1"
        header="{{ 'subject.reconciliateDocument' | translate }}"
      >
        <app-tptable
          (customAction1)="viewPDF($event, 'manageAssessedPDFVersionsTable')"
          (openDetail)="viewPDF($event, 'manageAssessedPDFVersionsTable')"
          [dataTable]="dataTableConciliation"
          [timeZoneOffset]="timeZoneOffset"
          [rowsPerPage]="5"
        ></app-tptable>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="p-grid p-fluid" *ngIf="pdfFiles.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
