import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Logger } from 'aws-amplify';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { SignatureEntityType } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { ViewPdfComponent } from 'src/app/shared/components/view-pdf/view-pdf.component';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';

const logger = new Logger('tp2-ManagePdfVersionsComponent');
@Component({
  selector: 'app-manage-pdf-versions',
  templateUrl: './manage-pdf-versions.component.html',
  styleUrls: ['./manage-pdf-versions.component.scss'],
})
export class ManagePdfVersionsComponent implements OnInit {
  index: number = 0;
  timeZoneOffset = 0;
  instance: any = [];
  pdfFiles: any[] = [];
  PDFName = '';
  isFileUploaded = false;
  dataCard: any[] = [];
  pdfFilesConciliation: any[] = [];
  dataTableConciliation: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  dataTableEdiary: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  subjectId: any;
  isEDiaryConf: any;
  isBtnGeneratePDFActive: boolean = false;
  hasSubjectReportPDFPermission: boolean = false;
  entity: string = ''; //entity de instancia Reporte o diario

  shouldShowPDFDiary: boolean = false;
  hasSubjectDiaryGeneratePDFPermission: boolean = false;
  shouldShowPDFAssessment: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private transformDatePipe: TransformDatePipe,
    private trialpalService: TrialpalService,
    public sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private authService: AuthService,
    private userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.instance = this.config?.data?.instance;
    this.timeZoneOffset = this.config?.data?.timeZoneOffset || 0;
    this.subjectId = this.config?.data?.subjectId;
    this.isEDiaryConf = this.config?.data?.isEDiaryConf;
    this.entity = this.config?.data?.entity;

    this.setPermissions();
    if (this.instance) {
      this.shouldShowPDFDiary =
        this.authService.isAdmin() ||
        (await this.userPermissionsService.hasPermission([
          TP2Permission.SubjectDiaryPDF,
        ]));
      this.shouldShowPDFAssessment =
        this.authService.isAdmin() ||
        (await this.userPermissionsService.hasPermission([
          TP2Permission.SubjectDiaryAssessmentPDF,
        ]));
      const pdfFiles = this.clasificatePDFList(this.instance?.pdfFiles);
      logger.debug('pdfFiles', pdfFiles);
      this.pdfFiles = [...pdfFiles].reverse();
      this.buildTPDataCard(this.pdfFiles);
    }
    this.isBtnGeneratePDFActive =
      this.authService.isAdmin() ||
      (await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectDiaryGeneratePDF,
      ]));
  }

  async setPermissions() {
    this.hasSubjectReportPDFPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectReportPDF,
        Roles.Admin,
      ]);
    this.hasSubjectDiaryGeneratePDFPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectDiaryGeneratePDF,
        Roles.Admin,
      ]);
  }

  buildTPDataCard(pdfFiles: any) {
    this.dataCard = [];
    //verificar si es de reportes
    const pdfFilesEdiary = pdfFiles.filter(
      (pdf: any) => pdf.type === 'report' || pdf.type === 'eDiary'
    );
    const isReport = pdfFilesEdiary.length > 0;

    //divide los pdfs en dos listas, una para los de conciliación y otra para los de diario
    this.pdfFilesConciliation = this.shouldShowPDFAssessment
      ? pdfFiles.filter((pdf: any) => pdf.type === 'eDiaryConciliacion')
      : [];

    if (this.pdfFilesConciliation.length > 0) {
      this.pdfFiles = this.pdfFilesConciliation;
      this.pdfFilesConciliation.forEach((res: any) => {
        this.dataCard.push(this.buildTPCardData(res, true, false));
      });
      this.dataTableConciliation = this.buildTPTableData(this.pdfFiles);
    }

    if (pdfFilesEdiary.length > 0) {
      this.pdfFiles = pdfFilesEdiary;
      pdfFilesEdiary.forEach((res: any) => {
        this.dataCard.push(this.buildTPCardData(res, false, isReport));
      });
      this.dataTableEdiary = this.buildTPTableData(
        this.pdfFiles,
        this.shouldShowPDFDiary
      );
    }
    logger.debug('dataCard', this.dataCard);
    //ordena el dataCard por fecha de subida
    this.dataCard.sort((a: any, b: any) => {
      const dateA = new Date(a.uploadDate).getTime();
      const dateB = new Date(b.uploadDate).getTime();
      return dateB - dateA;
    });
  }

  buildTPCardData(
    res: any,
    isConciliation: boolean,
    isReport: boolean
  ): TPCardData {
    const index =
      this.pdfFiles.length - this.pdfFiles.findIndex((x: any) => x === res);
    let version = '';
    if (index === this.pdfFiles.length) {
      version = this.trialpalService.translateService.instant(
        'general.currentVersion'
      );
    } else {
      version =
        this.trialpalService.translateService.instant('general.version') +
        ' ' +
        index;
    }

    let title = '';
    if (!isReport) {
      title = isConciliation
        ? this.trialpalService.translateService.instant(
            'general.eDiaryConciliation'
          )
        : this.trialpalService.translateService.instant('general.eDiary');
    }

    logger.debug('res', res);

    return {
      ...res,
      hideState: true,
      data: res.type,
      header: title,
      cardColor: isConciliation ? '#55E5AB' : '',
      icon1: 'pi pi-calendar',
      section1: version,
      icon2: 'pi pi-calendar-times',
      section2: this.transformDatePipe.transformToSiteHour(
        res.uploadDate,
        this.timeZoneOffset
      ),
      styleColorState: 'green',
      image: 'imgs/pdf.png',
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      cardIsButton: this.hasSubjectReportPDFPermission,
      objectAction: res.name,
    };
  }

  buildTPTableData(files: any, shouldShowPDF: boolean = true): TPTableData {
    let dataFiles = files.map((file: any) => {
      const version = files.length - files.findIndex((x: any) => x === file);
      return {
        data: file,
        entity: TP2Entites.EDIARYPHASEINSTANCE,
        version: version,
        date: this.formatDate(file.uploadDate),
      };
    });
    let cols = [
      {
        header:
          this.trialpalService.translateService.instant('general.version'),
        type: TypeInput.TEXT,
        field: 'version',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header:
          this.trialpalService.translateService.instant('conciliation.date'),
        type: TypeInput.TEXT,
        field: 'date',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];

    return {
      valueTable: dataFiles,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: ['version', 'date', 'lastUser'],
      cols,
      customButton1: shouldShowPDF
        ? {
            icon: 'pi pi-file-pdf',
          }
        : null,
      menuOptions: [],
    };
  }
  viewPDF(event: any, analyticsSource: string) {
    this.PDFName = event?.data?.fileUrl ?? event.fileUrl;
    this.openPdfModal(this.PDFName, analyticsSource);
  }

  /**
   * Clasifica cada PDF según si es de conciliación o de diario
   * @param pdfList Lista de PDFs
   * @returns Lista de PDFs clasificados con nuevo atributo type
   */
  clasificatePDFList(pdfList: any[]) {
    return pdfList.map((pdf: any) => {
      return {
        ...pdf,
        type: pdf.fileUrl.split('/')[1],
      };
    });
  }

  showViaService() {
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: 'PDF',
      detail: this.trialpalService.translateService.instant('subject.notPDF'),
    });
  }

  formatDate(value: string) {
    return this.transformDatePipe.transformToSiteHour(
      value,
      this.timeZoneOffset
    );
  }
  handleChange(event: any) {
    this.index = event.index;
  }

  async createPDF(): Promise<any> {
    this.ref.close({
      instance: this.instance,
      isConfEdiary: this.isEDiaryConf,
    });
  }

  openPdfModal(pdfUrl: any, analyticsSource: string): void {
    const isFromReport = this.entity === SignatureEntityType.REPORT;
    if (isFromReport && !this.hasSubjectReportPDFPermission) {
      return;
    }
    const sourceComponent =
      analyticsSource + (isFromReport ? 'Report' : 'Diary');
    const ref = this.dialogService.open(ViewPdfComponent, {
      data: {
        PDFUrl: pdfUrl,
        disablePrint: true,
        showPrintButton: true,
        sourceComponent,
        subjectId: this.subjectId,
      },
      styleClass: 'modal-pdf',
      closeOnEscape: true,
      dismissableMask: true,
      showHeader: false,
      width: '80%',
      height: '80%',
    });
    ref.onClose.subscribe();
  }
}
