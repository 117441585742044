import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { DictionaryPipe } from '../../pipes/dictionary.pipe';
import { TPTableData } from '../tptable/tpTableData';
import {
  GetConfInformedConsentQuery,
  InformedConsentInstance,
} from 'src/app/modules/informed-consent/informed-consent.types';
import { FilterService } from 'primeng/api';
const logger = new Logger('tp2-logger-eConsentAuditQuery');
@Component({
  selector: 'app-audit-query',
  templateUrl: './audit-query.component.html',
  styleUrls: ['./audit-query.component.scss'],
})
export class AuditQueryComponent implements OnInit {
  entity: string = '';
  consent: any = null;
  site: any = null;
  transactionType: string = '';
  transactionDate: string = '';
  transactionId: string = '';
  transactionOptions: { label: string; value: string }[] = [];
  siteOptions: any[] = [];
  userOptions: any[] = [];
  entityOptions: { label: string; value: string }[] = [];
  siteResults: string[] = [];
  consentResults: string[] = [];
  projectId: any;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  displayDialog: boolean = false;
  formFieldJson: string = '';
  auditItems: any = [];
  confInformedConsentsByProject: any[] = [];
  informedConsentInstances: any[] = [];
  groupedInformedConsentInstances: any[] = [];
  auditTrace: any[] = [];
  filteredGroups: any[] = [];
  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private trialpalService: TrialpalService,
    public transformDatePipe: TransformDatePipe,
    private dictionaryPipe: DictionaryPipe,
    private readonly informedConsentService: InformedConsentService,
    private filterService: FilterService
  ) {}
  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params.id;
    this.fillOptions();
  }

  async fillOptions() {
    this.translateService
      .get('informedConsent.audit.enums.entity')
      .subscribe((entities: { key: string; value: string }) => {
        for (const [key, value] of Object.entries(entities)) {
          this.entityOptions.push({ value: key, label: value });
        }
      });
  }
  search(event: any) {
    if (this.entity === 'ConfInformedConsent') {
      this.consentResults = this.confInformedConsentsByProject
        .filter((data) => {
          return (
            data.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1
          );
        })
        .map((dataValue) => ({
          ...dataValue,
          name: this.dictionaryPipe.transform(dataValue.name),
        }));
    } else {
      let query = event.query;
      let filteredGroups = [];

      for (let optgroup of this.groupedInformedConsentInstances) {
        let filteredSubOptions = this.filterService.filter(
          optgroup.items,
          ['label'],
          query,
          'contains'
        );
        if (filteredSubOptions?.length) {
          filteredGroups.push({
            label: optgroup.label,
            value: optgroup.value,
            items: filteredSubOptions,
          });
        }
      }

      this.filteredGroups = filteredGroups;
    }
  }
  async getEntities() {
    this.consent = null;
    this.trialpalService.showSpinner('subject.spinnerLoadtData');
    if (this.entity === 'ConfInformedConsent') {
      const confInformedConsentsByProject =
        await this.informedConsentService.getConfInformedConsentByProjectId(
          this.projectId,
          true
        );
      if (!this.authService.isAdmin()) {
        const authSites = this.authService.getUserSites();
        for (const site of authSites) {
          this.getConfInformedConsentsBySiteId(
            site,
            confInformedConsentsByProject
          );
        }
      } else {
        this.confInformedConsentsByProject = [...confInformedConsentsByProject];
      }
    } else {
      const informedConsentInstancesByProject =
        await this.informedConsentService.getInformedConsentInstanceByProjectId(
          this.projectId
        );
      if (!this.authService.isAdmin()) {
        const authSites = this.authService.getUserSites();
        for (const site of authSites) {
          this.getInformedConsentInstancesBySiteId(
            site,
            informedConsentInstancesByProject
          );
        }
      } else {
        this.informedConsentInstances = [...informedConsentInstancesByProject];
      }
      this.agrupateInformedConsentInstancesByConf();
    }
    this.trialpalService.hideSpinner();
  }

  async getConfInformedConsentsBySiteId(
    siteId: string,
    confInformedConsents: any[]
  ) {
    const searchConfInformedConsentsByProject = confInformedConsents.filter(
      (confInformedConsent: GetConfInformedConsentQuery) =>
        confInformedConsent?.sites?.some((site) => site?.site === siteId) &&
        this.confInformedConsentsByProject.findIndex(
          (confIC) => confIC.id === confInformedConsent.id
        ) === -1
    );
    this.confInformedConsentsByProject = [
      ...this.confInformedConsentsByProject,
      ...searchConfInformedConsentsByProject,
    ];
  }
  async getInformedConsentInstancesBySiteId(
    siteId: string,
    informedConsents: any[]
  ) {
    const searchInformedConsentsByProject = informedConsents.filter(
      (informedConsent: InformedConsentInstance) =>
        !informedConsent._deleted &&
        informedConsent?.siteId === siteId &&
        this.informedConsentInstances.findIndex(
          (IC) => IC.id === informedConsent.id
        ) === -1
    );
    this.informedConsentInstances = [
      ...this.informedConsentInstances,
      ...searchInformedConsentsByProject,
    ];
  }
  async onSubmit() {
    this.auditTrace = [];
    try {
      this.trialpalService.showSpinner('audit.loadingAuditInfo');
      if (this.entity === 'ConfInformedConsent') {
        const allConfSections =
          await this.informedConsentService.getConfICSectionsByConfInformedConsentId(
            this.consent.id,
            true
          );
        let allConfQuestions = await this.getAllConfQuestions(allConfSections);
        allConfQuestions = allConfQuestions.reduce(
          (acc: any, val: any) => acc.concat(val),
          []
        );
        let allConfAnswers = await this.getAllConfAnswers(allConfQuestions);
        allConfAnswers = allConfAnswers.reduce(
          (acc: any, val: any) => acc.concat(val),
          []
        );
        const allConfigurations = [
          ...[this.consent],
          ...allConfSections,
          ...allConfQuestions,
          ...allConfAnswers,
        ]
          .filter((list: any[]) => !Array.isArray(list))
          .map((data) => ({ ...data, entityName: data.__typename }));
        this.auditTrace = await this.getAllAuditInfo(allConfigurations);
      } else {
        const allICSectionInstances =
          await this.informedConsentService.getICSectionInstanceByInformedConsentInstanceId(
            this.consent.id,
            true
          );
        const allICQuestionInstances =
          await this.informedConsentService.getICQuestionInstanceByInformedConsentInstanceId(
            this.consent.id,
            true
          );
        const allConsentedUsers =
          await this.informedConsentService.getConsentedUserByInformedConsenteInstanceId(
            this.consent.id
          );
        const allInstances = [
          ...[this.consent],
          ...allICSectionInstances,
          ...allICQuestionInstances,
          ...allConsentedUsers,
        ]
          .filter((list: any[]) => !Array.isArray(list))
          .map((data) => ({ ...data, entityName: data.__typename }));
        this.auditTrace = await this.getAllAuditInfo(allInstances);
      }
      this.trialpalService.hideSpinner();
    } catch (error) {
      logger.error('Getting auditTrace -->', error);
      this.trialpalService.hideSpinner();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'general.messageErrorOperation.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'general.messageErrorOperation.detail'
        ),
      });
    }
  }

  formatDate(value: string) {
    const timeZone = Number(localStorage.getItem('timeZoneOffset') ?? 0);
    return this.transformDatePipe.transformToSiteHour(value, timeZone);
  }
  showDetail(event: any) {
    this.formFieldJson = event.data;
    this.displayDialog = true;
  }
  async getAllConfQuestions(allConfSections: any[]) {
    return await Promise.all(
      allConfSections.map(async (section: any) => {
        const confSectionId = section.id;

        const confQuestions =
          await this.informedConsentService.getConfICQuestionByConfICSectionId(
            confSectionId,
            true
          );

        return confQuestions;
      })
    );
  }
  async getAllConfAnswers(allConfQuestions: any[]) {
    return await Promise.all(
      allConfQuestions.map(async (question: any) => {
        const confQuestionId = question.id;
        const confAnswers =
          await this.informedConsentService.getConfICAnswerByConfICQuestionId(
            confQuestionId,
            true
          );
        return confAnswers;
      })
    );
  }

  async getAllAuditInfo(entities: any[]) {
    const results = await Promise.all(
      entities.map(async (entity: any) => {
        const trace = (await this.trialpalService.getAuditInfo(
          entity.entityName,
          entity.id
        )) as any;
        trace.items = trace.items.map((item: any) => {
          return {
            ...item,
            entityType: this.translateService.instant(
              `informedConsent.audit.enums.allEntities.${item.entity}`
            ),
          };
        });
        if (entity.entityName === 'ConfInformedConsent') {
          trace.items.forEach((item: any) => {
            item.entityName = this.dictionaryPipe.transform(entity.name) ?? '';
          });
        }
        if (entity.entityName === 'InformedConsentInstance') {
          trace.items.forEach((item: any) => {
            item.entityName = entity.consentedIdentificator;
          });
        }
        if (entity.entityName === 'ConfICSection') {
          trace.items.forEach((item: any) => {
            item.entityName = this.dictionaryPipe.transform(entity.title) ?? '';
          });
        }
        if (entity.entityName === 'ConsentedUser') {
          await this.processConsentedItems(trace, entity);
        }
        if (entity.entityName === 'ICSectionInstance') {
          trace.items.forEach((item: any) => {
            item.entityName =
              this.dictionaryPipe.transform(entity.confICSection.title) ?? 'NA';
          });
        }
        if (entity.entityName === 'ICQuestionInstance') {
          trace.items.forEach((item: any) => {
            item.entityName =
              this.dictionaryPipe.transform(
                entity.confICQuestion.description
              ) ?? 'NA';
          });
        }
        if (
          entity.entityName === 'ConfICQuestion' ||
          entity.entityName === 'ConfICAnswer'
        ) {
          trace.items.forEach((item: any) => {
            item.entityName =
              this.dictionaryPipe.transform(entity.description) ?? '';
          });
        }
        return trace;
      })
    );
    return results.reduce((acc: any, val: any) => acc.concat(val.items), []);
  }
  async processConsentedItems(trace: any, entity: any) {
    for (const item of trace.items) {
      item.entityName = entity.name;
      const data = JSON.parse(item.data);
      for (const key of Object.keys(data)) {
        if (this.isSignatureKeyWithData(data, key)) {
          if (this.getString(data, key, 'oldData') !== '') {
            await this.getImageUrl(data, key, 'oldData');
          }
          if (this.getString(data, key, 'newData') !== '') {
            await this.getImageUrl(data, key, 'newData');
          }
        }
      }
      item.data = JSON.stringify(data);
    }
  }
  isSignatureKeyWithData(data: any, key: string) {
    return key === 'signature' && (data[key]?.data || data[key]?.S?.data);
  }
  isTypedString(data: any): boolean {
    return !!data?.S?.data;
  }
  getString(data: any, key: string, oldNewKey: string): string {
    return (
      data[key]?.data[oldNewKey]?.S ?? data[key]?.S?.data[oldNewKey]?.S ?? ''
    );
  }

  async getImageUrl(data: any, key: string, oldNewKey: string) {
    const getImageElement = (url: string) =>
      `<img width="100%" height="100%" src="${url}" alt="Firma..." />`;
    const url = await this.informedConsentService.storage.get(
      this.getString(data, key, oldNewKey)
    );
    if (this.isTypedString(data)) {
      data[key].S.data[oldNewKey].S = getImageElement(url);
    } else {
      data[key].data[oldNewKey].S = getImageElement(url);
    }
  }
  agrupateInformedConsentInstancesByConf() {
    this.groupedInformedConsentInstances = Array.from(
      this.informedConsentInstances
        .reduce((acc, ICI) => {
          const id = ICI.ConfInformedConsent.id;
          if (!acc.has(id)) {
            // si no existe lo agrega el mapa con el atributo items vacio
            acc.set(id, { ...ICI.ConfInformedConsent, items: [] });
          }
          // si ya existe el id de la conf lo agrega respectivamente
          acc.get(id).items.push({
            ...ICI,
            label: ICI.consentedIdentificator,
            value: ICI.id,
          });
          return acc;
        }, new Map())
        .values()
      // mapea el resultado final para traducir las llaves y tener estandarizado los objetos
    ).map((GICI: any) => ({
      label: this.dictionaryPipe.transform(GICI.name),
      value: GICI.id,
      items: GICI.items,
    }));
  }
}
