<main
  class="formly-field-forms"
  [ngClass]="{ 'grid-col-1': formConfiguration.isGroupField }"
>
  <form #form="ngForm" class="p-d-flex p-flex-column gap-4">
    <p-message
      class="info"
      severity="info"
      [text]="'designer.addEdit.mandatoryFieldsMessage' | translate"
    ></p-message>
    <p-tabView id="addEditFormField">
      <!--Primera parte del formulario-->
      <p-tabPanel
        [header]="'designer.addEdit.btnDisplay' | translate"
        [selected]="true"
        *ngIf="formConfiguration.showDisplayTab"
      >
        <div class="p-d-flex p-flex-column gap-4 p-p-4">
          <p class="p-m-0 step-subtitle">
            <span>{{ 'designer.addEdit.displayHelp' | translate }}</span>
          </p>

          <!--Label form-->
          <div class="p-d-flex p-flex-column gap-2">
            <app-label-tooltip
              for="field_label"
              [label]="'designer.addEdit.display.label' | translate"
              [tooltip]="'designer.addEdit.display.labelHelp' | translate"
              [required]="false"
            ></app-label-tooltip>
            <app-simple-dictionary-input
              id="field_label"
              name="field_label"
              [projectId]="projectId"
              [isRequired]="false"
              [value]="field.templateOptions.label"
              (outputEvent)="
                field.templateOptions.label = $event; updateField()
              "
            ></app-simple-dictionary-input>
          </div>

          <!--field type form-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.textTypes"
          >
            <app-label-tooltip
              for="drop_fieldType"
              [label]="'designer.addEdit.display.type' | translate"
              [tooltip]="'designer.addEdit.display.typeTooltip' | translate"
            ></app-label-tooltip>
            <p-dropdown
              id="drop_fieldType"
              name="drop_fieldType"
              optionLabel="label"
              optionValue="value"
              class="input p-col-12 p-p-0"
              appendTo="body"
              [options]="formConfiguration.textTypes"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              [(ngModel)]="field.type"
              (onChange)="updateField()"
            >
            </p-dropdown>
          </div>

          <!--Placeholder form-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.placeholder"
          >
            <app-label-tooltip
              for="field_placeholder"
              [label]="'designer.addEdit.display.placeholder' | translate"
              [tooltip]="'designer.addEdit.display.placeholderHelp' | translate"
            ></app-label-tooltip>
            <app-simple-dictionary-input
              id="field_placeholder"
              name="placeholder"
              [projectId]="projectId"
              [isRequired]="false"
              [value]="field.templateOptions.placeholder"
              (outputEvent)="
                field.templateOptions.placeholder = $event; updateField()
              "
            ></app-simple-dictionary-input>
          </div>

          <!--Tooltip form-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.tooltip"
          >
            <app-label-tooltip
              [for]="'field_tooltip'"
              [label]="'designer.addEdit.display.textHelp' | translate"
              [tooltip]="'designer.addEdit.display.tooltipHelp' | translate"
            ></app-label-tooltip>
            <app-simple-dictionary-input
              id="field_tooltip"
              name="tooltip"
              [projectId]="projectId"
              [isRequired]="false"
              [value]="field.templateOptions.tooltip"
              (outputEvent)="
                field.templateOptions.tooltip = $event; updateField()
              "
            ></app-simple-dictionary-input>
          </div>

          <!--Solicita como se calculará el campo-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.isEvaluationSection"
          >
            <app-label-tooltip
              [for]="'drop_calculateTypes'"
              [label]="
                'designer.addEdit.display.calculateTypeLabel' | translate
              "
              [tooltip]="
                'designer.addEdit.display.calculateTypeTooltip' | translate
              "
              [required]="true"
            ></app-label-tooltip>

            <p-dropdown
              id="drop_calculateTypes"
              name="drop_calculateTypes"
              optionLabel="label"
              optionValue="value"
              class="input p-col-12 p-p-0"
              appendTo="body"
              [options]="formConfiguration.calculateTypes"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              [(ngModel)]="field.templateOptions.calculateType"
              [required]="true"
            >
            </p-dropdown>
          </div>

          <!--Required form-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.required"
          >
            <app-label-tooltip
              [for]="'field_required'"
              [label]="'designer.addEdit.display.required' | translate"
              [tooltip]="'designer.addEdit.display.tooltipRequired' | translate"
              [required]="true"
            ></app-label-tooltip>

            <p-selectButton
              name="required"
              id="field_required"
              [options]="formConfiguration.booleanTypes"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="field.templateOptions.required"
              [required]="true"
              (onChange)="updateField()"
              #requiredTemplate="ngModel"
            ></p-selectButton>
            <small
              class="form-error"
              *ngIf="
                requiredTemplate.invalid &&
                (requiredTemplate.touched || form.submitted)
              "
              >{{ 'general.required' | translate }}</small
            >
          </div>

          <!--group type form-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.score"
          >
            <app-label-tooltip
              [for]="'field_score'"
              [label]="'designer.addEdit.display.evaluationLabel' | translate"
              [tooltip]="
                'designer.addEdit.display.evaluationTooltip' | translate
              "
              [required]="true"
            ></app-label-tooltip>

            <p-selectButton
              name="score"
              id="field_score"
              [options]="formConfiguration.booleanTypes"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="field.templateOptions.score"
              [required]="true"
              (onChange)="updateField()"
              #scoreTemplate="ngModel"
            ></p-selectButton>
            <small
              class="form-error"
              *ngIf="
                scoreTemplate.invalid &&
                (scoreTemplate.touched || form.submitted)
              "
              >{{ 'general.required' | translate }}</small
            >
          </div>
        </div>
      </p-tabPanel>

      <!--Segunda parte del formulario / DATA-->
      <p-tabPanel
        [header]="'designer.addEdit.btnData' | translate"
        *ngIf="formConfiguration.showDataTab"
      >
        <div class="p-d-flex p-flex-column gap-4 p-p-4">
          <p class="p-m-0 step-subtitle">
            <span>{{ 'designer.addEdit.dataInformation' | translate }}</span>
          </p>

          <!--Creación de opciones para radio-grid-->
          <div
            class="p-d-flex p-flex-column gap-4 p-mb-5"
            *ngIf="field?.templateOptions?.questions"
          >
            <div>
              <!--Tabla de formulario de preguntas-->
              <p-table
                [value]="field.templateOptions.questions"
                styleClass="p-datatable-gridlines"
                [tableStyle]="{ 'min-width': '100%' }"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th id="col-field-1">
                      <app-label-tooltip
                        [label]="'designer.addEdit.questions.label' | translate"
                        [tooltip]="
                          'designer.addEdit.questions.help' | translate
                        "
                        [required]="true"
                      ></app-label-tooltip>
                    </th>
                    <th id="col-field-2">
                      <app-label-tooltip
                        [label]="
                          'designer.addEdit.questions.labelHelp' | translate
                        "
                        [tooltip]="
                          'designer.addEdit.questions.labelHelpTooltip'
                            | translate
                        "
                        [required]="true"
                      ></app-label-tooltip>
                    </th>
                    <th id="col-field-3"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-question let-index="rowIndex">
                  <tr>
                    <td>
                      <div class="min-table-field">
                        <app-simple-dictionary-input
                          [id]="'field_question_label_' + index"
                          [name]="'field_question_label_' + index"
                          [projectId]="projectId"
                          [isRequired]="true"
                          [value]="question.label"
                          (outputEvent)="question.label = $event; updateField()"
                        ></app-simple-dictionary-input>
                      </div>
                    </td>
                    <td>
                      <div class="min-table-field">
                        <app-simple-dictionary-input
                          [id]="'field_question_tooltip_' + index"
                          [name]="'field_question_tooltip_' + index"
                          [projectId]="projectId"
                          [value]="question.tooltip"
                          (outputEvent)="
                            question.tooltip = $event; updateField()
                          "
                        ></app-simple-dictionary-input>
                      </div>
                    </td>
                    <td>
                      <div class="min-table-field">
                        <p-button
                          icon="pi pi-trash"
                          style="align-self: center"
                          styleClass="p-button-danger"
                          class="p-p-2"
                          (click)="deleteQuestion(index)"
                          *ngIf="field.templateOptions.questions.length > 1"
                        ></p-button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <button
                pButton
                pRipple
                [label]="'designer.addEdit.btnAddQuestion' | translate"
                (click)="addNewQuestion()"
                type="button"
                class="p-button-secondary p-mt-3"
                style="width: max-content"
                icon="pi pi-plus"
              ></button>
            </div>
          </div>
          <!--Creación de opciones para radio buttons, multicheckbox-->
          <div
            class="p-d-flex p-flex-column gap-4"
            *ngIf="field?.templateOptions?.options"
          >
            <div>
              <!---Tabla creada para las opciones de campos multiradio, checkbox, etc-->
              <p-table
                [value]="field.templateOptions.options"
                styleClass="p-datatable-gridlines"
                [tableStyle]="{ 'min-width': '100%' }"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th id="col-field-4">
                      <app-label-tooltip
                        [label]="'designer.addEdit.labelAnswer' | translate"
                        [tooltip]="
                          'designer.addEdit.labelAnswerTooltip' | translate
                        "
                        [required]="true"
                      ></app-label-tooltip>
                    </th>
                    <th id="col-field-5">
                      <app-label-tooltip
                        [label]="'designer.addEdit.labelValue' | translate"
                        [tooltip]="
                          'designer.addEdit.labelValueTooltip' | translate
                        "
                        [required]="true"
                      ></app-label-tooltip>
                    </th>
                    <th id="col-field-6" *ngIf="field.templateOptions?.images">
                      <app-label-tooltip
                        [label]="
                          'designer.addEdit.labelUploadImage' | translate
                        "
                        [tooltip]="
                          'designer.addEdit.labelUploadImageTooltip' | translate
                        "
                        [required]="true"
                      ></app-label-tooltip>
                    </th>
                    <th id="col-field-7"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-option let-index="rowIndex">
                  <tr>
                    <td>
                      <div class="min-table-field">
                        <app-simple-dictionary-input
                          [id]="'field_option_label_' + index"
                          [name]="'field_option_label_' + index"
                          [projectId]="projectId"
                          [isRequired]="true"
                          [value]="option.label"
                          (outputEvent)="option.label = $event; updateField()"
                        ></app-simple-dictionary-input>
                      </div>
                    </td>
                    <td>
                      <div class="min-table-field">
                        <input
                          pInputText
                          [id]="'field_option_value_' + index"
                          [name]="'field_option_value_' + index"
                          [(ngModel)]="option.value"
                          (input)="updateField()"
                          required
                          #fieldOptionValue="ngModel"
                        />
                        <small
                          class="form-error"
                          *ngIf="
                            fieldOptionValue.invalid &&
                            (fieldOptionValue.touched || form.submitted)
                          "
                          >{{ 'general.required' | translate }}</small
                        >
                      </div>
                    </td>
                    <td *ngIf="field.templateOptions?.images">
                      <div class="min-table-field">
                        <div class="p-d-flex gap-2 p-ai-center">
                          <p-dropdown
                            [id]="'field_option_image_' + index"
                            [name]="'field_option_image_' + index"
                            [options]="formConfiguration.imageTypes"
                            [(ngModel)]="field.templateOptions.images[index]"
                            [showClear]="true"
                            [placeholder]="
                              'designer.addEdit.labelUploadImageSelect'
                                | translate
                            "
                            [editable]="true"
                            (onChange)="updateField()"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"
                            #fieldOptionImage="ngModel"
                            [required]="true"
                          >
                            <ng-template let-image pTemplate="item">
                              <div class="p-d-flex p-ai-center gap-2">
                                <img
                                  [src]="image.value"
                                  class="p-dropdown-image"
                                  alt="image"
                                />
                                <div>
                                  <span
                                    class="p-dropdown-text"
                                    [pTooltip]="image.label"
                                    tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
                                    >{{ image.label }}</span
                                  >
                                </div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                          <label
                            [for]="'field_option_image_file_' + index"
                            class="input-file"
                            [pTooltip]="
                              'designer.addEdit.uploadImageFromComputerLabel'
                                | translate
                            "
                            tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
                          >
                            <span class="pi pi-upload"></span>
                            <input
                              type="file"
                              [id]="'field_option_image_file_' + index"
                              class="input_file"
                              accept="image/*"
                              name="file"
                              (change)="
                                onBasicUploadAuto(
                                  $event,
                                  field.templateOptions.images,
                                  index
                                )
                              "
                              [required]="true"
                              #fileImage
                            />
                          </label>
                        </div>
                        <small
                          class="form-error"
                          *ngIf="
                            fieldOptionImage.invalid &&
                            (fieldOptionImage.touched || form.submitted)
                          "
                          >{{ 'general.required' | translate }}</small
                        >
                      </div>
                    </td>
                    <td>
                      <div class="min-table-field">
                        <p-button
                          icon="pi pi-trash"
                          style="align-self: center"
                          styleClass="p-button-danger"
                          class="p-p-2"
                          (click)="deleteFieldPropOption(index)"
                          *ngIf="field.templateOptions.options.length > 1"
                        ></p-button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <button
              pButton
              pRipple
              [label]="'designer.addEdit.btnAddAnwer' | translate"
              (click)="addNewFieldPropsOption()"
              type="button"
              class="p-button-secondary"
              style="width: max-content"
              icon="pi pi-plus"
            ></button>
          </div>
        </div>
      </p-tabPanel>

      <!--Tercera parte del formulario / Validaciones-->
      <p-tabPanel
        [header]="'designer.addEdit.btnValidation' | translate"
        *ngIf="formConfiguration.showValidationTab"
      >
        <div class="p-d-flex p-flex-column gap-4 p-p-4">
          <p class="p-m-0 step-subtitle">
            <span>{{
              'designer.addEdit.validationInformation' | translate
            }}</span>
          </p>
          <div class="p-d-flex p-flex-column gap-2">
            <app-label-tooltip
              [for]="'message_required'"
              [label]="'designer.addEdit.labelCustomError' | translate"
              [tooltip]="'designer.addEdit.labelCustomErrorTooltip' | translate"
            ></app-label-tooltip>

            <app-simple-dictionary-input
              id="field_message_required"
              name="message_required"
              [projectId]="projectId"
              [isRequired]="false"
              [value]="field.validation.messages.required"
              (outputEvent)="
                field.validation.messages.required = $event; updateField()
              "
            ></app-simple-dictionary-input>
          </div>
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.min"
          >
            <app-label-tooltip
              [for]="'field_min'"
              [label]="'designer.addEdit.labelMinimunLength' | translate"
              [tooltip]="
                'designer.addEdit.labelMinimunLengthTooltip' | translate
              "
              [required]="
                field.templateOptions.min ||
                field.templateOptions.min === 0 ||
                field.templateOptions.max >= 1
              "
            ></app-label-tooltip>
            <input
              pInputText
              id="field_min"
              name="min"
              type="number"
              [min]="min"
              [max]="field.templateOptions.max ?? max"
              [(ngModel)]="field.templateOptions.min"
              (input)="updateField()"
              [required]="
                field.templateOptions.min ||
                field.templateOptions.min === 0 ||
                field.templateOptions.max >= 1
              "
              #fieldMin="ngModel"
            />
            <small
              class="form-error"
              *ngIf="fieldMin.invalid && (fieldMin.touched || form.submitted)"
              >{{ 'designer.addEdit.invalidFieldMinAndMax' | translate }}</small
            >
          </div>
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.max"
          >
            <app-label-tooltip
              [for]="'field_max'"
              [label]="'designer.addEdit.labelMaxLength' | translate"
              [tooltip]="'designer.addEdit.labelMaxLengthTooltip' | translate"
              [required]="
                field.templateOptions.min ||
                field.templateOptions.min === 0 ||
                field.templateOptions.max >= 1
              "
            ></app-label-tooltip>
            <input
              pInputText
              id="field_max"
              name="max"
              type="number"
              [min]="
                field.templateOptions.min === 0 ? 1 : field.templateOptions.min
              "
              [(ngModel)]="field.templateOptions.max"
              (input)="updateField()"
              [required]="
                field.templateOptions.min ||
                field.templateOptions.min === 0 ||
                field.templateOptions.max >= 1
              "
              #fieldMax="ngModel"
            />
            <small
              class="form-error"
              *ngIf="fieldMax.invalid && (fieldMax.touched || form.submitted)"
              >{{ 'designer.addEdit.invalidFieldMinAndMax' | translate }}</small
            >
          </div>
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.minLength"
          >
            <app-label-tooltip
              [for]="'field_minLength'"
              [label]="'designer.addEdit.labelMinWord' | translate"
              [tooltip]="'designer.addEdit.labelMinWordTooltip' | translate"
              [required]="
                field.templateOptions.minLength ||
                field.templateOptions.minLength === 0 ||
                field.templateOptions.maxLength >= 1
              "
            ></app-label-tooltip>
            <input
              pInputText
              id="field_minLength"
              name="minLength"
              type="number"
              [min]="minLength"
              [max]="field.templateOptions.maxLength ?? maxLength"
              [(ngModel)]="field.templateOptions.minLength"
              (input)="updateField()"
              #fieldMinLength="ngModel"
              [required]="
                field.templateOptions.minLength ||
                field.templateOptions.minLength === 0 ||
                field.templateOptions.maxLength >= 1
              "
            />
            <small
              class="form-error"
              *ngIf="
                fieldMinLength.invalid &&
                (fieldMinLength.touched || form.submitted)
              "
              >{{ 'designer.addEdit.invalidFieldMinAndMax' | translate }}</small
            >
          </div>
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="formConfiguration.inputsVisibility.maxLength"
          >
            <app-label-tooltip
              [for]="'field_maxLength'"
              [label]="'designer.addEdit.labelMaxWord' | translate"
              [tooltip]="'designer.addEdit.labelMaxWordTooltip' | translate"
              [required]="
                field.templateOptions.minLength ||
                field.templateOptions.minLength === 0 ||
                field.templateOptions.maxLength >= 1
              "
            ></app-label-tooltip>
            <input
              pInputText
              id="field_maxLength"
              name="maxLength"
              type="number"
              [(ngModel)]="field.templateOptions.maxLength"
              [min]="
                field.templateOptions.minLength === 0
                  ? 1
                  : field.templateOptions.minLength
              "
              (input)="updateField()"
              #fieldMaxLength="ngModel"
              [required]="
                field.templateOptions.minLength ||
                field.templateOptions.minLength === 0 ||
                field.templateOptions.maxLength >= 1
              "
            />
            <small
              class="form-error"
              *ngIf="
                fieldMaxLength.invalid &&
                (fieldMaxLength.touched || form.submitted)
              "
              >{{ 'designer.addEdit.invalidFieldMinAndMax' | translate }}</small
            >
          </div>
        </div>
      </p-tabPanel>

      <!--Cuarta parte del formulario / Condicionales-->
      <p-tabPanel
        [header]="'designer.addEdit.btnConditional' | translate"
        *ngIf="formConfiguration.showConditionalTab"
      >
        <div class="p-d-flex p-flex-column gap-4 p-p-4">
          <p class="p-m-0 step-subtitle">
            <span>{{
              'designer.addEdit.conditionalInformation' | translate
            }}</span>
          </p>
          <div class="p-d-flex p-flex-column gap-2">
            <app-label-tooltip
              [for]="'drop_conditionalKey'"
              [label]="'designer.addEdit.labelConditional' | translate"
              [tooltip]="'designer.addEdit.labelConditionalTooltip' | translate"
            ></app-label-tooltip>
            <p-dropdown
              id="drop_conditionalKey"
              name="drop_conditionalKey"
              optionLabel="label"
              optionValue="value"
              class="input p-col-12 p-p-0"
              appendTo="body"
              [options]="formConfiguration.fieldsInGroupTypes"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              [(ngModel)]="formConfiguration.conditional.field"
            >
            </p-dropdown>
          </div>
          <div class="p-d-flex p-flex-column gap-2">
            <app-label-tooltip
              [for]="'drop_conditionalOperator'"
              [label]="'designer.addEdit.labelBe' | translate"
              [tooltip]="'designer.addEdit.labelBeTooltip' | translate"
            ></app-label-tooltip>
            <p-dropdown
              id="drop_conditionalOperator"
              name="drop_conditionalOperator"
              class="input p-col-12 p-p-0"
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              [options]="formConfiguration.operatorTypes"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              [(ngModel)]="formConfiguration.conditional.operator"
            >
            </p-dropdown>
          </div>
          <div class="p-d-flex p-flex-column gap-2">
            <app-label-tooltip
              [for]="'conditionalValue'"
              [label]="'designer.addEdit.labelConditionalValue' | translate"
              [tooltip]="
                'designer.addEdit.labelConditionalValueTooltip' | translate
              "
              *ngIf="
                formConfiguration.conditional?.field?.options?.length ||
                formConfiguration.conditional?.field?.type === 'number' ||
                formConfiguration.conditional?.field?.type === 'text' ||
                formConfiguration.conditional?.field?.type === 'textarea'
              "
            ></app-label-tooltip>
            <div *ngIf="!formConfiguration.conditional?.field?.options?.length">
              <!--Validar campos de text numericos-->
              <div
                *ngIf="formConfiguration.conditional.field.type === 'number'"
              >
                <input
                  pInputText
                  id="field_conditionalValue"
                  name="conditionalValue"
                  [(ngModel)]="formConfiguration.conditional.value"
                  [min]="formConfiguration.conditional.field.min"
                  [max]="formConfiguration.conditional.field.max"
                  type="number"
                  #conditionalValue="ngModel"
                />
                <small
                  class="form-error"
                  *ngIf="
                    conditionalValue?.invalid &&
                    (conditionalValue?.touched || form.submitted)
                  "
                  >{{
                    'designer.addEdit.invalidFieldConditionMinAndMax'
                      | translate
                        : {
                            min: formConfiguration.conditional.field.min,
                            max: formConfiguration.conditional.field.max
                          }
                  }}</small
                >
              </div>
              <!--Validar campos de texto-->
              <input
                pInputText
                id="field_conditionalValue"
                name="conditionalValue"
                [minLength]="formConfiguration.conditional.field.min"
                [maxLength]="formConfiguration.conditional.field.max"
                [(ngModel)]="formConfiguration.conditional.value"
                #conditionalValue="ngModel"
                type="text"
                *ngIf="formConfiguration.conditional.field.type === 'text'"
              />
              <!--Validar campos de text area-->
              <textarea
                pInputTextarea
                type="text"
                id="field_conditionalValue"
                name="conditionalValue"
                [minLength]="formConfiguration.conditional.field.min"
                [maxLength]="formConfiguration.conditional.field.max"
                [(ngModel)]="formConfiguration.conditional.value"
                #conditionalValue="ngModel"
                *ngIf="formConfiguration.conditional.field.type === 'textarea'"
              ></textarea>
            </div>
            <!--Validar campos con opciones-->
            <p-dropdown
              id="dfield_conditionalValuer"
              name="field_conditionalValue"
              class="input p-col-12 p-p-0"
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              [options]="formConfiguration.conditional.field.options"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              [(ngModel)]="formConfiguration.conditional.value"
              *ngIf="formConfiguration.conditional.field.options.length"
            >
            </p-dropdown>
          </div>
          <button
            pButton
            pRipple
            [label]="'designer.addEdit.btnClearConditional' | translate"
            (click)="clearConditional()"
            type="button"
            class="p-button-secondary"
            style="width: max-content"
          ></button>
        </div>
      </p-tabPanel>

      <!--Quinta parte del formulario / Avanzando-->
      <p-tabPanel
        [header]="'designer.addEdit.btnAvanced' | translate"
        *ngIf="formConfiguration.showAvancedTab"
      >
        <div class="p-d-flex p-flex-column gap-4 p-p-4">
          <p class="p-m-0 step-subtitle">
            <span>{{ 'designer.addEdit.advanceInformation' | translate }}</span>
          </p>
          <!--Indica si el campo será visible desde el pdf-->
          <div class="p-d-flex p-flex-column gap-2">
            <app-label-tooltip
              [for]="'field_visible_pdf'"
              [label]="'designer.addEdit.labelDescriptionPdf' | translate"
              [tooltip]="
                'designer.addEdit.labelDescriptionPdfTooltip' | translate
              "
              [required]="true"
            ></app-label-tooltip>

            <p-selectButton
              name="visible"
              id="field_visible_pdf"
              [options]="formConfiguration.booleanTypes"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="field.templateOptions.isPdfVisible"
              [required]="true"
              (onChange)="updateField()"
              #pdfVisibleTemplate="ngModel"
            ></p-selectButton>
            <small
              class="form-error"
              *ngIf="
                pdfVisibleTemplate.invalid &&
                (pdfVisibleTemplate.touched || form.submitted)
              "
              >{{ 'general.required' | translate }}</small
            >
          </div>

          <!--Indica el label que tendrá desde el pdf-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="field.templateOptions.isPdfVisible"
          >
            <app-label-tooltip
              for="field_labelPdf"
              [label]="'designer.addEdit.labelViewPdf' | translate"
              [tooltip]="'designer.addEdit.labelViewPdfTooltip' | translate"
            ></app-label-tooltip>
            <app-simple-dictionary-input
              id="field_labelPdf"
              name="field_labelPdfl"
              [projectId]="projectId"
              [isRequired]="false"
              [value]="field.templateOptions.labelPdf"
              (outputEvent)="
                field.templateOptions.labelPdf = $event; updateField()
              "
            ></app-simple-dictionary-input>
          </div>

          <!--Indica como se visualaza la respuesta en el PDF-->
          <div
            class="p-d-flex p-flex-column gap-2"
            *ngIf="
              formConfiguration.inputsVisibility.pdfViewType &&
              field.templateOptions.isPdfVisible
            "
          >
            <app-label-tooltip
              for="drop_fieldType"
              [label]="'designer.addEdit.labelViewTypePdf' | translate"
              [tooltip]="'designer.addEdit.labelViewTypePdfTooltip' | translate"
            ></app-label-tooltip>
            <p-dropdown
              id="drop_fieldType"
              name="drop_fieldType"
              optionLabel="label"
              optionValue="value"
              class="input p-col-12 p-p-0"
              appendTo="body"
              [options]="formConfiguration.pdfViewTypes"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              [(ngModel)]="field.templateOptions.pdfViewType"
              (onChange)="updateField()"
            >
            </p-dropdown>
          </div>

          <!--Formulario con grupo repetible-->
          <div
            class="p-d-flex p-flex-column gap-4"
            *ngIf="formConfiguration.inputsVisibility.repeat"
          >
            <div class="p-d-flex p-flex-column gap-2">
              <app-label-tooltip
                [for]="'field_required'"
                [label]="'designer.addEdit.repetibleLabel' | translate"
                [tooltip]="'designer.addEdit.repetibleTooltip' | translate"
                [required]="true"
              ></app-label-tooltip>

              <p-selectButton
                name="repeat"
                id="field_repeat"
                [options]="formConfiguration.booleanTypes"
                optionLabel="label"
                optionValue="value"
                [(ngModel)]="formConfiguration.isRepeatField"
                [required]="true"
                (onChange)="updateField()"
                #repeatTemplate="ngModel"
              ></p-selectButton>
              <small
                class="form-error"
                *ngIf="
                  repeatTemplate.invalid &&
                  (repeatTemplate.touched || form.submitted)
                "
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-d-flex p-flex-column gap-2"
              *ngIf="formConfiguration.isRepeatField"
            >
              <app-label-tooltip
                for="field_btn_add_label"
                [label]="'designer.addEdit.repetibleAddButtonLabel' | translate"
                [tooltip]="
                  'designer.addEdit.repetibleAddButtonTooltip' | translate
                "
                [required]="true"
              ></app-label-tooltip>
              <app-simple-dictionary-input
                id="field_btn_add_label"
                name="field_btn_add_label"
                [projectId]="projectId"
                [isRequired]="true"
                [value]="field.templateOptions.addButtonText"
                (outputEvent)="
                  field.templateOptions.addButtonText = $event; updateField()
                "
              ></app-simple-dictionary-input>
            </div>
            <div
              class="p-d-flex p-flex-column gap-2 border-bottom"
              *ngIf="formConfiguration.isRepeatField"
            >
              <app-label-tooltip
                for="field_btn_remove_label"
                [label]="
                  'designer.addEdit.repetibleDeleteButtonLabel' | translate
                "
                [tooltip]="
                  'designer.addEdit.repetibleDeleteButtonTooltip' | translate
                "
                [required]="true"
              ></app-label-tooltip>
              <app-simple-dictionary-input
                id="field_btn_remove_label"
                name="field_btn_remove_label"
                [projectId]="projectId"
                [isRequired]="true"
                [value]="field.templateOptions.removeText"
                (outputEvent)="
                  field.templateOptions.removeText = $event; updateField()
                "
              ></app-simple-dictionary-input>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </form>

  <!--Preview-->
  <div class="p-d-flex p-flex-column gap-4">
    <div class="preview border" *ngIf="!formConfiguration.isGroupField">
      <div class="viewer">
        <h3>{{ 'designer.addEdit.previewTitle' | translate }}</h3>
      </div>
      <div class="p-p-4 max-h" style="min-height: 20rem">
        <formly-form
          [fields]="[field] | clearField | dictionaryField"
        ></formly-form>
      </div>
    </div>
    <div class="p-d-flex gap-2 p-justify-end">
      <button
        id="btn_cancelAnswer"
        pButton
        pRipple
        label="{{ 'general.cancel' | translate }}"
        type="button"
        class="p-button-secondary p-button-outlined"
        (click)="close()"
      ></button>
      <button
        pButton
        pRipple
        label="{{ 'designer.btnSaveInformation' | translate }}"
        (click)="submit()"
        type="button"
        class="p-button-secondary"
        style="width: max-content"
        [disabled]="form.invalid"
      ></button>
    </div>
  </div>
</main>
