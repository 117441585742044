import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from '../../../../shared/components/change-reason/change-reason.component';
import { ProjectService } from '../../project.service';
import {
  CreateProjectInput,
  GetProjectQuery,
  ProjectState,
} from '../../project.types';
const logger = new Logger('tp2-logger-projectChangeStatePage');
@Component({
  selector: 'app-change-state-project',
  templateUrl: './change-state-project.component.html',
  styleUrls: ['./change-state-project.component.scss'],
})
export class ChangeStateProjectComponent implements OnInit {
  project: CreateProjectInput = {
    code: '',
    groups: [],
    name: '',
    phases: [],
    state: ProjectState.IN_DESIGN,
    faultRecipients: [],
    isEdiaryActivated: false,
    isICActivated: false,
    isReportsActivated: false,
    isChatActivated: false,
    _lastUser: '',
  };
  editMode = false;
  isModal = false;
  expectedVersion = 0;
  id = '';
  projects = [];
  optionStateProject: any[] = [];
  @ViewChild('f', { static: true })
  centerForm!: NgForm;
  confirmChange = false;
  stateProject = '';
  valueNewState = '';
  constructor(
    private router: Router,
    private trialpalService: TrialpalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.editMode = this.config?.data?.isEdit;
    const id = this.config?.data?.id;
    this.isModal = this.config?.data?.isModal;
    if (id) {
      this.trialpalService.showSpinner('project.entity', 'GET');
      this.editMode = true;
      this.projectService
        .getProject(id)
        .then((response: GetProjectQuery) => {
          logger.debug('getProject response', response);
          this.expectedVersion = response._version;
          this.id = response.id;
          this.trialpalService.cleanQueryResponse(response);
          this.project = Object.assign(this.project, response);
          //Si no hay correos coloca support@integrait.co por default
          this.project.faultRecipients ??= ['support@integrait.co'];
          this.stateProject = this.project.state;
          this.optionStateProject = this.projectService.getProjectStatesMaster(
            this.stateProject
          );
        })
        .catch((error) => {
          logger.error('getProject error', error);
          this.trialpalService.showServiceError('project.changeState', error);
        })
        .finally(() => this.trialpalService.hideSpinner());
    }
  }
  confirm(form: NgForm): any {
    let msgDetail = '';
    msgDetail = this.trialpalService.translateService.instant(
      'project.messageSure',
      {
        entity: this.projectService.getProjectStateLabel(this.project.state),
      }
    );
    this.confirmationService.confirm({
      message: msgDetail,
      accept: () => {
        if (form.valid) {
          this.project.name = this.project.name.trim();
          if (this.editMode) {
            this.openReasonChange();
          }
        } else {
          this.trialpalService.showInvalidFormError();
          this.trialpalService.hideSpinner();
        }
      },
    });
  }
  openReasonChange() {
    const ref = this.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'project.actions.updateProject'
        ) +
        ': ' +
        this.project.code,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.project._changeReason = data;
          this.editProject();
        }
      },
    });
  }
  onSubmit(form: NgForm): void {
    if (this.valueNewState !== 'DENIED') {
      this.confirm(form);
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  editProject(): void {
    this.trialpalService.showSpinner('project.entity', 'UPDATE');
    this.projectService
      .updateProject(
        { id: this.id, state: this.project.state },
        this.id,
        this.expectedVersion
      )
      .then((response) => {
        logger.debug('updateProject response', response);
        this.projectService.updateCurrentProject(response);
        this.trialpalService.showMutationSuccess('project.entity', 'UPDATE');
        if (this.isModal) {
          this.ref.close(this.project);
        } else {
          this.router.navigate(['listProjects']);
        }
      })
      .catch((error) => {
        logger.error('updateProject error', error);
        this.trialpalService.hideSpinner();
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  onCancelar(): void {
    if (this.isModal) {
      this.ref.close(null);
    } else {
      this.centerForm.reset();
      this.router.navigate(['/listProjects']);
    }
  }
  change(event: any): void {
    if (
      event.value === 'IN_PROGRESS' &&
      (this.stateProject === 'IN_DESIGN' || this.stateProject === 'SUSPENDED')
    ) {
      this.valueNewState = 'IN_PROGRESS';
    } else if (
      event.value === 'SUSPENDED' &&
      this.stateProject === 'IN_PROGRESS'
    ) {
      this.valueNewState = 'SUSPENDED';
    } else if (
      event.value === 'ENDED' &&
      (this.stateProject === 'IN_PROGRESS' || this.stateProject === 'SUSPENDED')
    ) {
      this.valueNewState = 'ENDED';
    } else if (event.value === 'ARCHIVED' && this.stateProject === 'ENDED') {
      this.valueNewState = 'ARCHIVED';
    } else if (event.value === this.stateProject) {
      this.valueNewState = ' ';
    } else {
      this.valueNewState = 'DENIED';
    }
  }
}
