<app-maintenance-page
  *featureFlag="'maintenance-site'; else content"
></app-maintenance-page>

<ng-template #content>
  <div class="container-tp2">
    <div class="p-grid" *ngIf="hasProjectReadPermission">
      <expand-collapse
        class="p-col-12 p-mb-5 p-p-0"
        header="{{ 'project.fieldsetLegendBasicData' | translate }}"
      >
        <div class="grid p-justify-end p-mb-5">
          <div *ngIf="hasProjectCreatePermission">
            <button
              pButton
              label=" {{ 'project.buttonLabelEdit' | translate }}"
              icon="pi pi-pencil"
              iconPos="right"
              type="button"
              (click)="editProject()"
              class="p-button-rounded p-button-secondary p-button-outlined"
            ></button>
          </div>
          <div
            *ngIf="
              this.showChangeStateButton && this.hasProjectStatusPermission
            "
          >
            <button
              pButton
              label=" {{ 'project.changeState' | translate }}"
              icon="pi pi-pencil"
              iconPos="right"
              type="button"
              (click)="changeState()"
              badgeClass="color: white;"
              class="p-button-rounded p-button-success pbutton"
            ></button>
          </div>
          <div *ngIf="hasProjectListPermission">
            <button
              pButton
              label="{{ 'general.btnAuditTrace' | translate }}"
              type="button"
              icon="pi pi-file"
              iconPos="right"
              (click)="openAuditModal()"
              badgeClass="color: white;"
              class="p-button-rounded p-button-success pbutton"
            ></button>
          </div>
        </div>

        <div class="grid grid-3 gap-4 p-justify-between">
          <!--PROJECT CODE-->
          <div>
            <p class="data-title">{{ 'project.labelCode' | translate }}:</p>
            <p>
              <p-skeleton width="5rem" *ngIf="!project.code"></p-skeleton>
              <span *ngIf="project.code">{{ project.code }}</span>
            </p>
          </div>

          <!--SPONSOR NAME-->
          <div>
            <p class="data-title">{{ 'project.labelSponsor' | translate }}:</p>
            <p>
              <p-skeleton width="5rem" *ngIf="!project.sponsor"></p-skeleton>
              <span *ngIf="project.sponsor">{{ project.sponsor }}</span>
            </p>
          </div>

          <!--PROJECT NAME-->
          <div>
            <p class="data-title">
              <strong>{{ 'project.labelName' | translate }}:</strong>
            </p>
            <p>
              <p-skeleton width="5rem" *ngIf="!project.name"></p-skeleton>
              <span *ngIf="project.name">{{ project.name }}</span>
            </p>
          </div>

          <!--GROUPS-->
          <app-project-list
            [items]="isArrayGroup"
            [loading]="loading"
            label="{{ 'project.labelGroups' | translate }}"
          ></app-project-list>

          <!--VISITS-->
          <app-project-list
            [items]="isArrayVisit"
            [loading]="loading"
            label="{{ 'project.labelPhases' | translate }}"
          ></app-project-list>

          <!--SITES-->
          <app-project-list
            [items]="siteNames"
            [loading]="loading"
            label="{{ 'project.labelSites' | translate }}"
          ></app-project-list>

          <!--PROJECT STATE-->
          <div>
            <p class="data-title">
              <strong>{{ 'project.labelState' | translate }}:</strong>
            </p>

            <p class="status" [class]="stateClass">
              <p-skeleton width="5rem" *ngIf="!stateProject"></p-skeleton>
              <span *ngIf="stateProject"> {{ stateProject | translate }}</span>
            </p>
          </div>
        </div>
      </expand-collapse>

      <expand-collapse
        class="p-col-12 p-mb-5 p-p-0"
        header="{{ 'project.fieldsetLegendConfig' | translate }}"
        *ngIf="
          authService.isAdmin() ||
          hasSiteListPermission ||
          hasListUserPermission ||
          hasReportListPermission ||
          hasReportCreatePermission ||
          hasReportUpdatePermission ||
          hasReportDeletePermission ||
          hasDiaryModulePermission
        "
      >
        <div class="p-grid containerButtonActionProject">
          <div
            class="p-col buttonCenterX buttonCenterY"
            *ngIf="hasSiteListPermission"
            style="border: 20%"
          >
            <button
              id="btnSitesConf"
              [routerLink]="['/sites/listSites/' + projectId]"
              pButton
              type="button"
              icon="pi pi-map-marker"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4Sites' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX buttonCenterY"
            *ngIf="authService.isAdmin()"
          >
            <button
              id="btnSitesConf"
              [routerLink]="['/roles/listRoles/' + projectId]"
              pButton
              type="button"
              icon="pi pi-user"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'roles.entityPlural' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX buttonCenterY"
            *ngIf="hasListUserPermission"
          >
            <button
              id="btnUsersConf"
              pButton
              type="button"
              icon="pi pi-users"
              [routerLink]="['/user/listUser/' + projectId]"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4User' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX buttonCenterY"
            *ngIf="
              hasReportListPermission ||
              hasReportCreatePermission ||
              hasReportUpdatePermission ||
              hasReportDeletePermission
            "
          >
            <p-inputSwitch
              id="btnReportsSwitch"
              class="inputSwitch"
              *ngIf="hasReportCreatePermission"
              [(ngModel)]="project.isReportsActivated"
              (onChange)="changeViewProjectActived()"
            ></p-inputSwitch>
            <button
              id="btnReportsConf"
              [disabled]="!project.isReportsActivated"
              pButton
              [routerLink]="['/report/' + projectId]"
              type="button"
              icon="pi pi-file"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.labelReportsActivated' | translate }}</h4>
          </div>
          <div class="p-col buttonCenterX" *ngIf="hasDiaryModulePermission">
            <p-inputSwitch
              id="btnEdiarySwitchConf"
              class="inputSwitch"
              [(ngModel)]="project.isEdiaryActivated"
              (onChange)="changeViewProjectActived()"
              *ngIf="hasDiaryCreatePermission"
            ></p-inputSwitch>
            <button
              id="btnEdiaryConf"
              [disabled]="!project.isEdiaryActivated"
              [routerLink]="['/ediary/' + projectId]"
              pButton
              type="button"
              icon="pi pi-calendar"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.labelEdiaryActivated' | translate }}</h4>
          </div>
          <div class="p-col buttonCenterX" *ngIf="authService.isAdmin()">
            <p-inputSwitch
              id="btnChatSwitchConf"
              name="isChatActivated"
              class="inputSwitch"
              [(ngModel)]="project.isChatActivated"
              (onChange)="changeViewProjectActived()"
            ></p-inputSwitch>
            <button
              id="btnChatConf"
              [disabled]="!project.isChatActivated"
              [routerLink]="['/chat/chatConf/' + projectId]"
              pButton
              type="button"
              icon="pi pi-comments "
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>
              {{ 'project.labelChatActivated' | translate }}
            </h4>
          </div>
          <div class="p-col buttonCenterX" *ngIf="authService.isAdmin()">
            <p-inputSwitch
              id="btnICSwitchConf"
              name="isICActivated"
              class="inputSwitch"
              [(ngModel)]="project.isICActivated"
              (onChange)="changeViewProjectActived()"
            ></p-inputSwitch>
            <button
              id="btnICConf"
              [disabled]="!project.isICActivated"
              [routerLink]="['/informed-consent/' + projectId]"
              pButton
              type="button"
              icon="pi pi-info "
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>
              {{ 'project.labelICActivated' | translate }}
            </h4>
          </div>
          <div
            class="p-col buttonCenterX buttonCenterY"
            *ngIf="hasReadGroupVisitPermission"
          >
            <button
              id="btnOpenVisitGroupConf"
              (click)="openVisitsGroups()"
              pButton
              type="button"
              icon="pi pi-users"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.visitGroup' | translate }}</h4>
          </div>
        </div></expand-collapse
      >

      <expand-collapse
        class="p-col-12 p-p-0"
        header="{{ 'project.fieldsetLegendTracing' | translate }}"
      >
        <div class="p-grid containerButtonActionProject">
          <div class="p-col buttonCenterX" *ngIf="hasSubjectModulePermission">
            <button
              id="btnSubjectsConf"
              [routerLink]="['/subjects/' + projectId]"
              pButton
              type="button"
              icon="pi pi-users"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4Subjects' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX"
            *ngIf="project.isReportsActivated && hasFollowupReportsPermission"
          >
            <button
              id="btnReportStatus"
              [routerLink]="['/report/status/' + projectId]"
              pButton
              type="button"
              icon="pi pi-check"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4ReportStatus' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX"
            *ngIf="project.isEdiaryActivated && hasFollowupDiaryPermission"
          >
            <button
              id="btnEdiaryStatus"
              [routerLink]="['/ediary/status/' + projectId]"
              pButton
              type="button"
              icon="pi pi-check"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4EdiaryStatus' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX"
            *ngIf="
              project.isICActivated &&
              (authService.isAdmin() || authService.isInvestigator())
            "
          >
            <button
              id="btnInformedConsentFollowup"
              [routerLink]="['/informed-consent', projectId, 'follow-up']"
              pButton
              type="button"
              (click)="trackeConsentFollowUpEvent()"
              icon="pi pi-file-o"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4InformedConsentFollowup' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX"
            *ngIf="project.isChatActivated && authService.isInvestigator()"
          >
            <button
              id="btnChat"
              [routerLink]="['/chat/chatRoom/' + projectId]"
              pButton
              type="button"
              icon="pi pi-comments"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.h4Chat' | translate }}</h4>
          </div>
          <div class="p-col buttonCenterX" *ngIf="hasVersionReadPermissions">
            <button
              id="btnAppVersion"
              [routerLink]="['/app-versions/list-user-versions/' + projectId]"
              pButton
              type="button"
              icon="pi pi-mobile"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'project.appVersion' | translate }}</h4>
          </div>
          <div
            class="p-col buttonCenterX"
            *ngIf="
              project.isICActivated &&
              (authService.isAdmin() || authService.isInvestigator())
            "
          >
            <button
              pButton
              type="button"
              icon="pi pi-book"
              iconPos="right"
              (click)="gotToeConsentAudit()"
              badgeClass="color: white;"
              class="
                buttonPersonal
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-mr-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
            ></button>
            <h4>{{ 'informedConsent.viewAuditTrace' | translate }}</h4>
          </div>
        </div>
      </expand-collapse>
    </div>
  </div>
</ng-template>
