import { Component, OnInit } from '@angular/core';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TransformDatePipe } from '../../../../../../pipe/transform-date.pipe';
import { PaginationService } from '../../../../pagination.service';

@Component({
  selector: 'app-detail-days-comment',
  templateUrl: './detail-days-comment.component.html',
  styleUrls: ['./detail-days-comment.component.scss'],
})
export class DetailDaysCommentComponent implements OnInit {
  daysComments: any[] = [];
  areComments: boolean = false;
  constructor(
    private trialpalService: TrialpalService,
    public conciliationService: ConciliationService,
    public paginationService: PaginationService,
    public transformDatePipe: TransformDatePipe
  ) {}

  async ngOnInit() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'subject.tabheaderComments'
      ),
      'LIST'
    );
    this.buildSubjectComments();

    const { items } =
      await this.conciliationService.getCommentByEdiaryPhaseId();
    this.conciliationService.currentComment = items[0] ?? {};
    this.paginationService.dayName = '';
    this.trialpalService.hideSpinner();
  }

  buildSubjectComments() {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    dayInstances = dayInstances.filter((DI: any) => {
      return DI?.configuration?.areCommentsRequired;
    });

    this.daysComments = dayInstances;
    //Verifica si hay algun comentario lleno
    this.areComments = this.daysComments.some((day: any) => {
      return day?.dayInstance?.comments;
    });
  }

  formatDate(value: string) {
    const timeZone = Number(localStorage.getItem('timeZoneOffset') ?? 0);
    return this.transformDatePipe.transformToSiteDate(value, timeZone);
  }
}
