<!--Numeric field-->
<div class="field" *ngIf="confSymptom?.intensityType === NUMERIC_VALUE">
  <label for="txt_size" class="required">
    {{ confSymptom.intensitySizeLabel ?? '' | dictionary }}
  </label>
  <p-inputNumber
    inputId="txt_size"
    name="size"
    mode="decimal"
    class="numeric-size"
    [minFractionDigits]="confSymptom.decimalPlaces ?? 0"
    [maxFractionDigits]="confSymptom.decimalPlaces ?? 0"
    [min]="minSize"
    [max]="maxSize"
    [(ngModel)]="symptomInstanceInput.size"
    (onInput)="buildSymptomInstanceIntensity()"
    [required]="true"
    #txtSize="ngModel"
    [disabled]="symptomInstanceInput?.rememberNoValuesTaken ?? false"
  ></p-inputNumber>
  <small class="form-error" *ngIf="txtSize.invalid && txtSize.touched">{{
    'general.required' | translate
  }}</small>
</div>

<!--Intensity field-->
<div class="field" *ngIf="confSymptom?.intensityType === MULTIPLE_CHOICE">
  <label for="drop_intensity" class="required">
    {{ 'symptominstance.intensity' | translate }}
  </label>
  <p-dropdown
    inputId="drop_intensity"
    name="intensity"
    optionLabel="label"
    optionValue="intensity"
    class="input p-p-0"
    appendTo="body"
    [options]="intensityScaleOptions"
    [(ngModel)]="symptomInstanceInput.intensity"
    placeholder="{{ 'general.selectPlaceholder' | translate }}"
    emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
    emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
    [required]="true"
    [disabled]="symptomInstanceInput?.rememberNoValuesTaken ?? false"
    #dropIntensity="ngModel"
  >
    <ng-template let-intensity pTemplate="item">
      <div>
        <div
          pTooltip="{{ intensity.label }}"
          tooltipPosition="left"
          style="white-space: pre-line"
        >
          {{ intensity.label }}
        </div>
      </div>
    </ng-template>
  </p-dropdown>
  <small
    class="form-error"
    *ngIf="dropIntensity.invalid && dropIntensity.touched"
    >{{ 'general.required' | translate }}</small
  >
</div>

<!--Numeric range field-->
<div class="field" *ngIf="confSymptom?.intensityType === RANGE">
  <label for="drop_integer" class="required">
    {{ confSymptom.intensitySizeLabel ?? '' | dictionary }}
  </label>
  <div class="p-d-flex p-p-0 p-col">
    <div
      class="p-p-0 p-mr-1 p-col-6 field"
      [ngClass]="{ 'p-col-12': !showDecimalOption }"
    >
      <p-dropdown
        inputId="drop_integer"
        name="integer"
        class="input"
        appendTo="body"
        [options]="integerScaleOptions"
        [(ngModel)]="symptomInteger"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        (onChange)="buildSymptomInstanceSizeAndIntensity()"
        [required]="true"
        [disabled]="symptomInstanceInput?.rememberNoValuesTaken ?? false"
        #integer="ngModel"
      >
      </p-dropdown>
      <small class="form-error" *ngIf="integer.invalid && integer.touched">{{
        'general.required' | translate
      }}</small>
    </div>
    <div class="p-col-6 p-p-0 field" *ngIf="showDecimalOption">
      <p-dropdown
        inputId="drop_decimal"
        name="decimal"
        class="input"
        appendTo="body"
        [options]="decimalScaleOptions"
        [(ngModel)]="symptomDecimal"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        (onChange)="buildSymptomInstanceSizeAndIntensity()"
        [required]="true"
        [disabled]="symptomInstanceInput?.rememberNoValuesTaken ?? false"
        #decimal="ngModel"
      >
      </p-dropdown>
      <small class="form-error" *ngIf="decimal.invalid && decimal.touched">{{
        'general.required' | translate
      }}</small>
    </div>
  </div>
</div>

<!--No Values taken-->
<div class="field" *ngIf="confSymptom?.showNoValuesTaken">
  <label for="cbx_no_value_taken" class="required">
    {{ confSymptom?.noValuesTakenLabel ?? '' | dictionary }}
  </label>
  <p-inputSwitch
    [(ngModel)]="symptomInstanceInput.rememberNoValuesTaken"
    (onChange)="noValuesTakenChange()"
    required
    inputId="cbx_no_value_taken"
    name="noValuesTaken"
  ></p-inputSwitch>
</div>

<!--Intensity Questions field-->
<div
  class="field border-bottom"
  *ngIf="confSymptom?.isIntensityQuestionsRequired"
>
  <label style="font-size: var(---text-title-size)">{{
    'symptom.intensityQuestions' | translate
  }}</label>
  <div
    class="field"
    *ngFor="
      let intensityQuestionAnswerInput of symptomInstanceInput.intensityQuestionAnswers
    "
  >
    <label class="required">{{
      intensityQuestionAnswerInput.question?.question ?? '' | dictionary
    }}</label>
    <p-selectButton
      [name]="
        'cbx_intensityQuestion' + intensityQuestionAnswerInput.question?.id
      "
      [options]="booleanOptions"
      [(ngModel)]="intensityQuestionAnswerInput.answer"
      (onChange)="buildSymptomInstanceIntensity()"
      optionLabel="label"
      optionValue="value"
      [required]="true"
      #intensityQuestion="ngModel"
    ></p-selectButton>
    <small
      class="form-error"
      *ngIf="intensityQuestion.invalid && intensityQuestion.touched"
      >{{ 'general.required' | translate }}</small
    >
  </div>
</div>

<!--Remember StartDate field-->
<div class="field" *ngIf="confSymptom?.isStartDateRequired">
  <label for="cbx_startDate" class="required">
    {{ 'symptominstance.startDateQuestion' | translate }}
  </label>
  <p-selectButton
    inputId="cbx_startDate"
    name="rememberStartDate"
    [options]="booleanOptions"
    [(ngModel)]="symptomInstanceInput.rememberStartDate"
    (onChange)="updateRememberDate()"
    optionLabel="label"
    optionValue="value"
    [required]="true"
  ></p-selectButton>
</div>

<!--StartDate field-->
<div
  class="field"
  *ngIf="
    confSymptom?.isStartDateRequired && symptomInstanceInput.rememberStartDate
  "
>
  <label for="jcl_startDate" class="required">
    {{ 'symptominstance.startDate' | translate }}
  </label>
  <p-calendar
    inputId="jcl_startDate"
    name="startDate"
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%' }"
    [showIcon]="true"
    [maxDate]="currentMaxDate"
    [(ngModel)]="symptomInstanceInput.startDate"
    (onSelect)="buildMinDate()"
    appendTo="body"
    placeholder="{{
      'addEditSubjectComponent.PlaceHolderFormatDate' | translate
    }}"
    dateFormat="dd-mm-yy"
    [required]="true"
    #jclStartDate="ngModel"
  ></p-calendar>
  <small
    class="form-error"
    *ngIf="jclStartDate.invalid && jclStartDate.touched"
    >{{ 'general.required' | translate }}</small
  >
</div>

<!--Remember finishDate field-->
<div class="field" *ngIf="confSymptom?.isFinishDateRequired">
  <label for="cbx_finishDate" class="required">
    {{ 'symptominstance.endDateQuestion' | translate }}
  </label>
  <p-selectButton
    inputId="cbx_finishDate"
    name="rememberFinishDate"
    [options]="booleanOptions"
    [(ngModel)]="symptomInstanceInput.rememberFinishDate"
    (onChange)="updateRememberDate()"
    optionLabel="label"
    optionValue="value"
    [required]="true"
  ></p-selectButton>
</div>
<!--FinishDate field-->
<div
  class="field"
  *ngIf="
    confSymptom?.isFinishDateRequired && symptomInstanceInput.rememberFinishDate
  "
>
  <label for="jcl_finishDate" class="required">
    {{ 'symptominstance.endDate' | translate }}
  </label>
  <p-calendar
    inputId="jcl_finishDate"
    name="finishDate"
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%' }"
    [showIcon]="true"
    [minDate]="currentMinDate"
    [maxDate]="currentDate"
    [(ngModel)]="symptomInstanceInput.finishDate"
    (onSelect)="buildCurrentMaxDate(); updateSymptomInstanceState()"
    appendTo="body"
    placeholder="{{
      'addEditSubjectComponent.PlaceHolderFormatDate' | translate
    }}"
    dateFormat="dd-mm-yy"
    [required]="true"
    #jclFinishDate="ngModel"
  ></p-calendar>
  <small
    class="form-error"
    *ngIf="jclFinishDate.invalid && jclFinishDate.touched"
    >{{ 'general.required' | translate }}</small
  >
</div>
<div class="field" *ngIf="isSymptomInstanceChange && isEdition">
  <change-reason
    name="symptom_reason"
    [label]="'audit.reason' | translate"
    [(ngModel)]="changeReason"
    [required]="true"
    #changeReasonTemplate="ngModel"
  ></change-reason>
  <small
    class="form-error p-col-12"
    *ngIf="changeReasonTemplate?.invalid && changeReasonTemplate.touched"
    >{{ 'general.required' | translate }}</small
  >
</div>
