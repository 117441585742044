<app-button-refresh-data
  [showSpinner]="showSpinner"
  *ngIf="idSubject"
  (buttonAction)="ngOnInit()"
></app-button-refresh-data>

<div
  class="container-tp2 containerAll"
  id="container"
  *ngIf="!showSpinner || !idSubject"
>
  <div class="p-grid p-fluid p-justify-end">
    <div
      class="p-col-12"
      style="display: flex; align-items: center"
      *ngIf="!idSubject"
    >
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        id="btn_forward"
        class="
          p-button-rounded
          p-button-secondary
          p-button-outlined
          p-button
          p-button-icon-only
        "
        (click)="forward()"
      ></button>
      <div class="p-col-10">
        <h1>{{ 'user.h1ListUser' | translate }}</h1>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-4" *ngIf="!isSubjectListiew">
      <label>{{ 'user.filterbyrole' | translate }}</label>
      <p-dropdown
        [showClear]="true"
        id="grupos"
        class="input"
        placeholder="{{ 'general.choose' | translate }}"
        name="grupos"
        [(ngModel)]="selectedRole"
        [options]="userGroups"
        (onChange)="filterMaster()"
        optionLabel="name"
        optionValue="value"
      ></p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-xl-4" *ngIf="!isSubjectListiew">
      <label>{{ 'user.filterbyproject' | translate }}</label>
      <p-dropdown
        [showClear]="true"
        [disabled]="blockDropdownProject"
        id="projects"
        class="input"
        placeholder="{{ 'general.choose' | translate }}"
        name="projects"
        [(ngModel)]="selectedProject"
        [options]="projects"
        (onChange)="filterMaster()"
        optionLabel="name"
        optionValue="value"
      ></p-dropdown>
    </div>

    <div class="p-col-12 p-md-6 p-xl-4">
      <label>{{ 'user.filterbyattribute' | translate }} </label>
      <input
        id="generalFilter"
        class="input"
        type="text"
        pInputText
        [(ngModel)]="filtroGeneral"
        (ngModelChange)="filterMaster()"
      />
    </div>

    <div
      class="p-col-12 p-md-6 p-xl-4 container_button_add"
      *ngIf="hasUserCreatePermission"
    >
      <button
        id="button"
        pButton
        label="{{ 'general.add' | translate }}"
        type="button"
        (click)="create()"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>

  <div class="p-grid p-fluid" *ngIf="usersFiltered.length > 0">
    <app-tpcard
      id="cards"
      *ngFor="let u of usersPaginated"
      class="p-col-12 p-md-6 p-lg-4"
      [tpCardData]="buildTPCardData(u)"
      (edit)="edit($event)"
      (delete)="openChangeReason($event)"
      (activate)="enabledDisabledUser($event)"
    ></app-tpcard>
    <div class="p-col-12">
      <p-paginator
        id="paginator"
        [rows]="pagesize"
        [totalRecords]="usersFiltered.length"
        (onPageChange)="paginate($event)"
      ></p-paginator>
    </div>
  </div>

  <div class="p-grid p-fluid" *ngIf="usersFiltered.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>

<app-progress-spinner *ngIf="showSpinner && idSubject"></app-progress-spinner>
