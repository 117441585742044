<p-card *ngIf="dataTable?.valueTable?.length > 0">
  <p-table
    #table
    [value]="dataTable.valueTable || []"
    [columns]="dataTable.cols || []"
    [(selection)]="dataTable"
    dataKey="id"
    [rowHover]="true"
    [rows]="rowsPerPage"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [paginator]="true"
    responsiveLayout="scroll"
    selectionMode="single"
    currentPageReportTemplate="
      {{
        'general.paginatorValues'
          | translate
            : {
                first: '{{first}}'
              }
              :
              {
                last:  '{{ last }} '
              }
              :
              {
                totalRecords: '{{ totalRecords }}'
              }
      }}"
    [filterDelay]="0"
    [globalFilterFields]="dataTable.globalFilterFields || []"
  >
    <ng-template
      pTemplate="caption"
      *ngIf="dataTable?.showResetFilter || dataTable?.showGeneralSearch"
    >
      <div class="p-d-flex">
        <button
          *ngIf="dataTable?.showResetFilter"
          pButton
          label="{{ 'general.clearFilter' | translate }}"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="clear(table)"
        ></button>

        <button
          *ngIf="hasDownloadTableDataPermission"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          class="p-button-success p-mr-2 p-ml-2"
          (click)="exportExcel()"
          tooltipPosition="bottom"
        ></button>
        <span
          class="p-input-icon-left p-ml-auto"
          *ngIf="dataTable?.showGeneralSearch"
        >
          <span class="pi pi-search"></span>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal(handleInput($event), 'contains')"
            placeholder="{{ 'general.searchPlaceholderGeneral' | translate }}"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          id="{{ col.field }}"
          *ngFor="let col of dataTable?.cols"
          pSortableColumn="{{ col.field }}"
          style="min-width: 260px"
        >
          <div
            class="p-d-flex p-jc-between p-ai-center"
            *ngIf="col.showBasicSearch"
          >
            <div>
              {{ col?.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </div>
            <p-columnFilter
              [type]="col.type"
              [field]="col.field"
              display="menu"
              class="ml-auto"
            ></p-columnFilter>
          </div>
          <!-- busqueda completa o exacta -->
          <div
            class="p-d-flex p-jc-between p-ai-center"
            *ngIf="col.showFilterComplete"
          >
            {{ col?.header }}
            <p-columnFilter
              [field]="col.field"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="header">
                <div class="p-px-3 p-pt-3 p-pb-0">
                  <span class="p-text-bold">
                    {{ 'general.titleFilterTable' | translate }}
                  </span>
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="col.optionsFilterComplete || []"
                  placeholder="{{ 'general.titleFilterTable' | translate }}"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="p-ml-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>

          <div
            class="p-d-flex p-jc-between p-ai-center"
            *ngIf="col.showSearchState"
          >
            {{ col?.header }}
            <p-columnFilter field="status" matchMode="equals" display="menu">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-dropdown
                  [ngModel]="value"
                  [options]="col.optionsSearchState || []"
                  (onChange)="filter($event.value)"
                  placeholder="{{ 'general.titleFilterTable' | translate }}"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option?.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>

        <th
          id="btn_actionButton"
          *ngIf="dataTable?.showMenuActionButton"
          style="width: 8rem"
        >
          {{ 'general.actions' | translate }}
        </th>
        <th
          id="label_actions"
          style="text-align: end"
          *ngIf="dataTable?.actionsTitle"
        >
          {{ dataTable.actionsTitle }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-value let-index="rowIndex" id="sfddfdfs">
      <tr
        class="p-selectable-row"
        (click)="onOpenDetail(value)"
        [ngClass]="{ 'row-green': value['greenColor'] }"
        id="{{ id + index }}"
      >
        <td
          *ngFor="let col of dataTable?.cols"
          style="min-width: 260px; word-break: break-word"
        >
          <span class="p-column-title">{{ col?.header }}</span>
          <span *ngIf="col.type !== 'date'"> {{ value[col.field] }} </span>
          <span *ngIf="col.type === 'date'">
            {{ transformDatePipe.transformToSiteHour(value[col.field], -5) }}
          </span>
          <span *ngIf="col.showFilterComplete">
            {{ value[col.field]?.name }}
          </span>
        </td>
        <td *ngIf="dataTable?.showMenuActionButton" style="text-align: center">
          <p-menu
            #menu
            [popup]="true"
            [model]="dataTable.menuOptions || []"
            [style]="{ 'white-space': 'nowrap', width: 'auto' }"
            [appendTo]="table"
          >
          </p-menu>
          <button
            id="btn_toggle"
            name="btn_toggle"
            type="button"
            pButton
            icon="pi pi-ellipsis-v"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
            (click)="onMenuOption($event, value, menu)"
          ></button>
        </td>
        <td
          *ngIf="
            dataTable?.customButton1 ||
            dataTable.showDeleteButton ||
            dataTable.showEditButton ||
            dataTable?.customButton2
          "
          style="
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            height: 4rem;
          "
        >
          <button
            *ngIf="dataTable?.customButton1 && !value['hideCustomButton1']"
            id="btn_customButton1"
            type="button"
            class="p-button-secondary customButtons"
            pButton
            [title]="value?.rowTitle || dataTable.customButton1?.title || ''"
            [icon]="value?.rowIcon || dataTable.customButton1?.icon"
            (click)="onCustomAction1($event, value)"
            [pTooltip]="dataTable.customButton1?.tooltipCustomButtom1 || ''"
          ></button>
          <button
            *ngIf="dataTable?.customButton2 && !value['hideCustomButton2']"
            id="btn_customButton2"
            type="button"
            class="p-button-secondary"
            pButton
            [icon]="dataTable.customButton2?.icon"
            (click)="onCustomAction2($event, value)"
            [pTooltip]="dataTable.customButton2?.tooltipCustomButtom2 || ''"
          ></button>
          <button
            *ngIf="dataTable?.customButton3 && !value['hideCustomButton3']"
            id="btn_customButton3"
            type="button"
            class="p-button-secondary"
            pButton
            [icon]="dataTable.customButton3?.icon"
            (click)="onCustomAction3($event, value)"
            [pTooltip]="dataTable.customButton3?.tooltipCustomButtom3 || ''"
          ></button>
          <button
            *ngIf="dataTable.showDeleteButton"
            type="button"
            class="p-button-danger customButtons"
            pButton
            title="{{ 'user.buttonDelete' | translate }}"
            icon="pi pi-trash"
            (click)="onDelete($event, value)"
          ></button>

          <button
            *ngIf="dataTable.showEditButton"
            type="button"
            class="p-button-primary customButtons"
            pButton
            title="{{ 'user.buttonEdit' | translate }}"
            icon="pi pi-pencil"
            (click)="onEdit($event, value)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">{{ 'general.noData' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
