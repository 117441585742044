import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { ConfEDiary } from 'src/app/modules/ediary/ediary.types';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InstanceState } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { ViewPdfComponent } from 'src/app/shared/components/view-pdf/view-pdf.component';
import {
  ButtonItem,
  Roles,
  Tp2Card,
  Tp2CardContent,
} from 'src/app/shared/global.variables';
import { SubjectsService } from '../../subjects.service';
import { DayInstance, Subject } from '../../subjects.types';
import { ManagePdfVersionsComponent } from '../manage-pdf-versions/manage-pdf-versions.component';
import { DisabledEnabledDiarySubjectComponent } from './disabled-enabled-diary-subject/disabled-enabled-diary-subject.component';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';

const logger = new Logger('tp2-logger-subjectEdiaryPage');
@Component({
  selector: 'app-daily-pdf-subject',
  templateUrl: './daily-pdf-subject.component.html',
  styleUrls: ['./daily-pdf-subject.component.scss'],
})
export class DailyPDFSubjectComponent implements OnInit, OnChanges {
  @Input() timeZoneOffset = 0;
  @Input() subject!: Subject;
  @Input() isOpened: boolean = false;
  dailyPDF = {
    dailyPDF: '',
    projectId: '',
  };
  ediaryPhaseCards: Tp2Card[] = [];
  confEdiary!: ConfEDiary;
  eDiaryPhases: any[] = [];
  dayInstances: any[] = [];
  subjectId: any = null;
  dailyPDFName = '';
  isDaysReported = 0;
  display: boolean = false;
  projectLanguage: string = '';
  projectId: string = '';
  showSpinner: boolean = true;
  hasSubjectSuspendVisitPermission: boolean = false;
  hasSubjectDiaryAssesmentPermissions: boolean = false;
  shouldShowPDFDiary: boolean = false;
  shouldShowPDFAssessment: boolean = false;
  DETAIL_SUBJECT_EDIARY_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_EDIARY_TOUR;

  constructor(
    private activeRoute: ActivatedRoute,
    private subjectsService: SubjectsService,
    public sanitizer: DomSanitizer,
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe,
    private dialogService: DialogService,
    private authService: AuthService,
    private ediaryService: EdiaryService,
    private confirmationService: ConfirmationService,
    private route: Router,
    private userPermissionsService: UserPermissionsService,
    private pageTourService: PageTourService
  ) {}

  openModal(
    ediaryPhase: any,
    isConciliation: boolean = false,
    analyticsSource: string = ''
  ) {
    const newEdiaryPhase = JSON.parse(JSON.stringify(ediaryPhase));
    if (isConciliation) {
      newEdiaryPhase.pdfFiles = [
        ...(ediaryPhase?.pdfFiles ?? []).filter((file: any) => {
          return file?.fileUrl?.includes('eDiaryConciliacion');
        }),
      ];
    } else {
      newEdiaryPhase.pdfFiles = [
        ...(ediaryPhase?.pdfFiles ?? []).filter((file: any) => {
          return !file?.fileUrl?.includes('eDiaryConciliacion');
        }),
      ];
    }

    const noPDFFileFound = newEdiaryPhase.pdfFiles?.length === 0;
    const showReaderPdf = this.authService.isReader() && noPDFFileFound;

    if (showReaderPdf || noPDFFileFound || !newEdiaryPhase.pdfFiles) {
      this.trialpalService.messageService.clear();
      return this.trialpalService.messageService.add({
        severity: 'warn',
        summary: this.trialpalService.translateService.instant(
          'user.messageWarningPDF.summary'
        ),
        detail: !newEdiaryPhase.phase
          ? this.trialpalService.translateService.instant(
              'user.messageWarningPDF.detail'
            )
          : this.trialpalService.translateService.instant('subject.notPDF'),
        life: !newEdiaryPhase.phase ? 10000 : 5000,
      });
    }
    this.selectPDF(newEdiaryPhase, analyticsSource);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.showSpinner = true;
      let eDiarys: any[] = [];
      this.eDiaryPhases = [];
      this.dayInstances = [];
      this.subjectId = this.activeRoute.snapshot.params.subjectId;
      let project = { id: '' };
      this.setPermissions();
      this.projectLanguage = this.getProjectLanguage() ?? '';
      eDiarys = await this.ediaryService.eDiaryInstanceBySubjectId(
        this.subjectId
      );
      this.shouldShowPDFDiary =
        this.authService.isAdmin() ||
        (await this.userPermissionsService.hasPermission([
          TP2Permission.SubjectDiaryPDF,
        ]));
      this.shouldShowPDFAssessment =
        this.authService.isAdmin() ||
        (await this.userPermissionsService.hasPermission([
          TP2Permission.SubjectDiaryAssessmentPDF,
        ]));

      logger.debug('eDiaryInstanceBySubjectId response', eDiarys);
      if (eDiarys) {
        await this.getEDiaryPhaseInstances();
        project.id = eDiarys.length > 0 ? eDiarys[0].subject.projectId : '';
      }
      await this.getConfEdiaryByProjectId(project.id);
      this.ediaryPhaseCards = [];
      await this.buildCards(this.eDiaryPhases, false);
      if (this.eDiaryPhases.length > 1 && eDiarys) {
        await this.buildCards(eDiarys, true);
      }

      this.showSpinner = false;
      this.startTour();
    } catch (error) {
      logger.error('Error eDiary', error);
    }
  }

  ngOnChanges() {
    this.startTour();
  }

  async setPermissions() {
    this.hasSubjectSuspendVisitPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectSuspendVisit,
        Roles.Admin,
      ]);

    this.hasSubjectDiaryAssesmentPermissions =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectDiaryAssesment,
        Roles.Admin,
      ]);
  }

  async buildCards(eDiarys: any, isEdiaryConf?: boolean) {
    for (let ediary of eDiarys) {
      const ediaryCard = await this.buildEdiaryCards(ediary, isEdiaryConf);
      this.ediaryPhaseCards.push(ediaryCard);
    }
  }

  getProjectLanguage() {
    return localStorage.getItem('lang');
  }

  async getEDiaryPhaseInstances(): Promise<void> {
    const eDiaryPhasesInstance =
      await this.subjectsService.eDiaryPhaseInstanceBySubjectId(this.subjectId);
    logger.debug('eDiaryPhaseInstance response', eDiaryPhasesInstance);
    const eDiaryPhase = eDiaryPhasesInstance.items?.filter(
      (ediaryPhase: any) =>
        !ediaryPhase._deleted && ediaryPhase.state !== InstanceState.DELETED
    );
    if (eDiaryPhase && eDiaryPhase.length > 0) {
      this.eDiaryPhases = this.sortInstance(eDiaryPhase);
      for (const phase of eDiaryPhase) {
        const eDiaryPhaseId = phase!.id;
        await this.getDayInstanceByEdiaryPhase(eDiaryPhaseId);
      }
    }
  }
  async getDayInstanceByEdiaryPhase(eDiaryPhaseId: any) {
    const dayInstances =
      await this.subjectsService.dayInstanceByEDiaryPhaseInstance(
        eDiaryPhaseId
      );
    logger.debug('dayInstanceByEDiaryPhaseInstance response', dayInstances);
    if (dayInstances && dayInstances.items.length > 0) {
      const days = dayInstances.items.filter((x: any) => !x._deleted);
      this.dayInstances = this.dayInstances.concat(days);
    }
    this.isDaysReported = this.dayInstances.filter(
      (x: any) => x.state === 'COMPLETED'
    ).length;
  }
  sortInstance(instance: any) {
    return [...instance].sort(function (a: any, b: any) {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }
  daysReport(res: any): any {
    let sum = 0;
    for (const p of res) {
      sum += p.dayInstances.items.filter(
        (a: any) => a.state === 'COMPLETED'
      ).length;
    }
    return sum;
  }
  selectPDF(p: any, analyticsSource: string): void {
    if (p.pdfFiles) {
      const actualPDF = p.pdfFiles.length - 1;
      this.dailyPDFName = p.pdfFiles[actualPDF].fileUrl;
      this.openPdfModal(this.dailyPDFName, analyticsSource);
    } else {
      this.showViaService();
    }
  }

  getAmountToReport(res: any): any {
    return this.dayInstances.filter(
      (a: any) =>
        res.id === a.eDiaryPhaseInstanceId &&
        a.state !== InstanceState.SUSPENDED &&
        a.state !== InstanceState.DELETED
    ).length;
  }
  getDaysReported(res: any): any {
    return this.dayInstances.filter(
      (a: any) => res.id === a.eDiaryPhaseInstanceId && a.state === 'COMPLETED'
    ).length;
  }

  isDaysPastToCurrentDate(ediaryPhase: any): any {
    const validStates: InstanceState[] = [
      InstanceState.COMPLETED,
      InstanceState.PENDING,
    ];

    const dayInstances = this.dayInstances.filter(
      (dayInstance: DayInstance) => {
        return dayInstance.confDay?.confForm?.isReconciliationRequired;
      }
    );

    const dayInstancesToConciliate = dayInstances.filter((day: any) => {
      const isBeforeToCurrentDate = this.trialpalService.isBeforeToCurrentDate(
        day.finishDate
      );

      const isEDiaryPhaseInstanceId =
        ediaryPhase.id === day.eDiaryPhaseInstanceId;

      //Valida si el estado es diferente a suspendido o eliminado
      const isAValidState = validStates.includes(day.state);

      return isBeforeToCurrentDate && isEDiaryPhaseInstanceId && isAValidState;
    });
    return dayInstancesToConciliate.length > 0;
  }

  getLastReportedDate(res: any): any {
    let lastReportDate = this.trialpalService.translateService.instant(
      'report.DayReportedNot'
    );
    let lastUpdate = 0;
    const completedInsntaces = this.dayInstances.filter(
      (report: any) =>
        res.id === report.eDiaryPhaseInstanceId &&
        report.state === InstanceState.COMPLETED
    );
    for (const p of completedInsntaces) {
      let lastTimeUpdatedAt = new Date(p.completedDate).getTime();
      if (lastTimeUpdatedAt > lastUpdate) {
        lastUpdate = lastTimeUpdatedAt;
        lastReportDate = this.transformDatePipe.transformToSiteHour(
          new Date(lastUpdate),
          this.timeZoneOffset
        );
      }
    }

    return lastReportDate;
  }

  async buildEdiaryCards(ediaryPhaseInstance: any, isEdiaryConf?: boolean) {
    //Obtiene la información del diario electronico
    const { phase, dateInfo, daysReport, isPastEdiaryPhaseInstance } =
      this.buildTp2CardInfo(ediaryPhaseInstance, isEdiaryConf);

    //Obtiene el contenido de la card
    const content = this.buildTp2CardContent(
      daysReport,
      dateInfo,
      isEdiaryConf
    );

    const showConciliationButton =
      this.isConciliationActive(
        ediaryPhaseInstance,
        isPastEdiaryPhaseInstance
      ) && !isEdiaryConf;

    //Obtiene las opciones de la tarjeta
    const menu = await this.buildTp2CardMenuButtons(
      ediaryPhaseInstance,
      showConciliationButton,
      isEdiaryConf
    );

    const buttons = this.buildTp2CardButtons(menu, isEdiaryConf);

    let isConciliationLocked = this.subjectsService.checkConciliationLock(
      ediaryPhaseInstance,
      this.authService.getUsername()
    );

    let borderColor = isEdiaryConf ? '#18DF38' : '#005265';
    let cardBackground = isEdiaryConf ? '#C2FFCC' : '#fff';
    borderColor =
      isConciliationLocked && showConciliationButton ? '#A36B18' : borderColor;

    return {
      borderColor,
      buttonItems: buttons,
      cardBackground,
      content: content,
      data: ediaryPhaseInstance,
      id: ediaryPhaseInstance.id,
      isButtonsEnabled: menu.length <= 2,
      isMenuEnabled: menu.length > 2,
      isFooterEnabled: showConciliationButton && isConciliationLocked,
      islabelsEnabled:
        this.hasPDFConciliated(ediaryPhaseInstance) &&
        this.shouldShowPDFAssessment,
      footerContent: this.trialpalService.translateService.instant(
        'conciliation.userLock',
        { user: ediaryPhaseInstance?.locked?.user }
      ),
      menu,
      textHeader: phase,
      iconHeader: isEdiaryConf ? 'pi pi-check' : '',
    };
  }

  //Se encarga de construir el contenido de la tarjeta
  buildTp2CardContent(
    daysReport: any,
    dateInfo: any,
    isEdiaryConf?: boolean
  ): Tp2CardContent[] {
    const content: Tp2CardContent[] = [];
    content.push({
      title: this.trialpalService.translateService.instant(
        'ediary.components.generalConfiguration.days'
      ),
      description: daysReport,
      icon: 'pi pi-clock',
      colorIcon: isEdiaryConf ? '#1FBA38' : '#205163',
    });
    content.push({
      title: this.trialpalService.translateService.instant('audit.date'),
      description: dateInfo,
      icon: 'pi pi-calendar',
      colorIcon: isEdiaryConf ? '#1FBA38' : '#205163',
    });
    return content;
  }

  //Se encarga de construir el menu
  async buildTp2CardMenuButtons(
    ediaryPhaseInstance: any,
    showConciliationButton: boolean = false,
    isEDiaryConf?: boolean
  ): Promise<MenuItem[]> {
    const menu: MenuItem[] = [];
    const showVersionButton =
      ediaryPhaseInstance?.pdfFiles &&
      ediaryPhaseInstance?.pdfFiles?.length > 0 &&
      (this.authService.isAdmin() ||
        (await this.userPermissionsService.hasPermission([
          TP2Permission.SubjectDiaryPDF,
          TP2Permission.SubjectDiaryGeneratePDF,
        ])) ||
        (this.hasPDFConciliated(ediaryPhaseInstance) &&
          (await this.userPermissionsService.hasPermission([
            TP2Permission.SubjectDiaryAssessmentPDF,
          ]))));

    const daysReported = this.getDaysReported(ediaryPhaseInstance);

    const showPdfButton =
      (daysReported > 0 || isEDiaryConf) &&
      (await this.isUserAuthorized()) &&
      !this.hasPDFConciliated(ediaryPhaseInstance);

    const showSuspendEdiaryButton =
      !isEDiaryConf && this.hasSubjectSuspendVisitPermission;

    if (showConciliationButton) {
      const conciliationText = this.trialpalService.translateService.instant(
        'conciliation.labelButtonConciliation'
      );
      let isConciliationLocked = this.subjectsService.checkConciliationLock(
        ediaryPhaseInstance,
        this.authService.getUsername()
      );
      menu.push({
        label: conciliationText,
        tooltip: conciliationText,
        icon: 'pi pi-briefcase',
        disabled: isConciliationLocked,
        command: () => {
          this.goToConciliate(ediaryPhaseInstance);
        },
      });
    }

    if (showVersionButton) {
      const versionText =
        this.trialpalService.translateService.instant('general.versions');
      menu.push({
        label: versionText,
        tooltip: versionText,
        icon: 'pi pi-file',
        command: () => {
          this.managePdfVersions(ediaryPhaseInstance, isEDiaryConf);
        },
      });
    }

    if (showPdfButton) {
      const pdfText = this.trialpalService.translateService.instant(
        'general.generatePDFEntity'
      );
      menu.push({
        label: pdfText,
        tooltip: pdfText,
        icon: 'pi pi-file-pdf',
        command: () => {
          this.createEDiaryCardPDFComplete(ediaryPhaseInstance, isEDiaryConf);
        },
      });
    }

    if (showSuspendEdiaryButton) {
      const suspendText = this.trialpalService.translateService.instant(
        'subject.SuspendEDiary'
      );
      menu.push({
        label: suspendText,
        tooltip: suspendText,
        icon: 'pi pi-calendar',
        command: () => {
          this.disableEnableDiary(ediaryPhaseInstance);
        },
      });
    }
    return menu;
  }

  //Se encarga de crear los botones (cuando solo son 2)
  buildTp2CardButtons(menu: MenuItem[], isEdiaryConf?: boolean): ButtonItem[] {
    const buttons: ButtonItem[] = [];

    menu.forEach((menuItem: MenuItem) => {
      buttons.push({
        command: menuItem.command,
        color: isEdiaryConf ? '#1C742B' : '#205163',
        icon: menuItem.icon ?? '',
        tooltip: menuItem.tooltip ?? '',
        disabled: menuItem.disabled ?? false,
      });
    });
    return buttons;
  }

  //Se encarga de construir la información de la tarjeta
  buildTp2CardInfo(res: any, eDiary?: boolean) {
    const entity = this.trialpalService.dictionaryPipe.transform(res.phase);
    const phase = eDiary
      ? this.trialpalService.translateService.instant(
          'subject.eDiaryPDFComplete'
        )
      : this.trialpalService.translateService.instant(
          'subject.eDiaryPDFVisit',
          {
            entity: entity || res.phase,
          }
        );

    let daysReport = '';
    let dateInfo = '';
    const daysReported = this.getDaysReported(res);
    const isPastEdiaryPhaseInstance = this.isDaysPastToCurrentDate(res);
    if (!eDiary) {
      daysReport =
        daysReported +
        '/' +
        this.getAmountToReport(res) +
        ' ' +
        this.trialpalService.translateService.instant(
          'report.DayReportedPlural'
        );
      dateInfo = this.getLastReportedDate(res);
    } else {
      daysReport =
        this.eDiaryPhases.length +
        ' ' +
        this.trialpalService.translateService.instant('subject.tabheaderVisit');
      dateInfo =
        String(this.isDaysReported) +
        ' ' +
        (this.isDaysReported === 1
          ? this.trialpalService.translateService.instant('report.DayReported')
          : this.trialpalService.translateService.instant(
              'report.DayReportedPlural'
            ));
    }

    return { phase, dateInfo, daysReport, isPastEdiaryPhaseInstance };
  }

  //Valida si la visita tiene una ventana activa de conciliación
  getWindowsByPhase(phase: string) {
    if (!phase) return false;
    return this.confEdiary.windows?.find((window: any) => {
      return window.phases.includes(phase);
    });
  }

  isConciliationActive(eDiary: any, isPastEdiaryPhaseInstance: boolean) {
    if (!this.hasSubjectDiaryAssesmentPermissions) return false;
    if (!this.confEdiary.isConciliationRequired) return false;
    if (!this.confEdiary.isWindowsConciliationRequired) {
      return eDiary?.phase && isPastEdiaryPhaseInstance;
    }
    const window = this.getWindowsByPhase(eDiary?.phase);
    if (!window) return isPastEdiaryPhaseInstance; //Si no tiene ventana ve el botón, pero le muestra mensaje de advertencia

    const { daysAfterVisit, totalWindowDays } = window;

    const completedPhaseVisitBySubject = this.getCompletedPhaseVisitBySubject(
      eDiary?.phase
    );

    const completedPhaseDate =
      eDiary?.completedPhaseDate ?? completedPhaseVisitBySubject;

    const diffBetweenCurrentDateAndCompleteVisit =
      this.trialpalService.diffBetweenDates(completedPhaseDate);

    //Obtiene la fecha de cuando acabará la ventana
    const windowEndDate = this.trialpalService.addDaysToDate(
      completedPhaseDate,
      daysAfterVisit + totalWindowDays
    );

    //Valida si la ventana sigue activa
    const isWindowDateActive =
      this.trialpalService.isAfterToCurrentDateWithTimeZone(windowEndDate);
    if (
      diffBetweenCurrentDateAndCompleteVisit >= daysAfterVisit &&
      isWindowDateActive
    ) {
      return isPastEdiaryPhaseInstance;
    }

    return false;
  }

  //Obtiene la fecha en la que se completo la visita
  getCompletedPhaseVisitBySubject(phase: string) {
    const completedVisits = this.subject?.scheduledPhases?.filter(
      (scheduledPhase: any) => scheduledPhase.phase === phase
    );

    //Si solo esta la visita una vez completada se agrega esa fecha
    if (completedVisits?.length === 1) {
      return completedVisits[0].date;
    }

    return null;
  }

  async isUserAuthorized() {
    return (
      this.authService.isAdmin() ||
      (await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectDiaryGeneratePDF,
      ]))
    );
  }

  showViaService() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: 'PDF',
      detail: this.trialpalService.translateService.instant('subject.notPDF'),
      life: 5000,
    });
  }
  managePdfVersions(event: any, isEDiaryConf: boolean = false): void {
    const ref = this.dialogService.open(ManagePdfVersionsComponent, {
      header: this.trialpalService.translateService.instant(
        'general.listVersions'
      ),
      width: '50%',
      data: {
        id: 'id',
        instance: event,
        timeZoneOffset: this.timeZoneOffset,
        isEDiaryConf,
        subjectId: this.subjectId,
      },
      dismissableMask: false,
    });
    ref.onClose.subscribe((data) => {
      if (data) {
        this.createEDiaryCardPDFComplete(
          data?.instance,
          data?.isConfEdiary,
          true
        );
      }
    });
  }

  async createEDiaryCardPDFComplete(
    eDiaryPhaseInstance: any,
    isConfEdiary?: any,
    isFromManageVersions: boolean = false
  ): Promise<any> {
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'general.generatePDF'
      ),
      accept: async () => {
        try {
          this.trialpalService.showSpinner('PDF', 'CREATE');
          if (!isConfEdiary) {
            await this.subjectsService.managePDFandAlert(eDiaryPhaseInstance);
          } else {
            await this.subjectsService.createEDiaryCardPDFConsolidate(
              this.subjectId
            );
          }
          await this.ngOnInit();
          this.showPDFModal(
            eDiaryPhaseInstance,
            'GeneratedFromEDiaryCard' +
              (isConfEdiary ? 'Consolidate' : '') +
              (isFromManageVersions ? 'ManageVersions' : '')
          );

          this.trialpalService.hideSpinner();
        } catch (error) {
          logger.error('Generated pdf error -->', error);
          this.trialpalService.showServiceError('PDF', error);
          this.trialpalService.hideSpinner();
        }
      },
    });
  }

  showPDFModal(eDiaryInstance: any, analyticsSource: string) {
    let findEDiary = this.ediaryPhaseCards.find(
      (eDiary: Tp2Card) => eDiary.id === eDiaryInstance.id
    );
    this.openModal(findEDiary?.data, false, analyticsSource);
  }

  disableEnableDiary(eDiary: any): any {
    if (!this.hasSubjectSuspendVisitPermission) return;

    const header = this.trialpalService.translateService.instant(
      'subject.SuspendEDiary'
    );
    const ref = this.dialogService.open(DisabledEnabledDiarySubjectComponent, {
      header: header,
      width: '70%',
      closeOnEscape: false,
      dismissableMask: false,
      data: {
        eDiary: eDiary,
        timeZoneOffset: this.timeZoneOffset,
        subjectId: this.subjectId,
      },
    });

    this.trialpalService.ref = ref;
    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.ngOnInit();
      }
    });
  }

  async getConfEdiaryByProjectId(projectId: string) {
    await this.ediaryService
      .getConfEdiaryByProjectId(projectId)
      .then((data: any) => {
        this.confEdiary = data.items[0];
        logger.debug('getConfEdiaryByProject', data);
      })
      .catch((err) => {
        logger.error('getConfEdiaryByProject', err);
      });
  }

  goToConciliate(ediaryPhaseInstance: any): void {
    logger.debug('tpDAta goToConciliate', ediaryPhaseInstance);
    this.route.navigate(
      [
        'subjects',
        this.subjectId,
        'detail',
        ediaryPhaseInstance?.id,
        'conciliation',
      ],
      {
        state: {
          dataSubjectEdiary: ediaryPhaseInstance,
        },
      }
    );
  }
  hasPDFConciliated(cardData: any) {
    if (!this.shouldShowPDFAssessment) return false;
    const containsPDFConciliated = (cardData?.pdfFiles ?? []).some(
      (file: any) => {
        return file?.fileUrl?.includes('eDiaryConciliacion');
      }
    );
    return containsPDFConciliated;
  }

  openPdfModal(pdfUrl: any, analyticsSource: string): void {
    const ref = this.dialogService.open(ViewPdfComponent, {
      data: {
        PDFUrl: pdfUrl,
        disablePrint: true,
        showPrintButton: true,
        sourceComponent: analyticsSource,
        subjectId: this.subjectId,
      },
      styleClass: 'modal-pdf',
      closeOnEscape: true,
      dismissableMask: true,
      showHeader: false,
      width: '80%',
      height: '80%',
    });

    ref.onClose.subscribe();
  }

  async startTour() {
    if (!this.isOpened || this.showSpinner) return;
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
    this.pageTourService.initiateTour(
      PageTourDataType.DETAIL_SUBJECT_EDIARY_TOUR
    );
  }
}
