<form>
  <label style="color: var(--primary-color)" class="p-mb-4 p-d-block">
    {{ 'project.accessibilityGroupEnabledGroupLabel' | translate }}
  </label>
  <p-table
    [value]="accessibilityGroups"
    styleClass="p-datatable-gridlines"
    class="p-mb-4 p-d-block"
    [breakpoint]="'460px'"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th id="group">{{ 'project.labelGroups' | translate }}</th>
        <th
          id="activate"
          style="color: var(--primary-color)"
          class="p-d-flex p-justify-end p-text-right"
        ></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group let-index>
      <tr>
        <td class="word-break">{{ group.group | dictionary }}</td>
        <td class="p-d-flex p-justify-end">
          <p-selectButton
            id="{{ group.group + index }}"
            name="{{ group.group + index }}"
            [options]="booleanOptions"
            [(ngModel)]="group.isActive"
            optionLabel="label"
            optionValue="value"
            required
          ></p-selectButton>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="p-d-flex p-justify-between" style="gap: 16px">
    <button
      pButton
      type="button"
      class="p-button-outlined p-button-secondary"
      style="width: 100%"
      label="{{ 'project.buttonCancel' | translate }}"
      (click)="close()"
    ></button>
    <button
      pButton
      type="button"
      class="btn btn-info"
      style="width: 100%"
      label="{{ 'general.btnSaveInformation' | translate }}"
      (click)="saveInformation()"
    ></button>
  </div>
</form>
