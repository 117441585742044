<div class="container-tp2">
  <app-back-button-header
    [pageTitle]="'informedConsent.sections.sectionDetail' | translate"
    [pageUrl]="
      '/informed-consent/' + projectId + '/detail/' + confInformedConsentId
    "
    [showButton]="false"
  ></app-back-button-header>
  <div class="tp2Card sectionDetail p-mb-4">
    <div>
      <p class="sectionTitle">
        {{ 'informedConsent.sections.sectionTitle' | translate }}:
      </p>
      <p class="sectionInfo">{{ confICSection.title | dictionary }}</p>
    </div>
    <div>
      <p class="sectionTitle">
        {{ 'informedConsent.sections.sectionType' | translate }}:
      </p>
      <p class="sectionInfo">
        {{
          'informedConsent.sections.sectionTypes.' + confICSection.type
            | translate
        }}
      </p>
    </div>
    <div *ngIf="showQuestionList">
      <p class="sectionTitle">
        {{ 'informedConsent.sections.sectionContent' | translate }}:
      </p>
      <p class="sectionInfo btn-content" (click)="dialogContentModal = true">
        <span class="pi pi-eye"></span>
        <span>{{
          'informedConsent.sections.showSectionContent' | translate
        }}</span>
      </p>
    </div>
    <div>
      <p class="sectionTitle">
        {{ 'informedConsent.sections.sectionOrder' | translate }}:
      </p>
      <p class="sectionInfo">
        {{ confICSection.order }}
      </p>
    </div>
  </div>
  <div class="tp2Card p-p-3 break-word" *ngIf="!showQuestionList">
    <p class="sectionTitle p-mb-4" style="font-size: 1.25rem">
      {{ 'informedConsent.sections.sectionContent' | translate }}:
    </p>
    <div [innerHTML]="confICSection.content | dictionary"></div>
  </div>
  <div class="tp2Card p-p-3" *ngIf="showQuestionList">
    <app-informed-consent-question-list></app-informed-consent-question-list>
  </div>
</div>

<p-dialog
  [header]="'informedConsent.sections.sectionContent' | translate"
  [(visible)]="dialogContentModal"
  [style]="{ width: '60vw' }"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  [baseZIndex]="9999"
  styleClass="modal-rounded"
  *ngIf="dialogContentModal"
>
  <div
    class="break-word"
    [innerHTML]="confICSection.content | dictionary"
  ></div>
</p-dialog>
