<main *ngIf="isTerms">
  <h1 class="bold">Términos, condiciones</h1>
  <article>
    <h2 class="title bold">1. Introducción</h2>
    <p class="text">
      Estos TÉRMINOS Y CONDICIONES DE USO son un acuerdo legal entre el usuario
      e Integra IT S.A.S.. Todas nuestras soluciones tecnológicas se rigen por
      estos TÉRMINOS Y CONDICIONES DE USO. Asegúrese de leer detenidamente y
      comprender todos los derechos y restricciones descritas.
    </p>
    <h2 class="title bold">2. Lineamientos</h2>
    <ul>
      <li class="text">
        Los siguientes TÉRMINOS Y CONDICIONES DE USO aplican para las soluciones
        web y aplicaciones móviles de propiedad y desarrollo de INTEGRA IT, en
        adelante los “Términos y Condiciones de Uso”, serán aplicables a
        aquellas personas que deseen acceder a las plataformas web y apps. Si
        usted no está de acuerdo con estos Términos y Condiciones de Uso, deberá
        abstenerse de utilizar nuestras soluciones tecnológicas.
      </li>
      <li class="text">
        Integra IT es el proveedor desarrollador de soluciones tecnológicas, que
        pueden ser aplicaciones configuradas para la recolección de datos en un
        diario electrónico (reporte de síntomas) y reportes para seguimiento de
        una investigación clínica. Integra IT es el administrador de todos los
        datos recolectados por nuestras soluciones tecnológicas, por tanto somos
        los responsables del tratamiento de los datos personales. De igual forma
        Integra IT es el Responsable de los mismos datos acorde a las leyes de
        Protección de Datos de cada país donde se utilicen las soluciones
        (Colombia, Panamá; USA y cualquier otro).
      </li>

      <li class="text">
        Estos Términos y Condiciones de Uso constituyen acuerdo vinculante entre
        el usuario e Integra IT y regulan el uso de las soluciones tecnológicas.
      </li>
      <li class="text">
        Estos Términos pueden ser revisados y modificados en cualquier momento y
        sin necesidad de autorización, más allá de ser notificadas las
        actualizaciones realizadas. El usuario manifiesta que reconoce y está de
        acuerdo que el uso de nuestras soluciones tecnológicas puede estar
        sujeta a la aceptación de una nueva versión de estos Términos y
        Condiciones de Uso.
      </li>
      <li class="text">
        Para el uso de la plataforma el usuario debe estar autorizado
        previamente por Integra IT y/o la empresa contratante de los servicios
        de Integra IT.
      </li>
      <li class="text">
        Integra IT no proporciona servicios o asesoría médica alguna.
      </li>
      <li class="text">
        El usuario se obliga a utilizar las soluciones tecnológicas de
        conformidad con la legislación vigente y estos Términos y Condiciones de
        Uso, debiendo darle un uso consistente con las exigencias que imponen el
        orden público, la moral, las buenas costumbres generalmente aceptadas y
        principalmente el respeto de terceros.
      </li>
      <li class="text">
        A tal efecto, el usuario se compromete a no utilizar las soluciones
        tecnológicas de forma perjudicial a los derechos e intereses de
        terceros, o que de cualquier forma puedan dañar, inutilizar,
        sobrecargar, deteriorar o impedir la normal utilización de las
        soluciones tecnológicas, los equipos informáticos o los documentos,
        archivos y toda clase de contenidos almacenados en cualquier equipo
        informático de Integra IT, de otros usuarios o de cualquier usuario de
        Internet.
      </li>

      <li class="text">
        Entre otras obligaciones, de manera ejemplar y sin que implique
        limitación alguna, al usuario se le prohíbe:
        <ol>
          <li class="text">
            Reproducir, copiar, distribuir o permitir el acceso a las soluciones
            tecnológicas a través de cualquier forma de comunicación pública,
            transformar o modificar los servicios, salvo que se cuente con la
            autorización del titular de los correspondientes derechos de
            propiedad intelectual o industrial, o ello resulte legalmente
            permitido;
          </li>
          <li class="text">
            Suprimir, eludir o manipular el derecho de propiedad intelectual e
            industrial y demás datos identificadores de los derechos de Integra
            IT o de otro titulares, incorporados a los servicios, así como los
            dispositivos técnicos de protección, las huellas digitales, medidas
            de autenticación biométrica o cualquier mecanismo de información que
            pudieren contener y/o solicitar los servicios;
          </li>
          <li class="text">
            Ejecutar, inducir o promover actos delictivos, denigrantes,
            difamatorios, o que en general sean contrarios a la ley, la moral,
            al orden público, o la seguridad nacional o que fueren
            discriminatorios por razón de sexo, raza, religión, creencias, edad
            o condición;
          </li>
          <li class="text">No respetar la privacidad de los usuarios;</li>
          <li class="text">
            Usar los servicios con propósitos comerciales, incluyendo la
            promoción de cualquier bien o servicio;
          </li>

          <li class="text">
            Obstaculizar, entorpecer, restringir o limitar de cualquier modo el
            uso de los servicios por parte de otros usuarios;
          </li>

          <li class="text">
            Proporcionar información que no sea exacta, verdadera, completa y
            actualizada o que de cualquier manera menoscabe a Integra IT o a
            terceros.
          </li>

          <li class="text">
            Utilizar alguno de los elementos de las soluciones tecnológicas para
            otros propósitos, incluyendo usos públicos o comerciales, sin haber
            recibido previo consentimiento por escrito por parte de Integra IT.
            Las soluciones tecnológicas pueden contener enlaces o links a sitios
            mantenidos por terceros y eventualmente pueden proporcionar material
            de terceros desde los sitios de éstos. Integra IT no es responsable
            por la funcionalidad, eficacia de los servicios y por los contenidos
            de aquellos sitios.
          </li>
        </ol>
      </li>
      <li class="text">
        Usted acepta que no usará las soluciones tecnológicas para compartir
        contenido que: (a) sea falso o engañoso; (b) sea difamatorio,
        despectivo, degradante o acosador de otro o constituya un ataque
        personal; (c) invada la privacidad de otra persona o incluya, copie o
        transmita información confidencial, sensible o personal de otra persona;
        (d) promueva la intolerancia, el racismo, el odio o el daño contra
        cualquier grupo o individuo; (e) sea obsceno o de mal gusto; (f) viole o
        infrinja o promueva la violación o infracción de los derechos de otra
        persona, incluidos los derechos de propiedad intelectual; (g) no tiene
        el derecho y la autoridad para Compartir y otorgar los derechos y
        licencias necesarios para; (h) viole o promueva la violación de
        cualquier ley o reglamento aplicable.
      </li>
      <li class="text">
        Integra IT puede denunciarlo a las autoridades pertinentes y puede
        actuar en la máxima medida de las leyes aplicables si transmite o carga
        contenido con la intención de causar daño.
      </li>
      <li class="text">
        Los enlaces o links mantenidos por terceros no son propiedad, ni están
        controlados por Integra IT. Integra IT no tiene forma de controlar el
        contenido de los sitios web de terceros y aplicaciones de terceros que
        siguen siendo totalmente independientes de Integra IT. Integra IT no es
        responsable por ningún contenido de un sitio web de terceros o una
        aplicación de terceros que se usa desde un hipervínculo que figura en
        las soluciones tecnológicas. Además, la existencia de un hipervínculo
        desde las soluciones tecnológicas a un sitio web de terceros o
        aplicación de terceros no significa que Integra IT aprueba o respalda en
        modo alguno tal sitio web de terceros ni a los de aplicaciones de
        terceros, y en particular la del uso que se pudiera hacer del mismo.
      </li>
      <li class="text">
        En ningún caso, Integra IT será responsable en forma alguna por la
        disponibilidad de dichos sitios web de terceros y aplicaciones de
        terceros, ni Integra IT supervisa, comprueba o respalda el contenido,
        publicidad, productos u otros materiales disponibles a través de dichos
        sitios web de terceros y aplicaciones de terceros.
      </li>
      <li class="text">
        Integra IT no es responsable por los reclamos, pérdidas, acciones,
        daños, demandas judiciales o procedimientos que surjan o estén
        relacionados de alguna manera con ocasión de los enlaces con sitios de
        terceros. La presente exención de responsabilidad se extiende a los
        enlaces con sitios mantenidos por terceros, donde se realiza cualquier
        tipo de transacción entre y/o mediante el usuario y el tercero
        responsable del sitio.
      </li>
      <li class="text">
        Las soluciones tecnológicas no deben ser consideradas como historia
        clínica y no pretenden ser sustituto de su regular registro de
        información en relación con sus pacientes.
      </li>
      <li class="text">
        Puede revisar los Términos y Condiciones y la política de privacidad en
        cada una de las soluciones.
      </li>
      <li class="text">
        En térmios de Habeas Data se debe diferenciar el titular de la
        información (en este caso el usuario) y el responsable de la información
        (puede ser el administrador por parte del cliente).
      </li>
    </ul>

    <h2 class="title bold">
      3. Requisitos para el uso de las soluciones tecnológicas en usuarios
      autorizados
    </h2>
    <p class="text">
      Nuestras soluciones tecnológicas están disponibles para los
      empleados/contratistas/sujetos de cualquier compañía que utilice los
      servicios de Integra IT para el desarrollo de funcionalidades que permiten
      consultar, monitorear y hacer seguimiento de la información relevante
      reportada por los sujetos pertenecientes a un proyecto de investigación
      para el que fue contratada Integra IT. El acceso y utilización de las
      soluciones tecnológicas requiere que el usuario disponga de acceso a
      Internet, ya sea por un plan, bolsa de datos, mediante conexión Wi-Fi o
      cualquier otro medio disponible. En el caso de apps, se requieren
      dispositivos compatibles y determinado software (podrían aplicarse
      tarifas), además se pueden requerir actualizaciones periódicas. Los
      servicios de la app pueden verse afectados por el rendimiento de los
      factores previamente señalados.
    </p>
    <h2 class="title bold">
      4. Manejo de datos personales y uso de claves para el registro
    </h2>

    <ul>
      <li class="text">
        En este documento, los usuarios, son los titulares de la información y
        los responsables de la información, son los administradores por parte
        del cliente.
      </li>
      <li class="text">
        Los usuarios que acceden a las plataformas web de las soluciones
        tecnológicas pueden ser los sujetos del estudios si son mayores de edad,
        o ser diferentes a los sujetos participantes del proyecto, es decir, a
        estas plataformas tienen acceso el personal administrativo del proyecto
        que cuenta con un usuario y contraseña y estos se registran previamente
        ingresando los datos personales que le son solicitados. El proceso de
        registro de acuerdo a la solución, puede constar de dos pasos: El
        registro del usuario, para lo cual se selecciona el rol con el que se
        podrá navegar dentro de la plataforma: Nombre, usuario, correo
        electrónico y número telefónico y un segundo paso en el cual, la
        plataforma notifica la creación correcta del usuario, asignando una
        contraseña temporal, la cual al ingresar por primera vez al sistema,
        solicita una actualización de dicha contraseña por una personal.
      </li>
      <li class="text">
        La contraseña personal de acceso que defina al usuario está destinada a
        su uso personal, por lo tanto, su uso será de su exclusiva
        responsabilidad. En consecuencia, el extravío, robo, hurto, mal uso o
        cualquiera otra circunstancia que afecte la clave personal de acceso del
        usuario será de su responsabilidad. Asimismo, cualquier instrucción o
        declaración que se ejecute a través de la clave personal de acceso se
        entenderá que ha emanado válida, legítima y auténticamente de un acto
        personal ejecutado por el usuario. En caso que exista mal uso de la
        clave personal, el usuario deberá dar aviso e informar a Integra IT
        apenas tenga conocimiento de la situación. Esta comunicación se deberá
        remitir al correo electrónico
        <a href="mailto:support@integrait.co">support@integrait.co</a>.
      </li>
      <li class="text">
        El usuario podrá en cualquier momento cambiar su clave personal de
        acceso, para lo cual deberá sujetarse a los procedimientos establecidos
        por las soluciones tecnológicas.
      </li>
      <li class="text">
        Está totalmente prohibido habilitar a otros individuos para usar las
        soluciones tecnológicas por medio de su clave personal de acceso.
      </li>
    </ul>

    <h2 class="title bold">5. Veracidad de la Información</h2>
    <ul>
      <li class="text">
        Toda la información que facilite el usuario a Integra IT por cualquier
        medio deberá ser exacta, verdadera, completa y actualizada. Adicional a
        lo anterior, el usuario deberá actualizar la información a INTEGRA IT en
        caso de que esta actualización sea necesaria.
      </li>
      <li class="text">
        A estos efectos, el usuario garantiza la autenticidad de todos aquellos
        datos que comunique a Integra IT como consecuencia del diligenciamiento
        de los formularios necesarios para ser registrados en las diferentes
        soluciones tecnológicas. De igual forma, será responsabilidad del
        usuario mantener toda la información entregada a Integra IT actualizada
        de forma que responda, en cada momento, a su situación real.
      </li>
      <li class="text">
        Integra IT y/o podrá suspender temporalmente los servicios, cuando tenga
        dudas sobre la veracidad, exactitud o actualización de la información
        proporcionada por un usuario, mientras la información suministrada no
        haya sido confirmada por el mismo. El usuario será el único responsable
        de las manifestaciones falsas o inexactas que realice, y de los
        perjuicios que pudiere causar a Integra IT o a terceros por la
        información inexacta que proporcione.
      </li>
    </ul>

    <h2 class="title bold">6. Uso de los Datos Personales</h2>
    <ul>
      <li class="text">
        Integra IT se preocupa por la protección de datos de carácter personal
        de sus usuarios y clientes, por lo cual, asegura que su tratamiento se
        efectuará con estricta sujeción a la legislación vigente (Colombia,
        Panamá, USA y cualquier otro). Integra IT como responsable de los datos
        personales obtenidos y/o proporcionados por el uso de las plataformas
        tecnológicas o de cualquier otra naturaleza para con dicha entidad, ha
        puesto a ha disposición la Política para Tratamiento Datos Personales de
        la compañía, la cual podrá ser consultada en cada una de las soluciones,
        y la cual incluye los derechos que a los Titulares les asisten y la
        manera de ejercerlos. En virtud de lo anterior y mediante la aceptación
        de estos Términos y Condiciones de Uso, declaro que:
        <ol>
          <li class="text">
            Integra IT, actuará como Responsable del Tratamiento de los datos
            personales de los cuales soy titular (los “Datos Personales”). El
            Tratamiento implica la recolección, almacenamiento, administración,
            utilización, transferencia, transmisión y destrucción de dichos
            datos.
          </li>
          <li class="text">
            Entiendo que el Tratamiento de los datos personales de usuarios de
            las soluciones tecnológicas que sean personas naturales, se requiere
            mientras persista el uso de la App (probable o actual) e incluso
            después de finalizado su uso, con el fin de cumplir las siguientes
            finalidades, las cuales ya me habían sido informadas: (i) entregar
            información y/o beneficios a los usuarios (ii) el cumplimiento y
            desarrollo de las obligaciones contractuales o acuerdos comerciales
            que tiene o pueda llegar a contratar con Integra IT o con cualquiera
            de sus filiales o subsidiarias; (iii) enviar notificaciones, avisos
            e información científica sobre proyectos propios o de nuestros
            aliados; (iv) envío de información, revistas y noticias de nuestra
            industria o de temas que nosotros consideremos que pueden
            interesarle teniendo en cuenta el alcance de la relación (probable o
            actual) o de cualquier otra naturaleza existente con Usted, así como
            noticias, o comunicaciones de Integra IT o sus empresas
            relacionadas.
          </li>
          <li class="text">
            Autorizo igualmente que los datos personales a los que se refiere la
            presente autorización sean Transferidos y/o Transmitidos a las demás
            empresas relacionadas con Integra IT; dicha Transferencia y/o
            Transmisión de datos personales podrá realizarse incluso a empresas
            que se encuentren en otros países diferentes a donde se está
            utilizando las soluciones tecnológicas, siempre cumpliendo la
            legislación vigente del país donde se está implementando la solución
            (Colombia, Panamá, USA y cualquier otro).
          </li>
          <li class="text">
            Así mismo, autorizo a que los datos personales a los que se refiere
            la presente autorización sean transmitidos a terceros contratistas
            que presten servicios a Integra IT, a quienes se les ha encargado el
            tratamiento de los datos personales para los fines descritos en el
            presente documento. Para lo anterior, el presente documento obra
            como autorización expresa. En el evento de Transferencia y/o
            Transmisión, los datos personales a los que se refiere el presente
            documento serán tratados bajo estrictas medidas de confidencialidad
            y seguridad.
          </li>
          <li class="text">
            Se me ha informado acerca del derecho en todo momento y de manera
            gratuita a revocar la autorización, conocer, actualizar, corregir o
            solicitar que se certifique o suprima mi información personal de las
            bases de datos en que se encuentren registrados y a ejercer
            cualquiera de los derechos de los Titulares de conformidad con la
            normatividad vigente en el país donde se está implementando la
            solución y remitiendo una comunicación a través del correo
            electrónico
            <a href="mailto:support@integrait.co.">support@integrait.co</a>.
          </li>
          <li class="text">
            El usuario que no desee continuar recibiendo correos electrónicos
            publicitarios o de marketing, podrá solicitarlo a través del mismo
            correo escrito anteriormente.
          </li>
          <li class="text">
            De acuerdo a la solución tecnológica utilizada, usaremos sus datos
            de localización para identificar su ubicación, previa autorización
            con la aceptación de la presente Política. Vamos a utilizar su
            conexión de Internet para autenticar las credenciales de usuario y
            poder almacenar dichas credenciales en los sistemas de Integra IT
            para la configuración del servicio y con fines de prestación de
            servicios únicamente.
          </li>
        </ol>
      </li>
    </ul>
    <h2 class="title bold">7. Propiedad intelectual e industrial</h2>
    <ul>
      <li class="text">
        Integra IT es titular de los derechos de propiedad industrial y de
        propiedad intelectual relativos a sus productos y servicios, así como
        los relativos a marcas registradas, el desarrollo de productos,
        conocimiento adquirido por el uso y/o nombres del dominio
        "integrait.co".
      </li>
      <li class="text">
        Quedan reservados todos los derechos, títulos e intereses no otorgados
        expresamente con respecto al sitio y el contenido provisto a través del
        mismo. Todo contenido u obra que el usuario vea, lea o a la cual acceda
        en nuestras soluciones tecnológicas (imágenes, fotografías,
        ilustraciones, iconos, textos, código fuente, diseño o cualquier otro
        elemento de contenido), se encuentra protegido bajo la legislación de
        cada país y los tratados internacionales sobre propiedad intelectual e
        industrial, salvo que expresamente se señale lo contrario. El usuario no
        podrá vender, reproducir, distribuir, modificar, publicar o cualquier
        otra actividad semejante, para beneficio propio o de un tercero, con
        cualquier elemento de contenido de las soluciones tecnológicas, ni
        tampoco usarlo en ningún otro portal en Internet, para propósito alguno.
      </li>
      <li class="text">
        Integra IT no concede ninguna licencia, derecho o autorización de uso de
        ninguna clase sobre sus derechos de propiedad industrial e intelectual,
        o sobre cualquier otra propiedad o derecho relacionado con las
        soluciones móviles o con los servicios que ofrece, salvo la licencia de
        que trata la sección siguiente. Integra IT podrá terminar inmediatamente
        el derecho del usuario para acceder a las soluciones tecnológicas, en
        caso de infracción del usuario a estas normas. El usuario será
        responsable de todo daño o perjuicio causado a Integra IT y/o a
        terceros, que deriven de las infracciones legales en que incurra.
      </li>
      <li class="text">
        Se deja expresa constancia que todo elemento de contenido, tales como
        artículos, publicaciones, noticias e información publicada, exhibida,
        transmitida o comunicada en las soluciones tecnológicas, será de
        responsabilidad de su autor o de la persona que las haya proveído en
        conformidad a las leyes sobre propiedad intelectual e industrial.
      </li>
      <li class="text">
        Nuestras soluciones tecnológicas pueden contener vínculos, links y/o
        referencias a sitios de terceros fabricantes, productores y/o
        proveedores de bienes y/o servicios. Integra IT reconoce a favor de sus
        titulares los correspondientes derechos de propiedad industrial e
        intelectual, y su sola mención o aparición en las soluciones
        tecnológicas no implica la existencia de derechos o responsabilidad
        alguna de Integra IT sobre los mismos. Integra IT no recomienda ni avala
        ningún producto o servicio ofrecido en dichos sitios, asimismo, Integra
        IT no es responsable de las prácticas de seguridad o privacidad, o el
        contenido y disponibilidad de los mismos.
      </li>
    </ul>
    <h2 class="title bold">8. Marca Registrada</h2>
    <p class="text">
      El logotipo de Integra IT, y los productos y servicios son marcas
      comerciales registradas de Integra IT, en los EE. UU, Colombia, Panamá y/o
      en otros países y no se pueden copiar, imitar o utilizar, en su totalidad
      o en parte. Todas las demás marcas comerciales, marcas comerciales
      registradas, nombres de productos o logotipos que aparecen en el sitio web
      y apps son propiedad de Integra IT en los EE. UU. y/o en otros países.
    </p>
    <h2 class="title bold">9. Licencias</h2>
    <p class="text">
      Las soluciones tecnológicas se licencian o se arriendan pero no se venden.
      La licencia para usar las soluciones tecnológicas desarrolladas por
      Integra IT, está sujeta a la aceptación expresa de estos Términos y
      Condiciones de Uso por usted.
    </p>
    <p class="text">
      Usted no puede distribuir ni poner las soluciones tecnológicas disponibles
      en una red donde pueda ser utilizada por múltiples dispositivos al mismo
      tiempo. Las soluciones tecnológicas no pueden ser alquiladas, arrendadas,
      cedidas, prestadas, vendidas, transferidas, redistribuidas o
      sublicenciadas
    </p>
    <p class="text">
      Usted no puede copiar (salvo lo expresamente permitido por esta licencia),
      descompilar, explotar, realizar ingeniería inversa, desmontar, intentar
      descifrar el código fuente, modificar o crear trabajos derivados de la
      aplicación licenciada, las actualizaciones, o cualquier parte de la misma
      (excepto y únicamente en la medida en que cualquier restricción anterior
      esté prohibida por la ley aplicable). Cualquier intento de llevar a cabo
      las conductas anteriores, se entenderá como una violación de los derechos
      de Integra IT y conllevará a las acciones legales correspondientes.
    </p>
    <p class="text">
      Los términos de esta licencia se aplican a cualquier actualización
      proporcionada por Integra IT, a menos que dicha actualización esté
      acompañada por una licencia independiente, en cuyo caso los términos de
      dicha licencia prevalecerán.
    </p>
    <p class="text">
      La licencia estará vigente hasta que se le dé término por usted o por
      Integra IT. Los derechos otorgados por esta licencia terminarán
      automáticamente sin previo aviso por parte de Integra IT, en caso que
      usted no dé cumplimiento a cualquier término(s) de la presente Licencia. A
      la terminación de la Licencia, usted deberá cesar el uso de la aplicación
      licenciada y destruir todas las copias, totales o parciales, de la misma.
    </p>
    <h2 class="title bold">10. Responsabilidad</h2>
    <p class="text">
      Integra IT no garantiza ni declara que el uso de los servicios de las
      soluciones tecnológicas serán sin interrupciones o libre de errores, usted
      acepta que cada cierto tiempo Integra IT podrá retirar los servicios de
      las soluciones móviles por períodos indefinidos de tiempo, o cancelar
      tales servicios en cualquier momento, sin necesidad de previo aviso.
    </p>
    <p class="text">
      Usted acepta expresamente que el uso, o la imposibilidad de uso, de los
      servicios de las soluciones tecnológicas es bajo su exclusivo riesgo. Los
      servicios de las soluciones tecnológicas son proporcionados "tal cual" sin
      soporte ni mantenimiento y disponibles para su uso, sin garantías de
      ningún tipo, ya sean expresas o implícitas, incluyendo todas las garantías
      implícitas de comercialización, idoneidad para un propósito particular,
      derechos y no infracción.
    </p>
    <p class="text">
      En ningún caso Integra IT, sus directores, empleados, afiliados, agentes,
      contratistas, gerentes o licenciantes, serán responsables por cualquier
      daño derivado del uso de las soluciones tecnológicas. Integra IT no
      declara ni garantiza que las soluciones tecnológicas serán libres de
      pérdidas, corrupción, ataques, virus, interferencias, intromisiones en la
      seguridad por hacking o de otro tipo.
    </p>
    <p class="text">
      En consecuencia y en la medida permitida por la ley, Integra IT se exime
      de cualquier responsabilidad: - Por cualquier imprecisión, inexactitud u
      omisión relativa a la información disponible en las soluciones
      tecnológicas; - De los daños ocasionados por intrusión fraudulenta de un
      tercero que lleve a una modificación de la información o material
      disponible en las soluciones tecnológicas; - Y en general, de cualquier
      daño, directo o indirecto, especial, consecuencial o incidental,
      independientemente de su causa, origen, naturaleza o consecuencias,
      incluso mientras Integra IT haya sido consciente de la posibilidad de
      tales daños causados como resultado de (i) el acceso o la imposibilidad de
      acceder a las soluciones tecnológicas, (ii) el uso de las soluciones
      tecnológicas, incluyendo cualquier daño o virus que puedan infectar su
      ordenador, su dispositivo móvil o de otros bienes, y / o (iii) la
      credibilidad dada a cualquier información proporcionada directa o
      indirectamente por las soluciones tecnológicas.
    </p>
    <p class="text">
      Además, es su responsabilidad tomar las precauciones necesarias para
      evitar la contaminación de las soluciones tecnológicas especialmente por
      uno o más "virus", “Trojan horses " o cualquier otro "parásito".
    </p>
    <p class="text">
      Usted entiende y reconoce que su único y exclusivo recurso con respecto a
      cualquier defecto o insatisfacción con el sitio es dejar de usar el sitio.
      Algunas jurisdicciones no permiten estas limitaciones de responsabilidad y
      exclusiones de garantías; por lo tanto, las limitaciones y exclusiones
      anteriores pueden no ser aplicables a usted. Usted puede tener otros
      derechos que pueden variar de estado a estado o dentro de las
      jurisdicciones de cada país.
    </p>
    <p class="text">
      La validez, interpretación y ejecución de este acuerdo está regida por las
      leyes de cada país. El cliente acepta la jurisdicción de cualquier
      tribunal en cada uno de los países donde se utilicen las soluciones
      tecnológicas de INTEGRA IT. Usted acepta que cualquier reclamación o causa
      de acción que surja o esté relacionada con su uso de las soluciones
      tecnológicas debe presentarse dentro de un (1) año después de que surgiera
      dicha reclamación o causa de acción.
    </p>
    <h2 class="title bold">
      11. Cumplimiento de la Ley y Buenas Prácticas Clínicas.
    </h2>
    <p class="text">
      El usuario acepta utilizar las soluciones tecnológicas y su contenido
      cumpliendo todas las leyes, normas y reglamentaciones aplicables. El
      usuario acepta no transmitir ningún material que fomente una conducta que
      pueda constituir un delito penal, dar lugar a responsabilidad civil o
      violar cualquier ley o regulación local, estatal, nacional o internacional
      aplicable. INTEGRA IT ha diseñado su software y servicios para cumplir
      con: La Conferencia Internacional sobre Armonización (ICH), Buenas
      Prácticas Clínicas (GCP) y regulaciones y pautas internacionales (como EMA
      y FDA) aplicables a los datos de investigación clínica electrónica. Cada
      usuario de INTEGRA IT es responsable de garantizar que su uso del software
      y los servicios cumplen con todas esas normas y prácticas.
    </p>
  </article>
</main>

<main *ngIf="!isTerms">
  <h1 class="bold">política de privacidad</h1>
  <article>
    <h2 class="title bold">1. DESCRIPCIÓN GENERAL</h2>
    <h3 class="bold">1.1 Información del tratamiento de datos</h3>
    <p class="text">
      INTEGRA IT desarrolla y comercializa a nivel nacional e internacional
      Sistemas de informática y software; y presta servicios profesionales y de
      consultoría, en desarrollo e implementación de sistemas de información,
      mantenimiento de Software, outsourcing de recursos especializados y de
      procesos operativos a sus clientes.
    </p>

    <h3 class="bold">
      1.2 Identificación del responsable del tratamiento de datos
    </h3>
    <p class="text">
      <span class="bold">COLOMBIA</span><br />
      Razón Social: INTEGRA IT S.A.S. <br />
      Nit: 900311906-7<br />
      Dirección: Cra. 23 # 124 – 87 Torre 1 Oficina 602 – Bogotá D.C. – CO<br />
      Correo electrónico:
      <a href="mailto:support@integrait.co">support@integrait.co</a><br />
      Teléfonos: +57 (1) 8050057<br />
    </p>
    <p class="text">
      <span class="bold">PANAMÁ</span><br />
      Razón Social: INTEGRA IT, S.A. <br />
      R.U.C. 2550007-1-826248 D.V 23<br />
      Dirección: Ciudad del Saber - Calle Jacinto Palacios - Edificio 230 Piso
      3.<br />
      Correo electrónico:
      <a href="mailto:support@integrait.co">support@integrait.co</a><br />
      Teléfonos: +57 (1) 8050057<br />
    </p>
    <p class="text">
      <span class="bold">USA</span><br />
      Razón Social: INTEGRA IT SOLUTIONS LLC <br />
      Dirección: 9241 NW 14TH CT, Plantation, Fl 33322, United States <br />
      EIN: 61-2006578<br />
      Correo electrónico:
      <a href="mailto:support@integrait.co">support@integrait.co</a><br />
    </p>
    <h3 class="bold">1.3 Cumplimiento de las leyes de privacidad</h3>
    <p class="text">
      <span class="bold">Panamá:</span> Integra IT, cumple con los definido en
      la Ley 81 de Protección de Datos Personales, que establece principios,
      derechos, obligaciones y procedimientos para regular la protección de
      datos personales en Panamá.
    </p>
    <p class="text">
      <span class="bold">Colombia:</span> INTEGRA IT S.A.S. cumple con la ley
      Ley 1581 de 2012, que aplica para los datos personales registrados en
      cualquier base de datos y que son susceptibles de tratamiento por
      entidades de naturaleza pública o privada.acuerdo a las normas definidas.
    </p>
    <p class="text">
      INTEGRA IT adoptó la definición que el derecho de habeas data es la
      facultad que tiene el titular de los datos personales de exigir a las
      administradoras de datos el acceso, inclusión, exclusión, corrección,
      adición, actualización y certificación de los datos; entendido este como
      un derecho autónomo que lo diferencia de otras garantías que está en
      estrecha relación con derechos como el derecho a la intimidad y a la
      información.
    </p>

    <ul>
      <li>
        Se entenderá como <span class="bold">titular</span> de la información la
        persona natural o jurídica a quien se refiere la información.
      </li>
      <li>
        <span class="bold">Por fuente de información</span> se tendrá a la
        persona, entidad u organización que conoce los datos personales del
        titular de la información.
      </li>
      <li>
        Cuando se refiera a <span class="bold">responsable</span> del
        tratamiento se entenderá, acorde a la Ley de Habeas Data, a la persona
        natural o jurídica, pública o privada, que por sí misma o en asocio de
        otros, realice el tratamiento de datos personales por cuenta del
        responsable del tratamiento.
      </li>
      <li>
        A su vez, el <span class="bold">encargado</span> será aquella persona
        natural o jurídica, pública o privada, que por sí misma o en asocio de
        otros, decida sobre la base de datos y/o el tratamiento de los datos.
      </li>
    </ul>
    <p class="text">
      Integra IT podrá recoger datos de carácter personal únicamente de forma
      voluntaria y los almacenará de modo que permitan el ejercicio de derecho
      de acceso de su titular cuando éste lo solicite. INTEGRA IT procederá a
      destruir aquellos datos que hayan dejado de ser necesarios o pertinentes a
      los fines para los cuales hubiesen sido recolectados o cuando el titular
      de la información solicite en cualquier momento su destrucción. De acuerdo
      a la legislación vigente, INTEGRA IT utilizará los datos recolectados,
      para responder a sus solicitudes, mejorar nuestro nivel de servicio y el
      contenido de nuestra página web; suministrarle, información útil, noticias
      y actualizaciones de productos; informarle sobre productos y servicios
      nuevos; obtener su opinión sobre nuestros productos y servicios. Si la
      finalidad de la recolección de los datos fuera otra distinta a las aquí
      enunciadas, la misma se notificará en forma expresa al momento de
      requerirse el ingreso de sus datos. Para la mayor comodidad de nuestros
      visitantes, el sitio web de INTEGRA IT podrá tener enlaces con otros
      sitios web de terceros, pero la presente política de privacidad no se
      aplicará a dichos sitios, por lo que deberá consultar las respectivas
      políticas de privacidad de los otros sitios. Si bien INTEGRA IT no puede
      ofrecer garantías absolutas contra toda pérdida, uso indebido o
      modificación de datos personales, sí realiza todos sus esfuerzos por
      evitar tales afectaciones, adoptando las medidas técnicas y legales que
      resultan necesarias para garantizar la seguridad y confidencialidad de los
      datos personales, de modo que se evite su adulteración, pérdida, consulta
      o tratamiento no autorizado y que eventualmente permitan detectar
      desviaciones, intencionales o no, de información, sin importar el cómo se
      esté pretendiendo afectar la seguridad. INTEGRA IT no comunicará los datos
      personales a ningún tercero salvo que el titular haya dado su
      consentimiento expreso al encargado o responsable del tratamiento, o que
      se trate de una comunicación permitida conforme a la legislación local.
    </p>
    <p class="text">
      Se entenderá que el consentimiento entregado por separado prevalecerá
      sobre la presente política de privacidad siempre y cuando esté acorde a la
      legislación vigente en la materia. Por último, en su calidad de
      Responsable Inscripto en Bases de Datos Personales, INTEGRA IT informa que
      todos sus trabajadores y/o colaboradores tienen un Acuerdo de
      Confidencialidad con la empresa, en el cual se obligan a proteger los
      datos o la información a la que tienen acceso con ocasión a la ejecución
      de un contrato laboral o de prestación de servicios profesionales.
    </p>

    <p>
      <span class="bold">Estados Unidos:</span> INTEGRA IT S.A.S. cumple con el
      Reglamento General de Protección de Datos (GDPR) que se promulgó en abril
      de 2016 y entró en vigencia el 25 de mayo de 2018, por esto garantizamos a
      los titulares de datos el derecho a solicitar la eliminación o corrección
      de sus datos personales, cumpliendo con todo tipo de solicitudes.
    </p>
    <p class="text">
      <span class="bold">México:</span> INTEGRA IT S.A.S. cumple con la Ley
      Federal de Protección de Datos Personales en Posesión de los Particulares
      o Ley de Protección de Datos, que regula el tratamiento de los datos
      personales por parte de empresas del sector privado, Con su aplicación
      evitamos que los datos personales sean utilizados indebidamente,
      garantizamos que se respeten los derechos de los dueños de los datos y
      aseguramos una expectativa razonable de privacidad. Nosotros como compañía
      tomamos en cuenta las guías y documentos emitidos por el Instituto
      Nacional de Transparencia, Acceso a la Información y Protección de Datos
      Personales ("INAI").
    </p>
    <p class="text">
      <span class="bold">República Dominicana:</span> INTEGRA IT S.A.S. cumple
      con la Ley No. 172-13 que tiene por objeto la protección integral de los
      datos personales asentados en archivos u otros medios técnicos de
      tratamiento de datos destinados a dar informes, sean estos públicos o
      privados.
    </p>
    <p class="text">
      <span class="bold">Chile:</span> Integra IT cumple la Ley 19628 de Agosto
      de 1999 sobre Protección de la Vida Privada, de esta forma entendemos que
      el tratamiento de los datos de carácter personal está sujeto a las
      disposiciones de esta ley. Y cumplimos la ley para asegurar que podemos
      efectuar el tratamiento de datos personales, de manera concordante con
      esta ley y para las finalidades permitidas por el ordenamiento jurídico.
      Siempre respetamos el pleno ejercicio de los derechos fundamentales de los
      titulares de los datos y de las facultades que esta ley les reconoce.
    </p>
    <h3 class="bold">1.4 Alcance de la Información</h3>
    <p class="text">
      Nuestra intención con las informaciones de protección de datos es
      presentar a los titulares las directrices de protección de datos online e
      informar sobre las posibilidades que estan a disposición del titular para
      recopilar informaciones acerca de cada uno en nuestro sitio web.
    </p>
    <h2 class="title bold">2. LINEAMIENTOS</h2>
    <ul>
      <li class="text">
        El tratamiento de los datos personales sólo se efectúa cuando la ley de
        cada país u otras disposiciones legales lo autorizan y el titular
        consienta expresamente en ello.
      </li>
      <li class="text">
        El tratamiento de los datos e informaciones personales siempre debe
        hacerse respetando los principios de calidad, licitud, lealtad,
        seguridad y finalidad.
      </li>
      <li class="text">
        El titular de los datos debe ser debidamente informado respecto del
        propósito del almacenamiento de sus datos personales y si fuera el caso,
        su posible comunicación al público. Esta autorización debe constar por
        escrito.
      </li>
      <li class="text">
        La autorización puede ser revocada, aunque sin efecto retroactivo, lo
        que también deberá hacerse por escrito.
      </li>
      <li class="text">
        El tratamiento de datos en INTEGRA IT S.A.S. estará limitado a aquellos
        datos personales que son pertinentes y adecuados para la finalidad para
        la cual son recolectados o requeridos de acuerdo con la constitución y
        la ley, lo cual será informado al titular de los mismos.
      </li>
      <li class="text">
        INTEGRA IT S.A.S. está comprometida en suministrar un correcto uso y
        tratamiento de los datos personales de sus titulares, sean o no
        sensibles, evitando el acceso no autorizado a terceros que permita
        conocer, vulnerar, modificar, divulgar y/o destruir la información, para
        lo cual INTEGRA IT S.A.S cuenta con políticas de seguridad de la
        información que incluyen medidas de control de obligatorio cumplimiento.
      </li>
      <li class="text">
        En todos los casos, la información debe ser exacta, actualizada y
        responder con veracidad a la situación real del titular de los datos.
      </li>
      <li class="text">
        Los datos personales deben ser eliminados o cancelados cuando su
        almacenamiento carece de fundamento legal o cuando han caducado o cuando
        el titular así lo requiera. Esto debe hacerse gratuitamente para el
        titular de los datos.
      </li>
      <li class="text">
        Si los datos personales están en un banco de datos al cual tienen acceso
        nuestros clientes, el titular puede requerir información a cualquiera de
        ellos.
      </li>
      <li class="text">
        Los datos personales deben ser modificados cuando sean erróneos,
        inexactos, equívocos o incompletos.
      </li>
      <li class="text">
        Todos los colaboradores de INTEGRA IT que tienen acceso a datos
        personales están obligados a guardar confidencialidad, obligación que no
        cesa al terminar el contrato de trabajo y que se encuentra dentro de los
        acuerdos de la contratación.
      </li>
      <li class="text">
        INTEGRA IT S.A.S. velará por el respeto y cumplimiento de los derechos
        fundamentales de los niños, niñas y adolescentes, garantizando los
        requisitos especiales establecidos para el tratamiento de sus datos
        personales y datos sensibles.
      </li>
      <li class="text">
        Para el caso donde los datos del titular son suministrados por el
        cliente inmediato, éste último será “El Responsable del Tratamiento de
        Datos” e INTEGRA IT S.A.S será “El Encargado del Tratamiento de datos”,
        de acuerdo con esto, INTEGRA IT S.A.S responderá a las indicaciones del
        Responsable del Tratamiento de Datos sobre los datos del Titular.
      </li>
      <li class="text">
        En INTEGRA IT S.A.S. sólo se hace tratamiento de datos sensibles cuando:
        <br />a) El titular ha dado su autorización explícita a dicho
        tratamiento, salvo en los casos que por ley no sea requerido el
        otorgamiento de dicha autorización. <br />b) El tratamiento sea
        necesario para salvaguardar el interés vital del titular y este se
        encuentre física o jurídicamente incapacitado; en estos eventos los
        representantes legales deberán otorgar su autorización. <br />c) El
        tratamiento sea efectuado en el curso de las actividades legítimas y con
        las debidas garantías por parte de una fundación, ONG, asociación o
        cualquier otro organismo sin ánimo de lucro, cuya finalidad sea
        política, filosófica, religiosa o sindical, siempre que se refieran
        exclusivamente a sus miembros o a las personas que mantengan contactos
        regulares por razón de su finalidad; en estos eventos, los datos no se
        podrán suministrar a terceros sin la autorización del titular.
      </li>
      <li class="text">
        Cuando Integra IT es responsable de los registros o bases donde se
        almacenen datos personales con posterioridad a su recolección debe
        cuidar de ellos con la debida diligencia, haciéndose responsable de los
        daños.
      </li>
      <li class="text">
        INTEGRA IT no tiene control ni propiedad de los datos del cliente.
        Cualquier pregunta relacionada con los datos del cliente deben ser
        dirigidas al cliente para el que trabaja o que recopiló su información
        utilizando una plataforma o aplicación de INTEGRA IT.
      </li>
    </ul>
    <p class="text">
      <span class="bold">Chile: </span>
      Cuando el titular de los datos, solicite información, modificación,
      cancelación o bloqueo de datos personales a INTEGRA IT, se le debe
      responder su requerimiento dentro de dos días hábiles. INTEGRA IT entiende
      y sabe que todos los datos relacionados a recetas médicas y análisis o
      exámenes de laboratorios clínicos y servicios relacionados con la salud
      son reservados. Sólo se revelará su contenido con el consentimiento
      expreso por escrito del titular.
    </p>
    <h2 class="title bold">3. RECOPILACIÓN Y USO DE LOS DATOS</h2>
    <p>
      Los tipos de información que recopilamos sobre los titulares, es la
      información proporcionada por cada uno, como sus datos de contacto, así
      como la información que nuestros Sitios recopilan automáticamente, como su
      dirección IP y la información recopilada por nuestro uso de cookies. Los
      datos personales siempre los usamos para los fines determinados y
      explícitos que fueron autorizados por el titular en el momento de su
      recolección. <br /><span class="bold"
        >Propósitos del procesamiento de información:</span
      >
      En INTEGRA IT se procesa información sobre los titulares, para
      proporcionar nuestros servicios; para cumplir con la ley y prevenir el
      fraude; y por otras razones con el consentimiento de cada titular. También
      podemos anonimizar los datos, lo que significa que los datos, con el fin
      de realizar análisis para aprender cómo proporcionar mejor nuestros Sitios
      y Servicios.
    </p>
    <h2 class="title bold">4. DERECHOS DE LOS TITULARES DE DATOS PERSONALES</h2>
    <p class="text">
      En INTEGRA IT respetamos los derechos legales asociados con el
      procesamiento de datos personales:
    </p>
    <ul>
      <li class="text">
        Derecho de acceso: El titular puede obtener sus datos personales que
        tengamos almacenados y conocer el origen y finalidad de su recolección.
      </li>
      <li class="text">
        Derecho de rectificación: El titular puede solicitar la corrección de
        sus datos personales si están incorrectos, son irrelevantes, inexactos,
        falsos, incompletos o impertinentes.
      </li>
      <li class="text">
        Derecho de oposición: El titular puede revocar el consentimiento.
      </li>
      <li class="text">
        Todo titular tiene el derecho de acceso a sus datos personales,así como
        conocer el origen y finalidad para los cuales han sido recabados.
      </li>
    </ul>
    <p class="text">
      Los clientes de INTEGRA IT son los controladores de los datos cuando se
      procesan en la plataforma, las aplicaciones y los servicios relacionados
      de INTEGRA IT. Por ejemplo, si alguien participa en un ensayo clínico o se
      es un investigador que inicia sesión en nuestras aplicaciones, el
      controlador de datos es el patrocinador de ese ensayo y/o el proveedor de
      atención médica del participante.
    </p>
    <h2 class="title bold">5. PLATAFORMA, APLICACIONES Y DATOS DEL CLIENTE</h2>
    <p class="text">
      Como parte de la plataforma, las aplicaciones y los servicios relacionados
      de INTEGRA IT, los empleados de nuestros clientes y los titulares
      autorizados pueden ingresar información de o sobre sus titulares
      autorizados, empleados y sujetos de ensayos clínicos (colectivamente,
      "Datos del cliente"), en nuestros servidores.
    </p>
    <p class="text">
      Esta política de privacidad no se aplica a los datos del cliente y no
      somos responsables del manejo de los datos del cliente por parte de
      nuestros clientes. Nuestros clientes tienen sus propias políticas con
      respecto a la recopilación, uso y divulgación de su información personal.
      Nuestro uso de los datos del cliente está sujeto al acuerdo escrito de
      servicios entre INTEGRA IT y el cliente que de ninguna manera implica la
      venta de sus datos.
    </p>
    <p class="text">
      La responsabilidad de INTEGRA IT en virtud de ese acuerdo, es la
      obligación de mantener los datos del cliente seguros y protegidos.
    </p>
    <h2 class="title bold">6. INFORMACIÓN PERSONAL QUE RECOPILA INTEGRA IT</h2>
    <h3 class="bold">6.1. Información que proporciona el titular</h3>
    <p class="text">
      La información personal que se puede proporcionar a través de los
      servicios incluye:
    </p>
    <ul>
      <li class="text">
        Información de contacto personal y comercial, como nombre, apellido,
        dirección postal, dirección de correo electrónico, número de teléfono,
        cargo y nombre del empleador.
      </li>
      <li class="text">
        Información de perfil, como nombre de titular y contraseña, industria,
        intereses y preferencias.
      </li>
      <li class="text">
        Comentarios y correspondencia, como la información que proporciona un
        titular como respuestas a las encuestas, cuando participa en actividades
        de investigación de mercado, informa un problema con los Sitios, recibe
        atención al cliente o se comunica con INTEGRA IT.
      </li>
      <li class="text">
        Información de transacciones, como detalles sobre cualquier compra que
        realice a través de los Sitios, registros de eventos que realice a
        través de los Sitios y detalles de facturación.
      </li>
      <li class="text">
        Información de uso, como información sobre cómo se usan los Sitios e
        interactúa con nosotros.
      </li>
      <li class="text">
        Información de marketing, como las preferencias para recibir
        comunicaciones de marketing y detalles sobre cómo se relaciona con
        ellos.
      </li>
      <li class="text">
        Podemos combinar otra información disponible públicamente, como la
        información relacionada con la organización para la que el titular
        trabaja, con la información personal que este proporciona a través de
        nuestros Sitios o Servicios
      </li>
    </ul>
    <h3 class="bold">6.3. Información recopilada automáticamente</h3>
    <p class="text">
      Podemos recopilar una dirección IP de los visitantes de nuestros Sitios.
      Usamos direcciones IP para ayudar a diagnosticar problemas con nuestro (s)
      servidor (es), para administrar los Sitios y para monitorear las
      actividades e interacciones con nuestros Sitios. También podemos registrar
      automáticamente información sobre los titulares y su computadora o
      dispositivo móvil cuando acceden a nuestros Sitios. Por ejemplo, podemos
      registrar el nombre y la versión del sistema operativo de la computadora o
      dispositivo móvil del titular, el fabricante y el modelo, el tipo de
      navegador, el idioma del navegador, la resolución de la pantalla, el sitio
      web que visitó antes de navegar a nuestros Sitios, las páginas que vio, el
      tiempo que pasó en una página, tiempos de acceso e información sobre su
      uso y acciones en nuestros Sitios. Recopilamos esta información sobre
      usted mediante cookies.
    </p>
    <h3 class="bold">6.4. Uso de la información personal de los titulares</h3>
    <p class="text">
      Usamos esta información para proporcionar nuestros servicios. Si el
      titular tiene una cuenta de INTEGRA IT o utiliza nuestros Sitios, usamos
      su información personal para:
    </p>
    <ul>
      <li class="text">Operar, mantener, administrar y mejorar los Sitios;</li>
      <li class="text">
        Administrar y comunicarse con el titular con respecto a la cuenta de
        INTEGRA IT, incluso enviando anuncios de servicio, avisos técnicos,
        actualizaciones, alertas de seguridad y mensajes administrativos y de
        soporte;
      </li>
      <li class="text">
        Procesar y administrar los registros que se realicen a través de los
        Sitios, incluso para rastrear y administrar capacitaciones o eventos
        para los que los titulares se registran y asisten.
      </li>
      <li class="text">
        Comprender mejor las necesidades e intereses de los titulares y
        personalizar su experiencia con los Sitios.
      </li>
      <li class="text">
        Brindar soporte y mantenimiento para los Sitios y nuestros Servicios.
      </li>
      <li class="text">
        Responder a solicitudes, preguntas y comentarios relacionados con el
        servicio.
      </li>
    </ul>
    <h3 class="bold">6.4. Cumplimiento de la legislación vigente</h3>
    <p class="text">
      Usamos la información personal según es necesario o apropiado para cumplir
      con las leyes aplicables, solicitudes y procesos legales, como responder a
      citaciones o solicitudes de autoridades gubernamentales.
    </p>
    <h3 class="bold">
      6.5. Compartir información con el consentimiento del titular
    </h3>
    <p class="text">
      Podemos usar o compartir información personal con el consentimiento
      respectivo, por ejemplo, cuando el titular da su consentimiento para que
      publiquemos sus testimonios o respaldos en nuestros Sitios, cuando este
      nos indica que tomemos una acción específica con respecto a su información
      personal o si opta por recibir comunicaciones de marketing.
    </p>

    <h3 class="bold">
      6.6. Uso de la información de los titulares para crear datos anónimos para
      análisis
    </h3>
    <p class="text">
      Podemos crear datos anónimos a partir de la información personal que
      recopilamos de los titulares. Convertimos la información personal en datos
      anónimos al excluir la información que hace que los datos identifiquen a
      alguien personalmente. Usamos estos datos anónimos para fines comerciales
      legales, como mejorar nuestros Sitios y Servicios.
    </p>
    <h3 class="bold">6.7. Prevención del fraude y seguridad</h3>
    <p class="text">
      Usamos la información personal como sea necesario o apropiado para:
    </p>
    <ul>
      <li class="text">
        Hacer cumplir los términos y condiciones que rigen nuestros Servicios.
      </li>
      <li class="text">
        Proteger nuestros derechos, privacidad, seguridad o propiedad, y / o los
        de los titulares u otros.
      </li>
      <li class="text">
        Proteger, investigar y disuadir contra actividades fraudulentas,
        dañinas, no autorizadas, no éticas o ilegales.
      </li>
    </ul>
    <h3 class="bold">6.8. Lineamientos para compartir información comercial</h3>
    <p class="text">
      Excepto como se describe en esta Política de privacidad, no compartimos la
      información personal que nos proporcionan con otras organizaciones.
    </p>
    <p class="text bold">
      Revelamos información personal a terceros en las siguientes
      circunstancias:
    </p>
    <ul>
      <li class="text">
        <span class="bold">Afiliados:</span> Podemos divulgar su información
        personal a nuestras subsidiarias y afiliadas corporativas para
        propósitos consistentes con esta Política de Privacidad.
      </li>
      <li class="text">
        <span class="bold">Proveedores de servicio:</span> Podemos emplear
        empresas e individuos de terceros para administrar y proporcionar los
        Servicios en nuestro nombre (como capacitación, atención al cliente,
        alojamiento, entrega de correo electrónico y servicios de administración
        de bases de datos). Estos terceros pueden usar su información solo como
        lo indique INTEGRA IT y de una manera consistente con esta Política de
        Privacidad, y tienen prohibido usar o divulgar su información para
        cualquier otro propósito.
      </li>
      <li class="text">
        <span class="bold">Asesores profesionales:</span> Podemos divulgar
        información personal a asesores profesionales, como abogados, banqueros,
        auditores y aseguradores, cuando sea necesario en el curso de los
        servicios profesionales que nos prestan.
      </li>
    </ul>
    <h2 class="title bold">
      7. CUMPLIMIENTO Y APLICACIÓN DE LAS LEYES; PROTECCION Y SEGURIDAD
    </h2>
    <p class="text">
      INTEGRA IT puede divulgar información sobre los titulares al gobierno o
      funcionarios encargados de hacer cumplir la ley o partes privadas según lo
      requiera la ley, y divulgar y usar dicha información según lo consideremos
      necesario o apropiado para:
    </p>
    <ul>
      <li class="text">
        Cumplir con las leyes aplicables y las solicitudes y los procesos
        legales, como responder a citaciones o solicitudes de autoridades
        gubernamentales.
      </li>
      <li class="text">
        Hacer cumplir los términos y condiciones que rigen nuestros Servicios.
      </li>
      <li class="text">
        Proteger nuestros derechos, privacidad, seguridad o propiedad, y/o los
        titulares u otros.
      </li>
      <li class="text">
        Proteger, investigar y disuadir de actividades fraudulentas, dañinas, no
        autorizadas, no éticas o ilegales.
      </li>
    </ul>
    <h3 class="bold">7.1. Protección de los datos de los titulares</h3>
    <p class="text">
      INTEGRA IT utiliza una variedad de medidas de seguridad administrativa,
      tanto internas como técnica para proteger la información personal. INTEGRA
      IT establece reglas de procedimientos de control interno que tienen que
      ver específicamente con el manejo de los datos personales. Estos incluyen
      medidas de control para la protección de la información privada recogida
      en línea. Los colaboradores de INTEGRA IT están capacitados para
      comprender y cumplir con estas medidas de control. Por otra parte,
      nuestros propios colaboradores estan conscientes de nuestra protección de
      información de datos, directrices y normas. A pesar de que estamos
      comprometidos con la protección de la información personal de los
      titulares, estos también deben tomar medidas de precaución necesaria para
      la protección de su información personal durante el uso de las soluciones.
      Además, INTEGRA IT emplea sus mejores esfuerzos, considerando la
      vulnerabilidad de los sistemas informáticos, y los constantes avances de
      las tecnologías.
    </p>
    <h3 class="bold">7.2. Protección de datos de menores</h3>
    <p class="text">
      La protección de datos en línea de menores es especialmente importante.
      INTEGRA IT no reúne ni solicita deliberadamente informaciones sobre
      menores de edad sin el consentimiento explícito de sus padres o tutores.
    </p>
    <h3 class="bold">7.3. Transferencias comerciales</h3>
    <p class="text">
      INTEGRA IT puede vender, transferir o compartir algunos o todos sus
      negocios o activos, incluida su información personal, en relación con un
      acuerdo comercial (o posible acuerdo comercial) como una fusión,
      consolidación, adquisición, reorganización o venta de activos o en caso de
      quiebra, en cuyo caso haremos todos los esfuerzos razonables para exigir
      al destinatario que cumpla con esta Política de privacidad.
    </p>
    <h3 class="bold">
      7.4. Acceder, actualizar, corregir o eliminar información de los titulares
    </h3>
    <p class="text">
      Todos los titulares de cuentas de INTEGRA IT pueden revisar, actualizar,
      corregir o eliminar la información personal en su perfil de registro
      iniciando sesión en su cuenta. Los titulares de cuentas de INTEGRA IT
      también pueden comunicarse con nosotros en
      <a href="mailto:support@integrait.co">support@integrait.co</a>.
    </p>
    <h3 class="bold">7.5. Testimonios</h3>
    <p class="text">
      Si un titular nos dio su consentimiento para publicar un testimonio en
      nuestros Sitios, pero desea actualizarlo o eliminarlo, se puede comunicar
      con
      <a href="mailto:support@integrait.co">support@integrait.co</a>.
    </p>
    <h3 class="bold">7.6. Elegir no compartir información personal</h3>
    <p class="text">
      Cuando la ley nos exija recopilar información personal, o cuando
      necesitemos información personal para brindar nuestros Servicios, pero el
      titular no proporciona esta información cuando se le solicita (o luego
      solicita eliminarla), es posible que no podamos proporcionarle los
      Servicios y es posible que el titular deba cerrar su cuenta. Le decimos a
      los titulares qué información deben proporcionar para recibir los
      Servicios.
    </p>
    <h2 class="title bold">8. COOKIES Y TECNOLOGIAS SIMILARES</h2>
    <p class="text">
      Podemos recopilar información mediante "cookies". Las cookies son pequeños
      archivos de datos almacenados en el disco duro del computador o
      dispositivo móvil por un sitio web. Podemos utilizar cookies de sesión
      (que caducan una vez que cierra su navegador web) y cookies persistentes
      (que permanecen en el computador o dispositivo móvil hasta que las
      elimine) para brindar una experiencia más personal e interactiva en
      nuestro Sitio.<br />
      Usamos dos categorías amplias de cookies:
    </p>
    <ul>
      <li class="text">
        Cookies de origen, que nosotros enviamos directamente al computador o
        dispositivo móvil, que usamos para reconocer el computador o dispositivo
        móvil cuando vuelve a visitar nuestro Sitio.
      </li>
      <li class="text">
        Cookies de terceros, que son atendidas por proveedores de servicios en
        nuestro Sitio, y pueden ser utilizadas por dichos proveedores de
        servicios para reconocer el computador o dispositivo móvil cuando visita
        otros sitios web.
      </li>
    </ul>
    <h3 class="bold">8.1. Cookies que utilizamos</h3>
    <p class="text">
      Nuestro sitio utiliza los siguientes tipos de cookies para los fines que
      se establecen a continuación:
    </p>
    <h3 class="bold">8.1.1. Cookies esenciales.</h3>
    <p class="text">
      Estas cookies son esenciales para brindar los servicios disponibles a
      través de nuestro Sitio y para permitir utilizar algunas de sus funciones.
      Sin estas cookies, los servicios solicitados no se pueden proporcionar, y
      solo usamos estas cookies para brindar esos servicios. Estos son
      utilizados principalmente por nuestros empleados y contratistas.
    </p>
    <h3 class="bold">8.1.2. Cookies de funcionalidad.</h3>
    <p class="text">
      Estas cookies permiten que nuestro sitio recuerde las elecciones que
      realiza cuando utiliza nuestro sitio. El propósito de estas cookies es
      brindarle una experiencia más personal y evitar que tenga que volver a
      seleccionar sus preferencias o volver a ingresar información cada vez que
      visite nuestro Sitio.
    </p>
    <h3 class="bold">8.1.3. Cookies de análisis y rendimiento.</h3>
    <p class="text">
      Estas cookies se utilizan para recopilar información sobre el tráfico a
      nuestro sitio y cómo los titulares utilizan nuestro sitio. La información
      recopilada puede incluir el número de visitantes a nuestro Sitio, los
      sitios web que los remitieron a nuestro Sitio, las páginas que visitaron
      en nuestro Sitio, la hora del día y la duración que visitaron nuestro
      Sitio, si han visitado nuestro Sitio antes y otra información similar.
      Usamos esta información para ayudar a operar nuestro Sitio de manera más
      eficiente, para recopilar información demográfica amplia y para monitorear
      el nivel de actividad en nuestro Sitio. Utilizamos principalmente Google
      Analytics para este propósito. Google Analytics utiliza sus propias
      cookies. Solo se utiliza para mejorar el funcionamiento de nuestro sitio.
      Puede encontrar más información sobre las cookies de Google Analytics aquí
      y sobre cómo Google protege sus datos aquí.
    </p>
    <h3 class="bold">8.1.4. Cookies dirigidas y publicitarias.</h3>
    <p class="text">
      Estas cookies rastrean los hábitos de navegación para permitirnos mostrar
      publicidad en un sitio de terceros que probablemente sea de su interés.
      Estas cookies utilizan información sobre los historiales de navegación
      para agruparlo con otros titulares que tienen intereses similares. Se
      pueden desactivar ciertas cookies que recuerden los hábitos de navegación
      y dirigen publicidad. Si se elige eliminar las cookies dirigidas o
      publicitarias, se seguirán viendo anuncios, pero es posible que no sean
      relevantes para el titular.
    </p>
    <h3 class="bold">8.2. Desactivación de cookies.</h3>
    <p class="text">
      Por lo general, se pueden eliminar o rechazar las cookies a través de la
      configuración del navegador. Para hacer esto, se deben seguir las
      instrucciones proporcionadas por el navegador (generalmente ubicadas
      dentro de la función de "configuración", "ayuda" "herramientas" o
      "editar"). Muchos navegadores están configurados para aceptar cookies
      hasta que se cambie la configuración.
      <br /><br />
      Si no se aceptan nuestras cookies, se pueden experimentar algunos
      inconvenientes en el uso de nuestro Sitio. Por ejemplo, es posible que no
      podamos reconocer el computador o dispositivo móvil y es posible que deba
      iniciar sesión cada vez que un titular se visite nuestro Sitio.
    </p>
    <h3 class="bold">8.3. Seguimiento in situ.</h3>
    <p class="text">
      También podemos usar etiquetas de seguimiento (que también se conocen como
      balizas web) en nuestro Sitio para rastrear las acciones de los titulares
      mientras están en nuestro Sitio. A diferencia de las cookies, que un sitio
      web almacena en el disco duro de su computadora o dispositivo móvil, las
      etiquetas de seguimiento están incrustadas en las páginas web. <br />Las
      etiquetas compilan estadísticas sobre el uso del Sitio, para que podamos
      administrar nuestro contenido de manera más efectiva. La información que
      recopilamos mediante etiquetas de seguimiento no está vinculada a los
      datos personales de nuestros titulares.
    </p>
    <h3 class="bold">8.4. No rastreo de señales.</h3>
    <p class="text">
      Algunos navegadores de Internet pueden estar configurados para enviar
      señales de "No rastrear" a los servicios en línea que visita un titular.
      Actualmente no respondemos a las señales de no seguimiento.
    </p>
    <h2 class="title bold">9. TRANSFERENCIA INTERNACIONAL</h2>
    <p class="text">
      INTEGRA IT tiene su sede en los Estados Unidos, Colombia y Panamá, tiene
      afiliados y proveedores de servicios en otros países, y su información
      personal puede ser transferida a los Estados Unidos u otras ubicaciones
      fuera de su estado, provincia, país u otra jurisdicción gubernamental.
      Cumplimos con las leyes de privacidad de cada país.
    </p>
    <h2 class="title bold">10. OTROS SITIOS Y SERVICIOS</h2>
    <p class="text">
      Los Sitios pueden contener enlaces a otros sitios web y servicios. Estos
      enlaces no son un respaldo, autorización o representación de que estamos
      afiliados a ese tercero. No ejercemos control sobre los sitios web o
      servicios de terceros y no somos responsables de sus acciones. Otros
      sitios web y servicios siguen reglas diferentes con respecto al uso o
      divulgación de la información personal que reciben. Es importante que los
      titulares conozcan las políticas de privacidad de los otros sitios web que
      visita y los servicios que utiliza.
    </p>
    <h2 class="title bold">11. CONTENIDO GENERADO POR EL TITULAR</h2>
    <p class="text">
      Podemos poner a disposición en nuestros Sitios, o enlazar, funciones que
      le permiten compartir información en línea (por ejemplo, en tableros de
      mensajes, en áreas de chat, en la carga de archivos, a través de eventos,
      etc.). Los titulares deben tener en cuenta que cada vez que se divulga
      voluntariamente información personal en línea, esa información se vuelve
      pública y puede ser recopilada y utilizada por otros. No tenemos control
      ni asumimos ninguna responsabilidad por el uso, almacenamiento o difusión
      de dicha información personal divulgada públicamente. Al publicar
      información personal en línea en foros públicos, el titular puede recibir
      mensajes no solicitados de otras partes.
    </p>
    <h2 class="title bold">12. CAMBIOS A ESTA POLITICA DE PRIVACIDAD</h2>
    <p class="text">
      Nos reservamos el derecho a modificar esta Política de privacidad en
      cualquier momento. Le recomendamos que revise periódicamente esta página
      para obtener la información más reciente sobre nuestras prácticas de
      privacidad. Si realizamos cambios sustanciales a esta Política de
      privacidad, se le notificará a través de la información de contacto que
      nos ha proporcionado o de otra manera que creemos que es razonablemente
      probable que se comunique con usted. Esto puede incluir la publicación de
      un anuncio específico en nuestros Sitios.
    </p>
    <p class="text">
      Cualquier modificación a esta Política de privacidad entrará en vigencia
      cuando publiquemos los nuevos términos y / o cuando se implementen los
      nuevos cambios en el Servicio (o según se indique de otro modo en el
      momento de la publicación). En todos los casos, su uso continuado de los
      Sitios y Servicios después de la publicación de cualquier Política de
      Privacidad modificada indica su aceptación de los términos de la Política
      de Privacidad modificada.
    </p>
    <h2 class="title bold">
      13. ATENCIÓN DE CONSULTAS, RECLAMOS, SOLICITUD DE RECTIFICACIONES,
      ACTUALIZACIÓN Y SUSPENSIÓN DE DATOS PERSONALES
    </h2>
    <p class="text">
      El Líder de Seguridad de la información es responsable en adelantar las
      acciones necesarias para el Tratamiento de Datos y el ejercicio de los
      derechos del titular, atenderá y gestionará las solicitudes por medio del
      correo electrónico
      <a href="mailto:support@integrait.co">support@integrait.co</a> o a través
      de la línea +57 (1) 8050057 Bogotá.
    </p>
    <h3 class="bold">13.1. Consultas:</h3>
    <p>
      Los titulares o sus causahabientes podrán consultar la información
      personal del titular que repose en cualquier base de datos de INTEGRA IT
      S.A.S. suministrando a estos toda la información contenida en el registro
      individual o que esté vinculada con la identificación del titular. Las
      consultas se formularán por los canales habilitados anteriormente
      mencionados, para dar respuesta INTEGRA IT S.A.S podrá realizar
      actividades de verificación de identidad como preguntas de seguridad, y
      demás que se consideren necesarias con el fin de proteger la información
      de los titulares. La consulta será atendida en un máximo de diez (10) días
      hábiles contados a partir de la fecha de recibo de la misma. Cuando no
      fuere posible atender la consulta dentro de dicho término, INTEGRA IT
      S.A.S informará al interesado, expresando los motivos de la demora y
      señalando la fecha en que se atenderá su consulta, la cual en ningún caso
      podrá superar los cinco (5) días hábiles siguientes al vencimiento del
      primer término.
    </p>
    <h3 class="bold">13.2. Reclamos:</h3>
    <p class="text">
      El titular o sus causahabientes que consideren que la información
      contenida en una base de datos debe ser objeto de corrección,
      actualización o supresión, o cuando adviertan el presunto incumplimiento
      de cualquiera de los deberes contenidos en las leyes vigentes, podrán
      presentar un reclamo ante INTEGRA IT S.A.S el cual será tramitado bajo las
      siguientes reglas:
    </p>
    <ul>
      <li class="text">
        El reclamo se formulará mediante solicitud dirigida a INTEGRA IT S.A.S
        con la identificación del titular, la descripción de los hechos que dan
        lugar al reclamo, la dirección, y acompañando los documentos que se
        quiera hacer valer. Si el reclamo resulta incompleto, se requerirá al
        interesado dentro de los cinco (5) días siguientes a la recepción del
        reclamo para que subsane las fallas.
      </li>
      <li class="text">
        Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el
        solicitante presente la información requerida, se entenderá que ha
        desistido del reclamo.
      </li>
      <li class="text">
        En caso de que quien reciba el reclamo no sea competente para
        resolverlo, dará traslado a quien corresponda en un término máximo de
        dos (2) días hábiles e informará de la situación al interesado.
      </li>
      <li class="text">
        El término máximo para atender el reclamo será de quince (15) días
        hábiles contados a partir del día siguiente a la fecha de su recibo,
        cuando no fuere posible atender el reclamo dentro de dicho término,
        INTEGRA IT S.A.S informará al interesado los motivos de la demora y la
        fecha en que se atenderá su reclamo, la cual en ningún caso podrá
        superar los ocho (8) días hábiles siguientes al vencimiento del primer
        término.
      </li>
    </ul>
    <h3 class="bold">13.3. Comunicación:</h3>
    <p class="text">
      INTEGRA IT es el controlador de su información cuando se procesa en el
      contexto de nuestros Sitios y Servicios. Puede ponerse en contacto con
      nuestro responsable de protección de datos enviando un correo electrónico
      a <a href="mailto:support@integrait.co">support@integrait.co</a> <br />
      Si tiene preguntas sobre la base legal de cómo procesamos su información
      personal, contáctenos en
      <a href="mailto:support@integrait.co">support@integrait.co</a>
    </p>
  </article>
</main>
