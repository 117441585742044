import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Logger } from 'aws-amplify';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';
import { InformedConsent } from 'src/app/shared/global.variables';
import { ConsentedActions } from '../../informed-consent-follow-up-main.component';
import {
  InformedConsentState,
  UpdateConsentedUserInput,
  tp2SendProjectAdminNotificationInput,
} from 'src/app/modules/informed-consent/informed-consent.types';
const logger = new Logger('tp2-econsent-detail');
@Component({
  selector: 'app-user-detail-card',
  templateUrl: './user-detail-card.component.html',
  styleUrls: ['./user-detail-card.component.scss'],
})
export class UserDetailCardComponent {
  @Input() userData: any = {};
  @Input() infomedConsent: any = {};
  @Input() confInformedConsent: any = {};
  @Input() projectAndSite: any = {};
  @Output() userActionEvent: EventEmitter<any> = new EventEmitter();
  display: boolean = false;
  deleteAction: string = ConsentedActions.DELETE;
  enableSignature: string = ConsentedActions.ENABLE_SIGNATURE;
  displayDialogEnableSign: boolean = false;

  constructor(private informedConsentService: InformedConsentService) {}
  async onEnableSignatures() {
    try {
      this.displayDialogEnableSign = false;
      this.informedConsentService.trialpalService.showSpinner(
        'informedConsent.entity',
        'UPDATE'
      );
      const inputConsentedUser: UpdateConsentedUserInput = {
        id: this.userData.id,
        _version: this.userData._version,
      };

      let stateChanges: any[] = this.informedConsentService.updateStateChanges(
        InformedConsentState.PENDING_FOR_SIGNATURE,
        this.userData.stateChanges
      );

      this.userData = await this.informedConsentService.updateConsentedUser(
        inputConsentedUser,
        {
          state: InformedConsentState.PENDING_FOR_SIGNATURE,
          stateChanges,
        }
      );
      const verificationICInput: InformedConsent = {
        consentedUser: this.userData,
        informedConsent: this.infomedConsent,
        confInformedConsent: this.confInformedConsent,
      };
      const informedConsentInstance =
        await this.informedConsentService.updateAndVerifyInformedConsentInstance(
          verificationICInput,
          InformedConsentState.PENDING_FOR_SIGNATURE
        );
      await this.sendEmail();
      this.userActionEvent.emit({
        action: this.enableSignature,
        consentedUser: this.userData,
        informedConsentInstance,
      });
      this.informedConsentService.trialpalService.hideSpinner();
      this.showMessage(
        'success',
        this.informedConsentService.trialpalService.translateService.instant(
          'informedConsent.followUp.signaturesEnabled'
        ),
        this.informedConsentService.trialpalService.translateService.instant(
          'informedConsent.followUp.signaturesEnabledDetail'
        )
      );
      this.informedConsentService.recordeConsentEvent(
        'site_consent_enablesign_confirm',
        {
          project: this.projectAndSite.projectId,
          site: this.projectAndSite.siteId,
        }
      );
    } catch (error) {
      this.informedConsentService.trialpalService.hideSpinner();
      this.showMessage(
        'error',
        this.informedConsentService.trialpalService.translateService.instant(
          'general.messageErrorOperation.summary'
        ),
        this.informedConsentService.trialpalService.translateService.instant(
          'general.messageErrorOperation.detail'
        )
      );
      logger.debug('Error habilidando firmas', error);
    }
  }

  async sendEmail(): Promise<void> {
    const input: tp2SendProjectAdminNotificationInput = {
      data: JSON.stringify({
        siteId: this.userData.siteId,
        email: this.userData.user.email,
      }),
      user: this.informedConsentService.auth.getUsername(),
      source: 'follow-up',
    };
    await this.informedConsentService.sendProjectAdminNotification(input);
  }

  showMessage(alertType: string, summary: string, detail: string): void {
    this.informedConsentService.trialpalService.messageService.clear();
    this.informedConsentService.trialpalService.messageService.add({
      severity: alertType,
      summary: summary,
      detail: detail,
    });
  }
  trackEnableSignatureCTA() {
    this.informedConsentService.recordeConsentEvent('site_consent_enablesign', {
      project: this.projectAndSite.projectId,
      site: this.projectAndSite.siteId,
    });
  }
  trackDeleteConsentedCTA() {
    this.informedConsentService.recordeConsentEvent(
      'site_consent_delete_consented',
      {
        project: this.projectAndSite.projectId,
        site: this.projectAndSite.siteId,
      }
    );
  }
  trackDeleteConsented() {
    this.informedConsentService.recordeConsentEvent(
      'site_consent_delete_consented_confirm',
      {
        project: this.projectAndSite.projectId,
        site: this.projectAndSite.siteId,
      }
    );
  }
}
