<form id="formEditUser" name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="p-fluid p-formgrid p-grid" id="formWindows">
    <div class="p-field p-col-12 p-md-6">
      <label>
        {{ 'user.labelTypeOfUser' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-multiSelect
        class="input multiSelect"
        name="role"
        #grupos="ngModel"
        defaultLabel="{{ 'general.choose' | translate }}"
        placeholder="{{ 'general.choose' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        [options]="allActiveRoles"
        [(ngModel)]="role"
        [filter]="false"
        [showHeader]="false"
        optionLabel="name"
        optionDisabled="inactive"
        (onClick)="changeSelectRole($event)"
        required
      ></p-multiSelect>
      <small
        class="form-error"
        *ngIf="grupos.invalid && (f.submitted || grupos.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>

    <div
      class="p-field p-col-12 p-md-6"
      id="projects"
      *ngIf="!isFromSubjectDetail && assignRoles"
    >
      <label>
        {{ 'user.labelRoles' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-multiSelect
        class="input multiSelect"
        name="rol"
        #role="ngModel"
        defaultLabel="{{ 'general.choose' | translate }}"
        placeholder="{{ 'general.choose' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        [options]="roles"
        [(ngModel)]="selectedRoles"
        [filter]="false"
        required
      >
        <ng-template let-role pTemplate="item">
          <div>
            <div pTooltip="{{ role.label }}">
              {{ role.label | shortText: 100 }}
            </div>
          </div>
        </ng-template>
      </p-multiSelect>
      <small
        class="form-error"
        *ngIf="role.invalid && (f.submitted || role.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>

    <div
      class="p-field p-col-12 p-md-6"
      id="projects"
      *ngIf="assignProject && !isFromSubjectDetail"
    >
      <label>
        {{ 'user.labelProjects' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-multiSelect
        id="proyects"
        appendTo="body"
        class="input multiSelect"
        name="proyects"
        [disabled]="blockDropdownProject"
        [options]="projects"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        [(ngModel)]="selectedProjects"
        #projectsInput="ngModel"
        [filter]="true"
        filterBy="label"
        (onChange)="selectedProjectsEvent($event)"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        [maxSelectedLabels]="2"
        selectedItemsLabel="{2}
          {{ 'user.labelSelectedProjects' | translate }}
        "
        required
      >
        <ng-template let-project pTemplate="item">
          <div>
            <div pTooltip="{{ project.label }}">
              {{ project.label | shortText: 100 }}
            </div>
          </div>
        </ng-template>
      </p-multiSelect>
      <small
        class="form-error"
        *ngIf="
          !projectsInput.valid &&
          (f.submitted || projectsInput.touched) &&
          maxProjectsReached
        "
        >{{
          'general.maxSelectedEntities'
            | translate
              : {
                  entity: 'project.entityPlural' | translate | lowercase
                }
        }}</small
      >
    </div>

    <div
      class="p-field p-col-12 p-md-6"
      *ngIf="assignProject && !isFromSubjectDetail"
    >
      <label>
        {{ 'user.labelSites' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-multiSelect
        (onChange)="selectedSitesEvent($event)"
        [(ngModel)]="selectedSites"
        [filter]="true"
        [group]="true"
        [maxSelectedLabels]="2"
        [options]="groupSites"
        #sitesInput="ngModel"
        appendTo="body"
        class="input multiSelect"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        filterBy="label"
        name="sites"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        selectedItemsLabel="{2}
        {{ 'user.labelSelectedSites' | translate }}
      "
        required
      >
        <ng-template let-group pTemplate="group">
          <hr />
          <div class="p-d-flex p-ai-center p-ml-3 p-py-2">
            <label style="font-size: 1rem">{{
              group.label | shortText: 100
            }}</label>
          </div>
        </ng-template>
        <ng-template let-site pTemplate="site">
          <div>
            <span>
              <div pTooltip="{{ site.label }}">
                {{ site.label | shortText: 100 }}
              </div></span
            >
          </div>
        </ng-template>
      </p-multiSelect>
      <small
        class="form-error"
        *ngIf="!sitesInput.valid && (f.submitted || sitesInput.touched)"
        >{{
          (!maxSitesReached
            ? 'general.required'
            : 'general.maxSelectedEntities'
          )
            | translate
              : {
                  entity: 'site.entityPlural' | translate | lowercase
                }
        }}</small
      >
    </div>

    <div class="p-field p-col-12 p-md-6">
      <label>
        {{ 'user.labelName' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <input
        id="name"
        class="input"
        name="name"
        type="text"
        autocomplete="off"
        pInputText
        [(ngModel)]="user.name"
        required
        #name="ngModel"
      />
      <small
        class="form-error"
        *ngIf="!name.valid && (f.submitted || name.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <!-- notification preference -->
    <div class="p-field p-col-12 p-md-6">
      <label for="notificationPreference">
        {{ 'user.selectNotificationPreference' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-dropdown
        #alertType="ngModel"
        [showClear]="true"
        id="notificationPreference"
        class="input"
        placeholder="{{ 'general.choose' | translate }}"
        name="notificationPreference"
        [options]="alertTypes"
        optionLabel="name"
        optionValue="value"
        [(ngModel)]="user.notificationPreference"
        [required]="true"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="alertType.invalid && (f.submitted || alertType.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="email">
        {{ 'user.labelEmail' | translate }}
        <span
          *ngIf="user.notificationPreference === 'EMAIL'"
          class="inputRequired"
          >*
        </span>
      </label>
      <input
        id="email"
        class="input"
        name="email"
        type="email"
        autocomplete="off"
        pInputText
        [(ngModel)]="user.email"
        #email="ngModel"
        [pattern]="user.notificationPreference === 'EMAIL' ? emailPatern : ''"
        [required]="user.notificationPreference === 'EMAIL'"
      />
      <small
        class="form-error"
        *ngIf="
          !email.valid &&
          (f.submitted || email.touched) &&
          !email.errors?.pattern
        "
        >{{ 'general.required' | translate }}</small
      >
      <br
        *ngIf="
          email.errors && email.errors.pattern && (f.submitted || email.touched)
        "
      />
      <small
        class="form-error"
        *ngIf="
          email.errors && email.errors.pattern && (f.submitted || email.touched)
        "
      >
        {{ 'user.labelPatternEmail' | translate }}
      </small>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="phoneN">
        {{ 'user.labelPhoneNumber' | translate
        }}<span
          *ngIf="user.notificationPreference === 'SMS' || isProjectMFAActive"
          class="inputRequired"
          >*
        </span>
      </label>
      <input
        class="input"
        id="phoneN"
        name="phoneN"
        id="phoneN"
        name="phoneN"
        autocomplete="off"
        placeholder="+### ########"
        type="text"
        pTooltip="{{ 'user.tooltipInpuPhoneNumber' | translate }}"
        tooltipPosition="top"
        pInputText
        [(ngModel)]="user.phoneNumber"
        #phone="ngModel"
        [required]="user.notificationPreference === 'SMS' || isProjectMFAActive"
      />
      <small
        class="form-error"
        *ngIf="
          user.phoneNumber &&
          !trialpalService.phoneNumberService.isValidPhoneNumber(
            user.phoneNumber
          ).isValid
        "
      >
        {{
          trialpalService.phoneNumberService.isValidPhoneNumber(
            user.phoneNumber
          ).message
        }} </small
      ><br />
      <br />
      <small
        class="form-error"
        *ngIf="
          !phone.valid &&
          (f.submitted || phone.touched) &&
          !phone.errors?.pattern
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>

    <div
      class="p-field p-col-12 p-md-6"
      *ngIf="assignSubjects && !isFromSubjectDetail"
    >
      <label>
        {{ 'user.labelSubjects' | translate }}
        <span class="inputRequired"> * </span>
      </label>
      <p-chips
        ngDefaultControl
        separator=","
        id="subjects"
        name="subjects"
        required
        [(ngModel)]="selectedSubjects"
        pTooltip="{{ 'user.tooltipInputSubjects' | translate }}"
        tooltipPosition="top"
        [addOnBlur]="true"
        [allowDuplicate]="false"
        #subjects="ngModel"
      ></p-chips>
      <small
        class="form-error"
        *ngIf="subjects.invalid && (f.submitted || subjects.touched)"
        >{{
          (!maxSubjectsReached
            ? 'general.required'
            : 'general.maxSelectedEntities'
          )
            | translate
              : { entity: 'subject.entityPlural' | translate | lowercase }
        }}</small
      >
    </div>

    <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
      <button
        pButton
        id="cancel"
        label="{{ 'user.buttonCancelCreateUser' | translate }}"
        type="button"
        (click)="onCancelar()"
        class="p-button-secondary"
      ></button>
    </div>

    <div class="p-field p-col-12 p-sm-4 p-md-3">
      <button
        pButton
        id="update"
        label="{{ 'user.buttonUpdateUser' | translate }}"
        type="submit"
      ></button>
    </div>
  </div>
</form>
