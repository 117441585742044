import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Analytics } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-button-table-card-mode',
  templateUrl: './button-table-card-mode.component.html',
})
export class ButtonTableCardModeComponent implements OnChanges {
  tableViewMode: boolean = false;
  @Input() dataSource: string = '';
  @Output() cardOrTableModeEmit: EventEmitter<boolean> = new EventEmitter();
  constructor(private authService: AuthService) {}

  ngOnChanges(): void {
    const login = this.authService.getUsername();
    const users = this.getViewModeFromLocalStorage();
    const userIndex = this.findUserIndexByLogin(users, login);

    if (userIndex !== -1) {
      this.tableViewMode = users[userIndex].viewMode === 'table';
    }

    this.emitCardOrTableMode();
  }

  setItemViewMode(): void {
    const login = this.authService.getUsername();
    const users = this.getViewModeFromLocalStorage();
    const userIndex = this.findUserIndexByLogin(users, login);

    if (userIndex !== -1) {
      users[userIndex].viewMode = this.tableViewMode ? 'table' : 'card';
    } else {
      users.push({
        login: login,
        viewMode: this.tableViewMode ? 'table' : 'card',
      });
    }

    this.saveUsersToLocalStorage(users);
  }

  findUserIndexByLogin(users: any[], login: string): number {
    return users.findIndex((user) => user.login === login);
  }

  getViewModeFromLocalStorage(): any[] {
    const usersString = localStorage.getItem('viewMode');
    return usersString ? JSON.parse(usersString) : [];
  }

  saveUsersToLocalStorage(users: any[]): void {
    localStorage.setItem('viewMode', JSON.stringify(users));
  }

  emitCardOrTableMode(): void {
    this.cardOrTableModeEmit.emit(this.tableViewMode);
  }

  selectCardOrTableMode() {
    this.tableViewMode = !this.tableViewMode;
    Analytics.record({
      name: 'site_prefer_view_type',
      attributes: {
        viewMode: this.tableViewMode ? 'table' : 'card',
        dataSource: this.dataSource,
        userSub: this.authService.user?.attributes?.sub,
        userName: this.authService.user?.username,
      },
    });
    this.cardOrTableModeEmit.emit(this.tableViewMode);
    this.setItemViewMode();
  }
}
