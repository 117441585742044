import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import {
  ConfTemperature,
  CreateVerifiedTemperatureRecordLogInput,
  IntensityReconciliationOption,
  TemperatureRecordLog,
  TemperatureUnit,
  UpdateVerifiedTemperatureRecordLogInput,
  VerifiedTemperatureRecordLog,
} from 'src/app/modules/conciliation/conciliation.types';
import {
  EntityTemperature,
  GeneralModel,
  NameValue,
  VerifiedEntityTemperature,
} from 'src/app/modules/conciliation/conciliationData';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Symptom } from 'src/app/services/trialpal.types';
import { TpHelpComponent } from 'src/app/shared/components/tp-help/tp-help.component';
import { TP2Entites } from '../../../../../../shared/global.variables';
import { ConciliationComponents } from '../../../../conciliationData';
import { PaginationService } from '../../../../pagination.service';
import { SummaryTemperatureComponent } from '../summary-temperature/summary-temperature.component';
import { TemperatureService } from '../temperature.service';

const logger = new Logger('tp2-editTemperature');
@Component({
  selector: 'app-temperature',
  templateUrl: './temperatureForm.component.html',
  styleUrls: ['./temperatureForm.component.scss'],
})
export class TemperatureFormComponent implements OnInit, OnChanges {
  @Input() currentTemperatureDay: any = {};
  @Input() temperatures: any[] = [];
  @Input() observations: any[] = [];
  @Input() subjectId: string = '';
  @Output() exportAuditRecord: EventEmitter<any> = new EventEmitter();
  units: NameValue[] = [];
  routes: NameValue[] = [];
  temperatureIndicator1: NameValue[] = [];
  temperatureIndicator2: NameValue[] = [];
  form: FormGroup;
  temperatureTaken: boolean = true;
  confTemperature!: ConfTemperature;
  verifiedTemperature?: VerifiedTemperatureRecordLog;
  temperatureRecord?: TemperatureRecordLog;
  timeZoneOffset: any;

  requiredTakenComment: boolean = false;
  requiredRouteComment: boolean = false;
  requiredUnitComment: boolean = false;
  requiredTemperatureComment: boolean = false;
  requiredTakenDateComment: boolean = false;
  requiredOtherComment: boolean = false;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  originalFormData: string = '';
  showTemperatureUnit: boolean = false;
  booleanOptions: any[] = [];
  isTemperatureDecimalActive: boolean = true;
  textHtml: string = '';
  temperatureReconciliationConfig: any;
  constructor(
    private trialpalService: TrialpalService,
    private formBuilder: FormBuilder,
    public temperatureService: TemperatureService,
    private authService: AuthService,
    private conciliationService: ConciliationService,
    private transformDatePipe: TransformDatePipe,
    private dialogService: DialogService,
    private paginationService: PaginationService
  ) {
    this.form = this.formBuilder.group({
      selectedIndicador1: [],
      selectedIndicador2: [],
      formTemperatureComment: [],
      formTemperatureUnit: [],
      formTemperatureUnitComment: [],
      formTemperatureRoute: [],
      formTemperatureRouteComment: [],
      formTemperatureWhichOtherRoute: [],
      formTemperatureWhichOtherRouteComment: [],
      formTemperatureTaken: [],
      formTemperatureTakenComment: [],
      formTemperatureTakenDate: [],
      formTemperatureTakenDateComment: [],
      changeReason: [''],
    });
  }

  ngOnInit() {
    this.requiredValues();
  }

  ngOnChanges(): void {
    if (!this.currentTemperatureDay) return;
    this.resetForm();
    this.confTemperature = this.currentTemperatureDay.configuration ?? {};
    logger.debug('Conf Temperature', this.currentTemperatureDay);
    this.temperatureReconciliationConfig =
      this.currentTemperatureDay?.confForm?.temperatureReconciliationConfig ??
      {};
    logger.debug(
      'temperatureReconciliationConfig',
      this.temperatureReconciliationConfig
    );
    this.temperatureRecord = this.currentTemperatureDay?.temperatureRecord;
    logger.debug('temperatureRecord', this.temperatureRecord);

    this.verifiedTemperature = this.currentTemperatureDay?.verifiedTemperature;
    this.confTemperature = {
      ...this.confTemperature,
      showTemperatureTaken: true,
    };
    this.booleanOptions = this.conciliationService.buildBooleanOptions();
    this.setTemperatureUnits();
    this.setTemperatureRoutes();

    //Rango de fechas que puede el usuario seleccionar
    this.getMaxDate(this.currentTemperatureDay?.dayInstance?.finishDate);
    this.getMinDate(this.currentTemperatureDay?.dayInstance?.startDate);
    this.requiredValues();
    this.setForm();
    this.setValuesVerified();
    logger.debug('isPendingInstance', this.currentTemperatureDay);
  }

  setTemperatureUnits() {
    const units: any[] = this.confTemperature?.units ?? [];
    this.units = [];
    const unitTranslateKey = 'temperaturerecordlog.enums.unit';
    for (let unit of units) {
      const unitSymbol = unit === TemperatureUnit.CELSIUS ? ' °C' : ' °F';
      this.units.push({
        name:
          this.trialpalService.translateService.instant(
            `${unitTranslateKey}.${unit}`
          ) + unitSymbol,
        value: unit,
      });
    }
    this.showTemperatureUnit = this.units.length > 1;
    this.setTemperatureUnit(undefined, true);
  }

  setTemperatureRoutes() {
    const routes = this.confTemperature?.routeOptions ?? [];
    this.routes = [];
    for (let route of routes) {
      this.routes.push({
        name: this.trialpalService.translateService.instant(
          `ediary.enums.temperatureRoute.${route}`
        ),
        value: route,
      });
    }
  }

  setTemperatureUnit(
    temperatureUnit: TemperatureUnit | undefined,
    controlTake: boolean
  ) {
    // Si no se muestra la unidad de temperatura, se asigna la primera unidad de la lista
    if (!this.showTemperatureUnit && controlTake) {
      temperatureUnit = this.units[0]?.value;
    }
    this.form.controls['formTemperatureUnit'].setValue(temperatureUnit);
    this.setIndicators(temperatureUnit);
  }

  setIndicators(value: TemperatureUnit | undefined) {
    if (!value) return;
    this.setIntegerRange(value);
    this.setDecimalRange(value);
  }

  getTemperatureRange(value: TemperatureUnit | undefined) {
    const CELSIUS = TemperatureUnit.CELSIUS;
    const celciusMinValue = this.confTemperature?.celciusRanges?.minValue ?? 35;
    const celciusMaxValue = this.confTemperature?.celciusRanges?.maxValue ?? 42;
    const fahrenheitMinValue =
      this.confTemperature?.fahrenheitRanges?.minValue ?? 93;
    const fahrenheitMaxValue =
      this.confTemperature?.fahrenheitRanges?.maxValue ?? 108;
    //Asigna el valor minimo y maximo según la unidad de temperatura seleccionado
    const min = value === CELSIUS ? celciusMinValue : fahrenheitMinValue;
    const max = value === CELSIUS ? celciusMaxValue : fahrenheitMaxValue;
    const decimalPlaces = this.getDecimalPlacesForUnit(value);
    return { min, max, decimalPlaces };
  }

  /**
   * Devuelve el número de decimales para una unidad de temperatura.
   * @param {TemperatureUnit | undefined} value
   *        Unidad de temperatura (Celsius o Fahrenheit).
   *        Si no se especifica, se usa `this.typeTemperature`.
   * @return {number} Número de decimales para la unidad especificada.
   *        Por defecto, 1 decimal si no hay configuraciones específicas.
   */
  getDecimalPlacesForUnit(value: TemperatureUnit | undefined): number {
    const temperaturaUnit =
      value ?? this.form.controls['formTemperatureUnit'].value;
    const celciusRanges = this.confTemperature?.celciusRanges;
    const fahrenheitRanges = this.confTemperature?.fahrenheitRanges;
    const celciusDecimal = celciusRanges?.decimalPlaces ?? 1;
    const fahrenheitDecimal = fahrenheitRanges?.decimalPlaces ?? 1;
    return temperaturaUnit === TemperatureUnit.CELSIUS
      ? celciusDecimal
      : fahrenheitDecimal;
  }

  setIntegerRange(value: TemperatureUnit | undefined) {
    this.temperatureIndicator1 = [];
    let { min, max } = this.getTemperatureRange(value);
    ({ min, max } = this.setRangeAccordingToAllowedAction(max, min));
    for (let i = Math.floor(min); i <= Math.floor(max); i++) {
      this.temperatureIndicator1.push({
        name: i.toString(),
        value: i.toString(),
      });
    }

    this.setDecimalRange(value);
  }

  setRangeAccordingToAllowedAction(max: number, min: number) {
    //Si la acción permitida es disminuir, se ajusta el rango máximo a la temperatura actual
    const allowedAction =
      this.temperatureReconciliationConfig?.allowIntensityReconciliation
        ?.allowedAction;
    if (allowedAction !== IntensityReconciliationOption.ANY) {
      if (
        allowedAction === IntensityReconciliationOption.DECREASE &&
        this.temperatureRecord?.temperature
      )
        max =
          this.temperatureRecord?.temperature < max
            ? this.temperatureRecord?.temperature
            : max;
      if (
        allowedAction === IntensityReconciliationOption.INCREASE &&
        this.temperatureRecord?.temperature
      )
        min =
          this.temperatureRecord?.temperature > min
            ? this.temperatureRecord?.temperature
            : min;
    }
    return { min, max };
  }

  onSelectedIndicador1Change() {
    this.setDecimalRange(this.form.controls['formTemperatureUnit'].value);
  }

  /**
   * Establece el rango de valores decimales para un indicador de temperatura, basado en una unidad de temperatura dada.
   * La función ajusta el rango de acuerdo con los valores mínimo y máximo permitidos, junto con el número de decimales requeridos.
   *
   * @param value - Unidad de temperatura que se usará para determinar el rango de decimales.
   */
  setDecimalRange(value: TemperatureUnit | undefined) {
    this.temperatureIndicator2 = [];
    let { min, max, decimalPlaces } = this.getTemperatureRange(value);
    this.isTemperatureDecimalActive = decimalPlaces !== 0;
    ({ min, max } = this.setRangeAccordingToAllowedAction(max, min));
    if (decimalPlaces !== 0) {
      const minDecimal = this.getDecimal(min, decimalPlaces, '0');
      const maxDecimal = this.getDecimal(max, decimalPlaces, '9');
      for (let i = minDecimal; i <= maxDecimal; i++) {
        const digits = i.toString().padStart(decimalPlaces, '0');
        this.temperatureIndicator2.push({ name: digits, value: digits });
      }
      this.clearFormDecimal(minDecimal, maxDecimal);
    }
  }

  /**
   * Obtiene el valor decimal de un número, ajustándolo al número de decimales especificado.
   * Si el número entero antes del punto decimal es igual a un valor de control,
   * se devuelve el valor decimal con el relleno especificado.
   * De lo contrario, se devuelve un valor alternativo, también ajustado al número de decimales.
   *
   * @param value - El valor numérico de entrada del que se extraerá la parte decimal.
   * @param decimalPlaces - El número de decimales al que se debe ajustar el resultado.
   * @param replaceValue - El valor a usar si la parte entera del número no coincide con el indicador esperado.
   * @returns El valor decimal ajustado al número de decimales, según la lógica descrita.
   */
  getDecimal(
    value: number,
    decimalPlaces: number,
    replaceValue: string
  ): number {
    const selectedIndicador1 = this.form.controls['selectedIndicador1'].value;
    const decimal = String(value).split('.')[1] ?? 0;
    if (String(value).split('.')[0] === selectedIndicador1) {
      return Number(String(decimal).padEnd(decimalPlaces, '0'));
    }

    return Number(String(replaceValue).padEnd(decimalPlaces, replaceValue));
  }

  clearFormDecimal(min: number, max: number) {
    const currentTemperatureDecimal =
      this.form.controls['selectedIndicador2'].value;
    if (min > currentTemperatureDecimal || currentTemperatureDecimal > max) {
      this.form.controls['selectedIndicador2'].setValue(undefined);
    }
  }

  getMinDate(date: any) {
    logger.debug('getMinDate', date);
    if (date) {
      this.minDate = new Date(date);
      //se le suma el timeZoneOffset para que la fecha sea correcta
      this.minDate.setMinutes(
        this.minDate.getMinutes() + this.minDate.getTimezoneOffset()
      );
      //Permite ingresar desde las 00:00 del día
      this.minDate.setHours(0, 0, 0, 0);
    }
  }
  getMaxDate(date: any) {
    if (date) {
      this.maxDate = new Date(date);
      //se le suma el timeZoneOffset para que la fecha sea correcta
      this.maxDate.setMinutes(
        this.maxDate.getMinutes() + this.maxDate.getTimezoneOffset()
      );
      //si el día de la maxdate es pasado ,se deja hasta las 23:59 de ese día, si no, se deja hasta la hora actual

      if (this.maxDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
        this.maxDate.setHours(23, 59, 59, 999);
      } else {
        this.maxDate = new Date();
      }
    }
  }

  /**
   * @desc asignar los valores al formulario
   * @desc  se evalua primero las variables de verificacion, si estan exiten se pasan al formulario caso contrario se envia la data original reportada
   */
  private setValuesVerified() {
    this.originalFormData = JSON.parse(JSON.stringify({ ...this.form.value }));
  }

  /**
   * Agregar los datos al formulario dependiendo de la configuracion
   */
  setForm() {
    logger.debug('setForm', this.confTemperature);
    if (this.temperatureRecord || this.verifiedTemperature?.temperature) {
      this.setFormTemperature();
    }
    if (this.confTemperature?.showTemperatureRoute) {
      this.setFormRoute();
    }

    if (this.confTemperature?.showTemperatureTaken) {
      this.setFormTaken();
    }
    if (this.confTemperature?.showTemperatureTakenDate) {
      this.setFormTakenDate();
    }

    this.form.patchValue({
      formTemperatureUnit: this.verifiedTemperature?.id
        ? this.verifiedTemperature?.unit
        : this.temperatureRecord?.temperatureUnit,

      formTemperatureUnitComment: this.verifiedTemperature?.unitComment,
    });

    this.setTemperatureUnit(
      this.form.controls['formTemperatureUnit'].value,
      this.form.controls['formTemperatureTaken'].value
    );
  }
  /**
   * @desc evalua los datos verificados y originales para enviarlos al formulario respecto a la temperature
   */
  private setFormTemperature() {
    let temperature: any;
    if (this.verifiedTemperature?.id) {
      temperature =
        this.verifiedTemperature?.temperature?.toString()?.split('.') ?? [];
    } else if (this.temperatureRecord?.temperature) {
      temperature = this.temperatureRecord?.temperature?.toString().split('.');
    } else {
      temperature = ['', '']; //si no se ha registrado nunca la temperatura se envía vacío
    }

    this.form.patchValue({
      selectedIndicador1: temperature[0] || null,
      selectedIndicador2: temperature[1] || '0',
      formTemperatureComment: this.verifiedTemperature?.temperatureComment,
    });
    logger.debug('setFormTemperature: ', this.form);
  }
  /**
   * @desc evalua los datos verificados y originales para enviarlos al formulario respecto a la ruta
   */
  private setFormRoute() {
    this.form.patchValue({
      formTemperatureRoute: this.verifiedTemperature?.id
        ? this.verifiedTemperature?.route
        : this.temperatureRecord?.temperatureRoute,

      formTemperatureRouteComment: this.verifiedTemperature?.routeComment,

      formTemperatureWhichOtherRoute: this.verifiedTemperature?.id
        ? this.verifiedTemperature?.whichOtherRoute
        : this.temperatureRecord?.temperatureWhichOtherRoute,

      formTemperatureWhichOtherRouteComment:
        this.verifiedTemperature?.whichOtherRouteComment,
    });
  }

  /**
   * @desc evalua los datos verificados y originales para enviarlos al formulario respecto a la fecha tomada la temperatura
   */
  private setFormTakenDate() {
    if (this.verifiedTemperature?.id) {
      const takenDate = this.verifiedTemperature?.takenDate;
      this.form.patchValue({
        formTemperatureTakenDate: takenDate ? new Date(takenDate) : null,
        formTemperatureTakenDateComment:
          this.verifiedTemperature?.takenDateComment,
      });
    } else {
      this.form.patchValue({
        formTemperatureTakenDate: this.temperatureRecord?.temperatureTakenDate
          ? new Date(this.temperatureRecord?.temperatureTakenDate)
          : '',
      });
    }
  }

  /**
   *@desc evalua los datos verificadosy original para enviarlos al formulario respecto si tomo o no la temperatura
   */
  private setFormTaken() {
    let temperatureTaken;

    temperatureTaken = this.verifiedTemperature?.id
      ? this.verifiedTemperature?.taken
      : this.temperatureRecord?.temperatureTaken;

    this.form.patchValue({
      formTemperatureTaken: temperatureTaken ?? false,
      formTemperatureTakenComment: this.verifiedTemperature?.takenComment,
    });
  }

  /**
   * @desc Metodo submit para actualizar o crear la instancia de la temperatura
   */
  async onSubmit() {
    logger.debug(
      'formulario',
      this.currentTemperatureDay?.temperatureRecord,
      this.form
    );
    this.conciliationService.clearSpaces(this.form);
    if (this.form.valid) {
      this.sendVerifiedTemperatureRecord();
    } else {
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
  }

  /**
   * @desc Crear instancia de temperatura Verificada
   */
  private async createVerifiedTemperatureRecordLog() {
    this.conciliationService.showSpinner(
      'transactionLoadingCreate',
      'temperaturerecordlog.entity'
    );
    const createVerifiedTemperatureRecord: CreateVerifiedTemperatureRecordLogInput =
      await this.getObjectCreateVerifiedTemperature();
    logger.debug(
      'createVerifiedTemperatureRecord',
      createVerifiedTemperatureRecord
    );
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    await this.temperatureService
      .createVerifiedTemperatureRecordLog(createVerifiedTemperatureRecord)
      .then(async (verifiedTemperature: any) => {
        this.currentTemperatureDay.verifiedTemperature = verifiedTemperature;
        this.verifiedTemperature = verifiedTemperature;
        this.temperatureService.verifiedTemperatures.push(verifiedTemperature);
        this.temperatureService.verifiedTempeturesBySubject.push(
          verifiedTemperature
        );
        this.updateValueSeccionTemperature(verifiedTemperature);
        this.messageSuccess(false);
      })
      .catch((error) => {
        this.messageError(error);
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }
  /**
   * @des actualiza la instancia de la temperatura con los datos conciliados
   */
  private async updatedTemperatureRecodLog() {
    this.conciliationService.showSpinner(
      'transactionLoadingUpdate',
      'temperaturerecordlog.entity'
    );
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    const updateVerified: UpdateVerifiedTemperatureRecordLogInput =
      this.getObjectUpdateVerifiedTemperature();
    await this.temperatureService
      .updateVerifiedTemperatureRecordLog(
        updateVerified,
        updateVerified.id,
        updateVerified._version
      )
      .then(async (data: any) => {
        this.currentTemperatureDay.temperatureRecord = this.temperatureRecord;
        this.currentTemperatureDay.verifiedTemperature = data;
        this.verifiedTemperature = data;
        this.updateValueSeccionTemperature(data);
        this.messageSuccess(true);
      })
      .catch((error: any) => {
        this.messageError(error);
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  messageSuccess(isUpdate: boolean) {
    let summary = 'general.messageSuccessOperation.summary';
    let detail = 'general.messageSuccessOperation.detail';
    if (isUpdate) {
      summary = 'conciliation.messageSuccessUpdated.summary';
      detail = 'conciliation.messageSuccessUpdated.detail';
    }

    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(summary),
      detail: this.trialpalService.translateService.instant(detail),
    });
  }

  messageError(error: any) {
    logger.error('formulario', error);
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'conciliation.messageError.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'conciliation.messageError.detail'
      ),
    });
  }

  changeScaleTemperatureUnit(event: any) {
    this.setIndicators(event.value);
    this.onChangeUnit();
  }

  /**
   * @des actualizar los datos de la tabla y evaluar las llaves de traduccion o enums
   */
  private updateValueSeccionTemperature(verifiedTemperature: any) {
    this.currentTemperatureDay.verifiedTemperature = verifiedTemperature;
    this.currentTemperatureDay.isEdition = true;
    this.updateTemperatures();
    this.temperatureService.addTemperatureComments();
    this.nextDay();
  }

  updateTemperatures() {
    const temperatureRoutes: any[] = [];
    const temperatures: any[] = [];
    const temperatureTakens: any[] = [];
    const temperatureTakenDates: any[] = [];
    this.temperatureService.colTemperatureDays =
      this.temperatureService.colTemperatureDays.map((colTemperature: any) => {
        const {
          temperatureRoute,
          temperatureTaken,
          temperatureTakenDate,
          temperature,
        } = this.temperatureService.getTemperatureValues(
          colTemperature?.temperatureRecord,
          colTemperature?.verifiedTemperature
        );
        temperatureRoutes.push(temperatureRoute);
        temperatures.push(temperature);
        temperatureTakens.push(temperatureTaken);
        temperatureTakenDates.push(temperatureTakenDate);
        colTemperature.temperatureRoute = temperatureRoute;
        colTemperature.temperatureTaken = temperatureTaken;
        colTemperature.temperatureTakenDate = temperatureTakenDate;
        colTemperature.temperature = temperature;
        return colTemperature;
      });

    //Posicion por FILAS para la asignacion del array correspondiente
    this.temperatureService.temperatures[0] = temperatureRoutes;
    this.temperatureService.temperatures[1] = temperatures;
    this.temperatureService.temperatures[2] = temperatureTakens;
    this.temperatureService.temperatures[3] = temperatureTakenDates;
  }

  /**
   *
   * @param name nombre de estrucra de datos segun establecido en el objeto creado por el servico
   * @param verifiedName nombre de verificacion de estrucra de datos segun establecido en el objeto creado por el servico    *
   * @param oldValue dato original
   * @param newValue nuevo valor o conciliado
   * @returns la enumeacion o actualizacion del dato segun si es enum, fecha o numerico
   */
  updatedEnumsOrValueTemperature(
    name: string,
    verifiedName: string,
    oldValue?: any,
    newValue?: any,
    comment?: string
  ) {
    let complementData;
    //Evaluar los tipos de datos actualizados para su actualizacion de llaves
    if (
      name === EntityTemperature.TEMPERATURE ||
      verifiedName === VerifiedEntityTemperature.TEMPERATURE
    ) {
      oldValue = this.getOldValueTemperature(oldValue);
      newValue = this.getNewValueTemperature(newValue);
      comment = this.formTemperatureComment?.value;
      complementData = this.getComplementData();
    }
    if (
      name === EntityTemperature.TEMPERATURE_ROUTE ||
      verifiedName === VerifiedEntityTemperature.ROUTE
    ) {
      oldValue = this.temperatureService.getValueEnumRoute(oldValue);
      newValue = this.temperatureService.getValueEnumRoute(newValue);
      comment = this.formTemperatureRouteComment?.value;
    }

    if (
      name === EntityTemperature.TEMPERATURE_TAKEN ||
      verifiedName === VerifiedEntityTemperature.TAKEN
    ) {
      oldValue = this.conciliationService.getYN(oldValue);
      newValue = this.conciliationService.getYN(newValue);
      comment = this.formTemperatureTakenComment?.value;
    }

    if (
      name === EntityTemperature.TEMPERATURE_TAKEN_DATE ||
      verifiedName === VerifiedEntityTemperature.TAKEN_DATE
    ) {
      oldValue = oldValue ? this.getDateTimeZone(oldValue) : GeneralModel.NA;
      newValue =
        newValue && (this.formTemperatureTaken?.value || true)
          ? this.getDateTimeZone(newValue)
          : GeneralModel.NA;
      comment = this.formTemperatureTakenDateComment?.value;
    }

    return { oldValue, newValue, comment, complementData };
  }

  getComplementData() {
    return {
      newValue: this.formTemperatureUnit?.value
        ? this.temperatureService.getUnitTemperature(
            this.formTemperatureUnit?.value
          )
        : '',
      record: this.temperatureRecord?.temperatureUnit
        ? this.temperatureService.getUnitTemperature(
            this.temperatureRecord?.temperatureUnit
          )
        : GeneralModel.NA,
      indexComment: null,
      comment: this.formTemperatureUnitComment?.value,
      isEdition: true,
    };
  }

  getOldValueTemperature(oldValue: any) {
    return oldValue ?? GeneralModel.NA;
  }

  getNewValueTemperature(newValue: any) {
    return newValue ? this.getCompleteTemperateFloat() : GeneralModel.NA;
  }

  getTakenDate(value: any) {
    return value ? this.getDateTimeZone(value) : GeneralModel.NA;
  }

  /**
   * @desc evaluar los dos indicadores de temperatura y retornar el dato completo
   * @returns valor decimal de la temperatura
   */
  private getCompleteTemperateFloat() {
    return parseFloat(
      this.selectedIndicador1?.value + '.' + this.selectedIndicador2?.value
    );
  }

  /**
   * @desc Valida si debe mostrar el modal de cambios, o crear la instancia conciliada
   */
  async sendVerifiedTemperatureRecord() {
    const { original, verified } = this.getOriginalAndVerifiedObject();
    const isChangeform = JSON.stringify(original) !== JSON.stringify(verified);

    if (isChangeform) {
      this.showSummaryComponent(original, verified);
    } else {
      if (!this.isCommentChange() && this.currentTemperatureDay?.isEdition) {
        return this.nextDay();
      }
      await this.createOrUpdateTemperature();
    }
  }

  //Funcion que se encarga de reenderizar el modal con los cambios que el usuario ha realizado
  showSummaryComponent(original: any, verified: any) {
    //Retorna arreglos con los cambios realizado por el usuario
    const { oldValue, newVerified } = this.getNewAndVerifiedSummaryObject(
      original,
      verified
    );
    logger.debug('showSummary', oldValue, newVerified);
    const ref = this.dialogService.open(SummaryTemperatureComponent, {
      data: {
        configuration: this.confTemperature,
        oldValue,
        newVerified,
      },
      width: '50%',
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmSaveForm'
      ),
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.conciliationService.recordChangeEvent(
          newVerified,
          oldValue,
          'temperature'
        );
        this.createOrUpdateTemperature();
      }
    });
  }

  //Funcion que se encarga de retornar en arreglos los cambios del sujeto
  getNewAndVerifiedSummaryObject(original: any, verified: any) {
    let oldValue: any[] = [];
    let newVerified: any[] = [];
    // recorrer el objeto original y validar cuales cambiaron para pasarlos al modal de verificacion
    for (const [key, value] of Object.entries(original)) {
      logger.debug('showSummary values', key, value, verified[key]);
      if (verified[key] !== value) {
        oldValue.push({
          [key]: value,
        });
        newVerified.push({
          [key]: verified[key],
        });
      }
    }
    return { oldValue, newVerified };
  }

  //Retorna los objetos del formulario actual y sus cambios
  getOriginalAndVerifiedObject() {
    // Objeto con los valores reportados por el sujeto
    let original: any = this.getRecordTemperature(this.temperatureRecord);
    if (this.currentTemperatureDay?.verifiedTemperature?.id) {
      original = this.getVerifiedTemperatureRecord(
        this.currentTemperatureDay?.verifiedTemperature
      );
    }

    // Crear el objeto con los nuevos valores
    const verified: any = this.getVerifiedTemperature();
    return { original, verified };
  }

  /**
   * @desc crear o editar la temperatura
   */
  async createOrUpdateTemperature() {
    logger.debug('createOr', this.currentTemperatureDay);
    if (
      this.currentTemperatureDay?.isEdition ||
      this.currentTemperatureDay?.verifiedTemperature?.id
    ) {
      await this.updatedTemperatureRecodLog();
    } else {
      await this.createVerifiedTemperatureRecordLog();
    }
  }

  isCommentChange() {
    const original = this.currentTemperatureDay?.verifiedTemperature?.id
      ? this.currentTemperatureDay?.verifiedTemperature
      : this.temperatureRecord;
    const verified = this.getObjectComment();
    return this.conciliationService.isFormCommentChange(original, verified);
  }

  /**
   * @returns objeto con los datos originales del sujeto
   */
  getRecordTemperature(temperatureRecord: any) {
    const temperature = this.temperatureService.getTemperatureByUnit(
      temperatureRecord?.temperature,
      temperatureRecord?.temperatureUnit
    );
    return {
      temperature: temperature ?? null,
      temperatureUnit: temperatureRecord?.temperatureUnit ?? null,
      temperatureRoute: temperatureRecord?.temperatureRoute ?? null,
      temperatureWhichOtherRoute:
        temperatureRecord?.temperatureWhichOtherRoute || false,
      temperatureTaken: temperatureRecord?.temperatureTaken ?? false,
      temperatureTakenDate: temperatureRecord?.temperatureTakenDate
        ? this.getDateTimeZone(temperatureRecord?.temperatureTakenDate)
        : GeneralModel.NA,
    };
  }

  getObjectComment() {
    const values = this.form.value;
    return {
      temperatureComment: values?.formTemperatureComment,
      routeComment: values?.formTemperatureRouteComment,
      takenComment: values?.formTemperatureTakenComment,
      takenDateComment: values?.formTemperatureTakenDateComment,
      unitComment: values?.formTemperatureUnitComment,
      whichOtherRouteComment: values?.formTemperatureWhichOtherRouteComment,
    };
  }

  getVerifiedTemperatureRecord(temperatureRecord: any) {
    const temperature = this.temperatureService.getTemperatureByUnit(
      temperatureRecord?.temperature,
      temperatureRecord?.unit
    );
    return {
      temperature: temperature ?? null,
      temperatureUnit: temperatureRecord?.unit ?? null,
      temperatureRoute: temperatureRecord?.route ?? null,
      temperatureWhichOtherRoute: temperatureRecord?.whichOtherRoute || false,
      temperatureTaken: temperatureRecord?.taken ?? false,
      temperatureTakenDate: temperatureRecord?.takenDate
        ? this.getDateTimeZone(temperatureRecord?.takenDate)
        : GeneralModel.NA,
    };
  }

  /**
   * @returns objeto con los datos verificados del formulario
   */
  getVerifiedTemperature() {
    const temperature = this.temperatureService.getTemperatureByUnit(
      this.getCompleteTemperateFloat(),
      this.formTemperatureUnit?.value
    );
    return {
      temperature: temperature ?? null,
      temperatureUnit: this.formTemperatureUnit?.value || null,
      temperatureRoute: this.formTemperatureRoute?.value
        ? this.formTemperatureRoute?.value
        : null,
      temperatureWhichOtherRoute:
        this.formTemperatureWhichOtherRoute?.value || false,
      temperatureTaken: this.confTemperature?.showTemperatureTaken
        ? this.formTemperatureTaken?.value
        : true,
      temperatureTakenDate: this.formTemperatureTakenDate?.value
        ? this.getDateTimeZone(this.formTemperatureTakenDate?.value)
        : GeneralModel.NA,
    };
  }

  /**
   *
   * @returns objecto con los datos de actualizacion de la instacia
   */
  async getObjectCreateVerifiedTemperature() {
    return {
      verifiedDayInstanceId:
        this.currentTemperatureDay?.verifiedDayInstance?.id,
      subjectId: this.subjectId,
      temperatureRecordLogID: this.currentTemperatureDay?.isPendingInstance
        ? undefined
        : this.currentTemperatureDay?.temperatureRecord?.id,
      temperature: this.getCompleteTemperateFloat(),
      temperatureComment: this.formTemperatureComment?.value,
      unit: this.formTemperatureUnit?.value,
      unitComment: this.formTemperatureUnitComment?.value,
      route: this.formTemperatureRoute?.value,
      routeComment: this.formTemperatureRouteComment?.value,
      whichOtherRoute: this.formTemperatureWhichOtherRoute?.value,
      whichOtherRouteComment: this.formTemperatureWhichOtherRouteComment?.value,
      taken: this.confTemperature?.showTemperatureTaken
        ? this.formTemperatureTaken?.value
        : true,
      takenComment: this.formTemperatureTakenComment?.value,
      takenDate: this.formTemperatureTakenDate?.value
        ? this.formTemperatureTakenDate?.value
        : null,
      takenDateComment: this.formTemperatureTakenDateComment?.value,
      _lastUser: this.authService.getUsername(),
    };
  }

  /**
   *
   * @returns objeto de actuallizacion de datos verificados
   */
  private getObjectUpdateVerifiedTemperature() {
    const reason = this.form.controls['changeReason'].value;
    return {
      verifiedDayInstanceId: this.currentTemperatureDay?.verifiedDayInstance.id,
      subjectId: this.subjectId,
      temperatureRecordLogID: this.currentTemperatureDay?.isPendingInstance
        ? undefined
        : this.currentTemperatureDay.verifiedTemperature.temperatureRecordLogID,
      id: this.currentTemperatureDay.verifiedTemperature?.id,
      temperature: this.getCompleteTemperateFloat(),
      temperatureComment: this.formTemperatureComment?.value,
      unit: this.formTemperatureUnit?.value,
      unitComment: this.formTemperatureUnitComment?.value,
      route: this.formTemperatureRoute?.value,
      routeComment: this.formTemperatureRouteComment?.value,
      whichOtherRoute: this.formTemperatureWhichOtherRoute?.value,
      whichOtherRouteComment: this.formTemperatureWhichOtherRouteComment?.value,
      taken: this.confTemperature?.showTemperatureTaken
        ? this.formTemperatureTaken?.value
        : true,
      takenComment: this.formTemperatureTakenComment?.value,
      takenDate: this.formTemperatureTakenDate?.value
        ? this.formTemperatureTakenDate?.value
        : null,
      takenDateComment: this.formTemperatureTakenDateComment?.value,
      _version: this.currentTemperatureDay?.verifiedTemperature?._version,
      _changeReason: reason,
    };
  }

  /**
   * @desc Mostrar u ocultar el campo de comentario de la ruta
   * @desc Evalua la si es requerido el campo del comenterio respectivo
   */
  onChangeRoute(_event: any) {
    if (this.confTemperature?.showTemperatureRoute) {
      const originalRoute = this.verifiedTemperature?.id
        ? this.verifiedTemperature.route
        : this.temperatureRecord?.temperatureRoute;

      this.requiredRouteComment =
        this.formTemperatureRoute?.value !== originalRoute;

      //valida si tiene un comentario relacionado
      if (this.verifiedTemperature?.routeComment) {
        this.requiredRouteComment = true;
      }

      // si el sintoma es otro elimina el requerido de los campos de commentario y cual de otro sintoma
      if (this.formTemperatureRoute?.value !== Symptom.OTHER) {
        //Convierte en no requerido
        this.conciliationService.setValidator(
          this.formTemperatureWhichOtherRoute,
          false
        );
        //Convierte en no requerido
        this.conciliationService.setValidator(
          this.formTemperatureWhichOtherRouteComment,
          false
        );
        this.conciliationService.setValidator(
          this.formTemperatureRouteComment,
          this.requiredRouteComment
        );
      }
    }
    //Si el valor no cambio se reseta el valor
    if (!this.requiredRouteComment) {
      this.formTemperatureRouteComment?.reset();
    }
    logger.debug('onChangeRoute', this.requiredUnitComment);
  }

  /**
   * @desc Mostrar u ocultar el campo de comentario de la unidad de temperatura
   * @desc Evalua la si es requerido el campo del comenterio respectivo
   */
  onChangeUnit() {
    const originalUnit = this.verifiedTemperature?.id
      ? this.verifiedTemperature.unit
      : this.temperatureRecord?.temperatureUnit;

    this.requiredUnitComment = this.formTemperatureUnit?.value !== originalUnit;
    //valida si es una edicion y tiene un comentario relacionado
    if (this.verifiedTemperature?.unitComment) {
      this.requiredUnitComment = true;
    }

    this.conciliationService.setValidator(
      this.formTemperatureUnitComment,
      this.requiredUnitComment
    );

    if (this.requiredUnitComment) {
      this.selectedIndicador1?.reset();
      this.conciliationService.setValidator(this.selectedIndicador1, true);
      this.selectedIndicador2?.reset();
      this.conciliationService.setValidator(
        this.selectedIndicador2,
        this.isTemperatureDecimalActive
      );
    }
    //Si el Valor no cambio se reseta el valor
    if (!this.requiredUnitComment) {
      this.formTemperatureUnitComment?.reset();
    }
    logger.debug('onChangeUnit', this.requiredUnitComment);
  }

  /**
   * @desc Mostrar u ocultar el campo de comentario de  temperatura
   * @desc Evalua la si es requerido el campo del comenterio respectivo
   */
  onChangeTemperature(_event: any) {
    let fullTemperature;
    const temperature = this.verifiedTemperature?.id
      ? this.verifiedTemperature?.temperature?.toString()?.split('.')
      : this.temperatureRecord?.temperature?.toString().split('.');

    //tomar la temperartura completa
    if (temperature) {
      const ex1 = temperature[0] || undefined;
      const ex2 = temperature[1] || '0';
      fullTemperature = parseFloat(ex1 + '.' + ex2);
    }

    //valida si tiene un comentario relacionado
    if (this.verifiedTemperature?.temperatureComment) {
      this.requiredTemperatureComment = true;
    } else {
      this.requiredTemperatureComment =
        this.getCompleteTemperateFloat() !== fullTemperature;
    }

    //Si el valor no cambio se reseta el valor
    if (!this.requiredTemperatureComment) {
      this.formTemperatureComment?.reset();
    }

    this.conciliationService.setValidator(
      this.formTemperatureComment,
      this.requiredTemperatureComment
    );
    logger.debug('onChangeTemperature', this.formTemperatureComment);
  }

  /**
   * @desc Mostrar u ocultar el campo de comentario de  si fue  o no tomada
   * @desc Evalua la si es requerido el campo del comentario respectivo
   */
  onChangeTemperatureTaken(event: any) {
    this.temperatureTaken = event.checked;
    if (this.confTemperature?.showTemperatureTaken) {
      const originalTemperatureTaken = this.verifiedTemperature?.id
        ? this.verifiedTemperature.taken
        : this.temperatureRecord?.temperatureTaken;

      this.requiredTakenComment =
        this.formTemperatureTaken?.value !== originalTemperatureTaken;

      //valida si tiene un comentario relacionado
      if (this.verifiedTemperature?.takenComment) {
        this.requiredTakenComment = true;
      } else {
        this.requiredTakenComment =
          this.formTemperatureTaken?.value !==
          this.temperatureRecord?.temperatureTaken;
      }

      const temperatureTaken = this.formTemperatureTaken?.value;
      const commentTemperatureTaken = this.formTemperatureTakenComment?.value;
      if (
        !this.requiredTakenComment ||
        !this.formTemperatureTaken?.value ||
        !this.temperatureTaken
      ) {
        logger.debug('onChangeTemperatureTakenDate1');

        this.form.reset();
        this.form.patchValue({
          formTemperatureTaken: temperatureTaken ?? false,
          formTemperatureTakenComment: commentTemperatureTaken,
        });
        this.formTemperatureTakenDate?.setValue(null);
        this.formTemperatureRouteComment?.clearValidators();
        this.formTemperatureRouteComment?.updateValueAndValidity();
        this.formTemperatureComment?.clearValidators();
        this.formTemperatureComment?.updateValueAndValidity();
        this.formTemperatureUnitComment?.clearValidators();
        this.formTemperatureUnitComment?.updateValueAndValidity();
        this.formTemperatureTakenDateComment?.clearValidators();
        this.formTemperatureTakenDateComment?.updateValueAndValidity();
        this.formTemperatureRoute?.clearValidators();
        this.formTemperatureRoute?.updateValueAndValidity();
        this.formTemperatureTakenDate?.clearValidators();
        this.formTemperatureTakenDate?.updateValueAndValidity();
        this.formTemperatureUnit?.clearValidators();
        this.formTemperatureUnit?.updateValueAndValidity();
        this.formTemperatureWhichOtherRoute?.clearValidators();
        this.formTemperatureWhichOtherRoute?.updateValueAndValidity();
        this.formTemperatureSelectedIndicador1?.clearValidators();
        this.formTemperatureSelectedIndicador1?.updateValueAndValidity();
        this.formTemperatureSelectedIndicador2?.clearValidators();
        this.formTemperatureSelectedIndicador2?.updateValueAndValidity();
      } else {
        this.form.reset();
        this.form.patchValue({
          formTemperatureTaken: temperatureTaken ?? false,
          formTemperatureTakenComment: commentTemperatureTaken,
        });
      }
    }
    if (this.formTemperatureTaken?.value && !this.showTemperatureUnit) {
      this.setTemperatureUnit(this.units[0].value, true);
    }
    this.conciliationService.setValidator(
      this.formTemperatureTakenComment,
      this.requiredTakenComment
    );

    logger.debug('onChangeTemperatureTaken', this.form);
    logger.debug('onChangeTemperatureTakenDate', this.requiredTakenComment);
  }

  /**
   * @desc Mostrar u ocultar el campo de comentario
   * @desc Evalua la si es requerido el campo del comenterio respectivo
   */
  onChangeTakenDate(_event: any) {
    logger.debug('onChangeTakenDate', _event);
    logger.debug(this.formTemperatureTakenDate, this.temperatureRecord);
    //valida si tiene un comentario relacionado
    if (this.verifiedTemperature?.takenDateComment) {
      this.requiredTakenDateComment = true;
    } else {
      const originalTemperatureTakenDate = this.verifiedTemperature?.id
        ? this.verifiedTemperature?.takenDate
        : this.temperatureRecord?.temperatureTakenDate;

      this.requiredTakenDateComment =
        this.getDateTimeZone(this.formTemperatureTakenDate?.value) !==
        this.getDateTimeZone(originalTemperatureTakenDate);
    }

    if (
      !this.requiredTakenDateComment ||
      !this.confTemperature?.showTemperatureTakenDate
    ) {
      this.formTemperatureTakenDateComment?.clearValidators();
      this.formTemperatureTakenDateComment?.updateValueAndValidity();
      this.formTemperatureTakenDateComment?.reset();
      this.formTemperatureTakenDate?.clearValidators();
      this.formTemperatureTakenDate?.updateValueAndValidity();
    }
    this.conciliationService.setValidator(
      this.formTemperatureTakenDateComment,
      this.requiredTakenDateComment
    );
  }

  nextDay() {
    if (
      this.currentTemperatureDay.index + 1 ===
      this.temperatureService.colTemperatureDays.length
    ) {
      return this.paginationService.nextSection(
        ConciliationComponents.TEMPERATURE
      );
    } else {
      const record =
        this.temperatureService.colTemperatureDays[
          this.currentTemperatureDay.index + 1
        ];
      return this.paginationService.selectedTemperature(record);
    }
  }
  previousDay() {
    if (this.currentTemperatureDay.index !== 0) {
      const record =
        this.temperatureService.colTemperatureDays[
          this.currentTemperatureDay.index - 1
        ];
      this.paginationService.selectedTemperature(record);
    }
  }
  requiredValues() {
    this.requiredRouteComment = false;
    this.requiredUnitComment = false;
    this.requiredTemperatureComment = false;
    this.requiredTakenComment = false;
    this.requiredTakenDateComment = false;
    this.requiredOtherComment = false;
    if (this.currentTemperatureDay?.isEdition) {
      this.originalFormData = JSON.parse(
        JSON.stringify({ ...this.form.value })
      );
      this.getRequiredIsEdition();
      this.exportAuditRecord.emit({
        entity: TP2Entites.VERIFIEDTEMPERATURERECORDLOG,
        id: this.currentTemperatureDay.verifiedTemperature.id,
        description: this.temperatureService.currentTemperatureDay?.header,
      });
    }
  }
  getRequiredIsEdition() {
    this.requiredRouteComment = !!this.verifiedTemperature?.routeComment;
    this.requiredUnitComment = !!this.verifiedTemperature?.unitComment;
    this.requiredTemperatureComment =
      !!this.verifiedTemperature?.temperatureComment;
    this.requiredTakenComment = !!this.verifiedTemperature?.takenComment;
    this.requiredTakenDateComment =
      !!this.verifiedTemperature?.takenDateComment;
    this.requiredOtherComment =
      !!this.verifiedTemperature?.whichOtherRouteComment;
  }
  /**
   * @desc resetar el formulario
   */
  private resetForm() {
    this.requiredRouteComment = false;
    this.requiredUnitComment = false;
    this.requiredTemperatureComment = false;
    this.requiredTakenComment = false;
    this.requiredTakenDateComment = false;
    this.requiredOtherComment = false;
    this.temperatureRecord = undefined;
    this.verifiedTemperature = undefined;
    this.temperatureTaken = false;
    this.form.reset();
    this.form.clearValidators();
    Object.keys(this.form.controls).forEach((key: any) => {
      this.form.controls[key].setErrors(null);
      this.conciliationService.setValidator(this.form.controls[key], false);
    });
  }

  /**Validaciones para temperatura esperada y grave */

  onTemperatureValueChange() {
    this.validateTemperature();
  }

  getTemperatureValue(): number | undefined {
    const decimalPlaces = this.getDecimalPlacesForUnit(undefined);
    const isDecimalActive = decimalPlaces !== 0;

    const temperatureInt = this.form.controls['selectedIndicador1'].value;
    const decimalTemperature = this.form.controls['selectedIndicador2'].value;

    if (isDecimalActive && temperatureInt && decimalTemperature) {
      return parseFloat(`${temperatureInt}.${decimalTemperature}`);
    } else if (!isDecimalActive && temperatureInt) {
      return parseFloat(`${temperatureInt}`); // Treat as integer
    }
    return undefined;
  }

  /**
   * Valida la temperatura recibida y muestra un modal de ayuda si está fuera de los rangos esperados.
   */
  async validateTemperature() {
    const isTemperatureDecimalActive = this.isTemperatureDecimalActive;
    const decimalValue = this.selectedIndicador2?.value;
    if (
      !isTemperatureDecimalActive ||
      (isTemperatureDecimalActive && decimalValue)
    ) {
      await this.validateAlertTextForUnexpectedValues();
      await this.validateAlertTextForSeriousValues();
    }
  }

  /**
   * Muestra un mensaje de alerta cuando se detectan valores inesperados de temperatura.
   */
  async validateAlertTextForUnexpectedValues(): Promise<void> {
    const temperature = this.getTemperatureValue() ?? 0;
    const isExpectedRangeActive = this.confTemperature?.isExpectedRangeActive;
    // Verificar si la temperatura está fuera del rango esperado
    const isTemperatureOutsideExpectedRange =
      this.isTemperatureOutsideExpectedRange(temperature);
    if (isExpectedRangeActive && isTemperatureOutsideExpectedRange) {
      const helpText = this.confTemperature?.alertTextForUnexpectedValues ?? '';
      await this.showWarning(helpText);
    }
  }

  /**
   * Muestra una alerta si la temperatura está en un rango peligroso.
   */
  async validateAlertTextForSeriousValues(): Promise<void> {
    const temperature = this.getTemperatureValue() ?? 0;
    const isTemperatureInSeriousRange =
      this.isTemperatureInSeriousRange(temperature);
    if (isTemperatureInSeriousRange) {
      const helpText = this.confTemperature?.alertTextForSeriousValues ?? '';
      await this.showWarning(helpText);
    }
  }

  /**
   * Determina si una temperatura está en un rango peligroso.
   * @param {number} temperature La temperatura a evaluar.
   * @return {boolean}
   * `true` si la temperatura está en un rango peligroso y el rango serio está activo.
   * `false` si la temperatura está en un rango seguro o si el rango serio no está activo.
   */
  isTemperatureInSeriousRange(temperature: number): boolean {
    const isSeriousRangeActive =
      this.confTemperature?.isSeriousRangeActive ?? false;
    const isTemperatureBeyondSafeRange =
      this.isTemperatureBeyondSafeRange(temperature);
    return isTemperatureBeyondSafeRange && isSeriousRangeActive;
  }

  isTemperatureOutsideExpectedRange(temperature: number) {
    const { min, max } = this.getMinAndMaxExpectedRange();
    return min > temperature || temperature > max;
  }

  isTemperatureBeyondSafeRange(temperature: number): boolean {
    const { min, max } = this.getMinAndMaxSeriousRange();
    return min <= temperature && temperature <= max;
  }

  getMinAndMaxExpectedRange(): { min: number; max: number } {
    const isCELSIUS =
      this.formTemperatureUnit?.value === TemperatureUnit.CELSIUS;
    const min = isCELSIUS
      ? this.confTemperature?.celciusRanges?.expectedMinValue
      : this.confTemperature?.fahrenheitRanges?.expectedMinValue;
    const max = isCELSIUS
      ? this.confTemperature?.celciusRanges?.expectedMaxValue
      : this.confTemperature?.fahrenheitRanges?.expectedMaxValue;
    return { min: min ?? 0, max: max ?? 0 };
  }

  getMinAndMaxSeriousRange(): { min: number; max: number } {
    const isCELSIUS =
      this.formTemperatureUnit?.value === TemperatureUnit.CELSIUS;
    const min = isCELSIUS
      ? this.confTemperature?.celciusRanges?.seriousMinValue
      : this.confTemperature?.fahrenheitRanges?.seriousMinValue;
    const max = isCELSIUS
      ? this.confTemperature?.celciusRanges?.seriousMaxValue
      : this.confTemperature?.fahrenheitRanges?.seriousMaxValue;
    return { min: min ?? 0, max: max ?? 0 };
  }

  async showWarning(helpText: string): Promise<any> {
    const unit =
      this.formTemperatureUnit?.value === TemperatureUnit.CELSIUS ? 'C°' : 'F°';
    const temperature = this.getTemperatureValue();
    const modal = this.trialpalService.dialogService.open(TpHelpComponent, {
      data: {
        html: helpText,
        key: '$TEMPERATURE',
        value: `${temperature} ${unit}`,
      },
      style: { 'min-width:': '360px', 'max-width': '70%' },
      dismissableMask: false,
      closeOnEscape: false,
      showHeader: false,
      baseZIndex: 10000,
    });
    await firstValueFrom(modal.onClose);
  }

  allowAddOrRemoveRecord() {
    let shouldDisable = false;
    const allowAddRecord =
      this.temperatureReconciliationConfig?.allowAddRecords;
    const allowRemoveRecord =
      this.temperatureReconciliationConfig?.allowRemoveRecords;
    const temperatureRecord = this.currentTemperatureDay?.temperatureRecord;
    if (!allowAddRecord && !temperatureRecord?.temperatureTaken) {
      if (!temperatureRecord?.id)
        this.form.patchValue({
          formTemperatureTaken: false,
        });
      shouldDisable = true;
    }
    if (!allowRemoveRecord && temperatureRecord?.temperatureTaken)
      shouldDisable = true;

    return shouldDisable;
  }

  /**
   * @param dateValue valor de la fecha
   * @returns Fecha con la zona horaria del sujeto
   */
  private getDateTimeZone(dateValue?: string | null) {
    logger.debug('getDateTimeZone', dateValue);
    logger.debug('timezone', this.currentTemperatureDay.timeZoneOffset);
    return dateValue
      ? this.transformDatePipe.transformToSiteHour(
          dateValue,
          this.currentTemperatureDay?.timeZoneOffset
        )
      : undefined;
  }

  get shouldShowTemperatureRoute() {
    if (
      !this.temperatureRecord?.temperatureRoute &&
      !this.verifiedTemperature?.route
    )
      return this.temperatureReconciliationConfig?.allowRouteReconciliation;
    return true;
  }
  //================= Get del formulario ============= //

  get formTemperatureTakenDate() {
    return this.form.get('formTemperatureTakenDate');
  }
  get formTemperatureTaken() {
    return this.form.get('formTemperatureTaken');
  }
  get selectedIndicador1() {
    return this.form.get('selectedIndicador1');
  }
  get selectedIndicador2() {
    return this.form.get('selectedIndicador2');
  }
  get formTemperatureUnit() {
    return this.form.get('formTemperatureUnit');
  }
  get formTemperatureRoute() {
    return this.form.get('formTemperatureRoute');
  }
  get formTemperatureComment() {
    return this.form.get('formTemperatureComment');
  }
  get formTemperatureUnitComment() {
    return this.form.get('formTemperatureUnitComment');
  }
  get formTemperatureRouteComment() {
    return this.form.get('formTemperatureRouteComment');
  }
  get formTemperatureWhichOtherRoute() {
    return this.form.get('formTemperatureWhichOtherRoute');
  }
  get formTemperatureWhichOtherRouteComment() {
    return this.form.get('formTemperatureWhichOtherRouteComment');
  }
  get formTemperatureTakenComment() {
    return this.form.get('formTemperatureTakenComment');
  }
  get formTemperatureTakenDateComment() {
    return this.form.get('formTemperatureTakenDateComment');
  }
  get formTemperatureSelectedIndicador1() {
    return this.form.get('selectedIndicador1');
  }
  get formTemperatureSelectedIndicador2() {
    return this.form.get('selectedIndicador2');
  }

  get isTemperatureChange() {
    const { original, verified } = this.getOriginalAndVerifiedObject();
    const isChangeform = JSON.stringify(original) !== JSON.stringify(verified);
    const isCommentChange = this.isCommentChange();
    return isChangeform || isCommentChange;
  }
}
