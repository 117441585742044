<div class="container-tp2">
  <p-accordion>
    <ng-container *ngFor="let site of sites">
      <p-accordionTab
        header="{{ 'chat.usersFrom' | translate }} {{ site.name }}"
        [selected]="true"
        *ngIf="site?.users?.length > 0"
      >
        <div *ngIf="site.users" class="p-grid p-fluid">
          <div class="p-col-12 p-d-flex p-flex-column">
            <p-listbox
              [filter]="true"
              [options]="site.users"
              [(ngModel)]="site.selectedUser"
              optionDisabled="false"
              [listStyle]="{ 'max-height': '520px' }"
            >
              <ng-template let-user pTemplate="item">
                <div
                  id="{{ user.value.id }}"
                  class="user-item"
                  style="width: 100%; border-bottom: 1px solid #a0a0a0"
                >
                  <div class="p-grid">
                    <div class="p-col-4">{{ user.value.login }}</div>
                    <div class="p-col-4">{{ user.value.role }}</div>
                    <div class="p-col-4">
                      <p-inputSwitch
                        id="user.value.id"
                        name="user.value.id"
                        class="inputSwitch"
                        [(ngModel)]="user.value.isChatActivated"
                        (onChange)="changeUserState(site, user)"
                      ></p-inputSwitch>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="footer">
                <div style="text-align: center">
                  <small
                    >{{ site.users?.length }}
                    {{ 'user.entityPlural' | translate }}</small
                  >
                </div>
              </ng-template>
            </p-listbox>
          </div>
        </div>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
</div>
