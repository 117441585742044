import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { ReportService } from 'src/app/modules/report/report.service';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { AlertSubjectComponent } from '../../../modules/subjects/detail-subject/alert-subject/alert-subject.component';
import { TRIALPAL_QUERIES } from 'src/app/services/trialpal.queries';
import {
  RevisionStateAlertInstance,
  UpdateAlertInstanceInput,
} from 'src/app/services/trialpal.types';
import { TP2Entites } from '../../global.variables';
const logger = new Logger('tp2-logger-AlertsByUser');
type EntityName = 'DAY_INSTANCE' | 'REPORT_INSTANCE';

@Component({
  selector: 'app-alerts-email',
  templateUrl: './alerts-email.component.html',
  styleUrls: ['./alerts-email.component.scss'],
})
export class AlertsEmailComponent implements OnInit {
  @ViewChild(AlertSubjectComponent) alertSubject!: AlertSubjectComponent;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  alerts: unknown[] = [];
  allowedAlerts: unknown[] = [];

  showSpinner: boolean = true;
  selectedRange: string = '';
  ranges: unknown[] = [];
  display: boolean = false;
  displayDialog: boolean = false;
  selectedAlert: any = {
    note: null,
  };
  translateGeneral: any;
  pendingAlerts: number = 0;
  symptoms: any[] = [];
  subjects: any[] = [];
  stateOptions: object[] = [];
  revisionStateValue: any = '';
  constructor(
    private translateService: TranslateService,
    private trialpalService: TrialpalService,
    public authService: AuthService,
    private transformDatePipe: TransformDatePipe,
    private ediaryService: EdiaryService,
    private reportService: ReportService
  ) {
    this.getAlertFiltersAndOptions();
  }

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('general.alerts.entity', 'LIST');
    const email = this.authService.getEmail();
    this.trialpalService.translateService
      .get('general')
      .subscribe((res) => (this.translateGeneral = res));
    this.alerts = await this.getAlertsByEmail(email);
    if (
      this.selectedRange === 'LAST_SIX_MONTHS' ||
      this.selectedRange === 'LAST_MONTH'
    ) {
      this.filter();
    } else {
      this.allowedAlerts = this.reportService.pendingWeekAlerts(this.alerts);
    }
    this.symptoms = await this.ediaryService.ListSymptomInstances();
    logger.debug('number of alerts:', this.alerts.length);
    this.reportService.alertsToReview.subscribe((pending) => {
      this.pendingAlerts = pending;
    });
    this.buildCardsAndTable(this.allowedAlerts);
  }

  async getAlertsByEmail(email: string) {
    if (!email) return [];
    const confAlerts = await this.reportService.getConfAlertsByEmail(email);
    logger.debug('ConfAlertsByEmail', email, confAlerts);
    return this.reportService.listAlertInstances(confAlerts);
  }

  buildCardsAndTable(alerts: any) {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };

    this.dataTable = this.buildTPTableData(alerts);
    this.showSpinner = false;
    this.trialpalService.hideSpinner();
  }

  buildTPTableData(alertsInstances: any): TPTableData {
    let alertInstances: any = alertsInstances.map((alert: any) => {
      return {
        ...alert,
        id: alert.id,
        name: this.trialpalService.dictionaryPipe.transform(
          alert.confAlert.alertName
        ),
        enity: TP2Entites.ALERTINSTANCE,
        symptoms: this.getSymptomsAlert(alert.confAlert_data),
        dateTime: this.transformDatePipe.transformWithHour(alert.createdAt),
        origin: this.evaluateOrigin(alert.entityName),
        status: this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.enums.' + alert.state
        ),
        revisionStatus:
          alert.revisionState !== null
            ? this.trialpalService.translateService.instant(
                'ediary.components.alertAddEdit.enums.' + alert.revisionState
              )
            : this.trialpalService.translateService.instant(
                'ediary.components.alertAddEdit.enums.PENDING'
              ),
        greenColor: !alert.revisionState || alert.revisionState === 'PENDING',
        _version: alert._version,
        rowIcon:
          !alert.revisionState || alert.revisionState === 'PENDING'
            ? 'pi pi-user-edit'
            : 'pi pi-eye',
        subjectNumber: alert.subject.subjectNumber,
      };
    });
    return this.getContentTable(alertInstances);
  }

  getContentTable(alertInstances: any) {
    return {
      valueTable: alertInstances,
      showGeneralSearch: true,
      showResetFilter: false,
      showMenuActionButton: false,
      globalFilterFields: ['name', 'symptoms', 'dateTime', 'origin', 'state'],
      customButton1: {
        icon: 'pi pi-user-edit',
      },
      cols: [
        {
          header: this.translateService.instant('general.alerts.name'),
          type: TypeInput.TEXT,
          field: 'name',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('general.alerts.symptoms'),
          type: TypeInput.TEXT,
          field: 'symptoms',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('general.alerts.dateTime'),
          type: TypeInput.TEXT,
          field: 'dateTime',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('general.alerts.origin'),
          type: TypeInput.TEXT,
          field: 'origin',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('general.alerts.status'),
          type: TypeInput.TEXT,
          field: 'status',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant(
            'general.alerts.revisionStatus'
          ),
          type: TypeInput.TEXT,
          field: 'revisionStatus',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('subject.entity'),
          type: TypeInput.TEXT,
          field: 'subjectNumber',
          showBasicSearch: true,
          showFilterComplete: false,
        },
      ],
    };
  }
  getAlertFiltersAndOptions() {
    this.translateService
      .get('general.alerts.enums.filters')
      .subscribe((filters) => {
        Object.keys(filters).forEach((e) => {
          this.ranges.push({ name: filters[e], value: e });
        });
      });

    this.translateService
      .get('ediary.components.alertAddEdit.revisionStatus')
      .subscribe((states) => {
        Object.keys(states).forEach((state) => {
          this.stateOptions.push({
            value: state,
            label: states[state],
          });
        });
      });
  }
  backward() {
    history.back();
  }
  showDetail(event: any) {
    this.selectedAlert = event;
    this.display = true;
    this.alertSubject?.openDetail(this.selectedAlert);
  }

  filter() {
    if (this.selectedRange === 'LAST_MONTH') {
      const month = 2592000000;
      return this.filterAlertsByTimestampDate(month);
    }
    if (this.selectedRange === 'LAST_SIX_MONTHS') {
      const sixMont = 15552000000;
      return this.filterAlertsByTimestampDate(sixMont);
    }
    return this.buildCardsAndTable(this.alerts);
  }

  filterAlertsByTimestampDate(range: number) {
    const timestampDate = Math.floor(new Date().getTime());
    this.allowedAlerts = this.alerts.filter(
      (alert: any) => alert._lastChangedAt >= timestampDate - range
    );
    this.buildCardsAndTable(this.allowedAlerts);
  }

  evaluateOrigin(entityName: EntityName): string {
    if (entityName === 'DAY_INSTANCE') {
      return this.translateService.instant(
        'ediary.enums.originAlert.AlertInstance'
      );
    }
    return this.translateService.instant(
      'ediary.enums.originAlert.ReportInstance'
    );
  }

  onCancel(): void {
    this.display = false;
    this.displayDialog = false;
  }
  async sendNote(form: NgForm) {
    try {
      if (form.valid) {
        const input: UpdateAlertInstanceInput = {
          id: this.selectedAlert.id,
          revisionState: this.revisionStateValue,
          _lastUser: this.authService.getUsername(),
          _version: this.selectedAlert._version,
        };
        const indexSelectedAlert = this.alerts.findIndex(
          (alert: any) => alert.id === this.selectedAlert.id
        );
        this.trialpalService.showSpinner('general.alerts.entity', 'UPDATE');
        const response = await this.trialpalService.performGraphQLQuery(
          TRIALPAL_QUERIES.UpdateAlertInstance,
          {
            input,
          }
        );

        const alertInstance = response.data.updateAlertInstance;
        this.alerts.splice(indexSelectedAlert, 1, alertInstance);
        this.allowedAlerts = this.reportService.pendingWeekAlerts(this.alerts);
        if (this.allowedAlerts.length > 0) {
          this.buildCardsAndTable(this.allowedAlerts);
        } else {
          this.buildCardsAndTable(this.alerts);
        }
        this.trialpalService.hideSpinner();
        this.display = false;
        this.displayDialog = false;
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.translateGeneral.success,
          detail: this.translateGeneral.updateSuccess,
        });
        this.trialpalService.hideSpinner();
      }
    } catch (error) {
      logger.error('sendNoteError', error);
    }
  }
  getSymptomsAlert(confAlert_data: string): string {
    const confAlertData = JSON.parse(confAlert_data);
    if (confAlertData.symptomActivateAlert.length === 0) {
      return 'N/A';
    }
    const symptomsByAlert = confAlertData.symptomActivateAlert.map(
      (symptom: any) => {
        if (symptom.symptom) {
          return this.trialpalService.translateService.instant(
            'symptom.enums.symptoms.' + symptom.symptom
          );
        } else if (symptom.symptomInstanceId) {
          return this.trialpalService.translateService.instant(
            'symptom.enums.symptoms.' +
              this.symptoms.find(
                (symptomInstance) =>
                  symptomInstance.id === symptom.symptomInstanceId
              )?.symptom
          );
        } else {
          return 'N/A';
        }
      }
    );
    return symptomsByAlert
      .filter(
        (symptom: string, index: number) =>
          symptomsByAlert.indexOf(symptom) === index
      )
      .toString();
  }
  changeState(event: any) {
    this.displayDialog = true;
    this.selectedAlert = event;
    this.revisionStateValue =
      event.revisionState ?? RevisionStateAlertInstance.PENDING;
  }

  clearFilter() {
    this.allowedAlerts = this.reportService.pendingWeekAlerts(this.alerts);
    this.buildCardsAndTable(this.allowedAlerts);
  }
}
