import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearField',
  pure: true,
})
export class ClearFieldPipe implements PipeTransform {
  /*Elimina las expresiones para que el campo se visible en los formularios de creación en el modulo del diseñador*/
  /*Si no se hace la transformación y el campo esta atado a otro, no se mostraria al momento de editarlo si la condicion no se cumple */
  transform(field: any[]): any {
    //Crea una copiar del campo original
    const value = JSON.parse(JSON.stringify(field));
    //Le quita las expressiones que tiene (No modifica el campo original)
    if (value[0]?.hideExpression) {
      value[0].hideExpression = '';
    }
    if (value[0].fieldArray) {
      value[0] = value[0].fieldArray;
    }
    return value;
  }
}
