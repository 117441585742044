import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { Roles } from 'src/app/shared/global.variables';
import { SitesService } from '../sites/sites.service';
import { SubjectsService } from '../subjects/subjects.service';
import { UserService } from '../user/user.service';
import { ModelUserFilterInput } from '../user/user.types';

const logger = new Logger('tp2-logger-VersionService');

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(
    private auth: AuthService,
    private userService: UserService,
    private sitesService: SitesService,
    private subjectsService: SubjectsService,
    private translateService: TranslateService
  ) {}
  async listSubjectUserByProject(projectId: string | null) {
    let users: any[] = [];
    for (const authSite of this.auth.getUserSites()) {
      const projectSiteAndRoleFilter: ModelUserFilterInput = {
        projects: {
          contains: projectId,
        },
        sites: {
          contains: authSite,
        },
        role: {
          eq: Roles.Subject,
        },
      };
      const arr = await this.userService.listUsers(projectSiteAndRoleFilter);
      const site = await this.sitesService.getSite(authSite);
      if (arr) {
        arr.forEach((user) => {
          user.sites = site.name;
          if (!user._deleted) {
            users.push(user);
          }
        });
      }
    }
    users = await this.organizeUsersData(users);

    logger.debug('listSubjectUserByProject', users);
    return Promise.resolve(users);
  }

  async organizeUsersData(users: any[]): Promise<any[]> {
    for (const user of users) {
      if (user.subjects && user.subjects !== '') {
        const subjects = user.subjects.split(',');
        let subjectNames: string[] = [];
        for (const subject of subjects) {
          const userSubject = await this.subjectsService.getSubject(subject);
          subjectNames.push(userSubject?.subjectNumber);
        }
        user.subjects = subjectNames.join(', ');
      } else {
        user.subjects = this.translateService.instant('version.noSubject');
      }
      if (!user.appVersion) {
        user.appVersion = this.translateService.instant('version.noVersion');
      }
      if (user.appVersion == 'Web version') {
        user.appVersion = this.translateService.instant('version.webVersion');
      }
    }
    return Promise.resolve(users);
  }
}
