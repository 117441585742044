import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConfState } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { projectStateGlobal, Roles } from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import { DeleteConfDayInput } from '../../ediary.types';
import { DayAddEditComponent } from '../day-add-edit/day-add-edit.component';
const logger = new Logger('tp2-logger-dayList');

@Component({
  selector: 'app-day-list',
  templateUrl: './day-list.component.html',
  styleUrls: ['./day-list.component.scss'],
})
export class DayListComponent implements OnInit {
  @Input() confEdiaryId: any = null;
  @Input() project: any = null;
  @Input() projectState: any = null;
  @Input() dictionary: any = null;
  @Output() confDaysUpdated: EventEmitter<any> = new EventEmitter();
  confDays: Array<any> = [];

  isReadOnly = false;

  //Permissions
  hasDiaryDayCreate: boolean = false; // Permiso para crear conf Days
  hasDiaryDayUpdate: boolean = false; // Permiso para actualizar conf Days
  hasDiaryDayDelete: boolean = false; // Permiso para eliminar conf Days

  constructor(
    private ediaryService: EdiaryService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private trialpalService: TrialpalService,
    private authService: AuthService,
    public userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isReadOnly = !this.authService.isAdmin();
    await this.loadConfDays();
    await this.setPermissions();
  }

  async setPermissions() {
    this.hasDiaryDayCreate = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryDayCreate,
      Roles.Admin,
    ]);
    this.hasDiaryDayDelete = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryDayDelete,
      Roles.Admin,
    ]);
  }

  async loadConfDays() {
    try {
      this.trialpalService.showSpinner(
        'ediary.components.dayAddEdit.loadingDays'
      );
      let confDays = await this.ediaryService.getConfDayByConfEdiaryId(
        this.confEdiaryId
      );
      logger.debug('getConfDayByConfEdiaryId res', confDays);

      confDays = confDays.items.filter(
        (confDay: any) =>
          !confDay._deleted && confDay.state !== ConfState.DELETED
      );
      if (confDays) {
        this.confDays = this.sortConfDayByOrder(confDays);
        this.confDaysUpdated.emit(this.confDays);
      }

      this.trialpalService.hideSpinner();
    } catch (error) {
      logger.error('loadConfDays', error);
      this.trialpalService.hideSpinner();
      this.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'general.messageErrorOperation.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'general.messageErrorOperation.detail'
        ),
      });
    }
  }

  sortConfDayByOrder(confDays: any) {
    return confDays.sort((confDayA: any, confDayB: any) =>
      confDayA.order > confDayB.order ? 1 : -1
    );
  }

  openForm(day?: any): void {
    const ref = this.dialogService.open(DayAddEditComponent, {
      data: {
        confEdiaryId: this.confEdiaryId,
        project: this.project,
        confDay: day ? { ...day } : null,
        projectState: this.projectState,
        dictionary: this.dictionary,
        confDays: this.confDays,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.reloadDayOrSave(res);
      }
    });
  }

  openFormInReadMode(day?: any): void {
    this.dialogService.open(DayAddEditComponent, {
      data: {
        confEdiaryId: this.confEdiaryId,
        project: this.project,
        confDay: day ? { ...day } : null,
        projectState: this.projectState,
        dictionary: this.dictionary,
        confDays: this.confDays,
        readMode: true,
      },
      width: '98%',
      height: '95%',
    });
  }

  private reloadDayOrSave(res: any) {
    if (!res.error) {
      const duplicate = this.confDays.find((e) => e.id === res.id);
      if (duplicate) {
        const index = this.confDays.indexOf(duplicate);
        this.confDays[index] = res;
      } else {
        this.confDays.push(res);
      }
      this.confDays.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
      this.confDaysUpdated.emit(this.confDays);
      this.messageService.add({
        severity: 'success',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.dayAddEdit.toastDaySaved'
        ),
      });
    } else {
      this.loadConfDays();
    }
  }

  buildTPCardData(confDay: any): TPCardData {
    return {
      data: confDay,
      header: confDay.dayName || '---',
      icon1: 'pi pi-book',
      section1: confDay.confForm ? confDay.confForm.name : '---',
      icon2: 'pi pi-sort',
      section2: confDay.order,
      showEditButton: !this.isReadOnly,
      showActivateButton: false,
      isHeaderDictionary: true,
      showDeleteButton: this.hasDiaryDayDelete,
      showCustomButton: false,
      iconCustomButton: 'pi pi-search',
      objectAction: confDay.dayName || '',
      entityAction:
        this.trialpalService.translateService.instant('ediary.dayEntity'),
    };
  }

  async onDeleteConfDay(confDay: any): Promise<void> {
    try {
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant(
          'ediary.components.dayAddEdit.loadingDeletingDays'
        )
      );
      if (
        this.projectState !== projectStateGlobal.IN_DESIGN &&
        this.projectState !== projectStateGlobal.IN_PROGRESS
      ) {
        this.messageService.add({
          severity: 'warn',
          summary: this.trialpalService.translateService.instant(
            'ediary.components.dayAddEdit.errorMessageDeleteDay.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'ediary.components.dayAddEdit.errorMessageDeleteDay.detail'
          ),
        });
      }
      const hasDayInstances =
        await this.ediaryService.findAtLeastOneDayInstanceByConfDay(confDay.id);

      hasDayInstances
        ? await this.deleteConfDaySoft(confDay)
        : await this.deleteConfDayHard(confDay);

      this.trialpalService.hideSpinner();
      this.deleteMessage();
      this.removeConfDayOfDayList(confDay);
    } catch (error) {
      this.trialpalService.hideSpinner();
      this.trialpalService.showServiceError(
        'general.messageErrorOperation.summary',
        error
      );
      logger.error('onDeleteConfDay error', error);
    }
  }

  async deleteConfDayHard(confDay: any) {
    const input: DeleteConfDayInput = {
      id: confDay.id,
      _version: confDay._version,
    };

    await this.ediaryService.deleteConfDay(input);
  }

  async deleteConfDaySoft(confDay: any) {
    const input = {
      state: ConfState.DELETED,
    };
    this.ediaryService.updateConfDay(input, confDay.id, confDay._version);
  }

  async deleteMessage() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant('general.success'),
      detail: this.trialpalService.translateService.instant(
        'general.deleteSuccess'
      ),
    });
  }
  async removeConfDayOfDayList(confAlertToRemove: any) {
    this.confDays = this.confDays.filter(
      (confDay) => confDay.id !== confAlertToRemove.id
    );
  }
}
