import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Analytics, Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InstanceState } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { ConfVisitGroup, GetProjectQuery } from '../../project/project.types';
import { SitesService } from '../../sites/sites.service';
import { SubjectsService } from '../subjects.service';
import { GetSubjectQuery, SubjectState } from '../subjects.types';
const moment = require('moment-timezone');
const logger = new Logger('tp2-logger-subjectDetailPage');

@Component({
  selector: 'app-detail-subject',
  templateUrl: './detail-subject.component.html',
  styleUrls: ['./detail-subject.component.scss'],
})
export class DetailSubjectComponent {
  subject: GetSubjectQuery = {
    __typename: 'Subject',
    id: '',
    subjectNumber: '',
    state: SubjectState.COMPLETED,
    group: '',
    siteId: '',
    scheduledPhases: [],
    projectId: '',
    _version: 0,
    _lastChangedAt: 0,
    createdAt: '',
    updatedAt: '',
    _lastUser: '',
  };
  subjectId: string = '';
  projectId: string = '';
  currentDate = '';
  displayBasic: boolean = false;
  visit = { phase: '', state: '' };
  submitCompletVisit = false;
  userRol: any = '';
  timeZoneOffset: number = 0;
  listVisitGroup: ConfVisitGroup[] = [];
  showSpinner: boolean = true;
  dataCard: any[] = [];
  maxDate: Date = new Date();
  projectCode: string = '';
  project: GetProjectQuery | undefined;
  showMedicalAttentionTab: boolean = true;
  showMedicationTab: boolean = true;
  showMultimediaTab: boolean = true;
  showTemperatureTab: boolean = true;

  //Valida que tabs han sido abiertos
  isAlertTabOpened: boolean = false;
  isSymptomTabOpened: boolean = false;
  isEdiaryTabOpened: boolean = false;
  isUsersTabOpened: boolean = false;
  isReportTabOpened: boolean = false;
  isTemperatureTabOpened: boolean = false;
  isMedicalAttentionTabOpened: boolean = false;
  isMedicationTabOpened: boolean = false;

  //Permissions
  hasUserListPermission: boolean = false;
  hasCompleteVisitPermission: boolean = false;
  hasRecompleteVisitPermission: boolean = false;
  hasSubjectCreatePermission: boolean = false;
  hasSubjectAuditPermission: boolean = false;
  hasSubjectUpdatePermission: boolean = false;
  DETAIL_SUBJECT_TOUR: PageTourDataType = PageTourDataType.DETAIL_SUBJECT_TOUR;
  DETAIL_SUBJECT_USERS_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_USERS_TOUR;
  currentTabIndex: number = 0;
  constructor(
    private subjectsService: SubjectsService,
    private activatedRoute: ActivatedRoute,
    private trialpalService: TrialpalService,
    private router: Router,
    private transformDatePipe: TransformDatePipe,
    public authService: AuthService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private sitesService: SitesService,
    private projectService: ProjectService,
    private userPermissionsService: UserPermissionsService,
    private pageTourService: PageTourService
  ) {
    this.route.params.subscribe((_val) => {
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      this.maxDate = new Date(currentDate + 'T00:00:00');
      this.getSubjectDetail().then();
    });
  }

  showBasicDialog(scheduledPhase: any) {
    this.currentDate = '';
    this.submitCompletVisit = false;
    this.displayBasic = true;
    this.visit = scheduledPhase;
  }

  async getSubjectDetail(): Promise<void> {
    try {
      this.showSpinner = true;
      this.subjectId = this.activatedRoute.snapshot.params.subjectId;
      this.trialpalService.translateService
        .get('primeng')
        .subscribe((res) => this.trialpalService.config.setTranslation(res));

      await this.setPermissions();
      await this.getSubject();
      await this.getConfVisitGroups();

      this.timeZoneOffset = this.getTimeZoneOffset();

      this.buildCards(this.getPhases(this.subject.group));
      this.showSpinner = false;
      await new Promise((resolve) => {
        setTimeout(() => resolve(true), 500);
      });
      this.pageTourService.initiateTour(PageTourDataType.DETAIL_SUBJECT_TOUR);
    } catch (e) {
      logger.error(e, 'getSubjectDetail');
      this.showSpinner = false;
    }
  }
  async setPermissions() {
    this.hasUserListPermission =
      await this.userPermissionsService.hasPermission([TP2Permission.UserList]);

    this.hasCompleteVisitPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.CompleteVisit,
      ]);

    this.hasRecompleteVisitPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.RecompleteVisit,
      ]);

    this.hasSubjectCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectCreate,
      ]);
    this.hasSubjectUpdatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectUpdate,
      ]);

    this.hasSubjectAuditPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectAudit,
        Roles.Admin,
      ]);
  }

  recordTabChange(event: any) {
    this.openTabTour(event);
    logger.debug('User performing tab change', this.authService.user);
    const TabNames = [
      'Visits',
      'Alerts',
      'Symptoms',
      'Ediary',
      'Users',
      'Surveillance reports',
      'Temperature',
      'Medical attention',
      'Medication',
      'Multimedia',
    ];
    Analytics.record({
      name: 'site_subject_detail_tab',
      attributes: {
        tabName: TabNames[event.index],
        userSub: this.authService.user?.attributes?.sub,
        userName: this.authService.user?.username,
        subjectId: this.subjectId,
      },
    });
  }
  openTabTour(event: any) {
    this.currentTabIndex = event.index;
    this.resetOpenedTabs();
    const alerts = this.trialpalService.translateService.instant(
      'subject.tabheaderAlerts'
    );
    const symptoms = this.trialpalService.translateService.instant(
      'subject.tabheaderSymptom'
    );
    const ediary = this.trialpalService.translateService.instant(
      'subject.tabheaderPDFDiary'
    );
    const users = this.trialpalService.translateService.instant(
      'subject.tabheaderUsers'
    );
    const report = this.trialpalService.translateService.instant(
      'subject.tabheaderSurveillanceReports'
    );
    const temperature = this.trialpalService.translateService.instant(
      'subject.tabheaderTemperature'
    );
    const medicalAttention = this.trialpalService.translateService.instant(
      'subject.tabheaderMedicalAssistance'
    );
    const medication = this.trialpalService.translateService.instant(
      'subject.tabheaderMedication'
    );
    const currentTab = event.originalEvent.target.innerText;
    switch (currentTab) {
      case alerts:
        this.isAlertTabOpened = true;
        break;

      case symptoms:
        this.isSymptomTabOpened = true;
        break;

      case ediary:
        this.isEdiaryTabOpened = true;
        break;

      case users:
        this.startTabTour(PageTourDataType.DETAIL_SUBJECT_USERS_TOUR);
        break;

      case report:
        this.isReportTabOpened = true;
        break;

      case temperature:
        this.isTemperatureTabOpened = true;
        break;

      case medicalAttention:
        this.isMedicalAttentionTabOpened = true;
        break;

      case medication:
        this.isMedicationTabOpened = true;
        break;

      default:
        break;
    }
  }

  resetOpenedTabs() {
    this.isAlertTabOpened = false;
    this.isSymptomTabOpened = false;
    this.isEdiaryTabOpened = false;
    this.isUsersTabOpened = false;
    this.isReportTabOpened = false;
    this.isTemperatureTabOpened = false;
    this.isMedicalAttentionTabOpened = false;
    this.isMedicationTabOpened = false;
  }

  buildCards(groups: any) {
    this.dataCard = [];
    for (let group of groups) {
      this.dataCard.push(this.buildTPCardData(group));
    }
  }

  async getSubject(): Promise<void> {
    let subject = await this.subjectsService.getSubject(this.subjectId);
    logger.debug('getSubject response', subject);
    if (subject?.id && subject?.state !== SubjectState.DELETED) {
      this.subject = subject;
      this.isUserAuthorized();
      this.projectId = this.subject.projectId;
      this.project = await this.projectService.getProject(this.projectId);
      this.projectCode = this.project.code;
    } else {
      this.projectId = subject.projectId;
      this.showSubjectDeletedMessage();
      return this.forward();
    }
    this.isUserAuthorized();
  }

  showSubjectDeletedMessage() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      detail: this.trialpalService.translateService.instant(
        'subject.messageSubjecteDeleted'
      ),
      summary: this.trialpalService.translateService.instant('general.error'),
      severity: 'error',
    });
  }
  isUserAuthorized() {
    if (this.authService.isInvestigator() || this.authService.isReader()) {
      if (
        this.authService.getUserSites().indexOf(this.subject?.siteId) === -1
      ) {
        this.router.navigateByUrl(`subjects/${this.projectId}`);
      }
    }
  }

  //Obtiene todas las visitas asociadas a un grupo
  async getConfVisitGroups() {
    return this.projectService
      .getConfVisitGroup(this.projectId)
      .then((response1: any) => {
        this.listVisitGroup = response1;
      });
  }

  buildTPCardData(res: any): TPCardData {
    let section3 = '';
    let icon3 = '';
    let phaseDate: any = '';
    if (this.subject.currentScheduledPhase?.phase === res.phase) {
      section3 = this.trialpalService.translateService.instant(
        'subject.pCurrentVisit'
      );
      icon3 = 'pi pi-check';
    }
    if (res.date) {
      phaseDate = this.transformDatePipe.transformToSiteHour(
        res.date,
        this.timeZoneOffset
      );
      if (res.date.substring(0, 10) === '2999-12-12') {
        phaseDate = '';
      }
    }
    return {
      ...res,
      data: res,
      header: this.trialpalService.dictionaryPipe.transform(res.phase),
      section1: phaseDate,
      icon1: 'pi pi-calendar',
      section2: this.trialpalService.translateService.instant(
        'visit.enums.visitStates'
      )[res.state],
      icon2: 'pi pi-info-circle',
      section3,
      icon3,
      styleColorState: '',
      hideState: true,
      showEditButton: false,
      showActivateButton: false,
      showAuditButton: false,
      showDeleteButton: false,
      cardIsButton: false,
      showCustomButton:
        this.subject.state === SubjectState.ENROLLED &&
        this.checkButtonVisibility(res),
      iconCustomButton: 'pi pi-check',
    };
  }

  openAuditModal() {
    this.trialpalService.dialogService.open(AuditInfoComponent, {
      data: {
        entity: TP2Entites.SUBJECT,
        description: this.subject.subjectNumber,
        idRecord: this.subject.id,
      },
      width: '95%',
      height: '95%',
    });
  }

  editSubject() {
    if (this.subject.state !== SubjectState.ENROLLED) {
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary: this.trialpalService.translateService.instant(
          'subject.errorMessageEditingSubject.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'subject.errorMessageEditingSubject.detail'
        ),
        life: 5000,
      });
      return;
    }
    this.router.navigate(['subjects', this.subject.projectId, 'edit'], {
      queryParams: [this.subject.id],
    });
  }

  completeVisit(): void {
    this.submitCompletVisit = true;
    if (this.currentDate) {
      this.confirmationService.confirm({
        message: this.trialpalService.translateService.instant(
          'visit.confirmCompleteVisit',
          {
            visit: this.trialpalService.dictionaryPipe.transform(
              this.visit.phase
            ),
          }
        ),
        accept: () => {
          this.displayBasic = false;
          if (this.visit?.state === InstanceState.COMPLETED) {
            this.showPreviouslyCompletedVisitConfirm();
          } else {
            this.callCompleteVisiteFunction();
          }
        },
      });
    }
  }

  showPreviouslyCompletedVisitConfirm() {
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'subject.previouslyCompletedVisitConfirm'
      ),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.callCompleteVisiteFunction();
      },
      key: 'positionDialog',
    });
  }

  async callCompleteVisiteFunction() {
    try {
      this.trialpalService.showSpinner('visit.spinnerCompleteVisit');
      const date = this.subjectsService.setDateWithTimezone(
        new Date(this.currentDate).toISOString()
      );
      const response = await this.subjectsService.completeVisit(
        this.subject.subjectNumber,
        this.subject?.project?.code ?? '',
        date,
        this.visit?.phase
      );
      if (response) {
        logger.debug('completeVisit response', response);
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'visit.messageSuccesCompleteVisit.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'visit.messageSuccesCompleteVisit.detail'
          ),
        });
        await this.getSubjectDetail();
      }
      this.trialpalService.hideSpinner();
    } catch (error: any) {
      logger.error('completeVisit error', error);
      if (error?.response?.data) {
        this.trialpalService.showServiceError('visit.spinnerCompleteVisit', {
          errors: [
            {
              errorType: 'Lambda:Unhandled',
              message: error?.response?.data,
            },
          ],
        });
      } else {
        this.trialpalService.showServiceError(
          'user.actions.getUserSubjectsByNumber',
          error
        );
      }
      this.trialpalService.hideSpinner();
    }
  }

  isColorState(): string {
    if (this.subject.state === 'COMPLETED') {
      return 'green';
    }
    if (this.subject.state === 'DROPOUT') {
      return 'red';
    }
    return '';
  }

  //Obtiene las visitas correspondientes al grupo actual del sujeto
  getPhases(group: string): any[] {
    let phases =
      this.subject.scheduledPhases?.filter((phase: any) => {
        return (
          this.listVisitGroup.findIndex((visitGroup: any) => {
            if (
              this.authService.isReader() ||
              this.authService.isInvestigator()
            ) {
              return (
                visitGroup.group === group &&
                visitGroup.visit === phase.phase &&
                visitGroup.isVisible
              );
            } else {
              return (
                visitGroup.group === group && visitGroup.visit === phase.phase
              );
            }
          }) !== -1
        );
      }) ?? [];
    return this.authService.isAdmin() ||
      this.authService.isInvestigator() ||
      this.authService.isReader()
      ? phases
      : [];
  }

  forward(): void {
    this.router.navigate(['/subjects/', this.projectId]);
  }

  getTimeZoneOffset() {
    return this.sitesService.getSiteGMT(this.subject?.site?.timezone);
  }

  checkButtonVisibility(phase: { state: string }): boolean {
    if (this.authService.isAdmin()) {
      return true;
    }

    const isPhaseCompleted = phase.state === InstanceState.COMPLETED;

    return (
      (this.hasRecompleteVisitPermission && isPhaseCompleted) ||
      (this.hasCompleteVisitPermission && !isPhaseCompleted)
    );
  }

  startTabTour(tour: PageTourDataType) {
    this.pageTourService.initiateTour(tour);
  }
}
