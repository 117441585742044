import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeatButton-section',
  template: `
    <div class="p-mb-3" *ngFor="let field of field.fieldGroup; let i = index">
      <formly-field [field]="field"></formly-field>
      <button
        pButton
        type="button"
        class="btn btn-danger"
        icon="pi pi-trash"
        style="width: max-content; border: none"
        label="{{ to.removeTextButton }}"
        (click)="remove(i)"
        [disabled]="i === 0"
      ></button>
    </div>
    <div>
      <p class="p-mt-0 p-mb-2">{{ to.title ? to.title : '' }}</p>
      <div class="p-d-flex p-ai-center p-mb-2" style="gap: 0.5rem">
        <p-radioButton
          name="repeat"
          value="add"
          [(ngModel)]="unit"
          (click)="add(); change()"
        ></p-radioButton>
        <label class="p-m-0">{{ to.addTextButton }}</label>
      </div>
      <div class="p-d-flex p-ai-center" style="gap: 0.5rem">
        <p-radioButton
          name="repeat"
          value="not"
          [(ngModel)]="unit"
          (click)="change()"
        ></p-radioButton>
        <label class="p-m-0">{{ to.noTextButton }}</label>
      </div>
    </div>
  `,
})
export class RepeatTypeButtonComponent extends FieldArrayType {
  unit: any;

  change() {
    if (this.unit === '' || this.unit === 'add') {
      this.unit = '';
      return this.unit;
    }
  }
}
