import { MenuItem } from 'primeng/api';
import { EntityName } from 'src/app/services/trialpal.types';
export interface TPTableData {
  cols: ColValues[]; //Valores de cabecera y fields de columnas
  customButton1?: any;
  customButton2?: any;
  customButton3?: any;
  entity?: EntityName; //TIPO DE ENTIDAD PARA REALIZAR AUDITORIA
  globalFilterFields?: string[]; // array de string para el filtro global el cual hace referencia cada dato a la columna filtrada de debe hacer referencia al dato buscado en el objeto
  menuOptions?: MenuItem[]; // menu de opciones (debe estar activo el isMenuActionButton)
  showGeneralSearch?: boolean; //Mostrar el input de busqueda genral para la tabla (debe estar activo para busqueda excat anidada)
  showHeaderActions?: boolean; //Mostrar el td de acciones en la tab
  showMenuActionButton?: boolean; //Mostrar el boton de acciones en la tabla
  showResetFilter?: boolean; //Mostrar el boton de limpiar  todos filtros en seccion general
  valueTable?: any; //informacion cargada en la tabla array de datos (en el menuOptions se puede usar Callback para retorna u obtener info con el atributo command)
  isFeminine?: boolean; //BOOLEANO PARA CONFIGURAR GENERO DE LOS TEXTOS
  showDeleteButton?: boolean; //BOOLEANO PARA MOSTRAR EL BOTON DE ELIMINAR
  showEditButton?: boolean; //BOOLEANO PARA MOSTRAR EL BOTON DE EDITAR
  entityAction?: string; // ENTIDAD QUE APARECE EN LOS TEXTOS DE CONFIRMAR Y ELIMINAR
  objectAction?: string; // TEXTO PARA MOSTRAR EN LOS CONFIIRM
  actionsTitle?: string; // TEXTO PARA EN LA COLUMNA DE LOS BOTONES
}

// tipos de datos que se pueden usar
export enum TypeInput {
  TEXT = 'text',
  DATE = 'date',
  BOOLEAN = 'boolean',
  NUMERIC = 'numeric',
}

//Valores de la tabla
//Cada columna solo puede tenr UN TIPO de busqueda
interface ColValues {
  header: string; // nombre de la columna
  type: TypeInput; //tipo de dato (Relacionado en TypeInput)
  field: string; //nombre al del objeto que busca para mostrar
  showBasicSearch: boolean; //busqueda sencilla
  showFilterComplete?: boolean; //busqueda exacta relacionada en los objetos
  optionsFilterComplete?: OptionsSearchFilter[];
  showSearchState?: boolean; //Mostrar el filtro de busqueda por estado (se debe pasar el array de estados o relacionarlos en el objeto)
  optionsSearchState?: OptionsSearchFilter[];
}
// Label que se muestra en el menu de busqueda
// valu es ngModel del atributo o key del mismo
interface OptionsSearchFilter {
  label: string;
  value: string;
}
