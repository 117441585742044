<div class="container-tp2">
  <h2>{{ 'project.h4EdiaryStatus' | translate }}</h2>
  <form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="p-grid p-fluid p-justify-start">
      <div class="p-col-12 p-md-6 p-xl-2">
        <label>
          {{ 'general.configurations.enums.frequency' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-dropdown
          id="date"
          ngDefaultControl
          class="input"
          name="groups"
          [options]="frequencies"
          [(ngModel)]="frequency"
          placeholder="{{ 'general.frequency.entity' | translate }}"
          #grupos="ngModel"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
        >
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <label>
          {{ 'general.phases' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-multiSelect
          appendTo="body"
          class="input multiSelect"
          name="visits"
          #visits="ngModel"
          [options]="projectVisits"
          [(ngModel)]="selectedVisits"
          (onChange)="controlDays()"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [filter]="true"
          filterBy="label"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [maxSelectedLabels]="2"
          selectedItemsLabel="{2}
          {{ 'user.labelSelectedVisits' | translate }}
        "
          required
        >
          <ng-template let-visits pTemplate="item">
            <div>
              <div pTooltip="{{ visits.label }}">
                {{ visits.label | shortText: 100 }}
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <label>
          {{ 'report.electronicJournalDays' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-multiSelect
          appendTo="body"
          class="input multiSelect"
          name="confDays"
          #confDays="ngModel"
          [options]="getDaysByVisit()"
          [(ngModel)]="selectedConfDays"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [filter]="true"
          filterBy="label"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [maxSelectedLabels]="2"
          selectedItemsLabel="{2}
          {{ 'user.labelSelectedDays' | translate }}
        "
          required
        >
          <ng-template let-site pTemplate="item">
            <div>
              <div pTooltip="{{ site.label }}">
                {{ site.label | shortText: 100 }}
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="p-col-12 p-md-6 p-xl-2">
        <label>
          {{ 'report.sites' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-multiSelect
          appendTo="body"
          class="input"
          name="sitios"
          [options]="projectSites"
          [(ngModel)]="selectedSites"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [filter]="true"
          filterBy="label"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [maxSelectedLabels]="2"
          selectedItemsLabel="{2}
          {{ 'user.labelSelectedSites' | translate }}
        "
          required
        >
          <ng-template let-site pTemplate="item">
            <div>
              <div pTooltip="{{ site.label }}">
                {{ site.label | shortText: 100 }}
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="p-col-12 p-md-6 p-xl-2 botton">
        <button
          pButton
          id="btn_shearch"
          type="submit"
          [disabled]="isDisabledSearchButton()"
          [label]="'general.find' | translate"
        ></button>
      </div>
    </div>
  </form>
  <div class="p-grid">
    <div class="card p-col">
      <h3 id="title">{{ title | translate }}</h3>
      <p-chart
        id="chart"
        type="bar"
        [data]="stackedData"
        [options]="stackedOptions"
        width="80vw"
        height="80vh"
      ></p-chart>
    </div>
  </div>
  <section class="p-mb-5">
    <div class="p-col-12" style="display: flex">
      <label for="diaryPending" style="margin-right: 1rem">
        {{ pending }}
      </label>
      <p-inputSwitch
        id="diaryPending"
        [(ngModel)]="filter"
        (onChange)="reportMasterWithCurrentTime()"
      ></p-inputSwitch>
    </div>
    <app-tptable
      [dataTable]="dataTable"
      (openDetail)="goDetailSubject($event)"
    ></app-tptable>
  </section>
  <br />
  <br />
</div>
