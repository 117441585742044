import { Component, Input } from '@angular/core';

@Component({
  selector: 'report-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.scss'],
})
export class MultimediaComponent {
  @Input() isReadOnly: boolean = false;
  @Input() readMode: boolean = false;
  @Input() confReport: any = {};
  @Input() form: any = {};
  @Input() mediaTypes: any[] = [];
}
