<div class="summary p-mb-5">
  <div class="p-grid p-col-12 p-p-0">
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        ![undefined, null].includes(original?.occurrency) ||
        ![undefined, null].includes(verified?.occurrency)
      "
    >
      <span
        id="lbl_verfiedOccurrency"
        name="lbl_verfiedOccurrency"
        for="verfiedOccurency"
      >
        {{ 'symptominstance.occurrency' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.occurrency | textNa: verified?.occurrency:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        ![undefined, null].includes(original?.intensity) ||
        ![undefined, null].includes(verified?.intensity)
      "
    >
      <span
        id="lbl_Intensity"
        name="lbl_verifiedIntensity"
        for="verifiedIntensity"
      >
        {{ 'symptominstance.intensity' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original.intensity | textNa: verified?.intensity:'intensity':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        ![undefined, null].includes(original?.size) ||
        ![undefined, null].includes(verified?.size)
      "
    >
      <span
        id="lbl_Intensity"
        name="lbl_verifiedIntensity"
        for="verifiedIntensity"
      >
        {{ 'symptominstance.size' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="original.size | textNa: verified?.size:'':true"
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.isStartDateRequired &&
        (![undefined, null].includes(original?.rememberStartDate) ||
          ![undefined, null].includes(verified?.rememberStartDate))
      "
    >
      <span
        id="lbl_verfiedRememberStartDate"
        name="lbl_verfiedRememberStartDate"
        for="verfiedRememberStartDate"
      >
        {{ 'symptominstance.startDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberStartDate
            | textNa: verified?.rememberStartDate:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        verified?.startDate ||
        original?.startDate ||
        verified?.rememberStartDate
      "
    >
      <span
        id="lbl_verifiedStartDate"
        name="lbl_verifiedStartDate"
        for="verifiedStartDate"
      >
        {{ 'symptominstance.startDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.startDate | textNa: verified?.startDate:'date':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.isFinishDateRequired &&
        (![undefined, null].includes(original?.rememberFinishDate) ||
          ![undefined, null].includes(verified?.rememberFinishDate))
      "
    >
      <span
        id="lbl_verifiedRememberFinishDate"
        name="lbl_veriifiedRememberFinishDate"
        for="verifiedRememberFinishDate"
      >
        {{ 'symptominstance.endDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberFinishDate
            | textNa: verified?.rememberFinishDate:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        original?.finishDate ||
        verified?.finishDate ||
        verified?.rememberFinishDate
      "
    >
      <span
        id="lbl_verifiedFinishDate"
        name="lbl_verifiedFinishDate"
        for="verifiedFinishDate"
      >
        {{ 'symptominstance.endDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.finishDate | textNa: verified?.finishDate:'date':true
        "
      ></div>
    </div>
    <div class="p-d-flex p-col-12 p-jc-between" *ngIf="originalQuestions">
      <span
        id="lbl_verifiedQuestions"
        name="lbl_verifiedQuestions"
        for="verifiedQuestions"
      >
        {{ 'symptom.questionsTitle' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="originalQuestions | textNa: verifiedQuestions:'':true"
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showNoValuesTaken &&
        (![undefined, null].includes(original?.rememberNoValuesTaken) ||
          ![undefined, null].includes(verified?.rememberNoValuesTaken))
      "
    >
      <span
        id="lbl_verifiedQuestions"
        name="lbl_verifiedQuestions"
        for="verifiedQuestions"
      >
        {{ 'symptom.showNoValuesTaken' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberNoValuesTaken
            | textNa: verified?.rememberNoValuesTaken:'boolean':true
        "
      ></div>
    </div>
  </div>
</div>

<div class="p-d-flex p-jc-end">
  <button
    pButton
    type="button"
    (click)="onClose(false)"
    label="{{ 'general.cancel' | translate }}"
    icon="pi pi-times"
    class="p-mx-2"
  ></button>
  <button
    pButton
    type="button"
    (click)="onClose(true)"
    label="{{ 'general.confirm' | translate }}"
    icon="pi pi-check"
    class="p-button-secondary"
  ></button>
</div>
