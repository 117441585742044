import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DeleteUserEndpointsCommand,
  PinpointClient,
} from '@aws-sdk/client-pinpoint';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { TranslateService } from '@ngx-translate/core';
import { API, Analytics, Auth, Logger, graphqlOperation } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import awsmobile from 'src/aws-exports';
import version from '../../../package.json';
import { TermsAndConditionsComponent } from '../shared/components/terms-and-conditions/terms-and-conditions.component';
import { Roles } from '../shared/global.variables';
import { AUTH_QUERIES, UserState } from './auth.service.queries';

const logger = new Logger('tp2-logger-AuthService');
@Injectable()
export class AuthService {
  user: any = {};
  userAuthenticated = new Subject<boolean>();
  termsAndPoliciesVersion: {
    lastTermsAndConditions?: string;
    lastPrivacyPolicies?: string;
  } = {};
  onUpdateUserSubscription: any;
  $postLoginSession = new Subject<boolean>();
  postLoginSession: boolean = false;
  lastUrl: string = '';
  constructor(
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {}

  getUsername(): string {
    if (this.isAuthenticated()) {
      const u = sessionStorage.getItem('username');
      if (u) {
        return u;
      } else {
        return 'non_authenticated_user';
      }
    } else {
      return 'non_authenticated_user';
    }
  }
  isAuthenticated(): boolean {
    const role = sessionStorage.getItem('role');
    return role !== '' && role !== null && role !== undefined;
  }
  async navigateHome() {
    await this.showTermsAndCondition();

    this.listenUserEvents();
    const customProjects = this.getUserProjects();
    if (customProjects.length === 1) {
      this.router.navigateByUrl(`project/${customProjects[0]}/detail`);
    } else {
      this.router.navigate(['home']);
    }
    this.postLoginSession = true;
    this.$postLoginSession.next(true);
  }

  async showTermsAndCondition() {
    const isAdmin = this.isAdmin();
    //Valida si el usuario ha aceptado los terminos y condiciones
    if (!isAdmin) {
      await this.validateTermsAndConditions();
      await this.checkUserTermsVersions();
    }
  }
  navigateChangePassword() {
    this.router.navigate(['newPassword']);
  }

  async logout(check: boolean = true, isLogin: boolean = true): Promise<any> {
    this.user = null;
    this.postLoginSession = false;
    this.lastUrl = '';
    const lang = localStorage.getItem('lang') ?? 'es';
    const viewMode = localStorage.getItem('viewMode') ?? '[]';
    const lastRoutes = localStorage.getItem('lastRoutes') ?? '[]';
    const tourHistory = localStorage.getItem('tourHistory') ?? '[]';
    const tp2OnBoarding = localStorage.getItem('tp2OnBoarding') ?? '[]';

    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('lang', lang);
    localStorage.setItem('viewMode', viewMode);
    localStorage.setItem('lastRoutes', lastRoutes);
    localStorage.setItem('tourHistory', tourHistory);
    localStorage.setItem('tp2OnBoarding', tp2OnBoarding);
    await Auth.signOut();

    const token = check ? 'logout' : 'login';
    localStorage.setItem('timer', token);

    this.userAuthenticated.next(false);
    if (isLogin) this.router.navigate(['/login']);

    this.onUpdateUserSubscription?.unsubscribe();
  }

  getUserRoles(): any {
    const roles = sessionStorage.getItem('role');
    if (roles) {
      return roles.split(',');
    }
    return [];
  }

  getUserProjects(): string[] {
    const str = sessionStorage.getItem('projects');
    if (str) {
      return str.trim().split(',');
    }
    return [];
  }
  getUserSites(): string[] {
    const str = sessionStorage.getItem('sites');
    if (str) {
      return str
        .trim()
        .split(',')
        .filter((x) => x !== '');
    }
    return [];
  }
  getName() {
    const name = sessionStorage.getItem('name');
    return name === 'undefined' ? '' : ' ' + name;
  }

  getEmail() {
    const email = sessionStorage.getItem('email');
    return email ?? '';
  }

  async loginUser(login: string, password: string): Promise<any> {
    return await Auth.signIn(login, password);
  }
  confirmSignIn(user: string, code: string): Promise<any> {
    return Auth.confirmSignIn(user, code, 'SMS_MFA');
  }
  completeNewPassword(password: string): any {
    const { requiredAttributes } = this.user.challengeParam;
    return Auth.completeNewPassword(this.user, password, requiredAttributes);
  }
  sendCodeResetPassword(username: string): any {
    return Auth.forgotPassword(username);
  }

  completeResetPassword(username: string, password: string, code: string): any {
    return Auth.forgotPasswordSubmit(username, code, password);
  }
  isRoleAuthorized(roles: string[]): boolean {
    const userRoles = this.getUserRoles();
    if (!roles) {
      return false;
    } else {
      for (const r of userRoles) {
        if (roles.indexOf(r) !== -1) {
          return true;
        }
      }
      return false;
    }
  }

  isAdmin(): boolean {
    const role: string[] = this.getUserRoles();
    return role && role.indexOf(Roles.Admin) !== -1;
  }
  isInvestigator(): boolean {
    const role = this.getUserRoles();
    return role && role.indexOf(Roles.Investigator) !== -1;
  }
  isCoordinator(): boolean {
    return false;
  }
  isSubject(): boolean {
    const role = this.getUserRoles();
    return role && role.indexOf(Roles.Subject) !== -1;
  }
  isReader(): boolean {
    const role = this.getUserRoles();
    return role && role.indexOf(Roles.Reader) !== -1;
  }

  isConciliator(): boolean {
    const role = this.getUserRoles();
    return role && role.indexOf(Roles.Conciliator) !== -1;
  }

  setUserInLocalStorage(cognitoUser: any): void {
    const userSesion = cognitoUser.getSignInUserSession();
    sessionStorage.setItem(
      'email',
      userSesion.getIdToken().decodePayload()['email']
    );
    sessionStorage.setItem(
      'role',
      userSesion.getAccessToken().decodePayload()['cognito:groups']
    );
    if (
      userSesion.getAccessToken().decodePayload()['cognito:groups'][0] ===
      Roles.Subject
    ) {
      this.logout();
      return this.userNoAuthorizedMessage();
    }
    sessionStorage.setItem('userId', cognitoUser?.attributes?.sub);
    sessionStorage.setItem('username', cognitoUser?.username);
    sessionStorage.setItem(
      'name',
      cognitoUser?.attributes
        ? cognitoUser?.attributes?.name
        : userSesion?.idToken?.payload['name'] || ''
    );
    const p = cognitoUser.attributes
      ? cognitoUser.attributes['custom:projects']
      : userSesion.idToken.payload['custom:projects'];
    if (p) {
      sessionStorage.setItem('projects', p);
    } else {
      sessionStorage.setItem('projects', '');
    }
    const s = cognitoUser.attributes
      ? cognitoUser.attributes['custom:sites']
      : userSesion.idToken.payload['custom:sites'];
    if (s) {
      sessionStorage.setItem('sites', s);
    } else {
      sessionStorage.setItem('sites', '');
    }

    this.userAuthenticated.next(true);
  }

  userNoAuthorizedMessage() {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(
        'user.messageErrorSubjectNonAuthorized.summary'
      ),
      detail: this.translateService.instant(
        'user.messageErrorSubjectNonAuthorized.detail'
      ),
    });
  }

  /**
   * Esta es una función asíncrona que recupera datos de usuario al iniciar sesión desde una API y
   * devuelve una Promesa.
   * @param {string} login - una cadena que representa el nombre de inicio de sesión del usuario.
   * @returns Una promesa que se resuelve en un objeto que contiene datos de usuario o se rechaza con un
   * error.
   */
  async getUserByLogin(login: string): Promise<any> {
    const response = await this.performGraphQLQuery(AUTH_QUERIES.UserByLogin, {
      login,
    });
    const userLogin = response.data.userByLogin;
    if (userLogin.items.length > 0) {
      const responseUser = await this.performGraphQLQuery(
        AUTH_QUERIES.GetUser,
        { id: userLogin?.items[0]?.id }
      );
      const userData = responseUser.data.getUser;
      const userTermsAndConditions = userData?.termsAndConditions || [];
      this.user.termsAndConditions = userTermsAndConditions;
      this.user.dynamoId = userData.id;
      this.user._version = userData._version;
      return this.user;
    }
    return undefined;
  }

  /**============================Funciones para validar los terminos y condiciones del usuario ====================================== */
  /**
   * Esta función verifica los términos y condiciones y las versiones de las políticas del usuario y
   * navega a la página de términos y condiciones si no los ha aceptado o si hay nuevas versiones
   * disponibles.
   */
  async checkUserTermsVersions(): Promise<void> {
    try {
      const user = await this.getUserByLogin(this.getUsername());
      const userTermsAndConditions = user?.termsAndConditions;

      // Call the checkUserTerms function to determine if the user has accepted any terms and conditions
      const canUserTerms = this.checkUserTerms(userTermsAndConditions, user);

      if (canUserTerms) return;

      const termsAndConditions =
        this.termsAndPoliciesVersion.lastTermsAndConditions ??
        sessionStorage.getItem('termsVersion');
      const privacyPolicies =
        this.termsAndPoliciesVersion.lastPrivacyPolicies ??
        sessionStorage.getItem('policiesVersion');

      // Call the checkNewTerms function to determine if the user needs to accept new terms and conditions
      this.checkNewTerms(
        userTermsAndConditions,
        termsAndConditions,
        privacyPolicies
      );
    } catch (error) {
      logger.debug('termsAndConditionsQuery', error);
    }
  }

  // Check if the user has accepted any terms and conditions
  checkUserTerms(userTermsAndConditions: any, user: any): boolean {
    if (!userTermsAndConditions || userTermsAndConditions.length === 0) {
      this.user.termsAndConditions = [];
      this.user.dynamoId = user.id;
      this.showTermsAndCoditionalModal();
      return true;
    }
    return false;
  }

  showTermsAndCoditionalModal(): void {
    this.dialogService.open(TermsAndConditionsComponent, {
      header: this.translateService.instant('general.terms.termsTitle'),
      width: '100vw',
      height: '100vh',
      styleClass: 'h-screen',
      transitionOptions: '0 linear',
      dismissableMask: false,
      closeOnEscape: false,
      showHeader: false,
      baseZIndex: 3001,
    });
  }

  // Check if the user needs to accept new terms and conditions
  checkNewTerms(
    userTermsAndConditions: any,
    termsAndConditions: any,
    privacyPolicies: any
  ): boolean {
    const maxTermsAndConditionsVersion = userTermsAndConditions.reduce(
      (max: any, obj: any) =>
        obj.termsAndConditionsVersion > max
          ? obj.termsAndConditionsVersion
          : max,
      ''
    );
    const maxPoliciesVersion = userTermsAndConditions.reduce(
      (max: any, obj: any) =>
        obj.policiesVersion > max ? obj.policiesVersion : max,
      ''
    );
    if (
      termsAndConditions &&
      privacyPolicies &&
      (maxTermsAndConditionsVersion < termsAndConditions ||
        maxPoliciesVersion < privacyPolicies)
    ) {
      this.showTermsAndCoditionalModal();
      return true;
    }
    return false;
  }

  /**
   * La función valida los términos y condiciones recuperando la última versión del almacenamiento y
   * configurándola en el almacenamiento de la sesión.
   * @param {any} terms
   * términos y condiciones que deben validarse.
   */
  async validateTermsAndConditions() {
    const response = await this.performGraphQLQuery(
      AUTH_QUERIES.Tp2TermsAndPolicies,
      {}
    );
    const lastTermsAndPolicies = response.data.tp2TermsAndPolicies;
    const termsAndPolicies = JSON.parse(lastTermsAndPolicies ?? '{}')?.body;
    this.termsAndPoliciesVersion = {
      lastTermsAndConditions: termsAndPolicies?.termsAndConditionsVersion ?? 1,
      lastPrivacyPolicies: termsAndPolicies.policiesVersion ?? 1,
    };
    sessionStorage.setItem(
      'policiesVersion',
      termsAndPolicies?.policiesVersion ?? 1
    );
    sessionStorage.setItem(
      'termsVersion',
      termsAndPolicies.termsAndConditionsVersion ?? 1
    );
  }

  listenUserEvents() {
    const response = this.performGraphQLQuery(
      AUTH_QUERIES.OnUpdateUser,
      {}
    ) as any;
    this.onUpdateUserSubscription = response.subscribe((_response: any) => {
      const data = _response.value.data.onUpdateUser;
      if (data?.value?.data?.onUpdateUser?.name === this.user?.username) {
        if (data?.value?.data?.onUpdateUser?.state === UserState.DISABLED) {
          logger.debug('User disabled');
          this.messageService.add({
            closable: true,
            life: 15000,
            severity: 'warn',
            summary: this.translateService.instant('general.sessionEnded'),
            detail: this.translateService.instant('general.sessionClosed'),
          });
          this.logout();
        }
      }
    });
  }

  async endpointUpdatingFlow() {
    logger.debug('endpointUpdatingFlow', sessionStorage.getItem('userId'));
    //1. Se eliminan los endpoints del usuario autenticado
    const userId = sessionStorage.getItem('userId');
    await this.deleteEndpoints(userId);

    //2. Se obtienen los datos del usuario autenticado
    const sites = sessionStorage.getItem('projects');
    const projects = sessionStorage.getItem('sites');
    const roles = sessionStorage.getItem('role');
    const email = sessionStorage.getItem('email');
    const username = sessionStorage.getItem('username');
    const siteVersion = 'V' + version.version;

    //2.1 Convertir los proyectos, sitios y roles en un arreglo y truncar a máximo 10 elementos
    const sitesArray = sites?.split(',').slice(0, 10);
    const projectsArray = projects?.split(',').slice(0, 10);
    const rolesArray = roles?.split(',').slice(0, 10);

    //3. Se actualiza el endpoint del usuario autenticado
    Analytics.updateEndpoint({
      UserId: userId,
      UserAttributes: {
        sites: sitesArray,
        projects: projectsArray,
        roles: rolesArray,
        email: [email],
        username: [username],
        siteVersion: [siteVersion],
      },
    });
  }

  async deleteEndpoints(userId?: any): Promise<void> {
    const cognitoCredentials = fromCognitoIdentityPool({
      identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      clientConfig: { region: awsmobile.aws_project_region },
    });
    const pinpointClient = new PinpointClient({
      region: awsmobile.aws_mobile_analytics_app_region,
      credentials: cognitoCredentials,
    });
    const params = {
      ApplicationId: awsmobile.aws_mobile_analytics_app_id,
      UserId: userId,
    };
    const command = new DeleteUserEndpointsCommand(params);
    const response = await pinpointClient.send(command);
    logger.debug('deleteEndpoints response', response);
  }

  //Funciones para guardar la ultima ruta autenticada del usuario
  getLastRoute(): any[] {
    const lastRoute = localStorage.getItem('lastRoutes');
    return lastRoute ? JSON.parse(lastRoute) : [];
  }

  getUserLastRoute(user: string) {
    const lastRoutes = this.getLastRoute();
    const lastRouteUser = lastRoutes.find((route) => route.user === user);
    return lastRouteUser ? lastRouteUser?.route : '';
  }

  clearLastRoute(user: string) {
    let lastRoutes = this.getLastRoute();
    lastRoutes = lastRoutes.filter((route) => route.user !== user);
    localStorage.setItem('lastRoutes', JSON.stringify(lastRoutes));
  }

  setLastRoute(route: string): void {
    const user = this.getUsername();
    const lastRoutes = this.getLastRoute();
    const lastRouteUser = lastRoutes.find((route) => route.user === user);
    if (lastRouteUser) {
      lastRouteUser.route = route;
    } else {
      lastRoutes.push({ user, route });
    }
    localStorage.setItem('lastRoutes', JSON.stringify(lastRoutes));
  }

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
