<div class="p-grid p-fluid">
  <!-- Toogle for adding new records -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig.enableAddingNewRecords"
  >
    <label class="full-witdh" for="addRecors">{{
      sectionReconciliationConfig.labelAddingNewRecords
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="addRecords"
        id="addRecors"
        [(ngModel)]="reconciliationConfig.allowAddRecords"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for removing new records -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig.enableRemovingRecords"
  >
    <label class="full-witdh" for="RemoveRecords">{{
      sectionReconciliationConfig.labelRemovingRecords
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="RemoveRecords"
        id="RemoveRecords"
        [(ngModel)]="reconciliationConfig.allowRemoveRecords"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for dates reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableDatesReconciliation"
  >
    <label class="full-witdh" for="DatesReconciliation">{{
      sectionReconciliationConfig.labelDatesReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="DatesReconciliation"
        id="DatesReconciliation"
        [(ngModel)]="reconciliationConfig.allowOcurencyDateReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for intensity reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableIntensityReconciliation"
  >
    <label class="full-witdh" for="intensityReconciliation">{{
      sectionReconciliationConfig.labelIntensityReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="intensityReconciliation"
        id="intensityReconciliation"
        [(ngModel)]="reconciliationConfig.allowIntensityReconciliation.allow"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Dropdown for intensity reconciliation options -->
  <div
    class="p-col-12 p-md-6 p-field"
    *ngIf="reconciliationConfig?.allowIntensityReconciliation?.allow"
  >
    <label class="full-witdh" for="intensityOptions"
      >{{ sectionReconciliationConfig.labelIntensityReconciliationOptions }}
      <span class="inputRequired"> * </span></label
    >
    <p-dropdown
      name="intensityOptions"
      id="intensityOptions"
      [(ngModel)]="
        reconciliationConfig.allowIntensityReconciliation.allowedAction
      "
      [options]="intensityOptions"
      optionLabel="label"
      optionValue="value"
      placeholder="{{ 'general.noResultsFound' | translate }}"
      [disabled]="readMode"
      appendTo="body"
    ></p-dropdown>
  </div>
  <!-- Toogle for route reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableRouteReconciliation"
  >
    <label class="full-witdh" for="RoutesReconciliation">{{
      sectionReconciliationConfig.labelRouteReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="RoutesReconciliation"
        id="RoutesReconciliation"
        [(ngModel)]="reconciliationConfig.allowRouteReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for Dosis reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableDosisReconciliation"
  >
    <label class="full-witdh" for="DoseReconciliation">{{
      sectionReconciliationConfig.labelDosisReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="DoseReconciliation"
        id="DoseReconciliation"
        [(ngModel)]="reconciliationConfig.allowDosisReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for Treatment reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableTreatmentReconciliation"
  >
    <label class="full-witdh" for="TreatmentReconciliation">{{
      sectionReconciliationConfig.labelTreatmentReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="TreatmentReconciliation"
        id="TreatmentReconciliation"
        [(ngModel)]="reconciliationConfig.allowTreatmentReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for Reason reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableReasonReconciliation"
  >
    <label class="full-witdh" for="ReasonReconciliation">{{
      sectionReconciliationConfig.labelReasonReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="ReasonReconciliation"
        id="ReasonReconciliation"
        [(ngModel)]="reconciliationConfig.allowReasonReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for Hospitalization reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableHospitalizationReconciliation"
  >
    <label class="full-witdh" for="HospitalizationReconciliation">{{
      sectionReconciliationConfig.labelHospitalizationReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="HospitalizationReconciliation"
        id="HospitalizationReconciliation"
        [(ngModel)]="reconciliationConfig.allowHospitalizationReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for SymptomOccurency reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableSymptomOccurencyReconciliation"
  >
    <label class="full-witdh" for="SymptomOcurrencyReconciliation">{{
      sectionReconciliationConfig.labelSymptomOccurencyReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="SymptomOcurrencyReconciliation"
        id="SymptomOcurrencyReconciliation"
        [(ngModel)]="reconciliationConfig.allowSymptomOccurencyReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
  <!-- Toogle for Type reconciliation -->
  <div
    class="p-col-12 p-md-6 p-field p-ai-center"
    style="display: grid; grid-template-columns: 1fr auto"
    *ngIf="sectionReconciliationConfig?.enableTypeReconciliation"
  >
    <label class="full-witdh" for="TypeReconciliation">{{
      sectionReconciliationConfig.labelTypeReconciliation
    }}</label>
    <div class="centerItem">
      <p-inputSwitch
        name="TypeReconciliation"
        id="TypeReconciliation"
        [(ngModel)]="reconciliationConfig.allowTypeReconciliation"
        [disabled]="readMode"
      ></p-inputSwitch>
    </div>
  </div>
</div>
