import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { ShortTextPipe } from 'src/app/shared/pipes/short-text.pipe';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { ActivationUsersComponent } from './activation-users/activation-users.component';
import { CreateUsersComponent } from './create-users/create-users.component';
import { EditUsersComponent } from './edit-users/edit-users.component';
import { ListUsersComponent } from './list-users/list-users.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserRoutingModule } from './user-routing.module';
import { AutoCompleteOffDirective } from 'src/app/directives/auto-complete-off.directive';
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AutoCompleteOffDirective,
    CreateUsersComponent,
    EditUsersComponent,
    ListUsersComponent,
    NewPasswordComponent,
    ResetPasswordComponent,
    ActivationUsersComponent,
    LoginComponent,
  ],
  imports: [UserRoutingModule, PrimengModule, SharedComponentsModule],
  exports: [ListUsersComponent, AutoCompleteOffDirective],
  providers: [ShortTextPipe, TranslatePipe],
})
export class UserModule {}
