import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { SitesService } from 'src/app/modules/sites/sites.service';
import { ConsentedUser } from 'src/app/services/API.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InformedConsentService } from '../../informed-consent.service';
import {
  ConfInformedConsent,
  GetConfInformedConsentQuery,
  InformedConsentState,
} from '../../informed-consent.types';
import { ProjectAndSiteInterface } from '../informed-consent-follow-up-user-form/informed-consent-follow-up-user-form.component';

const logger = new Logger('Informed-consent-follow-up');
@Component({
  selector: 'app-informed-consent-follow-up-main',
  templateUrl: './informed-consent-follow-up-main.component.html',
  styleUrls: ['./informed-consent-follow-up-main.component.scss'],
})
export class InformedConsentFollowUpMainComponent implements OnInit {
  siteId = '';
  confInformedConsentId = '';
  confInformedConsent!: ConfInformedConsent;
  consentedIdentificatorSearch = '';
  sites: any = [];
  confInformedConsents: any = [];
  informedConsentTableData: any = [];
  informedConsentInstances: any[] = [];
  confInformedConsentsByProject: any[] = [];
  projectId: any = null;
  labelButton1 = '';
  projectAndSite = {} as ProjectAndSiteInterface;
  menuOptions: any[] = [];
  selectedUser: any = {};
  selectedSite: any = {};
  totalSubjects: number = 0;
  timelineStatus: any = {};
  informConsentStates: any[] = [];
  currentFilter!: number | null;
  disableManageBtn: boolean = true;
  firstTablePage: number = 0;
  subjectsWhoNotContinue: any[] = [];
  subjectsWhoNotContinueFilterActive: boolean = false;
  filteredTableData: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trialpalService: TrialpalService,
    private readonly informedConsentService: InformedConsentService,
    private sitesService: SitesService
  ) {
    this.setEmptyFilters();
  }

  async ngOnInit() {
    this.trialpalService.showSpinner(
      'informedConsent.informedConsents',
      'LIST'
    );
    this.labelButton1 = this.trialpalService.translateService.instant(
      'general.configurations.title',
      {
        title: this.trialpalService.translateService.instant(
          'informedConsent.informedConsent'
        ),
      }
    );

    //Obtiene los id de la ruta
    this.projectId = this.route.snapshot.params.projectId;
    this.siteId = this.route.snapshot.params.siteId;
    this.confInformedConsentId = this.route.snapshot.params.confEconsentId;

    //Obtiene los confInformed creados del proyecto
    this.confInformedConsentsByProject =
      await this.informedConsentService.getConfInformedConsentByProjectId(
        this.projectId
      );
    //Obtiene los sitios que pertenecen al proyecto
    await this.getSitesByProject();
    //Inicializa el seguimiento
    await this.initSiteAndConfInformedInformation();
    this.trialpalService.hideSpinner();
  }

  async initSiteAndConfInformedInformation(): Promise<void> {
    const confInformedConsentId = this.confInformedConsentId;
    if (this.siteId) {
      await this.onSelectSite();
    }
    if (confInformedConsentId) {
      this.confInformedConsentId = confInformedConsentId;
      await this.onSelectInformedConsent();
    }
  }

  async getSitesByProject(): Promise<void> {
    const sites = await this.sitesService.getSitesByProject(this.projectId);

    this.sites = sites
      .map((site: any) => ({ label: site.name, value: site.id }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  getInformedConsent() {
    return this.informedConsentInstances.find((IC: any) => {
      return IC.consenterUserId === this.selectedUser.consenterUserId;
    });
  }

  async onSelectSite(): Promise<void> {
    this.setEmptyFilters();
    this.projectAndSite.projectId = this.projectId;
    this.projectAndSite.siteId = this.siteId;
    this.initializeGlobalVariables();
    await this.getConfInformedConsentsBySiteId();
    this.disableManageBtn = true;
    this.manageRedirect(false);
  }

  initializeGlobalVariables() {
    this.confInformedConsentId = '';
    this.informedConsentTableData = [];
    this.informedConsentInstances = [];
    this.subjectsWhoNotContinue = [];
    this.confInformedConsent = {} as ConfInformedConsent;
    this.totalSubjects = 0;
  }

  async getConfInformedConsentsBySiteId(): Promise<void> {
    this.confInformedConsents = this.confInformedConsentsByProject
      .filter(
        (confInformedConsent: GetConfInformedConsentQuery) =>
          !confInformedConsent._deleted &&
          confInformedConsent?.sites?.some((site) => site?.site === this.siteId)
      )
      .map((confInformedConsent: GetConfInformedConsentQuery) => {
        return {
          ...confInformedConsent,
          label: this.trialpalService.dictionaryPipe.transform(
            confInformedConsent?.name || ''
          ),
          value: confInformedConsent?.id,
        };
      });
  }

  async onSelectInformedConsent(): Promise<void> {
    if (this.confInformedConsentId) {
      this.trialpalService.showSpinner(
        'informedConsent.informedConsents',
        'LIST'
      );
      this.manageRedirect(true);

      this.informedConsentInstances = await this.getInformedConsentInstances();

      const confInformedConsent: ConfInformedConsent =
        this.confInformedConsents.find(
          (_confInformedConsent: ConfInformedConsent) =>
            _confInformedConsent.id === this.confInformedConsentId
        );
      this.confInformedConsent = confInformedConsent;
      this.totalSubjects =
        confInformedConsent.sites?.find(
          (site: any) => site.site === this.siteId
        )?.totalSubjects ?? 0;

      const consentedUsersPromises = this.informedConsentInstances.map(
        (informedConsentInstance) =>
          this.informedConsentService.getConsentedUserByInformedConsenteInstanceId(
            informedConsentInstance.id
          )
      );

      const consentedUsersResults = await Promise.all(consentedUsersPromises);
      this.informedConsentInstances.forEach((informedConsentInstance: any) => {
        informedConsentInstance.consentedUsers = consentedUsersResults.find(
          (consentedUsers: ConsentedUser[]) =>
            consentedUsers[0]?.informedConsentInstanceId ===
            informedConsentInstance.id
        );
        this.buildMissingConsentedUsersFromInformedConsent(
          informedConsentInstance,
          confInformedConsent
        );
      });
      this.getUsersWhoDidNotContinue();
      this.informedConsentInstances = this.informedConsentInstances.map(
        (ICI) => {
          ICI.usersState = this.getUsersState(ICI.consentedUsers);
          return ICI;
        }
      );

      this.buildTableFilter();
      this.trialpalService.hideSpinner();
    }
  }

  buildTableFilter() {
    this.setEmptyFilters();
    this.informedConsentTableData = [...this.informedConsentInstances];
    this.disableManageBtn = false;
    this.fillFiltersByState();
    this.getUsersWhoDidNotContinue();
    this.filteredTableData = [...this.informedConsentTableData];
    if (this.currentFilter) {
      this.filterData(this.currentFilter);
    }
    if (this.consentedIdentificatorSearch) {
      this.applySearchFilter();
    }
  }

  buildMissingConsentedUsersFromInformedConsent(
    informedConsentInstance: any,
    confInformedConsent: any
  ) {
    const consentedUsersSize =
      informedConsentInstance?.consentedUsers?.length ?? 0;
    //Calcula cuantos consentedUser estan vacios
    const missingUsers = {
      min:
        confInformedConsent.minRequiredSignatures - consentedUsersSize < 0
          ? 0
          : confInformedConsent.minRequiredSignatures - consentedUsersSize,
      max:
        confInformedConsent.maxRequiredSignatures - consentedUsersSize < 0
          ? 0
          : confInformedConsent.maxRequiredSignatures - consentedUsersSize,
    };
    informedConsentInstance.consentedUserMissings = missingUsers.min;
    informedConsentInstance.consentedUserMissingsToMax = missingUsers.max;
  }

  //Funcion que obtiene los consentimiento informado de los usuarios según el confInformedConsentId
  async getInformedConsentInstances() {
    const items: any =
      await this.informedConsentService.getInformedConsentInstancesByConfInformedConsentId(
        this.confInformedConsentId
      );

    return items.filter(
      (informedConsentInstance: any) =>
        !informedConsentInstance?._deleted &&
        informedConsentInstance?.siteId === this.siteId
    );
  }

  onSelectUser(user: any) {
    this.selectedUser = user;
  }

  goToUsersTable(): void {
    this.router.navigateByUrl(
      `/informed-consent/${this.projectId}/follow-up/users?siteId=${this.siteId}&icId=${this.confInformedConsentId}`
    );
  }
  filterData(filterIndex: number) {
    this.currentFilter = filterIndex;
    this.informedConsentTableData = this.informedConsentInstances.filter(
      (ICI) =>
        ICI.state === this.informConsentStates[this.currentFilter ?? -1]?.state
    );
    this.filteredTableData = [...this.informedConsentTableData];
    if (this.consentedIdentificatorSearch) {
      this.applySearchFilter();
    }
    if (this.subjectsWhoNotContinueFilterActive) {
      this.toogleFilterSubjectsNotContinue(
        { stopPropagation: () => {} },
        true,
        true
      );
    }
    this.firstTablePage = 0;
  }
  getUsersWhoDidNotContinue() {
    this.subjectsWhoNotContinue = this.informedConsentInstances.filter(
      (ICI) => ICI.state === InformedConsentState.DELETED
    );
  }

  countByState(array: any[]) {
    const countICIByState: Record<any, number> = array.reduce(
      (acc, obj, _index) => {
        const state = obj.state;
        if (!acc[state]) {
          acc[state] = 0;
        }
        acc[state]++;
        return acc;
      },
      {}
    );
    return countICIByState;
  }
  fillFiltersByState() {
    for (const [state, subjects] of Object.entries(
      this.countByState(this.informedConsentInstances)
    )) {
      const stateItem = this.informConsentStates.find(
        (item) => item.state === state
      );
      if (stateItem) {
        stateItem.subjects = subjects;
        stateItem.advance = Math.floor(
          (subjects / this.informedConsentInstances.length) * 100
        );
      }
    }
  }
  setEmptyFilters() {
    this.informConsentStates = Object.values(InformedConsentState)
      .filter(
        (state) =>
          state !== InformedConsentState.DELETED &&
          state !== InformedConsentState.PARTIALLY_SIGNED
      )
      .map((value) => ({
        state: value,
        advance: 0,
        subjects: 0,
      }));
  }
  getUsersState(consentedUsers: any[]) {
    const usersStatus = this.countByState(consentedUsers ?? []);
    if (Object.keys(usersStatus).length === 0) {
      return this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.details.UNASSIGNED'
      );
    }
    let fullDetail = '';
    for (const [state, number] of Object.entries(usersStatus)) {
      if (Object.keys(usersStatus).length === 1) {
        return this.buildDynamicText(state, number);
      } else if (fullDetail === '') {
        fullDetail += this.buildDynamicText(state, number, false);
      } else {
        fullDetail += ` / ${this.buildDynamicText(state, number, false)}`;
      }
    }
    return fullDetail;
  }

  buildDynamicText(
    state: string,
    number: number,
    general: boolean = true
  ): string {
    if (general) {
      return this.trialpalService.translateService.instant(
        `informedConsent.followUp.table.details.${state}_EVERY`,
        { number }
      );
    } else {
      return this.trialpalService.translateService.instant(
        `informedConsent.followUp.table.details.${state}`,
        { number }
      );
    }
  }
  onClearFilter(_event: any) {
    this.informedConsentTableData = [...this.informedConsentInstances];
    this.filteredTableData = [...this.informedConsentTableData];
    this.currentFilter = null;
    if (this.consentedIdentificatorSearch) {
      this.applySearchFilter();
    }
  }
  manageRedirect(isConfInformedConsent: boolean = false) {
    // Obtiene la ruta base actual
    const currentUrl = this.router.url.split('/follow-up')[0];

    //Construye la ruta cuando existe una consentimiento seleccionado
    const currentConfInformedUrl = isConfInformedConsent
      ? `e-consent/${this.confInformedConsentId}`
      : '';

    // Construye la nueva URL con los valores deseados
    const newUrl = `${currentUrl}/follow-up/${this.siteId}/${currentConfInformedUrl}`;

    // Cambia la URL en la barra de direcciones del navegador
    window.history.pushState({}, '', newUrl);
  }

  applySearchFilter() {
    let arrayToFiltering =
      this.filteredTableData.length !== this.informedConsentInstances.length
        ? [...this.filteredTableData]
        : [...this.informedConsentInstances];
    if (this.subjectsWhoNotContinueFilterActive) {
      arrayToFiltering = this.subjectsWhoNotContinue;
    }
    this.informedConsentTableData = arrayToFiltering.filter((ICI: any) =>
      ICI.consentedIdentificator
        .toLowerCase()
        .startsWith(this.consentedIdentificatorSearch.toLowerCase())
    );
  }

  async manageUserAction(
    event: any,
    informedConsentInstance: any
  ): Promise<void> {
    const { action, consentedUser } = event;

    switch (action) {
      case ConsentedActions.ADD_CONSENTED:
        this.addNewPersonToInformedConsent(
          informedConsentInstance,
          consentedUser
        );
        break;
      case ConsentedActions.DELETE:
        await this.deleteConsetedUser(informedConsentInstance, consentedUser);
        break;
      case ConsentedActions.ENABLE_SIGNATURE:
        this.updateStateInformedConsentInstance(
          event.informedConsentInstance,
          consentedUser
        );
        break;
    }
  }

  addNewPersonToInformedConsent(
    informedConsentInstance: any,
    consentedUser: any
  ): void {
    const consentedUsers = informedConsentInstance.consentedUsers;
    informedConsentInstance.consentedUsers = [...consentedUsers, consentedUser];
    this.buildMissingConsentedUsersFromInformedConsent(
      informedConsentInstance,
      this.confInformedConsent
    );
  }

  async deleteConsetedUser(
    informedConsentInstance: any,
    consentedUser: any
  ): Promise<void> {
    try {
      //Funcion principal para eliminar un consentido y su instancias relacionadas
      const changelog: any = await this.trialpalService.modalChangeReason(
        consentedUser,
        this.trialpalService.translateService.instant('user.actions.updateUser')
      );
      if (changelog?._changeReason) {
        consentedUser._changeReason = changelog._changeReason;
      } else {
        return;
      }
      logger.debug('deleteConsentedUser');
      await this.informedConsentService.deleteConsentedUserMain(consentedUser);
      const consentedUsers = informedConsentInstance.consentedUsers.filter(
        (_consentedUser: any) => _consentedUser.id !== consentedUser.id
      );
      const informedConsentInstanceStates = InformedConsentStateOrdered;
      for (const state of informedConsentInstanceStates) {
        const ICIUpdated =
          (await this.informedConsentService.updateAndVerifyInformedConsentInstance(
            {
              informedConsent: informedConsentInstance,
              confInformedConsent: this.confInformedConsent,
            },
            state as any
          )) as any;
        if (
          ICIUpdated.state === state &&
          informedConsentInstance._version + 1 === ICIUpdated._version
        ) {
          informedConsentInstance = ICIUpdated;
          informedConsentInstance.consentedUsers = consentedUsers;
          break;
        }
      }
      //Elimina el consentedUser del arreglo
      this.deleteConsentedUserFromInformedConsent(
        informedConsentInstance,
        consentedUser.id
      );
    } catch (error) {
      logger.error('Delete consentedUser error -->', error);
    }
  }

  //Funcion que se encarga de actualizar el informedConsent con los consentedUser activos
  deleteConsentedUserFromInformedConsent(
    informedConsentInstance: any,
    consentedUserId: string
  ) {
    informedConsentInstance.consentedUsers =
      informedConsentInstance.consentedUsers.filter(
        (_consentedUser: any) => _consentedUser.id !== consentedUserId
      );
    this.buildMissingConsentedUsersFromInformedConsent(
      informedConsentInstance,
      this.confInformedConsent
    );
    this.updateInformedConsentInstanceInArray(informedConsentInstance);
    this.informedConsentService.deleteConsentedUserSuccessMessage();
  }

  toogleFilterSubjectsNotContinue(
    event: any,
    clear: boolean = false,
    logic: boolean = false
  ) {
    if (this.subjectsWhoNotContinueFilterActive && clear) {
      event.stopPropagation();
      this.firstTablePage = 0;
      this.subjectsWhoNotContinueFilterActive = false;
      !logic && this.onClearFilter(null);
    } else {
      this.onClearFilter(null);
      this.firstTablePage = 0;
      this.subjectsWhoNotContinueFilterActive = true;
      this.informedConsentTableData = this.subjectsWhoNotContinue;
    }
    if (this.consentedIdentificatorSearch) {
      this.applySearchFilter();
    }
  }
  updateStateInformedConsentInstance(
    informedConsentInstance: any,
    consentedUser: any
  ) {
    const index = this.informedConsentInstances.findIndex(
      (ICInstance: any) => ICInstance.id === informedConsentInstance.id
    );
    const indexUser = this.informedConsentInstances[
      index
    ].consentedUsers.findIndex(
      (ConsentedUser: any) => ConsentedUser.id === consentedUser.id
    );
    const { state, _version, stateChanges, _lastUser } =
      informedConsentInstance;
    const informedConsentInstanceUpdates = {
      state,
      _version,
      stateChanges,
      _lastUser,
    };
    this.informedConsentInstances[index] = Object.assign(
      this.informedConsentInstances[index],
      informedConsentInstanceUpdates
    );
    this.informedConsentInstances[index].consentedUsers[indexUser] =
      consentedUser;
    this.informedConsentTableData = [...this.informedConsentInstances];
    this.setEmptyFilters();
    this.fillFiltersByState();
    this.currentFilter && this.filterData(this.currentFilter);
    this.consentedIdentificatorSearch && this.applySearchFilter();
    this.informedConsentInstances = this.informedConsentInstances.map((ICI) => {
      ICI.usersState = this.getUsersState(ICI.consentedUsers);
      return ICI;
    });
  }
  refreshInstanceAfertNewPersonAdded(informedConsentInstance: any) {
    if (informedConsentInstance) {
      this.buildMissingConsentedUsersFromInformedConsent(
        informedConsentInstance,
        this.confInformedConsent
      );
      this.updateInformedConsentInstanceInArray(informedConsentInstance);
    }
  }
  updateInformedConsentInstanceInArray(informedConsentInstance: any) {
    const findIndex = this.informedConsentInstances.findIndex(
      (ICI) => ICI.id === informedConsentInstance.id
    );
    this.informedConsentInstances[findIndex] = informedConsentInstance;
    this.informedConsentInstances = this.informedConsentInstances.map((ICI) => {
      ICI.usersState = this.getUsersState(ICI.consentedUsers);
      return ICI;
    });
    this.informedConsentTableData = [...this.informedConsentInstances];
    this.buildTableFilter();
    if (this.currentFilter) {
      this.filterData(this.currentFilter);
    }
    if (this.consentedIdentificatorSearch) {
      this.applySearchFilter();
    }
  }
}

export enum ConsentedActions {
  ADD_CONSENTED = 'ADD_CONSENTED',
  DELETE = 'DELETE',
  ENABLE_SIGNATURE = 'ENABLE_SIGNATURE',
}
export const InformedConsentStateOrdered = [
  'SIGNED_BY_PARTICIPANT',
  'PENDING_FOR_SIGNATURE',
  'REVIEWED',
  'IN_REVIEW',
  'ASSIGNED',
];
