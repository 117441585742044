import { Component, Input, OnInit } from '@angular/core';
import { Logger } from 'aws-amplify';
import {
  DayInstance,
  SymptomInstance,
  SymptomOccurrency,
  VerifiedSymptomInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import { ConfSymptom, SymptomType } from 'src/app/modules/ediary/ediary.types';
import { SectionsToConcilied } from 'src/app/shared/global.variables';
import { ConciliationService } from '../../../../conciliation.service';
import { PaginationService } from '../../../../pagination.service';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import {
  ConfSymptomInformation,
  Occurrency,
  SymptomInformation,
  SymptomOccurrence,
  SymptomService,
  TableSymptom,
} from '../symptom.service';
import { ConciliationComponents } from 'src/app/modules/conciliation/conciliationData';
const logger = new Logger('detail symptom conciliation');
@Component({
  selector: 'app-detail-symptom',
  templateUrl: './detail-symptom.component.html',
  styleUrls: ['./detail-symptom.component.scss'],
})
export class DetailSymptomComponent implements OnInit {
  @Input() symptomType: string = SymptomType.GENERAL;

  /**Variables para la tabla de ocurrencias */
  dayInstances: DayInstance[] = []; //Guarda la informacion de las instancias de dias a conciliar
  symptomOcurrencesTable: SymptomOccurrence[] = []; //Guarda la información de la ocurrencia por sintoma

  constructor(
    public symptomService: SymptomService,
    public paginationService: PaginationService,
    private conciliationService: ConciliationService,
    private dayInstanceService: DayInstanceService
  ) {}

  async ngOnInit() {
    this.dayInstances = this.buildDayInstances();
    await this.buildConfSymptomOcurrences();
    logger.debug('Symptoms table', this.symptomOcurrencesTable);
    this.createAndAssignSymptomComments(); //Llama la funcion que construira los sintomas
  }

  /**
   * Construye una lista de instancias de día.
   * Obtiene las instancias de día previas y devuelve un arreglo de instancias de día.
   * @returns Un arreglo de instancias de día.
   */
  buildDayInstances(): DayInstance[] {
    const dayInstancesAndConfigurations = this.getPreviousDayInstances();
    return dayInstancesAndConfigurations
      .map(({ dayInstance }: any) => dayInstance)
      .sort((a, b) => a?.confDay?.order - b?.confDay?.order);
  }

  /**
   * Obtiene las instancias de día previas que son elegibles para conciliación.
   * Combina las instancias de día pendientes y las instancias de día existentes y las filtra para obtener solo las que son anteriores a la fecha actual y permiten la conciliación de síntomas.
   * @returns Un arreglo de instancias de día previas y su configuracion.
   */
  getPreviousDayInstances(): any[] {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    //Filtra los días que ya hayan pasado
    return dayInstances.filter(({ dayInstance, configuration }: any) => {
      //Valida si los sintomas para el formulario actual puede ser conciliada
      const sectionsToConcilied =
        this.symptomType === SymptomType.GENERAL
          ? SectionsToConcilied.SOLICITED_SYMPTOMS
          : SectionsToConcilied.LOCAL_SYMPTOMS;
      const IsSymptomConciliationAllowed =
        !!configuration.sectionsToReconcile?.includes(sectionsToConcilied);

      //Valida si el día tiene asociado sintomas locales/generales (depende del componente actual reenderizado)
      const isDayInstanceHasSyptomWithCurrentSymptomType =
        this.isDayInstanceHasSyptomWithCurrentSymptomType(dayInstance.id);

      const isDayInstanceFinished =
        this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);
      return (
        isDayInstanceFinished &&
        IsSymptomConciliationAllowed &&
        isDayInstanceHasSyptomWithCurrentSymptomType
      );
    });
  }

  /**
   * Verifica si al menos hay una configuración de síntoma asociada con el tipo de síntoma actual para una instancia de día específica.
   * @param dayInstanceId El ID de la instancia de día con la que se comprobará la asociación.
   * @returns Un valor booleano que indica si el dia contiene simptomas con el tipo actual (general/local).
   */
  isDayInstanceHasSyptomWithCurrentSymptomType(dayInstanceId: string): boolean {
    const confSymptoms: ConfSymptomInformation[] =
      this.symptomService.confSymptoms;
    //Obtiene las configuraciones de sintomas (Pueden venir duplicados)
    const currentConfSymptomsWithCurrentSymptomType = confSymptoms.filter(
      (confSymptom: ConfSymptomInformation) =>
        confSymptom.confSymptom.type === this.symptomType
    );
    return currentConfSymptomsWithCurrentSymptomType.some(
      (confSymptom: ConfSymptomInformation) => {
        return confSymptom.dayInstance.id === dayInstanceId;
      }
    );
  }

  /**
   * Construye las ocurrencias de síntomas asociadas a cada configuración de síntoma.
   * Actualiza la propiedad symptomOcurrences con la lista de ocurrencias de síntomas construida.
   */
  async buildConfSymptomOcurrences(): Promise<void> {
    this.symptomOcurrencesTable = [];
    const confSymptoms: ConfSymptom[] = this.buildConfSymptoms();

    for (let confSymptom of confSymptoms) {
      //Obtiene el nombre del sintoma
      const name = this.symptomService.getConfSymptomName(confSymptom);

      //Obtiene la ocurrencias y dias asociados al sintoma
      const response = await this.buildOccurrencesByConfSymptom(confSymptom);
      if (!response) continue;
      const { occurrences, tableSymptoms } = response;

      this.symptomOcurrencesTable.push({
        id: confSymptom.id,
        name,
        data: confSymptom,
        occurrences,
        tableSymptoms,
        comments: [],
      });
    }
  }

  /**
   * Construye una lista de configuraciones de síntomas únicas y ordenadas según el tipo de síntoma.
   * @returns Un array de objetos ConfSymptom que representa las configuraciones de síntomas únicas y ordenadas.
   */
  buildConfSymptoms(): ConfSymptom[] {
    //Obtiene las configuraciones de sintomas (Pueden venir duplicados)
    const confSymptoms = this.symptomService.confSymptoms
      .map(({ confSymptom }: ConfSymptomInformation) => confSymptom)
      .filter(
        (confSymptom: ConfSymptom) => confSymptom.type === this.symptomType
      );

    //Elimina las configuraciones duplicadas
    const confSymptomUniques =
      this.symptomService.removeDuplicateObjectsById(confSymptoms);

    //Se ordena los sintomas segun la configuracion
    confSymptomUniques.sort(
      (a: ConfSymptom, b: ConfSymptom) => a.order - b.order
    );

    return confSymptomUniques;
  }

  /**
   * Builds occurrences associated with a given configuration symptom.
   * @param confSymptom The configuration symptom for which occurrences are to be built.
   * @returns A Promise that resolves to an array of Occurrency objects.
   */
  async buildOccurrencesByConfSymptom(
    confSymptom: ConfSymptom
  ): Promise<
    { occurrences: Occurrency[]; tableSymptoms: TableSymptom[] } | undefined
  > {
    const occurrences: Occurrency[] = [];
    const tableSymptoms: TableSymptom[] = [];

    //Valida si debe mostrar el día en la columna de ocurrencias (Si hay un día con el sintoma lo muestra)
    const isAtLeastOneDayInstanceAssociatedWithConfSymptom =
      this.isAtLeastOneDayInstanceAssociatedWithConfSymptom(confSymptom.id);

    if (!isAtLeastOneDayInstanceAssociatedWithConfSymptom) return undefined;

    for (let dayInstance of this.dayInstances) {
      const isAssociated = this.isConfSymptomAssociatedWithDayInstance(
        confSymptom.id,
        dayInstance.id
      );
      //Obtiene la ocurrencia original y verificada que tuvo el sintoma en el día
      const { symptomOccurrence, verifiedSymptomOccurrence } =
        await this.getSymptomOccurence(dayInstance, confSymptom);

      //Crea un arreglo con la ocurrencia original y verificada
      occurrences.push({
        key: `${confSymptom.id}${dayInstance.id}`,
        occurrency: symptomOccurrence?.occurrency ?? false,
        verifiedOccurrency: verifiedSymptomOccurrence?.occurrency ?? false,
        confSymptom,
        dayInstance: dayInstance,
        isAssociated,
      });

      if (!isAssociated) continue;

      //Obtiene la información del sintoma en el día actual
      const tableSymptom = await this.buildSyptomInformation(
        symptomOccurrence,
        verifiedSymptomOccurrence,
        dayInstance,
        confSymptom
      );

      tableSymptom && tableSymptoms.push(tableSymptom);
    }

    return { occurrences, tableSymptoms };
  }

  /**
   * Verifica si al menos una instancia de día está asociada con un síntoma configurado (ConfSymptom).
   * @param {string} confSymptomId - El ID del síntoma configurado que queremos verificar.
   * @returns {boolean} - Devuelve true si al menos una instancia de día está asociada con el síntoma configurado, de lo contrario devuelve false.
   */
  isAtLeastOneDayInstanceAssociatedWithConfSymptom(confSymptomId: string) {
    return this.dayInstances.some((dayInstance) => {
      return this.isConfSymptomAssociatedWithDayInstance(
        confSymptomId,
        dayInstance.id
      );
    });
  }

  /**
   * Comprueba si un síntoma configurado está asociado con una instancia de día específica.
   * @param confSymptomId El ID del síntoma configurado que se verificará si está asociado.
   * @param dayInstanceId El ID de la instancia de día con la que se comprobará la asociación.
   * @returns Un valor booleano que indica si el síntoma configurado está asociado con la instancia de día especificada.
   */
  isConfSymptomAssociatedWithDayInstance(
    confSymptomId: string,
    dayInstanceId: string
  ) {
    return this.symptomService.confSymptoms.some(
      ({ confSymptom, dayInstance }: ConfSymptomInformation) =>
        confSymptom.id === confSymptomId && dayInstance.id === dayInstanceId
    );
  }

  /**
   * Obtiene las ocurrencias de síntomas, tanto originales como verificadas, que ocurrieron en un día dado.
   * @param dayInstance El día para el que se desean obtener las ocurrencias de síntomas.
   * @param confSymptom La configuración de síntoma para la que se desean obtener las ocurrencias.
   * @returns Un objeto que contiene la ocurrencia original y la ocurrencia verificada del síntoma.
   */
  async getSymptomOccurence(
    dayInstance: DayInstance,
    confSymptom: ConfSymptom
  ): Promise<{
    symptomOccurrence: SymptomOccurrency;
    verifiedSymptomOccurrence: SymptomOccurrency;
  }> {
    //Obtiene la instancia verificada del día
    const verifiedDayInstance =
      await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
        dayInstance.id
      );

    //Obtiene las orrencias del día
    const daySymptomOccurrences = this.symptomService.buildSymptomOccurrences(
      dayInstance.id,
      dayInstance?.symptomOcurrencies ?? []
    );

    // Obtiene las occurencias verificadas del día
    const verifiedSymptomOccurences =
      this.symptomService.buildSymptomOccurrences(
        dayInstance.id,
        verifiedDayInstance?.symptomOcurrencies ?? []
      );

    //Busca la ocurrencia del sintoma en el día original
    const symptomOccurrence: SymptomOccurrency = daySymptomOccurrences.find(
      (symptomOccurrence: any) => {
        return symptomOccurrence.confSymptomId === confSymptom.id;
      }
    );

    //Busca la ocurrencia del sintoma en el día verificado
    const verifiedSymptomOccurrence: SymptomOccurrency =
      verifiedSymptomOccurences.find((symptomOccurrence: any) => {
        return symptomOccurrence.confSymptomId === confSymptom.id;
      });

    return { symptomOccurrence, verifiedSymptomOccurrence };
  }

  async buildSyptomInformation(
    symptomOccurrence: SymptomOccurrency,
    verifiedSymptomOccurrence: SymptomOccurrency,
    dayInstance: DayInstance,
    confSymptom: ConfSymptom
  ): Promise<TableSymptom | undefined> {
    const symptomInstanceId = symptomOccurrence?.symptomInstanceId;
    const verifiedSymptomId = verifiedSymptomOccurrence?.symptomInstanceId;

    //Si existe un sintoma creado por el sujeto
    if (symptomInstanceId && symptomOccurrence?.occurrency) {
      return this.buildSymptomInstance(
        symptomInstanceId,
        symptomOccurrence,
        verifiedSymptomOccurrence,
        confSymptom,
        dayInstance
      );
    } else {
      //Si la instancia no fue creada por el sujeto
      return this.buildVerifiedSymptomInstance(
        verifiedSymptomId,
        symptomOccurrence,
        verifiedSymptomOccurrence,
        confSymptom,
        dayInstance
      );
    }
  }

  async buildSymptomInstance(
    symptomInstanceId: string,
    symptomOccurrency: SymptomOccurrency,
    verifiedSymptomOccurrency: SymptomOccurrency,
    confSymptom: ConfSymptom,
    dayInstance: DayInstance
  ): Promise<TableSymptom | undefined> {
    const symptomInstance = this.symptomService.symptomInstances.find(
      (SI: SymptomInstance) => SI.id === symptomInstanceId
    );

    if (!symptomInstance?.id) return undefined; //Si no existe una instancia valida en la BD

    //Obtiene la instancia veridicada
    const verifiedSymptomInstance: VerifiedSymptomInstance | undefined =
      this.symptomService.getVerifiedSymptomInstanceBySymptomInstanceId(
        symptomInstance?.id
      );

    //Obtiene los recordsLogs asociados
    let symptomRecordLogs = this.symptomService.symptomRecordLogs.filter(
      (_symptomRecordlog: any) => {
        return _symptomRecordlog.symptomInstanceId === symptomInstanceId;
      }
    );

    symptomRecordLogs = await this.symptomService.fillSymptomRecordLogs(
      verifiedSymptomInstance?.id ?? '',
      symptomRecordLogs,
      dayInstance
    );

    //Construye la información de la tabla
    return this.buildTableSymptomInformation({
      symptomInstance,
      verifiedSymptomInstance,
      symptomOccurrency,
      verifiedSymptomOccurrency,
      dayInstance,
      confSymptom,
      symptomRecordLogs,
    });
  }

  async buildVerifiedSymptomInstance(
    verifiedSymptomId: string | undefined | null,
    symptomOccurrency: SymptomOccurrency,
    verifiedSymptomOccurrency: SymptomOccurrency,
    confSymptom: ConfSymptom,
    dayInstance: DayInstance
  ): Promise<TableSymptom | undefined> {
    const verifiedSymptomInstance =
      this.symptomService.getVerifiedSymptomInstancesById(verifiedSymptomId);

    //Obtiene el mock del sintoma
    const symptomInstance = this.symptomService.getSymptomInstanceEmpty(
      confSymptom,
      dayInstance.id
    );

    //Obtiene el mock del recordLog
    const symptomRecordLog = this.symptomService.getSymptomRecordLogEmpty(
      confSymptom,
      dayInstance.id
    );

    const symptomRecordLogs: any[] =
      await this.symptomService.fillSymptomRecordLogs(
        verifiedSymptomInstance?.id ?? '',
        [symptomRecordLog],
        dayInstance
      );

    return this.buildTableSymptomInformation({
      symptomInstance,
      verifiedSymptomInstance,
      symptomOccurrency,
      verifiedSymptomOccurrency,
      dayInstance,
      confSymptom,
      symptomRecordLogs,
    });
  }

  buildTableSymptomInformation({
    symptomInstance,
    verifiedSymptomInstance,
    symptomOccurrency,
    verifiedSymptomOccurrency,
    dayInstance,
    confSymptom,
    symptomRecordLogs,
  }: SymptomInformation): TableSymptom {
    //Obtiene las preguntas originales verificadas
    const intensityQuestionAnswers =
      symptomInstance?.intensityQuestionAnswers ?? [];

    //Obtiene las preguntas adiccionales verificadas
    const verifiedIntensityQuestionsAnswers =
      verifiedSymptomInstance?.intensityQuestionAnswers ?? [];

    return {
      key: `${confSymptom.id}${dayInstance.id}`,
      confSymptom,
      dayInstance,
      symptomType: confSymptom.type,
      dayName: dayInstance?.confDay?.dayName ?? '',
      confForm: dayInstance.confDay?.confForm,
      symptomOccurrency,
      verifiedSymptomOccurrency,
      symptomInstance,
      verifiedSymptomInstance,
      isEdition: !!verifiedSymptomInstance?.id,
      comments: {},
      intensityQuestions: this.symptomService.transformQuestions(
        intensityQuestionAnswers
      ),
      verifiedIntensityQuestions: this.symptomService.transformQuestions(
        verifiedIntensityQuestionsAnswers
      ),
      intensityQuestionAnswers: verifiedSymptomInstance?.id
        ? verifiedIntensityQuestionsAnswers
        : intensityQuestionAnswers,
      rememberNoValuesTaken: verifiedSymptomInstance?.id
        ? verifiedSymptomInstance?.rememberNoValuesTaken
        : symptomInstance?.rememberNoValuesTaken,
      symptomRecordLogs: symptomRecordLogs ?? [],
    };
  }

  /**
   * Maneja la selección de un síntoma.
   * Actualiza el tipo de síntoma en el servicio de síntomas, establece la ocurrencia seleccionada y busca el síntoma y el día asociado en la tabla de síntomas.
   * @param occurrency La ocurrencia seleccionada (SELECCIÓN DE UN SINTOMA DESDE EL HEADER).
   * @param symptom El síntoma de la tabla seleccionado (SELECCIÓN DE UN SINTOMA DESDE LA TABLA DE SINTOMAS).
   */
  selectedSymptom(
    activeAccordionTabIndex: number,
    occurrency: Occurrency | undefined,
    symptom: TableSymptom | undefined
  ): void {
    this.symptomService.symptomType = this.symptomType;

    //Obtiene el id de la configuracion del sintoma seleccionado
    const confSymptomId =
      occurrency?.confSymptom?.id ?? symptom?.confSymptom?.id;

    //Obtiene el id del dia del sintoma seleccionado
    const dayInstanceId =
      occurrency?.dayInstance?.id ?? symptom?.dayInstance?.id;

    //Busca el sintoma en la tabla de configuracion
    const findSymptomOccurence = this.symptomOcurrencesTable.find(
      (symptom: SymptomOccurrence) => symptom.id === confSymptomId
    );

    //Busca el dia asociado en la tabla de sintomas
    const currentSymptom = findSymptomOccurence?.tableSymptoms.find(
      (symptom: TableSymptom) => symptom.dayInstance.id === dayInstanceId
    );

    if (currentSymptom) {
      this.paginationService.selectedSymptom(
        currentSymptom,
        activeAccordionTabIndex,
        this.symptomType === SymptomType.LOCAL
          ? ConciliationComponents.SYMPTOM_LOCAL
          : ConciliationComponents.SYMPTOM_GENERAL
      );
    }
  }

  /**
   * Crea comentarios para los síntomas y los asigna a las instancias de síntomas en la tabla.
   */
  createAndAssignSymptomComments(): void {
    for (let symptomOccurrence of this.symptomOcurrencesTable) {
      const { comments, tableSymptoms } =
        this.symptomService.addSymptomInstanceComments(
          symptomOccurrence.tableSymptoms
        );
      symptomOccurrence.comments = comments;
      symptomOccurrence.tableSymptoms = tableSymptoms;
    }
  }
}
