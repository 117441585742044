import { Component, OnInit } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { SignatureComponent } from 'src/app/shared/components/Signature/signature.component';
import {
  ReportExecutionService,
  ReportPagination,
} from '../../services/report-executation.service';
import { ConfReport, IntensityType } from 'src/app/modules/report/report.types';
import {
  CreateSymptomInstanceInput,
  UpdateSymptomInstanceInput,
} from 'src/app/modules/subjects/subjects.types';
import { ConfSymptom } from 'src/app/modules/ediary/ediary.types';
const logger = new Logger('ReportExecutionSuccess');
@Component({
  selector: 'app-report-success',
  templateUrl: './report-success.component.html',
  styleUrls: ['./report-success.component.scss'],
})
export class ReportSuccessComponent implements OnInit {
  symptomInstances: any[] = [];
  confReport: ConfReport = {} as ConfReport;
  NUMERIC_VALUE: IntensityType = IntensityType.NUMERIC_VALUE;
  MULTIPLE_CHOICE: IntensityType = IntensityType.MULTIPLE_CHOICE;
  RANGE: IntensityType = IntensityType.RANGE;
  SYMPTOM_PAGE: ReportPagination = ReportPagination.SYMPTOM;
  FORM_PAGE: ReportPagination = ReportPagination.FORM;

  //Formularios
  score: number = 0;
  reportScoreLabel: string = '';
  showReportScore: boolean = false;
  showFormSummary: boolean = false;

  constructor(
    private reportExecutionService: ReportExecutionService,
    private trialpalService: TrialpalService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.confReport = this.reportExecutionService.confReport;
    this.score = this.reportExecutionService.reportInstance.score ?? 0;
    await this.buildSymptomInstancesSummary();
    this.buildDynamicFormValidation();
  }

  async buildSymptomInstancesSummary(): Promise<void> {
    const symptomOccurrences =
      this.reportExecutionService.reportInstance.symptomOcurrencies ?? [];

    for (const symptomOccurrency of symptomOccurrences) {
      const { confSymptomId, symptomInstanceId } = symptomOccurrency;
      const confSymptom = await this.getConfSymptomById(confSymptomId);
      const symptomInstance = await this.getSyptomInstanceById(
        symptomInstanceId ?? ''
      );

      //Guarda en un arreglo los datos importantes del sintoma
      this.symptomInstances.push({
        occurrency: symptomOccurrency?.occurrency ?? false,
        label: this.reportExecutionService.getSymptomLabel(confSymptom),
        confSymptom: confSymptom,
        intensityType: confSymptom.intensityType,
        symptomInstance: symptomInstance,
      });
    }
  }

  buildDynamicFormValidation() {
    this.showFormSummary = false;
    if (this.confReport.jsonForm?.length) {
      //Validaciones para mostrar el resumen de formularios
      const formConfiguration = this.getJsonConfiguration();
      this.reportScoreLabel = this.getReportScoreLabel(formConfiguration);
      this.showReportScore = this.hasGroupEvaluation(formConfiguration[0]);
      this.showFormSummary = true;
    }
  }

  getJsonConfiguration() {
    const confJSON = this.trialpalService.dictionaryPipe.transform(
      this.reportExecutionService.confReport?.jsonForm ?? '[]'
    );
    return JSON.parse(confJSON ?? '[]');
  }

  getReportScoreLabel(formConfiguration: any): string {
    if (formConfiguration[0]?.scoreLabel) {
      return this.trialpalService.dictionaryPipe.transform(
        formConfiguration[0].scoreLabel
      );
    }
    return this.trialpalService.translateService.instant(
      'report.labelJsonFormScore'
    );
  }

  hasGroupEvaluation(configuration: any): boolean {
    for (let key in configuration) {
      const value = configuration[key];
      if (typeof value === 'string' && value.includes('group_evaluation')) {
        return true;
      } else if (typeof value === 'object' && value !== null) {
        // Si la propiedad es un objeto, realiza una búsqueda recursiva
        if (this.hasGroupEvaluation(value)) return true;
      }
    }

    return false;
  }

  async getSyptomInstanceById(
    id: string
  ): Promise<CreateSymptomInstanceInput | UpdateSymptomInstanceInput> {
    return this.reportExecutionService.getSymptomInstanceById(id);
  }

  async getConfSymptomById(id: string): Promise<ConfSymptom> {
    return this.reportExecutionService.getConfSymptomById(id);
  }

  async completeReport(): Promise<void> {
    try {
      if (!this.confReport.isSignatureRequired) return this.sendReport();

      await this.completeReportWithSignature();
    } catch (error) {
      this.showErrorMessage(error);
    }
  }

  async completeReportWithSignature(): Promise<void> {
    const signatureComponentRef = await this.showSignatureComponent();
    signatureComponentRef.onClose.subscribe((isSignatureSuccess: boolean) => {
      if (isSignatureSuccess) {
        this.reportExecutionService.hideSpinner();
        this.showSignatureSuccessMessage();
        this.sendReport();
      }
    });
  }

  async sendReport(): Promise<void> {
    this.reportExecutionService
      .completeReport()
      .then(() => {
        this.showCompleteReportSuccessMessage();
        this.ref.close(true);
      })
      .catch((error: any) => this.showErrorMessage(error));
  }

  async showSignatureComponent(): Promise<DynamicDialogRef> {
    return this.dialogService.open(SignatureComponent, {
      closeOnEscape: true,
      dismissableMask: true,
      showHeader: false,
      styleClass: 'signature-modal',
      data: {
        title: this.trialpalService.translateService.instant('signature.title'),
        loadingText: this.trialpalService.translateService.instant(
          'signature.loadingSignature'
        ),
      },
    });
  }

  showSignatureSuccessMessage() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant('general.success'),
      detail: this.trialpalService.translateService.instant(
        'general.signedSuccess'
      ),
    });
  }

  showErrorMessage(error: any) {
    logger.error('resultReportError -->', error);
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.detail'
      ),
    });
  }

  showCompleteReportSuccessMessage() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'report.execution.messageCompleteReport.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'report.execution.messageCompleteReport.detail'
      ),
    });
  }

  goToPage(page: ReportPagination) {
    this.reportExecutionService.goToPage(page);
  }

  goToPreviousPage() {
    this.reportExecutionService.goToPreviousPage();
  }
}
