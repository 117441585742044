import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { Roles } from 'src/app/shared/global.variables';
import { InformedConsentSectionAddEditComponent } from './informed-consent-section-add-edit/informed-consent-section-add-edit.component';
import { InformedConsentSectionDetailComponent } from './informed-consent-section-detail/informed-consent-section-detail.component';

const routes: Routes = [
  {
    path: 'add',
    component: InformedConsentSectionAddEditComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin],
    },
  },
  {
    path: 'edit/:sectionId',
    component: InformedConsentSectionAddEditComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin],
    },
  },
  {
    path: ':sectionId',
    component: InformedConsentSectionDetailComponent,
  },
  {
    path: ':sectionId/question',
    loadChildren: () =>
      import(
        '../informed-consent-question/informed-consent-question.module'
      ).then((m) => m.InformedConsentQuestionModule),
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InformedConsentSectionRoutingModule {}
