import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API, Auth, graphqlOperation, Logger } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { TP2IdEntity } from 'src/app/services/trialpal.types';
import { INTEGRATIONS_API, Roles } from 'src/app/shared/global.variables';
import { RequestParamsBuilder } from 'src/app/shared/utils/requestParmasBuilder';
import { GetProjectQuery } from '../project/project.types';
import { AlertType } from '../report/report.types';
import { InstanceState } from '../roles/roles.types';
import { GetSubjectQuery } from '../subjects/subjects.types';
import { USERS_QUERIES } from './user.queries';
import {
  GetUserQuery,
  ListUsersQuery,
  listUsersSubjectsCustomQuery,
  ModelUserFilterInput,
  TP2UserInput,
  UserByEmailQuery,
  UserByLoginQuery,
} from './user.types';
const logger = new Logger('tp2-logger-userService');
@Injectable({
  providedIn: 'root',
})
export class UserService {
  groups: any[] = [];
  protected token = '';
  private paramsBuilder: RequestParamsBuilder = new RequestParamsBuilder('');
  constructor(
    private translateService: TranslateService,
    private auth: AuthService
  ) {
    Auth.currentSession()
      .then((response) => {
        this.token = response.getAccessToken().getJwtToken();
        this.paramsBuilder.updateToken(this.token);
      })
      .catch((error) => {
        logger.error('Get access token error:', error);
      });
  }

  getGroups(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('user.enums.groups');
    Object.keys(Roles).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  async getUserByLogin(login: string): Promise<any> {
    let { items }: any = await this.userByLogin(login);
    let user = null;
    if (items.length > 0) {
      user = items.find(
        (user: any) => !user._deleted && user.state !== InstanceState.DELETED
      );
    }
    return user;
  }

  async getGroupsAsync() {
    return new Promise((resolver, _reject) => {
      this.translateService.onLangChange.subscribe(() => {
        const data: any[] = [];
        const list = this.translateService.instant('user.enums.groups');
        Object.keys(Roles).forEach((e) => {
          data.push({ name: list[e], value: e });
        });
        resolver(data);
      });
    });
  }

  async getSubjectUserByProject(projectId: string): Promise<any[]> {
    if (this.auth.isInvestigator()) {
      const usersFromProject = await this.getUserByProject(projectId);

      const userSites = this.auth.getUserSites();

      const filteredUsers = usersFromProject.filter((user: any) =>
        userSites.includes(user.sites)
      );

      return filteredUsers;
    }

    return [];
  }

  async listUserUsers(
    projectId: string | null,
    hasUserListPermission = false
  ): Promise<any[]> {
    let users: any[] = [];

    if (this.auth.isAdmin()) {
      users = await this.getAdminFilteredUsers(projectId);
    } else if (this.auth.isInvestigator() || hasUserListPermission) {
      users = await this.getCoordinatorInvestigatorFilteredUsers(projectId);
    }

    // Remove duplicates based on 'id'
    const uniqueUsers = this.removeDuplicateUsers(users);

    return Promise.resolve(uniqueUsers);
  }

  private async getAdminFilteredUsers(
    projectId: string | null
  ): Promise<any[]> {
    let filter: ModelUserFilterInput;

    if (projectId) {
      filter = {
        projects: { contains: projectId },
        role: { ne: Roles.Subject },
      };
    } else {
      filter = { role: { ne: Roles.Subject } };
    }

    const users = await this.listUsers(filter);
    return users ? users.filter((x) => (x ? !x._deleted : false)) : [];
  }

  async getAllUsersByProject(projectId: string | null): Promise<any[]> {
    if (!projectId) return [];

    let filter: ModelUserFilterInput;

    filter = {
      projects: { contains: projectId },
      role: { ne: Roles.Admin },
    };

    const users = await this.listUsers(filter);
    return users ? users.filter((x) => (x ? !x._deleted : false)) : [];
  }

  private async getCoordinatorInvestigatorFilteredUsers(
    projectId: string | null
  ): Promise<any[]> {
    let users: any[] = [];

    for (const authSite of this.auth.getUserSites()) {
      const filter: ModelUserFilterInput = {
        role: { notContains: Roles.Admin },
        projects: { contains: projectId },
        sites: { contains: authSite },
      };

      const siteUsers = await this.listUsers(filter);

      if (siteUsers) {
        users = users.concat(
          siteUsers.filter((x) => (x ? !x._deleted : false))
        );
      }
    }

    return users;
  }

  private removeDuplicateUsers(users: any[]): any[] {
    const uniqueUsers = new Map<string, any>();

    for (const user of users) {
      uniqueUsers.set(user.id, user);
    }

    return Array.from(uniqueUsers.values());
  }

  async GetUser(id: string): Promise<GetUserQuery> {
    const gqlAPIServiceArguments: any = {
      id,
    };
    const response = (await API.graphql(
      graphqlOperation(USERS_QUERIES.GetUser, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }

  async getUserByProject(projectId: string, filter = {}): Promise<any[]> {
    const gqlAPIServiceArguments: any = {
      projects: projectId,
      filter: filter || {},
      limit: 10000,
    };

    let instances: any[] = [];

    do {
      const listQuery = await this.performGraphQLQuery(
        USERS_QUERIES.userByProject,
        gqlAPIServiceArguments
      );
      instances = instances.concat(listQuery?.data?.userByProject?.items);
      gqlAPIServiceArguments.nextToken =
        listQuery.data?.userByProject.nextToken;
    } while (gqlAPIServiceArguments.nextToken);

    return instances.filter(Boolean).filter((s: any) => !s._deleted);
  }

  private async performGraphQLQuery(query: any, args: any): Promise<any> {
    return (await API.graphql(graphqlOperation(query, args))) as any;
  }

  async listUsers(filter: any): Promise<any[]> {
    let instances: any[] = [];
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    gqlAPIServiceArguments.limit = 10000;
    let listQuery = (await API.graphql(
      graphqlOperation(USERS_QUERIES.ListUsers, gqlAPIServiceArguments)
    )) as any;
    instances = listQuery?.data?.listUsers?.items;
    let nextToken = listQuery.data?.listUsers.nextToken;
    while (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
      listQuery = (await API.graphql(
        graphqlOperation(USERS_QUERIES.ListUsers, gqlAPIServiceArguments)
      )) as any;
      instances = instances.concat(listQuery?.data?.listUsers?.items);
      nextToken = listQuery.data?.listUsers.nextToken;
    }
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances;
  }

  async getProject(id: string): Promise<GetProjectQuery> {
    const gqlAPIServiceArguments: any = {
      id,
    };
    const response = (await API.graphql(
      graphqlOperation(USERS_QUERIES.GetProject, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectQuery>response.data.getProject;
  }
  updateUser(_login: string, _user: any): Promise<any> {
    return Promise.resolve('OK');
  }
  async userByLogin(login: string): Promise<UserByLoginQuery> {
    const gqlAPIServiceArguments: any = {
      login,
    };
    const response = (await API.graphql(
      graphqlOperation(USERS_QUERIES.UserByLogin, gqlAPIServiceArguments)
    )) as any;
    return <UserByLoginQuery>response.data.userByLogin;
  }
  async userByEmail(email: string): Promise<UserByEmailQuery> {
    const gqlAPIServiceArguments: any = {
      email,
    };
    const response = (await API.graphql(
      graphqlOperation(USERS_QUERIES.UserByEmail, gqlAPIServiceArguments)
    )) as any;
    return <UserByEmailQuery>response.data.userByEmail;
  }
  async getSubject(id: string): Promise<any> {
    const gqlAPIServiceArguments: any = {
      id,
    };
    const response = (await API.graphql(
      graphqlOperation(USERS_QUERIES.GetSubject, gqlAPIServiceArguments)
    )) as any;
    return <GetSubjectQuery>response.data.getSubject;
  }
  async createUser(
    user: TP2UserInput,
    projects: TP2IdEntity[],
    sites: TP2IdEntity[],
    subjects: TP2IdEntity[],
    roles: TP2IdEntity[]
  ): Promise<string | null> {
    if (!user.phoneNumber) {
      user.phoneNumber = null;
    }
    user._lastUser = this.auth.getUsername() || user?._lastUser;
    const params = await this.paramsBuilder
      .withBody({ user, projects, sites, subjects, roles })
      .build();
    return API.post(INTEGRATIONS_API, '/users/create', params);
  }

  async updateMFAByProject(projectId: string, currentIsMFAActivated: boolean) {
    const params = await this.paramsBuilder
      .withBody({
        user: {},
        projects: [],
        undefined: undefined,
        subjects: undefined,
        roles: undefined,
        mfaConfiguration: {
          projectId,
          mfaActive: currentIsMFAActivated,
          batchSize: 100,
          retryCount: 10,
        },
      })
      .build();
    return API.post(INTEGRATIONS_API, '/users/mfa', params);
  }

  async updateUserPermissionsByRole(roleId: string) {
    const params = await this.paramsBuilder
      .withBody({
        user: {},
        projects: [],
        undefined: undefined,
        subjects: undefined,
        roles: undefined,
        permissionsConfiguration: {
          roleId,
          batchSize: 100,
          retryCount: 10,
        },
      })
      .build();
    return API.post(INTEGRATIONS_API, '/users/permissions', params);
  }

  async editUser(
    user: TP2UserInput,
    projects: TP2IdEntity[],
    sites: TP2IdEntity[],
    subjects: TP2IdEntity[],
    roles: TP2IdEntity[]
  ): Promise<string | null> {
    if (!user.phoneNumber) {
      user.phoneNumber = null;
    }
    user._lastUser = this.auth.getUsername() || user?._lastUser;
    const params = await this.paramsBuilder
      .withBody({ user, projects, sites, subjects, roles })
      .build();
    return API.post(INTEGRATIONS_API, '/users/update', params);
  }

  async deleteUser(user: TP2UserInput): Promise<string | null> {
    user._lastUser = this.auth.getUsername() || user?._lastUser;
    const params = await this.paramsBuilder.withBody({ user }).build();
    return API.post(INTEGRATIONS_API, '/users/delete', params);
  }

  async disabledUser(user: TP2UserInput): Promise<string | null> {
    user._lastUser = this.auth.getUsername() || user?._lastUser;
    const params = await this.paramsBuilder.withBody({ user }).build();
    return API.post(INTEGRATIONS_API, '/users/disable', params);
  }

  async enabledUser(user: TP2UserInput): Promise<string | null> {
    user._lastUser = this.auth.getUsername() || user?._lastUser;
    const params = await this.paramsBuilder.withBody({ user }).build();
    return API.post(INTEGRATIONS_API, '/users/enable', params);
  }
  async changePassword(user: TP2UserInput): Promise<string | null> {
    const params = await this.paramsBuilder.withBody({ user }).build();
    return API.post(INTEGRATIONS_API, '/users/changePassword', params);
  }

  async listUsersSubject(idSubject: string): Promise<any> {
    const gqlAPIServiceArguments: any = {};
    if (idSubject) {
      gqlAPIServiceArguments.idSubject = idSubject;
    }
    gqlAPIServiceArguments.limit = 10000;
    let instances: any[] = [];
    const response = (await API.graphql(
      graphqlOperation(
        USERS_QUERIES.listUsersSubjectsCustom,
        gqlAPIServiceArguments
      )
    )) as any;
    let listQuery = <listUsersSubjectsCustomQuery>response.data.listUsers;
    instances = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
      const res = (await API.graphql(
        graphqlOperation(
          USERS_QUERIES.listUsersSubjectsCustom,
          gqlAPIServiceArguments
        )
      )) as any;
      let listQuery = <listUsersSubjectsCustomQuery>res.data.listUsers;
      instances = instances.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances;
  }

  listUserByRoleLoginEmail(
    role: string,
    login: string,
    email: string
  ): Promise<ListUsersQuery> {
    const filter: ModelUserFilterInput = {
      or: [
        {
          and: [
            {
              role: {
                eq: role,
              },
            },
            {
              email: {
                eq: email,
              },
            },
          ],
        },
        {
          and: [
            {
              role: {
                eq: role,
              },
            },
            {
              login: {
                eq: login,
              },
            },
          ],
        },
      ],
    };
    return this.listUsers(filter) as any;
  }

  /**
   * @desc metodo para validar y retornar el valor de los roles asignados al usuario
   * @param roles string separado por comas de los roles del usuario
   * @returns enum unido de los roles asigandos
   */
  getEnumsRolesUser(roles: string): string {
    let splitRole = roles.split(',');
    let arrRole: string[] = [];
    if (splitRole) {
      for (const role of splitRole) {
        const roleUser = this.translateService.instant(
          'user.enums.groups.' + role
        );
        arrRole.push(roleUser);
      }
    }
    return arrRole.join(', ');
  }

  getAllActivatedRoles() {
    const allActiveRoles: any = [];
    const list = this.translateService.instant('user.enums.groups');

    Object.keys(Roles)
      .filter((rol: any) => rol !== Roles.Consented)
      .forEach((rol) => {
        if (rol === Roles.Conciliator) {
          allActiveRoles.push({ name: list[rol], value: rol, inactive: true });
        } else {
          allActiveRoles.push({ name: list[rol], value: rol, inactive: false });
        }
      });

    if (this.auth.isAdmin()) {
      const index = allActiveRoles.findIndex(
        (x: any) => x.value === Roles.Subject
      );
      allActiveRoles.splice(index, 1);
    } else if (this.auth.isInvestigator()) {
      const index = allActiveRoles.findIndex(
        (x: any) => x.value === Roles.Admin
      );
      allActiveRoles.splice(index, 1);
    }
    return allActiveRoles;
  }
  getAlertTypes(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService
        .get('ediary.enums.alertTypes')
        .subscribe((translate) => {
          const data: any[] = [];
          Object.keys(AlertType).forEach((e) => {
            data.push({ name: translate[e], value: e });
          });
          resolve(data);
        });
    });
  }
}
