import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/appconfigservice';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InformedConsentState } from '../../informed-consent.types';

@Component({
  selector: 'app-informed-consent-follow-up-diagram',
  templateUrl: './informed-consent-follow-up-diagram.component.html',
  styleUrls: ['./informed-consent-follow-up-diagram.component.scss'],
})
export class InformedConsentFollowUpDiagramComponent
  implements OnChanges, OnInit
{
  @Input() informedConsentInstances = [];

  data: any;

  chartOptions: any;

  subscription?: Subscription;

  config?: any;

  informedConsentStates: any = [];

  showDiagram = false;

  constructor(
    private configService: AppConfigService,
    private trialpalService: TrialpalService
  ) {}

  ngOnInit() {
    this.informedConsentStates = Object.keys(InformedConsentState)
      .map((state) => {
        return {
          label: state,
          value: this.trialpalService.translateService.instant(
            'informedConsent.enums.states.' + state
          ),
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
  }

  async ngOnChanges() {
    const data: number[] = [];
    await this.informedConsentStates.forEach((state: any) =>
      data.push(
        this.informedConsentInstances.filter(
          (instance: any) => instance.state === state.label
        ).length
      )
    );
    this.data = {
      labels: this.informedConsentStates.map((state: any) => state.value),
      datasets: [
        {
          data: data,
          backgroundColor: [
            'grey',
            'red',
            'orange',
            'green',
            '#33B8FF',
            '#33DDFF',
          ],
          hoverBackgroundColor: ['#FF6BFF'],
        },
      ],
    };
    if (data.length > 0 && data.find((e) => e > 0)) {
      this.showDiagram = true;
    }
  }
}
