<main class="onBoarding">
  <header class="onBoarding-header">
    <img
      class="onBoarding-icon"
      src="./assets/icons/on-boarding/idea.svg"
      alt="idea"
      srcset=""
    />
    <h2 class="title">{{ title }}</h2>
  </header>
  <section class="onBoarding-container">
    <ol class="onBoarding-list">
      <li *ngFor="let step of steps; let i = index">
        <span class="onBoarding-list-type">{{ i + 1 }}.</span>
        <span class="onBoarding-list-text">{{ step }}</span>
      </li>
    </ol>
  </section>
  <footer>
    <button
      id="btn_continue"
      name="btn_continue"
      class="btn btn-primary btn-onBoarding"
      pButton
      [label]="btnText"
      type="button"
      (click)="onContinue()"
    ></button>
  </footer>
</main>
