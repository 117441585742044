import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-multicheckbox',
  templateUrl: './formly-multicheckbox.component.html',
})
export class FormlyMulticheckboxComponent extends FieldType<FieldTypeConfig> {
  uniqueId: number = Math.floor(Math.random() * Date.now());
}
