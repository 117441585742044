import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import {
  ButtonItem,
  Tp2Card,
  Tp2CardContent,
} from 'src/app/shared/global.variables';
import { InformedConsentService } from '../../informed-consent.service';
import {
  ConfICQuestion,
  UpdateConfICQuestionInput,
} from '../../informed-consent.types';
import { ConfState } from 'src/app/services/trialpal.types';
const logger = new Logger('tp2-logger-listInformedConsentQuestionsPage');

@Component({
  selector: 'app-informed-consent-question-list',
  templateUrl: './informed-consent-question-list.component.html',
  styleUrls: ['./informed-consent-question-list.component.scss'],
})
export class InformedConsentQuestionListComponent implements OnInit {
  confICSectionId = '';
  confICQuestions: ConfICQuestion[] = [];
  confICQuestionCards: Tp2Card[] = [];
  INFORMED_CONSENT_QUESTION_ENTITY = 'informedConsent.question.question';
  confICQuestionSelected: any = {};
  showDisplayDeleteComponent: boolean = false;

  constructor(
    private readonly informedConsentService: InformedConsentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly trialpalService: TrialpalService
  ) {}

  async ngOnInit() {
    this.trialpalService.showSpinner(
      'informedConsent.question.questions',
      'LIST'
    );
    this.confICSectionId = this.route.snapshot.params.sectionId;
    if (this.confICSectionId) {
      await this.getConfICQuestions();

      this.buildTPCardData();
    } else {
      this.router.navigate(['../..'], {
        relativeTo: this.route,
      });
    }
    this.trialpalService.hideSpinner();
  }

  async getConfICQuestions() {
    this.confICQuestions =
      await this.informedConsentService.getConfICQuestionByConfICSectionId(
        this.confICSectionId
      );
  }

  onCreate(): void {
    this.router.navigate(['question', 'add'], {
      relativeTo: this.route,
    });
  }

  buildTPCardData() {
    const cards: Tp2Card[] = [];

    this.confICQuestions.sort((a, b) => a.order - b.order);

    for (let confIcQuestion of this.confICQuestions) {
      const buttonsItems: ButtonItem[] = [
        {
          color: '#205163',
          command: () => this.onEdit(confIcQuestion),
          icon: 'pi pi-pencil',
          tooltip: '',
          disabled: false,
        },
        {
          color: '#E41E1E',
          command: () => {
            this.confICQuestionSelected = confIcQuestion;
            this.showDisplayDeleteComponent = true;
          },
          icon: 'pi pi-trash',
          tooltip: '',
          disabled: false,
        },
      ];

      const content: Tp2CardContent[] = [
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.sections.sectionOrder'
          )}:`,
          description: `${confIcQuestion.order}`,
          icon: 'pi pi-sort',
        },
      ];

      cards.push({
        id: confIcQuestion.id,
        textHeader: this.trialpalService.dictionaryPipe.transform(
          confIcQuestion.description
        ),
        data: confIcQuestion,
        buttonItems: buttonsItems,
        content: content,
        isButtonsEnabled: true,
        isMenuEnabled: false,
        menu: [],
      });
    }

    this.confICQuestionCards = cards;
  }

  onEdit(item: any): void {
    this.router.navigate(['question', 'edit', item.id], {
      relativeTo: this.route,
    });
  }

  async onDelete(): Promise<void> {
    try {
      this.trialpalService.showSpinner(
        this.INFORMED_CONSENT_QUESTION_ENTITY,
        'DELETE'
      );
      const confICQuestion = this.confICQuestionSelected;

      let input: UpdateConfICQuestionInput = {
        id: confICQuestion.id,
        state: ConfState.DELETED,
        _version: confICQuestion._version,
      };
      this.modalChangeReason(input)
        .then(async (input: any) => {
          await this.informedConsentService.updateConfICQuestion(
            input,
            confICQuestion._version
          );
          //Borra las respuestas asociadas a la pregunta
          await this.informedConsentService.deleteInstancesAssociatedWithConfICQuestionId(
            confICQuestion.id,
            input._changeReason
          );

          this.confICQuestionCards = this.confICQuestionCards.filter(
            (confICQuestionCard: any) =>
              confICQuestionCard.id !== confICQuestion.id
          );

          this.trialpalService.showMutationSuccess(
            this.INFORMED_CONSENT_QUESTION_ENTITY,
            'DELETE'
          );
          this.trialpalService.hideSpinner();
        })
        .catch(() => this.trialpalService.hideSpinner());
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error('deleteQuestion error', error);
      this.trialpalService.showServiceError(
        this.INFORMED_CONSENT_QUESTION_ENTITY,
        error
      );
    }
  }
  async modalChangeReason(input: any) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'informedConsent.audit.updatingQuestion'
        ) +
        ': ' +
        this.trialpalService.dictionaryPipe.transform(input.name ?? ''),
      width: '70%',
    });
    return new Promise((resolve, reject) => {
      ref.onClose.subscribe({
        next: (data: string) => {
          if (data) {
            input._changeReason = data;
            resolve(input);
          } else {
            reject(new Error('No reason'));
            this.trialpalService.hideSpinner();
          }
        },
      });
    });
  }
}
