import { TP2Permission } from 'src/app/services/user-permissions-service';
import { ProjectTermsAndConditionsInput } from '../project/project.types';
import { AlertType, ReportAvailableUserType } from '../report/report.types';
import {
  ModelBooleanInput,
  ModelIDInput,
  ModelStringInput,
} from 'src/app/services/trialpal.types';
import { ModelAlertTypeInput } from '../ediary/ediary.types';

export type GetUserQuery = {
  __typename: 'User';
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<{
    __typename: 'TermsAndConditions';
    termsAndConditionsVersion: string;
    policiesVersion: string;
    acceptanceDate: string;
  } | null> | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListUsersQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type listUsersSubjectsCustomQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    state: UserState;
    phoneNumber?: string | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type TP2UserInput = {
  login: string;
  name?: string | null;
  email?: string | null;
  role?: string | null;
  phoneNumber?: string | null;
  state?: UserState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  notificationPreference?: string | null;
};

export type UserByLoginQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    notificationPreference?: AlertType | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type User = {
  __typename: 'User';
  id: string;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<TermsAndConditions | null> | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type TermsAndConditions = {
  __typename: 'TermsAndConditions';
  termsAndConditionsVersion: string;
  policiesVersion: string;
  acceptanceDate: string;
};
export type UpdateUserInput = {
  id: string;
  login?: string | null;
  name?: string | null;
  email?: string | null;
  role?: string | null;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state?: UserState | null;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<ProjectTermsAndConditionsInput | null> | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  login?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelStringInput | null;
  roles?: ModelStringInput | null;
  permissions?: ModelTP2PermissionListInput | null;
  state?: ModelUserStateInput | null;
  phoneNumber?: ModelStringInput | null;
  firebaseToken?: ModelStringInput | null;
  isMFAActivated?: ModelBooleanInput | null;
  notificationPreference?: ModelAlertTypeInput | null;
  subjects?: ModelStringInput | null;
  sites?: ModelStringInput | null;
  projects?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};
export type ModelTP2PermissionListInput = {
  eq?: Array<TP2Permission | null> | null;
  ne?: Array<TP2Permission | null> | null;
  contains?: TP2Permission | null;
  notContains?: TP2Permission | null;
};

export type ModelUserStateInput = {
  eq?: UserState | null;
  ne?: UserState | null;
};
export type UserByEmailQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type CreateUserInput = {
  id?: string | null;
  login: string;
  name?: string | null;
  email: string;
  role: string;
  roles?: Array<string | null> | null;
  permissions?: Array<TP2Permission | null> | null;
  state: UserState;
  phoneNumber?: string | null;
  firebaseToken?: string | null;
  isMFAActivated?: boolean | null;
  termsAndConditions?: Array<TermsAndConditionsInput | null> | null;
  notificationPreference?: AlertType | null;
  subjects?: string | null;
  sites?: string | null;
  projects?: string | null;
  appVersion?: string | null;
  lastTimezone?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type TermsAndConditionsInput = {
  termsAndConditionsVersion: string;
  policiesVersion: string;
  acceptanceDate: string;
};
export type UserEntityTypeInput = {
  username: string;
  userType: ReportAvailableUserType;
};
export type UserEntityType = {
  __typename: 'UserEntityType';
  username: string;
  userType: ReportAvailableUserType;
};
export enum UserState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
