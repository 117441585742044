<form id="form" class="form" name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="p-fluid p-form-grid p-grid">
    <div class="p-col-12 p-field p-md-6" style="padding-right: 2rem">
      <label>
        {{ 'roles.labelRoleName' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <input
        id="rolName"
        name="roleName"
        type="text"
        pInputText
        [(ngModel)]="role.name"
        [required]="true"
        #name="ngModel"
      />
      <small
        class="form-error"
        *ngIf="!name.valid && (f.submitted || name.touched)"
      >
        {{ 'general.required' | translate }}</small
      >
    </div>

    <div class="p-col-12 p-field p-md-6" style="padding-left: 2rem">
      <label>
        {{ 'roles.labelIsActive' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <div style="padding: 0; width: max-content">
        <p-selectButton
          name="isEnable"
          id="isEnable"
          #isEnable="ngModel"
          [(ngModel)]="role.isEnabled"
          [options]="booleanOptions"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-selectButton>
      </div>
      <small
        class="form-error"
        *ngIf="!isEnable.valid && (f.submitted || isEnable.touched)"
      >
        {{ 'general.required' | translate }}</small
      >
    </div>
  </div>

  <div style="padding: 0.5rem">
    <p-pickList
      [source]="TP2Permissions"
      [target]="role?.permissions || []"
      sourceHeader="{{ 'roles.labelPermissionsToAssign' | translate }}"
      targetHeader="{{ 'roles.labelPermissionsAssigned' | translate }} *"
      [responsive]="true"
      [sourceStyle]="{ height: '30rem' }"
      [targetStyle]="{ height: '30rem' }"
      filterBy="name"
      [showSourceControls]="false"
      [showTargetControls]="false"
    >
      <ng-template let-product pTemplate="item">
        <div class="product-item" style="height: 5px">
          <h4 class="">{{ product.name }}</h4>
        </div>
      </ng-template>
    </p-pickList>
    <small
      class="form-error p-text-right"
      *ngIf="f.submitted && role?.permissions?.length === 0"
    >
      {{ 'general.required' | translate }}</small
    >
  </div>

  <br />

  <div class="p-grid p-formgrid p-fluid">
    <div class="p-col-12 p-md-6 p-field">
      <label>
        {{ 'roles.labelRoleForProject' | translate }}
      </label>
      <div style="padding: 0; width: max-content">
        <p-selectButton
          name="isForProject"
          id="input_isForProject"
          #input_isForProject="ngModel"
          [(ngModel)]="role.isForProjects"
          [options]="booleanOptions"
          [disabled]="projectId !== ''"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>
    </div>

    <div
      class="p-field p-col-12 p-md-6"
      *ngIf="role.isForProjects"
      style="padding-left: 2rem"
    >
      <label>
        {{ 'roles.labelProjects' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-multiSelect
        appendTo="body"
        class="input multiSelect"
        name="proyectos"
        [options]="projects"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        [(ngModel)]="role.projects"
        #projectsInput="ngModel"
        [filter]="true"
        filterBy="label"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        [maxSelectedLabels]="2"
        selectedItemsLabel="{2}
          {{ 'user.labelSelectedProjects' | translate }}
        "
        [disabled]="projectId !== ''"
        required
      >
        <ng-template let-project pTemplate="item">
          <div>
            <div pTooltip="{{ project.label }}">
              {{ project.label | shortText: 100 }}
            </div>
          </div>
        </ng-template>
      </p-multiSelect>
      <small
        class="form-error"
        *ngIf="
          !projectsInput.valid &&
          (f.submitted || projectsInput.touched) &&
          projectId === ''
        "
      >
        {{ 'general.required' | translate }}</small
      >
    </div>

    <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
      <button
        pButton
        label="{{ 'user.buttonCancelCreateUser' | translate }}"
        type="button"
        (click)="onCancelar()"
        class="p-button-secondary"
      ></button>
    </div>

    <div class="p-field p-col-12 p-sm-4 p-md-3">
      <button
        id="btnSend"
        pButton
        label="{{
          (isEdit ? 'roles.h1EditRole' : 'roles.h1CreateRole') | translate
        }}"
        type="submit"
      ></button>
    </div>
  </div>
</form>
