import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';

@Component({
  selector: 'app-change-reason',
  templateUrl: './change-reason.component.html',
  styleUrls: ['./change-reason.component.scss'],
})
export class ChangeReasonComponent implements OnInit {
  reasonsKeys = [
    { value: 'typingError', label: 'general.changeReasons.typingError' },
    {
      value: 'configurationUpdate',
      label: 'general.changeReasons.configurationUpdate',
    },
    { value: 'other', label: 'general.changeReasons.other' },
  ];
  reasonsItems: SelectItem[] = [];
  otherReason = '';
  selectedReason = '';
  constructor(
    private trialpalService: TrialpalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    for (const reason of this.reasonsKeys) {
      this.reasonsItems.push({
        value: reason.value,
        label: this.trialpalService.translateService.instant(reason.label),
      });
    }
  }
  onSubmit(form: NgForm): void {
    if (this.selectedReason === 'other') {
      this.otherReason = this.otherReason.trim();
      this.trialpalService.validateSpaces(this.otherReason, 'otra', form);
    }

    if (form.valid) {
      const lastReason =
        this.selectedReason === 'other'
          ? this.otherReason
          : this.selectedReason;
      this.ref.close(lastReason);
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  onCancel(): void {
    this.trialpalService.hideSpinner();
    this.ref.close(null);
  }
}
