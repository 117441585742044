import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EdiaryReportStatusComponent } from 'src/app/modules/ediary/ediary-report-status/ediary-report-status.component';
import { GeneralConfigurationComponent } from './general-configuration/general-configuration.component';
import { AuthGuard } from 'src/app/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'status',
    component: EdiaryReportStatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'status/:projectId',
    component: EdiaryReportStatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':projectId',
    component: GeneralConfigurationComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EdiaryRoutingModule {}
