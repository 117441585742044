<div [ngClass]="{ 'container-tp2': !isModal }">
  <h1 *ngIf="!isModal">
    {{ 'project.changeState' | translate }}
  </h1>

  <form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="p-col-12 p-md-6 p-field">
      <label>
        {{ 'project.changeState' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-dropdown
        ngDefaultControl
        [showClear]="true"
        class="input"
        name="groups"
        [options]="optionStateProject"
        [(ngModel)]="project.state"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        [required]="true"
        (onChange)="change($event)"
        #grupos="ngModel"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
      >
      </p-dropdown>
      <div class="p-mt-3" *ngIf="valueNewState === 'DENIED'">
        <small class="form-error">{{
          'project.messageStateNot' | translate
        }}</small>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-grid p-mt-2 p-col-12" *ngIf="isModal">
        <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
          <button
            class="p-button-outlined p-button-secondary"
            pButton
            label="{{ 'project.buttonCancel' | translate }}"
            type="button"
            (click)="onCancelar()"
          ></button>
        </div>
        <div class="p-field p-col-12 p-sm-4 p-md-3">
          <button
            pButton
            label="
            {{
              (editMode ? 'project.buttonUpdate' : 'project.buttonAdd')
                | translate
            }}"
            type="submit"
          ></button>
        </div>
      </div>
    </div>

    <section>
      <div class="" *ngIf="valueNewState === 'IN_PROGRESS'">
        <div>
          <h2>{{ 'project.messageInform' | translate }}</h2>
        </div>
        <div>
          <label>{{ 'project.messageInProgress1' | translate }}</label>
        </div>
        <div>
          <label>{{ 'project.messageInProgress2' | translate }}</label>
        </div>
        <div>
          <label>{{ 'project.messageInProgress3' | translate }}</label>
        </div>
      </div>
      <div
        class=""
        *ngIf="
          valueNewState === 'SUSPENDED' ||
          valueNewState === 'ARCHIVED' ||
          valueNewState === 'ENDED'
        "
      >
        <div>
          <h2>{{ 'project.messageInform' | translate }}</h2>
        </div>
        <div>
          <label>{{ 'project.messageSuspended1' | translate }}</label>
        </div>
        <div>
          <label>{{ 'project.messageSuspended3' | translate }}</label>
        </div>
      </div>
    </section>
  </form>
</div>
