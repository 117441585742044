import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { Roles } from 'src/app/shared/global.variables';
import { InformedConsentAddEditComponent } from './informed-consent-add-edit/informed-consent-add-edit.component';
import { InformedConsentDetailComponent } from './informed-consent-detail/informed-consent-detail.component';
import { InformedConsentListComponent } from './informed-consent-list/informed-consent-list.component';

const roles = [Roles.Admin, Roles.Investigator, Roles.Reader];

const routes: Routes = [
  {
    path: ':projectId',
    component: InformedConsentListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':projectId/add',
    component: InformedConsentAddEditComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin],
    },
  },
  {
    path: ':projectId/edit/:id',
    component: InformedConsentAddEditComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin],
    },
  },
  {
    path: ':projectId/detail/:confInformedConsentId',
    component: InformedConsentDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':projectId/detail/:confInformedConsentId/section',
    loadChildren: () =>
      import(
        '../informed-consent/informed-consent-section/informed-consent-section.module'
      ).then((m) => m.InformedConsentSectionModule),
    canActivate: [AuthGuard],
    data: {
      roles: roles,
    },
  },
  {
    path: ':projectId/follow-up',
    loadChildren: () =>
      import(
        '../informed-consent/informed-consent-follow-up/informed-consent-follow-up.module'
      ).then((m) => m.InformedConsentFollowUpModule),
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InformedConsentRoutingModule {}
