<div class="dictionary">
  <div style="width: 100%">
    <p-editor
      [id]="id"
      [name]="name"
      #dictionaryInputEditor="ngModel"
      [required]="isRequired"
      [readonly]="isReadOnly"
      [(ngModel)]="value"
      (onTextChange)="onTextChangedDebounced()"
      (onSelectionChange)="emitChanges()"
      [style]="{ height: '200px' }"
    ></p-editor>
    <small
      class="form-error"
      *ngIf="
        dictionaryInputEditor.invalid &&
        (submitted || dictionaryInputEditor.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <button
    [disabled]="isReadOnly"
    id="btn_dictionaryButton"
    name="btn_dictionaryButton"
    type="button"
    pButton
    pRipple
    icon="pi pi-book"
    (click)="onDictionary(true)"
    class="
      p-button-rounded
      p-button-secondary
      p-button-outlined
      p-ml-2
      p-ripple
      p-button
      p-component
      p-button-icon-only
    "
    title="{{ 'dictionary.labelButton' | translate }}"
  ></button>
</div>
