<div class="p-inputAutocompleteButton">
  <p-autoComplete
    [disabled]="isReadOnly"
    class="p-flex-1"
    [ngStyle]="{ width: '100%' }"
    [id]="id"
    [name]="name"
    type="text"
    [(ngModel)]="value"
    [suggestions]="results"
    [required]="isRequired"
    (input)="emitChanges()"
    (completeMethod)="search($event)"
    (onSelect)="selectKeyLanguage($event); emitChanges()"
    [placeholder]="placeholder"
    [pTooltip]="tooltip"
    #dictionaryInputText="ngModel"
  >
  </p-autoComplete>
  <button
    [disabled]="isReadOnly"
    id="dictionaryButtonInformedConsent"
    name="dictionaryButtonInformedConsent"
    type="button"
    pButton
    pRipple
    icon="pi pi-book"
    (click)="onDictionaty()"
    class="
      p-button-rounded
      p-button-secondary
      p-button-outlined
      p-ml-2
      p-ripple
      p-button
      p-component
      p-button-icon-only
    "
    title="{{ 'dictionary.labelButton' | translate }}"
  ></button>
</div>
<small
  class="form-error"
  *ngIf="
    dictionaryInputText.invalid && (submitted || dictionaryInputText.touched)
  "
  >{{ 'general.required' | translate }}</small
>
<div class="p-d-flex p-flex-column translate" *ngIf="dictionaryInputText.valid">
  <small
    *ngIf="dictionaryItemSelected.spanish"
    [title]="dictionaryItemSelected.spanish"
    class="ellipsis"
    [pTooltip]="dictionaryItemSelected.spanish"
    tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
    ><label>ES:</label>
    {{ dictionaryItemSelected.spanish | shortText: shortText }}</small
  >
  <small
    *ngIf="dictionaryItemSelected.english"
    [title]="dictionaryItemSelected.english"
    class="ellipsis"
    [pTooltip]="dictionaryItemSelected.english"
    tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
    ><label>EN:</label>
    {{ dictionaryItemSelected.english | shortText: shortText }}</small
  >
</div>
