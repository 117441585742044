import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() isRequired: boolean = false;
  @Input() formSubmitted: boolean = false;
  @Input() currentFile: any = null; //Archivo subido a S3
  @Output() uploadFile: EventEmitter<File | null> = new EventEmitter();
  @ViewChild('inputElement') inputElement!: ElementRef;
  typeError: boolean = false;
  isDeletedFile: boolean = false;

  fileName: string = '';
  fileSize: string = '';
  fileImg: any = null;

  constructor(private sanitaze: DomSanitizer) {}

  ngOnInit(): void {
    if (this.currentFile) {
      this.fileName = this.currentFile?.name;
      this.fileSize = this.formatBytes(this.currentFile.size);
      this.fileImg = this.sanitaze.bypassSecurityTrustResourceUrl(
        this.currentFile?.image
      );
      this.uploadFile.emit(this.currentFile);
    }
  }

  uploadImage(event: any) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files[0]?.type?.includes('image')) {
      this.fileName = files[0]?.name;
      this.fileSize = this.formatBytes(files[0].size);
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileImg = event.target.result;
      };

      reader.readAsDataURL(files[0]);
      this.isDeletedFile = false;
      this.typeError = false;
      this.uploadFile.emit(files[0]);
    } else {
      this.typeError = true;
    }
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'Kb', 'Mb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  deleteFile() {
    this.fileName = '';
    this.fileSize = '';
    this.isDeletedFile = true;
    this.typeError = false;
    this.inputElement.nativeElement.value = '';
    this.uploadFile.emit(null);
  }
}
