import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { USERS_QUERIES } from 'src/app/modules/user/user.queries';
import { UpdateUserInput } from 'src/app/modules/user/user.types';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
type DocumentType = 'TERMS_AND_CONDITIONS' | 'PRIVACY_POLICIES';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  @ViewChild('myForm') form?: NgForm;
  @ViewChild('outsideElement', { static: true }) outsideElement!: ElementRef;
  @ViewChild('modalViewer', { static: true }) modalView$!: ElementRef;
  accept: boolean = false;
  showAlert: boolean = false;
  PDFUrl!: SafeResourceUrl;
  isFileUploaded: boolean = false;
  language: string | null = null;
  keys: any;
  termsAndConditionsVersion: string | null = null;
  privacyPoliciesVersion: string | null = null;
  showOnlyPDF: boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    private storage: StorageService,
    private authService: AuthService,
    private trialpalService: TrialpalService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.PDFUrl = this.transformToSecureUrl('');
    if (this.config.data) {
      this.showOnlyPDF = true;
    }
  }

  async ngOnInit() {
    this.language = localStorage.getItem('lang');
    if (!this.authService.user.dynamoId) {
      await this.authService.validateTermsAndConditions();
      await this.authService.getUserByLogin(this.authService.getUsername());
    }
    this.termsAndConditionsVersion =
      this.authService.termsAndPoliciesVersion.lastTermsAndConditions ??
      sessionStorage.getItem('termsVersion');
    this.privacyPoliciesVersion =
      this.authService.termsAndPoliciesVersion.lastPrivacyPolicies ??
      sessionStorage.getItem('policiesVersion');
    if (this.language === 'en') {
      this.keys = [
        {
          name: 'terms-and-conditions',
          version: this.termsAndConditionsVersion,
        },
        {
          name: 'privacy-policies',
          version: this.privacyPoliciesVersion,
        },
      ];
    } else {
      this.keys = [
        {
          name: 'terminos-y-condiciones',
          version: this.termsAndConditionsVersion,
        },
        {
          name: 'politicas-de-privacidad',
          version: this.privacyPoliciesVersion,
        },
      ];
    }
    if (this.config.data) {
      const { isTerms } = this.config.data;
      this.openDocument(isTerms ? 'TERMS_AND_CONDITIONS' : 'PRIVACY_POLICIES');
    }
  }

  async onSubmit() {
    try {
      const acceptedTermsAndConditions = {
        termsAndConditionsVersion: this.termsAndConditionsVersion,
        policiesVersion: this.privacyPoliciesVersion,
        acceptanceDate: new Date().toISOString(),
      };
      const userUpdateInput: UpdateUserInput = {
        id: this.authService.user.dynamoId,
        termsAndConditions: [
          ...this.authService.user.termsAndConditions.map((obj: any) => {
            const { __typename, ...rest } = obj;
            return rest;
          }),
          acceptedTermsAndConditions,
        ],
        _version: this.authService.user._version,
      };

      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant('user.actions.updateUser')
      );
      await this.trialpalService.performGraphQLQuery(USERS_QUERIES.UpdateUser, {
        input: userUpdateInput,
      });

      this.ref.close();
      this.trialpalService.hideSpinner();
    } catch (error) {
      this.trialpalService.showServiceError('user.actions.updateUser', error);
      this.trialpalService.hideSpinner();
    }
  }

  checkForm() {
    if (!this.accept) {
      this.showAlert = true;
    }
  }

  openDocument(document: DocumentType) {
    if (document === 'TERMS_AND_CONDITIONS') {
      this.storage
        .get(
          `terms-and-conditions/${this.keys[0].name}-v${this.keys[0].version}.pdf`
        )
        .then((url: string) => {
          this.PDFUrl = this.transformToSecureUrl(url);
          this.modalView$.nativeElement.classList.add('visible');
        })
        .catch((error) =>
          this.trialpalService.showServiceError(
            'errorMessage.anErrorOccurred',
            error
          )
        );
    } else {
      this.storage
        .get(
          `terms-and-conditions/${this.keys[1].name}-v${this.keys[1].version}.pdf`
        )
        .then((url: string) => {
          this.PDFUrl = this.transformToSecureUrl(url);
          this.modalView$.nativeElement.classList.add('visible');
        })
        .catch((error) =>
          this.trialpalService.showServiceError(
            'errorMessage.anErrorOccurred',
            error
          )
        );
    }
  }

  transformToSecureUrl(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @HostListener('document:click', ['$event.target'])
  public async onClick(targetElement: any) {
    const outsideElement =
      this.outsideElement.nativeElement.contains(targetElement);
    if (outsideElement) {
      this.closeModal();
    }
  }
  closeModal() {
    this.modalView$.nativeElement.classList.remove('visible');
    if (this.showOnlyPDF) {
      this.ref.close();
    }
  }
}
