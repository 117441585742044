<div class="footer" *ngIf="this.isLoggedIn">
  <div class="container-tp2">
    <div class="legal">
      <a class="openModal" (click)="showTermsOrPolicy(false)">{{
        'general.terms.politics' | translate
      }}</a>
      |
      <a class="openModal" (click)="showTermsOrPolicy(true)">{{
        'general.terms.use' | translate
      }}</a>
    </div>
    <div class="copyright">
      © {{ 'general.terms.rights' | translate }} - Integra IT {{ year }}
    </div>
    <select
      class="language"
      id="language"
      #langSelect
      (change)="this.trialpalService.changeLang(langSelect.value)"
    >
      <option value="en" [selected]="ViewLang() === 'en'">EN</option>
      <option value="es" [selected]="ViewLang() === 'es'">ES</option>
    </select>
  </div>
</div>
