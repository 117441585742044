import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { CardOrTableService } from 'src/app/services/card-or-table.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { TP2Entites } from 'src/app/shared/global.variables';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';
import { SubjectsService } from '../../subjects.service';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';

const logger = new Logger('tp2-logger-subjectTemperaturePage');

@Component({
  selector: 'app-temperature-subject',
  templateUrl: './temperature-subject.component.html',
  styleUrls: ['./temperature-subject.component.scss'],
})
export class TemperatureSubjectComponent implements OnInit, OnChanges {
  temperatures: any[] = [];
  @Input() idSubject = '';
  @Input() timeZoneOffset = 0;
  @Input() isOpened: boolean = false;
  showTemperatureTakenDateTable: boolean = false;
  tableViewMode: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  dataCard: any[] = [];
  showSpinner: boolean = true;
  @Output() hasMedicalAttentions: EventEmitter<boolean> = new EventEmitter();
  DETAIL_SUBJECT_TEMPERATURE_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_TEMPERATURE_TOUR;

  constructor(
    private subjectsService: SubjectsService,
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe,
    private dictionaryPipe: DictionaryPipe,
    private authService: AuthService,
    private pageTourService: PageTourService,
    public cardOrTableService: CardOrTableService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.showSpinner = true;
      this.temperatures = await this.getTemperaturesBySubjectId(this.idSubject);
      this.hasMedicalAttentions.emit(this.temperatures?.length > 0);
      await this.buildTemperatureTakenDate(this.temperatures);
      this.showTemperatureTakenDateTable =
        this.temperatures.findIndex(
          (temperature: any) => temperature.showTemperatureTakenDate
        ) > -1;
      this.buildCardAndTable();
      this.showSpinner = false;
      this.startTour();
    } catch (exception) {
      this.buildCardAndTable();
      this.showSpinner = false;
      this.startTour();
    }
  }

  ngOnChanges() {
    this.startTour();
  }

  buildCardAndTable() {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };
    if (this.temperatures && this.temperatures.length > 0) {
      this.dataTable = this.buildTPTableData(this.temperatures);
      this.buildCards(this.temperatures);
    }
  }
  buildCards(temperatures: any) {
    this.dataCard = [];
    for (let temp of temperatures) {
      this.dataCard.push(this.buildTPCardData(temp));
    }
  }
  async getTemperaturesBySubjectId(subjectId: string): Promise<any[]> {
    let temp = await this.subjectsService.listTemperatureRecordLogs(subjectId);
    if (temp && temp.length > 0) {
      temp = this.subjectsService.orderInstancesDescending(temp, 'updatedAt');
    }
    return temp || [];
  }
  async buildTemperatureTakenDate(temperatures: any) {
    let forms: any[] = [];
    for await (const temp of temperatures) {
      if (temp?.dayInstance?.confDay?.confFormId) {
        const confFormId = temp.dayInstance.confDay.confFormId;
        let findForm: any = forms.find((x: any) => x.confFormId === confFormId);
        if (!findForm) {
          findForm = await this.subjectsService.confTemperatureByConfForm(
            confFormId
          );
          findForm = findForm.items[0];
          forms = forms.concat(findForm);
        }
        temp.showTemperatureTakenDate = findForm
          ? findForm.showTemperatureTakenDate
          : true;
      }
    }
  }

  buildTPCardData(temp: any): TPCardData {
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    let temperature = this.getTemperatureWithUnit(temp);
    let temperatureRoute = this.getTemperatureRoute(temp);

    return {
      data: temp,
      header: this.getTemperatureDay(temp),
      section1: this.getTemperatureVisit(temp),
      icon1: 'pi pi-calendar',
      section2: temperature,
      icon2: temperature ? 'pi pi-info-circle' : '',
      section3: temperatureRoute,
      icon3: temperatureRoute ? 'pi pi-bookmark' : '',
      footer: temp.showTemperatureTakenDate
        ? this.getTemperatureTakenDate(temp)
        : this.getTemperatureSendReportDate(temp),
      footer1ToolTip: this.getTooltipTemperatureTakenDate(temp),
      styleColorState: '',
      showEditButton: false,
      showAuditButton,
      entity,
      showActivateButton: false,
      showDeleteButton: false,
      cardIsButton: false,
      objectAction: temp.phase,
    };
  }

  buildTPTableData(temperatureInstance: any): TPTableData {
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    let newSymptomInstance = temperatureInstance.map((temp: any) => {
      return {
        data: temp,
        entity,
        temperatureDay: `${this.getTemperatureDay(
          temp
        )} (${this.getTemperatureSendReportDate(temp)})`,
        temperatureVisit: this.getTemperatureVisit(temp),
        temperature: this.getTemperatureWithUnit(temp),
        temperatureRoute: this.getTemperatureRoute(temp) || 'N/A',
        temperatureDate: this.getTemperatureTakenDate(temp) || 'N/A',
      };
    });
    let cols = [
      {
        header: this.trialpalService.translateService.instant(
          'temperaturerecordlog.reportDay'
        ),
        type: TypeInput.TEXT,
        field: 'temperatureDay',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'temperaturerecordlog.visit'
        ),
        type: TypeInput.TEXT,
        field: 'temperatureVisit',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'temperaturerecordlog.temperature'
        ),
        type: TypeInput.TEXT,
        field: 'temperature',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'temperaturerecordlog.route'
        ),
        type: TypeInput.TEXT,
        field: 'temperatureRoute',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];

    if (this.showTemperatureTakenDateTable) {
      cols.push({
        header: this.trialpalService.translateService.instant(
          'temperaturerecordlog.temperatureTakenDate'
        ),
        type: TypeInput.TEXT,
        field: 'temperatureDate',
        showBasicSearch: true,
        showFilterComplete: false,
      });
    }

    return {
      valueTable: newSymptomInstance,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: [
        'temperatureDay',
        'temperatureVisit',
        'temperature',
        'temperatureRoute',
        'temperatureDate',
      ],
      cols,
      customButton1: showAuditButton
        ? {
            icon: 'pi pi-file',
          }
        : null,
      menuOptions: [],
    };
  }
  getEntityAndAuditButton(): any[] {
    let entity = '';
    let showAuditButton = false;
    if (this.authService.isAdmin() || this.authService.isInvestigator()) {
      entity = TP2Entites.TEMPERATURERECORDLOG;
      showAuditButton = true;
    }

    return [entity, showAuditButton];
  }

  getTemperatureRoute(temp: any) {
    if (!temp.temperatureTaken || !temp.temperatureRoute) return '';

    return temp.temperatureRoute === 'OTHER'
      ? temp.temperatureWhichOtherRoute
      : this.trialpalService.translateService.instant(
          `ediary.enums.temperatureRoute.${temp.temperatureRoute}`
        );
  }

  getTemperatureWithUnit(temp: any) {
    if (!temp.temperatureTaken || !temp.temperature) {
      return this.trialpalService.translateService.instant(
        'general.configurations.temperatureWasNotTaken'
      );
    }

    const typeTemperature = temp?.temperatureUnit === 'CELSIUS' ? 'C°' : 'F°';
    return `${temp.temperature} ${typeTemperature}`;
  }

  getTemperatureDay(temp: any) {
    return this.dictionaryPipe.transform(
      temp.dayInstance?.confDay?.dayName || ''
    );
  }
  getTemperatureVisit(temp: any) {
    return this.dictionaryPipe.transform(
      temp.dayInstance?.eDiaryPhaseInstance?.phase
    );
  }
  getTemperatureTakenDate(temp: any) {
    if (!temp.temperatureTaken) return '';

    return (
      this.transformDatePipe.transformToSiteHour(
        temp.temperatureTakenDate,
        this.timeZoneOffset
      ) || this.getTemperatureSendReportDate(temp)
    );
  }

  getTemperatureSendReportDate(temp: any) {
    return this.transformDatePipe.transform(temp.updatedAt) || '';
  }

  getTooltipTemperatureTakenDate(temp: any) {
    if (temp.showTemperatureTakenDate && temp.temperatureTakenDate) {
      return this.trialpalService.translateService.instant(
        'temperaturerecordlog.temperatureTakenDate'
      );
    }
    return this.trialpalService.translateService.instant(
      'temperaturerecordlog.TooltipTemperatureReportDate'
    );
  }
  async startTour() {
    if (!this.isOpened || this.showSpinner) return;
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
    this.pageTourService.initiateTour(
      PageTourDataType.DETAIL_SUBJECT_TEMPERATURE_TOUR
    );
  }
}
