import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/modules/user/user.service';
import {
  CreateUserInput,
  TP2UserInput,
  UserState,
} from 'src/app/modules/user/user.types';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Project, TP2IdEntity } from 'src/app/services/trialpal.types';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { Roles, emailRegex, userRegex } from 'src/app/shared/global.variables';
import { InformedConsentService } from '../../informed-consent.service';
import {
  CreateConsentedUserInput,
  InformedConsentState,
  UpdateConsentedUserInput,
  UpdateInformedConsentInstanceInput,
} from '../../informed-consent.types';
import { ExcelFileUserInput } from '../informed-consent-follow-up-users-table/informed-consent-follow-up-users-table';
const logger = new Logger('tp2-logger-informedConsentFollowUpUsersTable');

@Component({
  selector: 'app-informed-consent-follow-up-user-form',
  templateUrl: './informed-consent-follow-up-user-form.component.html',
  styleUrls: ['./informed-consent-follow-up-user-form.component.scss'],
})
export class InformedConsentFollowUpUserFormComponent implements OnInit {
  @Input() user: any = {};
  @Input() existingUsers: ExcelFileUserInput[] = [];
  @Input() confInformedConsent: any = {};
  @Input() projectAndSite = {} as ProjectAndSiteInterface;
  @Input() availableUsers: any[] = [];
  @Input() assignedKinships: any[] = [];
  @Input() fromAddPendingPerson = false;
  @Input() informedConsent: any;

  @Output() closeUserForm = new EventEmitter<(CreateUserInput | null)[]>();
  @Output() closeAddPendingPerson = new EventEmitter<any>();
  @Output() closeEditPerson = new EventEmitter<CreateUserInput | null>();
  currentProject: Partial<Project> = {
    isMFAActivated: false,
  };
  relationshipTypes: any[] = [];
  emailPattern = emailRegex;
  subjectSigns: any[] = [];
  userPattern = userRegex;
  requiredSignatures = 1;
  maxRequiredSignatures = 6;
  subjects: any[] = [{ id: '', name: '' }];
  canAddSubject = false;
  users: any[] = [];
  userIndex = 0;
  existingUser = false;
  selectedUser = '';
  isEdition: boolean = false;
  hasShownEditAlert: boolean = false;
  isSinglePerson: boolean = false;
  currentEmail: string = '';
  relationships: any[] = [];
  updatedInformedConsents = false;
  repeatedIds: any[] = [];
  originalUser: any;
  alertTypes: string[] = [];
  booleanOptions: { label: any; value: boolean }[];
  yearRange: string = `1900:${new Date().getFullYear()}`;
  maxDate = new Date();
  constructor(
    public trialpalService: TrialpalService,
    private informedConsentService: InformedConsentService,
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.booleanOptions = [
      {
        label: this.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    if (this.fromAddPendingPerson) {
      this.requiredSignatures = 0;
    } else if (
      //si el usuario viene con algún valor, solo se rellena la persona que se está editando
      this.user.login
    ) {
      this.requiredSignatures = 1;
    } else {
      this.requiredSignatures = this.confInformedConsent.minRequiredSignatures;
      this.maxRequiredSignatures =
        this.confInformedConsent.maxRequiredSignatures;
    }
    this.loadAlertTypes();
    this.currentEmail = this.user.email;

    logger.debug('user-->', this.user);
    // trim this.user.subjects
    this.user.subjects = this.user.subjects?.map((subject: any) => ({
      id: subject.id?.trim(),
      name: subject.name?.trim(),
      birthDate: this.informedConsentService.transformStringToDate(
        subject?.birthDate,
        'DD-MM-YYYY'
      ),
    }));

    //Construye el array de sujetos pertenecientes al usuario
    const subjects = this.user.subjects;
    this.subjects =
      subjects?.length > 0 ? subjects : [{ id: '', name: '', birthDate: '' }];
    this.isEdition = Boolean(this.user?.data?.id);
    if (this.isEdition) {
      this.putOriginalKinshipKeys();
    }
    this.subjectSigns = Array(this.requiredSignatures).fill(false);
    logger.debug('subjectSigns-->', this.subjectSigns);
    logger.debug('firmas-->', this.requiredSignatures);
    logger.debug('subjects-->', this.subjects);
    this.checkCanAddSubject();

    if (this.user.group === 'GROUP_A') {
      //excluir el tipo de relación del usuario actual
      this.assignedKinships = this.assignedKinships.filter(
        (kinship) => kinship !== this.user.relationship
      );
    }
    await this.setProject();
    await this.getRelationshipTypes();
    logger.debug(
      'minRequiredSignatures-->',
      this.confInformedConsent.minRequiredSignatures
    );
    this.isSinglePerson =
      this.confInformedConsent.minRequiredSignatures === 1 ||
      this.isEdition ||
      this.fromAddPendingPerson ||
      this.user?.data?.editTemp;
    this.originalUser = structuredClone(this.user);
  }

  async setProject() {
    try {
      this.currentProject = await this.informedConsentService.getProject(
        this.projectAndSite.projectId
      );
    } catch (error) {
      logger.error('get project error', error);
    }
  }

  putOriginalKinshipKeys(): void {
    const kinships = [
      {
        key: 'FATHER',
        values: ['Father', 'Padre'],
      },
      {
        key: 'MOTHER',
        values: ['Mother', 'Madre'],
      },
      {
        key: 'LEGAL_GUARDIAN',
        values: ['Legal guardian', 'Representante legal'],
      },
      {
        key: 'WITNESS',
        values: ['Witness', 'Testigo'],
      },
      {
        key: 'STUDY_SUBJECT',
        values: ['Study participant', 'Participante del estudio'],
      },
    ];

    this.user.relationship = this.user.relationship.split(', ');
    this.user.relationship = this.user.relationship.map((relationship: any) => {
      const kinship = kinships.find((kinship) =>
        kinship.values.includes(relationship)
      );
      return kinship?.key;
    });
    logger.debug('userKinship-->', this.user);
  }

  async getRelationshipTypesOnEdition(): Promise<void> {
    this.trialpalService.showSpinner('informedConsent.informedConsents', 'GET');
    try {
      for (const consent of this.user.data.informedConsents) {
        const consentedUsers =
          await this.informedConsentService.getConsentedUserByInformedConsenteInstanceId(
            consent.informedConsent.id
          );
        logger.debug('consentedUsers-->', consentedUsers);
        logger.debug('consentOnGetRelationships-->', consent);
        this.relationshipTypes.push(
          this.trialpalService
            .getTranslatedEnum('informedConsent.witnessesTypes', Relationship)
            .map((type) => ({
              value: type.value,
              label: type.name,
              disabled: consentedUsers.some((consentedUser: any) => {
                if (
                  consentedUsers.filter(
                    (myUser) => myUser.kinship === 'WITNESS'
                  ).length < 2 &&
                  consentedUser.kinship === 'WITNESS'
                ) {
                  return false;
                }
                return (
                  consentedUser.kinship === type.value &&
                  consentedUser.id !== consent.consentedUser.id
                );
              }),
            }))
        );
      }
    } catch (error) {
      logger.error('getRelationshipTypesOnEdition', error);
      this.trialpalService.showServiceError(
        'informedConsent.informedConsents',
        error
      );
    } finally {
      this.trialpalService.hideSpinner();
    }
  }

  async getRelationshipTypes() {
    if (this.isEdition) {
      await this.getRelationshipTypesOnEdition();
      return;
    }
    this.relationshipTypes = this.trialpalService
      .getTranslatedEnum('informedConsent.witnessesTypes', Relationship)
      .map((type) => ({
        value: type.value,
        label: type.name,
        disabled: false,
      }));
    this.updateRelationships();
  }

  async onSubmit(form: NgForm) {
    //si el usuario viene de add pending person, se asocia el usuario seleccionado
    if (this.fromAddPendingPerson || this.isEdition) {
      this.user.identifier = this.subjects.map((subject) => subject.id);
      this.user.subjectNames = this.subjects.map((subject) => subject.name);
      this.user.subjectBirthdates = this.subjects.map((subject) =>
        this.informedConsentService.transformDateToDDMMYYYYFormat(
          subject.birthDate
        )
      );
      logger.debug('userEdit', this.user);
      this.validateAndCreateUser(form);
      return;
    }
    //valida el último usuario
    this.validateSingleUser(form);
    const isValidPhoneNumber =
      this.trialpalService.phoneNumberService.isValidPhoneNumber(
        this.user.phoneNumber
      ).isValid;
    const isMFAActivated = this.currentProject?.isMFAActivated ?? false;
    if (
      !isValidPhoneNumber &&
      (this.user.notificationPreference === NotificationPreferences.SMS ||
        isMFAActivated)
    ) {
      return this.showPhoneMessageError();
    }
    if (form.valid) {
      if (this.userIndex === this.users.length) {
        this.users.push({ ...this.user });
      } else {
        this.users[this.userIndex] = { ...this.user };
      }
      this.subjectSigns[this.userIndex - 1] = true;
      //para cada usuario, se agrega el identifier
      this.users.forEach((user) => {
        user.identifier = this.subjects.map((subject) => subject.id);
        user.subjectNames = this.subjects
          .map((subject) => subject.name)
          .join(', ');
        user.subjectBirthdates = this.subjects
          .map((subject) =>
            this.informedConsentService.transformDateToDDMMYYYYFormat(
              subject.birthDate
            )
          )
          .join(', ');
        user.subjects = this.subjects;
      });
      this.closeUserForm.emit(this.users);
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  showPhoneMessageError() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant('general.error'),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.formUser.errors.phone'
      ),
    });
  }

  private async validateAndCreateUser(form: NgForm) {
    if (this.isEdition) {
      if (this.userHasChanged()) {
        return this.modalChangeReason(this.originalUser.login, form);
      } else {
        return this.closeUserForm.emit(undefined);
      }
    }
    if (this.existingUser) {
      const existingConsentedUsers =
        await this.informedConsentService.getConsentedUserByUserId(
          this.selectedUser
        );
      this.user.requiresSendingEmail =
        existingConsentedUsers.items[0]?.requiresSendingEmail;
      const createConsentedUserInput = await this.onAssociateUser();
      this.closeAddPendingPerson.emit(createConsentedUserInput);
    } else {
      logger.debug('user-->', this.user);
      this.trialpalService.showSpinner(
        'informedConsent.informedConsents',
        'UPDATE'
      );
      this.validateSingleUser(form);
      const isValidUser = await this.validateUniqueUser(form);
      if (isValidUser) {
        const createdUser = await this.createUser(this.user);
        if (createdUser) {
          this.selectedUser = createdUser.id;
          const createConsentedUserInput = await this.onAssociateUser();
          logger.debug('createConsentedUserInput-->', createConsentedUserInput);
          this.closeAddPendingPerson.emit(createConsentedUserInput);
        }
      }
    }
  }

  async editUser(form: any, changeReason: string) {
    try {
      this.trialpalService.showSpinner('user.entity', 'UPDATE');
      await this.getUpdatedInformedConsents();
      await this.validateSingleUser(form);
      logger.debug('isEdition-->', this.isEdition);
      const isValidUser = await this.validateUniqueUser(form);
      logger.debug('isValidUser-->', isValidUser);
      if (isValidUser) {
        logger.debug('userEdition-->', this.user);
        let index = 0;
        // Editar ConsentedUser
        for (const consent of this.user.data.informedConsents) {
          const inputConsentedUser: UpdateConsentedUserInput = {
            id: consent.consentedUser.id,
            _version: consent.consentedUser._version,
          };
          const updatedConsentedUser =
            await this.informedConsentService.updateConsentedUser(
              inputConsentedUser,
              {
                name: this.user.name,
                kinship: this.user.relationship[index],
                requiresSendingEmail: this.user.requiresSendingEmail,
                _lastUser: this.authService.getUsername(),
                _changeReason: changeReason,
              }
            );
          logger.debug('updatedConsentedUser-->', updatedConsentedUser);
          consent.consentedUser = updatedConsentedUser;
          index++;
        }

        // Editar Usuario
        let userInput: TP2UserInput = {
          login: this.user.login,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber,
          name: this.user.name,
          notificationPreference: this.user.notificationPreference,
        };

        const projects: TP2IdEntity[] = [{ id: this.projectAndSite.projectId }];
        const sites: TP2IdEntity[] = [{ id: this.projectAndSite.siteId }];
        userInput._changeReason = changeReason;
        userInput._lastUser = this.authService.getUsername();
        const updateUser = await this.userService.editUser(
          userInput,
          projects,
          sites,
          [],
          []
        );
        logger.debug('updateUser-->', updateUser);
        this.informedConsentService.recordeConsentEvent(
          'site_consent_save_editperson',
          {
            project: this.projectAndSite.projectId,
            site: this.projectAndSite.siteId,
          }
        );

        logger.debug('user-->', this.user);

        //Editar consentimientos informados (Sujetos)
        this.user.data.informedConsents.forEach(async (ic: any) => {
          logger.debug('ic-->', ic);
          logger.debug('ic.informedConsent.id-->', ic.informedConsent.id);
          logger.debug(
            'SubjectAsking-->',
            ic.informedConsent.consentedIdentificator,
            this.user.subjects
          );
          const birthDate = this.subjects.find(
            (subject: any) =>
              subject.id.trim() ===
              ic.informedConsent.consentedIdentificator.trim()
          )?.birthDate;
          const consentedBirthDate =
            this.informedConsentService.transformDateToYYYYMMDDFormat(
              birthDate,
              'DD-MM-YYYY'
            );
          const informedConsentInput: UpdateInformedConsentInstanceInput = {
            id: ic.informedConsent.id,
            _version: ic.informedConsent._version,
            consentedName: this.subjects.find(
              (subject: any) =>
                subject.id.trim() ===
                ic.informedConsent.consentedIdentificator.trim()
            )?.name,
            consentedBirthDate: consentedBirthDate,
            _lastUser: this.authService.getUsername(),
            _changeReason: changeReason,
          };
          const updatedInformedConsent =
            await this.informedConsentService.updateInformedConsentInstance(
              informedConsentInput
            );

          this.user.data.informedConsents.find(
            (arrayIc: any) =>
              arrayIc.informedConsent.id === ic.informedConsent.id
          ).informedConsent = updatedInformedConsent;

          logger.debug('updatedInformedConsent-->', updatedInformedConsent);
        });
        this.user.subjectNames = this.user.subjectNames.join(', ');
        this.user.subjectBirthdates = this.subjects
          .map((subject) =>
            this.informedConsentService.transformDateToDDMMYYYYFormat(
              subject.birthDate
            )
          )
          .join(', ');
        logger.debug('user-->', this.user);
        this.closeEditPerson.emit(this.user);
        this.trialpalService.hideSpinner();
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'general.messageSuccessOperation.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'general.messageSuccessOperation.detail'
          ),
        });
      }
    } catch (error: any) {
      this.trialpalService.hideSpinner();
      logger.error('validateAndCreateUser', error);
    }
  }

  async modalChangeReason(user: string, form: any) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'user.actions.updateUser'
        ) +
        ': ' +
        user,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.editUser(form, data).then();
        } else {
          this.trialpalService.hideSpinner();
        }
      },
    });
  }

  userHasChanged(): boolean {
    return (
      this.originalUser.login !== this.user.login ||
      this.originalUser.email !== this.user.email ||
      this.originalUser.phoneNumber !== this.user.phoneNumber ||
      this.originalUser.name !== this.user.name ||
      this.originalUser.notificationPreference !==
        this.user.notificationPreference ||
      this.originalUser.requiresSendingEmail !==
        this.user.requiresSendingEmail ||
      JSON.stringify(this.originalUser.subjects) !==
        JSON.stringify(this.user.subjects) ||
      JSON.stringify(this.originalUser.relationship) !==
        JSON.stringify(this.user.relationship)
    );
  }
  private async createUser(user: any) {
    try {
      const projects = [{ id: this.projectAndSite.projectId }];
      const sites = [{ id: this.projectAndSite.siteId }];

      const input: TP2UserInput = {
        login: user.login,
        email: user.email,
        phoneNumber: user.phoneNumber,
        name: user.name,
        role: Roles.Consented,
        state: UserState.ENABLED,
        _lastUser: this.authService.getUsername(),
        notificationPreference: this.user.notificationPreference,
      };
      const userCreated = await this.userService.createUser(
        input,
        projects,
        sites,
        [],
        []
      );

      return JSON.parse(userCreated ?? '').createdUser;
    } catch (error: any) {
      logger.error('createUser', error);
      if (
        error?.errors?.[0]?.message &&
        this.trialpalService.isJSON(error.errors[0].message)
      ) {
        this.messageService.add({
          severity: 'error',
          summary:
            this.trialpalService.translateService.instant('general.attention'),
          detail: JSON.parse(error.errors[0].message).message,
        });
      }
    }
    return {};
  }

  validateSingleUser(form: NgForm): void {
    this.user.role = Roles.Consented;
    logger.debug('user-->', this.user);
    const login = String(this.user.login);

    this.user.email = this.user.email?.trim();
    this.user.login = login?.trim();
    this.user.name = this.user.name?.trim();
    if (
      this.user.requiresSendingEmail ||
      this.user.notificationPreference === NotificationPreferences.EMAIL
    ) {
      this.trialpalService.validateSpaces(this.user.email, 'email', form);
    }
    this.trialpalService.validateSpaces(this.user.login, 'username', form);
    this.trialpalService.validateSpaces(this.user.name, 'name', form);
    logger.debug('user-->', this.user);
  }

  updateRelationships(): void {
    // resetea los tipos de relación
    this.relationshipTypes.forEach((type) => (type.disabled = false));

    // deshabilita el tipo de relación actual
    if (this.user.relationship) {
      this.relationshipTypes.find(
        (type) => type.value === this.user.relationship
      ).disabled = true;
    }

    // deshabilita los tipos de relación que ya están seleccionados
    for (const user of this.users) {
      if (user.relationship && this.userIndex !== this.users.indexOf(user)) {
        if (
          this.users.filter((myUser) => myUser.relationship === 'WITNESS')
            .length < 2 &&
          user.relationship === 'WITNESS'
        ) {
          continue;
        }
        this.relationshipTypes.find(
          (type) => type.value === user.relationship
        ).disabled = true;
      }
    }
    this.informedConsentService.recordeConsentEvent(
      'site_consent_relashionship',
      {
        userRelationship: this.user.relationship,
        project: this.projectAndSite.projectId,
        site: this.projectAndSite.siteId,
      }
    );
    if (!this.assignedKinships) return;
    // deshabilita los tipos de relación que vienen del componente de add person
    for (const kinship of this.assignedKinships) {
      if (
        this.assignedKinships.filter((kinshipName) => kinshipName === 'WITNESS')
          .length < 2 &&
        kinship === 'WITNESS'
      ) {
        continue;
      }
      this.relationshipTypes.find((type) => type.value === kinship).disabled =
        true;
    }
    logger.debug('user-->', this.user);
    logger.debug('relationshipTypes-->', this.relationshipTypes);
  }

  goToNextPerson(form: NgForm): void {
    //Valida que el formulario esté correcto para el usuario actual

    this.validateSingleUser(form);

    if (form.valid) {
      //agregar al final el usuario actual en el array de usuarios si no existe, si existe, se reemplaza
      if (!this.users[this.userIndex + 1]) {
        logger.debug('usersLenght-->', this.users.length);
        if (this.userIndex === this.users.length) {
          this.users.push({ ...this.user });
          this.user = {};
        }
        //vaciar los campos del usuario actual
        form.controls.email.reset();
        form.controls.name.reset();
        form.controls.phoneN.reset();
        form.controls.relationship.reset();
        form.controls.username.reset();
        form.controls.notificationPreference.reset();
        form.controls.requiresSendingEmail.reset();
      } else {
        this.users[this.userIndex] = { ...this.user };
        this.user = { ...this.users[this.userIndex + 1] };
      }
      this.userIndex++;

      logger.debug('users-->', this.users);

      logger.debug('form b-->', form);

      this.subjectSigns[this.userIndex - 1] = true;
      this.updateRelationships();
      logger.debug('form a-->', form);
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  selectUser(index: number, form: NgForm): void {
    if (this.users[index] && form.valid) {
      //Guarda en el array de usuarios el usuario actual
      if (
        JSON.stringify(this.user) !== JSON.stringify(this.users[this.userIndex])
      ) {
        this.users[this.userIndex] = { ...this.user };
      }
      !this.users[this.userIndex] && this.users.push({ ...this.user });
      this.user = { ...this.users[index] };
      this.subjectSigns[index] = true;
      this.validateSingleUser(form);
      this.userIndex = index;
      this.updateRelationships();
    }
  }

  getChipText(item: string): string {
    return item.length <= 20 ? item : item.substring(0, 20) + '...';
  }

  onChangeSubjectName(): void {
    if (
      (this.isEdition || this.user.group === 'GROUP_A') &&
      !this.hasShownEditAlert
    ) {
      this.hasShownEditAlert = true;
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          'informedConsent.followUp.subjectsEditionDetail'
        ),
      });
    }
    this.checkCanAddSubject();
  }
  checkCanAddSubject(form?: NgForm): void {
    logger.debug('subjects-->', this.subjects);
    this.canAddSubject = this.subjects.every(
      (subject) => subject.id && subject.name && subject.birthDate
    );
    //verifica que el id del sujeto no esté repetido
    const ids = this.subjects.map((subject) => subject.id);
    // guarda en un array los ids repetidos
    this.repeatedIds = ids.filter(
      (id, index) => ids.indexOf(id) !== index && id !== ''
    );
    logger.debug('repeatedIds-->', this.repeatedIds);

    if (this.repeatedIds.length > 0) {
      this.canAddSubject = false;
    }
    if (form) {
      this.checkIdentifiers(form);
    }
  }
  checkIdentifiers(form: NgForm): void {
    logger.debug('formcont-->', form.controls);
    //invalida los campos donde el control empieza con 'id' y el valor está en los ids repetidos
    Object.keys(form.controls).forEach((control) => {
      const isAInvalidId = this.isIdentifierExist(form.controls[control].value);
      if (control.startsWith('id'))
        if (
          this.repeatedIds.includes(form.controls[control].value) ||
          isAInvalidId
        ) {
          form.controls[control].setErrors({ existent: true });
        } else {
          form.controls[control].setErrors(null);
          // valida que el id no esté vacío
          if (form.controls[control].value === '') {
            form.controls[control].setErrors({ required: true });
          }
        }
    });
  }

  isIdentifierExist(id: string): boolean {
    return this.existingUsers.some((user: ExcelFileUserInput) => {
      for (const subjectIdentifier of user.identifier.data.split(',')) {
        if (subjectIdentifier?.trim() === id) return true;
      }
      return false;
    });
  }

  addSubject(): void {
    this.subjects.push({ id: '', name: '' });
    this.checkCanAddSubject();
  }
  deleteSubject(subjectsIndex: number): void {
    logger.debug('subjects-->', this.subjects);
    this.subjects.splice(subjectsIndex, 1);
    this.checkCanAddSubject();
  }
  onChangeExistingUser(form: NgForm): void {
    const newUserControls = ['email', 'name', 'phoneN', 'username'];
    const existingUserControls = ['relationship', 'dropdownUser'];
    //Si existingUser es true, no se deben validar los campos del usuario
    logger.debug('existingUserForm', form);
    if (this.existingUser) {
      newUserControls.forEach((control) => {
        form.controls[control].setErrors(null);
      });
    } else {
      existingUserControls.forEach((control) => {
        form.controls[control].setErrors(null);
      });
    }
  }

  async onAssociateUser(): Promise<any> {
    try {
      this.trialpalService.showSpinner(
        'informedConsent.informedConsents',
        'UPDATE'
      );
      const createConsentedUserInput: CreateConsentedUserInput = {
        projectId: this.projectAndSite.projectId,
        siteId: this.projectAndSite.siteId,
        informedConsentInstanceId: this.informedConsent.id,
        userId: this.selectedUser,
        kinship: this.user.relationship,
        state: InformedConsentState.ASSIGNED,
        stateChanges: [
          {
            state: InformedConsentState.ASSIGNED,
            date: new Date().toISOString(),
          },
        ],
        requiresSendingEmail: this.user.requiresSendingEmail,
        name: !this.existingUser
          ? this.user.name
          : this.availableUsers.find((user) => user.id === this.selectedUser)
              ?.name,
        _lastUser: this.authService.getUsername(),
      };
      const newConsentedUser =
        await this.informedConsentService.createConsentedUser(
          createConsentedUserInput
        );
      this.trialpalService.messageService.add({
        severity: 'success',
        summary: this.trialpalService.translateService.instant(
          'informedConsent.followUp.personAdded'
        ),
        detail: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.removeConsentedSuccessDetail'
        ),
      });
      this.trialpalService.hideSpinner();
      return newConsentedUser;
    } catch (error) {
      logger.debug('AddExistingUserError', error);
      this.trialpalService.showServiceError(
        'informedConsent.informedConsents',
        error
      );
      this.trialpalService.hideSpinner();
    }
  }
  async validateUniqueUser(form: NgForm): Promise<any> {
    let isValid = true;
    let emailExists = false;
    let usernameExists = false;

    if (this.isEdition) {
      if (this.user.email !== this.currentEmail) {
        emailExists = await this.informedConsentService.getUserByEmail(
          this.user.email
        );
      }
    } else {
      // valida unicidad de email y username
      emailExists = await this.informedConsentService.getUserByEmail(
        this.user.email
      );
      usernameExists = await this.informedConsentService.getUserByLogin(
        this.user.login
      );
    }
    if (emailExists || usernameExists) {
      this.trialpalService.hideSpinner();
      isValid = false;
    }

    if (emailExists) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.emailExists'
        ),
        detail: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.emailExistsDetail'
        ),
      });
      logger.debug('emailExists-->', emailExists);

      // hace invalido el campo email
      form.controls.email.setErrors({ existent: true });
    }
    if (usernameExists) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.usernameExists'
        ),
        detail: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.rowDetail.usernameExistsDetail'
        ),
      });
      logger.debug('usernameExists-->', usernameExists);

      // hace invalido el campo username
      form.controls.username.setErrors({ existent: true });
    }
    return isValid;
  }

  async getUpdatedInformedConsents() {
    if (this.updatedInformedConsents) return;
    logger.debug('user-->', this.user);
    this.user.data.informedConsents.forEach(async (ic: any) => {
      const informedConsent =
        await this.informedConsentService.getInformedConsentById(
          ic.informedConsent.id
        );

      ic.informedConsent = informedConsent;
      ic.consentedUser.informedConsentInstances = informedConsent;

      logger.debug('informedConsent-->', informedConsent);
    });
    logger.debug('user-->', this.user);
  }
  addAdditionalPerson() {
    this.subjectSigns.push(false);
  }
  removeAdditionalPerson(index: number) {
    this.subjectSigns.splice(index, 1);
    if (this.userIndex === index) {
      if (this.subjectSigns[index - 1] === true) {
        this.user = { ...this.users[index - 1] };
        this.userIndex = index - 1;
      }
    }
    if (index < this.userIndex) {
      this.userIndex--;
    }
    this.users.splice(index, 1);
    this.updateRelationships();
  }
  usersCompleted(form: NgForm): boolean {
    if (form.valid && Object.keys(form.controls).length !== 0) {
      this.subjectSigns[this.userIndex] = true;
    } else {
      this.subjectSigns[this.userIndex] = false;
    }
    return this.subjectSigns.every((sign) => sign === true);
  }
  loadAlertTypes(): void {
    this.userService.getAlertTypes().then((res) => {
      this.alertTypes = res;
    });
  }
}

export interface ProjectAndSiteInterface {
  siteId: string;
  projectId: string;
}

export enum Relationship {
  FATHER = 'FATHER',
  LEGAL_GUARDIAN = 'LEGAL_GUARDIAN',
  MOTHER = 'MOTHER',
  WITNESS = 'WITNESS',
  STUDY_SUBJECT = 'STUDY_SUBJECT',
}
export enum NotificationPreferences {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
