<h1>{{ 'project.titleVisitGroup' | translate }}</h1>
<div>
  <form
    class="p-grid"
    #form="ngForm"
    (ngSubmit)="addPhase(form)"
    style="align-items: center; margin-bottom: 1rem"
  >
    <div class="p-col-3 input-h">
      <label for="groups">{{ 'general.groups' | translate }}</label>
      <p-dropdown
        [options]="groups"
        id="groups"
        name="group"
        [(ngModel)]="group"
        [placeholder]="'project.listGroups' | translate"
        optionLabel="name"
        optionValue="value"
        #groupHtml="ngModel"
        [required]="true"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="!groupHtml.valid && (form.submitted || groupHtml.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-3 input-h">
      <label for="visits">{{ 'general.phases' | translate }}</label>
      <p-dropdown
        [options]="phases"
        id="visits"
        name="phase"
        [(ngModel)]="phase"
        [placeholder]="'project.listVisits' | translate"
        optionLabel="name"
        optionValue="value"
        #phaseHtml="ngModel"
        [required]="true"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="!phaseHtml.valid && (form.submitted || phaseHtml.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-2" style="align-items: center">
      <div>
        <p-checkbox
          [(ngModel)]="visible"
          id="visible"
          name="visible"
          [binary]="true"
          #visibleHtml="ngModel"
          [required]="true"
        ></p-checkbox>
        <label
          for="visible"
          style="display: inline; margin-bottom: 0; margin-left: 0.5rem"
          >{{ 'general.visible' | translate }}</label
        >
      </div>
      <small
        class="form-error"
        *ngIf="!visibleHtml.valid && (form.submitted || visibleHtml.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-3">
      <button
        pButton
        *ngIf="hasCreateGroupVisitPermission"
        [label]="'general.add' | translate"
        type="submit"
        badgeClass="color: white;"
        class="p-button-rounded p-button-success pbutton"
      ></button>
    </div>
  </form>

  <div style="margin-bottom: 1rem">
    <p-accordion>
      <p-accordionTab [header]="group.name" *ngFor="let group of groups">
        <h3 *ngIf="getPhases(group.value).length === 0">
          {{ 'project.visitGroupEmpty' | translate }}
        </h3>
        <div class="p-grid">
          <p-card
            class="card"
            class="p-col-3"
            *ngFor="let visit of getPhases(group.value)"
          >
            <div class="card-header">
              <button
                *ngIf="hasConfigVisitVisibilityPermission"
                id="btn_showCustomButton2"
                name="btn_showCustomButton2"
                pButton
                (click)="editVisitGroup(visit)"
                type="button"
                [icon]="getIcon(visit.isVisible)"
                [pTooltip]="''"
                class="
                  p-button-rounded
                  p-button-secondary
                  p-button-outlined
                  p-mr-2
                  p-ripple
                  p-button
                  p-component
                  p-button-icon-only
                "
              ></button>
              <button
                *ngIf="hasDeleteGroupVisitPermission"
                id="btn_showCustomButton2"
                name="btn_showCustomButton2"
                pButton
                (click)="deleteVisitGroup(visit)"
                type="button"
                icon="pi pi-trash"
                [pTooltip]="''"
                class="
                  p-button-rounded
                  p-button-secondary
                  p-button-outlined
                  p-mr-2
                  p-ripple
                  p-button
                  p-component
                  p-button-icon-only
                "
              ></button>
            </div>
            <div class="card-body">
              <p style="font-weight: 900">{{ visit.visit | dictionary }}</p>
              <p>
                {{
                  (visit.isVisible ? 'project.visible' : 'project.noVisible')
                    | translate
                }}
              </p>
            </div>
          </p-card>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>

  <div style="display: flex; justify-content: end">
    <button
      class="p-button-secondary"
      pButton
      label="{{ 'project.buttonCancel' | translate }}"
      type="button"
      (click)="close()"
    ></button>
  </div>
</div>
