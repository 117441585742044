export const INFORMED_CONSENT_QUERIES = {
  Tp2CreateEConsentPDF: `query Tp2CreateEConsentPDF($informedConsentInstanceId: String!) {
    tp2CreateEConsentPDF(informedConsentInstanceId: $informedConsentInstanceId)
  }`,
  ConfInformedConsentByProjectIDCustom: `query ConfInformedConsentByProjectIDCustom($projectId: ID!) {
    confInformedConsentByProjectID(projectId: $projectId) {
      __typename
      items {
        __typename
        projectId
        id
        name
        icVersion
        sites {
          __typename
          site
          recipients
          totalSubjects
        }
        type
        quizPageLayout
        state
        approvalSealFileUrl
        isMultipleSignatureRequired
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetConfInformedConsent: `query GetConfInformedConsent($id: ID!) {
    getConfInformedConsent(id: $id) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      name
      icVersion
      recipients
      sites {
        __typename
        site
        recipients
        totalSubjects
      }
      type
      quizPageLayout
      approvalSealFileUrl
      isMultipleSignatureRequired
      state
      minRequiredSignatures
      maxRequiredSignatures
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  GetInformedConsentInstance: `query GetInformedConsentInstance($id: ID!) {
    getInformedConsentInstance(id: $id) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      ConfInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consenterUserId
      consenterUser {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      consentedIdentificator
      consentedName
      consentedBirthDate
      consenterName
      consenterIdentification
      consenterSignature
      consenterRole
      state
      stateChanges {
        __typename
        state
        date
      }
      pdfFiles {
        __typename
        uploadDate
        fileUrl
      }
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  GetProject: `query GetProject($id: ID!) {
    getProject(id: $id) {
      __typename
      id
      isMFAActivated
    }
  }`,
  ConsentedUserByInformedConsentInstanceIdCustom: `query ConsentedUserByInformedConsentInstanceIdCustom($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String) {
    ConsentedUserByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        siteId
        userId
        user {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          subjects
          sites
          projects
        }
        informedConsentInstanceId
        informedConsentInstance {
          __typename
          consentedIdentificator
        }
        id
        name
        kinship
        identification
        state
        stateChanges {
          __typename
          state
          date
        }
        signature
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ICQuestionInstanceByInformedConsentInstanceIdCustom: `query ICQuestionInstanceByInformedConsentInstanceIdCustom($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
    ICQuestionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        informedConsentInstanceId
        confICQuestionId
        confICSectionId
        consentedUserId
        id
        failedAttempts
        isCompleted
        startDate
        completedDate
        state
        confICQuestion {
          __typename
          description
          id
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ICQuestionInstanceByInformedConsentInstanceId: `query ICQuestionInstanceByInformedConsentInstanceId($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
    ICQuestionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        informedConsentInstanceId
        confICQuestionId
        confICSectionId
        consentedUserId
        id
        failedAttempts
        isCompleted
        startDate
        completedDate
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ICSectionInstanceByInformedConsentInstanceIdCustom: `query ICSectionInstanceByInformedConsentInstanceIdCustom($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
    ICSectionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        informedConsentInstanceId
        confICSectionId
        id
        consentedUserId
        consentedSignatureDate
        progress
        startDate
        completedDate
        isCompleted
        state
        confICSection {
          __typename
          title
          id
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ICSectionInstanceByInformedConsentInstanceId: `query ICSectionInstanceByInformedConsentInstanceId($informedConsentInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
    ICSectionInstanceByInformedConsentInstanceId(informedConsentInstanceId: $informedConsentInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        informedConsentInstanceId
        confICSectionId
        id
        consentedUserId
        consentedSignatureDate
        progress
        startDate
        completedDate
        isCompleted
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ICQuestionInstanceByConsentedUserId: `query ICQuestionInstanceByConsentedUserId($consentedUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICQuestionInstanceFilterInput, $limit: Int, $nextToken: String) {
    ICQuestionInstanceByConsentedUserId(consentedUserId: $consentedUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        informedConsentInstanceId
        confICQuestionId
        confICSectionId
        consentedUserId
        id
        failedAttempts
        isCompleted
        startDate
        completedDate
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ICSectionInstanceByConsentedUserId: `query ICSectionInstanceByConsentedUserId($consentedUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelICSectionInstanceFilterInput, $limit: Int, $nextToken: String) {
    ICSectionInstanceByConsentedUserId(consentedUserId: $consentedUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        informedConsentInstanceId
        confICSectionId
        id
        consentedUserId
        consentedSignatureDate
        progress
        startDate
        completedDate
        isCompleted
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfInformedConsentByProject: `query ConfInformedConsentByProject($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
    confInformedConsentByProject(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        ConfInformedConsent {
          id
          name
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  CreateConfInformedConsent: `mutation CreateConfInformedConsent($input: CreateConfInformedConsentInput!, $condition: ModelConfInformedConsentConditionInput) {
    createConfInformedConsent(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      name
      icVersion
      recipients
      sites {
        __typename
        site
        recipients
        totalSubjects
      }
      type
      quizPageLayout
      approvalSealFileUrl
      isMultipleSignatureRequired
      state
      minRequiredSignatures
      maxRequiredSignatures
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfInformedConsent: `mutation UpdateConfInformedConsent($input: UpdateConfInformedConsentInput!, $condition: ModelConfInformedConsentConditionInput) {
    updateConfInformedConsent(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      name
      icVersion
      recipients
      sites {
        __typename
        site
        recipients
        totalSubjects
      }
      type
      quizPageLayout
      approvalSealFileUrl
      isMultipleSignatureRequired
      state
      minRequiredSignatures
      maxRequiredSignatures
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfICSectionByConfInformedConsentId: `query ConfICSectionByConfInformedConsentId($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICSectionFilterInput, $limit: Int, $nextToken: String) {
    ConfICSectionByConfInformedConsentId(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetConfICSection: `query GetConfICSection($id: ID!) {
    getConfICSection(id: $id) {
      __typename
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      title
      type
      order
      content
      mediaUrl
      mediaType
      icon
      complementaryQuestions {
        __typename
        id
        question
        type
        options
      }
      isElectronicSignatureRequired
      enableApprovalQuestions
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateConfICSection: `mutation CreateConfICSection($input: CreateConfICSectionInput!, $condition: ModelConfICSectionConditionInput) {
    createConfICSection(input: $input, condition: $condition) {
      __typename
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      title
      type
      order
      content
      mediaUrl
      mediaType
      icon
      complementaryQuestions {
        __typename
        id
        question
        type
        options
      }
      isElectronicSignatureRequired
      enableApprovalQuestions
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfICSection: `mutation UpdateConfICSection($input: UpdateConfICSectionInput!, $condition: ModelConfICSectionConditionInput) {
    updateConfICSection(input: $input, condition: $condition) {
      __typename
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      title
      type
      order
      content
      mediaUrl
      mediaType
      icon
      complementaryQuestions {
        __typename
        id
        question
        type
        options
      }
      isElectronicSignatureRequired
      enableApprovalQuestions
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfICAnswerByConfICQuestionId: `query ConfICAnswerByConfICQuestionId($confICQuestionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICAnswerFilterInput, $limit: Int, $nextToken: String) {
    ConfICAnswerByConfICQuestionId(confICQuestionId: $confICQuestionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confICQuestionId
        confInformedConsentId
        projectId
        id
        description
        order
        onErrorMessage
        isCorrect
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  UpdateConfICQuestion: `mutation UpdateConfICQuestion($input: UpdateConfICQuestionInput!, $condition: ModelConfICQuestionConditionInput) {
    updateConfICQuestion(input: $input, condition: $condition) {
      __typename
      confICSectionId
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      description
      order
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  GetConfICAnswer: `query GetConfICAnswer($id: ID!) {
    getConfICAnswer(id: $id) {
      __typename
      confICQuestionId
      confICQuestion {
        __typename
        confICSectionId
        confInformedConsentId
        projectId
        id
        description
        order
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      description
      order
      onErrorMessage
      isCorrect
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateConfICAnswer: `mutation CreateConfICAnswer($input: CreateConfICAnswerInput!, $condition: ModelConfICAnswerConditionInput) {
    createConfICAnswer(input: $input, condition: $condition) {
      __typename
      confICQuestionId
      confICQuestion {
        __typename
        confICSectionId
        confInformedConsentId
        projectId
        id
        description
        order
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      description
      order
      onErrorMessage
      isCorrect
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfICAnswer: `mutation UpdateConfICAnswer($input: UpdateConfICAnswerInput!, $condition: ModelConfICAnswerConditionInput) {
    updateConfICAnswer(input: $input, condition: $condition) {
      __typename
      confICQuestionId
      confICQuestion {
        __typename
        confICSectionId
        confInformedConsentId
        projectId
        id
        description
        order
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      description
      order
      onErrorMessage
      isCorrect
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  InformedConsentInstanceByICConfigurationCustom: `query InformedConsentInstanceByICConfigurationCustom($confInformedConsentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
    InformedConsentInstanceByICConfiguration(confInformedConsentId: $confInformedConsentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        stateChanges {
          __typename
          state
          date
        }
        pdfFiles {
          __typename
          uploadDate
          fileUrl
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  CreateInformedConsentInstance: `mutation CreateInformedConsentInstance($input: CreateInformedConsentInstanceInput!, $condition: ModelInformedConsentInstanceConditionInput) {
    createInformedConsentInstance(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      ConfInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consenterUserId
      consenterUser {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      consentedIdentificator
      consentedName
      consentedBirthDate
      consenterName
      consenterIdentification
      consenterSignature
      consenterRole
      state
      stateChanges {
        __typename
        state
        date
      }
      pdfFiles {
        __typename
        uploadDate
        fileUrl
      }
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  InformedConsentByConsentedIdentificator: `query InformedConsentByConsentedIdentificator($consentedIdentificator: String!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
    informedConsentByConsentedIdentificator(consentedIdentificator: $consentedIdentificator, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConsentedUserByUserIdCustom: `query ConsentedUserByUserIdCustom($userId: ID!) {
    ConsentedUserByUserId(userId: $userId) {
      __typename
      items {
        __typename
        informedConsentInstance {
          __typename
          projectId
          siteId
          confInformedConsentId
          ConfInformedConsent {
            __typename
            projectId
            id
            name
            icVersion
            state
            sites {
              __typename
              site
              recipients
              totalSubjects
            }
            type
            quizPageLayout
            approvalSealFileUrl
            isMultipleSignatureRequired
            minRequiredSignatures
            maxRequiredSignatures
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          consenterUserId
          subjectId
          id
          consentedIdentificator
          consentedName
          consentedBirthDate
          consenterName
          consenterIdentification
          consenterSignature
          consenterRole
          state
          stateChanges {
            __typename
            state
            date
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        identification
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        informedConsentInstanceId
        kinship
        requiresSendingEmail
        name
        projectId
        signature
        state
        stateChanges {
          __typename
          date
          state
        }
        siteId
        updatedAt
        userId
        _lastUser
        _changeReason
      }
    }
  }`,
  CreateConsentedUser: `mutation CreateConsentedUser($input: CreateConsentedUserInput!, $condition: ModelConsentedUserConditionInput) {
    createConsentedUser(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      informedConsentInstanceId
      informedConsentInstance {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      name
      kinship
      requiresSendingEmail
      identification
      state
      stateChanges {
        __typename
        state
        date
      }
      signature
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConsentedUser: `mutation UpdateConsentedUser($input: UpdateConsentedUserInput!, $condition: ModelConsentedUserConditionInput) {
    updateConsentedUser(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userId
      user {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      informedConsentInstanceId
      informedConsentInstance {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      name
      kinship
      identification
      state
      stateChanges {
        __typename
        state
        date
      }
      signature
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateInformedConsentInstance: `mutation UpdateInformedConsentInstance($input: UpdateInformedConsentInstanceInput!, $condition: ModelInformedConsentInstanceConditionInput) {
    updateInformedConsentInstance(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      ConfInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consenterUserId
      consenterUser {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      consentedIdentificator
      consentedName
      consentedBirthDate
      consenterName
      consenterIdentification
      consenterSignature
      consenterRole
      state
      stateChanges {
        __typename
        state
        date
      }
      pdfFiles {
        __typename
        uploadDate
        fileUrl
      }
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateICSectionInstance: `mutation CreateICSectionInstance($input: CreateICSectionInstanceInput!, $condition: ModelICSectionInstanceConditionInput) {
    createICSectionInstance(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      informedConsentInstanceId
      informedConsentInstance {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICSectionId
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      consentedUserId
      consentedUser {
        __typename
        projectId
        siteId
        userId
        informedConsentInstanceId
        id
        name
        kinship
        identification
        state
        signature
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consentedSignatureDate
      complementaryQuestionsAnswers {
        __typename
        type
        answer
      }
      progress
      startDate
      completedDate
      isCompleted
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateICQuestionInstance: `mutation CreateICQuestionInstance($input: CreateICQuestionInstanceInput!, $condition: ModelICQuestionInstanceConditionInput) {
    createICQuestionInstance(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      informedConsentInstanceId
      informedConsentInstance {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICQuestionId
      confICQuestion {
        __typename
        confICSectionId
        confInformedConsentId
        projectId
        id
        description
        order
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICSectionId
      consentedUserId
      consentedUser {
        __typename
        projectId
        siteId
        userId
        informedConsentInstanceId
        id
        name
        kinship
        identification
        state
        signature
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      failedAttempts
      isCompleted
      startDate
      completedDate
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateSignatureInstance: `mutation CreateSignatureInstance($input: CreateSignatureInstanceInput!, $condition: ModelSignatureInstanceConditionInput) {
    createSignatureInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      date
      type
      reason
      entityType
      entityId
      signedInfo
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  InformedConsentByConsenterUserCustom: `query InformedConsentByConsenterUserCustom($consenterUserId: ID!, $sortDirection: ModelSortDirection, $filter: ModelInformedConsentInstanceFilterInput, $limit: Int, $nextToken: String) {
    informedConsentByConsenterUser(consenterUserId: $consenterUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        siteId
        confInformedConsentId
        ConfInformedConsent {
          __typename
          id
          name
          state
          sites {
            __typename
            site
            recipients
            totalSubjects
          }
          _deleted
        }
        consenterUserId
        subjectId
        id
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        pdfFiles {
          __typename
          fileUrl
          uploadDate
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  UpdateICQuestionInstance: `mutation UpdateICQuestionInstance($input: UpdateICQuestionInstanceInput!, $condition: ModelICQuestionInstanceConditionInput) {
    updateICQuestionInstance(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      informedConsentInstanceId
      informedConsentInstance {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICQuestionId
      confICQuestion {
        __typename
        confICSectionId
        confInformedConsentId
        projectId
        id
        description
        order
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICSectionId
      consentedUserId
      consentedUser {
        __typename
        projectId
        siteId
        userId
        informedConsentInstanceId
        id
        name
        kinship
        identification
        state
        signature
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      failedAttempts
      isCompleted
      startDate
      completedDate
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  Tp2SendProjectAdminNotification: `query Tp2SendProjectAdminNotification($input: tp2SendProjectAdminNotificationInput!) {
    tp2SendProjectAdminNotification(input: $input)
  }`,
  ConfICQuestionByConfICSectionId: `query ConfICQuestionByConfICSectionId($confICSectionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfICQuestionFilterInput, $limit: Int, $nextToken: String) {
    ConfICQuestionByConfICSectionId(confICSectionId: $confICSectionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confICSectionId
        confInformedConsentId
        projectId
        id
        description
        order
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  GetConfICQuestion: `query GetConfICQuestion($id: ID!) {
    getConfICQuestion(id: $id) {
      __typename
      confICSectionId
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      description
      order
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateConfICQuestion: `mutation CreateConfICQuestion($input: CreateConfICQuestionInput!, $condition: ModelConfICQuestionConditionInput) {
    createConfICQuestion(input: $input, condition: $condition) {
      __typename
      confICSectionId
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confInformedConsentId
      confInformedConsent {
        __typename
        projectId
        id
        name
        icVersion
        recipients
        type
        quizPageLayout
        approvalSealFileUrl
        isMultipleSignatureRequired
        state
        minRequiredSignatures
        maxRequiredSignatures
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      description
      order
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConsentedUserByUserId: `query ConsentedUserByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConsentedUserFilterInput, $limit: Int, $nextToken: String) {
    ConsentedUserByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        siteId
        userId
        informedConsentInstanceId
        id
        name
        kinship
        identification
        state
        signature
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  UpdateICSectionInstance: `mutation UpdateICSectionInstance($input: UpdateICSectionInstanceInput!, $condition: ModelICSectionInstanceConditionInput) {
    updateICSectionInstance(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      informedConsentInstanceId
      informedConsentInstance {
        __typename
        projectId
        siteId
        confInformedConsentId
        consenterUserId
        subjectId
        id
        consentedIdentificator
        consentedName
        consentedBirthDate
        consenterName
        consenterIdentification
        consenterSignature
        consenterRole
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confICSectionId
      confICSection {
        __typename
        confInformedConsentId
        projectId
        id
        title
        type
        order
        content
        mediaUrl
        mediaType
        icon
        isElectronicSignatureRequired
        enableApprovalQuestions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      consentedUserId
      consentedUser {
        __typename
        projectId
        siteId
        userId
        informedConsentInstanceId
        id
        name
        kinship
        identification
        state
        signature
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consentedSignatureDate
      complementaryQuestionsAnswers {
        __typename
        type
        answer
      }
      progress
      startDate
      completedDate
      isCompleted
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
};
