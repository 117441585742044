import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrivacyAndTermsComponent } from './privacy-and-terms.component';

@NgModule({
  declarations: [PrivacyAndTermsComponent],
  imports: [CommonModule],
  exports: [PrivacyAndTermsComponent],
  providers: [],
})
export class PrivacyAndTermsModule {}
