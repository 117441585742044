import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Analytics, Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { DictionaryService } from 'src/app/shared/components/table-languages/dictionary.service';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';
import { SitesService } from '../../sites/sites.service';
import { AddEditProjectComponent } from '../add-edit-project/add-edit-project.component';
import { ChangeStateProjectComponent } from '../add-edit-project/change-state-project/change-state-project.component';
import { ProjectService } from '../project.service';
import { GetProjectQuery, ProjectState } from '../project.types';
import { VisitsGroupsComponent } from '../visits-groups/visits-groups.component';

const logger = new Logger('tp2-logger-projectDetailPage');

@Component({
  selector: 'app-detail-project',
  templateUrl: './detail-project.component.html',
  styleUrls: ['./detail-project.component.scss'],
})
export class DetailProjectComponent implements OnInit {
  project: GetProjectQuery = {
    __typename: 'Project',
    id: '',
    state: ProjectState.IN_DESIGN,
    code: '',
    name: '',
    sponsor: '',
    groups: [],
    phases: [],
    isEdiaryActivated: false,
    isReportsActivated: false,
    isICActivated: false,
    isChatActivated: false,
    isMFAActivated: false,
    createdAt: '',
    updatedAt: '',
    _version: 0,
    _lastChangedAt: 0,
    _lastUser: '',
  };

  projectId: any = null;
  stateClass = '';
  stateProject = '';
  showChangeStateButton = true;
  dictionary: any[] = [];
  isArrayGroup: any[] = [];
  isArrayVisit: any[] = [];
  siteNames: string[] = [];
  expectedVersion = 0;
  //Permissions
  hasProjectListPermission = false;
  hasProjectCreatePermission = false;
  hasProjectReadPermission = false;
  hasProjectStatusPermission = false;
  hasReadGroupVisitPermission = false;
  hasVersionReadPermissions: boolean = false;
  hasListUserPermission: boolean = false;
  hasFollowupReportsPermission = false;
  hasFollowupDiaryPermission = false;
  hasReportListPermission: boolean = false;
  hasReportCreatePermission: boolean = false;
  hasReportUpdatePermission: boolean = false;
  hasReportDeletePermission: boolean = false;
  hasSubjectModulePermission: boolean = false;
  hasDiaryCreatePermission: boolean = false;
  hasDiaryModulePermission: boolean = false;

  //Variable para los botones de "Ver más"
  group: number = 5;
  sites: number = 5;
  phases: number = 5;

  hasSiteListPermission: boolean = false;
  loading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dictionaryService: DictionaryService,
    private projectService: ProjectService,
    private sitesService: SitesService,
    private translateService: TranslateService,
    private trialpalService: TrialpalService,
    public authService: AuthService,
    private router: Router,
    private userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.projectId = this.activatedRoute.snapshot.params.id;
    this.trialpalService.showSpinner('project.entity', 'GET');
    this.loading = true;

    this.projectService
      .getProject(this.projectId)
      .then(async (response: GetProjectQuery) => {
        logger.debug('getProject response', response);
        this.project = response;
        this.expectedVersion = response._version;
        this.projectService.setCurrentProject(this.project);
        localStorage.setItem('currentProject', JSON.stringify(this.project));
        await this.setPermissions();
        this.stateAndColor();
        this.projectService
          .getProjectSites(this.projectId)
          .then(async (response2) => {
            if (response2.items) {
              this.getSitesProject();
              await this.loadDictionary();
              this.projectGroupsTransaction();
              this.projectPhasesTransaction();
              this.loading = false;
            }
          })
          .catch((error2) => {
            logger.error('getProjectSites error', error2);
            this.loading = false;
            this.trialpalService.showServiceError(
              'project.actions.getProjectSites',
              error2
            );
          })
          .finally(() => this.trialpalService.hideSpinner());
      })
      .catch((error) => {
        this.loading = false;
        logger.error('getProject error', error);
        this.trialpalService.showServiceError(
          'project.actions.getProject',
          error
        );
        this.trialpalService.hideSpinner();
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async setPermissions() {
    this.hasProjectListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectList,
        Roles.Admin,
      ]);

    this.hasProjectCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectCreate,
        Roles.Admin,
      ]);

    this.hasReadGroupVisitPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectReadGroupVisit,
        Roles.Admin,
      ]);

    this.hasProjectReadPermission =
      await this.userPermissionsService.hasPermission(
        [
          TP2Permission.ProjectRead,
          Roles.Admin,
          Roles.Investigator,
          Roles.Reader,
        ],
        true
      );

    this.hasProjectStatusPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectStatus,
        Roles.Admin,
      ]);

    this.hasVersionReadPermissions =
      await this.userPermissionsService.hasPermission([
        TP2Permission.VersionRead,
      ]);

    this.hasListUserPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserList,
        Roles.Admin,
      ]);

    this.hasFollowupReportsPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.FollowupReports,
        Roles.Admin,
        Roles.Investigator,
        Roles.Reader,
      ]);

    this.hasFollowupDiaryPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.FollowupDiary,
        Roles.Admin,
        Roles.Investigator,
        Roles.Reader,
      ]);

    this.hasSiteListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SiteList,
        Roles.Admin,
      ]);

    this.hasReportListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportList,
        Roles.Admin,
      ]);
    this.hasReportCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportCreate,
        Roles.Admin,
      ]);
    this.hasReportUpdatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportUpdate,
        Roles.Admin,
      ]);
    this.hasReportDeletePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportDelete,
        Roles.Admin,
      ]);

    this.hasSubjectModulePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectList,
        TP2Permission.SubjectCreate,
        Roles.Admin,
      ]);

    this.hasDiaryCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DiaryCreate,
        Roles.Admin,
      ]);

    this.hasDiaryModulePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DiaryCreate,
        TP2Permission.DiarySymptomCreate,
        TP2Permission.DiarySymptomDelete,
        TP2Permission.DiarySymptomUpdate,
        TP2Permission.DiaryFormCreate,
        TP2Permission.DiaryFormDelete,
        TP2Permission.DiaryFormUpdate,
        TP2Permission.DiaryDayCreate,
        TP2Permission.DiaryDayDelete,
        Roles.Admin,
      ]);
  }

  projectPhasesTransaction() {
    const lang = this.getSiteLanguage();
    this.isArrayVisit = [];
    if (this.project.phases && this.dictionary && this.dictionary.length > 0) {
      this.project.phases.forEach((phase: string) => {
        this.dictionaryTransaction(phase, lang, this.isArrayVisit);
      });
    } else {
      this.isArrayVisit = this.project.phases || [];
    }
  }

  projectGroupsTransaction() {
    const lang = this.getSiteLanguage();
    this.isArrayGroup = [];
    if (this.project.groups && this.dictionary && this.dictionary.length > 0) {
      this.project.groups.forEach((group: string) => {
        this.dictionaryTransaction(group, lang, this.isArrayGroup);
      });
    } else {
      this.isArrayGroup = this.project.groups || [];
    }
  }
  dictionaryTransaction(item: string, lang: string, array: any) {
    this.dictionary.forEach((data) => {
      if (data.key === item) {
        array.push(lang === 'es' ? data.spanish : data.english);
      } else {
        const existPhase = array.filter((e: any) => e === item).length;

        const existKey = this.dictionary.filter(
          (d: any) => d.key === item
        ).length;

        if (existPhase === 0 && existKey === 0) {
          array.push(item);
        }
      }
    });
  }

  getSiteLanguage() {
    return localStorage.getItem('lang') ?? '';
  }

  stateAndColor(): void {
    const state = this.project.state;
    this.stateProject = this.translateService.instant(
      'project.enums.projectStates.' + state
    );
    let colorState = 'black';
    if (state === 'IN_DESIGN') {
      colorState = 'DESIGN';
    } else if (state === 'IN_PROGRESS' || state === 'ENDED') {
      colorState = 'SUCCESS';
    } else if (state === 'SUSPENDED') {
      colorState = 'DANGER';
    } else if (state === 'ARCHIVED') {
      colorState = 'DANGER';
      this.showChangeStateButton = false;
    }
    this.stateClass = colorState;
  }
  editProject(): void {
    const isEdit = true;
    const ref = this.dialogService.open(AddEditProjectComponent, {
      header: this.translateService.instant('project.buttonLabelEdit'),
      width: '90%',
      data: { isEdit, id: this.project.id, isModal: true },
      dismissableMask: false,
    });
    ref.onClose.subscribe({
      next: (result: any) => {
        if (result && isEdit) {
          this.project = Object.assign(this.project, result);
          this.projectGroupsTransaction();
          this.projectPhasesTransaction();
          this.getSitesProject();
        }
      },
    });
  }
  changeState(): void {
    const isEdit = true;
    const ref = this.dialogService.open(ChangeStateProjectComponent, {
      header: this.translateService.instant('project.buttonLabelEdit'),
      width: '90%',
      data: { isEdit, id: this.project.id, isModal: true },
      dismissableMask: false,
    });
    ref.onClose.subscribe({
      next: (result: any) => {
        if (result && isEdit) {
          this.project = Object.assign(this.project, result);
          this.stateAndColor();
          this.getSitesProject();
        }
      },
    });
  }

  changeViewProjectActived(): void {
    const input: any = {
      id: this.project.id,
      _version: this.project._version,
      isEdiaryActivated: this.project.isEdiaryActivated,
      isReportsActivated: this.project.isReportsActivated,
      isICActivated: this.project.isICActivated,
      isChatActivated: this.project.isChatActivated,
    };
    input._changeReason = 'systemDefault';
    this.trialpalService.showSpinner('Actualizando proyecto...');
    this.projectService
      .updateProject(input, this.project.id, this.project._version)
      .then((response) => {
        logger.debug('updateProject response', response);
        this.project._version = response._version;
      })
      .catch((error) => {
        logger.error('updateProject error', error);
        this.trialpalService.showServiceError(
          'project.actions.updateProject',
          error
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  openAuditModal() {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity: TP2Entites.PROJECT,
        description: this.project.code,
        idRecord: this.project.id,
      },
      width: '95%',
      height: '95%',
    });
  }

  openVisitsGroups() {
    this.dialogService.open(VisitsGroupsComponent, {
      data: {
        entity: TP2Entites.PROJECT,
        description: this.project.code,
        id: this.project.id,
        project: this.project,
      },
      width: '95%',
      height: '95%',
    });
  }

  async loadDictionary() {
    await this.dictionaryService
      .getConfDictionaryByProjectId(this.projectId)
      .then((dictionary) => {
        dictionary.items?.forEach((item) => {
          const obj: any = {
            id: item?.id ?? '',
            key: item?.key!,
            spanish: item?.spanish ?? '',
            english: item?.english ?? '',
          };
          this.dictionary.push(obj);
        });
        localStorage.removeItem('Dictionaty');
        localStorage.setItem('Dictionary', JSON.stringify(dictionary));
      })
      .catch((error) => {
        logger.error('No se pudo consultar el diccionario', error);
      });
  }

  getSitesProject() {
    this.sitesService
      .getSitesByProject(this.projectId)
      .then((sites: any) => {
        if (sites) {
          this.siteNames = sites.map((site: any) => site.name);
          this.trialpalService.hideSpinner();
        }
      })
      .catch((error) => {
        logger.error('listSite error', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  gotToeConsentAudit() {
    this.router.navigate(['project/' + this.projectId + '/econsent-audit']);
  }
  trackeConsentFollowUpEvent() {
    Analytics.record({
      name: 'site_consent_followup',
      attributes: {
        userSub: this.authService.user?.attributes?.sub,
        userName: this.authService.user?.username,
        project: this.projectId,
      },
    });
  }
}
