import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { Roles } from 'src/app/shared/global.variables';
import { ReportService } from '../report.service';
import { ConfReport } from '../report.types';
const logger = new Logger('tp2-logger-reportListPage');

@Component({
  selector: 'app-list-report',
  templateUrl: './list-report.component.html',
  styleUrls: ['./list-report.component.scss'],
})
export class ListReportComponent implements OnInit {
  projectId = '';
  reports: any[] = [];
  programationTypes: any[] = [];
  //Permissions
  hasReportListPermission: boolean = false;
  hasReportCreatePermission: boolean = false;
  hasReportUpdatePermission: boolean = false;
  hasReportDetailPermission: boolean = false;
  hasReportDeletePermission: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trialpalService: TrialpalService,
    private readonly reportService: ReportService,
    public userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.trialpalService.showSpinner('report.entityPlural', 'LIST');
      await this.setReportPermissions();
      this.projectId = this.route.snapshot.params.projectId;
      this.reports = this.hasReportListPermission
        ? await this.reportService.getConfReportByProjectId(this.projectId)
        : [];
      logger.debug('listReport response', this.reports);

      this.reportService
        .getProgramationTypes()
        .then((res) => (this.programationTypes = res));
      this.trialpalService.hideSpinner();
      this.retrieveS3Icons();
    } catch (error) {
      this.trialpalService.showServiceError('report.entityPlural', error);
      logger.error('listReport ngOnInit error', error);
      this.trialpalService.hideSpinner();
    }
  }

  async setReportPermissions() {
    this.hasReportListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportList,
        Roles.Admin,
      ]);

    this.hasReportCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportCreate,
        Roles.Admin,
      ]);

    this.hasReportUpdatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportUpdate,
        Roles.Admin,
      ]);

    this.hasReportDetailPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportDetail,
        Roles.Admin,
      ]);

    this.hasReportDeletePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportDelete,
        Roles.Admin,
      ]);
  }

  buildTPCardData(res: any): TPCardData {
    const cardObject: TPCardData = {
      data: res,
      header: res.reportTitle,
      section1: this.programationTypes.find(
        (x) => x.value === res.programationType
      ).name,
      showEditButton: this.hasReportUpdatePermission,
      showActivateButton: false,
      showDeleteButton: this.hasReportDeletePermission,
      cardIsButton: false,
      isHeaderDictionary: true,
      showCustomButton: this.hasReportDetailPermission,
      iconCustomButton: 'pi pi-search',
      entityAction:
        this.trialpalService.translateService.instant('report.entity'),
      objectAction: res.reportTitle,
    };
    if (res.reportIcon) {
      if (res.s3Icon) {
        cardObject.image2 = res.reportIcon;
      } else {
        cardObject.image = `icons/${res.reportIcon}.png`;
      }
    }
    return cardObject;
  }

  editReport(s: any): void {
    this.router.navigate(['report', this.projectId, 'edit', s.id]);
  }

  openFormInReadMode(confReport: any): void {
    this.router.navigate(['report', this.projectId, 'readMode', confReport.id]);
  }

  async deleteConfReport(confReport: any): Promise<any> {
    try {
      this.trialpalService.showSpinner('report.entity', 'DELETE');
      const hasReportInstances =
        await this.reportService.findAtLeastOneReportInstanceByConfReport(
          confReport.id
        );

      hasReportInstances
        ? await this.reportService.deleteConfReportSoft(confReport)
        : await this.reportService.deleteConfReport(
            confReport.id,
            confReport._version
          );

      this.trialpalService.hideSpinner();
      this.trialpalService.showMutationSuccess('report.entity', 'DELETE');
      return this.removeConfReportOfReportList(confReport);
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error('deleteConfReport error', error);
      this.trialpalService.showServiceError('report.entity', error);
    }
  }

  async removeConfReportOfReportList(confReportToRemove: any) {
    this.reports = this.reports.filter(
      (confReport: ConfReport) => confReport.id !== confReportToRemove.id
    );
  }

  retrieveS3Icons() {
    try {
      this.reports = this.reports.map((report: any) => {
        if (String(report.reportIcon).startsWith('https')) {
          report.reportIcon = report.reportIcon.split('?')[0];
          return {
            ...report,
            s3Icon: true,
          };
        }
        return report;
      });
    } catch (error) {
      logger.error('Error retrieving icons:', error);
    }
  }
}
