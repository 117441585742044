import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InstanceState } from 'src/app/services/trialpal.types';
import { TP2Permission } from 'src/app/services/user-permissions-service';
import { ProjectService } from '../../project/project.service';
import { UserService } from '../../user/user.service';
import { RolesService } from '../roles.service';
import { CreateRoleInput } from '../roles.types';

@Component({
  selector: 'app-add-edit-roles',
  templateUrl: './add-edit-roles.component.html',
  styleUrls: ['./add-edit-roles.component.scss'],
})
export class AddEditRolesComponent implements OnInit {
  @ViewChild('f', { static: true }) roleForm: any;
  isEdit: boolean = false;
  roleId: string = '';
  role: CreateRoleInput = {
    name: '',
    isEnabled: true,
    permissions: [],
    isForProjects: null,
    projects: [],
    _lastUser: '',
    state: InstanceState.ON_GOING,
  };
  permissionsRoleCopy: any[] = [];
  TP2Permissions: any[] = [];
  projects: any[] = [];
  projectId: string = '';
  booleanOptions: any[] = [];

  constructor(
    private router: Router,
    public projectService: ProjectService,
    public trialpalService: TrialpalService,
    private rolesService: RolesService,
    public config: DynamicDialogConfig,
    public userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('roles.entity', 'LIST');
    this.isEdit = this.config?.data?.isEdit;
    this.projectId = this.config?.data?.projectId || '';
    this.TP2Permissions = this.rolesService.getPermissions();
    this.projects = await this.getProjects();

    if (this.isEdit) {
      this.roleId = this.config?.data?.id;
      await this.editMode();
    }

    this.setBooleanOptions();
    this.trialpalService.hideSpinner();
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  async editMode() {
    const role = await this.rolesService.getRoleById(this.roleId);
    if (role) {
      this.role = role;
      this.permissionsRoleCopy = this.role?.permissions
        ? [...(this.role?.permissions ?? [])]
        : [];
    }
    this.removeAssignedPermissions();
    this.setRequiredFormatForPickList();
  }

  /**
   * elimina los permisos que ya han sido asignado de la lista de permisos
   */
  removeAssignedPermissions() {
    if (this.TP2Permissions.length > 0) {
      for (let item of this.role.permissions ?? []) {
        const index = this.TP2Permissions.findIndex(
          (x: any) => x.name === item
        );
        if (index > -1) {
          this.TP2Permissions.splice(index, 1);
        }
      }
    }
  }

  /**
   * Toma array de TP2Permissions (strings) y devuelve una matriz de objetos con la propiedad name {name: string}
   */
  setRequiredFormatForPickList() {
    this.role.permissions = this.role.permissions?.map((permission: any) => {
      return { name: permission as unknown as TP2Permission };
    }) as unknown as [TP2Permission];
  }

  onSubmit(form: any): void {
    if (!form.valid) return this.trialpalService.showInvalidFormError();
    if (this.role.permissions?.length === 0)
      return this.trialpalService.showInvalidFormError(
        '',
        this.trialpalService.translateService.instant(
          'roles.atLeastOnePermission'
        )
      );
    this.role.permissions = this.role.permissions?.map((x: any) => x.name);
    if (!this.isEdit) {
      this.createRole();
    } else {
      this.editRole();
    }
  }

  async editRole() {
    this.trialpalService.showSpinner('roles.entity', 'UPDATE');
    const _version = this.role._version;
    this.trialpalService.cleanQueryResponse(this.role);
    const role = await this.rolesService.editRole(this.role, _version);
    await this.validateUpdateUserPermissions();

    if (role) {
      this.trialpalService.showMutationSuccess(
        '',
        '',
        '',
        this.trialpalService.translateService.instant(
          'roles.roleEditSuccesfully'
        )
      );
      this.trialpalService.ref.close(role);
    }
    this.trialpalService.hideSpinner();
  }

  async validateUpdateUserPermissions() {
    //verifica si se actualizaron los permisos
    if (this.areArraysEqual(this.role.permissions, this.permissionsRoleCopy)) {
      return;
    }
    // verifica que exista el Id del rol
    if (!this.role.id) return;

    await this.userService.updateUserPermissionsByRole(this.role.id);
  }

  /**
   * La función comprueba si dos matrices son iguales comparando sus
   * longitudes y elementos.
   */
  areArraysEqual(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((element: any, index: any) => element === arr2[index]);
  }

  async createRole() {
    this.trialpalService.showSpinner('roles.entity', 'CREATE');
    const role = await this.rolesService.createRole(this.role);
    if (role) {
      this.trialpalService.showMutationSuccess(
        '',
        '',
        '',
        this.trialpalService.translateService.instant(
          'roles.roleCreatedSuccesfully'
        )
      );
      this.trialpalService.ref.close(role);
    }
    this.trialpalService.hideSpinner();
  }

  async getProjects() {
    const projects: any = (await this.projectService.getProjects()).map(
      (project: any) => {
        return { value: project.id, label: project.name };
      }
    );
    return projects;
  }

  forward(): void {
    this.router.navigate(['/roles']);
  }

  onCancelar(): void {
    this.roleForm.reset();
    this.trialpalService.ref.close(null);
  }
}
