import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import { PaginationService } from 'src/app/modules/conciliation/pagination.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TP2Entites } from 'src/app/shared/global.variables';
import { ChangeReasonComponent } from '../../shared/change-reason/change-reason.component';
const logger = new Logger('Comment conciliation');
@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss'],
})
export class EditCommentComponent implements OnChanges {
  @Input() currentComment: any = {};
  @Output() exportAuditRecord: EventEmitter<any> = new EventEmitter();
  @ViewChild('formReason', { static: false })
  formReason?: ChangeReasonComponent;
  form: FormGroup;
  formChange: any = '';
  reasonsItems: any = [];
  isSignatureActive: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private conciliationService: ConciliationService,
    private confirmationService: ConfirmationService,
    private trialpalService: TrialpalService,
    private paginationService: PaginationService
  ) {
    this.form = this.formBuilder.group({
      comment: [''],
      auditChangeReason: [''],
      otherReason: [''],
    });
    this.buildForm();
    this.enabledSignatureButton();
  }

  enabledSignatureButton() {
    this.isSignatureActive =
      this.conciliationService.isEdiaryPhaseInstanceEnded();

    if (this.conciliationService.isConciliationEdition) {
      this.isSignatureActive =
        this.conciliationService.isEdiaryPhaseInstanceEnded() &&
        !this.conciliationService.isPdfConciliationGenerated;
    }
  }

  ngOnChanges() {
    this.buildForm();
    this.form.patchValue({
      comment: this.currentComment?.comment ?? '',
    });
    this.formChange = this.form.value.comment ?? '';
    this.exportAuditRecord.emit({
      entity: TP2Entites.COMMENTCONCILIATIONINSTANCE,
      id: this.currentComment.id,
      description: this.trialpalService.translateService.instant(
        'conciliation.generalComment'
      ),
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      comment: [''],
    });
  }

  async onSubmit() {
    let comment = this.form.value.comment;
    //Edition
    const valuesChanged = this.formChange.trim() !== comment.trim();
    const isFormReasonValid = this.conciliationService.getFormReasonValid(
      this.formReason
    );

    //Si no cambia los comentarios muestra el modal de firma
    if (!valuesChanged) return this.showSignature();

    if (this.form.valid && isFormReasonValid) {
      this.confirmationService.confirm({
        message: this.trialpalService.translateService.instant(
          'conciliation.confirmSaveFormComments'
        ),
        accept: async () => {
          this.conciliationService.recordChangeEvent(
            [{ comment: valuesChanged }],
            [],
            'comment'
          );
          if (this.currentComment?.id) {
            await this.updateCommentConciliation(comment);
          } else {
            await this.createCommentConciliation(comment);
          }
          this.enabledSignatureButton();
        },
      });
    } else {
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
  }

  async createCommentConciliation(comment: string) {
    this.conciliationService
      .createCommentConciliation(comment)
      .then((createComment: any) => {
        this.conciliationService.currentComment = createComment;
        this.conciliationService.messageSuccess(true);
        this.showSignature();
      })
      .catch((error: any) => {
        logger.error(error, 'error created');
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      });
  }

  async updateCommentConciliation(comment: string) {
    const reason = this.conciliationService.getChangeReason(this.formReason);
    this.conciliationService
      .updateCommentConciliation(this.currentComment, comment, reason)
      .then((updateComment: any) => {
        this.conciliationService.currentComment = updateComment;
        this.conciliationService.messageSuccess(false);
        this.showSignature();
      })
      .catch((error: any) => {
        logger.error(error, 'error update');
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      });
  }

  get formComment() {
    return this.form.get('comment');
  }

  previousMedication() {
    this.paginationService.goToPreviousSection();
  }

  showSignature() {
    if (this.isSignatureActive) {
      this.paginationService.showSignature();
    }
  }
}
