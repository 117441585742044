/* eslint-disable prettier/prettier */
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';

import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary/ediary.service';
import {
  ConfEdiaryByProjectQuery,
  ConfFormByConfEdiaryIdQuery,
} from '../../ediary/ediary.types';
import { ProjectService } from '../../project/project.service';
import {
  ConfVisitGroup,
  GetProjectQuery,
  ProjectState,
} from '../../project/project.types';
import { ReportService } from '../report.service';
import {
  ConfReport,
  CreateConfReportInput,
  GetConfReportQuery,
  HelpScreenDisplayOption,
  PageLayout,
  ReportProgramationType,
} from '../report.types';

const logger = new Logger('tp2-logger-reportAddEditPage');

@Component({
  selector: 'app-add-edit-report',
  templateUrl: './add-edit-report.component.html',
  styleUrls: ['./add-edit-report.component.scss'],
})
export class AddEditReportComponent implements OnInit {
  confReport: any = {
    projectId: '',
    reportTitle: '',
    reportIcon: '',
    isSignatureRequired: false,
    areSymptomsRequired: false,
    areAlertsRequired: false,
    isDeviceDataManagementRequired: false,
    isGPSRequired: false,
    isUserIPRequired: false,
    showHelp: false,
    isMediaRequired: false,
    isDeviceDataRequired: false,
    symptomPageLayout: PageLayout.ONE_BY_ONE,
    instancePerDay: false,
    enableLocalNotifications: false,
    plannedDayList: [],
    futureNotifications: 0,
    isAssociatedToVisits: false,
    isDisplayDependentOnTheEDiary: false,
    isDaysOfWeekRequired: false,
    isDateRangeLimitRequired: false,
    availableDays: [],
    startDate: undefined,
    endDate: undefined,
    forms: [],
    isControlledBySite: false,
    groups: [],
    phases: [],
    programationType: ReportProgramationType.ON_DEMAND,
    allowReportBackDating: false,
    availableUsers: [],
    _lastUser: '',
  };
  booleanOptions: any[] = [];
  confReportDesigner!: ConfReport;

  id: any = null;
  expectedVersion: any = null;
  mediaTypes: any[] = [];
  isAssociatedToVisitsDisabled: boolean = false;

  projectId: any = null;
  isConfReportLoaded = false;
  projectGroups: any[] = [];
  projectPhases: any[] = [];
  availableUserOptions: any[] = [];
  projectConfForms: any[] = [];
  icons: any[] = [];
  programationTypes: any[] = [];
  onDemandAlertTypes: any[] = [];
  weekDays: any[] = [];
  isNew = true;
  projectState?: ProjectState;
  dictionary: any[] = [];
  results: string[] = [];
  isSpanish: string = '';
  isEnglish: string = '';
  listVisitGroup: ConfVisitGroup[] = [];
  confReportCopy: any = {};
  isReadMode: boolean = false;
  isConfEdiaryLoaded: boolean = false;
  imageThumbnail: any;
  UPLOAD_PATH: string | undefined;
  iconFile: File | undefined;
  HelpScreenDisplayOptions: any[] = [];

  //permissions
  hasCreateUpdateReportJsonFormPermission: boolean = false;
  hasCreateUpdateReportConfDevicesPermission: boolean = false;
  hasCreateUpdateReportConfMultimediaPermission: boolean = false;
  hasCreateUpdateReportRemindersPermission: boolean = false;
  hasCreateUpdateReportAlertPermission: boolean = false;
  hasDeleteReportAlertPermission: boolean = false;
  hasCreateUpdateReportConfSymptomPermission: boolean = false;
  hasDeleteReportSymptomPermission: boolean = false;
  hasReportUpdatePermission: boolean = false;
  minDate: Date = new Date();

  constructor(
    private readonly trialpalService: TrialpalService,
    private readonly reportService: ReportService,
    private readonly ediaryService: EdiaryService,
    private readonly projectService: ProjectService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private dialogService: DialogService,
    private readonly s3Storage: StorageService,
    private userPermissionsService: UserPermissionsService
  ) {
    this.projectId = this.route.snapshot.params.projectId;
    this.id = this.route.snapshot.params?.id;
    this.isNew = !Boolean(this.id);
    if (this.route.snapshot.url?.length > 1) {
      this.isReadMode = this.route.snapshot.url[1]?.path === 'readMode';
    }
  }

  async ngOnInit() {
    this.trialpalService.showSpinner('report.entity', 'GET');
    this.icons = this.ediaryService.getSymptomIcons();
    await this.setPermissions();

    this.mediaTypes = await this.ediaryService.getMediaTypes();
    this.programationTypes = await this.reportService.getProgramationTypes();
    this.onDemandAlertTypes = await this.reportService.getOnDemandAlertTypes();
    this.availableUserOptions = this.reportService.getAvailableUsers();
    this.weekDays = await this.reportService.getWeekDays();
    this.setBooleanOptions();

    this.HelpScreenDisplayOptions = this.ediaryService
      .getHelpScreenDisplayOption()
      .filter(
        (option) =>
          option.value !== HelpScreenDisplayOption.WITH_SYMPTOM_OCCURRENCY
      );

    this.UPLOAD_PATH = 'conf-report/' + this.projectId + '/icon/';
    if (this.projectId) {
      await this.initializeAndLoadProjectReport();
      await this.loadDictionary();
    } else {
      this.trialpalService.showServiceError(
        'report.messageNoProject',
        'general.error'
      );
      this.isConfReportLoaded = false;
    }
  }

  transformStringToDate(date: string) {
    return this.reportService.transformStringToDate(date);
  }

  showReminderWarning() {
    if (!this.confReport.enableLocalNotifications) return;
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: this.trialpalService.translateService.instant(
        'report.toastReminderWarningTitle'
      ),
      detail: this.trialpalService.translateService.instant(
        'report.toastReminderWarningSummary'
      ),
      life: 5000,
    });
  }

  async setPermissions() {
    //Permitite ingresa solo a los usuarios con estos permisos
    const hasPermission = await this.userPermissionsService.hasPermission(
      [
        TP2Permission.ReportCreate,
        TP2Permission.ReportUpdate,
        TP2Permission.ReportDetail,
        Roles.Admin,
      ],
      true
    );

    if (!hasPermission) return this.onCancel();

    this.hasCreateUpdateReportJsonFormPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportJSONFormCreateUpdate, Roles.Admin],
        false
      );

    this.hasCreateUpdateReportConfDevicesPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportConfDevicesCreateUpdate, Roles.Admin],
        false
      );

    this.hasCreateUpdateReportConfMultimediaPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportConfMultimediaCreateUpdate, Roles.Admin],
        false
      );

    this.hasCreateUpdateReportRemindersPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportRemindersCreateUpdate, Roles.Admin],
        false
      );

    this.hasCreateUpdateReportAlertPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportAlertCreateUpdate, Roles.Admin],
        false
      );

    this.hasDeleteReportAlertPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportAlertDelete, Roles.Admin],
        false
      );

    this.hasCreateUpdateReportConfSymptomPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportConfSymptomCreateUpdate, Roles.Admin],
        false
      );
    this.hasReportUpdatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ReportUpdate,
        Roles.Admin,
      ]);

    this.hasDeleteReportSymptomPermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.ReportSymptomDelete, Roles.Admin],
        false
      );
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  async setImageThumbnail() {
    if (this.confReport.reportIcon?.includes('conf-report')) {
      logger.debug('setImageThumbnail', this.confReport.reportIcon);
      this.imageThumbnail = this.confReport.reportIcon;
      this.iconFile = {
        ...this.imageThumbnail,
        name: this.confReport.reportIcon?.split('/')[3],
      };
    }
  }

  async initializeAndLoadProjectReport(): Promise<void> {
    try {
      const project: GetProjectQuery = await this.projectService.getProject(
        this.projectId
      );
      if (project?.id) {
        this.projectState = project.state;
        this.projectGroups = this.getProjectGroups(project);
        this.getListVisitsGroups(this.confReport);
        await this.generateConfReport();
        this.trialpalService.hideSpinner();
      }
    } catch (error) {
      logger.error('getConfReportById error', error);
      this.trialpalService.hideSpinner();
    }
  }

  getProjectGroups(project: GetProjectQuery) {
    return project.groups.map((group) => {
      const label = this.trialpalService.dictionaryPipe.transform(group);
      return {
        label,
        value: group,
      };
    });
  }

  async generateConfReport(): Promise<void> {
    if (this.id) {
      const confReport: GetConfReportQuery =
        await this.reportService.getConfReport(this.id);
      this.selectKeyLanguage(undefined, confReport.reportTitle);
      this.isNew = false;
      this.updateConfReportFromResponse(confReport);
      this.setStartAndEndDate();
      this.confReport.isDisplayDependentOnTheEDiary === true &&
        this.loadConfEdiary();
      this.setImageThumbnail();
      this.confReportCopy = { ...this.confReport };
    } else {
      this.isConfReportLoaded = true;
      this.confReport.projectId = this.projectId;
      this.isNew = true;
    }
  }

  setStartAndEndDate() {
    this.confReport.startDate = this.transformStringToDate(
      this.confReport.startDate
    );
    this.confReport.endDate = this.transformStringToDate(
      this.confReport.endDate
    );
  }

  loadConfEdiary(): void {
    this.ediaryService
      .getConfEdiaryByProjectId(this.projectId)
      .then((confEdiary: ConfEdiaryByProjectQuery) => {
        if (confEdiary !== null && confEdiary?.items.length > 0) {
          const conf: any = confEdiary.items[0];
          this.ediaryService
            .getConfFormByConfEdiaryId(conf.id)
            .then((confForms: ConfFormByConfEdiaryIdQuery) => {
              if (confForms?.items) {
                this.projectConfForms = confForms.items
                  .filter((e) => e && !e._deleted && e.state !== 'DELETED')
                  .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                  .map((e: any) => {
                    const name = this.trialpalService.dictionaryPipe.transform(
                      e.name
                    );
                    return {
                      name,
                      value: e.id,
                    };
                  });
                this.isConfEdiaryLoaded = true;
              }
            })
            .catch((err) => logger.error('getConfFormByConfEdiaryId', err));
        } else {
          this.trialpalService.showServiceError(
            'report.messageNoProject',
            'general.error'
          );
          this.isConfReportLoaded = false;
        }
      })
      .catch((err) => {
        logger.error('getConfEdiaryByProject error', err);
      });
  }

  updateConfReportFromResponse(data: any): void {
    this.id = data.id;
    this.expectedVersion = data._version;
    this.confReportDesigner = { ...data };
    this.trialpalService.cleanQueryResponse(data);
    delete data['project'];
    this.setValues(data);
    this.getListVisitsGroups(this.confReport);
    this.isConfReportLoaded = true;
  }

  setValues(data: any) {
    Object.keys(data).forEach((element: any) => {
      if (data[element]) {
        this.confReport[element as keyof CreateConfReportInput] = data[element];
      }
    });
  }

  programationTypeChange() {
    if (this.confReport.programationType === ReportProgramationType.ON_DEMAND) {
      this.confReport.plannedDayList = this.confReportCopy.plannedDayList;
      this.isAssociatedToVisitsDisabled = false;
    } else {
      this.confReport.isAssociatedToVisits = true;
      this.isAssociatedToVisitsDisabled = true;
      this.disableWeeklyAndDateLimits();
    }
  }

  disableWeeklyAndDateLimits() {
    if (this.confReport.isDaysOfWeekRequired) {
      this.confReport.isDaysOfWeekRequired = false;
      this.showReminderWarning();
    }

    if (this.confReport.isDateRangeLimitRequired) {
      this.confReport.isDateRangeLimitRequired = false;
    }
  }

  async onSubmit(form: NgForm): Promise<void> {
    const isTimeLapseValid = this.validateAlertTimeLapse();
    this.removeSpaces();
    this.confirmErrors(form);
    if (!this.validateReminders()) {
      logger.debug('validateRemindersError');
      this.trialpalService.showInvalidFormError();
      return;
    }
    if (
      (this.confReport.reportIcon === '' || !this.confReport.reportIcon) &&
      !this.iconFile
    ) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'report.toastIconRequiredTitle'
        ),
        detail: this.trialpalService.translateService.instant(
          'report.toastIconRequiredSummary'
        ),
      });

      form.controls['icon'].markAsTouched();
      return;
    }
    if (
      this.confReport.id &&
      this.confReport.isMediaRequired &&
      this.confReport.mediaTypes?.length === 0
    ) {
      this.trialpalService.showInvalidFormError();
      return;
    }
    if (
      this.confReport.programationType === ReportProgramationType.PLANNED_DAYS
    ) {
      const plannedDays: Array<string> = form.controls['plannedDayList'].value;
      if (plannedDays.length === 0) {
        form.controls['plannedDayList'].setErrors({ incorrect: true });
      }
    }
    if (form.valid && isTimeLapseValid) {
      return await this.showChangeReasonComponent();
    } else if (isTimeLapseValid) {
      this.trialpalService.showInvalidFormError();
    }
  }

  isOndemandOrPlannedDays() {
    return (
      this.confReport.programationType === 'ON_DEMAND' ||
      (this.confReport.programationType === 'PLANNED_DAYS' &&
        this.confReport.allowReportBackDating)
    );
  }

  doesNotHaveFutureNotifications() {
    return (
      !this.confReport.alertType ||
      (!this.confReport.futureNotifications &&
        this.confReport.futureNotifications !== 0)
    );
  }

  isMissingWeeklyOrDailyRelatedFields() {
    return (
      (this.confReport.alertType === 'DAYS' &&
        !this.confReport.alertInBetweenDaysFrequency) ||
      (this.confReport.alertType === 'WEEKLY' &&
        (!this.confReport.alertWeeklyDays ||
          this.confReport.alertWeeklyDays?.length === 0))
    );
  }

  isMissingTransversalReminderFields() {
    return (
      !this.confReport.alertDuringSameDayFrequency ||
      !this.confReport.alertStartTime ||
      !this.confReport.alertEndTime
    );
  }

  validateReminders() {
    if (!this.confReport.id) {
      return true;
    }
    let isValid = true;
    if (this.confReport.enableLocalNotifications) {
      if (this.isOndemandOrPlannedDays()) {
        if (
          this.doesNotHaveFutureNotifications() ||
          this.isMissingWeeklyOrDailyRelatedFields()
        ) {
          isValid = false;
        }
      }
      if (this.isMissingTransversalReminderFields()) {
        isValid = false;
      }
    }

    return isValid;
  }

  async showChangeReasonComponent() {
    if (
      this.imageThumbnail &&
      (this.confReport.reportIcon === '' || !this.confReport.reportIcon)
    ) {
      await this.uploadIcon();
    }
    const originalValue = this.sortReportArrays({ ...this.confReport });
    const copyConfReport = this.sortReportArrays({ ...this.confReportCopy });
    if (this.isNew) {
      this.saveReport();
    } else if (
      JSON.stringify(originalValue) !== JSON.stringify(copyConfReport)
    ) {
      this.saveReport();
    } else {
      this.router.navigate(['report', this.projectId]);
    }
  }

  //Se encarga de ordenar los arreglos dentros del objeto de reporte
  sortReportArrays(object: any) {
    for (let key in object) {
      if (Array.isArray(object[key])) {
        if (key === 'plannedDayList') {
          object[key] = object[key].map((value: string | number) =>
            Number(value)
          );
        }
        object[key] = object[key].sort();
      }
    }

    return object;
  }

  removeSpaces() {
    this.confReport.reportTitle = this.confReport.reportTitle.trim();
  }

  //Valida que el campo no sea totalmente vacio, si lo es, marca error
  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(
      this.confReport.reportTitle,
      'reportTitle',
      form
    );
    if (this.confReport.showHelp) {
      //envia el html a un objeto HMTL y luego obtiene el texto sin espacios
      const htmlObject = document.createElement('div');
      logger.debug('confirmErrors', this.confReport.reportHelp);
      htmlObject.innerHTML = this.confReport.reportHelp ?? '';
      //si contiene una imagen, no se valida
      if (htmlObject.querySelector('img')) {
        return;
      }
      this.trialpalService.validateSpaces(
        htmlObject.innerText.trim(),
        'reportHelp',
        form
      );
    }
  }

  saveReport() {
    this.trialpalService.showSpinner(
      'report.entity',
      this.isNew ? 'CREATE' : 'UPDATE'
    );
    if (this.isNew) {
      this.reportService
        .createConfReport(this.confReport)
        .then((data) => {
          this.trialpalService.showMutationSuccess('report.entity', 'CREATE');
          this.updateConfReportFromResponse(data);
          this.confReport = data;
          this.setStartAndEndDate();
          this.manageRedirect(data.id);
          this.confReportCopy = { ...this.confReport };
          this.isNew = false;
        })
        .catch((err) => logger.error('createConfReport', err))
        .finally(() => this.trialpalService.hideSpinner());
    } else {
      this.reportService
        .updateConfReport(this.confReport, this.id, this.expectedVersion)
        .then((data) => {
          this.trialpalService.showMutationSuccess('report.entity', 'UPDATE');
          this.router.navigate(['report', this.projectId]);
        })
        .catch((err) => logger.error('updateConfReport', err))
        .finally(() => this.trialpalService.hideSpinner());
    }
  }

  manageRedirect(confReportId: string) {
    // Obtiene la ruta base actual
    const currentUrl = this.router.url.split('/create')[0];

    // Construye la nueva URL con los valores deseados
    const newUrl = `${currentUrl}/edit/${confReportId}`;

    // Cambia la URL en la barra de direcciones del navegador
    window.history.pushState({}, '', newUrl);
  }

  async uploadIcon() {
    if (!this.iconFile) {
      return;
    }
    const putResult = await this.s3Storage.put(
      this.iconFile,
      this.UPLOAD_PATH + this.iconFile.name,
      `${this.iconFile.name.replace(/ /g, '_')}_${new Date().getTime()}`,
      'public'
    );
    if (putResult) {
      logger.debug('uploadIcon', putResult);
      const originalUrl = await this.s3Storage.get(putResult.key);
      const parsedUrl = originalUrl.split('?')[0];
      this.confReport.reportIcon = parsedUrl;
      logger.debug('uploadIcon', this.confReport.reportIcon);
    } else {
      logger.error('uploadIcon', putResult);
    }
  }

  onChangeShowHelp(): void {
    this.confReport.reportHelp = null;
  }

  onAddDay(data: string): void {
    const input = Number.parseInt(data);
    if (
      (data.startsWith('0') && data.length > 1) ||
      data.includes('.') ||
      data.includes(',') ||
      Number.isNaN(input) ||
      !Number.isInteger(input) ||
      input > 365 ||
      input < 0 ||
      input === -0
    ) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          !Number.isNaN(input) && input > 365
            ? 'report.toastInvalidDayUpper'
            : 'report.toastInvalidDay'
        ),
      });

      if (this.confReport.plannedDayList) {
        this.confReport.plannedDayList.pop();
      }
    }
  }

  validateAlertTimeLapse(): boolean {
    if (
      (this.confReport.alertStartTime &&
        this.confReport.alertEndTime &&
        this.confReport.alertStartTime >= this.confReport.alertEndTime) ||
      (this.confReport.alertStartTime && !this.confReport.alertEndTime) ||
      (!this.confReport.alertStartTime && this.confReport.alertEndTime)
    ) {
      const title =
        this.trialpalService.translateService.instant('general.attention');
      const body = this.trialpalService.translateService.instant(
        'report.errorAlertTimeLapse'
      );
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: title,
        detail: body,
      });
      return false;
    }
    if (this.confReport.alertStartTime?.length === 0) {
      this.confReport.alertStartTime = null;
    }
    if (this.confReport.alertEndTime?.length === 0) {
      this.confReport.alertEndTime = null;
    }
    return true;
  }

  onCancel(): void {
    this.router.navigate(['report', this.projectId]);
  }

  onDictionaty(
    isForm: boolean,
    label?: string,
    isGlobal: boolean = false
  ): void {
    const ref = this.trialpalService.dialogService.open(
      TableLanguagesComponent,
      {
        data: {
          projectId: this.projectId,
          isForm: isForm,
          isGlobal: isGlobal,
        },
        width: '95%',
        height: '95%',
      }
    );

    ref.onClose.subscribe(async (data: any) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        await this.loadDictionary()
          .then(() => {
            this.trialpalService.messageService.add({
              severity: 'success',
              summary: this.trialpalService.translateService.instant(
                'dictionary.selectTranslate'
              ),
              detail: this.trialpalService.translateService.instant(
                'dictionary.succesSelectTranslate'
              ),
            });
          })
          .catch((err) => logger.error('No se pudo cargar el diccionario', err))
          .finally(() => {
            if (label === 'title') {
              this.confReport.reportTitle = data.key;
              this.selectKeyLanguage(undefined, data.key);
            }
            if (label === 'jsonFom') {
              this.confReport.jsonForm = data.key;
            }
          });
        this.trialpalService.hideSpinner();
      }
    });
  }

  search(event: any) {
    this.isSpanish = '';
    this.isEnglish = '';
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  selectKeyLanguage(event: any, titleReport?: string) {
    this.dictionary.forEach((data) => {
      if (data.key === event || data.key === titleReport) {
        this.isSpanish = data.spanish;
        this.isEnglish = data.english;
      }
    });
  }

  async loadDictionary() {
    this.dictionary = [];
    await this.trialpalService.dictionaryService
      .getConfDictionaryByProjectId(this.projectId)
      .then((dictionary) => {
        dictionary.items?.forEach((item) => {
          const obj: Dictionary = {
            id: item?.id!,
            key: item?.key!,
            isHtml: item?.isHtml ?? false,
            spanish: item?.spanish ?? '',
            english: item?.english ?? '',
          };
          this.dictionary.push(obj);
        });
        localStorage.removeItem('Dictionaty');
        localStorage.setItem('Dictionary', JSON.stringify(dictionary));
      })
      .catch((err) => logger.error('No se pudo cargar el diccionario', err));
  }

  onFileChange(event: any) {
    logger.debug('onFileChange', event);
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files[0]?.type?.includes('image')) {
      this.confReport.reportIcon = '';
      this.iconFile = files[0];
      logger.debug('onFileChange', files[0]);
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageThumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
      // limpia el input para que se pueda subir el mismo archivo
      target.value = '';
    }
  }

  changeGroups() {
    this.getPhases(this.confReport.groups);
  }

  getListVisitsGroups(data: any) {
    //Obtiene las visitas según los grupos configurados o seleccionados
    this.projectService
      .getConfVisitGroup(this.projectId)
      .then((response: any) => {
        this.listVisitGroup = response;
        //Muestra las visitas asociadas a los grupos escogidos anteriormente
        this.getPhases(data.groups || []);
      });
  }

  //Obtiene las visitas correspondientes al grupo seleccionado
  getPhases(groupNames: string[]) {
    let phases: any[] = [];
    //Añade en un array todas las visitas que esten relacionadas al array de grupos
    groupNames.forEach((groupName) => {
      let phasesGroup: any[] = this.listVisitGroup
        .filter((visitGroup: any) => visitGroup.group === groupName)
        .map((visitGroup: any) => ({
          value: visitGroup.visit,
          label: this.trialpalService.dictionaryPipe.transform(
            visitGroup.visit
          ),
        }));
      phases.push(...phasesGroup);
    });
    //Eliminas las visitas duplicadas
    phases = phases.filter((visit: any, index: number) => {
      return phases.findIndex((x: any) => x.value === visit.value) === index;
    });
    //Elimina las visitas seleccionadas que ya no se encuentran disponibles
    this.confReport.phases = this.confReport.phases.filter((phase: string) => {
      return phases.findIndex((x: any) => x.value === phase) !== -1;
    });
    this.projectPhases = phases;
  }

  openAuditModal() {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity: TP2Entites.CONFREPORT,
        description: this.trialpalService.dictionaryPipe.transform(
          this.confReport.reportTitle
        ),
        idRecord: this.confReport.id,
      },
      width: '95%',
      height: '95%',
    });
  }

  deleteIcon() {
    this.imageThumbnail = null;
    this.iconFile = undefined;
    this.confReport.reportIcon = '';
  }
  cutLabel(label: string | undefined) {
    if (!label) {
      return '';
    }
    return label.length > 20 ? label.slice(0, 20) + '...' : label;
  }
  actionDisplayDependentOnEdiary() {
    if (this.confReport?.isDisplayDependentOnTheEDiary) {
      !this.isConfEdiaryLoaded && this.loadConfEdiary();
      this.confReport.isControlledBySite = false;
    }
    if (
      this.confReportCopy?.isDisplayDependentOnTheEDiary === true &&
      !this.confReport?.isDisplayDependentOnTheEDiary
    ) {
      this.trialpalService.messageService.add({
        severity: 'success',
        summary: this.trialpalService.translateService.instant(
          'report.toastConfigDisabledTitle'
        ),
        detail: this.trialpalService.translateService.instant(
          'report.toastConfigDisabledSummary'
        ),
      });
    }
    if (
      this.confReportCopy?.isDisplayDependentOnTheEDiary === false &&
      this.confReport?.isDisplayDependentOnTheEDiary &&
      Array.isArray(this.confReport?.forms) &&
      this.confReport?.forms.length > 0
    ) {
      this.trialpalService.messageService.add({
        severity: 'success',
        summary: this.trialpalService.translateService.instant(
          'report.toastConfigEnabledTitle'
        ),
        detail: this.trialpalService.translateService.instant(
          'report.toastConfigEnabledSummary'
        ),
      });
    }
  }
}
