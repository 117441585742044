import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ReconciliationSectionsComponent } from 'src/app/modules/configurations/components/reconciliation-sections/reconciliation-sections.component';
import { TemperatureConfigureComponent } from 'src/app/modules/configurations/components/temperature/temperatureConfigure.component';
import { ProjectService } from 'src/app/modules/project/project.service';
import { PageLayout } from 'src/app/modules/report/report.types';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { MediaType } from 'src/app/services/trialpal.types';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { ConfigurationsSymtomsMedicationsAppComponent } from 'src/app/shared/components/configurations-symtoms-medication/configurations-symtoms-medication.component';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { DictionaryService } from 'src/app/shared/components/table-languages/dictionary.service';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';
import {
  SectionsToConcilied,
  TP2Entites,
} from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import {
  ConfFormConfSymptom,
  ConfSymptomByConfFormIdQuery,
  CreateConfFormConfSymptomInput,
  CreateConfFormInput,
  DeleteConfFormConfSymptomInput,
  HelpScreenDisplayOption,
  SymptomType,
} from '../../ediary.types';

const logger = new Logger('tp2-logger-formAddEditPage');

@Component({
  selector: 'app-form-add-edit',
  templateUrl: './form-add-edit.component.html',
  styleUrls: ['./form-add-edit.component.scss'],
})
export class FormAddEditComponent implements OnInit {
  confForm: CreateConfFormInput = {
    projectId: this.projectService.currentProject?.id || '',
    name: '',
    isTemperatureRequired: false,
    isMedicationRequired: false,
    isMedicalAttentionRequired: false,
    isReconciliationRequired: false,
    sectionsToReconcile: [],
    isMediaRequired: false,
    areCommentsRequired: false,
    isOtherForm: false,
    mediaTypes: new Array<MediaType>(),
    areOtherSymptomsRequired: false,
    symptomPageLayout: PageLayout.ONE_BY_ONE,
    confEdiaryId: '',
    showHelp: false,
    _lastUser: '',
  };

  ediarySymptoms: any = [];
  ediaryLocalSymptoms: any = [];
  selectedSymptoms: any = [];
  selectedLocalSymptoms: any = [];
  originalSelectedSymptoms: any = [];
  selectedSymptomsToShow: any = [];
  selectedLocalSymptomsToShow: any = [];
  currentFormSymptoms: any = [];

  confEdiaryId: any = null;
  isEdition = false;
  translate: any;
  translateGeneral: any;
  translateEnums: any;
  translateSymptoms: any;
  mediaTypes: any[] = [];
  pageLayouts: any[] = [];
  private id: any = null;
  private expectedVersion: any = null;
  symptomToAdd: any = null;
  dictionary: Dictionary[] = [];
  sectionsToConcilied: { label: string; value: string }[] = [];
  results: string[] = [];
  projectId: string = '';
  isSpanish: string = '';
  isEnglish: string = '';
  confFormCopy: any = {};
  originalForm: string = '';
  HelpScreenDisplayOptions: any[] = [];

  readMode: boolean = false;
  constructor(
    private trialpalService: TrialpalService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private translateService: TranslateService,
    private projectService: ProjectService,
    public dialogService: DialogService,
    private readonly dictionaryService: DictionaryService
  ) {}

  ngOnInit(): void {
    this.confEdiaryId = this.config.data.confEdiaryId;
    this.dictionary = this.config.data.dictionary;
    this.projectId = this.config.data.projectId;
    this.confFormCopy = this.config.data.confForm;
    this.readMode = this.config.data.readMode;
    this.HelpScreenDisplayOptions = this.ediaryService
      .getHelpScreenDisplayOption()
      .filter(
        (item) => item.value !== HelpScreenDisplayOption.WITH_SYMPTOM_OCCURRENCY
      );

    this.translateService
      .get('ediary.components.formAddEdit')
      .subscribe((res) => (this.translate = res));
    this.translateService
      .get('general')
      .subscribe((res) => (this.translateGeneral = res));
    this.translateService
      .get('ediary.enums')
      .subscribe((res) => (this.translateEnums = res));
    this.translateService
      .get('symptom.enums')
      .subscribe((res) => (this.translateSymptoms = res));
    this.trialpalService.showSpinner(this.translateGeneral.loadingInfo);
    this.ediaryService.getMediaTypes().then((res) => (this.mediaTypes = res));
    this.ediaryService.getPageLayouts().then((res) => (this.pageLayouts = res));
    this.ediaryService
      .getConfSymptomByConfEdiaryId(this.confEdiaryId)
      .then((res) => {
        if (res.items) {
          this.ediarySymptoms = res.items
            .filter((e) => !e?._deleted && e?.type === SymptomType.GENERAL)
            .map((e: any) => {
              return {
                name: this.generateSymptomName(e),
                value: e.id,
              };
            });
          this.ediaryLocalSymptoms = res.items
            .filter(
              (confSymptom: any) =>
                !confSymptom?._deleted && confSymptom.type === SymptomType.LOCAL
            )
            .map((e: any) => {
              return {
                name: this.generateSymptomName(e),
                value: e.id,
              };
            })
            .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
          this.trialpalService.hideSpinner();
          if (this.confFormCopy) {
            this.loadInfo();
            this.selectKeyLanguage(undefined, this.confForm.name);
          }
          this.ediarySymptoms.sort((a: any, b: any) =>
            a.name > b.name ? 1 : -1
          );
        }
      })
      .catch((err) => {
        logger.error('getConfSymptomByConfEdiaryId', err);
        this.trialpalService.hideSpinner();
      });
  }

  private generateSymptomName(e: any): string {
    const symptomLabel = e.symptomLabel
      ? this.getNameTranslate(e.symptomLabel) + ' ('
      : '';
    const symptom = this.translateSymptoms.symptoms[e.symptom] || '';
    const injectionSite =
      e.injectionSite && this.translateSymptoms.injectionSites[e.injectionSite]
        ? ' (' + this.translateSymptoms.injectionSites[e.injectionSite]
        : '';
    const injectionSide =
      e.injectionSide && this.translateSymptoms.injectionSides[e.injectionSide]
        ? ' - ' + this.translateSymptoms.injectionSides[e.injectionSide]
        : '';

    return (
      symptomLabel +
      symptom +
      injectionSite +
      injectionSide +
      (injectionSite ? ')' : '') +
      (symptomLabel ? ')' : '')
    );
  }

  setSectionsToReconciled() {
    this.sectionsToConcilied = [];
    const translationRoute = 'ediary.components.generalConfiguration';
    const sections: any = {
      isOtherForm: {
        label: this.trialpalService.translateService.instant(
          `${translationRoute}.requiredSymptoms`
        ),
        value: SectionsToConcilied.SOLICITED_SYMPTOMS,
      },
      isTemperatureRequired: {
        label: this.trialpalService.translateService.instant(
          `${translationRoute}.labelTemperature`
        ),
        value: SectionsToConcilied.TEMPERATURE,
      },
      areOtherSymptomsRequired: {
        label: this.trialpalService.translateService.instant(
          `${translationRoute}.labelOtherSymptom`
        ),
        value: SectionsToConcilied.OTHER_SYMPTOMS,
      },
      isMedicationRequired: {
        label: this.trialpalService.translateService.instant(
          `${translationRoute}.labelMedications`
        ),
        value: SectionsToConcilied.MEDICATION,
      },
      isMedicalAttentionRequired: {
        label: this.trialpalService.translateService.instant(
          `${translationRoute}.labelMedicalAttention`
        ),
        value: SectionsToConcilied.MEDICAL_ATTENTION,
      },
    };

    if (!this.confForm.isReconciliationRequired) {
      this.confForm.sectionsToReconcile = [];
      return;
    }

    const confForm: any = this.confForm;
    //Recorre las configuraciones y valida que secciones estan activas
    for (const key in sections) {
      //Valida si la configuracion esta activa (En caso de sintomas valida si isOtherForm es false)
      this.setReconciledSection(confForm, key, sections);
    }
  }

  setReconciledSection(confForm: any, key: string, sections: any) {
    const isConfigurationActive =
      key === 'isOtherForm' ? !confForm[key] : confForm[key];

    if (isConfigurationActive) {
      if (key === 'isOtherForm') {
        this.addRemoveSection('generalSymptom', sections, key);
        this.addRemoveSection('localSymtoms', sections, key);
      } else {
        this.sectionsToConcilied.push({
          label: sections[key].label,
          value: sections[key].value,
        });
      }
    } else {
      this.confForm.sectionsToReconcile =
        this.confForm.sectionsToReconcile?.filter(
          (section) => section !== sections[key].value
        );
    }
  }

  addRemoveSection(
    section: 'generalSymptom' | 'localSymtoms',
    sections: any,
    key: string
  ) {
    if (section === 'generalSymptom') {
      if (this.selectedLocalSymptoms.length > 0) {
        this.sectionsToConcilied.push({
          label: this.trialpalService.translateService.instant(
            'ediary.components.formAddEdit.labelLocalSymptomsRequired'
          ),
          value: SectionsToConcilied.LOCAL_SYMPTOMS,
        });
      } else {
        this.sectionsToConcilied = this.sectionsToConcilied.filter(
          (STC) => STC.value !== SectionsToConcilied.LOCAL_SYMPTOMS
        );
      }
    } else if (section === 'localSymtoms') {
      if (this.selectedSymptoms.length > 0) {
        this.sectionsToConcilied.push({
          label: sections[key].label,
          value: sections[key].value,
        });
      } else {
        this.sectionsToConcilied = this.sectionsToConcilied.filter(
          (STC) => STC.value !== SectionsToConcilied.SOLICITED_SYMPTOMS
        );
      }
    }
  }
  getNameTranslate(name: string) {
    return this.trialpalService.dictionaryPipe.transform(name);
  }
  private loadInfo(): void {
    this.isEdition = true;
    this.id = this.confFormCopy.id;
    this.expectedVersion = this.confFormCopy._version;
    delete this.confFormCopy.__typename;
    delete this.confFormCopy.createdAt;
    delete this.confFormCopy.updatedAt;
    delete this.confFormCopy._deleted;
    delete this.confFormCopy._lastChangedAt;
    delete this.confFormCopy.confEdiary;
    Object.assign(this.confForm, this.confFormCopy);
    this.originalForm = JSON.stringify(this.confForm);
    this.trialpalService.showSpinner(this.translateGeneral.loadingInfo);
    this.setSectionsToReconciled();
    this.ediaryService
      .getConfSymptomListByConfFormId(this.id)
      .then((res: ConfSymptomByConfFormIdQuery) => {
        const selectedItems: any = [];
        const selectedLocalItems: any = [];
        if (res.items) {
          res.items
            .filter((e) => !e?._deleted)
            .forEach((data: any) => {
              if (data.confSymptomId) {
                this.currentFormSymptoms.push(data);
                this.ediarySymptoms.some(
                  (symptom: any) => symptom.value === data.confSymptomId
                ) && selectedItems.push(data.confSymptomId);
                this.ediaryLocalSymptoms.some(
                  (symptom: any) => symptom.value === data.confSymptomId
                ) && selectedLocalItems.push(data.confSymptomId);
              }
            });
        }
        this.selectedSymptoms = selectedItems;
        this.selectedLocalSymptoms = selectedLocalItems;

        this.originalSelectedSymptoms = JSON.parse(
          JSON.stringify(selectedItems.concat(selectedLocalItems))
        );
        this.setSectionsToReconciled();
        this.updateSelectedSymptomsLabel().then(() => {
          this.trialpalService.hideSpinner();
        });
      })
      .catch((err) => {
        logger.error('getConfymptomListByConfFormId', err);
        this.trialpalService.hideSpinner();
      });
  }

  isOtherSyptomChange() {
    if (this.confForm.isOtherForm) {
      this.selectedSymptoms = [];
      this.selectedLocalSymptoms = [];
      this.selectedSymptomsToShow = [];
      this.selectedLocalSymptomsToShow = [];
      //Si el formulario es de otros sintomas no solicita temperatura
      this.confForm.isTemperatureRequired = false;
    }
  }

  async onSubmit(form: NgForm) {
    try {
      this.removeSpaces();
      this.confirmErrors(form);
      if (form.valid) {
        if (this.confForm.isOtherForm) {
          //Verifica si almenos hay un configuración escogido
          const isCanPass = this.isOneConfigurationChoose();
          if (!isCanPass) return;
        }
        this.trialpalService.showSpinner(this.translate.savingForm);
        await this.saveConfForm();
        this.trialpalService.hideSpinner();
      } else {
        this.trialpalService.showInvalidFormError();
      }
    } catch (error) {
      logger.debug(error, 'SubmitError');
      this.ref.close(null);
      this.trialpalService.hideSpinner();
    }
  }

  async saveConfForm() {
    if (this.isEdition) {
      if (
        this.originalForm !== JSON.stringify(this.confForm) ||
        this.selectedSymptoms !== this.originalSelectedSymptoms
      ) {
        return this.openChangeReason();
      }
      return this.ref.close(this.confForm);
    }
    const confForm = await this.createConfForm();
    return this.ref.close(confForm);
  }

  async createConfForm(): Promise<any> {
    this.confForm.confEdiaryId = this.confEdiaryId;
    const confForm = await this.ediaryService.createConfForm(this.confForm);
    await this.addSymptomsToForm(confForm.id);
    return confForm;
  }

  async updateConfForm(): Promise<any> {
    const confFormCopy: any = { ...this.confForm };
    delete confFormCopy.project;
    delete confFormCopy.__typename;
    delete confFormCopy.createdAt;
    delete confFormCopy.updatedAt;
    delete confFormCopy._deleted;
    delete confFormCopy._lastChangedAt;
    delete confFormCopy.confEdiary;
    const confForm = await this.ediaryService.updateConfForm(
      confFormCopy,
      this.id,
      this.expectedVersion
    );

    await this.addSymptomsToForm(confForm.id);
    return confForm;
  }

  isOneConfigurationChoose() {
    if (
      this.confForm.areCommentsRequired ||
      this.confForm.isMedicationRequired ||
      this.confForm.areOtherSymptomsRequired ||
      this.confForm.isMedicalAttentionRequired
    ) {
      return true;
    } else {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        life: 3000,
        summary: this.trialpalService.translateService.instant(
          'general.formErrorSummary'
        ),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.formAddEdit.messageError'
        ),
      });
      return false;
    }
  }

  onCancel(): void {
    this.ref.close(
      this.confForm._version !== this.confFormCopy._version
        ? this.confForm
        : null
    );
  }

  removeSpaces() {
    this.confForm.name = this.confForm.name.trim();
  }

  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(this.confForm.name, 'name', form);
    if (this.confForm.showHelp) {
      //envia el html a un objeto HMTL y luego obtiene el texto sin espacios
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = this.confForm.formHelp ?? '';
      this.trialpalService.validateSpaces(
        htmlObject.innerText.trim(),
        'formHelp',
        form
      );
    }
  }
  private addSymptomsToForm(formId: string): Promise<any> {
    logger.debug('currentConfSymptoms', this.currentFormSymptoms);
    logger.debug('selectedSymptoms', this.selectedSymptoms);
    const symptomsToAdd = this.selectedSymptoms
      .concat(this.selectedLocalSymptoms)
      .filter(
        (e: string) =>
          !this.currentFormSymptoms.find((f: any) => f.confSymptomId === e)
      );
    logger.debug('symptomsToAdd', symptomsToAdd);
    return new Promise<any>((resolve, reject) => {
      const promisesArray: any = [];
      symptomsToAdd.forEach((symptom: any) => {
        const input: CreateConfFormConfSymptomInput = {
          projectId: this.projectService.getCurrentProject().id,
          confFormId: formId,
          confSymptomId: symptom,
          _lastUser: '',
        };
        promisesArray.push(this.ediaryService.createConfFormConfSymptom(input));
      });
      const symptomsToRemove = this.currentFormSymptoms.filter(
        (e: ConfFormConfSymptom) =>
          !this.selectedSymptoms
            .concat(this.selectedLocalSymptoms)
            .find((f: string) => f === e.confSymptomId)
      );
      logger.debug('symptomsToRemove', symptomsToRemove);
      symptomsToRemove
        .filter((e: ConfFormConfSymptom) => !e._deleted)
        .forEach((symptom: ConfFormConfSymptom) => {
          const input: DeleteConfFormConfSymptomInput = {
            id: symptom.id || '',
            _version: symptom._version,
          };
          promisesArray.push(
            this.ediaryService.deleteConfFormConfSymptom(input)
          );
        });
      Promise.all(promisesArray)
        .then((res) => resolve(res))
        .catch((err) => reject(new Error(err)));
    });
  }

  updateSelectedSymptomsLabel(): Promise<any> {
    return new Promise((resolve) => {
      const data: Array<string> = [];
      const localData: Array<string> = [];
      this.selectedSymptoms.forEach((id: string) => {
        const symptom = this.ediarySymptoms.find((e: any) => e.value === id);
        if (symptom) {
          data.push(symptom.name);
        }
      });
      this.selectedSymptomsToShow = data;
      this.selectedLocalSymptoms.forEach((id: string) => {
        const symptom = this.ediaryLocalSymptoms.find(
          (e: any) => e.value === id
        );
        if (symptom) {
          localData.push(symptom.name);
        }
      });
      this.selectedSymptomsToShow = data;
      this.selectedLocalSymptomsToShow = localData;

      resolve(true);
    });
  }
  onDictionaty(isForm: boolean): void {
    const ref = this.dialogService.open(TableLanguagesComponent, {
      data: {
        projectId: this.projectId,
        isForm: isForm,
      },
      width: '95%',
      height: '95%',
    });
    ref.onClose.subscribe(async (data) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        this.dictionary = [];
        await this.dictionaryService
          .getConfDictionaryByProjectId(this.projectId)
          .then((dictionary: any) => {
            dictionary.items?.forEach((item: Dictionary) => {
              const obj: any = {
                id: item?.id,
                key: item?.key,
                isHtml: item?.isHtml ?? false,
                spanish: item?.spanish! ?? '',
                english: item?.english! ?? '',
              };
              this.dictionary.push(obj);
            });
            localStorage.removeItem('Dictionaty');
            localStorage.setItem('Dictionary', JSON.stringify(dictionary));
            this.messageService.add({
              severity: 'success',
              summary: this.trialpalService.translateService.instant(
                'dictionary.selectTranslate'
              ),
              detail: this.trialpalService.translateService.instant(
                'dictionary.succesSelectTranslate'
              ),
            });
          })
          .catch((err: any) =>
            logger.error('No se pudo cargar el diccionario', err)
          )
          .finally(() => {
            this.confForm.name = data.key;
            this.selectKeyLanguage(undefined, data.key);
          });
        this.trialpalService.hideSpinner();
      }
    });
  }

  selectKeyLanguage(event: any, titleReport?: string) {
    return this.dictionary.map((data) => {
      if (data.key === event || data.key === titleReport) {
        this.isSpanish = data.spanish ?? '';
        this.isEnglish = data.english ?? '';
      }
    });
  }

  search(event: any) {
    this.isSpanish = '';
    this.isEnglish = '';
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  onChangeShowHelp(): void {
    this.confForm.formHelp = null;
  }

  onConfiguration(
    entityConfiguration:
      | 'medication'
      | 'otherSymptom'
      | 'medicalAttention'
      | 'multimedia'
      | 'temperature'
      | 'reconciliation'
  ) {
    /**
     * @desc abrir el modal de configuracion dependiendo de la entidad
     */

    //TO DO se debe crear un switch para validar las entidades
    //TO DO temporalmente esta creado solo el componete de configuracion individual de temperatura, los demas estan en un solo componente
    let component;

    switch (entityConfiguration) {
      case 'temperature':
        component = TemperatureConfigureComponent;
        break;
      case 'reconciliation':
        component = ReconciliationSectionsComponent;
        break;
      default:
        component = ConfigurationsSymtomsMedicationsAppComponent;
        break;
    }

    const ref = this.dialogService.open(component, {
      data: {
        projectId: this.projectId,
        confEdiaryId: this.confEdiaryId,
        formId: this.confFormCopy.id,
        confForm: this.confForm,
        entityConfiguration: entityConfiguration,
        dictionary: this.dictionary,
        isForm: true,
        readMode: this.readMode,
        sectionsToConcilied: this.sectionsToConcilied,
      },
      width: '80%',
      height: 'auto',
    });
    ref.onClose.subscribe((res: any) => {
      if (res) {
        this.confForm = { ...res };
      }
    });
  }
  openAuditModal() {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity: TP2Entites.CONFFORM,
        description: this.trialpalService.translateService.instant(
          'ediary.components.formAddEdit.labelLegendEdit'
        ),
        idRecord: this.confForm.id,
      },
      width: '95%',
      height: '95%',
    });
  }
  async openChangeReason() {
    const confForm = await this.updateConfForm();
    this.ref.close(confForm);
  }
}
