<div *ngIf="confICSections.length > 0">
  <div class="p-mb-4">
    <h3 class="p-m-0">
      {{ 'informedConsent.sections.sectionsList' | translate }}
    </h3>
  </div>
  <div class="grid-cards" *ngIf="sectionCards.length > 0">
    <app-tp2-card
      *ngFor="let section of sectionCards"
      [tp2Card]="section"
      (tp2CardEmmiter)="detailEmitter.emit(section?.data)"
    ></app-tp2-card>
  </div>
</div>
<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="onDelete()"
  position="fixed"
>
</app-delete-confirmation>
