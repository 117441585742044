<div class="container-tp2" *ngIf="isConfReportLoaded">
  <form (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div style="display: flex; justify-content: end" class="p-mb-3">
      <button
        pButton
        id="dictionaryButton"
        name="dictionaryButton"
        class="p-button-secondary"
        type="button"
        (click)="onDictionaty(false, '', true)"
        label="{{ 'dictionary.labelButton' | translate }}"
      ></button>
      <button
        pButton
        label="{{ 'general.btnAuditTrace' | translate }}"
        type="button"
        icon="pi pi-file"
        iconPos="right"
        (click)="openAuditModal()"
        badgeClass="color: white;"
        class="p-ml-2 p-button-success pbutton"
        *ngIf="confReport.id && false"
      ></button>
    </div>
    <div class="p-grid p-fluid p-justify-end">
      <div class="p-col-12">
        <p-accordion>
          <p-accordionTab
            header="{{ 'report.generalConfiguration' | translate }}"
            [selected]="true"
          >
            <ng-template pTemplate="content">
              <p-card>
                <div class="p-grid p-fluid">
                  <div class="p-col-12 p-mb-4">
                    <app-form-step-guide
                      [step]="1"
                      [description]="'report.step1Description' | translate"
                      [additionalDescription]="
                        'report.step1AdditionalDescription' | translate
                      "
                    ></app-form-step-guide>
                  </div>
                  <!--Asociacion de Grupos-->
                  <div class="p-col-12 p-md-6 p-field">
                    <label for="groups"
                      >{{ 'general.groups' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-multiSelect
                      id="groups"
                      name="groups"
                      #groups="ngModel"
                      [options]="projectGroups"
                      [(ngModel)]="confReport.groups"
                      [required]="true"
                      (onChange)="changeGroups()"
                      [maxSelectedLabels]="3"
                      optionLabel="label"
                      optionValue="value"
                      emptyFilterMessage="{{
                        'general.emptyFilterMessage' | translate
                      }}"
                      [disabled]="isReadMode"
                    ></p-multiSelect>
                    <small
                      class="form-error"
                      *ngIf="!groups.valid && (f.submitted || groups.touched)"
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <!--Asociacion de visitas-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                  >
                    <label for="associatedToVisits">{{
                      'report.labelAssociateToVisits' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        required
                        id="associatedToVisits"
                        name="associatedToVisits"
                        #associatedToVisits="ngModel"
                        [(ngModel)]="confReport.isAssociatedToVisits"
                        [options]="booleanOptions"
                        [disabled]="isAssociatedToVisitsDisabled"
                        optionLabel="label"
                        optionValue="value"
                        tooltipPosition="top"
                      ></p-selectButton>
                    </div>
                  </div>

                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="confReport.isAssociatedToVisits"
                  >
                    <label for="phases"
                      >{{ 'general.phases' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-multiSelect
                      id="phases"
                      name="phases"
                      #phases="ngModel"
                      [options]="projectPhases"
                      [(ngModel)]="confReport.phases"
                      [required]="true"
                      optionLabel="label"
                      optionValue="value"
                      [maxSelectedLabels]="3"
                      emptyFilterMessage="{{
                        'general.emptyFilterMessage' | translate
                      }}"
                      [disabled]="isReadMode"
                    ></p-multiSelect>
                    <small
                      class="form-error"
                      *ngIf="!phases.valid && (f.submitted || phases.touched)"
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <!--Titulo del reporte-->
                  <div class="p-col-12 p-md-6 p-field">
                    <label for="reportTitle"
                      >{{ 'report.labelReportTitle' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <div class="p-inputAutocompleteButton">
                      <p-autoComplete
                        class="p-flex-1"
                        [ngStyle]="{ width: '100%' }"
                        #reportTitle="ngModel"
                        id="reportTitle"
                        name="reportTitle"
                        [(ngModel)]="confReport.reportTitle"
                        [suggestions]="results"
                        [required]="true"
                        (completeMethod)="search($event)"
                        (onSelect)="selectKeyLanguage($event)"
                        [disabled]="isReadMode"
                      >
                      </p-autoComplete>
                      <button
                        id="dictionaryButtonReport"
                        name="dictionaryButtonReport"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-book"
                        (click)="onDictionaty(true, 'title')"
                        class="
                          p-button-rounded
                          p-button-secondary
                          p-button-outlined
                          p-ml-2
                          p-ripple
                          p-button
                          p-component
                          p-button-icon-only
                        "
                        title="{{ 'dictionary.labelButton' | translate }}"
                        [disabled]="isReadMode"
                      ></button>
                    </div>
                    <div
                      class="p-d-flex p-flex-column"
                      *ngIf="reportTitle.valid"
                    >
                      <small *ngIf="isSpanish" [title]="isSpanish"
                        ><label>ES:</label>
                        {{ isSpanish | shortText: 110 }}</small
                      >
                      <small *ngIf="isEnglish" [title]="isEnglish"
                        ><label>EN:</label>
                        {{ isEnglish | shortText: 110 }}</small
                      >
                    </div>

                    <small
                      class="form-error"
                      *ngIf="
                        !reportTitle.valid &&
                        (f.submitted || reportTitle.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <!--Icono del reporte-->
                  <div class="p-col-12 p-md-2 p-field">
                    <label for="icon">{{
                      'report.labelIcon' | translate
                    }}</label
                    ><span class="inputRequired"> *</span>
                    <p-dropdown
                      appendTo="body"
                      placeholder="{{
                        'general.selectPlaceholder' | translate
                      }}"
                      [options]="icons"
                      optionValue="value"
                      [showClear]="true"
                      [required]="true"
                      id="icon"
                      name="icon"
                      #icon="ngModel"
                      [(ngModel)]="confReport.reportIcon"
                      [disabled]="isReadMode || imageThumbnail"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div>
                          <img
                            *ngIf="confReport.reportIcon"
                            style="vertical-align: middle"
                            src="{{
                              '/../assets/icons/' +
                                confReport.reportIcon +
                                '.png'
                            }}"
                            width="40px"
                            height="40px"
                            alt=""
                          />
                          <span>{{ confReport.reportIcon ? '' : '---' }}</span>
                        </div>
                      </ng-template>
                      <ng-template let-symptom pTemplate="item">
                        <div>
                          <img
                            style="vertical-align: middle"
                            src="{{
                              '/../assets/icons/' + symptom.value + '.png'
                            }}"
                            width="40px"
                            height="40px"
                            alt=""
                          />
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="p-col-12 p-md-2 p-field">
                    <label>{{ 'report.labelUploadIcon' | translate }}</label>
                    <input
                      style="display: none"
                      type="file"
                      accept="image/*"
                      (change)="onFileChange($event)"
                      [disabled]="isReadMode || imageThumbnail"
                      #hiddenfileinput
                    />
                    <p-button
                      (click)="hiddenfileinput.click()"
                      label="{{
                        (imageThumbnail
                          ? 'report.customIcon'
                          : 'report.buttonUploadIcon'
                        ) | translate
                      }}"
                      [disabled]="isReadMode || imageThumbnail"
                    >
                      <span class="pi pi-upload"></span>
                    </p-button>
                  </div>
                  <div clas="p-md-1 p-field">
                    <div
                      class="thumbnail-container"
                      style="margin-top: 2rem; margin-left: 1rem"
                    >
                      <img
                        [src]="imageThumbnail"
                        *ngIf="imageThumbnail"
                        alt="Miniatura de la imagen"
                      />
                    </div>
                  </div>
                  <div class="p-col-1 p-field">
                    <button
                      *ngIf="imageThumbnail"
                      id="btn_delete_icon"
                      pButton
                      type="button"
                      class="p-button-danger"
                      style="margin-top: 1.75rem; margin-left: 1rem"
                      icon="pi pi-trash"
                      iconPos="right"
                      (click)="deleteIcon()"
                    ></button>
                  </div>
                  <!--Usuarios que podran completar el reporte-->
                  <div class="p-col-12 p-md-6 p-field">
                    <label for="availableUsers"
                      >{{ 'report.labelAvailableUsers' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-multiSelect
                      id="availableUsers"
                      name="availableUsers"
                      #availableUsers="ngModel"
                      [options]="availableUserOptions"
                      [(ngModel)]="confReport.availableUsers"
                      [required]="true"
                      optionLabel="label"
                      optionValue="value"
                      emptyFilterMessage="{{
                        'general.emptyFilterMessage' | translate
                      }}"
                      [disabled]="isReadMode"
                    ></p-multiSelect>
                    <small
                      class="form-error"
                      *ngIf="
                        !availableUsers.valid &&
                        (f.submitted || availableUsers.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <!--Paso 2 del formulario de reporte (general)-->
                  <div class="p-col-12 p-mb-4">
                    <app-form-step-guide
                      [step]="2"
                      [description]="'report.step2Description' | translate"
                      [additionalDescription]="
                        'report.step2AdditionalDescription' | translate
                      "
                    ></app-form-step-guide>
                  </div>
                  <!--Tipo de programación del reporte-->
                  <div class="p-col-12 p-md-6 p-field">
                    <label for="programationType">{{
                      'report.labelProgramationType' | translate
                    }}</label>
                    <p-dropdown
                      appendTo="body"
                      placeholder="{{
                        'general.selectPlaceholder' | translate
                      }}"
                      [options]="programationTypes"
                      optionLabel="name"
                      optionValue="value"
                      id="programationType"
                      name="programationType"
                      #programationType="ngModel"
                      [(ngModel)]="confReport.programationType"
                      pTooltip="{{
                        'report.tooltipProgramationType' | translate
                      }}"
                      (onChange)="programationTypeChange()"
                      [disabled]="isReadMode"
                    ></p-dropdown>
                  </div>
                  <!--Días planeados-->
                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="confReport.programationType === 'PLANNED_DAYS'"
                  >
                    <label for="plannedDayList"
                      >{{ 'report.labelPlannedDayList' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-chips
                      required
                      [allowDuplicate]="false"
                      separator=","
                      (onAdd)="onAddDay($event.value)"
                      [(ngModel)]="confReport.plannedDayList"
                      id="plannedDayList"
                      name="plannedDayList"
                      #plannedDayList="ngModel"
                      [disabled]="isReadMode"
                    ></p-chips>
                    <small
                      class="form-error"
                      *ngIf="
                        !plannedDayList.valid &&
                        (plannedDayList.touched || f.submitted)
                      "
                    >
                      {{ 'general.required' | translate }}
                    </small>
                  </div>
                  <!--Dependencia con un diario-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                  >
                    <label for="isDisplayDependentOnEdiary">{{
                      'report.labelConfReportRespectToEdiary' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        id="isDisplayDependentOnEdiary"
                        name="isDisplayDependentOnEdiary"
                        #isDisplayDependentOnEdiary="ngModel"
                        [(ngModel)]="confReport.isDisplayDependentOnTheEDiary"
                        [options]="booleanOptions"
                        optionLabel="label"
                        optionValue="value"
                        (ngModelChange)="actionDisplayDependentOnEdiary()"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="confReport.isDisplayDependentOnTheEDiary"
                  >
                    <label for="phases"
                      >{{ 'report.labelEdiaryForms' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-multiSelect
                      id="forms"
                      name="forms"
                      #forms="ngModel"
                      [options]="projectConfForms"
                      [(ngModel)]="confReport.forms"
                      [required]="true"
                      optionLabel="name"
                      optionValue="value"
                      [maxSelectedLabels]="3"
                      emptyFilterMessage="{{
                        'report.placeholderEdiaryForms' | translate
                      }}"
                      [disabled]="isReadMode"
                    ></p-multiSelect>
                    <small
                      class="form-error"
                      *ngIf="!forms.valid && (f.submitted || forms.touched)"
                      >{{ 'general.required' | translate }}</small
                    >
                    <small
                      class="form-info"
                      *ngIf="confReport.isDisplayDependentOnTheEDiary"
                    >
                      {{ 'report.helpConfReportRespectToEdiary' | translate }}
                    </small>
                  </div>
                  <!--Instancia por día-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="confReport.programationType === 'ON_DEMAND'"
                  >
                    <label for="instancePerDay">{{
                      'report.labelInstancePerDay' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        required
                        id="instancePerDay"
                        name="instancePerDay"
                        #instancePerDay="ngModel"
                        [(ngModel)]="confReport.instancePerDay"
                        pTooltip="{{
                          'report.tooltipInstancePerDay' | translate
                        }}"
                        [options]="booleanOptions"
                        optionLabel="label"
                        optionValue="value"
                        tooltipPosition="top"
                      ></p-selectButton>
                    </div>
                  </div>
                  <!--Controlado por el sitio-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                  >
                    <label for="controlledBySite">{{
                      'report.labelControlledBySite' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        id="controlledBySite"
                        name="controlledBySite"
                        #controlledBySite="ngModel"
                        [options]="booleanOptions"
                        [(ngModel)]="confReport.isControlledBySite"
                        optionLabel="label"
                        optionValue="value"
                        pTooltip="{{
                          'report.tooltipControlledBySite' | translate
                        }}"
                        [disabled]="
                          isReadMode ||
                          (confReport.isDisplayDependentOnTheEDiary ?? false)
                        "
                        tooltipPosition="top"
                      ></p-selectButton>
                    </div>
                  </div>
                  <!--Activacion de días especificos de la semana-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="confReport.programationType === 'ON_DEMAND'"
                  >
                    <label for="isDaysOfWeekRequired">{{
                      'report.labelProgramationWeekToggle' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="isReadMode"
                        id="isDaysOfWeekRequired"
                        name="isDaysOfWeekRequired"
                        #isDaysOfWeekRequired="ngModel"
                        [(ngModel)]="confReport.isDaysOfWeekRequired"
                        [options]="booleanOptions"
                        optionLabel="label"
                        optionValue="value"
                        (onChange)="showReminderWarning()"
                      ></p-selectButton>
                    </div>
                  </div>
                  <!--Dias especificos de la semana-->
                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="
                      confReport.programationType === 'ON_DEMAND' &&
                      confReport.isDaysOfWeekRequired
                    "
                  >
                    <label for="programationWeek"
                      >{{ 'report.labelProgramationWeek' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-multiSelect
                      id="programationWeek"
                      name="programationWeek"
                      #programationWeek="ngModel"
                      [options]="weekDays"
                      placeholder="{{
                        'report.placeholderEdiaryForms' | translate
                      }}"
                      [(ngModel)]="confReport.availableDays"
                      [required]="true"
                      optionLabel="name"
                      optionValue="value"
                      emptyFilterMessage="{{
                        'report.placeholderEdiaryForms' | translate
                      }}"
                      [disabled]="isReadMode"
                      (onChange)="showReminderWarning()"
                    ></p-multiSelect>
                    <small
                      class="form-error"
                      *ngIf="
                        !programationWeek.valid &&
                        (f.submitted || programationWeek.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <!--Activcación de limite de fechas del reporte-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="confReport.programationType === 'ON_DEMAND'"
                  >
                    <label for="isDateRangeLimitRequired">{{
                      'report.labelisDateRangeLimitRequired' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="isReadMode"
                        id="isDateRangeLimitRequired"
                        name="isDateRangeLimitRequired"
                        #isDateRangeLimitRequired="ngModel"
                        [(ngModel)]="confReport.isDateRangeLimitRequired"
                        [options]="booleanOptions"
                        optionLabel="label"
                        optionValue="value"
                      ></p-selectButton>
                    </div>
                  </div>
                  <!--Fechas del reporte-->
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      align-items: baseline;
                    "
                    *ngIf="
                      confReport.programationType === 'ON_DEMAND' &&
                      confReport.isDateRangeLimitRequired
                    "
                  >
                    <div class="p-field p-pr-3">
                      <label for="cal_startDate"
                        >{{ 'report.labelStartDate' | translate }}
                        <span class="inputRequired"> * </span></label
                      >
                      <p-calendar
                        name="cal_startDate"
                        id="cal_startDate"
                        [style]="{ width: '100%' }"
                        [inputStyle]="{ width: '100%' }"
                        [showIcon]="true"
                        appendTo="body"
                        [(ngModel)]="confReport.startDate"
                        #startDateLimit="ngModel"
                        placeholder="{{
                          'addEditSubjectComponent.PlaceHolderFormatDate'
                            | translate
                        }}"
                        dateFormat="yy-mm-dd"
                        [required]="true"
                        [minDate]="minDate"
                        [maxDate]="confReport.endDate"
                      ></p-calendar>
                      <small
                        class="form-error"
                        *ngIf="
                          !startDateLimit.valid &&
                          (f.submitted || startDateLimit.touched)
                        "
                        >{{ 'general.required' | translate }}</small
                      >
                    </div>
                    <div class="p-field">
                      <label for="cal_endDate"
                        >{{ 'report.labelFinishDate' | translate }}
                        <span class="inputRequired"> * </span></label
                      >
                      <p-calendar
                        name="cal_endDate"
                        id="cal_endDate"
                        [style]="{ width: '100%' }"
                        [inputStyle]="{ width: '100%' }"
                        [showIcon]="true"
                        appendTo="body"
                        [(ngModel)]="confReport.endDate"
                        #endDateLimit="ngModel"
                        placeholder="{{
                          'addEditSubjectComponent.PlaceHolderFormatDate'
                            | translate
                        }}"
                        dateFormat="yy-mm-dd"
                        [required]="true"
                        [minDate]="confReport.startDate || minDate"
                      ></p-calendar>
                      <small
                        class="form-error"
                        *ngIf="
                          !endDateLimit.valid &&
                          (f.submitted || endDateLimit.touched)
                        "
                        >{{ 'general.required' | translate }}</small
                      >
                    </div>
                  </div>
                  <!--Paso 3 del formulario de reporte (general)-->
                  <div class="p-col-12 p-mb-4">
                    <app-form-step-guide
                      [step]="3"
                      [description]="'report.step3Description' | translate"
                      [additionalDescription]="
                        'report.step3AdditionalDescription' | translate
                      "
                    ></app-form-step-guide>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="hasCreateUpdateReportConfSymptomPermission"
                  >
                    <label for="symptomsRequired">{{
                      'report.labelAreSymptomsRequired' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        id="symptomsRequired"
                        name="symptomsRequired"
                        #controlledBySite="ngModel"
                        [options]="booleanOptions"
                        [(ngModel)]="confReport.areSymptomsRequired"
                        optionLabel="label"
                        optionValue="value"
                      ></p-selectButton>
                    </div>
                  </div>

                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="confReport.programationType === 'PLANNED_DAYS'"
                  >
                    <label for="allowReportBackDating">{{
                      'report.labelAllowReportBackDating' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        id="allowReportBackDating"
                        name="allowReportBackDating"
                        #allowReportBackDating="ngModel"
                        [options]="booleanOptions"
                        [(ngModel)]="confReport.allowReportBackDating"
                        optionLabel="label"
                        optionValue="value"
                        required
                        pTooltip="{{
                          'report.tooltipAllowReportBackDating' | translate
                        }}"
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        tooltipPosition="top"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="hasCreateUpdateReportConfMultimediaPermission"
                  >
                    <label for="media">{{
                      'report.labelrequiresMedia' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        id="media"
                        name="media"
                        #media="ngModel"
                        [options]="booleanOptions"
                        [(ngModel)]="confReport.isMediaRequired"
                        optionLabel="label"
                        optionValue="value"
                        required
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        tooltipPosition="top"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="hasCreateUpdateReportConfDevicesPermission"
                  >
                    <label>{{
                      'report.labelIsDeviceDataRequired' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        required
                        id="deviceDataManagement"
                        name="deviceDataManagement"
                        #deviceDataManagement="ngModel"
                        [options]="booleanOptions"
                        [(ngModel)]="confReport.isDeviceDataManagementRequired"
                        optionLabel="label"
                        optionValue="value"
                        [disabled]="isReadMode"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="hasCreateUpdateReportRemindersPermission"
                  >
                    <label>{{
                      'report.labelAreRemindersRequired' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasCreateUpdateReportRemindersPermission"
                        [options]="booleanOptions"
                        id="enableLocalNotifications"
                        name="enableLocalNotifications"
                        #enableLocalNotifications="ngModel"
                        [(ngModel)]="confReport.enableLocalNotifications"
                        optionLabel="label"
                        optionValue="value"
                        [disabled]="isReadMode"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                    *ngIf="hasCreateUpdateReportAlertPermission"
                  >
                    <label>{{
                      'report.labelAreAlertsRequired' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        [options]="mediaTypes"
                        id="enableAlerts"
                        name="enableAlerts"
                        #enableAlerts="ngModel"
                        [(ngModel)]="confReport.areAlertsRequired"
                        [options]="booleanOptions"
                        optionLabel="label"
                        optionValue="value"
                        [disabled]="isReadMode"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                  >
                    <label for="isSignatureRequired">{{
                      'report.labelIsSignRequired' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission || isReadMode"
                        [options]="mediaTypes"
                        id="isSignatureRequired"
                        name="isSignatureRequired"
                        #enableAlerts="ngModel"
                        [(ngModel)]="confReport.isSignatureRequired"
                        [options]="booleanOptions"
                        optionLabel="label"
                        optionValue="value"
                        [disabled]="isReadMode"
                      ></p-selectButton>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="hasCreateUpdateReportJsonFormPermission"
                  >
                    <label for="jsonForm">{{
                      'report.labelJsonForm' | translate
                    }}</label>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <textarea
                        style="width: 90%"
                        pInputTextarea
                        id="jsonForm"
                        name="jsonForm"
                        [(ngModel)]="confReport.jsonForm"
                        pTooltip="{{ 'report.tooltipJsonForm' | translate }}"
                        [disabled]="isReadMode"
                      ></textarea>
                      <button
                        id="dictionaryButtonReport"
                        name="dictionaryButtonReport"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-book"
                        (click)="onDictionaty(true, 'jsonFom')"
                        class="
                          p-button-rounded
                          p-button-secondary
                          p-button-outlined
                          p-ml-2
                          p-ripple
                          p-button
                          p-component
                          p-button-icon-only
                        "
                        title="{{ 'dictionary.labelButton' | translate }}"
                        [disabled]="isReadMode"
                      ></button>
                    </div>
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field p-ai-center"
                    style="display: grid; grid-template-columns: 1fr auto"
                  >
                    <label for="showHelp">{{
                      'report.labelShowHelp' | translate
                    }}</label>
                    <div class="p-col">
                      <p-selectButton
                        [disabled]="!hasReportUpdatePermission"
                        (onChange)="onChangeShowHelp()"
                        id="showHelp"
                        name="showHelp"
                        [options]="booleanOptions"
                        [(ngModel)]="confReport.showHelp"
                        optionLabel="label"
                        optionValue="value"
                        required
                        pTooltip="{{
                          'report.labelShowHelpTooltip' | translate
                        }}"
                        [disabled]="isReadMode"
                        tooltipPosition="top"
                      ></p-selectButton>
                    </div>
                  </div>
                  <!--Help Screen Display Options guide-->
                  <div
                    *ngIf="confReport.showHelp"
                    class="p-col-12 p-md-6 p-field"
                  >
                    <label
                      for="helpScreenDisplayOptions"
                      class="p-mb-2 p-d-block"
                      >{{ 'symptom.helpScreenDisplayOptions' | translate }}
                      <span class="inputRequired"> *</span></label
                    >
                    <p-multiSelect
                      appendTo="body"
                      [required]="true"
                      placeholder="{{
                        'general.selectOneOrMorePlaceholder' | translate
                      }}"
                      [options]="HelpScreenDisplayOptions"
                      optionLabel="name"
                      optionValue="value"
                      name="helpScreenDisplayOptions"
                      #helpScreenDisplayOptions="ngModel"
                      [(ngModel)]="confReport.helpScreenDisplayOptions"
                      tooltipPosition="top"
                      pTooltip="{{
                        'symptom.tooltiphelpScreenDisplayOption' | translate
                      }}"
                      [disabled]="isReadMode"
                    ></p-multiSelect>
                    <small
                      class="form-error"
                      *ngIf="
                        !helpScreenDisplayOptions.valid &&
                        (f.submitted || helpScreenDisplayOptions.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="confReport.showHelp"
                  >
                    <div class="p-col">
                      <div class="p-field">
                        <label for="help">{{
                          'report.labelHelp' | translate
                        }}</label
                        ><span class="inputRequired">* </span>
                      </div>
                      <div class="p-field">
                        <app-simple-dictionary-editor
                          [isRequired]="true"
                          [submitted]="f.submitted"
                          [value]="confReport.reportHelp"
                          (outputEvent)="confReport.reportHelp = $event"
                          [isReadOnly]="
                            !hasReportUpdatePermission || isReadMode
                          "
                        >
                        </app-simple-dictionary-editor>
                        <p-editor
                          [readonly]="!hasReportUpdatePermission || isReadMode"
                          id="reportHelp"
                          name="reportHelp"
                          [hidden]="true"
                          [required]="true"
                          #reportHelp="ngModel"
                          [(ngModel)]="confReport.reportHelp"
                          [style]="{ height: '320px' }"
                        ></p-editor>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-grid p-fluid p-mt-3">
                  <div class="p-col"></div>
                  <div class="p-col-12 p-md-4">
                    <div class="p-grid">
                      <div class="p-col"></div>
                      <div class="p-col" *ngIf="isNew">
                        <button
                          id="submitButton"
                          pButton
                          type="submit"
                          label="{{ 'general.add' | translate }}"
                          [disabled]="isReadMode"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            [header]="'designer.title' | translate"
            *ngIf="confReport.id && hasCreateUpdateReportJsonFormPermission"
          >
            <ng-template pTemplate="content">
              <app-formly-designer
                [projectId]="projectId"
                [confReport]="confReportDesigner"
                (confReportChange)="updateConfReportFromResponse($event)"
              ></app-formly-designer>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            *ngIf="
              confReport.id && hasCreateUpdateReportConfMultimediaPermission
            "
            header="{{ 'general.configurations.enums.multimedia' | translate }}"
            [disabled]="!confReport.isMediaRequired"
          >
            <report-multimedia
              [confReport]="confReport"
              [readMode]="isReadMode"
              [isReadOnly]="!hasReportUpdatePermission"
              [form]="f"
              [mediaTypes]="mediaTypes"
            ></report-multimedia>
          </p-accordionTab>
          <p-accordionTab
            *ngIf="
              confReport.id &&
              (hasCreateUpdateReportConfSymptomPermission ||
                hasDeleteReportSymptomPermission)
            "
            header="{{ 'report.requiredSymptoms' | translate }}"
            [disabled]="!confReport.areSymptomsRequired"
          >
            <ng-template pTemplate="content">
              <app-symptom-list
                [relatedEntity]="'REPORT'"
                [relatedEntityId]="id"
                [projectState]="projectState"
                [dictionary]="dictionary"
                [projectId]="projectId"
                [symptomPageLayout]="confReport.symptomPageLayout"
                (changePageLayout)="confReport.symptomPageLayout = $event"
              ></app-symptom-list>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            *ngIf="confReport.id && hasCreateUpdateReportRemindersPermission"
            header="{{ 'report.reminders' | translate }}"
            [disabled]="!confReport.enableLocalNotifications"
          >
            <report-reminders
              [confReport]="confReport"
              [readMode]="isReadMode"
              [isReadOnly]="false"
              [form]="f"
              [onDemandAlertTypes]="onDemandAlertTypes"
              [isDaysOfWeekRequired]="confReport.isDaysOfWeekRequired"
              [weekDays]="weekDays"
              [availableDays]="confReport.availableDays"
            ></report-reminders>
          </p-accordionTab>
          <p-accordionTab
            *ngIf="
              confReport.id &&
              (hasDeleteReportAlertPermission ||
                hasCreateUpdateReportAlertPermission)
            "
            header="{{ 'report.alerts' | translate }}"
            [disabled]="!confReport.areAlertsRequired"
          >
            <ng-template pTemplate="content">
              <app-alert-list
                [relatedEntity]="'REPORT'"
                [relatedEntityId]="id"
                [projectId]="projectId"
                [dictionary]="dictionary"
                [groups]="confReport.groups"
              ></app-alert-list>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            *ngIf="confReport.id && hasCreateUpdateReportConfDevicesPermission"
            header="{{ 'report.labelDeviceData' | translate }}"
            [disabled]="!confReport.isDeviceDataManagementRequired"
          >
            <app-device-data
              [confReport]="confReport"
              [readMode]="isReadMode"
              [isReadOnly]="!hasReportUpdatePermission"
            ></app-device-data>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <div class="p-grid p-fluid p-mt-3">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-4">
        <div class="p-grid">
          <div class="p-col">
            <button
              pButton
              id="cancelButton"
              name="cancelButton"
              class="p-button-secondary"
              type="button"
              (click)="onCancel()"
              label="{{ 'general.cancel' | translate }}"
            ></button>
          </div>
          <div class="p-col" *ngIf="hasReportUpdatePermission && !isNew">
            <button
              id="submitButton"
              name="submitButton"
              pButton
              type="submit"
              label="{{ (isNew ? 'general.add' : 'general.edit') | translate }}"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
