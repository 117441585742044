<div class="summary p-mb-5">
  <h3 class="p-mb-5" *ngIf="verified?.hasInstance === false">
    <span class="form-error">{{
      'conciliation.deleteIntances' | translate
    }}</span>
  </h3>
  <div class="p-grid p-col-12 p-p-0">
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="configuration?.showPrecondition"
    >
      <span
        id="lbl_labelVerifiedDose"
        name="lbl_verifiedDose"
        for="verifiedDose"
      >
        <span *ngIf="configuration?.label">
          {{ configuration.label | dictionary }}</span
        >
        <span *ngIf="!configuration?.label">
          {{ 'general.configurations.enums.precondition' | translate }}</span
        >:
      </span>
      <div
        [innerHTML]="
          original?.hasInstance | textNa: verified?.hasInstance:'boolean':true
        "
      ></div>
    </div>

    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="isGivenToEnabled && isMedicationGivenToValid()"
    >
      <span id="lbl_verifiedDose" name="lbl_verifiedDose" for="verifiedDose">
        {{ 'medicationinstance.givenTo' | translate }}:
      </span>
      <div
        [innerHTML]="
          original?.medicationGivenTo
            | textNa: verified?.medicationGivenTo:'givenTo':true
        "
      ></div>
    </div>
  </div>
</div>

<div class="p-d-flex p-jc-end">
  <button
    pButton
    type="button"
    (click)="onClose(false)"
    label="{{ 'general.cancel' | translate }}"
    icon="pi pi-times"
    class="p-mx-2"
  ></button>
  <button
    pButton
    type="button"
    (click)="onClose(true)"
    label="{{ 'general.confirm' | translate }}"
    icon="pi pi-check"
    class="p-button-secondary"
  ></button>
</div>
