import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { AddEditProjectComponent } from '../add-edit-project/add-edit-project.component';
import { ProjectService } from '../project.service';

const logger = new Logger('tp2-logger-projectListPage');
@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss'],
})
export class ListProjectComponent implements OnInit, AfterViewInit {
  projectsOriginal: any[] = [];
  projectsFilter: any[] = [];
  projectsPaginated: any[] = [];
  users: any[] = [];
  isAdmin = false;
  filter = '';

  pagesize = 6;
  hasProjectCreatePermission = false;
  canSeeAudit = false;
  constructor(
    private projectService: ProjectService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private trialpalService: TrialpalService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private userPermissionsService: UserPermissionsService
  ) {}

  public ngAfterViewInit() {
    this.detectScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectScreenSize();
  }
  private detectScreenSize() {
    const height = window.innerHeight;
    if (height > 1235) {
      this.pagesize = 12;
      this.projectsPaginated = this.projectsFilter.slice(0, this.pagesize);
    } else if (height > 1010) {
      this.pagesize = 9;
      this.projectsPaginated = this.projectsFilter.slice(0, this.pagesize);
    } else {
      this.pagesize = 6;
      this.projectsPaginated = this.projectsFilter.slice(0, this.pagesize);
    }
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.authService.isAdmin();
    this.hasProjectCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectCreate,
        'Admin',
      ]);
    this.canSeeAudit = await this.userPermissionsService.hasPermission([
      TP2Permission.ProjectList,
      'Admin',
    ]);
    this.trialpalService.showSpinner('project.entityPlural', 'LIST');
    await this.getProjects();
    this.trialpalService.hideSpinner();
  }

  async getProjects() {
    const projects = await this.projectService.getProjects();
    this.updateProjectResponsibles(projects);

    if (this.authService.isAdmin()) {
      this.initializeAdminProjects(projects);
    } else {
      this.initializeUserProjects(projects);
    }
  }

  updateProjectResponsibles(projects: any) {
    projects.forEach((project: any) => {
      const user = this.users.find((user) => user.project === project.id);
      project.responsible = user?.username ?? '';
    });
  }

  initializeAdminProjects(projects: any) {
    this.projectsOriginal = projects;
    this.projectsFilter = [...this.projectsOriginal];
    this.projectsPaginated = [...this.projectsOriginal.slice(0, this.pagesize)];
  }

  initializeUserProjects(projects: any) {
    const customProjects = this.authService.getUserProjects();
    if (customProjects.length > 1) {
      const userProjects = projects.filter((project: any) =>
        customProjects.includes(project.id)
      );
      this.projectsOriginal = userProjects;
      this.projectsFilter = [...this.projectsOriginal];
      this.projectsPaginated = [
        ...this.projectsOriginal.slice(0, this.pagesize),
      ];
    }
  }

  getStateColor(state: string): string {
    switch (state) {
      case 'IN_DESIGN':
        return 'blue';
      case 'IN_PROGRESS':
        return 'green';
      case 'SUSPENDED':
        return 'orange';
      case 'ARCHIVED':
      case 'ENDED':
        return 'red';
      default:
        return ''; // O cualquier otro color por defecto
    }
  }

  buildTPCardData(res: any): TPCardData {
    let entity;
    let showEditButton;
    let showAuditButton;
    let showDeleteButton;
    let cardIsButton;
    if (this.authService.isAdmin()) {
      entity = 'PROJECT';
      showEditButton = true;
      showAuditButton = true;
      showDeleteButton = true;
      cardIsButton = true;
    } else {
      entity = '';
      showEditButton = this.hasProjectCreatePermission;
      showAuditButton = this.canSeeAudit;
      showDeleteButton = false;
      cardIsButton = true;
    }

    const state = 'project.enums.projectStates.' + res.state;
    return {
      data: res,
      header: res.code,
      state: this.translateService.instant(state),
      icon1: 'pi pi-user',
      section1: res.sponsor,
      icon2: 'pi pi-bookmark',
      section2: res.name,
      styleColorState: this.getStateColor(res.state),
      entity,
      objectAction: res.code,
      entityAction: this.translateService.instant('project.entity'),
      showEditButton,
      showAuditButton,
      showDeleteButton,
      cardIsButton,
    };
  }
  createEdit(p?: any): void {
    const isEdit = !!p;

    const ref = this.dialogService.open(AddEditProjectComponent, {
      header: p
        ? this.translateService.instant('project.headerModalEditProject')
        : this.translateService.instant('project.headerModalCreateProject'),
      width: '90%',
      data: p ? { isEdit, id: p.id, isModal: true } : { isEdit, isModal: true },
      dismissableMask: false,
    });

    ref.onClose.subscribe((result: any) => {
      if (result && !isEdit) {
        this.router.navigate(['project', result[0].id, 'detail']);
        this.projectsFilter.push(result[0]);
      } else if (result && isEdit) {
        const i = this.projectsOriginal.findIndex((x) => x.id === result.id);
        this.projectsOriginal.splice(i, 1, result);
        const i2 = this.projectsFilter.findIndex((x) => x.id === result.id);
        this.projectsFilter.splice(i2, 1, result);
        const i3 = this.projectsPaginated.findIndex((x) => x.id === result.id);
        this.projectsPaginated.splice(i3, 1, result);
      }
    });
  }
  delete(): void {
    this.messageService.add({
      severity: 'info',
      summary: 'Función en construcción',
    });
  }
  detail(p: any): void {
    this.router.navigate(['project/' + p.id + '/detail']);
  }
  filterMaster() {
    this.projectsFilter = this.projectsOriginal;
    this.filterList();
    this.projectsPaginated = this.projectsFilter.slice(0, this.pagesize);
  }
  filterList(): void {
    if (this.filter && this.filter.trim() !== '') {
      this.projectsFilter = this.projectsOriginal.filter(
        (x) =>
          (x.name ? x.name : '')
            .toLowerCase()
            .includes(this.filter.toLowerCase().trim()) ||
          (x.code ? x.code : '')
            .toLowerCase()
            .includes(this.filter.toLowerCase().trim()) ||
          (x.sponsor ? x.sponsor : '')
            .toLowerCase()
            .includes(this.filter.toLowerCase().trim())
      );
      this.projectsPaginated = this.projectsFilter.slice(0, this.pagesize);
    } else {
      this.projectsFilter = this.projectsOriginal;
    }
  }
  getAttribute(attributes: [any], attName: string): string {
    let att = null;
    const a = attributes.find((x) => x.Name === attName);
    if (a) {
      att = a.Value;
    }
    return att;
  }
  paginate(event: any) {
    this.projectsPaginated = this.projectsFilter.slice(
      event.page * this.pagesize,
      event.page * this.pagesize + this.pagesize
    );
  }
}
