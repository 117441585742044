import { Injectable } from '@angular/core';
import { Analytics, Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { AuditInfoComponent } from '../shared/components/audit-info/audit-info.component';
import { TPTableData } from '../shared/components/tptable/tpTableData';
import { TP2Entites } from '../shared/global.variables';
import { AuthService } from './auth.service';
import { TrialpalService } from './trialpal.service';

const logger = new Logger('tp2-logger-service');

@Injectable({
  providedIn: 'root',
})
export class CardOrTableService {
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
  };
  constructor(
    private dialogService: DialogService,
    private trialpalService: TrialpalService,
    private authService: AuthService
  ) {}

  openAuditModal(data: any) {
    if (data.entity === 'PROJECT') {
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.PROJECT,
          description: data.data.code,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (String(data.entity).toLowerCase() === 'subject') {
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.SUBJECT,
          description: data.data.subjectNumber,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === 'USER') {
      Analytics.record({
        name: 'site_user_see_audit',
        attributes: {
          userSub: this.authService.user?.attributes?.sub,
          userName: this.authService.user?.username,
        },
      });

      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.USER,
          description: data.data.name,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === 'SITE') {
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.SITES,
          description: data.data.name,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === 'SYMPTOM') {
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.SYMPTOM,
          description: data.data.confSymptom?.symptomLabel,
          idRecord: data.data.confSymptomId,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === 'MedicalAttentionInstance') {
      let type: any = '';
      if (!data.data?.type) {
        type = this.trialpalService.translateService.instant(
          'subject.tabheaderMedicalAssistance'
        );
      } else {
        type = this.trialpalService.translateService.instant(
          'subject.enums.medicalAttention.type.' + data.data?.type
        );
      }
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.MEDICALATTENTIONINSTANCE,
          description: type,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === 'SymptomInstance') {
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.SYMPTOMINSTANCE,
          description: data?.header,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === TP2Entites.MEDICATIONINSTANCE) {
      this.dialogService.open(AuditInfoComponent, {
        data: {
          entity: TP2Entites.MEDICATIONINSTANCE,
          description: data?.header || data?.data?.header,
          idRecord: data.data.id,
        },
        width: '95%',
        height: '95%',
      });
    } else if (data.entity === 'TemperatureRecordLog') {
      this.openModalAudit(data, TP2Entites.TEMPERATURERECORDLOG);
    }
  }

  openModalAudit(data: any, entity: TP2Entites) {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity,
        description: data?.header,
        idRecord: data.data.id,
      },
      width: '95%',
      height: '95%',
    });
  }
}
