import { Component, EventEmitter, Output } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';

@Component({
  selector: 'app-delete-reason',
  templateUrl: './delete-reason.component.html',
  styleUrls: ['./delete-reason.component.scss'],
})
export class DeleteReasonComponent {
  @Output() changeReason = new EventEmitter<string>();

  //Eliminacion
  showDialog: boolean = false;
  formSubmited: boolean = false;
  changeReasonDelete: string = '';

  constructor(
    private ref: DynamicDialogRef,
    private conciliationService: ConciliationService
  ) {}

  confirm() {
    this.formSubmited = true;
    this.changeReasonDelete = this.changeReasonDelete.trim();
    if (this.changeReasonDelete.length === 0) {
      return this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
    this.ref.close(this.changeReasonDelete);
  }

  cancel() {
    this.ref.close(null);
  }
}
