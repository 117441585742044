import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { Roles } from 'src/app/shared/global.variables';
import { ListUserVersionsComponent } from './list-user-versions/list-user-versions.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'list-user-versions',
    pathMatch: 'full',
  },
  {
    path: 'list-user-versions',
    component: ListUserVersionsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin] },
  },
  {
    path: 'list-user-versions/:projectId',
    component: ListUserVersionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VersionRoutingModule {}
