<form [formGroup]="form" class="questionForm">
  <p class="textDecription">
    {{ 'informedConsent.sections.questionTitle' | translate }}
  </p>
  <div class="form-grid">
    <div>
      <label [for]="'txt_question_value_' + uniqueId"
        >{{ 'informedConsent.sections.descriptionTitle' | translate
        }}<span class="inputRequired"> * </span></label
      >
      <div>
        <app-simple-dictionary-input
          [id]="'txt_question_value_' + uniqueId"
          [name]="'question_value' + uniqueId"
          [isReadOnly]="false"
          [isRequired]="true"
          [projectId]="projectId"
          [value]="form.controls['question'].value"
          [submitted]="generalForm.submitted"
          (outputEvent)="form.controls['question'].setValue($event)"
        ></app-simple-dictionary-input>
      </div>
    </div>
    <div>
      <label
        >{{ 'informedConsent.sections.typeComponentDescription' | translate
        }}<span class="inputRequired"> * </span></label
      >
      <div>
        <p-dropdown
          [id]="'type_' + uniqueId"
          [name]="'type' + uniqueId"
          appendTo="body"
          placeholder="{{
            'informedConsent.sections.typeComponentSelect' | translate
          }}"
          [options]="complementaryQuestionType"
          optionValue="value"
          class="disabled"
          formControlName="type"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="!form.controls['type'].valid && form.controls['type'].touched"
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
  </div>
  <p class="textDecription">
    {{ 'informedConsent.sections.answerTitle' | translate }}
  </p>
  <div formArrayName="options">
    <div
      class="form-grid"
      *ngFor="let option of options; let optionIndex = index"
    >
      <div>
        <label [for]="'txt_question_option_' + optionIndex + '_' + uniqueId"
          >{{
            'informedConsent.sections.option'
              | translate: { option: optionIndex + 1 }
          }}<span class="inputRequired"> * </span></label
        >
        <div class="form-flex">
          <div>
            <app-simple-dictionary-input
              [id]="'txt_question_option_' + optionIndex + '_' + uniqueId"
              [name]="'question_option_' + optionIndex + '_' + uniqueId"
              [isReadOnly]="false"
              [isRequired]="true"
              [projectId]="projectId"
              [value]="option.value"
              [submitted]="generalForm.submitted || addOptionActive"
              (outputEvent)="option.value = $event; updateOptions()"
            ></app-simple-dictionary-input>
          </div>
          <div class="btnOptions">
            <button
              id="addQuestionBtn"
              name="addQuestionBtn"
              type="button"
              pButton
              pRipple
              icon="pi pi-plus"
              class="
                p-button-rounded
                p-button-success
                p-ml-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
              title="{{ 'general.add' | translate }}"
              (click)="addOption()"
            ></button>
            <button
              id="deleteQuestionBtn"
              name="deleteQuestionBtn"
              type="button"
              pButton
              pRipple
              icon="pi pi-trash"
              class="
                p-button-rounded
                p-button-secondary
                p-ml-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
              title="{{ 'user.buttonDelete' | translate }}"
              (click)="showModalDelete(optionIndex)"
              *ngIf="options.length > 1"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="deleteOption()"
  position="fixed"
>
</app-delete-confirmation>
