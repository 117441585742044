import { Component, EventEmitter, Input, Output } from '@angular/core';
import { float } from 'aws-sdk/clients/lightsail';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';

@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss'],
})
export class ProgressCardComponent {
  @Input() percentage: float = 0;
  @Input() order: number = 1;
  @Input() state: string = '';
  @Input() subjects: number = 0;
  @Input() isActive: boolean = false;
  @Input() disabled: boolean = true;
  @Output() clearFilter: EventEmitter<boolean> = new EventEmitter();

  constructor(private econsentService: InformedConsentService) {}

  onClearFilter(event: any) {
    event.stopPropagation();
    this.clearFilter.emit(true);
  }
  getImageUrl(): string {
    if (this.disabled) {
      return `assets/icons/informed-consent/state${this.order}_disabled.svg`;
    } else {
      return `assets/icons/informed-consent/state${
        this.percentage !== 100 ? this.order : 'Full'
      }.svg`;
    }
  }
}
