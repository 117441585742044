import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-text',
  template: `<p class="tp-label">
    <strong *ngIf="to.number">{{ to.number }}</strong>
    {{ to.subtitle }}
    <br />
    <strong *ngIf="to.strong">{{ to.strong }}</strong>
  </p> `,
})
export class FormlyTextFieldComponent extends FieldType {}
