import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {
  constructor(private translateService: TranslateService) {}

  isValidPhoneNumber(numberPhone: any): phoneNumberInterface {
    if (!numberPhone) {
      return { isValid: true, message: '' };
    }

    if (this.containsLetter(numberPhone)) {
      return {
        isValid: false,
        message: this.translateService.instant(
          'user.phoneNumberMessages.containsLetter'
        ),
      };
    }

    try {
      return this.validatePhoneNumber(numberPhone);
    } catch (error: any) {
      return this.handlePhoneNumberError(error);
    }
  }

  validatePhoneNumber(numberPhone: any): any {
    let phoneMessage = this.translateService.instant(
      'user.phoneNumberMessages.noValidPhone'
    );
    if (!PhoneNumberUtil) {
      return { isValid: false, message: phoneMessage };
    }

    const phoneUtil_ = PhoneNumberUtil.getInstance();
    const number = phoneUtil_.parseAndKeepRawInput(numberPhone);
    const isPossibleReason = phoneUtil_.isPossibleNumberWithReason(number);
    const isPossible = phoneUtil_.isPossibleNumber(number);

    switch (isPossibleReason) {
      case 1:
        phoneMessage = this.translateService.instant(
          'user.phoneNumberMessages.countryCode'
        );
        break;
      case 2:
      case 4:
        phoneMessage = this.translateService.instant(
          'user.phoneNumberMessages.tooShort'
        );
        break;
      case 3:
        phoneMessage = this.translateService.instant(
          'user.phoneNumberMessages.tooLong'
        );
        break;
      default:
        break;
    }

    if (!isPossible) return { isValid: false, message: phoneMessage };

    if (isPossibleReason !== 4) {
      const isNumberValid = phoneUtil_.isValidNumber(number);
      return { isValid: isNumberValid, message: phoneMessage };
    }

    return { isValid: false, message: phoneMessage };
  }

  handlePhoneNumberError(error: any): any {
    let phoneMessage = this.translateService.instant(
      'user.phoneNumberMessages.noValidPhone'
    );
    switch (error.message) {
      case 'Invalid country calling code':
        phoneMessage = this.translateService.instant(
          'user.phoneNumberMessages.countryCode'
        );
        break;
      case 'The string supplied is too long to be a phone number':
        phoneMessage = this.translateService.instant(
          'user.phoneNumberMessages.tooLong'
        );
        break;
      case 'The string supplied is too short to be a phone number':
        phoneMessage = this.translateService.instant(
          'user.phoneNumberMessages.tooShort'
        );
        break;
    }
    return { isValid: false, message: phoneMessage };
  }

  containsLetter(str: string) {
    // Utilizamos una expresión regular para buscar cualquier letra de la A a la Z, tanto mayúsculas como minúsculas
    const regex = /[a-zA-Z]/;
    // Utilizamos el método test para comprobar si la cadena contiene al menos una letra
    return regex.test(str);
  }
}

export interface phoneNumberInterface {
  isValid: boolean;
  message: string;
}
