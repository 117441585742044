<div
  class="dayInstance-conciliation p-mb-5"
  *ngIf="colDays && colDays.length > 1"
>
  <p-table
    [columns]="colDays"
    [value]="tableDayIntances"
    [scrollable]="true"
    scrollDirection="horizontal"
    styleClass="p-datatable-gridlines"
    [resizableColumns]="true"
  >
    <ng-template pTemplate="header" let-days>
      <tr>
        <th
          (click)="dayInstanceSelected(day)"
          *ngFor="let day of days; let i = index"
          [id]="'colHeaderDay' + i"
          [ngClass]="{
            'header-select':
              day.dayInstance?.id ===
                dayInstanceService.currentDayInstance?.dayInstance?.id &&
              currentSection === paginationService.currentSection,
            header: i !== 0
          }"
          class="grid-c col-2-c"
        >
          <span class="p-mr-3">{{ day.header | dictionary | uppercase }}</span>
          <app-check
            *ngIf="day.dayInstance?.id"
            [isConciliated]="day?.isCompleted"
          ></app-check>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-value>
      <tr>
        <td *ngFor="let item of value; let i = index">
          <span *ngIf="item.index === 0">
            <span> {{ item?.value }}</span>
          </span>
          <span *ngIf="item.index === 1">
            <span
              [innerHTML]="item.value | textNa: item?.verified:'boolean'"
            ></span>
            <sup
              *ngIf="item.commentIndex !== null"
              class="p-ml-1"
              pTooltip="{{ item.comment }}"
            >
              {{ item?.commentIndex }}
            </sup>
          </span>

          <span *ngIf="item.index === 2">
            <span
              [innerHTML]="
                item.value?.sort()
                  | textNa
                    : item?.verified?.sort()
                    : 'givenTo'
                    : false
                    : item.commentIndex !== null
              "
            ></span>
            <sup
              *ngIf="item.commentIndex !== null"
              class="p-ml-1"
              pTooltip="{{ item.comment }}"
            >
              {{ item?.commentIndex }}
            </sup>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
