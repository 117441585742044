<!--Componente para los pasos-->
<ul class="stepper">
  <li
    *ngFor="let step of steps; let index = index"
    class="step-option"
    [ngClass]="{
      active: index === currentStep
    }"
    [style.max-width]="'calc((100% / ' + steps.length + ') - 1rem)'"
    (click)="currentStep = index"
  >
    <span class="step-number">{{ index + 1 }}</span>
    <span
      class="step-text"
      [pTooltip]="step.label | dictionary"
      tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
      *ngIf="step.label"
      >{{ step?.label ?? '' | dictionary }}</span
    >
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 10"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m7 9 4-4-4-4M1 9l4-4-4-4"
      ></path>
    </svg>
  </li>
</ul>

<!--Visualizador del paso seleccionado-->
<div
  *ngFor="
    let field of field.fieldGroup;
    let index = index;
    let last = last;
    let first = first
  "
>
  <div *ngIf="currentStep === index" class="flex flex-col gap-4">
    <formly-field [field]="field"></formly-field>
    <div
      class="p-d-flex p-justify-end"
      [ngClass]="{ 'p-justify-between': !first }"
    >
      <p-button
        label="Anterior"
        type="button"
        (click)="currentStep = currentStep - 1"
        *ngIf="!first"
      ></p-button>
      <p-button
        label="Siguiente"
        type="button"
        *ngIf="!last"
        (click)="currentStep = currentStep + 1"
      ></p-button>
    </div>
  </div>
</div>
