<div class="p-grid">
  <div class="p-col-12">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-replay"
      class="p-button-rounded"
      [loading]="showSpinner"
      pTooltip="{{ 'general.refreshData' | translate }}"
      (click)="onButtonAction()"
    ></button>
  </div>
</div>
