<div class="p-grid">
  <app-button-refresh-data
    [showSpinner]="showSpinner"
    (buttonAction)="ngOnInit()"
  ></app-button-refresh-data>
  <app-button-table-card-mode
    dataSource="subjectSymptomsList"
    class="p-col-11"
    *ngIf="symptoms && symptoms.length > 0 && !showSpinner"
    (cardOrTableModeEmit)="tableViewMode = $event"
  ></app-button-table-card-mode>
</div>

<app-tour-guide-button
  [pageTourDataType]="DETAIL_SUBJECT_SYMPTOM_TOUR"
  *ngIf="symptoms?.length && !showSpinner"
></app-tour-guide-button>

<section *ngIf="!showSpinner">
  <div *ngIf="symptoms && symptoms.length > 0" id="symptoms_container">
    <section *ngIf="!tableViewMode">
      <div class="p-grid p-fluid">
        <app-tpcard
          class="p-col-12 p-md-4 p-lg-3"
          *ngFor="let symptom of dataCard; let i = index"
          [tpCardData]="symptom"
          (detail)="getSymptomDetail(symptom)"
          [id]="'symptom_container' + i"
        ></app-tpcard>
      </div>
    </section>

    <section *ngIf="tableViewMode" class="p-mb-5">
      <app-tptable
        (customAction1)="
          cardOrTableService.openAuditModal({
            data: $event.data,
            header: $event.name,
            entity: $event.entity
          })
        "
        (openDetail)="getSymptomDetail($event.data)"
        [dataTable]="dataTable"
        [timeZoneOffset]="timeZoneOffset"
        id="symptom_container"
      ></app-tptable>
    </section>
  </div>

  <div class="p-grid p-fluid" *ngIf="symptoms.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
  <p-dialog
    *ngIf="display"
    header=" {{ ('symptom.symptomDetails' | translate) + ': ' + symptomName }} "
    [(visible)]="display"
    [style]="{ width: '60vw', height: '60vh' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [closable]="true"
    (close)="display = false"
  >
    <div class="p-grid p-fluid" *ngIf="symptomRecords.length === 0">
      <h4 style="width: 100%; text-align: center">
        {{ 'general.noResultsFound' | translate }}
      </h4>
    </div>
    <p-table
      [value]="symptomRecords"
      [scrollable]="true"
      [rows]="100"
      scrollHeight="60vh"
      [virtualScroll]="true"
      [virtualRowHeight]="34"
      dataKey="id"
      *ngIf="symptomRecords.length > 0"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="date">
            {{ 'audit.date' | translate }}
          </th>
          <th id="day">
            {{ 'general.day' | translate }}
          </th>
          <th id="variable">
            {{ 'general.configurations.enums.intensity' | translate }}
          </th>
          <th id="size">
            {{ 'general.configurations.enums.size' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>{{ record.dayInstance?.startDate }}</td>
          <td>{{ record.dayInstance?.confDay?.dayName | dictionary }}</td>
          <td>
            {{ 'symptom.enums.intensityTypes.' + record.intensity | translate }}
          </td>
          <td>{{ record.size }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>
</section>

<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
