import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API, Logger, Storage, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  INTEGRATIONS_API,
  MaintenanceInfo,
  TP2Entites,
  TP2_ERRORS,
} from 'src/app/shared/global.variables';
import { ChangeReasonComponent } from '../shared/components/change-reason/change-reason.component';
import {
  ON_BOARDING_KEY,
  OnBoardingComponent,
} from '../shared/components/on-boarding/on-boarding.component';
import { DictionaryService } from '../shared/components/table-languages/dictionary.service';
import { DictionaryPipe } from '../shared/pipes/dictionary.pipe';
import { RequestParamsBuilder } from '../shared/utils/requestParmasBuilder';
import { AuthService } from './auth.service';
import { PhoneNumberService } from './phone-number.service';
import { TRIALPAL_QUERIES } from './trialpal.queries';
import {
  GetAuditInfoQuery,
  ModelAuditTraceFilterInput,
  ModelSortDirection,
} from './trialpal.types';
const logger = new Logger('tp2-logger-tp2Service');
@Injectable({
  providedIn: 'root',
})
export class TrialpalService {
  phoneNumberService: PhoneNumberService;
  translateService: TranslateService;
  messageService: MessageService;
  spinnerSubject = new BehaviorSubject<any>({ show: false, text: '' });
  config: PrimeNGConfig;
  dialogService: DialogService;
  dictionaryService: DictionaryService;
  dictionaryPipe: DictionaryPipe;
  ref: DynamicDialogRef;
  closable: boolean = true;
  maintenanceInfo = new BehaviorSubject<MaintenanceInfo>({ enable: false });
  maintenanceBannerInfo = new BehaviorSubject<MaintenanceInfo>({
    enable: false,
  });
  private paramsBuilder: RequestParamsBuilder = new RequestParamsBuilder('');
  constructor(
    messages: MessageService,
    translateService: TranslateService,
    private authService: AuthService,
    config: PrimeNGConfig,
    dialogService: DialogService,
    dictionaryService: DictionaryService,
    dictionaryPipe: DictionaryPipe,
    ref: DynamicDialogRef,
    phoneNumberService: PhoneNumberService
  ) {
    this.messageService = messages;
    this.translateService = translateService;
    this.config = config;
    this.dialogService = dialogService;
    this.dictionaryService = dictionaryService;
    this.dictionaryPipe = dictionaryPipe;
    this.ref = ref;
    this.phoneNumberService = phoneNumberService;
  }
  /**
   * Muestra un spinner con un mensaje dependiendo de la transacción que se ejecute.
   * Si no se encuentra la llave de traducción mostrará el string de la llave.
   * @param entity llave con los datos de la entidad que se va a trabajar
   * @param actionType enumeración de tipos de acción (CREATE, UPDATE, DELETE, LIST, GET, SEARCH)
   */
  showSpinner(entity: string, actionType?: string): void {
    this.translateService.get('general').subscribe((_res) => {
      const trans = this.translateService.instant(entity);
      const input = {
        text: entity,
        show: true,
      };
      if (entity && trans) {
        if (actionType === 'CREATE') {
          input.text = this.translateService.instant(
            'general.transactionLoadingCreate',
            { entity: trans }
          );
        } else if (actionType === 'UPDATE') {
          input.text = this.translateService.instant(
            'general.transactionLoadingUpdate',
            { entity: trans }
          );
        } else if (actionType === 'DELETE') {
          input.text = this.translateService.instant(
            'general.transactionLoadingDelete',
            { entity: trans }
          );
        } else if (actionType === 'GET') {
          input.text = this.translateService.instant(
            'general.transactionLoadingGet',
            { entity: trans }
          );
        } else if (actionType === 'LIST') {
          input.text = this.translateService.instant(
            'general.transactionLoadingListing',
            { entity: trans }
          );
        } else if (actionType === 'SEARCH') {
          input.text = this.translateService.instant(
            'general.transactionLoadingSearching',
            { entity: trans }
          );
        } else if (actionType === 'SEND') {
          input.text = this.translateService.instant(
            'general.transactionSendCode',
            {
              entity: trans,
            }
          );
        } else if (actionType === 'DOWNLOAD') {
          input.text = this.translateService.instant(
            'general.transactionLoadingDowload',
            {
              entity: trans,
            }
          );
        } else {
          input.text = trans;
        }
      }
      input.text = this.toUpperCaseFirstLetter(input.text);
      this.spinnerSubject.next(input);
    });
  }
  /**
   * Oculta el spinner
   */
  hideSpinner(): void {
    const input = {
      text: '',
      show: false,
    };
    this.spinnerSubject.next(input);
  }

  // Limpia los atributos generados por el Query. Sirve para las transacciones de update.
  cleanQueryResponse(response: any): void {
    try {
      delete response.__typename;
      delete response.createdAt;
      delete response.updatedAt;
      delete response.version;
      delete response._deleted;
      delete response._version;
      delete response._lastChangedAt;
    } catch (error) {
      logger.error(error);
    }
  }
  /**
   * Mensaje genérico para mostrar al usuario cuando una transacción falla.
   * @param actionI18nKey llave del archivo de internacionalización con la llave de la transacción
   * @param error String con el mensaje de error del sistema
   */
  showServiceError(actionI18nKey: string, error: any): void {
    if (error?.errors) {
      for (const e of error.errors) {
        const exce = {
          severity: 'error',
          summary: '',
          detail: '',
          sticky: false,
          life: 9000,
        };
        if (
          e.errorType === 'ExecutionTimeout' ||
          e.errorType === 'Lambda:ExecutionTimeoutException'
        ) {
          exce.severity = 'warn';
          exce.summary = this.toUpperCaseFirstLetter(
            this.translateService.instant(actionI18nKey)
          );
          exce.detail = this.translateService.instant('general.timeOut');
        } else if (e.errorType === 'Lambda:Unhandled') {
          try {
            const msg = JSON.parse(e.message);
            if (msg.code) {
              exce.summary = this.translateService.instant(
                'general.messageErrorOperation.summary'
              );
              exce.detail =
                this.translateService.instant('apiError.' + msg.code) +
                ` (${msg.code})`;
              if (msg.code === TP2_ERRORS.TP2ERRUC03) {
                this.authService
                  .logout()
                  .then(() => logger.debug('User logout successfull'))
                  .catch(() => logger.debug('User logout faild'));
              }
            } else {
              exce.summary = this.translateService.instant(
                'errorMessage.anErrorOccurred'
              );
              exce.detail = msg;
            }
          } catch (er) {
            this.showUncontrolledError(actionI18nKey);
            exce.summary = e.errorType;
            exce.detail = e.path.toString();
          }
        } else if (
          e.message.includes("Variable 'phoneNumber' has an invalid value")
        ) {
          exce.summary = '';
          exce.detail = this.translateService.instant(
            'user.phoneNumberMessages.noValidPhone'
          );
        } else {
          this.showUncontrolledError(actionI18nKey);
          exce.summary = e.errorType;
          exce.detail = e.message;
        }
        this.messageService.add(exce);
      }
    }
  }

  /**
   * Mensaje genérico para mostrar al usuario cuando ocurre un error no controlado en el sistema
   * @param actionI18nKey @param actionI18nKey llave del archivo de internacionalización con la llave de la transacción
   */
  showUncontrolledError(actionI18nKey: string): void {
    const msgSummary = this.translateService.instant(
      'general.serviceErrorSummary'
    );
    const transaction = this.translateService
      .instant(actionI18nKey)
      .toLowerCase();
    const msgDetail = this.translateService.instant(
      'general.serviceErrorDetail',
      {
        action: transaction,
      }
    );
    this.messageService.add({
      severity: 'error',
      summary: msgSummary,
      detail: msgDetail,
      sticky: false,
      life: 9000,
    });
  }

  /**
   * Mensaje genérico para mostrar al usuario cuando un formulario es inválido
   * @param entityI18nKey translate key para el entity/form que es inválido
   */
  showInvalidFormError(msgSummary?: string, msgDetail?: string): void {
    //se usa el metodo clear para borrar el mensaje anterior en caso de que exista.
    this.messageService.clear();
    const defaultMsgSummary = this.translateService.instant(
      'general.formErrorSummary'
    );
    const defaultMsgDetail = this.translateService.instant(
      'general.formErrorDetail'
    );
    this.messageService.add({
      severity: 'error',
      summary: msgSummary ?? defaultMsgSummary,
      detail: msgDetail ?? defaultMsgDetail,
      sticky: true,
      life: 9000,
      closable: true,
    });
  }

  /**
   * Mensaje genérico para mostrar al usuario cuando una mutación es exitosa.
   * @param entity llave del archivo de internacionalización con la llave
   * @param actionType enumeración de tipos de acción (CREATE, UPDATE, DELETE, LIST, GET, SEARCH)
   */
  showMutationSuccess(
    entity: string,
    actionType?: string,
    msgSummary?: string,
    msgDetail?: string
  ): void {
    const defaultMsgSummary = this.translateService.instant(
      'general.transactionSuccessSummary'
    );
    let defaultMsgDetail = '';
    const trans = entity ? this.translateService.instant(entity) : '';
    if (entity && trans) {
      switch (actionType) {
        case 'CREATE':
          defaultMsgDetail = this.translateService.instant(
            'general.mutationCreated',
            {
              entity: trans,
            }
          );
          break;
        case 'UPDATE':
          defaultMsgDetail = this.translateService.instant(
            'general.mutationUpdated',
            {
              entity: trans,
            }
          );
          break;
        case 'DELETE':
          defaultMsgDetail = this.translateService.instant(
            'general.mutationDeleted',
            {
              entity: trans,
            }
          );
          break;
        case 'SEND':
          defaultMsgDetail = this.translateService.instant(
            'general.mutationSend',
            {
              entity: trans,
            }
          );
          break;
        case 'SUSPENDED':
          defaultMsgDetail = this.translateService.instant(
            'general.mutationSuspended',
            {
              entity: trans,
            }
          );
          break;

        case 'DOWNLOAD':
          defaultMsgDetail = this.translateService.instant(
            'general.mutationDownload',
            {
              entity: trans,
            }
          );
          break;
        default:
          break;
      }
    }

    this.messageService.add({
      severity: 'success',
      life: 10000,
      summary: msgSummary ?? defaultMsgSummary,
      detail: this.toUpperCaseFirstLetter(msgDetail ?? defaultMsgDetail),
    });
  }
  validatePassword(password: string): boolean {
    const regex =
      /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,20}$/;
    return regex.test(password);
  }

  printValue(val: any) {
    if (val) {
      return val;
    } else {
      return '---';
    }
  }

  toUpperCaseFirstLetter(value: string) {
    if (value && value !== '') {
      return value.charAt(0).toUpperCase() + value.substring(1);
    } else {
      return value;
    }
  }

  truncateString(val: string, max: number) {
    if (val.length <= max) {
      return val;
    } else {
      return val.slice(0, max) + '...';
    }
  }

  async getAuditInfo(
    entity: TP2Entites | undefined,
    id: string
  ): Promise<GetAuditInfoQuery> {
    const filter: ModelAuditTraceFilterInput = {
      eventType: {
        eq: 'MODIFY',
      },
    };

    let instances: any[] = [];
    if (entity) {
      let nextToken = undefined;
      do {
        const listQuery = await this.performGraphQLQuery(
          TRIALPAL_QUERIES.GetAuditInfo,
          {
            entity,
            relatedEntityId: { eq: id },
            sortDirection: ModelSortDirection.DESC,
            filter,
            limit: 10000,
          }
        );
        instances = instances.concat(listQuery?.data.getAuditInfo.items);
        nextToken = listQuery.data?.getAuditInfo.nextToken;
      } while (nextToken);
    }
    return {
      __typename: 'ModelAuditTraceConnection',
      items: instances,
    };
  }
  changeLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
    location.reload();
    this.translateService.get('primeng').subscribe((res) => {
      this.config.setTranslation(res);
    });
  }

  saveImage(folder: string, file: File, name: string): Promise<any> {
    return Storage.put(folder + '/' + name, file, {
      level: 'protected',
      contentType: 'image/*',
    });
  }

  getImage(folder: string, key: string): Promise<any> {
    return Storage.get(folder + '/' + key, { level: 'protected' });
  }

  isJSON(message: any) {
    try {
      JSON.parse(message);
    } catch (e) {
      return false;
    }
    return true;
  }
  getTranslatedEnum(enumName: string, type: any): any[] {
    const data: any[] = [];
    const list = this.translateService.instant(enumName);
    Object.keys(type).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  validatEspecialCharacters(value: any, control: string, form: any) {
    let expReg = /^([a-zA-Z0-9_.:-])+$/;
    if (!expReg.test(value)) {
      logger.debug(control, 'controll');
      form.controls[control]?.setErrors({ incorrect: true });
    }
  }
  validateSpaces(value: any, control: string, form: any) {
    if (value?.length === 0) {
      logger.debug(control, 'controll');
      form.controls?.[control]?.setErrors({ incorrect: true });
    }
  }
  validateArrayLength(value: any[], control: string, form: any) {
    if (value?.length > 55) {
      logger.debug(control, 'controll');
      form.controls?.[control]?.setErrors({ lenght: true });
      return true;
    }
    return false;
  }

  isBeforeToCurrentDate(date: any) {
    if (date) {
      date = this.transformCurrentTimezone(date);
      date = moment(date);
      const firstDate = moment()
        .year(date.year())
        .month(date.month())
        .date(date.date());
      return moment(firstDate).isBefore(moment(), 'days');
    }
    return false;
  }

  isAfterToCurrentDateWithTimeZone(date: any) {
    if (date) {
      return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'), 'days');
    }
    return false;
  }

  diffBetweenDates(completedPhaseDate: any) {
    const currentDate = moment().format('YYYY-MM-DD');
    completedPhaseDate = moment(new Date(completedPhaseDate)).format(
      'YYYY-MM-DD'
    );
    return moment(currentDate).diff(completedPhaseDate, 'days');
  }

  addDaysToDate(completedPhaseDate: any, days: number) {
    completedPhaseDate = moment(new Date(completedPhaseDate)).format(
      'YYYY-MM-DD'
    );

    return moment(completedPhaseDate).add(days, 'days').format('YYYY-MM-DD');
  }

  transformCurrentTimezone(date: any) {
    const currentDate = new Date();
    const GMT = (-1 * currentDate.getTimezoneOffset()) / 60; //Obteniendo GMT actual [-12 - +14]
    let hourGMT_00 = currentDate.getHours() + -1 * GMT; //Obteniendo horas en GTM+0 [-24 - 24]
    hourGMT_00 = hourGMT_00 > 0 ? hourGMT_00 : 24 + hourGMT_00; //Obteniendo horas en GTM+0 [1 - 24]
    const new_fecha = new Date(date); //obteniendo fecha en formato Date
    new_fecha.setHours(new_fecha.getHours() + hourGMT_00 + GMT); // Sumarle horas obtenidas en GMT y hourGMT_00
    let fecha = new_fecha.toISOString(); //Formaro ISO string AAAA-MM-DDTHH:MM:SS.sssZ
    const fecha_date = fecha.split('T'); // Split por T que separa AAAA-MMM-DD y HH:MM:SS.sssZ
    fecha = fecha_date[0]; // Primera posicion donde guardamos el formato AAAA-MMM-DD
    return fecha; // Pasamos el resultado por el metodo transform para convertir al formato del proyecto
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.messageService.clear();
    this.messageService.add({
      severity: 'info',
      detail: this.translateService.instant('general.copied'),
      life: 2000,
      closable: false,
      styleClass: 'clipboard',
    });
  }

  async modalChangeReason(input: any, header: string) {
    const ref = this.dialogService.open(ChangeReasonComponent, {
      header: header + ': ' + this.dictionaryPipe.transform(input.name ?? ''),
      width: '70%',
    });
    return new Promise((resolve) => {
      ref.onClose.subscribe({
        next: (data: string) => {
          if (data) {
            input._changeReason = data;
            resolve(input);
          } else {
            resolve(input);
            this.hideSpinner();
          }
        },
      });
    });
  }

  successUpdateMessage() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant(
        'general.transactionSuccessSummary'
      ),
      detail: this.translateService.instant('general.updateSuccess'),
    });
    this.ref.close(null);
  }

  errorOperationMessage() {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(
        'general.messageErrorOperation.summary'
      ),
      detail: this.translateService.instant(
        'general.messageErrorOperation.detail'
      ),
    });
  }
  async getFeatureFlag(featureName: string): Promise<any> {
    const params = await this.paramsBuilder
      .withBody({ feature: featureName })
      .build();
    return API.post(INTEGRATIONS_API, '/common/feature-flags', params);
  }
  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }

  /**
   * Muestra el componente de OnBoarding en un cuadro de diálogo modal.
   *
   * @param {string} key - La clave que identifica el onboarding.
   * @param {string} title - El título que se mostrará en el componente de OnBoarding.
   * @param {string} btnText - El texto del botón que se mostrará en el componente de OnBoarding.
   * @param {string[]} steps - Una lista de pasos que se mostrarán en el componente de OnBoarding.
   */
  openOnBoarding(key: string, title: string, btnText: string, steps: string[]) {
    if (!this.shouldShowOnboarding(key)) return;
    this.dialogService.open(OnBoardingComponent, {
      showHeader: false,
      width: '370px',
      styleClass: 'modal-rounded',
      data: {
        title,
        btnText,
        key,
        steps,
      },
      dismissableMask: false,
      height: '95vh',
      closeOnEscape: false,
      baseZIndex: 100000,
    });
  }

  /**
   * Verifica si se debe mostrar el onboarding para un usuario específico y una clave dada.
   *
   * @param {string} key - La clave que identifica el onboarding.
   * @returns {boolean} - Retorna `true` si se debe mostrar el onboarding, `false` en caso contrario.
   */
  shouldShowOnboarding(key: string): boolean {
    const onboardingData = localStorage.getItem(ON_BOARDING_KEY);
    const users = onboardingData ? JSON.parse(onboardingData) : [];
    const currentUsername = this.authService.getUsername();
    const userOnboardingIndex = users.findIndex(
      (user: any) => user.key === key && user.username === currentUsername
    );
    return userOnboardingIndex === -1;
  }
}
