import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import {
  InstanceState,
  VerifiedSymptomInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import { IntensityReconciliationOption } from 'src/app/services/API.service';
import { Symptom } from 'src/app/services/trialpal.types';
import { TP2Entites } from 'src/app/shared/global.variables';
import en from '../../../../../../../assets/i18n/en.json';
import es from '../../../../../../../assets/i18n/es.json';
import { TrialpalService } from '../../../../../../services/trialpal.service';
import { ConciliationService } from '../../../../conciliation.service';
import {
  AddConfOtherSymptom,
  ConciliationComponents,
} from '../../../../conciliationData';
import { PaginationService } from '../../../../pagination.service';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import { SymptomService } from '../../symptom/symptom.service';
import { SummaryOtherSymptomComponent } from '../summary/summary-other-symptoms.component';
const logger = new Logger('Edit OtherSymptom');

@Component({
  selector: 'app-edit-other-symptoms',
  templateUrl: './edit-other-symptoms.component.html',
  styleUrls: ['./edit-other-symptoms.component.scss'],
})
export class EditOtherSymptomsComponent implements OnChanges {
  @Input() currentOtherSymptom: any = {};
  @Output()
  exportAuditRecord: EventEmitter<any> = new EventEmitter();
  configuration: any = {};
  intensities: any[] = [];
  professionalHealthCares: any[] = [];
  hospitalAdmissions: any[] = [];
  symptoms: any[] = [];
  filteredSymptoms: any[] = [];
  form: FormGroup;
  isNext: boolean = false;
  formChange: any = {};
  minDate: Date = new Date();
  maxDate: Date = new Date();
  selectedSymptom: string = '';
  isDelete: boolean = false;
  dayInstances: any[] = [];
  isNew: boolean = false;
  dayInstanceSelected: any = {};
  configurations: any[] = [];
  booleanOptions: any[] = [];
  constructor(
    public dialogService: DialogService,
    public symptomService: SymptomService,
    public conciliationService: ConciliationService,
    private formBuilder: FormBuilder,
    private trialpalService: TrialpalService,
    private paginationService: PaginationService,
    private dayInstanceService: DayInstanceService
  ) {
    this.form = this.formBuilder.group({});
    this.buildForm();
  }

  ngOnChanges() {
    if (this.currentOtherSymptom !== 'isNew') {
      this.initOtherSymptom();
    } else {
      this.getDayInstances();
    }
  }

  initOtherSymptom() {
    this.buildForm();
    this.buildIntensities();
    this.buildProfessionalHealthCares();
    this.buildSymptoms();
    this.booleanOptions = this.conciliationService.buildBooleanOptions();
    let formValues =
      this.currentOtherSymptom?.verifiedOtherSymptom ||
      this.currentOtherSymptom?.otherSymptom;

    logger.debug('formValues', formValues);
    this.isDelete =
      this.currentOtherSymptom?.verifiedOtherSymptom?.state ===
      InstanceState.DELETED;

    this.showAuditComponent(formValues);

    //Rango de fechas que puede el usuario seleccionar
    this.maxDate = new Date(
      this.currentOtherSymptom.dayInstance?.startDate + 'T00:00:00'
    );
    this.minDate = new Date(
      this.currentOtherSymptom.dayInstance?.finishDate + 'T00:00:00'
    );

    this.configuration = this.currentOtherSymptom.confSymptom || { id: '' }; // Obtiene la configuración del medicamento

    this.requiredsFields(); //Se encarga de activar o desactivar campos

    //Formulario
    this.fillForm(formValues);
  }

  showAuditComponent(formValues: any) {
    this.exportAuditRecord.emit(null);
    if (this.currentOtherSymptom?.isEdition) {
      this.exportAuditRecord.emit({
        entity: TP2Entites.VERIFIEDOTHERSYMPTOMINSTANCE,
        id: this.currentOtherSymptom.verifiedOtherSymptom.id,
        description: formValues?.whichOtherSymptom || '',
      });
    }
  }

  async getDayInstances() {
    const verifiedDayInstances = this.dayInstanceService.verifiedDayInstances;

    let dayInstances = [];

    //Filtra los dias donde los otros sintomas tengan la precondicion en si
    dayInstances = this.symptomService.addConfOtherSymptoms.filter(
      (confOtherSymptom: AddConfOtherSymptom) => {
        const verified = verifiedDayInstances?.find((VDI: any) => {
          return VDI?.dayInstanceId === confOtherSymptom.dayInstance.id;
        });

        const showPrecondition =
          confOtherSymptom.confOtherSymptom?.showPrecondition;

        const otherSymptomsReconciliationConfig =
          confOtherSymptom.dayInstance?.confDay?.confForm
            ?.otherSymptomsReconciliationConfig;

        return (
          (!showPrecondition ||
            (showPrecondition && verified?.hasOtherSymptoms)) &&
          otherSymptomsReconciliationConfig?.allowAddRecords
        );
      }
    );

    logger.debug('dayInstances other Symptoms', dayInstances);

    //Valida que solo muestres los dias que ya hayan pasado
    dayInstances = dayInstances.filter((DI: any) => {
      const configuration = DI.dayInstance?.confDay?.confForm;
      //Valida si el formulario tiene habilitado los otros sintomas para conciliar
      const isOtherSymptomConciliationAllowed =
        this.paginationService.isSectionReconciliationAllowed(
          'areOtherSymptomsRequired',
          configuration
        );

      return (
        this.conciliationService.isBeforeToCurrentDate(
          DI.dayInstance.finishDate
        ) && isOtherSymptomConciliationAllowed
      );
    });

    this.dayInstances = dayInstances.map((DI: any) => {
      let dayName = DI.dayInstance.confDay.dayName;
      return {
        label: this.trialpalService.dictionaryPipe.transform(dayName),
        value: DI,
      };
    });
    this.dayInstances.sort(
      (a: any, b: any) =>
        a?.value?.dayInstance?.confDay?.order -
        b?.value?.dayInstance?.confDay?.order
    );
  }

  async newOtherSymptom() {
    this.configuration = this.dayInstanceSelected.confOtherSymptom;
    const dayInstance = this.dayInstanceSelected?.dayInstance;
    this.paginationService.dayName = dayInstance?.confDay?.dayName;
    this.currentOtherSymptom = {
      confSymptom: this.configuration,
      dayInstance: dayInstance,
      confForm: dayInstance.confDay?.confForm,
      dayName: dayInstance.confDay.dayName,
      otherSymptom: this.symptomService.getEmptyOtherSymptom(
        dayInstance.id,
        this.configuration?.id || ''
      ),
      verifiedOtherSymptom: {},
      isEdition: false,
      symptomName: '',
      verifiedSymptomName: '',
      comments: {},
    };
    this.dayInstanceSelected = {};
    this.symptomService.isNewOtherSymptom = false;
    this.symptomService.currentOtherSymptom = this.currentOtherSymptom;
    this.initOtherSymptom();
  }

  fillForm(formValues: any) {
    const values = this.getFormValues(formValues);
    this.form.patchValue(values);
    //Copia del formulario
    this.formChange = { ...values };
  }

  requiredsFields() {
    this.intensityRequired();
    this.professionalHealthCareRequired();
    this.hospitalAdmissionRequired();
    this.rememberStartDateRequired();
    this.rememberFinishRequired();
    this.isStartDateEnabled();
    this.isFinishDateEnabled();
  }

  getFormValues(formValues: any) {
    return {
      symptom: formValues?.symptom,
      whichOtherSymptom: formValues?.whichOtherSymptom,
      whichOtherSymptomComment: formValues?.whichOtherSymptomComment,
      intensity: formValues?.intensity,
      intensityComment: formValues?.intensityComment,
      professionalHealthCare: formValues?.professionalHealthCare,
      professionalHealthCareComment: formValues?.professionalHealthCareComment,
      hospitalAdmission: formValues?.hospitalAdmission,
      hospitalAdmissionComment: formValues?.hospitalAdmissionComment,
      //verifiedRememberStartDate
      rememberStartDate: formValues?.rememberStartDate,
      //verifiedStartDate
      startDate: this.conciliationService.getFormatDate(formValues?.startDate),
      //verifiedStartDateComment
      startDateComment: formValues?.startDateComment,
      //verifiedRememberFinishDate
      rememberFinishDate: formValues?.rememberFinishDate,
      //verifiedFinishDate
      finishDate: this.conciliationService.getFormatDate(
        formValues?.finishDate
      ),
      //verifiedFinishDateComment
      finishDateComment: formValues?.finishDateComment,
    };
  }
  buildForm() {
    this.form = this.formBuilder.group({
      symptom: [],
      whichOtherSymptom: ['', [Validators.required]],
      whichOtherSymptomComment: [],
      intensity: [],
      intensityComment: [],
      professionalHealthCare: [''],
      professionalHealthCareComment: [],
      hospitalAdmission: [],
      hospitalAdmissionComment: [],
      rememberStartDate: [],
      startDate: [],
      startDateComment: [],
      rememberFinishDate: [],
      finishDate: [],
      finishDateComment: [],
      _changeReason: [],
    });
  }

  buildIntensities() {
    this.intensities = [
      {
        label: this.trialpalService.translateService.instant(
          'symptominstance.enums.LOW'
        ),
        value: 'LOW',
      },
      {
        label: this.trialpalService.translateService.instant(
          'symptominstance.enums.MEDIUM'
        ),
        value: 'MEDIUM',
      },
      {
        label: this.trialpalService.translateService.instant(
          'symptominstance.enums.HIGH'
        ),
        value: 'HIGH',
      },
      {
        label: this.trialpalService.translateService.instant(
          'symptominstance.enums.LIFE_THREATENING'
        ),
        value: 'LIFE_THREATENING',
      },
    ];

    const allowedIntensityAction =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig
        ?.allowIntensityReconciliation?.allowedAction;

    logger.debug('allowedIntensityAction', allowedIntensityAction);

    if (
      allowedIntensityAction &&
      this.currentOtherSymptom?.otherSymptom?.id !==
        this.conciliationService.NEW_INSTANCE_ID
    ) {
      if (allowedIntensityAction !== IntensityReconciliationOption.ANY) {
        const originalIntensity =
          this.currentOtherSymptom?.otherSymptom?.intensity;
        const originalIntensityIndex = this.intensities.findIndex(
          (intensity: any) => intensity.value === originalIntensity
        );
        if (allowedIntensityAction === IntensityReconciliationOption.DECREASE) {
          this.intensities = this.intensities.slice(
            0,
            originalIntensityIndex + 1
          );
        }
        if (allowedIntensityAction === IntensityReconciliationOption.INCREASE) {
          this.intensities = this.intensities.slice(
            originalIntensityIndex,
            this.intensities.length
          );
        }
      }
    }
  }

  buildProfessionalHealthCares() {
    this.professionalHealthCares = [
      {
        label: this.trialpalService.translateService.instant(
          'otherSymptom.requiredVisitProfessional.YES'
        ),
        value: 'YES',
      },
      {
        label: this.trialpalService.translateService.instant(
          'otherSymptom.requiredVisitProfessional.NO'
        ),
        value: 'NO',
      },
      {
        label: this.trialpalService.translateService.instant(
          'otherSymptom.requiredVisitProfessional.NOT_APPLY'
        ),
        value: 'NOT_APPLY',
      },
    ];
    this.hospitalAdmissions = this.professionalHealthCares.slice(0, 2);
  }

  async buildSymptoms() {
    this.symptoms = this.trialpalService.getTranslatedEnum(
      'enums.symptoms',
      Symptom
    );

    let symptomEs: any = es.symptom.enums.symptoms;
    let symptomEn: any = en.symptom.enums.symptoms;

    this.symptoms = this.symptoms?.map((symptom: any) => {
      return {
        ...symptom,
        name: this.trialpalService.translateService.instant(
          'symptom.enums.symptoms.' + symptom.value
        ),
        en: symptomEn[symptom.value],
        es: symptomEs[symptom.value],
      };
    });
  }

  selectSymptom(symptom: any) {
    this.form.controls['whichOtherSymptom'].setValue(symptom.name);
    this.form.controls['symptom'].setValue(symptom.value);
    this.filteredSymptoms = [];
  }

  filterSymptoms() {
    const whichOtherSymptom = this.form.controls['whichOtherSymptom'].value
      ?.trim()
      .toLowerCase();
    if (whichOtherSymptom?.length > 0) {
      this.filteredSymptoms = this.symptoms.filter((symptom: any) => {
        return symptom.name.trim().toLowerCase().includes(whichOtherSymptom);
      });
    }
  }

  onProfessionalHealthCaresChange() {
    this.validateHospitalAdmissions();
  }

  async onSubmit() {
    if (this.isDelete) return this.nextOtherSymptoms();
    this.conciliationService.clearSpaces(this.form);
    if (this.form.valid) {
      this.validateSymptom();
      this.validateHospitalAdmissions();
      return this.sendCreateOrUpdateOtherSymptom();
    } else {
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
  }

  async sendCreateOrUpdateOtherSymptom() {
    const isValidDates = this.conciliationService.isValidDates(this.form);
    if (!isValidDates) return this.conciliationService.messageErrorDates();
    const isFormChange = this.conciliationService.isFormChange(
      this.form.value,
      this.formChange
    );
    if (isFormChange) {
      this.openModal();
    } else {
      const isCommentChange = this.conciliationService.isFormCommentChange(
        this.form.value,
        this.formChange
      );
      await this.sendVerifiedOtherSymptom(isCommentChange);
    }
  }

  validateSymptom() {
    let whichOtherSymptom = this.form.controls['whichOtherSymptom']?.value;
    if (whichOtherSymptom) {
      whichOtherSymptom = whichOtherSymptom.trim().toLowerCase();
      const symptomExist = this.symptoms.find((symptom: any) => {
        return (
          whichOtherSymptom === symptom.name.trim().toLowerCase() ||
          whichOtherSymptom === symptom.en.trim().toLowerCase() ||
          whichOtherSymptom === symptom.es.trim().toLowerCase()
        );
      });
      if (symptomExist) {
        this.form.controls['symptom'].setValue(symptomExist.value);
      } else {
        this.form.controls['symptom'].setValue(Symptom.OTHER);
      }
    }
  }

  validateHospitalAdmissions() {
    if (this.form.controls['professionalHealthCare']?.value !== 'YES') {
      this.form.controls['hospitalAdmission'].setValue('NO');
    }
  }

  async openModal() {
    const [original, verified] = this.conciliationService.getValuesChange(
      this.formChange,
      this.form.value
    );
    const ref = this.dialogService.open(SummaryOtherSymptomComponent, {
      data: {
        original,
        verified,
        configuration: this.configuration,
      },
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmSaveForm'
      ),
      style: { 'min-width': '360px', width: '50%' },
    });
    ref.onClose.subscribe((canPass: boolean) => {
      if (canPass) {
        this.conciliationService.recordChangeEvent(
          verified,
          original,
          'otherSymptoms'
        );
        this.sendVerifiedOtherSymptom(true);
      }
    });
  }

  async sendVerifiedOtherSymptom(isChange: boolean = false) {
    if (this.currentOtherSymptom.isEdition) {
      //Si no hubo un cambio pasa al siguiente medicamento
      if (!isChange) {
        this.nextOtherSymptoms();
      } else {
        await this.updateVerifiedOtherSymptom();
      }
    } else {
      await this.createVerifiedOtherSymptom();
    }
  }

  async createVerifiedOtherSymptom() {
    this.conciliationService.showSpinner(
      'transactionLoadingCreate',
      'symptominstance.entity'
    );
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    this.symptomService
      .createVerifiedSymptom(
        this.currentOtherSymptom.otherSymptom,
        this.form.value
      )
      .then((verifiedOtherSymptom: VerifiedSymptomInstance) => {
        this.updateTable(verifiedOtherSymptom);
        this.conciliationService.messageSuccess(true);
      })
      .catch((err: any) => {
        logger.error('create error', err);
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async updateVerifiedOtherSymptom() {
    this.conciliationService.showSpinner(
      'transactionLoadingUpdate',
      'symptominstance.entity'
    );
    const reason = this.form.controls['_changeReason'].value;
    this.currentOtherSymptom.verifiedOtherSymptom._changeReason =
      reason ?? this.currentOtherSymptom.verifiedOtherSymptom?._changeReason;
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    this.symptomService
      .updateVerifiedSymptom(
        this.currentOtherSymptom?.verifiedOtherSymptom,
        this.form.value
      )
      .then((verifiedSymptom: VerifiedSymptomInstance) => {
        this.updateTable(verifiedSymptom);
        this.conciliationService.messageSuccess(false);
      })
      .catch((err: any) => {
        logger.error('update error', err);
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  updateTable(verifiedOtherSymptom: any) {
    this.currentOtherSymptom.verifiedOtherSymptom = verifiedOtherSymptom;

    const isNewOtherSymptom =
      this.currentOtherSymptom.otherSymptom.id ===
        this.conciliationService.NEW_INSTANCE_ID &&
      !this.currentOtherSymptom.isEdition;

    if (isNewOtherSymptom) {
      this.symptomService.tableOtherSymptoms.push(this.currentOtherSymptom);
    }

    this.currentOtherSymptom.isEdition = true;
    this.currentOtherSymptom.verifiedSymptomName =
      verifiedOtherSymptom?.symptom === 'OTHER'
        ? verifiedOtherSymptom?.whichOtherSymptom || ''
        : verifiedOtherSymptom?.symptom || '';
    this.fillForm(verifiedOtherSymptom);
    this.symptomService.addOtherSymptomComments();
    if (isNewOtherSymptom) {
      this.paginationService.selectedOtherSymptom({}, true);
    } else {
      this.nextOtherSymptoms();
    }
  }

  //Verifica que campos seran requeridos
  intensityRequired() {
    this.conciliationService.setValidator(
      this.form.controls['intensity'],
      this.configuration?.showIntensity && !this.shouldHideIntensity()
    );
  }

  professionalHealthCareRequired() {
    this.conciliationService.setValidator(
      this.form.controls['professionalHealthCare'],
      this.configuration?.showProfessionalHealthCare &&
        !this.shouldHideHospitalizationInfo()
    );
  }

  hospitalAdmissionRequired() {
    this.conciliationService.setValidator(
      this.form.controls['hospitalAdmission'],
      this.configuration?.showProfessionalHealthCare &&
        !this.shouldHideHospitalizationInfo()
    );
  }

  rememberStartDateRequired() {
    const isShowRememberStartDateEnabled =
      this.configuration?.showRememberStartDate;
    this.conciliationService.setValidator(
      this.form.controls['rememberStartDate'],
      isShowRememberStartDateEnabled && !this.shouldHideDateFields()
    );
  }

  rememberFinishRequired() {
    const isShowRememberFinishEnabled =
      this.configuration?.showRememberFinishDate;
    this.conciliationService.setValidator(
      this.form.controls['rememberFinishDate'],
      isShowRememberFinishEnabled && !this.shouldHideDateFields()
    );
    return isShowRememberFinishEnabled;
  }

  isStartDateEnabled() {
    const isStartDateEnabled = this.form.controls['rememberStartDate']?.value;
    this.conciliationService.setValidator(
      this.form.controls['startDate'],
      isStartDateEnabled && !this.shouldHideDateFields()
    );
    return isStartDateEnabled;
  }

  isFinishDateEnabled() {
    const isFinishDateEnabled = this.form.controls['rememberFinishDate']?.value;
    this.conciliationService.setValidator(
      this.form.controls['finishDate'],
      isFinishDateEnabled && !this.shouldHideDateFields()
    );
    return isFinishDateEnabled;
  }

  //Verifica que datos han cambiado

  isWhichOtherSymptomChange() {
    const isWhichOtherSymptomChange =
      this.form.controls['whichOtherSymptom']?.value !==
        this.formChange?.whichOtherSymptom ||
      this.formChange?.whichOtherSymptomComment;

    this.conciliationService.setValidator(
      this.form.controls['whichOtherSymptomComment'],
      isWhichOtherSymptomChange
    );

    return isWhichOtherSymptomChange;
  }

  isIntensityChange() {
    const isIntensityChange =
      this.form.controls['intensity'].value !== this.formChange?.intensity ||
      this.formChange?.intensityComment;

    this.conciliationService.setValidator(
      this.form.controls['intensityComment'],
      isIntensityChange
    );

    return isIntensityChange;
  }

  isProfessionalHealthCareChange() {
    const isProfessinatHealthCareChange =
      this.form.controls['professionalHealthCare'].value !==
        this.formChange?.professionalHealthCare ||
      this.formChange?.professionalHealthCareComment;

    this.conciliationService.setValidator(
      this.form.controls['professionalHealthCareComment'],
      isProfessinatHealthCareChange
    );

    return isProfessinatHealthCareChange;
  }

  isHospitalAdmissionChange() {
    const isHospitalAdmissionChange =
      this.form.controls['hospitalAdmission'].value !==
        this.formChange?.hospitalAdmission ||
      this.formChange?.hospitalAdmissionComment;

    this.conciliationService.setValidator(
      this.form.controls['hospitalAdmissionComment'],
      isHospitalAdmissionChange
    );

    return isHospitalAdmissionChange;
  }

  //Verifica que valores han sido cambiados
  isRememberStartDateChange() {
    let rememberStartDateChange =
      this.form.controls['rememberStartDate']?.value;
    let rememberStartDateInit = this.formChange?.rememberStartDate;

    let isRememberStartDateChange =
      rememberStartDateChange !== rememberStartDateInit ||
      this.formChange?.startDateComment;

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['startDateComment'],
      isRememberStartDateChange || this.isStartDateChange()
    );

    return isRememberStartDateChange;
  }

  isStartDateChange() {
    const transformChangeDate = this.conciliationService.getFormatDate(
      this.form.controls['startDate']?.value
    );
    const transformInitDate = this.conciliationService.getFormatDate(
      this.formChange?.startDate
    );
    const changeDate = moment(transformChangeDate).format('DD-MM-YYYY');
    const originalDate = moment(transformInitDate).format('DD-MM-YYYY');

    //Verifica cuando hay un cambio
    const isChangeStartDate =
      changeDate !== originalDate || this.formChange?.startDateComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['startDateComment'],
      isChangeStartDate
    );

    return isChangeStartDate;
  }

  isRememberFinishDateChange() {
    let rememberFinishDateChange =
      this.form.controls['rememberFinishDate']?.value;

    let rememberFinishDateInit = this.formChange?.rememberFinishDate;

    let isRememberFinishDateChange =
      rememberFinishDateChange !== rememberFinishDateInit ||
      this.formChange?.finishDateComment;

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['finishDateComment'],
      isRememberFinishDateChange || this.isFinishDateChange()
    );

    return isRememberFinishDateChange;
  }

  isFinishDateChange() {
    const transformChangeDate = this.conciliationService.getFormatDate(
      this.form.controls['finishDate']?.value
    );
    const transformInitDate = this.conciliationService.getFormatDate(
      this.formChange?.finishDate
    );
    const changeDate = moment(transformChangeDate).format('DD-MM-YYYY');
    const originalDate = moment(transformInitDate).format('DD-MM-YYYY');

    //Verifica cuando hay un cambio
    const isChangeFinishDate =
      changeDate !== originalDate || this.formChange?.finishDateComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['finishDateComment'],
      isChangeFinishDate
    );
    return isChangeFinishDate;
  }

  previousOtherSymptoms() {
    let index = this.getCurrentOtherSymptomsIndex();
    if (index === 0) {
      let otherSymptomColDays = this.symptomService.otherSymptomColDays.length;
      if (otherSymptomColDays > 1) {
        this.currentOtherSymptom = {};
        return this.paginationService.dayInstanceSelected(
          this.symptomService.otherSymptomColDays[otherSymptomColDays - 1],
          ConciliationComponents.OTHER_SYMPTOM
        );
      }
      return this.paginationService.goToPreviousSection();
    }
    return this.paginationService.selectedOtherSymptom(
      this.symptomService.tableOtherSymptoms[index - 1]
    );
  }

  nextOtherSymptoms() {
    let index = this.getCurrentOtherSymptomsIndex();
    if (index + 1 === this.symptomService.tableOtherSymptoms.length) {
      return this.paginationService.nextSection(
        ConciliationComponents.OTHER_SYMPTOM
      );
    }

    return this.paginationService.selectedOtherSymptom(
      this.symptomService.tableOtherSymptoms[index + 1]
    );
  }

  getCurrentOtherSymptomsIndex() {
    return this.symptomService.tableOtherSymptoms.findIndex(
      (otherSymptom: any) => {
        return (
          otherSymptom?.verifiedOtherSymptom?.id ===
          this.currentOtherSymptom?.verifiedOtherSymptom?.id
        );
      }
    );
  }

  get isChangeForm() {
    return (
      this.conciliationService.isFormCommentChange(
        this.form.value,
        this.formChange
      ) ||
      this.conciliationService.isFormChange(this.form.value, this.formChange)
    );
  }

  shouldDisableEntity(configVariable: any) {
    if (
      !configVariable &&
      this.currentOtherSymptom?.otherSymptom?.id !==
        this.conciliationService.NEW_INSTANCE_ID
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldHideEntity(configVariable: any) {
    if (
      !configVariable &&
      this.currentOtherSymptom?.otherSymptom?.id ===
        this.conciliationService.NEW_INSTANCE_ID
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldDisableDateFields() {
    const otherSymptomsReconciliationConfig =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;
    return this.shouldDisableEntity(
      otherSymptomsReconciliationConfig?.allowOcurencyDateReconciliation
    );
  }

  shouldHideDateFields() {
    const otherSymptomsReconciliationConfig =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;
    return this.shouldHideEntity(
      otherSymptomsReconciliationConfig?.allowOcurencyDateReconciliation
    );
  }

  shouldDisableIntensity() {
    const otherSymptomsReconciliationConfig =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;

    return this.shouldDisableEntity(
      otherSymptomsReconciliationConfig?.allowIntensityReconciliation?.allow
    );
  }

  shouldHideIntensity() {
    const otherSymptomsReconciliationConfig =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;

    return this.shouldHideEntity(
      otherSymptomsReconciliationConfig?.allowIntensityReconciliation?.allow
    );
  }

  shouldDisableHospitalizationInfo() {
    const otherSymptomsReconciliationConfig =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;

    return this.shouldDisableEntity(
      otherSymptomsReconciliationConfig?.allowHospitalizationReconciliation
    );
  }

  shouldHideHospitalizationInfo() {
    const otherSymptomsReconciliationConfig =
      this.currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;

    return this.shouldHideEntity(
      otherSymptomsReconciliationConfig?.allowHospitalizationReconciliation
    );
  }
}
