<form name="form" #f="ngForm" autocomplete="off" (ngSubmit)="onSubmit(f)">
  <p-fieldset
    legend="{{
      'general.configurations.title'
        | translate
          : {
              title: this.title | translate | lowercase
            }
    }}"
  >
    <small>{{ 'general.configurations.help' | translate }}</small>
    <div class="p-grid p-fluid p-mt-3">
      <div
        class="p-col-12 p-grid p-field centerItem"
        *ngIf="showPrecondition || requiereLabel"
      >
        <div class="p-col-6 p-px-0">
          <div class="p-mb-2">
            <label for="label"
              >{{ 'general.configurations.labelShow' | translate }}
              <span class="inputRequired"> * </span></label
            >
          </div>
          <div>
            <div class="p-inputAutocompleteButton">
              <p-autoComplete
                class="p-flex-1"
                [ngStyle]="{ width: '100%' }"
                id="labelShow"
                name="labelShow"
                #labelShow="ngModel"
                type="text"
                [(ngModel)]="preconditionLabel"
                [suggestions]="results"
                (completeMethod)="search($event)"
                (onSelect)="selectKeyLanguage($event)"
                [required]="showPrecondition || requiereLabel"
                [disabled]="readMode"
              >
              </p-autoComplete>
              <button
                id="dictionaryButtonReport"
                name="dictionaryButtonReport"
                type="button"
                pButton
                pRipple
                icon="pi pi-book"
                (click)="onDictionaty(true)"
                class="
                  p-button-rounded
                  p-button-secondary
                  p-button-outlined
                  p-ml-2
                  p-ripple
                  p-button
                  p-component
                  p-button-icon-only
                "
                title="{{ 'dictionary.labelButton' | translate }}"
                [disabled]="readMode"
              ></button>
            </div>
            <div class="p-d-flex p-flex-column">
              <small *ngIf="isSpanish && labelShow.valid" [title]="isSpanish"
                ><label>ES:</label> {{ isSpanish | shortText: 40 }}</small
              >
              <small *ngIf="isEnglish && labelShow.valid" [title]="isEnglish"
                ><label>EN:</label> {{ isEnglish | shortText: 40 }}</small
              >
            </div>
            <small
              class="form-error"
              *ngIf="!labelShow.valid && (f.submitted || labelShow.touched)"
            >
              {{ 'general.required' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div
        class="p-col-12 p-md-6 p-grid p-field centerItem"
        *ngIf="requiredsymptomOcurrencies"
      >
        <div class="p-col-8 p-px-0">
          <label for="isBindToSymptomOcurrencies">{{
            'general.configurations.enums.symtomOcurriencies' | translate
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="isBindToSymptomOcurrencies"
            name="isBindToSymptomOcurrencies"
            [(ngModel)]="isBindToSymptomOcurrencies"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div
        class="p-col-12 p-grid p-field centerItem"
        *ngIf="requiredMedicationConfMode"
      >
        <div class="p-col p-px-0">
          <label for="label" class="p-mb-2 p-d-block">{{
            'general.configurations.labelMode' | translate
          }}</label>
          <p-dropdown
            appendTo="body"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [options]="medicationConfModes"
            optionLabel="name"
            optionValue="value"
            name="medicationConfModes"
            #medicationMode="ngModel"
            [(ngModel)]="medicationConfMode"
            emptyMessage="{{ 'general.noResultsFound' | translate }}"
            emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
            [required]="true"
            [disabled]="readMode"
          ></p-dropdown>
          <small
            class="form-error"
            *ngIf="
              !medicationMode.valid && (f.submitted || medicationMode.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
        <div></div>
      </div>
      <div
        class="p-col-12 p-md-6 p-grid p-field p-md-ml-2 centerItem"
        *ngIf="requiredDose"
      >
        <div class="p-col-8 p-px-0">
          <label for="showDose">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.dose'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col">
          <p-inputSwitch
            id="showDose"
            name="showDose"
            [(ngModel)]="showDose"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6 p-grid p-field"
        *ngIf="requiredRememberStartDate"
      >
        <div class="p-col-8 p-px-0">
          <label for="showRememberStartDate">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.startDate'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showRememberStartDate"
            name="showRememberStartDate"
            [(ngModel)]="showRememberStartDate"
            (onChange)="changeStartDate($event)"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6 p-grid p-field"
        *ngIf="requiredRememberFinishDate"
      >
        <div class="p-col-8 p-px-0">
          <label for="showRememberFinishDate">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.endDate'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showRememberFinishDate"
            name="showRememberFinishDate"
            [(ngModel)]="showRememberFinishDate"
            [disabled]="!showRememberStartDate || readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-grid p-field" *ngIf="requiredFrequency">
        <div class="p-col-8 p-px-0">
          <label for="showFrequency">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.frequency'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showFrequency"
            name="showFrequency"
            [(ngModel)]="showFrequency"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-grid p-field" *ngIf="requiredIntensity">
        <div class="p-col-8 p-px-0">
          <label for="showIntensity">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.intensity'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showIntensity"
            name="showIntensity"
            [(ngModel)]="showIntensity"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6 p-grid p-field centerItem"
        *ngIf="requiredDetail"
      >
        <div class="p-col">
          <label for="showDetail">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.multimediaDetail'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showDetail"
            name="showDetail"
            [(ngModel)]="showDetail"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div class="p-col-12 p-grid p-field" *ngIf="requiredHelp">
        <div class="p-col">
          <div class="p-field">
            <label for="help">{{
              'ediary.components.formAddEdit.labelHelp' | translate
            }}</label
            ><span class="inputRequired">* </span>
          </div>
          <div class="p-field">
            <app-simple-dictionary-editor
              [isRequired]="true"
              [submitted]="f.submitted"
              [value]="helpText"
              (outputEvent)="helpText = $event"
              [isReadOnly]="readMode"
            >
            </app-simple-dictionary-editor>
            <p-editor
              id="ediaryHelp"
              name="ediaryHelp"
              [hidden]="true"
              [required]="true"
              #ediaryHelp="ngModel"
              [(ngModel)]="helpText"
              [style]="{ height: '180px' }"
              [readonly]="readMode"
            ></p-editor>
          </div>
        </div>
      </div>

      <!----->
      <div class="p-col-12 p-md-6 p-grid p-field" *ngIf="requiredPrecondition">
        <div class="p-col-8 p-px-0">
          <label for="showReason">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.precondition'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div
          class="p-col"
          style="display: flex; align-items: center; align-content: center"
        >
          <p-inputSwitch
            id="showPrecondition"
            name="showPrecondition"
            [(ngModel)]="showPrecondition"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <!---->
      <div
        class="p-col-12 p-md-6 p-grid p-field"
        *ngIf="requireProfessionalHealthCare"
      >
        <div class="p-col-8 p-px-0">
          <label for="showProfessionalHealthCare">{{
            'general.configurations.professionalhealthcare' | translate
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showProfessionalHealthCare"
            name="showProfessionalHealthCare"
            [(ngModel)]="showProfessionalHealthCare"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <!---->
      <div
        class="p-col-12 p-md-6 p-grid p-field"
        *ngIf="requiredShowHospitalization"
      >
        <div class="p-col-8 p-px-0">
          <label for="showReason">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.hospitalization'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div
          class="p-col"
          style="display: flex; align-items: center; align-content: center"
        >
          <p-inputSwitch
            id="showHospitalization"
            name="showHospitalization"
            [(ngModel)]="showHospitalization"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <!---->
      <!--Configuration mode-->
      <div class="p-col-12 p-grid p-field" *ngIf="requiredConfigurationMode">
        <label for="label">{{
          'general.configurations.labelMode' | translate
        }}</label>
        <p-dropdown
          style="width: 100%"
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="medicalConfModes"
          optionLabel="name"
          optionValue="value"
          name="medicalConfModes"
          #medicalConfMode1="ngModel"
          [(ngModel)]="medicalConfMode"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [required]="true"
          [disabled]="readMode"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="
            !medicalConfMode1.valid && (f.submitted || medicalConfMode1.touched)
          "
        >
          {{ 'general.required' | translate }}
        </small>
      </div>

      <!---->
      <div
        class="p-col-12 p-md-6 p-grid p-field"
        *ngIf="requiredAdministrationRoute"
      >
        <div class="p-col-8 p-px-0">
          <label for="showAdministrationRoute">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.administrationRoute'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showAdministrationRoute"
            name="showAdministrationRoute"
            [(ngModel)]="showAdministrationRoute"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-grid p-field" *ngIf="requiredType">
        <div class="p-col-8 p-px-0">
          <label for="showType">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.type'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div class="p-col centerItem">
          <p-inputSwitch
            id="showType"
            name="showType"
            [(ngModel)]="showType"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="p-col-12 p-fieldset p-grid p-field" *ngIf="showType">
        <div class="p-col-12 p-md-6 p-grid p-field">
          <div class="p-col-8 p-px-0">
            <label for="showEmergencyType">{{
              'general.configurations.require'
                | translate
                  : {
                      entityName:
                        'medicalattentioninstance.enums.ER'
                        | translate
                        | lowercase
                    }
            }}</label>
          </div>
          <div class="p-col centerItem">
            <p-inputSwitch
              id="showEmergencyType"
              name="showEmergencyType"
              [(ngModel)]="showEmergencyType"
              (onChange)="changeTypeValid(showEmergencyType)"
              [disabled]="readMode"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="p-col-12 p-md-6 p-grid p-field">
          <div class="p-col-8 p-px-0">
            <label for="showMedicalAppointmentType">{{
              'general.configurations.require'
                | translate
                  : {
                      entityName:
                        'medicalattentioninstance.enums.MEDICAL_APPONTMENT'
                        | translate
                        | lowercase
                    }
            }}</label>
          </div>
          <div class="p-col centerItem">
            <p-inputSwitch
              id="showMedicalAppointmentType"
              name="showMedicalAppointmentType"
              [(ngModel)]="showMedicalAppointmentType"
              (onChange)="changeTypeValid(showMedicalAppointmentType)"
              [disabled]="readMode"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="p-col-12 p-md-6 p-grid p-field">
          <div class="p-col-8 p-px-0">
            <label for="showTelemedicineType">{{
              'general.configurations.require'
                | translate
                  : {
                      entityName:
                        'medicalattentioninstance.enums.TELEMEDICINE'
                        | translate
                        | lowercase
                    }
            }}</label>
          </div>
          <div class="p-col centerItem">
            <p-inputSwitch
              id="showTelemedicineType"
              name="showTelemedicineType"
              [(ngModel)]="showTelemedicineType"
              (onChange)="changeTypeValid(showTelemedicineType)"
              [disabled]="readMode"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="p-col-12 p-md-6 p-grid p-field">
          <div class="p-col-8 p-px-0">
            <label for="showHospitalizationType">{{
              'general.configurations.require'
                | translate
                  : {
                      entityName:
                        'medicalattentioninstance.enums.HOSPITALIZATION'
                        | translate
                        | lowercase
                    }
            }}</label>
          </div>
          <div class="p-col centerItem">
            <p-inputSwitch
              id="showHospitalizationType"
              name="showHospitalizationType"
              [(ngModel)]="showHospitalizationType"
              (onChange)="changeTypeValid(showHospitalizationType)"
              [disabled]="readMode"
            ></p-inputSwitch>
          </div>
        </div>
      </div>
      <small class="form-error" *ngIf="typeInvalid && showType">
        {{ 'general.required' | translate }}
      </small>
      <div
        class="p-col-12 p-md-6 p-grid p-field"
        *ngIf="requiredShowPrecondition"
      >
        <div class="p-col-8 p-px-0">
          <label for="showPrecondition">{{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.enums.precondition'
                      | translate
                      | lowercase
                  }
          }}</label>
        </div>
        <div
          class="p-col"
          style="display: flex; align-items: center; align-content: center"
        >
          <p-inputSwitch
            id="showPrecondition"
            name="showPrecondition"
            [(ngModel)]="showPrecondition"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
    </div>

    <div
      class="p-mt-3"
      style="display: flex; justify-content: end"
      *ngIf="!readMode"
    >
      <div class="p-grid p-field">
        <button
          id="btn_cancelConfiguration"
          name="btn_cancelConfiguration"
          class="p-button-secondary p-mr-3"
          pButton
          type="button"
          label="{{ 'general.cancel' | translate }}"
          (click)="onCancel()"
          [disabled]="readMode"
        ></button>

        <button
          id="btn_saveconfiguration"
          name="btn_saveconfiguration"
          pButton
          type="submit"
          label="{{ 'general.save' | translate }}"
          [disabled]="readMode"
        ></button>
      </div>
    </div>
  </p-fieldset>
</form>
