<form
  name="form"
  (ngSubmit)="onSubmit(f)"
  #f="ngForm"
  [class.completed]="usersCompleted(f)"
>
  <div class="p-field p-col-12" style="margin: 0; padding: 0">
    <div
      class="p-grid p-justify-between p-ai-center"
      *ngIf="!fromAddPendingPerson && !isEdition"
    >
      <div class="p-md-6 p-col-12">
        <p>
          {{
            (isSinglePerson
              ? 'informedConsent.followUp.addNewSubjectInfoSingular'
              : 'informedConsent.followUp.addNewSubjectInfo'
            ) | translate
          }}
        </p>
      </div>

      <div class="p-md-3 p-col-12">
        <p-button
          id="btn_add"
          class="newWindow"
          label="{{ 'informedConsent.followUp.addNewSubject' | translate }}"
          tooltipPosition="bottom"
          styleClass="p-button-outlined"
          [disabled]="!canAddSubject"
          (onClick)="addSubject()"
        ></p-button>
      </div>
    </div>
    <ng-container *ngIf="!fromAddPendingPerson">
      <div
        class="p-grid p-justify-left p-ai-center"
        *ngFor="let subject of subjects; let subjectsIndex = index"
      >
        <div
          class="p-grid"
          [ngClass]="isEdition ? 'p-col-12' : 'p-col-11'"
          style="margin: 0; padding: 0"
        >
          <div
            class="p-field p-col-12"
            [ngClass]="isEdition ? 'p-md-3' : 'p-md-4'"
          >
            <label for="id{{ subjectsIndex + 1 }}">
              {{
                'informedConsent.followUp.table.cols.subjectsIdentifier'
                  | translate
              }}
              {{ subjectsIndex + 1 }}
              <span class="inputRequired">* </span>
            </label>
            <div>
              <input
                class="input"
                id="id{{ subjectsIndex + 1 }}"
                [disabled]="isEdition"
                placeholder="{{
                  'informedConsent.followUp.table.cols.subjectPlaceHolder'
                    | translate
                }}"
                name="id{{ subjectsIndex + 1 }}"
                type="text"
                pInputText
                [(ngModel)]="subject.id"
                required
                #id="ngModel"
                (ngModelChange)="checkCanAddSubject(f)"
              />
            </div>
            <small
              class="form-error"
              *ngIf="
                !id.valid &&
                (f.submitted || id.touched) &&
                !isEdition &&
                !id.errors?.existent
              "
              >{{ 'general.required' | translate }}</small
            >
            <small
              class="form-error"
              *ngIf="
                !id.valid &&
                (f.submitted || id.touched) &&
                !isEdition &&
                id.errors?.existent
              "
              >{{
                'informedConsent.followUp.table.rowDetail.subjectExistsDetail'
                  | translate
              }}</small
            >
          </div>
          <div
            class="p-field p-col-12"
            [ngClass]="isEdition ? 'p-md-3' : 'p-md-4'"
          >
            <label for="name{{ subjectsIndex + 1 }}">
              {{
                'informedConsent.followUp.table.cols.subjectName' | translate
              }}
              {{ subjectsIndex + 1 }}
              <span class="inputRequired">* </span>
            </label>
            <div>
              <input
                class="input"
                id="name{{ subjectsIndex + 1 }}"
                placeholder="{{
                  'informedConsent.followUp.table.cols.subjectNamePlaceHolder'
                    | translate
                }}"
                name="name{{ subjectsIndex + 1 }}"
                type="text"
                pInputText
                [(ngModel)]="subject.name"
                required
                #name="ngModel"
                (ngModelChange)="onChangeSubjectName()"
              />
            </div>
            <small
              class="form-error"
              *ngIf="!name.valid && (f.submitted || name.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <div
            class="p-field p-col-12"
            [ngClass]="isEdition ? 'p-md-3' : 'p-md-4'"
          >
            <label for="name{{ subjectsIndex + 1 }}">
              {{
                'informedConsent.followUp.table.cols.subjectBithDate'
                  | translate
              }}
              {{ subjectsIndex + 1 }}
              <span class="inputRequired">* </span>
            </label>
            <div>
              <p-calendar
                name="birthDate{{ subjectsIndex + 1 }}"
                [(ngModel)]="subject.birthDate"
                [required]="true"
                appendTo="body"
                #birthDate="ngModel"
                [showIcon]="true"
                [showOnFocus]="false"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                placeholder="{{
                  'addEditSubjectComponent.PlaceHolderFormatDate' | translate
                }}"
                dateFormat="dd-M-yy"
                [maxDate]="maxDate"
                inputId="birthDate{{ subjectsIndex + 1 }}"
                (onSelect)="checkCanAddSubject()"
                (onInput)="checkCanAddSubject()"
              >
              </p-calendar>
            </div>
            <small
              class="form-error"
              *ngIf="!name.valid && (f.submitted || name.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <div
            class="p-field p-col-12"
            [ngClass]="isEdition ? 'p-md-3' : 'p-md-4'"
            *ngIf="isEdition"
          >
            <label for="relationship{{ subjectsIndex + 1 }}">
              {{
                'informedConsent.followUp.table.cols.relationship' | translate
              }}
              <span class="inputRequired">* </span>
            </label>
            <p-dropdown
              id="relationship{{ subjectsIndex + 1 }}"
              name="relationship{{ subjectsIndex + 1 }}"
              [options]="relationshipTypes[subjectsIndex]"
              [(ngModel)]="user.relationship[subjectsIndex]"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [showClear]="true"
              [required]="true"
              appendTo="body"
              #relationship="ngModel"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="
                relationship?.invalid && (f.submitted || relationship.touched)
              "
            >
              {{ 'informedConsent.formUser.errors.relationship' | translate }}
            </small>
          </div>
        </div>

        <div class="p-grid p-col-1 p-justify-end" *ngIf="!isEdition">
          <div>
            <br />
            <button
              id="btn_delete"
              pButton
              type="button"
              icon="pi pi-trash"
              class="btn-icon btn-delete"
              [disabled]="subjects.length === 1 || isEdition"
              (click)="deleteSubject(subjectsIndex)"
            ></button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="p-grid p-jc-between" *ngIf="fromAddPendingPerson">
    <div class="p-d-flex p-col p-jc-between p-ai-center p-col-12 p-md-4">
      <p id="lbl_existingUser" name="lbl_existingUser" class="">
        {{ 'informedConsent.followUp.addExistingPerson' | translate }}
      </p>
      <p-inputSwitch
        id="existingUser"
        name="existingUser"
        class="inputSwitch"
        [(ngModel)]="existingUser"
        (onChange)="onChangeExistingUser(f)"
      ></p-inputSwitch>
    </div>
    <div class="p-md-6 p-field" *ngIf="existingUser">
      <label for="dropdownUser">
        {{ 'informedConsent.followUp.labelSearchAutocomplete' | translate }}
        <span class="inputRequired" *ngIf="existingUser"> *</span>
      </label>
      <p-dropdown
        [disabled]="false"
        [filter]="true"
        [required]="true"
        appendTo="body"
        placeholder="{{ 'subject.PlaceHolderSearchAutocomplete' | translate }}"
        id="dropdownUser"
        [options]="availableUsers"
        optionLabel="login"
        optionValue="id"
        name="dropdownUser"
        #dropdownUser="ngModel"
        [(ngModel)]="selectedUser"
        emptyFilterMessage="{{ 'project.emptyMessageFindSubject' | translate }}"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="
          selectedUser === '' &&
          (f.submitted || dropdownUser.touched) &&
          existingUser
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-field p-col-12 p-md-6" *ngIf="existingUser">
      <label for="relationship">
        {{ 'informedConsent.followUp.table.cols.relationship' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-dropdown
        id="relationship"
        name="relationship"
        [options]="relationshipTypes"
        [(ngModel)]="user.relationship"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        [showClear]="true"
        [required]="true"
        appendTo="body"
        #relationship="ngModel"
        (onChange)="updateRelationships()"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="relationship?.invalid && (f.submitted || relationship.touched)"
      >
        {{ 'informedConsent.formUser.errors.relationship' | translate }}
      </small>
    </div>
  </div>

  <div class="p-field p-col-12 p-lg">
    <p style="margin-bottom: 0.5rem" *ngIf="!fromAddPendingPerson">
      {{
        (isSinglePerson
          ? 'informedConsent.followUp.table.cols.subjectInstructionsSingular'
          : 'informedConsent.followUp.table.cols.subjectInstructions'
        ) | translate
      }}
    </p>
    <div class="subjectSigns" *ngIf="!fromAddPendingPerson || !existingUser">
      <div class="ul">
        <div
          class="subjectTab"
          *ngFor="let completed of subjectSigns; let i = index"
          [ngStyle]="{
            'border-color': completed
              ? '#18DF38'
              : i === userIndex
              ? '#0B85AD'
              : '#878787',
            background: i === userIndex ? '#E8FCFF' : '#FFF',
            cursor: users[i] && f.valid ? 'pointer' : 'auto'
          }"
          (click)="selectUser(i, f)"
        >
          <div class="left-content-tab">
            <p class="left-text-tab">
              {{ 'informedConsent.followUp.person' | translate }} {{ i + 1 }}
            </p>
            <p class="left-text-tab">
              {{
                completed
                  ? ('informedConsent.followUp.table.cols.infoCompleted'
                    | translate)
                  : ('informedConsent.followUp.table.cols.infoIncomplete'
                    | translate)
              }}
            </p>
          </div>

          <em
            *ngIf="!completed"
            class="pi pi-exclamation-triangle image-tab"
          ></em>
          <em *ngIf="completed" class="pi pi-check-circle image-tab"></em>
          <span
            *ngIf="
              this.subjectSigns.length > this.requiredSignatures &&
              i >= this.requiredSignatures &&
              !isEdition &&
              !fromAddPendingPerson
            "
            (click)="removeAdditionalPerson(i); $event.stopPropagation()"
            class="close-icon-delete pi pi-times"
          ></span>
        </div>
        <em
          *ngIf="
            subjectSigns.length < maxRequiredSignatures &&
            !isEdition &&
            !fromAddPendingPerson &&
            !isSinglePerson
          "
          (click)="addAdditionalPerson()"
          class="pi pi-plus image-tab"
        ></em>
      </div>
      <div class="subjectInfo">
        <div class="p-fluid p-grid">
          <div class="p-col-12" style="margin-top: 0rem">
            <p style="margin-top: 0rem">
              <strong style="color: #007b97">
                {{ 'informedConsent.followUp.person' | translate }}
                {{ userIndex + 1 }}.
              </strong>
              {{
                (isSinglePerson
                  ? 'informedConsent.followUp.personInfoSingular'
                  : 'informedConsent.followUp.personInfo'
                ) | translate
              }}
            </p>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="username">
              {{ 'user.labelUser' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <input
              class="input"
              id="username"
              placeholder="{{ 'user.placeholderUser' | translate }}"
              name="username"
              type="text"
              pInputText
              [(ngModel)]="user.login"
              [pattern]="userPattern"
              required
              #username="ngModel"
              [disabled]="isEdition"
            />
            <small
              class="form-error"
              *ngIf="
                !username.valid &&
                (f.submitted || username.touched) &&
                !username.errors?.existent &&
                !isEdition
              "
              >{{ 'informedConsent.formUser.errors.user' | translate }}</small
            >
            <small
              class="form-error"
              *ngIf="
                !username.valid &&
                (f.submitted || username.touched) &&
                username.errors?.existent
              "
              >{{
                'informedConsent.followUp.table.rowDetail.usernameExistsDetail'
                  | translate
              }}</small
            >
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="email">
              {{ 'user.labelEmail' | translate }}
              <span
                class="inputRequired"
                *ngIf="
                  user.requiresSendingEmail ||
                  user.notificationPreference === 'EMAIL'
                "
                >*
              </span>
            </label>
            <input
              class="input"
              id="email"
              placeholder="{{ 'user.placeholderEmail' | translate }}"
              name="email"
              type="email"
              pInputText
              [(ngModel)]="user.email"
              #email="ngModel"
              [pattern]="emailPattern"
              [required]="
                user.requiresSendingEmail ||
                user.notificationPreference === 'EMAIL'
              "
            />
            <small
              class="form-error"
              *ngIf="
                !email.valid &&
                (f.submitted || email.touched) &&
                email.errors?.existent
              "
              >{{
                'informedConsent.followUp.table.rowDetail.emailExistsDetail'
                  | translate
              }}</small
            >
            <small
              class="form-error"
              *ngIf="
                !email.valid &&
                (f.submitted || email.touched) &&
                !email.errors?.pattern &&
                !email.errors?.existent
              "
              >{{ 'general.required' | translate }}</small
            >
            <br
              *ngIf="
                email.errors &&
                email.errors.pattern &&
                (f.submitted || email.touched)
              "
            />
            <small
              class="form-error"
              *ngIf="
                email.errors &&
                email.errors.pattern &&
                (f.submitted || email.touched)
              "
            >
              {{ 'informedConsent.formUser.errors.email' | translate }}
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="name">
              {{ 'informedConsent.followUp.table.cols.fullName' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <input
              class="input"
              placeholder="{{ 'user.placeholderName' | translate }}"
              id="name"
              name="name"
              type="text"
              pInputText
              [(ngModel)]="user.name"
              required
              #name="ngModel"
            />
            <small
              class="form-error"
              *ngIf="!name.valid && (f.submitted || name.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>

          <div class="p-field p-col-12 p-md-6" *ngIf="!isEdition">
            <label for="relationship">
              {{
                'informedConsent.followUp.table.cols.relationship' | translate
              }}
              <span class="inputRequired">* </span>
            </label>
            <p-dropdown
              id="relationship"
              name="relationship"
              [options]="relationshipTypes"
              [(ngModel)]="user.relationship"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [showClear]="true"
              [required]="true"
              appendTo="body"
              #relationship="ngModel"
              (onChange)="updateRelationships()"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="
                relationship?.invalid && (f.submitted || relationship.touched)
              "
            >
              {{ 'informedConsent.formUser.errors.relationship' | translate }}
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="phoneN">
              {{ 'informedConsent.formUser.labelPhoneNumber' | translate }}
              <span
                class="inputRequired"
                *ngIf="
                  user.notificationPreference === 'SMS' ||
                  currentProject.isMFAActivated
                "
                >*
              </span>
            </label>
            <input
              class="input"
              id="phoneN"
              name="phoneN"
              placeholder="+### ########"
              type="text"
              pInputText
              tooltipPosition="top"
              [required]="
                user.notificationPreference === 'SMS' ||
                (currentProject.isMFAActivated ?? false)
              "
              pTooltip="{{ 'user.tooltipInpuPhoneNumber' | translate }}"
              tooltipPosition="top"
              [(ngModel)]="user.phoneNumber"
              #phone="ngModel"
            />
            <small
              class="form-error"
              *ngIf="
                user.phoneNumber &&
                !trialpalService.phoneNumberService.isValidPhoneNumber(
                  user.phoneNumber
                ).isValid
              "
            >
              {{
                trialpalService.phoneNumberService.isValidPhoneNumber(
                  user.phoneNumber
                ).message
              }}</small
            ><br />
            <small
              class="form-error"
              *ngIf="!phone.valid && (f.submitted || phone.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label
              for="notificationPreference"
              class="notification"
              [pTooltip]="'user.selectNotificationPreference' | translate"
              tooltipPosition="left"
            >
              {{ 'user.selectNotificationPreference' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <p-dropdown
              #notificationPreference="ngModel"
              [showClear]="true"
              id="notificationPreference"
              class="input"
              placeholder="{{ 'general.choose' | translate }}"
              name="notificationPreference"
              [options]="alertTypes"
              optionLabel="name"
              optionValue="value"
              [(ngModel)]="user.notificationPreference"
              [required]="true"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="
                notificationPreference?.invalid &&
                (f.submitted || notificationPreference.touched)
              "
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <div
            class="p-col-12 p-md-6 p-d-flex p-justify-between p-ai-center"
            style="padding-left: 0.5rem"
          >
            <label
              for="requiresSendingEmail"
              id="lbl_requiresSendingEmail"
              class="p-mr-3"
              pTooltip="{{
                'informedConsent.followUp.table.cols.requiresSendingEmailTooltip'
                  | translate
              }}"
            >
              {{
                'informedConsent.followUp.form.requiresSendingEmail' | translate
              }}
              <span class="inputRequired">* </span>
            </label>
            <p-selectButton
              class="input"
              id="cmb_requiresSendingEmail"
              name="requiresSendingEmail"
              [options]="booleanOptions"
              [(ngModel)]="user.requiresSendingEmail"
              optionLabel="label"
              optionValue="value"
              required
            ></p-selectButton>
          </div>
          <div
            class="p-col-12 p-md-6 nextButtondiv"
            *ngIf="!fromAddPendingPerson && userIndex + 1 < subjectSigns.length"
          >
            <div class="p-col-12 p-md-6" style="padding-left: 0">
              <p-button
                id="btn_add"
                class="newWindow"
                label="{{ 'informedConsent.followUp.nextPerson' | translate }}"
                tooltipPosition="bottom"
                styleClass="p-button-outlined"
                [disabled]="!f.valid"
                (onClick)="goToNextPerson(f)"
              ></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-9 p-sm-offset-4">
    <p-button
      id="btnSend"
      class="inline-block saveWindow"
      [disabled]="
        !f.valid || (!usersCompleted(f) && !fromAddPendingPerson && !isEdition)
      "
      styleClass="p-button-outlined"
      icon="pi pi-check"
      label="{{ 'informedConsent.followUp.saveInfo' | translate }}"
      type="submit"
    ></p-button>
  </div>
</form>
