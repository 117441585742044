<main>
  <h2 class="p-mt-0 p-mb-3">
    {{ 'report.execution.titleSummary' | translate }}
  </h2>
  <section class="section p-mb-3">
    <!--Resumen de envio para sintomas-->
    <article class="p-mb-5" *ngIf="confReport.areSymptomsRequired">
      <h3
        class="p-mt-0 p-mb-3 pointer border-bottom"
        (click)="goToPage(SYMPTOM_PAGE)"
      >
        {{ 'subject.tabheaderSymptom' | translate }}
      </h3>
      <ul>
        <li class="flex-col" *ngFor="let symptomInstance of symptomInstances">
          <h4 class="p-my-0">
            {{ symptomInstance.label }}:
            {{
              (symptomInstance.occurrency ? 'general.yes' : 'general.no')
                | translate
            }}
          </h4>
          <div class="flex-col" *ngIf="symptomInstance.occurrency">
            <p
              class="flex-row"
              *ngIf="
                (symptomInstance.intensityType === NUMERIC_VALUE ||
                  symptomInstance.intensityType === RANGE) &&
                !symptomInstance.symptomInstance.rememberNoValuesTaken
              "
            >
              <span class="label">
                {{
                  symptomInstance.confSymptom.intensitySizeLabel ?? ''
                    | dictionary
                }}:
              </span>

              <span>{{ symptomInstance.symptomInstance.size }}</span>
            </p>

            <!--Intensity field-->

            <p
              class="flex-row"
              *ngIf="
                symptomInstance.intensityType === MULTIPLE_CHOICE &&
                !symptomInstance.symptomInstance.rememberNoValuesTaken
              "
            >
              <span class="label">
                {{ 'symptominstance.intensity' | translate }}:</span
              >
              <span>{{
                'symptominstance.enums.' +
                  symptomInstance.symptomInstance.intensity | translate
              }}</span>
            </p>

            <p
              class="flex-row"
              *ngIf="symptomInstance.confSymptom?.showNoValuesTaken"
            >
              <span class="label"
                >{{
                  symptomInstance.confSymptom?.noValuesTakenLabel ?? ''
                    | dictionary
                }}:
              </span>
              <span>
                {{
                  (symptomInstance.symptomInstance.rememberNoValuesTaken
                    ? 'general.yes'
                    : 'general.no'
                  ) | translate
                }}
              </span>
            </p>

            <p
              *ngIf="symptomInstance.confSymptom?.isIntensityQuestionsRequired"
            >
              <span
                class="flex-row"
                *ngFor="
                  let intensityQuestionAnswerInput of symptomInstance
                    .symptomInstance.intensityQuestionAnswers
                "
              >
                <span class="label"
                  >{{
                    intensityQuestionAnswerInput.question?.question ?? ''
                      | dictionary
                  }}:
                </span>
                <span>{{
                  (intensityQuestionAnswerInput.answer
                    ? 'general.yes'
                    : 'general.no'
                  ) | translate
                }}</span>
              </span>
            </p>

            <div *ngIf="symptomInstance.confSymptom?.isStartDateRequired">
              <p class="flex-row">
                <span class="label">{{
                  'symptominstance.startDateQuestion' | translate
                }}</span>
                <span>
                  {{
                    (symptomInstance.symptomInstance.rememberStartDate
                      ? 'general.yes'
                      : 'general.no'
                    ) | translate
                  }}
                </span>
              </p>

              <p
                class="flex-row"
                *ngIf="symptomInstance.symptomInstance.rememberStartDate"
              >
                <span class="label"
                  >{{ 'symptominstance.startDate' | translate }}:</span
                >
                <span>{{
                  symptomInstance.symptomInstance.startDate?.substring(0, 10)
                    | date: 'dd-MMM-yyyy'
                }}</span>
              </p>
            </div>

            <div *ngIf="symptomInstance.confSymptom?.isFinishDateRequired">
              <p class="flex-row">
                <span class="label">
                  {{ 'symptominstance.endDateQuestion' | translate }}:</span
                >
                <span>
                  {{
                    (symptomInstance.symptomInstance.rememberFinishDate
                      ? 'general.yes'
                      : 'general.no'
                    ) | translate
                  }}</span
                >
              </p>

              <p
                class="flex-row"
                *ngIf="symptomInstance.symptomInstance.rememberFinishDate"
              >
                <span class="label"
                  >{{ 'symptominstance.endDate' | translate }}:</span
                >
                <span>{{
                  symptomInstance.symptomInstance.finishDate?.substring(0, 10)
                    | date: 'dd-MMM-yyyy'
                }}</span>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </article>

    <!--Resumen de envio para formularios-->
    <article *ngIf="showFormSummary">
      <h3
        class="p-mt-0 p-mb-3 pointer border-bottom"
        (click)="goToPage(FORM_PAGE)"
      >
        {{ 'report.labelJsonForm' | translate }}
      </h3>

      <p class="flex-row" *ngIf="showReportScore">
        <span class="label"> {{ reportScoreLabel }}:</span>
        <span>{{ score }}</span>
      </p>

      <p>
        {{ 'report.execution.formSummary' | translate }}
      </p>
    </article>
  </section>

  <app-pagination-button
    (previous)="goToPreviousPage()"
    (next)="completeReport()"
    [nextDisabled]="false"
    [previousDisabled]="false"
  ></app-pagination-button>
</main>
