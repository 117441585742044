import { EventEmitter, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { API, Analytics, Auth, Logger, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import {
  ConfICAnswer,
  ConfICQuestion,
  ConfICSection,
  ConfInformedConsent,
  ConsentedUser,
  ConsentedUserByUserIdQuery,
  CreateConfICAnswerInput,
  CreateConfICAnswerMutation,
  CreateConfICQuestionInput,
  CreateConfICQuestionMutation,
  CreateConfICSectionInput,
  CreateConfICSectionMutation,
  CreateConfInformedConsentInput,
  CreateConfInformedConsentMutation,
  CreateConsentedUserInput,
  CreateConsentedUserMutation,
  CreateICQuestionInstanceInput,
  CreateICQuestionInstanceMutation,
  CreateICSectionInstanceInput,
  CreateICSectionInstanceMutation,
  CreateInformedConsentInstanceInput,
  CreateInformedConsentInstanceMutation,
  CreateSignatureInstanceInput,
  GetConfICAnswerQuery,
  GetConfICQuestionQuery,
  GetConfICSectionQuery,
  GetConfInformedConsentQuery,
  GetInformedConsentInstanceQuery,
  ICQuestionInstance,
  ICSectionInstance,
  InformedConsentByConsentedIdentificatorQuery,
  InformedConsentInstance,
  InformedConsentInstanceByICConfigurationCustomQuery,
  InformedConsentSectionType,
  InformedConsentState,
  ModelConfICSectionFilterInput,
  ModelInformedConsentInstanceFilterInput,
  StateChangeInput,
  UpdateConfICAnswerInput,
  UpdateConfICAnswerMutation,
  UpdateConfICQuestionInput,
  UpdateConfICQuestionMutation,
  UpdateConfICSectionInput,
  UpdateConfInformedConsentInput,
  UpdateConfInformedConsentMutation,
  UpdateConsentedUserInput,
  UpdateICQuestionInstanceInput,
  UpdateICSectionInstanceInput,
  tp2SendProjectAdminNotificationInput,
} from 'src/app/services/API.service';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  ConfState,
  InstanceState,
  SignatureEntityType,
  SignatureReason,
  SignatureType,
} from 'src/app/services/trialpal.types';
import {
  INTEGRATIONS_API,
  InformedConsent,
  emailRegex,
  phoneRegex,
  userRegex,
} from 'src/app/shared/global.variables';
import { RequestParamsBuilder } from 'src/app/shared/utils/requestParmasBuilder';
import { USERS_QUERIES } from '../user/user.queries';
import { UserService } from '../user/user.service';
import {
  TP2UserInput,
  UpdateUserInput,
  User,
  UserState,
} from '../user/user.types';
import {
  NotificationPreferences,
  Relationship,
} from './informed-consent-follow-up/informed-consent-follow-up-user-form/informed-consent-follow-up-user-form.component';
import { ExcelFileUserInput } from './informed-consent-follow-up/informed-consent-follow-up-users-table/informed-consent-follow-up-users-table';
import { INFORMED_CONSENT_QUERIES } from './informed-consent.queries';
import { UpdateInformedConsentInstanceInput } from './informed-consent.types';
import { Project } from '../project/project.types';

const logger = new Logger('tp2-logger-informedConsentService');

@Injectable({
  providedIn: 'root',
})
export class InformedConsentService {
  clearFilterEvent = new EventEmitter<void>();
  confInformedConsentsByProject: any = [];
  transformDatePipe: TransformDatePipe;
  PHONE_STATE_US = [
    205, 251, 256, 334, 659, 938, 907, 480, 520, 602, 623, 928, 479, 501, 870,
    209, 213, 279, 310, 323, 341, 350, 408, 415, 424, 442, 510, 530, 559, 562,
    619, 626, 628, 650, 657, 661, 669, 707, 714, 747, 760, 805, 818, 820, 831,
    840, 858, 909, 916, 925, 949, 951, 303, 719, 720, 970, 983, 203, 475, 860,
    959, 302, 239, 305, 321, 352, 386, 407, 448, 561, 656, 689, 727, 754, 772,
    786, 813, 850, 863, 904, 941, 954, 229, 404, 470, 478, 678, 706, 762, 770,
    912, 943, 808, 208, 986, 217, 224, 309, 312, 331, 447, 464, 618, 630, 708,
    773, 779, 815, 847, 872, 219, 260, 317, 463, 574, 765, 812, 930, 319, 515,
    563, 641, 712, 316, 620, 785, 913, 270, 364, 502, 606, 859, 225, 318, 337,
    504, 985, 207, 240, 301, 410, 443, 667, 339, 351, 413, 508, 617, 774, 781,
    857, 978, 231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989, 218,
    320, 507, 612, 651, 763, 952, 228, 601, 662, 769, 314, 417, 557, 573, 636,
    660, 816, 406, 308, 402, 531, 702, 725, 775, 603, 201, 551, 609, 640, 732,
    848, 856, 862, 908, 973, 505, 575, 212, 315, 332, 347, 363, 516, 518, 585,
    607, 631, 646, 680, 716, 718, 838, 845, 914, 917, 929, 934, 252, 336, 472,
    704, 743, 828, 910, 919, 980, 984, 701, 216, 220, 234, 326, 330, 380, 419,
    440, 513, 567, 614, 740, 937, 405, 539, 572, 580, 918, 458, 503, 541, 971,
    215, 223, 267, 272, 412, 445, 484, 570, 582, 610, 717, 724, 814, 835, 878,
    401, 803, 839, 843, 854, 864, 605, 423, 615, 629, 731, 865, 901, 931, 210,
    214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512, 682, 713, 726, 737,
    806, 817, 830, 832, 903, 915, 936, 940, 945, 956, 972, 979, 385, 435, 801,
    802, 276, 434, 540, 571, 703, 757, 804, 826, 948, 206, 253, 360, 425, 509,
    564, 202, 771, 304, 681, 262, 414, 534, 608, 715, 920, 307,
  ];
  protected token = '';
  private paramsBuilder: RequestParamsBuilder = new RequestParamsBuilder('');

  constructor(
    public readonly auth: AuthService,
    public readonly storage: StorageService,
    public readonly sanitizer: DomSanitizer,
    public readonly trialpalService: TrialpalService,
    public readonly userService: UserService,
    transformDatePipe: TransformDatePipe
  ) {
    this.transformDatePipe = transformDatePipe;
    Auth.currentSession()
      .then((response) => {
        this.token = response.getAccessToken().getJwtToken();
        this.paramsBuilder.updateToken(this.token);
      })
      .catch((error) => {
        logger.error('Get access token error:', error);
      });
  }

  async createEConsentPDF(informedConsentInstanceId: string) {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.Tp2CreateEConsentPDF,
      { informedConsentInstanceId }
    );
    return response.data.tp2CreateEConsentPDF;
  }

  //Funciones get
  async getConfInformedConsentByProjectId(
    projectId: string,
    withDeletions: boolean = false
  ): Promise<ConfInformedConsent[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConfInformedConsentByProjectIDCustom,
      { projectId }
    );
    let { items } = response.data.confInformedConsentByProjectID;

    if (!withDeletions) {
      items = items.filter(
        (confInformedConsent: any) =>
          !confInformedConsent?._deleted &&
          confInformedConsent.state !== ConfState.DELETED
      );
    }

    items.sort((a: any, b: any) =>
      a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : 0
    );

    return items as ConfInformedConsent[];
  }

  async getConfInformedConsentById(
    id: string
  ): Promise<GetConfInformedConsentQuery> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.GetConfInformedConsent,
      { id }
    );

    return response.data.getConfInformedConsent;
  }

  async getInformedConsentById(
    id: string
  ): Promise<GetInformedConsentInstanceQuery> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.GetInformedConsentInstance,
      { id }
    );

    return response.data.getInformedConsentInstance;
  }

  async getConsentedUserByInformedConsenteInstanceId(
    informedConsentInstanceId: string
  ): Promise<ConsentedUser[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConsentedUserByInformedConsentInstanceIdCustom,
      { informedConsentInstanceId }
    );
    const { items } = response.data.ConsentedUserByInformedConsentInstanceId;
    const consentedUsers = items
      .filter(Boolean)
      .filter(
        (consentedUser: any) =>
          !consentedUser?._deleted &&
          consentedUser?.state !== InformedConsentState.DELETED
      ) as ConsentedUser[];
    return consentedUsers;
  }
  async getICQuestionInstanceByInformedConsentInstanceId(
    informedConsentInstanceId: string,
    custom: boolean = false
  ): Promise<ICQuestionInstance[]> {
    const customResponse = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ICQuestionInstanceByInformedConsentInstanceIdCustom,
      { informedConsentInstanceId, limit: 1000 }
    );
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ICQuestionInstanceByInformedConsentInstanceId,
      { informedConsentInstanceId, limit: 1000 }
    );
    const { items } = custom
      ? customResponse.data.ICQuestionInstanceByInformedConsentInstanceId
      : response.data.ICQuestionInstanceByInformedConsentInstanceId;
    const ICQuestionInstances = items
      .filter(Boolean)
      .filter(
        (ICQuestion: any) =>
          !ICQuestion?._deleted && ICQuestion?.state !== InstanceState.DELETED
      ) as any[];
    return ICQuestionInstances;
  }
  async getICSectionInstanceByInformedConsentInstanceId(
    informedConsentInstanceId: string,
    custom: boolean = false
  ): Promise<ICSectionInstance[]> {
    const customResponse = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ICSectionInstanceByInformedConsentInstanceIdCustom,
      { informedConsentInstanceId, limit: 1000 }
    );
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ICSectionInstanceByInformedConsentInstanceId,
      { informedConsentInstanceId, limit: 1000 }
    );
    const { items } = custom
      ? customResponse.data.ICSectionInstanceByInformedConsentInstanceId
      : response.data.ICSectionInstanceByInformedConsentInstanceId;
    const ICSectionInstances = items
      .filter(Boolean)
      .filter(
        (ICSection: any) =>
          !ICSection?._deleted && ICSection?.state !== InstanceState.DELETED
      ) as any[];
    return ICSectionInstances;
  }

  async getICQuestionInstanceByConsentedUserId(
    consentedUserId: string
  ): Promise<ICQuestionInstance[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ICQuestionInstanceByConsentedUserId,
      { consentedUserId, limit: 1000 }
    );
    const { items } = response.data.ICQuestionInstanceByConsentedUserId;
    const ICQuestionInstances = items
      .filter(Boolean)
      .filter(
        (ICQuestion: any) =>
          !ICQuestion?._deleted && ICQuestion?.state !== InstanceState.DELETED
      ) as any[];
    return ICQuestionInstances;
  }
  async getICSectionInstanceByConsentedUserId(
    consentedUserId: string
  ): Promise<ICSectionInstance[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ICSectionInstanceByConsentedUserId,
      { consentedUserId, limit: 1000 }
    );
    const { items } = response.data.ICSectionInstanceByConsentedUserId;
    const ICSectionInstances = items
      .filter(Boolean)
      .filter(
        (ICSection: any) =>
          !ICSection?._deleted && ICSection?.state !== InstanceState.DELETED
      ) as any[];
    return ICSectionInstances;
  }

  async getInformedConsentInstanceByProjectId(
    projectId: string
  ): Promise<InformedConsentInstance[]> {
    let instances: Exclude<
      InformedConsentInstanceByICConfigurationCustomQuery,
      '__typename'
    >[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        INFORMED_CONSENT_QUERIES.ConfInformedConsentByProject,
        { projectId, limit: 1000, nextToken }
      );
      instances = instances.concat(
        listQuery.data.confInformedConsentByProject.items
      );
      nextToken = listQuery.data.confInformedConsentByProject.nextToken;
    } while (nextToken);
    return instances.filter(
      (ICI: any) => !ICI?._deleted && ICI.state !== InstanceState.DELETED
    ) as any[];
  }
  //Funciones de create

  async createInformedConsent(
    data: any
  ): Promise<CreateConfInformedConsentMutation> {
    let input = {} as CreateConfInformedConsentInput;
    Object.assign(input, data);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateConfInformedConsent,
      { input }
    );

    return response.data.createConfInformedConsent;
  }

  async updateInformedConsent(
    data: any,
    expectedVersion: any
  ): Promise<UpdateConfInformedConsentMutation> {
    let id = data.id;
    let updateInput: UpdateConfInformedConsentInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateConfInformedConsent,
      { input: updateInput }
    );

    return response.data.updateConfInformedConsent;
  }

  // SECTIONS

  async getSignatureEconsentConfICSection(
    id: string
  ): Promise<ConfICSection[]> {
    const filter: ModelConfICSectionFilterInput = {
      type: {
        eq: InformedConsentSectionType.CONSENTER_SIGNATURE,
      },
    };

    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConfICSectionByConfInformedConsentId,
      { filter, confInformedConsentId: id }
    );
    let { items }: any = response.data.ConfICSectionByConfInformedConsentId;
    return items.filter(
      (confICSection: ConfICSection) =>
        !confICSection?._deleted && confICSection.state !== ConfState.DELETED
    ) as ConfICSection[];
  }

  async getConfICSectionsByConfInformedConsentId(
    id: string,
    withDeletions: boolean = false
  ): Promise<ConfICSection[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConfICSectionByConfInformedConsentId,
      { confInformedConsentId: id }
    );
    let { items }: any = response.data.ConfICSectionByConfInformedConsentId;
    if (withDeletions) {
      return items;
    }
    return items.filter(
      (confICSection: ConfICSection) =>
        !confICSection?._deleted && confICSection.state !== ConfState.DELETED
    ) as ConfICSection[];
  }

  async getConfICSectionById(id: string): Promise<GetConfICSectionQuery> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.GetConfICSection,
      { id }
    );

    return response.data.getConfICSection;
  }

  async createConfICSection(data: any): Promise<CreateConfICSectionMutation> {
    let input = {} as CreateConfICSectionInput;
    Object.assign(input, data);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateConfICSection,
      { input }
    );

    return response.data.createConfICSection;
  }

  async updateConfICSection(
    data: any,
    expectedVersion: any
  ): Promise<UpdateConfICSectionInput> {
    let id = data.id;
    let updateInput: UpdateConfICSectionInput = {
      id,
      _version: expectedVersion,
    };
    delete data.confInformedConsent;
    Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateConfICSection,
      { input: updateInput }
    );

    return response.data.updateConfICSection;
  }

  //Borra en cascadas las instancias asociadas al consentimiento informado
  async deleteInstancesAssociatedWithConfInformedConsentId(
    confInformedConsentId: string,
    changeReason: string
  ): Promise<void> {
    const confICSections: ConfICSection[] =
      await this.getConfICSectionsByConfInformedConsentId(
        confInformedConsentId
      );

    const promises: any[] = [];

    //Elimina las instancias asociadas a las secciones (Pasa a estado DELETED)
    promises.push(
      confICSections.map((confICSection: ConfICSection) => {
        return this.deleteInstancesAssociatedWithConfICSectionId(
          confICSection.id,
          changeReason
        );
      })
    );

    //Elimina las secciones (Pasa a estado DELETED)
    promises.push(
      confICSections.map((confICSection: ConfICSection) => {
        const input: UpdateConfICSectionInput = {
          id: confICSection.id,
          state: ConfState.DELETED,
          _changeReason: changeReason,
          _version: confICSection._version,
        };
        return this.updateConfICSection(input, confICSection._version);
      })
    );

    await Promise.all(promises);
  }

  //Borra en cascadas las instancias asociadas a la sección
  async deleteInstancesAssociatedWithConfICSectionId(
    confSectionId: string,
    changeReason: string
  ): Promise<void> {
    const confICQuestions: ConfICQuestion[] =
      await this.getConfICQuestionByConfICSectionId(confSectionId);
    const promises: any[] = [];

    promises.push(
      confICQuestions.map((confICQuestion: ConfICQuestion) => {
        return this.deleteInstancesAssociatedWithConfICQuestionId(
          confICQuestion.id,
          changeReason
        );
      })
    );

    promises.push(
      confICQuestions.map((confICQuestion: ConfICQuestion) => {
        const input: UpdateConfICSectionInput = {
          id: confICQuestion.id,
          state: ConfState.DELETED,
          _changeReason: changeReason,
          _version: confICQuestion._version,
        };

        return this.updateConfICQuestion(input, confICQuestion._version);
      })
    );

    await Promise.all(promises);
  }

  //Borra en cascadas las instancias asociadas a la pregunta
  async deleteInstancesAssociatedWithConfICQuestionId(
    confICQuestionId: string,
    changeReason: string
  ): Promise<void> {
    const promises: any[] = [];
    const confICAnswers: ConfICAnswer[] =
      await this.getConfICAnswerByConfICQuestionId(confICQuestionId);

    promises.push(
      confICAnswers.map((confICAnswer: ConfICAnswer) => {
        const input: UpdateConfICAnswerInput = {
          id: confICAnswer.id,
          state: ConfState.DELETED,
          _changeReason: changeReason,
          _version: confICAnswer._version,
        };
        return this.updateConfICAnswer(input);
      })
    );
    await Promise.all(promises);
  }

  // QUESTIONS

  async getConfICQuestionByConfICSectionId(
    id: string,
    withDeletions: boolean = false
  ): Promise<ConfICQuestion[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConfICQuestionByConfICSectionId,
      { confICSectionId: id }
    );
    let { items } = response.data.ConfICQuestionByConfICSectionId;

    if (!withDeletions) {
      items = items.filter(
        (confICQuestion: any) =>
          !confICQuestion?._deleted &&
          confICQuestion.state !== ConfState.DELETED
      );
    }

    items.sort(
      (confICQuestionCurrent: any, confICQuestionNext: any) =>
        confICQuestionCurrent.order - confICQuestionNext.order
    );

    return items as ConfICQuestion[];
  }

  async getConfICQuestion(id: string): Promise<GetConfICQuestionQuery> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.GetConfICQuestion,
      { id }
    );

    return response.data.getConfICQuestion;
  }

  async createConfICQuestion(data: any): Promise<CreateConfICQuestionMutation> {
    let input = {} as CreateConfICQuestionInput;
    Object.assign(input, data);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateConfICQuestion,
      { input }
    );

    return response.data.createConfICQuestion;
  }

  async updateConfICQuestion(
    data: any,
    expectedVersion: any
  ): Promise<UpdateConfICQuestionMutation> {
    let id = data.id;
    let updateInput: UpdateConfICQuestionInput = {
      id,
      _version: expectedVersion,
    };
    delete data.confInformedConsent;
    Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateConfICQuestion,
      { input: updateInput }
    );

    return response.data.updateConfICQuestion;
  }

  // ANSWERS

  async getConfICAnswerByConfICQuestionId(
    confICQuestionId: string,
    withDeletions: boolean = false
  ): Promise<ConfICAnswer[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConfICAnswerByConfICQuestionId,
      { confICQuestionId }
    );

    const { items } = response.data.ConfICAnswerByConfICQuestionId;
    if (withDeletions) {
      return items as ConfICAnswer[];
    }

    //Filtra los registros eliminados
    return items.filter(
      (answer: any) => !answer._deleted && answer.state !== ConfState.DELETED
    ) as ConfICAnswer[];
  }

  async getConfICAnswer(id: string): Promise<GetConfICAnswerQuery> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.GetConfICAnswer,
      { id }
    );

    return response.data.getConfICAnswer;
  }

  async createConfICAnswer(
    confICAnswer: any
  ): Promise<CreateConfICAnswerMutation> {
    let input = {} as CreateConfICAnswerInput;
    Object.assign(input, confICAnswer);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateConfICAnswer,
      { input }
    );

    return response.data.createConfICAnswer;
  }

  async updateConfICAnswer(
    confICAnswer: any
  ): Promise<UpdateConfICAnswerMutation> {
    let input = {} as UpdateConfICAnswerInput;
    Object.assign(input, confICAnswer);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateConfICAnswer,
      { input }
    );

    return response.data.updateConfICAnswer;
  }

  // Informed Consent Instances

  async getInformedConsentInstancesByConfInformedConsentId(
    confInformedConsentId: string
  ): Promise<
    Exclude<InformedConsentInstanceByICConfigurationCustomQuery, '__typename'>
  > {
    let instances: Exclude<
      InformedConsentInstanceByICConfigurationCustomQuery,
      '__typename'
    >[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        INFORMED_CONSENT_QUERIES.InformedConsentInstanceByICConfigurationCustom,
        { confInformedConsentId, limit: 1000, nextToken }
      );
      instances = instances.concat(
        listQuery.data.InformedConsentInstanceByICConfiguration.items
      );
      nextToken =
        listQuery.data.InformedConsentInstanceByICConfiguration.nextToken;
    } while (nextToken);
    return instances as any;
  }

  async createInformedConsentInstance(
    data: any
  ): Promise<CreateInformedConsentInstanceMutation> {
    let input = {} as CreateInformedConsentInstanceInput;
    Object.assign(input, data);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateInformedConsentInstance,
      { input }
    );

    return response.data.createInformedConsentInstance;
  }

  async getUserByLogin(login: string): Promise<any> {
    let { items }: any = await this.userService.userByLogin(login);
    let user = null;
    if (items.length > 0) {
      user = items.find(
        (user: any) => !user._deleted && user.state !== InstanceState.DELETED
      );
    }
    return user;
  }

  async getUserByEmail(email: string): Promise<any> {
    let user = null;
    if (email) {
      let { items }: any = await this.userService.userByEmail(email);
      if (items.length > 0) {
        user = items.find(
          (user: any) => !user._deleted && user.state !== InstanceState.DELETED
        );
      }
    }
    return user;
  }

  async getInformedConsentByConsentedIdentificator(
    consentedIdentificator: string
  ): Promise<InformedConsentByConsentedIdentificatorQuery> {
    const filter: ModelInformedConsentInstanceFilterInput = {
      state: {
        ne: InformedConsentState.DELETED,
      },
    };
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.InformedConsentByConsentedIdentificator,
      { consentedIdentificator, filter }
    );
    return response.data.informedConsentByConsentedIdentificator;
  }

  async getConsentedUserByUserId(
    userId: string
  ): Promise<ConsentedUserByUserIdQuery> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConsentedUserByUserId,
      { userId }
    );

    return response.data.consentedUserByUserId;
  }

  //Obtiene las intancias de los consentimientos informados por el id del usuario
  async getInformedConsentByUserId(
    userId: string,
    confInformedConsentId: string //Si no se pasa, toma todos ignorando el filtro de configuración
  ) {
    const eConsents = [];
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConsentedUserByUserIdCustom,
      { userId }
    );
    //Custom query que retorna el usuario con los consentimientos asociados
    let { items } = response.data.ConsentedUserByUserId;

    items = items.filter(
      (item: any) =>
        !item?._deleted && item?.state !== InformedConsentState.DELETED
    );

    //Recorre todos los consentedUser que tiene asignado el usuario
    for (const consentedUser of items) {
      const informedConsentInstance = consentedUser?.informedConsentInstance;
      if (
        consentedUser?.id &&
        this.validateEconsentInformedByConfInformedId(
          informedConsentInstance,
          confInformedConsentId
        )
      ) {
        eConsents.push({
          consentedUser,
          informedConsent: informedConsentInstance,
        });
      }
    }
    return eConsents;
  }

  validateEconsentInformedByConfInformedId(
    informedConsentInstance: any,
    confInformedConsentId: string
  ) {
    if (confInformedConsentId) {
      if (
        informedConsentInstance?.confInformedConsentId !== confInformedConsentId
      ) {
        return false;
      }
    }

    return informedConsentInstance?.state !== InformedConsentState.DELETED;
  }

  async createConsentedUser(data: any): Promise<CreateConsentedUserMutation> {
    let input = {} as CreateConsentedUserInput;
    Object.assign(input, data);
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateConsentedUser,
      { input }
    );

    return response.data.createConsentedUser;
  }
  async updateConsentedUser(consentedUser: any, data: any) {
    let input: UpdateConsentedUserInput = {
      id: consentedUser.id,
      _version: consentedUser._version,
      _changeReason: consentedUser._changeReason ?? '',
      _lastUser: this.auth.getUsername(),
    };
    input = Object.assign(input, data);
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateConsentedUser,
      { input }
    );

    return response.data.updateConsentedUser;
  }

  async updateInfomedConsentInstance(IC: any, data: any) {
    let input: UpdateInformedConsentInstanceInput = {
      id: IC.id,
      _version: IC._version,
      _lastUser: this.auth.getUsername(),
    };
    input = Object.assign(input, data);
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateInformedConsentInstance,
      { input }
    );

    return response.data.updateInformedConsentInstance;
  }

  async updateConfInfomedConsentInstance(
    input: UpdateConfInformedConsentInput
  ) {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateConfInformedConsent,
      { input }
    );

    return response.data.updateConfInformedConsent;
  }

  async createInformedConsentSectionInstance(
    data: any
  ): Promise<CreateICSectionInstanceMutation> {
    let input = {} as CreateICSectionInstanceInput;
    Object.assign(input, data);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateICSectionInstance,
      { input }
    );

    return response.data.createICSectionInstance;
  }

  async createInformedConsentQuestionInstance(
    data: any
  ): Promise<CreateICQuestionInstanceMutation> {
    let input = {} as CreateICQuestionInstanceInput;
    Object.assign(input, data);
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateICQuestionInstance,
      { input }
    );

    return response.data.createICQuestionInstance;
  }

  async createSignatureInstance(input: CreateSignatureInstanceInput) {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateSignatureInstance,
      { input }
    );

    return response.data.createSignatureInstance;
  }

  //Funciones de eliminacion

  async deleteUserWithRolConsented(user: any): Promise<any> {
    const input: TP2UserInput = {
      login: user.login,
      email: user.email,
      name: user.name,
      role: user.role,
      phoneNumber: user.phoneNumber,
      _lastUser: this.auth.getUsername(),
      state: UserState.DISABLED,
    };
    const params = await this.paramsBuilder.withBody({ user: input }).build();
    logger.info('Disabling user', params);
    const response = JSON.parse(
      await API.post(INTEGRATIONS_API, '/users/disable', params)
    );
    logger.info('User disabled', response);

    const changelog: any = await this.trialpalService.modalChangeReason(
      input,
      this.trialpalService.translateService.instant('user.actions.updateUser')
    );
    if (changelog?._changeReason) {
      input._changeReason = changelog._changeReason;
      return response.data.updateUser;
    } else {
      return user;
    }
  }

  async getProject(id: string): Promise<Partial<Project>> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.GetProject,
      {
        id,
      }
    );

    return response.data.getProject;
  }

  async updateUserWithRolConsented(user: any, data: any): Promise<User> {
    let input: UpdateUserInput = {
      id: user.id,
      _lastUser: this.auth.getUsername(),
      _version: user._version,
    };

    input = Object.assign(input, data);
    const response = await this.performGraphQLQuery(USERS_QUERIES.UpdateUser, {
      input,
    });

    return response.data.updateUser;
  }

  async deleteConsentedUserMain(consentedUser: ConsentedUser) {
    const promises: any[] = [];
    promises.push(
      this.deleteICQuestionInstancesByConsentedUserId(consentedUser.id)
    );

    promises.push(
      this.deleteICSectionInstancesByConsentedUserId(consentedUser.id)
    );

    promises.push(this.deleteConsentedUser(consentedUser));

    await Promise.all(promises);
  }
  async deleteConsentedUser(
    consentedUser: ConsentedUser
  ): Promise<ConsentedUser> {
    const stateChanges = this.getStateChanges(
      InformedConsentState.DELETED,
      consentedUser?.stateChanges ?? []
    );
    return this.updateConsentedUser(consentedUser, {
      state: InformedConsentState.DELETED,
      stateChanges: stateChanges,
    });
  }

  //Funcion que se encarga de eliminar todas las secciones asociadas a un consetedUser
  async deleteICSectionInstancesByConsentedUserId(
    consentedUserId: string
  ): Promise<void> {
    const iCSectionsInstances: ICSectionInstance[] =
      await this.getICSectionInstanceByConsentedUserId(consentedUserId);

    const iCSectionsInstancesPromises = iCSectionsInstances.map(
      (iCSectionsInstance: ICSectionInstance) => {
        return this.updateICSectionInstance(iCSectionsInstance, {
          state: InstanceState.DELETED,
        });
      }
    );

    Promise.all(iCSectionsInstancesPromises);
  }

  //Funcion que se encarga de eliminar todas las preguntas respondidas asociadas a un consetedUser
  async deleteICQuestionInstancesByConsentedUserId(
    consentedUserId: string
  ): Promise<void> {
    const iCQuestionInstances: ICQuestionInstance[] =
      await this.getICQuestionInstanceByConsentedUserId(consentedUserId);

    const iCQuestionInstancesPromises = iCQuestionInstances.map(
      (iCQuestionInstance: ICQuestionInstance) => {
        return this.updateICQuestionInstance(iCQuestionInstance, {
          state: InstanceState.DELETED,
        });
      }
    );

    Promise.all(iCQuestionInstancesPromises);
  }

  deleteConsentedUserSuccessMessage() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.rowDetail.removeConsentedSuccessSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.rowDetail.removeConsentedSuccessDetail'
      ),
    });
  }

  //Funciones de listar
  async listUsers(filter: any): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        USERS_QUERIES.ListUsers,
        { filter, limit: 1000, nextToken }
      );
      instances = instances.concat(listQuery.data.listUsers.items);
      nextToken = listQuery.data.listUsers.nextToken;
    } while (nextToken);
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances;
  }
  async informedConsentByConsenterUser(consenterUserId: string) {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    while (nextToken) {
      const listQuery: any = await this.performGraphQLQuery(
        INFORMED_CONSENT_QUERIES.InformedConsentByConsenterUserCustom,
        { consenterUserId, nextToken }
      );
      instances = instances.concat(
        listQuery.data.informedConsentByConsenterUser.items
      );
      nextToken = listQuery.data.informedConsentByConsenterUser.nextToken;
    }
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances;
  }
  async createSignature(informedConsentInstance: any) {
    const input: CreateSignatureInstanceInput = {
      _lastUser: this.auth.getUsername(),
      date: new Date().toISOString(),
      entityId: informedConsentInstance?.id,
      entityType: SignatureEntityType.ECONSENT,
      reason: SignatureReason.REVIEW,
      signedInfo: JSON.stringify(informedConsentInstance),
      type: SignatureType.USER_PASS,
    };

    await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.CreateSignatureInstance,
      { input }
    );

    //Se actualiza la instancia del consentimiento informado
    const IC = await this.updateInfomedConsentInstance(
      informedConsentInstance,
      { state: InformedConsentState.SIGNED_BY_INVESTIGATOR }
    );
    return IC;
  }

  //=====================Validación de cargue masivo========================
  /**
   * Main function to validate user information and set errors if validation fails.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @param {any[]} users - List of existing users.
   * @param {any} oldUsers - List of old users.
   * @param {boolean} isMFAActivated - Flag indicating if MFA is activated.
   * @returns {Promise<void>}
   */
  async validateUser(
    userCol: ExcelFileUserInput,
    users: any[],
    oldUsers: any,
    isMFAActivated: boolean
  ): Promise<void> {
    const { consentedIdentificators, consentedNames, consentedBirthDates } =
      this.getConsentedInformation(userCol);
    const notificationPreference = userCol.notificationPreference?.data;
    const requiresSendingEmail = userCol.requiresSendingEmail.data;

    const userValidationResult = await this.validateUserLogin(
      userCol,
      users,
      oldUsers
    );
    const validateUserNameResult = this.validateUserName(userCol);
    const validateUserIdentifierResult = this.validateUserIdentifier(userCol);
    const nameValidationResult = this.validateConsentedUserNames(
      consentedNames,
      consentedIdentificators,
      consentedBirthDates,
      userCol
    );
    const validateRequiresSendingEmailResult =
      this.validateRequiresSendingEmail(userCol);
    const emailValidationResult = await this.validateUserEmail(
      users,
      userCol,
      notificationPreference,
      requiresSendingEmail
    );
    const phoneValidationResult = this.validateUserPhoneNumber(
      userCol,
      isMFAActivated,
      notificationPreference
    );
    const validateUserBirthdatesResult = this.validateUserBirthdates(
      consentedBirthDates,
      userCol
    );
    const validateUserRelationshipResult =
      this.validateUserRelationship(userCol);
    const validateNotificationPreferenceResult =
      this.validateNotificationPreference(userCol);
    const validations = [
      userValidationResult,
      validateUserNameResult,
      validateUserIdentifierResult,
      nameValidationResult,
      validateRequiresSendingEmailResult,
      emailValidationResult,
      phoneValidationResult,
      validateUserBirthdatesResult,
      validateUserRelationshipResult,
      validateNotificationPreferenceResult,
    ];
    userCol.isError = validations.some((validation) => validation);
  }

  /**
   * Validates the user login information.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @param {any[]} users - List of existing users.
   * @param {any} oldUsers - List of old users.
   * @returns {Promise<boolean>} - Returns true if there is an error, false otherwise.
   */
  async validateUserLogin(
    userCol: ExcelFileUserInput,
    users: any[],
    oldUsers: any
  ): Promise<boolean> {
    const { userInvalid, userExist, userEmpty } = await this.isAValidUser(
      userCol.login.data,
      userCol.identifier.data,
      [...users, ...oldUsers]
    );

    userCol.login.isError = false;
    if (userInvalid || userExist || userEmpty) {
      userCol.login.isError = true;
      userCol.login.messageError = userInvalid
        ? 'informedConsent.formUser.errors.user'
        : userEmpty
        ? 'general.required'
        : '';
      userCol.login.messageError2 = userExist
        ? 'informedConsent.formUser.errors.userExist'
        : '';
      return true;
    }
    return false;
  }

  /**
   * Validates the user name.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateUserName(userCol: ExcelFileUserInput): boolean {
    userCol.name.isError = false;
    if (!this.isAValidUserName(userCol.name.data)) {
      userCol.name.isError = true;
      userCol.name.messageError = 'general.required';
      return true;
    }
    return false;
  }

  /**
   * Validates the user identifier.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateUserIdentifier(userCol: ExcelFileUserInput): boolean {
    userCol.identifier.isError = false;
    if (!this.isAValidUserName(userCol.identifier.data)) {
      userCol.identifier.isError = true;
      userCol.identifier.messageError = 'general.required';
      return true;
    }
    return false;
  }

  /**
   * Validates the consented user names.
   *
   * @param {string[]} consentedNames - Array of consented names.
   * @param {string[]} consentedIdentificators - Array of consented identifiers.
   * @param {string[]} consentedBirthDates - Array of consented birthdates.
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateConsentedUserNames(
    consentedNames: string[],
    consentedIdentificators: string[],
    consentedBirthDates: string[],
    userCol: ExcelFileUserInput
  ): boolean {
    const { nameInvalid, nameNotEqual } = this.isAValidConsentedUserNames(
      consentedNames,
      consentedIdentificators,
      consentedBirthDates
    );

    let isError = false;
    if (nameNotEqual) {
      userCol.error = 'informedConsent.formUser.errors.notEqual';
      isError = true;
    }

    userCol.subjectNames.isError = false;
    if (nameInvalid) {
      userCol.subjectNames.isError = true;
      userCol.subjectNames.messageError = nameInvalid ? 'general.required' : '';
      isError = true;
    }
    return isError;
  }

  /**
   * Validates if sending email is required.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateRequiresSendingEmail(userCol: ExcelFileUserInput): boolean {
    userCol.requiresSendingEmail.isError = false;
    if (
      userCol.requiresSendingEmail.data === null ||
      userCol.requiresSendingEmail.data === undefined
    ) {
      userCol.requiresSendingEmail.isError = true;
      userCol.requiresSendingEmail.messageError = 'general.required';
      return true;
    }
    return false;
  }

  /**
   * Validates the user email.
   *
   * @param {any[]} users - List of existing users.
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @param {NotificationPreferences} notificationPreference - Notification preference.
   * @param {boolean} requiresSendingEmail - Flag indicating if sending email is required.
   * @returns {Promise<boolean>} - Returns true if there is an error, false otherwise.
   */
  async validateUserEmail(
    users: any[],
    userCol: ExcelFileUserInput,
    notificationPreference: NotificationPreferences,
    requiresSendingEmail: boolean
  ): Promise<boolean> {
    const { emailInvalid, emailExist } = await this.isAValidEmail(
      users,
      userCol.email.data
    );

    const requiredEmail =
      notificationPreference === NotificationPreferences.EMAIL ||
      userCol.email.data ||
      requiresSendingEmail;

    userCol.email.isError = false;
    if (
      (emailInvalid || emailExist) &&
      (requiredEmail || !notificationPreference)
    ) {
      userCol.email.isError = true;
      userCol.email.messageError = emailInvalid
        ? 'informedConsent.formUser.errors.email'
        : '';
      userCol.email.messageError2 = emailExist
        ? 'informedConsent.formUser.errors.emailExist'
        : '';
      return true;
    }
    return false;
  }

  /**
   * Validates the user phone number.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @param {boolean} isMFAActivated - Flag indicating if MFA is activated.
   * @param {NotificationPreferences} notificationPreference - Notification preference.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateUserPhoneNumber(
    userCol: ExcelFileUserInput,
    isMFAActivated: boolean,
    notificationPreference: NotificationPreferences
  ): boolean {
    userCol.phoneNumber.isError = false;
    let isError = false;
    const isPhoneNumberRequired =
      isMFAActivated ||
      notificationPreference === NotificationPreferences.SMS ||
      !notificationPreference ||
      userCol.phoneNumber.data;

    if (
      !this.trialpalService.phoneNumberService.isValidPhoneNumber(
        userCol.phoneNumber.data
      ).isValid &&
      isPhoneNumberRequired
    ) {
      userCol.phoneNumber.isError = true;
      userCol.phoneNumber.messageError =
        this.trialpalService.phoneNumberService.isValidPhoneNumber(
          userCol.phoneNumber.data
        ).message;
      isError = true;
    }

    if (!userCol.phoneNumber.data && isPhoneNumberRequired) {
      userCol.phoneNumber.isError = true;
      userCol.phoneNumber.messageError = 'general.required';
      isError = true;
    }
    return isError;
  }

  /**
   * Validates the user birthdates.
   *
   * @param {string[]} consentedBirthDates - Array of consented birthdates.
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateUserBirthdates(
    consentedBirthDates: string[],
    userCol: ExcelFileUserInput
  ): boolean {
    userCol.subjectBirthdates.isError = false;
    if (!this.isAValidsubjectBirthdates(consentedBirthDates)) {
      userCol.subjectBirthdates.isError = true;
      userCol.subjectBirthdates.messageError =
        'informedConsent.formUser.errors.birthday';
      return true;
    }
    return false;
  }

  /**
   * Validates the user relationship type.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateUserRelationship(userCol: ExcelFileUserInput): boolean {
    userCol.relationship.isError = false;
    if (!this.isAValidRelationship(userCol.relationship.data)) {
      userCol.relationship.isError = true;
      userCol.relationship.messageError =
        'informedConsent.formUser.errors.relationship';
      return true;
    }
    return false;
  }

  /**
   * Validates the notification preference.
   *
   * @param {ExcelFileUserInput} userCol - The user information from the Excel file.
   * @returns {boolean} - Returns true if there is an error, false otherwise.
   */
  validateNotificationPreference(userCol: ExcelFileUserInput): boolean {
    userCol.notificationPreference.isError = false;
    if (
      !this.isAValidNotificatonPreference(userCol.notificationPreference.data)
    ) {
      userCol.notificationPreference.isError = true;
      userCol.notificationPreference.messageError =
        'informedConsent.formUser.errors.notificationPreference';
      return true;
    }
    return false;
  }

  async isAValidUser(
    user: string,
    identifiers: any,
    users: any[]
  ): Promise<any> {
    //Valida si se le ingreso datos en la columna del usuario
    if (!user || String(user)?.trim()?.length === 0) {
      return {
        userInvalid: false,
        userExist: false,
        userEmpty: true,
      };
    }

    //Valida si el consentimiento para el usuario no existe
    identifiers = String(identifiers)?.split(',') || [];
    const userExist = await this.getUserByLogin(user);
    let eConsentExist = false;
    if (userExist?.id) {
      const eConsents: any[] = await this.getInformedConsentByUserId(
        userExist.id,
        ''
      );

      eConsentExist = eConsents.find((eConsent: any) =>
        identifiers.includes(eConsent.informedConsent.consentedIdentificator)
      );
    }

    //Valida si no se añadio 2 veces el mismo usuario en la tabla
    const userExistInTable = users.filter((userCol: any) => {
      return userCol.login.data === user;
    });

    return {
      userInvalid: !new RegExp(userRegex).test(user),
      userExist: eConsentExist || userExistInTable.length > 1,
      userEmpty: String(user)?.trim()?.length === 0,
    };
  }

  isAValidUserName(name: string): boolean {
    return String(name)?.trim()?.length !== 0;
  }

  isAValidConsentedUserNames(
    names: string[],
    identifiers: string[],
    birthdays: string[]
  ): any {
    if (
      names.length === 0 &&
      identifiers.length === 0 &&
      birthdays.length === 0
    ) {
      return {
        nameInvalid: true,
        nameNotEqual: false,
      };
    }

    return {
      nameInvalid: false,
      nameNotEqual:
        names.length !== identifiers.length ||
        names.length !== birthdays.length,
    };
  }

  async isAValidEmail(users: any[], email: string = ''): Promise<any> {
    if (!email && String(email)?.trim()?.length === 0) {
      return {
        emailInvalid: true,
        emailExist: false,
      };
    }
    const emailExist = await this.getUserByEmail(email);

    //Valida si no se añadio 2 veces el mismo usuario en la tabla
    const emailExistInTable = users.filter((userCol: any) => {
      return userCol.email.data === email;
    });

    return {
      emailInvalid: !new RegExp(emailRegex).test(email),
      emailExist: emailExist || emailExistInTable.length > 1,
    };
  }

  isAValidPhoneNumber(phoneNumber: string): boolean {
    let isValidNumber = true;
    if (String(phoneNumber)?.includes('+1')) {
      isValidNumber = this.PHONE_STATE_US?.includes(
        Number(phoneNumber.split('+1')[1].slice(0, 3))
      );
    }
    return new RegExp(phoneRegex).test(phoneNumber) && isValidNumber;
  }

  isAValidSubjects(subjects: string) {
    return String(subjects)?.trim()?.length !== 0;
  }

  isAValidRelationship(relationShip: string): boolean {
    if (String(relationShip)?.trim()?.length === 0) return false;
    return Object.values(Relationship).some(
      (relation: any) => String(relationShip)?.toUpperCase() === relation
    );
  }
  isAValidNotificatonPreference(preference: string): boolean {
    if (String(preference)?.trim()?.length === 0) return false;
    return Object.values(NotificationPreferences).some(
      (pref: any) => String(preference)?.toUpperCase() === pref
    );
  }

  isAValidsubjectBirthdates(subjectBirthdates: string[]) {
    if (!subjectBirthdates?.length) return false;
    return subjectBirthdates.every((subjectsBirthday) =>
      this.isAValidDate(subjectsBirthday)
    );
  }

  //Valida que la fecha de nacimiento cumpla el formato DD-MM-AAAA
  isAValidDate(value: string) {
    if (!value) return false;
    const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d$/;
    if (!regex.test(value) || !this.isBirthDayInAValidRange(value)) {
      return false;
    }

    const [day, month, year] = value.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }

  //Valida que la fecha de nacimiento sea superior a 1900 e inferios al día actual
  isBirthDayInAValidRange(date: any): boolean {
    return moment(date, 'DD-MM-YYYY').isBetween(
      '01-01-1900',
      moment(),
      null,
      '[]'
    );
  }

  getConsentedInformation(userTable: ExcelFileUserInput) {
    const identifiers = userTable?.identifier?.data ?? [];
    const subjectNames = userTable?.subjectNames?.data ?? [];
    const subjectBirthdates = userTable?.subjectBirthdates?.data ?? [];
    //Valida si es un arreglo o un string separado por ','
    const consentedIdentificators = Array.isArray(identifiers)
      ? identifiers
      : userTable?.identifier?.data.split(',');
    //Valida si es un arreglo o un string separado por ','
    const consentedNames = Array.isArray(subjectNames)
      ? subjectNames
      : userTable?.subjectNames?.data?.split(',');
    const consentedBirthDates = Array.isArray(subjectBirthdates)
      ? subjectBirthdates
      : userTable?.subjectBirthdates?.data?.toString()?.split(',');

    return {
      consentedIdentificators: this.getArrayWithoutEmptyField(
        consentedIdentificators
      ),
      consentedNames: this.getArrayWithoutEmptyField(consentedNames),
      consentedBirthDates: this.getArrayWithoutEmptyField(consentedBirthDates),
    };
  }

  getArrayWithoutEmptyField(array: string[]) {
    return array
      .map((value: string) => value.trim())
      .filter((value: string) => value?.length !== 0);
  }

  onAddRecipient(input: any, form: any): void {
    let pattern = new RegExp(emailRegex);
    if (!pattern.test(input)) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.smallInvalidEmail'
        ),
      });

      let emails = form.controls['recipients'].value;
      emails = emails.filter((recipient: any) => input !== recipient);
      form.controls['recipients'].setValue(emails);
    }
  }

  async updateICQuestionInstance(ICQuestionInstance: any, data: any) {
    let input: UpdateICQuestionInstanceInput = {
      id: ICQuestionInstance.id,
      _version: ICQuestionInstance._version,
      _lastUser: this.auth.getUsername(),
    };
    input = Object.assign(input, data);
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateICQuestionInstance,
      { input }
    );

    return response.data.updateICQuestionInstance;
  }
  async updateICSectionInstance(ICSectionInstance: any, data: any) {
    let input: UpdateICSectionInstanceInput = {
      id: ICSectionInstance.id,
      _version: ICSectionInstance._version,
      _lastUser: this.auth.getUsername(),
    };
    input = Object.assign(input, data);
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateICSectionInstance,
      { input }
    );

    return response.data.updateICSectionInstance;
  }
  //Se encarga de añadir el nuevo estado al arreglo
  updateStateChanges(
    state: InformedConsentState,
    stateChanges: any[] = []
  ): any[] {
    const stateChange: StateChangeInput = {
      state: state,
      date: new Date().toISOString(),
    };

    stateChanges = stateChanges?.map((stateChange: any) => {
      delete stateChange?.__typename;
      return stateChange;
    });

    stateChanges.push(stateChange);
    return stateChanges;
  }
  async updateAndVerifyInformedConsentInstance(
    informedConsent: InformedConsent,
    informedConsentState: InformedConsentState
  ): Promise<InformedConsentInstance> {
    const informedConsentInstance = informedConsent.informedConsent;
    const confInformedConsent = informedConsent.confInformedConsent;

    const areAllConsentedUsersInCurrentState =
      await this.validateConsentedUsersStateByInformedConsents(
        informedConsentInstance.id,
        confInformedConsent,
        informedConsentState
      );

    if (areAllConsentedUsersInCurrentState) {
      let stateChanges: any[] = this.updateStateChanges(
        informedConsentState,
        informedConsentInstance.stateChanges ?? []
      );

      const input: UpdateInformedConsentInstanceInput = {
        id: informedConsentInstance.id,
        state: informedConsentState,
        stateChanges: stateChanges,
        _lastUser: this.auth.getUsername(),
        _version: informedConsentInstance._version,
      };

      return this.updateInformedConsentInstance(input);
    }
    return informedConsentInstance as any;
  }
  //Valida si todos los usuarios se encuentran en estado enviado como parametro
  async validateConsentedUsersStateByInformedConsents(
    informedConsentId: string,
    confInformedConsent: ConfInformedConsent,
    state: InformedConsentState
  ): Promise<boolean> {
    const consentedUsers: ConsentedUser[] =
      await this.getConsentedUsersByInformedConsentId(informedConsentId);

    const consentedUserFilter = consentedUsers.filter(
      (consentedUser: ConsentedUser) =>
        consentedUser.stateChanges?.find(
          (stateChange: any) => stateChange.state === state
        )
    );

    //Valida Si la cantidad de usuarios en el estado actual son los mismos solicitados para firma
    if (
      confInformedConsent.minRequiredSignatures ==
      confInformedConsent.maxRequiredSignatures
    ) {
      return (
        consentedUserFilter.length === confInformedConsent.minRequiredSignatures
      );
    } else {
      return (
        consentedUserFilter.length ===
          consentedUsers.filter(
            (consentedUser: any) =>
              !consentedUser._deleted &&
              consentedUser.state !== InformedConsentState.DELETED
          ).length &&
        consentedUserFilter.length >=
          confInformedConsent.minRequiredSignatures &&
        consentedUserFilter.length <= confInformedConsent.maxRequiredSignatures
      );
    }
  }
  async getConsentedUsersByInformedConsentId(
    id: string
  ): Promise<ConsentedUser[]> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.ConsentedUserByInformedConsentInstanceIdCustom,
      { informedConsentInstanceId: id }
    );

    const { items } = response.data.ConsentedUserByInformedConsentInstanceId;

    return items.filter(
      (consentedUser: any) =>
        !consentedUser._deleted &&
        consentedUser.state !== InformedConsentState.DELETED
    ) as ConsentedUser[];
  }
  async updateInformedConsentInstance(
    input: UpdateInformedConsentInstanceInput
  ): Promise<InformedConsentInstance> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.UpdateInformedConsentInstance,
      { input }
    );

    return response.data.updateInformedConsentInstance;
  }

  async sendProjectAdminNotification(
    input: tp2SendProjectAdminNotificationInput
  ): Promise<string | null> {
    const response = await this.performGraphQLQuery(
      INFORMED_CONSENT_QUERIES.Tp2SendProjectAdminNotification,
      { input }
    );

    return response.data.tp2SendProjectAdminNotification;
  }

  getStateChanges(
    state: InformedConsentState,
    stateChanges: any[] = []
  ): StateChangeInput[] {
    let stateChangesInput: StateChangeInput[] = [];

    stateChangesInput = stateChanges.map((stateChange: any) => {
      delete stateChange.__typename;
      return stateChange;
    });

    stateChangesInput.push({
      date: new Date().toISOString(),
      state: state,
    });

    return stateChangesInput;
  }
  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }

  recordeConsentEvent(eventName: string, attributes: any = {}) {
    Analytics.record({
      name: eventName,
      attributes: {
        userSub: this.auth.user?.attributes?.sub,
        userName: this.auth.user?.username,
        ...attributes,
      },
    });
  }

  /**
   * Transforma una cadena de texto que representa una fecha a un objeto Date.
   *
   * @param {any} date - La fecha que se desea transformar. Puede ser una cadena de texto u otro tipo de dato.
   * @param {string} format - El formato actual de la fecha. Se utilizará para interpretar la fecha de entrada.
   * @returns {Date | undefined} - La fecha transformada en un objeto Date. Si la entrada `date` es inválida o nula, o si no se puede transformar, se retorna `undefined`.
   */
  transformStringToDate(date: any, format: string): Date | undefined {
    if (!date) return undefined;
    if (!this.isAValidDate(date)) return undefined;
    try {
      return moment(date, format).toDate();
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Transforma una fecha dada al formato 'DD-MM-YYYY'.
   *
   * @param {any} date - La fecha que se desea formatear. Puede ser una cadena de texto, un objeto Date o cualquier valor aceptado por Moment.js.
   * @param {string} [format] - El formato actual de la fecha (opcional). Si se proporciona, se utilizará para interpretar la fecha de entrada.
   * @returns {string} - La fecha formateada en 'DD-MM-YYYY'. Si la entrada `date` es inválida o nula, se retorna la entrada original.
   */
  transformDateToDDMMYYYYFormat(date: any, format?: string): string {
    if (!date || !this.isAValidDate(date)) return date;
    if (!format) return moment(date).format('DD-MM-YYYY');
    return moment(date, format).format('DD-MM-YYYY');
  }

  /**
   * Transforma una fecha dada al formato 'YYYY-MM-DD'.
   *
   * @param {any} date - La fecha que se desea formatear. Puede ser una cadena de texto, un objeto Date o cualquier valor aceptado por Moment.js.
   * @param {string} format - El formato actual de la fecha. Se utilizará para interpretar la fecha de entrada.
   * @returns {string} - La fecha formateada en 'YYYY-MM-DD'. Si la entrada `date` es inválida o nula, se retorna la entrada original.
   */
  transformDateToYYYYMMDDFormat(date: any, format: string) {
    if (!date || !this.isAValidDate(date)) return date;
    return moment(date, format).format('YYYY-MM-DD');
  }
}
