import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MedicationConfigurationMode } from 'src/app/modules/conciliation/conciliation.types';

@Component({
  selector: 'app-summary-day',
  templateUrl: './summary-day.component.html',
})
export class SummaryDayComponent {
  original: any = {};
  verified: any = {};
  configuration: any = {};

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.original = this.config.data.original;
    this.verified = this.config.data.verified;
    this.configuration = this.config.data.configuration;
  }

  get isGivenToEnabled() {
    return (
      this.configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_OPEN_REASON ||
      this.configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_SYMPTOM_BINDING
    );
  }

  onClose(result: boolean) {
    this.ref.close(result);
  }

  isMedicationGivenToValid() {
    return this.original?.medicationGivenTo || this.verified?.medicationGivenTo;
  }
}
