import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatConfigurationComponent } from './chat-configuration/chat-configuration.component';
import { SiteChatComponent } from './site-chat/site-chat.component';

const routes: Routes = [
  {
    path: 'chatRoom/:projectId/:chatId',
    component: SiteChatComponent,
  },
  {
    path: 'chatRoom/:projectId',
    component: SiteChatComponent,
  },
  {
    path: 'chatConf/:projectId',
    component: ChatConfigurationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChatRoutingModule {}
