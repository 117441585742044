<form name="form" autocomplete="off" (ngSubmit)="onSubmit(form)" #form="ngForm">
  <header class="p-mb-4 header-form-signature">
    <div class="signature-image">
      <img src="./assets/imgs/documentSignature.png" alt="signatureImage" />
    </div>
    <h3 class="signature-title" *ngIf="title">{{ title }}</h3>
    <p class="siganture-description" *ngIf="description">
      {{ description }}
    </p>
  </header>
  <main class="p-d-flex p-flex-column p-mb-4" style="gap: 1rem">
    <div>
      <label id="lbl_user" name="lbl_user" for="txt_user"
        >{{ 'conciliation.labelSignatureUser' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <input
        type="text"
        pInputText
        id="txt_user"
        name="txt_user"
        #userInput="ngModel"
        [(ngModel)]="username"
        required
        placeholder="{{ 'conciliation.placeholderUser' | translate }}"
        [disabled]="true"
      />
      <small
        class="form-error"
        *ngIf="userInput.invalid && (form.submitted || userInput.touched)"
        >{{ 'general.required' | translate }}
      </small>
    </div>

    <div>
      <label id="lbl_password" name="lbl_password" for="txt_password"
        >{{ 'conciliation.labelSignaturePassword' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <div class="password-input">
        <input
          id="txt_password"
          name="txt_password"
          type="password"
          pPassword
          #txt_password="ngModel"
          [(ngModel)]="password"
          [showPassword]="isPasswordShown"
          [feedback]="false"
          required
          placeholder="{{ 'conciliation.placeholderPassword' | translate }}"
        />
        <span
          class="password-icon pi"
          [ngClass]="{
            'pi-eye': !isPasswordShown,
            'pi-eye-slash': isPasswordShown
          }"
          (click)="isPasswordShown = !isPasswordShown"
        ></span>
      </div>
      <small
        class="form-error"
        *ngIf="txt_password.invalid && (form.submitted || txt_password.touched)"
        >{{ 'general.required' | translate }}
      </small>
    </div>
  </main>
  <footer class="buttons-grid">
    <button
      id="btn_cancelAnswer"
      pButton
      pRipple
      type="submit"
      label="{{ 'general.cancel' | translate }}"
      style="width: 100%"
      (click)="onCancel()"
      type="button"
      class="p-button-secondary p-button-outlined"
    ></button>
    <button
      id="btn_createReport"
      name="btn_createReport"
      class="btn btn-primary"
      style="width: 100%"
      pButton
      label="{{ 'general.sign' | translate }}"
      type="submit"
      [disabled]="disabled"
    ></button>
  </footer>
</form>
