<div class="p-mb-5 summary">
  <div class="p-grid p-col-12 p-p-0">
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showTemperatureRoute &&
        oldValue.temperatureRoute !== verified.temperatureRoute
      "
    >
      <span id="lbl_route" name="lbl_route">
        {{ 'temperaturerecordlog.route' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.temperatureRoute
            | textNa: verified.temperatureRoute:'':true:false:false:false
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="oldValue.temperature !== verified.temperature"
    >
      <span id="lbl_temperature" name="lbl_temperature"
        >{{ 'temperaturerecordlog.temperature' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.temperature
            | textNa: verified.temperature:'':true:false:false:false
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showTemperatureTakenDate &&
        oldValue.temperatureTakenDate !== verified.temperatureTakenDate
      "
    >
      <span id="lbl_temperatureDate" name="lbl_temperatureDate">
        {{ 'temperaturerecordlog.temperatureTakenDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.temperatureTakenDate
            | textNa: verified.temperatureTakenDate:'':true:false:false:false
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showTemperatureTaken &&
        oldValue.temperatureTaken !== verified.temperatureTaken
      "
    >
      <span id="lbl_temperatureTakenDate" name="lbl_temperatureTakenDate">
        {{ 'temperaturerecordlog.temperatureTaken' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.temperatureTaken
            | textNa: verified.temperatureTaken:'':true:false:false:false
        "
      ></div>
    </div>
  </div>
</div>
<div class="p-d-flex p-jc-end">
  <button
    pButton
    type="button"
    label="{{ 'general.cancel' | translate }}"
    icon="pi pi-times"
    class="p-mx-2"
    (click)="onCancel()"
  ></button>
  <button
    pButton
    type="button"
    label="{{ 'general.confirm' | translate }}"
    icon="pi pi-check"
    class="p-button-secondary"
    (click)="onAccept(true)"
  ></button>
</div>
