<form class="form-site" #formTemplate="ngForm">
  <div class="delete-size" *ngIf="showDeleteButton">
    <span
      class="pi pi-times"
      (click)="showDisplayDeleteComponent = true"
    ></span>
  </div>
  <div>
    <label for="drop_site">
      {{ 'informedConsent.formSiteName' | translate }}
      <span class="inputRequired">* </span>
    </label>
    <p-dropdown
      id="drop_site"
      name="site"
      optionLabel="label"
      optionValue="value"
      [(ngModel)]="site.site"
      appendTo="body"
      [options]="siteOptions"
      (onChange)="validateSiteSelected()"
      placeholder="{{ 'informedConsent.formSitePlaceholderName' | translate }}"
      emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
      emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
      [required]="true"
      #siteTemplate="ngModel"
    >
    </p-dropdown>
    <small
      class="form-error"
      *ngIf="siteTemplate.invalid && (siteTemplate.touched || submitted)"
      >{{ 'general.required' | translate }}</small
    >
  </div>

  <div></div>

  <div>
    <label for="txt_totalSubjects">
      {{ 'informedConsent.formSiteTotalSubject' | translate }}
      <span class="inputRequired">* </span>
    </label>
    <input
      id="txt_totalSubjects"
      name="totalSubjects"
      type="number"
      [(ngModel)]="site.totalSubjects"
      min="0"
      pInputText
      [placeholder]="'informedConsent.formSitePlaceholderTotal' | translate"
      [required]="true"
      #totalSubjectsTemplate="ngModel"
    />
    <small
      class="form-error"
      *ngIf="
        totalSubjectsTemplate.invalid &&
        (totalSubjectsTemplate.touched || submitted)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>

  <div>
    <label for="chip_recipients">
      {{ 'informedConsent.formEmail' | translate }}
      <span class="inputRequired">* </span>
    </label>
    <div>
      <p-chips
        id="chip_recipients"
        [allowDuplicate]="false"
        separator=","
        name="recipients"
        [(ngModel)]="site.recipients"
        [addOnBlur]="true"
        (onAdd)="informedConsentService.onAddRecipient($event.value, form)"
        [placeholder]="'informedConsent.formEmailPlaceHolder' | translate"
        [required]="true"
        #recipientsTemplate="ngModel"
      >
      </p-chips>
    </div>
    <small
      class="form-error"
      *ngIf="
        recipientsTemplate.invalid && (recipientsTemplate.touched || submitted)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
</form>

<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="deleteSiteEmmiter.emit()"
  position="fixed"
>
</app-delete-confirmation>
