import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { Field } from '../../../interfaces/formly-designer.interface';
import { FieldType } from '../../field-types';
import { AddEditFieldComponent } from '../add-edit-field/add-edit-field.component';
const logger = new Logger('list-fields');
@Component({
  selector: 'app-list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./list-field.component.scss'],
})
export class FieldOptionComponent implements OnChanges {
  @Input() fieldGroup: Field[] = [];
  @Input() fieldSelected: { type: string; icon: string; value: Field } | null =
    null;
  @Input() projectId: string = '';
  @Input() isAPrincipalGroup: boolean = false;
  @Input() isAEvaluationGroup: boolean = false;
  @Input() onDragDrop$ = new Subject<CdkDragDrop<Array<any>>>();
  @Output() fieldGroupChange: EventEmitter<any> = new EventEmitter();
  showDeleteFieldComponent: boolean = false;
  fieldIndex: number = 0;
  isMainFieldGroup: boolean = false;
  constructor(private dialogService: DialogService) {}

  ngOnChanges(): void {
    this.isMainFieldGroup =
      this.fieldGroup[0]?.templateOptions?.isMain ?? false;
  }

  /* =============== Funciones que afectan un campo =============== */
  editField(fieldIndex: number) {
    let fieldGroup = this.fieldGroup;
    const field = fieldGroup[fieldIndex];
    const dialogRef = this.openAddEditFieldComponent(
      field,
      fieldGroup,
      this.isAPrincipalGroup,
      this.isAEvaluationGroup
    );

    dialogRef.onClose.subscribe((updateField: any) => {
      if (updateField) {
        this.fieldGroup[fieldIndex] = updateField;
        this.emitFieldGroup();
      }
    });
  }

  //Funcion que se encarga de copiar un campo debajo del seleccionado
  copyField(fieldIndex: number) {
    let fieldGroup = this.fieldGroup;
    let originalField = fieldGroup[fieldIndex];
    // Crea una copia del campo original
    let copiedField = structuredClone(originalField);
    const isAGroup = Boolean(copiedField?.fieldGroup);

    // Genera una nueva clave única para el campo copiado (Si el campo tenia previamente una clave)
    if (copiedField?.key) {
      copiedField.key = `${copiedField.type}_${new Date().getTime()}`;
    }

    //Si es un grupo actualiza las llaves de los campos dentro del grupo
    if (isAGroup) {
      const updateFieldKey = this.updateKeyIngroupFields(
        copiedField,
        JSON.stringify(copiedField)
      );
      copiedField = JSON.parse(updateFieldKey);
    }

    // Agrega el campo copiado debajo del campo original
    fieldGroup.splice(fieldIndex + 1, 0, copiedField);
    this.emitFieldGroup();
  }

  /**
   * Actualiza las claves de los campos en un objeto recursivamente.
   * Reemplaza las claves de los campos que coinciden con un patrón específico.
   * @param obj El objeto en el que se actualizarán las claves de los campos.
   * @param copiedField La cadena que representa el campo copiado que será actualizado.
   * @returns Una cadena actualizada con las claves de los campos modificadas según el objeto proporcionado.
   */
  updateKeyIngroupFields(obj: any, copiedField: string): string {
    for (let key in obj) {
      if (key === 'key' && obj[key] && !obj[key]?.includes('model')) {
        const type = obj[key].split('_')[0];
        const newKey = this.getNewKey(type);
        copiedField = copiedField.replace(new RegExp(obj[key], 'g'), newKey);
      }
      if (typeof obj[key] === 'object') {
        copiedField = this.updateKeyIngroupFields(obj[key], copiedField); // Recursive call
      }
    }
    return copiedField;
  }

  getNewKey(type: string) {
    return `${type}_${Math.floor(Math.random() * Date.now())}`;
  }

  openDeleteFieldComponent(fieldIndex: number) {
    this.showDeleteFieldComponent = true;
    this.fieldIndex = fieldIndex;
  }

  //Funcion que se encarga de eliminar un campo de la lista
  deleteField(fieldIndex: number) {
    this.fieldGroup.splice(fieldIndex, 1);
    this.emitFieldGroup();
  }

  emitFieldGroup() {
    this.fieldGroupChange.emit([...this.fieldGroup]);
  }

  /* =============== Funciones que afectan un campo =============== */

  addFieldInGroup(
    event: any,
    field: any,
    isMainFieldGroup: boolean,
    isEvaluationGroup: boolean = false
  ) {
    event.preventDefault();
    event.stopPropagation();
    const fieldGroup: any[] = field?.fieldGroup
      ? this.getFieldGroup(field)
      : this.fieldGroup;
    logger.debug(
      'addFielInGroup',
      isMainFieldGroup,
      'group -->',
      field,
      fieldGroup,
      'field -->',
      this.fieldSelected
    );
    //Si es el grupo principal solo permite elementos de grupo
    if (this.isMainFieldGroup && this.fieldSelected?.type !== FieldType.GROUP)
      return;

    //Si viene de un campo de las opciones
    if (this.fieldSelected) {
      const newField = this.fieldSelected.value;
      const close = this.openAddEditFieldComponent(
        newField,
        fieldGroup,
        isMainFieldGroup,
        isEvaluationGroup
      );

      close.onClose.subscribe((updatedField: any) => {
        if (updatedField) {
          fieldGroup.push(updatedField);
          this.emitFieldGroup();
        }
      });
      this.fieldSelected = null;
    }
  }

  //Funcion que se encarga de llamar el modal donde se editarán los campos
  openAddEditFieldComponent(
    field: any,
    fieldGroup: any,
    isMainFieldGroup: boolean = false,
    isAEvaluationGroup: boolean = false
  ) {
    const dialogRef = this.dialogService.open(AddEditFieldComponent, {
      closable: true,
      data: {
        field: field,
        fieldGroup,
        projectId: this.projectId,
        principalGroup: isMainFieldGroup,
        isAEvaluationGroup: isAEvaluationGroup,
      },
      closeOnEscape: true,
      dismissableMask: true,
      styleClass: 'modal-edit-field',
      baseZIndex: 10000,
    });

    return dialogRef;
  }

  getFieldGroup(field: any) {
    if (!field?.fieldGroup) return [];
    if (field?.templateOptions?.isMain) return field.fieldGroup;
    return (
      field?.fieldGroup[0]?.fieldArray?.fieldGroup ||
      field?.fieldGroup[0]?.fieldGroup
    );
  }

  isFieldGroupEmpty(field: any): boolean {
    if (!field?.fieldGroup) return false;
    const fieldGroup = this.getFieldGroup(field) ?? [];
    return fieldGroup.length === 0;
  }

  isEvaluationGroup(field: any) {
    return this.isAEvaluationGroup || (field?.templateOptions?.score ?? false);
  }
}
