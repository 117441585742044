<form
  name="form"
  (ngSubmit)="onSubmit(f)"
  #f="ngForm"
  autocomplete="off"
  class="form"
>
  <div class="p-grid p-fluid p-mb-3">
    <div class="p-col-12">
      <h3 class="p-m-0">{{ 'general.changeReasonHelp' | translate }}</h3>
    </div>
    <div class="p-col-12 p-md-6">
      <label for="changeReason" class="p-mb-1"
        >{{ 'general.changeReason' | translate
        }}<span class="inputRequired"> * </span></label
      >
      <p-dropdown
        id="reasonSelect"
        name="reasonSelect"
        class="dp p-mb-1"
        [options]="reasonsItems"
        [(ngModel)]="selectedReason"
        #reasonSelect="ngModel"
        [required]="true"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        [showClear]="true"
      ></p-dropdown>
      <small
        class="form-error"
        *ngIf="reasonSelect.invalid && (f.submitted || reasonSelect.touched)"
      >
        {{ 'general.required' | translate }}
      </small>
    </div>
    <div class="p-col-12 p-md-6" *ngIf="selectedReason === 'other'">
      <label for="other"
        >{{ 'general.changeReasons.other' | translate
        }}<span class="inputRequired"> * </span></label
      >
      <textarea
        pInputTextarea
        id="otherReason"
        name="otra"
        required="true"
        #otra="ngModel"
        [(ngModel)]="otherReason"
        [autoResize]="true"
      ></textarea>
      <small
        class="form-error"
        *ngIf="!otra.valid && (f.submitted || otra.touched)"
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </div>
  <div class="p-grid p-field p-fluid" style="justify-content: end">
    <div class="p-col-12 p-md-4">
      <div class="p-grid p-field">
        <div class="p-col">
          <button
            class="p-button-secondary"
            (click)="onCancel()"
            pButton
            type="button"
            label="{{ 'general.cancel' | translate }}"
          ></button>
        </div>
        <div class="p-col">
          <button
            pButton
            type="submit"
            label="{{ 'general.accept' | translate }}"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
