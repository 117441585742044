<form name="form" autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="scroll-conciliation temperature-edit">
    <div>
      <div
        *ngIf="
          confTemperature?.showTemperatureRoute &&
          formTemperatureTaken?.value &&
          shouldShowTemperatureRoute
        "
      >
        <div class="p-grid p-ai-center">
          <label id="lbl_route" name="lbl_route" for="typeScaleRoutes">
            {{ 'temperaturerecordlog.route' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <p-dropdown
            id="typeScaleRoutes"
            class="input p-col"
            name="drop_scaleRoutes"
            formControlName="formTemperatureRoute"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            optionLabel="name"
            optionValue="value"
            appendTo="body"
            [options]="routes"
            emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
            emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
            type="text"
            (onChange)="onChangeRoute($event)"
            [required]="
              confTemperature.showTemperatureRoute &&
              formTemperatureTaken?.value &&
              temperatureReconciliationConfig?.allowRouteReconciliation
            "
            [ngClass]="{
              disabled:
                temperatureReconciliationConfig?.allowRouteReconciliation ===
                false
            }"
          >
          </p-dropdown>
          <small
            class="form-error p-col-12"
            *ngIf="
              formTemperatureRoute?.invalid && formTemperatureRoute?.touched
            "
          >
            {{ 'general.required' | translate }}
          </small>
          <div *ngIf="requiredRouteComment" class="p-grid p-col-12 p-mt-3">
            <label
              id="lbl_observationRoute"
              name="lbl_observationRoute"
              for="txt_observationRoute"
            >
              {{ 'conciliation.titleObservation' | translate }}
              <span class="inputRequired"> * </span></label
            >
            <textarea
              id="txt_observationRoute"
              name="txt_observationRoute"
              class="p-col-12"
              pInputTextarea
              type="text"
              [autoResize]="true"
              [required]="requiredRouteComment"
              formControlName="formTemperatureRouteComment"
              placeholder="{{
                'conciliation.placeholderObservation' | translate
              }}"
            ></textarea>
            <small
              class="form-error p-col-12"
              *ngIf="
                formTemperatureRouteComment?.invalid &&
                formTemperatureRouteComment?.touched
              "
            >
              {{ 'general.required' | translate }}</small
            >
          </div>
        </div>
        <div
          class="p-grid p-col-12 p-ai-center"
          *ngIf="formTemperatureRoute?.value === 'OTHER'"
        >
          <label id="lbl_route" name="lbl_route" for="typeScaleRoutes">
            {{ 'temperaturerecordlog.which' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <input
            id="otherRoute"
            name="otherRoute"
            class="p-col-12"
            type="text"
            [required]="
              formTemperatureRoute?.value === 'OTHER' &&
              formTemperatureTaken?.value
            "
            pInputText
            formControlName="formTemperatureWhichOtherRoute"
            [ngClass]="{
              disabled:
                temperatureReconciliationConfig?.allowRouteReconciliation ===
                false
            }"
          />
          <small
            class="form-error p-col-12 p-py-2"
            *ngIf="
              formTemperatureWhichOtherRoute?.invalid &&
              formTemperatureWhichOtherRoute?.touched
            "
          >
            {{ 'general.required' | translate }}</small
          >

          <div *ngIf="requiredOtherComment" class="p-p-0 p-mt-2 p-col-12">
            <label
              id="lbl_otherComment"
              name="lbl_otherComment"
              for="txt_observationOtherComment"
            >
              {{ 'conciliation.titleObservation' | translate }}
              <span class="inputRequired"> * </span></label
            >
            <textarea
              id="txt_observationOtherComment"
              name="txt_observationOtherComment"
              pInputTextarea
              class="p-col-12"
              type="text"
              [autoResize]="true"
              [required]="requiredOtherComment"
              formControlName="formTemperatureWhichOtherRouteComment"
              placeholder="{{
                'conciliation.placeholderObservation' | translate
              }}"
            ></textarea>
            <small
              class="form-error p-col-12"
              *ngIf="
                formTemperatureWhichOtherRouteComment?.invalid &&
                formTemperatureWhichOtherRouteComment?.touched
              "
            >
              {{ 'general.required' | translate }}</small
            >
          </div>
        </div>
      </div>
      <div
        class="p-grid p-ai-center"
        *ngIf="formTemperatureTaken?.value && showTemperatureUnit"
      >
        <label
          id="lbl_temperatureUnit"
          name="lbl_temperatureUnit"
          for="typeScaleTemperature"
        >
          {{ 'temperaturerecordlog.temperatureunit' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <p-dropdown
          id="typeUnitTemperature"
          name="drop_unitTemperature"
          class="input p-col"
          optionLabel="name"
          optionValue="value"
          appendTo="body"
          [options]="units"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          formControlName="formTemperatureUnit"
          (onChange)="changeScaleTemperatureUnit($event)"
          [required]="formTemperatureTaken?.value"
          [ngClass]="{
            disabled:
              (temperatureReconciliationConfig?.allowIntensityReconciliation
                ?.allow === false &&
                !(
                  temperatureRecord?.temperatureUnit === null ||
                  temperatureRecord?.temperatureUnit === undefined
                )) ||
              (temperatureReconciliationConfig?.allowIntensityReconciliation
                ?.allow &&
                temperatureReconciliationConfig?.allowIntensityReconciliation
                  ?.allowedAction !== 'ANY' &&
                !(
                  temperatureRecord?.temperatureUnit === null ||
                  temperatureRecord?.temperatureUnit === undefined
                ))
          }"
        >
        </p-dropdown>
        <small
          class="form-error p-col-12"
          *ngIf="formTemperatureUnit?.invalid && formTemperatureUnit?.touched"
        >
          {{ 'general.required' | translate }}
        </small>
        <div
          *ngIf="requiredUnitComment"
          class="p-grid p-ai-center p-col-12 p-mt-3"
        >
          <label
            id="lbl_observationUnit"
            name="lbl_observationUnit"
            for="txt_observationScale"
          >
            {{ 'conciliation.titleObservation' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <textarea
            id="txt_observationScale"
            name="txt_observationScale"
            class="p-col"
            pInputTextarea
            type="text"
            [required]="requiredUnitComment"
            [autoResize]="true"
            formControlName="formTemperatureUnitComment"
            placeholder="{{
              'conciliation.placeholderObservation' | translate
            }}"
          ></textarea>
          <small
            class="form-error p-col-12"
            *ngIf="
              formTemperatureUnitComment?.invalid &&
              formTemperatureUnitComment?.touched
            "
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div class="p-grid p-ai-center" *ngIf="formTemperatureTaken?.value">
        <label id="lbl_temperature" name="lbl_temperature" for="temperature">
          {{ 'temperaturerecordlog.temperature' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <div class="teperature-range">
          <div
            [ngClass]="{
              'full-width': !isTemperatureDecimalActive,
              'w-50': isTemperatureDecimalActive
            }"
          >
            <p-dropdown
              id="temperature"
              name="drop_indicardor1"
              optionLabel="name"
              optionValue="value"
              class="input p-p-0"
              [required]="formTemperatureTaken?.value"
              appendTo="body"
              [options]="temperatureIndicator1"
              formControlName="selectedIndicador1"
              (onChange)="onTemperatureValueChange()"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              (onChange)="
                onSelectedIndicador1Change(); onChangeTemperature($event)
              "
              [readonly]="
                !form.controls['formTemperatureUnit'].value ||
                (temperatureReconciliationConfig?.allowIntensityReconciliation
                  ?.allow === false &&
                  !(
                    temperatureRecord?.temperature === null ||
                    temperatureRecord?.temperature === undefined
                  ))
              "
            >
            </p-dropdown>
          </div>
          <span class="point" *ngIf="isTemperatureDecimalActive">.</span>
          <div class="w-50" *ngIf="isTemperatureDecimalActive">
            <p-dropdown
              id="temperature"
              name="drop_indicardor2"
              class="input p-p-0"
              optionLabel="name"
              optionValue="value"
              [required]="formTemperatureTaken?.value"
              appendTo="body"
              [options]="temperatureIndicator2"
              formControlName="selectedIndicador2"
              (onChange)="onTemperatureValueChange()"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              (onChange)="onChangeTemperature($event)"
              [readonly]="
                !form.controls['selectedIndicador1'].value ||
                !form.controls['formTemperatureUnit'].value ||
                (temperatureReconciliationConfig?.allowIntensityReconciliation
                  ?.allow === false &&
                  !(
                    temperatureRecord?.temperature === null ||
                    temperatureRecord?.temperature === undefined
                  ))
              "
            >
            </p-dropdown>
          </div>
        </div>
        <small
          class="form-error p-col-12"
          *ngIf="
            (selectedIndicador1?.invalid || selectedIndicador2?.invalid) &&
            (selectedIndicador1?.touched || selectedIndicador2?.touched)
          "
        >
          {{ 'general.required' | translate }}</small
        >
        <div
          *ngIf="requiredTemperatureComment"
          class="p-grid p-ai-center p-col-12 p-mt-3"
        >
          <label
            id="lbl_observationUnit"
            name="lbl_observationUnit"
            for="txt_verifiedTemperatureComment"
            >{{ 'conciliation.titleObservation' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <textarea
            id="txt_verifiedTemperatureComment"
            name="txt_verifiedTemperatureComment"
            class="p-col"
            pInputTextarea
            type="text"
            [autoResize]="true"
            [required]="requiredTemperatureComment"
            formControlName="formTemperatureComment"
            placeholder="{{
              'conciliation.placeholderObservation' | translate
            }}"
          ></textarea>
          <small
            class="form-error p-col-12"
            *ngIf="
              formTemperatureComment?.invalid && formTemperatureComment?.touched
            "
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div
        *ngIf="
          confTemperature?.showTemperatureTakenDate &&
          form.controls['formTemperatureTaken'].value &&
          !(
            temperatureReconciliationConfig?.allowOcurencyDateReconciliation ===
              false && !temperatureRecord?.temperatureTakenDate
          )
        "
      >
        <div class="p-grid p-ai-center">
          <label
            id="lbl_temperatureDate"
            name="lbl_temperatureDate"
            for="fecha"
          >
            {{ 'temperaturerecordlog.temperatureTakenDate' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <div class="p-col">
            <p-calendar
              name="fecha"
              id="fecha"
              [maxDate]="maxDate"
              [minDate]="minDate"
              formControlName="formTemperatureTakenDate"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              [showIcon]="true"
              appendTo="body"
              [showTime]="true"
              [required]="confTemperature?.showTemperatureTakenDate || false"
              placeholder="{{
                'addEditSubjectComponent.PlaceHolderFormatDate' | translate
              }}"
              dateFormat="dd-M-yy"
              [ngClass]="{
                disabled:
                  temperatureReconciliationConfig.allowOcurencyDateReconciliation ===
                  false
              }"
              (onSelect)="onChangeTakenDate($event)"
            ></p-calendar>
            <small
              class="form-error"
              *ngIf="
                form.controls['formTemperatureTakenDate']?.invalid &&
                form.controls['formTemperatureTakenDate']?.touched
              "
            >
              {{ 'general.required' | translate }}
            </small>
          </div>

          <div
            *ngIf="requiredTakenDateComment"
            class="p-grid p-ai-center p-col-12 p-mt-3"
          >
            <label
              id="lbl_observationTakenDate"
              name="lbl_observationTakenDate"
              for="txt_observationTakenDate"
            >
              {{ 'conciliation.titleObservation' | translate }}
              <span class="inputRequired"> * </span></label
            >
            <textarea
              id="txt_observationTakenDate"
              name="txt_observationTakenDate"
              class="p-col"
              pInputTextarea
              type="text"
              [autoResize]="true"
              [required]="requiredTakenDateComment"
              formControlName="formTemperatureTakenDateComment"
              placeholder="{{
                'conciliation.placeholderObservation' | translate
              }}"
            ></textarea>
            <small
              class="form-error p-col-12"
              *ngIf="
                form.controls['formTemperatureTakenDateComment'].invalid &&
                form.controls['formTemperatureTakenDateComment']?.touched
              "
            >
              {{ 'general.required' | translate }}</small
            >
          </div>
        </div>
      </div>
      <div *ngIf="confTemperature?.showTemperatureTaken">
        <div class="p-grid p-ai-center p-jc-between">
          <label
            id="lbl_temperatureTaken"
            name="lbl_temperatureTaken"
            for="dateTaken"
          >
            {{ 'temperaturerecordlog.temperatureTaken' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-selectButton
            id="temperatureTaken"
            name="temperatureTaken"
            [options]="booleanOptions"
            formControlName="formTemperatureTaken"
            (onChange)="onChangeTemperatureTaken($event)"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            [ngClass]="{
              disabled: allowAddOrRemoveRecord()
            }"
          ></p-selectButton>
        </div>
        <div
          *ngIf="
            requiredTakenComment &&
            !(
              !temperatureReconciliationConfig?.allowAddRecords &&
              !currentTemperatureDay?.temperatureRecord?.temperatureTaken
            )
          "
          class="p-grid p-ai-center p-mt-3"
        >
          <label
            id="lbl_observation"
            name="lbl_observation"
            for="txt_observationTaken"
          >
            {{ 'conciliation.titleObservation' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <textarea
            id="txt_observationTaken"
            name="txt_observationTaken"
            class="p-col"
            pInputTextarea
            type="text"
            [autoResize]="true"
            [required]="requiredTakenComment"
            formControlName="formTemperatureTakenComment"
            placeholder="{{
              'conciliation.placeholderObservation' | translate
            }}"
          ></textarea>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['formTemperatureTakenComment']?.invalid &&
              form.controls['formTemperatureTakenComment']?.touched
            "
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div *ngIf="currentTemperatureDay?.isEdition && isTemperatureChange">
        <label
          id="lbl_changeReason"
          name="lbl_changeReason"
          for="txt_changeReason"
          >{{ 'audit.label' | translate }}</label
        >
        <change-reason
          name="temperature_reason"
          [label]="'conciliation.changeReason' | translate"
          formControlName="changeReason"
          [required]="true"
        ></change-reason>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['changeReason']?.invalid &&
            form.controls['changeReason']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
  </div>
  <app-form-buttons
    [isBackDisabled]="
      currentTemperatureDay?.index === 0 || !currentTemperatureDay?.index
    "
    [isNextDisabled]="form.invalid"
    (previousData)="previousDay()"
  ></app-form-buttons>
</form>
