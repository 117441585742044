import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { TranslateService } from '@ngx-translate/core';
import { Analytics, Auth } from 'aws-amplify';
import { BnNgIdleService } from 'bn-ng-idle';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';
import aws_exports from '../aws-exports';
import { ChatService } from './modules/chat/chat.service';
import { ConciliationService } from './modules/conciliation/conciliation.service';
import { ProjectService } from './modules/project/project.service';
import { AuthService } from './services/auth.service';
import { TrialpalService } from './services/trialpal.service';
import { UserPermissionsService } from './services/user-permissions-service';

const logger = new Logger('tp2-logger-root');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'trialpal2-web';
  blockedUI = false;
  blockedUIText = '';
  counter = 60 * 5;
  tick = 1000;
  isAuthenticated = false;
  langs: string[] = [];
  lang: string = '';
  envImage: string = '';
  showLastRouteModal: boolean = false;
  $timerLastRoute: any;
  userLogin$ = this.authService.userAuthenticated;
  constructor(
    private bnIdle: BnNgIdleService,
    private chatService: ChatService,
    private config: PrimeNGConfig,
    private messages: MessageService,
    private projectService: ProjectService,
    private router: Router,
    private translateService: TranslateService,
    private trialpalService: TrialpalService,
    public authService: AuthService,
    private conciliationService: ConciliationService,
    private userPermissionsService: UserPermissionsService
  ) {
    this.translateService.setDefaultLang(
      this.translateService.getBrowserLang()
    );
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
    this.translateService.addLangs(['es', 'en']);

    this.langs = this.translateService.getLangs();
    localStorage.setItem('time', 'login');
  }

  ngOnInit(): void {
    this.changeCounterValueByEnv(3600); //cambiar tiempo para la session en develop
    this.trialpalService.spinnerSubject.subscribe((input: any) => {
      Promise.resolve().then(() => (this.blockedUI = input.show));
      Promise.resolve().then(() => (this.blockedUIText = input.text));
    });
    this.lang = localStorage.getItem('lang') ?? 'es';
    localStorage.setItem('lang', this.lang);
    this.translateService.use(this.lang);

    //Verifica si el usuario tiene una cuenta activa en otra pestaña
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        this.loginUser(user);
      })
      .catch(() => this.authService.logout());

    this.isAuthenticated = this.authService.isAuthenticated();
    this.authService.userAuthenticated.subscribe((status) => {
      this.isAuthenticated = status;
    });

    //Muestra modal para pregunta si quiere volver a la ultima ruta ingresada
    this.authService.$postLoginSession.subscribe(() => {
      this.showLastRouteDialog();
      clearTimeout(this.$timerLastRoute);
      this.$timerLastRoute = setTimeout(() => {
        this.showLastRouteModal = false;
        this.authService.postLoginSession = false;
        //Si no acepta guarda la ruta actual como la ultima
        this.authService.setLastRoute(this.router.url);
      }, 15000);
    });

    if (this.authService.isAuthenticated()) {
      this.authService.listenUserEvents();
      this.chatService.initSiteChatSubscriptions();
    }

    //Llama a la función que está a la escucha de los cambios en el localStorage
    this.checkOtherTabLogout();

    //Inicia la suscripcion de traduccionde primeNg
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
    this.initSessionTimer();
    this.envImage = this.getEnvironmentImage();

    //Inicia el auto tracking de las paginas
    Analytics.autoTrack('pageView', {
      enable: true,
      eventName: 'site_page_views',
      type: 'SPA',
      provider: 'AWSPinpoint',
      getUrl: () => {
        const urlTree = this.router.parseUrl(this.router.url);
        return urlTree.root.children['primary']?.segments
          .map((it) => {
            if (
              !it.path.match(
                '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
              )
            ) {
              return it.path;
            } else {
              return '*';
            }
          })
          .join('/');
      },
    });
  }

  getClosableValue() {
    return this.trialpalService.closable;
  }

  changeCounterValueByEnv(time: number) {
    try {
      const envName = this.getCurrentEnvironment();
      if (
        (envName && (envName === 'develop' || envName === 'newdev')) ||
        window.location.href.includes('localhost')
      ) {
        this.counter = time;
      }
    } catch (e) {
      logger.debug('changeCounterValueByEnv ', e);
    }
  }

  //Obtiene el ambiente de la aplicación
  getCurrentEnvironment() {
    try {
      const restEndpoint = aws_exports.aws_cloud_logic_custom[0].endpoint;
      const envName = restEndpoint.split('amazonaws.com/')[1];
      return envName;
    } catch (e) {
      logger.debug('getCurrentEnvironment ', e);
      return '';
    }
  }

  getEnvironmentImage() {
    const env = this.getCurrentEnvironment();
    switch (env) {
      case 'newdev':
        return '../assets/imgs/Banda-DEV.png';
      case 'newrelease':
        return '../assets/imgs/Banda-QA.png';
      case 'newuat':
        return '../assets/imgs/Banda-UAT.png';
      default:
        return '';
    }
  }

  /**
   * Sirve para iniciar el timmer del tiempo de sesion.
   */
  private initSessionTimer() {
    //Escucha cuando han transcurrido 5 minutos de inactividad
    this.bnIdle
      .startWatching(this.counter)
      .subscribe(async (isTimedOut: boolean) => {
        if (isTimedOut) {
          //Si han transcurrido 5 minutos de inactividad y el usuario esta autenticado, se hace logout
          if (this.authService.isAuthenticated()) {
            if (this.router.url.includes('conciliation')) {
              await this.conciliationService.conciliationUnLock();
            }
            this.userPermissionsService.unsusbcribeUserAuth();
            this.authService.logout();
            this.messages.add({
              closable: true,
              life: 15000,
              severity: 'warn',
              data: this.translateService.instant('general.sessionexpired'),
              detail: this.translateService.instant(
                'general.sessionExpirednow'
              ),
            });
          }
          this.chatService.unsusbcribeChats();
        }
      });
  }

  showLastRouteDialog() {
    const $router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const user = this.authService.getUsername();
        const userLastRoute = this.authService.getUserLastRoute(user);
        if (userLastRoute && event.url !== userLastRoute) {
          this.showLastRouteModal = true;
        }
        $router.unsubscribe();
      });
  }

  goToLastRoute() {
    const user = this.authService.getUsername();
    const userLastRoute = this.authService.getUserLastRoute(user);
    this.router.navigateByUrl(userLastRoute);
    this.showLastRouteModal = false;
    clearTimeout(this.$timerLastRoute);
  }

  loginUser(response: any): void {
    this.projectService.cleanListProjects();
    this.authService.user = response;
    if (response.getSignInUserSession()) {
      //Cuando el usuario ingresa o reinicia la pagina se reinicia el tiempo de inactividad
      this.bnIdle.resetTimer();
      this.authService.setUserInLocalStorage(response);
      this.chatService.initSiteChatSubscriptions();
      this.authService.endpointUpdatingFlow();
      this.authService.showTermsAndCondition().then();
    }
  }

  private checkOtherTabLogout() {
    //Está a la escucha si se cerro sesión en otro tab
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let logoutOtherTab = localStorage.getItem('logout');
        //Si la sessión se ha cerrado, se cerra la pagína en todas las tabs
        if (logoutOtherTab === 'true') {
          if (logoutOtherTab) {
            sessionStorage.clear();
            localStorage.removeItem('logout');
            this.authService.userAuthenticated.next(false);
            this.router.navigate(['/login']);
            this.messageSession();
          }
        }
      }
    });
  }

  messageSession() {
    this.messages.add({
      closable: true,
      life: 15000,
      severity: 'warn',
      data: this.translateService.instant('general.sessionexpired'),
      detail: this.translateService.instant('general.sessionExpiredTab'),
    });
  }
}
