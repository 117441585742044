import { Injectable } from '@angular/core';
import { API, Logger, graphqlOperation } from 'aws-amplify';
import { Observable } from 'rxjs';
import { CONCILIATION_QUERIES } from 'src/app/modules/conciliation/conciliation.queries';
import {
  CreateVerifiedDayInstanceInput,
  DayInstance,
  InstanceState,
  UpdateVerifiedDayInstanceInput,
  VerifiedDayInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import { AuthService } from '../../../../../../services/auth.service';
import { TableDayInstanceComponent } from './table-day-instance/table-day-instance.component';
const logger = new Logger('dayInstance service');
@Injectable({
  providedIn: 'root',
})
export class DayInstanceService {
  currentDayInstance: any = {};
  verifiedDayInstances: any[] = [];
  constructor(private authService: AuthService) {}
  async createVerifiedDayInstance(
    data: any,
    dayInstance: any,
    isAutomatiReview: boolean
  ) {
    dayInstance = this.clearDayInstanceTypeName(dayInstance);
    let input: CreateVerifiedDayInstanceInput = {
      dayInstanceId: dayInstance.id,
      _lastUser: this.authService.getUsername(),
      subjectId: dayInstance.subjectId,
      eDiaryPhaseInstanceId: dayInstance.eDiaryPhaseInstanceId,
      confDayId: dayInstance.confDayId,
      siteId: dayInstance.siteId,
      hasMedicalAttention: dayInstance.hasMedicalAttention ?? false,
      hasMedicalAttentionCompleted: isAutomatiReview,
      hasMedication: dayInstance.hasMedication ?? false,
      hasMedicationCompleted: isAutomatiReview,
      hasMedicalAttentionComment: dayInstance?.hasMedicalAttentionComment,
      hasMedicationComment: dayInstance?.hasMedicationComment,
      hasOtherSymptoms: dayInstance.hasOtherSymptoms ?? false,
      hasOtherSymptomsCompleted: isAutomatiReview,
      hasOtherSymptomsComment: dayInstance?.hasOtherSymptomsComment,
      medicationGivenTo: dayInstance.medicationGivenTo,
      medicationGivenToComment: dayInstance?.medicationGivenToComment,
      symptomOcurrenciesComment: dayInstance?.symptomOcurrenciesComment,
      symptomOcurrencies: null,
      startDate: dayInstance?.startDate,
      finishDate: dayInstance?.finishDate,
      state: InstanceState.UNSIGNED,
      completedDate: null,
    };

    input = Object.assign(input, data);

    logger.debug(input, 'DayInstance Create');
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateVerifiedDayInstance,
      { input }
    );

    return response.data.createVerifiedDayInstance;
  }

  clearDayInstanceTypeName(dayInstance: DayInstance) {
    dayInstance.symptomOcurrencies = dayInstance.symptomOcurrencies?.map(
      (occurrency: any) => {
        delete occurrency.__typename;
        return occurrency;
      }
    );

    return dayInstance;
  }

  async updateVerifiedDayInstance(data: any, verifiedDayInstance: any) {
    const verified = this.verifiedDayInstances.find((VDI: any) => {
      return VDI.id === verifiedDayInstance.id;
    });

    let input: UpdateVerifiedDayInstanceInput = {
      id: verifiedDayInstance.id,
      _lastUser: this.authService.getUsername(),
      state: InstanceState.UNSIGNED,
      _version: verified?._version,
    };

    input = Object.assign(input, data);

    logger.debug(input, 'DayInstance Updated');

    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateVerifiedDayInstance,
      { input }
    );

    return response.data.updateVerifiedDayInstance;
  }

  async getVerifiedDayInstanceByDayInstanceId(id: string) {
    //Busca primero en el arreglo
    let verifiedDayInstance = this.verifiedDayInstances?.find(
      (dayInstance: any) => {
        return dayInstance.dayInstanceId === id;
      }
    );

    if (!verifiedDayInstance) {
      const response = await this.performGraphQLQuery(
        CONCILIATION_QUERIES.VerifiedDayInstanceByDayInstanceIdIdCustom,
        { dayInstanceId: id }
      );
      const { items } = response.data.verifiedDayInstancesByDayInstanceId;
      verifiedDayInstance = items?.length > 0 ? items[0] : null;
    }

    return verifiedDayInstance;
  }

  async fillVerifiedDayInstances(
    dayInstance: any,
    isAutomatiReview: boolean,
    verifiedDayInstances: any[] = []
  ) {
    let verifiedDayInstance = verifiedDayInstances.find(
      (verifiedDayInstance: any) => {
        return verifiedDayInstance.dayInstanceId === dayInstance.id;
      }
    );
    if (!verifiedDayInstance) {
      verifiedDayInstance = await this.createVerifiedDayInstance(
        {},
        dayInstance,
        isAutomatiReview
      );
    } else if (isAutomatiReview) {
      verifiedDayInstance = await this.completeVerifiedDayInstance(
        verifiedDayInstance
      );
    }
    this.verifiedDayInstances.push(verifiedDayInstance);
  }

  /**
   * Completa las secciones de atención médica, medicamentos y otros síntomas de una instancia de día verificada.
   * Si alguna de estas secciones no está completada, actualiza la instancia de día verificada para marcarlas como completadas.
   *
   * @param verifiedDayInstance - La instancia de día verificada a revisar y posiblemente actualizar.
   * @returns La instancia de día verificada, posiblemente actualizada.
   */
  async completeVerifiedDayInstance(
    verifiedDayInstance: VerifiedDayInstance
  ): Promise<VerifiedDayInstance> {
    if (
      !verifiedDayInstance.hasMedicalAttentionCompleted ||
      !verifiedDayInstance.hasMedicationCompleted ||
      !verifiedDayInstance.hasOtherSymptomsCompleted
    ) {
      return await this.updateVerifiedDayInstance(
        {
          hasMedicalAttentionCompleted: true,
          hasMedicationCompleted: true,
          hasOtherSymptomsCompleted: true,
          _version: verifiedDayInstance._version,
        },
        verifiedDayInstance
      );
    }

    return verifiedDayInstance;
  }

  updateListVerifiedDayInstances(verifiedDayInstance: any) {
    this.verifiedDayInstances = this.verifiedDayInstances?.map((VDI: any) => {
      return VDI?.id === verifiedDayInstance?.id ? verifiedDayInstance : VDI;
    });
  }

  async updateVerifiedDayInstanceByDeleteInstances(
    dayInstanceId: string,
    instance: string,
    verifiedInstance: string,
    input: any,
    tableInstances: any[],
    tableDayInstanceComponent: TableDayInstanceComponent
  ): Promise<any> {
    //Obtiene todos los elementos asociados al dia
    let fullInstances = tableInstances.filter((conciliationInstance: any) => {
      return conciliationInstance[instance]?.dayInstanceId === dayInstanceId;
    });

    //Obtiene los elementos borrados del día
    let instancesDeleted = fullInstances.filter((conciliationInstance: any) => {
      return (
        conciliationInstance[verifiedInstance]?.state === InstanceState.DELETED
      );
    });

    //Si todos las instancias estan eliminadas la precondicion del dia pasa a ser false
    if (fullInstances.length === instancesDeleted.length) {
      let verifiedDayInstance: any =
        await this.getVerifiedDayInstanceByDayInstanceId(dayInstanceId);

      const isMedication = instance === 'medication';
      verifiedDayInstance = await this.updateVerifiedDayInstance(
        input,
        verifiedDayInstance
      );

      //Actualiza la tabla de los días
      this.updateListVerifiedDayInstances(verifiedDayInstance);
      const dayIndex = tableDayInstanceComponent.colDays.findIndex(
        (day: any) => {
          return day?.verifiedDayInstance?.id === verifiedDayInstance.id;
        }
      );

      if (dayIndex !== -1) {
        tableDayInstanceComponent.colDays[dayIndex].verifiedDayInstance =
          verifiedDayInstance;
        if (isMedication) {
          return this.updateMedicationComments(
            tableDayInstanceComponent.colDays
          );
        }
        let hasInstance = 'hasOtherSymptoms';
        if (instance === 'medicalAttention') {
          hasInstance = 'hasMedicalAttention';
        }
        this.updateCustomComments(
          tableDayInstanceComponent.colDays,
          hasInstance
        );
      }
    }
  }

  updateMedicationComments(colDays: any) {
    let index = 0;
    for (let colDay of colDays) {
      if (colDay.dayInstance !== '') {
        const verified = colDay?.verifiedDayInstance;
        if (verified?.hasMedicationComment) index++;
        //Agrega el index
        colDay.hasMedicationRow.commentIndex = verified?.hasMedicationComment
          ? index
          : null;
        //Agrega el comentario
        colDay.hasMedicationRow.comment = verified?.hasMedicationComment;
        colDay.hasMedicationRow.verified = verified?.hasMedication;

        if (verified?.medicationGivenToComment) index++;
        //Agrega el index
        colDay.medicationGivenToRow.commentIndex =
          verified?.medicationGivenToComment ? index : null;
        //Agrega el comentario
        colDay.medicationGivenToRow.comment =
          verified?.medicationGivenToComment;

        colDay.medicationGivenToRow.verified = verified?.medicationGivenTo;
      }
    }
  }

  updateCustomComments(colDays: any[], hasInstance: string) {
    let index = 0;
    for (let colDay of colDays) {
      if (colDay.dayInstance !== '') {
        const verified = colDay?.verifiedDayInstance;
        if (verified) {
          if (verified[hasInstance + 'Comment']) index++;
          colDay[hasInstance + 'Row'].commentIndex = verified[
            hasInstance + 'Comment'
          ]
            ? index
            : null;
          colDay[hasInstance + 'Row'].comment =
            verified[hasInstance + 'Comment'];
          colDay[hasInstance + 'Row'].verified = verified[hasInstance];
        }
      }
    }
  }

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
