import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { ProjectService } from '../project.service';
import { ConfVisitGroup } from '../project.types';
const logger = new Logger('tp2-logger-visitGroupConfPage');

@Component({
  selector: 'app-visits-groups',
  templateUrl: './visits-groups.component.html',
  styleUrls: ['./visits-groups.component.scss'],
})
export class VisitsGroupsComponent {
  project: any = {};
  phase: string = '';
  phases: any[] = [];
  group: string = '';
  groups: any[] = [];
  listVisitGroup: ConfVisitGroup[] = [];
  visible: boolean = false;
  hasDeleteGroupVisitPermission: boolean = false;
  hasCreateGroupVisitPermission: boolean = false;
  hasConfigVisitVisibilityPermission: boolean = false;
  constructor(
    private projectService: ProjectService,
    private trialpalService: TrialpalService,
    public authService: AuthService,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    public userPermissionsService: UserPermissionsService
  ) {
    const id = this.config?.data?.id;
    this.project = this.config?.data?.project;
    this.getConfVisitGroup(id);
    this.setPermissions();
  }

  async setPermissions(): Promise<void> {
    this.hasCreateGroupVisitPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectCreateGroupVisit,
        'Admin',
      ]);
    this.hasDeleteGroupVisitPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectDeleteGroupVisit,
        'Admin',
      ]);
    this.hasConfigVisitVisibilityPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.ProjectConfigVisitVisibility,
        'Admin',
      ]);
  }

  getConfVisitGroup(id: string): void {
    this.projectService.getConfVisitGroup(id).then((response: any) => {
      this.listVisitGroup = response;
    });

    //Traduce las visitas segun su llave
    this.phases = this.project.phases.map((x: string) => ({
      value: x,
      name: this.trialpalService.dictionaryPipe.transform(x),
    }));

    //Traduce los grupos segun su llave
    this.groups = this.project.groups.map((x: string) => ({
      value: x,
      name: this.trialpalService.dictionaryPipe.transform(x),
    }));
  }

  async addPhase(form: NgForm) {
    if (form.valid) {
      const { group, phase, visible } = form.value;
      const _lastUser = this.authService.getUsername();
      const existVisitGroup = this.listVisitGroup.find(
        (x: any) => x.group === group && x.visit === phase
      );

      //Si la visita no está asociada al grupo, se puede crear, si no mostrará mensaje de error
      if (!existVisitGroup) {
        this.projectService
          .addGroupVisit(group, phase, visible, this.project.id, _lastUser)
          .then((response: any) => {
            this.listVisitGroup.push(response);
            this.messageSuccess('visitGroupSuccefully');
          });
      } else {
        this.messageWarn('visitGroupExist', group);
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  //Obtiene las visitas correspondientes al grupo seleccionado
  getPhases(groupName: string) {
    return this.listVisitGroup.filter(
      (visitGroup: any) => visitGroup.group === groupName
    );
  }

  //Edita la visibilidad de una visita
  editVisitGroup(visit: any) {
    visit.isVisible = !visit.isVisible;
    this.projectService
      .editVisitGroup(visit)
      .then((data: ConfVisitGroup) => {
        //Actualiza la visita en el arreglo de asociación
        this.listVisitGroup = this.listVisitGroup.map(
          (visitGroup: ConfVisitGroup) => {
            return visitGroup.id === visit.id ? data : visitGroup;
          }
        );
        this.messageSuccess('visitGroupUpdate');
      })
      .catch((error) => logger.error(error));
  }

  //Borra la visita de un grupo
  deleteVisitGroup(visit: any) {
    //Borra de la base de datos la visita vinculada al grupo
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'general.confirmDelete'
      ),
      accept: () => {
        this.projectService
          .deleteVisitGroup(visit.id, visit._version)
          .then(() => {
            //Borra del arreglo la visita vinculada al grupo
            this.listVisitGroup = this.listVisitGroup.filter(
              (x: any) => x.id !== visit.id
            );
            this.messageSuccess('visitGroupDelete');
          })
          .catch((error) => logger.error(error));
      },
    });
  }

  messageWarn(detailKey: string, group: string) {
    this.trialpalService.messageService.clear();
    group = this.trialpalService.dictionaryPipe.transform(group);
    this.trialpalService.messageService.add({
      severity: 'warn',
      life: 3000,
      detail: this.trialpalService.translateService.instant(
        'project.' + detailKey,
        {
          group: group,
        }
      ),
    });
  }

  messageSuccess(detailKey: string) {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'success',
      life: 3000,
      detail: this.trialpalService.translateService.instant(
        'project.' + detailKey
      ),
    });
  }

  close() {
    this.ref.close();
  }

  getIcon(visible: boolean) {
    return visible ? 'pi pi-eye' : 'pi pi-eye-slash';
  }
}
