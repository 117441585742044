<p-table [value]="to.questions" styleClass="p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr class="header">
      <th id="empty"></th>
      <th
        *ngFor="
          let option of to.options | formlySelectOptions: field | async;
          let index
        "
        [id]="'headerOption_' + index"
      >
        <div class="p-d-flex p-justify-center p-text word-break">
          <span class="text-align"> {{ option.label | dictionary }}</span>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-question let-index>
    <tr>
      <td>
        <label
          style="font-weight: 700"
          class="p-mb-2 p-d-block font-4 word-break"
        >
          {{ question.label | dictionary }}
          <span *ngIf="question.required" style="color: red">*</span>
          <span
            *ngIf="question['tooltip']?.trim()?.length > 0"
            [pTooltip]="question['tooltip'] | dictionary"
            tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
            class="pi pi-question-circle p-ml-2 cursor-pointer"
            style="color: var(--primary-color)"
          ></span>
        </label>
      </td>
      <td
        *ngFor="
          let option of to.options | formlySelectOptions: field | async;
          let optionIndex = index
        "
      >
        <form [formGroup]="formRadioField" class="p-d-flex p-justify-center">
          <p-radioButton
            inputId="{{
              question.label + option.value + field.key + index + optionIndex
            }}"
            [value]="option.value"
            [formlyAttributes]="field"
            [name]="question.label"
            [formControlName]="question.label"
            [required]="to.required ?? false"
            [disabled]="to.disabled ?? false"
            (click)="saveFormControlValues()"
          ></p-radioButton>
        </form>
      </td>
    </tr>
  </ng-template>
</p-table>
