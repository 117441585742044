<section style="overflow-y: hidden; padding: 0">
  <div #outsideElement id="PDF" *ngIf="!disablePrint">
    <div class="modal-view-container">
      <div #modalView class="modal view">
        <div class="modal-view-details">
          <object
            [data]="storagePdfUrl"
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <iframe
              title=""
              width="100%"
              height="100%"
              onerror="errorLoad()"
              [src]="storagePdfUrl"
            >
            </iframe>
          </object>
        </div>
      </div>
    </div>
  </div>

  <div
    id="outerContainer"
    class="no-print container-color"
    *ngIf="disablePrint"
  >
    <div class="container-color">
      <nav>
        <div class="pdf-controls">
          <a>{{ pdfProperties.pdfName }}</a>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-minus"
            (click)="zoomOut()"
            class="p-button-rounded p-button-text rm-border-button"
            [disabled]="pdfProperties.zoomLevel <= 0.25"
          ></button>
          <input
            type="text"
            id="zoomInput"
            [value]="getZoomValue()"
            [disabled]="true"
          />
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            (click)="zoomIn()"
            class="p-button-rounded p-button-text rm-border-button"
            [disabled]="pdfProperties.zoomLevel >= 2.5"
          ></button>
          <button
            *ngIf="showDownloadButton"
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            (click)="download()"
            class="p-button-rounded p-button-text rm-border-button"
          ></button>
          <button
            *ngIf="showPrintButton"
            pButton
            pRipple
            type="button"
            icon="pi pi-print"
            (click)="print()"
            class="p-button-rounded p-button-text rm-border-button"
          ></button>
        </div>
      </nav>
      <pdf-viewer
        [src]="pdfSrc"
        [original-size]="false"
        [render-text]="true"
        [render-text-mode]="0"
        [rotation]="0"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="pdfProperties.zoomLevel"
        [zoom-scale]="'page-width'"
        [stick-to-page]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="true"
        class="pdf-viewer"
      ></pdf-viewer>
    </div>
  </div>
</section>
