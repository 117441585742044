<div class="add-day p-mb-3 p-d-flex p-jc-end">
  <button
    id="btn_addDay"
    name="btn_addDay"
    class="p-button-secondary"
    pButton
    type="button"
    label="{{ 'general.add' | translate }}"
    (click)="newOtherSymptom()"
  ></button>

  <button
    *ngIf="showDeleteButton()"
    id="btn_delete"
    pButton
    type="button"
    class="p-button-danger p-ml-3"
    icon="pi pi-trash"
    iconPos="left"
    (click)="confirmDeleteOtherSymptom()"
  ></button>
</div>
<div class="symptom-conciliation">
  <app-table-day-instance
    [instance]="'OTHER_SYMPTOM'"
    (emitData)="isOtherSymptomDaysLoading($event)"
  ></app-table-day-instance>
  <p-table
    [value]="symptomService.tableOtherSymptoms"
    *ngIf="symptomService.tableOtherSymptoms.length > 0"
    styleClass="p-datatable-gridlines"
    responsiveLayout="scroll"
    class="p-pb-3 otherSymptom-conciliation"
  >
    <ng-template pTemplate="header">
      <tr>
        <th id="medicineName">
          {{ 'symptominstance.symptomIllness' | translate }}
        </th>
        <th id="medicineName">
          {{ 'symptominstance.intensity' | translate }}
        </th>
        <th id="medicineName">
          {{ 'otherSymptom.professionalHealthCare' | translate }}
        </th>
        <th id="medicineName">
          {{ 'otherSymptom.admisionHours' | translate }}
        </th>
        <th id="medicineName">
          {{ 'symptominstance.startDate' | translate }}
        </th>
        <th id="medicineName">
          {{ 'symptominstance.endDate' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-otherSymptom>
      <tr
        class="header"
        [ngClass]="{
          'header-select':
            (symptomService.currentOtherSymptom?.otherSymptom?.id ===
              otherSymptom.otherSymptom.id &&
              otherSymptom.otherSymptom.id !== '123456789') ||
            (symptomService.currentOtherSymptom?.verifiedOtherSymptom?.id ===
              otherSymptom?.verifiedOtherSymptom?.id &&
              otherSymptom.otherSymptom.id === '123456789')
        }"
        (click)="paginationService.selectedOtherSymptom(otherSymptom)"
      >
        <td>
          <div class="grid-c col-c-2">
            <app-check
              [isConciliated]="otherSymptom?.verifiedOtherSymptom?.id"
              [verifiedInstance]="otherSymptom?.verifiedOtherSymptom"
            ></app-check>
            <span>
              <span
                [innerHTML]="
                  otherSymptom?.symptomName
                    | textNa
                      : otherSymptom?.verifiedSymptomName
                      : 'translateSymptom'
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="otherSymptom.comments?.whichOtherSymptomComment"
                [pTooltip]="otherSymptom.comments?.whichOtherSymptomComment"
              >
                {{ otherSymptom.comments.whichOtherSymptomCommentIndex }}
              </sup>
            </span>
          </div>
        </td>

        <td>
          <div>
            <span
              [innerHTML]="
                otherSymptom.otherSymptom.intensity
                  | textNa
                    : otherSymptom.verifiedOtherSymptom?.intensity
                    : 'intensity'
              "
            >
            </span>
            <sup
              class="p-ml-1"
              *ngIf="otherSymptom.comments?.intensityComment"
              [pTooltip]="otherSymptom.comments?.intensityComment"
            >
              {{ otherSymptom.comments.intensityCommentIndex }}
            </sup>
          </div>
        </td>

        <td>
          <div>
            <span
              [innerHTML]="
                otherSymptom.otherSymptom.professionalHealthCare
                  | textNa
                    : otherSymptom.verifiedOtherSymptom?.professionalHealthCare
                    : 'translateRVP'
              "
            >
            </span>
            <sup
              class="p-ml-1"
              *ngIf="otherSymptom.comments?.professionalHealthCareComment"
              [pTooltip]="otherSymptom.comments?.professionalHealthCareComment"
            >
              {{ otherSymptom.comments.professionalHealthCareCommentIndex }}
            </sup>
          </div>
        </td>
        <td>
          <div>
            <span
              [innerHTML]="
                otherSymptom.otherSymptom.hospitalAdmission
                  | textNa
                    : otherSymptom.verifiedOtherSymptom?.hospitalAdmission
                    : 'translateRVP'
              "
            >
            </span>
            <sup
              class="p-ml-1"
              *ngIf="otherSymptom.comments?.hospitalAdmissionComment"
              [pTooltip]="otherSymptom.comments?.hospitalAdmissionComment"
            >
              {{ otherSymptom.comments.hospitalAdmissionCommentIndex }}
            </sup>
          </div>
        </td>

        <td>
          <div>
            <span
              [innerHTML]="
                otherSymptom.otherSymptom.startDate
                  | textNa
                    : otherSymptom.verifiedOtherSymptom?.startDate
                    : 'date'
                    : false
                    : otherSymptom.verifiedOtherSymptom?.startDateComment
              "
            ></span>
            <sup
              class="p-ml-1"
              *ngIf="otherSymptom.comments?.startDateComment"
              [pTooltip]="otherSymptom.comments?.startDateComment"
            >
              {{ otherSymptom.comments.startDateCommentIndex }}
            </sup>
          </div>
        </td>

        <td>
          <div>
            <span
              [innerHTML]="
                otherSymptom.otherSymptom.finishDate
                  | textNa
                    : otherSymptom.verifiedOtherSymptom?.finishDate
                    : 'date'
                    : false
                    : otherSymptom.verifiedOtherSymptom?.finishDateComment
              "
            ></span>
            <sup
              class="p-ml-1"
              *ngIf="otherSymptom.comments?.finishDateComment"
              [pTooltip]="otherSymptom.comments?.finishDateComment"
            >
              {{ otherSymptom.comments.finishDateCommentIndex }}
            </sup>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="p-grid p-col-12 p-pt-3"
    *ngIf="
      symptomService.otherSymptomsComments &&
      symptomService.otherSymptomsComments.length > 0
    "
  >
    <label class="p-col-12">{{
      'conciliation.titleObservation' | translate
    }}</label>
    <div>
      <ul class="p-d-flex p-flex-row p-flex-wrap">
        <div
          class="p-pl-3"
          *ngFor="let comment of symptomService.otherSymptomsComments"
        >
          <li *ngIf="comment?.comment">
            <label>{{ comment?.index }}. </label
            ><span style="word-break: break-word">{{ comment?.comment }}</span>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>

<div
  class="p-grid p-fluid"
  *ngIf="
    symptomService.tableOtherSymptoms &&
    symptomService.tableOtherSymptoms.length === 0 &&
    symptomService.otherSymptomColDays &&
    symptomService.otherSymptomColDays.length === 1
  "
>
  <h4 class="notResult">
    {{ 'general.noResultsFound' | translate }}
  </h4>
</div>
