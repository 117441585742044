<div
  *ngFor="let field of field.fieldGroup; let index = index"
  class="p-d-flex p-flex-column card"
>
  <formly-field [field]="field"></formly-field>
  <button
    pButton
    type="button"
    class="btn btn-danger"
    icon="pi pi-trash"
    style="width: max-content; border: none"
    [label]="to?.removeText | dictionary"
    (click)="remove(index)"
    *ngIf="fieldGroupSize > 1"
  ></button>
</div>

<button
  pButton
  type="button"
  class="btn btn-primary"
  [ngClass]="{ 'pointer-events-none': formControl.invalid }"
  icon="pi pi-plus"
  style="width: max-content"
  [label]="to?.addButtonText | dictionary"
  (click)="add()"
  [disabled]="formControl.invalid"
  *ngIf="to?.addButtonText"
></button>
