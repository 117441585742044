import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';
import {
  CALCULATE_TYPES,
  FormConfiguration,
  OPERATORS,
  PDF_VIEW_OPTIONS,
} from '../../../interfaces/formly-designer.interface';
import { DesignerField } from '../../field-types';
const logger = new Logger('AddEditFieldComponent');
@Component({
  selector: 'app-add-edit-field',
  templateUrl: './add-edit-field.component.html',
  styleUrls: ['./add-edit-field.component.scss'],
})
export class AddEditFieldComponent implements OnInit {
  @ViewChild('form') form!: NgForm;
  @ViewChild('fileImage') fileImage!: ElementRef;
  field: any = {};
  projectId: string = '';
  UPLOAD_PATH: string = '';
  group: any = {};
  min: number = -Infinity;
  max: number = Infinity;
  minLength: number = 0;
  maxLength: number = 999999;

  formConfiguration: FormConfiguration = {
    showDataTab: true,
    showDisplayTab: true,
    showConditionalTab: true,
    showAvancedTab: true,
    showValidationTab: true,
    isRepeatField: undefined,
    isEvaluationSection: false,
    isGroupField: false,
    inputsVisibility: {
      defaultValue: true,
      placeholder: true,
      required: true,
      textTypes: true,
      tooltip: true,
      score: false,
      repeat: false,
      min: false,
      max: false,
      minLength: false,
      maxLength: false,
      pdfViewType: false,
    },
    operatorTypes: [],
    booleanTypes: [],
    calculateTypes: [],
    textTypes: [],
    imageTypes: [],
    fieldsInGroupTypes: [],
    pdfViewTypes: [],
    conditional: {
      field: { key: '', type: '', options: [], min: this.min, max: this.max },
      operator: null,
      value: '',
    },
  };

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private trialpalService: TrialpalService,
    private dictionaryPipe: DictionaryPipe,
    private s3Storage: StorageService
  ) {
    this.projectId = this.config.data?.projectId;
    this.UPLOAD_PATH = 'conf-report/' + this.projectId + '/designer/';
  }

  async ngOnInit() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant('general.loadingInfo')
    );
    const field = JSON.parse(JSON.stringify(this.config.data.field));
    this.field = this.getField(field);
    this.formConfiguration.isRepeatField = this.getIsRepetible(field);
    //Funciones necesarias para llenar los campos tipo dropdown
    this.fillBooleanTypes();
    this.fillCalculateTypes();
    this.fillOperatorTypes();
    this.fillFieldInGroupTypes();
    this.fillFieldConditional();
    this.fillTextTypes();
    this.fillPdfViewTypes();

    //Valida si es un campo de titulo o subtitulo
    const isTitleField = this.getIsTitleField(this.field);

    //Funciones para validar que campos están activos
    this.showComponents(isTitleField);

    //Solo agrega las imagenes si es un campo de tipo imagenes
    if (this.field?.templateOptions?.images) {
      await this.fillImageTypes();
    }
    logger.debug('field', field);
    this.trialpalService.hideSpinner();
  }

  getIsRepetible(field: any): boolean | undefined {
    if (this.formConfiguration.isGroupField) {
      if (!field?.fieldGroup[0]?.key) return undefined;
      return Boolean(field?.fieldGroup[0].fieldArray);
    }

    return false;
  }

  //Funciones para mostrar los componentes dentro de formulario de creación/edición
  showComponents(isTitleField: boolean) {
    const showDefaultValue = this.getShowDefaultValue(this.field);
    const isGroupField = this.formConfiguration.isGroupField;

    this.showTabs(isTitleField, isGroupField);
    this.showInputs(isTitleField, isGroupField, showDefaultValue);
  }

  //Indica que tabs del componente se van a mostrar
  showTabs(isTitleField: boolean, isGroupField: boolean): void {
    const showFormData = this.getShowFormData(this.field);
    const showFormValidation = this.getShowFormValidation(this.field);

    this.formConfiguration.showDataTab = showFormData && !isGroupField;
    this.formConfiguration.showConditionalTab = !isTitleField;
    this.formConfiguration.showValidationTab =
      !isGroupField && showFormValidation;
    this.formConfiguration.showAvancedTab = !isTitleField;
  }

  //Indica que inputs estarán activos dependiendo del tipo de campo
  showInputs(
    isTitleField: boolean,
    isGroupField: boolean,
    showDefaultValue: boolean
  ): void {
    const inputsVisibility = this.formConfiguration.inputsVisibility;
    const showPlaceholder = this.showPlaceholder(this.field);
    const showCalculateTypes = this.showCalculateTypes(this.field);

    inputsVisibility.defaultValue = showDefaultValue && !isGroupField;
    inputsVisibility.textTypes = isTitleField && !isGroupField;
    inputsVisibility.placeholder = showPlaceholder && !isGroupField;
    inputsVisibility.required = !isTitleField && !isGroupField;
    inputsVisibility.tooltip = !isTitleField && !isGroupField;
    inputsVisibility.score = this.config.data.principalGroup && isGroupField;
    inputsVisibility.repeat = isGroupField;
    this.formConfiguration.isEvaluationSection =
      this.config.data.isAEvaluationGroup && showCalculateTypes;

    const showMinMaxWord = this.showMinAndMaxWordInput(this.field);
    const showMinMaxNumber = this.showMinMaxNumberInput(this.field);
    inputsVisibility.min = showMinMaxNumber;
    inputsVisibility.max = showMinMaxNumber;
    inputsVisibility.minLength = showMinMaxWord;
    inputsVisibility.maxLength = showMinMaxWord;
    inputsVisibility.pdfViewType = this.showPdfViewType(this.field);
  }

  showCalculateTypes(field: any) {
    const type = this.getFieldType(field);
    const validTypes = [
      DesignerField.multicheckbox,
      DesignerField.radio_grid,
      DesignerField.radio,
      DesignerField.radio_image,
      DesignerField.select,
      DesignerField.number,
    ];
    return validTypes.includes(type);
  }

  showPdfViewType(field: any) {
    const type = this.getFieldType(field);
    const validTypes = [
      DesignerField.multicheckbox,
      DesignerField.radio_grid,
      DesignerField.radio,
      DesignerField.radio_image,
      DesignerField.select,
    ];
    return validTypes.includes(type);
  }

  showPlaceholder(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [
      DesignerField.title,
      DesignerField.subtitle,
      DesignerField.date,
      DesignerField.checkbox,
      DesignerField.multicheckbox,
      DesignerField.radio_grid,
      DesignerField.radio,
      DesignerField.radio_image,
    ];
    return !invalidTypes.includes(type);
  }

  showMinMaxNumberInput(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [DesignerField.number];
    return invalidTypes.includes(type);
  }

  showMinAndMaxWordInput(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [DesignerField.text, DesignerField.textArea];
    return invalidTypes.includes(type);
  }

  getField(field: any) {
    if (field.fieldGroup) {
      this.formConfiguration.isGroupField = true;
      this.group = field;
      return {
        ...field,
        ...field.fieldGroup[0],
      };
    }
    this.formConfiguration.isGroupField = false;
    return field?.fieldArray ? field.fieldArray : field;
  }

  fillBooleanTypes() {
    this.formConfiguration.booleanTypes = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  fillCalculateTypes() {
    const type = this.getFieldType(this.field);
    const calculateTypes: any[] = [];
    const takeMinMaxValueValidTypes: DesignerField[] = [
      DesignerField.multicheckbox,
      DesignerField.radio_grid,
    ];

    if (takeMinMaxValueValidTypes.includes(type)) {
      calculateTypes.push({
        label: this.trialpalService.translateService.instant(
          'designer.fieldCalculateTypeEnums.TAKE_MIN_VALUE'
        ),
        value: CALCULATE_TYPES.TAKE_MIN_VALUE,
      });
      calculateTypes.push({
        label: this.trialpalService.translateService.instant(
          'designer.fieldCalculateTypeEnums.TAKE_MAX_VALUE'
        ),
        value: CALCULATE_TYPES.TAKE_MAX_VALUE,
      });
      calculateTypes.push({
        label: this.trialpalService.translateService.instant(
          'designer.fieldCalculateTypeEnums.SUM_ALL_VALUE'
        ),
        value: CALCULATE_TYPES.SUM_ALL_VALUE,
      });
      calculateTypes.push({
        label: this.trialpalService.translateService.instant(
          'designer.fieldCalculateTypeEnums.NO_CALCULATE_VALUE'
        ),
        value: CALCULATE_TYPES.NO_CALCULATE_VALUE,
      });
    } else {
      calculateTypes.push({
        label: this.trialpalService.translateService.instant(
          'designer.fieldCalculateTypeEnums.UNIQUE_VALUE'
        ),
        value: CALCULATE_TYPES.UNIQUE_VALUE,
      });
      calculateTypes.push({
        label: this.trialpalService.translateService.instant(
          'designer.fieldCalculateTypeEnums.NO_CALCULATE_VALUE'
        ),
        value: CALCULATE_TYPES.NO_CALCULATE_VALUE,
      });
    }
    this.formConfiguration.calculateTypes = calculateTypes;
  }

  getIsTitleField(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [DesignerField.title, DesignerField.subtitle];
    return invalidTypes.includes(type);
  }

  getShowFormData(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [
      DesignerField.title,
      DesignerField.subtitle,
      DesignerField.date,
      DesignerField.checkbox,
      DesignerField.text,
      DesignerField.email,
      DesignerField.textArea,
      DesignerField.number,
    ];
    return !invalidTypes.includes(type);
  }

  getShowFormValidation(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [
      DesignerField.title,
      DesignerField.subtitle,
      DesignerField.date,
      DesignerField.checkbox,
      DesignerField.select,
      DesignerField.radio,
      DesignerField.radio_grid,
      DesignerField.radio_image,
      DesignerField.multicheckbox,
    ];
    return !invalidTypes.includes(type);
  }

  getShowDefaultValue(field: any) {
    const type = this.getFieldType(field);
    const invalidTypes = [
      DesignerField.multicheckbox,
      DesignerField.radio_grid,
      DesignerField.checkbox,
      DesignerField.date,
    ];
    return !invalidTypes.includes(type);
  }

  getFieldType(field: any): DesignerField {
    return field.type === 'input' ? field.templateOptions.type : field.type;
  }

  fillTextTypes() {
    this.formConfiguration.textTypes = [
      {
        label: this.trialpalService.translateService.instant(
          'designer.fieldTypeEnums.SUBTITLE'
        ),
        value: DesignerField.subtitle,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.fieldTypeEnums.TITLE'
        ),
        value: DesignerField.title,
      },
    ];
  }

  fillPdfViewTypes() {
    this.formConfiguration.pdfViewTypes = [
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.answerSelected'
        ),
        value: PDF_VIEW_OPTIONS.ANSWER_SELECTED,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.optionsWithAnswers'
        ),
        value: PDF_VIEW_OPTIONS.OPTIONS_WITH_ASNWERS,
      },
    ];
  }

  //Función que se encarga de leer las imagenes guardadas previamente
  async fillImageTypes() {
    this.formConfiguration.imageTypes = [];
    //Lee las imagenes del proyecto de S3
    const files = await this.s3Storage.list(this.UPLOAD_PATH);
    const promises: any[] = files.map((file: any) =>
      this.s3Storage.get(file.key)
    );

    //Agrega las imagenes a la lista
    const images: string[] = await Promise.all(promises);
    this.formConfiguration.imageTypes = images.map((image: string) => ({
      label: this.transformImageName(image),
      value: this.transformUrl(image),
    }));
    this.field.templateOptions.images = [...this.field.templateOptions.images];
  }

  transformImageName(name: string) {
    return name.split('?')[0].split('/designer/')[1];
  }

  transformUrl(url: string) {
    return url.split('?')[0];
  }

  //Se encarga de obtener un arreglo de llaves con los campos del grupo
  fillFieldInGroupTypes() {
    const fieldGroup = this.config.data.fieldGroup;
    this.formConfiguration.fieldsInGroupTypes = fieldGroup
      .filter(
        (field: any) =>
          field.key !== this.field?.key && !this.getInvalidOptions(field)
      )
      .map((field: any) => {
        let options: any[] = this.getFieldOptions(field);
        const type = this.getFieldType(field);
        const templateOptions = field?.templateOptions;
        const min = templateOptions?.min ?? templateOptions?.minLength;
        const max = templateOptions?.max ?? templateOptions?.maxLength;
        return {
          label: `${this.dictionaryPipe.transform(
            field?.templateOptions?.label
          )} (${field.key})`,
          value: {
            key: `model?.${field.key}`,
            type: type,
            options,
            min: this.getMin(type, min),
            max: this.getMax(type, max),
          },
        };
      });
  }

  /**
   * Obtiene el valor máximo para un tipo específico.
   * @param type El tipo de valor ('number', 'text', 'textarea').
   * @param max El valor máximo configurado.
   * @returns El valor máximo permitido para el tipo especificado.
   */
  getMax(type: string, max: number | undefined) {
    if (type === 'number') {
      return max ?? this.max;
    }
    return max ?? this.maxLength;
  }

  /**
   * Obtiene el valor mínimo para un tipo específico.
   * @param type El tipo de valor ('number', 'text', 'textarea').
   * @param min El valor máximo configurado.
   * @returns El valor mínimo permitido para el tipo especificado.
   */
  getMin(type: string, min: number | undefined) {
    if (type === 'number') {
      return min ?? this.min;
    }
    return min ?? this.minLength;
  }

  getInvalidOptions(field: any) {
    const type = this.getFieldType(field);
    const invalidOptions: (DesignerField | undefined)[] = [
      DesignerField.title,
      DesignerField.subtitle,
      DesignerField.multicheckbox,
      DesignerField.radio_grid,
      undefined,
    ];
    return invalidOptions.includes(type);
  }

  getFieldOptions(field: any) {
    let options: any[] = [];
    const type = this.getFieldType(field);
    if (type === DesignerField.checkbox) {
      options = [
        {
          label: this.trialpalService.translateService.instant(
            'designer.addEdit.optionFalse'
          ),
          value: false,
        },
        {
          label: this.trialpalService.translateService.instant(
            'designer.addEdit.optionTrue'
          ),
          value: true,
        },
      ];
    } else {
      options = field?.templateOptions?.options?.map((option: any) => ({
        ...option,
        label: this.dictionaryPipe.transform(option.label),
      }));
    }
    return options ?? [];
  }

  fillOperatorTypes() {
    this.formConfiguration.operatorTypes = [
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.opertatorType.enum.EQUAL'
        ),
        value: OPERATORS.EQUAL,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.opertatorType.enum.NOT_EQUAL'
        ),
        value: OPERATORS.NOT_EQUAL,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.opertatorType.enum.GREATER_THAN'
        ),
        value: OPERATORS.GREATER_THAN,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.opertatorType.enum.LESS_THAN'
        ),
        value: OPERATORS.LESS_THAN,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.opertatorType.enum.GREATER_THAN_OR_EQUAL'
        ),
        value: OPERATORS.GREATER_THAN_OR_EQUAL,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.opertatorType.enum.LESS_THAN_OR_EQUAL'
        ),
        value: OPERATORS.LESS_THAN_OR_EQUAL,
      },
    ];
  }

  fillFieldConditional() {
    const conditional = this.field?.hideExpression ?? '';
    if (conditional?.length > 0) {
      const key = this.field.customExpression?.key;
      const { value } = this.findFielByKey(key);
      if (value?.type && value?.options) {
        this.formConfiguration.conditional = {
          field: {
            key,
            type: value.type,
            options: value.options,
            min: value.min,
            max: value.max,
          },
          operator: this.field.customExpression?.operator,
          value: this.field?.customExpression?.value,
        };
      }
    }
  }

  findFielByKey(key: string): any {
    return this.formConfiguration.fieldsInGroupTypes.find((field: any) => {
      return field.value.key === key;
    });
  }

  clearConditional() {
    this.formConfiguration.conditional = {
      field: { key: '', type: '', options: [], min: this.min, max: this.max },
      operator: null,
      value: '',
    };
    this.field.hideExpression = '';
    this.field.customExpression = {};
  }

  submit() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant('general.savingInfo')
    );
    //Construye la expressión si existe
    const { expression, key, operator, value } = this.buildExpressionHide();
    if (expression) {
      this.field = {
        ...this.field,
        hideExpression: expression,
        customExpression: {
          key,
          operator,
          value,
        },
      };
    }

    //Valida si es un campo de titulo o subtitulo
    const isTitleField = this.getIsTitleField(this.field);

    //Crea una key si no existe una creada anteriormente
    if (!this.field?.key && !isTitleField) {
      this.field.key = this.buildFieldKey();
    }

    if (this.formConfiguration.isGroupField) {
      this.field = this.buildGroup();
    }

    this.ref.close({ ...this.field });
    this.trialpalService.hideSpinner();
  }

  buildGroupFieldKey() {
    const isEvaluationGroup = this.field.templateOptions.score;
    const numberId = this.field.key
      ? this.field.key.split('_').at(-1)
      : Math.floor(Math.random() * Date.now());
    const prefix = isEvaluationGroup ? 'group_evaluation' : 'group';
    return `${prefix}_${numberId}`;
  }

  getNewKey(type: string) {
    return `${type}_${Math.floor(Math.random() * Date.now())}`;
  }

  buildGroup() {
    this.field.key = this.buildGroupFieldKey();

    const { hideExpression, customExpression, templateOptions } = this.field;
    const fieldGroup = this.group.fieldGroup[0];
    const group = {
      ...this.group,
      fieldGroup: [
        {
          templateOptions: fieldGroup.templateOptions,
          fieldGroup:
            fieldGroup?.fieldGroup || fieldGroup?.fieldArray?.fieldGroup,
          validation: fieldGroup.validation,
          key: this.field.key,
        },
      ],
      hideExpression,
      customExpression,
      templateOptions,
    };

    //Si es repetible, modifica el campo y añade fieldArray
    if (this.formConfiguration.isRepeatField) {
      const fieldGroup = group.fieldGroup[0]?.fieldGroup;

      group.fieldGroup[0] = {
        key: this.field.key,
        templateOptions,
        type: 'repeat-group',
        fieldArray: { fieldGroup },
        validation: group.fieldGroup[0].validation,
      };
    }

    return group;
  }

  buildFieldKey() {
    return `${this.field.type}_${Math.floor(Math.random() * Date.now())}`;
  }

  //Función que se encarga de subir las imagenes a s3
  async onBasicUploadAuto(event: any, images: any[], index: number) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files[0]?.type?.includes('image')) {
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant(
          'general.loadingUploadingFile'
        )
      );
      const url = await this.uploadImagesInS3(files[0]);
      images[index] = this.transformUrl(url);
      this.formConfiguration.imageTypes.push({
        label: this.transformImageName(url),
        value: this.transformUrl(url),
      });
      this.field.templateOptions.images = [...images];
      this.fileImage.nativeElement.value = '';
      this.trialpalService.hideSpinner();
      this.updateField();
    }
  }

  async uploadImagesInS3(file: File | null): Promise<string> {
    if (!file) return '';

    const putResult = await this.s3Storage.put(
      file,
      this.UPLOAD_PATH,
      `${file.name.replace(/ /g, '_')}_${new Date().getTime()}`,
      'public'
    );
    return this.s3Storage.get(putResult?.key);
  }

  //Funcion que se encarga de construir la expresion
  buildExpressionHide(): any {
    let { field, operator, value } = this.formConfiguration.conditional;
    if (!field.key || !operator || value == null) return {};
    //Se encarga de transformar el valor según su tipo
    const expressionValue = this.buildExpressionValue(field.type, value);

    //Indica si se quiere agregar los campos null en la expression
    const additionalExpression = this.getAddtionalExpression(field, operator);

    //Construye la expressión model.key || model.key (==, !=, <, >, <=, >=) value
    const expression = `${additionalExpression} !(${field.key} ${operator} ${expressionValue})`;
    return { expression, key: field.key, operator, value };
  }

  //Valida si se debe añadir un operador adittional
  getAddtionalExpression(field: any, operator: OPERATORS) {
    const { type, key } = field;
    const invalidOperators = [OPERATORS.NOT_EQUAL];

    if (
      type === DesignerField.checkbox ||
      invalidOperators.includes(operator)
    ) {
      return '';
    }

    return `!${key} ||`;
  }

  buildExpressionValue(type: string, value: any) {
    let fieldValue: any;
    switch (type) {
      case DesignerField.number:
        fieldValue = Number(value);
        break;
      case DesignerField.checkbox:
        fieldValue = value;
        break;
      default:
        fieldValue = `'${value}'`;
        break;
    }

    return fieldValue;
  }

  updateField() {
    this.field = { ...this.field };
  }

  //Funciones para multichecbox, radio buttons, checkbox
  deleteFieldPropOption(optionIndex: number) {
    this.field.templateOptions.options =
      this.field.templateOptions.options.filter(
        (option: any, _optionIndex: number) => {
          return _optionIndex !== optionIndex;
        }
      );

    if (this.field.templateOptions.images) {
      const images = this.field.templateOptions.images.filter(
        (image: any, _imageIndex: number) => {
          return _imageIndex !== optionIndex;
        }
      );
      this.field.templateOptions.images = [...images];
    }
    this.updateField();
  }

  addNewFieldPropsOption() {
    this.field.templateOptions.options = [
      ...this.field.templateOptions.options,
      { label: '', value: '' },
    ];
  }

  //Funciones para radio-grid
  addNewQuestion() {
    this.field.templateOptions.questions = [
      ...this.field.templateOptions.questions,
      { label: '', tooltip: '', required: false },
    ];
  }

  deleteQuestion(questionIndex: number) {
    this.field.templateOptions.questions =
      this.field.templateOptions.questions.filter(
        (question: any, _questionIndex: number) => {
          return _questionIndex !== questionIndex;
        }
      );
  }

  close() {
    this.ref.close(null);
  }
}
