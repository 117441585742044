import { Injectable } from '@angular/core';
import { API, graphqlOperation } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { SITES_QUERIES } from './sites.queries';
import { timeZome } from './sites.timezone';
import {
  AuthorizedSitesBySiteIdCustomQuery,
  CreateSiteInput,
  DeleteSiteInput,
  DeleteSiteMutation,
  GetSiteQuery,
  UpdateSiteInput,
  UpdateSiteMutation,
} from './sites.types';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  constructor(private auth: AuthService) {}

  async fetchSitesByProject(projectId: string): Promise<any> {
    let sites: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        SITES_QUERIES.SitesByProjectId,
        { projectId, limit: 100, nextToken }
      );
      sites = sites.concat(listQuery.data.SitesByProjectId.items);
      nextToken = listQuery.data.SitesByProjectId.nextToken ?? undefined;
    } while (nextToken);
    sites = sites.filter(Boolean).filter((s: any) => !s._deleted);
    return sites;
  }
  async getSitesByProject(projectId: string): Promise<any[]> {
    const instances = await this.fetchSitesByProject(projectId);
    if (this.auth.isAdmin()) return instances;
    return this.filterSitesByUserRol(instances);
  }

  async getSitesByProjectForConfAlert(projectId: string): Promise<any[]> {
    let instances: any = await this.fetchSitesByProject(projectId);
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    return instances.map((instance: any) => {
      return { ...instance, hasPermission: this.getPermissionSite(instance) };
    });
  }

  getPermissionSite(site: any) {
    const rolAuthorizedSites = this.auth.getUserSites();
    if (this.auth.isAdmin()) return true;
    return rolAuthorizedSites.findIndex((siteId) => siteId == site.id) > -1;
  }

  //Obtiene los sitios que pertenezcan al usuario en sessión
  filterSitesByUserRol(siteInstances: any) {
    const rolAuthorizedSites = this.auth.getUserSites();
    const filteredSiteInstances: any[] = [];
    rolAuthorizedSites.forEach((siteId: any) => {
      siteInstances.forEach((element: any) => {
        if (element.id === siteId) {
          filteredSiteInstances.push(element);
        }
      });
    });
    return filteredSiteInstances;
  }
  async deleteSite(
    id: string,
    expectedVersion: number
  ): Promise<DeleteSiteMutation> {
    const input: DeleteSiteInput = {
      id,
      _version: expectedVersion,
    };
    const response = await this.performGraphQLQuery(SITES_QUERIES.DeleteSite, {
      input,
    });
    return response.data.deleteSite;
  }
  async createSite(site: CreateSiteInput): Promise<CreateSiteInput> {
    site._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(SITES_QUERIES.CreateSite, {
      input: site,
    });
    return response.data.createSite;
  }
  async updateSite(
    data: CreateSiteInput,
    id: string,
    expectedVersion: number
  ): Promise<UpdateSiteMutation> {
    let updateInput: UpdateSiteInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput.showContactInfoLogin =
      updateInput.showContactInfoLogin ?? false;
    updateInput._lastUser = this.auth.getUsername();
    updateInput.showContactInfo = updateInput.showContactInfo ?? false;
    const response = await this.performGraphQLQuery(SITES_QUERIES.UpdateSite, {
      input: updateInput,
    });
    return response.data.updateSite;
  }
  async getSite(id: string): Promise<GetSiteQuery> {
    const response = await this.performGraphQLQuery(SITES_QUERIES.GetSite, {
      id,
    });
    return response.data.getSite;
  }
  getSiteGMT(timezone: string | null | undefined): number {
    if (timezone) {
      const tz = timeZome.find((x) => {
        return x.utc.indexOf(timezone) !== -1;
      });
      if (tz) {
        return tz.offset;
      }
    }
    return 0;
  }

  /**
   * @param siteId Sitio al que se le va a obtener la información
   * @returns AuthorizedSites con los usuarios del sitio
   */
  async getSiteAuthorizedUsers(
    siteId: string
  ): Promise<AuthorizedSitesBySiteIdCustomQuery> {
    const response = await this.performGraphQLQuery(
      SITES_QUERIES.AuthorizedSitesBySiteIdCustom,
      { siteId }
    );
    return response.data.siteId.AuthorizedSitesBySiteId;
  }

  /**
   * @param siteId Sitio al que se le va a obtener la información
   * @returns Sujetos pertenecientes al sitio
   */
  private async performGraphQLQuery(query: any, args: any): Promise<any> {
    return (await API.graphql(graphqlOperation(query, args))) as any;
  }
}
