import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss'],
})
export class FormPreviewComponent {
  form: FormGroup;
  fields: FormlyFieldConfig[] = [];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      mainModel: this.model,
    },
  };

  constructor(private fb: FormBuilder, private config: DynamicDialogConfig) {
    this.form = this.fb.group({});
    const formField = JSON.parse(
      JSON.stringify({ ...this.config.data.formField })
    );
    this.fields = [formField];
  }
}
