import { Component } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import { TemperatureService } from '../temperature.service';
import {
  ConfTemperature,
  TemperatureRecordLog,
} from 'src/app/modules/conciliation/conciliation.types';

const logger = new Logger('tp2-summaryTemperature');
@Component({
  selector: 'app-summary-temperature',
  templateUrl: './summary-temperature.component.html',
})
export class SummaryTemperatureComponent {
  configuration?: ConfTemperature;
  verified: VerifiedData;
  recordTemperature?: TemperatureRecordLog;
  oldValue: VerifiedData;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private temperatureService: TemperatureService,
    private conciliationService: ConciliationService
  ) {
    this.configuration = this.config.data?.configuration;
    this.verified = this.config.data?.newVerified
      ? Object.assign({}, ...this.config.data.newVerified)
      : {};
    this.oldValue = this.config.data?.oldValue
      ? Object.assign({}, ...this.config.data.oldValue)
      : {};
    // se valida que los objetos boolean no sean undefied
    this.oldValue.temperatureRoute =
      this.oldValue?.temperatureRoute !== undefined ||
      this.oldValue?.temperatureRoute !== null
        ? this.temperatureService.getValueEnumRoute(
            this.oldValue?.temperatureRoute
          )
        : undefined;

    this.verified.temperatureRoute =
      this.verified?.temperatureRoute !== undefined ||
      this.verified?.temperatureRoute !== null
        ? this.temperatureService.getValueEnumRoute(
            this.verified.temperatureRoute
          )
        : undefined;

    this.oldValue.temperatureTaken =
      this.oldValue?.temperatureTaken !== undefined
        ? this.conciliationService.getYN(this.oldValue?.temperatureTaken)
        : undefined;

    this.verified.temperatureTaken =
      this.verified?.temperatureTaken !== undefined
        ? this.conciliationService.getYN(this.verified?.temperatureTaken)
        : undefined;
    logger.debug('summary', this.config.data);
  }

  onCancel() {
    this.ref.close(null);
  }

  onAccept(accept: boolean) {
    this.ref.close(accept);
  }
}

interface VerifiedData {
  temperature?: any;
  temperatureRoute?: any;
  temperatureWhichOtherRoute?: string;
  temperatureTaken?: any;
  temperatureTakenDate?: string;
}
