<p-card>
  <div class="container" *ngIf="showDiagram">
    <p-chart
      type="pie"
      [data]="data"
      [options]="chartOptions"
      [style]="{ width: '40%' }"
    ></p-chart>
  </div>
  <h4 *ngIf="!showDiagram">
    {{ 'general.noData' | translate }}
  </h4>
</p-card>
