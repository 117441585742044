<div class="container-tp2">
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12 p-md-6 p-xl-4 project-list">
      <h1>{{ 'project.h1ListProject' | translate }}</h1>
    </div>

    <div
      class="p-col-12 p-md-6 p-xl-4 project-button"
      *ngIf="hasProjectCreatePermission"
    >
      <button
        id="btn_createProject"
        name="btn_createProject"
        pButton
        label="{{ 'project.buttonCreateProject' | translate }}"
        type="button"
        (click)="createEdit()"
        icon="pi pi-plus"
      ></button>
    </div>

    <div class="p-col-12 p-md-6 p-xl-4 p-field project-filter">
      <input
        id="txt_filterProject"
        name="txt_filterProject"
        type="text"
        pInputText
        [(ngModel)]="filter"
        (ngModelChange)="filterMaster()"
        placeholder="{{ 'project.placeHolderFilterProject' | translate }}"
      />
    </div>
  </div>

  <div class="p-grid p-fluid" *ngIf="projectsFilter.length > 0">
    <app-tpcard
      id="cards"
      *ngFor="let p of projectsPaginated"
      class="p-col-12 p-md-6 p-lg-4"
      [tpCardData]="buildTPCardData(p)"
      (edit)="createEdit($event)"
      (delete)="delete()"
      (detail)="detail($event)"
    ></app-tpcard>
    <div class="p-col-12">
      <p-paginator
        id="paginator"
        [rows]="pagesize"
        [totalRecords]="projectsFilter.length"
        (onPageChange)="paginate($event)"
      >
      </p-paginator>
    </div>
  </div>

  <div class="p-grid p-fluid" *ngIf="projectsFilter.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
