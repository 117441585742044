import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Logger } from 'aws-amplify';
import { AuthService } from '../services/auth.service';
import { TrialpalService } from '../services/trialpal.service';
import { FEATURE_FLAGS, FeatureFlag, Roles } from '../shared/global.variables';
const logger = new Logger('Feature flags directive');
@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective {
  private hasView = false;
  private elseTemplateRef: TemplateRef<any> | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private trialpalService: TrialpalService,
    private authService: AuthService
  ) {}

  @Input() set featureFlag(flag: string) {
    this.trialpalService.showSpinner('general.spinnerLoadingData');
    this.trialpalService
      .getFeatureFlag('')
      .then((flagResponse) => {
        const featureFlag = JSON.parse(flagResponse);
        //verify if the feature apply given the user role
        const hasRole = this.verifyRoles(flag);

        if (hasRole) {
          if (featureFlag[flag].enabled) {
            if (!this.hasView) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              this.hasView = true;
            }
            this.handleFeatureAttributes(flag, featureFlag[flag]);
          } else {
            if (this.hasView) {
              this.viewContainer.clear();
              this.hasView = false;
            }
            if (this.elseTemplateRef) {
              this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }
            if (featureFlag[flag] === 'maintenance-site') {
              localStorage.removeItem('maintenance');
            }
          }
        } else {
          this.renderTemplate();
        }

        this.trialpalService.hideSpinner();
      })
      .catch((error) => {
        this.trialpalService.hideSpinner();
        logger.debug('feature flag stratefy fail', error);
        if (this.elseTemplateRef) {
          this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }
      });
  }

  @Input() set featureFlagElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef = templateRef;
  }
  renderTemplate() {
    if (this.elseTemplateRef) {
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
    } else {
      this.viewContainer.clear();
    }
  }
  verifyRoles(flag: string) {
    const userRoles = this.authService.getUserRoles() as Array<Roles>;
    const flagRoles =
      FEATURE_FLAGS.find((feature: FeatureFlag) => feature.name === flag)
        ?.roles || [];
    return flagRoles.length > 0
      ? userRoles.some((role: Roles) => flagRoles.includes(role))
      : true;
  }
  handleFeatureAttributes(feature: any, attributes: any) {
    switch (feature) {
      case 'maintenance-site':
        localStorage.setItem('maintenance', 'true');
        this.trialpalService.maintenanceInfo.next({
          enable: true,
          endDate: attributes.endDate,
        });
        break;
      case 'maintenance-banner-site':
        this.trialpalService.maintenanceBannerInfo.next({
          enable: true,
          startDate: attributes.startDate,
          endDate: attributes.endDate,
        });
        break;

      default:
        break;
    }
  }
}
