<p-fieldset legend="{{ 'roles.roleDetail' | translate }}">
  <div class="p-grid p-fluid p-mt-3">
    <div class="p-col-12 p-md-6 p-field">
      <h4 class="full-width p-my-0">
        {{ 'roles.labelRoleName' | translate }}:
      </h4>
      <div class="p-col p-pl-0 centerItem">
        <label for="roleName">{{ role?.roleName }}</label>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-field">
      <h4 class="full-width p-my-0">{{ 'roles.labelState' | translate }}:</h4>
      <div class="p-col p-pl-0 centerItem">
        <label for="roleName">{{ role?.isEnabled }}</label>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-field">
      <h4 class="full-width p-my-0">
        {{ 'roles.labelRoleForProject' | translate }}:
      </h4>
      <div class="p-col p-pl-0 centerItem">
        <label for="roleName">{{ role?.isForProjects }}</label>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-field">
      <h4 class="full-width p-my-0">
        {{ 'roles.labelProjects' | translate }}:
      </h4>
      <div class="p-col p-pl-0 centerItem">
        <label for="roleName">{{ role?.projects }}</label>
      </div>
    </div>
  </div>

  <section class="p-mb-5">
    <app-tptable [dataTable]="dataTable"></app-tptable>
  </section>
</p-fieldset>
