import {
  AccessibilityGroup,
  ReportAvailableUserType,
} from '../modules/project/project.types';

export type GetAuditInfoQuery = {
  __typename: 'ModelAuditTraceConnection';
  items: Array<{
    __typename: 'AuditTrace';
    id: string;
    relatedEntityId: string;
    entity: string;
    projectId?: string | null;
    eventDateTime: string;
    eventType: string;
    _lastUser: string;
    _changeReason?: string | null;
    data: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type EDiaryPhaseInstance = {
  __typename: 'EDiaryPhaseInstance';
  subjectId: string;
  subject?: Subject | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: EDiaryInstance | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<TP2File> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLock | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ConciliationLock = {
  __typename: 'ConciliationLock';
  user: string;
  date: string;
};

export type TP2File = {
  __typename: 'TP2File';
  uploadDate: string;
  fileUrl: string;
};
export type Site = {
  __typename: 'Site';
  projectId: string;
  project?: Project | null;
  id: string;
  name: string;
  contactInfo?: string | null;
  showContactInfo?: boolean | null;
  showContactInfoLogin?: boolean | null;
  timezone?: string | null;
  defaultLanguage?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type Project = {
  __typename: 'Project';
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<AccessibilityGroup | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<ProjectTermsAndConditions | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ProjectTermsAndConditions = {
  __typename: 'ProjectTermsAndConditions';
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};

export type EDiaryInstance = {
  __typename: 'EDiaryInstance';
  subjectId: string;
  subject?: Subject | null;
  confEDiaryId: string;
  confEDiary?: ConfEDiary | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateAlertInstanceInput = {
  confAlertId?: string | null;
  subjectId?: string | null;
  id: string;
  confAlert_data?: string | null;
  entityId?: string | null;
  entityName?: string | null;
  state?: string | null;
  revisionState?: RevisionStateAlertInstance | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export type ConfEDiary = {
  __typename: 'ConfEDiary';
  projectId: string;
  project?: Project | null;
  windows?: Array<ConfEDiaryWindow | null> | null;
  isConciliationRequired: boolean;
  isWindowsConciliationRequired?: boolean | null;
  id: string;
  isSignatureRequired: boolean;
  isPastEntryEnabled: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isDeviceDataRequired: boolean;
  enableLocalNotifications?: boolean | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListEDiaryInstancesQuery = {
  __typename: 'ModelEDiaryInstanceConnection';
  items: Array<{
    __typename: 'EDiaryInstance';
    subjectId: string;
    confEDiaryId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    confEdiaryVersion: string;
    comment?: string | null;
    alerts?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ListReportInstancesQuery = {
  __typename: 'ModelReportInstanceConnection';
  items: Array<{
    __typename: 'ReportInstance';
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ConfEDiaryWindow = {
  __typename: 'ConfEDiaryWindow';
  name: string;
  phases: Array<string>;
  daysAfterVisit: number;
  totalWindowDays: number;
};

export enum OnDemandAlertType {
  DAYS = 'DAYS',
  WEEKLY = 'WEEKLY',
}

export type Subject = {
  __typename: 'Subject';
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<ScheduledPhase> | null;
  currentScheduledPhase?: ScheduledPhase | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ScheduledPhase = {
  __typename: 'ScheduledPhase';
  phase: string;
  date?: string | null;
  state: ScheduledPhaseState;
};

export type CreateConfDictionaryInput = {
  projectId: string;
  id?: string | null;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};

export type CreateConfDictionaryMutation = {
  __typename: 'ConfDictionary';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfDictionaryMutation = {
  __typename: 'ConfDictionary';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  key: string;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfDictionaryInput = {
  projectId?: string | null;
  id: string;
  key?: string | null;
  spanish?: string | null;
  english?: string | null;
  isHtml?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};

export enum ScheduledPhaseState {
  PLANNED = 'PLANNED',
  COMPLETED = 'COMPLETED',
  NOT_DONE = 'NOT_DONE',
}

export type TP2UserInput = {
  login: string;
  name?: string | null;
  email?: string | null;
  role?: string | null;
  phoneNumber?: string | null;
  state?: UserState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  notificationPreference?: string | null;
};

export type ModelAuditTraceFilterInput = {
  id?: ModelIDInput | null;
  relatedEntityId?: ModelStringInput | null;
  entity?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  eventDateTime?: ModelStringInput | null;
  eventType?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  data?: ModelStringInput | null;
  and?: Array<ModelAuditTraceFilterInput | null> | null;
  or?: Array<ModelAuditTraceFilterInput | null> | null;
  not?: ModelAuditTraceFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type UserEntityType = {
  __typename: 'UserEntityType';
  username: string;
  userType: ReportAvailableUserType;
};

export enum MediaType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}
export type ModelConfStateInput = {
  eq?: ConfState | null;
  ne?: ConfState | null;
};
export type ModelInstanceStateInput = {
  eq?: InstanceState | null;
  ne?: InstanceState | null;
};

export type ModelMediaTypeInput = {
  eq?: MediaType | null;
  ne?: MediaType | null;
};
export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};
export type ModelYNAInput = {
  eq?: YNA | null;
  ne?: YNA | null;
};
export type ModelYNInput = {
  eq?: YN | null;
  ne?: YN | null;
};
export type TP2IdEntity = {
  id: string;
};

export type TP2FileInput = {
  uploadDate: string;
  fileUrl: string;
};
export enum SignatureType {
  BIOMETRICS = 'BIOMETRICS',
  USER_PASS = 'USER_PASS',
}

export enum SignatureReason {
  AUTHORSHIP = 'AUTHORSHIP',
  APPROVAL = 'APPROVAL',
  REVIEW = 'REVIEW',
}

export enum SignatureEntityType {
  EDIARY = 'EDIARY',
  REPORT = 'REPORT',
  ECONSENT = 'ECONSENT',
}

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum UserState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum SubjectState {
  ENROLLED = 'ENROLLED',
  COMPLETED = 'COMPLETED',
  DROPOUT = 'DROPOUT',
  DELETED = 'DELETED',
}

export enum InstanceState {
  PENDING = 'PENDING',
  MUST_UPDATE = 'MUST_UPDATE',
  ON_GOING = 'ON_GOING',
  NOT_SENDED = 'NOT_SENDED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  UNSIGNED = 'UNSIGNED',
  DELETED = 'DELETED',
  CONCILIATION_DELETED = 'CONCILIATION_DELETED',
  SUSPENDED = 'SUSPENDED',
  LOCALLY_SAVED = 'LOCALLY_SAVED',
  CONCILIATION_COMPLETED = 'CONCILIATION_COMPLETED',
}

export enum ProjectState {
  IN_DESIGN = 'IN_DESIGN',
  IN_PROGRESS = 'IN_PROGRESS',
  SUSPENDED = 'SUSPENDED',
  ENDED = 'ENDED',
  ARCHIVED = 'ARCHIVED',
}
export enum ConfState {
  DELETED = 'DELETED',
}

export enum RevisionStateAlertInstance {
  PENDING = 'PENDING',
  EFFECTIVE_CONTACT = 'EFFECTIVE_CONTACT',
  NOT_EFFECTIVE_CONTACT = 'NOT_EFFECTIVE_CONTACT',
  REVIEWED = 'REVIEWED',
}

export enum Symptom {
  PAIN = 'PAIN',
  TENDERNESS = 'TENDERNESS',
  NAUSEA = 'NAUSEA',
  FEVER = 'FEVER',
  IRRITABILITY = 'IRRITABILITY',
  VOMIT = 'VOMIT',
  ANORMAL_CRYING = 'ANORMAL_CRYING',
  DROWSINESS = 'DROWSINESS',
  APPETITE_LOSS = 'APPETITE_LOSS',
  HEADACHE = 'HEADACHE',
  COUGH = 'COUGH',
  SORE_THROAT = 'SORE_THROAT',
  RUNNY_NOSE = 'RUNNY_NOSE',
  WHEEZING = 'WHEEZING',
  MUSCLE_SORENESS = 'MUSCLE_SORENESS',
  EARACHE = 'EARACHE',
  DIARRHEA = 'DIARRHEA',
  FATIGUE = 'FATIGUE',
  CHILL = 'CHILL',
  ARTHRALGIA = 'ARTHRALGIA',
  SWELLING = 'SWELLING',
  REDNESS = 'REDNESS',
  HEMATOMA = 'HEMATOMA',
  SORENESS = 'SORENESS',
  HARDNESS = 'HARDNESS',
  ITCHING = 'ITCHING',
  OTHER = 'OTHER',
}

export enum YNA {
  YES = 'YES',
  NO = 'NO',
  NOT_APPLY = 'NOT_APPLY',
}

export enum YN {
  YES = 'YES',
  NO = 'NO',
}

export enum EntityName {
  ReportInstance = 'ReportInstance',
  SignatureInstance = 'SignatureInstance',
  EDiaryInstance = 'EDiaryInstance',
  EDiaryPhaseInstance = 'EDiaryPhaseInstance',
  DayInstance = 'DayInstance',
  SymptomInstance = 'SymptomInstance',
  SymptomRecordLog = 'SymptomRecordLog',
  MedicationInstance = 'MedicationInstance',
  MedicalAttentionInstance = 'MedicalAttentionInstance',
  TemperatureRecordLog = 'TemperatureRecordLog',
  AlertInstance = 'AlertInstance',
  MediaRecordLog = 'MediaRecordLog',
  Subject = 'Subject',
}

export enum DataInputComponent {
  TEXT_INPUT = 'TEXT_INPUT',
  SPINNER = 'SPINNER',
  DROPDOWN = 'DROPDOWN',
}
