import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { SelectItem } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Roles } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { ChatService } from '../chat.service';
import {
  AuthorizedSitesBySiteIdCustomQuery,
  ChatMessageSender,
  CreateChatMessageInput,
} from '../chat.queries';
const logger = new Logger('tp2-logger-chatPage');
@Component({
  selector: 'app-site-chat',
  templateUrl: './site-chat.component.html',
  styleUrls: ['./site-chat.component.scss'],
})
export class SiteChatComponent implements OnInit, OnDestroy {
  projectId: string = '';
  sites: any[] = [];
  sitesChatSubscriptions: any[] = [];
  constructor(
    private trialpalService: TrialpalService,
    private chatService: ChatService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    for (const subs of this.sitesChatSubscriptions) {
      subs.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.projectId;
    const chatIdParam = this.route.snapshot.params.chatId;
    this.trialpalService.showSpinner('chat.entityPlural', 'LIST');
    // Tomamos la lista sitios a los que el usuario tiene acceso.
    this.projectService
      .listUserSites([this.projectId])
      .then((response) => {
        logger.debug('listUserSites response', response);
        this.sites = response;
        for (const site of this.sites) {
          const conv: SelectItem[] = [];
          site.chats = conv;
          site.selectedChat = {
            id: '',
            login: '',
            subjectsIds: [],
            subjects: [],
            days: [],
          };
          //Recuperamos la lista de usuarios de asociados al sitio
          this.chatService
            .getSiteUsers(site.id)
            .then((response2) => {
              logger.debug('getSiteUsers response', response2);
              const users = this.filterSubjectUsers(response2);
              //Recuperamos la lista de mensajes asociados al sitio
              this.chatService
                .getSiteChatMessages(site.id)
                .then((response3: any[]) => {
                  logger.debug('getSiteChatMessages response', response3);
                  if (response3) {
                    this.buildChatList(site, response3, users);
                    this.completeChatList(site, users);
                    this.setCurrentChat(site, chatIdParam);
                    this.completeSubjectNumbers(site);
                  }
                })
                .catch((error3) => {
                  logger.error('getSiteChatMessages error', error3);
                  this.trialpalService.showServiceError(
                    'chat.actions.getSiteChatMessages',
                    error3
                  );
                })
                .finally(() => this.trialpalService.hideSpinner());
            })
            .catch((error2) => {
              logger.error('getSiteUsers error', error2);
              this.trialpalService.hideSpinner();
              this.trialpalService.showServiceError(
                'chat.actions.getSiteUsers',
                error2
              );
            });
          this.addSiteSubscription(site);
        }
      })
      .catch((error: any) => {
        logger.error('listUserSites error', error);
        this.trialpalService.hideSpinner();
        this.trialpalService.showServiceError(
          'chat.actions.listUserSites',
          error
        );
      });
  }
  /**
   * Sirve para filtrar la lista y ajustar la estructura de datos
   * @param siteUsers sites y usuarios.
   * @returns una lista de solo usuarios.
   */
  private filterSubjectUsers(
    siteUsers: AuthorizedSitesBySiteIdCustomQuery
  ): any[] {
    let users: any[] = [];
    if (siteUsers.items) {
      users = siteUsers.items
        ?.map((x) => (x ? x.user : null))
        .filter((y: any) => y !== null && y.role === Roles.Subject)
        .filter(
          (z: any, i: any, a: any) =>
            a.findIndex((t: any) => t.id === z.id) === i
        );
    }
    logger.debug('Filtered users', users);
    return users;
  }
  /**
   * Sirve completar los datos de los sujetos asociados al los usuarios
   * @param site sitio donde se va completar los datos de os sujetos.
   */
  private completeSubjectNumbers(site: any) {
    this.chatService
      .getSiteSubjects(site.id)
      .then((response) => {
        logger.debug('getSiteSubjects response', response);
        if (response.items) {
          for (const chat of site.chats) {
            if (chat.value.subjectsIds) {
              for (const subjectId of chat.value.subjectsIds) {
                const sujeto = response.items.find(
                  (x: any) => x.id === subjectId
                );
                if (sujeto) {
                  chat.value.subjects.push(sujeto);
                  chat.label += '|' + sujeto.subjectNumber;
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        logger.error('getSiteSubjects error', error);
        this.trialpalService.showServiceError(
          'chat.actions.getSiteSubjects',
          error
        );
      });
  }
  /**
   * Sirve para asignar el chat, cuando llega por la ruta desde la notifación del app.component
   */
  private setCurrentChat(site: any, chatIdParam: any) {
    site.selectedChat = site.chats.find(
      (x: any) => x.value.id === chatIdParam
    )?.value;
  }
  /**
   * Sirve para hacer las suscripciones a los mensajes de cada sitio.
   * @param site site sobre el cual se van a generar las suscripciones
   */
  private addSiteSubscription(site: any) {
    this.sitesChatSubscriptions.push(
      this.chatService.onCreateChatMessageBySiteIdListener(site.id).subscribe({
        next: (data: any) => {
          logger.debug('data recieved', data);
          const chat = data.value.data.onCreateChatMessageBySiteId;
          const existingChat = site.chats.find(
            (x: any) => x.value.id === chat.userId
          );
          if (existingChat) {
            const groupDate = chat.createdAt.substring(0, 10);
            const existingDay = existingChat.value.days.find(
              (x: any) => x.chatDate === groupDate
            );
            if (existingDay) {
              existingDay.messages.push(chat);
            } else {
              site.selectedChat.days.push({
                chatDate: groupDate,
                messages: [chat],
              });
            }
          } else {
            logger.error('No user chat for recieved message');
          }
        },
      })
    );
  }
  /**
   * Sirve para construir la lista de chats con sus respectivos mensajes.
   * Arma la estructura de datos que contienen por cada chat sus mensajes
   * @param site sitio sobre el cual se construirá la lista de chats
   * @param siteChats lista de mensajes de chat asociados al sitio
   * @param users lista de usuarios asociados al sitio
   */
  private buildChatList(site: any, siteChats: any[], users: any[]) {
    const usersChats: SelectItem[] = [];
    for (const chat of siteChats) {
      const userChat = usersChats.find((x) => x.value.id === chat.userId);
      const groupDate = chat.createdAt.substring(0, 10);
      if (userChat) {
        const existingDay = userChat.value.days.find(
          (x: any) => x.chatDate === groupDate
        );
        if (existingDay) {
          existingDay.messages.push(chat);
        } else {
          userChat.value.days.push({
            chatDate: groupDate,
            messages: [chat],
          });
        }
      } else {
        const usuario = users.find((x: any) => x.id === chat.userId);
        if (usuario) {
          const newUserChat: SelectItem = {
            value: {
              id: chat.userId,
              login: usuario?.login,
              subjectsIds: usuario.subjects?.split(','),
              subjects: [],
              days: [
                {
                  chatDate: groupDate,
                  messages: [chat],
                },
              ],
            },
            label: usuario?.login,
          };
          usersChats.push(newUserChat);
        } else {
          logger.error(
            'user not found in site users',
            chat.userId,
            ' in site ',
            site.id
          );
        }
      }
    }
    site.chats = usersChats;
  }
  /**
   * Sirve para completar la lista de chats con los usuarios que aun no han mandado mensajes.
   * @param site sitio para completar los chats en blancos.
   * @param users usuarios del sitio con rol SUBJECT
   */
  private completeChatList(site: any, users: any[]) {
    for (const user of users) {
      const existingChat = site.chats.find((x: any) => x.value.id === user.id);
      if (!existingChat) {
        const chat: SelectItem = {
          value: {
            id: user.id,
            login: user?.login,
            subjectsIds: user?.subjects?.split(','),
            subjects: [],
            days: [],
          },
          label: user?.login,
        };
        site.chats.push(chat);
      }
    }
  }
  /**
   * Sirve para enviar un mensaje al usuario seleccionado
   * @param site sitio sobre el cual el usuario está trabajando.
   */

  sendMessage(site: any): void {
    if (site?.selectedChat) {
      const mensaje: CreateChatMessageInput = {
        message: site.message,
        siteId: site.id,
        userId: site.selectedChat.id,
        sender: ChatMessageSender.SITE,
        senderAlias: this.authService.getUsername(),
        _lastUser: this.authService.getUsername(),
      };
      this.chatService
        .createChatMessage(mensaje)
        .then((response) => {
          logger.debug('createChatMessage response ', response);
          site.message = '';
        })
        .catch((error) => logger.error('createChatMessage error', error));
    } else {
      logger.error('select a user before sending');
    }
  }
}
