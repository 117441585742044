<div class="econsentAnswer">
  <div class="p-d-flex p-justify-between p-ai-center p-mb-4">
    <p class="p-my-0 step-subtitle">
      <span class="p-pr-2">{{ 'informedConsent.step2' | translate }}</span>
      <span>{{ 'informedConsent.question.step2Description' | translate }}</span>
    </p>
    <button
      pButton
      pRipple
      label="{{ 'informedConsent.answer.addAnswer' | translate }}"
      (click)="onAddAnswer()"
      type="button"
      class="p-button-secondary"
      *ngIf="confICAnswers.length > 0"
    ></button>
  </div>

  <div *ngIf="confICAnswers.length > 0; else showEmptyAnswers">
    <p-table
      [value]="confICAnswers"
      [rowHover]="true"
      class="answerTable"
      [scrollable]="true"
      styleClass="p-datatable-gridlines"
    >
      <ng-template pTemplate="header">
        <tr class="header">
          <th id="order" pSortableColumn="order">
            {{ 'informedConsent.answer.answerOrder' | translate }}
            <p-sortIcon field="order"></p-sortIcon>
          </th>
          <th id="description" pSortableColumn="description">
            {{ 'informedConsent.answer.answerDescription' | translate }}
            <p-sortIcon field="description"></p-sortIcon>
          </th>
          <th id="isCorrect" pSortableColumn="isCorrect">
            {{ 'informedConsent.answer.answerIsCorrect' | translate }}
            <p-sortIcon field="isCorrect"></p-sortIcon>
          </th>
          <th id="onErrorMessage" pSortableColumn="onErrorMessage">
            {{ 'informedConsent.answer.answerOnError' | translate }}
            <p-sortIcon field="onErrorMessage"></p-sortIcon>
          </th>
          <th id="actions">
            {{ 'informedConsent.followUp.table.cols.actions' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-answer let-rowIndex="rowIndex">
        <tr *ngIf="answer?.state !== 'DELETED'">
          <td>{{ answer.order }}</td>
          <td [pTooltip]="answer.description">
            {{ answer.description | dictionary }}
          </td>
          <td
            [pTooltip]="
              (answer.isCorrect ? 'general.yes' : 'general.no') | translate
            "
          >
            <em
              *ngIf="answer.isCorrect"
              class="pi pi-check"
              style="font-size: 1.5rem; color: green"
            ></em>
            <em
              *ngIf="!answer.isCorrect"
              class="pi pi-times"
              style="font-size: 1.5rem; color: red"
            ></em>
          </td>
          <td [pTooltip]="answer.onErrorMessage">
            {{ answer.onErrorMessage | dictionary }}
          </td>
          <td>
            <button
              id="btn_edit"
              name="btn_edit"
              pButton
              type="button"
              icon="pi pi-pencil"
              class="btn-icon btn-edit"
              (click)="onEditAnswer(answer, rowIndex)"
            ></button>
            <button
              id="btn_delete"
              name="btn_delete"
              pButton
              type="button"
              icon="pi pi-trash"
              class="btn-icon btn-delete"
              [disabled]="isDeleteButtonDisabled"
              (click)="showDeleteModal(answer, rowIndex)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <ng-template #showEmptyAnswers>
    <app-not-found-items
      [description]="'informedConsent.answer.addAnswerDescription' | translate"
      [buttonDescription]="
        'informedConsent.answer.addAnswerButtonDescription' | translate
      "
      [buttonText]="'informedConsent.answer.addAnswer' | translate"
      (clickEmitter)="onAddAnswer()"
    ></app-not-found-items>
  </ng-template>
</div>

<p-dialog
  [(visible)]="showDialog"
  [style]="{ width: '460px' }"
  header="{{
    (!isConfAnswerEdition
      ? 'informedConsent.answer.addAnswer'
      : 'informedConsent.answer.editAnswer'
    ) | translate
  }}"
  [modal]="true"
  [draggable]="false"
  [closeOnEscape]="true"
  styleClass="p-fluid"
  class="custom-dialog-header"
>
  <ng-template pTemplate="content">
    <form #answerForm="ngForm" (submit)="onSubmitAnswer(answerForm)">
      <div class="field p-mb-3">
        <label for="answerOrder">
          {{ 'informedConsent.answer.answerOrder' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <input
          type="number"
          min="0"
          max="999"
          name="answerOrder"
          #answerOrder="ngModel"
          pInputText
          id="answerOrder"
          [(ngModel)]="currentConfICAnswer.order"
          required
        />
        <small
          class="form-error"
          *ngIf="
            answerOrder.invalid && (answerForm.submitted || answerOrder.touched)
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="field p-mb-3">
        <label for="answerDescription">
          {{ 'informedConsent.answer.answerDescription' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-input
          id="txt_answer_description"
          name="description"
          [isReadOnly]="false"
          [isRequired]="true"
          [projectId]="projectId"
          [value]="currentConfICAnswer.description"
          (outputEvent)="currentConfICAnswer.description = $event"
          [submitted]="answerForm.submitted"
        ></app-simple-dictionary-input>
      </div>
      <div class="field p-mb-3">
        <label for="answerIsCorrect">
          {{ 'informedConsent.answer.answerIsCorrect' | translate }}</label
        >
        <p-inputSwitch
          name="answerIsCorrect"
          #answerIsCorrect="ngModel"
          id="answerIsCorrect"
          [(ngModel)]="currentConfICAnswer.isCorrect"
          (onChange)="currentConfICAnswer.onErrorMessage = ''"
        ></p-inputSwitch>
      </div>
      <div class="field p-mb-6" *ngIf="!currentConfICAnswer.isCorrect">
        <label for="answerOnError">
          {{ 'informedConsent.answer.answerOnError' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-input
          id="txt_answer_message_error"
          name="error"
          [isReadOnly]="false"
          [isRequired]="true"
          [projectId]="projectId"
          [value]="currentConfICAnswer.onErrorMessage"
          (outputEvent)="currentConfICAnswer.onErrorMessage = $event"
          [submitted]="answerForm.submitted"
        ></app-simple-dictionary-input>
      </div>
      <div class="p-d-flex p-ac-center" style="gap: 1rem">
        <button
          id="btn_cancelAnswer"
          pButton
          pRipple
          label="{{ 'general.cancel' | translate }}"
          type="button"
          class="p-button-secondary p-button-outlined"
          (click)="onCancel()"
        ></button>

        <button
          id="btn_saveAnswer"
          pButton
          pRipple
          label="{{
            (isConfAnswerEdition ? 'general.edit' : 'general.add') | translate
          }}"
          type="submit"
          class="p-button-secondary"
        ></button>
      </div>
    </form>
  </ng-template>
</p-dialog>

<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="onDeleteAnswer()"
  position="fixed"
>
</app-delete-confirmation>
