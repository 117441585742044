export const SITES_QUERIES = {
  CreateSite: `mutation CreateSite($input: CreateSiteInput!, $condition: ModelSiteConditionInput) {
        createSite(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          additionalLanguages
          timezoneRecipients
          siteCode
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  DeleteSite: `mutation DeleteSite($input: DeleteSiteInput!, $condition: ModelSiteConditionInput) {
        deleteSite(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          additionalLanguages
          timezoneRecipients
          siteCode
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  SitesByProjectId: `query SitesByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        SitesByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            additionalLanguages
            timezoneRecipients
            siteCode
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  UpdateSite: `mutation UpdateSite($input: UpdateSiteInput!, $condition: ModelSiteConditionInput) {
        updateSite(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          additionalLanguages
          timezoneRecipients
          siteCode
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  GetSite: `query GetSite($id: ID!) {
        getSite(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          isDailyPendingDiariesNotificationEnable
          dailyPendingDiariesAlertRecipients
          isTimezoneNotificationEnable
          notificationSchedule
          additionalLanguages
          timezoneRecipients
          siteCode
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  AuthorizedSitesBySiteIdCustom: `query AuthorizedSitesBySiteIdCustom($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAuthorizedSiteFilterInput, $limit: Int, $nextToken: String) {
        AuthorizedSitesBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            user {
              __typename
              id
              login
              name
              email
              role
              state
              phoneNumber
              subjects
              sites
              projects
            }
            siteId
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
};
