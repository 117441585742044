import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';

@Component({
  selector: 'app-tour-guide-button',
  templateUrl: './tour-guide-button.component.html',
  styleUrls: ['./tour-guide-button.component.scss'],
})
export class TourGuideButtonComponent {
  @Input() pageTourDataType!: PageTourDataType;
  @Output() pageTourButtonClicked: EventEmitter<boolean> = new EventEmitter();
  constructor(private pageTourService: PageTourService) {}

  startOnDemandTour() {
    if (
      this.pageTourDataType ===
      PageTourDataType.DETAIL_SUBJECT_REPORTS_DETAIL_TOUR
    ) {
      return this.pageTourButtonClicked.emit(true);
    }
    this.pageTourService.startOnDemandTour(this.pageTourDataType, 0);
  }
}
