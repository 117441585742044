import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { SiteFormComponent } from './informed-consent-add-edit/components/site-form/site-form.component';
import { InformedConsentAddEditComponent } from './informed-consent-add-edit/informed-consent-add-edit.component';
import { InformedConsentDetailComponent } from './informed-consent-detail/informed-consent-detail.component';
import { InformedConsentListComponent } from './informed-consent-list/informed-consent-list.component';
import { InformedConsentRoutingModule } from './informed-consent-routing.module';
import { InformedConsentSectionModule } from './informed-consent-section/informed-consent-section.module';
import { InformedConsentFollowUpModule } from './informed-consent-follow-up/informed-consent-follow-up.module';
@NgModule({
  declarations: [
    InformedConsentListComponent,
    InformedConsentAddEditComponent,
    InformedConsentDetailComponent,
    SiteFormComponent,
  ],
  providers: [TranslatePipe],
  imports: [
    PrimengModule,
    SharedComponentsModule,
    InformedConsentRoutingModule,
    InformedConsentSectionModule,
    InformedConsentFollowUpModule,
    ReactiveFormsModule,
  ],
})
export class InformedConsentModule {}
