<div class="container-tp2 section-add-edit">
  <app-back-button-header
    [pageTitle]="
      (isEdition
        ? 'informedConsent.sections.editSection'
        : 'informedConsent.sections.addSection'
      ) | translate
    "
    [pageUrl]="
      '/informed-consent/' + projectId + '/detail/' + confInformedConsentId
    "
    [showButton]="false"
  ></app-back-button-header>
  <form
    name="form"
    class="tp2Card p-px-4 p-py-5"
    (ngSubmit)="onSubmit(f)"
    #f="ngForm"
    autocomplete="off"
  >
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-4">
        <label for="sectionTitle"
          >{{ 'informedConsent.sections.sectionTitle' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-input
          id="txt_sectionTitle"
          name="title"
          [isReadOnly]="isReadOnly"
          [isRequired]="true"
          [projectId]="projectId"
          [submitted]="f.submitted"
          [value]="currentConfICSectionInput.title"
          (outputEvent)="setSectionTitle($event)"
        ></app-simple-dictionary-input>
      </div>

      <div class="p-col-12 p-md-4">
        <label for="sectionType"
          >{{ 'informedConsent.sections.sectionType' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-dropdown
          [disabled]="isReadOnly"
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="sectionTypeOptions"
          optionValue="value"
          id="sectionType"
          name="sectionType"
          #sectionType="ngModel"
          [(ngModel)]="currentConfICSectionInput.type"
          [required]="true"
        ></p-dropdown>
        <p-message
          class="info"
          severity="info"
          [text]="'informedConsent.sections.signatureInfo' | translate"
          *ngIf="currentConfICSectionInput.type === 'CONSENT'"
        ></p-message>
        <p-message
          class="info"
          severity="info"
          [text]="'informedConsent.sections.signatureConsenterInfo' | translate"
          *ngIf="currentConfICSectionInput.type === 'CONSENTER_SIGNATURE'"
        ></p-message>
        <small
          class="form-error"
          *ngIf="!sectionType.valid && (f.submitted || sectionType.touched)"
        >
          {{ 'general.required' | translate }}
        </small>
      </div>

      <div class="p-field p-col-12 p-md-4" id="version">
        <label for="sectionOrder">
          {{ 'informedConsent.sections.sectionOrder' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <input
          name="sectionOrder"
          #sectionOrder="ngModel"
          required="true"
          [disabled]="isReadOnly"
          type="number"
          min="1"
          max="999"
          pInputText
          [(ngModel)]="currentConfICSectionInput.order"
        />
        <small
          class="form-error"
          *ngIf="!sectionOrder.valid && (f.submitted || sectionOrder.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <div class="p-col-12 p-md-12 p-mb-3">
        <label for="sectionContent"
          >{{ 'informedConsent.sections.sectionContent' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-editor
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="currentConfICSectionInput.content"
          (outputEvent)="currentConfICSectionInput.content = $event"
        >
        </app-simple-dictionary-editor>
      </div>

      <div
        class="p-col-12"
        *ngIf="currentConfICSectionInput.type === 'CONSENTER_SIGNATURE'"
      >
        <label
          for="cmb_isStudyRolRequired"
          id="lbl_isStudyRolRequired"
          class="p-d-block p-mb-2"
        >
          {{ 'informedConsent.sections.rolStudyLabel' | translate }}
        </label>
        <p-selectButton
          id="cmb_isStudyRolRequired"
          name="isStudyRolRequired"
          [options]="booleanOptions"
          [(ngModel)]="currentConfICSectionInput.isStudyRolRequired"
          optionLabel="label"
          optionValue="value"
          style="width: min-content; display: block"
        ></p-selectButton>
      </div>

      <div
        class="p-col-12 p-fluid"
        *ngIf="currentConfICSectionInput.type === 'OPTIONAL_CONSENT'"
      >
        <p-button
          id="btn_add"
          class="newQuestion"
          [label]="'informedConsent.sections.newQuestionBtn' | translate"
          icon="pi pi-plus"
          styleClass="p-button-outlined"
          [disabled]="!isComplementaryQuestionFormValid()"
          (click)="
            isComplementaryQuestionFormValid() && addComplementaryQuestion()
          "
        ></p-button>
        <div
          class="tp2Card p-px-3 p-py-4 p-mb-3"
          *ngFor="
            let complementaryQuestion of complementaryQuestions;
            let complementaryQuestionIndex = index
          "
        >
          <div
            class="close"
            *ngIf="complementaryQuestions.length > 1"
            (click)="showModalDelete(complementaryQuestionIndex)"
          >
            <span class="pi pi-times"></span>
          </div>
          <app-complementary-questions
            [projectId]="projectId"
            [complementaryQuestionType]="complementaryQuestionType"
            [complementaryQuestion]="complementaryQuestion"
            [generalForm]="f"
          ></app-complementary-questions>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6"
        *ngIf="currentConfICSectionInput.type !== 'CONSENTER_SIGNATURE'"
      >
        <label for="sectionType">{{
          'informedConsent.sections.sectionMediaType' | translate
        }}</label>
        <p-dropdown
          [disabled]="isReadOnly"
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="mediaTypeOptions"
          optionValue="value"
          id="mediaType"
          name="mediaType"
          #mediaType="ngModel"
          [(ngModel)]="currentConfICSectionInput.mediaType"
          (onChange)="onMediaTypeSelected($event.value, true)"
        ></p-dropdown>
      </div>

      <div
        *ngIf="
          currentConfICSectionInput.mediaType === 'AUDIO' ||
          currentConfICSectionInput.mediaType === 'PHOTO'
        "
        class="p-col-12 p-md-3"
      >
        <label for="sectionType">{{
          'informedConsent.sections.sectionMediaUrl' | translate
        }}</label>
        <p-button
          id="generateMedia"
          [label]="'informedConsent.sections.generateMedia' | translate"
          styleClass="p-button-outlined"
          (click)="onGenerateMedia()"
        ></p-button>
      </div>

      <div
        class="p-col-12"
        *ngIf="currentConfICSectionInput.type !== 'CONSENTER_SIGNATURE'"
      >
        <label for="sectionFile">{{
          'informedConsent.sections.sectionMediaUpload' | translate
        }}</label>

        <p-fileUpload
          name="sectionFile"
          id="sectionFile"
          #sectionFile
          [files]="myFiles"
          accept="{{ fileUploadAcceptType }}"
          (onSelect)="onFileSelected($event)"
          (onClear)="onClearFile()"
          (onRemove)="onClearFile()"
          [showUploadButton]="false"
          [disabled]="isReadOnly || !fileUploadAcceptType"
          cancelLabel="{{ 'general.cancel' | translate }}"
          chooseLabel="{{ 'informedConsent.labelUpload' | translate }}"
        >
        </p-fileUpload>
      </div>
      <div
        class="p-col-12 p-md-6 p-lg-3"
        *ngIf="
          isEdition &&
          currentConfICSectionInput.mediaUrl &&
          currentConfICSectionInput.type !== 'CONSENTER_SIGNATURE'
        "
      >
        <button
          pButton
          type="button"
          label="{{ 'informedConsent.currentFile' | translate }}"
          (click)="loadCurrentFile()"
        >
          <em class="pi pi-file-pdf"></em>
        </button>
      </div>
    </div>
    <div class="p-grid p-field p-fluid p-mt-3">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-4">
        <div class="p-grid p-field">
          <div class="p-col">
            <button
              class="p-button-secondary"
              routerLink="{{ [this.isEdition ? '../../..' : '../..'] }}"
              pButton
              type="button"
              label="{{ 'general.cancel' | translate }}"
            ></button>
          </div>
          <div class="p-col">
            <button
              [disabled]="isReadOnly"
              pButton
              type="submit"
              label="{{
                (isEdition ? 'general.edit' : 'general.add') | translate
              }}"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<p-dialog
  *ngIf="isEdition && displayModal"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '90vw', height: '90vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <object
    [data]="currentFileSanitizedUrl"
    type="application/pdf"
    width="100%"
    height="100%"
  >
    <iframe title="" width="100%" height="100%" [src]="currentFileSanitizedUrl">
    </iframe>
  </object>
</p-dialog>

<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="deleteComplementaryQuestion()"
  position="fixed"
>
</app-delete-confirmation>
