<div>
  <header class="title p-mt-2">
    <div class="p-d-flex">
      <button
        pButton
        pRipple
        type="button"
        class="p-button-text p-p-0 rm-border-button"
      >
        <img
          alt="logo"
          src="assets/icons/informed-consent/x.svg"
          style="width: 3.5rem"
          (click)="closeManageICPdfVersions()"
        />
      </button>
      <h1>
        {{
          'informedConsent.followUp.viewPdf.informedConsentVersions' | translate
        }}
      </h1>
    </div>
    <p>
      {{ 'informedConsent.followUp.viewPdf.viewPdfInfo' | translate }}
    </p>
  </header>
  <div class="grid-cards">
    <app-tp2-card
      *ngFor="let eCosent of eConsentPDFCards"
      [tp2Card]="eCosent"
      (tp2CardEmmiter)="openPdfModal(eCosent)"
    ></app-tp2-card>
  </div>
</div>
