import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutoCompleteOff]',
})
export class AutoCompleteOffDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  // Establece el campo en solo lectura al inicio
  ngAfterViewInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'readonly', 'true');
  }

  // Quita el modo de solo lectura cuando el campo gana el foco
  @HostListener('focus') onFocus() {
    this.renderer.removeAttribute(this.el.nativeElement, 'readonly');
  }

  // Vuelve a establecer el campo en solo lectura cuando pierde el foco
  @HostListener('blur') onBlur() {
    this.renderer.setAttribute(this.el.nativeElement, 'readonly', 'true');
  }
}
