<div class="summary p-mb-5">
  <div class="p-grid p-col-12 p-p-0">
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="original?.name || verified?.name"
    >
      <span id="lbl_verifiedName" name="llbl_verifiedName" for="verifiedName">
        {{ 'medicationinstance.medicineName' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="original?.name | textNa: verified?.name:'':true"
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="configuration?.showDose && (original?.dose || verified?.dose)"
    >
      <span id="lbl_verifiedDose" name="lbl_verifiedDose" for="verifiedDose">
        {{ 'medicationinstance.dose' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="original?.dose | textNa: verified?.dose:'':true"
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="isGivenToEnabled && (verified?.givenTo || verified?.givenTo)"
    >
      <span
        id="lbl_verifiedGivenTo"
        name="lbl_verifiedGivenTo"
        for="verifiedGivenTo"
      >
        {{ 'medicationinstance.givenTo' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.givenTo | textNa: verified?.givenTo:'givenTo':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        isSymptomsEnabled &&
        (original?.symptomsInstances || verified?.symptomsInstances)
      "
    >
      <span
        id="lbl_verifiedSymptomInstances"
        name="lbl_verifiedSymptomInstances"
        for="verifiedSymptomInstances"
      >
        {{ 'medicationinstance.reason' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.symptomsInstances
            | textNa: verified?.symptomsInstances:'':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="isReasonEnabled && (original?.reason || verified?.reason)"
    >
      <span
        id="lbl_verifiedReason"
        name="lbl_verifiedReason"
        for="verifiedReason"
      >
        {{ 'medicationinstance.reason' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="original?.reason | textNa: verified?.reason:'':true"
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showAdministrationRoute &&
        (original?.administrationRoute || verified?.administrationRoute)
      "
    >
      <span
        id="lbl_verifiedAdministrationRoute"
        name="lbl_verifiedAdministrationRoute"
        for="verifiedAdministrationRoute"
      >
        {{ 'medicationinstance.administracionRoute' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.administrationRoute
            | textNa: verified?.administrationRoute:'':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showFrequency &&
        (original?.frequency || verified?.frequency)
      "
    >
      <span
        id="lbl_verifiedFrequency"
        name="lbl_verifiedFrequency"
        for="verifiedFrequency"
      >
        {{ 'medicationinstance.frequency' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="original?.frequency | textNa: verified?.frequency:'':true"
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showRememberStartDate &&
        (![undefined, null].includes(original?.rememberStartDate) ||
          ![undefined, null].includes(verified?.rememberStartDate))
      "
    >
      <span
        id="lbl_verfiedRememberStartDate"
        name="lbl_verfiedRememberStartDate"
        for="verfiedRememberStartDate"
      >
        {{ 'symptominstance.startDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberStartDate
            | textNa: verified?.rememberStartDate:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        verified?.startDate ||
        original?.startDate ||
        verified?.rememberStartDate
      "
    >
      <span
        id="lbl_verifiedStartDate"
        name="lbl_verifiedStartDate"
        for="verifiedStartDate"
      >
        {{ 'symptominstance.startDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.startDate | textNa: verified?.startDate:'date':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        configuration?.showRememberFinishDate &&
        (![undefined, null].includes(original?.rememberFinishDate) ||
          ![undefined, null].includes(verified?.rememberFinishDate))
      "
    >
      <span
        id="lbl_verifiedRememberFinishDate"
        name="lbl_veriifiedRememberFinishDate"
        for="verifiedRememberFinishDate"
      >
        {{ 'symptominstance.endDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.rememberFinishDate
            | textNa: verified?.rememberFinishDate:'boolean':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        original?.finishDate ||
        verified?.finishDate ||
        verified?.rememberFinishDate
      "
    >
      <span
        id="lbl_verifiedFinishDate"
        name="lbl_verifiedFinishDate"
        for="verifiedFinishDate"
      >
        {{ 'symptominstance.endDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          original?.finishDate | textNa: verified?.finishDate:'date':true
        "
      ></div>
    </div>
  </div>
</div>

<div style="display: flex; justify-content: end">
  <button
    pButton
    type="button"
    (click)="onClose(false)"
    label="{{ 'general.cancel' | translate }}"
    icon="pi pi-times"
    class="p-mx-2"
  ></button>
  <button
    pButton
    type="button"
    (click)="onClose(true)"
    label="{{ 'general.confirm' | translate }}"
    icon="pi pi-check"
    class="p-button-secondary"
  ></button>
</div>
