import { Injectable } from '@angular/core';
import { API, Logger, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { ConfSymptom } from 'src/app/modules/ediary/ediary.types';
import {
  ConfReport,
  Intensity,
  ReportAvailableUserType,
} from 'src/app/modules/report/report.types';
import { SUBJECT_QUERIES } from 'src/app/modules/subjects/subjects.queries';
import {
  CreateReportInstanceInput,
  CreateSymptomInstanceInput,
  SymptomOccurrencyInput,
  UpdateReportInstanceInput,
  UpdateSymptomInstanceInput,
  tp2ValidateAndCompleteTransactionInput,
} from 'src/app/modules/subjects/subjects.types';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  ConfState,
  InstanceState,
  SignatureEntityType,
  SignatureReason,
  SignatureType,
} from 'src/app/services/trialpal.types';
const logger = new Logger('ReportExecutionService');
@Injectable({
  providedIn: 'root',
})
export class ReportExecutionService {
  pages: ReportPagination[] = [];
  currentPage: ReportPagination = ReportPagination.SYMPTOM;
  subjectId: string = '';
  reportInstance = {} as CreateReportInstanceInput | UpdateReportInstanceInput;
  confReport: ConfReport = {} as ConfReport;
  showSummaryButton: boolean = false;
  isReportInstanceEdition: boolean = false;

  symptomInstances: (
    | CreateSymptomInstanceInput
    | UpdateSymptomInstanceInput
  )[] = [];

  confSymptoms: ConfSymptom[] = [];

  booleanOptions: any[] = [];

  constructor(
    private trialpalService: TrialpalService,
    private authService: AuthService
  ) {}

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  //========== Funciones para el paginado ==========

  buildPagination() {
    this.pages = [];
    this.showSummaryButton = false;
    if (this.confReport.areSymptomsRequired) {
      this.pages.push(ReportPagination.SYMPTOM);
    }

    if (this.confReport.jsonForm?.length) {
      this.pages.push(ReportPagination.FORM);
    }

    this.pages.push(ReportPagination.REPORT_SUCCESS);
    this.currentPage = this.pages[0];
  }

  goToPage(page: ReportPagination) {
    this.currentPage = page;
  }

  goToNextPage() {
    const currentPageIndex = this.pages.indexOf(this.currentPage);
    this.currentPage = this.pages[currentPageIndex + 1];

    if (this.currentPage === ReportPagination.REPORT_SUCCESS) {
      this.showSummaryButton = true;
    }
  }

  goToPreviousPage() {
    const currentPageIndex = this.pages.indexOf(this.currentPage);
    this.currentPage = this.pages[currentPageIndex - 1];
  }

  //========== Funciones necesarias para sintomas ==========
  async getConfSymptomsByConfReportId(
    confReportId: string
  ): Promise<ConfSymptom[]> {
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.ConfSymptomByConfReportIdCustom,
      {
        confReportId,
      }
    );
    let { items } = response.data.confSymptomByConfReportId;
    items = items
      .filter(
        (confSymtom: any) =>
          !confSymtom._deleted && confSymtom.state !== ConfState.DELETED
      )
      .sort((a: any, b: any) => a.order - b.order);

    return items as ConfSymptom[];
  }

  async getConfSymptomById(id: string): Promise<ConfSymptom> {
    const confSymptom = this.confSymptoms.find(
      (confSymptom: ConfSymptom) => confSymptom.id === id
    );
    if (confSymptom) return confSymptom;
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.GetConfSymptom,
      {
        confSymptomId: id,
      }
    );
    return response.data.getConfSymptom;
  }

  async getSymptomInstanceById(
    id: string
  ): Promise<CreateSymptomInstanceInput | UpdateSymptomInstanceInput> {
    //Valida si encuentra el sintoma en la lista de sintomas (Por si es un sintoma nuevo que aun no se guarda en db)
    const symptomInstance = this.findSymptomInstanceBySymptomInstanceId(id);
    if (symptomInstance) return symptomInstance;
    const response = await this.performGraphQLQuery(
      SUBJECT_QUERIES.CustomGetSymptomInstance,
      {
        id,
      }
    );
    return response.data.getSymptomInstance;
  }

  //Función que se encarga de buscar en la lista de sintomas
  findSymptomInstanceBySymptomInstanceId(id: string) {
    return this.symptomInstances.find(
      (symptomInstance: any) => symptomInstance.id === id
    );
  }

  getSymptomLabel(confSymptom: ConfSymptom) {
    if (confSymptom.symptomLabel) {
      return this.dictionaryTransform(confSymptom?.symptomLabel);
    }

    return this.translateTransform(
      `symptom.enums.symptoms.${confSymptom.symptom}`
    );
  }

  getValueScale(confSymptom: ConfSymptom) {
    const valuesEscale = [];
    if (confSymptom.noneIntensityScaleOptions?.active) {
      const noneStart: ScaleRangeOptions = {
        startValue: confSymptom.noneIntensityScaleOptions.startValue,
        endValue: confSymptom.noneIntensityScaleOptions.endValue,
        intensity: Intensity.NONE,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.NONE
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.noneIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(noneStart);
    }

    if (confSymptom.lowIntensityScaleOptions?.active) {
      const lowStart: ScaleRangeOptions = {
        startValue: confSymptom.lowIntensityScaleOptions.startValue,
        endValue: confSymptom.lowIntensityScaleOptions.endValue,
        intensity: Intensity.LOW,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.LOW
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.lowIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(lowStart);
    }
    if (confSymptom.mediumIntensityScaleOptions?.active) {
      const mediumStart: ScaleRangeOptions = {
        startValue: confSymptom.mediumIntensityScaleOptions.startValue,
        endValue: confSymptom.mediumIntensityScaleOptions.endValue,
        intensity: Intensity.MEDIUM,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.MEDIUM
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.mediumIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(mediumStart);
    }

    if (confSymptom.highIntensityScaleOptions?.active) {
      const highStart: ScaleRangeOptions = {
        startValue: confSymptom.highIntensityScaleOptions.startValue,
        endValue: confSymptom.highIntensityScaleOptions.endValue,
        intensity: Intensity.HIGH,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.HIGH
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.highIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(highStart);
    }

    if (confSymptom.lifeThreateningScaleOptions?.active) {
      const lifeStart: ScaleRangeOptions = {
        startValue: confSymptom.lifeThreateningScaleOptions.startValue,
        endValue: confSymptom.lifeThreateningScaleOptions.endValue,
        intensity: Intensity.LIFE_THREATENING,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.LIFE_THREATENING
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.lifeThreateningScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(lifeStart);
    }
    return valuesEscale;
  }

  //Obtiene el rango de valores cuando el sintoma es por rango
  getIntRange(valuesEscale: any[]): any[] {
    const intRange = [];
    const values: number[] = [];
    valuesEscale.forEach((scaleOption: ScaleRangeOptions) => {
      const startValues = scaleOption.startValue.toString().split('.');
      if (startValues?.[0]) {
        values.push(parseInt(startValues[0]));
      }
      const endValues = scaleOption.endValue.toString().split('.');
      if (endValues?.[0]) {
        values.push(parseInt(endValues[0]));
      }
    });
    const maxValues = Math.max(...values);
    const minValues = Math.min(...values);

    for (let i = minValues; i <= maxValues; i++) {
      intRange.push(String(i));
    }

    return intRange;
  }

  //Obtiene los el rango de decimales si este está habilitado dentro del sintoma actual
  getDecimalRange(confSymptom: any): any[] {
    let decimalRange = [];
    const decimalPlaces = confSymptom?.decimalPlaces ?? 0;

    const isDecimalActive = decimalPlaces !== 0;

    decimalRange = new Array(Number('9'.repeat(decimalPlaces)) + 1)
      .fill(0)
      .map((_x, i) => String(i)); // array que va de 0 hasta el valor n

    return [isDecimalActive, decimalRange];
  }

  /**
   * Obtener la Mayor Intesidad de las preguntas
   * @returns Intensity
   */
  getIntensityFromQuestion(intensityQuestionAnswersSave: any = []) {
    // Filtro las Preguntas Positivas
    const intensityQuestionPositive = intensityQuestionAnswersSave.filter(
      (x: any) => x.answer === true
    );
    if (!intensityQuestionPositive.length) {
      return null;
    }
    // Filtro las Intensidades Repetidas
    const hash: any = {};
    const intensityQuestionUnique = intensityQuestionPositive.filter(
      (current: any) => {
        const exists = !hash[current.question.intensity];
        hash[current.question.intensity] = true;
        return exists;
      }
    );
    // Creo una lista de Intensidades
    const intensityQuestionOrderInit = intensityQuestionUnique.map((y: any) => {
      return {
        intensity: y.question.intensity,
      };
    });
    // obtener la intensidad mayor
    return this.getIntensityOrder(intensityQuestionOrderInit);
  }

  getIntensityOrder(intensityQuestionOrderInit: any[] = []) {
    const intensities = [
      Intensity.NONE,
      Intensity.LOW,
      Intensity.MEDIUM,
      Intensity.HIGH,
      Intensity.LIFE_THREATENING,
    ];
    // obtener el indice del array de Intensidades
    const intensityQuestionOrder = [];
    for (const intensityQuestion of intensityQuestionOrderInit) {
      const order = intensities.findIndex(
        (intensity: any) => intensity === intensityQuestion.intensity
      );
      intensityQuestionOrder.push({
        intensity: intensityQuestion.intensity,
        order: order,
      });
    }
    intensityQuestionOrder.sort((a, b) => b.order - a.order);
    return intensityQuestionOrder[0]?.intensity;
  }

  getCurrentDate() {
    const currentDate = moment().format('yyyy-MM-DD');
    return new Date(currentDate + 'T00:00:00');
  }

  getFormatDate(date: any) {
    if (!date) return date;
    const transformDate =
      typeof date === 'string'
        ? date?.substring(0, 10)
        : moment(date).format('yyyy-MM-DD');
    return new Date(transformDate + 'T00:00:00');
  }

  //======== Funciones para enviar el reporte al back ============

  async completeReport(): Promise<void> {
    try {
      this.showSpinner(
        this.trialpalService.translateService.instant(
          'report.execution.saveInformation'
        )
      );
      const symptomInstances = await this.transformSymptomInstance();
      const reportInstance = await this.transformReportInstance();
      const signatureInstance = this.getSignatureInstance();
      const input: tp2ValidateAndCompleteTransactionInput = {
        reportInstance: JSON.stringify(reportInstance),
        mediaRecordLogs: [],
        signatureInstance: JSON.stringify(signatureInstance),
        symptomInstances: symptomInstances,
        symptomRecordLogs: [],
        user: null,
        deviceId: null,
      };

      logger.debug('Input enviando -->', {
        reportInstance,
        signatureInstance,
        symptomInstances,
      });

      const response = await this.performGraphQLQuery(
        SUBJECT_QUERIES.Tp2ValidateAndCompleteTransaction,
        {
          input,
        }
      );

      logger.debug('Input enviando -->', response);
      this.trialpalService.hideSpinner();
    } catch (error: any) {
      logger.debug('complete report error -->', error);
      this.trialpalService.hideSpinner();
      return Promise.reject(new Error(error));
    }
  }

  async transformSymptomInstance(): Promise<any[]> {
    const symptomInstances = [];
    const symptomOcurrencies = this.reportInstance.symptomOcurrencies ?? [];
    for (const symptomOccurrency of symptomOcurrencies) {
      const { occurrency, symptomInstanceId } = symptomOccurrency;
      if (!symptomInstanceId) continue;
      const response = await this.performGraphQLQuery(
        SUBJECT_QUERIES.GetSymptomInstance,
        {
          id: symptomInstanceId,
        }
      );
      //Obtiene la instancia de sintoma que actualmente se encuentra en base de datos
      const oldSymptomInstance = response.data.getSymptomInstance;
      const symptomInstance = await this.getSymptomInstanceById(
        symptomInstanceId
      );

      //Asigna que se guardarán solo los que tienen ocurrencia como true
      let saveSymptomInstance = occurrency;

      //Si la ocurrencia es no y el sintoma se encuentra en base de datos, lo paso a eliminado
      if (!occurrency && oldSymptomInstance?.id) {
        symptomInstance.state = InstanceState.DELETED;
        saveSymptomInstance = true; //Se guarda, dado que el sintoma se eliminará
      }

      if (saveSymptomInstance) {
        symptomInstances.push(
          JSON.stringify({ old: oldSymptomInstance, new: symptomInstance })
        );
      }
    }

    return symptomInstances;
  }

  //Transforman las fechas a la zona horaria
  transformDate(newDate: any) {
    if (!newDate) return newDate;
    return new Date(moment(newDate).format('yyyy-MM-DD')).toISOString();
  }

  async transformReportInstance(): Promise<any> {
    let oldReportInstance = {};
    if (this.reportInstance.id) {
      const response = await this.performGraphQLQuery(
        SUBJECT_QUERIES.GetReportInstance,
        {
          id: this.reportInstance.id,
        }
      );
      oldReportInstance = response.data.getReportInstance;
    }

    this.reportInstance.completeDate = new Date().toISOString();
    this.reportInstance.state = InstanceState.COMPLETED;
    this.reportInstance.isAlertChecked = false;
    this.reportInstance.isPDFGenerated = false;
    this.reportInstance._lastUser = this.getUsername();
    this.reportInstance.reportedBy = {
      username: this.getUsername(),
      userType: ReportAvailableUserType.INVESTIGATOR,
    };

    //Elimina los symptomInstanceId de los que no tuvieron ocurrencia
    const symptomOcurrencies = this.reportInstance.symptomOcurrencies;
    this.reportInstance.symptomOcurrencies = symptomOcurrencies?.map(
      (symptomOccurrency: SymptomOccurrencyInput) => {
        if (!symptomOccurrency.occurrency) {
          symptomOccurrency.symptomInstanceId = '';
        }
        return symptomOccurrency;
      }
    );

    return { old: oldReportInstance, new: this.reportInstance };
  }

  getSignatureInstance() {
    if (this.confReport.isSignatureRequired) {
      const signatureInstance = {
        date: new Date().toISOString(),
        entityId: this.reportInstance.id ?? '',
        entityType: SignatureEntityType.REPORT,
        reason: SignatureReason.AUTHORSHIP,
        signedInfo: JSON.stringify(this.reportInstance),
        subjectId: this.subjectId,
        type: SignatureType.USER_PASS,
        _changeReason: 'Complete report by investigator',
        _lastUser: this.authService.getUsername(),
      };
      return { old: {}, new: signatureInstance };
    }

    return {};
  }

  clearVariables() {
    this.symptomInstances = [];
    this.reportInstance = {} as CreateReportInstanceInput;
    this.confSymptoms = [];
    this.confReport = {} as ConfReport;
    this.isReportInstanceEdition = false;
  }

  haveAttributesMutated(
    originalObj: any,
    modifiedObj: any,
    attributes: string[]
  ): boolean {
    for (let attribute of attributes) {
      // Transforma los valores a cadena de texto (Para validar objetos)
      const transformOriginalObj = JSON.stringify(originalObj[attribute]);
      const transformModifiedObj = JSON.stringify(modifiedObj[attribute]);
      if (transformOriginalObj !== transformModifiedObj) return true; // Hay una diferencia, el objeto ha mutado
    }
    return false; // No se encontraron mutaciones en los atributos especificados
  }

  //====== Funciones generales para ======

  getCurrentDateWitHours() {
    return moment().format('YYYY-MM-DDTHH:mm:ss.sssZ');
  }

  getUsername(): string {
    return this.authService.getUsername();
  }

  dictionaryTransform(key: string) {
    return this.trialpalService.dictionaryPipe.transform(key);
  }

  translateTransform(key: string) {
    return this.trialpalService.translateService.instant(key);
  }

  showSpinner(message: string): void {
    this.trialpalService.showSpinner(message);
  }

  hideSpinner() {
    this.trialpalService.hideSpinner();
  }

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}

interface ScaleRangeOptions {
  startValue: number;
  endValue: number;
  intensity: Intensity;
  label: string;
}

export enum ReportPagination {
  SYMPTOM = 'SYMPTOM',
  FORM = 'FORM',
  REPORT_SUCCESS = 'REPORT_SUCCESS',
}
