<ng-container *ngIf="!subjectIdParam">
  <div class="p-grid">
    <app-button-refresh-data
      [showSpinner]="showSpinner"
      (buttonAction)="ngOnInit()"
    ></app-button-refresh-data>
    <app-button-table-card-mode
      dataSource="subjectAlertsList"
      class="p-col-11"
      *ngIf="listAlert && listAlert.length > 0 && !showSpinner"
      (cardOrTableModeEmit)="tableViewMode = $event"
    ></app-button-table-card-mode>
  </div>

  <app-tour-guide-button
    [pageTourDataType]="DETAIL_SUBJECT_ALERT_TOUR"
    *ngIf="listAlert?.length && !showSpinner"
  ></app-tour-guide-button>

  <section *ngIf="!showSpinner">
    <div *ngIf="listAlert && listAlert.length > 0" id="alerts_container">
      <section *ngIf="!tableViewMode">
        <div class="p-grid p-fluid">
          <app-tpcard
            class="p-col-12 p-md-4 p-lg-3"
            *ngFor="let alert of dataCard"
            [tpCardData]="alert"
            (detail)="openDetail(alert)"
          ></app-tpcard>
        </div>
      </section>
      <section *ngIf="tableViewMode">
        <app-tptable
          (openDetail)="findAlertInstance($event)"
          [timeZoneOffset]="timeZoneOffset"
          [dataTable]="dataTable"
        ></app-tptable>
      </section>
    </div>

    <div class="p-grid p-fluid" *ngIf="listAlert && listAlert.length === 0">
      <h4 style="width: 100%; text-align: center">
        {{ 'general.noResultsFound' | translate }}
      </h4>
    </div>
  </section>
</ng-container>
<p-dialog
  header=" "
  [modal]="true"
  [(visible)]="display"
  [style]="{ width: '80vw', height: '80vh' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-fieldset
    legend="
{{ 'ediary.components.alertAddEdit.alertDetails' | translate }}
"
  >
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6">
        <p>
          <label>
            {{
              'ediary.components.alertAddEdit.labelAlertName' | translate
            }}:</label
          >
          {{ alertName }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label>
            {{
              'ediary.components.alertAddEdit.labelNotificationMethod'
                | translate
            }}:</label
          >
          {{ type }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label>
            {{
              'ediary.components.alertAddEdit.labelRecipients' | translate
            }}:</label
          >
          {{ recipients }}
        </p>
      </div>
      <div class="p-col-12 p-md-6" *ngIf="type === 'EMAIL'">
        <p>
          <label>
            {{
              'ediary.components.alertAddEdit.labelMailSubject' | translate
            }}:</label
          >
          {{ mailSubject }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label *ngIf="type === 'EMAIL'">
            {{
              'ediary.components.alertAddEdit.labelMailBody' | translate
            }}:</label
          >
          <label *ngIf="type === 'SMS'">
            {{
              'ediary.components.alertAddEdit.labelSMSBody' | translate
            }}:</label
          >

          {{ messageBody }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label>
            {{
              'ediary.components.alertAddEdit.labelDateAndTime' | translate
            }}:</label
          >
          {{ createdAt }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label>
            {{
              'ediary.components.alertAddEdit.labelOrigin' | translate
            }}:</label
          >
          {{ getEntityName(alertSelected.entityName) }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label>
            {{ 'addEditSubjectComponent.labelState' | translate }}:</label
          >
          {{ state }}
        </p>
      </div>
      <div class="p-col-12 p-md-6">
        <p>
          <label> {{ 'general.alerts.revisionStatus' | translate }}:</label>
          {{ revisionState }}
        </p>
      </div>
      <div class="p-col-12">
        <p-card>
          <label
            >{{
              'ediary.components.alertAddEdit.labelAlertRule' | translate
            }}:</label
          >
          <div class="p-col-12 p-grid p-mt-1">
            <div class="p-col-6">
              <label for="">{{
                'ediary.components.alertAddEdit.labelAlertType' | translate
              }}</label>
            </div>
            <div class="p-col-4">
              <label for="">{{
                'ediary.components.alertAddEdit.labelAlertRuleValue' | translate
              }}</label>
            </div>
          </div>
          <div
            class="p-grid"
            *ngFor="let alertRule of alertRules; let i = index"
          >
            <div class="p-col-6">
              <textarea
                type="text"
                pInputText
                [value]="alertRule.alertRuleTypeName"
                [disabled]="true"
              ></textarea>
            </div>
            <div
              class="p-col-3"
              *ngIf="
                alertRule.alertRuleType === 'SYMPTOM_INTENSITY_IN' ||
                alertRule.alertRuleType === 'SYMPTOM_OCCURRENCY' ||
                alertRule.alertRuleType ===
                  'SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN' ||
                alertRule.alertRuleType ===
                  'SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN' ||
                alertRule.alertRuleType === 'SYMPTOMS_OCURRENCY_EQUALS'
              "
            >
              <div>
                <textarea
                  pInputText
                  [value]="
                    alertRule?.nameSymptomConfig
                      ? alertRule.nameSymptomConfig
                      : alertRule.symptom
                  "
                  [disabled]="true"
                ></textarea>
              </div>
            </div>

            <div
              class="p-col-3"
              *ngIf="
                alertRule.alertRuleType ===
                  'SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN' ||
                alertRule.alertRuleType ===
                  'SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN' ||
                alertRule.alertRuleType === 'SYMPTOMS_OCURRENCY_EQUALS'
              "
            >
              <textarea
                pInputText
                [value]="alertRule.expectedValue"
                [disabled]="true"
              ></textarea>
            </div>

            <div
              class="p-col-3"
              *ngIf="alertRule.alertRuleType === 'SYMPTOM_INTENSITY_IN'"
            >
              <div>
                <textarea
                  pInputText
                  [value]="alertRule.intensity"
                  [disabled]="true"
                ></textarea>
              </div>
            </div>
            <div
              class="p-col-6"
              *ngIf="alertRule.alertRuleType !== 'SYMPTOM_INTENSITY_IN'"
            >
              <textarea
                *ngIf="
                  alertRule.alertRuleType === 'TEMPERATURE_EQUALS_OR_MORE_THAN'
                "
                pInputText
                value="{{
                  this.getEnumScaleTemperature(alertRule.expectedValue)
                }}"
                [disabled]="true"
              ></textarea>
              <textarea
                *ngIf="
                  alertRule.alertRuleType === 'JSONFORM_ANSWER' ||
                  alertRule.alertRuleType === 'WITHOUT_SYMPTOM_OCCURRENCY'
                "
                pInputText
                [value]="alertRule.expectedValue"
                [disabled]="true"
              ></textarea>
              <textarea
                *ngIf="
                  alertRule.alertRuleType ===
                  'SYMPTOM_VALUE_EQUALS_OR_MORE_THAN'
                "
                pInputText
                value="{{
                  this.getSymptomOrNameSymptom(
                    alertRule.expectedValue,
                    alertRule?.nameSymptomConfig
                  )
                }}"
                [disabled]="true"
              ></textarea>
              <textarea
                *ngIf="
                  alertRule.alertRuleType ===
                  'SYMPTOM_VALUE_EQUALS_OR_LESS_THAN'
                "
                pInputText
                value="{{
                  this.getSymptomOrNameSymptom(
                    alertRule.expectedValue,
                    alertRule?.nameSymptomConfig
                  )
                }}"
                [disabled]="true"
              ></textarea>
              <textarea
                *ngIf="alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS'"
                pInputText
                value="{{
                  this.getSymptomOrNameSymptom(
                    alertRule.expectedValue,
                    alertRule?.nameSymptomConfig
                  )
                }}"
                [disabled]="true"
              ></textarea>
              <textarea
                *ngIf="
                  alertRule.alertRuleType === 'TOTAL_FORM_VALUE_EQUALS' ||
                  alertRule.alertRuleType === 'TOTAL_FORM_VALUE_LESS_THAN' ||
                  alertRule.alertRuleType === 'TOTAL_FORM_VALUE_MORE_THAN'
                "
                pInputText
                value="{{ alertRule.expectedValue }}"
                [disabled]="true"
              ></textarea>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </p-fieldset>
</p-dialog>
<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
