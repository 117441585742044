import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { Subscription } from 'rxjs';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AppConfigService } from 'src/app/services/appconfigservice';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { AppConfig } from '../../../domain/appconfig';
import { ProjectService } from '../../project/project.service';
import { SitesService } from '../../sites/sites.service';
import { EdiaryService } from '../ediary.service';
import { InstanceState } from 'src/app/services/trialpal.types';

const logger = new Logger('tp2-logger-reportStatusPage');
@Component({
  selector: 'app-ediary-report-status',
  templateUrl: './ediary-report-status.component.html',
  styleUrls: ['./ediary-report-status.component.scss'],
})
export class EdiaryReportStatusComponent implements OnInit {
  projectId = '';
  frequency = '';
  frequencies: any[] = [];
  basicOptions: any;
  stackedData: any;
  stackedOptions: any;
  config!: AppConfig;
  subscription!: Subscription;
  dayInstances: any[] = [];
  dayInstancesTable: any = [];
  monthNamesShort: any[] = [];
  dateComplete: any[] = [];
  datePlaneate: any[] = [];
  date: any[] = [];
  projectSites: any[] = [];
  projectConfDays: any[] = [];
  projectVisits: any[] = [];
  selectedVisits: string[] = [];
  selectedSites: string[] = [];
  selectedConfDays: string[] = [];
  adherence: any[] = [];
  subjects: any[] = [];
  title2: string = '';
  pending: string = '';
  title = '';
  year = 0;
  month = 0;
  day = 0;
  dayTotal = 0;
  filter = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };

  constructor(
    private readonly route: ActivatedRoute,
    private ediaryService: EdiaryService,
    private readonly trialpalService: TrialpalService,
    private configService: AppConfigService,
    private transformDatePipe: TransformDatePipe,
    private sitesService: SitesService,
    private projectService: ProjectService,
    private router: Router
  ) {}
  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('ediary.entityPlural', 'SEARCH');
    this.projectId = this.route.snapshot.params.projectId;
    const confEdiary = await this.ediaryService.confEdiaryByProject(
      this.projectId
    );
    this.frequencies = this.ediaryService.getfrequency();
    this.graphReport();
    this.monthNamesShort = this.trialpalService.translateService.instant(
      'primeng.monthNamesShort'
    );
    if (confEdiary.items.length > 0) {
      logger.debug('confEdiaryByProject response', confEdiary);
      const ConfEDiaryId = confEdiary?.items[0]?.id ?? '';
      if (ConfEDiaryId) {
        this.getVisitsByProject();
        await this.getSitesByProject();
        await this.getConfDayByConfEDiaryId(ConfEDiaryId);
      }
    }
    this.dayTotalCalculate();
    this.trialpalService.hideSpinner();
  }

  async onSubmit(form: NgForm) {
    if (form.valid) {
      this.trialpalService.showSpinner('ediary.entityPlural', 'SEARCH');
      this.filter = false;
      this.dayInstances =
        await this.ediaryService.getDayInstanceBySitesConfDays(
          this.selectedSites,
          this.selectedConfDays
        );
      if (this.dayInstances && this.dayInstances.length > 0) {
        this.dayInstances = this.dayInstances.filter(
          (day) =>
            day &&
            day.state !== InstanceState.DELETED &&
            !day._deleted &&
            day.state !== InstanceState.SUSPENDED &&
            day.eDiaryPhaseInstance?.phase &&
            this.selectedVisits.findIndex(
              (visit) => visit === day.eDiaryPhaseInstance.phase
            ) > -1 &&
            this.selectedSites.findIndex((site) => site === day.siteId) > -1
        );
        logger.debug(
          'dayInstanceByEDiaryPhaseInstance response',
          this.dayInstances
        );
      }
      this.reportMasterWithCurrentTime();
      this.dataTable = this.buildTPTableData(this.dayInstancesTable);
      this.trialpalService.hideSpinner();
    }
  }

  isDisabledSearchButton(): boolean {
    if (!this.selectedSites || this.selectedSites.length === 0) {
      return true;
    }
    if (!this.selectedConfDays || this.selectedConfDays.length === 0) {
      return true;
    }
    if (!this.frequency) {
      return true;
    }
    return false;
  }

  reportMasterWithCurrentTime(): void {
    if (this.frequency) {
      this.filterPendingCurrentFrecuency(this.dayInstances, this.frequency);
      this.graphReport();
      this.title = this.createTitle();
      this.title2 = this.createTitle2();
      const data = this.filter ? this.subjects : this.dayInstancesTable;
      this.dataTable = this.buildTPTableData(data);
    }
  }

  /**
 * Obtiene una lista de dayInstance, filtra los eliminados, los clasifica
 * por orden y luego los asigna a una nueva matriz de objetos con una propiedad de valor y etiqueta.

 * @param {any} eDiaryId - cuerda
 */
  async getConfDayByConfEDiaryId(eDiaryId: any) {
    let confDays = await this.ediaryService.getConfDayByConfEdiaryId(eDiaryId);
    confDays = confDays.items.filter((x: any) => !x._deleted);
    if (confDays) {
      confDays = confDays.sort((a: any, b: any) =>
        a.order > b.order ? 1 : -1
      );
      this.projectConfDays = confDays.map((confDay: any) => {
        return {
          value: confDay.id,
          label: this.trialpalService.dictionaryPipe.transform(confDay.dayName),
          phases: confDay.phases,
        };
      });
    }
  }

  async getSitesByProject() {
    const sites = await this.sitesService.getSitesByProject(this.projectId);
    if (sites) {
      this.projectSites = sites.map((site: any) => {
        return {
          value: site.id,
          label: this.trialpalService.dictionaryPipe.transform(site.name),
        };
      });
    }
  }

  getVisitsByProject() {
    const project = this.projectService.getCurrentProject();
    if (project) {
      this.projectVisits = project.phases.map((phase: any) => {
        return {
          value: phase,
          label: this.trialpalService.dictionaryPipe.transform(phase),
        };
      });
    }
  }

  //Obtiene la confDays respectos a las visitas selecionadas
  getDaysByVisit(): any[] {
    let confDays: any[] = [];
    for (let visit of this.selectedVisits) {
      let currentConfDays = this.projectConfDays.filter(
        (confDay: any) =>
          confDay.phases &&
          confDay.phases.findIndex((phase: any) => phase === visit) > -1
      );
      confDays = confDays.concat(currentConfDays);
    }

    return confDays.filter((item, index) => {
      return confDays.indexOf(item) === index;
    });
  }

  //Esta funcion controla que cada vez que desmarquen una visita
  //esta debe actulizar la lista de confDays selecionados
  controlDays() {
    if (!this.selectedVisits || this.selectedVisits.length == 0) {
      this.selectedConfDays = [];
    }
    if (this.selectedConfDays.length > 0) {
      let confDays = this.getDaysByVisit();
      this.selectedConfDays = this.selectedConfDays.filter(
        (confDay: any) =>
          confDays.findIndex((x: any) => x.value === confDay) > -1
      );
    }
  }

  resetVariables(): void {
    if (this.filter === true) this.subjects = [];
    this.datePlaneate = [];
    this.dateComplete = [];
    this.subjects = [];
    this.date = [];
    this.dayInstancesTable = [];
  }

  filterRange(response: any, range: any): void {
    if (range === 'Daily') {
      this.filterByDay(response, 1, 0, false);
    }
    if (range === 'Weekly') {
      this.filterByWeekly(response, 0, false);
    }
    if (range === 'Monthly') {
      this.filterByMonthly(response, 0, false);
    }
  }

  filterByDay(response: any, i: any, d: any, filter: boolean): any {
    if (this.filter === true && filter === false) {
      this.subjects = this.subjects.concat(
        response.filter(
          (e: any) =>
            e.state !== InstanceState.COMPLETED &&
            this.dailyReports(e, this.dayTotal, i * d)
        )
      );
      this.removeRepeats();
    }
    if (this.filter === false && filter === true) {
      this.subjects = this.subjects.concat(
        response.filter(
          (e: any) =>
            e.state !== InstanceState.COMPLETED &&
            this.dailyReports(e, this.dayTotal, i * d)
        )
      );
    }
  }

  filterByWeekly(response: any, i: any, filter: boolean): any {
    if (this.filter === true && filter === false) {
      this.subjects = this.subjects.concat(
        response.filter(
          (e: any) =>
            e.state !== InstanceState.COMPLETED &&
            this.weeklyReports(e, this.year, i)
        )
      );
      this.removeRepeats();
    }
    if (this.filter === false && filter === true) {
      this.subjects = this.subjects.concat(
        response.filter(
          (e: any) =>
            e.state !== InstanceState.COMPLETED &&
            this.weeklyReports(e, this.year, i)
        )
      );
    }
  }

  filterByMonthly(response: any, i: any, filter: boolean): any {
    if (this.filter === true && filter === false) {
      this.subjects = this.subjects.concat(
        response.filter(
          (e: any) =>
            e.state !== InstanceState.COMPLETED &&
            this.monthlyReports(e, this.year, this.month, i)
        )
      );
      this.removeRepeats();
    }
    if (this.filter === false && filter === true) {
      this.subjects = this.subjects.concat(
        response.filter(
          (e: any) =>
            e.state !== InstanceState.COMPLETED &&
            this.monthlyReports(e, this.year, this.month, i)
        )
      );
    }
  }

  removeRepeats(): any {
    const hash: any = {};
    const filteredSubjects: any[] = [];

    for (const o of this.subjects) {
      if (!hash[o.subjectId]) {
        hash[o.subjectId] = true;
        filteredSubjects.push(o);
      }
    }

    this.subjects = filteredSubjects;
  }

  reportDayTotal(response: any): any {
    const Reportyear = this.transformDatePipe.getYear(response);
    const Reportmonth = this.transformDatePipe.getMonth(response);
    const Reportday = this.transformDatePipe.getDay(response);
    return (
      Reportyear * 365 +
      this.transformDatePipe.getDaysInYear(Reportyear, Reportmonth, Reportday)
    );
  }
  lastReportedDate(id: any): any {
    const values = this.dayInstances.filter(
      (e: any) =>
        e.subjectId === id &&
        e.state === InstanceState.COMPLETED &&
        e.finishDate
    );
    let lastReportedDate = {
      label: this.trialpalService.translateService.instant(
        'report.noReportedDays'
      ),
      value: '0',
    };
    let time = 0;
    for (const p of values) {
      const ReportdayTotal = this.reportDayTotal(p.startDate);
      if (ReportdayTotal > time) {
        time = ReportdayTotal;
        lastReportedDate = {
          label:
            this.trialpalService.translateService.instant('report.lastReport') +
            ': ' +
            this.transformDatePipe.transform(p.startDate),
          value: p.startDate,
        };
      }
    }
    return lastReportedDate;
  }
  daysWithoutReporting(dayReport: any, state: boolean): any {
    return state === true
      ? this.dayTotal - this.reportDayTotal(dayReport)
      : '0';
  }
  getDaysWithoutReport(ultimateDay: any) {
    let daysWithoutReport = 'N/A';
    if (ultimateDay.value !== '0') {
      daysWithoutReport =
        this.daysWithoutReporting(ultimateDay.value, true) +
        ' ' +
        this.trialpalService.translateService.instant(
          'report.daysWithoutReporting'
        );
    }
    return daysWithoutReport;
  }

  createTitle(): string {
    return this.trialpalService.translateService.instant(
      'ediary.title.' + this.frequency
    );
  }
  createTitle2(): string {
    this.pending = this.trialpalService.translateService.instant(
      'report.pending.' + this.frequency
    );
    return this.trialpalService.translateService.instant(
      'ediary.title2.' + this.frequency
    );
  }
  dayTotalCalculate(): any {
    const today = new Date();
    this.year = this.transformDatePipe.getYear(today);
    this.month = this.transformDatePipe.getMonth(today);
    this.day = this.transformDatePipe.getDay(today);
    this.dayTotal =
      this.year * 365 +
      this.transformDatePipe.getDaysInYear(this.year, this.month, this.day);
  }

  filterPendingCurrentFrecuency(filteredDaysInstance: any, range: any): void {
    this.resetVariables();
    this.datePlaneate = [];
    this.dateComplete = [];
    this.date = [];
    this.filterRange(filteredDaysInstance, range);
    let d = 1;
    for (let i = 0; i < 15; i++) {
      if (range === 'Daily') {
        const planned = filteredDaysInstance.filter((e: any) =>
          this.dailyReports(e, this.dayTotal, i * d)
        );
        const completed = filteredDaysInstance.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.dailyReports(e, this.dayTotal, i * d)
        );
        this.filterByDay(filteredDaysInstance, i, d, true);
        const value = this.getDay(this.month - 1, this.day - i, this.year);
        this.pushValues(planned, completed, value);
      } else if (range === 'Weekly') {
        const planned = filteredDaysInstance.filter((e: any) =>
          this.weeklyReports(e, this.year, i)
        );
        const completed = filteredDaysInstance.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.weeklyReports(e, this.year, i)
        );
        this.filterByWeekly(filteredDaysInstance, i, true);
        const valu = this.getWeekly(this.weeyOfYear() - i);
        this.pushValues(planned, completed, valu);
      } else if (range === 'Monthly') {
        const planned = filteredDaysInstance.filter((e: any) =>
          this.monthlyReports(e, this.year, this.month, i)
        );
        const completed = filteredDaysInstance.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.monthlyReports(e, this.year, this.month, i)
        );
        this.filterByMonthly(filteredDaysInstance, i, true);
        const value = this.getMonth(this.month - i - 1, this.year);
        this.pushValues(planned, completed, value);
      }
      this.removeRepeats();
    }
  }

  dailyReports(e: any, dayTotal: any, i: any): any {
    const year = this.transformDatePipe.getYear(e.startDate);
    const month = this.transformDatePipe.getMonth(e.startDate);
    const day = this.transformDatePipe.getDay(e.startDate);
    const v =
      dayTotal -
      (year * 365 + this.transformDatePipe.getDaysInYear(year, month, day));

    return v === i;
  }

  weeklyReports(e: any, year: any, i: any): any {
    const v = this.transformDatePipe.getYear(e.startDate);
    return (
      this.weeyOfYear(e.startDate) === this.weeyOfYear() - i &&
      year === v &&
      this.numberDay(e) <= this.dayTotal
    );
  }

  numberDay(e: any): number {
    const year = this.transformDatePipe.getYear(e.startDate);
    const month = this.transformDatePipe.getMonth(e.startDate);
    const day = this.transformDatePipe.getDay(e.startDate);
    return year * 365 + +this.transformDatePipe.getDaysInYear(year, month, day);
  }

  monthlyReports(e: any, year: any, month: any, i: any): any {
    const yearReport = this.transformDatePipe.getYear(e.startDate);
    const monthReport = this.transformDatePipe.getMonth(e.startDate);
    const diff = month - i;

    if (diff > 0) {
      return (
        yearReport === year &&
        monthReport === diff &&
        this.numberDay(e) <= this.dayTotal
      );
    } else if (diff > -12) {
      return (
        yearReport === year - 1 &&
        monthReport === 12 - Math.abs(diff) &&
        this.numberDay(e) <= this.dayTotal
      );
    } else {
      return (
        yearReport === year - 2 &&
        monthReport === 24 - Math.abs(diff) &&
        this.numberDay(e) <= this.dayTotal
      );
    }
  }

  weeyOfYear(date?: any): any {
    const d = date ? new Date(date) : new Date();
    return this.transformDatePipe.WeekOfYear(d);
  }

  getDay(month: number, day: number, year: number): any {
    return day > 0
      ? day + '/' + this.getMonth(month, year)
      : new Date(year, month, 0).getDate() -
          Math.abs(day) +
          '/' +
          this.getMonth(month - 1, year);
  }

  getMonth(month: number, year: number): any {
    let posMonth = month;
    if (month < 0) {
      posMonth = month >= -12 ? 12 - Math.abs(month) : 24 - Math.abs(month);
    }

    let isYear = year;
    if (month < 0) {
      isYear = month >= -12 ? year - 1 : year - 2;
    }

    return this.monthNamesShort[posMonth] + '/' + isYear;
  }

  getWeekly(weekly: any): any {
    return weekly > 0
      ? this.trialpalService.translateService.instant('primeng.weekHeader') +
          ' ' +
          weekly
      : this.trialpalService.translateService.instant('primeng.weekHeader') +
          ' ' +
          (52 - Math.abs(weekly));
  }

  pushValues(planned: any, completed: any, i: any): void {
    this.dayInstancesTable = this.dayInstancesTable.concat(planned);
    this.dayInstancesTable = this.dayInstancesTable.concat(completed);
    planned = planned.length;
    completed = completed.length;
    this.datePlaneate.push(planned - completed);
    this.dateComplete.push(completed);
    this.date.push(i);
    const adherence = (completed / planned) * 100;
    this.adherence.push(adherence >= 0 ? adherence : 0);
  }

  updateChartOptions() {
    if (this.config.dark) this.applyDarkTheme();
    else this.applyLightTheme();
  }

  applyDarkTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#ebedef',
        },
      },
      scales: {
        xAxes: {
          ticks: {
            fontColor: '#ebedef',
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
        yAxes: {
          ticks: {
            fontColor: '#ebedef',
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
    };
    this.stackedOptions.scales.xAxes.ticks = {
      fontColor: '#ebedef',
    };
    this.stackedOptions.scales.xAxes.gridLines = {
      color: 'rgba(255,255,255,0.2)',
    };
    this.stackedOptions.scales.yAxes.ticks = {
      fontColor: '#ebedef',
    };
    this.stackedOptions.scales.yAxes.gridLines = {
      color: 'rgba(255,255,255,0.2)',
    };
    this.stackedOptions.scales.yAxes.ticks = {
      fontColor: '#ebedef',
    };
    this.stackedOptions.scales.yAxes.gridLines = {
      color: 'rgba(255,255,255,0.2)',
    };
    this.stackedOptions.legend = {
      labels: {
        fontColor: '#ebedef',
      },
    };
    this.stackedOptions = { ...this.stackedOptions };
  }
  applyLightTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#495057',
        },
      },
      scales: {
        xAxes: {
          ticks: {
            fontColor: '#495057',
          },
        },
        yAxes: {
          ticks: {
            fontColor: '#495057',
          },
        },
      },
    };
    this.stackedOptions.scales.xAxes.ticks = {
      fontColor: '#495057',
    };
    this.stackedOptions.scales.xAxes.gridLines = {
      color: '#ebedef',
    };
    this.stackedOptions.scales.yAxes1.ticks = {
      fontColor: '#495057',
    };
    this.stackedOptions.scales.yAxes1.gridLines = {
      color: '#ebedef',
    };
    this.stackedOptions.legend = {
      labels: {
        fontColor: '#495057',
      },
    };
    this.stackedOptions = { ...this.stackedOptions };
  }
  graphReport(): void {
    this.stackedData = {
      labels: [...this.date].reverse(),
      datasets: [
        {
          type: 'line',
          label: this.trialpalService.translateService.instant(
            'report.chart.Adherence'
          ),
          borderColor: '#FFA726',
          borderWidth: 2,
          fill: false,

          data: [100, ...this.adherence].reverse(),
        },
        {
          type: 'bar',
          yAxisID: 'yAxes1',
          label: this.trialpalService.translateService.instant(
            'report.chart.Received'
          ),
          backgroundColor: '#42A5F5',
          data: [...this.dateComplete].reverse(),
        },
        {
          type: 'bar',
          yAxisID: 'yAxes1',
          label: this.trialpalService.translateService.instant(
            'report.chart.Pending'
          ),
          backgroundColor: '#E85145',
          data: [...this.datePlaneate].reverse(),
        },
      ],
    };
    this.stackedOptions = {
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: true,
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: this.trialpalService.translateService.instant(
              'report.chart.Adherence'
            ),
            color: '#FFA726',
            font: {
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: { top: 20, left: 0, right: 0, bottom: 0 },
          },
        },
        xAxes: {
          id: 'y-axis-1',
          stacked: false,
        },
        yAxes1: {
          type: 'linear',
          display: true,
          position: 'right',
          title: {
            display: true,
            text:
              this.trialpalService.translateService.instant(
                'report.chart.Pending'
              ) +
              ' - ' +
              this.trialpalService.translateService.instant(
                'report.chart.Received'
              ),
            color: '#098392',
            font: {
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: { top: 20, left: 0, right: 0, bottom: 0 },
          },
        },
      },
    };
    this.config = this.configService.config;
    this.updateChartOptions();
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
      this.updateChartOptions();
    });
  }
  showViaService() {
    this.trialpalService.messageService.add({
      severity: 'info',
      summary: this.trialpalService.translateService.instant(
        'project.labelEdiaryActivated'
      ),
      detail: this.trialpalService.translateService.instant('general.noData'),
    });
  }
  buildTPTableData(dayInstances: any): TPTableData {
    dayInstances = dayInstances.filter((item: any, index: any) => {
      return dayInstances.indexOf(item) === index;
    });
    let newSymptomInstance = dayInstances.map((dayInstance: any) => {
      const ultimateDay = this.lastReportedDate(dayInstance.subjectId);
      return {
        ...dayInstance,
        completedDate: this.getCompletedDateByDayInstance(dayInstance),
        startDate: this.transformDatePipe.transform(dayInstance.startDate),
        subjectNumber: dayInstance.subject.subjectNumber,
        currentPhase:
          this.trialpalService.dictionaryPipe.transform(
            dayInstance?.eDiaryPhaseInstance?.phase
          ) || '',
        day:
          this.trialpalService.dictionaryPipe.transform(
            dayInstance?.confDay?.dayName
          ) || '',
        state:
          this.trialpalService.translateService.instant(
            'general.instanceState.' + dayInstance.state
          ) || '',
        site: this.getSiteNameBySiteId(dayInstance.siteId),
        lastReportedDate: ultimateDay.label,
        ultimateDay: this.getDaysWithoutReport(ultimateDay),
      };
    });

    return {
      valueTable: newSymptomInstance,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: [
        'subjectNumber',
        'site',
        'currentPhase',
        'day',
        'state',
        'startDate',
        'completedDate',
      ],
      menuOptions: [],
      cols: [
        {
          header:
            this.trialpalService.translateService.instant('subject.entity'),
          type: TypeInput.TEXT,
          field: 'subjectNumber',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant('site.entity'),
          type: TypeInput.TEXT,
          field: 'site',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header:
            this.trialpalService.translateService.instant('subject.labelVisit'),
          type: TypeInput.TEXT,
          field: 'currentPhase',
          showBasicSearch: true,
          showFilterComplete: false,
        },

        {
          header: this.trialpalService.translateService.instant('general.day'),
          type: TypeInput.TEXT,
          field: 'day',
          showBasicSearch: true,
          showFilterComplete: false,
        },

        {
          header:
            this.trialpalService.translateService.instant('subject.labelState'),
          type: TypeInput.TEXT,
          field: 'state',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.plannedDate'
          ),
          type: TypeInput.TEXT,
          field: 'startDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.completedDate'
          ),
          type: TypeInput.TEXT,
          field: 'completedDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header:
            this.trialpalService.translateService.instant('subject.lastReport'),
          type: TypeInput.TEXT,
          field: 'lastReportedDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.formsWithoutReporting'
          ),
          type: TypeInput.TEXT,
          field: 'ultimateDay',
          showBasicSearch: true,
          showFilterComplete: false,
        },
      ],
    };
  }
  getSiteNameBySiteId(siteId: string) {
    return this.projectSites.find((x) => x.value === siteId)?.label || '';
  }
  getCompletedDateByDayInstance(dayInstance: any) {
    return (
      this.transformDatePipe.transformWithHour(dayInstance.completedDate) ||
      'N/A'
    );
  }
  goDetailSubject(subject: any): void {
    this.router.navigate(['subjects', subject.subjectId, 'detail']);
  }
}
