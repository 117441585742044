import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyDesignerModule } from 'src/app/modules/report/formly-designer/formly-designer.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { FormComponent } from './components/form/form.component';
import { PaginationButtonComponent } from './components/pagination-button/pagination-button.component';
import { ReportSuccessComponent } from './components/report-success/report-success.component';
import { AddEditSymptomComponent } from './components/symptoms/add-edit-symptom/add-edit-symptom.component';
import { SymptomsComponent } from './components/symptoms/symptoms.component';
import { ReportExecutionComponent } from './report-execution.component';

@NgModule({
  declarations: [
    ReportExecutionComponent,
    SymptomsComponent,
    FormComponent,
    AddEditSymptomComponent,
    ReportSuccessComponent,
    PaginationButtonComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    PrimengModule,
    FormlyDesignerModule,
  ],
  exports: [ReportExecutionComponent],
})
export class ReportExecutionModule {}
