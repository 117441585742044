import {
  ConfState,
  InstanceState,
  MediaType,
  ModelIDInput,
  ModelStringInput,
  SignatureEntityType,
  SignatureReason,
  SignatureType,
  TP2File,
  TP2FileInput,
} from 'src/app/services/trialpal.types';
import { TP2Permission } from 'src/app/services/user-permissions-service';
import { Project, ProjectState } from '../project/project.types';
import { Site } from '../sites/sites.types';
import { Subject, SubjectState } from '../subjects/subjects.types';
import { User, UserState } from '../user/user.types';

export type ConfICAnswer = {
  __typename: 'ConfICAnswer';
  confICQuestionId: string;
  confICQuestion?: ConfICQuestion | null;
  confInformedConsentId: string;
  confInformedConsent?: ConfInformedConsent | null;
  projectId: string;
  project?: Project | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfICQuestion = {
  __typename: 'ConfICQuestion';
  confICSectionId: string;
  confICSection?: ConfICSection | null;
  confInformedConsentId: string;
  confInformedConsent?: ConfInformedConsent | null;
  projectId: string;
  project?: Project | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfICSection = {
  __typename: 'ConfICSection';
  confInformedConsentId: string;
  confInformedConsent?: ConfInformedConsent | null;
  projectId: string;
  project?: Project | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<ComplementaryQuestion | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfInformedConsent = {
  __typename: 'ConfInformedConsent';
  projectId: string;
  project?: Project | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<InformedConsentSite | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type InformedConsentSite = {
  __typename: 'InformedConsentSite';
  site: string;
  recipients?: Array<string> | null;
  totalSubjects: number;
};
export type ConsentedUserByUserIdQuery = {
  __typename: 'ModelConsentedUserConnection';
  items: Array<{
    __typename: 'ConsentedUser';
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    signature?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ComplementaryQuestion = {
  __typename: 'ComplementaryQuestion';
  id?: string | null;
  question?: string | null;
  type?: ComplementaryQuestionType | null;
  options?: Array<string | null> | null;
};
export type CreateConfICAnswerInput = {
  confICQuestionId: string;
  confInformedConsentId: string;
  projectId: string;
  id?: string | null;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfICAnswerMutation = {
  __typename: 'ConfICAnswer';
  confICQuestionId: string;
  confICQuestion?: {
    __typename: 'ConfICQuestion';
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfICQuestionInput = {
  confICSectionId: string;
  confInformedConsentId: string;
  projectId: string;
  id?: string | null;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfICQuestionMutation = {
  __typename: 'ConfICQuestion';
  confICSectionId: string;
  confICSection?: {
    __typename: 'ConfICSection';
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfICSectionInput = {
  confInformedConsentId: string;
  projectId: string;
  id?: string | null;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<ComplementaryQuestionInput | null> | null;
  isElectronicSignatureRequired: boolean;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type ComplementaryQuestionInput = {
  id?: string | null;
  question?: string | null;
  type?: ComplementaryQuestionType | null;
  options?: Array<string | null> | null;
};
export type CreateConfICSectionMutation = {
  __typename: 'ConfICSection';
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: 'ComplementaryQuestion';
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfInformedConsentInput = {
  projectId: string;
  id?: string | null;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<InformedConsentSiteInput | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type InformedConsentSiteInput = {
  site: string;
  recipients?: Array<string> | null;
  totalSubjects: number;
};
export type CreateConfInformedConsentMutation = {
  __typename: 'ConfInformedConsent';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: 'InformedConsentSite';
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConsentedUserInput = {
  projectId: string;
  siteId: string;
  userId: string;
  informedConsentInstanceId: string;
  id?: string | null;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChangeInput | null> | null;
  signature?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  requiresSendingEmail?: boolean | null;
  _version?: number | null;
};
export type StateChangeInput = {
  state?: InformedConsentState | null;
  date?: string | null;
};
export type CreateConsentedUserMutation = {
  __typename: 'ConsentedUser';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  userId: string;
  user?: {
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: 'InformedConsentInstance';
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consentedBirthDate?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: 'StateChange';
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  signature?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateICQuestionInstanceInput = {
  projectId: string;
  informedConsentInstanceId: string;
  confICQuestionId: string;
  confICSectionId: string;
  consentedUserId: string;
  id?: string | null;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateICQuestionInstanceMutation = {
  __typename: 'ICQuestionInstance';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: 'InformedConsentInstance';
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consentedBirthDate?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICQuestionId: string;
  confICQuestion?: {
    __typename: 'ConfICQuestion';
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: 'ConsentedUser';
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    signature?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSection?: {
    __typename: 'ConfICSection';
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateICSectionInstanceInput = {
  projectId: string;
  informedConsentInstanceId: string;
  confICSectionId: string;
  id?: string | null;
  consentedUserId: string;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<ComplementaryQuestionAnswerInput> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateICSectionInstanceMutation = {
  __typename: 'ICSectionInstance';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: {
    __typename: 'InformedConsentInstance';
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consentedBirthDate?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confICSectionId: string;
  confICSection?: {
    __typename: 'ConfICSection';
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedUserId: string;
  consentedUser?: {
    __typename: 'ConsentedUser';
    projectId: string;
    siteId: string;
    userId: string;
    informedConsentInstanceId: string;
    id: string;
    name?: string | null;
    kinship?: string | null;
    identification?: string | null;
    state: InformedConsentState;
    signature?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<{
    __typename: 'ComplementaryQuestionAnswer';
    type?: ComplementaryQuestionType | null;
    answer?: string | null;
  }> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateInformedConsentInstanceInput = {
  projectId: string;
  siteId: string;
  confInformedConsentId: string;
  consenterUserId?: string | null;
  subjectId?: string | null;
  id?: string | null;
  consentedIdentificator: string;
  consentedName?: string | null;
  consentedBirthDate?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChangeInput | null> | null;
  pdfFiles?: Array<TP2FileInput | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateInformedConsentInstanceMutation = {
  __typename: 'InformedConsentInstance';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;

    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consentedBirthDate?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: 'StateChange';
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: 'TP2File';
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateSignatureInstanceInput = {
  subjectId?: string | null;
  id?: string | null;
  date: string;
  type: SignatureType;
  reason: SignatureReason;
  entityType: SignatureEntityType;
  entityId: string;
  signedInfo: string;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type GetConfICAnswerQuery = {
  __typename: 'ConfICAnswer';
  confICQuestionId: string;
  confICQuestion?: {
    __typename: 'ConfICQuestion';
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetConfICQuestionQuery = {
  __typename: 'ConfICQuestion';
  confICSectionId: string;
  confICSection?: {
    __typename: 'ConfICSection';
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetConfICSectionQuery = {
  __typename: 'ConfICSection';
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  title: string;
  type: InformedConsentSectionType;
  order: number;
  content: string;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon: string;
  complementaryQuestions?: Array<{
    __typename: 'ComplementaryQuestion';
    id?: string | null;
    question?: string | null;
    type?: ComplementaryQuestionType | null;
    options?: Array<string | null> | null;
  } | null> | null;
  isElectronicSignatureRequired: boolean;
  enableApprovalQuestions: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetConfInformedConsentQuery = {
  __typename: 'ConfInformedConsent';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: 'InformedConsentSite';
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetInformedConsentInstanceQuery = {
  __typename: 'InformedConsentInstance';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  ConfInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  consenterUserId?: string | null;
  consenterUser?: {
    __typename: 'User';
    id: string;
    login: string;
    name?: string | null;
    email: string;
    role: string;
    roles?: Array<string | null> | null;
    permissions?: Array<TP2Permission | null> | null;
    state: UserState;
    phoneNumber?: string | null;
    firebaseToken?: string | null;
    isMFAActivated?: boolean | null;
    subjects?: string | null;
    sites?: string | null;
    projects?: string | null;
    appVersion?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  subjectId?: string | null;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consentedBirthDate?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<{
    __typename: 'StateChange';
    state?: InformedConsentState | null;
    date?: string | null;
  } | null> | null;
  pdfFiles?: Array<{
    __typename: 'TP2File';
    uploadDate: string;
    fileUrl: string;
  } | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ICQuestionInstance = {
  __typename: 'ICQuestionInstance';
  projectId: string;
  project?: Project | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: InformedConsentInstance | null;
  confICQuestionId: string;
  confICQuestion?: ConfICQuestion | null;
  confICSectionId: string;
  consentedUserId: string;
  consentedUser?: ConsentedUser | null;
  confICSection?: ConfICSection | null;
  id: string;
  failedAttempts: number;
  isCompleted: boolean;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type InformedConsentInstance = {
  __typename: 'InformedConsentInstance';
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  confInformedConsentId: string;
  ConfInformedConsent?: ConfInformedConsent | null;
  consenterUserId?: string | null;
  consenterUser?: User | null;
  subjectId?: string | null;
  subject?: Subject | null;
  id: string;
  consentedIdentificator: string;
  consentedName?: string | null;
  consentedBirthDate?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChange | null> | null;
  pdfFiles?: Array<TP2File | null> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type StateChange = {
  __typename: 'StateChange';
  state?: InformedConsentState | null;
  date?: string | null;
};
export type ICSectionInstance = {
  __typename: 'ICSectionInstance';
  projectId: string;
  project?: Project | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: InformedConsentInstance | null;
  confICSectionId: string;
  confICSection?: ConfICSection | null;
  id: string;
  consentedUserId: string;
  consentedUser?: ConsentedUser | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<ComplementaryQuestionAnswer> | null;
  progress: number;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted: boolean;
  state?: InstanceState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ComplementaryQuestionAnswer = {
  __typename: 'ComplementaryQuestionAnswer';
  type?: ComplementaryQuestionType | null;
  question?: ComplementaryQuestion | null;
  answer?: string | null;
};
export type ConsentedUser = {
  __typename: 'ConsentedUser';
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  userId: string;
  user?: User | null;
  informedConsentInstanceId: string;
  informedConsentInstance?: InformedConsentInstance | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state: InformedConsentState;
  stateChanges?: Array<StateChange | null> | null;
  signature?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type InformedConsentByConsentedIdentificatorQuery = {
  __typename: 'ModelInformedConsentInstanceConnection';
  items: Array<{
    __typename: 'InformedConsentInstance';
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consentedBirthDate?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type InformedConsentInstanceByICConfigurationCustomQuery = {
  __typename: 'ModelInformedConsentInstanceConnection';
  items: Array<{
    __typename: 'InformedConsentInstance';
    projectId: string;
    siteId: string;
    confInformedConsentId: string;
    consenterUserId?: string | null;
    subjectId?: string | null;
    id: string;
    consentedIdentificator: string;
    consentedName?: string | null;
    consentedBirthDate?: string | null;
    consenterName?: string | null;
    consenterIdentification?: string | null;
    consenterSignature?: string | null;
    consenterRole?: string | null;
    state: InformedConsentState;
    stateChanges?: Array<{
      __typename: 'StateChange';
      state?: InformedConsentState | null;
      date?: string | null;
    } | null> | null;
    pdfFiles?: Array<{
      __typename: 'TP2File';
      uploadDate: string;
      fileUrl: string;
    } | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ModelInformedConsentInstanceFilterInput = {
  projectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  confInformedConsentId?: ModelIDInput | null;
  consenterUserId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  consentedIdentificator?: ModelStringInput | null;
  consentedName?: ModelStringInput | null;
  consentedBirthDate?: ModelStringInput | null;
  consenterName?: ModelStringInput | null;
  consenterIdentification?: ModelStringInput | null;
  consenterSignature?: ModelStringInput | null;
  consenterRole?: ModelStringInput | null;
  state?: ModelInformedConsentStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelInformedConsentInstanceFilterInput | null> | null;
  or?: Array<ModelInformedConsentInstanceFilterInput | null> | null;
  not?: ModelInformedConsentInstanceFilterInput | null;
};
export type ModelInformedConsentStateInput = {
  eq?: InformedConsentState | null;
  ne?: InformedConsentState | null;
};
export type UpdateConfICAnswerInput = {
  confICQuestionId?: string | null;
  confInformedConsentId?: string | null;
  projectId?: string | null;
  id: string;
  description?: string | null;
  order?: number | null;
  onErrorMessage?: string | null;
  isCorrect?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfICAnswerMutation = {
  __typename: 'ConfICAnswer';
  confICQuestionId: string;
  confICQuestion?: {
    __typename: 'ConfICQuestion';
    confICSectionId: string;
    confInformedConsentId: string;
    projectId: string;
    id: string;
    description: string;
    order: number;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  onErrorMessage?: string | null;
  isCorrect: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateConfICQuestionInput = {
  confICSectionId?: string | null;
  confInformedConsentId?: string | null;
  projectId?: string | null;
  id: string;
  description?: string | null;
  order?: number | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfICQuestionMutation = {
  __typename: 'ConfICQuestion';
  confICSectionId: string;
  confICSection?: {
    __typename: 'ConfICSection';
    confInformedConsentId: string;
    projectId: string;
    id: string;
    title: string;
    type: InformedConsentSectionType;
    order: number;
    content: string;
    mediaUrl?: string | null;
    mediaType?: MediaType | null;
    icon: string;
    isElectronicSignatureRequired: boolean;
    enableApprovalQuestions: boolean;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confInformedConsentId: string;
  confInformedConsent?: {
    __typename: 'ConfInformedConsent';
    projectId: string;
    id: string;
    name: string;
    icVersion: number;
    recipients?: Array<string | null> | null;
    type: InformedConsentType;
    quizPageLayout?: QuizLayout | null;
    approvalSealFileUrl: string;
    isMultipleSignatureRequired: boolean;
    state?: ConfState | null;
    minRequiredSignatures: number;
    maxRequiredSignatures: number;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  description: string;
  order: number;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateConfICSectionInput = {
  confInformedConsentId?: string | null;
  projectId?: string | null;
  id: string;
  title?: string | null;
  type?: InformedConsentSectionType | null;
  order?: number | null;
  content?: string | null;
  mediaUrl?: string | null;
  mediaType?: MediaType | null;
  icon?: string | null;
  complementaryQuestions?: Array<ComplementaryQuestionInput | null> | null;
  isElectronicSignatureRequired?: boolean | null;
  isStudyRolRequired?: boolean | null;
  enableApprovalQuestions?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfInformedConsentInput = {
  projectId?: string | null;
  id: string;
  name?: string | null;
  icVersion?: number | null;
  recipients?: Array<string | null> | null;
  sites?: Array<InformedConsentSiteInput | null> | null;
  type?: InformedConsentType | null;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl?: string | null;
  isMultipleSignatureRequired?: boolean | null;
  state?: ConfState | null;
  minRequiredSignatures?: number | null;
  maxRequiredSignatures?: number | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfInformedConsentMutation = {
  __typename: 'ConfInformedConsent';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  name: string;
  icVersion: number;
  recipients?: Array<string | null> | null;
  sites?: Array<{
    __typename: 'InformedConsentSite';
    site: string;
    recipients?: Array<string> | null;
    totalSubjects: number;
  } | null> | null;
  type: InformedConsentType;
  quizPageLayout?: QuizLayout | null;
  approvalSealFileUrl: string;
  isMultipleSignatureRequired: boolean;
  state?: ConfState | null;
  minRequiredSignatures: number;
  maxRequiredSignatures: number;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateConsentedUserInput = {
  projectId?: string | null;
  siteId?: string | null;
  userId?: string | null;
  informedConsentInstanceId?: string | null;
  id: string;
  name?: string | null;
  kinship?: string | null;
  identification?: string | null;
  state?: InformedConsentState | null;
  stateChanges?: Array<StateChangeInput | null> | null;
  signature?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateICQuestionInstanceInput = {
  projectId?: string | null;
  informedConsentInstanceId?: string | null;
  confICQuestionId?: string | null;
  confICSectionId?: string | null;
  consentedUserId?: string | null;
  id: string;
  failedAttempts?: number | null;
  isCompleted?: boolean | null;
  startDate?: string | null;
  completedDate?: string | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateICSectionInstanceInput = {
  projectId?: string | null;
  informedConsentInstanceId?: string | null;
  confICSectionId?: string | null;
  id: string;
  consentedUserId?: string | null;
  consentedSignatureDate?: string | null;
  complementaryQuestionsAnswers?: Array<ComplementaryQuestionAnswerInput> | null;
  progress?: number | null;
  startDate?: string | null;
  completedDate?: string | null;
  isCompleted?: boolean | null;
  state?: InstanceState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type ComplementaryQuestionAnswerInput = {
  type?: ComplementaryQuestionType | null;
  question?: ComplementaryQuestionInput | null;
  answer?: string | null;
};
export type UpdateInformedConsentInstanceInput = {
  projectId?: string | null;
  siteId?: string | null;
  confInformedConsentId?: string | null;
  consenterUserId?: string | null;
  subjectId?: string | null;
  id: string;
  consentedIdentificator?: string | null;
  consentedName?: string | null;
  consentedBirthDate?: string | null;
  consenterName?: string | null;
  consenterIdentification?: string | null;
  consenterSignature?: string | null;
  consenterRole?: string | null;
  state?: InformedConsentState | null;
  stateChanges?: Array<StateChangeInput | null> | null;
  pdfFiles?: Array<TP2FileInput | null> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type tp2SendProjectAdminNotificationInput = {
  user?: string | null;
  subject?: string | null;
  action?: string | null;
  data?: string | null;
  error?: tp2SendProjectAdminNotificationError | null;
  source?: string | null;
};
export type tp2SendProjectAdminNotificationError = {
  code?: string | null;
  message?: string | null;
};
export type ModelConfICSectionFilterInput = {
  confInformedConsentId?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  type?: ModelInformedConsentSectionTypeInput | null;
  order?: ModelIntInput | null;
  content?: ModelStringInput | null;
  mediaUrl?: ModelStringInput | null;
  mediaType?: ModelMediaTypeInput | null;
  icon?: ModelStringInput | null;
  isElectronicSignatureRequired?: ModelBooleanInput | null;
  isStudyRolRequired?: ModelBooleanInput | null;
  enableApprovalQuestions?: ModelBooleanInput | null;
  state?: ModelConfStateInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelConfICSectionFilterInput | null> | null;
  or?: Array<ModelConfICSectionFilterInput | null> | null;
  not?: ModelConfICSectionFilterInput | null;
};
export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};
export type ModelInformedConsentSectionTypeInput = {
  eq?: InformedConsentSectionType | null;
  ne?: InformedConsentSectionType | null;
};
export type ModelMediaTypeInput = {
  eq?: MediaType | null;
  ne?: MediaType | null;
};
export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};
export type ModelConfStateInput = {
  eq?: ConfState | null;
  ne?: ConfState | null;
};
export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}
export enum QuizLayout {
  QUIZ_BY_SECTION = 'QUIZ_BY_SECTION',
  QUIZ_BY_CONSENT = 'QUIZ_BY_CONSENT',
}
export enum InformedConsentType {
  CONSENT = 'CONSENT',
  ASSENT = 'ASSENT',
  AMENDMENT = 'AMENDMENT',
}
export enum InformedConsentSectionType {
  CONSENT = 'CONSENT',
  RISK = 'RISK',
  PURPOSE = 'PURPOSE',
  BENEFITS = 'BENEFITS',
  ALTERNATIVES = 'ALTERNATIVES',
  COMPENSATION = 'COMPENSATION',
  WITHDRAW = 'WITHDRAW',
  FINDINGS = 'FINDINGS',
  CONTACTS = 'CONTACTS',
  CONFIDENTIALITY = 'CONFIDENTIALITY',
  OTHER = 'OTHER',
  OPTIONAL_CONSENT = 'OPTIONAL_CONSENT',
  CONSENTER_SIGNATURE = 'CONSENTER_SIGNATURE',
}
export enum ComplementaryQuestionType {
  YES_NO = 'YES_NO',
  CHECK = 'CHECK',
  TEXT = 'TEXT',
  DATE = 'DATE',
}
export enum InformedConsentState {
  ASSIGNED = 'ASSIGNED',
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED',
  PENDING_FOR_SIGNATURE = 'PENDING_FOR_SIGNATURE',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  SIGNED_BY_PARTICIPANT = 'SIGNED_BY_PARTICIPANT',
  SIGNED_BY_INVESTIGATOR = 'SIGNED_BY_INVESTIGATOR',
  DELETED = 'DELETED',
}
