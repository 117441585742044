import { Component } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SymptomService } from '../../symptom/symptom.service';
import { MedicalAttentionService } from '../medical-attention.service';
import {
  ConfMedicalAttention,
  MedicalAttentionConfigurationMode,
  MedicalAttentionInstance,
  VerifiedMedicalAttentionInstance,
} from 'src/app/modules/conciliation/conciliation.types';
const logger = new Logger('tp2-summaryMedicalAttention');
@Component({
  selector: 'app-summary-medical-attention',
  templateUrl: './summary-medical-attention.component.html',
})
export class SummaryMedicalAttentionComponent {
  confMedicalAttention?: ConfMedicalAttention;
  medicalAttention?: any;
  verified: any;
  oldValue: any;
  changReason: boolean = false;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private medicalAttentionService: MedicalAttentionService,
    private symptomService: SymptomService
  ) {
    this.confMedicalAttention = this.config.data?.configuration;
    this.medicalAttention = this.config.data?.medicalAttention;
    this.verified = this.config.data?.newVerified
      ? Object.assign({}, ...this.config.data?.newVerified)
      : {};
    this.oldValue = this.config.data?.oldValue
      ? Object.assign({}, ...this.config.data?.oldValue)
      : {};
    logger.debug('summary', this.oldValue, this.verified);
    this.assignedValues(this.oldValue, this.verified);
  }

  /**
   * Traducion de variables de las instancias
   * @param oldValue Objeto con los valores originales
   * @param verified Objeto verifica o el actual del formulario
   */
  assignedValues(
    oldValue: MedicalAttentionInstance,
    verified: VerifiedMedicalAttentionInstance
  ) {
    if (
      this.confMedicalAttention?.mode ===
      MedicalAttentionConfigurationMode.SYMPTOM_BINDING
    ) {
      this.oldValue.symptomsInstances =
        oldValue.symptomsInstances && oldValue.symptomsInstances?.length > 0
          ? this.symptomService.getSymptoms(
              oldValue?.symptomsInstances,
              this.medicalAttention
            )
          : undefined;
      this.verified.symptomsInstances =
        verified.symptomsInstances && verified.symptomsInstances?.length > 0
          ? this.symptomService.getSymptoms(
              verified?.symptomsInstances,
              this.medicalAttention
            )
          : undefined;
    }

    this.getTransformValues(oldValue, verified);
    if (
      this.oldValue.reason ||
      this.oldValue.symptomsInstances ||
      this.verified.reason ||
      this.verified.symptomsInstances
    ) {
      this.changReason = true;
    }
  }

  getTransformValues(
    oldValue: MedicalAttentionInstance,
    verified: VerifiedMedicalAttentionInstance
  ) {
    if (this.confMedicalAttention?.showType) {
      this.oldValue.type = oldValue.type
        ? this.medicalAttentionService.getTransFormEnumType(oldValue.type)
        : undefined;
      this.verified.type = verified.type
        ? this.medicalAttentionService.getTransFormEnumType(verified.type)
        : undefined;
    }
  }
  onCancel() {
    this.ref.close(null);
  }

  onAccept(accept: boolean) {
    this.ref.close(accept);
  }
}
