import { ConfState } from 'src/app/services/trialpal.types';

export type GetProjectQuery = {
  __typename: 'Project';
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: 'AccessibilityGroup';
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: 'ProjectTermsAndConditions';
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfVisitGroupInput = {
  projectId: string;
  id?: string | null;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateProjectInput = {
  id?: string | null;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<AccessibilityGroupInput | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<ProjectTermsAndConditionsInput | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type AccessibilityGroupInput = {
  group?: string | null;
  isActive?: boolean | null;
};
export type ProjectTermsAndConditionsInput = {
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};
export type CreateProjectMutation = {
  __typename: 'Project';
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: 'AccessibilityGroup';
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: 'ProjectTermsAndConditions';
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type DeleteConfVisitGroupInput = {
  id: string;
  _version?: number | null;
};
export type DeleteProjectInput = {
  id: string;
  _version?: number | null;
};
export type DeleteProjectMutation = {
  __typename: 'Project';
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: 'AccessibilityGroup';
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: 'ProjectTermsAndConditions';
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateConfVisitGroupInput = {
  projectId?: string | null;
  id: string;
  visit?: string | null;
  group?: string | null;
  isVisible?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateProjectInput = {
  id: string;
  state?: ProjectState | null;
  code?: string | null;
  name?: string | null;
  sponsor?: string | null;
  groups?: Array<string> | null;
  phases?: Array<string> | null;
  accessibilityGroups?: Array<AccessibilityGroupInput | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated?: boolean | null;
  isReportsActivated?: boolean | null;
  termsAndConditions?: Array<ProjectTermsAndConditionsInput | null> | null;
  isICActivated?: boolean | null;
  isChatActivated?: boolean | null;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateProjectMutation = {
  __typename: 'Project';
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<{
    __typename: 'AccessibilityGroup';
    group?: string | null;
    isActive?: boolean | null;
  } | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<{
    __typename: 'ProjectTermsAndConditions';
    termsAndConditions: string;
    termsAndConditionsVersion: string;
    termsAndConditionsVersionDate: string;
    policies: string;
    policiesVersion: string;
    policiesVersionDate: string;
  } | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type Project = {
  __typename: 'Project';
  id: string;
  state: ProjectState;
  code: string;
  name: string;
  sponsor?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  accessibilityGroups?: Array<AccessibilityGroup | null> | null;
  faultRecipients?: Array<string> | null;
  isEdiaryActivated: boolean;
  isReportsActivated: boolean;
  termsAndConditions?: Array<ProjectTermsAndConditions | null> | null;
  isICActivated: boolean;
  isChatActivated: boolean;
  isMFAActivated?: boolean | null;
  isSubjectNumberCheckRequired?: boolean | null;
  subjectNumberRegex?: string | null;
  subjectNumberPlaceholder?: string | null;
  checkSiteCodeInSubjectNumber?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ProjectTermsAndConditions = {
  __typename: 'ProjectTermsAndConditions';
  termsAndConditions: string;
  termsAndConditionsVersion: string;
  termsAndConditionsVersionDate: string;
  policies: string;
  policiesVersion: string;
  policiesVersionDate: string;
};
export type ConfVisitGroup = {
  __typename: 'ConfVisitGroup';
  projectId: string;
  project?: Project | null;
  id: string;
  visit: string;
  group: string;
  isVisible: boolean;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type AccessibilityGroup = {
  __typename: 'AccessibilityGroup';
  group?: string | null;
  isActive?: boolean | null;
};
export enum ProjectState {
  IN_DESIGN = 'IN_DESIGN',
  IN_PROGRESS = 'IN_PROGRESS',
  SUSPENDED = 'SUSPENDED',
  ENDED = 'ENDED',
  ARCHIVED = 'ARCHIVED',
}
export enum ReportAvailableUserType {
  SUBJECT = 'SUBJECT',
  INVESTIGATOR = 'INVESTIGATOR',
}
