import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { Roles } from 'src/app/shared/global.variables';
import { ListSitesComponent } from './list-sites/list-sites.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'listSites',
    pathMatch: 'full',
  },
  {
    path: 'listSites',
    component: ListSitesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin] },
  },
  {
    path: 'listSites/:projectId',
    component: ListSitesComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SitesRoutingModule {}
