<form *ngIf="symptomService.isNewOtherSymptom">
  <label
    id="lbl_labelSelectedDay"
    name="labelSelectedDay"
    for="inputSelectedDay"
  >
    {{ 'symptominstance.newOtherSymptom' | translate }}
  </label>
  <p-dropdown
    id="inputSelectedDay"
    name="drop_dayInstances"
    optionLabel="label"
    optionValue="value"
    class="input p-col-12 p-p-0 p-mr-1"
    appendTo="body"
    [options]="dayInstances"
    [(ngModel)]="dayInstanceSelected"
    (onChange)="newOtherSymptom()"
    loader
    placeholder="{{ 'general.selectPlaceholder' | translate }}"
    emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
    emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
  >
  </p-dropdown>
</form>

<form
  name="form"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="!symptomService.isNewOtherSymptom"
>
  <div class="scroll-conciliation p-pr-2" *ngIf="!isDelete">
    <div class="p-ai-center">
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedWhichOtherSymptom"
          name="labelVerifiedWhichOtherSymptom"
          for="inputVerifiedWhichOtherSymptom"
        >
          {{ 'symptominstance.symptomIllness' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <input
          id="inputVerifiedWhichOtherSymptom"
          type="text"
          formControlName="whichOtherSymptom"
          class="full-width"
          (keyup)="filterSymptoms()"
          pInputText
        />
        <ul class="scroll-conciliation" *ngIf="filteredSymptoms.length > 0">
          <li
            *ngFor="let symptom of filteredSymptoms"
            (click)="selectSymptom(symptom)"
          >
            {{ symptom.name }}
          </li>
        </ul>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['whichOtherSymptom']?.invalid &&
            form.controls['whichOtherSymptom']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isWhichOtherSymptomChange()"
      >
        <label
          id="lbl_verifiedWhichOtherSymptomComment"
          name="lbl_verifiedWhichOtherSymptomComment"
          for="verifiedWhichOtherSymptomComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedWhichOtherSymptomComment"
          formControlName="whichOtherSymptomComment"
          class="p-col-12"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['whichOtherSymptomComment']?.invalid &&
            form.controls['whichOtherSymptomComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      class="p-ai-center"
      *ngIf="configuration?.showIntensity && !shouldHideIntensity()"
    >
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedIntensityOther"
          name="labelVerifiedIntensityOther"
          for="inputVerifiedIntensityOther"
        >
          {{ 'symptominstance.intensity' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-dropdown
          id="inputVerifiedIntensityOther"
          name="drop_intensity"
          optionLabel="label"
          optionValue="value"
          class="input p-col-12 p-p-0 p-mr-1"
          appendTo="body"
          [options]="intensities"
          [autoDisplayFirst]="false"
          formControlName="intensity"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [ngClass]="{ disabled: shouldDisableIntensity() }"
        >
        </p-dropdown>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['intensity']?.invalid &&
            form.controls['intensity']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isIntensityChange()"
      >
        <label
          id="lbl_verifiedIntensityComment"
          name="lbl_verifiedIntensityComment"
          for="verifiedIntensityComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedIntensityComment"
          formControlName="intensityComment"
          class="p-col-12"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['intensityComment']?.invalid &&
            form.controls['intensityComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      class="p-ai-center"
      *ngIf="
        configuration?.showProfessionalHealthCare &&
        !shouldHideHospitalizationInfo()
      "
    >
      <div class="p-mb-3 full-width">
        <label
          id="lbl_verfiedProfessionalHealthCare"
          name="lbl_verfiedProfessionalHealthCare"
          for="verfiedProfessionalHealthCare"
        >
          {{ 'otherSymptom.professionalHealthCare' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-dropdown
          id="verfiedProfessionalHealthCare"
          name="drop_professionalHealthCare"
          optionLabel="label"
          optionValue="value"
          class="input p-col-12 p-p-0 p-mr-1"
          appendTo="body"
          [options]="professionalHealthCares"
          [autoDisplayFirst]="false"
          (onChange)="onProfessionalHealthCaresChange()"
          formControlName="professionalHealthCare"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [ngClass]="{
            disabled: shouldDisableHospitalizationInfo()
          }"
        >
        </p-dropdown>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['professionalHealthCare']?.invalid &&
            form.controls['professionalHealthCare']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isProfessionalHealthCareChange()"
      >
        <label
          id="lbl_verifiedProfessionalHealthCareComment"
          name="lbl_verifiedProfessionalHealthCareComment"
          for="verifiedProfessionalHealthCareComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedProfessionalHealthCareComment"
          formControlName="professionalHealthCareComment"
          class="p-col-12"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['professionalHealthCareComment']?.invalid &&
            form.controls['professionalHealthCareComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      class="p-ai-center"
      *ngIf="
        configuration?.showProfessionalHealthCare &&
        !shouldHideHospitalizationInfo()
      "
    >
      <div class="p-mb-3 full-width">
        <label
          id="lbl_verfiedHospitalAdmission"
          name="lbl_verfiedHospitalAdmission"
          for="verfiedHospitalAdmission"
        >
          {{ 'otherSymptom.admisionHours' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-dropdown
          id="verfiedHospitalAdmission"
          name="drop_size"
          optionLabel="label"
          optionValue="value"
          class="input p-col-12 p-p-0 p-mr-1"
          appendTo="body"
          [options]="hospitalAdmissions"
          [autoDisplayFirst]="false"
          [readonly]="form.controls['professionalHealthCare'].value !== 'YES'"
          formControlName="hospitalAdmission"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [ngClass]="{
            disabled: shouldDisableHospitalizationInfo()
          }"
        >
        </p-dropdown>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['hospitalAdmission']?.invalid &&
            form.controls['hospitalAdmission']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isHospitalAdmissionChange()"
      >
        <label
          id="lbl_verifiedHospitalAdmissionComment"
          name="lbl_verifiedHospitalAdmissionComment"
          for="verifiedHospitalAdmissionComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedHospitalAdmissionComment"
          formControlName="hospitalAdmissionComment"
          class="p-col-12"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['hospitalAdmissionComment']?.invalid &&
            form.controls['hospitalAdmissionComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center"
      *ngIf="configuration?.showRememberStartDate && !shouldHideDateFields()"
    >
      <div class="p-pb-3">
        <label
          id="lbl_verifiedRememberStartDate"
          name="lbl_veriifiedRememberStartDate"
          for="verifiedRememberStartDate"
        >
          {{ 'symptominstance.startDateQuestion' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-selectButton
          id="verifiedRememberStartDate"
          formControlName="rememberStartDate"
          [options]="booleanOptions"
          optionLabel="label"
          optionValue="value"
          [required]="!shouldHideDateFields()"
          [ngClass]="{
            disabled: shouldDisableDateFields()
          }"
        ></p-selectButton>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['rememberStartDate']?.invalid &&
            form.controls['rememberStartDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center p-mb-3"
      *ngIf="isStartDateEnabled() && !shouldHideDateFields()"
    >
      <div class="p-mb-3">
        <label
          id="lbl_verifiedStartDate"
          name="lbl_verifiedStartDate"
          for="verifiedStartDate"
        >
          {{ 'symptominstance.startDate' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-calendar
          formControlName="startDate"
          id="verifiedStartDate"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [showIcon]="true"
          [maxDate]="maxDate"
          appendTo="body"
          placeholder="{{
            'addEditSubjectComponent.PlaceHolderFormatDate' | translate
          }}"
          dateFormat="dd-mm-yy"
          [ngClass]="{
            disabled: shouldDisableDateFields()
          }"
        ></p-calendar>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['startDate']?.invalid &&
            form.controls['startDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-grid p-mb-3 p-ai-center full-width"
      *ngIf="isRememberStartDateChange() || isStartDateChange()"
    >
      <label
        id="lbl_observation"
        name="lbl_observation"
        for="txt_observationTaken"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span>
      </label>
      <textarea
        id="verifiedStartDateComment"
        formControlName="startDateComment"
        class="p-col-12"
        pInputTextarea
        type="text"
        [autoResize]="true"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['startDateComment']?.invalid &&
          form.controls['startDateComment']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>

    <div
      class="p-ai-center"
      *ngIf="configuration?.showRememberFinishDate && !shouldHideDateFields()"
    >
      <div class="p-pb-3">
        <label
          id="lbl_verifiedRememberFinishDate"
          name="lbl_veriifiedRememberFinishDate"
          for="verifiedRememberFinishDate"
        >
          {{ 'symptominstance.endDateQuestion' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-selectButton
          id="verifiedRememberFinishDate"
          formControlName="rememberFinishDate"
          [options]="booleanOptions"
          optionLabel="label"
          optionValue="value"
          [required]="!shouldHideDateFields()"
          [ngClass]="{
            disabled: shouldDisableDateFields()
          }"
        ></p-selectButton>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['rememberFinishDate']?.invalid &&
            form.controls['rememberFinishDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center"
      *ngIf="isFinishDateEnabled() && !shouldHideDateFields()"
    >
      <div class="p-mb-3">
        <label
          id="lbl_verifiedFinishDate"
          name="lbl_verifiedFinishDate"
          for="verifiedFinishDate"
        >
          {{ 'symptominstance.endDate' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-calendar
          formControlName="finishDate"
          id="verifiedFinishDate"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [showIcon]="true"
          [maxDate]="maxDate"
          appendTo="body"
          placeholder="{{
            'addEditSubjectComponent.PlaceHolderFormatDate' | translate
          }}"
          dateFormat="dd-mm-yy"
          [ngClass]="{
            disabled: shouldDisableDateFields()
          }"
        ></p-calendar>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['finishDate']?.invalid &&
            form.controls['finishDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      class="p-grid p-mb-3 p-ai-center full-width"
      *ngIf="isRememberFinishDateChange() || isFinishDateChange()"
    >
      <label
        id="lbl_observation"
        name="lbl_observation"
        class="p-col-12"
        for="txt_observationTaken"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span>
      </label>
      <textarea
        id="verifiedFinishDateComment"
        formControlName="finishDateComment"
        class="p-col-12"
        pInputTextarea
        type="text"
        [autoResize]="true"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['finishDateComment']?.invalid &&
          form.controls['finishDateComment']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </div>
  <div *ngIf="isChangeForm && currentOtherSymptom.isEdition">
    <label
      id="lbl_changeReason"
      name="lbl_changeReason"
      for="txt_changeReason"
      >{{ 'audit.label' | translate }}</label
    >
    <change-reason
      name="other_symptom_reason"
      [label]="'conciliation.changeReason' | translate"
      formControlName="_changeReason"
      [required]="true"
    ></change-reason>
    <small
      class="form-error p-col-12"
      *ngIf="
        form.controls['_changeReason']?.invalid &&
        form.controls['_changeReason']?.touched
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <h4 class="notResult" *ngIf="isDelete">
    {{ 'general.recordDeleted' | translate }}
  </h4>
  <app-form-buttons
    (nextData)="isNext = $event"
    (previousData)="previousOtherSymptoms()"
    [isNextDisabled]="form.invalid"
  ></app-form-buttons>
</form>
