import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Roles } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { AuthorizedSitesBySiteIdCustomQuery } from '../../sites/sites.types';
import {
  CreateConfChatUserInput,
  UpdateConfChatUserInput,
} from '../chat.queries';
import { ChatService } from '../chat.service';
const logger = new Logger('tp2-logger-chatConfPage');
@Component({
  selector: 'app-chat-configuration',
  templateUrl: './chat-configuration.component.html',
  styleUrls: ['./chat-configuration.component.scss'],
})
export class ChatConfigurationComponent implements OnInit {
  projectId: string = '';
  sites: any[] = [];

  constructor(
    private trialpalService: TrialpalService,
    private chatService: ChatService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.projectId;
    this.trialpalService.showSpinner('Chat USERS PENDING TrANS', 'LIST');
    // Tomamos la lista sitios a los que el usuario tiene acceso.
    this.projectService
      .listUserSites([this.projectId])
      .then((response) => {
        logger.debug('listUserSites response', response);
        this.sites = response.items;
        for (const site of this.sites) {
          //Recuperamos la lista de usuarios de asociados al sitio
          this.chatService
            .getSiteUsers(site.id)
            .then((response2) => {
              logger.debug('getSiteUsers response', response2);
              site.users = this.filterSiteUsers(response2);
              this.chatService
                .getConfChatUserByProjectId(this.projectId)
                .then((response3: any[]) => {
                  logger.debug(
                    'getConfCharUserByProjectId response',
                    response3
                  );
                  if (response3) {
                    this.updateUserList(site, response3);
                  }
                })
                .catch((error3) => {
                  logger.error('getConfCharUserByProjectId error', error3);
                  this.trialpalService.hideSpinner();
                  this.trialpalService.showServiceError(
                    'chat.actions.getConfCharUserByProjectId',
                    error3
                  );
                })
                .finally(() => this.trialpalService.hideSpinner());
            })
            .catch((error2) => {
              logger.error('getSiteUsers error', error2);
              this.trialpalService.hideSpinner();
              this.trialpalService.showServiceError(
                'chat.actions.getSiteUsers',
                error2
              );
            });
        }
      })
      .catch((error: any) => {
        logger.error('listUserSites error', error);
        this.trialpalService.hideSpinner();
        this.trialpalService.showServiceError(
          'chat.actions.listUserSites',
          error
        );
      });
  }
  /**
   * Sirve para determinar que usuario ya tienen el chat activo
   * @param activeUsers usuarios que se encuentran activos.
   */
  updateUserList(site: any, activeUsers: any[]) {
    for (const user of site.users) {
      const confUser = activeUsers.find(
        (x) => x.userId === user.value.id && x.siteId === site.id
      );
      if (confUser) {
        user.value.isChatActivated = confUser.isChatActivated;
        user.value.idChatConf = confUser.id;
        user.value.version = confUser._version;
      } else {
        user.value.isChatActivated = false;
        user.value.idChatConf = null;
      }
    }
  }
  /**
   * Sirve para filtrar la lista y ajustar la estructura de datos
   * @param siteUsers sites y usuarios.
   * @returns una lista de solo usuarios.
   */
  private filterSiteUsers(
    siteUsers: AuthorizedSitesBySiteIdCustomQuery
  ): any[] {
    let users: any[] = [];
    if (siteUsers.items) {
      users = siteUsers.items
        ?.map((x) =>
          x
            ? { label: x.user?.login + '|' + x.user?.role, value: x.user }
            : null
        )
        .filter((y: any) => y?.value?.role === Roles.Investigator)
        .filter(
          (z: any, i: any, a: any) =>
            a.findIndex((t: any) => t.value.id === z.value.id) === i
        );
    }
    logger.debug('Filtered users', users);
    return users;
  }

  /**
   * Sirve para prender/apagar el estado de la configuración del chat.
   * @param site sitio que se esta revisando
   * @param user usuairo que se quiere cambiar de estado
   */
  changeUserState(site: any, user: any) {
    logger.debug('changing state to user ', user, 'in site', site);
    if (user.value.idChatConf === null) {
      const input: CreateConfChatUserInput = {
        isChatActivated: true,
        userId: user.value.id,
        siteId: site.id,
        projectId: site.projectId,
        _lastUser: '',
      };
      this.trialpalService.showSpinner('chat.entity', 'CREATE');
      logger.debug('createChatConfUser input', input);
      this.chatService
        .createChatConfUser(input)
        .then((response) => {
          logger.debug('createChatConfUser response', response);
          user.value.isChatActivated = true;
          user.value.idChatConf = response.id;
        })
        .catch((error) => {
          logger.error('listUserSites error', error);
          this.trialpalService.showServiceError(
            'chat.actions.createChatConfUser',
            error
          );
        })
        .finally(() => {
          this.trialpalService.hideSpinner();
        });
    } else {
      //Se debe actualizar el estado
      const input: UpdateConfChatUserInput = {
        id: user.value.idChatConf,
        isChatActivated: user.value.isChatActivated,
        _version: user.value.version,
      };
      this.trialpalService.showSpinner('chat.entity', 'UPDATE');
      logger.debug('updateChatConfUser input', input);
      this.chatService
        .updateChatConfUser(input)
        .then((response) => {
          logger.debug('updateChatConfUser response', response);
          user.value.version = response._version;
        })
        .catch((error) => {
          logger.error('updateChatConfUser error', error);
          this.trialpalService.showServiceError(
            'chat.actions.updateChatConfUser',
            error
          );
        })
        .finally(() => {
          this.trialpalService.hideSpinner();
        });
    }
  }
}
