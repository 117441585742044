import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { InformedConsentQuestionModule } from '../informed-consent-question/informed-consent-question.module';
import { ComplementaryQuestionsComponent } from './informed-consent-section-add-edit/components/complementary-questions/complementary-questions.component';
import { InformedConsentSectionAddEditComponent } from './informed-consent-section-add-edit/informed-consent-section-add-edit.component';
import { InformedConsentSectionDetailComponent } from './informed-consent-section-detail/informed-consent-section-detail.component';
import { InformedConsentSectionListComponent } from './informed-consent-section-list/informed-consent-section-list.component';
import { InformedConsentSectionRoutingModule } from './informed-consent-section-routing.module';
import { GenerateMediaModalComponent } from './informed-consent-section-add-edit/components/generate-media-modal/generate-media-modal.component';

@NgModule({
  declarations: [
    InformedConsentSectionListComponent,
    InformedConsentSectionAddEditComponent,
    InformedConsentSectionDetailComponent,
    ComplementaryQuestionsComponent,
    GenerateMediaModalComponent,
  ],
  imports: [
    PrimengModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    InformedConsentSectionRoutingModule,
    InformedConsentQuestionModule,
  ],
  exports: [InformedConsentSectionListComponent],
})
export class InformedConsentSectionModule {}
