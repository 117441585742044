export const USERS_QUERIES = {
  ListUsers: `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
      listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          subjects
          sites
          projects
          appVersion
          notificationPreference
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`,

  userByProject: `query UserByProject($projects: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
      userByProject(projects: $projects, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          subjects
          sites
          projects
          appVersion
          notificationPreference
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`,
  GetProject: `query GetProject($id: ID!) {
      getProject(id: $id) {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        termsAndConditions {
          __typename
          termsAndConditions
          termsAndConditionsVersion
          termsAndConditionsVersionDate
          policies
          policiesVersion
          policiesVersionDate
        }
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
  UserByLogin: `query UserByLogin($login: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
      userByLogin(login: $login, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          notificationPreference
          subjects
          sites
          projects
          appVersion
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`,
  GetUser: `query GetUser($id: ID!) {
      getUser(id: $id) {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        termsAndConditions {
          __typename
          termsAndConditionsVersion
          policiesVersion
          acceptanceDate
        }
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
  GetSubject: `query GetSubject($id: ID!) {
      getSubject(id: $id) {
        __typename
        projectId
        project {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        siteId
        site {
          __typename
          projectId
          id
          name
          contactInfo
          showContactInfo
          showContactInfoLogin
          timezone
          defaultLanguage
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        subjectNumber
        scheduledPhases {
          __typename
          phase
          date
          state
        }
        currentScheduledPhase {
          __typename
          phase
          date
          state
        }
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
  listUsersSubjectsCustom: `query listUsersSubjectsCustom($idSubject: String, $limit: Int, $nextToken: String) {
      listUsers(filter: {subjects: {contains: $idSubject}}, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          login
          name
          email
          role
          state
          phoneNumber
          subjects
          sites
          projects
          _lastUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }`,
  Tp2UserDisabled: `query Tp2UserDisabled($user: TP2UserInput!) {
      tp2UserDisabled(user: $user)
    }`,
  UserByEmail: `query UserByEmail($email: String!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
      userByEmail(email: $email, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          login
          name
          email
          role
          roles
          permissions
          state
          phoneNumber
          firebaseToken
          isMFAActivated
          subjects
          sites
          projects
          appVersion
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`,
  UpdateUser: `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
      updateUser(input: $input, condition: $condition) {
        __typename
        id
        login
        name
        email
        role
        roles
        permissions
        state
        phoneNumber
        firebaseToken
        isMFAActivated
        termsAndConditions {
          __typename
          termsAndConditionsVersion
          policiesVersion
          acceptanceDate
        }
        subjects
        sites
        projects
        appVersion
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
};
