<div
  id="contenedor"
  class="contenedor"
  [ngClass]="{ 'container-tp2': !isModal }"
>
  <h1 *ngIf="!isModal">
    {{
      (editMode
        ? 'subject.headerModalEditSubject'
        : 'subject.headerModalCreateSubject'
      ) | translate
    }}
  </h1>
  <app-tour-guide-button
    [pageTourDataType]="CREATE_SUBJECT_TOUR"
  ></app-tour-guide-button>
  <form id="form" class="form" name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
    <p-card class="card">
      <div class="p-fluid p-form-grid p-grid" id="basic-information">
        <div class="p-col-12 p-field" *ngIf="!editMode">
          <h4 style="margin: 10px 0 0">
            {{ 'subject.basicDataSuject' | translate }}
          </h4>
        </div>
        <div
          class="p-col-12 p-md-4 p-field"
          [ngClass]="editMode ? 'p-md-4' : 'p-md-6'"
          id="site_container"
        >
          <label>
            {{ 'project.labelSites' | translate }}
          </label>
          <span class="inputRequired"> * </span>
          <p-dropdown
            ngDefaultControl
            [showClear]="!editMode"
            class="input"
            [pTooltip]="'subject.tooltipSite' | translate"
            id="sites"
            name="sites"
            [required]="true"
            placeholder="
           {{ 'subject.sitesPlaceholder' | translate }}
          "
            [options]="optionSites"
            [(ngModel)]="subject.site_id"
            [disabled]="isCreateSubjectFromInformedConsent || editMode"
            #sites="ngModel"
            (onChange)="setUserBySite(f)"
          ></p-dropdown>
          <small
            class="form-error"
            *ngIf="!sites.valid && (f.submitted || sites.touched)"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
        <div
          class="p-col-12 p-field"
          [ngClass]="editMode ? 'p-md-4' : 'p-md-6'"
          id="subjectNumber_container"
        >
          <label>
            {{ 'subject.labelSubjectNumber' | translate }}
            <span class="inputRequired">* </span>
          </label>
          <input
            id="code"
            name="code"
            type="text"
            autocomplete="off"
            pInputText
            [(ngModel)]="subject.subjectNumber"
            [required]="true"
            #subjectNumber="ngModel"
            [placeholder]="
              (project.isSubjectNumberCheckRequired ||
                project.checkSiteCodeInSubjectNumber) &&
              project.subjectNumberPlaceholder
                ? project.subjectNumberPlaceholder
                : ''
            "
            pTooltip="{{ getSubjecNumberTooltip() }}"
            [readonly]="editMode"
            [pattern]="subjectNumberRegEx"
            (ngModelChange)="checkSubjectNumberRegEx(f)"
          />
          <small
            class="form-error"
            *ngIf="
              subjectNumber.invalid &&
              (f.submitted || subjectNumber.touched) &&
              !subjectNumber?.errors?.pattern &&
              !subjectNumber?.errors?.siteCode
            "
          >
            {{ 'general.required' | translate }}</small
          >
          <small
            class="form-error"
            *ngIf="
              !subjectNumber.valid &&
              (f.submitted || subjectNumber.touched) &&
              subjectNumber?.errors?.pattern &&
              !subjectNumber?.errors?.siteCode
            "
          >
            {{ 'subject.patternErrorMessage' | translate }}</small
          >
          <small
            class="form-error"
            *ngIf="
              !subjectNumber.valid &&
              (f.submitted || subjectNumber.touched) &&
              !subjectNumber?.errors?.pattern &&
              subjectNumber?.errors?.siteCode
            "
          >
            {{ 'subject.error.siteCode' | translate }}</small
          >
          <div *ngIf="f.controls['code'].errors?.incorrect">
            <small class="form-error">{{
              'general.noSpaces' | translate
            }}</small>
          </div>
        </div>
        <div
          class="p-col-12 p-field"
          [ngClass]="editMode ? 'p-md-4' : 'p-md-6'"
          id="group_container"
        >
          <label>
            {{ 'subject.labelSubjectGroup' | translate }}
          </label>
          <span class="inputRequired"> * </span>
          <p-dropdown
            ngDefaultControl
            [showClear]="!editMode"
            [pTooltip]="'subject.tooltipGroup' | translate"
            class="input"
            id="grupos"
            name="grupos"
            [required]="true"
            placeholder="
              {{ 'subject.PlaceHolderSubjectGroup' | translate }}
            "
            [options]="optionGroups"
            [(ngModel)]="subject.group"
            (onChange)="changeGroup()"
            #groups="ngModel"
          ></p-dropdown>
          <small
            class="form-error"
            *ngIf="!groups.valid && (f.submitted || groups.touched)"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>

        <div class="p-col-12 p-md-4 p-field" *ngIf="editMode">
          <label>
            {{ 'subject.labelStateSubjet' | translate }}
          </label>
          <span class="inputRequired"> * </span>
          <p-dropdown
            id="stateSubject"
            class="input"
            name="stateSubject"
            [options]="optionStateSubject"
            [pTooltip]="getTooltipSubjectState()"
            [(ngModel)]="state"
            placeholder="
             {{ 'subject.PlaceHolderStateSubject' | translate }}
            "
            [required]="true"
            #stateSubject="ngModel"
          >
            <ng-template let-item pTemplate="item">
              <div [pTooltip]="item.tooltip">{{ item.label }}</div>
            </ng-template>
          </p-dropdown>
          <small
            class="form-error"
            *ngIf="!stateSubject.valid && (f.submitted || stateSubject.touched)"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
        <div
          class="p-col-12 p-field"
          [ngClass]="editMode ? 'p-md-4' : 'p-md-6'"
          id="tag_container"
        >
          <label>
            {{ 'subject.tag' | translate }}
          </label>
          <input
            id="tag"
            name="tag"
            type="text"
            autocomplete="off"
            pInputText
            [pTooltip]="'subject.tooltipTag' | translate"
            [(ngModel)]="subject.tag"
            #tag="ngModel"
          />
        </div>
      </div>
    </p-card>

    <p-card *ngIf="!editMode">
      <div class="p-fluid p-form-grid p-grid" id="currentPhase_container">
        <div class="p-col-12 p-field">
          <h4 style="margin: 10px 0 0">
            {{ 'subject.firstVisitNotice' | translate }}
          </h4>
        </div>

        <div class="p-col-12 p-md-4 p-field" id="phase_container">
          <label>
            {{ 'subject.labelVisit' | translate }}
            <span class="inputRequired">* </span>
          </label>
          <p-dropdown
            id="visits"
            class="input"
            name="visits"
            [pTooltip]="'subject.tooltipVisit' | translate"
            placeholder="
              {{ 'subject.PlaceHolderPhaseState' | translate }}
            "
            [options]="optionVisits"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="subject.currentScheduledPhase.phase"
            [required]="true"
            #visits="ngModel"
          ></p-dropdown>
          <small
            class="form-error"
            *ngIf="!visits.valid && (f.submitted || visits.touched)"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>

        <div class="p-col-12 p-md-4 p-field" id="status_container">
          <label>
            {{ 'subject.labelState' | translate }}
            <span class="inputRequired">* </span>
          </label>
          <p-dropdown
            class="input"
            placeholder="
              {{ 'subject.PlaceHolderStateVisit' | translate }}
            "
            name="phaseState"
            id="phaseState"
            [pTooltip]="getStatesTooltip()"
            [options]="optionScheduledPhaseState"
            [(ngModel)]="subject.currentScheduledPhase.state"
            [required]="true"
            #phaseState="ngModel"
            ><ng-template let-item pTemplate="item">
              <div [pTooltip]="item.tooltip">{{ item.label }}</div>
            </ng-template></p-dropdown
          >

          <small
            class="form-error"
            *ngIf="!phaseState.valid && (f.submitted || phaseState.touched)"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>

        <div class="p-col-12 p-md-4 p-field" id="date_container">
          <label>
            {{ 'subject.labelDateFirstVisit' | translate }}
            <span class="inputRequired">* </span>
          </label>
          <p-calendar
            inputStyleClass="iText"
            name="fecha"
            id="fecha"
            [pTooltip]="'subject.tooltipDate' | translate"
            [(ngModel)]="currentDateVisit"
            [showIcon]="true"
            appendTo="body"
            placeholder="{{
              'addEditSubjectComponent.PlaceHolderFormatDate' | translate
            }}"
            dateFormat="dd-M-yy"
            [required]="true"
            #date="ngModel"
          ></p-calendar>
          <small
            class="form-error"
            *ngIf="!date.valid && (f.submitted || date.touched)"
          >
            {{ 'general.required' | translate }}</small
          >
        </div>
      </div>
    </p-card>

    <p-card *ngIf="!editMode" id="users_container">
      <div class="p-fluid p-form-grid p-grid">
        <div class="p-col-12 p-field" style="border-bottom: 1px solid #adadad">
          <h4 style="margin: 10px 0 0">
            {{ 'subject.associateUserToSubject' | translate }}
          </h4>
          <p>
            {{ 'subject.associateUserToSubjectDescription' | translate }}
          </p>
          <span
            [innerHTML]="'subject.userOptionsGeneralDescription' | translate"
          >
          </span>
        </div>

        <div class="p-col-12 p-md-6 p-field">
          <label>
            {{ 'addEditSubjectComponent.labelSearchAutocomplete' | translate }}
          </label>
          <span class="inputRequired" *ngIf="!isCreatingUser"> *</span>
          <p-multiSelect
            [disabled]="isCreatingUser"
            appendTo="body"
            placeholder="{{
              'subject.PlaceHolderSearchAutocomplete' | translate
            }}"
            [filter]="true"
            [showToggleAll]="false"
            id="dropdown_user"
            filterBy="label"
            [options]="usersBySite"
            optionLabel="label"
            optionValue="value"
            name="searchUserSubject"
            #searchUserSubject="ngModel"
            [(ngModel)]="selectedUsers"
            emptyMessage="{{ 'general.noResultsFound' | translate }}"
            emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
            (onChange)="completeInput($event)"
          >
          </p-multiSelect>

          <small
            class="form-error"
            *ngIf="
              !searchUserSubject.value &&
              (f.submitted || searchUserSubject.touched) &&
              !isCreatingUser
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
        <div class="p-col-1 p-mt-1 alingButton">
          <button
            [disabled]="isCreatingUser"
            pButton
            pRipple
            type="button"
            id="btn_SelectUser"
            icon="pi pi-check"
            class="p-button-rounded"
            pTooltip="{{ 'general.choose' | translate }}"
            (click)="completeInput($event)"
          ></button>
        </div>

        <div
          class="p-col-12 p-md-4"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <label>
            {{ 'subject.createNewUser' | translate }}
          </label>

          <p-selectButton
            id="createUser"
            name="createUser"
            #createUser="ngModel"
            [(ngModel)]="isCreatingUser"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            (onChange)="resetValuesForUser()"
          ></p-selectButton>
        </div>

        <section
          style="display: contents"
          *ngIf="selectedUsers.length === 0 && isCreatingUser"
        >
          <div style="margin: 30px 0" class="p-col-12 p-md-6 p-field">
            <label>
              {{ 'subject.labelUsername' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <input
              id="username"
              name="username"
              type="text"
              autocomplete="off"
              pInputText
              [pTooltip]="'subject.tooltipUsername' | translate"
              [(ngModel)]="newUser.login"
              [disabled]="!isCreatingUser"
              required
              #username="ngModel"
            />
            <small
              class="form-error"
              *ngIf="
                !username.valid &&
                (f.submitted || username.touched) &&
                isCreatingUser &&
                !f.controls['code'].errors
              "
              >{{ 'general.required' | translate }}</small
            >
            <div *ngIf="f.controls['code'].errors?.incorrect && isCreatingUser">
              <small class="form-error">{{
                'general.noSpaces' | translate
              }}</small>
            </div>
          </div>

          <div style="margin: 30px 0" class="p-col-12 p-md-6 p-field">
            <label>
              {{ 'subject.labelName' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <input
              id="name"
              name="name"
              type="text"
              autocomplete="off"
              pInputText
              [pTooltip]="'subject.tooltipName' | translate"
              [(ngModel)]="newUser.name"
              [disabled]="!isCreatingUser"
              required
              #name="ngModel"
            />
            <small
              class="form-error"
              *ngIf="
                !name.valid && (f.submitted || name.touched) && isCreatingUser
              "
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <!-- notification preference -->
          <div style="margin: 30px 0" class="p-col-12 p-md-6 p-field">
            <label for="notificationPreference">
              {{ 'user.selectNotificationPreference' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <p-dropdown
              #alertType="ngModel"
              [showClear]="true"
              id="notificationPreference"
              class="input"
              placeholder="{{ 'general.choose' | translate }}"
              name="notificationPreference"
              [options]="alertTypes"
              optionLabel="name"
              optionValue="value"
              [(ngModel)]="newUser.notificationPreference"
              [required]="true"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="
                !phone.valid &&
                (f.submitted || phone.touched) &&
                isCreatingUser &&
                !phone.errors?.pattern
              "
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <!-- email -->
          <div style="margin: 30px 0" class="p-col-12 p-md-6 p-field">
            <label for="email">
              {{ 'subject.labelEmail' | translate }}
              <span
                *ngIf="newUser.notificationPreference === 'EMAIL'"
                class="inputRequired"
                >*
              </span>
            </label>
            <input
              id="email"
              name="email"
              type="text"
              autocomplete="off"
              pInputText
              [(ngModel)]="newUser.email"
              [disabled]="!isCreatingUser"
              #email="ngModel"
              [pattern]="
                newUser.notificationPreference === 'EMAIL' ? emailPattern : ''
              "
              [required]="newUser.notificationPreference === 'EMAIL'"
            />
            <small
              class="form-error"
              *ngIf="
                !email.valid &&
                (f.submitted || email.touched) &&
                isCreatingUser &&
                !email.errors?.pattern
              "
              >{{ 'general.required' | translate }}</small
            >
            <small
              class="form-error"
              *ngIf="
                email.errors &&
                email.errors.pattern &&
                (f.submitted || email.touched) &&
                isCreatingUser
              "
            >
              {{ 'user.labelPatternEmail' | translate }}
            </small>
          </div>
          <!-- phone number -->
          <div style="margin: 30px 0" class="p-col-12 p-md-6 p-field">
            <label for="phoneNumber">
              {{ 'subject.labelPhoneNumber' | translate }}
              <span
                *ngIf="
                  newUser.notificationPreference === 'SMS' ||
                  (project.isMFAActivated ?? false)
                "
                class="inputRequired"
                >*
              </span>
            </label>
            <input
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              autocomplete="off"
              pInputText
              [(ngModel)]="newUser.phoneNumber"
              [disabled]="!isCreatingUser"
              [required]="
                newUser.notificationPreference === 'SMS' ||
                (project.isMFAActivated ?? false)
              "
              #phone="ngModel"
              pTooltip="{{ 'user.tooltipInpuPhoneNumber' | translate }}"
            />
            <small
              class="form-error"
              *ngIf="
                !phone.valid && (f.submitted || phone.touched) && isCreatingUser
              "
              >{{ 'general.required' | translate }}</small
            >
            <small
              class="form-error"
              *ngIf="
                newUser.phoneNumber &&
                !trialpalService.phoneNumberService.isValidPhoneNumber(
                  newUser.phoneNumber
                ).isValid
              "
            >
              {{
                trialpalService.phoneNumberService.isValidPhoneNumber(
                  newUser.phoneNumber
                ).message
              }}
            </small>
          </div>
        </section>
      </div>
      <div
        class="grid-subject-cards"
        *ngIf="selectedUsers.length > 0 && !isCreatingUser"
      >
        <app-tp2-card
          *ngFor="let user of usersCards"
          [tp2Card]="user"
        ></app-tp2-card>
      </div>
    </p-card>

    <div class="p-fluid p-formgrid p-grid p-justify-end" *ngIf="!isModal">
      <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
        <button
          id="cancelButton"
          class="p-button-outlined p-button-secondary"
          pButton
          label=" {{ 'general.cancel' | translate }}"
          type="button"
          (click)="onCancelar()"
        ></button>
      </div>
      <div
        *ngIf="editMode ? hasSubjectUpdatePermission : true"
        class="p-field p-col-12 p-sm-4 p-md-3"
      >
        <button
          pButton
          id="submit"
          label="{{ (editMode ? 'general.edit' : 'general.add') | translate }}"
          type="submit"
          [disabled]="f.invalid"
        ></button>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid" *ngIf="isModal">
      <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
        <button
          class="p-button-outlined p-button-secondary"
          pButton
          label=" {{ 'general.cancel' | translate }}"
          type="button"
          (click)="onCancelar()"
        ></button>
      </div>
      <div
        *ngIf="editMode ? hasSubjectUpdatePermission : true"
        class="p-field p-col-12 p-sm-4 p-md-3"
      >
        <button
          pButton
          label="{{ (editMode ? 'general.edit' : 'general.add') | translate }}"
          type="submit"
          [disabled]="f.invalid"
        ></button>
      </div>
    </div>
  </form>
</div>
<!-- Confirmation dialog -->
<app-delete-confirmation
  [header]="
    'subject.changeToCompletedStateQuestion'
      | translate: { state: selectedFinalState() }
  "
  [description]="
    'subject.changeToCompletedStateWarning'
      | translate: { state: selectedFinalState() }
  "
  [btnDeleteText]="'subject.btnChangeToCompleted' | translate"
  [(display)]="displayDialog"
  (confirm)="confirmationStateChange.next(true)"
  position="fixed"
>
</app-delete-confirmation>
