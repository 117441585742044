import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InformedConsentFollowUpAssignExistingUsersComponent } from './informed-consent-follow-up-assign-existing-users/informed-consent-follow-up-assign-existing-users.component';
import { InformedConsentFollowUpMainComponent } from './informed-consent-follow-up-main/informed-consent-follow-up-main.component';
import { InformedConsentFollowUpUsersTableComponent } from './informed-consent-follow-up-users-table/informed-consent-follow-up-users-table';

const routes: Routes = [
  {
    path: '',
    component: InformedConsentFollowUpMainComponent,
  },
  {
    path: 'users',
    component: InformedConsentFollowUpUsersTableComponent,
  },
  {
    path: 'assignUsers',
    component: InformedConsentFollowUpAssignExistingUsersComponent,
  },
  {
    path: ':siteId',
    component: InformedConsentFollowUpMainComponent,
  },
  {
    path: ':siteId/e-consent/:confEconsentId',
    component: InformedConsentFollowUpMainComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InformedConsentFollowUpRoutingModule {}
