import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { Roles, TP2_ERRORS } from 'src/app/shared/global.variables';
import { ChangeReasonComponent } from '../../../shared/components/change-reason/change-reason.component';
import { ProjectService } from '../../project/project.service';
import { CreateUsersComponent } from '../create-users/create-users.component';
import { EditUsersComponent } from '../edit-users/edit-users.component';
import { UserService } from '../user.service';
import { TP2UserInput, User, UserState } from '../user.types';

const logger = new Logger('tp2-logger-userListPage');

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() idSubject: string = '';
  display = false;
  users: any[] = [];
  usersFiltered: any[] = [];
  usersPaginated: any[] = [];
  userCreateSubs: Subscription = new Subscription();
  listToken = null;
  userSelected = {
    username: '',
  };
  idProject = null;
  userGroups: any[] = [];
  selectedRole = '';
  selectedProject = '';
  filtroGeneral = '';
  projects: any[] = [];
  pagesize = 6;
  innerWidth = 0;
  siteid: any[] = [];
  sitesAuth: any[] = [];
  blockDropdownProject = false;
  isSubjectListiew = false;
  showSpinner: boolean = true;
  //Permissions
  hasUserCreatePermission: boolean = false;
  hasUserUpdatePermission: boolean = false;
  hasUserDeletePermission: boolean = false;
  hasUserListPermission: boolean = false;
  hasUserEnableDisablePermission: boolean = false;
  hasUserResetPasswordPermission: boolean = false;
  constructor(
    private trialpalService: TrialpalService,
    private dialogService: DialogService,
    private userService: UserService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private auth: AuthService,
    private router: Router,
    private projectService: ProjectService,
    public userPermissionsService: UserPermissionsService
  ) {
    //Obtiene los grupos cuando se detecta el cambio en el idioma
    this.userService.getGroupsAsync().then((groups: any) => {
      this.setGroups(groups);
      this.selectedRole = '';
    });
  }

  public ngAfterViewInit() {
    this.detectScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.detectScreenSize();
  }
  private detectScreenSize() {
    const height = window.innerHeight;
    if (height > 1235) {
      this.pagesize = 12;
      this.usersPaginated = this.usersFiltered.slice(0, this.pagesize);
    } else if (height > 1010) {
      this.pagesize = 9;
      this.usersPaginated = this.usersFiltered.slice(0, this.pagesize);
    } else {
      this.pagesize = 6;
      this.usersPaginated = this.usersFiltered.slice(0, this.pagesize);
    }
  }

  async ngOnInit(): Promise<void> {
    this.showSpinner = true;
    await this.setUserPermissions();
    if (!this.idSubject)
      this.trialpalService.showSpinner('user.entityPlural', 'LIST');
    this.idProject = this.route.snapshot.params.idProject;
    this.setGroups(this.userService.getGroups());
    const projectUsers =
      this.idProject !== null && this.idProject !== undefined;
    this.isSubjectListiew =
      this.idSubject !== null &&
      this.idSubject !== undefined &&
      this.idSubject !== '';
    const allUsers = !projectUsers && !this.isSubjectListiew;
    this.blockDropdownProject = false;
    if (allUsers) {
      await this.getFilterProjects();
      this.listUserUsers();
    } else if (projectUsers) {
      this.blockDropdownProject = true;
      this.listUserUsers();
      //se omite de la lista de roles a buscar el Admin ya que no pertenece a un proyecto en específico
      this.addGroupsDistinctByRole(Roles.Admin);
    } else if (this.isSubjectListiew) {
      this.listUsersSubject();
    }
    this.showSpinner = false;
  }

  ngOnChanges(): void {
    if (this.idSubject.trim().length !== 0) {
      this.listUsersSubject();
    }
  }

  async setUserPermissions() {
    this.hasUserCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserCreate,
        Roles.Admin,
      ]);

    this.hasUserUpdatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserUpdate,
        Roles.Admin,
      ]);

    this.hasUserDeletePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserDelete,
        Roles.Admin,
      ]);

    this.hasUserListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserList,
        Roles.Admin,
      ]);

    this.hasUserEnableDisablePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserEnableDisable,
        Roles.Admin,
      ]);

    this.hasUserResetPasswordPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.UserResetPassword,
        Roles.Admin,
      ]);
  }

  /**
   * Opciones de busqueda del select
   * @param groups
   */
  setGroups(groups: any) {
    this.userGroups = groups;
    if (this.auth.isAdmin()) {
      //se omite el sujeto para la busqueda por rol para el usuario administrador
      this.addGroupsDistinctByRole(Roles.Subject);
    }

    if (this.auth.isInvestigator()) {
      // para los demas roles de usuarios omitimos de la busqueda del rol Admin
      this.addGroupsDistinctByRole(Roles.Admin);
    }
    if (this.auth.isReader()) {
      // para los demas roles de usuarios omitimos de la busqueda del rol Admin
      this.addGroupsDistinctByRole(Roles.Admin);
      this.addGroupsDistinctByRole(Roles.Investigator);
      this.addGroupsDistinctByRole(Roles.Conciliator);
    }
  }

  /**
   * @des metodo para omitir roles del array del filtro de busqueda
   * @param role rol de usuario que se quiere quitar del filtro de busqueda
   */
  addGroupsDistinctByRole(role: Roles) {
    this.userGroups = this.userGroups.filter((x) => x.value !== role);
  }
  ngOnDestroy(): void {
    this.userCreateSubs.unsubscribe();
  }
  async getFilterProjects(): Promise<void> {
    const projects = await this.projectService.getProjects();
    logger.debug('listprojects response', projects);
    if (projects) {
      if (this.idProject) this.selectedProject = this.idProject + '';
      for (const project of projects) {
        if (project) {
          this.projects.push({ name: project.code, value: project.id });
        }
      }
    }
  }
  listUserUsers(): void {
    this.userService
      .listUserUsers(this.idProject, this.hasUserListPermission)
      .then((users) => {
        logger.debug('listUserUsers response', users);
        const index = users.findIndex(
          (x) => x.login === this.auth.getUsername()
        );
        if (index >= 0) {
          users.splice(index, 1); //excluimos al usuario coordinador
        }

        if (this.auth.isReader()) {
          users = users.filter(
            (user: User) => !user.role?.split(',')?.includes(Roles.Investigator)
          );
        }

        this.users = users;
        this.usersFiltered = users;
        this.usersPaginated = users.slice(0, this.pagesize);
        logger.debug('usersFiltered response', this.users);
      })
      .catch((error) => {
        logger.error('listUserUsers error', error);
        this.trialpalService.showServiceError('user.actions.listUsers', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  listUsersSubject(): void {
    this.userService
      .listUsersSubject(this.idSubject)
      .then((response) => {
        logger.debug('listUserSubject response', response);
        const listUsers = response.filter((item: any) => !item._deleted);
        this.users = listUsers;
        this.usersFiltered = listUsers;
        this.usersPaginated = listUsers.slice(0, this.pagesize);
      })
      .catch((error) => {
        logger.error('listUserSubject error', error);
        this.trialpalService.showServiceError('user.actions.listUsers', error);
      });
  }

  projectsUser(res: any) {
    const projects = [];
    if (res.projects) {
      const currentProjects = res.projects.split(',');
      for (const currentProject of currentProjects) {
        for (const project of this.projects) {
          if (currentProject === project.value) {
            projects.push(project.name);
          }
        }
      }
    }
    return projects.toString();
  }

  buildTPCardData(user: any): TPCardData {
    let colorState;
    let showResetPasswordButton;
    if (user.state === UserState.ENABLED) {
      colorState = 'green';
      showResetPasswordButton = true;
    } else if (user.state === UserState.DISABLED) {
      colorState = 'red';
      showResetPasswordButton = false;
    }

    const projects = this.projectsUser(user);
    let appVersion = user.appVersion;
    if (appVersion && appVersion === 'Web version') {
      appVersion =
        this.trialpalService.translateService.instant('version.webVersion');
    }

    return {
      data: user,
      entity: 'USER',
      header: user.login,
      state: this.trialpalService.translateService.instant(
        'user.enums.userStates.' + user.state
      ),
      icon1: 'pi pi-user',
      section1: user.name,
      icon2: 'pi pi-phone',
      section2: user.phoneNumber ? user.phoneNumber : '---',
      footer: projects,
      footer2: this.userService.getEnumsRolesUser(user.role),
      footer2ToolTip: this.userService.getEnumsRolesUser(user.role),
      icon3: 'pi pi-envelope',
      section3: user.email,
      icon4: appVersion ? 'pi pi-mobile' : '',
      section4: appVersion ?? null,
      styleColorState: colorState,
      stateActivateButton: user.state === UserState.ENABLED,
      showEditButton:
        this.hasUserUpdatePermission &&
        !String(user.role).includes('Consented'),
      showActivateButton:
        this.hasUserEnableDisablePermission &&
        !String(user.role).includes('Consented'),
      showResetPasswordButton:
        showResetPasswordButton && this.hasUserResetPasswordPermission,
      showDeleteButton:
        this.hasUserDeletePermission &&
        !String(user.role).includes('Consented'),
      showAuditButton: this.hasUserListPermission,
      cardIsButton: false,
      objectAction: user.login,
      entityAction:
        this.trialpalService.translateService.instant('user.entity'),
    } as TPCardData;
  }

  filterForProject(users: any): any {
    const UserFilterProject: any = [];
    for (const user of users) {
      user.project = user.project.split(',');
      const userInProject = user.project.find(
        (x: string) => x === this.idProject
      );
      if (userInProject) {
        UserFilterProject.push(user);
      }
    }
    return UserFilterProject;
  }
  getAttribute(attributes: [any], attName: string): string {
    let att = null;
    const a = attributes.find((x) => x.Name === attName);
    if (a) {
      att = a.Value;
    }
    return att;
  }
  edit(usuario: any): void {
    const ref = this.dialogService.open(EditUsersComponent, {
      header: this.trialpalService.translateService.instant(
        'user.headerModalUpdateUser'
      ),
      width: '90%',
      closeOnEscape: false,
      dismissableMask: false,
      data: {
        usuario: usuario,
        currentProject: this.idProject,
        currentSubject: this.idSubject,
      },
    });

    //Asigna la referencia al modal de usuario
    this.trialpalService.ref = ref;

    ref.onClose.subscribe((data: any) => {
      if (data) {
        const indexOriginal = this.users.findIndex(
          (x: any) => x.login === data.login
        );
        if (indexOriginal !== -1) {
          this.users.splice(indexOriginal, 1, data);
        }
        this.filterMaster();
      }
    });
  }
  create(): void {
    const ref = this.dialogService.open(CreateUsersComponent, {
      header: this.trialpalService.translateService.instant(
        'user.headerModalCreateUser'
      ),
      width: '90%',
      closeOnEscape: false,
      dismissableMask: false,
      data: {
        currentProject: this.idProject,
        currentSubject: this.idSubject,
      },
    });

    this.trialpalService.ref = ref;
    this.userCreateSubs = ref.onClose.subscribe((data) => {
      if (data) {
        // si el usuario ya existe, primero se elimina y luego se agrega
        const indexOriginal = this.users.findIndex(
          (x: any) => x.login === data.login || x.email === data.email
        );
        if (indexOriginal !== -1) {
          this.users.splice(indexOriginal, 1);
        }
        this.users.push(data);
        this.filterMaster();
      }
    });
  }

  openChangeReason(user: any) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant('user.deleteUser') +
        ': ' +
        user.login,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          user._changeReason = data;
          this.delete(user);
        }
      },
    });
  }
  delete(user: any): void {
    const userDeleteInput: TP2UserInput = {
      login: user.login,
      name: user.login,
      email: user.login,
      role: user.login,
      phoneNumber: user.phoneNumber,
      state: user.state,
      _changeReason: user._changeReason,
      _lastUser: this.auth.getUsername(),
    };
    this.trialpalService.showSpinner(
      this.translateService.instant('user.spinnerDeleteUser')
    );
    this.userService
      .deleteUser(userDeleteInput)
      .then((response: any) => {
        logger.debug('deleteUser response', JSON.parse(response));
        this.usersPaginated = this.usersPaginated.filter(
          (x) => x.login !== userDeleteInput.login
        );
        this.usersFiltered = this.usersFiltered.filter(
          (x) => x.login !== userDeleteInput.login
        );
        this.users = this.users.filter(
          (x) => x.login !== userDeleteInput.login
        );
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.translateService.instant(
            'user.messageSuccessDeleteUser.summary'
          ),
          detail: this.translateService.instant(
            'user.messageSuccessDeleteUser.detail'
          ),
        });
        this.detectScreenSize();
      })
      .catch((error: any) => {
        logger.error('deleteUser error', error);
        const responseData = error?.response?.data;

        if (
          responseData &&
          this.trialpalService.isJSON(responseData) &&
          JSON.parse(responseData).code === TP2_ERRORS.TP2ERRUC03
        ) {
          this.trialpalService.showServiceError('general.error', error);
        } else {
          this.trialpalService.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'user.messageErrorServerDeleteUser.messageErrorOperation.summary'
            ),
            detail: this.translateService.instant(
              'user.messageErrorServerDeleteUser.messageErrorOperation.detail'
            ),
          });
        }
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  selectUser(user: any): void {
    this.userSelected = user;
  }
  enabledDisabledUser(user: any): void {
    const userEnableDisableInput = {
      login: user.login,
      name: user.name,
      email: user.email,
      role: user.role,
      phoneNumber: user.phoneNumber,
      state: user.state,
    };
    if (user.state === UserState.ENABLED) {
      this.trialpalService.showSpinner(
        this.translateService.instant('user.spinnerDisabledUser')
      );
      this.userService
        .disabledUser(userEnableDisableInput)
        .then((response: any) => {
          logger.debug('disabledUser response', JSON.parse(response));
          this.trialpalService.messageService.add({
            severity: 'success',
            summary: this.translateService.instant(
              'user.messageSuccessDisabledUser.summary'
            ),
            detail: this.translateService.instant(
              'user.messageSuccessDisabledUser.detail'
            ),
          });
          this.updateAfterEnableDesable(user, UserState.DISABLED);
        })
        .catch((error: any) => {
          logger.error('disabledUser error', error);
          const responseData = error?.response?.data;

          if (
            responseData &&
            this.trialpalService.isJSON(responseData) &&
            JSON.parse(responseData).code === TP2_ERRORS.TP2ERRUC03
          ) {
            this.trialpalService.showServiceError('general.error', error);
          } else {
            this.trialpalService.messageService.add({
              severity: 'error',
              summary: this.translateService.instant(
                'user.messageErrorServerDeleteUser.messageErrorOperation.summary'
              ),
              detail: this.translateService.instant(
                'user.messageErrorServerDeleteUser.messageErrorOperation.detail'
              ),
            });
          }
        })
        .finally(() => this.trialpalService.hideSpinner());
    } else {
      this.trialpalService.showSpinner(
        this.translateService.instant('user.spinnerEnabledUser')
      );
      this.userService
        .enabledUser(userEnableDisableInput)
        .then((response: any) => {
          logger.debug('EnabledUser response', JSON.parse(response));
          this.trialpalService.messageService.add({
            severity: 'success',
            summary: this.translateService.instant(
              'user.messageSuccessEnabledUser.summary'
            ),
            detail: this.translateService.instant(
              'user.messageSuccessEnabledUser.detail'
            ),
          });
          this.updateAfterEnableDesable(user, UserState.ENABLED);
        })
        .catch((error: any) => {
          logger.error('EnabledUser error', error);
          const responseData = error?.response?.data;

          if (
            responseData &&
            this.trialpalService.isJSON(responseData) &&
            JSON.parse(responseData).code === TP2_ERRORS.TP2ERRUC03
          ) {
            this.trialpalService.showServiceError('general.error', error);
          } else {
            this.trialpalService.messageService.add({
              severity: 'error',
              summary: this.translateService.instant(
                'user.messageErrorServerDeleteUser.messageErrorOperation.summary'
              ),
              detail: this.translateService.instant(
                'user.messageErrorServerDeleteUser.messageErrorOperation.detail'
              ),
            });
          }
        })
        .finally(() => this.trialpalService.hideSpinner());
    }
  }
  updateAfterEnableDesable(user: any, state: UserState) {
    const userIndex = this.users.findIndex((x) => x.login === user.login);
    this.users[userIndex].state = state;
    this.users.splice(userIndex, 1, this.users[userIndex]);

    const userIndexFiltered = this.usersFiltered.findIndex(
      (x) => x.login === user.login
    );
    this.usersFiltered[userIndexFiltered].state = state;
    this.usersFiltered.splice(
      userIndexFiltered,
      1,
      this.usersFiltered[userIndexFiltered]
    );
  }

  filterMaster() {
    this.usersFiltered = this.users;
    this.filterByProject();
    this.filterByRole();
    this.filterByAtributes();
    this.usersPaginated = this.usersFiltered.slice(0, this.pagesize);
  }
  filterByProject() {
    if (this.selectedProject) {
      const UserFilterProject: any = [];
      for (const user of this.usersFiltered) {
        if (user.projects) {
          const projects = user.projects.split(',');
          const userInProject = projects.find(
            (x: string) => x === this.selectedProject
          );
          if (userInProject) {
            UserFilterProject.push(user);
          }
        }
      }
      this.usersFiltered = UserFilterProject;
    }
  }
  filterByRole() {
    if (this.selectedRole) {
      this.usersFiltered = this.usersFiltered.filter(
        (x) => x.role.indexOf(this.selectedRole) !== -1
      );
    }
  }
  filterByAtributes(): void {
    const filter = this.filtroGeneral.toLowerCase().trim();
    if (this.selectedRole) {
      const keys = ['login', 'name', 'email', 'appVersion', 'phoneNumber'];
      this.usersFiltered = this.usersFiltered.filter((x) =>
        keys.some((key) => x[key]?.toLowerCase().includes(filter))
      );
    } else {
      this.usersFiltered = this.usersFiltered.filter(
        (x) =>
          x.login.toLowerCase().includes(filter) ||
          x.email.toLowerCase().includes(filter) ||
          x.name?.toLowerCase().includes(filter)
      );
    }
  }
  paginate(event: any) {
    this.usersPaginated = this.usersFiltered.slice(
      event.page * this.pagesize,
      event.page * this.pagesize + this.pagesize
    );
  }
  forward(): void {
    if (this.idProject) {
      this.router.navigate(['project/' + this.idProject + '/detail']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
