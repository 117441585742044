<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="p-grid p-fluid">
    <div class="p-offset-1 p-col-11" style="margin-top: 1rem">
      <a routerLink="/login">
        <em class="pi pi-chevron-left"> </em>
        {{ 'password.labelBack' | translate }}
      </a>
    </div>
    <div class="p-offset-1 p-col-11">
      <h2>{{ 'password.h2AssignNewPassword' | translate }}</h2>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4">
      <h4 class="p-my-0 p-pb-3">{{ 'password.labelPassword' | translate }}</h4>
      <div class="p-inputgroup">
        <input
          name="pass1"
          type="password"
          autocomplete="off"
          pPassword
          [(ngModel)]="password"
          [showPassword]="isPasswordShown"
          required
          [feedback]="false"
          #pass1="ngModel"
          [pTooltip]="'password.pTooltipPasswordPattern' | translate"
          tooltipPosition="top"
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass1.errors &&
          pass1.errors.required &&
          (f.submitted || pass1.touched)
        "
        >{{ 'general.required' | translate }}</small
      >

      <h4 class="p-my-0 p-py-3">
        {{ 'password.labelConfirmPassword' | translate }}
      </h4>
      <div class="p-inputgroup">
        <input
          name="pass2"
          type="password"
          pPassword
          [(ngModel)]="passwordConfirmation"
          [showPassword]="isPasswordShown"
          [feedback]="false"
          required
          #pass2="ngModel"
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass2.errors &&
          pass2.errors.required &&
          (f.submitted || pass2.touched)
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-md-4 p-md-offset-6 p-lg-3 p-lg-offset-8">
      <button
        pButton
        [label]="'password.buttonAssignPassword' | translate"
        type="submit"
        class="p-button-secondary"
      ></button>
    </div>
  </div>
</form>
<p-dialog
  [header]="'password.titleDialogCodeMFA' | translate"
  [modal]="true"
  [(visible)]="displayMFA"
  *ngIf="displayMFA"
>
  <input
    id="codeMFA"
    name="codeMFA"
    type="text"
    pInputText
    placeholder="{{ 'password.placeholderMfa' | translate }}"
    [(ngModel)]="codeMFA"
  />
  <button
    pButton
    id="btnCodeMFA"
    type="button"
    label="{{ 'user.buttonLogin' | translate }}"
    class="button_submit"
    style="margin-top: 10px"
    (click)="confirmLogin()"
  ></button>
</p-dialog>
