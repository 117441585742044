import {
  ConfState,
  InstanceState,
  MediaType,
  ModelBooleanInput,
  ModelIDInput,
  ModelInstanceStateInput,
  ModelIntInput,
  ModelStringInput,
  RevisionStateAlertInstance,
  TP2File,
} from 'src/app/services/trialpal.types';
import { SymptomOccurrency } from '../ediary/ediary.types';
import { Project, ProjectState } from '../project/project.types';
import { Site } from '../sites/sites.types';
import { Subject, SubjectState } from '../subjects/subjects.types';
import { UserEntityType } from '../user/user.types';

export type ConfSymptomByConfReportIdCustomQuery = {
  __typename: 'ModelConfSymptomConnection';
  items: Array<{
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption> | null;
    buttonHelpScreenLabel?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensitySizeLabel?: string | null;
    isFinishDateRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    intensityType?: IntensityType | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    intensityQuestions?: Array<{
      __typename: 'IntensityQuestion';
      id: string;
      order: number;
      question: string;
      intensity?: Intensity | null;
    } | null> | null;
    highIntensityScaleOptions?: {
      __typename: 'IntensityScale';
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    mediumIntensityScaleOptions?: {
      __typename: 'IntensityScale';
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lowIntensityScaleOptions?: {
      __typename: 'IntensityScale';
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    noneIntensityScaleOptions?: {
      __typename: 'IntensityScale';
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    lifeThreateningScaleOptions?: {
      __typename: 'IntensityScale';
      active: boolean;
      startValue: number;
      endValue: number;
      helpText: string;
      label?: string | null;
    } | null;
    _lastUser: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type CreateConfReportInput = {
  projectId: string;
  id?: string | null;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfReportMutation = {
  __typename: 'ConfReport';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type GetConfReportQuery = {
  __typename: 'ConfReport';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelReportInstanceFilterInput = {
  confReportId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  plannedDate?: ModelStringInput | null;
  completeDate?: ModelStringInput | null;
  json?: ModelStringInput | null;
  score?: ModelIntInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  reportConfVersion?: ModelIntInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelReportInstanceFilterInput | null> | null;
  or?: Array<ModelReportInstanceFilterInput | null> | null;
  not?: ModelReportInstanceFilterInput | null;
};
export type DeleteConfReportMutation = {
  __typename: 'ConfReport';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type DeleteConfReportInput = {
  id: string;
  _version?: number | null;
};
export type UpdateConfReportInput = {
  projectId?: string | null;
  id: string;
  groups?: Array<string> | null;
  phases?: Array<string> | null;
  instancePerDay?: boolean | null;
  reportTitle?: string | null;
  showHelp?: boolean | null;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout?: PageLayout | null;
  programationType?: ReportProgramationType | null;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating?: boolean | null;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired?: boolean | null;
  isGPSRequired?: boolean | null;
  isUserIPRequired?: boolean | null;
  isMediaRequired?: boolean | null;
  isDeviceDataRequired?: boolean | null;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfReportMutation = {
  __typename: 'ConfReport';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    isSubjectNumberCheckRequired?: boolean | null;
    subjectNumberRegex?: string | null;
    subjectNumberPlaceholder?: string | null;
    checkSiteCodeInSubjectNumber?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateConfReportBySubjectInput = {
  id: string;
  subjectId?: string | null;
  confReportId?: string | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type UpdateConfReportBySubjectMutation = {
  __typename: 'ConfReportBySubject';
  id: string;
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    isDaysOfWeekRequired?: boolean | null;
    isDateRangeLimitRequired?: boolean | null;
    availableDays?: Array<number | null> | null;
    startDate?: string | null;
    endDate?: string | null;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateConfReportBySubjectInput = {
  id?: string | null;
  subjectId: string;
  confReportId: string;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateConfReportBySubjectMutation = {
  __typename: 'ConfReportBySubject';
  id: string;
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    isDaysOfWeekRequired?: boolean | null;
    isDateRangeLimitRequired?: boolean | null;
    availableDays?: Array<number | null> | null;
    startDate?: string | null;
    endDate?: string | null;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetConfReportBySubjectQuery = {
  __typename: 'ConfReportBySubject';
  id: string;
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confReportId: string;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    isDaysOfWeekRequired?: boolean | null;
    isDateRangeLimitRequired?: boolean | null;
    availableDays?: Array<number | null> | null;
    startDate?: string | null;
    endDate?: string | null;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ListConfAlertsQueryCustom = {
  __typename: 'ModelConfAlertConnection';
  items: Array<{
    __typename: 'ConfAlert';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    confSymptomId?: string | null;
    confTemperatureId?: string | null;
    projectId: string;
    id: string;
    alertName: string;
    recipients?: Array<string> | null;
    siteRecipients: Array<SiteRecipients> | null;
    type: AlertType;
    mailSubject?: string | null;
    mailBody?: string | null;
    SMSBody?: string | null;
    triggerVisitCompletion?: boolean | null;
    triggerEDiarySuspension?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type SiteRecipients = {
  __typename: 'SiteRecipients';
  siteId: string;
  recipients: Array<string>;
};
export type ReportInstance = {
  __typename: 'ReportInstance';
  confReportId: string;
  confReport?: ConfReport | null;
  subjectId: string;
  subject?: Subject | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityType | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ConfReport = {
  __typename: 'ConfReport';
  projectId: string;
  project?: Project | null;
  id: string;
  groups: Array<string>;
  phases: Array<string>;
  instancePerDay: boolean;
  reportTitle: string;
  showHelp: boolean;
  reportHelp?: string | null;
  helpScreenDisplayOptions?: Array<HelpScreenDisplayOption | null> | null;
  reportIcon?: string | null;
  symptomPageLayout: PageLayout;
  programationType: ReportProgramationType;
  plannedDayList?: Array<number> | null;
  isDisplayDependentOnTheEDiary?: boolean | null;
  forms?: Array<string> | null;
  isControlledBySite?: boolean | null;
  allowReportBackDating: boolean;
  enableLocalNotifications?: boolean | null;
  futureNotifications?: number | null;
  alertType?: OnDemandAlertType | null;
  alertWeeklyDays?: Array<number | null> | null;
  alertInBetweenDaysFrequency?: number | null;
  alertDuringSameDayFrequency?: number | null;
  alertStartTime?: string | null;
  alertEndTime?: string | null;
  jsonForm?: string | null;
  isSignatureRequired: boolean;
  isGPSRequired: boolean;
  isUserIPRequired: boolean;
  isMediaRequired: boolean;
  isDeviceDataRequired: boolean;
  isDaysOfWeekRequired?: boolean | null;
  isDateRangeLimitRequired?: boolean | null;
  availableDays?: Array<number | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaTypes?: Array<MediaType> | null;
  isAssociatedToVisits?: boolean | null;
  areSymptomsRequired?: boolean | null;
  areAlertsRequired?: boolean | null;
  isDeviceDataManagementRequired?: boolean | null;
  availableUsers?: Array<ReportAvailableUserType | null> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type DeleteConfAlertInput = {
  id: string;
  _version?: number | null;
};
export type ListAlertInstancesQuery = {
  __typename: 'ModelAlertInstanceConnection';
  items: Array<{
    __typename: 'AlertInstance';
    confAlertId: string;
    subjectId: string;
    id: string;
    confAlert_data: string;
    entityId: string;
    entityName: string;
    state?: string | null;
    revisionState?: RevisionStateAlertInstance | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ModelAlertInstanceFilterInput = {
  confAlertId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  confAlert_data?: ModelStringInput | null;
  entityId?: ModelStringInput | null;
  entityName?: ModelStringInput | null;
  state?: ModelStringInput | null;
  revisionState?: ModelRevisionStateAlertInstanceInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelAlertInstanceFilterInput | null> | null;
  or?: Array<ModelAlertInstanceFilterInput | null> | null;
  not?: ModelAlertInstanceFilterInput | null;
};
export type ModelRevisionStateAlertInstanceInput = {
  eq?: RevisionStateAlertInstance | null;
  ne?: RevisionStateAlertInstance | null;
};
export type ConfReportBySubject = {
  __typename: 'ConfReportBySubject';
  id: string;
  subjectId: string;
  subject?: Subject | null;
  confReportId: string;
  confReport?: ConfReport | null;
  isEnabled?: boolean | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export enum SymptomType {
  LOCAL = 'LOCAL',
  GENERAL = 'GENERAL',
}
export enum Symptom {
  PAIN = 'PAIN',
  TENDERNESS = 'TENDERNESS',
  NAUSEA = 'NAUSEA',
  FEVER = 'FEVER',
  IRRITABILITY = 'IRRITABILITY',
  VOMIT = 'VOMIT',
  ANORMAL_CRYING = 'ANORMAL_CRYING',
  DROWSINESS = 'DROWSINESS',
  APPETITE_LOSS = 'APPETITE_LOSS',
  HEADACHE = 'HEADACHE',
  COUGH = 'COUGH',
  SORE_THROAT = 'SORE_THROAT',
  RUNNY_NOSE = 'RUNNY_NOSE',
  WHEEZING = 'WHEEZING',
  MUSCLE_SORENESS = 'MUSCLE_SORENESS',
  EARACHE = 'EARACHE',
  DIARRHEA = 'DIARRHEA',
  FATIGUE = 'FATIGUE',
  CHILL = 'CHILL',
  ARTHRALGIA = 'ARTHRALGIA',
  SWELLING = 'SWELLING',
  REDNESS = 'REDNESS',
  HEMATOMA = 'HEMATOMA',
  SORENESS = 'SORENESS',
  HARDNESS = 'HARDNESS',
  ITCHING = 'ITCHING',
  OTHER = 'OTHER',
}
export enum InjectionSite {
  NA = 'NA',
  ARM = 'ARM',
  THIGH = 'THIGH',
  BUTTOCK = 'BUTTOCK',
}
export enum InjectionSide {
  NA = 'NA',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
export enum IntensityType {
  RANGE = 'RANGE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  NUMERIC_VALUE = 'NUMERIC_VALUE',
  NUMERIC_VALUE_QUESTIONS = 'NUMERIC_VALUE_QUESTIONS',
}
export enum Intensity {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  LIFE_THREATENING = 'LIFE_THREATENING',
}
export enum PageLayout {
  OCURRENCE_FIRST = 'OCURRENCE_FIRST',
  ONE_BY_ONE = 'ONE_BY_ONE',
}
export enum ReportProgramationType {
  ON_DEMAND = 'ON_DEMAND',
  PLANNED_DAYS = 'PLANNED_DAYS',
}
export enum OnDemandAlertType {
  DAYS = 'DAYS',
  WEEKLY = 'WEEKLY',
}
export enum ReportAvailableUserType {
  SUBJECT = 'SUBJECT',
  INVESTIGATOR = 'INVESTIGATOR',
}
export enum AlertRuleType {
  SYMPTOM_INTENSITY_IN = 'SYMPTOM_INTENSITY_IN',
  TEMPERATURE_EQUALS_OR_MORE_THAN = 'TEMPERATURE_EQUALS_OR_MORE_THAN',
  MEDICAL_ATTENTION_WITH_HOSPITALIZATION = 'MEDICAL_ATTENTION_WITH_HOSPITALIZATION',
  JSONFORM_ANSWER = 'JSONFORM_ANSWER',
  SYMPTOM_VALUE_EQUALS_OR_MORE_THAN = 'SYMPTOM_VALUE_EQUALS_OR_MORE_THAN',
  SYMPTOM_VALUE_EQUALS_OR_LESS_THAN = 'SYMPTOM_VALUE_EQUALS_OR_LESS_THAN',
  SYMPTOM_VALUE_EQUALS = 'SYMPTOM_VALUE_EQUALS',
  SYMPTOM_OCCURRENCY = 'SYMPTOM_OCCURRENCY',
  WITHOUT_SYMPTOM_OCCURRENCY = 'WITHOUT_SYMPTOM_OCCURRENCY',
  SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN = 'SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN',
  SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN = 'SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN',
  SYMPTOMS_OCURRENCY_EQUALS = 'SYMPTOMS_OCURRENCY_EQUALS',
  TOTAL_FORM_VALUE_EQUALS = 'TOTAL_FORM_VALUE_EQUALS',
  TOTAL_FORM_VALUE_LESS_THAN = 'TOTAL_FORM_VALUE_LESS_THAN',
  TOTAL_FORM_VALUE_MORE_THAN = 'TOTAL_FORM_VALUE_MORE_THAN',
}
export enum AlertType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
export enum HelpScreenDisplayOption {
  ON_DEMAND = 'ON_DEMAND',
  PREVIOUS_TO_FORM = 'PREVIOUS_TO_FORM',
  WITH_SYMPTOM_OCCURRENCY = 'WITH_SYMPTOM_OCCURRENCY',
  SKIP_EDITION = 'SKIP_EDITION',
}
