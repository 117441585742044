import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Analytics, Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';

const logger = new Logger('tp2-logger-resetPasswordPage');
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  step = true;
  isPasswordShown = false;
  userId = '';
  newPassword = '';
  activationCode = '';
  passwordConfirmation = '';
  userName = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private trialpalService: TrialpalService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.step = true;
    this.userId = this.route.snapshot.params.username.trim();
    if (this.userId) {
      this.step = false;
    }
  }

  onSubmit(form: NgForm): void {
    this.userId = this.userId.trim();
    this.trialpalService.validateSpaces(this.userId, 'usuario', form);

    if (form.valid) {
      if (this.step) {
        this.sendCode(form);
      } else {
        this.validateChangePassword();
      }
    } else {
      return this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant(
          'password.messageErrorFieldEmpty.summary'
        ),
        detail: this.translateService.instant(
          'password.messageErrorFieldEmpty.detail'
        ),
      });
    }
  }
  showPasswords(): void {
    this.isPasswordShown = !this.isPasswordShown;
  }
  sendCode(form: NgForm): void {
    if (!this.userId) {
      return this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant(
          'password.messageErrorEmptyUser.summary'
        ),
        detail: this.translateService.instant(
          'password.messageErrorEmptyUser.detail'
        ),
      });
    }

    this.trialpalService.showSpinner(
      this.translateService.instant('password.spinnerSendCode')
    );
    this.authService.sendCodeResetPassword(this.userId.trim()).then(
      (response: any) => {
        logger.debug('sendCodeResetPassword response', response);
        const user = this.userId.trim();
        form.resetForm();
        form.controls['usuario'].setValue(user);
        this.step = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant(
            'password.messageSuccessSendCode.summary'
          ),
          detail: this.translateService.instant(
            'password.messageSuccessSendCode.detail'
          ),
        });
        this.trialpalService.hideSpinner();
      },
      (error: any) => {
        logger.error('sendCodeResetPassword error', error);
        this.trialpalService.hideSpinner();
        if (error.code === 'UserNotFoundException') {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'password.messageUserNotFound.summary'
            ),
            detail: this.translateService.instant(
              'password.messageUserNotFound.detail'
            ),
          });
        } else if (error.code === 'NotAuthorizedException') {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'password.messageErrorSendCode.summary'
            ),
            detail: this.translateService.instant(
              'password.messageErrorSendCode.detail'
            ),
          });
        } else if (error.code === 'LimitExceededException') {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'password.messageErrorLimmitExceeded.summary'
            ),
            detail: this.translateService.instant(
              'password.messageErrorLimmitExceeded.detail'
            ),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'password.messageErrorResetPassword.summary'
            ),
            detail: this.translateService.instant(
              'password.messageErrorResetPassword.detail',
              {
                errorCode: error.code,
              }
            ),
          });
        }
      }
    );
  }

  validateChangePassword(): void {
    if (this.newPassword === this.passwordConfirmation) {
      const passwordValidate = this.trialpalService.validatePassword(
        this.newPassword
      );
      if (!passwordValidate) {
        return this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant(
            'password.messageErrorPatternPassword.summary'
          ),
          detail: this.translateService.instant(
            'password.messageErrorPatternPassword.detail'
          ),
        });
      }
      this.trialpalService.showSpinner(
        this.translateService.instant('password.spinnerChangePassword')
      );
      this.authService
        .completeResetPassword(
          this.userId.trim(),
          this.newPassword,
          this.activationCode.trim()
        )
        .then(
          (response: any) => {
            logger.debug('completeResetPassword response', response);
            if (response === 'SUCCESS') {
              Analytics.record({
                name: 'site_restablish_password',
                attributes: {
                  userSub: this.authService.user?.attributes?.sub,
                  userName: this.authService.user?.username,
                },
              });
            }
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant(
                'password.messageSuccessChangePassword.summary'
              ),
              detail: this.translateService.instant(
                'password.messageSuccessChangePassword.detail'
              ),
            });
            this.trialpalService.hideSpinner();
            this.router.navigate(['/login']);
          },
          (error: any) => {
            logger.error('completeResetPassword error', error);
            this.step = false;
            this.trialpalService.hideSpinner();
            if (error.code === 'CodeMismatchException') {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant(
                  'password.messageErrorWrongCode.summary'
                ),
                detail: this.translateService.instant(
                  'password.messageErrorWrongCode.detail'
                ),
              });
            }
          }
        );
    } else {
      return this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant(
          'password.messageErrorPasswordNotMatch.summary'
        ),
        detail: this.translateService.instant(
          'password.messageErrorPasswordNotMatch.detail'
        ),
      });
    }
    if (!this.newPassword || !this.passwordConfirmation) {
      return this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant(
          'password.messageErrorPasswordEmpty.summary'
        ),
        detail: this.translateService.instant(
          'password.messageErrorPasswordEmpty.detail'
        ),
      });
    } else if (!this.activationCode.trim()) {
      return this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant(
          'password.messageErrorFieldEmpty.summary'
        ),
        detail: this.translateService.instant(
          'password.messageErrorFieldEmpty.detail'
        ),
      });
    }
  }
}
