import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import {
  InstanceState,
  MedicationConfigurationMode,
  VerifiedDayInstance,
  VerifiedMedicalAttentionInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import { ConciliationComponents } from 'src/app/modules/conciliation/conciliationData';
import { PaginationService } from 'src/app/modules/conciliation/pagination.service';
import { TrialpalService } from '../../../../../../../services/trialpal.service';
import { ConciliationService } from '../../../../../conciliation.service';
import { MedicalAttentionService } from '../../../medical-attention/medical-attention.service';
import { MedicationService } from '../../../medication/medication.service';
import { SymptomService } from '../../../symptom/symptom.service';
import { DayInstanceService } from '../day-instance.service';
import { SummaryDayComponent } from '../summary-day/summary-day.component';
const logger = new Logger('Dayinstance conciliation');
@Component({
  selector: 'app-edit-day-instance',
  templateUrl: './edit-day-instance.component.html',
  styleUrls: ['./edit-day-instance.component.scss'],
})
export class EditDayInstanceComponent implements OnChanges {
  @Input() currentDayInstance: any = {};
  @Output() exportAuditRecord: EventEmitter<any> = new EventEmitter();
  isNext: boolean = false;
  form: FormGroup;
  formChange: any = {};
  configuration: any = {};
  givenTo: any[] = [
    {
      label: this.trialpalService.translateService.instant(
        'medicationinstance.enums.PREVENT'
      ),
      value: 'PREVENT',
    },
    {
      label: this.trialpalService.translateService.instant(
        'medicationinstance.enums.TREAT'
      ),
      value: 'TREAT',
    },
  ];
  booleanOptions: any[] = [];
  constructor(
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    private medicationService: MedicationService,
    private trialpalService: TrialpalService,
    private conciliationService: ConciliationService,
    private dayInstanceService: DayInstanceService,
    private symptomService: SymptomService,
    private medicalAttentionService: MedicalAttentionService,
    private paginationService: PaginationService
  ) {
    this.form = this.formBuilder.group({});
    this.buildForm();
  }

  ngOnChanges(): void {
    this.buildForm();
    this.exportAuditRecord.emit(null);

    //No muestra el formulario de edición si no se selecciono ningun día
    if (!this.currentDayInstance?.dayInstance?.id) {
      this.paginationService.closeSideBar();
    } else {
      const verified = this.dayInstanceService.verifiedDayInstances.find(
        (VDI: any) => {
          return VDI.id === this.currentDayInstance?.verifiedDayInstance.id;
        }
      );
      if (verified?.id) {
        this.currentDayInstance.verifiedDayInstance = verified;
      }
      this.booleanOptions = this.conciliationService.buildBooleanOptions();
      this.configuration = this.currentDayInstance?.configuration;
      const dayInstance = this.currentDayInstance?.verifiedDayInstance?.id
        ? this.currentDayInstance.verifiedDayInstance
        : this.currentDayInstance.dayInstance;

      //Filtra los sintomas perteneciente a la instancia del medicamento // Obtiene la configuración del medicamento
      this.form.patchValue({
        hasInstance: dayInstance[this.currentDayInstance?.hasInstance],
        hasInstanceComment:
          dayInstance[this.currentDayInstance?.hasInstanceComment],
        medicationGivenTo: dayInstance.medicationGivenTo,
        medicationGivenToComment: dayInstance?.medicationGivenToComment,
      });
      logger.debug('form', this.form, dayInstance);
      this.formChange = this.form.value;
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      hasInstance: [],
      hasInstanceComment: [],
      medicationGivenTo: [],
      medicationGivenToComment: [],
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      if (
        this.conciliationService.isFormChange(this.form.value, this.formChange)
      ) {
        this.openModal();
      } else {
        const isCommentChange = this.conciliationService.isFormCommentChange(
          this.form.value,
          this.formChange
        );
        await this.sendVerifiedDayInstance(isCommentChange);
      }
    } else {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
  }

  async openModal() {
    const [original, verified] = this.conciliationService.getValuesChange(
      this.formChange,
      this.form.value
    );
    const ref = this.dialogService.open(SummaryDayComponent, {
      data: {
        original,
        verified,
        configuration: this.currentDayInstance?.configuration,
      },
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmSaveForm'
      ),
      style: { 'min-width': '360px', width: '50%' },
    });
    ref.onClose.subscribe(async (canPass: boolean) => {
      if (canPass) {
        this.conciliationService.recordChangeEvent(
          verified,
          original,
          'dayInstance'
        );
        await this.sendVerifiedDayInstance(true);
      }
    });
  }

  async sendVerifiedDayInstance(isChange: boolean = false) {
    if (this.currentDayInstance.isEdition) {
      //Si no hubo un cambio pasa al siguiente medicamento
      if (!isChange && this.currentDayInstance.isCompleted) {
        this.nextDayInstance();
      } else {
        await this.updateVerifiedDayInstance();
      }
    } else {
      await this.createVerifiedDayInstance();
    }
  }

  async createVerifiedDayInstance() {
    this.conciliationService.showSpinner(
      'transactionLoadingCreate',
      'ediary.dayEntity'
    );
    const data = this.updateData();
    this.dayInstanceService
      .createVerifiedDayInstance(
        data,
        this.currentDayInstance.dayInstance,
        this.conciliationService.getIsAutomaticReview()
      )
      .then(async (verifiedDayInstance: VerifiedDayInstance) => {
        await this.updateDataTable(verifiedDayInstance);
        this.conciliationService.messageSuccess(true);
        this.nextDayInstance();
      })
      .catch((err: any) => {
        logger.error('create error', err);
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async updateVerifiedDayInstance() {
    this.conciliationService.showSpinner(
      'transactionLoadingUpdate',
      'ediary.dayEntity'
    );
    const data = this.updateData();
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    this.dayInstanceService
      .updateVerifiedDayInstance(
        data,
        this.currentDayInstance.verifiedDayInstance
      )
      .then(async (verifiedDayInstance: VerifiedDayInstance) => {
        await this.updateDataTable(verifiedDayInstance);
        this.conciliationService.messageSuccess(false);
        this.nextDayInstance();
      })
      .catch((err: any) => {
        logger.error('update error', err);
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  updateData() {
    const data = { ...this.form.value };
    data[this.currentDayInstance?.hasInstance] = data.hasInstance;
    data[this.currentDayInstance?.hasInstanceComment] = data.hasInstanceComment;
    data[this.currentDayInstance?.hasInstanceCompleted] = true;
    delete data.hasInstance;
    delete data.hasInstanceComment;
    return data;
  }

  async updateDataTable(verifiedDayInstance: VerifiedDayInstance) {
    this.currentDayInstance.verifiedDayInstance = verifiedDayInstance;
    this.currentDayInstance.isEdition = true;
    this.currentDayInstance.isCompleted = true;

    this.dayInstanceService.updateListVerifiedDayInstances(verifiedDayInstance);

    if (this.currentDayInstance?.hasInstance === 'hasOtherSymptoms') {
      this.currentDayInstance.hasOtherSymptomsRow.verified =
        verifiedDayInstance.hasOtherSymptoms;
      if (verifiedDayInstance.hasOtherSymptoms === false) {
        await this.deleteOtherSymptomsByDayInstanceId(
          verifiedDayInstance.dayInstanceId ?? '',
          verifiedDayInstance.hasOtherSymptomsComment,
          verifiedDayInstance.id
        );
      }
      this.updateCustomComments();
      this.symptomService.addOtherSymptomComments();
    }

    //Obtiene el dia verificado

    if (this.currentDayInstance?.hasInstance === 'hasMedicalAttention') {
      if (verifiedDayInstance.hasMedicalAttention === false) {
        await this.deleteMedicalAttentionByDayInstance(
          verifiedDayInstance.dayInstanceId ?? '',
          verifiedDayInstance.hasMedicalAttentionComment,
          verifiedDayInstance.id
        );
      }
      this.currentDayInstance.hasMedicalAttentionRow.verified =
        verifiedDayInstance?.hasMedicalAttention;

      this.updateCustomComments();
      this.medicalAttentionService.addCommentsTable();
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedication') {
      if (verifiedDayInstance.hasMedication === false) {
        await this.deleteMedicationByDayInstanceId(
          verifiedDayInstance.dayInstanceId ?? '',
          verifiedDayInstance.hasMedicationComment
        );
      }
      this.currentDayInstance.hasMedicationRow.verified =
        verifiedDayInstance?.hasMedication;

      this.currentDayInstance.hasMedicationRow.verifiedId =
        verifiedDayInstance?.id;

      this.currentDayInstance.medicationGivenToRow.verified =
        verifiedDayInstance.medicationGivenTo;

      this.currentDayInstance.medicationGivenToRow.verifiedId =
        verifiedDayInstance?.id;

      this.updateComments();
      this.medicationService.addMedicationComments();
    }
  }

  async deleteOtherSymptomsByDayInstanceId(
    dayInstanceId: string,
    comment: any,
    verifiedDayInstanceId: string
  ) {
    const otherSymptoms = this.symptomService.tableOtherSymptoms.filter(
      (OSI: any) => {
        return (
          OSI?.otherSymptom?.dayInstanceId === dayInstanceId ||
          OSI?.verifiedOtherSymptom?.verifiedDayInstanceId ===
            verifiedDayInstanceId
        );
      }
    );

    let data: any = this.symptomService.getDeleteOtherSymptom(
      comment,
      verifiedDayInstanceId
    );

    for (let OSI of otherSymptoms) {
      let verifiedOtherSymptom: any = null;
      if (OSI.verifiedOtherSymptom?.state !== InstanceState.DELETED) {
        if (OSI.verifiedOtherSymptom?.id) {
          const { verified } =
            await this.symptomService.deleteVerifiedOtherSymptomInstanceTable(
              OSI.otherSymptom,
              OSI.verifiedOtherSymptom,
              data
            );
          verifiedOtherSymptom = verified;
        } else {
          verifiedOtherSymptom =
            await this.symptomService.createVerifiedSymptom(
              OSI.otherSymptom,
              data
            );
        }
        this.updateOtherSymptomInstance(OSI, verifiedOtherSymptom);
      }
    }
  }

  async deleteMedicationByDayInstanceId(dayInstanceId: string, comment: any) {
    const medicationInstances = this.medicationService.tableMedications.filter(
      (MI: any) => {
        return MI.medication.dayInstanceId === dayInstanceId;
      }
    );

    let data: any = this.medicationService.getMedicationDeleteData(comment);
    for (let MI of medicationInstances) {
      let verifiedMedication: any = null;
      if (MI.verifiedMedication?.id) {
        let { verified } =
          await this.medicationService.deleteVerifiedMedicationInstanceTable(
            MI.medication,
            MI.verifiedMedication,
            data
          );
        verifiedMedication = verified;
      } else {
        verifiedMedication =
          await this.medicationService.createVerifiedMedication(
            data,
            MI.medication
          );
      }
      this.updateMedicationInstance(MI, verifiedMedication);
    }
  }

  async deleteMedicalAttentionByDayInstance(
    dayInstanceId: string,
    comment: any,
    verifiedDayInstanceId: any
  ) {
    const medicalAttentionInstances =
      this.medicalAttentionService.medicalAttentionTable.filter((MI: any) => {
        return (
          MI.medicalAttention.dayInstanceId === dayInstanceId ||
          MI?.verifiedMedicalAttention?.verifiedDayInstanceId ===
            verifiedDayInstanceId
        );
      });

    for (let medicalAttention of medicalAttentionInstances) {
      if (
        medicalAttention.verifiedMedicalAttention?.state !==
        InstanceState.DELETED
      ) {
        let verifiedMedicalAttention: any = null;
        if (medicalAttention.verifiedMedicalAttention?.id) {
          const { verified } =
            await this.medicalAttentionService.updateOrDeleteMedicalAttentionTable(
              medicalAttention.verifiedMedicalAttention,
              medicalAttention.medicalAttention,
              comment
            );
          verifiedMedicalAttention = verified;
        } else {
          let data: any = {
            subjectId: medicalAttention.medicalAttention?.subjectId,
            state: InstanceState.DELETED,
            typeComment: comment,
            verifiedDayInstanceId: verifiedDayInstanceId,
            medicalAttentionInstanceId:
              medicalAttention.medicalAttentionInstanceId,
            type: medicalAttention.medicalAttention?.type,
            reason: medicalAttention.medicalAttention?.reason,
            symptomsInstances:
              medicalAttention.medicalAttention?.symptomsInstances,
            rememberStartDate:
              medicalAttention.medicalAttention?.rememberStartDate,
            startDate: medicalAttention.medicalAttention?.startDate,
            rememberFinishDate:
              medicalAttention.medicalAttention?.rememberFinishDate,
            finishDate: medicalAttention.medicalAttention?.finishDate,
            wasHospitalized: medicalAttention.medicalAttention?.wasHospitalized,
          };
          verifiedMedicalAttention =
            await this.medicalAttentionService.createVerifiedMedicalAttentionInstance(
              data
            );
        }
        this.updateMedicalAttencionInstance(
          medicalAttention,
          verifiedMedicalAttention
        );
      }
    }
  }

  updateOtherSymptomInstance(
    otherSymptomTable: any,
    verifiedOtherSymptom: any
  ) {
    otherSymptomTable.verifiedOtherSymptom = verifiedOtherSymptom;
    otherSymptomTable.isEdition = true;
    otherSymptomTable.verifiedSymptomName =
      verifiedOtherSymptom?.symptom === 'OTHER'
        ? verifiedOtherSymptom?.whichOtherSymptom || ''
        : verifiedOtherSymptom?.symptom || '';
    this.symptomService.tableOtherSymptoms =
      this.symptomService.tableOtherSymptoms.map((OSI: any) => {
        if (OSI.otherSymptom.id === this.conciliationService.NEW_INSTANCE_ID) {
          return OSI.verifiedOtherSymptom.id ===
            otherSymptomTable.verifiedOtherSymptom.id
            ? otherSymptomTable
            : OSI;
        } else {
          return OSI.otherSymptom.id === otherSymptomTable.otherSymptom.id
            ? otherSymptomTable
            : OSI;
        }
      });
  }

  updateMedicationInstance(MI: any, verifiedMedication: any) {
    MI.isEdition = true;
    MI.verifiedMedication = verifiedMedication;

    MI.symptomsVerified = this.symptomService.getSymptoms(
      verifiedMedication?.symptomsInstances,
      MI.medication
    );

    this.medicationService.tableMedications =
      this.medicationService.tableMedications.map((MITable: any) => {
        if (MI.medication.id === this.conciliationService.NEW_INSTANCE_ID) {
          return MI?.verifiedMedication?.id === MITable?.verifiedMedication?.id
            ? MI
            : MITable;
        } else {
          return MITable?.medication?.id === MI.medication?.id ? MI : MITable;
        }
      });
  }

  updateMedicalAttencionInstance(
    MI: any,
    verifiedMedicalAttention: VerifiedMedicalAttentionInstance
  ) {
    MI.isEdition = true;
    MI.verifiedMedicalAttention = verifiedMedicalAttention;

    MI.symptomsVerified = this.symptomService.getSymptoms(
      verifiedMedicalAttention?.symptomsInstances,
      MI.medicalAttention
    );

    this.medicalAttentionService.medicalAttentionTable =
      this.medicalAttentionService.medicalAttentionTable.map((MITable: any) => {
        if (MITable.medicalAttention?.id?.length === 0) {
          return MITable.verifiedMedicalAttention?.verifiedDayInstanceId ===
            MI?.medicalAttention?.dayInstanceId
            ? MI
            : MITable;
        } else {
          return MITable.medicalAttention.id === MI.medicalAttention.id
            ? MI
            : MITable;
        }
      });
  }

  updateComments() {
    let index = 0;
    const dayInstances = this.currentDayInstance?.colDays || [];
    for (let colDay of dayInstances) {
      if (colDay.dayInstance !== '') {
        const verified = colDay?.verifiedDayInstance;
        if (verified?.hasMedicationComment) index++;
        colDay.hasMedicationRow.commentIndex = verified?.hasMedicationComment
          ? index
          : null;
        colDay.hasMedicationRow.comment = verified?.hasMedicationComment;
        if (verified?.medicationGivenToComment) index++;
        colDay.medicationGivenToRow.commentIndex =
          verified?.medicationGivenToComment ? index : null;
        colDay.medicationGivenToRow.comment =
          verified?.medicationGivenToComment;
      }
    }
  }

  updateCustomComments() {
    let index = 0;
    for (let colDay of this.currentDayInstance.colDays) {
      if (colDay.dayInstance !== '') {
        const verified = colDay?.verifiedDayInstance;
        if (verified) {
          if (verified[this.currentDayInstance?.hasInstance + 'Comment'])
            index++;
          colDay[this.currentDayInstance?.hasInstance + 'Row'].commentIndex =
            verified[this.currentDayInstance?.hasInstance + 'Comment']
              ? index
              : null;
          colDay[this.currentDayInstance?.hasInstance + 'Row'].comment =
            verified[this.currentDayInstance?.hasInstance + 'Comment'];
        }
      }
    }
  }

  get isGivenToEnabled() {
    let isGivenToEnabled =
      this.configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_OPEN_REASON ||
      this.configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_SYMPTOM_BINDING;
    if (this.currentDayInstance?.hasInstance !== 'hasMedication') {
      isGivenToEnabled = false;
    }
    if (this.formHasInstance()?.value === false) isGivenToEnabled = false;
    if (
      this.currentDayInstance?.hasInstance === 'hasMedication' &&
      !(
        this.currentDayInstance?.dayInstance?.medicationGivenTo ||
        this.currentDayInstance?.verifiedDayInstance?.medicationGivenTo
      ) &&
      this.currentDayInstance?.confForm?.medicationReconciliationConfig
        ?.allowTreatmentReconciliation === false
    )
      isGivenToEnabled = false;

    this.conciliationService.setValidator(
      this.formMedicationGivenTo,
      isGivenToEnabled
    );
    return isGivenToEnabled;
  }

  enabledGivenTo() {
    if (this.isShowPrecondition) {
      if (!this.formHasInstance()?.value) {
        this.conciliationService.setValidator(
          this.formMedicationGivenTo,
          false
        );
        this.formMedicationGivenTo?.setValue(null);
        return true;
      }
    }
    if (
      this.currentDayInstance?.confForm?.medicationReconciliationConfig
        ?.allowTreatmentReconciliation === false
    )
      return true;

    return false;
  }

  get isShowPrecondition() {
    const isShowPrecondition = this.configuration?.showPrecondition;
    this.conciliationService.setValidator(
      this.formHasInstance(),
      isShowPrecondition
    );
    return isShowPrecondition;
  }

  formHasInstance() {
    return this.form.get('hasInstance');
  }

  formHasInstanceComment() {
    return this.form.get('hasInstanceComment');
  }

  get formMedicationGivenTo() {
    return this.form.get('medicationGivenTo');
  }

  get formMedicationGivenToComment() {
    return this.form.get('medicationGivenToComment');
  }

  changeReasonHasInstance() {
    const verifiedHasMedicationComment =
      this.currentDayInstance?.verifiedDayInstance?.hasMedicationComment;
    const verifiedOtherSymptomsComment =
      this.currentDayInstance?.verifiedDayInstance?.hasOtherSymptomsComment;
    const verifiedMedicalAttentionComment =
      this.currentDayInstance?.verifiedDayInstance?.hasMedicalAttentionComment;
    let instanceComment = this.currentDayInstance?.hasInstanceComment || '';
    let ischangeHasInstance =
      this.formChange?.hasInstance !== this.formHasInstance()?.value;

    if (
      (instanceComment === 'hasMedicationComment' &&
        verifiedHasMedicationComment) ||
      (instanceComment === 'hasOtherSymptomsComment' &&
        verifiedOtherSymptomsComment) ||
      (instanceComment === 'hasMedicalAttentionComment' &&
        verifiedMedicalAttentionComment)
    ) {
      ischangeHasInstance = true;
    }

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.formHasInstanceComment(),
      ischangeHasInstance
    );
    return ischangeHasInstance;
  }

  get changeReasonMedicationGivenTo() {
    const isChangeMedicationGivenTo =
      JSON.stringify(this.formChange?.medicationGivenTo?.sort()) !==
        JSON.stringify(this.formMedicationGivenTo?.value?.sort()) ||
      this.currentDayInstance.verifiedDayInstance?.medicationGivenToComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.formMedicationGivenToComment,
      isChangeMedicationGivenTo
    );
    return isChangeMedicationGivenTo;
  }

  previousDay() {
    let index = this.getDayInstanceIndex();
    if (index === 1) {
      return this.paginationService.goToPreviousSection();
    }

    return this.paginationService.dayInstanceSelected(
      this.currentDayInstance.colDays[index - 1],
      this.getConciliationComponent()
    );
  }

  nextDayInstance() {
    let index = this.getDayInstanceIndex();
    let tableInstances = this.getTableInstances();
    if (index + 1 === this.currentDayInstance.colDays?.length) {
      if (tableInstances.length > 0) {
        return this.seletedInstances(tableInstances[0]);
      }
      return this.paginationService.goToNextSection();
    }

    logger.debug(
      'this.currentDayInstance.colDays',
      this.currentDayInstance.colDays
    );
    return this.paginationService.dayInstanceSelected(
      this.currentDayInstance.colDays[index + 1],
      this.getConciliationComponent()
    );
  }

  getConciliationComponent(): ConciliationComponents {
    if (this.currentDayInstance?.hasInstance === 'hasOtherSymptoms') {
      return ConciliationComponents.OTHER_SYMPTOM;
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedication') {
      return ConciliationComponents.MEDICATION;
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedicalAttention') {
      return ConciliationComponents.MEDICAl_ATTETION;
    }
    return ConciliationComponents.OTHER_SYMPTOM;
  }

  getTableInstances(): any[] {
    if (this.currentDayInstance?.hasInstance === 'hasOtherSymptoms') {
      return this.symptomService.tableOtherSymptoms;
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedication') {
      return this.medicationService.tableMedications;
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedicalAttention') {
      return this.medicalAttentionService.medicalAttentionTable;
    }
    return [];
  }

  seletedInstances(instanceSelected: any) {
    if (this.currentDayInstance?.hasInstance === 'hasOtherSymptoms') {
      return this.paginationService.selectedOtherSymptom(instanceSelected);
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedication') {
      return this.paginationService.selectedMedication(instanceSelected);
    }
    if (this.currentDayInstance?.hasInstance === 'hasMedicalAttention') {
      this.dayInstanceService.currentDayInstance = {};
      const data = {
        data: instanceSelected,
      };
      this.medicalAttentionService.selectedMedicalAttention = instanceSelected;
      return this.paginationService.selectedMedicalAttention(data);
    }
  }

  getDayInstanceIndex() {
    return this.currentDayInstance.colDays?.findIndex((dayInstance: any) => {
      return (
        dayInstance.dayInstance?.id === this.currentDayInstance?.dayInstance?.id
      );
    });
  }

  shouldDisablePrecondition() {
    const confForm = this.currentDayInstance?.confForm;
    const { hasInstance, dayInstance, verifiedDayInstance } =
      this.currentDayInstance || {};

    const checkReconciliationConfig = (instanceFlag: any, configKey: any) => {
      const allowRemoveRecords = confForm?.[configKey]?.allowRemoveRecords;
      const allowAddRecords = confForm?.[configKey]?.allowAddRecords;

      if (dayInstance?.[instanceFlag] || verifiedDayInstance?.[instanceFlag]) {
        return allowRemoveRecords === false;
      }
      if (
        dayInstance?.[instanceFlag] === false ||
        verifiedDayInstance?.[instanceFlag] === false
      ) {
        return allowAddRecords === false;
      }
      return false;
    };

    if (hasInstance === 'hasMedicalAttention') {
      return checkReconciliationConfig(
        'hasMedicalAttention',
        'medicalAttentionReconciliationConfig'
      );
    }
    if (hasInstance === 'hasOtherSymptoms') {
      return checkReconciliationConfig(
        'hasOtherSymptoms',
        'otherSymptomsReconciliationConfig'
      );
    }
    if (hasInstance === 'hasMedication') {
      return checkReconciliationConfig(
        'hasMedication',
        'medicationReconciliationConfig'
      );
    }

    return false;
  }
}
