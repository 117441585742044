<div class="p-flex p-flex-column" style="gap: 0.5rem">
  <p-accordion [activeIndex]="0">
    <p-accordionTab
      [header]="step.templateOptions?.label ?? '' | dictionary"
      *ngFor="let step of field.fieldGroup; let index = index; let last = last"
    >
      <formly-field [field]="step"></formly-field>
    </p-accordionTab>
  </p-accordion>
</div>
