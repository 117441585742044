import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import {
  MedicationGivenTo,
  MedicationInstance,
  Symptom,
} from 'src/app/modules/ediary/ediary.types';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { CardOrTableService } from '../../../../services/card-or-table.service';
import { TP2Entites } from '../../../../shared/global.variables';
import { SubjectsService } from '../../subjects.service';

const logger = new Logger('tp2-logger-subjectMedicationPage');

@Component({
  selector: 'app-medication-subject',
  templateUrl: './medication-subject.component.html',
  styleUrls: ['./medication-subject.component.scss'],
})
export class MedicationSubjectComponent implements OnInit, OnChanges {
  @Input() timeZoneOffset = 0;
  @Input() isOpened: boolean = false;
  @Output() hasMedications: EventEmitter<boolean> = new EventEmitter();
  medications: any[] = [];
  tableViewMode: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  dataCard: any[] = [];
  showSpinner: boolean = true;
  DETAIL_SUBJECT_MEDICATION_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_MEDICATION_TOUR;

  constructor(
    private subjectsService: SubjectsService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private transformDatePipe: TransformDatePipe,
    private readonly authService: AuthService,
    private readonly trialpalService: TrialpalService,
    private pageTourService: PageTourService,
    public cardOrTableService: CardOrTableService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.showSpinner = true;
      const subjectid = this.activatedRoute.snapshot.params.subjectId;
      await this.getMedicationsBySubjectId(subjectid);
      if (this.medications && this.medications.length > 0) {
        await this.buildRazonForMedications();
        await this.buildStartAndFinishDate();
        this.buildCardsAndTable();
      }

      this.showSpinner = false;
      this.startTour();
    } catch (exception) {
      this.buildCardsAndTable();
      this.showSpinner = false;
      this.startTour();
    }
  }

  ngOnChanges() {
    this.startTour();
  }
  buildCardsAndTable() {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };
    if (this.medications && this.medications.length > 0) {
      this.dataTable = this.buildTPTableData(this.medications);
      this.buildCards(this.medications);
    }
  }
  buildCards(medications: any) {
    this.dataCard = [];
    for (let medication of medications) {
      this.dataCard.push(this.buildTPCardData(medication));
    }
  }

  async getMedicationsBySubjectId(subjectid: string) {
    const response = await this.subjectsService.medicationInstanceBySubjectId(
      subjectid
    );
    logger.debug('getMedicationSubject response', response);
    if (response.items) {
      this.medications = response.items.filter((x: any) => !x._deleted);
      this.medications = this.subjectsService.orderInstancesDescending(
        this.medications
      );
    }
    this.hasMedications.emit(this.medications.length > 0);
  }

  async buildStartAndFinishDate() {
    let forms: any[] = [];
    for (const medication of this.medications) {
      if (medication?.dayInstance?.confDay?.confFormId) {
        const confFormId = medication.dayInstance.confDay.confFormId;
        let findForm: any = forms?.find(
          (x: any) => x?.confFormId === confFormId
        );
        if (!findForm) {
          findForm = await this.subjectsService.confMedicationByConfForm(
            confFormId
          );
          findForm = findForm?.items[0];
          forms = forms.concat(findForm);
        }
        medication.showRememberStartDate = findForm
          ? findForm.showRememberStartDate
          : true;
        medication.showRememberFinishDate = findForm
          ? findForm.showRememberFinishDate
          : true;
      }
    }
  }
  async buildRazonForMedications() {
    for (const medication of this.medications) {
      const reason = await this.buildReasonForMedication(medication);
      if (reason) {
        medication.reason = reason.slice(0, -2);
      }
    }
  }

  async buildReasonForMedication(medication: any) {
    let reason = '';
    if (medication.symptomsInstances) {
      for (const id in medication.symptomsInstances || []) {
        const { instance, symptom } = await this.getSymptomInstance(
          medication,
          id
        );
        if (instance?.symptom || symptom) {
          medication.symptomsInstances[id] =
            this.trialpalService.translateService.instant(
              'symptom.enums.symptoms.' + (instance?.symptom || symptom)
            );
          reason += medication.symptomsInstances[id] + ', ';
        }
      }
    }
    return reason;
  }

  async getSymptomInstance(medication: any, id: string) {
    let instance;
    let symptom = '';
    if (medication.symptomsInstances[id] === Symptom.FEVER) {
      symptom = Symptom.FEVER;
    }
    if (!symptom) {
      instance = await this.subjectsService.getConfSymptom(
        medication.symptomsInstances[id]
      );
    }
    if (!instance && !symptom) {
      instance = await this.subjectsService.symptomInstanceById(
        medication.symptomsInstances[id]
      );
    }
    return { instance, symptom };
  }

  buildTPCardData(medication: any): TPCardData {
    let startDate: any = '';
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    if (medication.rememberStartDate && medication.startDate) {
      startDate = this.transformDatePipe.transform(medication.startDate);
    }
    let finishDate: any = '';
    if (medication.rememberFinishDate && medication.finishDate) {
      finishDate = this.transformDatePipe.transform(medication.finishDate);
    } else if (
      !medication.rememberFinishDate &&
      medication.showRememberFinishDate
    ) {
      finishDate = this.trialpalService.translateService.instant(
        'general.instanceState.ON_GOING'
      );
    }
    let reason =
      medication.reason ||
      this.trialpalService.translateService.instant('subject.withoutReason');

    if (!medication.rememberFinishDate && !medication.rememberStartDate) {
      startDate = false;
      finishDate = false;
    }

    return {
      ...medication,
      hideState: true,
      data: medication,
      header: medication.name,
      section1: startDate || false,
      icon1: startDate ? 'pi pi-calendar' : '',
      section1ToolTip: this.translateService.instant(
        'medicationinstance.startDate'
      ),
      section2: finishDate || false,
      icon2: finishDate ? 'pi pi-calendar-times' : '',
      section3: reason,
      icon3: 'pi pi-info-circle',
      section3ToolTip: this.translateService.instant(
        'medicationinstance.endDate'
      ),
      styleColorState: '',
      entity,
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      showAuditButton,
      cardIsButton: false,
      objectAction: medication.name,
      entityAction: this.trialpalService.translateService.instant(
        'medicationinstance.entity'
      ),
    };
  }

  getEntityAndAuditButton(): any[] {
    let entity = '';
    let showAuditButton = false;
    if (this.authService.isAdmin() || this.authService.isInvestigator()) {
      entity = TP2Entites.MEDICATIONINSTANCE;
      showAuditButton = true;
    }

    return [entity, showAuditButton];
  }

  buildTPTableData(medicationsInstances: any): TPTableData {
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    let medicationInstances = medicationsInstances.map(
      (medication: MedicationInstance) => {
        let [medicationStartDate, medicationFinishDate] =
          this.getMedicationsStartAndFinishDates(medication);
        return {
          entity,
          data: medication,
          id: medication?.id,
          name: medication?.name,
          administrationRoute: medication?.administrationRoute || 'N/A',
          dose: medication?.dose || 'N/A',
          frequency: medication?.frequency || 'N/A',
          reason: medication?.reason || 'N/A',
          givenTo:
            medication?.givenTo && medication?.givenTo?.length > 0
              ? this.getGivenToMessage(medication?.givenTo)
              : 'N/A',
          state: medication?.state
            ? this.translateService.instant(
                'general.instanceState.' + medication?.state
              )
            : '',
          startDate: medicationStartDate,
          finishDate: medicationFinishDate,
        };
      }
    );
    logger.debug('medicationInstances', medicationInstances);
    return this.getContentTable(medicationInstances, showAuditButton);
  }

  getContentTable(medicationInstances: any, showAuditButton: any) {
    return {
      valueTable: medicationInstances,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: [
        'name',
        'administrationRoute',
        'frequency',
        'dose',
        'reason',
        'state',
      ],
      customButton1: showAuditButton
        ? {
            icon: 'pi pi-file',
          }
        : null,
      cols: [
        {
          header: this.translateService.instant(
            'medicationinstance.medicineName'
          ),
          type: TypeInput.TEXT,
          field: 'name',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant(
            'medicationinstance.administracionRoute'
          ),
          type: TypeInput.TEXT,
          field: 'administrationRoute',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('medicationinstance.dose'),
          type: TypeInput.TEXT,
          field: 'dose',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('medicationinstance.frequency'),
          type: TypeInput.TEXT,
          field: 'frequency',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('medicationinstance.reason'),
          type: TypeInput.TEXT,
          field: 'reason',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('medicationinstance.givenTo'),
          type: TypeInput.TEXT,
          field: 'givenTo',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('subject.labelState'),
          type: TypeInput.TEXT,
          field: 'state',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('medicationinstance.startDate'),
          type: TypeInput.TEXT,
          field: 'startDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.translateService.instant('medicationinstance.endDate'),
          type: TypeInput.TEXT,
          field: 'finishDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
      ],
    };
  }

  getMedicationsStartAndFinishDates(medication: any): any[] {
    let medicationStartDate = 'N/A';
    let medicationFinishDate = 'N/A';
    if (medication.rememberStartDate && medication.startDate) {
      medicationStartDate = this.transformDatePipe.transform(
        medication.startDate
      );
    }
    if (medication.rememberFinishDate && medication.finishDate) {
      medicationFinishDate = this.transformDatePipe.transform(
        medication.finishDate
      );
    }

    return [medicationStartDate, medicationFinishDate];
  }

  getGivenToMessage(givenTo: any[]) {
    const arrayGivenTo: string[] = [];
    let given: string;

    givenTo.forEach((item: MedicationGivenTo) => {
      if (item === MedicationGivenTo.TREAT) {
        given = this.trialpalService.translateService.instant(
          'medicationinstance.enums.TREAT'
        );
      }
      if (item === MedicationGivenTo.PREVENT) {
        given = this.trialpalService.translateService.instant(
          'medicationinstance.enums.PREVENT'
        );
      }

      arrayGivenTo.push(given);
    });
    return arrayGivenTo;
  }

  async startTour() {
    if (!this.isOpened || this.showSpinner) return;
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
    this.pageTourService.initiateTour(
      PageTourDataType.DETAIL_SUBJECT_MEDICATION_TOUR
    );
    this.translateService.instant('subject.tabheaderReportDetailTitle');
  }
}
