<div class="p-grid">
  <div class="p-col-12 p-text-right p-pb-3">
    <span class="p-buttonset">
      <label>
        <button
          pButton
          pRipple
          label="
            {{ 'general.cardMode' | translate }}
          "
          icon="pi pi-microsoft"
          (click)="selectCardOrTableMode()"
          [disabled]="!tableViewMode"
        ></button>
        <button
          pButton
          pRipple
          label="
            {{ 'general.tableMode' | translate }}
          "
          icon="pi pi-table"
          (click)="selectCardOrTableMode()"
          [disabled]="tableViewMode"
        ></button>
      </label>
    </span>
  </div>
</div>
