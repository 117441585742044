<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
  <p-fieldset
    legend="{{
      (isEdition
        ? readMode
          ? 'symptom.viewSymptom'
          : 'symptom.titleEdit'
        : 'symptom.titleAdd'
      ) | translate
    }}"
  >
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-lg-2 p-col-offset-0 p-lg-offset-10">
        <button
          *ngIf="false"
          pButton
          label="{{ 'general.btnAuditTrace' | translate }}"
          type="button"
          icon="pi pi-file"
          iconPos="right"
          (click)="openAuditModal()"
          badgeClass="color: white;"
          class="p-ml-2 p-button-success pbutton"
          [disabled]="readMode"
        ></button>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="symptomLabel"
          >{{ 'symptom.labelSymptomLabel' | translate
          }}<span class="inputRequired"> * </span></label
        >

        <app-simple-dictionary-input
          id="symptomLabel"
          name="symptomLabel"
          [projectId]="projectId"
          [isReadOnly]="readMode"
          [isRequired]="true"
          [submitted]="f.submitted"
          [tooltip]="'symptom.tooltipSymptomLabel' | translate"
          [value]="confSymptom.symptomLabel"
          (outputEvent)="confSymptom.symptomLabel = $event"
        ></app-simple-dictionary-input>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label for="symptom">{{ 'symptom.labelSymptom' | translate }}</label>
        <p-dropdown
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [filter]="true"
          filterBy="name"
          [options]="symptoms"
          optionLabel="name"
          optionValue="value"
          name="symptom"
          #symptom="ngModel"
          [(ngModel)]="confSymptom.symptom"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-dropdown>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label for="order"
          >{{ 'symptom.labelOrder' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <p-inputNumber
          #order_input="ngModel"
          name="order"
          [(ngModel)]="confSymptom.order"
          [showButtons]="true"
          inputId="order"
          [min]="1"
          [max]="100"
          [disabled]="readMode"
        ></p-inputNumber>
        <small
          class="form-error"
          *ngIf="!confSymptom.order && (f.submitted || order_input.touched)"
        >
          {{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label for="icon">{{ 'symptom.labelIcon' | translate }}</label>
        <p-dropdown
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [options]="icons"
          optionLabel="name"
          optionValue="value"
          [showClear]="true"
          name="icon"
          #icon="ngModel"
          [(ngModel)]="confSymptom.symptomIcon"
          [disabled]="readMode"
        >
          <ng-template pTemplate="selectedItem">
            <div>
              <img
                *ngIf="confSymptom.symptomIcon"
                style="vertical-align: middle"
                src="{{
                  '/../assets/icons/' + confSymptom.symptomIcon + '.png'
                }}"
                width="40px"
                height="40px"
                alt=""
              />
              <span>{{ confSymptom.symptomIcon ? '' : '---' }}</span>
            </div>
          </ng-template>
          <ng-template let-symptom pTemplate="item">
            <div>
              <img
                style="vertical-align: middle"
                src="{{ '/../assets/icons/' + symptom.value + '.png' }}"
                width="40px"
                height="40px"
                alt=""
              />
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-4 p-field">
        <label for="type">{{ 'symptom.labelType' | translate }}</label>
        <p-selectButton
          class="full-width"
          name="type"
          #type="ngModel"
          [options]="symptomTypes"
          [(ngModel)]="confSymptom.type"
          optionLabel="name"
          optionValue="value"
          (onChange)="onChangeSymptomType()"
          [disabled]="readMode"
        ></p-selectButton>
        <div class="p-col"></div>
      </div>
      <div class="p-col-2"></div>
      <div class="p-col-12 p-md-3 p-field" *ngIf="confSymptom.type === 'LOCAL'">
        <label for="injectionSite">{{
          'symptom.labelInjectionSite' | translate
        }}</label
        ><span class="inputRequired"> *</span>
        <p-dropdown
          [required]="true"
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [filter]="true"
          filterBy="name"
          [options]="injectionSites"
          optionLabel="name"
          optionValue="value"
          name="injectionSite"
          #injectionSite="ngModel"
          [(ngModel)]="confSymptom.injectionSite"
          (change)="onChangeInjectionSite()"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="!injectionSite.valid && (f.submitted || injectionSite.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-col-12 p-md-3 p-field" *ngIf="confSymptom.type === 'LOCAL'">
        <label for="injectionSide">{{
          'symptom.labelInjectionSide' | translate
        }}</label
        ><span class="inputRequired"> *</span>
        <p-dropdown
          [required]="true"
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [filter]="true"
          filterBy="name"
          [options]="injectionSides"
          optionLabel="name"
          optionValue="value"
          name="injectionSide"
          #injectionSide="ngModel"
          [(ngModel)]="confSymptom.injectionSide"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="!injectionSide.valid && (f.submitted || injectionSide.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-col-12 p-field">
        <label class="full-width" for="intensity">{{
          'symptom.labelIntensity' | translate
        }}</label>
        <p-inputSwitch
          name="intensity"
          #intensity="ngModel"
          [(ngModel)]="confSymptom.isIntensityRequired"
          (onChange)="resetIntensityType()"
          [disabled]="readMode"
        ></p-inputSwitch>
      </div>

      <div
        class="p-col-12 grid-responsive"
        *ngIf="confSymptom.isIntensityRequired === true"
      >
        <!--Intensity guide-->
        <div>
          <label for="intensityType" class="p-mb-2 p-d-block"
            >{{ 'symptom.labelIntensityType' | translate }}
            <span class="inputRequired"> *</span></label
          >
          <p-dropdown
            appendTo="body"
            [required]="true"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [options]="intensityTypes"
            optionLabel="name"
            optionValue="value"
            name="intensityType"
            #intensityType="ngModel"
            [(ngModel)]="confSymptom.intensityType"
            tooltipPosition="top"
            pTooltip="{{ 'symptom.tooltipIntensityType' | translate }}"
            (onChange)="changeDecimal()"
            [disabled]="readMode"
          ></p-dropdown>
          <small
            class="form-error"
            *ngIf="
              !intensityType.valid && (f.submitted || intensityType.touched)
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>

        <!--Decimal places-->
        <div
          *ngIf="
            confSymptom.intensityType === 'RANGE' ||
            confSymptom.intensityType === 'NUMERIC_VALUE'
          "
        >
          <label for="intensityType" class="p-mb-2 p-d-block"
            >{{ 'symptom.labelDecimal' | translate }}
            <span class="inputRequired"> *</span></label
          >
          <p-inputNumber
            name="decimalPlaces"
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="0"
            [(ngModel)]="decimalPlaces"
            [min]="0"
            [max]="2"
            [required]="true"
            #cant="ngModel"
            [disabled]="readMode"
          ></p-inputNumber>
          <small
            class="form-error p-mt-2"
            *ngIf="!cant.valid && (f.submitted || cant.touched)"
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <!--numero minimo-->
        <div *ngIf="confSymptom.intensityType === 'NUMERIC_VALUE'">
          <label for="minSize" class="p-mb-2 p-d-block">
            {{ 'symptom.labelNumericMinValueIntensity' | translate }}
            <span class="inputRequired"> *</span></label
          >

          <p-inputNumber
            name="minSize"
            mode="decimal"
            [(ngModel)]="confSymptom.minSize"
            [required]="true"
            #minSize="ngModel"
            [disabled]="readMode"
          ></p-inputNumber>
          <small
            class="form-error p-mt-2"
            *ngIf="!minSize.valid && (f.submitted || minSize.touched)"
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <!--numero maximo-->
        <div *ngIf="confSymptom.intensityType === 'NUMERIC_VALUE'">
          <label for="maxSize" class="p-mb-2 p-d-block">
            {{ 'symptom.labelNumericMaxValueIntensity' | translate }}
            <span class="inputRequired"> *</span></label
          >

          <p-inputNumber
            name="maxSize"
            mode="decimal"
            [(ngModel)]="confSymptom.maxSize"
            [required]="true"
            #maxSize="ngModel"
            [disabled]="readMode"
          ></p-inputNumber>
          <small
            class="form-error p-mt-2"
            *ngIf="!maxSize.value && (f.submitted || maxSize.touched)"
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <!--Label to show-->
        <div
          *ngIf="
            confSymptom.intensityType === 'RANGE' ||
            confSymptom.intensityType === 'NUMERIC_VALUE' ||
            confSymptom.intensityType === 'MULTIPLE_CHOICE'
          "
        >
          <label for="intensityType" class="p-mb-2 p-d-block"
            >{{ 'general.configurations.labelShow' | translate }}
            <span class="inputRequired"> *</span></label
          >

          <app-simple-dictionary-input
            id="intensitySizeLabel"
            name="intensitySizeLabel"
            [projectId]="projectId"
            [isReadOnly]="readMode"
            [isRequired]="true"
            [submitted]="f.submitted"
            [value]="confSymptom.intensitySizeLabel"
            (outputEvent)="confSymptom.intensitySizeLabel = $event"
          ></app-simple-dictionary-input>
        </div>

        <!-- Component type to collect data -->
        <div
          *ngIf="
            confSymptom.intensityType === 'NUMERIC_VALUE' ||
            confSymptom.intensityType === 'RANGE'
          "
        >
          <label for="intensityType" class="p-mb-2 p-d-block">
            {{ 'symptom.labelComponentType' | translate }}
            <span class="inputRequired"> *</span></label
          >
          <p-dropdown
            id="dataInputComponent"
            name="dataInputComponent"
            appendTo="body"
            [required]="true"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [options]="dataInputComponentOptions"
            optionLabel="name"
            optionValue="value"
            #dataInputComponent="ngModel"
            tooltipPosition="bottom"
            tooltipStyleClass="type-of-component"
            pTooltip="{{ 'symptom.tooltipComponentType' | translate }}"
            [(ngModel)]="confSymptom.dataInputComponent"
            [disabled]="readMode"
          ></p-dropdown>
        </div>
      </div>

      <!--No values taken-->
      <div
        class="p-col-12 p-px-0 p-field p-grid"
        *ngIf="
          confSymptom.intensityType === 'RANGE' ||
          confSymptom.intensityType === 'NUMERIC_VALUE'
        "
      >
        <div class="p-col p-md-3">
          <label class="full-width p-d-block p-mb-2" for="showNoValuesTaken">{{
            'symptom.showNoValuesTaken' | translate
          }}</label>
          <p-inputSwitch
            tooltipPosition="top"
            [escape]="false"
            pTooltip="{{ 'symptom.tooltipShowNoValuesTaken' | translate }}"
            name="showNoValuesTaken"
            #showHelp="ngModel"
            [(ngModel)]="confSymptom.showNoValuesTaken"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
        <div class="p-col p-md-3" *ngIf="confSymptom.showNoValuesTaken">
          <label for="intensityType" class="p-d-block p-mb-2"
            >{{ 'general.configurations.labelShow' | translate }}
            <span class="inputRequired"> *</span></label
          >
          <app-simple-dictionary-input
            id="noValuesTakenLabel"
            name="noValuesTakenLabel"
            [projectId]="projectId"
            [isReadOnly]="readMode"
            [isRequired]="true"
            [submitted]="f.submitted"
            [value]="confSymptom.noValuesTakenLabel"
            (outputEvent)="confSymptom.noValuesTakenLabel = $event"
          ></app-simple-dictionary-input>
        </div>
      </div>
      <div
        class="p-col-12 p-field"
        *ngIf="
          (confSymptom.intensityType === 'RANGE' ||
            confSymptom.intensityType === 'MULTIPLE_CHOICE') &&
          confSymptom.isIntensityRequired === true
        "
      >
        <label>{{ 'symptom.labelIntensityList' | translate }}</label>
        <p-card>
          <div class="p-col-12 p-grid">
            <div class="p-col-2">
              <label for="">{{
                'symptom.labelValueIntensity' | translate
              }}</label>
            </div>
            <div class="p-col-2">
              <label for=""
                >{{ 'symptom.labelActiveIntensity' | translate
                }}<span class="inputRequired"> * </span></label
              >
            </div>
            <div class="p-col-2">
              <label for=""
                >{{
                  (confSymptom.intensityType === 'RANGE'
                    ? 'symptom.labelNumericMinValueIntensity'
                    : 'symptom.labelNumericValueIntensity'
                  ) | translate
                }}<span class="inputRequired"> *</span></label
              >
            </div>
            <div class="p-col-2" *ngIf="confSymptom.intensityType === 'RANGE'">
              <label for=""
                >{{ 'symptom.labelNumericMaxValueIntensity' | translate
                }}<span class="inputRequired"> *</span></label
              >
            </div>
            <div class="p-col-4">
              <label for=""
                >{{ 'symptom.labelHelpTextIntensity' | translate
                }}<span class="inputRequired"> * </span></label
              >
            </div>
          </div>
          <div class="p-col-12 p-grid p-mt-1">
            <div class="p-col-2">
              <label>{{ 'symptom.labelNoneIntensity' | translate }} </label>
            </div>
            <div class="p-col-2">
              <p-inputSwitch
                name="intensityNone"
                [(ngModel)]="confSymptom.noneIntensityScaleOptions!.active"
                (onChange)="onChangeIntensityConfiguration()"
                [disabled]="readMode"
              ></p-inputSwitch>
            </div>
            <div
              class="p-col-2"
              *ngIf="
                (confSymptom.intensityType === 'RANGE' ||
                  confSymptom.intensityType === 'MULTIPLE_CHOICE') &&
                confSymptom.noneIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="noneR1"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.noneIntensityScaleOptions!.startValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #noneR1="ngModel"
                [disabled]="readMode"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!noneR1.valid && (f.submitted || noneR1.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-2"
              *ngIf="
                confSymptom.intensityType === 'RANGE' &&
                confSymptom.noneIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="noneR2"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.noneIntensityScaleOptions!.endValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #noneR2="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!noneR2.value && (f.submitted || noneR2.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-4"
              *ngIf="confSymptom.noneIntensityScaleOptions!.active"
            >
              <app-simple-dictionary-input
                id="noneHT"
                name="noneHT"
                [projectId]="projectId"
                [isReadOnly]="readMode"
                [isRequired]="true"
                [submitted]="f.submitted"
                [shortText]="60"
                [value]="confSymptom.noneIntensityScaleOptions!.helpText"
                (outputEvent)="
                  confSymptom.noneIntensityScaleOptions!.helpText = $event
                "
              ></app-simple-dictionary-input>
            </div>
          </div>
          <div class="p-col-12 p-grid p-mt-1">
            <div class="p-col-2">
              <label>{{ 'symptom.labelLowIntensity' | translate }} </label>
            </div>
            <div class="p-col-2">
              <p-inputSwitch
                name="intensityLow"
                [(ngModel)]="confSymptom.lowIntensityScaleOptions!.active"
                (onChange)="onChangeIntensityConfiguration()"
                [disabled]="readMode"
              ></p-inputSwitch>
            </div>
            <div
              class="p-col-2"
              *ngIf="
                (confSymptom.intensityType === 'RANGE' ||
                  confSymptom.intensityType === 'MULTIPLE_CHOICE') &&
                confSymptom.lowIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="lowR1"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.lowIntensityScaleOptions!.startValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #lowR1="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!lowR1.value && (f.submitted || lowR1.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-2"
              *ngIf="
                confSymptom.intensityType === 'RANGE' &&
                confSymptom.lowIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="lowR2"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.lowIntensityScaleOptions!.endValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #lowR2="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!lowR2.value && (f.submitted || lowR2.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-4"
              *ngIf="confSymptom.lowIntensityScaleOptions!.active"
            >
              <app-simple-dictionary-input
                id="lowHT"
                name="lowHT"
                [projectId]="projectId"
                [isReadOnly]="readMode"
                [isRequired]="true"
                [submitted]="f.submitted"
                [shortText]="60"
                [value]="confSymptom.lowIntensityScaleOptions!.helpText"
                (outputEvent)="
                  confSymptom.lowIntensityScaleOptions!.helpText = $event
                "
              ></app-simple-dictionary-input>
            </div>
          </div>
          <div class="p-col-12 p-grid p-mt-1">
            <div class="p-col-2">
              <label>{{ 'symptom.labelMediumIntensity' | translate }} </label>
            </div>
            <div class="p-col-2">
              <p-inputSwitch
                name="intensityMed"
                [(ngModel)]="confSymptom.mediumIntensityScaleOptions!.active"
                (onChange)="onChangeIntensityConfiguration()"
                [disabled]="readMode"
              ></p-inputSwitch>
            </div>
            <div
              class="p-col-2"
              *ngIf="
                (confSymptom.intensityType === 'RANGE' ||
                  confSymptom.intensityType === 'MULTIPLE_CHOICE') &&
                confSymptom.mediumIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="medR1"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="
                  confSymptom.mediumIntensityScaleOptions!.startValue
                "
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #medR1="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!medR1.value && (f.submitted || medR1.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>

            <div
              class="p-col-2"
              *ngIf="
                confSymptom.intensityType === 'RANGE' &&
                confSymptom.mediumIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="medR2"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.mediumIntensityScaleOptions!.endValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #medR2="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!medR2.value && (f.submitted || medR2.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-4"
              *ngIf="confSymptom.mediumIntensityScaleOptions!.active"
            >
              <app-simple-dictionary-input
                id="medHT"
                name="medHT"
                [projectId]="projectId"
                [isReadOnly]="readMode"
                [isRequired]="true"
                [submitted]="f.submitted"
                [shortText]="60"
                [value]="confSymptom.mediumIntensityScaleOptions!.helpText"
                (outputEvent)="
                  confSymptom.mediumIntensityScaleOptions!.helpText = $event
                "
              ></app-simple-dictionary-input>
            </div>
          </div>
          <div class="p-col-12 p-grid p-mt-1">
            <div class="p-col-2">
              <label>{{ 'symptom.labelHighIntensity' | translate }} </label>
            </div>
            <div class="p-col-2">
              <p-inputSwitch
                name="intensityHigh"
                [(ngModel)]="confSymptom.highIntensityScaleOptions!.active"
                (onChange)="onChangeIntensityConfiguration()"
                [disabled]="readMode"
              ></p-inputSwitch>
            </div>
            <div
              class="p-col-2"
              *ngIf="
                (confSymptom.intensityType === 'RANGE' ||
                  confSymptom.intensityType === 'MULTIPLE_CHOICE') &&
                confSymptom.highIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="highR1"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.highIntensityScaleOptions!.startValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #highR1="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!highR1.value && (f.submitted || highR1.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>

            <div
              class="p-col-2"
              *ngIf="
                confSymptom.intensityType === 'RANGE' &&
                confSymptom.highIntensityScaleOptions!.active
              "
            >
              <p-inputNumber
                name="highR2"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.highIntensityScaleOptions!.endValue"
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #highR2="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!highR2.value && (f.submitted || highR2.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-4"
              *ngIf="confSymptom.highIntensityScaleOptions!.active"
            >
              <app-simple-dictionary-input
                id="highHT"
                name="highHT"
                [projectId]="projectId"
                [isReadOnly]="readMode"
                [isRequired]="true"
                [submitted]="f.submitted"
                [shortText]="60"
                [value]="confSymptom.highIntensityScaleOptions!.helpText"
                (outputEvent)="
                  confSymptom.highIntensityScaleOptions!.helpText = $event
                "
              ></app-simple-dictionary-input>
            </div>
          </div>
          <div class="p-col-12 p-grid p-mt-1">
            <div class="p-col-2">
              <label>{{ 'symptom.labelPotentiallyLife' | translate }} </label>
            </div>
            <div class="p-col-2">
              <p-inputSwitch
                name="intensityPotentiallyLife"
                [(ngModel)]="confSymptom.lifeThreateningScaleOptions!.active"
                (onChange)="onChangeIntensityConfiguration()"
                [disabled]="readMode"
              ></p-inputSwitch>
            </div>
            <div
              class="p-col-2"
              *ngIf="
                (confSymptom.intensityType === 'RANGE' ||
                  confSymptom.intensityType === 'MULTIPLE_CHOICE') &&
                confSymptom.lifeThreateningScaleOptions!.active
              "
            >
              <p-inputNumber
                name="lifeR1"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="
                  confSymptom.lifeThreateningScaleOptions!.startValue
                "
                [showButtons]="true"
                inputId="order"
                [min]="0"
                [max]="maxSafeNumber"
                [required]="true"
                #lifeR1="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!lifeR1.value && (f.submitted || lifeR1.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-2"
              *ngIf="
                confSymptom.intensityType === 'RANGE' &&
                confSymptom.lifeThreateningScaleOptions!.active
              "
            >
              <p-inputNumber
                name="lifeR2"
                mode="decimal"
                [minFractionDigits]="decimalPlaces"
                [maxFractionDigits]="decimalPlaces"
                [(ngModel)]="confSymptom.lifeThreateningScaleOptions!.endValue"
                [showButtons]="true"
                inputId="order"
                [required]="true"
                #lifeR2="ngModel"
                [disabled]="readMode"
                (ngModelChange)="validateRangeIntensityOptions()"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="!lifeR2.value && (f.submitted || lifeR2.touched)"
                >{{ 'general.required' | translate }}</small
              >
            </div>
            <div
              class="p-col-4"
              *ngIf="confSymptom.lifeThreateningScaleOptions!.active"
            >
              <app-simple-dictionary-input
                id="lifeThreateningHT"
                name="lifeThreateningHT"
                [projectId]="projectId"
                [isReadOnly]="readMode"
                [isRequired]="true"
                [submitted]="f.submitted"
                [shortText]="60"
                [value]="confSymptom.lifeThreateningScaleOptions!.helpText"
                (outputEvent)="
                  confSymptom.lifeThreateningScaleOptions!.helpText = $event
                "
              ></app-simple-dictionary-input>
            </div>
          </div>
          <div class="p-col-12 p-mt-2" *ngIf="isIntensityScaleOptionsError">
            <small class="form-error">{{
              'symptom.symptomInvalidRangeMessage' | translate
            }}</small>
          </div>
        </p-card>
      </div>
      <!-- Ranges and alerts -->
      <div
        class="p-grid p-fluid"
        *ngIf="
          (confSymptom.intensityType === 'RANGE' ||
            confSymptom.intensityType === 'NUMERIC_VALUE') &&
          confSymptom.isIntensityRequired === true
        "
      >
        <div class="p-col-12 p-md-6 p-field p-px-0">
          <div class="p-col-12 p-field">
            <label class="full-width" for="rangeAlert">{{
              'symptom.labelConfigureRangesAndAlerts' | translate
            }}</label>
            <p-inputSwitch
              name="rangeAlert"
              #rangeAlert="ngModel"
              [(ngModel)]="confSymptom.isRangeAlertActive"
              [disabled]="readMode"
            ></p-inputSwitch>
          </div>
        </div>
        <!-- numero minimo -->
        <div class="p-col-12 p-md-3" *ngIf="confSymptom.isRangeAlertActive">
          <label for="minValueForAlert" class="p-mb-2 p-d-block">
            {{ 'symptom.labelNumericMinValueIntensity' | translate }}
            <span class="inputRequired"> *</span></label
          >

          <p-inputNumber
            name="minValueForAlert"
            mode="decimal"
            [minFractionDigits]="decimalPlaces"
            [maxFractionDigits]="decimalPlaces"
            [min]="0"
            [(ngModel)]="confSymptom.minValueForAlert"
            [required]="true"
            #minValueForAlert="ngModel"
            [disabled]="readMode"
          ></p-inputNumber>
          <small
            class="form-error p-mt-2"
            *ngIf="
              !minValueForAlert.valid &&
              (f.submitted || minValueForAlert.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <!--numero maximo-->
        <div class="p-col-12 p-md-3" *ngIf="confSymptom.isRangeAlertActive">
          <label for="maxValueForAlert" class="p-mb-2 p-d-block">
            {{ 'symptom.labelNumericMaxValueIntensity' | translate }}
            <span class="inputRequired"> *</span></label
          >

          <p-inputNumber
            name="maxValueForAlert"
            mode="decimal"
            [minFractionDigits]="decimalPlaces"
            [maxFractionDigits]="decimalPlaces"
            [min]="0"
            [(ngModel)]="confSymptom.maxValueForAlert"
            [required]="true"
            #maxValueForAlert="ngModel"
            [disabled]="readMode"
          ></p-inputNumber>
          <small
            class="form-error p-mt-2"
            *ngIf="
              !maxValueForAlert.value &&
              (f.submitted || maxValueForAlert.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
        <!-- contenido de la alerta -->
        <div class="p-col-12 p-field" *ngIf="confSymptom.isRangeAlertActive">
          <label for="alertText">{{
            'symptom.labelAlertText' | translate
          }}</label
          ><span class="inputRequired"> * </span>
          <app-simple-dictionary-editor
            [isRequired]="true"
            [submitted]="f.submitted"
            [value]="confSymptom.alertText"
            (outputEvent)="confSymptom.alertText = $event"
            [isReadOnly]="readMode"
          >
          </app-simple-dictionary-editor>
          <p-editor
            name="alertText"
            [hidden]="true"
            #alertText="ngModel"
            [required]="true"
            [(ngModel)]="confSymptom.alertText"
            [style]="{ height: '320px' }"
            [readonly]="readMode"
          ></p-editor>
        </div>
      </div>
      <div
        class="p-col-12 p-px-0 p-field"
        *ngIf="
          confSymptom.intensityType === 'RANGE' ||
          confSymptom.intensityType === 'NUMERIC_VALUE'
        "
      >
        <div class="p-col p-md-3">
          <label
            class="full-width p-d-block p-mb-2"
            for="isIntensityQuestionsRequired"
            >{{ 'symptom.intensityQuestionsRequired' | translate }}</label
          >
          <p-inputSwitch
            tooltipPosition="top"
            name="isIntensityQuestionsRequired"
            (click)="onItensityQuestionsChange()"
            #isIntensityQuestionsRequired="ngModel"
            [(ngModel)]="confSymptom.isIntensityQuestionsRequired"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div
        class="p-col-12 p-field"
        *ngIf="
          confSymptom.isIntensityQuestionsRequired &&
          (confSymptom.intensityType === 'RANGE' ||
            confSymptom.intensityType === 'NUMERIC_VALUE')
        "
      >
        <label class="full-width p-d-block p-mb-2">{{
          'symptom.intensityQuestions' | translate
        }}</label>
        <p-card>
          <div class="p-col-12 p-grid">
            <div class="p-col-2">
              <label for="">{{ 'symptom.labelOrder' | translate }}</label>
            </div>
            <div class="p-col-5">
              <label for="">{{ 'symptom.questionsTitle' | translate }}</label>
            </div>
            <div class="p-col-3">
              <label for="">{{
                'symptom.labelValueIntensity' | translate
              }}</label>
            </div>
            <div class="p-col-2">
              <label for=""></label>
            </div>
          </div>
          <div
            class="p-col-12 p-grid p-mt-1"
            *ngFor="
              let itemQuestion of confSymptom.intensityQuestions;
              let i = index
            "
          >
            <div class="p-col-2" *ngIf="itemQuestion">
              <p-inputNumber
                id="orderQuestion"
                [name]="'orderQuestion' + i + isEdition"
                [(ngModel)]="itemQuestion.order"
                [min]="1"
                [required]="true"
                #orderQuestion="ngModel"
                [showButtons]="true"
                [disabled]="readMode"
              ></p-inputNumber>
              <small
                class="form-error"
                *ngIf="
                  !itemQuestion.order && (f.submitted || orderQuestion.touched)
                "
              >
                {{ 'general.required' | translate }}</small
              >
            </div>
            <div class="p-col-5" *ngIf="itemQuestion">
              <app-simple-dictionary-input
                [id]="'question' + itemQuestion.id"
                [name]="'question' + itemQuestion.id"
                [projectId]="projectId"
                [isReadOnly]="readMode"
                [isRequired]="true"
                [submitted]="
                  f.submitted ||
                  (isQuestionInValid && itemQuestion.question.trim() === '')
                "
                [shortText]="60"
                [value]="itemQuestion.question"
                (outputEvent)="itemQuestion.question = $event"
              ></app-simple-dictionary-input>
            </div>
            <div class="p-col-3" *ngIf="itemQuestion">
              <p-dropdown
                id="intensityQuestion"
                [name]="'intensityQuestion' + i + isEdition"
                appendTo="body"
                [required]="true"
                placeholder="{{ 'general.selectPlaceholder' | translate }}"
                [options]="intensityOptions"
                optionLabel="name"
                optionValue="value"
                #intensityQuestion="ngModel"
                [(ngModel)]="itemQuestion.intensity"
                [disabled]="readMode"
              ></p-dropdown>
            </div>
            <div class="p-col-2" *ngIf="itemQuestion">
              <button
                *ngIf="confSymptom.intensityQuestions?.length === i + 1"
                id="addQuestionBtn"
                name="addQuestionBtn"
                type="button"
                pButton
                pRipple
                icon="pi pi-plus"
                (click)="addQuestion(itemQuestion, i)"
                class="
                  p-button-rounded
                  p-button-success
                  p-ml-2
                  p-ripple
                  p-button
                  p-component
                  p-button-icon-only
                "
                title="{{ 'general.add' | translate }}"
                [disabled]="readMode"
              ></button>
              <button
                *ngIf="i > 0"
                id="deleteQuestionBtn"
                name="deleteQuestionBtn"
                type="button"
                pButton
                pRipple
                icon="pi pi-trash"
                (click)="deleteQuestion(itemQuestion.id)"
                class="
                  p-button-rounded
                  p-button-secondary
                  p-ml-2
                  p-ripple
                  p-button
                  p-component
                  p-button-icon-only
                "
                title="{{ 'user.buttonDelete' | translate }}"
                [disabled]="readMode"
              ></button>
            </div>
          </div>
        </p-card>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label
          class="full-width"
          style="position: relative"
          #labelSymptomRequireStartDate
          for="symptomRequireStartDate"
          >{{ 'symptom.labelRequireRememberStartDate' | translate }}</label
        >
        <div class="p-col p-px-0">
          <p-inputSwitch
            [appendTo]="labelSymptomRequireStartDate"
            tooltipPosition="top"
            [escape]="false"
            pTooltip="{{
              'symptom.tooltipRequireRememberStartDate' | translate
            }}"
            name="symptomRequireStartDate"
            #symptomRequireStartDate="ngModel"
            [(ngModel)]="confSymptom.isStartDateRequired"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="symptomRequireFinishDate">{{
          'symptom.labelRequireRememberFinishDate' | translate
        }}</label>
        <div class="p-col p-px-0">
          <p-inputSwitch
            tooltipPosition="top"
            [escape]="false"
            pTooltip="{{
              'symptom.tooltipRequireRememberFinishDate' | translate
            }}"
            name="symptomRequireFinishDate"
            #symptomRequireFinishDate="ngModel"
            [(ngModel)]="confSymptom.isFinishDateRequired"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="symptomHelp">{{
          'symptom.labelShowHelp' | translate
        }}</label>
        <div class="p-col p-px-0">
          <p-inputSwitch
            tooltipPosition="top"
            [escape]="false"
            pTooltip="{{ 'symptom.tooltipShowHelp' | translate }}"
            name="showHelp"
            #showHelp="ngModel"
            [(ngModel)]="confSymptom.showHelp"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <!--Help Screen Display Options guide-->
      <div *ngIf="confSymptom.showHelp" class="p-col-12 p-md-6 p-field">
        <label for="helpScreenDisplayOptions" class="p-mb-2 p-d-block"
          >{{ 'symptom.helpScreenDisplayOptions' | translate }}
          <span class="inputRequired"> *</span></label
        >
        <p-multiSelect
          appendTo="body"
          [required]="true"
          placeholder="{{ 'general.selectOneOrMorePlaceholder' | translate }}"
          [options]="HelpScreenDisplayOptions"
          optionLabel="name"
          optionValue="value"
          name="helpScreenDisplayOptions"
          #helpScreenDisplayOptions="ngModel"
          [(ngModel)]="confSymptom.helpScreenDisplayOptions"
          tooltipPosition="top"
          pTooltip="{{ 'symptom.tooltiphelpScreenDisplayOption' | translate }}"
          [disabled]="readMode"
          (onChange)="onHelpScreenDisplayOptionChange()"
        ></p-multiSelect>
        <small
          class="form-error"
          *ngIf="
            !helpScreenDisplayOptions.valid &&
            (f.submitted || helpScreenDisplayOptions.touched)
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="confSymptom.showHelp && hasOnDemandHelpScreenSelected"
      >
        <label
          class="full-width"
          style="position: relative"
          for="requiresButtonHelpScreenLabel"
          >{{ 'symptom.labelRequiresButtonHelpScreenLabel' | translate }}</label
        >
        <div class="p-col p-px-0">
          <p-inputSwitch
            appendTo="body"
            tooltipPosition="top"
            [escape]="false"
            pTooltip="{{
              'symptom.tooltipRequiresButtonHelpScreenLabel' | translate
            }}"
            name="requiresButtonHelpScreenLabel"
            #requiresButtonHelpScreenLabelSwitch="ngModel"
            [(ngModel)]="requiresButtonHelpScreenLabel"
            [disabled]="readMode"
            (onChange)="onRequiresButtonHelpScreenLabelChange()"
          ></p-inputSwitch>
        </div>
      </div>
      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="
          confSymptom.showHelp &&
          requiresButtonHelpScreenLabel &&
          hasOnDemandHelpScreenSelected
        "
      >
        <label class="full-width" for="buttonHelpScreenLabel">{{
          'symptom.labelButtonHelpScreenLabel' | translate
        }}</label>

        <app-simple-dictionary-input
          id="buttonHelpScreenLabel"
          name="buttonHelpScreenLabel"
          [projectId]="projectId"
          [isReadOnly]="readMode"
          [isRequired]="true"
          [submitted]="f.submitted"
          [tooltip]="'symptom.tooltipButtonHelpScreenLabel' | translate"
          [value]="confSymptom.buttonHelpScreenLabel"
          (outputEvent)="confSymptom.buttonHelpScreenLabel = $event"
        ></app-simple-dictionary-input>
      </div>
      <div class="p-col-12 p-field" *ngIf="confSymptom.showHelp">
        <label for="symptomHelp">{{ 'symptom.labelHelp' | translate }}</label
        ><span class="inputRequired"> * </span>
        <app-simple-dictionary-editor
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="confSymptom.symptomHelp"
          (outputEvent)="confSymptom.symptomHelp = $event"
          [isReadOnly]="readMode"
        >
        </app-simple-dictionary-editor>
        <p-editor
          name="symptomHelp"
          [hidden]="true"
          #symptomHelp="ngModel"
          [required]="true"
          [(ngModel)]="confSymptom.symptomHelp"
          [style]="{ height: '320px' }"
          [readonly]="readMode"
        ></p-editor>
      </div>
    </div>
    <div class="p-grid p-fluid p-mt-3" *ngIf="!readMode">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-3">
        <div class="p-grid">
          <div class="p-col">
            <button
              class="p-button-secondary"
              (click)="onCancel()"
              pButton
              type="button"
              label="{{ 'general.cancel' | translate }}"
              [disabled]="readMode"
            ></button>
          </div>
          <div class="p-col">
            <button
              pButton
              type="submit"
              label="{{
                (isEdition ? 'general.edit' : 'general.add') | translate
              }}"
              [disabled]="readMode"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
</form>
