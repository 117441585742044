import { Component } from '@angular/core';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
import * as moment from 'moment-timezone';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
  endTime!: string;

  constructor(
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe
  ) {
    this.trialpalService.maintenanceInfo.subscribe(async (maintenanceInfo) => {
      if (maintenanceInfo.enable) {
        const diff = moment()
          .tz(moment.parseZone(maintenanceInfo.endDate).format('Z'))
          .diff(moment(), 'minutes');

        this.endTime = moment(maintenanceInfo.endDate)
          .add(diff, 'minutes')
          .format('hh:mm A');

        const sameDate = this.isSameDate(maintenanceInfo);

        if (!sameDate) {
          const tempEndDate = moment(maintenanceInfo.endDate).add(
            diff,
            'minutes'
          );
          this.endTime = this.transformDatePipe.transformWithHour(
            tempEndDate,
            true
          );
        }
      }
    });
  }

  isSameDate(maintenanceInfo: any): boolean {
    return moment(moment().format('YYYY-MM-DD')).isSame(
      maintenanceInfo.endDate,
      'days'
    );
  }
}
