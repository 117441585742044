import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { EdiaryService } from '../ediary.service';
import {
  ConfDay,
  ConfEDiary,
  ConfEDiaryWindowInput,
  ConfForm,
  UpdateConfEDiaryInput,
} from '../ediary.types';

@Component({
  selector: 'app-conciliation-configuration',
  templateUrl: './conciliation-configuration.component.html',
  styleUrls: ['./conciliation-configuration.component.scss'],
})
export class ConciliationConfigurationComponent implements OnChanges, OnInit {
  @Input() confEdiary!: ConfEDiary;
  @Input() confForms: ConfForm[] = [];
  @Input() confDays: ConfDay[] = [];
  @Output() confEdiaryEmit: EventEmitter<ConfEDiary> = new EventEmitter();
  windows: WindowTimeForm[] = [];
  isEditionUI: boolean = false;
  isDeletion: boolean = false;
  skipCreation: boolean = false;

  constructor(
    private ediaryService: EdiaryService,
    private trialpalService: TrialpalService
  ) {}

  async ngOnChanges() {
    if (this.confDays.length === 0) {
      await this.getConfDays();
    }
  }

  ngOnInit() {
    this.getWindowsConciliation();
  }

  async getConfDays() {
    let { items } = await this.ediaryService.getConfDayByConfEdiaryId(
      this.confEdiary.id
    );
    items = items.filter((x: any) => !x._deleted);
    if (items.length) {
      this.confDays = items;
    }
  }

  getWindowsConciliation() {
    let windows: any = this.confEdiary.windows ?? [];
    this.isEditionUI = false;
    windows = windows.map((window: any, index: number) => {
      const windowTimeForm: WindowTimeForm = {
        id: `Identificator${index + 1}`, //Id para detectar que es una ventana ya creada
        totalWindowDays: window.totalWindowDays,
        daysAfterVisit: window.daysAfterVisit,
        name: window.name,
        phases: window.phases,
        isEdition: false,
      };
      return windowTimeForm;
    });
    this.windows = windows ?? [];
  }

  //Se encarga de añadir nuevas ventanas
  addNewWindow() {
    this.isEditionUI = true;
    const window = {
      id: undefined, //Id en undefined dado que es una ventana nueva
      daysAfterVisit: undefined,
      name: '',
      phases: [],
      totalWindowDays: undefined,
      isEdition: true,
    } as WindowTimeForm;
    this.windows.push(window);
  }

  async saveWindows(windows: WindowTimeForm[]): Promise<any> {
    this.windows = windows;
    if (!this.skipCreation || this.isDeletion) {
      const alert: { title: string; body: string } = {
        title: this.isDeletion
          ? 'general.configurations.windowDeletedTitle'
          : 'general.configurations.windowSavedTitle',
        body: this.isDeletion
          ? 'general.configurations.windowDeletedBody'
          : 'general.configurations.windowSavedBody',
      };
      await this.updateConfEdiary();
      this.isEditionUI = false;
      this.isDeletion = false;
      this.skipCreation = false;
      this.trialpalService.messageService.add({
        severity: 'success',
        summary: this.trialpalService.translateService.instant(alert.title),
        detail: this.trialpalService.translateService.instant(alert.body),
      });
    }
  }

  async updateConciliationRequired(confEdiary: ConfEDiary) {
    this.confEdiary = confEdiary;
    this.windows = this.confEdiary.windows?.length === 0 ? [] : this.windows;
    await this.updateConfEdiary();
  }

  async updateConfEdiary() {
    let windows: ConfEDiaryWindowInput[] = [];

    //Construye la ventanas
    this.windows.forEach((window: any) => {
      if (window.id) {
        windows.push({
          daysAfterVisit: window.daysAfterVisit,
          name: window.name,
          phases: window.phases,
          totalWindowDays: window.totalWindowDays,
        });
      }
    });

    //Si se eliminaron las ventanas se retorna al estado de no edición
    if (this.windows.length === 0) {
      this.isEditionUI = false;
    }

    //Input con las actualizaciones
    const input: UpdateConfEDiaryInput = {
      id: this.confEdiary.id,
      isConciliationRequired: this.confEdiary.isConciliationRequired,
      isWindowsConciliationRequired:
        this.confEdiary.isWindowsConciliationRequired,
      assessmentMode: this.confEdiary.assessmentMode,
      windows: windows,
      _version: this.confEdiary._version,
    };
    this.trialpalService.showSpinner('general.savingInfo');
    await this.ediaryService
      .updateConciliationConfEdiary(input)
      .then((confEdiary) => {
        this.confEdiary = confEdiary;
        this.confEdiaryEmit.emit(this.confEdiary);
      })
      .catch((error) => {
        this.trialpalService.messageService.add({
          severity: 'error',
          summary:
            this.trialpalService.translateService.instant('general.attention'),
          detail: JSON.stringify(error),
        });
        this.trialpalService.hideSpinner();
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
}

export interface WindowTimeForm {
  daysAfterVisit?: number;
  name: string;
  phases: string[];
  totalWindowDays?: number;
  id?: string;
  isEdition: boolean;
}
