import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import {
  IntensityReconciliationOption,
  VerifiedSymptomInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import { ConciliationComponents } from 'src/app/modules/conciliation/conciliationData';
import { PaginationService } from 'src/app/modules/conciliation/pagination.service';
import {
  ConfSymptom,
  Intensity,
  SymptomType,
} from 'src/app/modules/ediary/ediary.types';
import { IntensityType } from 'src/app/modules/report/report.types';
import { TrialpalService } from '../../../../../../services/trialpal.service';
import { TP2Entites } from '../../../../../../shared/global.variables';
import { ConciliationService } from '../../../../conciliation.service';
import { DetailSymptomComponent } from '../detail-symptom/detail-symptom.component';
import { SummarySymptomComponent } from '../summary-symptom/summary-symptom.component';
import { SymptomService, TableSymptom } from '../symptom.service';
const logger = new Logger('EditSymptomComponent conciliation');
@Component({
  selector: 'app-edit-symptom',
  templateUrl: './edit-symptom.component.html',
  styleUrls: ['./edit-symptom.component.scss'],
})
export class EditSymptomComponent implements OnChanges {
  @Input() currentSymptom!: TableSymptom;
  @Output()
  exportAuditRecord: EventEmitter<any> = new EventEmitter();
  configuration: ConfSymptom = {
    id: '',
    minSize: 0,
    maxSize: 1000,
    decimalPlaces: 0,
    symptomLabel: '',
  } as ConfSymptom;
  confSymptomName: string = '';
  form: FormGroup;
  currentOriginalSyptom: any = {};
  intensityScaleOptions: any[] = [];
  intensityQuestionAnswersOriginal: any[] = [];
  intRange: any[] = [];
  decimalRange: any[] = [];
  minDate: Date = new Date();
  maxDate: Date = new Date();
  booleanOptions: any[] = [];
  originalUserIntensity: number = 0;

  NUMERIC_VALUE: IntensityType = IntensityType.NUMERIC_VALUE;
  MULTIPLE_CHOICE: IntensityType = IntensityType.MULTIPLE_CHOICE;
  RANGE: IntensityType = IntensityType.RANGE;

  //Variables que guardaran el cambio en el formulario actual
  isIntensityChanged: boolean = false;
  isIntensityQuestionsChanged: boolean = false;
  isRememberStartDateChanged: boolean = false;
  isStartDateChanged: boolean = false;
  isRememberFinishDateChanged: boolean = false;
  isFinishDateChanged: boolean = false;
  isRememberNoValuesTakenChanged: boolean = false;
  isOccurrencyChanged: boolean = false;
  isFormChanged: boolean = false; //Guarda cuando el formulario ha sido modificado (excepto comentarios)
  isCommentsChanged: boolean = false; //Guarda cuando los comentarios del formulario han sido modificados
  confForm: any;
  symptomsReconciliationConfig: any;
  isAllowedToIncreaseIntensity: boolean = true;
  isAllowedToDecreaseIntensity: boolean = true;
  isAllowedToChangeIntensity: any;
  isIntensityAlertShown: any;
  isAllowedToChangeDate: any;
  allowAddRecords: any;
  allowRemoveRecords: any;
  isAllowedToChangeOccurrency: any;

  constructor(
    public dialogService: DialogService,
    public symptomService: SymptomService,
    private formBuilder: FormBuilder,
    private conciliationService: ConciliationService,
    private trialpalService: TrialpalService,
    private paginationService: PaginationService
  ) {
    this.form = this.formBuilder.group({});
    this.buildCurrentForm();
  }

  async ngOnChanges() {
    this.showAuditButton();
    this.booleanOptions = this.conciliationService.buildBooleanOptions();
    this.buildCurrentSymptom();
  }

  showAuditButton() {
    this.exportAuditRecord.emit(null);
    if (this.currentSymptom?.isEdition) {
      this.exportAuditRecord.emit({
        entity: TP2Entites.VERIFIEDOTHERSYMPTOMINSTANCE,
        id: this.currentSymptom?.verifiedSymptomInstance?.id,
        description:
          this.trialpalService.dictionaryPipe.transform(
            this.currentSymptom.confSymptom?.symptomLabel ?? ''
          ) ||
          this.trialpalService.translateService.instant(
            `symptom.enums.symptoms.${this.currentSymptom.confSymptom.symptom}`
          ),
      });
    }
  }

  /**
   * Construye y establece los valores del formulario para el síntoma actual.
   * La función construye el formulario, configura las fechas mínimas y máximas, obtiene la configuración del síntoma,
   * establece los valores del formulario y realiza copias de seguridad de los valores originales del formulario y las respuestas a las preguntas de intensidad.
   */
  buildCurrentSymptom() {
    this.buildCurrentForm();
    logger.debug(this.currentSymptom, 'currentSymptom');
    this.confForm = this.currentSymptom?.confForm;
    this.symptomsReconciliationConfig =
      this.currentSymptom.symptomType === SymptomType.GENERAL
        ? this.confForm.symptomsReconciliationConfig
        : this.confForm.localSymptomsReconciliationConfig;
    logger.debug(
      'symptomsReconciliationConfig',
      this.symptomsReconciliationConfig
    );
    logger.debug(
      'symptomsReconciliationConfig.allowIntensityReconciliation.allow',
      this.symptomsReconciliationConfig?.allowIntensityReconciliation
    );
    this.setIntensityChangeRules();
    this.setDatesChangeRules();
    //Rango de fechas que puede el usuario seleccionar
    this.maxDate = new Date(
      this.currentSymptom?.dayInstance?.startDate + 'T00:00:00'
    );
    this.minDate = new Date(
      this.currentSymptom?.dayInstance?.finishDate + 'T00:00:00'
    );

    this.configuration = this.currentSymptom.confSymptom; // Obtiene la configuración del sintoma
    this.confSymptomName = this.symptomService.getConfSymptomName(
      this.configuration
    );
    this.createVerifiedSymptomForm();
    this.setOccurrencyChangeRules();
  }

  createVerifiedSymptomForm() {
    let formValues =
      this.currentSymptom?.verifiedSymptomInstance ??
      this.currentSymptom?.symptomInstance;

    let symptomOccurrency = this.currentSymptom?.verifiedSymptomInstance
      ? this.currentSymptom.verifiedSymptomOccurrency
      : this.currentSymptom.symptomOccurrency;
    this.buildSymptomConfigurationScale();
    this.buildSymptom(formValues);
    //Formulario
    this.setFormValues(formValues, symptomOccurrency?.occurrency);
    this.recordFormValues();
    this.setOccurrencyValidator();
    this.setFormValidators();
  }

  getMinAllowedNumericIntensity() {
    const currentIntensity = this.currentSymptom?.symptomInstance?.size;
    return this.isAllowedToDecreaseIntensity
      ? this.configuration.minSize
      : currentIntensity;
  }

  getMaxAllowedNumericIntensity() {
    const currentIntensity = this.currentSymptom?.symptomInstance?.size;
    return this.isAllowedToIncreaseIntensity
      ? this.configuration.maxSize
      : currentIntensity;
  }

  /**
   * Realiza una copia de los valores actuales del formulario y las respuestas a las preguntas de intensidad.
   * Estos valores se utilizarán para comparar los cambios realizados en el formulario.
   */
  recordFormValues() {
    this.currentOriginalSyptom = structuredClone(this.form.value);
    this.intensityQuestionAnswersOriginal = [];
    this.currentOriginalSyptom?.intensityQuestionAnswers?.forEach(
      (answer: any) => {
        this.intensityQuestionAnswersOriginal.push({ ...answer });
      }
    );
  }

  /**
   * Establece los valores del formulario con los datos del síntoma verificado.
   * @param verifiedSymptomInstance La instancia de síntoma verificado que contiene los datos para establecer en el formulario.
   * @param occurrency Indica si el síntoma ocurrió (true) o no (false o null).
   */
  setFormValues(
    verifiedSymptomInstance: any,
    occurrency: boolean | undefined | null
  ) {
    this.form.patchValue({
      occurrency: occurrency,
      occurrencyComment: verifiedSymptomInstance?.occurrencyComment,
      rememberStartDate: verifiedSymptomInstance?.rememberStartDate,
      startDate: this.conciliationService.getFormatDate(
        verifiedSymptomInstance?.startDate
      ),
      startDateComment: verifiedSymptomInstance?.startDateComment,
      rememberFinishDate: verifiedSymptomInstance?.rememberFinishDate,
      finishDate: this.conciliationService.getFormatDate(
        verifiedSymptomInstance?.finishDate
      ),
      finishDateComment: verifiedSymptomInstance?.finishDateComment,
      intensity: verifiedSymptomInstance?.intensity,
      intensityComment: verifiedSymptomInstance?.intensityComment,
      size: verifiedSymptomInstance?.size,
      sizeComment: verifiedSymptomInstance?.sizeComment,
      rememberNoValuesTaken: verifiedSymptomInstance?.rememberNoValuesTaken,
      rememberNoValuesTakenComment:
        verifiedSymptomInstance?.rememberNoValuesTakenComment,
      intensityQuestionAnswers: structuredClone(
        verifiedSymptomInstance?.intensityQuestionAnswers
      ),
      intensityQuestionAnswersComment:
        verifiedSymptomInstance?.intensityQuestionAnswersComment,
      answers: this.formBuilder.group(
        this.buildAnswers(
          verifiedSymptomInstance?.intensityQuestionAnswers ?? []
        )
      ),
      changeReason: '',
    });
    this.setScaleForIntensityQuestions();
  }

  buildCurrentForm() {
    this.form = this.formBuilder.group({
      occurrency: [],
      occurrencyComment: [],
      intSize: [],
      decimalSize: [],
      intensity: [],
      intensityComment: [],
      size: [],
      sizeComment: [],
      rememberStartDate: [],
      startDate: [],
      startDateComment: [],
      rememberFinishDate: [],
      finishDate: [],
      finishDateComment: [],
      rememberNoValuesTaken: [],
      rememberNoValuesTakenComment: [],
      intensityQuestionAnswers: [],
      intensityQuestionAnswersComment: [],
      answers: this.formBuilder.group({
        answer0: [],
        answer1: [],
        answer2: [],
        answer3: [],
        answer4: [],
        answer5: [],
        answer6: [],
        answer7: [],
        answer8: [],
        answer9: [],
        answer10: [],
        answer11: [],
        answer12: [],
        answer13: [],
        answer14: [],
        answer15: [],
        answer16: [],
        answer17: [],
        answer18: [],
        answer19: [],
      }),
      changeReason: [],
    });
  }

  buildAnswers(answers: any[]): FormGroup {
    const form: any = {};
    answers?.forEach((answer: any, index) => {
      form[`answer${index}`] = answer.answer;
    });
    return this.formBuilder.group(form) || {};
  }

  buildSymptom(currentSymptom: any) {
    if (currentSymptom) {
      if (this.configuration.intensityType === IntensityType.RANGE) {
        const numberSplit = `${currentSymptom.size}`.split('.');
        const intSize = numberSplit[0] ?? 0;
        let decimalSize = numberSplit[1] ? numberSplit[1] : 0;
        if (typeof decimalSize === 'string' && this.configuration.decimalPlaces)
          decimalSize = decimalSize.padEnd(
            this.configuration.decimalPlaces,
            '0'
          );
        logger.debug('decimalSizeType', decimalSize, typeof decimalSize);
        if (currentSymptom.size || currentSymptom.size === 0) {
          this.form.controls['intSize'].setValue(intSize);
          this.form.controls['decimalSize'].setValue(decimalSize);
        }
      }
    }
  }

  shouldShowIntensity(): boolean {
    return (
      this.currentSymptom?.symptomInstance?.intensity ??
      this.currentSymptom?.symptomInstance?.size ??
      this.isAllowedToChangeIntensity
    );
  }

  setIntensityChangeRules() {
    let isAllowedToIncreaseIntensity = true;
    let isAllowedToDecreaseIntensity = true;
    this.isAllowedToChangeIntensity =
      this.symptomsReconciliationConfig?.allowIntensityReconciliation?.allow;
    if (
      this.symptomsReconciliationConfig?.allowIntensityReconciliation
        ?.allowedAction !== IntensityReconciliationOption.ANY
    ) {
      isAllowedToIncreaseIntensity =
        this.symptomsReconciliationConfig?.allowIntensityReconciliation
          ?.allowedAction === IntensityReconciliationOption.INCREASE;
      isAllowedToDecreaseIntensity =
        this.symptomsReconciliationConfig?.allowIntensityReconciliation
          ?.allowedAction === IntensityReconciliationOption.DECREASE;
    }
    this.isAllowedToIncreaseIntensity = isAllowedToIncreaseIntensity;
    this.isAllowedToDecreaseIntensity = isAllowedToDecreaseIntensity;
    logger.debug('isAllowedToIncreaseIntensity', isAllowedToIncreaseIntensity);
    logger.debug('isAllowedToDecreaseIntensity', isAllowedToDecreaseIntensity);
  }

  setDatesChangeRules() {
    logger.debug('setDatesChangeRules', this.symptomsReconciliationConfig);
    this.isAllowedToChangeDate =
      this.symptomsReconciliationConfig?.allowOcurencyDateReconciliation;
  }

  buildSymptomConfigurationScale() {
    if (this.configuration) {
      this.intensityScaleOptions = this.symptomService.getValueScale(
        this.configuration
      );
      logger.debug(
        'this.configuration.intensityType',
        this.configuration.intensityType
      );
      logger.debug('intensityScaleOptions', this.intensityScaleOptions);
      logger.debug('currentSymptom', this.currentSymptom);
      this.intRange = this.symptomService.getIntRange(
        this.intensityScaleOptions
      );

      const decimalRange = this.symptomService.getDecimalRange(
        this.configuration
      );

      this.decimalRange = decimalRange;
      logger.debug('decimalRange', decimalRange);

      this.setScaleForMultipleChoice();
      this.setScaleForRange();
    }
  }

  setScaleForRange() {
    //Si no se permite aumentar o disminuir la intensidad se filtra el rango de intensidad
    if (this.configuration.intensityType === IntensityType.RANGE) {
      const currentIntensity = this.currentSymptom?.symptomInstance?.size;
      logger.debug('decimalRange', currentIntensity);

      if (!this.isAllowedToIncreaseIntensity && currentIntensity) {
        this.intRange = this.intRange.filter(
          (intensity) =>
            intensity <= Number(currentIntensity.toString().split('.')[0])
        );
        logger.debug('decimalPlaces', this.configuration.decimalPlaces);
        if (this.configuration.decimalPlaces)
          this.sliceToMaxDecimalRange(currentIntensity);
      }

      if (!this.isAllowedToDecreaseIntensity && currentIntensity) {
        this.intRange = this.intRange.filter(
          (intensity) =>
            intensity >= Number(currentIntensity.toString().split('.')[0])
        );

        if (this.configuration.decimalPlaces)
          this.sliceToMinDecimalRange(currentIntensity);
      }
    }
  }

  setScaleForMultipleChoice() {
    if (
      this.configuration.intensityType === IntensityType.MULTIPLE_CHOICE &&
      this.currentSymptom?.symptomInstance?.intensity
    ) {
      const currentIntensity = this.currentSymptom?.symptomInstance?.intensity;
      //get currentSymptomIndex on intensityScaleOptions
      const currentSymptomIndex = this.intensityScaleOptions.findIndex(
        (option) => option.intensity === currentIntensity
      );
      if (!this.isAllowedToDecreaseIntensity) {
        //Deja solo las opciones con index mayor o igual al original
        this.intensityScaleOptions = this.intensityScaleOptions.filter(
          (option, index) => index >= currentSymptomIndex
        );
      }
      if (!this.isAllowedToIncreaseIntensity) {
        //Deja solo las opciones con index menor o igual al original
        this.intensityScaleOptions = this.intensityScaleOptions.filter(
          (option, index) => index <= currentSymptomIndex
        );
      }
    }
  }

  setScaleForIntensityQuestions() {
    if (
      this.form.controls['intensityQuestionAnswers']?.value &&
      this.currentSymptom?.symptomInstance?.intensityQuestionAnswers
    ) {
      logger.debug(
        'setScaleForIntensityQuestions: 1.1. intensityQuestionAnswers',
        this.currentSymptom?.symptomInstance?.intensityQuestionAnswers
      );
      logger.debug(
        'setScaleForIntensityQuestions: 1.2. intensityQuestionAnswers',
        this.form.controls['intensityQuestionAnswers']?.value
      );

      for (let answer of this.form.controls['intensityQuestionAnswers'].value) {
        const originalAnswer =
          this.currentSymptom?.symptomInstance?.intensityQuestionAnswers.find(
            (originalAnswer: any) =>
              originalAnswer.question.id === answer.question.id
          );
        logger.debug(
          'setScaleForIntensityQuestions: 2. originalAnswer',
          originalAnswer
        );
        if (
          originalAnswer?.answer === true &&
          !this.isAllowedToDecreaseIntensity
        )
          answer.readOnly = true;
        if (
          originalAnswer?.answer === false &&
          !this.isAllowedToIncreaseIntensity
        )
          answer.readOnly = true;
      }
      logger.debug(
        'setScaleForIntensityQuestions: 3. intensityQuestionAnswers',
        this.form.controls['intensityQuestionAnswers']?.value
      );
    }
  }

  async onSubmit() {
    this.conciliationService.clearSpaces(this.form);
    logger.debug(this.form, this.form.controls, 'formulario');

    if (this.form.valid) {
      const isValidDates = this.conciliationService.isValidDates(this.form);
      if (!isValidDates) return this.conciliationService.messageErrorDates();

      if (this.isFormChanged) {
        this.openModal();
      } else {
        this.clearReadOnlyIntensityQuestions();
        await this.sendVerifiedSymptom();
      }
    } else {
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
  }

  clearReadOnlyIntensityQuestions() {
    //elimina el readOnly de las preguntas de intensidad
    if (this.form.value) {
      for (let answer of this.form.value.intensityQuestionAnswers) {
        delete answer.readOnly;
      }
    }
    logger.debug('clearReadOnlyIntensityQuestions', this.form.value);
  }

  async openModal() {
    //Construye el formulario original con los datos guardados
    const currentOriginalSyptom = this.buildFormValues(
      this.currentOriginalSyptom,
      false
    );

    //Construye el formulario modificado por el usuario
    const currentSymptom = this.buildFormValues(this.form.value, true);

    //Obtiene un arreglo con los cambios realizados por el usuario
    const [original, verified] = this.conciliationService.getValuesChange(
      currentOriginalSyptom,
      currentSymptom
    );

    const ref = this.dialogService.open(SummarySymptomComponent, {
      data: {
        original,
        verified,
        configuration: this.configuration,
      },
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmSaveForm'
      ),
      style: { 'min-width': '380px', width: '50%' },
    });
    ref.onClose.subscribe((canPass: boolean) => {
      if (canPass) {
        this.conciliationService.recordChangeEvent(
          verified,
          original,
          'symptom'
        );
        this.sendVerifiedSymptom();
      }
    });
  }

  async sendVerifiedSymptom() {
    this.clearReadOnlyIntensityQuestions();
    if (this.currentSymptom.isEdition) {
      //Si no hubo un cambio pasa al siguiente registro
      if (this.isFormChanged || this.isCommentsChanged) {
        await this.updateVerifiedSymptom();
      } else {
        this.nextSymptom();
      }
    } else {
      await this.createVerifiedSymptom();
    }
  }

  /**
   * Construye los valores del formulario del síntoma utilizando los datos proporcionados.
   * @param values Los valores del formulario.
   * @param isForm Un indicador booleano que indica si los valores provienen del formulario o del objeto original.
   * @returns Los valores del formulario del síntoma construidos.
   */
  buildFormValues(symptom: any, isForm: boolean) {
    let formValues = structuredClone(symptom);
    formValues = this.buildSymptomRangeValue(formValues, isForm);
    formValues = this.deleteValues(formValues);
    return formValues;
  }

  /**
   * Construye los valores del rango del síntoma basados en los valores proporcionados.
   * @param value Los valores del síntoma.
   * @param isFormValues Un indicador booleano que indica si los valores provienen de un formulario.
   * @returns Los valores del síntoma actualizados con el rango construido.
   */
  buildSymptomRangeValue(symptom: any, isFormValues: boolean) {
    if (this.configuration?.intensityType === IntensityType.RANGE) {
      const size = this.getSize(symptom.intSize, symptom.decimalSize);
      if (Number(size) || Number(size) === 0) {
        symptom.size = size;
        symptom.intensity = this.getIntensityFromRangeScale(
          Number(size.split('.')[0])
        );
      } else {
        symptom.size = null;
        symptom.intensity = null;
      }
    } else if (this.configuration.intensityType === this.NUMERIC_VALUE) {
      symptom.intensity = Intensity.NONE;
    }

    const intensityQuestionAnswers = isFormValues
      ? this.form.controls['intensityQuestionAnswers'].value
      : this.intensityQuestionAnswersOriginal;

    symptom.intensity = this.getIntensityFromQuestions(
      symptom.intensity,
      intensityQuestionAnswers
    );

    return symptom;
  }

  /**
   * Elimina los valores no deseados del objeto proporcionado.
   * @param symptom El objeto del que se eliminarán los valores.
   * @returns El objeto con los valores no deseados eliminados.
   */
  deleteValues(symptom: any) {
    delete symptom.answers;
    delete symptom.intSize;
    delete symptom.decimalSize;
    delete symptom.changeReason;
    return symptom;
  }

  /**
   * Obtiene la intensidad final del síntoma basada en las respuestas a las preguntas de intensidad, si están disponibles.
   * @param intensity La intensidad actual del síntoma.
   * @param intensityQuestionAnswers Las respuestas a las preguntas de intensidad.
   * @returns La intensidad final del síntoma, actualizada según las respuestas a las preguntas de intensidad.
   */
  getIntensityFromQuestions(intensity: any, intensityQuestionAnswers: any[]) {
    if (intensity && this.configuration.isIntensityQuestionsRequired) {
      let intensityQuestion = this.symptomService.getIntenityFromQuestion(
        intensityQuestionAnswers
      );
      intensity = this.symptomService.getIntensityOrder([
        {
          intensity: intensityQuestion,
        },
        {
          intensity: intensity,
        },
      ]);
    }
    return intensity;
  }

  /**
   * Obtiene la intensidad del síntoma basada en el tamaño proporcionado en la escala de rango del síntoma.
   * @param size El tamaño del síntoma en la escala de rango.
   * @returns La intensidad del síntoma correspondiente al tamaño proporcionado.
   */
  getIntensityFromRangeScale(size: number) {
    let intensity = Intensity.NONE;
    for (let scaleOption of this.intensityScaleOptions) {
      if (scaleOption.startValue <= size && size <= scaleOption.endValue) {
        intensity = scaleOption.intensity;
      }
    }
    return intensity;
  }

  /**
   * Crea una instancia de síntoma verificado utilizando los datos del formulario actual.
   * La función realiza varias acciones, incluyendo la construcción de la instancia de síntoma verificado,
   * la actualización del estado de la fase del diario electrónico, la creación de la instancia de síntoma verificado
   * en el servicio de síntomas y la creación o actualización del registro de registro del síntoma.
   * Además, actualiza la tabla de síntomas en la interfaz de usuario y muestra mensajes de éxito o error según corresponda.
   */
  async createVerifiedSymptom() {
    try {
      this.conciliationService.showSpinner(
        'transactionLoadingCreate',
        'symptominstance.entity'
      );
      const verifiedSymptomInstance = this.buildFormValues(
        this.form.value,
        true
      );
      delete verifiedSymptomInstance.occurrency;
      const symptomInstance = this.currentSymptom.symptomInstance;
      const createVerifiedSymptomInstance =
        await this.symptomService.createVerifiedSymptom(
          symptomInstance,
          verifiedSymptomInstance
        );
      await this.createUpdateInformationPostSubmit(
        createVerifiedSymptomInstance
      );
      this.conciliationService.messageSuccess(true);
      this.trialpalService.hideSpinner();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error(error, 'Error create Verified Symptom');
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'conciliation.messageError.detail'
      );
    }
  }

  /**
   * Actualiza una instancia de síntoma verificado utilizando los datos del formulario actual.
   * La función realiza varias acciones, incluyendo la construcción de la instancia de síntoma verificado,
   * la actualización del estado de la fase del diario electrónico, la actualización de la instancia de síntoma verificado
   * en el servicio de síntomas y la creación o actualización del registro de registro del síntoma.
   * Además, actualiza la tabla de síntomas en la interfaz de usuario y muestra mensajes de éxito o error según corresponda.
   */
  async updateVerifiedSymptom() {
    this.conciliationService.showSpinner(
      'transactionLoadingUpdate',
      'symptominstance.entity'
    );

    try {
      const verifiedSymptomInstance = this.buildFormValues(
        this.form.value,
        true
      );
      delete verifiedSymptomInstance.occurrency; //Elimina variable ineccesaria
      logger.debug('Update VerifiedSymptomInstance', verifiedSymptomInstance);

      const _changeReason = this.form.controls['changeReason'].value;
      verifiedSymptomInstance._changeReason = _changeReason;

      const updateVerifiedSymptomInstance =
        await this.symptomService.updateVerifiedSymptom(
          this.currentSymptom.verifiedSymptomInstance,
          verifiedSymptomInstance
        );

      await this.createUpdateInformationPostSubmit(
        updateVerifiedSymptomInstance
      );
      this.conciliationService.messageSuccess(false);
      this.trialpalService.hideSpinner();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error(error, 'Error update Verified Symptom');
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'conciliation.messageError.detail'
      );
    }
  }

  /**
   * Actualiza el estado de la fase del diario electrónico y realiza varias operaciones después de enviar un síntoma verificado, como crear o actualizar registros de síntomas,
   * actualizar la ocurrencia del síntoma en el día y actualizar la vista de la tabla de ocurrencias de síntomas.
   * @param verifiedSymptomInstance La instancia verificada del síntoma.
   * @returns Una promesa que se resuelve cuando se completa la actualización de la información posterior al envío.
   */
  async createUpdateInformationPostSubmit(
    verifiedSymptomInstance: VerifiedSymptomInstance
  ): Promise<void> {
    await this.conciliationService.updateEdiaryPhaseState(false).catch();

    //Create o actualiza el symptomRecordLog
    const verifiedSymptomRecordLog =
      await this.symptomService.createOrUpdateSymptomRecordLog(
        verifiedSymptomInstance,
        this.currentSymptom.dayInstance.id,
        this.currentSymptom.symptomRecordLogs
      );

    let updateVerifiedSymptomOccurrency = undefined;
    //Actualiza la ocurrencia en el día si hubo un campo o se esta creando el sintoma verificado
    if (this.isOccurrencyChanged || !this.currentSymptom.isEdition) {
      updateVerifiedSymptomOccurrency =
        await this.symptomService.updateOcurrency(
          verifiedSymptomInstance,
          this.currentSymptom.dayInstance.id,
          verifiedSymptomRecordLog,
          this.form.controls['occurrency'].value,
          this.form.controls['changeReason'].value
        );
    }

    const detailSymptomComponent: DetailSymptomComponent =
      this.getCurrentDetailSymptomComponent();

    //Actualiza la vista
    await this.symptomService.updateTable(
      verifiedSymptomInstance,
      updateVerifiedSymptomOccurrency,
      this.currentSymptom,
      detailSymptomComponent
    );
    this.nextSymptom();
  }

  //Obtiene la referencia del componente modificado
  getCurrentDetailSymptomComponent(): DetailSymptomComponent {
    const symptomType =
      this.currentSymptom?.symptomType || this.symptomService.symptomType;
    return this.symptomService.getDetailSymptomComponentBySymptomType(
      symptomType as SymptomType
    );
  }

  getSize(value: any, decimalSize: any) {
    return this.configuration?.decimalPlaces
      ? `${value}.${decimalSize}`
      : `${value}`;
  }

  //================ VALIDACIÓN SI CAMBIO UN ELEMENTO =================

  setIntensityQuestionsAnswersChange() {
    this.isIntensityQuestionsChanged =
      JSON.stringify(
        this.form.controls['intensityQuestionAnswers']?.value || []
      ) !== JSON.stringify(this.intensityQuestionAnswersOriginal || []);
    this.clearIntensityQuestionAnswersComment();
    this.setFormChange();
  }

  /**
   * Limpia el campo de comentario de las respuestas a las preguntas de intensidad del síntoma si no ha habido cambios en las respuestas a las preguntas de intensidad y si no hay ningún comentario existente en las respuestas a las preguntas de intensidad.
   * Luego, actualiza el estado de cambio de comentario.
   */
  clearIntensityQuestionAnswersComment() {
    if (
      !this.isIntensityQuestionsChanged &&
      !this.currentOriginalSyptom?.intensityQuestionAnswersComment
    ) {
      this.form.controls['intensityQuestionAnswersComment'].setValue('');
    }
    this.setCommentChange();
  }

  /**
   * Determina si ha habido un cambio en la intensidad de algún síntoma.
   * @param numberSize El nuevo tamaño del número (Solo para la configuracion de valor numerico).
   */
  setIntensityChange(numberSize?: number) {
    let isIntensityChanged = false;
    if (this.configuration.intensityType === IntensityType.RANGE) {
      let size: string = this.getSize(
        this.form.controls['intSize']?.value,
        this.form.controls['decimalSize']?.value
      );

      isIntensityChanged =
        `${parseFloat(this.currentOriginalSyptom?.size)}` !==
        `${parseFloat(size)}`;

      this.checkDecimalIntensityChange();
    }

    if (this.configuration.intensityType === IntensityType.NUMERIC_VALUE) {
      logger.debug('SetIntensityChange: size', numberSize);
      logger.debug(
        'SetIntensityChange: currentOriginalSyptom size',
        this.currentOriginalSyptom?.size
      );
      if (!numberSize) numberSize = this.form.controls['size']?.value;
      isIntensityChanged =
        `${this.currentOriginalSyptom?.size}` !== `${numberSize}`;
    }

    if (this.configuration.intensityType === IntensityType.MULTIPLE_CHOICE) {
      isIntensityChanged =
        this.form.controls['intensity']?.value !==
        this.currentOriginalSyptom?.intensity;
    }

    this.isIntensityChanged = isIntensityChanged;
    this.clearSizeComment();
    this.setFormChange();
  }

  onInputIntensity(numberSize?: number) {
    this.originalUserIntensity =
      numberSize ?? this.form.controls['size']?.value;
    this.setIntensityChange(numberSize);
  }

  onBlurIntensity(numberSize?: number) {
    this.showIntensityAlertIfOutOfRange();
    this.setIntensityChange(numberSize);
  }

  showIntensityAlertIfOutOfRange() {
    const numberSize = this.originalUserIntensity;
    if (this.configuration.intensityType === IntensityType.NUMERIC_VALUE) {
      this.isIntensityChanged =
        `${this.currentOriginalSyptom?.size}` !== `${numberSize}`;
      logger.debug('SetIntensityChange: size newTests', numberSize);
      logger.debug(
        'SetIntensityChange: currentOriginalSyptom size',
        this.currentOriginalSyptom?.size
      );
      const maxAllowedNumericIntensity = this.getMaxAllowedNumericIntensity();
      const minAllowedNumericIntensity = this.getMinAllowedNumericIntensity();
      if (
        numberSize &&
        maxAllowedNumericIntensity &&
        minAllowedNumericIntensity &&
        !this.isIntensityAlertShown
      ) {
        if (
          numberSize > maxAllowedNumericIntensity ||
          numberSize < minAllowedNumericIntensity
        ) {
          this.trialpalService.messageService.add({
            severity: 'warn',
            summary:
              this.trialpalService.translateService.instant(
                'general.attention'
              ),
            detail: this.trialpalService.translateService.instant(
              'conciliation.intensityOutOfRange',
              {
                min: minAllowedNumericIntensity,
                max: maxAllowedNumericIntensity,
              }
            ),
          });
          this.isIntensityAlertShown = true;
        }
      }
    }
  }

  checkDecimalIntensityChange() {
    if (
      this.form.controls['intSize']?.value !==
      this.currentOriginalSyptom?.size?.toString()?.split('.')[0]
    ) {
      if (
        !this.isAllowedToIncreaseIntensity ||
        !this.isAllowedToDecreaseIntensity
      ) {
        this.decimalRange = this.symptomService.getDecimalRange(
          this.configuration
        );
      }
    } else {
      const currentIntensity = this.currentSymptom?.symptomInstance?.size;
      logger.debug('decimalPlaces', this.configuration.decimalPlaces);
      if (this.configuration.decimalPlaces) {
        if (!this.isAllowedToDecreaseIntensity)
          this.sliceToMinDecimalRange(Number(currentIntensity));
        if (!this.isAllowedToIncreaseIntensity)
          this.sliceToMaxDecimalRange(Number(currentIntensity));
      }
    }
  }
  sliceToMinDecimalRange(currentIntensity: number) {
    if (!currentIntensity.toString().split('.')[1]) return;
    this.decimalRange = this.decimalRange.filter(
      (decimal) =>
        decimal >=
        Number(
          currentIntensity
            .toString()
            .split('.')[1]
            .padEnd(this.configuration.decimalPlaces ?? 0, '0')
        )
    );
  }

  sliceToMaxDecimalRange(currentIntensity: number) {
    logger.debug('currentIntensity', currentIntensity);
    if (!currentIntensity.toString().split('.')[1]) return;
    this.decimalRange = this.decimalRange.filter(
      (decimal) =>
        decimal <=
        Number(
          currentIntensity
            .toString()
            .split('.')[1]
            .padEnd(this.configuration.decimalPlaces ?? 0, '0')
        )
    );
  }

  /**
   * Limpia el campo de comentario del tamaño del síntoma si no ha habido cambios en la intensidad del síntoma y si no hay ningún comentario existente en el tamaño del síntoma.
   */
  clearSizeComment() {
    if (!this.isIntensityChanged && !this.currentOriginalSyptom?.sizeComment) {
      this.form.controls['sizeComment'].setValue('');
    }
    this.setCommentChange();
  }

  /**
   * Verifica si ha habido un cambio en la fecha de inicio recordada.
   */
  setRememberStartDateChange() {
    let currentRememberStartDate =
      this.form.controls['rememberStartDate']?.value;
    let oldRememberStarDate = this.currentOriginalSyptom?.rememberStartDate;

    this.isRememberStartDateChanged =
      currentRememberStartDate !== oldRememberStarDate;

    if (!currentRememberStartDate) {
      this.form.controls['startDate'].setValue('');
      this.setStartDateChange();
    }

    this.clearStartDateComment();
    this.setFormChange();
  }

  /**
   * Verifica si ha habido un cambio en la fecha de inicio del síntoma.
   */
  setStartDateChange() {
    const currentStartDate = this.conciliationService.getFormatDate(
      this.form.controls['startDate']?.value
    );
    const transformInitDate = this.conciliationService.getFormatDate(
      this.currentOriginalSyptom?.startDate
    );
    const changeDate = moment(currentStartDate).format('DD-MM-YYYY');
    const originalDate = moment(transformInitDate).format('DD-MM-YYYY');

    this.isStartDateChanged = changeDate !== originalDate;
    this.clearStartDateComment();
    this.setFormChange();
  }

  /**
   * Limpia el campo de comentario de la occurrencia del síntoma si no ha habido cambios en la occurrencia,
   * y si no hay ningún comentario existente en la occurrencia del síntoma.
   */
  clearOccurrencyComment() {
    if (
      !this.isOccurrencyChanged &&
      !this.currentOriginalSyptom?.occurrencyComment
    ) {
      this.form.controls['occurrencyComment'].setValue('');
    }
    this.setCommentChange();
  }

  /**
   * Limpia el campo de comentario de la fecha de inicio del síntoma si no ha habido cambios en la fecha de inicio ni en el recuerdo de la fecha de inicio,
   * y si no hay ningún comentario existente en la fecha de inicio del síntoma.
   */
  clearStartDateComment() {
    if (
      !this.isRememberStartDateChanged &&
      !this.isStartDateChanged &&
      !this.currentOriginalSyptom?.startDateComment
    ) {
      this.form.controls['startDateComment'].setValue('');
    }
    this.setCommentChange();
  }

  /**
   * Verifica si ha habido un cambio en la fecha de finalización recordada.
   */
  setRememberFinishDateChange() {
    let currentRememberFinishDate =
      this.form.controls['rememberFinishDate']?.value;

    let oldRememberFinishDate = this.currentOriginalSyptom?.rememberFinishDate;

    this.isRememberFinishDateChanged =
      currentRememberFinishDate !== oldRememberFinishDate;
    if (!currentRememberFinishDate) {
      this.form.controls['finishDate'].setValue('');
      this.setFinishDateChange();
    }

    this.clearFinishDateComment();
    this.setFormChange();
  }

  /**
   * Verifica si ha habido un cambio en la fecha de finalización del síntoma.
   */
  setFinishDateChange() {
    const transformChangeDate = this.conciliationService.getFormatDate(
      this.form.controls['finishDate']?.value
    );
    const transformInitDate = this.conciliationService.getFormatDate(
      this.currentOriginalSyptom?.finishDate
    );
    const changeDate = moment(transformChangeDate).format('DD-MM-YYYY');
    const originalDate = moment(transformInitDate).format('DD-MM-YYYY');

    //Guarda si la fecha de finalización ha sido modificada
    this.isFinishDateChanged = changeDate !== originalDate;

    this.clearFinishDateComment();
    this.setFormChange();
  }

  isControlInvalid(controlName: string): boolean {
    const answersControl = this.form.controls['answers'] as FormGroup;
    return answersControl
      ? answersControl.controls[controlName].invalid &&
          answersControl.controls[controlName].touched
      : false;
  }

  /**
   * Limpia el campo de comentario de la fecha de finalización del síntoma si no ha habido cambios en la fecha de finalización ni en el recuerdo de la fecha de finalización,
   * y si no hay ningún comentario existente en la fecha de finalización del síntoma.
   */
  clearFinishDateComment() {
    if (
      !this.isRememberFinishDateChanged &&
      !this.isFinishDateChanged &&
      !this.currentOriginalSyptom?.finishDateComment
    ) {
      this.form.controls['finishDateComment'].setValue('');
    }
    this.setCommentChange();
  }

  /**
   * Maneja el cambio en la opción de "recordar que no se tomaron valores".
   */
  setRememberNoValuesTakenChange() {
    const rememberNoValuesTaken =
      this.form.controls['rememberNoValuesTaken']?.value;
    let rememberNoValuesTakenChange = rememberNoValuesTaken;

    let rememberNoValuesTakenInit =
      this.currentOriginalSyptom?.rememberNoValuesTaken;

    this.isRememberNoValuesTakenChanged =
      rememberNoValuesTakenChange !== rememberNoValuesTakenInit;

    if (rememberNoValuesTaken) {
      this.form.controls['size'].setValue(null);
      this.form.controls['intensity'].setValue(null);
      this.form.controls['decimalSize'].setValue(null);
      this.form.controls['intSize'].setValue(null);
      this.setIntensityChange();
    }

    this.clearRememberNoValuesTakenComment();
    this.setFormChange(); //Valida si el formulario ha sido modificado
  }

  clearRememberNoValuesTakenComment() {
    if (
      !this.isRememberNoValuesTakenChanged &&
      !this.currentOriginalSyptom?.rememberNoValuesTakenComment
    ) {
      this.form.controls['rememberNoValuesTakenComment'].setValue('');
    }
    this.setCommentChange();
  }

  setIsOccurrencyChange() {
    this.isOccurrencyChanged =
      this.form.controls['occurrency'].value !==
      this.currentOriginalSyptom.occurrency;
    if (!this.form.controls['occurrency'].value) {
      this.clearForm();
    } else {
      const occurrencyComment = this.form.controls['occurrencyComment'].value;
      this.createVerifiedSymptomForm();
      this.form.patchValue({ occurrency: true, occurrencyComment });
      if (
        !this.form.controls['rememberNoValuesTaken']?.value &&
        this.configuration?.showNoValuesTaken
      ) {
        this.form.controls['rememberNoValuesTaken'].setValue(false);
      }
    }
    this.markAsUntouched();
    this.resetChangeFlags();
    this.clearOccurrencyComment();
    this.setFormChange();
    this.setCommentChange();
  }

  /**
   * Restaura el estado de todos los indicadores de cambio a su valor predeterminado.
   *
   * Esta función es útil cuando necesitas restablecer múltiples indicadores que señalan
   * si ciertos elementos del formulario o configuraciones han cambiado.
   * Se puede usar para limpiar el estado antes de un nuevo ciclo de validación
   * o para reiniciar después de guardar cambios.
   *
   * Después de llamar a esta función, todos los indicadores de cambio se establecerán en `false`.
   */
  resetChangeFlags(): void {
    this.isIntensityChanged = false;
    this.isIntensityQuestionsChanged = false;
    this.isRememberStartDateChanged = false;
    this.isStartDateChanged = false;
    this.isRememberFinishDateChanged = false;
    this.isFinishDateChanged = false;
    this.isRememberNoValuesTakenChanged = false;
    this.isFormChanged = false;
    this.isCommentsChanged = false;
  }

  /**
   * Limpia los campos del formulario restableciéndolos a sus valores predeterminados o vacíos.
   * También activa la actualización del estado de cambio de comentario.
   */
  clearForm() {
    const intensityQuestionAnswers =
      this.symptomService.buildIntensityQuestionAnswers(this.configuration, []);
    this.form.patchValue({
      occurrency: this.form.controls['occurrency'].value,
      occurrencyComment: this.form.controls['occurrencyComment'].value,
      rememberStartDate: null,
      startDate: null,
      startDateComment: null,
      rememberFinishDate: null,
      finishDate: null,
      finishDateComment: '',
      intensity: null,
      intensityComment: '',
      intSize: null,
      decimalSize: null,
      size: null,
      sizeComment: '',
      rememberNoValuesTaken: null,
      rememberNoValuesTakenComment: '',
      intensityQuestionAnswers: intensityQuestionAnswers,
      intensityQuestionAnswersComment: '',
      answers: this.formBuilder.group(
        this.buildAnswers(intensityQuestionAnswers)
      ),
      changeReason: '',
    });
  }

  markAsUntouched() {
    for (let key of Object.keys(this.form.value)) {
      if (key !== 'occurrency' && key !== 'occurrencyComment') {
        this.form.controls[key].markAsUntouched();
      }
    }
  }

  //================ Paginación entre sintomas ==============================

  /**
   * Retrocede al síntoma anterior dentro del componente DetailSymptomComponent o a la sección anterior si se encuentra al inicio del síntoma actual.
   */
  previousSymptom(): void {
    // Obtiene el índice del acordeón activo y del síntoma actual
    let { activeAccordionTabIndex, currentSymptomIndex } =
      this.getCurrentSymptomIndex();

    // Obtiene el componente DetailSymptomComponent actual y la tabla de ocurrencias de síntomas
    const detailSymptomComponent = this.getCurrentDetailSymptomComponent();
    const symptomOcurrencesTable =
      detailSymptomComponent.symptomOcurrencesTable;

    // Obtiene los síntomas de la tabla correspondientes al acordeón activo
    let currentTableSymptoms =
      symptomOcurrencesTable[activeAccordionTabIndex].tableSymptoms;

    // Verifica si se encuentra al inicio del primer acordeón y del primer síntoma
    if (activeAccordionTabIndex === 0 && currentSymptomIndex === 0) {
      return this.paginationService.goToPreviousSection();
    } else if (currentSymptomIndex === 0) {
      // Retrocede al último síntoma del acordeón anterior si se encuentra al inicio del síntoma actual
      const previousTableSymptoms =
        symptomOcurrencesTable[activeAccordionTabIndex - 1].tableSymptoms;
      return this.paginationService.selectedSymptom(
        previousTableSymptoms[previousTableSymptoms.length - 1],
        activeAccordionTabIndex - 1,
        this.getCurrentConciliationComponent()
      );
    }

    // Retrocede al síntoma anterior dentro del mismo acordeón
    return this.paginationService.selectedSymptom(
      currentTableSymptoms[currentSymptomIndex - 1],
      activeAccordionTabIndex,
      this.getCurrentConciliationComponent()
    );
  }

  /**
   * Avanza al siguiente síntoma dentro del componente DetailSymptomComponent o a la siguiente sección si se encuentra al final del síntoma actual.
   */
  nextSymptom(): void {
    // Obtiene el índice del acordeón activo y del síntoma actual
    let { activeAccordionTabIndex, currentSymptomIndex } =
      this.getCurrentSymptomIndex();

    // Obtiene el componente DetailSymptomComponent actual y la tabla de ocurrencias de síntomas
    const detailSymptomComponent = this.getCurrentDetailSymptomComponent();
    const symptomOcurrencesTable =
      detailSymptomComponent.symptomOcurrencesTable;

    // Obtiene los síntomas de la tabla correspondientes al acordeón activo
    let tableSymptoms =
      symptomOcurrencesTable[activeAccordionTabIndex].tableSymptoms;

    /**  Verifica si se encuentra al final del último acordeón y del último síntoma
        Avanza a la siguiente sección si se encuentra al final del último síntoma
    */
    if (
      activeAccordionTabIndex + 1 === symptomOcurrencesTable.length &&
      currentSymptomIndex + 1 === tableSymptoms.length
    ) {
      return this.paginationService.nextSection(
        this.getCurrentConciliationComponent()
      );
    } else if (currentSymptomIndex + 1 === tableSymptoms.length) {
      // Avanza al primer síntoma del siguiente acordeón si se encuentra al final del síntoma actual
      return this.paginationService.selectedSymptom(
        symptomOcurrencesTable[activeAccordionTabIndex + 1].tableSymptoms[0],
        activeAccordionTabIndex + 1,
        this.getCurrentConciliationComponent()
      );
    }

    // Avanza al siguiente síntoma dentro del mismo acordeón
    return this.paginationService.selectedSymptom(
      tableSymptoms[currentSymptomIndex + 1],
      activeAccordionTabIndex,
      this.getCurrentConciliationComponent()
    );
  }

  getCurrentConciliationComponent(): ConciliationComponents {
    return this.currentSymptom.symptomType === SymptomType.GENERAL
      ? ConciliationComponents.SYMPTOM_GENERAL
      : ConciliationComponents.SYMPTOM_LOCAL;
  }

  /**
   * Obtiene el índice del síntoma actual dentro del componente DetailSymptomComponent y el índice del acordeón activo.
   * @returns Un objeto que contiene el índice del acordeón activo y el índice del síntoma actual.
   */
  getCurrentSymptomIndex() {
    const detailSymptomComponent = this.getCurrentDetailSymptomComponent();
    let symptomOccurrences = detailSymptomComponent.symptomOcurrencesTable;
    let activeAccordionTabIndex = 0;
    let currentSymptomIndex = 0;
    for (let [index, symptomOccurrence] of symptomOccurrences.entries()) {
      activeAccordionTabIndex = index; //Indice actual del accordion de sintomas
      currentSymptomIndex = symptomOccurrence.tableSymptoms.findIndex(
        (symptom: TableSymptom) => symptom.key === this.currentSymptom.key
      );
      if (currentSymptomIndex !== -1) break;
    }

    return { activeAccordionTabIndex, currentSymptomIndex };
  }

  //================ Control de cambios del formulario ==============================

  /**
   * Actualiza el estado de cambio de comentario del formulario del síntoma.
   * Construye los valores del formulario del síntoma y verifica si ha habido cambios en los comentarios del formulario en comparación con la instancia original del síntoma.
   */
  setCommentChange() {
    const verifiedSymptomInstance = this.buildFormValues(this.form.value, true);
    this.isCommentsChanged = this.conciliationService.isFormCommentChange(
      verifiedSymptomInstance,
      this.currentOriginalSyptom
    );
    this.setFormValidators();
  }

  /**
   * Actualiza el estado de cambio del formulario del síntoma.
   * Comprueba si algún componente del formulario ha sido modificado y actualiza el estado en consecuencia.
   */
  setFormChange() {
    const formPartChanges = [
      this.isIntensityChanged,
      this.isIntensityQuestionsChanged,
      this.isRememberStartDateChanged,
      this.isStartDateChanged,
      this.isRememberFinishDateChanged,
      this.isFinishDateChanged,
      this.isRememberNoValuesTakenChanged,
      this.isOccurrencyChanged,
    ];
    //Valida si algun componente del formulario ha sido modificado
    const isFormChanged = formPartChanges.some(
      (isChanged: boolean) => isChanged
    );
    this.isFormChanged = isFormChanged;
    this.setFormValidators();
  }

  //================ Validaciones del formulario ==============================
  setFormValidators() {
    this.setOccurrencyCommentValidator();
    this.setRangeValidator();
    this.setIntensityValidator();
    this.setSizeValidator();
    this.setSizeCommentValidator();
    this.setAdditionalQuestionsValidator();
    this.setAdditionalQuestionsCommentValidator();
    this.setRememberStartDateValidator();
    this.setStartDateValidator();
    this.setStartDateCommentValidator();
    this.setRememberFinishDateValidator();
    this.setFinishDateValidator();
    this.setFinishDateCommentValidator();
    this.setRememberNoValuesTakenValidator();
    this.setRememberNoValuesTakenCommentValidator();
    this.setChangeReasonValidator();
  }

  setOccurrencyValidator() {
    this.form.controls['occurrency'].setValidators([Validators.required]);
  }

  setOccurrencyCommentValidator() {
    const control =
      this.isOccurrencyChanged ||
      Boolean(this.currentOriginalSyptom?.occurrencyComment);
    this.setFormControlValidatorWithoutOccurrency('occurrencyComment', control);
  }

  setRangeValidator() {
    const rememberNoValuesTaken =
      this.form.controls['rememberNoValuesTaken']?.value;
    const showControl =
      this.configuration?.intensityType === this.RANGE &&
      this.shouldShowIntensity();
    this.setFormControlValidator(
      'intSize',
      !rememberNoValuesTaken && showControl
    );
    const showDecimalPlaces = Boolean(this.configuration?.decimalPlaces);
    this.setFormControlValidator(
      'decimalSize',
      !rememberNoValuesTaken && showControl && showDecimalPlaces
    );
  }

  setIntensityValidator() {
    const rememberNoValuesTaken =
      this.form.controls['rememberNoValuesTaken']?.value;
    const isMultipleChoice =
      this.configuration?.intensityType === this.MULTIPLE_CHOICE;
    const showControl = isMultipleChoice && this.shouldShowIntensity();
    this.setFormControlValidator(
      'intensity',
      !rememberNoValuesTaken && showControl
    );
  }

  setSizeValidator() {
    const rememberNoValuesTaken =
      this.form.controls['rememberNoValuesTaken']?.value;
    const isNumericSize =
      this.configuration?.intensityType === this.NUMERIC_VALUE;
    const showControl = isNumericSize && this.shouldShowIntensity();
    this.setFormControlValidator('size', !rememberNoValuesTaken && showControl);
  }

  setSizeCommentValidator() {
    const showControl =
      this.isIntensityChanged ||
      Boolean(this.currentOriginalSyptom?.sizeComment);
    this.setFormControlValidator('sizeComment', showControl);
  }

  setAdditionalQuestionsValidator() {
    const isIntensityQuestionsRequired =
      this.configuration?.isIntensityQuestionsRequired ?? false;
    const showControl =
      isIntensityQuestionsRequired && this.shouldShowIntensity();
    const intensityQuestionAnswers =
      this.form.controls['intensityQuestionAnswers']?.value ?? [];
    for (let i = 0; i < intensityQuestionAnswers.length; i++) {
      this.setFormControlValidator(`answer${i}`, showControl, true);
    }
  }

  setAdditionalQuestionsCommentValidator() {
    const showControl =
      this.isIntensityQuestionsChanged ||
      Boolean(this.currentOriginalSyptom?.intensityQuestionAnswersComment);
    this.setFormControlValidator(
      'intensityQuestionAnswersComment',
      showControl
    );
  }

  setRememberStartDateValidator() {
    const isStartDateRequired =
      this.configuration?.isStartDateRequired &&
      this.symptomsReconciliationConfig?.allowOcurencyDateReconciliation;
    this.setFormControlValidator('rememberStartDate', isStartDateRequired);
  }

  setStartDateValidator() {
    const isStartDateRequired =
      this.configuration?.isStartDateRequired ?? false;
    const showRememberStartDate = this.form.controls['rememberStartDate'].value;
    this.setFormControlValidator(
      'startDate',
      isStartDateRequired && showRememberStartDate
    );
  }

  setStartDateCommentValidator() {
    const showControl =
      this.isRememberStartDateChanged ||
      this.isStartDateChanged ||
      Boolean(this.currentOriginalSyptom?.startDateComment);
    this.setFormControlValidator('startDateComment', showControl);
  }

  setRememberFinishDateValidator() {
    const isFinishDateRequired =
      this.configuration?.isFinishDateRequired &&
      this.symptomsReconciliationConfig?.allowOcurencyDateReconciliation;
    this.setFormControlValidator('rememberFinishDate', isFinishDateRequired);
  }

  setFinishDateValidator() {
    const isFinishDateRequired =
      this.configuration?.isFinishDateRequired ?? false;
    const showRememberFinishDate = this.form.controls['finishDate'].value;
    this.setFormControlValidator(
      'finishDate',
      isFinishDateRequired && showRememberFinishDate
    );
  }

  setFinishDateCommentValidator() {
    const showControl =
      this.isRememberFinishDateChanged ||
      this.isFinishDateChanged ||
      Boolean(this.currentOriginalSyptom?.finishDateComment);
    this.setFormControlValidator('finishDateComment', showControl);
  }

  setRememberNoValuesTakenValidator() {
    const showNoValuesTaken = this.configuration?.showNoValuesTaken ?? false;
    this.setFormControlValidator('rememberNoValuesTaken', showNoValuesTaken);
  }

  setRememberNoValuesTakenCommentValidator() {
    const rememberNoValuesTakenCommentExist = Boolean(
      this.currentOriginalSyptom?.rememberNoValuesTakenComment
    );
    const showControl =
      this.isRememberNoValuesTakenChanged || rememberNoValuesTakenCommentExist;
    this.setFormControlValidator('rememberNoValuesTakenComment', showControl);
  }

  setChangeReasonValidator() {
    const showControl =
      (this.isFormChanged || this.isCommentsChanged) &&
      this.currentSymptom.isEdition;
    this.setFormControlValidatorWithoutOccurrency('changeReason', showControl);
  }

  /**
   * Establece o elimina validadores para un control específico dentro del formulario,
   * con la opción de aplicar validaciones a un subgrupo si `isAdditionalQuestions` es verdadero.
   *
   * @param key - El nombre del control de formulario para el cual se está configurando el validador.
   * @param showControl - Un booleano que indica si se debe mostrar el control. Si es falso, el validador se elimina.
   * @param isAdditionalQuestions - Un booleano opcional que, si es verdadero, indica que el control pertenece al subgrupo 'answers'.
   */
  setFormControlValidator(
    key: string,
    showControl: boolean,
    isAdditionalQuestions?: boolean
  ) {
    const form = isAdditionalQuestions
      ? (this.form.controls['answers'] as FormGroup)
      : this.form;
    if (!this.form.controls['occurrency'].value || !showControl) {
      form.controls[key].clearValidators();
    } else {
      form.controls[key].addValidators([Validators.required]);
    }
    form.controls[key].updateValueAndValidity();
  }

  /**
   * Establece o elimina validadores para un control específico dentro del formulario.
   * A diferencia de `setFormControlValidator`, no tiene en cuenta la existencia del control 'occurrency'.
   *
   * @param key - El nombre del control de formulario para el cual se está configurando el validador.
   * @param showControl - Un booleano que indica si se debe mostrar el control. Si es falso, el validador se elimina.
   */
  setFormControlValidatorWithoutOccurrency(key: string, showControl: boolean) {
    if (showControl) {
      this.form.controls[key].addValidators([Validators.required]);
    } else {
      this.form.controls[key].clearValidators();
    }
    this.form.controls[key].updateValueAndValidity();
  }
  setOccurrencyChangeRules() {
    logger.debug(
      'setOccurrencyChangeRules',
      this.symptomsReconciliationConfig,
      this.currentSymptom
    );
    this.allowAddRecords = this.symptomsReconciliationConfig?.allowAddRecords;
    this.allowRemoveRecords =
      this.symptomsReconciliationConfig?.allowRemoveRecords;

    if (!this.currentOriginalSyptom.occurrency && !this.allowAddRecords) {
      this.form.patchValue({ occurrency: false });
    }

    this.isAllowedToChangeOccurrency = this.allowChangeSymptomOccurrency();
  }

  allowChangeSymptomOccurrency(): boolean {
    let shouldAllowChange = false;
    if (this.currentOriginalSyptom.occurrency) {
      shouldAllowChange =
        (this.currentOriginalSyptom?.occurrency === false &&
          this.allowAddRecords) ||
        (this.currentOriginalSyptom?.occurrency === true &&
          this.allowRemoveRecords);
    } else {
      shouldAllowChange = this.allowAddRecords;
    }
    if (!shouldAllowChange) {
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          'conciliation.cannotChangeOccurrency'
        ),
      });
    }
    return shouldAllowChange;
  }

  isAllowedToChangeRememberStartDate(): boolean {
    if (
      (this.currentSymptom.symptomInstance?.rememberStartDate === null ||
        this.currentSymptom.symptomInstance?.rememberStartDate === undefined) &&
      !this.isAllowedToChangeDate
    ) {
      return false;
    } else {
      return true;
    }
  }

  isAllowedToChangeRememberFinishDate(): boolean {
    if (
      (this.currentSymptom.symptomInstance?.rememberFinishDate === null ||
        this.currentSymptom.symptomInstance?.rememberFinishDate ===
          undefined) &&
      !this.isAllowedToChangeDate
    ) {
      return false;
    } else {
      return true;
    }
  }
}
