import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Dictionary } from '../dictionary';
import { DictionaryService } from '../dictionary.service';
import { CreateConfDictionaryInput } from 'src/app/services/trialpal.types';

const logger = new Logger('tp2-logger-DictionaryAddEditPage');

@Component({
  selector: 'app-edit-add-dictionary',
  templateUrl: './edit-add-dictionary.component.html',
  styleUrls: ['./edit-add-dictionary.component.scss'],
})
export class EditAddDictionaryComponent implements OnInit {
  dictionary: any;
  dictionarys: any[] = [];
  idItem: string = '';
  key: string = '';
  spanish: string = '';
  english: string = '';
  projectId: string = '';
  isEdit: boolean = false;
  isExistKey: boolean = false;
  isHtml: boolean = false;
  oldKey: string = '';

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private trialpalService: TrialpalService,
    private dictionaryService: DictionaryService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    if (this.config.data) {
      const data = this.config.data.dictionary;
      this.dictionary = this.config.data.dictionary;
      this.idItem = data?.id;
      this.key = data?.key;
      this.oldKey = this.key;
      this.spanish = data?.spanish;
      this.english = data?.english;
      this.projectId = this.config.data?.projectId;
      this.isEdit = this.config.data?.isEdit;
      this.dictionarys = this.config.data.dictionarys;
      this.isHtml = this.config.data?.isHtml || false;
    }
  }

  cancel() {
    this.ref.close();
  }

  async saveItem(form: NgForm) {
    this.isExistKey = false;

    this.removeSpaces();
    this.confirmErrors(form);
    if (form.valid) {
      if (this.isEdit) {
        const obj: any = {
          projectId: this.projectId,
          id: this.dictionary.id,
          key: this.key,
          spanish: this.spanish,
          english: this.english,
          isHtml: this.isHtml,
          _lastUser: this.auth.getUsername(),
          _version: this.dictionary._version,
        };

        if (this.validateKey('', obj)) {
          this.isExistKey = false;

          await this.updatedItemDictionary(obj)
            .then(() => {
              this.ref.close([obj, this.isEdit]);
            })
            .catch(() => {
              return;
            });
        } else {
          this.isExistKey = true;
        }
      } else if (this.validateKey(this.key.trim())) {
        this.isExistKey = false;
        const obj: CreateConfDictionaryInput = {
          projectId: this.projectId,
          key: this.key,
          spanish: this.spanish,
          isHtml: this.isHtml,
          english: this.english,
          _lastUser: this.auth.getUsername(),
        };

        await this.createdItemDictionary(obj).then(() => {
          this.ref.close([obj, this.isEdit]);
        });
      } else {
        this.isExistKey = true;
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.key = this.key?.trim();
    this.spanish = this.spanish?.trim();
    this.english = this.english?.trim();
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(this.key, 'txt_key', form);
    this.trialpalService.validateSpaces(this.spanish, 'txt_spanish', form);
    this.trialpalService.validateSpaces(this.english, 'txt_english', form);
  }

  private async createdItemDictionary(obj: CreateConfDictionaryInput) {
    this.trialpalService.showSpinner(
      'dictionary.translationSingular',
      'CREATE'
    );
    await this.dictionaryService
      .createConfDictionaryInput(obj)
      .then(async (data) => {
        this.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'dictionary.messageSuccessCreated.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'dictionary.messageSuccessCreated.detail'
          ),
        });
      })
      .catch((error) => {
        this.trialpalService.showServiceError('dictionary.createError', error);
        logger.error('error during createConfDictionaryInput', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  private async updatedItemDictionary(dictionary: Dictionary) {
    this.trialpalService.showSpinner('dictionary.translationPlural', 'UPDATE');
    await this.dictionaryService
      .updateDictionary(dictionary)
      .then(async (data) => {
        this.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'dictionary.messageSuccessUpdated.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'dictionary.messageSuccessUpdated.detail'
          ),
        });
      })
      .catch((error) => {
        logger.error('Error during updateDictionary', error);

        this.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'dictionary.updateError'
          ),
          detail: this.trialpalService.translateService.instant(
            'dictionary.errorVersion'
          ),
        });
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  private validateKey(key: string, obj?: any): boolean {
    let isValid: boolean = false;
    if (key) {
      const x = this.dictionarys.some(
        (d) =>
          d.key.toLowerCase().replace(/\s+/g, '') ===
          key.toLowerCase().replace(/\s+/g, '')
      );
      isValid = !x;
    }
    if (obj) {
      if (
        this.key.toLowerCase().replace(/\s+/g, '') ===
        this.oldKey.toLowerCase().replace(/\s+/g, '')
      ) {
        isValid = true;
      } else {
        const y = this.dictionarys.some(
          (d) =>
            d.key.toLowerCase().replace(/\s+/g, '') ===
            this.key.toLowerCase().replace(/\s+/g, '')
        );
        isValid = !y;
      }
    }
    return isValid;
  }
  onSearchChange(event: any) {
    if (event) {
      this.isExistKey = false;
    }
  }
}
