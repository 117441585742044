import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { TP2Permission } from 'src/app/services/user-permissions-service';
import { Roles } from 'src/app/shared/global.variables';
import { AddEditSubjectsComponent } from './add-edit-subjects/add-edit-subjects.component';
import { DetailSubjectComponent } from './detail-subject/detail-subject.component';
import { ListSubjectsComponent } from './list-subjects/list-subjects.component';

const routes: Routes = [
  {
    path: ':subjectId/detail',
    component: DetailSubjectComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin, TP2Permission.SubjectRead],
    },
  },
  {
    path: ':projectId',
    component: ListSubjectsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        Roles.Admin,
        TP2Permission.SubjectList,
        TP2Permission.SubjectCreate,
      ],
    },
  },
  {
    path: ':projectId/:action',
    component: AddEditSubjectsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [TP2Permission.SubjectCreate, TP2Permission.SubjectUpdate],
    },
  },
  {
    path: ':projectId/:action/siteId/:siteId/informedInstanceId/:informedInstanceId',
    component: AddEditSubjectsComponent, //Para usuarios creados desde el consentimiento
    canActivate: [AuthGuard],
  },
  {
    path: ':subjectId/detail/:eDiaryPhaseInstanceId/conciliation',
    loadChildren: () =>
      import('./../conciliation/conciliation.module').then(
        (m) => m.ConciliationModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: [TP2Permission.SubjectDiaryAssesment],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubjectsRoutingModule {}
