import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import {
  ConfSymptom,
  IntensityType,
  SymptomType,
} from 'src/app/modules/ediary/ediary.types';
import { ConfReport } from 'src/app/modules/report/report.types';
import {
  CreateSymptomInstanceInput,
  SymptomOccurrencyInput,
  UpdateSymptomInstanceInput,
} from 'src/app/modules/subjects/subjects.types';
import { InstanceState } from 'src/app/services/trialpal.types';
import { ReportExecutionService } from '../../services/report-executation.service';
const logger = new Logger('symptomsReport');
@Component({
  selector: 'app-symptoms',
  templateUrl: './symptoms.component.html',
  styleUrls: ['./symptoms.component.scss'],
})
export class SymptomsComponent implements OnInit {
  @ViewChild('form') form!: NgForm; //Referencia del formulario
  booleanOptions: any = [];
  confReport: ConfReport = {} as ConfReport;
  symptomOccurrences: ConfSymptomOccurrency[] = [];
  currentConfSymptomIndex: number = 0;
  currentDefaultSymptomInstance:
    | CreateSymptomInstanceInput
    | UpdateSymptomInstanceInput = {
    id: '',
    order: 1,
    subjectId: '',
    rememberStartDate: null,
    rememberFinishDate: null,
    startDate: null,
    finishDate: null,
    type: SymptomType.GENERAL,
    state: InstanceState.ON_GOING,
    size: null,
    intensityQuestionAnswers: [],
    isOtherSymptom: false,
    intensity: null,
    rememberNoValuesTaken: false,
    _lastUser: '',
    _version: 1,
  };
  changeReason: string = '';

  oldSymptomOccurrences: ConfSymptomOccurrency[] = [];

  constructor(public reportExecutionService: ReportExecutionService) {}

  async ngOnInit(): Promise<void> {
    const symptomLoadingMessage =
      this.reportExecutionService.translateTransform(
        'report.execution.loadingSymptoms'
      );
    this.reportExecutionService.showSpinner(symptomLoadingMessage);
    this.confReport = this.reportExecutionService.confReport;
    this.booleanOptions = this.reportExecutionService.booleanOptions;
    this.reportExecutionService.confSymptoms = await this.getConfSymptoms();
    await this.buildSymptomOcurrencies();
    this.reportExecutionService.hideSpinner();
  }

  async getConfSymptoms(): Promise<ConfSymptom[]> {
    return this.reportExecutionService.getConfSymptomsByConfReportId(
      this.confReport.id
    );
  }

  //Funcion que se encarga de construir el arreglo con las occurencias y los sintomas asociados
  async buildSymptomOcurrencies(): Promise<void> {
    this.symptomOccurrences = [];
    for (let confSymptom of this.reportExecutionService.confSymptoms) {
      //Obtiene si tiene una ocurrencia previamente guardada
      const occurrency = this.getOccurrency(confSymptom);
      //obtiene si tiene un sintoma previamente creado asociado a la occurencia
      const symptomInstance = await this.getSymptomInstance(
        occurrency,
        confSymptom
      );

      //Crea un arreglo con los datos de los sintomas
      const symptomOcurrency: ConfSymptomOccurrency = {
        label: this.reportExecutionService.getSymptomLabel(confSymptom),
        occurrency: occurrency?.occurrency ?? false,
        confSymptomId: confSymptom?.id,
        symptomInstanceId: symptomInstance?.id ?? '',
        isEdition: !symptomInstance?.id?.includes(confSymptom.id),
        confSymptom: confSymptom,
        symptomInstance: symptomInstance,
        symptomInstanceInput: structuredClone(symptomInstance),
      };
      this.symptomOccurrences.push(symptomOcurrency);
    }
    this.oldSymptomOccurrences = JSON.parse(
      JSON.stringify(this.symptomOccurrences)
    );
  }

  //Obtiene la ocurrencia previamente guardada en la instancia de reporte
  getOccurrency(confSymptom: ConfSymptom): SymptomOccurrencyInput | undefined {
    return this.reportExecutionService.reportInstance.symptomOcurrencies?.find(
      (occurrency: SymptomOccurrencyInput) => {
        return occurrency.confSymptomId === confSymptom.id;
      }
    );
  }

  async getSymptomInstance(
    occurrency: SymptomOccurrencyInput | undefined,
    confSymptom: ConfSymptom
  ): Promise<CreateSymptomInstanceInput | UpdateSymptomInstanceInput> {
    //Si la ocurrencia es true, obtiene el sintoma asociado desde la base de datos, si no crea uno desde cero
    if (occurrency?.occurrency && occurrency.symptomInstanceId) {
      return this.reportExecutionService.getSymptomInstanceById(
        occurrency.symptomInstanceId
      );
    } else {
      return {
        ...this.currentDefaultSymptomInstance,
        symptom: confSymptom.symptom,
        order: confSymptom.order,
        reportInstanceId: this.reportExecutionService.reportInstance.id,
        confSymptomId: confSymptom?.id,
        id: `${new Date().getTime() + confSymptom.id}`, //Agrega un id temporal
        type: confSymptom.type,
        subjectId: this.reportExecutionService.subjectId,
        _lastUser: this.reportExecutionService.getUsername(),
      };
    }
  }

  nextSymptomInstance(
    confSymptomOccurrency: ConfSymptomOccurrency,
    occurrency: boolean
  ): void {
    this.updateSymptomInstance(confSymptomOccurrency, occurrency);
    //Valida si aún tiene sintomas que recorrer, si no pasa a la siguiente pagina
    if (this.currentConfSymptomIndex !== this.symptomOccurrences.length - 1) {
      this.currentConfSymptomIndex += 1;
    } else {
      this.updateSymptomOccurrences();
      this.reportExecutionService.goToNextPage();
    }
  }

  previousSymptomInstance(occurrency: ConfSymptomOccurrency): void {
    occurrency.symptomInstanceInput = structuredClone(
      occurrency.symptomInstance
    );
    this.currentConfSymptomIndex = this.currentConfSymptomIndex - 1;
  }

  //Función que se encarga de actualizar la información del sintoma y su ocurrencia
  updateSymptomInstance(
    confSymptomOccurrency: ConfSymptomOccurrency,
    occurrency: boolean
  ) {
    //Actualiza la ocurrencia del sintoma
    confSymptomOccurrency.occurrency = occurrency;

    //Se encarga de actualizar la instancia de sintomas con los ultimos datos
    confSymptomOccurrency.symptomInstance = structuredClone(
      confSymptomOccurrency.symptomInstanceInput
    );

    //Actualiza cual fueron los cambios en la instancia de ocurrencias
    this.updateReportInstanceChangeReason(confSymptomOccurrency, occurrency);
    this.oldSymptomOccurrences = JSON.parse(
      JSON.stringify(this.symptomOccurrences)
    );
  }

  updateReportInstanceChangeReason(
    confSymptomOccurrency: ConfSymptomOccurrency,
    occurrency: boolean
  ) {
    const isReportInstanceEdition =
      this.reportExecutionService.isReportInstanceEdition;
    const isOccurrencyChange = this.isOccurrencyChange(
      confSymptomOccurrency,
      occurrency
    );

    if (isOccurrencyChange && isReportInstanceEdition) {
      this.reportExecutionService.reportInstance._changeReason =
        this.changeReason;
    }
  }

  //Función que se encarga de actualizar la ocurrencias en la instancia de reporte
  updateSymptomOccurrences() {
    this.reportExecutionService.reportInstance.symptomOcurrencies = [];
    this.reportExecutionService.symptomInstances = [];

    for (const occurrency of this.symptomOccurrences) {
      const { symptomInstance, confSymptom } = occurrency;
      this.reportExecutionService.reportInstance.symptomOcurrencies?.push({
        confSymptomId: occurrency.confSymptomId,
        occurrency: occurrency.occurrency,
        order: occurrency.confSymptom.order,
        symptomInstanceId: occurrency.symptomInstanceId,
        symptomRecordLogId: null,
      });

      this.saveSymptomInstance(symptomInstance, confSymptom);

      logger.debug('Symptom Save -->', occurrency);
    }
  }

  saveSymptomInstance(
    symptomInstance: CreateSymptomInstanceInput | UpdateSymptomInstanceInput,
    confSymptom: ConfSymptom
  ) {
    const { startDate, finishDate } = symptomInstance;

    symptomInstance._lastUser = this.reportExecutionService.getUsername();

    symptomInstance.startDate =
      this.reportExecutionService.transformDate(startDate);

    symptomInstance.finishDate =
      this.reportExecutionService.transformDate(finishDate);

    if (confSymptom.intensityType === IntensityType.MULTIPLE_CHOICE) {
      symptomInstance.size = 0;
    }

    this.reportExecutionService.symptomInstances.push(symptomInstance);
  }

  isOccurrencyChange(
    occurrency: ConfSymptomOccurrency,
    occurrencyValue: boolean
  ) {
    const oldOccurrency = this.oldSymptomOccurrences.find(
      (_occurrency: ConfSymptomOccurrency) =>
        _occurrency.confSymptomId === occurrency.confSymptomId
    );

    return occurrencyValue !== oldOccurrency?.occurrency;
  }
}

export interface ConfSymptomOccurrency {
  label: string;
  occurrency: boolean;
  confSymptomId: string;
  symptomInstanceId: string;
  confSymptom: ConfSymptom;
  isEdition: boolean;
  symptomInstance: CreateSymptomInstanceInput | UpdateSymptomInstanceInput;
  symptomInstanceInput: CreateSymptomInstanceInput | UpdateSymptomInstanceInput;
}
