import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { Intensity, Symptom } from 'src/app/modules/ediary/ediary.types';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { TP2Entites } from 'src/app/shared/global.variables';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';
import { CardOrTableService } from '../../../../services/card-or-table.service';
import {
  TPTableData,
  TypeInput,
} from '../../../../shared/components/tptable/tpTableData';
import { SubjectsService } from '../../subjects.service';
import { SymptomRecordLogBysymptomInstanceIdQuery } from '../../subjects.types';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';

const logger = new Logger('tp2-logger-SymptomsPage');

@Component({
  selector: 'app-symptom-subject',
  templateUrl: './symptom-subject.component.html',
  styleUrls: ['./symptom-subject.component.scss'],
})
export class SymptomSubjectComponent implements OnInit, OnChanges {
  @Input() timeZoneOffset = 0;
  @Input() isOpened: boolean = false;
  symptomRecords: any = [];
  symptoms: any[] = [];
  detailsSymptom: any;
  symptomName = '';
  details: boolean = false;
  display: boolean = false;
  tableViewMode: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  dataCard: any[] = [];
  showSpinner: boolean = true;
  DETAIL_SUBJECT_SYMPTOM_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_SYMPTOM_TOUR;

  constructor(
    private subjectsService: SubjectsService,
    private activatedRoute: ActivatedRoute,
    public datePipe: DatePipe,
    private trialpalService: TrialpalService,
    public translateService: TranslateService,
    public dialogService: DialogService,
    private transformDatePipe: TransformDatePipe,
    private dictionaryPipe: DictionaryPipe,
    private readonly authService: AuthService,
    public cardOrTableService: CardOrTableService,
    private pageTourService: PageTourService
  ) {}
  async ngOnInit(): Promise<void> {
    try {
      this.showSpinner = true;
      const subjectId = this.activatedRoute.snapshot.params.subjectId;
      await this.getSymptomsBySubjectId(subjectId);
      this.buildCardsAndTable();
      this.showSpinner = false;
      this.startTour();
    } catch (exception) {
      this.buildCardsAndTable();
      this.showSpinner = false;
      this.startTour();
    }
  }

  ngOnChanges(): void {
    this.startTour();
  }

  buildCardsAndTable() {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };
    if (this.symptoms && this.symptoms.length > 0) {
      this.dataTable = this.buildTPTableData(this.symptoms);
      this.buildCards(this.symptoms);
    }
  }
  buildCards(symptoms: any) {
    this.dataCard = [];
    for (let symptom of symptoms) {
      this.dataCard.push(this.buildTPCardData(symptom));
    }
  }

  async getSymptomsBySubjectId(subjectId: string) {
    const symptomInstances =
      await this.subjectsService.symptomInstanceBySubjectId(subjectId);
    logger.debug('symptomInstanceBySubjectId response', symptomInstances);

    if (symptomInstances.items) {
      this.symptoms = symptomInstances.items.filter((s: any) => !s._deleted);
      this.symptoms = this.subjectsService.orderInstancesDescending(
        this.symptoms
      );
    }
  }

  getSymptomDetail(p: any): void {
    this.detailsSymptom = p;
    if (p.dayInstanceId) {
      //Solo se muestra el detalle si el síntoma es reportado por el diario.
      this.symptomName =
        this.dictionaryPipe.transform(
          this.detailsSymptom?.confSymptom?.symptomLabel
        ) ||
        (this.detailsSymptom.symptom === Symptom.OTHER
          ? this.detailsSymptom.whichOtherSymptom
          : this.trialpalService.translateService.instant(
              'symptom.enums.symptoms.' + this.detailsSymptom.symptom
            ));
      this.getSymptomRecordLogs(this.detailsSymptom.id);
    }
  }

  getSymptomRecordLogs(id: any): any {
    this.subjectsService
      .symptomRecordLogBysymptomInstanceId(id)
      .then((response: SymptomRecordLogBysymptomInstanceIdQuery) => {
        logger.debug('symptomRecordLogBysymptomInstanceId response', response);
        if (response.items) {
          this.symptomRecords = response.items;
          for (const symptom of this.symptomRecords) {
            this.subjectsService
              .getDayInstance(symptom.dayInstanceId)
              .then((response2) => {
                symptom.dayInstance = response2;
              });
          }
          this.details = true;
          this.showDialog();
        }
      })
      .catch((error) => {
        logger.error('symptomInstanceBySubjectId error', error);
        this.trialpalService.showServiceError(
          'symptom.actions.listSubjectSymptomsRecords',
          error
        );
      });
  }

  // Helper function to calculate color intensity
  getColorIntensity(intensity: Intensity): string {
    if (
      intensity === Intensity.HIGH ||
      intensity === Intensity.LIFE_THREATENING
    ) {
      return 'red';
    } else if (intensity === Intensity.MEDIUM) {
      return 'orange';
    } else if (intensity === Intensity.LOW) {
      return 'green';
    } else {
      return 'gray';
    }
  }

  // Original buildTPCardData function with the extracted colorIntensity
  buildTPCardData(symptomInstance: any): TPCardData {
    let [startDate, finishDate] = this.getSymptomDates(symptomInstance);
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    const colorIntensity = this.getColorIntensity(symptomInstance.intensity);

    return {
      ...symptomInstance,
      data: symptomInstance,
      header: this.getSymptomHeader(symptomInstance),
      section1: this.getCompleteDateByInstance(symptomInstance),
      icon1: 'pi pi-check-circle',
      section1ToolTip: this.trialpalService.translateService.instant(
        'subject.reportedDate'
      ),

      section2: startDate !== '' ? startDate : undefined,
      icon2: startDate !== '' ? 'pi pi-calendar' : '',
      section2ToolTip: this.trialpalService.translateService.instant(
        'general.configurations.enums.startDate'
      ),

      section3: finishDate !== '' ? finishDate : undefined,
      icon3: finishDate !== '' ? 'pi pi-calendar-times' : '',
      section3ToolTip: this.trialpalService.translateService.instant(
        'general.configurations.enums.endDate'
      ),

      section4: this.trialpalService.translateService.instant(
        'symptom.enums.symptomTypes.' + symptomInstance.type
      ),
      icon4: 'pi pi-info-circle',
      section4ToolTip: this.trialpalService.translateService.instant(
        'general.configurations.enums.type'
      ),

      state: this.getSymptomIntensity(symptomInstance),
      stateToolTip: this.trialpalService.translateService.instant(
        'general.configurations.enums.intensity'
      ),
      styleColorState: colorIntensity,
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      showAuditButton,
      entity,
      cardIsButton: true,
      objectAction: symptomInstance.name,
      entityAction:
        this.trialpalService.translateService.instant('symptom.entity'),
    };
  }

  getEntityAndAuditButton(): any[] {
    let entity = '';
    let showAuditButton = false;
    if (this.authService.isAdmin() || this.authService.isInvestigator()) {
      entity = TP2Entites.SYMPTOMINSTANCE;
      showAuditButton = true;
    }

    return [entity, showAuditButton];
  }

  getSymptomDates(symptom: any) {
    let startDate = '';
    if (symptom.rememberStartDate === true && symptom.startDate) {
      startDate = this.transformDatePipe.transform(symptom.startDate);
    }
    let finishDate = '';
    if (symptom.rememberFinishDate === true && symptom.finishDate) {
      finishDate = this.transformDatePipe.transform(symptom.finishDate);
    }

    return [startDate, finishDate];
  }

  getSymptomHeader(symptom: any) {
    return (
      this.dictionaryPipe.transform(symptom.confSymptom?.symptomLabel) ||
      (symptom.symptom === Symptom.OTHER
        ? symptom.whichOtherSymptom
        : this.trialpalService.translateService.instant(
            'symptom.enums.symptoms.' + symptom.symptom
          ))
    );
  }

  buildTPTableData(symptomsInstances: any): TPTableData {
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    let newSymptomInstance = symptomsInstances.map((symptomInstance: any) => {
      let [startDate, finishDate] = this.getSymptomDates(symptomInstance);
      return {
        data: symptomInstance,
        entity,
        completedDate: this.getCompleteDateByInstance(symptomInstance),
        name: this.getSymptomHeader(symptomInstance),
        intensity: this.getSymptomIntensity(symptomInstance),
        type: symptomInstance.type
          ? this.trialpalService.translateService.instant(
              'symptom.enums.symptomTypes.' + symptomInstance.type
            )
          : 'N/A',
        startDate: startDate || 'N/A',
        finishDate: finishDate || 'N/A',
      };
    });

    return {
      valueTable: newSymptomInstance,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: ['name', 'intensity', 'type'],
      customButton1: showAuditButton
        ? {
            icon: 'pi pi-file',
          }
        : null,
      menuOptions: [],
      cols: [
        {
          header: this.trialpalService.translateService.instant(
            'symptominstance.entityCL'
          ),
          type: TypeInput.TEXT,
          field: 'name',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'symptominstance.type'
          ),
          type: TypeInput.TEXT,
          field: 'type',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'symptominstance.intensity'
          ),
          type: TypeInput.TEXT,
          field: 'intensity',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.reportedDate'
          ),
          type: TypeInput.TEXT,
          field: 'completedDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },

        {
          header: this.trialpalService.translateService.instant(
            'symptominstance.startDate'
          ),
          type: TypeInput.TEXT,
          field: 'startDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'symptominstance.endDate'
          ),
          type: TypeInput.TEXT,
          field: 'finishDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
      ],
    };
  }
  /**
   * metodo para calcular le estado de un sintoma segun su configuracion
   * @param symptomInstance instancia del sintoma que se esta evaluando
   * @returns undefined si la configuracion indica que no es requerio o se marca por defecto
   */
  private getSymptomIntensity(symptomInstance: any): string | undefined {
    if (
      symptomInstance.confSymptomId &&
      symptomInstance.confSymptom &&
      !symptomInstance.confSymptom.isIntensityRequired
    ) {
      return undefined;
    } else if (
      symptomInstance.isOtherSymptom &&
      symptomInstance.intensity === Intensity.NONE
    ) {
      return undefined;
    } else {
      return this.trialpalService.translateService.instant(
        'symptom.enums.intensityTypes.' + symptomInstance.intensity
      );
    }
  }
  showDialog(): any {
    this.display = true;
  }

  getCompleteDateByInstance(symptomInstance: any) {
    if (symptomInstance?.dayInstance?.completedDate)
      return this.transformDatePipe.transformToSiteHour(
        symptomInstance?.dayInstance?.completedDate,
        this.timeZoneOffset
      );

    if (symptomInstance?.reportInstance?.completeDate)
      return this.transformDatePipe.transformToSiteHour(
        symptomInstance?.reportInstance?.completeDate,
        this.timeZoneOffset
      );

    return 'N/A';
  }
  async startTour() {
    if (!this.isOpened || this.showSpinner) return;
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
    this.pageTourService.initiateTour(
      PageTourDataType.DETAIL_SUBJECT_SYMPTOM_TOUR
    );
  }
}
