<div class="summary">
  <div class="p-grid p-col-12 p-p-0">
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        confMedicalAttention?.showType && (oldValue?.type || verified?.type)
      "
    >
      <span id="lbl_route" name="lbl_route"
        >{{ 'medicalattentioninstance.type' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="oldValue.type | textNa: verified.type:'':true"
      ></div>
    </div>

    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="confMedicalAttention?.mode !== 'NO_REASON'"
    >
      <span id="lbl_reason" name="lbl_reason" *ngIf="changReason"
        >{{ 'medicalattentioninstance.reason' | translate }}:
      </span>
      <div
        class="p-ml-1"
        *ngIf="
          confMedicalAttention?.mode === 'OPEN_REASON' &&
          oldValue.reason !== verified.reason
        "
        [innerHTML]="oldValue.reason | textNa: verified.reason:'':true"
      ></div>
      <div
        class="p-ml-1"
        *ngIf="
          confMedicalAttention?.mode === 'SYMPTOM_BINDING' &&
          oldValue?.symptomsInstances !== verified?.symptomsInstances
        "
        [innerHTML]="
          oldValue.symptomsInstances
            | textNa: verified.symptomsInstances:'':true
        "
      ></div>
    </div>
    <div
      class="p-d-flex p-col-12 p-jc-between"
      *ngIf="
        confMedicalAttention?.showHospitalization &&
        (![undefined, null].includes(oldValue?.wasHospitalized) ||
          ![undefined, null].includes(verified?.wasHospitalized))
      "
    >
      <span id="lbl_wasHospitalized" name="lbl_wasHospitalized">
        {{ 'medicalattentioninstance.wasHospitalized' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.wasHospitalized
            | textNa: verified.wasHospitalized:'boolean':true
        "
      ></div>
    </div>
    <div
      *ngIf="
        confMedicalAttention?.showRememberStartDate &&
        (![undefined, null].includes(oldValue.rememberStartDate) ||
          ![undefined, null].includes(verified.rememberStartDate))
      "
      class="p-d-flex p-col-12 p-jc-between"
    >
      <span id="lbl_startDate" name="lbl_startDate">
        {{ 'symptominstance.startDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.rememberStartDate
            | textNa: verified.rememberStartDate:'boolean':true
        "
      ></div>
    </div>
    <div
      *ngIf="
        confMedicalAttention?.showRememberStartDate &&
        (oldValue.startDate || verified.startDate)
      "
      class="p-d-flex p-col-12 p-jc-between"
    >
      <span id="lbl_temperatureDate" name="lbl_temperatureDate">
        {{ 'symptominstance.startDate' | translate }}:
      </span>

      <div
        class="p-ml-1"
        [innerHTML]="oldValue.startDate | textNa: verified.startDate:'':true"
      ></div>
    </div>

    <div
      *ngIf="
        confMedicalAttention?.showRememberFinishDate &&
        (![undefined, null].includes(oldValue.rememberFinishDate) ||
          ![undefined, null].includes(verified.rememberFinishDate))
      "
      class="p-d-flex p-col-12 p-jc-between"
    >
      <span id="lbl_finishDate" name="lbl_finishDate" for="dateTaken">
        {{ 'symptominstance.endDateQuestion' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="
          oldValue.rememberFinishDate
            | textNa: verified.rememberFinishDate:'boolean':true
        "
      ></div>
    </div>
    <div
      *ngIf="
        confMedicalAttention?.showRememberFinishDate &&
        (oldValue.finishDate || verified.finishDate)
      "
      class="p-d-flex p-col-12 p-jc-between"
    >
      <span id="lbl_temperatureDate" name="lbl_temperatureDate">
        {{ 'symptominstance.endDate' | translate }}:
      </span>
      <div
        class="p-ml-1"
        [innerHTML]="oldValue.finishDate | textNa: verified.finishDate:'':true"
      ></div>
    </div>
  </div>
</div>
<div class="p-d-flex p-jc-end p-pt-3">
  <button
    pButton
    type="button"
    label="{{ 'general.cancel' | translate }}"
    icon="pi pi-times"
    class="p-mx-2"
    (click)="onCancel()"
  ></button>
  <button
    pButton
    type="button"
    label="{{ 'general.confirm' | translate }}"
    icon="pi pi-check"
    class="p-button-secondary"
    (click)="onAccept(true)"
  ></button>
</div>
