<div class="p-grid">
  <app-button-refresh-data
    [showSpinner]="showSpinner"
    (buttonAction)="ngOnInit()"
  ></app-button-refresh-data>
  <app-button-table-card-mode
    dataSource="subjectTemperaturesList"
    class="p-col-11"
    *ngIf="temperatures && temperatures.length > 0 && !showSpinner"
    (cardOrTableModeEmit)="tableViewMode = $event"
  ></app-button-table-card-mode>
</div>
<app-tour-guide-button
  [pageTourDataType]="DETAIL_SUBJECT_TEMPERATURE_TOUR"
  *ngIf="temperatures?.length && !showSpinner"
></app-tour-guide-button>
<section *ngIf="!showSpinner">
  <div
    *ngIf="temperatures && temperatures.length > 0"
    id="subjectTemperature_container"
  >
    <section *ngIf="!tableViewMode">
      <div class="p-grid p-fluid" *ngIf="temperatures.length > 0">
        <app-tpcard
          class="p-col-12 p-md-4 p-lg-3"
          *ngFor="let temp of dataCard; let i = index"
          [id]="'temperatureCard' + i"
          [tpCardData]="temp"
        ></app-tpcard>
      </div>
    </section>
    <section *ngIf="tableViewMode" class="p-mb-5">
      <app-tptable
        (customAction1)="
          cardOrTableService.openAuditModal({
            data: $event.data,
            header: $event.name,
            entity: $event.entity
          })
        "
        [dataTable]="dataTable"
        [timeZoneOffset]="timeZoneOffset"
        id="temperatureCard"
      ></app-tptable>
    </section>
  </div>

  <h4 style="width: 100%; text-align: center" *ngIf="temperatures.length === 0">
    {{ 'general.noResultsFound' | translate }}
  </h4>
</section>
<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
