import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'aws-amplify';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InformedConsentService } from '../../informed-consent.service';
import {
  CreateConsentedUserInput,
  CreateInformedConsentInstanceInput,
  GetConfInformedConsentQuery,
  InformedConsentState,
} from '../../informed-consent.types';
const logger = new Logger('informed-consent-follow-up-assign-existing-users');
@Component({
  selector: 'app-informed-consent-follow-up-assign-existing-users',
  templateUrl:
    './informed-consent-follow-up-assign-existing-users.component.html',
  styleUrls: [
    './informed-consent-follow-up-assign-existing-users.component.scss',
  ],
})
export class InformedConsentFollowUpAssignExistingUsersComponent
  implements OnInit
{
  users: any[] = [];
  tableColumns: any[] = [];
  selectedUsers: any[] = [];
  consenterIdentificators: any[] = [];
  projectId: string = '';
  siteId: string = '';
  confInformedConsentId: string = '';
  confInformedConsentSelectId: string = '';
  errorMessages: string[] = [];

  consentedIdentificatorSearch = '';
  existingUsersTable: any[] = [];
  existingUsersTableFilter: any[] = [];

  confInformedConsentsByProject: any[] = [];
  confInformedConsents: any = [];
  constructor(
    private trialpalService: TrialpalService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private informedConsentService: InformedConsentService,
    public config: DynamicDialogConfig
  ) {
    this.tableColumns = [
      {
        field: 'login.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.user'
        ),
      },
      {
        field: 'name.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.name'
        ),
      },
      {
        field: 'email.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.email'
        ),
      },
      {
        field: 'phoneNumber.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.phoneNumber'
        ),
      },
      {
        field: 'relationship.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.relationship'
        ),
      },
      {
        field: 'identifier.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.participants'
        ),
      },
      {
        field: 'informedConsentsName.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.consents'
        ),
      },
      {
        field: 'notificationPreference.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.notificationPreference'
        ),
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('user.entityPlural', 'LIST');

    this.projectId = this.config.data.projectId;
    this.confInformedConsentId = this.route.snapshot.queryParams.icId;
    this.siteId = this.route.snapshot.queryParams.siteId;

    const users = JSON.parse(JSON.stringify(this.config.data.users));
    logger.debug('users existing', users);
    this.existingUsersTable = await this.getConsentedUsers(users);
    this.existingUsersTableFilter = [...this.existingUsersTable];
    this.confInformedConsentsByProject =
      await this.informedConsentService.getConfInformedConsentByProjectId(
        this.projectId
      );

    this.getConfInformedConsentsBySiteId();
    this.trialpalService.hideSpinner();
  }

  async getConsentedUsers(users: any) {
    const informedConsents = await this.getInformedConsentByUsersId(users);
    const currentUsers = [];
    for (let user of users) {
      const subjects = user.identifier?.data?.split(',');
      user.data.informedConsents = this.getInformedConsentByUserId(
        informedConsents,
        user.data.id
      );
      for (let subject of subjects) {
        let newUser = JSON.parse(JSON.stringify(user));
        newUser.identifier.data = subject.trim();
        newUser.data.informedConsents = this.getInformedConsentsByIdentificator(
          newUser,
          newUser.identifier.data
        );
        newUser.subjectNames.data = this.getInformedConsentValueByKey(
          newUser.data,
          'consentedName'
        );
        newUser.relationship.data = this.getKinshipByUser(newUser.data);
        newUser.notificationPreference.data =
          this.getUserNotificationPreferenceValueByKey(
            newUser.notificationPreference.data
          );
        newUser = {
          ...newUser,
          informedConsentsName: {
            data: this.getICNames(newUser.data),
          },
        };
        newUser.row = new Date().getTime() + subject + user.data.id;
        currentUsers.push(newUser);
      }
    }
    return currentUsers;
  }

  async getInformedConsentByUsersId(users: any[]) {
    return Promise.all(
      users.map((user: any) => {
        return this.informedConsentService.getInformedConsentByUserId(
          user.data.id,
          ''
        );
      })
    );
  }

  getInformedConsentByUserId(informedConsents: any, userId: string) {
    return informedConsents.find((informedConsent: any) => {
      if (informedConsent.length) {
        return informedConsent[0].consentedUser.userId === userId;
      }
      return false;
    });
  }

  getICNames(user: any) {
    let ICs: any[] = [];
    for (let consents of user.informedConsents) {
      let name = this.trialpalService.dictionaryPipe.transform(
        consents?.informedConsent.ConfInformedConsent?.name
      );

      ICs.push(name);
    }
    ICs = ICs.filter((item, index) => {
      return ICs.indexOf(item) === index;
    });
    return ICs.join(', ');
  }

  getInformedConsentValueByKey(user: any, key: string) {
    let ICs: any[] = [];
    for (let informedConsent of user.informedConsents) {
      let informedConsentValue = informedConsent?.informedConsent[key] ?? '';
      ICs.push(informedConsentValue);
    }

    ICs = ICs.filter((value: any) => value.trim().length !== 0);
    return ICs.join(', ');
  }
  getUserNotificationPreferenceValueByKey(key: string) {
    return this.trialpalService.translateService.instant(
      `ediary.enums.alertTypes.${key}`
    );
  }

  getKinshipByUser(user: any) {
    const kinships = user.informedConsents
      .filter(
        (informedConsent: any) =>
          informedConsent.informedConsent.confInformedConsentId ===
          this.confInformedConsentId
      )
      .map((informedConsent: any) =>
        this.trialpalService.translateService.instant(
          `informedConsent.witnessesTypes.${informedConsent?.consentedUser.kinship}`
        )
      )
      .join(', ');

    return kinships;
  }

  getInformedConsentsByIdentificator(user: any, identificator: string) {
    return user.data.informedConsents.filter((informedConsent: any) => {
      return (
        informedConsent.informedConsent.consentedIdentificator === identificator
      );
    });
  }

  async getConfInformedConsentsBySiteId() {
    this.confInformedConsents = this.confInformedConsentsByProject
      .filter(
        (confInformedConsent: GetConfInformedConsentQuery) =>
          confInformedConsent?.sites?.some(
            (site) => site?.site === this.siteId
          ) && confInformedConsent.id !== this.confInformedConsentId
      )
      .map((confInformedConsent: GetConfInformedConsentQuery) => {
        return {
          ...confInformedConsent,
          label: this.trialpalService.dictionaryPipe.transform(
            confInformedConsent?.name || ''
          ),
          value: confInformedConsent?.id,
        };
      });
  }

  applySearchFilter() {
    this.selectedUsers = [];
    this.existingUsersTableFilter = this.existingUsersTable.filter(
      (user) =>
        this.filterIfUserHasInformedConsent(user) &&
        (user.login.data
          .toLowerCase()
          .includes(this.consentedIdentificatorSearch.toLowerCase()) ||
          user.identifier.data
            .toLowerCase()
            .includes(this.consentedIdentificatorSearch.toLowerCase()) ||
          user.name.data
            .toLowerCase()
            .includes(this.consentedIdentificatorSearch.toLowerCase()) ||
          user.phoneNumber.data
            .toLowerCase()
            .includes(this.consentedIdentificatorSearch.toLowerCase()) ||
          user.email.data
            .toLowerCase()
            .includes(this.consentedIdentificatorSearch.toLowerCase()) ||
          user.relationship.data
            .toLowerCase()
            .includes(this.consentedIdentificatorSearch.toLowerCase()) ||
          user.notificationPreference.data
            .toLowerCase()
            .includes(this.consentedIdentificatorSearch.toLowerCase()))
    );
  }

  async onSubmit(form: NgForm) {
    if (!this.confInformedConsentSelectId || this.selectedUsers.length === 0) {
      return;
    }
    await this.assingInformedConsent(this.selectedUsers);
    this.trialpalService.ref.close('');
    this.informedConsentService.recordeConsentEvent(
      'site_consent_assignconsents',
      {
        project: this.projectId,
        site: this.siteId,
      }
    );
  }

  getConsenterIdentificators(user: any, identifiers: any) {
    for (const identifier of identifiers) {
      if (identifier) {
        this.consenterIdentificators.push({
          label: identifier,
          value: identifier,
          user: user,
        });
      }
    }
  }

  async assingInformedConsent(users: any) {
    this.consenterIdentificators = [];
    for (const user of users) {
      const subjectsIdentifiers = [user.identifier.data];
      this.getConsenterIdentificators(user.data, subjectsIdentifiers);
      await this.assingIdentifiersToIC(subjectsIdentifiers, user);
    }
  }

  async assingIdentifiersToIC(identifiers: any, user: any) {
    this.trialpalService.showSpinner('user.entityPlural', 'LIST');

    const { consentedName, kinship, consentedBirthDate } =
      this.getConsentedNameandKinshipByUser(user.data);

    logger.debug('Tp2AssignExistinUser ->', user, kinship, consentedName);
    for (let identifier of identifiers) {
      const findIdentifier = this.consenterIdentificators.find(
        (CI) => CI.value === identifier && CI.user.id === user.data.id
      );

      const informedConsentCreated = await this.informendConsentDrive(
        findIdentifier,
        consentedName,
        consentedBirthDate
      );

      this.createConsentedUserDrive(
        findIdentifier,
        kinship,
        informedConsentCreated
      );
    }
    this.trialpalService.hideSpinner();
    this.trialpalService.messageService.add({
      severity: 'success',

      summary: this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.usersCreated'
      ),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.usersAssignedDetail'
      ),
    });
  }

  getConsentedNameandKinshipByUser(user: any) {
    const informedConsent = user.informedConsents.find(
      (informedConsent: any) => {
        const confInformedConsentId =
          informedConsent.informedConsent.confInformedConsentId;
        return confInformedConsentId === this.confInformedConsentId;
      }
    );
    const consentedName = informedConsent.informedConsent?.consentedName ?? '';
    const consentedBirthDate =
      informedConsent.informedConsent?.consentedBirthDate ?? '';
    const kinship = informedConsent?.consentedUser.kinship;
    return { consentedName, kinship, consentedBirthDate };
  }

  async informendConsentDrive(
    identifier: any,
    consentedName: string,
    consentedBirthDate: string
  ) {
    let informedConsent: any = await this.getinformedConsentByIdentificator(
      identifier.value
    );
    if (!informedConsent) {
      informedConsent = await this.createICInstance(
        identifier,
        consentedName,
        consentedBirthDate
      );
    }

    return informedConsent;
  }

  async getinformedConsentByIdentificator(identificator: string) {
    const informedConsent =
      await this.informedConsentService.getInformedConsentByConsentedIdentificator(
        identificator
      );
    return informedConsent.items.find(
      (IC: any) =>
        !IC._deleted &&
        IC.confInformedConsentId === this.confInformedConsentSelectId
    );
  }

  private createICInstance(
    identifier: any,
    consentedName: string,
    consentedBirthDate: string
  ): any {
    const createInput: CreateInformedConsentInstanceInput = {
      projectId: this.projectId,
      siteId: this.siteId,
      confInformedConsentId: this.confInformedConsentSelectId,
      _lastUser: this.auth.getUsername(),
      consentedIdentificator: identifier.value,
      consentedName: consentedName,
      consentedBirthDate: consentedBirthDate,
      stateChanges: [
        {
          state: InformedConsentState.ASSIGNED,
          date: new Date().toISOString(),
        },
      ],
      state: InformedConsentState.ASSIGNED,
    };
    return this.informedConsentService.createInformedConsentInstance(
      createInput
    );
  }

  async createConsentedUserDrive(
    findIdentifier: any,
    kinship: string,
    informedConsentCreated: any
  ) {
    const informedConsentsByICCreated =
      findIdentifier.user.informedConsents.filter(
        (IC: any) => IC.informedConsent.id === informedConsentCreated.id
      );

    const hasConsentedUser = informedConsentsByICCreated.find(
      (x: any) => x.consentedUser.userId === findIdentifier.user.id
    );
    if (!hasConsentedUser) {
      await this.createConsentedUserInstance(
        findIdentifier,
        kinship,
        informedConsentCreated
      );
    }
  }

  private async createConsentedUserInstance(
    identifier: any,
    kinship: string,
    informedConsentCreated: any
  ) {
    const createConsentedUserInput: CreateConsentedUserInput = {
      projectId: this.projectId,
      siteId: this.siteId,
      informedConsentInstanceId: informedConsentCreated?.id || '',
      state: InformedConsentState.ASSIGNED,
      userId: identifier.user.id,
      kinship: kinship,
      stateChanges: [
        {
          state: InformedConsentState.ASSIGNED,
          date: new Date().toISOString(),
        },
      ],
      name: identifier.user.name,
      _lastUser: this.auth.getUsername(),
    };
    return this.informedConsentService.createConsentedUser(
      createConsentedUserInput
    );
  }

  handleInput(event: Event) {
    return (event.target as HTMLInputElement).value;
  }
  clear(table: Table) {
    table.clear();
  }

  onChangeConfInformedConsentSelectId() {
    this.selectedUsers = [];
    this.existingUsersTableFilter = this.existingUsersTable.filter((user) => {
      return this.filterIfUserHasInformedConsent(user);
    });
  }

  filterIfUserHasInformedConsent(user: any) {
    const informedConsents = user.data.informedConsents;

    //Valida si ya tiene una instancia en el consentimiento destino
    const informedConsent =
      this.getInformedConsentByConfInformedIdAndConsentedIdentificator(
        informedConsents
      );
    if (informedConsent.length) return false;
    return true;
  }

  getInformedConsentByConfInformedIdAndConsentedIdentificator(
    informedConsents: any[]
  ) {
    return informedConsents.filter((informedConsent: any) => {
      const consentedUser = informedConsent.informedConsent;
      return (
        consentedUser.confInformedConsentId === this.confInformedConsentSelectId
      );
    });
  }
}
