import { Component, Input, OnChanges } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ICComplementaryQuestion } from '../../informed-consent-section-add-edit.component';
import { ComplementaryQuestionType } from 'src/app/modules/informed-consent/informed-consent.types';

@Component({
  selector: 'app-complementary-questions',
  templateUrl: './complementary-questions.component.html',
  styleUrls: ['./complementary-questions.component.scss'],
})
export class ComplementaryQuestionsComponent implements OnChanges {
  form: FormGroup;
  @Input() projectId: string = '';
  @Input() generalForm: any;
  @Input() complementaryQuestion!: ICComplementaryQuestion;
  @Input() complementaryQuestionType: any[] = [];
  options: any[] = [{ value: '' }];
  addOptionActive: boolean = false;
  showDisplayDeleteComponent: boolean = false;
  currentOptionIndexSelect: number = -999;
  uniqueId: number = new Date().getTime();

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      question: ['', [Validators.required]],
      type: ['', [Validators.required]],
      options: [[], [Validators.required, this.allOptionsFilledValidator()]],
    });
  }

  ngOnChanges() {
    //Obtiene la información de las opciones
    const options = this.complementaryQuestion?.options ?? [''];
    const complementaryOptions: any = options?.map((option: any) => {
      return { value: option };
    });

    this.form.setValue({
      question: this.complementaryQuestion?.question ?? '',
      type:
        this.complementaryQuestion?.type ?? ComplementaryQuestionType.YES_NO,
      options: options,
    });

    this.options = complementaryOptions;
    this.complementaryQuestion.form = this.form;
  }

  addOption() {
    const optionsValid = this.options.every(
      (option: any) => option.value.trim().length !== 0
    );
    this.addOptionActive = true;

    if (!optionsValid) return;
    this.addOptionActive = false;
    this.options.push({ value: '' });
    this.updateOptions();
  }

  showModalDelete(index: number) {
    this.currentOptionIndexSelect = index;
    this.showDisplayDeleteComponent = true;
  }

  deleteOption() {
    const optionIndex = this.currentOptionIndexSelect;
    this.options = this.options.filter(
      (_option: any, index: number) => optionIndex !== index
    );
    this.updateOptions();
  }

  updateOptions() {
    const options: string[] = this.options.map((option: any) => option.value);
    this.form.controls['options'].setValue(options);
  }

  allOptionsFilledValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const optionsArray = control.value as string[];
      const allFilled = optionsArray.every(
        (option) => option?.trim()?.length > 0
      );

      return allFilled && optionsArray.length > 0
        ? null
        : { optionsNotFilled: true };
    };
  }
}
