import { MenuItem } from 'primeng/api';
import {
  ConfInformedConsent,
  ConsentedUser,
  InformedConsentInstance,
} from '../modules/informed-consent/informed-consent.types';

export enum Roles {
  Admin = 'Admin',
  Investigator = 'Investigator',
  Subject = 'Subject',
  Reader = 'Reader',
  Consented = 'Consented',
  Conciliator = 'Conciliator',
}

export enum projectStateGlobal {
  IN_DESIGN = 'IN_DESIGN',
  IN_PROGRESS = 'IN_PROGRESS',
  SUSPENDED = 'SUSPENDED',
  ARCHIVED = 'ARCHIVED',
  ENDED = 'ENDED',
}

export enum SymptomIcons {
  APPETITE = 'APPETITE',
  BREATH = 'BREATH',
  CRYING = 'CRYING',
  DROWSINESS = 'DROWSINESS',
  FEVER = 'FEVER',
  HEALTH = 'HEALTH',
  IRRITABILITY = 'IRRITABILITY',
  MEDICATION = 'MEDICATION',
  OTHERSYM = 'OTHERSYM',
  REDNESS = 'REDNESS',
  SWELLING = 'SWELLING',
  VACCINE = 'VACCINE',
  VOMIT = 'VOMIT',
  FORM = 'FORM',
  HARDNESS = 'HARDNESS',
  HOUSEHOLD = 'HOUSEHOLD',
}
export enum SectionsToConcilied {
  TEMPERATURE = 'TEMPERATURE',
  SOLICITED_SYMPTOMS = 'SOLICITED_SYMPTOMS',
  LOCAL_SYMPTOMS = 'LOCAL_SYMPTOMS',
  OTHER_SYMPTOMS = 'OTHER_SYMPTOMS',
  MEDICATION = 'MEDICATION',
  MEDICAL_ATTENTION = 'MEDICAL_ATTENTION',
}

export const emailRegex =
  /^([a-zA-Z0-9_.-]+)(\+[0-9]+)?@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const phoneRegex =
  '^(([+][(]?[0-9]{1,3}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{3,4})';

export const userRegex = /^([a-zA-Z0-9_.:-]){1,50}$/;

export enum TP2Entites {
  // conf entities
  PROJECT = 'Project',
  CONFREPORT = 'ConfReport',
  CONFEDIARY = 'ConfEDiary',
  CONFDAY = 'ConfDay',
  CONFFORM = 'ConfForm',
  CONFSYMPTOM = 'ConfSymptom',
  CONFFORMCONFSYMPTOM = 'ConfFormConfSymptom',
  CONFTEMPERATURE = 'ConfTemperature',
  CONFALERT = 'ConfAlert',
  CONFCONTACT = 'ConfContact',
  // instance entities
  SUBJECT = 'Subject',
  SCHEDULEDPHASE = 'ScheduledPhase',
  REPORTINSTANCE = 'ReportInstance',
  SIGNATUREINSTANCE = 'SignatureInstance',
  EDIARYINSTANCE = 'EDiaryInstance',
  EDIARYPHASEINSTANCE = 'EDiaryPhaseInstance',
  DAYINSTANCE = 'DayInstance',
  SYMPTOMRECORDLOG = 'SymptomRecordLog',
  SYMPTOMINSTANCE = 'SymptomInstance',
  MEDICATIONRECORDLOG = 'MedicationRecordLog',
  MEDICATIONINSTANCE = 'MedicationInstance',
  MEDICALATTENTIONINSTANCE = 'MedicalAttentionInstance',
  TEMPERATURERECORDLOG = 'TemperatureRecordLog',
  MEDIARECORDLOG = 'MediaRecordLog',
  ALERTINSTANCE = 'AlertInstance',
  TRIALPALLOG = 'TrialPalLog',
  COMMENTCONCILIATIONINSTANCE = 'CommentConciliationInstance',

  // User
  USER = 'User',
  SITES = 'Site',
  STATE = 'State',
  SUBJECTS = 'Subjects',
  SYMPTOM = 'Symptom',
  PROJECTS = 'Projects',
  ROLE = 'Role',

  //conciliation
  VERIFIEDOTHERSYMPTOMINSTANCE = 'VerifiedSymptomInstance',
  VERIFIEDMEDICALATTENTIONINSTANCE = 'VerifiedMedicalAttentionInstance',
  VERIFIEDTEMPERATURERECORDLOG = 'VerifiedTemperatureRecordLog',
  VERIFIEDMEDICATIONINSTANCE = 'VerifiedMedicationInstance',

  //eConsent
  CONSENTEDUSER = 'ConsentedUser',
  CONFINFORMEDCONSENT = 'ConfInformedConsent',
  CONFICSECTION = 'ConfICSection',
  CONFICQUESTION = 'ConfICQuestion',
  CONFICANSWER = 'ConfICAnswer',
  INFORMEDCONSENTINSTANCE = 'InformedConsentInstance',
  ICSECTIONINSTANCE = 'ICSectionInstance',
  ICQUESTIONINSTANCE = 'ICQuestionInstance',
  CONFDICTIONARY = 'ConfDictionary',
}
export class TP2Variables {
  public static readonly PROJECT_VARS: TP2ModelVO[] = [
    {
      name: 'state',
      translateKey: 'project.labelState',
      enumKey: 'project.enums.projectStates.',
    },
    { name: 'code', translateKey: 'project.labelCode' },
    { name: 'name', translateKey: 'project.labelName' },
    { name: 'sponsor', translateKey: 'project.labelSponsor' },
    { name: 'groups', translateKey: 'project.labelGroups' },
    { name: 'phases', translateKey: 'project.labelPhases' },
    { name: 'isEdiaryActivated', translateKey: 'project.labelEdiaryActivated' },
    {
      name: 'isActive',
      translateKey: 'project.accessibilityGroupEnabledGroupLabelAudit',
    },
    {
      name: 'isReportsActivated',
      translateKey: 'project.labelReportsActivated',
    },
    {
      name: 'isContactInfoActivated',
      translateKey: 'project.labelContactInfoActivated',
    },
    { name: 'isICActivated', translateKey: 'project.labelICActivated' },
    { name: 'isChatActivated', translateKey: 'project.labelChatActivated' },
    {
      name: 'timezone',
      translateKey: 'project.timeZone',
    },
    {
      name: 'isMFAActivated',
      translateKey: 'password.codeMFA',
    },
    {
      name: 'faultRecipients',
      translateKey: 'project.labelFaultRecipients',
    },
    {
      name: 'isSubjectNumberCheckRequired',
      translateKey: 'project.isSubjectNumberCheckRequired',
    },
    {
      name: 'subjectNumberRegex',
      translateKey: 'project.subjectNumberRegex',
    },
    {
      name: 'subjectNumberPlaceholder',
      translateKey: 'project.subjectNumberPlaceholder',
    },
    {
      name: 'checkSiteCodeInSubjectNumber',
      translateKey: 'project.checkSiteCodeInSubjectNumber',
    },
  ];

  public static readonly CONF_REPORT: TP2ModelVO[] = [
    {
      name: 'groups',
      translateKey: 'general.groups',
      isArray: true,
      isDictionary: true,
    },
    {
      name: 'phases',
      translateKey: 'general.phases',
      isArray: true,
      isDictionary: true,
    },
    { name: 'instancePerDay', translateKey: 'report.labelInstancePerDay' },
    { name: 'reportTitle', translateKey: 'report.labelReportTitle' },
    { name: 'showHelp', translateKey: 'report.labelShowHelp' },
    { name: 'reportHelp', translateKey: 'report.labelHelp' },
    {
      name: 'reportIcon',
      translateKey: 'report.labelIcon',
      enumKey: 'symptom.enums.symptomIcons.',
    },
    {
      name: 'programationType',
      translateKey: 'report.labelProgramationType',
      enumKey: 'report.enums.programationTypes.',
    },
    { name: 'plannedDayList', translateKey: 'report.labelPlannedDayList' },
    {
      name: 'symptomPageLayout',
      translateKey: 'report.labelPageLayout',
      enumKey: 'ediary.enums.pageLayouts.',
    },
    {
      name: 'allowReportBackDating',
      translateKey: 'report.labelAllowReportBackDating',
    },
    {
      name: 'enableLocalNotifications',
      translateKey: 'report.labelEnableLocalNotifications',
    },
    {
      name: 'alertType',
      translateKey: 'report.labelPeriodicityType',
      enumKey: 'report.enums.onDemandAlertTypes.',
    },
    {
      name: 'futureNotifications',
      translateKey: 'report.labelFutureNotifications',
    },
    {
      name: 'alertWeeklyDays',
      translateKey: 'report.labelAlertWeeklyDays',
      enumKey: 'report.enums.dayNames.',
      isArray: true,
      isDictionary: false,
    },
    {
      name: 'alertInBetweenDaysFrequency',
      translateKey: 'report.labelAlertInBetweenDaysFrequency',
    },
    {
      name: 'alertDuringSameDayFrequency',
      translateKey: 'report.labelAlertDuringSameDayFrequency',
    },
    { name: 'alertStartTime', translateKey: 'report.labelAlertStartTime' },
    { name: 'alertEndTime', translateKey: 'report.labelAlertEndTime' },
    { name: 'jsonForm', translateKey: 'report.labelJsonForm' },
    { name: 'isSignatureRequired', translateKey: 'report.labelSignature' },
    { name: 'isGPSRequired', translateKey: 'report.labelGPS' },
    { name: 'isUserIPRequired', translateKey: 'report.labelIP' },
    { name: 'isMediaRequired', translateKey: 'report.labelMedia' },
    { name: 'isDeviceDataRequired', translateKey: 'report.labelDeviceData' },
    {
      name: 'mediaTypes',
      translateKey: 'report.labelMediaType',
      enumKey: 'ediary.enums.mediaTypes.',
      isArray: true,
      isDictionary: false,
    },
    {
      name: 'isControlledBySite',
      translateKey: 'report.labelControlledBySite',
    },
  ];

  public static readonly CONF_FORM: TP2ModelVO[] = [
    { name: 'name', translateKey: 'ediary.components.formAddEdit.labelName' },
    { name: 'isOtherForm', translateKey: 'report.labelIsOtherForm' },
    {
      name: 'instancePerDay',
      translateKey: 'ediary.components.formAddEdit.labelSymptomsRequired',
      enumKey: 'symptom.enums.symptoms.',
    },
    {
      name: 'symptomPageLayout',
      translateKey: 'ediary.components.formAddEdit.labelPageLayout',
      enumKey: 'ediary.enums.pageLayouts.',
    },
    {
      name: 'isTemperatureRequired',
      translateKey: 'ediary.components.formAddEdit.labelTemperature',
    },
    {
      name: 'isMedicationRequired',
      translateKey: 'ediary.components.formAddEdit.labelMedication',
    },
    {
      name: 'isMedicalAttentionRequired',
      translateKey: 'ediary.components.formAddEdit.labelMedicalAttention',
    },
    {
      name: 'areOtherSymptomsRequired',
      translateKey: 'ediary.components.formAddEdit.labelOtherSymptom',
    },
    {
      name: 'areCommentsRequired',
      translateKey: 'ediary.components.formAddEdit.labelComments',
    },
    {
      name: 'isMediaRequired',
      translateKey: 'ediary.components.formAddEdit.labelMedia',
    },
    {
      name: 'mediaTypes',
      translateKey: 'ediary.components.formAddEdit.labelMediaType',
    },
    {
      name: 'showHelp',
      translateKey: 'ediary.components.formAddEdit.labelShowHelp',
    },
    {
      name: 'formHelp',
      translateKey: 'ediary.components.formAddEdit.labelHelp',
    },
  ];
  public static readonly CONF_EDIARY: TP2ModelVO[] = [
    {
      name: 'isSignatureRequired',
      translateKey: 'ediary.components.generalConfiguration.labelSignature',
    },
    {
      name: 'isGPSRequired',
      translateKey: 'ediary.components.generalConfiguration.labelGPS',
    },
    {
      name: 'isUserIPRequired',
      translateKey: 'ediary.components.generalConfiguration.labelIP',
    },
    {
      name: 'isDeviceDataRequired',
      translateKey: 'report.labelDeviceData',
    },
    {
      name: 'isConciliationRequired',
      translateKey:
        'ediary.components.generalConfiguration.labelEnableElectronicJournalConcilation',
    },
    {
      name: 'enableLocalNotifications',
      translateKey: 'report.labelEnableLocalNotifications',
    },
    {
      name: 'alertInBetweenDaysFrequency',
      translateKey: 'report.labelReminders',
    },
    {
      name: 'alertDuringSameDayFrequency',
      translateKey: 'report.labelAlertDuringSameDayFrequency',
    },
    {
      name: 'alertStartTime',
      translateKey: 'report.labelAlertStartTime',
    },
    {
      name: 'alertEndTime',
      translateKey: 'report.labelAlertEndTime',
    },
    {
      name: 'alertType',
      translateKey: 'report.alertType',
      enumKey: 'report.enums.onDemandAlertTypes.',
    },
    {
      name: 'programationType',
      translateKey: 'report.programationType',
      enumKey: 'report.enums.programationTypes.',
    },
  ];
  public static readonly CONF_SYMPTOM: TP2ModelVO[] = [
    {
      name: 'type',
      translateKey: 'symptom.labelType',
      enumKey: 'symptom.enums.symptomTypes.',
    },
    {
      name: 'symptom',
      translateKey: 'symptom.labelSymptom',
    },
    { name: 'order', translateKey: 'symptom.labelOrder' },
    {
      name: 'injectionSite',
      translateKey: 'symptom.labelInjectionSite',
      enumKey: 'symptom.enums.injectionSites.',
    },
    {
      name: 'injectionSide',
      translateKey: 'symptom.labelInjectionSide',
      enumKey: 'symptom.enums.injectionSides.',
    },
    { name: 'showHelp', translateKey: 'symptom.labelShowHelp' },
    { name: 'symptomHelp', translateKey: 'symptom.labelHelp' },
    { name: 'symptomLabel', translateKey: 'symptom.labelSymptomLabel' },
    {
      name: 'symptomIcon',
      translateKey: 'symptom.labelIcon',
    },
    { name: 'decimalPlaces', translateKey: 'symptom.labelDecimal' },
    {
      name: 'intensityType',
      translateKey: 'symptom.labelIntensityType',
      enumKey: 'symptom.enums.intensityTypes.',
    },
    {
      name: 'intensityQuestions',
      translateKey: 'symptom.intensityQuestions',
    },
    {
      name: 'intensitySizeLabel',
      translateKey: 'general.configurations.labelShow',
    },
    {
      name: 'noneIntensityScaleOptions',
      translateKey: 'symptom.labelNoneIntensity',
    },
    {
      name: 'noneIntensityScaleOptions.label',
      translateKey: 'symptom.none.label',
    },
    {
      name: 'noneIntensityScaleOptions.active',
      translateKey: 'symptom.none.active',
    },
    {
      name: 'noneIntensityScaleOptions.startValue',
      translateKey: 'symptom.none.startValue',
    },
    {
      name: 'noneIntensityScaleOptions.endValue',
      translateKey: 'symptom.none.endValue',
    },
    {
      name: 'noneIntensityScaleOptions.helpText',
      translateKey: 'symptom.none.helpText',
    },
    {
      name: 'lowIntensityScaleOptions',
      translateKey: 'symptom.labelLowIntensity',
    },
    {
      name: 'lowIntensityScaleOptions.label',
      translateKey: 'symptom.low.label',
    },
    {
      name: 'lowIntensityScaleOptions.startValue',
      translateKey: 'symptom.low.startValue',
    },
    {
      name: 'lowIntensityScaleOptions.endValue',
      translateKey: 'symptom.low.endValue',
    },
    {
      name: 'lowIntensityScaleOptions.active',
      translateKey: 'symptom.low.active',
    },
    {
      name: 'lowIntensityScaleOptions.helpText',
      translateKey: 'symptom.low.helpText',
    },
    {
      name: 'mediumIntensityScaleOptions',
      translateKey: 'symptom.labelMediumIntensity',
    },
    {
      name: 'mediumIntensityScaleOptions.label',
      translateKey: 'symptom.medium.label',
    },
    {
      name: 'mediumIntensityScaleOptions.startValue',
      translateKey: 'symptom.medium.startValue',
    },
    {
      name: 'mediumIntensityScaleOptions.endValue',
      translateKey: 'symptom.medium.endValue',
    },
    {
      name: 'mediumIntensityScaleOptions.active',
      translateKey: 'symptom.medium.active',
    },
    {
      name: 'mediumIntensityScaleOptions.helpText',
      translateKey: 'symptom.medium.helpText',
    },
    {
      name: 'highIntensityScaleOptions',
      translateKey: 'symptom.labelHighIntensity',
    },
    {
      name: 'highIntensityScaleOptions.label',
      translateKey: 'symptom.high.label',
    },
    {
      name: 'highIntensityScaleOptions.startValue',
      translateKey: 'symptom.high.startValue',
    },
    {
      name: 'highIntensityScaleOptions.endValue',
      translateKey: 'symptom.high.endValue',
    },
    {
      name: 'highIntensityScaleOptions.active',
      translateKey: 'symptom.high.active',
    },
    {
      name: 'highIntensityScaleOptions.helpText',
      translateKey: 'symptom.high.helpText',
    },
    {
      name: 'lifeThreateningScaleOptions',
      translateKey: 'symptom.labelPotentiallyLife',
    },
    {
      name: 'lifeThreateningScaleOptions.label',
      translateKey: 'symptom.threat.label',
    },
    {
      name: 'lifeThreateningScaleOptions.startValue',
      translateKey: 'symptom.threat.startValue',
    },
    {
      name: 'lifeThreateningScaleOptions.endValue',
      translateKey: 'symptom.threat.endValue',
    },
    {
      name: 'lifeThreateningScaleOptions.active',
      translateKey: 'symptom.threat.active',
    },
    {
      name: 'lifeThreateningScaleOptions.helpText',
      translateKey: 'symptom.threat.helpText',
    },
    { name: 'intensityQuestions', translateKey: 'symptom.labelIntensityList' },
    {
      name: 'isIntensityQuestionsRequired',
      translateKey: 'symptom.intensityQuestionsRequired',
    },
    { name: 'isIntensityRequired', translateKey: 'symptom.labelIntensity' },
    {
      name: 'isStartDateRequired',
      translateKey: 'symptom.labelRequireRememberStartDate',
    },
    {
      name: 'isFinishDateRequired',
      translateKey: 'symptom.labelRequireRememberFinishDate',
    },
    { name: 'showNoValuesTaken', translateKey: 'symptom.showNoValuesTaken' },
    {
      name: 'noValuesTakenLabel',
      translateKey: 'general.configurations.labelShow',
    },
  ];
  public static readonly SUBJECT_VARS: TP2ModelVO[] = [
    {
      name: 'state',
      translateKey: 'subject.labelState',
      enumKey: 'subject.enums.subjectStates.',
    },
    { name: 'group', translateKey: 'subject.pGroup' },
    {
      name: 'currentScheduledPhase.phase',
      translateKey: 'subject.pCurrentVisit',
    },
    {
      name: 'currentScheduledPhase.state',
      translateKey: 'subject.pCurrentVisitState',
      enumKey: 'visit.enums.visitStates.',
    },
    {
      name: 'currentScheduledPhase.date',
      translateKey: 'subject.dateVisit',
      isDate: true,
    },
    {
      name: 'subjectNumber',
      translateKey: 'subject.labelName',
    },
    {
      name: 'siteId',
      translateKey: 'user.labelSites',
    },
    {
      name: 'userId',
      translateKey: 'user.userName',
    },
    {
      name: 'scheduledPhases',
      translateKey: 'subject.scheduledPhases',
    },
    {
      name: 'tag',
      translateKey: 'subject.tag',
    },
  ];
  public static readonly SITE_VARS: TP2ModelVO[] = [
    {
      name: 'name',
      translateKey: 'site.siteName',
    },
    {
      name: 'timezone',
      translateKey: 'project.labelTimezone',
    },
    {
      name: 'defaultLanguage',
      translateKey: 'site.language',
      enumKey: 'site.languages.',
    },
    {
      name: 'additionalLanguages',
      translateKey: 'site.additionalLanguages',
      enumKey: 'site.languages.',
      isArray: true,
    },
    {
      name: 'contactInfo',
      translateKey: 'site.labelContactInfoActivated',
    },
    {
      name: 'showContactInfoLogin',
      translateKey: 'site.labelShowContactHome',
    },
    {
      name: 'showContactInfo',
      translateKey: 'site.labelDisabledContact',
    },
    {
      name: 'siteCode',
      translateKey: 'site.siteCode',
    },
    {
      name: 'isTimezoneNotificationEnable',
      translateKey: 'site.isTimezoneNotificationEnable',
    },
    {
      name: 'isDailyPendingDiariesNotificationEnable',
      translateKey: 'site.isDailyPendingDiariesNotificationEnable',
    },
    {
      name: 'notificationSchedule',
      translateKey: 'site.notificationSchedule',
    },
    {
      name: 'timezoneRecipients',
      translateKey: 'site.labelTimezoneRecipients',
    },
  ];

  public static readonly USER_VARS: TP2ModelVO[] = [
    {
      name: 'state',
      translateKey: 'user.labelState',
      enumKey: 'user.enums.userStates.',
    },
    {
      name: 'subjects',
      translateKey: 'user.labelSubjects',
    },
    {
      name: 'phoneNumber',
      translateKey: 'user.labelPhoneNumber',
    },
    {
      name: 'name',
      translateKey: 'user.labelName',
    },
    {
      name: 'sites',
      translateKey: 'user.labelSites',
    },
    {
      name: 'projects',
      translateKey: 'user.labelProjects',
    },
    {
      name: '_deleted',
      translateKey: 'user.deleted',
    },
    {
      name: 'login',
      translateKey: 'user.userName',
    },
    {
      name: 'role',
      translateKey: 'user.labelRoles',
    },
    {
      name: 'notificationPreference',
      translateKey: 'user.notificationPreference',
    },
    {
      name: 'permissions',
      translateKey: 'user.permissions',
    },
  ];

  public static readonly MEDICALATTENTIONINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'finishDate',
      translateKey: 'medicalattentioninstance.endDate',
      isDate: true,
    },
    {
      name: 'rememberStartDate',
      translateKey: 'medicalattentioninstance.startDateQuestion',
    },
    {
      name: 'rememberFinishDate',
      translateKey: 'medicalattentioninstance.endDateQuestion',
    },
    {
      name: 'startDate',
      translateKey: 'medicalattentioninstance.startDate',
      isDate: true,
    },
    {
      name: 'wasHospitalized',
      translateKey: 'medicalattentioninstance.wasHospitalized',
    },
    {
      name: 'type',
      translateKey: 'medicalattentioninstance.type',
      enumKey: 'medicalattentioninstance.enums.',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
    {
      name: 'symptomsInstances',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'reason',
      translateKey: 'medicationinstance.reason',
    },
  ];
  public static readonly VERIFIEDMEDICALATTENTIONINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'finishDate',
      translateKey: 'medicalattentioninstance.endDate',
      isDate: true,
    },
    {
      name: 'finishDateComment',
      translateKey: 'verifiedmedicalattentioninstance.finishDateComment',
    },
    {
      name: 'rememberStartDate',
      translateKey: 'medicalattentioninstance.startDateQuestion',
    },
    {
      name: 'rememberFinishDate',
      translateKey: 'medicalattentioninstance.endDateQuestion',
    },
    {
      name: 'startDate',
      translateKey: 'medicalattentioninstance.startDate',
      isDate: true,
    },
    {
      name: 'startDateComment',
      translateKey: 'verifiedmedicalattentioninstance.startDateComment',
      isDate: true,
    },
    {
      name: 'wasHospitalized',
      translateKey: 'medicalattentioninstance.wasHospitalized',
    },
    {
      name: 'wasHospitalizedComment',
      translateKey: 'verifiedmedicalattentioninstance.wasHospitalizedComment',
    },
    {
      name: 'type',
      translateKey: 'medicalattentioninstance.type',
      enumKey: 'medicalattentioninstance.enums.',
    },
    {
      name: 'typeComment',
      translateKey: 'verifiedmedicalattentioninstance.typeComment',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
    {
      name: 'symptomsInstancesComment',
      translateKey: 'verifiedmedicalattentioninstance.symptomsInstancesComment',
    },
    {
      name: 'symptomsInstances',
      translateKey: 'verifiedmedicalattentioninstance.symptoms',
    },
  ];

  public static readonly COMMENTCONCILIATIONINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'comment',
      translateKey: 'commentconciliationinstance.comment',
    },
  ];
  public static readonly SYMPTOMINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'finishDate',
      translateKey: 'symptominstance.endDate',
      isDate: true,
    },
    {
      name: 'rememberStartDate',
      translateKey: 'symptominstance.startDateQuestion',
    },
    {
      name: 'rememberFinishDate',
      translateKey: 'symptominstance.endDateQuestion',
    },
    {
      name: 'startDate',
      translateKey: 'symptominstance.startDate',
      isDate: true,
    },
    {
      name: 'intensity',
      translateKey: 'symptominstance.intensity',
      enumKey: 'symptominstance.enums.',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
    {
      name: 'size',
      translateKey: 'symptominstance.size',
    },
    {
      name: 'symptom',
      translateKey: 'symptominstance.entity',
    },
    {
      name: 'professionalHealthCare',
      translateKey: 'symptominstance.professionalHealthCare',
    },
    {
      name: 'hospitalAdmission',
      translateKey: 'symptominstance.admisionHours',
    },
    {
      name: 'admisionHours',
      translateKey: 'symptominstance.admisionHours',
    },
    {
      name: 'whichOtherSymptom',
      translateKey: 'symptominstance.whichOtherSymptom',
    },
  ];

  public static readonly VERIFIED_SYMPTOMINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'finishDate',
      translateKey: 'symptominstance.endDate',
      isDate: true,
    },
    {
      name: 'rememberStartDate',
      translateKey: 'symptominstance.startDateQuestion',
    },
    {
      name: 'rememberFinishDate',
      translateKey: 'symptominstance.endDateQuestion',
    },
    {
      name: 'startDate',
      translateKey: 'symptominstance.startDate',
      isDate: true,
    },
    {
      name: 'intensity',
      translateKey: 'symptominstance.intensity',
      enumKey: 'symptominstance.enums.',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
    {
      name: 'size',
      translateKey: 'symptominstance.size',
    },
    {
      name: 'symptom',
      translateKey: 'symptominstance.entity',
    },
    {
      name: 'professionalHealthCare',
      translateKey: 'symptominstance.professionalHealthCare',
    },
    {
      name: 'hospitalAdmission',
      translateKey: 'symptominstance.admisionHours',
    },
    {
      name: 'admisionHours',
      translateKey: 'symptominstance.admisionHours',
    },
    {
      name: 'whichOtherSymptom',
      translateKey: 'symptominstance.whichOtherSymptom',
    },
    {
      name: 'whichOtherSymptomComment',
      translateKey: 'verifiedsymptominstance.whichOtherSymptomComment',
    },
    {
      name: 'intensityComment',
      translateKey: 'verifiedsymptominstance.intensityComment',
    },
    {
      name: 'professionalHealthCareComment',
      translateKey: 'verifiedsymptominstance.professionalHealthCareComment',
    },
    {
      name: 'hospitalAdmissionComment',
      translateKey: 'verifiedsymptominstance.hospitalAdmissionComment',
    },
    {
      name: 'startDateComment',
      translateKey: 'verifiedsymptominstance.startDateComment',
    },
    {
      name: 'finishDateComment',
      translateKey: 'verifiedsymptominstance.finishDateComment',
    },
    {
      name: 'rememberNoValuesTaken',
      translateKey: 'symptom.showNoValuesTaken',
    },
    {
      name: 'rememberNoValuesTakenComment',
      translateKey: 'verifiedsymptominstance.rememberNoValuesTakenComment',
    },
    {
      name: 'sizeComment',
      translateKey: 'verifiedsymptominstance.sizeComment',
    },
    {
      name: 'intensityQuestionAnswersComment',
      translateKey: 'verifiedsymptominstance.intensityQuestionAnswersComment',
    },
    {
      name: 'question.question',
      translateKey: 'symptom.intensityQuestions',
      ignore: true,
    },
  ];

  public static readonly VERIFIED_MEDICATIONINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'administrationRoute',
      translateKey: 'medicationinstance.administracionRoute',
    },
    {
      name: 'symptomsInstances',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'reason',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'name',
      translateKey: 'medicationinstance.medicineName',
    },
    {
      name: 'hasMedication',
      translateKey: 'medicationinstance.hasMedication',
    },
    {
      name: 'continueTakingMedicine',
      translateKey: 'medicationinstance.continueTakingMedicine',
    },
    {
      name: 'dose',
      translateKey: 'medicationinstance.dose',
    },
    {
      name: 'givenTo',
      translateKey: 'medicationinstance.givenTo',
      enumKey: 'medicationinstance.enums.',
      isArray: true,
      isTranslate: true,
    },
    {
      name: 'frequency',
      translateKey: 'medicationinstance.frequency',
    },
    {
      name: 'finishDate',
      translateKey: 'medicationinstance.endDate',
      isDate: true,
    },
    {
      name: 'rememberStartDate',
      translateKey: 'medicationinstance.startDateQuestion',
    },
    {
      name: 'rememberFinishDate',
      translateKey: 'medicationinstance.endDateQuestion',
    },
    {
      name: 'startDate',
      translateKey: 'medicationinstance.startDate',
      isDate: true,
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
    {
      name: 'nameComment',
      translateKey: 'verifiedmedicationinstance.nameComment',
    },
    {
      name: 'doseComment',
      translateKey: 'verifiedmedicationinstance.doseComment',
    },
    {
      name: 'givenToComment',
      translateKey: 'verifiedmedicationinstance.givenToComment',
    },
    {
      name: 'reasonComment',
      translateKey: 'verifiedmedicationinstance.reasonComment',
    },
    {
      name: 'symptomsInstancesComment',
      translateKey: 'verifiedmedicationinstance.symptomsInstancesComment',
    },
    {
      name: 'administrationRouteComment',
      translateKey: 'verifiedmedicationinstance.administrationRouteComment',
    },
    {
      name: 'frequencyComment',
      translateKey: 'verifiedmedicationinstance.frequencyComment',
    },
    {
      name: 'startDateComment',
      translateKey: 'verifiedmedicationinstance.startDateComment',
    },
    {
      name: 'finishDateComment',
      translateKey: 'verifiedmedicationinstance.finishDateComment',
    },
  ];

  public static readonly MEDICATIONINSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'administrationRoute',
      translateKey: 'medicationinstance.administracionRoute',
    },
    {
      name: 'symptomsInstances',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'reason',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'name',
      translateKey: 'medicationinstance.medicineName',
    },
    {
      name: 'hasMedication',
      translateKey: 'medicationinstance.hasMedication',
    },
    {
      name: 'continueTakingMedicine',
      translateKey: 'medicationinstance.continueTakingMedicine',
    },
    {
      name: 'medicineName',
      translateKey: 'medicationinstance.medicineName',
    },
    {
      name: 'dose',
      translateKey: 'medicationinstance.dose',
    },
    {
      name: 'reason',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'symptomsInstances',
      translateKey: 'medicationinstance.reason',
    },
    {
      name: 'givenTo',
      translateKey: 'medicationinstance.givenTo',
      enumKey: 'medicationinstance.enums.',
    },
    {
      name: 'frequency',
      translateKey: 'medicationinstance.frequency',
    },
    {
      name: 'finishDate',
      translateKey: 'medicationinstance.endDate',
      isDate: true,
    },
    {
      name: 'rememberStartDate',
      translateKey: 'medicationinstance.startDateQuestion',
    },
    {
      name: 'rememberFinishDate',
      translateKey: 'medicationinstance.endDateQuestion',
    },
    {
      name: 'startDate',
      translateKey: 'medicationinstance.startDate',
      isDate: true,
    },
    {
      name: 'medicationStartDate',
      translateKey: 'medicationinstance.medicationStartDate',
      isDate: true,
    },
    {
      name: 'medicationEndDate',
      translateKey: 'medicationinstance.medicationEndDate',
      isDate: true,
    },
    {
      name: 'previousMedication',
      translateKey: 'medicationinstance.previousMedication',
    },
    {
      name: 'size',
      translateKey: 'medicationinstance.size',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
    {
      name: 'name',
      translateKey: 'medicationinstance.medicineName',
    },
  ];

  public static readonly TEMPERATURERECORDLOG_VARS: TP2ModelVO[] = [
    {
      name: 'temperature',
      translateKey: 'temperaturerecordlog.temperature',
    },
    {
      name: 'temperatureUnit',
      translateKey: 'temperaturerecordlog.temperatureunit',
      enumKey: 'temperaturerecordlog.enums.unit.',
    },
    {
      name: 'temperatureRoute',
      translateKey: 'temperaturerecordlog.route',
      enumKey: 'ediary.enums.temperatureRoute.',
    },
    {
      name: 'temperatureTakenDate',
      translateKey: 'temperaturerecordlog.temperatureTakenDate',
    },
    {
      name: 'temperatureTaken',
      translateKey: 'temperaturerecordlog.temperatureTaken',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
  ];
  public static readonly VERIFIEDTEMPERATURERECORDLOG_VARS: TP2ModelVO[] = [
    {
      name: 'temperature',
      translateKey: 'temperaturerecordlog.temperature',
    },
    {
      name: 'unit',
      translateKey: 'temperaturerecordlog.temperatureunit',
    },
    {
      name: 'temperatureUnit',
      translateKey: 'temperaturerecordlog.temperatureunit',
      enumKey: 'temperaturerecordlog.enums.unit.',
    },
    {
      name: 'route',
      translateKey: 'temperaturerecordlog.route',
      enumKey: 'ediary.enums.temperatureRoute.',
    },
    {
      name: 'temperatureTakenDate',
      translateKey: 'temperaturerecordlog.temperatureTakenDate',
    },
    {
      name: 'taken',
      translateKey: 'temperaturerecordlog.temperatureTaken',
    },
    {
      name: 'temperatureComment',
      translateKey: 'verifiedtemperaturerecordlog.temperatureComment',
    },
    {
      name: 'unitComment',
      translateKey: 'verifiedtemperaturerecordlog.unitComment',
    },
    {
      name: 'takenDate',
      translateKey: 'temperaturerecordlog.temperatureTakenDate',
      isDate: true,
    },
    {
      name: 'takenDateComment',
      translateKey: 'verifiedtemperaturerecordlog.takenDateComment',
    },
    {
      name: 'takenComment',
      translateKey: 'verifiedtemperaturerecordlog.takenComment',
    },
    {
      name: 'routeComment',
      translateKey: 'verifiedtemperaturerecordlog.routeComment',
    },
    {
      name: 'whichOtherRoute',
      translateKey: 'verifiedtemperaturerecordlog.whichOtherRoute',
    },
    {
      name: 'whichOtherRouteComment',
      translateKey: 'verifiedtemperaturerecordlog.whichOtherRouteComment',
    },
  ];
  public static readonly CONF_INFORMED_CONSENT_VARS: TP2ModelVO[] = [
    {
      name: 'name',
      translateKey: 'informedConsent.entity',
    },
    {
      name: 'icVersion',
      translateKey: 'informedConsent.version',
    },
    {
      name: 'recipients',
      translateKey: 'informedConsent.formOptionsEmails',
    },
    {
      name: 'type',
      translateKey: 'informedConsent.labelType',
      enumKey: 'informedConsent.informedConsentTypes.',
    },
    {
      name: 'quizPageLayout',
      translateKey: 'informedConsent.labelQuices',
      enumKey: 'informedConsent.orderQuiz.',
    },
    {
      name: 'approvalSealFileUrl',
      translateKey: 'informedConsent.audit.variables.approvalSealFileUrl',
    },
    {
      name: 'isMultipleSignatureRequired',
      translateKey: 'informedConsent.isMultipleSignatures',
    },
    {
      name: 'minRequiredSignatures',
      translateKey: 'informedConsent.audit.variables.minRequiredSignatures',
    },
    {
      name: 'maxRequiredSignatures',
      translateKey: 'informedConsent.audit.variables.maxRequiredSignatures',
    },
    {
      name: 'site',
      translateKey: 'informedConsent.audit.variables.site',
    },
    {
      name: 'totalSubjects',
      translateKey: 'informedConsent.audit.variables.totalSubjects',
    },
    {
      name: 'state',
      translateKey: 'informedConsent.audit.variables.state',
      enumKey: 'informedConsent.audit.variables.enums.confState.',
    },
  ];
  public static readonly CONF_IC_SECTION_VARS: TP2ModelVO[] = [
    {
      name: 'title',
      translateKey: 'informedConsent.sections.sectionTitle',
    },
    {
      name: 'type',
      translateKey: 'informedConsent.sections.sectionType',
      enumKey: 'informedConsent.sections.sectionTypes.',
    },
    {
      name: 'order',
      translateKey: 'informedConsent.sections.sectionOrder',
    },
    {
      name: 'content',
      translateKey: 'informedConsent.sections.sectionContent',
    },
    {
      name: 'enableApprovalQuestions',
      translateKey: 'informedConsent.sections.sectionEnableApprovalQuestions',
    },
    {
      name: 'mediaType',
      translateKey: 'informedConsent.sections.sectionMediaType',
      enumKey: 'ediary.enums.mediaTypes.',
    },
    {
      name: 'mediaUrl',
      translateKey: 'informedConsent.currentFile',
    },
    {
      name: 'state',
      translateKey: 'informedConsent.audit.variables.state',
      enumKey: 'informedConsent.audit.variables.enums.confState.',
    },
  ];
  public static readonly CONF_IC_QUESTION_VARS: TP2ModelVO[] = [
    {
      name: 'description',
      translateKey: 'informedConsent.question.questionDescription',
    },
    {
      name: 'order',
      translateKey: 'informedConsent.question.questionOrder',
    },
    {
      name: 'state',
      translateKey: 'informedConsent.audit.variables.state',
      enumKey: 'informedConsent.audit.variables.enums.confState.',
    },
  ];
  public static readonly CONF_IC_ANSWER_VARS: TP2ModelVO[] = [
    {
      name: 'description',
      translateKey: 'informedConsent.answer.answerDescription',
    },
    {
      name: 'order',
      translateKey: 'informedConsent.answer.answerOrder',
    },
    {
      name: 'onErrorMessage',
      translateKey: 'informedConsent.answer.answerOnError',
    },
    {
      name: 'isCorrect',
      translateKey: 'informedConsent.answer.answerIsCorrect',
    },
    {
      name: 'order',
      translateKey: 'informedConsent.answer.answerOrder',
    },
    {
      name: 'state',
      translateKey: 'informedConsent.audit.variables.state',
      enumKey: 'informedConsent.audit.variables.enums.confState.',
    },
  ];
  public static readonly INFORMED_CONSENT_INSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'consentedIdentificator',
      translateKey: 'informedConsent.followUp.table.cols.subjectsIdentifier',
    },
    {
      name: 'consentedName',
      translateKey: 'informedConsent.followUp.table.cols.subjectName',
    },
    {
      name: 'consentedBirthDate',
      translateKey: 'informedConsent.followUp.table.cols.subjectBithDate',
    },
    {
      name: 'consenterName',
      translateKey: 'informedConsent.audit.variables.consenterName',
    },
    {
      name: 'consenterIdentification',
      translateKey: 'informedConsent.audit.variables.consenterIdentification',
    },
    {
      name: 'consenterUserId',
      translateKey: 'informedConsent.audit.variables.consenterUserId',
    },
    {
      name: 'consenterRole',
      translateKey: 'informedConsent.audit.variables.consenterRole',
    },
    {
      name: 'consenterSignature',
      translateKey: 'informedConsent.audit.variables.consenterSignature',
    },
    {
      name: 'state',
      translateKey: 'user.labelState',
      enumKey: 'informedConsent.enums.states.',
    },
    {
      name: 'order',
      translateKey: 'informedConsent.answer.answerOrder',
    },
  ];
  public static readonly IC_SECTION_INSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'consentedSignatureDate',
      translateKey: 'informedConsent.audit.variables.consentedSignatureDate',
      isDate: true,
    },
    {
      name: 'complementaryQuestionsAnswers',
      translateKey:
        'informedConsent.audit.variables.complementaryQuestionsAnswers',
    },
    {
      name: 'startDate',
      translateKey: 'informedConsent.followUp.executionSummary.startDate',
      isDate: true,
    },
    {
      name: 'completedDate',
      translateKey: 'subject.completedDate',
      isDate: true,
    },
    {
      name: 'isCompleted',
      translateKey: 'informedConsent.audit.variables.isCompleted',
    },
    {
      name: 'progress',
      translateKey: 'informedConsent.audit.variables.progress',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
  ];
  public static readonly IC_QUESTION_INSTANCE_VARS: TP2ModelVO[] = [
    {
      name: 'failedAttempts',
      translateKey: 'informedConsent.audit.variables.failedAttempts',
      isDate: true,
    },
    {
      name: 'isCompleted',
      translateKey: 'informedConsent.audit.variables.isCompleted',
    },
    {
      name: 'startDate',
      translateKey: 'informedConsent.followUp.executionSummary.startDate',
      isDate: true,
    },
    {
      name: 'completedDate',
      translateKey: 'subject.completedDate',
      isDate: true,
    },
    {
      name: 'isCompleted',
      translateKey: 'informedConsent.audit.variables.isCompleted',
    },
    {
      name: 'state',
      translateKey: 'general.configurations.enums.state',
      enumKey: 'general.instanceState.',
    },
  ];
  public static readonly CONSENTED_USER_VARS: TP2ModelVO[] = [
    {
      name: 'name',
      translateKey: 'informedConsent.followUp.table.cols.fullName',
    },
    {
      name: 'kinship',
      translateKey: 'informedConsent.audit.variables.kinship',
      enumKey: 'informedConsent.witnessesTypes.',
    },
    {
      name: 'identification',
      translateKey: 'informedConsent.audit.variables.identification',
    },
    {
      name: 'state',
      translateKey: 'informedConsent.audit.variables.state',
      enumKey: 'informedConsent.enums.states.',
    },
    {
      name: 'signature',
      translateKey: 'informedConsent.audit.variables.signature',
    },
    {
      name: 'requiresSendingEmail',
      translateKey: 'informedConsent.audit.variables.requiresSendingEmail',
    },
  ];
}

export type TP2ModelVO = {
  name: string;
  translateKey: string;
  enumKey?: string;
  ignore?: boolean;
  isDate?: boolean;
  isArray?: boolean;
  isDictionary?: boolean;
  isTranslate?: boolean;
};

export enum TP2_ERRORS {
  TP2ERRUC03 = 'TP2ERRUC03',
}
export interface Tp2Card {
  id: string;
  data?: any;
  textHeader: string;
  isButtonsEnabled: boolean;
  buttonItems: ButtonItem[];
  isMenuEnabled: boolean;
  menu: MenuItem[];
  content: Tp2CardContent[];
  borderColor?: string;
  iconHeader?: string;
  cardBackground?: string;
  isFooterEnabled?: boolean;
  footerContent?: string;
  islabelsEnabled?: string;
  icon?: string;
}

export interface ButtonItem {
  icon: string;
  color: string;
  tooltip: string;
  command: any;
  disabled: boolean;
}

export interface Tp2CardContent {
  icon: string;
  colorIcon?: string;
  title: string;
  description: string;
}

export interface InformedConsent {
  informedConsent: InformedConsentInstance;
  consentedUser?: ConsentedUser;
  confInformedConsent: ConfInformedConsent;
}
export const INTEGRATIONS_API: string = 'tp2IntegrationAPI';

export interface FeatureFlag {
  name: string;
  roles?: Array<Roles>;
}
export const FEATURE_FLAGS: FeatureFlag[] = [
  {
    name: 'maintenance-site',
    roles: [
      Roles.Investigator,
      Roles.Conciliator,
      Roles.Reader,
      Roles.Consented,
    ],
  },
  {
    name: 'maintenance-banner-site',
    roles: [
      Roles.Investigator,
      Roles.Conciliator,
      Roles.Reader,
      Roles.Consented,
    ],
  },
];
export interface MaintenanceInfo {
  enable: boolean;
  startDate?: string;
  endDate?: string;
}
