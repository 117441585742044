import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Logger } from 'aws-amplify';
import { ConciliationService } from '../../../../conciliation.service';

const logger = new Logger('tp2-logger-SubmiotConciliation');
@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss'],
})
export class FormButtonsComponent {
  @Input() formSubmited: any;
  @Input() isNextDisabled: boolean = false;
  @Input() isBackDisabled: boolean = false;
  @Output() nextData: EventEmitter<any> = new EventEmitter();
  @Output() previousData: EventEmitter<any> = new EventEmitter();
  subjectId: string = '';

  constructor(public conciliationService: ConciliationService) {}

  nextForm(_component: any) {
    logger.debug('formSub', this.formSubmited);
    this.nextData.emit(true);
  }

  previousForm() {
    this.previousData.emit(true);
  }

  cancel() {
    logger.debug('cancelling');
  }
}
