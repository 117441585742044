<div class="upload-input">
  <input
    type="file"
    id="input_file"
    class="input_file"
    accept="image/*"
    name="file"
    (change)="uploadImage($event)"
    [required]="isRequired"
    #inputElement
  />
  <div class="input_container">
    <div>
      <div *ngIf="!fileName" class="input_text">
        <span *ngIf="!typeError">{{ placeholder }}</span>
        <span class="input-text-invalid" *ngIf="typeError">{{
          'general.inputFileInvalid' | translate
        }}</span>
      </div>
      <div *ngIf="fileName" class="p-d-flex p-ai-center input-text">
        <div class="file_img">
          <img [src]="fileImg" [alt]="fileName" />
        </div>
        <div>
          <div class="bold">
            <span>{{ fileName | shortText: 20 }}</span>
          </div>
          <div>
            <span>{{ fileSize }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; gap: 0.5rem; align-items: center">
      <div>
        <button
          pButton
          icon="pi pi-trash"
          type="button"
          class="btn_delete"
          id="btn_deleteFile"
          (click)="deleteFile()"
        ></button>
      </div>
      <div>
        <label
          for="input_file"
          class="btn btn_file"
          id="btn_file"
          *ngIf="!fileName"
        >
          <span class="pi pi-link"> </span>
          <span>{{ 'informedConsent.btnSaveFile' | translate }}</span>
        </label>
        <label class="btn_file disabled" id="btn_file" *ngIf="fileName">
          <span class="pi pi-link"> </span>
          <span>{{ 'informedConsent.btnSaveFile' | translate }}</span>
        </label>
      </div>
    </div>
  </div>
  <small
    class="form-error"
    *ngIf="isRequired && ((!fileName && formSubmitted) || isDeletedFile)"
    >{{ 'general.required' | translate }}</small
  >
</div>
