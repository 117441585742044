import { Injectable } from '@angular/core';
import { API, graphqlOperation } from 'aws-amplify';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TP2PermissionActive } from 'src/app/services/user-permissions-service';
import { ModelUserFilterInput } from '../user/user.types';
import { ROLES_QUERIES } from './roles.queries';
import { CreateRoleInput, InstanceState, UpdateRoleInput } from './roles.types';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private authService: AuthService) {}

  getPermissions() {
    const data: any[] = [];
    Object.keys(TP2PermissionActive).forEach((e) => {
      data.push({ name: e });
    });
    return data;
  }

  async getRolesForCreateEditUser(projectId?: any) {
    if (projectId) {
      let filter = {
        isForProjects: {
          eq: false,
        },
        isEnabled: {
          eq: true,
        },
      };
      const rolesForProject = await this.listRoles(projectId);
      const globalRoles = await this.listRoles(undefined, filter);

      // Concatenar y eliminar duplicados por id
      const allRoles = rolesForProject.concat(globalRoles);
      const uniqueRoles = allRoles.filter(
        (currentRole, index, self) =>
          index === self.findIndex((role) => role.id === currentRole.id)
      );

      return uniqueRoles;
    }

    return this.listRoles();
  }

  async getRoles(projectId?: any): Promise<any> {
    if (projectId) {
      return this.listRoles(projectId);
    }

    return await this.listRoles();
  }

  async listRoles(projectId?: any, inputFilter?: any) {
    let filter = undefined;
    if (projectId && !inputFilter) {
      filter = {
        isForProjects: {
          eq: true,
        },
        projects: {
          contains: projectId,
        },
      };
    }
    let instances: any[] = [];
    let response = await this.performGraphQLQuery(ROLES_QUERIES.ListRoles, {
      filter,
      limit: 1000,
    });
    let listQuery = response.data.listRoles;
    instances = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.performGraphQLQuery(ROLES_QUERIES.ListRoles, {
        filter,
        limit: 1000,
        nextToken,
      });
      listQuery = response.data.listRoles;
      instances = instances.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  async createRole(inputRole: CreateRoleInput): Promise<any> {
    inputRole._lastUser = this.authService.getUsername();
    const response = await this.performGraphQLQuery(ROLES_QUERIES.CreateRole, {
      input: inputRole,
    });
    return response.data.createRole;
  }

  async deleteRolById(roleId: string, _version: any): Promise<any> {
    const roleInput: UpdateRoleInput = {
      id: roleId,
      state: InstanceState.DELETED,
      _version,
    };
    const response = await this.performGraphQLQuery(ROLES_QUERIES.UpdateRole, {
      input: roleInput,
    });
    return response.data.updateRole;
  }

  async getRoleById(id: string): Promise<any> {
    const response = await this.performGraphQLQuery(ROLES_QUERIES.GetRole, {
      id,
    });
    return response.data.getRole;
  }

  async editRole(role: any, _version: any): Promise<any> {
    let roleInput: UpdateRoleInput = {
      id: '',
      _version,
    };
    roleInput = Object.assign(roleInput, role);
    const response = await this.performGraphQLQuery(ROLES_QUERIES.UpdateRole, {
      input: roleInput,
    });
    return response.data.updateRole;
  }

  async getUsersByRole(roleId: string) {
    const filter: ModelUserFilterInput = {
      roles: {
        contains: roleId,
      },
    };
    let instances: any[] = [];
    let response = await this.performGraphQLQuery(ROLES_QUERIES.ListUsers, {
      filter,
      limit: 1000,
    });
    let listQuery = response.data.listUsers;
    instances = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.performGraphQLQuery(ROLES_QUERIES.ListUsers, {
        filter,
        limit: 1000,
        nextToken,
      });
      listQuery = response.data.listUsers;
      instances = instances.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
