export const EDIARY_QUERIES = {
  GetConfSymptom: `query GetConfSymptom($id: ID!) {
        getConfSymptom(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          helpScreenDisplayOptions
          buttonHelpScreenLabel
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          dataInputComponent
          state
          isRangeAlertActive
          minValueForAlert
          maxValueForAlert
          alertText
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfFormByConfSymptom: `query ConfFormByConfSymptom($confSymptomId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String) {
        confFormByConfSymptom(confSymptomId: $confSymptomId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confFormId
            confSymptomId
            projectId
            id
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  GetConfEDiary: `query GetConfEDiary($id: ID!) {
        getConfEDiary(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          assessmentMode
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfEdiaryByProjectIdCustom: `query ConfEdiaryByProjectIdCustom($projectId: ID!) {
        confEdiaryByProject(projectId: $projectId) {
          __typename
          items {
            __typename
            projectId
            windows {
              __typename
              name
              phases
              daysAfterVisit
              totalWindowDays
            }
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  CreateConfEDiary: `mutation CreateConfEDiary($input: CreateConfEDiaryInput!, $condition: ModelConfEDiaryConditionInput) {
        createConfEDiary(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          windows {
            __typename
            name
            phases
            daysAfterVisit
            totalWindowDays
          }
          isConciliationRequired
          isWindowsConciliationRequired
          assessmentMode
          id
          isSignatureRequired
          isPastEntryEnabled
          isGPSRequired
          isUserIPRequired
          isDeviceDataRequired
          enableLocalNotifications
          alertType
          alertWeeklyDays
          alertInBetweenDaysFrequency
          alertDuringSameDayFrequency
          alertStartTime
          alertEndTime
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfMedication: `mutation CreateConfMedication($input: CreateConfMedicationInput!, $condition: ModelConfMedicationConditionInput) {
        createConfMedication(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            helpScreenDisplayOptions
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showPrecondition
          mode
          showRememberStartDate
          showRememberFinishDate
          showDose
          showFrequency
          showAdministrationRoute
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfMedicalAttention: `mutation CreateConfMedicalAttention($input: CreateConfMedicalAttentionInput!, $condition: ModelConfMedicalAttentionConditionInput) {
        createConfMedicalAttention(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            helpScreenDisplayOptions
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          isBindToSymptomOcurrencies
          showRememberStartDate
          showRememberFinishDate
          showHospitalization
          mode
          showPrecondition
          showType
          medicalAttentionTypes
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfOtherSymptoms: `mutation CreateConfOtherSymptoms($input: CreateConfOtherSymptomsInput!, $condition: ModelConfOtherSymptomsConditionInput) {
        createConfOtherSymptoms(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            helpScreenDisplayOptions
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showRememberStartDate
          showRememberFinishDate
          showProfessionalHealthCare
          showType
          showIntensity
          showOtherSymptom
          showPrecondition
          preconditionLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfMultimedia: `mutation CreateConfMultimedia($input: CreateConfMultimediaInput!, $condition: ModelConfMultimediaConditionInput) {
        createConfMultimedia(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            helpScreenDisplayOptions
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          showDetail
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfTemperature: `mutation CreateConfTemperature($input: CreateConfTemperatureInput!, $condition: ModelConfTemperatureConditionInput) {
        createConfTemperature(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confFormId
          confForm {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            helpScreenDisplayOptions
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          helpText
          label
          dayRule
          routeOptions
          showTemperatureRoute
          ocurrencyValue
          ocurrencyUnit
          showTemperatureTaken
          showTemperatureTakenDate
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfMedicationByConfEdiary: `query ConfMedicationByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
        confMedicationByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfMedicationByConfForm: `query ConfMedicationByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
        confMedicationByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfMultimediaByConfForm: `query ConfMultimediaByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMultimediaFilterInput, $limit: Int, $nextToken: String) {
        confMultimediaByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showDetail
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfMedicalAttentionByConfForm: `query ConfMedicalAttentionByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
        confMedicalAttentionByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfMedicalAttentionByConfEdiary: `query ConfMedicalAttentionByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
        confMedicalAttentionByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfOtherSymptomsByConfEdiary: `query ConfOtherSymptomsByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String) {
        confOtherSymptomsByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfOtherSymptomsByConfForm: `query ConfOtherSymptomsByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfOtherSymptomsFilterInput, $limit: Int, $nextToken: String) {
        confOtherSymptomsByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showRememberStartDate
            showRememberFinishDate
            showProfessionalHealthCare
            showType
            showIntensity
            showOtherSymptom
            showPrecondition
            preconditionLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfTemperatureByConfForm: `query ConfTemperatureByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String) {
    confTemperatureByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        dayRule
        routeOptions
        units
        showFahrenheitUnit
        showTemperatureRoute
        ocurrencyValue
        ocurrencyUnit
        showTemperatureTaken
        showTemperatureTakenDate
        state
        showHelpScreen
        helpScreenText
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        allowExitToTakeTemperature
        alertExitToTakeTemperatureText
        isExpectedRangeActive
        alertTextForUnexpectedValues
        isSeriousRangeActive
        alertTextForSeriousValues
        dataInputComponent
        celciusRanges {
          minValue
          maxValue
          decimalPlaces
          expectedMinValue
          expectedMaxValue
          seriousMinValue
          seriousMaxValue
        }
        fahrenheitRanges {
          minValue
          maxValue
          decimalPlaces
          expectedMinValue
          expectedMaxValue
          seriousMinValue
          seriousMaxValue
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,

  ConfMultimediaByConfEdiary: `query ConfMultimediaByConfEdiary($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMultimediaFilterInput, $limit: Int, $nextToken: String) {
    confMultimediaByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        showDetail
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  UpdateConfMedication: `mutation UpdateConfMedication($input: UpdateConfMedicationInput!, $condition: ModelConfMedicationConditionInput) {
    updateConfMedication(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      helpText
      label
      showPrecondition
      mode
      showRememberStartDate
      showRememberFinishDate
      showDose
      showFrequency
      showAdministrationRoute
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfOtherSymptoms: `mutation UpdateConfOtherSymptoms($input: UpdateConfOtherSymptomsInput!, $condition: ModelConfOtherSymptomsConditionInput) {
    updateConfOtherSymptoms(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      helpText
      label
      showRememberStartDate
      showRememberFinishDate
      showProfessionalHealthCare
      showType
      showIntensity
      showOtherSymptom
      showPrecondition
      preconditionLabel
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfMedicalAttention: `mutation UpdateConfMedicalAttention($input: UpdateConfMedicalAttentionInput!, $condition: ModelConfMedicalAttentionConditionInput) {
    updateConfMedicalAttention(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      helpText
      label
      isBindToSymptomOcurrencies
      showRememberStartDate
      showRememberFinishDate
      showHospitalization
      mode
      showPrecondition
      showType
      medicalAttentionTypes
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfMultimedia: `mutation UpdateConfMultimedia($input: UpdateConfMultimediaInput!, $condition: ModelConfMultimediaConditionInput) {
    updateConfMultimedia(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      helpText
      label
      showDetail
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateEDiaryPhaseInstance: `mutation UpdateEDiaryPhaseInstance($input: UpdateEDiaryPhaseInstanceInput!, $condition: ModelEDiaryPhaseInstanceConditionInput) {
    updateEDiaryPhaseInstance(input: $input, condition: $condition) {
      __typename
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      eDiaryInstanceId
      eDiaryInstance {
        __typename
        subjectId
        confEDiaryId
        siteId
        id
        state
        confEdiaryVersion
        comment
        alerts
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      siteId
      site {
        __typename
        projectId
        id
        name
        contactInfo
        showContactInfo
        showContactInfoLogin
        timezone
        defaultLanguage
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      phase
      pdfFiles {
        __typename
        uploadDate
        fileUrl
      }
      pdfState
      completedPhaseDate
      suspensionReason
      suspensionDate
      suspensionUser
      locked {
        __typename
        user
        date
      }
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfTemperature: `mutation UpdateConfTemperature($input: UpdateConfTemperatureInput!, $condition: ModelConfTemperatureConditionInput) {
    updateConfTemperature(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        helpScreenDisplayOptions
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      helpText
      label
      dayRule
      routeOptions
      units
      showTemperatureRoute
      ocurrencyValue
      ocurrencyUnit
      showTemperatureTaken
      showTemperatureTakenDate
      state
      showHelpScreen
      helpScreenText
      helpScreenDisplayOptions
      buttonHelpScreenLabel
      allowExitToTakeTemperature
      alertExitToTakeTemperatureText
      celciusRanges {
        __typename
        minValue
        maxValue
        decimalPlaces
        expectedMinValue
        expectedMaxValue
        seriousMinValue
        seriousMaxValue
      }
      fahrenheitRanges {
        __typename
        minValue
        maxValue
        decimalPlaces
        expectedMinValue
        expectedMaxValue
        seriousMinValue
        seriousMaxValue
      }
      isExpectedRangeActive
      alertTextForUnexpectedValues
      isSeriousRangeActive
      alertTextForSeriousValues
      dataInputComponent
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,

  UpdateConfEDiary: `mutation UpdateConfEDiary($input: UpdateConfEDiaryInput!, $condition: ModelConfEDiaryConditionInput) {
    updateConfEDiary(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      windows {
        __typename
        name
        phases
        daysAfterVisit
        totalWindowDays
      }
      isConciliationRequired
      isWindowsConciliationRequired
      assessmentMode
      id
      isSignatureRequired
      isPastEntryEnabled
      isGPSRequired
      isUserIPRequired
      isDeviceDataRequired
      enableLocalNotifications
      alertType
      alertWeeklyDays
      alertInBetweenDaysFrequency
      alertDuringSameDayFrequency
      alertStartTime
      alertEndTime
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfSymptomByConfEdiaryIdCustom: `query ConfSymptomByConfEdiaryIdCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfSymptomFilterInput, $limit: Int, $nextToken: String) {
    confSymptomByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        symptomHelp
        symptomLabel
        symptomIcon
        isIntensityRequired
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isFinishDateRequired
        isStartDateRequired
        _lastUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        showNoValuesTaken
        noValuesTakenLabel
        isIntensityQuestionsRequired
        alertText
        maxValueForAlert
        minValueForAlert
        isRangeAlertActive
        intensityQuestions {
          __typename
          id
          order
          question
          intensity
        }
        noneIntensityScaleOptions {
          __typename
          active
          startValue
          endValue
          helpText
          label
        }
        lowIntensityScaleOptions {
          __typename
          active
          startValue
          endValue
          helpText
          label
        }
        mediumIntensityScaleOptions {
          __typename
          active
          startValue
          endValue
          helpText
          label
        }
        highIntensityScaleOptions {
          __typename
          active
          startValue
          endValue
          helpText
          label
        }
        lifeThreateningScaleOptions {
          __typename
          active
          startValue
          endValue
          helpText
          label
        }
        dataInputComponent
        isRangeAlertActive
        minValueForAlert
        maxValueForAlert
        alertText
      }
      nextToken
      startedAt
    }
  }`,
  ConfSymptomByConfFormId: `query ConfSymptomByConfFormId($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormConfSymptomFilterInput, $limit: Int, $nextToken: String) {
    confSymptomByConfFormId(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confFormId
        confSymptomId
        projectId
        id
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfFormConfSymptomsByConfSymptomIdCustom: `query ConfFormConfSymptomsByConfSymptomIdCustom($confSymptomId: ID!) {
    listConfFormConfSymptoms(filter: {confSymptomId: {eq: $confSymptomId}}) {
      __typename
      items {
        __typename
        id
        confFormId
        confSymptomId
        _version
      }
    }
  }`,
  CreateConfSymptom: `mutation CreateConfSymptom($input: CreateConfSymptomInput!, $condition: ModelConfSymptomConditionInput) {
    createConfSymptom(input: $input, condition: $condition) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      type
      symptom
      order
      injectionSite
      injectionSide
      showHelp
      helpScreenDisplayOptions
      buttonHelpScreenLabel
      symptomHelp
      symptomLabel
      symptomIcon
      decimalPlaces
      minSize
      maxSize
      intensityType
      intensitySizeLabel
      noneIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lowIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      mediumIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      highIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lifeThreateningScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      intensityQuestions {
        __typename
        id
        order
        question
        intensity
      }
      isIntensityQuestionsRequired
      isIntensityRequired
      isStartDateRequired
      isFinishDateRequired
      showNoValuesTaken
      noValuesTakenLabel
      state
      isRangeAlertActive
      minValueForAlert
      maxValueForAlert
      alertText
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfSymptom: `mutation UpdateConfSymptom($input: UpdateConfSymptomInput!, $condition: ModelConfSymptomConditionInput) {
    updateConfSymptom(input: $input, condition: $condition) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      type
      symptom
      order
      injectionSite
      injectionSide
      showHelp
      helpScreenDisplayOptions
      buttonHelpScreenLabel
      symptomHelp
      helpScreenDisplayOptions
      buttonHelpScreenLabel
      symptomLabel
      symptomIcon
      decimalPlaces
      minSize
      maxSize
      intensityType
      intensitySizeLabel
      noneIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lowIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      mediumIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      highIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lifeThreateningScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      intensityQuestions {
        __typename
        id
        order
        question
        intensity
      }
      isIntensityQuestionsRequired
      isIntensityRequired
      isStartDateRequired
      isFinishDateRequired
      showNoValuesTaken
      noValuesTakenLabel
      state
      isRangeAlertActive
      minValueForAlert
      maxValueForAlert
      alertText
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  DeleteConfSymptom: `mutation DeleteConfSymptom($input: DeleteConfSymptomInput!, $condition: ModelConfSymptomConditionInput) {
    deleteConfSymptom(input: $input, condition: $condition) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      type
      symptom
      order
      injectionSite
      injectionSide
      showHelp
      helpScreenDisplayOptions
      buttonHelpScreenLabel
      symptomHelp
      symptomLabel
      symptomIcon
      decimalPlaces
      minSize
      maxSize
      intensityType
      intensitySizeLabel
      noneIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lowIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      mediumIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      highIntensityScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      lifeThreateningScaleOptions {
        __typename
        active
        startValue
        endValue
        helpText
        label
      }
      intensityQuestions {
        __typename
        id
        order
        question
        intensity
      }
      isIntensityQuestionsRequired
      isIntensityRequired
      isStartDateRequired
      isFinishDateRequired
      showNoValuesTaken
      noValuesTakenLabel
      state
      isRangeAlertActive
      minValueForAlert
      maxValueForAlert
      alertText
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfDayByConfEdiaryCustom: `query ConfDayByConfEdiaryCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfDayFilterInput, $limit: Int, $nextToken: String) {
    confDayByConfEdiary(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confEdiaryId
        confFormId
        confForm {
          __typename
          id
          name
          isReconciliationRequired
        }
        id
        dayName
        order
        startDay
        endDay
        trackSymptomOcurrencies
        hidePDFDayColumn
        groups
        phases
        _lastUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,

  CreateConfDay: `mutation CreateConfDay($input: CreateConfDayInput!, $condition: ModelConfDayConditionInput) {
    createConfDay(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      dayName
      order
      startDay
      endDay
      groups
      phases
      trackSymptomOcurrencies
      hidePDFDayColumn
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfDay: `mutation UpdateConfDay($input: UpdateConfDayInput!, $condition: ModelConfDayConditionInput) {
    updateConfDay(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      dayName
      order
      startDay
      endDay
      groups
      phases
      trackSymptomOcurrencies
      hidePDFDayColumn
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  DeleteConfDay: `mutation DeleteConfDay($input: DeleteConfDayInput!, $condition: ModelConfDayConditionInput) {
    deleteConfDay(input: $input, condition: $condition) {
      __typename
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      dayName
      order
      startDay
      endDay
      groups
      phases
      trackSymptomOcurrencies
      hidePDFDayColumn
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfFormByConfEdiaryId: `query ConfFormByConfEdiaryId($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfFormFilterInput, $limit: Int, $nextToken: String) {
        confFormByConfEdiaryId(
          confEdiaryId: $confEdiaryId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            confEdiaryId
            projectId
            id
            name
            isTemperatureRequired
            isMedicationRequired
            isMedicalAttentionRequired
            isMediaRequired
            isReconciliationRequired
            sectionsToReconcile
            areCommentsRequired
            isOtherForm
            mediaTypes
            areOtherSymptomsRequired
            symptomPageLayout
            showHelp
            formHelp
            helpScreenDisplayOptions
            state
            symptomsReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowOcurencyDateReconciliation
              allowIntensityReconciliation {
                allow
                allowedAction
              }
            }
            localSymptomsReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowOcurencyDateReconciliation
              allowIntensityReconciliation {
                allow
                allowedAction
              }
            }
            temperatureReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowIntensityReconciliation {
                allow
                allowedAction
              }
              allowRouteReconciliation
              allowOcurencyDateReconciliation
            }
            medicationReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowDosisReconciliation
              allowOcurencyDateReconciliation
              allowRouteReconciliation
              allowTreatmentReconciliation
              allowReasonReconciliation
            }
            medicalAttentionReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowTypeReconciliation
              allowSymptomOccurencyReconciliation
              allowOcurencyDateReconciliation
              allowHospitalizationReconciliation
              allowReasonReconciliation
            }
            otherSymptomsReconciliationConfig {
              allowAddRecords
              allowRemoveRecords
              allowOcurencyDateReconciliation
              allowHospitalizationReconciliation
              allowIntensityReconciliation {
                allow
                allowedAction
              }
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  CreateConfForm: `mutation CreateConfForm($input: CreateConfFormInput!, $condition: ModelConfFormConditionInput) {
        createConfForm(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            isSubjectNumberCheckRequired
            subjectNumberRegex
            subjectNumberPlaceholder
            checkSiteCodeInSubjectNumber
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowRouteReconciliation
            allowOcurencyDateReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowDosisReconciliation
            allowOcurencyDateReconciliation
            allowRouteReconciliation
            allowTreatmentReconciliation
            allowReasonReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowTypeReconciliation
            allowSymptomOccurencyReconciliation
            allowOcurencyDateReconciliation
            allowHospitalizationReconciliation
            allowReasonReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
          }
          localSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
          }
          otherSymptomsReconciliationConfig {
            __typename
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowHospitalizationReconciliation
          }
          symptomPageLayout
          showHelp
          formHelp
          helpScreenDisplayOptions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateConfFormConfSymptom: `mutation CreateConfFormConfSymptom($input: CreateConfFormConfSymptomInput!, $condition: ModelConfFormConfSymptomConditionInput) {
    createConfFormConfSymptom(input: $input, condition: $condition) {
      __typename
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  DeleteConfFormConfSymptom: `mutation DeleteConfFormConfSymptom($input: DeleteConfFormConfSymptomInput!, $condition: ModelConfFormConfSymptomConditionInput) {
    deleteConfFormConfSymptom(input: $input, condition: $condition) {
      __typename
      confFormId
      confForm {
        __typename
        confEdiaryId
        projectId
        id
        name
        isTemperatureRequired
        isMedicationRequired
        isMedicalAttentionRequired
        isMediaRequired
        isReconciliationRequired
        sectionsToReconcile
        areCommentsRequired
        isOtherForm
        helpScreenDisplayOptions
        mediaTypes
        areOtherSymptomsRequired
        symptomPageLayout
        showHelp
        formHelp
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfForm: `mutation UpdateConfForm($input: UpdateConfFormInput!, $condition: ModelConfFormConditionInput) {
        updateConfForm(input: $input, condition: $condition) {
          __typename
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            assessmentMode
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            isSubjectNumberCheckRequired
            subjectNumberRegex
            subjectNumberPlaceholder
            checkSiteCodeInSubjectNumber
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          name
          isTemperatureRequired
          temperatureReconciliationConfig {
            allowAddRecords
            allowRemoveRecords
            allowIntensityReconciliation {
              allow
              allowedAction
            }
            allowRouteReconciliation
            allowOcurencyDateReconciliation
          }
          isMedicationRequired
          medicationReconciliationConfig {
            allowAddRecords
            allowRemoveRecords
            allowDosisReconciliation
            allowOcurencyDateReconciliation
            allowRouteReconciliation
            allowTreatmentReconciliation
            allowReasonReconciliation
          }
          isMedicalAttentionRequired
          medicalAttentionReconciliationConfig {
            allowAddRecords
            allowRemoveRecords
            allowTypeReconciliation
            allowSymptomOccurencyReconciliation
            allowOcurencyDateReconciliation
            allowHospitalizationReconciliation
            allowReasonReconciliation
          }
          isMediaRequired
          isReconciliationRequired
          sectionsToReconcile
          areCommentsRequired
          isOtherForm
          mediaTypes
          areOtherSymptomsRequired
          symptomsReconciliationConfig {
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowIntensityReconciliation {
              allow
              allowedAction
            }
          }
          localSymptomsReconciliationConfig {
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowIntensityReconciliation {
              allow
              allowedAction
            }
          }
          otherSymptomsReconciliationConfig {
            allowAddRecords
            allowRemoveRecords
            allowOcurencyDateReconciliation
            allowHospitalizationReconciliation
            allowIntensityReconciliation {
              allow
              allowedAction
            }
          }
          symptomPageLayout
          showHelp
          formHelp
          helpScreenDisplayOptions
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfAlertByConfEdiaryIdCustom: `query ConfAlertByConfEdiaryIdCustom($confEdiaryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
    confAlertByConfEdiaryId(confEdiaryId: $confEdiaryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confReportId
        confEdiaryId
        confSymptomId
        confTemperatureId
        projectId
        id
        state
        alertName
        alertRules {
          __typename
          ruleId
          alertRuleType
          expectedValue
        }
        visitList {
          __typename
          order
          visitName
        }
        suspendVisitList {
          __typename
          order
          visitName
        }
        siteRecipients {
          __typename
          recipients
          siteId
        }
        triggerVisitCompletion
        triggerEDiarySuspension
        recipients
        type
        mailSubject
        mailBody
        SMSBody
        _lastUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,
  CreateConfAlert: `mutation CreateConfAlert($input: CreateConfAlertInput!, $condition: ModelConfAlertConditionInput) {
    createConfAlert(input: $input, condition: $condition) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confTemperatureId
      confTemperature {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        dayRule
        routeOptions
        showFahrenheitUnit
        showTemperatureRoute
        ocurrencyValue
        ocurrencyUnit
        showTemperatureTaken
        showTemperatureTakenDate
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      alertName
      recipients
      siteRecipients {
        __typename
        siteId
        recipients
      }
      type
      mailSubject
      mailBody
      SMSBody
      alertRules {
        __typename
        ruleId
        alertRuleType
        expectedValue
        expectedUnit
      }
      triggerVisitCompletion
      triggerEDiarySuspension
      visitList {
        __typename
        order
        visitName
      }
      suspendVisitList {
        __typename
        order
        visitName
      }
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfAlert: `mutation UpdateConfAlert($input: UpdateConfAlertInput!, $condition: ModelConfAlertConditionInput) {
    updateConfAlert(input: $input, condition: $condition) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confTemperatureId
      confTemperature {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        dayRule
        routeOptions
        showFahrenheitUnit
        showTemperatureRoute
        ocurrencyValue
        ocurrencyUnit
        showTemperatureTaken
        showTemperatureTakenDate
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      alertName
      recipients
      siteRecipients {
        __typename
        siteId
        recipients
      }
      type
      mailSubject
      mailBody
      SMSBody
      alertRules {
        __typename
        ruleId
        alertRuleType
        expectedValue
        expectedUnit
      }
      triggerVisitCompletion
      triggerEDiarySuspension
      visitList {
        __typename
        order
        visitName
      }
      suspendVisitList {
        __typename
        order
        visitName
      }
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  DeleteConfAlert: `mutation DeleteConfAlert($input: DeleteConfAlertInput!, $condition: ModelConfAlertConditionInput) {
    deleteConfAlert(input: $input, condition: $condition) {
      __typename
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confEdiaryId
      confEdiary {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confSymptomId
      confSymptom {
        __typename
        confReportId
        confEdiaryId
        projectId
        id
        type
        symptom
        order
        injectionSite
        injectionSide
        showHelp
        helpScreenDisplayOptions
        buttonHelpScreenLabel
        symptomHelp
        symptomLabel
        symptomIcon
        decimalPlaces
        minSize
        maxSize
        intensityType
        intensitySizeLabel
        isIntensityQuestionsRequired
        isIntensityRequired
        isStartDateRequired
        isFinishDateRequired
        showNoValuesTaken
        noValuesTakenLabel
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confTemperatureId
      confTemperature {
        __typename
        confEdiaryId
        confFormId
        projectId
        id
        helpText
        label
        dayRule
        routeOptions
        showFahrenheitUnit
        showTemperatureRoute
        ocurrencyValue
        ocurrencyUnit
        showTemperatureTaken
        showTemperatureTakenDate
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      alertName
      recipients
      siteRecipients {
        __typename
        siteId
        recipients
      }
      type
      mailSubject
      mailBody
      SMSBody
      alertRules {
        __typename
        ruleId
        alertRuleType
        expectedValue
        expectedUnit
      }
      triggerVisitCompletion
      triggerEDiarySuspension
      visitList {
        __typename
        order
        visitName
      }
      suspendVisitList {
        __typename
        order
        visitName
      }
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ConfEdiaryByProject: `query ConfEdiaryByProject($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfEDiaryFilterInput, $limit: Int, $nextToken: String) {
    confEdiaryByProject(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        isConciliationRequired
        isWindowsConciliationRequired
        assessmentMode
        id
        isSignatureRequired
        isPastEntryEnabled
        isGPSRequired
        isUserIPRequired
        isDeviceDataRequired
        enableLocalNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ListDayInstancesCustom: `query ListDayInstancesCustom($filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
    listDayInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        subject {
          __typename
          subjectNumber
          id
          state
          projectId
          siteId
          currentScheduledPhase {
            __typename
            date
            phase
            state
          }
          _deleted
        }
        eDiaryPhaseInstanceId
        eDiaryPhaseInstance {
          __typename
          phase
          state
        }
        confDayId
        confDay {
          __typename
          dayName
        }
        completedDate
        siteId
        id
        state
        startDate
        finishDate
        userIP
        gps
        appVersion
        confDayVersion
        hasMedication
        hasMedicalAttention
        hasOtherSymptoms
        _lastUser
        _deviceModel
        _deviceSOVersion
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,
  AlertInstanceByConfAlertId: `query AlertInstanceByConfAlertId($confAlertId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
    alertInstanceByConfAlertId(confAlertId: $confAlertId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confAlertId
        subjectId
        id
        confAlert_data
        entityId
        entityName
        state
        revisionState
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  DayInstanceByConfDayId: `query DayInstanceByConfDayId($confDayId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
    dayInstanceByConfDayId(confDayId: $confDayId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        eDiaryPhaseInstanceId
        confDayId
        siteId
        id
        state
        startDate
        finishDate
        completedDate
        userIP
        gps
        appVersion
        confDayVersion
        hasMedication
        medicationGivenTo
        hasMedicalAttention
        hasOtherSymptoms
        isAlertChecked
        isPDFGenerated
        comments
        _lastUser
        _changeReason
        _deviceModel
        _deviceSOVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  CustomEDiaryInstanceBySubjectId: `query CustomEDiaryInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
    eDiaryInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        confEDiaryId
        siteId
        subject {
          __typename
          projectId
        }
        pdfFiles {
          __typename
          fileUrl
          uploadDate
        }
        id
        state
        confEdiaryVersion
        comment
        alerts
        _lastUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }`,
  ListSymptomInstances: `query ListSymptomInstances($filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
    listSymptomInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        subjectId
        reportInstanceId
        dayInstanceId
        confSymptomId
        id
        order
        state
        type
        symptom
        whichOtherSymptom
        intensity
        size
        rememberStartDate
        startDate
        rememberFinishDate
        finishDate
        _deviceModel
        _deviceSOVersion
        isOtherSymptom
        medications
        medicalAttentions
        professionalHealthCare
        hospitalAdmission
        createdAt
        rememberNoValuesTaken
        _lastUser
        _changeReason
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
};
