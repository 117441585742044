<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" class="form-style">
  <div class="p-grid p-fluid" style="margin: 0">
    <div class="p-offset-1 p-col-11" style="margin-top: 1rem">
      <a routerLink="/login">
        <em class="pi pi-chevron-left"> </em>
        {{ 'password.labelLogin' | translate }}
      </a>
    </div>
    <div class="p-offset-1 p-col-11">
      <h2>{{ 'password.h2RecoverPassword' | translate }}</h2>
      <h4 [hidden]="!step">
        {{ 'password.h4EnterUsername' | translate }}
      </h4>
      <h4 [hidden]="step">
        {{ 'password.h4EnterCodeSentEmail' | translate }}
      </h4>
    </div>

    <div class="p-col-10 p-offset-1 p-lg-4" [hidden]="!step">
      <h4>
        {{ 'password.labelUser' | translate }}
        <span class="inputRequired">* </span>
      </h4>
      <input
        name="usuario"
        type="text"
        autocomplete="off"
        pInputText
        [(ngModel)]="userId"
        [required]="step"
        #usuario="ngModel"
        [required]="!step"
        appAutoCompleteOff
      />
      <small
        class="form-error"
        *ngIf="!usuario.valid && (f.submitted || usuario.touched)"
      >
        {{ 'general.required' | translate }}
      </small>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4" [hidden]="step">
      <h4>
        {{ 'password.labelActivationCode' | translate }}
        <span class="inputRequired">* </span>
      </h4>
      <input
        name="code"
        type="text"
        autocomplete="off"
        pInputText
        [(ngModel)]="activationCode"
        [required]="!step"
        #code="ngModel"
        appAutoCompleteOff
      />
      <small
        class="form-error"
        *ngIf="!code.valid && (f.submitted || code.touched)"
      >
        {{ 'general.required' | translate }}
      </small>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4" [hidden]="step">
      <h4>
        {{ 'password.labelPassword' | translate }}
        <span class="inputRequired">* </span>
      </h4>
      <div class="p-inputgroup">
        <input
          name="clave"
          type="password"
          autocomplete="off"
          pPassword
          [(ngModel)]="newPassword"
          [showPassword]="isPasswordShown"
          [required]="!step"
          [feedback]="false"
          [pTooltip]="'password.pTooltipPassword' | translate"
          tooltipPosition="top"
          #pass1="ngModel"
          appAutoCompleteOff
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass1.errors &&
          pass1.errors.required &&
          (f.submitted || pass1.touched)
        "
      >
        {{ 'general.required' | translate }}
      </small>
      <h4 style="margin-top: 10px">
        {{ 'password.labelConfirmPassword' | translate }}
        <span class="inputRequired">* </span>
      </h4>
      <div class="p-inputgroup">
        <input
          name="clave"
          type="password"
          autocomplete="off"
          pPassword
          [(ngModel)]="passwordConfirmation"
          [showPassword]="isPasswordShown"
          [feedback]="false"
          [required]="!step"
          #pass2="ngModel"
          appAutoCompleteOff
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass2.errors &&
          pass2.errors.required &&
          (f.submitted || pass2.touched)
        "
      >
        {{ 'general.required' | translate }}
      </small>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-2 p-lg-offset-8">
      <button
        pButton
        [label]="'password.buttonSendCode' | translate"
        type="submit"
        class="p-button-secondary"
        *ngIf="step"
      ></button>
      <button
        pButton
        [label]="'password.buttonChangePassword' | translate"
        type="submit"
        class="p-button-secondary"
        *ngIf="!step"
      ></button>
    </div>
  </div>
</form>
