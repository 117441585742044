<main *ngIf="!showOnlyPDF">
  <figure src="./assets/icons/terms-and-conditions/integra-icon.svg"></figure>
  <section class="intro">
    <h1>{{ 'termsAndConditions.welcome' | translate }}</h1>
    <hr />
    <p>{{ 'termsAndConditions.welcomeText' | translate }}</p>
  </section>
  <section class="body">
    <form #myForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-4">
          <p-checkbox
            [(ngModel)]="accept"
            id="accept"
            name="accept"
            [binary]="true"
            #acceptHtml="ngModel"
            [required]="true"
            (onChange)="
              acceptHtml.value === false
                ? (showAlert = true)
                : (showAlert = false)
            "
          ></p-checkbox>
          <div class="content">
            <p>
              {{ 'termsAndConditions.checkboxTextInit' | translate }}
              <strong
                class="terms"
                (click)="openDocument('TERMS_AND_CONDITIONS')"
                >{{
                  'termsAndConditions.termsAndConditions' | translate
                }}</strong
              >
              {{ 'termsAndConditions.checkboxTextMiddle' | translate }}
              <strong
                class="policies"
                (click)="openDocument('PRIVACY_POLICIES')"
                >{{ 'termsAndConditions.privacyPolicies' | translate }}</strong
              >
              {{ 'termsAndConditions.checkboxTextEnd' | translate }}
            </p>
          </div>
        </div>
        <aside *ngIf="showAlert">
          <span></span>
          <p>{{ 'termsAndConditions.checkboxAlert' | translate }}</p>
        </aside>
        <div (click)="checkForm()">
          <button
            pButton
            [label]="'termsAndConditions.continue' | translate"
            type="submit"
            badgeClass="color: white;"
            class="p-button-rounded pbutton"
            [disabled]="!accept"
          ></button>
        </div>
      </div>
    </form>
  </section>
</main>
<div #outsideElement id="PDF">
  <div class="modal-view-container">
    <div
      #modalViewer
      class="modal view"
      [class.general-modal-view-details]="showOnlyPDF"
    >
      <div class="modal-view-details">
        <object
          [data]="PDFUrl"
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <iframe
            title=""
            width="100%"
            height="100%"
            onerror="errorLoad()"
            [src]="PDFUrl"
          >
          </iframe>
        </object>
      </div>
    </div>
  </div>
</div>
