<div class="p-p-4 formly-designer-main">
  <div class="p-mb-4" *ngIf="isJsonError">
    <p-message
      severity="error"
      text="Se encontro errores en el formulario JSON"
    ></p-message>
  </div>
  <div style="position: relative">
    <p-tabView id="closableTabView">
      <p-tabPanel
        [header]="
          (isSaving ? 'designer.updatingForm' : 'designer.btnForm') | translate
        "
        [selected]="true"
        [disabled]="isSaving"
      >
        <div class="p-p-3">
          <app-designer
            [projectId]="projectId"
            [formField]="formFieldDesginer"
            (formFieldChange)="formFieldDesignerChange($event)"
            #designer
          ></app-designer>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="'designer.btnJSON' | translate">
        <app-json-view
          [formField]="formFieldJson"
          (formFieldChange)="formFieldJSONChange($event)"
          #jsonEditor
        ></app-json-view>
      </p-tabPanel>
    </p-tabView>
    <button
      pButton
      pRipple
      id="btn_preview"
      [label]="'designer.btnJSONPreview' | translate"
      (click)="openPreviewComponent()"
      type="button"
      class="p-button-secondary btn-preview"
      style="width: max-content"
    ></button>
  </div>
</div>

<div class="p-d-flex p-justify-end">
  <button
    pButton
    type="button"
    class="btn btn-info"
    icon="pi pi-check"
    style="width: max-content"
    label="{{ 'designer.btnSaveInformation' | translate }}"
    [disabled]="isFormFieldInvalid || isJsonError"
    (click)="saveForm()"
  ></button>
</div>
