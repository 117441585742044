import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent {
  @Input() items: string[] = [];
  @Input() label: string = '';
  @Input() loading: boolean = false;
  cantItems: number = 5;

  show() {
    this.cantItems = this.cantItems === 5 ? this.items.length : 5;
  }
}
