import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { ListUserVersionsComponent } from './list-user-versions/list-user-versions.component';
import { VersionRoutingModule } from './version-routing.module';

@NgModule({
  declarations: [ListUserVersionsComponent],
  imports: [
    CommonModule,
    PrimengModule,
    SharedComponentsModule,
    VersionRoutingModule,
  ],
  providers: [TranslatePipe],
  exports: [ListUserVersionsComponent],
})
export class VersionModule {}
