<div class="container-tp2">
  <app-back-button-header
    [pageTitle]="
      (isEdition
        ? 'informedConsent.editInformedConsent'
        : 'informedConsent.addInformedConsent'
      ) | translate
    "
    [pageDescription]="
      'informedConsent.informedConsentCreationDescription' | translate
    "
    [pageUrl]="'/informed-consent/' + projectId"
    [showButton]="false"
  ></app-back-button-header>

  <div class="tp2Card">
    <form
      name="form"
      class="consent-form"
      (ngSubmit)="onSubmit(f)"
      #f="ngForm"
      autocomplete="off"
    >
      <!--Paso 1-->
      <p class="p-mt-0 p-mb-4 subtitle">
        <span class="p-pr-2">{{ 'informedConsent.step1' | translate }}</span>
        <span>{{ 'informedConsent.step1Description' | translate }}</span>
      </p>
      <div class="grid-form mb-5">
        <div>
          <label for="informedConsentTitle"
            >{{ 'informedConsent.labelTitle' | translate
            }}<span class="inputRequired"> * </span></label
          >
          <app-simple-dictionary-input
            id="txt_consent_name"
            name="title"
            [projectId]="projectId"
            [isReadOnly]="isReadOnly"
            [isRequired]="true"
            [value]="input.name"
            [submitted]="f.submitted"
            (outputEvent)="setNameAttribute($event)"
            [placeholder]="'informedConsent.placeholderName' | translate"
          ></app-simple-dictionary-input>
        </div>
        <div>
          <label for="informedConsentType"
            >{{ 'informedConsent.labelType' | translate
            }}<span class="inputRequired"> * </span></label
          >
          <p-dropdown
            [disabled]="isReadOnly"
            appendTo="body"
            placeholder="{{
              'informedConsent.labelTypePlaceholder' | translate
            }}"
            [options]="informedConsentTypeOptions"
            optionValue="value"
            id="informedConsentType"
            name="informedConsentType"
            #informedConsentType="ngModel"
            [(ngModel)]="input.type"
            [required]="true"
            (ngModelChange)="trackSelectedeConsentTypeEvent()"
          ></p-dropdown>
          <small
            class="form-error"
            *ngIf="
              informedConsentType.invalid &&
              (f.submitted || informedConsentType.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
        <div id="version">
          <label>
            {{ 'informedConsent.version' | translate }}
            <span class="inputRequired">* </span>
          </label>
          <input
            name="version"
            #version="ngModel"
            required="true"
            [disabled]="isReadOnly"
            type="number"
            min="1"
            max="9999"
            pInputText
            [(ngModel)]="input.icVersion"
            [placeholder]="'informedConsent.placeholderVersion' | translate"
          />
          <small
            class="form-error"
            *ngIf="!version.valid && (f.submitted || version.touched)"
            >{{ 'general.required' | translate }}</small
          >
        </div>

        <div>
          <label for="chip_recipients">
            {{ 'informedConsent.formOptionsEmails' | translate }}
          </label>
          <div>
            <p-chips
              id="chip_recipients"
              [allowDuplicate]="false"
              separator=","
              name="recipients"
              [(ngModel)]="input.recipients"
              [addOnBlur]="true"
              (onAdd)="informedConsentService.onAddRecipient($event.value, f)"
              [placeholder]="'informedConsent.formEmailPlaceHolder' | translate"
              #inputRecipients="ngModel"
            >
            </p-chips>
          </div>
          <small
            class="form-error"
            *ngIf="
              inputRecipients.invalid &&
              (f.submitted || inputRecipients.touched)
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
        <div>
          <label for="approvalSealFileUrl"
            >{{ 'informedConsent.labelApprovalSealFile' | translate }}
            <span class="inputRequired"> * </span>
          </label>

          <app-input-file
            [placeholder]="
              'informedConsent.placeholderApprovalSealFile' | translate
            "
            [currentFile]="currentFile"
            [isRequired]="true"
            [formSubmitted]="f.submitted"
            (uploadFile)="uploadedFile = $event"
            *ngIf="isFileLoaded"
          ></app-input-file>
          <div class="input_container" *ngIf="!isFileLoaded">
            <app-progress-spinner></app-progress-spinner>
          </div>
        </div>
      </div>

      <!--Paso 2-->
      <div class="mb-5">
        <p class="p-mt-0 subtitle p-mb-4">
          <span class="p-pr-2">{{ 'informedConsent.step2' | translate }}</span>
          <span>{{ 'informedConsent.step2Description' | translate }}</span>
        </p>

        <div class="grid-form p-ai-center">
          <div
            style="display: grid; grid-template-columns: 1fr auto"
            class="p-ai-center"
          >
            <label
              for="cmb_isMultipleSignatureRequired"
              id="lbl_isMultipleSignatureRequired"
              class="p-my-0 p-mr-3"
            >
              {{ 'informedConsent.isMultipleSignatures' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <p-selectButton
              id="cmb_isMultipleSignatureRequired"
              name="isMultipleSignatureRequired"
              [options]="booleanOptions"
              [(ngModel)]="input.isMultipleSignatureRequired"
              optionLabel="label"
              optionValue="value"
              (onChange)="isMultipleSignatureRequiredChange()"
              required
              #isMultipleSignatureRequiredTemplate="ngModel"
            ></p-selectButton>
            <small
              class="form-error"
              *ngIf="
                isMultipleSignatureRequiredTemplate.invalid &&
                (f.submitted || isMultipleSignatureRequiredTemplate.touched)
              "
            >
              {{ 'general.required' | translate }}
            </small>
          </div>
          <div
            *ngIf="input.isMultipleSignatureRequired"
            class="p-d-flex p-flex-wrap"
          >
            <label class="full-width p-mb-0" for="dp_totalRequiredSignatures"
              >{{ 'informedConsent.labelTotalSignature' | translate
              }}<span class="inputRequired"> * </span></label
            >
            <div class="p-md-6 p-col-12 p-px-0">
              <p-dropdown
                class="p-mr-2"
                [disabled]="isReadOnly"
                appendTo="body"
                placeholder="{{
                  'informedConsent.placeholderMinRequiredSignatures' | translate
                }}"
                [options]="signatureOptions"
                id="dp_minRequiredSignatures"
                name="minRequiredSignatures"
                #minRequiredSignatureTemplate="ngModel"
                [(ngModel)]="input.minRequiredSignatures"
                [required]="true"
              ></p-dropdown>
              <small
                class="form-error"
                *ngIf="
                  minRequiredSignatureTemplate.invalid &&
                  (f.submitted || minRequiredSignatureTemplate.touched)
                "
              >
                {{ 'general.required' | translate }}
              </small>
            </div>
            <div class="p-md-6 p-col-12 p-px-0">
              <p-dropdown
                class="p-ml-2"
                [disabled]="isReadOnly"
                appendTo="body"
                placeholder="{{
                  'informedConsent.placeholderMaxRequiredSignatures' | translate
                }}"
                [options]="signatureOptions"
                id="dp_maxRequiredSignatures"
                name="maxRequiredSignatures"
                #maxRequiredSignatureTemplate="ngModel"
                [(ngModel)]="input.maxRequiredSignatures"
                [required]="true"
              ></p-dropdown>
              <small
                class="form-error"
                *ngIf="
                  maxRequiredSignatureTemplate.invalid &&
                  (f.submitted || maxRequiredSignatureTemplate.touched)
                "
              >
                {{ 'general.required' | translate }}
              </small>
              <small
                class="form-error"
                *ngIf="
                  maxRequiredSignatureTemplate.value <
                    minRequiredSignatureTemplate.value &&
                  (f.submitted || maxRequiredSignatureTemplate.touched)
                "
              >
                {{ 'informedConsent.errorMaxSmallerThanMin' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <!--Paso 3-->
      <div class="step2-header p-mb-4">
        <p class="p-my-0 subtitle">
          <span class="p-pr-2">{{ 'informedConsent.step3' | translate }}</span>
          <span>{{ 'informedConsent.step3Description' | translate }}</span>
        </p>
        <div class="site-info">
          <div>
            <span class="site-inf-subject">{{
              'informedConsent.step2TotalSubjects' | translate
            }}</span>
            <span class="site-info-select"
              >{{ seletedSites.length }}
              {{
                'informedConsent.step2SiteSelected'
                  | translate: { prural: seletedSites.length === 1 ? '' : 's' }
              }}</span
            >
          </div>
          <div>
            <span class="site-info-total">{{ getTotalSubjects() }}</span>
          </div>
        </div>
        <button
          pButton
          type="button"
          class="btn btn-primary"
          icon="pi pi-plus"
          label="{{ 'informedConsent.buttonNewSite' | translate }}"
          (click)="addNewSite()"
          [disabled]="isAddButtonSizeDisabled()"
        ></button>
      </div>
      <div class="mb-5 site-form-container">
        <app-site-form
          #siteForm
          *ngFor="let site of sites; let i = index"
          [site]="site"
          [siteIndex]="i"
          [sites]="sites"
          [sitesOptions]="sitesOptions"
          [showDeleteButton]="sites.length > 1"
          [submitted]="f.submitted"
          (deleteSiteEmmiter)="deleteSite(i)"
        ></app-site-form>
      </div>
      <div class="p-d-flex p-justify-end">
        <button
          [disabled]="isReadOnly || f.invalid"
          pButton
          type="submit"
          class="btn btn-info"
          icon="pi pi-check"
          label="{{ 'general.saveInformation' | translate }}"
        ></button>
      </div>
    </form>
  </div>
</div>
