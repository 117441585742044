import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import * as FileSaver from 'file-saver';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { CardOrTableService } from '../../../services/card-or-table.service';
import { TPTableData } from './tpTableData';
const logger = new Logger('tp2-logger-tpTableApge');
@Component({
  selector: 'app-tptable',
  templateUrl: './tptable.component.html',
  styleUrls: ['./tptable.component.scss'],
})
export class TptableComponent implements OnInit {
  @ViewChild('table') table: Table | undefined;

  @Output() customAction1 = new EventEmitter<any>();
  @Output() customAction2 = new EventEmitter<any>();
  @Output() customAction3 = new EventEmitter<any>();
  @Output() openDetail = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();

  @Output() item = new EventEmitter<any>();
  @Input() timeZoneOffset: any;
  @Input() rowsPerPage: number = 10;
  @Input() dataTable: TPTableData = {
    cols: [],
  };
  @Input() id: string = '';
  hasDownloadTableDataPermission = false;
  constructor(
    private primengConfig: PrimeNGConfig,
    public cardOrTableService: CardOrTableService,
    public transformDatePipe: TransformDatePipe,
    private trialpalService: TrialpalService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private userPermissionsService: UserPermissionsService,
    public config: DynamicDialogConfig
  ) {}

  async ngOnInit(): Promise<void> {
    this.primengConfig.ripple = true;
    this.hasDownloadTableDataPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DownloadTableData,
      ]);

    if (this.config?.data?.dataTable) {
      this.dataTable = this.config?.data?.dataTable;
    }
  }

  exportExcel() {
    this.trialpalService.showSpinner('general.file', 'DOWNLOAD');
    const dataToExport = this.getDataToExport();

    const fileName = this.getXlsxName();
    import('xlsx')
      .then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(dataToExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, fileName);
        this.trialpalService.showMutationSuccess(
          'general.file',
          'DOWNLOAD',
          this.trialpalService.translateService.instant(
            'general.messageSuccessOperation.summary'
          )
        );
      })
      .catch((error) => {
        this.trialpalService.showServiceError('general.file', error);
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  getXlsxName() {
    const entityName =
      this.dataTable.valueTable.find((x: any) => x.entity)?.entity || 'Data';
    const translateName = this.trialpalService.translateService.instant(
      'general.tables.enums.' + entityName
    );
    return `${translateName.toLowerCase()}`;
  }

  getDataToExport() {
    const dataInTable = this.table?.filteredValue ?? this.table?._value;
    return (
      dataInTable?.map((data: any) => {
        const newData: any = {};
        this.dataTable.cols.forEach((col: any) => {
          const columnName: string = col.header;
          const field = col.field;
          newData[columnName] = data[field];
        });
        return newData;
      }) ?? []
    );
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  /**
   * @desc Retorna el evento de busqueda global el input
   * @param event evento en el input del busqueda
   * @returns datos de busqueda
   */
  handleInput(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  /**
   * @desc Limpia los filtro de la tabla
   * @param table Evento de la tabla
   */
  clear(table: Table) {
    table.clear();
  }
  onMenuOption(event: any, value?: any, menu?: any) {
    event.stopPropagation();
    this.item.emit(value);
    menu.toggle(event);
  }

  onCustomAction1(event: Event, value?: any) {
    event.stopPropagation();
    this.customAction1.emit(value);
  }
  onCustomAction2(event: Event, value?: any) {
    event.stopPropagation();
    if (this.dataTable.customButton2.messageConfirmCustomButton2) {
      this.confirmationService.confirm({
        message: this.dataTable.customButton2.messageConfirmCustomButton2
          ? this.dataTable.customButton2.messageConfirmCustomButton2
          : '',
        accept: () => {
          event.stopPropagation();
          this.customAction2.emit(value);
        },
      });
    } else {
      event.stopPropagation();
      this.customAction2.emit(value);
    }
  }
  onCustomAction3(event: Event, value?: any) {
    event.stopPropagation();
    this.customAction3.emit(value);
  }
  onOpenDetail(value: any) {
    this.openDetail.emit(value);
  }

  async onDelete(event: Event, data: any) {
    event.stopPropagation();
    let deletionTarget = '';
    if (data.entity === 'SUBJECT') {
      deletionTarget = data.subjectNumber;
    }
    logger.debug('onDelete', data);
    const key = this.dataTable.isFeminine
      ? 'TPCardComponent.messageConfirmDeleteFem'
      : 'TPCardComponent.messageConfirmDelete';
    let message =
      this.trialpalService.toUpperCaseFirstLetter(
        this.translateService.instant(key, {
          entityName: this.dataTable.entityAction,
        })
      ) + this.trialpalService.dictionaryPipe.transform(deletionTarget);

    this.confirmationService.confirm({
      message,
      accept: () => {
        this.delete.emit(data.data);
      },
    });
  }
  onEdit(event: Event, data: any): void {
    event.stopPropagation();
    this.edit.emit(data.data);
  }
}
