<div class="container-tp2 p-py-5 econsent">
  <app-back-button-header
    [pageTitle]="'informedConsent.followUp.informedConsentNewUsers' | translate"
    [pageDescription]="
      'informedConsent.followUp.informedConsentNewUsersInfo' | translate
    "
    [pageUrl]="
      '/informed-consent/' +
      projectAndSite.projectId +
      '/follow-up/' +
      this.projectAndSite.siteId +
      '/e-consent/' +
      this.confInformedConsentId
    "
    [showButton]="false"
  ></app-back-button-header>

  <div class="tp2Card p-p-4">
    <div class="window p-py-2 p-px-3 p-mb-5">
      <div class="p-d-flex p-justify-between flex-container-button p-p-0">
        <div class="p-ai-center p-as-center">
          <button
            pButton
            pRipple
            label="{{ 'informedConsent.followUp.addUserIC' | translate }}"
            [ngClass]="{ disabled: newUsers.length > 0 }"
            (click)="onAddUser()"
            type="button"
            class="p-button-secondary p-w-100"
            style="width: 100%"
          ></button>
        </div>
        <div>
          <small class="p-mb-2 p-d-flex">{{
            'informedConsent.followUp.uploadDownloadTemplate' | translate
          }}</small>

          <div
            class="p-d-flex p-jc-end flex-container-button"
            style="gap: 0.5rem"
          >
            <p-button
              id="btn_add"
              class="newWindow"
              label="{{
                'informedConsent.followUp.downloadTemplate' | translate
              }}"
              [ngClass]="{ disabled: newUsers.length > 0 }"
              pTooltip="XLS"
              tooltipPosition="bottom"
              styleClass="p-button-outlined"
              (click)="exportExcel()"
            ></p-button>
            <p-fileUpload
              #fileUpload
              mode="basic"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              [maxFileSize]="1000000"
              chooseLabel="{{
                'informedConsent.followUp.uploadTemplate' | translate
              }}"
              [ngClass]="{ disabled: newUsers.length > 0 }"
              pTooltip="XLS"
              class="inline-block saveWindow"
              (onSelect)="importExcel($event, fileUpload)"
            ></p-fileUpload>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-mb-4">
        <p-table
          [value]="getAllUsers()"
          [columns]="tableColumns"
          [rowHover]="true"
          [scrollable]="true"
          rowGroupMode="subheader"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [rows]="10"
          selectionMode="single"
          currentPageReportTemplate="
          {{
            'general.paginatorValues'
              | translate
                : {
                    first: '{{first}}'
                  }
                  :
                  {
                    last:  '{{ last }} '
                  }
                  :
                  {
                    totalRecords: '{{ totalRecords }}'
                  }
          }}"
          #userTable
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-jc-between p-ai-center">
              <button
                pButton
                [label]="'primeng.clear' | translate"
                class="p-button-outlined"
                icon="pi pi-filter-slash"
                (click)="clearTable()"
              ></button>
              <span class="p-input-icon-left ml-auto">
                <span class="pi pi-search search-icon"></span>
                <input
                  pInputText
                  type="text"
                  #searchInput
                  (input)="applySearchFilter(searchInput.value)"
                  [placeholder]="
                    'informedConsent.followUp.table.searchLabel' | translate
                  "
                />
              </span>
            </div>
          </ng-template>
          <!--HEADER TABLE-->
          <ng-template pTemplate="header" let-columns>
            <tr class="header">
              <th scope="col">
                {{ 'informedConsent.followUp.table.cols.status' | translate }}
              </th>
              <th
                id="{{ col.field }}"
                *ngFor="let col of columns"
                pSortableColumn="{{ col.field }}"
                [pTooltip]="col.header"
              >
                <span>{{ col?.header }}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th scope="col">
                {{ 'informedConsent.followUp.table.cols.actions' | translate }}
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="groupheader" let-user>
            <tr
              pRowGroupHeader
              [ngClass]="{
                disabled: user.group === 'GROUP_B' && newUsers.length > 0
              }"
            >
              <td
                class="
                  groupheader
                  p-text-left p-d-flex p-justify-between p-ai-center
                "
              >
                <span>{{
                  (user.group === 'GROUP_A'
                    ? 'informedConsent.followUp.table.cols.userPending'
                    : 'informedConsent.followUp.table.cols.userSave'
                  ) | translate
                }}</span>
                <p-button
                  id="btn_addAnotherEconsent"
                  class="newWindow"
                  label="{{
                    'informedConsent.followUp.addAnotherEconsent' | translate
                  }}"
                  (onClick)="assignExistingUsers()"
                  styleClass="p-button-outlined"
                  *ngIf="user.group === 'GROUP_B'"
                ></p-button>
              </td>
            </tr>
          </ng-template>

          <!--BODY TABLE-->
          <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
            <small *ngIf="user.isError && user.error" class="general-error">{{
              user.error | translate
            }}</small>
            <tr
              [ngClass]="{
                disabled: user.group === 'GROUP_B' && newUsers.length > 0
              }"
            >
              <td>
                <span *ngIf="user.isError" class="error">Error</span>
                <span
                  *ngIf="!user.isError && user.group === 'GROUP_A'"
                  class="success"
                >
                  OK
                </span>
                <span
                  *ngIf="!user.isError && user.group === 'GROUP_B'"
                  class="assigned"
                >
                  {{ 'informedConsent.enums.states.ASSIGNED' | translate }}
                </span>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.login.isError }"
                [pTooltip]="user.login.data"
                (click)="copyUserTableData(user.login.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{ user.login.data }}</span>
                <div *ngIf="user.login.isError">
                  <div
                    class="p-mb-3"
                    *ngIf="user.login.messageError"
                    class="text-error"
                  >
                    {{ user.login.messageError | translate }}
                  </div>
                  <div *ngIf="user.login.messageError2" class="text-error">
                    {{ user.login.messageError2 | translate }}
                  </div>
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.name.isError }"
                [pTooltip]="user.name.data"
                (click)="copyUserTableData(user.name.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{ user.name.data }}</span>
                <div *ngIf="user.name.isError" class="text-error">
                  {{ user.name.messageError | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.email.isError }"
                [pTooltip]="user.email.data"
                (click)="copyUserTableData(user.email.data)"
                [ngClass]="{
                  disabled:
                    user.notificationPreference.data !== 'EMAIL' &&
                    !user.requiresSendingEmail.data &&
                    !user.email.data
                }"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{ user.email.data }}</span>
                <div *ngIf="user.email.isError">
                  <div
                    class="p-mb-3"
                    *ngIf="user.email.messageError"
                    class="text-error"
                  >
                    {{ user.email.messageError | translate }}
                  </div>
                  <div *ngIf="user.email.messageError2" class="text-error">
                    {{ user.email.messageError2 | translate }}
                  </div>
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.phoneNumber.isError }"
                [pTooltip]="user.phoneNumber.data"
                (click)="copyUserTableData(user.phoneNumber.data)"
                [ngClass]="{
                  disabled:
                    user.notificationPreference.data !== 'SMS' &&
                    !user.phoneNumber.data
                }"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{ user.phoneNumber.data }}</span>
                <div *ngIf="user.phoneNumber.isError" class="text-error">
                  {{ user.phoneNumber.messageError | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.identifier.isError }"
                [pTooltip]="user.identifier.data"
                (click)="copyUserTableData(user.identifier.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{ user.identifier.data }}</span>
                <div *ngIf="user.identifier.isError" class="text-error">
                  {{ user.identifier.messageError | translate }}
                </div>
                <div *ngIf="user.identifier.messageError2" class="text-error">
                  {{ user.identifier.messageError2 | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.subjectNames.isError }"
                [pTooltip]="user.subjectNames.data"
                (click)="copyUserTableData(user.subjectNames.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{ user.subjectNames.data }}</span>
                <div *ngIf="user.subjectNames.isError" class="text-error">
                  {{ user.subjectNames.messageError | translate }}
                </div>
                <div *ngIf="user.subjectNames.messageError2" class="text-error">
                  {{ user.subjectNames.messageError2 | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.subjectBirthdates.isError }"
                [pTooltip]="
                  user.subjectBirthdates.data | transformDate: dateType:true
                "
                (click)="copyUserTableData(user.subjectBirthdates.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{
                  user.subjectBirthdates.data | transformDate: dateType:true
                }}</span>
                <div *ngIf="user.subjectBirthdates.isError" class="text-error">
                  {{ user.subjectBirthdates.messageError | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.relationship.isError }"
                [pTooltip]="
                  relationshipTypes.includes(user.relationship.data)
                    ? getTranslatedRelationship(user.relationship.data)
                    : user.relationship.data
                "
                (click)="copyUserTableData(user.relationship.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span *ngIf="user.relationship.isError">{{
                  user.relationship.data
                }}</span>
                <span *ngIf="!user.relationship.isError">{{
                  relationshipTypes.includes(user.relationship.data)
                    ? getTranslatedRelationship(user.relationship.data)
                    : user.relationship.data
                }}</span>
                <div *ngIf="user.relationship.isError" class="text-error">
                  {{ user.relationship.messageError | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.notificationPreference.isError }"
                [pTooltip]="
                  notificationPreferencesTypes.includes(
                    user.notificationPreference.data
                  )
                    ? ('ediary.enums.alertTypes.' +
                        user.notificationPreference.data | translate)
                    : user.notificationPreference.data
                "
                (click)="copyUserTableData(user.notificationPreference.data)"
              >
                <span class="pi pi-copy copy-active"></span>
                <span>{{
                  notificationPreferencesTypes.includes(
                    user.notificationPreference.data
                  )
                    ? ('ediary.enums.alertTypes.' +
                        user.notificationPreference.data | translate)
                    : user.notificationPreference.data
                }}</span>
                <div
                  *ngIf="user.notificationPreference.isError"
                  class="text-error"
                >
                  {{ user.notificationPreference.messageError | translate }}
                </div>
                <div
                  *ngIf="user.notificationPreference.messageError2"
                  class="text-error"
                >
                  {{ user.notificationPreference.messageError2 | translate }}
                </div>
              </td>
              <td
                [ngClass]="{ 'bg-error': user.requiresSendingEmail.isError }"
                [pTooltip]="
                  (user.requiresSendingEmail.data
                    ? 'general.yes'
                    : 'general.no'
                  ) | translate
                "
                (click)="
                  copyUserTableData(
                    trialpalService.translateService.instant(
                      user.requiresSendingEmail.data
                        ? 'general.yes'
                        : 'general.no'
                    )
                  )
                "
              >
                <span class="pi pi-copy copy-active"></span>
                <span *ngIf="user.requiresSendingEmail.isError">{{
                  user.requiresSendingEmail.data
                }}</span>
                <span *ngIf="!user.requiresSendingEmail.isError">{{
                  user.requiresSendingEmail.data
                    ? ('general.yes' | translate)
                    : ('general.no' | translate)
                }}</span>
                <div
                  *ngIf="user.requiresSendingEmail.isError"
                  class="text-error"
                >
                  {{ user.requiresSendingEmail.messageError | translate }}
                </div>
              </td>
              <td style="min-width: 7rem">
                <button
                  id="btn_edit"
                  name="btn_edit"
                  pButton
                  type="button"
                  icon="pi pi-pencil"
                  class="btn-icon btn-edit"
                  (click)="onEditUser(user)"
                ></button>
                <button
                  id="btn_delete"
                  name="btn_delete"
                  pButton
                  type="button"
                  icon="pi pi-trash"
                  class="btn-icon btn-delete"
                  (click)="selectedUser = user; showDeleteUserDialog = true"
                ></button>
              </td>
            </tr>
          </ng-template>

          <!--FOOTER TABLE-->
          <ng-template pTemplate="footer">
            <div class="footer border-x">
              <div
                class="window container-footer"
                [ngClass]="{
                  activeWindow: newUsers.length > 0 && !isFormInValid
                }"
              >
                <p class="p-my-0 footer-text" *ngIf="newUsers.length === 0">
                  {{ 'informedConsent.followUp.noUsersFoundText' | translate }}
                </p>
                <p
                  class="p-my-0 footer-text"
                  *ngIf="!isFormInValid && newUsers.length > 0"
                >
                  <span *ngIf="newUsers.length === 1">{{
                    'informedConsent.followUp.pendingUsersSingular' | translate
                  }}</span>

                  <span *ngIf="newUsers.length > 1">{{
                    'informedConsent.followUp.pendingUsers'
                      | translate
                        : {
                            people: newUsers.length
                          }
                  }}</span>
                </p>
                <p
                  class="p-my-0 footer-text"
                  *ngIf="isFormInValid && newUsers.length > 0"
                >
                  <span *ngIf="totalUsersError === 1">{{
                    'informedConsent.followUp.errorUsersTextSingular'
                      | translate
                  }}</span>

                  <span *ngIf="totalUsersError > 1">{{
                    'informedConsent.followUp.errorUsersText'
                      | translate
                        : {
                            people: totalUsersError
                          }
                  }}</span>
                </p>
                <button
                  pButton
                  type="button"
                  class="btn btn-info"
                  icon="pi pi-check"
                  label="{{
                    'informedConsent.followUp.btnSaveUsers' | translate
                  }}"
                  [disabled]="isFormInValid || newUsers.length === 0"
                  (click)="createMultipleUsers()"
                ></button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <div class="p-py-4">
              <div *ngIf="newUsers.length === 0 && existingUsers.length === 0">
                <app-not-found-items
                  [description]="
                    'informedConsent.followUp.notFoundUserGlobalDescription'
                      | translate
                  "
                  [buttonDescription]="
                    'informedConsent.followUp.notFoundUserDescription'
                      | translate
                  "
                  [buttonText]="
                    'informedConsent.followUp.addUserIC' | translate
                  "
                  (clickEmitter)="onAddUser()"
                ></app-not-found-items>
              </div>
              <p
                class="emptyMessage p-m-0"
                *ngIf="newUsers.length !== 0 || existingUsers.length !== 0"
              >
                {{ 'primeng.emptyFilterMessage' | translate }}
              </p>
            </div>
          </ng-template>
        </p-table>
      </div>
    </div>

    <p-dialog
      [(visible)]="showDialog"
      *ngIf="showDialog"
      [style]="{ width: '90%' }"
      [modal]="true"
      styleClass="p-fluid"
    >
      <header class="title">
        <h2>{{ 'user.addUser' | translate }}</h2>
        <p>{{ 'user.fillTheBlank' | translate }}</p>
      </header>

      <ng-template pTemplate="content">
        <app-informed-consent-follow-up-user-form
          [user]="user"
          [existingUsers]="getAllUsers()"
          [assignedKinships]="assignedKinships"
          [confInformedConsent]="confInformedConsent"
          [projectAndSite]="projectAndSite"
          (closeUserForm)="onCloseModal($event)"
          (closeEditPerson)="onEditPerson($event)"
        ></app-informed-consent-follow-up-user-form>
      </ng-template>
    </p-dialog>
  </div>
</div>

<app-delete-confirmation
  [header]="
    'informedConsent.followUp.table.rowDetail.deleteConsentedQuestion'
      | translate
  "
  [description]="
    'informedConsent.followUp.table.rowDetail.deleteConsentedAction' | translate
  "
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDeleteUserDialog"
  (confirm)="deleteUserFromUserTable()"
  position="fixed"
>
</app-delete-confirmation>
