import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ProjectService } from '../../../modules/project/project.service';
import { Dictionary } from '../table-languages/dictionary';
import { DictionaryService } from '../table-languages/dictionary.service';
import { TableLanguagesComponent } from '../table-languages/table-languages.component';
const logger = new Logger('tp2-logger-dictionaryComponent');

@Component({
  selector: 'app-simple-dictionary-editor',
  templateUrl: './simple-dictionary-editor.component.html',
  styleUrls: ['./simple-dictionary-editor.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SimpleDictionaryEditorComponent {
  isSpanish: string = '';
  isEnglish: string = '';
  dictionary: any[] = [];
  results: string[] = [];
  timer: any;

  @Input() isReadOnly: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() submitted: boolean = false;
  @Input() value: any = '';
  @Input() id: string = 'dictionaryInputEditor_' + new Date().getTime();
  @Input() name: string = 'dictionaryInputEditor_' + new Date().getTime();
  @Output() outputEvent = new EventEmitter();

  constructor(
    private readonly dictionaryService: DictionaryService,
    private readonly trialpalService: TrialpalService,
    private projectService: ProjectService
  ) {}

  search(event: any) {
    this.isSpanish = '';
    this.isEnglish = '';
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  selectKeyLanguage(event: any) {
    this.dictionary.forEach((data) => {
      if (data.key === event) {
        this.isSpanish = data.spanish;
        this.isEnglish = data.english;
      }
    });
  }

  onDictionary(isForm: boolean): void {
    const ref = this.trialpalService.dialogService.open(
      TableLanguagesComponent,
      {
        data: {
          projectId: this.projectService.currentProject.id,
          isForm: isForm,
          isEditorForm: true,
        },
        width: '95%',
        height: '95%',
      }
    );

    ref.onClose.subscribe(async (data) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        this.dictionary = [];
        try {
          const dictionaryData =
            await this.dictionaryService.getConfDictionaryByProjectId(
              this.projectService.currentProject.id
            );
          dictionaryData.items?.forEach((item) => {
            const obj: Dictionary = {
              id: item?.id!,
              key: item?.key!,
              isHtml: item?.isHtml ?? false,
              spanish: item?.spanish || '',
              english: item?.english || '',
            };
            this.dictionary.push(obj);
          });
          localStorage.removeItem('Dictionaty');
          localStorage.setItem('Dictionary', JSON.stringify(dictionaryData));
          this.trialpalService.messageService.add({
            severity: 'success',
            summary: this.trialpalService.translateService.instant(
              'dictionary.selectTranslate'
            ),
            detail: this.trialpalService.translateService.instant(
              'dictionary.succesSelectTranslate'
            ),
          });
        } catch (err) {
          logger.error('No se pudo cargar el diccionario', err);
        } finally {
          this.selectKeyLanguage(data.key);
          this.value = data.key;
          this.emitChanges();
          this.trialpalService.hideSpinner();
        }
      }
    });
  }

  emitChanges(): void {
    this.outputEvent.emit(this.value);
  }

  onTextChangedDebounced() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.outputEvent.emit(this.value);
    }, 500);
  }
}
