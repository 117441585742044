import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PageTourService } from './page-tour.service';
import { UserPermissionsService } from './user-permissions-service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private pageTourService: PageTourService,
    public userPermissionsService: UserPermissionsService
  ) {}

  async canActivate(
    pRoute: ActivatedRouteSnapshot,
    _pState: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      const hasPermission = await this.userPermissionsService.hasPermission(
        pRoute.data.roles
      );

      if (hasPermission) {
        this.setLastRoute(_pState.url);
        this.pageTourService.destroyTour();
        return true;
      } else {
        this.translateService.get('general').subscribe((translate) => {
          this.messageService.add({
            severity: 'error',
            summary: translate.error,
            detail: translate.rolNotAuthorized,
          });
          this.authService.navigateHome().then();
          this.pageTourService.destroyTour();
        });
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      this.pageTourService.destroyTour();
      return false;
    }
  }
  setLastRoute(url: string) {
    const user = this.authService.getUsername();
    const lastRouteExist = this.authService.getUserLastRoute(user);

    //Si es un postLogin no modifica la ultima ruta guardada
    if (this.authService.postLoginSession && lastRouteExist) {
      if (this.authService.lastUrl && this.authService.lastUrl !== url) {
        this.authService.postLoginSession = false;
      }
      this.authService.lastUrl = url;
      return;
    }
    this.authService.setLastRoute(url);
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
