import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from 'aws-amplify';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ReportService } from 'src/app/modules/report/report.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import {
  ButtonItem,
  Roles,
  Tp2Card,
  Tp2CardContent,
  projectStateGlobal,
} from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import {
  ConfFormConfSymptomsByConfSymptomIdCustomQuery,
  ConfSymptom,
  ConfSymptomByConfReportIdQuery,
  DeleteConfFormConfSymptomInput,
  DeleteConfSymptomInput,
  InjectionSide,
  InjectionSite,
  SymptomType,
} from '../../ediary.types';
import { SymptomAddEditComponent } from '../symptom-add-edit/symptom-add-edit.component';

const logger = new Logger('ediaryConfigurationSymptoms');

@Component({
  selector: 'app-symptom-list',
  templateUrl: './symptom-list.component.html',
  styleUrls: ['./symptom-list.component.scss'],
})
export class SymptomListComponent implements OnInit {
  confSymptoms: Array<any> = [];
  @Input() relatedEntityId: any = null;
  @Input() relatedEntity: any = null;
  @Input() projectState: any = null;
  @Input() dictionary: any = null;
  @Input() projectId: any = null;
  @Input() symptomPageLayout: any;
  @Output() changePageLayout: EventEmitter<any> = new EventEmitter();
  symptomCards: Tp2Card[] = [];
  pageLayouts: any[] = [];

  //Permissions
  hasDiarySymptomCreate: boolean = false;
  hasDiarySymptomUpdate: boolean = false;
  hasDiarySymptomDelete: boolean = false;

  constructor(
    private ediaryService: EdiaryService,
    private reportService: ReportService,
    public dialogService: DialogService,
    private trialpalService: TrialpalService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setPermissions();
    if (this.relatedEntity === 'EDIARY') {
      this.loadFromEdiary();
    } else {
      this.ediaryService
        .getPageLayouts()
        .then((res) => (this.pageLayouts = res));
      this.loadFromReport();
    }
  }

  async setPermissions() {
    this.hasDiarySymptomCreate =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DiarySymptomCreate,
        Roles.Admin,
      ]);
    this.hasDiarySymptomUpdate =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DiarySymptomUpdate,
        Roles.Admin,
      ]);
    this.hasDiarySymptomDelete =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DiarySymptomDelete,
        Roles.Admin,
      ]);
  }

  loadFromEdiary(): void {
    this.trialpalService.showSpinner('symptom.entityPlural', 'LIST');
    this.ediaryService
      .getConfSymptomByConfEdiaryId(this.relatedEntityId)
      .then((res) => {
        logger.debug('getConfSymptomByConfEdiaryId response', res);
        if (res.items) {
          res.items = res.items.filter((x: any) => x._deleted !== true);
          this.confSymptoms = [...res.items].sort((a: any, b: any) => {
            return a.order - b.order;
          });
          this.buildTPCardData(this.confSymptoms);
        }
      })
      .catch((err) => {
        this.trialpalService.showServiceError(
          'getConfSymptomByConfEdiaryId',
          err
        );
        logger.error('getConfSymptomByConfEdiaryId', err);
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  loadFromReport(): void {
    this.trialpalService.showSpinner('symptom.entityPlural', 'LIST');
    this.reportService
      .getConfSymptomByConfReportId(this.relatedEntityId)
      .then((res: ConfSymptomByConfReportIdQuery) => {
        logger.debug('getConfSymptomByConfReportId response', res);
        if (res.items) {
          this.confSymptoms = res.items
            .filter((e) => e && !e._deleted)
            .sort((a: any, b: any) => {
              return a.order - b.order;
            });
          this.buildTPCardData(this.confSymptoms);
        }
      })
      .catch((err) => {
        this.trialpalService.showServiceError(
          'getConfSymptomByConfReportId',
          err
        );
        logger.error('getConfSymptomByConfReportId error', err);
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  openForm(isEdition: boolean, symptom?: any): void {
    const ref = this.dialogService.open(SymptomAddEditComponent, {
      data: {
        relatedEntityId: this.relatedEntityId,
        relatedEntity: this.relatedEntity,
        confSymptom: symptom ? { ...symptom } : null,
        projectState: this.projectState,
        dictionary: this.dictionary,
        projectId: this.projectId,
        isEdition: isEdition,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        const duplicate = this.confSymptoms.find((e) => e.id === res.id);
        if (duplicate) {
          const index = this.confSymptoms.indexOf(duplicate);
          this.confSymptoms[index] = res;
        } else {
          this.confSymptoms.push(res);
        }
        this.confSymptoms.sort((a: any, b: any) => {
          return a.order - b.order;
        });
      }
      if (this.relatedEntity === 'EDIARY') {
        this.loadFromEdiary();
      } else {
        this.loadFromReport();
      }
    });
  }

  openFormInReadMode(symptom?: any): void {
    this.dialogService.open(SymptomAddEditComponent, {
      data: {
        relatedEntityId: this.relatedEntityId,
        relatedEntity: this.relatedEntity,
        confSymptom: symptom ? { ...symptom } : null,
        projectState: this.projectState,
        dictionary: this.dictionary,
        projectId: this.projectId,
        isEdition: true,
        readMode: true,
      },
      width: '95%',
      height: '95%',
    });
  }
  buildTPCardData(confSymptoms: ConfSymptom[]) {
    const cards: Tp2Card[] = [];

    for (let confSymptom of confSymptoms) {
      const trans = this.trialpalService.translateService.instant(
        'symptom.enums.symptoms.' + confSymptom.symptom
      );
      const type = this.trialpalService.translateService.instant(
        'symptom.enums.symptomTypes.' + confSymptom.type
      );
      const labelToShow = confSymptom.symptomLabel
        ? this.trialpalService.dictionaryPipe.transform(
            confSymptom.symptomLabel
          )
        : trans;

      let buttonsItems: ButtonItem[] = [];

      buttonsItems = [
        {
          color: '#007b97',
          command: () => this.openFormInReadMode(confSymptom),
          icon: 'pi pi-search',
          tooltip: '',
          disabled: false,
        },
      ];

      if (this.hasDiarySymptomUpdate) {
        buttonsItems.push({
          color: '#205163',
          command: () => this.openForm(true, confSymptom),
          icon: 'pi pi-pencil',
          tooltip: '',
          disabled: false,
        });
      }

      if (this.hasDiarySymptomDelete) {
        buttonsItems.push({
          color: '#E41E1E',
          command: () => this.checkDelete(confSymptom, labelToShow),
          icon: 'pi pi-trash',
          tooltip: '',
          disabled: false,
        });
      }

      //Solo se muestra el botón si el usuario tiene permisos

      const injectionSiteText = this.getInjectionSiteText(confSymptom);
      const content: Tp2CardContent[] = [
        {
          title: `${this.trialpalService.translateService.instant(
            'symptom.labelOrder'
          )}:`,
          description: confSymptom.order,
          icon: 'pi pi-sort',
        },
        {
          title: `${this.trialpalService.translateService.instant(
            'symptom.labelType'
          )}:`,
          description: type,
          icon: injectionSiteText.length > 0 ? 'pi pi-tag' : '',
        },
      ];
      if (injectionSiteText) {
        content.push({
          title: `${this.trialpalService.translateService.instant(
            'symptom.labelInjectionSite'
          )}:`,
          description: injectionSiteText,
          icon: injectionSiteText.length > 0 ? 'pi pi-tags' : '',
        });
      }
      cards.push({
        id: confSymptom.id,
        textHeader: labelToShow,
        buttonItems: buttonsItems,
        content: content,
        isButtonsEnabled: true,
        isMenuEnabled: false,
        icon: confSymptom.symptomIcon ?? undefined,
        menu: [],
      });
    }

    this.symptomCards = cards;
  }
  private getInjectionSiteText(res: any): string {
    let result = '';
    if (
      res.type === SymptomType.LOCAL &&
      res.injectionSite !== InjectionSite.NA
    ) {
      result = this.trialpalService.translateService.instant(
        'symptom.enums.injectionSites.' + res.injectionSite
      );
      if (res.injectionSide !== InjectionSide.NA) {
        result +=
          ' - ' +
          this.trialpalService.translateService.instant(
            'symptom.enums.injectionSides.' + res.injectionSide
          );
      }
    }
    return result;
  }

  onDelete(confSymptom: any): void {
    if (
      this.projectState === projectStateGlobal.IN_DESIGN ||
      this.projectState === projectStateGlobal.IN_PROGRESS
    ) {
      if (confSymptom.id) {
        const input: DeleteConfSymptomInput = {
          id: confSymptom.id,
          _version: confSymptom._version,
        };
        this.trialpalService.showSpinner('symptom.entity', 'DELETE');
        this.ediaryService
          .deleteConfSymptom(input)
          .then(() => {
            this.deleteConfSymptoms(confSymptom);
            this.deleteFormSymptoms(confSymptom.id);
            this.trialpalService.showMutationSuccess(
              'symptom.entity',
              'DELETE'
            );
          })
          .catch((err) => {
            logger.error('deleteConfSymptom', err);
            this.trialpalService.showServiceError('symptom.actions.', err);
          })
          .finally(() => this.trialpalService.hideSpinner());
      }
    } else {
      this.showMessageDeleteSymptomError();
    }
  }

  deleteConfSymptoms(confSymptom: any) {
    this.confSymptoms = this.confSymptoms.filter(
      (e) => e.id !== confSymptom.id
    );
    this.symptomCards = this.symptomCards.filter((symptomCard: Tp2Card) => {
      return symptomCard.id !== confSymptom.id;
    });
  }

  showMessageDeleteSymptomError() {
    this.messageService.add({
      severity: 'warn',
      summary: this.trialpalService.translateService.instant(
        'symptom.errorMessageDeleteSymptom.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'symptom.errorMessageDeleteSymptom.detail'
      ),
    });
  }
  private deleteFormSymptoms(symptomId: string): void {
    this.ediaryService
      .getConfFormConfSymptomByConfSymptomId(symptomId)
      .then((res: ConfFormConfSymptomsByConfSymptomIdCustomQuery) => {
        if (res?.items) {
          res.items.forEach((element) => {
            if (element) {
              const deleteFormSymptomInput: DeleteConfFormConfSymptomInput = {
                id: element.id || '',
                _version: element._version,
              };
              this.ediaryService.deleteConfFormConfSymptom(
                deleteFormSymptomInput
              );
            }
          });
        }
      });
  }

  async checkDelete(confSymptom: ConfSymptom, labeltoShow: string) {
    logger.debug('onDelete', confSymptom);
    const key = 'TPCardComponent.messageConfirmDelete';
    let message =
      this.trialpalService.toUpperCaseFirstLetter(
        this.trialpalService.translateService.instant(key, {
          entityName:
            this.trialpalService.translateService.instant('symptom.entity'),
        })
      ) + this.trialpalService.dictionaryPipe.transform(labeltoShow ?? '');

    const items = await this.symptomConfForm(confSymptom);

    //Verifica si es un sintoma y si este tiene un formulario asociado
    if (items.length !== 0) {
      this.showMessageErrorDeleteSymptom();
      return;
    }
    this.confirmationService.confirm({
      message,
      accept: () => {
        this.onDelete(confSymptom);
      },
    });
  }

  async symptomConfForm(data: ConfSymptom) {
    let items: any[] = [];
    if (data.__typename === 'ConfSymptom') {
      items = await this.ediaryService.getConfFormByConfSymptom(data.id);
    }

    return items;
  }

  /**
   * @desc Mensaje de error de eliminacion sintoma
   */
  private showMessageErrorDeleteSymptom() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.errorDeletingSymptom.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.errorDeletingSymptom.detail'
      ),
    });
  }
}
