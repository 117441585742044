<div class="container p-grid p-fluid">
  <h4 _ngcontent-hvb-c134="" class="p-mt-2">
    {{ 'report.labelRemindersInstruction' | translate }}
  </h4>
  <div
    class="p-col-12 p-md-6 p-field"
    *ngIf="
      confReport.enableLocalNotifications &&
      (confReport.programationType === 'ON_DEMAND' ||
        (confReport.programationType === 'PLANNED_DAYS' &&
          confReport.allowReportBackDating))
    "
  >
    <label for="alertPeriodicityType"
      >{{ 'report.labelPeriodicityType' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <p-dropdown
      appendTo="body"
      placeholder="{{ 'general.selectPlaceholder' | translate }}"
      [showClear]="true"
      [options]="onDemandAlertTypes"
      (onChange)="onChangeAlertType()"
      optionLabel="name"
      [required]="confReport.enableLocalNotifications"
      optionValue="value"
      id="alertPeriodicityType"
      name="alertPeriodicityType"
      #alertPeriodicityType="ngModel"
      [(ngModel)]="confReport.alertType"
      [disabled]="readMode || isAlertTypeDisabled"
    ></p-dropdown>
    <small
      class="form-error"
      *ngIf="
        !alertPeriodicityType.valid &&
        !confReport.alertType?.length &&
        (form.submitted || alertPeriodicityType.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <div
    class="p-col-12 p-md-6 p-field"
    *ngIf="
      confReport.enableLocalNotifications &&
      (confReport.programationType === 'ON_DEMAND' ||
        (confReport.programationType === 'PLANNED_DAYS' &&
          confReport.allowReportBackDating))
    "
  >
    <label for="alertInBetweenDaysFrequency"
      >{{ 'report.labelFutureNotifications' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <p-inputNumber
      [required]="confReport.enableLocalNotifications"
      [(ngModel)]="confReport.futureNotifications"
      id="futureNotifications"
      name="futureNotifications"
      #futureNotifications="ngModel"
      [min]="0"
      [max]="10"
      tooltipPosition="top"
      pTooltip="{{ 'report.tooltiFutureNotifications' | translate }}"
      [disabled]="readMode"
    ></p-inputNumber>
    <small
      class="form-error"
      *ngIf="
        !futureNotifications.valid &&
        (form.submitted || futureNotifications.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <div
    class="p-col-12 p-md-6 p-field"
    *ngIf="
      confReport.alertType === 'DAYS' &&
      confReport.enableLocalNotifications &&
      (confReport.programationType === 'ON_DEMAND' ||
        (confReport.programationType === 'PLANNED_DAYS' &&
          confReport.allowReportBackDating))
    "
  >
    <label for="alertInBetweenDaysFrequency"
      >{{ 'report.labelAlertInBetweenDaysFrequency' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <p-inputNumber
      [required]="confReport.enableLocalNotifications"
      [(ngModel)]="confReport.alertInBetweenDaysFrequency"
      id="alertInBetweenDaysFrequency"
      name="alertInBetweenDaysFrequency"
      #alertInBetweenDaysFrequency="ngModel"
      [min]="1"
      [max]="30"
      tooltipPosition="top"
      pTooltip="{{ 'report.tooltipAlertInBetweenDaysFrequency' | translate }}"
      [disabled]="readMode"
    ></p-inputNumber>
    <small
      class="form-error"
      *ngIf="
        !alertInBetweenDaysFrequency.valid &&
        (form.submitted || alertInBetweenDaysFrequency.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <div
    class="p-col-12 p-md-6 p-field"
    *ngIf="
      confReport.alertType === 'WEEKLY' &&
      confReport.enableLocalNotifications &&
      (confReport.programationType === 'ON_DEMAND' ||
        (confReport.programationType === 'PLANNED_DAYS' &&
          confReport.allowReportBackDating))
    "
  >
    <label for="alertWeeklyDays"
      >{{ 'report.labelAlertWeeklyDays' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <p-multiSelect
      appendTo="body"
      placeholder="{{ 'general.selectPlaceholder' | translate }}"
      [options]="weekDaysOptions"
      optionLabel="name"
      optionValue="value"
      id="alertWeeklyDays"
      name="alertWeeklyDays"
      emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
      #alertWeeklyDays="ngModel"
      [(ngModel)]="confReport.alertWeeklyDays"
      [required]="confReport.enableLocalNotifications"
      [disabled]="readMode || isAlertWeeklyDaysDisabled"
    ></p-multiSelect>
    <small
      class="form-error"
      *ngIf="
        !alertWeeklyDays.valid &&
        confReport.alertWeeklyDays?.length === 0 &&
        (form.submitted || alertWeeklyDays.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <div
    class="p-col-12 p-md-6 p-field"
    *ngIf="confReport.enableLocalNotifications"
  >
    <label for="alertInBetweenDaysFrequency"
      >{{ 'report.labelAlertDuringSameDayFrequency' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <p-inputNumber
      [(ngModel)]="confReport.alertDuringSameDayFrequency"
      id="alertDuringSameDayFrequency"
      name="alertDuringSameDayFrequency"
      #alertDuringSameDayFrequency="ngModel"
      [required]="confReport.enableLocalNotifications"
      [min]="1"
      [max]="12"
      tooltipPosition="top"
      pTooltip="{{ 'report.tooltipAlertDuringSameDayFrequency' | translate }}"
      [disabled]="readMode"
    ></p-inputNumber>
    <small
      class="form-error"
      *ngIf="
        !alertDuringSameDayFrequency.valid &&
        (form.submitted || alertDuringSameDayFrequency.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <div
    class="p-field p-col-12 p-md-6"
    *ngIf="confReport.enableLocalNotifications"
  >
    <label for="timeonly"
      >{{ 'report.labelAlertStartTime' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <input
      type="time"
      pInputText
      id="alertStartTime"
      name="alertStartTime"
      [required]="confReport.enableLocalNotifications"
      #alertStartTime="ngModel"
      [(ngModel)]="confReport.alertStartTime"
      (ngModelChange)="valueTime()"
      [value]="confReport.alertStartTime"
      [disabled]="readMode"
    />
    <small
      class="form-error"
      *ngIf="
        !alertStartTime.valid && (form.submitted || alertStartTime.touched)
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>
  <div
    class="p-field p-col-12 p-md-6"
    *ngIf="confReport.enableLocalNotifications"
  >
    <label for="timeonly"
      >{{ 'report.labelAlertEndTime' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <input
      type="time"
      pInputText
      id="alertEndTime"
      name="alertEndTime"
      [required]="confReport.enableLocalNotifications"
      #alertEndTime="ngModel"
      [(ngModel)]="confReport.alertEndTime"
      (ngModelChange)="valueTime()"
      [value]="confReport.alertEndTime"
      [disabled]="readMode"
    />
    <small
      class="form-error"
      *ngIf="!alertEndTime.valid && (form.submitted || alertEndTime.touched)"
      >{{ 'general.required' | translate }}</small
    >
  </div>
</div>
