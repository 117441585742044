<div class="add-day p-mb-3 p-d-flex p-jc-end">
  <button
    id="btn_addDay"
    name="btn_addDay"
    class="p-button-secondary"
    pButton
    type="button"
    label="{{ 'general.add' | translate }}"
    (click)="newMedicationInstance()"
  ></button>

  <button
    *ngIf="showDeleteButton()"
    id="btn_delete"
    pButton
    type="button"
    class="p-button-danger p-ml-3"
    icon="pi pi-trash"
    iconPos="left"
    (click)="confirmDeleteMedication()"
  ></button>
</div>
<div class="medication-conciliation">
  <app-table-day-instance
    [instance]="'MEDICATION'"
    (emitData)="isMedicationDaysLoading($event)"
  ></app-table-day-instance>
  <div>
    <p-table
      *ngIf="
        medicationService.tableMedications &&
        medicationService.tableMedications.length > 0
      "
      [value]="medicationService.tableMedications"
      styleClass="p-datatable-gridlines"
      responsiveLayout="scroll"
      class="p-pb-3"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="medicineName">
            {{ 'medicationinstance.medicineName' | translate }}
          </th>
          <th id="medicineDose">{{ 'medicationinstance.dose' | translate }}</th>
          <th id="medicineGivenTo">
            {{ 'medicationinstance.givenTo' | translate }}
          </th>
          <th id="medicineReason">
            {{ 'medicationinstance.reason' | translate }}
          </th>
          <th id="medicineAdministration">
            {{ 'medicationinstance.administracionRoute' | translate }}
          </th>
          <th id="medicineFrequency">
            {{ 'medicationinstance.frequency' | translate }}
          </th>
          <th id="medicineStartDate">
            {{ 'symptominstance.startDate' | translate }}
          </th>
          <th id="medicineEndDate">
            {{ 'symptominstance.endDate' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-medication>
        <tr
          class="header"
          (click)="paginationService.selectedMedication(medication, false)"
          [ngClass]="{
            'header-select':
              (medication.medication.id ===
                medicationService.currentMedication?.medication?.id &&
                medication.medication.id !== '123456789') ||
              (medicationService.currentMedication?.verifiedMedication?.id ===
                medication?.verifiedMedication?.id &&
                medication.medication.id === '123456789')
          }"
        >
          <td>
            <div class="grid-c col-c-2">
              <app-check
                [isConciliated]="medication.verifiedMedication?.id"
                [verifiedInstance]="medication?.verifiedMedication"
              ></app-check>
              <span>
                <span
                  [innerHTML]="
                    medication.medication.name
                      | textNa: medication.verifiedMedication?.name
                  "
                >
                </span>
                <sup
                  class="p-ml-1"
                  *ngIf="medication.comments.nameComment"
                  [pTooltip]="medication.comments.nameComment"
                >
                  {{ medication.comments.nameCommentIndex }}
                </sup>
              </span>
            </div>
          </td>
          <td>
            <div>
              <span
                [innerHTML]="
                  medication.medication.dose
                    | textNa: medication.verifiedMedication?.dose
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.doseComment"
                [pTooltip]="medication.comments.doseComment"
                >{{ medication.comments.doseCommentIndex }}
              </sup>
            </div>
          </td>
          <td>
            <div>
              <span
                [innerHTML]="
                  medication.medication.givenTo?.sort()
                    | textNa
                      : medication.verifiedMedication?.givenTo?.sort()
                      : 'givenTo'
                "
              >
              </span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.givenToComment"
                [pTooltip]="medication.comments.givenToComment"
                >{{ medication.comments.givenToCommentIndex }}
              </sup>
            </div>
          </td>
          <td>
            <div
              *ngIf="
                medicationService.isReasonEnabled(medication.configuration)
              "
            >
              <span
                [innerHTML]="
                  medication.medication.reason
                    | textNa: medication.verifiedMedication?.reason
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.reasonComment"
                [pTooltip]="medication.comments.reasonComment"
                >{{ medication.comments.reasonCommentIndex }}
              </sup>
            </div>
            <div
              *ngIf="
                medicationService.isSymptomsEnabled(medication.configuration)
              "
            >
              <span
                [innerHTML]="
                  medication.symptomsInstances
                    | textNa: medication?.symptomsVerified
                "
              >
              </span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.symptomsInstancesComment"
                [pTooltip]="medication.comments.symptomsInstancesComment"
                >{{ medication.comments.symptomsInstancesCommentIndex }}
              </sup>
            </div>
          </td>
          <td>
            <div>
              <span
                [innerHTML]="
                  medication.medication.administrationRoute
                    | textNa: medication.verifiedMedication?.administrationRoute
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.administrationRouteComment"
                [pTooltip]="medication.comments.administrationRouteComment"
                >{{ medication.comments.administrationRouteCommentIndex }}
              </sup>
            </div>
          </td>
          <td>
            <div>
              <span
                [innerHTML]="
                  medication.medication.frequency
                    | textNa: medication.verifiedMedication?.frequency
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.frequencyComment"
                [pTooltip]="medication.comments.frequencyComment"
                >{{ medication.comments.frequencyCommentIndex }}
              </sup>
            </div>
          </td>
          <td>
            <div>
              <span
                [innerHTML]="
                  medication.medication.startDate
                    | textNa
                      : medication.verifiedMedication?.startDate
                      : 'date'
                      : false
                      : medication.comments.startDateComment
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.startDateComment"
                [pTooltip]="medication.comments.startDateComment"
                >{{ medication.comments.startDateCommentIndex }}
              </sup>
            </div>
          </td>
          <td>
            <div>
              <span
                [innerHTML]="
                  medication.medication.finishDate
                    | textNa
                      : medication.verifiedMedication?.finishDate
                      : 'date'
                      : false
                      : medication.comments.finishDateComment
                "
              ></span>
              <sup
                class="p-ml-1"
                *ngIf="medication.comments.finishDateComment"
                [pTooltip]="medication.comments.finishDateComment"
                >{{ medication.comments.finishDateCommentIndex }}
              </sup>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div
      class="p-grid p-col-12 p-pt-3"
      *ngIf="
        medicationService.medicationComments &&
        medicationService.medicationComments.length > 0
      "
    >
      <label class="p-col-12">{{
        'conciliation.titleObservation' | translate
      }}</label>
      <div>
        <ul class="p-d-flex p-flex-row p-flex-wrap">
          <div
            class="p-pl-3"
            *ngFor="let comment of medicationService.medicationComments"
          >
            <li *ngIf="comment?.comment">
              <label>{{ comment?.index }}. </label
              ><span style="word-break: break-word">{{
                comment?.comment
              }}</span>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<div
  class="p-grid p-fluid"
  *ngIf="
    medicationService.tableMedications &&
    medicationService.tableMedications.length === 0 &&
    medicationService.medicationColDays &&
    medicationService.medicationColDays.length === 1
  "
>
  <h4 class="notResult">
    {{ 'general.noResultsFound' | translate }}
  </h4>
</div>
