import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { AddEditRolesComponent } from './add-edit-roles/add-edit-roles.component';
import { ListRolesComponent } from './list-roles/list-roles.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'listRoles',
    pathMatch: 'full',
  },
  {
    path: 'listRoles',
    component: ListRolesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'listRoles/:projectId',
    component: ListRolesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createRol',
    component: AddEditRolesComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RolesRoutingModule {}
