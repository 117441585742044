<div *ngIf="areComments">
  <p-table [value]="daysComments" responsiveLayout="scroll" class="p-mb-5">
    <ng-template pTemplate="header">
      <tr>
        <th id="dayInstance" class="title">
          {{ 'conciliation.day' | translate }}
        </th>
        <th id="finishDate" class="title" style="min-width: 8rem">
          {{ 'conciliation.date' | translate }}
        </th>
        <th id="commentSubject" class="title">
          {{ 'conciliation.commentSubject' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-day>
      <tr *ngIf="day?.dayInstance?.comments">
        <td>
          <p class="conciliation-comment scroll-conciliation">
            <span>{{ day?.dayInstance?.confDay?.dayName | dictionary }}</span>
          </p>
        </td>
        <td>
          <p class="conciliation-comment scroll-conciliation">
            <span>{{
              formatDate(day?.dayInstance?.completedDate) || 'N/A'
            }}</span>
          </p>
        </td>
        <td>
          <p class="conciliation-comment scroll-conciliation">
            <span>{{ day?.dayInstance?.comments || 'N/A' }}</span>
          </p>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="!areComments">
  <h4 class="notResult">
    {{ 'conciliation.noResultsFoundCommentSubject' | translate }}
  </h4>
</div>

<div style="background: #fff">
  <div (click)="paginationService.openComment()">
    <p class="header title">
      {{ 'conciliation.generalComment' | translate }}
    </p>
  </div>
  <div *ngIf="conciliationService.currentComment?.id" class="p-px-3 p-pb-2">
    <p class="conciliation-comment scroll-conciliation">
      <strong>{{ conciliationService.currentComment?.comment }}</strong>
    </p>
  </div>
  <div *ngIf="!conciliationService.currentComment?.id" class="p-px-3 p-pb-2">
    <h4 class="notResult">
      {{ 'conciliation.commentNotFound' | translate }}
    </h4>
  </div>
</div>
