<div class="tp2Card p-p-5" [ngClass]="{ hide: hide }">
  <header class="p-d-flex p-justify-between p-align-center">
    <h2 class="section-title">
      {{ header }}
    </h2>
    <button
      class="btn btn-expand"
      [pTooltip]="(hide ? 'general.expand' : 'general.hide') | translate"
      (click)="hide = !hide"
    >
      <img
        [src]="
          hide
            ? './assets/icons/home/expand-outline.svg'
            : './assets/icons/home/remove-outline.svg'
        "
        alt="icon"
      />
    </button>
  </header>
  <section class="section" #section>
    <ng-content></ng-content>
  </section>
</div>
