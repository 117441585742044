import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { TransformDatePipe } from '../../pipe/transform-date.pipe';
import { ProjectRoutingModule } from '../project/project-routing.module';
import { UserModule } from '../user/user.module';
import { AddEditSubjectsComponent } from './add-edit-subjects/add-edit-subjects.component';
import { DailyPDFSubjectComponent } from './detail-subject/daily-pdf-subject/daily-pdf-subject.component';
import { DisabledEnabledDiarySubjectComponent } from './detail-subject/daily-pdf-subject/disabled-enabled-diary-subject/disabled-enabled-diary-subject.component';
import { DetailSubjectComponent } from './detail-subject/detail-subject.component';
import { ManagePdfVersionsComponent } from './detail-subject/manage-pdf-versions/manage-pdf-versions.component';
import { MediaSubjectComponent } from './detail-subject/media-subject/media-subject.component';
import { MedicalAttentionSubjectComponent } from './detail-subject/medical-attention-subject/medical-attention-subject.component';
import { MedicationSubjectComponent } from './detail-subject/medication-subject/medication-subject.component';
import { ReportExecutionModule } from './detail-subject/report-subject/report-execution/report-execution.module';
import { ReportSubjectComponent } from './detail-subject/report-subject/report-subject.component';
import { SymptomSubjectComponent } from './detail-subject/symptom-subject/symptom-subject.component';
import { TemperatureSubjectComponent } from './detail-subject/temperature-subject/temperature-subject.component';
import { ListSubjectsComponent } from './list-subjects/list-subjects.component';
import { SubjectsRoutingModule } from './subjects-routing.module';
@NgModule({
  declarations: [
    ListSubjectsComponent,
    AddEditSubjectsComponent,
    DetailSubjectComponent,
    MedicationSubjectComponent,
    MedicalAttentionSubjectComponent,
    SymptomSubjectComponent,
    ReportSubjectComponent,
    DailyPDFSubjectComponent,
    TemperatureSubjectComponent,
    MediaSubjectComponent,
    ManagePdfVersionsComponent,
    DisabledEnabledDiarySubjectComponent,
  ],
  imports: [
    SubjectsRoutingModule,
    ProjectRoutingModule,
    PrimengModule,
    SharedComponentsModule,
    UserModule,
    GalleriaModule,
    ReportExecutionModule,
  ],
  providers: [DatePipe, TransformDatePipe, DictionaryPipe],
})
export class SubjectsModule {}
