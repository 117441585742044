import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent {
  @Input() display: boolean = false;
  @Input() header: string = '';
  @Input() description: string = '';
  @Input() position: string = 'fixed';
  @Input() btnDeleteText: string = '';
  @Output() displayChange: EventEmitter<boolean> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  showDialog() {
    this.display = true;
    this.displayChange.emit(this.display);
  }

  hideDialog() {
    this.display = false;
    this.displayChange.emit(this.display);
  }
}
