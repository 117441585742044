import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import {
  CreateConfTemperatureInput,
  HelpScreenDisplayOption,
  TemperatureRoute,
  TemperatureUnit,
} from 'src/app/modules/ediary/ediary.types';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { DataInputComponent } from 'src/app/services/trialpal.types';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { DictionaryService } from 'src/app/shared/components/table-languages/dictionary.service';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';

const logger = new Logger('tp2-logger-temperatureConfPage');
@Component({
  selector: 'app-temperature',
  templateUrl: './temperatureConfigure.component.html',
  styleUrls: ['./temperatureConfigure.component.scss'],
})
export class TemperatureConfigureComponent implements OnInit {
  projectId: any;
  confTemperatures: any;
  confEdiaryId: any;
  formId: any;
  isForm: any;

  confTemperature: CreateConfTemperatureInput = {
    fahrenheitRanges: {
      minValue: null,
      maxValue: null,
      decimalPlaces: null,
      expectedMinValue: null,
      expectedMaxValue: null,
      seriousMinValue: null,
      seriousMaxValue: null,
    },
    celciusRanges: {
      minValue: null,
      maxValue: null,
      decimalPlaces: null,
      expectedMinValue: null,
      expectedMaxValue: null,
      seriousMinValue: null,
      seriousMaxValue: null,
    },
    isSeriousRangeActive: false,
    alertTextForUnexpectedValues: '',
    alertTextForSeriousValues: '',
    isExpectedRangeActive: false,
    routeOptions: [],
    units: [],
    dataInputComponent: undefined,
    allowExitToTakeTemperature: false,
    alertExitToTakeTemperatureText: '',
    helpScreenDisplayOptions: [],
    showHelpScreen: false,
    helpScreenText: '',
    buttonHelpScreenLabel: '',
    showTemperatureRoute: false,
    showTemperatureTaken: false,
    showTemperatureTakenDate: false,
    confEdiaryId: '',
    projectId: '',
    _lastUser: '',
    label: '',
    helpText: '',
    id: undefined,
    _version: 1,
  };

  routeDisplayOptions: any[] = [];
  unitsDisplayOptions: any[] = [];
  dataInputComponentDisplayOptions: any[] = [];
  helpScreenDisplayOptions: any[] = [];

  dictionary: any[] = [];
  results: string[] = [];
  isSpanish: string = '';
  isEnglish: string = '';

  readMode: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private trialpalService: TrialpalService,
    private authService: AuthService,
    private dialogService: DialogService,
    private dictionaryService: DictionaryService
  ) {}

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner(
      'general.configurations.enums.configuration',
      'GET'
    );
    if (this.config.data) {
      this.projectId = this.config.data.projectId;
      this.confEdiaryId = this.config.data.confEdiaryId;
      this.dictionary = this.config.data.dictionary;
      this.formId = this.config.data.formId;
      this.isForm = this.config.data.isForm;
      this.readMode = this.config.data.readMode;
    }
    await this.setValuesTemperature();
    this.setHelpScreenDisplayOptions();
    this.setRouteDisplayOptions();
    this.setUnitsDisplayOptions();
    this.setDataInputComponentDisplayOptions();
    this.trialpalService.hideSpinner();
  }

  getShowFahrenheitRangesMinMax() {
    return this.confTemperature.units?.includes(TemperatureUnit.FAHRENHEIT);
  }

  getShowCelsiudRangesMinMax() {
    return this.confTemperature.units?.includes(TemperatureUnit.CELSIUS);
  }

  getShowButtonHelpScreenLabel(): boolean {
    return !!this.confTemperature.helpScreenDisplayOptions?.find(
      (option) => option === HelpScreenDisplayOption.ON_DEMAND
    );
  }
  setDataInputComponentDisplayOptions() {
    const list = this.trialpalService.translateService.instant(
      'general.configurations.enums.dataInputComponent'
    );

    this.dataInputComponentDisplayOptions = Object.keys(DataInputComponent)
      .filter((key) => key !== DataInputComponent.TEXT_INPUT)
      .map((key) => ({
        label: list[key],
        value: key,
      }));
  }

  setUnitsDisplayOptions() {
    const list = this.trialpalService.translateService.instant(
      'temperaturerecordlog.enums.unit'
    );

    this.unitsDisplayOptions = Object.keys(TemperatureUnit).map((key) => ({
      label: list[key],
      value: key,
    }));
  }

  setRouteDisplayOptions() {
    const list = this.trialpalService.translateService.instant(
      'ediary.enums.temperatureRoute'
    );

    this.routeDisplayOptions = Object.keys(TemperatureRoute)
      .filter((key) => key !== TemperatureRoute.OTHER)
      .map((key) => ({
        label: list[key],
        value: key,
      }));
  }

  setHelpScreenDisplayOptions() {
    const list = this.trialpalService.translateService.instant(
      'general.configurations.helpViewPreferences'
    );
    const helpForTemp = [
      HelpScreenDisplayOption.ON_DEMAND,
      HelpScreenDisplayOption.PREVIOUS_TO_FORM,
      HelpScreenDisplayOption.SKIP_EDITION,
    ] as string[];
    this.helpScreenDisplayOptions = Object.keys(HelpScreenDisplayOption)
      .filter((key) => helpForTemp.includes(key))
      .map((key) => ({
        label: list[key],
        value: key,
      }));
  }

  async setValuesTemperature() {
    if (this.isForm) {
      try {
        const data = await this.ediaryService.getConfTemperatureByByFormId(
          this.formId
        );

        if (data) {
          this.confTemperatures = data;
          if (this.confTemperatures.items.length > 0) {
            const lastItem =
              this.confTemperatures.items[
                this.confTemperatures.items.length - 1
              ];

            this.confTemperature._version = lastItem?._version ?? 1;
            this.trialpalService.cleanQueryResponse(lastItem);
            const newObj = Object.fromEntries(
              Object.entries(lastItem).filter(([_, value]) => value !== null)
            );
            Object.assign(this.confTemperature, newObj);
          }
        }

        this.selectKeyLanguage(this.confTemperature.label);
      } catch (err) {
        logger.error(err);
        this.messageErrorOperation();
      }
    }
  }

  setHelpScreenSelectOptions(item: any) {
    return item.helpScreenDisplayOptions.map((option: any) => {
      return {
        label: this.trialpalService.translateService.instant(
          'general.configurations.helpViewPreferences.' + option
        ),
        value: option,
      };
    });
  }

  onSubmit(form: NgForm) {
    this.confTemperature.label = this.confTemperature?.label
      ? this.confTemperature?.label.trim()
      : this.confTemperature?.label;

    if (form.valid) {
      this.createUpdateTemperature();
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  /**
   * @des Actualiza o crea la configuracion de temperatura del formulario
   */
  async createUpdateTemperature() {
    if (this.confTemperatures.items.length > 0) {
      await this.updateTemperature();
    } else {
      await this.createTemperature();
    }
  }

  private async updateTemperature() {
    this.trialpalService.showSpinner(
      'general.configurations.tooltipButton',
      'UPDATE'
    );

    if (this.isForm) {
      try {
        await this.ediaryService.updateConfMTemperatureReport(
          this.confTemperature,
          this.confTemperature.id ?? '',
          this.formId,
          this.confTemperature._version ?? 1
        );
        this.messsageSuccessUpdateOnClose();
      } catch (err) {
        logger.error(err);
        this.messageErrorOperation();
      }
    }

    this.trialpalService.hideSpinner();
  }

  private async createTemperature() {
    this.trialpalService.showSpinner(
      'general.configurations.tooltipButton',
      'CREATE'
    );

    if (this.isForm) {
      this.confTemperature.confEdiaryId = this.confEdiaryId;
      this.confTemperature.projectId = this.projectId;
      this.confTemperature.confFormId = this.formId;

      try {
        await this.ediaryService.createConfTemperatureByEdiary(
          this.confTemperature
        );
        this.messsageSuccessCreateOnClose();
      } catch (err) {
        logger.error(err);
        this.messageErrorOperation();
      }
    }

    this.trialpalService.hideSpinner();
  }

  /**
   * @desc realizar la busqueda en el arreglo del diccionario y validar si el event es igual al key
   * @param event
   * @result Asigna el valor de la traduccion a las variables
   *
   */
  selectKeyLanguage(event: any) {
    if (event) {
      this.dictionary.forEach((data) => {
        if (data.key === event) {
          this.isSpanish = data.spanish;
          this.isEnglish = data.english;
        }
      });
    } else {
      this.isSpanish = '';
      this.isEnglish = '';
    }
  }

  onDictionary(): void {
    const ref = this.dialogService.open(TableLanguagesComponent, {
      data: {
        projectId: this.projectId,
        isForm: true,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe(async (data) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        this.dictionary = [];
        await this.dictionaryService
          .getConfDictionaryByProjectId(this.projectId)
          .then((dataDictionary) => {
            dataDictionary.items?.forEach((item) => {
              const obj: Dictionary = {
                id: item?.id ?? '',
                key: item?.key!,
                spanish: item?.spanish ?? '',
                isHtml: item?.isHtml ?? false,
                english: item?.english ?? '',
              };
              this.dictionary.push(obj);
            });
            localStorage.removeItem('Dictionaty');
            localStorage.setItem('Dictionary', JSON.stringify(dataDictionary));
            this.messsageSuccessSelectedTranslation();
          })
          .catch((err) => {
            logger.error(err);
            this.messageErrorOperation();
          })
          .finally(() => {
            this.selectKeyLanguage(data.key);
            this.confTemperature.label = data.key;
          });
        this.trialpalService.hideSpinner();
      }
    });
  }

  /**
   * @desc realizar la busqueda de elementos en el arreglo del diccionario por el key
   * @param event
   * @return nuevo arreglo de coincidencias del key omitiendo Mayusculas o minusculas
   */
  search(event: any) {
    this.isSpanish = '';
    this.isEnglish = '';
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  onCancel(): void {
    this.ref.close(null);
  }

  // Mensajes de operacion
  messsageSuccessCreateOnClose() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'general.transactionSuccessSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.createSuccess'
      ),
    });
    this.ref.close(null);
  }

  messsageSuccessUpdateOnClose() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'general.transactionSuccessSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.updateSuccess'
      ),
    });
    this.ref.close(null);
  }

  messsageSuccessSelectedTranslation() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'dictionary.selectTranslate'
      ),
      detail: this.trialpalService.translateService.instant(
        'dictionary.succesSelectTranslate'
      ),
    });
  }

  messageErrorOperation() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.detail'
      ),
    });
  }

  changesByShowTemperatureTaken() {
    if (!this.confTemperature.showTemperatureTaken) {
      this.confTemperature.allowExitToTakeTemperature = false;
    }
  }

  validateNumberValue(value: any): number {
    return typeof value === 'number' ? value : 0;
  }
}
