import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConfState } from 'src/app/services/trialpal.types';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { InformedConsentService } from '../../informed-consent.service';
import {
  ConfICAnswer,
  CreateConfICAnswerInput,
  CreateConfICQuestionInput,
  UpdateConfICAnswerInput,
  UpdateConfICQuestionInput,
} from '../../informed-consent.types';
const logger = new Logger('tp2-logger-addEditInformedConsentQuestionPage');

@Component({
  selector: 'app-informed-consent-question-add-edit',
  templateUrl: './informed-consent-question-add-edit.component.html',
  styleUrls: ['./informed-consent-question-add-edit.component.scss'],
})
export class InformedConsentQuestionAddEditComponent implements OnInit {
  isEdition = false;
  isReadOnly = false;
  foundMaxOneCorrectAnswer: boolean = false;
  currentRecord: any = null;
  expectedVersion: any = null;
  input = {} as CreateConfICQuestionInput | UpdateConfICQuestionInput;
  confSectionId: any = null;
  confInformedConsentId: string = '';
  confICAnswers: ConfICAnswer[] = [];
  projectId: any = null;
  INFORMED_CONSENT_QUESTION_ENTITY = 'informedConsent.question.question';
  originalConfICQuestion: any = {};
  showEditSuccessMessage: boolean = true;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly trialpalService: TrialpalService,
    private readonly informedConsentService: InformedConsentService,
    private readonly router: Router
  ) {}

  async ngOnInit() {
    this.trialpalService.showSpinner(
      this.INFORMED_CONSENT_QUESTION_ENTITY,
      'GET'
    );
    this.isReadOnly = !this.informedConsentService.auth.isAdmin();

    this.projectId = this.route.snapshot.params.projectId;
    this.confSectionId = this.route.snapshot.params.sectionId;

    this.confInformedConsentId =
      this.route.snapshot.params.confInformedConsentId;

    const currentRecordId = this.route.snapshot.params.questionId;
    if (currentRecordId) {
      this.currentRecord = await this.informedConsentService.getConfICQuestion(
        currentRecordId
      );
      this.updateCurrentRecord(this.currentRecord);
      await this.getConfIcAnswers();
    } else {
      this.setInitialValues();
    }
    this.hideSpinner();
  }

  async getConfIcAnswers() {
    if (this.input.id) {
      //Filtra los registros eliminados
      this.confICAnswers =
        await this.informedConsentService.getConfICAnswerByConfICQuestionId(
          this.input.id
        );

      this.validateConfICCorrectAnswers();

      //Ordena las preguntas
      this.confICAnswers.sort(
        (a: ConfICAnswer, b: ConfICAnswer) => a.order - b.order
      );
    }
  }

  validateConfICCorrectAnswers() {
    const confICCorrectAnswers = this.confICAnswers.filter(
      (confICAnswer: ConfICAnswer) =>
        confICAnswer.isCorrect && confICAnswer.state !== ConfState.DELETED
    );

    this.foundMaxOneCorrectAnswer = confICCorrectAnswers.length === 1;
  }

  updateCurrentRecord(data: any) {
    this.expectedVersion = data._version;
    this.trialpalService.cleanQueryResponse(data);
    delete data.project;
    delete data.confICSection;
    Object.assign(this.input, data);
    this.originalConfICQuestion = JSON.parse(JSON.stringify(this.input));
    this.isEdition = true;
  }

  setInitialValues() {
    this.confICAnswers = [];
    this.input.confICSectionId = this.confSectionId;
    this.input.confInformedConsentId = this.confInformedConsentId;
    this.input.projectId = this.projectId;
  }

  setSectionDescription(data: any): void {
    this.input.description = data;
  }

  hideSpinner(): void {
    return this.trialpalService.hideSpinner();
  }

  async onSubmit(form: NgForm) {
    this.showEditSuccessMessage = true;
    if (!form.valid) return this.trialpalService.showInvalidFormError();

    this.trialpalService.showSpinner(
      this.INFORMED_CONSENT_QUESTION_ENTITY,
      this.isEdition ? 'UPDATE' : 'CREATE'
    );

    if (this.isEdition) {
      if (
        JSON.stringify(this.originalConfICQuestion) ===
        JSON.stringify(this.input)
      ) {
        this.trialpalService.hideSpinner();
      } else {
        this.modalChangeReason();
      }
    } else {
      this.showEditSuccessMessage = false;
      await this.createConfICQuestion();
      this.updateCurrentUrl();
    }

    await this.saveConfICAnswers();
    this.showEditSuccessMessage &&
      this.trialpalService.showMutationSuccess(
        this.INFORMED_CONSENT_QUESTION_ENTITY,
        'UPDATE'
      );
  }

  async createConfICQuestion(): Promise<void> {
    await this.informedConsentService
      .createConfICQuestion(this.input)
      .then((res) => {
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_QUESTION_ENTITY,
          'CREATE'
        );
        this.updateCurrentRecord(res);
      })
      .catch((err) => {
        logger.log(err);
        this.trialpalService.showServiceError(
          this.INFORMED_CONSENT_QUESTION_ENTITY,
          err
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  updateCurrentUrl() {
    const currentUrl = this.router.url.split('question')[0];
    window.history.pushState(
      {},
      '',
      `${currentUrl}question/edit/${this.input.id}`
    );
  }

  async modalChangeReason() {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'informedConsent.audit.updatingQuestion'
        ) +
        ': ' +
        this.trialpalService.dictionaryPipe.transform(
          this.input.description ?? ''
        ),
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.input._changeReason = data;
          this.updateConfICQuestion();
        } else {
          this.showEditSuccessMessage = false;
          this.trialpalService.hideSpinner();
        }
      },
    });
  }
  async updateConfICQuestion(): Promise<void> {
    await this.informedConsentService
      .updateConfICQuestion(this.input, this.expectedVersion)
      .then((res) => {
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_QUESTION_ENTITY,
          'UPDATE'
        );
        this.updateCurrentRecord(res);
      })
      .catch((err) => {
        logger.log(err);
        this.trialpalService.showServiceError(
          this.INFORMED_CONSENT_QUESTION_ENTITY,
          err
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async saveConfICAnswers() {
    const confICAnswersPromises = this.confICAnswers.map(
      (confICAnswer: ConfICAnswer) => {
        //Si no existe crea la respuesta
        if (!confICAnswer?.id) {
          return this.createConfICAnswer(confICAnswer);
        }
        //Si existe actualiza la respuesta
        return this.updateConfICAnswer(confICAnswer);
      }
    );

    const confICAnswers = await Promise.all(confICAnswersPromises);
    //Actualiza el arreglo con los cambios
    if (JSON.stringify(confICAnswers) === JSON.stringify(this.confICAnswers)) {
      this.showEditSuccessMessage = false;
    }
    this.confICAnswers = confICAnswers;
  }

  createConfICAnswer(confICAnswer: ConfICAnswer): Promise<any> {
    const input: CreateConfICAnswerInput = {
      order: confICAnswer.order,
      confICQuestionId: this.input.id ?? '',
      confInformedConsentId: this.confInformedConsentId,
      description: confICAnswer.description,
      isCorrect: confICAnswer.isCorrect,
      projectId: this.projectId,
      onErrorMessage: confICAnswer.onErrorMessage,
      _lastUser: this.informedConsentService.auth.getUsername(),
    };

    return this.informedConsentService.createConfICAnswer(input);
  }

  updateConfICAnswer(confICAnswer: ConfICAnswer & Updated): Promise<any> {
    //Si no ha sido modificado no lo actualiza en BD
    if (!confICAnswer?.updated) return Promise.resolve(confICAnswer);
    return new Promise((resolve) => {
      const ref = this.trialpalService.dialogService.open(
        ChangeReasonComponent,
        {
          header:
            this.trialpalService.translateService.instant(
              'informedConsent.audit.updatingAnswer'
            ) +
            ': ' +
            this.trialpalService.dictionaryPipe.transform(
              confICAnswer.description ?? ''
            ),
          width: '70%',
        }
      );
      ref.onClose.subscribe({
        next: (data: string) => {
          if (data) {
            const input: UpdateConfICAnswerInput = {
              id: confICAnswer.id,
              order: confICAnswer.order,
              description: confICAnswer.description,
              isCorrect: confICAnswer.isCorrect,
              onErrorMessage: confICAnswer.onErrorMessage,
              state: confICAnswer.state,
              _changeReason: data,
              _lastUser: this.informedConsentService.auth.getUsername(),
              _version: confICAnswer._version,
            };
            resolve(this.informedConsentService.updateConfICAnswer(input));
          } else {
            this.trialpalService.hideSpinner();
            resolve(confICAnswer);
          }
        },
      });
    });
  }
}

export type Updated = {
  updated?: boolean;
};
