import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import { SectionsToConcilied } from 'src/app/shared/global.variables';
import { TrialpalService } from '../../services/trialpal.service';
import { ConfForm, SymptomType } from '../ediary/ediary.types';
import { ConciliationService } from './conciliation.service';
import { ConciliationComponents } from './conciliationData';
import { MedicalAttentionService } from './detail-conciliation/components/medical-attention/medical-attention.service';
import { MedicationService } from './detail-conciliation/components/medication/medication.service';
import { DayInstanceService } from './detail-conciliation/components/shared/day-instance/day-instance.service';
import { SignatureConciliationComponent } from './detail-conciliation/components/signature-conciliation/signature-conciliation.component';
import {
  ConfSymptomInformation,
  SymptomService,
  TableSymptom,
} from './detail-conciliation/components/symptom/symptom.service';
import { TemperatureService } from './detail-conciliation/components/temperature/temperature.service';
const logger = new Logger('Pagination Servie');
@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  display: boolean = false;
  currentSection: string = '';
  sectionName: string = '';
  sectionIndex: number = -1;
  dayName?: string = '';
  isNextSection?: boolean = true;

  //Verifica que componentes han sido abiertos
  isTemperatureOpen = false;
  isSymptomLocalOpen = false;
  isSymptomGeneralOpen = false;
  isOtherSymptomOpen = false;
  isMedicationOpen = false;
  isMedicalAttentionOpen = false;
  isCommentsOpen = false;

  //Variable que se encarga de validar si se deben mostrar o no las secciones
  sections: ReviewSection[] = [
    {
      isActive: true,
      section: ConciliationComponents.TEMPERATURE,
      configurationKey: 'isTemperatureRequired',
    },
    {
      isActive: true,
      section: ConciliationComponents.SYMPTOM_LOCAL,
      configurationKey: SymptomType.LOCAL,
    },
    {
      isActive: true,
      section: ConciliationComponents.SYMPTOM_GENERAL,
      configurationKey: SymptomType.GENERAL,
    },
    {
      isActive: true,
      section: ConciliationComponents.OTHER_SYMPTOM,
      configurationKey: 'areOtherSymptomsRequired',
    },
    {
      isActive: true,
      section: ConciliationComponents.MEDICATION,
      configurationKey: 'isMedicationRequired',
    },
    {
      isActive: true,
      section: ConciliationComponents.MEDICAl_ATTETION,
      configurationKey: 'isMedicalAttentionRequired',
    },
    {
      isActive: true,
      section: ConciliationComponents.COMMENT,
      configurationKey: 'areCommentsRequired',
    },
  ];

  constructor(
    private medicationService: MedicationService,
    private dayInstanceService: DayInstanceService,
    private symptomService: SymptomService,
    private medicalAttentionService: MedicalAttentionService,
    private temperatureService: TemperatureService,
    private trialpalService: TrialpalService,
    private conciliationService: ConciliationService
  ) {}

  //Avanza entre componentes
  goToNextSection() {
    const sectionIndex = this.sections.findIndex(
      (section: any) => section.section === this.currentSection
    );

    let currentSection = '';
    //Recorre el arreglo apartir de la sección actual y busca el siguiente componente activo
    for (let i = sectionIndex + 1; i < this.sections.length; i++) {
      if (this.sections[i].isActive) {
        currentSection = this.sections[i].section;
        break;
      }
    }
    this.isNextSection = true;
    this.onTabOpen({ index: currentSection });
  }
  //Retrocede entre componentes
  goToPreviousSection() {
    const currentSection = this.getPreviousActiveSection();
    this.isNextSection = false;
    this.onTabOpen({ index: currentSection });
  }

  //Se encarga de retornar la sección anterior a la actual activa
  getPreviousActiveSection(section?: string) {
    section = section ?? this.currentSection;
    const sectionIndex = this.sections.findIndex(
      (_section: any) => _section.section === section
    );

    let currentSection = '';

    //Recorre el arreglo apartir de la sección actual y busca la sección anterior activo
    for (let i = sectionIndex - 1; i >= 0; i--) {
      if (this.sections[i].isActive) {
        currentSection = this.sections[i].section;
        break;
      }
    }
    return currentSection;
  }

  selectedFirstTemperature() {
    if (this.temperatureService.colTemperatureDays.length) {
      const firstTemperature = this.temperatureService.colTemperatureDays[0];
      return this.selectedTemperature(firstTemperature);
    }
  }

  selectedFirstSymptomGeneral() {
    const symptomComponent =
      this.symptomService.getDetailSymptomComponentBySymptomType(
        SymptomType.GENERAL
      );
    const symptomOcurrencesTable = symptomComponent.symptomOcurrencesTable;
    const tableSymptoms = symptomOcurrencesTable[0].tableSymptoms ?? [];
    if (tableSymptoms.length) {
      this.selectedSymptom(
        tableSymptoms[0],
        0,
        ConciliationComponents.SYMPTOM_GENERAL
      );
    }
  }

  selectedFirstSymptomLocal() {
    const symptomComponent =
      this.symptomService.getDetailSymptomComponentBySymptomType(
        SymptomType.LOCAL
      ); //Referencia al componente del sintoma
    const symptomOcurrencesTable = symptomComponent.symptomOcurrencesTable;
    const tableSymptoms = symptomOcurrencesTable[0].tableSymptoms ?? [];
    if (tableSymptoms.length) {
      this.selectedSymptom(
        tableSymptoms[0],
        0,
        ConciliationComponents.SYMPTOM_LOCAL
      );
    }
  }

  selectedFirstOtherSymptom() {
    const colDaysTam = this.symptomService.otherSymptomColDays.length;
    if (colDaysTam > 1) {
      const firstDayInstance = this.symptomService.otherSymptomColDays[1];
      return this.dayInstanceSelected(
        firstDayInstance,
        ConciliationComponents.OTHER_SYMPTOM
      );
    }

    if (this.symptomService.tableOtherSymptoms.length) {
      const firstOtherSymptom = this.symptomService.tableOtherSymptoms[0];
      return this.selectedOtherSymptom(firstOtherSymptom);
    }

    this.selectedOtherSymptom({}, true);
  }

  selectedFirstMedication() {
    const colDaysTam = this.medicationService.medicationColDays.length;
    if (colDaysTam > 1) {
      const firstDayInstance = this.medicationService.medicationColDays[1];
      return this.dayInstanceSelected(
        firstDayInstance,
        ConciliationComponents.MEDICATION
      );
    }

    if (this.medicationService.tableMedications.length) {
      const firstMedicationInstance =
        this.medicationService.tableMedications[0];
      return this.selectedMedication(firstMedicationInstance);
    }

    return this.selectedMedication({}, true);
  }

  selectedFirstMedicalAttention() {
    const colDaysTam = this.medicalAttentionService.preconditionTable.length;
    //Si existe dias con precondición o tratamiento general
    if (colDaysTam > 1) {
      const firstDayInstance =
        this.medicalAttentionService.preconditionTable[1];
      return this.dayInstanceSelected(
        firstDayInstance,
        ConciliationComponents.MEDICAl_ATTETION
      );
    }

    if (this.medicalAttentionService.medicalAttentionTable.length) {
      const firstMedicalAttention =
        this.medicalAttentionService.medicalAttentionTable[0];
      const event = { data: firstMedicalAttention };
      return this.selectedMedicalAttention(event);
    }
    return this.selectedMedicalAttention({}, true);
  }

  /**
   * @desc validar la posicion del p-accordionTab para mostrar la lista de datos peteniciente a cada acordeon
   */
  onTabOpen(event: any) {
    logger.debug('onTabOpen', event);
    this.conciliationService.addVisitedSection(event.index);
    this.closeSideBar();
    this.currentSection = '';
    if (event) {
      switch (event.index) {
        case 0:
          this.openTemperature();
          break;
        case ConciliationComponents.TEMPERATURE:
          this.openTemperature();
          break;
        case 1:
          this.openSymptom(true);
          break;
        case ConciliationComponents.SYMPTOM_LOCAL:
          this.openSymptom(true);
          break;
        case 2:
          this.openSymptom(false);
          break;
        case ConciliationComponents.SYMPTOM_GENERAL:
          this.openSymptom(false);
          break;
        case 3:
          this.openOtherSymptom();
          break;
        case ConciliationComponents.OTHER_SYMPTOM:
          this.openOtherSymptom();
          break;
        case 4:
          this.openMedication();
          break;
        case ConciliationComponents.MEDICATION:
          this.openMedication();
          break;
        case 5:
          this.openMedicalAttention();
          break;
        case ConciliationComponents.MEDICAl_ATTETION:
          this.openMedicalAttention();
          break;
        case 6:
          this.openComment();
          break;
        case ConciliationComponents.COMMENT:
          this.openComment();
          break;
        default:
          break;
      }
    }
  }

  nextSection(section: ConciliationComponents) {
    const isNextSectionDisabled = this.isSectionDisabled(section, true);
    if (isNextSectionDisabled) {
      return this.trialpalService.messageService.add({
        severity: 'warn',
        summary: this.trialpalService.translateService.instant(
          'conciliation.nextSectionWarning.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'conciliation.nextSectionWarning.detail'
        ),
      });
    }
    return this.goToNextSection();
  }

  isSectionDisabled(currentSection: string, isNextSection: boolean): boolean {
    const isAutomatiReview = this.conciliationService.getIsAutomaticReview();
    const previousSectionActive = this.getPreviousActiveSection(currentSection);
    //Si es la primera sección estará activa
    if (!previousSectionActive.length || isAutomatiReview) return false;
    const currentSectionActive = isNextSection
      ? currentSection
      : previousSectionActive;
    switch (currentSectionActive) {
      case ConciliationComponents.TEMPERATURE: {
        //Verifica que el numero de teperaturas verificadas sea igual al total
        const temperatures = this.temperatureService.colTemperatureDays || [];
        const verifiedTemperatures = temperatures?.filter(
          (temp: any) => temp?.verifiedTemperature?.id
        );

        return !(
          temperatures?.length === verifiedTemperatures?.length &&
          this.isTemperatureOpen
        );
      }
      case ConciliationComponents.SYMPTOM_LOCAL: {
        //Verifica que el numero de sintomas verificados sea igual al total
        const currentProgress =
          this.symptomService.getSymptomLocalProgressBar();
        return !(currentProgress === 100 && this.isSymptomLocalOpen);
      }
      case ConciliationComponents.SYMPTOM_GENERAL: {
        const currentProgress =
          this.symptomService.getSymptomGeneralProgressBar();
        return !(currentProgress === 100 && this.isSymptomGeneralOpen);
      }
      case ConciliationComponents.OTHER_SYMPTOM: {
        //Verifica que el numero de otros sintomas verificadas sea igual al total
        let [
          cantColDays,
          cantTableOtherSymptoms,
          cantColdDaysVerifieds,
          cantOtherSymptomsVerifieds,
        ] = this.symptomService.getOtherSymptomProgressBarValues();

        return !(
          cantColDays + cantTableOtherSymptoms ===
            cantColdDaysVerifieds + cantOtherSymptomsVerifieds &&
          this.isOtherSymptomOpen
        );
      }
      case ConciliationComponents.MEDICATION: {
        let [
          tamMedications,
          tamMedicationColDays,
          tamVerifiedMedications,
          tamVerifiedMedicationColDays,
        ] = this.medicationService.getProgressMedicationValues();
        return !(
          tamMedicationColDays + tamMedications ===
            tamVerifiedMedications + tamVerifiedMedicationColDays &&
          this.isMedicationOpen
        );
      }
      case ConciliationComponents.MEDICAl_ATTETION: {
        let [
          tamMedicalAttentions,
          tamMedicalAttentionsColDays,
          tamVerifiedMedicalAttentions,
          tamVerifiedMedicalAttentionColDays,
        ] = this.medicalAttentionService.getProgressMedicalAttentionValues();

        return !(
          tamMedicalAttentions + tamMedicalAttentionsColDays ===
            tamVerifiedMedicalAttentions + tamVerifiedMedicalAttentionColDays &&
          this.isMedicalAttentionOpen
        );
      }
      default:
        return false;
    }
  }

  //Valida si los diarios tienen activo la sección
  isSectionActive(value: string): boolean {
    const dayInstances = this.conciliationService.getDayInstances();
    //Valida si la configuracion esta activa en los diarios de los componentes distintons a los sintomas
    if (value !== SymptomType.LOCAL && value !== SymptomType.GENERAL) {
      return dayInstances?.some((dayInstance: any) => {
        const configuration = dayInstance?.configuration;
        return (
          configuration[value] &&
          this.isSectionReconciliationAllowed(value, configuration)
        );
      });
    }

    const hasSymptomForm = dayInstances?.some((dayInstance: any) => {
      const configuration = dayInstance?.configuration;
      return (
        !configuration.isOtherForm &&
        this.isSectionReconciliationAllowed(value, configuration)
      );
    });

    if (!hasSymptomForm) return false;

    //Valida si hay sintomas locales/generales activo en los diarios de la visita
    return this.symptomService.confSymptoms.some(
      (confSymptom: ConfSymptomInformation) => {
        const dayInstanceFound = dayInstances.find(
          (dayInstance: any) =>
            dayInstance.dayInstance.id === confSymptom?.dayInstance.id
        );

        const isDayInstanceBeforeToCurrentDate =
          this.conciliationService.isBeforeToCurrentDate(
            confSymptom?.dayInstance?.finishDate
          );

        return (
          dayInstanceFound &&
          confSymptom.confSymptom.type === value &&
          isDayInstanceBeforeToCurrentDate
        );
      }
    );
  }

  /**
   * Determines if a specific section reconciliation is allowed based on the configuration provided.
   *
   * @param {string} value - The value representing the section to check.
   * @param {ConfForm} configuration - The configuration object containing sections to reconcile.
   * @returns {boolean} - Returns true if the specified section is allowed for reconciliation, otherwise false.
   */
  isSectionReconciliationAllowed(
    value: string,
    configuration: ConfForm
  ): boolean {
    const sectionMap: { [key: string]: SectionsToConcilied } = {
      isTemperatureRequired: SectionsToConcilied.TEMPERATURE,
      areOtherSymptomsRequired: SectionsToConcilied.OTHER_SYMPTOMS,
      isMedicationRequired: SectionsToConcilied.MEDICATION,
      isMedicalAttentionRequired: SectionsToConcilied.MEDICAL_ATTENTION,
      [SymptomType.GENERAL]: SectionsToConcilied.SOLICITED_SYMPTOMS,
      [SymptomType.LOCAL]: SectionsToConcilied.LOCAL_SYMPTOMS,
    };

    return !!configuration?.sectionsToReconcile?.includes(sectionMap[value]);
  }

  openTemperature() {
    this.isTemperatureOpen = true;
    this.currentSection = ConciliationComponents.TEMPERATURE;
    this.sectionName = 'subject.tabheaderTemperature';
    this.sectionIndex = 0;
    this.selectedFirstTemperature();
  }

  openSymptom(isLocal: boolean) {
    this.currentSection = isLocal
      ? ConciliationComponents.SYMPTOM_LOCAL
      : ConciliationComponents.SYMPTOM_GENERAL;

    this.sectionIndex = 1;

    if (isLocal) {
      this.isSymptomLocalOpen = true;
      this.sectionName = 'subject.tabheaderSymptomLocal';
      this.currentSection = ConciliationComponents.SYMPTOM_LOCAL;
      this.symptomService.symptomType = SymptomType.LOCAL;
      this.selectedFirstSymptomLocal();
    } else {
      this.isSymptomGeneralOpen = true;
      this.sectionName = 'subject.tabheaderSymptomGeneral';
      this.currentSection = ConciliationComponents.SYMPTOM_GENERAL;
      this.symptomService.symptomType = SymptomType.GENERAL;
      this.selectedFirstSymptomGeneral();
    }
  }

  openOtherSymptom() {
    this.currentSection = ConciliationComponents.OTHER_SYMPTOM;
    this.sectionName = 'subject.tabheaderOtherSymptom';
    this.sectionIndex = 2;
    this.selectedFirstOtherSymptom();
    this.isOtherSymptomOpen = true;
  }

  openMedication() {
    this.currentSection = ConciliationComponents.MEDICATION;
    this.sectionName = 'subject.tabheaderMedication';
    this.sectionIndex = 3;
    this.selectedFirstMedication();
    this.isMedicationOpen = true;
  }

  openMedicalAttention() {
    this.currentSection = ConciliationComponents.MEDICAl_ATTETION;
    this.sectionName = 'subject.tabheaderMedicalAssistance';
    this.sectionIndex = 5;
    this.selectedFirstMedicalAttention();
    this.isMedicalAttentionOpen = true;
  }

  openComment() {
    this.currentSection = ConciliationComponents.COMMENT;
    this.sectionName = 'subject.tabheaderComments';
    this.sectionIndex = 6;
    this.isCommentsOpen = true;
    this.openSideBar();
  }

  //Funciones que seleccionan los items

  selectedMedication(medication: any, isNewMedication: boolean = false) {
    if (isNewMedication) {
      this.medicationService.currentMedication = 'isNew';
      this.medicationService.isNewMedicationInstance = true;
      this.dayName = '';
      this.sectionIndex = 3;
      this.dayInstanceService.currentDayInstance = {};
      this.currentSection = ConciliationComponents.MEDICATION;
      this.openSideBar();
    } else if (medication?.medication?.id) {
      this.medicationService.isNewMedicationInstance = false;
      this.medicationService.currentMedication = medication;
      this.dayName = medication?.dayInstance?.confDay?.dayName;
      this.sectionIndex = 3;
      this.dayInstanceService.currentDayInstance = {};
      this.currentSection = ConciliationComponents.MEDICATION;
      this.openSideBar();
    } else {
      this.closeSideBar();
    }

    logger.debug(medication, 'current medication');
  }

  selectedSymptom(
    currentSymptom: TableSymptom | undefined,
    activeAccordionTabIndex: number,
    currentSection: ConciliationComponents
  ) {
    this.symptomService.currentSymptom = undefined;
    setTimeout(() => {
      this.symptomService.currentSymptom = currentSymptom;
      this.symptomService.activeAccordionTabIndex = activeAccordionTabIndex;
      this.dayName = currentSymptom?.dayName;
      this.sectionIndex = 1;
      this.currentSection = currentSection;
      this.openOrCloseSideBar(!currentSymptom?.symptomInstance?.id);
      logger.debug('current Symptom', currentSymptom);
    }, 100);
  }

  selectedOtherSymptom(
    otherSymptom: any,
    isNewOtherSymptom: boolean = false
  ): any {
    if (isNewOtherSymptom) {
      this.symptomService.currentOtherSymptom = 'isNew';
      this.symptomService.isNewOtherSymptom = true;
      this.dayName = '';
      this.sectionIndex = 2;
      this.dayInstanceService.currentDayInstance = {};
      this.currentSection = ConciliationComponents.OTHER_SYMPTOM;
      this.openSideBar();
    } else if (otherSymptom?.otherSymptom?.id) {
      this.symptomService.currentOtherSymptom = otherSymptom;
      this.symptomService.isNewOtherSymptom = false;
      this.dayName = otherSymptom.dayName;
      this.sectionIndex = 2;
      this.dayInstanceService.currentDayInstance = {};
      this.currentSection = ConciliationComponents.OTHER_SYMPTOM;
      this.openSideBar();
    } else {
      this.closeSideBar();
    }
    logger.debug('current otherSymptom', otherSymptom);
  }

  dayInstanceSelected(
    dayInstance: any,
    currentSection: ConciliationComponents
  ) {
    if (dayInstance.dayInstance !== '') {
      this.sectionIndex = 4;
      this.dayInstanceService.currentDayInstance = dayInstance;
      this.dayName = dayInstance.header;
      this.symptomService.currentOtherSymptom = null;
      this.medicationService.currentMedication = null;
      this.medicalAttentionService.medicalAttention = undefined;
      this.medicalAttentionService.selectedMedicalAttention = null;
      this.currentSection = currentSection;
      this.openSideBar();
      logger.debug('current dayInstance', dayInstance);
    } else {
      this.closeSideBar();
    }
  }

  selectedMedicalAttention(event: any, isNewMedicalAttention: boolean = false) {
    if (event?.data?.configuration?.id) {
      this.sectionIndex = 5;
      this.medicalAttentionService.medicalAttention = event?.data;
      this.medicalAttentionService.isNewMedicalAttention = false;
      this.medicalAttentionService.addMedicalAttention = false;
      this.medicalAttentionService.selectedMedicalAttention = event?.data;
      this.currentSection = ConciliationComponents.MEDICAl_ATTETION;
      this.openSideBar();
    } else if (isNewMedicalAttention) {
      this.medicalAttentionService.medicalAttention = 'isNew';
      this.medicalAttentionService.isNewMedicalAttention = true;
      this.dayName = '';
      this.sectionIndex = 5;
      this.dayInstanceService.currentDayInstance = {};
      this.currentSection = ConciliationComponents.MEDICAl_ATTETION;
      this.openSideBar();
    } else {
      this.closeSideBar();
    }
    logger.debug('current medicationAttention', event);
  }

  selectedTemperature(record: any) {
    this.temperatureService.currentTemperatureDay = undefined;
    setTimeout(() => {
      this.sectionIndex = 0;
      this.temperatureService.currentTemperatureDay = record;
      this.dayName = this.temperatureService.currentTemperatureDay?.header;
      this.currentSection = ConciliationComponents.TEMPERATURE;
      this.openOrCloseSideBar(false);
      logger.debug(
        this.temperatureService.currentTemperatureDay,
        'current temperature'
      );
    }, 100);
  }

  openOrCloseSideBar(closeModal: boolean) {
    if (closeModal) {
      this.closeSideBar();
    } else {
      this.openSideBar();
    }
  }

  openSideBar() {
    this.display = true;
  }

  closeSideBar() {
    this.display = false;
  }

  showSignature() {
    this.trialpalService.dialogService.open(SignatureConciliationComponent, {
      header: this.trialpalService.translateService.instant(
        'conciliation.headerSignature'
      ),
      styleClass: 'signature-modal',
      closable: false,
    });
  }
}

export interface ReviewSection {
  isActive: boolean;
  section: ConciliationComponents;
  configurationKey: string;
}
