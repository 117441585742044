import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Dictionary } from '../table-languages/dictionary';
import { DictionaryService } from '../table-languages/dictionary.service';
import { TableLanguagesComponent } from '../table-languages/table-languages.component';
import {
  CreateConfMedicalAttentionInput,
  CreateConfMedicationInput,
  CreateConfMultimediaInput,
  CreateConfOtherSymptomsInput,
  MedicalAttentionConfigurationMode,
  MedicalAttentionType,
  MedicationConfigurationMode,
} from 'src/app/modules/ediary/ediary.types';

const logger = new Logger('conf-symtoms-medication');

@Component({
  selector: 'configurations-symtoms-medication-app.component',
  templateUrl: './configurations-symtoms-medication.html',
  styleUrls: ['./configurations-symtoms-medication.scss'],
})
export class ConfigurationsSymtomsMedicationsAppComponent implements OnInit {
  isBindToSymptomOcurrencies: boolean = false;
  medicationConfMode!: MedicationConfigurationMode;
  medicalConfMode!: MedicalAttentionConfigurationMode;
  showIntensity: boolean = false;
  showReason: boolean = false;
  showPrecondition: boolean = false;
  showDose: boolean = false;
  showFrequency: boolean = false;
  showAdministrationRoute: boolean = false;
  showType: boolean = false;
  showRememberStartDate: boolean = false;
  showRememberFinishDate: boolean = false;
  showDetail: boolean = false;
  showProfessionalHealthCare: boolean = false;
  showEmergencyType: boolean = false;
  showMedicalAppointmentType: boolean = false;
  showTelemedicineType: boolean = false;
  showHospitalizationType: boolean = false;
  showHospitalization: boolean = false;
  preconditionLabel: string = '';
  helpText: string = '';

  requiredMedicationConfMode: boolean = false;
  requiredsymptomOcurrencies: boolean = false;
  requiredIntensity: boolean = false;
  requiredReason: boolean = false;
  requiredPrecondition: boolean = false;
  requiredDose: boolean = false;
  requiredFrequency: boolean = false;
  requiredAdministrationRoute: boolean = false;
  requiredType: boolean = false;
  requiredRememberStartDate: boolean = false;
  requiredRememberFinishDate: boolean = false;
  requiredDetail: boolean = false;
  requiereLabel: boolean = false;
  requiredHelp: boolean = false;
  requireProfessionalHealthCare: boolean = false;
  requiredShowPrecondition: boolean = false;
  requiredShowHospitalization: boolean = false;
  requiredConfigurationMode: boolean = false;
  medicationConfModes: any[] = [];
  medicalConfModes: any[] = [];

  projectId: string = '';
  id: string = '';
  version: number = 1;
  confEdiaryId: string = '';
  entityConfiguration: string = '';
  confMedication: any;
  confMedicalAttention: any;
  confMultimedia: any;
  confOtherSymptoms: any;
  title: string = '';

  dictionary: any[] = [];
  results: string[] = [];
  isSpanish: string = '';
  isEnglish: string = '';
  formId: string = '';
  confForm: any = {};
  isForm: boolean = false;
  typeInvalid: boolean = false;

  readMode: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private trialpalService: TrialpalService,
    private authService: AuthService,
    private dialogService: DialogService,
    private dictionaryService: DictionaryService
  ) {}

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner(
      'general.configurations.enums.configuration',
      'GET'
    );
    if (this.config.data) {
      this.projectId = this.config.data.projectId;
      this.confEdiaryId = this.config.data.confEdiaryId;
      this.entityConfiguration = this.config.data.entityConfiguration;
      this.dictionary = this.config.data.dictionary;
      this.formId = this.config.data.formId;
      this.isForm = this.config.data.isForm;
      this.confForm = this.config.data.confForm;
      this.readMode = this.config.data.readMode;
    }

    if (this.entityConfiguration === 'medication') {
      await this.setValuesMedication();
    }
    if (this.entityConfiguration === 'otherSymptom') {
      await this.setValuesOtherSymptom();
    }
    if (this.entityConfiguration === 'medicalAttention') {
      await this.setValuesMedicalAttention();
    }
    if (this.entityConfiguration === 'multimedia') {
      await this.setValuesMultimedia();
    }

    this.trialpalService.hideSpinner();
  }

  async setValuesMedication() {
    this.title = 'ediary.components.generalConfiguration.labelMedications';
    this.requiredMedicationConfMode = true;
    this.requiredFrequency = true;
    this.requiredDose = true;
    this.requiredRememberStartDate = true;
    this.requiredRememberFinishDate = true;
    this.requiredAdministrationRoute = true;
    this.requiredShowPrecondition = true;
    this.requiereLabel = false;

    const medicationConfModes = this.trialpalService.getTranslatedEnum(
      'ediary.enums.medicationConfigurationMode',
      MedicationConfigurationMode
    );

    this.medicationConfModes = this.setConfModes(medicationConfModes);

    if (this.isForm) {
      await this.ediaryService
        .getConfMedicationByFormId(this.formId)
        .then((data) => {
          this.confMedication = data;
          this.confMedication.items?.forEach((item: any) => {
            this.id = item.id;
            this.version = item._version;
            this.isBindToSymptomOcurrencies = item?.isBindToSymptomOcurrencies!;
            this.showAdministrationRoute = item?.showAdministrationRoute!;
            this.showDose = item?.showDose!;
            this.showFrequency = item?.showFrequency!;
            this.showReason = item?.showReason!;
            this.showRememberStartDate = item?.showRememberStartDate!;
            this.showRememberFinishDate = item?.showRememberFinishDate!;
            this.preconditionLabel = item?.label!;
            this.medicationConfMode = item?.mode!;
            this.showPrecondition = item.showPrecondition;
          });
          this.selectKeyLanguage(this.preconditionLabel);
        });
    }
  }

  setConfModes(confModes: any[]) {
    const { isOtherForm, areOtherSymptomsRequired } = this.confForm;

    if (isOtherForm && !areOtherSymptomsRequired) {
      confModes = confModes.filter((confMode: any) => {
        return !confMode.value.includes('SYMPTOM');
      });
    }

    return confModes;
  }

  async setValuesOtherSymptom() {
    this.title = 'ediary.components.generalConfiguration.labelOtherSymptom';
    this.requiredIntensity = true;
    this.requiredRememberStartDate = true;
    this.requiredRememberFinishDate = true;
    this.requireProfessionalHealthCare = true;
    this.requiredShowPrecondition = true;
    this.requiereLabel = false;
    if (this.isForm) {
      await this.ediaryService
        .getConfOtherSymtompByByFormId(this.formId)
        .then((res) => {
          this.confOtherSymptoms = res;
          this.confOtherSymptoms.items?.forEach((item: any) => {
            this.showIntensity = item.showIntensity;
            this.showRememberFinishDate = item.showRememberFinishDate;
            this.showRememberStartDate = item.showRememberStartDate;
            this.showProfessionalHealthCare = item.showProfessionalHealthCare;
            this.showPrecondition = item.showPrecondition;
            this.preconditionLabel = item?.preconditionLabel!;

            this.id = item.id;
            this.version = item._version;
          });
        });
    } else {
      await this.ediaryService
        .getConfOtherSymtompByEdiaryId(this.confEdiaryId)
        .then((res) => {
          this.confOtherSymptoms = res;
          this.confOtherSymptoms.items?.forEach((item: any) => {
            if (!item.formId) {
              this.showIntensity = item.showIntensity;
              this.showRememberFinishDate = item.showRememberFinishDate;
              this.showRememberStartDate = item.showRememberStartDate;
              this.showProfessionalHealthCare = item.showProfessionalHealthCare;
              this.showPrecondition = item.showPrecondition;
              this.preconditionLabel = item?.preconditionLabel!;
              this.id = item.id;
              this.version = item._version;
            }
          });
        });
    }
    this.selectKeyLanguage(this.preconditionLabel);
  }

  async setValuesMedicalAttention() {
    this.title = 'ediary.components.generalConfiguration.labelMedicalAttention';
    this.requiredsymptomOcurrencies = true;
    this.requiredRememberStartDate = true;
    this.requiredRememberFinishDate = true;
    this.requiredShowHospitalization = true;
    this.requiredConfigurationMode = true;
    this.requiredType = true;
    this.requiredPrecondition = true;
    this.requiereLabel = false;

    const medicalConfModes = this.trialpalService.getTranslatedEnum(
      'ediary.enums.medicalAttentionConfigurationMode',
      MedicalAttentionConfigurationMode
    );

    this.medicalConfModes = this.setConfModes(medicalConfModes);

    if (this.isForm) {
      await this.ediaryService
        .getConfMedicalAttentionByFormId(this.formId)
        .then((result) => {
          this.confMedicalAttention = result;
          this.confMedicalAttention.items?.forEach((data: any) => {
            this.id = data.id;
            this.version = data._version;
            this.isBindToSymptomOcurrencies = data.isBindToSymptomOcurrencies!;
            this.showRememberStartDate = data.showRememberStartDate!;
            this.showRememberFinishDate = data.showRememberFinishDate!;
            this.showReason = data.showReason!;
            this.showHospitalization = data.showHospitalization;
            this.medicalConfMode = data.mode;
            this.showPrecondition = data.showPrecondition;
            this.preconditionLabel = data.label;
            this.showType = data.showType;
            this.setTypes(data.medicalAttentionTypes);
          });
        });
    } else {
      await this.ediaryService
        .getConfMedicalAttentionByEdiaryId(this.confEdiaryId)
        .then((result) => {
          this.confMedicalAttention = result;
          this.confMedicalAttention.items?.forEach((data: any) => {
            if (!data.formId) {
              this.id = data.id;
              this.version = data._version;
              this.isBindToSymptomOcurrencies =
                data.isBindToSymptomOcurrencies!;
              this.showRememberStartDate = data.showRememberStartDate!;
              this.showRememberFinishDate = data.showRememberFinishDate!;
              this.showReason = data.showReason!;
              this.showHospitalization = data.showHospitalization;
              this.showPrecondition = data.showPrecondition;
              this.preconditionLabel = data.label;
              this.showType = data.showType;
              this.setTypes(data.medicalAttentionTypes);
            }
          });
        });
    }
  }

  setTypes(medicalAttentionTypes: any[]) {
    medicalAttentionTypes?.forEach((x) => {
      if (x === MedicalAttentionType.HOSPITALIZATION) {
        this.showHospitalizationType = true;
      }
      if (x === MedicalAttentionType.ER) {
        this.showEmergencyType = true;
      }
      if (x === MedicalAttentionType.MEDICAL_APPONTMENT) {
        this.showMedicalAppointmentType = true;
      }
      if (x === MedicalAttentionType.TELEMEDICINE) {
        this.showTelemedicineType = true;
      }
    });
  }

  async setValuesMultimedia() {
    this.title = 'general.configurations.enums.multimedia';
    this.requiredDetail = true;
    this.requiereLabel = true;
    this.requiredHelp = true;

    if (this.isForm) {
      await this.ediaryService
        .getConfMultimediaByFormId(this.formId)
        .then((response) => {
          this.confMultimedia = response;
          this.confMultimedia.items?.forEach((data: any) => {
            this.id = data.id;
            this.version = data._version;
            this.showDetail = data.showDetail;
            this.preconditionLabel = data.label;
            this.helpText = data.helpText;
          });
        });
    } else {
      await this.ediaryService
        .getConfMultimediaByEdiaryId(this.confEdiaryId)
        .then((data) => {
          this.confMultimedia = data;
          this.confMultimedia.items?.forEach((multimedia: any) => {
            if (!multimedia.formId) {
              this.id = multimedia.id;
              this.version = multimedia._version;
              this.showDetail = multimedia.showDetail;
              this.preconditionLabel = multimedia.label;
              this.helpText = multimedia.helpText;
            }
          });
        });
    }
    this.selectKeyLanguage(this.preconditionLabel);
  }

  onSubmit(form: NgForm) {
    this.preconditionLabel = this.preconditionLabel?.trim();
    this.confirmErrors(form);

    if (form.valid) {
      if (this.entityConfiguration === 'medication') {
        this.createupdateConfMedication();
      }
      if (this.entityConfiguration === 'medicalAttention') {
        this.createUpdateConfMedicalAttention();
      }
      if (this.entityConfiguration === 'otherSymptom') {
        this.createUpdateOtherSymptoms();
      }
      if (this.entityConfiguration === 'multimedia') {
        this.createUpdateMultimedia();
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(
      this.preconditionLabel,
      'labelShow',
      form
    );
    if (this.requiredHelp) {
      //envia el html a un objeto HMTL y luego obtiene el texto sin espacios
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = this.helpText || '';
      //Si contiene una imagen deja el campo como valido
      if (this.helpText?.includes('<img')) {
        this.trialpalService.validateSpaces('<img', 'ediaryHelp', form);
      } else {
        this.trialpalService.validateSpaces(
          htmlObject.innerText.trim(),
          'ediaryHelp',
          form
        );
      }
    }
  }

  onCancel(): void {
    this.ref.close(null);
  }

  changeStartDate(event: any) {
    if (!event.checked) {
      this.showRememberFinishDate = false;
    }
  }

  async createupdateConfMedication() {
    if (this.confMedication.items.length > 0) {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'UPDATE'
      );
      if (this.isForm) {
        const updateConfMedication: CreateConfMedicationInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          id: this.id,
          confFormId: this.formId,
          showAdministrationRoute: this.showAdministrationRoute,
          showDose: this.showDose,
          showFrequency: this.showFrequency,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          mode: this.medicationConfMode,
          _lastUser: this.authService.getUsername(),
          _version: this.version,
        };

        await this.ediaryService
          .updateMedicationReport(
            updateConfMedication,
            this.id,
            this.formId,
            this.version
          )
          .then(() => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
      } else {
        const updateConfMedication: CreateConfMedicationInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          id: this.id,
          showAdministrationRoute: this.showAdministrationRoute,
          showDose: this.showDose,
          mode: this.medicationConfMode,
          showFrequency: this.showFrequency,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
          _version: this.version,
        };

        await this.ediaryService
          .updateConfMedication(updateConfMedication, this.id, this.version)
          .then(() => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
      }
      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'CREATE'
      );
      if (this.isForm) {
        const createConfMedication: CreateConfMedicationInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          confFormId: this.formId,
          showAdministrationRoute: this.showAdministrationRoute,
          showDose: this.showDose,
          mode: this.medicationConfMode,
          showFrequency: this.showFrequency,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfMedicationByEdiary(createConfMedication)
          .then(() => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
      } else {
        const createConfMedication: CreateConfMedicationInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          showAdministrationRoute: this.showAdministrationRoute,
          showDose: this.showDose,
          mode: this.medicationConfMode,
          showFrequency: this.showFrequency,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfMedicationByEdiary(createConfMedication)
          .then(() => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
      }
      this.trialpalService.hideSpinner();
    }
  }

  changeTypeValid(event: any) {
    if (event) {
      this.typeInvalid = false;
    }
  }

  async createUpdateConfMedicalAttention() {
    const medicalAttentionTypes: MedicalAttentionType[] = [];
    this.typeInvalid = false;

    //Agrega al array los tipos de atenciones medicas
    if (this.showMedicalAppointmentType) {
      medicalAttentionTypes.push(MedicalAttentionType.MEDICAL_APPONTMENT);
    }
    if (this.showHospitalizationType) {
      medicalAttentionTypes.push(MedicalAttentionType.HOSPITALIZATION);
    }
    if (this.showEmergencyType) {
      medicalAttentionTypes.push(MedicalAttentionType.ER);
    }
    if (this.showTelemedicineType) {
      medicalAttentionTypes.push(MedicalAttentionType.TELEMEDICINE);
    }

    //Verifica si el usuario no inhabilita todos los campos
    if (
      !this.showType &&
      this.medicalConfMode === MedicalAttentionConfigurationMode.NO_REASON &&
      !this.showRememberStartDate &&
      !this.showHospitalization
    ) {
      this.messageWarningMedicalAttention();
      return;
    }

    //Si el tipo está activo, verifica que se agrego un tipo al array
    if (this.showType && medicalAttentionTypes.length === 0) {
      this.typeInvalid = true;
      this.trialpalService.showInvalidFormError();
      return;
    }

    if (this.confMedicalAttention.items.length > 0) {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'UPDATE'
      );
      if (this.isForm) {
        const updateConfMedicalAttention: CreateConfMedicalAttentionInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          confFormId: this.formId,
          id: this.id,
          mode: this.medicalConfMode,
          isBindToSymptomOcurrencies: this.isBindToSymptomOcurrencies,
          showRememberStartDate: this.showRememberStartDate,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          showRememberFinishDate: this.showRememberFinishDate,
          showHospitalization: this.showHospitalization,
          showType: this.showType,
          medicalAttentionTypes,
          _lastUser: this.authService.getUsername(),
          _version: this.version,
        };
        await this.ediaryService
          .updateMedicalAttentionReport(
            updateConfMedicalAttention,
            this.id,
            this.formId,
            this.version
          )
          .then((data) => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((error) => {
            this.messageErrorOperation();
          });
      } else {
        const updateConfMedicalAttention: CreateConfMedicalAttentionInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          mode: this.medicalConfMode,
          id: this.id,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          isBindToSymptomOcurrencies: this.isBindToSymptomOcurrencies,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showHospitalization: this.showHospitalization,
          showType: this.showType,
          medicalAttentionTypes,
          _lastUser: this.authService.getUsername(),
          _version: this.version,
        };
        await this.ediaryService
          .updateConfMedicalAttention(
            updateConfMedicalAttention,
            this.id,
            this.version
          )
          .then((data) => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((error) => {
            this.messageErrorOperation();
          });
      }
      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'CREATE'
      );
      if (this.isForm) {
        const createConfMedicalAttention: CreateConfMedicalAttentionInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          confFormId: this.formId,
          mode: this.medicalConfMode,
          isBindToSymptomOcurrencies: this.isBindToSymptomOcurrencies,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showHospitalization: this.showHospitalization,
          showType: this.showType,
          medicalAttentionTypes,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfMedicalAttentionByEdiary(createConfMedicalAttention)
          .then((data) => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
      } else {
        const createConfMedicalAttention: CreateConfMedicalAttentionInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          mode: this.medicalConfMode,
          isBindToSymptomOcurrencies: this.isBindToSymptomOcurrencies,
          showPrecondition: this.showPrecondition,
          label: this.preconditionLabel,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showHospitalization: this.showHospitalization,
          showType: this.showType,
          medicalAttentionTypes,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfMedicalAttentionByEdiary(createConfMedicalAttention)
          .then((data) => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
      }
      this.trialpalService.hideSpinner();
    }
  }

  async createUpdateOtherSymptoms() {
    if (this.confOtherSymptoms.items.length > 0) {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'UPDATE'
      );
      if (this.isForm) {
        const updateConf: CreateConfOtherSymptomsInput = {
          confEdiaryId: this.confEdiaryId,
          id: this.id,
          confFormId: this.formId,
          _version: this.version,
          projectId: this.projectId,
          showIntensity: this.showIntensity,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showProfessionalHealthCare: this.showProfessionalHealthCare,
          showPrecondition: this.showPrecondition,
          preconditionLabel: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .updateOtherSymtompReport(
            updateConf,
            this.id,
            this.formId,
            this.version
          )
          .then((data) => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((error) => {
            this.messageErrorOperation();
          });
      } else {
        const updateConf: CreateConfOtherSymptomsInput = {
          confEdiaryId: this.confEdiaryId,
          id: this.id,
          _version: this.version,
          projectId: this.projectId,
          showIntensity: this.showIntensity,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showProfessionalHealthCare: this.showProfessionalHealthCare,
          showPrecondition: this.showPrecondition,
          preconditionLabel: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .updateConfOtherSymptom(updateConf, this.id, this.version)
          .then((data) => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((error) => {
            this.messageErrorOperation();
          });
      }
      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'CREATE'
      );
      if (this.isForm) {
        const createConf: CreateConfOtherSymptomsInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          confFormId: this.formId,
          showIntensity: this.showIntensity,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showProfessionalHealthCare: this.showProfessionalHealthCare,
          showPrecondition: this.showPrecondition,
          preconditionLabel: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfOtherSyptomsByEdiary(createConf)
          .then((data) => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
        this.trialpalService.hideSpinner();
      } else {
        const createConf: CreateConfOtherSymptomsInput = {
          confEdiaryId: this.confEdiaryId,
          projectId: this.projectId,
          showIntensity: this.showIntensity,
          showRememberStartDate: this.showRememberStartDate,
          showRememberFinishDate: this.showRememberFinishDate,
          showProfessionalHealthCare: this.showProfessionalHealthCare,
          showPrecondition: this.showPrecondition,
          preconditionLabel: this.preconditionLabel,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfOtherSyptomsByEdiary(createConf)
          .then((data) => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
        this.trialpalService.hideSpinner();
      }
    }
  }
  async createUpdateMultimedia() {
    if (this.confMultimedia.items.length > 0) {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'UPDATE'
      );
      if (this.isForm) {
        const updateConfMultimedia: CreateConfMultimediaInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          id: this.id,
          confFormId: this.formId,
          label: this.preconditionLabel,
          helpText: this.helpText,
          showDetail: this.showDetail,
          _lastUser: this.authService.getUsername(),
          _version: this.version,
        };
        await this.ediaryService
          .updateConfMultimediaReport(
            updateConfMultimedia,
            this.id,
            this.formId,
            this.version
          )
          .then((data) => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((error) => {
            this.messageErrorOperation();
          });
      } else {
        const updateConfMultimedia: CreateConfMultimediaInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          id: this.id,
          label: this.preconditionLabel,
          showDetail: this.showDetail,
          helpText: this.helpText,
          _lastUser: this.authService.getUsername(),
          _version: this.version,
        };
        await this.ediaryService
          .updateConfMultimedia(updateConfMultimedia, this.id, this.version)
          .then((data) => {
            this.messsageSuccessUpdateOnClose();
          })
          .catch((error) => {
            this.messageErrorOperation();
          });
      }
      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.showSpinner(
        'general.configurations.tooltipButton',
        'CREATE'
      );
      if (this.isForm) {
        const createConfMultimedia: CreateConfMultimediaInput = {
          projectId: this.projectId,
          confEdiaryId: this.confEdiaryId,
          confFormId: this.formId,
          showDetail: this.showDetail,
          label: this.preconditionLabel,
          helpText: this.helpText,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfMultimediaByEdiary(createConfMultimedia)
          .then((data) => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
        this.trialpalService.hideSpinner();
      } else {
        const createConfMultimedia: CreateConfMultimediaInput = {
          confEdiaryId: this.confEdiaryId,
          showDetail: this.showDetail,
          projectId: this.projectId,
          label: this.preconditionLabel,
          helpText: this.helpText,
          _lastUser: this.authService.getUsername(),
        };
        await this.ediaryService
          .createConfMultimediaByEdiary(createConfMultimedia)
          .then((data) => {
            this.messsageSuccessCreateOnClose();
          })
          .catch((err) => {
            this.messageErrorOperation();
          });
        this.trialpalService.hideSpinner();
      }
    }
  }

  messsageSuccessCreateOnClose() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'general.transactionSuccessSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.createSuccess'
      ),
    });
    this.ref.close(null);
  }
  messsageSuccessUpdateOnClose() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'general.transactionSuccessSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.updateSuccess'
      ),
    });
    this.ref.close(null);
  }

  messageWarningMedicalAttention() {
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: this.trialpalService.translateService.instant(
        'general.configurations.messageWarnMedicalAttention'
      ),
      detail:
        this.trialpalService.translateService.instant(
          'general.configurations.enums.type'
        ) +
        ', ' +
        this.trialpalService.translateService.instant(
          'general.configurations.labelMode'
        ) +
        ', ' +
        this.trialpalService.translateService.instant(
          'general.configurations.enums.startDate'
        ) +
        ', ' +
        this.trialpalService.translateService.instant(
          'general.configurations.enums.hospitalization'
        ),
    });
  }

  messageErrorOperation() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.detail'
      ),
    });
  }

  onDictionaty(isForm: boolean): void {
    const ref = this.dialogService.open(TableLanguagesComponent, {
      data: {
        projectId: this.projectId,
        isForm: isForm,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe(async (data: any) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        this.dictionary = [];
        await this.dictionaryService
          .getConfDictionaryByProjectId(this.projectId)
          .then((dataDictionary) => {
            dataDictionary.items?.forEach((item) => {
              const obj: Dictionary = {
                id: item?.id ?? '',
                key: item?.key!,
                isHtml: item?.isHtml ?? false,
                spanish: item?.spanish ?? '',
                english: item?.english ?? '',
              };
              this.dictionary.push(obj);
            });
            localStorage.removeItem('Dictionaty');
            localStorage.setItem('Dictionary', JSON.stringify(dataDictionary));
            this.trialpalService.messageService.add({
              severity: 'success',
              summary: this.trialpalService.translateService.instant(
                'dictionary.selectTranslate'
              ),
              detail: this.trialpalService.translateService.instant(
                'dictionary.succesSelectTranslate'
              ),
            });
          })
          .catch((err) => logger.error(err))
          .finally(() => {
            this.selectKeyLanguage(data.key);
            this.preconditionLabel = data.key;
          });
        this.trialpalService.hideSpinner();
      }
    });
  }

  search(event: any) {
    this.isSpanish = '';
    this.isEnglish = '';
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  selectKeyLanguage(event: any) {
    if (event) {
      this.dictionary.forEach((data) => {
        if (data.key === event) {
          this.isSpanish = data.spanish;
          this.isEnglish = data.english;
        }
      });
    } else {
      this.isSpanish = '';
      this.isEnglish = '';
    }
  }
}
