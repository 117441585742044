import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { emailRegex } from 'src/app/shared/global.variables';
import { SitesService } from '../sites.service';
import { timeZome } from '../sites.timezone';
import { CreateSiteInput, GetSiteQuery } from '../sites.types';

const logger = new Logger('tp2-logger-siteAddEditPage');
@Component({
  selector: 'app-add-edit-sites',
  templateUrl: './add-edit-sites.component.html',
  styleUrls: ['./add-edit-sites.component.scss'],
})
export class AddEditSitesComponent implements OnInit {
  site: CreateSiteInput = {
    projectId: '',
    name: '',
    siteCode: '',
    timezone: '',
    defaultLanguage: '',
    additionalLanguages: [],
    contactInfo: '',
    _lastUser: '',
    showContactInfo: false,
    showContactInfoLogin: false,
    isTimezoneNotificationEnable: false,
    notificationSchedule: [],
    dailyPendingDiariesAlertRecipients: [],
    isDailyPendingDiariesNotificationEnable: false,
  };
  contactTextHTML: string = '';
  siteChange: string = '';
  siteId: string = '';
  editMode = false;
  isModal = false;
  expectedVersion = 0;
  languageOptions: { label: string; value: Language }[] = [];
  additionalLanguagesOptions: { label: string; value: Language }[] = [];
  timezoneOptions: any[] = [];
  hoursOptions: any[] = [];
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private sitesService: SitesService,
    private trialpalService: TrialpalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}
  @ViewChild('f', { static: true })
  centerForm!: NgForm;

  ngOnInit(): void {
    this.editMode = this.config?.data?.isEdit;
    this.siteId = this.config?.data?.id;
    this.isModal = this.config?.data?.isModal;
    for (const tz of timeZome) {
      for (const utc of tz.utc) {
        this.timezoneOptions.push({ name: utc, time: tz.offset });
      }
    }
    this.timezoneOptions = [...this.timezoneOptions].sort(function SortArray(
      x,
      y
    ) {
      if (x.name < y.name) {
        return -1;
      }
      if (x.name > y.name) {
        return 1;
      }
      return 0;
    });
    this.buildLanguageOptions();
    this.hoursOptions = this.generateHours();
    if (this.editMode && this.siteId) {
      this.getSiteQuery();
    } else {
      this.site.projectId = this.config?.data?.projectId;
    }
  }
  getSiteQuery(): void {
    this.trialpalService.showSpinner('site.entity', 'GET');
    this.sitesService
      .getSite(this.siteId)
      .then((response: GetSiteQuery) => {
        logger.debug('getProject response', response);
        delete response.project;
        this.expectedVersion = response._version;
        this.trialpalService.cleanQueryResponse(response);
        this.site = Object.assign(this.site, response);
        this.buildLanguageOptions();

        //Convierte el dato en string para luego ser probado
        this.siteChange = JSON.stringify(this.site);
        this.trialpalService.hideSpinner();
      })
      .catch((error) => {
        logger.error('getProject error', error);
        this.trialpalService.showServiceError(
          'project.actions.getProject',
          error
        );
        this.trialpalService.hideSpinner();
      });
  }
  buildLanguageOptions(): any {
    const languages = Object.values(Language);
    const languagesOptions = [];
    for (let language of languages) {
      languagesOptions.push({
        value: language,
        label: this.trialpalService.translateService.instant(
          `site.languages.${language}`
        ),
      });
    }
    this.languageOptions = languagesOptions;
    this.additionalLanguagesOptions = this.languageOptions.filter(
      (language) => language.value !== this.site.defaultLanguage
    );
  }

  /**
   * onDefaultLanguageChange
   *
   * Esta función maneja los cambios del idioma predeterminado del sitio. Realiza dos acciones principales:
   * 1. Actualiza las opciones de idiomas adicionales, excluyendo el idioma predeterminado.
   * 2. Elimina el idioma predeterminado de la lista de idiomas adicionales, si es necesario.
   *
   * Detalles:
   * - La propiedad `additionalLanguagesOptions` se actualiza para mostrar solo los idiomas
   *   que no sean el idioma predeterminado (`this.site.defaultLanguage`).
   * - Si el idioma predeterminado está en la lista de idiomas adicionales (`this.site.additionalLanguages`),
   *   se elimina para evitar duplicidades.
   */
  onDefaultLanguageChange() {
    //Muestra los lenguages excepto el escogido por defecto
    this.additionalLanguagesOptions = this.languageOptions.filter(
      (language) => language.value !== this.site.defaultLanguage
    );
    //Si en la lista de lenguajes adiccionales esta el por defecto lo elimina
    const defaultLanguage = this.site.defaultLanguage ?? '';
    if (this.site.additionalLanguages?.includes(defaultLanguage)) {
      this.site.additionalLanguages = this.site.additionalLanguages.filter(
        (language) => language !== defaultLanguage
      );
    }
  }
  async onSubmit(form: NgForm): Promise<void> {
    //Valida que el campo name no este vacio ni contenga espacios antes y despues
    this.site.name = this.site.name.trim();
    this.trialpalService.validateSpaces(this.site.name, 'site_name', form);

    //Valida que el campo siteCode no contenga espacios antes y despues
    this.site.siteCode = this.site.siteCode?.trim();
    this.trialpalService.validateSpaces(this.site.siteCode, 'siteCode', form);
    await this.checkUniqueSiteCode();

    if (form.valid) {
      // en caso de no solicitar la informacion de contacto se reestablece la info y deja en false mostrar en el login
      if (!this.site.showContactInfo) {
        this.site.contactInfo = '';
        this.site.showContactInfoLogin = false;
      }
      if (this.editMode) {
        this.editSite();
      } else {
        this.addSite();
      }
    } else {
      this.trialpalService.showInvalidFormError();
      this.trialpalService.hideSpinner();
    }
  }
  editSite(): void {
    //Comprueba si hubo un cambio en los datos del sitio
    if (this.siteChange === JSON.stringify(this.site)) {
      this.ref.close();
      return;
    }
    const ref = this.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'site.actions.updateSite'
        ) +
        ': ' +
        this.site.name,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.site._changeReason = data;
          this.site.name = this.site.name.trim();
          this.trialpalService.showSpinner('site.entity', 'UPDATE');
          this.sitesService
            .updateSite(this.site, this.siteId, this.expectedVersion)
            .then((response) => {
              logger.debug('editSite response', response);
              this.trialpalService.showMutationSuccess('site.entity', 'UPDATE');
              this.ref.close(response);
            })
            .catch((error: any) => {
              logger.error('editSite error', error);
              this.trialpalService.showServiceError('site.entity', error);
            })
            .finally(() => this.trialpalService.hideSpinner());
        }
      },
    });
  }
  addSite(): void {
    this.site.name = this.site.name.trim();
    this.trialpalService.showSpinner('site.entity', 'CREATE');
    logger.debug('createSite input', this.site);
    this.sitesService
      .createSite(this.site)
      .then((response) => {
        logger.debug('createSite response', response);
        this.trialpalService.showMutationSuccess('site.entity', 'CREATE');
        this.ref.close(response);
        this.trialpalService.hideSpinner();
      })
      .catch((error: any) => {
        logger.error('createSite error', error);
        this.trialpalService.showServiceError('site.entity', error);
        this.trialpalService.hideSpinner();
      });
  }
  onCancelar(): void {
    if (this.isModal) {
      this.ref.close(null);
    } else {
      this.centerForm.reset();
      this.router.navigate(['/listSites']);
    }
  }

  /**
   * @desc valida si no esta habilitada la informacion de contacto y restablece la opcion de info en el home
   * @param event
   */
  changePreferenceContactInfo(event: any) {
    if (event && !event.checked) {
      this.site.showContactInfoLogin = false;
    }
  }
  onAddRecipient(input: any, index?: any): void {
    let pattern;
    pattern = new RegExp(emailRegex);

    if (!pattern.test(input)) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.smallInvalidEmail'
        ),
      });
      if (!index && this.site.timezoneRecipients) {
        this.site.timezoneRecipients = this.site.timezoneRecipients.filter(
          (faultRecipient: string | null) => faultRecipient !== input
        );
      }
    }
  }

  async checkUniqueSiteCode(): Promise<void> {
    this.trialpalService.showSpinner('site.entity', 'GET');

    this.site.siteCode = this.site.siteCode?.trim();
    //Valida que el campo siteCode no esté repetido en la lista de sitios del proyecto
    if (this.site.siteCode) {
      const sites = await this.sitesService.fetchSitesByProject(
        this.site.projectId
      );

      const siteCodeRepeated = sites.find(
        (site: any) => site.siteCode === this.site.siteCode
      );
      if (siteCodeRepeated && siteCodeRepeated.id !== this.siteId) {
        this.centerForm.form.controls.siteCode.setErrors({
          siteCodeRepeated: true,
        });
      }
    }
    this.trialpalService.hideSpinner();
  }
  getChipText(item: string): string {
    return item.length <= 20 ? item : item.substring(0, 20) + '...';
  }

  generateHours() {
    const hours = [];
    for (let hour = 0; hour <= 23; hour++) {
      const hourString = (hour < 10 ? '0' : '') + hour + ':00';
      hours.push(hourString);
    }
    return hours;
  }
}

export enum Language {
  ES = 'es',
  EN = 'en',
}
