<form name="form" #f="ngForm" autocomplete="off" (ngSubmit)="onSubmit(f)">
  <p-fieldset
    legend="{{
      'general.configurations.title'
        | translate
          : {
              title:
                'ediary.components.generalConfiguration.labelTemperature'
                | translate
                | lowercase
            }
    }}"
  >
    <small>{{ 'general.configurations.help' | translate }}</small>

    <div class="p-grid p-fluid p-mt-3">
      <div class="p-col-12 p-field">
        <label class="full-width" for="label"
          >{{ 'general.configurations.labelShow' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-input
          id="labelShow"
          name="labelShow"
          [projectId]="projectId"
          [isReadOnly]="readMode"
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="confTemperature.label"
          (outputEvent)="confTemperature.label = $event"
        ></app-simple-dictionary-input>
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="showTemperatureRoute">
          {{ 'general.configurations.enums.route' | translate }}</label
        >
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            pTooltip="{{ 'general.showTemperatureRoute.tooltip' | translate }}"
            id="showTemperatureRoute"
            name="showTemperatureRoute"
            [(ngModel)]="confTemperature.showTemperatureRoute"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="confTemperature.showTemperatureRoute"
      >
        <label class="full-width" for="routeOptions"
          >{{ 'general.configurations.temperatureRoutes' | translate }}
          <span class="inputRequired"> * </span></label
        >

        <p-multiSelect
          appendTo="body"
          name="routeOptions"
          class="input multiSelect"
          id="routeOptions"
          #routeOptions="ngModel"
          defaultLabel="{{ 'general.choose' | translate }}"
          placeholder="{{ 'general.choose' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [options]="routeDisplayOptions"
          [(ngModel)]="confTemperature.routeOptions"
          [filter]="false"
          [showHeader]="false"
          [disabled]="readMode"
          required
        ></p-multiSelect>

        <small
          class="form-error"
          *ngIf="!routeOptions.valid && (f.submitted || routeOptions.touched)"
        >
          {{ 'general.required' | translate }}
        </small>
      </div>
      <div class="p-col-12 p-grid">
        <div class="p-col-6 p-pl-0">
          <label class="full-width" for="dataInputComponent"
            >{{
              'general.configurations.componentTypeForTemperatureRecording'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >

          <p-dropdown
            appendTo="body"
            name="dataInputComponent"
            class="input multiSelect"
            id="dataInputComponent"
            #dataInputComponent="ngModel"
            placeholder="{{ 'general.choose' | translate }}"
            emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
            emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
            [options]="dataInputComponentDisplayOptions"
            [(ngModel)]="confTemperature.dataInputComponent"
            [filter]="false"
            [disabled]="readMode"
            [required]="true"
          ></p-dropdown>

          <small
            class="form-error"
            *ngIf="
              !dataInputComponent.valid &&
              (f.submitted || dataInputComponent.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-6 p-pr-0">
          <label class="full-width" for="help"
            >{{ 'general.configurations.measurementUnit' | translate }}
            <span class="inputRequired"> * </span></label
          >

          <p-multiSelect
            appendTo="body"
            name="units"
            class="input multiSelect"
            id="units"
            #units="ngModel"
            defaultLabel="{{ 'general.choose' | translate }}"
            placeholder="{{ 'general.choose' | translate }}"
            emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
            emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
            [options]="unitsDisplayOptions"
            [(ngModel)]="confTemperature.units"
            [filter]="false"
            [showHeader]="false"
            [disabled]="readMode"
            required
          ></p-multiSelect>

          <small
            class="form-error"
            *ngIf="!units.valid && (f.submitted || units.touched)"
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </div>

      <ng-container *ngIf="getShowFahrenheitRangesMinMax()">
        <div class="p-col-12 p-md-4 p-field">
          <label class="full-width" for="fahrenheitRangesMin"
            >{{
              'general.configurations.enums.MinimumValueFahrenheit' | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #fahrenheitRangesMin="ngModel"
            name="fahrenheitRangesMin"
            [(ngModel)]="confTemperature.fahrenheitRanges!.minValue"
            [showButtons]="true"
            inputId="fahrenheitRangesMin"
            [disabled]="readMode"
            [required]="true"
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(
                confTemperature.fahrenheitRanges!.decimalPlaces
              )
            "
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !fahrenheitRangesMin.valid &&
              (f.submitted || fahrenheitRangesMin.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-4 p-field">
          <label class="full-width" for="fahrenheitRangesMax"
            >{{
              'general.configurations.enums.MaximumValueFahrenheit' | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #fahrenheitRangesMax="ngModel"
            name="fahrenheitRangesMax"
            [(ngModel)]="confTemperature.fahrenheitRanges!.maxValue"
            [showButtons]="true"
            inputId="fahrenheitRangesMax"
            [disabled]="readMode"
            [required]="true"
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(
                confTemperature.fahrenheitRanges!.decimalPlaces
              )
            "
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !fahrenheitRangesMax.valid &&
              (f.submitted || fahrenheitRangesMax.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-4 p-field">
          <label class="full-width" for="fahrenheitDecimalPlaces"
            >{{ 'symptom.labelDecimal' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #fahrenheitDecimalPlaces="ngModel"
            name="fahrenheitDecimalPlaces"
            [(ngModel)]="confTemperature.fahrenheitRanges!.decimalPlaces"
            [showButtons]="true"
            inputId="fahrenheitDecimalPlaces"
            [min]="0"
            [max]="2"
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !fahrenheitDecimalPlaces.valid &&
              (f.submitted || fahrenheitDecimalPlaces.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </ng-container>

      <ng-container *ngIf="getShowCelsiudRangesMinMax()">
        <div class="p-col-12 p-md-4 p-field">
          <label class="full-width" for="celciusRangesRangesMin"
            >{{
              'general.configurations.enums.MinimumValueCelsius' | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #celciusRangesRangesMin="ngModel"
            name="celciusRangesRangesMin"
            [(ngModel)]="confTemperature.celciusRanges!.minValue"
            [showButtons]="true"
            inputId="celciusRangesRangesMin"
            [disabled]="readMode"
            [required]="true"
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(confTemperature.celciusRanges!.decimalPlaces)
            "
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !celciusRangesRangesMin.valid &&
              (f.submitted || celciusRangesRangesMin.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-4 p-field">
          <label class="full-width" for="celciusRangesRangesMax"
            >{{
              'general.configurations.enums.MaximumValueCelsius' | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #celciusRangesRangesMax="ngModel"
            name="celciusRangesRangesMax"
            [(ngModel)]="confTemperature.celciusRanges!.maxValue"
            [showButtons]="true"
            inputId="celciusRangesRangesMax"
            [disabled]="readMode"
            [required]="true"
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(confTemperature.celciusRanges!.decimalPlaces)
            "
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !celciusRangesRangesMax.valid &&
              (f.submitted || celciusRangesRangesMax.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-4 p-field">
          <label class="full-width" for="celsiusDecimalPlaces"
            >{{ 'symptom.labelDecimal' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #celsiusDecimalPlaces="ngModel"
            name="celsiusDecimalPlaces"
            [(ngModel)]="confTemperature.celciusRanges!.decimalPlaces"
            [showButtons]="true"
            inputId="celsiusDecimalPlaces"
            [min]="0"
            [max]="2"
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !celsiusDecimalPlaces.valid &&
              (f.submitted || celsiusDecimalPlaces.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </ng-container>

      <div class="p-col-12 p-field">
        <label class="full-width" for="isExpectedRangeActive">
          {{
            'general.configurations.enums.isExpectedRangeActive' | translate
          }}</label
        >
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            id="isExpectedRangeActive"
            name="isExpectedRangeActive"
            [(ngModel)]="confTemperature.isExpectedRangeActive"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <ng-container
        *ngIf="
          getShowFahrenheitRangesMinMax() &&
          confTemperature.isExpectedRangeActive
        "
      >
        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MinimumExpectedValueFahrenheit"
            >{{
              'general.configurations.enums.MinimumExpectedValueFahrenheit'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MinimumExpectedValueFahrenheit="ngModel"
            name="MinimumExpectedValueFahrenheit"
            [(ngModel)]="confTemperature.fahrenheitRanges!.expectedMinValue"
            [showButtons]="true"
            inputId="MinimumExpectedValueFahrenheit"
            [min]="
              validateNumberValue(confTemperature.fahrenheitRanges!.minValue)
            "
            [max]="
              validateNumberValue(confTemperature.fahrenheitRanges!.maxValue)
            "
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(
                confTemperature.fahrenheitRanges!.decimalPlaces
              )
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MinimumExpectedValueFahrenheit.valid &&
              (f.submitted || MinimumExpectedValueFahrenheit.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MaximumExpectedValueFahrenheit"
            >{{
              'general.configurations.enums.MaximumExpectedValueFahrenheit'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MaximumExpectedValueFahrenheit="ngModel"
            name="MaximumExpectedValueFahrenheit"
            [(ngModel)]="confTemperature.fahrenheitRanges!.expectedMaxValue"
            [showButtons]="true"
            inputId="MaximumExpectedValueFahrenheit"
            [min]="
              validateNumberValue(confTemperature.fahrenheitRanges!.minValue)
            "
            [max]="
              validateNumberValue(confTemperature.fahrenheitRanges!.maxValue)
            "
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(
                confTemperature.fahrenheitRanges!.decimalPlaces
              )
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MaximumExpectedValueFahrenheit.valid &&
              (f.submitted || MaximumExpectedValueFahrenheit.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          getShowCelsiudRangesMinMax() && confTemperature.isExpectedRangeActive
        "
      >
        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="celciusExpectedMinValue"
            >{{
              'general.configurations.enums.MinimumExpectedValueCelsius'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #celciusExpectedMinValue="ngModel"
            name="celciusExpectedMinValue"
            [(ngModel)]="confTemperature.celciusRanges!.expectedMinValue"
            [showButtons]="true"
            inputId="celciusExpectedMinValue"
            [min]="validateNumberValue(confTemperature.celciusRanges!.minValue)"
            [max]="validateNumberValue(confTemperature.celciusRanges!.maxValue)"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(confTemperature.celciusRanges!.decimalPlaces)
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !celciusExpectedMinValue.valid &&
              (f.submitted || celciusExpectedMinValue.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MaximumExpectedValueCelsius"
            >{{
              'general.configurations.enums.MaximumExpectedValueCelsius'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MaximumExpectedValueCelsius="ngModel"
            name="MaximumExpectedValueCelsius"
            [(ngModel)]="confTemperature.celciusRanges!.expectedMaxValue"
            [showButtons]="true"
            inputId="MaximumExpectedValueCelsius"
            [min]="validateNumberValue(confTemperature.celciusRanges!.minValue)"
            [max]="validateNumberValue(confTemperature.celciusRanges!.maxValue)"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(confTemperature.celciusRanges!.decimalPlaces)
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MaximumExpectedValueCelsius.valid &&
              (f.submitted || MaximumExpectedValueCelsius.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </ng-container>

      <div
        class="p-col-12 p-field"
        *ngIf="confTemperature.isExpectedRangeActive"
      >
        <label for="alertTextForUnexpectedValues"
          >{{ 'general.configurations.alertTextForUnexpectedValues' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-editor
          id="alertTextForUnexpectedValues"
          name="alertTextForUnexpectedValues"
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="confTemperature.alertTextForUnexpectedValues"
          (outputEvent)="confTemperature.alertTextForUnexpectedValues = $event"
        >
        </app-simple-dictionary-editor>
      </div>

      <div class="p-col-12 p-field">
        <label class="full-width" for="isSeriousRangeActive">{{
          'general.configurations.isSeriousRangeActive' | translate
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="isSeriousRangeActive"
            #isSeriousRangeActive="ngModel"
            [(ngModel)]="confTemperature.isSeriousRangeActive"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <ng-container
        *ngIf="
          getShowFahrenheitRangesMinMax() &&
          confTemperature.isSeriousRangeActive
        "
      >
        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MinimumSeriousValueFahrenheit"
            >{{
              'general.configurations.enums.MinimumSeriousValueFahrenheit'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MinimumSeriousValueFahrenheit="ngModel"
            name="MinimumSeriousValueFahrenheit"
            [(ngModel)]="confTemperature.fahrenheitRanges!.seriousMinValue"
            [showButtons]="true"
            inputId="MinimumSeriousValueFahrenheit"
            [min]="
              validateNumberValue(confTemperature.fahrenheitRanges!.minValue)
            "
            [max]="
              validateNumberValue(confTemperature.fahrenheitRanges!.maxValue)
            "
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(
                confTemperature.fahrenheitRanges!.decimalPlaces
              )
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MinimumSeriousValueFahrenheit.valid &&
              (f.submitted || MinimumSeriousValueFahrenheit.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MaximumSeriousValueFahrenheit"
            >{{
              'general.configurations.enums.MaximumSeriousValueFahrenheit'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MaximumSeriousValueFahrenheit="ngModel"
            name="MaximumSeriousValueFahrenheit"
            [(ngModel)]="confTemperature.fahrenheitRanges!.seriousMaxValue"
            [showButtons]="true"
            inputId="MaximumSeriousValueFahrenheit"
            [min]="
              validateNumberValue(confTemperature.fahrenheitRanges!.minValue)
            "
            [max]="
              validateNumberValue(confTemperature.fahrenheitRanges!.maxValue)
            "
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(
                confTemperature.fahrenheitRanges!.decimalPlaces
              )
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MaximumSeriousValueFahrenheit.valid &&
              (f.submitted || MaximumSeriousValueFahrenheit.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          getShowCelsiudRangesMinMax() && confTemperature.isSeriousRangeActive
        "
      >
        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MinimumSeriousValueCelsius"
            >{{
              'general.configurations.enums.MinimumSeriousValueCelsius'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MinimumSeriousValueCelsius="ngModel"
            name="MinimumSeriousValueCelsius"
            [(ngModel)]="confTemperature.celciusRanges!.seriousMinValue"
            [showButtons]="true"
            inputId="MinimumSeriousValueCelsius"
            [min]="validateNumberValue(confTemperature.celciusRanges!.minValue)"
            [max]="validateNumberValue(confTemperature.celciusRanges!.maxValue)"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(confTemperature.celciusRanges!.decimalPlaces)
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MinimumSeriousValueCelsius.valid &&
              (f.submitted || MinimumSeriousValueCelsius.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="MaximumSeriousValueCelsius"
            >{{
              'general.configurations.enums.MaximumSeriousValueCelsius'
                | translate
            }}
            <span class="inputRequired"> * </span></label
          >
          <p-inputNumber
            #MaximumSeriousValueCelsius="ngModel"
            name="MaximumSeriousValueCelsius"
            [(ngModel)]="confTemperature.celciusRanges!.seriousMaxValue"
            [showButtons]="true"
            inputId="MaximumSeriousValueCelsius"
            [min]="validateNumberValue(confTemperature.celciusRanges!.minValue)"
            [max]="validateNumberValue(confTemperature.celciusRanges!.maxValue)"
            [minFractionDigits]="0"
            [maxFractionDigits]="
              validateNumberValue(confTemperature.celciusRanges!.decimalPlaces)
            "
            [disabled]="readMode"
            [required]="true"
          ></p-inputNumber>

          <small
            class="form-error"
            *ngIf="
              !MaximumSeriousValueCelsius.valid &&
              (f.submitted || MaximumSeriousValueCelsius.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>
      </ng-container>

      <div
        class="p-col-12 p-field"
        *ngIf="confTemperature.isSeriousRangeActive"
      >
        <label for="alertTextForSeriousValues"
          >{{ 'general.configurations.alertTextForSeriousValues' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-editor
          id="alertTextForSeriousValues"
          name="alertTextForSeriousValues"
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="confTemperature.alertTextForSeriousValues"
          (outputEvent)="confTemperature.alertTextForSeriousValues = $event"
        >
        </app-simple-dictionary-editor>
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="showTemperatureTaken">
          {{ 'general.configurations.temperatureTake' | translate }}</label
        >
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            id="showTemperatureTaken"
            name="showTemperatureTaken"
            [(ngModel)]="confTemperature.showTemperatureTaken"
            [disabled]="readMode"
            (onChange)="changesByShowTemperatureTaken()"
          ></p-inputSwitch>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="confTemperature.showTemperatureTaken"
      >
        <label class="full-width" for="allowExitToTakeTemperature">{{
          'general.configurations.allowExitToTakeTemperature' | translate
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="allowExitToTakeTemperature"
            #allowExitToTakeTemperature="ngModel"
            [(ngModel)]="confTemperature.allowExitToTakeTemperature"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div
        class="p-col-12 p-field"
        *ngIf="confTemperature.allowExitToTakeTemperature"
      >
        <label class="full-width" for="help">
          {{
            'general.configurations.alertExitToTakeTemperatureText' | translate
          }}<span class="inputRequired"> * </span>
        </label>

        <div>
          <app-simple-dictionary-editor
            id="alertExitToTakeTemperatureText"
            name="alertExitToTakeTemperatureText"
            [isRequired]="true"
            [submitted]="f.submitted"
            [value]="confTemperature.alertExitToTakeTemperatureText"
            (outputEvent)="
              confTemperature.alertExitToTakeTemperatureText = $event
            "
          >
          </app-simple-dictionary-editor>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="showTemperatureTakenDate">
          {{
            'general.configurations.require'
              | translate
                : {
                    entityName:
                      'general.configurations.dateTime' | translate | lowercase
                  }
          }}</label
        >

        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            id="showTemperatureTakenDate"
            name="showTemperatureTakenDate"
            [(ngModel)]="confTemperature.showTemperatureTakenDate"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="help">{{
          'ediary.components.generalConfiguration.labelShowHelp' | translate
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="showHelp"
            #showHelp="ngModel"
            [(ngModel)]="confTemperature.showHelpScreen"
            pTooltip="{{ 'report.labelShowHelpTooltip' | translate }}"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>

      <ng-container *ngIf="confTemperature.showHelpScreen">
        <div class="p-col-12 p-field">
          <label for="helpScreenText"
            >{{ 'ediary.components.formAddEdit.labelHelp' | translate
            }}<span class="inputRequired"> * </span></label
          >
          <app-simple-dictionary-editor
            id="helpScreenText"
            name="helpScreenText"
            [isRequired]="true"
            [submitted]="f.submitted"
            [value]="confTemperature.helpScreenText"
            (outputEvent)="confTemperature.helpScreenText = $event"
          >
          </app-simple-dictionary-editor>
        </div>

        <div class="p-col-12 p-md-6 p-field">
          <label class="full-width" for="help"
            >{{
              'general.configurations.helpScreenViewPreference' | translate
            }}
            <span class="inputRequired"> * </span></label
          >

          <p-multiSelect
            appendTo="body"
            name="helpScreenViewPreference"
            class="input multiSelect"
            id="helpScreenViewPreference"
            #helpScreenViewPreference="ngModel"
            defaultLabel="{{ 'general.choose' | translate }}"
            placeholder="{{ 'general.choose' | translate }}"
            emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
            emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
            [options]="helpScreenDisplayOptions"
            [(ngModel)]="confTemperature.helpScreenDisplayOptions"
            [filter]="false"
            [showHeader]="false"
            [disabled]="readMode"
            required
          ></p-multiSelect>

          <small
            class="form-error"
            *ngIf="
              !helpScreenViewPreference.valid &&
              (f.submitted || helpScreenViewPreference.touched)
            "
          >
            {{ 'general.required' | translate }}
          </small>
        </div>

        <div
          class="p-col-12 p-md-6 p-field"
          *ngIf="getShowButtonHelpScreenLabel()"
        >
          <label class="full-width" for="buttonHelpScreenLabel">
            {{ 'general.configurations.labelToShowInHelpScreen' | translate }}
            <span class="inputRequired"> * </span>
          </label>

          <div>
            <app-simple-dictionary-input
              id="buttonHelpScreenLabel"
              name="buttonHelpScreenLabel"
              [projectId]="projectId"
              [isReadOnly]="readMode"
              [isRequired]="true"
              [submitted]="f.submitted"
              [value]="confTemperature.buttonHelpScreenLabel"
              (outputEvent)="confTemperature.buttonHelpScreenLabel = $event"
            ></app-simple-dictionary-input>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      class="p-mt-3"
      style="display: flex; justify-content: end"
      *ngIf="!readMode"
    >
      <div class="p-grid p-field">
        <button
          id="btn_cancelConfiguration"
          name="btn_cancelConfiguration"
          class="p-button-secondary p-mr-3"
          pButton
          type="button"
          label="{{ 'general.cancel' | translate }}"
          (click)="onCancel()"
          [disabled]="readMode"
        ></button>

        <button
          id="btn_saveconfiguration"
          name="btn_saveconfiguration"
          pButton
          type="submit"
          label="{{ 'general.save' | translate }}"
          [disabled]="readMode"
        ></button>
      </div>
    </div>
  </p-fieldset>
</form>
