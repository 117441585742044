<div class="p-d-flex p-jc-between p-ai-center">
  <h1>
    {{ 'dictionary.listTranslations' | translate }}
  </h1>
  <div class="p-d-flex" style="gap: 0.5rem">
    <button
      pButton
      class="p-button-secondary"
      type="button"
      (click)="addEditDictionary(undefined, false, false)"
      [disabled]="newItem"
      *ngIf="!isEditorForm || isGlobal"
    >
      {{ 'general.add' | translate }}
    </button>

    <button
      pButton
      class="p-button-secondary"
      type="button"
      (click)="addEditDictionary(undefined, false, true)"
      [disabled]="newItem"
      *ngIf="isEditorForm || isGlobal"
    >
      {{ 'general.addContent' | translate }}
    </button>
  </div>
</div>
<p-table
  [value]="dictionaries"
  scrollHeight="60vh"
  responsiveLayout="scroll"
  [scrollable]="true"
  [rows]="300"
  [virtualRowHeight]="34"
  *ngIf="dictionaries.length > 0"
  [globalFilterFields]="['key', 'english', 'spanish']"
  #dictionaryTable
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between p-ai-center">
      <button
        pButton
        [label]="'primeng.clear' | translate"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clearTable()"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <span class="pi pi-search"></span>
        <input
          pInputText
          type="text"
          #searchInput
          (input)="dictionaryTable.filterGlobal(searchInput.value, 'contains')"
          [placeholder]="
            'informedConsent.followUp.table.searchLabel' | translate
          "
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="key" pSortableColumn="key">
        <p-columnFilter type="text" field="key" display="menu"></p-columnFilter>
        {{ 'dictionary.key' | translate }}
        <p-sortIcon field="key"></p-sortIcon>
      </th>

      <th id="spanish" pSortableColumn="spanish">
        <p-columnFilter
          type="text"
          field="spanish"
          display="menu"
        ></p-columnFilter>
        {{ 'dictionary.spanish' | translate }}
        <p-sortIcon field="spanish"></p-sortIcon>
      </th>

      <th id="english" pSortableColumn="english">
        <p-columnFilter
          type="text"
          field="english"
          display="menu"
        ></p-columnFilter>
        {{ 'dictionary.english' | translate }}
        <p-sortIcon field="english"></p-sortIcon>
      </th>
      <th id="accion" style="display: flex; justify-content: center">
        {{ 'dictionary.action' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-dictionary>
    <tr>
      <td>{{ dictionary.key }}</td>
      <td><span [innerHTML]="dictionary.spanish"></span></td>
      <td><span [innerHTML]="dictionary.english"></span></td>
      <td style="justify-content: center">
        <button
          id="btn_edit"
          name="btn_edit"
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          (click)="addEditDictionary(dictionary, true)"
          title="{{ 'user.buttonEdit' | translate }}"
        ></button>
        <button
          id="btn_select"
          name="btn_select"
          *ngIf="isForm"
          pButton
          pRipple
          type="button"
          icon="pi pi-check"
          class="p-button-rounded p-button-text p-button-success"
          (click)="onSelectKeySetForm(dictionary)"
          title="{{ 'dictionary.useTranslate' | translate }}"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<h3 *ngIf="dictionaries.length === 0" style="width: 100%; text-align: center">
  {{ 'general.emptyFilterMessage' | translate }}
</h3>
