import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BnNgIdleService } from 'bn-ng-idle';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { ChatModule } from './modules/chat/chat.module';
import { ConciliationModule } from './modules/conciliation/conciliation.module';
import { ConfigurationsModule } from './modules/configurations/configurations.module';
import { EdiaryModule } from './modules/ediary/ediary.module';
import { InformedConsentModule } from './modules/informed-consent/informed-consent.module';
import { ProjectModule } from './modules/project/project.module';
import { ReportModule } from './modules/report/report.module';
import { RolesModule } from './modules/roles/roles.module';
import { SitesModule } from './modules/sites/sites.module';
import { SubjectsModule } from './modules/subjects/subjects.module';
import { UserModule } from './modules/user/user.module';
import { VersionModule } from './modules/version/version.module';
import { NavBarComponent } from './navbar/navbar.component';
import { PrivacyAndTermsModule } from './privacy-and-terms/privacy-and-terms.module';
import { AppConfigService } from './services/appconfigservice';
import { AuthService } from './services/auth.service';
import { UserPermissionsService } from './services/user-permissions-service';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { PrimengModule } from './shared/primeng.module';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent, NavBarComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PrimengModule,
    GalleriaModule,
    FormsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SharedComponentsModule,
    UserModule,
    RolesModule,
    ReportModule,
    ProjectModule,
    InformedConsentModule,
    EdiaryModule,
    SitesModule,
    SubjectsModule,
    ChatModule,
    ConfigurationsModule,
    ConciliationModule,
    PrivacyAndTermsModule,
    VersionModule,
  ],
  providers: [
    AuthService,
    BnNgIdleService,
    MessageService,
    DialogService,
    ConfirmationService,
    AppConfigService,
    UserPermissionsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
