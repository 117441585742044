<main>
  <section>
    <div #htmlContent></div>
  </section>
  <footer class="p-d-flex p-mt-3 p-jc-end">
    <p-button
      [label]="'general.continue' | translate"
      styleClass="p-button-primary"
      (click)="close()"
    ></p-button>
  </footer>
</main>
