<p class="data-title">{{ label }}:</p>
<div
  *ngIf="!items.length && loading"
  class="p-d-flex p-flex-column"
  style="gap: 8px"
>
  <p-skeleton
    width="5rem"
    height="1rem"
    *ngFor="let item of [1, 2, 3, 4, 5, 6]"
  ></p-skeleton>
</div>
<div class="grid-chips">
  <span
    class="chips"
    *ngFor="let item of items | slice: 0:cantItems"
    [pTooltip]="item"
    >{{ item }}</span
  >
</div>
<button *ngIf="items.length > 5" class="btn-more" (click)="show()">
  {{
    (items.length === cantItems ? 'general.btnSeeLess' : 'general.btnSeeMore')
      | translate
  }}
</button>
