import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-checkbox',
  templateUrl: './formly-checkbox.component.html',
})
export class FormlyCheckboxComponent extends FieldType<FieldTypeConfig> {
  date: number = new Date().getTime();
}
