<div
  class="card"
  (click)="tp2CardEmmiter.emit()"
  [ngStyle]="{
    borderColor: tp2Card.borderColor,
    backgroundColor: tp2Card.cardBackground,
  }"
>
  <div class="card-header p-4 p-mb-4">
    <h4 class="card-title p-d-flex p-ai-center" [pTooltip]="tp2Card.textHeader">
      <div *ngIf="tp2Card.icon">
        <img
          [src]="'/../assets/icons/' + tp2Card.icon + '.png'"
          alt="icon"
          class="card-icon"
        />
      </div>
      {{ tp2Card.textHeader }}
      <div class="card-title-icon p-ml-3" *ngIf="tp2Card.iconHeader">
        <span [class]="tp2Card.iconHeader"></span>
      </div>
    </h4>
    <div class="options" (click)="$event.stopPropagation()" id="menu_options">
      <div class="options" *ngIf="tp2Card.isButtonsEnabled">
        <div *ngFor="let item of tp2Card.buttonItems">
          <button
            id="btn_action"
            name="btn_action"
            [ngStyle]="{ color: item.color }"
            pButton
            type="button"
            [pTooltip]="item.tooltip"
            [icon]="item.icon"
            class="card-btn"
            [disabled]="item.disabled"
            (click)="$event.stopPropagation(); item.command()"
          ></button>
        </div>
      </div>
      <div class="options" *ngIf="tp2Card.isMenuEnabled">
        <p-menu #menu [popup]="true" [model]="tp2Card.menu"></p-menu>
        <button
          style="color: #2b5a6b"
          type="button"
          pButton
          icon="pi pi-ellipsis-v"
          class="card-btn"
          (click)="$event.stopPropagation(); menu.toggle($event)"
        ></button>
      </div>
    </div>
  </div>
  <div class="p-4 p-pt-0">
    <ng-content></ng-content>
    <div
      class="card-body"
      id="content_container"
      [ngStyle]="{
        'grid-template-columns': 'repeat(' + tp2Card.content.length + ', auto)'
      }"
    >
      <div
        class="content overflow-hidden"
        *ngFor="let content of tp2Card.content; let i = index"
      >
        <div class="content-icon">
          <span [class]="content.icon"></span>
        </div>
        <div class="overflow-hidden">
          <span class="content-title">{{ content.title }}</span>
          <span
            class="content-description overflow-hidden"
            [pTooltip]="content.description"
            >{{ content.description }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer" *ngIf="tp2Card.isFooterEnabled">
    <span class="card-footer-info">{{ tp2Card.footerContent }}</span>
  </div>
</div>
