import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-back-button-header',
  templateUrl: './back-button-header.component.html',
  styleUrls: ['./back-button-header.component.scss'],
})
export class BackButtonHeaderComponent {
  constructor(private router: Router, private location: Location) {}

  @Input() pageTitle: string = '';
  @Input() pageUrl: string = '';
  @Input() pageDescription: string = '';
  @Input() secondaryPageDescription: string = '';

  //Botón
  @Input() showButton: boolean = false;
  @Input() textButton: string = '';
  @Input() isButtonDisabled: boolean = false;

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  goBack() {
    if (this.pageUrl === 'back') {
      return this.location.back();
    }
    this.router.navigateByUrl(this.pageUrl);
  }
}
