import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TemperatureUnit } from 'src/app/modules/ediary/ediary.types';
import { AlertRuleType } from 'src/app/modules/report/report.types';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { TP2Entites } from 'src/app/shared/global.variables';
import { SubjectsService } from '../../subjects.service';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';
const logger = new Logger('tp2-logger-subjectAlertPage');
@Component({
  selector: 'app-alert-subject',
  templateUrl: './alert-subject.component.html',
  styleUrls: ['./alert-subject.component.scss'],
})
export class AlertSubjectComponent implements OnInit, OnChanges {
  @Input() timeZoneOffset = 0;
  @Input() subjectIdParam = null;
  @Input() isOpened: boolean = false; //True cuando se ingreso a la alerta
  listAlert: any[] = [];
  alertConditionTypes: any[] = [];
  display = false;
  alertSelected: any = {
    confAlertId: '',
    confAlert: {
      confReportId: '',
      confEdiaryId: '',
      confSymptomId: '',
      confTemperatureId: '',
      id: '',
      alertName: '',
      recipients: '',
      type: '',
      mailSubject: '',
      mailBody: '',
      SMSBody: '',
      alertRules: {
        ruleId: '',
        alertRuleType: '',
        expectedValue: '',
      },
    },
    id: '',
    confAlert_data: '',
    entityId: '',
    entityName: '',
    subjectId: '',
    state: '',
    createdAt: '',
  };
  createdAt = '';
  translateEnum: any;
  messageBody = '';
  alertRules = [
    {
      ruleId: '',
      alertRuleTypeName: '',
      alertRuleType: '',
      expectedValue: '',
      symptom: '',
      nameSymptomConfig: '',
      intensity: '',
    },
  ];
  state = '';
  alertName = '';
  type = '';
  recipients = '';
  mailSubject = '';
  subjectId: any = null;
  tableViewMode: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  dataCard: any[] = [];
  showSpinner: boolean = true;
  revisionState: string = '';
  subject: any = {};
  DETAIL_SUBJECT_ALERT_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_ALERT_TOUR;

  constructor(
    public trialpalService: TrialpalService,
    private subjectsService: SubjectsService,
    private transformDatePipe: TransformDatePipe,
    private route: ActivatedRoute,
    private pageTourService: PageTourService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.showSpinner = true;
      this.subjectId =
        this.route.snapshot.params.subjectId || this.subjectIdParam;
      this.trialpalService.translateService
        .get('ediary.enums')
        .subscribe((res) => (this.translateEnum = res));
      this.listAlert = await this.getAlertInstancesBySubjectId(this.subjectId);

      await this.buildCardsAndTable();
      this.showSpinner = false;
      this.startTour();
      this.subject = await this.subjectsService.getSubject(this.subjectId);
    } catch (exception) {
      await this.buildCardsAndTable();
      this.showSpinner = false;
    }
  }

  ngOnChanges() {
    this.startTour();
  }

  async buildCardsAndTable() {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };
    if (this.listAlert?.length > 0) {
      this.dataTable = await this.buildTPTableData(this.listAlert);
      this.buildCards(this.listAlert);
    }
  }

  buildCards(alerts: any) {
    this.dataCard = [];
    for (let alert of alerts) {
      this.dataCard.push(this.buildTPCardData(alert));
    }
  }

  async getAlertInstancesBySubjectId(subjectId: string) {
    let alertInstances = await this.subjectsService.getAlertInstanceBySubjectId(
      subjectId
    );
    logger.debug('listAlertInstances response', alertInstances);
    alertInstances = alertInstances.filter(
      (alertInstance: any) => !alertInstance._deleted
    );
    alertInstances =
      this.subjectsService.orderInstancesDescending(alertInstances);
    return alertInstances;
  }

  async findAlertInstance(event: any): Promise<void> {
    const alertInstance = this.listAlert.find(
      (alert: any) => alert.id === event.id
    );
    await this.openDetail(alertInstance);
  }

  async getCurrentSymptomNameByAlertRuleType(alertRule: any, confAlert: any) {
    switch (alertRule.alertRuleType) {
      case AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN:
        return this.trialpalService.translateService.instant(
          'temperaturerecordlog.temperature'
        );
      case AlertRuleType.MEDICAL_ATTENTION_WITH_HOSPITALIZATION:
        return this.trialpalService.translateService.instant(
          'medicalattentioninstance.enums.HOSPITALIZATION'
        );
      case AlertRuleType.JSONFORM_ANSWER:
        return this.trialpalService.translateService.instant(
          'ediary.enums.alertConditionTypes.JSONFORM_ANSWER'
        );
      case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN:
      case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS:
      case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN:
        return this.getSymptomsNameBySymptomsOcurrency(alertRule, confAlert);

      default:
        return '';
    }
  }

  async getSymptomsNameBySymptomsOcurrency(alertRule: any, confAlert: any) {
    let instance: any;

    // Obtener la instancia basada en entityName
    if (confAlert.entityName === 'DAY_INSTANCE') {
      instance = await this.subjectsService.getDayInstance(confAlert.entityId);
    } else if (confAlert.entityName === 'REPORT_INSTANCE') {
      instance = await this.subjectsService.getReportInstance(
        confAlert.entityId
      );
    }

    // Procesar la instancia si está disponible
    if (instance) {
      const symptomOcurrencies = instance.symptomOcurrencies;
      const ids = alertRule.ruleId.split(',');
      const idsWithOccurrencyTrue: any[] = [];

      // Filtrar IDs con occurrency true
      ids.forEach((id: any) => {
        const foundObject = symptomOcurrencies?.find(
          (object: any) => object.confSymptomId === id
        );
        if (foundObject && foundObject.occurrency) {
          idsWithOccurrencyTrue.push(id);
        }
      });

      // Obtener nombres de síntomas
      return this.getConfSymptomName(idsWithOccurrencyTrue.join(','));
    }

    return '';
  }

  async getSymptomOcurrencyAlert(confAlert: any) {
    let symptoms: any[] = [];
    const confAlert_data = confAlert.confAlert_data;
    let confAlertData = JSON.parse(confAlert_data);

    for (let alertRule of confAlertData.alertRules) {
      let currentSymptomName = await this.getCurrentSymptomNameByAlertRuleType(
        alertRule,
        confAlert
      );
      if (!currentSymptomName && alertRule.ruleId) {
        currentSymptomName = await this.getConfSymptomName(alertRule.ruleId);
      }
      if (!currentSymptomName) {
        const expectedValue = alertRule.expectedValue
          ? alertRule.expectedValue.split('.')
          : [];
        currentSymptomName =
          expectedValue.length > 0
            ? this.trialpalService.translateService.instant(
                'symptom.enums.symptoms.' + expectedValue[0]
              )
            : '';
      }
      symptoms.push(currentSymptomName);
    }
    return symptoms
      .filter((item, index) => {
        return symptoms.indexOf(item) === index;
      })
      .toString();
  }

  async getConfSymptomName(ruleId: any) {
    let confSymptomNames = [];

    const ruleIds = ruleId.split(',');

    for (const id of ruleIds) {
      const confSymptomName = await this.getSingleConfSymptomName(id.trim());
      if (confSymptomName) {
        confSymptomNames.push(confSymptomName);
      }
    }

    return confSymptomNames.join(', '); // Devolver los nombres de síntomas como una cadena separada por comas
  }

  async getSingleConfSymptomName(ruleId: any) {
    let confSymptomName = '';
    const confSymptom = await this.subjectsService.getConfSymptom(ruleId);
    if (confSymptom) {
      confSymptomName = this.trialpalService.dictionaryPipe.transform(
        confSymptom.symptomLabel
      );
    }
    if (!confSymptomName && confSymptom) {
      confSymptomName = this.trialpalService.translateService.instant(
        'symptom.enums.symptoms.' + confSymptom.symptom
      );
    }
    return confSymptomName;
  }

  async buildTPTableData(alertInstances: any): Promise<TPTableData> {
    let newAlertInstance = await Promise.all(
      alertInstances.map(async (alertInstance: any) => {
        const confAlert_data = JSON.parse(alertInstance.confAlert_data);
        return {
          id: alertInstance.id,
          entity: TP2Entites.ALERTINSTANCE,
          alertName: confAlert_data.alertName,
          createdAt: new Date(alertInstance.createdAt),
          entityName: this.getEntityName(alertInstance.entityName),
          state: this.trialpalService.translateService.instant(
            'ediary.components.alertAddEdit.enums.' + alertInstance.state
          ),
          confAlert_data: await this.getSymptomOcurrencyAlert(alertInstance),
        };
      })
    );
    return {
      valueTable: newAlertInstance,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: ['alertName', 'createdAt', 'entityName', 'state'],
      customButton1: null,
      cols: [
        {
          header: this.trialpalService.translateService.instant(
            'ediary.components.alertAddEdit.labelAlertName'
          ),
          type: TypeInput.TEXT,
          field: 'alertName',
          showBasicSearch: true,
          showFilterComplete: false,
        },

        {
          header: this.trialpalService.translateService.instant(
            'subject.tabheaderSymptom'
          ),
          type: TypeInput.TEXT,
          field: 'confAlert_data',
          showBasicSearch: true,
          showFilterComplete: false,
        },

        {
          header: this.trialpalService.translateService.instant(
            'ediary.components.alertAddEdit.labelDateAndTime'
          ),
          type: TypeInput.DATE,
          field: 'createdAt',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'ediary.components.alertAddEdit.labelOrigin'
          ),
          type: TypeInput.TEXT,
          field: 'entityName',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'addEditSubjectComponent.labelShippingStatus'
          ),
          type: TypeInput.TEXT,
          field: 'state',
          showBasicSearch: true,
          showFilterComplete: false,
        },
      ],
    };
  }

  buildTPCardData(res: any): TPCardData {
    const data = JSON.parse(res.confAlert_data);
    return {
      ...res,
      data: res,
      header: data.alertName,
      section1: this.transformDatePipe.transformToSiteHour(
        res.createdAt,
        this.timeZoneOffset
      ),
      icon1: 'pi pi-clock',
      section2:
        res.entityName === 'DAY_INSTANCE'
          ? this.trialpalService.translateService.instant(
              'ediary.enums.originAlert.AlertInstance'
            )
          : this.trialpalService.translateService.instant(
              'ediary.enums.originAlert.ReportInstance'
            ),
      icon2: 'pi pi-calendar',
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      hideState: true,
      cardIsButton: true,
    };
  }

  async openDetail(alertInstance: any) {
    this.trialpalService.showSpinner('subject.spinnerLoadtData');
    alertInstance.confAlert.alertRule =
      this.translateEnum.alertConditionTypes[alertInstance.confAlert.alertRule];

    const confAlert = await this.subjectsService.getConfAlert(
      alertInstance.confAlert.id
    );
    const siteRecipients =
      (confAlert?.siteRecipients?.find(
        (siteRecipient) => siteRecipient.siteId === this.subject.siteId
      ) as any) ?? [];
    this.alertSelected = alertInstance;
    const data = JSON.parse(alertInstance.confAlert_data);
    this.createdAt = this.transformDatePipe.transformToSiteHour(
      alertInstance.createdAt,
      this.timeZoneOffset
    );
    this.messageBody = this.removeHtmlTags(data.body.html, data.body.smsText);
    this.alertRules = await this.addalertConditionTypes(data?.alertRules);
    this.alertName = data.alertName;
    this.type = data.type;
    this.recipients =
      ([
        ...new Set<string>(data.body.to.concat(siteRecipients.recipients)),
      ] as any) || data.body.smsNumber;
    this.recipients = this.recipients.toString().split(',').join('\n');
    this.mailSubject = data.mailSubject;
    this.state = this.trialpalService.translateService.instant(
      'ediary.components.alertAddEdit.enums.' + alertInstance.state
    );
    this.revisionState = this.trialpalService.translateService.instant(
      'ediary.components.alertAddEdit.enums.' +
        (alertInstance.revisionState ?? 'PENDING')
    );
    this.trialpalService.hideSpinner();
    this.display = true;
    this.trialpalService.hideSpinner();
  }

  removeHtmlTags(html: string, sms: string): any {
    if (html) {
      return html.replace(/<[^>]*>?/g, '');
    }
    return sms.replace(/<[^>]*>?/g, '');
  }

  async addalertConditionTypes(data: any) {
    const alerts = [];
    for await (const alert of data) {
      alert.alertRuleTypeName = this.getAlertRuleTypeNameTranslation(
        alert.alertRuleType
      );
      if (alert.ruleId) {
        //Verifica si es una alerta de otros sintomas
        if (
          alert.ruleId === 'OTHER_SYMPTOMS' &&
          alert.expectedValue.length === 0
        ) {
          //Si el expectedValue vienen en blanco es una alerta de ocurrencia
          alert.expectedValue = 'OTHER_SYMPTOMS';
        } else {
          alert.nameSymptomConfig = await this.getSymtomConfigByRuleId(
            alert.ruleId
          );
        }
      }

      alert.symptom = this.trialpalService.translateService.instant(
        'symptom.enums.symptoms.' + alert.expectedValue.split('.')[0]
      );

      alert.intensity = this.trialpalService.translateService.instant(
        'symptom.enums.intensityTypes.' + alert.expectedValue.split('.')[1]
      );
      alerts.push(alert);
    }
    return alerts;
  }

  getAlertRuleTypeNameTranslation(alertRuleType: AlertRuleType) {
    return this.trialpalService.translateService.instant(
      `ediary.enums.alertConditionTypes.${alertRuleType}`
    );
  }

  /**
   * @desc consultar la configuracion del sintoma de la alerta
   * @param ruleId Id del sintoma que es el ruleId
   */
  async getSymtomConfigByRuleId(ruleId: string): Promise<string> {
    const ids = ruleId.split(',');
    let nameSymptom: any[] = [];
    for (const id of ids) {
      const confSymptom = await this.subjectsService.getConfSymptom(id);

      if (confSymptom) {
        confSymptom.symptomLabel &&
          nameSymptom.push(
            this.trialpalService.dictionaryPipe.transform(
              confSymptom.symptomLabel
            )
          );
      }
      logger.debug('confSymptom', confSymptom);
    }
    return nameSymptom.join(', ');
  }

  /**
   * @param data enum sintoma y valor
   * @param nameConfSymptom nmombre del sintoma establecido en la configuracion del sintoma
   * @returns nombre del sintoma configurado o el sintoma y el valor respectivo
   */
  getSymptomOrNameSymptom(data: string, nameConfSymptom?: string) {
    let symptom: string = '';
    let value: string = '';
    if (data) {
      symptom = this.trialpalService.translateService.instant(
        'symptom.enums.symptoms.' + data.split('.')[0]
      );
      value = data.split('.')[1];
    }
    return (nameConfSymptom ?? symptom) + '\n' + value;
  }

  /**
   * @des asiganr el valor en C o F segun la enumeración
   * @param data enumeración de temperatura y valor
   */
  getEnumScaleTemperature(data: string) {
    let unit: string = '';
    let value: string = '';
    if (data) {
      const temp = data.split('.');
      let unitTemperature;
      if (temp.length === 2) {
        value = temp[0];
        unitTemperature = temp[1];
      } else if (temp.length === 3) {
        value = temp[0] + '.' + temp[1];
        unitTemperature = temp[2];
      }
      unit = unitTemperature === TemperatureUnit.CELSIUS ? '°C ' : '°F';
    }
    return value + ' ' + unit;
  }

  getEntityName(entityName: any) {
    const translationKey =
      entityName === 'DAY_INSTANCE'
        ? 'ediary.enums.originAlert.AlertInstance'
        : 'ediary.enums.originAlert.ReportInstance';

    return this.trialpalService.translateService.instant(translationKey);
  }

  startTour() {
    if (!this.isOpened || this.showSpinner) return;
    this.pageTourService.initiateTour(
      PageTourDataType.DETAIL_SUBJECT_ALERT_TOUR
    );
  }
}
