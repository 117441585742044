<div class="p-d-flex p-flex-column" style="gap: 0.5rem">
  <div *ngFor="let option of to.options | formlySelectOptions: field | async">
    <div class="p-d-flex p-ac-center" style="gap: 1rem">
      <p-checkbox
        [formControl]="formControl"
        [formlyAttributes]="field"
        [value]="option.value"
        [inputId]="
          option.label + option.value + field.key + '_' + uniqueId ?? ''
        "
        [name]="field.key + '[]'"
        [required]="to.required ?? false"
        [disabled]="to.disabled ?? false"
      >
      </p-checkbox>

      <label
        [for]="option.label + option.value + field.key + '_' + uniqueId"
        style="font-size: var(--text-primary-size)"
        >{{ option.label | dictionary }}</label
      >
    </div>
  </div>
</div>
