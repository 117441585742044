<div class="p-d-flex p-flex-column" style="gap: 0.5rem">
  <div>
    <div class="p-d-flex p-ac-center" style="gap: 1rem">
      <p-checkbox
        inputId="{{ field?.key + '_' + date ?? '' }}"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [disabled]="to.disabled ?? false"
        [binary]="true"
        [required]="to.required ?? false"
      ></p-checkbox>

      <label
        style="font-weight: 700; font-size: 1rem; word-break: break-word"
        class="p-d-block"
        [for]="field?.key + '_' + date"
      >
        {{ to?.label ?? '' | dictionary }}
        <span *ngIf="to.required" style="color: red">*</span>
        <span
          *ngIf="to['tooltip']?.trim()?.length > 0"
          [pTooltip]="to['tooltip'] | dictionary"
          class="pi pi-question-circle p-ml-2 cursor-pointer"
          style="color: var(--primary-color)"
        ></span>
      </label>
    </div>
  </div>
</div>
