<main
  class="new-person-container"
  [class.required_users_completed]="consentedUserMissings === 0"
>
  <div class="p-d-flex p-ai-center alert">
    <span class="pi pi-exclamation-triangle exclamation-icon"></span>
    <p class="add-person-text p-ml-2">
      {{
        'informedConsent.followUp.table.rowDetail.addPersonDescription'
          | translate: { person: consentedUserMissings }
      }}
    </p>
  </div>
  <div>
    <button
      pButton
      pRipple
      label="{{
        'informedConsent.followUp.table.rowDetail.addPersonButton' | translate
      }}"
      type="button"
      class="p-button-secondary"
      (click)="onAddUser()"
    ></button>
  </div>
  <p-dialog
    [(visible)]="showDialog"
    *ngIf="showDialog"
    [style]="{ width: '90%' }"
    [modal]="true"
    styleClass="p-fluid"
  >
    <header class="title">
      <h2>{{ 'user.addUser' | translate }}</h2>
    </header>

    <ng-template pTemplate="content">
      <app-informed-consent-follow-up-user-form
        [fromAddPendingPerson]="true"
        [informedConsent]="informedConsent"
        [availableUsers]="avaiableUsers"
        [assignedKinships]="assignedUsersKinships"
        [projectAndSite]="projectAndSite"
        (closeAddPendingPerson)="onCloseModal($event)"
      ></app-informed-consent-follow-up-user-form>
    </ng-template>
  </p-dialog>
</main>
