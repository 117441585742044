import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { CardOrTableService } from 'src/app/services/card-or-table.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';
import { ChangeReasonComponent } from '../../../shared/components/change-reason/change-reason.component';
import { ProjectService } from '../../project/project.service';
import { SubjectsService } from '../subjects.service';
import { SubjectState } from '../subjects.types';

const logger = new Logger('tp2-logger-subjectsListPage');

@Component({
  selector: 'app-list-subjects',
  templateUrl: './list-subjects.component.html',
  styleUrls: ['./list-subjects.component.scss'],
})
export class ListSubjectsComponent implements OnInit, AfterViewInit {
  subjects: any[] = [];
  subjectsPaginated: any[] = [];
  pagesize = 6;
  users: any = [];
  projectId = '';
  tableViewMode: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  currentPage = 0;
  hasSubjectListPermission: boolean = false; // Permiso para listar sujetos, ver auditoria, buscar sujeto
  hasSubjectReadPermission: boolean = false; // Permiso para ver detalle del sujeto
  hasSubjectUpdatePermission: boolean = false; // Permiso para crear/editar sujeto
  hasSubjectCreatePermission: boolean = false; // Permiso para crear/editar sujeto
  hasSubjectDeletePermission: boolean = false; // Permiso para eliminar sujeto
  hasSubjectAuditPermission: boolean = false; // Permiso para ver auditoria de sujeto

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subjectsService: SubjectsService,
    private trialpalService: TrialpalService,
    public authService: AuthService,
    private transformDatePipe: TransformDatePipe,
    private projectService: ProjectService,
    public cardOrTableService: CardOrTableService,
    private userPermissionsService: UserPermissionsService
  ) {}

  public ngAfterViewInit() {
    this.detectScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectScreenSize();
  }
  private detectScreenSize() {
    const height = window.innerHeight;
    if (height > 1235) {
      this.pagesize = 12;
      this.subjectsPaginated = this.subjects.slice(0, this.pagesize);
    } else if (height > 1010) {
      this.pagesize = 9;
      this.subjectsPaginated = this.subjects.slice(0, this.pagesize);
    } else {
      this.pagesize = 6;
      this.subjectsPaginated = this.subjects.slice(0, this.pagesize);
    }
  }

  async ngOnInit() {
    this.projectId = this.route.snapshot.params.projectId;
    await this.setPermissions();
    await this.listSubjectsUserAdmin();
    if (this.subjects.length > 0) {
      this.dataTable = this.buildTPTableData(this.subjects);
    }
  }

  /**
   * La función `setPermissions` establece los permisos para sujetos
   * según los roles del usuario.
   */
  async setPermissions() {
    this.hasSubjectListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectList,
        Roles.Admin,
      ]);

    this.hasSubjectReadPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectRead,
        Roles.Admin,
      ]);

    this.hasSubjectCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectCreate,
      ]);

    this.hasSubjectDeletePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectDelete,
        Roles.Admin,
      ]);
    this.hasSubjectUpdatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectUpdate,
      ]);

    this.hasSubjectAuditPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectAudit,
        Roles.Admin,
      ]);
  }

  async listSubjectsUserAdmin() {
    this.trialpalService.showSpinner('subject.entity', 'LIST');
    this.subjects = await this.subjectsService.listProjectSubjects(
      this.projectId
    );
    // se ordenan los sujetos por el subjectNumber
    this.subjects.sort((a: any, b: any) =>
      a.subjectNumber.toLowerCase() > b.subjectNumber.toLowerCase() ? 1 : -1
    );
    this.subjectsPaginated = this.subjects.slice(0, this.pagesize);
    this.trialpalService.hideSpinner();
  }

  buildTPCardData(res: any): TPCardData {
    let date: any = '';
    let section3Site = '';
    if (res.currentScheduledPhase) {
      date = this.transformDatePipe.transform(res.currentScheduledPhase.date);
    }
    let entity;
    let showEditButton;
    let showActivateButton;
    let showAuditButton;
    let showDeleteButton;
    let cardIsButton;
    if (
      this.authService.isAdmin() ||
      this.authService.isInvestigator() ||
      this.authService.isReader()
    ) {
      entity = 'SUBJECT';
      showEditButton = this.hasSubjectUpdatePermission;
      showActivateButton = false;
      showAuditButton = this.hasSubjectAuditPermission;
      showDeleteButton = this.hasSubjectDeletePermission;
      cardIsButton = this.hasSubjectReadPermission;
      date = this.transformDatePipe.transform(res.currentScheduledPhase?.date);
      section3Site = res.site?.name;
    } else {
      entity = '';
      showEditButton = false;
      showActivateButton = false;
      showAuditButton = false;
      showDeleteButton = false;
      cardIsButton = false;
    }
    return {
      data: res,
      header: res.subjectNumber,
      section1: res.group,
      icon1: 'pi pi-user',
      section2: date,
      icon2: 'pi pi-calendar',
      section3:
        section3Site ??
        this.trialpalService.translateService.instant(
          'visit.enums.visitStates'
        )[res.state],
      icon3: 'pi pi-info-circle',
      styleColorState: '',
      entity,
      showEditButton,
      showActivateButton,
      showAuditButton,
      showDeleteButton,
      cardIsButton,
      objectAction: res.subjectNumber,
      entityAction:
        this.trialpalService.translateService.instant('subject.entity'),
    };
  }

  getEntityAndAuditButton() {
    let entity;
    if (
      this.authService.isAdmin() ||
      this.authService.isInvestigator() ||
      this.authService.isReader()
    ) {
      entity = TP2Entites.SUBJECT;
    } else {
      entity = '';
    }
    return [entity, this.hasSubjectAuditPermission];
  }

  buildTPTableData(subjectList: any): TPTableData {
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    let showDeleteButton = this.hasSubjectDeletePermission;
    let showEditButton = this.hasSubjectUpdatePermission;

    let newSubjectList = subjectList.map((s: any) => {
      return {
        data: s,
        entity,
        subjectNumber: s.subjectNumber,
        group: this.trialpalService.dictionaryPipe.transform(s.group),
        currentScheduledPhaseDate: this.transformDatePipe.transform(
          s.currentScheduledPhase?.date
        ),
        site: s.site?.name,
        visit: this.trialpalService.dictionaryPipe.transform(
          s.currentScheduledPhase?.phase
        ),
      };
    });
    let cols = [
      {
        header: this.trialpalService.translateService.instant(
          'subject.pSubjectNumber'
        ),
        type: TypeInput.TEXT,
        field: 'subjectNumber',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant('subject.pGroup'),
        type: TypeInput.TEXT,
        field: 'group',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'addEditSubjectComponent.PlaceHolderPhaseState'
        ),
        type: TypeInput.TEXT,
        field: 'visit',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'subject.completedVisitDate'
        ),
        type: TypeInput.TEXT,
        field: 'currentScheduledPhaseDate',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant('subject.siteId'),
        type: TypeInput.TEXT,
        field: 'site',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];

    return {
      valueTable: newSubjectList,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: [
        'subjectNumber',
        'group',
        'visit',
        'currentScheduledPhaseDate',
        'site',
      ],
      cols,
      customButton1: showAuditButton
        ? {
            icon: 'pi pi-file',
            title: this.trialpalService.translateService.instant(
              'general.btnAuditTrace'
            ),
          }
        : null,
      showDeleteButton,
      showEditButton,
      entityAction:
        this.trialpalService.translateService.instant('subject.entity'),
      menuOptions: [],
    };
  }
  openChangeReason(subject: any) {
    logger.debug('openChangeReason', subject);
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant('subject.deleteSubject') +
        ': ' +
        subject.subjectNumber,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.delete(subject, data);
        }
      },
    });
  }
  delete(s: any, _changeReason: string): void {
    const projectCode = this.projectService.getCurrentProject().code;
    this.trialpalService.showSpinner('subject.entity', 'DELETE');
    this.subjectsService
      .tp2SubjectDelete(s.subjectNumber, projectCode, _changeReason)
      .then((response) => {
        logger.debug('deleteContdeleteSubjectractor response', response);

        this.detectScreenSize();
        this.projectService.setCurrentProject(
          JSON.parse(localStorage.getItem('currentProject') ?? '')
        );
        this.trialpalService.hideSpinner();
        this.trialpalService.showMutationSuccess('subject.entity', 'DELETE');
        //elimina el subject de la lista
        this.subjects = this.subjects.filter((subjectInstance: any) => {
          return subjectInstance.subjectNumber !== s.subjectNumber;
        });
        if (this.subjects.length > 0) {
          this.dataTable = this.buildTPTableData(this.subjects);
        }
        //elimina el sujeto de la vista de tarjetas refrescando la paginación actual
        let paginationTarget = {
          page: this.currentPage,
        };
        this.paginate(paginationTarget);
      })
      .catch((error) => {
        this.trialpalService.hideSpinner();
        if (error?.response?.data) {
          this.trialpalService.showServiceError('subjects.deleteSubject', {
            errors: [
              {
                errorType: 'Lambda:Unhandled',
                message: error?.response?.data,
              },
            ],
          });
        } else {
          this.trialpalService.showServiceError(
            'user.actions.getUserSubjectsByNumber',
            error
          );
        }
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  editSubject(s: any): void {
    if (s.state !== SubjectState.ENROLLED) {
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary: this.trialpalService.translateService.instant(
          'subject.errorMessageEditingSubject.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'subject.errorMessageEditingSubject.detail'
        ),
        life: 5000,
      });
      return;
    }
    this.router.navigate(['subjects', this.projectId, 'edit'], {
      queryParams: [s.id],
    });
  }

  goDetailSubject(s: any): void {
    if (this.hasSubjectReadPermission) {
      this.router.navigate(['subjects', s.id, 'detail']);
    }
  }

  paginate(event: any) {
    this.currentPage = event.page;
    this.subjectsPaginated = this.subjects.slice(
      event.page * this.pagesize,
      event.page * this.pagesize + this.pagesize
    );
  }
  forward(): void {
    this.router.navigate(['project/' + this.projectId + '/detail']);
  }
}
