<div>
  <label
    id="lbl_observationUnit"
    name="lbl_observationUnit"
    for="txt_verifiedTemperatureComment"
    >{{ 'audit.label' | translate }}</label
  >
  <div class="p-col-12 p-pl-0" id="audit">
    <label for="reasonSelect"
      >{{ 'conciliation.changeReason' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <p-dropdown
      id="auditChangeReason"
      name="auditChangeReason"
      [options]="reasonsItems"
      [(ngModel)]="reason"
      placeholder="{{ 'general.selectPlaceholder' | translate }}"
      [showClear]="true"
      [required]="true"
      (onChange)="changeValue()"
      #auditChangeReason="ngModel"
    ></p-dropdown>
    <small class="form-error" *ngIf="auditChangeReason?.invalid">
      {{ 'general.required' | translate }}
    </small>
  </div>
  <div *ngIf="auditChangeReason?.value === 'other'" class="p-mt-3">
    <label for="txtOtherReason"
      >{{ 'general.changeReasons.other' | translate
      }}<span class="inputRequired"> * </span></label
    >
    <textarea
      class="p-col-12"
      pInputTextarea
      id="txtOtherReason"
      name="txtOtherReason"
      required="true"
      [(ngModel)]="otherReason"
      (input)="changeArea()"
      [autoResize]="true"
      #txtOtherReason="ngModel"
    ></textarea>
    <small class="form-error" *ngIf="txtOtherReason?.invalid">{{
      'general.required' | translate
    }}</small>
  </div>
</div>
