import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { SitesService } from '../../sites/sites.service';
import { InformedConsentService } from '../informed-consent.service';
import { SiteFormComponent } from './components/site-form/site-form.component';
import {
  ConfInformedConsent,
  CreateConfInformedConsentInput,
  InformedConsentSiteInput,
  InformedConsentType,
  QuizLayout,
  UpdateConfInformedConsentInput,
} from '../informed-consent.types';
const logger = new Logger('tp2-logger-addEditInformedConsentPage');

@Component({
  selector: 'app-informed-consent-add-edit',
  templateUrl: './informed-consent-add-edit.component.html',
  styleUrls: ['./informed-consent-add-edit.component.scss'],
})
export class InformedConsentAddEditComponent implements OnInit {
  @ViewChildren(SiteFormComponent)
  siteForms!: QueryList<FormGroupDirective>;
  projectId = '';
  input = {} as CreateConfInformedConsentInput | UpdateConfInformedConsentInput;
  confInformedConsent: any = null;
  isEdition = false;
  isSpanish: string = '';
  isEnglish: string = '';
  isReadOnly = false;
  dictionary: any[] = [];
  results: string[] = [];
  informedConsentTypeOptions: any[] = [];
  sitesOptions: any[] = [];
  uploadedFile: File | null = null;
  currentFile: any = {
    image: '',
    size: 0,
    name: '',
  };
  isFileLoaded: boolean = false;
  expectedVersion = null;
  INFORMED_CONSENT_ENTITY = 'informedConsent.informedConsent';
  UPLOAD_PATH = '';
  quizPagesLoyout: any[] = [];
  sites: any[] = [];
  booleanOptions: any[] = [];
  signatureOptions: number[] = [2, 3, 4, 5, 6];
  originalConfIC: any = {};
  constructor(
    public readonly informedConsentService: InformedConsentService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trialpalService: TrialpalService,
    private readonly translateService: TranslateService,
    private readonly sitesService: SitesService
  ) {}

  async ngOnInit() {
    this.projectId = this.route.snapshot.params.projectId;
    this.UPLOAD_PATH =
      'conf-informed-consent/' +
      this.projectId +
      '/committeeApprovalSealFiles/';
    this.isReadOnly = !this.informedConsentService.auth.isAdmin();
    this.showSpinner();

    await this.getSitesByProject();
    const confInformedConsentId = this.route.snapshot.params.id;
    await this.getInformedConsentById(confInformedConsentId);

    this.setInformedConsentSites(this.confInformedConsent);
    this.initInformedConsentTypeOptionsAndQuizPageLoyout();
    this.setBooleanOptions();
    this.hideSpinner();
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  async getInformedConsentById(confInformedConsentId: string): Promise<any> {
    if (!confInformedConsentId) {
      this.isFileLoaded = true;
      return this.setInitialValues();
    }

    this.confInformedConsent =
      await this.informedConsentService.getConfInformedConsentById(
        confInformedConsentId
      );
    this.updateconfInformedConsent(this.confInformedConsent);
    await this.loadCurrentFile();
  }

  showSpinner() {
    this.trialpalService.showSpinner(this.INFORMED_CONSENT_ENTITY, 'GET');
  }

  async getSitesByProject() {
    await this.sitesService
      .getSitesByProject(this.projectId)
      .then((sites: any) => {
        if (sites) {
          this.sitesOptions = sites;
        }
      })
      .catch((error) => {
        logger.error('listSite error', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  initInformedConsentTypeOptionsAndQuizPageLoyout() {
    this.informedConsentTypeOptions = Object.values(InformedConsentType)
      .map((type: any) => {
        return {
          label: this.trialpalService.translateService.instant(
            'informedConsent.informedConsentTypes.' + type
          ),
          value: type,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    this.quizPagesLoyout = Object.values(QuizLayout).map((quizLayout) => {
      return {
        label: this.trialpalService.translateService.instant(
          'informedConsent.orderQuiz.' + quizLayout
        ),
        value: quizLayout,
      };
    });
  }

  setInitialValues(): void {
    this.input.projectId = this.projectId;
  }

  hideSpinner(): void {
    this.trialpalService.hideSpinner();
  }

  isMultipleSignatureRequiredChange() {
    const {
      isMultipleSignatureRequired,
      minRequiredSignatures,
      maxRequiredSignatures,
    } = this.input;
    if (
      isMultipleSignatureRequired &&
      minRequiredSignatures === 1 &&
      maxRequiredSignatures === 1
    ) {
      this.input.minRequiredSignatures = undefined;
      this.input.maxRequiredSignatures = undefined;
    }
  }

  async onSubmit(form: NgForm) {
    let isFormsSiteValid = this.isFormsSiteValid();
    logger.debug('Formulario -->', form, isFormsSiteValid, this.uploadedFile);
    //Valida si el formulario es valido, se subio un archivo y el formulario de sitios es correcto
    if (
      form.valid &&
      this.uploadedFile?.name &&
      isFormsSiteValid &&
      (this.input.maxRequiredSignatures ?? 1) >=
        (this.input.minRequiredSignatures ?? 1)
    ) {
      const message = this.isEdition
        ? 'informedConsent.updatingEcosent'
        : 'informedConsent.creatingEcosent';
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant(message)
      );

      this.setFormSites();
      this.setTotalRequiredSignatures();
      this.input.icVersion = Number(this.input.icVersion);
      await this.uploadFile();

      this.saveInformedConfConsent();
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  //Si no es de multiple firma agrega 1 por defecto
  setTotalRequiredSignatures() {
    this.input.minRequiredSignatures = this.input.isMultipleSignatureRequired
      ? this.input.minRequiredSignatures
      : 1;
    this.input.maxRequiredSignatures = this.input.isMultipleSignatureRequired
      ? this.input.maxRequiredSignatures
      : 1;
  }

  //Se encarga de llenar la instancia de configuración con los sitios seleccionados
  setFormSites() {
    const informedConsentSitesInput: InformedConsentSiteInput[] = [];
    this.sites.forEach((site: any) => {
      informedConsentSitesInput.push({
        site: site.site,
        recipients: site.recipients,
        totalSubjects: site.totalSubjects,
      });
    });

    this.input.sites = informedConsentSitesInput;
  }

  async uploadFile() {
    const isNameEqual = this.currentFile?.name === this.uploadedFile?.name;
    const isSizeEqual = this.currentFile?.size === this.uploadedFile?.size;
    if (!isNameEqual || !isSizeEqual || !this.currentFile?.name) {
      this.input.approvalSealFileUrl = await this.getUploadKey(
        this.uploadedFile
      );
    }
  }

  saveInformedConfConsent() {
    logger.debug('Save ConfInformedConsent -->', this.input);
    if (this.isEdition) {
      if (JSON.stringify(this.originalConfIC) === JSON.stringify(this.input)) {
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_ENTITY,
          'UPDATE'
        );
        return this.trialpalService.hideSpinner();
      } else {
        return this.modalChangeReason();
      }
    }
    this.createConfInformedConsent();
  }

  async modalChangeReason() {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'informedConsent.audit.updatingEconsent'
        ) +
        ': ' +
        this.trialpalService.dictionaryPipe.transform(this.input.name ?? ''),
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.input._changeReason = data;
          this.updateConfInformedConsent();
        } else {
          this.trialpalService.hideSpinner();
        }
      },
    });
  }

  isFormsSiteValid() {
    let isFormsSiteValid: boolean = true;
    this.siteForms.toArray().forEach((formSite: any) => {
      const form = formSite.form as NgForm;
      if (form.invalid) {
        isFormsSiteValid = false;
        Object.keys(formSite.form.controls).forEach((control: string) => {
          form.controls[control].markAllAsTouched();
        });
      }
    });

    return isFormsSiteValid;
  }

  isAddButtonSizeDisabled() {
    let isFormsSiteInValid: boolean = false;
    this.siteForms?.toArray()?.forEach((formSite: any) => {
      if (formSite.form.invalid) {
        isFormsSiteInValid = true;
      }
    });
    if (this.sites.length === this.sitesOptions.length) {
      isFormsSiteInValid = true;
    }
    return isFormsSiteInValid;
  }

  createConfInformedConsent() {
    this.informedConsentService
      .createInformedConsent(this.input)
      .then((res) => {
        this.trialpalService.messageService.clear();
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_ENTITY,
          'CREATE'
        );
        this.updateconfInformedConsent(res);
        this.router.navigateByUrl(
          `/informed-consent/${this.projectId}/detail/${res.id}`
        );
      })
      .catch((err) => {
        logger.error(err);
        this.trialpalService.showServiceError(
          this.INFORMED_CONSENT_ENTITY,
          err
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  updateConfInformedConsent() {
    this.informedConsentService
      .updateInformedConsent(this.input, this.expectedVersion)
      .then((res) => {
        this.trialpalService.messageService.clear();
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_ENTITY,
          'UPDATE'
        );
        this.updateconfInformedConsent(res);
      })
      .catch((err) => {
        logger.error(err);
        this.trialpalService.showServiceError(
          this.INFORMED_CONSENT_ENTITY,
          err
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async getUploadKey(file: File | null): Promise<string> {
    if (file) {
      const putResult = await this.informedConsentService.storage.put(
        file,
        this.UPLOAD_PATH,
        file.name
      );
      this.currentFile = file;
      return putResult.key;
    }
    return '';
  }

  updateconfInformedConsent(data: any) {
    this.expectedVersion = data._version;
    this.trialpalService.cleanQueryResponse(data);
    delete data.project;
    Object.assign(this.input, data);
    this.originalConfIC = structuredClone(this.input);
    this.originalConfIC.sites.forEach((site: any) => {
      delete site.__typename;
      return site;
    });
    this.isEdition = true;
  }

  setInformedConsentSites(confInformedConsent: ConfInformedConsent) {
    this.sites = [];
    confInformedConsent?.sites?.forEach((site: any) => {
      this.sites.push({
        site: site.site,
        recipients: site.recipients,
        totalSubjects: site.totalSubjects,
      });
    });

    if (this.sites.length === 0) {
      this.sites.push({
        site: '',
        recipients: [],
        totalSubjects: undefined,
      });
    }
  }

  async loadCurrentFile() {
    if (this.input.approvalSealFileUrl) {
      const files = await this.informedConsentService.storage.list(
        this.input.approvalSealFileUrl
      );
      const url = await this.informedConsentService.storage.get(
        this.input.approvalSealFileUrl
      );

      if (files[0]) {
        this.currentFile = {
          name: files[0]?.key?.split('/committeeApprovalSealFiles/')[1],
          size: files[0].size,
          image: url,
        };
      }
    }
    this.isFileLoaded = true;
  }

  setNameAttribute(data: any): void {
    this.input.name = data;
  }

  addNewSite() {
    this.sites.push({
      site: '',
      recipients: [],
      total: undefined,
    });
  }

  deleteSite(siteIndex: number) {
    this.sites = this.sites.filter((site: any, index: number) => {
      return index !== siteIndex;
    });
  }

  getTotalSubjects() {
    let totalSubjects = 0;
    for (const site of this.sites) {
      if (typeof site.totalSubjects !== 'number') continue;
      totalSubjects += site.totalSubjects;
    }
    return totalSubjects ?? 0;
  }

  //Devuelve todas las ventanas con site seleccionado
  get seletedSites(): any[] {
    const selectedSites = this.sites.filter((site: any) => site?.site?.trim());
    return selectedSites ?? [];
  }
  trackSelectedeConsentTypeEvent() {
    this.informedConsentService.recordeConsentEvent(
      'site_consent_selected_type_' + this.input.type,
      { project: this.projectId }
    );
  }
}
