import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InstanceState } from 'src/app/services/trialpal.types';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { SubjectsService } from '../../../subjects.service';

const logger = new Logger('tp2-logger-diaryEnabledPage');
@Component({
  selector: 'app-disabled-enabled-diary-subject',
  templateUrl: './disabled-enabled-diary-subject.component.html',
  styleUrls: ['./disabled-enabled-diary-subject.component.scss'],
})
export class DisabledEnabledDiarySubjectComponent implements OnInit {
  currentDate: Date | null = null;
  timeZoneOffset: number = 0;
  dayInstances: any[] = [];
  ediary: any;
  pendingDays = 0;
  completedDays = 0;
  suspendedDays = 0;
  submitSuspendDaily = false;
  subjectId: string = '';

  suspensionReasonsKeys = [
    {
      value: 'suspensionByErrorByLAR',
      label: 'subject.ediarySuspension.suspensionByErrorByLAR',
    },
    {
      value: 'suspensionByErrorBySite',
      label: 'subject.ediarySuspension.suspensionByErrorBySite',
    },
    {
      value: 'suspensionByWithoutSymptom',
      label: 'subject.ediarySuspension.suspensionByWithoutSymptom',
    },
    { value: 'other', label: 'general.changeReasons.other' },
  ];
  reasonsItems: SelectItem[] = [];
  selectedReason = '';

  otherReason = '';
  constructor(
    private configDialog: DynamicDialogConfig,
    private readonly trialpalService: TrialpalService,
    private subjectsService: SubjectsService,
    private transformDatePipe: TransformDatePipe,
    private confirmationService: ConfirmationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.trialpalService.translateService
      .get('primeng')
      .subscribe((res) => this.trialpalService.config.setTranslation(res));
    this.trialpalService.showSpinner(
      'ediary.components.generalConfiguration.days',
      'LIST'
    );
    this.subjectId = this.configDialog?.data.subjectId;
    this.ediary = this.configDialog?.data?.eDiary;
    this.putSuspensionValues(this.ediary);
    this.timeZoneOffset = this.configDialog?.data?.timeZoneOffset || 0;
    this.getSuspensionEnumsValue();
    await this.getDayInstancesByEdiaryPhase();
  }

  putSuspensionValues(ediary: any) {
    if (ediary.suspensionReason) {
      let key = this.suspensionReasonsKeys.find(
        (suspensionKey: any) => suspensionKey.value === ediary.suspensionReason
      );
      this.selectedReason = key ? key.value : 'other';
      this.otherReason =
        this.selectedReason === 'other' ? ediary.suspensionReason : '';
    }
    if (ediary.suspensionDate) {
      this.currentDate = new Date(ediary.suspensionDate);
    }
  }

  getSuspensionEnumsValue() {
    const _visit = this.trialpalService.dictionaryPipe.transform(
      this.ediary.phase
    );
    for (const reason of this.suspensionReasonsKeys) {
      this.reasonsItems.push({
        value: reason.value,
        label: this.trialpalService.translateService.instant(reason.label, {
          VISIT: _visit,
        }),
      });
    }
  }

  async getDayInstancesByEdiaryPhase() {
    const dayinstances = await this.subjectsService.getDayInstanceByEDiaryPhase(
      this.ediary.id
    );
    logger.debug('getDayInstanceByEDiaryPhase response', dayinstances);
    this.dayInstances = dayinstances.items.filter(
      (dayInstance: any) =>
        !dayInstance._deleted && dayInstance.state !== InstanceState.DELETED
    );
    this.dayInstances = [...this.dayInstances].sort(function (a, b) {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });
    this.getDaysNumberByState();

    this.trialpalService.hideSpinner();
  }

  getDaysNumberByState() {
    this.completedDays = this.dayInstances.filter(
      (days: any) => days.state === InstanceState.COMPLETED
    ).length;
    this.pendingDays = this.dayInstances.filter(
      (days: any) => days.state === InstanceState.PENDING
    ).length;
    this.suspendedDays = this.dayInstances.filter(
      (days: any) => days.state === InstanceState.SUSPENDED
    ).length;
  }

  buildTPCardData(res: any): TPCardData {
    return {
      data: res.type,
      header: this.trialpalService.dictionaryPipe.transform(
        res.confDay.dayName
      ),
      state: this.trialpalService.translateService.instant(
        'general.instanceState.' + res.state
      ),
      styleColorState: this.getStateColor(res.state),
      section1: this.transformDatePipe.transform(res.startDate),
      icon1: 'pi pi-calendar',
      section1ToolTip: this.trialpalService.translateService.instant(
        'medicalattentioninstance.startDate'
      ),
      section2: this.transformDatePipe.transform(res.finishDate),
      icon2: 'pi pi-calendar-times',
      section2ToolTip: this.trialpalService.translateService.instant(
        'medicalattentioninstance.endDate'
      ),
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      cardIsButton: false,
      objectAction: 'res.name',
    };
  }

  getStateColor(state: any) {
    if (state === InstanceState.COMPLETED) {
      return 'green';
    } else if (state === InstanceState.PENDING) {
      return 'red';
    } else if (state === InstanceState.SUSPENDED) {
      return 'blue';
    }
    return 'black';
  }

  onSubmit(form: NgForm): void {
    this.submitSuspendDaily = true;
    if (this.currentDate && form.valid) {
      const today = new Date(this.currentDate).toISOString().slice(0, 10);
      this.confirmationService.confirm({
        message: this.trialpalService.translateService.instant(
          'subject.confirmSuspendDaily',
          {
            currentDate: this.transformDatePipe.transform(today),
          }
        ),
        accept: () => {
          logger.debug('TODO DISABLED ENABLED DAYS');
          this.suspendedDaysTransaction();
          this.verifyCompletedDayInstance();
        },
      });
    }
  }

  async suspendedDaysTransaction() {
    try {
      this.trialpalService.showSpinner(
        'ediary.components.generalConfiguration.days',
        'UPDATE'
      );
      const suspensionDate = (this.currentDate ?? new Date()).toISOString();
      const suspensionReason =
        this.selectedReason === 'other'
          ? this.otherReason
          : this.selectedReason;
      const result = await this.subjectsService.tp2EDiarySuspension(
        this.ediary.id,
        suspensionDate,
        suspensionReason
      );

      if (result) {
        this.trialpalService.showMutationSuccess(
          'subject.tabheaderPDFDiary',
          'SUSPENDED'
        );
        this.trialpalService.messageService.add({
          severity: 'info',
          life: 10000,
          detail: this.trialpalService.translateService.instant(
            'subject.sucessMessageSupendDaily'
          ),
        });
      } else {
        this.transactionErrorMessage();
      }
      this.trialpalService.ref.close(result);
      this.trialpalService.hideSpinner();
    } catch (err) {
      this.transactionErrorMessage();
      this.trialpalService.ref.close(err);
    }
  }
  transactionErrorMessage() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.messageErrorOperation.detail'
      ),
    });
  }

  verifyCompletedDayInstance() {
    const date = this.currentDate ?? new Date();
    const daysTransaction = this.dayInstances.filter((x) => {
      const startDate = new Date(x.startDate);
      startDate.setDate(startDate.getDate() + 1);
      return !!(
        startDate.getTime() >= date.getTime() && x.state === 'COMPLETED'
      );
    });
    if (daysTransaction.length > 0) {
      this.showCompletedDayInfoMessage();
    }
  }

  showCompletedDayInfoMessage() {
    this.trialpalService.messageService.add({
      severity: 'info',
      life: 10000,
      summary:
        this.trialpalService.translateService.instant('general.attention'),
      detail: this.trialpalService.translateService.instant(
        'subject.daysCompletedSuspension'
      ),
    });
  }
}
