import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import {
  CreateVerifiedMedicalAttentionInstanceInput,
  InstanceState,
  MedicalAttentionInstance,
  VerifiedMedicalAttentionInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import { SubjectsService } from 'src/app/modules/subjects/subjects.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { PaginationService } from '../../../../pagination.service';
import { TableDayInstanceComponent } from '../../shared/day-instance/table-day-instance/table-day-instance.component';
import { DeleteReasonComponent } from '../../shared/delete-reason/delete-reason.component';
import { SymptomService } from '../../symptom/symptom.service';
import { DayInstanceService } from './../../shared/day-instance/day-instance.service';
import { MedicalAttentionService } from './../medical-attention.service';

const logger = new Logger('tp2-conciliationDetail-medicalAttentionDetail');
@Component({
  selector: 'app-detail-days-medical-attention',
  templateUrl: './detail-days-medical-attention.component.html',
  styleUrls: ['./detail-days-medical-attention.component.scss'],
})
export class DetailDaysMedicalAttentionComponent implements OnInit {
  @ViewChild(TableDayInstanceComponent)
  tableDayInstanceComponent!: TableDayInstanceComponent;
  @Input() subjectId: string = '';
  @Input() confEdiaryId: string = '';

  cols: any[] = [];
  constructor(
    public medicalAttentionService: MedicalAttentionService,
    public conciliationService: ConciliationService,
    private subjectsService: SubjectsService,
    private trialpalService: TrialpalService,
    private symptomService: SymptomService,
    private dayInstanceService: DayInstanceService,
    private paginationService: PaginationService,
    private dialogService: DialogService
  ) {
    this.cols = [
      {
        field: 'type',
        header: this.trialpalService.translateService.instant(
          'medicalattentioninstance.type'
        ),
      },
      {
        field: 'reason',
        header: this.trialpalService.translateService.instant(
          'medicalattentioninstance.reason'
        ),
      },
      {
        field: 'wasHospitalized',
        header: this.trialpalService.translateService.instant(
          'medicalattentioninstance.enums.HOSPITALIZATION'
        ),
      },
      {
        field: 'startDate',
        header: this.trialpalService.translateService.instant(
          'symptominstance.startDate'
        ),
      },
      {
        field: 'finishDate',
        header: this.trialpalService.translateService.instant(
          'symptominstance.endDate'
        ),
      },
    ];
  }

  async ngOnInit() {
    await this.symptomService.getSymptomsMedicationAndMedicalAttention();
    const items =
      this.medicalAttentionService.configMedicalAttentionsByEdiary?.items ?? [];

    if (items.length === 0) {
      await this.medicalAttentionService.getConfMedicalAttentionsByEdiary(
        this.conciliationService.confEdiaryId
      );
    }
  }

  async isMedicalAttentionLoading(colDays: any[]) {
    logger.debug('isMedicalAttentionLoading:', colDays);
    await this.medicalAttentionService.getVerifiedMedicalAttentions(
      this.subjectId
    );
    if (colDays && colDays.length > 0) {
      this.medicalAttentionService.preconditionTable = colDays;
    }
    await this.listMedicalAttentions();
    await this.listNewMedicalAttentions();
    this.completeMedicalAttentionTable();
  }

  completeMedicalAttentionTable() {
    this.medicalAttentionService.addCommentsTable();
    logger.debug(
      'medicalAttentionsDay',
      this.medicalAttentionService.medicalAttentionTable,
      this.medicalAttentionService.observations
    );
  }

  newMedicalAttention() {
    this.paginationService.selectedMedicalAttention({}, true);
  }

  async listNewMedicalAttentions() {
    const verifiedDayInstances = this.dayInstanceService.verifiedDayInstances;

    //Filtra las atenciones medicas verificadas que sean creadas y ademas sean de la visita actual a conciliar
    const verifiedMedicalAttentions =
      this.medicalAttentionService.verifiedMedicalAttentionsBySubject.filter(
        (VMA: any) => {
          //Busca si la atencion medica esta asociada a los días verificados actuales
          const verifiedDayInstance = verifiedDayInstances?.find((day: any) => {
            return day.id === VMA?.verifiedDayInstanceId;
          });

          return (
            VMA?.medicalAttentionInstanceId?.length === 0 &&
            verifiedDayInstance &&
            !verifiedDayInstance._deleted
          );
        }
      );

    for (let verifiedMedicalAttention of verifiedMedicalAttentions) {
      const dayInstance =
        this.conciliationService.getDayInstanceByVerifiedDayInstanceId(
          verifiedMedicalAttention.verifiedDayInstanceId ?? ''
        );
      const isDayBeforeToCurrentDate =
        this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);

      if (dayInstance?.id && isDayBeforeToCurrentDate) {
        const medicalAttention =
          this.medicalAttentionService.getNewMedicalAttention(
            dayInstance,
            dayInstance.confDay.confFormId,
            this.subjectId,
            verifiedMedicalAttention
          );
        this.medicalAttentionService.medicalAttentionTable.push(
          medicalAttention
        );
      }
      logger.debug('Day instance', dayInstance);
    }
  }

  /**
   * @desc Cargar las instancias de atenciones medicas del sujeto
   */
  async listMedicalAttentions() {
    this.medicalAttentionService.medicalAttention = [];
    this.medicalAttentionService.medicalAttentionTable = [];

    //Obtiene los dayInstances con los medicamentos activos
    const dayInstances = this.filterDaysByMedicalAttentionRequired();
    await this.subjectsService
      .medicalAttentionInstanceBySubjectId(this.subjectId)
      .then(async (medicalAttentionInstances: any) => {
        medicalAttentionInstances = medicalAttentionInstances?.items?.filter(
          (_medicalAtt: any) => {
            return dayInstances.find(
              (day: any) => day.dayInstance.id === _medicalAtt.dayInstanceId
            );
          }
        );

        const MedicalAttentions: MedicalAttentionInstance[] =
          medicalAttentionInstances;
        MedicalAttentions?.sort(
          (a: MedicalAttentionInstance, b: MedicalAttentionInstance) =>
            a.createdAt > b.createdAt ? 1 : -1
        );
        if (MedicalAttentions && MedicalAttentions?.length > 0) {
          for (const [index, medicalAttention] of MedicalAttentions.entries()) {
            if (medicalAttention.dayInstance?.confDay) {
              //Cargar la configuracion
              let configuration =
                this.medicalAttentionService.getConfMedicalAttentionByForm(
                  medicalAttention.dayInstance?.confDay?.confFormId
                );
              //Cargar los datos vefificados
              const verifiedMedicalAttention =
                this.medicalAttentionService.verifiedMedicalAttentionsBySubject?.find(
                  (verified: VerifiedMedicalAttentionInstance) =>
                    verified.medicalAttentionInstanceId === medicalAttention.id
                );

              logger.debug(
                'verifiedMedicalAttentionsBySubject',
                this.medicalAttentionService.verifiedMedicalAttentionsBySubject
              );
              if (!configuration) {
                configuration =
                  this.medicalAttentionService.getEmptyConfMedicalAttention();
              }
              const reason = this.medicalAttentionService.addReason(
                configuration,
                medicalAttention
              );

              this.addMedicalAttention(
                index,
                medicalAttention,
                reason,
                configuration,
                verifiedMedicalAttention
              );
            }
          }
        }
      });
  }

  filterDaysByMedicalAttentionRequired() {
    //Obtiene los dayInstances con los medicamentos activos
    return this.conciliationService.dayInstancesAndConfigurations.filter(
      (dayConf: any) => {
        const configuration = dayConf.configuration;
        //Valida si el formulario puede conciliar atenciones medicas
        const isMedicalAttentionConciliationAllowed =
          this.paginationService.isSectionReconciliationAllowed(
            'isMedicalAttentionRequired',
            configuration
          );
        return (
          configuration?.isMedicalAttentionRequired &&
          isMedicalAttentionConciliationAllowed
        );
      }
    );
  }

  showDeleteButton() {
    const currentMedicalAttention =
      this.medicalAttentionService.selectedMedicalAttention;
    const medicalAttentionReconciliationConfig =
      currentMedicalAttention?.confForm?.medicalAttentionReconciliationConfig;
    logger.debug(
      'medicalAttentionReconciliationConfig',
      medicalAttentionReconciliationConfig
    );
    if (medicalAttentionReconciliationConfig?.allowRemoveRecords === false)
      return false;
    const verifiedMedicalAttention =
      currentMedicalAttention?.verifiedMedicalAttention;
    const medicalAttention = currentMedicalAttention?.medicalAttention;
    if (verifiedMedicalAttention?.id) {
      return verifiedMedicalAttention?.state !== InstanceState.DELETED;
    } else if (medicalAttention?.id) {
      return medicalAttention?.state !== InstanceState.DELETED;
    } else {
      return false;
    }
  }

  confirmDeleteMedicalAttention() {
    const ref = this.dialogService.open(DeleteReasonComponent, {
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmDelete'
      ),
      style: { 'min-width': '360px', width: '50%' },
    });
    ref.onClose.subscribe(async (deleteReason: string) => {
      if (deleteReason) {
        await this.changeMedicalAttentionToDeleteState(deleteReason);
      }
    });
  }

  private addMedicalAttention(
    index: number,
    medicalAttention: MedicalAttentionInstance,
    reason: any,
    configuration: any,
    verifiedMedicalAttention?: VerifiedMedicalAttentionInstance
  ) {
    const dayInstance =
      this.conciliationService.dayInstancesAndConfigurations.find(
        (day: any) => day.dayInstance.id === medicalAttention.dayInstanceId
      )?.dayInstance;
    const isDayBeforeToCurrentDate =
      this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);

    if (isDayBeforeToCurrentDate) {
      this.medicalAttentionService.medicalAttentionTable.push(
        this.medicalAttentionService.objMedicalAttention(
          index,
          dayInstance,
          medicalAttention,
          reason,
          configuration,
          verifiedMedicalAttention
        )
      );
    }
  }
  /**
   * @des asignar al objeto de la medicion la fila seleccionada
   * @param event objeto de la fila
   */
  onRowSelect(event: any) {
    logger.debug('onRowSelect', event);
    this.paginationService.selectedMedicalAttention(event);
    this.dayInstanceService.currentDayInstance = {};
  }
  async changeMedicalAttentionToDeleteState(deleteReason: string) {
    try {
      this.conciliationService.showSpinner(
        'transactionLoadingDelete',
        'medicalattentioninstance.entity'
      );
      const medicalAttention =
        this.medicalAttentionService.medicalAttention?.medicalAttention;
      const selectedMedicalAttention =
        this.medicalAttentionService.selectedMedicalAttention;
      let verifiedMedicalAttention =
        selectedMedicalAttention?.verifiedMedicalAttention;

      let isMedicalAttentionDelete = false;

      if (verifiedMedicalAttention?.id) {
        const { verified, isDelete } =
          await this.medicalAttentionService.updateOrDeleteMedicalAttentionTable(
            verifiedMedicalAttention,
            medicalAttention,
            deleteReason
          );
        isMedicalAttentionDelete = isDelete;
        verifiedMedicalAttention = verified;
      } else {
        const verifiedDayInstance =
          await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
            medicalAttention.dayInstanceId
          );
        const newMedicalAttention: CreateVerifiedMedicalAttentionInstanceInput =
          {
            subjectId: medicalAttention.subjectId,
            finishDate: medicalAttention.finishDate || null,
            typeComment: deleteReason,
            reason: medicalAttention.reason,
            rememberStartDate: medicalAttention.rememberStartDate,
            startDate: medicalAttention.startDate || null,
            state: InstanceState.DELETED,
            type: medicalAttention.type,
            wasHospitalized: medicalAttention.wasHospitalized,
            _changeReason: medicalAttention._changeReason,
            _lastUser: medicalAttention._lastUser,
            medicalAttentionInstanceId: medicalAttention.id,
            verifiedDayInstanceId: verifiedDayInstance.id,
            _version: 1,
            symptomsInstances: medicalAttention.symptomsInstances,
            rememberFinishDate: medicalAttention.rememberFinishDate,
          };
        verifiedMedicalAttention =
          await this.medicalAttentionService.createVerifiedMedicalAttentionInstance(
            newMedicalAttention
          );
      }

      //Actualiza la tabla
      this.updateVerifiedMedicalAttention(verifiedMedicalAttention);

      //Actualiza el dia si se elimina la ultima instancia asociada
      await this.updateVerifiedDayInstance(
        selectedMedicalAttention.dayInstance.id,
        deleteReason
      );
      await this.conciliationService.updateEdiaryPhaseState(false).catch();
      this.completeMedicalAttentionTable();
      this.medicalAttentionService.selectedMedicalAttention = {};
      this.medicalAttentionService.medicalAttention = {};
      this.medicalAttentionService.verifiedMedicalAttention =
        {} as VerifiedMedicalAttentionInstance;
      this.paginationService.selectedMedicalAttention({}, true);
      if (!isMedicalAttentionDelete) {
        this.paginationService.selectedMedicalAttention(
          { data: selectedMedicalAttention },
          false
        );
      }
      this.trialpalService.hideSpinner();
      this.conciliationService.messageDeleteSuccess();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error('error eliminar atencion medica', error);
    }
  }

  //Actualiza la instanci en la tabla de atenciones medicas
  updateVerifiedMedicalAttention(verifiedMedicalAttention: any) {
    this.medicalAttentionService.medicalAttentionTable =
      this.medicalAttentionService.medicalAttentionTable.map(
        (medicalAttention: any) => {
          const updateObject = this.medicalAttentionService.objMedicalAttention(
            medicalAttention?.index,
            medicalAttention?.dayInstance,
            medicalAttention?.medicalAttention,
            medicalAttention?.reason,
            medicalAttention?.configuration,
            verifiedMedicalAttention
          );

          if (
            verifiedMedicalAttention?.id &&
            medicalAttention?.verifiedMedicalAttention?.id ===
              verifiedMedicalAttention?.id
          ) {
            medicalAttention = updateObject;
          }

          return medicalAttention;
        }
      );

    this.medicalAttentionService.selectedMedicalAttention.verifiedMedicalAttention =
      verifiedMedicalAttention;
    this.medicalAttentionService.selectedMedicalAttention.type = {
      comment: verifiedMedicalAttention.typeComment,
      ...this.medicalAttentionService.selectedMedicalAttention.type,
    };
  }

  async updateVerifiedDayInstance(dayInstanceId: string, deleteReason: string) {
    const medicalAttentionInput = {
      hasMedicalAttention: false,
      hasMedicalAttentionComment: deleteReason,
    };
    //LLama la funcion para actualizar la precondicion del dia
    await this.dayInstanceService.updateVerifiedDayInstanceByDeleteInstances(
      dayInstanceId,
      'medicalAttention',
      'verifiedMedicalAttention',
      medicalAttentionInput,
      this.medicalAttentionService.medicalAttentionTable,
      this.tableDayInstanceComponent
    );
  }
}
