import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { Subscription } from 'rxjs';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AppConfigService } from 'src/app/services/appconfigservice';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';
import { AppConfig } from '../../../domain/appconfig';
import { SubjectsService } from '../../subjects/subjects.service';
import { ReportService } from '../report.service';
import { InstanceState } from 'src/app/services/trialpal.types';
import { ReportProgramationType } from '../report.types';

const logger = new Logger('report-list');
@Component({
  selector: 'app-report-status',
  templateUrl: './report-status.component.html',
  styleUrls: ['./report-status.component.scss'],
})
export class ReportStatusComponent implements OnInit {
  adherence: any[] = [];
  basicOptions: any;
  config!: AppConfig;
  date: any[] = [];
  dateComplete: any[] = [];
  datePlaneate: any[] = [];
  day = 0;
  dayTotal = 0;
  filter = false;
  frequencies: any[] = [];
  frequency = '';
  month = 0;
  monthNamesShort: any[] = [];
  pending: string = '';
  programationTypes: any[] = [];
  projectId = '';
  report = '';
  reportInstances: any[] = [];
  reports: any[] = [];
  stackedData: any;
  stackedOptions: any;
  showGraph: boolean = false;
  subjects: any[] = [];
  subscription!: Subscription;
  title: string = '';
  title2: string = '';
  year = 0;
  graphTypes: any[] = [];
  graphType: string = '';
  isOnDemandReport: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly trialpalService: TrialpalService,
    private readonly reportService: ReportService,
    private configService: AppConfigService,
    private subjectsService: SubjectsService,
    private transformDatePipe: TransformDatePipe,
    private dictionaryPipe: DictionaryPipe,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.trialpalService.showSpinner('report.entityPlural', 'SEARCH');
    this.projectId = this.route.snapshot.params.projectId;
    this.reportService
      .getConfReportByProjectId(this.projectId)
      .then((confReports: any) => {
        if (confReports.length > 0) {
          logger.debug('listReport response', confReports);
          const currentConfReports = confReports.filter(
            (x: any) => !x._deleted
          );

          this.reports = currentConfReports.map((confReport: any) => {
            return {
              ...confReport,
              label: this.dictionaryPipe.transform(confReport.reportTitle),
              value: confReport.id,
            };
          });
          this.frequencies = this.reportService.getfrequency();
          this.monthNamesShort = this.trialpalService.translateService.instant(
            'primeng.monthNamesShort'
          );
          this.graphTypes = this.reportService.getGraphTypes();
          this.graphReport();
          this.trialpalService.hideSpinner();
        } else {
          this.trialpalService.hideSpinner();
          this.showViaService();
        }
      })
      .catch((error: Error) => {
        logger.error('listReport error', error);
        this.trialpalService.hideSpinner();
      })
      .finally();

    this.reportService
      .getProgramationTypes()
      .then((res) => (this.programationTypes = res));

    this.dayTotalCalculate();
  }

  reportMasterWithCurrentTime(): any {
    this.resetVariables();
    if (this.filter) {
      this.filterPendingCurrentFrecuency(this.reportInstances, this.frequency);
      this.dataTable = this.buildTPTableData(this.subjects);
    } else {
      this.filterPendingCompletedByFrecuency(
        this.reportInstances,
        this.frequency
      );
      this.dataTable = this.buildTPTableData(this.reportInstances);
    }
  }
  subjectsPendingReport(res: any): any {
    this.subjects = res.items.filter(
      (e: any) => e.state !== InstanceState.COMPLETED
    );
    this.removeRepeats();
  }
  removeRepeats(): any {
    const hash: any = {};
    this.subjects = this.subjects.filter((o: any) => {
      const isDuplicate = hash[o.subjectId];
      hash[o.subjectId] = true;
      return !isDuplicate;
    });
  }

  createTitle(report: string): string {
    report = this.dictionaryPipe.transform(report);
    return this.trialpalService.translateService.instant(
      'report.title.' + this.frequency,
      {
        entity: report,
      }
    );
  }
  createTitle2(report: string): string {
    report = this.dictionaryPipe.transform(report);
    this.pending = this.trialpalService.translateService.instant(
      'report.pending.' + this.frequency
    );
    return this.trialpalService.translateService.instant(
      'report.title2.' + this.frequency,
      {
        entity: report,
      }
    );
  }
  dayTotalCalculate(): any {
    const today = new Date();
    this.year = this.transformDatePipe.getYear(today);
    this.month = this.transformDatePipe.getMonth(today);
    this.day = this.transformDatePipe.getDay(today);
    this.dayTotal =
      this.year * 365 +
      this.transformDatePipe.getDaysInYear(this.year, this.month, this.day);
  }

  filterPendingCurrentFrecuency(response: any, range: any): void {
    if (range === 'Daily') {
      this.filterByDay(response, 0, 1, false);
    }
    if (range === 'Weekly') {
      this.filterByWeekly(response, 0, false);
    }
    if (range === 'Monthly') {
      this.filterByMonthly(response, 0, false);
    }
  }

  resetVariables(): void {
    if (this.filter === true) this.subjects = [];
    this.datePlaneate = [];
    this.dateComplete = [];
    this.subjects = [];
    this.date = [];
  }

  filterPendingCompletedByFrecuency(response: any, range: any): void {
    let d = 1;
    for (let i = 0; i < 15; i++) {
      if (range === 'Daily') {
        const planned = response.filter(
          (e: any) =>
            this.dailyReports(e, this.dayTotal, i * d) ||
            (e.state === ReportProgramationType.ON_DEMAND && e.i === i)
        ).length;
        const completed = response.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.dailyReports(e, this.dayTotal, i * d)
        ).length;
        this.filterByDay(response, i, d, true);
        const dayName = this.getDayName(
          this.month - 1,
          this.day - i,
          this.year
        );
        this.pushValues(planned, completed, dayName);
      } else if (range === 'Weekly') {
        const planned = response.filter(
          (e: any) =>
            this.weeklyReports(e, this.year, i) ||
            (e.state === ReportProgramationType.ON_DEMAND && e.i === i)
        ).length;
        const completed = response.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.weeklyReports(e, this.year, i)
        ).length;
        this.filterByWeekly(response, i, true);
        const weeklyName = this.getWeeklyName(this.weeyOfYear() - i);
        this.pushValues(planned, completed, weeklyName);
      } else if (range === 'Monthly') {
        const planned = response.filter(
          (e: any) =>
            this.monthlyReports(e, this.year, this.month, i) ||
            (e.state === ReportProgramationType.ON_DEMAND && e.i === i)
        ).length;
        const completed = response.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.monthlyReports(e, this.year, this.month, i)
        ).length;
        this.filterByMonthly(response, i, true);
        const monthName = this.getMonthName(this.month - i - 1, this.year);
        this.pushValues(planned, completed, monthName);
      }
      this.removeRepeats();
    }
  }

  filterByDay(response: any, i: any, d: any, filter: boolean): any {
    this.subjects = this.subjects.concat(
      response.filter(
        (e: any) =>
          (e.state !== InstanceState.COMPLETED &&
            this.dailyReports(e, this.dayTotal, i * d)) ||
          (e.state === ReportProgramationType.ON_DEMAND && e.i === i)
      )
    );

    if (this.filter === true && filter === false) {
      this.removeRepeats();
    }
  }

  filterByWeekly(response: any, i: any, filter: boolean): any {
    this.subjects = this.subjects.concat(
      response.filter(
        (e: any) =>
          (e.state !== InstanceState.COMPLETED &&
            this.weeklyReports(e, this.year, i)) ||
          (e.state === ReportProgramationType.ON_DEMAND && e.i === i)
      )
    );

    if (this.filter === true && filter === false) {
      this.removeRepeats();
    }
  }

  filterByMonthly(response: any, i: any, filter: boolean): any {
    this.subjects = this.subjects.concat(
      response.filter(
        (e: any) =>
          (e.state !== InstanceState.COMPLETED &&
            this.monthlyReports(e, this.year, this.month, i)) ||
          (e.state === ReportProgramationType.ON_DEMAND && e.i === i)
      )
    );
    if (this.filter === true && filter === false) {
      this.removeRepeats();
    }
  }

  getMonthName(month: number, year: number): any {
    let posMonth = month;
    if (month < 0) {
      posMonth = month >= -12 ? 12 - Math.abs(month) : 24 - Math.abs(month);
    }

    let isYear = year;
    if (month < 0) {
      isYear = month >= -12 ? year - 1 : year - 2;
    }

    return this.monthNamesShort[posMonth] + '-' + isYear;
  }

  getDayName(month: number, day: number, year: number): any {
    return day > 0
      ? day + '-' + this.getMonthName(month, year)
      : new Date(year, month, 0).getDate() -
          Math.abs(day) +
          '-' +
          this.getMonthName(month - 1, year);
  }

  getWeeklyName(weekly: any): any {
    return weekly > 0
      ? this.trialpalService.translateService.instant('primeng.weekHeader') +
          ' ' +
          weekly
      : this.trialpalService.translateService.instant('primeng.weekHeader') +
          ' ' +
          (52 - Math.abs(weekly));
  }

  dailyReports(e: any, dayTotal: any, i: any): any {
    const year = this.transformDatePipe.getYear(e.plannedDate);
    const month = this.transformDatePipe.getMonth(e.plannedDate);
    const day = this.transformDatePipe.getDay(e.plannedDate);
    const v =
      dayTotal -
      (year * 365 + this.transformDatePipe.getDaysInYear(year, month, day));
    return v === i;
  }

  weeklyReports(e: any, year: any, i: any): boolean {
    const v = this.transformDatePipe.getYear(e.plannedDate);
    return (
      this.weeyOfYear(e.plannedDate) === this.weeyOfYear() - i &&
      year === v &&
      this.numberDay(e) <= this.dayTotal
    );
  }

  monthlyReports(e: any, year: any, month: any, i: any): boolean {
    const yearReport = this.transformDatePipe.getYear(e.plannedDate);
    const monthReport = this.transformDatePipe.getMonth(e.plannedDate);

    if (month - i > 0) {
      return (
        yearReport === year &&
        monthReport === month - i &&
        this.numberDay(e) <= this.dayTotal
      );
    } else if (month - i > -12) {
      return (
        yearReport === year - 1 &&
        monthReport === 12 - Math.abs(month - i) &&
        this.numberDay(e) <= this.dayTotal
      );
    } else {
      return (
        yearReport === year - 2 &&
        monthReport === 24 - Math.abs(month - i) &&
        this.numberDay(e) <= this.dayTotal
      );
    }
  }

  numberDay(e: any): number {
    const year = this.transformDatePipe.getYear(e.startDate);
    const month = this.transformDatePipe.getMonth(e.startDate);
    const day = this.transformDatePipe.getDay(e.startDate);
    return year * 365 + this.transformDatePipe.getDaysInYear(year, month, day);
  }

  weeyOfYear(date?: any): any {
    const d = date ? new Date(date) : new Date();
    return this.transformDatePipe.WeekOfYear(d);
  }

  pushValues(planned: any, completed: any, frecuencyName: any): void {
    this.datePlaneate.push(planned - completed);
    this.dateComplete.push(completed);
    this.date.push(frecuencyName);
    const adherence = (completed / planned) * 100;
    this.adherence.push(adherence >= 0 ? adherence : 0);
  }

  updateChartOptions() {
    if (this.config.dark) this.applyDarkTheme();
    else this.applyLightTheme();
  }

  applyDarkTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#ebedef',
        },
      },
      scales: {
        xAxes: {
          ticks: {
            fontColor: '#ebedef',
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
        yAxes: {
          ticks: {
            fontColor: '#ebedef',
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
    };
    this.stackedOptions.scales.xAxes.ticks = {
      fontColor: '#ebedef',
    };
    this.stackedOptions.scales.xAxes.gridLines = {
      color: 'rgba(255,255,255,0.2)',
    };
    this.stackedOptions.scales.yAxes.ticks = {
      fontColor: '#ebedef',
    };
    this.stackedOptions.scales.yAxes.gridLines = {
      color: 'rgba(255,255,255,0.2)',
    };
    this.stackedOptions.scales.yAxes.ticks = {
      fontColor: '#ebedef',
    };
    this.stackedOptions.scales.yAxes.gridLines = {
      color: 'rgba(255,255,255,0.2)',
    };
    this.stackedOptions.legend = {
      labels: {
        fontColor: '#ebedef',
      },
    };
    this.stackedOptions = { ...this.stackedOptions };
  }
  applyLightTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#495057',
        },
      },
      scales: {
        xAxes: {
          ticks: {
            fontColor: '#495057',
          },
        },

        yAxes: {
          ticks: {
            fontColor: '#495057',
          },
        },
      },
    };

    this.stackedOptions.scales.xAxes.ticks = {
      fontColor: '#495057',
    };
    this.stackedOptions.scales.xAxes.gridLines = {
      color: '#ebedef',
    };
    this.stackedOptions.scales.yAxes1.ticks = {
      fontColor: '#495057',
    };
    this.stackedOptions.scales.yAxes1.gridLines = {
      color: '#ebedef',
    };
    this.stackedOptions.legend = {
      labels: {
        fontColor: '#495057',
      },
    };
    this.stackedOptions = { ...this.stackedOptions };
  }
  graphReport(): void {
    this.stackedData = {
      labels: [...this.date].reverse(),
      datasets: [
        {
          type: 'line',
          label: this.trialpalService.translateService.instant(
            'report.chart.Adherence'
          ),
          borderColor: '#FFA726',
          borderWidth: 2,
          fill: false,
          data:
            this.graphType === 'TOTALS'
              ? []
              : [100, ...this.adherence].reverse(),
        },
        {
          type: this.graphType !== 'TOTALS' ? 'bar' : 'line',
          yAxisID: 'yAxes1',
          label: this.trialpalService.translateService.instant(
            'report.chart.Received'
          ),
          borderColor: '#42A5F5',
          backgroundColor: '#42A5F5',
          data: [...this.dateComplete].reverse(),
        },
        {
          type: 'bar',
          yAxisID: 'yAxes1',
          label: this.trialpalService.translateService.instant(
            'report.chart.Pending'
          ),
          backgroundColor: '#E85145',
          data: [...this.datePlaneate].reverse(),
        },
      ],
    };

    if (this.graphType === 'TOTALS') {
      this.stackedData.datasets.splice(0, 1);
      this.stackedData.datasets.splice(1, 1);
    }

    this.stackedOptions = {
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: true,

      scales: {
        y:
          this.graphType !== 'TOTALS'
            ? {
                display: true,
                title: {
                  display: true,
                  text: this.trialpalService.translateService.instant(
                    'report.chart.Adherence'
                  ),
                  color: '#FFA726',
                  font: {
                    size: 15,
                    weight: 'bold',
                    lineHeight: 1.2,
                  },
                  padding: { top: 20, left: 0, right: 0, bottom: 0 },
                },
              }
            : {},
        xAxes: {
          stacked: true,
        },
        yAxes1: {
          stacked: true,
          type: 'linear',
          display: true,
          position: 'right',
          title: {
            display: true,
            text:
              this.graphType !== 'TOTALS'
                ? this.trialpalService.translateService.instant(
                    'report.chart.Pending'
                  ) +
                  ' - ' +
                  this.trialpalService.translateService.instant(
                    'report.chart.Received'
                  )
                : this.trialpalService.translateService.instant(
                    'report.chart.Received'
                  ),
            color: '#098392',
            font: {
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: { top: 20, left: 0, right: 0, bottom: 0 },
          },
        },
      },
    };
    this.config = this.configService.config;
    this.updateChartOptions();
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
      this.updateChartOptions();
    });
  }

  lastReportedDate(id: any): any {
    const values = this.reportInstances.filter(
      (e: any) =>
        e.subject?.id === id &&
        e.state === InstanceState.COMPLETED &&
        e.completeDate
    );
    let lastReportedDate = {
      label: this.trialpalService.translateService.instant(
        'report.noReportedDays'
      ),
      value: '0',
    };
    let time = 0;
    for (const p of values) {
      const ReportdayTotal = this.reportDayTotal(p.plannedDate);
      if (ReportdayTotal > time) {
        time = ReportdayTotal;
        lastReportedDate = {
          label:
            this.trialpalService.translateService.instant('report.lastReport') +
            ': ' +
            this.transformDatePipe.transform(p.plannedDate),
          value: p.plannedDate,
        };
      }
    }
    return lastReportedDate;
  }
  daysWithoutReporting(dayReport: any, state: boolean): any {
    return state === true
      ? this.dayTotal - this.reportDayTotal(dayReport)
      : '0';
  }
  reportDayTotal(response: any): any {
    const Reportyear = this.transformDatePipe.getYear(response);
    const Reportmonth = this.transformDatePipe.getMonth(response);
    const Reportday = this.transformDatePipe.getDay(response);
    return (
      Reportyear * 365 +
      this.transformDatePipe.getDaysInYear(Reportyear, Reportmonth, Reportday)
    );
  }
  showViaService() {
    this.trialpalService.messageService.add({
      severity: 'info',
      summary: this.trialpalService.translateService.instant(
        'project.labelReportsActivated'
      ),
      detail: this.trialpalService.translateService.instant('general.noData'),
    });
  }
  isDisabledSearchButton() {
    if (this.isOnDemandReport && !this.graphType) {
      return true;
    }
    return !this.report || !this.frequency;
  }
  async onSubmit(form: NgForm) {
    if (form.valid) {
      this.trialpalService.showSpinner('report.entityPlural', 'GET');
      this.reportInstances = [];
      this.filter = false;
      this.resetVariables();
      const currentReport = this.getCurrenReport(this.reports, this.report);
      this.createTitles(currentReport);
      await this.verifyReportProgramationType(currentReport);
      this.filterPendingCompletedByFrecuency(
        this.reportInstances,
        this.frequency
      );
      this.graphReport();
      this.dataTable = this.buildTPTableData(this.reportInstances);
      if (this.reportInstances.length === 0) {
        this.showViaService();
      }
      this.trialpalService.hideSpinner();
      this.showGraph = true;
    }
  }
  getCurrenReport(reports: any, currentReport: any) {
    return reports.find((report: any) => report.value === currentReport);
  }
  createTitles(report: any) {
    report = report ? report.label : '';
    this.title = this.createTitle(report);
    this.title2 = this.createTitle2(report);
  }
  async verifyReportProgramationType(report: any) {
    return report.programationType === ReportProgramationType.ON_DEMAND &&
      this.graphType === 'ADHERENCE'
      ? this.onDemandReports(report)
      : this.getReportInstanceByConfReport();
  }
  async getReportInstanceByConfReport() {
    this.reportInstances =
      await this.reportService.reportInstanceByConfReportId(this.report);
    logger.debug('listReport response', this.reportInstances);
  }

  async onDemandReports(report: any) {
    let subjects = await this.subjectsService.getSubjectsByProjectId(
      this.projectId
    );
    subjects = await this.filterSubjectByControlledBySite(subjects, report);
    for (let subject of subjects) {
      let reports = await this.reportService.reportInstanceBySubjectId(
        subject.id,
        report.id
      );
      if (reports) {
        reports = this.validateNoCompletedReport(
          reports,
          this.frequency,
          subject
        );
        this.reportInstances = this.reportInstances.concat(reports);
      }
    }
  }
  async filterSubjectByControlledBySite(subjects: any, report: any) {
    if (report.isControlledBySite) {
      let subjectsEnabled = [];
      for (let subject of subjects) {
        let isConfFormEnabled = await this.getConfReportsBySubjectId(
          subject.id,
          report.id
        );
        if (isConfFormEnabled) {
          subjectsEnabled.push(subject);
        }
      }
      return subjectsEnabled;
    }
    return subjects;
  }
  validateNoCompletedReport(response: any, range: any, subject: any): any {
    let reports: any[] = [];
    this.filterPendingCurrentFrecuency(response, range);
    for (let i = 0; i < 15; i++) {
      let completed = [];
      let plannedName = '';
      if (range === 'Daily') {
        completed = response.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.dailyReports(e, this.dayTotal, i)
        );
        plannedName = this.getDayName(this.month - 1, this.day - i, this.year);
      } else if (range === 'Weekly') {
        completed = response.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.weeklyReports(e, this.year, i)
        );
        plannedName = this.getWeeklyName(this.weeyOfYear() - i);
      } else if (range === 'Monthly') {
        completed = response.filter(
          (e: any) =>
            e.state === InstanceState.COMPLETED &&
            this.monthlyReports(e, this.year, this.month, i)
        );
        plannedName = this.getMonthName(this.month - i - 1, this.year);
      }
      const currentReports = this.validateReport(
        completed,
        subject,
        i,
        plannedName
      );
      reports = reports.concat(currentReports);
    }
    return reports;
  }
  validateReport(completed: any, subject: any, i: any, plannedName: any) {
    if (completed.length === 0) {
      const report = {
        i: i,
        state: ReportProgramationType.ON_DEMAND,
        subjectId: subject.id,
        subject: {
          id: subject.id,
          subjectNumber: subject.subjectNumber,
          currentScheduledPhase: {
            phase: subject?.currentScheduledPhase?.phase,
          },
        },
        onDemandPlannedDate: plannedName,
      };
      return report;
    } else {
      return completed[0];
    }
  }
  async getConfReportsBySubjectId(
    subjectId: string,
    confReportId: string
  ): Promise<any> {
    const confReportBySubjectIdItems =
      await this.reportService.getConfReportBySubjectId(subjectId);
    if (confReportBySubjectIdItems) {
      const findConfReports = confReportBySubjectIdItems.find(
        (confReportsBySubject: any) =>
          confReportsBySubject.confReportId === confReportId
      );
      if (!findConfReports?.isEnabled) {
        return false;
      } else if (findConfReports?.isEnabled) {
        return true;
      }
    }
  }
  buildTPTableData(reportInstances: any): TPTableData {
    reportInstances = reportInstances.filter((item: any, index: any) => {
      return reportInstances.indexOf(item) === index;
    });
    let newSymptomInstance = reportInstances.map((reportInstance: any) => {
      const ultimateDay = this.lastReportedDate(reportInstance.subjectId);
      return {
        ...reportInstance,
        completedDate: this.getCompletedDateByreportInstance(reportInstance),
        plannedDate: this.getPlannedDateByReportInstance(reportInstance),
        subjectNumber: reportInstance?.subject?.subjectNumber,
        currentPhase:
          this.trialpalService.dictionaryPipe.transform(
            reportInstance?.subject?.currentScheduledPhase?.phase
          ) || '',
        state: this.getReportInstanceState(reportInstance),
        lastReportedDate: ultimateDay.label,
        ultimateDay: this.getDaysWithoutReport(ultimateDay),
        totalReports: this.getTotalReports(
          reportInstance?.subject?.subjectNumber,
          reportInstances
        ),
      };
    });

    return {
      valueTable: newSymptomInstance,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: [
        'subjectNumber',
        'currentPhase',
        'state',
        'startDate',
        'completedDate',
      ],
      menuOptions: [],
      cols: [
        {
          header:
            this.trialpalService.translateService.instant('subject.entity'),
          type: TypeInput.TEXT,
          field: 'subjectNumber',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header:
            this.trialpalService.translateService.instant('subject.labelVisit'),
          type: TypeInput.TEXT,
          field: 'currentPhase',
          showBasicSearch: true,
          showFilterComplete: false,
        },

        {
          header:
            this.trialpalService.translateService.instant('subject.labelState'),
          type: TypeInput.TEXT,
          field: 'state',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.plannedDate'
          ),
          type: TypeInput.TEXT,
          field: 'plannedDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.completedDate'
          ),
          type: TypeInput.TEXT,
          field: 'completedDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header:
            this.trialpalService.translateService.instant('subject.lastReport'),
          type: TypeInput.TEXT,
          field: 'lastReportedDate',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.formsWithoutReporting'
          ),
          type: TypeInput.TEXT,
          field: 'ultimateDay',
          showBasicSearch: true,
          showFilterComplete: false,
        },
        {
          header: this.trialpalService.translateService.instant(
            'subject.totalReport'
          ),
          type: TypeInput.TEXT,
          field: 'totalReports',
          showBasicSearch: true,
          showFilterComplete: false,
        },
      ],
    };
  }
  getReportInstanceState(reportInstance: any) {
    if (reportInstance.state === ReportProgramationType.ON_DEMAND) {
      return (
        this.trialpalService.translateService.instant(
          'general.instanceState.' + 'PENDING'
        ) || ''
      );
    }
    return (
      this.trialpalService.translateService.instant(
        'general.instanceState.' + reportInstance.state
      ) || ''
    );
  }
  getPlannedDateByReportInstance(reportInstance: any) {
    if (reportInstance.state === ReportProgramationType.ON_DEMAND) {
      return reportInstance.onDemandPlannedDate;
    }
    return reportInstance.plannedDate
      ? this.transformDatePipe.transform(reportInstance.plannedDate)
      : 'N/A';
  }
  getCompletedDateByreportInstance(reportInstance: any) {
    return reportInstance.completeDate
      ? this.transformDatePipe.transformWithHour(reportInstance.completeDate)
      : 'N/A';
  }
  getDaysWithoutReport(ultimateDay: any) {
    let daysWithoutReport = 'N/A';
    if (ultimateDay.value !== '0') {
      daysWithoutReport =
        this.daysWithoutReporting(ultimateDay.value, true) +
        ' ' +
        this.trialpalService.translateService.instant(
          'report.daysWithoutReporting'
        );
    }
    return daysWithoutReport;
  }
  getTotalReports(subjectNumber: string, reportInstances: any) {
    return reportInstances.filter(
      (report: any) =>
        report.subject?.subjectNumber === subjectNumber &&
        report.state === InstanceState.COMPLETED
    ).length;
  }
  getReportType() {
    const currentReport = this.getCurrenReport(this.reports, this.report);
    this.isOnDemandReport =
      currentReport.programationType === ReportProgramationType.ON_DEMAND;
    if (!this.isOnDemandReport) this.graphType = '';
  }
  goDetailSubject(subject: any): void {
    this.router.navigate(['subjects', subject.subjectId, 'detail']);
  }
}
