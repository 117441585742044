import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Analytics, Logger } from 'aws-amplify';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserState } from 'src/app/services/auth.service.queries';
import { CardOrTableService } from 'src/app/services/card-or-table.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  EDiaryPhaseInstance,
  TP2UserInput,
} from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from './tpcardData';

const logger = new Logger('tp2-logger-tpCardPage');

@Component({
  selector: 'app-tpcard',
  templateUrl: './tpcard.component.html',
  styleUrls: ['./tpcard.component.scss'],
})
export class TPCardComponent implements OnInit, AfterViewInit {
  traductorTPCardComponent: any = {};
  isCardSelected = false;
  items: MenuItem[] = [];
  short = 80;
  calculateHeight = '220px';
  @Input() tpCardData: TPCardData = {};
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() activate = new EventEmitter<any>();
  @Output() detail = new EventEmitter<any>();
  @Output() customAction = new EventEmitter<any>();
  @Output() customAction2 = new EventEmitter<any>();
  @Output() customAction3 = new EventEmitter<any>();
  eDiaryPhaseInstance!: EDiaryPhaseInstance;

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService: UserService,
    private translateService: TranslateService,
    private trialpalService: TrialpalService,
    private ediaryService: EdiaryService,
    public cardOrTableService: CardOrTableService,
    public userPermissionsService: UserPermissionsService
  ) {}
  public ngAfterViewInit(): void {
    this.detectScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectScreenSize();
  }
  private detectScreenSize() {
    const height = window.innerHeight;
    if (height > 900) {
      this.short = 120;
      Promise.resolve().then(() => (this.calculateHeight = '180px'));
    } else if (height > 800) {
      this.short = 100;
      this.calculateHeight = '220px';
    } else {
      this.short = 80;
      this.calculateHeight = '220px';
    }
  }

  async ngOnInit() {
    this.translateService.get('TPCardComponent').subscribe((data: any) => {
      this.traductorTPCardComponent = data;
    });
    this.getMenuButtons();
  }

  onEdit(): void {
    this.edit.emit(this.tpCardData.data);
  }

  async onDelete() {
    logger.debug('onDelete', this.tpCardData);
    const key = this.tpCardData.isFeminine
      ? 'TPCardComponent.messageConfirmDeleteFem'
      : 'TPCardComponent.messageConfirmDelete';
    let message =
      this.trialpalService.toUpperCaseFirstLetter(
        this.translateService.instant(key, {
          entityName: this.tpCardData.entityAction,
        })
      ) +
      this.trialpalService.dictionaryPipe.transform(
        this.tpCardData.objectAction ?? ''
      );

    const items = await this.symptomConfForm();

    //Verifica si es un sintoma y si este tiene un formulario asociado
    if (items.length !== 0) {
      this.showMessageErrorDeleteSymptom();
      return;
    }
    this.confirmationService.confirm({
      message,
      accept: () => {
        this.delete.emit(this.tpCardData.data);
      },
    });
  }

  //Verifica si la entidad a borrar es un sintoma y si este, está asociado a un formulario
  async symptomConfForm() {
    let items: any[] = [];
    if (this.tpCardData.data.__typename === 'ConfSymptom') {
      items = await this.ediaryService.getConfFormByConfSymptom(
        this.tpCardData.data.id
      );
    }

    return items;
  }

  onActivate(): void {
    let message;
    if (this.tpCardData.stateActivateButton) {
      message =
        this.translateService.instant(
          'TPCardComponent.messageConfirmDeactivate',
          {
            entityName: this.tpCardData.entityAction,
          }
        ) + this.tpCardData.objectAction;
    } else {
      message =
        this.translateService.instant('TPCardComponent.messageConfirmActive', {
          entityName: this.tpCardData.entityAction,
        }) + this.tpCardData.objectAction;
    }
    this.confirmationService.confirm({
      message,

      accept: () => {
        if (this.tpCardData.entityAction === 'User') {
          Analytics.record({
            name: 'site_user_change_status',
            attributes: {
              action: this.tpCardData.stateActivateButton
                ? 'deactivate'
                : 'activate',
              userSub: this.authService.user?.attributes?.sub,
              userName: this.authService.user?.username,
            },
          });
        }
        this.activate.emit(this.tpCardData.data);
      },
    });
  }

  getActiveEmitter() {
    return this.activate;
  }
  onDetail(): void {
    this.detail.emit(this.tpCardData.data);
  }

  onCustomAction(): void {
    if (this.tpCardData.messageConfirmCustomButton) {
      this.confirmationService.confirm({
        message:
          (this.tpCardData.messageConfirmCustomButton
            ? this.tpCardData.messageConfirmCustomButton
            : '') +
          (this.tpCardData.objectAction ? this.tpCardData.objectAction : ''),
        accept: () => {
          this.customAction.emit(this.tpCardData.data);
        },
      });
    } else {
      this.customAction.emit(this.tpCardData.data);
    }
  }
  onCustomAction2(): void {
    if (this.tpCardData.messageConfirmCustomButton2) {
      this.confirmationService.confirm({
        message:
          (this.tpCardData.messageConfirmCustomButton2
            ? this.tpCardData.messageConfirmCustomButton2
            : '') +
          (this.tpCardData.objectAction
            ? ' ' + this.tpCardData.objectAction
            : ''),
        accept: () => {
          if (
            this.tpCardData.messageConfirmCustomButton2 ===
            this.trialpalService.translateService.instant('general.generatePDF')
          ) {
            const tpCardData: any = { ...this.tpCardData };
            Analytics.record({
              name: 'site_pdf_generation',
              attributes: {
                type: tpCardData?.__typename || tpCardData?.data?.__typename,
                userSub: this.authService.user?.attributes?.sub,
                userName: this.authService.user?.username,
              },
            });
          }
          this.customAction2.emit(this.tpCardData.data);
        },
      });
    } else {
      this.customAction2.emit(this.tpCardData.data);
    }
  }
  onCustomAction3(): void {
    if (this.tpCardData.messageConfirmCustomButton3) {
      this.confirmationService.confirm({
        message:
          (this.tpCardData.messageConfirmCustomButton3
            ? this.tpCardData.messageConfirmCustomButton3
            : '') +
          (this.tpCardData.objectAction ? this.tpCardData.objectAction : ''),
        accept: () => {
          this.customAction3.emit(this.tpCardData.data);
        },
      });
    } else {
      this.customAction3.emit(this.tpCardData.data);
    }
  }

  resetPasswordUser() {
    const user = this.tpCardData.data;
    this.confirmationService.confirm({
      message: this.trialpalService.toUpperCaseFirstLetter(
        this.translateService.instant('user.messageConfirmResetPassword', {
          user: user.name,
        })
      ),
      accept: async () => {
        await this.validateUserRol();
      },
    });
  }

  async validateUserRol() {
    const user = this.tpCardData.data;
    // Tomar solo el primer rol del usuario
    const roles = user.role.split(',')[0];

    // Roles que un coordinador y/o gestor de usuarios puede resetear la contraseña
    const allowedRoles = ['Investigator', 'Subject', 'Reader'];

    if (
      (await this.userPermissionsService.hasPermission([
        TP2Permission.UserResetPassword,
      ])) ||
      this.authService.isAdmin() ||
      (this.authService.isCoordinator() && allowedRoles.includes(roles))
    ) {
      this.sendPasswordTemporary();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant(
          'user.messageErrorServerDeleteUser.messageErrorOperation.detail'
        ),
        detail: this.trialpalService.translateService.instant(
          'user.messageErrorRole'
        ),
      });
    }
  }

  async sendPasswordTemporary() {
    this.trialpalService.showSpinner(
      this.translateService.instant('password.sendTemporaryPassword')
    );
    const user: TP2UserInput = {
      login: this.tpCardData.data.login,
      name: this.tpCardData.data.name,
      email: this.tpCardData.data.email,
      role: this.tpCardData.data.role,
      phoneNumber: this.tpCardData.data.phoneNumber,
      state: this.tpCardData.data.state,
    };

    await this.userService
      .changePassword(user)
      .then((_data) => {
        Analytics.record({
          name: 'site_user_recover_password',
          attributes: {
            userSub: this.authService.user?.attributes?.sub,
            userName: this.authService.user?.username,
          },
        });
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant(
            'password.messageSuccessSendCode.summary'
          ),
          detail: this.translateService.instant(
            'password.messageSuccessSendTemporaryPassword'
          ),
        });
      })
      .catch((_err) => {
        logger.error('Error sendPasswordTemporary', _err);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant(
            'password.messageErrorSendCode.summary'
          ),
          detail: this.translateService.instant(
            'password.messageErrorSendCode.detail'
          ),
        });
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  getImage(ignoreBaseRoute?: boolean): string {
    return ignoreBaseRoute
      ? this.tpCardData.image2 + ''
      : '/../assets/' + this.tpCardData.image;
  }

  getShortText(item?: any, cant: any = 20): string {
    if (item) {
      const itemString = item.toString();
      return itemString.length <= 33
        ? itemString
        : itemString.substring(0, cant) + '...';
    } else {
      return '';
    }
  }
  needToolTip(item?: any): boolean {
    if (item) {
      const itemString = item.toString();
      return itemString.length > 25;
    } else {
      return false;
    }
  }
  getMenuButtons() {
    this.items = [];
    let labelStateActivateButton = '';
    let iconStateActivateButton = '';

    this.getActiveEmitter().subscribe((data: any) => {
      if (data.state === UserState.DISABLED) {
        logger.debug('estado', data.state);

        labelStateActivateButton = this.translateService.instant(
          'user.buttonInactivate'
        );
        iconStateActivateButton = 'pi pi-ban';
        this.removeResetPasswordButton();
      } else if (data.state === UserState.ENABLED) {
        labelStateActivateButton =
          this.translateService.instant('user.buttonActive');
        iconStateActivateButton = 'pi pi-check ';
        this.pushResetPasswordButton();
      }
      this.items[0] = {
        label: labelStateActivateButton,
        icon: iconStateActivateButton,
        command: () => {
          this.onActivate();
        },
      };
    });
    if (this.tpCardData.showActivateButton) {
      if (this.tpCardData.stateActivateButton) {
        labelStateActivateButton = this.translateService.instant(
          'user.buttonInactivate'
        );
        iconStateActivateButton = 'pi pi-ban';
      } else {
        labelStateActivateButton =
          this.translateService.instant('user.buttonActive');
        iconStateActivateButton = 'pi pi-check ';
      }
      this.items.push({
        label: labelStateActivateButton,
        icon: iconStateActivateButton,
        command: () => {
          this.onActivate();
        },
      });
    }

    if (this.tpCardData.showEditButton) {
      this.items.push({
        label: this.translateService.instant('user.buttonEdit'),
        icon: 'pi pi-pencil',
        command: () => {
          this.onEdit();
        },
      });
    }
    if (this.tpCardData.showDeleteButton) {
      this.items.push({
        label: this.translateService.instant('user.buttonDelete'),
        icon: 'pi pi-trash',
        command: () => {
          this.onDelete();
        },
      });
    }
    if (this.tpCardData.showCustomButton) {
      this.items.push({
        label: '',
        icon: this.tpCardData.iconCustomButton,
        command: () => {
          this.onCustomAction();
        },
      });
    }
    if (this.tpCardData.showAuditButton) {
      this.items.push({
        label: this.translateService.instant('general.btnAuditTrace'),
        icon: 'pi pi-file',
        command: () => {
          this.cardOrTableService.openAuditModal(this.tpCardData);
        },
      });
    }
    if (this.tpCardData.showResetPasswordButton) {
      this.pushResetPasswordButton();
    }
  }

  pushResetPasswordButton() {
    this.items.push({
      label: this.translateService.instant('user.resetPassword'),
      icon: 'pi pi-key',
      command: () => {
        this.resetPasswordUser();
      },
    });
  }

  removeResetPasswordButton() {
    const index = this.items.findIndex(
      (x: any) =>
        x.label === this.translateService.instant('user.resetPassword')
    );
    this.items.splice(index, 1);
  }

  /**
   * @desc Mensaje de error de eliminacion sintoma
   */
  private showMessageErrorDeleteSymptom() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.errorDeletingSymptom.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.errorDeletingSymptom.detail'
      ),
    });
  }
}
