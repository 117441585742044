export const PROJECT_QUERIES = {
  GetProject: `query GetProject($id: ID!) {
        getProject(id: $id) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          isSubjectNumberCheckRequired
          subjectNumberRegex
          subjectNumberPlaceholder
          checkSiteCodeInSubjectNumber
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ListProjects: `query ListProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            isSubjectNumberCheckRequired
            subjectNumberRegex
            subjectNumberPlaceholder
            checkSiteCodeInSubjectNumber
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ListSites: `query ListSites($filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  SitesByProjectId: `query SitesByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        SitesByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  GetSymptomInstance: `query GetSymptomInstance($id: ID!) {
        getSymptomInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateProject: `mutation CreateProject($input: CreateProjectInput!, $condition: ModelProjectConditionInput) {
        createProject(input: $input, condition: $condition) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          isSubjectNumberCheckRequired
          subjectNumberRegex
          subjectNumberPlaceholder
          checkSiteCodeInSubjectNumber
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  DeleteProject: `mutation DeleteProject($input: DeleteProjectInput!, $condition: ModelProjectConditionInput) {
        deleteProject(input: $input, condition: $condition) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          isSubjectNumberCheckRequired
          subjectNumberRegex
          subjectNumberPlaceholder
          checkSiteCodeInSubjectNumber
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  UpdateProject: `mutation UpdateProject($input: UpdateProjectInput!, $condition: ModelProjectConditionInput) {
        updateProject(input: $input, condition: $condition) {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          accessibilityGroups {
            __typename
            group
            isActive
          }
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          termsAndConditions {
            __typename
            termsAndConditions
            termsAndConditionsVersion
            termsAndConditionsVersionDate
            policies
            policiesVersion
            policiesVersionDate
          }
          isICActivated
          isChatActivated
          isMFAActivated
          isSubjectNumberCheckRequired
          subjectNumberRegex
          subjectNumberPlaceholder
          checkSiteCodeInSubjectNumber
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfVisitGroupByProjectId: `query ConfVisitGroupByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfVisitGroupFilterInput, $limit: Int, $nextToken: String) {
        ConfVisitGroupByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            visit
            group
            isVisible
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  CreateConfVisitGroup: `mutation CreateConfVisitGroup($input: CreateConfVisitGroupInput!, $condition: ModelConfVisitGroupConditionInput) {
        createConfVisitGroup(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  UpdateConfVisitGroup: `mutation UpdateConfVisitGroup($input: UpdateConfVisitGroupInput!, $condition: ModelConfVisitGroupConditionInput) {
        updateConfVisitGroup(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  DeleteConfVisitGroup: `mutation DeleteConfVisitGroup($input: DeleteConfVisitGroupInput!, $condition: ModelConfVisitGroupConditionInput) {
        deleteConfVisitGroup(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          visit
          group
          isVisible
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
};
