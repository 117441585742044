import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectService } from 'src/app/modules/project/project.service';
import { ReportService } from 'src/app/modules/report/report.service';
import { SitesService } from 'src/app/modules/sites/sites.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';
import { emailRegex, phoneRegex } from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import {
  CreateConfAlertInput,
  Intensity,
  IntensityType,
} from '../../ediary.types';
import { AlertRuleType, AlertType } from 'src/app/modules/report/report.types';
import { ConfVisitGroup } from 'src/app/modules/project/project.types';
const logger = new Logger('tp2-logger-alertsAddEditPage');
@Component({
  selector: 'app-alert-add-edit',
  templateUrl: './alert-add-edit.component.html',
  styleUrls: ['./alert-add-edit.component.scss'],
})
export class AlertAddEditComponent implements OnInit {
  @ViewChild('f') formulario!: NgForm;

  confAlert: CreateConfAlertInput = {
    projectId: this.projectService.currentProject?.id || '',
    alertName: '',
    type: AlertType.EMAIL,
    recipients: [],
    mailSubject: '',
    alertRules: [],
    _lastUser: '',
    triggerVisitCompletion: undefined,
    visitList: [],
    siteRecipients: [],
    triggerEDiarySuspension: undefined,
    suspendVisitList: [],
  };
  confAlertCopy: CreateConfAlertInput = {
    projectId: this.projectService.currentProject?.id || '',
    alertName: '',
    type: AlertType.EMAIL,
    recipients: [],
    mailSubject: '',
    alertRules: [],
    _lastUser: '',
    triggerVisitCompletion: undefined,
    siteRecipients: [],
    triggerEDiarySuspension: undefined,
  };
  alertTypes: any[] = [];
  alertConditionTypes: any[] = [];
  relatedEntityId: any = null;
  relatedEntity: string = '';
  groups: any[] = [];
  isEdition = false;
  translate: any;
  translateGeneral: any;
  private id: any = null;
  expectedVersion: number = 1;
  recipient: any = null;
  isNewId: number = 0;
  activateRequired = false;

  symptoms: any[] = [];
  confSymptoms: Array<any> = [];
  intensityTypes: any[] = [];
  currentAlertRules: any[] = [];
  currentIntensity = '';
  currentSymptom = '';
  isPass = true;

  dictionary: any[] = [];
  results: string[] = [];
  projectId: string = '';
  isSpanishName: string = '';
  isEnglishName: string = '';
  isSpanishAffair: string = '';
  isEnglishAffair: string = '';
  isSpanishSms: string = '';
  isEnglishSms: string = '';

  temperatureUnits: any[] = [];
  visitsOptions: any[] = [];
  selectedVisits: any[] = [];
  suspenVisits: any[] = [];
  sites: any[] = [];
  currentSites: any[] = [];
  filterSymptomIntensityIn: any[] = [];
  filterSymptomNumericOrRange: any[] = [];
  filterSymptoms: any[] = []; // no contiene otros sintomas
  listVisitGroup: ConfVisitGroup[] = [];
  formOtherSymptoms: any = [];
  booleanOptions: any[] = [];

  readMode: boolean = false;
  constructor(
    private trialpalService: TrialpalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private sitesService: SitesService
  ) {}
  async ngOnInit(): Promise<void> {
    const { data } = this.config;
    this.setInitialValues(data);
    this.setupTranslations();
    this.loadAlertTypes();
    await this.loadDictionary();
    this.buildBooleanOptions();
    if (data.confAlert) {
      this.setupEditionMode(data);
    }

    if (this.relatedEntity === 'EDIARY') {
      this.alertConditionTypes = this.getAlertContitionTypesEDiary();
      await this.loadFromEdiary();
    } else {
      this.alertConditionTypes = this.getAlertContitionTypesReport();
      await this.loadFromReport();
    }

    this.temperatureUnits = this.ediaryService.getTemperatureUnits();
    const project = await this.projectService.getProject(this.projectId);
    await this.getSitesByProject(this.projectId);

    if (this.relatedEntity === 'EDIARY') {
      this.getProjectPhases(project);
    } else {
      //Obtiene todas las visitas asociadas a un grupo
      this.projectService
        .getConfVisitGroup(this.projectId)
        .then((response: any) => {
          this.listVisitGroup = response;
          //Muestra las visitas asociadas a los grupos escogidos anteriormente
          this.getPhases(this.groups || []);
        });
    }

    this.addConfSymptom();
    this.setAlertRules();
    this.createSitesData();
    this.filterSymptomIntensityIn = this.symptoms.filter(
      (x) => x.intensityType === IntensityType.MULTIPLE_CHOICE
    );
    this.filterSymptomNumericOrRange = this.symptoms.filter(
      (x) =>
        x.intensityType === IntensityType.NUMERIC_VALUE ||
        x.intensityType === IntensityType.NUMERIC_VALUE_QUESTIONS ||
        x.intensityType === IntensityType.RANGE
    );

    this.filterSymptoms = this.symptoms.filter(
      (symptom: any) => symptom.id !== 'OTHER_SYMPTOMS'
    );
    this.confAlertCopy = JSON.parse(JSON.stringify(this.confAlert));
    this.trialpalService.hideSpinner();
  }

  buildBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  setInitialValues(data: any): void {
    const {
      relatedEntityId,
      relatedEntity,
      dictionary,
      projectId,
      groups,
      readMode,
    } = data;
    this.relatedEntityId = relatedEntityId;
    this.relatedEntity = relatedEntity;
    this.dictionary = dictionary;
    this.projectId = projectId;
    this.groups = groups;
    this.readMode = readMode;
  }

  setupTranslations(): void {
    this.trialpalService.translateService
      .get('ediary.components.alertAddEdit')
      .subscribe((res: any) => (this.translate = res));

    this.trialpalService.translateService
      .get('general')
      .subscribe((res: any) => (this.translateGeneral = res));
  }

  loadAlertTypes(): void {
    this.ediaryService.getAlertTypes().then((res) => {
      this.alertTypes = res;
    });
  }

  setupEditionMode(data: any): void {
    this.isEdition = true;
    const { confAlert } = data;
    this.id = confAlert.id;
    this.expectedVersion = confAlert._version;
    this.setKeysForm();
    this.cleanConfAlertData(confAlert);
  }

  cleanConfAlertData(confAlert: any): void {
    const excludedProperties = [
      'createdAt',
      'updatedAt',
      '_deleted',
      '_lastChangedAt',
      '__typename',
      'confEdiary',
      'confReport',
      'confTemperature',
      'confSymptom',
    ];

    for (const prop of excludedProperties) {
      delete confAlert[prop];
    }

    Object.assign(this.confAlert, confAlert);
    this.confAlert.alertRules = this.confAlert.alertRules.map((x: any) => ({
      ruleId: x.ruleId,
      alertRuleType: x.alertRuleType,
      expectedValue: x.expectedValue,
    }));
  }

  getProjectPhases(project: any) {
    if (project.phases) {
      project.phases.forEach((phase: any) => {
        const key = this.trialpalService.dictionaryPipe.transform(phase);
        this.visitsOptions.push({ label: key, value: phase });
      });
    }
  }
  getAlertContitionTypesEDiary(): Array<object> {
    let eDiaryTypes: any = [];
    const enums = this.trialpalService.translateService.instant(
      'ediary.enums.alertConditionTypes'
    );
    const notIncludeList: string[] = [
      AlertRuleType.JSONFORM_ANSWER,
      AlertRuleType.TOTAL_FORM_VALUE_EQUALS,
      AlertRuleType.TOTAL_FORM_VALUE_LESS_THAN,
      AlertRuleType.TOTAL_FORM_VALUE_MORE_THAN,
    ] as string[];

    Object.keys(AlertRuleType).forEach((alertTypeEnum) => {
      if (!notIncludeList.includes(alertTypeEnum)) {
        eDiaryTypes.push({ label: enums[alertTypeEnum], value: alertTypeEnum });
      }
    });
    return eDiaryTypes;
  }

  getAlertContitionTypesReport(): Array<object> {
    let reportTypes: any = [];
    const enums = this.trialpalService.translateService.instant(
      'ediary.enums.alertConditionTypes'
    );

    const notIncludeList: string[] = [
      AlertRuleType.MEDICAL_ATTENTION_WITH_HOSPITALIZATION,
      AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN,
      AlertRuleType.TOTAL_FORM_VALUE_EQUALS,
      AlertRuleType.TOTAL_FORM_VALUE_LESS_THAN,
      AlertRuleType.TOTAL_FORM_VALUE_MORE_THAN,
    ] as string[];

    Object.keys(AlertRuleType).forEach((alertTypeEnum) => {
      if (!notIncludeList.includes(alertTypeEnum)) {
        reportTypes.push({ label: enums[alertTypeEnum], value: alertTypeEnum });
      }
    });
    return reportTypes;
  }

  async loadFromEdiary(): Promise<void> {
    this.trialpalService.showSpinner('symptom.entityPlural', 'LIST');
    const res = await this.ediaryService.getConfSymptomByConfEdiaryId(
      this.relatedEntityId
    );
    logger.debug('getConfSymptomByConfEdiaryId response', res);
    if (res.items) {
      res.items = res.items.filter(
        (x: any) => x._deleted !== true && x.state !== 'DELETED'
      );
      this.confSymptoms = [...res.items].sort((a: any, b: any) => {
        return a.order - b.order;
      });
    }
  }
  async loadFromReport(): Promise<void> {
    this.trialpalService.showSpinner('symptom.entityPlural', 'LIST');
    const res = await this.reportService.getConfSymptomByConfReportId(
      this.relatedEntityId
    );

    logger.debug('getConfSymptomByConfReportId response', res);
    if (res.items) {
      this.confSymptoms = res.items
        .filter((e) => e && !e._deleted)
        .sort((a: any, b: any) => {
          return a.order - b.order;
        });
    }
  }

  sortArray(array: any) {
    return [].slice.call(array).sort(function SortArray(x: any, y: any) {
      if (x.name < y.name) {
        return -1;
      }
      if (x.name > y.name) {
        return 1;
      }
      return 0;
    });
  }

  addConfSymptom() {
    this.selectedVisits =
      this.confAlert.visitList?.map((x: any) => {
        return x.visitName;
      }) ?? [];
    this.suspenVisits =
      this.confAlert.suspendVisitList?.map((x: any) => x.visitName) ?? [];
    this.symptoms = this.ediaryService.getSymptomsByInstances(
      this.confSymptoms
    );

    if (this.relatedEntity === 'EDIARY') {
      this.symptoms.push({
        id: 'OTHER_SYMPTOMS',
        name: this.trialpalService.translateService.instant(
          'ediary.components.generalConfiguration.labelOtherSymptom'
        ),
        value: 'OTHER_SYMPTOMS',
        intensityType: IntensityType.MULTIPLE_CHOICE,
        decimalPlaces: 0,
      });
    }
    this.symptoms = this.sortArray(this.symptoms);
    const list2 = this.trialpalService.translateService.instant(
      'symptom.enums.intensityTypes'
    );
    for (const intensity of Object.keys(Intensity)) {
      this.intensityTypes.push({
        label: list2[intensity],
        value: intensity,
      });
    }
  }

  setAlertRules() {
    this.currentAlertRules = this.confAlert.alertRules.map((alertRule: any) => {
      return {
        ruleId: this.getRuleId(alertRule),
        alertRuleType: alertRule.alertRuleType,
        expectedValue: alertRule.expectedValue,
        value1: this.getValue1CurrentValues(
          alertRule.alertRuleType,
          alertRule.expectedValue
        ),
        value2: this.getValue2CurrentValues(
          alertRule.alertRuleType,
          alertRule.expectedValue
        ),
      };
    });
    this.checkUniqueValue();
    if (!this.isEdition) {
      this.addAlertCase();
    }
  }

  getRuleId(alertRule: any) {
    return this.isSymptomsOcurrency(alertRule)
      ? alertRule.ruleId.split(',')
      : alertRule.ruleId;
  }

  //Funcion que nos permite separar y identificar los datos del expectedValue1
  getValue1CurrentValues(alertRuleType: any, expectedValue: any) {
    if (alertRuleType === AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN) {
      return expectedValue.split('.').length > 2
        ? expectedValue.split('.')[0] + '.' + expectedValue.split('.')[1]
        : expectedValue.split('.')[0];
    }
    if (
      alertRuleType === AlertRuleType.SYMPTOM_OCCURRENCY ||
      this.isSymptomsOcurrency({ alertRuleType })
    ) {
      return expectedValue;
    }
    return expectedValue?.split('.')[0];
  }

  //Funcion que nos permite separar y identificar los datos del expectedValue2
  getValue2CurrentValues(alertRuleType: any, expectedValue: any) {
    if (alertRuleType === AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN) {
      return expectedValue?.split('.').length > 2
        ? expectedValue?.split('.')[2]
        : expectedValue?.split('.')[1];
    }
    if (alertRuleType === AlertRuleType.SYMPTOM_OCCURRENCY) {
      return '';
    }
    return expectedValue?.split('.').length > 2
      ? expectedValue?.split('.')[1] + '.' + expectedValue?.split('.')[2]
      : expectedValue?.split('.')[1];
  }

  async onSubmit(form: NgForm): Promise<void> {
    this.controllerFormat();
    if (!this.isPass) return this.trialpalService.showInvalidFormError();

    this.prepareAlertData();

    this.confirmErrors(form);
    this.formatVisitListAlert();
    this.formatSuspendVisitListAlert();
    this.completedSites();

    if (this.isFormValid(form) && this.validateRecipients()) {
      this.trialpalService.showSpinner(this.translate.loadingSavingAlert);

      await (this.isEdition
        ? this.editAlertTransaction()
        : this.createAlertTransaction());
    } else {
      this.handleInvalidForm();
    }
  }

  prepareAlertData(): void {
    this.completedAlertRules();
    this.removeSpaces();
  }

  isFormValid(form: NgForm): boolean {
    if (form.valid) {
      return true;
    }

    this.trialpalService.messageService.clear();
    this.trialpalService.showInvalidFormError();
    return false;
  }

  handleInvalidForm(): void {
    if (!this.validateRecipients()) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.translateGeneral.attention,
        detail: this.translate.labelRegisterRecipients,
      });
    }
  }

  async createAlertTransaction() {
    try {
      if (this.relatedEntity === 'EDIARY') {
        this.confAlert.confEdiaryId = this.relatedEntityId;
      } else {
        this.confAlert.confReportId = this.relatedEntityId;
      }
      const alert = await this.ediaryService.createConfAlert(this.confAlert);

      this.trialpalService.hideSpinner();
      this.ref.close(alert);
    } catch (error) {
      logger.error('createConfAlert', error);
    }
  }

  async editAlertTransaction() {
    if (JSON.stringify(this.confAlert) === JSON.stringify(this.confAlertCopy)) {
      this.trialpalService.hideSpinner();
      this.ref.close('');
    } else {
      await this.updateConfAlert();
    }
  }

  async modalChangeReason() {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'general.alerts.loadingUpdatingAlert'
        ) +
        ': ' +
        this.confAlert.alertName,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.confAlert._changeReason = data;
          this.updateConfAlert();
        } else {
          this.trialpalService.hideSpinner();
        }
      },
    });
  }

  async updateConfAlert() {
    try {
      const res = await this.ediaryService.updateConfAlert(
        this.confAlert,
        this.id,
        this.expectedVersion
      );
      this.trialpalService.hideSpinner();
      this.ref.close(res);
    } catch (error) {
      logger.error('updateConfAlert', error);
    }
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.confAlert.alertName = this.confAlert.alertName.trim();
    this.confAlert.mailSubject = this.confAlert.mailSubject?.trim();

    this.confAlert.alertRules = this.confAlert.alertRules.map((x) => ({
      ...x,
      expectedValue: x.expectedValue?.trim(),
    }));

    if (this.confAlert.type === 'SMS') {
      this.confAlert.SMSBody = this.confAlert.SMSBody?.trim();
    }
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(
      this.confAlert.alertName,
      'alertName',
      form
    );
    this.trialpalService.validateSpaces(
      this.confAlert.mailSubject,
      'mailSubject',
      form
    );

    this.confAlert.alertRules.forEach((x, i) => {
      this.trialpalService.validateSpaces(
        x.expectedValue?.trim(),
        `elemValue${i}${this.isNewId}`,
        form
      );
    });

    if (this.confAlert.type === 'SMS') {
      this.trialpalService.validateSpaces(
        this.confAlert.SMSBody,
        'mailSubject',
        form
      );
    }

    if (this.confAlert.type === 'EMAIL') {
      //envia el html a un objeto HMTL y luego obtiene el texto sin espacios
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = this.confAlert.mailBody ?? '';
      this.trialpalService.validateSpaces(
        htmlObject.innerText.trim(),
        'mailBody',
        form
      );
    }
  }

  completedAlertRules(): void {
    this.confAlert.alertRules = [];

    for (const alRule of this.currentAlertRules) {
      if (this.isSymptomsOcurrency(alRule)) {
        alRule.ruleId = alRule.ruleId.join(',');
      }

      this.confAlert.alertRules.push({
        ruleId: alRule.ruleId,
        alertRuleType: alRule.alertRuleType,
        expectedValue: this.getExpectedValue(alRule),
      });
    }
  }

  getExpectedValue(alertRule: any): string {
    if (this.isValueValue2ByRule(alertRule)) {
      const symptom = this.symptoms.find((e) => e.id === alertRule.ruleId);
      return symptom ? `${symptom.value}.${alertRule.value2}` : '';
    }

    if (
      alertRule.alertRuleType === AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN
    ) {
      return `${alertRule.value1}.${alertRule.value2}`;
    }

    if (this.isValue1ByRule(alertRule) || this.isSymptomsOcurrency(alertRule)) {
      return alertRule.value1;
    }

    return alertRule.expectedValue || '';
  }

  isSymptomsOcurrency(alertRule: any): boolean {
    return (
      alertRule.alertRuleType === AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS ||
      alertRule.alertRuleType ===
        AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN ||
      alertRule.alertRuleType ===
        AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN
    );
  }

  isValueValue2ByRule(alRule: any) {
    return (
      alRule.alertRuleType === AlertRuleType.SYMPTOM_INTENSITY_IN ||
      alRule.alertRuleType ===
        AlertRuleType.SYMPTOM_VALUE_EQUALS_OR_LESS_THAN ||
      alRule.alertRuleType ===
        AlertRuleType.SYMPTOM_VALUE_EQUALS_OR_MORE_THAN ||
      alRule.alertRuleType === AlertRuleType.SYMPTOM_VALUE_EQUALS
    );
  }
  isValue1ByRule(alRule: any) {
    return (
      alRule.alertRuleType === AlertRuleType.SYMPTOM_OCCURRENCY ||
      alRule.alertRuleType === AlertRuleType.WITHOUT_SYMPTOM_OCCURRENCY ||
      alRule.alertRuleType === AlertRuleType.TOTAL_FORM_VALUE_EQUALS ||
      alRule.alertRuleType === AlertRuleType.TOTAL_FORM_VALUE_LESS_THAN ||
      alRule.alertRuleType === AlertRuleType.TOTAL_FORM_VALUE_MORE_THAN
    );
  }
  onCancel(f: any): void {
    if (f.form.touched) {
      const key = 'general.changes';
      this.confirmationService.confirm({
        message: this.trialpalService.toUpperCaseFirstLetter(
          this.trialpalService.translateService.instant(key)
        ),
        accept: () => {
          this.ref.close(null);
        },
      });
    } else {
      this.ref.close(null);
    }
  }
  getShortText(item: string): string {
    return item.length <= 30 ? item : item.substring(0, 25) + '...';
  }
  onChangeAlertType(): void {
    if (
      (this.confAlert?.recipients && this.confAlert.recipients.length > 0) ||
      this.currentSites.length > 0
    ) {
      this.confirmationService.confirm({
        message: this.translate.confirmChangeAlertType,
        accept: () => {
          this.confAlert.recipients = [];
          this.confAlert.siteRecipients = [];
          this.currentSites = [];
          if (this.confAlert.type === AlertType.EMAIL) {
            this.confAlert.SMSBody = null;
          } else {
            this.confAlert.mailBody = null;
          }
        },
        reject: () => {
          this.confAlert.type =
            this.confAlert.type === AlertType.EMAIL
              ? AlertType.SMS
              : AlertType.EMAIL;
        },
      });
    } else {
      this.confAlert.recipients = [];
    }
  }
  onAddRecipient(input: any, index?: any): void {
    let pattern;
    if (this.confAlert.type === AlertType.EMAIL) {
      pattern = new RegExp(emailRegex);
    } else {
      pattern = new RegExp(phoneRegex);
    }
    if (!pattern.test(input)) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.translateGeneral.attention,
        detail:
          this.confAlert.type === AlertType.EMAIL
            ? this.translate.smallInvalidEmail
            : this.translate.smallInvalidSMS,
      });
      if (!index && index !== 0 && this.confAlert.recipients) {
        this.confAlert.recipients = this.confAlert.recipients.filter(
          (e: string) => e !== input
        );
      } else {
        this.currentSites[index] = this.currentSites[index].filter(
          (e: string) => e !== input
        );
      }
    }
  }
  getChipText(item: string): string {
    return item.length <= 20 ? item : item.substring(0, 20) + '...';
  }

  addMedicalAttentionWithHospitalizartion(index: number): void {
    if (
      this.currentAlertRules.length > 0 &&
      this.currentAlertRules[index].alertRuleType ===
        AlertRuleType.MEDICAL_ATTENTION_WITH_HOSPITALIZATION
    ) {
      this.currentAlertRules[index].expectedValue = 'true';
    }
  }

  addAlertCase(index?: any): void {
    this.checkUniqueValue();
    this.addMedicalAttentionWithHospitalizartion(index);
    this.controllerFormat();

    if (this.isPass) {
      const actual = this.currentAlertRules.length;
      const expectedActual = this.getExpectValue();

      if (actual === expectedActual) {
        this.activateRequired = false;
        this.currentAlertRules.push({
          alertRuleType: '',
          expectedValue: '',
          value1: '',
          value2: '',
        });
        this.isNewId++;
      } else {
        this.activateRequired = true;
        this.showViaService();
      }
    } else {
      this.showViaService();
    }
  }

  getExpectValue(): number {
    return this.currentAlertRules.filter((value: any) =>
      this.shouldIncludeRule(value)
    ).length;
  }

  shouldIncludeRule(value: any): boolean {
    return (
      value.expectedValue ||
      (value.value1 && value.value2) ||
      ((this.isSymptomOrFormOccurrenceRule(value.alertRuleType) ||
        this.isTotalFormValueRule(value.alertRuleType)) &&
        value.value1)
    );
  }

  isSymptomOrFormOccurrenceRule(alertRuleType_: AlertRuleType): boolean {
    return [
      AlertRuleType.SYMPTOM_OCCURRENCY,
      AlertRuleType.WITHOUT_SYMPTOM_OCCURRENCY,
      AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN,
      AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN,
      AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS,
    ].includes(alertRuleType_);
  }

  isTotalFormValueRule(alertRuleType_: AlertRuleType): boolean {
    return [
      AlertRuleType.TOTAL_FORM_VALUE_EQUALS,
      AlertRuleType.TOTAL_FORM_VALUE_LESS_THAN,
      AlertRuleType.TOTAL_FORM_VALUE_MORE_THAN,
    ].includes(alertRuleType_);
  }

  deletingTrash(alertRule: any, i: any, newId: any): void {
    if (alertRule.alertRuleType !== AlertRuleType.JSONFORM_ANSWER) {
      delete this.formulario.value[`elemValue${i}${newId}`];
    }

    const index = this.currentAlertRules.findIndex((x) => x === alertRule);
    this.currentAlertRules[index].expectedValue = '';
    this.currentAlertRules[index].value1 = '';
    this.currentAlertRules[index].value2 = '';
    this.currentAlertRules[index].ruleId = '';
    if (
      this.currentAlertRules.length > 0 &&
      this.currentAlertRules[index].alertRuleType ===
        AlertRuleType.MEDICAL_ATTENTION_WITH_HOSPITALIZATION
    ) {
      this.currentAlertRules[index].expectedValue = 'true';
    }
  }

  deleteAlertCase(event: any): void {
    const index = this.currentAlertRules.findIndex((x) => x === event);
    this.currentAlertRules.splice(index, 1);
    this.controllerFormat();
  }

  controllerFormat() {
    this.isPass = true;

    for (const value of this.currentAlertRules) {
      const { alertRuleType, value1, value2, expectedValue, ruleId } = value;

      switch (alertRuleType) {
        case AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN:
          this.handleTemperatureRule(value1);
          break;

        case AlertRuleType.JSONFORM_ANSWER:
          this.handleJsonFormAnswerRule(expectedValue);
          break;

        case AlertRuleType.SYMPTOM_VALUE_EQUALS:
        case AlertRuleType.SYMPTOM_VALUE_EQUALS_OR_LESS_THAN:
        case AlertRuleType.SYMPTOM_VALUE_EQUALS_OR_MORE_THAN:
          this.handleSymptomValueRule(value1, value2);
          break;

        case AlertRuleType.WITHOUT_SYMPTOM_OCCURRENCY:
          this.handleWithoutSymptomOccurrenceRule(value1);
          break;

        case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS:
        case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN:
        case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN:
          this.handleSymptomsOccurrenceRule(value1, ruleId);
          break;

        default:
          // Handle other cases if needed
          break;
      }
    }
  }

  handleTemperatureRule(value1: any): void {
    if (!this.validateTemperature(value1)) {
      this.isPass = false;
      this.activateRequired = true;
    }
  }

  handleJsonFormAnswerRule(expectedValue: string): void {
    if (expectedValue.trim().length === 0) {
      this.isPass = false;
      this.activateRequired = true;
    }
  }

  handleSymptomValueRule(value1: any, value2: any): void {
    if (!this.validateDecimal(value1, value2)) {
      this.isPass = false;
      this.activateRequired = true;
    }
  }

  handleWithoutSymptomOccurrenceRule(value1: any): void {
    if (!this.validateDigits(value1)) {
      this.isPass = false;
      this.activateRequired = true;
    }
  }

  handleSymptomsOccurrenceRule(value1: any, ruleId: any): void {
    if (
      !value1 ||
      !this.validateDigits(value1) ||
      (Array.isArray(ruleId) && ruleId.length < 2) ||
      this.validateNumberOfSymptomsWithOccurrency(ruleId, value1)
    ) {
      this.isPass = false;
      this.activateRequired = true;
    }
  }

  validateNumberOfSymptomsWithOccurrency(ruleId: any, value: any) {
    if (!ruleId || ruleId.length === 0) return false;
    return ruleId.length < Number(value);
  }

  validateNumbersForSymptomsOcurrencies(ruleId: any): boolean {
    return Array.isArray(ruleId) && ruleId.length < 2;
  }

  validateDigits(event: any): boolean {
    return /^(\d+)?$/.test(event);
  }

  validateTemperature(event: any): boolean {
    return /^(\d{0,3})([.]\d)?$/.test(event);
  }

  validateDecimal(symptom: any, value: any): boolean {
    symptom = this.getSymptomsValueInstances().find(
      (x: any) => x.value === symptom
    );

    const cantDecimal = symptom?.decimalPlaces || 0;
    const decimalPart = (value as string)?.split('.')[1] ?? '';

    return decimalPart?.length <= cantDecimal;
  }

  showViaService() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: this.translateGeneral.attention,
      detail: this.translateGeneral.formConditionAlert,
    });
  }

  isTooltipConditionAlert(type: any): string {
    if (type === AlertRuleType.TEMPERATURE_EQUALS_OR_MORE_THAN) {
      return this.trialpalService.translateService.instant(
        'ediary.components.alertAddEdit.tooltipTemperature'
      );
    }
    if (type === AlertRuleType.SYMPTOM_INTENSITY_IN) {
      return this.trialpalService.translateService.instant(
        'ediary.components.alertAddEdit.tooltipSymptom'
      );
    }
    if (type === AlertRuleType.JSONFORM_ANSWER) {
      return this.trialpalService.translateService.instant(
        'ediary.components.alertAddEdit.tooltipJsonForm'
      );
    }

    if (
      type === AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS ||
      type === AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN ||
      type === AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN
    ) {
      return this.trialpalService.translateService.instant(
        'ediary.components.alertAddEdit.placeholderNoSymtoms'
      );
    }
    return '';
  }

  getPlaceHolderByAlertRuleType(alertRuleType: string): string {
    switch (alertRuleType) {
      case AlertRuleType.WITHOUT_SYMPTOM_OCCURRENCY:
        return this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.placeholderNoSymtom'
        );

      case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS:
      case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN:
      case AlertRuleType.SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN:
        return this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.placeholderNoSymtoms'
        );

      default:
        return '';
    }
  }

  onDictionaty(isForm: boolean, label: string): void {
    const ref = this.trialpalService.dialogService.open(
      TableLanguagesComponent,
      {
        data: {
          projectId: this.projectId,
          isForm: isForm,
        },
        width: '95%',
        height: '95%',
      }
    );

    ref.onClose.subscribe(async (data) => {
      await this.loadDictionary();
      if (data) {
        switch (label) {
          case 'name':
            this.confAlert.alertName = data.key;
            break;
          case 'affair':
            this.confAlert.mailSubject = data.key;
            break;
          case 'sms':
            this.confAlert.SMSBody = data.key;
            break;
        }
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'dictionary.selectTranslate'
          ),
          detail: this.trialpalService.translateService.instant(
            'dictionary.succesSelectTranslate'
          ),
        });
      }
      switch (label) {
        case 'name':
          this.selectKeyLanguage(undefined, 'name', this.confAlert.alertName);
          break;
        case 'affair':
          this.selectKeyLanguage(
            undefined,
            'affair',
            this.confAlert.mailSubject ?? undefined
          );
          break;
        case 'sms':
          this.selectKeyLanguage(
            undefined,
            'sms',
            this.confAlert.SMSBody ?? undefined
          );
          break;
      }
    });
  }

  async loadDictionary() {
    this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
    this.dictionary = [];
    await this.trialpalService.dictionaryService
      .getConfDictionaryByProjectId(this.projectId)
      .then((dictionary: any) => {
        dictionary.items?.map((item: Dictionary) => {
          if (item) {
            const obj: any = {
              id: item.id,
              key: item.key,
              spanish: item.spanish ?? '',
              isHtml: item?.isHtml ?? false,
              english: item.english ?? '',
            };
            this.dictionary.push(obj);
          }
        });
        localStorage.removeItem('Dictionary');
        localStorage.setItem('Dictionary', JSON.stringify(dictionary));
        this.trialpalService.messageService.clear();
      })
      .catch((err) => logger.error('No se pudo cargar el diccionario', err));
    this.trialpalService.hideSpinner();
  }

  search(event: any, model?: string) {
    switch (model) {
      case 'name':
        this.isSpanishName = '';
        this.isEnglishName = '';
        break;
      case 'affair':
        this.isSpanishAffair = '';
        this.isEnglishAffair = '';
        break;
      case 'sms':
        this.isSpanishSms = '';
        this.isEnglishSms = '';
        break;
    }
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  setKeysForm() {
    if (this.dictionary) {
      this.dictionary.forEach((data) => {
        this.compareDataKeys(data);
      });
    }
  }

  compareDataKeys(data: any) {
    if (data.key === this.config.data.confAlert.alertName) {
      this.isSpanishName = data?.spanish || '';
      this.isEnglishName = data?.english || '';
    }
    if (data.key === this.config.data.confAlert.mailSubject) {
      this.isSpanishAffair = data?.spanish || '';
      this.isEnglishAffair = data?.english || '';
    }
    if (data.key === this.config.data.confAlert.SMSBody) {
      this.isSpanishSms = data?.spanish || '';
      this.isEnglishSms = data?.english || '';
    }
  }

  selectKeyLanguage(event: any, modelAlert?: string, key?: string) {
    if (modelAlert && this.dictionary) {
      switch (modelAlert) {
        case 'name':
          this.dictionary.forEach((data) => {
            if (data.key === event || data.key === key) {
              this.isSpanishName = data.spanish;
              this.isEnglishName = data.english;
            }
          });
          break;
        case 'affair':
          this.dictionary.forEach((data) => {
            if (data.key === event || data.key === key) {
              this.isSpanishAffair = data.spanish;
              this.isEnglishAffair = data.english;
            }
          });
          break;
        case 'sms':
          this.dictionary.forEach((data) => {
            if (data.key === event || data.key === key) {
              this.isSpanishSms = data.spanish;
              this.isEnglishSms = data.english;
            }
          });
          break;
      }
    }
  }

  //Devueleve una array filtrado de sÃ­ntomas configurados dependiendo el tipo de regla seleccionado
  getSymptomsValueInstances(alertRuleType?: string): Array<Object> {
    if (alertRuleType === AlertRuleType.SYMPTOM_INTENSITY_IN) {
      return this.filterSymptomIntensityIn;
    } else if (alertRuleType === AlertRuleType.SYMPTOM_OCCURRENCY) {
      return this.symptoms;
    } else if (this.isSymptomsOcurrency({ alertRuleType })) {
      return this.filterSymptoms;
    }
    return this.filterSymptomNumericOrRange;
  }

  getIntensityValueBySymptom(ruleId?: string): Array<Object> {
    const confSymptom = this.confSymptoms.find(
      (confSymptom: any) => confSymptom.id === ruleId
    );
    if (ruleId && confSymptom) {
      let currentIntensityTypes = [];
      if (confSymptom.noneIntensityScaleOptions.active) {
        currentIntensityTypes.push(this.intensityTypes[0]);
      }
      if (confSymptom.lowIntensityScaleOptions.active) {
        currentIntensityTypes.push(this.intensityTypes[1]);
      }
      if (confSymptom.mediumIntensityScaleOptions.active) {
        currentIntensityTypes.push(this.intensityTypes[2]);
      }
      if (confSymptom.highIntensityScaleOptions.active) {
        currentIntensityTypes.push(this.intensityTypes[3]);
      }
      if (confSymptom.lifeThreateningScaleOptions.active) {
        currentIntensityTypes.push(this.intensityTypes[4]);
      }
      return currentIntensityTypes;
    }
    return this.intensityTypes;
  }
  //Crea una expression regular para verificar numero de decimales dependiendo del sÃ­ntoma seleccionado
  getPatternSymptomsValue(symptom?: any) {
    symptom = this.getSymptomsValueInstances().find(
      (x: any) => x.value === symptom
    );
    const cantDecimal = symptom?.decimalPlaces || 0;
    if (!cantDecimal) return '^([0-9]{0,})?$';
    const expression = '^([0-9]{0,})([.][0-9]{1,decimal})?$';
    return expression.replace('decimal', cantDecimal);
  }
  //Dandole formato al array (visitList) de configuraciÃ³n de alertas, Agregando Order
  formatVisitListAlert() {
    this.confAlert.visitList = this.selectedVisits.map((visit, index) => ({
      order: index,
      visitName: visit,
    }));
  }

  formatSuspendVisitListAlert() {
    this.confAlert.suspendVisitList = this.suspenVisits.map((visit, index) => ({
      order: index,
      visitName: visit,
    }));
  }

  async getSitesByProject(projectId: string) {
    const sites = await this.sitesService.getSitesByProjectForConfAlert(
      projectId
    );
    this.sites = sites;
  }

  //Valida que exista al menos un destinatario
  validateRecipients() {
    if (this.confAlert.recipients && this.confAlert.recipients.length > 0) {
      return true;
    }
    if (
      this.confAlert.siteRecipients &&
      this.confAlert.siteRecipients.filter((x: any) => x.recipients).length > 0
    ) {
      return true;
    }
    return false;
  }

  //Funcion que convierte la info recogida en los ships de destinatarios en el formato configurado en el modelo
  completedSites() {
    if (this.confAlert.siteRecipients) {
      this.sites.forEach((site, siteIndex) => {
        if (
          this.confAlert.siteRecipients!.findIndex(
            (x: any) => x.siteId === site.id
          ) === -1
        ) {
          const recipient = this.currentSites[siteIndex];
          if (recipient) {
            this.confAlert.siteRecipients?.push({
              siteId: site.id,
              recipients: recipient,
            });
          }
        } else {
          const recipient = this.currentSites[siteIndex];
          const index =
            this.confAlert.siteRecipients?.findIndex(
              (x: any) => x.siteId === site.id
            ) ?? 0;
          if (recipient) {
            this.confAlert.siteRecipients![index] = {
              siteId: site.id,
              recipients: recipient,
            };
          }
        }
      });
      this.verifySiteRecipientsBySites();
    }
  }

  verifySiteRecipientsBySites() {
    const currentSitesRecipients: any[] = [];
    this.confAlert?.siteRecipients!.forEach((siteRecipient) => {
      const findIndex = this.sites.findIndex(
        (site: any) => site.id === siteRecipient.siteId
      );
      if (findIndex > -1) {
        currentSitesRecipients.push(siteRecipient);
      }
    });
    this.confAlert.siteRecipients = currentSitesRecipients.filter(
      (x: any) => x.recipients && x.recipients.length > 0
    );
  }

  //Funcion que nos ayuda a adapar la informacion de los sitios de la configuracion en las variables locales para la vista
  createSitesData() {
    if (this.confAlert.siteRecipients) {
      this.confAlert.siteRecipients.forEach((site) => {
        const index = this.sites.findIndex((x: any) => x.id === site.siteId);
        this.currentSites[index] = site.recipients;
      });
    }
  }

  //Asiganr al current value el enum del sintoma
  changeValue(ruleId: string, index: any) {
    if (ruleId === 'OTHER_SYMPTOMS') {
      this.currentAlertRules[index].value1 = 'OTHER_SYMPTOMS';
    } else {
      const symptom = this.symptoms.find((e) => e.id === ruleId);
      if (symptom) {
        //pasar el enum del sintoma
        this.currentAlertRules[index].value1 = symptom.value;
      }
    }
  }

  //Obtiene las visitas correspondientes al grupo seleccionado
  getPhases(groupNames: string[]) {
    let phases: any[] = [];
    //AÃ±ade en un array todas las visitas que esten relacionadas al array de grupos
    groupNames.forEach((groupName) => {
      let phasesGroup: any[] = this.listVisitGroup
        .filter((visitGroup: any) => visitGroup.group === groupName)
        .map((visitGroup: any) => ({
          value: visitGroup.visit,
          label: this.trialpalService.dictionaryPipe.transform(
            visitGroup.visit
          ),
        }));
      phases.push(...phasesGroup);
    });
    //Eliminas las visitas duplicadas
    phases = phases.filter((visit: any, index: number) => {
      return phases.findIndex((x: any) => x.value === visit.value) === index;
    });
    //Elimina las visitas seleccionadas que ya no se encuentran disponibles
    this.selectedVisits = this.selectedVisits.filter((phase: string) => {
      return phases.findIndex((x: any) => x.value === phase) !== -1;
    });
    this.suspenVisits = this.suspenVisits.filter((phase: string) => {
      return phases.findIndex((x: any) => x.value === phase) !== -1;
    });
    this.visitsOptions = phases;
  }

  checkUniqueValue() {
    if (this.currentAlertRules.length === 1) {
      let alertRuleType = this.currentAlertRules[0].alertRuleType;
      if (
        alertRuleType === 'SYMPTOM_INTENSITY_IN' ||
        alertRuleType === 'SYMPTOM_VALUE_EQUALS_OR_MORE_THAN' ||
        alertRuleType === 'SYMPTOM_VALUE_EQUALS_OR_LESS_THAN' ||
        alertRuleType === 'SYMPTOM_VALUE_EQUALS' ||
        alertRuleType === 'SYMPTOM_OCCURRENCY'
      ) {
        this.changeValue(this.currentAlertRules[0].ruleId, 0);
      }
    }
  }
}
