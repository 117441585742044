import { Component, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss'],
})
export class OnBoardingComponent {
  @Input() steps: string[] = [
    'asfdfsdfadsdasfasdasdfasdfsadfafadsfsadfs sdfasdfasdfasdfasdf dsfadsfsadfsadfsafasdfasdfasdfsafsadfasdfasdfasdfasfsadfsadfsadfsdfasdfasd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
    'asdfasdfasdfdsafa',
    'sdafadfadsfasdfadsasfd',
  ];
  @Input() key: string = '';
  @Input() title: string = '¡Ten en cuenta esta información!';
  @Input() btnText: string = 'Continuar';

  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private authService: AuthService
  ) {
    this.key = this.config.data.key;
    this.title = this.config.data.title;
    this.btnText = this.config.data.btnText;
    this.steps = this.config.data.steps;
  }

  /**
   * almacena la información del usuario actual en el localStorage.
   */
  onContinue() {
    const onboardingStorage = localStorage.getItem(ON_BOARDING_KEY);
    const users = onboardingStorage ? JSON.parse(onboardingStorage) : [];
    const currentUserOnboarding = {
      key: this.key,
      username: this.authService.getUsername(),
    };
    users.push(currentUserOnboarding);
    localStorage.setItem(ON_BOARDING_KEY, JSON.stringify(users));
    this.ref.close();
  }
}

export const ON_BOARDING_KEY = 'tp2OnBoarding';
export enum OnBoardingSection {
  ASSESSMENT = 'ASSESSMENT',
}
