/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const MIN_PASS_LENGHT = 6;
const awsmobile = {
  aws_project_region: 'us-east-2',
  aws_appsync_graphqlEndpoint:
    'https://b6ca4njjufc4bnw7eaymbgp7ci.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'tp2IntegrationAPI',
      endpoint: 'https://8m0emy8t6f.execute-api.us-east-2.amazonaws.com/newdev',
      region: 'us-east-2',
    },
  ],
  aws_cognito_identity_pool_id:
    'us-east-2:da081ade-8448-463f-af00-d23f344f681e',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_3IJezCMoA',
  aws_user_pools_web_client_id: '4pm1hd1katp1emc8kkshvoeu01',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['PHONE_NUMBER'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: MIN_PASS_LENGHT,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'tp2storage165720-newdev',
  aws_user_files_s3_bucket_region: 'us-east-2',
  aws_mobile_analytics_app_id: 'c94554e587a54b47aa437f092eeadac2',
  aws_mobile_analytics_app_region: 'us-east-1',
  predictions: {
    convert: {
      speechGenerator: {
        region: 'us-east-2',
        proxy: false,
        defaults: {
          VoiceId: 'Lupe',
          LanguageCode: 'es-US',
        },
      },
    },
  },
};

export default awsmobile;
