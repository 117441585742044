<div class="back_button p-d-flex p-ai-center p-justify-between">
  <div class="p-mr-3">
    <div class="p-d-flex p-ai-center p-mb-3">
      <div>
        <button
          pButton
          type="button"
          icon="pi pi-arrow-left"
          id="btn_forward"
          class="
            p-button-rounded
            p-button-secondary
            p-button-outlined
            p-button
            p-button-icon-only
          "
          (click)="goBack()"
        ></button>
      </div>
      <div class="p-ml-3">
        <h1 class="page-title">{{ pageTitle }}</h1>
      </div>
    </div>
    <div class="description-container">
      <p class="page-description">{{ pageDescription }}</p>
      <p class="page-description">{{ secondaryPageDescription }}</p>
    </div>
  </div>
  <div *ngIf="showButton">
    <button
      id="btn_createReport"
      name="btn_createReport"
      class="btn btn-primary"
      pButton
      [label]="textButton"
      type="button"
      (click)="clickEmitter.emit()"
      [disabled]="isButtonDisabled"
    ></button>
  </div>
</div>
