import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tp2Card } from '../../global.variables';

@Component({
  selector: 'app-tp2-card',
  templateUrl: './tp2-card.component.html',
  styleUrls: ['./tp2-card.component.scss'],
})
export class Tp2CardComponent {
  @Input() tp2Card: Tp2Card = {
    id: '',
    textHeader: 'Consentimiento NOR 206 - Consentimiento',
    isButtonsEnabled: true,
    buttonItems: [],
    isMenuEnabled: false,
    menu: [],
    content: [],
    borderColor: '#005265',
    cardBackground: '#fff',
    isFooterEnabled: false,
    footerContent: '',
  };

  @Output() tp2CardEmmiter: EventEmitter<any> = new EventEmitter();
}
