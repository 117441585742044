import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssessmentMode, ConfEDiary } from '../../ediary.types';

@Component({
  selector: 'app-conciliation-header',
  templateUrl: './conciliation-header.component.html',
  styleUrls: ['./conciliation-header.component.scss'],
})
export class ConciliationHeaderComponent {
  @Input() confEdiary = {
    isConciliationRequired: false,
    isWindowsConciliationRequired: false,
    assessmentMode: null,
  } as ConfEDiary;
  @Output() confEdiaryEmit: EventEmitter<any> = new EventEmitter();
  booleanOptions: any[] = [];
  assessmentModes: { label: string; value: AssessmentMode }[] = [];

  constructor(private translateService: TranslateService) {
    this.booleanOptions = [
      {
        label: this.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.translateService.instant('general.yes'),
        value: true,
      },
    ];

    this.assessmentModes = [
      {
        label: this.translateService.instant(
          'ediary.components.generalConfiguration.conciliation.assessmentMode.enum.DETAILED_REVIEW'
        ),
        value: AssessmentMode.DETAILED_REVIEW,
      },
      {
        label: this.translateService.instant(
          'ediary.components.generalConfiguration.conciliation.assessmentMode.enum.AUTOMATIC_REVIEW'
        ),
        value: AssessmentMode.AUTOMATIC_REVIEW,
      },
    ];
  }

  isConciliationEnabledChange() {
    if (!this.confEdiary.isConciliationRequired) {
      this.confEdiary.isWindowsConciliationRequired = false;
      this.confEdiary.windows = [];
    }
    this.confEdiaryEmit.emit(this.confEdiary);
  }

  isWindowsConciliationEnabledChange() {
    if (!this.confEdiary.isWindowsConciliationRequired) {
      this.confEdiary.windows = [];
    }
    this.confEdiaryEmit.emit(this.confEdiary);
  }

  assessmentModelChange() {
    this.confEdiaryEmit.emit(this.confEdiary);
  }
}
