<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="off">
  <p-fieldset
    legend="{{
      isEdition
        ? readMode
          ? translate.viewAlert
          : translate.labelLegendEdit
        : translate.labelLegendAdd
    }}"
  >
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6">
        <label for="alertName"
          >{{ translate.labelAlertName
          }}<span class="inputRequired"> * </span></label
        >
        <div class="p-inputAutocompleteButton">
          <p-autoComplete
            class="p-flex-1"
            [ngStyle]="{ width: '100%' }"
            id="alertName"
            name="alertName"
            #alertName="ngModel"
            type="text"
            [(ngModel)]="confAlert.alertName"
            [suggestions]="results"
            [required]="true"
            (completeMethod)="search($event, 'name')"
            (onSelect)="selectKeyLanguage($event, 'name')"
            [disabled]="readMode"
          >
          </p-autoComplete>
          <button
            id="dictionaryButtonReport"
            name="dictionaryButtonReport"
            type="button"
            pButton
            pRipple
            icon="pi pi-book"
            (click)="onDictionaty(true, 'name')"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-ml-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
            title="{{ 'dictionary.labelButton' | translate }}"
            [disabled]="readMode"
          ></button>
        </div>
        <div class="p-d-flex p-flex-column" *ngIf="alertName.valid">
          <small *ngIf="isSpanishName" [title]="isSpanishName"
            ><label>ES:</label> {{ isSpanishName | shortText: 70 }}</small
          >
          <small *ngIf="isEnglishName" [title]="isEnglishName"
            ><label>EN:</label> {{ isEnglishName | shortText: 70 }}</small
          >
        </div>
        <small
          class="form-error"
          *ngIf="!alertName.valid && (f.submitted || alertName.touched)"
        >
          {{ translateGeneral.required | translate }}
        </small>
      </div>

      <div class="p-col-12 p-md-6">
        <label for="form"
          >{{ translate.labelNotificationMethod
          }}<span class="inputRequired"> * </span></label
        >
        <p-dropdown
          [required]="true"
          (onChange)="onChangeAlertType()"
          appendTo="body"
          placeholder="{{ translateGeneral.selectPlaceholder }}"
          [options]="alertTypes"
          optionLabel="name"
          optionValue="value"
          name="alertType"
          #alertType="ngModel"
          [(ngModel)]="confAlert.type"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="!alertType.valid && (f.submitted || alertType.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>

      <div class="p-col-12 p-md-6">
        <label for="dayName">{{ translate.labelGeneralRecipients }}</label>
        <p-chips
          [allowDuplicate]="false"
          separator=","
          (onAdd)="onAddRecipient($event.value)"
          [(ngModel)]="confAlert.recipients"
          name="chipRecipients"
          #chipRecipients="ngModel"
          [addOnBlur]="true"
          [disabled]="readMode"
        >
          <ng-template let-item pTemplate="item">
            <span pTooltip="{{ item }}">{{ getChipText(item) }}</span>
          </ng-template>
        </p-chips>
        <small
          class="form-error"
          *ngIf="
            !chipRecipients.valid && (f.submitted || chipRecipients.touched)
          "
        >
          {{ translateGeneral.required }}
        </small>
      </div>

      <!-- sites recipients -->
      <div class="p-col-12 p-md-6" *ngFor="let site of sites; let i = index">
        <label for="dayName"
          >{{ translate.labelRecipients }} {{ site.name | dictionary }}</label
        >
        <p-chips
          [allowDuplicate]="false"
          separator=","
          (onAdd)="onAddRecipient($event.value, i)"
          [(ngModel)]="currentSites[i]"
          [name]="'chipRecipients' + i"
          #chipRecipients="ngModel"
          [addOnBlur]="true"
          [disabled]="readMode"
        >
          <ng-template let-item pTemplate="item">
            <span pTooltip="{{ item }}">{{ getChipText(item) }}</span>
          </ng-template>
        </p-chips>
        <small
          class="form-error"
          *ngIf="
            !chipRecipients.valid && (f.submitted || chipRecipients.touched)
          "
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <!--  -->
      <div class="p-col-12 p-md-6" *ngIf="confAlert.type === 'EMAIL'">
        <label for="mailSubject"
          >{{ translate.labelMailSubject
          }}<span class="inputRequired"> * </span></label
        >
        <div class="p-inputAutocompleteButton">
          <p-autoComplete
            class="p-flex-1"
            [ngStyle]="{ width: '100%' }"
            id="mailSubject"
            name="mailSubject"
            #mailSubject="ngModel"
            type="text"
            [(ngModel)]="confAlert.mailSubject"
            [suggestions]="results"
            [required]="true"
            (completeMethod)="search($event, 'affair')"
            (onSelect)="selectKeyLanguage($event, 'affair')"
            [disabled]="readMode"
          >
          </p-autoComplete>
          <button
            id="dictionaryButtonReport"
            name="dictionaryButtonReport"
            type="button"
            pButton
            pRipple
            icon="pi pi-book"
            (click)="onDictionaty(true, 'affair')"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-ml-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
            title="{{ 'dictionary.labelButton' | translate }}"
            [disabled]="readMode"
          ></button>
        </div>
        <div class="p-d-flex p-flex-column" *ngIf="mailSubject.valid">
          <small *ngIf="isSpanishAffair" [title]="isSpanishAffair"
            ><label>ES:</label> {{ isSpanishAffair | shortText: 70 }}</small
          >
          <small *ngIf="isEnglishAffair" [title]="isEnglishAffair"
            ><label>EN:</label> {{ isEnglishAffair | shortText: 70 }}</small
          >
        </div>

        <small
          class="form-error"
          *ngIf="!mailSubject.valid && (f.submitted || mailSubject.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>

      <div class="p-col-12 p-md-6" *ngIf="confAlert.type === 'SMS'">
        <label for="mailBody" *ngIf="confAlert.type === 'SMS'"
          >{{ translate.labelSMSBody
          }}<span class="inputRequired"> * </span></label
        >

        <div *ngIf="confAlert.type === 'SMS'">
          <div class="p-inputAutocompleteButton">
            <p-autoComplete
              class="p-flex-1"
              [ngStyle]="{ width: '100%' }"
              id="mailSubject"
              name="mailBody"
              #mailBody="ngModel"
              [maxlength]="255"
              #mailSubject="ngModel"
              type="text"
              [(ngModel)]="confAlert.SMSBody"
              [suggestions]="results"
              [required]="true"
              (completeMethod)="search($event, 'sms')"
              (onSelect)="selectKeyLanguage($event, 'sms')"
              [disabled]="readMode"
            >
            </p-autoComplete>
            <button
              id="dictionaryButtonReport"
              name="dictionaryButtonReport"
              type="button"
              pButton
              pRipple
              icon="pi pi-book"
              (click)="onDictionaty(true, 'sms')"
              class="
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-ml-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
              title="{{ 'dictionary.labelButton' | translate }}"
              [disabled]="readMode"
            ></button>
          </div>

          <div class="p-d-flex p-flex-column" *ngIf="mailBody.valid">
            <small *ngIf="isSpanishSms" [title]="isSpanishSms"
              ><label>ES:</label> {{ isSpanishSms | shortText: 70 }}</small
            >
            <small *ngIf="isEnglishSms" [title]="isEnglishSms"
              ><label>EN:</label> {{ isEnglishSms | shortText: 70 }}</small
            >
          </div>
          <small
            class="form-error"
            *ngIf="!mailBody.valid && (f.submitted || mailBody.touched)"
          >
            {{ translateGeneral.required }}
          </small>
        </div>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12" *ngIf="confAlert.type === 'EMAIL'">
        <label for="mailBody" *ngIf="confAlert.type === 'EMAIL'"
          >{{ translate.labelMailBody
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-editor
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="confAlert.mailBody"
          (outputEvent)="confAlert.mailBody = $event"
          [isReadOnly]="readMode"
        >
        </app-simple-dictionary-editor>
        <p-editor
          [required]="true"
          [hidden]="true"
          name="mailBody"
          #mailBody="ngModel"
          [(ngModel)]="confAlert.mailBody"
          [style]="{ height: '320px' }"
          [readonly]="readMode"
        ></p-editor>
      </div>

      <div class="p-col-12">
        <div class="p-grid p-col-12 p-pl-0">
          <label class="full-width" for="intensity">{{
            'ediary.components.alertAddEdit.labelAlertCompletedVisit'
              | translate
          }}</label>

          <p-selectButton
            id="cmb_phase"
            name="phase"
            pTooltip="{{
              'ediary.components.alertAddEdit.labelAlertCompletedVisitHelp'
                | translate
            }}"
            tooltipPosition="top"
            [options]="booleanOptions"
            [(ngModel)]="confAlert.triggerVisitCompletion"
            optionLabel="label"
            optionValue="value"
            #intensity="ngModel"
            [disabled]="readMode"
          ></p-selectButton>
        </div>

        <p-fieldset
          class="p-col-12"
          legend="{{ translate.labelAlertVisitManagement }} "
          *ngIf="confAlert.triggerVisitCompletion"
        >
          <label class="full-width" for="intensity"
            >{{ 'ediary.components.dayAddEdit.labelPhases' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <p-multiSelect
            appendTo="body"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [filter]="true"
            filterBy="label"
            name="visits"
            [options]="visitsOptions"
            optionLabel="label"
            optionValue="value"
            name="symptom"
            #visitsSelected="ngModel"
            [(ngModel)]="selectedVisits"
            emptyMessage="{{ 'general.noResultsFound' | translate }}"
            emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
            [required]="true"
            [disabled]="readMode"
          >
          </p-multiSelect>
          <small
            class="form-error"
            *ngIf="
              !visitsSelected.valid && (f.submitted || visitsSelected.touched)
            "
          >
            {{ translateGeneral.required }}
          </small>
        </p-fieldset>

        <div class="p-grid p-col-12 p-pl-0">
          <label class="full-width">{{
            'ediary.components.alertAddEdit.labelAlertEdiarySuspen' | translate
          }}</label>
          <p-selectButton
            id="ediarySuspension"
            name="ediarySuspension"
            pTooltip="{{
              'ediary.components.alertAddEdit.labelAlertEdiarySuspenHelp'
                | translate
            }}"
            tooltipPosition="top"
            [(ngModel)]="confAlert.triggerEDiarySuspension"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            [disabled]="readMode"
            #ediarySuspension="ngModel"
          ></p-selectButton>
        </div>

        <!--Visitas para suspender-->
        <p-fieldset
          class="p-col-12"
          legend="{{ translate.labelAlertVisitManagement }} "
          *ngIf="confAlert.triggerEDiarySuspension"
        >
          <label class="full-width" for="suspenVisits"
            >{{ 'ediary.components.dayAddEdit.labelPhases' | translate }}
            <span class="inputRequired"> * </span></label
          >
          <p-multiSelect
            class="full-width"
            appendTo="body"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [filter]="true"
            filterBy="label"
            name="suspendVisits"
            [options]="visitsOptions"
            optionLabel="label"
            optionValue="value"
            #suspenVisitsElement="ngModel"
            [(ngModel)]="suspenVisits"
            emptyMessage="{{ 'general.noResultsFound' | translate }}"
            emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
            [required]="true"
            [disabled]="readMode"
          >
          </p-multiSelect>
          <small
            class="form-error"
            *ngIf="
              !suspenVisitsElement.valid &&
              (f.submitted || suspenVisitsElement.touched)
            "
          >
            {{ translateGeneral.required }}
          </small>
        </p-fieldset>
      </div>
    </div>
    <p-fieldset class="p-col-12 p-p-0" legend="{{ translate.labelAlertRule }}">
      <div class="p-grid p-col-12 p-p-0">
        <div class="p-col-12 p-grid p-p-0">
          <div class="p-col-4 p-pb-0">
            <label for=""
              >{{
                'ediary.components.alertAddEdit.labelAlertType' | translate
              }}
              <span class="inputRequired"> * </span></label
            >
          </div>
          <div
            class="p-col-4 p-pb-0"
            *ngIf="
              currentAlertRules.length > 0 &&
              currentAlertRules[0]?.alertRuleType
            "
          >
            <label for=""
              >{{
                'ediary.components.alertAddEdit.labelAlertRuleValue' | translate
              }}
              <span class="inputRequired"> * </span></label
            >
          </div>
        </div>
        <div
          class="p-grid p-col-12 p-p-0"
          *ngFor="let alertRule of currentAlertRules; let i = index"
        >
          <div class="p-col-4">
            <p-dropdown
              appendTo="body"
              [filter]="true"
              [required]="true"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [options]="alertConditionTypes"
              optionLabel="label"
              optionValue="value"
              [name]="'elemType' + i + isNewId"
              #alertType="ngModel"
              [(ngModel)]="alertRule.alertRuleType"
              (ngModelChange)="deletingTrash(alertRule, i, isNewId)"
              emptyMessage="{{ 'general.noResultsFound' | translate }}"
              emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
              [disabled]="readMode"
            >
              <ng-template let-alertType pTemplate="item">
                <div>
                  <div pTooltip="{{ alertType.label }}">
                    {{ alertType.label | shortText: 100 }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <small
              class="form-error"
              *ngIf="!alertType.valid && (f.submitted || alertType.touched)"
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-4"
            *ngIf="
              alertRule.alertRuleType ===
                'SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN' ||
              alertRule.alertRuleType ===
                'SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN' ||
              alertRule.alertRuleType === 'SYMPTOMS_OCURRENCY_EQUALS'
            "
          >
            <p-multiSelect
              class="full-width"
              appendTo="body"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [filter]="true"
              filterBy="label"
              (onChange)="changeValue(alertRule.id, i)"
              [name]="'symptom' + i + isNewId"
              [options]="getSymptomsValueInstances(alertRule.alertRuleType)"
              optionLabel="name"
              optionValue="id"
              #symptom="ngModel"
              [(ngModel)]="alertRule.ruleId"
              [maxSelectedLabels]="2"
              selectedItemsLabel="{2}
            {{
                'ediary.components.alertAddEdit.labelSelectedSymptoms'
                  | translate
              }}
          "
              emptyMessage="{{ 'general.noResultsFound' | translate }}"
              emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
              [required]="true"
              [disabled]="readMode"
            >
              <ng-template let-symptom pTemplate="item">
                <div>
                  <div pTooltip="{{ symptom.name }}">
                    {{ symptom.name | shortText: 100 }}
                  </div>
                </div>
              </ng-template>
            </p-multiSelect>

            <small
              class="form-error"
              *ngIf="
                (!symptom.valid && (f.submitted || symptom.touched)) ||
                (activateRequired && !symptom.valid)
              "
            >
              {{ translateGeneral.required }}
            </small>

            <small
              class="form-error"
              *ngIf="
                validateNumbersForSymptomsOcurrencies(alertRule.ruleId) &&
                (f.submitted || symptom.touched)
              "
            >
              {{
                'ediary.components.alertAddEdit.labelSymptomsSelect' | translate
              }}
            </small>
          </div>
          <div
            class="p-col-3"
            *ngIf="
              alertRule.alertRuleType ===
                'SYMPTOMS_OCURRENCY_EQUALS_OR_MORE_THAN' ||
              alertRule.alertRuleType ===
                'SYMPTOMS_OCURRENCY_EQUALS_OR_LESS_THAN' ||
              alertRule.alertRuleType === 'SYMPTOMS_OCURRENCY_EQUALS'
            "
          >
            <input
              [required]="true"
              type="text"
              [name]="'elemValue' + i + isNewId"
              #inputSymptomsOcurrency="ngModel"
              pInputText
              [pTooltip]="isTooltipConditionAlert(alertRule.alertRuleType)"
              pattern="^([0-9]{1,})?$"
              [(ngModel)]="alertRule.value1"
              [placeholder]="
                getPlaceHolderByAlertRuleType(alertRule.alertRuleType)
              "
              [disabled]="readMode"
            />
            <small
              class="form-error"
              *ngIf="
                !inputSymptomsOcurrency.valid &&
                (f.submitted || inputSymptomsOcurrency.touched)
              "
            >
              {{ translateGeneral.required }}
            </small>
            <small
              class="form-error"
              *ngIf="
                validateNumberOfSymptomsWithOccurrency(
                  alertRule.ruleId,
                  alertRule.value1
                )
              "
            >
              {{
                'ediary.components.alertAddEdit.labelNumberSymptomsOcurrencySelect'
                  | translate
              }}
            </small>
          </div>
          <div
            class="p-col-4"
            *ngIf="
              alertRule.alertRuleType === 'SYMPTOM_INTENSITY_IN' ||
              alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS_OR_MORE_THAN' ||
              alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS_OR_LESS_THAN' ||
              alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS' ||
              alertRule.alertRuleType === 'SYMPTOM_OCCURRENCY'
            "
          >
            <p-dropdown
              appendTo="body"
              [required]="true"
              [filter]="true"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [name]="'symptom' + i + isNewId"
              [options]="getSymptomsValueInstances(alertRule.alertRuleType)"
              optionLabel="name"
              optionValue="id"
              #symptom="ngModel"
              (onChange)="changeValue(alertRule.ruleId, i)"
              [(ngModel)]="alertRule.ruleId"
              emptyMessage="{{ 'general.noResultsFound' | translate }}"
              emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
              [disabled]="readMode"
              ><ng-template let-symptom pTemplate="item">
                <div>
                  <div pTooltip="{{ symptom.name }}">
                    {{ symptom.name | shortText: 100 }}
                  </div>
                </div>
              </ng-template></p-dropdown
            >
            <small
              class="form-error"
              *ngIf="
                (!symptom.valid && (f.submitted || symptom.touched)) ||
                (activateRequired && !symptom.valid)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-3"
            *ngIf="alertRule.alertRuleType === 'SYMPTOM_INTENSITY_IN'"
          >
            <p-dropdown
              appendTo="body"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [filter]="true"
              filterBy="name"
              [name]="'type' + i + isNewId"
              [options]="getIntensityValueBySymptom(alertRule.ruleId)"
              optionLabel="label"
              optionValue="value"
              name="symptom"
              #intensityType="ngModel"
              [(ngModel)]="alertRule.value2"
              emptyMessage="{{ 'general.noResultsFound' | translate }}"
              emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
              [required]="true"
              [disabled]="readMode"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="
                (!intensityType.valid &&
                  (f.submitted || intensityType.touched)) ||
                (activateRequired && !intensityType.valid)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-3"
            *ngIf="
              alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS_OR_MORE_THAN' ||
              alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS_OR_LESS_THAN' ||
              alertRule.alertRuleType === 'SYMPTOM_VALUE_EQUALS'
            "
          >
            <input
              [required]="true"
              type="text"
              [name]="'elemValue' + i + isNewId"
              #symptomValue="ngModel"
              pInputText
              [pTooltip]="isTooltipConditionAlert(alertRule.alertRuleType)"
              [(ngModel)]="alertRule.value2"
              [pattern]="getPatternSymptomsValue(alertRule.value1)"
              (ngModelChange)="
                validateDecimal(alertRule.value1, alertRule.value2)
              "
              [disabled]="readMode"
            />
            <small
              class="form-error"
              *ngIf="
                !symptomValue.valid && (f.submitted || symptomValue.touched)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-4"
            *ngIf="
              alertRule.alertRuleType === 'TEMPERATURE_EQUALS_OR_MORE_THAN'
            "
          >
            <input
              [required]="true"
              type="text"
              [name]="'elemValue' + i + isNewId"
              #temperatureSubject="ngModel"
              pInputText
              [pTooltip]="isTooltipConditionAlert(alertRule.alertRuleType)"
              [(ngModel)]="alertRule.value1"
              pattern="^([0-9]{0,3})([.][0-9]{1,1})?$"
              (ngModelChange)="validateTemperature(alertRule.value1)"
              [disabled]="readMode"
            />
            <small
              class="form-error"
              *ngIf="
                (!temperatureSubject.valid &&
                  (f.submitted || temperatureSubject.touched)) ||
                (activateRequired && alertRule.value1.trim().length === 0)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-4"
            *ngIf="
              alertRule.alertRuleType === 'WITHOUT_SYMPTOM_OCCURRENCY' ||
              alertRule.alertRuleType === 'TOTAL_FORM_VALUE_EQUALS' ||
              alertRule.alertRuleType === 'TOTAL_FORM_VALUE_LESS_THAN' ||
              alertRule.alertRuleType === 'TOTAL_FORM_VALUE_MORE_THAN'
            "
          >
            <input
              [required]="true"
              type="text"
              [name]="'elemValue' + i + isNewId"
              #temperatureSubject="ngModel"
              pInputText
              [pTooltip]="isTooltipConditionAlert(alertRule.alertRuleType)"
              pattern="^([0-9]{1,})?$"
              [(ngModel)]="alertRule.value1"
              [placeholder]="
                getPlaceHolderByAlertRuleType(alertRule.alertRuleType)
              "
              [disabled]="readMode"
            />
            <small
              class="form-error"
              *ngIf="
                (!temperatureSubject.valid &&
                  (f.submitted || temperatureSubject.touched)) ||
                (activateRequired && alertRule.value1.trim().length === 0)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-3"
            *ngIf="
              alertRule.alertRuleType === 'TEMPERATURE_EQUALS_OR_MORE_THAN'
            "
          >
            <p-dropdown
              appendTo="body"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [filter]="true"
              filterBy="name"
              [name]="'typeUnity' + i + isNewId"
              [options]="temperatureUnits"
              optionLabel="label"
              optionValue="value"
              name="symptom"
              #intensityType="ngModel"
              [(ngModel)]="alertRule.value2"
              emptyMessage="{{ 'general.noResultsFound' | translate }}"
              emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
              [required]="true"
              [disabled]="readMode"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="
                (!intensityType.valid &&
                  (f.submitted || intensityType.touched)) ||
                (activateRequired && !intensityType.valid)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>
          <div
            class="p-col-7"
            *ngIf="
              alertRule.alertRuleType ===
              'MEDICAL_ATTENTION_WITH_HOSPITALIZATION'
            "
          ></div>
          <div
            class="p-col-3"
            *ngIf="
              alertRule.alertRuleType === 'SYMPTOM_OCCURRENCY' ||
              alertRule.alertRuleType === 'WITHOUT_SYMPTOM_OCCURRENCY' ||
              alertRule.alertRuleType === 'TOTAL_FORM_VALUE_EQUALS' ||
              alertRule.alertRuleType === 'TOTAL_FORM_VALUE_LESS_THAN' ||
              alertRule.alertRuleType === 'TOTAL_FORM_VALUE_MORE_THAN'
            "
          ></div>
          <div
            class="p-col-6"
            *ngIf="alertRule.alertRuleType === 'JSONFORM_ANSWER'"
          >
            <input
              [required]="true"
              type="text"
              [name]="'elemValue' + i + isNewId"
              #temperatureSubject="ngModel"
              pInputText
              [pTooltip]="isTooltipConditionAlert(alertRule.alertRuleType)"
              [(ngModel)]="alertRule.expectedValue"
              [disabled]="readMode"
            />
            <small
              class="form-error"
              *ngIf="
                (!temperatureSubject.valid &&
                  (f.submitted || temperatureSubject.touched)) ||
                (activateRequired &&
                  alertRule.expectedValue.trim().length === 0)
              "
            >
              {{ translateGeneral.required }}
            </small>
          </div>

          <div class="p-col-1" style="text-align: center">
            <button
              *ngIf="i !== 0 || currentAlertRules.length > 1"
              pButton
              pRipple
              type="button"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger buttonController p-m-1"
              (click)="deleteAlertCase(alertRule)"
              [disabled]="readMode"
            ></button>
            <button
              *ngIf="currentAlertRules.length === i + 1"
              pButton
              pRipple
              #buttonAdd
              type="button"
              icon="pi pi-plus"
              class="p-button-rounded p-button-success buttonController p-m-1"
              (click)="addAlertCase(i)"
              [disabled]="readMode"
            ></button>
          </div>
        </div>
      </div>
    </p-fieldset>

    <div class="p-grid p-field p-fluid p-mt-3" *ngIf="!readMode">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-4">
        <div class="p-grid p-field">
          <div class="p-col">
            <button
              class="p-button-secondary"
              (click)="onCancel(f)"
              pButton
              type="button"
              label="{{ translateGeneral.cancel }}"
              [disabled]="readMode"
            ></button>
          </div>
          <div class="p-col">
            <button
              pButton
              type="submit"
              label="{{
                isEdition ? translateGeneral.edit : translateGeneral.add
              }}"
              [disabled]="readMode"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
</form>
