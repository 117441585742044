<div class="container-tp2 detail-subject">
  <div class="p-fluid p-grid">
    <div class="p-col-4" style="display: flex; align-items: center">
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        id="btn_forward"
        class="
          p-button-rounded
          p-button-secondary
          p-button-outlined
          p-button
          p-button-icon-only
        "
        (click)="forward()"
      ></button>
      <h2 style="margin: 10px">
        {{ 'subject.h2SubjectDetail' | translate }}
      </h2>
    </div>

    <div class="p-fluid p-grid p-col-8 p-jc-end" style="align-items: center">
      <div
        class="p-col-3"
        id="btnEdit_container"
        *ngIf="hasSubjectUpdatePermission"
      >
        <button
          pButton
          label=" {{ 'project.buttonLabelEdit' | translate }}"
          icon="pi pi-pencil"
          iconPos="right"
          type="button"
          (click)="editSubject()"
          class="p-button-rounded p-button-secondary p-button-outlined"
        ></button>
      </div>

      <div
        class="p-col-4"
        style="text-align: right"
        id="audit_container"
        *ngIf="hasSubjectAuditPermission"
      >
        <button
          pButton
          label="{{ 'general.btnAuditTrace' | translate }}"
          type="button"
          icon="pi pi-file"
          iconPos="right"
          (click)="openAuditModal()"
          badgeClass="color: white;"
          class="p-button-rounded p-button-success pbutton"
        ></button>
      </div>
    </div>
  </div>

  <div
    class="p-fluid p-grid"
    id="basicInformation_container"
    *ngIf="subject.subjectNumber"
  >
    <p class="p-col-6">
      {{ 'subject.pSubjectNumber' | translate }}: {{ subject.subjectNumber }}
    </p>
    <p class="p-col-6">
      {{ 'subject.pGroup' | translate }}: {{ subject.group | dictionary }}
    </p>
    <p class="p-col-6">
      {{ 'subject.pProject' | translate }}: {{ subject.project?.name }}
    </p>
    <p class="p-col-6">
      {{ 'site.entity' | translate }}: {{ subject.site?.name }}
      <strong>('GMT' {{ getTimeZoneOffset() }})</strong>
    </p>
    <p class="p-col-6" id="phase_container">
      {{ 'subject.pCurrentVisit' | translate }}:
      {{ subject?.currentScheduledPhase?.phase || '' | dictionary }}
    </p>
    <p class="p-col-6" id="status_container">
      {{ 'subject.pState' | translate }}:
      <strong
        [style]="{
          color: isColorState()
        }"
        >{{
          'subject.enums.subjectStates.' + subject.state | translate
        }}</strong
      ><em
        class="pi pi-check"
        *ngIf="subject.state === 'COMPLETED'"
        [style]="{
          color: 'green',
          left: '200px'
        }"
      ></em>
    </p>
  </div>
  <app-tour-guide-button
    [pageTourDataType]="DETAIL_SUBJECT_TOUR"
    *ngIf="currentTabIndex === 0"
  ></app-tour-guide-button>
  <div id="tabs_container">
    <p-tabView
      [style]="{ width: '100%', padding: '0' }"
      style="width: 100%"
      (onChange)="recordTabChange($event)"
    >
      <p-tabPanel
        [selected]="true"
        header="{{ 'subject.tabheaderVisit' | translate }}"
      >
        <app-button-refresh-data
          [showSpinner]="showSpinner"
          (buttonAction)="getSubjectDetail()"
        ></app-button-refresh-data>
        <section *ngIf="!showSpinner">
          <div
            class="p-grid p-fluid"
            *ngIf="
              subject.scheduledPhases && subject.scheduledPhases.length > 0
            "
            id="phases_container"
          >
            <div
              class="p-col-12 p-md-4 p-lg-3"
              *ngFor="let p of dataCard; let i = index"
              [id]="'phaseCard_container' + i"
            >
              <app-tpcard
                [tpCardData]="p"
                (customAction)="showBasicDialog($event)"
              ></app-tpcard>
            </div>
          </div>
          <div
            class="p-grid p-fluid"
            *ngIf="
              subject.scheduledPhases && subject.scheduledPhases.length === 0
            "
          >
            <h4 style="width: 100%; text-align: center">
              {{ 'general.noResultsFound' | translate }}
            </h4>
          </div>
        </section>
        <app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
      </p-tabPanel>
      <p-tabPanel header="{{ 'subject.tabheaderAlerts' | translate }}">
        <app-alert-subject
          [timeZoneOffset]="getTimeZoneOffset()"
          [isOpened]="isAlertTabOpened"
        ></app-alert-subject>
      </p-tabPanel>
      <p-tabPanel header="{{ 'subject.tabheaderSymptom' | translate }}"
        ><app-symptom-subject
          [timeZoneOffset]="getTimeZoneOffset()"
          [isOpened]="isSymptomTabOpened"
        ></app-symptom-subject
      ></p-tabPanel>
      <p-tabPanel
        *ngIf="project?.isEdiaryActivated"
        header="{{ 'subject.tabheaderPDFDiary' | translate }}"
        ><app-daily-pdf-subject
          [timeZoneOffset]="getTimeZoneOffset()"
          [subject]="subject"
          [isOpened]="isEdiaryTabOpened"
        ></app-daily-pdf-subject
      ></p-tabPanel>
      <p-tabPanel
        *ngIf="hasUserListPermission"
        header="{{ 'subject.tabheaderUsers' | translate }}"
        id="users_container"
      >
        <app-tour-guide-button
          [pageTourDataType]="DETAIL_SUBJECT_USERS_TOUR"
        ></app-tour-guide-button>
        <!-- Usuarios -->
        <app-list-users [idSubject]="subjectId"></app-list-users>
      </p-tabPanel>
      <p-tabPanel
        *ngIf="project?.isReportsActivated"
        header="{{ 'subject.tabheaderSurveillanceReports' | translate }}"
        ><app-report-subject
          [projectId]="projectId"
          [timeZoneOffset]="getTimeZoneOffset()"
          [isOpened]="isReportTabOpened"
        ></app-report-subject
      ></p-tabPanel>
      <p-tabPanel
        *ngIf="showTemperatureTab"
        header="{{ 'subject.tabheaderTemperature' | translate }}"
      >
        <app-temperature-subject
          [idSubject]="subjectId"
          [timeZoneOffset]="getTimeZoneOffset()"
          [isOpened]="isTemperatureTabOpened"
          (hasMedicalAttentions)="showTemperatureTab = $event"
        ></app-temperature-subject>
      </p-tabPanel>
      <p-tabPanel
        *ngIf="showMedicalAttentionTab"
        header="{{ 'subject.tabheaderMedicalAssistance' | translate }}"
        ><app-medical-attention-subject
          [timeZoneOffset]="getTimeZoneOffset()"
          [isOpened]="isMedicalAttentionTabOpened"
          (hasMedicalAttentions)="showMedicalAttentionTab = $event"
        ></app-medical-attention-subject
      ></p-tabPanel>
      <p-tabPanel
        *ngIf="showMedicationTab"
        header="{{ 'subject.tabheaderMedication' | translate }}"
      >
        <app-medication-subject
          [timeZoneOffset]="getTimeZoneOffset()"
          [isOpened]="isMedicationTabOpened"
          (hasMedications)="showMedicationTab = $event"
        ></app-medication-subject>
      </p-tabPanel>
      <p-tabPanel
        *ngIf="showMultimediaTab"
        header="{{ 'subject.tabheaderMultimedia' | translate }}"
        ><app-media-subject
          [timeZoneOffset]="getTimeZoneOffset()"
          (hasMultimedia)="showMultimediaTab = $event"
        ></app-media-subject
      ></p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog
  header="{{ 'subject.dateVisit' | translate }}"
  [(visible)]="displayBasic"
  [style]="{ width: '50vw' }"
  *ngIf="displayBasic"
>
  <div class="p-col-12 p-md-4 p-field">
    <label>
      {{ 'general.selectPlaceholder' | translate }}
      <span class="inputRequired">* </span>
    </label>
    <p-calendar
      inputStyleClass="iText"
      name="fecha"
      id="fecha"
      [(ngModel)]="currentDate"
      [maxDate]="maxDate"
      [showIcon]="true"
      appendTo="body"
      [maxDate]="maxDate"
      placeholder="{{
        'addEditSubjectComponent.PlaceHolderFormatDate' | translate
      }}"
      dateFormat="dd-M-yy"
      [required]="true"
      #date="ngModel"
    ></p-calendar>
    <small
      class="form-error"
      *ngIf="!date.valid && (submitCompletVisit || date.touched)"
    >
      {{ 'general.required' | translate }}</small
    >
  </div>
  <ng-template pTemplate="footer">
    <button
      pButton
      label=" {{ 'visit.Complete' | translate }}"
      icon="pi pi-check"
      iconPos="right"
      type="button"
      (click)="completeVisit()"
      class="p-button-rounded p-button-secondary p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>

<p-confirmDialog
  [style]="{ width: '50vw' }"
  header="{{ 'general.headerConfirm' | translate }}"
  acceptLabel="{{ 'general.confirm' | translate }}"
  rejectLabel="{{ 'general.cancel' | translate }}"
  key="positionDialog"
  position="top"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-outlined"
></p-confirmDialog>
