import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Logger } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { TrialpalService } from '../services/trialpal.service';
const logger = new Logger('tp2-logger-datePipe');
@Pipe({
  name: 'transformDate',
})
export class TransformDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private trialpalService: TrialpalService
  ) {}
  /**
   * Parsea la fecha en el formato del proyecto dd-MM-yyyy
   * @param date fecha a transformar (sin hora)
   * @param type si es una fecha o multiple fechas
   * @param format Indica si la fecha ya viene formateada en dd-MM-yyyy
   * @returns fecha formateada dd-MMM-yyyy
   */
  transform(date: any, type?: DateType, format?: boolean): any {
    if (!date) return date;
    if (type === DateType.UNIQUE || !type) {
      return this.transformDate(date);
    }
    if (type === DateType.MULTIPLE) {
      const dates = date.split(',');
      const transformDates = dates.map((date: string) => {
        if (!date) return date;
        return this.transformDate(date, format);
      });
      return transformDates.join(', ');
    }
  }

  transformDate(date: any, format?: boolean) {
    const currentDate = format
      ? date?.split('-')
      : this.datePipe.transform(date, 'dd-MM-yyyy')?.split('-');
    const months = this.trialpalService.translateService.instant(
      'primeng.monthNamesShort'
    );
    const indexMonth = currentDate ? parseInt(currentDate[1]) : 0;
    const month = months[indexMonth - 1];
    currentDate?.splice(1, 1, month);
    return currentDate?.join('-');
  }
  /**
   * Se utiliza para formatear una fecha con hora a la fecha y hora local.
   * Se utiliza para visualizar la traza de auditoría principalmente
   * @param date fecha que se quiere formatear
   * @returns fecha en formato del proyecto dd-MMM-yyyy HH:mm:ss
   */
  transformWithHour(date: any, extraInfo: boolean = false): any {
    const hasNoTimezone = new Date(date).toISOString().includes('00:00.000Z');
    if (hasNoTimezone && !extraInfo) {
      return this.transform(date);
    }
    const currenDate = this.transform(date);
    const hour = this.datePipe.transform(date, 'H');
    const minutes = this.datePipe.transform(date, 'mm');
    const second = this.datePipe.transform(date, 'ss');
    return (
      currenDate +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      second +
      (extraInfo ? ` ${this.datePipe.transform(date, 'a')}` : '')
    );
  }

  /**
   * se utliza para convertir las fechas del server (UTC 0) a las fechas del sitio.
   * @param date fecha que se desea transformar
   * @param timeZoneOffset gmt del sitio donde pertenece el sujeto
   * @returns la fecha en el formato del proyecto en el GTM del sitio
   */
  transformToSiteHour(date: any, timeZoneOffset: number): any {
    const siteDate = this.getSiteDate(date, timeZoneOffset);
    const currentDate = this.transform(siteDate);
    const timeString = this.getTimeString(siteDate);

    return `${currentDate} ${timeString}`;
  }

  private getSiteDate(date: any, timeZoneOffset: number): Date {
    const hasNoTimezone = new Date(date).toISOString().includes('00:00.000Z');
    const originalDate = hasNoTimezone ? date : new Date(date);

    const siteDate = new Date(originalDate);
    const diffHour = (-1 * siteDate.getTimezoneOffset()) / 60 - timeZoneOffset;
    siteDate.setHours(siteDate.getHours() - diffHour);

    return siteDate;
  }

  private getTimeString(date: Date): string {
    const hour = this.datePipe.transform(date, 'H');
    const minutes = this.datePipe.transform(date, 'mm');
    const second = this.datePipe.transform(date, 'ss');

    return `${hour}:${minutes}:${second}`;
  }

  /**
   * se utliza para convertir las fechas del server (UTC 0) a las fechas del sitio.
   * @param date fecha que se desea transformar
   * @param timeZoneOffset gmt del sitio donde pertenece el sujeto
   * @returns la fecha en el formato del proyecto en el GTM del sitio
   */
  transformToSiteDate(date: any, timeZoneOffset: number): any {
    const siteDate = this.getSiteDate(date, timeZoneOffset);
    return this.transform(siteDate);
  }

  getYear(date: Date): any {
    const currentYear = this.datePipe.transform(date, 'yyyy') || '0';
    return parseInt(currentYear);
  }
  getMonth(date: Date): any {
    const currentMonth = this.datePipe.transform(date, 'MM') || '0';
    return parseInt(currentMonth);
  }
  getDay(date: Date): any {
    const currentDay = this.datePipe.transform(date, 'dd') || '0';
    return parseInt(currentDay);
  }
  WeekOfYear(date: Date): any {
    const WeekOfYear = this.datePipe.transform(date, 'w') || '0';
    return parseInt(WeekOfYear);
  }
  DayOfMonth(date: Date): any {
    const DayOfMonth = this.datePipe.transform(date, 'd') || '0';
    return parseInt(DayOfMonth);
  }
  //Devuelve el numero de dias respecto al año y mes
  getDaysInMonth(year: any, month: any): any {
    return new Date(year, month, 0).getDate();
  }
  //Devuelve el numero de dias que lleva el año en la fecha actual
  getDaysInYear(year: any, month: any, day: any) {
    let numberDays = day;
    for (let i = 1; i < month; i++) {
      numberDays += this.getDaysInMonth(year, i);
    }
    return numberDays;
  }

  diff(date1: any, date2: any): string {
    const d1 = moment(date1);
    const d2 = moment(date2);
    const diffData = moment.duration(d2.diff(d1));

    const daysDiff = diffData.days();
    const hoursDiff = diffData.hours();
    const minutesDiff = diffData.minutes();

    let diffString = '';

    if (daysDiff) {
      diffString += `${daysDiff} ${this.translate('days')}`;
    }

    if (hoursDiff) {
      diffString += daysDiff ? ', ' : '';
      diffString += `${hoursDiff} ${this.translate('hours')}`;
    }

    if (minutesDiff) {
      diffString += daysDiff || hoursDiff ? ` ${this.translate('and')}` : '';
      diffString += ` ${minutesDiff} ${this.translate('minutes')}`;
    }

    return diffString || ` ${minutesDiff} ${this.translate('minutes')}`;
  }

  private translate(key: string): string {
    // Implement logic to translate the key into the desired language
    // You can use your translation service here
    return this.trialpalService.translateService.instant(
      `informedConsent.followUp.executionSummary.${key}`
    );
  }
}

export enum DateType {
  UNIQUE = 'UNIQUE',
  MULTIPLE = 'MULTIPLE',
}
