import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { JsonViewComponent } from 'src/app/modules/report/formly-designer/json-view/json-view.component';
import { AlertSubjectComponent } from 'src/app/modules/subjects/detail-subject/alert-subject/alert-subject.component';
import { DictionaryPipe } from '../pipes/dictionary.pipe';
import { ShortTextPipe } from '../pipes/short-text.pipe';
import { TextNaPipe } from '../pipes/text-na.pipe';
import { PrimengModule } from '../primeng.module';
import { AlertsEmailComponent } from './alerts-email/alerts-email.component';
import { AuditInfoComponent } from './audit-info/audit-info.component';
import { AuditQueryComponent } from './audit-query/audit-query.component';
import { BackButtonHeaderComponent } from './back-button-header/back-button-header.component';
import { ButtonRefreshDataComponent } from './button-refresh-data/button-refresh-data.component';
import { ButtonTableCardModeComponent } from './button-table-card-mode/button-table-card-mode.component';
import { ChangeReasonFormComponent } from './change-reason-form/change-reason.component';
import { ChangeReasonComponent } from './change-reason/change-reason.component';
import { ConfigurationsSymtomsMedicationsAppComponent } from './configurations-symtoms-medication/configurations-symtoms-medication.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { ExpandCollapseComponent } from './expand-collapse/expand-collapse.component';
import { InputFileComponent } from './input-file/input-file.component';
import { NotFoundItemsComponent } from './not-found-items/not-found-items.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { SignatureComponent } from './Signature/signature.component';
import { SimpleDictionaryEditorComponent } from './simple-dictionary-editor/simple-dictionary-editor.component';
import { SimpleDictionaryInputComponent } from './simple-dictionary-input/simple-dictionary-input.component';
import { EditAddDictionaryComponent } from './table-languages/edit-add-dictionary/edit-add-dictionary.component';
import { TableLanguagesComponent } from './table-languages/table-languages.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { Tp2CardComponent } from './tp2-card/tp2-card.component';
import { TPCardComponent } from './tpcard/tpcard.component';
import { TptableComponent } from './tptable/tptable.component';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { TpHelpComponent } from './tp-help/tp-help.component';
import { FeatureFlagDirective } from 'src/app/directives/feature-flags.directive';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { FormStepGuideComponent } from './form-step-guide/form-step-guide.component';
import { MaintenanceBannerComponent } from './maintenance-banner/maintenance-banner.component';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TourGuideButtonComponent } from './tour-guide-button/tour-guide-button.component';
@NgModule({
  declarations: [
    AlertsEmailComponent,
    AlertSubjectComponent,
    AuditInfoComponent,
    BackButtonHeaderComponent,
    ButtonRefreshDataComponent,
    ButtonTableCardModeComponent,
    ChangeReasonComponent,
    ChangeReasonFormComponent,
    ConfigurationsSymtomsMedicationsAppComponent,
    DeleteConfirmationComponent,
    DictionaryPipe,
    EditAddDictionaryComponent,
    InputFileComponent,
    NotFoundItemsComponent,
    ProgressSpinnerComponent,
    ShortTextPipe,
    SignatureComponent,
    SimpleDictionaryEditorComponent,
    SimpleDictionaryInputComponent,
    TableLanguagesComponent,
    TermsAndConditionsComponent,
    TextNaPipe,
    Tp2CardComponent,
    TPCardComponent,
    TptableComponent,
    ViewPdfComponent,
    AuditQueryComponent,
    JsonViewComponent,
    ExpandCollapseComponent,
    TpHelpComponent,
    FeatureFlagDirective,
    MaintenancePageComponent,
    FormStepGuideComponent,
    MaintenanceBannerComponent,
    OnBoardingComponent,
    TransformDatePipe,
    TourGuideButtonComponent,
  ],
  imports: [
    PrimengModule,
    TranslateModule,
    PdfViewerModule,
    MonacoEditorModule.forRoot(),
  ],
  exports: [
    AlertsEmailComponent,
    AlertSubjectComponent,
    AuditInfoComponent,
    BackButtonHeaderComponent,
    ButtonRefreshDataComponent,
    ButtonTableCardModeComponent,
    DeleteConfirmationComponent,
    ChangeReasonFormComponent,
    DictionaryPipe,
    InputFileComponent,
    NotFoundItemsComponent,
    ProgressSpinnerComponent,
    ShortTextPipe,
    SignatureComponent,
    SimpleDictionaryEditorComponent,
    SimpleDictionaryInputComponent,
    TextNaPipe,
    Tp2CardComponent,
    TPCardComponent,
    TptableComponent,
    TranslatePipe,
    TransformDatePipe,
    ViewPdfComponent,
    JsonViewComponent,
    ExpandCollapseComponent,
    TpHelpComponent,
    FeatureFlagDirective,
    MaintenancePageComponent,
    FormStepGuideComponent,
    MaintenanceBannerComponent,
    OnBoardingComponent,
    TourGuideButtonComponent,
  ],
})
export class SharedComponentsModule {}
