import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
const logger = new Logger('tp2-logger-userActivationPage');
@Component({
  selector: 'app-activation-users',
  templateUrl: './activation-users.component.html',
  styleUrls: ['./activation-users.component.scss'],
})
export class ActivationUsersComponent implements OnInit {
  userId = '';
  codigo = '';

  constructor(
    private router: Router,
    private trialpalService: TrialpalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.params.userId.trim();
  }

  onSubmit(form: NgForm): void {
    this.removeSpaces();
    this.confirmErrors(form);
    if (form.valid) {
      this.confirmCode();
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }
  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.userId = this.userId.trim();
    this.codigo = this.codigo.trim();
  }
  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(this.userId, 'username', form);
    this.trialpalService.validateSpaces(this.codigo, 'code', form);
  }
  sendAgain(): void {
    this.trialpalService.showSpinner('user.entityCode', 'SEND');
    Auth.resendSignUp(this.userId.trim())
      .then((data) => {
        logger.debug('resendSignUp response: ', data);
        this.trialpalService.showMutationSuccess('user.entityCode', 'SEND');
      })
      .catch((error) => {
        logger.error('error al resendSignUp', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  confirmCode(): void {
    this.trialpalService.showSpinner('user.entityCode', 'SEND');
    Auth.confirmSignUp(this.userId, this.codigo)
      .then((data: any) => {
        logger.debug('confirmSignUp response', data);
        if (data === 'SUCCESS') {
          Auth.signOut(); // Con esto aseguramos limpiar el sessionStorage de las sesiones anteriores.
          this.router.navigate(['']);
          this.trialpalService.showMutationSuccess('user.entity', 'UPDATE');
        }
      })
      .catch((error: any) => {
        logger.error('confirmSignUp error', error);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
}
