import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import {
  InstanceState,
  MedicationGivenTo,
} from 'src/app/modules/conciliation/conciliation.types';
import { PaginationService } from 'src/app/modules/conciliation/pagination.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ShortTextPipe } from 'src/app/shared/pipes/short-text.pipe';
import { TP2Entites } from '../../../../../../shared/global.variables';
import { ConciliationComponents } from '../../../../conciliationData';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import { SymptomService } from '../../symptom/symptom.service';
import { MedicationService } from '../medication.service';
import { SummaryModalComponent } from '../summary-modal/summary-modal.component';
const logger = new Logger('MedicationInstance conciliation edit');
@Component({
  selector: 'app-edit-medication',
  templateUrl: './edit-medication.component.html',
  styleUrls: ['./edit-medication.component.scss'],
})
export class EditMedicationComponent implements OnChanges {
  @Input() currentMedication: any = {};
  @Output()
  exportAuditRecord: EventEmitter<any> = new EventEmitter();
  configuration: any = {};
  verifiedDayInstance: any;
  form: FormGroup;
  givenTo: any = [];
  formChange: any = {};
  symptoms: any[] = [];
  formValues: any = {};
  minDate: Date = new Date();
  maxDate: Date = new Date();
  isDelete: boolean = false;

  //New medication
  dayInstances: any[] = [];
  isNew: boolean = false;
  dayInstanceSelected: any = {};
  booleanOptions: any[] = [];

  constructor(
    public dialogService: DialogService,
    public medicationService: MedicationService,
    private conciliationService: ConciliationService,
    private trialpalService: TrialpalService,
    private symptomService: SymptomService,
    private formBuilder: FormBuilder,
    private paginationService: PaginationService,
    private dayInstanceService: DayInstanceService
  ) {
    this.form = this.formBuilder.group({});
    this.buildForm();
  }

  async ngOnChanges() {
    if (this.currentMedication !== 'isNew') {
      await this.symptomService.getSymptomsMedicationAndMedicalAttention();
      this.initMedicationInstance();
    } else {
      this.getDayInstances();
    }
  }

  async initMedicationInstance() {
    logger.debug('currentMedication', this.currentMedication);
    this.buildForm();
    this.formChange = {};
    this.booleanOptions = this.conciliationService.buildBooleanOptions();
    //Filtra los sintomas perteneciente a la instancia del medicamento
    this.symptoms = this.symptomService.symptoms?.filter(
      (sym: any) =>
        sym?.dayInstanceId ===
          this.currentMedication?.medication?.dayInstanceId &&
        sym.state !== InstanceState.DELETED
    );

    this.givenTo = [
      {
        label: this.trialpalService.translateService.instant(
          'medicationinstance.enums.PREVENT'
        ),
        value: 'PREVENT',
      },
      {
        label: this.trialpalService.translateService.instant(
          'medicationinstance.enums.TREAT'
        ),
        value: 'TREAT',
      },
    ];
    if (this.currentMedication?.dayInstance?.id) {
      this.verifiedDayInstance =
        await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
          this.currentMedication.medication.dayInstanceId
        );
    }
    let formValues =
      this.currentMedication?.verifiedMedication ||
      this.currentMedication?.medication;
    this.isDelete =
      this.currentMedication?.verifiedMedication?.state ===
      InstanceState.DELETED;

    this.showAuditComponent(formValues);
    this.configuration = this.currentMedication?.configuration; // Obtiene la configuración del medicamento

    //Rango de fechas que puede el usuario seleccionar
    this.minDate = new Date(
      this.currentMedication?.dayInstance?.startDate + 'T00:00:00'
    );
    this.maxDate = new Date(
      this.currentMedication?.dayInstance?.finishDate + 'T00:00:00'
    );
    this.setFormValues(formValues);
  }

  //Funcion que se encarga de llenar el formulario con la información del medicamento
  setFormValues(formValues: any) {
    this.form.patchValue({
      //VerifiedName
      name: formValues?.name,
      //verifiedNameComment
      nameComment: this.currentMedication?.verifiedMedication?.nameComment,
      //verifiedAdministrationRoute
      administrationRoute: formValues?.administrationRoute,
      //verifiedAdministrationRouteComment
      administrationRouteComment:
        this.currentMedication?.verifiedMedication?.administrationRouteComment,
      //VerfiedGivenTo
      givenTo: this.orderArray(formValues?.givenTo),
      givenToComment:
        this.currentMedication?.verifiedMedication?.givenToComment,
      //VerifiedSymptomInstance
      symptomsInstances: this.orderArray(formValues?.symptomsInstances),
      symptomsInstancesComment:
        this.currentMedication?.verifiedMedication?.symptomsInstancesComment,
      //verifiedReason
      reason: formValues?.reason,
      //verifiedReasonComment
      reasonComment: this.currentMedication?.verifiedMedication?.reasonComment,
      //verifiedDose
      dose: formValues?.dose,
      //verifiedDoseComment
      doseComment: this.currentMedication?.verifiedMedication?.doseComment,
      //verifiedFrequency
      frequency: formValues?.frequency,
      //verifiedFrequencyComment
      frequencyComment:
        this.currentMedication?.verifiedMedication?.frequencyComment,
      //verifiedRememberStartDate
      rememberStartDate: formValues?.rememberStartDate,
      //verifiedStartDate
      startDate: this.conciliationService.getFormatDate(formValues?.startDate),
      //verifiedStartDateComment
      startDateComment:
        this.currentMedication?.verifiedMedication?.startDateComment,
      //verifiedRememberFinishDate
      rememberFinishDate: formValues?.rememberFinishDate,
      //verifiedFinishDate
      finishDate: this.conciliationService.getFormatDate(
        formValues?.finishDate
      ),
      //verifiedFinishDateComment
      finishDateComment:
        this.currentMedication?.verifiedMedication?.finishDateComment,
    });
    this.formChange = Object.assign(this.form.value);
  }

  //Funcion que se encarga de mostrar el componente de auditoria
  showAuditComponent(formValues: any) {
    this.exportAuditRecord.emit(null);
    if (this.currentMedication?.isEdition) {
      this.exportAuditRecord.emit({
        entity: TP2Entites.VERIFIEDMEDICATIONINSTANCE,
        id: this.currentMedication.verifiedMedication.id,
        description: formValues?.name || '',
      });
    }
  }

  getDayInstances() {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    let confMedications = this.medicationService.confMedications;
    const verifiedDayInstances = this.dayInstanceService.verifiedDayInstances;
    //Filtra los días que tengan habilitado la opcion de otros sintomas y permitan crear
    dayInstances = dayInstances.filter((DI: any) => {
      return this.isValidDayInstance(DI, verifiedDayInstances, confMedications);
    });

    //Valida que solo muestres los dias que ya hayan pasado y tengan habilitado la opcion de conciliar
    dayInstances = dayInstances.filter((DI: any) => {
      const IsMedicationConciliationAllowed =
        this.paginationService.isSectionReconciliationAllowed(
          'isMedicationRequired',
          DI.configuration
        );

      const medicationReconciliationConfig =
        DI.dayInstance?.confDay?.confForm?.medicationReconciliationConfig;

      return (
        this.conciliationService.isBeforeToCurrentDate(
          DI.dayInstance.finishDate
        ) &&
        IsMedicationConciliationAllowed &&
        medicationReconciliationConfig?.allowAddRecords
      );
    });
    logger.debug('dayInstancesMed', dayInstances);

    //Transforma el arreglo para que se muestre el nombre del dia y como valor el mismo día
    this.dayInstances = dayInstances.map((DI: any) => {
      return {
        label: this.trialpalService.dictionaryPipe.transform(
          DI.dayInstance.confDay.dayName
        ),
        value: DI,
      };
    });

    this.dayInstances.sort(
      (a: any, b: any) =>
        a?.value?.dayInstance?.confDay?.order -
        b?.value?.dayInstance?.confDay?.order
    );
  }

  //Verifica si la instancia de dia tiene medicamentos activo
  isValidDayInstance(
    DI: any,
    verifiedDayInstances: any[],
    confMedications: any[]
  ) {
    const verified = verifiedDayInstances?.find((VDI: any) => {
      return VDI?.dayInstanceId === DI.dayInstance.id;
    });
    const confMedication = confMedications.find((_confMedication: any) => {
      return _confMedication?.confFormId === DI.dayInstance?.confDay.confFormId;
    });

    if (confMedication?.showPrecondition) {
      return verified?.hasMedication;
    }
    return DI.configuration.isMedicationRequired;
  }

  orderArray(array: any[]) {
    if (!array) return null;
    return array.sort();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [],
      nameComment: [],
      administrationRoute: [],
      administrationRouteComment: [],
      reason: [],
      reasonComment: [],
      givenTo: [],
      givenToComment: [],
      dose: [],
      doseComment: [],
      symptomsInstances: [],
      symptomsInstancesComment: [],
      frequency: [],
      frequencyComment: [],
      rememberStartDate: [],
      startDate: [],
      startDateComment: [],
      rememberFinishDate: [],
      finishDate: [],
      finishDateComment: [],
      _changeReason: [],
    });
  }

  //Filtra los sintomas segun si es prevencion o tratamiento
  getSymtomsFiltered(): any[] {
    let symtomsFiltered = [];
    let givenTo = [];
    if (this.isgeneralMode()) {
      givenTo = this.verifiedDayInstance.medicationGivenTo;
    } else {
      givenTo = this.form.controls['givenTo']?.value;
    }

    const symptomsTreat = this.symptoms?.filter(
      (symptom: any) => symptom.ocurrend
    );
    const symptomsPrevent = this.symptoms?.filter(
      (symptom: any) => !symptom.ocurrend
    );
    if (givenTo?.includes(MedicationGivenTo.PREVENT)) {
      symtomsFiltered.push(...symptomsPrevent);
    }

    if (givenTo?.includes(MedicationGivenTo.TREAT)) {
      symtomsFiltered.push(...symptomsTreat);
    }

    symtomsFiltered = symtomsFiltered.map((sym: any) => {
      //slice the symptomLabel
      let shortTextPipe = new ShortTextPipe();
      sym.symptomLabelShortened = shortTextPipe.transform(sym.symptomLabel, 40);
      return sym;
    });
    return symtomsFiltered || [];
  }

  //Filtra el giventTo segun la ocurrencia de los medicamentos
  getGivenToFiltered() {
    if (this.isSymptomsEnabled()) {
      const isEveryOcurrend = this.symptoms?.every((sym: any) => sym.ocurrend);
      const isEveryNoOcurrend = this.symptoms?.every(
        (sym: any) => !sym.ocurrend
      );
      if (isEveryNoOcurrend) return [this.givenTo[0]];
      if (isEveryOcurrend) return [this.givenTo[1]];
    }
    return this.givenTo;
  }

  //=============== Codigo que se encarga de la creación o actualización de datos ===========

  async onSubmit() {
    logger.debug(this.form, 'formulario medication');
    if (this.isDelete) return this.nextMedication();
    this.conciliationService.clearSpaces(this.form);

    //Valida si el formulario de razon de cambio y medicamentos son validos
    if (this.form.valid) {
      const isValidDates = this.conciliationService.isValidDates(this.form);
      if (!isValidDates) return this.conciliationService.messageErrorDates();

      //Valida si hubo un cambio de datos en el formulario
      this.formatValues();
      const isFormChange = this.conciliationService.isFormChange(
        this.form.value,
        this.formChange
      );

      //Valida si debe mostrar el modal con los cambios o actualizar solo los comentarios
      if (isFormChange) {
        this.showSummaryComponent();
      } else {
        const isCommentChange = this.conciliationService.isFormCommentChange(
          this.form.value,
          this.formChange
        );
        await this.sendVerifiedMedication(isCommentChange);
      }
    } else {
      this.conciliationService.messageError(
        'conciliation.messageError.summary',
        'general.incompleteFormMessage'
      );
    }
  }

  showSummaryComponent() {
    const [original, verified] = this.conciliationService.getValuesChange(
      this.formChange,
      this.form.value
    );
    logger.debug(original, verified, 'verifiedverified');
    const ref = this.dialogService.open(SummaryModalComponent, {
      data: {
        original,
        verified,
        medication: this.currentMedication?.medication,
        configuration: this.currentMedication.configuration,
      },
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmSaveForm'
      ),
      style: { 'min-width': '360px', width: '50%' },
    });

    ref.onClose.subscribe(async (canPass: boolean) => {
      if (canPass) {
        this.conciliationService.recordChangeEvent(
          verified,
          original,
          'medication'
        );
        await this.sendVerifiedMedication(true);
      }
    });
  }

  formatValues() {
    if (!this.isSymptomsEnabled) {
      this.form.controls['symptomsInstances']?.setValue(null);
      this.formChange.symptomsInstances = null;
    } else {
      this.form.controls['symptomsInstances']?.setValue(
        this.orderArray(this.form.controls['symptomsInstances']?.value)
      );
    }

    //Ordena el givenTo
    if (this.isGivenToEnabled()) {
      this.form.controls['givenTo']?.setValue(
        this.orderArray(this.form.controls['givenTo']?.value)
      );
    }
  }

  async sendVerifiedMedication(isChange: boolean = false) {
    if (this.currentMedication.isEdition) {
      //Si no hubo un cambio pasa al siguiente medicamento
      if (!isChange) {
        this.nextMedication();
      } else {
        await this.updateVerifiedMedicationInstance();
      }
    } else {
      await this.createVerifiedMedicationInstance();
    }
  }

  async createVerifiedMedicationInstance() {
    this.conciliationService.showSpinner(
      'transactionLoadingCreate',
      'medicationinstance.entity'
    );
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    await this.medicationService
      .createVerifiedMedication(
        this.form.value,
        this.currentMedication.medication
      )
      .then((medicationCreate: any) => {
        logger.debug('formulario', this.currentMedication, medicationCreate);
        this.updateTable(medicationCreate); // Actualiza la data
        this.conciliationService.messageSuccess(true);
      })
      .catch((err: any) => {
        logger.error(err, 'error created');
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async updateVerifiedMedicationInstance() {
    this.conciliationService.showSpinner(
      'transactionLoadingUpdate',
      'medicationinstance.entity'
    );
    const reason = this.form.controls['_changeReason'].value;
    this.currentMedication.verifiedMedication._changeReason = reason;
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    await this.medicationService
      .updateVerifiedMedication(
        this.form.value,
        this.currentMedication.verifiedMedication
      )
      .then((medicationUpdate: any) => {
        //Actualiza las variables
        this.updateTable(medicationUpdate);
        logger.debug('update data', this.currentMedication, medicationUpdate);
        this.conciliationService.messageSuccess(false);
      })
      .catch((err: any) => {
        logger.error(err, 'error updated');
        this.conciliationService.messageError(
          'conciliation.messageError.summary',
          'conciliation.messageError.detail'
        );
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  updateTable(verifiedMedication: any) {
    const isNewMedication =
      this.currentMedication.medication.id ===
        this.conciliationService.NEW_INSTANCE_ID &&
      !this.currentMedication.isEdition;

    if (isNewMedication) {
      this.medicationService.tableMedications.push(this.currentMedication);
    }
    this.currentMedication.isEdition = true;
    this.currentMedication.verifiedMedication = verifiedMedication;

    this.currentMedication.symptomsVerified = this.symptomService.getSymptoms(
      verifiedMedication?.symptomsInstances,
      this.currentMedication.medication
    );
    //LLena los comentarios en las instancias de los medicamentos
    this.medicationService.addMedicationComments();
    if (isNewMedication) {
      this.paginationService.selectedMedication({}, true);
    } else {
      this.nextMedication();
    }
  }

  //=============== Codigo que se encarga de verificar si hubo un cambio en los campos ===========
  //Obtiene si hubo un cambio en el campo de name
  isChangeName() {
    //Verifica cuando hay un cambio
    const isChangeName =
      this.form.get('name')?.value !== this.formChange?.name ||
      this.currentMedication?.verifiedMedication?.nameComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['nameComment'],
      isChangeName
    );
    return isChangeName;
  }

  //Obtiene si hubo un cambio en el campo de en reason
  isChangeReason() {
    //Verifica cuando hay un cambio
    const isChangeReason =
      this.form.get('reason')?.value != this.formChange?.reason ||
      this.currentMedication?.verifiedMedication?.reasonComment;

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['reasonComment'],
      isChangeReason && !this.shouldHideReason()
    );
    return isChangeReason;
  }

  //Obtiene si hubo un cambio en el campo de administrationRoute
  isChangeAdministrationRoute() {
    //Verifica cuando hay un cambio
    const isChangeAdministration =
      this.form.get('administrationRoute')?.value !==
        this.formChange?.administrationRoute ||
      this.currentMedication?.verifiedMedication?.administrationRouteComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['administrationRouteComment'],
      isChangeAdministration && !this.shouldHideRoute()
    );
    return isChangeAdministration;
  }

  //Obtiene si hubo un cambio en el campo de dose
  isChangeDose() {
    //Verifica cuando hay un cambio
    const isChangeDose =
      this.form.get('dose')?.value !== this.formChange?.dose ||
      this.currentMedication?.verifiedMedication?.doseComment;

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['doseComment'],
      isChangeDose && !this.shouldHideDosis()
    );
    return isChangeDose;
  }

  //Obtiene si hubo un cambio en el campo de frequency
  isChangeFrequency() {
    //Verifica cuando hay un cambio
    const isChangeFrequency =
      this.form.get('frequency')?.value !== this.formChange?.frequency ||
      this.currentMedication?.verifiedMedication?.frequencyComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['frequencyComment'],
      isChangeFrequency
    );
    return isChangeFrequency;
  }

  //Obtiene si hubo un cambio en el campo de rememberStartDate
  isChangeRememberStartDate() {
    const rememberStartDate = this.form.controls['rememberStartDate']?.value;
    //Verifica cuando hay un cambio
    const isChangeRememberStartDate =
      rememberStartDate !== this.formChange?.rememberStartDate ||
      this.currentMedication?.verifiedMedication?.startDateComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['startDateComment'],
      (isChangeRememberStartDate || this.isChangeStartDate()) &&
        !this.shouldHideDateFields()
    );
    return isChangeRememberStartDate;
  }

  //Obtiene si hubo un cambio en el campo de startDate
  isChangeStartDate() {
    const changeDate = this.getMomentFormatDate(
      this.conciliationService.getFormatDate(
        this.form.controls['startDate']?.value
      )
    );
    const originalDate = this.getMomentFormatDate(this.formChange?.startDate);
    //Verifica cuando hay un cambio
    const isChangeStartDate =
      changeDate !== originalDate ||
      this.currentMedication?.verifiedMedication?.startDateComment;
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['startDateComment'],
      isChangeStartDate && !this.shouldHideDateFields()
    );
    return isChangeStartDate;
  }

  //Obtiene si hubo un cambio en el campo de rememberFinishDate
  isChangeRememberFinishDate() {
    const rememberFinishDate = this.form.controls['rememberFinishDate']?.value;

    const isChangeRememberFinishDate =
      rememberFinishDate !== this.formChange?.rememberFinishDate ||
      Boolean(this.currentMedication?.verifiedMedication?.finishDateComment);

    this.conciliationService.setValidator(
      this.form.controls['finishDateComment'],
      (isChangeRememberFinishDate || this.isChangeFinishDate()) &&
        !this.shouldHideDateFields()
    );

    return isChangeRememberFinishDate;
  }

  //Obtiene si hubo un cambio en el campo de finishDate
  isChangeFinishDate() {
    const changeDate = this.getMomentFormatDate(
      this.conciliationService.getFormatDate(
        this.form.controls['finishDate']?.value
      )
    );
    const originalDate = this.getMomentFormatDate(this.formChange?.finishDate);

    //Verifica cuando hay un cambio
    const isChangeFinishDate =
      changeDate !== originalDate ||
      Boolean(this.currentMedication?.verifiedMedication?.finishDateComment);
    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['finishDateComment'],
      isChangeFinishDate && !this.shouldHideDateFields()
    );
    return isChangeFinishDate;
  }

  getMomentFormatDate(date: any) {
    return moment(date).format('DD-MM-YYYY');
  }

  //Obtiene si hubo un cambio en el campo de givenTo
  isChangeGivenTo() {
    //Verifica cuando hay un cambio
    const isChangeGivenTo =
      JSON.stringify(this.orderArray(this.form.controls['givenTo']?.value)) !==
        JSON.stringify(this.orderArray(this.formChange?.givenTo)) ||
      this.currentMedication?.verifiedMedication?.givenToComment;

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['givenToComment'],
      isChangeGivenTo && !this.shouldHideTreatment()
    );
    return isChangeGivenTo;
  }

  //Obtiene si hubo un cambio en el campo de givenTo
  isChangeSymptomsInstances() {
    //Verifica cuando hay un cambio
    const isChangeSymptomsInstances =
      JSON.stringify(
        this.orderArray(this.form.controls['symptomsInstances']?.value)
      ) !==
        JSON.stringify(this.orderArray(this.formChange?.symptomsInstances)) ||
      this.currentMedication?.verifiedMedication?.symptomsInstancesComment;

    //Envia si será requerido o no
    this.conciliationService.setValidator(
      this.form.controls['symptomsInstancesComment'],
      isChangeSymptomsInstances
    );
    return isChangeSymptomsInstances;
  }

  //========== campos requeridos por la configuración en el medicamentos actual===================
  isReasonEnabled() {
    const isReasonEnabled = this.medicationService.isReasonEnabled(
      this.configuration
    );
    this.conciliationService.setValidator(
      this.form.controls['reason'],
      isReasonEnabled && !this.shouldHideReason()
    );
    return isReasonEnabled;
  }

  isGivenToEnabled() {
    const isGivenToEnabled =
      this.configuration?.mode ===
        this.medicationService.individualGivenToSymptomBinding ||
      this.configuration?.mode ===
        this.medicationService.individualGivenToOpenReason;

    this.conciliationService.setValidator(
      this.form.controls['givenTo'],
      isGivenToEnabled && !this.shouldHideTreatment()
    );
    return isGivenToEnabled;
  }

  isSymptomsEnabled() {
    const isSymptomsEnabled = this.medicationService.isSymptomsEnabled(
      this.configuration
    );
    this.conciliationService.setValidator(
      this.form.controls['symptomsInstances'],
      isSymptomsEnabled && !this.shouldHideReason()
    );
    return isSymptomsEnabled;
  }

  isAdminitrationRouteEnabled() {
    const isAdminitrationRouteEnabled =
      this.configuration?.showAdministrationRoute;
    this.conciliationService.setValidator(
      this.form.controls['administrationRoute'],
      isAdminitrationRouteEnabled && !this.shouldHideRoute()
    );
    return isAdminitrationRouteEnabled;
  }

  isFrequencyEnabled() {
    const isFrequencyEnabled = this.configuration?.showFrequency;
    this.conciliationService.setValidator(
      this.form.controls['frequency'],
      isFrequencyEnabled
    );
    return isFrequencyEnabled;
  }

  isShowRememberStartDateEnabled() {
    const isShowRememberStartDateEnabled =
      this.configuration?.showRememberStartDate;
    this.conciliationService.setValidator(
      this.form.controls['rememberStartDate'],
      isShowRememberStartDateEnabled && !this.shouldHideDateFields()
    );
    return isShowRememberStartDateEnabled;
  }

  isStartDateEnabled() {
    const isStartDateEnabled = this.form.controls['rememberStartDate']?.value;
    this.conciliationService.setValidator(
      this.form.controls['startDate'],
      isStartDateEnabled && !this.shouldHideDateFields()
    );
    return isStartDateEnabled;
  }

  isShowRememberFinishEnabled() {
    const isShowRememberFinishEnabled =
      this.configuration?.showRememberFinishDate;
    this.conciliationService.setValidator(
      this.form.controls['rememberFinishDate'],
      isShowRememberFinishEnabled && !this.shouldHideDateFields()
    );
    return isShowRememberFinishEnabled;
  }

  isFinishDateEnabled() {
    const isFinishDateEnabled = this.form.controls['rememberFinishDate']?.value;
    this.conciliationService.setValidator(
      this.form.controls['finishDate'],
      isFinishDateEnabled && !this.shouldHideDateFields()
    );
    return isFinishDateEnabled;
  }

  isDoseEnabled() {
    const isDoseEnabled = this.configuration?.showDose;
    this.conciliationService.setValidator(
      this.form.controls['dose'],
      isDoseEnabled && !this.shouldHideDosis()
    );
    return isDoseEnabled;
  }

  isgeneralMode() {
    return (
      this.configuration?.mode ===
        this.medicationService.generalGivenToSymptomBinding ||
      this.configuration?.mode ===
        this.medicationService.generalGivenToOpenReason
    );
  }

  //=============== Codigo que se encarga del movimiento entre medicamentos o componentes ===========

  //Avanaza al siguiente medicamento o al siguiente componente
  nextMedication() {
    if (
      this.currentMedication?.index + 1 ===
      this.medicationService.tableMedications?.length
    ) {
      return this.paginationService.nextSection(
        ConciliationComponents.MEDICATION
      );
    } else {
      this.paginationService.selectedMedication(
        this.medicationService.tableMedications[
          this.currentMedication?.index + 1
        ]
      );
    }
  }

  //Retrocede al anterior medicamento o al anterior componente
  previousMedication() {
    if (this.currentMedication?.index === 0) {
      if (this.medicationService.medicationColDays?.length > 1) {
        this.medicationService.currentMedication = {};
        const lastIndex = this.medicationService.medicationColDays.length - 1;
        return this.paginationService.dayInstanceSelected(
          this.medicationService.medicationColDays[lastIndex],
          ConciliationComponents.MEDICATION
        );
      }
      return this.paginationService.goToPreviousSection();
    }
    return this.paginationService.selectedMedication(
      this.medicationService.tableMedications[this.currentMedication?.index - 1]
    );
  }

  //Funcion que se encarga de crear el esquelo para un medicamento creado desde el site
  async newMedicationInstance() {
    const confFormId =
      this.dayInstanceSelected?.dayInstance?.confDay?.confFormId || '';
    this.configuration = this.medicationService.confMedications.find(
      (confMedication: any) => {
        return confMedication.confFormId === confFormId;
      }
    );
    const dayInstance = this.dayInstanceSelected?.dayInstance;
    this.paginationService.dayName = dayInstance.confDay.dayName;
    this.currentMedication = {
      configuration: this.configuration,
      dayInstance: dayInstance,
      confForm: dayInstance.confDay?.confForm,
      index: this.medicationService.tableMedications.length,
      isEdition: false,
      medication: this.medicationService.getMedicationInstanceEmpty(
        dayInstance.id
      ),
      verifiedMedication: {},
      symptomsInstances: [],
      symptomsVerified: [],
      comments: {},
    };
    this.dayInstanceSelected = {};
    this.medicationService.isNewMedicationInstance = false;
    this.medicationService.currentMedication = this.currentMedication;
    this.initMedicationInstance();
  }

  shouldHideEntity(configVariable: any) {
    if (
      !configVariable &&
      this.currentMedication?.medication?.id ===
        this.conciliationService.NEW_INSTANCE_ID
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldDisableEntity(configVariable: any) {
    if (
      !configVariable &&
      this.currentMedication?.medication?.id !==
        this.conciliationService.NEW_INSTANCE_ID
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldDisableDateFields() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldDisableEntity(
      medicationReconciliationConfig?.allowOcurencyDateReconciliation
    );
  }

  shouldHideDateFields() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldHideEntity(
      medicationReconciliationConfig?.allowOcurencyDateReconciliation
    );
  }

  shouldDisableReason() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldDisableEntity(
      medicationReconciliationConfig?.allowReasonReconciliation
    );
  }

  shouldHideReason() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldHideEntity(
      medicationReconciliationConfig?.allowReasonReconciliation
    );
  }

  shouldDisableTreatment() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldDisableEntity(
      medicationReconciliationConfig?.allowTreatmentReconciliation
    );
  }

  shouldHideTreatment() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldHideEntity(
      medicationReconciliationConfig?.allowTreatmentReconciliation
    );
  }

  shouldDisableRoute() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldDisableEntity(
      medicationReconciliationConfig?.allowRouteReconciliation
    );
  }

  shouldHideRoute() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldHideEntity(
      medicationReconciliationConfig?.allowRouteReconciliation
    );
  }

  shouldDisableDosis() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldDisableEntity(
      medicationReconciliationConfig?.allowDosisReconciliation
    );
  }

  shouldHideDosis() {
    const medicationReconciliationConfig =
      this.currentMedication?.confForm?.medicationReconciliationConfig;
    return this.shouldHideEntity(
      medicationReconciliationConfig?.allowDosisReconciliation
    );
  }

  //Funcion que se encarga de detectar si hubo un cambio en el formulario
  get isChangeForm() {
    return (
      this.conciliationService.isFormChange(this.form.value, this.formChange) ||
      this.conciliationService.isFormCommentChange(
        this.form.value,
        this.formChange
      )
    );
  }
}
