<div class="container-tp2">
  <h2>{{ 'project.h4ReportStatus' | translate }}</h2>
  <form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="p-grid p-fluid p-justify-start">
      <div class="p-col-12 p-md-6 p-xl-3 p-field">
        <p-dropdown
          id="report"
          ngDefaultControl
          class="input"
          name="reports"
          [options]="reports"
          [(ngModel)]="report"
          placeholder="{{ 'report.entityPlural' | translate }}"
          #grupos="ngModel"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          (onChange)="getReportType()"
          ><ng-template let-reports pTemplate="item">
            <div>
              <div pTooltip="{{ reports.label }}">
                {{ reports.label | shortText: 100 }}
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <p-dropdown
          id="frecuency"
          ngDefaultControl
          class="input"
          name="frecuencies"
          [options]="frequencies"
          [(ngModel)]="frequency"
          placeholder="{{ 'general.frequency.entity' | translate }}"
          #grupos="ngModel"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
        >
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3" *ngIf="isOnDemandReport">
        <p-dropdown
          id="OnDemand"
          ngDefaultControl
          class="input"
          name="onDemands"
          [options]="graphTypes"
          [(ngModel)]="graphType"
          placeholder="{{ 'general.graphTypes.entity' | translate }}"
          #grupos="ngModel"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
        >
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-4 p-xl-2 p-field">
        <button
          pButton
          id="btn_shearch"
          type="submit"
          [disabled]="isDisabledSearchButton()"
          [label]="'general.find' | translate"
        ></button>
      </div>
    </div>
  </form>

  <div class="p-grid p-fluid;" *ngIf="!showGraph">
    <h4 style="width: 100%; text-align: center">
      {{ 'report.statusReportHelp' | translate }}
    </h4>
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
  </div>
  <section *ngIf="showGraph">
    <div class="p-grid">
      <div class="card p-col">
        <h3 id="title">{{ title }}</h3>
        <p-chart
          id="chart"
          type="bar"
          [data]="stackedData"
          [options]="stackedOptions"
          width="80vw"
          height="80vh"
        ></p-chart>
      </div>
    </div>
    <section class="p-mb-5">
      <div
        class="p-col-12"
        style="display: flex"
        *ngIf="graphType !== 'TOTALS'"
      >
        <label for="diaryPending" style="margin-right: 1rem">
          {{ pending }}
        </label>
        <p-inputSwitch
          pTooltip="{{ 'report.tooltipPendingFrecuencyToggle' | translate }}"
          id="diaryPending"
          [(ngModel)]="filter"
          (onChange)="reportMasterWithCurrentTime()"
        ></p-inputSwitch>
      </div>
      <app-tptable
        [dataTable]="dataTable"
        (openDetail)="goDetailSubject($event)"
      ></app-tptable>
    </section>
    <br />
    <br />
  </section>
</div>
