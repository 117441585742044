<div class="p-grid container">
  <div
    class="p-col-12"
    *ngIf="relatedEntity !== 'REPORT' && hasDiarySymptomCreate"
  >
    <div class="p-grid p-fluid">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-2">
        <button
          pButton
          (click)="openForm(false)"
          icon="pi pi-plus"
          type="button"
          label="{{ 'general.add' | translate }}"
        ></button>
      </div>
    </div>
  </div>
  <div *ngIf="relatedEntity === 'REPORT'" class="p-col-12">
    <div
      class="p-grid p-fluid p-ai-stretch p-mb-2"
      style="border-bottom: 1px solid #adadad"
    >
      <div class="p-col-12 p-md-6">
        <h4 class="p-mt-0">
          {{ 'report.labelsPageLagout.title' | translate }}
        </h4>
        <p>
          <strong
            >{{ 'ediary.enums.pageLayouts.ONE_BY_ONE' | translate }}: </strong
          >{{ 'report.labelsPageLagout.LABEL_ONE_BY_ONE' | translate }}
        </p>
        <p>
          <strong
            >{{
              'ediary.enums.pageLayouts.OCURRENCE_FIRST' | translate
            }}: </strong
          >{{ 'report.labelsPageLagout.LABEL_OCURRENCE_FIRST' | translate }}
        </p>
      </div>
      <div class="p-col-12 p-md-6 p-d-flex p-flex-column p-jc-between p-mb-4">
        <button
          pButton
          id="btnAddReport"
          (click)="openForm(false)"
          icon="pi pi-plus"
          type="button"
          style="margin-left: auto; width: 40%"
          label="{{ 'general.add' | translate }}"
        ></button>
        <div class="p-ml-lg-6">
          <label for="symptomPageLayout"
            >{{ 'report.labelPageLayout' | translate
            }}<span class="inputRequired"> * </span></label
          >
          <p-dropdown
            appendTo="body"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [options]="pageLayouts"
            id="symptomPageLayout"
            optionLabel="name"
            optionValue="value"
            name="symptomPageLayout"
            #symptomPageLayouts="ngModel"
            [(ngModel)]="symptomPageLayout"
            (ngModelChange)="changePageLayout.emit($event)"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <div class="p-ml-5" *ngIf="confSymptoms.length === 0">
      <h4>
        <em>{{ 'general.noInfo' | translate }}</em>
      </h4>
    </div>
    <div class="grid-cards" *ngIf="symptomCards.length > 0">
      <app-tp2-card
        *ngFor="let symptom of symptomCards"
        [tp2Card]="symptom"
      ></app-tp2-card>
    </div>
  </div>
</div>
