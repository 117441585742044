<form
  class="p-grid form-window"
  [formGroup]="form"
  (submit)="saveNewWindow()"
  autocomplete="off"
>
  <div class="p-col-12 p-md-8 p-grid">
    <div class="p-col-12 p-md-6">
      <label for="txt_name" id="lbl_name" class="p-mb-2">{{
        'ediary.components.generalConfiguration.conciliation.formName'
          | translate
      }}</label>
      <input
        id="txt_name"
        name="name"
        type="text"
        formControlName="name"
        pInputText
        placeholder="{{
          'ediary.components.generalConfiguration.conciliation.formPlaceHolderName'
            | translate
        }}"
      />
      <small
        class="form-error"
        *ngIf="form.controls['name']?.invalid && form.controls['name']?.touched"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-12 p-md-6">
      <div class="p-d-flex p-ai-center p-mb-2">
        <label for="drop_phases" id="lbl_phase">{{
          'ediary.components.generalConfiguration.conciliation.formPhase'
            | translate
        }}</label>
        <span
          class="p-pl-2 p-d-flex info-icon"
          [pTooltip]="
            'ediary.components.generalConfiguration.conciliation.formTooltipPhase'
              | translate
          "
          tooltipPosition="top"
          ><span class="pi pi-info-circle"></span
        ></span>
      </div>
      <p-multiSelect
        id="drop_phases"
        name="phases"
        optionLabel="label"
        optionValue="value"
        formControlName="phases"
        class="input p-col-12 p-p-0 p-mr-1 p-d-block"
        appendTo="body"
        (onChange)="
          validateTotalWindowDays(this.form.controls['totalWindowDays'].value)
        "
        [options]="phases"
        placeholder="{{
          'ediary.components.generalConfiguration.conciliation.selectPlacehodelPhase'
            | translate
        }}"
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
      >
      </p-multiSelect>
      <small
        class="form-error"
        *ngIf="
          form.controls['phases']?.invalid && form.controls['phases']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-12 p-py-0">
      <small>{{
        'ediary.components.generalConfiguration.conciliation.configureWindowMessage'
          | translate
      }}</small>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="p-d-flex p-ai-center p-mb-2">
        <label for="drop_daysAfterVisit" id="lbl_daysAfterVisit">{{
          'ediary.components.generalConfiguration.conciliation.formWindowActivation'
            | translate
        }}</label>
        <span
          class="p-pl-2 p-d-flex info-icon"
          [pTooltip]="
            'ediary.components.generalConfiguration.conciliation.formTooltipDaysAfterVisit'
              | translate
          "
          tooltipPosition="top"
          ><span class="pi pi-info-circle"></span
        ></span>
      </div>
      <p-dropdown
        id="drop_daysAfterVisit"
        name="daysAfterVisit"
        optidivabel="label"
        optionValue="value"
        formControlName="daysAfterVisit"
        class="input p-col-12 p-p-0 p-mr-1"
        appendTo="body"
        [options]="daysAfterVisit"
        placeholder="{{
          'ediary.components.generalConfiguration.conciliation.selectPlaceholderActivation'
            | translate
        }}"
        (onChange)="
          validateTotalWindowDays(this.form.controls['totalWindowDays'].value)
        "
        emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
        emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
      >
      </p-dropdown>
      <small
        class="form-error"
        *ngIf="
          form.controls['daysAfterVisit']?.invalid &&
          form.controls['daysAfterVisit']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div class="p-col-12 p-md-6">
      <div class="p-d-flex p-ai-center p-mb-2">
        <label for="txt_days" id="lbl_days">{{
          'ediary.components.generalConfiguration.conciliation.formDays'
            | translate
        }}</label>
        <span
          class="p-pl-2 p-d-flex info-icon"
          [pTooltip]="
            'ediary.components.generalConfiguration.conciliation.formTooltipTotalDays'
              | translate
          "
          tooltipPosition="top"
          ><span class="pi pi-info-circle"></span
        ></span>
      </div>
      <p-inputNumber
        id="txt_days"
        name="days"
        formControlName="totalWindowDays"
        (onInput)="validateTotalWindowDays($event.value)"
        [ngClass]="{
          error: isTotalWindowDaysError && windowTimeForm.isEdition
        }"
        placeholder="{{
          'ediary.components.generalConfiguration.conciliation.formPlaceHolderDays'
            | translate
        }}"
      ></p-inputNumber>
      <small
        class="form-error"
        *ngIf="
          form.controls['totalWindowDays']?.invalid &&
          form.controls['totalWindowDays']?.touched &&
          !isTotalWindowDaysError
        "
        >{{ 'general.required' | translate }}</small
      >

      <small
        class="form-error"
        *ngIf="isTotalWindowDaysError && windowTimeForm.isEdition"
        >{{
          'ediary.components.generalConfiguration.conciliation.totalWindowError'
            | translate
              : {
                  day: totalWindowDay
                }
        }}</small
      >
    </div>
  </div>
  <div class="p-col-12 p-md-4 p-d-flex p-flex-column p-justify-between">
    <!--close window-->
    <div *ngIf="!windowTimeForm.id" class="close-icon" (click)="closeWindow()">
      <span class="pi pi-times"></span>
    </div>
    <!--Mensaje para mostrar cuando días estará activa la ventana-->
    <div class="p-col-12 info" *ngIf="windowTimeForm.id">
      <p-message
        class="info"
        severity="info"
        [text]="
          'ediary.components.generalConfiguration.conciliation.conciliationInfo'
            | translate
              : {
                  days:
                    form.controls['daysAfterVisit'].value +
                    form.controls['totalWindowDays'].value
                }
        "
      ></p-message>
    </div>

    <!--Guardar/Elimina ventana-->
    <div class="p-d-flex p-col-12 p-justify-end p-ai-center">
      <div *ngIf="this.windowTimeForm.isEdition" class="p-mr-2">
        <p-button
          [label]="
            'ediary.components.generalConfiguration.conciliation.btnSaveWindows'
              | translate
          "
          icon="pi pi-check"
          type="submit"
          class="saveWindow"
          [disabled]="form.invalid || isTotalWindowDaysError"
        ></p-button>
      </div>
      <div *ngIf="!this.windowTimeForm.isEdition" class="p-mr-3">
        <p-button
          icon="pi pi-pencil"
          styleClass="p-button-text"
          (click)="enableForm()"
        ></p-button>
      </div>
      <div>
        <p-button
          icon="pi pi-trash"
          styleClass="p-button-danger p-button-text"
          (click)="this.windowTimeForm.id && showDeleteWindow()"
          [disabled]="!this.windowTimeForm.id"
        ></p-button>
      </div>
    </div>
  </div>
</form>

<app-delete-confirmation
  [header]="
    'ediary.components.generalConfiguration.conciliation.deleteQuestion'
      | translate
  "
  [description]="
    'ediary.components.generalConfiguration.conciliation.deleteAction'
      | translate
  "
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="display"
  (confirm)="deleteWindow()"
  position="absolute"
  *ngIf="display"
>
</app-delete-confirmation>
