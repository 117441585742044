import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ViewPdfComponent } from 'src/app/shared/components/view-pdf/view-pdf.component';
import { Tp2Card, Tp2CardContent } from 'src/app/shared/global.variables';

@Component({
  selector: 'app-manage-pdf-versions',
  templateUrl: './manage-pdf-versions.component.html',
  styleUrls: ['./manage-pdf-versions.component.scss'],
})
export class ManagePdfVersionsComponent implements OnInit {
  informedConsent: any = {};
  eConsentPDFCards: Tp2Card[] = [];
  confInformedConsent: any = {};

  constructor(
    public config: DynamicDialogConfig,
    private readonly trialpalService: TrialpalService,
    private readonly informedConsentService: InformedConsentService,
    private readonly dialogService: DialogService,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.informedConsent = this.config?.data?.informedConsent;
    this.confInformedConsent = this.config?.data?.confInformedConsent;

    const pdfFiles = this.informedConsent?.pdfFiles;
    if (pdfFiles?.length) this.buildTPCardData(pdfFiles);
  }

  buildTPCardData(pdfFiles: any) {
    const cards: Tp2Card[] = [];

    pdfFiles?.forEach((pdfFile: any, index: number) => {
      const content: Tp2CardContent[] = [
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.followUp.viewPdf.date'
          )}:`,
          description:
            this.informedConsentService.transformDatePipe.transformWithHour(
              pdfFile?.uploadDate
            ),
          icon: 'pi pi-clock',
        },
        {
          title: `${this.trialpalService.translateService.instant(
            'informedConsent.version'
          )}:`,
          description: `${index + 1}`,
          icon: 'pi pi-clock',
        },
      ];

      cards.push({
        id: pdfFile?.fileUrl,
        textHeader: this.trialpalService.dictionaryPipe.transform(
          this.confInformedConsent?.name
        ),
        buttonItems: [],
        content: content,
        isButtonsEnabled: true,
        isMenuEnabled: false,
        cardBackground: pdfFiles?.length === index + 1 ? '#c6ddff' : '#fff',
        menu: [],
      });
    });

    this.eConsentPDFCards = cards;
  }

  openPdfModal(pdfUrl: any): void {
    const ref = this.dialogService.open(ViewPdfComponent, {
      data: {
        PDFUrl: pdfUrl.id,
        sourceComponent: 'informedConsentFollowUp',
        subjectId: this.informedConsent?.subjectId,
      },
      closeOnEscape: true,
      dismissableMask: true,
      width: '80%',
      height: '90%',
    });

    ref.onClose.subscribe();
  }

  closeManageICPdfVersions(): void {
    this.ref.close();
  }
}
