import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss'],
})
export class ExpandCollapseComponent implements AfterViewChecked {
  @ViewChild('section') sectionContainer!: ElementRef;
  @Input() header: string = '';
  hide: boolean = false;

  //Detecta cuando la altura ha cambiado y actualiza la altura maxima del contenido
  ngAfterViewChecked(): void {
    if (!this.sectionContainer) return;

    const sectionContainer = this.sectionContainer.nativeElement;
    const cardHeight = sectionContainer.scrollHeight + 'px';

    sectionContainer.style.maxHeight = this.hide ? 0 : cardHeight;
  }
}
