<div class="container-tp2">
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12" style="display: flex; align-items: center">
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        id="btn_forward"
        class="
          p-button-rounded
          p-button-secondary
          p-button-outlined
          p-button
          p-button-icon-only
        "
        (click)="forward()"
      ></button>
      <div class="p-col-10">
        <h1>{{ 'site.h1SitetList' | translate }}</h1>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-xl-4">
      <button
        *ngIf="hasSiteCreatePermission"
        pButton
        label="{{ 'site.buttonCreateSite' | translate }}"
        type="button"
        (click)="createEdit()"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>
  <div class="p-grid p-fluid" *ngIf="sites.length > 0">
    <app-tpcard
      id="cards"
      *ngFor="let p of sitesPaginated"
      class="p-col-12 p-md-6 p-lg-4"
      [tpCardData]="buildTPCardData(p)"
      (delete)="delete($event)"
      (edit)="createEdit($event)"
    ></app-tpcard>
    <div class="p-col-12">
      <p-paginator
        id="paginator"
        [rows]="pagesize"
        [totalRecords]="sites.length"
        (onPageChange)="paginate($event)"
      >
      </p-paginator>
    </div>
  </div>

  <div class="p-grid p-fluid" *ngIf="sites.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
