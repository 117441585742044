<div class="grid">
  <div
    *ngFor="
      let option of to.options | formlySelectOptions: field | async;
      let index = index
    "
  >
    <label
      [for]="option.value + field.key + index + '_' + uniqueId"
      class="
        cursor-pointer
        p-d-flex p-justify-center p-flex-column p-ai-center p-p-2
      "
    >
      <span class="p-mb-2 font-bold p-d-block font-4 break-word text-center">{{
        option.label | dictionary
      }}</span>

      <div *ngIf="to['images']">
        <img
          [src]="to['images'][index]"
          alt="{{ option.value }}"
          class="image"
        />
      </div>

      <p-radioButton
        inputId="{{ option.value + field.key + index + '_' + uniqueId }}"
        [formControl]="formControl"
        [value]="option.value"
        [formlyAttributes]="field"
        name="{{ to.label + '_' + uniqueId }}"
        [required]="to.required ?? true"
      ></p-radioButton>
    </label>
  </div>
</div>
