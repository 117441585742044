export interface DataTable {
  indexComment?: number;
  dayIndex?: number;
  newValue?: any;
  record?: any;
  verifiedName?: any;
  name?: any;
  nameDay?: string;
  idRecord?: string;
  comment?: string | null;
  isPendingInstance: boolean;
  isEdition: boolean;
  complementData?: DataTable;
}
export interface NameValue {
  name?: any;
  value?: any;
}

//Referencia los campos de la bd para TemperatureRecordLog
export enum EntityTemperature {
  TEMPERATURE = 'temperature',
  TEMPERATURE_UNIT = 'temperatureUnit',
  TEMPERATURE_ROUTE = 'temperatureRoute',
  TEMPERATURE_WHICH_OTHER_ROUTE = 'temperatureWhichOtherRoute',
  TEMPERATURE_TAKEN = 'temperatureTaken',
  TEMPERATURE_TAKEN_DATE = 'temperatureTakenDate',
}

//Referencia los campos de la bd para VerifiedTemperatureRecordLog
export enum VerifiedEntityTemperature {
  TEMPERATURE = 'temperature',
  UNIT = 'unit',
  ROUTE = 'route',
  WHICH_OTHER_ROUTE = 'whichOtherRoute',
  TAKEN = 'taken',
  TAKEN_DATE = 'takenDate',
}

export enum ConciliationComponents {
  TEMPERATURE = 'TEMPERATURE',
  MEDICATION = 'MEDICATION',
  SYMPTOM_GENERAL = 'SYMPTOM_GENERAL',
  SYMPTOM_LOCAL = 'SYMPTOM_LOCAL',
  OTHER_SYMPTOM = 'OTHER_SYMPTOM',
  MEDICAl_ATTETION = 'MEDICAl_ATTETION',
  COMMENT = 'COMMENT',
}

export enum GeneralModel {
  NA = 'N/A',
}

export interface AddConfOtherSymptom {
  confOtherSymptom: any;
  dayInstance: any;
  confFormId: string;
}
