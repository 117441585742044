import { HttpStatusCode } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'aws-amplify';
import { saveAs } from 'file-saver';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { UserService } from 'src/app/modules/user/user.service';
import {
  CreateUserInput,
  ModelUserFilterInput,
  UserState,
} from 'src/app/modules/user/user.types';
import { DateType } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InstanceState, TP2UserInput } from 'src/app/services/trialpal.types';
import { Roles } from 'src/app/shared/global.variables';
import * as xlsx from 'xlsx';
import en from '../../../../../assets/i18n/en.json';
import es from '../../../../../assets/i18n/es.json';
import { InformedConsentService } from '../../informed-consent.service';
import {
  CreateConsentedUserInput,
  CreateInformedConsentInstanceInput,
  InformedConsentState,
} from '../../informed-consent.types';
import { InformedConsentFollowUpAssignExistingUsersComponent } from '../informed-consent-follow-up-assign-existing-users/informed-consent-follow-up-assign-existing-users.component';
import {
  NotificationPreferences,
  ProjectAndSiteInterface,
} from '../informed-consent-follow-up-user-form/informed-consent-follow-up-user-form.component';
import { Project } from 'src/app/modules/project/project.types';
const logger = new Logger('tp2-logger-informedConsentFollowUpUsersTable');

@Component({
  selector: 'app-informed-consent-follow-up-users-table',
  templateUrl: './informed-consent-follow-up-users-table.component.html',
  styleUrls: ['./informed-consent-follow-up-users-table.component.scss'],
})
export class InformedConsentFollowUpUsersTableComponent implements OnInit {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('userTable') userTable!: Table;
  projectAndSite = {} as ProjectAndSiteInterface;
  confInformedConsentId = '';
  showDialog: boolean = false;
  showDeleteUserDialog: boolean = false;
  isFormInValid: boolean = false;
  newUsers: ExcelFileUserInput[] = [];
  existingUsers: ExcelFileUserInput[] = [];
  selectedUser: any = {};
  user: any;
  tableColumns: any = [];
  errorMessages: string[] = [];
  totalUsersError: number = 0;
  confInformedConsent: any;
  assignedKinships: any;
  relationshipTypes: any = [];
  notificationPreferencesTypes: any = [];
  tableFiltered: boolean = false;
  allUsers: any[] = [];
  currentProject: Partial<Project> = {
    isMFAActivated: false,
  };
  dateType: DateType = DateType.MULTIPLE;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    public trialpalService: TrialpalService,
    private informedConsentService: InformedConsentService,
    private authService: AuthService,
    private storage: StorageService,
    private dialogService: DialogService
  ) {
    this.tableColumns = [
      {
        field: 'login.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.user'
        ),
      },
      {
        field: 'name.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.name'
        ),
      },
      {
        field: 'email.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.email'
        ),
      },
      {
        field: 'phoneNumber.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.phoneNumber'
        ),
      },
      {
        field: 'identifier.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.participants'
        ),
      },
      {
        field: 'subjectNames.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.participantsName'
        ),
      },
      {
        field: 'subjectBirthdates.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.subjectBithDate'
        ),
      },
      {
        field: 'relationship.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.relationship'
        ),
      },
      {
        field: 'notificationPreference.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.notificationPreference'
        ),
      },
      {
        field: 'requiresSendingEmail.data',
        header: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.cols.requiresSendingEmail'
        ),
      },
    ];
  }

  async ngOnInit() {
    this.trialpalService.showSpinner('user.entityPlural', 'LIST');
    this.projectAndSite.projectId = this.route.snapshot.params.projectId;
    this.route.queryParams.subscribe((params: any) => {
      this.projectAndSite.siteId = params.siteId;
      this.confInformedConsentId = params.icId;
    });
    await this.getProject();
    this.relationshipTypes = Object.keys(en.informedConsent.witnessesTypes);
    this.notificationPreferencesTypes = Object.keys(NotificationPreferences);
    logger.debug('relationshipTypes', this.relationshipTypes);
    this.newUsers = [];
    this.confInformedConsent = await this.getonfInformedConsentById();
    logger.debug('confInformedConsent -->', this.confInformedConsent);
    const consentedUsers = await this.getConsentedUsersBySite();
    logger.debug('consentedUsersBySite -->', consentedUsers);
    this.existingUsers = await this.putInformedConsentToUsers(consentedUsers);
    logger.debug('ExistingsUsers -->', this.existingUsers);
    this.trialpalService.hideSpinner();
  }

  async getProject() {
    try {
      this.currentProject = await this.informedConsentService.getProject(
        this.projectAndSite.projectId
      );
    } catch (error) {
      logger.error('get project error', error);
    }
  }

  async getonfInformedConsentById() {
    return this.informedConsentService.getConfInformedConsentById(
      this.confInformedConsentId
    );
  }

  async getConsentedUsersBySite() {
    const roleAndSiteFilter: ModelUserFilterInput = {
      role: {
        eq: Roles.Consented,
      },
      state: {
        ne: UserState.DISABLED,
      },
      sites: {
        contains: this.projectAndSite.siteId,
      },
    };
    return this.informedConsentService.listUsers(roleAndSiteFilter);
  }

  async putInformedConsentToUsers(users: any): Promise<ExcelFileUserInput[]> {
    let usersWitCurrentInformedConsent: ExcelFileUserInput[] = [];
    logger.debug('usersPutIC', users);
    const informedConsents = await Promise.all(
      users.map((user: any) => {
        return this.informedConsentService.getInformedConsentByUserId(
          user.id,
          this.confInformedConsentId
        );
      })
    );

    for (let user of users) {
      user.informedConsents = informedConsents.find((informedConsent: any) => {
        if (informedConsent.length) {
          return informedConsent[0].consentedUser.userId === user.id;
        }
        return false;
      });

      //Valida que el usuario tenga consentimiento asociados al consentimiento actual
      if (user.informedConsents?.length > 0) {
        user.subjects = this.getInformedConsentValueByKey(
          user,
          'consentedIdentificator'
        );
        user.subjectNames = this.getInformedConsentValueByKey(
          user,
          'consentedName'
        );
        user.subjectBirthdates = this.getSubjectBirthdates(user);
        user.requiresSendingEmail =
          user.informedConsents[0]?.consentedUser.requiresSendingEmail;
        user.kinship = this.getKinshipByUser(user);
        usersWitCurrentInformedConsent.push(user);

        logger.debug('userWithIC', user);
      }
    }
    logger.debug(
      'usersWitCurrentInformedConsent',
      usersWitCurrentInformedConsent
    );
    return this.getUserFormater(usersWitCurrentInformedConsent);
  }

  getSubjectBirthdates(user: any) {
    const subjectBirthdates =
      this.getInformedConsentValueByKey(user, 'consentedBirthDate') ?? '';
    return subjectBirthdates
      ?.split(',')
      ?.map((birthDate: any) =>
        this.informedConsentService.transformDateToDDMMYYYYFormat(
          birthDate,
          'YYYY-MM-DD'
        )
      )
      ?.join(',');
  }

  getInformedConsentValueByKey(user: any, key: string) {
    let ICs: any[] = [];
    for (let informedConsent of user.informedConsents) {
      let informedConsentValue = informedConsent?.informedConsent[key] ?? '';
      ICs.push(informedConsentValue);
    }

    ICs = ICs.filter((value: any) => value.trim().length !== 0);
    return ICs.join(', ');
  }

  /**
   * Obtiene y traduce las relaciones (kinships) de los usuarios a través de sus consentimientos informados.
   * @param {any} user - El usuario del cual se obtendrán las relaciones.
   * @returns {string} - Una cadena que representa las relaciones traducidas, separadas por comas.
   */
  getKinshipByUser(user: any) {
    const kinships = user.informedConsents
      .map((informedConsent: any) => informedConsent?.consentedUser.kinship)
      .filter((kinship: any) => kinship) // Eliminar valores falsy (undefined, null, etc.)
      .map((kinship: any) =>
        this.trialpalService.translateService.instant(
          `informedConsent.witnessesTypes.${kinship}`
        )
      )
      .join(', ');

    return kinships;
  }

  getUserFormater(usersWitCurrentInformedConsent: any) {
    const users = [];
    logger.debug(
      'usersWitCurrentInformedConsent',
      usersWitCurrentInformedConsent
    );
    for (let user of usersWitCurrentInformedConsent) {
      const input: ExcelFileUserInput = this.getUserInformation({
        group: GROUP_TABLE.GROUP_B,
        data: user,
        row: Number(user) + 1,
        login: user.login,
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber,
        identifier: user.subjects,
        subjectNames: user.subjectNames,
        subjectBirthdates: user.subjectBirthdates,
        relationship: user.kinship,
        notificationPreference: user.notificationPreference,
        requiresSendingEmail: user.requiresSendingEmail,
      });
      users.push(input);
    }

    return users;
  }

  getAllUsers() {
    return [...this.newUsers, ...this.existingUsers];
  }

  onAddUser(): void {
    this.user = {};
    this.showDialog = true;
    this.informedConsentService.recordeConsentEvent('site_consent_addperson', {
      project: this.projectAndSite.projectId,
      site: this.projectAndSite.siteId,
    });
  }

  getKinshipsOnEdit(subjects: any) {
    let kinships = [];
    const identifiers = subjects.map((subject: any) => subject.id);
    for (const user of this.newUsers) {
      for (const subject of user.identifier.data) {
        if (identifiers.includes(subject)) {
          kinships.push(user.relationship.data);
        }
      }
    }
    return kinships;
  }

  onEditUser(user: ExcelFileUserInput) {
    const subjects: any[] = this.getSubjectsByUser(user);

    if (user.group === GROUP_TABLE.GROUP_A) {
      this.assignedKinships = this.getKinshipsOnEdit(subjects);
      logger.debug('assignedKinships', this.assignedKinships);
    }
    this.user = {
      data: user.data?.id ? user.data : { ...user.data, editTemp: true },
      row: user.row,
      login: user.login.data,
      email: user.email.data,
      name: user.name.data,
      phoneNumber: user.phoneNumber.data,
      relationship: user.relationship.data,
      notificationPreference: user.notificationPreference.data,
      requiresSendingEmail: user.requiresSendingEmail.data,
      subjects: subjects,
      group: user.group,
    };
    this.showDialog = true;
  }

  getSubjectsByUser(user: ExcelFileUserInput): any[] {
    const identifiers: string[] = user.identifier.data
      ? String(user.identifier.data).split(',')
      : [];

    const subjectNames: string[] = user.subjectNames.data
      ? String(user.subjectNames.data).split(',')
      : [];

    const subjectBirthdates: string[] = user.subjectBirthdates.data
      ? String(user.subjectBirthdates.data).split(',')
      : [];
    const subjects: any[] = [];

    //Valida el tamaño de ambos arreglos y toma el amor
    const maxItems = Math.max(
      identifiers.length,
      subjectNames.length,
      subjectBirthdates.length
    );

    //Construye el arreglo de sujetos, si no son iguales se colocará vacio
    for (let index = 0; index < maxItems; index++) {
      subjects.push({
        id: identifiers[index] ?? '',
        name: subjectNames[index] ?? '',
        birthDate: subjectBirthdates[index] ?? '',
      });
    }
    return subjects;
  }

  onDeleteUser(user: ExcelFileUserInput) {
    this.newUsers = this.newUsers.filter(
      (currentUser: any) => currentUser.row !== user.row
    );
    this.setTotalErrors();
  }

  onCancel(): void {
    this.showDialog = false;
  }

  async onCloseModal(data: (CreateUserInput | null)[]) {
    if (data) {
      for (const user of data) {
        this.updateSubjectNames(user);
        const input: ExcelFileUserInput = this.getUserInformation({
          ...user,
          data: {},
          group: GROUP_TABLE.GROUP_A,
        });
        const currentIndex = this.newUsers.findIndex(
          (existingUser) => existingUser.row === input.row
        );
        if (currentIndex !== -1) {
          input.row = this.newUsers[currentIndex].row;
          this.newUsers[currentIndex] = input;
        } else {
          this.newUsers.push(input);
        }
      }
    }

    await this.validateUserTable();
    this.showDialog = false;
  }

  async exportExcel() {
    const key = `informed-consent/users-template/${this.trialpalService.translateService.instant(
      'informedConsent.followUp.template'
    )}.xlsx`;
    try {
      const result = await this.storage.get(key);
      const response = await fetch(result);
      if (response.status === HttpStatusCode.Ok) {
        const blob = await response.blob();
        saveAs(
          blob,
          `${this.trialpalService.translateService.instant(
            'informedConsent.followUp.userUploadTemplate'
          )}.xlsx`
        );
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      logger.debug('Download Template', error);
    }
  }

  async importExcel(event: any, fileUpload: any) {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.importExcel'
      )
    );
    const file: File = event.files[0];
    const wb = xlsx.read(await file.arrayBuffer());
    const users: any = [];

    //Obtiene solo la información de la hoja de usuarios
    const usersExcelData = wb.Sheets['Users'] ?? wb.Sheets['Usuarios'];
    const data = xlsx.utils.sheet_to_json<any>(usersExcelData);

    fileUpload.clear();
    logger.debug('ImportExcelData', data);
    for (let index in data) {
      const input: ExcelFileUserInput = this.getUserInformation(
        this.getUserRowFormater(data, index)
      );
      users.push(input);
    }

    this.newUsers = this.newUsers.concat(users);
    this.clearTable();

    this.trialpalService.hideSpinner();
    await this.validateUserTable();
    this.informedConsentService.recordeConsentEvent('site_consent_bulkload', {
      project: this.projectAndSite.projectId,
      site: this.projectAndSite.siteId,
    });
  }

  getUserRowFormater(data: any, index: any) {
    const row = data[index];

    return {
      data: {},
      group: GROUP_TABLE.GROUP_A,
      row: Number(index) + 1,
      login: row[this.tableColumns[0].header],
      name: row[this.tableColumns[1].header],
      email: row[this.tableColumns[2].header],
      phoneNumber: row[this.tableColumns[3].header],
      identifier: row[this.tableColumns[4].header],
      subjectNames: row[this.tableColumns[5].header],
      subjectBirthdates: row[this.tableColumns[6].header],
      relationship: this.getRelationShipKey(row[this.tableColumns[7].header]),
      notificationPreference: this.getNotificationPreferenceKey(
        row[this.tableColumns[8].header]
      ),
      requiresSendingEmail:
        this.trialpalService.translateService.instant('general.yes') ===
        row[this.tableColumns[9].header]
          ? true
          : this.trialpalService.translateService.instant('general.no') ===
            row[this.tableColumns[9].header]
          ? false
          : null,
    };
  }

  //Funcion que se encarga de convertir el tipo de relación a llave
  getRelationShipKey(relationship: any) {
    let witnessesTypesES: any = es.informedConsent.witnessesTypes;
    let witnessesTypesEN: any = en.informedConsent.witnessesTypes;
    let key = '';
    for (let witness in witnessesTypesES) {
      if (
        witnessesTypesEN[witness] === relationship ||
        witnessesTypesES[witness] === relationship
      ) {
        key = witness;
      }
    }

    return key;
  }
  getNotificationPreferenceKey(preference: any) {
    let preferenceTypesES: any = es.ediary.enums.alertTypes;
    let preferenceTypesEN: any = en.ediary.enums.alertTypes;
    let key = '';
    for (let notificationPreference in preferenceTypesES) {
      if (
        preferenceTypesEN[notificationPreference] === preference ||
        preferenceTypesES[notificationPreference] === preference
      ) {
        key = notificationPreference;
      }
    }

    return key;
  }

  getUserInformation(data: any): ExcelFileUserInput {
    return {
      data: data.data,
      group: data?.group,
      row: data?.row ?? this.newUsers.length + 1,
      login: {
        data: data.login,
        isError: false,
        messageError: '',
      },
      name: {
        data: data.name ?? '',
        isError: false,
        messageError: '',
      },
      email: {
        data: data.email,
        isError: false,
        messageError: '',
      },
      phoneNumber: {
        data: data.phoneNumber ?? '',
        isError: false,
        messageError: '',
      },
      identifier: {
        data: data.identifier ?? '',
        isError: false,
        messageError: '',
      },
      subjectNames: {
        data: data.subjectNames ?? '',
        isError: false,
        messageError: '',
      },
      subjectBirthdates: {
        data: data.subjectBirthdates ?? '',
        isError: false,
        messageError: '',
      },
      relationship: {
        data: data.relationship ?? '',
        isError: false,
        messageError: '',
      },
      notificationPreference: {
        data: data.notificationPreference ?? '',
        isError: false,
        messageError: '',
      },
      requiresSendingEmail: {
        data: data.requiresSendingEmail,
        isError: false,
        messageError: '',
      },
    };
  }

  async createMultipleUsers() {
    try {
      this.trialpalService.showSpinner('general.savingInfo');
      for await (const userTable of this.newUsers) {
        const { consentedIdentificators, consentedNames, consentedBirthDates } =
          this.informedConsentService.getConsentedInformation(userTable);

        const userCreated = await this.userDrive(userTable);
        const newUser = userCreated;
        const informedConsents: any[] = [];
        for (let identifier of consentedIdentificators) {
          //Obtiene el nombre del sujeto con el identificador actual
          const consentedIdentificatorIndex =
            consentedIdentificators.indexOf(identifier);
          const consentedName = consentedNames[consentedIdentificatorIndex];
          const consentedBirthDate =
            consentedBirthDates[consentedIdentificatorIndex];

          const informedConsentCreated = await this.informendConsentDrive(
            identifier,
            consentedName,
            consentedBirthDate
          );
          const consentedUser = await this.ConsentedUserDrive(
            userCreated,
            userTable,
            informedConsentCreated
          );
          informedConsents.push({
            informedConsent: informedConsentCreated,
            consentedUser: consentedUser,
          });
        }
        //Se encarga de guardar el usuario en el arreglo de usuarios existente
        this.saveNewUserInExistinUsers(newUser, informedConsents);
      }

      //Limpia el arreglo de usuarios por guardar
      this.newUsers = [];
      this.clearTable();
      this.trialpalService.messageService.add({
        severity: 'success',
        summary: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.usersCreated'
        ),
        detail: this.trialpalService.translateService.instant(
          'informedConsent.followUp.table.usersCreatedDetail'
        ),
      });
      this.trialpalService.hideSpinner();
      this.informedConsentService.recordeConsentEvent(
        'site_consent_assignconsents',
        {
          project: this.projectAndSite.projectId,
          site: this.projectAndSite.siteId,
        }
      );
    } catch (error) {
      logger.error('createMultipleUsers error', error);
      this.trialpalService.hideSpinner();
    }
  }

  getConsentedIdentificatorsAndNames(userTable: ExcelFileUserInput) {
    const identifiers = userTable?.identifier?.data ?? [];
    const subjectNames = userTable?.subjectNames?.data ?? [];
    //Valida si es un arreglo o un string separado por ','
    const consentedIdentificators = Array.isArray(identifiers)
      ? identifiers
      : userTable?.identifier?.data.split(',');
    //Valida si es un arreglo o un string separado por ','
    const consentedNames = Array.isArray(subjectNames)
      ? subjectNames
      : userTable?.subjectNames?.data.split(',');

    return { consentedIdentificators, consentedNames };
  }

  //Función que se encarga de actualizar el arreglo de usuarios existentes
  saveNewUserInExistinUsers(newUser: any, informedConsents: any[]) {
    newUser.informedConsents = informedConsents;
    newUser.subjects = this.getInformedConsentValueByKey(
      newUser,
      'consentedIdentificator'
    );
    newUser.subjectNames = this.getInformedConsentValueByKey(
      newUser,
      'consentedName'
    );
    newUser.subjectBirthdates = this.getInformedConsentValueByKey(
      newUser,
      'consentedBirthDate'
    );
    newUser.subjectBirthdates = this.getSubjectBirthdates(newUser);
    newUser.kinship = this.getKinshipByUser(newUser);
    newUser.requiresSendingEmail =
      informedConsents[0]?.consentedUser.requiresSendingEmail;
    const userFormater = this.getUserFormater([newUser]);
    this.existingUsers.push(...userFormater);
    this.clearTable();
  }

  async ConsentedUserDrive(
    userCreated: any,
    userTable: any,
    informedConsentCreated: any
  ): Promise<any> {
    let consentedUser = await this.createConsentedUserInstance(
      userCreated,
      userTable,
      informedConsentCreated
    );
    logger.debug('consentedUserRecentlyCreated', consentedUser);

    return consentedUser;
  }

  async userDrive(user: ExcelFileUserInput): Promise<any> {
    let userCreated = await this.informedConsentService.getUserByLogin(
      user.login.data
    );

    if (!userCreated) {
      userCreated = await this.createUser(user);
    }
    logger.debug('userCreated', userCreated);
    return userCreated;
  }

  async informendConsentDrive(
    identifier: string,
    consentedName: string,
    consentedBirthDate: string
  ) {
    let informedConsent: any = await this.getInformedConsentByIdentificator(
      identifier
    );
    if (!informedConsent) {
      informedConsent = await this.createICInstance(
        identifier,
        consentedName,
        consentedBirthDate
      );
    }

    return informedConsent;
  }

  async getInformedConsentByIdentificator(identificator: string) {
    const informedConsent =
      await this.informedConsentService.getInformedConsentByConsentedIdentificator(
        identificator
      );
    return informedConsent.items.find(
      (IC: any) => !IC._deleted && IC.state !== InstanceState.DELETED
    );
  }

  // Create all informed consent instances for each user in the table
  private createICInstance(
    identifier: string,
    consentedName: string,
    consentedBirthDate: string
  ): any {
    const createInput: CreateInformedConsentInstanceInput = {
      projectId: this.projectAndSite.projectId,
      siteId: this.projectAndSite.siteId,
      confInformedConsentId: this.confInformedConsentId,
      _lastUser: this.authService.getUsername(),
      consentedIdentificator: identifier,
      consentedName: consentedName,
      consentedBirthDate:
        this.informedConsentService.transformDateToYYYYMMDDFormat(
          consentedBirthDate,
          'DD-MM-YYYY'
        ),
      stateChanges: [
        {
          state: InformedConsentState.ASSIGNED,
          date: new Date().toISOString(),
        },
      ],
      state: InformedConsentState.ASSIGNED,
    };
    return this.informedConsentService.createInformedConsentInstance(
      createInput
    );
  }

  private async createConsentedUserInstance(
    user: any,
    userTable: any,
    informedConsentCreated: any
  ) {
    logger.debug('createConsentedUserInstance', user, userTable);
    const createConsentedUserInput: CreateConsentedUserInput = {
      projectId: this.projectAndSite.projectId,
      informedConsentInstanceId: informedConsentCreated.id,
      siteId: this.projectAndSite.siteId,
      state: InformedConsentState.ASSIGNED,
      userId: user.id,
      kinship: userTable?.relationship?.data ?? '',
      stateChanges: [
        {
          state: InformedConsentState.ASSIGNED,
          date: new Date().toISOString(),
        },
      ],
      requiresSendingEmail: userTable?.requiresSendingEmail?.data,
      name: userTable?.name?.data ?? '',
      _lastUser: this.authService.getUsername(),
    };

    return this.informedConsentService.createConsentedUser(
      createConsentedUserInput
    );
  }

  private async createUser(user: any) {
    try {
      const projects = [{ id: this.projectAndSite.projectId }];
      const sites = [{ id: this.projectAndSite.siteId }];

      const input: TP2UserInput = {
        login: user.login.data,
        email: user.email.data,
        phoneNumber: user.phoneNumber.data,
        name: user.name.data,
        role: Roles.Consented,
        state: UserState.ENABLED,
        _lastUser: this.authService.getUsername(),
        notificationPreference: user.notificationPreference.data,
      };
      const userCreated = await this.userService.createUser(
        input,
        projects,
        sites,
        [],
        []
      );

      return JSON.parse(userCreated ?? '').createdUser;
    } catch (error: any) {
      logger.error('createUser', error);
      this.trialpalService.showServiceError('user.actions.createUser', error);
    }
    return {};
  }

  /**
   * Esta función valida la entrada del usuario en un archivo de Excel para un formulario de
   * consentimiento informado.
   */
  async validateUserTable() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'informedConsent.followUp.table.validateForm'
      )
    );

    this.isFormInValid = false;
    for (let userCol of this.newUsers) {
      await this.informedConsentService.validateUser(
        userCol,
        this.newUsers,
        this.existingUsers,
        this.currentProject.isMFAActivated ?? false
      );
    }

    this.setTotalErrors();

    this.trialpalService.hideSpinner();
  }

  setTotalErrors() {
    this.totalUsersError = this.newUsers.filter((user: ExcelFileUserInput) => {
      return user.isError;
    }).length;

    this.isFormInValid = this.totalUsersError > 0;
  }

  assignExistingUsers(): void {
    const ref = this.dialogService.open(
      InformedConsentFollowUpAssignExistingUsersComponent,

      {
        data: {
          users: this.existingUsers ?? [],
          projectId: this.projectAndSite?.projectId ?? '',
        },
        width: '85%',
        height: '90%',
        closeOnEscape: false,
        dismissableMask: false,
      }
    );

    this.trialpalService.ref = ref;
  }

  async deleteUserFromUserTable(): Promise<void> {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant('user.spinnerDeleteUser')
    );
    this.showDeleteUserDialog = false;
    let showDeletedUserMessage = true;
    if (this.selectedUser.data.id) {
      const user = this.selectedUser.data;
      showDeletedUserMessage = await this.deleteUserFromDB(user);
    } else {
      this.newUsers = this.newUsers.filter(
        (user: any) => user.row !== this.selectedUser.row
      );
    }
    this.validateUserTable();
    this.trialpalService.hideSpinner();
    if (showDeletedUserMessage) {
      this.informedConsentService.deleteConsentedUserSuccessMessage();
    }
    this.informedConsentService.recordeConsentEvent(
      'site_consent_deletedperson',
      {
        project: this.projectAndSite.projectId,
        site: this.projectAndSite.siteId,
      }
    );
  }

  getUsersWithSameIdentifiers(subject: any, isNewUser: boolean) {
    let users: any = [];
    const usersToChange = isNewUser ? this.newUsers : this.existingUsers;
    logger.debug('usersToChangeSUBJECT', subject);

    //Busca los usuarios que tengan el identificador actual en su lista de identificadores
    for (const currentUser of usersToChange) {
      if (currentUser?.identifier?.data) {
        logger.debug('currentUserOnEdit', currentUser);
        let identifiers: string[] = [];
        if (typeof currentUser.identifier.data === 'string') {
          identifiers = currentUser.identifier.data.split(', ');
        } else if (Array.isArray(currentUser.identifier.data)) {
          identifiers = currentUser.identifier.data;
        }

        if (identifiers.includes(subject.id)) {
          logger.debug('currentUserOnEditPush', currentUser);
          users.push(currentUser);
        }
      }
    }

    return users;
  }

  getTranslatedRelationship(relationship: string) {
    const relationships = relationship.split(', ');
    let translatedRelationship = '';
    for (const relation of relationships) {
      translatedRelationship += `${this.trialpalService.translateService.instant(
        `informedConsent.witnessesTypes.${relation}`
      )}, `;
    }
    return translatedRelationship.slice(0, -2); // remove the last comma and space
  }

  onEditPerson(user: any) {
    logger.debug('OnEditUser', user, this.existingUsers);
    try {
      //Editar el usuario actual
      const userIndex = this.existingUsers.findIndex(
        (currentUser: any) => currentUser.login.data === user.login
      );

      if (userIndex !== -1) {
        this.existingUsers[userIndex].data = user.data;
        this.existingUsers[userIndex].login.data = user.login;
        this.existingUsers[userIndex].name.data = user.name;
        this.existingUsers[userIndex].email.data = user.email;
        this.existingUsers[userIndex].phoneNumber.data = user.phoneNumber;
        this.existingUsers[userIndex].identifier.data =
          user.identifier?.join(', ');
        this.existingUsers[userIndex].subjectNames.data = user.subjectNames;
        this.existingUsers[userIndex].subjectBirthdates.data =
          user.subjectBirthdates;
        this.existingUsers[userIndex].notificationPreference.data =
          user.notificationPreference;
        this.existingUsers[userIndex].relationship.data =
          //obtiene la traducción de cada relación
          user.relationship
            .map((relationship: any) =>
              this.trialpalService.translateService.instant(
                `informedConsent.witnessesTypes.${relationship}`
              )
            )
            .join(', ');
      }
      this.existingUsers[userIndex].requiresSendingEmail.data =
        user.requiresSendingEmail;
      //Editar los sujetos en todos los usuarios que tengan los mismos identificadores
      this.updateSubjectNames(user);
      this.showDialog = false;
    } catch (error) {
      logger.error('onEditPerson', error);
    }
  }

  updateSubjectNames(user: any) {
    const isNewUser = user.group === GROUP_TABLE.GROUP_A;
    logger.debug('isNewUser', isNewUser);
    logger.debug('userOnUpdateSubjectNames', user);
    for (const subject of user.subjects) {
      logger.debug('subjectOnEdit', subject);

      const users = this.getUsersWithSameIdentifiers(subject, isNewUser);
      logger.debug('usersOnEdit', users);
      //Edita los nombres de los sujetos en los usuarios encontrados
      for (const currentUser of users) {
        logger.debug('currentUserOnEdit', currentUser);
        if (currentUser?.identifier?.data) {
          let subjectIndex = [];
          if (typeof currentUser.identifier.data === 'string') {
            subjectIndex = currentUser.identifier.data
              .split(', ')
              .indexOf(subject.id);
          } else if (Array.isArray(currentUser.identifier.data)) {
            subjectIndex = currentUser.identifier.data.indexOf(subject.id);
          }
          const subjectNames = currentUser.subjectNames.data.split(', ');
          subjectNames[subjectIndex] = subject.name;
          currentUser.subjectNames.data = subjectNames.join(', ');
          logger.debug('currentUserOnEditEdited', currentUser);
        }
      }
    }
  }

  async deleteUserFromDB(user: any) {
    //obtiene los consentimientos informados asociados al usuario
    const informedConsents =
      await this.informedConsentService.getInformedConsentByUserId(user.id, '');
    logger.debug('informedConsents', informedConsents);
    let changeReason: string = '';
    if (informedConsents.length === user.informedConsents.length) {
      const userDeleted =
        await this.informedConsentService.deleteUserWithRolConsented(user);
      if (JSON.stringify(user) === JSON.stringify(userDeleted)) {
        return false;
      }
      changeReason = userDeleted._changeReason;
      logger.debug('deleteUserWithRolConsented');
    }
    //Elimina los consentedUser asociados al usuario
    if (changeReason === '') {
      const changelog: any = await this.trialpalService.modalChangeReason(
        { ...user, _changeReason: undefined },
        this.trialpalService.translateService.instant('user.actions.updateUser')
      );
      if (changelog?._changeReason) {
        changeReason = changelog._changeReason;
      } else {
        return false;
      }
    }
    const promises: any[] = user.informedConsents.map(
      (informedConsent: any) => {
        this.informedConsentService.deleteConsentedUserMain({
          ...informedConsent.consentedUser,
          _changeReason: changeReason,
        });
      }
    );
    await Promise.all(promises);
    this.existingUsers = this.existingUsers.filter(
      (_user: any) => _user.data.id !== user.id
    );
    this.allUsers = this.allUsers.filter(
      (_user: any) => _user.data.id !== user.id
    );
    return true;
  }

  clearTable() {
    this.searchInput.nativeElement.value = '';
    this.userTable.clear();
    this.userTable.filterGlobal('', 'contains');
  }

  copyUserTableData(userInfo: string) {
    this.trialpalService.copyToClipboard(userInfo);
  }
  applySearchFilter(keywordSearch: string) {
    let filteredUsers = [];
    if (!this.tableFiltered) {
      this.allUsers = [...this.newUsers, ...this.existingUsers];
    }
    this.tableFiltered = keywordSearch.length !== 0;
    filteredUsers = [...this.allUsers];
    const newfilteredUsers = filteredUsers.filter(
      (user: any) =>
        user.login.data.toLowerCase().includes(keywordSearch.toLowerCase()) ||
        (Array.isArray(user.identifier.data)
          ? user.identifier.data.some((id: string) =>
              id.toLowerCase().includes(keywordSearch.toLowerCase())
            )
          : user.identifier.data
              .toLowerCase()
              .includes(keywordSearch.toLowerCase())) ||
        user.name.data.toLowerCase().includes(keywordSearch.toLowerCase()) ||
        user.phoneNumber.data
          .toLowerCase()
          .includes(keywordSearch.toLowerCase()) ||
        user.email.data.toLowerCase().includes(keywordSearch.toLowerCase()) ||
        user.subjectNames.data
          .toLowerCase()
          .includes(keywordSearch.toLowerCase()) ||
        this.searchRelationShip(user, keywordSearch) ||
        this.searchNotificationPreference(user, keywordSearch) ||
        this.searchRequiresSendingEmail(user, keywordSearch)
    );
    this.newUsers = [
      ...newfilteredUsers.filter((user) => Object.keys(user.data).length === 0),
    ];
    this.existingUsers = [
      ...newfilteredUsers.filter((user) => Object.keys(user.data).length > 0),
    ];
  }
  searchRelationShip(user: any, keywordSearch: string) {
    const relationship = this.relationshipTypes.includes(user.relationship.data)
      ? this.trialpalService.translateService.instant(
          `informedConsent.witnessesTypes.${user.relationship.data}`
        )
      : user.relationship.data;
    return relationship.toLowerCase().includes(keywordSearch.toLowerCase());
  }
  searchNotificationPreference(user: any, keywordSearch: string) {
    const preference = this.notificationPreferencesTypes.includes(
      user.notificationPreference.data
    )
      ? this.trialpalService.translateService.instant(
          `ediary.enums.alertTypes.${user.notificationPreference.data}`
        )
      : user.notificationPreference.data;
    return preference.toLowerCase().includes(keywordSearch.toLowerCase());
  }
  searchRequiresSendingEmail(user: any, keywordSearch: string) {
    const requiresSendingEmail = user.requiresSendingEmail.data
      ? this.trialpalService.translateService.instant('general.yes')
      : this.trialpalService.translateService.instant('general.no');
    return requiresSendingEmail
      .toLowerCase()
      .includes(keywordSearch.toLowerCase());
  }
}

export interface ExcelFileUserInput {
  data: any;
  group: string;
  row: number;
  isError?: boolean;
  error?: string;
  login: Data;
  name: Data;
  email: Data;
  phoneNumber: Data;
  identifier: Data;
  subjectNames: Data;
  subjectBirthdates: Data;
  relationship: Data;
  notificationPreference: Data;
  requiresSendingEmail: Data;
}

export interface Data {
  data: any;
  messageError: string;
  messageError2?: string;
  isError: boolean;
}

enum GROUP_TABLE {
  GROUP_A = 'GROUP_A', //Nuevos usuarios
  GROUP_B = 'GROUP_B', //Usuarios existentes
}
