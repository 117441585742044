import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { CardOrTableService } from 'src/app/services/card-or-table.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { SubjectsService } from '../../subjects.service';
import { Symptom } from 'src/app/modules/ediary/ediary.types';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';

const logger = new Logger('tp2-logger-subjectMedicalAttPage');

@Component({
  selector: 'app-medical-attention-subject',
  templateUrl: './medical-attention-subject.component.html',
  styleUrls: ['./medical-attention-subject.component.scss'],
})
export class MedicalAttentionSubjectComponent implements OnInit, OnChanges {
  @Input() timeZoneOffset = 0;
  @Input() isOpened: boolean = false;
  @Output() hasMedicalAttentions: EventEmitter<boolean> = new EventEmitter();
  medicalAttentions: any[] = [];
  showSpinner: boolean = true;
  tableViewMode: boolean = false;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  dataCard: any[] = [];
  DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR;
  constructor(
    private subjectsService: SubjectsService,
    private activatedRoute: ActivatedRoute,
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe,
    private readonly authService: AuthService,
    private pageTourService: PageTourService,
    public cardOrTableService: CardOrTableService
  ) {}
  async ngOnInit(): Promise<void> {
    try {
      this.showSpinner = true;
      const subjectid = this.activatedRoute.snapshot.params.subjectId;
      await this.getMedicalAttentionBySubjectId(subjectid);
      logger.debug('medicalAttentions', this.medicalAttentions);
      if (this.medicalAttentions && this.medicalAttentions.length > 0) {
        await this.buildStartAndFinishDate();
        await this.buildReasonForMedicalAttention();
        this.buildCardsAndTable();
      }
      this.showSpinner = false;
      this.startTour();
    } catch (exception) {
      this.buildCardsAndTable();
      this.showSpinner = false;
      this.startTour();
    }
  }

  ngOnChanges(): void {
    this.startTour();
  }
  buildCardsAndTable() {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };
    if (this.medicalAttentions && this.medicalAttentions.length > 0) {
      this.dataTable = this.buildTPTableData(this.medicalAttentions);
      this.buildCards(this.medicalAttentions);
    }
  }
  buildCards(medicalAttentions: any) {
    this.dataCard = [];
    for (let medicalAttention of medicalAttentions) {
      this.dataCard.push(this.buildTPCardData(medicalAttention));
    }
  }
  async getMedicalAttentionBySubjectId(subjectid: string) {
    const medicalAttention =
      await this.subjectsService.medicalAttentionInstanceBySubjectId(subjectid);
    logger.debug(
      'medicalAttentionInstanceBySubjectId response',
      medicalAttention
    );
    if (medicalAttention.items) {
      this.medicalAttentions = medicalAttention.items.filter(
        (x: any) => !x._deleted
      );
      this.medicalAttentions = this.subjectsService.orderInstancesDescending(
        this.medicalAttentions
      );
    }
    this.hasMedicalAttentions.emit(this.medicalAttentions?.length > 0);
  }
  async buildStartAndFinishDate() {
    let forms: any[] = [];
    for await (const medical of this.medicalAttentions) {
      const confFormId = medical.dayInstance.confDay.confFormId;
      if (confFormId) {
        let findForm: any = forms.find((x: any) => x.confFormId === confFormId);
        if (!findForm) {
          findForm = await this.subjectsService.ConfMedicalAttentionByConfForm(
            confFormId
          );
          findForm = findForm.items[0];
          forms = forms.concat(findForm);
        }
        medical.showRememberStartDate = findForm
          ? findForm.showRememberStartDate
          : true;
        medical.showRememberFinishDate = findForm
          ? findForm.showRememberFinishDate
          : true;
      }
    }
  }
  async buildReasonForMedicalAttention() {
    for await (const medicalAttention of this.medicalAttentions) {
      const reason = await this.buildReasonForMedicalAttentionInstance(
        medicalAttention
      );
      if (reason) {
        medicalAttention.reason = reason.slice(0, -2);
      }
    }
  }

  async buildReasonForMedicalAttentionInstance(medicalAttention: any) {
    let reason = '';
    if (medicalAttention.symptomsInstances) {
      for (const id in medicalAttention.symptomsInstances || []) {
        const { instance, symptom } = await this.getSymptomInstance(
          medicalAttention,
          id
        );
        if (instance?.symptom || symptom) {
          medicalAttention.symptomsInstances[id] =
            this.trialpalService.translateService.instant(
              'symptom.enums.symptoms.' + (instance?.symptom || symptom)
            );
          reason += medicalAttention.symptomsInstances[id] + ', ';
        }
      }
    }
    return reason;
  }

  async getSymptomInstance(medicalAttention: any, id: string) {
    let instance;
    let symptom = '';
    if (medicalAttention.symptomsInstances[id] === Symptom.FEVER) {
      symptom = Symptom.FEVER;
    }
    if (!symptom) {
      instance = await this.subjectsService.getConfSymptom(
        medicalAttention.symptomsInstances[id]
      );
    }
    if (!instance && !symptom) {
      instance = await this.subjectsService.symptomInstanceById(
        medicalAttention.symptomsInstances[id]
      );
    }
    return { instance, symptom };
  }

  buildTPCardData(medicalAttention: any): TPCardData {
    let startDate: any = '';
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    if (medicalAttention.rememberStartDate && medicalAttention.startDate) {
      startDate = this.transformDatePipe.transform(medicalAttention.startDate);
    }
    let finishDate: any = '';
    if (medicalAttention.rememberFinishDate && medicalAttention.finishDate) {
      finishDate = this.transformDatePipe.transform(
        medicalAttention.finishDate
      );
    } else if (
      !medicalAttention.rememberFinishDate &&
      medicalAttention.showRememberFinishDate
    ) {
      finishDate = this.trialpalService.translateService.instant(
        'general.instanceState.ON_GOING'
      );
    }
    let reason =
      medicalAttention.reason ||
      this.trialpalService.translateService.instant('subject.withoutReason');

    let type = !medicalAttention.type
      ? this.trialpalService.translateService.instant(
          'subject.tabheaderMedicalAssistance'
        )
      : this.trialpalService.translateService.instant(
          'subject.enums.medicalAttention.type.' + medicalAttention.type
        );

    if (
      !medicalAttention.rememberFinishDate &&
      !medicalAttention.rememberStartDate
    ) {
      startDate = false;
      finishDate = false;
    }

    return {
      ...medicalAttention,
      hideState: true,
      data: medicalAttention,
      header: type,
      section1: startDate || false,
      icon1: startDate ? 'pi pi-calendar' : '',
      section1ToolTip: this.trialpalService.translateService.instant(
        'medicalattentioninstance.startDate'
      ),
      section2: finishDate || false,
      icon2: finishDate ? 'pi pi-calendar-times' : '',
      section2ToolTip: this.trialpalService.translateService.instant(
        'medicalattentioninstance.endDate'
      ),
      section3: reason || '',
      icon3: 'pi pi-info-circle',
      section3ToolTip: this.trialpalService.translateService.instant(
        'medicationinstance.reason'
      ),
      styleColorState: 'green',
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      cardIsButton: false,
      objectAction: medicalAttention.name,
      entity,
      showAuditButton,
      entityAction: this.trialpalService.translateService.instant(
        'medicalattentioninstance.entity'
      ),
    };
  }

  buildTPTableData(medicalAttentionsList: any): TPTableData {
    let [entity, showAuditButton] = this.getEntityAndAuditButton();
    let newMedicalAttentionInstance = medicalAttentionsList.map(
      (medicalAttention: any) => {
        return {
          data: medicalAttention,
          entity,
          type: medicalAttention.type
            ? this.trialpalService.translateService.instant(
                'subject.enums.medicalAttention.type.' + medicalAttention.type
              )
            : this.trialpalService.translateService.instant(
                'subject.tabheaderMedicalAssistance'
              ),
          startDate:
            medicalAttention.rememberStartDate && medicalAttention.startDate
              ? this.transformDatePipe.transform(medicalAttention.startDate)
              : 'N/A',
          finishDate:
            medicalAttention.rememberFinishDate && medicalAttention.finishDate
              ? this.transformDatePipe.transform(medicalAttention.finishDate)
              : 'N/A',
        };
      }
    );
    let cols = [
      {
        header: this.trialpalService.translateService.instant(
          'medicalattentioninstance.type'
        ),
        type: TypeInput.TEXT,
        field: 'type',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'medicalattentioninstance.startDate'
        ),
        type: TypeInput.TEXT,
        field: 'startDate',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'medicalattentioninstance.endDate'
        ),
        type: TypeInput.TEXT,
        field: 'finishDate',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];
    return {
      valueTable: newMedicalAttentionInstance,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: ['type', 'startDate', 'finishDate'],
      cols,
      customButton1: showAuditButton
        ? {
            icon: 'pi pi-file',
          }
        : null,
      menuOptions: [],
    };
  }

  getEntityAndAuditButton(): any[] {
    let entity = '';
    let showAuditButton = false;
    if (
      this.authService.isAdmin() ||
      this.authService.isCoordinator() ||
      this.authService.isInvestigator()
    ) {
      entity = 'MedicalAttentionInstance';
      showAuditButton = true;
    }

    return [entity, showAuditButton];
  }

  async startTour() {
    if (!this.isOpened || this.showSpinner) return;
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
    this.pageTourService.initiateTour(
      PageTourDataType.DETAIL_SUBJECT_MEDICAL_ATTENTION_TOUR
    );
  }
}
