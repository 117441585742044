import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ErrorTranslationService } from 'src/app/modules/user/error-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';

const logger = new Logger('tp2-signatureConciliation');
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() loadingText: string = '';
  @Input() disabled: boolean = false;
  @Output() isUserAuthenticated: EventEmitter<boolean> = new EventEmitter();

  username: string = '';
  password: string = '';
  isPasswordShown = false;
  constructor(
    private authService: AuthService,
    private errorTranslationService: ErrorTranslationService,
    private trialpalService: TrialpalService,
    private ref: DynamicDialogRef,
    private conf: DynamicDialogConfig
  ) {
    this.username = this.authService.getUsername();

    if (this.conf.data) {
      this.title = this.conf.data.title;
      this.description = this.conf.data.description;
      this.loadingText = this.conf.data.loadingText;
    }
  }

  /**
   *
   * @param form formulario
   */
  async onSubmit(form: NgForm) {
    this.username = this.username.trim();
    this.trialpalService.validateSpaces(this.username, 'usuario', form);
    if (form.valid) {
      if (this.isUserEqual()) {
        this.trialpalService.showSpinner(
          this.trialpalService.translateService.instant(this.loadingText)
        );
        this.authService
          .loginUser(this.username, this.password)
          .then(async (responseUser: any) => {
            this.isUserAuthenticated.next(true);
            this.ref.close(true);
            logger.debug('loginUser response', responseUser);
          })
          .catch((error) => {
            logger.error('loginUser error', error);
            this.trialpalService.hideSpinner();
            this.errorTranslationService.notifyAWSError(error);
          });
      } else {
        this.trialpalService.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'dictionary.updateError'
          ),
          detail: this.trialpalService.translateService.instant(
            'conciliation.userNotSession'
          ),
        });
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  isUserEqual() {
    const userSession = this.authService.getUsername();
    return this.username === userSession;
  }

  onCancel(): void {
    this.ref.close(false);
  }
}
