<main class="tabs-container">
  <p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel
      *ngFor="
        let step of field.fieldGroup;
        let index = index;
        let first = first;
        let last = last
      "
      class="outline-none"
    >
      <ng-template pTemplate="header">
        <span
          class="step-text"
          [pTooltip]="step.templateOptions?.label ?? '' | dictionary"
          tooltipStyleClass="tp2-tooltip tp2-tooltip-right"
          >{{ step.templateOptions?.label ?? '' | dictionary }}</span
        >
      </ng-template>
      <div
        class="p-d-flex p-flex-column gap-4 tp2Card p-p-4"
        style="border-radius: 0; box-shadow: none"
      >
        <formly-field [field]="step"></formly-field>
        <div
          class="p-d-flex p-justify-end"
          [ngClass]="{ 'p-justify-between': !first }"
        >
          <p-button
            label="Anterior"
            type="button"
            (click)="activeIndex = activeIndex - 1"
            *ngIf="!first"
          ></p-button>
          <p-button
            label="Siguiente"
            type="button"
            *ngIf="!last"
            (click)="activeIndex = activeIndex + 1"
          ></p-button>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</main>
