import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { TP2Permission } from 'src/app/services/user-permissions-service';
import { Roles } from 'src/app/shared/global.variables';
import { AddEditReportComponent } from './add-edit-report/add-edit-report.component';
import { ListReportComponent } from './list-report/list-report.component';
import { ReportStatusComponent } from './report-status/report-status.component';

const routes: Routes = [
  {
    path: 'status',
    component: ReportStatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'status/:projectId',
    component: ReportStatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':projectId/create',
    component: AddEditReportComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        Roles.Admin,
        TP2Permission.ReportCreate,
        TP2Permission.ReportDetail,
      ],
    },
  },
  {
    path: ':projectId/edit/:id',
    component: AddEditReportComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        Roles.Admin,
        TP2Permission.ReportUpdate,
        TP2Permission.ReportDetail,
      ],
    },
  },
  {
    path: ':projectId/readMode/:id',
    component: AddEditReportComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin, TP2Permission.ReportDetail],
    },
  },
  {
    path: ':projectId',
    component: ListReportComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportRoutingModule {}
