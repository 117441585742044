import {
  ConfState,
  InstanceState,
  MediaType,
  ModelBooleanInput,
  ModelFloatInput,
  ModelIDInput,
  ModelInstanceStateInput,
  ModelIntInput,
  ModelMediaTypeInput,
  ModelStringInput,
  ModelYNAInput,
  ModelYNInput,
  TP2File,
  TP2FileInput,
  YN,
  YNA,
} from 'src/app/services/trialpal.types';
import { ConciliationLock } from '../conciliation/conciliation.types';
import {
  ConfDay,
  ConfEDiary,
  ConfSymptom,
  DayRule,
  MedicalAttentionType,
  MedicationGivenTo,
  ModelIntensityInput,
  ModelMedicalAttentionTypeInput,
  ModelMedicationGivenToListInput,
  ModelSymptomInput,
  ModelSymptomTypeInput,
  ModelTemperatureRouteInput,
  ModelTemperatureUnitInput,
  TemperatureRoute,
  TemperatureUnit,
} from '../ediary/ediary.types';
import {
  Project,
  ProjectState,
  ReportAvailableUserType,
} from '../project/project.types';
import {
  AlertRuleType,
  AlertType,
  ConfReport,
  InjectionSide,
  InjectionSite,
  Intensity,
  IntensityType,
  OnDemandAlertType,
  PageLayout,
  ReportProgramationType,
  Symptom,
  SymptomType,
} from '../report/report.types';
import { Site } from '../sites/sites.types';
import { UserEntityType, UserEntityTypeInput } from '../user/user.types';

export type tp2ValidateAndCompleteTransactionInput = {
  dayInstance?: string | null;
  reportInstance?: string | null;
  temperature?: string | null;
  symptomInstances?: Array<string | null> | null;
  mediaRecordLogs?: Array<string | null> | null;
  otherSymptomInstances?: Array<string | null> | null;
  symptomRecordLogs?: Array<string | null> | null;
  medicationInstances?: Array<string | null> | null;
  medicalAttentionInstances?: Array<string | null> | null;
  signatureInstance?: string | null;
  user?: string | null;
  deviceId?: string | null;
};
export type UpdateReportInstanceInput = {
  confReportId?: string | null;
  subjectId?: string | null;
  siteId?: string | null;
  id: string;
  state?: InstanceState | null;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion?: number | null;
  symptomOcurrencies?: Array<SymptomOccurrencyInput> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityTypeInput | null;
  pdfFiles?: Array<TP2FileInput> | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};
export type UpdateSymptomInstanceInput = {
  subjectId?: string | null;
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  confSymptomId?: string | null;
  id: string;
  order?: number | null;
  state?: InstanceState | null;
  type?: SymptomType | null;
  symptom?: Symptom | null;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CustomEDiaryPhaseInstanceBySubjectIdQuery = {
  __typename: 'ModelEDiaryPhaseInstanceConnection';
  items: Array<{
    __typename: 'EDiaryPhaseInstance';
    subjectId: string;
    subject?: {
      __typename: 'Subject';
      projectId: string;
      siteId: string;
      id: string;
      subjectNumber: string;
      group: string;
      state: SubjectState;
      tag?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    eDiaryInstanceId: string;
    eDiaryInstance?: {
      __typename: 'EDiaryInstance';
      subjectId: string;
      confEDiaryId: string;
      siteId: string;
      id: string;
      state: InstanceState;
      confEdiaryVersion: string;
      comment?: string | null;
      alerts?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    siteId: string;
    site?: {
      __typename: 'Site';
      projectId: string;
      id: string;
      name: string;
      contactInfo?: string | null;
      showContactInfo?: boolean | null;
      showContactInfoLogin?: boolean | null;
      timezone?: string | null;
      defaultLanguage?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    id: string;
    state: InstanceState;
    phase: string;
    pdfFiles?: Array<{
      __typename: 'TP2File';
      uploadDate: string;
      fileUrl: string;
    }> | null;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    locked?: {
      __typename: 'ConciliationLock';
      user: string;
      date: string;
    } | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type DayInstanceByEDiaryPhaseInstanceCustomQuery = {
  __typename: 'ModelDayInstanceConnection';
  items: Array<{
    __typename: 'DayInstance';
    subjectId: string;
    subject?: {
      __typename: 'Subject';
      subjectNumber: string;
      id: string;
      state: SubjectState;
      projectId: string;
      siteId: string;
      _deleted?: boolean | null;
    } | null;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    confDay?: {
      __typename: 'ConfDay';
      dayName: string;
      order: number;
      confFormId: string;
      startDay: number;
      endDay: number;
      hidePDFDayColumn?: boolean | null;
      _deleted?: boolean | null;
      confForm?: {
        __typename: 'ConfForm';
        confEdiaryId: string;
        projectId: string;
        id: string;
        name: string;
        isTemperatureRequired: boolean;
        isMedicationRequired: boolean;
        isMedicalAttentionRequired: boolean;
        isReconciliationRequired?: boolean | null;
        isMediaRequired: boolean;
        areCommentsRequired: boolean;
        isOtherForm?: boolean | null;
        mediaTypes?: Array<MediaType> | null;
        areOtherSymptomsRequired: boolean;
        symptomPageLayout: PageLayout;
        showHelp: boolean;
        formHelp?: string | null;
        _lastUser: string;
        _changeReason?: string | null;
        createdAt: string;
        updatedAt: string;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
      } | null;
    } | null;
    siteId: string;
    site?: {
      __typename: 'Site';
      projectId: string;
      id: string;
      name: string;
      contactInfo?: string | null;
      showContactInfo?: boolean | null;
      showContactInfoLogin?: boolean | null;
      timezone?: string | null;
      defaultLanguage?: string | null;
      _lastUser: string;
      _changeReason?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    symptomOcurrencies?: Array<{
      __typename: 'SymptomOccurrency';
      confSymptomId: string;
      symptomInstanceId?: string | null;
      symptomRecordLogId?: string | null;
      occurrency?: boolean | null;
      order?: number | null;
    }> | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    _lastUser: string;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type GetConfAlertQuery = {
  __typename: 'ConfAlert';
  confReportId?: string | null;
  confReport?: {
    __typename: 'ConfReport';
    projectId: string;
    id: string;
    groups: Array<string>;
    phases: Array<string>;
    instancePerDay: boolean;
    reportTitle: string;
    showHelp: boolean;
    reportHelp?: string | null;
    reportIcon?: string | null;
    symptomPageLayout: PageLayout;
    programationType: ReportProgramationType;
    plannedDayList?: Array<number> | null;
    isDisplayDependentOnTheEDiary?: boolean | null;
    forms?: Array<string> | null;
    isControlledBySite?: boolean | null;
    allowReportBackDating: boolean;
    enableLocalNotifications?: boolean | null;
    futureNotifications?: number | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    jsonForm?: string | null;
    isSignatureRequired: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isMediaRequired: boolean;
    isDeviceDataRequired: boolean;
    mediaTypes?: Array<MediaType> | null;
    isAssociatedToVisits?: boolean | null;
    areSymptomsRequired?: boolean | null;
    areAlertsRequired?: boolean | null;
    isDeviceDataManagementRequired?: boolean | null;
    availableUsers?: Array<ReportAvailableUserType | null> | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confEdiaryId?: string | null;
  confEdiary?: {
    __typename: 'ConfEDiary';
    projectId: string;
    isConciliationRequired: boolean;
    isWindowsConciliationRequired?: boolean | null;
    id: string;
    isSignatureRequired: boolean;
    isPastEntryEnabled: boolean;
    isGPSRequired: boolean;
    isUserIPRequired: boolean;
    isDeviceDataRequired: boolean;
    enableLocalNotifications?: boolean | null;
    alertType?: OnDemandAlertType | null;
    alertWeeklyDays?: Array<number | null> | null;
    alertInBetweenDaysFrequency?: number | null;
    alertDuringSameDayFrequency?: number | null;
    alertStartTime?: string | null;
    alertEndTime?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confTemperatureId?: string | null;
  confTemperature?: {
    __typename: 'ConfTemperature';
    confEdiaryId: string;
    confFormId?: string | null;
    projectId: string;
    id: string;
    helpText?: string | null;
    label?: string | null;
    dayRule?: DayRule | null;
    routeOptions?: Array<TemperatureRoute | null> | null;
    showFahrenheitUnit?: boolean | null;
    showTemperatureRoute?: boolean | null;
    ocurrencyValue?: number | null;
    ocurrencyUnit?: TemperatureUnit | null;
    showTemperatureTaken?: boolean | null;
    showTemperatureTakenDate?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  alertName: string;
  recipients?: Array<string> | null;
  siteRecipients?: Array<{
    __typename: 'SiteRecipients';
    siteId: string;
    recipients: Array<string>;
  }> | null;
  type: AlertType;
  mailSubject?: string | null;
  mailBody?: string | null;
  SMSBody?: string | null;
  alertRules: Array<{
    __typename: 'AlertRule';
    ruleId?: string | null;
    alertRuleType: AlertRuleType;
    expectedValue?: string | null;
    expectedUnit?: string | null;
  }>;
  triggerVisitCompletion?: boolean | null;
  triggerEDiarySuspension?: boolean | null;
  visitList?: Array<{
    __typename: 'TriggeredVisit';
    order: number;
    visitName: string;
  }> | null;
  suspendVisitList?: Array<{
    __typename: 'TriggeredVisit';
    order: number;
    visitName: string;
  }> | null;
  state?: ConfState | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetDayInstanceQuery = {
  __typename: 'DayInstance';
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: {
    __typename: 'EDiaryPhaseInstance';
    subjectId: string;
    eDiaryInstanceId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    phase: string;
    pdfState?: InstanceState | null;
    completedPhaseDate?: string | null;
    suspensionReason?: string | null;
    suspensionDate?: string | null;
    suspensionUser?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confDayId: string;
  confDay?: {
    __typename: 'ConfDay';
    confEdiaryId: string;
    confFormId: string;
    projectId: string;
    id: string;
    dayName: string;
    order: number;
    startDay: number;
    endDay: number;
    groups: Array<string>;
    phases: Array<string>;
    trackSymptomOcurrencies?: boolean | null;
    hidePDFDayColumn?: boolean | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<{
    __typename: 'SymptomOccurrency';
    confSymptomId: string;
    symptomInstanceId?: string | null;
    symptomRecordLogId?: string | null;
    occurrency?: boolean | null;
    order?: number | null;
  }> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type GetSymptomInstanceQuery = {
  __typename: 'SymptomInstance';
  subjectId: string;
  subject?: {
    __typename: 'Subject';
    projectId: string;
    siteId: string;
    id: string;
    subjectNumber: string;
    group: string;
    state: SubjectState;
    tag?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  reportInstanceId?: string | null;
  reportInstance?: {
    __typename: 'ReportInstance';
    confReportId: string;
    subjectId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    plannedDate?: string | null;
    completeDate?: string | null;
    json?: string | null;
    score?: number | null;
    gps?: string | null;
    appVersion?: string | null;
    userIP?: string | null;
    reportConfVersion: number;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  dayInstanceId?: string | null;
  dayInstance?: {
    __typename: 'DayInstance';
    subjectId: string;
    eDiaryPhaseInstanceId: string;
    confDayId: string;
    siteId: string;
    id: string;
    state: InstanceState;
    startDate: string;
    finishDate: string;
    completedDate?: string | null;
    userIP?: string | null;
    gps?: string | null;
    appVersion?: string | null;
    confDayVersion: string;
    hasMedication?: boolean | null;
    medicationGivenTo?: Array<MedicationGivenTo | null> | null;
    hasMedicalAttention?: boolean | null;
    hasOtherSymptoms?: boolean | null;
    isAlertChecked?: boolean | null;
    isPDFGenerated?: boolean | null;
    comments?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  confSymptomId?: string | null;
  confSymptom?: {
    __typename: 'ConfSymptom';
    confReportId?: string | null;
    confEdiaryId?: string | null;
    projectId: string;
    id: string;
    type: SymptomType;
    symptom: Symptom;
    order: number;
    injectionSite?: InjectionSite | null;
    injectionSide?: InjectionSide | null;
    showHelp: boolean;
    symptomHelp?: string | null;
    symptomLabel?: string | null;
    symptomIcon?: string | null;
    decimalPlaces?: number | null;
    minSize?: number | null;
    maxSize?: number | null;
    intensityType?: IntensityType | null;
    intensitySizeLabel?: string | null;
    isIntensityQuestionsRequired?: boolean | null;
    isIntensityRequired?: boolean | null;
    isStartDateRequired?: boolean | null;
    isFinishDateRequired?: boolean | null;
    showNoValuesTaken?: boolean | null;
    noValuesTakenLabel?: string | null;
    state?: ConfState | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<{
    __typename: 'IntensityQuestionAnswer';
    answer?: boolean | null;
  }> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type CreateReportInstanceInput = {
  confReportId: string;
  subjectId: string;
  siteId: string;
  id?: string | null;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<SymptomOccurrencyInput> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityTypeInput | null;
  pdfFiles?: Array<TP2FileInput> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  _version?: number | null;
};
export type CreateSymptomInstanceInput = {
  subjectId: string;
  reportInstanceId?: string | null;
  dayInstanceId?: string | null;
  confSymptomId?: string | null;
  id?: string | null;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswerInput> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type IntensityQuestionAnswerInput = {
  question?: IntensityQuestionInput | null;
  answer?: boolean | null;
};
export type IntensityQuestionInput = {
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};
export type SymptomOccurrencyInput = {
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};
export type MediaRecordLogBySubjectIdQuery = {
  __typename: 'ModelMediaRecordLogConnection';
  items: Array<{
    __typename: 'MediaRecordLog';
    dayInstanceId?: string | null;
    reportInstanceId?: string | null;
    subjectId: string;
    id: string;
    state: InstanceState;
    mediaType: MediaType;
    url: string;
    comment?: string | null;
    dateTime?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type MedicalAttentionInstanceBySubjectIdQuery = {
  __typename: 'ModelMedicalAttentionInstanceConnection';
  items: Array<{
    __typename: 'MedicalAttentionInstance';
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    type?: MedicalAttentionType | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    wasHospitalized?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type MedicationInstanceBySubjectIdQuery = {
  __typename: 'ModelMedicationInstanceConnection';
  items: Array<{
    __typename: 'MedicationInstance';
    subjectId: string;
    dayInstanceId?: string | null;
    id: string;
    state: InstanceState;
    rememberStartDate: boolean;
    startDate?: string | null;
    isOnGoing?: boolean | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    name?: string | null;
    reason?: string | null;
    symptomsInstances?: Array<string> | null;
    givenTo?: Array<MedicationGivenTo | null> | null;
    dose?: string | null;
    frequency?: string | null;
    administrationRoute?: string | null;
    _lastUser: string;
    _changeReason?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type ModelMediaRecordLogFilterInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  mediaType?: ModelMediaTypeInput | null;
  url?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  dateTime?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelMediaRecordLogFilterInput | null> | null;
  or?: Array<ModelMediaRecordLogFilterInput | null> | null;
  not?: ModelMediaRecordLogFilterInput | null;
};
export type ModelMedicalAttentionInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelMedicalAttentionTypeInput | null;
  reason?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  wasHospitalized?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelMedicalAttentionInstanceFilterInput | null> | null;
  or?: Array<ModelMedicalAttentionInstanceFilterInput | null> | null;
  not?: ModelMedicalAttentionInstanceFilterInput | null;
};
export type ModelMedicationInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  isOnGoing?: ModelBooleanInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  name?: ModelStringInput | null;
  reason?: ModelStringInput | null;
  symptomsInstances?: ModelStringInput | null;
  givenTo?: ModelMedicationGivenToListInput | null;
  dose?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  administrationRoute?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelMedicationInstanceFilterInput | null> | null;
  or?: Array<ModelMedicationInstanceFilterInput | null> | null;
  not?: ModelMedicationInstanceFilterInput | null;
};
export type ModelReportInstanceFilterInput = {
  confReportId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  plannedDate?: ModelStringInput | null;
  completeDate?: ModelStringInput | null;
  json?: ModelStringInput | null;
  score?: ModelIntInput | null;
  gps?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  userIP?: ModelStringInput | null;
  reportConfVersion?: ModelIntInput | null;
  isAlertChecked?: ModelBooleanInput | null;
  isPDFGenerated?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  and?: Array<ModelReportInstanceFilterInput | null> | null;
  or?: Array<ModelReportInstanceFilterInput | null> | null;
  not?: ModelReportInstanceFilterInput | null;
};
export type ModelSymptomInstanceFilterInput = {
  subjectId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  dayInstanceId?: ModelIDInput | null;
  confSymptomId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  order?: ModelIntInput | null;
  state?: ModelInstanceStateInput | null;
  type?: ModelSymptomTypeInput | null;
  symptom?: ModelSymptomInput | null;
  whichOtherSymptom?: ModelStringInput | null;
  intensity?: ModelIntensityInput | null;
  size?: ModelFloatInput | null;
  rememberStartDate?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  rememberFinishDate?: ModelBooleanInput | null;
  finishDate?: ModelStringInput | null;
  _deviceModel?: ModelStringInput | null;
  _deviceSOVersion?: ModelStringInput | null;
  isOtherSymptom?: ModelBooleanInput | null;
  medications?: ModelStringInput | null;
  medicalAttentions?: ModelStringInput | null;
  professionalHealthCare?: ModelYNAInput | null;
  hospitalAdmission?: ModelYNInput | null;
  createdAt?: ModelStringInput | null;
  rememberNoValuesTaken?: ModelBooleanInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelSymptomInstanceFilterInput | null> | null;
  or?: Array<ModelSymptomInstanceFilterInput | null> | null;
  not?: ModelSymptomInstanceFilterInput | null;
};
export type ModelTemperatureRecordLogFilterInput = {
  dayInstanceId?: ModelIDInput | null;
  reportInstanceId?: ModelIDInput | null;
  subjectId?: ModelIDInput | null;
  id?: ModelIDInput | null;
  state?: ModelInstanceStateInput | null;
  temperature?: ModelFloatInput | null;
  temperatureUnit?: ModelTemperatureUnitInput | null;
  temperatureRoute?: ModelTemperatureRouteInput | null;
  temperatureWhichOtherRoute?: ModelStringInput | null;
  date?: ModelStringInput | null;
  temperatureTaken?: ModelBooleanInput | null;
  temperatureTakenDate?: ModelStringInput | null;
  _lastUser?: ModelStringInput | null;
  _changeReason?: ModelStringInput | null;
  and?: Array<ModelTemperatureRecordLogFilterInput | null> | null;
  or?: Array<ModelTemperatureRecordLogFilterInput | null> | null;
  not?: ModelTemperatureRecordLogFilterInput | null;
};
export type SymptomInstanceBySubjectIdQuery = {
  __typename: 'ModelSymptomInstanceConnection';
  items: Array<{
    __typename: 'SymptomInstance';
    subjectId: string;
    reportInstanceId?: string | null;
    dayInstanceId?: string | null;
    confSymptomId?: string | null;
    id: string;
    order: number;
    state: InstanceState;
    type?: SymptomType | null;
    symptom: Symptom;
    whichOtherSymptom?: string | null;
    intensity?: Intensity | null;
    size?: number | null;
    rememberStartDate?: boolean | null;
    startDate?: string | null;
    rememberFinishDate?: boolean | null;
    finishDate?: string | null;
    _deviceModel?: string | null;
    _deviceSOVersion?: string | null;
    isOtherSymptom?: boolean | null;
    medications?: Array<string> | null;
    medicalAttentions?: Array<string> | null;
    professionalHealthCare?: YNA | null;
    hospitalAdmission?: YN | null;
    createdAt?: string | null;
    rememberNoValuesTaken?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};
export type SymptomRecordLogBysymptomInstanceIdQuery = {
  __typename: 'ModelSymptomRecordLogConnection';
  items: Array<{
    __typename: 'SymptomRecordLog';
    dayInstanceId: string;
    symptomInstanceId: string;
    state?: InstanceState | null;
    subjectId: string;
    id: string;
    intensity?: Intensity | null;
    size?: number | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SymptomOccurrency = {
  __typename: 'SymptomOccurrency';
  confSymptomId: string;
  symptomInstanceId?: string | null;
  symptomRecordLogId?: string | null;
  occurrency?: boolean | null;
  order?: number | null;
};
export type SymptomInstance = {
  __typename: 'SymptomInstance';
  subjectId: string;
  subject?: Subject | null;
  reportInstanceId?: string | null;
  reportInstance?: ReportInstance | null;
  dayInstanceId?: string | null;
  dayInstance?: DayInstance | null;
  confSymptomId?: string | null;
  confSymptom?: ConfSymptom | null;
  id: string;
  order: number;
  state: InstanceState;
  type?: SymptomType | null;
  symptom: Symptom;
  whichOtherSymptom?: string | null;
  intensity?: Intensity | null;
  size?: number | null;
  rememberStartDate?: boolean | null;
  startDate?: string | null;
  rememberFinishDate?: boolean | null;
  finishDate?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  isOtherSymptom?: boolean | null;
  medications?: Array<string> | null;
  medicalAttentions?: Array<string> | null;
  professionalHealthCare?: YNA | null;
  hospitalAdmission?: YN | null;
  createdAt?: string | null;
  intensityQuestionAnswers?: Array<IntensityQuestionAnswer> | null;
  rememberNoValuesTaken?: boolean | null;
  _lastUser: string;
  _changeReason?: string | null;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type IntensityQuestionAnswer = {
  __typename: 'IntensityQuestionAnswer';
  question?: IntensityQuestion | null;
  answer?: boolean | null;
};
export type IntensityQuestion = {
  __typename: 'IntensityQuestion';
  id: string;
  order: number;
  question: string;
  intensity?: Intensity | null;
};
export type IntensityScale = {
  __typename: 'IntensityScale';
  active: boolean;
  startValue: number;
  endValue: number;
  helpText: string;
  label?: string | null;
};
export type DayInstance = {
  __typename: 'DayInstance';
  subjectId: string;
  subject?: Subject | null;
  eDiaryPhaseInstanceId: string;
  eDiaryPhaseInstance?: EDiaryPhaseInstance | null;
  confDayId: string;
  confDay?: ConfDay | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  startDate: string;
  finishDate: string;
  completedDate?: string | null;
  userIP?: string | null;
  gps?: string | null;
  appVersion?: string | null;
  confDayVersion: string;
  hasMedication?: boolean | null;
  medicationGivenTo?: Array<MedicationGivenTo | null> | null;
  hasMedicalAttention?: boolean | null;
  hasOtherSymptoms?: boolean | null;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  comments?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type EDiaryPhaseInstance = {
  __typename: 'EDiaryPhaseInstance';
  subjectId: string;
  subject?: Subject | null;
  eDiaryInstanceId: string;
  eDiaryInstance?: EDiaryInstance | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  phase: string;
  pdfFiles?: Array<TP2File> | null;
  pdfState?: InstanceState | null;
  completedPhaseDate?: string | null;
  suspensionReason?: string | null;
  suspensionDate?: string | null;
  suspensionUser?: string | null;
  locked?: ConciliationLock | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type EDiaryInstance = {
  __typename: 'EDiaryInstance';
  subjectId: string;
  subject?: Subject | null;
  confEDiaryId: string;
  confEDiary?: ConfEDiary | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  confEdiaryVersion: string;
  comment?: string | null;
  alerts?: string | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ReportInstance = {
  __typename: 'ReportInstance';
  confReportId: string;
  confReport?: ConfReport | null;
  subjectId: string;
  subject?: Subject | null;
  siteId: string;
  site?: Site | null;
  id: string;
  state: InstanceState;
  plannedDate?: string | null;
  completeDate?: string | null;
  json?: string | null;
  score?: number | null;
  gps?: string | null;
  appVersion?: string | null;
  userIP?: string | null;
  reportConfVersion: number;
  symptomOcurrencies?: Array<SymptomOccurrency> | null;
  isAlertChecked?: boolean | null;
  isPDFGenerated?: boolean | null;
  reportedBy?: UserEntityType | null;
  pdfFiles?: Array<TP2File> | null;
  _lastUser: string;
  _changeReason?: string | null;
  _deviceModel?: string | null;
  _deviceSOVersion?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type Subject = {
  __typename: 'Subject';
  projectId: string;
  project?: Project | null;
  siteId: string;
  site?: Site | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<ScheduledPhase> | null;
  currentScheduledPhase?: ScheduledPhase | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type ScheduledPhase = {
  __typename: 'ScheduledPhase';
  phase: string;
  date?: string | null;
  state: ScheduledPhaseState;
};

export type TP2SubjectInput = {
  subjectNumber: string;
  group: string;
  site_id: string;
  tag?: string | null;
  currentScheduledPhase: TP2ScheduledPhases;
  scheduledPhases?: Array<TP2ScheduledPhases | null> | null;
  _lastUser?: string | null;
};
export type TP2ScheduledPhases = {
  date: string;
  phase: string;
  state: string;
};
export type GetSubjectQuery = {
  __typename: 'Subject';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: 'ScheduledPhase';
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: 'ScheduledPhase';
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateSubjectMutation = {
  __typename: 'Subject';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: 'ScheduledPhase';
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: 'ScheduledPhase';
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export type UpdateSubjectInput = {
  projectId?: string | null;
  siteId?: string | null;
  id: string;
  subjectNumber?: string | null;
  scheduledPhases?: Array<ScheduledPhaseInput> | null;
  currentScheduledPhase?: ScheduledPhaseInput | null;
  group?: string | null;
  state?: SubjectState | null;
  tag?: string | null;
  _lastUser?: string | null;
  _changeReason?: string | null;
  _version?: number | null;
};
export type ScheduledPhaseInput = {
  phase: string;
  date?: string | null;
  state: ScheduledPhaseState;
};
export type CreateSubjectInput = {
  projectId: string;
  siteId: string;
  id?: string | null;
  subjectNumber: string;
  scheduledPhases?: Array<ScheduledPhaseInput> | null;
  currentScheduledPhase?: ScheduledPhaseInput | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
};
export type CreateSubjectMutation = {
  __typename: 'Subject';
  projectId: string;
  project?: {
    __typename: 'Project';
    id: string;
    state: ProjectState;
    code: string;
    name: string;
    sponsor?: string | null;
    groups: Array<string>;
    phases: Array<string>;
    faultRecipients?: Array<string> | null;
    isEdiaryActivated: boolean;
    isReportsActivated: boolean;
    isICActivated: boolean;
    isChatActivated: boolean;
    isMFAActivated?: boolean | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  siteId: string;
  site?: {
    __typename: 'Site';
    projectId: string;
    id: string;
    name: string;
    contactInfo?: string | null;
    showContactInfo?: boolean | null;
    showContactInfoLogin?: boolean | null;
    timezone?: string | null;
    defaultLanguage?: string | null;
    timezoneRecipients?: Array<string | null> | null;
    _lastUser: string;
    _changeReason?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  id: string;
  subjectNumber: string;
  scheduledPhases?: Array<{
    __typename: 'ScheduledPhase';
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  }> | null;
  currentScheduledPhase?: {
    __typename: 'ScheduledPhase';
    phase: string;
    date?: string | null;
    state: ScheduledPhaseState;
  } | null;
  group: string;
  state: SubjectState;
  tag?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};
export enum ScheduledPhaseState {
  PLANNED = 'PLANNED',
  COMPLETED = 'COMPLETED',
  NOT_DONE = 'NOT_DONE',
}
export enum SubjectState {
  ENROLLED = 'ENROLLED',
  COMPLETED = 'COMPLETED',
  DROPOUT = 'DROPOUT',
  DELETED = 'DELETED',
}
