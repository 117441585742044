import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConfState } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { Roles } from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import { UpdateConfFormInput } from '../../ediary.types';
import { FormAddEditComponent } from '../form-add-edit/form-add-edit.component';

const logger = new Logger('tp2-logger-formListPage');

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss'],
})
export class FormListComponent implements OnInit {
  @Input() confEdiaryId: any = null;
  @Input() dictionary: any = null;
  @Input() projectId: string = '';
  @Output() confFormsUpdated: EventEmitter<any> = new EventEmitter();
  confForms: Array<any> = [];

  //Permissions
  hasDiaryFormCreate: boolean = false; // Permiso para crear conf Forms
  hasDiaryFormUpdate: boolean = false; // Permiso para actualizar conf Forms
  hasDiaryFormDelete: boolean = false; // Permiso para eliminar conf Forms

  constructor(
    private ediaryService: EdiaryService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private trialpalService: TrialpalService,
    public userPermissionsService: UserPermissionsService
  ) {}

  ngOnInit(): void {
    this.loadConfForms();
    this.setPermissions();
  }

  private async loadConfForms(): Promise<void> {
    try {
      const res = await this.ediaryService.getConfFormByConfEdiaryId(
        this.confEdiaryId
      );
      if (res.items) {
        this.confForms = res.items
          .filter((e) => e && !e._deleted && e.state !== 'DELETED')
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
        this.confFormsUpdated.emit(this.confForms);
      }
    } catch (err) {
      logger.error('getConfFormByConfEdiaryId', err);
    } finally {
      this.trialpalService.hideSpinner();
    }
  }

  private async setPermissions() {
    this.hasDiaryFormCreate = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryFormCreate,
      Roles.Admin,
    ]);

    this.hasDiaryFormUpdate = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryFormUpdate,
      Roles.Admin,
    ]);

    this.hasDiaryFormDelete = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryFormDelete,
      Roles.Admin,
    ]);
  }

  openForm(form?: string): void {
    const ref = this.dialogService.open(FormAddEditComponent, {
      data: {
        confEdiaryId: this.confEdiaryId,
        confForm: form,
        dictionary: this.dictionary,
        projectId: this.projectId,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        const duplicate = this.confForms.find((e) => e.id === res.id);
        if (duplicate) {
          const index = this.confForms.indexOf(duplicate);
          this.confForms[index] = res;
        } else {
          this.confForms.push(res);
          this.confForms.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
          this.confFormsUpdated.emit(this.confForms);
        }
        this.messageService.add({
          severity: 'success',
          summary:
            this.trialpalService.translateService.instant('general.attention'),
          detail: this.trialpalService.translateService.instant(
            'ediary.components.formAddEdit.toastFormSaved'
          ),
        });
      }
    });
  }

  openFormInReadMode(form?: string): void {
    this.dialogService.open(FormAddEditComponent, {
      data: {
        confEdiaryId: this.confEdiaryId,
        confForm: form,
        dictionary: this.dictionary,
        projectId: this.projectId,
        readMode: true,
      },
      width: '95%',
      height: '95%',
    });
  }

  buildTPCardData(res: any): TPCardData {
    return {
      data: res,
      header: res.name,
      showEditButton: this.hasDiaryFormUpdate,
      showActivateButton: false,
      isHeaderDictionary: true,
      showDeleteButton: this.hasDiaryFormDelete,
      showCustomButton: false,
      iconCustomButton: 'pi pi-search',
      objectAction: res.name,
      entityAction: this.trialpalService.translateService.instant(
        'ediary.components.formAddEdit.entity'
      ),
    };
  }

  async onDelete(form: any): Promise<void> {
    if (form.id && (await this.checkAssociatedDays(form.id))) {
      const input: UpdateConfFormInput = {
        id: form.id,
        state: ConfState.DELETED,
      };
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant(
          'ediary.components.formAddEdit.deletingForm'
        )
      );
      this.ediaryService
        .updateConfForm(input, form.id, form._version)
        .then(() => {
          this.confForms = this.confForms.filter((e) => e.id !== form.id);
          this.messageService.add({
            severity: 'success',
            summary:
              this.trialpalService.translateService.instant('general.success'),
            detail: this.trialpalService.translateService.instant(
              'general.deleteSuccess'
            ),
          });
        })
        .catch((err) => logger.error('deleteConfForm', err))
        .finally(() => this.trialpalService.hideSpinner());
    }
  }
  async checkAssociatedDays(id: string): Promise<boolean> {
    this.trialpalService.showSpinner(
      'ediary.components.dayAddEdit.labelForm',
      'GET'
    );
    const confDays = await this.ediaryService.getConfDayByConfEdiaryId(
      this.confEdiaryId
    );
    const confDaysByConfFormId = confDays.items.filter(
      (x: any) => x.confFormId === id && !x._deleted
    );
    if (confDaysByConfFormId.length === 0) {
      return true;
    }
    this.trialpalService.hideSpinner();
    this.showViaService();
    return false;
  }

  showViaService() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.errorDeletingFormWithDays.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.errorDeletingFormWithDays.detail'
      ),
    });
  }
}
