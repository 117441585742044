import { Component, ElementRef, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DictionaryPipe } from '../../pipes/dictionary.pipe';

@Component({
  selector: 'app-tp-help',
  templateUrl: './tp-help.component.html',
  styleUrls: ['./tp-help.component.scss'],
})
export class TpHelpComponent {
  @ViewChild('htmlContent') htmlContent!: ElementRef;
  html: string = '';
  key: string = '';
  value: string = '';
  show: boolean = true;
  constructor(
    private conf: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dictionaryPipe: DictionaryPipe
  ) {
    this.html = this.conf.data?.html;
    this.key = this.conf.data?.key;
    this.value = this.conf.data?.value;
  }
  ngAfterViewInit(): void {
    if (!this.htmlContent?.nativeElement) return;
    let html = this.dictionaryPipe.transform(this.html);
    html = this.key
      ? html.replace(new RegExp(this.escapeRegExp(this.key), 'g'), this.value)
      : html;

    this.htmlContent.nativeElement.innerHTML = html;
  }

  // Función para escapar caracteres especiales para expresiones regulares
  escapeRegExp(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  close() {
    this.show = true;
    this.ref.close();
  }
}
