import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';

@Component({
  selector: 'app-detail-roles',
  templateUrl: './detail-roles.component.html',
  styleUrls: ['./detail-roles.component.scss'],
})
export class DetailRolesComponent implements OnInit {
  role: any = {
    roleName: '',
    isEnabled: true,
    permissions: [],
    isForProjects: false,
    projects: '',
    data: {
      permissions: [],
    },
  };
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  constructor(
    public config: DynamicDialogConfig,
    private trialpalService: TrialpalService
  ) {}

  ngOnInit(): void {
    this.role = this.config?.data?.role;
    if (this.role?.data?.permissions?.length > 0) {
      this.dataTable = this.buildTPTableData(this.role.data.permissions);
    }
  }

  buildTPTableData(permissions: any): TPTableData {
    let newPermissions = permissions.map((permission: any) => {
      return {
        permission,
      };
    });

    let cols = [
      {
        header: this.trialpalService.translateService.instant(
          'roles.labelAssignedPermissions'
        ),
        type: TypeInput.TEXT,
        field: 'permission',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];
    return {
      valueTable: newPermissions,
      globalFilterFields: ['permission'],
      cols,
      menuOptions: [],
    };
  }
}
