<article class="step-container">
  <p class="p-m-0">
    <span class="p-pr-1 step">{{
      'formStepGuide.step' | translate: { step: this.step }
    }}</span>
    <span class="description">{{ description }}</span>
  </p>
  <p class="p-m-0 additional-description" *ngIf="additionalDescription">
    {{ additionalDescription }}
  </p>
</article>
