import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import {
  ComplementaryQuestionInput,
  ComplementaryQuestionType,
  CreateConfICSectionInput,
  InformedConsentSectionType,
  MediaType,
} from 'src/app/services/API.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { UpdateConfICSectionInput } from '../../../../services/API.service';
import { InformedConsentService } from '../../informed-consent.service';
import { GenerateMediaModalComponent } from './components/generate-media-modal/generate-media-modal.component';
const logger = new Logger('tp2-logger-addEditInformedConsentSectionPage');

@Component({
  selector: 'app-informed-consent-section-add-edit',
  templateUrl: './informed-consent-section-add-edit.component.html',
  styleUrls: ['./informed-consent-section-add-edit.component.scss'],
})
export class InformedConsentSectionAddEditComponent implements OnInit {
  @ViewChild('sectionFile') sectionFile!: FileUpload;
  isEdition = false;
  isReadOnly = false;
  currentConfICSection: any = null;
  expectedVersion: any = null;
  currentConfICSectionInput = {} as
    | CreateConfICSectionInput
    | UpdateConfICSectionInput;
  myFiles: any = [];
  complementaryQuestionType: any[] = [];
  complementaryQuestionInit: ICComplementaryQuestion = {
    id: '',
    question: '',
    type: ComplementaryQuestionType.YES_NO,
    options: [''],
    form: {} as FormGroup,
  };

  projectId: string = '';
  confInformedConsentId: string = '';
  sectionTypeOptions: any[] = [];
  mediaTypeOptions: any[] = [];
  uploadedFile?: File;
  isNewFileUpload = false;
  displayModal = false;
  currentFileSanitizedUrl: SafeResourceUrl = '';
  fileUploadAcceptType: any = null;
  INFORMED_CONSENT_SECTION_ENTITY = 'informedConsent.sections.section';
  isQuestionsValid: boolean = false;
  complementaryQuestions: ICComplementaryQuestion[] = [];
  showDisplayDeleteComponent: boolean = false;
  complementaryQuestionIndex: number = -99;
  originalConfSection: any = {};
  booleanOptions: any[] = [];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trialpalService: TrialpalService,
    private readonly informedConsentService: InformedConsentService,
    private readonly dialogService: DialogService
  ) {
    this.trialpalService.showSpinner(
      this.INFORMED_CONSENT_SECTION_ENTITY,
      'GET'
    );
  }

  async ngOnInit() {
    this.isReadOnly = !this.informedConsentService.auth.isAdmin();

    await new Promise((resolve) => setTimeout(resolve, 500));
    this.setBooleanOptions();
    this.projectId = this.route.snapshot.params.projectId;
    this.confInformedConsentId =
      this.route.snapshot.params.confInformedConsentId;

    await this.initConfICSection();
    this.trialpalService.hideSpinner();
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  async initConfICSection() {
    const currentRecordId = this.route.snapshot.params.sectionId;
    if (currentRecordId) {
      this.currentConfICSection =
        await this.informedConsentService.getConfICSectionById(currentRecordId);
      this.updateCurrentRecord(this.currentConfICSection);
    } else {
      this.setInitialValues();
    }
    this.fillComplementaryQuestions(
      this.currentConfICSectionInput?.complementaryQuestions ?? []
    );
    this.setOptionsValue();
  }

  setOptionsValue() {
    this.setSectionTypeOptionsValues();
    this.setMediaOptionsValues();
    this.setComplementaryQuestionsValues();
  }

  //Opciones para los tipos de secciones
  setSectionTypeOptionsValues() {
    this.sectionTypeOptions = Object.values(InformedConsentSectionType)
      .map((sectionType: any) => {
        return {
          label: this.trialpalService.translateService.instant(
            'informedConsent.sections.sectionTypes.' + sectionType
          ),
          value: sectionType,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  //Opciones para los archivos multimedia
  setMediaOptionsValues() {
    this.mediaTypeOptions = Object.values(MediaType)
      .map((e) => {
        return {
          value: e,
          label: this.trialpalService.translateService.instant(
            'ediary.enums.mediaTypes.' + e
          ),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  //Opciones de componentes para las preguntas complementarias
  setComplementaryQuestionsValues() {
    this.complementaryQuestionType = Object.values(ComplementaryQuestionType)
      .map((e) => {
        return {
          value: e,
          label: this.trialpalService.translateService.instant(
            'informedConsent.sections.enums.componentType.' + e
          ),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  updateCurrentRecord(confICSection: any) {
    this.expectedVersion = confICSection._version;
    this.trialpalService.cleanQueryResponse(confICSection);
    delete confICSection.project;
    Object.assign(this.currentConfICSectionInput, confICSection);
    this.isEdition = true;
    this.onMediaTypeSelected(this.currentConfICSectionInput.mediaType);
    this.originalConfSection = JSON.parse(
      JSON.stringify(this.currentConfICSectionInput)
    );
    this.originalConfSection.complementaryQuestions.forEach((CQ: any) => {
      delete CQ.__typename;
      return CQ;
    });
    logger.debug('ConfICSection data -->', confICSection);
  }
  //Llena la información del complementaryQuestions
  fillComplementaryQuestions(complementaryQuestions: any[] = []) {
    this.complementaryQuestions = [];

    //Llena con la información previamente guardada
    if (complementaryQuestions.length > 0) {
      complementaryQuestions.forEach(
        (complementaryQuestion: ComplementaryQuestionInput) => {
          this.complementaryQuestions.push({
            id: complementaryQuestion.id ?? '',
            options: complementaryQuestion.options,
            question: complementaryQuestion.question,
            type: complementaryQuestion.type,
            form: {} as FormGroup,
          });
        }
      );
      return;
    }

    const complementaryQuestionId = this.getComplementaryQuestionId();

    const complementaryQuestionInit = {
      ...this.complementaryQuestionInit,
      id: complementaryQuestionId,
    };
    //Datos iniciales si no hay información
    this.complementaryQuestions = [complementaryQuestionInit];
  }

  setInitialValues(): void {
    const confInformedConsentId =
      this.route.snapshot.params.confInformedConsentId;
    if (confInformedConsentId) {
      this.currentConfICSectionInput.confInformedConsentId =
        confInformedConsentId;
    }
    this.currentConfICSectionInput.projectId = this.projectId;
    this.currentConfICSectionInput.isElectronicSignatureRequired = false;
    this.currentConfICSectionInput.enableApprovalQuestions = false;
    this.currentConfICSectionInput.icon = '';
  }

  setSectionTitle(data: any): void {
    this.currentConfICSectionInput.title = data;
  }

  setSectionContent(data: any): void {
    this.currentConfICSectionInput.content = data;
  }

  async onSubmit(form: NgForm) {
    const isComplementaryQuestionFormValid =
      this.isComplementaryQuestionFormValid();
    if (form.valid && isComplementaryQuestionFormValid) {
      this.showSaveSpinner();
      if (this.isNewFileUpload && this.uploadedFile) {
        this.currentConfICSectionInput.mediaUrl = await this.getUploadKey(
          this.uploadedFile
        );
        this.isNewFileUpload = false;
      }
      this.updateComplementaryQuestions();
      this.updateSignatureRequired();
      await this.sendConfICSection();
      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  //Si es una instancia de consentimiento activa las firmas por defecto
  updateSignatureRequired() {
    if (
      this.currentConfICSectionInput.type === InformedConsentSectionType.CONSENT
    ) {
      this.currentConfICSectionInput.isElectronicSignatureRequired = true;
    } else {
      this.currentConfICSectionInput.isElectronicSignatureRequired = false;
    }
  }

  //Funcion que se encarga de actualizar las preguntar declarativas
  updateComplementaryQuestions() {
    const complementaryQuestionsInput: ComplementaryQuestionInput[] = [];

    this.complementaryQuestions = this.complementaryQuestions.filter(
      (complementaryQuestion: any) => {
        return complementaryQuestion.form?.valid;
      }
    );

    this.complementaryQuestions.forEach(
      (complementaryQuestion: ICComplementaryQuestion) => {
        const form = complementaryQuestion.form;
        const { question, type, options } = form.value;
        complementaryQuestionsInput.push({
          id: complementaryQuestion.id,
          question: question,
          type: type,
          options: options,
        });
      }
    );

    this.currentConfICSectionInput.enableApprovalQuestions = false;

    if (
      this.currentConfICSectionInput.type ===
      InformedConsentSectionType.OPTIONAL_CONSENT
    ) {
      this.currentConfICSectionInput.enableApprovalQuestions = true;
    }

    this.currentConfICSectionInput.complementaryQuestions =
      complementaryQuestionsInput;
  }

  showSaveSpinner() {
    this.trialpalService.showSpinner(
      this.INFORMED_CONSENT_SECTION_ENTITY,
      this.isEdition ? 'UPDATE' : 'CREATE'
    );
  }

  async sendConfICSection(): Promise<any> {
    if (this.isEdition) {
      if (
        JSON.stringify(this.originalConfSection) ===
        JSON.stringify(this.currentConfICSectionInput)
      ) {
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_SECTION_ENTITY,
          'UPDATE'
        );
        return this.trialpalService.hideSpinner();
      } else {
        return this.modalChangeReason();
      }
    }
    return this.createConfICSection();
  }

  async createConfICSection(): Promise<void> {
    await this.informedConsentService
      .createConfICSection(this.currentConfICSectionInput)
      .then((res) => {
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_SECTION_ENTITY,
          'CREATE'
        );
        this.router.navigate(['../..'], {
          relativeTo: this.route,
        });
      })
      .catch((error) => {
        logger.log('create section error -->', error);
        this.trialpalService.showServiceError(
          this.INFORMED_CONSENT_SECTION_ENTITY,
          error
        );
      });
  }

  async modalChangeReason() {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'informedConsent.audit.updatingSection'
        ) +
        ': ' +
        this.trialpalService.dictionaryPipe.transform(
          this.currentConfICSectionInput.title ?? ''
        ),
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.currentConfICSectionInput._changeReason = data;
          this.updateConfICSection();
        } else {
          this.trialpalService.hideSpinner();
        }
      },
    });
  }
  async updateConfICSection(): Promise<void> {
    await this.informedConsentService
      .updateConfICSection(this.currentConfICSectionInput, this.expectedVersion)
      .then((res) => {
        this.trialpalService.showMutationSuccess(
          this.INFORMED_CONSENT_SECTION_ENTITY,
          'UPDATE'
        );
        this.updateCurrentRecord(res);
      })
      .catch((error) => {
        logger.log('update section error -->', error);
        this.trialpalService.showServiceError(
          this.INFORMED_CONSENT_SECTION_ENTITY,
          error
        );
      });
  }

  async getUploadKey(file: File): Promise<string> {
    if (this.uploadedFile) {
      const putResult = await this.informedConsentService.storage.put(
        this.uploadedFile,
        'conf-informed-consent/' + this.projectId + '/sectionsMedia/',
        file.name,
        'public'
      );
      return putResult.key;
    }
    return '';
  }

  onFileSelected(event: any): void {
    if (this.sectionFile && this.sectionFile?.msgs?.length > 0) {
      this.sectionFile.clear();
      this.sectionFile.msgs = [];
      this.onClearFile();
      return this.showFileMessageError();
    }
    this.isNewFileUpload = true;
    this.uploadedFile = event.files[0];
  }

  showFileMessageError() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant('general.error'),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.sections.sectionMediaError'
      ),
    });
  }

  onClearFile() {
    this.uploadedFile = undefined;
    this.myFiles = [];
  }

  async loadCurrentFile(): Promise<void> {
    if (this.currentConfICSectionInput.mediaUrl) {
      this.currentFileSanitizedUrl =
        this.informedConsentService.sanitizer.bypassSecurityTrustResourceUrl(
          await this.informedConsentService.storage.get(
            this.currentConfICSectionInput.mediaUrl
          )
        );
      this.displayModal = true;
    }
  }

  onMediaTypeSelected(event: any, resetFileContent?: boolean): void {
    if (resetFileContent) {
      this.myFiles = [];
    }
    switch (event) {
      case MediaType.AUDIO:
        this.fileUploadAcceptType = 'audio/mp3,audio/*;capture=microphone';
        break;
      case MediaType.VIDEO:
        this.fileUploadAcceptType = 'video/*';
        break;
      case MediaType.PHOTO:
        this.fileUploadAcceptType = 'image/*';
        break;
    }
  }

  addComplementaryQuestion() {
    const complementaryQuestionId = this.getComplementaryQuestionId();
    this.complementaryQuestions.push({
      ...this.complementaryQuestionInit,
      id: complementaryQuestionId,
    });
  }

  getComplementaryQuestionId() {
    return `${Math.floor(new Date().getTime() * Math.random())}`;
  }

  showModalDelete(index: number) {
    this.complementaryQuestionIndex = index;
    this.showDisplayDeleteComponent = true;
  }

  deleteComplementaryQuestion() {
    const complementaryQuestionIndex = this.complementaryQuestionIndex;

    this.complementaryQuestions = this.complementaryQuestions.filter(
      (_complementaryQuestion: any, index: number) => {
        return index !== complementaryQuestionIndex;
      }
    );
  }

  isComplementaryQuestionFormValid() {
    if (
      this.currentConfICSectionInput.type !==
      InformedConsentSectionType.OPTIONAL_CONSENT
    ) {
      return true;
    }

    return this.complementaryQuestions.every((complementaryQuestion) => {
      return complementaryQuestion.form?.valid;
    });
  }

  onGenerateMedia() {
    let windowHeight = '85%';
    if (this.currentConfICSectionInput.mediaType === MediaType.PHOTO) {
      windowHeight = window.innerHeight > 750 ? '59%' : '83%';
    } else if (this.currentConfICSectionInput.mediaType === MediaType.AUDIO) {
      windowHeight = window.innerHeight > 750 ? '65%' : '85%';
    }
    const ref = this.dialogService.open(GenerateMediaModalComponent, {
      header: this.trialpalService.translateService.instant(
        'informedConsent.sections.mediaGenerationTitle'
      ),
      data: {
        mediaType: this.currentConfICSectionInput.mediaType,
      },
      width: '90%',
      height: windowHeight,
    });

    ref.onClose.subscribe({
      next: (data: any) => this.onGenerateMediaClose(data),
    });
  }

  onGenerateMediaClose(data: any) {
    if (data) {
      logger.debug('media generated -->', data);
      const file = data;
      this.onFileSelected({ files: [file] });
      this.myFiles = [file];
    }
  }
}

export interface ICComplementaryQuestion {
  id: string | null;
  question: string | null | undefined;
  type: ComplementaryQuestionType | null | undefined;
  options: (string | null)[] | null | undefined;
  form: FormGroup;
}
