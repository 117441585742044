<div class="container-tp2">
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12">
      <h1>{{ 'report.actions.h1ReportList' | translate }}</h1>
    </div>

    <div class="p-col-12 p-md-6 p-xl-3">
      <button
        id="btn_return"
        name="btn_return"
        pButton
        label="{{ 'report.return' | translate }}"
        type="button"
        [routerLink]="['../../project/', projectId, 'detail']"
        icon="pi pi-chevron-left"
        class="p-button-outlined"
      ></button>
    </div>
    <div class="p-col-12 p-md-6 p-xl-4" *ngIf="hasReportCreatePermission">
      <button
        id="btn_createReport"
        name="btn_createReport"
        pButton
        label="{{ 'report.actions.buttonCreateReport' | translate }}"
        type="button"
        [routerLink]="['create']"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>

  <div class="p-grid p-fluid" *ngIf="reports.length > 0">
    <app-tpcard
      class="p-col-12 p-md-6 p-lg-4"
      *ngFor="let p of reports"
      [tpCardData]="buildTPCardData(p)"
      (delete)="deleteConfReport($event)"
      (customAction)="openFormInReadMode($event)"
      (edit)="editReport($event)"
    ></app-tpcard>
  </div>
  <div class="p-grid p-fluid" *ngIf="reports && reports.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
