import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/user/login/login.component';
import { AuthGuard } from './services/auth-guard.service';
import { AlertsEmailComponent } from './shared/components/alerts-email/alerts-email.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { Roles } from './shared/global.variables';
import { UnauthGuard } from './services/unauth-guard.service';
import { FeatureFlagsGuard } from './guards/feature-flags.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'alerts',
    canMatch: [FeatureFlagsGuard],
    component: AlertsEmailComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./homes/homes.module').then((m) => m.HomesModule),
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'sites',
    loadChildren: () =>
      import('./modules/sites/sites.module').then((m) => m.SitesModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./modules/roles/roles.module').then((m) => m.RolesModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'subjects',
    loadChildren: () =>
      import('./modules/subjects/subjects.module').then(
        (m) => m.SubjectsModule
      ),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'project',
    loadChildren: () =>
      import('./modules/project/project.module').then((m) => m.ProjectModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'ediary',
    loadChildren: () =>
      import('./modules/ediary/ediary.module').then((m) => m.EdiaryModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./modules/report/report.module').then((m) => m.ReportModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'informed-consent',
    loadChildren: () =>
      import('./modules/informed-consent/informed-consent.module').then(
        (m) => m.InformedConsentModule
      ),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./modules/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },
  {
    path: 'privacy-and-terms',
    loadChildren: () =>
      import('./privacy-and-terms/privacy-and-terms.module').then(
        (m) => m.PrivacyAndTermsModule
      ),
  },
  {
    path: 'app-versions',
    loadChildren: () =>
      import('./modules/version/version.module').then((m) => m.VersionModule),
    canActivate: [AuthGuard],
    canMatch: [FeatureFlagsGuard],
    data: {
      roles: [Roles.Admin, Roles.Investigator, Roles.Reader],
    },
  },

  // { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
