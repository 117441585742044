import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TrialpalService } from 'src/app/services/trialpal.service';

@Component({
  selector: 'app-change-reason',
  templateUrl: './change-reason.component.html',
  styleUrls: ['./change-reason.component.scss'],
})
export class ChangeReasonComponent implements OnInit {
  @ViewChild('f', { static: false }) form?: NgForm;
  @Input() validReason: any = {
    isInvalidReason: false,
  };
  isOtherReason: boolean = false;
  reason: string = '';
  otherReason: string = '';
  reasonsItems: any[] = [];
  reasonsKeys = [
    { value: 'typingError', label: 'general.changeReasons.typingError' },
    { value: 'other', label: 'general.changeReasons.other' },
  ];
  constructor(private trialpalService: TrialpalService) {}

  ngOnInit() {
    this.reason = '';
    this.otherReason = '';
    this.reasonsItems = [];
    this.validReason.isInvalidReason = false;

    this.isOtherReason = false;
    for (const reason of this.reasonsKeys) {
      this.reasonsItems.push({
        value: reason.value,
        label: this.trialpalService.translateService.instant(reason.label),
      });
    }
  }

  get validForm() {
    return this.form?.valid;
  }

  get selectedReason() {
    return this.reasonsItems.find((e) => e.value === this.reason)?.label;
  }

  changeValue() {
    if (this.reason) {
      this.isOtherReason = this.reason === 'other';
      if (!this.isOtherReason) {
        this.validReason.isInvalidReason = false;
      }
    }
  }

  changeArea() {
    this.validReason.isInvalidReason = this.otherReason?.trim().length === 0;
  }
}
