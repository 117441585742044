<form
  name="form"
  (ngSubmit)="onSubmit(f)"
  #f="ngForm"
  autocomplete="off"
  class="form"
>
  <div class="p-grid">
    <div class="p-col-7 p-fluid">
      <label>
        {{ 'general.selectPlaceholder' | translate }}
        <span class="inputRequired">* </span>
      </label>
      <p-calendar
        inputStyleClass="iText"
        name="fecha"
        id="fecha"
        [(ngModel)]="currentDate"
        [showIcon]="true"
        appendTo="body"
        placeholder="{{
          'addEditSubjectComponent.PlaceHolderFormatDate' | translate
        }}"
        dateFormat="dd-M-yy"
        [required]="true"
        #date="ngModel"
      ></p-calendar>
      <small
        class="form-error"
        *ngIf="!date.valid && (submitSuspendDaily || date.touched)"
      >
        {{ 'general.required' | translate }}</small
      >
      <div class="p-fluid p-grid margin">
        <label class="p-col-12"
          >{{ 'subject.daysCompleted' | translate }}: {{ completedDays }}</label
        >
        <label class="p-col-12"
          >{{ 'subject.daysPending' | translate }}: {{ pendingDays }}</label
        >
        <label class="p-col-12"
          >{{ 'subject.daysSuspended' | translate }}: {{ suspendedDays }}</label
        >
      </div>

      <div class="p-col-12">
        <label for="changeReason" class="p-mb-1"
          >{{ 'subject.suspensionReason' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <p-dropdown
          id="reasonSelect"
          name="reasonSelect"
          class="dp p-mb-1"
          [options]="reasonsItems"
          [(ngModel)]="selectedReason"
          #reasonSelect="ngModel"
          [required]="true"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          [showClear]="true"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="reasonSelect.invalid && (f.submitted || reasonSelect.touched)"
        >
          {{ 'general.required' | translate }}
        </small>
      </div>
      <div class="p-col-12" *ngIf="selectedReason === 'other'">
        <label for="other"
          >{{ 'general.changeReasons.other' | translate
          }}<span class="inputRequired"> * </span></label
        >
        <textarea
          pInputTextarea
          id="otherReason"
          name="otra"
          required="true"
          #otra="ngModel"
          [(ngModel)]="otherReason"
          [autoResize]="true"
        ></textarea>
        <small
          class="form-error"
          *ngIf="!otra.valid && (f.submitted || otra.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <button
        style="margin-top: 0.5rem"
        pButton
        label=" {{ 'subject.suspend' | translate }}"
        icon="pi pi-check"
        iconPos="right"
        type="submit"
        class="p-button-rounded p-button-secondary p-button-outlined margin"
      ></button>
    </div>
    <div class="p-col-5 p-fluid">
      <p-scrollPanel
        [style]="{ width: '100%', height: '550px' }"
        styleClass="custombar1"
        id="scrollpanel_subjects"
      >
        <div class="p-fluid" style="height: 30%">
          <app-tpcard
            id="card"
            class="p-col-12 p-md-4 p-lg-3"
            *ngFor="let p of dayInstances"
            [tpCardData]="buildTPCardData(p)"
          ></app-tpcard>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</form>
