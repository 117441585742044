import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from 'aws-amplify';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import {
  InstanceState,
  TemperatureRecordLog,
} from 'src/app/modules/conciliation/conciliation.types';
import { PaginationService } from '../../../../pagination.service';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import { TemperatureService } from '../temperature.service';

const logger = new Logger('tp2-conciliationDetail-temperatureDetail');

@Component({
  selector: 'app-detail-days-temperature',
  templateUrl: './detail-days-temperature.component.html',
  styleUrls: ['./detail-days-temperature.component.scss'],
})
export class DetailDaysTemperatureComponent implements OnInit {
  @Input() subjectId: string = '';
  @Input() timeZoneOffset = 0;
  @Input() confEdiaryId: string = '';
  @Output() exportAuditRecord: EventEmitter<any> = new EventEmitter();
  configurationByForm: any;
  dayIndex: number = 0;
  //Nombre de las columnas
  constructor(
    public temperatureService: TemperatureService,
    public conciliationService: ConciliationService,
    public paginationService: PaginationService,
    private dayInstanceService: DayInstanceService
  ) {}

  async ngOnInit() {
    this.temperatureService.colTemperatureDays = [];
    this.temperatureService.temperatures = [];
    this.temperatureService.verifiedTemperatures = [];
    this.temperatureService.observations = [];
    await this.temperatureService.getConfTemperatureByEdiary(this.confEdiaryId);
    await this.temperatureService.listTemperatureVerified(this.subjectId);
    await this.listTemperatureRecordLogs();
    this.temperatureService.addTemperatureComments();
    this.emitEventForm(this.temperatureService.colTemperatureDays[0]);
  }

  async listTemperatureRecordLogs() {
    this.temperatureService.colTemperatureDays = [];
    this.temperatureService.temperatures = [];
    const temperatureRoutes: any[] = [];
    const temperatures: any[] = [];
    const temperatureTakens: any[] = [];
    const temperatureTakenDates: any[] = [];

    //Obtiene las temperaturas del sujeto
    let temperaturesRecordLogs: TemperatureRecordLog[] =
      await this.temperatureService.listTemperatureRecordLogs(this.subjectId);

    //Obtiene todas las temperaturas verificadas del sujeto
    let verifiedTemperatures =
      this.temperatureService.verifiedTempeturesBySubject;

    //Obtiene los dias que han pasado y tenga habilitado temperatura
    const dayInstances = this.getPreviousDays();
    //Se encarga de llenar las temperaturas en la tabla
    await this.buildTemperatureRecordLogTable({
      dayInstances,
      temperaturesRecordLogs,
      verifiedTemperatures,
      temperatureRoutes,
      temperatureTakens,
      temperatureTakenDates,
      temperatures,
    });

    //Posicion por FILAS para la asignacion del array correspondiente
    this.temperatureService.temperatures[0] = temperatureRoutes;
    this.temperatureService.temperatures[1] = temperatures;
    this.temperatureService.temperatures[2] = temperatureTakens;
    this.temperatureService.temperatures[3] = temperatureTakenDates;

    logger.debug(
      'temperatureColDays table -->',
      this.temperatureService.colTemperatureDays
    );
  }

  async buildTemperatureRecordLogTable({
    dayInstances,
    temperaturesRecordLogs,
    verifiedTemperatures,
    temperatureRoutes,
    temperatureTakens,
    temperatureTakenDates,
    temperatures,
  }: any) {
    let index = 0;
    for (let dayAndConf of dayInstances) {
      let dayInstance = dayAndConf.dayInstance;
      let confForm = dayAndConf.configuration;

      let temperatureRecord = temperaturesRecordLogs.find(
        (temperature: any) => temperature.dayInstanceId === dayInstance.id
      );

      //Si el día no presenta temperatura toma un esqueleto de la misma
      if (!temperatureRecord?.id) {
        temperatureRecord = this.getTemperatureRecordLog(dayInstance);
      }

      const dayName = dayInstance?.confDay?.dayName || '';

      //Obtiene el dia verificado perteneciente al día
      const verifiedDayInstance =
        await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
          dayInstance.id
        );

      //Obtiene la temperatura verificada
      const verifiedTemperature = this.getVerifiedTemperatureRecordLog(
        verifiedTemperatures,
        verifiedDayInstance.id
      );

      //Obtiene la configuración de la temperatura
      const confTemperature = this.temperatureService.getConfTemperatureByForm(
        confForm?.id
      );

      const {
        temperatureRoute,
        temperatureTaken,
        temperatureTakenDate,
        temperature,
      } = this.temperatureService.getTemperatureValues(
        temperatureRecord,
        verifiedTemperature
      );

      temperatureRoutes.push(temperatureRoute);
      temperatureTakens.push(temperatureTaken);
      temperatureTakenDates.push(temperatureTakenDate);
      temperatures.push(temperature);

      //Llena la tabla con los datos de los sintomas
      this.temperatureService.colTemperatureDays.push({
        field: dayName,
        header: dayName,
        temperatureRecord,
        verifiedTemperature,
        configuration: confTemperature,
        isEdition: verifiedTemperature?.id,
        index,
        timeZoneOffset: this.timeZoneOffset,
        dayInstance,
        confForm: confForm,
        verifiedDayInstance,
        isPendingInstance: !temperatureRecord?.id,
        temperatureRoute,
        temperatureTaken,
        temperatureTakenDate,
        temperature,
      });
      index++;
    }
  }
  /**
   * Esta función busca un registro de registro de temperatura verificado basado en el ID de registro de
   * temperatura o el ID de instancia de día verificado.
   * @param {any[]} verifiedTemperatures - una serie de objetos que contienen registros de temperatura
   * verificados
   * @param {string} temperatureRecordId - una cadena que representa el ID de un registro de registro de
   * temperatura.
   * @param {string} verifiedDayInstanceId - Una cadena que representa el identificador único para una
   * instancia de día verificada.
   * @returns un objeto de registro de registro de temperatura verificado de una matriz de temperaturas
   * verificadas en función del ID de registro de temperatura proporcionado o el ID de instancia de día
   * verificado.
   */
  getVerifiedTemperatureRecordLog(
    verifiedTemperatures: any[],
    verifiedDayInstanceId: any
  ) {
    let verifiedTemperature = verifiedTemperatures.find(
      (verifiedTemperature: any) => {
        return (
          verifiedTemperature.verifiedDayInstanceId === verifiedDayInstanceId
        );
      }
    );
    return verifiedTemperature ?? {};
  }

  getTemperatureRecordLog(dayInstance: any): TemperatureRecordLog {
    return {
      __typename: 'TemperatureRecordLog',
      subjectId: this.subjectId,
      state: InstanceState.PENDING,
      _lastUser: '',
      _version: 0,
      createdAt: '',
      updatedAt: '',
      _lastChangedAt: 0,
      id: '',
      dayInstance: dayInstance,
      dayInstanceId: dayInstance?.id,
      temperature: undefined,
      temperatureRoute: undefined,
      temperatureTaken: false,
      temperatureTakenDate: undefined,
      temperatureUnit: undefined,
      temperatureWhichOtherRoute: undefined,
    };
  }

  //Obtiene los dayInstances con las temperaturas activos
  getPreviousDays() {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    //Filtra los días que ya hayan pasado
    dayInstances = dayInstances.filter((DI: any) => {
      const isPastDay = this.conciliationService.isBeforeToCurrentDate(
        DI.dayInstance.finishDate
      );

      //Valida si la temperatura para el formulario actual puede ser conciliada
      const IsTemperatureConciliationAllowed =
        this.paginationService.isSectionReconciliationAllowed(
          'isTemperatureRequired',
          DI.configuration
        );

      return (
        isPastDay &&
        DI.configuration?.isTemperatureRequired &&
        IsTemperatureConciliationAllowed
      );
    });

    return dayInstances.sort(
      (a, b) => a?.dayInstance?.confDay?.order - b?.dayInstance?.confDay?.order
    );
  }

  emitEventForm(record: any) {
    this.paginationService.selectedTemperature(record);
    logger.debug(
      'emitEventForm',
      this.temperatureService.currentTemperatureDay
    );
  }
}
