import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TrialpalService } from '../services/trialpal.service';
import { TermsAndConditionsComponent } from '../shared/components/terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  loginSubs: Subscription;
  isLoggedIn = false;
  year = new Date().getFullYear();

  constructor(
    public authService: AuthService,
    public trialpalService: TrialpalService,
    private dialogService: DialogService
  ) {
    this.loginSubs = this.authService.userAuthenticated.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
  }
  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated();
  }
  ngOnDestroy(): void {
    this.loginSubs.unsubscribe();
  }
  ViewLang() {
    return localStorage.getItem('lang');
  }

  showTermsOrPolicy(isTerms: boolean): void {
    this.dialogService.open(TermsAndConditionsComponent, {
      header: this.trialpalService.translateService.instant(
        'general.terms.termsTitle'
      ),
      data: {
        isTerms: isTerms,
      },
      width: '90%',
      height: '100%',
      dismissableMask: true,
      closeOnEscape: true,
    });
  }
}
