import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';
import { ConfICSection } from 'src/app/modules/informed-consent/informed-consent.types';

@Component({
  selector: 'app-signature-econsent',
  templateUrl: './signature-econsent.component.html',
  styleUrls: ['./signature-econsent.component.scss'],
})
export class SignatureEconsentComponent implements OnInit {
  confInformedConsentId: string = '';
  confICSection!: ConfICSection | undefined; //Sección de firma por el investigador
  consenterRol: string = '';
  isStudyRolRequired: boolean = false;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private econsentService: InformedConsentService
  ) {
    this.confInformedConsentId = this.config.data?.confInformedConsentId ?? '';
  }

  async ngOnInit() {
    this.econsentService.trialpalService.showSpinner(
      this.econsentService.trialpalService.translateService.instant(
        'general.loadingInfo'
      )
    );
    const confICSections =
      await this.econsentService.getSignatureEconsentConfICSection(
        this.confInformedConsentId
      );
    if (confICSections.length > 0) {
      this.confICSection = confICSections[0];
      this.isStudyRolRequired = this.confICSection?.isStudyRolRequired ?? false;
    }
    this.econsentService.trialpalService.hideSpinner();
  }

  isUserAuthenticated() {
    this.ref.close({
      consenterRol: this.consenterRol,
      isSignatureCorrect: true,
    });
  }
}
