import { NgModule } from '@angular/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { InformedConsentAnswersComponent } from './informed-consent-answers/informed-consent-answers.component';
import { InformedConsentQuestionAddEditComponent } from './informed-consent-question-add-edit/informed-consent-question-add-edit.component';
import { InformedConsentQuestionListComponent } from './informed-consent-question-list/informed-consent-question-list.component';
import { InformedConsentQuestionRoutingModule } from './informed-consent-question-routing.module';
@NgModule({
  declarations: [
    InformedConsentQuestionListComponent,
    InformedConsentQuestionAddEditComponent,
    InformedConsentAnswersComponent,
  ],
  imports: [
    PrimengModule,
    SharedComponentsModule,
    InformedConsentQuestionRoutingModule,
  ],
  exports: [InformedConsentQuestionListComponent],
})
export class InformedConsentQuestionModule {}
