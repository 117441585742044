<footer class="footer justify-space">
  <div class="footer">
    <button
      pButton
      pRipple
      id="btn_preview"
      label="{{ 'report.execution.btnPrevious' | translate }}"
      type="button"
      class="p-button-secondary btn-preview"
      style="width: max-content"
      (click)="previous.emit()"
      [disabled]="previousDisabled"
    ></button>
    <button
      pButton
      pRipple
      id="btn_next"
      label="{{ 'report.execution.btnNext' | translate }}"
      type="button"
      class="p-button-secondary btn-preview"
      style="width: max-content"
      (click)="next.emit()"
      [disabled]="nextDisabled"
    ></button>
  </div>

  <button
    pButton
    pRipple
    id="btn_close"
    label="{{ 'report.execution.btnClose' | translate }}"
    type="button"
    class="p-button-secondary p-button-outlined btn-preview btn-close"
    style="width: max-content"
    (click)="close()"
  ></button>
</footer>
