<div [ngClass]="{ 'container-tp2': !isModal }">
  <h1 *ngIf="!isModal">
    {{ (editMode ? 'site.buttonCreateSite' : 'site.h1EditSite') | translate }}
  </h1>

  <form id="form" class="form" name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12" *ngIf="!isModal">
        <h3>{{ 'project.titleBasicData' | translate }}</h3>
      </div>

      <div class="p-grid p-fluid p-mt-3">
        <div class="p-grid">
          <div class="p-field p-col-12 p-md-6">
            <label>
              {{ 'site.siteName' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <input
              id="site_name"
              name="site_name"
              type="text"
              autocomplete="off"
              [(ngModel)]="site.name"
              required
              #site_name="ngModel"
              pInputText
            />
            <small
              class="form-error"
              *ngIf="site_name.invalid && (f.submitted || site_name.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="siteCode">
              {{ 'site.siteCode' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <input
              id="siteCode"
              name="siteCode"
              type="text"
              autocomplete="off"
              [(ngModel)]="site.siteCode"
              required
              #siteCode="ngModel"
              pInputText
            />
            <small
              class="form-error"
              *ngIf="
                siteCode.invalid &&
                (f.submitted || siteCode.touched) &&
                !siteCode.errors?.siteCodeRepeated
              "
              >{{ 'general.required' | translate }}</small
            >
            <small
              class="form-error"
              *ngIf="
                siteCode.invalid &&
                (f.submitted || siteCode.touched) &&
                siteCode.errors?.siteCodeRepeated
              "
              >{{ 'general.site.repeatedMessage' | translate }}</small
            >
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label>
              {{ 'project.labelTimezone' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <p-dropdown
              ngDefaultControl
              [showClear]="true"
              class="input"
              id="timezone"
              name="timezone"
              [options]="timezoneOptions"
              [(ngModel)]="site.timezone"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              [required]="true"
              #timezone="ngModel"
              optionLabel="name"
              optionValue="name"
              [filter]="true"
              filterBy="name,time"
              emptyMessage="{{ 'general.noResultsFound' | translate }}"
              emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
            >
              <ng-template let-item pTemplate="item">
                {{ item.name }} - ({{ item.time }})
              </ng-template>
            </p-dropdown>
            <small
              class="form-error"
              *ngIf="timezone.invalid && (f.submitted || timezone.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label>
              {{ 'site.language' | translate }}
              <span class="inputRequired">* </span>
            </label>
            <p-dropdown
              ngDefaultControl
              [showClear]="true"
              class="input"
              placeholder="{{ 'general.choose' | translate }}"
              name="language"
              [options]="languageOptions"
              [(ngModel)]="site.defaultLanguage"
              (onChange)="onDefaultLanguageChange()"
              [required]="true"
              #language="ngModel"
              optionLabel="label"
              optionValue="value"
            ></p-dropdown>
            <small
              class="form-error"
              *ngIf="language.invalid && (f.submitted || language.touched)"
              >{{ 'general.required' | translate }}</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label>
              {{ 'site.additionalLanguages' | translate }}
            </label>

            <p-multiSelect
              appendTo="body"
              name="additionalLanguages"
              class="input multiSelect"
              id="additionalLanguages"
              #additionalLanguages="ngModel"
              defaultLabel="{{ 'general.choose' | translate }}"
              placeholder="{{ 'general.choose' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              [options]="additionalLanguagesOptions"
              [(ngModel)]="site.additionalLanguages"
              [filter]="false"
              [showHeader]="false"
            ></p-multiSelect>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="timezoneRecipients">
              {{ 'site.labelTimezoneRecipients' | translate }}
              <span
                class="inputRequired"
                *ngIf="
                  site.isTimezoneNotificationEnable ||
                  site.isDailyPendingDiariesNotificationEnable
                "
                >*
              </span>
            </label>
            <div>
              <p-chips
                id="timezoneRecipients"
                [allowDuplicate]="false"
                separator=","
                name="timezoneRecipients"
                [(ngModel)]="site.timezoneRecipients"
                (onAdd)="onAddRecipient($event.value)"
                [required]="
                  !!site.isTimezoneNotificationEnable ||
                  !!site.isDailyPendingDiariesNotificationEnable
                "
                [addOnBlur]="true"
                [placeholder]="
                  'informedConsent.formEmailPlaceHolder' | translate
                "
                #timezoneRecipients="ngModel"
              >
              </p-chips>
              <small
                class="form-error"
                *ngIf="
                  timezoneRecipients.invalid &&
                  (f.submitted || timezoneRecipients.touched)
                "
                >{{ 'general.required' | translate }}</small
              >
            </div>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="isTimezoneNotificationEnable">{{
              'site.isTimezoneNotificationEnable' | translate
            }}</label>
            <div class="p-col p-pl-0 centerItem">
              <p-inputSwitch
                id="isTimezoneNotificationEnable"
                name="isTimezoneNotificationEnable"
                class="p-pb-2"
                #isTimezoneNotificationEnable="ngModel"
                [(ngModel)]="site.isTimezoneNotificationEnable"
                pTooltip="{{ 'site.isTimezoneNotificationEnable' | translate }}"
                tooltipPosition="top"
              ></p-inputSwitch>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="isDailyPendingDiariesNotificationEnable">{{
              'site.isDailyPendingDiariesNotificationEnable' | translate
            }}</label>
            <div class="p-col p-pl-0 centerItem">
              <p-inputSwitch
                id="isDailyPendingDiariesNotificationEnable"
                name="isDailyPendingDiariesNotificationEnable"
                class="p-pb-2"
                #isDailyPendingDiariesNotificationEnable="ngModel"
                [(ngModel)]="site.isDailyPendingDiariesNotificationEnable"
                pTooltip="{{
                  'site.isDailyPendingDiariesNotificationEnable' | translate
                }}"
                tooltipPosition="top"
              ></p-inputSwitch>
            </div>
          </div>

          <div
            class="p-field p-col-12 p-md-6"
            *ngIf="site.isDailyPendingDiariesNotificationEnable"
          >
            <label for="contactInfo"
              >{{ 'site.notificationSchedule' | translate
              }}<span class="inputRequired"> * </span></label
            >

            <p-multiSelect
              appendTo="body"
              name="notificationSchedule"
              class="input multiSelect"
              id="notificationSchedule"
              #notificationSchedule="ngModel"
              defaultLabel="{{ 'general.choose' | translate }}"
              placeholder="{{ 'general.choose' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              [options]="hoursOptions"
              [(ngModel)]="site.notificationSchedule"
              [filter]="false"
              [showHeader]="false"
            ></p-multiSelect>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="contactInfo">{{
            'site.labelDisabledContact' | translate
          }}</label>
          <div class="p-col p-pl-0 centerItem">
            <p-inputSwitch
              id="contactInfo"
              name="contactInfo"
              class="p-pb-2"
              #controlledBySite="ngModel"
              [(ngModel)]="site.showContactInfo"
              (onChange)="changePreferenceContactInfo($event)"
              pTooltip="{{ 'site.labelDisabledContact' | translate }}"
              tooltipPosition="top"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="site.showContactInfo">
          <label for="contactInfoLogin">{{
            'site.labelShowContactHome' | translate
          }}</label>
          <div class="p-col p-pl-0 centerItem">
            <p-inputSwitch
              id="ContactInfoLogin"
              name="ContactInfoLogin"
              #controlledBySite="ngModel"
              [(ngModel)]="site.showContactInfoLogin"
              [disabled]="!site.showContactInfo"
              pTooltip="{{ 'site.labelShowContactHome' | translate }}"
              tooltipPosition="top"
            ></p-inputSwitch>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-field" *ngIf="site.showContactInfo">
        <label for="contactTextHTML">{{
          'confContact.labelContactHTML' | translate
        }}</label
        ><span class="inputRequired"> * </span>
        <app-simple-dictionary-editor
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="site.contactInfo"
          (outputEvent)="site.contactInfo = $event"
        >
        </app-simple-dictionary-editor>
        <p-editor
          name="contactTextHTML"
          #contactTextHTML="ngModel"
          [required]="true"
          [hidden]="true"
          [ngModel]="site.contactInfo"
        ></p-editor>
      </div>

      <div
        class="
          p-field p-col-12 p-sm-6 p-md-4 p-md-offset-4 p-xl-2 p-xl-offset-8
        "
        *ngIf="!isModal"
      >
        <button
          class="p-button-outlined p-button-secondary"
          pButton
          label="{{ 'project.buttonCancel' | translate }}"
          type="button"
          (click)="onCancelar()"
        ></button>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-4 p-xl-2" *ngIf="!isModal">
        <button
          id="btnSend"
          pButton
          label="
            {{
            (editMode ? 'project.buttonUpdate' : 'project.buttonAdd')
              | translate
          }}"
          type="submit"
        ></button>
      </div>
      <div class="p-grid p-mt-2 p-col-12" *ngIf="isModal">
        <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
          <button
            class="p-button-outlined p-button-secondary"
            pButton
            label="{{ 'project.buttonCancel' | translate }}"
            type="button"
            (click)="onCancelar()"
          ></button>
        </div>
        <div class="p-field p-col-12 p-sm-4 p-md-3">
          <button
            id="btnSend2"
            pButton
            label="
            {{
              (editMode ? 'project.buttonUpdate' : 'project.buttonAdd')
                | translate
            }}"
            type="submit"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
