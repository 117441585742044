import { Component, OnInit, ViewChild } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { InstanceState } from 'src/app/modules/conciliation/conciliation.types';
import { TrialpalService } from '../../../../../../services/trialpal.service';
import { ConciliationService } from '../../../../conciliation.service';
import { PaginationService } from '../../../../pagination.service';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import { TableDayInstanceComponent } from '../../shared/day-instance/table-day-instance/table-day-instance.component';
import { DeleteReasonComponent } from '../../shared/delete-reason/delete-reason.component';
import { SymptomService } from '../../symptom/symptom.service';
const logger = new Logger('table other symptom conciliation');
@Component({
  selector: 'app-detail-other-symptoms',
  templateUrl: './detail-other-symptoms.component.html',
  styleUrls: ['./detail-other-symptoms.component.scss'],
})
export class DetailOtherSymptomsComponent implements OnInit {
  @ViewChild(TableDayInstanceComponent)
  tableDayInstanceComponent!: TableDayInstanceComponent;
  constructor(
    private trialpalService: TrialpalService,
    private conciliationService: ConciliationService,
    private dialogService: DialogService,
    private dayInstanceService: DayInstanceService,
    public symptomService: SymptomService,
    public paginationService: PaginationService
  ) {}

  async ngOnInit() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant('subject.tabheaderSymptom'),
      'LIST'
    );
  }

  async isOtherSymptomDaysLoading(otherSymptomColDays: any) {
    this.symptomService.otherSymptomColDays = otherSymptomColDays;
    if (this.symptomService.addConfOtherSymptoms.length === 0) {
      await this.symptomService.getConfOtherSymptoms();
    }
    this.listOtherSymptomInstances();
    //Ordena la tabla segun el orden de los dias
    this.symptomService.tableOtherSymptoms.sort((a: any, b: any) => {
      return a?.dayInstance?.confDay?.order - b?.dayInstance?.confDay?.order;
    });
    this.symptomService.addOtherSymptomComments();
  }

  listOtherSymptomInstances() {
    this.symptomService.tableOtherSymptoms = [];

    let otherSymptomInstances = this.symptomService.symptomInstances?.filter(
      (symptomInstance: any) => symptomInstance?.isOtherSymptom
    );

    for (let symptomInstance of otherSymptomInstances) {
      //Obtiene la información del dia del sintoma
      const dayInstance =
        this.conciliationService.dayInstancesAndConfigurations.find(
          (dayConf: any) =>
            dayConf.dayInstance.id === symptomInstance.dayInstanceId
        )?.dayInstance;

      const isDayBeforeToCurrentDate =
        this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);

      if (isDayBeforeToCurrentDate) {
        let confFormId = dayInstance.confDay.confFormId;

        //Obtiene la configuración del otro sintoma
        let confOtherSymptom = this.symptomService.addConfOtherSymptoms.find(
          (configuration: any) => configuration.confFormId === confFormId
        )?.confOtherSymptom;

        //Obtiene los sintomas verificados
        const verifiedOtherSymptoms: any[] =
          this.symptomService.getVerifiedSymptomInstancesBySymptomId(
            symptomInstance.id
          );
        const verifiedOtherSymptom = verifiedOtherSymptoms[0];

        this.symptomService.tableOtherSymptoms.push({
          confSymptom: confOtherSymptom,
          dayInstance,
          confForm: dayInstance.confDay?.confForm,
          dayName: dayInstance.confDay.dayName,
          otherSymptom: symptomInstance,
          verifiedOtherSymptom,
          isEdition: !!verifiedOtherSymptom?.id,
          symptomName: this.symptomService.getOtherSymptomName(symptomInstance),
          verifiedSymptomName:
            this.symptomService.getOtherSymptomName(verifiedOtherSymptom),
          comments: {},
        });
      }
    }

    this.getNewOtherSymptoms();
  }

  getNewOtherSymptoms() {
    const verifiedSymptomInstances =
      this.symptomService.getVerifiedSymptomInstancesBySymptomId(
        this.conciliationService.NEW_INSTANCE_ID
      );
    for (let verifiedOtherSymptom of verifiedSymptomInstances) {
      let dayInstance: any =
        this.conciliationService.getDayInstanceByVerifiedDayInstanceId(
          verifiedOtherSymptom.verifiedDayInstanceId
        );
      const isDayBeforeToCurrentDate =
        this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);

      if (dayInstance?.id && isDayBeforeToCurrentDate) {
        const confFormId = dayInstance?.confDay?.confFormId ?? '';
        let confSymptom: any = this.symptomService.addConfOtherSymptoms.find(
          (configuration: any) => configuration.confFormId === confFormId
        )?.confOtherSymptom;

        this.symptomService.tableOtherSymptoms.push({
          confSymptom,
          dayInstance,
          confForm: dayInstance.confDay?.confForm,
          dayName: dayInstance.confDay.dayName,
          otherSymptom: this.symptomService.getEmptyOtherSymptom(
            dayInstance?.id,
            verifiedOtherSymptom.confSymptomId
          ),
          verifiedOtherSymptom,
          isEdition: !!verifiedOtherSymptom?.id,
          symptomName: '',
          verifiedSymptomName:
            this.symptomService.getOtherSymptomName(verifiedOtherSymptom),
          comments: {},
        });
      }
    }
  }

  newOtherSymptom() {
    this.paginationService.selectedOtherSymptom({}, true);
  }

  showDeleteButton() {
    const currentOtherSymptom = this.symptomService.currentOtherSymptom;
    const otherSymptomsReconciliationConfig =
      currentOtherSymptom?.confForm?.otherSymptomsReconciliationConfig;
    if (otherSymptomsReconciliationConfig?.allowRemoveRecords === false)
      return false;
    const otherSymptom = currentOtherSymptom?.otherSymptom;
    const verifiedOtherSymptom = currentOtherSymptom?.verifiedOtherSymptom;
    if (
      (otherSymptom?.id === this.conciliationService.NEW_INSTANCE_ID &&
        !verifiedOtherSymptom?.id) ||
      (otherSymptom?.id &&
        verifiedOtherSymptom?.state === InstanceState.DELETED) ||
      !otherSymptom?.id
    ) {
      return false;
    }

    return true;
  }
  confirmDeleteOtherSymptom() {
    const ref = this.dialogService.open(DeleteReasonComponent, {
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmDelete'
      ),
      style: { 'min-width': '360px', width: '50%' },
    });
    ref.onClose.subscribe(async (deleteReason: string) => {
      if (deleteReason) {
        await this.changeOtherSymptomToDeleteState(deleteReason);
      }
    });
  }

  async changeOtherSymptomToDeleteState(deleteReason: string) {
    try {
      this.conciliationService.showSpinner(
        'transactionLoadingDelete',
        'symptominstance.entity'
      );
      const currentOtherSymptom = this.symptomService.currentOtherSymptom;
      const /* Una variable que se utiliza para almacenar los datos de los otros síntomas. */
        otherSymptom = currentOtherSymptom.otherSymptom;
      let verifiedOtherSymptom = currentOtherSymptom?.verifiedOtherSymptom;
      //Obtiene el dia verificado
      const verifiedDayInstance =
        await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
          otherSymptom.dayInstanceId
        );

      const otherSymptomInput: any = this.symptomService.getDeleteOtherSymptom(
        deleteReason,
        verifiedDayInstance.id
      );
      let isOtherSymptomDelete = false;
      if (verifiedOtherSymptom?.id) {
        const { verified, isDelete } =
          await this.symptomService.deleteVerifiedOtherSymptomInstanceTable(
            otherSymptom,
            verifiedOtherSymptom,
            otherSymptomInput
          );

        isOtherSymptomDelete = isDelete;
        verifiedOtherSymptom = verified;
      } else {
        verifiedOtherSymptom = await this.symptomService.createVerifiedSymptom(
          otherSymptom,
          otherSymptomInput
        );
      }

      this.symptomService.currentOtherSymptom.verifiedOtherSymptom =
        verifiedOtherSymptom;

      await this.updateVerifiedDayInstance(
        otherSymptom.dayInstanceId,
        deleteReason
      );
      await this.conciliationService.updateEdiaryPhaseState(false).catch();
      this.symptomService.addOtherSymptomComments();
      this.symptomService.currentOtherSymptom = {};
      this.paginationService.selectedOtherSymptom({}, true);

      //Si fue borrado de la base de datos no se mostrara el sintoma (_delete = true)
      if (!isOtherSymptomDelete) {
        this.paginationService.selectedOtherSymptom(currentOtherSymptom, false);
      }
      this.trialpalService.hideSpinner();
      this.conciliationService.messageDeleteSuccess();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.debug('eliminar otro sintoma', error);
    }
  }

  async updateVerifiedDayInstance(dayInstanceId: string, deleteReason: string) {
    const otherSymptomInput = {
      hasOtherSymptoms: false,
      hasOtherSymptomsComment: deleteReason,
    };
    //LLama la funcion para actualizar la precondicion del dia
    await this.dayInstanceService.updateVerifiedDayInstanceByDeleteInstances(
      dayInstanceId,
      'otherSymptom',
      'verifiedOtherSymptom',
      otherSymptomInput,
      this.symptomService.tableOtherSymptoms,
      this.tableDayInstanceComponent
    );
  }
}
