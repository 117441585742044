<div class="container-tp2">
  <div class="p-grid p-fluid">
    <div class="p-col-12" style="display: flex; align-items: center">
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        id="btn_forward"
        class="
          p-button-rounded
          p-button-secondary
          p-button-outlined
          p-button
          p-button-icon-only
        "
        (click)="forward()"
      ></button>
      <div class="p-col-10">
        <h1>{{ 'subject.h1SubjectList' | translate }}</h1>
      </div>
    </div>

    <div class="p-col-12 p-md-4 p-xl-4" *ngIf="hasSubjectCreatePermission">
      <button
        pButton
        label="{{ 'subject.buttonCreateSubject' | translate }}"
        type="button"
        [routerLink]="['create']"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>

  <div class="" *ngIf="subjects.length > 0 && hasSubjectListPermission">
    <app-button-table-card-mode
      dataSource="subjectsList"
      (cardOrTableModeEmit)="tableViewMode = $event"
    ></app-button-table-card-mode>
    <section *ngIf="!tableViewMode">
      <div class="p-grid p-fluid" *ngIf="subjects.length > 0">
        <app-tpcard
          id="cards"
          *ngFor="let p of subjectsPaginated"
          class="p-col-12 p-md-6 p-lg-4"
          [tpCardData]="buildTPCardData(p)"
          (delete)="openChangeReason($event)"
          (edit)="editSubject($event)"
          (detail)="goDetailSubject($event)"
        ></app-tpcard>
        <div class="p-col-12">
          <p-paginator
            id="paginator"
            [rows]="pagesize"
            [totalRecords]="subjects.length"
            (onPageChange)="paginate($event)"
          >
          </p-paginator>
        </div>
      </div>
    </section>
    <section *ngIf="tableViewMode" class="p-mb-5">
      <app-tptable
        [dataTable]="dataTable"
        (customAction1)="
          cardOrTableService.openAuditModal({
            data: $event.data,
            header: $event.name,
            entity: $event.entity
          })
        "
        (delete)="openChangeReason($event)"
        (edit)="editSubject($event)"
        (openDetail)="goDetailSubject($event.data)"
      ></app-tptable>
    </section>
  </div>

  <div
    class="p-grid p-fluid"
    *ngIf="!hasSubjectListPermission || subjects.length === 0"
  >
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</div>
