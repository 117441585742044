import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import {
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  imports: [CommonModule],
  exports: [
    FormsModule,
    CommonModule,
    AutoCompleteModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextModule,
    CalendarModule,
    SelectButtonModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    InputNumberModule,
    InputTextareaModule,
    InputSwitchModule,
    ConfirmDialogModule,
    ChartModule,
    CardModule,
    DropdownModule,
    BlockUIModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    DynamicDialogModule,
    DialogModule,
    MenuModule,
    StepsModule,
    TableModule,
    MenubarModule,
    ProgressBarModule,
    TabViewModule,
    AccordionModule,
    FieldsetModule,
    TooltipModule,
    SelectButtonModule,
    EditorModule,
    PasswordModule,
    ChipsModule,
    FileUploadModule,
    PaginatorModule,
    ListboxModule,
    BadgeModule,
    ScrollPanelModule,
    ToolbarModule,
    SidebarModule,
    SkeletonModule,
    PickListModule,
    SelectButtonModule,
    DragDropModule,
    ToggleButtonModule,
  ],
  providers: [DynamicDialogRef, DynamicDialogConfig],
  declarations: [],
})
export class PrimengModule {}
