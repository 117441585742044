import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';

import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TP2IdEntity } from 'src/app/services/trialpal.types';
import { UserPermissionsService } from 'src/app/services/user-permissions-service';
import { Roles, emailRegex } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { RolesService } from '../../roles/roles.service';
import { SubjectsService } from '../../subjects/subjects.service';
import { Subject, SubjectState } from '../../subjects/subjects.types';
import { UserService } from '../user.service';
import { TP2UserInput, UserState } from '../user.types';
const logger = new Logger('tp2-logger-userCreatePage');

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss'],
})
export class CreateUsersComponent implements OnInit {
  @ViewChild('f', { static: true }) userForm: any;
  users = [];
  projects: any[] = [];
  assignProject: boolean = false;
  assignSubjects: boolean = false;
  assignRoles: boolean = false;
  count = 0;
  maxProjectsReached = false;
  maxSitesReached = false;
  maxSubjectsReached = false;
  //roles
  roles: any[] = [];
  userRoles: any[] = [];
  selectedRoles: string[] = [];

  //sites
  userSites: any[] = [];
  sites: any[] = [];
  selectedSites: string[] = [];
  groupSites: any[] = [];

  //projects
  selectedProjects: string[] = [];
  selectedSubjects: string[] = [];

  showToggleAll = true;
  user: TP2UserInput = {
    login: '',
    email: '',
    role: '',
    name: '',
    phoneNumber: null,
    state: UserState.ENABLED,
    notificationPreference: '',
  };
  role: any[] = [];
  idProject = '';
  idSubject = '';
  isFromSubjectDetail = false;
  BlockDropdownProject = false;
  allActiveRoles: any[] = [];
  emailPattern: RegExp = emailRegex;

  alertTypes: string[] = [];
  isProjectMFAActive: boolean = false;
  constructor(
    public trialpalService: TrialpalService,
    private authService: AuthService,
    public projectService: ProjectService,
    private userService: UserService,
    private subjectService: SubjectsService,
    private confirmationService: ConfirmationService,
    private configDialog: DynamicDialogConfig,
    private rolesService: RolesService,
    private userPermissionsService: UserPermissionsService
  ) {
    this.allActiveRoles = this.userService.getAllActivatedRoles();
  }

  async ngOnInit(): Promise<void> {
    this.idProject = this.configDialog.data.currentProject;
    this.idSubject = this.configDialog.data.currentSubject;
    this.isFromSubjectDetail = this.idSubject !== '';
    this.allActiveRoles = this.userService.getAllActivatedRoles();
    this.authService.userAuthenticated.subscribe((res) => {
      if (!res) {
        this.trialpalService.ref.close(null);
      }
    });
    this.loadAlertTypes();
    if (!this.isFromSubjectDetail) {
      if (this.idProject) {
        await this.getProject(this.idProject);
      } else {
        await this.getProjects();
      }
      this.roles = await this.getRoles();
    }
  }

  loadAlertTypes(): void {
    this.userService.getAlertTypes().then((res) => {
      this.alertTypes = res;
    });
  }

  async getRoles(): Promise<any> {
    this.trialpalService.showSpinner('roles.entityPlural', 'GET');
    this.userRoles = await this.rolesService.getRolesForCreateEditUser(
      this.idProject
    );

    this.userRoles = this.userRoles
      .filter((role: any) => role.isEnabled)
      .map((role: any) => {
        return {
          label: role.name,
          value: role.id,
          projects: role.isForProjects ? role.projects : [],
          isForProjects: role.isForProjects,
          permissions: role.permissions,
        };
      });

    this.trialpalService.hideSpinner();
    return this.idProject
      ? this.userRoles
      : this.userRoles.filter((role: any) => !role.isForProjects);
  }

  async getProjects() {
    this.trialpalService.showSpinner('project.entityPlural', 'LIST');
    const currentProjects = await this.projectService.getProjects();
    logger.debug('listUserProjects response', currentProjects);
    const projects: any = [];
    for (const project of currentProjects) {
      projects.push({
        isMFAActivated: project.isMFAActivated,
        value: project.id,
        label: project.name,
      });
    }
    this.projects = projects;
    await this.getSite();

    this.trialpalService.hideSpinner();
  }
  async getProject(idProject: string) {
    this.trialpalService.showSpinner('project.entity', 'GET');
    const projects: any = [];
    const projectIds: string[] = [];
    const project = await this.userService.getProject(idProject);
    this.isProjectMFAActive = project.isMFAActivated ?? false;
    projectIds.push(project.id);
    projects.push({
      value: project.id,
      label: project.name,
      isMFAActivated: project.isMFAActivated,
    });
    this.BlockDropdownProject = true;
    this.projects = projects;
    await this.getSite(projectIds);
    this.trialpalService.hideSpinner();
  }

  async getSite(projectIds?: any): Promise<any> {
    let currentSites = projectIds
      ? await this.projectService.listUserSites(projectIds)
      : await this.projectService.getSites();
    logger.debug('listUserSites response', currentSites);
    if (currentSites && currentSites.length > 0) {
      currentSites = currentSites.filter((site: any) => !site._deleted);
      for (const site of currentSites) {
        this.userSites.push({
          value: site.id,
          label: site.name,
          projectId: site.projectId,
        });
      }
    }
    if (this.idProject) {
      this.selectedProjectsEvent();
    }
  }

  onSubmit(form: NgForm): void {
    this.removeSpaces();
    this.confirmErrors(form);
    if (
      form.valid &&
      this.trialpalService.phoneNumberService.isValidPhoneNumber(
        this.user.phoneNumber
      ).isValid
    ) {
      if (this.getValidateRole(Roles.Admin)) {
        this.createAdminUser();
      } else if (
        this.getValidateRole(Roles.Reader) ||
        this.getValidateRole(Roles.Investigator)
      ) {
        this.createCoordinatorInvestigatorReaderUserManager();
      } else if (this.isFromSubjectDetail) {
        this.createSubjectUser();
      } else if (this.getValidateRole(Roles.Subject)) {
        this.createSubjectUserWithSiteInfo();
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  /**
   * Recorrer el array de roles y asignar si es activo o no
   * ej: si es ADMIN .. los otros roles pasaran a ser INACTIVOS
   * @param roleUser Rol de usuario a validar
   */
  activeOptionsRoles(rolUser: Roles) {
    //Validacion para usuario con rol coordinador o investigador
    if (rolUser === Roles.Investigator) {
      this.allActiveRoles.forEach((role: any) => {
        role.inactive =
          role.value !== rolUser && role.value !== Roles.Conciliator;
      });
    } else if (rolUser === Roles.Conciliator) {
      // si solo se selecciona el rol de gestor de usuario o conciliator se deja en el estado original el multiselect
      if (this.checkDependentRoles()) {
        this.role = [];
      }
    } else {
      this.allActiveRoles.forEach((role: any) => {
        role.inactive = role.value !== rolUser;
      });
    }
  }

  checkDependentRoles() {
    if (this.role?.length === 1) {
      return this.role.find((role) => role.value === Roles.Conciliator);
    }
    return false;
  }

  /**
   * metodo para validar la existencia del rol en la seleccion de los roles del usuarios
   * @param roleUser Rol del usario a validar
   * @returns booleno
   */
  getValidateRole(roleUser: Roles): boolean {
    return this.role?.find((role: any) => role.value === roleUser) ?? false;
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.user.email = this.user.email?.trim();
    this.user.login = this.user.login?.toLowerCase().trim();
    this.user.name = this.user.name?.trim();
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.user.notificationPreference === 'EMAIl' &&
      this.trialpalService.validateSpaces(this.user.email, 'email', form);
    this.trialpalService.validateSpaces(this.user.login, 'username', form);
    this.trialpalService.validateSpaces(this.user.name, 'name', form);
    this.maxProjectsReached = this.trialpalService.validateArrayLength(
      this.selectedProjects,
      'proyectos',
      form
    );
    this.maxSitesReached = this.trialpalService.validateArrayLength(
      this.selectedSites,
      'sites',
      form
    );
    this.maxSubjectsReached = this.trialpalService.validateArrayLength(
      this.selectedSubjects,
      'subjects',
      form
    );
  }
  private createSubjectUser() {
    this.trialpalService.showSpinner('user.entity', 'CREATE');
    this.subjectService
      .getSubject(this.idSubject)
      .then((response) => {
        logger.debug('getSubject response', response);
        const userProjects = [{ id: response.projectId }];
        const userSites = [{ id: response.siteId }];
        const userSubjects = [{ id: this.idSubject }];
        this.user.role = Roles.Subject;
        this.createUser(this.user, userProjects, userSites, userSubjects, []);
      })
      .catch((error) => {
        logger.error('createUser', error);
        if (error?.response?.data) {
          this.trialpalService.showServiceError(
            'user.actions.getUserSubjectsByNumber',
            {
              errors: [
                {
                  errorType: 'Lambda:Unhandled',
                  message: error?.response?.data,
                },
              ],
            }
          );
        } else {
          this.trialpalService.showServiceError(
            'user.actions.getUserSubjectsByNumber',
            error
          );
        }
        this.trialpalService.hideSpinner();
      });
  }

  private async createSubjectUserWithSiteInfo() {
    this.trialpalService.showSpinner('user.entity', 'CREATE');

    const subjects = await this.subjectService.getUserSubjectsByNumber(
      this.selectedSubjects,
      this.selectedSites
    );

    logger.info('getUserSubjectsByNumber input', this.selectedSubjects);
    logger.debug('getUserSubjectsByNumber response', subjects);

    const subjectDropOut: any = this.validateSubjectDropOut(subjects);
    if (subjectDropOut) {
      this.trialpalService.hideSpinner();
      return this.showSubjectDropOutMessage(subjectDropOut.subjectNumber);
    }

    if (
      subjects &&
      subjects.length > 0 &&
      subjects.length === this.selectedSubjects.length
    ) {
      const userProjects: TP2IdEntity[] = this.selectedProjects.map(
        (project: any) => {
          return { id: project };
        }
      );
      const userSites: TP2IdEntity[] = this.selectedSites.map((site: any) => {
        return { id: site };
      });
      const userSubjects: TP2IdEntity[] = subjects.map((subject: any) => {
        return { id: subject.id };
      });
      this.createUser(this.user, userProjects, userSites, userSubjects, []);
    } else {
      this.showSubjectNotFoundMessage();
      this.trialpalService.hideSpinner();
    }
  }

  showSubjectNotFoundMessage() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'user.messageErrorSubjectsSummay'
      ),
      detail: this.trialpalService.translateService.instant(
        'user.messageErrorSubjectsDetail'
      ),
    });
  }

  showSubjectDropOutMessage(subjectNumber: string) {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'user.messageDropOutSubjectsSummay'
      ),
      detail: this.trialpalService.translateService.instant(
        'user.messageDropOutSubjectsDetail',
        { subjectNumber: subjectNumber }
      ),
    });
  }

  validateSubjectDropOut(subjects: Subject[]) {
    const subjectStates: string[] = [
      SubjectState.DROPOUT,
      SubjectState.COMPLETED,
    ];
    return subjects.find((subject: Subject) => {
      return subjectStates.includes(subject.state);
    });
  }

  private createAdminUser() {
    this.trialpalService.showSpinner('user.entity', 'CREATE');
    const userRoles: TP2IdEntity[] = this.selectedRoles.map((role: any) => {
      return { id: role };
    });

    this.createUser(this.user, [], [], [], userRoles);
  }

  private createCoordinatorInvestigatorReaderUserManager() {
    const userProjects: TP2IdEntity[] = this.selectedProjects.map(
      (project: any) => {
        return { id: project };
      }
    );
    const userSites: TP2IdEntity[] = this.selectedSites.map((site: any) => {
      return { id: site };
    });

    const userRoles: TP2IdEntity[] = this.selectedRoles.map((role: any) => {
      return { id: role };
    });

    this.trialpalService.showSpinner('user.entity', 'CREATE');
    this.createUser(this.user, userProjects, userSites, [], userRoles);
  }

  onCancelar(): void {
    this.userForm.reset();
    this.trialpalService.ref.close(null);
  }

  async onRolSelectedEvent(form: any): Promise<void> {
    logger.debug('rolSelected - onChange', this.role);
    this.updateRoleList();

    const userRole = this.role;
    if (this.count > 0) {
      form.resetForm();
    }
    this.role = userRole;

    if (
      (this.getValidateRole(Roles.Investigator) ||
        this.getValidateRole(Roles.Reader) ||
        this.getValidateRole(Roles.Subject)) &&
      this.idProject
    ) {
      this.sites = [];
      this.userSites = [];
      this.selectedSites = [];
      this.selectedProjects = [this.idProject];
      await this.getSite([this.idProject]);
    }

    if (
      this.getValidateRole(Roles.Admin) ||
      !this.role ||
      this.role.length === 0
    ) {
      this.assignProject = false;
      this.assignSubjects = false;
      this.assignRoles = false;
      this.selectedRoles = [];
    } else if (
      this.getValidateRole(Roles.Reader) ||
      this.getValidateRole(Roles.Investigator)
    ) {
      this.assignProject = true;
      this.assignSubjects = false;
      this.assignRoles = true;
    } else if (this.getValidateRole(Roles.Subject)) {
      this.assignProject = true;
      this.assignSubjects = true;
      this.assignRoles = false;
      this.selectedRoles = [];
    }

    this.getAllowedRolesList();

    this.showToggleAll = !this.getValidateRole(Roles.Subject);
    this.count++;
  }

  private updateRoleList() {
    //Validación por roles
    Object.values(Roles).forEach((rol) => {
      if (this.getValidateRole(rol)) {
        this.activeOptionsRoles(rol);
      }
    });

    // activar todas las opciones menos el  conciliator en caso de no exitir alguna seleccionada
    if (this.role.length === 0) {
      this.allActiveRoles.forEach((role: any) => {
        role.inactive = role.value === Roles.Conciliator;
      });
    }

    const setRoleByUser: string[] = [];
    this.role.forEach((role: any) => {
      setRoleByUser.push(role.value);
    });
    this.user.role = setRoleByUser.join();
  }

  getAttribute(attributes: [any], attName: any): string {
    let att = null;
    const a = attributes.find((x) => x.Name === attName);
    if (a) {
      att = a.Value;
    }
    return att;
  }
  selectedProjectsEvent(_event?: any) {
    if (
      this.getValidateRole(Roles.Subject) &&
      this.selectedProjects.length > 1
    ) {
      this.showViaService();
      this.selectedProjects.splice(1, this.selectedProjects.length);
    }

    this.getAllowedSitesList();
    this.getAllowedRolesList();
    this.checkSelectedProjectsMFAConfig(this.selectedProjects);
  }

  getAllowedSitesList() {
    this.sites = [];
    this.sites = this.userSites.filter(
      (site) => this.selectedProjects.indexOf(site.projectId) > -1
    );

    this.groupSites = this.selectedProjects
      .map((selectedProject) => {
        const items = this.userSites.filter(
          (site) => site.projectId == selectedProject
        );
        const project = this.projects.find((x) => x.value === selectedProject);

        return items.length > 0
          ? {
              label: project.label,
              value: project.value,
              items: items,
            }
          : null;
      })
      .filter(Boolean);

    this.selectedSites = this.sites
      ?.filter((site) => this.selectedSites?.indexOf(site.value) > -1)
      ?.map((y) => y.value);
  }

  getAllowedRolesList() {
    this.roles = [];
    this.roles = this.userRoles.filter(
      (role) =>
        role.projects.filter((x: any) => this.selectedProjects.indexOf(x) > -1)
          .length > 0
    );

    this.roles = this.roles.concat(
      this.userRoles.filter((role: any) => !role.isForProjects)
    );

    if (this.getValidateRole(Roles.Reader)) {
      this.exludeRolesForReader();
    }

    this.selectedRoles = this.roles
      ?.filter((role) => this.selectedRoles?.indexOf(role.value) > -1)
      .map((y) => y.value);
  }

  /**
   * Funcion para excluir roles que no aplican para tipo de usuario Lector.
   *
   * Esta función filtra los roles que tienen permisos que no están permitidos para el rol de Lector..
   * Los permisos excluidos se obtienen de `userPermissionsService` mediante el método `excludedReaderPermissions`..
   *
   * @returns {void} - The function does not return any value.
   */
  exludeRolesForReader() {
    const excludedPermission =
      this.userPermissionsService.excludedReaderPermissions();

    this.roles = this.roles.filter(
      (item: any) =>
        !excludedPermission.some((permission: any) =>
          item.permissions.includes(permission)
        )
    );
  }

  selectedSitesEvent(_event?: any) {
    if (this.getValidateRole(Roles.Subject) && this.selectedSites.length > 1) {
      this.showViaService();
      this.selectedSites.splice(1, this.selectedSites.length);
    }
  }
  showViaService() {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary:
        this.trialpalService.translateService.instant('general.attention'),
      detail:
        this.trialpalService.translateService.instant('apiError.TP2ERRU15'),
    });
  }
  createUser(
    user: any,
    userProjects: any,
    userSites: any,
    userSubjects: any,
    userRoles: any
  ): void {
    this.userService
      .createUser(user, userProjects, userSites, userSubjects, userRoles)
      .then((response2) => {
        logger.debug('createUser response', response2);
        if (response2) {
          const obj = JSON.parse(response2);
          this.trialpalService.showMutationSuccess('user.entity', 'CREATE');
          this.trialpalService.ref.close(obj.createdUser);
        }
      })
      .catch((error) => {
        logger.error('createUser error', error);
        const responseData = error?.response?.data;

        if (
          responseData &&
          this.trialpalService.isJSON(responseData) &&
          ['TP2ERRU03', 'TP2ERRU06', 'TP2ERRU05'].includes(
            JSON.parse(responseData).code
          ) &&
          user.role !== this.getValidateRole(Roles.Admin)
        ) {
          this.userService
            .listUserByRoleLoginEmail(user.role, user.login, user.email)
            .then((response: any) => {
              if (response && Array.isArray(response) && response?.length > 0) {
                this.addUserToProjectAlreadyExist(response[0], user);
              } else {
                this.showAttentionMessage(
                  'addEditSubjectComponent.messageAssignedUserExistsSubject.detail'
                );
              }
            })
            .catch((error2) => {
              logger.error('listUserByRoleLoginEmail error', error2);
              if (error2?.response?.data) {
                this.trialpalService.showServiceError(
                  'user.actions.createUser',
                  {
                    errors: [
                      {
                        errorType: 'Lambda:Unhandled',
                        message: error2?.response?.data,
                      },
                    ],
                  }
                );
              } else {
                this.trialpalService.showServiceError(
                  'user.actions.createUser',
                  error2
                );
              }
            });
        } else if (error?.response?.data) {
          this.trialpalService.showServiceError('user.actions.createUser', {
            errors: [
              {
                errorType: 'Lambda:Unhandled',
                message: error?.response?.data,
              },
            ],
          });
        } else {
          this.trialpalService.showServiceError(
            'user.actions.createUser',
            error
          );
        }
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }
  addUserToProjectAlreadyExist(userToUpdate: any, currentUser: any) {
    const user = {
      login: userToUpdate?.login,
      email: userToUpdate?.email ?? currentUser?.email,
      role: userToUpdate?.role,
      roles: userToUpdate?.roles,
      name: userToUpdate?.name,
      phoneNumber: userToUpdate?.phoneNumber ?? currentUser?.phoneNumber,
      state: userToUpdate?.state,
      notificationPreference: userToUpdate?.notificationPreference,
    };
    //Projects
    let userProjects = userToUpdate?.projects
      .split(',')
      .concat(this.selectedProjects);
    const associatedUserProjects = userProjects;
    if (userProjects?.length > 0) {
      userProjects = userProjects.filter((item: any, index: any) => {
        return userProjects.indexOf(item) === index;
      });
      userProjects = userProjects.map((x: string) => {
        return { id: x };
      });
    }
    //Sites
    let userSites = userToUpdate?.sites.split(',').concat(this.selectedSites);
    if (userSites?.length > 0) {
      userSites = userSites.filter((item: any, index: any) => {
        return userSites.indexOf(item) === index;
      });
      userSites = userSites.map((x: string) => {
        return { id: x };
      });
    }
    //roles
    let userRoles = userToUpdate?.roles.concat(this.selectedRoles);
    if (userRoles?.length > 0) {
      userRoles = userRoles.filter((item: any, index: any) => {
        return userRoles.indexOf(item) === index;
      });
      userRoles = userRoles.map((x: string) => {
        return { id: x };
      });
    }
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'user.messageErrorLoginAndEmailAlreadyExist'
      ),
      accept: () => {
        this.checkSelectedProjectsMFAConfig(associatedUserProjects ?? []);
        if (this.isProjectMFAActive && !user?.phoneNumber) {
          this.showAttentionMessage(
            'user.messageErrorPhoneNumberRequiredByMFAEnable'
          );
          return;
        }
        this.trialpalService.showSpinner('user.entity', 'UPDATE');
        this.userService
          .editUser(user, userProjects, userSites, [], userRoles)
          .then((response) => {
            logger.debug('editUser response', response);
            if (response) {
              this.trialpalService.showMutationSuccess('user.entity', 'UPDATE');
              this.trialpalService.ref.close(JSON.parse(response));
            }
          })
          .catch((error) => {
            logger.error('editUser error', error);
            if (error?.response?.data) {
              this.trialpalService.showServiceError('user.actions.editUser', {
                errors: [
                  {
                    errorType: 'Lambda:Unhandled',
                    message: error?.response?.data,
                  },
                ],
              });
            } else {
              this.trialpalService.showServiceError(
                'user.actions.editUser',
                error
              );
            }
          })
          .finally(() => {
            this.trialpalService.hideSpinner();
          });
      },
    });
  }

  checkSelectedProjectsMFAConfig(selectedProjects: Array<string>) {
    this.isProjectMFAActive = selectedProjects.some((projectId) => {
      const project = this.projects.find(
        (project) => project.value === projectId
      );
      return project?.isMFAActivated === true;
    });
  }

  showAttentionMessage(translationKey: string) {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary:
        this.trialpalService.translateService.instant('general.attention'),
      detail: this.trialpalService.translateService.instant(translationKey),
      life: 5000,
    });
  }
}
