import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';
import { ConsentedUser } from 'src/app/services/API.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TP2IdEntity } from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import {
  Roles,
  Tp2Card,
  Tp2CardContent,
  emailRegex,
} from 'src/app/shared/global.variables';
import { InformedConsentService } from '../../informed-consent/informed-consent.service';
import {
  InformedConsentInstance,
  UpdateInformedConsentInstanceInput,
} from '../../informed-consent/informed-consent.types';
import { ProjectService } from '../../project/project.service';
import {
  ConfVisitGroup,
  GetProjectQuery,
  ProjectState,
} from '../../project/project.types';
import { SitesService } from '../../sites/sites.service';
import { UserService } from '../../user/user.service';
import { TP2UserInput } from '../../user/user.types';
import { SubjectsService } from '../subjects.service';
import {
  ScheduledPhaseState,
  SubjectState,
  TP2SubjectInput,
} from '../subjects.types';

const logger = new Logger('tp2-logger-subjectAddEditPage');

@Component({
  selector: 'app-add-edit-subjects',
  templateUrl: './add-edit-subjects.component.html',
  styleUrls: ['./add-edit-subjects.component.scss'],
})
export class AddEditSubjectsComponent implements OnInit {
  subject: TP2SubjectInput = {
    subjectNumber: '',
    group: '',
    site_id: '',
    tag: '',
    scheduledPhases: [],
    currentScheduledPhase: {
      phase: '',
      date: '',
      state: '',
    },
  };

  scheduledPhases: any[] = [];
  subjectId = '';
  projectId = '';

  state = SubjectState.ENROLLED;
  newUser: TP2UserInput = {
    name: null,
    login: '',
    email: null,
    role: null,
    phoneNumber: null,
    notificationPreference: '',
  };
  alertTypes: string[] = [];
  oldState = '';
  oldGroup = '';
  oldSite = '';
  oldTag = '';
  project: GetProjectQuery = {
    __typename: 'Project',
    code: '',
    createdAt: '',
    groups: [],
    id: '',
    isEdiaryActivated: false,
    isICActivated: false,
    isReportsActivated: false,
    isChatActivated: false,
    name: '',
    phases: [],
    state: ProjectState.IN_DESIGN,
    updatedAt: '',
    isSubjectNumberCheckRequired: false,
    subjectNumberRegex: '',
    subjectNumberPlaceholder: '',
    checkSiteCodeInSubjectNumber: false,
    sponsor: '',
    _version: 1,
    _lastChangedAt: 1,
    _lastUser: '',
  };
  isModal = false;
  editMode = false;

  optionSites: any[] = [];
  optionGroups: any[] = [];
  optionVisits: any[] = [];
  optionScheduledPhaseState: any[] = [];
  optionStateSubject: any[] = [];

  expectedVersion = 0;

  users: any[] = [];
  usersBySite: any[] = [];
  usersFound: any[] = [];
  userSelected: any = {};

  selectedUsers = [];
  usersCards: Tp2Card[] = [];
  userId = '';
  isCreatingUser: boolean = false;
  booleanOptions: any[] = [];

  currentDateVisit: string = '';
  emailPattern: RegExp = emailRegex;

  @ViewChild('f', { static: true })
  subjectForm!: NgForm;
  listVisitGroup: (ConfVisitGroup | null)[] = [];
  isCreateSubjectFromInformedConsent: boolean = false;
  informedConsentInstanceId: string = '';
  informedConsentInstance!: InformedConsentInstance;
  displayDialog: boolean = false;
  confirmationStateChange: Subject<boolean> = new Subject<boolean>();
  confirmedFinal: boolean = false;
  subjectNumberRegEx: RegExp = new RegExp('');
  hasSubjectUpdatePermission: boolean = false; // Permiso para crear/editar sujeto
  CREATE_SUBJECT_TOUR: PageTourDataType = PageTourDataType.CREATE_SUBJECT_TOUR;
  constructor(
    public trialpalService: TrialpalService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private subjectsService: SubjectsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private authService: AuthService,
    private informedService: InformedConsentService,
    private sitesService: SitesService,
    private userPermissionsService: UserPermissionsService,
    private pageTourService: PageTourService
  ) {}

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('subject.entity', 'GET');
    this.initTranslation();
    this.handleConfigData();
    this.setPermissions();
    await Promise.all([
      this.setConfVisitGroup(),
      this.getProjectById(this.projectId),
      this.getSitesByProjectId(this.projectId),
      this.handleEditOrCreateMode(),
    ]);
    this.loadAlertTypes();
    this.setSiteUserRole();
    this.optionScheduledPhaseState = this.subjectsService.getVisitStates();
    this.optionStateSubject = this.subjectsService.getSubjectStates();
    this.setBooleanOptions();
    if (
      this.project?.isSubjectNumberCheckRequired &&
      this.project?.subjectNumberRegex &&
      !this.editMode
    ) {
      this.subjectNumberRegEx = new RegExp(this.project?.subjectNumberRegex);
    }
    this.optionStateSubject.forEach((stateOption) => {
      if (
        stateOption.value === SubjectState.COMPLETED &&
        !this.subject?.scheduledPhases?.some(
          (phase) => phase?.state === ScheduledPhaseState.COMPLETED
        )
      ) {
        stateOption.disabled = true;
      }
    });
    this.startTour();

    this.trialpalService.hideSpinner();
  }

  async setPermissions() {
    this.hasSubjectUpdatePermission =
      await this.userPermissionsService.hasPermission(
        [TP2Permission.SubjectUpdate],
        this.editMode
      );

    await this.userPermissionsService.hasPermission(
      [TP2Permission.SubjectCreate],
      !this.editMode
    );
  }

  //Comienza el tour del formulario
  startTour() {
    if (!this.editMode) {
      this.pageTourService.initiateTour(PageTourDataType.CREATE_SUBJECT_TOUR);
    }
  }

  loadAlertTypes(): void {
    this.userService.getAlertTypes().then((res) => {
      this.alertTypes = res;
    });
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  private async getSitesByProjectId(projectId: string): Promise<void> {
    try {
      const sitesResponse = await this.sitesService.fetchSitesByProject(
        projectId
      );
      this.optionSites = this.createOptionSites(sitesResponse);
    } catch (error) {
      logger.error('sitesByProject error', error);
    }
  }

  resetValuesForUser() {
    this.selectedUsers = [];
  }

  private createOptionSites(sites: any[]): any[] {
    logger.debug('filteredSites', sites);
    return sites
      .filter((site: any) => !site?._deleted)
      .map((filteredSites: any) => {
        return {
          label:
            (filteredSites.siteCode ? filteredSites.siteCode + ' - ' : '') +
            filteredSites.name,
          value: filteredSites.id,
          siteCode: filteredSites.siteCode,
        };
      });
  }

  private async getProjectById(projectId: string): Promise<void> {
    try {
      const projectDetails = await this.projectService.getProject(projectId);
      this.handleProjectDetails(projectDetails);
    } catch (error) {
      this.handleProjectError(error);
    }
  }

  private handleProjectDetails(projectDetails: any): void {
    logger.debug('getProject response', projectDetails);
    this.project = projectDetails;
    this.optionGroups = this.createOptionGroups();
    this.state = SubjectState.ENROLLED;
  }

  private createOptionGroups(): any[] {
    return this.project.groups.map((group) => {
      const label = this.trialpalService.dictionaryPipe.transform(group);
      return { label, value: group };
    });
  }

  private handleProjectError(error: any): void {
    logger.debug('getProject error', error);
    this.trialpalService.hideSpinner();
    this.trialpalService.showServiceError(
      'subject.headerModalCreateSubject',
      error
    );
  }

  private async setConfVisitGroup(): Promise<void> {
    //Obtiene todas las visitas asociadas a un grupo
    const visitGroup = await this.projectService.getConfVisitGroup(
      this.projectId
    );
    if (visitGroup) {
      this.listVisitGroup = visitGroup;
    }
  }

  private handleConfigData(): void {
    if (this.config.data) {
      this.extractConfigData();
    } else {
      this.handleRouteSnapshot();
    }
  }

  private handleRouteSnapshot(): void {
    this.isModal = false;
    this.editMode = this.route.snapshot.params.action !== 'create';

    if (!this.editMode) {
      this.initCreateSubjectFromInformedConsent();
    }

    this.projectId = this.route.snapshot.params.projectId;
  }

  private extractConfigData(): void {
    this.editMode = this.config.data.isEdit;
    this.isModal = this.config.data.isModal;
    this.projectId = this.config.data.projectId;
  }

  private initTranslation(): void {
    this.trialpalService.translateService
      .get('primeng')
      .subscribe((res) => this.trialpalService.config.setTranslation(res));
  }

  initCreateSubjectFromInformedConsent() {
    const siteId = this.route.snapshot.params?.siteId;
    this.subject.site_id = siteId;
    this.isCreateSubjectFromInformedConsent = false;
    const informedConsentInstanceId =
      this.route.snapshot.params?.informedInstanceId;

    //Valida si los datos por parametros se obtuvieron
    if (siteId && informedConsentInstanceId) {
      this.isCreateSubjectFromInformedConsent = true;
      this.informedConsentInstanceId = informedConsentInstanceId;
    }
  }

  setSiteUserRole() {
    if (this.authService.isInvestigator()) {
      const userSiteIds = this.authService.getUserSites();

      this.optionSites = this.optionSites.filter((site: any) =>
        userSiteIds.includes(site.value)
      );
    }
  }

  async handleEditOrCreateMode(): Promise<void> {
    if (this.editMode) {
      await this.routeObservable();
      return;
    }

    await this.getUsers();
  }

  async routeObservable() {
    return new Promise((resolve, reject) => {
      this.route.queryParamMap.subscribe((p: any) => {
        this.subjectId = p.params[0];
        this.subjectsService
          .getSubject(this.subjectId)
          .then((subject: any) => {
            logger.debug('subjectsService subject', subject);

            this.updateSubjectProperties(subject);

            this.trialpalService.cleanQueryResponse(subject);
            delete subject.project;
            delete subject.user;
            delete subject.site;

            for (const sp of subject.scheduledPhases || []) {
              this.trialpalService.cleanQueryResponse(sp);
            }

            Object.assign(this.subject, subject);
            resolve(subject);
          })
          .catch((error) => {
            reject(new Error(error));
            logger.error('subjectsService error', error);
          })
          .finally(() => this.trialpalService.hideSpinner());
      });
    });
  }

  private updateSubjectProperties(subject: any): void {
    this.subject.site_id = subject.siteId;
    this.expectedVersion = subject._version;
    this.state = subject.state;
    this.oldState = subject.state;
    this.oldGroup = subject.group;
    this.oldSite = subject.siteId;
    this.oldTag = subject.tag;
  }

  async getUsers(): Promise<void> {
    try {
      this.trialpalService.showSpinner('subject.entity', 'GET');
      if (!this.isCreateSubjectFromInformedConsent) {
        await this.getUsersFromProjectId();
      } else {
        await this.getUsersFromInformedConsentId();
      }
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error('getUsers error', error);
    }
  }

  async getUsersFromProjectId(): Promise<void> {
    let users = await this.userService.getSubjectUserByProject(this.projectId);
    if (users) {
      users = users.filter((user: any) => user.role.includes(Roles.Subject));
      this.buildUsers(users);
    }
  }

  async getUsersFromInformedConsentId(): Promise<void> {
    //Obtiene todos los consentidos asociados al participante
    const consentedUsers: ConsentedUser[] =
      await this.informedService.getConsentedUserByInformedConsenteInstanceId(
        this.informedConsentInstanceId
      );

    this.informedConsentInstance = consentedUsers[0]
      .informedConsentInstance as InformedConsentInstance;

    //Obtiene la lista de usuarios asociados al participante
    const users = consentedUsers.map(
      (consentedUser: ConsentedUser) => consentedUser.user
    );

    this.buildUsers(users);
    this.setUserBySite();
  }

  buildUsers(users: any[]) {
    this.users = users.map((user: any) => {
      return {
        value: user.id,
        name: user.name,
        email: user.email,
        login: user.login,
        phoneNumber: user.phoneNumber,
        label: `${user.login} - ${user.email}`,
        siteId: user.sites,
        notificationPreference: user.notificationPreference,
      };
    });
  }

  validateForm(form: any): boolean {
    if (
      !form.valid ||
      (this.selectedUsers.length === 0 && !this.isValidNewUser())
    ) {
      this.trialpalService.showInvalidFormError();
      this.trialpalService.hideSpinner();
      return false;
    }

    return true;
  }

  isValidNewUser(): boolean {
    const { name, login, email, phoneNumber, notificationPreference } =
      this.newUser;

    if (
      !name ||
      !login ||
      (notificationPreference === 'EMAIL' && !email) ||
      (notificationPreference === 'SMS' && !phoneNumber)
    ) {
      return false;
    }

    if (
      notificationPreference === 'SMS' &&
      !this.trialpalService.phoneNumberService.isValidPhoneNumber(phoneNumber)
        .isValid
    ) {
      return false;
    }

    return true;
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.subject.tag = this.subject.tag?.trim();
    this.subject.subjectNumber = this.subject.subjectNumber?.trim();
    if (this.isCreatingUser) {
      this.newUser.login = this.newUser.login?.toLowerCase().trim();
      this.newUser.name = this.newUser.name?.trim();
      this.newUser.email = this.newUser.email?.trim();
    }
  }

  checkSubjectNumberRegEx(form: NgForm) {
    form.controls.code.setErrors(null);
    if (this.subject.subjectNumber.trim() === '') {
      form.controls.code.setErrors({ required: true });
      return;
    }
    if (this.project.isSubjectNumberCheckRequired && !this.editMode) {
      if (!this.subjectNumberRegEx.test(this.subject.subjectNumber)) {
        form.controls.code.setErrors({ pattern: true });
        this.trialpalService.hideSpinner();
      }
    }
    this.checkSiteCodeInSubjectNumber(form);
  }

  checkSiteCodeInSubjectNumber(form: any) {
    logger.debug('checkSiteCodeInSubjectNumber', form);
    if (
      this.project.checkSiteCodeInSubjectNumber &&
      this.subject.site_id &&
      !this.editMode
    ) {
      const siteCode = this.optionSites.find(
        (site) => site.value === this.subject.site_id
      ).siteCode;
      if (!siteCode) {
        return;
      }
      if (!this.subject.subjectNumber.includes(siteCode)) {
        form.controls.code.setErrors({ siteCode: true });
        this.trialpalService.hideSpinner();
      }
    }
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    if (!this.project.isSubjectNumberCheckRequired) {
      this.trialpalService.validatEspecialCharacters(
        this.subject.subjectNumber,
        'code',
        form
      );
    } else {
      this.checkSubjectNumberRegEx(form);
    }

    if (this.isCreatingUser) {
      this.trialpalService.validatEspecialCharacters(
        this.newUser.login,
        'username',
        form
      );
    }
  }
  validateUserAssociate(): boolean {
    if (!this.isCreatingUser) {
      if (
        !this.userSelected.hasOwnProperty('username') ||
        !this.userSelected.hasOwnProperty('email') ||
        !this.userSelected.hasOwnProperty('name')
      ) {
        this.trialpalService.showServiceError(
          'subject.headerModalCreateSubject',
          'error'
        );
        this.trialpalService.hideSpinner();
        return false;
      }
    } else if (
      !this.newUser.login ||
      !this.newUser.email ||
      !this.newUser.name
    ) {
      this.trialpalService.showServiceError(
        'subject.headerModalCreateSubject',
        'error'
      );
      this.trialpalService.hideSpinner();
      return false;
    }
    const uExist = this.users.filter(
      (x) =>
        x.login === this.newUser.login &&
        x.email === this.newUser.email &&
        x.name === this.newUser.name
    );
    if (uExist.length > 0) {
      this.isCreatingUser = false;
    }
    const uDuplicate = this.users.filter(
      (x) =>
        (x.login === this.newUser.login &&
          x.email !== this.newUser.email &&
          this.isCreatingUser) ||
        (x.login !== this.newUser.login &&
          x.email === this.newUser.email &&
          this.isCreatingUser)
    );
    if (uDuplicate.length > 0) {
      this.trialpalService.showServiceError(
        'subject.headerModalCreateSubject',
        'error'
      );
      this.trialpalService.hideSpinner();
      return false;
    }
    return true;
  }

  async onSubmit(form: any): Promise<void> {
    this.removeSpaces();
    logger.debug('onSubmit', this.subject);
    this.confirmErrors(form);

    if (this.editMode) {
      this.editSubject();
    } else {
      if (this.validateForm(form)) {
        await this.createEnrollSubject();
      }
    }
  }

  async createEnrollSubject() {
    try {
      this.trialpalService.showSpinner('subject.entity', 'CREATE');

      const projects: TP2IdEntity = { id: this.projectId };
      const sites: TP2IdEntity = { id: this.subject.site_id };

      this.prepareSubjectAndScheduledPhases();
      this.newUser.role =
        this.isCreateSubjectFromInformedConsent && this.userId
          ? Roles.Consented
          : Roles.Subject;

      const users: TP2UserInput[] = this.selectedUsers.map((user: any) => {
        const findUser = this.users.find((us: any) => us.value === user);
        return {
          name: findUser.name,
          login: findUser.login,
          email: findUser.email,
          role: this.isCreateSubjectFromInformedConsent
            ? Roles.Consented
            : Roles.Subject,
          phoneNumber: findUser.phoneNumber,
          notificationPreference: findUser.notificationPreference,
        };
      });

      //Enrolla el sujeto

      const response = await this.subjectsService.subjectEnroll(
        this.subject,
        this.isCreatingUser ? [this.newUser] : users,
        projects,
        sites
      );

      if (response) {
        const responseData = JSON.parse(response ?? '{}');
        await this.updateConsentedUserWithSubjectInformation(
          responseData?.subject
        );
        this.handleSuccessResponse(responseData);
      }

      this.trialpalService.hideSpinner();
    } catch (error) {
      this.handleCreateEnrollSubjectError(error);
    }
  }

  private prepareSubjectAndScheduledPhases(): void {
    const date = new Date(this.currentDateVisit);
    this.subject.currentScheduledPhase.date =
      this.subjectsService.setDateWithTimezone(date.toISOString());
    this.modifyCurrentVisit();
    this.subject.scheduledPhases = this.scheduledPhases;

    this.subject.scheduledPhases = this.scheduledPhases;
    this.subject.subjectNumber = this.subject.subjectNumber?.trim();
    this.subject.tag = this.subject.tag?.trim();
  }

  private handleCreateEnrollSubjectError(error: any): void {
    logger.error('error', error);
    this.trialpalService.hideSpinner();
    if (error?.response?.data) {
      this.trialpalService.showServiceError(
        'subject.headerModalCreateSubject',
        {
          errors: [
            {
              errorType: 'Lambda:Unhandled',
              message: error?.response?.data,
            },
          ],
        }
      );
    } else {
      this.trialpalService.showServiceError(
        'user.actions.getUserSubjectsByNumber',
        error
      );
    }
  }

  private handleSuccessResponse(responseData: any): void {
    logger.debug('createdResponse', responseData);
    this.trialpalService.showMutationSuccess('subject.entity', 'CREATE');

    if (this.isModal) {
      this.ref.close(this.subject);
    } else {
      const subjectId = responseData.subject.id;
      const routeParams = subjectId
        ? ['/subjects/', subjectId, 'detail']
        : ['subjects', this.projectId];

      this.router.navigate(routeParams);
    }
  }

  async updateConsentedUserWithSubjectInformation(subject: any): Promise<void> {
    if (this.isCreateSubjectFromInformedConsent) {
      await this.updateInformedConsentInstances(subject?.id);
    }
  }

  //Se encarga de asociar el sujeto creado con el consentimiento informado - Consentimiento
  async updateInformedConsentInstances(subjectId: string) {
    const consentedIdentificator =
      this.informedConsentInstance.consentedIdentificator;
    const informedConsentInstances =
      await this.informedService.getInformedConsentByConsentedIdentificator(
        consentedIdentificator
      );
    const promises = informedConsentInstances.items.map(
      (informedConsent: any) => {
        const input: UpdateInformedConsentInstanceInput = {
          id: informedConsent.id,
          subjectId: subjectId,
          _version: informedConsent._version,
          _lastUser: this.authService.getUsername(),
          _changeReason: 'Create Subject',
        };
        return this.informedService.updateInfomedConsentInstance(
          informedConsent,
          input
        );
      }
    );

    await Promise.all(promises);
  }

  onCancelar(): void {
    if (this.isModal) {
      this.ref.close(null);
    } else {
      this.subjectForm.reset();
      this.router.navigate(['subjects', this.projectId]);
    }
  }

  async editSubject() {
    if (
      this.oldSite !== this.subject.site_id ||
      this.oldState !== this.state ||
      this.oldGroup !== this.subject.group ||
      this.oldTag !== this.subject.tag
    ) {
      this.updateReasonSubject();
    } else {
      this.updateSubject();
    }
  }

  async updateReasonSubject() {
    if (
      this.oldState === SubjectState.ENROLLED &&
      (this.state === SubjectState.COMPLETED ||
        this.state === SubjectState.DROPOUT)
    ) {
      this.displayDialog = true;
      // Wait for the confirmation response
      await this.waitForConfirmationResponse();
    }
    const ref = this.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'subject.actions.updateSubject'
        ) +
        ': ' +
        this.subject.subjectNumber,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: async (_changeReason: string) => {
        if (_changeReason) {
          logger.debug('data', _changeReason);
          await this.updateSubject(_changeReason);
        }
      },
    });
  }

  async updateSubject(_changeReason: string = '') {
    const projectCode = this.project.code;
    const subjectNumber = this.subject.subjectNumber;
    this.trialpalService.showSpinner('subject.entity', 'UPDATE');
    if (
      this.oldSite !== this.subject.site_id ||
      this.oldTag !== this.subject.tag
    ) {
      await this.isChangeSiteOrTag(_changeReason);
    }
    if (this.oldState !== this.state) {
      await this.isChangeState(subjectNumber, projectCode, _changeReason);
    }
    if (this.oldGroup !== this.subject.group) {
      await this.isChangeGroup(subjectNumber, projectCode, _changeReason);
    }
    this.trialpalService.showMutationSuccess('subject.entity', 'UPDATE');
    if (this.isModal) {
      this.ref.close(this.subject);
    } else {
      this.router.navigate(['/subjects/' + this.subjectId + '/detail']);
    }
    this.trialpalService.hideSpinner();
  }

  async isChangeSiteOrTag(_changeReason: any) {
    return new Promise<void>((resolve, _reject) => {
      const updateSubject: any = {
        siteId: this.subject.site_id,
        tag: this.subject.tag?.trim(),
        _changeReason: _changeReason,
      };
      logger.debug('updateSubject input', this.subject);
      this.subjectsService
        .updateSubject(
          updateSubject,
          this.subjectId || '',
          this.expectedVersion
        )
        .then((response) => {
          logger.debug('updateSubject response', response);
          resolve();
        })
        .catch((error) => {
          logger.error('updateSubject error', error);
          this.trialpalService.hideSpinner();
        });
    });
  }

  async isChangeGroup(
    subjectNumber: string,
    projectCode: string,
    _changeReason: string
  ) {
    return new Promise<void>((resolve, _reject) => {
      this.subjectsService
        .changeGroup(
          subjectNumber,
          projectCode,
          this.subject.group,
          _changeReason
        )
        .then((subject) => {
          logger.debug('updateSubject response', subject);
          resolve();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.trialpalService.showServiceError(
              'user.actions.getUserSubjectsByNumber',
              {
                errors: [
                  {
                    errorType: 'Lambda:Unhandled',
                    message: error?.response?.data,
                  },
                ],
              }
            );
          } else {
            this.trialpalService.showServiceError(
              'user.actions.getUserSubjectsByNumber',
              error
            );
          }
          this.trialpalService.hideSpinner();
        });
    });
  }

  async isChangeState(
    subjectNumber: string,
    projectCode: string,
    _changeReason: string
  ) {
    return new Promise<void>((resolve, _reject) => {
      this.subjectsService
        .changeState(subjectNumber, projectCode, this.state, _changeReason)
        .then((subject) => {
          logger.debug('updateSubject response', subject);
          resolve();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.trialpalService.showServiceError(
              'user.actions.getUserSubjectsByNumber',
              {
                errors: [
                  {
                    errorType: 'Lambda:Unhandled',
                    message: error?.response?.data,
                  },
                ],
              }
            );
          } else {
            this.trialpalService.showServiceError(
              'user.actions.getUserSubjectsByNumber',
              error
            );
          }
          this.trialpalService.hideSpinner();
        });
    });
  }

  modifyCurrentVisit(): void {
    const v = this.scheduledPhases.findIndex(
      (x: any) => x.phase === this.subject.currentScheduledPhase.phase
    );
    if (
      this.subject.currentScheduledPhase.date &&
      this.subject.currentScheduledPhase.phase
    ) {
      this.scheduledPhases[v].date = this.subject.currentScheduledPhase.date;
    }
    if (
      this.subject.currentScheduledPhase.state &&
      this.subject.currentScheduledPhase.phase
    ) {
      this.scheduledPhases[v].state = this.subject.currentScheduledPhase.state;
    }
  }

  searchUser(event: any): void {
    const word = event.query.toString();
    this.usersFound = this.users.filter(
      (u: any) =>
        u.login.toUpperCase().includes(word.toUpperCase()) ||
        u.name.toUpperCase().includes(word.toUpperCase()) ||
        u.email.toUpperCase().includes(word.toUpperCase())
    );
  }

  completeInput(s: any): void {
    if (this.selectedUsers.length > 2) {
      this.selectedUsers.splice(2, this.selectedUsers.length - 1);
    }
    this.buildTPCardData(this.selectedUsers);
    if (this.userId) {
      s = this.users.find((x: any) => x.value === this.userId);
      this.userSelected = s;
      this.newUser.name = s.name;
      this.newUser.login = s.login;
      this.newUser.email = s.email;
      this.newUser.phoneNumber = s.phoneNumber;
      this.newUser.notificationPreference = s.notificationPreference;
    }
  }

  setUserBySite(form?: any) {
    this.usersBySite = this.users.filter(
      (user: any) => user.siteId === this.subject.site_id
    );
    this.selectedUsers = [];
    this.buildTPCardData(this.selectedUsers);
    this.checkSubjectNumberRegEx(form);
  }

  viewFormAddUser(): void {
    this.newUser.name = null;
    this.newUser.login = '';
    this.newUser.email = null;
    this.newUser.phoneNumber = null;
    this.userId = '';
    this.selectedUsers = [];
    this.userSelected = {};
  }

  changeGroup() {
    this.getPhases(this.subject.group);
  }

  //Obtiene las visitas correspondientes al grupo seleccionado
  getPhases(groupName: string) {
    this.optionVisits = [];
    this.scheduledPhases = [];
    //Filtra las visitas que pertenezcan al grupo seleccionado y ademas sean visible
    this.listVisitGroup
      .filter((visitGroup: any) => visitGroup.group === groupName)
      .forEach((visitGroup: any) => {
        const label = this.trialpalService.dictionaryPipe.transform(
          visitGroup.visit
        );
        //Agrega en el array las visitas que sean visibles y pertenezcan al grupo seleccionado
        this.optionVisits.push({ label, value: visitGroup.visit });
        this.scheduledPhases.push({
          phase: visitGroup.visit,
          date: '2999-12-12T23:59:59.683Z',
          state: ScheduledPhaseState.PLANNED,
        });
      });

    return this.optionVisits;
  }

  buildTPCardData(users: any) {
    const cards: Tp2Card[] = [];

    users?.forEach((user: any, index: number) => {
      const findUser = this.users.find((us: any) => us.value === user);
      const content: Tp2CardContent[] = [
        {
          title: `${this.trialpalService.translateService.instant(
            'subject.labelName'
          )}:`,
          description: findUser.name,
          icon: 'pi pi-clock',
        },
        {
          title: `${this.trialpalService.translateService.instant(
            'subject.labelEmail'
          )}:`,
          description: findUser.email,
          icon: 'pi pi-clock',
        },
        {
          title: `${this.trialpalService.translateService.instant(
            'subject.labelPhoneNumber'
          )}:`,
          description: findUser.phoneNumber,
          icon: 'pi pi-clock',
        },
      ];

      cards.push({
        id: user,
        textHeader: findUser.login,
        buttonItems: [],
        content: content,
        isButtonsEnabled: true,
        isMenuEnabled: false,
        cardBackground: '#fff',
        menu: [],
      });
    });

    this.usersCards = cards;
  }
  async waitForConfirmationResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let subscription: Subscription;
      // Subscribe to the confirmation response
      this.confirmedFinal = false;
      subscription = this.confirmationStateChange.subscribe(
        (confirmed: boolean) => {
          if (confirmed && !this.confirmedFinal) {
            this.confirmedFinal = true;
            this.displayDialog = false;
            resolve(confirmed);
            subscription?.unsubscribe(); // Clean up the subscription
          }
        }
      );
    });
  }
  selectedFinalState(): string {
    if (this.state === SubjectState.COMPLETED) {
      return this.trialpalService.translateService.instant(
        'subject.enums.subjectStates.COMPLETED'
      );
    } else {
      return this.trialpalService.translateService.instant(
        'subject.enums.subjectStates.DROPOUT'
      );
    }
  }

  getSubjecNumberTooltip(): string {
    const baseMessage = this.trialpalService.translateService.instant(
      'subject.subjectNumberTooltipBase'
    );
    const {
      isSubjectNumberCheckRequired,
      checkSiteCodeInSubjectNumber,
      subjectNumberPlaceholder,
    } = this.project;

    if (
      !isSubjectNumberCheckRequired &&
      !checkSiteCodeInSubjectNumber &&
      !subjectNumberPlaceholder
    ) {
      return baseMessage;
    }

    if (
      isSubjectNumberCheckRequired &&
      !checkSiteCodeInSubjectNumber &&
      subjectNumberPlaceholder
    ) {
      return (
        baseMessage +
        '\n' +
        this.formatTooltip(
          'subject.subjectPatternNumberTooltip',
          subjectNumberPlaceholder
        )
      );
    }

    if (!isSubjectNumberCheckRequired && checkSiteCodeInSubjectNumber) {
      return (
        baseMessage +
        '\n' +
        this.trialpalService.translateService.instant(
          'subject.subjectSiteNumberTooltip'
        )
      );
    }

    if (
      isSubjectNumberCheckRequired &&
      checkSiteCodeInSubjectNumber &&
      subjectNumberPlaceholder
    ) {
      return (
        baseMessage +
        '\n' +
        this.formatTooltip(
          'subject.subjectNumberTooltip',
          subjectNumberPlaceholder
        )
      );
    }

    return baseMessage;
  }

  private formatTooltip(translationKey: string, placeholder: string): string {
    const tooltip =
      this.trialpalService.translateService.instant(translationKey);
    return tooltip.replace('XXX', placeholder);
  }
  getStatesTooltip(): string {
    if (!this.subject.currentScheduledPhase.state) {
      return '';
    }
    return this.trialpalService.translateService.instant(
      'subject.statesTooltip.' + this.subject.currentScheduledPhase.state
    );
  }
  getTooltipSubjectState(): string {
    if (!this.state) {
      return '';
    }
    return this.trialpalService.translateService.instant(
      'subject.tooltipSubjectState.' + this.state
    );
  }
}
