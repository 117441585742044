<form
  name="form"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="formSymptom"
  *ngIf="configuration"
>
  <div class="p-d-block p-mt-0 p-mb-4">
    <label class="p-d-block p-mt-0" for="">
      <span class="p-d-block p-mb-2" style="color: var(--primary-color)">
        {{ 'symptominstance.occurrency' | translate }} ({{ confSymptomName }})
        <span class="inputRequired"> * </span>:
      </span>
      <p-selectButton
        id="cmb_ocurrences"
        name="occurrences"
        [options]="booleanOptions"
        formControlName="occurrency"
        (onChange)="setIsOccurrencyChange()"
        optionLabel="label"
        [ngClass]="{
          disabled: !isAllowedToChangeOccurrency
        }"
        optionValue="value"
      ></p-selectButton>
    </label>
    <small
      class="form-error p-col-12"
      *ngIf="
        form.controls['occurrency']?.invalid &&
        form.controls['occurrency']?.touched
      "
      >{{ 'general.required' | translate }}</small
    >
    <div
      class="p-grid p-mb-3 p-ai-center full-width"
      *ngIf="isOccurrencyChanged || currentOriginalSyptom?.occurrencyComment"
    >
      <label id="lbl_observation" name="lbl_observation" for="occurrencyComment"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span>
      </label>
      <textarea
        id="occurrencyComment"
        formControlName="occurrencyComment"
        class="p-col"
        pInputTextarea
        type="text"
        [autoResize]="true"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        (input)="setCommentChange()"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['occurrencyComment']?.invalid &&
          form.controls['occurrencyComment']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </div>
  <div class="scroll-conciliation p-pr-2">
    <div *ngIf="form.controls['occurrency'].value">
      <div class="p-ai-center">
        <div
          class="p-mb-3"
          *ngIf="
            configuration?.intensityType === NUMERIC_VALUE &&
            shouldShowIntensity()
          "
        >
          <label
            id="lbl_labelVerifiedSize"
            name="labelVerifiedSize"
            for="inputVerifiedSize"
          >
            {{ configuration?.intensitySizeLabel || '' | dictionary }}
            <span class="inputRequired"> * </span>
          </label>
          <div class="number-size">
            <p-inputNumber
              name="size"
              mode="decimal"
              [minFractionDigits]="configuration?.decimalPlaces ?? 0"
              [maxFractionDigits]="configuration?.decimalPlaces ?? 0"
              [min]="getMinAllowedNumericIntensity() ?? 0"
              [max]="getMaxAllowedNumericIntensity() ?? 1000"
              inputId="size"
              formControlName="size"
              (onInput)="onInputIntensity($event.value)"
              (onBlur)="onBlurIntensity($event.value)"
              [readonly]="
                form.controls['rememberNoValuesTaken']?.value ||
                !isAllowedToChangeIntensity
              "
            ></p-inputNumber>
          </div>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['size']?.invalid && form.controls['size']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>

        <div
          class="p-mb-3 intensitySymptom"
          *ngIf="
            configuration?.intensityType === MULTIPLE_CHOICE &&
            shouldShowIntensity()
          "
        >
          <label
            id="lbl_labelVerifiedIntensity"
            name="labelVerifiedIntensiry"
            for="inputVerifiedIntensity"
          >
            {{ 'symptominstance.intensity' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-dropdown
            id="inputVerifiedIntensity"
            name="drop_size"
            optionLabel="label"
            optionValue="intensity"
            class="input p-col-12 p-p-0"
            appendTo="body"
            [options]="intensityScaleOptions"
            formControlName="intensity"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
            emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
            (onChange)="setIntensityChange()"
            [readonly]="
              form.controls['rememberNoValuesTaken']?.value ||
              !isAllowedToChangeIntensity
            "
          >
            <ng-template let-intensity pTemplate="item">
              <div>
                <div
                  pTooltip="{{ intensity.label }}"
                  tooltipPosition="left"
                  style="white-space: pre-line"
                >
                  {{ intensity.label }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['intensity']?.invalid &&
              form.controls['intensity']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>

        <div
          class="p-mb-3"
          *ngIf="
            configuration?.intensityType === RANGE && shouldShowIntensity()
          "
        >
          <label
            id="lbl_labelVerifiedSize"
            name="labelVerifiedSize"
            for="symptomSize"
          >
            {{ configuration?.intensitySizeLabel || '' | dictionary }}
            <span class="inputRequired"> * </span>
          </label>
          <div class="p-d-flex p-pl-0 p-col">
            <p-dropdown
              id="symptomSize"
              name="drop_indicardor1"
              class="input p-p-0 p-mr-1"
              [ngClass]="{
                'p-col-6': configuration?.decimalPlaces,
                'p-col-12': !configuration?.decimalPlaces
              }"
              appendTo="body"
              [options]="intRange"
              formControlName="intSize"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              (onChange)="setIntensityChange()"
              [readonly]="
                form.controls['rememberNoValuesTaken']?.value ||
                !isAllowedToChangeIntensity
              "
            >
            </p-dropdown>

            <p-dropdown
              *ngIf="configuration?.decimalPlaces"
              id="symptomSize2"
              name="drop_indicardor2"
              class="input p-col-6 p-p-0"
              appendTo="body"
              [options]="decimalRange"
              formControlName="decimalSize"
              placeholder="{{ 'general.selectPlaceholder' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
              (onChange)="setIntensityChange()"
              [readonly]="
                form.controls['rememberNoValuesTaken']?.value ||
                !isAllowedToChangeIntensity
              "
            >
            </p-dropdown>
          </div>
          <small
            class="form-error p-col-12"
            *ngIf="
              (form.controls['decimalSize']?.invalid ||
                form.controls['intSize']?.invalid) &&
              (form.controls['decimalSize']?.touched ||
                form.controls['intSize']?.touched)
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>

        <div
          class="p-grid p-mb-3 p-ai-center full-width"
          *ngIf="isIntensityChanged || currentOriginalSyptom?.sizeComment"
        >
          <label
            id="lbl_observation"
            name="lbl_observation"
            for="verifiedSizeComment"
            >{{ 'conciliation.titleObservation' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <textarea
            id="verifiedSizeComment"
            formControlName="sizeComment"
            class="p-col"
            pInputTextarea
            type="text"
            [autoResize]="true"
            placeholder="{{
              'conciliation.placeholderObservation' | translate
            }}"
            (input)="setCommentChange()"
          ></textarea>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['sizeComment'].invalid &&
              form.controls['sizeComment']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>

      <div
        class="p-ai-center border-bottom p-mb-3"
        *ngIf="
          configuration.isIntensityQuestionsRequired && shouldShowIntensity()
        "
        formGroupName="answers"
      >
        <label class="p-mb-3" for=""
          >{{ 'symptom.intensityQuestions' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <div
          *ngFor="
            let answer of form.controls['intensityQuestionAnswers']?.value;
            let i = index
          "
        >
          <div class="p-pl-0 p-col p-mb-3">
            <label
              [id]="'lbl_verfiedAnswer' + i"
              name="lbl_verfiedAnswer"
              [for]="'verfiedAnswer' + i"
              class="p-pl-0 p-pr-3"
            >
              {{ answer?.question?.question | dictionary }}
              <span class="inputRequired"> * </span>:
            </label>
            <p-selectButton
              [id]="'verifiedAnswer' + i"
              class="inputSwitch"
              [formControlName]="'answer' + i"
              [(ngModel)]="answer.answer"
              (onChange)="setIntensityQuestionsAnswersChange()"
              [options]="booleanOptions"
              optionLabel="label"
              optionValue="value"
              [ngClass]="{
                disabled: !isAllowedToChangeIntensity || answer?.readOnly
              }"
            ></p-selectButton>
          </div>
          <small
            class="form-error p-col-12"
            *ngIf="isControlInvalid('answer' + i)"
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="
          isIntensityQuestionsChanged ||
          currentOriginalSyptom?.intensityQuestionAnswersComment
        "
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="verifiedIntensityQuestionAnswersComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedIntensityQuestionAnswersComment"
          formControlName="intensityQuestionAnswersComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          (input)="setCommentChange()"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['intensityQuestionAnswersComment']?.invalid &&
            form.controls['intensityQuestionAnswersComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <div
        class="p-ai-center"
        *ngIf="
          configuration?.isStartDateRequired &&
          isAllowedToChangeRememberStartDate()
        "
      >
        <div class="p-pb-3">
          <label
            id="lbl_verifiedRememberStartDate"
            name="lbl_veriifiedRememberStartDate"
            for="verifiedRememberStartDate"
          >
            {{ 'symptominstance.startDateQuestion' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-selectButton
            id="verifiedRememberStartDate"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="rememberStartDate"
            (onChange)="setRememberStartDateChange()"
            [ngClass]="{
              disabled: !isAllowedToChangeDate
            }"
          ></p-selectButton>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['rememberStartDate']?.invalid &&
              form.controls['rememberStartDate']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>

      <div
        class="p-ai-center p-mb-3"
        *ngIf="form.controls['rememberStartDate'].value"
      >
        <div class="p-mb-3">
          <label
            id="lbl_verifiedStartDate"
            name="lbl_verifiedStartDate"
            for="verifiedStartDate"
          >
            {{ 'symptominstance.startDate' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-calendar
            formControlName="startDate"
            id="verifiedStartDate"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [showIcon]="true"
            [maxDate]="maxDate"
            appendTo="body"
            placeholder="{{
              'addEditSubjectComponent.PlaceHolderFormatDate' | translate
            }}"
            dateFormat="dd-mm-yy"
            [ngClass]="{
              disabled: !isAllowedToChangeDate
            }"
            (onSelect)="setStartDateChange()"
          ></p-calendar>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['startDate']?.invalid &&
              form.controls['startDate']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>

      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="
          isRememberStartDateChanged ||
          isStartDateChanged ||
          currentOriginalSyptom?.startDateComment
        "
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          class="p-col-12"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedStartDateComment"
          formControlName="startDateComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          (input)="setCommentChange()"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['startDateComment']?.invalid &&
            form.controls['startDateComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <div
        class="p-ai-center"
        *ngIf="
          this.configuration.isFinishDateRequired &&
          isAllowedToChangeRememberFinishDate()
        "
      >
        <div class="p-pb-3">
          <label
            id="lbl_verifiedRememberFinishDate"
            name="lbl_veriifiedRememberFinishDate"
            for="verifiedRememberFinishDate"
          >
            {{ 'symptominstance.endDateQuestion' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-selectButton
            id="verifiedRememberFinishDate"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="rememberFinishDate"
            [ngClass]="{
              disabled: !isAllowedToChangeDate
            }"
            (onChange)="setRememberFinishDateChange()"
          ></p-selectButton>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['rememberFinishDate']?.invalid &&
              form.controls['rememberFinishDate']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>

      <div
        class="p-ai-center"
        *ngIf="this.form.controls['rememberFinishDate'].value"
      >
        <div class="p-mb-3">
          <label
            id="lbl_verifiedFinishDate"
            name="lbl_verifiedFinishDate"
            for="verifiedFinishDate"
          >
            {{ 'symptominstance.endDate' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-calendar
            formControlName="finishDate"
            id="verifiedFinishDate"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [showIcon]="true"
            [maxDate]="maxDate"
            appendTo="body"
            placeholder="{{
              'addEditSubjectComponent.PlaceHolderFormatDate' | translate
            }}"
            dateFormat="dd-mm-yy"
            [ngClass]="{
              disabled: !isAllowedToChangeDate
            }"
            (onSelect)="setFinishDateChange()"
          ></p-calendar>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['finishDate']?.invalid &&
              form.controls['finishDate']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div
        class="p-grid p-ai-center full-width p-mb-3"
        *ngIf="
          isRememberFinishDateChanged ||
          isFinishDateChanged ||
          currentOriginalSyptom?.finishDateComment
        "
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedFinishDateComment"
          formControlName="finishDateComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          (input)="setCommentChange()"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['finishDateComment']?.invalid &&
            form.controls['finishDateComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <div class="p-ai-center" *ngIf="configuration?.showNoValuesTaken">
        <div class="p-mb-3 full-width">
          <div class="p-d-flex p-pl-0 p-col">
            <label
              id="lbl_verfiedRememberNoValuesTaken"
              name="lbl_verfiedRememberNoValuesTaken"
              for="verfiedRememberNoValuesTaken"
            >
              {{ 'symptom.showNoValuesTaken' | translate }}
              <span class="inputRequired"> * </span>
            </label>
            <p-inputSwitch
              id="verfiedRememberNoValuesTaken"
              class="inputSwitch"
              formControlName="rememberNoValuesTaken"
              (onChange)="setRememberNoValuesTakenChange()"
              [readonly]="!isAllowedToChangeIntensity"
            ></p-inputSwitch>
          </div>
          <small
            class="form-error p-col-12"
            *ngIf="
              form.controls['rememberNoValuesTaken']?.invalid &&
              form.controls['rememberNoValuesTaken']?.touched
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="
          isRememberNoValuesTakenChanged ||
          currentOriginalSyptom?.rememberNoValuesTakenComment
        "
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="verifiedRememberNoValuesTakenComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedRememberNoValuesTakenComment"
          formControlName="rememberNoValuesTakenComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          (input)="setCommentChange()"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['rememberNoValuesTakenComment']?.invalid &&
            form.controls['rememberNoValuesTakenComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      *ngIf="(isFormChanged || isCommentsChanged) && currentSymptom.isEdition"
    >
      <label
        id="lbl_changeReason"
        name="lbl_changeReason"
        for="txt_changeReason"
        >{{ 'audit.label' | translate }}</label
      >
      <change-reason
        name="symptom_reason"
        [label]="'conciliation.changeReason' | translate"
        formControlName="changeReason"
      ></change-reason>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['changeReason']?.invalid &&
          form.controls['changeReason']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </div>
  <app-form-buttons
    (previousData)="previousSymptom()"
    [isNextDisabled]="form.invalid"
  ></app-form-buttons>
</form>
