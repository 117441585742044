<div class="container-tp2">
  <p-accordion>
    <ng-container *ngFor="let site of sites">
      <p-accordionTab
        header="{{ 'chat.usersFrom' | translate }} {{ site.name }}"
        [selected]="true"
        *ngIf="site.chats.length > 0"
      >
        <div *ngIf="site.chats" class="p-grid p-fluid">
          <div class="p-col-12 p-md-4 p-d-flex p-flex-column">
            <p-listbox
              [filter]="true"
              [options]="site.chats"
              [(ngModel)]="site.selectedChat"
              optionDisabled="false"
              [listStyle]="{ 'max-height': '520px' }"
            >
              <ng-template let-chat pTemplate="item">
                <div
                  id="{{ chat.value.id }}"
                  class="chat-item"
                  style="width: 100%; border-bottom: 1px solid #a0a0a0"
                >
                  <div>{{ chat.value.login }}</div>
                  <div class="p-d-flex p-jc-end">
                    <ng-container *ngFor="let subject of chat.value.subjects">
                      <p-badge
                        [value]="subject.subjectNumber"
                        severity="info"
                        class="p-mr-1 p-mb-1"
                      ></p-badge>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="footer">
                <div style="text-align: center">
                  <small
                    >{{ site.chats.length }}
                    {{ 'user.entityPlural' | translate }}</small
                  >
                </div>
              </ng-template>
            </p-listbox>
          </div>
          <div
            class="p-col-12 p-md-8 p-d-flex p-flex-column"
            style="height: 600px"
          >
            <div class="messages">
              <div
                *ngIf="site.selectedChat?.id"
                #chatScroll
                class="messages-scroller"
                [scrollTop]="chatScroll.scrollHeight"
              >
                <ng-container *ngFor="let day of site.selectedChat?.days">
                  <div class="day-title">
                    {{ day.chatDate | date: 'dd-MMM-yyyy' }}
                  </div>
                  <ng-container *ngFor="let message of day.messages">
                    <div
                      [ngClass]="
                        message.sender === 'SUBJECT'
                          ? 'message-subject'
                          : 'message-site'
                      "
                    >
                      {{ message.message }}
                      <br />
                      <small style="float: right"
                        >{{ message.senderAlias }} -
                        {{ message.createdAt | date: 'HH:mm' }}</small
                      >
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="p-mt-auto">
              <div class="p-inputgroup p-mt-1">
                <input
                  id="{{ site.id }}message"
                  name="message"
                  [disabled]="!site.selectedChat?.id"
                  [(ngModel)]="site.message"
                  type="text"
                  pInputText
                  placeholder="{{ 'chat.labelSendMessage' | translate }}"
                  (keyup.enter)="sendMessage(site)"
                />
                <button
                  type="button"
                  id="btnSendMessage{{ site.id }}"
                  [disabled]="!site.selectedChat?.id"
                  pButton
                  pRipple
                  icon="pi pi-send"
                  styleClass="p-button-warn"
                  (click)="sendMessage(site)"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
</div>
