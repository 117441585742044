import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ReportExecutionService,
  ReportPagination,
} from '../../services/report-executation.service';

@Component({
  selector: 'app-pagination-button',
  templateUrl: './pagination-button.component.html',
  styleUrls: ['./pagination-button.component.scss'],
})
export class PaginationButtonComponent {
  @Input() nextDisabled: boolean = false;
  @Input() previousDisabled: boolean = false;

  @Output() next: EventEmitter<boolean> = new EventEmitter();
  @Output() previous: EventEmitter<boolean> = new EventEmitter();
  SUMMARY_PAGE: ReportPagination = ReportPagination.REPORT_SUCCESS;
  constructor(
    private ref: DynamicDialogRef,
    public reportExecutionService: ReportExecutionService
  ) {}

  close() {
    this.ref.close();
  }
}
