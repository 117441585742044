import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class ErrorTranslationService {
  traductorErrorMessage: any = {};
  traductorGeneral: any = {};
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.translateService.get('errorMessage').subscribe((data) => {
      this.traductorErrorMessage = data;
    });
    this.translateService.get('general').subscribe((data) => {
      this.traductorGeneral = data;
    });
  }
  notifyAWSError(error: any): void {
    let errorMessage = JSON.stringify(error);
    if (error.code) {
      errorMessage =
        this.traductorErrorMessage.anErrorOccurred +
        error.code +
        this.traductorErrorMessage.contact;
      if (error.code === 'UsernameExistsException') {
        errorMessage = this.traductorErrorMessage.existingUser;
      } else if (error.code === 'InvalidParameterException') {
        if (error.message === 'Invalid phone number format.') {
          errorMessage = this.traductorErrorMessage.invalidPhone;
        } else {
          errorMessage = this.traductorErrorMessage.invalidFormat;
        }
      } else if (
        error.code === 'CodeMismatchException' ||
        error.code === 'ExpiredCodeException'
      ) {
        errorMessage = this.traductorErrorMessage.invalidCode;
      } else if (error.code === 'UserNotFoundException') {
        errorMessage = this.traductorErrorMessage.UserDoesNotExist;
      } else if (error.code === 'LimitExceededException') {
        errorMessage = this.traductorErrorMessage.LimitExceeded;
      } else if (error.code === 'NotAuthorizedException') {
        errorMessage = this.errorMessageByNotAuthorizedException(error.message);
      } else if (error.code === 'UserLambdaValidationException') {
        errorMessage = this.traductorErrorMessage.attemptLimit;
      }
      // UserNotFoundException;
    }
    if (errorMessage !== '{}') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }
  errorMessageByNotAuthorizedException(errorMessage: any) {
    if (
      errorMessage === 'Incorrect username or password.' ||
      errorMessage === 'Password attempts exceeded'
    ) {
      return this.traductorErrorMessage.IncorrectUsername;
    } else if (
      errorMessage === 'User cannot be confirmed. Current status is CONFIRMED'
    ) {
      return this.traductorErrorMessage.tryToRecover;
    } else {
      return this.traductorErrorMessage.unauthorizedUser;
    }
  }
}
