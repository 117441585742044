import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditQueryComponent } from 'src/app/shared/components/audit-query/audit-query.component';
import { DetailProjectComponent } from './detail-project/detail-project.component';
import { AuthGuard } from 'src/app/services/auth-guard.service';

const routes: Routes = [
  {
    path: ':id/detail',
    component: DetailProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id/econsent-audit',
    component: AuditQueryComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
