import { Component, Input, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { OnDemandAlertType } from '../../../report.types';

@Component({
  selector: 'report-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
})
export class RemindersComponent implements OnChanges {
  @Input() isReadOnly: boolean = false;
  @Input() readMode: boolean = false;
  @Input() confReport: any = {};
  @Input() form: NgForm = {} as NgForm;
  @Input() onDemandAlertTypes: any = [];
  @Input() weekDays: any = [];
  @Input() availableDays: any = [];
  @Input() isDaysOfWeekRequired: boolean = false;
  weekDaysOptions: any[] = [];
  isAlertTypeDisabled: boolean = false;
  isAlertWeeklyDaysDisabled: boolean = false;
  constructor(private trialpalService: TrialpalService) {}

  ngOnChanges(): void {
    this.isAlertTypeDisabled = false;
    this.isAlertWeeklyDaysDisabled = false;
    this.weekDaysOptions = this.weekDays;
    this.confReport.alertType = null;

    if (this.isDaysOfWeekRequired) {
      this.configureWeeklyAlert();
    }
  }

  /**
   * Configura la alerta semanal, deshabilita la selección de tipo de alerta y ajusta las opciones de días de la semana disponibles.
   * Si solo hay una opción de día de la semana disponible, la selecciona por defecto.
   */
  configureWeeklyAlert() {
    this.confReport.alertType = OnDemandAlertType.WEEKLY;
    this.isAlertTypeDisabled = true;
    this.weekDaysOptions = this.weekDays.filter(({ value }: any) => {
      return this.availableDays.includes(value);
    });
    if (this.weekDaysOptions.length === 1) {
      this.selectDefaultWeekDay();
    }

    if (this.weekDaysOptions.length > 0) {
      this.filterAvailableAlertWeeklyDays();
    }
  }

  selectDefaultWeekDay() {
    const weekDay = this.weekDaysOptions[0].value;
    this.confReport.alertWeeklyDays = [weekDay];
    this.isAlertWeeklyDaysDisabled = true;
  }

  /**
   * Filters the alert weekly days to only include available weekdays.
   *
   * This function updates the `alertWeeklyDays` property of `confReport` by filtering
   * out any days that are not included in the available `weekDaysOptions`.
   */
  filterAvailableAlertWeeklyDays(): void {
    const weekDaysOptions = this.weekDaysOptions.reduce(
      (prev, current) => [...prev, current.value],
      []
    );
    this.confReport.alertWeeklyDays = this.confReport.alertWeeklyDays.filter(
      (weekDay: number) => weekDaysOptions.includes(weekDay)
    );
  }

  onChangeAlertType(): void {
    this.confReport.alertWeeklyDays = [];
    this.confReport.alertInBetweenDaysFrequency = null;
  }

  valueTime() {
    let starTime: any =
      moment(this.confReport.alertStartTime, 'HH:mm:ss') || '';
    let endTime = moment(this.confReport.alertEndTime, 'HH:mm:ss') || '';

    if (endTime < starTime) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant('general.error'),
        detail: this.trialpalService.translateService.instant(
          'report.invalidDateRange'
        ),
      });
      this.confReport.alertEndTime = undefined;
      this.confReport.alertStartTime = undefined;
    }
  }
}
