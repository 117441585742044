import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index">
      <p class="tp-label">
        <strong *ngIf="to.label1 || to.label2"
          >{{ to.label1 ? to.label1 : '' }} {{ i + 1 }}
          {{ to.label2 ? to.label2 : '' }}</strong
        >
      </p>
      <formly-field [field]="field"></formly-field>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {}
