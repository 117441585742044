import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InformedConsentService } from '../../../informed-consent.service';

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SiteFormComponent implements OnChanges {
  @ViewChild('formTemplate') form!: NgForm;
  @Input() site: any = {};
  @Input() siteIndex: number = 0;
  @Input() sites: any[] = [];
  @Input() sitesOptions: any[] = [];
  @Input() submitted: boolean = false;
  @Input() showDeleteButton: boolean = false;
  @Output() deleteSiteEmmiter: EventEmitter<any> = new EventEmitter();
  showDisplayDeleteComponent: boolean = false;
  siteOptions: any[] = [];
  constructor(
    public informedConsentService: InformedConsentService,
    private trialpalService: TrialpalService
  ) {}

  ngOnChanges() {
    this.setSiteOptions();
  }

  setSiteOptions() {
    this.siteOptions = this.sitesOptions.map((site: any) => {
      return {
        label: site.name,
        value: site.id,
      };
    });
  }

  validateSiteSelected() {
    const currentSite = this.form.controls['site'].value;
    let isSitePrviousSelected = false;
    this.sites?.forEach((site: any, index: number) => {
      if (site.site === currentSite && this.siteIndex !== index) {
        isSitePrviousSelected = true;
      }
    });

    if (isSitePrviousSelected) {
      this.form.controls['site'].setValue(null);
      this.showSiteDuplicateError();
    }
  }

  showSiteDuplicateError() {
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary:
        this.trialpalService.translateService.instant('general.attention'),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.siteDuplicate'
      ),
    });
  }
}
