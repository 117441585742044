<form *ngIf="medicationService.isNewMedicationInstance">
  <label
    id="lbl_labelSelectedDay"
    name="labelSelectedDay"
    for="inputSelectedDay"
  >
    {{ 'medicationInstance.newMedicationInstance' | translate }}
  </label>
  <p-dropdown
    id="inputSelectedDay"
    name="drop_dayInstances"
    optionLabel="label"
    optionValue="value"
    class="input p-col-12 p-p-0 p-mr-1"
    appendTo="body"
    [options]="dayInstances"
    [(ngModel)]="dayInstanceSelected"
    (onChange)="newMedicationInstance()"
    placeholder="{{ 'general.selectPlaceholder' | translate }}"
    emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
    emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
  >
  </p-dropdown>
</form>
<form
  name="form"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="!medicationService.isNewMedicationInstance"
>
  <div class="scroll-conciliation p-pr-2" *ngIf="!isDelete">
    <div class="p-ai-center">
      <div class="p-mb-3">
        <label
          id="lbl_labelVerifiedName"
          name="labelVerifiedName"
          for="inputVerifiedName"
        >
          {{ 'medicationinstance.medicineName' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <input
          id="inputVerifiedName"
          type="text"
          formControlName="name"
          class="full-width"
          [required]="true"
          pInputText
        />
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['name']?.invalid && form.controls['name']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-grid p-mb-3 p-ai-center full-width" *ngIf="isChangeName()">
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedNameComment"
          formControlName="nameComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['nameComment']?.invalid &&
            form.controls['nameComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div class="p-ai-center" *ngIf="isDoseEnabled() && !shouldHideDosis()">
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedDose"
          name="labelVerifiedDose"
          for="inputVerifiedDose"
        >
          {{ 'medicationinstance.dose' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <input
          id="inputVerifiedDose"
          type="text"
          formControlName="dose"
          class="full-width"
          pInputText
          [ngClass]="{
            disabled: shouldDisableDosis(),
          }"
        />
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['dose']?.invalid && form.controls['dose']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-grid p-mb-3 p-ai-center full-width" *ngIf="isChangeDose()">
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedDoseComment"
          formControlName="doseComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['doseComment']?.invalid &&
            form.controls['doseComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center"
      *ngIf="isGivenToEnabled() && !shouldHideTreatment()"
    >
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedGivenTo"
          name="labelVerifiedGivenTo"
          for="inputVerifiedGivenTo"
        >
          {{ 'medicationinstance.givenTo' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-multiSelect
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          class="full-width"
          id="inputVerifiedGivenTo"
          formControlName="givenTo"
          [options]="getGivenToFiltered()"
          optionLabel="label"
          optionValue="value"
          [ngClass]="{
            disabled: shouldDisableTreatment(),
          }"
        >
        </p-multiSelect>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['givenTo']?.invalid &&
            form.controls['givenTo']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isChangeGivenTo()"
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedGivenToComment"
          formControlName="givenToComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['givenToComment']?.invalid &&
            form.controls['giveToComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div class="p-ai-center" *ngIf="isSymptomsEnabled() && !shouldHideReason()">
      <div class="p-mb-3">
        <label
          id="lbl_labelVerifiedSymptomInstances"
          name="labelVerifiedSymptomInstances"
          for="inputVerifiedSymptomInstances"
        >
          {{ 'medicationinstance.reason' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-multiSelect
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          class="full-width"
          id="inputVerifiedSymptomInstances"
          formControlName="symptomsInstances"
          [options]="getSymtomsFiltered()"
          optionLabel="symptomLabelShortened"
          optionValue="id"
          [maxSelectedLabels]="1"
          selectedItemsLabel="{1}
          {{ 'conciliation.labelSelectedSubjects' | translate }}"
          [ngClass]="{
            disabled: shouldDisableReason(),
          }"
        >
        </p-multiSelect>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['symptomsInstances']?.invalid &&
            form.controls['symptomsInstances']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isChangeSymptomsInstances()"
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedSymptomsInstancesComment"
          formControlName="symptomsInstancesComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['symptomsInstancesComment']?.invalid &&
            form.controls['symptomsInstancesComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div class="p-ai-center" *ngIf="isReasonEnabled() && !shouldHideReason()">
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedReason"
          name="labelVerifiedReason"
          for="inputVerifiedReason"
        >
          {{ 'medicationinstance.reason' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <input
          id="inputverifiedReason"
          type="text"
          formControlName="reason"
          class="full-width"
          pInputText
          [ngClass]="{
            disabled: shouldDisableReason(),
          }"
        />
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['reason']?.invalid && form.controls['reason']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isChangeReason()"
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedReasonComment"
          formControlName="reasonComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['reasonComment']?.invalid &&
            form.controls['reasonComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center"
      *ngIf="isAdminitrationRouteEnabled() && !shouldHideRoute()"
    >
      <div class="p-mb-3">
        <label
          id="lbl_labelVerifiedAdministrationRoute"
          name="labelVerifiedAdministrationRoute"
          for="inputVerifiedAdministrationRoute"
        >
          {{ 'medicationinstance.administracionRoute' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <input
          id="inputVerifiedAdministrationRoute"
          type="text"
          formControlName="administrationRoute"
          [ngClass]="{
            disabled: shouldDisableRoute(),
          }"
          pInputText
        />
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['administrationRoute']?.invalid &&
            form.controls['administrationRoute']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isChangeAdministrationRoute()"
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedAdministrationRouteComment"
          formControlName="administrationRouteComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['administrationRouteComment']?.invalid &&
            form.controls['administrationRouteComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div class="p-ai-center" *ngIf="isFrequencyEnabled()">
      <div class="p-mb-3 full-width">
        <label
          id="lbl_labelVerifiedFrequency"
          name="labelVerifiedFrequency"
          for="inputVerifiedFrequency"
        >
          {{ 'medicationinstance.frequency' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <input
          id="inputverifiedFrequency"
          type="text"
          formControlName="frequency"
          class="full-width"
          pInputText
        />
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['frequency']?.invalid &&
            form.controls['frequency']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-mb-3 p-ai-center full-width"
        *ngIf="isChangeFrequency()"
      >
        <label
          id="lbl_observation"
          name="lbl_observation"
          for="txt_observationTaken"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <textarea
          id="verifiedFrequencyComment"
          formControlName="frequencyComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['frequencyComment']?.invalid &&
            form.controls['frequencyComment']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center"
      *ngIf="isShowRememberStartDateEnabled() && !shouldHideDateFields()"
    >
      <div class="p-mb-3 full-width">
        <label
          id="lbl_verfiedRememberStartDate"
          name="lbl_verfiedRememberStartDate"
          for="verfiedRememberStartDate"
        >
          {{ 'symptominstance.startDateQuestion' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-selectButton
          id="verifiedRememberStartDate"
          formControlName="rememberStartDate"
          [options]="booleanOptions"
          optionLabel="label"
          optionValue="value"
          [ngClass]="{
            disabled: shouldDisableDateFields(),
          }"
          [required]="true"
        ></p-selectButton>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['rememberStartDate']?.invalid &&
            form.controls['rememberStartDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center p-mb-3"
      *ngIf="isStartDateEnabled() && !shouldHideDateFields()"
    >
      <div class="p-mb-3">
        <label
          id="lbl_verifiedStartDate"
          name="lbl_verifiedStartDate"
          for="verifiedStartDate"
        >
          {{ 'symptominstance.startDate' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-calendar
          formControlName="startDate"
          id="verifiedStartDate"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [showIcon]="true"
          [maxDate]="maxDate"
          appendTo="body"
          [ngClass]="{
            disabled: shouldDisableDateFields(),
          }"
          placeholder="{{
            'addEditSubjectComponent.PlaceHolderFormatDate' | translate
          }}"
          dateFormat="dd-mm-yy"
        ></p-calendar>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['startDate']?.invalid &&
            form.controls['startDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-grid p-mb-3 p-ai-center full-width"
      *ngIf="
        configuration?.showRememberStartDate &&
        isChangeRememberStartDate() &&
        !shouldHideDateFields()
      "
    >
      <label
        id="lbl_observation"
        name="lbl_observation"
        for="txt_observationTaken"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span>
      </label>
      <textarea
        id="verifiedStartDateComment"
        formControlName="startDateComment"
        class="p-col"
        pInputTextarea
        type="text"
        [autoResize]="true"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['startDateComment']?.invalid &&
          form.controls['startDateComment']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>

    <div
      class="p-ai-center"
      *ngIf="isShowRememberFinishEnabled() && !shouldHideDateFields()"
    >
      <div class="p-pb-3">
        <label
          id="lbl_verifiedRememberFinishDate"
          name="lbl_veriifiedRememberFinishDate"
          for="verifiedRememberFinishDate"
        >
          {{ 'symptominstance.endDateQuestion' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-selectButton
          id="verifiedRememberFinishDate"
          formControlName="rememberFinishDate"
          [options]="booleanOptions"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          [ngClass]="{
            disabled: shouldDisableDateFields(),
          }"
        ></p-selectButton>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['rememberFinishDate']?.invalid &&
            form.controls['rememberFinishDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>

    <div
      class="p-ai-center"
      *ngIf="isFinishDateEnabled() && !shouldHideDateFields()"
    >
      <div class="p-mb-3">
        <label
          id="lbl_verifiedFinishDate"
          name="lbl_verifiedFinishDate"
          for="verifiedFinishDate"
        >
          {{ 'symptominstance.endDate' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-calendar
          formControlName="finishDate"
          id="verifiedFinishDate"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [showIcon]="true"
          [maxDate]="maxDate"
          appendTo="body"
          placeholder="{{
            'addEditSubjectComponent.PlaceHolderFormatDate' | translate
          }}"
          dateFormat="dd-mm-yy"
          [ngClass]="{
            disabled: shouldDisableDateFields(),
          }"
        ></p-calendar>
        <small
          class="form-error p-col-12"
          *ngIf="
            form.controls['finishDate'].invalid &&
            form.controls['finishDate']?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      class="p-grid p-ai-center full-width"
      *ngIf="
        configuration?.showRememberFinishDate &&
        isChangeRememberFinishDate() &&
        !shouldHideDateFields()
      "
    >
      <label
        id="lbl_observation"
        name="lbl_observation"
        for="txt_observationTaken"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span>
      </label>
      <textarea
        id="verifiedFinishDateComment"
        formControlName="finishDateComment"
        class="p-col"
        pInputTextarea
        type="text"
        [autoResize]="true"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['finishDateComment']?.invalid &&
          form.controls['finishDateComment']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div *ngIf="isChangeForm && currentMedication.isEdition">
      <label
        id="lbl_changeReason"
        name="lbl_changeReason"
        for="txt_changeReason"
        >{{ 'audit.label' | translate }}</label
      >
      <change-reason
        name="other_symptom_reason"
        [label]="'conciliation.changeReason' | translate"
        formControlName="_changeReason"
        [required]="true"
      ></change-reason>
      <small
        class="form-error p-col-12"
        *ngIf="
          form.controls['_changeReason']?.invalid &&
          form.controls['_changeReason']?.touched
        "
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </div>
  <h4 class="notResult" *ngIf="isDelete">
    {{ 'general.recordDeleted' | translate }}
  </h4>
  <app-form-buttons
    (previousData)="previousMedication()"
    [isNextDisabled]="form.invalid"
  ></app-form-buttons>
</form>
