import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Analytics, Logger } from 'aws-amplify';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TP2IdEntity } from 'src/app/services/trialpal.types';
import { UserPermissionsService } from 'src/app/services/user-permissions-service';
import { ChangeReasonComponent } from 'src/app/shared/components/change-reason/change-reason.component';
import { Roles, emailRegex } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { RolesService } from '../../roles/roles.service';
import { SubjectsService } from '../../subjects/subjects.service';
import { UserService } from '../user.service';
import { TP2UserInput, UserByLoginQuery, UserState } from '../user.types';

const logger = new Logger('tp2-logger-userEditPage');
@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss'],
})
export class EditUsersComponent implements OnInit {
  @ViewChild('f', { static: true }) userForm: any;
  user: TP2UserInput = {
    login: '',
    name: '',
    email: null,
    role: '',
    phoneNumber: '',
    state: UserState.ENABLED,
    notificationPreference: '',
  };
  //Variables de cambio
  userChange: TP2UserInput = {
    login: '',
    name: '',
    email: null,
    role: '',
    phoneNumber: '',
    state: UserState.ENABLED,
  };
  currentProjectsChange: any[] = [];
  currentSitesChange: any[] = [];
  currentSubjectsChange: any[] = [];
  currentRolesChange: any[] = [];

  idProject = null;
  allGroups: SelectItem[] = [];
  projects: any[] = [];

  assignProject: boolean = false;
  assignSubjects: boolean = false;
  assignRoles: boolean = false;

  userSites: any[] = [];
  sites: any[] = [];
  subjectsByProject: any[] = [];
  subjects: any[] = [];
  currentProjects: string[] = [];
  currentSites: string[] = [];
  currentSubjects: string[] = [];

  selectedProjects: any[] = [];
  selectedSites: string[] = [];
  selectedSubjects: string[] = [];

  maxProjectsReached = false;
  maxSitesReached = false;
  maxSubjectsReached = false;

  idSubject = '';
  isFromSubjectDetail = false;
  blockDropdownProject = false;

  allActiveRoles: any[] = [];
  role: any = [];
  emailPatern: RegExp = emailRegex;

  roles: any[] = [];
  selectedRoles: any[] = [];
  userRoles: any[] = [];
  currentRoles: any[] = [];

  alertTypes: string[] = [];
  groupSites: any[] = [];
  isProjectMFAActive: boolean = false;
  constructor(
    public trialpalService: TrialpalService,
    private authService: AuthService,
    private projectService: ProjectService,
    private userService: UserService,
    private auth: AuthService,
    private subjectService: SubjectsService,
    public config: DynamicDialogConfig,
    private rolesService: RolesService,
    private userPermissionsService: UserPermissionsService
  ) {
    this.allActiveRoles = this.userService.getAllActivatedRoles();
  }

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('user.entity', 'GET');
    this.allGroups = this.userService.getGroups();
    this.idProject = this.config.data.currentProject;
    this.idSubject = this.config.data.currentSubject;
    this.isFromSubjectDetail = this.idSubject !== '';
    const login = this.config.data.usuario.login;
    this.loadAlertTypes();
    this.authService.userAuthenticated.subscribe((res) => {
      if (!res) {
        this.trialpalService.ref.close(null);
      }
    });
    if (this.idProject) {
      this.blockDropdownProject = true;
    }
    this.userService
      .userByLogin(login)
      .then(async (users: UserByLoginQuery) => {
        if (users.items && users.items.length > 0) {
          const currentUser = users.items.filter((x: any) => !x._deleted)[0];
          logger.debug('userByLogin response', users);
          this.roles = await this.getRoles();
          this.setUserInformation(currentUser);
          this.isProjectTransaction();
        }
      })
      .catch((error) => {
        logger.error('userByLogin error', error);
        this.trialpalService.hideSpinner();
        this.trialpalService.ref.close();
        this.trialpalService.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.detail'
          ),
        });
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  loadAlertTypes(): void {
    this.userService.getAlertTypes().then((res) => {
      this.alertTypes = res;
    });
  }

  async getRoles(): Promise<any> {
    this.trialpalService.showSpinner('roles.entityPlural', 'GET');
    this.userRoles = await this.rolesService.getRolesForCreateEditUser(
      this.idProject
    );

    this.userRoles = this.userRoles
      .filter((role: any) => role.isEnabled)
      .map((role: any) => {
        return {
          label: role.name,
          value: role.id,
          projects: role.isForProjects ? role.projects : [],
          isForProjects: role.isForProjects,
          permissions: role.permissions,
        };
      });

    this.trialpalService.hideSpinner();
    return this.idProject
      ? this.userRoles
      : this.userRoles.filter((role: any) => !role.isForProjects);
  }

  isProjectTransaction(): any {
    if (this.getValidateRole(Roles.Admin)) {
      this.assignProject = false;
      this.assignSubjects = false;
      this.assignRoles = false;
      return this.trialpalService.hideSpinner();
    }

    if (
      this.getValidateRole(Roles.Reader) ||
      this.getValidateRole(Roles.Investigator)
    ) {
      this.assignProject = true;
      this.assignSubjects = false;
      this.assignRoles = true;
    } else if (this.getValidateRole(Roles.Subject)) {
      this.assignProject = true;
      this.assignSubjects = true;
      this.assignRoles = false;
    }

    if (this.isFromSubjectDetail) {
      return this.trialpalService.hideSpinner();
    }
    this.getSubjects();
    if (this.idProject) {
      this.getProjects();
    } else {
      this.getAllProject();
    }
  }
  getProjects() {
    const projects: any = [];
    const projectIds: string[] = [];
    let count = 1;
    for (const project of this.currentProjects) {
      this.userService
        .getProject(project)
        .then((response) => {
          projectIds.push(response.id);
          projects.push({
            isMFAActivated: response.isMFAActivated,
            value: response.id,
            label: response.name,
          });
          if (count === this.currentProjects.length) {
            this.projects = projects;
            if (this.idProject) {
              this.selectedProjects = projectIds;
            }
            this.getSites();
          }
        })
        .finally(() => {
          count++;
        });
    }
  }

  async getAllProject() {
    const currentProjects = await this.projectService.getProjects();
    if (currentProjects) {
      logger.debug('listUserProjects response', currentProjects);
      this.projects = currentProjects.map((project: any) => {
        return {
          isMFAActivated: project.isMFAActivated,
          value: project.id,
          label: project.name,
        };
      });
      await this.getSites();
    }
  }

  async getSites() {
    this.trialpalService.showSpinner('user.entity', 'GET');
    const currentSites = await this.projectService.getSites();
    if (currentSites) {
      logger.debug('listUserSites response', currentSites);
      if (this.auth.isAdmin()) {
        for (const site of currentSites) {
          if (!site._deleted) {
            this.userSites.push({
              value: site.id,
              label: site.name,
              projectId: site.projectId,
            });
          }
        }
      } else {
        this.userSites = currentSites
          .filter(
            (x: any) =>
              !x._deleted &&
              x.id === this.auth.getUserSites().find((y) => x.id === y)
          )
          .map((s: any) => {
            return {
              value: s.id,
              label: s.name,
              projectId: s.projectId,
            };
          });
      }
      this.currentProjectsAndSites();
      this.trialpalService.hideSpinner();
    }
  }

  getSubjects() {
    if (this.getValidateRole(Roles.Subject) && this.currentSubjects) {
      for (const subject of this.currentSubjects) {
        this.userService
          .getSubject(subject)
          .then((response) => {
            if (!response._deleted) {
              logger.debug('getSubject response', response);
              this.subjects.push(response.subjectNumber);
              this.selectedSubjects = this.selectedSubjects.concat(
                response.subjectNumber
              );
            }
          })
          .catch((error) => {
            logger.error('getSubject error', error);
          });
      }
    }
  }

  setUserInformation(currentUser: any): any {
    const user = {
      name: currentUser.name,
      email: currentUser.email,
      state: currentUser.state,
      login: currentUser.login,
      role: currentUser.role,
      phoneNumber: currentUser.phoneNumber ? currentUser.phoneNumber : '',
      notificationPreference: currentUser.notificationPreference,
    };
    this.currentProjects = currentUser.projects
      ? currentUser.projects.split(',').filter(Boolean)
      : [];
    this.currentSites = currentUser.sites
      ? currentUser.sites.split(',').filter(Boolean)
      : [];
    this.currentSubjects = currentUser.subjects
      ? currentUser.subjects.split(',').filter(Boolean)
      : [];

    this.currentRoles = currentUser.roles || [];
    this.user = user;

    if (this.user?.role) {
      this.role = this.getTypeOfUserByUser(this.user);
    }

    //Variables que verificarán si hubo un cambio
    this.setValuesForVerifyChanges(user);
  }

  getTypeOfUserByUser(user: any) {
    const splitRole = user?.role.split(',') || [''];
    const arrRole = splitRole.map((role: any) => {
      const translatedRoleName = this.trialpalService.translateService.instant(
        'user.enums.groups.' + role
      );
      const roleUser = {
        name: translatedRoleName,
        value: role,
        inactive: false,
      };
      if (role === Roles.Conciliator) {
        const index = this.allActiveRoles.findIndex(
          (r) => r.value === Roles.Conciliator
        );
        if (index !== -1) {
          this.allActiveRoles[index].inactive = false;
        }
      }
      return roleUser;
    });
    return arrRole;
  }

  setValuesForVerifyChanges(user: any) {
    this.userChange = { ...user };
    this.currentProjectsChange = [...this.currentProjects];
    this.currentSitesChange = [...this.currentSites];
    this.currentSubjectsChange = [...this.currentSubjects];
    this.currentRolesChange = [...this.currentRoles];
  }

  currentProjectsAndSites(): any {
    this.selectedProjects = this.currentProjects;
    this.selectedSites = this.currentSites;
    this.selectedRoles = this.currentRoles;
    this.selectedProjectsEvent(true);
  }

  async selectedProjectsEvent(_event?: any) {
    if (
      this.getValidateRole(Roles.Subject) &&
      this.selectedProjects.length > 1
    ) {
      this.showViaService();
      this.selectedProjects.splice(1, 1);
    }

    this.subjectsByProject = [];
    this.getAllowedSitesList();
    this.getAllowedRolesList();
    this.checkSelectedProjectsMFAConfig(this.selectedProjects);
  }

  getAllowedSitesList() {
    this.sites = [];
    this.sites = this.userSites.filter(
      (site) => this.selectedProjects.indexOf(site.projectId) > -1
    );

    this.groupSites = this.selectedProjects
      .map((selectedProject) => {
        const items = this.userSites.filter(
          (site) => site.projectId == selectedProject
        );
        const project = this.projects.find((x) => x.value === selectedProject);
        return items.length > 0
          ? {
              label: project.label,
              value: project.value,
              items: items,
            }
          : null;
      })
      .filter(Boolean);

    if (this.auth.isAdmin()) {
      this.selectedSites = this.sites
        ?.filter(
          (x) => this.selectedSites?.indexOf(x.value) > -1 && !x._deleted
        )
        ?.map((y) => y.value);
    }
  }

  getAllowedRolesList() {
    this.roles = [];
    this.roles = this.userRoles.filter(
      (role) =>
        role.projects.filter((x: any) => this.selectedProjects.indexOf(x) > -1)
          .length > 0
    );

    this.roles = this.roles.concat(
      this.userRoles.filter((role: any) => !role.isForProjects)
    );

    if (this.getValidateRole(Roles.Reader)) {
      this.exludeRolesForReader();
    }

    this.selectedRoles = this.roles
      ?.filter((role) => this.selectedRoles?.indexOf(role.value) > -1)
      .map((y) => y.value);
  }

  /**
   * Funcion para excluir roles que no aplican para tipo de usuario Lector.
   *
   * Esta función filtra los roles que tienen permisos que no están permitidos para el rol de Lector..
   * Los permisos excluidos se obtienen de `userPermissionsService` mediante el método `excludedReaderPermissions`..
   *
   * @returns {void} - The function does not return any value.
   */
  exludeRolesForReader() {
    const excludedPermission =
      this.userPermissionsService.excludedReaderPermissions();

    this.roles = this.roles.filter(
      (item: any) =>
        !excludedPermission.some((permission: any) =>
          item.permissions.includes(permission)
        )
    );
  }

  selectedSitesEvent(_event?: any) {
    if (this.getValidateRole(Roles.Subject) && this.selectedSites.length > 1) {
      this.showViaService();
      this.selectedSites.splice(1, 1);
    }
  }

  onSubmit(form: NgForm): void {
    this.removeSpaces();
    this.confirmErrors(form);

    if (
      form.valid &&
      this.trialpalService.phoneNumberService.isValidPhoneNumber(
        this.user.phoneNumber
      ).isValid
    ) {
      if (this.getValidateRole(Roles.Admin)) {
        this.editAdminUser();
      } else if (
        this.getValidateRole(Roles.Investigator) ||
        this.getValidateRole(Roles.Reader)
      ) {
        this.editCoordinatorInvestigatorReader();
      } else if (this.getValidateRole(Roles.Subject)) {
        if (this.isFromSubjectDetail) {
          this.editSubjectUser();
        } else {
          this.editSubjectUserWithSiteInfo();
        }
      }
    } else {
      this.trialpalService.showInvalidFormError();
      this.trialpalService.hideSpinner();
    }
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.user.email = this.user.email?.trim();
    this.user.name = this.user.name?.trim();
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.user.notificationPreference === 'EMAIl' &&
      this.trialpalService.validateSpaces(this.user.email, 'email', form);
    this.trialpalService.validateSpaces(this.user.login, 'username', form);
    this.trialpalService.validateSpaces(this.user.name, 'name', form);
    this.maxProjectsReached = this.trialpalService.validateArrayLength(
      this.selectedProjects,
      'proyects',
      form
    );
    this.maxSitesReached = this.trialpalService.validateArrayLength(
      this.selectedSites,
      'sites',
      form
    );
    this.maxSubjectsReached = this.trialpalService.validateArrayLength(
      this.selectedSubjects,
      'subjects',
      form
    );
  }

  private editSubjectUser() {
    const sameProjects: TP2IdEntity[] = [];
    const sameSites: TP2IdEntity[] = [];
    const sameSubjects: TP2IdEntity[] = [];

    for (const p of this.currentProjects) {
      sameProjects.push({ id: p });
    }
    for (const s of this.currentSites) {
      sameSites.push({ id: s });
    }
    for (const su of this.currentSubjects) {
      sameSubjects.push({ id: su });
    }

    this.editSubjectUserMaster(sameProjects, sameSites, sameSubjects);
  }

  private editSubjectUserWithSiteInfo() {
    const userSubjects: TP2IdEntity[] = [];
    this.subjectService
      .getUserSubjectsByNumber(this.selectedSubjects, this.selectedSites)
      .then((response) => {
        logger.debug('getUserSubjectsByNumber response', response);
        if (
          response &&
          response.length > 0 &&
          this.selectedSubjects.length === response.length
        ) {
          let isAuthorizedProject = true;
          let isAuthorizedSite = true;
          for (const s of response) {
            userSubjects.push({
              id: s.id,
            });
            const existProject =
              this.selectedProjects.indexOf(s.projectId) > -1;
            if (!existProject) {
              isAuthorizedProject = false;
            }
            const existSite = this.selectedSites.indexOf(s.siteId) > -1;
            if (!existSite) {
              isAuthorizedSite = false;
            }
          }
          if (!isAuthorizedProject || !isAuthorizedSite) {
            this.verifyAuthorization(isAuthorizedSite, isAuthorizedProject);
          } else {
            const userProjects: TP2IdEntity[] = this.selectedProjects.map(
              (projectId: any) => {
                return {
                  id: projectId,
                };
              }
            );
            const userSites: TP2IdEntity[] = this.selectedSites.map(
              (siteId: any) => {
                return {
                  id: siteId,
                };
              }
            );

            this.editSubjectUserMaster(userProjects, userSites, userSubjects);
          }
        } else {
          this.trialpalService.messageService.clear();
          this.trialpalService.messageService.add({
            severity: 'error',
            summary: this.trialpalService.translateService.instant(
              'user.messageErrorSubjectsSummay'
            ),
            detail: this.trialpalService.translateService.instant(
              'user.messageErrorSubjectsDetail'
            ),
          });
          this.trialpalService.hideSpinner();
        }
      });
  }
  editSubjectUserMaster(
    userProjects: TP2IdEntity[],
    userSites: TP2IdEntity[],
    userSubjects: TP2IdEntity[]
  ) {
    const changeReason = this.compareUser(
      userProjects,
      userSites,
      userSubjects,
      []
    );

    if (changeReason) {
      this.modalReasonSubjectUserMaster(userProjects, userSites, userSubjects);
    } else {
      this.trialpalService.ref.close();
    }
  }

  saveEditSubjectUserMaster(
    userProjects: any,
    userSites: any,
    userSubjects: any
  ) {
    this.trialpalService.showSpinner('user.entity', 'UPDATE');
    this.userService
      .editUser(this.user, userProjects, userSites, userSubjects, [])
      .then((response) => {
        logger.debug('editUser response', response);
        if (response) {
          const obj = JSON.parse(response);
          this.trialpalService.showMutationSuccess('user.entity', 'UPDATE');
          this.trialpalService.ref.close(obj);
          this.recordEditionEvent(obj);
        }
      })
      .catch((error) => {
        logger.error('editUser errorMaster', error);
        if (error?.response?.data) {
          this.trialpalService.showServiceError('user.actions.editUser', {
            errors: [
              { errorType: 'Lambda:Unhandled', message: error?.response?.data },
            ],
          });
        } else {
          this.trialpalService.showServiceError('user.actions.editUser', error);
        }
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  modalReasonSubjectUserMaster(
    userProjects: any,
    userSites: any,
    userSubjects: any
  ) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'user.actions.updateUser'
        ) +
        ': ' +
        this.user.login,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.user._changeReason = data;
          this.saveEditSubjectUserMaster(userProjects, userSites, userSubjects);
        }
      },
    });
  }
  verifyAuthorization(isAuthorizedSite: any, isAuthorizedProject: any) {
    if (!isAuthorizedSite) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'user.actions.editUser'
        ),
        detail:
          this.trialpalService.translateService.instant('apiError.TP2ERRU14'),
      });
    }
    if (!isAuthorizedProject) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'user.actions.editUser'
        ),
        detail:
          this.trialpalService.translateService.instant('apiError.TP2ERRU13'),
      });
    }
    this.trialpalService.hideSpinner();
  }
  editCoordinatorInvestigatorReader() {
    const userProjects: TP2IdEntity[] = this.selectedProjects.map(
      (projectId: any) => {
        return {
          id: projectId,
        };
      }
    );
    const userSites: TP2IdEntity[] = this.selectedSites.map((siteId: any) => {
      return {
        id: siteId,
      };
    });

    const userRoles: TP2IdEntity[] = this.selectedRoles.map((roleId: any) => {
      return {
        id: roleId,
      };
    });

    const changeReason = this.compareUser(
      userProjects,
      userSites,
      [],
      userRoles
    );

    if (changeReason) {
      this.modalReasonCoordinatorInvestigatorReader(
        userProjects,
        userSites,
        userRoles
      );
    } else {
      this.trialpalService.ref.close();
    }
  }

  recordEditionEvent(response: any) {
    Analytics.record({
      name: 'site_user_edition',
      attributes: {
        role: response.role,
        userSub: this.authService.user?.attributes?.sub,
        userName: this.authService.user?.username,
      },
    });
  }

  saveEditCoordinatorInvestigatorReader(
    userProjects: any,
    userSites: any,
    userRoles: any
  ) {
    this.trialpalService.showSpinner('user.entity', 'UPDATE');
    this.userService
      .editUser(this.user, userProjects, userSites, [], userRoles)
      .then((response) => {
        logger.debug('editUser response', response);
        if (response) {
          const obj = JSON.parse(response);
          this.trialpalService.showMutationSuccess('user.entity', 'UPDATE');
          this.trialpalService.ref.close(obj);
          this.recordEditionEvent(obj);
        }
      })
      .catch((error) => {
        logger.error('editUser error', error);
        if (error?.response?.data) {
          this.trialpalService.showServiceError('user.actions.editUser', {
            errors: [
              { errorType: 'Lambda:Unhandled', message: error?.response?.data },
            ],
          });
        } else {
          this.trialpalService.showServiceError('user.actions.editUser', error);
        }
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  modalReasonCoordinatorInvestigatorReader(
    userProjects: any,
    userSites: any,
    userRoles: any
  ) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'user.actions.updateUser'
        ) +
        ': ' +
        this.user.login,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.user._changeReason = data;
          this.saveEditCoordinatorInvestigatorReader(
            userProjects,
            userSites,
            userRoles
          );
        }
      },
    });
  }

  editAdminUser() {
    const userRoles: TP2IdEntity[] = this.selectedRoles.map((roleId: any) => {
      return {
        id: roleId,
      };
    });
    if (this.compareUser([], [], [], userRoles)) {
      this.modalReasonAdminUser(userRoles);
    } else {
      this.trialpalService.ref.close();
    }
  }

  saveEditAdminUser(userRoles: any) {
    this.trialpalService.showSpinner('user.entity', 'UPDATE');
    this.userService
      .editUser(this.user, [], [], [], userRoles)
      .then((response) => {
        logger.debug('editUser response', response);
        if (response) {
          const obj = JSON.parse(response);
          this.trialpalService.showMutationSuccess('user.entity', 'UPDATE');
          this.trialpalService.ref.close(obj);
          this.recordEditionEvent(obj);
        }
      })
      .catch((error) => {
        logger.error('editUse error', error);
        if (error?.response?.data) {
          this.trialpalService.showServiceError('user.actions.editUser', {
            errors: [
              { errorType: 'Lambda:Unhandled', message: error?.response?.data },
            ],
          });
        } else {
          this.trialpalService.showServiceError('user.actions.editUser', error);
        }
      })
      .finally(() => {
        this.trialpalService.hideSpinner();
      });
  }

  modalReasonAdminUser(userRoles: any) {
    const ref = this.trialpalService.dialogService.open(ChangeReasonComponent, {
      header:
        this.trialpalService.translateService.instant(
          'user.actions.updateUser'
        ) +
        ': ' +
        this.user.login,
      width: '70%',
    });
    ref.onClose.subscribe({
      next: (data: string) => {
        if (data) {
          this.user._changeReason = data;
          this.saveEditAdminUser(userRoles);
        }
      },
    });
  }

  onCancelar(): void {
    this.userForm.reset();
    this.trialpalService.ref.close(null);
  }
  showViaService() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary:
        this.trialpalService.translateService.instant('general.attention'),
      detail:
        this.trialpalService.translateService.instant('apiError.TP2ERRU15'),
    });
  }

  compareUser(
    userProjects: any,
    userSites: any,
    userSubjects: any,
    userRoles: any
  ) {
    let changeReason = false;

    //Crea un array con los valores del objeto para luego ser comparados
    const userArray = Object.values(this.user);
    const userChangeArray = Object.values(this.userChange);

    //Verifica si se ha cambiado alguna variable
    userArray.forEach((value, i) => {
      if (value !== userChangeArray[i]) {
        changeReason = true;
      }
    });

    //Verifica si los arrays son iguales y tienen la misma longitud, si no es que hubo un cambio
    if (!this.compareArray(userProjects, this.currentProjects)) {
      changeReason = true;
    }

    if (!this.compareArray(userSites, this.currentSitesChange)) {
      changeReason = true;
    }

    if (!this.compareArray(userSubjects, this.currentSubjectsChange)) {
      changeReason = true;
    }

    if (!this.compareArray(userRoles, this.currentRolesChange)) {
      changeReason = true;
    }

    return changeReason;
  }

  compareArray(arrayChange: any[], arrayInit: any[]) {
    //Convierte arrayChange en un array de String y los ordena
    arrayChange = arrayChange.map((value: any) => value.id);
    arrayChange.sort();
    arrayInit.sort();

    // Verifica si los 2 array son iguales y si cada valor son iguales
    return (
      arrayChange.length == arrayInit.length &&
      arrayChange.every((value, i) => value === arrayInit[i])
    );
  }

  changeSelectRole(_event: any) {
    //Validación par roles
    Object.values(Roles).forEach((rol) => {
      if (this.getValidateRole(rol)) {
        this.activeOptionsRoles(rol);
      }
    });

    // activar todas las opciones menos el gestor de usuarios en caso de no exitir alguna seleccionada
    if (this.role.length === 0) {
      this.allActiveRoles.forEach((role: any) => {
        role.inactive = role.value === Roles.Conciliator;
      });
    }
    const setRoleByUser: string[] = [];
    this.role.forEach((role: any) => {
      setRoleByUser.push(role.value);
    });

    this.getAllowedRolesList();
    this.user.role = setRoleByUser.join();
  }

  getValidateRole(roleUser: Roles): boolean {
    return this.role?.find((role: any) => role.value === roleUser) ?? false;
  }

  /**
   * Recorrer el array de roles y asignar si es activo o no
   * ej: si es ADMIN .. los otros roles pasaran a ser INACTIVOS
   * @param roleUser Rol de usuario a validar
   */
  activeOptionsRoles(rolUser: Roles) {
    //Validacion para usuario con rol coordinador o investigador
    if (rolUser === Roles.Admin) {
      this.assignProject = false;
      this.assignRoles = false;
      this.selectedRoles = [];
    }

    if (rolUser === Roles.Investigator) {
      if (this.projects.length === 0) {
        this.isProjectTransaction();
      }
      this.assignProject = true;
      this.assignRoles = true;
      this.allActiveRoles.forEach((role: any) => {
        role.inactive =
          role.value !== rolUser && role.value !== Roles.Conciliator;
      });
    } else if (rolUser === Roles.Subject) {
      this.assignRoles = false;
      this.selectedRoles = [];
    } else if (rolUser === Roles.Conciliator) {
      // si solo se selecciona el rol de gestor de usuario se deja en el estado original el multiselect
      if (this.checkDependentRoles()) {
        this.role = [];
      }
    } else {
      this.allActiveRoles.forEach((role: any) => {
        role.inactive = role.value !== rolUser;
      });
    }
  }

  checkDependentRoles() {
    if (this.role?.length === 1) {
      return this.role.find((role: any) => role.value === Roles.Conciliator);
    }

    return false;
  }

  checkSelectedProjectsMFAConfig(selectedProjects: Array<string>) {
    this.isProjectMFAActive = selectedProjects.some((projectId) => {
      const project = this.projects.find(
        (project) => project.value === projectId
      );
      return project?.isMFAActivated === true;
    });
  }
}
