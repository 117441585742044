import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubjectsService } from 'src/app/modules/subjects/subjects.service';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { CONCILIATION_QUERIES } from '../../../conciliation.queries';
import { ConciliationService } from '../../../conciliation.service';
import {
  CreateSignatureInstanceInput,
  InstanceState,
  SignatureEntityType,
  SignatureReason,
  SignatureType,
  UpdateVerifiedMedicalAttentionInstanceInput,
  UpdateVerifiedTemperatureRecordLogInput,
} from '../../../conciliation.types';
import { PaginationService } from '../../../pagination.service';
import { MedicalAttentionService } from '../medical-attention/medical-attention.service';
import { MedicationService } from '../medication/medication.service';
import { DayInstanceService } from '../shared/day-instance/day-instance.service';
import { SymptomService } from '../symptom/symptom.service';
import { TemperatureService } from '../temperature/temperature.service';

const logger = new Logger('tp2-signatureConciliation');
@Component({
  selector: 'app-signature-conciliation',
  templateUrl: './signature-conciliation.component.html',
  styleUrls: ['./signature-conciliation.component.scss'],
})
export class SignatureConciliationComponent {
  username: string = '';
  password: string = '';
  isPasswordShown = false;
  signatureInfo: any = {};
  constructor(
    private authService: AuthService,
    private dayInstanceService: DayInstanceService,
    private medicalAttentionService: MedicalAttentionService,
    private medicationService: MedicationService,
    private symptomService: SymptomService,
    private temperatureService: TemperatureService,
    private trialpalService: TrialpalService,
    private subjectService: SubjectsService,
    private router: Router,
    public conciliationService: ConciliationService,
    public ref: DynamicDialogRef,
    public paginationService: PaginationService
  ) {}

  getSignatureInfo() {
    const temperatures: any[] = [];
    const symptomsGeneral: any[] = [];
    const symptomsLocal: any[] = [];
    const otherSymptoms: any[] = [];
    const medications: any[] = [];
    const medicalAttentions: any[] = [];
    const verifiedDayInstances: any[] =
      this.dayInstanceService?.verifiedDayInstances;
    const comment = this.conciliationService?.currentComment;

    const sections = this.paginationService.sections;

    //Obtiene todas las temperaturas verificadas
    this.temperatureService?.colTemperatureDays.forEach((TI: any) => {
      if (TI?.verifiedTemperature && sections[0].isActive) {
        temperatures.push({
          original: TI?.temperatureRecord,
          verified: TI.verifiedTemperature,
        });
      }
    });

    const tableLocalSymptoms =
      this.symptomService.detailSymptomComponentLocal.symptomOcurrencesTable.reduce(
        (acc: any, curr) => acc.concat(curr.tableSymptoms),
        []
      );

    //Obtiene todos los sintomas verificados
    tableLocalSymptoms.forEach((SI: any) => {
      if (SI?.verifiedSymptom && sections[1].isActive) {
        symptomsLocal.push({
          original: SI?.symptomInstance,
          verified: SI.verifiedSymptomInstance,
        });
      }
    });

    const tableGeneralSymptoms =
      this.symptomService.detailSymptomComponentGeneral.symptomOcurrencesTable.reduce(
        (acc: any, curr) => acc.concat(curr.tableSymptoms),
        []
      );
    tableGeneralSymptoms.forEach((SI: any) => {
      if (SI?.verifiedSymptomInstance && sections[2].isActive) {
        symptomsGeneral.push({
          original: SI?.symptomInstance,
          verified: SI.verifiedSymptomInstance,
        });
      }
    });

    //Obtiene todos los otros sintomas verificados
    this.symptomService.tableOtherSymptoms.forEach((OSI) => {
      if (OSI.verifiedOtherSymptom && sections[3].isActive) {
        otherSymptoms.push({
          original: OSI.otherSymptom,
          verified: OSI.verifiedOtherSymptom,
        });
      }
    });

    //Obtiene todos los otros sintomas verificados
    this.medicationService?.tableMedications.forEach((MI) => {
      if (MI?.verifiedMedication && sections[4].isActive) {
        medications.push({
          original: MI?.medication,
          verified: MI.verifiedMedication,
        });
      }
    });

    //Obtiene todos los otros sintomas verificados
    this.medicalAttentionService?.medicalAttentionTable.forEach((MA: any) => {
      if (MA?.verifiedMedicalAttention && sections[5].isActive) {
        medicalAttentions.push({
          original: MA?.medicalAttention,
          verified: MA.verifiedMedicalAttention,
        });
      }
    });

    return {
      comment,
      medicalAttentions,
      medications,
      otherSymptoms,
      symptomsGeneral,
      symptomsLocal,
      temperatures,
      verifiedDayInstances,
    };
  }

  async createSignature() {
    try {
      await this.updateConciliationState();

      const signatureInfo = this.getSignatureInfo();
      const signature: CreateSignatureInstanceInput = {
        entityId: this.conciliationService.ediaryPhaseId,
        signedInfo: JSON.stringify(signatureInfo),
        reason: SignatureReason.REVIEW,
        entityType: SignatureEntityType.EDIARY,
        subjectId: this.conciliationService.subjectId,
        type: SignatureType.USER_PASS,
        date: new Date().toISOString(),
        _lastUser: this.authService.getUsername(),
      };

      const response = await this.conciliationService.performGraphQLQuery(
        CONCILIATION_QUERIES.CreateSignatureInstance,
        { input: signature }
      );

      const signatureInstance = response.data.createSignatureInstance;
      await this.conciliationService.updateEdiaryPhaseState(true);
      this.trialpalService.hideSpinner();
      this.trialpalService.messageService.add({
        severity: 'success',
        summary:
          this.trialpalService.translateService.instant('general.success'),
        detail: this.trialpalService.translateService.instant(
          'general.signedSuccess'
        ),
      });
      this.goToSubjectDetail();
      logger.debug('signatureInstance', signatureInstance);
      const pdfResponse = await this.subjectService.createEDiaryConciliationPDF(
        this.conciliationService.subjectId,
        this.conciliationService.ediaryPhaseId
      );
      logger.debug('PDF created', pdfResponse);
      this.trialpalService.hideSpinner();
      this.conciliationService.recordSignatureEvent();
      this.ref.close();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error('signatureInstanceError', error);
    }
  }

  async updateConciliationState() {
    try {
      await this.updateVerifiedTemperatureRecordState();
      await this.updateVerifiedSymptomInstanceState();
      await this.updateVerifiedOtherSymptomState();
      await this.updateVerifiedMedicationState();
      await this.updateVerifiedMedicalAttentionState();
      await this.UpdateVerifiedDayInstanceState();
    } catch (error) {
      logger.error('UpdateConciliation state error', error);
    }
  }

  async UpdateVerifiedDayInstanceState() {
    const listVerifiedDayInstances =
      this.dayInstanceService.verifiedDayInstances || [];

    for (const verifiedDayInstance of listVerifiedDayInstances) {
      if (verifiedDayInstance?.state === InstanceState.UNSIGNED) {
        const data = {
          state: InstanceState.COMPLETED,
          completedDate: new Date().toISOString(),
        };
        const verifiedDayInstanceUpdate =
          await this.dayInstanceService.updateVerifiedDayInstance(
            data,
            verifiedDayInstance
          );
        this.dayInstanceService.updateListVerifiedDayInstances(
          verifiedDayInstanceUpdate
        );
      }
    }
  }

  //Funcion que se encarga de actualizar los estados de medicamentos
  async updateVerifiedTemperatureRecordState() {
    await Promise.all(
      this.temperatureService?.colTemperatureDays.map(
        async (temperature: any) => {
          if (
            temperature.verifiedTemperature?.state === InstanceState.UNSIGNED
          ) {
            const input = {
              state: InstanceState.COMPLETED,
            } as UpdateVerifiedTemperatureRecordLogInput;

            temperature.verifiedTemperature =
              await this.temperatureService.updateVerifiedTemperatureRecordLog(
                input,
                temperature.verifiedTemperature.id,
                temperature.verifiedTemperature._version
              );
          }
          return temperature;
        }
      )
    );
  }

  //Funcion que se encarga de actualizar los estados en otros sintomas
  async updateVerifiedOtherSymptomState() {
    await Promise.all(
      this.symptomService.tableOtherSymptoms.map(async (OSI: any) => {
        if (OSI.verifiedOtherSymptom?.state === InstanceState.UNSIGNED) {
          const state = this.conciliationService.getState(
            OSI.verifiedOtherSymptom
          );

          OSI.verifiedOtherSymptom =
            await this.symptomService.updateVerifiedSymptom(
              OSI.verifiedOtherSymptom,
              { state }
            );
        }
        return OSI;
      })
    );
  }
  //Funcion que se encarga de actualizar los estados de medicamentos
  async updateVerifiedMedicationState() {
    await Promise.all(
      this.medicationService?.tableMedications.map(async (MI: any) => {
        if (MI.verifiedMedication?.state === InstanceState.UNSIGNED) {
          const state = this.conciliationService.getState(
            MI.verifiedMedication
          );

          MI.verifiedMedication =
            await this.medicationService.updateVerifiedMedication(
              { state },
              MI.verifiedMedication
            );
        }
        return MI;
      })
    );
  }

  //Funcion que se encarga de actualizar los estados de medicamentos
  async updateVerifiedMedicalAttentionState() {
    await Promise.all(
      this.medicalAttentionService?.medicalAttentionTable.map(
        async (MA: any) => {
          if (MA.verifiedMedicalAttention?.state === InstanceState.UNSIGNED) {
            const state = this.conciliationService.getState(
              MA.verifiedMedicalAttention
            );

            const input = {
              state,
            } as UpdateVerifiedMedicalAttentionInstanceInput;

            MA.verifiedMedicalAttention =
              await this.medicalAttentionService.updateVerifiedMedicalAttentionInstance(
                input,
                MA.verifiedMedicalAttention.id,
                MA.verifiedMedicalAttention._version
              );
          }
          return MA;
        }
      )
    );
  }

  //Funcion que se encarga de actualizar los estados de medicamentos
  async updateVerifiedSymptomInstanceState() {
    //Unifica todos los sintomas generales
    const tableGeneralSymptoms =
      this.symptomService.detailSymptomComponentGeneral.symptomOcurrencesTable.reduce(
        (acc: any, curr) => acc.concat(curr.tableSymptoms),
        []
      );
    await Promise.all(
      tableGeneralSymptoms.map(async (SI: any) => {
        if (SI.verifiedSymptomInstance?.state === InstanceState.UNSIGNED) {
          const state = this.conciliationService.getState(
            SI.verifiedSymptomInstance
          );

          SI.verifiedSymptomInstance =
            await this.symptomService.updateVerifiedSymptom(
              SI.verifiedSymptomInstance,
              { state }
            );

          const SRL = SI.symptomRecordLogs[0];
          let verifiedSymptomRecordLog = SRL?.verifiedSymptomRecordLog;
          SRL.verifiedSymptomRecordLog =
            this.symptomService.updateVerifiedSymptomRecordLog(
              SI.verifiedSymptomInstance,
              verifiedSymptomRecordLog,
              verifiedSymptomRecordLog?.verifiedDayInstanceId
            );
          SI.symptomRecordLogs[0] = SRL;
        }
        return SI;
      })
    );

    //Unifica todos los sintomas locales
    const tableSymptoms =
      this.symptomService.detailSymptomComponentLocal.symptomOcurrencesTable.reduce(
        (acc: any, curr) => acc.concat(curr.tableSymptoms),
        []
      );
    await Promise.all(
      tableSymptoms.map(async (SI: any) => {
        if (SI.verifiedSymptomInstance?.state === InstanceState.UNSIGNED) {
          const state = this.conciliationService.getState(
            SI.verifiedSymptomInstance
          );

          SI.verifiedSymptomInstance =
            await this.symptomService.updateVerifiedSymptom(
              SI.verifiedSymptomInstance,
              { state }
            );

          const SRL = SI.symptomRecordLogs[0];
          let verifiedSymptomRecordLog = SRL?.verifiedSymptomRecordLog;
          SRL.verifiedSymptomRecordLog =
            await this.symptomService.updateVerifiedSymptomRecordLog(
              SI.verifiedSymptomInstance,
              verifiedSymptomRecordLog,
              verifiedSymptomRecordLog?.verifiedDayInstanceId
            );
          SI.symptomRecordLogs[0] = SRL;
        }
        return SI;
      })
    );
  }

  goToSubjectDetail(): void {
    //Para que no muestre el mensaje de confirmación
    this.conciliationService.conciliationLockedExit = true;
    this.router.navigate([
      '/subjects/',
      this.conciliationService.subjectId,
      'detail',
    ]);
  }
}
