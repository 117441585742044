import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';
import { emailRegex } from 'src/app/shared/global.variables';
import { ChangeReasonComponent } from '../../../shared/components/change-reason/change-reason.component';
import { UserService } from '../../user/user.service';
import { ProjectService } from '../project.service';
import {
  AccessibilityGroupInput,
  ConfVisitGroup,
  CreateProjectInput,
  GetProjectQuery,
  ProjectState,
} from '../project.types';
import { Dictionary } from './../../../shared/components/table-languages/dictionary';
import { AddEditAccessibilityGroupComponent } from './add-edit-accessibility-group/add-edit-accessibility-group.component';

const logger = new Logger('tp2-logger-projectAddEditPage');

@Component({
  selector: 'app-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.scss'],
})
export class AddEditProjectComponent implements OnInit {
  project: CreateProjectInput = {
    code: '',
    groups: [],
    name: '',
    phases: [],
    faultRecipients: [],
    accessibilityGroups: [],
    termsAndConditions: [],
    state: ProjectState.IN_DESIGN,
    isEdiaryActivated: false,
    isICActivated: false,
    isReportsActivated: false,
    isChatActivated: false,
    isMFAActivated: false,
    _lastUser: '',
  };
  editMode = false;
  isModal = false;
  expectedVersion = 0;
  projectId = '';
  projects: any[] = [];
  dictionary: any[] = [];
  results: Dictionary[] = [];
  isSpanish: string = '';
  isEnglish: string = '';
  reportVisit: string = '';
  isArraySpanishVisit: any[] = [];
  isArrayEnglishVisit: any[] = [];
  isArraySpanishGroup: any[] = [];
  isArrayEnglishGroup: any[] = [];
  projectChange: string = '';
  listVisitGroup: ConfVisitGroup[] = [];
  groupsAux: string[] = [];
  phasesAux: string[] = [];
  isMFAActivatedOld: boolean = false;
  isSubjectNumberCheckRequired: boolean = false;
  subjectNumberRegex: string = '';
  subjectNumberPlaceholder: string = '';
  checkSiteCodeInSubjectNumber: boolean = false;

  @ViewChild('f', { static: true })
  centerForm!: NgForm;
  testText: string = '';
  isTestTextValid: boolean = true;

  constructor(
    private router: Router,
    private trialpalService: TrialpalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public authService: AuthService,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.editMode = this.config?.data?.isEdit;
    const id = this.config?.data?.id;
    this.isModal = this.config?.data?.isModal;

    this.authService.userAuthenticated.subscribe((res) => {
      if (!res) {
        this.ref.close(null);
      }
    });
    this.getProjects();
    if (id) {
      this.getProject(id);
    }
  }
  getProjects(): void {
    this.projectService
      .listUserProjectsValidate()
      .then((response) => {
        logger.debug('listProjects response', response);
        this.projects = response;
      })
      .catch((error) => {
        logger.debug('listProjects error', error);
        this.trialpalService.showServiceError(
          'project.actions.listProjects',
          error
        );
      });
  }
  getProject(id: string): void {
    this.trialpalService.showSpinner('project.entity', 'GET');
    this.editMode = true;
    this.projectService.getConfVisitGroup(id).then((response: any) => {
      this.listVisitGroup = response;
    });
    this.projectService
      .getProject(id)
      .then(async (response: GetProjectQuery) => {
        logger.debug('getProject response', response);
        this.expectedVersion = response._version;
        this.projectId = response.id;
        this.isMFAActivatedOld = response.isMFAActivated ?? false;
        this.trialpalService.cleanQueryResponse(response);
        this.project = Object.assign(this.project, response);
        //Auxiliar de los grupos y visitas
        this.groupsAux = this.project.groups;
        this.phasesAux = this.project.phases;
        this.projectChange = JSON.stringify(this.project);
        await this.loadDictionary();
        if (this.project.phases) {
          this.project.phases.forEach((phase) => {
            this.dictionary.forEach((data) => {
              if (data.key === phase) {
                this.setDataVisit(data);
              }
            });
          });
        }
        if (this.project.groups) {
          this.project.groups.forEach((group) => {
            this.dictionary.forEach((data) => {
              if (data.key === group) {
                this.setDataGroup(data);
              }
            });
          });
        }
        this.trialpalService.hideSpinner();
      })
      .catch((error) => {
        logger.error('getProject error', error);
        this.trialpalService.showServiceError(
          'project.actions.getProject',
          error
        );
        this.trialpalService.hideSpinner();
      });
  }
  onSubmit(form: NgForm): void {
    this.removeSpaces();
    this.confirmErrors(form);

    if (form.valid) {
      if (!this.isDuplicate()) {
        this.validateGroupInAccessibilityGroups();
        if (this.editMode) {
          this.editProject();
        } else {
          this.addProject();
        }
      } else {
        return this.trialpalService.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'project.messageDuplicateProject.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'project.messageDuplicateProject.detail'
          ),
        });
      }
    } else {
      this.trialpalService.showInvalidFormError();
      this.trialpalService.hideSpinner();
    }
  }

  //Función que se encarga de validar si los grupos de accesibilidad están asociados a grupos existentes
  validateGroupInAccessibilityGroups() {
    //Valida si los grupos aún siguen existiendo en la lista de accesibilidad
    this.project.accessibilityGroups = this.project.accessibilityGroups?.filter(
      (accessibilityGroup: AccessibilityGroupInput | null) => {
        return this.project.groups.find(
          (group: string) => group === accessibilityGroup?.group
        );
      }
    );

    //Borra el typename si existe en la lista
    this.project.accessibilityGroups = this.project.accessibilityGroups?.map(
      (accessibilityGroup: any) => {
        delete accessibilityGroup.__typename;
        return accessibilityGroup;
      }
    );
  }

  //Si se borra una visita o un grupo, se borran sus relaciones tambien
  async deleteVisitsGroup() {
    //Se guardará en un arreglo los elementos que no pertenezcan a un grupo/visita
    const visitGroupDelete: any[] = [];
    //Se recorre cada relación entre visita y grupo y se busca en las phases y grupos actuales
    this.listVisitGroup.forEach((visitGroup: ConfVisitGroup) => {
      //Verifica si la visita esté en el arreglo de visitas del proyecto
      const phaseExist = this.project.phases.findIndex(
        (phase: string) => phase === visitGroup.visit
      );
      //Verifica si el grupo esta en el arreglo de visitas del proyecto
      const groupExist = this.project.groups.findIndex(
        (group: string) => group === visitGroup.group
      );
      //Si no se encuentra el grupo/visita se elimina su relación
      if (phaseExist === -1 || groupExist === -1) {
        visitGroupDelete.push(visitGroup);
      }
    });

    //Elimina las asociaciones de los grupos con la visita
    for await (const visitGroup of visitGroupDelete) {
      await this.projectService.deleteVisitGroup(
        visitGroup.id,
        visitGroup._version
      );
    }
  }

  //Se encarga de remover los espacios antes y despues de una cadena de texto
  removeSpaces() {
    this.project.name = this.project.name?.trim();
    this.project.code = this.project.code?.trim();
    this.project.sponsor = this.project.sponsor?.trim();
    this.project.subjectNumberRegex = this.project.subjectNumberRegex?.trim();
    this.project.subjectNumberPlaceholder =
      this.project.subjectNumberPlaceholder?.trim();
  }

  //Se encarga de validar si el campo cumple los requisitos
  confirmErrors(form: any) {
    this.trialpalService.validateSpaces(this.project.name, 'name', form);
    this.trialpalService.validateSpaces(this.project.code, 'code', form);
    if (this.project.isSubjectNumberCheckRequired)
      this.trialpalService.validateSpaces(
        this.project.subjectNumberRegex,
        'subjectNumberRegex',
        form
      );
  }

  editProject(): void {
    //Comprueba si ha cambiado los datos del proyecto
    if (this.projectChange === JSON.stringify(this.project)) {
      this.ref.close();
      return;
    }
    if (!this.isDuplicate()) {
      const ref = this.dialogService.open(ChangeReasonComponent, {
        header:
          this.trialpalService.translateService.instant(
            'project.actions.updateProject'
          ) +
          ': ' +
          this.project.code,
        width: '70%',
      });
      ref.onClose.subscribe({
        next: (data: string) => {
          if (data) {
            this.project._changeReason = data;
            this.trialpalService.showSpinner('project.entity', 'UPDATE');
            this.projectService
              .updateProject(this.project, this.projectId, this.expectedVersion)
              .then(async (response) => {
                this.projectService.updateCurrentProject(response);
                //Elimina las relaciones de los grupos y visitas que se hayan eliminado
                await this.deleteVisitsGroup();
                await this.updateMFAByProject(
                  response?.isMFAActivated ?? false
                );
                logger.debug('updateProject response', response);
                this.trialpalService.showMutationSuccess(
                  'project.entity',
                  'UPDATE'
                );
                this.ref.close(response);
              })
              .catch((error) => {
                logger.error('updateProject error', error);
                this.trialpalService.showServiceError(
                  'project.actions.updateProject',
                  error
                );
              })
              .finally(() => {
                this.ref.close(null);
                this.router.navigate(['project/' + this.projectId + '/detail']);
                this.trialpalService.hideSpinner();
              });
          }
        },
      });
    } else {
      return this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'project.messageDuplicateProject.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'project.messageDuplicateProject.detail'
        ),
      });
    }
  }

  async updateMFAByProject(currentIsMFAActivated: boolean): Promise<void> {
    if (this.isMFAActivatedOld !== currentIsMFAActivated) {
      await this.userService.updateMFAByProject(
        this.projectId,
        currentIsMFAActivated
      );
    }
  }

  addProject(): void {
    this.project.state = ProjectState.IN_DESIGN;
    this.project.isEdiaryActivated = false;
    this.project.isICActivated = false;
    this.project.isReportsActivated = false;
    this.project.code = this.project.code.trim();
    this.project.name = this.project.name.trim();
    this.trialpalService.showSpinner('project.entity', 'CREATE');
    logger.debug('createProject input', this.project);
    this.projectService
      .createProject(this.project)
      .then((response) => {
        logger.debug('createProject response', response);
        this.trialpalService.showMutationSuccess('project.entity', 'CREATE');
        this.project.id = response.id;
        this.projectService.pushProject(response);
      })
      .catch((error) => {
        logger.error('createProject error', error);
        this.trialpalService.showServiceError(
          'project.actions.createProject',
          error
        );
      })
      .finally(() => {
        this.ref.close(null);
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['project/' + this.project.id + '/detail']);
          });
      });
    this.trialpalService.hideSpinner();
  }

  isDuplicate(): boolean {
    if (this.editMode) {
      const projects = this.projects.filter(
        (p: any) =>
          p.code.toLowerCase().replace(/\s+/g, '') ===
            this.project.code.toLowerCase().replace(/\s+/g, '') &&
          this.project.id !== p.id
      );
      if (projects.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return (
        this.projects.findIndex(
          (p: any) =>
            p.code.toLowerCase().replace(/\s+/g, '') ===
            this.project.code.toLowerCase().replace(/\s+/g, '')
        ) !== -1
      );
    }
  }

  onCancelar(): void {
    if (this.isModal) {
      this.ref.close(null);
    } else {
      this.centerForm.reset();
      this.router.navigate(['/listProjects']);
    }
  }

  onDictionaty(isForm: boolean, label?: string): void {
    const ref = this.trialpalService.dialogService.open(
      TableLanguagesComponent,
      {
        data: {
          projectId: this.projectId,
          isForm: isForm,
        },
        width: '95%',
        height: '95%',
      }
    );

    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        this.loadDictionary()
          .then(() => {
            if (label === 'group') {
              if (!this.project.groups.includes(data.key)) {
                this.project.groups = [...this.project.groups, data.key];
                this.setDataGroup(data);
                this.trialpalService.messageService.add({
                  severity: 'success',
                  summary: this.trialpalService.translateService.instant(
                    'dictionary.selectTranslate'
                  ),
                  detail: this.trialpalService.translateService.instant(
                    'dictionary.succesSelectTranslate'
                  ),
                });
              } else {
                this.trialpalService.messageService.add({
                  severity: 'info',
                  summary: this.trialpalService.translateService.instant(
                    'dictionary.selectTranslate'
                  ),
                  detail: this.trialpalService.translateService.instant(
                    'dictionary.inforExistKey'
                  ),
                });
              }
            }
            if (label === 'visit') {
              if (!this.project.phases.includes(data.key)) {
                this.project.phases = [...this.project.phases, data.key];
                this.setDataVisit(data);
                this.trialpalService.messageService.add({
                  severity: 'success',
                  summary: this.trialpalService.translateService.instant(
                    'dictionary.selectTranslate'
                  ),
                  detail: this.trialpalService.translateService.instant(
                    'dictionary.succesSelectTranslate'
                  ),
                });
              } else {
                this.trialpalService.messageService.add({
                  severity: 'info',
                  summary: this.trialpalService.translateService.instant(
                    'dictionary.selectTranslate'
                  ),
                  detail: this.trialpalService.translateService.instant(
                    'dictionary.inforExistKey'
                  ),
                });
              }
            }
          })
          .catch((err) => logger.error('No se pudo cargar el diccionario', err))
          .finally(() => this.trialpalService.hideSpinner());
      }
    });
  }

  openAddEditAccesibilityGroup() {
    const ref = this.trialpalService.dialogService.open(
      AddEditAccessibilityGroupComponent,
      {
        data: {
          projectId: this.projectId,
          groups: this.project.groups,
          accessibilityGroups: this.project.accessibilityGroups,
        },
        header: this.trialpalService.translateService.instant(
          'project.accessibilityGroupTitle'
        ),
        width: '460px',
        height: 'auto',
        styleClass: 'modal-container',
      }
    );
    ref.onClose.subscribe((accessibilityGroups: AccessibilityGroupInput[]) => {
      if (accessibilityGroups) {
        this.project.accessibilityGroups = accessibilityGroups;
      }
    });
  }

  addVisitDictionary(event: any) {
    this.phasesAux.push(event.value);
    this.dictionary.forEach((data) => {
      if (data.key === event.value) {
        this.setDataVisit(data.trim());
      }
    });
  }

  setDataVisit(data: Dictionary) {
    const s = {
      key: data.key,
      spanish: data.spanish,
    };
    this.isArraySpanishVisit.push(s);
    const e = {
      key: data.key,
      english: data.english,
    };
    this.isArrayEnglishVisit.push(e);
  }

  removeVisit(event: any) {
    if (this.editMode) {
      //Borra de la base de datos la visita vinculada al grupo
      this.confirmationService.confirm({
        message: this.trialpalService.translateService.instant(
          'project.visitDelete',
          {
            visit: this.trialpalService.dictionaryPipe.transform(event.value),
          }
        ),
        accept: () => {
          //Elimina los grupos del arreglo auxiliar
          this.phasesAux = this.phasesAux.filter(
            (phase: string) => phase !== event.value
          );
          this.removeVisitDictionary(event);
        },
        reject: () => {
          const index = this.phasesAux.findIndex(
            (phase: string) => phase === event.value
          );
          this.project.phases = this.project.phases
            .slice(0, index)
            .concat(event.value)
            .concat(this.project.phases.slice(index));
        },
      });
    } else {
      this.removeVisitDictionary(event);
    }
  }

  removeVisitDictionary(event: any) {
    this.isArraySpanishVisit.forEach((data, index) => {
      if (data.key === event.value) {
        this.isArraySpanishVisit.splice(index, 1);
      }
    });
    this.isArrayEnglishVisit.forEach((data, index) => {
      if (data.key === event.value) {
        this.isArrayEnglishVisit.splice(index, 1);
      }
    });
  }

  addGroupDictionary(event: any) {
    this.groupsAux.push(event.value);
    this.dictionary.forEach((data) => {
      if (data.key === event.value) {
        this.setDataGroup(data.trim());
      }
    });
  }

  setDataGroup(data: Dictionary) {
    const s = {
      key: data.key,
      spanish: data.spanish,
    };
    this.isArraySpanishGroup.push(s);
    const e = {
      key: data.key,
      english: data.english,
    };
    this.isArrayEnglishGroup.push(e);
  }
  removeGroup(event: any) {
    if (this.editMode) {
      //Borra de la base de datos la visita vinculada al grupo
      this.confirmationService.confirm({
        message: this.trialpalService.translateService.instant(
          'project.groupDelete',
          {
            group: this.trialpalService.dictionaryPipe.transform(event.value),
          }
        ),
        accept: () => {
          //Elimina los grupos del arreglo auxiliar
          this.groupsAux = this.groupsAux.filter(
            (group: string) => group !== event.value
          );
          this.removeGroupDictionary(event);
        },
        reject: () => {
          //Si el usuario cancela, se coloca nuevamente el grupo en su anterior posicion
          const index = this.groupsAux.findIndex(
            (group: string) => group === event.value
          );
          this.project.groups = this.project.groups
            .slice(0, index)
            .concat(event.value)
            .concat(this.project.groups.slice(index));
        },
      });
    } else {
      this.removeGroupDictionary(event);
    }
  }
  removeGroupDictionary(event: any) {
    this.isArraySpanishGroup.forEach((data, index) => {
      if (data.key === event.value) {
        this.isArraySpanishGroup.splice(index, 1);
      }
    });
    this.isArrayEnglishGroup.forEach((data, index) => {
      if (data.key === event.value) {
        this.isArrayEnglishGroup.splice(index, 1);
      }
    });
  }

  async loadDictionary() {
    this.dictionary = [];
    await this.trialpalService.dictionaryService
      .getConfDictionaryByProjectId(this.projectId)
      .then((dictionary) => {
        dictionary.items?.forEach((item) => {
          const obj: any = {
            id: item?.id,
            key: item?.key,
            spanish: item?.spanish ?? '',
            english: item?.english ?? '',
          };
          this.dictionary.push(obj);
        });
        localStorage.removeItem('Dictionaty');
        localStorage.setItem('Dictionary', JSON.stringify(dictionary));
      })
      .catch((err) => logger.error('No se pudo cargar el diccionario', err));
  }
  getChipText(item: string): string {
    return item.length <= 20 ? item : item.substring(0, 20) + '...';
  }

  onAddRecipient(input: any, index?: any): void {
    let pattern;
    pattern = new RegExp(emailRegex);

    if (!pattern.test(input)) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'error',
        summary:
          this.trialpalService.translateService.instant('general.attention'),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.smallInvalidEmail'
        ),
      });
      if (!index && this.project.faultRecipients) {
        this.project.faultRecipients = this.project.faultRecipients.filter(
          (faultRecipient: string) => faultRecipient !== input
        );
      }
    }
  }
  testRegex() {
    logger.debug('testTextxd', this.testText);
    if (this.project.isSubjectNumberCheckRequired && this.testText) {
      if (this.project.subjectNumberRegex) {
        const pattern = new RegExp(this.project.subjectNumberRegex);
        this.isTestTextValid = pattern.test(this.testText);
      }
    }
  }
}
