import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Librerias externas
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { TranslatePipe } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { AddEditFieldComponent } from './designer/components/add-edit-field/add-edit-field.component';
import { LabelTooltipComponent } from './designer/components/label-component/label-tooltip.component';
import { FieldOptionComponent } from './designer/components/list-field/list-field.component';
import { DesignerComponent } from './designer/designer.component';
import { FormPreviewComponent } from './form-preview/form-preview.component';
import { FormlyDesignerComponent } from './formly-designer.component';
import { ClearFieldPipe } from './pipes/clear-field.pipe';
import { DictionaryFieldPipe } from './pipes/dictionary-field.pipe';
import { FieldGroupPipe } from './pipes/fieldGroup.pipe';
import { FormlyFieldCardComponent } from './types/card.types';
//Components
import { FormlyDateTimeComponent } from './types/date-time/formly-date-time.component';
import { FormlyCheckboxComponent } from './types/formly-checkbox/formly-checkbox.component';
import { FormlyMulticheckboxComponent } from './types/formly-multicheckbox/formly-multicheckbox.component';
import { FormlyRadioGridComponent } from './types/formly-radio-grid/formly-radio-grid.component';
import { FormlyRadioImageComponent } from './types/formly-radio-image/formly-radio-image.component';
import { FormlyRepeatComponent } from './types/formly-repeat/formly-repeat.component';
import { RepeatTypeComponent } from './types/repeat-seccion';
import { RepeatTypeButtonComponent } from './types/repeat-sectionButton';
import { SubtitleComponent } from './types/subtitle/subtitle.component';
import { FormlyTextFieldComponent } from './types/text-field';
import { TitleComponent } from './types/title/title.component';
import { AccordionComponent } from './wrappers/accordion/accordion.component';
import { CardComponent } from './wrappers/card/card.component';
import { LabelComponent } from './wrappers/label/label.component';
import { StepperComponent } from './wrappers/stepper/stepper.component';
import { TabsComponent } from './wrappers/tabs/tabs.component';
import { ValidationComponent } from './wrappers/validation/validation.component';
export function formlyValidationConfig() {
  return {
    validationMessages: [
      { name: 'required', message: () => 'Campo requerido' },
    ],
  };
}

@NgModule({
  declarations: [
    FormlyDesignerComponent,
    DesignerComponent,
    AddEditFieldComponent,
    StepperComponent,
    AccordionComponent,
    TabsComponent,
    FormlyRadioImageComponent,
    CardComponent,
    FormlyRadioGridComponent,
    TitleComponent,
    FormlyCheckboxComponent,
    FormlyMulticheckboxComponent,
    FormlyRepeatComponent,
    FormPreviewComponent,
    ValidationComponent,
    LabelTooltipComponent,
    ClearFieldPipe,
    DictionaryFieldPipe,
    FieldGroupPipe,
    LabelComponent,
    SubtitleComponent,
    FieldOptionComponent,
    FormlyFieldCardComponent,
    RepeatTypeButtonComponent,
    RepeatTypeComponent,
    FormlyTextFieldComponent,
    FormlyDateTimeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PrimengModule,
    SharedComponentsModule,
    FormlySelectModule,
    DragDropModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      wrappers: [
        { name: 'tabs', component: TabsComponent },
        { name: 'accordion', component: AccordionComponent },
        { name: 'stepper', component: StepperComponent },
        { name: 'card', component: CardComponent },
        { name: 'validation', component: ValidationComponent },
        { name: 'label', component: LabelComponent },
      ],
      types: [
        { name: 'checkbox', component: FormlyCheckboxComponent },
        { name: 'radio-image', component: FormlyRadioImageComponent },
        { name: 'radio-grid', component: FormlyRadioGridComponent },
        { name: 'multicheckbox', component: FormlyMulticheckboxComponent },
        { name: 'repeat-group', component: FormlyRepeatComponent },
        { name: 'title', component: TitleComponent },
        { name: 'subtitle-text', component: SubtitleComponent },
        { name: 'card', component: FormlyFieldCardComponent },
        { name: 'repeat-button', component: RepeatTypeButtonComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'subtitle', component: FormlyTextFieldComponent },
        { name: 'datetime', component: FormlyDateTimeComponent },
      ],
    }),
    FormlyPrimeNGModule,
  ],
  exports: [FormlyDesignerComponent, DictionaryFieldPipe, FormlyModule],
  providers: [
    TranslatePipe,
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: formlyValidationConfig,
    },
  ],
})
export class FormlyDesignerModule {}
