import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { API, Analytics, Logger, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { EDIARY_QUERIES } from '../ediary/ediary.queries';
import { AssessmentMode, ConfEDiary } from '../ediary/ediary.types';
import { SubjectsService } from '../subjects/subjects.service';
import { EDiaryPhaseInstance } from '../subjects/subjects.types';
import { CONCILIATION_QUERIES } from './conciliation.queries';
import {
  CreateCommentConciliationInstanceInput,
  InstanceState,
  ModelSignatureInstanceFilterInput,
  SignatureEntityType,
  UpdateCommentConciliationInstanceInput,
  UpdateEDiaryPhaseInstanceInput,
} from './conciliation.types';
import { DayInstanceService } from './detail-conciliation/components/shared/day-instance/day-instance.service';
const logger = new Logger('tp2-conciliation service');
@Injectable({
  providedIn: 'root',
})
export class ConciliationService {
  //========== Todos los componentes manejan estas variables
  ediaryPhaseInstance!: EDiaryPhaseInstance;
  confEDiary!: ConfEDiary;
  ediaryPhaseId: string = '';
  confEdiaryId: string = '';
  NEW_INSTANCE_ID: string = '123456789';
  subjectId: string = '';
  dayInstancesAndConfigurations: any[] = [];
  isPdfConciliationGenerated: boolean = true;
  isConciliationEdition: boolean = false;
  conciliationLockedExit: boolean = false;
  visitedSections: any[] = [];
  assessmentMode: AssessmentMode = AssessmentMode.DETAILED_REVIEW;

  //Objetos que representan los comentarios
  currentComment: any = {};

  transformDatePipe: TransformDatePipe;

  //=====CONSTRUCTOR===============
  constructor(
    private authService: AuthService,
    private trialpalService: TrialpalService,
    private subjectsService: SubjectsService,
    private dayInstanceService: DayInstanceService,
    transformDatePipe: TransformDatePipe
  ) {
    this.transformDatePipe = transformDatePipe;
  }

  //=======================================================
  /**
   * @desc evaluar un boleano para reemplazar el true o false
   * @return SI/NO
   */
  getYN(value?: boolean | null) {
    return value
      ? this.trialpalService.translateService.instant('general.yes')
      : this.trialpalService.translateService.instant('general.no');
  }

  //Obtiene todas los diarios asociadas a la visita
  async getDayInstancesByEdiaryPhaseId() {
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.DayInstanceByEDiaryPhaseInstanceCustom,
      { eDiaryPhaseInstanceId: this.ediaryPhaseId }
    );

    let { items } = response.data.dayInstanceByEDiaryPhaseInstance;
    return items?.filter((dayInstance: any) => !dayInstance._deleted) || [];
  }

  async getVerifiedDayInstancesByEdiaryPhaseId() {
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.VerifiedDayInstanceByEdiaryPhaseIdCustom,
      { eDiaryPhaseInstanceId: this.ediaryPhaseId }
    );

    let { items } = response.data.verifiedDayInstancesByEDiaryPhaseInstance;
    return items;
  }

  //Obtiene los dias segun la fase y su configuración
  async getDayInstanceByEdiaryPhase() {
    this.dayInstancesAndConfigurations = [];

    const dayInstances: any[] = await this.getDayInstancesByEdiaryPhaseId();
    const verifiedDayInstances =
      await this.getVerifiedDayInstancesByEdiaryPhaseId();

    for await (const dayInstance of dayInstances) {
      const configuration = dayInstance.confDay.confForm;

      const isDayInstanceWithReconciliationDisabled =
        dayInstance.confDay._deleted ||
        !configuration?.isReconciliationRequired;

      if (isDayInstanceWithReconciliationDisabled) continue;

      if (
        dayInstance.state === InstanceState.COMPLETED ||
        dayInstance.state === InstanceState.PENDING
      ) {
        this.dayInstancesAndConfigurations.push({
          dayInstance,
          configuration,
        });
      }
      if (this.isBeforeToCurrentDate(dayInstance.finishDate)) {
        await this.dayInstanceService.fillVerifiedDayInstances(
          dayInstance,
          this.getIsAutomaticReview(),
          verifiedDayInstances
        );
      }
    }

    this.dayInstancesAndConfigurations.sort(
      (a, b) => a.dayInstance?.confDay?.order - b.dayInstance?.confDay?.order
    );

    logger.debug(
      'Lista de dias y sus configuraciones',
      this.dayInstancesAndConfigurations,
      'Lista de dias verificados',
      this.dayInstanceService.verifiedDayInstances
    );
  }

  getDayInstanceByVerifiedDayInstanceId(id: string): any {
    const verifiedDayInstance =
      this.dayInstanceService.verifiedDayInstances.find((VDI: any) => {
        return VDI.id === id;
      });

    let dayInstance = null;
    if (verifiedDayInstance?.dayInstanceId) {
      const dayInstances = this.getDayInstances();
      dayInstance = dayInstances.find((DI: any) => {
        return DI.dayInstance.id === verifiedDayInstance?.dayInstanceId;
      });
    }
    return dayInstance?.dayInstance ?? null;
  }

  getDayInstances() {
    return this.dayInstancesAndConfigurations.filter((dayConf: any) => {
      return this.isBeforeToCurrentDate(dayConf.dayInstance.finishDate);
    });
  }

  //=========================EDIARY PHASE==============================================

  //Obtiene las firmas que se ha hecho en conciliación
  async getSignatureInstanceByEntityId(entityId: string) {
    let instances: any[] = [];

    const filter: ModelSignatureInstanceFilterInput = {
      entityId: { eq: entityId },
      entityType: { eq: SignatureEntityType.EDIARY },
    };

    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.SignaturesBySubjectId,
      {
        subjectId: this.subjectId,
        sortDirection: undefined,
        filter,
        limit: 1000,
      }
    );

    let listQuery = response.data.signaturesBySubjectId;
    instances = listQuery.items;
    instances = instances
      .filter(Boolean)
      .filter(
        (signature: any) =>
          !signature._deleted && signature.state !== InstanceState.DELETED
      );
    return instances;
  }

  async getEdiaryPhaseById(): Promise<any> {
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.GetEDiaryPhaseInstance,
      { id: this.ediaryPhaseId }
    );

    return response.data.getEDiaryPhaseInstance;
  }

  async getConfEdiaryById(): Promise<void> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.GetConfEDiary,
      { id: this.confEdiaryId }
    );

    this.confEDiary = response.data.getConfEDiary;
  }

  async getAssessmentMode(): Promise<void> {
    const confEDiary = this.confEDiary;
    this.assessmentMode =
      confEDiary?.assessmentMode ?? AssessmentMode.DETAILED_REVIEW;
  }

  getIsAutomaticReview(): boolean {
    return this.assessmentMode === AssessmentMode.AUTOMATIC_REVIEW;
  }

  async isEdiaryPhaseUnlock(ediaryPhaseInstance: EDiaryPhaseInstance) {
    const isConciliationLock = this.subjectsService.checkConciliationLock(
      ediaryPhaseInstance,
      this.authService.getUsername()
    );

    if (isConciliationLock) {
      this.showConliationLockMessage(ediaryPhaseInstance);
      return false;
    } else {
      this.ediaryPhaseInit(ediaryPhaseInstance);
      return true;
    }
  }

  async ediaryPhaseInit(ediaryPhaseInstance: EDiaryPhaseInstance) {
    this.ediaryPhaseInstance = ediaryPhaseInstance;
    this.confEdiaryId =
      this.ediaryPhaseInstance?.eDiaryInstance?.confEDiaryId ?? '';

    this.conciliationLockedExit = false;
    //Valida si se ha firmado ya la conciliación
    const signatures = await this.getSignatureInstanceByEntityId(
      ediaryPhaseInstance.id
    );

    this.isConciliationEdition = signatures?.length > 0;

    //Valida si se ha generado un PDF anterioridad
    this.isPdfConciliationGenerated = this.isConciliationEdition
      ? this.ediaryPhaseInstance.state === InstanceState.CONCILIATION_COMPLETED
      : true;
    // Realiza el bloqueo de la conciliación
    await this.lockConciliation();

    logger.debug('EdiaryPhase', this.ediaryPhaseInstance);
  }

  showConliationLockMessage(ediaryPhaseInstance: EDiaryPhaseInstance) {
    const message = this.trialpalService.translateService.instant(
      'conciliation.userLock',
      {
        user: ediaryPhaseInstance?.locked?.user,
      }
    );
    const summary =
      this.trialpalService.translateService.instant('general.attention');

    this.messageError(summary, message);
    this.conciliationLockedExit = true;
    this.trialpalService.hideSpinner();
  }

  async lockConciliation(): Promise<any> {
    const input: UpdateEDiaryPhaseInstanceInput = {
      id: this.ediaryPhaseInstance.id,
      locked: {
        user: this.authService.getUsername(),
        date: new Date().toISOString(),
      },
      _version: this.ediaryPhaseInstance._version,
      _lastUser: this.authService.getUsername(),
    };
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateEDiaryPhaseInstance,
      { input }
    );
    this.ediaryPhaseInstance = response.data.updateEDiaryPhaseInstance;
  }

  async updateEdiaryPhaseState(
    isPdfConciliationGenerated: boolean
  ): Promise<any> {
    try {
      const state = !isPdfConciliationGenerated
        ? InstanceState.UNSIGNED
        : InstanceState.CONCILIATION_COMPLETED;

      const input: UpdateEDiaryPhaseInstanceInput = {
        state: state,
        id: this.ediaryPhaseInstance.id,
        _version: this.ediaryPhaseInstance._version,
        _lastUser: this.authService.getUsername(),
      };
      const response = await this.performGraphQLQuery(
        CONCILIATION_QUERIES.UpdateEDiaryPhaseInstance,
        { input }
      );

      this.ediaryPhaseInstance = response.data.updateEDiaryPhaseInstance;

      //Valida si se ha generado un PDF anterioridad
      this.isPdfConciliationGenerated = this.isConciliationEdition
        ? this.ediaryPhaseInstance.state ===
          InstanceState.CONCILIATION_COMPLETED
        : true;
    } catch (error: any) {
      logger.error(error, 'error');
      if (
        error?.errors &&
        error?.errors[0]?.message === 'Conflict resolver rejects mutation.'
      ) {
        this.ediaryPhaseInstance._version =
          this.ediaryPhaseInstance._version + 1;
        await this.updateEdiaryPhaseState(isPdfConciliationGenerated);
      }
    }
  }

  //=========================COMENTARIOS==============================================

  async getCommentByEdiaryPhaseId(): Promise<any> {
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.CommentConciliationInstanceByEDiaryPhaseInstance,
      { eDiaryPhaseInstanceId: this.ediaryPhaseId }
    );
    return response.data.commentConciliationInstanceByEDiaryPhaseInstance;
  }

  async createCommentConciliation(comment: string): Promise<any> {
    const input: CreateCommentConciliationInstanceInput = {
      comment,
      _lastUser: this.authService.getUsername(),
      eDiaryPhaseInstanceId: this.ediaryPhaseId,
    };
    this.updateEdiaryPhaseState(false).then();
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateCommentConciliationInstance,
      { input }
    );
    return response.data.createCommentConciliationInstance;
  }

  async updateCommentConciliation(
    instance: any,
    comment: string,
    changeReason: string
  ) {
    const input: UpdateCommentConciliationInstanceInput = {
      id: instance.id,
      comment,
      _lastUser: this.authService.getUsername(),
      eDiaryPhaseInstanceId: this.ediaryPhaseId,
      _version: instance._version,
      _changeReason: changeReason,
    };
    this.updateEdiaryPhaseState(false).then();
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateCommentConciliationInstance,
      { input }
    );
    return response.data.updateCommentConciliationInstance;
  }

  //======================== DATOS COMPARTIDOS ENTRE COMPONENTES ===================
  setValidator(
    formController: AbstractControl | null,
    isValidatorRequired: boolean
  ) {
    const validator = isValidatorRequired ? Validators.required : null;
    if (!isValidatorRequired) formController?.reset();
    formController?.setValidators(validator);
    formController?.updateValueAndValidity();
  }

  buildBooleanOptions(): { label: string; value: boolean }[] {
    return [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }

  //===================== Codigo para obtener los sintomas =============

  //Devuelve un objeto solo con los cambios que hubo
  getValuesChange(formOriginal: any, formVerified: any) {
    let original: any = {};
    let verified: any = {};

    for (const key in formOriginal) {
      const isOriginalValueEmpty = this.isEmpty(formOriginal[key]);
      const isVerifiedValueEmpty = this.isEmpty(formVerified[key]);
      const isACommentKey = key?.includes('Comment');
      const isDifferentElement =
        JSON.stringify(formOriginal[key]) !== JSON.stringify(formVerified[key]);
      if (
        (!isOriginalValueEmpty || !isVerifiedValueEmpty) &&
        !isACommentKey &&
        isDifferentElement
      ) {
        original[key] = formOriginal[key];
        verified[key] = formVerified[key];
      }
    }
    return [original, verified];
  }

  isEmpty(value: any): boolean {
    const ignore: any[] = ['', undefined, null];
    return ignore.includes(value);
  }

  messageError(summary: string, detail: string) {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(summary),
      detail: this.trialpalService.translateService.instant(detail),
      life: 7000,
    });
  }

  messageSuccess(isCreated?: boolean) {
    this.trialpalService.messageService.clear();
    let summary = '';
    let detail = '';
    if (isCreated) {
      summary = 'conciliation.messageSuccessCreated.summary';
      detail = 'conciliation.messageSuccessCreated.detail';
    } else {
      summary = 'conciliation.messageSuccessUpdated.summary';
      detail = 'conciliation.messageSuccessUpdated.detail';
    }
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(summary),
      detail: this.trialpalService.translateService.instant(detail),
    });
  }

  messageDeleteSuccess() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant(
        'general.deleteSuccessSummary'
      ),
      detail: this.trialpalService.translateService.instant(
        'general.deleteSuccess'
      ),
    });
  }

  getFormatDate(date: any) {
    if (!date) return undefined;
    const currentDate = new Date(date).toDateString();
    return new Date(currentDate);
  }

  transformDate(data: any, verifiedInstance: any = {}) {
    if (data?.finishDate) {
      let isSameFinishDate = moment(data.finishDate).isSame(
        verifiedInstance?.finishDate,
        'days'
      );
      data.finishDate = isSameFinishDate
        ? verifiedInstance?.finishDate
        : this.subjectsService.setDateWithTimezone(
            new Date(data.finishDate).toISOString()
          );
    }

    if (data?.startDate) {
      let isSameStartDate = moment(data.startDate).isSame(
        verifiedInstance?.startDate,
        'days'
      );
      data.startDate = isSameStartDate
        ? verifiedInstance?.startDate
        : this.subjectsService.setDateWithTimezone(
            new Date(data.startDate).toISOString()
          );
    }
  }

  transformSummaryDate(data: any) {
    if (data?.finishDate) {
      data.finishDate = this.subjectsService.setDateWithTimezone(
        new Date(data.finishDate).toISOString()
      );
    }

    if (data?.startDate) {
      data.startDate = this.subjectsService.setDateWithTimezone(
        new Date(data.startDate).toISOString()
      );
    }
  }

  isValidDates(form: FormGroup, isMedicalAttention?: boolean) {
    let { startDate, finishDate } = form.value;

    if (isMedicalAttention) {
      let { formStartDate, formFinishDate } = form.value;
      startDate = formStartDate;
      finishDate = formFinishDate;
    }

    if (startDate && finishDate) {
      startDate = new Date(startDate).toDateString();
      finishDate = new Date(finishDate).toDateString();

      if (new Date(startDate) > new Date(finishDate)) {
        return false;
      }
    }

    return true;
  }

  messageErrorDates() {
    this.trialpalService.messageService.add({
      detail: this.trialpalService.translateService.instant(
        'general.messageErrorBeforeDate.detail'
      ),
      summary: this.trialpalService.translateService.instant(
        'general.messageErrorBeforeDate.summary'
      ),
      severity: 'error',
    });
  }

  clearSpaces(form: FormGroup) {
    const formValues = form.value;
    Object.entries(formValues).forEach(([key]) => {
      if (typeof formValues[key] === 'string') {
        formValues[key] = formValues[key]?.trim();
      }
    });
    form.patchValue(formValues);
  }

  //Verifica si hubo un cambio en las vaibles
  isFormChange(original: any, change: any) {
    let isChange = false;
    const ignore: any = ['', undefined, null];
    //Verifica si el campo esta lleno y campo
    for (const value of Object.entries(original)) {
      if (!ignore.includes(value[1]) && !value[0].includes('Comment')) {
        if (JSON.stringify(change[value[0]]) !== JSON.stringify(value[1])) {
          isChange = true;
        }
      }
    }
    return isChange;
  }

  //Verifica si hubo un cambio en comentarios
  isFormCommentChange(formValue: any, formChange: any) {
    let isCommentChange = false;
    //Verifica si el campo esta lleno y campo
    for (const value of Object.entries(formChange)) {
      if (value[1] && value[0].includes('Comment')) {
        if (formValue[value[0]] !== value[1]) {
          isCommentChange = true;
        }
      }
    }
    return isCommentChange;
  }

  showSpinner(transation: string, entity: string) {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(`general.${transation}`, {
        entity: this.trialpalService.translateService.instant(entity),
      })
    );
  }

  getState(data: any) {
    let state = data?.state;
    if (
      data?.state !== InstanceState.DELETED &&
      data?.state !== InstanceState.CONCILIATION_DELETED
    ) {
      state = data?.finishDate
        ? InstanceState.COMPLETED
        : InstanceState.ON_GOING;
    }
    return state;
  }

  isInTheDateRange(startDate: any) {
    if (startDate) {
      startDate = this.trialpalService.transformCurrentTimezone(startDate);
      startDate = moment(startDate);
      const firstDate = moment()
        .year(startDate.year())
        .month(startDate.month())
        .date(startDate.date());
      const curDate = moment();

      return moment(firstDate).isSameOrBefore(curDate);
    }
    return false;
  }

  isBeforeToCurrentDate(date: any) {
    if (date) {
      date = this.trialpalService.transformCurrentTimezone(date);
      date = moment(date);
      const firstDate = moment()
        .year(date.year())
        .month(date.month())
        .date(date.date());
      return moment(firstDate).isBefore(moment(), 'days');
    }
    return false;
  }

  //Codigo para validar formulario de razon de cambios para la auditoria

  /**
   * Si formReason no es null, devuelve el motivo si no es igual a 'general.changeReasons.other'; de lo
   * contrario, devuelve el otro motivo.
   * @param {any} formReason - any: este es el objeto que contiene el motivo y otras propiedades del
   * @returns La razon del cambio.
   */
  getChangeReason(formReason: any) {
    if (!formReason) return '';
    return formReason?.reason === 'other'
      ? formReason?.otherReason
      : formReason?.reason.split('.').slice(-1)[0];
  }

  /**
   * @des valida si el formulario de razón de cambio es valido
   * @return boolean
   */
  getFormReasonValid(formReason: any) {
    if (formReason?.reason === 'other') {
      let otherReason = formReason?.otherReason;
      return otherReason.trim().length !== 0;
    }
    if (formReason?.reason?.length === 0) {
      return false;
    }
    return true;
  }

  async conciliationUnLock() {
    const input: UpdateEDiaryPhaseInstanceInput = {
      id: this.ediaryPhaseInstance.id,
      locked: null,
      _version: this.ediaryPhaseInstance._version,
      _lastUser: this.authService.getUsername(),
    };
    const response = await this.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateEDiaryPhaseInstance,
      { input }
    );

    return response.data.updateEDiaryPhaseInstance;
  }

  getauthSession() {
    return this.authService.isAuthenticated();
  }

  isEdiaryPhaseInstanceEnded() {
    return this.dayInstancesAndConfigurations?.every((dayInstance: any) => {
      return this.isBeforeToCurrentDate(dayInstance.dayInstance.finishDate);
    });
  }

  recordChangeEvent(newValue: any, oldValue?: any, section?: any) {
    logger.debug(newValue, 'newValueOnRecord');
    logger.debug(oldValue, 'oldValueOnRecord');
    let index = 0;
    //si es un array
    if (newValue instanceof Array) {
      newValue.forEach((value: any) => {
        logger.debug(value, 'valueOnRecord');
        Object.keys(value).forEach((key) => {
          let areNullEquals = false;
          if (oldValue) {
            areNullEquals = this.areNullEquals(value, oldValue[index], key);
          }
          this.callAnalyticsEvent(key, section, areNullEquals);
        });
        index++;
      });
    } else if (newValue instanceof Object) {
      Object.keys(newValue).forEach((key) => {
        let areNullEquals = false;
        if (oldValue) {
          areNullEquals = this.areNullEquals(newValue, oldValue, key);
        }
        this.callAnalyticsEvent(key, section, areNullEquals);
      });
    }
  }

  callAnalyticsEvent(key: any, section?: any, areNullEquals?: any) {
    const attributes = {
      userSub: this.authService.user?.attributes?.sub,
      userName: this.authService.user?.username,
    };
    if (!areNullEquals) {
      Analytics.record({
        name: 'site_change_conciliation_' + key + '_' + section,
        attributes: attributes,
      });
    }
  }

  areNullEquals(newValue: any, oldValue?: any, key?: any) {
    let areNullEquals = false;
    if (oldValue) {
      //si es un array
      let oldValueValue = oldValue[key];
      let newValueValue = newValue[key];

      areNullEquals =
        ((oldValueValue instanceof Array && oldValueValue.length === 0) ||
          oldValueValue === null ||
          oldValueValue === undefined) &&
        ((newValueValue instanceof Array && newValueValue.length === 0) ||
          newValueValue === null ||
          newValueValue === undefined);
    }
    return areNullEquals;
  }

  addVisitedSection(section: any) {
    const sections = [
      'TEMPERATURE',
      'SYMPTOM_LOCAL',
      'SYMPTOM_GENERAL',
      'OTHER_SYMPTOM',
      'MEDICATION',
      'MEDICAl_ATTETION',
      'COMMENT',
      'SIGNATURE',
    ];
    const lastVisitedSection =
      typeof section === 'number' ? sections[section] : section;
    if (!this.visitedSections.includes(lastVisitedSection)) {
      this.visitedSections.push(lastVisitedSection);
      logger.debug('visitedSections', this.visitedSections);
    }
  }

  getLastVisitedSection() {
    return this.visitedSections[this.visitedSections.length - 1];
  }

  clearVisitedSections() {
    this.visitedSections = [];
  }

  recordExitEvent() {
    const lastVisitedSection = this.getLastVisitedSection();
    const attributes = {
      userSub: this.authService.user?.attributes?.sub,
      userName: this.authService.user?.username,
    };
    Analytics.record({
      name: 'site_exit_conciliation' + '_' + lastVisitedSection?.toLowerCase(),
      attributes: attributes,
    });
    this.clearVisitedSections();
  }

  recordSignatureEvent() {
    const attributes = {
      userSub: this.authService.user?.attributes?.sub,
      userName: this.authService.user?.username,
    };
    Analytics.record({
      name: 'site_sign_conciliation',
      attributes: attributes,
    });
  }

  //** codigo para la conciliación automatica */

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
