/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API, graphqlOperation } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  ConfState,
  InstanceState,
  MediaType,
} from 'src/app/services/trialpal.types';
import { SymptomIcons } from 'src/app/shared/global.variables';
import { AlertType, PageLayout } from '../report/report.types';
import { EDIARY_QUERIES } from './ediary.queries';
import {
  ConfEdiaryByProjectQuery,
  ConfFormByConfEdiaryIdQuery,
  ConfFormConfSymptomsByConfSymptomIdCustomQuery,
  ConfMedicalAttentionByConfEdiaryQuery,
  ConfMedicationByConfEdiaryQuery,
  ConfMultimediaByConfEdiaryQuery,
  ConfOtherSymptomsByConfEdiaryQuery,
  ConfSymptomByConfEdiaryIdQuery,
  ConfSymptomByConfFormIdQuery,
  ConfTemperatureByConfEdiaryQuery,
  CreateConfAlertInput,
  CreateConfAlertMutation,
  CreateConfDayInput,
  CreateConfDayMutation,
  CreateConfEDiaryInput,
  CreateConfEDiaryMutation,
  CreateConfFormConfSymptomInput,
  CreateConfFormConfSymptomMutation,
  CreateConfFormInput,
  CreateConfFormMutation,
  CreateConfMedicalAttentionInput,
  CreateConfMedicalAttentionMutation,
  CreateConfMedicationInput,
  CreateConfMedicationMutation,
  CreateConfMultimediaInput,
  CreateConfMultimediaMutation,
  CreateConfOtherSymptomsInput,
  CreateConfOtherSymptomsMutation,
  CreateConfSymptomInput,
  CreateConfSymptomMutation,
  CreateConfTemperatureInput,
  CreateConfTemperatureMutation,
  DeleteConfDayInput,
  DeleteConfDayMutation,
  DeleteConfFormConfSymptomInput,
  DeleteConfFormConfSymptomMutation,
  DeleteConfSymptomInput,
  DeleteConfSymptomMutation,
  GetConfSymptomQuery,
  HelpScreenDisplayOption,
  InjectionSide,
  InjectionSite,
  Intensity,
  IntensityType,
  ModelConfAlertFilterInput,
  ModelConfDayFilterInput,
  ModelDayInstanceFilterInput,
  Symptom,
  SymptomType,
  TemperatureUnit,
  UpdateConfAlertInput,
  UpdateConfAlertMutation,
  UpdateConfDayInput,
  UpdateConfEDiaryInput,
  UpdateConfFormInput,
  UpdateConfFormMutation,
  UpdateConfMedicalAttentionInput,
  UpdateConfMedicationInput,
  UpdateConfMultimediaInput,
  UpdateConfOtherSymptomsInput,
  UpdateConfSymptomInput,
  UpdateConfSymptomMutation,
  UpdateConfTemperatureInput,
  UpdateEDiaryPhaseInstanceInput,
} from './ediary.types';
@Injectable({
  providedIn: 'root',
})
export class EdiaryService {
  constructor(
    public translateService: TranslateService,
    private auth: AuthService,
    private trialpalService: TrialpalService
  ) {}

  async getConfEdiaryByProjectId(
    projectId: string
  ): Promise<ConfEdiaryByProjectQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfEdiaryByProjectIdCustom,
      { projectId }
    );

    return response.data.confEdiaryByProject;
  }

  async createConfEdiary(
    data: CreateConfEDiaryInput
  ): Promise<CreateConfEDiaryMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfEDiary,
      { input: data }
    );

    return response.data.createConfEDiary;
  }

  async createConfMedicationByEdiary(
    data: CreateConfMedicationInput
  ): Promise<CreateConfMedicationMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfMedication,
      { input: data }
    );

    return response.data.createConfMedication;
  }

  async createConfMedicalAttentionByEdiary(
    data: CreateConfMedicalAttentionInput
  ): Promise<CreateConfMedicalAttentionMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfMedicalAttention,
      { input: data }
    );

    return response.data.createConfMedicalAttention;
  }

  async createConfOtherSyptomsByEdiary(
    data: CreateConfOtherSymptomsInput
  ): Promise<CreateConfOtherSymptomsMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfOtherSymptoms,
      { input: data }
    );

    return response.data.createConfOtherSymptoms;
  }

  async createConfMultimediaByEdiary(
    data: CreateConfMultimediaInput
  ): Promise<CreateConfMultimediaMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfMultimedia,
      { input: data }
    );

    return response.data.createConfMultimedia;
  }

  async createConfTemperatureByEdiary(
    data: CreateConfTemperatureInput
  ): Promise<CreateConfTemperatureMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfTemperature,
      { input: data }
    );

    return response.data.createConfTemperature;
  }

  async getConfMedicationByFormId(
    formId: string
  ): Promise<ConfMedicationByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfMedicationByConfForm,
      { confFormId: formId }
    );

    return response.data.confMedicationByConfForm;
  }

  async getConfMultimediaByFormId(
    formId: string
  ): Promise<ConfMultimediaByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfMultimediaByConfForm,
      { confFormId: formId }
    );

    return response.data.confMultimediaByConfForm;
  }

  async getConfMedicalAttentionByFormId(
    formId: string
  ): Promise<ConfMedicalAttentionByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfMedicalAttentionByConfForm,
      { confFormId: formId }
    );

    return response.data.confMedicalAttentionByConfForm;
  }

  async getConfMedicalAttentionByEdiaryId(
    ediaryId: string
  ): Promise<ConfMedicalAttentionByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfMedicalAttentionByConfEdiary,
      { confEdiaryId: ediaryId }
    );

    return response.data.confMedicalAttentionByConfEdiary;
  }

  async getConfOtherSymtompByEdiaryId(
    ediaryId: string
  ): Promise<ConfOtherSymptomsByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfOtherSymptomsByConfEdiary,
      { confEdiaryId: ediaryId }
    );

    return response.data.confOtherSymptomsByConfEdiary;
  }
  async getConfOtherSymtompByByFormId(
    formId: string
  ): Promise<ConfOtherSymptomsByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfOtherSymptomsByConfForm,
      { confFormId: formId }
    );

    return response.data.confOtherSymptomsByConfForm;
  }

  async getConfTemperatureByByFormId(
    formId: string
  ): Promise<ConfTemperatureByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfTemperatureByConfForm,
      { confFormId: formId }
    );

    return response.data.confTemperatureByConfForm;
  }

  async getConfMultimediaByEdiaryId(
    ediaryId: string
  ): Promise<ConfMultimediaByConfEdiaryQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfMultimediaByConfEdiary,
      { confEdiaryId: ediaryId }
    );

    return response.data.confMultimediaByConfEdiary;
  }

  async updateConfMedication(
    data: CreateConfMedicationInput,
    id: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfMedicationInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfMedication,
      { input: updateInput }
    );

    return response.data.updateConfMedication;
  }

  async updateConfOtherSymptom(
    data: CreateConfOtherSymptomsInput,
    id: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfOtherSymptomsInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfOtherSymptoms,
      { input: updateInput }
    );

    return response.data.updateConfOtherSymptoms;
  }
  async updateConfMedicalAttention(
    data: CreateConfMedicalAttentionInput,
    id: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfMedicalAttentionInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfMedicalAttention,
      { input: updateInput }
    );

    return response.data.updateConfMedicalAttention;
  }

  async updateConfMultimedia(
    data: CreateConfMultimediaInput,
    id: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfMultimediaInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfMultimedia,
      { input: updateInput }
    );

    return response.data.updateConfMultimedia;
  }

  async updateMedicationReport(
    data: CreateConfMedicationInput,
    id: string,
    formId: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfMedicationInput = {
      id,
      confFormId: formId,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfMedication,
      { input: updateInput }
    );

    return response.data.updateConfMedication;
  }

  async updateMedicalAttentionReport(
    data: CreateConfMedicalAttentionInput,
    id: string,
    formId: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfMedicalAttentionInput = {
      id,
      confFormId: formId,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfMedicalAttention,
      { input: updateInput }
    );

    return response.data.updateConfMedicalAttention;
  }

  async updateOtherSymtompReport(
    data: CreateConfOtherSymptomsInput,
    id: string,
    formId: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfOtherSymptomsInput = {
      id,
      confFormId: formId,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfOtherSymptoms,
      { input: updateInput }
    );

    return response.data.updateConfOtherSymptoms;
  }

  async updateEdiaryPhase(input: UpdateEDiaryPhaseInstanceInput): Promise<any> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateEDiaryPhaseInstance,
      { input }
    );

    return response.data.updateEDiaryPhaseInstance;
  }

  async updateConfMultimediaReport(
    data: CreateConfMultimediaInput,
    id: string,
    formId: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfMultimediaInput = {
      id,
      confFormId: formId,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfMultimedia,
      { input: updateInput }
    );

    return response.data.updateConfMultimedia;
  }

  async updateConfMTemperatureReport(
    data: CreateConfTemperatureInput,
    id: string,
    formId: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfTemperatureInput = {
      id,
      confFormId: formId,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfTemperature,
      { input: updateInput }
    );

    return response.data.updateConfTemperature;
  }

  async updateConfEdiary(
    data: CreateConfEDiaryInput,
    id: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfEDiaryInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfEDiary,
      { input: updateInput }
    );

    return response.data.updateConfEDiary;
  }

  async updateConciliationConfEdiary(
    input: UpdateConfEDiaryInput
  ): Promise<any> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfEDiary,
      { input }
    );

    return response.data.updateConfEDiary;
  }

  async getConfSymptomById(data: string): Promise<GetConfSymptomQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.GetConfSymptom,
      { id: data }
    );

    return response.data.getConfSymptom;
  }

  async getConfSymptomByConfEdiaryId(
    confEdiaryId: string
  ): Promise<ConfSymptomByConfEdiaryIdQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfSymptomByConfEdiaryIdCustom,
      { confEdiaryId }
    );
    return response.data.confSymptomByConfEdiaryId;
  }

  async getConfSymptomListByConfFormId(
    confFormId: string
  ): Promise<ConfSymptomByConfFormIdQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfSymptomByConfFormId,
      { confFormId }
    );

    return response.data.confSymptomByConfFormId;
  }

  async getConfFormByConfSymptom(confSymptomId: string): Promise<any> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfFormByConfSymptom,
      { confSymptomId }
    );
    const { items } = response.data.confFormByConfSymptom;
    return items.filter((e: any) => !e?._deleted);
  }

  async getConfFormConfSymptomByConfSymptomId(
    confSymptomId: string
  ): Promise<ConfFormConfSymptomsByConfSymptomIdCustomQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfFormConfSymptomsByConfSymptomIdCustom,
      { confSymptomId }
    );

    return response.data.listConfFormConfSymptoms;
  }

  async createConfSymptom(
    data: CreateConfSymptomInput
  ): Promise<CreateConfSymptomMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfSymptom,
      { input: data }
    );

    return response.data.createConfSymptom;
  }

  async updateConfSymptom(
    data: CreateConfSymptomInput,
    id: string,
    expectedVersion: number
  ): Promise<UpdateConfSymptomMutation> {
    let updateInput: UpdateConfSymptomInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfSymptom,
      { input: updateInput }
    );

    return response.data.updateConfSymptom;
  }

  async deleteConfSymptom(
    input: DeleteConfSymptomInput
  ): Promise<DeleteConfSymptomMutation> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.DeleteConfSymptom,
      { input }
    );

    return response.data.deleteConfSymptom;
  }

  async getConfDayByConfEdiaryId(confEdiaryId: string): Promise<any> {
    const filter: ModelConfDayFilterInput = {
      state: {
        ne: ConfState.DELETED,
      },
    };
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfDayByConfEdiaryCustom,
      { confEdiaryId, filter }
    );

    return response.data.confDayByConfEdiary;
  }

  async createConfDay(
    data: CreateConfDayInput
  ): Promise<CreateConfDayMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfDay,
      { input: data }
    );

    return response.data.createConfDay;
  }

  async updateConfDay(
    data: any,
    id: string,
    expectedVersion: number
  ): Promise<any> {
    let updateInput: UpdateConfDayInput = {
      id,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfDay,
      { input: updateInput }
    );

    return response.data.updateConfDay;
  }

  async deleteConfDay(
    data: DeleteConfDayInput
  ): Promise<DeleteConfDayMutation> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.DeleteConfDay,
      { input: data }
    );

    return response.data.deleteConfDay;
  }

  async getConfFormByConfEdiaryId(
    confEdiaryId: string
  ): Promise<ConfFormByConfEdiaryIdQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfFormByConfEdiaryId,
      { confEdiaryId }
    );

    return response.data.confFormByConfEdiaryId;
  }

  async createConfForm(
    data: CreateConfFormInput
  ): Promise<CreateConfFormMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfForm,
      { input: data }
    );

    return response.data.createConfForm;
  }

  async createConfFormConfSymptom(
    data: CreateConfFormConfSymptomInput
  ): Promise<CreateConfFormConfSymptomMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfFormConfSymptom,
      { input: data }
    );

    return response.data.createConfFormConfSymptom;
  }

  async deleteConfFormConfSymptom(
    data: DeleteConfFormConfSymptomInput
  ): Promise<DeleteConfFormConfSymptomMutation> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.DeleteConfFormConfSymptom,
      { input: data }
    );

    return response.data.deleteConfFormConfSymptom;
  }

  async updateConfForm(
    data: any,
    id: string,
    expectedVersion: number
  ): Promise<UpdateConfFormMutation> {
    let updateInput: UpdateConfFormInput = {
      id,
      isReconciliationRequired: data.isReconciliationRequired ?? false,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfForm,
      { input: updateInput }
    );

    return response.data.updateConfForm;
  }

  async getConfAlertByConfEdiaryId(confEdiaryId: string): Promise<any> {
    const filter: ModelConfAlertFilterInput = {
      state: {
        ne: ConfState.DELETED,
      },
    };
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;

    do {
      const listQuery: any = await this.performGraphQLQuery(
        EDIARY_QUERIES.ConfAlertByConfEdiaryIdCustom,
        { confEdiaryId, filter, limit: 1000, nextToken }
      );

      instances = instances.concat(
        listQuery.data.confAlertByConfEdiaryId.items
      );
      nextToken =
        listQuery.data.confAlertByConfEdiaryId?.nextToken || undefined;
    } while (nextToken);

    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== ConfState.DELETED);

    return instances;
  }

  async createConfAlert(
    data: CreateConfAlertInput
  ): Promise<CreateConfAlertMutation> {
    data._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.CreateConfAlert,
      { input: data }
    );

    return response.data.createConfAlert;
  }

  async updateConfAlert(
    data: any,
    id: string,
    expectedVersion: number
  ): Promise<UpdateConfAlertMutation> {
    let updateInput: UpdateConfAlertInput = {
      id,
      _version: expectedVersion,
    };
    delete data.projectId;
    delete data.project;
    delete data.__typename;
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.UpdateConfAlert,
      { input: updateInput }
    );

    return response.data.updateConfAlert;
  }

  async deleteConfAlert(data: any): Promise<any> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.DeleteConfAlert,
      { input: data }
    );

    return response.data.deleteConfAlert;
  }
  async confEdiaryByProject(id: string): Promise<ConfEdiaryByProjectQuery> {
    const response = await this.performGraphQLQuery(
      EDIARY_QUERIES.ConfEdiaryByProject,
      { projectId: id }
    );

    return response.data.confEdiaryByProject;
  }
  getMediaTypes(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService
        .get('ediary.enums.mediaTypes')
        .subscribe((translate) => {
          const data: any[] = [];
          Object.keys(MediaType).forEach((e) => {
            data.push({ name: translate[e], value: e });
          });
          resolve(data);
        });
    });
  }

  getSymptoms(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.symptoms');
    Object.keys(Symptom).forEach((e) => {
      if (e !== 'OTHER') {
        data.push({ name: list[e], value: e });
      }
    });
    return data;
  }
  getSymptomsByInstances(instances: any): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.symptoms');
    instances.forEach((e: any) => {
      if (e.symptom !== 'OTHER') {
        data.push({
          id: e.id,
          name:
            this.trialpalService.dictionaryPipe.transform(e.symptomLabel) +
            ' (' +
            list[e.symptom] +
            ')',
          value: e.symptom,
          intensityType: e.intensityType,
          decimalPlaces: e.decimalPlaces,
        });
      }
    });
    return data;
  }

  getInjectionSites(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.injectionSites');
    Object.keys(InjectionSite).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  getInjectionSides(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.injectionSides');
    Object.keys(InjectionSide).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  getSymptomIcons(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.symptomIcons');
    Object.keys(SymptomIcons).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  getSymptomTypes(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.symptomTypes');
    Object.keys(SymptomType).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  getIntensityTypes(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptom.enums.intensityTypes');
    Object.keys(IntensityType).forEach((e) => {
      if (e !== IntensityType.NUMERIC_VALUE_QUESTIONS) {
        data.push({ name: list[e], value: e });
      }
    });
    return data;
  }

  getHelpScreenDisplayOption(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant(
      'symptom.enums.helpScreenDisplayOptions'
    );
    Object.keys(HelpScreenDisplayOption).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  getIntensity(): any[] {
    const data: any[] = [];
    const list = this.translateService.instant('symptominstance.enums');
    Object.keys(Intensity).forEach((e) => {
      data.push({ name: list[e], value: e });
    });
    return data;
  }

  getPageLayouts(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService
        .get('ediary.enums.pageLayouts')
        .subscribe((translate) => {
          const data: any[] = [];
          Object.keys(PageLayout).forEach((e) => {
            data.push({ name: translate[e], value: e });
          });
          resolve(data);
        });
    });
  }
  getAlertTypes(): Promise<any[]> {
    return new Promise((resolve) => {
      this.translateService
        .get('ediary.enums.alertTypes')
        .subscribe((translate) => {
          const data: any[] = [];
          Object.keys(AlertType).forEach((e) => {
            data.push({ name: translate[e], value: e });
          });
          resolve(data);
        });
    });
  }
  getfrequency(): any[] {
    const frequency = {
      Daily: '',
      Weekly: '',
      Monthly: '',
    };
    const data: any[] = [];
    const list = this.translateService.instant('general.frequency');
    Object.keys(frequency).forEach((e) => {
      data.push({ label: list[e], value: e });
    });
    return data;
  }
  getTemperatureUnits() {
    const data: any[] = [];
    const list: any = { CELSIUS: 'C°', FAHRENHEIT: 'F°' };
    Object.keys(TemperatureUnit).forEach((e: any) => {
      data.push({
        label: list[e],
        value: e,
      });
    });
    return data;
  }

  async getDayInstanceBySitesConfDays(_sites: any, confDays: any) {
    const filter: ModelDayInstanceFilterInput = {
      or: [],
    };
    for (let confDay of confDays) {
      filter.or?.push({ confDayId: { eq: confDay } });
    }
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        EDIARY_QUERIES.ListDayInstancesCustom,
        { filter, limit: 1000, nextToken }
      );
      instances = instances.concat(listQuery.data.listDayInstances.items);
      nextToken = listQuery.data.listDayInstances.nextToken ?? undefined;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((instance: any) => instance && !instance._deleted);
    return instances;
  }

  async findAtLeastOneAlertInstanceByConfAlert(id: string) {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;

    do {
      const listQuery: any = await this.performGraphQLQuery(
        EDIARY_QUERIES.AlertInstanceByConfAlertId,
        { confAlertId: id, limit: 100, nextToken }
      );
      instances = instances.concat(
        listQuery.data.alertInstanceByConfAlertId.items
      );
      nextToken = listQuery.data.alertInstanceByConfAlertId.nextToken;
      instances = instances
        .filter(Boolean)
        .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
      if (instances.length > 0) return true;
    } while (nextToken);

    return instances.length > 0;
  }
  async findAtLeastOneDayInstanceByConfDay(id: string) {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        EDIARY_QUERIES.DayInstanceByConfDayId,
        { confDayId: id, limit: 100, nextToken }
      );
      instances = instances.concat(listQuery.data.dayInstanceByConfDayId.items);
      nextToken = listQuery.data.dayInstanceByConfDayId.nextToken;
      instances = instances
        .filter(Boolean)
        .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
      if (instances.length > 0) return true;
    } while (nextToken);
    return instances.length > 0;
  }
  async eDiaryInstanceBySubjectId(subjectId: any): Promise<any> {
    const eDiaryInstances = await this.performGraphQLQuery(
      EDIARY_QUERIES.CustomEDiaryInstanceBySubjectId,
      { subjectId }
    );
    return eDiaryInstances.data.eDiaryInstanceBySubjectId.items.filter(
      (x: any) => !x._deleted && x.state !== InstanceState.DELETED
    );
  }
  async ListSymptomInstances() {
    let symptoms: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.performGraphQLQuery(
        EDIARY_QUERIES.ListSymptomInstances,
        {
          limit: 1000,
          nextToken,
        }
      );
      symptoms = symptoms.concat(listQuery.data.listSymptomInstances.items);
      nextToken = listQuery.data.listSymptomInstances.nextToken;
    } while (nextToken);
    return symptoms.filter(Boolean).filter((s: any) => !s._deleted);
  }

  async performGraphQLQuery(query: any, args: any): Promise<any> {
    return (await API.graphql(graphqlOperation(query, args))) as any;
  }
}
