import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import {
  DayInstance,
  MedicationConfigurationMode,
  MedicationInstanceBySubjectIdQuery,
} from 'src/app/modules/conciliation/conciliation.types';
import { SubjectsService } from 'src/app/modules/subjects/subjects.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { InstanceState } from 'src/app/services/trialpal.types';
import { PaginationService } from '../../../../pagination.service';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import { TableDayInstanceComponent } from '../../shared/day-instance/table-day-instance/table-day-instance.component';
import { DeleteReasonComponent } from '../../shared/delete-reason/delete-reason.component';
import { SymptomService } from '../../symptom/symptom.service';
import { MedicationService } from '../medication.service';
const logger = new Logger('tp2-conciliationDetail-medicationDetail');

@Component({
  selector: 'app-detail-days-medication',
  templateUrl: './detail-days-medication.component.html',
  styleUrls: ['./detail-days-medication.component.scss'],
})
export class DetailDaysMedicationComponent implements OnInit {
  @Input() subjectId: string = '';
  @ViewChild(TableDayInstanceComponent)
  tableDayInstanceComponent!: TableDayInstanceComponent;
  medicationObservations: any[] = [];
  isDayInstanceLoading: boolean = false;

  constructor(
    public symptomService: SymptomService,
    public conciliationService: ConciliationService,
    public medicationService: MedicationService,
    public dayInstanceService: DayInstanceService,
    public paginationService: PaginationService,
    private subjectsService: SubjectsService,
    private trialpalService: TrialpalService,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    try {
      await this.symptomService.getSymptomsMedicationAndMedicalAttention();
    } catch (error) {
      logger.error('ListMedication error', error);
      this.trialpalService.showServiceError(
        this.trialpalService.translateService.instant(
          'subject.tabheaderMedication'
        ),
        error
      );
    }
  }

  async isMedicationDaysLoading(coldDays: any[]) {
    if (this.medicationService.confMedications.length === 0) {
      await this.medicationService.getConfMedicationByConfEdiaryId();
    }
    await this.listMedicationBySubject();
    //Ordena la tabla segun el orden de los dias
    this.medicationService.tableMedications.sort((a: any, b: any) => {
      return a?.dayInstance?.confDay?.order - b?.dayInstance?.confDay?.order;
    });

    this.medicationService.tableMedications =
      this.medicationService.tableMedications?.map(
        (medication: any, index: number) => {
          medication.index = index;
          return medication;
        }
      );

    this.medicationService.medicationColDays = coldDays;
    this.medicationService.addMedicationComments();
  }

  /**
   * @desc listado de medicamentos
   */
  async listMedicationBySubject() {
    const dayInstances = this.filterDaysByMedicationRequired();

    //Obtiene todas las medicaciones del sujeto
    const _medications: MedicationInstanceBySubjectIdQuery =
      await this.subjectsService.medicationInstanceBySubjectId(this.subjectId);

    let medications: any[] = _medications?.items;

    //Limpia las variables
    this.medicationService.tableMedications = [];

    //Obtiene los medicamentos asociados a los dias
    for (const dayInstanceConf of dayInstances) {
      //Filtra los medicamentos que pertenezcan a los dias habilitados
      let medicationDay = medications?.filter((medication: any) => {
        return dayInstanceConf?.dayInstance?.id === medication?.dayInstanceId;
      });

      let configuration = this.medicationService.confMedications.find(
        (_configuration: any) =>
          _configuration?.confFormId === dayInstanceConf?.configuration?.id
      );

      //Cuando el dia tiene medicamentos registrados
      if (medicationDay.length !== 0) {
        //Obtiene la configuracion de medicamento del dia actual
        await this.setMedication(medicationDay, configuration);
      }
    }

    await this.getNewMedicationInstance();

    logger.debug('medications', this.medicationService.tableMedications);
  }

  filterDaysByMedicationRequired() {
    //Obtiene los dayInstances con los medicamentos activos
    return this.conciliationService.dayInstancesAndConfigurations.filter(
      (dayConf: any) => {
        const configuration = dayConf.configuration;
        //Valida si el medicamento del formulario actual se puede conciliar
        const IsMedicationConciliationAllowed =
          this.paginationService.isSectionReconciliationAllowed(
            'isMedicationRequired',
            configuration
          );
        return (
          configuration?.isMedicationRequired && IsMedicationConciliationAllowed
        );
      }
    );
  }

  isGivenToEnabled(configuration: any) {
    return (
      configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_OPEN_REASON ||
      configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_SYMPTOM_BINDING
    );
  }

  async setMedication(medications: any, configuration: any) {
    for await (const medication of medications) {
      const verifiedMedication =
        await this.medicationService.getVerifiedMedicationInstancesByMedicationId(
          medication.id
        );
      this.fillMedicationTable(
        medication,
        configuration,
        verifiedMedication[0]
      );
    }
  }

  fillMedicationTable(medication: any, configuration: any, verified: any) {
    const dayInstance =
      this.conciliationService.dayInstancesAndConfigurations.find(
        (confDay: any) => confDay?.dayInstance.id === medication?.dayInstanceId
      )?.dayInstance;

    const isDayBeforeToCurrentDate =
      this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);

    if (isDayBeforeToCurrentDate) {
      this.medicationService.tableMedications.push({
        configuration,
        dayInstance,
        confForm: dayInstance.confDay?.confForm,
        index: this.medicationService.tableMedications.length,
        isEdition: !!verified?.id,
        medication,
        verifiedMedication: verified,
        symptomsInstances: this.symptomService.getSymptoms(
          medication?.symptomsInstances,
          medication
        ),
        symptomsVerified: this.symptomService.getSymptoms(
          verified?.symptomsInstances,
          medication
        ),
        comments: {},
      });
    }
  }

  async getNewMedicationInstance() {
    const verifiedMedicationInstances: any[] =
      await this.medicationService.getVerifiedMedicationInstancesByMedicationId(
        this.conciliationService.NEW_INSTANCE_ID
      );
    for (let verifiedMedicationInstance of verifiedMedicationInstances) {
      let dayInstance: DayInstance =
        this.conciliationService.getDayInstanceByVerifiedDayInstanceId(
          verifiedMedicationInstance.verifiedDayInstanceId
        );

      const isDayBeforeToCurrentDate =
        this.conciliationService.isBeforeToCurrentDate(dayInstance?.finishDate);

      if (dayInstance?.id && isDayBeforeToCurrentDate) {
        const confFormId = dayInstance?.confDay?.confFormId ?? '';
        let configuration: any = this.medicationService.confMedications.find(
          (confMedication: any) => {
            return confMedication.confFormId === confFormId;
          }
        );

        const medication = this.medicationService.getMedicationInstanceEmpty(
          dayInstance.id
        );

        this.medicationService.tableMedications.push({
          configuration,
          dayInstance,
          confForm: dayInstance.confDay?.confForm,
          index: this.medicationService.tableMedications.length,
          isEdition: !!verifiedMedicationInstance?.id,
          medication: this.medicationService.getMedicationInstanceEmpty(
            dayInstance.id
          ),
          verifiedMedication: verifiedMedicationInstance,
          symptomsInstances: this.symptomService.getSymptoms(
            medication?.symptomsInstances,
            { dayInstanceId: dayInstance.id }
          ),
          symptomsVerified: this.symptomService.getSymptoms(
            verifiedMedicationInstance?.symptomsInstances,
            { dayInstanceId: dayInstance.id }
          ),
          comments: {},
        });
      }
    }
  }

  newMedicationInstance() {
    this.paginationService.selectedMedication({}, true);
  }

  confirmDeleteMedication() {
    const ref = this.dialogService.open(DeleteReasonComponent, {
      header: this.trialpalService.translateService.instant(
        'conciliation.confirmDelete'
      ),
      style: { 'min-width': '360px', width: '50%' },
    });
    ref.onClose.subscribe(async (deleteReason: string) => {
      if (deleteReason) {
        await this.changeMedicationToDeleteState(deleteReason);
      }
    });
  }

  async changeMedicationToDeleteState(deleteReason: string) {
    try {
      this.conciliationService.showSpinner(
        'transactionLoadingDelete',
        'medicationinstance.entity'
      );
      let medicationDelete = false;
      const currentMedication = this.medicationService.currentMedication;
      const confMedication =
        this.medicationService.currentMedication.configuration;
      const medication = currentMedication.medication;
      let verifiedMedication = currentMedication?.verifiedMedication;
      let data = this.medicationService.getMedicationDeleteData(deleteReason);
      if (verifiedMedication?.id) {
        let { verified, isDelete } =
          await this.medicationService.deleteVerifiedMedicationInstanceTable(
            medication,
            verifiedMedication,
            data
          );
        medicationDelete = isDelete;
        verifiedMedication = verified;
      } else {
        verifiedMedication =
          await this.medicationService.createVerifiedMedication(
            data,
            medication
          );
      }

      this.medicationService.currentMedication.verifiedMedication =
        verifiedMedication;

      await this.updateVerifiedDayInstance(
        confMedication,
        medication.dayInstanceId,
        deleteReason
      );
      await this.conciliationService.updateEdiaryPhaseState(false).catch();
      this.medicationService.addMedicationComments();
      this.medicationService.currentMedication = {};
      this.paginationService.selectedMedication({}, true);
      //Si fue borrado de la base de datos no se mostrara el medicamento (_delete = true)
      if (!medicationDelete) {
        this.paginationService.selectedMedication(currentMedication, false);
      }
      this.trialpalService.hideSpinner();
      this.conciliationService.messageDeleteSuccess();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.debug('eliminar medicamento', error);
    }
  }

  showDeleteButton() {
    const currentMedication = this.medicationService.currentMedication;
    const medicationReconciliationConfig =
      currentMedication?.confForm?.medicationReconciliationConfig;
    logger.debug(
      'medicationReconciliationConfig',
      medicationReconciliationConfig
    );
    if (medicationReconciliationConfig?.allowRemoveRecords === false)
      return false;
    const medication = currentMedication?.medication;
    const verifiedMedication = currentMedication?.verifiedMedication;
    if (
      (medication?.id === this.conciliationService.NEW_INSTANCE_ID &&
        !verifiedMedication?.id) ||
      (medication?.id && verifiedMedication?.state === InstanceState.DELETED) ||
      !medication?.id
    ) {
      return false;
    }

    return true;
  }

  //Actualiza la tabla de dia con la actualización
  async updateVerifiedDayInstance(
    confMedication: any,
    dayInstanceId: string,
    deleteReason: string
  ) {
    let isGivenToEnabled =
      confMedication?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_OPEN_REASON ||
      confMedication?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_SYMPTOM_BINDING;

    const medicationInput: any = {
      hasMedication: false,
      hasMedicationComment: deleteReason,
    };

    if (isGivenToEnabled) {
      medicationInput.medicationGivenTo = null;
      medicationInput.medicationGivenToComment = deleteReason;
    }

    //LLama la funcion para actualizar la precondicion del dia
    await this.dayInstanceService.updateVerifiedDayInstanceByDeleteInstances(
      dayInstanceId,
      'medication',
      'verifiedMedication',
      medicationInput,
      this.medicationService.tableMedications,
      this.tableDayInstanceComponent
    );
  }
}
