import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  NgForm,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'change-reason',
  templateUrl: './change-reason.component.html',
  styleUrls: ['./change-reason.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChangeReasonFormComponent),
      multi: true,
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ChangeReasonFormComponent implements OnInit, ControlValueAccessor {
  @Input() label: string = '';
  @Input() required: boolean = true;
  isOtherReason: boolean = false;
  principalReason: string = '';
  reason: string = '';

  otherReason: string = '';
  reasonsItems: any[] = [];
  reasonsKeys = [
    { value: Reason.TYPING_ERROR, label: 'general.changeReasons.typingError' },
    { value: Reason.OTHER_REASON, label: 'general.changeReasons.other' },
  ];

  OTHER_REASON: Reason = Reason.OTHER_REASON;
  TYPING_ERROR: Reason = Reason.TYPING_ERROR;

  isDisabled: boolean = false;

  onChange?: (reason: string) => void;
  onTouched?: () => void;
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.buildReasonKeys();
  }

  buildReasonKeys() {
    this.reasonsItems = [];
    for (const reason of this.reasonsKeys) {
      this.reasonsItems.push({
        value: reason.value,
        label: this.translateService.instant(reason.label),
      });
    }
  }

  writeValue(reason: string): void {
    this.reason = reason;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changePrincipalReason() {
    this.otherReason = '';
    const reason =
      this.principalReason !== Reason.OTHER_REASON ? this.principalReason : '';
    this.setChange(reason);
  }

  changeOtherReason() {
    this.setChange(this.otherReason);
  }

  setChange(reason: string) {
    if (this.onChange) {
      this.onChange(reason.trim());
    }
  }
}

enum Reason {
  TYPING_ERROR = 'typingError',
  OTHER_REASON = 'other',
}
