import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@aws-amplify/core';
import { Analytics } from 'aws-amplify';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConciliationService } from '../modules/conciliation/conciliation.service';
import { ProjectService } from '../modules/project/project.service';
import {
  GetProjectQuery,
  ProjectState,
} from '../modules/project/project.types';
import { ReportService } from '../modules/report/report.service';
import { SubjectsService } from '../modules/subjects/subjects.service';
import { Subject } from '../modules/subjects/subjects.types';
import { UserService } from '../modules/user/user.service';
import {
  TP2Permission,
  UserPermissionsService,
} from '../services/user-permissions-service';
import { Roles } from '../shared/global.variables';

const logger = new Logger('tp2-logger-navBarPage');
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  errorMessage = '';
  loginSubs: Subscription;
  isLoggedIn = false;
  items: MenuItem[] = [];
  projectError = false;
  userRole: string[] = [];
  userName: string = '';
  alerts: number = 0;
  private currentProjectSubs: Subscription;
  subjects: Subject[] = [];
  currentProject: GetProjectQuery | null = {
    __typename: 'Project',
    id: '',
    state: ProjectState.IN_DESIGN,
    code: ' --- ',
    name: '',
    sponsor: '',
    groups: [],
    phases: [],
    isEdiaryActivated: false,
    isReportsActivated: false,
    isICActivated: false,
    isChatActivated: false,
    createdAt: '',
    updatedAt: '',
    _version: 0,
    _lastChangedAt: 0,
    _lastUser: '',
  };
  subjectsFound: any[] = [];
  subjectLoading: boolean = false;
  hasSubjectListPermission: boolean = false; // Permiso para listar sujetos, ver auditoria, buscar sujeto
  hasSubjectReadPermission: boolean = false; // Permiso para ver detalle del sujeto

  constructor(
    private userService: UserService,
    private conciliationService: ConciliationService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private router: Router,
    private subjectsService: SubjectsService,
    public authService: AuthService,
    public trialpalService: TrialpalService,
    public reportService: ReportService,
    public userPermissionsService: UserPermissionsService
  ) {
    this.loginSubs = this.authService.userAuthenticated.subscribe(
      async (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        if (this.isLoggedIn) {
          await this.loadUserInfo();
          // const email = this.authService.getEmail(); //not implemented yet
          // this.listAlertInstances(email); //not implemented yet
        }
      }
    );
    this.reportService.alertsToReview.subscribe((alerts: number) => {
      this.alerts = alerts;
    });
    this.currentProjectSubs =
      this.projectService.currentProjectSubject.subscribe(
        (input: GetProjectQuery | null) => {
          this.handleProjectUpdate(input);
        }
      );
  }

  async setPermissions() {
    this.hasSubjectListPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectList,
        Roles.Admin,
      ]);

    this.hasSubjectReadPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectRead,
        Roles.Admin,
      ]);
  }

  private handleProjectUpdate(project: GetProjectQuery | null): void {
    this.subjects = [];
    this.currentProject = null;

    this.setPermissions().then();

    if (project?.id) {
      this.updateProjectDetails(project);
    } else {
      const storedProject = this.retrieveProjectFromLocalStorage();
      if (storedProject?.id) {
        this.updateProjectDetails(storedProject);
      }
    }
  }

  private updateProjectDetails(project: GetProjectQuery): void {
    this.currentProject = project;
    this.projectError = false;
    this.getSubjectsByProject(this.currentProject.id);
  }

  private retrieveProjectFromLocalStorage(): GetProjectQuery | null {
    const storedProject = localStorage.getItem('currentProject');
    return storedProject ? JSON.parse(storedProject) : null;
  }

  async ngOnInit() {
    this.isLoggedIn = this.authService.isAuthenticated();
    await this.loadUserInfo();
    await this.setPermissions();
  }

  ngOnDestroy(): void {
    this.loginSubs.unsubscribe();
    this.currentProjectSubs.unsubscribe();
  }

  async getSubjectsByProject(projectId: string): Promise<void> {
    this.subjectLoading = true;
    this.subjects = await this.subjectsService.getSearchSubjectsByProjectId(
      projectId
    );
    this.subjectLoading = false;
  }

  logout() {
    if (this.router.url.includes('conciliation')) {
      const isConciliationEdition =
        this.conciliationService.isConciliationEdition;

      const isPdfConciliationGenerated =
        this.conciliationService.isPdfConciliationGenerated;

      if (
        !isConciliationEdition ||
        (isConciliationEdition && !isPdfConciliationGenerated)
      ) {
        return this.conciliationOnExit();
      }
    }
    return this.confirmationLogout(0);
  }

  confirmationLogout(timer: number) {
    setTimeout(() => {
      this.confirmationService.confirm({
        message: this.trialpalService.translateService.instant(
          'navbarComponent.messageConfirmExit'
        ),
        accept: async () => {
          this.trialpalService.showSpinner(
            this.trialpalService.translateService.instant('general.loggingOut')
          );
          if (this.router.url.includes('conciliation')) {
            await this.conciliationService.conciliationUnLock();
          }
          Analytics.record({
            name: 'site_sign_out',
            attributes: {
              userSub: this.authService.user?.attributes?.sub,
              userName: this.authService.user?.username,
            },
          });
          this.authService.clearLastRoute(this.authService.getUsername()); //Limpia la ultima ruta navegada del usuario
          this.authService.logout();
          this.userPermissionsService.unsusbcribeUserAuth();
          this.trialpalService.hideSpinner();
        },
      });
    }, timer);
  }

  conciliationOnExit() {
    this.confirmationService.confirm({
      header: this.trialpalService.translateService.instant(
        'conciliation.conciliationExitHeader'
      ),
      message: this.trialpalService.translateService.instant(
        'conciliation.conciliationExitMessage'
      ),
      acceptLabel: this.trialpalService.translateService.instant(
        'conciliation.conciliationExitAcceptLabel'
      ),
      rejectLabel: this.trialpalService.translateService.instant(
        'conciliation.conciliationRejectLabel'
      ),
      accept: () => {
        this.confirmationLogout(500);
      },
    });
  }

  async loadUserInfo() {
    this.userName = sessionStorage.getItem('username') ?? '';
    const roles = await this.getUserByLogin(this.userName);
    this.userRole = this.getEnumsRolesUser(roles);
  }

  async getUserByLogin(login: string): Promise<string> {
    const user = await this.userService.userByLogin(login);
    if (user.items.length > 0) {
      return user?.items[0]?.role ?? '';
    }
    return '';
  }

  getEnumsRolesUser(roles: string): string[] {
    let splitRole = roles.split(',');
    let arrRole: string[] = [];
    if (splitRole) {
      for (const role of splitRole) {
        if (role) {
          arrRole.push(role);
        }
      }
    }
    return arrRole;
  }
  searchSubject(event: any): void {
    if (this.currentProject?.id) {
      this.projectError = false;
      const word = event.query.toString().toLowerCase().trim();
      this.subjectsFound = this.subjects.filter((subject: Subject) =>
        subject.subjectNumber.toLowerCase().includes(word)
      );
    } else {
      this.projectError = true;
      this.subjectsFound = [];
    }
  }
  goDetailSubject(s: any): void {
    if (this.hasSubjectReadPermission) {
      this.router
        .navigateByUrl('/subjects/', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/subjects/' + s.id + '/detail']);
        });
    }
  }
  goAlertsEmail() {
    this.router.navigate(['/alerts']);
  }
  async listAlertInstances(email: string) {
    if (!email) return [];
    const confAlerts = await this.reportService.getConfAlertsByEmail(email);
    return this.reportService.listAlertInstances(confAlerts);
  }
  goHome() {
    const customProjects = this.authService.getUserProjects();
    if (customProjects.length === 1) {
      this.router.navigateByUrl(`project/${customProjects[0]}/detail`);
    } else {
      this.router.navigate(['home']);
    }
  }
}
