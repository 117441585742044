import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { TP2Entites } from 'src/app/shared/global.variables';
import { VersionService } from '../version.service';

const logger = new Logger('tp2-logger-listUserVersions');

@Component({
  selector: 'app-list-user-versions',
  templateUrl: './list-user-versions.component.html',
  styleUrls: ['./list-user-versions.component.scss'],
})
export class ListUserVersionsComponent implements OnInit {
  projectId: any;
  users: any[] = [];
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  constructor(
    private versionService: VersionService,
    private route: ActivatedRoute,
    private router: Router,
    private trialpalService: TrialpalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.projectId = this.route.snapshot.params.projectId;
    await this.getUserVersions().then((users) => {
      this.users = users;
      if (this.users && this.users.length > 0) {
        this.dataTable = this.buildTPTableData(this.users);
      }
      logger.debug('data', this.dataTable);
    });
  }
  async getUserVersions(): Promise<any> {
    this.trialpalService.showSpinner('user.entityPlural', 'LIST');
    let users = this.versionService
      .listSubjectUserByProject(this.projectId)
      .then((users) => {
        this.trialpalService.hideSpinner();
        return users;
      });
    return users;
  }
  forward(): void {
    if (this.projectId) {
      this.router.navigate(['project/' + this.projectId + '/detail']);
    } else {
      this.router.navigate(['/home']);
    }
  }
  buildTPTableData(users: any[]): TPTableData {
    let newUsers = users.map((user) => {
      return {
        entity: TP2Entites.USER,
        appVersion: user.appVersion,
        login: user.login,
        subjects: user.subjects,
        site: user.sites,
      };
    });
    logger.debug('newUsers', newUsers);

    let cols = [
      {
        header:
          this.trialpalService.translateService.instant('version.version'),
        field: 'appVersion',
        type: TypeInput.TEXT,
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant('version.user'),
        field: 'login',
        type: TypeInput.TEXT,
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header:
          this.trialpalService.translateService.instant('version.subjects'),
        field: 'subjects',
        type: TypeInput.TEXT,
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant('version.site'),
        field: 'site',
        type: TypeInput.TEXT,
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];
    return {
      valueTable: newUsers,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: false,
      globalFilterFields: ['appVersion', 'login', 'subjects', 'site'],
      cols,
      menuOptions: [],
      customButton1: null,
    };
  }
}
