import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/modules/project/project.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { WindowTimeForm } from '../conciliation-configuration.component';
import { ConfDay, ConfForm } from '../../ediary.types';

@Component({
  selector: 'app-window-time-form',
  templateUrl: './window-time-form.component.html',
  styleUrls: ['./window-time-form.component.scss'],
})
export class WindowTimeFormComponent implements OnChanges {
  @Input() windowTimeForm: WindowTimeForm = {
    isEdition: true,
    name: '',
    phases: [],
    daysAfterVisit: undefined,
    id: '',
    totalWindowDays: undefined,
  };
  @Input() windowIndex: number = 0;
  @Input() windows: WindowTimeForm[] = [];
  @Input() confDays: ConfDay[] = [];
  @Input() confForms: ConfForm[] = [];

  @Output() isWindowEditionEmit: EventEmitter<boolean> = new EventEmitter();
  @Output() skipCreationEmit: EventEmitter<boolean> = new EventEmitter();
  @Output() isEnableFormEmit: EventEmitter<WindowTimeForm[]> =
    new EventEmitter();
  @Output() windowsEmit: EventEmitter<WindowTimeForm[]> = new EventEmitter();
  @Output() isWindowDeletionEmit: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup;

  phases: any[] = [];
  daysAfterVisit: any[] = [];
  display: boolean = false;
  isTotalWindowDaysError: boolean = false;
  totalWindowDay: number = 0;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private trialpalService: TrialpalService
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      phases: [null, [Validators.required]],
      daysAfterVisit: [null, [Validators.required]],
      totalWindowDays: [null, [Validators.required, Validators.min(1)]],
    });
  }

  ngOnChanges() {
    this.getPhases(); //Obtiene las visitas relaciondas al proyecto
    this.getDays(); //Obtiene los días despues de la visita

    this.form.patchValue({
      name: this.windowTimeForm?.name,
      phases: this.windowTimeForm?.phases,
      daysAfterVisit: this.windowTimeForm?.daysAfterVisit,
      totalWindowDays: this.windowTimeForm?.totalWindowDays,
    });

    this.disableForm();
  }

  getDays(): void {
    this.daysAfterVisit = [];
    for (let i = 1; i <= 30; i++) {
      this.daysAfterVisit.push({
        label: this.trialpalService.translateService.instant(
          'ediary.components.generalConfiguration.conciliation.daysAfterVisit',
          {
            day: i,
            s: i > 1 ? 's' : '',
          }
        ),
        value: i,
      });
    }
  }

  //Obtiene las visitas del proyecto y las filtra
  getPhases() {
    let phases: any[] = this.projectService.currentProject?.phases;
    //Valida si la phase ha sido añadida a una ventana previamente
    phases = phases.filter((phase: string) => {
      return !this.windows.find(
        (window: WindowTimeForm) =>
          window.phases.includes(phase) && window.id !== this.windowTimeForm.id
      );
    });

    //Crea el arreglo para mostrar en el dropdown
    this.phases = phases?.map((phase: string) => {
      return {
        label: this.trialpalService.dictionaryPipe.transform(phase),
        value: phase,
      };
    });
  }

  //Función que se encarga de editar/actualizar una ventana
  saveNewWindow() {
    if (this.form.valid && !this.isTotalWindowDaysError) {
      //Si es una ventana nueva le agrega un id
      if (!this.windowTimeForm.id) {
        this.windowTimeForm.id = `Identificator${this.windows.length}`;
      }

      this.windowTimeForm.isEdition = false;
      this.windowTimeForm = Object.assign(this.windowTimeForm, this.form.value);

      const windows = this.windows.map(
        (window: WindowTimeForm, index: number) => {
          return index === this.windowIndex ? this.windowTimeForm : window;
        }
      );
      this.skipCreationEmit.emit(false);
      this.isWindowEditionEmit.emit(false);
      this.windowsEmit.emit(windows);
      this.disableForm();

      //Llamar funcion que debe guardar this.windows en confEdiary y en la bd
    }
  }

  disableForm() {
    if (!this.windowTimeForm.isEdition) {
      this.form.disable();
    }
  }

  /**
   * Esta función habilita un formulario y deshabilita el resto de ventanas
   */
  enableForm() {
    this.form.enable();
    const windows = this.windows.map((window: WindowTimeForm) => {
      window.isEdition = window.id === this.windowTimeForm.id;
      return window;
    });
    this.isWindowEditionEmit.emit(true);
    this.isEnableFormEmit.emit(windows);
  }

  showDeleteWindow() {
    this.display = true;
  }

  /**
   * Esta función elimina una ventana de una matriz de ventanas y emite eventos para actualizar la
   * interfaz de usuario.
   */
  closeWindow() {
    const windows = this.windows.filter((_window: any, index: number) => {
      return index !== this.windowIndex;
    });
    this.isWindowEditionEmit.emit(false);
    this.skipCreationEmit.emit(true);
    this.windowsEmit.emit(windows);
  }

  //Función que valida el rango de días que estará activa la ventana como minimo
  validateTotalWindowDays(totalWindowDays: number) {
    const daysAfterVisit = this.form.controls['daysAfterVisit'].value ?? 0;
    const totalDays = daysAfterVisit + totalWindowDays;

    //Obtiene la cantidad de dias maximo de la ventana
    const { maxDay, maxDayError } = this.getMaxRangeOfDays();

    //Valida si el usuario digito un rango mayor a los días configurados segun la viisita
    if (totalDays <= maxDay) {
      this.isTotalWindowDaysError = true;
      this.form.controls['totalWindowDays'].markAsTouched();
      this.totalWindowDay = maxDayError;
    } else {
      this.totalWindowDay = 0;
      this.isTotalWindowDaysError = false;
    }
  }

  getMaxRangeOfDays() {
    const phases = this.form.controls['phases'].value ?? [];
    let maxDay = 1;
    let maxDayError = 0;

    //Obtiene las visitas actuales y valida cuantos dias asociadas tiene
    phases.forEach((phase: string) => {
      const days = this.confDays.filter((confDay: ConfDay) => {
        const isReconciliationRequired = this.isReconciliationRequired(confDay);
        return confDay.phases.includes(phase) && isReconciliationRequired;
      });

      //Valida cual es el día con el rango mayor
      days.forEach((confDay: ConfDay) => {
        const totalDays = confDay.startDay + confDay.endDay;
        const startTotalDays = confDay.startDay + 1;
        //Cuando el dia es de medicamentos o un dia de sintomas
        //Si es de sintomas se calcula el mayor y se suma 1 ()
        const rangeDay = confDay.endDay > 0 ? totalDays : startTotalDays;

        if (rangeDay > maxDay) {
          maxDay = rangeDay;
          maxDayError = confDay.endDay > 0 ? confDay.endDay : startTotalDays;
        }
      });
    });
    return { maxDay, maxDayError };
  }

  isReconciliationRequired(confDay: ConfDay) {
    const confForm = this.confForms.find(
      (_confForm: ConfForm) => _confForm.id === confDay.confFormId
    );

    return (
      confForm?.isReconciliationRequired ??
      confDay.confForm?.isReconciliationRequired
    );
  }
  deleteWindow() {
    const windows = this.windows.filter((_window: any, index: number) => {
      return index !== this.windowIndex;
    });
    this.isWindowDeletionEmit.emit(true);
    this.windowsEmit.emit(windows);
  }
}
