import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-step-guide',
  templateUrl: './form-step-guide.component.html',
  styleUrls: ['./form-step-guide.component.scss'],
})
export class FormStepGuideComponent {
  @Input() step: number = 0;
  @Input() description: string = '';
  @Input() additionalDescription: string = '';
}
