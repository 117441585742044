<div
  class="conciliation-message"
  *ngIf="!conciliationService.isPdfConciliationGenerated"
>
  <p-message
    class="warn"
    severity="warn"
    [text]="'conciliation.rememberSigned' | translate"
  ></p-message>
</div>
<div class="conciliation" [ngClass]="{ flex: paginationService.display }">
  <div
    class="container-tp2 p-my-0"
    [ngClass]="{ widthCalc: paginationService.display }"
  >
    <div class="p-fluid p-grid">
      <div class="p-col-12 centerTitleButton">
        <button
          pButton
          type="button"
          icon="pi pi-arrow-left"
          id="btn_forward"
          class="
            p-button-rounded
            p-button-secondary
            p-button-outlined
            p-button
            p-button-icon-only
          "
          (click)="forward()"
        ></button>
        <h2 class="titleDataConciliation">
          {{ 'conciliation.titleConciliation' | translate }}
        </h2>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-grid p-col-12">
        <div class="p-col-4">
          <p>
            {{ 'subject.pSubjectNumber' | translate }}:
            <strong>{{ subject?.subjectNumber }}</strong>
          </p>
          <p>
            {{ 'site.entity' | translate }}:
            <strong>{{ subject?.site?.name || '' | dictionary }} </strong>
          </p>
        </div>
        <div class="p-col-8">
          <p>
            {{ 'subject.pGroup' | translate }}:
            <strong> {{ subject?.group || '' | dictionary }}</strong>
          </p>
          <p>
            {{ 'conciliation.titleVisitConciliate' | translate }}:
            <strong>
              {{
                conciliationService?.ediaryPhaseInstance?.phase ?? ''
                  | dictionary
              }}
            </strong>
          </p>
        </div>
      </div>
      <div class="p-col-12 p-mb-3">
        <p>{{ 'conciliation.titleEdiary' | translate }}:</p>

        <p-table
          [resizableColumns]="true"
          styleClass="p-datatable-gridlines"
          responsiveLayout="scroll"
          [scrollable]="true"
          scrollDirection="horizontal"
          [columns]="colsDays"
          [value]="startDates"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                id="header"
                style="min-width: 200px; white-space: pre-line"
                *ngFor="let col of columns"
              >
                {{ col?.header | dictionary }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-startDate>
            <tr>
              <td style="min-width: 200px" *ngFor="let item of startDate">
                {{ item }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="p-grid p-col-12 p-p-0">
      <!-- tabs del hader -->
      <div class="p-col-12 p-md-12 contenedor-conciliation">
        <p-accordion
          [multiple]="conciliationService.getIsAutomaticReview()"
          (onOpen)="paginationService.onTabOpen($event)"
        >
          <p-accordionTab
            [selected]="isSectionSelected(sectionTemperature)"
            [hidden]="!paginationService.sections[0].isActive"
            [disabled]="
              paginationService.isSectionDisabled(sectionTemperature, false)
            "
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderTemperature' | translate }}
              </div>
              <div class="p-ml-auto p-col-2">
                <p-progressBar
                  class="customProgress"
                  [style]="{ height: '8px' }"
                  [value]="
                    paginationService.isTemperatureOpen
                      ? temperatureService.getProgressTemperature
                      : 0
                  "
                  *ngIf="!isAutomaticReview"
                ></p-progressBar>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-days-temperature
                [subjectId]="subjectId"
                [confEdiaryId]="conciliationService.confEdiaryId"
                [timeZoneOffset]="timeZoneOffset"
                (exportAuditRecord)="assignCurrentAuditRecord($event)"
              ></app-detail-days-temperature>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab
            [selected]="isSectionSelected(sectionSymptomLocal)"
            [disabled]="
              paginationService.isSectionDisabled(sectionSymptomLocal, false)
            "
            [hidden]="!paginationService.sections[1].isActive"
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderSymptomLocal' | translate }}
              </div>
              <div class="p-ml-auto p-col-2">
                <p-progressBar
                  class="customProgress"
                  [style]="{ height: '8px' }"
                  [value]="
                    paginationService.isSymptomLocalOpen
                      ? symptomService.getSymptomLocalProgressBar()
                      : 0
                  "
                  *ngIf="!isAutomaticReview"
                ></p-progressBar>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-symptom
                #local
                symptomType="LOCAL"
              ></app-detail-symptom>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab
            [selected]="isSectionSelected(sectionSymptomGeneral)"
            [disabled]="
              paginationService.isSectionDisabled(sectionSymptomGeneral, false)
            "
            [hidden]="!paginationService.sections[2].isActive"
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderSymptomGeneral' | translate }}
              </div>
              <div class="p-ml-auto p-col-2">
                <p-progressBar
                  class="customProgress"
                  [style]="{ height: '8px' }"
                  [value]="
                    paginationService.isSymptomGeneralOpen
                      ? symptomService.getSymptomGeneralProgressBar()
                      : 0
                  "
                  *ngIf="!isAutomaticReview"
                ></p-progressBar>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-symptom
                #general
                [symptomType]="'GENERAL'"
              ></app-detail-symptom>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab
            [selected]="isSectionSelected(sectionOtherSymptom)"
            [disabled]="
              paginationService.isSectionDisabled(sectionOtherSymptom, false)
            "
            [hidden]="!paginationService.sections[3].isActive"
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderOtherSymptom' | translate }}
              </div>
              <div class="p-ml-auto p-col-2">
                <p-progressBar
                  class="customProgress"
                  [style]="{ height: '8px' }"
                  [value]="
                    paginationService.isOtherSymptomOpen
                      ? symptomService.getOtherSymptomProgressBar
                      : 0
                  "
                  *ngIf="!isAutomaticReview"
                ></p-progressBar>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-other-symptoms></app-detail-other-symptoms>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab
            [selected]="isSectionSelected(sectionMedication)"
            [disabled]="
              paginationService.isSectionDisabled(sectionMedication, false)
            "
            [hidden]="!paginationService.sections[4].isActive"
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderMedication' | translate }}
              </div>
              <div class="p-ml-auto p-col-2">
                <p-progressBar
                  class="customProgress"
                  [style]="{ height: '8px' }"
                  [value]="
                    paginationService.isMedicationOpen
                      ? medicationService.getProgressMedication()
                      : 0
                  "
                  *ngIf="!isAutomaticReview"
                ></p-progressBar>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-days-medication
                [subjectId]="subjectId"
              ></app-detail-days-medication>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab
            [selected]="isSectionSelected(sectionMedicalAttention)"
            [disabled]="
              paginationService.isSectionDisabled(
                sectionMedicalAttention,
                false
              )
            "
            [hidden]="!paginationService.sections[5].isActive"
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderMedicalAssistance' | translate }}
              </div>
              <div class="p-ml-auto p-col-2">
                <p-progressBar
                  class="customProgress"
                  [style]="{ height: '8px' }"
                  [value]="
                    paginationService.isMedicalAttentionOpen
                      ? medicalAttentionService.getProgressMedicalAttentions()
                      : 0
                  "
                  *ngIf="!isAutomaticReview"
                ></p-progressBar>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-days-medical-attention
                [subjectId]="subjectId"
                [confEdiaryId]="conciliationService.confEdiaryId"
              ></app-detail-days-medical-attention>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab
            [selected]="isSectionSelected(sectionComment)"
            [disabled]="
              paginationService.isSectionDisabled(sectionComment, false)
            "
          >
            <ng-template pTemplate="header">
              <div>
                {{ 'subject.tabheaderComments' | translate }}
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-detail-days-comment></app-detail-days-comment>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <footer class="footer-container">
      <p-message
        class="warn"
        severity="warn"
        [text]="'conciliation.signatureWarning' | translate"
        *ngIf="!isSignatureButtonEnabled"
      ></p-message>

      <button
        id="btn_saveAndSignature"
        name="btn_saveAndSignature"
        class="p-button-secondary p-col-12"
        pButton
        type="button"
        label="{{ 'conciliation.buttonSaveAngSignature' | translate }}"
        [disabled]="isButtonSignatureDisabled()"
        (click)="saveAndSignature()"
      ></button>
    </footer>
  </div>
  <div
    class="sideBar"
    [ngClass]="{
      activeSide: paginationService.display,
      inactive: !paginationService.display
    }"
  >
    <div>
      <div class="sideHeader">
        <h3>
          {{
            paginationService.dayName
              ? (paginationService.dayName | dictionary)
              : ''
          }}
          {{ paginationService.dayName ? ' - ' : '' }}
          {{
            (paginationService.sectionName ? paginationService.sectionName : '')
              | translate
          }}
        </h3>
        <span class="sideClose" (click)="closeSide()">
          <span class="pi pi-times"></span>
        </span>
      </div>
      <div class="p-col-12 p-text-left p-pl-0" *ngIf="currentAuditRecord?.id">
        <button
          pButton
          label="{{ 'general.btnAuditTrace' | translate }}"
          type="button"
          icon="pi pi-file"
          iconPos="right"
          class="p-button-success pbutton"
          (click)="openAuditModal()"
        ></button>
      </div>
      <p *ngIf="paginationService.currentSection !== sectionComment">
        {{ 'conciliation.resumeSeccionForm' | translate }}
      </p>
      <p *ngIf="paginationService.currentSection === sectionComment">
        {{ 'conciliation.resumeSeccionComment' | translate }}
      </p>
      <p-message
        class="info p-mb-3"
        severity="info"
        [text]="'conciliation.mandatoryFieldsMessage' | translate"
      ></p-message>
      <div class="p-field p-col-12 p-pl-0" *ngIf="showSkeleton">
        <div *ngFor="let skeleton of [1, 1, 1]" class="p-mb-2">
          <p-skeleton
            borderRadius="8px"
            width="10rem"
            styleClass="p-mb-2"
          ></p-skeleton>
          <p-skeleton
            borderRadius="8px"
            height="2rem"
            styleClass="p-mb-2"
          ></p-skeleton>
          <p-skeleton borderRadius="8px" height="4rem"></p-skeleton>
        </div>

        <div class="skeleton-grid p-mb-2">
          <div *ngFor="let skeleton of [1, 1, 1]" class="p-mt-5">
            <p-skeleton borderRadius="8px" height="2rem"></p-skeleton>
          </div>
        </div>
        <p-skeleton borderRadius="8px" height="3rem"></p-skeleton>
      </div>
      <!--Seccion de editar datos de temperatura-->
      <container-element
        [ngSwitch]="paginationService.sectionIndex"
        *ngIf="!showSkeleton"
      >
        <ng-container *ngSwitchCase="0">
          <app-temperature
            [currentTemperatureDay]="temperatureService.currentTemperatureDay"
            [subjectId]="subjectId"
            [temperatures]="temperatureService.temperatures"
            [observations]="temperatureService.observations"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
          ></app-temperature>
        </ng-container>

        <!--Seccion de editar datos sintomas-->
        <ng-container *ngSwitchCase="1">
          <app-edit-symptom
            [currentSymptom]="symptomService.currentSymptom"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
            *ngIf="symptomService.currentSymptom"
          >
          </app-edit-symptom>
        </ng-container>
        <!--Seccion de editar datos de otros sintomas-->
        <ng-container *ngSwitchCase="2">
          <app-edit-other-symptoms
            [currentOtherSymptom]="symptomService.currentOtherSymptom"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
          ></app-edit-other-symptoms>
        </ng-container>
        <!--Seccion de editar datos de medicamentos-->
        <ng-container *ngSwitchCase="3">
          <app-edit-medication
            [currentMedication]="medicationService.currentMedication"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
          ></app-edit-medication>
        </ng-container>
        <!--Seccion de editar datos de medicamentos-->
        <ng-container *ngSwitchCase="4">
          <app-edit-day-instance
            [currentDayInstance]="dayInstanceService.currentDayInstance"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
          ></app-edit-day-instance>
        </ng-container>
        <!--Seccion de editar datos de Atenciones medicas-->
        <ng-container *ngSwitchCase="5">
          <app-edit-medical-attention
            [currentMedicalAttentionDay]="
              medicalAttentionService.medicalAttention
            "
            [subjectId]="subjectId"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
          ></app-edit-medical-attention>
        </ng-container>

        <!--Seccion de editar datos de comentarios-->
        <ng-container *ngSwitchCase="6">
          <app-edit-comment
            [currentComment]="conciliationService.currentComment"
            (exportAuditRecord)="assignCurrentAuditRecord($event)"
          ></app-edit-comment>
        </ng-container>
      </container-element>
    </div>
  </div>
</div>
