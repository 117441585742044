import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat',
  templateUrl: './formly-repeat.component.html',
  styleUrls: ['./formly-repeat.component.scss'],
})
export class FormlyRepeatComponent extends FieldArrayType implements OnInit {
  ngOnInit() {
    //Agrega un campo por defecto
    if (this.field.fieldGroup?.length === 0) {
      this.add();
    }
  }

  get fieldGroupSize(): number {
    return this.field.fieldGroup?.length ?? 0;
  }
}
