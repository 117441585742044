<div class="p-grid">
  <div class="p-col-12">
    <div class="p-grid p-fluid">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-2" *ngIf="hasDiaryDayCreate">
        <button
          pButton
          (click)="openForm()"
          icon="pi pi-plus"
          type="button"
          label="{{ 'general.add' | translate }}"
        ></button>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-ml-5" *ngIf="confDays.length === 0">
        <h4>{{ 'general.noInfo' | translate }}</h4>
      </div>
      <div *ngFor="let data of confDays" class="p-col-12 p-md-6 p-lg-4">
        <app-tpcard
          [tpCardData]="buildTPCardData(data)"
          (edit)="openForm($event)"
          (delete)="onDeleteConfDay($event)"
          (customAction)="openFormInReadMode($event)"
        >
        </app-tpcard>
      </div>
    </div>
  </div>
</div>
