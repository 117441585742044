import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'aws-amplify';
import * as moment from 'moment-timezone';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ReportService } from 'src/app/modules/report/report.service';
import {
  ConfReport,
  ConfReportBySubject,
  CreateConfReportBySubjectInput,
  ReportAvailableUserType,
  ReportInstance,
  ReportProgramationType,
  UpdateConfReportBySubjectInput,
} from 'src/app/modules/report/report.types';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import {
  PAGE_TOUR_DATA,
  PageTourDataType,
  PageTourService,
} from 'src/app/services/page-tour.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  InstanceState,
  SignatureEntityType,
} from 'src/app/services/trialpal.types';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { ViewPdfComponent } from 'src/app/shared/components/view-pdf/view-pdf.component';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';
import { SubjectsService } from '../../subjects.service';
import { Subject } from '../../subjects.types';
import { ManagePdfVersionsComponent } from '../manage-pdf-versions/manage-pdf-versions.component';
import { ReportExecutionComponent } from './report-execution/report-execution.component';
const logger = new Logger('tp2-logger-subjectReportPage');
@Component({
  selector: 'app-report-subject',
  templateUrl: './report-subject.component.html',
  styleUrls: ['./report-subject.component.scss'],
})
export class ReportSubjectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: string = '';
  @Input() timeZoneOffset = 0;
  @Input() isOpened: boolean = false;
  reportInstances: any[] = [];
  reportFilter: any[] = [];
  filter: string = '';
  time: string = '';
  options: string[] = [];
  times: any[] = [];
  confReportsBySubject: any[] = [];
  confReportsByProject: ConfReportBySubjectInterface[] = [];
  currentSubject?: Subject;
  showSpinner: boolean = true;
  //Variables para el desarrollo del PDF
  isFileUploaded: boolean = false;
  reportPDFUrl!: SafeResourceUrl;
  reportPDF = {
    reportPDF: '',
    projectId: '',
  };
  reportPDFName: string = '';
  reports = [];
  programationTypes: any[] = [];
  subjectId: string = '';
  tableViewMode: boolean = false;
  $reportInstanceSubscribe!: Subscription;

  hasSubjectReportPDFPermission: boolean = false; // Permiso para ver PDF y versiones del PDF
  hasSubjectEnableDisableReportPermission: boolean = false; // Permiso para habilitar y desabilitar reportes
  hasSubjectGenerateReportPDFPermission: boolean = false; // Permiso para generar PDF
  hasSubjectExecuteUpdateReportPermissions: boolean = false; // Permiso para ejecutar reporte
  DETAIL_SUBJECT_REPORTS_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_REPORTS_TOUR;
  DETAIL_SUBJECT_REPORTS_DETAIL_TOUR: PageTourDataType =
    PageTourDataType.DETAIL_SUBJECT_REPORTS_DETAIL_TOUR;
  currentIndexTabOpen: number = -1;
  currentIndexTabs: Set<number> = new Set();
  constructor(
    private subjectsService: SubjectsService,
    private activatedRoute: ActivatedRoute,
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe,
    public sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private readonly reportService: ReportService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private userPermissionsService: UserPermissionsService,
    private pageTourService: PageTourService
  ) {
    this.sanitizer = sanitizer;
    this.reportPDFUrl = this.transformToSecureUrl(this.reportPDFUrl);
    this.isFileUploaded = false;
    this.subjectId = this.activatedRoute.snapshot.params.subjectId;
    //Actualiza cada vez que hay una actualización de un reporte (Desde la app y del sujeto actual)
    this.$reportInstanceSubscribe = this.subjectsService
      .OnUpdateReportInstanceListener(this.subjectId)
      .subscribe((reportInstance: any) => {
        if (
          reportInstance?.id !==
          this.subjectsService.pdfGeneratedOnSite?.reportInstanceId
        ) {
          this.ngOnInit().then();
        }
      }) as Subscription;
  }

  ngOnDestroy(): void {
    this.$reportInstanceSubscribe?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.setPermissions();
      this.confReportsByProject = [];
      this.confReportsBySubject = [];
      this.reportFilter = [];
      this.time = '';
      this.filter = '';
      this.showSpinner = true;
      this.subjectId = this.activatedRoute.snapshot.params.subjectId;
      this.reportInstances =
        await this.subjectsService.reportInstanceBySubjectId(this.subjectId);
      logger.debug('reportInstanceBySubjectId response', this.reportInstances);
      this.reportInstances = this.subjectsService.orderInstancesDescending(
        this.reportInstances
      );
      this.getReportConsolidate();
      this.filterMaster();
      this.options = this.subjectsService.getStateReport();
      this.currentSubject = await this.subjectsService.getSubject(
        this.subjectId
      );
      await this.getConfReportsByProjectId(this.projectId);
      this.showSpinner = false;
      this.startTour(PageTourDataType.DETAIL_SUBJECT_REPORTS_TOUR);
    } catch (error) {
      logger.error('reportSubject ngOnInit', error);
      this.showSpinner = false;
    }
  }

  ngOnChanges() {
    this.startTour(PageTourDataType.DETAIL_SUBJECT_REPORTS_TOUR);
  }

  async setPermissions() {
    this.hasSubjectReportPDFPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectReportPDF,
        Roles.Admin,
      ]);

    this.hasSubjectEnableDisableReportPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectEnableDisableReport,
        Roles.Admin,
      ]);

    this.hasSubjectGenerateReportPDFPermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectGenerateReportPDF,
        Roles.Admin,
      ]);

    this.hasSubjectExecuteUpdateReportPermissions =
      await this.userPermissionsService.hasPermission([
        TP2Permission.SubjectExecuteUpdateReport,
        Roles.Admin,
      ]);
  }

  openModal(instance: any) {
    if (!this.hasSubjectReportPDFPermission) return;

    if (instance.__typename === 'ReportInstance') {
      this.selectPDF(instance);
    } else if (
      instance.data?.__typename === 'ConfReport' ||
      instance.__typename === 'ConfReport'
    ) {
      this.openModalConsolidate(instance.data || instance);
    }
  }

  selectPDF(p: any): void {
    if (p.pdfFiles) {
      const actualPDF = p.pdfFiles.length - 1;
      this.reportPDFName = p.pdfFiles[actualPDF].fileUrl;
      this.openPdfModal(this.reportPDFName, 'ReportCard');
    } else {
      this.showViaService();
    }
  }

  openModalConsolidate(p: any, fromGeneratePDF: boolean = false): void {
    if (!this.hasSubjectReportPDFPermission) return;

    this.reportPDFName =
      this.projectId + '/report/' + this.subjectId + '/' + p.id + '(0).pdf';
    this.openPdfModal(
      this.reportPDFName,
      'ReportCardConsolidate' + (fromGeneratePDF ? 'fromGenerate' : '')
    );
  }

  private async getConfReportsByProjectId(projectId: string): Promise<any> {
    this.confReportsBySubject =
      await this.reportService.getConfReportBySubjectId(this.subjectId);

    //Obtiene todos los reportes asociados al proyecto
    const confReportsByProject =
      await this.reportService.getConfReportByProjectId(projectId);

    if (this.currentSubject) {
      //Filtra solo los reportes distintos a deleted y pertenezcan al grupo del sujeto
      const confReports =
        confReportsByProject?.filter((confReport: any) =>
          confReport?.groups.includes(this.currentSubject?.group ?? '')
        ) ?? [];

      await this.buildReports(confReports);
    }
    logger.debug('reports', this.confReportsByProject);
  }

  async buildReports(confReportsByProject: any[]) {
    for (let confReport of confReportsByProject) {
      const reportInstances = this.reportInstancesByConfReport(confReport?.id);

      //Busca si existe un reporte controlado asociado, si no lo crea
      let confReportBySubject = await this.getConfReportBySubjectByConfReportId(
        confReport
      );

      this.confReportsByProject.push({
        confReport: confReport,
        confReportBySubject: confReportBySubject,
        reportInstances: reportInstances,
        dataCard: this.buildCards(
          reportInstances,
          confReport,
          confReportBySubject
        ),
        dataTable: this.buildTPTableData(
          reportInstances,
          confReport,
          confReportBySubject
        ),
      });
    }
  }

  buildCards(reportInstances: any, confReport: any, confReportBySubject: any) {
    const dataCard = [];
    for (let reportInstance of reportInstances) {
      dataCard.push(
        this.buildTPCardData(reportInstance, confReport, confReportBySubject)
      );
    }
    if (confReport) {
      dataCard.push(this.buildTPCardDataReports(confReport));
    }

    return dataCard;
  }
  async createConfReportBySujectIdAndConfReportId(
    confReportId: string
  ): Promise<any> {
    let newItem: CreateConfReportBySubjectInput = {
      subjectId: this.subjectId,
      confReportId: confReportId,
      isEnabled: false,
      _lastUser: '',
    };
    const confReportBySubject =
      await this.reportService.createConfReportBySubject(newItem);
    this.confReportsBySubject.push(confReportBySubject);
    return confReportBySubject;
  }

  getReportConsolidate(): void {
    this.reportService
      .getConfReportByProjectId(this.projectId)
      .then((confReports: any) => {
        logger.debug('listReport response', confReports);
        this.reports = confReports.filter(
          (e: ConfReport) =>
            !e._deleted && this.verifyConfReportExistenceInSubject(e)
        );
      })
      .catch((error: Error) => {
        logger.error('listReport error', error);
      });

    this.reportService
      .getProgramationTypes()
      .then((res) => (this.programationTypes = res));
  }

  verifyConfReportExistenceInSubject(res: any): number {
    return this.reportInstances.filter((r: any) => r.confReportId === res.id)
      .length;
  }

  transformToSecureUrl(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  isReaderView(isGenerated: boolean) {
    if (this.authService.isReader() && !isGenerated) {
      this.trialpalService.messageService.clear();
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary: this.trialpalService.translateService.instant(
          'user.messageWarningReportPDF.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'user.messageWarningReportPDF.detail'
        ),
      });
      return false;
    }
    return true;
  }

  buildTPCardDataReports(res: any): TPCardData {
    const cardObject: TPCardData = {
      data: res,
      header: this.trialpalService.dictionaryPipe.transform(res.reportTitle),
      section1: this.programationTypes.find(
        (x) => x.value === res.programationType
      ).name,
      cardColor: '#55E5AB',
      showActivateButton: false,
      cardIsButton: this.hasSubjectReportPDFPermission,
      isHeaderDictionary: true,
      entityAction:
        this.trialpalService.translateService.instant('report.entity'),
      showCustomButton2: this.hasSubjectGenerateReportPDFPermission,
      iconCustomButton2: 'pi pi-file-pdf',
      tooltipCustomButtom2: this.trialpalService.translateService.instant(
        'general.generatePDFEntity'
      ),
      messageConfirmCustomButton2:
        this.trialpalService.translateService.instant('general.generatePDF'),
    };

    if (res.reportIcon) {
      cardObject.image = `icons/${res.reportIcon}.png`;
    }
    return cardObject;
  }

  buildTPTableData(
    reportInstances: any,
    confReport: any,
    confReportBySubject: any
  ): TPTableData {
    let newReportInstances = [];
    newReportInstances.push({
      data: confReport ?? {},
      entity: TP2Entites.REPORTINSTANCE,
      greenColor: true,
      score: 'N/A',
      status:
        this.trialpalService.dictionaryPipe.transform(
          confReport?.reportTitle
        ) ?? '',
      plannedDate: this.trialpalService.translateService.instant(
        'subject.ConsolidatedSurveillanceReports'
      ),
      completeDate: confReport
        ? this.programationTypes.find(
            (x) => x.value === confReport.programationType
          ).name
        : '',
      hideCustomButton1: true,
      hideCustomButton2: !this.hasSubjectGenerateReportPDFPermission,
      hideCustomButton3: true,
    });

    newReportInstances = newReportInstances.concat(
      reportInstances.map((reportInstance: any) => {
        const showButtonExecution = this.showReportExecutionButtonInCard(
          confReport,
          reportInstance,
          confReportBySubject
        );
        return {
          data: reportInstance,
          status: this.trialpalService.translateService.instant(
            'general.instanceState.' + reportInstance.state
          ),
          plannedDate:
            this.transformDatePipe.transform(
              reportInstance?.plannedDate?.substring(0, 10)
            ) || 'N/A',
          completeDate:
            this.transformDatePipe.transform(reportInstance.completeDate) ||
            'N/A',
          greenColor: false,
          hideCustomButton1:
            (!this.hasSubjectGenerateReportPDFPermission &&
              !this.hasSubjectReportPDFPermission) ||
            reportInstance?.pdfFiles?.length === 0,
          hideCustomButton2:
            reportInstance.state !== InstanceState.COMPLETED ||
            !this.hasSubjectGenerateReportPDFPermission,

          hideCustomButton3: !showButtonExecution,
          score: reportInstance?.score ? String(reportInstance.score) : 'N/A',
        };
      })
    );
    const confFormJson = this.getJsonConfiguration(confReport);
    const reportScoreLabel = this.trialpalService.translateService.instant(
      'report.labelJsonFormScore'
    );
    const hasGroupEvaluation = this.hasGroupEvaluation(confFormJson[0]);

    let cols = [
      {
        header:
          this.trialpalService.translateService.instant('report.stateReport'),
        type: TypeInput.TEXT,
        field: 'status',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'subject.plannedDate'
        ),
        type: TypeInput.TEXT,
        field: 'plannedDate',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'subject.completedDate'
        ),
        type: TypeInput.TEXT,
        field: 'completeDate',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];

    if (hasGroupEvaluation) {
      cols.push({
        header: reportScoreLabel,
        type: TypeInput.NUMERIC,
        field: 'score',
        showBasicSearch: true,
        showFilterComplete: false,
      });
    }

    const tableConfig = {
      valueTable: newReportInstances,
      customButton1: {
        icon: 'pi pi-replay',
        tooltipCustomButtom1: this.trialpalService.translateService.instant(
          'general.versionManagement'
        ),
      },
      customButton2: {
        icon: 'pi pi-file-pdf',
        tooltipCustomButtom2: this.trialpalService.translateService.instant(
          'general.generatePDFEntity'
        ),
        messageConfirmCustomButton2:
          this.trialpalService.translateService.instant('general.generatePDF'),
      },
      customButton3: {
        icon: 'pi pi-play',
        tooltipCustomButtom3: this.trialpalService.translateService.instant(
          'report.executeReport'
        ),
      },
      showGeneralSearch: true,
      showResetFilter: true,
      actionsTitle:
        this.trialpalService.translateService.instant('general.actions'),
      globalFilterFields: ['status', 'plannedDate', 'completeDate', 'score'],
      cols,
      menuOptions: [],
    } as TPTableData;
    return tableConfig;
  }

  buildTPCardData(
    reportInstance: any,
    confReport: any,
    confReportBySubject: any
  ): TPCardData {
    let plannedDate: any = reportInstance.plannedDate
      ? this.transformDatePipe.transform(
          reportInstance?.plannedDate?.substring(0, 10)
        )
      : this.trialpalService.translateService.instant('subject.PENDING');

    let completeDate: any = reportInstance.completeDate
      ? this.transformDatePipe.transformToSiteHour(
          reportInstance.completeDate,
          this.timeZoneOffset
        )
      : this.trialpalService.translateService.instant('subject.PENDING');
    const showReportExecutionButtonInCard =
      this.showReportExecutionButtonInCard(
        confReport,
        reportInstance,
        confReportBySubject
      );
    const confFormJson = this.getJsonConfiguration(confReport);
    const showScoreLabel = this.showScoreLabel(reportInstance, confFormJson[0]);

    const reportScoreLabel = this.trialpalService.translateService.instant(
      'report.labelJsonFormScore'
    );

    return {
      ...reportInstance,
      reportInstance: reportInstance,
      data: reportInstance.type,
      header: '',
      state: this.trialpalService.translateService.instant(
        'subject.enums.medicalAttention.' + reportInstance.state
      ),
      section1: plannedDate,
      icon1: 'pi pi-calendar',
      section2: completeDate,
      icon2: 'pi pi-calendar-times',
      icon3: showScoreLabel ? 'pi pi-check-square' : '',
      section3: showScoreLabel
        ? `${reportScoreLabel}: ${reportInstance?.score}`
        : '',
      styleColorState: 'green',
      showEditButton: false,
      showActivateButton: false,
      showDeleteButton: false,
      cardIsButton: this.hasSubjectReportPDFPermission,
      showCustomButton:
        (this.hasSubjectGenerateReportPDFPermission ||
          this.hasSubjectReportPDFPermission) &&
        reportInstance?.pdfFiles?.length > 0,
      iconCustomButton: 'pi pi-replay',
      tooltipCustomButtom: this.trialpalService.translateService.instant(
        'general.versionManagement'
      ),
      showCustomButton2:
        this.hasSubjectGenerateReportPDFPermission &&
        reportInstance?.state === InstanceState.COMPLETED,
      iconCustomButton2: 'pi pi-file-pdf',
      tooltipCustomButtom2: this.trialpalService.translateService.instant(
        'general.generatePDFEntity'
      ),
      showCustomButton3: showReportExecutionButtonInCard,
      iconCustomButton3: 'pi pi-caret-right',
      tooltipCustomButtom3: this.trialpalService.translateService.instant(
        'report.executeReport'
      ),
      messageConfirmCustomButton2:
        this.trialpalService.translateService.instant('general.generatePDF'),
      objectAction: reportInstance.name,
    };
  }

  getJsonConfiguration(confReport: ConfReport) {
    try {
      const confJSON = this.trialpalService.dictionaryPipe.transform(
        confReport?.jsonForm ?? '[]'
      );
      return JSON.parse(confJSON ?? '[]');
    } catch (error) {
      logger.error(error);
    }
    return [];
  }

  showScoreLabel(reportInstance: ReportInstance, confFormJson: any): boolean {
    if (!reportInstance?.score) return false;
    return this.hasGroupEvaluation(confFormJson);
  }

  showReportExecutionButtonInCard(
    confReport: ConfReport,
    reportInstance: any,
    confReportBySubject: ConfReportBySubject
  ): boolean {
    //Valida si el rol actual puede ejecutar reporte
    const isExecutableByInvestigator =
      this.isExecutableByInvestigator(confReport);
    const shouldIncludeReportCardBasedOnCurrentDay =
      this.shouldIncludeReportCardBasedOnCurrentDay(confReport);
    const shouldIncludeReportCardBasedOnDateRange =
      this.shouldIncludeReportCardBasedOnDateRange(confReport);
    const reportValidations = [
      this.hasSubjectExecuteUpdateReportPermissions,
      isExecutableByInvestigator,
      shouldIncludeReportCardBasedOnCurrentDay,
      shouldIncludeReportCardBasedOnDateRange,
    ];
    if (reportValidations.includes(false)) return false;

    if (confReport.isControlledBySite && !confReportBySubject?.isEnabled) {
      return false;
    }

    //Si el reporte fue privamente creado por un sujeto no lo permitira ejecutar desde el site
    const userType = reportInstance?.reportedBy?.userType;
    if (userType === ReportAvailableUserType.SUBJECT) return false;

    //Si es a demanda y tiene una instancia de reporte actual asociada (Reporte completado actual)
    if (confReport.programationType === ReportProgramationType.ON_DEMAND) {
      return this.validateConfReportOnDemandReportExecution(
        confReport,
        reportInstance
      );
    }

    //Valida si el reporte esta programado para la fecha actual
    if (confReport.programationType === ReportProgramationType.PLANNED_DAYS) {
      let isReportBeforeToCurrentDate = false;
      if (confReport.allowReportBackDating) {
        isReportBeforeToCurrentDate = this.isBeforeToCurrentDate(
          reportInstance.plannedDate ?? ''
        );
      }
      return (
        moment(reportInstance.plannedDate.substring(0, 10)).isSame(
          moment().format('yyyy-MM-DD')
        ) || isReportBeforeToCurrentDate
      );
    }

    return false;
  }

  /**
   * Verifica si una tarjeta de reporte debe ser incluida según el día actual y las configuraciones.
   *
   * @param confReport - El objeto de configuración del reporte que contiene las configuraciones para la tarjeta de reporte.
   * @returns boolean - Retorna true si la tarjeta de reporte debe ser incluida, de lo contrario retorna false.
   */
  shouldIncludeReportCardBasedOnCurrentDay(confReport: ConfReport): boolean {
    if (!confReport?.isDaysOfWeekRequired) {
      return true;
    }

    const currentDay = this.subjectsService.getCurrentDay();
    return confReport?.availableDays?.includes(currentDay) ?? false;
  }

  /**
   * Verifica si una tarjeta de reporte debe ser incluida según el rango de fechas y las configuraciones.
   *
   * @param confReport - El objeto de configuración del reporte que contiene las configuraciones para la tarjeta de reporte.
   * @returns boolean - Retorna true si la tarjeta de reporte debe ser incluida, de lo contrario retorna false.
   */
  shouldIncludeReportCardBasedOnDateRange(confReport: ConfReport): boolean {
    // Si no se requiere limite de fechas, incluir la tarjeta.
    if (!confReport?.isDateRangeLimitRequired) {
      return true;
    }
    return this.subjectsService.isInTheDateRange(
      confReport?.startDate,
      confReport?.endDate
    );
  }

  isBeforeToCurrentDate(date: string = '') {
    if (date) {
      return moment(moment(date.substring(0, 10))).isBefore(moment(), 'day');
    }
    return false;
  }

  async getConfReportBySubjectByConfReportId(
    confReport: ConfReport
  ): Promise<any> {
    let confReportBySubject = this.confReportsBySubject?.find(
      (_confReportBySubject: any) =>
        _confReportBySubject?.confReportId === confReport.id
    );

    //Si no existe lo crea y ademas valida que el reporte tenga activo la opcion de controlado por sitio
    if (!confReportBySubject?.id && confReport?.isControlledBySite) {
      confReportBySubject =
        await this.createConfReportBySujectIdAndConfReportId(confReport.id);
    }

    return confReportBySubject;
  }

  //Valida si el reporte a demanda enviado debe poder ejecutarse
  validateConfReportOnDemandReportExecution(
    confReport: ConfReport,
    reportInstance: any
  ): boolean {
    if (reportInstance?.id && confReport.instancePerDay) {
      return moment(reportInstance.plannedDate.substring(0, 10)).isSame(
        moment().format('yyyy-MM-DD')
      );
    }
    const _reportInstance =
      this.getReportInstanceBySubjectAndConfAndDate(confReport);
    return confReport.instancePerDay && !_reportInstance;
  }

  //Función que retorna si el botón general de completar reporte debe ser mostrado
  //Validará si un reporte es a demanda y no cuenta con reportes Asociados al día actual
  showGeneralReportExecutionButton(item: any) {
    //Valida si el rol actual puede ejecutar reporte
    const confReport: ConfReport = item?.confReport;
    const isAllowedToExecuteReport = this.isUserAuthorized();
    const shouldIncludeReportCardBasedOnCurrentDay =
      this.shouldIncludeReportCardBasedOnCurrentDay(confReport);
    const shouldIncludeReportCardBasedOnDateRange =
      this.shouldIncludeReportCardBasedOnDateRange(confReport);
    const reportValidations = [
      isAllowedToExecuteReport,
      shouldIncludeReportCardBasedOnCurrentDay,
      shouldIncludeReportCardBasedOnDateRange,
    ];
    if (reportValidations.includes(false)) return false;
    const confReportBySubject = item?.confReportBySubject;

    //No muestra el botón general si el reporte no puede ser ejecutado por el investigador
    if (!this.isExecutableByInvestigator(confReport)) return false;

    //Si no esta activo el controlado por el sitio no muestra el botón general
    if (this.isControlledBySite(confReportBySubject, confReport)) return false;

    //Valida si existe una reportInstance asociado al reporte y si tiene fecha actual, no se mostrará el boton
    if (confReport.programationType === ReportProgramationType.ON_DEMAND) {
      const reportInstance =
        this.getReportInstanceBySubjectAndConfAndDate(confReport);

      //No se podrá ejecutar si ya fue reportado por un sujeto
      const userType = reportInstance?.reportedBy?.userType;
      if (userType === ReportAvailableUserType.SUBJECT) return false;

      return !reportInstance; //Si existe un reportInstance no muestra el botón general
    }
    return false;
  }

  isControlledBySite(confReportBySubject: any, confReport: ConfReport) {
    return (
      confReportBySubject &&
      !confReportBySubject?.isEnabled &&
      confReport?.isControlledBySite
    );
  }

  //Obtiene si existe un reportInstance asociado al reporte a demanda
  getReportInstanceBySubjectAndConfAndDate(
    confReport: ConfReport
  ): ReportInstance | undefined {
    //Si es un reporte a demanda sin instancia por día, retorna undefined
    if (!confReport.instancePerDay) return undefined;
    //Busca si existe una instancia creada para el reporte a demanda
    return this.reportInstances.find(
      (reportInstance: ReportInstance) =>
        reportInstance.confReportId === confReport.id &&
        reportInstance.plannedDate?.startsWith(moment().format('yyyy-MM-DD'))
    );
  }

  isUserAuthorized() {
    return (
      this.authService.isAdmin() ||
      this.authService.isCoordinator() ||
      this.authService.isInvestigator()
    );
  }

  filterMaster(): void {
    this.time = '';
    this.times = [];
    this.reportFilter = [];
    if (this.filter === 'planned') {
      for (const r of this.reportInstances) {
        if (!r.completeDate && r.plannedDate) {
          this.eventDate('ALL', true);
        }
      }
      this.times = this.subjectsService.getPeriodPlannedReport();
    } else if (this.filter === 'completed') {
      for (const r of this.reportInstances) {
        if (r.completeDate) {
          this.eventDate('ALL', true);
        }
      }
      this.times = this.subjectsService.getPeriodCompleteReport();
    } else {
      this.filter = 'planned';
      this.eventDate('ALL', true);
      this.filter = 'completed';
      this.eventDate('ALL', false);
      this.filter = '';
    }
    this.updateReportInstancesAfterFilter();
  }

  eventDate(date: string, erase: boolean): any {
    const event = {
      value: date,
    };
    this.filterDateMaster(event, erase);
  }

  filterDateMaster(event?: any, erase?: boolean): void {
    logger.debug('filterDateMaster', event);
    const today = new Date();
    if (erase === true) this.reportFilter = [];
    const year = this.transformDatePipe.getYear(today);
    const month = this.transformDatePipe.getMonth(today);
    const day = this.transformDatePipe.getDay(today);
    const dayTotal = year * 365 + month * 30 + day;
    if (this.filter === 'planned') {
      for (const r of this.reportInstances) {
        if (!r.completeDate && r.plannedDate) {
          const reportYear = this.transformDatePipe.getYear(r.plannedDate);
          const reportMonth = this.transformDatePipe.getMonth(r.plannedDate);
          const reportDay = this.transformDatePipe.getDay(r.plannedDate) + 1;
          const dayReportTotal =
            reportYear * 365 + reportMonth * 30 + reportDay;

          const value = dayReportTotal - dayTotal;
          this.AddtoFilter(event.value, value, r);
        }
      }
    } else if (this.filter === 'completed') {
      for (const r of this.reportInstances) {
        const reportYear = this.transformDatePipe.getYear(r.completeDate);
        const reportMonth = this.transformDatePipe.getMonth(r.completeDate);
        const reportDay = this.transformDatePipe.getDay(r.completeDate);
        const dayReportTotal = reportYear * 365 + reportMonth * 30 + reportDay;
        if (r.completeDate) {
          const value = dayTotal - dayReportTotal;
          this.AddtoFilter(event.value, value, r);
        }
      }
    }
  }

  filterAndUpdateDateMaster(event?: any, erase?: boolean) {
    this.filterDateMaster(event, erase);
    this.updateReportInstancesAfterFilter();
  }

  updateReportInstancesAfterFilter(): void {
    for (const confReport of this.confReportsByProject) {
      //toma solo los objetos de confReport.reportInstances en los que el id del objeto es igual al id del objeto del reportFilter
      const reportInstances = confReport.reportInstances.filter((report: any) =>
        this.reportFilter.some((filter) => filter.id === report.id)
      );
      const confReportBySubjectId = confReport.confReportBySubject;
      const confReportFilter = this.filterConfReport(confReport.confReport.id);
      logger.debug('reportInstances', reportInstances);
      confReport.dataCard = this.buildCards(
        reportInstances,
        confReportFilter[0],
        confReportBySubjectId
      );
      confReport.dataTable = this.buildTPTableData(
        reportInstances,
        confReportFilter[0],
        confReportBySubjectId
      );
    }
  }

  AddtoFilter(event: any, value: any, report: any): void {
    const criteria = [
      {
        event: '1W',
        value: 7,
      },
      {
        event: '2W',
        value: 14,
      },
      {
        event: '1M',
        value: 30,
      },
      {
        event: '2M',
        value: 60,
      },
      {
        event: '6M',
        value: 180,
      },
      {
        event: '1Y',
        value: 365,
      },
      {
        event: '5Y',
        value: 1825,
      },
    ];
    if (event == 'ALL') {
      this.reportFilter.push(report);
    }
    if (
      criteria.find((e) => event === e.event && value <= e.value && value >= 0)
    ) {
      this.reportFilter.push(report);
    }
  }

  showViaService(isConsolidate?: boolean, confReportId?: any) {
    if (isConsolidate) {
      this.generatePDFConsolidate(confReportId);
    } else {
      this.trialpalService.messageService.add({
        severity: 'warn',
        summary: 'PDF',
        detail: this.trialpalService.translateService.instant('subject.notPDF'),
      });
    }
  }

  /**
   * Filtra los reportes por su Id de configuración
   * @param confReportId
   * @returns
   */
  reportInstancesByConfReport(confReportId: any): any[] {
    return this.reportFilter.filter((e) => e.confReportId === confReportId);
  }

  filterConfReport(report: string) {
    return this.reports.filter((x: any) => x.id === report);
  }

  async updateConfReportBySubject(
    confReportsByProject: ConfReportBySubjectInterface
  ) {
    try {
      this.trialpalService.showSpinner('general.savingInfo');
      const confReportBySubject = confReportsByProject?.confReportBySubject;
      const ConfReportBySubject: UpdateConfReportBySubjectInput = {
        id: confReportBySubject.id ?? '',
        isEnabled: confReportBySubject?.isEnabled,
        _version: confReportBySubject._version,
      };

      const updateConfReportBySubject =
        await this.reportService.updateConfReportBySubject(ConfReportBySubject);

      confReportsByProject.confReportBySubject = updateConfReportBySubject;

      //Actualiza el arreglo de confReportsBySubject
      this.confReportsBySubject = this.confReportsBySubject.map(
        (confReportBySubject: any) => {
          return confReportBySubject?.id === updateConfReportBySubject?.id
            ? updateConfReportBySubject
            : confReportBySubject;
        }
      );

      this.updateShowReportExecutionButton(confReportsByProject);
      this.trialpalService.hideSpinner();
    } catch (error) {
      this.trialpalService.hideSpinner();
      logger.error('updateConfReportBySubject', error);
    }
  }

  //Actualiza si se debe mostrar el botón de ejecutar en las tarjetas según si esta controlado por el sitio activo
  updateShowReportExecutionButton(confReport: ConfReportBySubjectInterface) {
    //Actualiza si se debe mostrar el botón de ejecutar en las tarjetas del reporte actual
    confReport.dataCard?.forEach((card: any) => {
      if (card?.reportInstance?.__typename === 'ReportInstance') {
        const showReportExecutionButtonInCard =
          this.showReportExecutionButtonInCard(
            confReport.confReport,
            card.reportInstance,
            confReport.confReportBySubject
          );
        card.showCustomButton3 = showReportExecutionButtonInCard;
      }
    });

    //Actualiza si se debe mostrar el botón de ejecutar en la tabla del reporte actual
    confReport.dataTable?.valueTable?.forEach((card: any) => {
      if (card?.data?.__typename === 'ReportInstance') {
        const showReportExecutionButtonInCard =
          this.showReportExecutionButtonInCard(
            confReport.confReport,
            card.data,
            confReport.confReportBySubject
          );
        card.hideCustomButton3 = !showReportExecutionButtonInCard;
      }
    });
  }

  managePdfVersions(event: any): void {
    this.dialogService.open(ManagePdfVersionsComponent, {
      header: this.trialpalService.translateService.instant(
        'general.versionManagement'
      ),
      width: '50%',
      data: {
        id: 'id',
        instance: event,
        timeZoneOffset: this.timeZoneOffset,
        entity: SignatureEntityType.REPORT,
      },
      dismissableMask: false,
    });
  }

  async createReportCardPDFComplete(confReportId: any, reportInstanceId?: any) {
    try {
      this.trialpalService.showSpinner('PDF', 'CREATE');

      if (confReportId === reportInstanceId) reportInstanceId = null; // Verifica si el reportInstanceId tiene el id del confReport
      console.error('reportInstanceId', reportInstanceId);
      if (reportInstanceId) {
        this.subjectsService.pdfGeneratedOnSite = {
          reportInstanceId: reportInstanceId,
        };
        await this.subjectsService.managePDFandAlert(
          undefined,
          reportInstanceId
        );
        await this.updateReportInstanceInConfReportsByProject(
          confReportId,
          reportInstanceId
        );
      } else {
        const reportCardPDF =
          await this.subjectsService.createReportCardPDFConsolidate(
            this.subjectId,
            confReportId
          );
        if (reportCardPDF) {
          this.openModalConsolidate({ id: confReportId }, true);
        }
      }
      this.trialpalService.showMutationSuccess('PDF', 'CREATE');
      this.trialpalService.hideSpinner();
    } catch (error) {
      this.trialpalService.showServiceError('PDF', error);
    }
  }

  async updateReportInstanceInConfReportsByProject(
    confReportId: any,
    reportInstanceId?: any
  ) {
    const index = this.confReportsByProject.findIndex(
      (x) => x?.confReport?.id === confReportId
    );

    const currentConfReportsByProject = this.confReportsByProject[index];
    if (!currentConfReportsByProject) return;
    const reportIndex = currentConfReportsByProject.reportInstances.findIndex(
      (x: any) => x.id === reportInstanceId
    );
    currentConfReportsByProject.reportInstances[reportIndex] =
      await this.reportService.getReportInstanceById(reportInstanceId);

    currentConfReportsByProject.dataCard = this.buildCards(
      currentConfReportsByProject?.reportInstances,
      currentConfReportsByProject?.confReport,
      currentConfReportsByProject?.confReportBySubject
    );
    currentConfReportsByProject.dataTable = this.buildTPTableData(
      currentConfReportsByProject?.reportInstances,
      currentConfReportsByProject?.confReport,
      currentConfReportsByProject?.confReportBySubject
    );

    //Open PDF
    this.openModal(currentConfReportsByProject.reportInstances[reportIndex]);
  }

  generatePDFConsolidate(confReportId: any): void {
    this.confirmationService.confirm({
      message: this.trialpalService.translateService.instant(
        'general.notPdfGenerate'
      ),
      accept: () => {
        this.createReportCardPDFComplete(confReportId);
      },
    });
  }

  showInputSwitchControlledBySite(isControlledBySite: any) {
    return (
      isControlledBySite &&
      (this.authService.isInvestigator() ||
        this.authService.isCoordinator() ||
        this.authService.isAdmin())
    );
  }

  isExecutableByInvestigator(confReport: ConfReport) {
    return (
      confReport?.availableUsers?.includes(
        ReportAvailableUserType.INVESTIGATOR
      ) ?? false
    );
  }

  async completeReport(confReport: ConfReport, reportInformation: any) {
    //Obtiene la instancia de reporte (Si existe una)
    const reportInstance = await this.getReportInstanceById(
      reportInformation?.id
    );

    //Si existe un reporte valida si ya se genero el PDF
    const isAllowedToEnterTheReport =
      this.isAllowedToEnterTheReport(reportInstance);

    if (!isAllowedToEnterTheReport) {
      return this.showIsAllowedToLeaveTheReportMessage();
    }

    //Abre el modal para completar el reporte a ejecutar
    const reportRef = this.dialogService.open(ReportExecutionComponent, {
      width: '70%',
      height: '95vh',
      closeOnEscape: true,
      dismissableMask: false,
      showHeader: false,
      data: {
        confReport,
        subjectId: this.subjectId,
        siteId: this.currentSubject?.siteId,
        reportInstance: reportInstance,
      },
      baseZIndex: 100000,
    });

    reportRef.onClose.subscribe((isCompleted: boolean) => {
      if (isCompleted) this.ngOnInit();
    });
  }

  isAllowedToEnterTheReport(reportInstance: ReportInstance | undefined) {
    const completedDate = reportInstance?.completeDate;
    if (!reportInstance) return true;
    if (this.distanceInMinutes(completedDate) > 2) return true;
    if (
      completedDate &&
      (!reportInstance?.isPDFGenerated || !reportInstance?.isAlertChecked)
    ) {
      return false;
    }
    return true;
  }

  distanceInMinutes(completedDate: any) {
    const currentDate = moment(new Date());
    return currentDate.diff(moment(completedDate), 'minutes');
  }

  showIsAllowedToLeaveTheReportMessage() {
    this.trialpalService.messageService.add({
      severity: 'warn',
      summary: this.trialpalService.translateService.instant(
        'report.execution.warning'
      ),
      detail: this.trialpalService.translateService.instant(
        'report.execution.allowedReport'
      ),
    });
  }

  async getReportInstanceById(id: string): Promise<ReportInstance | undefined> {
    if (!id) return undefined;
    return this.reportService.getReportInstanceById(id);
  }

  openPdfModal(pdfUrl: any, analyticsSource: string): void {
    const ref = this.dialogService.open(ViewPdfComponent, {
      data: {
        PDFUrl: pdfUrl,
        disablePrint: true,
        showPrintButton: true,
        sourceComponent: analyticsSource,
        subjectId: this.subjectId,
      },
      styleClass: 'modal-pdf',
      closeOnEscape: true,
      dismissableMask: true,
      showHeader: false,
      width: '80%',
      height: '80%',
    });

    ref.onClose.subscribe();
  }

  getReportScoreLabel(formConfiguration: any): string {
    if (formConfiguration[0]?.scoreLabel) {
      return this.trialpalService.dictionaryPipe.transform(
        formConfiguration[0].scoreLabel
      );
    }
    return this.trialpalService.translateService.instant(
      'report.labelJsonFormScore'
    );
  }

  hasGroupEvaluation(configuration: any): boolean {
    for (let key in configuration) {
      const value = configuration[key];
      if (typeof value === 'string' && value.includes('group_evaluation')) {
        return true;
      } else if (typeof value === 'object' && value !== null) {
        // Si la propiedad es un objeto, realiza una búsqueda recursiva
        if (this.hasGroupEvaluation(value)) return true;
      }
    }

    return false;
  }

  onTabOpen({ index }: any) {
    const currentReport = this.confReportsByProject[index];
    if (!currentReport?.reportInstances?.length) return;
    this.currentIndexTabOpen = index;
    this.currentIndexTabs.add(index);
    this.generateDetailSubjectReportsDetailTour(index, currentReport);
    this.startTour(PageTourDataType.DETAIL_SUBJECT_REPORTS_DETAIL_TOUR);
  }

  startOnDemandTour(index: number) {
    const currentReport = this.confReportsByProject[index];
    if (!currentReport?.reportInstances?.length) return;
    this.generateDetailSubjectReportsDetailTour(index, currentReport);
    this.pageTourService.startOnDemandTour(
      PageTourDataType.DETAIL_SUBJECT_REPORTS_DETAIL_TOUR
    );
  }

  onTabClose({ index }: any) {
    this.currentIndexTabs.delete(index);
    const currentIndexTabsSize = this.currentIndexTabs.size;
    this.currentIndexTabOpen = !currentIndexTabsSize
      ? -1
      : Array.from(this.currentIndexTabs)[currentIndexTabsSize - 1];
  }

  generateDetailSubjectReportsDetailTour(
    index: number,
    currentReport: ConfReportBySubjectInterface
  ) {
    const tableViewMode = currentReport?.tableViewMode ?? false;
    let firstElementId = `#reportSubjectSection_container${index} #reportSubjectCard0`;
    let secondElementId = `#reportSubjectSection_container${index} #reportSubjectCardConsolidate`;
    if (tableViewMode && currentReport.dataCard.length > 1) {
      firstElementId = `#reportSubjectSection_container${index} #reportSubjectRow1`;
      secondElementId = `#reportSubjectSection_container${index} #reportSubjectRow0`;
    }
    PAGE_TOUR_DATA.DETAIL_SUBJECT_REPORTS_DETAIL_TOUR = [
      {
        element: firstElementId,
        popover: {
          title: 'subject.tabheaderReportCardTitle',
          description: 'subject.detailSubjectReportTour.reportCard',
        },
      },
      {
        element: secondElementId,
        popover: {
          title: 'subject.tabheaderReportConsolidateCardTitle',
          description: 'subject.detailSubjectReportTour.reportCardConsolidate',
        },
      },
    ];
  }

  async startTour(tour: PageTourDataType): Promise<void> {
    if (!this.isOpened || this.showSpinner) return;
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500);
    });
    this.pageTourService.initiateTour(tour);
  }
}

export interface ConfReportBySubjectInterface {
  confReport: any;
  confReportBySubject: any;
  tableViewMode?: boolean;
  reportInstances?: any;
  reportInstance?: any;
  dataCard: any;
  dataTable: TPTableData;
}
