import { Component, Input, OnInit } from '@angular/core';
import { Logger } from 'aws-amplify';
import { DialogService } from 'primeng/dynamicdialog';
import { ReportService } from 'src/app/modules/report/report.service';
import { DeleteConfAlertInput } from 'src/app/modules/report/report.types';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConfState } from 'src/app/services/trialpal.types';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { TPCardData } from 'src/app/shared/components/tpcard/tpcardData';
import { EdiaryService } from '../../ediary.service';
import { AlertAddEditComponent } from '../alert-add-edit/alert-add-edit.component';
const logger = new Logger('tp2-logger-alertListPage');

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit {
  @Input() relatedEntityId: any = null;
  @Input() relatedEntity: any = null;
  @Input() dictionary: Dictionary[] = [];
  @Input() projectId: string = '';
  @Input() groups: any[] = [];
  confAlerts: Array<any> = [];

  isReadOnly = false;

  constructor(
    private ediaryService: EdiaryService,
    public dialogService: DialogService,
    private trialpalService: TrialpalService,
    private authService: AuthService,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.isReadOnly = !this.authService.isAdmin();
    this.loadAlerts();
  }

  async loadAlerts(): Promise<void> {
    this.trialpalService.showSpinner(
      'ediary.components.alertAddEdit.loadingAlerts'
    );
    this.relatedEntity === 'EDIARY'
      ? await this.getConfAlertByConfEDiary()
      : await this.getConfAlertByConfReport();

    this.trialpalService.hideSpinner();
  }

  async getConfAlertByConfEDiary() {
    try {
      this.confAlerts = await this.ediaryService.getConfAlertByConfEdiaryId(
        this.relatedEntityId
      );
      logger.log('getConfAlertByConfEDiary', this.confAlerts);
    } catch (err) {
      logger.error('getConfAlertByConfEdiaryId', err);
    }
  }

  async getConfAlertByConfReport() {
    try {
      this.confAlerts = await this.reportService.getConfAlertByConfReportId(
        this.relatedEntityId
      );
      logger.log('getConfAlertByConfReport', this.confAlerts);
    } catch (err) {
      logger.error('getConfAlertByConfReportId', err);
    }
  }

  openForm(alert?: any): void {
    const ref = this.dialogService.open(AlertAddEditComponent, {
      data: {
        relatedEntityId: this.relatedEntityId,
        relatedEntity: this.relatedEntity,
        confAlert: alert,
        dictionary: this.dictionary,
        projectId: this.projectId,
        groups: this.groups,
      },
      width: '98%',
      height: '95%',
    });
    ref.onClose.subscribe((res) => {
      if (res) {
        const duplicate = this.confAlerts.find((e) => e.id === res.id);
        if (duplicate) {
          const index = this.confAlerts.indexOf(duplicate);
          this.confAlerts[index] = res;
        } else {
          this.confAlerts.push(res);
        }
        this.trialpalService.messageService.add({
          severity: 'success',
          summary:
            this.trialpalService.translateService.instant('general.attention'),
          detail: this.trialpalService.translateService.instant(
            'ediary.components.alertAddEdit.toastAlertSaved'
          ),
        });
      }
    });
  }

  openFormInReadMode(alert?: any) {
    this.dialogService.open(AlertAddEditComponent, {
      data: {
        relatedEntityId: this.relatedEntityId,
        relatedEntity: this.relatedEntity,
        confAlert: alert,
        dictionary: this.dictionary,
        projectId: this.projectId,
        groups: this.groups,
        readMode: true,
      },
      width: '98%',
      height: '95%',
    });
  }

  buildTPCardData(alert: any): TPCardData {
    return {
      data: alert,
      header: alert.alertName,
      icon2: 'pi pi-envelope',
      section2: this.trialpalService.translateService.instant(
        'ediary.enums.alertTypes.' + alert.type
      ),
      showEditButton: !this.isReadOnly,
      showActivateButton: false,
      showDeleteButton: !this.isReadOnly,
      showCustomButton: false,
      iconCustomButton: 'pi pi-search',
      objectAction: alert.alertName,
      entityAction: this.trialpalService.translateService.instant(
        'ediary.components.alertAddEdit.entity'
      ),
      isFeminine: true,
      isHeaderDictionary: true,
    };
  }

  async onDeleteConfAlert(confAlert: any): Promise<void> {
    try {
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant(
          'ediary.components.alertAddEdit.loadingDeletingAlert'
        )
      );
      const hasAlertInstances =
        await this.ediaryService.findAtLeastOneAlertInstanceByConfAlert(
          confAlert.id
        );

      hasAlertInstances
        ? await this.deleteConfAlertSoft(confAlert)
        : await this.deleteConfAlertHard(confAlert);

      this.trialpalService.hideSpinner();
      this.deleteMessage();
      this.removeConfAlertOfAlertList(confAlert);
    } catch (error) {
      this.trialpalService.hideSpinner();
      this.trialpalService.showServiceError(
        'general.messageErrorOperation.summary',
        error
      );
      logger.error('onDeleteConfAlert error', error);
    }
  }

  async deleteConfAlertSoft(confAlert: any) {
    const input = {
      state: ConfState.DELETED,
    };
    return this.ediaryService.updateConfAlert(
      input,
      confAlert.id,
      confAlert._version
    );
  }

  async deleteConfAlertHard(confAlert: any) {
    const input: DeleteConfAlertInput = {
      id: confAlert.id,
      _version: confAlert._version,
    };
    await this.ediaryService.deleteConfAlert(input);
  }

  async deleteMessage() {
    this.trialpalService.messageService.add({
      severity: 'success',
      summary: this.trialpalService.translateService.instant('general.success'),
      detail: this.trialpalService.translateService.instant(
        'general.deleteSuccess'
      ),
    });
  }

  async removeConfAlertOfAlertList(confAlertToRemove: any) {
    if (this.confAlerts.length) {
      this.confAlerts = this.confAlerts?.filter(
        (confAlert) => confAlert?.id !== confAlertToRemove?.id
      );
    }
  }
}
