<form name="form" #f="ngForm" autocomplete="off" (ngSubmit)="onSubmit(f)">
  <p-fieldset legend="{{ 'conciliation.labelConciliation' | translate }}">
    <small>{{ 'general.configurations.reconciliationHelp' | translate }}</small>
    <div class="p-grid p-fluid p-mt-3">
      <div
        class="p-col-12 p-md-6 p-field p-ai-center"
        style="display: grid; grid-template-columns: 1fr auto"
        *ngFor="let section of sectionsToConcilied; let index = index"
      >
        <label for="{{ 'btn' + section.value }}">{{ section.label }}</label>
        <div class="p-col">
          <p-selectButton
            [disabled]="readMode"
            required
            id="{{ 'btn' + section.value }}"
            name="{{ 'btn' + section.value }}"
            [(ngModel)]="section.active"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            tooltipPosition="top"
            (ngModelChange)="checkAccordionTap(index)"
          ></p-selectButton>
        </div>
      </div>
    </div>
    <!-- Accordion setup options -->
    <p-accordion #accordion>
      <p-accordionTab
        [header]="section.label"
        *ngFor="let section of sectionsToConcilied; index as i"
        [disabled]="!section.active"
      >
        <ng-template pTemplate="content">
          <ng-container [ngSwitch]="section.value">
            <!--plantilla para configuraciones de sintomas-->
            <ng-template #symptoms>
              <app-reconciliation-section-detail
                [confForm]="confForm"
                section="symptoms"
                [readMode]="readMode"
              ></app-reconciliation-section-detail>
            </ng-template>
            <ng-template #localSymptoms>
              <app-reconciliation-section-detail
                [confForm]="confForm"
                section="localSymptoms"
                [readMode]="readMode"
              ></app-reconciliation-section-detail>
            </ng-template>
            <ng-template #temperature>
              <app-reconciliation-section-detail
                [confForm]="confForm"
                section="temperature"
                [readMode]="readMode"
              ></app-reconciliation-section-detail>
            </ng-template>
            <ng-template #medication>
              <app-reconciliation-section-detail
                [confForm]="confForm"
                section="medication"
                [readMode]="readMode"
              ></app-reconciliation-section-detail>
            </ng-template>
            <ng-template #medicalAttention>
              <app-reconciliation-section-detail
                [confForm]="confForm"
                section="medical-attention"
                [readMode]="readMode"
              ></app-reconciliation-section-detail>
            </ng-template>
            <ng-template #otherSymptoms>
              <app-reconciliation-section-detail
                [confForm]="confForm"
                section="other-symptoms"
                [readMode]="readMode"
              ></app-reconciliation-section-detail>
            </ng-template>
            <!-- Use ngSwitchCase to select which template to display -->
            <ng-container *ngSwitchCase="'SOLICITED_SYMPTOMS'">
              <ng-container *ngTemplateOutlet="symptoms"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'LOCAL_SYMPTOMS'">
              <ng-container *ngTemplateOutlet="localSymptoms"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'TEMPERATURE'">
              <ng-container *ngTemplateOutlet="temperature"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'MEDICATION'">
              <ng-container *ngTemplateOutlet="medication"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'MEDICAL_ATTENTION'">
              <ng-container *ngTemplateOutlet="medicalAttention"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'OTHER_SYMPTOMS'">
              <ng-container *ngTemplateOutlet="otherSymptoms"></ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </p-fieldset>
  <div class="p-col p-text-right p-mt-4">
    <button
      id="submitButton"
      name="submitButton"
      pButton
      type="submit"
      [disabled]="f.invalid"
      label="{{ 'general.save' | translate }}"
    ></button>
  </div>
</form>
