import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
const logger = new Logger('Calculate form');
@Injectable({
  providedIn: 'root',
})
export class ReportDynamicFormService {
  calculationTypes: any = {
    take_min_value: 'take_min_value',
    take_max_value: 'take_max_value',
    sum_all_value: 'sum_all_value',
    unique_value: 'unique_value',
    no_calculate_value: 'no_calculate_value',
  };

  findFieldConfByKey(configurationForm: any, keyToFind: string) {
    for (let key in configurationForm) {
      if (configurationForm[key] === keyToFind) {
        return configurationForm.templateOptions.calculateType; // Return the value if the key is found
      } else if (typeof configurationForm[key] === 'object') {
        const foundValue: any = this.findFieldConfByKey(
          configurationForm[key],
          keyToFind
        ); // Recursive call
        if (foundValue !== undefined) return foundValue; // Return the value if found in nested objects
      }
    }
    return undefined;
  }

  calculateValue(value: any, type: string) {
    let score = 0; //por defecto
    try {
      //SI es un arreglo, transforma los valores en numericos (en caso de que sean string)
      if (Array.isArray(value)) {
        value = value.filter((a) => Boolean(a)).map((a) => Number(a));
      }
      switch (type) {
        case this.calculationTypes.unique_value:
          score = Number(value);
          break;
        case this.calculationTypes.take_max_value:
          score = Math.max(...value);
          break;

        case this.calculationTypes.take_min_value:
          score = Math.min(...value);
          break;

        case this.calculationTypes.sum_all_value:
          if (Array.isArray(value)) {
            score = value.reduce((a, b) => a + b, 0);
          }
          break;
      }
    } catch (error) {
      logger.error('Hubo un error en el calculo del campo -->', error);
    }

    return typeof score === 'number' ? Math.floor(score) : 0;
  }

  calculate(configuration: any, formData: any) {
    let totalScore = 0;
    for (let key in formData) {
      if (key.includes('group')) {
        totalScore += this.calculate(configuration, formData[key]);
      } else if (Array.isArray(formData)) {
        const Formkey: any = key;
        totalScore += this.calculate(configuration, formData[Formkey]);
      } else {
        if (formData[key] && typeof formData[key] === 'object') {
          formData[key] = Object.values(formData[key]);
        }
        const type = this.findFieldConfByKey(configuration, key);
        const calculatedScore = this.calculateValue(formData[key], type);
        logger.debug('result -->', key, type, formData[key], calculatedScore);
        totalScore += calculatedScore;
      }
    }

    return totalScore;
  }

  calculateScore(configuration: any, formData: any): number {
    let score = 0;

    for (let key in formData) {
      if (key.includes('group_evaluation')) {
        score += this.calculate(configuration, formData[key]);
      }

      if (typeof formData[key] === 'object') {
        score += this.calculateScore(configuration, formData[key]);
      }
    }

    return score;
  }
}
