<main class="designer-container">
  <aside>
    <p-accordion class="w-full" [activeIndex]="0">
      <p-accordionTab
        [header]="fieldsType.name | translate"
        *ngFor="let fieldsType of designerFieldTypes"
      >
        <ul class="field-list">
          <li
            *ngFor="let field of fieldsType?.options"
            pDraggable
            (onDragStart)="dragStart(field)"
            (onDragEnd)="dragEnd()"
          >
            <button class="field-button" type="button">
              <img
                [src]="field.icon"
                class="field-icon capitalize"
                alt="icon"
              /><span>{{
                'designer.fieldTypeEnums.' + field.type | translate
              }}</span>
            </button>
          </li>
        </ul>
      </p-accordionTab>
      <p-accordionTab [header]="'designer.fieldTypes.layout' | translate">
        <ul class="layout-container">
          <li>
            <button
              class="btn-layout"
              [ngClass]="{ 'btn-active': currentDisplay === card }"
              type="button"
              (click)="changeDisplay(card)"
            >
              {{ 'designer.layout.card' | translate }}
            </button>
          </li>
          <li>
            <button
              class="btn-layout"
              [ngClass]="{ 'btn-active': currentDisplay === accordion }"
              type="button"
              (click)="changeDisplay(accordion)"
            >
              {{ 'designer.layout.accordion' | translate }}
            </button>
          </li>
          <li>
            <button
              class="btn-layout"
              [ngClass]="{ 'btn-active': currentDisplay === stepper }"
              type="button"
              (click)="changeDisplay(stepper)"
            >
              {{ 'designer.layout.stepper' | translate }}
            </button>
          </li>
          <li>
            <button
              class="btn-layout"
              [ngClass]="{ 'btn-active': currentDisplay === tabs }"
              type="button"
              (click)="changeDisplay(tabs)"
            >
              {{ 'designer.layout.tabs' | translate }}
            </button>
          </li>
        </ul>
      </p-accordionTab>
    </p-accordion>
  </aside>

  <!-- LISTA DE CAMPOS Y GRUPOS CREADOS PARA EL DISEÑADOR -->
  <article>
    <!--Label form-->
    <div class="p-d-flex p-flex-column gap-2 p-mb-3">
      <app-label-tooltip
        for="score_label"
        [label]="'designer.scoreLabel' | translate"
        [tooltip]="'designer.scoreLabelHelp' | translate"
        [required]="false"
      ></app-label-tooltip>
      <app-simple-dictionary-input
        id="score_label"
        name="score_label"
        [projectId]="projectId"
        [isRequired]="false"
        [value]="formField.scoreLabel"
        (outputEvent)="formField.scoreLabel = $event; updateFormField()"
      ></app-simple-dictionary-input>
    </div>

    <div class="field-grid p-mb-3">
      <div class="p-d-flex p-flex-column gap-2">
        <app-label-tooltip
          for="drop_fieldType"
          [label]="'designer.pdfViewTypeGeneral' | translate"
          [tooltip]="'designer.pdfViewTypeGeneralTooltip' | translate"
          [required]="false"
        ></app-label-tooltip>
        <p-dropdown
          id="drop_fieldType"
          name="drop_fieldType"
          optionLabel="label"
          optionValue="value"
          class="input p-col-12 p-p-0"
          appendTo="body"
          [options]="pdfViewTypes"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
          emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
          [(ngModel)]="formField.pdfViewType"
          (onChange)="updateFormField()"
          [required]="false"
        >
        </p-dropdown>
      </div>
      <div class="p-d-flex gap-2" style="align-items: center; align-self: end">
        <p-checkbox
          id="priority"
          name="priority"
          [binary]="true"
          [required]="false"
          [(ngModel)]="formField.pdfViewTypePriority"
          (onChange)="updateFormField()"
        ></p-checkbox>
        <app-label-tooltip
          [for]="'priority'"
          [label]="'designer.pdfViewTypePriority' | translate"
          [tooltip]="'designer.pdfViewTypePriorityTooltip' | translate"
          [required]="false"
        ></app-label-tooltip>
      </div>
    </div>
    <app-list-field
      [fieldGroup]="formField.fieldGroup ?? []"
      [fieldSelected]="fieldSelected"
      [onDragDrop$]="onDragDrop$"
      [projectId]="projectId"
      (fieldGroupChange)="emitFormField()"
    ></app-list-field>
  </article>
</main>
