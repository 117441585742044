import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-radio-grid',
  templateUrl: './formly-radio-grid.component.html',
  styleUrls: ['./formly-radio-grid.component.scss'],
})
export class FormlyRadioGridComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  formControlValues: { question: string; value: string }[] = [];
  formRadioField: FormGroup;

  constructor(private fb: FormBuilder) {
    super();
    this.formRadioField = this.fb.group({});
  }

  ngOnInit(): void {
    const questions = this.to.questions ?? [];
    const formRadioFieldConfig: { [key: string]: any } = {};

    questions.forEach((question: any) => {
      if (this.to.required) {
        formRadioFieldConfig[question.label] = [
          null,
          [Validators.required, this.allOptionsFilledValidator()],
        ];
      } else {
        formRadioFieldConfig[question.label] = [];
      }
    });

    this.formRadioField = this.fb.group(formRadioFieldConfig);
    this.formRadioField.patchValue(this.model[`${this.field.key}`]);

    if (this.to.required && this.formRadioField.invalid) {
      this.formControl.setErrors({ invalid: true });
    }
  }

  saveFormControlValues() {
    this.formControl.setValue(this.formRadioField.value);
    if (!this.formRadioField.invalid) return this.formControl.setErrors(null);
    this.formControl.setErrors({ invalid: true });
  }

  // Ejemplo de validación sincrónica del formulario
  allOptionsFilledValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value ? null : { optionsNotFilled: true };
    };
  }
}
