import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ChatService } from '../../chat/chat.service';

const logger = new Logger('tp2-logger-newPasswordPage');
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent {
  isPasswordShown = false;
  passwordConfirmation = '';
  password = '';
  displayMFA: boolean = false;
  codeMFA = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private trialpalService: TrialpalService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private chatService: ChatService
  ) {}

  onSubmit(form: NgForm): void {
    if (!form.valid) return this.formInvalidError();
    if (this.password !== this.passwordConfirmation) {
      return this.passwordDontMathError();
    }
    const passwordValidate = this.trialpalService.validatePassword(
      this.password
    );
    if (!passwordValidate) return this.passWordInvalidError();

    this.trialpalService.showSpinner(
      this.translateService.instant('password.spinneChangingPassword')
    );

    this.authService
      .completeNewPassword(this.password)
      .then((response: any) => {
        this.completeNewPassword(response);
      })
      .catch((error: any) => {
        this.newPasswordError(error);
        this.trialpalService.hideSpinner();
      });
  }

  completeNewPassword(response: any) {
    this.trialpalService.hideSpinner();
    logger.debug('completeNewPassword response', response);
    if (response.challengeName && response.challengeName === 'SMS_MFA') {
      this.displayMFA = true;
    } else {
      this.showMessageSuccefully();
      this.router.navigate(['/login']);
    }
  }

  formInvalidError() {
    this.trialpalService.hideSpinner();
    return this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(
        'password.messageErrorFormNotValid.summary'
      ),
      detail: this.translateService.instant(
        'password.messageErrorFormNotValid.detail'
      ),
    });
  }

  passWordInvalidError() {
    this.trialpalService.hideSpinner();
    return this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(
        'password.messageErrorPatternPassword.summary'
      ),
      detail: this.translateService.instant(
        'password.messageErrorPatternPassword.detail'
      ),
    });
  }

  passwordDontMathError() {
    this.trialpalService.hideSpinner();
    return this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(
        'password.messageErrorPasswordNotMatch.summary'
      ),
      detail: this.translateService.instant(
        'password.messageErrorPasswordNotMatch.detail'
      ),
    });
  }

  newPasswordError(error: any): void {
    this.trialpalService.hideSpinner();
    if (error?.message === 'Invalid device key given.') {
      this.showMessageSuccefully();
      this.router.navigate(['/login']);
    } else if (
      error?.message === 'Invalid session for the user, session is expired.'
    ) {
      this.showMessageSessionExpired();
      this.router.navigate(['/login']);
    } else {
      this.trialpalService.showServiceError(
        'user.actions.resetPassword',
        error
      );
    }
    logger.error('register error', error);
  }

  showMessageSessionExpired() {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(
        'password.messageSuccessChangePassword.summary'
      ),
      detail: this.translateService.instant('password.sessionExpired'),
    });
  }

  showMessageSuccefully() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant(
        'password.messageSuccessChangePassword.summary'
      ),
      detail: this.translateService.instant(
        'password.messageSuccessChangePassword.detail'
      ),
    });
  }
  showPasswords(): void {
    this.isPasswordShown = !this.isPasswordShown;
  }

  async confirmLogin(): Promise<void> {
    if (!this.codeMFA) {
      this.showMessageErrorMfa('messageErrorFormNotValid');
      return;
    }

    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'password.spinnerConfirnSignInMFA'
      )
    );

    try {
      const response = await this.authService.confirmSignIn(
        this.authService.user,
        this.codeMFA
      );
      logger.debug('confirmSignIn response', response);
      this.showMessageSuccefully();
      this.authService.setUserInLocalStorage(response);
      await this.authService.navigateHome();
      this.chatService.initSiteChatSubscriptions();
    } catch (error) {
      logger.error('confirmSignIn error', error);
      logger.error(error);
      this.showMessageErrorMfa('messageErrorCodeMFAInValid');
    } finally {
      this.trialpalService.hideSpinner();
    }
  }

  showMessageErrorMfa(messageError: string) {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'password.messageErrorCodeMFAInValid.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        `password.${messageError}.detail`
      ),
    });
  }
}
