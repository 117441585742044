import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Analytics, Logger } from 'aws-amplify';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { UserPermissionsService } from 'src/app/services/user-permissions-service';
import version from '../../../../../package.json';
import { ChatService } from '../../chat/chat.service';
import { ProjectService } from '../../project/project.service';
import { ErrorTranslationService } from '../error-translation.service';

const logger = new Logger('tp2-logger-loginPage');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  password = '';
  username = '';
  isPasswordShown = false;
  codeMFA = '';
  display: boolean = false;
  versionProject = '';
  showMFARetry = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private trialpalService: TrialpalService,
    private errorTranslationService: ErrorTranslationService,
    private chatService: ChatService,
    private projectService: ProjectService,
    private bnIdle: BnNgIdleService,
    private userPermissionsService: UserPermissionsService
  ) {}

  ngOnInit(): void {
    this.userPermissionsService.unsusbcribeUserAuth();
    this.versionProject = 'V' + version.version;
  }

  async onSubmit(form: NgForm): Promise<void> {
    //Valida que el campo username no este vacio ni contenga espacios antes y despues
    this.username = this.username.trim();
    this.trialpalService.validateSpaces(this.username, 'usuario', form);

    if (form.valid) {
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant('general.loadingInfo')
      );
      try {
        const response = await this.authService.loginUser(
          this.username,
          this.password
        );

        logger.debug('loginUser response', response);
        await this.loginUser(response);
      } catch (error) {
        logger.error('loginUser error', error);
        this.handleLoginException(error);
      }

      this.trialpalService.hideSpinner();
    } else {
      this.trialpalService.showInvalidFormError();
      this.trialpalService.hideSpinner();
    }
  }

  public handleLoginException(error: any): void {
    logger.error('loginUser error', error);
    if (error.code === 'UserNotConfirmedException') {
      this.router.navigate(['activation/' + this.username.trim()]);
    } else if (error.code === 'PasswordResetRequiredException') {
      this.router.navigate(['resetPassword/' + this.username.trim()]);
    } else {
      this.errorTranslationService.notifyAWSError(error);
    }
  }

  async loginUser(response: any): Promise<any> {
    this.projectService.cleanListProjects();
    this.showMFARetry = false;
    this.authService.user = response;

    await this.userPermissionsService.initialize(); //Inicia proceso de permisos al iniciar sesion

    if (this.userHasSubject(response)) {
      this.authService.logout();
      return this.authService.userNoAuthorizedMessage();
    }
    if (response.getSignInUserSession()) {
      await this.signInUserSession(response);
    }

    if (response.challengeName) {
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await this.newPasswordRequiredChallenge(response);
      } else if (response.challengeName === 'SMS_MFA') {
        this.smsMFAChallenge();
      }
    }
  }

  async signInUserSession(response: any) {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant('general.loadingInfo')
    );
    //Cuando el usuario ingresa o reinicia la pagina se reinicia el tiempo de inactividad
    this.bnIdle.resetTimer();
    this.authService.setUserInLocalStorage(response);
    this.chatService.initSiteChatSubscriptions();
    await this.authService.navigateHome();
    await this.authService.endpointUpdatingFlow();
    Analytics.record({
      name: 'site_sign_in',
      attributes: {
        userSub: this.authService.user?.attributes?.sub,
        userName: this.authService.user?.username,
      },
    });
    this.trialpalService.hideSpinner();
  }

  async smsMFAChallenge() {
    logger.debug('SMS REQUIRED');
    setTimeout(() => {
      this.showMFARetry = true;
    }, 15000);
    this.showDialogCodeMFA();
  }

  async newPasswordRequiredChallenge(response: any) {
    this.trialpalService.hideSpinner();
    await this.authService.logout(true, true);
    this.authService.user = response;
    this.router.navigate(['/newPassword']);
  }

  userHasSubject(response: any) {
    try {
      if (response.attributes) {
        return response?.attributes['custom:subjects'];
      } else if (response.challengeParam) {
        return response?.challengeParam?.userAttributes['custom:subjects'];
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  showPasswords(): void {
    this.isPasswordShown = !this.isPasswordShown;
  }

  lang(lang: string): void {
    this.trialpalService.changeLang(lang);
  }

  showDialogCodeMFA(): void {
    this.display = true;
  }

  confirmLogin(): void {
    if (this.codeMFA) {
      this.trialpalService.showSpinner(
        this.trialpalService.translateService.instant(
          'password.spinnerConfirnSignInMFA'
        )
      );
      this.authService
        .confirmSignIn(this.authService.user, this.codeMFA)
        .then((response: any) => {
          logger.debug('confirmSignIn response', response);
          this.signInUserSession(response);
        })
        .catch((error) => {
          logger.error('confirmSignIn error');
          logger.error(error);
          this.showMessageErrorMfa('messageErrorCodeMFAInValid');
        })
        .finally(() => this.trialpalService.hideSpinner());
    } else {
      this.showMessageErrorMfa('messageErrorFormNotValid');
    }
  }

  showMessageErrorMfa(messageError: string) {
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'password.messageErrorCodeMFAInValid.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        `password.${messageError}.detail`
      ),
    });
  }
}
