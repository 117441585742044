import { Pipe, PipeTransform } from '@angular/core';

interface DictionaryItem {
  key: string;
  spanish: string;
  english: string;
}

@Pipe({
  name: 'dictionary',
})
export class DictionaryPipe implements PipeTransform {
  transform(key: string): string {
    const language = localStorage.getItem('lang');
    const array = localStorage.getItem('Dictionary');

    let data = key;
    if (array) {
      try {
        const dictionaryItems: DictionaryItem[] = JSON.parse(array).items;
        const matchingItem = dictionaryItems.find(
          (dictionary: DictionaryItem) => dictionary.key === key
        );
        if (matchingItem) {
          data =
            language === 'es' ? matchingItem.spanish : matchingItem.english;
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    return data;
  }
}
