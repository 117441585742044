<div class="container-tp2">
  <div class="header p-mb-4">
    <app-back-button-header
      [pageUrl]="'project/' + projectId + '/detail'"
      [pageTitle]="
        'informedConsent.followUp.informedConsentFollowUp' | translate
      "
      [pageDescription]="'informedConsent.followUp.description' | translate"
      [secondaryPageDescription]="
        'informedConsent.followUp.secondaryDescription' | translate
      "
      [showButton]="true"
      [textButton]="'informedConsent.followUp.peopleManager' | translate"
      [isButtonDisabled]="disableManageBtn"
      (clickEmitter)="!disableManageBtn && goToUsersTable()"
    ></app-back-button-header>
  </div>
  <div class="dropdown-container">
    <p class="p-mb-1">{{ 'informedConsent.followUp.siteLabel' | translate }}</p>
    <p class="p-mb-1">
      {{ 'informedConsent.followUp.eConsentLabel' | translate }}
    </p>
    <p-dropdown
      [options]="sites"
      [(ngModel)]="siteId"
      optionLabel="label"
      optionValue="value"
      [filter]="true"
      [placeholder]="'informedConsent.followUp.sitePlaceholder' | translate"
      (onChange)="onSelectSite()"
    ></p-dropdown>
    <p-dropdown
      [disabled]="!siteId"
      [options]="confInformedConsents"
      [(ngModel)]="confInformedConsentId"
      optionLabel="label"
      optionValue="value"
      [filter]="true"
      [placeholder]="'informedConsent.followUp.eConsentPlaceholder' | translate"
      (onChange)="onSelectInformedConsent()"
    ></p-dropdown>
  </div>

  <div class="body-container" [class.disabled]="disableManageBtn">
    <div class="status-container">
      <div class="blue-alert">
        <p class="text">
          {{ 'informedConsent.followUp.subjectsForSite' | translate }}
        </p>
        <p class="num-bold-label">{{ totalSubjects }}</p>
      </div>
      <div
        class="custom-divider"
        style="height: 2px; background: #adadad; width: 19rem"
      ></div>
      <div class="timeline-container">
        <p class="comment">
          {{ 'informedConsent.followUp.progressOfProcess' | translate }}
        </p>
        <ng-container *ngFor="let item of informConsentStates; let i = index">
          <div class="state" (click)="!disableManageBtn && filterData(i)">
            <app-progress-card
              [state]="item.state"
              [percentage]="item.advance"
              [order]="i + 1"
              [subjects]="item.subjects"
              [isActive]="currentFilter === i"
              [disabled]="disableManageBtn"
              (clearFilter)="onClearFilter($event)"
            ></app-progress-card>
            <span class="line"></span>
          </div>
        </ng-container>
      </div>
      <div
        class="users-not-continue"
        [class.active]="subjectsWhoNotContinueFilterActive"
        (click)="!disableManageBtn && toogleFilterSubjectsNotContinue($event)"
      >
        <p class="comment">
          {{ 'informedConsent.followUp.subjectsWhoDidNotConinue' | translate }}
        </p>
        <div class="deserters-container">
          <p class="num-bold-label">{{ subjectsWhoNotContinue.length }}</p>
          <p-button
            icon="{{
              subjectsWhoNotContinueFilterActive
                ? 'pi pi-times'
                : 'pi pi-arrow-right'
            }}"
            styleClass="p-button-rounded p-button-help p-button-text"
            (onClick)="
              !disableManageBtn && toogleFilterSubjectsNotContinue($event, true)
            "
          ></p-button>
        </div>
      </div>
    </div>

    <div class="consents-container" style="width: 100%">
      <div class="flex justify-content-start align-items-center">
        <div class="p-input-icon-right" style="width: 21rem">
          <i
            *ngIf="!consentedIdentificatorSearch"
            class="pi pi-search"
            aria-hidden="true"
          ></i>
          <i
            *ngIf="consentedIdentificatorSearch"
            (click)="consentedIdentificatorSearch = ''; applySearchFilter()"
            class="pi pi-times"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <input
            [disabled]="disableManageBtn"
            type="text"
            #search
            [(ngModel)]="consentedIdentificatorSearch"
            (input)="applySearchFilter()"
            pInputText
            [placeholder]="
              'informedConsent.followUp.table.searchLabel' | translate
            "
          />
        </div>
      </div>
      <p-table
        [value]="informedConsentTableData"
        [rows]="10"
        [paginator]="informedConsentInstances.length > 0"
        responsiveLayout="scroll"
        [rowHover]="true"
        currentPageReportTemplate="{{ 'general.paginatorValues' | translate }}"
        [showCurrentPageReport]="true"
        [tableStyle]="{ 'min-width': '100%' }"
        dataKey="id"
        [(first)]="firstTablePage"
      >
        <span class="p-input-icon-left ml-auto">
          <span class="pi pi-search"></span>
          <input pInputText type="text" placeholder="Search keyword" />
        </span>
        <ng-template pTemplate="header">
          <tr id="p-table-header">
            <th
              pSortableColumn="subjects"
              id="headerVal"
              style="border-top-left-radius: 10px; width: 11rem"
            >
              {{ 'informedConsent.followUp.table.participant' | translate }}
            </th>
            <th
              pSortableColumn="image"
              id="headerVal"
              class="p-text-center"
              style="width: 11rem"
            >
              {{ 'informedConsent.followUp.table.consentState' | translate }}
            </th>
            <th
              colspan="2"
              pSortableColumn="price"
              id="headerVal"
              style="
                border-top-right-radius: 10px;
                width: 20rem;
                text-align: right;
                padding-right: 1.5rem;
              "
            >
              {{ 'informedConsent.followUp.table.processDetails' | translate }}
            </th>
            <th id="headerVal" class="p-hidden"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="height: 100%">
          <tr>
            <td colspan="3" style="text-align: center; vertical-align: middle">
              <app-not-found-items
                [description]="
                  'informedConsent.followUp.emptySelectionDescription'
                    | translate
                "
                [buttonDescription]="
                  'informedConsent.followUp.emptySelection' | translate
                "
                [displayButton]="false"
                *ngIf="informedConsentInstances.length === 0"
              ></app-not-found-items>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-consent let-expanded="expanded">
          <tr>
            <td style="width: 30%" class="p-text-left">
              {{ consent.consentedIdentificator }}
            </td>
            <td
              [ngClass]="consent.state"
              style="width: 30%; white-space: nowrap"
              class="p-text-center"
              id="econsent-state"
            >
              <span [ngClass]="consent.state">{{
                'informedConsent.enums.states.' + consent.state
                  | translate
                  | uppercase
              }}</span>
            </td>
            <td style="width: 35%" class="p-text-right">
              <span>{{ consent.usersState }}</span>
            </td>
            <td style="width: 5%">
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="consent"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                style="font-size: 0.75rem"
                id="accordion-icon"
              ></button>
            </td>
          </tr>
          <tr class="divider-row">
            <td id="p-row-divider" colspan="100%"></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" #rowexpansion let-consent>
          <tr>
            <td colspan="4">
              <div class="p-d-flex p-jc-between" style="gap: 1rem">
                <div class="users full-width">
                  <p class="p-mb-2">
                    {{
                      'informedConsent.followUp.table.rowDetail.title'
                        | translate
                    }}
                  </p>
                  <aside class="p-mb-2">
                    <div
                      *ngIf="
                        consent.consentedUserMissingsToMax > 0 &&
                        consent.state !== 'DELETED'
                      "
                    >
                      <app-add-person
                        [consentedUserMissings]="consent.consentedUserMissings"
                        [consentedUserMissingsToMax]="
                          consent.consentedUserMissingsToMax
                        "
                        [informedConsent]="consent"
                        [projectAndSite]="projectAndSite"
                        (refresh)="refreshInstanceAfertNewPersonAdded($event)"
                      ></app-add-person>
                    </div>
                    <app-user-detail-card
                      *ngFor="let user of consent.consentedUsers"
                      [userData]="user"
                      [projectAndSite]="projectAndSite"
                      [infomedConsent]="consent"
                      [confInformedConsent]="confInformedConsent"
                      (userActionEvent)="manageUserAction($event, consent)"
                    ></app-user-detail-card>
                  </aside>
                </div>
                <div class="actions">
                  <app-menu-actions
                    [econsentData]="consent"
                    [confInformedConsentId]="confInformedConsentId"
                    [projectAndSite]="projectAndSite"
                    [confInformedConsent]="confInformedConsent"
                    (refresh)="buildTableFilter()"
                  ></app-menu-actions>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
