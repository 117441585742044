import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import {
  ConfForm,
  ConfSymptom,
  Intensity,
  Symptom,
  SymptomType,
} from 'src/app/modules/ediary/ediary.types';
import { getValueOrDefault } from 'src/app/shared/utils/helpers';
import { AuthService } from '../../../../../services/auth.service';
import { TrialpalService } from '../../../../../services/trialpal.service';
import { CONCILIATION_QUERIES } from '../../../conciliation.queries';
import { ConciliationService } from '../../../conciliation.service';
import {
  CreateVerifiedSymptomInstanceInput,
  CreateVerifiedSymptomRecordLogInput,
  DayInstance,
  DeleteVerifiedSymptomRecordLogInput,
  InstanceState,
  SymptomInstance,
  SymptomOccurrency,
  UpdateVerifiedSymptomInstanceInput,
  UpdateVerifiedSymptomRecordLogInput,
  VerifiedSymptomInstance,
  VerifiedSymptomRecordLog,
} from '../../../conciliation.types';
import { AddConfOtherSymptom } from '../../../conciliationData';
import { DayInstanceService } from '../shared/day-instance/day-instance.service';
import { DetailSymptomComponent } from './detail-symptom/detail-symptom.component';

const logger = new Logger('symptom service');

@Injectable({
  providedIn: 'root',
})
export class SymptomService {
  symptoms: any[] = [];
  tableOtherSymptoms: OtherSymptomData[] = [];
  otherSymptomsComments: any[] = []; //Comentarios que se mostrarán en la tabla de otros sintomas
  symptomInstances: any[] = [];
  verifiedSymptomInstances: any[] = [];
  symptomRecordLogs: any[] = [];
  confSymptoms: ConfSymptomInformation[] = [];
  addConfOtherSymptoms: AddConfOtherSymptom[] = [];

  isNewOtherSymptom: boolean = false;
  symptomType: string = '';

  //Datos para comentarios para
  otherSymptomColDays: any[] = [];

  //Current Symptom
  currentSymptom: TableSymptom | undefined;
  // Index del acordeón activo: guarda qué síntoma está actualmente en foco, es decir, el índice del tab del acordeón actual.
  activeAccordionTabIndex: number = 0;
  currentOtherSymptom: any = {};

  detailSymptomComponentGeneral!: DetailSymptomComponent; //Guarda la referencia de los componente de sintoma general
  detailSymptomComponentLocal!: DetailSymptomComponent; //Guarda la referencia del componente de sintoma local
  constructor(
    private trialpalService: TrialpalService,
    private authService: AuthService,
    private dayInstanceService: DayInstanceService,
    public conciliationService: ConciliationService
  ) {}

  async getSymptomByDayInstanceId(dayInstanceId: string) {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.SymptomInstanceByDayInstanceId,
      {
        dayInstanceId,
      }
    );
    return response.data.SymptomInstanceByDayInstanceId;
  }

  async getSymptomInstanceById(symptomId: string): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.GetSymptomInstance,
      {
        id: symptomId,
      }
    );
    return response.data.getSymptomInstance;
  }

  async getVerifiedSymptomInstanceBySymptomId(
    symptomId: string
  ): Promise<VerifiedSymptomInstance | null> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.VerifiedSymptomInstanceBySymptomInstanceIdCustom,
      {
        symptomInstanceId: symptomId,
      }
    );
    const { items } = response.data.VerifiedSymptomInstanceBySymptomInstanceId;
    return items[0];
  }

  getVerifiedSymptomInstancesBySymptomId(symptomId: string): any[] {
    return this.verifiedSymptomInstances.filter(
      (verifiedSymptomInstance: any) => {
        return verifiedSymptomInstance.symptomInstanceId === symptomId;
      }
    );
  }

  /**Obtiene la instancia verificada del sintoma */
  getVerifiedSymptomInstanceBySymptomInstanceId(
    symptomId: string
  ): VerifiedSymptomInstance | undefined {
    return this.verifiedSymptomInstances.find(
      (verifiedSymptomInstance: any) => {
        return verifiedSymptomInstance.symptomInstanceId === symptomId;
      }
    );
  }

  getVerifiedSymptomInstancesById(
    id: string | undefined | null
  ): VerifiedSymptomInstance | undefined | null {
    if (!id) return undefined;
    return this.verifiedSymptomInstances.find(
      (verifiedSymptomInstance: VerifiedSymptomInstance) => {
        return verifiedSymptomInstance.id === id;
      }
    );
  }

  async getVerifiedSymptomRecordLogByRecordLogId(
    verifiedSymptomInstanceId: string
  ): Promise<VerifiedSymptomRecordLog | null> {
    if (!verifiedSymptomInstanceId) return null;
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.VerifiedSymptomRecordLogsByVerifiedSymptomInstanceId,
      {
        verifiedSymptomInstanceId: verifiedSymptomInstanceId,
      }
    );
    const { items } =
      response.data.verifiedSymptomRecordLogsByVerifiedSymptomInstanceId;
    return items[0];
  }

  //Obtiene el dia y su configuración
  getDayInstanceById(dayInstanceId: string) {
    const dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];
    return dayInstances.find((dayInstanceConf: any) => {
      return dayInstanceConf.dayInstance.id === dayInstanceId;
    });
  }

  getConfSymptomByConfSymptom(confSymptomId: string) {
    return this.confSymptoms.find(
      (_confSymptom: ConfSymptomInformation) =>
        _confSymptom?.confSymptom?.id === confSymptomId
    )?.confSymptom;
  }

  async confSymptomByConfFormId(confFormId: string): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfSymptomByConfFormId,
      {
        confFormId: confFormId,
      }
    );
    const { items } = response.data.confSymptomByConfFormId;
    return items;
  }

  async getConfSymptomByConfSymptomId(confSymptomId: string): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.GetConfSymptom,
      {
        id: confSymptomId,
      }
    );
    return response.data.getConfSymptom;
  }

  getSymptoms(symptomsId: any, instance: any) {
    let symptoms = this.symptoms?.filter(
      (symptom: any) =>
        symptomsId?.includes(symptom.id) &&
        symptom?.dayInstanceId === instance?.dayInstanceId
    );
    return symptoms?.map((sym: any) => sym?.symptomLabel).join(', ');
  }

  isDayInstanceWithSymptoms(dayInstance: any) {
    return dayInstance?.symptomOcurrencies?.some(
      (occurrency: any) => occurrency?.occurrency
    );
  }

  async symptomRecordLogBysymptomInstanceId(id: string): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.SymptomRecordLogBysymptomInstanceId,
      {
        symptomInstanceId: id,
      }
    );
    const { items } = response.data.symptomRecordLogBysymptomInstanceId;
    return items;
  }

  async getSymptomRecordsLogs(): Promise<any[]> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.GetSymptomRecordLogsBySubjectIdCustom,
      {
        subjectId: this.conciliationService.subjectId,
      }
    );
    //Obtiene los recordsLogs
    let instances = response.data.symptomRecordLogBySubjectId;
    return instances.items.filter((s: any) => !s._deleted);
  }

  //Obtiene las configuracion para todos los días
  async getDaySymptomsInstances() {
    const dayInstancesAndConfigurations = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];
    this.confSymptoms = [];
    this.symptomInstances = [];
    //Obtiene las instancias
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.SymptomInstanceBySubjectIdCustom,
      {
        subjectId: this.conciliationService.subjectId,
      }
    );
    const symptomInstances = response.data.SymptomInstanceBySubjectId;

    //Obtiene los recordsLogs
    this.symptomRecordLogs = await this.getSymptomRecordsLogs();

    const response2 = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.VerifiedSymptomInstanceBySubjectIdCustom,
      {
        subjectId: this.conciliationService.subjectId,
      }
    );
    //Obtiene los sintomas verificados
    const verifiedSymptomInstances =
      response2.data.verifiedSymptomInstanceBySubjectId;

    this.verifiedSymptomInstances = verifiedSymptomInstances?.items.filter(
      (verifiedSymptom: any) =>
        !verifiedSymptom._deleted &&
        verifiedSymptom.state !== InstanceState.CONCILIATION_DELETED
    );
    const confSymptoms: any[] = [];
    for (const dayInstanceConf of dayInstancesAndConfigurations) {
      const confFormId = dayInstanceConf.configuration.id;
      //Obtiene la instancia de los sintomas segun su dayInstanceId
      let { items } = symptomInstances;
      items = items.filter(
        (item: any) =>
          !item._deleted &&
          item.state !== InstanceState.DELETED &&
          item?.dayInstanceId === dayInstanceConf.dayInstance.id
      );
      this.symptomInstances.push(...items);

      //Obtiene configuraciones que se han cargado previamente con el mismo ConfFormId
      let confSymptomByConfForm = confSymptoms.find(
        (confSymptom: any) => confSymptom.confFormId === confFormId
      )?.confSymptoms;

      //Si no encuentra la configuraciones en el arreglo hace la peticion por el api
      if (!confSymptomByConfForm) {
        confSymptomByConfForm = await this.getConfSymptomByConfFormId(
          confFormId
        );
        confSymptoms.push({ confFormId, confSymptoms: confSymptomByConfForm });
      }

      const confSymptomsFilter = confSymptomByConfForm.map(
        (confSymptom: any) => ({
          confSymptom,
          dayInstance: dayInstanceConf.dayInstance,
          configuration: dayInstanceConf.configuration,
        })
      );

      this.confSymptoms.push(...confSymptomsFilter);
    }
  }

  async getConfSymptomByConfFormId(confFormId: string): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfSymptomByConfFormIdCustom,
      {
        confFormId,
      }
    );
    let { items } = response.data.confSymptomByConfFormId;

    return items
      .filter((confSymptomConfForm: any) => !confSymptomConfForm._deleted)
      .filter(
        (confSymptomConfForm: any) => !confSymptomConfForm.confSymptom._deleted
      )
      .map((confSymptomConfForm: any) => confSymptomConfForm.confSymptom);
  }

  async getSymptomsMedicationAndMedicalAttention() {
    const dayInstancesAndConfigurations = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    this.symptoms = [];
    //Añade los otros sintomas al arreglo
    this.fillOtherSymptomsInstance();

    for (const dayInstanceConf of dayInstancesAndConfigurations) {
      //SI LA TEMPERATURA ESTA ACTIVA LA AÑADE AL ARREGLO
      if (dayInstanceConf.configuration?.isTemperatureRequired) {
        this.symptoms.push({
          id: Symptom.FEVER,
          symptomLabel: this.trialpalService.translateService.instant(
            'symptom.enums.symptoms.' + Symptom.FEVER
          ),
          createdAt: '',
          typeOther: false,
          ocurrend: true,
          dayInstanceId: dayInstanceConf.dayInstance.id,
        });
      }

      let verifiedDayInstance =
        await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
          dayInstanceConf.dayInstance.id
        );

      //Añade los sintomas segun su ocurrencia
      this.fillConfSymptoms(
        dayInstanceConf.dayInstance.id,
        verifiedDayInstance?.symptomOcurrencies
      );
    }
    //Añade la traduccion al label de los sintomas
    this.symptoms = this.symptoms.map((sym: any) => {
      return {
        ...sym,
        symptomLabel: this.trialpalService.dictionaryPipe.transform(
          sym.symptomLabel
        ),
      };
    });
    //Agrega como true la ocurrencia los sintomas que se encuentran en las ocurrencias del día
    this.symptoms.sort((a: any, b: any) =>
      a.symptomLabel > b.symptomLabel ? 1 : -1
    );
    const enumSymptoms = this.trialpalService.translateService.instant(
      'symptom.enums.symptoms'
    );
    this.symptoms.forEach((symptom) => {
      if (symptom.symptomLabel in enumSymptoms) {
        symptom.symptomLabel = this.trialpalService.translateService.instant(
          'symptom.enums.symptoms.' + symptom.symptomLabel
        );
      }
    });
  }

  //añade los otros sintomas al arreglo
  fillOtherSymptomsInstance() {
    if (this.tableOtherSymptoms?.length !== 0) {
      this.tableOtherSymptoms.forEach((_symptom: any) => {
        const symptom = _symptom?.verifiedOtherSymptom;
        //Obtiene si el id es de un sintoma o un sintoma verificado
        const symptomId =
          _symptom?.otherSymptom?.id ===
          this.conciliationService.NEW_INSTANCE_ID
            ? symptom?.id
            : _symptom?.otherSymptom.id;

        if (symptom) {
          this.symptoms.push({
            id: symptomId,
            symptomLabel: this.getOtherSymptomName(symptom),
            createdAt: symptom.createdAt,
            typeOther: true,
            ocurrend: true,
            dayInstanceId: _symptom?.otherSymptom?.dayInstanceId,
            state: symptom.state,
          });
        }
      });
    }
  }

  getOtherSymptomName(data: {
    symptom: string;
    whichOtherSymptom: string;
  }): string {
    return data?.symptom === 'OTHER'
      ? data?.whichOtherSymptom || ''
      : data?.symptom || '';
  }

  //Añade las ocurrencias a los sintomas que se mostraran en atenciones medicas o medicamentos
  fillConfSymptoms(dayInstanceId: string, symptomOcurrencies: any[] = []) {
    symptomOcurrencies?.forEach((ocurrency: any) => {
      //Obtiene la configuracion del sintoma
      const confSymptom = this.confSymptoms.find(
        (confSymptom: ConfSymptomInformation) =>
          confSymptom?.confSymptom?.id === ocurrency?.confSymptomId
      );

      //Valida si el sujeto presento o no el sintoma
      const isOcurrency = ocurrency?.occurrency ?? false;
      if (confSymptom?.confSymptom?.id) {
        this.symptoms.push({
          id: confSymptom?.confSymptom?.id,
          symptomLabel: confSymptom.confSymptom.symptomLabel
            ? confSymptom.confSymptom.symptomLabel
            : confSymptom.confSymptom.symptom,
          createdAt: '',
          typeOther: false,
          ocurrend: isOcurrency,
          dayInstanceId: dayInstanceId,
        });
      }
    });
  }

  //funcion que se encarga de obtiene todas las configuraciones de los otros sintomas
  async getConfOtherSymptoms() {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    //Filtra los días que tengan habilitado la opcion de otros sintomas
    dayInstances = dayInstances.filter((DI: any) => {
      return (
        DI.configuration.areOtherSymptomsRequired &&
        this.conciliationService.isBeforeToCurrentDate(
          DI.dayInstance.finishDate
        )
      );
    });

    const confOtherSymptoms = await this.getConfOtherSymptomsByConfEdiaryId();

    for await (let dayInstance of dayInstances) {
      const confFormId = dayInstance.dayInstance?.confDay?.confFormId || '';

      let configuration = this.addConfOtherSymptoms.find(
        (configuration: any) => configuration.confFormId === confFormId
      )?.confOtherSymptom;

      if (!configuration) {
        configuration = confOtherSymptoms.find(
          (confOtherSymptom: any) => confOtherSymptom.confFormId === confFormId
        );
      }

      this.addConfOtherSymptoms.push({
        confFormId,
        confOtherSymptom: configuration,
        dayInstance: dayInstance.dayInstance,
      });
    }
  }

  async getConfOtherSymptomsByConfEdiaryId(): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfOtherSymptomsByConfEdiary,
      {
        confEdiaryId: this.conciliationService.confEdiaryId,
      }
    );
    const { items } = response.data.confOtherSymptomsByConfEdiary;
    return items.filter((confOtherSymptom: any) => !confOtherSymptom._deleted);
  }

  async getConfOtherSymptomsByEdiaryId(ediaryId: string): Promise<any> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfOtherSymptomsByConfEdiary,
      {
        confEdiaryId: ediaryId,
      }
    );
    const { items } = response.data.confOtherSymptomsByConfEdiary;
    return items;
  }

  //=================== Campos para la creacion/edicion de sintomas =======================

  async createVerifiedSymptom(symptomInstance: any, data: any) {
    //Obtiene el dia verificado
    const verifiedDayInstance =
      await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
        symptomInstance.dayInstanceId
      );

    this.conciliationService.transformDate(data);
    data = this.clearIntensityQuestionsAnswers(data);
    let input: CreateVerifiedSymptomInstanceInput = {
      confSymptomId: getValueOrDefault(symptomInstance?.confSymptomId),
      finishDate: getValueOrDefault(symptomInstance?.finishDate),
      finishDateComment: getValueOrDefault(symptomInstance?.finishDateComment),
      hospitalAdmission: symptomInstance?.hospitalAdmission,
      hospitalAdmissionComment: getValueOrDefault(
        symptomInstance?.hospitalAdmissionComment
      ),
      intensity: symptomInstance?.intensity,
      intensityComment: getValueOrDefault(symptomInstance?.intensityComment),
      intensityQuestionAnswers: symptomInstance?.intensityQuestionAnswers,
      intensityQuestionAnswersComment: getValueOrDefault(
        symptomInstance?.intensityQuestionAnswersComment
      ),
      isOtherSymptom: symptomInstance.isOtherSymptom,
      medicalAttentions: symptomInstance?.medicalAttentions,
      medicalAttentionsComment: getValueOrDefault(
        symptomInstance?.medicalAttentionsComment
      ),
      medications: symptomInstance?.medications,
      medicationsComment: getValueOrDefault(symptomInstance?.medications, 0),
      professionalHealthCare: symptomInstance?.professionalHealthCare,
      professionalHealthCareComment: getValueOrDefault(
        symptomInstance?.professionalHealthCareComment
      ),
      rememberFinishDate: symptomInstance?.rememberFinishDate,
      rememberFinishDateComment: getValueOrDefault(
        symptomInstance?.rememberFinishDateComment
      ),
      rememberNoValuesTaken: symptomInstance?.rememberNoValuesTaken,
      rememberStartDate: symptomInstance?.rememberStartDate,
      rememberStartDateComment: getValueOrDefault(
        symptomInstance?.rememberStartDateComment
      ),
      size: symptomInstance?.size,
      sizeComment: getValueOrDefault(symptomInstance?.sizeComment),
      state: InstanceState.UNSIGNED,
      startDate: getValueOrDefault(symptomInstance?.startDate),
      startDateComment: symptomInstance?.startDateComment,
      subjectId: symptomInstance.subjectId,
      symptom: symptomInstance?.symptom,
      symptomInstanceId: symptomInstance.id,
      type: symptomInstance?.type,
      verifiedDayInstanceId: verifiedDayInstance.id,
      whichOtherSymptom: symptomInstance?.whichOtherSymptom,
      whichOtherSymptomComment: getValueOrDefault(
        symptomInstance?.whichOtherSymptomComment
      ),
      rememberNoValuesTakenComment: getValueOrDefault(
        symptomInstance?.rememberNoValuesTakenComment
      ),
      _changeReason: getValueOrDefault(symptomInstance?._changeReason),
      _lastUser: this.authService.getUsername(),
    };
    input = this.clearIntensityQuestionsAnswers(input);
    input = Object.assign(input, data);
    logger.debug(input, 'createVerifiedSymptom input');
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateVerifiedSymptomInstance,
      {
        input,
      }
    );
    return response.data.createVerifiedSymptomInstance;
  }

  async updateVerifiedSymptom(
    verifiedSymptom: any,
    data: any
  ): Promise<VerifiedSymptomInstance> {
    this.conciliationService.transformDate(data, verifiedSymptom);
    data = this.clearIntensityQuestionsAnswers(data);

    let input: UpdateVerifiedSymptomInstanceInput = {
      _lastUser: this.authService.getUsername(),
      id: verifiedSymptom.id,
      state: InstanceState.UNSIGNED,
      _version: verifiedSymptom._version,
      _changeReason: verifiedSymptom._changeReason,
      intensityQuestionAnswers: data?.intensityQuestionAnswers,
    };

    input = Object.assign(input, data);

    logger.debug(input, 'UpdateVerifiedSymptomInstanceInput');
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateVerifiedSymptomInstance,
      {
        input,
      }
    );
    return response.data.updateVerifiedSymptomInstance;
  }

  async createVerifiedSymptomRecordLog(
    symptomRecordLog: any,
    verifiedSymptomInstance: any,
    verifiedDayInstanceId: string
  ) {
    let input: CreateVerifiedSymptomRecordLogInput = {
      _lastUser: this.authService.getUsername(),
      verifiedDayInstanceId: verifiedDayInstanceId,
      subjectId: verifiedSymptomInstance?.subjectId,
      verifiedSymptomInstanceId: verifiedSymptomInstance?.id,
      intensity: verifiedSymptomInstance?.intensity,
      size: verifiedSymptomInstance?.size,
      intensityComment: verifiedSymptomInstance?.intensityComment,
      sizeComment: verifiedSymptomInstance?.sizeComment,
      symptomRecordLogId: symptomRecordLog?.id,
      state: verifiedSymptomInstance?.state,
    };

    logger.debug(input, 'CreateVerifiedSymptomRecordLogInput');
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateVerifiedSymptomRecordLog,
      {
        input,
      }
    );
    return response.data.createVerifiedSymptomRecordLog;
  }

  async updateVerifiedSymptomRecordLog(
    verifiedSymptomInstance: any,
    verifiedSymptomRecordLog: any,
    verifiedDayInstanceId: string
  ) {
    let input: UpdateVerifiedSymptomRecordLogInput = {
      id: verifiedSymptomRecordLog.id,
      verifiedDayInstanceId: verifiedDayInstanceId,
      _lastUser: this.authService.getUsername(),
      intensity: verifiedSymptomInstance?.intensity,
      intensityComment: verifiedSymptomInstance?.intensityComment,
      size: verifiedSymptomInstance?.size,
      sizeComment: verifiedSymptomInstance?.sizeComment,
      state: verifiedSymptomInstance?.state,
      _version: verifiedSymptomRecordLog._version,
    };

    logger.debug(input, 'UpdateVerifiedSymptomRecordLogInput');
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateVerifiedSymptomRecordLog,
      {
        input,
      }
    );
    return response.data.updateVerifiedSymptomRecordLog;
  }

  async deleteVerifiedSymptomRecordLog(verifiedSymptomRecordLog: any) {
    const input: DeleteVerifiedSymptomRecordLogInput = {
      id: verifiedSymptomRecordLog.id,
      _version: verifiedSymptomRecordLog._version,
    };

    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.DeleteVerifiedSymptomRecordLog,
      {
        input,
      }
    );

    return response.data.deleteVerifiedSymptomRecordLog;
  }

  clearIntensityQuestionsAnswers(data: any) {
    if (data?.intensityQuestionAnswers?.length > 0) {
      data.intensityQuestionAnswers = data.intensityQuestionAnswers.map(
        (intensityQuestionAnswer: any) => {
          delete intensityQuestionAnswer.__typename;
          delete intensityQuestionAnswer.question.__typename;
          return intensityQuestionAnswer;
        }
      );
    }
    return data;
  }

  //===================Campos para la validación de sintomas =======================
  //Obtiene el rango de valores cuando el sintoma es por rango
  getIntRange(valuesEscale: any[]) {
    const intRange = [];
    const values: number[] = [];
    valuesEscale.forEach((scaleOption: ScaleRangeOptions) => {
      const startValues = scaleOption.startValue.toString().split('.');
      if (startValues?.[0]) {
        values.push(parseInt(startValues[0]));
      }
      const endValues = scaleOption.endValue.toString().split('.');
      if (endValues?.[0]) {
        values.push(parseInt(endValues[0]));
      }
    });
    const maxValues = Math.max(...values);
    const minValues = Math.min(...values);

    for (let i = minValues; i <= maxValues; i++) {
      intRange.push(i.toString());
    }

    return intRange;
  }

  //Obtiene los el rango de decimales si este está habilitado dentro del sintoma actual
  getDecimalRange(confSymptom: any): any[] {
    let decimalRange = [];
    const decimalPlaces = confSymptom?.decimalPlaces
      ? confSymptom?.decimalPlaces
      : 0;

    for (let i = 0; i < Math.pow(10, decimalPlaces); i++) {
      decimalRange.push(i.toString().padStart(decimalPlaces, '0'));
    }

    return decimalRange;
  }

  getValueScale(confSymptom: any) {
    const valuesEscale = [];
    if (confSymptom.noneIntensityScaleOptions?.active) {
      const noneStart: ScaleRangeOptions = {
        startValue: confSymptom.noneIntensityScaleOptions.startValue,
        endValue: confSymptom.noneIntensityScaleOptions.endValue,
        intensity: Intensity.NONE,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.NONE
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.noneIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(noneStart);
    }

    if (confSymptom.lowIntensityScaleOptions?.active) {
      const lowStart: ScaleRangeOptions = {
        startValue: confSymptom.lowIntensityScaleOptions.startValue,
        endValue: confSymptom.lowIntensityScaleOptions.endValue,
        intensity: Intensity.LOW,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.LOW
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.lowIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(lowStart);
    }
    if (confSymptom.mediumIntensityScaleOptions?.active) {
      const mediumStart: ScaleRangeOptions = {
        startValue: confSymptom.mediumIntensityScaleOptions.startValue,
        endValue: confSymptom.mediumIntensityScaleOptions.endValue,
        intensity: Intensity.MEDIUM,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.MEDIUM
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.mediumIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(mediumStart);
    }

    if (confSymptom.highIntensityScaleOptions?.active) {
      const highStart: ScaleRangeOptions = {
        startValue: confSymptom.highIntensityScaleOptions.startValue,
        endValue: confSymptom.highIntensityScaleOptions.endValue,
        intensity: Intensity.HIGH,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.HIGH
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.highIntensityScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(highStart);
    }

    if (confSymptom.lifeThreateningScaleOptions?.active) {
      const lifeStart: ScaleRangeOptions = {
        startValue: confSymptom.lifeThreateningScaleOptions.startValue,
        endValue: confSymptom.lifeThreateningScaleOptions.endValue,
        intensity: Intensity.LIFE_THREATENING,
        label: `${this.trialpalService.translateService.instant(
          'symptom.enums.intensityTypes.' + Intensity.LIFE_THREATENING
        )} (${this.trialpalService.dictionaryPipe.transform(
          confSymptom.lifeThreateningScaleOptions.helpText
        )})`,
      };
      valuesEscale.push(lifeStart);
    }
    return valuesEscale;
  }

  /**
   * Obtener la Mayor Intesidad de las preguntas
   * @returns Intensity
   */
  getIntenityFromQuestion(intensityQuestionAnswersSave: any[] = []) {
    // Filtro las Preguntas Positivas
    const intensityQuestionPositive = intensityQuestionAnswersSave.filter(
      (x: any) => x.answer === true
    );
    if (!intensityQuestionPositive.length) {
      return undefined;
    }
    // Filtro las Intensidades Repetidas
    const hash: any = {};
    const intensityQuestionUnique = intensityQuestionPositive.filter(
      (current: any) => {
        const exists = !hash[current.question.intensity];
        hash[current.question.intensity] = true;
        return exists;
      }
    );
    // Creo una lista de Intensidades
    const intensityQuestionOrderInit = intensityQuestionUnique.map((y: any) => {
      return {
        intensity: y.question.intensity,
      };
    });
    // obtener el indice del array de Intensidades
    return this.getIntensityOrder(intensityQuestionOrderInit);
  }

  getIntensityOrder(intensityQuestionOrderInit: any[] = []) {
    const intensities = [
      Intensity.NONE,
      Intensity.LOW,
      Intensity.MEDIUM,
      Intensity.HIGH,
      Intensity.LIFE_THREATENING,
    ];
    // obtener el indice del array de Intensidades
    const intensityQuestionOrder = [];
    for (const intensityQuestion of intensityQuestionOrderInit) {
      const order = intensities.findIndex(
        (y: any) => y === intensityQuestion.intensity
      );
      intensityQuestionOrder.push({
        intensity: intensityQuestion.intensity,
        order: order,
      });
    }
    intensityQuestionOrder.sort((a, b) => b.order - a.order);
    return intensityQuestionOrder[0]?.intensity;
  }

  //==================Funciones para transformar la data ===============
  transformQuestions(intensityQuestionAnswers: any) {
    const questions: any[] = [];
    if (intensityQuestionAnswers && intensityQuestionAnswers?.length > 0) {
      intensityQuestionAnswers.forEach((answer: any) => {
        const questionAnswer = answer.answer;
        let translateAnswer = '';
        if (questionAnswer !== null && questionAnswer !== undefined) {
          translateAnswer = questionAnswer ? 'general.yes' : 'general.no';
        } else {
          translateAnswer = 'N/A';
        }
        questions.push(
          `${this.trialpalService.dictionaryPipe.transform(
            answer?.question?.question
          )}: ${this.trialpalService.translateService.instant(translateAnswer)}`
        );
      });
    }

    return questions?.join('\n');
  }

  //Añade los comentarios a la tabla de otros sintomas y al arreglo
  addOtherSymptomComments() {
    let indexComment = 1;
    this.otherSymptomsComments = [];
    for (const precondicion of this.otherSymptomColDays) {
      if (
        precondicion.verifiedDayInstance?.id &&
        precondicion.verifiedDayInstance?.hasOtherSymptomsComment
      ) {
        this.addOtherSymptomObservation(
          precondicion.verifiedDayInstance?.hasOtherSymptomsComment,
          indexComment
        );
        indexComment++;
      }
    }
    this.tableOtherSymptoms = this.tableOtherSymptoms.map(
      (otherSymptom: any) => {
        const verifiedOtherSymptom = otherSymptom?.verifiedOtherSymptom ?? {};
        const {
          whichOtherSymptomComment,
          intensityComment,
          professionalHealthCareComment,
          hospitalAdmissionComment,
          startDateComment,
          finishDateComment,
        } = verifiedOtherSymptom;
        otherSymptom.comments = {};
        //Valida si tiene comentarios y los añade a la tabla y arreglo
        if (whichOtherSymptomComment) {
          otherSymptom.comments.whichOtherSymptomComment =
            whichOtherSymptomComment;
          otherSymptom.comments.whichOtherSymptomCommentIndex = indexComment;
          this.addOtherSymptomObservation(
            whichOtherSymptomComment,
            indexComment
          );
          indexComment++;
        }
        if (intensityComment) {
          otherSymptom.comments.intensityComment = intensityComment;
          otherSymptom.comments.intensityCommentIndex = indexComment;
          this.addOtherSymptomObservation(intensityComment, indexComment);
          indexComment++;
        }

        if (professionalHealthCareComment) {
          otherSymptom.comments.professionalHealthCareComment =
            professionalHealthCareComment;
          otherSymptom.comments.professionalHealthCareCommentIndex =
            indexComment;
          this.addOtherSymptomObservation(
            professionalHealthCareComment,
            indexComment
          );
          indexComment++;
        }

        if (hospitalAdmissionComment) {
          otherSymptom.comments.hospitalAdmissionComment =
            hospitalAdmissionComment;
          otherSymptom.comments.hospitalAdmissionCommentIndex = indexComment;
          this.addOtherSymptomObservation(
            hospitalAdmissionComment,
            indexComment
          );
          indexComment++;
        }
        if (startDateComment) {
          otherSymptom.comments.startDateComment = startDateComment;
          otherSymptom.comments.startDateCommentIndex = indexComment;
          this.addOtherSymptomObservation(startDateComment, indexComment);
          indexComment++;
        }
        if (finishDateComment) {
          otherSymptom.comments.finishDateComment = finishDateComment;
          otherSymptom.comments.finishDateCommentIndex = indexComment;
          this.addOtherSymptomObservation(finishDateComment, indexComment);
          indexComment++;
        }
        return otherSymptom;
      }
    );
  }

  addOtherSymptomObservation(comment: string, indexComment: number) {
    if (comment.length > 0) {
      this.otherSymptomsComments.push({
        index: indexComment,
        comment: comment,
      });
    }
  }

  // Añade comentarios a la tabla de síntomas y devuelve un arreglo de comentarios
  addSymptomInstanceComments(tableSymptoms: TableSymptom[]): {
    comments: Comment[];
    tableSymptoms: TableSymptom[];
  } {
    const comments: Comment[] = []; //Arreglo de comentarios que se muestra en el footer

    for (const symptomInstance of tableSymptoms) {
      const verifiedSymptom: any =
        symptomInstance?.verifiedSymptomInstance || {};
      if (!verifiedSymptom) continue;
      //Construye la lista de comentarios y su validacion
      //key and validation
      const commentsToAdd: CommentAddInfo[] =
        this.getCommentsKey(verifiedSymptom);
      for (const { key, indexKey, validator } of commentsToAdd) {
        if (!validator) continue; //Si no existe comentario salta al siguiente
        const index = comments.length + 1;
        comments.push({ index: index, comment: verifiedSymptom[key] });
        //Añade los comentarios y su index en la tabla de sintomas
        symptomInstance.comments = symptomInstance.comments || {};
        symptomInstance.comments[key] = verifiedSymptom[key];
        symptomInstance.comments[indexKey] = index;
      }
    }

    return { comments, tableSymptoms };
  }

  getCommentsKey(verifiedSymptom: any): CommentAddInfo[] {
    return [
      {
        key: 'occurrencyComment',
        indexKey: 'occurrencyCommentIndex',
        validator: verifiedSymptom?.occurrencyComment,
      },
      {
        key: 'rememberNoValuesTakenComment',
        indexKey: 'rememberNoValuesTakenCommentIndex',
        validator: verifiedSymptom?.rememberNoValuesTaken,
      },
      {
        key: 'intensityComment',
        indexKey: 'intensityCommentIndex',
        validator:
          verifiedSymptom?.intensityComment &&
          !verifiedSymptom?.rememberNoValuesTaken,
      },
      {
        key: 'sizeComment',
        indexKey: 'sizeCommentIndex',
        validator: verifiedSymptom?.sizeComment,
      },
      {
        key: 'intensityQuestionAnswersComment',
        indexKey: 'intensityQuestionAnswersCommentIndex',
        validator: verifiedSymptom?.intensityQuestionAnswersComment,
      },
      {
        key: 'startDateComment',
        indexKey: 'startDateCommentIndex',
        validator: verifiedSymptom?.startDateComment,
      },
      {
        key: 'finishDateComment',
        indexKey: 'finishDateCommentIndex',
        validator: verifiedSymptom?.finishDateComment,
      },
    ];
  }

  getOtherSymptomProgressBarValues() {
    const coldDaysVerifieds: any[] =
      this.otherSymptomColDays.filter(
        (day: any) => day.verifiedDayInstance?.hasOtherSymptomsCompleted
      ) || [];

    const otherSymptomsVerifieds: any[] =
      this.tableOtherSymptoms.filter((otherSymptom: any) => {
        return otherSymptom.verifiedOtherSymptom?.id;
      }) || [];

    let cantColDays = this.otherSymptomColDays?.length - 1 || 0;
    let cantTableOtherSymptoms = this.tableOtherSymptoms?.length || 0;

    return [
      cantColDays,
      cantTableOtherSymptoms,
      coldDaysVerifieds?.length || 0,
      otherSymptomsVerifieds?.length || 0,
    ];
  }

  getSymptomGeneralProgressBar(): number {
    const detailSymptomComponent = this.getDetailSymptomComponentBySymptomType(
      SymptomType.GENERAL
    );
    return this.getProgressByDetailSymptomComponent(detailSymptomComponent);
  }

  getSymptomLocalProgressBar(): number {
    const detailSymptomComponent = this.getDetailSymptomComponentBySymptomType(
      SymptomType.LOCAL
    );
    return this.getProgressByDetailSymptomComponent(detailSymptomComponent);
  }

  /**
   * Calcula el progreso de un componente DetailSymptomComponent en función de la cantidad de instancias de síntomas verificadas.
   * @param detailSymptomComponent El componente DetailSymptomComponent del cual se desea calcular el progreso.
   * @returns El progreso del componente como un valor entre 0 y 100.
   */
  getProgressByDetailSymptomComponent(
    detailSymptomComponent: DetailSymptomComponent
  ): number {
    if (!detailSymptomComponent) return 0;
    const tableSymptoms = detailSymptomComponent.symptomOcurrencesTable.reduce(
      (acc: any, curr) => acc.concat(curr.tableSymptoms),
      []
    );
    const cantSymptomInstance = tableSymptoms?.length ?? 0;
    const verifiedSymptomInstance = tableSymptoms?.filter(
      (SI: TableSymptom) => SI.verifiedSymptomInstance?.id
    );

    const cantVerifiedSymptomInstance = verifiedSymptomInstance?.length ?? 0;

    return cantSymptomInstance === 0
      ? 100
      : (cantVerifiedSymptomInstance / cantSymptomInstance) * 100;
  }

  /**
   * Obtiene el componente DetailSymptomComponent correspondiente según el tipo de síntoma proporcionado.
   * @param symptomType El tipo de síntoma para el que se desea obtener el componente DetailSymptomComponent.
   * @returns El componente DetailSymptomComponent apropiado basado en el tipo de síntoma.
   */
  getDetailSymptomComponentBySymptomType(
    symptomType: SymptomType
  ): DetailSymptomComponent {
    // Get the appropriate detail symptom component based on the symptom type
    return symptomType === SymptomType.GENERAL
      ? this.detailSymptomComponentGeneral
      : this.detailSymptomComponentLocal;
  }

  get getOtherSymptomProgressBar() {
    let [
      cantColDays,
      cantTableOtherSymptoms,
      cantColdDaysVerifieds,
      cantOtherSymptomsVerifieds,
    ] = this.getOtherSymptomProgressBarValues();

    const progress =
      ((cantColdDaysVerifieds + cantOtherSymptomsVerifieds) /
        (cantColDays + cantTableOtherSymptoms)) *
      100;

    return cantColDays + cantTableOtherSymptoms === 0 ? 100 : progress;
  }

  getEmptyOtherSymptom(dayInstanceId: string, configurationId: string): any {
    return {
      id: this.conciliationService.NEW_INSTANCE_ID,
      subjectId: this.conciliationService.subjectId,
      symptom: '',
      state: InstanceState.UNSIGNED,
      confSymptomId: configurationId,
      dayInstanceId: dayInstanceId,
      finishDate: '',
      intensity: '',
      isOtherSymptom: true,
      professionalHealthCare: 'NO',
      whichOtherSymptom: '',
      hospitalAdmission: 'NO',
      startDate: '',
      rememberFinishDate: null,
      rememberStartDate: null,
    };
  }

  getSymptomInstanceEmpty(
    confSymptom: ConfSymptom,
    dayInstanceId: string
  ): any {
    const intensityQuestionAnswers = this.buildIntensityQuestionAnswers(
      confSymptom,
      []
    );
    return {
      id: confSymptom.id + 'new',
      symptom: confSymptom.symptom,
      state: InstanceState.UNSIGNED,
      order: 1,
      confSymptomId: confSymptom?.id,
      dayInstanceId: dayInstanceId,
      finishDate: null,
      intensity: null,
      intensityQuestionAnswers: intensityQuestionAnswers,
      rememberFinishDate: null,
      rememberNoValuesTaken: null,
      size: null,
      type: confSymptom.type,
      startDate: null,
      rememberStartDate: null,
      subjectId: this.conciliationService.subjectId,
      isOtherSymptom: false,
    };
  }

  getSymptomRecordLogEmpty(confSymptom: any, dayInstanceId: string): any {
    return {
      dayInstanceId: dayInstanceId,
      id: confSymptom?.id + 'new',
      subjectId: this.conciliationService.subjectId,
      symptomInstanceId: confSymptom?.id + 'new',
      intensity: null,
      size: null,
    };
  }

  buildIntensityQuestionAnswers(confSymptom: any, answers: any[] = []) {
    const intensityQuestionAnswers: any[] = [];
    const intensityQuestions = confSymptom?.intensityQuestions || [];
    for (let intensityQuestion of intensityQuestions) {
      const answer = answers?.find(
        (answer) => answer.question.id === intensityQuestion?.id
      );
      intensityQuestionAnswers.push({
        answer: answer?.answer,
        question: {
          id: intensityQuestion?.id || Math.random() * 100000,
          intensity: intensityQuestion?.intensity || Intensity.LOW,
          question: intensityQuestion.question,
          order: intensityQuestion?.order ?? 1,
        },
      });
    }
    return intensityQuestionAnswers;
  }

  //Create symptomRecordLog and updateOcurrencies
  async createOrUpdateSymptomRecordLog(
    verifiedSymptom: VerifiedSymptomInstance,
    dayInstanceId: string,
    symptomRecordLogs: any[]
  ) {
    const symptomRecordLog = symptomRecordLogs[0];
    let verifiedSymptomRecordLog = null;
    const verifiedDayInstance =
      await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
        dayInstanceId
      );
    if (symptomRecordLog) {
      if (!symptomRecordLog?.isEdition) {
        verifiedSymptomRecordLog = await this.createVerifiedSymptomRecordLog(
          symptomRecordLog.symptomRecordLog,
          verifiedSymptom,
          verifiedDayInstance.id
        );
      } else {
        verifiedSymptomRecordLog = await this.updateVerifiedSymptomRecordLog(
          verifiedSymptom,
          symptomRecordLog?.verifiedSymptomRecordLog,
          verifiedDayInstance.id
        );
      }

      if (verifiedSymptomRecordLog) {
        symptomRecordLogs[0].verifiedSymptomRecordLog =
          verifiedSymptomRecordLog;
        symptomRecordLogs[0].isEdition = true;
      }
    }
    return verifiedSymptomRecordLog;
  }

  /**
   * Actualiza la tabla de ocurrencias de síntomas con la información proporcionada por la instancia verificada del síntoma.
   * @param verifiedSymptomInstance La instancia verificada del síntoma.
   * @returns Una promesa que se resuelve cuando se completa la actualización de la tabla.
   */
  async updateTable(
    verifiedSymptomInstance: VerifiedSymptomInstance,
    updateVerifiedSymptomOccurrency: SymptomOccurrency | undefined,
    currentSymptom: TableSymptom,
    detailSymptomComponent: DetailSymptomComponent
  ): Promise<void> {
    //Obtiene las instancias actuales necesarias
    const { symptomInstance, dayInstance, confSymptom, symptomOccurrency } =
      currentSymptom;

    //Obtiene los recordsLogs asociados
    const symptomRecordLogs = await this.buildSymptomRecordLogs(
      symptomInstance?.id ?? '',
      verifiedSymptomInstance,
      dayInstance
    );

    //Si no se actualizo la ocurrencia se obtiene la ocurrencia creada desde la tabla
    const verifiedSymptomOccurrency =
      updateVerifiedSymptomOccurrency ??
      currentSymptom.verifiedSymptomOccurrency;

    //Construye la información de la tabla
    const updateCurrentSymptom =
      detailSymptomComponent.buildTableSymptomInformation({
        symptomInstance,
        verifiedSymptomInstance,
        symptomOccurrency,
        verifiedSymptomOccurrency,
        dayInstance,
        confSymptom,
        symptomRecordLogs,
      });

    for (let symptomOcurrenceTable of detailSymptomComponent.symptomOcurrencesTable) {
      symptomOcurrenceTable.tableSymptoms = this.updateSymptomsTable(
        symptomOcurrenceTable.tableSymptoms,
        updateCurrentSymptom,
        currentSymptom
      );

      symptomOcurrenceTable.occurrences = this.updateOccurrencesTable(
        symptomOcurrenceTable.occurrences,
        verifiedSymptomOccurrency?.occurrency,
        currentSymptom
      );
    }

    detailSymptomComponent.symptomOcurrencesTable = [
      ...detailSymptomComponent.symptomOcurrencesTable,
    ];
    detailSymptomComponent.createAndAssignSymptomComments();
  }

  buildSymptomRecordLogs(
    symptomInstanceId: string,
    verifiedSymptomInstance: VerifiedSymptomInstance,
    dayInstance: DayInstance
  ) {
    //Obtiene los recordsLogs asociados
    let symptomRecordLogs = this.symptomRecordLogs.filter(
      (_symptomRecordlog: any) => {
        return _symptomRecordlog.symptomInstanceId === symptomInstanceId;
      }
    );

    return this.fillSymptomRecordLogs(
      verifiedSymptomInstance?.id || '',
      symptomRecordLogs,
      dayInstance
    );
  }

  async updateOcurrency(
    verifiedSymptom: VerifiedSymptomInstance,
    dayInstanceId: string,
    verifiedSymptomRecordLog: any,
    symptomOcurrency: boolean,
    _changeReason: string
  ): Promise<SymptomOccurrency> {
    //Obtiene el dia verificado
    const verifiedDayInstance =
      await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
        dayInstanceId
      );

    //Obtiene los symptomOcurrencies del dia
    const currentSymptomOcurrences =
      verifiedDayInstance?.symptomOcurrencies || [];
    let occurrences = this.buildSymptomOccurrences(
      dayInstanceId,
      currentSymptomOcurrences
    );

    //Nuevo
    const occurrency: SymptomOccurrency = {
      occurrency: symptomOcurrency,
      symptomInstanceId: verifiedSymptom?.id ?? '',
      symptomRecordLogId: verifiedSymptomRecordLog?.id ?? '',
      confSymptomId: verifiedSymptom?.confSymptomId ?? '',
      order: 0,
    } as SymptomOccurrency;

    const occurrencyIndex = occurrences?.findIndex((_occurrency: any) => {
      return _occurrency?.confSymptomId === verifiedSymptom?.confSymptomId;
    });

    //Crea ó Actualiza la ocurrencia del sintoma
    if (occurrencyIndex === -1) {
      occurrences.push(occurrency);
    } else {
      occurrences[occurrencyIndex] = occurrency;
    }

    //Actualiza el dia con los symptomOccurencies
    const verifiedDayInstanceUpdate =
      await this.dayInstanceService.updateVerifiedDayInstance(
        {
          symptomOcurrencies: occurrences,
          _changeReason: _changeReason ?? verifiedDayInstance?._changeReason,
        },
        verifiedDayInstance
      );

    this.dayInstanceService.updateListVerifiedDayInstances(
      verifiedDayInstanceUpdate
    );
    logger.debug('verifiedDayInstance ocurrencies', verifiedDayInstanceUpdate);
    return occurrency;
  }

  /**
   * Actualiza la lista de síntomas de la tabla, reemplazando el síntoma actualmente seleccionado con el síntoma actualizado proporcionado.
   * @param tableSymptoms Lista de síntomas de la tabla a actualizar.
   * @param updateCurrentSymptom El síntoma actualizado que se utilizará para reemplazar el síntoma actualmente seleccionado.
   * @returns Una nueva lista de síntomas de la tabla con el síntoma actualizado reemplazando el síntoma actualmente seleccionado.
   */
  updateSymptomsTable(
    tableSymptoms: TableSymptom[],
    updateCurrentSymptom: TableSymptom,
    currentSymptom: TableSymptom
  ) {
    return tableSymptoms.map((symptomItem) => {
      return symptomItem.key === currentSymptom.key
        ? updateCurrentSymptom
        : symptomItem;
    });
  }

  /**
   * Actualiza la propiedad `verifiedOccurrency` de cada ocurrencia de síntoma en la lista proporcionada
   * con el valor especificado `verifiedOccurrency` para la ocurrencia del síntoma actual.
   * @param occurrences Lista de ocurrencias de síntomas a actualizar.
   * @param verifiedOccurrency Valor booleano que indica si la ocurrencia de síntoma está verificada.
   * @returns Una nueva lista de ocurrencias de síntomas con la propiedad `verifiedOccurrency` actualizada para la ocurrencia del síntoma actual.
   */
  updateOccurrencesTable(
    occurrences: Occurrency[],
    verifiedOccurrency: boolean | undefined | null,
    currentSymptom: TableSymptom
  ) {
    return occurrences.map((symptomOccurrency) => {
      const updateOcurrency: Occurrency = {
        confSymptom: symptomOccurrency.confSymptom,
        dayInstance: symptomOccurrency.dayInstance,
        key: symptomOccurrency.key,
        occurrency: symptomOccurrency.occurrency,
        verifiedOccurrency: verifiedOccurrency,
        isAssociated: symptomOccurrency.isAssociated,
      };
      return symptomOccurrency.key === currentSymptom.key
        ? updateOcurrency
        : symptomOccurrency;
    });
  }

  //Obtiene la referencia del componente modificado
  getCurrentDetailSymptomComponent(): DetailSymptomComponent {
    const symptomType = this.currentSymptom?.symptomType ?? this.symptomType;
    return this.getDetailSymptomComponentBySymptomType(
      symptomType as SymptomType
    );
  }

  buildSymptomOccurrences(dayInstanceId: string, symptomOcurrencies: any[]) {
    //Obtiene los symptomOcurrencies del dia
    let occurrencies: any = [];
    if (symptomOcurrencies?.length > 0) {
      symptomOcurrencies.forEach((occurrency: any) => {
        occurrencies.push({
          occurrency: occurrency?.occurrency ?? false,
          symptomInstanceId: occurrency?.symptomInstanceId,
          symptomRecordLogId: occurrency?.symptomRecordLogId,
          confSymptomId: occurrency?.confSymptomId,
          order: 0,
        });
      });
    }

    occurrencies = this.getEmptySymptomOcurrencies(dayInstanceId, occurrencies);

    return occurrencies;
  }

  //SI el arreglo esta en vacio construye la occurencia con la configuraciones
  getEmptySymptomOcurrencies(dayInstanceId: string, symptomOcurrencies: any[]) {
    if (symptomOcurrencies.length === 0) {
      const confSymptoms = this.confSymptoms.filter(
        (confSymptom: ConfSymptomInformation) => {
          return confSymptom.dayInstance.id === dayInstanceId;
        }
      );
      confSymptoms?.forEach((confSymptom: any) => {
        symptomOcurrencies.push({
          confSymptomId: confSymptom?.confSymptom?.id,
          symptomInstanceId: '',
          symptomRecordLogId: '',
          occurrency: false,
          order: 0,
        });
      });
    }

    return symptomOcurrencies;
  }

  async fillSymptomRecordLogs(
    verifiedSymptomInstanceId: string,
    symptomRecordLogs: any,
    dayInstance: any
  ) {
    return Promise.all(
      symptomRecordLogs?.map(async (SRL: any) => {
        const verifiedSymptomRecordLog =
          await this.getVerifiedSymptomRecordLogByRecordLogId(
            verifiedSymptomInstanceId
          );
        return {
          symptomRecordLog: SRL,
          verifiedSymptomRecordLog,
          isEdition: !!verifiedSymptomRecordLog?.id,
          dayInstance,
        };
      })
    );
  }

  getDeleteOtherSymptom(comment: string, verifiedDayInstanceId: string) {
    return {
      state: InstanceState.DELETED,
      whichOtherSymptomComment: comment,
      finishDateComment: null,
      hospitalAdmissionComment: null,
      intensityComment: null,
      professionalHealthCareComment: null,
      startDateComment: null,
      verifiedDayInstanceId: verifiedDayInstanceId,
      _changeReason: comment,
    };
  }

  async deleteVerifiedOtherSymptomInstanceTable(
    otherSymptom: any,
    verifiedOtherSymptom: any,
    otherSymptomInput: any
  ) {
    let isDelete = false;
    if (otherSymptom.id === this.conciliationService.NEW_INSTANCE_ID) {
      otherSymptomInput.state = InstanceState.CONCILIATION_DELETED;
      //Elimina la instancia de la tabla
      this.tableOtherSymptoms = this.tableOtherSymptoms.filter(
        (otherSymptom: any) => {
          return (
            otherSymptom?.verifiedOtherSymptom?.id !== verifiedOtherSymptom?.id
          );
        }
      );
      isDelete = true;
    }
    verifiedOtherSymptom = await this.updateVerifiedSymptom(
      verifiedOtherSymptom,
      otherSymptomInput
    );

    return { verified: verifiedOtherSymptom, isDelete };
  }

  // Function to remove duplicate objects based on the value of the "id" property
  removeDuplicateObjectsById(array: any[]): any[] {
    // Object to store unique objects
    const uniqueObjectMap: any = {};

    // Filter the array and keep only unique objects
    array.forEach((object: any) => {
      uniqueObjectMap[object.id] = object;
    });

    // Convert the object back to an array
    const uniqueObjects = Object.values(uniqueObjectMap);

    return uniqueObjects;
  }

  /**
   * Obtiene el nombre del síntoma asociado a una configuración de síntoma.
   * @param confSymptom La configuración de síntoma de la que se desea obtener el nombre.
   * @returns El nombre del síntoma.
   */
  getConfSymptomName(confSymptom: ConfSymptom): string {
    const symptomTranslateKey = 'symptom.enums.symptoms';
    return confSymptom?.symptomLabel
      ? this.trialpalService.dictionaryPipe.transform(confSymptom.symptomLabel)
      : this.trialpalService.translateService.instant(
          `${symptomTranslateKey}.${confSymptom?.symptom}`
        );
  }

  /** ==================== Revisa los sintomas locales o generales =================== */
  /**
   * Procesa los datos de los síntomas verificando instancias de síntomas no verificadas, actualizando los registros de síntomas y refrescando la vista de la tabla.
   * @param detailSymptomComponent - Componente para actualizar la vista detallada de los síntomas.
   */
  async reviewSymptomsDetailData(
    detailSymptomComponent: DetailSymptomComponent
  ): Promise<void> {
    const symptomOccurrences: SymptomOccurrence[] =
      detailSymptomComponent.symptomOcurrencesTable;
    const pendingSymptoms = this.getPendingSymptoms(symptomOccurrences);

    for (let symptom of pendingSymptoms) {
      const verifiedSymptomInstance = await this.createVerifiedSymptomInstance(
        symptom
      );
      const verifiedSymptomRecordLog =
        await this.createOrUpdateSymptomRecordLog(
          verifiedSymptomInstance,
          symptom.dayInstance.id,
          symptom.symptomRecordLogs
        );
      const updatedOccurrence = await this.updateOcurrency(
        verifiedSymptomInstance,
        symptom.dayInstance.id,
        verifiedSymptomRecordLog,
        symptom?.symptomOccurrency?.occurrency ?? false,
        ''
      );
      await this.updateTable(
        verifiedSymptomInstance,
        updatedOccurrence,
        symptom,
        detailSymptomComponent
      );
    }
  }

  /**
   * Obtiene los síntomas pendientes que aún no están verificados.
   * @param symptomOccurrences - Array de ocurrencias de síntomas.
   * @returns Array de síntomas no verificados.
   */
  getPendingSymptoms(symptomOccurrences: SymptomOccurrence[]): TableSymptom[] {
    return symptomOccurrences
      .flatMap((symptom: SymptomOccurrence) => symptom.tableSymptoms)
      .filter((symptom: TableSymptom) => !symptom.verifiedSymptomInstance?.id);
  }

  /**
   * Crea una instancia de síntoma verificada.
   * @param symptom - Síntoma a verificar.
   * @returns La instancia de síntoma verificada.
   */
  createVerifiedSymptomInstance(
    symptom: TableSymptom
  ): Promise<VerifiedSymptomInstance> {
    return this.createVerifiedSymptom(symptom.symptomInstance, {});
  }
  /** ==================== Revisa los otros síntomas=================== */

  async reviewOtherSymptomsData(): Promise<void> {
    const otherSymptomsData: OtherSymptomData[] = this.tableOtherSymptoms;
    const pendingOtherSymptoms = otherSymptomsData.filter(
      (symptom: OtherSymptomData) => !symptom.verifiedOtherSymptom?.id
    );

    for (let otherSymptom of pendingOtherSymptoms) {
      const verifiedOtherSymptom = await this.createVerifiedSymptom(
        otherSymptom.otherSymptom,
        {}
      );
      otherSymptom.verifiedOtherSymptom = verifiedOtherSymptom;
      otherSymptom.isEdition = true;
      otherSymptom.verifiedSymptomName =
        this.getOtherSymptomName(verifiedOtherSymptom);
      this.tableOtherSymptoms = this.tableOtherSymptoms.map(
        (_otherSymptom: OtherSymptomData) => {
          return _otherSymptom.otherSymptom.id === otherSymptom.otherSymptom.id
            ? otherSymptom
            : _otherSymptom;
        }
      );
    }
  }
}

interface ScaleRangeOptions {
  startValue: number;
  endValue: number;
  intensity: Intensity;
  label: string;
}

export interface TableSymptom {
  key: string;
  confSymptom: ConfSymptom;
  symptomType: string;
  dayInstance: DayInstance;
  dayName: string;
  confForm: ConfForm | undefined | null;
  symptomOccurrency: SymptomOccurrency;
  verifiedSymptomOccurrency: SymptomOccurrency;
  symptomInstance: SymptomInstance | undefined;
  verifiedSymptomInstance: VerifiedSymptomInstance | undefined | null;
  isEdition: boolean;
  comments: any;
  verifiedIntensityQuestions: string;
  intensityQuestions: string;
  intensityQuestionAnswers: any[] | undefined | null;
  rememberNoValuesTaken: boolean | undefined | null;
  symptomRecordLogs: any[];
}

export interface CommentAddInfo {
  key: string; //llave para acceder al comentario del sintoma
  indexKey: string; //Indice del comentario
  validator: boolean; //Valida si se debe mostrar o no el comentario
}

export interface Comment {
  comment: string;
  index: number;
}

export interface SymptomOccurrence {
  id: string;
  name: string;
  data: ConfSymptom;
  occurrences: Occurrency[];
  tableSymptoms: TableSymptom[];
  comments: Comment[];
}

export interface Occurrency {
  key: string;
  occurrency: boolean | undefined;
  verifiedOccurrency: boolean | undefined | null;
  confSymptom: ConfSymptom;
  dayInstance: DayInstance;
  isAssociated: boolean;
}

export interface SymptomInformation {
  symptomInstance: SymptomInstance | undefined;
  verifiedSymptomInstance: VerifiedSymptomInstance | undefined | null;
  symptomOccurrency: SymptomOccurrency;
  verifiedSymptomOccurrency: SymptomOccurrency;
  dayInstance: DayInstance;
  confSymptom: ConfSymptom;
  symptomRecordLogs: any[];
}

export interface ConfSymptomInformation {
  confSymptom: ConfSymptom;
  dayInstance: DayInstance;
  configuration: ConfForm;
}

export interface OtherSymptomData {
  confSymptom: ConfSymptom;
  dayInstance: DayInstance;
  confForm?: ConfForm;
  dayName: string;
  otherSymptom: SymptomInstance;
  verifiedOtherSymptom?: VerifiedSymptomInstance;
  isEdition: boolean;
  symptomName: string;
  verifiedSymptomName: string;
  comments: any;
}
