import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { ConciliationService } from '../../../conciliation.service';
import { CONCILIATION_QUERIES } from '../../../conciliation.queries';
import {
  ConfTemperature,
  ConfTemperatureByConfEdiaryCustomQuery,
  ConfTemperatureByConfEdiaryQuery,
  CreateVerifiedTemperatureRecordLogInput,
  DayInstance,
  InstanceState,
  TemperatureRecordLog,
  TemperatureRoute,
  TemperatureUnit,
  UpdateVerifiedTemperatureRecordLogInput,
  UpdateVerifiedTemperatureRecordLogMutation,
  VerifiedDayInstance,
  VerifiedTemperatureRecordLog,
} from '../../../conciliation.types';
import { ConfForm } from 'src/app/modules/ediary/ediary.types';
import { SUBJECT_QUERIES } from 'src/app/modules/subjects/subjects.queries';

const logger = new Logger('tp2-conciliationDetail-temperatureDetail');
@Injectable({
  providedIn: 'root',
})
export class TemperatureService {
  //=====DATOS SELECCIONADO DE TEMPERATURA===============
  colTemperatureDays: TemperatureData[] = [];
  currentTemperatureDay: any = {};
  temperature: any[] = [];
  temperatureRoute: any[] = [];
  temperatureTaken: any[] = [];
  temperatureTakenDate: any[] = [];
  temperatures: any[] = [];
  unit: any[] = [];
  observations: any[] = [];
  verifiedTemperatures: VerifiedTemperatureRecordLog[] = [];
  verifiedTempeturesBySubject: VerifiedTemperatureRecordLog[] = [];
  configTemperatureByEdiary?: ConfTemperatureByConfEdiaryQuery;
  pendingInstances: any[] = [];
  constructor(
    private authService: AuthService,
    private trialpalService: TrialpalService,
    private conciliationService: ConciliationService
  ) {}
  /**
   * @param temperature temperatura
   * @param unitTemperature unidad de escala C o F
   * @returns la temperatura con la abreviacion
   */
  getTemperatureByUnit(
    temperature: any,
    unitTemperature?: TemperatureUnit | null
  ) {
    return temperature
      ? temperature + this.getUnitTemperature(unitTemperature)
      : null;
  }

  /**
   * @param value enumeracion tipo de temperatura
   * @returns abreviacion de escala en C o F
   */
  getUnitTemperature(value?: TemperatureUnit | null) {
    return value === TemperatureUnit.CELSIUS ? ' °C' : ' °F';
  }

  /**
   *
   * @param enumValue enumeracion de ruta
   * @returns traduccion de la enumeracion
   */
  getValueEnumRoute(enumValue?: TemperatureRoute | null): string {
    return enumValue
      ? this.trialpalService.translateService.instant(
          'ediary.enums.temperatureRoute.' + enumValue
        )
      : null;
  }

  /**
   * @desc metodo para actualizar la instancia de la temperatura
   * @param data informacion que se va a actuallizar
   * @param id id de la instancia
   * @param expectedVersion version
   */
  async updateVerifiedTemperatureRecordLog(
    data: UpdateVerifiedTemperatureRecordLogInput,
    id: string,
    expectedVersion: any
  ): Promise<UpdateVerifiedTemperatureRecordLogMutation> {
    let updateInput: UpdateVerifiedTemperatureRecordLogInput = {
      id,
      state: InstanceState.UNSIGNED,
      _version: expectedVersion,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.authService.getUsername();
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.UpdateVerifiedTemperatureRecordLog,
      {
        input: updateInput,
      }
    );
    return response.data.updateVerifiedTemperatureRecordLog;
  }

  async listTemperatureRecordLogs(id: string): Promise<any[]> {
    let instances: any[] = [];
    let nextToken: string | undefined = undefined;
    do {
      const listQuery: any = await this.conciliationService.performGraphQLQuery(
        SUBJECT_QUERIES.TemperatureRecordLogBySubjectIdCustom,
        { subjectId: id, limit: 1000, nextToken }
      );
      instances = instances.concat(
        listQuery.data.temperatureRecordLogBySubjectId.items
      );
      nextToken = listQuery.data.temperatureRecordLogBySubjectId.nextToken;
    } while (nextToken);
    instances = instances
      .filter(Boolean)
      .filter((s: any) => !s._deleted && s.state !== InstanceState.DELETED);
    return instances;
  }

  async confTemperatureByConfEdiary(
    confEdiaryId: string
  ): Promise<ConfTemperatureByConfEdiaryCustomQuery> {
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.ConfTemperatureByConfEdiary,
      {
        confEdiaryId: confEdiaryId,
      }
    );
    return response.data.confTemperatureByConfEdiary;
  }

  //retorna el porcentaje de temperaturas conciliadas
  get getProgressTemperature() {
    const temperatures = this.colTemperatureDays;
    const verifiedTemperatures = temperatures?.filter(
      (temp: any) => temp?.verifiedTemperature?.id
    );
    return temperatures.length === 0
      ? 100
      : (verifiedTemperatures.length / temperatures.length) * 100;
  }

  /**
   * @param verified objeto conciliado de temperaura
   * @returns Objeto de la creacion de la intancia de verificacion de temperatura
   */
  async createVerifiedTemperatureRecordLog(
    verified: CreateVerifiedTemperatureRecordLogInput
  ): Promise<VerifiedTemperatureRecordLog> {
    verified.state = InstanceState.UNSIGNED;
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.CreateVerifiedTemperatureRecordLog,
      {
        input: verified,
      }
    );
    return response.data.createVerifiedTemperatureRecordLog;
  }

  /**
   * @desc filtro las temperaturtas por el sujeto
   * @param subjectId id del sujeto
   * @returns array de temperaturas del sujeto
   */
  async listVerifiedTemperatureRecordLogs(subjectId: string): Promise<any[]> {
    let instances: any[] = [];
    const response = await this.conciliationService.performGraphQLQuery(
      CONCILIATION_QUERIES.VerifiedTemperatureBySubjectIdCustom,
      {
        subjectId,
      }
    );
    let listQuery = response.data.verifiedTemperatureRecordLogBySubjectId;
    instances = listQuery.items;
    instances = instances.filter(Boolean).filter((s: any) => !s._deleted);
    instances.sort(
      (a: any, b: any) =>
        a?.verifiedDayInstance?.confDay?.order -
        b?.verifiedDayInstance?.confDay?.order
    );
    return instances;
  }

  /**
   *@desc cargar las configuraciones los fomrularios de los dias
   * @param id del confEdiary
   */
  async getConfTemperatureByEdiary(id: string) {
    await this.confTemperatureByConfEdiary(id)
      .then((data: ConfTemperatureByConfEdiaryQuery) => {
        if (data && data.items.length > 0) {
          this.configTemperatureByEdiary = data;
        }
        logger.debug(
          'getConfTemperatureByEdiary',
          this.configTemperatureByEdiary
        );
      })
      .catch((err) => {
        logger.error('errorGetConfTemperatureByEdiary', err);
      });
  }

  /**
   * @desc Buscar la confguracion de la temperatura segun el formulario correspondiente
   * @param formId del formulario
   */
  getConfTemperatureByForm(formId: string): ConfTemperature | undefined | null {
    return this.configTemperatureByEdiary?.items.find(
      (item: any) => item?.confFormId === formId
    );
  }

  /**
   *@desc Cargar la lista e instancias verificadas por el id del sujeto
   */
  async listTemperatureVerified(subjectId: string) {
    await this.listVerifiedTemperatureRecordLogs(subjectId)
      .then((temperatures: VerifiedTemperatureRecordLog[]) => {
        if (temperatures?.length) {
          this.verifiedTempeturesBySubject = temperatures;
          logger.debug(
            'listTemperatureVerified by subject',
            this.verifiedTempeturesBySubject
          );
        }
      })
      .catch((error: any) => {
        logger.error('ListVerifiedTemperature error', error);
      });
  }

  getTemperatureValues(
    temperatureRecord: TemperatureRecordLog,
    verifiedTemperature: any
  ) {
    const isEdition = !!verifiedTemperature?.id;

    const temperatureRoute = {
      id: temperatureRecord.id,
      value: temperatureRecord?.temperatureRoute,
      verified: verifiedTemperature?.route,
      verifiedId: verifiedTemperature?.id,
      comment: verifiedTemperature?.routeComment,
      commentIndex: null,
      isEdition,
      type: 'route',
    };

    const temperature = {
      id: temperatureRecord.id,
      value: temperatureRecord?.temperature,
      verified: verifiedTemperature?.temperature,
      verifiedId: verifiedTemperature?.id,
      comment: verifiedTemperature?.temperatureComment,
      commentIndex: null,
      isEdition,
      valueUnit: temperatureRecord?.temperatureUnit,
      verifiedUnit: verifiedTemperature?.unit,
      commentUnit: verifiedTemperature?.unitComment,
      commentUnitIndex: null,
      type: 'temperature',
    };

    const temperatureTaken = {
      id: temperatureRecord.id,
      value: temperatureRecord?.temperatureTaken ?? false,
      verified: verifiedTemperature?.taken ?? false,
      verifiedId: verifiedTemperature?.id,
      comment: verifiedTemperature?.takenComment,
      commentIndex: null,
      isEdition,
      type: 'boolean',
    };

    const temperatureTakenDate = {
      id: temperatureRecord.id,
      value: temperatureRecord?.temperatureTakenDate,
      verified: verifiedTemperature?.takenDate,
      verifiedId: verifiedTemperature?.id,
      comment: verifiedTemperature?.takenDateComment,
      commentIndex: null,
      isEdition,
      type: 'temperatureDate',
    };

    return {
      temperatureRoute,
      temperatureTaken,
      temperatureTakenDate,
      temperature,
    };
  }

  //Funcion que se encarga de añadir los comentarios a la tabla y al pie de la tabla
  addTemperatureComments() {
    let index = 1;
    this.observations = [];
    this.colTemperatureDays = this.colTemperatureDays.map(
      (temperatureDay: any) => {
        const {
          temperatureRoute,
          temperatureTaken,
          temperatureTakenDate,
          temperature,
        } = temperatureDay;

        if (temperatureRoute?.comment) {
          temperatureRoute.commentIndex = index;
          this.addObservation(temperatureRoute.comment, index);
          index++;
        }

        if (temperature?.comment) {
          temperature.commentIndex = index;
          this.addObservation(temperature.comment, index);
          index++;
        }

        if (temperature?.commentUnit) {
          temperature.commentUnitIndex = index;
          this.addObservation(temperature.commentUnit, index);
          index++;
        }

        if (temperatureTaken?.comment) {
          temperatureTaken.commentIndex = index;
          this.addObservation(temperatureTaken.comment, index);
          index++;
        }
        if (temperatureTakenDate?.comment) {
          temperatureTakenDate.commentIndex = index;
          this.addObservation(temperatureTakenDate.comment, index);
          index++;
        }
        return temperatureDay;
      }
    );
  }

  addObservation(comment: string, index: number) {
    this.observations.push({
      comment: comment,
      index: index,
    });
  }

  /** ==================== Revisa las temperaturas de forma automatica =================== */

  async reviewTemperatureRecordLogsData(): Promise<void> {
    const temperatureRecordLogsData: TemperatureData[] =
      this.colTemperatureDays;
    const pendingTemperatureRecordLogs = temperatureRecordLogsData.filter(
      (temperatureRecordLog: TemperatureData) =>
        !temperatureRecordLog.verifiedTemperature?.id
    );

    for (let temperatureRecordLog of pendingTemperatureRecordLogs) {
      const verifiedTemperatureRecordLog =
        await this.createAutomaticVerifiedTemperatureRecordLog(
          temperatureRecordLog
        );
      this.updateTemperatureRecordLog(
        temperatureRecordLog,
        verifiedTemperatureRecordLog
      );
    }
  }

  updateTemperatureRecordLog(
    temperatureRecordLog: TemperatureData,
    verifiedTemperatureRecordLog: VerifiedTemperatureRecordLog
  ) {
    const {
      temperatureRoute,
      temperatureTaken,
      temperatureTakenDate,
      temperature,
    } = this.getTemperatureValues(
      temperatureRecordLog.temperatureRecord,
      verifiedTemperatureRecordLog
    );
    temperatureRecordLog.verifiedTemperature = verifiedTemperatureRecordLog;
    temperatureRecordLog.temperatureRoute = temperatureRoute;
    temperatureRecordLog.temperatureTaken = temperatureTaken;
    temperatureRecordLog.temperatureTakenDate = temperatureTakenDate;
    temperatureRecordLog.temperature = temperature;
    temperatureRecordLog.isEdition = true;
    this.verifiedTempeturesBySubject.push(verifiedTemperatureRecordLog);
    this.updateColTemperatureDay(temperatureRecordLog);
    this.updateTemperatureInformation(
      temperatureRoute,
      temperatureRecordLog.temperatureRecord.id,
      0
    );
    this.updateTemperatureInformation(
      temperature,
      temperatureRecordLog.temperatureRecord.id,
      1
    );
    this.updateTemperatureInformation(
      temperatureTaken,
      temperatureRecordLog.temperatureRecord.id,
      2
    );
    this.updateTemperatureInformation(
      temperatureTakenDate,
      temperatureRecordLog.temperatureRecord.id,
      3
    );
  }

  async createAutomaticVerifiedTemperatureRecordLog(
    temperatureRecordLog: TemperatureData
  ): Promise<VerifiedTemperatureRecordLog> {
    const createVerifiedTemperatureRecord =
      this.getObjectCreateVerifiedTemperature(temperatureRecordLog);
    return this.createVerifiedTemperatureRecordLog(
      createVerifiedTemperatureRecord
    );
  }

  updateColTemperatureDay(temperatureRecordLog: TemperatureData) {
    this.colTemperatureDays = this.colTemperatureDays.map(
      (_temperatureRecordLog: TemperatureData) => {
        return _temperatureRecordLog.temperatureRecord.id ===
          temperatureRecordLog.temperatureRecord.id
          ? temperatureRecordLog
          : _temperatureRecordLog;
      }
    );
  }

  updateTemperatureInformation(data: any, id: string, index: number) {
    this.temperatures[index] = this.temperatures[index].map(
      (temperature: any) => (temperature.id === id ? data : temperature)
    );
  }

  /**
   *
   * @returns objecto con los datos de actualizacion de la instacia
   */
  getObjectCreateVerifiedTemperature(
    temperatureRecordLog: TemperatureData
  ): CreateVerifiedTemperatureRecordLogInput {
    return {
      verifiedDayInstanceId: temperatureRecordLog?.verifiedDayInstance?.id,
      subjectId: this.conciliationService.subjectId,
      temperatureRecordLogID: temperatureRecordLog?.isPendingInstance
        ? undefined
        : temperatureRecordLog?.temperatureRecord?.id,
      temperature: temperatureRecordLog.temperatureRecord?.temperature,
      unit: temperatureRecordLog.temperatureRecord?.temperatureUnit,
      route: temperatureRecordLog.temperatureRecord?.temperatureRoute,
      whichOtherRoute:
        temperatureRecordLog.temperatureRecord?.temperatureWhichOtherRoute,
      taken: temperatureRecordLog.temperatureRecord?.temperatureTaken,
      takenDate:
        temperatureRecordLog.temperatureRecord?.temperatureTakenDate ?? null,
      _lastUser: this.authService.getUsername(),
    };
  }
}

export interface TemperatureData {
  field: string;
  header: string;
  temperatureRecord: TemperatureRecordLog;
  verifiedTemperature?: VerifiedTemperatureRecordLog;
  configuration: ConfTemperature | undefined | null;
  isEdition: boolean;
  index: number;
  timeZoneOffset: number;
  dayInstance: DayInstance;
  confForm?: ConfForm;
  verifiedDayInstance?: VerifiedDayInstance;
  isPendingInstance: boolean;
  temperatureRoute: any;
  temperatureTaken: any;
  temperatureTakenDate: any;
  temperature: any;
}
