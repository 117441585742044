import { Component, Input, OnInit } from '@angular/core';
import { TrialpalService } from 'src/app/services/trialpal.service';

@Component({
  selector: 'app-device-data',
  templateUrl: './device-data.component.html',
  styleUrls: ['./device-data.component.scss'],
})
export class DeviceDataComponent implements OnInit {
  @Input() isReadOnly: boolean = false;
  @Input() readMode: boolean = false;
  @Input() confReport: any = {};

  booleanOptions: any[] = [];
  constructor(private readonly trialpalService: TrialpalService) {}

  ngOnInit() {
    this.setBooleanOptions();
  }

  setBooleanOptions() {
    this.booleanOptions = [
      {
        label: this.trialpalService.translateService.instant('general.no'),
        value: false,
      },
      {
        label: this.trialpalService.translateService.instant('general.yes'),
        value: true,
      },
    ];
  }
}
