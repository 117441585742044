import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  AmazonAIConvertPredictionsProvider,
  Predictions,
} from '@aws-amplify/predictions';
import {
  DeleteUserEndpointsCommand,
  PinpointClient,
} from '@aws-sdk/client-pinpoint';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import Amplify, { Analytics } from 'aws-amplify';
import axios from 'axios';
import version from '../package.json';
import { AppModule } from './app/app.module';
import awsmobile from './aws-exports';
import { environment } from './environments/environment';

async function getData() {
  let data: {
    latitude?: number | string;
    longitude?: number | string;
    TP2version?: string;
    width?: number;
    height?: number;
    country?: string;
    city?: string;
    region?: string;
  } = {};

  await new Promise<void>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (gps) => {
          data.latitude = gps.coords.latitude;
          data.longitude = gps.coords.longitude;
          resolve();
        },
        (error) => {
          console.log(`Error: ${error}`);
          data.latitude = 'error';
          data.longitude = 'error';
          resolve();
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      data.latitude = 'error';
      data.longitude = 'error';
      resolve();
    }
  });
  data.TP2version = 'V' + version.version;
  data.width = window.screen.width;
  data.height = window.screen.height;

  return data;
}

async function makeGeolocationRequest(
  latitude: number | string | undefined,
  longitude: number | string | undefined
) {
  try {
    let response;
    if (latitude == 'error' || longitude == 'error') {
      response = await axios.get(
        'https://api.bigdatacloud.net/data/reverse-geocode-client'
      );
    } else {
      response = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

function analyticsSetup(data: any) {
  //configuracion de aws amplify
  Amplify.configure(awsmobile);
  const { latitude, longitude, TP2version, width, height } = data;

  const analyticsConfig = {
    AWSPinpoint: {
      region: awsmobile.aws_mobile_analytics_app_region,
      mandatorySignIn: false,
    },
  };
  endpointUpdatingFlowMain();

  Analytics.configure(analyticsConfig);

  Analytics.autoTrack('event', {
    enable: true,
  });

  //autoTack de session
  Analytics.autoTrack('session', {
    enable: true,
    provider: 'AWSPinpoint',
    attributes: {
      latitude: latitude,
      longitude: longitude,
      country: data.country,
      city: data.city,
      region: data.region,
      TP2version: TP2version,
      width: width,
      height: height,
    },
  });
}

//Setup de la aplicacion
function initialSetup() {
  Predictions.addPluggable(new AmazonAIConvertPredictionsProvider());
  if (environment.production) {
    enableProdMode();
    Amplify.Logger.LOG_LEVEL = 'ERROR';
  } else {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
  }

  //bootstrap de la aplicacion
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

function main() {
  getData()
    .then(async (data) => {
      const reverseGeolocation = await makeGeolocationRequest(
        data.latitude,
        data.longitude
      );
      data.country = reverseGeolocation.countryName;
      data.city = reverseGeolocation.city;
      data.region = reverseGeolocation.principalSubdivision;
      data.latitude = reverseGeolocation.latitude;
      data.longitude = reverseGeolocation.longitude;
      analyticsSetup(data);
      initialSetup();
    })
    .catch((err) => {
      console.log(err);
    });
}
async function endpointUpdatingFlowMain() {
  //1. Se eliminan los endpoints del usuario autenticado
  const userId = 'not-logged-in';
  await deleteEndpoints(userId);

  //2. Se actualiza el endpoint del usuario autenticado
  Analytics.updateEndpoint({
    userId: 'not-logged-in',
  });
}

async function deleteEndpoints(userId?: any): Promise<void> {
  const cognitoCredentials = fromCognitoIdentityPool({
    identityPoolId: awsmobile.aws_cognito_identity_pool_id,
    clientConfig: { region: awsmobile.aws_project_region },
  });
  console.debug('Cognito Credentials', cognitoCredentials);
  const pinpointClient = new PinpointClient({
    region: awsmobile.aws_mobile_analytics_app_region,
    credentials: cognitoCredentials,
  });
  const params = {
    ApplicationId: awsmobile.aws_mobile_analytics_app_id,
    UserId: userId,
  };
  const command = new DeleteUserEndpointsCommand(params);
  const response = await pinpointClient.send(command);
  console.debug('deleteEndpoints response', response);
}
main();
