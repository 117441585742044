<p-dialog
  [header]="header"
  [(visible)]="display"
  (onShow)="showDialog()"
  (onHide)="hideDialog()"
  [draggable]="false"
  class="custom-dialog"
  [style]="{ position: position }"
>
  <div class="p-mb-3">
    <p class="p-py-0 p-my-0 description">
      {{ description }}
    </p>
  </div>

  <div class="buttons">
    <p-button
      id="btn_cancel"
      class="cancelWindow"
      [label]="'general.cancel' | translate"
      (click)="display = false"
      styleClass="p-button-outlined"
    ></p-button>
    <p-button
      id="btn_delete"
      [label]="btnDeleteText"
      class="deleteWindow"
      (click)="confirm.emit(); hideDialog()"
    ></p-button>
  </div>
</p-dialog>
