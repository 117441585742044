<h1>
  {{
    (multiEntity ? 'audit.changeHistory' : 'audit.auditTitle')
      | translate
        : { entityName: entityName | lowercase, description: description }
  }}
</h1>

<p-table
  #auditTable
  [value]="data"
  [scrollable]="true"
  [rows]="100"
  scrollHeight="60vh"
  dataKey="id"
  *ngIf="data.length > 0"
  styleClass="p-datatable-gridlines"
  [globalFilterFields]="[
    'date',
    'lastUser',
    'variable',
    'newValue',
    'oldValue',
    'time',
    'reason',
    'entityType',
    'entityId',
    'entityName'
  ]"
  [rows]="multiEntity ? 10 : data.length"
  [paginator]="multiEntity ? true : false"
  [showCurrentPageReport]="multiEntity ? true : false"
  [rowsPerPageOptions]="multiEntity ? [10, 25, 50] : []"
  currentPageReportTemplate="
  {{
    multiEntity?
    ('general.paginatorValues'
      | translate
        : {
            first: '{{first}}'
          }
          :
          {
            last:  '{{ last }} '
          }
          :
          {
            totalRecords: '{{ totalRecords }}'
          }):
          ''
  }}"
>
  <ng-template *ngIf="multiEntity" pTemplate="caption">
    <div class="p-d-flex p-jc-between p-ai-center">
      <button
        pButton
        [label]="'primeng.clear' | translate"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(auditTable, searchInput)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <span class="pi pi-search search-icon"></span>
        <input
          pInputText
          type="text"
          #searchInput
          (input)="auditTable.filterGlobal(searchInput.value, 'contains')"
          [placeholder]="
            'informedConsent.followUp.table.searchLabel' | translate
          "
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="date" pSortableColumn="date">
        <p-columnFilter
          type="text"
          field="date"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.date' | translate }}
        <p-sortIcon field="date"></p-sortIcon>
      </th>
      <th *ngIf="multiEntity" id="entityType" pSortableColumn="entityType">
        <p-columnFilter
          type="text"
          field="entityType"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.entityType' | translate }}
        <p-sortIcon field="entityType"></p-sortIcon>
      </th>
      <th *ngIf="multiEntity" id="entityId" pSortableColumn="entityId">
        <p-columnFilter
          type="text"
          field="entityId"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.entityId' | translate }}
        <p-sortIcon field="entityId"></p-sortIcon>
      </th>
      <th *ngIf="multiEntity" id="entityName" pSortableColumn="entityName">
        <p-columnFilter
          type="text"
          field="entityName"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.entityName' | translate }}
        <p-sortIcon field="entityName"></p-sortIcon>
      </th>
      <th id="lastUser" pSortableColumn="lastUser">
        <p-columnFilter
          type="text"
          field="lastUser"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.user' | translate }}
        <p-sortIcon field="lastUser"></p-sortIcon>
      </th>
      <th id="variable" pSortableColumn="variable">
        <p-columnFilter
          type="text"
          field="variable"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.variable' | translate }}
        <p-sortIcon field="variable"></p-sortIcon>
      </th>
      <th id="oldValue" pSortableColumn="oldValue">
        <p-columnFilter
          type="text"
          field="oldValue"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.oldValue' | translate }}
        <p-sortIcon field="oldValue"></p-sortIcon>
      </th>
      <th id="newValue" pSortableColumn="newValue">
        <p-columnFilter
          type="text"
          field="newValue"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.newValue' | translate }}
        <p-sortIcon field="newValue"></p-sortIcon>
      </th>
      <th id="reason" pSortableColumn="reason">
        <p-columnFilter
          type="text"
          field="reason"
          display="menu"
        ></p-columnFilter>
        {{ 'audit.reason' | translate }}
        <p-sortIcon field="reason"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-record>
    <tr>
      <td>{{ record.date }}</td>
      <td *ngIf="multiEntity">{{ record.entityType }}</td>
      <td *ngIf="multiEntity">{{ record.entityId }}</td>
      <td *ngIf="multiEntity">{{ record.entityName }}</td>
      <td>{{ record.lastUser }}</td>
      <td>{{ record.variable }}</td>
      <td class="tdValue">
        <span [innerHTML]="record.oldValue"></span>
      </td>
      <td class="tdValue">
        <span [innerHTML]="record.newValue"></span>
      </td>
      <td>{{ record.reason }}</td>
    </tr>
  </ng-template>
</p-table>

<h3 *ngIf="data.length === 0" style="width: 100%; text-align: center">
  {{ 'general.emptyFilterMessage' | translate }}
</h3>
