<div class="container-tp2 containerAll" id="container">
  <div>
    <div class="p-col-12" style="display: flex; align-items: center">
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        id="btn_forward"
        class="
          p-button-rounded
          p-button-secondary
          p-button-outlined
          p-button
          p-button-icon-only
        "
        (click)="forward()"
      ></button>
      <div class="p-col-10">
        <h1>{{ 'user.h1ListUser' | translate }}</h1>
      </div>
    </div>
    <div *ngIf="!users || users.length === 0" class="p-col-12 noData">
      <h2>{{ 'general.noData' | translate }}</h2>
    </div>
    <section *ngIf="users && users.length > 0" class="p-md-12">
      <app-tptable [dataTable]="dataTable"> </app-tptable>
    </section>
  </div>
</div>
