import { NgModule } from '@angular/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { EdiaryModule } from '../ediary/ediary.module';
import { AddEditReportComponent } from './add-edit-report/add-edit-report.component';
import { DeviceDataComponent } from './add-edit-report/components/device-data/device-data.component';
import { MultimediaComponent } from './add-edit-report/components/multimedia/multimedia.component';
import { RemindersComponent } from './add-edit-report/components/reminders/reminders.component';
import { FormlyDesignerModule } from './formly-designer/formly-designer.module';
import { ListReportComponent } from './list-report/list-report.component';
import { ReportRoutingModule } from './report-routing.module';
import { ReportStatusComponent } from './report-status/report-status.component';

@NgModule({
  declarations: [
    ListReportComponent,
    AddEditReportComponent,
    ReportStatusComponent,
    DeviceDataComponent,
    MultimediaComponent,
    RemindersComponent,
  ],
  imports: [
    PrimengModule,
    SharedComponentsModule,
    ReportRoutingModule,
    EdiaryModule,
    FormlyDesignerModule,
  ],
})
export class ReportModule {}
