import { Injectable } from '@angular/core';
import { Logger } from '@aws-amplify/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Observable } from 'zen-observable-ts';
import { UserService } from '../user/user.service';
import { API, graphqlOperation } from 'aws-amplify';
import {
  AuthorizedSitesBySiteIdCustomQuery,
  CHAT_QUERIES,
  CreateChatMessageInput,
  CreateChatMessageMutation,
  CreateConfChatUserInput,
  ModelSortDirection,
  SubjectsBySiteIdQuery,
  SubscriptionResponse,
  UpdateConfChatUserInput,
} from './chat.queries';

const logger = new Logger('tp2-logger-chatService');
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  chatSubscriptions: any[] = [];
  constructor(
    private auth: AuthService,
    private userService: UserService,
    private trialpalService: TrialpalService,
    private messages: MessageService,
    private translateService: TranslateService
  ) {}

  /**
   * Sirve para inicializar las subscripciones al chat.
   */
  initSiteChatSubscriptions() {
    logger.debug('getting id for', this.auth.getUsername());
    if (!this.auth.isReader()) {
      this.userService
        .userByLogin(this.auth.getUsername())
        .then(async (response) => {
          logger.debug('userByLogin response', response);
          if (response?.items?.[0]) {
            const idUser = response.items[0].id;
            await this.getSuscriptionsByUserId(idUser);
          }
        })
        .catch((error) => {
          logger.error('userByLogin error', error);
          this.trialpalService.showServiceError(
            'user.actions.userByLogin',
            error
          );
        });
    }
  }

  private async getSuscriptionsByUserId(userId: string) {
    await this.getConfChatUserByUserId(userId)
      .then((response: any[]) => {
        logger.debug('getConfCharUserByUserId response', response);
        if (response) {
          const sites: string[] = [];
          for (const item of response) {
            if (item) {
              sites.push(item.siteId);
            }
          }
          this.susbcribeUserSitesChats(sites);
        }
      })
      .catch((err) => {
        logger.error('getConfCharUserByUserId error', err);
        this.trialpalService.showServiceError(
          'chat.actions.getConfCharUserByUserId',
          err
        );
      });
  }
  /**
   * Sirve para realizar las subscripciones y guardarlas en el componente
   * @param userSites los sitios que el usuario esta configurado para chatear.
   */
  private susbcribeUserSitesChats(userSites: string[]) {
    for (const siteId of userSites) {
      this.chatSubscriptions.push(
        this.onCreateChatMessageBySiteIdListener(siteId).subscribe({
          next: (data: any) => {
            logger.debug('data recieved', data);
            const chat = data.value.data.onCreateChatMessageBySiteId;
            if (chat.sender === 'SUBJECT') {
              this.messages.add({
                key: 'chatToast',
                severity: 'info',
                detail: chat.message,
                life: 900000,
                data: chat.site.projectId + '/' + chat.userId,
                summary:
                  chat.senderAlias +
                  ' ' +
                  this.translateService.instant('general.says') +
                  ':  ',
              });
            }
          },
        })
      );
    }
  }

  unsusbcribeChats(): void {
    for (const s of this.chatSubscriptions) {
      s.unsubscribe();
    }
  }
  async getSiteUsers(
    siteId: string
  ): Promise<AuthorizedSitesBySiteIdCustomQuery> {
    const response = await this.performGraphQLQuery(
      CHAT_QUERIES.AuthorizedSitesBySiteIdCustom,
      {
        siteId,
      }
    );
    return response.data.subjectsBySiteId;
  }
  async getSiteSubjects(siteId: string): Promise<SubjectsBySiteIdQuery> {
    const response = await this.performGraphQLQuery(
      CHAT_QUERIES.SubjectsBySiteId,
      {
        siteId,
      }
    );
    return response.data.SubjectsBySiteId;
  }
  async getSiteChatMessages(siteId: string): Promise<any[]> {
    let chatMessages: any[] = [];
    let response = await this.performGraphQLQuery(
      CHAT_QUERIES.ChatMessagesBySiteId,
      {
        siteId,
        createdAt: undefined,
        sortDirection: ModelSortDirection.ASC,
        filter: undefined,
        limit: 1000,
      }
    );
    let listQuery = response.data.ChatMessagesBySiteId;
    chatMessages = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.performGraphQLQuery(
        CHAT_QUERIES.ChatMessagesBySiteId,
        {
          siteId,
          createdAt: undefined,
          sortDirection: ModelSortDirection.ASC,
          filter: undefined,
          limit: 1000,
          nextToken,
        }
      );
      listQuery = response.data.ChatMessagesBySiteId;
      chatMessages = chatMessages.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    chatMessages = chatMessages.filter(Boolean).filter((s: any) => !s._deleted);
    return chatMessages;
  }

  async createChatMessage(
    input: CreateChatMessageInput
  ): Promise<CreateChatMessageMutation> {
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      CHAT_QUERIES.CreateChatMessage,
      { input }
    );
    return response.data.createChatMessage;
  }

  onCreateChatMessageBySiteIdListener(
    siteId: string
  ): Observable<SubscriptionResponse<any>> {
    return this.performGraphQLQuery(CHAT_QUERIES.OnCreateChatMessageBySiteId, {
      siteId,
    }) as Observable<SubscriptionResponse<any>>;
  }

  async createChatConfUser(input: CreateConfChatUserInput) {
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      CHAT_QUERIES.CreateConfChatUser,
      { input }
    );
    return response.data.createConfChatUser;
  }
  async updateChatConfUser(input: UpdateConfChatUserInput) {
    input._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      CHAT_QUERIES.UpdateConfChatUser,
      { input }
    );
    return response.data.updateConfChatUser;
  }

  async getConfChatUserByProjectId(projectId: string): Promise<any[]> {
    let chatMessages: any[] = [];
    let response = await this.performGraphQLQuery(
      CHAT_QUERIES.ConfCharUserByProjectId,
      {
        projectId,
        sortDirection: undefined,
        filter: undefined,
        limit: 1000,
      }
    );
    let listQuery = response.data.ConfCharUserByProjectId;
    chatMessages = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.performGraphQLQuery(
        CHAT_QUERIES.ConfCharUserByProjectId,
        {
          projectId,
          sortDirection: undefined,
          filter: undefined,
          limit: 1000,
          nextToken,
        }
      );
      listQuery = response.data.ConfCharUserByProjectId;
      chatMessages = chatMessages.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    chatMessages = chatMessages.filter(Boolean).filter((s: any) => !s._deleted);
    return chatMessages;
  }
  async getConfChatUserByUserId(userId: string): Promise<any[]> {
    let chatMessages: any[] = [];
    let response = await this.performGraphQLQuery(
      CHAT_QUERIES.ConfCharUserByUserId,
      {
        userId,
        sortDirection: undefined,
        filter: undefined,
        limit: 1000,
      }
    );
    let listQuery = response.data.ConfCharUserByUserId;
    chatMessages = listQuery.items;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.performGraphQLQuery(
        CHAT_QUERIES.ConfCharUserByUserId,
        {
          userId,
          sortDirection: undefined,
          filter: undefined,
          limit: 1000,
          nextToken,
        }
      );
      listQuery = response.data.ConfCharUserByUserId;
      chatMessages = chatMessages.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    chatMessages = chatMessages.filter(Boolean).filter((s: any) => !s._deleted);
    return chatMessages;
  }

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
