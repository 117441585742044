<div class="container-tp2">
  <app-back-button-header
    [pageTitle]="'informedConsent.informedConsentDetail' | translate"
    [pageDescription]="
      'informedConsent.informedConsentDetailDescription' | translate
    "
    [pageUrl]="'/informed-consent/' + projectId"
    [showButton]="confICSections.length > 0"
    [textButton]="
      'informedConsent.informedSectionListNotFoundTextButton' | translate
    "
    (clickEmitter)="onCreate()"
    class="p-mb-3 p-d-block"
  ></app-back-button-header>

  <main>
    <div class="tp2Card sectionDetail">
      <div>
        <p class="sectionTitle">
          {{ 'informedConsent.labelTitle' | translate }}:
        </p>
        <p class="sectionInfo">{{ confInformedConsent.name | dictionary }}</p>
      </div>
      <div>
        <p class="sectionTitle">
          {{ 'informedConsent.labelType' | translate }}:
        </p>
        <p class="sectionInfo">
          {{
            'informedConsent.informedConsentTypes.' + confInformedConsent.type
              | translate
          }}
        </p>
      </div>
      <div>
        <p class="sectionTitle">{{ 'informedConsent.version' | translate }}:</p>
        <p class="sectionInfo">
          {{ confInformedConsent.icVersion }}
        </p>
      </div>
      <div>
        <p class="sectionTitle">
          {{ 'informedConsent.labelSite' | translate }}:
        </p>
        <p class="sectionInfo">{{ sites }}</p>
      </div>
    </div>
    <div class="tp2Card sectionList">
      <div class="sectionHeader">
        <form class="formQuices">
          <span class="questionsText"
            >{{ 'informedConsent.quicesText' | translate }}
          </span>
          <div style="overflow: hidden">
            <label for="drop_quices">{{
              'informedConsent.labelQuices' | translate
            }}</label>
            <p-dropdown
              id="drop_quices"
              name="drop_quices"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="quizLayout"
              (onChange)="saveQuizLayout()"
              appendTo="body"
              [options]="quices"
              placeholder="{{ 'informedConsent.dropQuices' | translate }}"
              emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
              emptyFilterMessage="{{
                'general.emptyFilterMessage' | translate
              }}"
            >
            </p-dropdown>
          </div>
        </form>
        <div class="border"></div>
      </div>
      <app-informed-consent-section-list
        [confICSections]="confICSections"
        (editEmitter)="onEdit($event)"
        (detailEmitter)="onDetail($event)"
      ></app-informed-consent-section-list>
      <app-not-found-items
        *ngIf="confICSections.length === 0"
        [description]="
          'informedConsent.informedSectionListNotFoundTitle' | translate
        "
        [buttonDescription]="
          'informedConsent.informedSectionListNotFoundDescription' | translate
        "
        [buttonText]="
          'informedConsent.informedSectionListNotFoundTextButton' | translate
        "
        (clickEmitter)="onCreate()"
      ></app-not-found-items>
    </div>
  </main>
</div>
