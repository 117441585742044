import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { MedicationConfigurationMode } from 'src/app/modules/conciliation/conciliation.types';
import { EdiaryService } from 'src/app/modules/ediary/ediary.service';
import { TrialpalService } from '../../../../../../../services/trialpal.service';
import { ConciliationService } from '../../../../../conciliation.service';
import { ConciliationComponents } from '../../../../../conciliationData';
import { PaginationService } from '../../../../../pagination.service';
import { MedicalAttentionService } from '../../../medical-attention/medical-attention.service';
import { MedicationService } from '../../../medication/medication.service';
import { SymptomService } from '../../../symptom/symptom.service';
import { DayInstanceService } from '../day-instance.service';
const logger = new Logger('tp2-dayinstance-table-conciliation');
@Component({
  selector: 'app-table-day-instance',
  templateUrl: './table-day-instance.component.html',
  styleUrls: ['./table-day-instance.component.scss'],
})
export class TableDayInstanceComponent implements OnChanges {
  @Input() instance: string = '';
  @Output() emitData: EventEmitter<any> = new EventEmitter();
  @Output() emitConfigurations: EventEmitter<any> = new EventEmitter();
  currentSection: ConciliationComponents | undefined = undefined;
  colDays: any[] = [];
  tableDayIntances: any[] = [];
  medicalAttentionConfigurations: any[] = [];
  constructor(
    public dayInstanceService: DayInstanceService,
    public conciliationService: ConciliationService,
    public paginationService: PaginationService,
    private trialpalService: TrialpalService,
    private ediaryService: EdiaryService,
    private medicalAttentionService: MedicalAttentionService,
    private symptomService: SymptomService,
    private medicationService: MedicationService
  ) {}

  async ngOnChanges() {
    if (this.instance === ConciliationComponents.OTHER_SYMPTOM) {
      await this.buildOtherSymptomsDays();
      this.currentSection = ConciliationComponents.OTHER_SYMPTOM;
    }

    if (this.instance === ConciliationComponents.MEDICATION) {
      await this.buildMedicationDays();
      this.currentSection = ConciliationComponents.MEDICATION;
    }
    if (this.instance === ConciliationComponents.MEDICAl_ATTETION) {
      await this.buildMedicalAttentions();
      this.currentSection = ConciliationComponents.MEDICAl_ATTETION;
    }

    if (this.colDays.length > 1) {
      this.paginationService.dayInstanceSelected(
        this.colDays[1],
        this.instance as ConciliationComponents
      );
    }
  }
  async buildOtherSymptomsDays() {
    if (this.symptomService.addConfOtherSymptoms.length === 0) {
      await this.symptomService.getConfOtherSymptoms();
    }
    const dayInstances = this.filterDaysByEntityRequired(
      'areOtherSymptomsRequired'
    );
    //Datos para la precondición del dia

    //Datos para la precondición del dia
    const hasOtherSymptoms: any[] = [];
    this.colDays = [];

    this.colDays.push({
      field: '',
      header: '',
      dayInstance: '',
      configuration: '',
    });

    //Obtiene los valores de la precondición
    hasOtherSymptoms.push({
      index: 0,
      value: this.trialpalService.translateService.instant(
        'general.configurations.enums.precondition'
      ),
    });
    let index = 0;
    //Obtiene los medicamentos asociados a los dias
    for (const dayInstanceConf of dayInstances) {
      const confFormId = dayInstanceConf.dayInstance?.confDay?.confFormId || '';
      let configuration = this.symptomService.addConfOtherSymptoms.find(
        (configuration: any) => configuration.confFormId === confFormId
      )?.confOtherSymptom;
      //Columna de los días
      if (configuration?.showPrecondition) {
        const verifiedDayInstance =
          await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
            dayInstanceConf?.dayInstance.id
          );

        if (verifiedDayInstance?.hasOtherSymptomsComment) index++;
        //Obtiene los valores de la precondición
        const hasOtherSymptom = {
          index: 1,
          value: dayInstanceConf.dayInstance.hasOtherSymptoms ?? false,
          verified: verifiedDayInstance?.hasOtherSymptoms ?? false,
          verifiedId: verifiedDayInstance?.id,
          comment: verifiedDayInstance?.hasOtherSymptomsComment,
          commentIndex: verifiedDayInstance?.hasOtherSymptomsComment
            ? index
            : null,
        };
        hasOtherSymptoms.push(hasOtherSymptom);
        this.colDays.push({
          field: dayInstanceConf.dayInstance.confDay?.dayName,
          header: dayInstanceConf.dayInstance.confDay?.dayName,
          dayInstance: dayInstanceConf.dayInstance,
          confForm: dayInstanceConf.dayInstance.confDay?.confForm,
          verifiedDayInstance,
          isEdition: !!verifiedDayInstance?.id,
          hasOtherSymptomsRow: hasOtherSymptom,
          hasInstance: 'hasOtherSymptoms',
          hasInstanceComment: 'hasOtherSymptomsComment',
          hasInstanceCompleted: 'hasOtherSymptomsCompleted',
          isCompleted: verifiedDayInstance?.hasOtherSymptomsCompleted || false,
          configuration,
          colDays: this.colDays,
        });
      }
    }
    this.tableDayIntances.push(hasOtherSymptoms);
    this.emitData.emit(this.colDays);
  }

  async buildMedicationDays() {
    const dayInstances = this.filterDaysByEntityRequired(
      'isMedicationRequired'
    );
    //Datos para la precondición del dia
    const hasMedications: any[] = [];
    const medicationsGivenTo: any[] = [];
    this.colDays = [];
    this.tableDayIntances = [];

    if (this.medicationService.confMedications.length === 0) {
      await this.medicationService.getConfMedicationByConfEdiaryId();
    }

    this.colDays.push({
      field: '',
      header: '',
      dayInstance: '',
      configuration: '',
    });

    //Obtiene los valores de la precondición
    hasMedications.push({
      index: 0,
      value: this.trialpalService.translateService.instant(
        'general.configurations.enums.precondition'
      ),
    });
    medicationsGivenTo.push({
      index: 0,
      value: this.trialpalService.translateService.instant(
        'medicationinstance.givenTo'
      ),
    });

    let index = 0;
    //Obtiene los medicamentos asociados a los dias
    for (const dayInstanceConf of dayInstances) {
      let configuration: any = this.medicationService.confMedications.find(
        (confMedication: any) => {
          return (
            confMedication.confFormId === dayInstanceConf?.configuration?.id
          );
        }
      );
      //Columna de los días
      index = await this.buildDaysColumnMedications(
        configuration,
        index,
        dayInstanceConf,
        hasMedications,
        medicationsGivenTo
      );
    }
    //Almacena los datos de la precondición
    this.tableDayIntances[0] = hasMedications;
    this.tableDayIntances[1] = medicationsGivenTo;
    this.emitData.emit(this.colDays);
  }

  async buildDaysColumnMedications(
    configuration: any,
    index: number,
    dayInstanceConf: any,
    hasMedications: any[],
    medicationsGivenTo: any[]
  ) {
    if (
      configuration?.showPrecondition ||
      this.isGivenToEnabled(configuration)
    ) {
      const verifiedDayInstance =
        await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
          dayInstanceConf?.dayInstance.id
        );

      if (verifiedDayInstance?.hasMedicationComment) index++;

      const hasMedication = {
        index: 1,
        value: dayInstanceConf.dayInstance.hasMedication ?? false,
        verified: verifiedDayInstance?.hasMedication ?? false,
        verifiedId: verifiedDayInstance?.id,
        comment: verifiedDayInstance?.hasMedicationComment,
        commentIndex: verifiedDayInstance?.hasMedicationComment ? index : null,
      };

      if (verifiedDayInstance?.medicationGivenToComment) index++;

      const medicationGivenTo = {
        index: 2,
        value: dayInstanceConf.dayInstance.medicationGivenTo,
        verified: verifiedDayInstance?.medicationGivenTo,
        verifiedId: verifiedDayInstance?.id,
        comment: verifiedDayInstance?.medicationGivenToComment,
        commentIndex: verifiedDayInstance?.medicationGivenToComment
          ? index
          : null,
      };

      //Obtiene los valores de la precondición
      hasMedications.push(hasMedication);
      medicationsGivenTo.push(medicationGivenTo);
      this.colDays.push({
        field: dayInstanceConf.dayInstance.confDay?.dayName,
        header: dayInstanceConf.dayInstance.confDay?.dayName,
        dayInstance: dayInstanceConf.dayInstance,
        confForm: dayInstanceConf.dayInstance.confDay?.confForm,
        verifiedDayInstance,
        isEdition: !!verifiedDayInstance?.id,
        configuration,
        hasMedicationRow: hasMedication,
        medicationGivenToRow: medicationGivenTo,
        hasInstance: 'hasMedication',
        hasInstanceComment: 'hasMedicationComment',
        hasInstanceCompleted: 'hasMedicationCompleted',
        isCompleted: verifiedDayInstance?.hasMedicationCompleted || false,
        colDays: this.colDays,
      });
    }
    return index;
  }

  async buildMedicalAttentions() {
    const dayInstances = this.filterDaysByEntityRequired(
      'isMedicalAttentionRequired'
    );
    logger.debug('buildMedicalAttentions', dayInstances);
    const hasMedicalAttentions: any[] = [];
    this.colDays = [];
    this.tableDayIntances = [];
    this.medicalAttentionConfigurations = [];

    const items =
      this.medicalAttentionService.configMedicalAttentionsByEdiary?.items ?? [];

    if (items.length === 0) {
      await this.medicalAttentionService.getConfMedicalAttentionsByEdiary(
        this.conciliationService.confEdiaryId
      );
    }

    this.colDays.push({
      field: '',
      header: '',
      dayInstance: '',
      configuration: '',
    });
    hasMedicalAttentions.push({
      index: 0,
      value: this.trialpalService.translateService.instant(
        'general.configurations.enums.precondition'
      ),
    });
    let index = 0;
    for await (const dayInstanceConf of dayInstances) {
      logger.debug('dayInstanceConf', dayInstanceConf?.configuration?.id);
      //Cargar la configuracion
      const configuration =
        this.medicalAttentionService.getConfMedicalAttentionByForm(
          dayInstanceConf?.configuration?.id || ''
        );
      this.medicalAttentionConfigurations.push(configuration);
      //Columna de los días

      if (configuration?.showPrecondition) {
        const verifiedDayInstance =
          await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
            dayInstanceConf?.dayInstance.id
          );
        logger.debug('configuration', configuration, verifiedDayInstance);
        if (verifiedDayInstance?.hasMedicalAttentionComment) index++;

        const hasMedicalAttention = {
          index: 1,
          value: dayInstanceConf.dayInstance.hasMedicalAttention ?? false,
          verified: verifiedDayInstance?.hasMedicalAttention ?? false,
          verifiedId: verifiedDayInstance?.id,
          comment: verifiedDayInstance?.hasMedicalAttentionComment,
          commentIndex: verifiedDayInstance?.hasMedicalAttentionComment
            ? index
            : null,
        };

        //Obtiene los valores de la precondición
        hasMedicalAttentions.push(hasMedicalAttention);
        this.colDays.push({
          field: dayInstanceConf.dayInstance.confDay?.dayName,
          header: dayInstanceConf.dayInstance.confDay?.dayName,
          dayInstance: dayInstanceConf.dayInstance,
          confForm: dayInstanceConf.dayInstance.confDay?.confForm,
          verifiedDayInstance,
          isEdition: !!verifiedDayInstance?.id,
          configuration,
          hasMedicalAttentionRow: hasMedicalAttention,
          hasInstance: 'hasMedicalAttention',
          hasInstanceComment: 'hasMedicalAttentionComment',
          hasInstanceCompleted: 'hasMedicalAttentionCompleted',
          isCompleted:
            verifiedDayInstance?.hasMedicalAttentionCompleted || false,
          colDays: this.colDays,
        });
      }
      logger.debug('colDays', this.colDays);
    }

    this.tableDayIntances[0] = hasMedicalAttentions;
    this.emitData.emit(this.colDays);
  }
  filterDaysByEntityRequired(type: string) {
    const dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    dayInstances?.sort(
      (a, b) => a?.dayInstance?.confDay?.order - b?.dayInstance?.confDay?.order
    );
    //Obtiene los dayInstances con los medicamentos activos
    return dayInstances.filter((dayConf: any) => {
      let isEntityRequired = dayConf?.configuration[type];
      return (
        isEntityRequired &&
        this.conciliationService.isBeforeToCurrentDate(
          dayConf.dayInstance.finishDate
        )
      );
    });
  }
  //Obtiene la configuración del medicamento
  async getConfMedicationByFormId(formId: string) {
    const { items } = await this.ediaryService.getConfMedicationByFormId(
      formId
    );
    return items[0];
  }

  //Obtiene la configuración del medicamento
  async getConfOtherSymtompByFormId(formId: string) {
    const { items } = await this.ediaryService.getConfOtherSymtompByByFormId(
      formId
    );
    return items[0];
  }

  /**
   * Consultar la configuracion del formulario
   * @param formId id de la configuración del formulario
   * @returns
   */
  async getConfMedicalAttentionByFormId(formId: string) {
    const { items } = await this.ediaryService.getConfMedicalAttentionByFormId(
      formId
    );
    return items[0];
  }

  isGivenToEnabled(configuration: any) {
    return (
      configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_OPEN_REASON ||
      configuration?.mode ===
        MedicationConfigurationMode.GENERAL_GIVEN_TO_SYMPTOM_BINDING
    );
  }

  dayInstanceSelected(day: any) {
    if (this.instance === ConciliationComponents.OTHER_SYMPTOM) {
      this.symptomService.currentOtherSymptom = {};
    }

    if (this.instance === ConciliationComponents.MEDICATION) {
      this.medicationService.currentMedication = {};
    }
    if (this.instance === ConciliationComponents.MEDICAl_ATTETION) {
      this.medicalAttentionService.medicalAttention = undefined;
    }
    this.paginationService.dayInstanceSelected(
      day,
      this.instance as ConciliationComponents
    );
  }
}
