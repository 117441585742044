import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TP2Permission,
  UserPermissionsService,
} from 'src/app/services/user-permissions-service';
import { AuditInfoComponent } from 'src/app/shared/components/audit-info/audit-info.component';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { DictionaryService } from 'src/app/shared/components/table-languages/dictionary.service';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';
import { Roles, TP2Entites } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { ReportService } from '../../report/report.service';
import { EdiaryService } from '../ediary.service';
import {
  ConfDay,
  ConfEDiary,
  ConfForm,
  CreateConfEDiaryInput,
} from '../ediary.types';

const logger = new Logger('tp2-logger-ediaryGeneralConfPage');

@Component({
  selector: 'app-general-configuration',
  templateUrl: './general-configuration.component.html',
  styleUrls: ['./general-configuration.component.scss'],
})
export class GeneralConfigurationComponent implements OnInit {
  ediaryConf: CreateConfEDiaryInput = {
    projectId: '',
    isSignatureRequired: false,
    isPastEntryEnabled: false,
    isGPSRequired: false,
    isUserIPRequired: false,
    isDeviceDataRequired: false,
    isConciliationRequired: false,
    isWindowsConciliationRequired: false,
    windows: [],
    _lastUser: '',
    _changeReason: null,
  };
  confForms: ConfForm[] = [];
  confDays: ConfDay[] = [];

  confEdiary!: ConfEDiary;

  id: any = null;
  expectedVersion: any = null;
  mediaTypes: any[] = [];
  translate: any;
  translateGeneral: any;
  projectId: any = null;
  confEdiaryId: any = null;
  isEdiaryLoaded = false;
  project: any;
  dictionary: any[] = [];
  results: string[] = [];
  weekDays: any[] = [];
  onDemandAlertTypes: any[] = [];
  originalEdidaryConf: CreateConfEDiaryInput = { ...this.ediaryConf };

  //Permissions
  hasDiaryCreatePermission: boolean = false;
  hasSymptomPermission: boolean = false;
  hasFormPermission: boolean = false;
  hasDayPermission: boolean = false;

  constructor(
    private trialpalService: TrialpalService,
    private ediaryService: EdiaryService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private dictionaryService: DictionaryService,
    public dialogService: DialogService,
    public reportService: ReportService,
    public userPermissionsService: UserPermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setPermissions();
    this.trialpalService.translateService
      .get('ediary.components.generalConfiguration')
      .subscribe((res) => {
        this.translate = res;
        this.trialpalService.showSpinner(
          this.translate.loadingEDiaryConfiguration
        );
      });
    this.trialpalService.translateService.get('general').subscribe((res) => {
      this.translateGeneral = res;
    });
    this.reportService.getWeekDays().then((res) => {
      this.weekDays = res;
    });
    this.reportService
      .getOnDemandAlertTypes()
      .then((res) => (this.onDemandAlertTypes = res));
    this.ediaryService.getMediaTypes().then((res) => (this.mediaTypes = res));
    this.projectId = this.route.snapshot.params.projectId;
    if (!this.projectId) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.translateGeneral.error,
        detail: this.translate.messageNoProject,
      });
      this.isEdiaryLoaded = false;
      return;
    }
    this.project = await this.getProject(this.projectId);
    const confEdiary = await this.getConfEdiaryByProjectId(this.projectId);
    this.updateConfEdiaryFromResponse(confEdiary);
    this.trialpalService.hideSpinner();
  }

  async setPermissions() {
    this.hasDiaryCreatePermission =
      await this.userPermissionsService.hasPermission([
        TP2Permission.DiaryCreate,
        Roles.Admin,
      ]);

    this.hasSymptomPermission = await this.userPermissionsService.hasPermission(
      [
        TP2Permission.DiarySymptomCreate,
        TP2Permission.DiarySymptomDelete,
        TP2Permission.DiarySymptomUpdate,
        Roles.Admin,
      ]
    );

    this.hasFormPermission = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryFormCreate,
      TP2Permission.DiaryFormUpdate,
      TP2Permission.DiaryFormDelete,
      Roles.Admin,
    ]);

    this.hasDayPermission = await this.userPermissionsService.hasPermission([
      TP2Permission.DiaryDayCreate,
      TP2Permission.DiaryDayDelete,
      Roles.Admin,
    ]);
  }

  async getProject(projectId: string) {
    const project = await this.projectService.getProject(projectId);
    logger.debug('getProject', project);
    if (!project) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.translateGeneral.error,
        detail: this.translate.messageNoProject,
      });
      this.isEdiaryLoaded = false;
    }

    return project;
  }

  async getConfEdiaryByProjectId(projectId: string) {
    const confEdiarys = await this.ediaryService.getConfEdiaryByProjectId(
      projectId
    );
    logger.debug('getConfEdiaryByProjectId', confEdiarys.items[0]);
    if (confEdiarys?.items?.length <= 0) {
      this.ediaryConf.projectId = this.projectId;
      const confEdiary = this.ediaryService.createConfEdiary(this.ediaryConf);
      logger.debug('createConfEdiary', confEdiary);
      return confEdiary;
    }
    this.getDictionary();
    return confEdiarys.items[0];
  }

  updateConfEdiaryFromResponse(data: any): void {
    this.id = data.id;
    //Elimina el typeName de la ventanas
    this.clearTypeNameWindows(data);
    this.confEdiary = { ...data };
    this.expectedVersion = data._version;
    this.trialpalService.cleanQueryResponse(data);
    delete data['project'];
    delete data['items'];
    Object.assign(this.ediaryConf, data);
    Object.assign(this.originalEdidaryConf, data);
    this.isEdiaryLoaded = true;
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      const changes = this.checkChanges();
      if (changes.length > 0) {
        this.openChangeReason();
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  goToProject(): void {
    this.router.navigate(['/project/' + this.projectId + '/detail']);
  }
  onDictionary(isGlobal: boolean): void {
    const ref = this.dialogService.open(TableLanguagesComponent, {
      data: {
        projectId: this.projectId,
        isGlobal: isGlobal,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe(() => {
      this.getDictionary();
    });
  }

  onChangeAlertType(): void {
    this.ediaryConf.alertWeeklyDays = [];
    this.ediaryConf.alertInBetweenDaysFrequency = null;
  }

  async getDictionary() {
    this.dictionary = [];
    await this.dictionaryService
      .getConfDictionaryByProjectId(this.projectId)
      .then((dictionary) => {
        dictionary.items?.forEach((item) => {
          const obj: Dictionary = {
            id: item?.id!,
            key: item?.key!,
            isHtml: item?.isHtml ?? false,
            spanish: item?.spanish ?? '',
            english: item?.english ?? '',
          };
          this.dictionary.push(obj);
        });
        localStorage.removeItem('Dictionaty');
        localStorage.setItem('Dictionary', JSON.stringify(dictionary));
      })
      .catch((err) => logger.error('No se pudo cargar el diccionario', err));
  }

  valueTime() {
    let starTime: any =
      moment(this.ediaryConf.alertStartTime, 'HH:mm:ss') || '';
    let endTime = moment(this.ediaryConf.alertEndTime, 'HH:mm:ss') || '';

    if (endTime < starTime) {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.translateGeneral.error,
        detail: this.trialpalService.translateService.instant(
          'report.invalidDateRange'
        ),
      });
      this.ediaryConf.alertEndTime = undefined;
      this.ediaryConf.alertStartTime = undefined;
    }
  }
  checkChanges(): Array<any> {
    type Change = { field: string; oldValue: any; newValue: any };
    const fieldsChanges: Array<Change> = [];
    for (const iterator of Object.keys(this.originalEdidaryConf)) {
      const key = iterator as keyof CreateConfEDiaryInput;
      if (this.ediaryConf[key] !== this.originalEdidaryConf[key]) {
        fieldsChanges.push({
          field: key,
          oldValue: this.originalEdidaryConf[key],
          newValue: this.ediaryConf[key],
        });
      }
    }
    return fieldsChanges;
  }
  openChangeReason() {
    this.trialpalService.showSpinner(
      this.translate.loadingSavingEDiaryConfiguration
    );
    this.ediaryService
      .updateConfEdiary(this.ediaryConf, this.id, this.expectedVersion)
      .then((data) => {
        if (data) {
          this.updateConfEdiaryFromResponse(data);
        }
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.translateGeneral.success,
          detail: this.translateGeneral.updateSuccess,
        });
      })
      .catch((err) => {
        logger.error('updateConfEdiary', err);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }
  openAuditModal() {
    this.dialogService.open(AuditInfoComponent, {
      data: {
        entity: TP2Entites.CONFEDIARY,
        description: this.trialpalService.translateService.instant(
          'project.labelEdiaryActivated'
        ),
        idRecord: this.ediaryConf.id,
      },
      width: '95%',
      height: '95%',
    });
  }

  //Función que se encarga de actualizar el ediaryConf segun los cambios en la ventana de conciliacion
  updateConfEdiaryFromConciliation(confEDiary: ConfEDiary) {
    this.expectedVersion = confEDiary._version;
    this.clearTypeNameWindows(confEDiary);
    this.ediaryConf = {
      ...this.ediaryConf,
      isConciliationRequired: confEDiary.isConciliationRequired,
      isWindowsConciliationRequired: confEDiary.isWindowsConciliationRequired,
      windows: confEDiary.windows,
      assessmentMode: confEDiary.assessmentMode,
    };
  }

  clearTypeNameWindows(confEdiary: any) {
    confEdiary.windows =
      confEdiary?.windows?.map((window: any) => {
        this.trialpalService.cleanQueryResponse(window);
        return window;
      }) ?? [];
  }
}
