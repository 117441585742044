<label
  class="p-m-0 p-t-0 p-pb-2 p-d-block"
  style="
    font-size: 1.5rem;
    border-bottom: 1px solid #0000002c;
    word-break: break-word;
  "
>
  {{ to?.label ?? '' | dictionary }}
</label>
