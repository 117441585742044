import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'aws-amplify';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ProjectService } from 'src/app/modules/project/project.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { Dictionary } from 'src/app/shared/components/table-languages/dictionary';
import { DictionaryService } from 'src/app/shared/components/table-languages/dictionary.service';
import { TableLanguagesComponent } from 'src/app/shared/components/table-languages/table-languages.component';
import { projectStateGlobal } from 'src/app/shared/global.variables';
import { EdiaryService } from '../../ediary.service';
import { FormListComponent } from '../../form/form-list/form-list.component';
import { CreateConfDayInput } from '../../ediary.types';
import { ConfVisitGroup } from 'src/app/modules/project/project.types';
const logger = new Logger('tp2-logger-dayAddEditPage');

@Component({
  selector: 'app-day-add-edit',
  templateUrl: './day-add-edit.component.html',
  styleUrls: ['./day-add-edit.component.scss'],
})
export class DayAddEditComponent implements OnInit {
  @ViewChild('confFormList') confFormList!: FormListComponent;

  formList: any[] = [];

  confDay: CreateConfDayInput = {
    projectId: this.projectService.currentProject?.id || '',
    confEdiaryId: '',
    confFormId: '',
    groups: [],
    phases: [],
    dayName: '',
    order: 1,
    startDay: 0,
    endDay: 0,
    _lastUser: '',
    trackSymptomOcurrencies: false,
    hidePDFDayColumn: false,
  };

  confDayCopy: CreateConfDayInput = {
    projectId: this.projectService.currentProject?.id || '',
    confEdiaryId: '',
    confFormId: '',
    groups: [],
    phases: [],
    dayName: '',
    order: 1,
    startDay: 0,
    endDay: 0,
    _lastUser: '',
    trackSymptomOcurrencies: false,
    hidePDFDayColumn: false,
  };

  confDays: any[] = [];
  confEdiaryId: any = null;
  isEdition = false;
  projectState: string = '';
  translate: any;
  translateGeneral: any;
  private id: any = null;
  private expectedVersion: any = null;
  projectGroups: any[] = [];
  projectPhases: any[] = [];
  dictionary: any[] = [];
  results: string[] = [];
  projectId: string = '';
  isSpanish: string = '';
  isEnglish: string = '';
  listVisitGroup: ConfVisitGroup[] = [];
  hidePDFDayColumn: boolean = false;
  readMode: boolean = false;
  constructor(
    private trialpalService: TrialpalService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ediaryService: EdiaryService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private readonly dictionaryService: DictionaryService
  ) {}

  ngOnInit(): void {
    this.confEdiaryId = this.config.data.confEdiaryId;
    this.projectState = this.config.data.projectState;
    this.dictionary = this.config.data.dictionary;
    this.projectId = this.config.data.project.id;
    this.confDays = this.config.data.confDays;
    this.readMode = this.config.data.readMode;

    this.projectGroups = this.config.data.project.groups.map((e: any) => {
      const label = this.trialpalService.dictionaryPipe.transform(e);
      return {
        label,
        value: e,
      };
    });

    //Obtiene todas las visitas asociadas a un grupo
    this.projectService
      .getConfVisitGroup(this.projectId)
      .then((response: any) => {
        this.listVisitGroup = response;
        //Muestra las visitas asociadas a los grupos escogidos anteriormente
        this.getPhases(this.config.data.confDay.groups || []);
      });

    this.translateService
      .get('ediary.components.dayAddEdit')
      .subscribe((res) => (this.translate = res));
    this.translateService
      .get('general')
      .subscribe((res) => (this.translateGeneral = res));
    this.trialpalService.showSpinner(this.translateGeneral.loadingInfo);
    this.ediaryService
      .getConfFormByConfEdiaryId(this.confEdiaryId)
      .then((res) => {
        if (res.items) {
          this.formList = res.items
            .filter((e) => e && !e._deleted)
            .map((e: any) => {
              const name = this.trialpalService.dictionaryPipe.transform(
                e.name
              );
              return {
                name,
                value: e.id,
              };
            });
        }
        this.trialpalService.hideSpinner();
      })
      .catch((err) => logger.error('getConfFormByConfEdiaryId', err));
    if (this.config.data.confDay) {
      this.isEdition = true;
      this.id = this.config.data.confDay.id;
      this.expectedVersion = this.config.data.confDay._version;
      this.trialpalService.cleanQueryResponse(this.config.data.confDay);
      delete this.config.data.confDay.confEdiary;
      delete this.config.data.confDay.confForm;
      delete this.config.data.confDay.project;
      Object.assign(this.confDay, this.config.data.confDay);
      this.confDay.hidePDFDayColumn = this.confDay?.hidePDFDayColumn ?? false;
      this.confDayCopy = { ...this.confDay };
      this.selectKeyLanguage(undefined, this.confDay.dayName);
    }
  }

  onSubmit(form: NgForm): void {
    this.confDay.dayName = this.confDay.dayName.trim();
    this.trialpalService.validateSpaces(this.confDay.dayName, 'dayName', form);

    if (form.valid) {
      if (!this.validateStartDay(this.confDay)) return;
      if (this.isEdition) {
        this.editDayTransaction();
      } else {
        this.createDay();
      }
    } else {
      this.trialpalService.showInvalidFormError();
    }
  }

  createDay() {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.loadingSavingDay'
      )
    );
    this.confDay.confEdiaryId = this.confEdiaryId;
    this.ediaryService
      .createConfDay(this.confDay)
      .then((res) => {
        this.ref.close(res);
      })
      .catch((err) => {
        logger.error('createConfDay', err);
        this.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.detail'
          ),
        });
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  async editDayTransaction() {
    if (JSON.stringify(this.confDay) === JSON.stringify(this.confDayCopy)) {
      this.trialpalService.hideSpinner();
      this.ref.close('');
    } else if (
      this.projectState === projectStateGlobal.IN_PROGRESS ||
      this.projectState === projectStateGlobal.IN_DESIGN
    ) {
      await this.modalChangeReason();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: this.trialpalService.translateService.instant(
          'ediary.components.dayAddEdit.errorMessageEditingDay.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.dayAddEdit.errorMessageEditingDay.detail'
        ),
      });
    }
  }

  async modalChangeReason() {
    return this.updateDay();
  }

  validateStartDay(currentConfDay: any) {
    const confDays = this.confDays.filter((confDay: any) => {
      return (
        confDay?.confFormId === currentConfDay?.confFormId &&
        confDay?.startDay === currentConfDay?.startDay &&
        confDay.id !== currentConfDay?.id
      );
    });

    if (confDays.length > 0) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'general.messageErrorOperation.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'ediary.components.dayAddEdit.errorDayExist.detail'
        ),
      });
      return false;
    }
    return true;
  }

  onCancel(): void {
    this.ref.close(null);
  }

  getChipText(item: string): string {
    return item.length <= 20 ? item : item.substring(0, 20) + '...';
  }

  updateDay(): void {
    this.trialpalService.showSpinner(
      this.trialpalService.translateService.instant(
        'ediary.components.dayAddEdit.loadingUpdatingDay'
      )
    );
    this.ediaryService
      .updateConfDay(this.confDay, this.id, this.expectedVersion)
      .then((res) => {
        delete res.project;
        this.ref.close(res);
      })
      .catch((err) => {
        logger.error('updateConfDay', err);
        this.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.detail'
          ),
        });
        const res = {
          error: err,
        };
        this.ref.close(res);
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  onDictionaty(isForm: boolean): void {
    const ref = this.dialogService.open(TableLanguagesComponent, {
      data: {
        projectId: this.projectId,
        isForm: isForm,
      },
      width: '95%',
      height: '95%',
    });

    ref.onClose.subscribe(async (data) => {
      if (data) {
        this.trialpalService.showSpinner('dictionary.updateForm', 'UPDATE');
        this.dictionary = [];
        await this.dictionaryService
          .getConfDictionaryByProjectId(this.projectId)
          .then((dictionary) => {
            dictionary.items?.forEach((item) => {
              const obj: Dictionary = {
                id: item?.id!,
                key: item?.key!,
                isHtml: item?.isHtml ?? false,
                spanish: item?.spanish ?? '',
                english: item?.english ?? '',
              };
              this.dictionary.push(obj);
            });
            localStorage.removeItem('Dictionaty');
            localStorage.setItem('Dictionary', JSON.stringify(dictionary));
            this.messageService.add({
              severity: 'success',
              summary: this.trialpalService.translateService.instant(
                'dictionary.selectTranslate'
              ),
              detail: this.trialpalService.translateService.instant(
                'dictionary.succesSelectTranslate'
              ),
            });
          })
          .catch((err) => logger.error('No se pudo cargar el diccionario', err))
          .finally(() => {
            this.selectKeyLanguage(undefined, data.key);
            this.confDay.dayName = data.key;
          });
        this.trialpalService.hideSpinner();
      }
    });
  }

  search(event: any) {
    this.isSpanish = '';
    this.isEnglish = '';
    this.results = this.dictionary
      .filter((data) => {
        return data.key.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
      })
      .map((data) => data.key);
  }

  selectKeyLanguage(event: any, titleReport?: string) {
    this.dictionary.forEach((data) => {
      if (data.key === event || data.key === titleReport) {
        this.isSpanish = data.spanish;
        this.isEnglish = data.english;
      }
    });
  }

  changeGroup() {
    this.getPhases(this.confDay.groups);
  }

  //Obtiene las visitas correspondientes al grupo seleccionado
  getPhases(groupNames: string[]) {
    let phases: any[] = [];
    //Añade en un array todas las visitas que esten relacionadas al array de grupos
    groupNames.forEach((groupName) => {
      let phasesGroup: any[] = this.listVisitGroup
        .filter((visitGroup: any) => visitGroup.group === groupName)
        .map((visitGroup: any) => ({
          value: visitGroup.visit,
          label: this.trialpalService.dictionaryPipe.transform(
            visitGroup.visit
          ),
        }));
      phases.push(...phasesGroup);
    });
    //Eliminas las visitas duplicadas
    phases = phases.filter((visit: any, index: number) => {
      return phases.findIndex((x: any) => x.value === visit.value) === index;
    });
    //Elimina las visitas seleccionadas que ya no se encuentran disponibles
    this.confDay.phases = this.confDay.phases.filter((phase: string) => {
      return phases.findIndex((x: any) => x.value === phase) !== -1;
    });
    this.projectPhases = phases;
  }
}
