<span *ngIf="verifiedInstance?.state !== 'DELETED' || !verifiedInstance?.id">
  <span
    pTooltip="{{ 'conciliation.tooltipConciliated' | translate }}"
    *ngIf="isConciliated"
  >
    <em class="pi pi-check-circle emConciliated"></em>
  </span>
  <span
    pTooltip="{{ 'conciliation.tooltipPendingConciliated' | translate }}"
    *ngIf="!isConciliated"
  >
    <em class="pi pi-info-circle emPendingConciliated"> </em>
  </span>
</span>

<span *ngIf="verifiedInstance?.state === 'DELETED'" style="color: #ff5252">
  <span pTooltip="{{ 'conciliation.DRAFT' | translate }}">
    <em class="pi pi-times"> </em>
  </span>
</span>
