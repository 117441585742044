<div>
  <div class="p-grid p-fluid p-justify-end p-mb-4">
    <div class="p-col-12">
      <h3>{{ 'informedConsent.question.questionsList' | translate }}</h3>
    </div>
    <div class="p-col-12 p-md-6 p-xl-3" *ngIf="confICQuestionCards.length > 0">
      <button
        pButton
        pRipple
        label="{{ 'informedConsent.question.addQuestion' | translate }}"
        type="button"
        class="p-button-secondary"
        (click)="onCreate()"
      ></button>
    </div>
  </div>

  <div class="grid-cards" *ngIf="confICQuestionCards.length > 0">
    <app-tp2-card
      *ngFor="let confIcQuestion of confICQuestionCards"
      [tp2Card]="confIcQuestion"
    ></app-tp2-card>
  </div>

  <div *ngIf="confICQuestionCards.length === 0">
    <app-not-found-items
      [description]="
        'informedConsent.question.addQuestionDescription' | translate
      "
      [buttonDescription]="
        'informedConsent.answer.addAnswerButtonDescription' | translate
      "
      [buttonText]="'informedConsent.question.addQuestion' | translate"
      (clickEmitter)="onCreate()"
    ></app-not-found-items>
  </div>
</div>
<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="onDelete()"
  position="fixed"
>
</app-delete-confirmation>
