<div class="add-day p-mb-3 p-d-flex p-jc-end">
  <button
    id="btn_addDay"
    name="btn_addDay"
    class="p-button-secondary"
    pButton
    type="button"
    label="{{ 'general.add' | translate }}"
    (click)="newMedicalAttention()"
  ></button>
  <button
    *ngIf="showDeleteButton()"
    id="btn_delete"
    pButton
    type="button"
    class="p-button-danger p-ml-3"
    icon="pi pi-trash"
    iconPos="left"
    (click)="confirmDeleteMedicalAttention()"
  ></button>
</div>

<app-table-day-instance
  [instance]="'MEDICAl_ATTETION'"
  (emitData)="isMedicalAttentionLoading($event)"
>
</app-table-day-instance>

<div class="medicalAttention-conciliation">
  <div
    *ngIf="
      medicalAttentionService.medicalAttentionTable &&
      medicalAttentionService.medicalAttentionTable.length > 0
    "
  >
    <p-table
      scrollDirection="horizontal"
      styleClass="p-datatable-gridlines"
      [resizableColumns]="true"
      responsiveLayout="scroll"
      dataKey="index"
      selectionMode="single"
      (onRowSelect)="onRowSelect($event)"
      [metaKeySelection]="true"
      [value]="medicalAttentionService.medicalAttentionTable"
      [scrollable]="true"
      [(selection)]="medicalAttentionService.selectedMedicalAttention"
      [columns]="cols"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="header" class="cell" *ngFor="let col of columns">
            {{ col?.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-medicalAttention>
        <tr [pSelectableRow]="medicalAttention">
          <td class="cell cell-flex">
            <app-check
              [isConciliated]="medicalAttention.isEdition"
              [verifiedInstance]="medicalAttention?.verifiedMedicalAttention"
            ></app-check>
            <p>
              <span
                [innerHTML]="
                  medicalAttention?.type?.record
                    | textNa: medicalAttention?.type?.verified
                "
              ></span>
              <sup
                class="p-ml-2"
                *ngIf="
                  medicalAttention?.type?.comment &&
                  medicalAttention?.type?.indexComment
                "
                [pTooltip]="medicalAttention?.type?.comment"
              >
                {{ medicalAttention?.type?.indexComment }}
              </sup>
            </p>
          </td>
          <td class="cell">
            <p>
              <span
                [innerHTML]="
                  medicalAttention?.reason?.record
                    | textNa: medicalAttention?.reason?.verified
                "
              ></span>

              <sup
                class="p-mx-2"
                *ngIf="
                  medicalAttention?.reason?.comment &&
                  medicalAttention?.reason?.indexComment
                "
                [pTooltip]="medicalAttention?.reason?.comment"
              >
                {{ medicalAttention?.reason?.indexComment }}
              </sup>
            </p>
          </td>
          <td class="cell">
            <p>
              <span
                [innerHTML]="
                  medicalAttention?.wasHospitalized?.record
                    | textNa: medicalAttention?.wasHospitalized?.verified
                "
              ></span>
              <sup
                class="p-ml-2"
                *ngIf="
                  medicalAttention?.wasHospitalized?.comment &&
                  medicalAttention?.wasHospitalized?.indexComment
                "
                [pTooltip]="medicalAttention?.wasHospitalized?.comment"
              >
                {{ medicalAttention?.wasHospitalized?.indexComment }}
              </sup>
            </p>
          </td>
          <td class="cell">
            <p>
              <span
                [innerHTML]="
                  medicalAttention?.startDate?.record
                    | textNa: medicalAttention?.startDate?.verified
                "
              ></span>
              <sup
                class="p-ml-2"
                *ngIf="
                  medicalAttention?.startDate?.comment &&
                  medicalAttention?.startDate?.indexComment
                "
                [pTooltip]="medicalAttention?.startDate?.comment"
              >
                {{ medicalAttention?.startDate?.indexComment }}
              </sup>
            </p>
          </td>
          <td class="cell">
            <p>
              <span
                [innerHTML]="
                  medicalAttention?.finishDate?.record
                    | textNa: medicalAttention?.finishDate?.verified
                "
              ></span>
              <sup
                class="p-ml-2"
                *ngIf="
                  medicalAttention?.finishDate?.comment &&
                  medicalAttention?.finishDate?.indexComment
                "
                [pTooltip]="medicalAttention?.finishDate?.comment"
              >
                {{ medicalAttention?.finishDate?.indexComment }}
              </sup>
            </p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div
    class="p-grid p-col-12 p-pt-3"
    *ngIf="
      medicalAttentionService.observations &&
      medicalAttentionService.observations.length > 0
    "
  >
    <label for="" class="p-col-12">
      {{ 'conciliation.titleObservation' | translate }}
    </label>
    <!-- commentsDay -->
    <div *ngFor="let observation of medicalAttentionService.observations">
      <div class="p-d-flex p-d-flex-row p-flex-wrap">
        <ul>
          <label id="lbl_record" class="p-ml-1">
            {{ observation.indexComment }}.
          </label>
          <span class="line">{{ observation.comment }}</span>
        </ul>
      </div>
    </div>
  </div>
</div>

<div
  class="p-grid p-fluid"
  *ngIf="
    medicalAttentionService.medicalAttentionTable &&
    medicalAttentionService.medicalAttentionTable.length === 0 &&
    medicalAttentionService.preconditionTable &&
    medicalAttentionService.preconditionTable.length === 1
  "
>
  <h4 class="notResult">
    {{ 'general.noResultsFound' | translate }}
  </h4>
</div>
