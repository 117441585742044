import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-json-view',
  templateUrl: './json-view.component.html',
  styleUrls: ['./json-view.component.scss'],
})
export class JsonViewComponent {
  @Input() formField: string = '';
  @Input() readOnly: boolean = false;
  @Output() formFieldChange: EventEmitter<any> = new EventEmitter();
  counter: number = 0;
  editorOptions = {
    theme: 'vs-light', // Tema del editor
    language: 'json', // Idioma (lenguaje) del editor
    minimap: {
      enabled: false,
    },
    automaticLayout: true,
    wordWrap: 'on',
    scrollBeyondLastLine: false,
    cursorBlinking: 'expand',
    fontSize: 14,
    lineNumbers: 'on',
    rulers: [80],
    autoIndent: true,
    formatOnPaste: true,
    formatOnType: true,
    scrollBar: {
      vertical: 'auto',
      horizontal: 'auto',
    },
  };
  monacoEditorElement: any;
  darkmodeActive: boolean = false;
  locked: boolean = false;

  //Funcion que se llama cuando se inicializa el editor y toma su referencia
  async initEditor(editorElement: any) {
    this.monacoEditorElement = editorElement;
    this.locked = true;
    this.activateAction('editor.action.formatDocument');
    await this.wait700ms();
    this.locked = false;
    this.readOnly &&
      this.monacoEditorElement?.updateOptions({ readOnly: true });
  }

  //Función que se dispara cada vez que hay un cambio en el editor (Se dispara tambien cuando se hace un cambio en el diseñador)
  async onEditorContentChange() {
    if (!this.locked) {
      await this.wait700ms();
      this.formFieldChange.emit(this.formField);
    }
  }

  changeEditorTheme() {
    this.darkmodeActive = !this.darkmodeActive;
    const theme = this.darkmodeActive ? 'vs-dark' : 'vs-light';
    this.editorOptions = { ...this.editorOptions, theme: theme };
  }

  keyBoardAction(action: string) {
    if (this.monacoEditorElement) {
      this.monacoEditorElement.trigger('keyboard', action, null);
    }
  }

  activateAction(action: string) {
    if (this.monacoEditorElement) {
      setTimeout(() => this.monacoEditorElement.getAction(action).run(), 100);
    }
  }

  async wait700ms() {
    return new Promise((resolve) => setTimeout(() => resolve(true), 700));
  }
}
