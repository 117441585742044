import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { ConfirmationService } from 'primeng/api';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  ConfICAnswer,
  CreateConfICAnswerInput,
  UpdateConfICAnswerInput,
} from '../../informed-consent.types';
import { ConfState } from 'src/app/services/trialpal.types';
const logger = new Logger('tp2-logger-addEditInformedConsentAnswerComponent');

@Component({
  selector: 'app-informed-consent-answers',
  templateUrl: './informed-consent-answers.component.html',
  styleUrls: ['./informed-consent-answers.component.scss'],
})
export class InformedConsentAnswersComponent implements OnChanges {
  @Input() confICAnswers: any[] = [];
  @Input() projectId: any = '';
  @Input() isReadOnly: any = true;
  @Output() emitConfICAnswers: EventEmitter<any[]> = new EventEmitter();
  showDisplayDeleteComponent: boolean = false;
  INFORMED_CONSENT_ANSWER_ENTITY = 'informedConsent.answer.answer';
  currentConfICAnswer = {} as CreateConfICAnswerInput | UpdateConfICAnswerInput;
  currentConfICAnswerIndex: number = -1;
  showDialog = false;
  isConfAnswerEdition = false;
  confICAnswerSelect: any = {};
  confICAnswerIndexSelect: any = {};
  isDeleteButtonDisabled: boolean = false;
  originalConfICAnswer: any = {};
  constructor(
    private readonly trialpalService: TrialpalService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnChanges(): void {
    this.validateDeleteButtonDisabled();
    logger.debug('CurrentAnwers', this.confICAnswers);
  }

  onAddAnswer(): void {
    this.isConfAnswerEdition = false;
    this.currentConfICAnswer = {
      order: null,
      description: '',
      isCorrect: false,
      onErrorMessage: '',
    } as Omit<ConfICAnswer, 'order'> & { order: number | null };
    this.currentConfICAnswerIndex = this.confICAnswers.length;
    this.showDialog = true;
  }

  onEditAnswer(answer: any, currentConfICAnswerIndex: number) {
    this.isConfAnswerEdition = true;
    this.currentConfICAnswer = { ...answer };
    this.currentConfICAnswerIndex = currentConfICAnswerIndex;
    this.showDialog = true;
    this.originalConfICAnswer = structuredClone(this.currentConfICAnswer);
    logger.debug('CurrentAnwers edit', this.currentConfICAnswer);
  }

  onSubmitAnswer(form: NgForm): void {
    if (!form.valid) return this.trialpalService.showInvalidFormError();
    if (this.getHasCorrectAnswer() && this.currentConfICAnswer.isCorrect) {
      return this.hasCorrectAnswerMessage();
    }
    let message_success = 'UPDATE';
    if (this.isConfAnswerEdition) {
      this.updateConfICAnswer();
    } else {
      this.confICAnswers = [...this.confICAnswers, this.currentConfICAnswer];
      message_success = 'CREATE';
    }

    this.emitConfICAnswers.emit(this.confICAnswers);
    this.validateDeleteButtonDisabled();
    this.showSuccessMessage(message_success);
    this.showDialog = false;
    this.currentConfICAnswerIndex = -1;
  }

  getHasCorrectAnswer(): boolean {
    return this.confICAnswers.some(
      (answer: ConfICAnswer, index: number) =>
        answer.isCorrect &&
        answer.state !== ConfState.DELETED &&
        index !== this.currentConfICAnswerIndex
    );
  }

  hasCorrectAnswerMessage() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant('general.error'),
      detail: this.trialpalService.translateService.instant(
        'informedConsent.answer.messageErrorCorrectAnswer.detail'
      ),
      life: 5000,
    });
  }

  showSuccessMessage(message_success: string) {
    this.trialpalService.messageService.clear();
    this.trialpalService.showMutationSuccess(
      this.INFORMED_CONSENT_ANSWER_ENTITY,
      message_success
    );
  }

  //Actualiza el arreglo de preguntas
  private updateConfICAnswer(): void {
    this.confICAnswers = this.confICAnswers.map(
      (_confAnswer: ConfICAnswer, _currentConfICAnswerIndex: number) => {
        //Valida si la misma respuesta a la que se esta actualizando
        const isCurrentConfAnwer = this.currentConfICAnswer.id
          ? this.currentConfICAnswer.id === _confAnswer.id
          : _currentConfICAnswerIndex === this.currentConfICAnswerIndex;

        if (!isCurrentConfAnwer) return _confAnswer;
        if (
          JSON.stringify(this.originalConfICAnswer) !==
          JSON.stringify(this.currentConfICAnswer)
        ) {
          return { ...this.currentConfICAnswer, updated: true };
        } else {
          return { ...this.currentConfICAnswer };
        }
      }
    );
  }

  /**
   * La función onDeleteAnswer se utiliza para eliminar una respuesta de confirmación y actualizar la
   * lista de respuestas de confirmación en consecuencia.
   * @param {ConfICAnswer} confAnswer - El parámetro `confAnswer` es un objeto de tipo `ConfICAnswer`.
   * Representa la respuesta que debe eliminarse.
   * @param {number} index - El parámetro de índice es el índice de la respuesta en la matriz de
   * respuestas. Se utiliza para identificar la respuesta específica que debe eliminarse.
   */
  showDeleteModal(confAnswer: ConfICAnswer, index: number) {
    this.confICAnswerSelect = confAnswer;
    this.confICAnswerIndexSelect = index;
    this.showDisplayDeleteComponent = true;
  }
  onDeleteAnswer() {
    const confAnswer = this.confICAnswerSelect;
    const confICAnswerIndexSelect = this.confICAnswerIndexSelect;
    this.trialpalService.showSpinner(
      this.INFORMED_CONSENT_ANSWER_ENTITY,
      'DELETE'
    );

    //Pasa a estado DELETED los registros creados anteriormente
    if (confAnswer.id) {
      this.confICAnswers = this.confICAnswers.map((_confAnswer: ConfICAnswer) =>
        _confAnswer.id === confAnswer.id
          ? {
              ...confAnswer,
              state: ConfState.DELETED,
              _changeReason: '',
              updated: true,
            }
          : _confAnswer
      );
      //Si no, los borra del arreglo
    } else {
      this.confICAnswers = this.confICAnswers.filter(
        (_confAnswer: ConfICAnswer, _index: number) =>
          confICAnswerIndexSelect !== _index
      );
    }
    this.trialpalService.showMutationSuccess(
      this.INFORMED_CONSENT_ANSWER_ENTITY,
      'DELETE'
    );
    this.validateDeleteButtonDisabled();
    this.emitConfICAnswers.emit(this.confICAnswers);
    this.trialpalService.hideSpinner();
  }

  //Valida si almenos hay una respuesta activa para activar el boton de eliminar
  validateDeleteButtonDisabled() {
    const confICAnswers = this.confICAnswers.filter(
      (confICAnswer: ConfICAnswer) => {
        return confICAnswer.state !== ConfState.DELETED;
      }
    );

    this.isDeleteButtonDisabled = confICAnswers.length <= 1;
  }

  onCancel(): void {
    this.showDialog = false;
  }
}
