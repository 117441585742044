<app-button-refresh-data
  [showSpinner]="showSpinner"
  (buttonAction)="ngOnInit()"
></app-button-refresh-data>

<app-tour-guide-button
  [pageTourDataType]="DETAIL_SUBJECT_EDIARY_TOUR"
  *ngIf="eDiaryPhases?.length && !showSpinner"
></app-tour-guide-button>

<section *ngIf="!showSpinner">
  <diV *ngIf="eDiaryPhases.length > 0" id="eDiaryPhases_container">
    <div style="display: flex; align-items: center">
      <h5>{{ 'subject.eDiaryPDF' | translate }}</h5>
    </div>
    <div class="grid-ediary-cards">
      <app-tp2-card
        [tp2Card]="tp2Card"
        (tp2CardEmmiter)="
          !tp2Card.islabelsEnabled &&
            shouldShowPDFDiary &&
            openModal(tp2Card.data, false, 'EDiaryCard')
        "
        *ngFor="let tp2Card of ediaryPhaseCards; let i = index"
        id="{{
          i === ediaryPhaseCards.length - 1 && ediaryPhaseCards.length > 1
            ? 'consolidate_card'
            : 'eDiaryPhases_container' + i
        }}"
        [class.withLabels]="tp2Card.islabelsEnabled || !shouldShowPDFDiary"
      >
        <aside
          id="documents_cta"
          *ngIf="tp2Card.islabelsEnabled"
          class="p-d-flex p-jc-between p-pb-2"
        >
          <div
            (click)="openModal(tp2Card.data, false, 'EDiaryLabel')"
            *ngIf="shouldShowPDFDiary"
          >
            <span class="p-menuitem-icon pi pi-file-pdf p-mr-1"></span>
            <label>{{ 'subject.sourceDocument' | translate }}</label>
          </div>
          <div
            (click)="openModal(tp2Card.data, true, 'AssessmentLabel')"
            *ngIf="shouldShowPDFAssessment"
          >
            <span class="p-menuitem-icon pi pi-file-pdf p-mr-1"></span>
            <label>{{ 'subject.reconciliateDocument' | translate }}</label>
          </div>
        </aside>
      </app-tp2-card>
    </div>
  </diV>

  <div class="p-grid p-fluid" *ngIf="eDiaryPhases.length === 0">
    <h4 style="width: 100%; text-align: center">
      {{ 'general.noResultsFound' | translate }}
    </h4>
  </div>
</section>
<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
