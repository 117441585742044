<p-card
  [style]="{
    height: '100%',
    'background-color': isCardSelected
      ? '#ADD8E6'
      : tpCardData.cardColor || 'white',
    'min-height': calculateHeight
  }"
  (mouseover)="isCardSelected = true"
  (mouseout)="isCardSelected = false"
>
  <div class="p-card-body no-padding">
    <div class="p-grid">
      <div
        [ngClass]="{
          'p-col-12': !isCardSelected,
          'p-col-10': isCardSelected && items.length > 3,
          'p-col-8': isCardSelected && items.length < 3
        }"
        (click)="onDetail()"
        [style]="{ cursor: tpCardData.cardIsButton ? 'pointer' : 'auto' }"
      >
        <h4
          class="tpcard-header"
          pTooltip="{{ tpCardData.header }}"
          tooltipPosition="top"
          *ngIf="!tpCardData.isHeaderDictionary; else headerDictionary"
        >
          {{
            isCardSelected
              ? getShortText(tpCardData.header, short / 3)
              : tpCardData.header
          }}
        </h4>
        <ng-template #headerDictionary>
          <h4
            *ngIf="tpCardData.header"
            class="tpcard-header"
            tooltipPosition="top"
            pTooltip="{{ tpCardData.header | dictionary }}"
          >
            {{
              isCardSelected
                ? (tpCardData.header | dictionary | shortText: short)
                : (tpCardData.header | dictionary)
            }}
          </h4>
        </ng-template>
      </div>
      <div class="p-col p-text-right" [hidden]="!isCardSelected">
        <div *ngIf="items.length > 3; else buttons">
          <p-menu
            #menu
            [popup]="true"
            [model]="items"
            [style]="{ 'white-space': 'nowrap', width: 'auto' }"
          >
          </p-menu>
          <button
            id="btn_toggle"
            name="btn_toggle"
            type="button"
            pButton
            icon="pi pi-ellipsis-v"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
            (click)="menu.toggle($event)"
          ></button>
        </div>

        <ng-template #buttons>
          <button
            id="btn_showActivateButton"
            name="btn_showActivateButton"
            *ngIf="tpCardData.showActivateButton"
            pButton
            (click)="onActivate()"
            type="button"
            [icon]="
              tpCardData.stateActivateButton ? 'pi pi-ban' : 'pi pi-check'
            "
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>

          <button
            id="btn_showEditButton"
            name="btn_showEditButton"
            *ngIf="tpCardData.showEditButton"
            title="{{ 'user.buttonEdit' | translate }}"
            pButton
            (click)="onEdit()"
            type="button"
            icon="pi pi-pencil"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
          <button
            id="btn_showDeleteButton"
            name="btn_showDeleteButton"
            *ngIf="tpCardData.showDeleteButton"
            title="{{ 'user.buttonDelete' | translate }}"
            pButton
            (click)="onDelete()"
            type="button"
            icon="pi pi-trash"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
          <button
            id="btn_showCustomButton"
            name="btn_showCustomButton"
            *ngIf="tpCardData.showCustomButton"
            pButton
            (click)="onCustomAction()"
            type="button"
            [icon]="tpCardData.iconCustomButton || ''"
            [pTooltip]="tpCardData.tooltipCustomButtom || ''"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
          <button
            id="btn_showCustomButton2"
            name="btn_showCustomButton2"
            *ngIf="tpCardData.showCustomButton2"
            pButton
            (click)="onCustomAction2()"
            type="button"
            [icon]="tpCardData.iconCustomButton2 || ''"
            [pTooltip]="tpCardData.tooltipCustomButtom2 || ''"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
          <button
            id="btn_showCustomButton3"
            name="btn_showCustomButton3"
            *ngIf="tpCardData.showCustomButton3"
            pButton
            (click)="onCustomAction3()"
            type="button"
            [icon]="tpCardData.iconCustomButton3 || ''"
            [pTooltip]="tpCardData.tooltipCustomButtom3 || ''"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
          <button
            id="btn_showAuditButton"
            name="btn_showAuditButton"
            *ngIf="tpCardData.showAuditButton"
            title="{{ 'general.btnAuditTrace' | translate }}"
            pButton
            (click)="cardOrTableService.openAuditModal(tpCardData)"
            type="button"
            icon="pi pi-file"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
          <button
            id="btn_resetPasswordButton"
            name="btn_resetPasswordButton"
            *ngIf="tpCardData.showResetPasswordButton"
            title="{{ 'user.resetPassword' | translate }}"
            pButton
            (click)="resetPasswordUser()"
            type="button"
            icon="pi pi-key"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-mr-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
          ></button>
        </ng-template>
      </div>
    </div>
    <div
      class="p-grid"
      (click)="onDetail()"
      [style]="{
        padding: '0',
        cursor: tpCardData.cardIsButton ? 'pointer' : 'auto'
      }"
    >
      <div
        *ngIf="tpCardData.state && !tpCardData.hideState"
        class="p-col-1 p-text-center rotated"
        title="{{ tpCardData.stateToolTip || '' | dictionary }}"
      >
        <strong
          [style]="{
            color: tpCardData.styleColorState
              ? tpCardData.styleColorState
              : 'black'
          }"
          >{{ tpCardData.state }}</strong
        >
      </div>
      <div class="p-col">
        <div
          class="p-grid"
          *ngIf="tpCardData.section1 || tpCardData.icon1"
          title="{{ tpCardData.section1ToolTip || '' | dictionary }}"
        >
          <div *ngIf="tpCardData.icon1" class="p-col-1 p-mr-1">
            <i class="{{ tpCardData.icon1 }}" aria-hidden="true"></i>
          </div>
          <div class="p-col" *ngIf="tpCardData.section1">
            <span
              pTooltip="{{
                needToolTip(tpCardData.section1)
                  ? (tpCardData.section1 || '' | dictionary)
                  : ''
              }}"
              >{{ getShortText(tpCardData.section1) | dictionary }}</span
            >
          </div>
        </div>
        <div
          class="p-grid"
          *ngIf="tpCardData.section2 || tpCardData.icon2"
          title="{{ tpCardData.section2ToolTip || '' | dictionary }}"
        >
          <div *ngIf="tpCardData.icon2" class="p-col-1 p-mr-1">
            <i class="{{ tpCardData.icon2 }}" aria-hidden="true"></i>
          </div>
          <div class="p-col" *ngIf="tpCardData.section2">
            <span
              pTooltip="{{
                needToolTip(tpCardData.section2)
                  ? (tpCardData.section2 || '' | dictionary)
                  : ''
              }}"
              >{{ getShortText(tpCardData.section2) }}</span
            >
          </div>
        </div>
        <div
          class="p-grid"
          title="{{ tpCardData.section3ToolTip || '' | dictionary }}"
          *ngIf="
            tpCardData?.section3 !== 'symptom.enums.symptomTypes.null' &&
            (tpCardData.section3 || tpCardData.icon3)
          "
        >
          <div *ngIf="tpCardData.icon3" class="p-col-1 p-mr-1">
            <i class="{{ tpCardData.icon3 }}" aria-hidden="true"></i>
          </div>
          <div class="p-col">
            <span
              pTooltip="{{
                needToolTip(tpCardData.section3)
                  ? (tpCardData.section3 || '' | dictionary)
                  : ''
              }}"
              >{{ getShortText(tpCardData.section3) }}</span
            >
          </div>
        </div>
        <div
          class="p-grid"
          *ngIf="
            tpCardData.section4 &&
            tpCardData?.section4 !== 'symptom.enums.symptomTypes.null'
          "
        >
          <div *ngIf="tpCardData.icon4" class="p-col-1 p-mr-1">
            <i class="{{ tpCardData.icon4 }}" aria-hidden="true"></i>
          </div>
          <div class="p-col">
            <span
              pTooltip="{{
                needToolTip(tpCardData.section4)
                  ? (tpCardData.section4 || '' | dictionary)
                  : ''
              }}"
              >{{ getShortText(tpCardData.section4) }}</span
            >
          </div>
        </div>
      </div>
      <div class="p-col-3 p-text-center" *ngIf="tpCardData.image">
        <img src="{{ getImage() }}" style="margin: 0" alt="" height="60px" />
      </div>
      <div class="p-col-3 p-text-center" *ngIf="tpCardData.image2">
        <img
          src="{{ getImage(true) }}"
          style="margin: 0"
          height="60px"
          width="60px"
          class="imgRound"
          alt="Icon"
        />
      </div>
    </div>
    <div
      class="p-grid"
      (click)="onDetail()"
      [style]="{ cursor: tpCardData.cardIsButton ? 'pointer' : 'auto' }"
      *ngIf="tpCardData.footer || tpCardData.footer2"
    >
      <div
        class="p-col"
        *ngIf="getShortText(tpCardData.footer)"
        title="{{ tpCardData.footer1ToolTip || '' | dictionary }}"
      >
        <span>{{ getShortText(tpCardData.footer) }}</span>
      </div>
      <div
        title="{{ tpCardData.footer2ToolTip || '' | dictionary }}"
        [ngClass]="{
          'p-col-3 p-text-left': getShortText(tpCardData.footer),
          'p-col p-text-right': !getShortText(tpCardData.footer)
        }"
      >
        <span
          ><em
            ><strong>{{ getShortText(tpCardData.footer2) }}</strong></em
          ></span
        >
      </div>
    </div>
  </div>
</p-card>
