<div class="container-tp2">
  <app-back-button-header
    [pageTitle]="
      (isEdition
        ? 'informedConsent.question.editQuestion'
        : 'informedConsent.question.addQuestion'
      ) | translate
    "
    [pageDescription]="
      'informedConsent.question.configureDescription' | translate
    "
    [pageUrl]="
      '/informed-consent/' +
      projectId +
      '/detail/' +
      confInformedConsentId +
      '/section/' +
      confSectionId
    "
    [showButton]="false"
  ></app-back-button-header>
  <div class="tp2Card">
    <form
      name="form"
      (ngSubmit)="onSubmit(questionForm)"
      #questionForm="ngForm"
      autocomplete="off"
    >
      <p class="p-mt-0 step-subtitle p-mb-4">
        <span class="p-pr-2">{{ 'informedConsent.step1' | translate }}</span>
        <span>{{
          'informedConsent.question.step1Description' | translate
        }}</span>
      </p>
      <div
        class="p-d-flex p-flex-column p-flex-md-row p-justify-between p-mb-6"
        style="gap: 1.5rem"
      >
        <div class="" style="width: 100%">
          <label for="questionDescription"
            >{{ 'informedConsent.question.questionDescription' | translate
            }}<span class="inputRequired"> * </span></label
          >
          <app-simple-dictionary-input
            id="txt_question_description"
            name="question_description"
            [isReadOnly]="isReadOnly"
            [isRequired]="true"
            [projectId]="projectId"
            [submitted]="questionForm.submitted"
            [value]="input.description"
            (outputEvent)="input.description = $event"
          ></app-simple-dictionary-input>
        </div>
        <div style="width: 100%">
          <label for="questionOrder">
            {{ 'informedConsent.question.questionOrder' | translate }}
            <span class="inputRequired">* </span>
          </label>
          <input
            name="questionOrder"
            #questionOrder="ngModel"
            id="questionOrder"
            required="true"
            [disabled]="isReadOnly"
            type="number"
            min="1"
            max="999"
            pInputText
            [(ngModel)]="input.order"
          />
          <small
            class="form-error"
            *ngIf="
              questionOrder.invalid &&
              (questionForm.submitted || questionOrder.touched)
            "
            >{{ 'general.required' | translate }}</small
          >
        </div>
      </div>
      <div class="p-mb-4">
        <app-informed-consent-answers
          [confICAnswers]="confICAnswers"
          (emitConfICAnswers)="
            confICAnswers = $event; validateConfICCorrectAnswers()
          "
          [projectId]="projectId"
          [isReadOnly]="isReadOnly"
        ></app-informed-consent-answers>
      </div>
      <div class="p-d-flex p-justify-end">
        <button
          [disabled]="
            questionForm.invalid ||
            confICAnswers.length === 0 ||
            !foundMaxOneCorrectAnswer
          "
          pButton
          type="submit"
          class="btn btn-info"
          icon="pi pi-check"
          label="{{ 'general.saveInformation' | translate }}"
        ></button>
      </div>
    </form>
  </div>
</div>
