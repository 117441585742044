import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ReportDynamicFormService } from '../../services/report-dynamic-form.service';
import { ReportExecutionService } from '../../services/report-executation.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  form: FormGroup;
  fields: FormlyFieldConfig[] = [];
  model: any = {};
  oldModel: any = {};
  options: FormlyFormOptions = { formState: { mainModel: this.model } };
  changeReason: string = '';

  constructor(
    private fb: FormBuilder,
    private reportDynamicFormService: ReportDynamicFormService,
    public reportExecutionService: ReportExecutionService
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit() {
    const formLoadingMessage = this.reportExecutionService.translateTransform(
      'report.execution.loadingForm'
    );
    this.reportExecutionService.showSpinner(formLoadingMessage);
    this.buildForm();
    this.reportExecutionService.hideSpinner();
  }

  buildForm() {
    const confForm = this.reportExecutionService.confReport.jsonForm ?? '[]';
    const jsonForm = this.reportExecutionService.dictionaryTransform(confForm);
    this.fields = JSON.parse(jsonForm);
    const json = this.reportExecutionService?.reportInstance?.json ?? '{}';
    const model = JSON.parse(json);
    this.form.patchValue(model);
    this.model = model;
    this.oldModel = structuredClone(this.model);
  }

  goToPreviousPage() {
    this.reportExecutionService.goToPreviousPage();
  }

  goToNextPage() {
    this.saveForm();
    this.reportExecutionService.goToNextPage();
  }

  saveForm() {
    if (this.shouldUpdateChangeReason()) this.updateChangeReason();
    this.saveUserInput();
    this.saveScore();
  }

  shouldUpdateChangeReason(): boolean {
    return (
      this.isFormChange && this.reportExecutionService.isReportInstanceEdition
    );
  }

  updateChangeReason(): void {
    this.reportExecutionService.reportInstance._changeReason =
      this.changeReason;
  }

  saveUserInput(): void {
    this.reportExecutionService.reportInstance.json = JSON.stringify(
      this.form.value
    );
  }

  saveScore(): void {
    const score = this.reportDynamicFormService.calculateScore(
      this.fields,
      this.model
    );

    this.reportExecutionService.reportInstance.score = score;
  }

  get isFormChange() {
    return JSON.stringify(this.form.value) !== JSON.stringify(this.oldModel);
  }
}
