<div class="container-tp2" *ngIf="isEdiaryLoaded">
  <form (ngSubmit)="onSubmit(f)" #f="ngForm" name="form">
    <div style="display: flex; justify-content: end" class="p-mb-3">
      <button
        pButton
        id="dictionaryButton"
        name="dictionaryButton"
        class="p-button-secondary"
        type="button"
        (click)="onDictionary(true)"
        label="{{ 'dictionary.labelButton' | translate }}"
      ></button>
      <button
        *ngIf="false"
        pButton
        label="{{ 'general.btnAuditTrace' | translate }}"
        type="button"
        icon="pi pi-file"
        iconPos="right"
        (click)="openAuditModal()"
        badgeClass="color: white;"
        class="p-ml-2 p-button-success pbutton"
      ></button>
    </div>
    <div class="p-grid p-fluid p-justify-end">
      <div class="p-col-12">
        <p-accordion>
          <p-accordionTab
            header="{{ translate.generalConfiguration }}"
            [disabled]="!hasDiaryCreatePermission"
          >
            <ng-template pTemplate="content">
              <p-card>
                <div class="p-grid">
                  <div class="p-col-12">
                    <h3>{{ translate.H3UserPermissions }}</h3>
                  </div>
                  <div class="p-col-12 p-md-6 p-grid p-field">
                    <div class="p-col-8 p-md-6">
                      <label for="gps">{{ translate.labelGPS }}</label>
                    </div>
                    <div class="p-col">
                      <p-inputSwitch
                        required
                        id="gps"
                        name="gps"
                        #gps="ngModel"
                        [(ngModel)]="ediaryConf.isGPSRequired"
                        pTooltip="{{ translate.labelGPSTooltip }}"
                      ></p-inputSwitch>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-grid p-field">
                    <div class="p-col-8 p-md-6">
                      <label for="ip">{{ translate.labelIP }}</label>
                    </div>
                    <div class="p-col">
                      <p-inputSwitch
                        required
                        id="ip"
                        name="ip"
                        #ip="ngModel"
                        [(ngModel)]="ediaryConf.isUserIPRequired"
                        pTooltip="{{ translate.labelIPTooltip }}"
                        tooltipPosition="top"
                      ></p-inputSwitch>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-grid p-field">
                    <div class="p-col-8 p-md-6">
                      <label for="labelDeviceData">{{
                        'report.labelDeviceData' | translate
                      }}</label>
                    </div>
                    <div class="p-col">
                      <p-inputSwitch
                        id="deviceData"
                        name="deviceData"
                        #ip="ngModel"
                        [(ngModel)]="ediaryConf.isDeviceDataRequired"
                        pTooltip="{{
                          'report.labelDeviceDataTooltip' | translate
                        }}"
                        tooltipPosition="top"
                      ></p-inputSwitch>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-grid p-field">
                    <div class="p-col-8 p-md-6">
                      <label for="signature">{{
                        translate.labelSignature
                      }}</label>
                    </div>
                    <div class="p-col">
                      <p-inputSwitch
                        required
                        id="signature"
                        name="signature"
                        #signature="ngModel"
                        [(ngModel)]="ediaryConf.isSignatureRequired"
                        pTooltip="{{ translate.labelSignatureTooltip }}"
                      ></p-inputSwitch>
                    </div>
                  </div>

                  <div class="p-col-12 p-md-6 p-grid p-field">
                    <div class="p-col-12">
                      <h3>{{ 'report.labelReminders' | translate }}</h3>
                    </div>
                    <div class="p-col-8 p-md-6">
                      <label for="gps">{{
                        'report.labelEnableLocalNotifications' | translate
                      }}</label>
                    </div>
                    <div class="p-col">
                      <p-inputSwitch
                        id="enableLocalNotifications"
                        name="enableLocalNotifications"
                        #enableLocalNotifications="ngModel"
                        [(ngModel)]="ediaryConf.enableLocalNotifications"
                      ></p-inputSwitch>
                    </div>
                  </div>

                  <div
                    class="p-col-12 p-md-6 p-field"
                    *ngIf="ediaryConf.enableLocalNotifications"
                  >
                    <label for="alertInBetweenDaysFrequency"
                      >{{ 'report.labelAlertDuringSameDayFrequency' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <p-inputNumber
                      [(ngModel)]="ediaryConf.alertDuringSameDayFrequency"
                      id="alertDuringSameDayFrequency"
                      name="alertDuringSameDayFrequency"
                      #alertDuringSameDayFrequency="ngModel"
                      [required]="ediaryConf.enableLocalNotifications"
                      [min]="1"
                      [max]="12"
                      tooltipPosition="top"
                      pTooltip="{{
                        'report.tooltipAlertDuringSameDayFrequency' | translate
                      }}"
                    ></p-inputNumber>
                    <small
                      class="form-error"
                      *ngIf="
                        !alertDuringSameDayFrequency.valid &&
                        (f.submitted || alertDuringSameDayFrequency.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <div
                    class="p-field p-col-12 p-md-6"
                    *ngIf="ediaryConf.enableLocalNotifications"
                  >
                    <label for="timeonly"
                      >{{ 'report.labelAlertStartTime' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <input
                      type="time"
                      pInputText
                      id="alertStartTime"
                      name="alertStartTime"
                      [required]="ediaryConf.enableLocalNotifications"
                      #alertStartTime="ngModel"
                      [(ngModel)]="ediaryConf.alertStartTime"
                      (ngModelChange)="valueTime()"
                      [value]="ediaryConf.alertStartTime"
                    />
                    <small
                      class="form-error"
                      *ngIf="
                        !alertStartTime.valid &&
                        (f.submitted || alertStartTime.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                  <div
                    class="p-field p-col-12 p-md-6"
                    *ngIf="ediaryConf.enableLocalNotifications"
                  >
                    <label for="timeonly"
                      >{{ 'report.labelAlertEndTime' | translate
                      }}<span class="inputRequired"> * </span></label
                    >
                    <input
                      type="time"
                      pInputText
                      id="alertEndTime"
                      name="alertEndTime"
                      [required]="ediaryConf.enableLocalNotifications"
                      #alertEndTime="ngModel"
                      [(ngModel)]="ediaryConf.alertEndTime"
                      (ngModelChange)="valueTime()"
                      [value]="ediaryConf.alertEndTime"
                    />
                    <small
                      class="form-error"
                      *ngIf="
                        !alertEndTime.valid &&
                        (f.submitted || alertEndTime.touched)
                      "
                      >{{ 'general.required' | translate }}</small
                    >
                  </div>
                </div>
              </p-card>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            header="{{ translate.requiredSymptoms }}"
            [disabled]="!hasSymptomPermission"
          >
            <ng-template pTemplate="content">
              <app-symptom-list
                [relatedEntity]="'EDIARY'"
                [relatedEntityId]="id"
                [projectId]="projectId"
                [projectState]="project.state"
                [dictionary]="dictionary"
              ></app-symptom-list>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            header="{{ translate.forms }}"
            [disabled]="!hasFormPermission"
          >
            <ng-template pTemplate="content">
              <app-form-list
                [confEdiaryId]="id"
                [projectId]="projectId"
                [dictionary]="dictionary"
                (confFormsUpdated)="confForms = $event"
              ></app-form-list>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab
            header="{{ translate.days }}"
            [disabled]="!hasDayPermission"
          >
            <ng-template pTemplate="content">
              <app-day-list
                [confEdiaryId]="id"
                [project]="project"
                [projectState]="project.state"
                [dictionary]="dictionary"
                (confDaysUpdated)="confDays = $event"
              ></app-day-list>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab header="{{ translate.conciliationConfiguration }}">
            <ng-template pTemplate="content">
              <app-conciliation-configuration
                [confEdiary]="confEdiary"
                [confForms]="confForms"
                [confDays]="confDays"
                (confEdiaryEmit)="updateConfEdiaryFromConciliation($event)"
              ></app-conciliation-configuration>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab header="{{ translate.alerts }}">
            <ng-template pTemplate="content">
              <app-alert-list
                [relatedEntity]="'EDIARY'"
                [relatedEntityId]="id"
                [projectId]="projectId"
                [dictionary]="dictionary"
              ></app-alert-list>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <div class="p-grid p-fluid p-mt-3">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-4">
        <div class="p-grid">
          <div class="p-col">
            <button
              pButton
              id="returnButton"
              class="p-button-secondary"
              type="button"
              (click)="goToProject()"
              label="{{ translateGeneral.return }}"
            ></button>
          </div>
          <div class="p-col" *ngIf="hasDiaryCreatePermission">
            <button
              pButton
              id="submitButton"
              type="submit"
              label="{{ translateGeneral.edit }}"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
