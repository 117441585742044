import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformedConsentService } from '../../informed-consent.service';
import {
  GetConfICSectionQuery,
  InformedConsentSectionType,
} from '../../informed-consent.types';
@Component({
  selector: 'app-informed-consent-section-detail',
  templateUrl: './informed-consent-section-detail.component.html',
  styleUrls: ['./informed-consent-section-detail.component.scss'],
})
export class InformedConsentSectionDetailComponent implements OnInit {
  confICSection = {} as GetConfICSectionQuery;
  sectionId: any = null;
  dialogContentModal: boolean = false;
  showQuestionList: boolean = false;
  projectId: string = '';
  confInformedConsentId: string = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly informedConsentService: InformedConsentService
  ) {}

  async ngOnInit() {
    const id = this.route.snapshot.params.sectionId;
    this.projectId = this.route.snapshot.params.projectId;
    this.confInformedConsentId =
      this.route.snapshot.params.confInformedConsentId;
    if (id) {
      this.confICSection =
        await this.informedConsentService.getConfICSectionById(id);
      const unAuthorizedQuestionList = [
        InformedConsentSectionType.OPTIONAL_CONSENT,
        InformedConsentSectionType.CONSENT,
        InformedConsentSectionType.CONSENTER_SIGNATURE,
      ];
      this.showQuestionList = !unAuthorizedQuestionList.includes(
        this.confICSection.type
      );
    }
  }
}
