<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="off">
  <p-fieldset
    legend="{{
      isEdition
        ? readMode
          ? translate.viewDay
          : translate.labelLegendEdit
        : translate.labelLegendAdd
    }}"
  >
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-6">
        <label for="dayName"
          >{{ translate.labelDayName
          }}<span class="inputRequired"> * </span></label
        >
        <div class="p-inputAutocompleteButton">
          <p-autoComplete
            class="p-flex-1"
            [ngStyle]="{ width: '100%' }"
            id="dayName"
            name="dayName"
            #dayName="ngModel"
            type="text"
            [(ngModel)]="confDay.dayName"
            [suggestions]="results"
            [required]="true"
            (completeMethod)="search($event)"
            (onSelect)="selectKeyLanguage($event)"
            [disabled]="readMode"
          >
          </p-autoComplete>
          <button
            id="dictionaryButtonReport"
            name="dictionaryButtonReport"
            type="button"
            pButton
            pRipple
            icon="pi pi-book"
            (click)="onDictionaty(true)"
            class="
              p-button-rounded
              p-button-secondary
              p-button-outlined
              p-ml-2
              p-ripple
              p-button
              p-component
              p-button-icon-only
            "
            title="{{ 'dictionary.labelButton' | translate }}"
            [disabled]="readMode"
          ></button>
        </div>
        <div class="p-d-flex p-flex-column" *ngIf="dayName.valid">
          <small *ngIf="isSpanish" [title]="isSpanish"
            ><label>ES:</label> {{ isSpanish | shortText: 110 }}</small
          >
          <small *ngIf="isEnglish" [title]="isEnglish"
            ><label>EN:</label> {{ isEnglish | shortText: 110 }}</small
          >
        </div>
        <small
          class="form-error"
          *ngIf="!dayName.valid && (f.submitted || dayName.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <div class="p-col-6 p-md-3">
        <label for="startDay"
          >{{ translate.labelStartDay
          }}<span class="inputRequired"> * </span></label
        >
        <p-inputNumber
          [required]="true"
          #startDay="ngModel"
          name="startDay"
          [(ngModel)]="confDay.startDay"
          [showButtons]="true"
          inputId="order"
          [min]="0"
          [max]="100"
          [disabled]="readMode"
        ></p-inputNumber>
        <small
          class="form-error"
          *ngIf="!startDay.valid && (f.submitted || startDay.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <div class="p-col-6 p-md-3">
        <label for="endDay"
          >{{ translate.labelEndDay
          }}<span class="inputRequired"> * </span></label
        >
        <p-inputNumber
          [required]="true"
          #endDay="ngModel"
          name="endDay"
          [(ngModel)]="confDay.endDay"
          [showButtons]="true"
          inputId="order"
          [min]="0"
          [max]="100"
          [disabled]="readMode"
        ></p-inputNumber>
        <small
          class="form-error"
          *ngIf="!endDay.valid && (f.submitted || endDay.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <div class="p-col-12 p-md-6">
        <label for="order"
          >{{ translate.labelOrder
          }}<span class="inputRequired"> * </span></label
        >
        <p-inputNumber
          [required]="true"
          #order="ngModel"
          name="order"
          [(ngModel)]="confDay.order"
          [showButtons]="true"
          inputId="order"
          [min]="1"
          [max]="100"
          [disabled]="readMode"
        ></p-inputNumber>
        <small
          class="form-error"
          *ngIf="!order.valid && (f.submitted || order.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <div class="p-col-12 p-md-6">
        <label for="form"
          >{{ translate.labelForm
          }}<span class="inputRequired"> * </span></label
        >
        <p-dropdown
          [required]="true"
          appendTo="body"
          placeholder="{{ translateGeneral.selectPlaceholder }}"
          [filter]="true"
          filterBy="name"
          [options]="formList"
          optionLabel="name"
          optionValue="value"
          name="form"
          #form="ngModel"
          [(ngModel)]="confDay.confFormId"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-dropdown>
        <small
          class="form-error"
          *ngIf="!form.valid && (f.submitted || form.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label for="groups"
          >{{ translateGeneral.groups
          }}<span class="inputRequired"> * </span></label
        >
        <p-multiSelect
          name="groups"
          #groups="ngModel"
          appendTo="body"
          [options]="projectGroups"
          [(ngModel)]="confDay.groups"
          (onChange)="changeGroup()"
          [required]="true"
          [maxSelectedLabels]="3"
          optionLabel="label"
          optionValue="value"
          [emptyFilterMessage]="translateGeneral.emptyFilterMessage"
          [disabled]="readMode"
        ></p-multiSelect>
        <small
          class="form-error"
          *ngIf="!groups.valid && (f.submitted || groups.touched)"
          >{{ translateGeneral.required }}</small
        >
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label for="phases"
          >{{ translateGeneral.phases
          }}<span class="inputRequired"> * </span></label
        >
        <p-multiSelect
          name="phases"
          appendTo="body"
          #phases="ngModel"
          [options]="projectPhases"
          [(ngModel)]="confDay.phases"
          [required]="true"
          optionLabel="label"
          optionValue="value"
          [maxSelectedLabels]="3"
          [emptyFilterMessage]="translateGeneral.emptyFilterMessage"
          [disabled]="readMode"
        ></p-multiSelect>
        <small
          class="form-error"
          *ngIf="!phases.valid && (f.submitted || phases.touched)"
          >{{ translateGeneral.required }}</small
        >
      </div>
      <div class="p-col-12 p-md-6 p-grid p-field">
        <div class="p-col-8 p-md-6">
          <label for="persistence">{{
            'ediary.components.dayAddEdit.labelTrackSymptomOcurrencies'
              | translate
          }}</label>
        </div>
        <div class="p-col">
          <p-inputSwitch
            required
            id="persistence"
            name="persistence"
            #persistence="ngModel"
            [(ngModel)]="confDay.trackSymptomOcurrencies"
            pTooltip="{{
              'ediary.components.dayAddEdit.tooltipTrackSymptomOcurrencies'
                | translate
            }}"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-grid p-field">
        <div class="p-col-8 p-md-6">
          <label for="hideColPDF">{{
            'ediary.components.dayAddEdit.labelHidePDFColumn' | translate
          }}</label>
        </div>
        <div class="p-col">
          <p-inputSwitch
            required
            id="hideColPDF"
            name="hideColPDF"
            #hideColPDF="ngModel"
            [(ngModel)]="confDay.hidePDFDayColumn"
            pTooltip="{{
              'ediary.components.dayAddEdit.tooltipHidePDFColumn' | translate
            }}"
            [disabled]="readMode"
          ></p-inputSwitch>
        </div>
      </div>
    </div>
    <div class="p-grid p-field p-fluid p-mt-3" *ngIf="!readMode">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-4">
        <div class="p-grid p-field">
          <div class="p-col">
            <button
              class="p-button-secondary"
              (click)="onCancel()"
              pButton
              type="button"
              label="{{ translateGeneral.cancel }}"
              [disabled]="readMode"
            ></button>
          </div>
          <div class="p-col">
            <button
              pButton
              type="submit"
              label="{{
                isEdition ? translateGeneral.edit : translateGeneral.add
              }}"
              [disabled]="readMode"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
</form>
