<div class="content p-mb-3">
  <label for="changeReason" class="p-mb-2" style="display: block"
    >{{ 'general.deleteReason' | translate
    }}<span class="inputRequired"> * </span></label
  >
  <textarea
    name="deleteReason"
    #deleteReason="ngModel"
    pInputTextarea
    [(ngModel)]="changeReasonDelete"
    required
    style="width: 100%"
  ></textarea>
  <small
    class="form-error p-col-12 p-pl-0"
    *ngIf="
      (deleteReason.invalid && deleteReason.touched) ||
      (deleteReason.invalid && formSubmited)
    "
  >
    {{ 'general.required' | translate }}</small
  >
</div>
<div class="footer p-d-flex" style="justify-content: flex-end">
  <button
    id="btn_cancel"
    name="btn_cancel"
    pButton
    type="button"
    class="p-button-danger"
    label="{{ 'general.cancel' | translate }}"
    (click)="cancel()"
  ></button>
  <button
    id="btn_"
    name="btn_addDay"
    class="p-button-secondary p-ml-3"
    pButton
    type="button"
    label="{{ 'general.confirm' | translate }}"
    (click)="confirm()"
  ></button>
</div>
