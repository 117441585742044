import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { InformedConsentFollowUpAssignExistingUsersComponent } from './informed-consent-follow-up-assign-existing-users/informed-consent-follow-up-assign-existing-users.component';
import { InformedConsentFollowUpDiagramComponent } from './informed-consent-follow-up-diagram/informed-consent-follow-up-diagram.component';
import { AddPersonComponent } from './informed-consent-follow-up-main/components/add-person/add-person.component';
import { ManagePdfVersionsComponent } from './informed-consent-follow-up-main/components/manage-pdf-versions/manage-pdf-versions.component';
import { SignatureEconsentComponent } from './informed-consent-follow-up-main/components/menu-actions/components/signature-econsent/signature-econsent.component';
import { MenuActionsComponent } from './informed-consent-follow-up-main/components/menu-actions/menu-actions.component';
import { ProgressCardComponent } from './informed-consent-follow-up-main/components/progress-card/progress-card.component';
import { UserDetailCardComponent } from './informed-consent-follow-up-main/components/user-detail-card/user-detail-card.component';
import { InformedConsentFollowUpMainComponent } from './informed-consent-follow-up-main/informed-consent-follow-up-main.component';
import { InformedConsentFollowUpRoutingModule } from './informed-consent-follow-up-routing.module';
import { InformedConsentFollowUpUserFormComponent } from './informed-consent-follow-up-user-form/informed-consent-follow-up-user-form.component';
import { InformedConsentFollowUpUsersTableComponent } from './informed-consent-follow-up-users-table/informed-consent-follow-up-users-table';

@NgModule({
  declarations: [
    InformedConsentFollowUpMainComponent,
    InformedConsentFollowUpDiagramComponent,
    InformedConsentFollowUpUsersTableComponent,
    InformedConsentFollowUpUserFormComponent,
    InformedConsentFollowUpAssignExistingUsersComponent,
    ProgressCardComponent,
    UserDetailCardComponent,
    MenuActionsComponent,
    AddPersonComponent,
    ManagePdfVersionsComponent,
    SignatureEconsentComponent,
  ],
  imports: [
    PrimengModule,
    FormsModule,
    SharedComponentsModule,
    InformedConsentFollowUpRoutingModule,
  ],
})
export class InformedConsentFollowUpModule {}
