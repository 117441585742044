import { Component } from '@angular/core';
import * as moment from 'moment-timezone';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { TrialpalService } from 'src/app/services/trialpal.service';
@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss'],
})
export class MaintenanceBannerComponent {
  startDate!: string;
  endDate!: string;
  isClosed: boolean = false;
  constructor(
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe
  ) {}
  ngOnInit(): void {
    this.trialpalService.maintenanceBannerInfo.subscribe(
      async (maintenanceInfo) => {
        const diff = moment()
          .tz(moment.parseZone(maintenanceInfo.startDate).format('Z'))
          .diff(moment(), 'minutes');

        //saca la fecha de inicio
        const tempStartDate = moment(maintenanceInfo.startDate).add(
          diff,
          'minutes'
        );

        this.startDate = this.transformDatePipe.transformWithHour(
          tempStartDate,
          true
        );

        const sameDate = this.isSameDate(maintenanceInfo);

        //saca la fecha de finalizacion
        const tempEndDate = moment(maintenanceInfo.endDate)
          .add(diff, 'minutes')
          .format(sameDate ? 'HH:mm A' : '');

        this.endDate = sameDate
          ? tempEndDate
          : this.transformDatePipe.transformWithHour(tempEndDate, true);
      }
    );
  }

  isSameDate(maintenanceInfo: any): boolean {
    return moment(maintenanceInfo.startDate.substring(0, 10)).isSame(
      maintenanceInfo.endDate,
      'days'
    );
  }
}
