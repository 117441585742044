export const SUBJECT_QUERIES = {
  SubjectsBySiteId: `query SubjectsBySiteId($siteId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectsBySiteId(siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  SubjectByProjectIdCustom: `query SubjectByProjectIdCustom($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            _lastUser
            _changeReason
            _version
            _deleted
            _lastChangedAt
            site {
              __typename
              name
            }
            currentScheduledPhase {
              __typename
              date
              phase
            }
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  SubjectSearchByProjectIdCustom: `query SubjectSearchByProjectIdCustom($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            subjectNumber
            state
            _deleted
          }
          nextToken
          startedAt
        }
      }`,
  GetSubject: `query GetSubject($id: ID!) {
        getSubject(id: $id) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  MedicationInstanceBySubjectIdCustom: `query MedicationInstanceBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicationInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicationInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            dayInstance {
              __typename
              confDay {
                __typename
                confFormId
                dayName
              }
            }
            id
            state
            rememberStartDate
            startDate
            isOnGoing
            rememberFinishDate
            finishDate
            name
            reason
            symptomsInstances
            givenTo
            dose
            frequency
            administrationRoute
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  MedicalAttentionInstanceBySubjectIdCustom: `query MedicalAttentionInstanceBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMedicalAttentionInstanceFilterInput, $limit: Int, $nextToken: String) {
        medicalAttentionInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            dayInstanceId
            dayInstance {
              __typename
              confDay {
                __typename
                confFormId
                dayName
              }
            }
            id
            state
            type
            reason
            symptomsInstances
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            wasHospitalized
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfMedicalAttentionByConfForm: `query ConfMedicalAttentionByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicalAttentionFilterInput, $limit: Int, $nextToken: String) {
        confMedicalAttentionByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            isBindToSymptomOcurrencies
            showRememberStartDate
            showRememberFinishDate
            showHospitalization
            mode
            showPrecondition
            showType
            medicalAttentionTypes
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfMedicationByConfForm: `query ConfMedicationByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfMedicationFilterInput, $limit: Int, $nextToken: String) {
        confMedicationByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            showPrecondition
            mode
            showRememberStartDate
            showRememberFinishDate
            showDose
            showFrequency
            showAdministrationRoute
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfTemperatureByConfForm: `query ConfTemperatureByConfForm($confFormId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfTemperatureFilterInput, $limit: Int, $nextToken: String) {
        confTemperatureByConfForm(confFormId: $confFormId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  MediaRecordLogBySubjectId: `query MediaRecordLogBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelMediaRecordLogFilterInput, $limit: Int, $nextToken: String) {
        mediaRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            reportInstanceId
            subjectId
            id
            state
            mediaType
            url
            comment
            dateTime
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  SymptomInstanceBySubjectId: `query SymptomInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        SymptomInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            confSymptom {
              symptomLabel
              isIntensityRequired
            }
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            dayInstance {
              completedDate
              eDiaryPhaseInstance {
                phase
              }
            }
            reportInstance {
              completeDate
              confReport {
                reportTitle
              }
            }
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  SymptomRecordLogBysymptomInstanceId: `query SymptomRecordLogBysymptomInstanceId($symptomInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSymptomRecordLogFilterInput, $limit: Int, $nextToken: String) {
        symptomRecordLogBysymptomInstanceId(symptomInstanceId: $symptomInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            symptomInstanceId
            state
            subjectId
            id
            intensity
            size
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  GetSymptomInstance: `query GetSymptomInstance($id: ID!) {
        getSymptomInstance(id: $id) {
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          reportInstanceId
          reportInstance {
            __typename
            confReportId
            subjectId
            siteId
            id
            state
            plannedDate
            completeDate
            json
            score
            gps
            appVersion
            userIP
            reportConfVersion
            isAlertChecked
            isPDFGenerated
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dayInstanceId
          dayInstance {
            __typename
            subjectId
            eDiaryPhaseInstanceId
            confDayId
            siteId
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            isAlertChecked
            isPDFGenerated
            comments
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CustomReportInstanceBySubjectId: `query CustomReportInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confReport {
              __typename
              projectId
              id
              groups
              phases
              instancePerDay
              reportTitle
              showHelp
              reportHelp
              reportIcon
              symptomPageLayout
              programationType
              plannedDayList
              isDisplayDependentOnTheEDiary
              forms
              isControlledBySite
              allowReportBackDating
              enableLocalNotifications
              futureNotifications
              alertType
              alertWeeklyDays
              alertInBetweenDaysFrequency
              alertDuringSameDayFrequency
              alertStartTime
              alertEndTime
              jsonForm
              isSignatureRequired
              isGPSRequired
              isUserIPRequired
              isMediaRequired
              isDeviceDataRequired
              mediaTypes
              isAssociatedToVisits
              areSymptomsRequired
              areAlertsRequired
              isDeviceDataManagementRequired
              availableUsers
              state
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subjectId
            subject {
              __typename
              projectId
              siteId
              id
              subjectNumber
              group
              state
              tag
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            plannedDate
            completeDate
            json
            score
            reportedBy {
              __typename
              username
              userType
            }
            gps
            appVersion
            userIP
            reportConfVersion
            symptomOcurrencies {
              __typename
              confSymptomId
              symptomInstanceId
              symptomRecordLogId
              occurrency
              order
            }
            isAlertChecked
            isPDFGenerated
            pdfFiles {
              __typename
              uploadDate
              fileUrl
            }
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  OnUpdateReportInstance: `subscription OnUpdateReportInstance {
        onUpdateReportInstance {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CreateSubject: `mutation CreateSubject($input: CreateSubjectInput!, $condition: ModelSubjectConditionInput) {
        createSubject(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CustomEDiaryInstanceBySubjectId: `query CustomEDiaryInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            confEDiaryId
            siteId
            subject {
              __typename
              projectId
            }
            pdfFiles {
              __typename
              fileUrl
              uploadDate
            }
            id
            state
            confEdiaryVersion
            comment
            alerts
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  CustomEDiaryPhaseInstanceBySubjectId: `query CustomEDiaryPhaseInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEDiaryPhaseInstanceFilterInput, $limit: Int, $nextToken: String) {
        eDiaryPhaseInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            subject {
              __typename
              projectId
              siteId
              id
              subjectNumber
              group
              state
              tag
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            eDiaryInstanceId
            eDiaryInstance {
              __typename
              subjectId
              confEDiaryId
              siteId
              id
              state
              confEdiaryVersion
              comment
              alerts
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            phase
            pdfFiles {
              __typename
              uploadDate
              fileUrl
            }
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            locked {
              __typename
              user
              date
            }
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  DayInstanceByEDiaryPhaseInstanceCustom: `query DayInstanceByEDiaryPhaseInstanceCustom($eDiaryPhaseInstanceId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDayInstanceFilterInput, $limit: Int, $nextToken: String) {
        dayInstanceByEDiaryPhaseInstance(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            subject {
              __typename
              subjectNumber
              id
              state
              projectId
              siteId
              _deleted
            }
            eDiaryPhaseInstanceId
            confDayId
            confDay {
              __typename
              dayName
              order
              confFormId
              startDay
              endDay
              hidePDFDayColumn
              _deleted
              confForm {
                __typename
                confEdiaryId
                projectId
                id
                name
                isTemperatureRequired
                isMedicationRequired
                isMedicalAttentionRequired
                isReconciliationRequired
                isMediaRequired
                areCommentsRequired
                isOtherForm
                mediaTypes
                areOtherSymptomsRequired
                symptomPageLayout
                showHelp
                formHelp
                _lastUser
                _changeReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            startDate
            finishDate
            completedDate
            userIP
            gps
            appVersion
            confDayVersion
            hasMedication
            medicationGivenTo
            hasMedicalAttention
            hasOtherSymptoms
            symptomOcurrencies {
              __typename
              confSymptomId
              symptomInstanceId
              symptomRecordLogId
              occurrency
              order
            }
            isAlertChecked
            isPDFGenerated
            comments
            _lastUser
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  UpdateSubject: `mutation UpdateSubject($input: UpdateSubjectInput!, $condition: ModelSubjectConditionInput) {
        updateSubject(input: $input, condition: $condition) {
          __typename
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          subjectNumber
          scheduledPhases {
            __typename
            phase
            date
            state
          }
          currentScheduledPhase {
            __typename
            phase
            date
            state
          }
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  SubjectBySubjectNumberAndSite: `query SubjectBySubjectNumberAndSite($subjectNumber: String!, $siteId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSubjectFilterInput, $limit: Int, $nextToken: String) {
        subjectBySubjectNumberAndSite(subjectNumber: $subjectNumber, siteId: $siteId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  TemperatureRecordLogBySubjectIdCustom: `query TemperatureRecordLogBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTemperatureRecordLogFilterInput, $limit: Int, $nextToken: String) {
        temperatureRecordLogBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            dayInstanceId
            dayInstance {
              __typename
              state
              startDate
              finishDate
              completedDate
              confDay {
                __typename
                confFormId
                dayName
                order
              }
              eDiaryPhaseInstance {
                __typename
                phase
              }
            }
            reportInstanceId
            subjectId
            id
            temperature
            temperatureUnit
            temperatureRoute
            temperatureWhichOtherRoute
            date
            temperatureTaken
            temperatureTakenDate
            state
            _lastUser
            _changeReason
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  GetDayInstance: `query GetDayInstance($id: ID!) {
        getDayInstance(id: $id) {
          __typename
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          eDiaryPhaseInstanceId
          eDiaryPhaseInstance {
            __typename
            subjectId
            eDiaryInstanceId
            siteId
            id
            state
            phase
            pdfState
            completedPhaseDate
            suspensionReason
            suspensionDate
            suspensionUser
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confDayId
          confDay {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            dayName
            order
            startDay
            endDay
            groups
            phases
            trackSymptomOcurrencies
            hidePDFDayColumn
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          startDate
          finishDate
          completedDate
          userIP
          gps
          appVersion
          confDayVersion
          hasMedication
          medicationGivenTo
          hasMedicalAttention
          hasOtherSymptoms
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          comments
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  AlertInstanceBySubjectIdCustom: `query AlertInstanceBySubjectIdCustom($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
        alertInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confAlertId
            confAlert {
              __typename
              confReportId
              confEdiaryId
              confSymptomId
              confTemperatureId
              id
              alertName
              recipients
              type
              mailSubject
              mailBody
              SMSBody
              alertRules {
                __typename
                ruleId
                alertRuleType
                expectedValue
              }
              _lastUser
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            id
            confAlert_data
            entityId
            entityName
            subjectId
            state
            revisionState
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  Tp2ManagePDFAndAlertLog: `query Tp2ManagePDFAndAlertLog($input: tp2ManagePDFAndAlertLogInput!) {
        tp2ManagePDFAndAlertLog(input: $input)
      }`,
  Tp2CreateEDiaryCardPDF: `query Tp2CreateEDiaryCardPDF($subject: TP2SubjectDiaryCard!, $version: String) {
        tp2CreateEDiaryCardPDF(subject: $subject, version: $version)
      }`,
  Tp2EDiarySuspension: `query Tp2EDiarySuspension($eDiaryPhaseInstanceId: String!, $suspensionReason: String, $suspensionDate: String!) {
        tp2EDiarySuspension(eDiaryPhaseInstanceId: $eDiaryPhaseInstanceId, suspensionReason: $suspensionReason, suspensionDate: $suspensionDate)
      }`,
  Tp2CreateEDiaryConciliationPDF: `query Tp2CreateEDiaryConciliationPDF($subject: TP2SubjectDiaryCard!, $version: String) {
        tp2CreateEDiaryConciliationPDF(subject: $subject, version: $version)
      }`,
  Tp2CreateReportCardPDF: `query Tp2CreateReportCardPDF($subjectId: String, $confReportId: String, $reportInstanceId: String, $version: String) {
        tp2CreateReportCardPDF(subjectId: $subjectId, confReportId: $confReportId, reportInstanceId: $reportInstanceId, version: $version)
      }`,
  ListSymptomInstances: `query ListSymptomInstances($filter: ModelSymptomInstanceFilterInput, $limit: Int, $nextToken: String) {
        listSymptomInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            subjectId
            reportInstanceId
            dayInstanceId
            confSymptomId
            id
            order
            state
            type
            symptom
            whichOtherSymptom
            intensity
            size
            rememberStartDate
            startDate
            rememberFinishDate
            finishDate
            _deviceModel
            _deviceSOVersion
            isOtherSymptom
            medications
            medicalAttentions
            professionalHealthCare
            hospitalAdmission
            createdAt
            rememberNoValuesTaken
            _lastUser
            _changeReason
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  GetConfAlert: `query GetConfAlert($id: ID!) {
        getConfAlert(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confSymptomId
          confSymptom {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensityType
            intensitySizeLabel
            isIntensityQuestionsRequired
            isIntensityRequired
            isStartDateRequired
            isFinishDateRequired
            showNoValuesTaken
            noValuesTakenLabel
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confTemperatureId
          confTemperature {
            __typename
            confEdiaryId
            confFormId
            projectId
            id
            helpText
            label
            dayRule
            routeOptions
            showFahrenheitUnit
            showTemperatureRoute
            ocurrencyValue
            ocurrencyUnit
            showTemperatureTaken
            showTemperatureTakenDate
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          alertName
          recipients
          siteRecipients {
            __typename
            siteId
            recipients
          }
          type
          mailSubject
          mailBody
          SMSBody
          alertRules {
            __typename
            ruleId
            alertRuleType
            expectedValue
            expectedUnit
          }
          triggerVisitCompletion
          triggerEDiarySuspension
          visitList {
            __typename
            order
            visitName
          }
          suspendVisitList {
            __typename
            order
            visitName
          }
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  GetConfSymptom: `query GetConfSymptom($id: ID!) {
        getConfSymptom(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          confEdiaryId
          confEdiary {
            __typename
            projectId
            isConciliationRequired
            isWindowsConciliationRequired
            id
            isSignatureRequired
            isPastEntryEnabled
            isGPSRequired
            isUserIPRequired
            isDeviceDataRequired
            enableLocalNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          project {
            __typename
            id
            state
            code
            name
            sponsor
            groups
            phases
            faultRecipients
            isEdiaryActivated
            isReportsActivated
            isICActivated
            isChatActivated
            isMFAActivated
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          type
          symptom
          order
          injectionSite
          injectionSide
          showHelp
          symptomHelp
          symptomLabel
          symptomIcon
          decimalPlaces
          minSize
          maxSize
          intensityType
          intensitySizeLabel
          noneIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lowIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          mediumIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          highIntensityScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          lifeThreateningScaleOptions {
            __typename
            active
            startValue
            endValue
            helpText
            label
          }
          intensityQuestions {
            __typename
            id
            order
            question
            intensity
          }
          isIntensityQuestionsRequired
          isIntensityRequired
          isStartDateRequired
          isFinishDateRequired
          showNoValuesTaken
          noValuesTakenLabel
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  CustomGetSymptomInstance: `query CustomGetSymptomInstance($id: ID!) {
        getSymptomInstance(id: $id) {
          __typename
          subjectId
          reportInstanceId
          dayInstanceId
          confSymptomId
          id
          order
          state
          type
          symptom
          whichOtherSymptom
          intensity
          size
          rememberStartDate
          startDate
          rememberFinishDate
          finishDate
          _deviceModel
          _deviceSOVersion
          isOtherSymptom
          medications
          medicalAttentions
          professionalHealthCare
          hospitalAdmission
          createdAt
          intensityQuestionAnswers {
            __typename
            answer
            question {
              __typename
              id
              intensity
              order
              question
            }
          }
          rememberNoValuesTaken
          _lastUser
          _changeReason
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  ConfSymptomByConfReportIdCustom: `query ConfSymptomByConfReportIdCustom($confReportId: ID!) {
        confSymptomByConfReportId(confReportId: $confReportId) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensitySizeLabel
            isFinishDateRequired
            isStartDateRequired
            isIntensityRequired
            intensityType
            showNoValuesTaken
            noValuesTakenLabel
            isIntensityQuestionsRequired
            intensityQuestions {
              __typename
              id
              order
              question
              intensity
            }
            highIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            mediumIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lowIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            noneIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lifeThreateningScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  Tp2ValidateAndCompleteTransaction: `query Tp2ValidateAndCompleteTransaction($input: tp2ValidateAndCompleteTransactionInput!) {
        tp2ValidateAndCompleteTransaction(input: $input)
      }`,
  GetReportInstance: `query GetReportInstance($id: ID!) {
        getReportInstance(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
};
