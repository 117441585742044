<app-button-refresh-data
  [showSpinner]="showSpinner"
  (buttonAction)="ngOnInit()"
></app-button-refresh-data>
<app-tour-guide-button
  [pageTourDataType]="DETAIL_SUBJECT_REPORTS_TOUR"
  *ngIf="!showSpinner && !currentIndexTabs.size"
></app-tour-guide-button>
<section *ngIf="!showSpinner" id="reportSubject_container">
  <div class="p-grid p-fluid p-justify-end" id="reportSubjectFilter_container">
    <div class="p-col-12 p-md-6 p-xl-4 p-field">
      <label for="groupsOptions">{{ 'report.stateReport' | translate }}</label>
      <p-dropdown
        ngDefaultControl
        [showClear]="true"
        class="input"
        id="groupsOptions"
        name="groups"
        [options]="options"
        [(ngModel)]="filter"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        (onChange)="filterMaster()"
        #grupos="ngModel"
        emptyMessage="{{ 'general.noResultsFound' | translate }}"
        emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
      >
      </p-dropdown>
    </div>

    <div class="p-col-12 p-md-6 p-xl-4 p-field">
      <label for="groupsTime">{{ 'report.period' | translate }}</label>
      <p-dropdown
        id="groupsTime"
        ngDefaultControl
        class="input"
        name="groups"
        [options]="times"
        [(ngModel)]="time"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        (onChange)="filterAndUpdateDateMaster($event, true)"
        #grupos="ngModel"
        emptyMessage="{{ 'general.noResultsFound' | translate }}"
        emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
      >
      </p-dropdown>
    </div>
  </div>
  <p-accordion
    [multiple]="true"
    (onOpen)="onTabOpen($event)"
    (onClose)="onTabClose($event)"
  >
    <p-accordionTab
      *ngFor="let item of confReportsByProject; let i = index"
      header="{{ item?.confReport?.reportTitle | dictionary }}"
      [id]="'reportSubjectSection_container' + i"
    >
      <app-tour-guide-button
        [pageTourDataType]="DETAIL_SUBJECT_REPORTS_DETAIL_TOUR"
        (pageTourButtonClicked)="startOnDemandTour(i)"
        *ngIf="item?.reportInstances?.length && currentIndexTabOpen === i"
      ></app-tour-guide-button>
      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="
          showInputSwitchControlledBySite(item.confReport.isControlledBySite) &&
          item?.confReportBySubject &&
          hasSubjectEnableDisableReportPermission
        "
      >
        <label for="enableControlledBySite">
          {{ 'report.labelReportEnabled' | translate }}
        </label>
        <div class="p-col">
          <p-inputSwitch
            required
            id="enableControlledBySite"
            name="enableControlledBySite"
            #enableControlledBySite="ngModel"
            [(ngModel)]="item.confReportBySubject.isEnabled"
            (onChange)="updateConfReportBySubject(item)"
          ></p-inputSwitch>
        </div>
      </div>
      <div
        id="btn_execute_report"
        *ngIf="
          showGeneralReportExecutionButton(item) &&
          hasSubjectExecuteUpdateReportPermissions
        "
      >
        <button
          class="p-button"
          pButton
          pRipple
          [label]="'report.executeReport' | translate"
          icon="pi pi-check"
          (click)="completeReport(item.confReport, item)"
        ></button>
      </div>
      <div class="p-grid p-fluid" *ngIf="item.dataCard.length === 0">
        <h4 style="width: 100%; text-align: center">
          {{ 'general.noResultsFound' | translate }}
        </h4>
      </div>

      <div *ngIf="item.reportInstances.length > 0">
        <app-button-table-card-mode
          dataSource="subjectReportsList"
          (cardOrTableModeEmit)="item.tableViewMode = $event"
        ></app-button-table-card-mode>

        <section *ngIf="!item.tableViewMode">
          <div class="p-grid p-fluid">
            <app-tpcard
              class="p-col-12 p-md-4 p-lg-3"
              *ngFor="let p of item.dataCard; let i = index"
              [tpCardData]="p"
              (customAction)="managePdfVersions(p)"
              (customAction2)="
                createReportCardPDFComplete(item.confReport.id, p.id)
              "
              (customAction3)="completeReport(item.confReport, p)"
              (detail)="openModal(p)"
              [id]="
                i === item.dataCard.length - 1 && item.dataCard.length > 1
                  ? 'reportSubjectCardConsolidate'
                  : 'reportSubjectCard' + i
              "
            ></app-tpcard>
          </div>
        </section>
        <section *ngIf="item.tableViewMode">
          <app-tptable
            [dataTable]="item.dataTable"
            (customAction1)="managePdfVersions($event.data)"
            (customAction2)="
              createReportCardPDFComplete(item.confReport.id, $event?.data?.id)
            "
            (customAction3)="completeReport(item.confReport, $event.data)"
            (openDetail)="openModal($event.data)"
            id="reportSubjectRow"
          ></app-tptable>
        </section>
      </div>
    </p-accordionTab>
  </p-accordion>
</section>

<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
