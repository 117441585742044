import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { InformedConsentService } from 'src/app/modules/informed-consent/informed-consent.service';
import { Roles } from 'src/app/shared/global.variables';
import { ProjectAndSiteInterface } from '../../../informed-consent-follow-up-user-form/informed-consent-follow-up-user-form.component';
import {
  InformedConsentState,
  UpdateInformedConsentInstanceInput,
} from 'src/app/modules/informed-consent/informed-consent.types';
import {
  ModelUserFilterInput,
  UserState,
} from 'src/app/modules/user/user.types';
const logger = new Logger('tp2-logger-informedConsentFollowUpUsersTable');

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.scss'],
})
export class AddPersonComponent implements OnChanges, OnInit {
  @Input() consentedUserMissings: number = 0;
  @Input() consentedUserMissingsToMax: number = 0;
  @Input() informedConsent: any;
  @Input() projectAndSite = {} as ProjectAndSiteInterface;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  confInformedConsent: any;
  confInformedConsentId = '';
  showDialog = false;
  avaiableUsers: any[] = [];
  assignedUsersKinships: any[] = [];
  consentedUsers: any[] = [];

  constructor(private informedConsentService: InformedConsentService) {}
  async ngOnInit() {
    logger.debug('consentedUsers', this.consentedUsers);
    this.consentedUsers = await this.getConsentedUsersBySite();
    this.updateRelationships();
  }

  async ngOnChanges() {
    logger.debug('informedConsentFromAddPerson', this.informedConsent);
    this.consentedUsers?.length > 0 && this.updateRelationships();
    logger.debug('consentedUsersFromAddPerson', this.consentedUsers);
  }

  updateRelationships() {
    const assignedUsersIds = this.informedConsent?.consentedUsers?.map(
      (user: any) => user.user.id
    );
    this.assignedUsersKinships = this.informedConsent?.consentedUsers?.map(
      (user: any) => user.kinship
    );
    logger.debug('assignedUsersIds', assignedUsersIds);
    logger.debug('assignedUsersKinships', this.assignedUsersKinships);

    //filtro de id de usuarios que ya están en el consentimiento
    this.avaiableUsers = this.consentedUsers?.filter(
      (user: any) => !assignedUsersIds?.includes(user.id)
    );
    logger.debug('avaiableUsers', this.avaiableUsers);
  }

  async onCloseModal(event: any): Promise<void> {
    logger.debug('onCloseModal', event);
    if (event) {
      await this.updateInformedConsentInstanceByAssignedState();
      if (this.informedConsent.consentedUsers) {
        this.informedConsent.consentedUsers.push(event);
      } else {
        this.informedConsent.consentedUsers = [event];
      }
      if (this.consentedUserMissings > 0) {
        this.consentedUserMissings = this.consentedUserMissings - 1;
        this.informedConsent.consentedUserMissings = this.consentedUserMissings;
      }
      this.refresh.emit(this.informedConsent);
      this.showDialog = false;
    }
  }

  async updateInformedConsentInstanceByAssignedState(): Promise<void> {
    if (this.informedConsent.state !== InformedConsentState.ASSIGNED) {
      const login = this.informedConsentService.auth.getUsername();
      const newInformedConsentState = InformedConsentState.ASSIGNED;
      const stateChanges = this.informedConsentService.getStateChanges(
        newInformedConsentState,
        this.informedConsent?.stateChanges
      );

      const input: UpdateInformedConsentInstanceInput = {
        id: this.informedConsent.id,
        state: newInformedConsentState,
        stateChanges: stateChanges,
        _changeReason: '',
        _lastUser: login,
        _version: this.informedConsent._version,
      };

      const informedConsentUpdated =
        await this.informedConsentService.updateInfomedConsentInstance(
          this.informedConsent,
          input
        );

      this.informedConsent = Object.assign(
        this.informedConsent,
        informedConsentUpdated
      );
    }
  }

  onAddUser() {
    this.updateRelationships();
    this.showDialog = true;
    this.informedConsentService.recordeConsentEvent(
      'site_consent_addperson_consent',
      {
        project: this.projectAndSite.projectId,
        site: this.projectAndSite.siteId,
      }
    );
  }

  async getConsentedUsersBySite() {
    const roleAndSiteFilter: ModelUserFilterInput = {
      role: {
        eq: Roles.Consented,
      },
      state: {
        ne: UserState.DISABLED,
      },
      sites: {
        contains: this.projectAndSite.siteId,
      },
    };
    return this.informedConsentService.listUsers(roleAndSiteFilter);
  }
}
