import { Component, OnInit } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent extends FieldWrapper implements OnInit {
  currentStep: number = 0;
  steps: any = [];

  ngOnInit(): void {
    this.steps = this.field.fieldGroup?.map((fieldGroup: any) => ({
      label: fieldGroup?.templateOptions?.label,
    }));
  }
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field?.formControl?.valid ?? false;
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
}
