import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  TPTableData,
  TypeInput,
} from 'src/app/shared/components/tptable/tpTableData';
import { TP2Entites } from 'src/app/shared/global.variables';
import { ProjectService } from '../../project/project.service';
import { AddEditRolesComponent } from '../add-edit-roles/add-edit-roles.component';
import { DetailRolesComponent } from '../detail-roles/detail-roles.component';
import { RolesService } from '../roles.service';
@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.scss'],
})
export class ListRolesComponent implements OnInit {
  roles: any[] = [];
  item: any = undefined;
  dataTable: TPTableData = {
    cols: [],
    valueTable: [],
    customButton1: null,
  };
  projectId: string = '';
  constructor(
    private rolesService: RolesService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private trialpalService: TrialpalService,
    public projectService: ProjectService,
    private confirmationService: ConfirmationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.trialpalService.showSpinner('roles.entity', 'LIST');
    this.projectId = this.route.snapshot.params.projectId;
    this.roles = await this.rolesService.getRoles(this.projectId);
    await this.buildTable();
    this.trialpalService.hideSpinner();
  }

  async buildTable() {
    this.dataTable = {
      cols: [],
      valueTable: [],
      customButton1: null,
    };
    if (this.roles && this.roles.length > 0) {
      this.dataTable = await this.buildTPTableData(this.roles);
    }
  }

  showRoleDetail(role: any) {
    const ref = this.dialogService.open(DetailRolesComponent, {
      width: '80%',
      closeOnEscape: false,
      dismissableMask: false,
      data: {
        role,
      },
    });

    this.trialpalService.ref = ref;
  }

  async buildTPTableData(roleInstances: any): Promise<TPTableData> {
    let newRoleInstances = await Promise.all(
      roleInstances.map(async (role: any) => {
        return {
          data: role,
          roleName: role.name,
          entity: TP2Entites.ROLE,
          PermissionNumber: role.permissions.length || 0,
          projects: await this.getProjectsByRole(role),
          isEnabled: this.getStateByRole(role),
          isForProjects: this.getIsforProjectsByRole(role),
        };
      })
    );
    let cols = [
      {
        header: this.trialpalService.translateService.instant(
          'roles.labelRoleName'
        ),
        type: TypeInput.TEXT,
        field: 'roleName',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'roles.NumberOfPermissions'
        ),
        type: TypeInput.TEXT,
        field: 'PermissionNumber',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header:
          this.trialpalService.translateService.instant('roles.labelState'),
        type: TypeInput.TEXT,
        field: 'isEnabled',
        showBasicSearch: true,
        showFilterComplete: false,
      },
      {
        header: this.trialpalService.translateService.instant(
          'roles.labelProjects'
        ),
        type: TypeInput.TEXT,
        field: 'projects',
        showBasicSearch: true,
        showFilterComplete: false,
      },
    ];
    return {
      valueTable: newRoleInstances,
      showGeneralSearch: true,
      showResetFilter: true,
      showMenuActionButton: true,
      globalFilterFields: [
        'roleName',
        'PermissionNumber',
        'isEnabled',
        'projects',
      ],
      cols,
      menuOptions: [
        {
          label: this.trialpalService.translateService.instant(
            'general.btnAuditTrace'
          ),
          icon: 'pi pi-file',
          command: () => {
            this.trialpalService.messageService.add({
              severity: 'info',
              summary: `Audit in construction ${this.item.roleName}`,
              detail: 'Audit in construction',
            });
          },
        },
        {
          label: this.trialpalService.translateService.instant('general.edit'),
          icon: 'pi pi-pencil',
          command: () => {
            this.createEdit(this.item);
          },
        },
        {
          label:
            this.trialpalService.translateService.instant('roles.queryUsers'),
          icon: 'pi pi-user-plus',
          command: () => {
            this.trialpalService.messageService.add({
              severity: 'info',
              summary: `List in construction ${this.item.roleName}`,
              detail: 'List users in construction',
            });
          },
        },
        {
          label:
            this.trialpalService.translateService.instant('roles.deleteRole'),
          icon: 'pi pi-trash',
          command: () => {
            this.deleteRole(this.item);
          },
        },
      ],
    };
  }

  getItemByTable(event: any) {
    this.item = event;
  }

  getIsforProjectsByRole(role: any) {
    if (role.isForProjects)
      return this.trialpalService.translateService.instant('roles.YES');
    return this.trialpalService.translateService.instant('roles.NO');
  }

  getStateByRole(role: any) {
    if (role.isEnabled)
      return this.trialpalService.translateService.instant('roles.active');
    return this.trialpalService.translateService.instant('roles.inactive');
  }

  async getProjectsByRole(role: any) {
    if (!role.isForProjects) return 'N/A';
    let projectsName = '';
    const projects = await this.projectService.getProjects();
    for (const projectId of role.projects) {
      const currentProject = projects.find((x: any) => x.id === projectId);
      if (currentProject)
        projectsName +=
          this.trialpalService.dictionaryPipe.transform(currentProject.name) +
          ', ';
    }
    return projectsName.slice(0, -2);
  }

  createEdit(item?: any): void {
    const isEdit = !!item;
    const ref = this.dialogService.open(AddEditRolesComponent, {
      header: isEdit
        ? this.trialpalService.translateService.instant('roles.h1EditRole')
        : this.trialpalService.translateService.instant('roles.h1CreateRole'),
      width: '90%',
      closeOnEscape: false,
      dismissableMask: false,
      data: {
        isEdit,
        id: item?.data?.id,
        isModal: true,
        projectId: this.projectId,
      },
    });
    this.trialpalService.ref = ref;
    ref.onClose.subscribe((result: any) => {
      if (result) {
        this.trialpalService.showSpinner('roles.entity', 'LIST');
        if (isEdit) {
          const index = this.roles.findIndex((x: any) => x.id === result.id);
          this.roles[index] = result;
        } else {
          this.trialpalService.showSpinner('roles.entity', 'LIST');
          this.roles.push(result);
        }
        this.buildTable();
        this.trialpalService.hideSpinner();
      }
    });
  }

  async deleteRole(role: any) {
    this.confirmationService.confirm({
      message:
        this.trialpalService.translateService.instant(
          'roles.roleConfirmationMessage'
        ) + role.roleName,
      accept: async () => {
        if (await this.checkAssociatedRoles(role.data.id)) {
          this.rolesService
            .deleteRolById(role.data.id, role.data._version)
            .then((role) => {
              if (role) {
                this.trialpalService.showMutationSuccess(
                  '',
                  '',
                  '',
                  this.trialpalService.translateService.instant(
                    'roles.roleDeletedSuccesfully'
                  )
                );

                this.ngOnInit();
              }
            });
        }
      },
    });
  }
  async checkAssociatedRoles(roleId: string): Promise<boolean> {
    this.trialpalService.showSpinner('roles.entity', 'DELETE');
    const users = await this.rolesService.getUsersByRole(roleId);
    const _users = users.filter((x: any) => !x._deleted);
    if (_users.length === 0) {
      return true;
    }
    this.trialpalService.hideSpinner();
    this.showViaService();
    return false;
  }

  showViaService() {
    this.trialpalService.messageService.clear();
    this.trialpalService.messageService.add({
      severity: 'error',
      summary: this.trialpalService.translateService.instant(
        'roles.errorDeletingRolesWithUser.summary'
      ),
      detail: this.trialpalService.translateService.instant(
        'roles.errorDeletingRolesWithUser.detail'
      ),
    });
  }

  forward(): void {
    if (this.projectId) {
      this.router.navigate(['project/' + this.projectId + '/detail']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
