<ng-container #fieldComponent></ng-container>

<small
  class="form-error p-d-block p-mt-2"
  *ngIf="formControl?.invalid && formControl.touched"
>
  <span>{{
    field?.validation?.messages?.required
      ? field?.validation?.messages?.required
      : ('general.required' | translate)
  }}</span>
</small>
