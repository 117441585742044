<div class="container-tp2 containerAll" id="container" *ngIf="!showSpinner">
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12">
      <div class="p-grid p-fluid p-ai-center">
        <button
          pButton
          type="button"
          icon="pi pi-arrow-left"
          id="btn_forward"
          class="
            p-button-rounded
            p-button-secondary
            p-button-outlined
            p-button
            p-button-icon-only
          "
          (click)="backward()"
        ></button>
        <div class="p-col-10">
          <h1>{{ 'general.alerts.labelAlertsGenerated' | translate }}</h1>
        </div>
      </div>
      <div class="p-grid p-fluid p-d-flex p-flex-column p-ai-start">
        <label class="range p-col-6 p-md-4">{{
          'general.alerts.queryRange' | translate
        }}</label>
        <div class="p-col-6 p-md-3">
          <p-dropdown
            [showClear]="true"
            id="grupos"
            class="input"
            placeholder="{{ 'general.choose' | translate }}"
            name="grupos"
            [(ngModel)]="selectedRange"
            [options]="ranges"
            (onChange)="filter()"
            optionLabel="name"
            optionValue="value"
            appendTo="body"
            (onClear)="clearFilter()"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="p-datatable-wrapper" id="alerts-by-email">
    <app-tptable
      [dataTable]="dataTable"
      (openDetail)="showDetail($event)"
      (customAction1)="changeState($event)"
    ></app-tptable>
  </div>
</div>

<app-alert-subject
  #alertSubject
  [subjectIdParam]="selectedAlert.subject.id"
  *ngIf="display"
></app-alert-subject>

<p-dialog
  header=" "
  [modal]="true"
  [(visible)]="displayDialog"
  [style]="{ width: '45rem', height: '30rem' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  *ngIf="displayDialog"
>
  <form name="form" #f="ngForm" autocomplete="off" (ngSubmit)="sendNote(f)">
    <p-fieldset
      legend="
{{ 'general.alerts.labelAlertsReviewing' | translate }}
"
    >
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
          <p>
            <label>
              {{
                'general.alerts.labelChangeRevisionState' | translate
              }}:</label
            >
          </p>
        </div>
        <div class="p-col-12 p-md-6">
          <p-dropdown
            id="revisionState"
            name="revisionState"
            class="p-mt-2"
            [options]="stateOptions"
            [(ngModel)]="revisionStateValue"
            placeholder="{{ 'general.selectPlaceholder' | translate }}"
            [showClear]="true"
            [required]="true"
            [virtualScroll]="true"
            (onChange)="selectedAlert.revisionState = $event.value"
            #revisionState="ngModel"
          ></p-dropdown>
          <small class="form-error" *ngIf="revisionState?.invalid">
            {{ 'general.required' | translate }}
          </small>
        </div>
        <div class="p-col-12 p-d-flex p-justify-end p-mt-3 p-px-0">
          <div class="p-col-3">
            <button
              class="p-button-outlined p-button-secondary"
              pButton
              label="{{ 'project.buttonCancel' | translate }}"
              type="button"
              (click)="onCancel()"
            ></button>
          </div>
          <div class="p-col-3">
            <button
              id="btnSend"
              pButton
              label="{{ 'general.save' | translate }}"
              type="submit"
            ></button>
          </div>
        </div>
      </div>
    </p-fieldset>
  </form>
</p-dialog>
<app-not-found-items
  [description]="'general.alerts.emptySelection' | translate"
  [buttonDescription]="'general.alerts.emptySelectionDescription' | translate"
  [displayButton]="false"
  *ngIf="dataTable.valueTable.length === 0 && !showSpinner"
></app-not-found-items>
