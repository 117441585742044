<form *ngIf="medicalAttentionService.isNewMedicalAttention">
  <label
    id="lbl_labelVerifiedWhichOtherSymptom"
    name="labelVerifiedWhichOtherSymptom"
    for="inputVerifiedWhichOtherSymptom"
  >
    {{ 'medicalattentioninstance.newMedicalAttention' | translate }}
  </label>
  <p-dropdown
    id="inputVerifiedIntensityOther"
    name="drop_intensity"
    optionLabel="label"
    optionValue="value"
    class="input p-col-12 p-p-0 p-mr-1"
    appendTo="body"
    [options]="dayInstances"
    [(ngModel)]="dayInstanceSelected"
    (onChange)="newMedicalAttention()"
    placeholder="{{ 'general.selectPlaceholder' | translate }}"
    emptyMessage="{{ 'general.emptyFilterMessage' | translate }}"
    emptyFilterMessage="{{ 'general.emptyFilterMessage' | translate }}"
  >
  </p-dropdown>
</form>

<form
  name="form"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="!medicalAttentionService.isNewMedicalAttention"
>
  <div class="scroll-conciliation" *ngIf="!isDelete">
    <div
      *ngIf="confMedicalAttention?.showType && !shouldHideTypeField()"
      class="p-grid p-ai-center p-py-0 p-mb-3"
    >
      <label id="lbl_route" name="lbl_route" for="type"
        >{{ 'medicalattentioninstance.type' | translate }}
        <span class="inputRequired"> * </span></label
      >
      <p-dropdown
        id="type"
        class="input p-col p-py-0"
        name="drop_scaleType"
        placeholder="{{ 'general.selectPlaceholder' | translate }}"
        optionLabel="name"
        optionValue="value"
        [options]="optionsMedicalAttention"
        formControlName="formType"
        appendTo="body"
        type="text"
        [ngClass]="{
          disabled: shouldDisableTypeField(),
        }"
        [required]="confMedicalAttention?.showType || false"
        (onChange)="onChangeType($event)"
      >
      </p-dropdown>
      <small
        class="form-error p-col-12"
        *ngIf="formType?.invalid && formType?.touched"
        >{{ 'general.required' | translate }}</small
      >
      <div
        *ngIf="requiredTypeComment"
        class="p-grid p-ai-center p-col-12 p-py-0 p-mb-3"
      >
        <label
          id="lbl_observationType"
          name="lbl_observationType"
          class="p-col-12"
          for="txt_verifiedType"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <textarea
          id="txt_formTypeComment"
          name="txt_formTypeComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          formControlName="formTypeComment"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          [required]="requiredTypeComment"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="formTypeComment?.invalid && formTypeComment?.touched"
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div>
      <div
        class="p-grid p-col-12 p-py-0 p-mb-3"
        *ngIf="
          confMedicalAttention?.mode &&
          confMedicalAttention?.mode !== 'NO_REASON' &&
          (!shouldHideOpenReasonField() ||
            !shouldHideSymptomOccurencyReasonTypeField())
        "
      >
        <label id="lbl_reason" name="lbl_reason" for="txt_reason"
          >{{ 'conciliation.medicalattentioninstance.reason' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <input
          *ngIf="
            confMedicalAttention?.mode === 'OPEN_REASON' &&
            !shouldHideOpenReasonField()
          "
          id="txt_reason"
          name="txt_reason"
          type="text"
          placeholder="{{ 'conciliation.placeholderReason' | translate }}"
          formControlName="formReason"
          class="full-width"
          [ngClass]="{
            disabled: shouldDisableOpenReasonField(),
          }"
          pInputText
        />
        <small
          class="form-error p-col-12"
          *ngIf="
            formReason?.invalid &&
            confMedicalAttention?.mode === 'OPEN_REASON' &&
            formReason?.touched
          "
          >{{ 'general.required' | translate }}</small
        >

        <p-multiSelect
          *ngIf="
            confMedicalAttention?.mode === 'SYMPTOM_BINDING' &&
            !shouldHideSymptomOccurencyReasonTypeField()
          "
          appendTo="body"
          placeholder="{{ 'general.selectPlaceholder' | translate }}"
          class="full-width"
          id="sel_symtomps"
          formControlName="formSymptomsInstances"
          [options]="filteredSymptoms"
          optionLabel="symptomLabel"
          optionValue="id"
          (onChange)="changeSymptom($event)"
          [maxSelectedLabels]="1"
          selectedItemsLabel="{1}
          {{ 'conciliation.labelSelectedSubjects' | translate }}
        "
          [required]="confMedicalAttention?.mode === 'SYMPTOM_BINDING'"
          [ngClass]="{
            disabled: shouldDisableSymptomOccurencyReasonField(),
          }"
        >
        </p-multiSelect>
        <small
          class="form-error p-col-12"
          *ngIf="
            formSymptomsInstances?.invalid &&
            formSymptomsInstances?.touched &&
            confMedicalAttention?.mode === 'SYMPTOM_BINDING'
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-ai-center p-col-12 p-py-0 p-mb-3"
        *ngIf="
          confMedicalAttention?.mode === 'OPEN_REASON' &&
          changeOpenReason &&
          !shouldHideOpenReasonField() &&
          !shouldDisableOpenReasonField()
        "
      >
        <label
          id="lbl_reasonComment"
          name="lbl_reasonComment"
          class="p-col-12"
          for="txt_verifiedType"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <textarea
          id="txt_formReasonComment"
          name="txt_formReasonComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          formControlName="formReasonComment"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            formReasonComment?.invalid &&
            confMedicalAttention?.mode === 'OPEN_REASON' &&
            formReasonComment?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div
        class="p-grid p-ai-center p-col-12 p-py-0 p-mb-3"
        *ngIf="
          confMedicalAttention?.mode === 'SYMPTOM_BINDING' &&
          requiredSymptomComment &&
          !shouldHideSymptomOccurencyReasonTypeField() &&
          !shouldDisableSymptomOccurencyReasonField()
        "
      >
        <label
          id="lbl_reasonComment"
          name="lbl_reasonComment"
          class="p-col-12"
          for="txt_formSymptomsInstancesComment"
          >{{ 'conciliation.titleObservation' | translate }}
          <span class="inputRequired"> * </span></label
        >
        <textarea
          id="txt_formSymptomsInstancesComment"
          name="txt_formSymptomsInstancesComment"
          class="p-col"
          pInputTextarea
          type="text"
          [autoResize]="true"
          formControlName="formSymptomsInstancesComment"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          [required]="requiredSymptomComment"
        ></textarea>
        <small
          class="form-error p-col-12"
          *ngIf="
            formSymptomsInstancesComment?.invalid &&
            formSymptomsInstancesComment?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      *ngIf="
        confMedicalAttention?.showHospitalization &&
        !shouldHideHospitalizationField()
      "
      class="p-grid p-py-0"
    >
      <div class="full-width p-mb-3">
        <label
          id="lbl_wasHospitalized"
          name="lbl_wasHospitalized"
          for="lbl_observationWasHospitalized"
        >
          {{ 'medicalattentioninstance.wasHospitalized' | translate }}
          <span class="inputRequired"> * </span>
        </label>
        <p-selectButton
          id="wasHospitalized"
          name="wasHospitalized"
          formControlName="formWasHospitalized"
          (onChange)="onChangeWasHospitalized($event)"
          [options]="booleanOptions"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          [ngClass]="{
            disabled: shouldDisableHospitalizationField(),
          }"
        ></p-selectButton>
      </div>
      <div
        *ngIf="requiredWasHospitalizedComment"
        class="p-grid p-ai-center p-col-12 p-py-0 p-mb-3"
      >
        <label
          id="lbl_observationWasHospitalized"
          name="lbl_observationWasHospitalized"
          for="txt_observationWasHospitalized"
          >{{ 'conciliation.titleObservation' | translate }}
        </label>
        <textarea
          id="txt_observationWasHospitalized"
          name="txt_observationWasHospitalized"
          class="full-width"
          pInputTextarea
          type="text"
          [autoResize]="true"
          formControlName="formWasHospitalizedComment"
          placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
          [required]="requiredWasHospitalizedComment"
        ></textarea>
        <small
          class="form-error"
          *ngIf="
            formWasHospitalizedComment?.invalid &&
            formWasHospitalizedComment?.touched
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
    </div>
    <div
      *ngIf="
        confMedicalAttention?.showRememberStartDate && !shouldHideDateFields()
      "
    >
      <div class="p-ai-center">
        <div class="p-mb-3 full-width">
          <label id="lbl_startDate" name="lbl_startDate" for="dateTaken">
            {{ 'symptominstance.startDateQuestion' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-selectButton
            id="dateStart"
            name="dateStart"
            formControlName="formRememberStartDate"
            (onChange)="onChangeRememberStartDate($event)"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            [ngClass]="{
              disabled: shouldDisableDateFields(),
            }"
          ></p-selectButton>
        </div>
      </div>

      <div
        class="p-grid p-ai-center"
        *ngIf="formRememberStartDate?.value && !shouldHideDateFields()"
      >
        <div class="p-mb-3 full-width">
          <label
            id="lbl_temperatureDate"
            name="lbl_temperatureDate"
            for="cal_startDate"
          >
            {{ 'symptominstance.startDate' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <div class="p-col">
            <p-calendar
              name="cal_startDate"
              id="cal_startDate"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              [showIcon]="true"
              appendTo="body"
              formControlName="formStartDate"
              placeholder="{{
                'addEditSubjectComponent.PlaceHolderFormatDate' | translate
              }}"
              dateFormat="dd-M-yy"
              (onSelect)="selectStartDate($event)"
              [required]="requiredStartDate"
              [maxDate]="maxDate"
              [ngClass]="{
                disabled: shouldDisableDateFields(),
              }"
            ></p-calendar>
            <small
              class="form-error"
              *ngIf="formStartDate?.invalid && formStartDate?.touched"
              >{{ 'general.required' | translate }}</small
            >
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="requiredStartDateComment && !shouldHideDateFields()"
      class="p-grid p-ai-center p-col-12 p-py-0 p-mb-3"
    >
      <label
        id="lbl_observationUnit"
        name="lbl_observationUnit"
        for="txt_observationStartDate"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span></label
      >
      <textarea
        id="txt_observationStartDate"
        name="txt_observationStartDate"
        class="p-col"
        pInputTextarea
        type="text"
        [autoResize]="true"
        formControlName="formStartDateComment"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="formStartDateComment?.invalid && formStartDateComment?.touched"
        >{{ 'general.required' | translate }}</small
      >
    </div>
    <div
      *ngIf="
        confMedicalAttention?.showRememberFinishDate && !shouldHideDateFields()
      "
    >
      <div class="p-ai-center">
        <div class="p-mb-3 full-width">
          <label id="lbl_finishDate" name="lbl_finishDate" for="dateTaken">
            {{ 'symptominstance.endDateQuestion' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <p-selectButton
            id="finishDate"
            name="finishDate"
            formControlName="formRememberFinishDate"
            (onChange)="onChangeRememberFinishDate($event)"
            [options]="booleanOptions"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            [ngClass]="{
              disabled: shouldDisableDateFields(),
            }"
          ></p-selectButton>
        </div>
      </div>

      <div
        class="p-grid p-ai-center"
        *ngIf="formRememberFinishDate?.value && !shouldHideDateFields()"
      >
        <div class="p-mb-3 full-width">
          <label
            id="lbl_temperatureDate"
            name="lbl_temperatureDate"
            for="cal_finishDate"
          >
            {{ 'symptominstance.endDate' | translate }}
            <span class="inputRequired"> * </span>
          </label>
          <div class="p-col">
            <p-calendar
              name="cal_finishDate"
              id="cal_finishDate"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              [showIcon]="true"
              appendTo="body"
              formControlName="formFinishDate"
              placeholder="{{
                'addEditSubjectComponent.PlaceHolderFormatDate' | translate
              }}"
              dateFormat="dd-M-yy"
              [required]="requiredFinishDate"
              (onSelect)="selectFinishDate($event)"
              [maxDate]="maxDate"
              [ngClass]="{
                disabled: shouldDisableDateFields(),
              }"
            ></p-calendar>
            <small
              class="form-error"
              *ngIf="formFinishDate?.invalid && formFinishDate?.touched"
              >{{ 'general.required' | translate }}</small
            >
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="requiredFinishDateComment && !shouldHideDateFields()"
      class="p-grid p-ai-center p-col-12"
    >
      <label
        id="lbl_observationUnit"
        name="lbl_observationUnit"
        for="txt_observationFinishDate"
        >{{ 'conciliation.titleObservation' | translate }}
        <span class="inputRequired"> * </span></label
      >
      <textarea
        id="txt_observationFinishDate"
        name="txt_observationFinishDate"
        class="p-col"
        pInputTextarea
        type="text"
        [autoResize]="true"
        formControlName="formFinishDateComment"
        placeholder="{{ 'conciliation.placeholderObservation' | translate }}"
        [required]="requiredFinishDateComment"
        [ngClass]="{
          disabled: shouldDisableDateFields(),
        }"
      ></textarea>
      <small
        class="form-error p-col-12"
        *ngIf="formFinishDateComment?.invalid && formFinishDateComment?.touched"
        >{{ 'general.required' | translate }}</small
      >
    </div>
  </div>
  <h4 class="notResult" *ngIf="isDelete">
    {{ 'general.recordDeleted' | translate }}
  </h4>
  <div
    *ngIf="currentMedicalAttentionDay?.isEdition && isMedicalAttentionChange"
  >
    <label
      id="lbl_changeReason"
      name="lbl_changeReason"
      for="txt_changeReason"
      >{{ 'audit.label' | translate }}</label
    >
    <change-reason
      name="other_symptom_reason"
      [label]="'conciliation.changeReason' | translate"
      formControlName="_changeReason"
      [required]="true"
    ></change-reason>
    <small
      class="form-error p-col-12"
      *ngIf="
        form.controls['_changeReason']?.invalid &&
        form.controls['_changeReason']?.touched
      "
      >{{ 'general.required' | translate }}</small
    >
  </div>

  <app-form-buttons
    (nextData)="isNext = $event"
    (previousData)="previousDay()"
  ></app-form-buttons>
</form>
