import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-radio-image',
  templateUrl: './formly-radio-image.component.html',
  styleUrls: ['./formly-radio-image.component.scss'],
})
export class FormlyRadioImageComponent extends FieldType<FieldTypeConfig> {
  uniqueId: number = Math.floor(Math.random() * Date.now());
}
