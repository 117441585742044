<div class="tp2Spinner">
  <p-dialog
    [transitionOptions]="'5ms'"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [(visible)]="blockedUI"
    [baseZIndex]="9000"
  >
    <div class="spinner">
      <div class="img-spinner">
        <svg viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M70 38C70 42.2023 69.1723 46.3634 67.5641 50.2459C65.956 54.1283 63.5989 57.6559 60.6274 60.6274C57.6559 63.5989 54.1283 65.956 50.2459 67.5641C46.3634 69.1723 42.2023 70 38 70C33.7977 70 29.6365 69.1723 25.7541 67.5641C21.8717 65.956 18.3441 63.5989 15.3726 60.6274C12.4011 57.6559 10.044 54.1283 8.43585 50.2459C6.8277 46.3634 6 42.2023 6 38C6 33.7977 6.82771 29.6365 8.43586 25.7541C10.044 21.8717 12.4011 18.3441 15.3726 15.3726C18.3441 12.4011 21.8717 10.044 25.7541 8.43585C29.6366 6.8277 33.7977 6 38 6C42.2023 6 46.3635 6.82771 50.2459 8.43586C54.1283 10.044 57.6559 12.4011 60.6274 15.3726C63.5989 18.3441 65.956 21.8717 67.5641 25.7541C69.1723 29.6366 70 33.7977 70 38L70 38Z"
            stroke="#4ADFE6"
            stroke-width="10.6667"
          />
          <path
            d="M70 38C70 44.7577 67.8606 51.342 63.8885 56.8091C59.9164 62.2762 54.3155 66.3456 47.8885 68.4338C41.4616 70.5221 34.5384 70.5221 28.1115 68.4338C21.6845 66.3455 16.0836 62.2762 12.1115 56.8091"
            stroke="#2B979C"
            stroke-width="10.6667"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <span> {{ blockedUIText }} </span>
    </div>
  </p-dialog>
</div>
<p-toast
  id="confMessageElem"
  position="top-center"
  closable="true"
  life="8000"
></p-toast>

<p-toast
  id="chatToast"
  position="top-right"
  closable="true"
  life="900000"
  key="chatToast"
  ><ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-right">
        <em class="pi pi-comments" style="font-size: 1.5rem"></em>
      </div>
      <div class="p-text-left">
        <strong>{{ message.summary }}</strong>
        {{ message.detail }}
      </div>
      <div class="p-text-right">
        <a routerLink="/chat/chatRoom/{{ message.data }}">{{
          'general.gotoChat' | translate
        }}</a>
      </div>
    </div>
  </ng-template></p-toast
>
<div class="central-conatiner">
  <app-navbar *ngIf="userLogin$ | async"></app-navbar>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
<p-confirmDialog
  header="{{ 'general.headerConfirm' | translate }}"
  icon="pi pi-exclamation-triangle"
  acceptLabel="{{ 'general.confirm' | translate }}"
  rejectLabel="{{ 'general.cancel' | translate }}"
  [closable]="getClosableValue()"
  acceptButtonStyleClass="p-button-secondary"
>
</p-confirmDialog>
<div *ngIf="envImage !== ''">
  <img src="{{ envImage }}" alt="{{ envImage }}" class="envTag" />
</div>

<div class="tp2LastRouteModal">
  <p-dialog
    [header]="'general.lastRoute' | translate"
    [(visible)]="showLastRouteModal"
    [draggable]="false"
    position="top-right"
    [style]="{ width: '300px' }"
  >
    <p-button
      [label]="'general.accept' | translate"
      (click)="goToLastRoute()"
      styleClass="p-button-primary"
    ></p-button>
  </p-dialog>
</div>
