import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'aws-amplify';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { SitesService } from '../../sites/sites.service';
import { InformedConsentService } from '../informed-consent.service';
import {
  ConfICSection,
  GetConfInformedConsentQuery,
  QuizLayout,
  UpdateConfInformedConsentInput,
} from '../informed-consent.types';
const logger = new Logger('Detail consent');
@Component({
  selector: 'app-informed-consent-detail',
  templateUrl: './informed-consent-detail.component.html',
  styleUrls: ['./informed-consent-detail.component.scss'],
})
export class InformedConsentDetailComponent implements OnInit {
  confInformedConsent = {} as GetConfInformedConsentQuery;
  projectId: string = '';
  confInformedConsentId: string = '';
  sites: string = '';
  confICSections: ConfICSection[] = [];
  quices: any[] = [];
  quizLayout: QuizLayout | null | undefined = null;

  constructor(
    private readonly activateRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly informedConsentService: InformedConsentService,
    private readonly sitesService: SitesService,
    private readonly trialpalService: TrialpalService
  ) {}

  async ngOnInit() {
    try {
      this.showSpinner();
      this.getRouteParams();
      if (this.confInformedConsentId) {
        await this.getConfInformedConsent(this.confInformedConsentId);
        await this.getSitesName(this.confInformedConsent.sites);
        await this.getConfICSections(this.confInformedConsentId);
        this.quizLayout = this.confInformedConsent.quizPageLayout;
        this.setQuicesValues();
      }
      this.trialpalService.hideSpinner();
    } catch (error) {
      logger.error('ngOnInit error', error);
      this.trialpalService.hideSpinner();
    }
  }

  showSpinner() {
    this.trialpalService.showSpinner(
      'informedConsent.sections.sections',
      'LIST'
    );
  }

  getRouteParams() {
    this.confInformedConsentId =
      this.activateRoute.snapshot.params.confInformedConsentId;
    this.projectId = this.activateRoute.snapshot.params.projectId;
  }

  async getConfInformedConsent(confInformedConsentId: any): Promise<void> {
    this.confInformedConsent =
      await this.informedConsentService.getConfInformedConsentById(
        confInformedConsentId
      );
  }

  async getSitesName(InformedConsentSites: any): Promise<void> {
    const siteList: any[] = [];
    const sites = await this.sitesService.getSitesByProject(this.projectId);

    InformedConsentSites?.forEach((consentSite: any) => {
      const { name } = sites.find((site: any) => site.id === consentSite.site);
      siteList.push(name);
    });

    this.sites = siteList.join(', ');
  }

  async getConfICSections(confInformedConsentId: string): Promise<void> {
    if (confInformedConsentId) {
      const confICSections: ConfICSection[] =
        await this.informedConsentService.getConfICSectionsByConfInformedConsentId(
          confInformedConsentId
        );

      confICSections.sort(
        (confICSectionCurrent: any, confICSectionNext: any) =>
          confICSectionCurrent.order - confICSectionNext.order
      );
      this.confICSections = confICSections;
    }
  }

  setQuicesValues() {
    this.quices = [];
    Object.values(QuizLayout).forEach((quiz: any) => {
      this.quices.push({
        value: quiz,
        label: this.trialpalService.translateService.instant(
          `informedConsent.orderQuiz.${quiz}`
        ),
      });
    });
    this.quices.sort((a, b) => a.label.localeCompare(b.label));
  }

  async saveQuizLayout(): Promise<void> {
    const input: UpdateConfInformedConsentInput = {
      quizPageLayout: this.quizLayout,
      _version: this.confInformedConsent._version,
      id: this.confInformedConsent.id,
    };

    this.confInformedConsent =
      await this.informedConsentService.updateConfInfomedConsentInstance(input);
  }

  onCreate(): void {
    this.router.navigate(['section', 'add'], {
      relativeTo: this.activateRoute,
    });
  }

  onEdit(confICSection: ConfICSection): void {
    this.router.navigate(['section', 'edit', confICSection.id], {
      relativeTo: this.activateRoute,
    });
  }

  onDetail(confICSection: ConfICSection): void {
    this.router.navigate(['section', confICSection.id], {
      relativeTo: this.activateRoute,
    });
  }
}
