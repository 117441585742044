import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import { SymptomService } from '../symptom.service';

@Component({
  selector: 'app-summary-symptom',
  templateUrl: './summary-symptom.component.html',
})
export class SummarySymptomComponent {
  original: any = {};
  verified: any = {};
  originalQuestions: any;
  verifiedQuestions: any;
  configuration: any = {};
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public symptomService: SymptomService,
    public conciliationService: ConciliationService
  ) {
    this.original = this.config.data?.original;
    this.verified = this.config.data?.verified;
    this.configuration = this.config.data.configuration;
    this.conciliationService.transformSummaryDate(this.original);
    this.conciliationService.transformSummaryDate(this.verified);
    this.originalQuestions = this.transformQuestion(
      this.original?.intensityQuestionAnswers
    );
    this.verifiedQuestions = this.transformQuestion(
      this.verified?.intensityQuestionAnswers
    );
  }

  onClose(result: boolean) {
    this.ref.close(result);
  }

  transformQuestion(questions: any) {
    return this.symptomService.transformQuestions(questions);
  }
}
