export const REPORT_QUERIES = {
  ConfSymptomByConfReportIdCustom: `query ConfSymptomByConfReportIdCustom($confReportId: ID!) {
        confSymptomByConfReportId(confReportId: $confReportId) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            projectId
            id
            type
            symptom
            order
            injectionSite
            injectionSide
            showHelp
            symptomHelp
            helpScreenDisplayOptions
            buttonHelpScreenLabel
            symptomLabel
            symptomIcon
            decimalPlaces
            minSize
            maxSize
            intensitySizeLabel
            isFinishDateRequired
            isStartDateRequired
            isIntensityRequired
            intensityType
            showNoValuesTaken
            noValuesTakenLabel
            isIntensityQuestionsRequired
            dataInputComponent
            alertText
            maxValueForAlert
            minValueForAlert
            isRangeAlertActive
            intensityQuestions {
              __typename
              id
              order
              question
              intensity
            }
            highIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            mediumIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lowIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            noneIntensityScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            lifeThreateningScaleOptions {
              __typename
              active
              startValue
              endValue
              helpText
              label
            }
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfAlertByConfReportIdCustom: `query ConfAlertByConfReportIdCustom($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
        confAlertByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confEdiaryId
            confSymptomId
            confTemperatureId
            projectId
            id
            state
            alertName
            alertRules {
              __typename
              ruleId
              alertRuleType
              expectedValue
            }
            visitList {
              __typename
              order
              visitName
            }
            siteRecipients {
              __typename
              recipients
              siteId
            }
            triggerVisitCompletion
            recipients
            type
            mailSubject
            mailBody
            SMSBody
            _lastUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  ConfReportByProject: `query ConfReportByProject($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfReportFilterInput, $limit: Int, $nextToken: String) {
        confReportByProject(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        helpScreenDisplayOptions
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        isDaysOfWeekRequired
        isDateRangeLimitRequired
        availableDays
        startDate
        endDate
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  ConfReportBySubjectId: `query ConfReportBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfReportBySubjectFilterInput, $limit: Int, $nextToken: String) {
        confReportBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subjectId
            confReportId
            isEnabled
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  CreateConfReport: `mutation CreateConfReport($input: CreateConfReportInput!, $condition: ModelConfReportConditionInput) {
    createConfReport(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        isSubjectNumberCheckRequired
        subjectNumberRegex
        subjectNumberPlaceholder
        checkSiteCodeInSubjectNumber
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      groups
      phases
      instancePerDay
      reportTitle
      showHelp
      reportHelp
      helpScreenDisplayOptions
      reportIcon
      symptomPageLayout
      programationType
      plannedDayList
      isDisplayDependentOnTheEDiary
      forms
      isControlledBySite
      allowReportBackDating
      enableLocalNotifications
      futureNotifications
      alertType
      alertWeeklyDays
      alertInBetweenDaysFrequency
      alertDuringSameDayFrequency
      alertStartTime
      alertEndTime
      jsonForm
      isSignatureRequired
      isGPSRequired
      isUserIPRequired
      isMediaRequired
      isDeviceDataRequired
      isDaysOfWeekRequired
      isDateRangeLimitRequired
      availableDays
      startDate
      endDate
      mediaTypes
      isAssociatedToVisits
      areSymptomsRequired
      areAlertsRequired
      isDeviceDataManagementRequired
      availableUsers
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  GetConfReport: `query GetConfReport($id: ID!) {
    getConfReport(id: $id) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        isSubjectNumberCheckRequired
        subjectNumberRegex
        subjectNumberPlaceholder
        checkSiteCodeInSubjectNumber
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      groups
      phases
      instancePerDay
      reportTitle
      showHelp
      reportHelp
      helpScreenDisplayOptions
      reportIcon
      symptomPageLayout
      programationType
      plannedDayList
      isDisplayDependentOnTheEDiary
      forms
      isControlledBySite
      allowReportBackDating
      enableLocalNotifications
      futureNotifications
      alertType
      alertWeeklyDays
      alertInBetweenDaysFrequency
      alertDuringSameDayFrequency
      alertStartTime
      alertEndTime
      jsonForm
      isSignatureRequired
      isGPSRequired
      isUserIPRequired
      isMediaRequired
      isDeviceDataRequired
      isDaysOfWeekRequired
      isDateRangeLimitRequired
      availableDays
      startDate
      endDate
      mediaTypes
      isAssociatedToVisits
      areSymptomsRequired
      areAlertsRequired
      isDeviceDataManagementRequired
      availableUsers
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  ReportInstanceByConfReportIdCustom: `query ReportInstanceByConfReportIdCustom($confReportId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceByConfReportId(confReportId: $confReportId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            subjectId
            subject {
              __typename
              subjectNumber
              id
              siteId
              state
              projectId
              currentScheduledPhase {
                __typename
                date
                phase
                state
              }
            }
            siteId
            id
            state
            plannedDate
            completeDate
            json
            gps
            appVersion
            userIP
            reportConfVersion
            _lastUser
            _deviceModel
            _deviceSOVersion
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`,
  CustomReportInstanceBySubjectId: `query CustomReportInstanceBySubjectId($subjectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelReportInstanceFilterInput, $limit: Int, $nextToken: String) {
        reportInstanceBySubjectId(subjectId: $subjectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            confReportId
            confReport {
              __typename
              projectId
              id
              groups
              phases
              instancePerDay
              reportTitle
              showHelp
              reportHelp
              helpScreenDisplayOptions
              reportIcon
              symptomPageLayout
              programationType
              plannedDayList
              isDisplayDependentOnTheEDiary
              forms
              isControlledBySite
              allowReportBackDating
              enableLocalNotifications
              futureNotifications
              alertType
              alertWeeklyDays
              alertInBetweenDaysFrequency
              alertDuringSameDayFrequency
              alertStartTime
              alertEndTime
              jsonForm
              isSignatureRequired
              isGPSRequired
              isUserIPRequired
              isMediaRequired
              isDeviceDataRequired
              mediaTypes
              isAssociatedToVisits
              areSymptomsRequired
              areAlertsRequired
              isDeviceDataManagementRequired
              availableUsers
              state
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subjectId
            subject {
              __typename
              projectId
              siteId
              id
              subjectNumber
              group
              state
              tag
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            siteId
            site {
              __typename
              projectId
              id
              name
              contactInfo
              showContactInfo
              showContactInfoLogin
              timezone
              defaultLanguage
              _lastUser
              _changeReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            id
            state
            plannedDate
            completeDate
            json
            score
            reportedBy {
              __typename
              username
              userType
            }
            gps
            appVersion
            userIP
            reportConfVersion
            symptomOcurrencies {
              __typename
              confSymptomId
              symptomInstanceId
              symptomRecordLogId
              occurrency
              order
            }
            isAlertChecked
            isPDFGenerated
            pdfFiles {
              __typename
              uploadDate
              fileUrl
            }
            _lastUser
            _changeReason
            _deviceModel
            _deviceSOVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`,
  DeleteConfReport: `mutation DeleteConfReport($input: DeleteConfReportInput!, $condition: ModelConfReportConditionInput) {
    deleteConfReport(input: $input, condition: $condition) {
      __typename
      projectId
      project {
        __typename
        id
        state
        code
        name
        sponsor
        groups
        phases
        faultRecipients
        isEdiaryActivated
        isReportsActivated
        isICActivated
        isChatActivated
        isMFAActivated
        isSubjectNumberCheckRequired
        subjectNumberRegex
        subjectNumberPlaceholder
        checkSiteCodeInSubjectNumber
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      groups
      phases
      instancePerDay
      reportTitle
      showHelp
      reportHelp
      helpScreenDisplayOptions
      reportIcon
      symptomPageLayout
      programationType
      plannedDayList
      isDisplayDependentOnTheEDiary
      forms
      isControlledBySite
      allowReportBackDating
      enableLocalNotifications
      futureNotifications
      alertType
      alertWeeklyDays
      alertInBetweenDaysFrequency
      alertDuringSameDayFrequency
      alertStartTime
      alertEndTime
      jsonForm
      isSignatureRequired
      isGPSRequired
      isUserIPRequired
      isMediaRequired
      isDeviceDataRequired
      isDaysOfWeekRequired
      isDateRangeLimitRequired
      availableDays
      startDate
      endDate
      mediaTypes
      isAssociatedToVisits
      areSymptomsRequired
      areAlertsRequired
      isDeviceDataManagementRequired
      availableUsers
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfReport: `mutation UpdateConfReport($input: UpdateConfReportInput!, $condition: ModelConfReportConditionInput) {
    updateConfReport(input: $input, condition: $condition) {
      __typename
      projectId
      id
      groups
      phases
      instancePerDay
      reportTitle
      showHelp
      reportHelp
      helpScreenDisplayOptions
      reportIcon
      symptomPageLayout
      programationType
      plannedDayList
      isDisplayDependentOnTheEDiary
      forms
      isControlledBySite
      allowReportBackDating
      enableLocalNotifications
      futureNotifications
      alertType
      alertWeeklyDays
      alertInBetweenDaysFrequency
      alertDuringSameDayFrequency
      alertStartTime
      alertEndTime
      jsonForm
      isSignatureRequired
      isGPSRequired
      isUserIPRequired
      isMediaRequired
      isDeviceDataRequired
      isDaysOfWeekRequired
      isDateRangeLimitRequired
      availableDays
      startDate
      endDate
      mediaTypes
      isAssociatedToVisits
      areSymptomsRequired
      areAlertsRequired
      isDeviceDataManagementRequired
      availableUsers
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  CreateConfReportBySubject: `mutation CreateConfReportBySubject($input: CreateConfReportBySubjectInput!, $condition: ModelConfReportBySubjectConditionInput) {
    createConfReportBySubject(input: $input, condition: $condition) {
      __typename
      id
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        helpScreenDisplayOptions
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        isDaysOfWeekRequired
        isDateRangeLimitRequired
        availableDays
        startDate
        endDate
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      isEnabled
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  UpdateConfReportBySubject: `mutation UpdateConfReportBySubject($input: UpdateConfReportBySubjectInput!, $condition: ModelConfReportBySubjectConditionInput) {
    updateConfReportBySubject(input: $input, condition: $condition) {
      __typename
      id
      subjectId
      subject {
        __typename
        projectId
        siteId
        id
        subjectNumber
        group
        state
        tag
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      confReportId
      confReport {
        __typename
        projectId
        id
        groups
        phases
        instancePerDay
        reportTitle
        showHelp
        reportHelp
        helpScreenDisplayOptions
        reportIcon
        symptomPageLayout
        programationType
        plannedDayList
        isDisplayDependentOnTheEDiary
        forms
        isControlledBySite
        allowReportBackDating
        enableLocalNotifications
        futureNotifications
        alertType
        alertWeeklyDays
        alertInBetweenDaysFrequency
        alertDuringSameDayFrequency
        alertStartTime
        alertEndTime
        jsonForm
        isSignatureRequired
        isGPSRequired
        isUserIPRequired
        isMediaRequired
        isDeviceDataRequired
        isDaysOfWeekRequired
        isDateRangeLimitRequired
        availableDays
        startDate
        endDate
        mediaTypes
        isAssociatedToVisits
        areSymptomsRequired
        areAlertsRequired
        isDeviceDataManagementRequired
        availableUsers
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      isEnabled
      state
      _lastUser
      _changeReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }`,
  GetReportInstance: `query GetReportInstance($id: ID!) {
        getReportInstance(id: $id) {
          __typename
          confReportId
          confReport {
            __typename
            projectId
            id
            groups
            phases
            instancePerDay
            reportTitle
            showHelp
            reportHelp
            helpScreenDisplayOptions
            reportIcon
            symptomPageLayout
            programationType
            plannedDayList
            isDisplayDependentOnTheEDiary
            forms
            isControlledBySite
            allowReportBackDating
            enableLocalNotifications
            futureNotifications
            alertType
            alertWeeklyDays
            alertInBetweenDaysFrequency
            alertDuringSameDayFrequency
            alertStartTime
            alertEndTime
            jsonForm
            isSignatureRequired
            isGPSRequired
            isUserIPRequired
            isMediaRequired
            isDeviceDataRequired
            mediaTypes
            isAssociatedToVisits
            areSymptomsRequired
            areAlertsRequired
            isDeviceDataManagementRequired
            availableUsers
            state
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subjectId
          subject {
            __typename
            projectId
            siteId
            id
            subjectNumber
            group
            state
            tag
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          siteId
          site {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          id
          state
          plannedDate
          completeDate
          json
          score
          gps
          appVersion
          userIP
          reportConfVersion
          symptomOcurrencies {
            __typename
            confSymptomId
            symptomInstanceId
            symptomRecordLogId
            occurrency
            order
          }
          isAlertChecked
          isPDFGenerated
          reportedBy {
            __typename
            username
            userType
          }
          pdfFiles {
            __typename
            uploadDate
            fileUrl
          }
          _lastUser
          _changeReason
          _deviceModel
          _deviceSOVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`,
  listConfAlertsCustom: `query ListConfAlerts($filter: ModelConfAlertFilterInput, $limit: Int, $nextToken: String) {
    listConfAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confReportId
        confEdiaryId
        confSymptomId
        confTemperatureId
        projectId
        id
        alertName
        recipients
        type
        mailSubject
        mailBody
        SMSBody
        triggerVisitCompletion
        triggerEDiarySuspension
        state
        siteRecipients {
          recipients
          siteId
        }
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
  AlertInstanceByConfAlertId: `query AlertInstanceByConfAlertId($confAlertId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAlertInstanceFilterInput, $limit: Int, $nextToken: String) {
    alertInstanceByConfAlertId(confAlertId: $confAlertId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        confAlertId
        subjectId
        id
        confAlert_data
        entityId
        entityName
        state
        revisionState
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }`,
};
