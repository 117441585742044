<aside class="banner" *ngIf="!isClosed">
  <label for="banner-text">{{
    'general.labelManteinanceBanner' | translate
  }}</label>
  <p id="banner-text">
    {{ 'general.labelMaintenanceBannerMessage' | translate
    }}<strong>{{ startDate }} - {{ endDate }}</strong>
  </p>
  <span class="close" (click)="isClosed = true">
    <img src="../../../../assets/icons/home/union.svg" alt="Close icon" />
  </span>
</aside>
