import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryPipe } from 'src/app/shared/pipes/dictionary.pipe';

@Pipe({
  name: 'dictionaryField',
})
export class DictionaryFieldPipe implements PipeTransform {
  constructor(private dictionary: DictionaryPipe) {}

  transform(field: any): any {
    const clonedField = JSON.parse(JSON.stringify(field));
    this.recursiveTraversal(clonedField[0]);

    return [...clonedField];
  }

  // Define una función recursiva para recorrer la estructura JSON
  recursiveTraversal(field: any) {
    if (field && typeof field === 'object') {
      for (const key in field) {
        if (field.hasOwnProperty(key)) {
          if (key === 'templateOptions') {
            field[key] = this.dictionaryField(field[key]);
          }
          this.recursiveTraversal(field[key]);
        }
      }
    }
  }

  dictionaryField(templateOptions: any) {
    if (templateOptions) {
      const { label, placeholder, addText, removeText, options, questions } =
        templateOptions;
      if (label) {
        templateOptions.label = this.transformLabel(label);
      }

      if (placeholder) {
        templateOptions.placeholder = this.transformLabel(placeholder);
      }

      if (options) {
        templateOptions.options = this.transformOptions(options);
      }

      if (questions) {
        templateOptions.questions = this.transformQuestions(questions);
      }

      if (addText) {
        templateOptions.addText = this.transformLabel(addText);
      }

      if (removeText) {
        templateOptions.addText = this.transformLabel(removeText);
      }
    }
    return templateOptions;
  }

  transformOptions(options: any[]): any[] {
    if (options) {
      return options.map((option: any) => ({
        ...option,
        label: this.transformLabel(option.label),
      }));
    }
    return options;
  }

  transformQuestions(questions: any[]): any[] {
    if (questions) {
      return questions.map((question: any) => ({
        label: this.transformLabel(question.label),
        tooltip: question.tooltip,
      }));
    }
    return questions;
  }

  transformLabel(label: string): string {
    return this.dictionary.transform(label);
  }
}
