import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API, graphqlOperation } from 'aws-amplify';
import { Observable } from 'rxjs';
import { TRIALPAL_QUERIES } from 'src/app/services/trialpal.queries';
import {
  CreateConfDictionaryInput,
  CreateConfDictionaryMutation,
  UpdateConfDictionaryInput,
  UpdateConfDictionaryMutation,
} from 'src/app/services/trialpal.types';
import { ConfDictionaryByProjectIdQuery } from './dictionary';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(private auth: AuthService) {}

  async createConfDictionaryInput(
    data: CreateConfDictionaryInput
  ): Promise<CreateConfDictionaryMutation> {
    const response = await this.performGraphQLQuery(
      TRIALPAL_QUERIES.CreateConfDictionary,
      {
        input: data,
      }
    );
    return response.data.createConfDictionary;
  }

  async getConfDictionaryByProjectId(
    projectId: string
  ): Promise<ConfDictionaryByProjectIdQuery> {
    let dictionary: ConfDictionaryByProjectIdQuery;
    let response = await this.performGraphQLQuery(
      TRIALPAL_QUERIES.ConfDictionaryByProjectId,
      {
        projectId,
        sortDirection: undefined,
        filter: undefined,
        limit: 1000,
      }
    );
    let listQuery = response.data.confDictionaryByProjectId;
    dictionary = listQuery;
    let nextToken = listQuery.nextToken;
    while (nextToken) {
      response = await this.performGraphQLQuery(
        TRIALPAL_QUERIES.ConfDictionaryByProjectId,
        {
          projectId,
          sortDirection: undefined,
          filter: undefined,
          limit: 1000,
          nextToken,
        }
      );
      let listQuery = response.data.confDictionaryByProjectId;
      dictionary.items = dictionary.items.concat(listQuery.items);
      nextToken = listQuery.nextToken;
    }
    return dictionary;
  }

  async updateDictionary(
    data: UpdateConfDictionaryInput
  ): Promise<UpdateConfDictionaryMutation> {
    let updateInput: UpdateConfDictionaryInput = {
      id: data.id,
      _version: data._version,
    };
    updateInput = Object.assign(updateInput, data);
    updateInput._lastUser = this.auth.getUsername();
    const response = await this.performGraphQLQuery(
      TRIALPAL_QUERIES.UpdateConfDictionary,
      {
        input: updateInput,
      }
    );
    return response.data.updateConfDictionary;
  }

  performGraphQLQuery(query: any, args: any): Promise<any> | Observable<any> {
    return API.graphql(graphqlOperation(query, args)) as any;
  }
}
