<div
  *ngFor="let occurrency of symptomOccurrences; let index = index"
  [ngClass]="{ 'height-100': index === currentConfSymptomIndex }"
>
  <div class="section height-100" *ngIf="index === currentConfSymptomIndex">
    <h3 class="p-m-0 title">{{ 'subject.tabheaderSymptom' | translate }}</h3>
    <header class="symptom-header">
      <label for="occurrency" class="list-occurrency-label">
        <span class="symptom-image-container">
          <img
            [src]="
              '/assets/icons/' + occurrency.confSymptom?.symptomIcon + '.png'
            "
            alt="symptomIcon"
            *ngIf="occurrency.confSymptom?.symptomIcon"
          />
        </span>
        <span class="required"> {{ occurrency.label }} </span>
      </label>
      <p-selectButton
        required
        [id]="'occurrency' + index"
        [name]="'occurrency' + index"
        [options]="booleanOptions"
        [ngModel]="occurrency.occurrency"
        optionLabel="label"
        optionValue="value"
        [required]="true"
        #occurrencyTemplate="ngModel"
      ></p-selectButton>
    </header>
    <form #form="ngForm" class="form">
      <app-add-edit-symptom
        [confSymptom]="occurrency.confSymptom"
        [isEdition]="occurrency.isEdition"
        [symptomInstance]="occurrency.symptomInstance"
        [symptomInstanceInput]="occurrency.symptomInstanceInput"
        *ngIf="occurrencyTemplate.value"
      ></app-add-edit-symptom>
      <!--Obtiene la auditoria para el cambio de una occurencia en el symptomOccurrencies-->
      <div class="field">
        <change-reason
          *ngIf="
            isOccurrencyChange(occurrency, occurrencyTemplate.value) &&
            reportExecutionService.isReportInstanceEdition
          "
          name="report_reason"
          [label]="'audit.reason' | translate"
          [(ngModel)]="changeReason"
          [required]="true"
        ></change-reason>
      </div>
    </form>
    <app-pagination-button
      (next)="nextSymptomInstance(occurrency, occurrencyTemplate.value)"
      (previous)="previousSymptomInstance(occurrency)"
      [nextDisabled]="form?.invalid ?? true"
      [previousDisabled]="currentConfSymptomIndex === 0"
    ></app-pagination-button>
  </div>
</div>
