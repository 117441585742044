<div [ngClass]="{ 'container-tp2': !isModal }">
  <h1 *ngIf="!isModal">
    {{
      (editMode ? 'project.h1CreateProject' : 'project.h1EditProject')
        | translate
    }}
  </h1>
  <form id="form" class="form" name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12" *ngIf="!isModal">
        <h3>{{ 'project.titleBasicData' | translate }}</h3>
      </div>

      <div class="p-col-12 p-field">
        <label>
          {{ 'project.labelName' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <textarea
          id="name"
          pInputTextarea
          name="name"
          type="text"
          pInputText
          [(ngModel)]="project.name"
          [rows]="3"
          [autoResize]="true"
          required
          #name="ngModel"
          placeholder="{{ 'project.titleHelp' | translate }}"
        ></textarea>
        <small
          class="form-error"
          *ngIf="!name.valid && (f.submitted || name.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label>
          {{ 'project.labelCode' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <input
          id="code"
          name="code"
          type="text"
          autocomplete="off"
          pInputText
          [(ngModel)]="project.code"
          required
          #code="ngModel"
        />
        <small
          class="form-error"
          *ngIf="!code.valid && (f.submitted || code.touched)"
          >{{ 'general.required' | translate }}</small
        >
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label>
          {{ 'project.labelSponsor' | translate }}
        </label>
        <input
          id="sponsor"
          name="sponsor"
          type="text"
          autocomplete="off"
          pInputText
          [(ngModel)]="project.sponsor"
          #sponsor="ngModel"
        />
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label>
          {{ 'project.labelPhases' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <div class="p-inputAutocompleteButton">
          <div
            [ngClass]="{ 'p-col-11': editMode, 'p-col-12': !editMode }"
            style="padding: 0"
          >
            <p-chips
              ngDefaultControl
              id="phases"
              name="phases"
              [(ngModel)]="project.phases"
              required
              #phases="ngModel"
              separator=","
              (onAdd)="addVisitDictionary($event)"
              (onRemove)="removeVisit($event)"
              [allowDuplicate]="false"
            >
              <ng-template let-item pTemplate="item">
                <span class="text-wrap" pTooltip="{{ item }}">{{
                  getChipText(item)
                }}</span>
              </ng-template>
            </p-chips>
          </div>
          <div class="p-col" *ngIf="editMode">
            <button
              id="dictionaryButtonReport"
              name="dictionaryButtonReport"
              type="button"
              pButton
              pRipple
              icon="pi pi-book"
              (click)="onDictionaty(true, 'visit')"
              class="
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-ml-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
              title="{{ 'dictionary.labelButton' | translate }}"
            ></button>
          </div>
        </div>
        <small>{{ 'project.tooltipInputPhases' | translate }}</small>
        <small
          class="form-error"
          *ngIf="!phases.valid && (f.submitted || phases.touched)"
          >{{ 'general.required' | translate }}</small
        >
        <div class="p-d-flex p-flex-column">
          <small
            class="lineListDictionary"
            *ngIf="this.isArraySpanishVisit.length > 0"
            ><label class="p-mr-1">ES:</label>
            <div class="p-mr-3" *ngFor="let item of this.isArraySpanishVisit">
              <strong>*</strong> {{ item?.spanish }}
            </div>
          </small>
          <small
            class="lineListDictionary"
            *ngIf="this.isArrayEnglishVisit.length > 0"
            ><label class="p-mr-1">EN:</label>
            <div class="p-mr-3" *ngFor="let item of this.isArrayEnglishVisit">
              <strong>*</strong> {{ item.english }}
            </div>
          </small>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-field">
        <label>
          {{ 'project.labelGroups' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <div class="p-inputAutocompleteButton">
          <div
            [ngClass]="{ 'p-col-11': editMode, 'p-col-12': !editMode }"
            style="padding: 0"
          >
            <p-chips
              id="groups"
              name="groups"
              [(ngModel)]="project.groups"
              required
              separator=","
              #groups="ngModel"
              separator=","
              (onAdd)="addGroupDictionary($event)"
              (onRemove)="removeGroup($event)"
              [allowDuplicate]="false"
            >
              <ng-template let-item pTemplate="item">
                <span class="text-wrap" pTooltip="{{ item }}">{{
                  getChipText(item)
                }}</span>
              </ng-template>
            </p-chips>
          </div>
          <div class="p-col" *ngIf="editMode">
            <button
              id="dictionaryButtonReport"
              name="dictionaryButtonReport"
              type="button"
              pButton
              pRipple
              icon="pi pi-book"
              (click)="onDictionaty(true, 'group')"
              class="
                p-button-rounded
                p-button-secondary
                p-button-outlined
                p-ml-2
                p-ripple
                p-button
                p-component
                p-button-icon-only
              "
              title="{{ 'dictionary.labelButton' | translate }}"
            ></button>
          </div>
        </div>
        <small>{{ 'project.tooltipInputGropus' | translate }}</small>
        <small
          class="form-error"
          *ngIf="!groups.valid && (f.submitted || groups.touched)"
          >{{ 'general.required' | translate }}</small
        >
        <div class="p-d-flex p-flex-column">
          <small
            class="lineListDictionary"
            *ngIf="this.isArraySpanishGroup.length > 0"
            ><label class="p-mr-1">ES:</label>
            <div class="p-mr-3" *ngFor="let item of this.isArraySpanishGroup">
              <strong>*</strong> {{ item?.spanish }}
            </div>
          </small>
          <small
            class="lineListDictionary"
            *ngIf="this.isArrayEnglishGroup.length > 0"
            ><label class="p-mr-1">EN:</label>
            <div class="p-mr-3" *ngFor="let item of this.isArrayEnglishGroup">
              <strong>*</strong> {{ item.english }}
            </div>
          </small>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field" *ngIf="authService.isAdmin()">
        <label class="full-width">
          {{ 'project.enableMFA' | translate }}
        </label>
        <p-inputSwitch
          id="project.isMFAActivated"
          name="project.isMFAActivated"
          [(ngModel)]="project.isMFAActivated"
        ></p-inputSwitch>
        <br />
        <small>
          {{ 'project.helpMFA' | translate }}
        </small>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label for="dayName"
          >{{ 'project.labelFaultRecipients' | translate }}
          <span class="inputRequired">* </span></label
        >
        <p-chips
          [allowDuplicate]="false"
          separator=","
          name="faultRecipients"
          (onAdd)="onAddRecipient($event.value)"
          [(ngModel)]="project.faultRecipients"
          #faultRecipients="ngModel"
          [addOnBlur]="true"
          required
        >
          <ng-template let-item pTemplate="item">
            <span class="text-wrap" pTooltip="{{ item }}">{{
              getChipText(item)
            }}</span>
          </ng-template>
        </p-chips>
        <small
          class="form-error"
          *ngIf="
            !faultRecipients.valid && (f.submitted || faultRecipients.touched)
          "
        >
          {{ 'general.required' | translate }}
        </small>
      </div>
      <div class="p-col-12 p-md-6 p-field p-align-center">
        <label class="p-mr-2">{{
          'project.accessibilityGroupConfig' | translate
        }}</label>
        <p-button
          icon="pi pi-cog"
          iconPos="left"
          (click)="openAddEditAccesibilityGroup()"
        ></p-button>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="isSubjectNumberCheckRequired">{{
          'project.isSubjectNumberCheckRequired' | translate
        }}</label>
        <p-inputSwitch
          id="isSubjectNumberCheckRequired"
          name="isSubjectNumberCheckRequired"
          [(ngModel)]="project.isSubjectNumberCheckRequired"
        ></p-inputSwitch>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="checkSiteCodeInSubjectNumber">{{
          'project.checkSiteCodeInSubjectNumber' | translate
        }}</label>
        <p-inputSwitch
          id="checkSiteCodeInSubjectNumber"
          name="checkSiteCodeInSubjectNumber"
          [(ngModel)]="project.checkSiteCodeInSubjectNumber"
        ></p-inputSwitch>
      </div>
      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="project.isSubjectNumberCheckRequired"
      >
        <label for="subjectNumberRegex">
          {{ 'project.subjectNumberRegex' | translate }}
          <span class="inputRequired">* </span>
        </label>
        <div class="inputAutocompleteButton p-d-flex">
          <div class="p-col-11" style="padding: 0">
            <input
              id="subjectNumberRegex"
              name="subjectNumberRegex"
              type="text"
              autocomplete="off"
              pInputText
              [(ngModel)]="project.subjectNumberRegex"
              required
              #subjectNumberRegex="ngModel"
            />
          </div>
          <div class="p-col">
            <a href="https://regexr.com/" target="_blank" rel="noopener">
              <button
                id="regExHelpButton"
                name="regExHelpButton"
                type="button"
                pButton
                pRipple
                icon="pi pi-question"
                class="
                  p-button-rounded
                  p-button-secondary
                  p-button-outlined
                  p-ml-2
                  p-ripple
                  p-button
                  p-component
                  p-button-icon-only
                "
                pTooltip="{{ 'project.regExHelpButton' | translate }}"
                tooltipPosition="top"
              ></button>
            </a>
          </div>
        </div>
        <small
          class="form-error"
          *ngIf="
            !subjectNumberRegex.valid &&
            (f.submitted || subjectNumberRegex.touched)
          "
          >{{ 'general.required' | translate }}</small
        >
        <div class="inputAutocompleteButton p-d-flex">
          <div
            class="p-col-11"
            style="padding-left: 0; padding-right: 0; padding-top: 0.5rem"
          >
            <input
              id="testText"
              name="testText"
              type="text"
              autocomplete="off"
              placeholder="{{ 'project.testText' | translate }}"
              pInputText
              [(ngModel)]="testText"
              (ngModelChange)="testRegex()"
            />
            <small *ngIf="testText && project.subjectNumberRegex">{{
              isTestTextValid
                ? ('project.correctTestRegex' | translate)
                : ('project.incorrectTestRegex' | translate)
            }}</small>
          </div>
        </div>
      </div>

      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="
          project.isSubjectNumberCheckRequired ||
          project.checkSiteCodeInSubjectNumber
        "
      >
        <label for="subjectNumberPlaceholder">
          {{ 'project.subjectNumberPlaceholder' | translate }}
        </label>
        <input
          id="subjectNumberPlaceholder"
          name="subjectNumberPlaceholder"
          type="text"
          pInputText
          [(ngModel)]="project.subjectNumberPlaceholder"
          #subjectNumberPlaceholder="ngModel"
        />
      </div>
      <div
        class="
          p-field p-col-12 p-sm-6 p-md-4 p-md-offset-4 p-xl-2 p-xl-offset-8
        "
        *ngIf="!isModal"
      >
        <button
          class="p-button-outlined p-button-secondary"
          pButton
          label="{{ 'project.buttonCancel' | translate }}"
          type="button"
          (click)="onCancelar()"
        ></button>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-4 p-xl-2" *ngIf="!isModal">
        <button
          id="btnSend"
          pButton
          label="
            {{
            (editMode ? 'project.buttonUpdate' : 'project.buttonAdd')
              | translate
          }}"
          type="submit"
        ></button>
      </div>
      <div class="p-grid p-mt-2 p-col-12" *ngIf="isModal">
        <div class="p-field p-col-12 p-sm-4 p-md-3 p-md-offset-6 p-sm-offset-4">
          <button
            class="p-button-outlined p-button-secondary"
            pButton
            label="{{ 'project.buttonCancel' | translate }}"
            type="button"
            (click)="onCancelar()"
          ></button>
        </div>
        <div class="p-field p-col-12 p-sm-4 p-md-3">
          <button
            id="btnSend2"
            pButton
            label="
            {{
              (editMode ? 'project.buttonUpdate' : 'project.buttonAdd')
                | translate
            }}"
            type="submit"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
