<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="off">
  <p-fieldset
    legend="{{
      isEdition
        ? readMode
          ? translate.viewForm
          : translate.labelLegendEdit
        : translate.labelLegendAdd
    }}"
  >
    <div class="p-text-right">
      <button
        *ngIf="false"
        pButton
        label="{{ 'general.btnAuditTrace' | translate }}"
        type="button"
        icon="pi pi-file"
        iconPos="right"
        (click)="openAuditModal()"
        badgeClass="color: white;"
        class="p-button-rounded p-button-success pbutton"
        [disabled]="readMode"
      ></button>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <label class="text-bold label-step" for="txt_confForm_name">
          {{ 'form.firstStep' | translate }}
        </label>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-witdh" for="name"
          >{{ translate.labelName
          }}<span class="inputRequired"> * </span></label
        >
        <app-simple-dictionary-input
          id="txt_confForm_name"
          name="name"
          [projectId]="projectId"
          [isReadOnly]="readMode"
          [isRequired]="true"
          [submitted]="f.submitted"
          [value]="confForm.name"
          (outputEvent)="confForm.name = $event"
        ></app-simple-dictionary-input>
      </div>

      <div class="p-grid p-fluid p-col-12 p-p-0">
        <!--Secciona si el formulario de otros sintomas-->
        <div class="p-col-12 p-md-6 p-field">
          <label class="full-witdh" for="typeForm">{{
            'report.labelIsOtherForm' | translate
          }}</label>
          <div class="p-col centerItem p-pl-0">
            <p-inputSwitch
              name="typeForm"
              id="typeForm"
              #isOtherForm="ngModel"
              [(ngModel)]="confForm.isOtherForm"
              (onChange)="isOtherSyptomChange(); setSectionsToReconciled()"
              [disabled]="readMode"
            ></p-inputSwitch>
          </div>
        </div>

        <!--Secciona si el formulario puede ser conciliado-->
        <div class="p-col-12 p-md-6 p-field">
          <label class="full-witdh" for="sw_reconciliation">{{
            'form.labelReconciliationRequired' | translate
          }}</label>
          <div class="centerItem">
            <p-inputSwitch
              name="sw_reconciliation"
              id="sw_reconciliation"
              class="p-d-block"
              #isReconciliationRequired="ngModel"
              [(ngModel)]="confForm.isReconciliationRequired"
              (onChange)="setSectionsToReconciled()"
              [disabled]="readMode"
              [required]="true"
            ></p-inputSwitch>
            <em
              *ngIf="confForm.isReconciliationRequired && isEdition"
              class="pi pi-cog p-ml-2"
              style="font-size: 1.2em"
              [ngStyle]="{ cursor: 'pointer' }"
              (click)="onConfiguration('reconciliation')"
              pTooltip="{{
                'general.configurations.tooltipButton' | translate
              }}"
            ></em>
            <small
              class="form-error p-d-block"
              *ngIf="
                !isReconciliationRequired.valid &&
                (f.submitted || isReconciliationRequired.touched)
              "
              >{{ translateGeneral.required }}</small
            >
          </div>
        </div>
      </div>
      <!-- General symptoms -->
      <div class="p-col-12 p-md-6 p-field" *ngIf="!confForm.isOtherForm">
        <label for="confSymptoms"
          >{{ translate.labelSymptomsRequired
          }}<span class="inputRequired"> * </span></label
        >
        <p-multiSelect
          name="confSymptoms"
          id="confSymptoms"
          #confSymptoms="ngModel"
          defaultLabel="{{ translateGeneral.selectPlaceholder }}"
          [options]="ediarySymptoms"
          [(ngModel)]="selectedSymptoms"
          optionLabel="name"
          optionValue="value"
          [maxSelectedLabels]="1"
          selectedItemsLabel="{0} {{ translate.labelSymptoms }}"
          [required]="
            selectedLocalSymptoms.length === 0 && selectedSymptoms.length === 0
          "
          (onChange)="updateSelectedSymptomsLabel(); setSectionsToReconciled()"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-multiSelect>
        <div
          style="display: inline-block"
          *ngFor="let data of selectedSymptomsToShow; index as i"
        >
          <small>
            {{ data + (selectedSymptomsToShow.length - 1 > i ? ' - ' : '') }}
          </small>
        </div>
        <small
          class="form-error"
          *ngIf="!confSymptoms.valid && (f.submitted || confSymptoms.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>
      <!-- Local symptoms -->
      <div
        class="p-col-12 p-md-6 p-field local"
        id="localSymtomsDropdown"
        *ngIf="!confForm.isOtherForm"
      >
        <label for="localSymptoms"
          >{{ translate.labelLocalSymptomsRequired
          }}<span class="inputRequired"> * </span></label
        >
        <p-multiSelect
          name="localSymptoms"
          id="localSymptoms"
          #localSymptoms="ngModel"
          defaultLabel="{{ translateGeneral.selectPlaceholder }}"
          [options]="ediaryLocalSymptoms"
          [(ngModel)]="selectedLocalSymptoms"
          optionLabel="name"
          optionValue="value"
          [maxSelectedLabels]="1"
          selectedItemsLabel="{0} {{ translate.labelSymptoms }}"
          [required]="
            selectedLocalSymptoms.length === 0 && selectedSymptoms.length === 0
          "
          (onChange)="updateSelectedSymptomsLabel(); setSectionsToReconciled()"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-multiSelect>
        <div
          style="display: inline-block"
          *ngFor="let data of selectedLocalSymptomsToShow; index as i"
        >
          <small>
            {{
              data + (selectedLocalSymptomsToShow.length - 1 > i ? ' - ' : '')
            }}
          </small>
        </div>
        <small
          class="form-error"
          *ngIf="!localSymptoms.valid && (f.submitted || localSymptoms.touched)"
        >
          {{ translateGeneral.required }}
        </small>
      </div>

      <div class="p-col-12 p-md-6 p-field" *ngIf="!confForm.isOtherForm">
        <label for="symptomPageLayout"
          >{{ translate.labelPageLayout
          }}<span class="inputRequired"> * </span></label
        >
        <p-dropdown
          appendTo="body"
          placeholder="{{ translateGeneral.selectPlaceholder }}"
          [options]="pageLayouts"
          optionLabel="name"
          optionValue="value"
          name="symptomPageLayout"
          id="symptomPageLayout"
          #symptomPageLayout="ngModel"
          [(ngModel)]="confForm.symptomPageLayout"
          emptyMessage="{{ 'general.noResultsFound' | translate }}"
          emptyFilterMessage="{{ 'general.noResultsFound' | translate }}"
          [disabled]="readMode"
        ></p-dropdown>
        <p-message
          class="info"
          severity="info"
          [text]="'form.onSelectSymptomsOcurrenceFirstMsj' | translate"
          *ngIf="confForm.symptomPageLayout === 'OCURRENCE_FIRST'"
        ></p-message>
      </div>
      <div class="p-col-12 p-field">
        <label class="label-step" for="isTemperatureRequired">{{
          'form.labelOptional' | translate
        }}</label>
      </div>
      <div class="p-col-12 p-md-6 p-field" *ngIf="!confForm.isOtherForm">
        <label class="full-width" for="isTemperatureRequired">{{
          translate.labelTemperature
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="isTemperatureRequired"
            id="isTemperatureRequired"
            #isTemperatureRequired="ngModel"
            [(ngModel)]="confForm.isTemperatureRequired"
            (onChange)="setSectionsToReconciled()"
            [disabled]="readMode"
          ></p-inputSwitch>
          <em
            *ngIf="confForm.isTemperatureRequired && isEdition"
            class="pi pi-cog p-ml-2"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="onConfiguration('temperature')"
            pTooltip="{{ 'general.configurations.tooltipButton' | translate }}"
          ></em>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="isMedicationRequired">{{
          translate.labelMedication
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="isMedicationRequired"
            id="isMedicationRequired"
            #isMedicationRequired="ngModel"
            [(ngModel)]="confForm.isMedicationRequired"
            (onChange)="setSectionsToReconciled()"
            [disabled]="readMode"
          ></p-inputSwitch>
          <em
            *ngIf="confForm.isMedicationRequired && isEdition"
            class="pi pi-cog p-ml-2"
            style="font-size: 1.2em"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="onConfiguration('medication')"
            pTooltip="{{ 'general.configurations.tooltipButton' | translate }}"
          ></em>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="isMedicalAttentionRequired">{{
          translate.labelMedicalAttention
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="isMedicalAttentionRequired"
            id="isMedicalAttentionRequired"
            #isMedicalAttentionRequired="ngModel"
            [(ngModel)]="confForm.isMedicalAttentionRequired"
            (onChange)="setSectionsToReconciled()"
            [disabled]="readMode"
          ></p-inputSwitch>
          <em
            *ngIf="confForm.isMedicalAttentionRequired && isEdition"
            class="pi pi-cog p-ml-2"
            style="font-size: 1.2em"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="onConfiguration('medicalAttention')"
            pTooltip="{{ 'general.configurations.tooltipButton' | translate }}"
          ></em>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="areOtherSymptomsRequired">{{
          translate.labelOtherSymptom
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="areOtherSymptomsRequired"
            id="areOtherSymptomsRequired"
            #areOtherSymptomsRequired="ngModel"
            [(ngModel)]="confForm.areOtherSymptomsRequired"
            (onChange)="setSectionsToReconciled()"
            [disabled]="readMode"
          ></p-inputSwitch>
          <em
            *ngIf="confForm.areOtherSymptomsRequired && isEdition"
            class="pi pi-cog p-ml-2"
            style="font-size: 1.2em"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="onConfiguration('otherSymptom')"
            pTooltip="{{ 'general.configurations.tooltipButton' | translate }}"
          ></em>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="areCommentsRequired">{{
          translate.labelComments
        }}</label>
        <p-inputSwitch
          name="areCommentsRequired"
          id="areCommentsRequired"
          #areCommentsRequired="ngModel"
          [(ngModel)]="confForm.areCommentsRequired"
          [disabled]="readMode"
        ></p-inputSwitch>
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="isMediaRequired">{{
          translate.labelMedia
        }}</label>
        <div class="p-col p-pl-0 centerItem">
          <p-inputSwitch
            name="isMediaRequired"
            id="isMediaRequired"
            #isMediaRequired="ngModel"
            [(ngModel)]="confForm.isMediaRequired"
            [disabled]="readMode"
          ></p-inputSwitch>
          <em
            *ngIf="confForm.isMediaRequired && isEdition"
            class="pi pi-cog p-ml-2"
            style="font-size: 1.2em"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="onConfiguration('multimedia')"
            pTooltip="{{ 'general.configurations.tooltipButton' | translate }}"
          ></em>
        </div>
      </div>
      <div
        class="p-col-12 p-md-6 p-field"
        *ngIf="confForm.isMediaRequired && !confForm.isOtherForm"
      >
        <label for="mediaTypes"
          >{{ translate.labelMediaType
          }}<span class="inputRequired"> * </span></label
        >
        <p-selectButton
          [required]="true"
          name="mediatypes"
          id="mediatypes"
          #mediatypes="ngModel"
          [options]="mediaTypes"
          [(ngModel)]="confForm.mediaTypes"
          [multiple]="true"
          optionLabel="name"
          optionValue="value"
          [disabled]="readMode"
        ></p-selectButton>
        <small
          class="form-error"
          *ngIf="!mediatypes.valid && (f.submitted || mediatypes.touched)"
          >{{ translateGeneral.required }}</small
        >
      </div>
      <div class="p-col-12 p-md-6 p-field">
        <label class="full-width" for="help">{{
          translate.labelShowHelp
        }}</label>
        <p-inputSwitch
          name="showHelp"
          id="showHelp"
          #showHelp="ngModel"
          (onChange)="onChangeShowHelp()"
          [(ngModel)]="confForm.showHelp"
          pTooltip="{{ 'report.labelShowHelpTooltip' | translate }}"
          [disabled]="readMode"
        ></p-inputSwitch>
      </div>
      <!--Help Screen Display Options guide-->
      <div class="p-col-12 p-md-6 p-field" *ngIf="confForm.showHelp">
        <label for="helpScreenDisplayOptions" class="p-mb-2 p-d-block"
          >{{ 'symptom.helpScreenDisplayOptions' | translate }}
          <span class="inputRequired"> *</span></label
        >
        <p-multiSelect
          appendTo="body"
          [required]="true"
          placeholder="{{ 'general.selectOneOrMorePlaceholder' | translate }}"
          [options]="HelpScreenDisplayOptions"
          optionLabel="name"
          optionValue="value"
          name="helpScreenDisplayOptions"
          id="helpScreenDisplayOptions"
          #helpScreenDisplayOptions="ngModel"
          [(ngModel)]="confForm.helpScreenDisplayOptions"
          tooltipPosition="top"
          pTooltip="{{ 'symptom.tooltiphelpScreenDisplayOption' | translate }}"
          [disabled]="readMode"
        ></p-multiSelect>
        <small
          class="form-error"
          *ngIf="
            !helpScreenDisplayOptions.valid &&
            (f.submitted || helpScreenDisplayOptions.touched)
          "
          >{{ 'general.required' | translate }}</small
        >
      </div>
      <div class="p-col-12 p-md-6 p-field" *ngIf="confForm.showHelp">
        <div class="p-field">
          <label for="help"
            >{{ translate.labelHelp
            }}<span class="inputRequired"> * </span></label
          >
          <app-simple-dictionary-editor
            [isRequired]="true"
            [submitted]="f.submitted"
            [value]="confForm.formHelp"
            (outputEvent)="confForm.formHelp = $event"
          >
          </app-simple-dictionary-editor>
          <p-editor
            name="formHelp"
            id="formHelp"
            [required]="true"
            [hidden]="true"
            #formHelp="ngModel"
            [(ngModel)]="confForm.formHelp"
            [style]="{ height: '320px' }"
            [readonly]="readMode"
          ></p-editor>
        </div>
      </div>
    </div>
    <div class="p-grid p-field p-fluid p-mt-3" *ngIf="!readMode">
      <div class="p-col"></div>
      <div class="p-col-12 p-md-4">
        <div class="p-grid p-field">
          <div class="p-col">
            <button
              class="p-button-secondary"
              (click)="onCancel()"
              pButton
              type="button"
              label="{{ translateGeneral.cancel }}"
              [disabled]="readMode"
            ></button>
          </div>
          <div class="p-col">
            <button
              pButton
              type="submit"
              label="{{
                isEdition ? translateGeneral.edit : translateGeneral.add
              }}"
              [disabled]="readMode"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
</form>
