import { NgModule } from '@angular/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { PrimengModule } from 'src/app/shared/primeng.module';
import { AddEditAccessibilityGroupComponent } from './add-edit-project/add-edit-accessibility-group/add-edit-accessibility-group.component';
import { AddEditProjectComponent } from './add-edit-project/add-edit-project.component';
import { ChangeStateProjectComponent } from './add-edit-project/change-state-project/change-state-project.component';
import { ProjectListComponent } from './detail-project/components/project-list/project-list.component';
import { DetailProjectComponent } from './detail-project/detail-project.component';
import { ListProjectComponent } from './list-project/list-project.component';
import { ProjectRoutingModule } from './project-routing.module';
import { VisitsGroupsComponent } from './visits-groups/visits-groups.component';

@NgModule({
  declarations: [
    ListProjectComponent,
    AddEditProjectComponent,
    DetailProjectComponent,
    ChangeStateProjectComponent,
    VisitsGroupsComponent,
    AddEditAccessibilityGroupComponent,
    ProjectListComponent,
  ],
  imports: [ProjectRoutingModule, PrimengModule, SharedComponentsModule],
  exports: [ListProjectComponent],
  providers: [],
})
export class ProjectModule {}
