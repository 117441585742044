<div class="container-tp2">
  <app-back-button-header
    [pageTitle]="'informedConsent.informedConsentList' | translate"
    [pageDescription]="
      'informedConsent.informedConsentListDescription' | translate
    "
    [pageUrl]="'/project/' + projectId + '/detail'"
    [showButton]="eConsentsCard.length > 0"
    [textButton]="'informedConsent.informedConsentListCreate' | translate"
    (clickEmitter)="create()"
  ></app-back-button-header>

  <div class="grid-cards" *ngIf="eConsentsCard.length > 0">
    <app-tp2-card
      *ngFor="let eCosent of eConsentsCard"
      [tp2Card]="eCosent"
      (tp2CardEmmiter)="onDetail(eCosent)"
    ></app-tp2-card>
  </div>

  <div *ngIf="eConsentsCard.length === 0" class="p-mt-6">
    <app-not-found-items
      [description]="
        'informedConsent.informedConsentListNotFoundTitle' | translate
      "
      [buttonDescription]="
        'informedConsent.informedConsentListNotFoundDescription' | translate
      "
      [buttonText]="
        'informedConsent.informedConsentListNotFoundTextButton' | translate
      "
      (clickEmitter)="create()"
    ></app-not-found-items>
  </div>
</div>

<app-delete-confirmation
  [header]="'informedConsent.confirmDelete' | translate"
  [description]="'informedConsent.confirmDescriptionDelete' | translate"
  [btnDeleteText]="'general.btnDelete' | translate"
  [(display)]="showDisplayDeleteComponent"
  (confirm)="onDelete()"
  position="fixed"
>
</app-delete-confirmation>
