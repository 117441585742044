<div class="card">
  <img class="image-state-icon" [src]="getImageUrl()" alt="Number icon" />
  <div class="card-content" [class.active]="isActive">
    <div class="card-header">
      <div class="card-info">
        <p id="tittle">
          {{ 'informedConsent.enums.states.' + state | translate }}
        </p>
        <p id="description" class="p-mb-2">
          {{ subjects }}
          {{ 'informedConsent.followUp.participants' | translate }}
        </p>
      </div>
      <div class="button-container">
        <img
          [src]="
            './assets/icons/informed-consent/info-circle' +
            (disabled ? '_disabled' : '') +
            '.svg'
          "
          alt="info"
          pTooltip="{{
            'informedConsent.followUp.tooltipsByState.' + state | translate
          }}"
          tooltipPosition="top"
          tooltipStyleClass="states-tooltip"
        />
        <img
          *ngIf="isActive"
          src="./assets/icons/informed-consent/cross.svg"
          alt="close"
          (click)="onClearFilter($event)"
        />
        <img
          *ngIf="!isActive"
          [src]="
            './assets/icons/informed-consent/arrow_right' +
            (disabled ? '_disabled' : '') +
            '.svg'
          "
          alt="arrow"
        />
      </div>
    </div>
    <p-progressBar
      class="customProgress p-mb-1 p-d-block"
      [value]="percentage"
      [showValue]="false"
      unit="%"
      [style]="{ color: 'var(--success-color-info)' }"
    ></p-progressBar>
    <p id="progress-label">
      {{ percentage }}
    </p>
  </div>
</div>
