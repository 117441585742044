import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TrialpalService } from 'src/app/services/trialpal.service';
import {
  Field,
  FormlyDisplay,
  PDF_VIEW_OPTIONS,
} from '../interfaces/formly-designer.interface';
import { DesignerFieldTypes } from './field-types';

@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.scss'],
})
export class DesignerComponent implements OnInit {
  @Input() formField: Field = { key: '', fieldGroup: [], scoreLabel: '' };
  @Input() projectId: any = {};
  @Output() formFieldChange: EventEmitter<any> = new EventEmitter();
  designerFieldTypes: DesignerFieldTypes[] = DesignerFieldTypes;
  fieldSelected: { type: string; icon: string; value: Field } | null = null;
  fieldInFieldGruopSelected: any;
  isDragOver: boolean = false;
  isDragInFieldGroup: boolean = false;
  timer: any; //Funciona para emitir cuando han pasado n segundos despues de la ultima iteración del usuario
  //Visualización del formulario

  currentDisplay: FormlyDisplay = FormlyDisplay.CARD;
  card: FormlyDisplay = FormlyDisplay.CARD;
  stepper: FormlyDisplay = FormlyDisplay.STEPPER;
  tabs: FormlyDisplay = FormlyDisplay.TABS;
  accordion: FormlyDisplay = FormlyDisplay.ACCORDION;
  pdfViewTypes: { label: string; value: string }[] = [];
  public onDragDrop$ = new Subject<CdkDragDrop<Array<any>>>();

  constructor(private trialpalService: TrialpalService) {}

  ngOnInit(): void {
    this.onDragDrop$.subscribe(($event: CdkDragDrop<any[]>) =>
      this.onDrop($event)
    );
    if (this.formField?.fieldGroup?.[0]?.wrappers) {
      this.currentDisplay = this.formField.fieldGroup[0].wrappers[0];
    }

    this.pdfViewTypes = [
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.answerSelected'
        ),
        value: PDF_VIEW_OPTIONS.ANSWER_SELECTED,
      },
      {
        label: this.trialpalService.translateService.instant(
          'designer.addEdit.optionsWithAnswers'
        ),
        value: PDF_VIEW_OPTIONS.OPTIONS_WITH_ASNWERS,
      },
    ];
  }

  //Función que se encarga de ingresar nuevos campos al formulario
  onDrop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.emitFormField();
  }

  dragStart(field: any) {
    this.fieldSelected = field;
    this.isDragOver = true;
  }

  dragEnd() {
    this.fieldSelected = null;
    this.isDragOver = false;
  }

  //Funcion que se encarga de actualizar el mensaje de score en el padre (500ms despues de la ultima iteracion del usuario)
  updateFormField() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.emitFormField(), 500);
  }

  emitFormField() {
    this.formFieldChange.emit({ ...this.formField });
  }

  changeDisplay(display: FormlyDisplay) {
    if (this.formField.fieldGroup) {
      this.formField.fieldGroup[0].wrappers = [display];
      this.currentDisplay = display;
      this.emitFormField();
    }
  }
}
