import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { ConciliationService } from 'src/app/modules/conciliation/conciliation.service';
import {
  ConfMedicalAttention,
  InstanceState,
  MedicalAttentionConfigurationMode,
  MedicalAttentionType,
  UpdateVerifiedMedicalAttentionInstanceMutation,
  VerifiedMedicalAttentionInstance,
} from 'src/app/modules/conciliation/conciliation.types';
import {
  GeneralModel,
  NameValue,
} from 'src/app/modules/conciliation/conciliationData';
import { PaginationService } from 'src/app/modules/conciliation/pagination.service';
import { TransformDatePipe } from 'src/app/pipe/transform-date.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { TrialpalService } from 'src/app/services/trialpal.service';
import { TP2Entites } from 'src/app/shared/global.variables';
import { ConciliationComponents } from '../../../../conciliationData';
import { DayInstanceService } from '../../shared/day-instance/day-instance.service';
import { SymptomService } from '../../symptom/symptom.service';
import { SummaryMedicalAttentionComponent } from '../summary-medical-attention/summary-medical-attention.component';
import { MedicalAttentionService } from './../medical-attention.service';
const logger = new Logger('tp2-conciliationMedicalAttetion');

@Component({
  selector: 'app-edit-medical-attention',
  templateUrl: './edit-medical-attention.component.html',
  styleUrls: ['./edit-medical-attention.component.scss'],
})
export class EditMedicalAttentionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() currentMedicalAttentionDay?: any;
  @Input() observations: any[] = [];
  @Input() subjectId: string = '';
  @Output() exportAuditRecord: EventEmitter<any> = new EventEmitter();
  optionsMedicalAttention: NameValue[] = [];
  form: FormGroup;
  subscriptionEnumTypeMedicalAttention: Subscription = new Subscription();
  confMedicalAttention?: ConfMedicalAttention;
  verifiedMedicalAttention?: VerifiedMedicalAttentionInstance;
  medicalAttention?: any;
  requiredWasHospitalizedComment: boolean = false;
  requiredTypeComment: boolean = false;
  requiredOpenReasonComment: boolean = false;
  requiredStartDateComment: boolean = false;
  requiredStartDate: boolean = false;
  requiredFinishDateComment: boolean = false;
  requiredFinishDate: boolean = false;
  requiredSymptomComment: boolean = false;
  maxDate: Date = new Date();
  optionsSyptoms: any[] = [];
  isNext: boolean = false;
  isDelete: boolean = false;
  filteredSymptoms: any[] = [];
  dayInstances: any[] = [];
  dayInstanceSelected: any = {};
  originalForm: any = {};
  reasonsItems: any = [];
  booleanOptions: any[] = [];
  constructor(
    private authService: AuthService,
    private conciliationService: ConciliationService,
    private formBuilder: FormBuilder,
    private trialpalService: TrialpalService,
    private transformDatePipe: TransformDatePipe,
    private symptomService: SymptomService,
    private paginationService: PaginationService,
    private dayInstanceService: DayInstanceService,
    public medicalAttentionService: MedicalAttentionService
  ) {
    this.form = this.formBuilder.group({
      formType: [],
      formTypeComment: [],
      formReason: [],
      formReasonComment: [],
      formSymptomsInstances: [],
      formSymptomsInstancesComment: [],
      formWasHospitalized: [],
      formWasHospitalizedComment: [],
      formRememberStartDate: [],
      formStartDate: [],
      formStartDateComment: [],
      formRememberFinishDate: [],
      formFinishDate: [],
      formFinishDateComment: [],
      formAddDay: [],
      _changeReason: [],
    });
    this.resetForm();
    this.subscriptionEnumTypeMedicalAttention =
      this.trialpalService.translateService
        .get('medicalattentioninstance.enums')
        .subscribe((_translate: any) => {
          this.optionsMedicalAttention = this.trialpalService.getTranslatedEnum(
            'medicalattentioninstance.enums',
            MedicalAttentionType
          );
        });
  }
  ngOnDestroy() {
    this.subscriptionEnumTypeMedicalAttention.unsubscribe();
  }
  async ngOnInit() {
    this.requiredValues();
  }

  onChangeDay(_event: any) {
    const day = this.conciliationService.dayInstancesAndConfigurations.find(
      (day: any) => day.dayInstance.id === this.formAddDay?.value
    );
    this.paginationService.dayName = day?.dayInstance?.confDay.dayName;
    //Cargar la configuracion
    const configuration =
      this.medicalAttentionService.getConfMedicalAttentionByForm(
        day?.dayInstance?.confDay?.confFormId
      );
    if (configuration) {
      this.confMedicalAttention = configuration;
      this.currentMedicalAttentionDay.isEdition = false;
    }
    logger.debug('onChangeDay', day, configuration);
  }
  async ngOnChanges() {
    if (this.medicalAttentionService.isNewMedicalAttention) {
      this.getDayInstances();
    } else {
      await this.loadFormMedicalAttention();
    }
  }

  async loadFormMedicalAttention() {
    this.resetForm();
    this.paginationService.dayName =
      this.currentMedicalAttentionDay?.dayName || '';

    this.medicalAttention = this.currentMedicalAttentionDay?.medicalAttention;
    this.confMedicalAttention = this.currentMedicalAttentionDay?.configuration;
    this.booleanOptions = this.conciliationService.buildBooleanOptions();
    //Si no existe la configuracion, se agrega la de razon abierta por defecto
    if (!this.confMedicalAttention?.id) {
      this.confMedicalAttention =
        this.medicalAttentionService.getEmptyConfMedicalAttention();
    }
    this.verifiedMedicalAttention =
      this.currentMedicalAttentionDay?.verifiedMedicalAttention;
    this.isDelete =
      this.currentMedicalAttentionDay?.verifiedMedicalAttention?.state ===
      InstanceState.DELETED;
    //Rango de fechas que puede el usuario seleccionar
    this.maxDate = new Date(
      this.currentMedicalAttentionDay?.dayInstance?.startDate + 'T00:00:00'
    );
    logger.debug('changes ', this.currentMedicalAttentionDay);

    await this.loadSymptoms();

    this.requiredValues();
    this.setValuesVerified();
    this.originalForm = { ...this.form.value };
  }

  getDayInstances() {
    let dayInstances = [
      ...this.conciliationService.dayInstancesAndConfigurations,
    ];

    const confMedicalAttentions =
      this.medicalAttentionService.configMedicalAttentionsByEdiary?.items ?? [];

    const verifiedDayInstances = this.dayInstanceService.verifiedDayInstances;

    //Valida que solo muestres los dias que ya hayan pasa
    dayInstances = dayInstances.filter((DI: any) => {
      const isMedicalAttentionConciliationAllowed =
        this.paginationService.isSectionReconciliationAllowed(
          'isMedicalAttentionRequired',
          DI.configuration
        );
      return (
        this.conciliationService.isBeforeToCurrentDate(
          DI.dayInstance.finishDate
        ) &&
        DI.configuration.isMedicalAttentionRequired &&
        isMedicalAttentionConciliationAllowed
      );
    });

    //Filtra los días que tengan habilitado la opcion de otros sintomas y permitan crear
    dayInstances = dayInstances.filter((DI: any) => {
      const confFormId = DI.dayInstance?.confDay?.confFormId;

      //Obtiene el dia verificado
      const verified = verifiedDayInstances?.find((VDI: any) => {
        return VDI?.dayInstanceId === DI.dayInstance.id;
      });

      //Obtiene la configuracion de atencion medica para ese dia
      const confMedicalAttention = confMedicalAttentions.find(
        (_confMedicalAttention: any) => {
          return _confMedicalAttention.confFormId === confFormId;
        }
      );

      const medicalAttentionReconciliationConfig =
        DI.dayInstance?.confDay?.confForm?.medicalAttentionReconciliationConfig;

      //Valida si la precondicion esta activa y si tiene "si"
      const showPrecondition = confMedicalAttention?.showPrecondition;

      return (
        (!showPrecondition ||
          (showPrecondition && verified?.hasMedicalAttention)) &&
        medicalAttentionReconciliationConfig?.allowAddRecords
      );
    });

    //Transforma el arreglo para que se muestre el nombre del dia y como valor el mismo día
    this.dayInstances = dayInstances.map((DI: any) => {
      return {
        label: this.trialpalService.dictionaryPipe.transform(
          DI.dayInstance.confDay.dayName
        ),
        value: DI,
      };
    });
    this.dayInstances.sort(
      (a: any, b: any) =>
        a?.value?.dayInstance?.confDay?.order -
        b?.value?.dayInstance?.confDay?.order
    );
  }

  //Se encarga de construir el esqueleto de la atencion medica que será agregada
  newMedicalAttention() {
    const confFormId =
      this.dayInstanceSelected?.dayInstance?.confDay?.confFormId || '';

    const dayInstance = this.dayInstanceSelected?.dayInstance;
    this.paginationService.dayName = dayInstance?.confDay?.dayName;

    this.medicalAttentionService.isNewMedicalAttention = false;
    const currentMedicalAttention =
      this.medicalAttentionService.getNewMedicalAttention(
        dayInstance,
        confFormId,
        this.subjectId,
        null
      );

    //Agrega la atencion medica al formulario
    this.currentMedicalAttentionDay = currentMedicalAttention;
    this.loadFormMedicalAttention();
    this.medicalAttentionService.isNewMedicalAttention = false;
    this.dayInstanceSelected = null;
  }

  async loadSymptoms() {
    await this.symptomService.getSymptomsMedicationAndMedicalAttention();
    //Filtra los sintomas a mostrar
    const isBindToSymptomOcurrencies =
      this.confMedicalAttention?.isBindToSymptomOcurrencies ?? false;
    const symptoms = this.symptomService.symptoms?.filter(
      (symptom: any) =>
        symptom?.dayInstanceId ===
          this.currentMedicalAttentionDay?.dayInstance?.id &&
        symptom.state !== InstanceState.DELETED
    );

    const filterSymptoms =
      symptoms?.filter((symptom: any) => symptom.ocurrend) || [];

    this.filteredSymptoms = isBindToSymptomOcurrencies
      ? filterSymptoms
      : symptoms;
  }

  requiredValues() {
    this.requiredStartDate = this.confMedicalAttention?.showRememberStartDate
      ? this.medicalAttention?.rememberStartDate
      : false;
    this.requiredFinishDate = this.confMedicalAttention?.showRememberFinishDate
      ? this.medicalAttention?.rememberFinishDate
      : false;
    this.requiredWasHospitalizedComment = false;
    this.requiredFinishDateComment = false;
    this.requiredStartDateComment = false;
    this.requiredSymptomComment = false;
    if (this.currentMedicalAttentionDay?.isEdition) {
      this.getRequiredIsEdition();
      this.exportAuditRecord.emit({
        entity: TP2Entites.VERIFIEDMEDICALATTENTIONINSTANCE,
        id: this.verifiedMedicalAttention?.id,
        description: this.paginationService?.dayName ?? '',
      });
    }
  }

  /**
   * Valores requeridos si es una edicion
   */
  getRequiredIsEdition() {
    this.requiredWasHospitalizedComment =
      !!this.verifiedMedicalAttention?.wasHospitalizedComment;

    this.requiredTypeComment = !!this.verifiedMedicalAttention?.typeComment;
    this.requiredOpenReasonComment =
      !!this.verifiedMedicalAttention?.reasonComment;
    this.requiredStartDateComment =
      !!this.verifiedMedicalAttention?.startDateComment;
    this.requiredFinishDateComment =
      !!this.verifiedMedicalAttention?.finishDateComment;
    this.requiredSymptomComment =
      !!this.verifiedMedicalAttention?.symptomsInstancesComment;
    this.requiredStartDate =
      this.verifiedMedicalAttention?.rememberStartDate ?? false;
    this.requiredFinishDate =
      this.verifiedMedicalAttention?.rememberFinishDate ?? false;
  }
  /**
   * @desc pasar los datos al formulario
   */
  setValuesVerified() {
    if (this.confMedicalAttention) {
      if (this.confMedicalAttention?.showType) {
        this.pacthValueType();
      }
      if (this.confMedicalAttention?.showHospitalization) {
        this.pacthValueHopitalization();
      }
      if (this.confMedicalAttention?.showRememberStartDate) {
        this.patchValueStartDate();
      }
      if (this.confMedicalAttention?.showRememberFinishDate) {
        this.patchValueFinishDate();
      }
      if (this.confMedicalAttention?.mode) {
        this.pacthValueMode(this.confMedicalAttention.mode);
      }
    }
  }

  /**
   * @desc Metodo submit para actualizar o crear la instancia de la temperatura
   */
  async onSubmit() {
    logger.debug('formularioSubmit1', this.form.value);
    if (this.isDelete) return this.nextMedicalAttention();
    this.conciliationService.clearSpaces(this.form);

    const isFormReasonValid = this.conciliationService.getFormReasonValid(
      this.formReason
    );

    if (this.form.valid && isFormReasonValid) {
      const isValidDates = this.conciliationService.isValidDates(
        this.form,
        true
      );
      if (!isValidDates) return this.conciliationService.messageErrorDates();
      await this.showDialogSummary();
    } else {
      this.trialpalService.messageService.add({
        severity: 'error',
        summary: this.trialpalService.translateService.instant(
          'conciliation.messageError.summary'
        ),
        detail: this.trialpalService.translateService.instant(
          'general.incompleteFormMessage'
        ),
      });
    }
  }

  async showDialogSummary() {
    // Objeto con los valores reportados por el sujeto
    let originObject: any = this.getOriginObject();
    // Crear el objeto con los nuevos valores
    const newValue: any = this.getVerifiedMedicalAttention();

    //Evaluar si cambian los sintomas seleccionados
    const changeSymptom =
      JSON.stringify(originObject.symptomsInstances) !==
      JSON.stringify(newValue.symptomsInstances);

    const isChangeform =
      JSON.stringify(originObject) !== JSON.stringify(newValue);

    logger.debug('isChangeform', originObject, newValue, isChangeform);
    logger.debug(
      'isChangeformSymtomps',
      this.verifiedMedicalAttention,
      originObject.symptomsInstances,
      newValue.symptomsInstances,
      changeSymptom
    );
    if (isChangeform || changeSymptom) {
      let oldValue: any[] = [];
      let newVerified: any[] = [];
      // recorrer el objeto original y validar cuales cambiaron para pasarlos al modal de verificacion
      for (const [key, value] of Object.entries(originObject)) {
        if (key === 'symptomsInstances' && changeSymptom) {
          oldValue.push({
            [key]: value,
          });
          newVerified.push({
            [key]: newValue[key],
          });
        } else if (key !== 'symptomsInstances' && newValue[key] !== value) {
          logger.debug('normal', newValue[key], value);
          oldValue.push({
            [key]: value,
          });
          newVerified.push({
            [key]: newValue[key],
          });
        }
      }
      logger.debug('Cambiios', newVerified, oldValue);
      const ref = this.trialpalService.dialogService.open(
        SummaryMedicalAttentionComponent,
        {
          data: {
            medicalAttention: this.medicalAttention,
            configuration: this.confMedicalAttention,
            oldValue,
            newVerified,
          },
          width: '50%',
          header: this.trialpalService.translateService.instant(
            'conciliation.confirmSaveForm'
          ),
        }
      );
      ref.onClose.subscribe((res) => {
        if (res) {
          this.conciliationService.recordChangeEvent(
            newVerified,
            oldValue,
            'medicalAttention'
          );
          this.createOrUpdateMedicalAttentionInstance();
        }
      });
    } else {
      await this.saveMedicalAttentionWithCommentChange();
    }
  }

  //Guarda las atenciones medicas donde solo cambio el comentario
  async saveMedicalAttentionWithCommentChange() {
    const isCommentChange = this.conciliationService.isFormCommentChange(
      this.form.value,
      this.originalForm
    );
    if (isCommentChange) {
      await this.updatedMedicalAttention();
    } else {
      this.nextOrUpdateMedicalAttention();
    }
    logger.debug('sin Cambios', isCommentChange);
  }

  getOriginObject() {
    let originObject: any = this.getRecordMedicalAttention(
      this.medicalAttention
    );

    if (this.verifiedMedicalAttention?.id) {
      originObject = this.getRecordMedicalAttention(
        this.verifiedMedicalAttention
      );
    }

    return originObject;
  }

  async nextOrUpdateMedicalAttention() {
    if (this.currentMedicalAttentionDay?.isEdition && this.isCommentChange()) {
      return this.nextMedicalAttention();
    }
    await this.createOrUpdateMedicalAttentionInstance();
  }

  isCommentChange() {
    const original = this.verifiedMedicalAttention?.id
      ? this.verifiedMedicalAttention
      : this.medicalAttention;
    const verified = this.form.value;
    return this.conciliationService.isFormCommentChange(original, verified);
  }

  /**
   * Crear un objeto con los datos originales reportados por el sujeto
   * @returns Objeto con los datos originlaes reportados
   */
  getRecordMedicalAttention(medicalAttention: any) {
    return {
      type: medicalAttention?.type || undefined,
      reason: medicalAttention?.reason || undefined,
      symptomsInstances: medicalAttention?.symptomsInstances
        ? medicalAttention?.symptomsInstances
        : [],
      rememberStartDate: medicalAttention?.rememberStartDate,
      startDate: medicalAttention?.startDate
        ? this.medicalAttentionService.getTransFormDate(
            medicalAttention?.startDate
          )
        : undefined,
      rememberFinishDate: medicalAttention?.rememberFinishDate,
      finishDate: medicalAttention?.finishDate
        ? this.medicalAttentionService.getTransFormDate(
            medicalAttention?.finishDate
          )
        : undefined,
      wasHospitalized: medicalAttention?.wasHospitalized,
    };
  }

  /**
   *Crear un objeto con los datos actuales registrados en el formulario
   * @returns Objeto con los datos actiales del formulario
   */
  getVerifiedMedicalAttention() {
    return {
      type: this.formType?.value || undefined,
      reason: this.formReason?.value || undefined,
      symptomsInstances: this.formSymptomsInstances?.value
        ? this.formSymptomsInstances?.value
        : [],
      rememberStartDate: this.formRememberStartDate?.value,
      startDate: this.formStartDate?.value
        ? this.medicalAttentionService.getTransFormDate(
            this.formStartDate?.value
          )
        : undefined,
      rememberFinishDate: this.formRememberFinishDate?.value,
      finishDate: this.formFinishDate?.value
        ? this.medicalAttentionService.getTransFormDate(
            this.formFinishDate?.value
          )
        : undefined,
      wasHospitalized: this.formWasHospitalized?.value,
    };
  }
  /**
   * @desc Crear instancia de atencion medica Verificada
   */
  async createVerifiedMedicalAttentionInstance() {
    this.conciliationService.showSpinner(
      'transactionLoadingCreate',
      'medicalattentioninstance.entity'
    );
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    const createVerifiedMedicalAttentionInstance: any =
      await this.getObjectVerifiedMedicalAttention();
    await this.medicalAttentionService
      .createVerifiedMedicalAttentionInstance(
        createVerifiedMedicalAttentionInstance
      )
      .then(async (result: any) => {
        logger.debug('Created Verified Medical Attention', result);
        if (result?.medicalAttentionInstanceId?.length === 0) {
          this.medicalAttentionService.medicalAttentionTable.push(
            this.currentMedicalAttentionDay
          );
        }
        this.verifiedMedicalAttention = result;
        const dayIndex = this.currentMedicalAttentionDay?.index;
        this.currentMedicalAttentionDay.verifiedMedicalAttention = result;
        this.medicalAttentionService.verifiedMedicalAttentionsBySubject.push(
          result
        );
        this.updatedTable(result, dayIndex);

        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'general.messageSuccessOperation.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'general.messageSuccessOperation.detail'
          ),
        });
        this.currentMedicalAttentionDay.isEdition = true;
      })
      .catch((err) => {
        logger.error('Verified MedicalAttention', err);
        this.trialpalService.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'general.messageErrorOperation.detail'
          ),
        });
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  /**
   * @des actualiza la instancia de la atencion medica con los datos conciliados
   */
  async updatedMedicalAttention() {
    this.conciliationService.showSpinner(
      'transactionLoadingUpdate',
      'medicalattentioninstance.entity'
    );
    const updateVerified: any = await this.getObjectVerifiedMedicalAttention();
    await this.conciliationService.updateEdiaryPhaseState(false).catch();
    await this.medicalAttentionService
      .updateVerifiedMedicalAttentionInstance(
        updateVerified,
        updateVerified.id,
        updateVerified._version
      )
      .then(async (data: any) => {
        this.verifiedMedicalAttention = data;
        const dayIndex = this.currentMedicalAttentionDay?.index;

        this.updatedTable(data, dayIndex, true);
        this.trialpalService.messageService.add({
          severity: 'success',
          summary: this.trialpalService.translateService.instant(
            'conciliation.messageSuccessUpdated.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'conciliation.messageSuccessUpdated.detail'
          ),
        });
      })
      .catch((err: any) => {
        logger.error('updatedMedicalAttention', err);
        this.trialpalService.messageService.add({
          severity: 'error',
          summary: this.trialpalService.translateService.instant(
            'conciliation.messageError.summary'
          ),
          detail: this.trialpalService.translateService.instant(
            'conciliation.messageError.detail'
          ),
        });
      })
      .finally(() => this.trialpalService.hideSpinner());
  }

  private updatedTable(
    verifiedMedicalAttention: UpdateVerifiedMedicalAttentionInstanceMutation,
    dayIndex: number,
    isUpdate: boolean = false
  ) {
    const reason = this.medicalAttentionService.addReason(
      this.confMedicalAttention,
      this.medicalAttention
    );

    const updateTable: any = this.medicalAttentionService.objMedicalAttention(
      dayIndex,
      this.currentMedicalAttentionDay.dayInstance,
      this.medicalAttention,
      reason,
      this.confMedicalAttention,
      verifiedMedicalAttention
    );

    this.medicalAttentionService.medicalAttentionTable =
      this.medicalAttentionService.medicalAttentionTable?.map((day: any) => {
        if (day.index === dayIndex) {
          day = updateTable;
          return day;
        }
        return day;
      });

    this.medicalAttentionService.addCommentsTable();

    //Se moverá al siguiente cuando sea una actualizacion
    if (
      isUpdate ||
      verifiedMedicalAttention?.medicalAttentionInstanceId?.length !== 0
    ) {
      this.nextMedicalAttention();
    } else {
      this.paginationService.selectedMedicalAttention({}, true);
    }

    logger.debug(
      'updateTable',
      this.medicalAttentionService.medicalAttentionTable
    );
  }

  addObservations() {
    const arrComments: any[] = [];
    if (this.formTypeComment?.value) {
      arrComments.push(this.formTypeComment?.value);
    }
    if (this.formReasonComment?.value) {
      arrComments.push(this.formReasonComment?.value);
    }
    if (this.formSymptomsInstancesComment?.value) {
      arrComments.push(this.formSymptomsInstancesComment?.value);
    }
    if (this.formWasHospitalizedComment?.value) {
      arrComments.push(this.formWasHospitalizedComment?.value);
    }
    if (this.formRememberStartDateComment?.value) {
      arrComments.push(this.formRememberStartDateComment?.value);
    }
    if (this.formStartDateComment?.value) {
      arrComments.push(this.formStartDateComment?.value);
    }
    if (this.formRememberFinishDateComment?.value) {
      arrComments.push(this.formRememberFinishDateComment?.value);
    }
    if (this.formFinishDateComment?.value) {
      arrComments.push(this.formFinishDateComment?.value);
    }

    return arrComments;
  }
  /**
   * @desc crear o editar la atencion medica
   */
  async createOrUpdateMedicalAttentionInstance() {
    if (this.currentMedicalAttentionDay?.isEdition) {
      await this.updatedMedicalAttention();
    } else {
      await this.createVerifiedMedicalAttentionInstance();
    }
  }

  /**
   * @returns objeto de actualizacion de datos verificados de la atencion medica
   */
  async getObjectVerifiedMedicalAttention() {
    const dayInstanceId = this.currentMedicalAttentionDay?.dayInstance?.id;
    const verifiedDayInstance =
      await this.dayInstanceService.getVerifiedDayInstanceByDayInstanceId(
        dayInstanceId
      );
    const reason = this.form.controls['_changeReason'].value;
    const createOrUpdateMedicalAttention: CreateOrUpdateMedicalAttention = {
      subjectId: this.currentMedicalAttentionDay?.subjectId,
      verifiedDayInstanceId: verifiedDayInstance.id,
      medicalAttentionInstanceId:
        this.currentMedicalAttentionDay?.medicalAttentionInstanceId,
      type: this.confMedicalAttention?.showType
        ? this.formType?.value
        : undefined,
      typeComment: this.formTypeComment?.value,
      reason: this.formReason?.value,
      reasonComment: this.formReasonComment?.value,
      symptomsInstances: this.formSymptomsInstances?.value,
      symptomsInstancesComment: this.formSymptomsInstancesComment?.value,
      rememberStartDate: this.confMedicalAttention?.showRememberStartDate
        ? this.formRememberStartDate?.value
        : undefined,
      rememberStartDateComment: this.formRememberStartDateComment?.value,
      startDate:
        this.confMedicalAttention?.showRememberStartDate &&
        this.formRememberStartDate?.value
          ? this.formStartDate?.value
          : undefined,
      startDateComment: this.formStartDateComment?.value,
      rememberFinishDate: this.confMedicalAttention?.showRememberFinishDate
        ? this.formRememberFinishDate?.value
        : undefined,
      rememberFinishDateComment: this.formRememberFinishDateComment?.value,
      finishDate:
        this.confMedicalAttention?.showRememberFinishDate &&
        this.formRememberFinishDate?.value
          ? this.formFinishDate?.value
          : undefined,
      finishDateComment: this.formFinishDateComment?.value,
      wasHospitalized: this.confMedicalAttention?.showHospitalization
        ? this.formWasHospitalized?.value
        : undefined,
      wasHospitalizedComment: this.formWasHospitalizedComment?.value,
      _lastUser: this.authService.getUsername(),
      _version: 1,
      _changeReason: reason,
      state: InstanceState.UNSIGNED,
    };

    if (this.currentMedicalAttentionDay?.isEdition) {
      createOrUpdateMedicalAttention.id =
        this.verifiedMedicalAttention?.id ?? '';
      createOrUpdateMedicalAttention._version =
        this.verifiedMedicalAttention?._version;
    }
    logger.debug(
      'createOrUpdateMedicalAttention',
      createOrUpdateMedicalAttention
    );
    return createOrUpdateMedicalAttention;
  }

  // validaciones de campos requeridos del formulario ============//

  get changeOpenReason() {
    let requiredComentOpenReason: boolean = false;
    if (
      this.confMedicalAttention?.mode &&
      this.confMedicalAttention?.mode ===
        MedicalAttentionConfigurationMode.OPEN_REASON
    ) {
      requiredComentOpenReason =
        this.medicalAttention?.reason?.trim() !==
        this.formReason?.value?.trim();
      if (this.currentMedicalAttentionDay?.isEdition) {
        requiredComentOpenReason =
          this.medicalAttention?.reason?.trim() !==
          this.formReason?.value?.trim();
        if (this.verifiedMedicalAttention?.reasonComment) {
          requiredComentOpenReason = true;
        }
      }
      this.conciliationService.setValidator(
        this.formReason,
        !this.shouldDisableOpenReasonField()
      );
    }

    this.conciliationService.setValidator(
      this.formReasonComment,
      requiredComentOpenReason && !this.shouldDisableOpenReasonField()
    );
    return requiredComentOpenReason;
  }

  /**
   *Validar los cambios del los sintomas seleccionados y establecer como requerido el campo
   * @param event cambio en el multiselect de los sintomas
   */
  changeSymptom(event: any) {
    if (
      this.confMedicalAttention?.mode &&
      this.confMedicalAttention?.mode ===
        MedicalAttentionConfigurationMode.SYMPTOM_BINDING
    ) {
      this.requiredSymptomComment =
        JSON.stringify(event.value) !==
        JSON.stringify(this.medicalAttention?.symptomsInstances);

      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredOpenReasonComment =
          !!this.verifiedMedicalAttention?.symptomsInstancesComment;
      }
      this.conciliationService.setValidator(
        this.formSymptomsInstances,
        !this.shouldDisableSymptomOccurencyReasonField()
      );
    }

    if (!this.requiredSymptomComment) {
      this.formSymptomsInstancesComment?.reset();
    }
    this.conciliationService.setValidator(
      this.formSymptomsInstancesComment,
      this.requiredSymptomComment &&
        !this.shouldDisableSymptomOccurencyReasonField()
    );
    logger.debug('changeSymptom', this.requiredSymptomComment);
  }
  /**
   * Validar el comentario del tipo de atencion medica como requerido o no
   */
  onChangeType(_event: any) {
    if (this.confMedicalAttention?.showType) {
      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredTypeComment =
          this.medicalAttention?.type !== this.formType?.value;
        if (this.verifiedMedicalAttention?.typeComment) {
          this.requiredTypeComment = true;
        }
      } else {
        this.requiredTypeComment =
          this.medicalAttention?.type !== this.formType?.value;
      }
    }
    if (!this.requiredTypeComment) {
      this.formTypeComment?.reset();
    }
    logger.debug('changeType', this.requiredTypeComment);
  }

  /**
   * @desc valida si es o no obligatorio el cmapo de comentario respecto a si fue hospitalizado
   */
  onChangeWasHospitalized(_event: any) {
    if (this.confMedicalAttention?.showHospitalization) {
      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredWasHospitalizedComment =
          this.medicalAttention?.wasHospitalized !==
          this.formWasHospitalized?.value;
        if (this.verifiedMedicalAttention?.wasHospitalizedComment) {
          this.requiredWasHospitalizedComment = true;
        }
      } else {
        this.requiredWasHospitalizedComment =
          this.medicalAttention?.wasHospitalized !==
          this.formWasHospitalized?.value;
      }
    }
    if (!this.requiredWasHospitalizedComment) {
      this.formWasHospitalizedComment?.reset();
    }
    this.conciliationService.setValidator(
      this.formWasHospitalizedComment,
      this.requiredWasHospitalizedComment
    );
    logger.debug('changeHospitalized', this.requiredWasHospitalizedComment);
  }

  /**
   * @desc Validar obligatoriedad del campo comentario para la fecha de inicio
   */
  onChangeRememberStartDate(event: any) {
    if (this.confMedicalAttention?.showRememberStartDate) {
      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredStartDateComment =
          event.checked !== this.medicalAttention?.rememberStartDate;
        if (this.verifiedMedicalAttention?.startDateComment) {
          this.requiredStartDateComment = true;
        }
      } else {
        this.requiredStartDateComment =
          event.checked !== this.medicalAttention?.rememberStartDate;
      }

      this.conciliationService.setValidator(this.formStartDate, event.checked);
      this.conciliationService.setValidator(
        this.formStartDateComment,
        this.requiredStartDateComment
      );
    }
    logger.debug('changeRememberStartDate', this.requiredStartDateComment);
  }

  selectStartDate(_event: any) {
    if (this.confMedicalAttention?.showRememberStartDate) {
      let startDate;
      if (this.medicalAttention?.startDate !== GeneralModel.NA) {
        startDate = this.medicalAttention?.startDate;
      }
      const changeDate: boolean =
        this.transformDatePipe.transform(this.formStartDate?.value) !==
        this.transformDatePipe.transform(startDate);

      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredStartDateComment = changeDate;
        if (this.verifiedMedicalAttention?.startDateComment) {
          this.requiredStartDateComment = true;
        }
      } else {
        this.requiredStartDateComment = changeDate;
      }
      this.conciliationService.setValidator(this.formStartDate, true);
      this.conciliationService.setValidator(
        this.formStartDateComment,
        this.requiredStartDateComment
      );
      logger.debug(
        'changeRememberStartDateSelectDate',
        this.requiredStartDateComment
      );
    }
  }
  /**
   * @desc Validar obligatoriedad del campo comentario para la fecha final
   */

  onChangeRememberFinishDate(event: any) {
    if (this.confMedicalAttention?.showRememberFinishDate) {
      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredFinishDateComment =
          event.checked !== this.medicalAttention?.rememberFinishDate;
        if (this.verifiedMedicalAttention?.finishDateComment) {
          this.requiredFinishDateComment = true;
        }
      } else {
        this.requiredFinishDateComment =
          event.checked !== this.medicalAttention?.rememberFinishDate;
      }

      this.conciliationService.setValidator(this.formFinishDate, event.checked);
      this.conciliationService.setValidator(
        this.formFinishDateComment,
        this.requiredFinishDateComment
      );
    }
    logger.debug('changeRememberFinishDate', this.requiredFinishDateComment);
  }

  selectFinishDate(_event: any) {
    if (this.confMedicalAttention?.showRememberFinishDate) {
      let finishDate;
      if (this.medicalAttention?.finishDate !== GeneralModel.NA) {
        finishDate = this.medicalAttention?.finishDate;
      }
      const changeDate: boolean =
        this.transformDatePipe.transform(this.formFinishDate?.value) !==
        this.transformDatePipe.transform(finishDate);

      if (this.currentMedicalAttentionDay?.isEdition) {
        this.requiredFinishDateComment = changeDate;
        if (this.verifiedMedicalAttention?.finishDateComment) {
          this.requiredFinishDateComment = true;
        }
      } else {
        this.requiredFinishDateComment = changeDate;
      }
      this.conciliationService.setValidator(this.formFinishDate, true);
      this.conciliationService.setValidator(
        this.formFinishDateComment,
        this.requiredFinishDateComment
      );
      logger.debug(
        'changeRememberFinishDateSelectDate',
        this.requiredFinishDateComment
      );
    }
  }

  pacthValueType() {
    let type = this.currentMedicalAttentionDay?.isEdition
      ? this.verifiedMedicalAttention?.type
      : this.medicalAttention?.type;

    this.form.patchValue({
      formType: type,
      formTypeComment: this.verifiedMedicalAttention?.typeComment,
    });
  }
  pacthValueHopitalization() {
    this.form.patchValue({
      formWasHospitalized: this.currentMedicalAttentionDay?.isEdition
        ? this.verifiedMedicalAttention?.wasHospitalized
        : this.medicalAttention?.wasHospitalized,
      formWasHospitalizedComment:
        this.verifiedMedicalAttention?.wasHospitalizedComment,
    });
  }
  patchValueStartDate() {
    let rememberStartDate = this.medicalAttention?.rememberStartDate;
    let startDate = this.medicalAttention?.startDate;
    if (this.currentMedicalAttentionDay?.isEdition) {
      rememberStartDate = this.verifiedMedicalAttention?.rememberStartDate;
      startDate = this.verifiedMedicalAttention?.startDate;
    }
    this.form.patchValue({
      formRememberStartDate: rememberStartDate,
      formStartDate:
        rememberStartDate && startDate ? new Date(startDate) : undefined,
      formStartDateComment: this.verifiedMedicalAttention?.startDateComment,
    });
  }
  patchValueFinishDate() {
    let rememberFinishDate = this.medicalAttention?.rememberFinishDate;
    let finishDate = this.medicalAttention?.finishDate;
    if (this.currentMedicalAttentionDay?.isEdition) {
      rememberFinishDate = this.verifiedMedicalAttention?.rememberFinishDate;
      finishDate = this.verifiedMedicalAttention?.finishDate;
    }
    this.form.patchValue({
      formRememberFinishDate: rememberFinishDate,
      formFinishDate:
        rememberFinishDate && finishDate ? new Date(finishDate) : undefined,
      formFinishDateComment: this.verifiedMedicalAttention?.finishDateComment,
    });
  }
  pacthValueMode(mode: MedicalAttentionConfigurationMode) {
    if (mode === MedicalAttentionConfigurationMode.OPEN_REASON) {
      let reason = this.currentMedicalAttentionDay?.isEdition
        ? this.verifiedMedicalAttention?.reason
        : this.medicalAttention?.reason;

      this.form.patchValue({
        formReason: reason,
        formReasonComment: this.verifiedMedicalAttention?.reasonComment,
      });
    }
    if (mode === MedicalAttentionConfigurationMode.SYMPTOM_BINDING) {
      let symptomsInstances = this.currentMedicalAttentionDay?.isEdition
        ? this.verifiedMedicalAttention?.symptomsInstances
        : this.medicalAttention?.symptomsInstances;
      this.form.patchValue({
        formSymptomsInstances: symptomsInstances,
        formSymptomsInstancesComment:
          this.verifiedMedicalAttention?.symptomsInstancesComment,
      });
    }
  }

  /**
   * @desc resetar todos los campos requeridos y los campos del formulario
   */
  private resetForm() {
    this.requiredWasHospitalizedComment = false;
    this.requiredTypeComment = false;
    this.requiredOpenReasonComment = false;
    this.requiredStartDate = false;
    this.requiredFinishDate = false;
    this.requiredStartDateComment = false;
    this.requiredFinishDateComment = false;
    this.requiredSymptomComment = false;
    this.conciliationService.setValidator(this.formType, false);
    this.conciliationService.setValidator(this.formTypeComment, false);
    this.conciliationService.setValidator(this.formReason, false);
    this.conciliationService.setValidator(this.formReasonComment, false);
    this.conciliationService.setValidator(this.formSymptomsInstances, false);
    this.conciliationService.setValidator(this.formWasHospitalized, false);
    this.conciliationService.setValidator(this.formRememberStartDate, false);
    this.conciliationService.setValidator(this.formStartDate, false);
    this.conciliationService.setValidator(this.formStartDateComment, false);
    this.conciliationService.setValidator(this.formRememberFinishDate, false);
    this.conciliationService.setValidator(this.formFinishDate, false);
    this.conciliationService.setValidator(this.formFinishDateComment, false);
    this.conciliationService.setValidator(
      this.formWasHospitalizedComment,
      false
    );
    this.conciliationService.setValidator(
      this.formSymptomsInstancesComment,
      false
    );
    this.form.reset();
    Object.keys(this.form.controls).forEach((key: any) => {
      this.form.controls[key].setErrors(null);
    });
  }

  previousDay() {
    let index = this.currentMedicalAttentionDay?.index;
    if (index - 1 === -1) {
      return this.paginationService.goToPreviousSection();
    }
    this.navigateDay(index - 1);
    logger.debug('previousDayInstance', this.currentMedicalAttentionDay);
  }

  nextMedicalAttention() {
    let index = this.currentMedicalAttentionDay?.index;
    const endDay = this.medicalAttentionService.medicalAttentionTable.length;
    if (index + 1 === endDay) {
      return this.paginationService.nextSection(
        ConciliationComponents.MEDICAl_ATTETION
      );
    }
    this.navigateDay(index + 1);
    logger.debug('nextDayInstance', this.currentMedicalAttentionDay);
  }

  navigateDay(index: number) {
    this.medicalAttentionService.medicalAttention =
      this.medicalAttentionService.medicalAttentionTable[index];
    this.medicalAttentionService.selectedMedicalAttention =
      this.medicalAttentionService.medicalAttentionTable[index];
  }

  shouldHideEntity(configVariable: any) {
    logger.debug('dayInstanceSelected', this.dayInstanceSelected);
    if (
      !configVariable &&
      (this.currentMedicalAttentionDay?.medicalAttention?.id ===
        this.conciliationService.NEW_INSTANCE_ID ||
        !this.currentMedicalAttentionDay?.medicalAttention?.id)
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldDisableEntity(configVariable: any) {
    if (
      !configVariable &&
      this.currentMedicalAttentionDay?.medicalAttention?.id !==
        this.conciliationService.NEW_INSTANCE_ID &&
      this.currentMedicalAttentionDay?.medicalAttention?.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldDisableDateFields() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldDisableEntity(
      medicalAttentionReconciliationConfig?.allowOcurencyDateReconciliation
    );
  }

  shouldDisableTypeField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldDisableEntity(
      medicalAttentionReconciliationConfig?.allowTypeReconciliation
    );
  }

  shouldDisableHospitalizationField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldDisableEntity(
      medicalAttentionReconciliationConfig?.allowHospitalizationReconciliation
    );
  }

  shouldDisableOpenReasonField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldDisableEntity(
      medicalAttentionReconciliationConfig?.allowReasonReconciliation
    );
  }

  shouldDisableSymptomOccurencyReasonField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldDisableEntity(
      medicalAttentionReconciliationConfig?.allowSymptomOccurencyReconciliation
    );
  }

  shouldHideDateFields() {
    logger.debug('currentMedicalAttentionDay', this.currentMedicalAttentionDay);

    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldHideEntity(
      medicalAttentionReconciliationConfig?.allowOcurencyDateReconciliation
    );
  }
  shouldHideTypeField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldHideEntity(
      medicalAttentionReconciliationConfig?.allowTypeReconciliation
    );
  }
  shouldHideHospitalizationField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldHideEntity(
      medicalAttentionReconciliationConfig?.allowHospitalizationReconciliation
    );
  }
  shouldHideOpenReasonField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldHideEntity(
      medicalAttentionReconciliationConfig?.allowReasonReconciliation
    );
  }
  shouldHideSymptomOccurencyReasonTypeField() {
    const medicalAttentionReconciliationConfig =
      this.currentMedicalAttentionDay?.confForm
        ?.medicalAttentionReconciliationConfig;
    return this.shouldHideEntity(
      medicalAttentionReconciliationConfig?.allowSymptomOccurencyReconciliation
    );
  }

  // ============= metodos get del formulario =============== //
  get formType() {
    return this.form.get('formType');
  }
  get formTypeComment() {
    return this.form.get('formTypeComment');
  }
  get formReason() {
    return this.form.get('formReason');
  }
  get formReasonComment() {
    return this.form.get('formReasonComment');
  }
  get formWasHospitalized() {
    return this.form.get('formWasHospitalized');
  }
  get formWasHospitalizedComment() {
    return this.form.get('formWasHospitalizedComment');
  }
  get formRememberStartDate() {
    return this.form.get('formRememberStartDate');
  }
  get formRememberStartDateComment() {
    return this.form.get('formRememberStartDateComment');
  }
  get formStartDate() {
    return this.form.get('formStartDate');
  }
  get formStartDateComment() {
    return this.form.get('formStartDateComment');
  }
  get formRememberFinishDate() {
    return this.form.get('formRememberFinishDate');
  }
  get formRememberFinishDateComment() {
    return this.form.get('formRememberFinishDateComment');
  }
  get formFinishDate() {
    return this.form.get('formFinishDate');
  }
  get formFinishDateComment() {
    return this.form.get('formFinishDateComment');
  }
  get formSymptomsInstances() {
    return this.form.get('formSymptomsInstances');
  }
  get formSymptomsInstancesComment() {
    return this.form.get('formSymptomsInstancesComment');
  }
  get formAddDay() {
    return this.form.get('formAddDay');
  }

  get isMedicalAttentionChange() {
    return (
      JSON.stringify(this.form.value) !== JSON.stringify(this.originalForm)
    );
  }
}

interface CreateOrUpdateMedicalAttention {
  subjectId: string;
  verifiedDayInstanceId?: string | null;
  medicalAttentionInstanceId?: string | null;
  id?: string | null;
  state?: InstanceState | null;
  type?: MedicalAttentionType | null;
  typeComment?: string | null;
  reason?: string | null;
  reasonComment?: string | null;
  symptomsInstances?: Array<string> | null;
  symptomsInstancesComment?: string | null;
  rememberStartDate?: boolean | null;
  rememberStartDateComment?: string | null;
  startDate?: string | null;
  startDateComment?: string | null;
  rememberFinishDate?: boolean | null;
  rememberFinishDateComment?: string | null;
  finishDate?: string | null;
  finishDateComment?: string | null;
  wasHospitalized?: boolean | null;
  wasHospitalizedComment?: string | null;
  _lastUser: string;
  _changeReason?: string | null;
  _version?: number | null;
}
