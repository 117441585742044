export const TRIALPAL_QUERIES = {
  GetAuditInfo: `query GetAuditInfo($entity: String!, $relatedEntityId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAuditTraceFilterInput, $limit: Int, $nextToken: String) {
        getAuditInfo(entity: $entity, relatedEntityId: $relatedEntityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            relatedEntityId
            entity
            projectId
            eventDateTime
            eventType
            _lastUser
            _changeReason
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
    }`,
  ListSites: `query ListSites($filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            projectId
            id
            name
            contactInfo
            showContactInfo
            showContactInfoLogin
            timezone
            defaultLanguage
            _lastUser
            _changeReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
    }`,
  UpdateAlertInstance: `mutation UpdateAlertInstance($input: UpdateAlertInstanceInput!, $condition: ModelAlertInstanceConditionInput) {
      updateAlertInstance(input: $input, condition: $condition) {
        __typename
        confAlertId
        confAlert {
          __typename
          confReportId
          confEdiaryId
          confSymptomId
          confTemperatureId
          projectId
          id
          alertName
          recipients
          type
          mailSubject
          mailBody
          SMSBody
          triggerVisitCompletion
          triggerEDiarySuspension
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectId
        subject {
          __typename
          projectId
          siteId
          id
          subjectNumber
          group
          state
          tag
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        confAlert_data
        entityId
        entityName
        state
        revisionState
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
  CreateConfDictionary: `mutation CreateConfDictionary($input: CreateConfDictionaryInput!, $condition: ModelConfDictionaryConditionInput) {
      createConfDictionary(input: $input, condition: $condition) {
        __typename
        projectId
        project {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        key
        spanish
        english
        isHtml
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
  ConfDictionaryByProjectId: `query ConfDictionaryByProjectId($projectId: ID!, $sortDirection: ModelSortDirection, $filter: ModelConfDictionaryFilterInput, $limit: Int, $nextToken: String) {
      confDictionaryByProjectId(projectId: $projectId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          projectId
          id
          key
          spanish
          english
          isHtml
          state
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`,
  UpdateConfDictionary: `mutation UpdateConfDictionary($input: UpdateConfDictionaryInput!, $condition: ModelConfDictionaryConditionInput) {
      updateConfDictionary(input: $input, condition: $condition) {
        __typename
        projectId
        project {
          __typename
          id
          state
          code
          name
          sponsor
          groups
          phases
          faultRecipients
          isEdiaryActivated
          isReportsActivated
          isICActivated
          isChatActivated
          isMFAActivated
          _lastUser
          _changeReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        key
        spanish
        english
        isHtml
        state
        _lastUser
        _changeReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }`,
};
